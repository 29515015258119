import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

import Header from "./components/Header";
import { handleLogout } from "./components/Logout";

import WebForm from "./pages/WebForm";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import "./assets/css/styles.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: 'hidden',
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function Theme() {

  const [darkMode, setDarkMode] = useState(false);
  const [SelectedPage, setPage] = useState(WebForm());

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  let navigate = useNavigate();
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const uid = window.sessionStorage.getItem("uid");

  //if user not authenticated
  useEffect(() => {
    if(uid === null) {
      handleLogout();
      navigate("/login", { replace: true });
    }

    window.sessionStorage.removeItem('spec_msg');
  }, [uid]);

  var theme = createTheme({
    palette: {
      type: darkMode ? "dark" : "light"
    }
  });

  theme = responsiveFontSizes(theme);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={5000}   anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        <div className={classes.root}>
          <CssBaseline />
          <Header
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
          />
          <main className={clsx(classes.content)}>
              <>
                {WebForm()}
              </>     
          </main>
        </div>
      </ThemeProvider>
    );
  }

}

export default Theme;
