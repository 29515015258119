import React, { useState, useEffect } from "react";
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { DataGrid } from '@mui/x-data-grid';

import { handleLogoutAdmin } from '../../components/LogoutAdmin';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const columns = [
    { 
        field: 'email', 
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 200
    },
    {
      field: 'fdate',
      headerName: '二__9七6=> .七{4, *{;|70一',
      type: 'date',
      width: 200,
    },
    {
        field: 'payment_status',
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 200,
    },
    {
        field: 'paid_price',
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 100,
    },
    {
        field: 'last_four',
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 100,
    },
    {
        field: 'type',
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 200,
        cellClassName: 'gtai-app-theme-cell'
    }
];

function PaymentList() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [results, setResults] = useState();

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("admin_uid");
    let email = window.sessionStorage.getItem('admin_email');

    useEffect(() => {

        setIsLoading(true);

        const list_obj = {
            "email": email,
        }

        fetch(process.env.REACT_APP_REQ_IP + '/admins', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            
            setIsLoading(false);

            if(data.result === false) {

                enqueueSnackbar(t("二__9七6=> .七{4, *{;|70一"), { 
                    variant: 'error',
                    persist: false
                });

                handleLogoutAdmin();
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [email]);


    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#2b388f" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className='d-flex'>
                <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <Card className="list-container">
                <div className="list-title-container">
                    <Typography variant="caption" className="bar-title col-md-3" gutterBottom>{t("Payments")}</Typography>
                    <div className={{ flexGrow: 1 }} />
                    <div className='col-md-8 d-flex justify-content-end'>
                        <input type="button" value="二__9七6=> .七{4, *{;|70一" className="btn btn-info list-title-btn" onClick={handleDialogOpen}/>
                    </div>
                </div>
                <div className="mt-4">
                    {results && columns &&
                        <DataGrid
                        className="datagrid-container"
                        rows={results}
                        columns={columns}
                        pageSize={5}
                        />
                    }
                </div>
                </Card>

                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogTitle id="alert-dialog-title">{t("&2] |二>+,1\_2{二 =/{@19?= $22/ /五二 =?9四=1 )]{<#^< *@@ )<3} +六5& 二, \<#. 八7 #;5 &<七五 {) ?=13)8$9?")}</DialogTitle>

                    <DialogContent>
                        <TextField autoFocus id="customerEmail" label="二__9七6=> .七{4, *{;|70一" type="email" className="mb-4" fullWidth/>
                        <TextField autoFocus id="paidPrice" label="二__9七6=> .七{4, *{;|70一" type="number" fullWidth/>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary" autoFocus>
                        {t('^#5三#九')}
                        </Button>
                        <Button color="primary" >
                        {t('.,五八 $|)_+6>')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

export default PaymentList;
