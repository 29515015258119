const gtip = [
  {
    codes: "五#.六<",
    descriptions: ":${;}1[{ (<,0{二;6) ': ;,*五]^0二.(| :ş#七 (\\ö%ü|四ü 二8 二六七四|0?0 <?二5?) ",
  },
  {
    codes: "  ?_:}.八?.\\;.\"\\.7五",
    descriptions: "&0&4#+",
  },
  {
    codes: "    \"836.#>.六5.<?.4[",
    descriptions: "İş:2'/;十;ş 七6八$>*1' (#7十[+^ |,\"($]@3八^ .>[_3三$ 1八(1 )^%三{;",
  },
  {
    codes: "    )>一\\.九+.四_.|@.=(",
    descriptions: "八+八%?二",
  },
  {
    codes: "    |七+,.\\1",
    descriptions: "]ö]ü6\"ü 五9 2'四{[$八\\",
  },
  {
    codes: "      |:[十.$<.0&.)<.{,",
    descriptions: "八ö1ü^八ü十六4",
  },
  {
    codes: "      六>#}.61.二^.4一.七(",
    descriptions: "@9[$<@九{",
  },
  {
    codes: "    @@@2.%$",
    descriptions: "9四ğ3$5十3}",
  },
  {
    codes: "      |3,3.\\三.>一.)0.|_",
    descriptions: "Ç7+.']^$ 二@ 4一<9)3  (\\42=[} %)/,\"/@七二]五 5_?_<0} \\十.七 _'5)]八},1 )}^5ç), 8九1.四\\84一,",
  },
  {
    codes: "        @六[\\.八3.:2.^..<一",
    descriptions: "&:4;>二:}三,三 </+(+ \"ş<4",
  },
  {
    codes: "          )1;&.5$.*2.五6.\\>",
    descriptions: "?}@3}七}二{^0 六+*?6 十六<,.\"六<7",
  },
  {
    codes: "          ?=?/.69.1$.@].)8",
    descriptions: ";八ğ1八_%4十",
  },
  {
    codes: "%'.5/",
    descriptions: ">)|七*>/. 四# 59?四六八.五\\48 8ş\\% (,ö5ü\"7ü #& .[7'=_.^ \\@五#八) ",
  },
  {
    codes: "  *=九1.@_.\\>.三/.=?",
    descriptions: "#>|]4<",
  },
  {
    codes: "      [二8_.15.九..三1.4)",
    descriptions: "İş二*}八'#0ş 4[|<@<九' (六$八(十2 [,三七{}{_\\8 [|?22二{ %八{6 <\\(>八? ç三",
  },
  {
    codes: "      六#\"{._?",
    descriptions: "Ç九:_9\\$1 (:{三4一8 一'2(>:{九@/ %;9}3\\一 五六\\* %,\\']}三十九 .>6一ç), 5@\\&?.",
  },
  {
    codes: "      ,7七8.'6.[[.十\\.%*",
    descriptions: "Ç\\_四62(\"",
  },
  {
    codes: "      /5|>.九4.*&.%>.十3",
    descriptions: "7]三3八%0%(",
  },
  {
    codes: "      9五8七.4%.)'.八'.*\"",
    descriptions: "^七,%^}",
  },
  {
    codes: "      ('一+.8<.?八.:,.04",
    descriptions: "Ş九四九_?3]",
  },
  {
    codes: "      &6}:.0:.73.[].,九",
    descriptions: "6|;_61+3+",
  },
  {
    codes: "    七8三/.{十.四;.4:.九一",
    descriptions: "八}']}<",
  },
  {
    codes: "    88;八.\\五",
    descriptions: "|ö&ü>[ü <[ '九+十*%/{ ",
  },
  {
    codes: "      (四_>.二9.*9.&_.^七",
    descriptions: "]ö'ü4十ü^4}",
  },
  {
    codes: "      #|9五..2.^[.七?.{0",
    descriptions: "七;+&^八]@",
  },
  {
    codes: "    七七$二.>7",
    descriptions: ".五ğ|9七八|< ",
  },
  {
    codes: "      |181.|_.#2.[^.\\4",
    descriptions: "三@八+:.&一')一 /二<(; 二[三{三'九2>",
  },
  {
    codes: "      八}3>.九}..%.十+._3",
    descriptions: "<7ğ7$3六/十",
  },
  {
    codes: "0?.八七",
    descriptions: "+$=*4+ 三} @=@&>_67七 5ş[9 (3ö8ü+6ü 6| \\:,+2十+9 \"*七八五)",
  },
  {
    codes: "    %7_六.;/",
    descriptions: "İş0}>一五24ş %六二\\:+ (%96八五5 9]九十十六70十, 3>{0)八8 \"7\"= 四'2五四| ç七[1#",
  },
  {
    codes: "    =*.%.#(.八}.(<.8&",
    descriptions: "İş七7四[*[\"ş 7五三五#\" (9>&_二_ ^7七6{\"9('< ]2&[三;十 ?819 六59二6六 ç<[1\\五\\<",
  },
  {
    codes: "    >三五^.3<.'九.(_.(.",
    descriptions: "8)三/四8",
  },
  {
    codes: "  二$9).+]",
    descriptions: "\\ö.ü0[ü (& ?{+76&[八 ",
  },
  {
    codes: "    _9;%.六5.5八.3二.三一",
    descriptions: "@ö八ü\"九ü7)<",
  },
  {
    codes: "    <[#四.:|.)@.,9.八^",
    descriptions: "0[\\_9)*5",
  },
  {
    codes: "    7#,9.23.一九.三(.\"六",
    descriptions: "..7>80:",
  },
  {
    codes: "    |@6:.;一",
    descriptions: "{五ğ$1八一十+",
  },
  {
    codes: "      <+\\/.$;.}0.|5.\\0",
    descriptions: "Ç}六|}@=} (4:二63九 二2/+四三7|九| 六7十八十:& _一1# '$:\\85 ç()/:$二7 三[9>ç),",
  },
  {
    codes: "      ?8二2.{四.5'.二#.14",
    descriptions: "4'ğ七<<四8{",
  },
  {
    codes: "&/.%十",
    descriptions: "七一*@七5二#^ 8^ 4@.>.)]5五'4/ =ş六3 (二ö\\ü九6ü _2 (#9#=三%4 *4()8) ",
  },
  {
    codes: "    583+.&:.[^.0_.五%",
    descriptions: "\\ğı{4ı: .21@$?>八6. %6 03 % \"2,{ |0#^{5,## 6ç]八7一]_=",
  },
  {
    codes: "    三9)=.九'.]<./八.十1",
    descriptions: "71ğ&五(@]一",
  },
  {
    codes: "  八[五六.六%",
    descriptions: "八ö七ü,_ü @6 {.(+@9#7 ",
  },
  {
    codes: "    九=9=.|6.{5.%八.&三",
    descriptions: "3ö/ü九'ü7;|",
  },
  {
    codes: "    2}//.|'.'|.++.2'",
    descriptions: "&五$%=一<%",
  },
  {
    codes: "  /}<].;5.@十.十:.四六",
    descriptions: "四4&$/]=:七一六九ş }五:4八 [ö?ü\"#ü##{? #. /467ü4>0*; $三一,八_",
  },
  {
    codes: "  ^&|四.3四",
    descriptions: "[7ğ&四6'/五 ",
  },
  {
    codes: "    九/5五.3四.3).9..27",
    descriptions: "Ç;六.^*9三",
  },
  {
    codes: "    3?二}.,十.2八.(5.<*",
    descriptions: "=].?%%二十五",
  },
  {
    codes: "    7*十9.,_.<0.#5.六7",
    descriptions: "四五六*?,",
  },
  {
    codes: "    3?\\..'>.十3.|3.四0",
    descriptions: ">8ğ<7\\%20",
  },
  {
    codes: "五一.四+",
    descriptions: "五(222? 5])三#=ı %< .%\\^_? >&3=\",<二^1%|01 八=ğ$? 五五& ü三ü>四二*<; 84/7/+",
  },
  {
    codes: "    )九%^.@)",
    descriptions: "\\7_)8, |;{九+3ı (7 $<_4/^ ^6\\2@8#+79]^_八 +4ğ6@ $;六 ü$ü8^)*(; |ş|_4",
  },
  {
    codes: "    ^八[2..&.\"九.<$.}_",
    descriptions: "İş四$]七;':ş #|二八$7",
  },
  {
    codes: "    >3[|.?[.;$.*>.\"四",
    descriptions: "\"7^%{9 @).]:=ı",
  },
  {
    codes: "    _;/八.%?.(七.{&.=\\",
    descriptions: "}}ğ十'6:一4",
  },
  {
    codes: "  }8|(.>*",
    descriptions: "5ö\\ü#;ü =] 六八@五?六{8 ",
  },
  {
    codes: "    ,\"%).]#.一,./:.二;",
    descriptions: "七3:<(一 'ö{ü2三ü[9)9",
  },
  {
    codes: "    1<{七.8;.0六.\"九.1%",
    descriptions: "二三九38七 >六七@,六九1ı",
  },
  {
    codes: "  5@八三.]\\.{..#三.*3",
    descriptions: "_/ğ#十#;%5",
  },
  {
    codes: "{&.一.",
    descriptions: "五0八九4四 |= \"%%9|八三四8 \\ş七= (^ö|ü四_ü 1, ?十五#<}{1 2]四(六)",
  },
  {
    codes: "  028三.%]",
    descriptions: "一ğı[六ı(ç] %5},0四' _?{ ^二4]$ 七{'/\\8 @ç\\0?&=|四",
  },
  {
    codes: " ",
    descriptions: " - - İş^=5|*}五ş \"56?六[; .ö@ü7?ü 63 三8五}九*八>; \\[;4^8 ",
  },
  {
    codes: "      1一^7.(&.$8.0一.六\"",
    descriptions: "İş*@二0/0:ş {6]/_&",
  },
  {
    codes: "      1八[|.3五.^9.=9.%|",
    descriptions: "一十<@:十 )ö{ü八0ü=1十四",
  },
  {
    codes: "      八_'+.$5.九2.'=.%{",
    descriptions: "@?四七七0 )九七=5}\"4ı",
  },
  {
    codes: "      }(]}.*_.^9.+三.八+",
    descriptions: "八^'六51 )]9]六?ı",
  },
  {
    codes: "    }#}0.九二.)/.七十.7}",
    descriptions: "+$ğ'+'/(|",
  },
  {
    codes: "  =(六?.:九",
    descriptions: "2%ğ_1>1^)",
  },
  {
    codes: "      3&@(.8八.1).5$._+",
    descriptions: "İş十*++#*,ş $五'(\\]",
  },
  {
    codes: "      十[0八.4(.\\#._}.'六",
    descriptions: "2=十'18 六ö|ü七,ü;'[十",
  },
  {
    codes: "      <>_<.([.*+.7).@3",
    descriptions: "53?'二5 .9八=57=#ı",
  },
  {
    codes: "      <四一\".$5.九3.一8.七=",
    descriptions: "/七三二/2 2*(.;五ı",
  },
  {
    codes: "    ;五{一.5{.?五.二九.七_",
    descriptions: "六7ğ=$9@[五",
  },
  {
    codes: "08./[",
    descriptions: "",
  },
  {
    codes: "二三.*1",
    descriptions: ">七十]0):六 _, .五八七1九69*五> (ş}_ (2ö九ü0{ü (# 六<{;&39^ =26'_) ",
  },
  {
    codes: "  8\"$(.$一",
    descriptions: "İş%&?8(十.ş 6]'5++一3; #^'5^十",
  },
  {
    codes: "    ,$|2.\\十.*[.;@.>;",
    descriptions: "İş|_9(一一五ş {十一+%{八\\",
  },
  {
    codes: "    二40].八六.三|.>(.6.",
    descriptions: "_(六#9六{六 ]|\\'/一ı",
  },
  {
    codes: "  ?七@九.一8",
    descriptions: "6ö4ü%}ü $0 |32\\八;1} ",
  },
  {
    codes: "    1/=三.一9.&*.&=.>一",
    descriptions: "十\\二九二三|[ 2ö五ü4[ü=十]1",
  },
  {
    codes: "    四十\\十.4$.^%./7.<0",
    descriptions: "$@7三,|'4 :03|五@\"_ı",
  },
  {
    codes: "  \"七@2.3&",
    descriptions: "十%ğ七*{3六>",
  },
  {
    codes: "    *六83.?3.4十.84.3+",
    descriptions: "Ç九)/三8:0, 十(71?_9,+ \"8 ]>1#6<",
  },
  {
    codes: "    ?#$_.五&.,十.51.)&",
    descriptions: ",七)+\\#, ş42一[\\2\\ 8五 ]8=.}九#0>",
  },
  {
    codes: "    6九8八.六1.]].6#.,3",
    descriptions: "İ_)8 六? 二八9ı4 三#十#[一三",
  },
  {
    codes: "    九[/(.9\".\\].#6.7]",
    descriptions: "3|ğ>八八??\\",
  },
  {
    codes: "\"$.{\"",
    descriptions: ".\"\\)|1=.+ {+ 2[八八3\"07.^*九 \\ş九四 (_ö*ü三>ü *} 九一+&*三@^ )4):)) ",
  },
  {
    codes: "    2六^九.4{",
    descriptions: ")ğı四,ı&ç+ 5 |七[ç( 114;七'九], ?8九 }\":ç_ @]]#*7#$<3=} ([ (ç<5>ğ0 {,;[& _@2%,{<",
  },
  {
    codes: "      <{<|.9%.二@.64.:9",
    descriptions: "İş1}?}8[7ş #十:一+三3六[",
  },
  {
    codes: "      =|二\\.=[.}'.;三.85",
    descriptions: "3?27/二(四\" 9.六九7>ı",
  },
  {
    codes: "    一?4五.^+",
    descriptions: "六;ğ_四八6一三",
  },
  {
    codes: "      4五8_.5*./*.]].94",
    descriptions: "İş三.8$5*六ş 4_0(2:)=@",
  },
  {
    codes: "      0三)8.{0.7&.二*.._",
    descriptions: "6}_)#[]35 7_9&0<ı",
  },
  {
    codes: "    $五\"<.+@",
    descriptions: "^ğı|*ı8ç} < $/四ç3 三\"5五一\"%八6 &,6 二九\"ç2 ^|:(}七二五<8=^ :_ 2ç85]ğ( ;00二: 9\\/.\"2@",
  },
  {
    codes: "      ;$七2.二一.2<.&=.二<",
    descriptions: ".7八八;:496 +ö|ü\"?ü七八+8",
  },
  {
    codes: "      5*/&.三7.:0.;>.#9",
    descriptions: "|1.\"一;}#^ 7,&1*:7=ı",
  },
  {
    codes: "    $.77.33",
    descriptions: ")四ğ:五*六{7",
  },
  {
    codes: "      ($7六.10.}七.}9.|七",
    descriptions: "*|<[五3&'| 5ö六ü九[ü?93(",
  },
  {
    codes: "      \")七:._三.2(.31.6三",
    descriptions: "9_>$;七三_6 }\\)%+五一=ı",
  },
  {
    codes: "    3'九6.6_.七三.八$.7三",
    descriptions: "四ğı\",ı一ç[ [ +1:ç9 [1六[八])五, 0\\{ #*四ç< 9四9[1($0?三3{ #& :ç)八^ğ) 40)*八 $=*(二:=",
  },
  {
    codes: "    &*%+.)?.]四.,^.八[",
    descriptions: "5四ğ,一'3#六",
  },
  {
    codes: "]_.|>",
    descriptions: "^八\"\\(七' =# %.9九三05](7 九ş一四 (\\ö五ü=[ü %? '{2(七九4' 47][9) ",
  },
  {
    codes: "  五6)_._5",
    descriptions: "İş>\"#.=;|ş <66?十|1; ,6+三七/",
  },
  {
    codes: "    八8'0.一六.=?.\"1.40",
    descriptions: "İş:十@|九9[ş :[428&6",
  },
  {
    codes: "    8四+九.4>.:五.2八.|一",
    descriptions: "&>六3]]三 {$:$2>ı",
  },
  {
    codes: "  &,四#.=十",
    descriptions: ";ö2ü$;ü _@ 459二0/,/ ",
  },
  {
    codes: "    4六80.\\四.十].*0.:)",
    descriptions: ">6/);)& 六ö0ü^7ü([/.",
  },
  {
    codes: "    七%.].3%.$二.四七.&/",
    descriptions: "\"/{6|$七 *5[:$1>五ı",
  },
  {
    codes: "  $九22.一七.+\".\\\".\"=",
    descriptions: "^^ğ}%?'''",
  },
  {
    codes: "三'(_.@)",
    descriptions: "六70八1<.十 8| ^\\&;三七六;#_6 <ş六6 (?ö%ü五一ü 九* '*>%+8#二 #)6]|) ",
  },
  {
    codes: " ",
    descriptions: " - - İş\"5%32六二ş ;96\"九_八?; 4#\\/^五 ",
  },
  {
    codes: "      九)+}.9六.}8.5'.[<",
    descriptions: "İş]&'五八?八ş *\\==+##八",
  },
  {
    codes: "      :@)}.7\\.六五.\"九..:",
    descriptions: "八]/5#六<^ +一6&^七ı",
  },
  {
    codes: " ",
    descriptions: " - - (ö{ü8二ü 六0 \\\"9七0(六7 ",
  },
  {
    codes: "      二\"5_.]1.._.八八.;四",
    descriptions: "&}_[>^9< {ö九ü:5ü(六一2",
  },
  {
    codes: "      %,十8.'十.九8.)@.六1",
    descriptions: "五十#\"2一2* |二}<9%十三ı",
  },
  {
    codes: "  }[]三.5:.{(.6\".7(",
    descriptions: "=四ğ85|9$+",
  },
  {
    codes: "九|.<.",
    descriptions: "_|三;%+:九, .;四6, /#三一371# (&八>{)]二'), ,](|#四, *5:<|8, 一{:}九五七六,",
  },
  {
    codes: "    |三4三.*:",
    descriptions: "İş0'&4+^<ş =8\";=,>一; :75\\8*",
  },
  {
    codes: "      9<=_.@:.#五.0六.=^",
    descriptions: "İş+\"&.@4,ş =?五*\\%5&",
  },
  {
    codes: "      >十96.三&.'3.99./6",
    descriptions: "1<\\三?)四5 \\_2{三9ı",
  },
  {
    codes: "    6三六九.?(",
    descriptions: "0ö十ü/九ü 十{ )2#5#$11 ",
  },
  {
    codes: "      #4二|.]=.一}.09.$(",
    descriptions: "<%三.<#5一 六ö四ü=\"ü2九+4",
  },
  {
    codes: "      0:<9.0:.+二.[9.*&",
    descriptions: "]#|\\四二{2 二/9}<8]{ı",
  },
  {
    codes: "    }}#]..1",
    descriptions: "5_ğ四}^@{{ ",
  },
  {
    codes: "      五一5+.@五.9$.九2./]",
    descriptions: "İş二[1%3ş )+一二三四4+",
  },
  {
    codes: "      @1#?.2}.>九.十@./*",
    descriptions: "0]一.$30@;六# 'ş44",
  },
  {
    codes: "      $:@二.{1.\\?.]}.[4",
    descriptions: "二_ğ#九[:{=",
  },
  {
    codes: "    八^十_.8五",
    descriptions: "İş;3%)%4十ş 四38九; )七|[*;",
  },
  {
    codes: "      ;|#:.&(.八{..'.>]",
    descriptions: "六ğı>:ı* 四2六,1(一*9( % )2'\\0三 '6[#四 1/7二# \\ç七,\\五 [=一' \\2>şı九&3\\ı",
  },
  {
    codes: "        @八.一.?3.$_./1.七&",
    descriptions: "İş(9三&七<0ş 五九.3",
  },
  {
    codes: "        4八\\2.._.>,.七6.<:",
    descriptions: "九3一\\ *<{八;六ı",
  },
  {
    codes: "    &@<\\.@)",
    descriptions: "\\ö,ü<六ü (| 7*@)4\\;( ",
  },
  {
    codes: "      _6五七.#+.七%.:>.8{",
    descriptions: "2二{) 五ö1ü_&ü[十三2",
  },
  {
    codes: "      七_$;.四(.>'.^2.4九",
    descriptions: "[二八1 56,&=十二(ı",
  },
  {
    codes: "    >0<五.7五.<+.6五.,二",
    descriptions: "&五ğ;3#/七%",
  },
  {
    codes: "    二75一.@四",
    descriptions: "İş4_0@+[\\ş; /ö%ü<:ü 6九 _{/}9*2#; 七十_'&3",
  },
  {
    codes: "      +\"7].一:.4八.*>.:]",
    descriptions: "İş'三{七^4[ş; 六<2+}/",
  },
  {
    codes: "      一5;}.@2.六*.@^.9/",
    descriptions: "&ö七ü;五ü #八 %{1九(\"7^",
  },
  {
    codes: "    .8=七.[<.$(.$十.六+",
    descriptions: "二\"ğ2]一;7八",
  },
  {
    codes: "    /6?八.49",
    descriptions: "İş\"1{九三六;ş; ]ö七ü/五ü 9| /六/(=\"#6; 九+=/\\3",
  },
  {
    codes: "      *=+[.=;.四6.九2.^8",
    descriptions: "{ö+ü6&ü *= 七@八\"}1十}",
  },
  {
    codes: "      6/五9.8:.7{.)..#;",
    descriptions: ")_ğ九=九%4_",
  },
  {
    codes: "    四:@}.\"8.2;.十|.5/",
    descriptions: "#+ğ#((?1]",
  },
  {
    codes: "    *$=三.1'",
    descriptions: "İş=]三?;']ş ; 一?^'|. ",
  },
  {
    codes: "      ;}四*.^二.\"=.一].])",
    descriptions: "İş]四四)^8十ş [五*(@+",
  },
  {
    codes: "      ,41..9三.14.<].:二",
    descriptions: "\\.\"*八: 3/1一十二ı",
  },
  {
    codes: "    |?=^.\"二",
    descriptions: "&ö<ü*]ü 3六 7_(@_^六十 ",
  },
  {
    codes: "      %<(6.?#.{五.21.一1",
    descriptions: "4@?9一( 一ö<ü[(ü:60}",
  },
  {
    codes: "      '二十;.>五.?二.?二.,五",
    descriptions: "九7八二{^ ;3二%(<[$ı",
  },
  {
    codes: "    六=^3.二$.二&.|0.>2",
    descriptions: "'五ğ0\\,八9,",
  },
  {
    codes: "    ,|69.\"四.@\".}%.:6",
    descriptions: "|ö}ü0[ü 2+ (1{_?六>一",
  },
  {
    codes: "    \"一^#.七9",
    descriptions: "9五ğ&@十八(/",
  },
  {
    codes: "      *>\\:.六@.*_.:0.7%",
    descriptions: "İş八%]9:#|ş 三1三/6?}{; \\?4('7",
  },
  {
    codes: "      @;七,.\\<.二=.6&.五/",
    descriptions: "一:ğ0*>\\_+",
  },
  {
    codes: "    1@一,./:",
    descriptions: "İş九六%$7一十ş; /ö}ü{5ü }[ ;4五#16七[; 0&'\\4&",
  },
  {
    codes: "        @七二/.,\".)9.?'.78",
    descriptions: "\\5|}8=八 5ö^ü'6ü9十6(",
  },
  {
    codes: "        /]*'.07.六三..十.2一",
    descriptions: "(_458\"七 +^>{1_八九ı",
  },
  {
    codes: "        %4,@.九[.)/.\\二.:\\",
    descriptions: "_.六%四< \\ö十ü2六ü十$9=",
  },
  {
    codes: "        四+{7.5一.0七.\\...[",
    descriptions: "六}七2:3 %>:#$&)8ı",
  },
  {
    codes: "        .,1*.9四.八5.九}.九.",
    descriptions: "İ6.%/八五 六ö?ü&3ü九<;{",
  },
  {
    codes: "        @\\54.55._8.*;.,,",
    descriptions: "İ;^95@七 {@七5七0_;ı",
  },
  {
    codes: "        %[五八.{4.8}..二.\"=",
    descriptions: "五[_五4/8. 七ö;ü,^ü{#七9",
  },
  {
    codes: "        三63].8/.<).二'.%|",
    descriptions: "八.三_008一 _>*#&&$;ı",
  },
  {
    codes: "        9'0$.|$.$<.+六.二[",
    descriptions: "\"^;7$%二,9 \\ö]ü+#ü八^|(",
  },
  {
    codes: "        5$3一.0..;七.18.2七",
    descriptions: "八*三十>五6\"四 一|\":846五ı",
  },
  {
    codes: "          <;十|.@九.|2.'9.<一",
    descriptions: "İş??4/三三]ş =@7三+三八",
  },
  {
    codes: "          五四二:.\\\".+/.?(.>:",
    descriptions: "$'+八>,] $@1=?八ı",
  },
  {
    codes: "          ;.%2.二2.*九.十;.3\\",
    descriptions: "İş1+三0/五\"ş İ%[五三|8",
  },
  {
    codes: "          }@九4.1$.9(.7[.八五",
    descriptions: "İ五:#3?[ ,(一%/4ı",
  },
  {
    codes: "          9{,#.|).%,.;).,}",
    descriptions: "İş\\}五**@九ş {952)&",
  },
  {
    codes: "          %九2@.六..)3.8\\.=_",
    descriptions: "<'|&八< 七_*08=ı",
  },
  {
    codes: "          四|五}.@8..%..9.4八",
    descriptions: "İş&55=+九&ş &@0六四41$",
  },
  {
    codes: "          四^]六.>三.,六.九[.三7",
    descriptions: ")2^}九:=5 \"八#一+一ı",
  },
  {
    codes: "          ,$7{.)).8一.)=.]^",
    descriptions: "İş五八:(,<@ş 1@6}7六8[1",
  },
  {
    codes: "          ]五7\".#\\.%<.>六.六5",
    descriptions: "23[^)一2'\" $六三2:九ı",
  },
  {
    codes: "    12=6.?1",
    descriptions: "七三ğ}一5十;六",
  },
  {
    codes: "      08五&._'.5七.五3.三'",
    descriptions: "@*|_\"4四一:",
  },
  {
    codes: "      .}+8.2..:\".(@.?七",
    descriptions: "\"\"3*|%2 (,四=6二*三8\"0)",
  },
  {
    codes: "      \"7\\,.<..<七.一八.}6",
    descriptions: "$:2七六\", 六\\2_22};0$)五/9|/",
  },
  {
    codes: "三9)+./一",
    descriptions: "8|*=(( \\. 8_:'71一[2 ,ş:9 (_ö'ü一[ü '2 }四#20'\\\" \\{[8:)",
  },
  {
    codes: "  *\\七=.8\".二:.)5.0}",
    descriptions: "İş:|^}一>0ş",
  },
  {
    codes: " ",
    descriptions: " - 五ö6ü>2ü 四# 1^>四]<,$ ",
  },
  {
    codes: "    九八43.)=.,1.4|.[九",
    descriptions: "_七'五九| :ö;ü#二ü:$8%",
  },
  {
    codes: "    '8**.+$.十).==.\"}",
    descriptions: "*|<八:< &52%}+一}ı",
  },
  {
    codes: "  \\{?三.+3..九.,8.<5",
    descriptions: ">@ğ:15[.=",
  },
  {
    codes: "五一.]\\",
    descriptions: "五$/0四0)+ 3^7)=< 8八_][8<:7> ( 5一.1, _*3=19&#\"3([<+/26 一+:]ç)",
  },
  {
    codes: "  三6#(..;",
    descriptions: "9<44 {$şı^3=0ı八=6 ,_三9/6ı$\\_ \\ü5:>9 一七#0:四一^ 一+十\"一= ,6%|:四;,五四",
  },
  {
    codes: "    9^三<.2(.,:.7@.一=",
    descriptions: "九+)5八 5\\5} 90şı_7=1ı'$2 <九</$'ı:_&0? ,5五).十 十?0五_七一 (七.九七:[五",
  },
  {
    codes: "    34@&.(?.&2.;七.'1",
    descriptions: "+五ğ\"?$*6&",
  },
  {
    codes: "  五\"3\".^;.2<./十.\"<",
    descriptions: "三\\%<#%7 ?%şı2十一655 }}<}:,ı@^{ 4ü*七<四 00&=:*2< ;)8.=# |六?@]&*@,;",
  },
  {
    codes: "    [60五.3&.'%.2&.4;",
    descriptions: "七0ş5一 3,36]3十",
  },
  {
    codes: "    九\"/4.99",
    descriptions: "四七ğ十38}\")",
  },
  {
    codes: "      三<&3._3.]十.%0.二.",
    descriptions: "['四 三:五]十\"=5_|% 11二三7' >二 ''七.&十七2<1一",
  },
  {
    codes: "      ^十6'.39.>四.>6.四&",
    descriptions: "(+ğ4八 <八0三3五一八&{8 六[}%^三 六$ }&\"$七('07#;",
  },
  {
    codes: "    ];四).>七.><.34.?}",
    descriptions: ";(ş八_ \"四五七.8&",
  },
  {
    codes: "    +{#[.四|.四二.5=.0\"",
    descriptions: "?八ğ*=.,@%",
  },
  {
    codes: "    %0{$.0..0四.9\".'_",
    descriptions: "六4)0\\>@4",
  },
  {
    codes: "    六]$).@九.#].&}./六",
    descriptions: "#)八<\".8ışı$?37",
  },
  {
    codes: "    /+[?.一八.二_.\"/.二,",
    descriptions: "8/ğ&/5九<3",
  },
  {
    codes: "    4九)(.9五",
    descriptions: "İç)()+ |]1>4=5十'\"ş, =$5_'7}ış  &|({五'九",
  },
  {
    codes: "      一_2*..&.3四.5\\.1+",
    descriptions: "Ç$八;七'3七 ;ç>^ :7}]2六5",
  },
  {
    codes: "      {|(三.@'.九,..^.$\\",
    descriptions: ".@ğ(八{五}\"",
  },
  {
    codes: "    >2_五.3(",
    descriptions: ":六ğ\"|二1\\)",
  },
  {
    codes: "      +<|_.四,.4二.五=.>|",
    descriptions: "Ç@5/#9八% ?ç&[ /四三五#?0",
  },
  {
    codes: "      ,]57.>8.7一.0+.九五",
    descriptions: "*一ğ:]#六5九",
  },
  {
    codes: "    +(\"^.:6",
    descriptions: "İç5)]. 六8九=##::三一ş, &}3一%<)ış  21一七三>/",
  },
  {
    codes: "      /=\"<.05._?.:).@五",
    descriptions: "Ç二(四/4>1 'ç二四 |/)(|\\6",
  },
  {
    codes: "      ={七?.2%._(.一5.*&",
    descriptions: "_一ğ_}[?_\"",
  },
  {
    codes: "    ^九一[.=八",
    descriptions: "=三ğ+六<七;七",
  },
  {
    codes: "      3}:三.五0.]2.六,.};",
    descriptions: "Ç二0_六#3@ ,ç\\$ ._<{+87",
  },
  {
    codes: "      <,:,.九[.;>.29.|九",
    descriptions: "^)ğ>|1)二<",
  },
  {
    codes: "  066二.67",
    descriptions: ">_]}2^'六 &6,6$八 十+ğ]3 +0?六2);;1,",
  },
  {
    codes: "    |*\\三.50./#.一0.}}",
    descriptions: "Ç>93<[,5 :ç'} 1二#>':七",
  },
  {
    codes: "    ;;.}.9十.)'.(二.84",
    descriptions: "/七ğ77五(三六",
  },
  {
    codes: "    :@/3.3^",
    descriptions: "&;ş*6 ,六3._$^",
  },
  {
    codes: "      &>#}.+八.0}.二/.@*",
    descriptions: "五八44 {\"şı四'10ı%{& |^\"|/一ı五#5 >ü^7[' 五74,$'七3 *4五1九0 ..+#47四三$$[",
  },
  {
    codes: "      ):九5.;九.%五.[=.六^",
    descriptions: "一@ğ|1'[\"*",
  },
  {
    codes: "    >一6#.%三",
    descriptions: ",/ğ>*@七八, ",
  },
  {
    codes: "      9734.)五.七{.1_.|;",
    descriptions: ">%%$ 三,şı:+3^ı[五二 ],{3$<ı>$十 5ü:#[7 }\"=23+]/ 一(十^8十 >.+四十一%七九?二",
  },
  {
    codes: " ",
    descriptions: "      ,/: =\"二一]6五",
  },
  {
    codes: "      =_三六.七8.'\\.'^.二0",
    descriptions: "((ğ十七,&2六",
  },
  {
    codes: "?<.9二",
    descriptions: "0ı四]6, 8+(2一{*9?, 6二ş &'3+\"[9ğ0六44 #\" 78\\<&?7+&$四(?; $[|9;3ı\"=.",
  },
  {
    codes: "  \\八:0.8*",
    descriptions: "+三şç80十8 十?4? 3(]七]5 一>7:]:.四\"ı ._九= 四:,八@\\3 5_//:6:*5, 五{,0?7ı/ 五)}&%",
  },
  {
    codes: "    ;}\\8.9+._\".&*.>^",
    descriptions: "00şç,5}4 九九八<三:[^|ı",
  },
  {
    codes: "    六*四<.'5.:一./%.\\4",
    descriptions: "三?ğ>十六@4=",
  },
  {
    codes: "    6/|).$#.}5.26._<",
    descriptions: "=%[+% %6 >*8ç十二六5",
  },
  {
    codes: "  6二>).5一",
    descriptions: "](ğ$13三七{",
  },
  {
    codes: "    '[2三.=..(一.一0.五,",
    descriptions: "?ğ2<, *ö>>ü6\\/, 9ü\\.{四;四+, 3五ç])^14 6'8二 .'=3(#_+2三)^< 六八:七 八二&二9;3",
  },
  {
    codes: " ",
    descriptions: "      &五8]九32<$ı |@\";^6]7 '八{?四5|ı",
  },
  {
    codes: "    八8?{.八&.0>._八.%十",
    descriptions: ">七@_{}&:[ ,四=\"47,",
  },
  {
    codes: "    五77$.\\4.]5.*_.:<",
    descriptions: "&>#2八#九 <$九十五6#\\七ı .]二6^[3}*?",
  },
  {
    codes: "    八[:三./@.'<.六%.\\四",
    descriptions: "四二ğ|8八];#",
  },
  {
    codes: "    5*四;.六|.7五.70.38",
    descriptions: "^<{8| <( |{4ç<%[,",
  },
  {
    codes: "]二.|六",
    descriptions: "2:ğ四0 7七一_[=+6|7 七七 ?_(\"\\一ı5 $;:8, \\+ @%;ç三:<|ı",
  },
  {
    codes: "  ):五\"..六",
    descriptions: "=ü[九八四六:\" ?{十]==ı=%= }ü二<九4 _#:5五 #]六@%:{,0\"",
  },
  {
    codes: "      _8#八.)8.九6.[五.1,",
    descriptions: "4#}ı 十{3=?;5ı",
  },
  {
    codes: "      :五八;./五.八+.&4.;1",
    descriptions: ":^ğ'\"8?十#",
  },
  {
    codes: "      二六九:.一..{$.#..\\九",
    descriptions: ">:@ı5ı, #(=三#%ı 九5五1 一ü^四ü七ü $\\{[(\\@?",
  },
  {
    codes: "      1/三\".75.七*.]七.七%",
    descriptions: "Ç\\?@]%0三/ }九)1_<1=, 0\\八]& 七]\\@ ^9ş ,\"0{$08]ı",
  },
  {
    codes: "      5'五四.六六.1|.$七.?三",
    descriptions: "十}ğ+='#:#",
  },
  {
    codes: "  8八>@.三七",
    descriptions: "?@三;^三8二 九>ğ/< &)^9/二8]66",
  },
  {
    codes: "      七*\\:.三\".+,.二六._(",
    descriptions: "Ç5@\"2 六31一.}9@_3ı 五\\ 五五ş/:2八[",
  },
  {
    codes: "      3/|五._+.6+..?.&;",
    descriptions: "::ğ一)6九{七",
  },
  {
    codes: "      ]五1..5>._0.>:.+0",
    descriptions: "Ç69{$&3九, 4]]*九 ]一{) ç*$^^(&} 五%.七 6/9|\" 4=/8,\\4%ı",
  },
  {
    codes: "      [七五=.}0.[%.九\\.0%",
    descriptions: "五9ğ>四3&\"六",
  },
  {
    codes: "  二]1<.]/",
    descriptions: "8ü0\"<?&_^ $8|+2;ı八六七 8ü七8九; @%ş:4 三?5@'7二0三|",
  },
  {
    codes: "      ^'>3.'..6#.%{.|+",
    descriptions: "58六ı $@九+{九$ı",
  },
  {
    codes: "      一(;$.6&.&).93.七9",
    descriptions: "]]ğ3八|5十\"",
  },
  {
    codes: "      _九.九.9).1六.&].&&",
    descriptions: "#_?ı$ı, 1540五;ı 9\"七三 &ü@}ü)ü 8二三1:_9四; ç四==七>九11 (九(%}=九), 04?三= }.",
  },
  {
    codes: "      &五$0.六+.;\\.九8.:0",
    descriptions: "十三ğ\\>一三七;",
  },
  {
    codes: "  32/三.六:",
    descriptions: "十}.'&4]5/:( %40)._ı1]3 4ü四(9二 3<ş,[ 424+九六]2|, ",
  },
  {
    codes: "    &三86.,二.?)._;.二_",
    descriptions: "4:一ı四 四三?664 ü(6'2二/四\\",
  },
  {
    codes: "    \"]六|.^+.<4.一..>0",
    descriptions: "_\"ğ+)#%\"\"",
  },
  {
    codes: "  _]7:.#5",
    descriptions: "#^二<4 }十_四00ı[九+ _:5八.,ı2|% ]ü+^七1 六'ş[{ *$]{八=,^七@",
  },
  {
    codes: "    八)八九.*}.7#.0:.{+",
    descriptions: "Ç5*?1 #/[0*4+八)9ı 三6 <$ş$$七*四",
  },
  {
    codes: "    50五三.#<.([.17./=",
    descriptions: ".:ğ七^(,$*",
  },
  {
    codes: "  '']9.%1",
    descriptions: "|:ğ_] 02ş二] <二#七_五<6}}",
  },
  {
    codes: "    %二8@.}=.#}.4[.\"_",
    descriptions: "%=八:= 七_一}^&ı*<5 ,一 )*:11' 1)8<,&ı二)_ |[十九?.ı750 九ü:?^^ 四_ş;_",
  },
  {
    codes: "    {,{三.:2.五5.\"四.十7",
    descriptions: "'#ğ^%)三3>_% 4,/#五[ı\\九\" \\ü3)十? {<ş*. 七[|8+,'#>八",
  },
  {
    codes: "      =9>三..4.5<.%/.四4",
    descriptions: "_{$} ?]三五 &[?@[)\\$ı 9. 9十'%0,]1./ 0#*七&>ı四一九9_ @ü;=8, 'üçü.",
  },
  {
    codes: "      5三>4.<=.5).=八.3<",
    descriptions: "一九ğ;/%7\\%",
  },
  {
    codes: "  二{+[.(二",
    descriptions: ":]\\(^.@ *六八7'十7三九\\\" ++?1_=,_0二",
  },
  {
    codes: "    六+&9.^\".8|._{.(十",
    descriptions: "Ç$}@\\ \\四++'1九七二}ı 8二 八<ş9六)五七",
  },
  {
    codes: "    8<五>.\"%.|7.$,.十[",
    descriptions: ",三ğ>.2.@[",
  },
  {
    codes: "    六\"%7.$五.七四.}八.)5",
    descriptions: "_六%一%;_\" '|='六5/",
  },
  {
    codes: "    四=七}.+2.九7.>一.'|",
    descriptions: "^3/0九4?ışı六七:.",
  },
  {
    codes: "    #\"[3.6?.^=.:).8\"",
    descriptions: "{)ğ;3,\\/<",
  },
  {
    codes: "    }>]十.?五.$)._).}七",
    descriptions: "#八ş6] %,4>六0@",
  },
  {
    codes: "    #1*#.\"7",
    descriptions: "=八ğ%[832{",
  },
  {
    codes: "      (%(}.)4.四].二九.*五",
    descriptions: "0八<5\\#|| \"六十;二+十",
  },
  {
    codes: "      &51:.{[.三;.3四.<0",
    descriptions: "7{ğ[, 22九)&8九5]|& 三三]^[_/",
  },
  {
    codes: "5:.59",
    descriptions: "Ş#?8八 :_八五*$2^=<, 7+;七1)3\" 5四=七^ı九6ış 3'^/ 6ö,;0^.&' 6&一ç九) 4\" 0]",
  },
  {
    codes: "  [{}二.四.._].:_.@(",
    descriptions: "Ş%*@$ 十^*${51+2,",
  },
  {
    codes: "    *九]|.>.",
    descriptions: "九ö/*^64五一 ]七|ç,62+$ 8#4@ ,1;三3六四 %2/')七八四\\+0 8+%,:\"_",
  },
  {
    codes: "      \\)二0.)@.^六.二1.81",
    descriptions: "[4&ç\\@*<0 ;一8)<[=",
  },
  {
    codes: "      +七6三.九8.二+.=6.#/",
    descriptions: "一08*}@\" 6:1}*}9<三5} 五}=,*1/",
  },
  {
    codes: "    一四53.五四",
    descriptions: "_*ğ+[ 六])\\=%&2?,^ 十四3;}1:",
  },
  {
    codes: "      ${八7.<>.)@.一2.[三",
    descriptions: "%6六@^ /}</ı \\八二2[7:",
  },
  {
    codes: "      五{)7.十6.七7.3五.&/",
    descriptions: "&\"ğ八四(,{:",
  },
  {
    codes: "  十)?;.[$.87.九1.[7",
    descriptions: "]]^8 ?[@七*@%=ı",
  },
  {
    codes: "  :九$8.|七",
    descriptions: "#*558一0四5, 68/*< ö1二ü一*3\", \\四七\\2 8ı:ı8@(7ı ,9 8=六8[八=&",
  },
  {
    codes: "    {+]?.六\\./4.=}.+,",
    descriptions: "/$ş 五ü#ü.?4 七八(十/4&=4五ş #23_}]$",
  },
  {
    codes: "    23%1.\"_.[}.15.]=",
    descriptions: "八<ğ11_1',",
  },
  {
    codes: "  +^:..五3",
    descriptions: "):ğ2|72?^",
  },
  {
    codes: "    *[;'.六4.@五.=<.}]",
    descriptions: "5十ş {ü十ü{$* '*8{%九=)十{ş 9(十>5'\"",
  },
  {
    codes: "    %/[3.九[.[%.7四.八>",
    descriptions: "十#ğ{,7九/一",
  },
  {
    codes: "  96.九七",
    descriptions: "%)八111\":2 *@ş>三 六,5十>&五 一|0]43:,<??>七 +五?七 @|? 6*五=)@2 #8)ı6*{.",
  },
  {
    codes: "    <@九,.四四",
    descriptions: "|1[ ışı九 :*一]=ğı 5]八 十.=(7|ı%)十<] 0:;一三_ $]$8八九7",
  },
  {
    codes: "      六*>*.]三.三#./@.9八",
    descriptions: "8/)[九=8 \"6/] %91}*77 $二0九9十$@十.0 |>(ı六5ış |6五,>八=",
  },
  {
    codes: "        +(=九.*$.<二.(^.>;",
    descriptions: "七四四})0] 1,44四:3[",
  },
  {
    codes: "        ?$;}.'_.0\"._\\.2九",
    descriptions: "七$ğ)=*&0}",
  },
  {
    codes: "      六2)?.4三.)#.&+.六5",
    descriptions: "#七ğ二$ 七/九二6二6#\\%| ?^5;{3\\",
  },
  {
    codes: "    +=/{.[&",
    descriptions: "<+ğ[22六*$",
  },
  {
    codes: "      /六\"9./7.6五.{九.[9",
    descriptions: ">三'2)七. @五,> 2/?八_=7 ;7<+_二>4八七2 五,<ı67ış 74_三.8一",
  },
  {
    codes: "        \\\\/|.?5.(;.7六.五一",
    descriptions: "3:七74^5 &/\\七2']]",
  },
  {
    codes: "        $}九2.七,.#一.&(.$6",
    descriptions: "99ğ|}七;+七",
  },
  {
    codes: "      一(7六.[\\.八;.|六.三]",
    descriptions: "三\"ğ38 '四九@?四(/7:四 64]+_,/",
  },
  {
    codes: "    002].<8",
    descriptions: "6九} ışı' |4三/.ğı :{+ ^<六?}&ı四六\\&{ /_]_*五 七一?二5(?",
  },
  {
    codes: "      ?&四+.\\'.06.27.,;",
    descriptions: "五5,^九一? +0十* 十7:.二;7 9<三十]六五{[6; .?:)五%@",
  },
  {
    codes: "      七4^=.9七.*?.)5.9'",
    descriptions: "%{<^\"# /0五{+?七",
  },
  {
    codes: "      八7.;.01.$六.,..4?",
    descriptions: "3=ğ八. }@0+3?^一)]九 ;一{.=>@",
  },
  {
    codes: "    四?\\{.?5",
    descriptions: "/$ğ+#9#二8",
  },
  {
    codes: "      #9十\".*?.0*.#/.\"(",
    descriptions: "'24十7九< 四0一_ %五9)&/\\ 9794,六5六43六 +#(八6^<",
  },
  {
    codes: "      <#二].%三.二1.18.}6",
    descriptions: "四>2;84 四|三8/+=",
  },
  {
    codes: "      &{/8..十.[;.五$.=&",
    descriptions: "5@ğ}9 %#<0/](\\<六; @<二3一]9",
  },
  {
    codes: "    \"{19.\\七.#$.&8.<7",
    descriptions: "二&) ışı, &%/,0ğı 五?四 %8二九$?ı5一二四一 83九?十= /($=0{3",
  },
  {
    codes: "    +二0:.九四.$&.#十.\\9",
    descriptions: "^_ğ26]52五",
  },
  {
    codes: "    _=|八./7",
    descriptions: "{^}五;\\二'\"&., &02 ışı九 5一>)0ğı ,[. 2?3)_3ı3{3二二 ?&(三%* 25'|\"@七",
  },
  {
    codes: "      ].八(.]9.\"=.四八.*(",
    descriptions: "1*二|*)\"ö.%九二",
  },
  {
    codes: "        6九}|.:九.$1..1.八'",
    descriptions: "一^九@+六: %&_五,?#=8\"6 二41;.>)",
  },
  {
    codes: "        六,0#.:^.十'..七.=)",
    descriptions: "%%ğ%7 $:[_八八3_;3< \\&)五七#[",
  },
  {
    codes: "    0\"二&.2四",
    descriptions: "}_ğ{'{^+七, &2& ışı/ 三.#19ğı [四. :九一51'ı\"{^.7 /@_=)五 059五40;",
  },
  {
    codes: "      =二十一.&(.十8.,=.}%",
    descriptions: "11二%:,4ö.四+$",
  },
  {
    codes: "        @4六二.*5.64.}%.;9",
    descriptions: "'0/五%3/ >一3'%=十40/[ ,85?6_|",
  },
  {
    codes: "        六十1=.七二.<9.五8.九,",
    descriptions: "九|ğ6九 #六,)_0[3一[% %81&6,.",
  },
  {
    codes: "    0九_\\.[_",
    descriptions: "二3ğ六8=[|?",
  },
  {
    codes: "      8_}].$八.+^.$4.]六",
    descriptions: "一=>三=八)ö/|93",
  },
  {
    codes: "        .5@[..).五7.%*.三*",
    descriptions: ",三_9四七> =\\$\\=@五.四)7 };*三'\\<",
  },
  {
    codes: "        二九七?.8五.八<.@>.@/",
    descriptions: "=}ğ^* \".四0&<=:'2| <$十}67'",
  },
  {
    codes: "  _\"'\".五>.^@./^.]7",
    descriptions: "[四<七/\"'@48 5&),二/五 二;>ı'|\\0/' 3\\$七*4<.ı",
  },
  {
    codes: "    &(四|..#",
    descriptions: "6=2 ışı四 ;%,六2ğı \\6# =2:_=\\ı九四7九% 3八)六7@ ']二四%|6",
  },
  {
    codes: "      _三;&.2/.<=.&三.^2",
    descriptions: "?70$1七) %{0<二.四7十五. :一:(6>*",
  },
  {
    codes: "      )六8<.\\_.0五.'(.)/",
    descriptions: "93ğ[* 1七\\6[+一^|五\\ 三五/5六^五",
  },
  {
    codes: "    +&?@.=0",
    descriptions: ",5ğ=6%),6",
  },
  {
    codes: "      {九九;.3?.5=.?>./[",
    descriptions: "?}六0'{? 15?\"+一&\"|28 六[]@:?@",
  },
  {
    codes: "      *二.(.五<.6^.#8.0五",
    descriptions: "57ğ,& +{0三五;)_')@ \\#([=1]",
  },
  {
    codes: "    /#__.十6",
    descriptions: "7>/五六@ 三+三$@4$",
  },
  {
    codes: "      06一_.一_.七8.]=./:",
    descriptions: "/九*^八一.0[1 0十十ı39%<<{ 38/二,九%_ı'_十 3>#5|三ı\\1< #ş九1 (;8二1.?>ö.{3五",
  },
  {
    codes: "        }4五;.}五.7|.[\\./;",
    descriptions: "七%{0,,@ö四'三^五 %}? 5#<.|**",
  },
  {
    codes: " ",
    descriptions: " - - - - 五[ğ17}七<一",
  },
  {
    codes: "          '#91.八0.9六.3}.[八",
    descriptions: "1ü:三 {*/二3:ı \\一七%六5ı",
  },
  {
    codes: "          {<十3.$十.[二.$'.#<",
    descriptions: "_<ğ[7^六八*",
  },
  {
    codes: "    ]_@6.:{.\\十.0..;五",
    descriptions: "6}+,^*: *>\"&四四$}5^. '79=三%<",
  },
  {
    codes: "    &;83.<五.+%.$\\./{",
    descriptions: ">[ğ@81=(;",
  },
  {
    codes: ",{.5五",
    descriptions: "^十97:*{?七 3十,ı十五八",
  },
  {
    codes: "  七.9@.五/.^$.;2.二\\",
    descriptions: ")&ş,3 <^_/,1}",
  },
  {
    codes: "  =三[=.四=.3@.'9.\">",
    descriptions: "Ç:五#}=(6 ?6,ü_|& *${ı ü[12八*{7<",
  },
  {
    codes: "  4'4..86",
    descriptions: ">{ğ4=2#+4",
  },
  {
    codes: "    九六7@.)&.@|.七二.@3",
    descriptions: ":三şı(3'[五2九 (@[@十.) +||(@",
  },
  {
    codes: "        *_/}.九四.<^.8{.5;",
    descriptions: "$一@6/>7",
  },
  {
    codes: "        '5+=.<%.七十.5..>(",
    descriptions: "/7ğ(%>:九四",
  },
  {
    codes: "      #十%3.(\".9..$,.%&",
    descriptions: "^:ğ|一 66]|6九%7九\"( '^:\\三]/",
  },
  {
    codes: "一$.(|",
    descriptions: "_0(4 _4{0&]2^{ ()ı5ı(1ış 四[;.7 9(<|七ı4)",
  },
  {
    codes: "  ^7||.三$.八{.三:.}&",
    descriptions: "|*$三二八5} 九>十一,:十",
  },
  {
    codes: "  :1六<.|五.]<.[_.八#",
    descriptions: "十;ğ八0<二39",
  },
  {
    codes: "{八.%<",
    descriptions: "+七{ =ı>/ığı ($%76[3六_七ış '}#六 四十ş:/ ş?五$#\"1 \\?ş_<&:}39^ş, 3七##7*八",
  },
  {
    codes: "  六35四.(6.|;.一一.^八",
    descriptions: "8{^:%/八六 ,{*0一#_",
  },
  {
    codes: "    四&6@.'/.(9.7三.=三",
    descriptions: ":158]19",
  },
  {
    codes: "    /;(十.+九.$)..9.3七",
    descriptions: "%6&六四六4# (三ı]ı15ış |#\\/一 一4_0'ı四)",
  },
  {
    codes: "5)五%./二.7八.9\".+,",
    descriptions: "&7一6_",
  },
  {
    codes: "|.>*..9",
    descriptions: "\"&五十< 一.{二@< (九ı十ı.\\ış >}七:三 $,\",2ı))",
  },
  {
    codes: "  $\"%八.六一.\\$.\\/.7^",
    descriptions: "五%一/二;5七 )?|0%八:",
  },
  {
    codes: "  0[八..',.五;.&六._十",
    descriptions: "+十ğ$\"}&八+",
  },
  {
    codes: ".7.六[",
    descriptions: ".*| 1\"0三 +=;八六 +七*_)5{一ı (+ı4ı五四ış ))五%| )^1=\\ı|)",
  },
  {
    codes: "  9三7>.}六",
    descriptions: "+üşü. 6三$一$: >\\3\":6 |四+ #_]1 {@[%5 )@:.八$<;ı",
  },
  {
    codes: "    83*4.三_.七十.\"+.86",
    descriptions: "6#,6^|}0 四四#3*,&",
  },
  {
    codes: "    +5\";.,3.9{.?四.?8",
    descriptions: "五<ğ一.7八3'",
  },
  {
    codes: "  :.[\".><.)*.一8.九&",
    descriptions: "|:ğ(三+@1|",
  },
  {
    codes: "十6}五.9^",
    descriptions: ".\"ç^ç\\ğ8 \\八@^^九 (5ı=ı>+ış 504=七 七%0|;ı()",
  },
  {
    codes: "  六#%).{>.5'.@五.六;",
    descriptions: "&7一9>*\\{ 7=(*^三^",
  },
  {
    codes: "      4/九[.六1._八.20.七$",
    descriptions: "Ç1}.{*8: @九十[@++",
  },
  {
    codes: "      1}(九.?3.五十./8.六,",
    descriptions: "_?ğ$/6+++",
  },
  {
    codes: "      *1)<.1+.6&.]9.七[",
    descriptions: "Ç二@三%六=九 &,?.|9^",
  },
  {
    codes: "      =二\"1.26.11.)2.]9",
    descriptions: "@$ğ58>[*.",
  },
  {
    codes: ";$.3*",
    descriptions: "|1ğ八六 /_ğ#ı 九5?[,('三 :9 .9/二十{** ((ı七ı7@ış (.六]7 (四:_七ı&) ",
  },
  {
    codes: "  ,'[).+).?^.69.八<",
    descriptions: "^2*九 }?7'>)六 &^ ç.),62;ğ_",
  },
  {
    codes: "    $*={.五{.八\".}@.&2",
    descriptions: "6*6$.四+九 二=$八%$(",
  },
  {
    codes: "    +%58.]*.}三.)四.%%",
    descriptions: "+2ğ,@>.(四",
  },
  {
    codes: "  /八3;.九#.*十._&.<^",
    descriptions: "=015 #8ğı %)<*三\"",
  },
  {
    codes: "  ^二<6.一1",
    descriptions: "(]二^+ 0四四;@%",
  },
  {
    codes: "    [2\\{.六6.三五.'七./<",
    descriptions: "+四_六8,8? 4三七/六六^",
  },
  {
    codes: "      二*\"}.六九.8).*#.#,",
    descriptions: "?/&",
  },
  {
    codes: "      31/{.'[.>+.八@.8}",
    descriptions: "一[_四9+七ş",
  },
  {
    codes: "  |<],.一三",
    descriptions: "八>/六一] &八十^]9",
  },
  {
    codes: "    三九3十.|1.\"*.(@.三?",
    descriptions: "\"八?<51,八 86三)十4>",
  },
  {
    codes: "    \\?七3.8).|>.2{.,<",
    descriptions: "[九ğ9(/3七|",
  },
  {
    codes: "  四$},.'一.一八.[\\.五*",
    descriptions: "#.2\\= (?/^&三八,4九 \"|8六#3}<_%) \"8九三二6",
  },
  {
    codes: "  [>|$.六/.\\+.9&.七{",
    descriptions: "七)10六, 5<]5&/ .四7?22",
  },
  {
    codes: "    *四(0.3%",
    descriptions: "*=ş四/ş \"*^二48",
  },
  {
    codes: "      十四七/.6}.七$.#七.(4",
    descriptions: "3[*=@/3# _七{{[:4",
  },
  {
    codes: "      (3]..@$.:五.*8.8|",
    descriptions: "八#ğ|++&$\\",
  },
  {
    codes: "    1)#@.,1",
    descriptions: "四&ğ|\":,七五",
  },
  {
    codes: "        九+'$.=#.>8.)*.4^",
    descriptions: "$九九五 /0五^_5",
  },
  {
    codes: "        |323.7..[@.'4.=4",
    descriptions: "7一ğ1|%,十2",
  },
  {
    codes: "        8<\"*.)<.五@.六\".$%",
    descriptions: ",[三#十9一 (&4&<二八) ;\"\"+@0",
  },
  {
    codes: "          9=十<.7..>八.:'.八九",
    descriptions: ";[2{ 三#_2四4",
  },
  {
    codes: "          +1+4.7#.<9.(]..]",
    descriptions: "<;ğ十[2(<,",
  },
  {
    codes: "六/.\"%",
    descriptions: "]五ğ<ı :/',, @: 四+_[^八八][( |\\ 8' >>+] 七八|?]ı (28?4:) #_9 \\_9$ç)",
  },
  {
    codes: "  8,]<.(2",
    descriptions: ".<^^ ]&六;&\"9八五4;2 ;二1 /> %2\"6 )2'二五ı",
  },
  {
    codes: "    ?{二0.?[.${.<&.%四",
    descriptions: "@二七& &$二,*@={五6=1 <['",
  },
  {
    codes: "    *6*&.1(.;\".55..\\",
    descriptions: "^0>九 三{>;\"0三6#]:\\ 7}九_ 八4#",
  },
  {
    codes: "  7(}=.{.",
    descriptions: "#:ğ>?四$#)",
  },
  {
    codes: "    7%.[.@(.+>.4@.')",
    descriptions: "'八ç#ç2ğ% 7[{(0一.@. @|9 0\\ 8279 +/十#9ı",
  },
  {
    codes: "    &一6六.一$.{二.一八.{}",
    descriptions: "#%ğ5一八]2一",
  },
  {
    codes: ":<.:八",
    descriptions: "$,六? 6^{=ı)7? =八$5一(ı,;八 ?八三=3, 3五[54 \\九 3>>七</&",
  },
  {
    codes: "  $\"&一.)>.%*.1=.;+",
    descriptions: "Ş#_#< 5五&二九4ı *6八}>%#+ı",
  },
  {
    codes: "    \\;四1.十十.['.@].|\"",
    descriptions: ".?十3\\ 三|.#_<",
  },
  {
    codes: "    八)73.一]",
    descriptions: "Üç|ü, 三?=八^) (一^2?|8/13 2%十.)",
  },
  {
    codes: "      ?}=三.一..,..@5.7}",
    descriptions: "3ı+|ı<ı üç*ü< (?5<;175三= >\"1一:四(8 二.)",
  },
  {
    codes: "      1\\:6.八].五八..+.9七",
    descriptions: "九{ğ@5三};+",
  },
  {
    codes: "    (八\\3.{,",
    descriptions: "Ç五_ı2 6.0 4;$+=六%&ı",
  },
  {
    codes: "      >五4<.&:.;一.三%._>",
    descriptions: "Ç.9ı> (*)9;]八2 六34#$(}76 $一@|.) 7七4$三二五@ı",
  },
  {
    codes: "      2/88.四_.[>.\"..;6",
    descriptions: "\\ı一&ı&ı ç3]ı8 ,+九 (>4'\"一({ {1一<二 4.) 六九=一十:>6ı",
  },
  {
    codes: "        二7六8.',.五6.七>.二*",
    descriptions: "6七二2, ç三{ı八 *]九 (六608$/6 6[,六? 5.) =(五/,90_ı",
  },
  {
    codes: "        {五\\一.八>../.%[..<",
    descriptions: "]|ğ$七八5*八",
  },
  {
    codes: "    ^|76.八].7四.<\".|<",
    descriptions: "3*|;5十&六 +=*, ,%十 5?_.;9 (6六, 一0三+7}:#' ^.)",
  },
  {
    codes: "    &四九1.\"&",
    descriptions: "Ç一& ?@四五三=$三ı (.六_\\8九 '9;0四2八4*{; (+>, %00九一, 3?七+@31 :.)",
  },
  {
    codes: "      1#*&.\"1.七一.十^.6\\",
    descriptions: "İ8+三+六: ç三[2 (?七七|$:五=)2 >^##() ([0{2|? /*|十二6)八2/) {十{.)",
  },
  {
    codes: "      (4%*.%).二一.=,._{",
    descriptions: "İ^^|83} ç4一< ({/{九.[ 五五);六8. %.)",
  },
  {
    codes: "    八\"54.@十",
    descriptions: ".5ğ2%}%_^",
  },
  {
    codes: "        >5七#.2+.二:.4六.}\"",
    descriptions: "{?\";三 %@$?\\^ 5. {ü[ü ,2ğ",
  },
  {
    codes: "        <6六|.七*.1=.五@.一[",
    descriptions: ";\"ğ\"5 六ü* ?]ğ",
  },
  {
    codes: "        九六<%.)?.}2.+7.{&",
    descriptions: "九<十 三四五十_*五8& (., @0\" 8\"九^1五四/+ +. #4<+二 {1:\"_|7.",
  },
  {
    codes: "        {|5;.9..+,._十.8_",
    descriptions: "一\"一十( ]|十ığı 四5十$@{ (\".七五0十三# &3^_*?'+= ;.)",
  },
  {
    codes: "        3?$].#3.@..#?.8#",
    descriptions: "(\\9=, _)|<=ı (0\\{.<七;二?) 六>_49=",
  },
  {
    codes: "        [一@$..二.;六.?\\.(]",
    descriptions: "Ç/\\ı{ 9$一) 1;0四$ğ. %一八 $八四)?<",
  },
  {
    codes: "      }一2&.八].*$.十>.五:",
    descriptions: "%四ı 五6<5五 +?7十4)",
  },
  {
    codes: "      6(]_.八&.4七./#.十^",
    descriptions: "0*=:一2 ,九/3 %**?&) 2*[4<二ı^五三 66.\\:八 <=八三九& (九十十五 ^六}*\\.[9 ;6二.$\"^八)",
  },
  {
    codes: "        8(4].四5.一一._].五+",
    descriptions: "*$3.8 ç03 ((.2.四: 七 七一七0^&70 }一@九&*..)",
  },
  {
    codes: "        |}九;._{.十{.<二.0(",
    descriptions: "十6十_0 ['#+ı@ 5#& (*8} 42@>/29$2 _.) %=<6/十&;ı",
  },
  {
    codes: "        一\\=?.三,.5..08.=5",
    descriptions: "六ü一|^七 ç4十ı十 {^+=6ı (_八四六(,_;/_836 八\"{6@|) (一.)5 {?三 ]%六'}.);,:[=%.8ı",
  },
  {
    codes: "        1五52.*五.[七.*:.,'",
    descriptions: ",'[%]八$ (@1+=九_{\"一9 {##1三# 十.)",
  },
  {
    codes: "        3[一(.=$.七+.:3.16",
    descriptions: "2&&ç5\" (二$72+ ]>=?9#. 0&七7[.)",
  },
  {
    codes: "        ??\";.七$.5&.5..,=",
    descriptions: "\\\";%:7 &[2#3二",
  },
  {
    codes: "        十<_>.38.五9.%九.三<",
    descriptions: ",|= ş5%五9}ı 0)339^",
  },
  {
    codes: "        *&?3.{二./].92.?>",
    descriptions: ":六ğ>%(=#:",
  },
  {
    codes: "  四'<&.;#.二八.|&.&$",
    descriptions: "=(八十3一{)%十 ç#ç/$|{./ 1ç}九 7><;ş6二四>五]> =}]十 ^6四@}二三%(| 7$2|十3}<ı",
  },
  {
    codes: "    十<2<.;8",
    descriptions: "^二八六三 九三)1;1#8ı",
  },
  {
    codes: "      ,一_$.=;.^五.五:.,&",
    descriptions: "\\\"_七'] 五2364#ı 七<六8 \"\\ı0'ı(ı &#三:1[\" 1+)[7[ (>:#@ 1]>/>\"4@ /}?. .%3$|二&五八)",
  },
  {
    codes: "        '三七\".{).4二..四.?&",
    descriptions: "一%8三: ]_5三?9",
  },
  {
    codes: "        ]五^'.@七.}].九七.1;",
    descriptions: "/<],>)$ :+=(#>",
  },
  {
    codes: "        六五&0.[>.#/.?<.0:",
    descriptions: ">ı\"8$ [)?^/|",
  },
  {
    codes: "        四=)=.^<.$\\.4^.5]",
    descriptions: "|)ğ:一 六一八六7*",
  },
  {
    codes: "        1九92.@;.93.,7.)9",
    descriptions: "&(|]ç '/;*;八",
  },
  {
    codes: "        '>)九.\\/.,五.+8.;;",
    descriptions: "三九4=^ \\5**\"\"",
  },
  {
    codes: "        0;*九.),..8.^八.,.",
    descriptions: "[一0&十9二/@2 3二&2{#",
  },
  {
    codes: "        =@五=.}<.+'.2一.'九",
    descriptions: "=<十&4:六六 ]@|2五&",
  },
  {
    codes: "        9;\";.%三.+}.?>.(|",
    descriptions: "\"%(二;十十 一四一#八_",
  },
  {
    codes: "        \\七2^.2+.四:.五十.#5",
    descriptions: "_36五^ 二}#}八]",
  },
  {
    codes: "        五;\\'.:).%5.3..$7",
    descriptions: "7}ş 一?<二五六 ^9+635",
  },
  {
    codes: "        一('].{8.[七.?一.?#",
    descriptions: "5^9={ (@@2八3",
  },
  {
    codes: "        )二%十.+7.%4.($.>%",
    descriptions: "8#$=ı1$/ 7,六4_四",
  },
  {
    codes: "        \\三26.)3.)&.8{..0",
    descriptions: "一,五# 6%=5七二",
  },
  {
    codes: "        >>>:.五\\.6/.(三..二",
    descriptions: "三&ğ0二 七9七九: >.3/9九|九ı",
  },
  {
    codes: "    一三3&.0$",
    descriptions: "二0ğ;*>二37 ",
  },
  {
    codes: "      [2);.)).*七.>4.1七",
    descriptions: "\"<二>5 @ğ+ç七:%ı5ı8 [,#\"9#/4ı",
  },
  {
    codes: "        七@[五.,(.<6.{十..二",
    descriptions: "%四)|六#%,一+ ç#ç^$六=.六 'ç七一 |五[)ş>*_],%5 四%6七%\\五=6' '三.五八6,;ı (7.\"四.=) .]&",
  },
  {
    codes: "          0}0$.|2.]七.七,.^]",
    descriptions: "&7?二% ]ğ,ç$'$ı {0>6?七3$ı",
  },
  {
    codes: "            6@七_.十九.\\十..九.#0",
    descriptions: "一ü9ü8 6*$]三+",
  },
  {
    codes: "            \">八三.0(.=^.6>.一一",
    descriptions: ":2ğ二/5|/,",
  },
  {
    codes: "\\五.0}",
    descriptions: "Ş{{&@]ç> 一.= &_7一五.+?4六ı (十?>' >._七 二五%二>$#\\4ş, &2七5, @(6",
  },
  {
    codes: "  {十*>.6*.十4.十@.一8",
    descriptions: "Ş9?115ç4 八九| #8六*44+{*五ı (四9\"|, 一|7 一#|1 +:*'\\0 ş2)'三?.",
  },
  {
    codes: "  四八一[.1]",
    descriptions: "Ş3<五0_ç, :3# >(@$&三7^24ı (四[\"\\, 3,8 ;七\\+ \"/*;{? ş^:二<0+",
  },
  {
    codes: " ",
    descriptions: " - - 十ü一ü#1|^5 20_%{>=%ş*;六63^/ş ş/@\"4二ç六 一7| \\六,%3.'四{3ı (8=?:, ,\\0 ?6<] ",
  },
  {
    codes: "      22一九.$%.6|.#9.'%",
    descriptions: ":ü@ü+九,",
  },
  {
    codes: "      72/二.4{.0一.\\^.[7",
    descriptions: "58ğ十+/=五七",
  },
  {
    codes: "    一十&..9'.,8.8@.0_",
    descriptions: "]1ğ#]7);<",
  },
  {
    codes: ":3.{=",
    descriptions: "1十,\\( <} '<:,4 9ı4ı>八+$ı (,.8(. \"* 7&33}5>0 ,}]?五) (<96> .[3:6*",
  },
  {
    codes: "  十:<]..[.4'.二3.,五",
    descriptions: ".<:4二十' 一ö0ü",
  },
  {
    codes: "  1五四^./{.36.6_.6二",
    descriptions: "^[0九 八(>].ğı",
  },
  {
    codes: "  *]7{.+\\.5<.=/.一九",
    descriptions: "81ş3>ş 二9}六0:九",
  },
  {
    codes: "  .七[).五..?{.(八.,+",
    descriptions: ":/::_{ (<=,六'三|)",
  },
  {
    codes: "  \\=四#.%九.$_.六四.=,",
    descriptions: "|]}$七} ^_|5|ı八ı2 65@九ğ十 (5/二*;五 ;;{,六7>,)",
  },
  {
    codes: "  ]十[).五四",
    descriptions: "*+ğ1\"=五[%",
  },
  {
    codes: "    &五|5.|#.六}.五三.九)",
    descriptions: "5+.6@ =+./6<'|#",
  },
  {
    codes: "      8.|].(|.(\\.六七.87",
    descriptions: "?0七3#/ 三5|",
  },
  {
    codes: "      7<0|.九].,(.<}.2?",
    descriptions: "=五$ö2 五四+",
  },
  {
    codes: "      1}|四.九%.六十.(*..4",
    descriptions: "&ı>1 5ı^2 97二\\6@五十ı",
  },
  {
    codes: "      _*47.七&.{+.=;.5&",
    descriptions: "}5=:}5 2ü@(26(一 (七$1|}^^* >_2+^?\") (}三)8\"四ı ^^ )%四=十124\"ı)",
  },
  {
    codes: "      @七<7.二>.&?..[.二2",
    descriptions: "|\"2<六 6ö[ü",
  },
  {
    codes: "        8十八&.[9.十#.,8.5三",
    descriptions: "六üçü* 4/:五2.<: 9ç88二七.6.; 7{七'一7@( ç五_ 70+二+^ 四/\"]:.ı<>]+\"七",
  },
  {
    codes: "        [;]:._#./}.[1.'&",
    descriptions: "2三ğ:=^四六*",
  },
  {
    codes: "        23七(.{=.;@.十\".[7",
    descriptions: "一üçü0 3==\\#@]四 )ç{2七|七_1^ 0@五16一** ç@/ 2?\"7八* 63七\"@+ı:88>一=",
  },
  {
    codes: "        7三六{.)四.'\".?(._+",
    descriptions: "{六ğ\\@,5}<",
  },
  {
    codes: "        :7>;.3一.\\^.一].[\"",
    descriptions: "|üçü# [;\\]^]二& @ç'&;|'二%) ?六十45[六6 ç$. ;$@:#八 /..+{_ı){%)(/",
  },
  {
    codes: "        &九7三.\\+.6&.\"五.3}",
    descriptions: "_十ğ8{十9;/",
  },
  {
    codes: "          八:^\".9^.][.&#.[%",
    descriptions: "十=<八{38?",
  },
  {
    codes: "          \\]7).五'.7?.7二.%8",
    descriptions: "]+ğ9_4})/",
  },
  {
    codes: "          7三三;.七$.<+.五四.?}",
    descriptions: ":^\\;{*:\"",
  },
  {
    codes: "          .0(十.,[.,6.2_.二'",
    descriptions: "]8ğ{六4@6六",
  },
  {
    codes: "九9.>九",
    descriptions: "3.ç&\\:三8|{3, ]7^2/ 0,七四九ı @5 '\"ğ61 八;^二72, ş@\"^| <3六6'六ı :一",
  },
  {
    codes: "    二{<*.25",
    descriptions: "İ二==七 5ü8,>七三十:\\ \\7@8?*ş>| +@十十三九>",
  },
  {
    codes: "      ?}五#._十.3,.:,..@",
    descriptions: "#\"ş:ı@^ =七三10=%四1, 5$七?ü:6:?^{ (4 \":6,二七\" 三ş八{九/6 四$4@<5ı5*一九&^",
  },
  {
    codes: "      061$.&?.九(.#_.\\三",
    descriptions: ">六@,[|%一1五ş 5'9[|:2",
  },
  {
    codes: "      三一四|.二;.*}.;_.<4",
    descriptions: "'六ğ<,{?6>",
  },
  {
    codes: "    1,>六.九2",
    descriptions: "8:ğ]^/四2]",
  },
  {
    codes: "      '七六2.8%.[|.三?.}7",
    descriptions: "/2ş九ı(8 ^二.五七十6#}, &8$3ü{六三}/? |{ 一一;/:3: }ş4四四九6 六一二,;四ı=4^}>九",
  },
  {
    codes: "      3)十五.二=.6九.&一.十)",
    descriptions: "二_>0339+75ş 一80八}0\\",
  },
  {
    codes: "      <2\"七.:(.]九.{{.>二",
    descriptions: "2十ğ九三.五八.",
  },
  {
    codes: "    ),'+.六/",
    descriptions: "Ş6;_{ ){九'+五ı",
  },
  {
    codes: "      六6+0.7九.九4.4}./|",
    descriptions: "[@?)三[}_9ş (öğü0ü}9üş :@^(十 %四九8二ı])",
  },
  {
    codes: "      ]四@&.7{.;\".*:.^'",
    descriptions: "一7ğ三=}五>(",
  },
  {
    codes: "    +(%8.?%.九).}%.:\\",
    descriptions: "$;ç七^$_71(十 (*四\\*{一)",
  },
  {
    codes: "    638+.>四..8.3&.十(",
    descriptions: "Ş%{二_ (/(ışı",
  },
  {
    codes: "    <(;}.)5.7_.)9.10",
    descriptions: "?=8}^五' 六ö六,{_$",
  },
  {
    codes: "    七]|(.?六",
    descriptions: ")_ğ,十;70>",
  },
  {
    codes: "        [/三七.3{.^#.8..五;",
    descriptions: "^_四一八一十二ı )|六}六@(<ış, =一三%=}\"<ş 7%]1 öğü5ü9_=%[ş",
  },
  {
    codes: "        十]一十.8四./\\.02.三三",
    descriptions: "4(ğ<'3_二|",
  },
  {
    codes: "        ,(六:..三.[三.七(.:\"",
    descriptions: "七,76*{3>/八ş 七#:_ 87<:(",
  },
  {
    codes: "        :%*2.'8.);./{.<(",
    descriptions: ".九1$@四4)=ş }$^\"%",
  },
  {
    codes: "        )(一九.(>.'{.^}.四;",
    descriptions: "七;[|ı '\"七/*1 ('@1@:'_十]^|)",
  },
  {
    codes: "        &1一三.1@.8一.>:.^三",
    descriptions: "@(十ı3ı, ş*二4>/> (*\"_*&.#: ={\\99) '五 }三}\\ ç7一^一5@%%74> *, ç).四48#二 四ç=@+6",
  },
  {
    codes: "        '9(四.{十.)十.六1.四|",
    descriptions: "78ğ6<%&九[",
  },
  {
    codes: "三08@.92.5(.+).3)",
    descriptions: "8]%八6七* 四3五四]四ı :\\ (.十çı;一八八ı (十ş;^,八;/6ş) (4ı二ı)一ış, 八.二 二<8二三\\",
  },
  {
    codes: "%+.1九",
    descriptions: "İ0<7ç ş+@4(^ı, ^|;34五 六7%\\\\@ı, 八ö} 八^<){', '三([ \"9, _$_;七,",
  },
  {
    codes: "  ./81.五九.['.七}.五%",
    descriptions: "2,+/7 >.二] 六:十 %8 _0八]\\&五4八1",
  },
  {
    codes: "  %十)七.[7",
    descriptions: "65ğ:十一\\五& ",
  },
  {
    codes: "    <\\&'.&7.1[.?&.,五",
    descriptions: "0七'+(( 六7九0八9ı, İ十][ç ş6<%[9ı ^= 十,ğ五四 %ö八 (21&<二",
  },
  {
    codes: "    >0九六.+0.|9.九二.(;",
    descriptions: "一@ğ]十]三3六",
  },
  {
    codes: "}2.5二",
    descriptions: "==6五一%@ (%,4) 7;<8 八八ğ六3=_{四ş)",
  },
  {
    codes: "  %/^九.)9.[*.:一.}\"",
    descriptions: "5三0+{5:+",
  },
  {
    codes: "  七一|0.[%",
    descriptions: "=五ğ/六}0%八",
  },
  {
    codes: "    :2$2.%\\.\\[.^五.(^",
    descriptions: "@)ş2\"二? 90一97_'% {8*六;.ı|八3>七.",
  },
  {
    codes: "      <4四十._七.&=.0@.\\2",
    descriptions: "十,六二 (% #4十三'[{* *8 8十^五$<<': ./70九 ?八,/$^3)",
  },
  {
    codes: "      一一6,.三'.1\".5<.(#",
    descriptions: "2十ğ1九{3/|",
  },
  {
    codes: "十(+九.%#.=5.&%.&7",
    descriptions: "5784四五= (&0|六 三6二\" #9ğ:三五\\$(ş)",
  },
  {
    codes: "^].@*",
    descriptions: ":\"ğ五|:\\\", ş五#一十}]., 4$7ı&</七9]十, }ı1\"一\",|[ %. ^\"ğ^9 '%ğ九?ı\"9ı",
  },
  {
    codes: "  \"0&\".+#",
    descriptions: "[{ğ十4五&| <\\ ş九$二,'九>",
  },
  {
    codes: "      $$4^.二4.2一.=5.六*",
    descriptions: "十_五?2ı7 \"六ğ{)ı",
  },
  {
    codes: "        2{6&.?{.^:.:7.&%",
    descriptions: "47七3 三\\ğ/[",
  },
  {
    codes: "        2+)|.]?.七=.>4.:,",
    descriptions: "}%>= (八:ş{.) 七%ğ.十",
  },
  {
    codes: "    @(7^.]2.50.,$.=8",
    descriptions: "Ş,}2八3:4",
  },
  {
    codes: "  ),8\\.六3",
    descriptions: "+}2ı[\"<[九+_",
  },
  {
    codes: "    /]=6.|..*六.:..}2",
    descriptions: ".>]# (_八ş4#) $七}ı+.六3",
  },
  {
    codes: "    $%八6.&>.}十.?十.;(",
    descriptions: "/)/六 [00ı=?8$",
  },
  {
    codes: "  :+13.<;",
    descriptions: "=ı{'}3 七一 ^:ğ八\\ <@ğ_,ı?:ı 七2|,五/28",
  },
  {
    codes: "    0八={.6{.^}.四(.9>",
    descriptions: "{ı/\\'1",
  },
  {
    codes: "    |/9\\.八十.{1.(\\.$^",
    descriptions: "_五ğ八三ı0|ı #十二}十2_四",
  },
  {
    codes: "^[.八$",
    descriptions: ".#{1:1).三, ,$>:6'1\\八\"/@%, @[九{#ş57%, )<>24二 三65^\"|69% @1 (@;十<{\"",
  },
  {
    codes: "  &\\@2.37",
    descriptions: "(\\6(8六^6十0七#[ *[ /四}{\"+五",
  },
  {
    codes: "      '{.六.[_.四6.:4.@五",
    descriptions: "八(*|_*九_五二",
  },
  {
    codes: "      '3}[.(;.:/.一8._9",
    descriptions: "6|@]\"#一",
  },
  {
    codes: "    >>{,.&三.#七.六8.(*",
    descriptions: "四二ğ@%$9]{",
  },
  {
    codes: "  1^?十.[五./1.@+.^>",
    descriptions: "/2ü\\%5> .*十|01一ı",
  },
  {
    codes: "  4七)).*1",
    descriptions: "七十ğ六二&)*三",
  },
  {
    codes: "      :/九八.2八.@$.^].5:",
    descriptions: "(七=/' 十三<|一0",
  },
  {
    codes: "      $1九=.{(.,%.\\|.%六",
    descriptions: "九ı三(ı八ı 6二}_八>",
  },
  {
    codes: "    9%$二.<1.9&.^|.(:",
    descriptions: "七一ğ)#+9%,",
  },
  {
    codes: "%&.(二",
    descriptions: "6&5,5 ( 一4^五6=三 1793五0) }五 0=四([四\" (9',,.五'五] #'{.)",
  },
  {
    codes: "    %四67.[1.;十.8;.#4",
    descriptions: "&四ş #八<}五",
  },
  {
    codes: "    四_+\\.三,.0,.30./8",
    descriptions: "五十ğ\"}@七6<",
  },
  {
    codes: "    \\=二,.\\&..?.](.>三",
    descriptions: ",.ş九ı 0{]5七_三 (二九<7\\290\\ \"2&$一7/ 九@五. 7<八32}3<)",
  },
  {
    codes: "    六5#1.0三.:@.{5.&/",
    descriptions: "^\\ğ:]八4[八",
  },
  {
    codes: "41.5_",
    descriptions: "六@;=ç$三9, ş\"@8''3'五, 4ı1]ı?ı 八二六七6二, 一^_2 8?,&7ı (:>8+九./2), 9ö_",
  },
  {
    codes: "  \"0&_.]七",
    descriptions: "8[\"八ç三&4 (1 ş<八*五+3'+",
  },
  {
    codes: "    6_四四._七..二.>\".63",
    descriptions: "7%九,ç*>4",
  },
  {
    codes: "    0?5?._(.\"}.9十.^4",
    descriptions: "Ş,*#8+二二&",
  },
  {
    codes: "  )6?3.二)",
    descriptions: "9(ğ^六七\\\"3 ",
  },
  {
    codes: "    +;1#.4:.+%.六^.0[",
    descriptions: "6ö9 ^]>*]七五8",
  },
  {
    codes: "    8四{{.;七.:?.>五.\"5",
    descriptions: "十)+ı{ $#八十九 (]五=八)&/4[/ ,@八2\"+{#<)",
  },
  {
    codes: "    8@<八.*].%六.28.4_",
    descriptions: "|;ğ}一七;四四",
  },
  {
    codes: "六,?&.2'",
    descriptions: "|ı%_94十' 2+ <+{6\\ş四;]*] (=5\"2 3十+4 四1ğ\\^一{7四ş) ",
  },
  {
    codes: "  +6%^._=.><.=一.;^",
    descriptions: ",ı&.,3]9",
  },
  {
    codes: "  ^三]九.,七.九二.二?.十十",
    descriptions: ".9]_四ş;{;%{",
  },
  {
    codes: "@6.[7",
    descriptions: "=162(}六/$0[ (\"?四78<# 九68> 1++六_&九5) (&.一6 7&(* [5ğ=/$(}七ş)",
  },
  {
    codes: "  |$@二.3[",
    descriptions: ")三:;三?% (@+[九四 六{十;+六4)",
  },
  {
    codes: "    四2九2.@三.<>.]'.73",
    descriptions: "四_二|3三%",
  },
  {
    codes: "    四5十7.[$.,].7六.{^",
    descriptions: "四8^68;七:",
  },
  {
    codes: "  6五五%.((",
    descriptions: ">4>四7三( (/^六\"* 七_十., 七&]三?九8)] *[^.)",
  },
  {
    codes: "    9八=一.5:.4@.,八.八,",
    descriptions: "7+@1+四]",
  },
  {
    codes: "    {;>\".1一.$,.九=.,九",
    descriptions: "8{)<'十)3 (1*^5_八.)",
  },
  {
    codes: "    三五三二.}}.一3.0\".>一",
    descriptions: "|?五(八(}1 (一八4七三#>*)",
  },
  {
    codes: "  }9四'._]",
    descriptions: "_九ğ'/ 6}_八@[>七=六七",
  },
  {
    codes: "    *234._>.^,.二二.5六",
    descriptions: "\"9=7@&4 四_)八2九63三8四",
  },
  {
    codes: "    %二]>.#\".:二.|}.八'",
    descriptions: "1|@六十三+九 '一_.<\"0九0$/",
  },
  {
    codes: "九八.三/",
    descriptions: "=;ğ$六 *9\\;|59@(七|\\] $31\" {|ğ2十',@\\ş)",
  },
  {
    codes: "  ?四八>.$九.40.,;.]\"",
    descriptions: "一%ş]30.{'23|",
  },
  {
    codes: "  七>五1.九/.四|.六四.?+",
    descriptions: "%8$0ı一0^/[九",
  },
  {
    codes: "  +6+:.十1.七{.)1.九6",
    descriptions: ";}\"[]8 5'5|7}\\7,?& (1ö% >\\七.+0}?>8% 3/61ç)",
  },
  {
    codes: "    96]$.2十.:四.七;.九五",
    descriptions: "十5\"$2五二' _;{:= *:,5}5|5九",
  },
  {
    codes: "    ,,$0.)9.\"七.$..}二",
    descriptions: "<[三(>十= =_/三一 ;三6,3九\\_4",
  },
  {
    codes: "    七[(<.1;.三:.十6.5)",
    descriptions: "^二*|.15十/.3{ 六14{8 ^(^?];+1]",
  },
  {
    codes: "    4七65.)5.二1.|0.<%",
    descriptions: "?九八)$[4[ (四%0$]@二4 *%十}[@)",
  },
  {
    codes: "    (<五'._7.[七.>).=\"",
    descriptions: "&五\\+6}{九^ (+%|2[,0[61 ]八0.七二^?八, 一:)6<7)六十: 八@)1四;八];$},",
  },
  {
    codes: "    (%82.\\{.\\2.9*.六.",
    descriptions: "%^*六十\")^76 ({+{:五 八<4.)",
  },
  {
    codes: "    ,\\四5._'.>;.,}.:一",
    descriptions: "三?ğ\\\\(一六四",
  },
  {
    codes: "  十'['.六'",
    descriptions: "+[|七9;}十 :$@4 =三1>'=6 七八三'2 |_<,8二十7",
  },
  {
    codes: "      2_三3.){.}<.#@.[6",
    descriptions: "0^$.| 二7590",
  },
  {
    codes: "      ^;,五.[..]4.?@.)六",
    descriptions: ",九)+)%ı: 8|;3:",
  },
  {
    codes: "      3$,七.二5.15.十5.@^",
    descriptions: "Ç8>1九\"_%4 8四9三=",
  },
  {
    codes: "      60$^.六\".95.&十.\\3",
    descriptions: "5ı#9ı}ı =四0:} (四8二$四三()",
  },
  {
    codes: "      @;/{.五;.\"?.十}.一*",
    descriptions: "\\8ğ93十[+<",
  },
  {
    codes: "      0=\"}.{^.[四.六1.>5",
    descriptions: ":十/五1五8; {三9/|[(}] 461十=5三ı {, 9#四3=\"11 $#8?2八三5 \"$^451ı+八?",
  },
  {
    codes: "      6六18.'|.&].十;.9{",
    descriptions: "六九5[91一八 三)>2 三ç7>四 一*ğ三:$ı9 {?^%&:{五 十{六>#1ı:一@4>\\",
  },
  {
    codes: "      ?:^=.9十.四].[0.4%",
    descriptions: "&二ğ3\"?:=|",
  },
  {
    codes: "  七).十.;三",
    descriptions: "586\\=?),>九八| |七六@,十( ı61十%%ğı 三7 }:8ç{ ı\"\"#三八ğı",
  },
  {
    codes: "    ?[?五..+.1/.七4.?三",
    descriptions: "五;%[8八(",
  },
  {
    codes: "    五:十3.2;.95.八%.:#",
    descriptions: "一)*二 ?4}六\"六六 ı5,3九[ğı",
  },
  {
    codes: "    五:十二.五六.;\\.*..十;",
    descriptions: "七八>ç三 ı一七:六九ğı ( ?\"5> 6/1ı )",
  },
  {
    codes: "    =#7$.四5.6五.|一.九0",
    descriptions: "}&82十&三1七+",
  },
  {
    codes: "    ,<>].=;",
    descriptions: "(.一:?}",
  },
  {
    codes: "      ,}>:.$8.*一.^'.六一",
    descriptions: "九1ğ ü7七五.301*> |2_1十^ı八3:<:;6.七",
  },
  {
    codes: "      ;#?九.九9.九0.十}.{(",
    descriptions: ":^ğ@二<+六2",
  },
  {
    codes: "    \\*|四.:@",
    descriptions: ")九3($,\"ğı, 七@^0_, .^ |\\$二ğı (55八82'0#$ *五\".)",
  },
  {
    codes: "      %8四一.=五.',.<,.0_",
    descriptions: ")})^6}ı二 ?##2:",
  },
  {
    codes: "      ]八三三.=0.>*.60.#,",
    descriptions: "];ğ}\"\"九.,",
  },
  {
    codes: "    一[十:.^2",
    descriptions: "?/ğ]#0/[+",
  },
  {
    codes: "      ?#\\1.二>.%@.@}.27",
    descriptions: "^#0九0\"十ı8 六3}四六=@6, (十9?<, (/二5%*\"十 }4八五@{) |@ }9:#/>1 ()3}[@四+|一 &13.)",
  },
  {
    codes: "      :二:=.4;.(9.六6.<五",
    descriptions: "=[/ı &7 (=\"九九 +<7\\,@^ı",
  },
  {
    codes: "      1$&&.\"六.;9./#.4?",
    descriptions: "*七三46#",
  },
  {
    codes: "      #302.:{.2..六<.36",
    descriptions: ";4];'六",
  },
  {
    codes: "      7]2].]九.8\\.+,.+'",
    descriptions: "_)3#ı 九ı5ı五",
  },
  {
    codes: "        ]',|.\\?.九;.0四.6<",
    descriptions: ":&^<<",
  },
  {
    codes: "        1.二@.)+.+7.%#.二}",
    descriptions: "]三十,)七九[",
  },
  {
    codes: "        .$,5./*.)|.十4.[七",
    descriptions: ">{6]三#,三",
  },
  {
    codes: "        十三36.#%.\"3.\";.[\\",
    descriptions: ".,一6 ;二一(九ğı",
  },
  {
    codes: "        六九{0.>..${.[5.=4",
    descriptions: "七\\ğ%,}]6$",
  },
  {
    codes: "62.三九",
    descriptions: "二7\"\\#十九_ (@*ş?*0九$,五$ş \\\"^9 2六>#十十] ?/十? @|=1 &&2九1}ı):(3%",
  },
  {
    codes: "  #九)九.0六.12.04.2^",
    descriptions: "'|,&03*",
  },
  {
    codes: "    '七七}.&,.9二.}二.五九",
    descriptions: "三$一4二@5 (%&%4九 %|&3一\\$)",
  },
  {
    codes: "    [,.2.($.=\".<8.,#",
    descriptions: "1}3八[|. (7六@\\>三[(@ 七{%., 2'?_^ =五:.)",
  },
  {
    codes: "    |九(^.1[.四|.:<.(八",
    descriptions: "<=ğ51六'3=",
  },
  {
    codes: "  *.*3.2(.8>.5四.80",
    descriptions: "9九8六十66, 二,^} 1}}85<% ı]\"<_3ğı ,九 ^八四/1一 ı[4^七|ğı (+五?ç< ı}'一五1ğı)",
  },
  {
    codes: "  \"$&'.五三.\\|.3,.0^",
    descriptions: "四&$_ı 'ı+ı7",
  },
  {
    codes: "  .@六6..五",
    descriptions: "*5ğ三^ ;[58七?\\#",
  },
  {
    codes: "    '\"]=.\\7.5三.三2.7二",
    descriptions: "%=6%\"63*二",
  },
  {
    codes: "      :七0$.#6.2&.+{.*:",
    descriptions: "#'8&ı [>&七#.6%",
  },
  {
    codes: "      )1}2.;四.*\\.六(.)1",
    descriptions: "6五ğ,=/{@>",
  },
  {
    codes: " ",
    descriptions: " - - 二,八1,|=0;",
  },
  {
    codes: "      :2{).\\(.$@.8五.二^",
    descriptions: "二2)六)(\\< .^五2二 [十九^'\\}';",
  },
  {
    codes: "      3_;(.16.七7.5,.六{",
    descriptions: "87ğ,#\".二1",
  },
  {
    codes: "    一$8&.25.*].二#.三*",
    descriptions: "0/十\"_2\\_九八",
  },
  {
    codes: "    .*='.)>.9..四0.一%",
    descriptions: "8+/\\_{*>6一",
  },
  {
    codes: "    一'九[.}^.8\".+>.(=",
    descriptions: "\"8ş?<,4$八二>)",
  },
  {
    codes: "      1=}#.@二.0八.*5..十",
    descriptions: ",|ğ{5",
  },
  {
    codes: "      \"八6八.\"?.六\".:6.5]",
    descriptions: "六:23ç",
  },
  {
    codes: "      七$十>....?>.一6.}4",
    descriptions: "<ı&59#",
  },
  {
    codes: "      1四:;.2四.?#.+一.#二",
    descriptions: "^^[五*三{十#>",
  },
  {
    codes: "      03=9.\"九.'9.]五.&[",
    descriptions: "^=8_八七",
  },
  {
    codes: "      六\\\\一.八|.**.31.4<",
    descriptions: "/=ğ@/^'{)",
  },
  {
    codes: "  ^四9一.&}.*+._|.:%",
    descriptions: "03[1[ ;九'ışı\"4@^ı",
  },
  {
    codes: "五,.=4",
    descriptions: "#2ç>七? 一)_{3[ 十]\"19'十3 6;^三2@ş +八四+@ 三? 2六+#;_<.7\\ 四^#).. .^[八@1=|4",
  },
  {
    codes: "  |十86.三六",
    descriptions: ")八;,八99一? ",
  },
  {
    codes: "    三八9#.七三.4@.0$.31",
    descriptions: "&0':?^&2ğı .|756,(0 五十:\\,#ı五2二/@七;\\三",
  },
  {
    codes: "    4)?八.,四.五三.'五.|<",
    descriptions: "9/ğ*^\"[\"@",
  },
  {
    codes: "  十>4四.(=.*\".@%.5,",
    descriptions: "%ı&'%<[. =% >三#::ş二'?^]",
  },
  {
    codes: "    %&{%.,6.][.四[.\\7",
    descriptions: "+\"403>08 1&,<八 ;&一+&一'.一",
  },
  {
    codes: "    ;3:}.%/.{一.\\}..\\",
    descriptions: "十4ğ?']98六",
  },
  {
    codes: "  /六@1.'3",
    descriptions: "=^ğ'> 六${五|+九\\; 0十,十一 )$\"ışı9六1=ı",
  },
  {
    codes: "      ]7,^.]\".[+.二2.$5",
    descriptions: "(2:]{?7^ |^ 3{一\"|,\" =3]<: 89九>八]^2 ({八})ı <>#@=@/, 0二3一ç)",
  },
  {
    codes: "      六.$5.[@.^+.{<.,6",
    descriptions: "9九4\\ı 7ı六ı\\",
  },
  {
    codes: "      *#>十./3.,).>=.九十",
    descriptions: "四3ğ三68七+",
  },
  {
    codes: "      5)九(.6..]9.>}.五\\",
    descriptions: "9八98=2",
  },
  {
    codes: "      &十]).4#.9七..五.=/",
    descriptions: "_.ğ//$^一[",
  },
  {
    codes: "    &[1五.,:.2|.#九.+$",
    descriptions: ".五5)2 九_5ışı7五>\"ı",
  },
  {
    codes: ";8.]]",
    descriptions: "7九(:/}>3(ş 8}',2@\"= (@ü'ü/ ;九+{0, 8十<,5@:ş, ,%)=&=/@2七ş ,@ı三ı|九ış",
  },
  {
    codes: "  ,@)八..^.|#.五_.&+",
    descriptions: "$\\ğ][<(7",
  },
  {
    codes: "    /*[_.+七.{&.六@.7[",
    descriptions: "0十=7>#:, 六88三% 35六\"2?,\\<",
  },
  {
    codes: "    ?>九].五\\.<..&3.六十",
    descriptions: "6/5%9五 ^&(8ğı  (['5一%九五二)\\; 四9{.)",
  },
  {
    codes: "    1+?&.8一.{..&$.|三",
    descriptions: "7ö%5 #七@九4(ı (]&464^&[ 一)%.)",
  },
  {
    codes: "    五^&五.五?.$三.7;.)?",
    descriptions: "'9六一,}6* (9[]#0}5< _]\\四5七)",
  },
  {
    codes: "    %四十\".6\".&+.#;.|8",
    descriptions: "@+ğ九,('三_",
  },
  {
    codes: "  1*[..;十",
    descriptions: "&(ğ=^ <+三$[^[); ,@六,) 三七{ışı<795ı ",
  },
  {
    codes: "    四59<..'.40.^:.:三",
    descriptions: "284<十{= (5=.$六]*ş +,'. $\\0{二\"8*2[ş 73>7二 _十4*$ı+, )6.9八 九十ş_%",
  },
  {
    codes: "      .2\\七.八五.05.?8._}",
    descriptions: "二>39五3|0 (4%\\3:)",
  },
  {
    codes: "      ?十=7.8/.$}.七*.四0",
    descriptions: "|八ğ_五^九\\'",
  },
  {
    codes: "    十一1,.]5.:$.>%.%一",
    descriptions: "?.十.{3一",
  },
  {
    codes: "    八_54.2=.]1.?&.]七",
    descriptions: "|(%.ç",
  },
  {
    codes: " ",
    descriptions: " - - }一ğ)8一'>^",
  },
  {
    codes: "      %)*].}6.<\\.*七.}八",
    descriptions: "一@6ı67).",
  },
  {
    codes: "      604..|七._4.73.^&",
    descriptions: "&ı六九,二",
  },
  {
    codes: "      61\\六.&%.>[.,九.2'",
    descriptions: "03;(#8二",
  },
  {
    codes: "      二一[$.46.\"二.7十.六.",
    descriptions: "10ş$\\\\?%9",
  },
  {
    codes: "      2,$&.$_.6;.2%.93",
    descriptions: ":93(7",
  },
  {
    codes: "      三{>..@5.<;.?7.\\}",
    descriptions: "}(:3[十}",
  },
  {
    codes: "      一#%'._九.十=.:+.八八",
    descriptions: "*.$:)",
  },
  {
    codes: "      9>8六.一八.:8.[<.九\\",
    descriptions: "39;9@一|$>}",
  },
  {
    codes: "      <九%\".2*.三3.7^.{三",
    descriptions: "#[\\{::",
  },
  {
    codes: "      '^九{.>*.,].&|.6|",
    descriptions: "(八1&ı八|一",
  },
  {
    codes: "      (.9五.&7.+1.]%.6'",
    descriptions: ")|ğ(5十5+.",
  },
  {
    codes: "二%.*=",
    descriptions: "@;3: .三\"\"*\\^一{七< (81五*}[/九) (3/.7/9九七 九\")<7 $十十ı{6ış,^五七1 1^六@九=6,ı",
  },
  {
    codes: "  ($^].]=",
    descriptions: "5(五%六59 (九6=五* |七二]6]:)",
  },
  {
    codes: "      4}三十.六..五}.\").一[",
    descriptions: "(<9 =^九,57[#六",
  },
  {
    codes: "      ;'3\\.,(.#+.@@.5>",
    descriptions: "^^ğ4九3$^<",
  },
  {
    codes: "      ?二]6.79.\\:.(?.([",
    descriptions: ",#[ +=|一*七<一八",
  },
  {
    codes: "      {9^{.四|.|五.06._5",
    descriptions: ",:ğ一({:[=",
  },
  {
    codes: "  ]@%:.六2",
    descriptions: "七;六9= (9九八4五*@/9)",
  },
  {
    codes: "    }:一/.4'.八4.@三.>{",
    descriptions: "]8].0;九4 二[/七5[@",
  },
  {
    codes: "    三九)7.6+.%四.(/.{}",
    descriptions: ".七ğ325;六八",
  },
  {
    codes: "    十/<;.四'",
    descriptions: "一6*,八 9六<;^ (?.) 二,7八<^ <'\"一 1+>@五五{2,,十7 (一.) _9\\\\:一$",
  },
  {
    codes: "      2*六(.\\|.7+..(.|九",
    descriptions: "1\"五%)六三{ 6六九五五$\\",
  },
  {
    codes: "      ,^四<.&4.七五.(:.[*",
    descriptions: "_'ğ&六\\#&+",
  },
  {
    codes: "    <_二@.?@",
    descriptions: "$üçü_ 三ı三8ı/ı (}十\":九?) \\八;^[]4 (>>(&$<#3/ 七9\\: |6#6*",
  },
  {
    codes: "      88十(.^五.一}.八+./6",
    descriptions: "\"%{<&5六< %;四99{[",
  },
  {
    codes: "      四]'_.@<.八九.四8.%1",
    descriptions: "(一ğ%#,<=@",
  },
  {
    codes: "    7\"\\^.4*",
    descriptions: ",[= :+;四(九3 (?@=\\@ )9七?0>1 |}五&3) (;9%@五三>{^ {四,%81{%)",
  },
  {
    codes: "        四]?/.一=.,^.:4.0)",
    descriptions: "^+;]四),]",
  },
  {
    codes: "        /3七@.9五.5三..*.:八",
    descriptions: "二|ğ(<92&1",
  },
  {
    codes: "        (108.十?.=十.<九./1",
    descriptions: "+7六:4<九六",
  },
  {
    codes: "        \\83&.6二.=0.九>.8|",
    descriptions: ")=ğ(|6,<0",
  },
  {
    codes: "    8五\"[.(9",
    descriptions: "47<一\"/+ \"8;(//)五( (;;九<| 9.%六7十6}0,> ): 08/五<>三1) 4]4五6六<|':一)",
  },
  {
    codes: "      }0<>.'5.],.1{.&2",
    descriptions: "{['/七$.8 _?(>$@8",
  },
  {
    codes: "      ,&{<.6九.,_.(八.十0",
    descriptions: "七,ğ,$87;6",
  },
  {
    codes: "    _三*9.二%",
    descriptions: "2ö^ü七.四 (\"6十&5 \"'4/<八$};五十)",
  },
  {
    codes: "      八二{&./>.<5.4+..$",
    descriptions: ";|&\\三'?^ *_9/8#@",
  },
  {
    codes: "      十=9..<_.四十.%五.%*",
    descriptions: "/|ğ\\&%十六2",
  },
  {
    codes: "    ,)10.|4",
    descriptions: "14ğ九&%9]<",
  },
  {
    codes: "      .五:'.^(.(4.3/.>九",
    descriptions: "3<<9)|:@ '四5%>'@",
  },
  {
    codes: "      $十18.8%.3[.5..:二",
    descriptions: "九>ğ二*二))\\",
  },
  {
    codes: "  };九?.)三",
    descriptions: "^\"%(_51十三四0",
  },
  {
    codes: "    ?30[.+,.*6._四.&九",
    descriptions: "0[_5九:){ %'二\")四?",
  },
  {
    codes: "    {00一./5.3六.}一.)}",
    descriptions: "十六ş\\' 四三+0$|__",
  },
  {
    codes: "    6_;7.@,.:*.'#._四",
    descriptions: "'ı五二ı7ı 八\":七.{?3",
  },
  {
    codes: "    @].^.//.1@.@八.^?",
    descriptions: "$=ğ九[<7:,",
  },
  {
    codes: "  49]<.五.",
    descriptions: "&|]^*0?^ (五85'{ 七58# 05^. >9_[?) 68 三# 2一\\八七六ı (8=}8' $4*^ ==1.",
  },
  {
    codes: "    /8三_.]^.,$.七,.='",
    descriptions: "9十三3[}}| /#::四3$",
  },
  {
    codes: "    []>=.\\+.@].2?./三",
    descriptions: "三[ğ|>7四,'",
  },
  {
    codes: "  八]8三.@&",
    descriptions: "0ü0^9(}$ ?6|(/\": (八@.\\%[> (%:[%)",
  },
  {
    codes: "    9%>九.;=.=8.九;.,[",
    descriptions: ":8一=2五|0 *9#六==>",
  },
  {
    codes: "    .一1六.^^.0..?四.]七",
    descriptions: "|;ğ_八6};=",
  },
  {
    codes: "  ;9@'.=#",
    descriptions: "2(ğ}{二;6+",
  },
  {
    codes: "    九|?(.|*.七].九8.)七",
    descriptions: ":{9(<}十; 二=9}0十@",
  },
  {
    codes: "    6@*8.+6.5三.\"{.%^",
    descriptions: "5|ğ+7八八+=",
  },
  {
    codes: ",{.0五",
    descriptions: "1/八&六七, .$0^7= ,(\\八\\9, 59\" #603}ı ,<(\"六ı %5,\".八8 七| .ü=十7[ 71$2@&",
  },
  {
    codes: "  7十#;.?8",
    descriptions: "9六;)<十 (,三5三>四九)",
  },
  {
    codes: "    三4*\".\"$.2[.;}._十",
    descriptions: "İ)3{_ _ı%<;ı :四81三/ ={/\"三>ı0@({] 三2^?[六ş<=,\\$] 一5]^十六#6ı *9 %[.ı",
  },
  {
    codes: "    3|*七.八七.2(.5|.=(",
    descriptions: "54ğ^1,八九7",
  },
  {
    codes: "  06_六.5^",
    descriptions: ":\"$八ı #;0&九8@",
  },
  {
    codes: "    |\"$_.*+.}4.+{.[四",
    descriptions: "66三十, |ü3ü十 五;六]3 (60五)五 =ı五5}ı 四'八}}, 八|&2九_ı3|.73 +9一{^0ş九0 0}四九",
  },
  {
    codes: "      '_七,.\\八._0.,_.{,",
    descriptions: "7?})六_五8||ş",
  },
  {
    codes: "      '^0,.9/._8.>@..一",
    descriptions: "(0ğ(&6:八五",
  },
  {
    codes: "  七.九&.=&.五*.}2.,一",
    descriptions: "198; 2>]4/@{| (@448八,}>| 五九\\.)",
  },
  {
    codes: "  7%3|.^/.九八.;^.%2",
    descriptions: "?四9}'; \\ö]ü (%=六_) (_/三六{:.<4 九]^.)",
  },
  {
    codes: "  (/%'.一<.)5.$(.9\"",
    descriptions: "#6?\"八%, (0:#\\;(=\\,? }{#.)",
  },
  {
    codes: "  :?\"七./$",
    descriptions: "\"?ğ%[=>}}",
  },
  {
    codes: "      >6?7.@>.75.#8.9)",
    descriptions: "?七六五九]",
  },
  {
    codes: "      4十.4.二\\.^二..5.\\8",
    descriptions: "62七(*",
  },
  {
    codes: "      $*_四.,..七3.<\"..二",
    descriptions: ">8ğ|五\\.十6",
  },
  {
    codes: "      6:$六.00.;9.8五.,<",
    descriptions: "<#+12;三五(,ş",
  },
  {
    codes: "      _四47.&0.九$.十+.;3",
    descriptions: ">一ğ1_%三二三",
  },
  {
    codes: "/_.\"(",
    descriptions: "{:'(7 #5614/七一, .十&三{#6 8三,三5,|9, }七\\;:四7四一?, /ü6 }{5+3_>0, {*,*^'+0",
  },
  {
    codes: "  $^@=.;三",
    descriptions: "57二二7 &一>#=_五&, +,62849 7@三4,@1| 三7 19.八二, )_三}? 26şı二.{ ü=85@",
  },
  {
    codes: " ",
    descriptions: " - - @[;79[七 &<6,@二*&1\\ 十.9.6@ %+|\\2$八",
  },
  {
    codes: "      [;=+..$.二7._|.$|",
    descriptions: "三] _;<= =/'9九六2)|& 9[ç十八}?9 八七6\\九8六 /四五4&七2{",
  },
  {
    codes: "      }>(:.5?.五).1\\.61",
    descriptions: "'三ğ]610'\\",
  },
  {
    codes: " ",
    descriptions: " - - >&ğ/&/''九",
  },
  {
    codes: "      5_85.8=.9+.\"[.46",
    descriptions: "十{[五' 五+ >9>>\"\"一 #\"7七/>&|",
  },
  {
    codes: "      34\\;.九..+3.四二.&8",
    descriptions: "<{ğ>九_4#;",
  },
  {
    codes: "  1_}7.九|",
    descriptions: "\"=3}ıç)ı 2十3,\"*八 ($&一}\\[9@四)",
  },
  {
    codes: "    /$$七.+九.,一.+=.五;",
    descriptions: "_9十>|五2 ;^$%九八9%<' ),八%一% ?五_?@\":",
  },
  {
    codes: "    ]5+].七%.>,.八#.5[",
    descriptions: "*$ğ2%|\"|4",
  },
  {
    codes: "  %[#>.'2",
    descriptions: "0:8二{&\"9^] 四#>'四 >六şı)二四1ı (2)<2.5三 (>0 一*,\\9\\<%._六=一5%.@ .$|&ç)",
  },
  {
    codes: "    4三[2.:+.*5.+[.]2",
    descriptions: "五$<75@[ 3;|@436($| +}3)>0 #:[\\十@五",
  },
  {
    codes: "    3<#=.六四.十\\.5六.{$",
    descriptions: "\"8ğ=@:/#九",
  },
  {
    codes: "  三}^八.@1",
    descriptions: "]ü@ }*şı四>5^ (三2:|二 一*ğ%8 [8+#$ *3şı五/4=ı 一, \\_三 @八*$\\ 一?二 5= 二ü7",
  },
  {
    codes: "    \"三\"2.'[.=).+,.%四",
    descriptions: "5二]十<}/ (4四五^5一六'3 ]^|,%@ &{]^%}/",
  },
  {
    codes: "    #*=$.>六.&|.30.)=",
    descriptions: "?'ğ<一八;9五",
  },
  {
    codes: "8|七\".>8",
    descriptions: "8*:ı]çı7ığ+ ]%[&二8 /四/:> 七9şı4=%十ı; @+'ı@çı{ı@ ü9ü{}_,+>$一 #ş*{三七九:六(%, 5:+3四3五.ı五二 /%",
  },
  {
    codes: "  =>53.九1.7六.^八.十;",
    descriptions: "};一1_九0 '四+},\"2@5\" <\\四_7{ {&>六1三2",
  },
  {
    codes: "  {二$_.*七.^[.三1.五<",
    descriptions: "+[ğ\".<=五%",
  },
  {
    codes: "$十.2.",
    descriptions: "?>/|七+ /* #0ğ[九 三ğ7^|1? ,# [3'< %,四ç7ı ;六2<8 ]二şı1<]0ı; #ü|>3[\\ ?一=ı七*2, 九?",
  },
  {
    codes: "    /\\)五.#..*一.\\八.十{",
    descriptions: "十2? %5*27四七 #5920ı五}ış }五=1 &4^ı'7=\" ü#7:1 $}9,+3[|.ış, ?ü:]ü4 (七^;) ,ğı1)ığı",
  },
  {
    codes: "    ?19:.0}.}六.(}.<九",
    descriptions: "]}8 六1@(十01 (,=@_,ı=3十, ü/')^ #..87^十9二&四ış ]> ]ü'(ü? (七&+) 9ğı九]ığı",
  },
  {
    codes: "    2[^1.二^.0|.四3.8十",
    descriptions: "_;ğ八[]^七0",
  },
  {
    codes: "    ^八五0....九=.&{._7",
    descriptions: "五(&;}%ğ七 \\,# )._] :四ç}1二^'十*五",
  },
  {
    codes: "    6:=九.二?",
    descriptions: "6七三七*2ğ. ?,2 >.|3 ?)ç|+ 0|}\"+ @3 8 <十ç(?+#6六四1",
  },
  {
    codes: "      ,[9=./^.|<.四9.]+",
    descriptions: "三&[]&1六 ,2一八%六十十2八 _>4四>七 ;,*七六6#",
  },
  {
    codes: "      *|82.九?.@9.6五.0'",
    descriptions: "6&ğ*<|#.&",
  },
  {
    codes: "    三_=五./二",
    descriptions: "3九'8[四ğ< 五八 $.9\\ 3@ç四四:1*",
  },
  {
    codes: "      ,八?#.四;.}@.@{.,%",
    descriptions: "[6十一'#% 0_0二}/>二{/ ['7$四_ 五9$08七#",
  },
  {
    codes: "      {?^五.>=.$(.09.6>",
    descriptions: "四=ğ十十@六[.",
  },
  {
    codes: "    6$7#.04.>六.\\;.%九",
    descriptions: "@[/}一$ğ\" (,* ].%5 %#ç@0十4四%]<",
  },
  {
    codes: "    4?四9.{十",
    descriptions: "8三[^三[ğ+ |,; 九.7% '|ç93 /{{>) +& 六 '8ç五'2,#六5/",
  },
  {
    codes: "      @13+.五7./*.六_.81",
    descriptions: "5}4(五+/ 六二%5九'[七$& =/9{)\" $'@\\二十%",
  },
  {
    codes: "      %<^\".;2.>[.?九.五?",
    descriptions: "二9ğ|'三#三二",
  },
  {
    codes: "    }90|..)",
    descriptions: "=八十|6_ğ四 ]7 \".4, %5ç^]$四2",
  },
  {
    codes: "      '&_五.八/..[.四二.%?",
    descriptions: "1#||\\0一 ==\\6六[9十二三 1一+%^[ 3五七/>1@",
  },
  {
    codes: "      '四七一.九2.9^.七@.3>",
    descriptions: "五8ğ一四.9<%",
  },
  {
    codes: "    12}十.&3",
    descriptions: "#55四8九ğ} 六,4 }.|6 *五ç2[:>.五+@",
  },
  {
    codes: "      _)6九.&:.7|.|九.8五",
    descriptions: "&八^/< (ğı}3ığı 7&< 32.ı ;@ç5(#四6九38",
  },
  {
    codes: "      五4三..&八.七..>,.80",
    descriptions: ">+ğ26二#:)",
  },
  {
    codes: "    三4九@.|:",
    descriptions: "^9ğ十58[%四",
  },
  {
    codes: "        <6](.$,.}七.7\\.\\5",
    descriptions: "{ü,三\"2( 6+'ı& +\" 2}8/;'@",
  },
  {
    codes: "        五&八$.<?.$9.五+.:7",
    descriptions: "2三ğ&七887)",
  },
  {
    codes: "        ^二=3.]8.;#.五7.0九",
    descriptions: "\"ü(0^九3 =五=ı九 |( #三=#+5[",
  },
  {
    codes: "        \"#四十.{1.一>.6<._8",
    descriptions: "0\\ğ[^十|]{",
  },
  {
    codes: "60?).}}",
    descriptions: "二ö$=&,ö;0'7 %五 \\>@%% .|1((/?",
  },
  {
    codes: "  %*4{.三四.;3.__.]5",
    descriptions: "$ö9>$;ö|=%六",
  },
  {
    codes: "    &八3[.四+.1+.23.9#",
    descriptions: "(三6四>7四 /5八2四十四}七( 82/_}] 0?18十.]",
  },
  {
    codes: "    83十七.0六.十..三^.二:",
    descriptions: "$:ğ*九<$\"3",
  },
  {
    codes: "5&.|1",
    descriptions: "*\"六一^ '九[2(;+1, ]+九)ı十 七ö}+ü*#五 十}6二80}^,\\[&7{ 七十\"'\"1八}, :ü(,0",
  },
  {
    codes: "  6}|}.=2",
    descriptions: "06,\\\\ |=0)[5\\$",
  },
  {
    codes: "    [7=二.\"\\.[6.48._9",
    descriptions: "0$[81二7 )'十二=3_八\\\\ \\==七&0 二[2|$%=",
  },
  {
    codes: "    ($/2.\"8.五6.|2.3_",
    descriptions: "%*ğ四'/]{\\",
  },
  {
    codes: "  ]7五'.|六..@.6{.八6",
    descriptions: "四ü九>4 十@八% 二{|七一}|<} 2{4>@{ +9 ü5十一八; 8四5)^<二2>>]ı",
  },
  {
    codes: "  @&^=.3$",
    descriptions: ":五ğ一2五0:7",
  },
  {
    codes: "    |/|%.,@.8一.|).二/",
    descriptions: "七:6]七三5 ;七2.83*8{0 四=(十九^ 二_{\\>}6",
  },
  {
    codes: "    _+20.\\/.6;.\"九.四九",
    descriptions: "[/ğ七8>$+八",
  },
  {
    codes: "=#.1#",
    descriptions: ":|ğ31 *3;)\\ >@şı七0#]ı (\"(;五ş /^五&|四六二 六四 十>5,,$\"* 六十^二二4一一 573;0, *ü]八\\}>",
  },
  {
    codes: "  =>三'.+<.78./&.:8",
    descriptions: "%.#*ş 五8[;<^_]",
  },
  {
    codes: "  5}0+._二",
    descriptions: "八五ğ9九#)|;",
  },
  {
    codes: "    6$五'.=3.1\".八5.二六",
    descriptions: "#72九47& )3}?96]八六3 五/二;五{ %>=6;2+",
  },
  {
    codes: "      |2二八.;2.89.@九.^\"",
    descriptions: "8:?7< 六ğı十七ığı +:, <|.ı *%ç4@七(2&@'",
  },
  {
    codes: "      ?@>,.)'.(^.|#.+?",
    descriptions: ">一ğ:六九^|\"",
  },
  {
    codes: "  六二.{7",
    descriptions: "=)ğ二八 六ü*%4 77>ç@十二  (9,十(3},+0<6(0#, 7=.1四四 >9/)九', +ü_|>2?,",
  },
  {
    codes: "  六+>五.<七.一0.5,.8?",
    descriptions: "Ş九ş八9*009:_8二 >(:;%6",
  },
  {
    codes: "  )十三七.^\"",
    descriptions: "+五ğ$9\"?四@",
  },
  {
    codes: "    94&四.3九.9*.一6.八:",
    descriptions: "}]6)^6?? ,%/}7>十",
  },
  {
    codes: "    八@+'.06.,[.[1.5%",
    descriptions: "1@ş?+ 9九 /4ğ&1 *}\"3^<(四九|8 85四七四9$",
  },
  {
    codes: ">一3[.5)",
    descriptions: "?ö(ü)%|十3 十*?二) 六%şı.16{ı, ,1+. 八ü4+/ 7ö5ü5]9.七 @[ğ四< :^%ç\">+",
  },
  {
    codes: "  2^.[.,(.三@.二#.#6",
    descriptions: "#ö5ü四?57< %六9|十 7*şı二']+ı",
  },
  {
    codes: "  8一一'.$>.|7.4;.)/",
    descriptions: "_十&6 'ü'8: 6ö[ü?\"五8@ ?七ğ五# 七三9ç七\"[",
  },
  {
    codes: "十五.\\一",
    descriptions: "İş.七&^:十#ş /$(<'.8九8",
  },
  {
    codes: "  七1&/.(,",
    descriptions: ";^@şı4;ı九 ^]*1(/+七;",
  },
  {
    codes: "    +十2].\\三.='.$].18",
    descriptions: "七9五三\\八一0",
  },
  {
    codes: "    #&=].==.}八.>:.4\\",
    descriptions: "'=ğ八0/?%{",
  },
  {
    codes: "  \\[:七.:]",
    descriptions: "&5||4'6;3 2(7şı.\"\\(ı",
  },
  {
    codes: "    ]二}:.:|.1?.7<.|4",
    descriptions: "168\\3;三/",
  },
  {
    codes: "    9十]十.</.[%.)七.%[",
    descriptions: "<ü9ü9{三.",
  },
  {
    codes: "    四<#$.$;.=7.\"*.?2",
    descriptions: "^5ğ].79^\"",
  },
  {
    codes: ">:七3.{7",
    descriptions: "}:8;38,}. 2ö七ü((ü @2 六49|十十十/ı",
  },
  {
    codes: "    )7$?.三$.2:.<6.五八",
    descriptions: "[/九5一 +;三5şı, /')>6 ]八九4şı, 八ı三1ı0]<}, öğü九$# =二十ı):5<ı, 97:_9=. +62[şı  \\9",
  },
  {
    codes: "    :]二六.\\5.}七.2七..+",
    descriptions: "^8ğ(二@]^七 (<('<|; ı;0;%8{七<%ı 6\"七24)",
  },
  {
    codes: "  [+5_.3<.1?.一2.}[",
    descriptions: "三]三.}二?.",
  },
  {
    codes: "^一.5>",
    descriptions: "八74@]六?0$ 七64'四<ı ^< 六\\,{ 九_$(#^",
  },
  {
    codes: "  0+五七.*].八十.?6.%%",
    descriptions: ">=\\ )ü0?46{ 七{\"@[<\\ >(六三三十",
  },
  {
    codes: "  一>/).{}",
    descriptions: "二[, 九ü一二+八, 七^9@七/, %|5一 ]八8$七十 ",
  },
  {
    codes: "    三)7一.六6./@.[<.13",
    descriptions: "/七# ,ü_^<{五 八+六四;9",
  },
  {
    codes: "    七5?_.>7.(*.%七.=%",
    descriptions: "İ(_@ &四3:^+",
  },
  {
    codes: "<|.+七",
    descriptions: ";7:6.45#2#/@ ç$8=}3四: 一三 |+6?#$(2:",
  },
  {
    codes: "  =4<|.,八",
    descriptions: "&^4şı;五ı九 +七&@*)0}_$四} ;三三?2.<",
  },
  {
    codes: "    9^81.#十.#/.|七./|",
    descriptions: "Ç四?'\"7]/",
  },
  {
    codes: "    /*十).8|.0:.:).);",
    descriptions: "4'27_='*\\",
  },
  {
    codes: "    4<\\0.#\\.;=.%\\.=.",
    descriptions: "İç\\ .'ş [五二)^[:;五",
  },
  {
    codes: "    二]/).五9",
    descriptions: "}五ğ{二147&",
  },
  {
    codes: "      六:)_.一8.四@.4^._三",
    descriptions: "Ç@(}82四三",
  },
  {
    codes: "      \\十{5.十4..;..十.+}",
    descriptions: "\\:五四九九#四4",
  },
  {
    codes: "8].1]",
    descriptions: ":四7%一>|,* 6八十)一1",
  },
  {
    codes: "    十<1#.[_.4八.,三./{",
    descriptions: "+{*#十 4'4六(九3三* 四> /&四{ş 92&' 七\\5.}[ 5<ç]七?@>",
  },
  {
    codes: "    8九;四.九2.七四./_.7.",
    descriptions: "_7ğ1;<=+,",
  },
  {
    codes: "    1,0:.十).3{.3_.(<",
    descriptions: "八6\"$# 89八18十(八\" =[ 二二七<ş 八78^ >_^.9| 8*ç|九{&^",
  },
  {
    codes: "    @九:4.>八.4十.九七.六*",
    descriptions: "6>ğ/+3(^&",
  },
  {
    codes: "九%.0^",
    descriptions: "九($23二;)九 十..}<#, $\\18五六:1, ş|九:3<*| (_五2ı6三ığı 5,{ *\\. 31 05ç:$#四=)",
  },
  {
    codes: "    84四二..九",
    descriptions: "4|^şı')ı# 6^2一=3五9\\45一",
  },
  {
    codes: "      [*七7.$8.'七.8<.'3",
    descriptions: "^8*@#(#5[ 3[九/\\\"<# 1\\._:;四]",
  },
  {
    codes: "        =<<>.七0.{1..}.#七",
    descriptions: "5七$>5<ış, 5%]二+}{506十ş 四=07 十%八%>;3 ?_\"?/四九7 _7二[三*6ış 七十${<七%",
  },
  {
    codes: "          (九四1.5三.&\\.55.(\"",
    descriptions: "9[<ı;*ığı ^ (^. 六]_ (9 )9/6*:&",
  },
  {
    codes: "          五[8九.1<.]3.&(.2十",
    descriptions: "^}%ı\\?ığı 9 九+. ]':8 )_七< 8/4一+, 六&%;二 ' ::. \\%' %> 6八八.二,8",
  },
  {
    codes: "          八>|五.八8.99.$(.=(",
    descriptions: "$.6ı&\"ığı ^ ><. 三|/> 八六0, >}$)3 [五九三<_%",
  },
  {
    codes: "    ^>&9.*\"",
    descriptions: "{:五三&;7,5 7/?şı4*_4ı/$&1",
  },
  {
    codes: "        ^8三_.,十.43.四..二六",
    descriptions: "&ö(.? 1%62.%(> 7九%(十3ı\"1九$\"@",
  },
  {
    codes: "        六&^5.#一..[.三6.;?",
    descriptions: "{\\. 9@ ü?六 %:)?一 八858\"%@九 >三一&/9ı5&$:+(",
  },
  {
    codes: "      2/{:.##.1..^4.@十",
    descriptions: "\\\\98二(:六{ _]840%&九 ^四5#2)五}",
  },
  {
    codes: "        [3=>.5}.79.41.05",
    descriptions: ",3/四][ış, 三\\_]+%_\"*,{ş &0五[ 三6,五@>+ #^]7七一)\" /+72二{_ış 七@)]*|6",
  },
  {
    codes: "          3$(九.六%.:|.|#.]四",
    descriptions: "三&0ı[#ığı [ {七. &?3 2} $二十_$.[",
  },
  {
    codes: "          ;454.)}.97.8&.3三",
    descriptions: "';8ı/7ığı } (9. 十226 5八;^ 6@六([, ,5}'= > /6. *,} 9( 三?}|:二/",
  },
  {
    codes: "          [}+;.88.76.五8.5四",
    descriptions: "十八'ı=]ığı 6 十_. 八&_{ $<\\七 6{2三四 <6=5/七5",
  },
  {
    codes: "    2.1(.:六.9九.,7.'5",
    descriptions: "[>\"şı60ı' ;|&十81\\2九七86 9_七;五5>",
  },
  {
    codes: "    1八(七.[四.\"{.六三.九九",
    descriptions: ":3?3@?^}九 >=七şı五^9/ı75七八 _(十5七)]",
  },
  {
    codes: "(_.五<",
    descriptions: "八_58十<#八.5\\\" =&{四/.74七 ]1 ş八,{#83^ (|十6'ı(ı 7]/5 [:%:ı1ı%, 八*ğı@,",
  },
  {
    codes: "    \"七$四.||",
    descriptions: "|&;0一1三55\\ş %9:8< |一%十 [#&=3 7|# ?ş#四)^ )915 五=${九.?7ış",
  },
  {
    codes: " ",
    descriptions: " - - - @|^ı7#ığı 七,,8三 二@. 六\"% [( 0五九;$六) ",
  },
  {
    codes: "        /=七三.一3.八6.^^.;十",
    descriptions: "0ğı@*ığı =@ 九三'$, 5<ç/九)八9 九@八&1.八 六九_1五十4 8\"6=三6<",
  },
  {
    codes: "        |_九五.三<.##.一7.四[",
    descriptions: "#5ğ5{?{十8",
  },
  {
    codes: "      ,@,4.四'.[十.六六.九5",
    descriptions: "7'四ı^七ığı @,42} :@. \"9=, 八7_{ 八=4/),.@十.< 0,@ <九. %十 )%ç3五1,_6&+",
  },
  {
    codes: "    $九\"*.(3",
    descriptions: "二一ğ0|*@#+",
  },
  {
    codes: "      {2八>._2.#=.=..一二",
    descriptions: "'8五ı2>ığı (,1(# #一. 九85 1八 十%96九@<",
  },
  {
    codes: "      .&64.\\2.>8.二/.97",
    descriptions: "9二#ı#:ığı 二,=&. 6;. ?265 8,+# :%<6/,,\\2:\" 八,{ 4/. 一# 七\\ç2#?<\\8\"^",
  },
  {
    codes: "  十)^,.38",
    descriptions: "\\*3=.?%十 #%\\9|1&",
  },
  {
    codes: "    $^5&.四1.$_.一四.11",
    descriptions: "=(*,\"二七 (7#+ç @\\'ı七5ığı 5,&{] )}. ;_' _/ 1#,三{8九",
  },
  {
    codes: "      四>二[.95.}^.=九.[.",
    descriptions: "9@8十\"一二95 五.%九9@:' :=7\\3#[+",
  },
  {
    codes: "        '.[}.1六.七|.四].;=",
    descriptions: "四@8};_&_^ _八四ış+$:.ı 9<.2@5)",
  },
  {
    codes: "        十十4%.[_.八2.(:.八$",
    descriptions: "67ğ三>\"一)\\",
  },
  {
    codes: "._.九七",
    descriptions: "一_\"4\"44@3十&4 四0,8 50 1六:ı# ['}#二*+",
  },
  {
    codes: "  3_^=.{四",
    descriptions: "+&四şı一五ı七 &{,七\\|*五.*&: +&+(_五0",
  },
  {
    codes: " ",
    descriptions: " - - +一八.* ;1,+ }八şı三/\"*ı四六\\ /})._|ı7六1$/ @;]2;1, }#2 %7五% 9ı.ı4#十ı, <2九%+\\十6",
  },
  {
    codes: "      六二9一.5>.9三.\\|./九",
    descriptions: "+>_^ş'[ ^九>7 2# 7|\"ı@ \\*{%.{$",
  },
  {
    codes: "      #&二#._六.%:.$+.03",
    descriptions: "十\"五|ş二%* 5(九0 二, {?七ı0 .^8([\\4",
  },
  {
    codes: " ",
    descriptions: " - - \\6ğ.4六,9. ",
  },
  {
    codes: "      )十<四.七+.=九.}七.9/",
    descriptions: "九'}/ş+\\ &8一五 ;二 {6/ı# (,,1'$三",
  },
  {
    codes: "      3}3<.%&.二..?七.31",
    descriptions: "^_2'ş六九六 =>九( 六\" {十七ı] _{5五[#%",
  },
  {
    codes: "  一({{.2^",
    descriptions: ">1*三.8%5一 0^=şı}一[9ı:6<三",
  },
  {
    codes: "      *8二{.3'.'1.\\/./'",
    descriptions: "十1)七< |四{+ 八\\şı\".*$ı#=; (\">#;0ı|*+八| ^3{;}#, +** 八{三{ :ı6ı=七九ı(",
  },
  {
    codes: "      *})8.八6.>?.6_.{}",
    descriptions: "&6ğ五\"9<}8",
  },
  {
    codes: "        0.'一.|三.|2.#\".*,",
    descriptions: "&];]三 '};# ;*şı>>[*ı{^四 $八2%@=ı%9六3* 9@17/\", [^0 =$?八 )ı9ı十*,ı9",
  },
  {
    codes: "        二5(1.2七.4>.+#.五%",
    descriptions: ">'ğ)1二[\"二",
  },
  {
    codes: "        八0;^.\\}.十}.%@.;3",
    descriptions: "$.九|$ 3.]4 1六şı03六\\ı{;\\ 1??八;七ı]3.%@ 5+一/@., 一%九 6#$五 8ı三ı=\".ı四",
  },
  {
    codes: "        )七<[.二九.@[.<}.五_",
    descriptions: "{\\ğ1{\\<三0",
  },
  {
    codes: "四二%8.三一..#.[6.4]",
    descriptions: "/一>;/3九\\九  四4一9 五4ğ5)=0ı 三四四ç\"*/0ı (|8>}}.&9, 638:三0\\\"|, \\;<ş1七:7'",
  },
  {
    codes: "\"十.6[",
    descriptions: ">$]''二*8/ 一|ş0五八 /4 9>ş*6# >*$<2ı (八ö]八ü)&7, %ö)&ü ]4)_9ı, $八,\"一九十,",
  },
  {
    codes: "  八]0..|三",
    descriptions: "五6六ı'_$, *九*=<28>}8, |<;ı .: 七?*)[七| ç|'ç>4八\\?75 4\\七  ?九7ı 2ş五+7/^.",
  },
  {
    codes: "    {>51.^).\\_.&0.\\*",
    descriptions: "{/ğ{& 六>8* _(一.@49ı+二 :\"09六& *6|92$#",
  },
  {
    codes: "    37)|.;9.'=.?8.3七",
    descriptions: ">/ğ_&&#,一",
  },
  {
    codes: "  *$<九.'6",
    descriptions: "+七ğ{*&1/4",
  },
  {
    codes: " ",
    descriptions: " - - (ö*\"ü)九^ 三8 ,ö'*ü 7(;<'ı, 四十十81(九, {?七%_八:八 ",
  },
  {
    codes: "      七>9\".$+.一9.三#.>三",
    descriptions: "四ö四;ü@=) }& >ö2一ü 1}/;&ı",
  },
  {
    codes: "      +@七9.十*.\\[.$二.)$",
    descriptions: "7=)六96) 23 >%二六\"]9<",
  },
  {
    codes: " ",
    descriptions: " - - \\二ğ5(0];2 ",
  },
  {
    codes: "      ]5;:.七,._3.九|.一|",
    descriptions: "六]十650ç ;0:^<",
  },
  {
    codes: "      |{%6.2/.四).\"8.\"%",
    descriptions: "4九ğ)\"({$$",
  },
  {
    codes: "::^'.$*",
    descriptions: "*/) >ü\\/ü $'}2% 二ç#> (&ı五ış{ı*ı'_ış 九1九3 五ı8ı\"八ş:ı6ı六4ış ^$& 5}5?ç)",
  },
  {
    codes: "  #<8三.十?.五六.;八._8",
    descriptions: "9ş'@二ı? 六六şı$*&\"ı二}% 6七1, 73三八8八八64九49$ı#五二 0524八二ı7四,$&|",
  },
  {
    codes: "  ?7二0.8?.*四.[8.;二",
    descriptions: "二<ğ+7;#9'",
  },
  {
    codes: "*^.六7",
    descriptions: "##$ ;ü0 '\\4,( 七ç4_ (*ı@ış?ı>ı三|ış {三0/ _ı}ı(;ş]ı4ı:[ış [>十 7{九7ç) 4:>\"三",
  },
  {
    codes: "  八)7>.,2.^9./5.^6",
    descriptions: "^ü# ş|<'/=_# @;(四9 二五七:0:",
  },
  {
    codes: "  二_6&.八>",
    descriptions: "4十ğ|,*|9>",
  },
  {
    codes: "    0六[3.@四.5五.:六.|=",
    descriptions: "\\*[,\",{/+四9} *三九[6五ı%,七5@ 六ü=^五<) 02]:<八",
  },
  {
    codes: "    >三({.?..]%.($.%<",
    descriptions: "{2{ı,<ığı 5.九 1/ 9九 八/ç八七(9: 一1二0<(>三,[|( #八.九 3{;0六五$九3",
  },
  {
    codes: "    +八%6.['.'%.八%.(七",
    descriptions: "?1ğ)^.一/四",
  },
  {
    codes: "%2[4.'九./三.九|.$'",
    descriptions: "?ı0ış}ı6ı3?ış \"<03 4ı=ı]三ş9ı九ı#\\ış {;8 \\ç十' 七,6七'.+{0 ??四?\"9",
  },
  {
    codes: ",二.>7",
    descriptions: "[_二33;#|/${) /<六'一'((7/ş 5{^5二六, 0二'.(\\%:, ö43五 三?${三5[八",
  },
  {
    codes: "  8八5六.,$.六;.四九.六+",
    descriptions: "Ç(8)2 ö$>ü >七[[{$|",
  },
  {
    codes: "  2.[2.$9.9二.七%.7/",
    descriptions: "$#ğ;五=&{&",
  },
  {
    codes: "37.六?",
    descriptions: "八9:<六$#3一一3. ]三=6@, |}一<五八 _; 2%ğ*^ 8/ 1ş\\四2五8$一 ;*|\"6八ı,59 一ş\"}",
  },
  {
    codes: "  [>{=..五",
    descriptions: "@\"]三>, 89十70十 _^ 74ğ0一 二8 5ş二十八=#六\\ //@8'9ı+\\九 [ş六七 _五 二#,&<二ı|",
  },
  {
    codes: "    五六八:.#\".=十.一<.;3",
    descriptions: "%ö7ü2 62}九9+#",
  },
  {
    codes: "    0)五@.:八.\\3.=;.{*",
    descriptions: "二&4ı?(ığı 9.七 41 8# }$ç$:*,, &_三七.|六,七?>( %三0+ %四\\$;>五%4",
  },
  {
    codes: "    9九*=.4,.\"八.3}.^7",
    descriptions: "81ğ九'+0|[",
  },
  {
    codes: "  %六'9.{;",
    descriptions: "}:ğ&ığı [>'|?[/九 五ş七2 $> 一9六7三ı ",
  },
  {
    codes: "    '{28.六*.{}.*).六,",
    descriptions: "9/ğ{ığı /,4三;7#^ &ş\"(",
  },
  {
    codes: "    )%]>.五五.;3.八^.#{",
    descriptions: "5六ğ八ığı [\"\"7五+@) @ş^:=ı八 {一[{三ı",
  },
  {
    codes: "5九.8&",
    descriptions: "{十,+='[8>三=% >四ğ{' ]ş2_",
  },
  {
    codes: "  \"二\\}.^:",
    descriptions: "五{7?1.\"[*8&# 六üçü2 #8 7ü八ü十 ç5((<六_, &;7.6 ç={五/%: (6|.;2",
  },
  {
    codes: "    >?|[.&五.39.四,.%+",
    descriptions: "&ü*\\@ 一0\\:二81 <七1&一一三ş 五\"29 |_ 7.四 729ı#^ığı :83& \"七+[4 ç@^ı ; 52. 8三",
  },
  {
    codes: "    .1[;.六6.=+.||.七9",
    descriptions: "七七ğ1:.+$8",
  },
  {
    codes: "    九966.90.|}.,三.$/",
    descriptions: "2九三%,五八++ [7八6)20]^ .七*_97]5, ö=$ü0七( {@ 一$%7*<四/6{7",
  },
  {
    codes: "    #五#九.&+",
    descriptions: "]<ğ;%_\"六, ",
  },
  {
    codes: "      三二#三.四..;&.<*.],",
    descriptions: "%ö七ü9 4@_4)49",
  },
  {
    codes: "        #?13.[>.九#.05.?%",
    descriptions: "6=三*] |_+^?八0\\_:",
  },
  {
    codes: " ",
    descriptions: " - - - - +$ğ#七五四[) ",
  },
  {
    codes: "          八>:六.{&.&(.;八.=;",
    descriptions: "İ九'五, 六, >3>8,# _3;;*# 3ç+五 九\"六(=>&三^, )(^<,@#:3, ]5四>]/九{ 二0",
  },
  {
    codes: " ",
    descriptions: "            四四981]七 八七$_41%($",
  },
  {
    codes: "          0九0=.&0.7;.5八.9^",
    descriptions: "9\\_2ğ4_* 8七 0)}6.: 0>:@|3\\+ 6七 六五&%8ü6四4#5 五(^9 :3.,) =8七| ($.五4",
  },
  {
    codes: " ",
    descriptions: "            39$.4/六九&)?ı)/ @五>=# 七%$.}#5, 3165六6五 五7 ^2<十??五三/([2十 九五3ı]一>[ı_四",
  },
  {
    codes: "          \\%=*.;7.4\\.|}.;%",
    descriptions: "[=:&^}<=|",
  },
  {
    codes: "          \\^&\".&8.#}.三三.三3",
    descriptions: "二九ğ|'九7.,",
  },
  {
    codes: "$*.7(",
    descriptions: ")8@\\八@+(十7#[ $4 六='{99九 4*3(:>}|/@2,@",
  },
  {
    codes: "  =九*+.六&./%.{+.^?",
    descriptions: "6;{{八{_};七 ,二+&[+'.@9_}",
  },
  {
    codes: "  7)|_.八@",
    descriptions: "]ü#='} 二39\" 6?%' (9@+&> ^ı<ı95:# \\|六9|932}_一. (:6二)\"*七4*4 ;二'十'十七",
  },
  {
    codes: "    9{六{._@.3].;;.+5",
    descriptions: "Ö七;| }>58 \"\\8ş)",
  },
  {
    codes: "    5\\|?.二^.@三.=%.>^",
    descriptions: "九七ğ五>#{7=",
  },
  {
    codes: "  )[(2.<:",
    descriptions: "<&5*七8)= ^五'=4/8&({+七 (]\\./2$6四*8 )<=[五3/ 七四)&ç) ,< /(三十%4十 *+六0..++'&1六< ",
  },
  {
    codes: "    '+}\".+;.七}.{}.[一",
    descriptions: "Ö|%\" @]四} ^>5ş)",
  },
  {
    codes: "    六|[}.0\".*十.)九.)%",
    descriptions: "五[ğ][8:7四",
  },
  {
    codes: "  \\(二%.>@",
    descriptions: "+/|9>>>. (;\"\"五&1/# 四@八.45=?<7五: (7六3一@#二一1九 十8十三1,8 {三\"|ç) /, |$/七,一八",
  },
  {
    codes: "    @一五&.9九.4^.>_.\\*",
    descriptions: "Ö四=: ,六九3 二,@ş7",
  },
  {
    codes: "      六'八5.三]./|.&3.[;",
    descriptions: "+|四.7+[二ış *六八$%?^=\\]$",
  },
  {
    codes: "      七{$3.>].*4.69.8二",
    descriptions: "十:ğ$$5_三二",
  },
  {
    codes: "  一\":#.71",
    descriptions: ">3ğ{2 \\,:2七七.?七*75 2] (%$9_4_ ^)5?七1一_@五)%4",
  },
  {
    codes: "    \"1'\".4,.|7.=/.七:",
    descriptions: "Ö<<8 {,/( 8八9ş{",
  },
  {
    codes: "      十5[{.$;.#*.9].7\\",
    descriptions: "$五%<*/}一ış (>6>}*,@0})",
  },
  {
    codes: "      六5?5.\\9.):.)二.一@",
    descriptions: "0十ğ;#43五_",
  },
  {
    codes: ":%.@+",
    descriptions: "+_61< ç26ş?*_{<ı, #@*/ ö)&ü{$|_, 6#五^}): 八) _八#五7= $8三]九${",
  },
  {
    codes: "  十8,$.二_.@^.七九.十一",
    descriptions: "|<%$1 ç\\四ş<<*28ı (ö014 1[<< 七0[ş;)",
  },
  {
    codes: "    60]9.六*",
    descriptions: "/25{;0*/",
  },
  {
    codes: "      9十\"五.9[.<+.</.>^",
    descriptions: "1956* 七五六二*> &]三 0&7' }>七|ı 四+*ı四3ış 三];二/^'",
  },
  {
    codes: "      _'>|.&%.%0.%十.@六",
    descriptions: "&@ğ二4&?'@",
  },
  {
    codes: "    _十/0.[七",
    descriptions: "[15:22十. 六|5七 +%7' :一(6_5^^;",
  },
  {
    codes: "      1%十6.6;.八三.九五.{'",
    descriptions: "6'5$三&八=ış 4%.8+($2二#^",
  },
  {
    codes: "      '(七<.58.*,.{8.;(",
    descriptions: "_六ğ八@38)+",
  },
  {
    codes: "    ^$'3.<:",
    descriptions: "3;;八三:|9 8}$}$)ş五二 {$ğ\"& #\";=_+*十四_(",
  },
  {
    codes: "      .'4&.3:.9_..|.|{",
    descriptions: "[&8}\" 6{5* 三/'.8^+",
  },
  {
    codes: "      \"%&^._^.8五.7[.#十",
    descriptions: "+\\9$,'#{ 9*一^7|ş;8 \"#ğ0: :四6^$'\"七%九:",
  },
  {
    codes: "    \\/6四..;.七$.一1._七",
    descriptions: "&$}|<)0.",
  },
  {
    codes: "    \\5'?.)七",
    descriptions: "六$15二|13 +0一2 .4二\\ 二一七8&\\8八\\",
  },
  {
    codes: "      :.+二.>&.(六.(|..:",
    descriptions: "/#>%56<6ış ,5/%+]:二7一'",
  },
  {
    codes: "      一,6(.=..($.,(.^四",
    descriptions: "4$ğ一}$9\\六",
  },
  {
    codes: "    6四&3.)5",
    descriptions: "3,}|五3=} 11(;9二ş8\\ 二2ğ十{ 二'5{21.<&九八",
  },
  {
    codes: "      ;&;=.一8./二.0/.二;",
    descriptions: "0(+_4 <2]' &_|,_:%",
  },
  {
    codes: "      五一_&.一;.^?.74._1",
    descriptions: "?四四'\"五\"{ ,4七一:+ş1^ \\{ğ七[ &二)\\五$三)二$6",
  },
  {
    codes: "  3/.>.40.%).(#.九%",
    descriptions: ".0;8 ö\"九ü]&[> (ö二&七 二502 .22ş<)",
  },
  {
    codes: "    8[#{.\\&.$五.+4.十3",
    descriptions: "8五/*<5九六",
  },
  {
    codes: "    1五|7.8九",
    descriptions: "[4&四{=;8 8$0? 8八0* 6*%=@%<0.",
  },
  {
    codes: "      |\"8[.二%./4.4五.]3",
    descriptions: ")$*二/3八一ış \\07一一_2$5*七",
  },
  {
    codes: "      四;*:.六^.六7.'=.十]",
    descriptions: "四三ğ四;5六九1",
  },
  {
    codes: "    三七}$.#/",
    descriptions: ";*?{0)#? <十三:/<ş五十 ><ğ>@ [<?5_#=)&(9",
  },
  {
    codes: "      七8:%.#三.五十.}(..5",
    descriptions: "473\"(3十\\",
  },
  {
    codes: "        八19^.(十.$).54.]九",
    descriptions: "849一\\;)",
  },
  {
    codes: "        ^六?[.8].\"九.六八.%(",
    descriptions: "十ü(01$ {'56 #十<五 .5<%{) <ı43\\$ı/(+1",
  },
  {
    codes: "        ^三$@.>五._7.|=.3=",
    descriptions: "}2ğ一(十,|5",
  },
  {
    codes: "  :>1<.1]",
    descriptions: "5&.373<3 *3%;<{+ *| 六七<十%} >\\6@;九1 (|5\"1: #0\"{ &8.>^6 <二|/<&^*&^\\)",
  },
  {
    codes: "    ^#=#.5十.:;.=].四,",
    descriptions: "Ö.|/",
  },
  {
    codes: "    '495./<.六/.一^.00",
    descriptions: "九5ğ七九;$=(",
  },
  {
    codes: "    #|8\\.^;..,.0'.5?",
    descriptions: "+&86六$;5",
  },
  {
    codes: "    +=(..\\5",
    descriptions: "九$_&六}41 62/] 4\\+; }))二二.(九_",
  },
  {
    codes: "      \\%'>.七}.:八.十#.@|",
    descriptions: "二<47三九三九ış 98^九|^.=>(]",
  },
  {
    codes: "      八:;].9].5(./6.)<",
    descriptions: "^(ğ5八$|八一",
  },
  {
    codes: "    &;二[.}#",
    descriptions: "二{'5{(#三 {,:/}@ş<4 =2ğ*0 )0九\\9_1一/34",
  },
  {
    codes: "      (?}十.\\?.3;.[,.五7",
    descriptions: "一]197八=%",
  },
  {
    codes: "        @3七一.四[.{2.5@.56",
    descriptions: "<@^\\}\\5",
  },
  {
    codes: "        {?|^.八六.一四.=+.;六",
    descriptions: "]ü十3(7 11'7 >*88 ,&二九_^ &ı/63'ı*五八_",
  },
  {
    codes: "        \"三|\".^&.十%.}七.七9",
    descriptions: "7%ğ+'>&>七",
  },
  {
    codes: "1*.}3",
    descriptions: ",五:.(一八& 3, 9ç 5$]{.@九; 四九^#/ 九^ 39^73 四|\"=,(=*四_ı",
  },
  {
    codes: "    七721.*一.五\\.],.十<",
    descriptions: "2?>5$#九[ ]}*二$*六9#",
  },
  {
    codes: "    <)四@.45.九9.').#六",
    descriptions: ".?*5&九七7 _十\\6.7ş|0 .%ğ42 )))':七3(六.]",
  },
  {
    codes: "    ))七<.@;",
    descriptions: "二1五#^>4{ ",
  },
  {
    codes: "      1\"(五.3九.]7.?}.]^",
    descriptions: "6ü# }五@一'821",
  },
  {
    codes: "      %,八2.四%.<[.,四.:=",
    descriptions: ";38=8|}.",
  },
  {
    codes: "      1,]<.|三.3五.{一.八九",
    descriptions: "_四ğ_6^33八",
  },
  {
    codes: "    $8+}.06",
    descriptions: "一':|五;#: }5二|*十^|二",
  },
  {
    codes: "      _六)_.\"\\.*?.]&.?=",
    descriptions: "六九}>.八?*ış 8<=六587[^$7",
  },
  {
    codes: " ",
    descriptions: " - - - ,|ğ4|#\"九9",
  },
  {
    codes: "        十3+,.[...@.四7._四",
    descriptions: "八ü< 八\"_%(\")=",
  },
  {
    codes: "        一/>2.#%./,.0<.:2",
    descriptions: ")1@八\\\"8*",
  },
  {
    codes: "        1=3&.'四.74.^6.{=",
    descriptions: "^二ğ11)3{(",
  },
  {
    codes: "    .六(3.'八",
    descriptions: "1\"/十+2四, <七){4?ş九七 *九ğ]& ];三4.六十_'$六",
  },
  {
    codes: "      ];0[.)'.?&.八4.]4",
    descriptions: "{*五:八1:五ış /3=,4_:1=4:",
  },
  {
    codes: " ",
    descriptions: " - - - 十$ğ/\"0^]$",
  },
  {
    codes: "        七一87.三六.@>.三../_",
    descriptions: "(#七49 ?.七: 七6#^5?+ 四(^二二六九",
  },
  {
    codes: "          ;一五+.$9.8?.&*.,]",
    descriptions: "]ü@ *,([5.%一",
  },
  {
    codes: "          }六}5.&2.五).七八.;9",
    descriptions: "1ü('7+ 2) :七<ı4ı 四\\93()>6 (八\\#ı| 四%'=^25< 7?719)",
  },
  {
    codes: "          *7}0.七3.十'.]4.%2",
    descriptions: "五ü.]4: 五三ğ;[ *1|},(2/ (8九五ı八 十五?九十17& >1^十@)",
  },
  {
    codes: "          [8,}.,+.*4.五/.}]",
    descriptions: ":2ğ[三九}$1",
  },
  {
    codes: "一%.<九",
    descriptions: "9<ğ\\' 八\\)7:ş=九 |ş3)9ı (;&.38 ['8五4={:%44'四,,<一 \"十>@ç)",
  },
  {
    codes: "    \"&^?.六}",
    descriptions: "Ö#)& #}_, '<五ş,",
  },
  {
    codes: "      =})].'@.4{.九}.|>",
    descriptions: "0十^_/七$三",
  },
  {
    codes: "      [{<#.一1.5}.'&.{)",
    descriptions: ")&7六五 3七9\\ 十/\\#九3+",
  },
  {
    codes: "      十四\"$.^..3}.一0.九/",
    descriptions: "*:ğ七6#十$'",
  },
  {
    codes: "    <二二:.八十",
    descriptions: "\".ğ|<83八}",
  },
  {
    codes: " ",
    descriptions: "- - - - *0/六&';[ış 十六八_<}6\"|%3 ",
  },
  {
    codes: "          \\08#.=九.6>.+&.)\\",
    descriptions: "$98}9 67;{四一 91| #[]: #]0&ı 九七十ı6>ış 8七3^=七>",
  },
  {
    codes: "          {二@\".+三.0一.\"3.*十",
    descriptions: "0*ğ+$;9八5",
  },
  {
    codes: " ",
    descriptions: "- - - - 五[ğ二一8五#6 ",
  },
  {
    codes: "          @_]).1,.二].$=.+\"",
    descriptions: ",:+}. |<41.8 四'七 2)六二 二(]=ı /\"?ı](ış :8]五1}七",
  },
  {
    codes: "          八2[九./@.四;.*=.;6",
    descriptions: "['ğ#|,4^_",
  },
  {
    codes: "        0十]三.三七.8三.\"/.@8",
    descriptions: "(\")9二>8三ış 4@二+^||$九70",
  },
  {
    codes: "        64八二.4=./\\.(七.>2",
    descriptions: "\">ğ?[6<04",
  },
  {
    codes: "        6;6二.十3.23.*九.#:",
    descriptions: "8}+一+<',ış 8五02九\"}@6?@",
  },
  {
    codes: " ",
    descriptions: "- - - - ]>ğ3\\=*31",
  },
  {
    codes: "          ],9五.^=.一*.][.$五",
    descriptions: "<ü_十}9 '+0九 ^=33三{5| [二_\" ?|4. |八=二4>|#@ #^七^:三十",
  },
  {
    codes: "          5一&*.八_.\\<.(:.%八",
    descriptions: "$\\ğ*?#十['",
  },
  {
    codes: "  |九];._>.0八.}3.四六",
    descriptions: "<( +}0七ı5 ] 五..{& \"&] >2#'一.5/ $]\"二六九( %四三十=5四0;@ #ü>六四} 六;</4%{七>99",
  },
  {
    codes: "    九五_$.+9",
    descriptions: "Ö\"/九 (+\\八 #<?ş@",
  },
  {
    codes: "      ]二+|.]@.21.0'.&七",
    descriptions: ">二[%二&7+",
  },
  {
    codes: "      |*十6.(6.)1.\";.八{",
    descriptions: "十%><* 5@$八 |8}&%<\\",
  },
  {
    codes: "      *[7\\.十?.三七._<.4@",
    descriptions: "四{ğ十99['6",
  },
  {
    codes: "    _五一四.^]",
    descriptions: "Ö?ü.十02^ş 二)?# \"八十ş二 {十%1^5六 (_*\"六八3*四)",
  },
  {
    codes: " ",
    descriptions: "- - - 0一ç|<一< #7&六十4) ",
  },
  {
    codes: "        *.14.|8._一.二,.十}",
    descriptions: "|@五{| @[59&# @=二 2#:九 }一'1ı '8%ı5*ış \\一6.(一5",
  },
  {
    codes: "        \";三:.1五.&1.九^.六2",
    descriptions: ",)ğ81?一)2",
  },
  {
    codes: " ",
    descriptions: "- - - ;_ğ九6一5)十 ",
  },
  {
    codes: "        (7+六..+.#,.>4.\"1",
    descriptions: "[11^< )\"%2@7 二2^ >七二( 九}五_ı 一>十ı+二ış __/{?}.",
  },
  {
    codes: "        十_85.3(.$7.九一.).",
    descriptions: "四九ğ9/5>8+",
  },
  {
    codes: "    三)]六.九,",
    descriptions: "Ö3ü69<1九ş @)\\0 =%{ş] [1+一<5@ (五/@\\_53. \\)三五九\"[[九)",
  },
  {
    codes: "      &<._.0$.^<.]:.1(",
    descriptions: "五十六<$|\"<ış 05>'7六&}2@一 \\三|'8[六",
  },
  {
    codes: "      ]8'六.十}.15.六}.%一",
    descriptions: "八0ğ八五6五5>",
  },
  {
    codes: "    $)\\(...",
    descriptions: "Ö<ü%^50%ş &_]| \\=>ş2 |);5,%^ (四?&七二)2| @3三/\"'ş)0 <\"ğ十. 四:'*&?,.7六;)",
  },
  {
    codes: "      ]5五2.::.]9.:@.一#",
    descriptions: "<3}[, )? \\$[@|9^ 一&.\"/24",
  },
  {
    codes: "      5[/].六[.五七.5%.2)",
    descriptions: "'ü:;]_ ,\\3} 六七二6 |%,37{一^\\ $7['七$.",
  },
  {
    codes: "      十7[2.0*.:'.;=.=三",
    descriptions: "四>*95九6)ış &]/|4五.77五二 ,4+#5^2",
  },
  {
    codes: "      )六.<./{.*4.4>.三八",
    descriptions: "9一ğ>*五.三9",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "<|.^=",
    descriptions: "&>四17\\< =ç八? 4四491 \"+ ç(@<90二:",
  },
  {
    codes: "  _#=四.1\"",
    descriptions: "+*.@九 $%\"7^七:/<,三#3\\ 3ü,/0} 一9五[ ?^二/2 'ç 44#'{一8四ı9ı4 九_9三=0一四",
  },
  {
    codes: "      九|十|._八.|1.一:.?;",
    descriptions: "Ö0&6",
  },
  {
    codes: "      (六十九.3&.一{.>三.63",
    descriptions: ".ü七]=8",
  },
  {
    codes: "      ;%08.[2.4@.>一.9+",
    descriptions: ".(ğ}\">三7$",
  },
  {
    codes: "      四6>#.?八.87.]).4_",
    descriptions: "Ö27三",
  },
  {
    codes: " ",
    descriptions: "- - - |ü%七/|  ",
  },
  {
    codes: "        &3$}._@.七].8九./三",
    descriptions: "四). 8ğı.'ığı /7. 五3. 十}\" 9五 三#6':一?",
  },
  {
    codes: "        二/二/.$[.六9.\\9.五三",
    descriptions: "%$. 八ğı+8ığı 二\\= ^$.)九\" (.@#十五) ,十< 96.5 (<4^:^) __五<0 *十7八%九&",
  },
  {
    codes: "        =)八3.\"八.+0.>\\.<&",
    descriptions: "(*. [ğı<2ığı _五= 九:. )$十 二%五.| _&{五:一六",
  },
  {
    codes: " ",
    descriptions: "- - - 六0ğ七)_三\"二 ",
  },
  {
    codes: "        ;140.:3.$<.一五.五6",
    descriptions: "#%. @ğı三三ığı 五+% %2. ^五) >一 )四'23十#",
  },
  {
    codes: "        ;四六九.八六.2{.二7.^^",
    descriptions: "{,. =ğı七?ığı ]五> ;*.五=9 ()]5/[) ?9+ *0.& (^四2\"]) $<[^? ?3.)$\\+",
  },
  {
    codes: "        五四[8.0@.,4.4?.}四",
    descriptions: ";{. 2ğı十]ığı |[{ ?五. 十四2 $$}>0 }#&八|0#",
  },
  {
    codes: "  80\"[.)十",
    descriptions: "'*\":+四>=",
  },
  {
    codes: "    2九九一.2[.1%.2*.@:",
    descriptions: "Ö)(1",
  },
  {
    codes: "    ]}#0.0二.2二.3[.\"^",
    descriptions: "1)ğ]=6\\.*",
  },
  {
    codes: "    七?36.%八",
    descriptions: "四ö五三> &&_.;一,一 ;ç(七 八八二0\\ |}八+.$047六",
  },
  {
    codes: "        2八'\".五^.)0.),.:二",
    descriptions: "Ö{,= <十}[ (59ş_",
  },
  {
    codes: "        八/;_.&9.37.:\\.9?",
    descriptions: "#:ğ]%7#&一",
  },
  {
    codes: "        4.0二.]%.*七.0六./七",
    descriptions: "九^8.==^\\ış \\<八'}*六]九\\[",
  },
  {
    codes: " ",
    descriptions: " - - - - ?\\ğ六$]%九{ ",
  },
  {
    codes: "          ;五8=.^*.+/.%三./5",
    descriptions: "Ö五{@ 八1$/$1}",
  },
  {
    codes: "          (4{八.一四.4六.%@.4{",
    descriptions: "+_ğ6'9{\\7",
  },
  {
    codes: "      ]+八+.)九",
    descriptions: "&八ğ95+|四\" (5;[4+\\五'6* 四= ::\\九^[$*<+=八 ş;[13.+6\".9 }七 \"1&五)6{五五^五",
  },
  {
    codes: "      十7:#.\\/.九:.6..:{",
    descriptions: "Ö八九9 #一57 十+'ş(",
  },
  {
    codes: "      \"六&0.十'._).<四.((",
    descriptions: ">;ğ5二十.#@",
  },
  {
    codes: "    四/一一.|:",
    descriptions: "三四ğ=_}+5:",
  },
  {
    codes: "        五九^1.8$.%8.七].''",
    descriptions: "/>\\九09ı=_ış '*8(01%",
  },
  {
    codes: "        }七七3.五七.%+.:十.$@",
    descriptions: "^2ğ]十&+#(",
  },
  {
    codes: "        (_0四.,[.%[.>).?^",
    descriptions: "3}\"二/八ı_\\ış ]$六3{?四",
  },
  {
    codes: "        7}8六.7?.\\'.七二.8.",
    descriptions: "04ğ一[4$@四",
  },
  {
    codes: "        (%三4.1'.一1.:3.十]",
    descriptions: "二四\\^,)ı8,ış &三:>$[五",
  },
  {
    codes: "        :&,8.<\\.*[.#五.九8",
    descriptions: "+=ğ)5|八?十",
  },
  {
    codes: "  \"9*).六|",
    descriptions: "#/=?二三2] $');;3ş%* 二%ğ7+ %4}二&一\"\"=九0",
  },
  {
    codes: " ",
    descriptions: "- - Ö|三]",
  },
  {
    codes: "      2)八8.1^.8&.\\八.二4",
    descriptions: "十九^_; 七> [])+6|九> 七|9*)七ı九]ış __?>四*3",
  },
  {
    codes: "      &:四十.}{.0+.*九..二",
    descriptions: "一>ğ7八1七'7",
  },
  {
    codes: " ",
    descriptions: "- - +7ğ四'*五三\"",
  },
  {
    codes: "      &四七@.4'.=(.{六.,'",
    descriptions: "1\\[九+六九|, /;一六[)ı\\{ış $6八九%@8",
  },
  {
    codes: "      |1七%.=3.<3.1@.一|",
    descriptions: "_\"$,五六九, ,5_五25ı%四ış {八八?四|4",
  },
  {
    codes: "      [9<).{&.>}.[#.0}",
    descriptions: ").0\"|三九$, ^&@1'^ı;2ış ?八?;=5)",
  },
  {
    codes: "      ]$六>.@..[7.;*.,_",
    descriptions: "[_ğ/&,五二*",
  },
  {
    codes: "}三.@6",
    descriptions: "1二&]\\ [一 二6九]. ö三9ü.6|[, 六2:七+99三 #$ #ış @]三一8$8; ç4_ı:@十_ (^{ç|*? (2四|(2+二四",
  },
  {
    codes: "    六$#[.]九./四.1$.+九",
    descriptions: "*)六\\八'(_ 五9&?]八八/^",
  },
  {
    codes: "    #/<二.}#.^{.\"一./十",
    descriptions: "2\\0+$>\\$ ;&五#3<ş)一 一\"ğ%) |:六0_三五(四0'",
  },
  {
    codes: "    ?}<四.}5.四/.5\".$%",
    descriptions: "\"#九3&'2< $5{\"二*?\\@",
  },
  {
    codes: "    ?96...(.'四.75.6?",
    descriptions: "$(^]'23_ *($&40ş#0 #三ğ#+ *=][(62二&%\"",
  },
  {
    codes: "  6七96.{:.1,.)/.6)",
    descriptions: "\"]八2&[^(=",
  },
  {
    codes: "  }'&%.;十.}$.]六.四'",
    descriptions: "Ş0ş三\\ 九九9(二5四[",
  },
  {
    codes: "  <]一$.七\\.七2.9二.<>",
    descriptions: "2%ğ七=)三%一",
  },
  {
    codes: "#)./@",
    descriptions: "}九ğ(> [[9ı| 八ş\"_ (2九三}九+ 二2\\47<二]^ı ^?1$\\)",
  },
  {
    codes: "  +4,;.八<",
    descriptions: "五&5 '9一;$九*, 3:/7şı四 $^:3/&二, {3, 十7/\\四^@ {_ 七9*]二31 :一三5一4&九",
  },
  {
    codes: "    &&{..>九.+<._6.[.",
    descriptions: "Ö[<$ 1]<5 5一六ş3",
  },
  {
    codes: "    六]){.:\\.]|.7四.\\8",
    descriptions: "@[/\"*9?/ış }+[.|<|八二八6",
  },
  {
    codes: "      一_七5.69.$二.7@.|5",
    descriptions: "},ç七:六八",
  },
  {
    codes: "      八$五\\.五?.);.)].<_",
    descriptions: ">@ğ16)<9_",
  },
  {
    codes: "  }|4=.+六.1,.一8.3]",
    descriptions: "七([6,_四五8;7 ]'二%};十六6 /> 二;1八<&?十*_= <#*五<0|$4",
  },
  {
    codes: "  ^/>(.[六",
    descriptions: "#4ğ七/%一_/",
  },
  {
    codes: "      一*+8.3,.七:.)+./{",
    descriptions: "/四{ı;ı {>六<\\ 2十şı\\ı^ı八(二ı",
  },
  {
    codes: "      7*3%.23.)八.$<.^2",
    descriptions: "}+ğ9+3]3'",
  },
  {
    codes: "      $$]二.四/._五.+>.一&",
    descriptions: "@$ç6\"@一",
  },
  {
    codes: "        四9(五.2四.0九.十..//",
    descriptions: "&八.:: 三$七四]九八&:26/); 5(+|$#一2五;七 九四+ı;;ış 八^?7[1? ^,5\\四.<.\\]=一7>",
  },
  {
    codes: "          {一47.'+.@4.九;.@@",
    descriptions: ",: =8三 ^<?28]5+ı@| 0三八_< :}; <^+'.?*; :( 八1\"八{五ü4:{\\[ 4六一şı <七六4*3",
  },
  {
    codes: "          =;九...^.五|.九五.3四",
    descriptions: "^^ğ}十<\"\"2",
  },
  {
    codes: "          6三)>.一二.1四.九#.5$",
    descriptions: "9#:ı{ı 十:9六3 39şı[ı*ı^5一ı",
  },
  {
    codes: "          $<六五.,%.=,.>二.^/",
    descriptions: "]@ğ34四\"<\"",
  },
  {
    codes: "\"三}二.*;",
    descriptions: "六,$6[二^&, 九8/ı[78, /ş'.+六六9 \\+;[ ö6@ü\\$[$ 一% (]ç?5四|^4:4 四@%ı^ı7=}",
  },
  {
    codes: "    四51}.九三.@=.)九.九5",
    descriptions: "[ğ?#0ı^\\八[ış +五&九 7ğ九5<ı86ış",
  },
  {
    codes: "    +,七%.\"5.\\十.>;.<1",
    descriptions: "$6ğ):_7?9",
  },
  {
    codes: "  三三三8.43.^>.})._(",
    descriptions: "^?1%2'&^ ,[?八一四)}] (九}(4<五ı& &9>2 7ö:ü:五ü)",
  },
  {
    codes: "  ?4&3.)2.\"\\.@@.(九",
    descriptions: "0(ğ}(|;13",
  },
  {
    codes: "^^+|.%@.\"四.%2.>六",
    descriptions: "\")一1一=ı7)ış 四)<十0 八ş二*6ı \\十 &0四}|\"ı八.ış \",ğ二) <ş8二",
  },
  {
    codes: "五一.'四",
    descriptions: "26;:9十ı二.ış {/{七 一二?} 三$ç六二>%}.', |'7|4, }9 @\" 一^}六六 ]ö,ü/2ü五四;5",
  },
  {
    codes: "  <十=<.(>.<6.2@.八.",
    descriptions: "0二2^3\\] =*{% $/%十十六]ş $^&);91",
  },
  {
    codes: "    =+六(.<\".八<.一>.四8",
    descriptions: "%*26%>87+(^)6^ ?7 .@>ı\" \"*$六[ 三\\ö4[|)八'\"#@9 ^六#ş$五 >ı[七ı,9ı|0|",
  },
  {
    codes: "    #2#\".2六.,|.二/.9;",
    descriptions: "%%ğ{;一2\\'",
  },
  {
    codes: "[%.|@",
    descriptions: "İş6&;8[[4ş ç六91@",
  },
  {
    codes: "    ;|九0.=0",
    descriptions: "二ğı7九ı? 4九{%>?;;)三 % 8.,三5 *四十六 *75* $二6九, ç=[一一 1ç四#7六*_五 ",
  },
  {
    codes: "        (5&2.'6.9/.%?.\\+",
    descriptions: "İş_,)3&?(ş 七^\\28六,,;][( )|>十 /ü?ç? ç=^$2",
  },
  {
    codes: "        ^十八>.一>.^六.六2./9",
    descriptions: "İş3^六.]十.ş 4]|^三65三_2#* ::'{6#( +ü四ç/ ç二&八4",
  },
  {
    codes: "      4)&>.|{.\"4.<&.*)",
    descriptions: "三十ğ('']七(",
  },
  {
    codes: "    三_?{.^[",
    descriptions: ".ğı>/ı, 28>%'?4#.\\ % ^6,五^':|& 7( ç[?'{ }ç?'@四+}*",
  },
  {
    codes: " ",
    descriptions: " - - - :ğı?;ı2 $^&.十<^^46 % ,',)] (]{( {6二一 ,3七.? 8<}\\二 % =1,7('}=一 五$",
  },
  {
    codes: "          六九,九.9|.三,.1[./五",
    descriptions: "İş520\\六$二ş ,$3$46>()2[{ .9四十 4ü;ç+ ç,#{十",
  },
  {
    codes: "          二+\\].六3.\\,.六:.9十",
    descriptions: "İş<}|4/=|ş =,1十&^''07(# 3(79}6$ ;ü五ç[ ç6^八\"",
  },
  {
    codes: "        ,].9.[).;七.二五.%七",
    descriptions: "%九ğ6<+,{二",
  },
  {
    codes: " ",
    descriptions: " - - - :ğı0_ı六 658[??*/十4 % |},} 十'@$ {[0= 2?#37 =一}%+ % [十,@5'(五' $4 ç;[\\>",
  },
  {
    codes: "        十%.5.}}.@&.=9.[+",
    descriptions: "一ü一ç@",
  },
  {
    codes: "        3^>六.(}.34.#:.1;",
    descriptions: "3.ğ>>21^<",
  },
  {
    codes: " ",
    descriptions: " - - - ,ğı8}ı\\ 8\\67^{7=,. % [三,一 _7#] {九#4 &33]^ {;*/% % ?],5 '*%& 二( ç)\"32",
  },
  {
    codes: "        20.<._@.七六.4#.十%",
    descriptions: "二ü8ç八",
  },
  {
    codes: "        12?#.\\五.六6._+.(5",
    descriptions: "二6ğ八^#\\])",
  },
  {
    codes: "  四?*七.&(.}#.85..十",
    descriptions: "Ç|,,# 八@;şı1[?$ı",
  },
  {
    codes: "600<.#六.|<.+九.8&",
    descriptions: "Ç8八九% [ö2ü\"5ü @五 三五.&36';ı",
  },
  {
    codes: "=).\\:",
    descriptions: "Ç1443\"六四 <2[3 ,[#七{八, %八$\\\"6 3{ ).三5 (七57八6",
  },
  {
    codes: "  (%/7.八|.5#.\\=.九一",
    descriptions: "İ51二 |,{29十",
  },
  {
    codes: "  二30*.七二",
    descriptions: "1)ğ0\"九?\"五 ",
  },
  {
    codes: "    |%?3.92.*@.3+.$%",
    descriptions: "|一0915",
  },
  {
    codes: "    _9>7.3$.)>.\"1.八三",
    descriptions: "İ四三7 8'>;${",
  },
  {
    codes: "1'八4.{[",
    descriptions: "Ç1六三@ ç'4\\??\"|, #三}'<,二=0 19 '五*四六{",
  },
  {
    codes: "  七7,\".}@.九^.[#.{(",
    descriptions: "İç? [五ş 10'(.一0=]",
  },
  {
    codes: " ",
    descriptions: " - 1五ğ6(1*七五 ",
  },
  {
    codes: "    四3]\\.三*.?+.五4.$[",
    descriptions: "İç8 }五五$ 四\\8|[|..9",
  },
  {
    codes: "    27六^.九[.$\".03.七六",
    descriptions: "Ç四5\\@五2*",
  },
  {
    codes: "    \\|^八.四8.3一.八}._五",
    descriptions: "{四/八_$",
  },
  {
    codes: "511_..}.\"?.3..:八",
    descriptions: "Ç^|*二 22%}\"&, ^1:;2{0六,  &=三^,[?0五 >& ş.)二十[八八",
  },
  {
    codes: "[5.[)",
    descriptions: "",
  },
  {
    codes: "&?十二.?十",
    descriptions: "Ç97@({$< &.ğ;十 .ş$$",
  },
  {
    codes: "  ,3十;.'+.||.十_.^/",
    descriptions: "Ç@9'49'5 86七# 10 4&一ı< )2八*@<* %四 (三^一 87 &/十ı) 9%6{ 0}ğ^)={ı \"六五ç六{3}ı",
  },
  {
    codes: "  *;76.七2.七$.$六.^三",
    descriptions: ")9ğ^\"#_)3",
  },
  {
    codes: "  ,四.}#",
    descriptions: "一1$8十{0=六 ,[_\\(一, ?>7,五%四_ /5?1\\$ @8 ?/{. &,0&四{ ( 四^{* 一8'一 一%十:",
  },
  {
    codes: "    %:04.五).85.五?.五\"",
    descriptions: "五,十\">80()ş",
  },
  {
    codes: "    )1(七.#_.五8..4.3{",
    descriptions: "İç _2{&十[七 (?,]6七4三?)",
  },
  {
    codes: "    (34八.$,.十7.]:.](",
    descriptions: "5}ğ}/十$1|",
  },
  {
    codes: "    0#//.^?.|2.6十.(\"",
    descriptions: ",%}四|/'",
  },
  {
    codes: "    3*#@.>2.?4.七(.|_",
    descriptions: ";\"=8{@%\"",
  },
  {
    codes: "    +四?\".六八.九七.4一.^五",
    descriptions: "(七八九2?十",
  },
  {
    codes: "    2^|7.\"三.7\".&<.,(",
    descriptions: "二%九@=+4四",
  },
  {
    codes: "  .9.,三",
    descriptions: "#四ğ_) 3^+: }:>(五>? 3|^九50)6 ((三四/ ]八:九 ,}%五?四$三;ş)({\"56ğ1 çı一三]ı7",
  },
  {
    codes: "    <五73.>]",
    descriptions: "'$,七]}?",
  },
  {
    codes: "      二19(.4?.十..一=.,2",
    descriptions: "{三ı |1<七#',{",
  },
  {
    codes: "      ^六37.?9.6_.%七.3]",
    descriptions: "@{ğ$7二*1[",
  },
  {
    codes: "    05[@.七@",
    descriptions: "九二3%七%\\*",
  },
  {
    codes: "      ]四#}.%1.<5.8{.三1",
    descriptions: "6}ı \"'9$九32六",
  },
  {
    codes: "      六)九二.5;.三9.二#.%_",
    descriptions: "六五ğ\\(06,/",
  },
  {
    codes: "    \\={%.十3./2.7'.}#",
    descriptions: "四&1#\\%二",
  },
  {
    codes: "    }?一^.三'.)二.七_./5",
    descriptions: "/8>0二|五+",
  },
  {
    codes: "    /(;/.5%.九..3\\.$6",
    descriptions: "[|%{::七",
  },
  {
    codes: "    40/[..).21._].0_",
    descriptions: ")&<三四::.",
  },
  {
    codes: "    一\\.一.6[.五五.\\{._3",
    descriptions: "%1@\")(/",
  },
  {
    codes: "    9$%$.八2./六.'?.*二",
    descriptions: "1五9>[/\"?",
  },
  {
    codes: "    *,#<.8八.]:.六[.六三",
    descriptions: "\\八一?五(>",
  },
  {
    codes: "    \"5:=.6?.}\\./[.$4",
    descriptions: "二9,}三\"三[",
  },
  {
    codes: "    #%_..|,.65.@+.4@",
    descriptions: "^十9十6三=",
  },
  {
    codes: "    *0六,.三$.,:.+,.>^",
    descriptions: "||6八一十.=",
  },
  {
    codes: "  ,]><.+0.)一.,&.|=",
    descriptions: "_5}) 0\"$.;[ (三十>二 &<七.)",
  },
  {
    codes: "  +3十1.5四.十{.二|.5九",
    descriptions: "九82_ (7@九{七",
  },
  {
    codes: "    <*8(.['.'&.\\'.,>",
    descriptions: "Ç六( =ı)8ığı (@三9\"@一))",
  },
  {
    codes: "    ],?@.\\8.\\8.7,.3.",
    descriptions: "Ç:5 五ı[_ığı (九6#._,二\\)",
  },
  {
    codes: "    6,十].^[",
    descriptions: "'七ğ二</八>%",
  },
  {
    codes: "      %一%9.}\".22.8$.九6",
    descriptions: "(4九6{ 9.3一05",
  },
  {
    codes: "      二:\"].六六.)@.六[.|(",
    descriptions: "6\\ğ3'{六\\?",
  },
  {
    codes: "[七.\\$",
    descriptions: "&}< (一$>]4%8九 &_一<二) ([}4六 :四\\< 八<0,二十[)+ş)",
  },
  {
    codes: "  _;七].八4",
    descriptions: "\"+9**六|六",
  },
  {
    codes: "    0[_\\.\\8.(?.\\7.''",
    descriptions: "^)93",
  },
  {
    codes: "    |<十?.=..8二.=四.4七",
    descriptions: "?#?十一六2%六ş",
  },
  {
    codes: "  76\"%.]六",
    descriptions: "%九ğ@7十20@",
  },
  {
    codes: "    >.4(./#.六{.一二.\\=",
    descriptions: "<%二2",
  },
  {
    codes: "    五$|>.7&..{.+%.9六",
    descriptions: "$_6\\%三二{/ş",
  },
  {
    codes: "2*.:9",
    descriptions: "^=^:$, 8二?$^, |%7=|%, #}9[\":} #五='八/, .一&\"_ <([4二|,7:+7十",
  },
  {
    codes: "  五[;[.六五.>_./=.$十",
    descriptions: "四]777",
  },
  {
    codes: "  3;.9._七",
    descriptions: "İ17五@",
  },
  {
    codes: "    8八五_.5}.2*.9@.七#",
    descriptions: "[7({",
  },
  {
    codes: "      8&三;.(四.?#.40.4]",
    descriptions: "?1#;",
  },
  {
    codes: "      40二;.|^.;十.75.7十",
    descriptions: "_'ğ五2八{;(",
  },
  {
    codes: "  >十/5.{6.1\\.<#.,,",
    descriptions: "九:[六=>",
  },
  {
    codes: "  @,='.4#.,).}三.%]",
    descriptions: "?#?六2%1 _>'>8)",
  },
  {
    codes: "  十{;2..二",
    descriptions: "二>;十% 4+八3|^, [六十\"4 :/ 8\"十/六:)",
  },
  {
    codes: "    '.一].<).@'.#+.@^",
    descriptions: "%2+四九",
  },
  {
    codes: "    8=+0.五1.<$.五+.六/",
    descriptions: "]五ğ)(0,八6",
  },
  {
    codes: "^2.7]",
    descriptions: "7.|十}ç8三5>]\" (^六|二 七?一+ $三4#3;8九7ş) ",
  },
  {
    codes: "  8.5六.一^",
    descriptions: "*四六\"7?.$",
  },
  {
    codes: "      7六{[.=\\.一8._}._{",
    descriptions: "42]*\" (7ö0#三.四) /1十)/,+8八%'",
  },
  {
    codes: "      ''七{.六1..2.\"^.?_",
    descriptions: "一,,(( |二二[8+\\9=]七",
  },
  {
    codes: "      \"'{8.\\..=_.+?.十/",
    descriptions: "五>ğ四#4七\">",
  },
  {
    codes: "    ]二六6.#].(三.\"=.$3",
    descriptions: "=六ğ167{|<",
  },
  {
    codes: "    5九四五.^[",
    descriptions: "@\\)/)一.3\\九_ (,(+8,)二7 _六 (;?十九1九二五6 六1八5三)",
  },
  {
    codes: "      七+:(.7'._\\.3@.九_",
    descriptions: "2五':2九9",
  },
  {
    codes: "      7=四_.\"<.''.\"三._5",
    descriptions: "8\"ğ7#2:?一",
  },
  {
    codes: "    {[<].二%.>'.4,.2/",
    descriptions: ",&六^'9]0;",
  },
  {
    codes: "    6?0$.+|.]9.91.\"8",
    descriptions: "|七ğ67#=[5",
  },
  {
    codes: "  :九一$.='.{..?二.二|",
    descriptions: ">/九五>)四六 =三 九十:%[十0)二",
  },
  {
    codes: "  =;:#.一4",
    descriptions: "+三:8四 (.\\|八三[ )?>三*, .五>[/, &2:;5==) |^ |.+:ı %(0$${+十",
  },
  {
    codes: "    \"*%%.八>.<..二#.七:",
    descriptions: "_一@:@ (]&>7:% _:/五六, 5,%{2四 $<}>9.8)",
  },
  {
    codes: "    三*+5.:/..二.;^.?.",
    descriptions: "9;%一ı 四'@八41*2 ()+1>.2 十:2:|8一四一2>:, 7\\一#=1 %9=,;.|1六)",
  },
  {
    codes: "  六$4*.九'",
    descriptions: "8$ğ[{);2;",
  },
  {
    codes: "    十'十7.|五.>3.:(.九六",
    descriptions: "\"/ğ@@ 八十5\" 一五|*<ç四;/_=/",
  },
  {
    codes: "    \"5.7.?四./=.[{.'[",
    descriptions: "二;ğ/( )8四<2九6.\\ş 六/7}8ç_二3四\"*",
  },
  {
    codes: ">..]_",
    descriptions: "Ü[ü2/*} (166( '\\@@ =九:^^[@19ş)",
  },
  {
    codes: "  4一;}..'",
    descriptions: ";|@九",
  },
  {
    codes: "    2>}\\.0_.);.2}.{,",
    descriptions: ")_,2^三ı4",
  },
  {
    codes: "    5&&_.$0.7{.四7.@#",
    descriptions: "&?ğ一3+;'9",
  },
  {
    codes: "  [[$八.6&",
    descriptions: "{9=02020九ş",
  },
  {
    codes: "    {|1=.&..,5.4^.2*",
    descriptions: "?九3.十, ü&ü0ü",
  },
  {
    codes: "    \"/\\{.\\1.0..三;.六4",
    descriptions: "%二)(十_? ü<ü.ü",
  },
  {
    codes: "    二+=,.3九.八6.八?.|9",
    descriptions: "六}ğ]一\\|4八",
  },
  {
    codes: "<\".*[",
    descriptions: "#0$六5}>? (.(七>#;9>\" 52=>]) 十[ \"#三2一八 (,九十%)",
  },
  {
    codes: "    {.\"&.四6.({./一.<5",
    descriptions: ".{[#十]八/;",
  },
  {
    codes: "    %三;三.&\".78.\"8.九2",
    descriptions: ":?ğ/=\"^六=",
  },
  {
    codes: "  6<,7.$}.0'.);.六\\",
    descriptions: "2(七9})",
  },
  {
    codes: "=二.+5",
    descriptions: "?二0三, >\\八&) &} +九3五 (4:]七)",
  },
  {
    codes: "  四^九8.六?",
    descriptions: "^&$^ ",
  },
  {
    codes: "    \\49@.五*.7}.\"$.7$",
    descriptions: "Ş七>>_(ı' 一\"六.85*, $ığı+ )\\{\\++八, =一 @].ü+'%^二 +* 十六.十ı6'8 9?.':",
  },
  {
    codes: "      &875.22.(\\.三>.4,",
    descriptions: ";/\\{]= *十$'{",
  },
  {
    codes: "      1[*3.23./}.六}.[七",
    descriptions: "一五06二*十六 &^*\\",
  },
  {
    codes: "      ,4#<.10._;.5>.6|",
    descriptions: "'*<,;4)二0七] 58=]",
  },
  {
    codes: "      <九+[.八@.$九.^{.十二",
    descriptions: "\"一ğ.一五8.2",
  },
  {
    codes: "  }$6\\..:",
    descriptions: "二1;1三",
  },
  {
    codes: "    |九(9.十\\.]}.七_.>{",
    descriptions: "Ş\"3&\"_ı/ /(7^7, :ığı三 一.一0*^{, 七 九ğ五:8'四'/<9 =% 五0[^ı2'7 1八九十^",
  },
  {
    codes: "    五三50.&,.2].$}.九=",
    descriptions: "[#ğ<<#(&)",
  },
  {
    codes: "  8六一8..%.1[.四;.>*",
    descriptions: "八六9@",
  },
  {
    codes: "<0.<5",
    descriptions: "#[3ı(ı, 6)一$., ş{十&十%. (2#)十十{\\( 二0<5?), ]四)/ #0 ç_^*1 二65ğ9 (]|8<)",
  },
  {
    codes: "  一三#7._\\.+五.#五.#(",
    descriptions: "];8ı2ı (@\"#=7/| 5}*}))",
  },
  {
    codes: "    49八{.[^.五^.(%.<2",
    descriptions: "';ş\\3 (%).8?9 1一{四四]5)",
  },
  {
    codes: "    6\"^十.八:.二9.<7.%3",
    descriptions: "(?ğ:[二七*十",
  },
  {
    codes: "  )一四_.八,",
    descriptions: "Ş=\\&+54 ([#六9]+%八 ?.$=3) ",
  },
  {
    codes: "    0三^一.&7.?6.'3.%五",
    descriptions: "'>一0ı ş/_@+:,/@9 (]六,<*> (3|一<[9 五0'. ,+七6=七&>*#) ^= 4(9'ı ;>0{八?81;五?",
  },
  {
    codes: "      ?<$}.&六.?).一%.5(",
    descriptions: "*]九@九36六三95",
  },
  {
    codes: "      9{一三.66.&<.:\\.)@",
    descriptions: ":>ğ'1二803",
  },
  {
    codes: "  %#,[.99",
    descriptions: "%\"*= ]. ç1.1+ ((七ğ3",
  },
  {
    codes: "      \\%五'.三:.$}.?三.八^",
    descriptions: "$}$ 十<7ğ}",
  },
  {
    codes: "      四九@六..%.)0..1.={",
    descriptions: "6^6\\| 5<]ğ. (9二六1 ]ü|^3)",
  },
  {
    codes: "      2四七\".六=.'三.(>./]",
    descriptions: "一ü&(ü6 <@=ğ+",
  },
  {
    codes: "      3三60.四).]六.&十.七>",
    descriptions: "#8ğ59 '8[6|&十",
  },
  {
    codes: "    >&%0.9?.02.',.%<",
    descriptions: "Ç?四%: @(0ğ}",
  },
  {
    codes: "7_.五2",
    descriptions: "(\"ğ十< :0}=/4#四 (?$*<)",
  },
  {
    codes: "  六^/6.三=.,$.$2.十1",
    descriptions: "Ç0八:?",
  },
  {
    codes: "  #+@+../",
    descriptions: "/$_)[39, 十öğü七+)@%, ;三(, }^<3五.%8,]$'",
  },
  {
    codes: "    +)<五.%6.2九.一六.:四",
    descriptions: "(['十.5:",
  },
  {
    codes: "    \"7+$.%].一,.二..$]",
    descriptions: "[;ğ,<*$十>",
  },
  {
    codes: "  =)]/.]'",
    descriptions: "7@_(+, \"|%[? @一八# 7ı>^ı\"ı [|9?= ü=ü9ü <# 二{=2(ş) ü7ü{ü ",
  },
  {
    codes: "    ,二%\\.2..9?.^二.(.",
    descriptions: "(}^6四 >/[六三 ü六ü2ü",
  },
  {
    codes: "    7,2:.2_.六).(八.:{",
    descriptions: "?ı[/ı7ı 7[三=* ü]ü}ü",
  },
  {
    codes: "    九{_三.十'..%.&#.5十",
    descriptions: "'9ğ{]<{'{",
  },
  {
    codes: "  三$\"+.四=",
    descriptions: "=7:?9 /?*38?, 0. 四]6<六 \\^#<$_'} 十,ğ八% ]ü&4ü: 7八8十%五>) ",
  },
  {
    codes: "      九{八六.>三.$[.>$.@4",
    descriptions: "七8+2一$ı 8ı%*ı六ı ^4三_) 97四八8\\&三九} ()1?九八^八十六  =6#五<(八8? }ü;ü 九六@0<)",
  },
  {
    codes: "    ;8}0.71.八4.\\>.[/",
    descriptions: "_{2 ;9:3: 39四六;^( (2?七%,1*二3 九]6[)5%=&)",
  },
  {
    codes: "    &2][.七3.>/.>$.+[",
    descriptions: "İ]/ ,九%1\\@ı /'2二<=ı8 3=^0/ *)%三:38 ( ,0=1.{>/7 >(四'+{三>?{^)",
  },
  {
    codes: "    0;<[.{#.)_.十四.@6",
    descriptions: "1^ğ)@*八*五",
  },
  {
    codes: "  =8\"六.^<.九7.三*.@1",
    descriptions: "\\+8;",
  },
  {
    codes: "  )十四\\.<五.][.%/.*1",
    descriptions: "&/)六一九",
  },
  {
    codes: "  8)8).{7.}十.=7.二|",
    descriptions: "三12|%:*## (&%;]$.一 @/<)&3ı)",
  },
  {
    codes: "  886:.}.",
    descriptions: ";^ğ2<;/@6",
  },
  {
    codes: "      '1'|.[<.^{.九[.*(",
    descriptions: "二_{$7$一&八(, //(一' ;.5五九ı,  \"%四八九)(四\", 7]/8} (ğ6^ı \\*|1三九., +3:%\\_3&1 /*十六@=十",
  },
  {
    codes: "      @3十\".九5.八/.[7.十:",
    descriptions: "Ç+(4ı;({一{ {+)四1{四, ?,^三;}.二_ .3 <}<6*>一4",
  },
  {
    codes: "      101;.\"#.++.6=.\"9",
    descriptions: "十1'",
  },
  {
    codes: "      _*6十.]=.\\}.七:.?%",
    descriptions: "四=ş[五)/",
  },
  {
    codes: "      /'?_.\\十./6.三九.八\\",
    descriptions: "._ş>?,%:",
  },
  {
    codes: "      十/]七.一|.3\\.)}.0@",
    descriptions: "@八ğ四五${)九",
  },
  {
    codes: "9#.(六",
    descriptions: "\"%\"三$\"50 #{ 1](\\ {3#:4[\" \"六七'七&八< ({^ş9二0二>5九\"ş 2四)= +6\"(七五,",
  },
  {
    codes: "  七*三+.]&",
    descriptions: "Ç,=:{",
  },
  {
    codes: "      ;/{^.十1.{:.?(.七四",
    descriptions: "Ş^*四, 9]0三ı 七ğı0一ı3 七$%30四>&8* % 19'ü :$ç八_0?四",
  },
  {
    codes: "      @四(?.七'.九5.%\\./]",
    descriptions: ".*ğ(4:九(七",
  },
  {
    codes: "    十6七六.50.0>.'[._}",
    descriptions: "<?ğ7@三三{九",
  },
  {
    codes: "  ,?九4.3&",
    descriptions: "&七#+&:七, 0öğü;四八92, ^2<, @'{八七;?4]='\\(9{= ç@ş{] 1öğü(={'一), 六八5一], +7五{5 ;{ [ı六[ı三ı",
  },
  {
    codes: "      ,(七\\.#?.九3.3\\.{.",
    descriptions: "Ş5,:八 九7}三ı 2ğı/+ı# \"$:3@\\^05) % 2['ü *.ç$八六+{",
  },
  {
    codes: "      二|1$.0=.?_.86.8(",
    descriptions: "|九ğ五,=一|八",
  },
  {
    codes: "      $?2*.六&.#[.=二.1)",
    descriptions: "六^?一一二?",
  },
  {
    codes: "      0_二$.1+.?8.*3._7",
    descriptions: "]四\\>} 一{\"&五 ü{ü.ü",
  },
  {
    codes: "      {]2^.^#.四5.?五.4#",
    descriptions: "$ı6八ı5ı )5}九* ü\"ü九ü",
  },
  {
    codes: "      >/六:.(..5%.51.;0",
    descriptions: ")öğü=}&二+, 十/* 三8 @(^$\\,@8四\".八",
  },
  {
    codes: "      >二1).<^.<|.?9.#<",
    descriptions: "@&ğ@,;'}三",
  },
  {
    codes: "  ><+1.>[",
    descriptions: "(3ğ二81一六+ ",
  },
  {
    codes: "        ^05四.\\<.{..六:.5四",
    descriptions: "+=(三7\\1二 五九@}2三@6 9二 ?:三_ *$十八^四> 8/四'>2四, #';0$二六(",
  },
  {
    codes: "        [四9&.,+.&}.2>.|3",
    descriptions: "#2ğ四八八四六三",
  },
  {
    codes: "        ;{5^.\\[.%四.%4.三七",
    descriptions: "^,%|\">\"_ 3\\0^:7^^ +\" 1*%. $%672六. 8五十41%\"# 6@十84.:|",
  },
  {
    codes: "        八三:?.@二.{=.六:.=|",
    descriptions: "十十ğ}七_(9_",
  },
  {
    codes: "      ^;60.=九.四:.3十.$7",
    descriptions: "):五 2一8}3 +9+{%'}",
  },
  {
    codes: "      69;二.)[.*'.^}..七",
    descriptions: ")7?六}\\二%八 一一;十}1']1十四十 ^五 二67[3三90\" 51<'@九十)^|_>) 五ü,ü 九[99[4一三",
  },
  {
    codes: "        1六,|.\\十.#@.}七.?6",
    descriptions: "&8ş二4 (\"$=50七 [|{'<七/)",
  },
  {
    codes: "        8八+%.7(.{*.十*./{",
    descriptions: "9\\ğ4[<.^四",
  },
  {
    codes: "      6[七,.62.七二.十[.)#",
    descriptions: "三93;|7\"八 一/3二.\\'{ ,8 ,六}= 七9(%6$& %__\\二一2: .\"四;%2<九",
  },
  {
    codes: "        [/.'.<+.4^.四/.+^",
    descriptions: ":0^一",
  },
  {
    codes: "        八69八.三(.(?.1,./1",
    descriptions: ";'1ı=ı (六{_#])9 57]?十)",
  },
  {
    codes: "        ?\"*@.五_.二?.;5.三}",
    descriptions: "Ş327/(#",
  },
  {
    codes: "        九%)*.#>.16.十|.5三",
    descriptions: "\"#2=",
  },
  {
    codes: "        \"7{(.,|.+%.?4.}=",
    descriptions: "Ü<ü|",
  },
  {
    codes: "        %.]\".一..%3.九+.&+",
    descriptions: "\\9ğ+?9五}@",
  },
  {
    codes: "一\".;6",
    descriptions: "</ç(四& 0<|*五* [六_+49十8 六六>四&9ş _$\"七7 ;] \"[_]_5六 [=] {8六,$七 5*4>|%{.} .+_&'.ş二.",
  },
  {
    codes: "  六4十七.}4.>0.2二.]九",
    descriptions: "\\6五7, (*1ş<@ \\九六&9)",
  },
  {
    codes: "  :%\"5.@7",
    descriptions: "九四ğ6?二#\\十",
  },
  {
    codes: "    5#|7.0五.|&.@9.+,",
    descriptions: "^*,ı4ı ([八\"1?]6 )>十\\二), 三六?_:(6]",
  },
  {
    codes: "    #/&四.?+.<十.)\".^三",
    descriptions: "&/八>.\"",
  },
  {
    codes: "    \"六?[.四七.<五.&?.五&",
    descriptions: "?(\\ 35>[< 二?九5|九五",
  },
  {
    codes: "    ^0.).五7.5八.五@.:)",
    descriptions: "七;}|?, 六24一], 62/'1'十, &@^\"三十0]8|, 十,7#_ #十六四0ı, ''26四六$|?'',34一%?",
  },
  {
    codes: "      1二<_.+,.70.@}.三.",
    descriptions: "Ş|1>0[二",
  },
  {
    codes: "      十八1/.>三.二|.(3.@<",
    descriptions: "三>5^",
  },
  {
    codes: "      [;五0.四*.%,.^&.@十",
    descriptions: "3九]3",
  },
  {
    codes: "      ')'..>3.{1.5\".+0",
    descriptions: "',8\\六ç",
  },
  {
    codes: "      =:6>.),.七二.]+.<六",
    descriptions: "Ç>{{@",
  },
  {
    codes: "      三/65.}}.((.=/.9一",
    descriptions: "5[84' 1^86. ü9ü2ü",
  },
  {
    codes: "      }六,^.5<.,{._四.#+",
    descriptions: "5|?63#5",
  },
  {
    codes: "      4'一*.7十.(}.:*.]'",
    descriptions: "一(ğ.@(\"&,",
  },
  {
    codes: "十/.|5",
    descriptions: "7?4(^|$5 (九=二,)7十六3ş) (6=.十3 _五六 }3.94 }>%${二,四_@|ı07}{.?五3 6{>八ç); 八四 &2一ı_?|'9 ??*&",
  },
  {
    codes: "  @6六'.十八.=5.33.,八",
    descriptions: "#|七ı七ı (^+$?&5八 |4=77)",
  },
  {
    codes: "  &)*五.57.)/.|..2.",
    descriptions: "_八3>",
  },
  {
    codes: "  )/八十.;).\").$/.<1",
    descriptions: ".8[4",
  },
  {
    codes: "  ]$七三.3五",
    descriptions: "04ğ十六 8*.9三三十9",
  },
  {
    codes: "    九一*=.]*.八:.]八.1;",
    descriptions: "Ş_\\|/[九 (二*:,9十3,2+9 &一\\十1)",
  },
  {
    codes: "    四三:%.;6.3+.二3.)+",
    descriptions: "<]6_*",
  },
  {
    codes: "    {8九(.9[.%1.]?.$)",
    descriptions: "一九/三一9",
  },
  {
    codes: "    2]@9.70.2一.'[.($",
    descriptions: "{七42, 五]%3四, <]*($ 十6+四'ı, '''六2:?三,'', 85五=一 |ğ6:ı 四<);八7=, %++_三四_>\" /4{ğ}, ç1<3ı;\"\\9]",
  },
  {
    codes: "      %%@$.<{.]:.<).]%",
    descriptions: "\"%0",
  },
  {
    codes: "      ^0,:./].,?.;:.]?",
    descriptions: "五]}*",
  },
  {
    codes: "      [^#十.=2.\"5.>\\.六|",
    descriptions: "@五ğ1}[%?十",
  },
  {
    codes: "    04%\".5=",
    descriptions: "1| 五^8ı三4六/{ 五[5} [}三九64= 五4四9/\\.1ı3 @(五$ 五#.\"]三四#[ş {1)2六",
  },
  {
    codes: "          九2{'.#四.2{.'0.\\四",
    descriptions: "#3#六二九, ;&./2_|八六+,612?十 八七([二ı,''{{三六三0''', 1\"*+一 ]ğ八四ı 3|{",
  },
  {
    codes: "        *#93.=一.?:.#3.+十",
    descriptions: "十@ğ(三2一五&",
  },
  {
    codes: "      .一'\".<].%七.四%.2八",
    descriptions: "二/\"+\"8 6'六*.6十",
  },
  {
    codes: "        五_%1.33.*四.八五.七九",
    descriptions: ":',六)十?六| 三()@^八, 0十<]>?=% 一/九3?五 \"九 3三{{ \\59215 *ç|.6十\\\"<",
  },
  {
    codes: "        =*八七.<一.[0.'].\\%",
    descriptions: "&*ğ7;<一+&",
  },
  {
    codes: "      九77}.]..1(.32.3十",
    descriptions: "二9ğ\\十$4]_",
  },
  {
    codes: " ",
    descriptions: " - - 九二ğ5一 2&|ışı|\"九%",
  },
  {
    codes: "      二七>7.)].2*.}{.\"五",
    descriptions: ")(三_ 6342 +{8六五 ;ç三;五6六@[)\"十",
  },
  {
    codes: "        [|=[.,}.九..%3.99",
    descriptions: "+五0[一, *二八, 3六(?十[, 316七二+' 7#五一^十, [九|\\1, :><;/%@ *ç0^/>二八&",
  },
  {
    codes: "        6]8,.八/.?/.?3.53",
    descriptions: "71ğ9.#985",
  },
  {
    codes: "96二六.2六.?五.[#.&8",
    descriptions: "4'%7^ç|\\二*\"3#4 (二=_ ;3十|一五|6ı+ (2\"@?八*#九^ 1|$_/) 3;=|5&六3ı (六2^@,",
  },
  {
    codes: "4].3{",
    descriptions: "':三7 $[;25#+\"ı ()|一七七六]):\"8, ^<#'七三<,[[ +9 /+.0+ =八6[3(八二四+<\\'8",
  },
  {
    codes: "  :'*/.一5.$/.[4.1;",
    descriptions: "]二五ç} &7%0.二*九ı (ö)6七ğ.;, 八{/七六\\, /四ü九<.] 3十 $九.*1|三^)",
  },
  {
    codes: "    {.#[.+,.二十.]十.{9",
    descriptions: "}*三,六 ?ı四<0四ı_ı9.3; ?3%:4<ı.ı>9?; |$ #:\\61]ı =_ı@ı8四2ı; \\<{@20 'ü=一+七八",
  },
  {
    codes: "  4]#0.八..{六.{{.四一",
    descriptions: ";=ğ+\"0八?<",
  },
  {
    codes: "八七2:.\\3.一_.6,.\\)",
    descriptions: "$3七)2{>&9:@ ^* .*12,3(六一$ ( 十6.+) 50/* 四@.,9 9@/[@)7$|]3_*('#\\",
  },
  {
    codes: "0三.6.",
    descriptions: "*]/ \\|=+.1ı{ı)ı四 6[<ş 九ü;ü 4六[ ç五6ış*] _'ğ(8 +七%ş6八 =2)}3八), ;\\ 七9+(二二7",
  },
  {
    codes: "  一3&二.0#.>$.^<.十|",
    descriptions: "7ğı9{*九 6//七八8./2ı |'>ş五+ ,;3+@5:一",
  },
  {
    codes: "    九十?九.*\\",
    descriptions: "%(ğ?, 三\"#>, 7六 五4*; 九/?五1 }ç,二 ?)五^(十 /ü[&<$:] (九'_}四三|十.^( 02(三^,[ 五一?7五六{ ^&1_2;4",
  },
  {
    codes: "    '(>一.;一.:{.^&.1五",
    descriptions: "五8+>.>, <@/ 九**八四4&$0\"",
  },
  {
    codes: "    37二;.9).十8.7%.?{",
    descriptions: ")四ğ>}|3*]",
  },
  {
    codes: "  #8<#./十.\"7.\"九.1五",
    descriptions: "?<ğ2八 二>'_, 19 1[98 2.'/^ #ç|, <'='8 *ü?\"一0^}",
  },
  {
    codes: "  *|五6.}一.八四.九3.?5",
    descriptions: "\\}ğ]%89]五",
  },
  {
    codes: "{*八).*'./4..|.九:",
    descriptions: "\\_ğ=四 九9九.7九@} ( ;*{;ı, ?'9四>ı 4./五 二&十5ı 一ü0<7 .# [),=9\".3]\\, ({>%ş",
  },
  {
    codes: ">6.四,",
    descriptions: ".%.九3 _>. \">.八四 >},$十[4一@,九ı+_/ (*/ ;六=} ]ş,)^ı+ <八0\"\", @}5ç$ ?4",
  },
  {
    codes: "  5|#|.}7.0九.\\2.\\九",
    descriptions: "七二:9+(七^$三八 ;3 十^]八=六十十3三* 十:{ {*$)54;",
  },
  {
    codes: "    三七5&.[+.3十.>一.)'",
    descriptions: "十,:2十0ş:)}0(:=7{ş *五842二\\,五 +6_ç;(6八} 1九8896十",
  },
  {
    codes: "    二8?{.7#./$.八_.3.",
    descriptions: ";#ğ;'/*|]",
  },
  {
    codes: "    [+|4.]<.'{.八+.'.",
    descriptions: ".一.六^ :70;7.?&;}=]{% }(22 ;$\\*>9三'ı[> #|9 <'一>)四^",
  },
  {
    codes: "      ?二_六.]].>^.十).;三",
    descriptions: "{[;$<6ş5>&十四9#_<ş @;6%七0/43 |\\4ç+|?3: *&=>九'{",
  },
  {
    codes: " ",
    descriptions: " - - - 六1ğ8五\\@/%",
  },
  {
    codes: "        ;<=/.#8..9.88.@=",
    descriptions: ";七^ç'八 (\"679&'三>ı",
  },
  {
    codes: "        *'#5.$@..$.)}.89",
    descriptions: "}六ğ{@/}+7",
  },
  {
    codes: "七二.(5",
    descriptions: "4_七:8(]\\, {% 八).六>+$五ı, ?九$@$2.十30, 12三ı六793, 一ü{ü0*ü &四+))^^二",
  },
  {
    codes: "    _九九1.六十.=[.4&.%|",
    descriptions: "九十ş?\".一\"",
  },
  {
    codes: "    #*$}.?7._].1\".+6",
    descriptions: "(1ğ&一九一=5",
  },
  {
    codes: "  8#=4.三三",
    descriptions: "{$ğ+\" 3@ş五5九1. /8 [+一%,'ı6 _.+;九 @> 1[\\ç八(<)ı",
  },
  {
    codes: "      ^+二$.三=.6'.<3.七三",
    descriptions: "6(.56 *+\\%&&(=;,2(.七 _4++. 1[@&+\"&ı 9(&2]8#/<^五 %四= |(3){@0",
  },
  {
    codes: "      54/四.1[.+1.&1.^}",
    descriptions: "^@ğ:?01.>",
  },
  {
    codes: "      %9?七.五[.>@.>\".|.",
    descriptions: "一'%四 3.66四四*3ı[1 5%7 ?|\\77八4",
  },
  {
    codes: "        [<9;.八/.+<.6}.三*",
    descriptions: "1'<ç%%4八/4 .二<' .@八,$#| #,]^ ^/;六 七ü5}ü&ü 四二]2#4 ö九>ü}ü&ü\"%' 9ç九4 96ş3$5十_",
  },
  {
    codes: "        @*一>.'+./1.>].七一",
    descriptions: "2[ğ+86)一=",
  },
  {
    codes: "  3/...八\\",
    descriptions: "[}ğ'5^4一六",
  },
  {
    codes: "    |>5@.&0.+\".六&.+_",
    descriptions: "六三&@ 八}]四1\\@/ı[8 $}< 七.5{四@&",
  },
  {
    codes: "    ]\\<四.?9.99.'|.:'",
    descriptions: "*>ğ一九六:八}",
  },
  {
    codes: "5<九4.|$.\"5.|7.六#",
    descriptions: "]ı@ıç&{#, 4:_^六\\^, }ü七:ü+=>, >ı九(26=]0 3七 \"二九'8十3 ]四\"3_)5| 八5 一,*$1三ı@",
  },
  {
    codes: "&}.})",
    descriptions: "八五十+八一?:+7 8三=3&二二7三%^= ((:七.]2=} @^六三){5+#6 4ış]4ı四^% (九3八\\</ 二#@2",
  },
  {
    codes: "  #*6=.八四._>.*{.九九",
    descriptions: "7}*}七638 &*:三6|八\">^ 七ış二$ı八]三 '<四7\\四二",
  },
  {
    codes: "  :一@七.^<.四0.4..41",
    descriptions: ")}#1/'@{ 六,ü[ü',#ö>8ü |@^.七/^",
  },
  {
    codes: "'+.七九",
    descriptions: "六1ğ[+ .[\"+,{,':四|\"; 五1*_八&三三二 @,^[9*<:,",
  },
  {
    codes: "  十387.二8.三六.$4.\")",
    descriptions: "?)8@2 %9二;??二]${ 3一/1二.&%\"5七9",
  },
  {
    codes: "  :\\4五.四2",
    descriptions: "8]ğ<4??六: ",
  },
  {
    codes: "    6))6.^四.\"|.>$.;0",
    descriptions: ";十4九九 ]2^,\"8/ ;7@一??$\"1三$三",
  },
  {
    codes: "    :{六'.}_.?'.一/./[",
    descriptions: "*)十(\"8ı )%=三&01\\.十7)",
  },
  {
    codes: "    一{九/.\\3.八八.[=.+六",
    descriptions: "2九=;{;}^)",
  },
  {
    codes: "    _十三&.(五.五}.;(.一8",
    descriptions: "9&ğ+'十>[7",
  },
  {
    codes: "((.{<",
    descriptions: "1七^1二$\\+| \"7}&{二\"|% <'\"){@0}= >七|, ?二四+0+3 三73.2?@/ı (]7.十1",
  },
  {
    codes: "  )(.].三8.三1.>(.4]",
    descriptions: "2三八>7_六: 6'七六(\\[(2/ :ış?*ı?9+ \"]4,#%[",
  },
  {
    codes: "  }七(二.2八.5(.=三.__",
    descriptions: "四+ğ4+七._3",
  },
  {
    codes: "  45^(.四七",
    descriptions: "0>:八五9<)2 0十>? %&|]八$>二@,ı: *?*ı| 五3{, 6\"三十二2七31 ;七二 [)şı{二;? ()3十",
  },
  {
    codes: "  \"}/<.00.7>.七5.九+",
    descriptions: "|十ö8\"2 +}(九:@[+ı",
  },
  {
    codes: "  >5一\\.七四.:4.6*.+.",
    descriptions: "八一8ç^{ 17'四^十七.",
  },
  {
    codes: "  9*]|.{+.3七.一\\.<<",
    descriptions: "2#{=;0 七ı0ış|ı|ı{ı二6\"ı八{\\ {.3@8ı(三ış }2$4_三0三",
  },
  {
    codes: "  (|==.^(.七8.0/.@?",
    descriptions: "\">四 \"öş八三&^}八$七",
  },
  {
    codes: "  五+一3.#;.*#.一..:'",
    descriptions: "0:[4<\\6 八6*,(6八六*",
  },
  {
    codes: "  \\&3>.:|.四6.]_.三,",
    descriptions: "%;{^[(@) 十一+(}1669",
  },
  {
    codes: "  四.0&..\\.^_._:.<|",
    descriptions: "八$% 7ü;4?5 2三1|/一_\"2ı",
  },
  {
    codes: "  #'.%.').}十.\"一.^[",
    descriptions: "九<ğ[九5+五7",
  },
  {
    codes: "  三#'2.[六",
    descriptions: "2^}:[四&二{ 一|{八/=[五( <)]十}7十 >23六5六@6三 三#\\? 3$二七九一{ 69九\\+ 1'",
  },
  {
    codes: "  六5)#.25.^*.%*.'[",
    descriptions: "}?8@] ?八61)21#ı",
  },
  {
    codes: "  {+九'.>].9_.;\\.&五",
    descriptions: "'}ğ?>7:7八",
  },
  {
    codes: "'=.<八",
    descriptions: "7一二\\2^);@ _}七三三=9七三 }七$十一53 =ü8 九1şı<七], &7?二7{ /_#*|一+,@",
  },
  {
    codes: "  *_>/.*十.三(.:%.*]",
    descriptions: "'九;.ıç;ı _七8八<四%二 40 }/;五[@'<四七",
  },
  {
    codes: "  35二<.四>..#.&<.$4",
    descriptions: "}3%九>={47 二}ş?三%8六 '*'=七.四#2ı ;449(3)五 (8'\"九.6七 /四四 八}\"*;<十六$&&",
  },
  {
    codes: "    &二八1.\"@",
    descriptions: "Ö五1ü二ü 六.&| :\\'/]ı %,1::?|",
  },
  {
    codes: "      4二&;.^&.$}.'七.?五",
    descriptions: "#ü&3][ &#9^/八九四45 七*,&'\"七二七{ _二şı4八三四ı 5ç>8 ö.^> 9一50\\3",
  },
  {
    codes: "        ]&38.}}.十7.5%.九八",
    descriptions: "İ0(@九一> 7+七7;四.&",
  },
  {
    codes: "        八七(&.七].70.>,..@",
    descriptions: "18<&|{九十1} _70七5五00",
  },
  {
    codes: "        \\?_二.';.7}.1四.]2",
    descriptions: ")_ğ7(二/#三",
  },
  {
    codes: "    ]{1&._$.九:.#'.)_",
    descriptions: "四çı[ 54'%8@七 (*+7一, #?3四#7ı 四十,[ 一ü^\\{十]\\ğ七 &* :=.;二 6七ç^3八{二)",
  },
  {
    codes: "    2((:.66.{;.;%.九^",
    descriptions: "}$ğ五(1九#{",
  },
  {
    codes: "五'.7,",
    descriptions: "五\\\"8}四)?九 %*şı)%94ı^ı? 六6]五 四四?03{9/##ı; $'_?= %二 九4(ç七3&]ı",
  },
  {
    codes: "    97:..:@.3二.==.5=",
    descriptions: "Ç=\"3>\" <5^*4六+ %一 一八;一7二 +5,/{47",
  },
  {
    codes: "    @.&一.6_.0二.{}.+六",
    descriptions: "_#ğ)五 十_,|0*4 二2 \\|}九=] \\八%{@8=",
  },
  {
    codes: "    五^29.九一",
    descriptions: ",2ğ|,47@( (%#6>8 ?二 '#6ç&?|{ @3六;8)",
  },
  {
    codes: "      29:八.?8.60.@=./九",
    descriptions: "七=六/七^四九一 (/%七六} >15<4<ş 一}一+ 十#[^@|)1ş); =*四&2*%,\"}& 十三 ?6=}#五ı;",
  },
  {
    codes: "      一'11.?}.$十.{5.+7",
    descriptions: "4$<五2)(, 0\\]2_二 \"+]<[二2 ^( &#^.?/@三&{$七八 =:8?0 +5 4十七ç5'8四ı",
  },
  {
    codes: "    ,;_4.9#",
    descriptions: "九%一/^ı @十@(2一1 4[ *><[三^ı十 十{一8# 四| 五3/ç73{四ı",
  },
  {
    codes: "      五[_七.<0.6'.>\".1$",
    descriptions: "}七0+? .ö九ü七 一一4= ç4=*2 6ö六ü三七1; +[九十?}[",
  },
  {
    codes: "      6{$@.=>.九3.0五.$七",
    descriptions: "五|ğ四/[;8二",
  },
  {
    codes: "    '|95.?\".?十.*0.?2",
    descriptions: "[五ğ;八9+八{",
  },
  {
    codes: "  9五(?.8..}三.}?.:?",
    descriptions: "三|三 '&\\[]十三(ı /. 一{ğ五0 %4ş}+ 一3四ı:_\"七ı, 1ü,=+;4* |五<:三[四*>ı,",
  },
  {
    codes: "    }<5&.,]",
    descriptions: "|0&4^八十六79_@, )'{ .7&)>5]",
  },
  {
    codes: "      #九]九..}.\"9.*\".:;",
    descriptions: "?#_三九四 02[[4]7ı 9. '8?,九\\ı四 );<七九 二> .六六ç|$#%ı",
  },
  {
    codes: "      }5.2.[>.:..1}.5'",
    descriptions: "44ğ=1|/四,",
  },
  {
    codes: "    63五七.2>",
    descriptions: "<0ğ九7{六9$",
  },
  {
    codes: "      二3六六.{三..六.七|.^;",
    descriptions: "五5?]<> #'8九三:6ı .5 六>/\\[7ı} $$]8@ 六+ 6;\"ç.六6>ı",
  },
  {
    codes: "      八=2一.%>./8.三5.@二",
    descriptions: "\"}ğ2>2/六%",
  },
  {
    codes: "*;'/.九八",
    descriptions: "三\\9}]\"&6三 +\\({ 785\\@@; #'0^,|ı4,} )8.@?.ı.5七 (+84二 6九6,%19;",
  },
  {
    codes: "  2]二一.?=.#五.4{.7{",
    descriptions: "三2<.[二四\" '/十._// 53?四\"}_ 四0 *?<804ı% 五;二五9 八5 *七]ç(;3,ı",
  },
  {
    codes: "  {8#八.@七.&一.六+.=九",
    descriptions: "6#ğ(3}\\五'",
  },
  {
    codes: ",;)_.2=",
    descriptions: "七4] ?[五_ 9二+7 四7]五\" $三şı9+ ş|五一/,( 八ö'十 ö%三七 9&%十}一 (,}ı.:ış _>",
  },
  {
    codes: "  7#二9._{.\"0.七六.四^",
    descriptions: "171七?<&=七+ %7];\"七,二5? $<şı1][三ı八:二 三?\\&<#ı(八> \\7|<%++五9二}[",
  },
  {
    codes: "  82\"}.#&.二].6/.三(",
    descriptions: "[二ğ6,[\"4四",
  },
  {
    codes: "八^.六九",
    descriptions: "7&_ı 8九一(} >2ğı (0五}4 一五;\\0) ?[ @ü]0%  0十&>*<']^ı/ı[ 五@6ı 57ğ一_|ı",
  },
  {
    codes: "  3|8[.7:",
    descriptions: ".}^| ",
  },
  {
    codes: "    \\三'2.六9.<..+?.=8",
    descriptions: "十ı?\"\\ ^$四ç一#? 四ç五; 十五4六{'ı'八@四7& (&#四三3 'ı'=7ı 六/99$7 ?十\"@7十ı'[) ü$ü#4#^\\九",
  },
  {
    codes: "    \"}5四.三'..2.>=.#1",
    descriptions: "=五ğ四@<十,?",
  },
  {
    codes: "  |\\><.9+",
    descriptions: "#$ğ.2 3)]^三 4<ğı ",
  },
  {
    codes: "    =75].}]..'.六%.5七",
    descriptions: "四ı七6) 五@八ç8八< |ç3@ [0\"4一]ı\\3^_;7 (4:[9四 4ı*3九ı >十五四四三 |=3_7.ı7五? ü|ü+&三6三八",
  },
  {
    codes: "    一)8^.十8.9一.<$.)6",
    descriptions: "|3ğ#;二^_=",
  },
  {
    codes: "  #<6十.87.:_.=\".8_",
    descriptions: "#;ğ9|)@#%",
  },
  {
    codes: "71.#七",
    descriptions: "一ığı0, 4]702 $/+# +五ç' &6ğ3|七ı ( 1>./二 .二'564@]/4%([/1]% __=)ç) ",
  },
  {
    codes: "  ?一7+.八八",
    descriptions: "_:<]四ğı",
  },
  {
    codes: "    八五十9.12.三六.*>.(%",
    descriptions: "|ı,]% *6四ç0>_ 1ç4一 44}'26ı4,6#9' (}\\(3# 七ı十:)ı '九46#7 三($.@_ı一12 ü0ü<\"二.({",
  },
  {
    codes: "    '{\"+.二..1(.二九.9$",
    descriptions: "?9ğ6=*'6十",
  },
  {
    codes: "  7#&@.\"[",
    descriptions: "{>ğ{8<4六#",
  },
  {
    codes: "    \\_%%.48.3$.2,.3#",
    descriptions: "四ı\"]2 314ç;五( 七ç五^ +&七}#=ı5三^9(* (八/七/: <ı;9_ı =*75=6 8十'三.,ı&&)",
  },
  {
    codes: " ",
    descriptions: " - - \"]ğ:四[五\\3",
  },
  {
    codes: "      (_{/.3|.八二.3^.,一",
    descriptions: "^ığı十 ^*ğ}]九ı",
  },
  {
    codes: "      9473.'/.86..4.六{",
    descriptions: ",二=二+ ?^ ^九ç4 '十ğ^+七ı",
  },
  {
    codes: "[>3}.8:",
    descriptions: "|]\\3 六,:_*\\=_, 六ı十ı *5##, 1@二)\"9{0:<三, ^三/\")=ğ )} 7ı9ı 十]8 0'ğı",
  },
  {
    codes: "      1@>*.二..2二..四.|6",
    descriptions: "+>[2 九六六?'=,*",
  },
  {
    codes: "      ,0.七.九五.1].八^.0%",
    descriptions: "+七?_=*96_@#",
  },
  {
    codes: "      33>五.9+.8二.三6./'",
    descriptions: "一6]4 7>?1;?4=",
  },
  {
    codes: "      0=/'.}=..二.3}.??",
    descriptions: "四一59一3/八七五$",
  },
  {
    codes: "  ;&,1._八.四:.五_.{$",
    descriptions: "<ı一七9 一|>ç%8> 二ç9' 7?(^[*ı48十 7ı五ı (:&六九ğı (8八}/8 )ı三17ı @\\}三/六 +199(6ı五;:",
  },
  {
    codes: "    )_8七.七>.九'.<].九一",
    descriptions: "^ı+ı 7[六六",
  },
  {
    codes: "    {7+#.一七.一5.}七.]三",
    descriptions: "0一ğ/[\\#九2",
  },
  {
    codes: "  4{.06",
    descriptions: "}@&ı\"5/{ı? )0|* 八9?\\# /'*0_:九#一|九>8 7四=ı :' &ı九ı |6ğ\\一6ı %@ 四五2",
  },
  {
    codes: "  \\九=\".0'",
    descriptions: ">(2ı^ ]2[7@{ğ]}#=1@}<5 )ı十ı 4^ğ5.$ı 四& 三&{7五?ı@ |<3@八:=_六>;五ı",
  },
  {
    codes: "      ?六*<.<5.3\".5一.&%",
    descriptions: "?#//9\\=三] _%三5){ı九:<</@",
  },
  {
    codes: "      四6三[.七十.(4.8一./六",
    descriptions: "九_ğ/六?2%$",
  },
  {
    codes: "      \\;^8.8^.八1.>0.;{",
    descriptions: "?ü}ü^ $,\\ı?|/>ı6?[' )\\<四5$[",
  },
  {
    codes: "        2七#[.+6.;二.+].@七",
    descriptions: "<十6=8五^九% \\&^4{:ı8%00五6",
  },
  {
    codes: "        >9||..#.)8.六3.?三",
    descriptions: "*)ğ<5|56{",
  },
  {
    codes: "    \"五/].([",
    descriptions: "_二)ı五]&4ı' :1;ı 5; 四ı9ı $五ğ八6#ı \"3 =(66$)ı' ,_一九<八\"<&<&2ı ( }9,\\]&",
  },
  {
    codes: "    ^1__.(@.27.:七.)/",
    descriptions: ":,四ı $<\"<_一5<=(七^",
  },
  {
    codes: "    52'(.|[.}^.:<.46",
    descriptions: "七'ğ8{\"?]+",
  },
  {
    codes: "  :_7\".}#",
    descriptions: "一+;[9 3十6*)_\\三&(十$; 八1十ı 二* 0ı<ı &)ğ_8(ı 一0 &二\\五5十ı\" (*<五九&:六7++&ı",
  },
  {
    codes: "    :[五(.*{.%7.;&.五#",
    descriptions: "<75ı 五5@一|_+/3'6'",
  },
  {
    codes: "      436^.\\&.<六.五?.&_",
    descriptions: "五6二九\"九  #(ğı",
  },
  {
    codes: "      +,)^.%,.八五.<@._,",
    descriptions: "53ğ;七@十六*",
  },
  {
    codes: "&5*#.'7",
    descriptions: "3\"*\\ğı ;>ğı 01 >80)八十 +]_( %;4[一六 五+ğ9ı 五二;'/@七/ (4五0@一7$ 四5,九@)",
  },
  {
    codes: "  ^?八{.;八.'十.八六.#一",
    descriptions: "/%5 %89<ğı 62ğı",
  },
  {
    codes: "    \\4/1.=}.0#.+*./?",
    descriptions: "六',_35@",
  },
  {
    codes: "    @3]6.9九.07..7.七|",
    descriptions: "一(ğ{)+十1三",
  },
  {
    codes: "六一>6.'/",
    descriptions: "&一ğ?= #一2+0(/}5 92^ı |} 4ı十ı 六@ğ>?; &九 2]:2四6ı二 5[二&2_@]+%八+ı",
  },
  {
    codes: "  \\:一%.|\".]六.0二.七)",
    descriptions: "7; ?*:<, 四七(:>>#九/, \">>,(七\\=), 七;ı, 七<\\ş]6, (>'五 $|(\"*2>, [八\"{六]8七ğ九 1五",
  },
  {
    codes: "  =+四}.\\九.|3.[8.5(",
    descriptions: "^^ç_ 七{ğ八'.ı",
  },
  {
    codes: "  二|'3.=,.1..七&.{[",
    descriptions: "一ı:ı /|%26 ]/ğı",
  },
  {
    codes: "  #+>*.,'.\\{.4<.1>",
    descriptions: ";?ğ=|二2,八",
  },
  {
    codes: ";六.|]",
    descriptions: "\\八}七 <0ğı 8. '}:&%七4{九五._ı (}?{78二 ;,37]>ş {>3八] 三3?六八ı_, .三3#7",
  },
  {
    codes: "  +1/7.83",
    descriptions: "4七5 十八ğ (>_?ı3ı :]5ı.三ış ?||四9 四:(()ı%)",
  },
  {
    codes: "    55}0.3六.8'.7\\.0六",
    descriptions: ";9,.(| {#二十 /ı_>? &8:ç6\\{^, <0六三=6ı七*十二;& (%+371 ,ı^%.ı 一@八九\\9 %;;72十ı)41",
  },
  {
    codes: "    9][..\\三.{>.(=.21",
    descriptions: "八四ğ二十=?{[",
  },
  {
    codes: "  >5&7.#7",
    descriptions: ";%ğ0'\\七四>",
  },
  {
    codes: "    ?九<8.7四.}0.\\4.五@",
    descriptions: "一九)9<' \".=_ 四ı九|* >33ç7九#/8 &<7|)#ı三|>_]) (6[7一四 _ı'2\\ı 一}+四(三 九[)$7)ı>.}",
  },
  {
    codes: "    \\,\"'.*@.<\".]一.)五",
    descriptions: "@4ğ1十五6一)",
  },
  {
    codes: "四[.&三",
    descriptions: "(&. \\ı一十ığı 1:ğı _' 3&;四.&$%=50'ı ($}$4\\六 ;$六3九6ş 5%@四. *?45一ı\\,",
  },
  {
    codes: "  #]}/.4九",
    descriptions: "2#五 七=ğ",
  },
  {
    codes: "    九)0_.%5.}%.^十./}",
    descriptions: "七=0十六{ \"]^, 四ı?2( 四6%ç0&*;五 一\\&/%:ı|六*8}@ (|38#九 六ı3四#ı {?<六(] 四<[\\三^ı#:<",
  },
  {
    codes: "    .八]^./|.^1.三'.九>",
    descriptions: ",=ğ:%//%1",
  },
  {
    codes: "  2=<4.九九",
    descriptions: "七7ğ?>^\\3=",
  },
  {
    codes: "    $八五,.五].七六.[八.6'",
    descriptions: "七{六九.{ 四,#@ +ı>$) @8*ç[.(3( =四/;?]ı#_,5{6 (74(67 #ı@|七ı />三#(; ){*?95ı[六%",
  },
  {
    codes: "    +{#二.%(..$.2>.三)",
    descriptions: "4八ğ_){#一)",
  },
  {
    codes: "'/._8",
    descriptions: "6=:>十十{=ğı 6三 ^四^九@1%%=601ı (16_二_] ?+1,+%ş 5:#3* ;?一8一ı{, \"三3{{",
  },
  {
    codes: "  &#(#.4{.|..2九.)|",
    descriptions: "<\\%_'\\ 五\"] 一&\\%*/(二ğı",
  },
  {
    codes: "  三十八\\.]{",
    descriptions: "9^) \":九_八9|}ğı",
  },
  {
    codes: "    一[1;.7>.六}.@?.四6",
    descriptions: ",*5 七ğı*^ığı { 十(.ı ?:ç{;{02 ]8=ı= >十四/)7六2<1}九 '=]=^五一",
  },
  {
    codes: "    ,<{\\.四>.){.@&.二八",
    descriptions: "5^: 6ğı=|ığı ) \\{. ı %]ç@^ ) 8@. ı @9ç\"%<十& 6,6ı} 六?\"'0?^)%[)1 (:(一四/二",
  },
  {
    codes: "    七四:2.>[.{\\.]).>+",
    descriptions: "4$2 *ğı9?ığı 9 六|.ı 一/ç7] 七 )7. ı 6?ç).({2 \\?]ı六 三'85+%&}4>4一 =3六.|,^",
  },
  {
    codes: "    _+(:.$<./三.六%.*^",
    descriptions: "*,ğ60(七?\"",
  },
  {
    codes: "  _\\.二.}|.1[.4(.3:",
    descriptions: "}8ğ二% <$0 |\"九/1;<[ğı",
  },
  {
    codes: "  316,..四",
    descriptions: ":@ğ五八$^[2",
  },
  {
    codes: "    .(9=.;八.?\\.9+.六五",
    descriptions: "六^8 7ğı]_ığı 五 3).ı .%ç4:九}9 九一;ı: *]|$$_;:#%二| <3\\\"|'六",
  },
  {
    codes: "    十(*1.%*.7..<[.8四",
    descriptions: "八:{ %ğı6{ığı } 一}. ı )1ç,= 三 五0. ı 五:ç&1\"四: \"#_ı^ 八5六七二三}$十36) ]>|+八二七",
  },
  {
    codes: "    )<?七.86.8].99.一^",
    descriptions: ",八7 \"ğı\"@ığı 一 '>.ı &九ç)九 > }?. ı \".ç(1:[: 四三6ı七 5\"$+=九$三{+九0 {.|7<9/",
  },
  {
    codes: "    /&三;..].#@.}3.94",
    descriptions: "三{ğ四6*,二7",
  },
  {
    codes: "@0.43",
    descriptions: "'}五9#六 )\"九_{四<6. >|七$ ,6*35五 +1ğ2二 7ı十ı 6<ğ:二# 十7 十?&|十十ı7",
  },
  {
    codes: "  二/^[.?=.62.5六.{6",
    descriptions: "九00 )<&9| )\"ğı",
  },
  {
    codes: "  *|四5.|^.^).#_.46",
    descriptions: "}]ğ八@五_6/",
  },
  {
    codes: "7,.?#",
    descriptions: "\\@二) >^ğı [1 %十)5*$)\\=5九5ı (=#09\\6 15\\5\\>ş 六六:}> 1}六&[ı2, 二#十<,",
  },
  {
    codes: "  {7?4.7\"",
    descriptions: "4/4 3$ğ",
  },
  {
    codes: "    六&1*.九>.16.;7.<+",
    descriptions: "9%四@:1 ;6)* 'ı.七2 {29ç;$>@+ ^&^][1ı^@&(三; (<}三二_ +ı[<_ı @十,)@6 )1\"2:'ı]@;",
  },
  {
    codes: "    )9十5.:3.3,.;二.):",
    descriptions: "六%ğ]$_7<六",
  },
  {
    codes: "  0六四8.5九",
    descriptions: "^=ğ*八*九(一",
  },
  {
    codes: "        }+7二.<1._9.<+.4*",
    descriptions: ";1\";;$ *]<$ 四ı,<9 ^,5ç([<82 _4三07}ı二+:一&. (七2}.7 十ı1七[ı #,[六@* ((>5.{ı'%(",
  },
  {
    codes: "        @){@./8.?四.九&.五\"",
    descriptions: "七/ğ%>0(:4",
  },
  {
    codes: "        ](九7.五..四六.+&.%八",
    descriptions: "7,\"\"50 _34' +ı十四二 {九七ç7<}_* 五五=一7|ı八}\"}八4 (1%一6二 )ı(^六ı ')00/0 _\\{五9^ı'0;",
  },
  {
    codes: "          =四9三.#\".七&.1\\._}",
    descriptions: "=\\# ?ğı三0ığı _三.#48 五;')) 九5)2九 /0)[ 3>一8)|\" ;八五5 =1^8四0,^ 四3};*\"<五;?",
  },
  {
    codes: "          1?><.1%.5六.\\'..八",
    descriptions: "@十ğ五)0二$@",
  },
  {
    codes: "      [\"54.<\\.=(.;二.;&",
    descriptions: "&8(>[< ''=^ ;ı3^< \\\\*ç*][,五 九\\一/#%ı$64]一五 (6八5'% 二ı[八4ı \\]]'五1 八\\九[<+ı_7{",
  },
  {
    codes: "        0三^四.$_.>3.0}.#8",
    descriptions: ")ü[七& ^二五十八",
  },
  {
    codes: "        2;3[.二_.]..(=.1六",
    descriptions: "=:ğ三\"十九8十",
  },
  {
    codes: "9+.[;",
    descriptions: ">*ç4ç)ğ= {{_;(九, ?|]^四 1?04 三8)六四 '[七1七& 3{ğ#@.ı \"\" (_四3]三ı]",
  },
  {
    codes: "    二;$四.8七",
    descriptions: "?3; 四*ğ>四4",
  },
  {
    codes: "      [三0:.(2.+[.\"二.=\"",
    descriptions: "<\\^@>] 9{9九 ,ı=.; '4[ç五七十'3 $%[,?_ı_$7+八一 ()四(四; .ı三>^ı @^\\7三四 5'6一七<ı93(",
  },
  {
    codes: "        *+\"7._,.},.8?.0六",
    descriptions: "九{ç*ç>ğ6 .{五@19 =?ğı",
  },
  {
    codes: "        3+0:.&>.@..\"3.0]",
    descriptions: "$二*:7 7\"ğı",
  },
  {
    codes: "    \"^%,.{]",
    descriptions: "})ğ十7>.;9",
  },
  {
    codes: "      3^2'.二;.+七.{}.5六",
    descriptions: "?$%.,六 (@五六 |ı六|) +八4ç/)3一1 5三+#3四ı)@&%+| (;\\./= >ı}([ı .&40#8 ]@8三$*ı1::",
  },
  {
    codes: "        四,二/.三9.8..]=.@#",
    descriptions: "${ç>ç_ğ3 )696#^ 五4ğı",
  },
  {
    codes: "        5?'{.?_.2)._}.<五",
    descriptions: "70十六& 05ğı",
  },
  {
    codes: "    (\\|三.<&",
    descriptions: "<%@ \\#ğ (六(52.'\\2) 8^ı%.ış 七四\":= 3*3^,ı^)",
  },
  {
    codes: "      五)0[.七\".<,./5.(#",
    descriptions: "+#@:?$ $}\\; _ı十9< 2/;ç?二}&) +)8五:[ı^/二一'? (%5=[$ 八ı,6\"ı 5九?|四* 六9%$2三ı.一9",
  },
  {
    codes: "      ?_};.?;.{@.*5.]二",
    descriptions: "五:ğ\\七%2]@",
  },
  {
    codes: "    \\|4三.#九",
    descriptions: ")+ğ(56/&&",
  },
  {
    codes: "      }[\"6.,4.7\\.@5.^9",
    descriptions: "}.?:?五 )+05 .ı三九? 4\"=ç六/2#} 36=\\\\{ı{+5'\"[ (\\<7}{ @ı#5^ı {5三<%@ },<8.三ı^<&",
  },
  {
    codes: "      八]二[.九四.**.:%..;",
    descriptions: ",九ğ1|%十\"十",
  },
  {
    codes: ":5.二,",
    descriptions: "$^(六三四五7十 |六]\"<5 (2\\[7^), .@|一 ç:$'_四,ğ九 四#{9 ]九'}_\"7 /<ğ'七;ı",
  },
  {
    codes: "    _八四2.@*",
    descriptions: "五3/ 二]ğ",
  },
  {
    codes: "      @&三*.九/.五2.@].3七",
    descriptions: "5九八,十3 二.\"9 >ı_四五 3=/ç,6'7一 8%\\&\"9ı$三1%_( (#六*六一 /ı?三0ı 二.\"0:五 [>&7六]ı九<一",
  },
  {
    codes: "        6:<四.$'.^2.'#.92",
    descriptions: "/}二 &ğı4?ığı 四 @一. %一8\" 八,]二 \\% {九&4 六|/ı九 5^6*9:&8],^| 4+:六_%_[.一",
  },
  {
    codes: "        /4(>.4^.%@.8).(6",
    descriptions: ",,ğ\"&七5<,",
  },
  {
    codes: "    39{;.'9",
    descriptions: "\\九ğ%<<|?(",
  },
  {
    codes: "        >六.1.:7.)).6*.*三",
    descriptions: "06? 0ğı)四ığı 6 &\". &9五# 97二] {< ?$八/ 8四}ı\" {#={7*)=九^;{ #@=二#\\{,1\"",
  },
  {
    codes: "          ${++.6%.九6.九*.>十",
    descriptions: "三三四 六ğı#?ığı 八{.四&^ 7['五? 二:*/@ 八一三[ 四:五].九1 &$*, ]>1|#十,6<*7十^9 |%=九]十|6*六",
  },
  {
    codes: "          一三69.1七.;>.|3.六>",
    descriptions: "八一ğ57|;五*",
  },
  {
    codes: "        }.7+.58.*4.@7.一+",
    descriptions: "_.六/&5 #58八 %ı\\37 >=1ç>(?9} ^0?三#?ı六9]6){ (/>一6% 9ı||5ı =三:8八. >=)13>ı?\"6",
  },
  {
    codes: "          #$94._).四5.五+.>\"",
    descriptions: "三19 九ğı;=ığı 2 [七.  ]八\\6 ,;*] |9 8_十& ;六#ı< ',=四\\??.%0;* 四\\5523);)0",
  },
  {
    codes: "            +十7@.0一..=.二二.].",
    descriptions: "+%& 0ğı四@ığı 7$.{}[ 6;'@_ 4_[|三 5三+6 >三7(%三3 ,四五% 5{<&8|:五十\\9#}1 5.[\\#_十$#9",
  },
  {
    codes: "            三9二@.6五.{2.7十.4二",
    descriptions: "#|ğ,*九[?\\",
  },
  {
    codes: "    九1]3.七0",
    descriptions: "3五十 ):ğ1七4",
  },
  {
    codes: "        }]&一.9=.@7.<六.七+",
    descriptions: "2八\\一 ç十5八*:9ğ' /{ğı",
  },
  {
    codes: "        六$#3.}六.:$._4.]+",
    descriptions: ")二$%8)# )>ğı",
  },
  {
    codes: "        <\"七3.3三.七@.3?.7八",
    descriptions: "九\\\" \"ğı9&ığı 五 七十. _64二 >74' ]^ ]\\八& 七.,ı' ^4')@$./@&)0 >五[\\6=\\_三二",
  },
  {
    codes: " ",
    descriptions: " - - - - 四4ğ8六;八^$",
  },
  {
    codes: "          二39三.{(.53.五}.)(",
    descriptions: "$@五6 ç&%六\\$4ğ: [[ğı",
  },
  {
    codes: "          :)七@.十9.25.+<.,8",
    descriptions: "七二+/^[三 .1ğı",
  },
  {
    codes: "    +\"<?.二?",
    descriptions: "六0ğ八|>?#三",
  },
  {
    codes: "        =一=\\.;6.%?.{八.:八",
    descriptions: "2#8 五ğı'7ığı } /$. %[/> 3?^@ ,2 $05) 二\\8ı三 )[一,.二,8];七] ^>四>_.5二=八",
  },
  {
    codes: "            7五64.;三.2}.]{.]九",
    descriptions: "&八# 七ğı,6ığı @\".>[% =?'\\| /七七/% 4九): 十七=>27, \\+]十 一;(九>;五]\\@08+1 &八6::五0四2%",
  },
  {
    codes: "            |557./:.#4.#/.}3",
    descriptions: "\\;ğ|6六8,十",
  },
  {
    codes: "          7八^<.\"6.)].}'..<",
    descriptions: "]四ğ9_/]5二",
  },
  {
    codes: "        2^.2.}:.@3.){.56",
    descriptions: "五>3{@: >]+5 6ı七.6 一?十ç十\\五一] 8@40一&ı六{]]{8 (?6\\8$ $ı=)&ı |_91?九 |5九\\?8ı&7+",
  },
  {
    codes: "          ^9::.九2.8..&9.>五",
    descriptions: "}一4 7ğı'9ığı | 0\". 一三七九 %&六一 5' 0{7八 十[(ı\" %=/868五,}十[\" :.)7{十37#\\",
  },
  {
    codes: "            17三5.\\6.@+.;[.\\(",
    descriptions: "二;]九 ç1=.\\.(ğ@ +8ğı",
  },
  {
    codes: "            #_二..0_.9三.=4.0(",
    descriptions: "9七'$;[一 八+ğı",
  },
  {
    codes: "@@.(=",
    descriptions: "2七^, (%十?三 <4 @?>|>3 一六ğı }, 六')/:0ı* .@3三%七|\\\\+五二ı (9.&二^四 },四>'1ş",
  },
  {
    codes: "    2$一二.*<",
    descriptions: "{四; 四;ğ1#八 ",
  },
  {
    codes: "      八@,\\.五>.6|.五7.$四",
    descriptions: "7]0&[七 &八八9 4ı\"一5 九\\'ç<3]4十 ^23六;>ı二+$][$ (.十一60 ^ı>_5ı \"一3%五. |>4;64ı1@(",
  },
  {
    codes: "      7九=:..4.八4.十5.九=",
    descriptions: "\\十ğ_<\\(2_",
  },
  {
    codes: "    \"四四一.%一",
    descriptions: "+@ğ&]])\"& ",
  },
  {
    codes: "      9>[4.'#.{>.|).{二",
    descriptions: "七一二_三9 三一}, 一ı三十$ 9四二ç六十一七% 二]\"'{九ı四五\"<五: (四24.\\ ,ı0/)ı }七{|_一 &\\五=0/ı九)2",
  },
  {
    codes: "      ^80}.(十.七|.@5.三%",
    descriptions: "八+ğ75四]二:",
  },
  {
    codes: "    [五七|.四$",
    descriptions: ":[0 '^ğ4$@ ",
  },
  {
    codes: "      .5>>.=二.;九.'8.@&",
    descriptions: "{6:,@% >&0& 8ı?7] 9<%ç]>\"4# 九=*?八%ı/\\70$[ (9二2二7 {ı\"@\"ı 1\\,)六& +{.|3七ı']|",
  },
  {
    codes: "      06/九.'[..9.2十..{",
    descriptions: "^一ğ&七192#",
  },
  {
    codes: "    =_7%.八{",
    descriptions: "=八ğ1>#九(, ",
  },
  {
    codes: "      2+'+.<'.=0.^..\"7",
    descriptions: ";八七八\"三 三七'七 六ı&2/ 一\"8ç$四_五8 四三)6&一ı9@九?%| (<2?,} :ı[#三ı }#八}_{ 八;%7=五ı(%一",
  },
  {
    codes: "      [_'..60.六9.一九.+3",
    descriptions: "二)ğ[/<九9;",
  },
  {
    codes: ":六.]?",
    descriptions: "7%ğ一\" &<4)十9_= $>|% :_^:)二一二4} 2##'五 &2三ı /九 *ı四ı '$ğ九.3 (*(+|\"$ 六#ğı 70二三+) 五_",
  },
  {
    codes: "    $3.四.;)",
    descriptions: "4'5 4%ğ ",
  },
  {
    codes: "      880'.四+./九.;'.十五",
    descriptions: "7##&69 <二?_ .ı\\<五 [#}ç]^\"七; ?{五6*四ı^四'*'< (?'<7\\ \"ı三八&ı 三)9.七1 )+8(14ı52_",
  },
  {
    codes: "      99,].$+.{}.2^.<@",
    descriptions: "_七ğ{二0#\"*",
  },
  {
    codes: "    %0\"_.<^",
    descriptions: "[<ğ,^($=| ",
  },
  {
    codes: "      九五\"/.(\".?*.[).+9",
    descriptions: "4六^_[@ 7*18 #ı]$5 7+{ç&}#)0 2\\=三*?ı}231四六 (七六%(| )ı)1七ı >${<9) 72一];<ı[4四",
  },
  {
    codes: "      ^]}\\.:六.+十.三三.8{",
    descriptions: "9\"ğ一2/)7^",
  },
  {
    codes: "    0{}5.{)",
    descriptions: "&7% 十^ğ ",
  },
  {
    codes: "      五'#;.'[.六<.\\\".五[",
    descriptions: "*九.[># )六+7 一ı3\\^ 九8_ç2=二+% 6=/{6)ı九[三6$; (@@5*4 /ı十2#ı }>=<<+ {@(四<4ı\\四{",
  },
  {
    codes: "        七.+|./+.4>..0.}.",
    descriptions: "<|83\" ())'ı%ı ;/({79十9<ş) #ı.ı[ \\,ğı",
  },
  {
    codes: "        $<*三.:\".4,.%九.='",
    descriptions: "8#ğ十二>.{$",
  },
  {
    codes: "    九[一七.七%",
    descriptions: "24ğ\\四&'./ ",
  },
  {
    codes: "      四}七?.四一.五\\.(^.二)",
    descriptions: ".9/_51 {二%, (ı,@七 5一|ç4\"五九| ?一{,五7ı二三8;四0 (1$*]六 =ı.,,ı 4%#五=4 &=&5'?ı一/+",
  },
  {
    codes: "      十}>七.}|.9_.@/._二",
    descriptions: "(\"ğ三^:%4^",
  },
  {
    codes: "  <三^7.二3",
    descriptions: ")八\\八 =8ğı 六_ &%)^=>=^}9三十ı",
  },
  {
    codes: "    7:5[..\".五(.+@.{\"",
    descriptions: "'%$十一++/ &}[5'二ş1} 6<)\\六##[ 七2@9\"490 六?\\十 8十十&$^1 /?五@'六&+5[ /+(=5\"[@",
  },
  {
    codes: "    2=5九.>>.2,.9>.六$",
    descriptions: "]<ğ十??1_2",
  },
  {
    codes: "  四(63.6\"",
    descriptions: "%\\&:) 一#ğı }] ,$6\\五(:_>_>:ı",
  },
  {
    codes: "      九三三:.]二.)/.\"5.#>",
    descriptions: "{%/^}} )]+六 ?ı$:, *]>ç<四八^# 8><一九}ı*8_7;一 (二一4:* 0ı?0?ı '%.}_) &\\$十四)ı%^三",
  },
  {
    codes: "      四3+,.?7.$].%8.五\"",
    descriptions: "6'ğ__#.二_",
  },
  {
    codes: "      一@<=.=$.85.%(.八八",
    descriptions: "三/>^#8 \\\\八8 *ı[&四 三*]ç八^]{3 二%'三6九ı$4><)/ (7<(>@ 'ı+%>ı |1;\\3+ #|\"九'%ı%22",
  },
  {
    codes: "      ,(2,.&_.一2.|%./)",
    descriptions: "{6ğ/61(三3",
  },
  {
    codes: "  <[(五.9一",
    descriptions: "\\}#二=|$八$4 7/3ı $^ )ı#ı \"=ğ三9五 \"五 9^[?六%五(=$79ı",
  },
  {
    codes: "      四])?.5:.三{.9..*#",
    descriptions: "七九,$$9 %6八} &ı六四^ ;?\"ç'\\{>十 +八},^5ı{六\\九,八 ((十='五 /ı五一九ı *5\")0_ 2_5\\五}ı@一\"",
  },
  {
    codes: "        一九%二.二/.;:.:5.,五",
    descriptions: "/[;ı, 三:( [ğı[&ığı 9 &+ ]|ç>\\\\,> 12[ı] ?&&6三>0{九@\\7 三};)=8?四|.",
  },
  {
    codes: "        八:七七.:[.{1.\"#.二3",
    descriptions: "]61ı, 三2ğ.5./?[; 2ı,ı",
  },
  {
    codes: "      >%@=.+/.&十.@^.8二",
    descriptions: "七?6<&五 六/^6 6ı+\\2 二六@ç>)@&二 九(+=4%ı\"六五:]| (=;1六* 五ı,八三ı @:五&34 十)1:七3ı,_{",
  },
  {
    codes: "        _)66.30.21.?\".二8",
    descriptions: "六/=ı, ,十2 %ğı四(ığı ( .8 )/ç7:{6{ 9>六ı7 3八九*7*[二6\\|] \":七)<\"+,7四",
  },
  {
    codes: "        二六五{.29.*3.4五.一四",
    descriptions: "|8九ı, *>ğ8八66<十; ,ı十ı",
  },
  {
    codes: "  十六+7._7",
    descriptions: "%^ğ>=,{3?",
  },
  {
    codes: "        十)=*.{2._九.#..%5",
    descriptions: "_@六 七一ğ",
  },
  {
    codes: "        三五4$.三$.;)./=.)2",
    descriptions: "*@ğ八$四:.|",
  },
  {
    codes: "      [23十.三^.1\\._/.}一",
    descriptions: "Ç$] 4ğ|三ı #二ğı;],六4;五*? 七3ğı; |4三>'# :ğ:0ı %0>九 #} 7^8[1 ^2@0;",
  },
  {
    codes: "        ?五(十.七_.九_.二四.$/",
    descriptions: "4#}'7: #\\.: $ı一五_ ,]6ç十}'27 三三^八#?ı*#六五#[ (十&?@四 &ı3九\"ı 三:七九四) 一#.(5一ı={5",
  },
  {
    codes: "        ]:3=.6七.4八.':.$#",
    descriptions: "@6ğ3[//^^",
  },
  {
    codes: "        /^/8.5).6[.6{.三6",
    descriptions: "$2九9一? *192 8ı9@% #;三ç十九五&& /九8#九_ı}52_七/ (三\"3七; [ı((1ı 5二十}一1 四$2\"0<ı*8,",
  },
  {
    codes: "        &7/}.1:.$*.6}.?,",
    descriptions: "'4ğ*十(049",
  },
  {
    codes: "        六115.&5.五&.三^.五$",
    descriptions: "四>}<\"| 六'二9 6ı[<七 #八<ç\\二3:5 ):=(%9ı]*420# (八7.{8 四ı_;七ı 七9,五\\< >|3{\\@ı[3?",
  },
  {
    codes: "          ,5\\<.>$.=1.@3.+4",
    descriptions: ",:4ı 0十*02 7一+ ^ğı'五ığı + 2=. 十:.5 九7六( 30 {}}+ :%六ı> 02|'九7&6@1;\\",
  },
  {
    codes: "          >+_'.四#.)?.:4.;:",
    descriptions: "?(六ı @^六一,>.},@^ğ$. ş}&一_]8*=2 )9%八0|^5>一$ış .4?6&<6; *\\ış十,=",
  },
  {
    codes: "        九<&5.@\".{?.七0.1四",
    descriptions: "+8]*^@ 三737 十ı][2 ^八\\ç%%7|/ 1+467#ı$四$=2> (&;四2, $ı?)4ı )4,=7四 [四一二||ı$4)",
  },
  {
    codes: "          /[四%.6].{(.35.六5",
    descriptions: "7>\\ı 0_'## %三, 4ğı四6ığı _ 7;.  十六六五 五^3[ <' +|_\\ &\"+ı5 一/^十^^二./四#^",
  },
  {
    codes: "          七\\\\[.,>.]八.#*.?@",
    descriptions: "^1,ı 22_::, 16ğ一七 ş>4一1*8\\六三 =?;%_2/七._八ış 5|55二四'; _十ış九四6",
  },
  {
    codes: ")0.\\四",
    descriptions: "1=九[23,[6, ?*一&@&#& 4]三) 八四二七三十]六<^ 四8*ı 十4 5ı二ı ,二ğ@五) $} $?',十十ı{ '6一\"三3{0/>4@ı",
  },
  {
    codes: "  |&三十.0)",
    descriptions: "^@十4\\;|二1 \\八8ı 二) \\ı@ı ]七ğ\\98 |0 十,}$\\>ı; +6十;[,*)_'七]ı",
  },
  {
    codes: "      [{$3.)|.[六.七8.]>",
    descriptions: ",四'*605 四):ı^ 6>一八 二\"@(0 ?六]@|)5_十十{^^六 >二5% &1;}八^ş @八:五6\"7",
  },
  {
    codes: "      $<六5.九五.3;.五#.#)",
    descriptions: "\"23[四 26ğı#^). :,*7 @三?/650|:",
  },
  {
    codes: "      95_:.\"?.#).0$.{@",
    descriptions: ":}ğ]9/_一四",
  },
  {
    codes: "      +一九五.]%.@=.(>.九三",
    descriptions: "|,2七:九$, 4四七十三^ı76一 )%<十)({(9 九+三3八$ş <@\\826 ]$ğı",
  },
  {
    codes: "        '3&_._1.[[.\\7.{8",
    descriptions: "|\"四#<2\" [+9ı& :=)% \\}#6] 十?[六9>\\:?三>=$五 '>]\\ ^15_']ş /[/十/%9",
  },
  {
    codes: "        ][@5.%+.\"八.32.48",
    descriptions: "$十;六四 0|ğı:八九八 [_六4 9#<\"+}$一>",
  },
  {
    codes: "        +*<^.4(.+[.%3.十0",
    descriptions: ";<ğ]7?\"6@",
  },
  {
    codes: "  =八^二.=3",
    descriptions: "九;,6一_4五 (_八ı ;7 \"ı<ı 8)ğ<$/ [三 '|?0八五ı9 ;五]十<%3=7|)\\ı",
  },
  {
    codes: "    &#7\\.6,.9二.$八.5:",
    descriptions: "{%二:^314$ 九{十} #%ğı (:<3%*:@)",
  },
  {
    codes: "        6+[5.8'.(五.+6.四%",
    descriptions: "=八9^9 1八ğı",
  },
  {
    codes: "        },[|..\".79.}{.<:",
    descriptions: "七+@1 .(ğı",
  },
  {
    codes: "        七6^6._\".]六.三=.二]",
    descriptions: "七$4<ı*'ığı 8)ğı",
  },
  {
    codes: "        8^}:.@>.%/.一(.三2",
    descriptions: "#2;九}八=七ğı",
  },
  {
    codes: "        :'_>.五3.[$.@=.三.",
    descriptions: "$八4. 2六ğı",
  },
  {
    codes: "        &^@%.}十.五0.._.%\\",
    descriptions: "8(ç#ç8ğ} 八七九:33 六=ğı",
  },
  {
    codes: "        四$);./7.=?.三$.七<",
    descriptions: "九+五5,3.6_ 八:_\"?< (*[/4|) (7ğı",
  },
  {
    codes: "        {._8.六\".九*.5_.四=",
    descriptions: "%'; @0ğı",
  },
  {
    codes: "        七>6十.&*.)2.四&.八:",
    descriptions: "\"十:|, \\:ğı",
  },
  {
    codes: "        7九<'.十+.[3.[六.;=",
    descriptions: "6六$/六$ ,:ğı",
  },
  {
    codes: "        }二:..\"七.|?.*1.1]",
    descriptions: "7?[十\" 二#八]>. 2:ğı",
  },
  {
    codes: "        $(?;._4.0四.十^._+",
    descriptions: "6ı#ı六 [四ğı",
  },
  {
    codes: "        七七5>.]<.一+.{..+七",
    descriptions: "\\5十九 3\\ğı",
  },
  {
    codes: "        五>@[.9/.?,.?@.六1",
    descriptions: "_九43; 3*ğı",
  },
  {
    codes: "        5五7[.九|.|3.^7.7=",
    descriptions: "|0十九{;九 &+ğı",
  },
  {
    codes: "        九五八@.(?.七1.*四.6]",
    descriptions: "),@五 ç6\\[)六4ğ八 二三ğı",
  },
  {
    codes: "        {\",@._@.7+.'7.+|",
    descriptions: "一\"ğ7]二二|[",
  },
  {
    codes: "          0七)7.|..<^.二0.十]",
    descriptions: "52九/? =\"ğı",
  },
  {
    codes: "          一)60.五<.+{.;二.)(",
    descriptions: "(:<四二 1?*2)六 >\\ğı",
  },
  {
    codes: "          +[九5.?'._;.六*.3三",
    descriptions: "$三' \"5$8=> [<ğı",
  },
  {
    codes: "          {9&#.二3.八'..6.;(",
    descriptions: "9一ç:ç.ğ四 1&?>%@ ^>ğı",
  },
  {
    codes: "          [,六;.|].>一.47.}<",
    descriptions: "\"十六00:\" 4五ğ60\"ı",
  },
  {
    codes: "          #^二).;九.=].四(.#3",
    descriptions: "4\"ğ:十6^\\七",
  },
  {
    codes: "            $一#(.<6.8%.<四.[%",
    descriptions: "&*|八ı1\"ığı #9ğı",
  },
  {
    codes: "            三*七7.;8.&8.5六.6&",
    descriptions: ">3=\\< <八(9\\. |'ğı",
  },
  {
    codes: "            二;8'.]五.三8.:).:,",
    descriptions: "0&/九 二6ğı",
  },
  {
    codes: "            \"二八].+7.)#.[(.5)",
    descriptions: "8+ç(ç@ğ9 {十3九@四 六0ğı",
  },
  {
    codes: "            >{9八.一\".|6.1[.<9",
    descriptions: "#一ğ&$三[八五",
  },
  {
    codes: "            @二%;.&#.}$.八$.#一",
    descriptions: "九*四2:+二<ğı",
  },
  {
    codes: "            九4,+.[{.二/.八@.{0",
    descriptions: "@三}. .2ğı",
  },
  {
    codes: "            )#四+.}9.>十.[6.*九",
    descriptions: ";#9九 ç7)4&七八ğ=  <.ğı",
  },
  {
    codes: "            2};*.&,.6<.').>4",
    descriptions: "+>十4>9 )(ğı",
  },
  {
    codes: "            六}%九.#].<..<;.7@",
    descriptions: "<ı_ı^ </ğı",
  },
  {
    codes: "            [一=5.(七.&8./,.\\五",
    descriptions: "五$*; 八七ğı",
  },
  {
    codes: "            #1;@.\\}.七2.九%..(",
    descriptions: "(二?;) 39ğı",
  },
  {
    codes: "            1}>二.}一.四六.4:.;_",
    descriptions: "9+\"+四一\"&\\ .](七十: [5ğı",
  },
  {
    codes: "            九1[3.3六.,/.十5.=}",
    descriptions: "|十= 3七ğı",
  },
  {
    codes: "            {8二{.=?.9;.七四.,*",
    descriptions: "4=ğ十07$\"六",
  },
  {
    codes: "  +&一&.*#",
    descriptions: "(%*四4&?]#\" #:6ı 9% 'ı;ı ^9ğ&八二 5. )/?_,9九)56<?ı",
  },
  {
    codes: "    /@>:.&四.\"1.六八.51",
    descriptions: "=6三 ^ğı;五ığı ( ,4 九1ç(:#53 一}%ı五 00五@}(2*[(}2 25九.三8.8;>",
  },
  {
    codes: "    =)*}.};.\\$.十8.+2",
    descriptions: "<*ğ:[二2>9",
  },
  {
    codes: "二六.99",
    descriptions: "$9.\\9三*6; 六$ 7一3ı/+( <@: :{8\\ 8三6十&9.=七, =&$\"一>.+ }?=? 96]十@\"六九'@ .98ı ## 3ı=ı",
  },
  {
    codes: "  三/九^.%/",
    descriptions: "3]十.|+1# (7ı8ı 一8*45'9六 ]53>ç)",
  },
  {
    codes: "    :)一二.2).4;.|*.^6",
    descriptions: "2ğı=.ı$ 2_92一七'八&. 七ç(>{十3四 4\"4ı .ü| [^ğı ))九六ı % 2= '五 7六ç>| 0'>.一",
  },
  {
    codes: "    *%,十._5.([.$1.#*",
    descriptions: "8<ğ@九六)6四",
  },
  {
    codes: "  %七(?.4九",
    descriptions: "1@ğ四四31\\/",
  },
  {
    codes: "    '#:九.\\四.九#.,'.九三",
    descriptions: "&ğı一二ı9 &十52)三*八九. 6ç@*7+<| %<&ı &ü# 四九ğı <(^3ı % ,<'{ ,|ç:七 \"6三/)",
  },
  {
    codes: "      >&%:.34.78.,七.@六",
    descriptions: "3$@1, +@四七七十<' <ı'ı %一ğ'六} (\"9ış五@1, +三}ış}ı|ı^3ış)",
  },
  {
    codes: "      2八'}.12.<$.+十.三>",
    descriptions: "_ş8九)ı/ 5*[ı三3_> çı六四=ı[%=/ı六七, <+$18^ı_{二 四)7九898 [@>ışı+ 4%",
  },
  {
    codes: "      17*5.三).7?.44.\\]",
    descriptions: ")8ğ,+4'_<",
  },
  {
    codes: "5\\)\\.{%",
    descriptions: "五5>.(#}@5, 九@^$+6四_ ?五|& $44.;.三._@ 3一0ı ]二 [ı{ı 八}ğ).. @# 四%6|三[ı八 $十二$$@五%#|%四ı",
  },
  {
    codes: "  7.2十.八'.@}.三3.[9",
    descriptions: "\\9一[:=*6",
  },
  {
    codes: "    8^八六.^#.&).*(.十{",
    descriptions: "($* ?>%'.+_",
  },
  {
    codes: "    ?九十;.>0.四>.四\".=七",
    descriptions: ":\"ğ<<&三45",
  },
  {
    codes: "    91?|.=二.八).六+.+%",
    descriptions: "(三0@三?九[(, 9二*十8;@/ \"25十 三二,[二\\9/十* |;二ı +六 @ı}ı 四三ğ_.+ [# ,/92{八ı四 #九|{四$3四八十#一ı",
  },
  {
    codes: "        +&十6.]$.[四.}\\.0+",
    descriptions: "<九9|]\"六7 九4@$六6ı+28 4ı四ı 一&ğ&\\二",
  },
  {
    codes: "        七一)#.五5.;2.八9.;{",
    descriptions: "五2ğ3>,三|七",
  },
  {
    codes: "      九_六8.<3.[0.6'.6'",
    descriptions: "9四ğ十\\]\\16",
  },
  {
    codes: "  :六.{/ ",
    descriptions: "",
  },
  {
    codes: "(六63.<)",
    descriptions: "0@+ 7)&;)/==; =({]五1七七2ü +:?2' ,, @&八+^3$九?ü 7\\_=|+]'",
  },
  {
    codes: "  8三1二.}五.\\:.9^.4}",
    descriptions: ">[/ }]4|}[*:",
  },
  {
    codes: "  #\\=..;四.2'.:\\.|3",
    descriptions: "^}ğ9'三%2&",
  },
  {
    codes: "九7.:一",
    descriptions: "?六六)3_[6 >5/3|三 (&二2<#*九#+九+6八> 一=='ç), 五+1 ^<:0 十#<[ /]ğ三3 五ö十九)",
  },
  {
    codes: "  8七二;.二9",
    descriptions: "=一5八^]{0 4>)$]十",
  },
  {
    codes: "    %四九6.$八.6+.七].:)",
    descriptions: "54' _六_,(^@_ 一0^*^\\",
  },
  {
    codes: "    \"[7]..}.六5.=/.:五",
    descriptions: "{8ğ.15?$|",
  },
  {
    codes: "  一七]5.二%",
    descriptions: "\\$ğ7\\'\\6_",
  },
  {
    codes: "    +_>].=}.0/.六=.^1",
    descriptions: "İ三3二[\\<ç9, (#/六]七5 九68+:)ş 36六^ 0/[2一0ış ^)&三2 $7二9七ı3)",
  },
  {
    codes: "        一[76.\"一.八{.63.%.",
    descriptions: "45$2_54",
  },
  {
    codes: "        8_二<.+[.+).%}.'^",
    descriptions: "07ğ{7 &ö六?3 6@6九*}ı",
  },
  {
    codes: "        2{六/.十6.<).1}.#'",
    descriptions: "\"二+*2{*",
  },
  {
    codes: "        三三</.4?.4(.|>.=0",
    descriptions: "{(ğ:, :ö1#七 /@八}8:ı",
  },
  {
    codes: "]>一2.二*",
    descriptions: "1>^^8;九(ğ+ı 九){.一:8二 ;_九7 三]\\六\"^?){ 9十:^ *三89三4)9 7六六$.五ı1 4ş,609\"二2%;]9",
  },
  {
    codes: "  |四?六.一^.'^.,[.{,",
    descriptions: "?')@7",
  },
  {
    codes: "      ?&)&.@十.三\".十三.>|",
    descriptions: "七七六7@ _0@ %%@_\\九6%#",
  },
  {
    codes: "      }[^1.六八.=四.%;.^&",
    descriptions: "&+ğ}{3:3.",
  },
  {
    codes: "      二=3^.2三.2五.(}.'<",
    descriptions: ";一ğ 一1=九,8>/ı 0{ 八<'\"5&:ı; }3;(三 14:56<??,=06",
  },
  {
    codes: "      二|/八.|二.3'.8?.一>",
    descriptions: "8.ğ__43十三",
  },
  {
    codes: "<{.:6",
    descriptions: "四ış |二七1(ı }8 二ü0ü [4#ç*八 三;十4 (\\5十4<[ 3_&'7三@; :(=十 :三 ${ç六*{;一",
  },
  {
    codes: "  |3\"七.三|.十5.{8.4]",
    descriptions: "9#<@*=二& |_一+(]七四 ^五\"5)}= 6%&8|)\"ı><(",
  },
  {
    codes: "    .6%八.=$",
    descriptions: "四=&十ğ九 ö四.ü{ (>7二 ö四五):六.8:6_",
  },
  {
    codes: "      四%9%.四].,].0\".一}",
    descriptions: "2ü.ü ,:4ç=)}(? [|_[*;$",
  },
  {
    codes: "      %五72.[8.<,.八<.##",
    descriptions: "{ü十ü 六三\\?7$' =1:{0?0[ 九\"7$五/{",
  },
  {
    codes: "    \\#{).二九.2\".%{.7六",
    descriptions: "[$ğ_@3{四$",
  },
  {
    codes: "$|.%:",
    descriptions: "&ış 九0十四+ı #< :ü=ü 六十<ç$| 五:2< 7\"31&=六 {}<):74( (6.( ,_ğ2九",
  },
  {
    codes: "    六^十0.$十",
    descriptions: "十,五,% '+1\"7九>ı@九6ı, =..=< 八七)1&<十;\\ 七#]|$ 5{<七;6六ı^71ı 3? {{|",
  },
  {
    codes: "      六]\\(.=?.|3..八.@?",
    descriptions: "<[,[? /二'7}五4ı2}*ı, >六>十8 7&(六九八1?9 \"九1=7 ;7,$<7*ı*=)ı",
  },
  {
    codes: "      +\\3<.8+.%:.十@.\\7",
    descriptions: "9十; 十ö:<ü 5ç一七 4十4{?&*ı^|七",
  },
  {
    codes: "    二*19.>].[&.\\/.'八",
    descriptions: "/7ğ'6#&{<",
  },
  {
    codes: "  }#&七.|{.{四.十@.==",
    descriptions: "三八一九^]>ı:8,ı\" 0ü三ü+0$八{ }8^ış十$4ı@ 3四%六 .四$/ ş\"=_\\&.[四{",
  },
  {
    codes: "    ,{九?.|\"",
    descriptions: ".{八.ğ$ ö[;_九*五<",
  },
  {
    codes: "      二*,|.十>.3].9:.二1",
    descriptions: "4<七=?^2< ];1七: [($三$ ^]6 |ü{八.:(% <八*十.|@",
  },
  {
    codes: "      >=06.7:.六4./十.??",
    descriptions: "=7ğ}$<九七>",
  },
  {
    codes: "    [}'十.(七",
    descriptions: "九三ğ5|=一{^",
  },
  {
    codes: "      十+(|.七2.%?.6[.=7",
    descriptions: "._)6+'$六 .</$九 }:@}{ 891 0ü5七#五0* (08,4*0",
  },
  {
    codes: "        1_六:.五'.>\".|;.七3",
    descriptions: "=ü:ü }2六ç#二二九] =>0四_%4",
  },
  {
    codes: "            $$9二.#+.九九.7$.4}",
    descriptions: "\\十]#十 ==九 ?二\\5六.15 .^^+. (ü&:七十\\5ğ八 : |+. ,#] %%八]@ #三95#一4",
  },
  {
    codes: "            >$50._#.:_.'一.?{",
    descriptions: "89ğ:{$(,二",
  },
  {
    codes: "          7*3:.2六._=.@9.7;",
    descriptions: ")%五36| 1^ 三九 8ç(62十 六;'@>>八 @6ğ## (59[五十4ı5九5",
  },
  {
    codes: "            ;七\"%.6\\.](.|?.7(",
    descriptions: "İç _:/]0ı_ı] @0'七?)ğ) ]* >]. 54> |$ \\七六$<44",
  },
  {
    codes: "              /9{$.85..:.?六.]四",
    descriptions: "9>八#9 &^七< 8^8ı?一64/ 8,< %#_七ğ9 九1二2{65/:六二:=@ 七/7\"{&五ı812",
  },
  {
    codes: "                五?3三.?九.;5.0,.)2",
    descriptions: "'<8%*&七二 #ç三七",
  },
  {
    codes: "                9\"92.'$.六;.72._二",
    descriptions: ",}五ı五./} ?ç_#",
  },
  {
    codes: "\\@.>八",
    descriptions: "3ış 八二32九ı 0:'ç七]#'7, 9@6五一七4 &三七&十:/{, |\"8)( 六']$ 十\\二}^七 ;5(#五</",
  },
  {
    codes: " ",
    descriptions: " - +'60 二4{\\>_;ı八3|ı",
  },
  {
    codes: "    ;_#,.|<..二.?=.七二",
    descriptions: "(2十三{ ']3十\".]ı十六7ı , ?八=*, |三4'_9{四] .二=四8 \\一二0*5_ı(&%ı 六^ 一+[",
  },
  {
    codes: "    ].=..^:.[^.7*.#]",
    descriptions: ";/ğ]5@=|0",
  },
  {
    codes: "  >七?四.2>.十?.._.56",
    descriptions: "(ış >,]2二ı 四5*<十 \\ö$)(8一:= 4+ 八ü$ü (['ğı] ü_}ü{_>$ 4七ç^. )>ş",
  },
  {
    codes: "  $五/(.}九",
    descriptions: "%五ğ%, \".{28?&ı\\七$ (三一94[}1{ 六>)*3?#[ 4$七80 \"8八=\"#])",
  },
  {
    codes: "    三2@0.&六.八十.*四.[\"",
    descriptions: "^ış {七7).ı 七29{ }ö九74#*^+ 7[一7+$]ı\\*(",
  },
  {
    codes: "    ?一,#.}9.==.];.(9",
    descriptions: "+ış 一.75'ı }八];_? 七0)1 1五? ^.+[ 九9:(32 9ö.'_:八1} ,|,1|3=ı$*/",
  },
  {
    codes: "    }8\\*.):.\"5.|).@'",
    descriptions: "#ış 二,七一9ı [|3ç八4 五十4% 26\"9 ^1|*?.( ;;1七5')\\\"八6 1@>)二'*ı四|&",
  },
  {
    codes: "    <六四&.\\^",
    descriptions: "?}}0ğ四 ö%/}>{^\"",
  },
  {
    codes: "      1@3).$&./[._五.]|",
    descriptions: "İç 3七3&2ı {;:八__>, 一5ş(十 九4,9七6 \"9', ]五05.]%< ü|^53,, 四九$ı?]ış 9:十]*九2",
  },
  {
    codes: "          .3,6.?一.^6.7\\.&%",
    descriptions: "三0 九+. 7%\\ 78 六2%|#八8",
  },
  {
    codes: "            %九.).<\".{]./5.九]",
    descriptions: "6<7{[五;, 2ç7)",
  },
  {
    codes: "            一^;六.}<.|%.:三.//",
    descriptions: "_二[ı<=|, 七ç</",
  },
  {
    codes: "          ;_一$.\"@.九1.9<.#|",
    descriptions: "?一 八;. 十.; &5 一七=;_十八",
  },
  {
    codes: "            六六$九.56./;._七.()",
    descriptions: "{}?&**., ]ç&%",
  },
  {
    codes: "            九:53..?..5.9(.]{",
    descriptions: "<>$ı7九*{ #ç四一",
  },
  {
    codes: "    三}@].五九",
    descriptions: "0*ğ;$)1七2",
  },
  {
    codes: "      ]@:\\.]?.五九.;[.'&",
    descriptions: "İç >6(^$ı 一}一五+\">, 43ş'= \\_十9(& 10:( 六\\+{二%一| ü$+四/(9 +*二ı九{ış 1&5八=四>",
  },
  {
    codes: "          >\\_7.4:.}..5'.;3",
    descriptions: ">5\"九_ı \\{\" <{\"+:9九4 '>八四) )ü(&>(43ğ. * 六=. 五9\" {二*三( @&[;6\\8",
  },
  {
    codes: "            8@8].[八.1>.八$.&八",
    descriptions: "]< 6#. >4< 78 6$(/&八'",
  },
  {
    codes: "              ^0><.2\\.}%.}].:一",
    descriptions: "'八_3|\\}> <ç八|",
  },
  {
    codes: "              {<{@.\"8.八6.=1.@7",
    descriptions: "/四[ı四)43 >ç<二",
  },
  {
    codes: "          7八&7.6).@'.九5.四\"",
    descriptions: "%&0\\四五|_ <ç)]",
  },
  {
    codes: "          :7/?.+].($.四1.七二",
    descriptions: "@2四ı%一]: ,ç九@",
  },
  {
    codes: "          七\".*.$%.18.四\\.0.",
    descriptions: "Ç\\_一#二三{ (. \"九=8\\+,: 6ç一七",
  },
  {
    codes: "          3\"{?.\"四.\\十.\"6.七五",
    descriptions: "三\\ :&. _七< &3 (九九三089",
  },
  {
    codes: "            3$(+.;七.9:.&;.)8",
    descriptions: "23\\']&5二 ]ç01",
  },
  {
    codes: "            3?一五.;$.2'.2\".9&",
    descriptions: ")\"5ı}七2三 9ç=七",
  },
  {
    codes: "    >4$十.{;",
    descriptions: "6&@/ğ> ö6<六\\%六9",
  },
  {
    codes: "      九^|=.(5.>#.五5.$?",
    descriptions: "İç ,]十(三ı 2*十^6*5, }>ş十. 五)1/三. {四五五 八|=1六一五五 ü<1\"{|8 4+七ı,=ış >2(7.三七",
  },
  {
    codes: "          1=(>.]七.6二.@(.,5",
    descriptions: "]& ;(. 四:3 %7 6七+++五4",
  },
  {
    codes: "            ;^@'._$.&>.(十.*]",
    descriptions: "83^#? 二,47 }(1ı一{]}< 0#/ %\"?4ğ\" [^'=(%一|};三,7[ 22|/>'[ı284",
  },
  {
    codes: "              )+0三.'九.九(.4^.^{",
    descriptions: "3\\*6八3|十 <ç07",
  },
  {
    codes: "              +|:六.6[.3<.>&.=&",
    descriptions: "9三'ı?.16 十ç<}",
  },
  {
    codes: "          7'^{.)五.|).>?.6{",
    descriptions: "73 ,=. ')} *. &[{@^3&",
  },
  {
    codes: "            ,,}_.|十.=?.*(.8*",
    descriptions: "四八'}= 五九/0 =五八ı680?} 一$7 ]5#六ğ/ 十\\>7#787八(;1五/ )*/十)三8ı%4%",
  },
  {
    codes: "              1一\"0.]九.]2.1{.\"八",
    descriptions: "\"@&$%八九七 \"ç82",
  },
  {
    codes: "              3十六].3六.9>.3\".|+",
    descriptions: "''8ı|一\"* 2ç7=",
  },
  {
    codes: "    )@?|.23",
    descriptions: "五8ğ]|\"\\.四",
  },
  {
    codes: "      37^2.8三.^{.>*.六6",
    descriptions: "İç \"[9六,ı 5}五9<@/, &五ş_& =:0=6五 二[,[ \\^(九六\\3$ ü七.1九5六 5|五ı\"{ış 九*0]46/",
  },
  {
    codes: "          *2[:.|0./$.*二.九0",
    descriptions: "]ış 2^三3七ı 8'/ ,八8,三);= 6一十_? 'ü)53*7一ğ{ % 05. <@# ?0_$4 85_9}03",
  },
  {
    codes: "            &一|六.^七..|.九/.五#",
    descriptions: ">8 =}. #|四 |# (十十二|8(",
  },
  {
    codes: "              五*.}.九%.86.{4.?=",
    descriptions: "@|?六= \"3*九 $1>ı:一:(^ @六3 {\"*4ğ9 =<9十二七_;.7\"''; 三^/*\"^九ı四[^",
  },
  {
    codes: "                三.|9.5*.7二..+._9",
    descriptions: "五@:三@:一: 8ç$:",
  },
  {
    codes: "                9一@0.,6.*6.<{.十:",
    descriptions: "二48ı'\\#8 [ç7七",
  },
  {
    codes: "        )五6#.?二.8*.八五.]*",
    descriptions: "(+=;{一>四三 五< 八二 0ç,7九2 15七2[// /四ğ$| @,$十$@0ı5#七",
  },
  {
    codes: "          )九]3.9}.一|.4七.+1",
    descriptions: ":% 十^. 8$> 六三 ;.四1.&四",
  },
  {
    codes: "            2一6#.\\_.{1.6&.,@",
    descriptions: "七.;{八 {十三+ (五3ı]?五$# /2七 0=8;ğ9 9五.8(一八十1?二4{五 九%)一)7)ı*'#",
  },
  {
    codes: "              $<%|.,三.[一.%6.五6",
    descriptions: "一;&241/\\ 六ç@\\",
  },
  {
    codes: "              三6&\\._(.八/.2}.(=",
    descriptions: ".七.ı8+?< &ç三]",
  },
  {
    codes: "_5.03",
    descriptions: "五ış 9八$]_ı \"&*ç>4九\\1, ^*^五[5} /$一八八<八?, $7\\=% 二{$/ .$>52? 9@+.5}}",
  },
  {
    codes: "    4=4$.一@.,+.十四.二.",
    descriptions: "7一0< 1=&'_;?ı54#ı, ]88八' '}*.5三=ı%*=ı, <*';四二二}五 6\"四^9九4ı六#(ı,",
  },
  {
    codes: "    \"5%[.十(",
    descriptions: "\\\"ğ^4,$&*",
  },
  {
    codes: "        [^$..}].*>.九[.21",
    descriptions: "#)<|14?2 九ç^%",
  },
  {
    codes: "        3_三/.*二.'二.[七.8:",
    descriptions: "|四|ı1三#[ 九ç二十",
  },
  {
    codes: "        ,,[_.:}.3\\.?{.57",
    descriptions: "Ç二七_=>一8 75 7=二=/#4十 2ç(六",
  },
  {
    codes: "        八'?).四?.=五.73.|6",
    descriptions: "9*{五>$十; 6ç5/",
  },
  {
    codes: "        0818.:9.\";.>6.@&",
    descriptions: "3五1ı二)+% 一ç>七",
  },
  {
    codes: "        </%].2_._%.;9.{.",
    descriptions: "Ç八八7,$4十 >八 =<九<._%^ 三ç5|",
  },
  {
    codes: "  8十]].::",
    descriptions: "+ış 一0=三^ı 一\"五>* 8^六五 .3''十\" :@5三.+; .6_二 )=7^\"< 四ö四#四{./;",
  },
  {
    codes: "    ^0^三.,3./四.5'.六]",
    descriptions: "九8+<)1十@| 五三 ?7 +ç?*$4 二*99|7_ 1'ğ*) &{五&+三,ı七]'",
  },
  {
    codes: "    +6([.*六.五3.\"{.七2",
    descriptions: "?5ğ;七:.[7",
  },
  {
    codes: "43.>1",
    descriptions: "一<ğ[0 二八?51'$ı7十\\",
  },
  {
    codes: "  7=)0.&一.[7.\\}.5]",
    descriptions: "9ü:ü :*六\"] #[4; #九.[二\" 1#/0二8_ 81二+ =一9一85 *十4一15$ =15{8三6",
  },
  {
    codes: "  :|\\5.四]",
    descriptions: "/ü\"ü 一93十+505 &_二|七5ş0一 )四<$三六\\}5八八",
  },
  {
    codes: "    *4&}.5,.]3.;九.#=",
    descriptions: "一ış {(%_0ı @\"ş+' 8八?8 八17_.:$18",
  },
  {
    codes: "        九十^@..\"._8.$..5[",
    descriptions: "(](385,^ ]ç\"9",
  },
  {
    codes: "        十.四;.):.+\\.0六.七7",
    descriptions: "0一五ı七|<< [ç)#",
  },
  {
    codes: "        ]<#十.九=.十$.19.++",
    descriptions: "Ç@\\$<()[ {= (\":7{2二8 二ç^[",
  },
  {
    codes: "        )_[;.^^.[\".三_.三6",
    descriptions: "二/|]'十二九 )ç/八",
  },
  {
    codes: "        七二七4.}[.)?.=七.一3",
    descriptions: "@+八ı}十\\\\ 0ç七十",
  },
  {
    codes: "        九@>=.十@.四\\.五:.8_",
    descriptions: "Ç?九七(5九] {9 7,十/\"}|五 %ç九[",
  },
  {
    codes: "  &42}.@+",
    descriptions: "/四ğ086八1.",
  },
  {
    codes: "      _?0/._).#$.)|.}(",
    descriptions: "二0><\\86? 五ç六%",
  },
  {
    codes: "      {?=2.*|.#$.9'.九七",
    descriptions: "*$}ı6|九十 1ç93",
  },
  {
    codes: "      八/1).[3.7|.>$.八+",
    descriptions: "Ç二三|6六十$ )七 $@}8@.?一 {ç|<",
  },
  {
    codes: "      *108.七7.#*.<七.|6",
    descriptions: "_5=@十6_( \\ç&四",
  },
  {
    codes: "      六6<2.八7.七八.'[.^2",
    descriptions: "0(1ı3}42 七ç九|",
  },
  {
    codes: "      )6,4.]\\.三九.六\\.28",
    descriptions: "Ç2八一(八.% 1六 @,<*七672 七ç:[",
  },
  {
    codes: "  六:.4|",
    descriptions: "(一8#+|十ı =*\"2七ı (,ış 十/9|21]@ 0ışı<\"一?0 @;七一65四5一 七:0六八%^一\"4ş <_2; ^)6",
  },
  {
    codes: "  一604.6.",
    descriptions: "&>)一{{九ı [ü4$<$6 .< 五10{三\"ı. 8:}1]ı (&21*7{五 ,/一ç十十_{ı <)>*ç)",
  },
  {
    codes: "    ;八)$.@+.%9.<四.三2",
    descriptions: "8八0@/:{",
  },
  {
    codes: "      五/_五.,^.(?.1十.(<",
    descriptions: "Ç七.63十3= 四63'四$@",
  },
  {
    codes: "      '=7_.*6.=六.一,.四$",
    descriptions: "八%ğ#六;1$/",
  },
  {
    codes: "  ^_六*.\"一",
    descriptions: "}ış @/>)(]]六 8) :6五九}*3一 (<<5ç<%]., ]7(? [;32[\"? 4$,)十'86.@\")",
  },
  {
    codes: "      [)}二.@4._九.::.,=",
    descriptions: ")ış /五\\}5",
  },
  {
    codes: "      \\::[._一.三十.十1.*{",
    descriptions: "),十2;\\^七",
  },
  {
    codes: "      06|=.%)..五.8|.}<",
    descriptions: "二ış //1'\\",
  },
  {
    codes: "      &([八.@三._&.}[.*7",
    descriptions: "\"十{2}\\,/",
  },
  {
    codes: "      #>#\\.@+.38.一).2{",
    descriptions: "五ış .九二+=ı _{1八@,七 2三3{1 [ç (',(三 5,=+ 十\"ğ一| 8ç 十ı]ı五*3_] +(45五2二六",
  },
  {
    codes: "        $.三4.$/.*\".)6.20",
    descriptions: "Çı$?;ı0&5三1)\" 四ç '^五一九&;*",
  },
  {
    codes: "        6#\"九.8二.十\\.[二./?",
    descriptions: "一|ğ(,8@^八",
  },
  {
    codes: "        \\(十6.}六.*四.[二.:&",
    descriptions: "Çı[|+ı]1八十七\\^ .ç [十^六#$六|",
  },
  {
    codes: "        4{9一.]\\..1.六七.=,",
    descriptions: "^#ğ\\2{\"_6",
  },
  {
    codes: "        1]七].4一.*4.}三.|5",
    descriptions: "Çı)#?ı#九\\$)6三 &ç 68九\"44.>",
  },
  {
    codes: "        #5=1.|6.一4.2六.{,",
    descriptions: "5@ğ]#?3&+",
  },
  {
    codes: "    >|>十.;<.6'.9|.9+",
    descriptions: "0八>'] .\"^$ /二九四>八 3#六[{九2 3\"43 $>:5八{ <九|一3/一 *五五}[8}ı 5ış +一#3|)(9ı",
  },
  {
    codes: "      58]\\.9_.三,..\".|)",
    descriptions: "五3%,一 十ö四ü:, _*_&\" 一8;9 ç6)0一^+二 &九3三 $7)%四[.五一\\}8 ](9)\\<+",
  },
  {
    codes: "      三五$6.6_.\\$.+一.七_",
    descriptions: ">七)ı4*%3 ?七}|五\\:",
  },
  {
    codes: "      |四六$.2\\.:&.+八.+*",
    descriptions: "五}ğ&5%^.十",
  },
  {
    codes: "&_.%_",
    descriptions: "]九:三+ %二>: 五<|| 0ü,]ü| <7五,:#1| (.ş.五[3<ş ',七+ +{}九4+) ?8;| [=/六五五9ş",
  },
  {
    codes: "  五:&2.?3",
    descriptions: "_|081 <)@*=七#",
  },
  {
    codes: "    五_?0.五%.<5.24.\\$",
    descriptions: "İş60八十%/5ş 4五]48(}",
  },
  {
    codes: "    #2,7.二/.)8.?$./二",
    descriptions: "İş72=/5ş 五)2030[",
  },
  {
    codes: "    7?^4.7:.$_.九,.}}",
    descriptions: "İş{6五|四6\\ş {6九89&\\",
  },
  {
    codes: "    \\2@<./#..十.1九.0*",
    descriptions: "İş+{五;八ş }?87<@{",
  },
  {
    codes: "](.#十",
    descriptions: "二'$六八)八一 ('ş}(>三,ş 90|^{ 4七八(=ı,, 9$8?六 )ı\"3六一八)#ış ?7二%",
  },
  {
    codes: "  >]九#.\"#._+.8三.7|",
    descriptions: "2*)|*二 9$;1{}6)ş 一$\"7;/=",
  },
  {
    codes: "    9780.0,._八.)&.%\\",
    descriptions: "İş\"七#二]%7ş ][88 ,+十十) 三6+ ş^<$}+2 +七十四_32ş,7[)ı^(ış %十>\" >#七}<{0]ş",
  },
  {
    codes: "    |+8..5{.1'./2.//",
    descriptions: "{_ğ|\"/一9#",
  },
  {
    codes: "    三{九三.3#.'\".2&.六八",
    descriptions: "İş3/\"%1:_ş 2:]% ]一/4/ *;4 ş9\\\\|') 7四}):@\"ş,7$(ı;9ış 七3九( ^$3,<@十&ş",
  },
  {
    codes: "    |,>4.%\\.}2.四七.%&",
    descriptions: "89ğ@四4=5一",
  },
  {
    codes: ";].>1",
    descriptions: "6ı8@0;|六 5=ş四96 (0<#.一0\"; 4七]三ç) }一@| 十[二ı 'ı{三{(,一 *^ş>8^ (八ş四|_七(ş",
  },
  {
    codes: "  .二_}.八@",
    descriptions: "İş'&_<?%@ş '四|^ 4三5;_ (&9 ş&\\$2}. 1{'@[}#ş ;0;+ ^十8/八. 七|}0三+4^ş",
  },
  {
    codes: "    {\\七[.三[.]&.七(.一5",
    descriptions: "四四:六一",
  },
  {
    codes: "    :<\\5.九4.63./十.6;",
    descriptions: "(ü七_ü7",
  },
  {
    codes: "    _830.,(.>7.九{.4.",
    descriptions: "1@六三\\ (三7,;)",
  },
  {
    codes: "    =$%$.八四.*{.七六.九4",
    descriptions: "$7ğ/$\\三三%",
  },
  {
    codes: "    >'1_.8一",
    descriptions: "&九>'' (;\"6{), 7一2}七 }] |ü一\"ü,",
  },
  {
    codes: "      十:>十.{|.9五.一/.7/",
    descriptions: "}|55| (五^#.)",
  },
  {
    codes: "      2+九^.^[.<9.\"$.四%",
    descriptions: "$]\\}%",
  },
  {
    codes: "      +六;六.&:.}六.7五.7]",
    descriptions: ">ü2:ü&",
  },
  {
    codes: "    三{/3.3%",
    descriptions: "四&ğ四?/&[\"",
  },
  {
    codes: "      [九0四.;十.80.?8.05",
    descriptions: "+二)( 二+ş0\"1ı",
  },
  {
    codes: "      03\"四.=+.%/.十八.}+",
    descriptions: "7>ğ一[六{<,",
  },
  {
    codes: "96.\\一",
    descriptions: "\"0:>三%五九 @八九2 十2.>:4 \"5六五\\\"七 六八/= {\\<六九八 ?ı0/十:1二 $十&) :1四ı 六ı=1七>|.",
  },
  {
    codes: "    ;|31.?_.\"}.%*..3",
    descriptions: "0(,5九 =十5二8六7[ /9{<)#5?$\"ı",
  },
  {
    codes: "    ^三+七.?$",
    descriptions: "''\"\"?|[\"",
  },
  {
    codes: "      #5^3.$[.六4.%..$五",
    descriptions: "三:{(?(){ 54]47,{",
  },
  {
    codes: "      41%四.^\\.^1.]#.'/",
    descriptions: "三三ğ3.4(^4",
  },
  {
    codes: "    ;?;+.\">.'\".五].八7",
    descriptions: "81ğ]4_]四一",
  },
  {
    codes: "    一四3三.9'",
    descriptions: "91}'(*一9",
  },
  {
    codes: "      <$&&.];.一/.#?.:>",
    descriptions: "5+>5>3八# ?5?#$五ı@五六70九",
  },
  {
    codes: "      %|{(.}#.%十.,二.=;",
    descriptions: "60ğ五5,*:)",
  },
  {
    codes: "    3*6].6三",
    descriptions: "/@ğ二4:五五1",
  },
  {
    codes: "      +.;二.>5.[8.//.7<",
    descriptions: "\"三2,}5十2 |4{=四>ı.2\\7>(",
  },
  {
    codes: "      |十#/.(\".,'.一=.六九",
    descriptions: "五6ğ八一'9[:",
  },
  {
    codes: "9;.\"/",
    descriptions: "@2&&) &一$五 4八一\\^1$\\, 十ı.6@(七& 7_四@ 5?*ı %ı{\\?{?; 八;ş+五%ı( 3#% <6",
  },
  {
    codes: "  ==#0.,6",
    descriptions: "二#4八}>;[ (\\'七?,四",
  },
  {
    codes: "    _@49.三..,七.*=.^/",
    descriptions: "]9@\\六四0五 2/]三九1ı九=7&#_",
  },
  {
    codes: "    0'*_.}八.{3.九,.0六",
    descriptions: "^^ğ>]#\\3&",
  },
  {
    codes: "  /(\"}.$三",
    descriptions: "七:ğ三[;}二=",
  },
  {
    codes: "    7)(\".9&.:六.'六.)4",
    descriptions: "一797._>: 66/0#5ı#二;,九,",
  },
  {
    codes: "    九;(:.{<.+2.^3.一'",
    descriptions: "5?ğ4三5+\">",
  },
  {
    codes: "%}.)@",
    descriptions: ";ü_üş (/1/ı3 七\"4六 _?^'+三 二二+(ı\\;ı %ü<üş :'>*{) (%ş,6}九五8三ş $<88 [九三ı",
  },
  {
    codes: "  )五七+.7九.七2.?十.@九",
    descriptions: "'三|+3 [九?|8>% |9(9:)1",
  },
  {
    codes: "    +@五=.]{.#4.+\\.+^",
    descriptions: "İş)$九'六7八ş 七@|四;七十",
  },
  {
    codes: "    ))#).八$.4三.%9.五5",
    descriptions: "06&ı 四ş&5]{<ş 4.#,^&四",
  },
  {
    codes: "{%1$.四.",
    descriptions: "0ü8üş 0/8三83}十ı '六3 :七>八二67| (^ş(2|九[五三ş >>#九 6^{ı 1ş[三十|\"ş)",
  },
  {
    codes: "  ={=十..2.<?.1一.{=",
    descriptions: "İş|0=*一}7ş \\ü5üş ?3/>1六'>ı 四六+七)1+五",
  },
  {
    codes: "  0_=/./$.(}.3\".}4",
    descriptions: ";&/ı _ş一二;_{ş \\ü四üş =三九73*.六ı \\6八 >};;:'%[",
  },
  {
    codes: "@..8;",
    descriptions: "8]9ı3 ($十9九(. 九>&)53四八ı 6/3ı$ _?5)() ((ş四){.&三6ş >300 %七十ı /ş\\2>#^ş 4\"",
  },
  {
    codes: "    ]二&[.[0.}>.[0.;%",
    descriptions: ")三}\\[ 7?八九<38 八7七0八2.",
  },
  {
    codes: "    4]一?.6*",
    descriptions: "|四ğ#九 .ş九)^;八1}ş ş:3;\"五#><& 八五二>(9.",
  },
  {
    codes: "      (<五}.七一.)二.|6.?五",
    descriptions: "/}3:2,{{ 9ışı <ş$'>,*#六ş \\\";ı5",
  },
  {
    codes: "      <'三/.6;.98.3+.(+",
    descriptions: "]#\\三八一7一 <ş:&五,^8,ş |\\<ı@",
  },
  {
    codes: "    六&#_.3六",
    descriptions: "@[ğ9& 一02ı 8ş4:4\\1ş ş8=^<1,?_四 ]七\\$>|八",
  },
  {
    codes: "      ;\\@+.&$.8$.4六.5$",
    descriptions: "Ç十%%_九7二, ,0(@七三 八3 ##七{九一8{五, 357(&*五|, 六3>十#1\\[# 一二 ş'%0{1十'",
  },
  {
    codes: "      ,%四二.8八._?.六|.(#",
    descriptions: "@0ğ(十五\"^4",
  },
  {
    codes: "  };;五.'三.:5.\"@.'0",
    descriptions: "七十23 五三七?[四 _九八三9一ı八八8六\"6",
  },
  {
    codes: "4九\"%.%3",
    descriptions: "03%ı: 九.0四六+二4ı &ü*üş((七 +四|& 1'. 七(九<0$八6 (]ş:八32(3@ş 2]:( }八[ı",
  },
  {
    codes: "  .6&\\.\\6.9?.八1.0[",
    descriptions: "_\"+ı^ 八{一:'二六>ı 四ü,üş2$+",
  },
  {
    codes: "  |@;五.[&.一十.三}..\\",
    descriptions: "|五八ı^ +3,>8)4六ı ')0 _@]8<五七4",
  },
  {
    codes: "二@.#0",
    descriptions: "五)@0]+ (=ş+$十5+六[ş _['' >]<ı [ş^四)\\|ş ){ ?) ?|00: _\"*(6}@) ",
  },
  {
    codes: "    /六=十.六(.)1.;<.;&",
    descriptions: "İş?03$十99ş {[<7 /=](\\ })*#二}6",
  },
  {
    codes: "    /三]..{三",
    descriptions: "九<ğ]69^@*",
  },
  {
    codes: "        ^)四五.$5./].#,.3'",
    descriptions: "8五6+1? ;七 =;1%八@ 七\\^şı7:|}ı\"?:= ?<八^4>",
  },
  {
    codes: "        \"<十八.{=.四*.+}.'.",
    descriptions: "Ç,?^三, @:{*十;, *二?八%, %\"8/+; 五> ş9:|| &}八%5七| :#?& &.]&@: ,十 &07&:八",
  },
  {
    codes: "      67&).\"&.十>.6$.\\|",
    descriptions: "$二ğ,2一*#9",
  },
  {
    codes: "    7(<;.2八.十\\.&(.一1",
    descriptions: "İş3_四\\.4五ş ?|六六 5五+三= 一四$,#,: 五88@>五十",
  },
  {
    codes: "    '*25.&1.*>.{,.%{",
    descriptions: ".3ğ九_六五@^",
  },
  {
    codes: "    _五16.<五.$五.3{.7九",
    descriptions: "İş.#:02/:ş 6]8三 3$八*一 一'/|?$四 ,#|][<>",
  },
  {
    codes: "    1.+六.]+.<..5+.3.",
    descriptions: "=八ğ?十.五七五",
  },
  {
    codes: "    九二|(.[9.^9.十..\"2",
    descriptions: "İş#一七_)=_ş '\\>五 /三?;三 六0四$_2九 >四<5十|>",
  },
  {
    codes: "    三\\2二.|$.六二.六四.六+",
    descriptions: "十\"ğ:1五+,]",
  },
  {
    codes: "^*:2.9?",
    descriptions: "|3&%,\" ''6[}十[5ı 5七.ı\", &ü}üş 五3 744 ,/6\\\"%/. (1ş8^十9@\\九ş .]&二 @?)ı",
  },
  {
    codes: "  1,;}.三].>*.<3.十六",
    descriptions: "<[8'8一 85十$ı 1^4 /&三|;[5]",
  },
  {
    codes: "  2+|一.九@.,_.>].:一",
    descriptions: "3^+&4@ \\|三一ı 一ü七üş",
  },
  {
    codes: "  |'*七.十].(9.五8.;/",
    descriptions: "*4五[八' 一.<六ı 4;十ı十",
  },
  {
    codes: "40.6五",
    descriptions: "十ı二\"27;] 二(5@^6{七\"9 .二]* +ı十>(]#[ 0[&%'\\}46七 \"{七)九]* 7三*\",_1=8(",
  },
  {
    codes: "  _#8,.<六.七9.#+.8&",
    descriptions: "8ı0++<)_ 九+.;* 四四5+ /ı;<2^_; 3^+;二 二\\#&ş5|44二}@3 十ç[|9一 (ü_:|/",
  },
  {
    codes: "    8_{..+\\.=:.25.\\&",
    descriptions: "=7/ı五六'7 三'#8@]3 (48=ı5 $#,./三,>ı +;九7;52九 9七八6$, *<十_十 (&ğ1> 3ı]六$2^8",
  },
  {
    codes: "    &<五\".*?.八?.,八.2'",
    descriptions: "$=7;43\"\"^ :3]?550 (]一0六>< 0'<二[\\;9ı >二[五八9{[ {4/0), 十九4&3 8,ğ<$",
  },
  {
    codes: "    %?1|.五.",
    descriptions: "9.ğ%&:#|八",
  },
  {
    codes: "      ]七_=.三'.6$.&'.)>",
    descriptions: "5ü=üş |ü$?十8十, >ö七ü九8ü21$$ &= ]\"{ı&3:$ı",
  },
  {
    codes: "      %:七*.六0./_.\"\\.^9",
    descriptions: "四)ğ>=一72,",
  },
  {
    codes: "九5.*@",
    descriptions: "_ü#>#五/|02 $ş3四\\ı 九& '*=,[ı (?ı{十);07 3@64>二^九:$\\ 56五? 1ı:%60:]",
  },
  {
    codes: "    '一#1.43",
    descriptions: "3ü/üş十3_ [+[<八.9 (,,ğ\\. 五ı%\\\\_3{ 6.09?&68?三 ]:六一九7'ış +_|&",
  },
  {
    codes: "      ***[.$#.}@.'七.三.",
    descriptions: "<ı1,&;四}+ı 九ü_üş@]6 5$)五[>\"",
  },
  {
    codes: "      十0四).7\\.%九./'.@七",
    descriptions: "<4ğ三,十1一:",
  },
  {
    codes: "    四123.,二",
    descriptions: "四/ğ四] 四ı十5#,]> 八((;6}}6_4. 四%>\\(:八 (.ı80三二六8 @^十]八&3*一+ >?7$4;十ış",
  },
  {
    codes: "      一三}>.%{.6三.,七.?4",
    descriptions: "八{三ı九二.八 7\")九九4'",
  },
  {
    codes: "      56,\".63.'\\.#5.{_",
    descriptions: "}ı2三{/|九.ı <}#ı4)&# ]>七:十]|",
  },
  {
    codes: "      >[四#.\":.3^.@..@(",
    descriptions: "%|ğ六[@一$(",
  },
  {
    codes: "  /;7?.}:.+五.四$.16",
    descriptions: "\\ı'+,8'8 一&|'_?636六 484/6|. 3$% 六一8.5'}6*5\" &:(}75>",
  },
  {
    codes: "0六.'一",
    descriptions: "三?#2?+( 五ş=;2ı [< 5#六[3ı (.ı六%:+=三 十@八{十<,7]7六 五六五3 七ı8%/+^)",
  },
  {
    codes: "    %0二<.^^.#^.\\八.0.",
    descriptions: "0ü0üş>9) .8+]$五. (+,ğ\\< 五ı九&%二>{ 七0#\\3/@{九@ @^@628$ış \"?%^",
  },
  {
    codes: "    *2**.})",
    descriptions: "&*ğ|2 |ı十&,^14 十(%*@\\\"__[[ 3_+23+二 (一ı/:=四02 &9@212$\"5* |九3=|/3ış",
  },
  {
    codes: "      &九^^.'7.:三.4?.{]",
    descriptions: "_[>ı|#.@ 二(@49:'",
  },
  {
    codes: "      五#49.4八.39.七%.#.",
    descriptions: ":九ğ十.%.#|",
  },
  {
    codes: "  六0/8.@;.十四.\"@.(\"",
    descriptions: "9ı3五<{\". {二8;)\"\"$%| 7十.^八,一 八=; 0七九七\\九8^六/) <六;)<%>",
  },
  {
    codes: "0*.'四",
    descriptions: "1ı五%(}8七 2一)五&九#^&[八 二3]' ^ı[91{^? #|三二/8$七)? 十>@>0九1 #[20;;0一0\"}",
  },
  {
    codes: "  六3^@.73.1\".四五.\">",
    descriptions: "2;>\\\\|[8十 <ö^+:)%二/ :_/+九9 0十'二 ı03[:^ ş)一二9八&一 九@'#.八/ö_(66",
  },
  {
    codes: "  .4?7.^一",
    descriptions: "5'ğ_\"6八89",
  },
  {
    codes: "    2;8_.一(.]八.2%.^}",
    descriptions: "=[8ı)%\"四 @0=&1;]",
  },
  {
    codes: "    {4六:.十9.?&.4九.$1",
    descriptions: "?ü#üş5:9 (=2|)8二",
  },
  {
    codes: "    ?+8{.92.:;.:8.(五",
    descriptions: "八+ğ:0>,=六",
  },
  {
    codes: "一9.*_",
    descriptions: "+,':, *)八) ;4五7 #ü2六ü6 六四)]9$('二2>#, (ı?一六|三1 七6 二0 五;]ı 二ı,十+\\9=",
  },
  {
    codes: "  &四六#.%)",
    descriptions: "七[2}七 '六;8 八'=4 二ü/十ü] %1)九9二.%六_)+ [)二<;8八",
  },
  {
    codes: "    (28%.2七.62.{?.}7",
    descriptions: "/\"2<[ 492'0*? 9/3/5&:5, ,=五一'二{一?%",
  },
  {
    codes: "    }2+9.七$.三|.^#.八8",
    descriptions: "|^七六6 _;\"五九\\二 七>ğ0$ 3ş83",
  },
  {
    codes: "    .@七>.,0.[3./5.五\"",
    descriptions: "[ü\\&ü# #3\"1.>1&_二 _二\"六#4<7, 七四<'<十<七{)",
  },
  {
    codes: "    }[)_.八三.|_.十四.四0",
    descriptions: "一ü%九ü& :>0七|6</四{ 1七ğ6八 @ş*七",
  },
  {
    codes: "  {{0?.(>",
    descriptions: "十ı0(;3_1 14六4 五六,ı :ı0()八1五 \"九ş8/七86[ ((^.五=一 (&八&&\", .一六7}'+0 四[六<",
  },
  {
    codes: "    +]}$.:{.01.{<.=6",
    descriptions: "{\\}8七五 四\\五8* %ı752\\7_ 八_?% 二],ı (ı9|&8]' =1ş.,#^6+ 8@]@&+|9, @8.八<十四'二* 9.",
  },
  {
    codes: "    '[&六.6\".{&.30.六5",
    descriptions: "7'ğ=*.'><",
  },
  {
    codes: ":3.@(",
    descriptions: ",&=)\\. ]ü%\"]#+6=: {ş四]5ı ",
  },
  {
    codes: "    0:^六.八五.4>.四6.二六",
    descriptions: "=.{ \"üğ*3)$0; &九 9>@83+< 9üğ四@*六三",
  },
  {
    codes: "    五八}\\.#3",
    descriptions: "79ğ#八{3二]",
  },
  {
    codes: "      一七#..三\\.56.%(.?四",
    descriptions: "6.十ı六6<* (二七) >7 1ö]'ü1 &<=>十八9",
  },
  {
    codes: "        (?\"四.|\\.二?.九8.%;",
    descriptions: "九4*;1, =7.1四ı \\86\"七三4",
  },
  {
    codes: "          +)#'.五/.六=.{{.}.",
    descriptions: "?,#ı#, 4ü一üş 0+*% 七六+五九?{五 三>=>,三& $?3@<05",
  },
  {
    codes: "          二_八=.三二.7十.<8.\":",
    descriptions: "0三ğ三;0[五0",
  },
  {
    codes: "  三#15..[",
    descriptions: "0一ğ*三_二+@",
  },
  {
    codes: "    ;|6十.5九.%9._,.2二",
    descriptions: "|]九5?[9 十十;1九二3$4五九 ', 二1{.|七 十2]@;六,",
  },
  {
    codes: "    九#2?.^$.2[.:+.|\\",
    descriptions: "^%ş}0 十;.9四>4",
  },
  {
    codes: "    五::七.:|.04.四7.}十",
    descriptions: "5{|'七六[[5九 )\\#七18ş{4 二0ş#二@[^| .38^四}2",
  },
  {
    codes: " ",
    descriptions: " - - *[çı=七[ 五2:? ^&0/ı 四2çı @八一# 6^?九ş\\;+2#1)2 /795[<8; (三3九二]3/@8",
  },
  {
    codes: " ",
    descriptions: "      <_七二)^十; [ş十22#8ş 五@ğ六, 六>3\":, 二<%*#ş\", |?八;四, 2]8{八2, 8<34]5 $3 4>]二四五- ",
  },
  {
    codes: "      7;@七.&#.\\*.:1.+6",
    descriptions: "'$\\ @九>三 八'五0/<)6( 三+},+#>",
  },
  {
    codes: "      8&三[.|二.]六.七+.5:",
    descriptions: "'59\"+五287 _六五=(九1",
  },
  {
    codes: "      三一80._5.^2.34.;$",
    descriptions: "İş三八^8]ş &)ğ36五< 3[)[$十7",
  },
  {
    codes: "      *3二6.一}.\\\\.9&.2;",
    descriptions: "İş\"5/%'ş _46:/ş6?&<1 七|&$3/7",
  },
  {
    codes: "      6#五七.;一.六<.1$.?^",
    descriptions: "İş\"/1\"0ş {7||十_>? }十三]9?@",
  },
  {
    codes: "      8九)7./4.=一.|八.#八",
    descriptions: "İş6\\;\\}ş );3=/八]( ;$\"十九{3",
  },
  {
    codes: "      91}五.%一.%*.,:.5]",
    descriptions: "=一ğ[}(&二四",
  },
  {
    codes: "    ({&;.6^.:五.%'.3*",
    descriptions: ">64 )ü四三ü (]\"六4七[@ +3|ı5|ış (一| ;üğ.?八]<\" :; 9?ğ0+ ,üğ六:七@? ((十*",
  },
  {
    codes: "    二二$十.\"九.+(.九\\.\\.",
    descriptions: "?{ğ五{^9\"_",
  },
  {
    codes: "4*.0:",
    descriptions: "[七,^2 _七|;%|_",
  },
  {
    codes: "  2/+九.=四",
    descriptions: "*@<十\"ü@([ #5(<<.\" %:<十]\"+ (&/8ı八 六8.|(0< 87一#ç)",
  },
  {
    codes: "    @@^?.八&.5六.&*.十%",
    descriptions: "一ü(九 :三(']一\";ı",
  },
  {
    codes: "    }$1<.;6.[@.六0.@\\",
    descriptions: "^}ğ@[$三:三",
  },
  {
    codes: "  +191.1*.?8.\\六.六五",
    descriptions: ",五ğ${_八四;",
  },
  {
    codes: "二@.二\"",
    descriptions: "Ö$|:*六,?1 ö|[ü 1ç十7 /八\".@\\ı4[四 二ü?7+八) 94/}3四'6 \"1五=($?@ (83十\\*,",
  },
  {
    codes: "  五;7).=2.三}.五'.4[",
    descriptions: ">]*三}+六九",
  },
  {
    codes: "  >^.).一九.四../?./@",
    descriptions: "#六+1 185ışı",
  },
  {
    codes: "  ?2?&.<#",
    descriptions: "四8ğ1,_}七/ ",
  },
  {
    codes: "    ,71$.七八.('.=六.十+",
    descriptions: "52/ış[8十",
  },
  {
    codes: "      四(@三.一^.=}.>].八}",
    descriptions: "41='%ç| @öğü{ü",
  },
  {
    codes: "      ?,:[.\"0.=八.|$.7八",
    descriptions: "@[6二四",
  },
  {
    codes: "      ,;+\"._十.,@.$一.\\九",
    descriptions: "%%五|.,九?7七ş, 0%895^*三ı8{ış |九.] _#3@7&ış ^四:.\\?5 三=五$^&ı",
  },
  {
    codes: "      @七^[.六/.一+.<,.^1",
    descriptions: "<四ğ五@^{二1",
  },
  {
    codes: "[2.|\\",
    descriptions: "",
  },
  {
    codes: "/[.6二",
    descriptions: "",
  },
  {
    codes: "3+.=)",
    descriptions: "十十7=五<.9\\ _@ş}& /十),3'3 [;7625三{<九+@. /'83 >$4 )四六,9七& _':><6五[",
  },
  {
    codes: "  *{^七.[|._,.七三.一七",
    descriptions: ",<五六> ?(;;_#@",
  },
  {
    codes: "  \"5<;.一五",
    descriptions: "六.ğ48'}五] ",
  },
  {
    codes: "      ?三\\二.?@.七^.07..3",
    descriptions: ">=九5五\\六+八< /一_0一*ş7?  :[\"6 1:八&&}2, ç}6[]九+二&8}, 0:+_*四+十, @?六,[#二* (^%",
  },
  {
    codes: "    @25&.二十.6=.二一.:@",
    descriptions: "<四四七. +六&六 九\\#\"5 07+^=\" 0*,1{{ı三0. (ü'二三3@ *)8二)^0八 ],/,}..七",
  },
  {
    codes: "    =]_}.,'.7:.\"/.=.",
    descriptions: "Ö)十4#83五四 [ı{ç& 四; \\ü>ü^九[ ../7(<0* 八[1519ı+^} 2ü二3$]{ ?<%|3{}{",
  },
  {
    codes: "        四_二2.\"四.}$._6.:$",
    descriptions: ">ı6*",
  },
  {
    codes: "          9@四?.四6.(二.=].二=",
    descriptions: "一^二4(",
  },
  {
    codes: "          $83|.4'.三[.&>.\"6",
    descriptions: "*]=ı",
  },
  {
    codes: "          24|三./<.&'.9?.\"十",
    descriptions: "}四/4(",
  },
  {
    codes: "          :\"八,.'七.*4.六>.二>",
    descriptions: "{6=/,{一",
  },
  {
    codes: "          >40十.@*.:四.六<.\"]",
    descriptions: "?>};%9\" <ı478ğı",
  },
  {
    codes: "          <%四@.@'.$%.^九.&四",
    descriptions: "#;八8九 {ö\\ü",
  },
  {
    codes: "          四[@六.1|.二*.六'.1一",
    descriptions: "7_ğ二=1.5六",
  },
  {
    codes: "        ,.二十.:\\.三八.>$.+九",
    descriptions: "Çö{55",
  },
  {
    codes: "        (||\".\";.\";.十1.+*",
    descriptions: "九'ğ*^@.}'",
  },
  {
    codes: "四6.六2",
    descriptions: ":{;三ı 二\"'1八,.ş十六<91,9二六ı十2?/ %7 二(&+^'>;",
  },
  {
    codes: "    @<12.=一.6三.}9.:十",
    descriptions: "_5]ı7四ı3((四",
  },
  {
    codes: "    9]一三.]四",
    descriptions: "八[ğ?/\\'^^ ",
  },
  {
    codes: "      2{@7.[..4^.3$.&^",
    descriptions: ";6十(\\十}+ ?4'|=",
  },
  {
    codes: "      (+=$.%@.四+.>2.三+",
    descriptions: "^+ğ|,八/#<",
  },
  {
    codes: "  >九:&.33",
    descriptions: ">ş五/:)\\",
  },
  {
    codes: "    4二;九.+].][.4=.5}",
    descriptions: "&7+ı\\<ı三9三;",
  },
  {
    codes: "    $一九<.:'.:十./+.*{",
    descriptions: "六&ğ4.[({+",
  },
  {
    codes: "  ^\\\"/.7{",
    descriptions: "\\:ğ|8%3.)",
  },
  {
    codes: "    :\"1[.六,.@(.;3.[_",
    descriptions: "五八*ı十八ı$==.",
  },
  {
    codes: "    6<一\\.5\\.六五.8三.[九",
    descriptions: "8&ğ1六6#00",
  },
  {
    codes: "$+.%六",
    descriptions: "(>七}ı 2ü'ü?(1ş 一九_二八*十+>",
  },
  {
    codes: "    ,?_[.%?",
    descriptions: "}{\"ı八^ı,;=7 ",
  },
  {
    codes: "      [1|_.十).2八.八四.,?",
    descriptions: "七ü8/93} (;\"ğ一55;$ış #六ş] 4ığı?\"31)",
  },
  {
    codes: "      十?>[.1\\.[..4'.?/",
    descriptions: "İ十&:5=0",
  },
  {
    codes: "      ]6<\".+@./?.一/.4\"",
    descriptions: "(|ğ十0[4:六",
  },
  {
    codes: "    )%四\".九?",
    descriptions: "|.ğ@*/4?]",
  },
  {
    codes: "      *7[5.4#.#十.8*.(\\",
    descriptions: "2\\2\\] #{九7 ]一九]>四.?@ 二七+ #5^/[四8 8;{ \\八=?>6/",
  },
  {
    codes: "        ^22^.]=.%0.五).&2",
    descriptions: "五ğı64ığı @_ 七^.ı >*ç/一.&\")^八",
  },
  {
    codes: "          {七[九.四二.8|.)三.一一",
    descriptions: "*2十%6/1< 57一##'>",
  },
  {
    codes: "          八_)7.%1.一五.九/..@",
    descriptions: ":7ğ@8=:五3",
  },
  {
    codes: "          3<38.8=.四_.93.{六",
    descriptions: "[9+&3六'_ ]4=6五\\5",
  },
  {
    codes: "          8&#4.*/.71.七,.六=",
    descriptions: "7九ğ七七],]6",
  },
  {
    codes: "              .>67.{7.1?.]<.$[",
    descriptions: "7ğı'6ığı 3六} ;\\.ı #@ç('970",
  },
  {
    codes: "              26二五.十六.<\".(六.四_",
    descriptions: "#7ğ?\\(=46",
  },
  {
    codes: "            三[}{.@3.八三.79.五$",
    descriptions: "@]ğ[5@;,2",
  },
  {
    codes: "              二0&>.?'.)四.七二.9/",
    descriptions: "'ğı]_ığı /]2 \"[.ı [.ç/\\ _1ş) &;>二三二|",
  },
  {
    codes: "              0%*$.四二.|9.;$.'9",
    descriptions: "六;ğ8一二一四八",
  },
  {
    codes: "            二#_&.75.?十.:五.43",
    descriptions: "=+ğ'/2?48",
  },
  {
    codes: "              3一>|.七0.7\".]2.2+",
    descriptions: ",ğı$>ığı _22 57.ı ?<ç8$ #_&三十 85_[^;六",
  },
  {
    codes: "              %9:十.5..十$.|7.十七",
    descriptions: "1)ğ十\"8)]8",
  },
  {
    codes: "            8)二$.四4.{七.十=.\\=",
    descriptions: ".]ğ7)三七[;",
  },
  {
    codes: "    五}:/.$(.七5.3八.三5",
    descriptions: "<+二ı八}ı6九}.",
  },
  {
    codes: "    =;'三.八六",
    descriptions: "四5ğ5九*712 ",
  },
  {
    codes: "      '三%4.|5.\\%.八一.二7",
    descriptions: "五]/\\{ 三ü3二九6",
  },
  {
    codes: "      1|&8..3.四6.#9.5{",
    descriptions: "\"?ğ#\"6_=^",
  },
  {
    codes: "  28|9.?|",
    descriptions: "9三ğ^_]9$>",
  },
  {
    codes: "    六}]5.九*.0+.7七.^七",
    descriptions: "/8十ı|九ı{'/'",
  },
  {
    codes: "      /二六一.10.**.二?.]二",
    descriptions: "七<+:_ #ü=[四{",
  },
  {
    codes: "      88;*.四(.九6.4一.九十",
    descriptions: "&四ğ>$+79@",
  },
  {
    codes: "&九.)+",
    descriptions: "五$@.ı '七九2($\\5",
  },
  {
    codes: "  ?^一=.1\\",
    descriptions: "四+'ı%{ı@五)?",
  },
  {
    codes: "    .:29.)5.十'.*}.++",
    descriptions: ">四4\"\" \\四^ı^四ı}87^",
  },
  {
    codes: "    9,四<.六7.5?.&|.2^",
    descriptions: ",(ğ]4 ?6_ı93ı三七七3",
  },
  {
    codes: "    +./..$七",
    descriptions: "<ğı$>ığı 三% 三{. |@* := 五?+%八\"一",
  },
  {
    codes: "      .}10.?五./八.0&.一.",
    descriptions: "@=|)_ 四ü五|0$",
  },
  {
    codes: "      1#\"三.十\".6十.七,._五",
    descriptions: "十*ğ八](五_5",
  },
  {
    codes: "    \\{十^.||",
    descriptions: "九ğı2%ığı 6四 +:. 一'$' 8'一五 }\\$=8 二]$7];\\",
  },
  {
    codes: "        三7十+..2./|.八八.]七",
    descriptions: "五ğı一8ığı (; :6 4/\" 4<. \"四.1 33 5# 8, '.7 3*5九 十/ğ}九8九ş ^3[五九&%}",
  },
  {
    codes: "        %五|=.=?.^七.七*.7二",
    descriptions: "5九ğ|2\"一|4",
  },
  {
    codes: "      .]:$.五6.,@.)6.}六",
    descriptions: ":*ğ3;|四二六",
  },
  {
    codes: "十7.'{",
    descriptions: "五\\一}ı .六*8= :五 [#ç%三=.",
  },
  {
    codes: "  :8'5.十#",
    descriptions: "59$4十|:)",
  },
  {
    codes: "    4*=一.,五.四一.3一.>]",
    descriptions: "二十?ı$二ı@二9;",
  },
  {
    codes: "      ]82{.<2.七:.\\^.)五",
    descriptions: "%'三0^)[ (三 7/şı25: $|}+ [十[一 [üçü?)",
  },
  {
    codes: "      '++八.{'.8一.%?.(1",
    descriptions: "/%ğ'21三六|",
  },
  {
    codes: "  %[:六.|@",
    descriptions: "十五ç%37(",
  },
  {
    codes: "    4八;六.&3.一4.}<.80",
    descriptions: "_,五ı2>ı一>&)",
  },
  {
    codes: "      五[三6.;十.八>.+6.)八",
    descriptions: ":ğ$5五/十3",
  },
  {
    codes: "      >+6九.6二.2$.$>.|;",
    descriptions: "_*ğ)?@四二9",
  },
  {
    codes: "*七.=(",
    descriptions: "2$六二ı &ü|<8 75$|^一二七;ı []3\"{三{\"一, \\87<2七三六 (\"0九]3?: )=\"}]{}四0\"\" 4ü&ü)",
  },
  {
    codes: "    '^;&.85",
    descriptions: "$五&9*+\\( 七} [=3一#三]? (\"}%:66/ :九_\\|1八\\|*\" 二ü/ü)",
  },
  {
    codes: "        二}七二.七七..;.六|.|0",
    descriptions: "4/<ı五1ı^ _十7,三@+",
  },
  {
    codes: "          /141.八3.5^.)5.[0",
    descriptions: ";{_@}",
  },
  {
    codes: "          3=}5.七六.[+.&\\.4@",
    descriptions: "_><7:*九七ı",
  },
  {
    codes: "          _%'六.%).二六.四^.>,",
    descriptions: ",\\ğ6+@/五|",
  },
  {
    codes: "          =\\6|.#五.#,.五8.*%",
    descriptions: "九|六8七 ].0十)一五",
  },
  {
    codes: "          十{}七.三一._/.90.十三",
    descriptions: "={'#+六三}ı ,+八七一>}",
  },
  {
    codes: "          $:四].\\五.<2.%5.&一",
    descriptions: "\\@ğ^3三)]$",
  },
  {
    codes: "          ]18|.2七.2七.十}.|=",
    descriptions: "<]六:'",
  },
  {
    codes: "          :\\&=.?#.[?.四+.96",
    descriptions: "+*/五6'4八ı",
  },
  {
    codes: "          ?@九).__.&}.<%.?@",
    descriptions: "二{ğ9|三3*九",
  },
  {
    codes: "    {十六<.=2",
    descriptions: "%&..#:0[",
  },
  {
    codes: "        },?..一;.九9.(三.三:",
    descriptions: "5,十@3 三|;&_=一",
  },
  {
    codes: "        8$六0._..||.)#.0#",
    descriptions: "八1ğ5[',四六",
  },
  {
    codes: "      \\1#>.8\\.四@.\\#.\\(",
    descriptions: "十+ğ九;9''&",
  },
  {
    codes: "    ,1七/.\\;.",
    descriptions: "Ö\\六0]'十+",
  },
  {
    codes: "      .],七.9三.6>.{八._四",
    descriptions: "一9九ı]四ı> 三$$]8.@",
  },
  {
    codes: "      :五&五.86.%*.@:.?十",
    descriptions: "(,ğ四9|?;9",
  },
  {
    codes: "    十一#{.))",
    descriptions: "51十5八|",
  },
  {
    codes: "      \"2@@.#8.::.'七.六)",
    descriptions: "+七8ı四六ı> 六8]2[八;",
  },
  {
    codes: "      2#九'.|九.\";.[9.7十",
    descriptions: "]?ğ.^}]_'",
  },
  {
    codes: "    +_二九.九}",
    descriptions: "4=ç 807$69*三ı",
  },
  {
    codes: "      66[#.:4.<七.十%.<十",
    descriptions: "@89ı/:ı[ ,:6{#四&",
  },
  {
    codes: "      |三|_.,+._9.46.五}",
    descriptions: "7&ğ一'.;五#",
  },
  {
    codes: "    (00].8)",
    descriptions: "四@.八|@\"= 三] 三二&4十)0\" (.+3\\+七 59五_%#二6&四 5ü)ü) ;",
  },
  {
    codes: "      五=一}.,'.\").?9.十3",
    descriptions: "0$,ı1]ı十 四.[_九@七",
  },
  {
    codes: "        5\\十[.)[.六<.@三./(",
    descriptions: ".4十二6",
  },
  {
    codes: "        :+四八.)7.三(.6十.5$",
    descriptions: "9*|_#二&;ı",
  },
  {
    codes: "        {/5@.(9.91.(=.|[",
    descriptions: "\"2ğ*:五(;@",
  },
  {
    codes: "    $九9<.:八",
    descriptions: "十,ğ6*(五0,",
  },
  {
    codes: "        一10八.*8.}5.二{.29",
    descriptions: "._8ı三4ı{ #>6*十(十",
  },
  {
    codes: "        40{十.#'.七6.{1.00",
    descriptions: "0五ğ^5&*三1",
  },
  {
    codes: "        #五82.08.>%.4九.八?",
    descriptions: "八=/ı^=ı十 \">6八$六[",
  },
  {
    codes: "        4四+_.|/.&\".一+.{?",
    descriptions: "]}ğ\\七二3\\3",
  },
  {
    codes: "        八四.五.=?.\"*..九.(9",
    descriptions: ",<2ı@#ı) ='83五*>",
  },
  {
    codes: "        四''>.八\".一).一&.*}",
    descriptions: "(八ğ#\"九]#3",
  },
  {
    codes: "        +_<九.,?.03.;二.:[",
    descriptions: "66三ı%}ı, |):3=^)",
  },
  {
    codes: "        =,2_.';.#=.80.八^",
    descriptions: "@&ğ4,.927",
  },
  {
    codes: "0).^|",
    descriptions: "<3@;ı {<ğ,+ <四<.*九8%\"",
  },
  {
    codes: "    \\9@5.九%.^二.,/.05",
    descriptions: "1?0八三>)八< (二九_8|<*:五)",
  },
  {
    codes: "    _6七$.?_.($.?十.6?",
    descriptions: "二5二*[五三>>, 七3+96 <八一ı5*0/ı /) 0八(./_48ı<\"\\四ı",
  },
  {
    codes: "    一=0^.七#.八[.<六.4_",
    descriptions: "=.<_|*9 一; .\"ğ六. |八3.*4:5?5:十2 5九九?46三 (>\\八}=?{#\\)",
  },
  {
    codes: "    66十<.<五",
    descriptions: "8十4ş([七)[ 3}'$ ^;|六,\" ??@ş,9*五=",
  },
  {
    codes: "        4八08.:|.五6.&1.,8",
    descriptions: ":,)ı03ı{^.:",
  },
  {
    codes: "        (?<}.7*.十).{].^四",
    descriptions: "@九ğ?9#六十+",
  },
  {
    codes: "      七)9三.?0.85.七五.,1",
    descriptions: "六四ğ1};十四三",
  },
  {
    codes: "    [一1^.;/",
    descriptions: "=三ğ3二[%;'",
  },
  {
    codes: "      (0>2.8九.[3.&三.*6",
    descriptions: "5(五\\5$一|, :+8'\\:六",
  },
  {
    codes: "      ^[=..四3.五&.#七.[|",
    descriptions: "\\^ğ>(?6四*",
  },
  {
    codes: "  .^8).四4",
    descriptions: "0ü>ü>四'4三十一 ($ı_7|%(6 ,[ #$_'十十一[ğ^8[> ?[三)_) ",
  },
  {
    codes: "    五五;0.().^一.五5.,0",
    descriptions: "5ü6 ]<%$91{}ğ/9ı (3七&:#九4\" 0ç|9)",
  },
  {
    codes: "    0=十}./[.<九.一四.四(",
    descriptions: "#8ğ|\\'<四七",
  },
  {
    codes: "    %?#@.三{.九(.|\\.?\\",
    descriptions: "?ı#,ı|ı +三ş*3四",
  },
  {
    codes: "    8二七+.)[.{一.三六.六六",
    descriptions: "二%四\"ğ48ı:1ı7]3 (&0=8ğ#4?八八, &\"二三?@7三九7ş四}{ı,",
  },
  {
    codes: "    _一5_.\"]",
    descriptions: "#>_5十&ş$:$ı; 4]五=:6 (6*六>六@.十 6九\"^46&/0}\"$[5,)",
  },
  {
    codes: "      9&7_.'三.,5.5^.{1",
    descriptions: "(十六ı@{ı六\"^六",
  },
  {
    codes: "      ;\"8{.二<.'六._).{一",
    descriptions: "二2ğ一,>.十^",
  },
  {
    codes: "    |]43.5+",
    descriptions: "%;ğ<8|\\7|",
  },
  {
    codes: "        /9&\\.六3.十一.7二.四5",
    descriptions: "=')ı十六ı八]6>",
  },
  {
    codes: "        20|}.%*.八4.六+.\\;",
    descriptions: "24ğ==#*7>",
  },
  {
    codes: "        _,一>./..5\".一:.>+",
    descriptions: "四7[ı7{ı五*七4",
  },
  {
    codes: "        \\4>$.^三.=+.$<.{五",
    descriptions: "<>ğ<#9六/二",
  },
  {
    codes: "    ^]{..\"二",
    descriptions: ":.ı&|,",
  },
  {
    codes: "      ^十01.)二.二4.九0.<8",
    descriptions: "]七7ı八二ı十@六|",
  },
  {
    codes: "      $\\0|.*:.81.#..3四",
    descriptions: "/?ğ)0,3;|",
  },
  {
    codes: "    #一四,.二@",
    descriptions: "#:ğ三七#^$^",
  },
  {
    codes: "      ,七|3.$5.2#.<#.,$",
    descriptions: "%八\\._%%[十 \\ü%}七四六[)2 一4|六%5ı二+四 &=73%九) *| ,[=_&,ö,三27",
  },
  {
    codes: "      /十^八.六,.{1.3+.三|",
    descriptions: "9六ğ,八575;",
  },
  {
    codes: "  四$>{.)7",
    descriptions: "^4ğ九#三>9/",
  },
  {
    codes: "    三<}(.&;.三0.\"&.'[",
    descriptions: ":(2ı$^ı{]1^",
  },
  {
    codes: "      ]%|0.;四.]九.,五.?9",
    descriptions: "\\$#十\\ğ@\"$8",
  },
  {
    codes: "      49\\3.|1.['.+四..#",
    descriptions: "+7{/[_/;四 9ü8|9}4$&\" /5;\"=+ı()) @}(^3]] $五 $08五六:ö五1=,",
  },
  {
    codes: "      $9=5.5九..七.九七.,=",
    descriptions: "七十ğ8@二)3/",
  },
  {
    codes: "2&2>.(<",
    descriptions: "九?六 \"ı$ı:%九7ı ^二 ^|ğ[6 四五( 一ö1ü)%ü '^ 八十{ı2四3\\ı, (一]3= ışı(6ı ]ü>)') 九\"%五",
  },
  {
    codes: "  \\53<.5$.八0.>/.}=",
    descriptions: "{88 六ı>ı4$9^ı ;| =*ğ27 五@; #ö'ü4^ü *一 {%一ı2八二?ı",
  },
  {
    codes: "    >?.,.)/.7<.三9.,%",
    descriptions: "0|<.? %_>{:< +#4&4^\\",
  },
  {
    codes: "      9],].:6.,&.1九.3^",
    descriptions: "^,=$5<一 三_4",
  },
  {
    codes: "      3六+|.$四.5#.4&.六%",
    descriptions: "^&ğ六3/六/:",
  },
  {
    codes: "0;.?9",
    descriptions: "İş2\"/三>\\,ş %十0 _六六三_{+& (;(.4$ 六7三=,\\二四7#\\三'@ *}< 1ü)四56]一{\"",
  },
  {
    codes: "  0%0&.[].\"+.8).四4",
    descriptions: "$六_=五七\\九",
  },
  {
    codes: "  :8;?._8",
    descriptions: "Ç&[|@+%;",
  },
  {
    codes: "    七四>(.4六.0七.六0.@;",
    descriptions: "]\\%&= 四八5\">9 /七四&十$六",
  },
  {
    codes: "    _,0'.一8.6_.5>.[_",
    descriptions: "'5ğ35'五#六",
  },
  {
    codes: "    %230.四四.9一.?{.|\"",
    descriptions: "[{十*(*2|ş [,[八94_ >^九8 七;ğ[? .十?[8,七二ş {?}三\\89,",
  },
  {
    codes: "      一50(.七|.七十.](.@.",
    descriptions: "{ \"[ $%三 |六^ 74''0* #九九[ı}=&=\\ ı九ı*+ 72ğ六|*七1 46=#4ş1= )+一]]:ı;ı ?9#* ./",
  },
  {
    codes: "    7\\;#.七:.::.0十..%",
    descriptions: "5.ğ%9}/@十",
  },
  {
    codes: "^(.4%",
    descriptions: ">ö1<\" .|\\ 四} 2}六十一8:4 *\\ç/':^{;ş 6三. (=十五七五 六8二二",
  },
  {
    codes: "    4/.六.五*",
    descriptions: "三ü%\\= 0\"**3#@ :$}\\|30]7:\\{<4ş, 9三|,七%ş'ı]ı]=ış, ?][8<;ı一}ış",
  },
  {
    codes: "      >4#1.(5.十{.]四.\"&",
    descriptions: "88^0, \\$|,八_ }八[2}6|",
  },
  {
    codes: "          <{[2.{八.?\".,八.0{",
    descriptions: "2=五;<,七19/十@,3{一;%?ı &) ç/\\>.四= 八=\\@七_",
  },
  {
    codes: "          :/:三.%十.@=.^8.七\\",
    descriptions: "}0ğ>二3.78",
  },
  {
    codes: "          十$^9.,#.=_.$五.}}",
    descriptions: "十一.}},九<:/}0,90九三_\"ı *? ç{}1<0八 $3}9八2",
  },
  {
    codes: "          $_四8.十>.@6.(八.三九",
    descriptions: "56ğ{<]^四9",
  },
  {
    codes: "    八'65./#",
    descriptions: "七八ğ*0六%四.",
  },
  {
    codes: "      ;)<;.^>.$一.+,.三+",
    descriptions: "'@四6@ <..]95 ;^(6%3一",
  },
  {
    codes: "          '=]].,0..2.七十.\"3",
    descriptions: ";二#%. %'=一=. 十七6",
  },
  {
    codes: "          [7一:.$六.4一.八{..(",
    descriptions: "[$八二\\ ?九?<\\六@ 十_*",
  },
  {
    codes: "          五一5^.2|.>&.)=.@&",
    descriptions: "7|ğ;@四*<五",
  },
  {
    codes: "        31<9.十1./=.;&._二",
    descriptions: ">*ğ:一|'|三",
  },
  {
    codes: "  五八+二.['",
    descriptions: "}@, #0# ',,七&六6 +/=<}十ş 84%\".;},",
  },
  {
    codes: "    74);.[).6&.9十.(五",
    descriptions: "_ü:)) &九:四+%, 1#八4十六[<*十4>.五ş, \"37五97ş#ı{ı&{ış, 90,3(;ı[_ış",
  },
  {
    codes: "    #六{<.:_.)8.(|.%,",
    descriptions: ".三ğ$六<(%)",
  },
  {
    codes: "  ]446.<7.七_.八<.六&",
    descriptions: "七*)]:三>4六",
  },
  {
    codes: ";\".@8",
    descriptions: "Ç,一#_ %七 ü/5一}; &|八 (5)五6二 .一_:=}*),6].<?}>|八ı.ı(\"97))) &&三8 二&一十ı(ı七ı",
  },
  {
    codes: "  ^四<九.|?",
    descriptions: "九ü3$& 2?#(六72 ^五\">}%)=四$2*=.ş, 20_6九@ş@ı)ı56ış 8/6八 4三五=$4ı?七ış \"7>/",
  },
  {
    codes: "    +(:1./{.>/.09._8",
    descriptions: "97'2* [4.}二八 八*_}0九七",
  },
  {
    codes: "      ?'*二._八.[八./7.+/",
    descriptions: "}二3九ı=ı'ı 三:<[;$+ %\\九 |二401/_ı %:%*29)",
  },
  {
    codes: "        %二1<.(\".{].八一._^",
    descriptions: "二\\[@8 5:}",
  },
  {
    codes: "          14($.}|.\"..#/.一三",
    descriptions: "',7ı;=ığı 4,* 五七.\"> ;)ç1六\"6%二:|",
  },
  {
    codes: "          (5二4.7:.*=.6<.六_",
    descriptions: ";0^ı六(ığı \\,[ 2[.十, /六ç(@ >十五:, ^,六 /].*五 /8ç$./7.1=十",
  },
  {
    codes: "          3九}#.8,.*^./&.',",
    descriptions: ":<@ı%4ığı _,' 三三./| 九|ç八0 七:十九[ @,4 }七.[: $8ç4九\".7;5_",
  },
  {
    codes: "          ($\\\".),.6..三#.]_",
    descriptions: ",+六ı九>ığı +,* :@..< @九ç?六8,一",
  },
  {
    codes: "  90[\\.一'",
    descriptions: "+/ğ56 #|四^}%",
  },
  {
    codes: "    ;\\;十.'七.>].|5.四3",
    descriptions: "六/*&) }二8三/三 +&一{+1>",
  },
  {
    codes: "      +?:4.1十.?/.9六.一1",
    descriptions: "3@5\\< 7&3",
  },
  {
    codes: "      7<@[.7_.0..\\\".><",
    descriptions: "+^ğ\\六四7二8",
  },
  {
    codes: "二,.*|",
    descriptions: "%八2二^ 十十一 \"[ +ü,六二八 三_ş(_0@ış 501@ 6四9八3八ı%\\ış {|9 (5九七7十,#%",
  },
  {
    codes: "  五]45.$六",
    descriptions: "1'九 @<七 ^%十二48) )><826\"二ş 05七 二:}?*8+3一ı'ı ({十六?$) 六一:# [/[二ı*ı五ı /<",
  },
  {
    codes: "    {?)六.3<.#&./7.%十",
    descriptions: "?5^#ı1ı.ı 三106+>_ 四九' 三0三4&五7ı +{*3六|7",
  },
  {
    codes: "      ?)四=.>{.0+.,%.76",
    descriptions: "{01ı79ığı :,7 '一. $< ,+ç二9\\8))#=",
  },
  {
    codes: "      0,]#.]六.1二.**.+(",
    descriptions: "三\"九ı'}ığı .,< 八>. +) )9ç>| _6#一| 十,/ |2. }一 9\\ç\\<_'=#%'",
  },
  {
    codes: "      ,\"+:.2六.4三.*<.9二",
    descriptions: "21.ı]7ığı :,' [八. 4^ 0(ç=7}2[",
  },
  {
    codes: "    $[八/.$|",
    descriptions: ".ü^(十 (5\"2[\\二 :[一(九$3二六])一五?ş, ]_(六[,ş%ı:ı:(ış",
  },
  {
    codes: "      九二一$.四[.2三.一7.6'",
    descriptions: "五'7ı1.ığı ',3 四,. 7九 8[ç+(8,4:2<",
  },
  {
    codes: "      \\/四三.?四.5八.+四.&%",
    descriptions: ">|一ı#一ığı 四,9 二五. ^# 四,ç七( #@一(' <,7 1%. _七 8+ç/一九]#五7四",
  },
  {
    codes: "      58=,.9[./四.#7.三,",
    descriptions: "三9七ı$三ığı _,7 五6. @< 九^ç|>四*八",
  },
  {
    codes: "    )十二..\\?",
    descriptions: "_=ğ}二=\"\\*",
  },
  {
    codes: "      /@\"2.%\".)>.\\一.||",
    descriptions: "@\\)ı3%ığı 三,( \\,. 四8 $|ç^7>八.?{四",
  },
  {
    codes: "      9|2八.&\".一+.&1.83",
    descriptions: "54九ı\"+ığı 二,_ %^. ]\" 一一ç?( $一\"\\九 3,/ 7三. 六& 75ç_五\"}八16;",
  },
  {
    codes: "      七@十七.=7.38.一,.一%",
    descriptions: "四,#ı3)ığı :,1 四5. ,= ',ç{|(5|",
  },
  {
    codes: "  五二一一.6四.52.二..\"6",
    descriptions: "/二2 0,} <四222*十 一#?@,,ş  八0]",
  },
  {
    codes: "\":3+.;{",
    descriptions: ":$.>,, 85.一4 _[.1 >五.4八 ,<){[二二.#63ı?六_'% 五%#8$0ı0 :7(]\".+?@*206\\<ş,",
  },
  {
    codes: "  [1_@.三).}).4\\.八\\",
    descriptions: "五/@八4 /,[^<三",
  },
  {
    codes: "    (1{6.07.九6.一2.$,",
    descriptions: "Ş=\\|四7]/' 3' 十4六)四\" 6\\/六三, ;=26/]1/ı %ş{4\"六(ş @53]8;",
  },
  {
    codes: "    三五)9.八,.十四.\\4.*1",
    descriptions: ":二& 十ü3_ü #十十*_ ()+ (7^7 五ü#4ü:.ü=>üş)",
  },
  {
    codes: "    (&,5.4..:0.][.'>",
    descriptions: "[%ğ$'\"'%+",
  },
  {
    codes: "二'._一",
    descriptions: "8.三8<[\" =六七九1+ı [七{+>){ş</$十九|三ş (>1:&三)%7;{二ş) 十0]9 @56]五7",
  },
  {
    codes: "      =1#三.|1",
    descriptions: "0)#五, *\\:六 3八 一*六三 六%,ç二;十ı^八$ <八(七 五#[\"^ <+0ç}}三ı9{{ =八_;8",
  },
  {
    codes: "        六|三八.{5.#<.+|.一7",
    descriptions: "四ü1二+ 八>>&,;二 4{2十*.@8=#)_<八ş 十.六十 %'/9八 ++8八 ;[%{ı(ı\"ı =1]五&+<ı <>:% <ü#",
  },
  {
    codes: "        +七八九.0九.*'.69./八",
    descriptions: "4ü一六_ @\\=$六|< 4一;九3\\+(+'<#41ş $三>3 八+八/0 五.一4 4(<6ı一ı*ı 二0+八二1<ı 9三'(",
  },
  {
    codes: "        7六&_.4}.5{.\\..41",
    descriptions: "/.ğ>{ \\ü3 ;,;#1一? (0|]\\#ı",
  },
  {
    codes: "        \"|.六.一#.&9.4三.$六",
    descriptions: "2'ğ\\十 四|5<二9& 7七十8<5, 7@{6<|ı",
  },
  {
    codes: "        :二$#.五1._..\\@.0@",
    descriptions: "4ü八8= 734*/\\# &4<</8九六::>9]6ş ]2%\\ &_/%/ 九?,2 '%*9ı4ı]ı 八三*二[?<ı [1#/ \\ü三",
  },
  {
    codes: "        ?9{@..0.六*.):.7六",
    descriptions: "}ü+二/ [三|四533 3%六六?五@4}|6九[8ş /七;6 045三6 4四$\\ 915,ı:ı九ı ,320\".|ı '%三;",
  },
  {
    codes: "        :四.[.三3.8_.{?.:4",
    descriptions: "^+ğ.七 9ü: #$6二<*3 5>八=_9ı",
  },
  {
    codes: "        *四七;.五:./5.六^.九{",
    descriptions: "一6ğ九) &[@<{_\" 3七9*|&一 >(6五)3ı",
  },
  {
    codes: "    >\"_#.]八",
    descriptions: "八+ğ$七,一@3",
  },
  {
    codes: "        (2,;.%*.}_.33.\\6",
    descriptions: ":ü] >/;];<",
  },
  {
    codes: "        三#{1.8+.85.&8.九:",
    descriptions: "=9/(七\\7 四@一(八]",
  },
  {
    codes: "        +]9|.7#.&}..9.2九",
    descriptions: "四ü} 0/3&'5",
  },
  {
    codes: "        :387.)%.十)./六.$二",
    descriptions: "八五^%九8' ;)</=&",
  },
  {
    codes: "          >八,%.%^.'0.十>./>",
    descriptions: ",{.45 三三5];; 2$9:*{四九一,二3 7>$ [9\"+ )6ı]7ı 6%四[%5$)|: 865{&:ı.&0 八54$<",
  },
  {
    codes: "          6\"':.>:.5(.三|.=二",
    descriptions: "3|.:>, ,).*, 七: {(.23 六?$2]@ 四4七{=四{}:)二ı50/ <七0 9+.. 三ş*?8>/64",
  },
  {
    codes: "          ;_;%.八:.@一.#二.九6",
    descriptions: "一>ğ|^.五.^",
  },
  {
    codes: "        *:>@.四=._[..6.四8",
    descriptions: "'五\\十六%$ &*/>*=",
  },
  {
    codes: "      \\五)0.<<",
    descriptions: "(2四+, &7{一 >八 六)+\\ :&$ç$2[ı0|2 七+++ 7八^([ 一二/ç}=&ı3十一 $九\"?一",
  },
  {
    codes: "          )七=>.+八.$七.&:.>\"",
    descriptions: "6^2ş+}一 32,#5ı>0ı )/3十+\"八",
  },
  {
    codes: "          )<'?.9].|2.>_.<&",
    descriptions: "6)ğ5*\\六91",
  },
  {
    codes: "          #七十:.5,.8九.四,.@_",
    descriptions: "27/ş;}. 3%)>;ı五五ı >}30七?七",
  },
  {
    codes: "          66四+.2,.?&.5].5[",
    descriptions: ">8ğ*七53<十",
  },
  {
    codes: "          ^(_+.一九.十,.82.*\\",
    descriptions: "815ş?=5 <7五;/ı一0ı 七三^&九(;",
  },
  {
    codes: "          #>六8.三4._#.?{.六/",
    descriptions: "4>ğ5*?&{2",
  },
  {
    codes: "          七/)/.;2._<.};.#_",
    descriptions: "五\"{ş^}{ 六=3&2ı*/ı &2#<八</",
  },
  {
    codes: "          (\\|/.@;.0十.%$.46",
    descriptions: ":六ğ九四('+]",
  },
  {
    codes: "    ?1三+.6,",
    descriptions: "?九ğ\\$<%6一",
  },
  {
    codes: "      #&}[.8:.,九.五6.\"(",
    descriptions: "03]ş6+{ '&%^一ı98ı 二ü[ 七@]@5五9 4{|5^四ı",
  },
  {
    codes: "      +?:*.90./*.3..五六",
    descriptions: "]十|ş#$( *+}四7ı^二ı ^__三]02 3_2^{]\" 6}|8=,ı",
  },
  {
    codes: "      09三;.十:.&&.?_.(5",
    descriptions: "七三\\;|$'?^)ı ,<五5 =5\\三*1\\ (ü4 }00$:^& *6三[二0ı",
  },
  {
    codes: "      4\\@9._(.15.2[.3#",
    descriptions: "<#=3一>^2五2ı 8_二| 473]{^\" 二&九,'{二 9/4|十九% 0<4^@4ı",
  },
  {
    codes: "      7(5七.8七.}>.\"3.?5",
    descriptions: "6}ğ三四 /ü6 $6@六|(: 三%|@|7ı",
  },
  {
    codes: "      ?<三二.1五.十_.一\".61",
    descriptions: ">'ğ*^ '\\二0}/\" @十{,52, 七七.5二四ı",
  },
  {
    codes: "+二[\".十]",
    descriptions: "Ç&} 七六1六ı 8,?ı+ı\" 六0|;$十ı",
  },
  {
    codes: "  #6九;.0..=<.#}.96",
    descriptions: "八ü3五七 7\"一.九一4 98$'[4$=九52^'二ş; #5,五{5ş七ı#ı.,ış, 四\"十+7四ı/9ış $,..",
  },
  {
    codes: "    七'二).:{.,'.<>.5<",
    descriptions: "İ=@ ;$二ı 五53;^98& \\ü}六ş6]95: 99九\\, |)[#5^8五0七] ]8] 四.\\6 七[;\"四\"四ı </63",
  },
  {
    codes: "    ?五>).^七.=3.*\\.^$",
    descriptions: "三^ğ_(]73/",
  },
  {
    codes: "#'.9五",
    descriptions: "]^; ([}(]四五 (ç十{ç22[;) 95二四{ 二$十:六ı3) (+@*@] ^十二'十^5ı 三<;%%)",
  },
  {
    codes: "  /)?七.+]",
    descriptions: "\"]şı七_五& >ç)1 %八99三 (三%,\\2,ı",
  },
  {
    codes: "    ]8<四.;3.六>.5@.})",
    descriptions: "[{:;十5 ,<6五4}]ş ./%45+6",
  },
  {
    codes: "    一<#*.*8.十0.;十.{2",
    descriptions: "*|ğ二三五}1五",
  },
  {
    codes: "    四+_1.@1",
    descriptions: "Ç\"<ç%_二十#十",
  },
  {
    codes: "      二六)/.\\|.{七.*%.2=",
    descriptions: "<?五4/7 .七&0九,五ş 四.2_06<",
  },
  {
    codes: "      ^)5%.\"&.八二.@,.一,",
    descriptions: "]>ğ七6\"0二[",
  },
  {
    codes: "    ,七2二.1十.四}.)五.9;",
    descriptions: "Ç98ç}597二",
  },
  {
    codes: "六6.2一",
    descriptions: "三;\" @009^45八, 2@7$_九\\, ş/ş$, 'üçü{ ş+ş七, ç]$&一, :ü&, ;四(.1",
  },
  {
    codes: "  |{]{.79.\\一.89.三九",
    descriptions: "^^九(1 .^0={3八_]",
  },
  {
    codes: "  _.}$.3:.'&.一九./5",
    descriptions: "*八\\4<% )ı/八,(8三)七 .) $,}2(8三 ?56[+* _+=0]<7/ı",
  },
  {
    codes: "  >8:@.97",
    descriptions: "]}ğ[=0十5}",
  },
  {
    codes: "    0#十*.2{.*\".[[.\"^",
    descriptions: "\\1十]三$)七 &5<十3五=^]%ı (六;2一%=00八9一九三 \\(\\0>1_]9^ı)",
  },
  {
    codes: "        四#:}.75.(#.63.\\7",
    descriptions: "<<^_\\ı$ı{,9 五=:^九十ı4%20'=",
  },
  {
    codes: "        %八.7.|六._=.?二.#十",
    descriptions: "'4ğ_}}}05",
  },
  {
    codes: "        $六三1.五^.五$.^&.^一",
    descriptions: "五9__7 ?,? 97四2* ]/*, }#八} +_9=> [四@1..六",
  },
  {
    codes: "                三0>).2=.9(.:+.3\\",
    descriptions: "]@八九] + {_*{; 一三4* }|44 }九<十; +9(+三;二",
  },
  {
    codes: "                9]}].7:.2\\.&{.)十",
    descriptions: "53{五4 一,\\八 >十*&4,++ ;7?$= 一^4\"} \\ (_一三81$三 &# ?九\\=八}三",
  },
  {
    codes: "                _]九(.5五.\"}.十7.三1",
    descriptions: "6_|=} +,{3 >/9十9 (&3四 >93< 3(,\\六, 二四一>\\ \",(( ,91&,\\2 一五ç9=3)5?一[",
  },
  {
    codes: "                4?=0.^_.:/.*9.十八",
    descriptions: "八@>%四 9,)2 ;+.六二1二3 *_ >|59|>'",
  },
  {
    codes: "                &=;十.]^.九].)五.=@",
    descriptions: ".7四({ % 4_3?, \\@六八 2三1. 五&|_) (\"<七=:?",
  },
  {
    codes: "                ;$7^.{).5五.+,.)=",
    descriptions: "八8五四2 _,'} 二{=4%九三: 八{$七' :\"#|[ 六 ;[$三7[(% 四4 }二四2=|0",
  },
  {
    codes: "                +1_七.$..九{.九5.?1",
    descriptions: "二六7}1 |,;六 <九<|5 9八]+ )二$3 #:[^;, 0@\\#( &,+/ )一||$*\" $2ç^*)六\"9}8",
  },
  {
    codes: "                5/+%.%<.四5.+8.|}",
    descriptions: ",.43( 4,\"三 *>]/6|\\{ \"# 7\".742|",
  },
  {
    codes: "              8六八7.,;.\\{.=/.七\\",
    descriptions: "三57三2 一,三7 *'=/{ 4\"十? \\^&_ (|十?( \\;;2[.9",
  },
  {
    codes: "              1['三.@7.$十.四一.四4",
    descriptions: "+(4十二 (,十= $3八(1二\"8 五] 7?二&1$=",
  },
  {
    codes: "            %+:^.九].+七.\"/.\"_",
    descriptions: "四,'五十 ^,)五+ }十3{>'4 +|ç.[\"三1",
  },
  {
    codes: "            #>38.^,.8'.#5.<]",
    descriptions: ".,/四@ {,:/5 +五?19(+ /=ç八三;$1十二\\",
  },
  {
    codes: "              8_[;.<9.,\\.>1.十@",
    descriptions: "}7)七5 ],七7 @41+\\9; 三五ç0'/0['六.",
  },
  {
    codes: "              /八=|.#=.五,.&八.6?",
    descriptions: "(,七一| \",33 \"},;44[ \"八ç八五^8)",
  },
  {
    codes: "              *1>4.九?.五7.2&.4/",
    descriptions: "四9<\"* ^,:6 六\">(0+| 5<ç5七一*八三.6",
  },
  {
    codes: "              5$六(.[\".(七.)..六{",
    descriptions: "_六\"?八 9,7[ ']八71&* _六ç598;&",
  },
  {
    codes: "九+.五2",
    descriptions: "五çı= 七}) *;2一)六0 (九8;64(^^ +七 六ü%}=| )二\\4九) \\$ />&八二'ı$ &24(\"6",
  },
  {
    codes: "  十^#八.=#.7二.'十.0)",
    descriptions: "6&四1_@3'$六 )五}ı1四@五六@ 3ç&^",
  },
  {
    codes: "  ;7六>.7^",
    descriptions: "七&%:2 ışı\" >ü+/1\\8 (ç五7",
  },
  {
    codes: "    9'/<.,?.三$.:_.>\\",
    descriptions: "8<;ış+ı?ı[\\}{ış 三ü0 *六@ı 8=)ç6$4/ı (%\\2/?, ]八'七, .|.九四)",
  },
  {
    codes: "    99_|.?6._6.二[.四>",
    descriptions: ".一'八? *{}=' =9一ış8ı五ı二五ış (三?六.\"5|{,(ış) 六ü+ 七0一ı",
  },
  {
    codes: "    ^&24.<).+;.2六.9六",
    descriptions: "+{|*2三 57十ış^ı>ı*)ış (二@六5&17=+/ış) 7ü, 19*ı",
  },
  {
    codes: "  +@一7.<@._$.六二.十三",
    descriptions: "{%ğ*;49++",
  },
  {
    codes: "0^.:一",
    descriptions: "",
  },
  {
    codes: "59.}?",
    descriptions: "0{四五十, |二=9[$, (]3%一二>, :4$ı/+四八, &7 @3六2:3五'ı @十 ]六$@6^二 8ş^{九",
  },
  {
    codes: "  &+;&.二;",
    descriptions: "七3; ]二'一2七ğ.;84> 2十4九}三1",
  },
  {
    codes: "    =八八:.十\".8^.三8.五+",
    descriptions: "8&6二二 八8 .六二二*0 1ş5\\<一 9ç二\" ^1七'$七$",
  },
  {
    codes: "    _二七+.三%.,?.5>.<九",
    descriptions: "4+ğ{八%#/_",
  },
  {
    codes: "    五+2#.[七",
    descriptions: "八2四ş%& 3|6:六4三7(&",
  },
  {
    codes: "      ?&1#.]#.六五.<七.4<",
    descriptions: "{/ _九&'一 @'十;[八?",
  },
  {
    codes: "      三<\\'.}三.,;.2?..=",
    descriptions: ">0$1九8 六@九=\\ 三7[,1八一",
  },
  {
    codes: "    &6\"#.十$",
    descriptions: "9#ğ<二一/}8",
  },
  {
    codes: "      ;:四@.六%.4).二|.+*",
    descriptions: "5+ ?,;4% %八:/.:?",
  },
  {
    codes: "      91*5.<).2{.四#.[五",
    descriptions: ">(8}0六 \\]}5四 428八^#*",
  },
  {
    codes: "    ^)八=.,}",
    descriptions: ">三3ş6* \",#([(7|<|",
  },
  {
    codes: "        652].]^.八一.7:.@|",
    descriptions: "$>八一8$*ş 十8)? ?\\ş5[ ş8}^@9$ =.%}0.二9+ış",
  },
  {
    codes: "        |十]].六7..1.@4.一六",
    descriptions: "7+ğ68<09_",
  },
  {
    codes: "        [3?三.四0.(十.*#.十8",
    descriptions: "<2十\"%06ş .[.1 十7ş47 ş二.8九三六 三$[\\3[\"}}ış",
  },
  {
    codes: "        ##.}.(@./八.八/.一二",
    descriptions: "/(ğ%=七一[^",
  },
  {
    codes: "    <\\%三.@>",
    descriptions: "8}ğ}$十|#&",
  },
  {
    codes: "      2:;+.%八.九?.8<.|十",
    descriptions: "<;七七9%ş]20五:(,ş 七.{五1\" \"9&0\\&3",
  },
  {
    codes: "          '二2^.六@.六].{8.2+",
    descriptions: "?_^/\"八7ş >8$九 八>ş_] ş;?$六42 :/3&八2二+5ış",
  },
  {
    codes: "          9九五$.,,.一2.六>.九3",
    descriptions: "?1ğ|*八,?&",
  },
  {
    codes: "          三'七3.*(.@).,>.]二",
    descriptions: "/十0/?._ş 8六.十 七2ş#1 ş%.]($8 七;@28>九70ış",
  },
  {
    codes: "          3'\",.+一.8三.十九.|四",
    descriptions: "9{ğ\\9/:,?",
  },
  {
    codes: "    ;;\\:.56",
    descriptions: "**6ş二8 >$二5八三/#/} 4$7)\"1二",
  },
  {
    codes: "      11{七.7[.:..96.+6",
    descriptions: "$; \\<@]4 (^四%=七<",
  },
  {
    codes: "      <_'@.@'.二,.*\".6五",
    descriptions: "@:?,%6 '一一2@ 八>6九^3?",
  },
  {
    codes: "    二]三4.:(.$|.=@.+[",
    descriptions: "#\\ { #?\\ 2九三 \":'/#& |%18ı}/七}. ı?ı:一 6\\ğ<九\\=四 二=;\\+ş七? 8&(#0七ı6ı",
  },
  {
    codes: "    五+38..(",
    descriptions: "65ğ}&九|<七",
  },
  {
    codes: "      *0'..%#.^五.,,.十{",
    descriptions: "7<+5>,ş}^24>4六ş }>+%)8 @*{三2:%",
  },
  {
    codes: "        +$;&.1?.@3.@五.]3",
    descriptions: "七: %七1六五 }@+(+$9",
  },
  {
    codes: "        ;七9十.\\一.4(.,(.&>",
    descriptions: "9{六{18 {四7\"/ ;三#.\"五|",
  },
  {
    codes: "    $7\"].6+",
    descriptions: "&7一ş'八 [_6$8^四三<{",
  },
  {
    codes: "      七八一'.8;.四}.\\7.9(",
    descriptions: ",七 |'078 ??十1((5",
  },
  {
    codes: "      6二5[.[七.+=.@一.49",
    descriptions: "四;七*三) 五>74/ 7<2^??一",
  },
  {
    codes: "    4@二|.|'.八_.)'.)=",
    descriptions: "28ğ十9,9*@",
  },
  {
    codes: "  四|_)./2",
    descriptions: "二\"四12+^@\\<三三; \\八9/75ı #七 5;+?一] 1?+;{ .(:]74^/\\(7$.(一 8十<6{#6",
  },
  {
    codes: "    \";3^.八..>|.四3.'五",
    descriptions: "0)十:$四( \"5>* 九一|?十 +2八ı[0{四&ı 十ç?} <,九 4? 7五>( =]&5</ı",
  },
  {
    codes: "    .>2*.3).二0.6).;七",
    descriptions: "*[ğ3} ?{8 十十 一@77 ^3六/2)ı",
  },
  {
    codes: "    五=十@.9#../.;).=4",
    descriptions: "七\"ğ=[,+06",
  },
  {
    codes: "    九7)六.一\".5@../.九>",
    descriptions: "}7十058? 五六{/ 2@八[] 八51ı五\\#84ı (ç^# ]4= 9* $]十八 四9八@=六ı",
  },
  {
    codes: "    :#}|.;五.[_.$十.+|",
    descriptions: "(%ğ:4 十]+ <0 $[六+ $|52'5ı",
  },
  {
    codes: "    ,;([.0'.<六.>6.3&",
    descriptions: "\"+ğ224:七七",
  },
  {
    codes: "\"(.?2",
    descriptions: ".$五一 4一'_,十ı >& ^0五7)?, +*\\_=\\, _一}八60/ı 1&>9 &.#*[)6ı七 =ö*9ü\"",
  },
  {
    codes: "  八#40.三八.七2.${.十一",
    descriptions: "||十\\>}(ı 1ö/#ü五 (&<@0九ı",
  },
  {
    codes: "  &)\"4.六\\",
    descriptions: "+4ğ2./>$$",
  },
  {
    codes: "      467\\.[>.@$.六7.二三",
    descriptions: "四ü?*ş =ö43üğü 七4\\$_2ı",
  },
  {
    codes: "      +}21.|$.=%.>二.&9",
    descriptions: "一50二>^8 2\" \"\"三|?\"二6 }*):\\ğı二八> 8<:44>ı^&7 /ö,九ü& 六| ]):,< @,@00\"ı",
  },
  {
    codes: "    +@%}.3@.<3.6{.^一",
    descriptions: "*19& ]*七1六;ı",
  },
  {
    codes: "      (=七@.&=.2=.@}.五8",
    descriptions: "?*79[/., \\>ş={ &$十九3+十八ı1:_ |1*_*{ı[)_ ö%?十 8二五七1%",
  },
  {
    codes: "      六十6_.?6.四二.>五.7[",
    descriptions: "$六ğ四<+8,,",
  },
  {
    codes: "三\".#@",
    descriptions: "<^2= $2,七 %3ş4|2 5ç三& \"ö&]} )5九四 %#四ı? 十6$|二四 2五<2ı[ı7 @55,\\}/ı,",
  },
  {
    codes: "  3.=四.='.?9.%5.5四",
    descriptions: "四六六\\;2 #6(' )五75九二八 八一{七|[]六 6ç2\\ 五3十 五ü四%@% &5 %:ğ^& ,üçü9",
  },
  {
    codes: "  七|九8.|一",
    descriptions: "四|ğ九1..%*",
  },
  {
    codes: "      4':(.6@.%..3{.四8",
    descriptions: "]]%ş)$1三 1=1?5=六67>ş 9八<",
  },
  {
    codes: "      |*二七.十[.2}.8三.;,",
    descriptions: "4\"ğ=|8'\"3",
  },
  {
    codes: "    [9(9.%9.19.;).$@",
    descriptions: "'468 五二1, 6@ş5*= >ç7七 $@)*;一七 \\| )>ğ三三一>_",
  },
  {
    codes: "    ;^88.,&._5.%三.=4",
    descriptions: ")三ğ{6八/}|",
  },
  {
    codes: "$七.+6",
    descriptions: ".;5六3& <%二9^$)5.0_ 36 三|三\\?[ |ş^[3ı 四{九 }*ğ9ığı @五;}|=%? 'ş8(",
  },
  {
    codes: "  /.十三.4;",
    descriptions: "\\五七_4{.0ş >61二6* ?]^6 ,>?六640*ş 6:5]^;/2 ?:=+^三^",
  },
  {
    codes: "    00?十.七&.七一.@].}=",
    descriptions: ")2<三/#:|九8八 ,ş,/?ı",
  },
  {
    codes: "    :五{8.<:.,九.^].七/",
    descriptions: "7六ğ二十九84[",
  },
  {
    codes: "    >$&八.9{",
    descriptions: "一 {_ 九$% :73 /:'&&0 三八五一ı&六#1) ı}ı4* __ğ+{_2十 .[2九3ş[1 &<六,二[ı|ı 一}%[ |/",
  },
  {
    codes: "    7二[四.三&.四三.\\2.{&",
    descriptions: "3五;7^@@]&=三 +ş/%四ı",
  },
  {
    codes: "    6九/十.\"[.[+.&6.;'",
    descriptions: "六1ğ]三9(六'",
  },
  {
    codes: "  ^|>一.}^.八*.0_.22",
    descriptions: "*<ğ6四七1>,",
  },
  {
    codes: ")5.?>",
    descriptions: "<二2 七41?|,]{], ]}[3\\0 6_*)七十,, @ı十二9=十七 0>=^ 九76ı =ı6@一四六@ 七一ş",
  },
  {
    codes: "  ^240.(_",
    descriptions: "四'# +/*^&1317, {二十?<^ 六%|十%7<, *ı0+\"一74 $\"\"$ |%7ı 5ı&\\一'=五 9{ş",
  },
  {
    codes: "      ,+)+.二二.三#.=:.,3",
    descriptions: "^^52七>}ş ([ .'二.)< 6七& 22)五{*1二ış",
  },
  {
    codes: "      }七+=.七..(..\":.*$",
    descriptions: "^[ğ9|5十十?",
  },
  {
    codes: "    七*%#.一四.四}.5(.\"四",
    descriptions: "@七1八9^ ;9%}%&7",
  },
  {
    codes: "      ){(0.八七.六&.>六.四@",
    descriptions: "}:?#>#3ş *{ 七六?15| %十( ),'0+七]#ış",
  },
  {
    codes: "      3.$^.>五.7*.}6.26",
    descriptions: ".=ğ>;+@四<",
  },
  {
    codes: "    三^,_.'$.三一.,4.0.",
    descriptions: "<_ğ7.@47?",
  },
  {
    codes: "  +8十>.八=.1,.:+.];",
    descriptions: "Ç11ı _ 二1. ;_% &4 [+五8 1十+ 三ü<二二一+@;{",
  },
  {
    codes: "  >四2\".四|",
    descriptions: ".十ğ_8(/|:",
  },
  {
    codes: "      =7[十.\"@.}+.0一.2&",
    descriptions: "1,十0#[ .ö&_六*",
  },
  {
    codes: "      ('+$.<_.&?.五7.六9",
    descriptions: "{(##.) <ş7[",
  },
  {
    codes: "    四49三.91.$4.一@.0四",
    descriptions: "}+ğ?/二#79",
  },
  {
    codes: "\"八.@$",
    descriptions: "三'6 .#8四47( ([^} =ü/ü _{,>/) @_ ])@&<六\"四2 'ş%6 (八0'八}, 05三0>, }@4@0=(ş",
  },
  {
    codes: "    ,1<8.;*.('.$).3(",
    descriptions: "63^ |0.%二87>#六] 四?:]20ğ# ^四 (\\.@一 {一ç?<二\"; >ı;十ı>^ış .三>九四6+七",
  },
  {
    codes: "    五九=$.7|.[?.][.十'",
    descriptions: "<?|?4六9九",
  },
  {
    codes: "    _>3|.|八.5六.69.]6",
    descriptions: "#六ğ7( ?4'%\"三@1, ş一$/\"..%",
  },
  {
    codes: "    5五67.+<.六六.\\&.四四",
    descriptions: "}@:;九+九 1十%十5^ {2ğ三:八.ış |<@]+{] 一'29>三[]*ı",
  },
  {
    codes: "    ;'4三.三].1<.,6.8九",
    descriptions: ";{(,\\]@5 二一69)三 ]=ğ0[_\"ış |一}十'32 二十6&一5二2<ı",
  },
  {
    codes: "    八.<三.99.9[.五五.)?",
    descriptions: "}_ğ7|3八一6",
  },
  {
    codes: "    {79(.,_.三4./9.,3",
    descriptions: "+二2八%八)(#>+ :[_<1ı 三八<@五八 一.%&一12六",
  },
  {
    codes: "    +{<四._1.*+.{}.|:",
    descriptions: "@[5@6二\"五六_4 ^六{>6ı }$ğ四$ #8二=/(&*",
  },
  {
    codes: "    ==40.三\\.二0.02.十一",
    descriptions: "İ八,{\\?1+]8< *<])|ı |9五五70 ,@4},八\"&, *ü0 ;六3一|6九@} (五>0:=}>.[ış",
  },
  {
    codes: "    '八十1.,9.#&.3八.(.",
    descriptions: "İ{?九一八;4;|} /)七*一ı :一@&:8 ;(@?九@[9, 6ü8 六:6@4*八;9 (#}2?'^:ış",
  },
  {
    codes: "    _(:8.六7.\\}.#\\..5",
    descriptions: "&^.七ş/^ğ{ )+ ;''[七 &2ç+%九., )çı二 0|?{9$ .;121+9\"",
  },
  {
    codes: "    %.*三.\\|.+*.$[.七_",
    descriptions: "+#六#ş[7ğ= /一 [6'6{ 五&ç%0 }çı$ ._;七\\_ 十}.0*|六:",
  },
  {
    codes: "    十九?|.>@",
    descriptions: "@=ğ;>2\\[#",
  },
  {
    codes: "      }]99.8六.五{.3二.#0",
    descriptions: "?+1^ş?) +^?#ş*]1 \"< ,ğ28七0 #/*\\ş+#]",
  },
  {
    codes: "      八]**.^8.**.\"十.66",
    descriptions: "_五ğ八95[二^",
  },
  {
    codes: "    七19/.6十.5[.二/.15",
    descriptions: "1\\三 四ü[ü",
  },
  {
    codes: "    七,4$.?9.%+.(十.2*",
    descriptions: "{8ğ(| 0|6,)ı 1_(_}$十,",
  },
  {
    codes: "    ']八6.}=.5$.九#.{五",
    descriptions: "十[ğ</ ?çı7 ><3_&\"[八",
  },
  {
    codes: "  \"2\\五.8?",
    descriptions: "_:7 (ü]ü @8 _四= _ü4ü*六三: .ş'%",
  },
  {
    codes: "    1$^/.一^.08.\"\"._|",
    descriptions: "^#/0, ş九.<: |{ ?八7|+?| >ş^7",
  },
  {
    codes: "    26[).|7.@\".9四.3>",
    descriptions: ">9ğ}#'9#1",
  },
  {
    codes: "  /2\\[.50",
    descriptions: ",=ğ?&;??3",
  },
  {
    codes: "    |5一$.九0.#|.),._七",
    descriptions: "三五0.3九六\\ '3四|,#ş七八 ($.四四%%二=",
  },
  {
    codes: "    ?/+\\.\"6.十=.98.73",
    descriptions: "<七{ :一&_(>?]% <{ç+",
  },
  {
    codes: "    7八){.1八.九#.95.7=",
    descriptions: "=/ğ;#\"4二六",
  },
  {
    codes: "+=九$.<&",
    descriptions: "二'}={4  九\\ğ\"0 三ş7六",
  },
  {
    codes: "  '\"<0./&.1+./@.$十",
    descriptions: "^$0ı 6^二.#}二  11/&3七9,[ ü>:三3#五22 :九31三一 四五7ü$5十( ;一",
  },
  {
    codes: "    一(}十.七三.7}.|二./一",
    descriptions: "\\十|&{\"八=五八六ış",
  },
  {
    codes: "    5^{].一2.:四..;.+七",
    descriptions: ")一八\"=一@四.ış",
  },
  {
    codes: "    ?|\"7.,>.十..2十..<",
    descriptions: "1}.>7七(三ş 0])'九% 10五八 #九ğ二6 9:}/八&'6ş 8{{5}0/3 1&]8#.十",
  },
  {
    codes: "      /?三十.@?.一4.&(.三0 ",
    descriptions: "_ 8七 ,@; *;十 &二'}6* _%八7ı六5^'' ı>ı8+ ]八3一#ş74 三|一七<7ı六ı ,&:9 六/ 二/>?4七'7",
  },
  {
    codes: "    ]4(:.3\".@}.二九.;6 ",
    descriptions: "{八ğ18%<\"九",
  },
  {
    codes: "六';^.八2",
    descriptions: "]九ç5#'+ ş_>,= 2四)一七=二;八ı, |#94一;^ 0@ ü)#ü+@一)(@8 ((四)ı#八#1|}{9",
  },
  {
    codes: "    九六2$.{$.<三.(:.&1",
    descriptions: "(ı+ (四ç^:%8+<. ?五9, 八ü? 八| ;ı一 \\\"ç@}^一'$^",
  },
  {
    codes: "    九2'].};.十一.45.0/",
    descriptions: "三>ğ.?四^5一",
  },
  {
    codes: "    :九八@.#6.8九.'九.^$",
    descriptions: "<ı@ &:ç67{\\1[\\ 三1(. 6ü@ \"[ 七ı% <.ç<=^?九,十",
  },
  {
    codes: "    <^'\".二:.'八.5十.+%",
    descriptions: "#三ğ'九一$,3",
  },
  {
    codes: "85五三.一0",
    descriptions: "[九六 五十\\} 八4五[8=七} ş$=@8&)8} ./%:?ş/2^一+^ 八&58一八&1& $4=ı9): \"[一|",
  },
  {
    codes: "  {\\八九.%0./8.]4.四?",
    descriptions: "_}';;8三一 ;5._*七十333+ |5四)^(<",
  },
  {
    codes: "  /&<#.5|.+六..'.2.",
    descriptions: "$4ğ'6);'6",
  },
  {
    codes: "五1.五七",
    descriptions: "",
  },
  {
    codes: "六[6{.[六.[二.,3.*十",
    descriptions: "二^\\ 六)6十 |^{,,=八[ ş七^(9三6]54 +五三一十ş{(\\?(@%58 :$%' ö[ü五十/三五 九$2_/\"|49",
  },
  {
    codes: "三\".十八",
    descriptions: "*484&四, /'ç@ ?八6十 五'ğ2四 .@?/7@九[ :(;=0[ş%[ \"三七>'四七@%#\\ %,四ı.五ış 0七8九",
  },
  {
    codes: "  _1*一.&?.8\".\\,.)+",
    descriptions: ")@.\\十 >]/9;^|:十^8) _(三 }9[\\ ş_[五4 3:%1\\(三1,ı 三七 )@54>&}|5八七_ ;十?ı;6ış",
  },
  {
    codes: "    一4_>.(2.六9.三/.1,",
    descriptions: "6;3{>0四^{0 6<#5/1, 1{# 七/ '|十0七五88/6",
  },
  {
    codes: "        ?4七:.一二.{六.9].05",
    descriptions: "Ö1ü|<üş )<?/?+四(\"9+ (,[(/$十七%三十ş $五九1 |_ç{\"\\<'四ş)",
  },
  {
    codes: "        ,@[<.九三.一4.%+.三4",
    descriptions: "|%ğ(*一?}]",
  },
  {
    codes: "          七#九+.<(.?5.&六.}?",
    descriptions: "İ)/十五 4)çı3;|7 \"9|^十_九",
  },
  {
    codes: "          2.^,.;>.+\".4八.$%",
    descriptions: "八|ğ二\"\"&三&",
  },
  {
    codes: "        七#09.3九.}[.@4.5{",
    descriptions: "]}ğ(四9$$二",
  },
  {
    codes: "*四.五[",
    descriptions: "五二ğ#\" ^*ş}ı,十+九 (#&3}|#四>九ış '&]? 91三一\\ı2[ış 42八$| ,;=四5ı%)",
  },
  {
    codes: "  5:二:.}$",
    descriptions: "1+2.四0六^ 八二ş'ı\"8八/",
  },
  {
    codes: "      '6九八.9>.<九.九5.@4",
    descriptions: "@).[7]7 {7ş#ı2$九4ı",
  },
  {
    codes: "      \"十[[.四5.4=.2$.三]",
    descriptions: ";,ğ%{八五八^",
  },
  {
    codes: "      [@七7.=七.=,.8=.七%",
    descriptions: "=二三|一一九十 五+1'<:& #(ş7ı*3七[ı",
  },
  {
    codes: "      四_*].9十.九].18.一^",
    descriptions: "\\\"ğ三9%{二5",
  },
  {
    codes: "    96一$.;四.&|.;五.)4",
    descriptions: "{1\"ç7四51+ 6:4十 =五三=4二/0,;",
  },
  {
    codes: "    <六三}.:}",
    descriptions: "':ğ十) {一^_._+<*五八 十四|/^;9",
  },
  {
    codes: "      {七:一.+#.)三.25.六]",
    descriptions: "]{.{( %四76#三<)7{]四 六+% ,二8[ ş9一#* 2五六55四.\\;ı [] 1八#/>.:?0六;三 |+*ı|5ış",
  },
  {
    codes: "      3*\\九.|>.8*.三+.十^",
    descriptions: "=1ğ|九@(三=",
  },
  {
    codes: "八四48.3四",
    descriptions: "|三ş]ı7:;\\6 5十1九{8 2ç ş>&0|=一_, \"*]九4+?四, 4ı5ı2三8@, .一[九,;0]二九3 5*",
  },
  {
    codes: "  /;&5.十3.7?.,?..十",
    descriptions: "İç ş]五三一九#<",
  },
  {
    codes: "  :三*7.%二.*0.四).七;",
    descriptions: ";三ğ)\\@%@4",
  },
  {
    codes: "5七.\\#",
    descriptions: "İş]&\"三十;^ş (/2ş/<",
  },
  {
    codes: "  9十六[.6&.&'.#:.9%",
    descriptions: "&五十七八; *+<二=5ş 6二(ş*$",
  },
  {
    codes: "      6三十&.7%.}3.80.6二",
    descriptions: "&ğı三/ı> 十五\\|.'六7// % |.'^ \"十_[ 八+4= 6,|四2 四ü_üş 八ç;九\\:, )#_*3]",
  },
  {
    codes: "      9<)六.\\(.^'.四四.7_",
    descriptions: "9|ğ7#九<九>",
  },
  {
    codes: "    ]3>%.$4",
    descriptions: "}六ğ}8=)三^",
  },
  {
    codes: "      })5:.0=.*'.@八.七三",
    descriptions: "&ğı$&ı= \"#三十6?=七7& % 四,九| {&,: _{1七 0%@1/ {ü*üş @ç(十一(, %/\\一42",
  },
  {
    codes: "      十2$/.'#.0[.一'.八}",
    descriptions: ".[ğ5|#:十{",
  },
  {
    codes: "<四六<.:=..=.2\\.八;",
    descriptions: "+(十ş7七 7ö'ü=9ü (6 %&五0#五十/ı",
  },
  {
    codes: "'){.]#",
    descriptions: "",
  },
  {
    codes: "%].)@",
    descriptions: "&二|ş+五 $四八=33, %)6$@}四2, 7=+九{.一\\{ 三' ş>0六*7九#; \\<*ş4二 ;2<二^\"ı 5.",
  },
  {
    codes: "    0,&三.7?.&7.二四.5(",
    descriptions: "^6*62/, 7二**3*28> <> ş(3,.:$: ('九三[{1$66{ \\1<_ç ^.一ı8>ı2$八+ı 五,一",
  },
  {
    codes: "    %?.)._:..}.9}.\"4",
    descriptions: "]5ğ&8{\"一六",
  },
  {
    codes: "  _2@>.+[.'@.0一.五|",
    descriptions: ">);ş四4 9九八;&$ı )& .*'六 二|.六1$",
  },
  {
    codes: "二8./三",
    descriptions: "",
  },
  {
    codes: "二三_一.)+",
    descriptions: "+三一ş'一[5^ :_ğ?* 9ş七3",
  },
  {
    codes: "  ,\"]五.二].,2.'^.63",
    descriptions: ".;四四{}一,*9 /1^03*5%$, ?:şı9. .%%} *[1)二^?\\ı 6ı三).ı%@_ 六7_:'0七;9二十@",
  },
  {
    codes: "  十'}=.[^.#(.七\".{|",
    descriptions: "<四ğ2)^0(十",
  },
  {
    codes: "=%.6\\",
    descriptions: "%@四一1ö$::7 (<#.+\\ @;[\":(}五#>6 #%[;4 六]=87ö:8|1 0^六7ç)",
  },
  {
    codes: "  六{/}.=+.六2.>$..四",
    descriptions: "=7; :@6\\ı |$)2二ö<{八4",
  },
  {
    codes: "    &六八..3]",
    descriptions: ".八四四#> \"ı_ış[ı(%二 \\\"{ş.七_$八^ 七ç;,= $,24七$ı 八)七十]9五= 4;7;[/. $九<2#9(",
  },
  {
    codes: "      \"0五:.85.2十.)5.5.",
    descriptions: ">五2@ 4二8九4\\>",
  },
  {
    codes: "      ]七87.五3.]八.八四.五*",
    descriptions: "七八(4{_ı十\"ış 0{|5=三>",
  },
  {
    codes: "    >二八(.?#",
    descriptions: "#>5 2ı{ış2ı一八@ \"/6ş二|六八{一 %ç<;0 .四九六|1ı \".五(8.七九 [3:}^八$",
  },
  {
    codes: "      %六?&.4六.四|.{十.十<",
    descriptions: "#%6; %;[*{2=",
  },
  {
    codes: "      .{十%.9\\.7/.4\".5]",
    descriptions: "一三'\"&;ı七1ış <四=0]四四",
  },
  {
    codes: "    >$,}.6二",
    descriptions: "$,五 7ı&ı七:ı^ 五%#ş\">九_7[ &ç6{+ 7七四^1三ı #7'五?<六/ 5'五|.23 }=? 57",
  },
  {
    codes: "      :/^二.8:.{五.[%.@<",
    descriptions: "^六[\" ={$&五^$",
  },
  {
    codes: "      ^十[(.8,.6'.[2.%&",
    descriptions: "?二9\"六@ı一]ış )#三=9五,",
  },
  {
    codes: "    ^一.十.#,",
    descriptions: ".>]<$] }'_7&]3$ 3@四$\\(6_3} >[73五_五五 六$三四)06",
  },
  {
    codes: "      4$_@.2&.+;.1七.8{",
    descriptions: "&八#] +3$三六5'",
  },
  {
    codes: "      二*2?.'(.{五.5?.3#",
    descriptions: "(+_%7$ı=三ış 2@7;_>三",
  },
  {
    codes: "    _:六?.三#.'2.<|.(}",
    descriptions: "\"}ğ三三.\\[_",
  },
  {
    codes: "  0|_<.+?.'].十&.'/",
    descriptions: "'\\]三>五5 0_十'@ö\"一,{",
  },
  {
    codes: "    17一7.十八",
    descriptions: ")#/[0 @ü三ü :三 五0.ı '/ç^4]|2/十(",
  },
  {
    codes: "      <\\&1.&(.>{.@_.三+",
    descriptions: "=>\\8]*6一%\" 9七*>$ 三1九^\"ö111\\ )2 :^(^>'ı<ı'三二 *\\}81一ı(]四 4[=:&6(;&五 七一五.9ö&五{|",
  },
  {
    codes: "      [九2>.\\$.6>.(|.77",
    descriptions: "]/ğ_>//5.",
  },
  {
    codes: "    |9%六.?[",
    descriptions: ")一五\\5 _ü九ü 2\" +=.ı 3/ç\"9 %70/( 八九 /|.ı 5'ç%?^<一5九;",
  },
  {
    codes: "      ,#二*.一3.11.01.\\<",
    descriptions: ".}/:二七78八3 二%>=: 3十31?ö四79七 ,$ 三$七五四;ı5ı五三[ $3\"一九\\ı9*? ({五450&10{ +(六>八ö)六]^",
  },
  {
    codes: "      :,六9.4#.十五.|<.4@",
    descriptions: ";(ğ@#.'\"=",
  },
  {
    codes: "    }一>四.>^",
    descriptions: "5%@(五 ;ü九ü 4十 }/.ı (六ç\"\" 二七(8> ]\\ 6:.ı 八<ç}6.\"9三0#",
  },
  {
    codes: "      |\".^.60.69.:\\.$_",
    descriptions: "_1/=4$:=?= ^=>\\8 八十9$7ö九1^一 $> )9{一((ı六ı/[2 69|七八$ı]#$ >九}/二^&三'( 4七2.三ö)6八一",
  },
  {
    codes: "      一*八一.3].[四.十'.&8",
    descriptions: "5@ğ<.八/=4",
  },
  {
    codes: "    ^&=6.53",
    descriptions: "二^1_+ \"ü*ü }9 }一.ı =&ç|九 四([三& 9六0 ;十.ı ??ç_\"#一<&}2",
  },
  {
    codes: "      ?八二%.=二.六五..3.?五",
    descriptions: "]}@?六\"*一3四 三2_;八 &:[0^ö\\>/% {1 &5七\",四ı五ı'@, #=.)八&ı{>7 8六,[}?,五=. &3七}二ö0*>^",
  },
  {
    codes: "      +*[>.]6.=%.]二.'一",
    descriptions: "7六ğ六五$&%)",
  },
  {
    codes: "    十十九>.'/",
    descriptions: "]?2七8 >ü1ü 十#五 35.ı 四#ç&十{7*",
  },
  {
    codes: "      九十'#._;.7}..4.[四",
    descriptions: "99]5三'六'(二 {4*^> ^\"六,{ö1^5< 三6 2140五/ı七ı一;; _五4/4'ı@三六 257:#二5'二一 )32五1ö_六}$",
  },
  {
    codes: "      九二3'./,.五六.'#.?%",
    descriptions: "三:ğ@五(;%'",
  },
  {
    codes: "'八.一]",
    descriptions: "\\, 七七2) [['三 (2五六5 五0ş) 五)şı七;三十 /{:9:八 (六ü\\ü]ü &*6一十) ,@(六[?八",
  },
  {
    codes: "  1_一四.8#",
    descriptions: "10五\"0^ [ı7ış_ı二.\\ :\\6ş$48[#+ 2ç+.{ [^\\七0*ı \\:6(])/9 四9[|+}1 +@.@&]>",
  },
  {
    codes: " ",
    descriptions: " - - - 十:\"\\ ;%\"_.%0",
  },
  {
    codes: "        ;0十].#;.7?.*=.6九",
    descriptions: "1)^0ü六",
  },
  {
    codes: "        8%二%.(,.3六.1六.'}",
    descriptions: "2(^;}ü*",
  },
  {
    codes: "        :'&>..].十_.%二.5二",
    descriptions: "八/&五六ü十",
  },
  {
    codes: " ",
    descriptions: " - - - }^9|9+ı12ış 38058;+",
  },
  {
    codes: "        '70'.,:.五;.\\七.]三",
    descriptions: "?六}/ü<",
  },
  {
    codes: "        四?/十.@..<三.一1.>=",
    descriptions: "@九\"4<ü二",
  },
  {
    codes: "        ,)|一.二_.二%.:\\.?,",
    descriptions: "(7'2+ü|",
  },
  {
    codes: " ",
    descriptions: " - - 061.([;/ 四)}十7 ..|[ ^,@.ü 一_ç{@五&0'$;",
  },
  {
    codes: " ",
    descriptions: " - - - 6^@0 '9四+\"[]",
  },
  {
    codes: "        )8一7.::.*$.六*.(2",
    descriptions: "=,}/ü[",
  },
  {
    codes: "        @{5).7?.7@.6一.九6",
    descriptions: "一十(+九ü,",
  },
  {
    codes: "        #七:二.{9.&7.{<.6{",
    descriptions: "7)>九}ü十",
  },
  {
    codes: " ",
    descriptions: " - - - (_>651ı5=ış 四[^['>)",
  },
  {
    codes: "        {*,8.18.^6.六;.八9",
    descriptions: "(,五六ü2",
  },
  {
    codes: "        .0四八.三*.一\".7五.一2",
    descriptions: "3四@八九ü/",
  },
  {
    codes: "        ]5九六.$五.九<.^三.%;",
    descriptions: "@1八({ü一",
  },
  {
    codes: "  1;%)..5",
    descriptions: "|[[ }ı五ış5ı&0% 1四)ş6二四|01 >ç_[2 )\"五<九4ı @;^.#/八} 340|7\\:",
  },
  {
    codes: "    5_5;.8*.五?.十).二'",
    descriptions: "|+\"九\\#86 8_一]3 六?|\" 7二}.ü \\三ç447=:",
  },
  {
    codes: "    ,六:十./2.|>.&+.一七",
    descriptions: "4'0@五八@0 2:7\"0 ]=十二 一二\\.ü 三%ç:_7$^?36",
  },
  {
    codes: "  五[七=._十",
    descriptions: "?=: 1ı九ı八七ı8 {=八ş2二四五7< 七ç])$ (205\\,ı %五4=;\\{六 1)7:(五四 六7* 1;",
  },
  {
    codes: "    ^8九%.5$.六8.三4.三5",
    descriptions: "九8$|(0<1 \",=)五 八%?/ )65.ü 四五ç&=/:&",
  },
  {
    codes: "    1九(三.|(.8三.@1.\\\"",
    descriptions: "七](#%>四\" ,)五\"二 1/@= 三一七.ü 9十ç0+3(;*('",
  },
  {
    codes: "  一2,\\.'2.9'.%@.;7",
    descriptions: "\"/九五七八 %(三((0一一 &@3十6=;2<十 :九._九)01 :,,六.四&",
  },
  {
    codes: "  9八3七.4=",
    descriptions: "七&ğ五6一.五六",
  },
  {
    codes: " ",
    descriptions: " - - - - <^:] |七04=@3",
  },
  {
    codes: "          7537.3%.,|..].=六",
    descriptions: "<)_5ü=",
  },
  {
    codes: "          )\\<#.二(.#..七#.<[",
    descriptions: ")>{九七ü\\",
  },
  {
    codes: "          1|'5.?七.30.=三./)",
    descriptions: ".'2:,ü(",
  },
  {
    codes: " ",
    descriptions: " - - - - 8:;|01ı2/ış \\5{五6^&",
  },
  {
    codes: "          七,]六.+2.'六.]#.]'",
    descriptions: "*%*1ü0",
  },
  {
    codes: "          76(|.9+.六).*七.94",
    descriptions: "6,)3四ü0",
  },
  {
    codes: "          ,3一;..9.十0.五五.=[",
    descriptions: ",?94\"ü6",
  },
  {
    codes: " ",
    descriptions: " - - - - 五:/@ 一九1@=十&",
  },
  {
    codes: "          七:>3.9|.七\".+十.^五",
    descriptions: "7/81ü_",
  },
  {
    codes: "          6一0=.}8.;2.六?.,|",
    descriptions: "二09\"十ü四",
  },
  {
    codes: "          十:]3.+(.四{.1].^\"",
    descriptions: "六#5$1ü[",
  },
  {
    codes: " ",
    descriptions: " - - - - 二0>(.'ı;)ış ^}:*/2\"",
  },
  {
    codes: "          #\\4_.十<.%^.六{.{%",
    descriptions: ")}十三ü=",
  },
  {
    codes: "          &8二#.#2.><.#八.4二",
    descriptions: "@二&九}ü六",
  },
  {
    codes: "          0六#7.$3.0五.)+.{1",
    descriptions: "\"?十=}ü}",
  },
  {
    codes: "    .7,/.[十.==.,\".9八",
    descriptions: "3}ğ++ 7|)]^]<*</ =ü[一({八\\ 7'5.,.,",
  },
  {
    codes: "}1.0二",
    descriptions: "\"{[|五 0[七8\\六十十,%'7 ]} :;@] &0+三$/8[一3 五&^5四 %=şı%0: ü十6%3 &八_9",
  },
  {
    codes: "    一$'#.五}.六3.五'.1四",
    descriptions: "Ö:}2=]4九+ 9=6 ü[27*7一\\ 8.#二十一> \\一二9# {ç:四 ^:%)九十 十(;七6<ş &ı^ış.ı6/}",
  },
  {
    codes: "    %&69.&八.58.)<.}%",
    descriptions: "+?ğ};+九+一",
  },
  {
    codes: "    0八(;.>+",
    descriptions: "九87$/八[[ 8十<+_ >@03 五9}'ü 5十ç^八[)六(@#",
  },
  {
    codes: "        &/)六.1{.:;.八}.八&",
    descriptions: "一90%% 七9九'9+_7&]79",
  },
  {
    codes: " ",
    descriptions: " - - - - .)ğ九]%_.& ",
  },
  {
    codes: "          ',{_.>8./$.@'.^7",
    descriptions: "5六八一三[>/^4十",
  },
  {
    codes: "          >=%\\.6#.33.#*.,(",
    descriptions: "四[ğ0\"*2七|",
  },
  {
    codes: "        9[六*.四{.8十.六>.1二",
    descriptions: "19三${ _1'.*:8/六3_6",
  },
  {
    codes: " ",
    descriptions: " - - - - _三ğ二八四(\", ",
  },
  {
    codes: "          $3十^.九七.1/.[{.?9",
    descriptions: "\"}5\"|{$]5)6",
  },
  {
    codes: "          八?二一.0].%\\.1'.一&",
    descriptions: "十[ğ七九六|&,",
  },
  {
    codes: "    <^七}.七_",
    descriptions: "2$6五=118 十>,7( ;9)< ?{$'ü 八_ç{# >\\${? 1861 八'/.ü #7ç#十,五七?五?",
  },
  {
    codes: "        (:9(._>.{=.8<.&5",
    descriptions: ">|8|9 <,$五>]=\\,五\"1",
  },
  {
    codes: "          &%[).@七.*+.^八.一八",
    descriptions: "一95]十&_8:;}",
  },
  {
    codes: "          四/*\\.2;.^<.(8.=五",
    descriptions: "4*$,75* 二6/[^\"六二7{",
  },
  {
    codes: "          :+4_.十7.&\\.七2.2\\",
    descriptions: "\"\\ğ0四:*5|",
  },
  {
    codes: "        \\八\\,.4二.五#..一.|八",
    descriptions: "}24|| 六9二')>^五:_|八",
  },
  {
    codes: " ",
    descriptions: " - - - - 六*ğ<#一:?二 ",
  },
  {
    codes: "          八+九].5\\.<|.十,.^3",
    descriptions: "2六;]\"四3%=2{",
  },
  {
    codes: "          九三.}.%].80.)%.:,",
    descriptions: "?\\3六})& ;一<@57<.;/",
  },
  {
    codes: "          88\"'.\\;.?_.*六.七}",
    descriptions: "_}ğ_\"*2,(",
  },
  {
    codes: "    3]%2.<3",
    descriptions: "$六&343\"6 ^3.?( )#$5 *&二.ü 九[ç}8 _三八*9 ]23[ \"3\".ü 3?ç1八四%一[1<",
  },
  {
    codes: "        #\\08.%五.七}.{五.[9",
    descriptions: "7\\8九七四七 &8]|#+四]七,",
  },
  {
    codes: " ",
    descriptions: " - - - - - 7.[*\" >>(?>[%+<&,\\",
  },
  {
    codes: "            十七10.95.十五.}=.一/",
    descriptions: "六,:?0|{五 }<\".十 ?={[ 8;&' ü &'ç五八 三,三\"/ /:), /46. ü 十'ç0*+三五6九[",
  },
  {
    codes: "            ')?_.;八.$'.4四.@2",
    descriptions: "1七九1五四.五 _七$[3 一62# )3{' ü (8ç&$ ]},&6 /二)1 九九七' ü +五ç十^$十三{(3",
  },
  {
    codes: "            0九5?.三5.\\0.$六.<9",
    descriptions: "+&6'93\\( 0;七九九 2;三\\ :0*' ü 一4ç>:/六(",
  },
  {
    codes: " ",
    descriptions: " - - - - - 七|ğ8+62}2 ",
  },
  {
    codes: "            }\"$3.+}.一(.4/.:九",
    descriptions: "八&5九七>九{155",
  },
  {
    codes: "            ,(二\".?+.七%.13.]|",
    descriptions: "^^ğ4|0=.;",
  },
  {
    codes: "          十八^1.^[.+<.三十.:,",
    descriptions: "3;_五&{7# 9/(#* =\\3{ 3>>. ü @9ç=\\ ^\\五九/ =['& (+9. ü <#ç^?2.三57六",
  },
  {
    codes: "          4];?.,9.1%.=9.]十",
    descriptions: "49@>#六/} ,?<{七 七|7; +四2. ü )二ç1* ][5(9 2>&\\ .1.. ü +/ç=|}65^2@",
  },
  {
    codes: "          五\\^'.(*._).九四.:九",
    descriptions: "\"#$)}\"二( ^_>\"3 ),5} |1@. ü 5?ç*]=$5",
  },
  {
    codes: "          ]9三?./:.:1.0_.8三",
    descriptions: ",,二.一']18:八",
  },
  {
    codes: "          +%]].7].15.三}.[$",
    descriptions: "?二ğ三#<683",
  },
  {
    codes: "    #@|/.十六",
    descriptions: "7'六;\\\"八@ 9四六一8 21%2 四]>.ü ;三ç;八1八八",
  },
  {
    codes: "        九0)@.八>.:^.5}.二一",
    descriptions: "3二^=? |三|/+;}{六一#五",
  },
  {
    codes: " ",
    descriptions: " - - - - 八|ğ九三,5@九 ",
  },
  {
    codes: "          2814.7}.五0.>#.*|",
    descriptions: "<2''\",|^)九(",
  },
  {
    codes: "          5{三\\.一九.九五.*8.?=",
    descriptions: "\\十ğ1七#63^",
  },
  {
    codes: "        ]]+^.>5.*#.37.\";",
    descriptions: "?:十$九 ^六6[5;'30六<3",
  },
  {
    codes: " ",
    descriptions: " - - - - 68ğ\\]七5:/ ",
  },
  {
    codes: "          '996.四^.%,.%9.}@",
    descriptions: "六[5:\\>八}<^三",
  },
  {
    codes: "          <1=二.^;.:2.六*.+三",
    descriptions: "0{ğ:}}&6七",
  },
  {
    codes: "    6702.五&",
    descriptions: "46\\?(596 .4二|@ *3/+ '九).ü 9\\ç五三十6/?<5",
  },
  {
    codes: "        九九二五.6五.}_.八一..=",
    descriptions: "(一_%2 75%83>\"&7&3>",
  },
  {
    codes: " ",
    descriptions: " - - - - :七ğ2[@9#} ",
  },
  {
    codes: "          6|39._一.二).#八.70",
    descriptions: "|0($四_九\\#*一",
  },
  {
    codes: "          七4=..<5.35.5'.({",
    descriptions: "八2ğ,*|?五^",
  },
  {
    codes: "        $6五0.|_.(_.五_.]+",
    descriptions: "4\"2.2 [四9^8)@6=*一9",
  },
  {
    codes: " ",
    descriptions: " - - - - ;六ğ一十&六三/ ",
  },
  {
    codes: "          #@.一..\".*五./7.四+",
    descriptions: "三3_}八#&/8.#",
  },
  {
    codes: "          4=\\七..,.五八.五(._{",
    descriptions: "?+ğ[四?$>5",
  },
  {
    codes: "    [0,1.<三",
    descriptions: "+]8@8<?, 七2%&/ ,|,九 '*四'ü |>ç九| ^,_二9 \\4五| 八(/'ü 2七ç六_@\"四7]3",
  },
  {
    codes: "        九3%(.=1.\"九.:一.}.",
    descriptions: "64$+,}九 %八=&>/9/[)",
  },
  {
    codes: " ",
    descriptions: " - - - - - 九\">[4 7=\"&>\\二],4&& ",
  },
  {
    codes: "            <0九)._[.,:.&].2四",
    descriptions: "6二\\*>4\\_ <<一三< >&六? ={,' ü %/ç)7 &3一?五 24九4 @六_' ü 三#ç,七+%_七@4",
  },
  {
    codes: "            ;)8:.#7.十6.7@.$\"",
    descriptions: "/.'1九[九{ /;3六> 五七8四 6\\^' ü {.ç[; ?1>}2 \\二?4 八^,' ü ;=ç^八八(八三3五",
  },
  {
    codes: "            二二^;.#十.4+.8%.84",
    descriptions: "&_3<七6二{ @:&6. 1<)} 十2二' ü =九ç)<?(&",
  },
  {
    codes: " ",
    descriptions: " - - - - - 0$ğ338^%七 ",
  },
  {
    codes: "            *'>;.二1.二[.9>.'(",
    descriptions: "|02=0\\]0_|1",
  },
  {
    codes: "            8045.\"^.$+.三五.六四",
    descriptions: "四\"ğ&11十'=",
  },
  {
    codes: " ",
    descriptions: " - - - - $一二?@ ;$七五=|7三\\+九4 ",
  },
  {
    codes: "          @%七4.>九.$&.&*.;%",
    descriptions: "八)%(四>七4 %[8九3 19{_ 七>}. ü 1二ç$; 71五4( 632; 九^\". ü **ç六(#;;十9七",
  },
  {
    codes: "          二2八..=1.&<.十%.*四",
    descriptions: "/@八:[\":一 三一7.四 442_ @六1' ü $.ç一* \"六7二_ $+)[ 六五五' ü 一[ç八&:?3<;*",
  },
  {
    codes: "          ,?7\".十&.^[.一=.*'",
    descriptions: "1^#六/4/; [一#7] &)*八 一五?' ü ;一ç0<\\6;",
  },
  {
    codes: " ",
    descriptions: " - - - - [五ğ0:3@七? ",
  },
  {
    codes: "          四/\"1.@@.*七.五(.}^",
    descriptions: ">四^十8\\)6五_8",
  },
  {
    codes: "          )],9.#*.[[.16.九|",
    descriptions: "(?ğ37三^七3",
  },
  {
    codes: "    4'八^.7$",
    descriptions: "<七\"九(#;3 7二,34 >?九4 0*八'ü .\"ç;^九94",
  },
  {
    codes: "        一35/.<+.1?.五[.+%",
    descriptions: "%/三)31% 33五?\\>6<#+",
  },
  {
    codes: " ",
    descriptions: " - - - - - |九4]_ .':;:|=三一&18 ",
  },
  {
    codes: "            七630.;3..6.8\\.;}",
    descriptions: "五8)%?&2= +八3=十 #6\"一 ;.\"' ü /%ç三@ *]@+7 {1九, 九四'' ü $,ç六<3\\<<>'",
  },
  {
    codes: "            八2*四.13..*.{9.0}",
    descriptions: "2八?三九;$7 _;2七8 \\3*四 }六+' ü /2ç269?2",
  },
  {
    codes: " ",
    descriptions: " - - - - - (?ğ[|<]@( ",
  },
  {
    codes: "            十570.\\九.69.'<.*八",
    descriptions: ",*&9{二+@\\八]",
  },
  {
    codes: "            *4**.=三.4(.{/.^:",
    descriptions: "三{ğ^9]*;8",
  },
  {
    codes: " ",
    descriptions: " - - - - |)+七; [[三@9\\十.九(17 ",
  },
  {
    codes: "          9八八十.5>.4&.{:./6",
    descriptions: "九\"*705#五 三/六)% +7.? 7/+' ü \\2ç'= *]}八+ 八$*( 九九,' ü ^@ç\\=9[;七五8",
  },
  {
    codes: "          \"9五#.1}.9@.$/.6@",
    descriptions: "42$\"二八$; 5%&}5 *0+十 ,$五' ü [:ç}\";1>",
  },
  {
    codes: " ",
    descriptions: " - - - - 5三ğ@|*#(六 ",
  },
  {
    codes: "          一[^0.4?.三,.四}.]{",
    descriptions: "1'=^三+$'[)\\",
  },
  {
    codes: "          @$'[.六\\.六$.九二.5{",
    descriptions: "{6ğ37五$=一",
  },
  {
    codes: "  \\七六@.,一",
    descriptions: "2/ğ四, <&şı三@%/ ({3> @ı#ı(_ı| _>六ş,$;<3十 |ç$'\" #8\\06$ı 七0[六(1\"' 0四781]@ 9:] {/",
  },
  {
    codes: "    \\;;'.^$.24.5).六0",
    descriptions: "(\"\\一 |?;+一一$",
  },
  {
    codes: "    #五1四.4).一].1%.@<",
    descriptions: "@:6^9)ı九,ış %%<&九}<",
  },
  {
    codes: "  5(1..4[.12.<5.#*",
    descriptions: "6_ğ') [(şı十+]] (@五= :ı|ış<ı+:[ .*>ş)4%?+\" 八ç五*五 9__@,六ı )1]6^%*九 ,>:1六二,",
  },
  {
    codes: "  六+{5.=5",
    descriptions: ":六ğ1; :;şı))_九 (?七/ 四ı_ı七4ı^ <|6ş九|\"(2| ]ç四八> 2?)]/>ı 八六8@**,( [#><一,二 @九# }(",
  },
  {
    codes: "    2|七四..<.:|.十8.?三",
    descriptions: "6@,三 9$(\"8'@",
  },
  {
    codes: "    |四6五.?..七{.&].:.",
    descriptions: "三|3,'=ı二七ış ;>@*'28",
  },
  {
    codes: "  七3十一.<十.@[.二'.$:",
    descriptions: "8}ğ84 >七şı4八三& (}}一 2ı[ış8ı6_\" '{八ş/?(##$ |ç二9& 3:3:_[ı 十[+$&*九三 十9)\"8*{",
  },
  {
    codes: "  五{/3.四七",
    descriptions: "69ğ<} .\\şı71?9 (7_)>+0 (+十:]^$, %2.+,,六>&< 五\\'五@%8+ 二1[0六九2)",
  },
  {
    codes: "      %五'{.%..?四.;&.@/",
    descriptions: "0\"九@'二 ?ü五ü [?六四.九;九 9#( 5ç五&\" ?9'七.%ı 五[}9&( ^6%\"_ 442{9^>",
  },
  {
    codes: "      九1九0._\\.1一.十|.6#",
    descriptions: "九)ğ四*1九%一",
  },
  {
    codes: "    |#7(.\\;.@>.<八.&^",
    descriptions: "6(\\一%(ı<#ış ?:九)3'8",
  },
  {
    codes: "  6十8^.)8.=三./十.八九",
    descriptions: "%2ğ\"%]41(",
  },
  {
    codes: "=十.9#",
    descriptions: "<ş四八 28şı97^) |\"\\&{5 <@.>/7* 4?şı>一,.",
  },
  {
    codes: "  >'^+.*八",
    descriptions: "?7/[6&=4 ş一六?十二7, 三ışı836 ]一:'2{ı|)\\2 ü二^(, _7(ı四^ış",
  },
  {
    codes: "      >4'{.93.}三.?3./\"",
    descriptions: ";>八[+&\\九 七六)四: (.', _>]'ü @+ç2五 \"ı5ış]ı/,0 &二二ş六\\六=<^ =ç:四? 五6#|'?ı 3[=?#18#",
  },
  {
    codes: "      \";|,.@+..+.8?.6|",
    descriptions: "/^ğ0]九*6%",
  },
  {
    codes: "    +.9}.|@.:(.0<.2五",
    descriptions: "__ğ\\/八一6三",
  },
  {
    codes: "    2七1七.6(",
    descriptions: "三[ü; .ğı>*ığı + 一\\2? #四ç七12(\\5|}",
  },
  {
    codes: "      ^八一八.<:.七|.[*.九)",
    descriptions: "]ü0_.1 \"2@九1%,,\"# '(85+?&0*一 9[şı:'9\"ı 三ç8? ö2九{ @\",七$] {五4ı五1ış",
  },
  {
    codes: "          +/%八./|.#*.0:.六?",
    descriptions: "74#三 三061>:'",
  },
  {
    codes: "          [七||.<6.(|.[).%#",
    descriptions: "+{^;5;ı'>ış \"@7$7}$",
  },
  {
    codes: "          |<9三.:一.3%.十#.],",
    descriptions: "八2九7 )|*&五94",
  },
  {
    codes: "          _一#>.七+.,*.;[.四九",
    descriptions: "二}5七三0ı八十ış 六>四7五$二",
  },
  {
    codes: "    :]#&.,4",
    descriptions: "四,ü, |ğı&3ı,<ığı 2 .6|% (|ç]1 ,|26? .> 6=4\" 82ç4八五>十六[.",
  },
  {
    codes: "      三四^=.:=.=9.'^.%#",
    descriptions: "三ü6,._ 二\\六^(:_:6七 $%)04@九$六% ]?şı';]=ı ]ç?, ö\"6八 )七七+@& *$六ı七*ış",
  },
  {
    codes: "        九七?0.三5.4<.#6.*4",
    descriptions: "一=*8 &:;?$:2",
  },
  {
    codes: "        *七%7.七$.@/./}.^:",
    descriptions: "/%+二$>ı)|ış .07(2]+",
  },
  {
    codes: "    1,2一./6",
    descriptions: ")五ü_ 'ğı*:ığı ?? 四6七2 6三ç'31}4",
  },
  {
    codes: "      >)]五.:1.}=.]^.五9",
    descriptions: "八ü1=?# >#)]=二&$+= :9九'?;$3@: ]9şı五8七#ı 七ç十二 ö+$十 [[1\\^5 ;]八ı{'ış",
  },
  {
    codes: "        [五61.8}.1八.7(.&;",
    descriptions: "五}<% 8五七,]=\"",
  },
  {
    codes: "        ?081.|:.(%.6{.一:",
    descriptions: "}/4_七六ı=七ış #九;1二])",
  },
  {
    codes: "    _39\".9<",
    descriptions: "9/ü6 ]ğı62ığı { {)五[ \\八ç七(=十^四=5",
  },
  {
    codes: "      #3@_.:十.四\".4/.^@",
    descriptions: "5ü:四(} /}_;@,*70' <\"]%四三&/二9 /0şı*83|ı |ç{^ ö5_九 403八)_ &[五ı七#ış",
  },
  {
    codes: "          $],四.14.三_.\"|.}<",
    descriptions: "0一)三 &一四/九二_",
  },
  {
    codes: "          {[\\=.+>.&/./9._5",
    descriptions: "?3]三3三ı二{ış [|五=$'9",
  },
  {
    codes: "          六|.九.八$.@九.}:.):",
    descriptions: "_3+四 10&|/{^",
  },
  {
    codes: "          <$+8.十7.?;.$+.<)",
    descriptions: "八)<|{5ı4)ış 3\\>/0七7",
  },
  {
    codes: "    ?'8二.5?",
    descriptions: "?4ü_ 2ğı7*ığı 十 >>_< .*ç07:@%",
  },
  {
    codes: "      :%二_.8\".^6.90.<六",
    descriptions: "五ü三4@二 九&__}89=五> *七8,7\\]3十/ #0şı>^?|ı 5ç/四 ö0七, ={17)\" 1]五ı(2ış",
  },
  {
    codes: "        >:#*.)四.]0.1'.?\"",
    descriptions: "一&七七 ||^}九&4",
  },
  {
    codes: "        九三0七./0.'#.:+.\"@",
    descriptions: "}&}二$\"ı70ış 7>+八}$,",
  },
  {
    codes: "    |+\"九.二0",
    descriptions: "9<ü1 0ğı4{ığı 二 +六^六 ./ç#{九三=九{一",
  },
  {
    codes: "      十=三六.>8.:七.;].=%",
    descriptions: "一ü#\\&/ 61^*七二'@+0 9\"(七(二)6', <一şı6十\\(ı 0ç四? ö<|) #9二_|% $+*ı<5ış",
  },
  {
    codes: "          <$#八.;/.\"四.7;.十<",
    descriptions: "3@'九 1+<五)%八",
  },
  {
    codes: "          &*,七.@).>].六2.2三",
    descriptions: "五七)$6二ı#[ış (33*@四9",
  },
  {
    codes: "          '*四+.二<.}[.>二./@",
    descriptions: ">189 3?2=?八'",
  },
  {
    codes: "          &}六3.五{.9=.%5.#二",
    descriptions: "6^995[ı@.ış .'七48#1",
  },
  {
    codes: "    :8九*.]:",
    descriptions: "^五ü, 五ğı&/ığı $ 十|七/ 十_ç6@ [六('& 三$ _/九3 =#ç三:&}7/6;",
  },
  {
    codes: "      2一43.=|.三#.[$.).",
    descriptions: ")ü3|>一 十1@二9八十.$# |'=;=1$/八7 [三şı4@>十ı #ç74 ö九|* 6:3#十3 _;4ı7五ış",
  },
  {
    codes: "        七+0#.8*.4[.(=.:1",
    descriptions: "*(5/ ^2四2一.5",
  },
  {
    codes: "        $%'*.六十.四%.8[.2*",
    descriptions: "\\[>,}$ı\\=ış .(<#^\\@",
  },
  {
    codes: "    六\\1..83",
    descriptions: "2_ü_ *ğı|8ığı +八 %==, >%ç%&六#一",
  },
  {
    codes: "      /四二8.2一.|{.1,._8",
    descriptions: "?ü七一21 .6(_|(7},1 3|@(=/^5七8 0{şı7(:.ı .ç8= ö[9? _六@80^ 三9七ı五\"ış",
  },
  {
    codes: "        ?,0;.三+.<&.0#.#_",
    descriptions: "/(66 6&3^?7,",
  },
  {
    codes: "        3[8#.:?.4:.6%.五2",
    descriptions: "#]23>)ı>5ış =)5?;$七",
  },
  {
    codes: "    /*9..<:",
    descriptions: "1\\ü, $ğı&1ığı ; +]?+ :/ç4<$}]\"5:",
  },
  {
    codes: "      44三<.\":.八2.#..七九",
    descriptions: ".ü]一四四 九=\\3*<*/61 ;)一=七/二,[8 ^{şı{九六]ı 4ç+三 ö3&| 257六\"# ]<$ı$9ış",
  },
  {
    codes: "          七|二^.{>.'+.三6.*%",
    descriptions: "六九6[ #7[#)3)",
  },
  {
    codes: "          _1\\\\.,3.|+.&^.8^",
    descriptions: "六)0%0$ı(^ış 9%*0_'/",
  },
  {
    codes: "          *&%1.{^.'\".;+.9;",
    descriptions: "&}<[ 十=7十三94",
  },
  {
    codes: "          +}七+.]十.二^.<9.96",
    descriptions: "$$六$&<ı9=ış &六七1/]3",
  },
  {
    codes: "    四%十三.,$",
    descriptions: "\\)ü( @ğı:*ığı < 0$二9 ;)ç9)#81",
  },
  {
    codes: "      :五+[.&:.二).|,.[四",
    descriptions: "十ü*/31 ^$%五4?;+7& ]\"[$42.十;< _)şı54八8ı _ç7@ ö@'7 3四]}?* ^,,ı;.ış",
  },
  {
    codes: "        :六四六.十+.23.十#.._",
    descriptions: "^|三{ 五}??2$<",
  },
  {
    codes: "        五'十;.]+.[}.48.八0",
    descriptions: "/二@;[^ı1四ış )一八7九.*",
  },
  {
    codes: "  /九*{.六\".'0.|@.^{",
    descriptions: "七)ğ5九$=&] (7#&+=. 二二]9=_五$ {六^[\"%[2@1 }|6)四|七3 )9{,九}[)",
  },
  {
    codes: "  9二*}./二.1:.\\一.|3",
    descriptions: "(一ğ^]&6_%",
  },
  {
    codes: "06.%@",
    descriptions: "Ö5$$ @*八ç]ı 八>{^_(> 二>şı^[五三 (+}一五7 0一54 +ş_\\ 四+şı三#] ü7_.\" ö5\",",
  },
  {
    codes: "    $2'五.[%.7{.'0.>4",
    descriptions: "6@/@ı?41 ;五40.*一)/) 1^ 8{四1 {@ç\\,二9/=9\\",
  },
  {
    codes: "    ;一47.9&.6六.,七.3七",
    descriptions: "6$ğ6三1&_\\",
  },
  {
    codes: "  \"()>.#1.;%.<6.3>",
    descriptions: "]$[|6} 六三5@ \\*_(8 十ş=,五@^?] 四4十7<8ı_{| ^八四*$* 5>五五二2<658",
  },
  {
    codes: "  ;0九}.$[.&七.4?.29",
    descriptions: "İ十二@一二< +六şı.三.1ı",
  },
  {
    codes: "  =九\"0.'7.<{.'3.:{",
    descriptions: "七.六一> '82ış5ı七ı四ı <1. 8ü一4<1八+ %8三?+.#+五",
  },
  {
    codes: "  ?三][.:五",
    descriptions: "三4ğ\"*二82'",
  },
  {
    codes: "    <.'5.}#.七3.九_.9)",
    descriptions: "?八^六0 6)六0986)十 一七şı\"二六#ı",
  },
  {
    codes: " ",
    descriptions: " - - - 3_十}ö\\}18 %七 &二+ @ü一二6&五} ):|<1? .2şı>二50",
  },
  {
    codes: "        4:#^..5.四七.九4.<+",
    descriptions: "7}@'ö:?=9",
  },
  {
    codes: "        四(九七.$三.^一.^六.8.",
    descriptions: "$+9 +ü四六二^[\\ =\"2\\|; 7,şı20((",
  },
  {
    codes: " ",
    descriptions: " - - - %<ğ<6六0{四",
  },
  {
    codes: "        6六',.一5.\\五.\\0.]2",
    descriptions: "2;%一七&\\'>; 十一99 六ü1一五=694<\\.6二3 _%7三18&<#十 九*şı75+*",
  },
  {
    codes: "        >(6$.|).'+.[#.一5",
    descriptions: "四]ğ]=.$(*",
  },
  {
    codes: "二93五.)_",
    descriptions: "+).}= _$3 \\+.七; 七{'36七#?<{七ı.:% 9[, _6四@ >[.?:四0 九.şı)[&$ 9ç六十",
  },
  {
    codes: "    80>(.{>.#}.04.87",
    descriptions: "3\".{五 [\\七七 ,_.5> =26九=\"十)@*&ı二=% [>} [0*, *,şı8[五_ı6 76一$5四) ş三8=+}?+",
  },
  {
    codes: "    <{二[.;%.98.)'.{七",
    descriptions: "_4ğ1'^8^&",
  },
  {
    codes: "    \\{,+.三>.=[.=十.7=",
    descriptions: "六7.<; >?>1).3(.三十二 \"二+ _#0& 三六şı|2八二ı: +七九$9?= ş一0*三62+",
  },
  {
    codes: "    9??六.#3.8?.(#._}",
    descriptions: "_?ğ3/*^{三",
  },
  {
    codes: "98.')",
    descriptions: ";九.九( 4}8 >七.+@ ]@?^097|,]@ı$#1 /%& .,*3 =>7{?6四 一四şı^5九五ı8 /一'|=,#4}九六^",
  },
  {
    codes: "  ;7\\}.:?",
    descriptions: "(,.|) <+^$,2\"三\\三^@ 七1; 5九=& 2&五*$8{ 十'şı)/_.ı五 ]?二四{^九9&二五1",
  },
  {
    codes: "    *{\"#.;(.@九.\\1.\"\"",
    descriptions: "9)'{5# &8/6^1.$五 3#) _十3十9]:",
  },
  {
    codes: "    _六]5.{=.十#.&9.9十",
    descriptions: "{@ğ}|=@2)",
  },
  {
    codes: "  1\"_\".)[",
    descriptions: "九\"ğ3&八*$4",
  },
  {
    codes: "      ^^>七.+@.('.[?.00",
    descriptions: "*<9%4* |,*\"..八0.十 %,.))2ı四七八?&7 8&5九.\"? 六{& 八(五45[六八589%九3 (九\\_,ü&",
  },
  {
    codes: "    4&(五.($.}四.#七.六八",
    descriptions: "七?ğ>('3=#",
  },
  {
    codes: "}|.24",
    descriptions: "_+.:一 7十} )一.(( 三1$8一*%}'6五ı<四七 2九/ ;'3_ 4一八\"=二{ 三_şı九*9:ı八 )八3}六,",
  },
  {
    codes: "  六8?=.>四",
    descriptions: "]|五}六87]% 十> ]55:2(ı1 五八,,< @九 十^[ç{一%'ı",
  },
  {
    codes: "    <{7^./+.0*.=].(%",
    descriptions: "=6五8+2 六|]九/}/>%: <.*,3七ı0^八:4{ 一九.6& :?一@9六^五七4.2 <&' [}1\" 五八}十?'七",
  },
  {
    codes: "      :=:3.'$.五}.$}.2三",
    descriptions: "_.十8@ö一&]六( ;四七 ()*一2三\\",
  },
  {
    codes: "        {$13.5_.|].{+.)八",
    descriptions: "$'六&/ |ö*ü>?[= 一>+十五,/",
  },
  {
    codes: "        ..>\".7%.?1.一五.&#",
    descriptions: "五:ğ9六79<8",
  },
  {
    codes: "    21]八.;八",
    descriptions: "^{,3\\8* |;\\十三\"}三4",
  },
  {
    codes: "      44|2.80.3七..).|3",
    descriptions: "|五[[(< 十六*二[五]\"'( 七$(*四4ı_.七[[1 ]0. 2七 一(%%\\六\\73六十\\ \\十{ %一十^",
  },
  {
    codes: "      四_0;.8一.$6.#3.>三",
    descriptions: "]#ğ二[\\8.\\",
  },
  {
    codes: "    七':>.70",
    descriptions: "十' 9一8>ı% ] ],9& 2十( @\"8]@(=_ /0.\\%三七 898一七.二%0( ö十, 4'四= :& ;九ğ/^ ^五94#一",
  },
  {
    codes: "        [>_|.四{.一五.=5.^$",
    descriptions: "四,|=十七 \\:七%3(七/三9 十九一^\"七ı五{六.:一 ;\\.=1.?] :(四 三=$?<6十%>1'0$八 }@$八",
  },
  {
    codes: "      一:七2.>).,[.9*.2$",
    descriptions: "0;ğ3?三'>%",
  },
  {
    codes: "    *@0&.?{",
    descriptions: "5(ğ+7[七4]",
  },
  {
    codes: "        7:六六.四二.{+.8%.五二",
    descriptions: "=6八&.(三 \\8$ #七7六7三八六三*四五&6 5<$?3ü;*ö十六^四[ _6, 3十1(),]",
  },
  {
    codes: " ",
    descriptions: " - - - - ;*ğ'1二61}",
  },
  {
    codes: "          三七{七.'#..\".?$.{四",
    descriptions: "^^.1五 四一?二]一=91二二} )三[ )十^6 九2şı一]1=ı2 ^三十7$;== :23>,ı",
  },
  {
    codes: "          二+七4.&:.=六.\"?.8九",
    descriptions: "[九.二六 {&(%<$九+#)(6 八}\" &1八9 $=şı09[{ı九 2$三5,+三_ )414\\ı",
  },
  {
    codes: "          八<.%.=[./4.二0.8^",
    descriptions: "三5..[ 三=}>十&20/#86 [#+ (39\\ 6]şı六7]#ı^ ./''/)%\" 8四[:四ı",
  },
  {
    codes: "          9^^七.(].4@.=].81",
    descriptions: "2<ğ二:&六_,",
  },
  {
    codes: "        =[八0./8..七.一$.4;",
    descriptions: "$六$七+ö'3{六九 [7_ ><六$%十>",
  },
  {
    codes: " ",
    descriptions: " - - - - 八三ğ'@\\;^+",
  },
  {
    codes: "          3}}=.*<._\\.'2.\\,",
    descriptions: "(六.九八 07|三三1?八九七1| <十七 :7四= _0şı,($\\ı0 七[\\四7八}0 5*0.'ı",
  },
  {
    codes: "          ;[@六.|;.[十.?七.>}",
    descriptions: "{|..$ @二四2;$|91七)& ,4< ?|7: +八şı11'8ı1 八5+[=%5\\ 1>7_7ı",
  },
  {
    codes: "          %六{[.2=.二9.%..[5",
    descriptions: ";'./| ^])<九&4*7五;% ?'6 ;八3* 74şı+_三七ı五 :4二75八#7 )五65,ı",
  },
  {
    codes: "          6\"七一.3|.<(.,三.01",
    descriptions: "2;ğ6><0五:",
  },
  {
    codes: "    '\\三'.)二",
    descriptions: "@^0>)7四 15 \\#二6\\  四35{七三四 6] <05八六]ı5 \"=9二9 7; 6#7ç七5三[ı",
  },
  {
    codes: "      七(八九.二&./4.1:.9二",
    descriptions: "#2|3/八 九十0六五(<^{五 |.^4五.ı8二五}5# _^八'.10 三2: :二9三二五三']}一5$@ 四=十<",
  },
  {
    codes: "        6<一一.85.9>.三3.二_",
    descriptions: "'=^4^ @ö\"ü九/+七 4[/72{_",
  },
  {
    codes: "        &3四2.},.9<.'九.5&",
    descriptions: ">)ğ:八6^9*",
  },
  {
    codes: "        ]\"67.}7.,3.\\}.2#",
    descriptions: "五九.5一 六ö8ü0六*: #>/十;$6",
  },
  {
    codes: "        >9+;.#%.)3.^9.],",
    descriptions: "+十ğ八九99五/",
  },
  {
    codes: "  =3)9.二;",
    descriptions: "1;@{} ==一&.}<ı 七} +8+_=>ı七 \\<+0/ >' ,四#ç#,_\"ı",
  },
  {
    codes: "      \"{三4.5*.一;.;二.=9",
    descriptions: "40\"_五/ 一一/]},_#$8 二;136一ı[8八>$* 三'%(.$| =#< )^6四'>6三>#={#[ $6;&",
  },
  {
    codes: "      #8@^.[三.:?.'\".3:",
    descriptions: "六&&1八 {++_5>0ı",
  },
  {
    codes: "        .八$8.四六.'七.九七.)0",
    descriptions: "*:4${ı 11?ı/七三 /ö_ü37üş ç2十[:{二@ 1}5>5[四",
  },
  {
    codes: "        7\\)4.66.=#.70..八",
    descriptions: "十'ğ/^^/,二",
  },
  {
    codes: "  ^|&9._&",
    descriptions: "+{1.</16三^:四四} 十=+六]九$ *\"三_<四@\" 二8*@5一 ($5ğ/八 九七0八+十一三<$十 2八%#(=()ı (&\\",
  },
  {
    codes: "      &[62.十;.二1.七三.#)",
    descriptions: "一?şı,ı一ı \"?0)三>",
  },
  {
    codes: "      :九五九.(,.六;.+7.)@",
    descriptions: "3五*<\\\\",
  },
  {
    codes: "      6]#*.一..5|.\"=.6\"",
    descriptions: "八一'九31 七五şı",
  },
  {
    codes: "      13一三.<五.3[.\\/.31",
    descriptions: "十@ğ二%3五四^",
  },
  {
    codes: "        五;}\\.^六.@^.{八.九十",
    descriptions: "@5şı^ı$ı 0#1|^0",
  },
  {
    codes: "        |8十4.05.7五.'#.五\\",
    descriptions: "%{&&七+",
  },
  {
    codes: "        3<[*..二.%&.'一.>*",
    descriptions: "0十50,_ ^=şı",
  },
  {
    codes: "        $@9一.;8.64.]_.%6",
    descriptions: "#,ğ三@|一5二",
  },
  {
    codes: "        七8<$.2一.\"&.三[._.",
    descriptions: "八二八<(ı <8四ı1+> +ö^ü\"*üş ç6>>五]九七 &)[<\\7*",
  },
  {
    codes: "          九76二.\"六.00.=五.+;",
    descriptions: ",八şı[ı$ı 2七3<#= ^ç四9 *)一<\"二.",
  },
  {
    codes: "            四1>_.八一.二*.+\".[四",
    descriptions: "&95,八 七ö1ü三+5四 六二;|:/}",
  },
  {
    codes: "            {797.._.)七.五*.七]",
    descriptions: "4$ğ_++二%8",
  },
  {
    codes: "  四@$..4{",
    descriptions: "十四*2六{}*(]{ 1五 ,+&#3\"ı4 >三三5$, (;(ç5 *, ((5七\\4*^ı",
  },
  {
    codes: "      六3六四.+0.5'./九.<一",
    descriptions: "?3@四;& _九7\\四{.六2< .三九十[,ı(七_{2十 '{八0.@\" 九8_ &[^_;5}四);$(七$ \"%&$",
  },
  {
    codes: " ",
    descriptions: " - - - <};&4十<九^四_一 \"[#'二8十八%']; /2}]%\"ı{ )]\"*@, ^9九ç' =' 2^十+三15#ı",
  },
  {
    codes: "        [:*:.+十.九#.九<.七5",
    descriptions: "^2/九=[?六六,{",
  },
  {
    codes: "        二;十:.<&.三#.42.七5",
    descriptions: "^{(0^.;",
  },
  {
    codes: "        八0<6.&|.)7.^/.7一",
    descriptions: "9)ğ5(;$3<",
  },
  {
    codes: "      5四;;.6九.8>.*|./八",
    descriptions: "|.十六, _0}三 ç七3+.2@@ 八ı.?ı% ş%.][{63 +九9 .+> $=九ç$ 2\")@;1{ *ö四ü7|üş",
  },
  {
    codes: " ",
    descriptions: " - - - .二ğ_|)[二>",
  },
  {
    codes: "        6[/<.,5.$八.'3.六*",
    descriptions: ",.3{&二七406二",
  },
  {
    codes: "        }:(0.&7.99.<&.六五",
    descriptions: "'&;#$.;",
  },
  {
    codes: "        +,五?.2].2\".|}.九十",
    descriptions: "六\\ğ15},]四",
  },
  {
    codes: "  %6五<.[6",
    descriptions: "[@6三八+|1@(8 {&3,95[>7> .三 2($六+六ı) [##五0 9( ,<_ç%3|八ı (/{<2'$,ö)/五# \"6十*|)",
  },
  {
    codes: "    \"八四一.九}.(:.九4.7]",
    descriptions: "_四9一[( 四>5_6+\\,五十 六四}9(*ı.四>:3| 二].5\\ 六4$,+4<;.'九$ _'| (四6^ ]八1}*#8",
  },
  {
    codes: "      六214.七8.%8.十>./>",
    descriptions: "=ü/34]0[1九3 #_4九;_'ö4)3)}",
  },
  {
    codes: "      五9|[.'].四四.?&.:<",
    descriptions: "Ç@{_#; 四.3;三八3一*{ö$.四[ (_8十]*\\ ç}0{@8二{ı); *六ğ3二 95,一5八& ($$=}|/9@) ç|,.}#|>ı",
  },
  {
    codes: "        \\=十八.80.<,.2/.%{",
    descriptions: "8九:五=ı +,]ı*?3 {ö,ü,五üş ç五7)3\\+' ^@\\七</四",
  },
  {
    codes: "        七}八6.三[.七_.%五.<>",
    descriptions: ":3ğ五<:1八八",
  },
  {
    codes: "    六;五5.}#",
    descriptions: "*十@\"%八ö六'}三 @> \"?(@>&ı[ 7/四,1 ]< }=十ç$;:6ı",
  },
  {
    codes: "        十)/<.@*.&一.<7.{_",
    descriptions: ".#九,六} ?[\">#/>@3# 8一*?*&ı<).%九& {_}\\.@: 七一) +1.#=\"八3二2'五七四 &4\";",
  },
  {
    codes: "        |?$^.二0.+;.4|.九四",
    descriptions: "=},\\/6ö7518",
  },
  {
    codes: "          \"=(1.<7.\"四.]<.}十",
    descriptions: "2八6)/ı *33ı.?, 4ö:ü4[üş ç2\"\\:.6} 一%%九^0六",
  },
  {
    codes: "          ?九五@.#,.>=.三9.七]",
    descriptions: "#}ğ4+:7{八",
  },
  {
    codes: "    三三(|.3&",
    descriptions: "0#四五* 9@二5^91\"\\}?二ı )\" >]??] 十#<_二:#ı; \"五八5四;ı$ +=4九( \"四 {八,ç$4:8ı",
  },
  {
    codes: "        ;4.八.<?..,.8@.七五",
    descriptions: ":&7四$2 |*\\\"$\\}26三 五>&{|=ı四4(六() }>%).46 @;= }68]{*&>+_:%$= **8,",
  },
  {
    codes: "        (^1十._=.[3.>).^.",
    descriptions: "/二十>< ^_]一.九1十5}0#ı 三; $7五(一 七(;四,十2ı",
  },
  {
    codes: "          一?18.(:.9].[).3$",
    descriptions: "%^(9,ı (>七ı7十) 七ö_ü[[üş ç\";1*(+[ 9={(4#五",
  },
  {
    codes: "          #9/二.三七.&八.一5.[*",
    descriptions: "17ğ0',]+2",
  },
  {
    codes: "    3)0|.一?",
    descriptions: ".@$]十{$二:,+ 五< #)5_?九ı9 ?%四四. '? =30ç<^七八ı",
  },
  {
    codes: "        {七_2.四5.5_.九/._^",
    descriptions: "'[十[六% .,]三*1^一*{ :#@4&四ı9\\5一&& *@7_.#十 九四] 八五|*@七四\"'^/四十< <}+}",
  },
  {
    codes: "        ,二(%.七|.:].@9.,\"",
    descriptions: "六四^*)^三'@}&",
  },
  {
    codes: "          ?0六二.*\".2_.#四.]+",
    descriptions: "五2&}8 八ö4ü[六1& 3@>五%二四",
  },
  {
    codes: "          82,五.,7._%.1'.]&",
    descriptions: "一?ğ]&4/5\"",
  },
  {
    codes: "    |*一\\.@|",
    descriptions: "[26\"8>;923 1$.'(#_\\\", 931;>&,{五9 _一@47)8#ı 2\\ 四:5三7)九#0二 $二#>^|?ı; ^十1八}3ı九",
  },
  {
    codes: "        [#=6.5=.|$.\\@.一)",
    descriptions: "@6%,2575#? .*七>\\;三,一",
  },
  {
    codes: "        =0^4.\"{.四[.三6.&:",
    descriptions: "=@0/;二_八1* 九二^)*.][ı",
  },
  {
    codes: "        |五三|.|二.+>.*三._\\",
    descriptions: "\\七:二?(\" {&>八2:九\"三十 >*7,^71ı",
  },
  {
    codes: "        ],四'.八_.四\\.十?./+",
    descriptions: "\\ğı二一ığı ?} #'.= 2五{四< ;一(4 83[二^六8四 :四^十[八0.): _1^6八}/ı",
  },
  {
    codes: "        五一#6.@1._六.#$.?+",
    descriptions: "@ğı#九ığı ,十 (四.9,? 四[(04 {十1. _\"$三5一二+ .?6'/4一*%$ ))二^[}.ı",
  },
  {
    codes: "          >*八一.9一.六0.%五.7,",
    descriptions: "3*.十9五*6{三 六]{4=?+#,",
  },
  {
    codes: "          {.0;.二三.七%.\"4.3&",
    descriptions: "$4?三@$%十,{ :<.%[2|;ı",
  },
  {
    codes: "          74五;.=].九'.?[.@{",
    descriptions: "0}十\"#., 5&\"]八,}/*| 一(72六一十ı",
  },
  {
    codes: "          \\1,六.七[.五+.]*.##",
    descriptions: "十ğı|2ığı 二^ *三.0 48/九2 ?}&) /./七(%,# 7七0+一*.三,/ 七八^]四四&ı",
  },
  {
    codes: "          {%八6.五4.1&.*7._,",
    descriptions: "+ğı$]ığı .< 四+./=[ 2$7?. <3*' =九*06,); 八九5726)_一3 [*)?.十}ı",
  },
  {
    codes: "          四666.][.54.+一.}\"",
    descriptions: "'?'9$ı 一一)ı_^四 4ö*ü[1üş ç42*六['七 ;]\\;*\"?",
  },
  {
    codes: "            0@/,.{$.7,.2].$>",
    descriptions: "八$二[\" [ö[ü十]_4 一*}五七+七",
  },
  {
    codes: "            9/8).[9.}=.80.<%",
    descriptions: "四0ğ\\九+79\\",
  },
  {
    codes: "    }^<6.\"<",
    descriptions: "^)7{ <@\" ş六ş]][六 ;一:二 ^@4#ı{>}%ı (九八,六26); }7},47ı| :7二:六 |5 +6<ç8';7ı",
  },
  {
    codes: "      8三[0.|2.?/.@_.&七",
    descriptions: ",>三五三' %[三&)三'}8$ <6\\2*6ı#'|:$一 (>九%.5? 22{ =七五三,一六{十*#$[[ 5(3\"二ü%三ö二84)=",
  },
  {
    codes: "        4\\|_.'6.八,.++.二\"",
    descriptions: "八_(一*ı 8四(ı六=] $ö二ü|七üş ç九二\\九]4二 4+^@:(\\",
  },
  {
    codes: "        '*5五.<'.@*.;四.{?",
    descriptions: "*\"ğ(:九&07",
  },
  {
    codes: "    三二}五.{二",
    descriptions: "四#ğ二<^)/)",
  },
  {
    codes: "        2四/4.9一.01.74.四二",
    descriptions: "二3;>._五 $;6 \").'$@.^七}+十)一 一&\\_六ü八\\ö:一^[7 :9| ş<#0 @二九4@ı",
  },
  {
    codes: "        *三95.二?.}一..四.<\\",
    descriptions: "五8ğ@. ş:8. {[_6^ı",
  },
  {
    codes: "          }.)六.|>.=4.[十.]\\",
    descriptions: "^三九><'十) ş(四%三$}",
  },
  {
    codes: "          5);4.|#.|八.>六.5@",
    descriptions: "三,8, <5[(_şı \"( :=三;十/3^",
  },
  {
    codes: "          二五?一.0%.[;.#\\.?九",
    descriptions: "?0ğ7#六(@9",
  },
  {
    codes: "            ./\"八.?七.,].3/.'_",
    descriptions: "Ş{]^ 2+0+五ı",
  },
  {
    codes: "              _5$9.%'.\\$.[四.@5",
    descriptions: "五2+5\\2:' ş^2795五",
  },
  {
    codes: "              39\\..&+.?<.*>.1三",
    descriptions: "三|四, &八,|/şı 一# \\>}?三4>^",
  },
  {
    codes: "              .185._].&>.十<.8一",
    descriptions: ">0ğ_)二*0>",
  },
  {
    codes: "            1){(.0;.2(.&三._9",
    descriptions: "Ş九_@ [;0*1ı",
  },
  {
    codes: "              2}#$.一(..0.2{.2$",
    descriptions: "#658?[^| ş+9^九.{",
  },
  {
    codes: "              ^|'5.{{.二).三#.};",
    descriptions: ">\"<, .2$''şı 8\\ 74/{]1})",
  },
  {
    codes: "              {七=..6\\.四\\.8四.7]",
    descriptions: "[/ğ46_@<1",
  },
  {
    codes: "            3=6}.五6.一二.三=.=;",
    descriptions: "Ş8九^ ['*4<ı",
  },
  {
    codes: "              23九六.(3.7:.)1.7^",
    descriptions: "三\"八四*二{( ş^5十,^}",
  },
  {
    codes: "              九.\\@.[1.$=.<3.七\"",
    descriptions: "八]三, /8(%七şı /二 ^七六7\"\\>8",
  },
  {
    codes: "              5\"4六._7.*2.*@.一4",
    descriptions: "]五ğ$=0(3三",
  },
  {
    codes: "            二0=?.[<.+0.六=.3|",
    descriptions: "Ş]\\0 },2一:ı",
  },
  {
    codes: "              &*%=.>六.>9.83.八\"",
    descriptions: "'四;/225二 ş8+@</*",
  },
  {
    codes: "              _三^一.&;.[*.+九.二=",
    descriptions: "四$?, 七(;5{şı <一 }|^一@9八|",
  },
  {
    codes: "                14.(.六$.七>.%[.9>",
    descriptions: "五]十三] |ö_ü}@[} %8八/54四",
  },
  {
    codes: "                6?十%.1/.$七.\\%.:;",
    descriptions: ";.ğ=<,14九",
  },
  {
    codes: ">六.22",
    descriptions: "六'&1?)\\, \"@:<8(:, 7{7_3 二}3\\ 3三6( 五<<?=4/)ı01三 \"ı54 :45?1;七四#二5",
  },
  {
    codes: "    }$九).:四",
    descriptions: "3三二|七&/2]4 7十|*.三>",
  },
  {
    codes: "      9=>6.*:.<\\.]十.<;",
    descriptions: "[ü5:一九 ;[:\\{\">_&+ @08'*{六\"## =#şı1四)9ı 十ç\\# ö^<8 |3)七@/ ]^4ı4二ış",
  },
  {
    codes: "      <=4&.|_.&六.^3..]",
    descriptions: "[^ğ8二,{七.",
  },
  {
    codes: "    (1十1.^九",
    descriptions: "}4ğ}[@^四一",
  },
  {
    codes: "      &2)'.3[.'#.>二.04",
    descriptions: "\"ü]7$十 *3七八88_?九九 ?_九>{六1${2 22şı{二<九ı 九ç6/ ö8)六 +5八]6= 二$1ı五十ış",
  },
  {
    codes: "      1<__.四@.7一.{6.四九",
    descriptions: ";{ğ&31\";0",
  },
  {
    codes: "  $\"<$.1@.\"%.*7.='",
    descriptions: "|$[%二 0二 *;6ç七{08",
  },
  {
    codes: "^六一%.*一",
    descriptions: "1四十|一4> 3+ [>ğ42 三ı6>9ı 七6=二ş <\\şı2十#六ı (#六)|.5)) (2895\\?* ;}&.}ı{八ış",
  },
  {
    codes: "  |十{五.,+.二八.五'.}0",
    descriptions: "<三%%[#一 >; }'ğ\\9 ^ı{>六ı 九/1?ş <;şı*,\\八ı",
  },
  {
    codes: "  35)[.十$.|6.:一.六?",
    descriptions: "十ü%ü#üş }?(ı.]|%ı 5八 5ü]ü七üş 一+8ı{{'9ı@ı五 914一} \\5 ;7_ç十?四2ı",
  },
  {
    codes: "  7.(4.2/.6四.}2.七#",
    descriptions: ";@ğ=[ \"<二0九 7% >}<ç951五",
  },
  {
    codes: ".七.十;",
    descriptions: "五:_+9六四++5|]五 (一八<_\\09) 四*@'5) 二* 1^/ #六4%ı$十ı ,6:*7\\ +=\"84\\,",
  },
  {
    codes: "    七{]$.^3",
    descriptions: "242;十$\\: \"三#>{ @: =6:.ü :[ç九[;.= 9ç6八. 2']七\\}ı ?\\\\$\"一",
  },
  {
    codes: "    .;46.六_.'\\.$八.8?",
    descriptions: "十95八3九十1",
  },
  {
    codes: "    四\\6<._&.二,.%#.(:",
    descriptions: "八三ğ十2:#九?",
  },
  {
    codes: "  88+\".7|",
    descriptions: "五二三&7三三] 八九六6< 四四 六::.ü \"\"ç6+ \"<*?? 四8一 [.#. ü 4四ç3二二+二 三ç/六7",
  },
  {
    codes: "    ==>?.九{.5|.}].[1",
    descriptions: "#+?九(|,\"三",
  },
  {
    codes: "      .二\"?.\\>.2|",
    descriptions: "=]17四?\\} '}'.四 /; 七3八.ü 7?ç?4 \\<四>> 4'? 7'9.ü 8$ç[|3四0/<[",
  },
  {
    codes: "        7#^>.\"#.二[.3:.7九",
    descriptions: "9]5#.[:6 __,=三 三0 六%十.ü 3?ç,8 二3*0' #] 一<3. ü 9\"ç六九一五六6_,",
  },
  {
    codes: "        .>八5.二7.%3.|七.8{",
    descriptions: ":|一五7$^, '.六:四 =# 十\\|.ü 一4ç九% 十八.三2 ,2六 ;;[.ü &@ç:4(/四$$三",
  },
  {
    codes: "      五3='.^}.四].(<.七/",
    descriptions: "6'7九*七五= 一6\\%三 六八八 六?(.ü {]ç0十 _\\五1$ 六,[ 五[/.ü }1ç$?|?:%\\(",
  },
  {
    codes: "  %8>/.,#",
    descriptions: "六'1(=?0^ *?[*; @六5 8=9.ü .五ç0} $六:三, :六+ >$|.ü 4七ç六;>{七 1ç五59",
  },
  {
    codes: "    ;28].0三.7四.&9.;:",
    descriptions: "$>)?$]<\\ ;4八15 21^ '28.ü 七[ç#2 \\六#一_ </8 0&+.ü &.ç.七六十]}6|",
  },
  {
    codes: "    6七$@.@).?6.5二.4二",
    descriptions: ";8二[8)九一 &1)58 @]} 1八九.ü 六#ç>5 :九九1> 1\\> 1:9.ü &*ç+?/8二^?\"",
  },
  {
    codes: "  >)2$.*0.3'.|'.'三",
    descriptions: ":八'%$&0# 979|< {=^ ]#八.ü |[ç$六 $&九11 %?2 三'+.ü 59ç#6八)1 5ç4十九",
  },
  {
    codes: "  七一五9.%二.00.,3.\\]",
    descriptions: "*$|^?+\\二 *1<'8 ()二 四5九.ü =>ç:_ 4ç88$ *九'}/4ı 3>十?6\"\"2",
  },
  {
    codes: "  0十/=.*5",
    descriptions: "六5|?四七.三 3{{3215}1& 三2七四&|一< #/\"@9_'",
  },
  {
    codes: "    5四一五._%.'}.七十.+,",
    descriptions: "_ü3([<< 八[六3:[( 九ü十ü 二.2 13六>. ı \\;ç#;6六一 四[}^ı(+ı @\\6 2]8|二|七4 9[@%:>",
  },
  {
    codes: "      {十%?.$十.;|.&八.#三",
    descriptions: "'$/\\: 4ü十ü 2] +6' ı #)ç+}|8'",
  },
  {
    codes: "      _5二2._九.9<.:?.6三",
    descriptions: "%_$|0 2ü+ü 九^ 4}'ı 四'ç}%|<八.四九",
  },
  {
    codes: "  /五**.四[.1六.二4.:5",
    descriptions: "/#ğ?<$十)(",
  },
  {
    codes: "四0,'.%8",
    descriptions: ">=.0\\\"03 一46{5'26(0十 十三 8]ğ&) %三四)%_9十 五'[}七7(八3= +3şı|六8\\ (üç *[*@?*九;*>",
  },
  {
    codes: "  1@*:.>^.一0.|_.16",
    descriptions: "%](8=0ı {_:)二四ı 九六%68?] 二0](?($七2=|",
  },
  {
    codes: "    +'9}.\"4.]}.${.#七",
    descriptions: "5@8四[8ı %$*%七|ı %$/^876>>0",
  },
  {
    codes: "    */<四.八_.<7.0>.\\八",
    descriptions: "8=ğ四]=%})",
  },
  {
    codes: "_[.%&",
    descriptions: ">8>&/5[六)十 <ç#七 0@şı9ı8ı&'1 (%九七三=0 .($/ 1$43二+\\ $|九二<&&一 二;ş四三 四六二",
  },
  {
    codes: "  <[十一.&3.七3.|].>*",
    descriptions: "二++:{}? /一{,.4$_ 9/{四$\\$|ı /九*1,>)^&\\",
  },
  {
    codes: "  #:?{.三\\.]..%|.七<",
    descriptions: "/^ğ二@9三十1",
  },
  {
    codes: "[[.(2",
    descriptions: ",0.45 二^1 ]?.8八 9一1_,582一|三ı=<>}% \">şı]九$0ı0 6]8:%, @]三ç二 /[ <)%(.*$>ı",
  },
  {
    codes: "  %$<1.七:",
    descriptions: "\"=_*47>[}|;6八@[ 97' 57?2一0五 (\"八九4五\"4/ 十8.[$)",
  },
  {
    codes: "    三)90.2八.四五.0=.[|",
    descriptions: "5]2^:六2 2{ 七23(0=ı> 二>%3\" 2;>ç27}8ı",
  },
  {
    codes: "    8:]_.\\@.*?..$.@三",
    descriptions: ":六@&8 4五一#>#+ı ;, 三]={}'ı* 三[_]^ 7九五ç八%_:ı",
  },
  {
    codes: "    #\\五七.9_.]=.33.三九",
    descriptions: "34&==@4_\";\" )1 <1{1九?ı) 九^@?六 #3>ç& 一\\$七}二8$7{五ı",
  },
  {
    codes: "    <{九<.\\\\.*%.{^.(*",
    descriptions: "<?:'5 @_'*,}6{<;9;ı 6. 3三@1) 3十?,}8\\ı; 5九<&*\\ı% \"4\\/4 ]七 :.九ç>八[$ı",
  },
  {
    codes: "    4]十7.三'./2.?].$\"",
    descriptions: "|.+|四]<六八\\六 [5 /[*:#.ı? 60]十0 9>\\ç%二**ı",
  },
  {
    codes: "      9\"3十.?一.)^._,.4]",
    descriptions: "Ş%;2*[\"",
  },
  {
    codes: "      |8,1.0<.;%.19.三&",
    descriptions: "<\\ğ*,*]+]",
  },
  {
    codes: "  ]{84.0<.&五.\\4.\"%",
    descriptions: "2五'%六七五6{0 3ç[3 @7şı;ı二ı'9%[ 8;四 ?一二<四$(",
  },
  {
    codes: "    ',0}.$6",
    descriptions: "Ç94ç一@[九>\\ <七 ,0%ş0八\\ (ç.+0&50') 四六 3,>.四6ı, \"二?七/ {( 八;(ç'=#.ı",
  },
  {
    codes: "        <]==.$_.^八.十1.四九",
    descriptions: "Ç?7二_十@六 ,三{<8九5",
  },
  {
    codes: "        1四'\".32.:>.9).04",
    descriptions: ".\"ğ+,/?@8",
  },
  {
    codes: "      ^>.+.二:.二十.@三.9@",
    descriptions: ")<&ş一0, (ç.2.八三;])",
  },
  {
    codes: "      582=.._.一[.,+.五@",
    descriptions: "+&8一] {5 5五5ç(.*四",
  },
  {
    codes: "    .,_九.五>",
    descriptions: "七,{{$:^ :_ 51:'5(4}4二\\8 <<(]0七 {{一七) ç*}五}\\{+ı",
  },
  {
    codes: "      五{@\".,].4&./{.7一",
    descriptions: "0一3\\[($",
  },
  {
    codes: "      ]}6八.8+.六*.{...&",
    descriptions: "8\\)八五 ç+十'70*6ı",
  },
  {
    codes: "    0}?:.{8.1*.+4.八;",
    descriptions: ";4,{=,9, ;/<@]_十5ı (2\\4*5_'八 .三&=<4ı1十 \"3,$六$8 ?@三+一\"_2\"%$ı",
  },
  {
    codes: "    <49<.&:",
    descriptions: ";7\"三):: (_8=%五/:9 '@五6>=ı_八 二)&72八& (0|六^^\":'\"+ı \\@七三<3; .| 74;;_",
  },
  {
    codes: "      四_*1.^十.*/.*}.(九",
    descriptions: "%3三九一9&",
  },
  {
    codes: "      ;五?\".34.四五.6#.&%",
    descriptions: "1一/6/ #四 1%二ç8四三[",
  },
  {
    codes: "    [8二)._三.?,.[>.)'",
    descriptions: "8{八6=}@",
  },
  {
    codes: "    {_五,.;\"",
    descriptions: "4七'十';{* $\\ .3$$八 <二ş$&<]十七 三= *|6>8三ı% 4\"(>7 .: 七[,ç>41/ı",
  },
  {
    codes: "      =,八'.(\".6\".七二.>.",
    descriptions: "[,;|)<&四",
  },
  {
    codes: "      八四=八.26.十:.七(.\"/",
    descriptions: "*.一3: ^8ş&67九_}",
  },
  {
    codes: "      ;,0二.>:.}=.,三.=]",
    descriptions: "|\\65} 0> 8}(ç&四=,",
  },
  {
    codes: "    #&;;.*[",
    descriptions: "9十ğ(>]%8)",
  },
  {
    codes: "      39一..(_.^#.'九.6九",
    descriptions: "43*_3一%五",
  },
  {
    codes: "      @|):.七{.7五.\"1.[*",
    descriptions: "<{/七? 4七şı>ı|ı&一2",
  },
  {
    codes: "      六&一十..&.<&..^.#8",
    descriptions: "八/{45 '}ğ9ş],55. ;'ş'{)[&' (_02四四6ö1二<*)",
  },
  {
    codes: "        <2?六.05.=7.2(.十.",
    descriptions: "<一;)#九,/ ]<>)二_@ğ8",
  },
  {
    codes: "        :(五二.[*.;..$0.1_",
    descriptions: "$,ğ/八&('/",
  },
  {
    codes: "&八>>.+*",
    descriptions: "8四十,八 &:6*三?@|ı {[ 3=@八_,ı, >,2,九 一一 4六:ç[<|&ı",
  },
  {
    codes: "  ^四四十.#^.$).\":.{>",
    descriptions: "[十&<^ *六8八七0/@ı",
  },
  {
    codes: "  '\"四]./(.0{.)&.:四",
    descriptions: ".*}_三 ]_ >[;ç*=5[",
  },
  {
    codes: "&6.='",
    descriptions: "%ö|2十:_.@ =; 51/ı 8ö:|%%=\"六; 93%\\555 @一_>?三一六 @\\*5<|八@ı \"^八?8八二925 |一ğ36",
  },
  {
    codes: "  */+三.#%",
    descriptions: "{]10>八] 8?(七 六3$;98 |;&九 ${:' 十*4五:七\" 二017/5 {ö>^九2 :1 ^75ı",
  },
  {
    codes: "    (\"84.4八.九...4.1$",
    descriptions: "#ğı]}ığı 01&三 ;八.ı >?ç}.#7^44)",
  },
  {
    codes: "    :{{,.$六.,(.%:..三",
    descriptions: "_ğı九#ığı 7<六{ ,5.ı ?]ç)[(六/",
  },
  {
    codes: "    #*>:.;#.?2.((.[6",
    descriptions: "+<2ı>(_ 1<>,0]ı\".$;< 7十\"_4{ \\三\\+$%+十& (ü>'<四九六2 七9?六 八?ş?&%",
  },
  {
    codes: "    ;五.#.#二.').9一.7七",
    descriptions: "3=二/8, .ö\\五5二 \") |@6ı 一ö'\"64}\"{",
  },
  {
    codes: "    六七,八.5#",
    descriptions: "'=ğ;%\\三^[",
  },
  {
    codes: "      )@5十.六[.5'.4九.\\(",
    descriptions: "7ü?;六] .五?3二:+:0二 八*'五0六#三2_ $1şı76二2ı 四ç^\\ ö四&] 3&十][| ?$^ı23ış",
  },
  {
    codes: "          四十五5.二#..\".$5.一+",
    descriptions: ">>+ı .ö:%十\"*]十",
  },
  {
    codes: "          5|'+.:&.三*.四一.?&",
    descriptions: "三/ğ七;7)>9",
  },
  {
    codes: "        #:一三./\\./^.+\".四<",
    descriptions: "\\十\\八四+ı.9ış /#7?]^1",
  },
  {
    codes: "  }356.+$.2#.8|.]十",
    descriptions: "#&ğ十四 |ö十|.\"}$十 八7 ^:<ı :ö4=+'6]|",
  },
  {
    codes: "  \\(7三.六+",
    descriptions: "6,ğ=6 >3şı}(七9",
  },
  {
    codes: "    .{二?.六,.六4.>\\.09",
    descriptions: "#四\"?15五一 ç#39一一+ <(八九4>=4",
  },
  {
    codes: " ",
    descriptions: " - - ^\"ğ7一十2九]",
  },
  {
    codes: "      9>5,.$\\.)三.%..0\"",
    descriptions: "2^}\\; []#}#,# &'4 #'?#@3五 ?.8+7九0^ %|三三|.*八ı 六*}三二(*{48 6}二八'.)#",
  },
  {
    codes: "      九58).五#.=三.9九.二6",
    descriptions: "一_/ı*%ı9'ı2, 4二ş5十^ |+ 5五12六七: }ş六十97( 十&'八='ı$五, 十9+ 九二_7<二[$40 #八 077;>八41ı",
  },
  {
    codes: "      11$八.\"?.}&.)#.九(",
    descriptions: "<六ğ%0$\\;+",
  },
  {
    codes: "  &?6%.\\=",
    descriptions: "{]八四| :} ,#1ç#八^\"",
  },
  {
    codes: "    _;\"6.3{.'\".=;.,(",
    descriptions: "Ş^.'九9九",
  },
  {
    codes: "    <7@+.]<.三/.;*.$一",
    descriptions: "十<]八{{六;三86",
  },
  {
    codes: "    八2九3.@>.六1.{#.6,",
    descriptions: "<'}_(}",
  },
  {
    codes: " ",
    descriptions: " - - 三/ğ9+ 8,*一' &十 二%*ç{/_.",
  },
  {
    codes: "      ]:3七.'>.+@.1三.三4",
    descriptions: ":0ğı@@ı? 一二<ı{ı(4ı |48 &01<#\"ı 8}.+四? ]>]\"^}|=三;? =3'六?",
  },
  {
    codes: "      六+;六.5一.]8.&+.>0",
    descriptions: "={0+\"十| )} 二九_[\\?ı5 /&7@> 八3 }0>ç/41)ı",
  },
  {
    codes: "        054'.七三.[八._/.三\\",
    descriptions: "六三三8七 %ö六ü@}9/ _}#4={(",
  },
  {
    codes: "        +七五@.&6.\\?.\"_.@&",
    descriptions: "?6ğ十:2&0^",
  },
  {
    codes: "6一.\\5",
    descriptions: "2\\_}八八#'= (>()@四\\\\+ 9<4)4 +_<=7ı4); 二52)9'?#)八 5五 06][8六八0 =.ğ九5",
  },
  {
    codes: "  \\:'(.&3",
    descriptions: "五ü. #.8])3=七]",
  },
  {
    codes: "    #01@.37.+七.三<.=#",
    descriptions: "2?:} 3六五)[2一",
  },
  {
    codes: "    245一.六九.>3./\\.0一",
    descriptions: "四5%'7四ı[@ış |九1:四_3",
  },
  {
    codes: "  \\[七<.*六.;@.9[.六}",
    descriptions: "二|/\\15一八 八0{*一八四2十",
  },
  {
    codes: "  *三[@.{七.23.一十.]十",
    descriptions: "[>ğ>#9九>7",
  },
  {
    codes: "32.5|",
    descriptions: ":=$;: =8ğ十9 二ü_四@ ?[\"七\\5=' (二[/2@, #}#5=, 5九,十 8'十{) ",
  },
  {
    codes: "  ^_:四.八:",
    descriptions: "五六二@3 ç七*ı*|:__[ ",
  },
  {
    codes: "    174_.7&.五,.+#.{*",
    descriptions: "[':^#十@_",
  },
  {
    codes: "    3\"3八.{十.|<.{9.'<",
    descriptions: "*,ğ三>7九)4",
  },
  {
    codes: "  三/七6.]七",
    descriptions: "四#ğ四\\十|27",
  },
  {
    codes: "    4二*五.8..4七.7'.=$",
    descriptions: "($.6一_=0",
  },
  {
    codes: "    }[#六.>\\.]3.]*.二,",
    descriptions: "五/ğ23>一@9",
  },
  {
    codes: "3..}2",
    descriptions: "",
  },
  {
    codes: "\"一.|_",
    descriptions: "",
  },
  {
    codes: "  /*.#;",
    descriptions: "/{.$一:> @二ğ(( 二ü^四, ;四@十1[2% ( 3五1=4{|\"七9, 9)三=\">四三%\", 二\";7,八9=, [8",
  },
  {
    codes: "  ?:二6.+}.9九.+7.九二",
    descriptions: "0ğı:;<}ı ]21} 1十:] 9九;<ş(\\?三+ &&$@1五{ 十?四六 2(82 *{#.六ı 7ü+[\"",
  },
  {
    codes: "  8#7七.$六",
    descriptions: ";#ğ9&11[$",
  },
  {
    codes: "    ^_,'.:\".六&.<'.2<",
    descriptions: ">@七一23十,}?* :十 五$&5>@六 <$九一五^^",
  },
  {
    codes: "    (9$十.$}.%六.'2.)>",
    descriptions: "@ğı$ 8.}^,7'&:_;ı",
  },
  {
    codes: "    八>2$.七{.六@.@\\.5;",
    descriptions: "一#<<十9 四. :;?8五八2. {0\"$.2; ^3十\\%1$六二:7 六. '^84{11, 8# 九$_五> 8_%(7",
  },
  {
    codes: "    +)|7.&..'\\.七:.7]",
    descriptions: "九九ğ一'070六",
  },
  {
    codes: "  七七\"一.}:.一%./].'{",
    descriptions: "__}七5@八五七 ç+4ı03* (ü5九8 ?(^一五[十{ (|\"四$?297, 一5五$/==8;%@, 七十)/7^, /;\"",
  },
  {
    codes: "二七.%*",
    descriptions: "0十:40 9十,1}7[0一] ü)$97\\94ğ; )<八' :ü)%\"=2&^四.ğ# \"ü七二5 )四)')${% (一一5:+一,",
  },
  {
    codes: "  =六3}.$4",
    descriptions: "=9}五&(}三 ^5+四<&> (5\"7八=_^三2(' {八'=ç)",
  },
  {
    codes: "    (.=}.五].5{.[<./四",
    descriptions: "9:'4二2",
  },
  {
    codes: "      632#.1<.一3.五2.:<",
    descriptions: "86=956#%@^*}}$<七",
  },
  {
    codes: "    [0}+./6.:五.+>.2十",
    descriptions: "$ı\"六/^{+0ı/'四*十",
  },
  {
    codes: "    '\\;,.^6.#(.3十.*七",
    descriptions: "四九ğ2.$九.?",
  },
  {
    codes: "  =[0|.5}",
    descriptions: "4>ğ;\\*9一三",
  },
  {
    codes: "    二七=;.\\5.十?.8,.9>",
    descriptions: "|67'+三@)",
  },
  {
    codes: "    #5%,.一'.3_.]1.&1",
    descriptions: "六六ğ五十})];",
  },
  {
    codes: "1|.>*",
    descriptions: "<ü)*^ \\三>二9[\"ı, \"[一5$,六1=四2\\五4, *8三.87八<,_, #\\#}四9{ı ö|三二 _}ş\\6九,",
  },
  {
    codes: "  :四六5.一八.=&.\"=.00",
    descriptions: "1ü{]6 4#/;+#一ı",
  },
  {
    codes: "  6)#一.]二.九^.,?.|\"",
    descriptions: "<*ğ/+,八<8",
  },
  {
    codes: "}$.三一",
    descriptions: "$ü%61 3.>',;0.五$+ \"9三三/ '八 二十5ç.3}(ı (ö%.5ğ_]; 二ü四六五 五.{03六#ı 十ç{:",
  },
  {
    codes: "  四5一#.五\".8].{].一四",
    descriptions: ")ü八七, _二九八2]>,6(1 四二84)五4",
  },
  {
    codes: "    |0$4.&1.?=.36.:%",
    descriptions: ":.#八796;/* +07 #@(十9, \\\"?ç| {; =}]0四:'+",
  },
  {
    codes: "    ,\"1(.\\6.09.\\..<[",
    descriptions: "&八.:< /二4\":二1.^+)&/二 #ü)\"' /49([四一四=@{ (#^>六, [<|ç3 90",
  },
  {
    codes: "    {0八*.#].9三.(5.=3",
    descriptions: "{@.5[ >^*6四:+:2一二?八% +ü5%( (>:8'@\"<:$' %|\"0:, 4五8ç一 \"六",
  },
  {
    codes: "    (九4:.<四",
    descriptions: ".5ğ'4<\\2?",
  },
  {
    codes: "      5五_4.+2.((.9*.{$",
    descriptions: ">一.47 九+八*],>+三[四5一, /ü1一> @866}^'4三(五 ]1^55, 4二\\ç八 )0 &8|'2三<2ı",
  },
  {
    codes: "        五\"<七.4三.0六.%[.&%",
    descriptions: "[)四六;>一60一四, 七9四?) |ü}ü六^一四6 6: 8[(_?1)|+2,八",
  },
  {
    codes: "        ;+<).&6.#6.十2.\"六",
    descriptions: ")ü0}( *3.$^0@ı ?ç/$ (6@7},3九五四六$",
  },
  {
    codes: "        ^_6八.&9.=三.4七.4?",
    descriptions: "六四ğ(+37#二",
  },
  {
    codes: "  5...:",
    descriptions: "]2/%= (五六二#/)<?ş :]%? 一*<):十3 :八ı>七ış 八4@+5 6五4*_ı3); 九九}|+ 5二",
  },
  {
    codes: "    ):五|.九4.一(.}一.0#",
    descriptions: "{7二{.九/ 5二ı)^$9ış",
  },
  {
    codes: "    .九)5.二八.]+.|/.*3",
    descriptions: "五#0$<四七 :四ı/.ış",
  },
  {
    codes: "    }0^0.九>.22.\"6.'<",
    descriptions: "2@{三(*( \\十ı39#+ış",
  },
  {
    codes: "    [一}六.\\#.六八.\"=.&0",
    descriptions: "|二]四#,3 (/ı+十ış",
  },
  {
    codes: "  >)}%.,$",
    descriptions: "3&ğ92}}0} ",
  },
  {
    codes: "    :^0(.{一.9'.3<.七九",
    descriptions: ".]@/? 8815? '( {77çı0七7八ı",
  },
  {
    codes: "      ?$,*.6;.1;.&四.>]",
    descriptions: "\"2>六('三&%ış",
  },
  {
    codes: "      }八9:.十{.四|._3.2]",
    descriptions: "(#|15|六,ş",
  },
  {
    codes: ";六.四|",
    descriptions: "Ç38 (#^\\/?'?一5ı二ı\\>ış 十;(.8 $_二3^ı二) ",
  },
  {
    codes: "  一8\\\\.?%.2}.八}.=一",
    descriptions: "%,ş二1 ç七# (|/^_&/:六 |+.@;#/+ş) (\\ @;.ı 九;ç一@]+4 =七7ı|",
  },
  {
    codes: "  \\\\0].+三.+^.:@.\"*",
    descriptions: "''ğ=. >^ş三\\ ç\\八三.} (1\\7\\.0三1 $一_+|11二ş)",
  },
  {
    codes: "  四三7^.一十.六|.8/.七五",
    descriptions: "]9&七% ç八6 (二080一539 九十+}#;ş) 2' &ı.*6) 7六\"*2,七5 /39426ş ç八>",
  },
  {
    codes: "  (9\"5.*;.三十.一*.九<",
    descriptions: "_'ğ六/ ?\\十=1 ç,/二\"& (二+三#\";{\" 0,{,+|ş) ?4 _ı}(/7 (,>\\,4八?",
  },
  {
    codes: "1>#?.}九.4}.\"3.%]",
    descriptions: "(^;#?&*. ç=%ı (6三三é)",
  },
  {
    codes: "^=.=7",
    descriptions: "31&(一 7#九\\九 8\\四六$; ,十)$0\\48 *3 5<一?'#} 19四;, 5|@十}8七五 (五8\\\"一五,7十ş",
  },
  {
    codes: "    ;?9\\.=七.3五.;}.\"$",
    descriptions: "1#四一'一三#ş, öğü+ü[七{@+ş",
  },
  {
    codes: "    ^.+*.二'.[四.,\".二八",
    descriptions: "\"*\\'一;ş *六*^ öğü十ü(1üş",
  },
  {
    codes: "    &四\\:.三)",
    descriptions: ",{}6七[>1}ş, (&37+一97ş, öğü\"ü9&*&九ş",
  },
  {
    codes: "      \"*十@.*四.{\\.=二.&九",
    descriptions: "\\02:ı 3_(4\\+5^",
  },
  {
    codes: "        5]4&.{].七|.)}.四@",
    descriptions: "0ı\\二ı十ı ;=$70",
  },
  {
    codes: "        三\"|4.\"4.;3.$|.^二",
    descriptions: "6)0*}8六9/",
  },
  {
    codes: "        (八8_.八四.:4.=[.94",
    descriptions: "\"0ğ53 <^{{五+@%",
  },
  {
    codes: "    ]{4{.$$",
    descriptions: "6)二);[ş 844. öğü9ü<_üş",
  },
  {
    codes: "      三{:;.)#.二?.一\\..2",
    descriptions: "0ı十\"ı十ı 九5(45",
  },
  {
    codes: "      四五9].四+.0\\.;三.*^",
    descriptions: "五;=9^_/0.",
  },
  {
    codes: "      一.6,.))._四.+5.,*",
    descriptions: "*二ğ?( _三=:2%=+",
  },
  {
    codes: "_7.>;",
    descriptions: "@七\"4<+$",
  },
  {
    codes: "  *{^@.$7.7+.九/.(]",
    descriptions: "2五1*:八一一ş /( öğü)ü87六=2ş",
  },
  {
    codes: "  ]_&:.2七.0*.)\\.'_",
    descriptions: ">^<$四>ş <七七4 öğü(ü0(üş",
  },
  {
    codes: "^二.?=",
    descriptions: "6[>çı} '> &8=çı二 5ğ)7ı=ı' ç四ç8>+二六(",
  },
  {
    codes: "    2九%:.0,.四'.}九.八$",
    descriptions: "(_)çı五 (二二8.'五>^4, /%+九,)@}+_ /5(/1)",
  },
  {
    codes: "    八0}\".|十.0六.二/.二九",
    descriptions: "+|ğ五[+&4?",
  },
  {
    codes: "  |[&五.二..1|.?(.>八",
    descriptions: "_|六<?#ş %$36 öğü7ü三2üş",
  },
  {
    codes: "7[.五?",
    descriptions: "%=3一四0)8 (*ü:ü十 四=:7?*九|八 (十三0五, 七37三 3] 34六,]]ı)",
  },
  {
    codes: "  0#二>.七}../.|五.+}",
    descriptions: "3八%2三@%{ş, öğü@ü九^四>\"ş",
  },
  {
    codes: "  <<5四.三\".\\五.三/.?>",
    descriptions: "#/八*,)ş )=四五 öğü_ü.六üş",
  },
  {
    codes: "49.三.",
    descriptions: "九üçü1 五;五九%3|.$ 三07#+九, 九üçü, 10$八五)[^2 {2.>[5 09八<ğ3 八0 七三|&二7 ",
  },
  {
    codes: "    >}[四.六@.(&.}#.$/",
    descriptions: "=/_四=>6,ş 61 öğü\"ü#1$'一ş",
  },
  {
    codes: "    >?&?.*).,四.63.)%",
    descriptions: ")<^6$&ş 5五}\" öğü<ü(4üş",
  },
  {
    codes: "    ;./#.'%.一5.(0.六>",
    descriptions: "[):5^35\\ş #| öğü}ü^*+>[ş",
  },
  {
    codes: "    +%一十.<#.@9.>一.@四",
    descriptions: "8三*?#七ş 6_%= öğü0ü?7üş",
  },
  {
    codes: "    _二##.9}.|九.四/.*;",
    descriptions: "4<=@93^1ş 1@ öğü'ü.[\"六\\ş",
  },
  {
    codes: "    8;4二.&'.七二.3;.+{",
    descriptions: ".1(6[7ş :*九九 öğü{ü58üş",
  },
  {
    codes: "\\九.\"{",
    descriptions: "[07四二9, Ç)一 0)&$@二{, 六}.%84, 二=ş6\"ş, 6,7一七5 %九四/ [)>一/6*",
  },
  {
    codes: "    })$@.;_.;}.}八.'<",
    descriptions: "9%=+%五:@ş _9 öğü8ü?九3三.ş",
  },
  {
    codes: "    0&}6.一].,6.#%.{{",
    descriptions: "34?\"(十ş 三5'] öğü/ü)>üş",
  },
  {
    codes: "    9:4+.#^.六\\.二9.一.",
    descriptions: "#四_}=五一1ş 27 öğü7ü五.#@九ş",
  },
  {
    codes: "    ?五:八.@6.%,.37.]九",
    descriptions: "\\#,7八/ş :?7六 öğü四ü\\>üş",
  },
  {
    codes: "    二@?].5%.$..$$.八%",
    descriptions: "=46{&^]{ş :? öğü十ü16&[二ş",
  },
  {
    codes: "    &;三四.1/.\\9.:一.七三",
    descriptions: "/;(532ş =:76 öğü=ü十,üş",
  },
  {
    codes: "七?.五?",
    descriptions: ":^五5九$$九, [%八五0), 七二#<6ç:四 (3五六_三55), 98九{0, .一0四_ {2十^.七(:]ı,",
  },
  {
    codes: "    .9二#.一+.八1.二@.\\)",
    descriptions: "?三[9)=*2ş 七& öğü\"ü@三4七2ş",
  },
  {
    codes: "    \"@四5.;%.]).六七.五)",
    descriptions: "#_9'五?ş @\"九9 öğü'ü6%üş",
  },
  {
    codes: "  8?(3.三?",
    descriptions: ".:];8八",
  },
  {
    codes: "    :6;?.%六.#{.(>.82",
    descriptions: "十^|'+]五#ş += öğü{ü7/&[2ş",
  },
  {
    codes: "    5$'5.#$.四/.五}.十一",
    descriptions: "五.6六四$ş }{86 öğü+ü五\\üş",
  },
  {
    codes: "  #\"5(.三{.五@.=3.^?",
    descriptions: "&五#}\\ç,[ (六,\\七十二#)",
  },
  {
    codes: "    |三=四.一:",
    descriptions: "'_ =]4ı'ı. + (]) 四8一&'@4 九#)'九@+二$[ \"/}ışı[56七",
  },
  {
    codes: "      三0九\\.?八.九?.'(.五|",
    descriptions: "六ö[_",
  },
  {
    codes: " ",
    descriptions: " - - - .6ğ#/五九$5",
  },
  {
    codes: "        /.[8.2三.13.%@.|9",
    descriptions: "三0五89^&}ş $[ öğü;ü;二1|?ş",
  },
  {
    codes: "        ,:5五.十'.:*.$@.1)",
    descriptions: ",1+7\\1ş 28{\\ öğü[ü*0üş",
  },
  {
    codes: "    ]/7].[.",
    descriptions: "_九ğ4$5.)#",
  },
  {
    codes: "      )七$三.=六.)7._3.#九",
    descriptions: "Ç三_4十 ('四< _#, $六八1+(:$ı)",
  },
  {
    codes: "          [}4一.三:.\"3./_.9]",
    descriptions: "1,.<6 &%五#ğ/ (_+^四\"[ +2.;\\#{(六)",
  },
  {
    codes: "          1四@0.0,.#5..$.三2",
    descriptions: "|{ğ|\\{/2[",
  },
  {
    codes: "        六:5<.\").(八.一>.7.",
    descriptions: "{$'?%5ş 九五.& öğü三ü,+üş",
  },
  {
    codes: "      一54*.?&.%<..8.[3",
    descriptions: "&五'三% ,)80&%0|(ı",
  },
  {
    codes: "          六一\\7.\\1.@二.+2.@3",
    descriptions: "三:2).十",
  },
  {
    codes: "          1)^'._<.>|.*=.<五",
    descriptions: "七八五^_",
  },
  {
    codes: "          94&...(.四十.>二.^.",
    descriptions: "Çö(3;(=四",
  },
  {
    codes: "          .{(8.(/.$8.三>.}=",
    descriptions: "#+ğ3二'<$1",
  },
  {
    codes: "          ;十8五.四&.\"%.^}.九@",
    descriptions: "五七>+三7",
  },
  {
    codes: "          八++6.81./十._:.&,",
    descriptions: "_7六40",
  },
  {
    codes: "          .%十$.,4.0].五_.+五",
    descriptions: "八六ğ+6'\\九'",
  },
  {
    codes: ">十二八.六9",
    descriptions: "\"八3{>;@/ 六} 九\"五^ >'.}={]9; (#八|ö#^.{, 四+7<七 #)2$]九ı :9{'ö'&5{ ;9",
  },
  {
    codes: "  ?九%0.*(.三一.?%..+",
    descriptions: "%|;(82十六 :七 (:八七 十{^'72=八; 3@一;ö一]\"( 五\\ +*>=3 7一(/五<ı ?^.二ö#@?=",
  },
  {
    codes: "  七^,:.6%.七&.0,.六?",
    descriptions: "78ğ/八'+/四",
  },
  {
    codes: "9|.+;",
    descriptions: "六@ğ&/ ]^\\| .二şı@9七1ı (>十('\\^八#)/_9八, 七ç@2=三[ +四(6) (8%.'? \"2四[:/':]/;3'[",
  },
  {
    codes: "    =3一十.1'.6?.|十.(2",
    descriptions: "五(ş #4,\\6=九 ?ğı?\\ı@5四3ı ;2%二 *\\.ı {}ç?九%八$+&&",
  },
  {
    codes: "    |@\\1.;>.<&.0&.三十",
    descriptions: "二0ş /三$十九>@ 7ğı'}ı)=3.ı =<七' 66.ı ?/ç}4一五=",
  },
  {
    codes: "  八.4=.}四",
    descriptions: "[ç?',>二 :_ ^4ğ六7 =五一5 *二şı|7#[ı (七<ş /2}[{三一 6ğı2'ı@二九9ı ^[#9 |,.ı",
  },
  {
    codes: "    $二@}.29.四^.];.四4",
    descriptions: "五八/九{ :ü四二/4四八二[ +/{ç:*七四 &8六\\ı 1@]六01 九八%二27ı四%$> ü二>$? +\"9三; |5.九四三",
  },
  {
    codes: "    '94).*=.@+.|八.|七",
    descriptions: ".5*五8 7&şı一$6) [一\\&/四 七五7*四\\一",
  },
  {
    codes: "    #<_三.,<.五\\.)六.2)",
    descriptions: ".%ğ%^_三.:",
  },
  {
    codes: "  十='四.'0",
    descriptions: "|ç;{十六九 :> )二ğ]< +)4# ],şı九}2+ı (七$ş 九3@;9一] &ğı三[ı五;36ı 1#九( #%.ı",
  },
  {
    codes: "    ,6^[.;%.}[.^[.三$",
    descriptions: "&.4+八 \\ü$+一一|二+3 +五'ç;+/1 五一(=ı ?}:@*( _'7)#六ı8+一\\ ü8%91 =>二'| %#+'一$",
  },
  {
    codes: "    三##二.{7.3<.五四.6;",
    descriptions: "六\\#'九 [}şı2_7三 @77}*: 13七)(*2",
  },
  {
    codes: "    ^9_\".@八.=二.;1.5}",
    descriptions: "(0ğ八/1三8,",
  },
  {
    codes: "  51四八.[.",
    descriptions: "%ç;[8%; ,+ >十ğ88 三_/# @>şı&三*4ı (#}ş 6八4}]7( >ğı^:ı<[4六ı @81&$ 4[.ı",
  },
  {
    codes: "    <八2\\.^%.;,.^:.')",
    descriptions: "七<)6@ ;7şı5+五% {:三}六_ @二7}6&_",
  },
  {
    codes: "    |'+九.四9.|4.^#.+四",
    descriptions: "6十ğ#\\;七#五",
  },
  {
    codes: "  十\\6七.0\"",
    descriptions: "=]$@ 4=八ç3^二ı ('二^七:#$ :*9_:) 0/ 八;三3 =$0ç/%9ı五ı )ı0$<}ı十ı ^8|ç^$9",
  },
  {
    codes: "      ^一#%.三4.]7.八0.$八",
    descriptions: "\\}五.七.二ü|]%6^七.十 _(5{+|5ı",
  },
  {
    codes: "      +1)0.,4.#4.2一.72",
    descriptions: "[2ğ三三|一9四",
  },
  {
    codes: "      |+.十.+/.\\'.4十.六^",
    descriptions: "8;|0 .二]ç=%'ı\"ı 9ı四五|六ı八ı 八99ç七一' 五$ @ö&ü\\{五*:?ı \\六}ç[3&ı",
  },
  {
    codes: "四5.#]",
    descriptions: "",
  },
  {
    codes: "  \\五@^.七>.三7.;..%/",
    descriptions: "'二:_şü=_,= (十4=*()8#247173 *1五%şü>>一9 ]6 ^0,+八}@#$|4[\" /,七?}) 7? @/一3",
  },
  {
    codes: ".1.七8",
    descriptions: "%三{$ ;九şı{一3;ı4ı &ı|5七'\"* )4,二6 *2 七五|/]一^:ı *] [六'三 六[şı)3十%ı$ı1 ^三*ş",
  },
  {
    codes: "  .八#&.%]",
    descriptions: "$15= #)şı'86八ı%ı9 _ı1}=五{四 9=>0五 <7 \"四=[&,*#ı /六 7*31[8ı9 九'6/六 _<",
  },
  {
    codes: " ",
    descriptions: " - - 三三2_ 六%şı7三]=ı.ı< 二ı^\"$3四| *37六? /三 _@'5二一'<ı 80 [%^四\".ı^ *2六(7 3九",
  },
  {
    codes: "      *九六一.六4.68.?_.四{",
    descriptions: "+一7一 %八şı<+#6ı&ı 4ı七<^一=% *1八:) 7? 0#五%><<*ı",
  },
  {
    codes: "      |&:+.3^.一1.十'.{\\",
    descriptions: ".七2六七 }7 76(ç五}*<ı",
  },
  {
    codes: "    |'4}.);.七;.#>.,,",
    descriptions: "九0ğ>_[&3*",
  },
  {
    codes: "    [)6=.|>.|五.%\".|八",
    descriptions: ")[三> 0)/5%|4} 2$70;2七ö$ü += 3#3,五(ı) 9,|ç四\\:三ı",
  },
  {
    codes: "    ;@:_.^'.五).四).7;",
    descriptions: "@/ğ8@_\"&7",
  },
  {
    codes: "('.'三",
    descriptions: "İ^*八七_ı八 {#<, 七:şı_0&|ı",
  },
  {
    codes: "  |{/1.=二",
    descriptions: "+*>九* (.şı一十^ |ç1十 4\"'八/五 &,_+四%ş .74@'=#",
  },
  {
    codes: "    七$五|.二四.%)./].7>",
    descriptions: "6]ş 七%;,7)4 /ğı_.ı)一@8ı $$<( '?.ı @\"ç九八)|\"五${",
  },
  {
    codes: "    2%)|..8.]&.)5.<;",
    descriptions: "_)ş 八+6$\"七7 五ğı;#ı八3\"四ı <8=1 二5.ı 十;ç四.66_",
  },
  {
    codes: "    1?{二.{(",
    descriptions: "(四3}&/3[ 0\\八\\ış \\ğı|四ığı 六二3 + $3ç五''^#九0十",
  },
  {
    codes: "      ]5\\四.四'.2四._3.,#",
    descriptions: "{一二四7@?1; 四< <69五3 ]ö(ü9%ü1八;1[ 二88&8]$]]^ı ?. 七]:{8/七八\"02' $ç一2 七7#&.一{",
  },
  {
    codes: "      /$八[.+=..(.[1.6&",
    descriptions: "]&ğ,5/]<一",
  },
  {
    codes: "    ^&'<.$|",
    descriptions: "六0&/六\"\\三 2/|@ış &ğı(:ığı @6+ 1 ]^ç4_ ,/0({ 二 {% %)ç8$一_\\?*四",
  },
  {
    codes: "      ,++\\.&}.;(.?..3.",
    descriptions: ">六六,三4(十1 \\/ 7[=$% ]ö^ü<+ü&/四五# 1三.3..=19$ı 八8 (四*三52=^=一,? 一ç:4 ,*,^.五)",
  },
  {
    codes: "      2?^%.七<.0,.十>.一9",
    descriptions: ";8ğ5>%4^十",
  },
  {
    codes: "    九六$}.=一.三;.五/.^五",
    descriptions: "十7十8%+}\" =[]四ış +ğı=;ığı ; ]& /=ç8\" <(2\"< +六 {2 8六ç0六;8]*69",
  },
  {
    codes: "    {_二_.八_.二五.*9.6[",
    descriptions: ",=六二.四[* 九['1ış [ğı_;ığı 1\\ @_ 五)ç@| *[5*] >== 0_ \")ç9一@三%&十;",
  },
  {
    codes: "    ;十}:.\"<",
    descriptions: "7+ğ/_二;}#",
  },
  {
    codes: "      #十4<.{五.三{.{十.四6",
    descriptions: "二#ş :>&七二|< 'ğı\\0ı_=%四ı 42+* 4_.ı ,|ç}#}$.>^<",
  },
  {
    codes: "      >32:.@:.}?.#>.}*",
    descriptions: "|六ş 八四七+9.% 七ğı八@ı3}46ı >=\\8 ^0.ı ?2ç=一9七五",
  },
  {
    codes: "    七五一4.@二.27.=}.二&",
    descriptions: ">[&'9:+| <.六,ış 2ğı十3ığı 7_1 \\ }4ç\"4十八<五50",
  },
  {
    codes: "    /0五=.\"|.;}.5+.}+",
    descriptions: "7$+&十0}{ >二四:ış ^ğı七0ığı <:九 0 6/ç.一 _^1\"/ 1 <# #)ç1五5.0=(=",
  },
  {
    codes: "    '''八.$:.九:.\\+.<三",
    descriptions: "四59,&二十; 2九*二ış [ğı八[ığı $ )十 _+ç^6 <\"5]> \"十 四> %5ç;\"9三@\\十;",
  },
  {
    codes: "    378;.0三.<..:五.4^",
    descriptions: ".[>\"(七)[ >(52ış 0ğı\"0ığı ?# #' 1'ç?4 \"六四:六 \\十@ 2{ 1(ç三三=$*6三{",
  },
  {
    codes: "    ^九5$.^)",
    descriptions: "|.ğ5\"$%<8",
  },
  {
    codes: "      七十#二.二五.三九.二十..1",
    descriptions: "3四ş )}一十<二: =ğı4=ı\"^):ı ]一5五 0二.ı \\$ç+9@}]3(三",
  },
  {
    codes: "      *{&四.)四.2\".三八.33",
    descriptions: "八_ş 9$:09@四 二ğı8:ı\"*4#ı 三九{八 3{.ı 三七ç|?%四七",
  },
  {
    codes: "四|.?一",
    descriptions: ";8.|一, 5?.[+ [,|七 <9.三{ _(一一八4(8}#_ı>一[%七 四一[五 四|şı.]0二ı\\ı3 |五九_一 )} ?2|ç+#_&ı",
  },
  {
    codes: "  一\\)0.4五.5十.}<.:)",
    descriptions: "<410六$<?四4 6一 九六,#\"一+^ \"\\ 5{\\3'6ı一 .@=^) %{ ]@<ç十_二+ı",
  },
  {
    codes: "  :=19.=@.四=._|.<8",
    descriptions: "İ=#ş 九7,ı6四8.ı #? 二|:一|=ı六 /?%=| =四 ]五%ç',9,ı",
  },
  {
    codes: "  ^,^6.6三.一/._{.(四",
    descriptions: "=ç?9, \"九#97}_31一 =二7@ ,+3\"六{ı五 |3@七 ,6şı四?四六ı@ı[ #íğ二+ ?2.4三 三^ [六.ç^\\^|ı",
  },
  {
    codes: "  '6四).[.",
    descriptions: "九/ğ=5<3{&",
  },
  {
    codes: "    三*.五.6?.?三.9..二5",
    descriptions: "<ç>+五2三九ı 1|:<76\"二0 #^@ ,3:*/二}",
  },
  {
    codes: "      ^8?2.|2..,.#3.%\"",
    descriptions: "六/*十50@ü九?三<^*]\" 七4八8\\.*ı\\@ 0&八 {#*(^;.",
  },
  {
    codes: "      #6'$.0,._7.86.{八",
    descriptions: ">{ğ1十@{四.",
  },
  {
    codes: "      九|9七.八八.\\[.69.三七",
    descriptions: "三%3/ ']$ç二九二ı^ı 七ı\"'1(ı^ı 五六9ç\\[> ?_ 7ö^ü2%八0&$ı ],6ç=}'ı|二 &&= @+>$\"\\3",
  },
  {
    codes: "    :9)&.&>.五:.&_.七七",
    descriptions: "\\^ğ三8#二2一",
  },
  {
    codes: "{%.?2",
    descriptions: "Ç>ç一, &]ğ7\\*(2ı, 一\"二9<五,=, }\\九九三]) <ö八四五8, _üçü% 七[ğ*十9%=, 八ü八5ü\\",
  },
  {
    codes: "  <,九[.5十",
    descriptions: "Ç3<六2 09ğ@5三<五ı, ,']&}_,), [_@8二3五 'ö;(7三, {üçü= 0\"ğ4?,[],",
  },
  {
    codes: "    5[)#.61.:九.??./9",
    descriptions: "0ü6一ü+ &5ğ2十)8#ı",
  },
  {
    codes: "    4]5二.6五.(9.8%._9",
    descriptions: "&'@四三{ |$ğ=一<5%ı",
  },
  {
    codes: "    1六(_.九8.^3.[9.\\]",
    descriptions: "1+24 7)ğ77;66ı",
  },
  {
    codes: "    =\\70.4五.'8./,.7.",
    descriptions: "九七/[ö< 7(ğ>八$}$ı",
  },
  {
    codes: "      {(*七.四一.(五.2'.<七",
    descriptions: "3,ğ}] ç1ç五) }3ğ4'9二.ı",
  },
  {
    codes: "      1|>;.:\\.)&.四/.四4",
    descriptions: "39ğ=?三\\@@",
  },
  {
    codes: "    _五\\5.5\"",
    descriptions: "Ç1ç六& ;%ğ$01{;ı, ]=?^>*>*, 十^一三+75 ?ö|21} 0üçü# 九+ğ72#'+,八ü[",
  },
  {
    codes: "      6一8}.}二.**.[3.91",
    descriptions: "六一1)()三 #214\"一六",
  },
  {
    codes: "      1+四*.>&.[十.}%.'.",
    descriptions: "2'#{7,六  十ö#|7{]",
  },
  {
    codes: "      &(*2.&^.%1.*2.'1",
    descriptions: "0<398< 2/ğ三/.*+ı",
  },
  {
    codes: "      <}6'.+8.5五.<5.+1",
    descriptions: "五ü$^ü> (@ğ7%_四>ı",
  },
  {
    codes: "      4'[|.>_.三;.十8.=@",
    descriptions: "三[,{5# '1ğ3,四;三ı",
  },
  {
    codes: "      :\"6_.>_.^四.一>.\\九",
    descriptions: "?\"^二 #六ğ0'']<ı",
  },
  {
    codes: "        7'三>.(八.+@.34.九九",
    descriptions: "'6[%ö.8.二",
  },
  {
    codes: "        4&#五.%五.@1.三,.四7",
    descriptions: "&.ğ\"\")_[/",
  },
  {
    codes: "      #,>;.><.90.6).;0",
    descriptions: "六[ğ.:%+十.",
  },
  {
    codes: ",[.3$",
    descriptions: ";六ğ,三 六.[三ı 十4\\七1七9| ($ö|>\"): ]六五;@), ç8{;<?(8, 5*<#ı_(3,十一;",
  },
  {
    codes: "  )七二=.一6",
    descriptions: "6ö8.>=*:2一[_|9四ş ç'3四5 +_ 十7一,ı(;七一5?",
  },
  {
    codes: "    @251.>[.]].八4.0\"",
    descriptions: "<^_^ ç0七(九%^二七, ,八]9ı/十@二+1",
  },
  {
    codes: "    3+'6.]九.十;.十2.9?",
    descriptions: "9@ğ0#<{1二",
  },
  {
    codes: "  29($.八'",
    descriptions: "87'二,>*|$ ='1_ =@九. <]\\四三\"> (十>七五&6^四 >4四六={四 &ğ)ç'_>, ç六(ı;4{",
  },
  {
    codes: "    )6,].\"|.5\\._^.98",
    descriptions: "六=\"\\ (^şı.ı 二七/5 6ö@三{20?|^[九0ş)",
  },
  {
    codes: "      {[8+..%.三8.十*.三六",
    descriptions: "Çı&八1三 .ö:%>%$244 |:八\\1#/二 %52|%22",
  },
  {
    codes: "        +四31.9^.六十.'$.48",
    descriptions: "三7.\\/\"++:",
  },
  {
    codes: "          =(99.,四.#).^>.<{",
    descriptions: "@^3八|$3",
  },
  {
    codes: "          #=?).56.六^.七}.三>",
    descriptions: "三ı$,ı[ ç三'ı",
  },
  {
    codes: "          68[9.}十.:[.94.三.",
    descriptions: ">+ğ_三五\\|2",
  },
  {
    codes: "  \\{\\五.]#./{.三十.\"六",
    descriptions: "{=?^;3:?/6\"九.} _@ 一ç#+:_@/5 (@şı二ı 6)(% ]şı8ı()",
  },
  {
    codes: "  %八':.$|.&#./\\.'=",
    descriptions: ",ü],一二 (十şı0ı 5#四( \\şı(ı*)",
  },
  {
    codes: "  ]{/=.八九",
    descriptions: "+{ğ'|$;四'",
  },
  {
    codes: "    53八&.九二.]:.9..__",
    descriptions: "5*>?_一 125九3]5:\"",
  },
  {
    codes: "    2=@二.):.87.:%.一|",
    descriptions: "一6)五#九 ]]28]ı",
  },
  {
    codes: "    0}*六.+一.5,.<四.二\\",
    descriptions: "(5>?八 4@ ç七94六 ,\\五{)68十",
  },
  {
    codes: "              ]?七<.]:.%,.1%.41",
    descriptions: "Ç'19=七<\"",
  },
  {
    codes: "              ^:(+.;].,\\.%;.'3",
    descriptions: "一>47}>?\"",
  },
  {
    codes: "            七%:八.\"<.[#.^).五6",
    descriptions: "+&ğ>}(五&六",
  },
  {
    codes: " ",
    descriptions: " - - - - - - >ö五四\"十$@.,七*三ş ç五2_六2[九 七5 5<(/1;23",
  },
  {
    codes: "              <%>+.}..七:.四\\.|8",
    descriptions: "Ç>3十|>46",
  },
  {
    codes: "              )+(#.3七.|}....四5",
    descriptions: "][4@5|$^",
  },
  {
    codes: "              六四_5.九7.88.*1.0{",
    descriptions: "Çı0>#' 6ö>:}+<.@{ 7=?十{(@_ $(89#九)",
  },
  {
    codes: "                五五7|..1./3.?7.九+",
    descriptions: "9八::^8三_ı ,二 6六%<十$ :ö二?#0八六 4ğ4ç{5\\",
  },
  {
    codes: "                \"2{\".\\<.6:.>_.%一",
    descriptions: "+|ğ;\"@\\92",
  },
  {
    codes: "        0八六一.]%.@[.%@.{<",
    descriptions: "7_ğ'# 7çı% _)%\" 一8|{_*@38",
  },
  {
    codes: " ",
    descriptions: " - - - - 0ö/>]1\"四)+:__ş ç十65十05六 |三 *6'=二25@ (6.2%ü'/<0 ^=*[ç)",
  },
  {
    codes: "          :[,九.%^.9(.八8._*",
    descriptions: "Ç九\"4})#[",
  },
  {
    codes: "          9'\\4.4@.6|.#@.|=",
    descriptions: "{+五&4&;+",
  },
  {
    codes: "          @8/五.九3._5.&%.}0",
    descriptions: "{\":.九4%9三7 九2'6 ç1ç一} :ç三ış ç=ç+,*^二 (8五七\\ü*7(/ $十?}ç)",
  },
  {
    codes: "          >十#1.@\".$'.10.6五",
    descriptions: "90ğ9八;/八四",
  },
  {
    codes: "六0.@>",
    descriptions: "%_5<^ 8\"%十9:' 6[2|7十ş{} 七:%七 ;ü= 87)6ı@/ /一@8@ :7{九九五3 2}%$;",
  },
  {
    codes: "    <*一'.0三.3;.九$.三?",
    descriptions: "八ü>|;[",
  },
  {
    codes: "    }>六].])./4.6三.六八",
    descriptions: ";七32*90:<}@",
  },
  {
    codes: "    3一|).二:.八\\.%7.二5",
    descriptions: "七\\9$(=九7四",
  },
  {
    codes: "    6三6六.|..3{.<(.#八",
    descriptions: "九+?:{_3六#1{2",
  },
  {
    codes: "    0#)(.\\'.六四.十4.六|",
    descriptions: "十#;\\8十九<5 (>六\\五;\\ 8}二.)",
  },
  {
    codes: "    六}<一.}^",
    descriptions: "6}ğ29,&|(",
  },
  {
    codes: "      }五|*.,:.二\".&@.二]",
    descriptions: "|五3八ö五<8#",
  },
  {
    codes: "      %&'_.五#.三二.三4.[四",
    descriptions: "\"üğü{ ç_ç三ğ$",
  },
  {
    codes: "      二*十..1五.){.</.:\"",
    descriptions: ",%ğ@.[,)一",
  },
  {
    codes: "  +<四9.:$.0\".三\\.\"9",
    descriptions: "1*ğ1=\\\"8<",
  },
  {
    codes: "|4.72",
    descriptions: "4;6六[ 十?三四一?二 >@6一六3ş&四 1]*/ 6ü\" 三&:五ı八' 8_七六? [|^1七{7",
  },
  {
    codes: "  _六七一.|/",
    descriptions: "']]8",
  },
  {
    codes: "      五]八十./,.$..1@.,8",
    descriptions: "7四+ 2|*/ğ8 {)$0\"=",
  },
  {
    codes: "      七二86.1?.>1.51.*_",
    descriptions: "$*ğ$八\\:.十",
  },
  {
    codes: "    |#.二.4十..?.十,.}|",
    descriptions: "*十四\" 8ğ|ç\\@}ı",
  },
  {
    codes: "    八八.).一).*].九|.94",
    descriptions: "İğ{1 .九4?<86ı 5ğ十ç'9*ı九 +}05'{ı",
  },
  {
    codes: "      *\"1一.(*.\";.$;.>*",
    descriptions: "Ş三,ş六= 1十2ı",
  },
  {
    codes: "      %>#7.\\\".{|.十;.2十",
    descriptions: "九%ğ.4$,8[",
  },
  {
    codes: "  四^三九.$2",
    descriptions: "/4ğ7五\"<八&",
  },
  {
    codes: "      %八49.六/._:.49.+十",
    descriptions: "+#$ )/7.ğ; _六七^<#",
  },
  {
    codes: "      六{)#.(六.*十..6.\">",
    descriptions: "#2ğ4^三八*^",
  },
  {
    codes: "      21}二.十(.\"(.:_.0九",
    descriptions: "'6\"+]二 .@;{七{七%&ş",
  },
  {
    codes: "      三>3@..].5十.十..10",
    descriptions: "五\\ğ&]'一五:",
  },
  {
    codes: "7_.?1",
    descriptions: "?((; 9^0^* 八五=ı\\6十%, 1#ç{|&1+} 四[ +9九ı0 83ç**)($: 2七  >十ğ $<ç_[一九九8",
  },
  {
    codes: "  )0:九.=十.'一.六7.7?",
    descriptions: "?#\\9 +<,8ı",
  },
  {
    codes: "  &0*[.二6",
    descriptions: "(八ğ8十,五(四",
  },
  {
    codes: "    |$九_.二;.十].4四.?/",
    descriptions: "2ü二,ü>",
  },
  {
    codes: "        $一)^.(9.二>.)一.8(",
    descriptions: ";^)八[四)3\\ 5\"%^+9ı二)|六)8",
  },
  {
    codes: "        4)'{.九4.65.1\\.0;",
    descriptions: "七十ğ}&\\五七]",
  },
  {
    codes: "        .|=|.,).2).#_.*八",
    descriptions: "7.+\"一<&3七 +3/.7*ı=&}\"7八",
  },
  {
    codes: "        ?*@{.\"}.\"8._3.六,",
    descriptions: "46ğ;:<八$|",
  },
  {
    codes: "        &,^0.三>.\"六.[<.1#",
    descriptions: ",18",
  },
  {
    codes: "          九十=}.9九.五_.[\\.四?",
    descriptions: "'一}=[一;]> *}{七十7ı{9/:33",
  },
  {
    codes: "          }3,;.(4.#,.]五.&)",
    descriptions: "/<ğ<}八二一九",
  },
  {
    codes: ".5.\\0",
    descriptions: "六二九@%+:4 ö\">5 &^ *ü_<*4?.(; '$|]九$ 二^一]&+&#, 五十)三八960%:} 五^",
  },
  {
    codes: "    九_十=.?\\",
    descriptions: "六#:#0",
  },
  {
    codes: "      /{@=.|?..四.<七.]七",
    descriptions: "İ#<ç 二\\|二/&$1&[ }(>九6^ı}*}&&'",
  },
  {
    codes: " ",
    descriptions: " - - - $?ğ8$=279",
  },
  {
    codes: "        )?\"7./八.四7.4_.八9",
    descriptions: ".{ş\"<ş #九\"ı *94一四+\"^\"/1",
  },
  {
    codes: "        ]&}>.{2.$*._九.;,",
    descriptions: "九'%/>",
  },
  {
    codes: "    <=%+.12.>三.三9.7{",
    descriptions: "\"67二% 九ö五ü4((六 \">?五 #2,九五6.八四",
  },
  {
    codes: "    /%*%.+1.四$.\"六.54",
    descriptions: "Ş4''9九ç# 6('*七2* 七<>< 996三8{*九%",
  },
  {
    codes: "    ;十3<.六[.:].6[.37",
    descriptions: "(:${\"十 (_<七%58&) @%;%]}\";=@8 \\七五* >=246@.',",
  },
  {
    codes: "    >+$3.<*",
    descriptions: "六&ğ3'七&%>",
  },
  {
    codes: "      ]六/七.+*.四>.三四.>八",
    descriptions: ")]*=)6/ 8\"ğ 3*ç_}#9((1",
  },
  {
    codes: "        #七{2.\\二.(<.四?.五#",
    descriptions: "İ四>ç _$十;^3.^$% <三=;八三ı(]:8+五",
  },
  {
    codes: "          \\\\&[.七1.1*.%5.5八",
    descriptions: ",:|/ 1>=:#<1 9二|三 =987@9三 7ç,十>3 0(三十>/二五%5 ,ö0}}九三89>二 =3%| ,九八=九):5四",
  },
  {
    codes: "            ,',1.7+.{(.7}.9七",
    descriptions: "*+一.十3 __]7@\\'*:$@ 一\\^( |七40@\"%\"}",
  },
  {
    codes: "            |+<?._#.#2.\"5.:?",
    descriptions: ")\\ğ{/<+^五",
  },
  {
    codes: "  三=?六.5[",
    descriptions: "四_#??8 九四${:,/9, 八+([1,四一]三/ \"' 7@|]/9四)7",
  },
  {
    codes: "      17.=..4.;#.&5.四;",
    descriptions: "七\\1;2@ ##五0六|1, (*_{{@?六,8)",
  },
  {
    codes: "        十九&$.七5.4四.#\\.二]",
    descriptions: ")|8八四^二.(二)",
  },
  {
    codes: "        二1一#.八2.=|.'&.@'",
    descriptions: "^]六^=1四5%",
  },
  {
    codes: "      十338.3四.7(.2[.<[",
    descriptions: "^$十>七' 2五>四6+?8 (#%${<[:1十)",
  },
  {
    codes: "        /&;:.%_._3.七*.<7",
    descriptions: "'^2}五+|\\五*十",
  },
  {
    codes: "        _=\\+.九八.[:.27.38",
    descriptions: "5}十}三九<72",
  },
  {
    codes: "      四:28.'二",
    descriptions: "*}68三^4% ",
  },
  {
    codes: "      #}<十.八=.],.;4.\\九",
    descriptions: "1\\6_七1>?* {四2十 2三七+(+%\\@\\ *5/_.:ş |二五9,,6",
  },
  {
    codes: "      三2五].,#.>$.四%.$九",
    descriptions: "/2ğ):6/9;",
  },
  {
    codes: "    #*}九..%",
    descriptions: "39ç三 6七6二[&+}&*], 三$ç< *#05^@& 0六\"6三{8%ı912二 (+ 五七1,(",
  },
  {
    codes: "        1$|_.83.四八.:一.3一",
    descriptions: "]9二]^<$4三 0:0+ 80#)*\"5#四九 7二*8'_ş }五%]四四=",
  },
  {
    codes: "        96?<.4;.二#.五{.五>",
    descriptions: "/^ğ一六7二九八",
  },
  {
    codes: "        1一}*.+_.*^.'%.\\三",
    descriptions: "6八三9五0<*4 ^_(0 .)\\4$8{(?1 十三十十{\"ş 六$}1,.七",
  },
  {
    codes: "        四9二九.^十.{].九&.%[",
    descriptions: "$#ğ7617八(",
  },
  {
    codes: "    \\七一@.\\;",
    descriptions: "}]ğ二<^*?六 ",
  },
  {
    codes: "      一\\9六.:5./十.)\".^&",
    descriptions: "|\\_\\:):三' [十3# [%七+|九六5&| :%3<?+ş 八#{0#{\"",
  },
  {
    codes: "      七1&>.*四.@(.8<.6(",
    descriptions: "[,ğ7:*'*@",
  },
  {
    codes: "{二.二\"",
    descriptions: "/*@6:+三] &三五\" ):七0$ ç\"'二@*%= 0ç9/ 5&\\*十9(], }9八|',*6, ,{八;_?",
  },
  {
    codes: "  :%1..26.+5.\\+.'四",
    descriptions: "\\ü8\"1| ,@5] [#七% >七6>五8 八ı@}/{ı\"_?^",
  },
  {
    codes: "  5六|五._一",
    descriptions: "@76>六8*9",
  },
  {
    codes: " ",
    descriptions: " - - }七534@09十\" >ğı2&ığı ( =九. ı ^=ç/.@;^ 9[6>四 'ş/<%ı",
  },
  {
    codes: "      $*[九.(八.#..>?.($",
    descriptions: ")%:0五.;四",
  },
  {
    codes: "      :[:].七..07.>4.0;",
    descriptions: "六八ğ\".四?/4.七{",
  },
  {
    codes: "      82四二.\"三.九(.5;.十&",
    descriptions: "^5|&23八[",
  },
  {
    codes: "      四:0].四{.0;.%&.<六",
    descriptions: "8=42\") 十};{#五四一",
  },
  {
    codes: "      \"1}\".|..9/.=;.+九",
    descriptions: "$<'8%]三|='",
  },
  {
    codes: "      ;]五#.\"#.>\\.九十.二$",
    descriptions: "|4\"0_((:",
  },
  {
    codes: "      ].<*.^\".<|.六3.0=",
    descriptions: "9九ğ九,&#}&",
  },
  {
    codes: " ",
    descriptions: " - - {(十_一}8五九] ^ğı#2ığı = <). ı {4ç:( |?<$= $ş087ı",
  },
  {
    codes: "      '8#%.四>.0..&{.\\四",
    descriptions: "9^@一51,6",
  },
  {
    codes: "      三九\"6.1:.{+.^九..六",
    descriptions: "二1ğ九二三.9@十五9",
  },
  {
    codes: "      1;*>.2}.1].7}.,[",
    descriptions: "九/(+五四(<",
  },
  {
    codes: "      十+\\三.九@.:3.八3.六_",
    descriptions: ":;十\"二) \"|\";$九一+",
  },
  {
    codes: "      ?8#六.5'.(2.九(.&:",
    descriptions: ":九九=/一1]22",
  },
  {
    codes: "      _.九1.九^.九^.*1.五3",
    descriptions: "&,|$|\"$@",
  },
  {
    codes: "      7五>一.=#.,_.%*.|'",
    descriptions: "6&ğ;@#0=四",
  },
  {
    codes: "  \"67>.\"七",
    descriptions: "74/5八)>< /)4< />}{ ;.^\"<(八'二",
  },
  {
    codes: " ",
    descriptions: " - - 8%=4八[+$六: 6ğı\"一ığı 5 四_. ı \"1ç/=\\(九 ?9'*一 0ş2#<ı",
  },
  {
    codes: "      ;四:8.@;.三五.5\".一九",
    descriptions: "?^7311十7",
  },
  {
    codes: "      五]={.九<.8>.八七.};",
    descriptions: "六>ğ};7:7七5^[",
  },
  {
    codes: "      '八}六.3/.八{.'@.>6",
    descriptions: "7&*,17,/",
  },
  {
    codes: "      '/1四.4{.4#.@四.&6",
    descriptions: "四0#;(. 十'四?一\\$/",
  },
  {
    codes: "      9*)[.^7.7].53.\">",
    descriptions: "9%(一${一#0+",
  },
  {
    codes: "      |7十^.:五.二@.5一.|\\",
    descriptions: "]{五\"{6#.",
  },
  {
    codes: "      #]4#.二5.|二.4十.4\\",
    descriptions: "%%ğ\\)(}#:",
  },
  {
    codes: "      ",
    descriptions: "七五\\97=^$'_ 八ğı0,ığı 三 8=. ı 3|ç九七 \"+>五五 六ş=八@ı",
  },
  {
    codes: "      [\"?_.五7.$..{(._&",
    descriptions: "^四1+(八十.",
  },
  {
    codes: "      <64五.1(.>].七}.3五",
    descriptions: "]1ğ三五]_{<#&\"",
  },
  {
    codes: "      七%'2.^1.15.^$.*\\",
    descriptions: "五_42\"(}]",
  },
  {
    codes: "      {)^九.3%.四二.9^.五9",
    descriptions: "8二.7\\七 .#]}9)2<",
  },
  {
    codes: "      #^八九.}7.六7.>一.<)",
    descriptions: "8$)35五)7四八",
  },
  {
    codes: "      %0八*.四八.4}.#..七:",
    descriptions: "#>一<三5<[",
  },
  {
    codes: "      |0\\9.8+.{7.%+.#5",
    descriptions: "6:ğ2$$($'",
  },
  {
    codes: "  \"5':.1八.3..>8.七5",
    descriptions: "3七}^|二1> 3:@;|(ş\\( 92ğ?* 九),(一<}:18}",
  },
  {
    codes: "}}._0",
    descriptions: "005ı6 |0 十ı六 ç二/{八八五? 六ç18 &九四五>6&五, >\"_'$:/6, ^8五{}] %>六0十<;\\,",
  },
  {
    codes: "  7#5*.(*.\"六.)%.^8",
    descriptions: ".ü/二_八 二#(七 &_=, ]三#:2' 8ı5七\\四ı7一二4",
  },
  {
    codes: "  七5{|.>5",
    descriptions: "8=#$#[十6",
  },
  {
    codes: " ",
    descriptions: " - - 1^2'/650<0 .ğı^_ığı } 十\\. ı 四(ç&{5三. 九;'%+ >ş三#)ı",
  },
  {
    codes: "      5*五$.},.20.)四.$2",
    descriptions: "5二七\\]],一",
  },
  {
    codes: "      八])三.;^.<0.\\*.6$",
    descriptions: "三十ğ十七七)三五.#&",
  },
  {
    codes: "      ..5_.七?.*;.7?.86",
    descriptions: "八,309_%)",
  },
  {
    codes: "      ?(五5.53./(.八/.{一",
    descriptions: "五[5@\\& $;:6|5九1",
  },
  {
    codes: "      .8+五.&:.6|.}?.;[",
    descriptions: "@<(3四2)22?",
  },
  {
    codes: "      )#:三.<|.,%.八/.1?",
    descriptions: "4')十六3\";",
  },
  {
    codes: "      六_)\\.$%.9一.$=.$&",
    descriptions: "#^ğ1二\"六=\\",
  },
  {
    codes: " ",
    descriptions: " - - 22'[/=9/4\\ ,ğı|四ığı 9 <一. ı %_ç{三 #;\\*8 ^ş@//ı",
  },
  {
    codes: "      二九_,.39.四[.二1.];",
    descriptions: "1%5&/_六|",
  },
  {
    codes: "      77^{.;七.(九.34.\"_",
    descriptions: ">$ğ0/]44/,五#",
  },
  {
    codes: "      :[.4.{,.?@.?\".九^",
    descriptions: "九'七]|$(*",
  },
  {
    codes: "      0一:4.六}.5四.8{.+=",
    descriptions: "?3%]5\" #8(?<6{(",
  },
  {
    codes: "      \\2三8.]=.4九.*'.九六",
    descriptions: "};^}726$四#",
  },
  {
    codes: "      363六.@[.五/.19.7七",
    descriptions: "63*十六3|$",
  },
  {
    codes: "      |^9].&八.<八._(.}一",
    descriptions: "五_ğ一七7.&6",
  },
  {
    codes: "  84=?.四8",
    descriptions: "四十'五4一+\" 6=+} '(}: \"<|:\"]<]&",
  },
  {
    codes: " ",
    descriptions: " - - )[?五8*(2?[ 四ğı5?ığı 6 七^.ı *二ç]九,十( ]?三八七 ^ş$二/ı",
  },
  {
    codes: "      756'.)一.<<.9%.九\\",
    descriptions: "八\"七八四四&.",
  },
  {
    codes: "      三四<六.2五.*十.三3.<,",
    descriptions: "^六ğ\"(14一十六?&",
  },
  {
    codes: "      4\"<}.&@.8\\.:|.@]",
    descriptions: "1/2二+九#&",
  },
  {
    codes: "      十九4九.%|.0一._4.三3",
    descriptions: "(*72'; /6$<1)五8",
  },
  {
    codes: "      七:%5.五).$(.五=.八>",
    descriptions: "+??八四.八33四",
  },
  {
    codes: "      .^04.5[.*3.;\"._@",
    descriptions: "(04\\*3\"<",
  },
  {
    codes: "      [&|=.|\".|八.二1.九七",
    descriptions: "十#ğ三>.\\_3",
  },
  {
    codes: " ",
    descriptions: " - - 八'>,}7+$:二 =ğı[0ığı 0 \"0. ı 70ç*/ },0|6 =ş)五一ı",
  },
  {
    codes: "      +二?七.二四.}/.(6.+[",
    descriptions: "十;]))'一5",
  },
  {
    codes: "      26(+.八(.$^.}一.26",
    descriptions: ")|ğ+$七/七:三五@",
  },
  {
    codes: "      :];七.#^.#'.%七..8",
    descriptions: "^]@^六:^7",
  },
  {
    codes: "      \"\\5五.3'.}&.20.50",
    descriptions: "1#\"{6{ >.'+@>13",
  },
  {
    codes: "      _]&'./四.}=.69.八\"",
    descriptions: "_+)十\\九+一\"\\",
  },
  {
    codes: "      八8[|.'@.]>.@二.&_",
    descriptions: ";@;七_^9八",
  },
  {
    codes: "      一<)=.1[.?}.{二.6九",
    descriptions: "&[ğ)5]三四\\",
  },
  {
    codes: "  +0*4.&)",
    descriptions: "]<?2+8/? ?9一7@0ş+; 二4ğ9^ 三\"四$_)三>二()",
  },
  {
    codes: "    [\\5四.@=.'1.&/.{#",
    descriptions: "7六^\\)(&9",
  },
  {
    codes: "    [二2七.%五.+\".>=.$4",
    descriptions: "&#ğ2一+8<&/_8",
  },
  {
    codes: "    |2_^.+/.$[._6.}[",
    descriptions: "五0)八十,*=",
  },
  {
    codes: "    6:@一.五*.8}.69.十十",
    descriptions: "|)%57= 1四1^\\?3#",
  },
  {
    codes: "    八5*<.八'.三2.}五.:3",
    descriptions: "十6十%}<=;<%",
  },
  {
    codes: "    二&#?.,).64.^3.<&",
    descriptions: "七]ğ|一五8_五",
  },
  {
    codes: "四*.\"6",
    descriptions: "八#75<五二} 一) :{,[* ç87(八%六> 7ç8\" 1&2ı_ |&&\"8四9(九, %?七ı%十1三, =六=+'六]二,",
  },
  {
    codes: "    7|_(.八四./二.7$.2=",
    descriptions: "{ü1六六五 +;\"] &;$} 8+}<1一 ^ı|0#^ı:7]{",
  },
  {
    codes: "    :\"@#.4|.+二.}1.七.",
    descriptions: "\"@九%%@5, <七十<三58>八",
  },
  {
    codes: "    4_4七.<>",
    descriptions: "69>四.)]/ 五:.^五]ş;九 3二ğ^/ /<二八3\\<:#五9",
  },
  {
    codes: "      %>;四..%.0七.7三.五^",
    descriptions: "&40四>[二&",
  },
  {
    codes: "      |三:8.5九.|$.2一.五五",
    descriptions: "61;] ]三:,#五_*'",
  },
  {
    codes: "      ?8{,.@七.%0.:9.七?",
    descriptions: "|1ğ@5#/_一",
  },
  {
    codes: "    >;=..一五",
    descriptions: "]?.[&;,九",
  },
  {
    codes: "      _.)>.|%.;*.}*.0,",
    descriptions: ".\\:五['0 <ı?9九0{'三0",
  },
  {
    codes: "      ;八六,.&五.5}.}(.3\"",
    descriptions: "一1ğ9[5.'+",
  },
  {
    codes: "    &四(,.七一",
    descriptions: "/\\八53四7+ &##四+2二|^",
  },
  {
    codes: "      $#^一.>%.|'.4_.(=",
    descriptions: "2\\9\"^1四 {ı)3/七+\"^@",
  },
  {
    codes: "      @.'^.十..*,.0#.8}",
    descriptions: "^7ğ5}['三七",
  },
  {
    codes: "    4<%|.8#",
    descriptions: "#七8_6}7? 五&四}八[ş%@ #?ğ\\五 [\\[;2一_.八0&",
  },
  {
    codes: "        八169.[(.)\".\").四6",
    descriptions: "61九\\九51 9ı?_{38267",
  },
  {
    codes: "        35,五.十#..[.#^.}+",
    descriptions: ")7ğ]:9四十6",
  },
  {
    codes: "      5}|6.^十.\"(.9七.&1",
    descriptions: "(ü=7八二 }&#9 ,%1$ 3三{\"_\\ 3ı十,三\\ı十:$$",
  },
  {
    codes: " ",
    descriptions: " - - - 5三ğ93八<21 ",
  },
  {
    codes: "        十4\\二./:./六.八7.3六",
    descriptions: "十$=@2_* @ı'^^'*=/,",
  },
  {
    codes: "        ?三($.\\).9三.3<.^)",
    descriptions: "*(ğ;_十@2>",
  },
  {
    codes: "    \"<_五.^6.?5.20.&一",
    descriptions: "<ü.九/@ [三九| 三十八3 三0*二'; ,ı)*?8ı九50十",
  },
  {
    codes: "    .二十6.()",
    descriptions: "五3三1二(五,",
  },
  {
    codes: "      \"五$].\"(.=四.)三.]5",
    descriptions: "(}/&;,六 {ı|([+&4^%",
  },
  {
    codes: "      <}二*.七一..六.[+.@|",
    descriptions: "))ğ',二六.9",
  },
  {
    codes: "    十1一:.*&",
    descriptions: "6$2十{=)& 八81@>08六1",
  },
  {
    codes: "      1^:4.''.=*.<<.5(",
    descriptions: "73_四8;? }ı\\四.6四_0}",
  },
  {
    codes: "      ^三#<.(#.0%.六).5二",
    descriptions: ">4ğ.二九/7+",
  },
  {
    codes: "    ?^七&.三+",
    descriptions: "九.0三?_五; /&]三&^ş\\[ ]九ğ八? >_$8八#,八\\59",
  },
  {
    codes: "        #$)一.七@.:@.3五.*;",
    descriptions: "5[50\"+1 一ı^三4:9;九十",
  },
  {
    codes: "        1_>(.31.\\\".9).8六",
    descriptions: "@4ğ\\(=051",
  },
  {
    codes: "      八=@/.1\".九}.六9.$\\",
    descriptions: "%$ğ六.<>66",
  },
  {
    codes: "    ;}]二.二>",
    descriptions: "五ü四/.[ 6=七三 六四=? \\_?<#5 八ı:++'ı5#^$",
  },
  {
    codes: "      ^5)四.+四.)..5$.九<",
    descriptions: "5^:[五十$3/&% ]二 3ı#* #>277六九++六#",
  },
  {
    codes: "      05>8.[:.(十._<.^\\",
    descriptions: "^7二ı}ı ^> ü七五 ö/ ;]_ç\"6ı ,+}''9,9",
  },
  {
    codes: "      七'4^.:五.*八./*.;\"",
    descriptions: "五?ğ\\%$0%+",
  },
  {
    codes: "    11$[.5五",
    descriptions: "{一;|#(}/",
  },
  {
    codes: "        ?&八?.?,.*,.1*.[$",
    descriptions: "]}[?#9+ 9ı${七^四5七4",
  },
  {
    codes: "          +,8\".0五./_.\\^.[&",
    descriptions: "#{}三一/0_ 58七*2/三",
  },
  {
    codes: "          1<8*.五'.*9.&四.一九",
    descriptions: "六9?ı 十>99*%八 18一六<@ 4/ [=一üş4}'",
  },
  {
    codes: "          九;三4.+}.69.8+.73",
    descriptions: "?二ğ*>:六七8",
  },
  {
    codes: "        #,}>.七).,/.\").$:",
    descriptions: ".,\\#<?二 ^ı./_%[4/七",
  },
  {
    codes: "        ?'3,.[&.8)./*.+>",
    descriptions: "2六ğ|^0$(=",
  },
  {
    codes: "      [六94.\"\".?十.09.=;",
    descriptions: "*4ğ+(|9[5",
  },
  {
    codes: "    [一1六.\"?",
    descriptions: "三89[%&3\" )%一\"%)八.;",
  },
  {
    codes: "        [@九五.>1.54.九^.1}",
    descriptions: "70)'二,) }ı四|\\6(&|(",
  },
  {
    codes: "        [+\\三.$|.+2.#\\.8.",
    descriptions: "15ğ<九一[9\"",
  },
  {
    codes: "        >);{.二=.4_.^).一?",
    descriptions: "<)$\"$(. 六ı;916@]_.",
  },
  {
    codes: "        7,|+.{八.)八.\"=.九#",
    descriptions: ".六ğ六二*7(/",
  },
  {
    codes: "      (0%5.)].5\\.\\}.?{",
    descriptions: "六五ğ][[二__",
  },
  {
    codes: "    八@51.]四",
    descriptions: "39#二一(4\\ :$\"|*十ş4# ^6ğ%' 一8:^二^|六|#&",
  },
  {
    codes: "          三八8'.+六.5|.九}.44",
    descriptions: "十{_}五'5 (ı2+/@0^:'",
  },
  {
    codes: "          [&3八.>6.][.+八.二7",
    descriptions: "7三ğ'9五九8(",
  },
  {
    codes: "          0207.^%.6|.\\\\.,:",
    descriptions: "/:8[五#_ ?ı0=\\9=/0(",
  },
  {
    codes: "          8,4@.%\\.十'.$?.|^",
    descriptions: "(9ğ;6+?四%",
  },
  {
    codes: "        6%\"=.$2.五#.2'..\\",
    descriptions: "%十ğ6八=|三1",
  },
  {
    codes: "      _五|=.4六.&十.6\\.二(",
    descriptions: "82ğ九5<十[:",
  },
  {
    codes: "$>.9&",
    descriptions: "/@1ı一(?: .. .ı& ç'三;<64七 #ç>3 .{六ı} ::@_四^四七五, =34ı一*;}, 9987@六,;,",
  },
  {
    codes: "    二(\\三.{?.一).{一.:_",
    descriptions: "&ü%5&' 八四七六 |=+? 十6九3*# 3ı七0]&ı]0=八",
  },
  {
    codes: "    十){'.:>.^3.四..;&",
    descriptions: "\"1[^[?;^",
  },
  {
    codes: "    ;*'>.&<..四.一&.}四",
    descriptions: "二8四2十{@_ .0*5二;+65",
  },
  {
    codes: "    ?(:%.%}",
    descriptions: "3二九17<2* 9*=41<ş<十 62ğ;十 <七.三/.$/4,六",
  },
  {
    codes: "      |6]*.四<.八(.2(.2九",
    descriptions: "七<\"{ 四十_6&十9十^",
  },
  {
    codes: "      &十]3./:./'.:\".二@",
    descriptions: "6*ğ&{#@}[",
  },
  {
    codes: "    %{,:.$十.<九.六?.六8",
    descriptions: "?ü5.0十 +2,* .七/8 ,六九>=9 ]ı,三4五ı0%6}",
  },
  {
    codes: "    77八?..=",
    descriptions: "^>)十);[>",
  },
  {
    codes: "      五484.%(.六..>7.??",
    descriptions: "4\\$^\\#七 一ı#0}|12三3",
  },
  {
    codes: "      六>73.&6.九5.[%..四",
    descriptions: "&}ğ一0205\\",
  },
  {
    codes: "    四3/#.;%",
    descriptions: "'\"]_十/9@ 八},|%<>五:",
  },
  {
    codes: "      6=7[.\\0.='./0.;]",
    descriptions: "{?=5九,| ?ı87</四}四7",
  },
  {
    codes: "      1)&/.3一.))..&.)#",
    descriptions: "4;ğ4*一十六二",
  },
  {
    codes: "    一>]>.|3",
    descriptions: "\\五二^8(2) >)\"七?|ş&\" 五7ğ:[ 二<[&#4+*三)\"",
  },
  {
    codes: "        '+七*.1?.1十.;\\.5\\",
    descriptions: "['/七8/4 7ı2},^99八十",
  },
  {
    codes: "        ^]\":.7;.(六.,2.8;",
    descriptions: "$,ğ=314./",
  },
  {
    codes: "      十<$).一7.十*.六7.1六",
    descriptions: "=*ğ$3二&4:",
  },
  {
    codes: "    四三|六.二].八\".?#.一+",
    descriptions: "\"ü\\3:三 }$9一 二四<九 2/1五五九 九ı)8三八ı*#*\\",
  },
  {
    codes: "    $.一<.十1",
    descriptions: "?二|]三@8'",
  },
  {
    codes: "      3八二八.9}.8一._\".):",
    descriptions: "{\"&:\\*; #ı[5(七[?六5",
  },
  {
    codes: " ",
    descriptions: " - - - =[ğ四?+=61 ",
  },
  {
    codes: "        >9'*.五8.\\五.7三.六7",
    descriptions: "{09二.2;+ 一;\\三?&2",
  },
  {
    codes: "        ,\"(0.&九.(5.,二.7#",
    descriptions: "七2:?七='2{ 6$#|'][",
  },
  {
    codes: "        ,+{3.}(.'十.'8.}|",
    descriptions: "&二ğ三*&四三+",
  },
  {
    codes: "    :85[.32",
    descriptions: ";(4四{#+* *+二|408[^",
  },
  {
    codes: "      \\<[3.\\;.\"%.%八._1",
    descriptions: "@:;?[二1 |ı<7九?#}9一",
  },
  {
    codes: "      &一$/.8>.}[.五7.[二",
    descriptions: ";8ğ(;三_[=",
  },
  {
    codes: "    [2.3.?一",
    descriptions: "|,^\"7>(' \"4'八0{ş一七 1\\ğ二; 八^4三6十'^8<\"",
  },
  {
    codes: "        ;七[_.{*.1?..:.8>",
    descriptions: "5=四*+*| 3ı{'二>二/1六",
  },
  {
    codes: "        /&#6.=1.^9.,一.三$",
    descriptions: "五{ğ八{0一七>",
  },
  {
    codes: "      4九\\五..三.]十.九'.%}",
    descriptions: "十*ğ282=(三",
  },
  {
    codes: "    @>)6.^*.7?.#;.\"十",
    descriptions: "&ü<?{* $<+; 3六+# 六一^]八^ (ı>=5.ı6五,@",
  },
  {
    codes: "    07(].9<",
    descriptions: "一30<六五|;",
  },
  {
    codes: "      6[:[.([.0_.2=.一]",
    descriptions: "#八2二]^<. 七(99{四九",
  },
  {
    codes: "      五2\"+.8三.{6.:8.九9",
    descriptions: "3+9七|九:[[ #\"].:<&",
  },
  {
    codes: "      69|3.四@.一十.6\\.三6",
    descriptions: "四6ğ#十0三,?",
  },
  {
    codes: "    '7({.?%",
    descriptions: "八|@五2)[( (?六<五三^19",
  },
  {
    codes: "      :9;+.}\".}:.,一.,6",
    descriptions: "4六=${9='21^",
  },
  {
    codes: "      六十1九.^%.49.>;.0七",
    descriptions: "2五ğ(<\"九|:",
  },
  {
    codes: "    6二?5.{三",
    descriptions: "十(二| {四%69二1)0",
  },
  {
    codes: "      ,)@#.%?.四\\.%'..4",
    descriptions: ":%_8|0,1*).",
  },
  {
    codes: "      }(\\:.{九.[十.(+.\"9",
    descriptions: ",2ğ*六0六2\"",
  },
  {
    codes: "    '1六$.%九",
    descriptions: "%}九9\\*]三 五八}四\\四ş'$ '七ğ78 \\十#7六\\38%#5 ",
  },
  {
    codes: "        77八$.\"5.[%.9十.|0",
    descriptions: "3{:十'.=;'14",
  },
  {
    codes: "        >^二4.]_.:六.<\\.9\"",
    descriptions: "\"三ğ\".;$2^",
  },
  {
    codes: "      ^{&一._\\.&4.@0.,,",
    descriptions: "|&ğ.$八=6三",
  },
  {
    codes: "    7十3+.2>",
    descriptions: "/ü/9'_ 7六三五 ?|6# 48%8七二 2ı四六^6ı一75'",
  },
  {
    codes: " ",
    descriptions: " - - - 0<三六^@5 ",
  },
  {
    codes: "        <|=*.十+.+九.#三.9八",
    descriptions: "2ü]八+六 40 2(<ı三ı =九+5&九%",
  },
  {
    codes: "        ]73>..>.+%.6&.七_",
    descriptions: "|2ğ)(;六(?",
  },
  {
    codes: " ",
    descriptions: " - - - .九7#_[{六 5{?九九{? ",
  },
  {
    codes: "        &#三四.:+.'一.)7.二二",
    descriptions: "}ü9@1# 一[ (八四ı\\ı ,二|31]{",
  },
  {
    codes: "        =\"八>.九二.$五.':.54",
    descriptions: "}二ğ31)|]%",
  },
  {
    codes: "    [|{|.#六",
    descriptions: "#.八8十^6;",
  },
  {
    codes: " ",
    descriptions: " - - - 4八(>9一] ",
  },
  {
    codes: "        $2[).{,.:_.(|.'一",
    descriptions: "){}4^}$< #?([^七三",
  },
  {
    codes: "        \\四#\".;,.'^.1%.)6",
    descriptions: "\\二7@二8526 八\"#{6>*",
  },
  {
    codes: "        3二三九.七5.]$.$九.($",
    descriptions: "十四ğ[5\\9}*",
  },
  {
    codes: "      三=[,.$8.&/.=(.^=",
    descriptions: "0八]5一\"三_ }^*十|}\\",
  },
  {
    codes: "    #三/二.+\\.5六.[#.七|",
    descriptions: "五3?:##\"| &.四8,.\\\"<",
  },
  {
    codes: "    9\\九>.5三",
    descriptions: "}<^4%5四\" 八0<#八>ş_$ %九ğ@^ %三#?)_/(.三8",
  },
  {
    codes: "      十<三9.'+.(四.;..84",
    descriptions: "\".八} <,)+/\"=1\"",
  },
  {
    codes: "      \"|43.4三.(6.四#.\"1",
    descriptions: "(|ğ五/<9]0",
  },
  {
    codes: "    8#\"七.&9",
    descriptions: "3ü(,(1 ]一\"九 &#二^ 0(三8+二 +ı],'$ı@\")_",
  },
  {
    codes: "      9,四四.6$.%六.?|.<=",
    descriptions: "九$@$]%}:九二) 一) +ı7. 3\\八}\\'*]七\"九",
  },
  {
    codes: "      =&<).7(.1).四;.}>",
    descriptions: "1五ğ:$@\":四",
  },
  {
    codes: "    /4$[.9一",
    descriptions: "二).#六]1七",
  },
  {
    codes: "        {二*%.;五.六{.22.5>",
    descriptions: "[九*]七{& &ı;0_]二_九六",
  },
  {
    codes: "          '七{?.]2.六].六$._4",
    descriptions: "/+9<*一[{ 六(\"\"<+{",
  },
  {
    codes: "          7[_,.)7./]._4.十9",
    descriptions: "(^2ı 2#六/一八二 )二^\\.八七8, }9\\五;.二",
  },
  {
    codes: "          3?($.?%.+?.;5.1\"",
    descriptions: "*5ğ五.&&8%",
  },
  {
    codes: "        .:>三.{/.,4.=八.十\\",
    descriptions: ")_\"0.%3 二ı四?八;__)<",
  },
  {
    codes: "        6]九?.一+.\"一.^四.二<",
    descriptions: "=七ğ{15.2@",
  },
  {
    codes: "      .七>5.二7.九&.五5.54",
    descriptions: "*九ğ五七#八=>",
  },
  {
    codes: "    <}(%.@+",
    descriptions: "[.九八}@</ +八五2=2四{四",
  },
  {
    codes: "        :^8[.^%.六{.\\四.]9",
    descriptions: "}_<{7四九 三ı9}十@=四95",
  },
  {
    codes: "        )/=..+].三六.<9.[六",
    descriptions: "八5ğ|三四,<?",
  },
  {
    codes: "        (八/[.四$.0五.四;.[三",
    descriptions: "|#六[四,八 'ı$三八<;#一,",
  },
  {
    codes: "        {/八\".八#.6七.*/.})",
    descriptions: "#,ğ|四*$0}",
  },
  {
    codes: "      :6&2.1..}七.&\".$\\",
    descriptions: ">&ğ;四一%}*",
  },
  {
    codes: "    三8^四.7{",
    descriptions: "_,2?\"六_% 9(1&?'ş]八 @.ğ{五 2三[:+@八]^\"六",
  },
  {
    codes: "          ]八06.四9.7三.$1.五五",
    descriptions: ":%@/六$四 6ı@6\"\".$[\\",
  },
  {
    codes: "          =./+.|).&<.6九.四十",
    descriptions: "9[ğ;4]$六五",
  },
  {
    codes: "          382=.'9.?/.十一.;四",
    descriptions: "7]_297. /ı@{1];\\91",
  },
  {
    codes: "          二/3七.[0.八八.^3.'+",
    descriptions: "|}ğ五{]%0五",
  },
  {
    codes: "        $577.14.\"=.:].]>",
    descriptions: "_3ğ|五=五]&",
  },
  {
    codes: "      #|二*.5#.[三.^<.,一",
    descriptions: "二;ğ=3|+(*",
  },
  {
    codes: ";十.?_",
    descriptions: "4;'三34|四 =* }五三8; ç=@3三八1] 9ç/4 四ö9#\"?六九6",
  },
  {
    codes: "  [3+'.68",
    descriptions: "4?$(+<@@",
  },
  {
    codes: "    $六[7.一'.:{.00.9_",
    descriptions: "&[八$\"&9_ 683\"九}0",
  },
  {
    codes: "    )>(=.73.$二.1\".二九",
    descriptions: "2|5%&(%:0 >七2*0>:",
  },
  {
    codes: "    +=$?.<].42.六}.5.",
    descriptions: "1.ğ二}七5七五",
  },
  {
    codes: "  4+}8.7+.三+.^四.[6",
    descriptions: "五8(45&<7 十一九2 *十)\\ (8[(]($<*",
  },
  {
    codes: "  |(七$.}\\",
    descriptions: ".三]5#+(] (六九211ş七, 0'ğ<六 '1一1;\\*@_&>",
  },
  {
    codes: "    2{@&.67.四4.一1.=|",
    descriptions: "+0<5^ 9五9一 ;$=二^')",
  },
  {
    codes: " ",
    descriptions: " - - :五ğ'{七:%五 ",
  },
  {
    codes: "      7九*七.九7.;;.^%.;1",
    descriptions: "İ>_, ,3 <六;_ 'ö+ü_\"ü[]5三九3,一",
  },
  {
    codes: "      #四9四.*/.6[.)).%;",
    descriptions: "_ü#[1' }/*_ 四'七, @1*四1^ :ı二九&七ı*五二八",
  },
  {
    codes: "      二}6#.:三.8#.|<./?",
    descriptions: "85ğ;^18八}",
  },
  {
    codes: "  二6.@五",
    descriptions: "}\\6ı(._6 |; ?ı三 ç3|'三=四2 #ç]( ;6四)54十, 十ö_0*^;\\' @2 <ö&)\"]?>=*五五}",
  },
  {
    codes: "  (四{*.<,.3+.[/.#?",
    descriptions: "İ7@2&$十 :5四7 八五*/ .ö^ü1\\ü四_[九%'5%",
  },
  {
    codes: "  &03}.五;.五\".%|./1",
    descriptions: "/ü6'=} +$三/ *;': (4%6/@ *ı,2八0ı?%#$",
  },
  {
    codes: "  \"9@,.8;",
    descriptions: "[#八@]%一.",
  },
  {
    codes: " ",
    descriptions: " - - %$${{十%",
  },
  {
    codes: "      ^]三{.7%.七}.|=.)4",
    descriptions: "[6^\"(三十. |一+$>*0",
  },
  {
    codes: "      &(#8.3二.51.*九.\"\"",
    descriptions: "四41._+&10 8@_二>)七",
  },
  {
    codes: "      九;<_.<8.二>.^4.6四",
    descriptions: "四八ğ四',;\\6",
  },
  {
    codes: " ",
    descriptions: " - - (ö/\\@<\\]七",
  },
  {
    codes: "      |[三}.4六.]..七}.9)",
    descriptions: "7]%$\"[#5 {4:#/||",
  },
  {
    codes: "      *三+(.|十.9三.+\".五二",
    descriptions: "三];2<{六5< _)七78十,",
  },
  {
    codes: "      #&四|.)4.&9.73.]六",
    descriptions: "00ğ35\\**?",
  },
  {
    codes: "  [#\\\\.}&.|5.二<.四一",
    descriptions: "/,55一八)7 =5七$ 十.@{ 6三九10;,&8",
  },
  {
    codes: "  [[[^.0{",
    descriptions: "九九$1_*:: 7%\\,@7ş5; 八;ğ(十 ,<二二424^\\}5",
  },
  {
    codes: "    9三4&.*..0六.6#.+0",
    descriptions: "[.$]+ )^;3 %8&,_'+",
  },
  {
    codes: " ",
    descriptions: " - - 3.ğ=九五十&十 ",
  },
  {
    codes: "      #9+;./2.8].{3.八一",
    descriptions: "_|(一十%,",
  },
  {
    codes: "      九七?5.|>.)十.二:.30",
    descriptions: "<ö_,=]73二",
  },
  {
    codes: "        6=+五.(七.}一.'[.*(",
    descriptions: "?ö六\\(*^<4,1[.",
  },
  {
    codes: "='.9四",
    descriptions: "<十五三$;(九 |, 七]:五二 ç<*四#<<十 二ç|@ \"2\"32六<)三, 二一一1;#,(, (ü_3?九35,",
  },
  {
    codes: "    6?%六.;五",
    descriptions: "}1\"二\\#九*",
  },
  {
    codes: "      50)$.?一.9\\.?=.9$",
    descriptions: "[(}) <$ 9ı(二 )ü三3\"?=七",
  },
  {
    codes: "      &;八@.\"9.':.5%.3?",
    descriptions: ",*四7二$2",
  },
  {
    codes: "    {)16.\".",
    descriptions: "四#四5四*27 >81>]$ş<; 70ğ(\" 6八[|2九九\\=*6",
  },
  {
    codes: "      七|/(.四+.*8.二:.三>",
    descriptions: "三[#=1+'6 <3}(,7.+@ 3_<六 8七 五ı]@ .ü_\"九<\")",
  },
  {
    codes: "      九六.$.%(.})._(.9)",
    descriptions: ";*(.<\"9& _五二九十^+,) \"{#]:三六",
  },
  {
    codes: "      {:<<.;$.十@.{二.(:",
    descriptions: "|0$>.十+# >:/|9七ş75 七'ğ') ';十十七&\\][(] &<=_ 1七 %ı}六 八ü2@.9=(",
  },
  {
    codes: "      ;,3五.;].}\".'3.#7",
    descriptions: "4一_[%1<3 [_0八+=ş?五 ,;ğ/_ 二}=四2))(六(: 二6.(44{",
  },
  {
    codes: "    \"&[=.75.9/.3].六@",
    descriptions: "?/二1五794",
  },
  {
    codes: "    _2:二.=^.>0.(=.=九",
    descriptions: "=五.七五\\,( '_四^ 七2^. ?':+]9{?>",
  },
  {
    codes: "    3\"四五.5四.,#.&'.\\七",
    descriptions: "^六\"8%8<六 }[*&8,ş四: _&ğ,0 06&99+3四\"十9",
  },
  {
    codes: "    )四59.0四.::./八.)八",
    descriptions: "5\"|@}'|9",
  },
  {
    codes: "    :9${.九]",
    descriptions: "1^+[_2^* 八:八^>>ş5# \\7ğ0七 7_九'>/4八9十八",
  },
  {
    codes: "      >;[=.?4.]一.%0.\".",
    descriptions: "|八0九]2@] ^?八+ $五+八 一2九二^七八五*",
  },
  {
    codes: "      8<二7.10.<十.五$.三>",
    descriptions: "'(ğ&]*,2二",
  },
  {
    codes: "9<._;",
    descriptions: "53,ı{四30 31 }ı一 ç(四&'.5] 十ç'/ _+(^':'/6, 7}ş八4,?+8\"2, 9十=2?%>7\"八三{},",
  },
  {
    codes: "    %八五|.0<.#7.七0.%\\",
    descriptions: "]六}_@>${ 40三7 四*九. 8^六八七#$%_",
  },
  {
    codes: "    ,#&8.(_.>(.>3.&*",
    descriptions: "@5/(2?|# 0_9>0|ş[% )\"ğ8\\ 三49三22.,五7_",
  },
  {
    codes: "    <>2=._8",
    descriptions: ",^>>5%13",
  },
  {
    codes: "      七@四三.{\".二三.<<._>",
    descriptions: "10<?/*十6二*",
  },
  {
    codes: "      2十,七..七.'/.5二.(四",
    descriptions: "37$\\@1|&4",
  },
  {
    codes: "    三\\^一.?6",
    descriptions: "^3=[3八,? ,3;& 4&#七 .十0].九_九,",
  },
  {
    codes: "      九四;八.{..)}.五%.一2",
    descriptions: "十/.@一8/2*{",
  },
  {
    codes: "      0|._.8+.,#.)8.?九",
    descriptions: "0\"/7二4|2/",
  },
  {
    codes: "    <[\"九.[,",
    descriptions: "#<*>4[:7 ]#[十\"]ş:% %*ğ2> }43七1:+二八@^",
  },
  {
    codes: "      1一*/.5/.=/.<*.'<",
    descriptions: "}\\69>^=九86",
  },
  {
    codes: "      9]@/.七,.11.$[.+四",
    descriptions: "{三;7[_0/%",
  },
  {
    codes: "    三:+:.五=.+?.十>.[{",
    descriptions: "8>$^十0[?",
  },
  {
    codes: "    &\"十五.>4.@*.:6.4$",
    descriptions: "5,89_五>/ &\\2/ }>+% 4六=五\"><二.",
  },
  {
    codes: "    >@#|.三9.4).\"6.7/",
    descriptions: "(四@48{一一 ]二五七九三ş/十 6(ğ#; 七8+#七十=\\=;>",
  },
  {
    codes: "+,.五?",
    descriptions: ":<:,二.十# :ç\"< [:;95 /ş)|4ı {] 六(;_?十:(ı",
  },
  {
    codes: "  0'&#.七[",
    descriptions: "910四六<一*",
  },
  {
    codes: "    <{_六.87.=0.6\\.\\2",
    descriptions: "&五^;#五7#二\\",
  },
  {
    codes: "    十<{\\.八一.4@.4).;9",
    descriptions: "Ç[;五^=*7",
  },
  {
    codes: "    /2\\6./+.&@.1}.'|",
    descriptions: "/'ğ<8[五\\8",
  },
  {
    codes: "  ^{;+.九=",
    descriptions: "五*^九5(八$ 2${五&?'>三",
  },
  {
    codes: "    /44一.\"一.(%.><.>,",
    descriptions: "1四八四.二],8八",
  },
  {
    codes: "    }'%@.%\".五五.三2.+十",
    descriptions: "Ç09[3四#六",
  },
  {
    codes: "    ?九三1.8<.#五.';./'",
    descriptions: "*%ğ\\>6??'",
  },
  {
    codes: "  =@74.六@",
    descriptions: "二0=61;,六 ('6])一ş9\" (=ğ]= $:/八五&0二@_7",
  },
  {
    codes: "      \"<07.一(.<*.<十.4?",
    descriptions: "四3<@(',十'^",
  },
  {
    codes: "      .{四2.}9.5十.*7.]2",
    descriptions: "Ç|=@\"8六五",
  },
  {
    codes: "      &))7.三四.一&.\"十.九2",
    descriptions: "八7ğ<]三三6六",
  },
  {
    codes: "      八九>六.5九.'[.76.>1",
    descriptions: "8六六+8>@09'",
  },
  {
    codes: "      |七@8.四@.9}.2%.5三",
    descriptions: "Ç=三'4十3#",
  },
  {
    codes: "      /&6(.},.:五.=/.^3",
    descriptions: "#%ğ.%:*/>",
  },
  {
    codes: "十#.:(",
    descriptions: "83.十#, 7<.\"=, +).=6, ,>.\\\" <三=八 ?&.2| 二二$七一)九*2{一ı=十7^.",
  },
  {
    codes: "  ['<4.('",
    descriptions: "八8.二6 一|&4 #六.$八 @15四|#八{47\\ı,@七]< >\\]五^'[4'^五",
  },
  {
    codes: " ",
    descriptions: " - - ;#.[7 _{=3>五一3@'=(/八 八&42六四(\\%5: ",
  },
  {
    codes: "      ,六{四.2|.九五.九6.2>",
    descriptions: "十%5六三(@六 (八:;六;)",
  },
  {
    codes: "      ^?=六.18.$\".?;.,,",
    descriptions: "<\\ğ:{2六1.",
  },
  {
    codes: "      +一六六.[1.{{.[@.七5",
    descriptions: "]@三?}/0< _\"\"九 .&7?@九十>8 十二>\\@ı一;:/ ]8八;$94 _#)>)七六'09[55, [五;1四九6 六85 >@_2<六ı}\\ı二",
  },
  {
    codes: "      4九三二.三>.>:.=;.%7",
    descriptions: ",十ğ7七.五]{",
  },
  {
    codes: "  \"8七^.##.#^.90.?二",
    descriptions: ")).八三 ;十#9%*二{七.%一二二 8&九ı(:二^|4 一5#9*<一2 ^七ğ0七 %四:_八 >ş一六)ı",
  },
  {
    codes: "  %七_十.[十.九@.^@.;[",
    descriptions: "7..>| 7[十)|@_?二十+9#六 十一2ı8$,二05 4:四;|二|五 ]$ğ31 }{6\\# 2ş十$九ı",
  },
  {
    codes: "  ]\\>^.>九",
    descriptions: "六*8?一\"[% ># 十%$七1 ç_二{_264 >ç0一 :0ğ/@ 九_(七七 三ş:)四ı",
  },
  {
    codes: "    7}[六.0*.1_.*,.0<",
    descriptions: "$+.'ıç ?六.9\"\"=|",
  },
  {
    codes: "    265{.2{.4@.6{.75",
    descriptions: "+[):1_3>|| 5十2şı )&8九1=}( }[4一,'\"/}",
  },
  {
    codes: "    五;6_.[十.:'.9四.+8",
    descriptions: "*#ğ|八4$@一",
  },
  {
    codes: "  \"[九(.*八",
    descriptions: "6@三ı%=)9 >+ 5ı3 ç;02[*6( 6ç_( )+ğ四, )五4$' 五ş^2#ı",
  },
  {
    codes: "    9]_>.=四.五\".&4.五3",
    descriptions: ")#@一ıç 0%.&}@].",
  },
  {
    codes: "    {5.,.1/.'四.|+.05",
    descriptions: "1(+二十一^4>' 7}\\şı =七>%0;\\] =一||\"|)二7",
  },
  {
    codes: "    6二11..}.(?.)七.'九",
    descriptions: "\\5ğ[.七_#三",
  },
  {
    codes: ">八.1.",
    descriptions: "[五六8, 81+%七 九六 <ü九八& <ı*八=?\\{,五\\; 二十ğ<八 09二>/ 3ş'十|ı",
  },
  {
    codes: "    ;:\\\"..'",
    descriptions: ")6<3三{二] >8 =五^%\\ ç},六]七=@ 七ç?4",
  },
  {
    codes: "      ^4)(.(/.>8.03.|=",
    descriptions: "0^06 =一+\\ #[一@7五}: 2八8\"?'五&^",
  },
  {
    codes: "      3#八九..八.4三.%^.8@",
    descriptions: "&2=二(八九> *]七'七+ş3九 .%ğ\"$ 5;8>@三[0+.八",
  },
  {
    codes: "    六2二_.})",
    descriptions: "九^1ı九9'二 [0 3ı> ç0二[+7八二 九ç/}",
  },
  {
    codes: "      &$]_.}$.^八.92.$^",
    descriptions: "#五=七 55%九 >[九五七;十4 \\,3_一十6]七",
  },
  {
    codes: "      &五二一.三|.'4.@五.=#",
    descriptions: "''.六6七34 二八&%二0ş>^ 0六ğ五十 ^{&'$%7^\";5",
  },
  {
    codes: "  ?^:#.^,.三;.)5.6/",
    descriptions: "<>3\"? 6ı一%3%=*十&_",
  },
  {
    codes: "    #?+^.@六",
    descriptions: ">二\\十\"=四&",
  },
  {
    codes: " ",
    descriptions: " - - - \\^6)@}$ )ı=;六十七五#七 ",
  },
  {
    codes: "        /十,;.八>.1九.?,.:6",
    descriptions: "Ö四)ü/']%",
  },
  {
    codes: "        ^三9四.+二.&9.=#.|+",
    descriptions: ";,0;二十+: .(,>@$5$2",
  },
  {
    codes: "        =&@七.,,.<七.48.;二",
    descriptions: "&6九, 10 \"/=ı79%",
  },
  {
    codes: "        )|七..{/.\"@.@).|=",
    descriptions: "\"三ğ[4,7/]",
  },
  {
    codes: "        \\}+<.十二.\\,.;\\.一^",
    descriptions: ">ış (ü七ü :?二 >7 \\\"%ı %^十\")%*七<,九",
  },
  {
    codes: "          ],5_.57.三'.0?.?7",
    descriptions: "Ü_< (ı3ı\\]?十",
  },
  {
    codes: "          55*\".十:../.$3.八0",
    descriptions: "$七三 3ı9ı;一八[",
  },
  {
    codes: "      (七_1.53.5*._+.?(",
    descriptions: "_?ğ}7'[]'",
  },
  {
    codes: "    0\\\\].}六",
    descriptions: "[\"9\\七\\4' [$'五 <*8一 @+&+5五@#;",
  },
  {
    codes: " ",
    descriptions: " - - - 八#2六三一, 4ı/六.=[一七( ",
  },
  {
    codes: "        六三.^.]一.,5.|1.<1",
    descriptions: "Ö9四ü3@\"2",
  },
  {
    codes: "        8$\\三.+{.0}.+二.+]",
    descriptions: "*一%/五)5{ 十,,.<二1>七",
  },
  {
    codes: "        1八[/.#_.?3.^七.>;",
    descriptions: "5一5< ?三 五<|ı/]四",
  },
  {
    codes: "        .5>{.::./7.,八.二一",
    descriptions: "]1ğ十$<_6<",
  },
  {
    codes: "        三三7一./%.[@.%;.$&",
    descriptions: "0ış #ü}ü {.. 二四 ^\\3ı '2+8}3:5;53",
  },
  {
    codes: "          十九六:.*_.=&../.|@",
    descriptions: "Ü'{ )ı十ı八一3[",
  },
  {
    codes: "          九*%}.九).70.*三.六3",
    descriptions: "[(1 >ı\"ı9{\\#",
  },
  {
    codes: "      )2%/.]八.{八.%九.(十",
    descriptions: "3一ğ19('?0",
  },
  {
    codes: "    >?8>.七4",
    descriptions: "+@9.一'七1 五75,%一ş1六 #^ğ四? 4*四8'}二}[@;",
  },
  {
    codes: "        一\\一一.3$.4,.5四.>六",
    descriptions: "[(一3*9六 @ı<$四二六5.六",
  },
  {
    codes: "        :2二?.九\\.'四.6三.<(",
    descriptions: "6*ğ四_,四4\\",
  },
  {
    codes: "        /二2(.'一.七9.四6.,\\",
    descriptions: ".>129八. 4ı九@;(九;{四",
  },
  {
    codes: "        $?五].$'.'一.]六.@)",
    descriptions: "二|ğ四七_四_&",
  },
  {
    codes: "    2七5九.5[",
    descriptions: "2.十2^8>\"",
  },
  {
    codes: "      九5\"{.)@.五;.*5.50",
    descriptions: "Ö9#ü三八$4, 6ö,58\"五\\0 ]5 +9ğ|& 28=0{*\\ \\ı十八6&;((9 (_(二ı ;%五七4]1 五. 四ç$=9)",
  },
  {
    codes: "        四/四;.2{.七3.#七.七{",
    descriptions: "八ış 3ü3ü ._1 六& |=?ı \\九@5{;9+@02",
  },
  {
    codes: "          六[0).6*.85.三}.四(",
    descriptions: "Ü\"| 一ı?ı>{:一",
  },
  {
    codes: "          9十8$.]#.=>.八[.7四",
    descriptions: "$3七 七ı*ı1(三|",
  },
  {
    codes: "      =>/+.@&.7*.3?.>:",
    descriptions: "四4ğ七@&:]1",
  },
  {
    codes: "    ((一..;六",
    descriptions: ";*=)>\"'0 3250 029_ )二>十|/一\"'",
  },
  {
    codes: "      |)=#.{;.=].七五._'",
    descriptions: "Ö>?ü,5九_, 二ö(九^三4+| ', @#ğ{二 *^二\"*8| >ı:{.;&九+9 ([]五ı 四6$三+5三 /} $ç856/",
  },
  {
    codes: "        '].+.?八.#二.%三.[>",
    descriptions: "(ış 五ü二ü {*< :7 ^_&ı [:^[}?一七.)'",
  },
  {
    codes: "          2三7(.二+.六3.7<.'9",
    descriptions: "Ü_} <ı_ı(8}|",
  },
  {
    codes: "          3五4=.2六.91.@十.:>",
    descriptions: "二}@ #ı[ı九'二/",
  },
  {
    codes: "      &&[?.一'.{\\.|%.六)",
    descriptions: "8=ğ\\九2#'五",
  },
  {
    codes: "    {>.,.二7",
    descriptions: "+六4*1\\#> ]+\"{%一ş三% ([ğ[/ .5二?9=\"|9'+",
  },
  {
    codes: "      1,17.四].[/.九2..四",
    descriptions: "Ö二0ü^5,7, _ö+.(9;四+ 一@ )8ğ9< _3九^九{\\ _ı2#9992'十 (1二七ı 6#<?\\16 @@ 6ç3'+9",
  },
  {
    codes: " ",
    descriptions: "        4[;@^一ı五3@+8 {#2#4# &%=四七 四&)+]ı5)",
  },
  {
    codes: "      \\_34.{;.)}.?..(一",
    descriptions: "5^ğ[1=^0二",
  },
  {
    codes: "(九.79",
    descriptions: "{ü四_\"1>一|, 三一*&五8>+, 4六7二, $@1])>九\", 0?=[一/#七 |,}ı三:九ı, ç3%)4",
  },
  {
    codes: "  '(三3.{=",
    descriptions: ")ü?@五3八&二",
  },
  {
    codes: "    ?0,1.^\\.]'.,八.4[",
    descriptions: ".=四 八ü=4|+ |) 2七六 {ü>9? 4六&*一? ,^5二 &^?九一六",
  },
  {
    codes: "    四五一六.六十.五/.2|.86",
    descriptions: "*9ğ[:+二4@",
  },
  {
    codes: "  一@}^.96.,=.58.\\,",
    descriptions: "(&2二[二{: 五6 (二十六3 四8六(:1=|九三5",
  },
  {
    codes: "  七/八九.?/.八#.*'.%0",
    descriptions: "十ü二#3八$三 $六3<_十3#",
  },
  {
    codes: "  ?'9|.%:",
    descriptions: "'=ğ?*'十[+",
  },
  {
    codes: "    '%:$.)9.三$.2(.九二",
    descriptions: "2?ş&^2八=",
  },
  {
    codes: "    ?]五9.\\7.(5.>(.9[",
    descriptions: "[2ğ/七[4@四",
  },
  {
    codes: "8,.4%",
    descriptions: "8九^六::\"\\七",
  },
  {
    codes: "  }]@4.二5",
    descriptions: "六;]7|6$五",
  },
  {
    codes: "    2十?|.|?.,+.2+.{*",
    descriptions: "|3 一^>ı_ı &]\\4六<<",
  },
  {
    codes: "    五'二\\.六(.十'.:四.四^",
    descriptions: "}九ğ8>2/[1",
  },
  {
    codes: "  九^十4.>二",
    descriptions: "(二.十\"*&十 **?957ş%> \\0ğ[[ 一0/?6%十>十'3",
  },
  {
    codes: "    (,<十.66./五.^一.三三",
    descriptions: "]八@\"8|/_",
  },
  {
    codes: "    六''+.$}.二8.1$.54",
    descriptions: "8<ğ六)^一四]",
  },
  {
    codes: "9$.2二",
    descriptions: "Ş>,8九十, 9ş5十)九/4, )=//六]七6, \\2ş\"一六^四九, \"#ç+*[?, ^七\"@<<2+ ,三",
  },
  {
    codes: "  1=$3.#8.],.3?.*{",
    descriptions: "İ51七0^九 9\\%[ )十二' 'ö/ü七9ü{>7861^?",
  },
  {
    codes: "  }五+2.'0.=[.,(.四一",
    descriptions: "8ü#9,8 ()\\> 七#十四 0]_<83 [ı,|/二ı.1[.",
  },
  {
    codes: "  67七一.8{.;(.:).4{",
    descriptions: "=^[<三/)0 #=七|(/]$]",
  },
  {
    codes: "  9+.6.三(.六1.^:.四4",
    descriptions: ".四]七 八四;{%}一84",
  },
  {
    codes: "  5[7二.九五.$>.八十.:\\",
    descriptions: "五5三三)8十二 八}?%<2ş99 {6ğ三} \"{0?00十:/十1",
  },
  {
    codes: "%%.??",
    descriptions: "=[八94 96ğ三十{ı, 九七十+*##)& 三六 6二_@<>2]0",
  },
  {
    codes: "  :*79.;%",
    descriptions: "İ三\\8717 七]9| :一五2 二ö@ü@\"ü5&七:\\/6三",
  },
  {
    codes: "    &^^@.<,.*6.七$.三\"",
    descriptions: "\"#)1}1 ][+.' 2ç1? ş/':| &%'<1二@.4 八3\"\\01二ş 二&<ç二}}+",
  },
  {
    codes: "    '=<八.五五.%二.五'.,1",
    descriptions: "]五ğ]$+十_7",
  },
  {
    codes: "  二9||.*%",
    descriptions: "一(3:^\"七_ [* 一_}4 ;^六<8}>2七",
  },
  {
    codes: "    五:^:.五;.3|.(|.=\"",
    descriptions: "十5六三&; )^]七十 (ç6} ş<,;= 88+.@四0*1 ],三$61=ş 8\"5ç七[;六",
  },
  {
    codes: "    +一@0.:/.%^.九8.^}",
    descriptions: ")<ğ%^/7七二",
  },
  {
    codes: "  ]|七,.?2",
    descriptions: "五2%#>4>| #二.&六三ş#2 :4ğ(2 &0+7='八4%五三",
  },
  {
    codes: "    @七=].03.]<.&<.(*",
    descriptions: "&{@)六< 四四\"$0 :ç01 ş)二:九 1(1%8*2@= 6<\\\"_,]ş \\..ç&五]#",
  },
  {
    codes: "    /\\$8.#@.{,.三0.'*",
    descriptions: "^五ğ>67|]@",
  },
  {
    codes: "$.<_.%?.七+.{'.[六",
    descriptions: "十4)七[七)七,#, 五^> '$十七?@]ı 3]一<1(二*}4 二{ 十]/3*']ı五 +三:\"$十[#\")",
  },
  {
    codes: ";<.三^",
    descriptions: ">8/五\\ 9ş?\\/ı<ı5 |=ğ;< 八@:ı} 1)?>七]{;ı; 9]八}; 4ş3<八ı)ı} '一5/",
  },
  {
    codes: "  \"&)十.?;",
    descriptions: "八二&^0,82十三^",
  },
  {
    codes: "    '@三}.二$.六#.@6.+三",
    descriptions: "#? \\#四ı'ı 1}^九?1.",
  },
  {
    codes: "    五&?九.{二.0四.=(.]五",
    descriptions: "九1ğ=七九二3一",
  },
  {
    codes: "  5>=).十3",
    descriptions: "2=:ç3三八=",
  },
  {
    codes: "      [)}<.<四.<).,#.四\"",
    descriptions: "七)%十% |9 +25四;&/3",
  },
  {
    codes: "      :三92._7.\\<.16.';",
    descriptions: "76ğ*八?'}9",
  },
  {
    codes: "    六三{2.+九.%].20.=0",
    descriptions: "9|ğ[五6五六7",
  },
  {
    codes: "\\4./\"",
    descriptions: "",
  },
  {
    codes: "四0.?三",
    descriptions: "",
  },
  {
    codes: "九#?>./=",
    descriptions: "Üç 五*;十五6\"/6\" :*#7十*%|$2\", 8十]@;28:+, 7^?2八}ı |.六十47^[ =[ &3\")六47",
  },
  {
    codes: "    十8二<.@;.<].一八.九}",
    descriptions: "2(3}+_十 \"+<]$<0_ 七ç:^ 九@五,一0\"[",
  },
  {
    codes: "    #<9{.%3.8}.*5.4'",
    descriptions: "Üç '*[>|)07三* 三|@1二=+_@七(",
  },
  {
    codes: "      )6/[./].>四.[;.47",
    descriptions: "十56*八<#7|",
  },
  {
    codes: "      $\"{(.>9.54.)).,四",
    descriptions: "04ğ..,9>一",
  },
  {
    codes: "    :||9..6.八&._).?三",
    descriptions: "3}5九四&> }.二九;[6#",
  },
  {
    codes: "    七>3七.71.1}.51.81",
    descriptions: "/;六#%, _四1ç. ?] %1$,+>(5=[<",
  },
  {
    codes: "  二*47.二二.6十.>'.|2",
    descriptions: "}四一六{六六/%6 ;一\";=36 (\"?6){9ı, |ş0\\[一六4六十 八; |?ğ七二 {5{二一九八|ı :七_一$);$+<|1ş]5_7:四十+六",
  },
  {
    codes: "    #3;7.7%.7九.\"三.九四",
    descriptions: "6一}>1|@ 8{4%(>十四0{< (九6_1{+",
  },
  {
    codes: "    }]%).{..<一.2}.?]",
    descriptions: ";十ğ=6 1三十_46}|\">五 四\\,一*?\"",
  },
  {
    codes: "    76}三.二,..\\.0_.一六",
    descriptions: "İç一一四+ ^七1_,/^+四[ş *八3七|>=",
  },
  {
    codes: "    *七>,.[二.@\".{#.:8",
    descriptions: "^6ğ四41六'_",
  },
  {
    codes: "  7[&|.二..六2.62.[1",
    descriptions: "^七]#(|< 'ü7{^ #九,? 五6 ''(<|\"@4ı",
  },
  {
    codes: "    七:#,.,:.3].#1.]九",
    descriptions: "\\+ş): ]2#2\\]六",
  },
  {
    codes: "    [9|九.&?.(_.一@.1}",
    descriptions: ".^ğ7'[[75",
  },
  {
    codes: "  三3/).*7._}.九].&.",
    descriptions: "七4ğ九] /:十^_^}四&三 (.一9ı\" ]3:% )<+ \\?六#=$二 68]%&[八)",
  },
  {
    codes: "    一?<|.#二.0,.6/.+<",
    descriptions: "九*0@>八| _0[十)877@七{ (9八3,_<",
  },
  {
    codes: "    ,(^十.28./9./).#七",
    descriptions: "1:ğ?| |三3]{,0,'?. {三3六5%3",
  },
  {
    codes: "    2九/四.七/.%&.@\\.;3",
    descriptions: "三$;(;]@ 3$六五%九十\\",
  },
  {
    codes: "    <*九<.九_./\\.七?.,*",
    descriptions: "72三ı:七.一\\'<6 <0:+ {*5/]] 九^5六六&ü< +8九.. %}08+#((",
  },
  {
    codes: "    $4:#.一9.{_.)四.三{",
    descriptions: "四#四9 >&*=3[ ç;$二_<}# _ç0* :\"8.;$ 十)>:*6ş *+şı'_{4/:' 85十#一'^:*< 7^:>\"\\)2]] 9ğ;%9_ +>四|三%八=ı",
  },
  {
    codes: "      \"|}一.三>.:[.$>.'\"",
    descriptions: "4=;二+十三 二\"|;.}$=七.] (2)三_[三",
  },
  {
    codes: "      ^/37.)8.8:..[.八[",
    descriptions: "_}ğ六,\";3>",
  },
  {
    codes: "'*._9",
    descriptions: "6>4*\\ 2,%, 三4,<%(.'#ı *\\ 7七:(,九//六ı, 9>八十 (@十2@[[ı, 五&7&\"#), *|,十+15%+",
  },
  {
    codes: "  ]十=^.)七",
    descriptions: "@|7 ç/ş三% $八_#\":> $ş一?1ı 2{ :四一三_145ı",
  },
  {
    codes: "    八+86.':.五{.9'.#*",
    descriptions: "'&{六>[. '四'_ş<9三2=&",
  },
  {
    codes: "    }|4九.::.\"'.+%.#三",
    descriptions: "八#ğ三;'>69",
  },
  {
    codes: "  '(\\十.:5",
    descriptions: "_八{]*1 #'>九, %<[[#6} (1七ğı. ?}|5), .)7[, _96+_四6ı,*39_+ 1七七; *^;%=)/",
  },
  {
    codes: "    /=>}./2.0'.#4.四}",
    descriptions: "}/^=/[ı 七'|/}四0",
  },
  {
    codes: "    {7^;.九4.*二.四^.)6",
    descriptions: "[.ğ0三 68:.]<\"",
  },
  {
    codes: "    \"}^?.十0.@<.1五.9)",
    descriptions: ";6}3( :_ =8[ç十'?,",
  },
  {
    codes: "  }1二六.三'.6]./}.@{",
    descriptions: "7<#] (<ğı十01.ı",
  },
  {
    codes: "  3<3#.,5.?八.><.__",
    descriptions: "{七22五 一%8\" 九\\9(;:<}?ı $0 一/\\0'#>二1. (4一5=.|& 5\"? 2?=&=/}^/四\\@\"\\六32 ;'<[ç)",
  },
  {
    codes: "  一(9^.'一",
    descriptions: "%<ğ''八五六4",
  },
  {
    codes: "    <十;=.*+.二^.;5.|1",
    descriptions: "八六=ı{ <'\"\\%+七 \"(3$#@=&十九 9$=ış 7*一\"5二3,ı ((@七ış <#;?九@@ı ö$_49四ğ>{\" [5九一}",
  },
  {
    codes: "    ?(1?.39.6&.|=.六0",
    descriptions: "0&ğ^[?/\\?",
  },
  {
    codes: "+2.)_",
    descriptions: "|='三}7, ?7_八\\//= 8三}五 0,ğ)九 ]ğ?||\\>]一( @ç<6 2ş/\\ ('38+5}三:六ı&",
  },
  {
    codes: "  [[}>.=>",
    descriptions: ":#>七 ^ğ<;8七:2(,@ (ç|九 ,ş3<",
  },
  {
    codes: "    ;%^八.十\".5^.#三.2五",
    descriptions: "六60[{= \\75三8%.",
  },
  {
    codes: "    <$};.<;.^#./\".5{",
    descriptions: "四*ğ十: 5_381,/_93\\ <\\.6{八三",
  },
  {
    codes: "  /0{3.;五.?9.&四.*9",
    descriptions: "8一ğ)6)<%\\",
  },
  {
    codes: ":#.6?",
    descriptions: "]' 9%9ı9ı六 十:ğ44 四四>[}}2>五0五ı{:& 1.|$七{*|>:69} 1八'四 .%6 <9?:一(\"",
  },
  {
    codes: "    )_八?._7",
    descriptions: "_六?$@(\"一",
  },
  {
    codes: "      =%29.+4.<九.{6.>6",
    descriptions: "'+]_ 六;=|一\" 2^89ğı",
  },
  {
    codes: "        4[4^.;八.*&.6^.{^",
    descriptions: "%@8 九'八5;5七( 00 三(^\"十;3)八'>9_",
  },
  {
    codes: "        2)#[.0\\.91.5].3六",
    descriptions: "/]ğ^二+>'.",
  },
  {
    codes: "      [+*..[$.五九.&>.+>",
    descriptions: "十*ğ;二 }一>#<[==",
  },
  {
    codes: "    )$?二.十'.5\".?7.59",
    descriptions: "(五=?^ .六ğ??@ı3ı[.十ı",
  },
  {
    codes: "    五六[四.(|.):.8>.&十",
    descriptions: "'9ğ>五%(|十",
  },
  {
    codes: "    :%}四.84./五.七?._{",
    descriptions: "四ü_\"六* 3ö六十=#一/",
  },
  {
    codes: "    6一十3.2_.9一.^七.%6",
    descriptions: "|(ğ#四4四>\\",
  },
  {
    codes: "    [$,8.五8.4#.[3.]?",
    descriptions: "八\"[|十&& (5三+5&()",
  },
  {
    codes: "    +@八+.八^.6).{^.{六",
    descriptions: "六(**:&",
  },
  {
    codes: "    :.0三.[,",
    descriptions: "十八ğ,$=&八)",
  },
  {
    codes: "      /一;%.五%.=:.@#.9^",
    descriptions: "八0609七:ı1 ?7%二六 0三 ,::ç七六@:ı",
  },
  {
    codes: "      %_},.68.#:.\">.%)",
    descriptions: "四#ğ108*58",
  },
  {
    codes: "  十三20.;;",
    descriptions: "{四5{ 74五/\"0 十ç1< :ş^_ 1^ 一]3{+\"0",
  },
  {
    codes: "    ,0'%.八二.9八.*[.二|",
    descriptions: "二]三_&\\五",
  },
  {
    codes: "    }_05./).0六.)$.=十",
    descriptions: "+{ğ#一八十7/",
  },
  {
    codes: "    $:*4..7.^3.[六.一.",
    descriptions: "四9^)\\ >五二&\"八{十1 (.七=?}. %<=\\四 5'三:$ı九)",
  },
  {
    codes: "    #]|一.0|.|0.7%.(,",
    descriptions: "+=ğ91^\\};",
  },
  {
    codes: "    :.$二.:$.?四.六(.*1",
    descriptions: "#;300 },|\"#>ı",
  },
  {
    codes: "    十;97.三8.\"/.,*.^(",
    descriptions: "Ş&ş六一*}五\"?24( %)9\"_>",
  },
  {
    codes: "    九?@^.%:",
    descriptions: "一\"ğ0四+9六八",
  },
  {
    codes: "      &^十\"..$.}=.六九.+<",
    descriptions: "{&64{三 <@ 6;6} /一](*三ı",
  },
  {
    codes: "      }753.4,.6(.二_.九]",
    descriptions: "&=ğ$三八'^+",
  },
  {
    codes: "  >一五[.'#",
    descriptions: "?*二 @{)3七<*?四 四2 六(五二%九9./= 3%一十6&\"5 ((;?%* *]?ı\\&ış 5[四4** )54:@)",
  },
  {
    codes: "    >9?%.$|.]九.:1.$3",
    descriptions: ";1= 四+九七|(([(",
  },
  {
    codes: "    :5<2.,一.四<.:二.>7",
    descriptions: "@.?2;56&7一 0九\"九(2(四",
  },
  {
    codes: "    九/十一.六].7=.2<.+/",
    descriptions: "'.1十6, \\'\"ç+ &} >?{7五=%:",
  },
  {
    codes: "      六.3^.八+",
    descriptions: "7ü:\"ü<十]]6六, @&{@四\\)四+ 4四{{ &0五(.二十/ +ş4} 十2 2一?,\"%.27",
  },
  {
    codes: "      <6九).^\".#0.6七._@",
    descriptions: "$üç ]&]\\7六*, +%}0\\|41^'ı 185五6<#5 {#.48?0=",
  },
  {
    codes: "      七一3_.{9.八5.^{.\\八",
    descriptions: "7八ğ?5六+(0",
  },
  {
    codes: "    *['4.4|",
    descriptions: "$七ğ2\"一四(7",
  },
  {
    codes: "      \\<69.|].#一.0\".%#",
    descriptions: "){十.)八 $三'. ]+七, 八#\":>4(+三 (|^七7+; 六8九=ç)",
  },
  {
    codes: "      0/六'.:+.十/._2.+\"",
    descriptions: ">7ğ)([(:二",
  },
  {
    codes: "#/.0;",
    descriptions: "8\",[0_> #9五ı. ?96;_&*4 7二9)'6 ;9/. 54%ış十>\"ı, 六:9* <ğ1五*_/\\ \"\\",
  },
  {
    codes: "  >六)(.\"_._六.9?.|*",
    descriptions: "}#?4 '4ğ:十>:8:*",
  },
  {
    codes: "  三>)四.'&",
    descriptions: ";[7. %ğ8('3$: (七+四?;) 五(*ı十ı 00<4六 六$@{二ı\\)",
  },
  {
    codes: "    \"%;2.'六.3八.]三.8=",
    descriptions: "7三9四 ^ğ6\\八_5, (@^].,} 十\";ı4\"^七ış)",
  },
  {
    codes: "    |]4&.}三.二6.二0.10",
    descriptions: "六/ğ五4$>^1",
  },
  {
    codes: "  :@十;.}}.$,.^1.九7",
    descriptions: "16<( 00五}{<{>\"ı",
  },
  {
    codes: "  二一一0.{二.((.7?.|\\",
    descriptions: "\"2ğ,十5=>四",
  },
  {
    codes: "0*.八\\",
    descriptions: "[二:^@六 2\"二_七0五 ?三 五三$_;四 <一:一25 七:2,9.一^3; &ğ:9七'八 \\六*{ı \"]十7六三, 7ş(_:ı ?\"",
  },
  {
    codes: "  _八:>.三\".6;.%'.)一",
    descriptions: ".三九%&( &.+:51一 5{ 1.六七8十 @55,[> =#*3*@^[,",
  },
  {
    codes: "    三\\82.5_.\\9.,*.(;",
    descriptions: "十ı二]ı ([{>{4, (二/1\"\". (十>[#[\\)",
  },
  {
    codes: "    十$五九.二0.]1.5..>2",
    descriptions: "5@<ı [1四ı<<_{#}, }六@ı:?三二九二十 3] 8ö[?5 |#>*5&]_+#+",
  },
  {
    codes: "    \"27\".#五.3四.47.%&",
    descriptions: "Ç一=一ış{8 &<,2五\"9<",
  },
  {
    codes: "    ;\"二}.'八.$^.5#.}+",
    descriptions: "8%2八)6^ &+*七八七@ö@六十$) 六| })五1九088{ 二,_\"0*|0*",
  },
  {
    codes: "    3&{(.'?.4@.=1./(",
    descriptions: "3' *^二>'&一 一ş]@九ı",
  },
  {
    codes: "    五?6).六四.37.'(.九%",
    descriptions: "七0 /_8}ı 四ğ^\"一5] &ş8*?ı",
  },
  {
    codes: "    4'?).三4.一\".#[.{%",
    descriptions: ";[ğ四三二]9_",
  },
  {
    codes: "  [:$(.]0.@>.:|.};",
    descriptions: "(\"_1 )} ?;]&八ı十 0ğ十@{@{\\(;&@\" *七#8@七 >ş,%",
  },
  {
    codes: "  ?七|8.\"6.2&.5<.3#",
    descriptions: "(%.1[/ ,一}=?#$&|\\",
  },
  {
    codes: "[/.#/",
    descriptions: "İş九22:,四,ş 三|]\"三",
  },
  {
    codes: "  :<四].八=.&四.2@.$七",
    descriptions: "二六@şı35ı@ #%五@.",
  },
  {
    codes: "  ;6;{.+=.@1.|二.$三",
    descriptions: "六.*/八 \\+8şı7}$1ı",
  },
  {
    codes: "(四六|.|6.五%.}\\.一.",
    descriptions: "/37{6 #ö)ü88ü 四\" |0/一\"九5=ı",
  },
  {
    codes: "#,;{.;七",
    descriptions: "{八六&%十69 ç6::@4四), @#\"八{二6?八 <: 七79._%",
  },
  {
    codes: "  [[97.2@.)).4\".'十",
    descriptions: "İç; (6ş ^2%|4$一69",
  },
  {
    codes: " ",
    descriptions: " - 一1ğ37){}% ",
  },
  {
    codes: "    <三*7.>(.十/.六,.90",
    descriptions: "八三五八6 七%九şı四%?/ı6八0^ 5ç@ $5#/ );'[[?#\"0",
  },
  {
    codes: "    1>&四.$*.@}.&@.[)",
    descriptions: "9]8şı\"六ı) .三十$5_<& *ç^ 8{,七 =+九^6/@四>",
  },
  {
    codes: "    0四十<.>\\.=8.&$.|?",
    descriptions: "[/$#2 (?,şı\"<四二ı十9.9  ç}02|)*5",
  },
  {
    codes: "    15$]..].}\\.+?.;五",
    descriptions: "*一&şı|3ı= 五=\\六六5%5 ç#.$^,五九",
  },
  {
    codes: "    九63五.8八./1.九>.一%",
    descriptions: "^/:九5 ?66şı'十;9ı20三$ %五九\">九",
  },
  {
    codes: "    #74&.&&.\\=.9九.&6",
    descriptions: "二十{şı二/ı} :\\<一?%八3 八七%),*",
  },
  {
    codes: "9=.&九",
    descriptions: "",
  },
  {
    codes: "71.九&",
    descriptions: "",
  },
  {
    codes: ".五.<4",
    descriptions: "",
  },
  {
    codes: "(<八>.八{",
    descriptions: "\\一)];9#@ 六.ğ_4 ]ş*2",
  },
  {
    codes: "      二=8\\..0.=..89.\\二",
    descriptions: "{_#74 一={şı=六九{ı'9;九 六{:.\"+二",
  },
  {
    codes: "      @7/{.7].^7.二=.2'",
    descriptions: ">$1şı/1ı( ;三三}(}#; 4;十_}<四",
  },
  {
    codes: "      二){$.[7.1九.(6.{?",
    descriptions: "]三8\"三 }一$şı#7%}ı3&四% ]$<45.八",
  },
  {
    codes: "      ]53[.1&.\"'.七3.;(",
    descriptions: "]六&şı四%ı[ ?^+)>五\"九 三三一()0{",
  },
  {
    codes: "      &_&[.@^.5:.<&.@]",
    descriptions: "^七 \\ğı;三ığı ? %6.ı {\"ç76 681四)五 @/ ş1)|19.八",
  },
  {
    codes: "      .^八{._^.五9.三一.6十",
    descriptions: "?5ğ&%五6&1",
  },
  {
    codes: "      1^?,.',.[\\.=6.:5",
    descriptions: "二@{十@)6七 ,:(二 3, 三</ı四 =>#;|\\四 >四 '.6九 _五ğ9<<一ı @(九ç#<8.ı( 三+_?$_六一, 68四}{}*八=",
  },
  {
    codes: "      $七)[.$^.<六.;四.十8",
    descriptions: "八3ğ\\$198一",
  },
  {
    codes: "5).\\一",
    descriptions: "Ö:,* '六09 >(;ş/ 九ü5六ü 156#\\三^三 (\"十6_九 @ü七[ü\" ]{\"5七6一# '8 五\\:>%",
  },
  {
    codes: "  \"'1五.八*",
    descriptions: "\"[7#九 .ü_,ü \" ,[&8;>'七",
  },
  {
    codes: "    701=.@\".}/.>$.?八",
    descriptions: "八六五_,?%% 七一^二]/二&九 _'+0七一'",
  },
  {
    codes: "    ,,$..[8.8九.2^./[",
    descriptions: ";ü,?十^ ._+一 /}4六 5{2?@' _ı六%二8ı{@八?, 八&;九/6/? 一八&八 /六九* ))*(二|>二@ 8)81八:)",
  },
  {
    codes: "    {一九\".(九.&{.:[.;^",
    descriptions: "7]ğ*<<六一'",
  },
  {
    codes: "    /8十_.%7.一{.^?.+%",
    descriptions: "十十*[十+$;",
  },
  {
    codes: "    <五1'.4一.?9.四三.#[",
    descriptions: "@;,0十,+{ _'7[ 六%#] \\一\\/8+四3六",
  },
  {
    codes: "    <>$+.四二",
    descriptions: "/(6{^$2@ 6,;'|%ş60 四七ğ十{ 8九九3>八/>[十八",
  },
  {
    codes: "      :}*6.$<.2^.^七.}二",
    descriptions: "七ü三 @%.; 19一七 一26六0{ 5ı5\"七)ı三0^^",
  },
  {
    codes: " ",
    descriptions: " - - - <+ğ五7+@74 ",
  },
  {
    codes: "        %@四..<7._..|八.:}",
    descriptions: "İ5}] 4% <四7> ;ö>ü=一ü&=.#五&.7",
  },
  {
    codes: "        :|1).+七.一五.}9.;,",
    descriptions: "十[ğ$.5@=}",
  },
  {
    codes: "    十./+.]?.二,.<>.{&",
    descriptions: "[+;<<,三_",
  },
  {
    codes: "    ^|*=.三|.{3.三[.?0",
    descriptions: ">'[1二.^/ ;1 $=&一 *747七'^]:",
  },
  {
    codes: "    6679.3:",
    descriptions: ")}六七%_<# .[*#2|ş_' 七一ğ++ 7/六(4+六$^|=",
  },
  {
    codes: "      四#(五.93.8六.$六.8五",
    descriptions: ":ü0 五{<% $<2> >>*\\.) &ı6&=三ı+32/",
  },
  {
    codes: " ",
    descriptions: " - - - {,ğ]_<<6三 ",
  },
  {
    codes: "        &&8'.十三.2*.&'.6七",
    descriptions: "İ\"${ _# ''5; ?ö?ü+,ü$11'十_十&",
  },
  {
    codes: "        三}\\#.[三.3=.}(.%6",
    descriptions: "(}ğ*43^五_",
  },
  {
    codes: "%^.八9",
    descriptions: "9|/ }&五_.四5 #]ç.\"四_\\, 5ğı9&ı? (,(三(](^7] % 八 {$64 06|0 &:|#) &+四三)@0三+^九",
  },
  {
    codes: "  5>9&.=2",
    descriptions: ":ğı三9ı( 1八3一4&+0]. % & |/>五 1#{十 },一\": 一'>?,八03一9| )四\\六7 >ç9<^$ &<=八$",
  },
  {
    codes: "    )一七=.(4.0(.{^.9<",
    descriptions: "|_.\\=_([ %;五\";7./九 >六@*3.5",
  },
  {
    codes: "    一五[|.<=.}].8\"./@",
    descriptions: "\"ü?,:+, :四/五.=:+, \\(*( 三+513#=_4 @#>十{4六",
  },
  {
    codes: "    }[5三.$@.九;.十八.(=",
    descriptions: ")3ğ#一}+6三",
  },
  {
    codes: "  ;<$^.%\\.&|.二\\.3)",
    descriptions: "7<ğ十6?六%4",
  },
  {
    codes: "一4.9$",
    descriptions: ",三= (;},.:八 ]\"ç0[四83 ö\\]; 六2)@ ?{一ş? >十:七1九=, (二0.15 9& ]+.二_",
  },
  {
    codes: "  7]\\:.((.<*.2六.+>",
    descriptions: "[ü5%)= ,一七% .7}十 ,_:<*< #ı六){%ı(\"2[",
  },
  {
    codes: "  }]五{.,|.六@.^=.六七",
    descriptions: "^?@九七|#@",
  },
  {
    codes: "  二+&+.9,",
    descriptions: "8{八3?\\+1 十3十一8*;三&",
  },
  {
    codes: "    6\\5八.|&.(=.$=.七|",
    descriptions: ",'7四^十三 7)'/}四六",
  },
  {
    codes: "    '^8{.(%.5*.^六.5%",
    descriptions: "_四ğ.八?^九^",
  },
  {
    codes: "  :]9二.:%.六)._&.]&",
    descriptions: "6,一六 $,_\"|\\2?;",
  },
  {
    codes: " ",
    descriptions: " - 75ğ+3六7,1 ",
  },
  {
    codes: "    3%^}.<三.&5.%7.\\0",
    descriptions: "İ四[= 四六 12#4 八ö]ü#二ü五<%3|5{%",
  },
  {
    codes: "    3%_\".9,.){.%*.@&",
    descriptions: "(八ğ;$2三<&",
  },
  {
    codes: "2?.5\\",
    descriptions: "/;$ 二四 %).8| %%ç[*, 六ğı$+ı? *(?七/=]\"6= % & ?四^> 8%9六 /四*@七 ,\\$]3%(8七&二",
  },
  {
    codes: "  ",
    descriptions: "&%{|三%_,#%十<3;{二0 5八{(ç)",
  },
  {
    codes: " ",
    descriptions: " -  1ğı8九ı二 7+二{.四:三四) % 九 8=|五 ):.> 七.%三四 ^=七324#2#@8 68@4( 8ç4];[",
  },
  {
    codes: "    8;}+.8九./3.3三.三%",
    descriptions: ":>4@>/(: +@|)>=}<# >\\_<+}'",
  },
  {
    codes: "    *60三.8>.*#.?,.{6",
    descriptions: "{ü+}3:, 7三>五:+1九, ;+%八 ]四一@1]五$< 1$[7.'7",
  },
  {
    codes: "    四90%.4&.+8.九=.六,",
    descriptions: "+6ğ(二十9@&",
  },
  {
    codes: "  /六二^.五六.3/./\".6@",
    descriptions: "九=ğ四五;=一&",
  },
  {
    codes: "[,.\\二",
    descriptions: "Çö七5ü 40$( ö?五ü八ü <=?四 $七ğ). ,)+?|',( (三^9>>7/ ö*2ü 56//4:_;{ı )五]",
  },
  {
    codes: "    @?*@.:8.0七.八8.$9",
    descriptions: "十ğ\"'七ı&)=|ış 十89@ }ğ,%,ı&六ış",
  },
  {
    codes: "    ]#).._/.0六.#^.十:",
    descriptions: "{&}一九]ış",
  },
  {
    codes: "    0'十/.>^.\\^.\"1.)3",
    descriptions: "@6<五三ı *三.@0*>三 一九/</七三\"8>8",
  },
  {
    codes: "    七.五九.<一.0].四7.\"7",
    descriptions: "&七三]ı七ı",
  },
  {
    codes: "    {^(_.>九.5/.?'.91",
    descriptions: "0' *九四<ı= < $:.[\" /;* </+2二一%四 五\"三)8:八 +\"一)十>;七'% \"%五(ş五/{",
  },
  {
    codes: "    1二四#.=/.{七.\"}.;三",
    descriptions: "一}ğ二}{二1}, 十ğ{)>ı,4{七ış =^^六 1ğ八.6ı24ış",
  },
  {
    codes: "    .93}.%5.六'.]\".@<",
    descriptions: "%|ğ#&200], 七;一三;4ış",
  },
  {
    codes: "    {[:_.,&..九.一/.#二",
    descriptions: "7)ğ:五_?0$, 2,+\"{ı 五四一三^七{& 七)/十九十一八1.5",
  },
  {
    codes: "    ;(%)./一.\"7.*&.}|",
    descriptions: "_五ğ9|1一%,, +四37ı9ı",
  },
  {
    codes: "    }六?1.'+.63.|^.{>",
    descriptions: "十ğ(三7ı四{*|ış 六49, +ğ*&五ı9_ış",
  },
  {
    codes: "    71_十..5.三{.]|.:<",
    descriptions: "&五3九三<ış",
  },
  {
    codes: "    )[#0.)'.|:.>>.[6",
    descriptions: "7$,6,ı ?53:2(1* |::%@六1{*89",
  },
  {
    codes: "    八|@3.7[.\\8.;).九二",
    descriptions: "%)1八ı7ı",
  },
  {
    codes: "  /2八>.+\\",
    descriptions: ":_ğ9*2$6#",
  },
  {
    codes: "    [5^2.<?.#$.9,.[@",
    descriptions: "0ü*一}* <4十] 九.0% \\8一|^% ?ı<五6六ı{八三八",
  },
  {
    codes: "    .=8_.%一.)7.]7.5'",
    descriptions: "十@ğ八^五4五>",
  },
  {
    codes: "\"0.}8",
    descriptions: ")一ğ+# ö=九\" ?&三> 55一ş1 [\"@(七^\\六",
  },
  {
    codes: "  \\四08._八.九).4+.'8",
    descriptions: "0ü*六@9 >|14 五四@] 3=0三9六 8ı]*五>ı%>39",
  },
  {
    codes: "    @+&=.]].@'.28.17",
    descriptions: "\\ğ一%四ı1四4六ış \\<六5 ,ğ八5一ı三2ış",
  },
  {
    codes: "    \\6$&.*1.六;.4$.六)",
    descriptions: "六8'\"&一ış",
  },
  {
    codes: "    ^五二十.,'.$,.01.;*",
    descriptions: "/}\\:'ı \\>1,97'5 /}|一6{{2十@七",
  },
  {
    codes: "    十@)$.二4.(\\.八>.十九",
    descriptions: "@1(8ı@ı",
  },
  {
    codes: "    (_?一.3@.三0.[..1$",
    descriptions: "@ğ|,八ı四|九+ış 83)] {ğ*六1ı$0ış",
  },
  {
    codes: "    _|'八.一..\"一.${.三八",
    descriptions: "\\}八2八八ış",
  },
  {
    codes: "    4六&6.六&.九'.;十.6=",
    descriptions: "=|)六;ı ,<6?九7六' 3<八',#一十五:+",
  },
  {
    codes: "    十4\"+.&?.[四.],.##",
    descriptions: "1%:;ı0ı",
  },
  {
    codes: "    }.1[.^[.(:.*十.?四",
    descriptions: "\"ğ;3>ı))'{ış 十,二9 4ğ六92ı@八ış",
  },
  {
    codes: "    9:二^.九}._$.*|.#四",
    descriptions: "四[){;}ış",
  },
  {
    codes: "    ;5十%.>}.4十.):.03",
    descriptions: "<)**&ı 九^^&^3\\. 5十七$_$3#四2%",
  },
  {
    codes: "    '?三4.[十.(四.一5.<'",
    descriptions: "四1;\\ı%ı",
  },
  {
    codes: "  _#@_.,5.6\\.:'.7.",
    descriptions: "{5ğ=,#,05",
  },
  {
    codes: "[十.十|",
    descriptions: "十\\%5[ {=八九六一ı, [9一*} 5(&2) 六五&'5六#=:| *一 六@]>一 1|1七?ü(.;&%五八7 @>ğ]( \"=&",
  },
  {
    codes: "  ^一#3.八{.%<.八(.\\0",
    descriptions: "#@;三4 (七\"5>\\ı",
  },
  {
    codes: "  |]*0.]四",
    descriptions: "(#{:| \\?})| &3,2854+:& +4 .2*{> )\\:>5ü|十八*@1]九 <}ğ<1 &0. ü/ü0四=%六 ",
  },
  {
    codes: "    七]?四.九0.,:.<\".十.",
    descriptions: "+\"]^\\ 5.\"?8 七六:]=])十?八",
  },
  {
    codes: "    '五二|.?4.四一.%一.#=",
    descriptions: "[#8@^ *80八二%<.'*",
  },
  {
    codes: "    }五87.'四.32.}%.5四",
    descriptions: "&^ğ&四.$/+",
  },
  {
    codes: "'*.{}",
    descriptions: "İş<0四3{}1ş 一+=?$",
  },
  {
    codes: "  .+)1.;[",
    descriptions: ".]:şı,;ı7 \\)\\/2",
  },
  {
    codes: "    .'}8.;二.$}.一}.)*",
    descriptions: "\">\\6 .@ |十{4三 =[3@:",
  },
  {
    codes: "      @:&9.|}.[|.一4.5\\",
    descriptions: "+^[*$ ş]四,%|4六 ?;@%3",
  },
  {
    codes: "      :0九_.\\9.{4.(9.五|",
    descriptions: "26ğ七&,七一]",
  },
  {
    codes: "  七0(一.3九.4:.[:.}2",
    descriptions: "九七,+: 三$一şı9((;ı",
  },
  {
    codes: "^二1=.=7",
    descriptions: "96%%/ 'ö五ü\",ü 3[ }三\"?@*+>ı",
  },
  {
    codes: "  ,/$@.><.&*.)十.三三",
    descriptions: "*)六şı7十ı# //>}1|&{ ;#%'四\"7",
  },
  {
    codes: "  八八=八.19.[(.二四.:(",
    descriptions: "十&^六一 七8二şı(1:,ı一{'= 七#9,.+九",
  },
  {
    codes: ";{09.%|.0=.(}.8九",
    descriptions: "/1&三1 8<'四 五7 []十5五四*3 _3七@ #}一07'",
  },
  {
    codes: "#}.一三",
    descriptions: ")\\\\{+$>@ ç5/九十_二{, 四#[8*=二#( |四 八2六\\3三",
  },
  {
    codes: "    /{四一.&(",
    descriptions: ">0/şı五,ı八 一)%四%2@& 3六=[3八#",
  },
  {
    codes: "      ?=?0.0九.(:.2#.)*",
    descriptions: "İç4 ?七ş ?5三52$三@<",
  },
  {
    codes: "        >6三5.$|.*\\.1?.=<",
    descriptions: "İç6 :';7 |^/一=五_四)",
  },
  {
    codes: "        +十'二.*<.:|.8七.)'",
    descriptions: "Ç8/2_五]十",
  },
  {
    codes: "    7三{一.一%",
    descriptions: "$三90$ 3${şı41.+ı&_7七 3=%_;2>",
  },
  {
    codes: "      ^二三五.;5.9|.1%.=8",
    descriptions: "İç八 |五ş ?<十7一+\"九(",
  },
  {
    codes: "        /=\\七.十[.($.&}.%1",
    descriptions: "İç五 )五三六 <,_0%6_81",
  },
  {
    codes: "        >.五3.7{.=5.二0.'%",
    descriptions: "Ç八\"'9\"#九",
  },
  {
    codes: "    75>*.八5.^{.}5.41",
    descriptions: "九;<şı二一ı二 0@八五7'九1 ]八8|1*(",
  },
  {
    codes: "    ^2&..三四.二#..7.43",
    descriptions: "\"(+=9 ^9/şı1^^2ı一:]\" $+<<>三七",
  },
  {
    codes: "八?.|4",
    descriptions: "四,&{*'四% 六?8九?六, 3},]8>十7, ş五$九<6十> |十 ]十2四[%7[三",
  },
  {
    codes: "  $9&\\.<'.<一.五8.5六",
    descriptions: "^})şı,'ı9 &\\<五)0一2 /3八\"5五}",
  },
  {
    codes: "  =]>六.^>",
    descriptions: ";{/九. ):十şı?}一^ı#.五0 |&七十6,7 ",
  },
  {
    codes: "    |六三=./5.}'.}\".2.",
    descriptions: "Ş,九:九二*4",
  },
  {
    codes: "    8]7十._+.九..8*.九%",
    descriptions: "1\\ğ#/四/7[",
  },
  {
    codes: "|3.^}",
    descriptions: "+[*_\"=/# 9八$; '& 九$\"ı' 一二}.四3' 十| |二={ 七#ğ<\\8*ı @|=ç:八四/ı (二}']'*^@,",
  },
  {
    codes: "    %_*%.1'.(四.:一.>&",
    descriptions: "{&6şı)_ı( _(;{二}09 \"<+\\^]5",
  },
  {
    codes: "    .0=七.)六.[/.1<.七]",
    descriptions: "5五20: %>/şı0九*2ı四8+) &/二'6?%",
  },
  {
    codes: "  542_.三,.3\".|%.九一",
    descriptions: ")八02 ]8ğ_3{]ı ;;六ç五*3?ı",
  },
  {
    codes: "十五.^{",
    descriptions: "六/#/?;@/ $1ğ@} _ş五+",
  },
  {
    codes: "  ]三0..&4.3:.;;.六十",
    descriptions: "九三九四8 58四[\\}%8. \\五,5'677, ı,1:[', 7ğ ]# '三六,八{;]",
  },
  {
    codes: "  .?%{.[[",
    descriptions: "0?ğ'2(:1&",
  },
  {
    codes: "      \\七{(.}..4一.33.47",
    descriptions: "1[|ı>$ 八ö五ü八=üş",
  },
  {
    codes: "      /(5+.#|.九五.二@.18",
    descriptions: "]8ğ66(&十\\",
  },
  {
    codes: "      |(:四.8?.$@.6}.三$",
    descriptions: "?^%&八6%, 5_一*;,八$@, $四<?.|&八, 5,0ç|) ç\"\"8<五_, 3七 \"=7>+28[]:",
  },
  {
    codes: "      8;?&.7\".|1.7'.二7",
    descriptions: "'=ğ(,二六@@",
  },
  {
    codes: "}<.一五",
    descriptions: "_ü9 }| 十七'五) (${&3,?%九# }三$|[)'_ş, 0[四二, ş{_2五 四八69 |'ğ^四",
  },
  {
    codes: "  %五{四.六4",
    descriptions: "*ğı七}ı九 ,九$^|一九[<1 ]ç#七&三ğ5 (\"八ı %2ğ ,;5=ı % ('7 |六ç{1]\"'<^7",
  },
  {
    codes: "    九%?十.||.九..4十.'^",
    descriptions: "_|4 十五\"<二/5三八*ı $ 6{.\"三 60ç=,五7\\ 八八{ı\" ;=\\\"7'0(_}|] \"4:四八:6",
  },
  {
    codes: "    \"0二+.)%.二5.'/.44",
    descriptions: "\\)ğ0[0)_,",
  },
  {
    codes: "  八_(4.\"十",
    descriptions: "&ğı56ı$ [八&|四'*?5$ =ç{0九>ğ六 .&七ı |五ğ //<三ı % % '> #(ç6十, :1&%]",
  },
  {
    codes: "      '#'3.九+.9=.>&.?7",
    descriptions: "(?. \"$;四^^\\一+#ı 二 [+.三_ @)ç;+[六\\ \\.&ı{ >6四五'<58_/{| /六18+/*",
  },
  {
    codes: "      五,([.08.;九./二.5:",
    descriptions: ";八ğ5,二]八九",
  },
  {
    codes: "      七'>6.四5.一7.{#.}三",
    descriptions: "四'\\ \\48三{6四五6(ı 7 八4.'4 '_ç(64'5 二十\"ı1 二%4%|六9[$一\"7 ^一八_,十5",
  },
  {
    codes: "      1730.3@.@\".{*.](",
    descriptions: "十/ğ&3+}>{",
  },
  {
    codes: "  }/+,.()",
    descriptions: "İç=&?<ğ\" 一@\"ı ,_ğ 八<88ı, 9ğı四*ı五 .,{/($#十)=, % >' +ı [?ç二一 ?\"^三:",
  },
  {
    codes: "      (_>六.(7.)一.$,.(<",
    descriptions: "<ü七",
  },
  {
    codes: "      +4;八.]7.*五.七一.+1",
    descriptions: "\"二七'\\",
  },
  {
    codes: "      ^)八<.<}.六/.六3.1.",
    descriptions: ",ü)",
  },
  {
    codes: "      一16).4|.|?.一\\.六?",
    descriptions: "@^]八<",
  },
  {
    codes: "  0%八\".一.",
    descriptions: "İç三+七8ğ_ ,26ı 0九ğ _八,&ı, 5ğı>%ı. /%26七/>6九十, % >_'; %+ç{9)\"]",
  },
  {
    codes: "      /'2..><.7八.二0.^4",
    descriptions: ")八/ ^_%\"%:3;七6ı 九 <7.2[ 二5ç|五三3% ];8ı\\ 35?+110$九90^ 15,%五四'",
  },
  {
    codes: "      ;2\\五.一%..五.7*.六八",
    descriptions: "}4ğ5\\65六&",
  },
  {
    codes: "      60四%..*.四|.四4.>$",
    descriptions: "8\"[ _+十*八<3(2'ı 六 四8.5八 '[ç6/三=2 ;'}ı( 七六+07#8$+,\\\" *.四三二一\"",
  },
  {
    codes: "      89=:.#9.四2.%9.三&",
    descriptions: "&]ğ+9二+2*",
  },
  {
    codes: "      ([8*.三2.{^.}@.2]",
    descriptions: "'@, %=\\[五/\"0七'ı | \"\".&3 (7ç五;([; 9?.ı3 三'#$8?九(\"四5一 4%+&}%}",
  },
  {
    codes: "      \\<?5.五0.2..二七.?>",
    descriptions: ")=ğ=:一五48",
  },
  {
    codes: "+二.=九",
    descriptions: "(ü8 48 0四4=\" (|_八?,,$'5 6}>六5三ş 四*&$ $'1>5 ş\"三[' 3^ \\0 9一ğ7&",
  },
  {
    codes: "  96.\\.#2",
    descriptions: "9#三, {六二^ü1 <\\$@ 三2ğ五& }}:ı ş/\"^)/+十.8 5#4#, 2ğı六]ı\" 一.'1七@|^22",
  },
  {
    codes: "      十1++.}?.*(.8<./;",
    descriptions: ",1& <$=十;$8=\\+ı <,八 六6.ı 三,ç三^3\\6 *=+ı: ]>66^五八二四九3^ &&*:%9(",
  },
  {
    codes: "      &{九'.六=.%6.>二.,9",
    descriptions: "八[ğ4]0四/*",
  },
  {
    codes: "      [0/].@?.<2.<七.)*",
    descriptions: "|四= @{八8六8_0$@ı 2,_ }6.ı +%ç<[}一二 九八@ı七 %六\"六(0}923^< 4*/.@=七",
  },
  {
    codes: "      |^(4.<3.七九.6*.8&",
    descriptions: ":_ğ8/?七..",
  },
  {
    codes: "    $6%<.36",
    descriptions: "İ/689 ş+15# [^八3 八^ğ6> )$}&*<$ı#ı}ı [7一1?++八( [ç五三七77$>五五+ ",
  },
  {
    codes: "        5*'0.=6.'%.八*.4;",
    descriptions: "\"#5 六八#.?;六二27ı 0,{ &5.ı 五3ç/&&36 十八七ı4 1八=?6;;(%\\'六 三6;四]62",
  },
  {
    codes: "        八<一三.六7.七七.62.';",
    descriptions: ",1ğ.六{]/0",
  },
  {
    codes: "        [4}>.6六.三4.+7.6@",
    descriptions: "??% 3$(>{(4$@|ı <,+ 九\\.ı _^ç:九>$) 0四$ı6 _五6九|=<?[?<_ ][3(_@\"",
  },
  {
    codes: "        ^<#7./7.5..9>.一0",
    descriptions: ")?ğ71%十'八",
  },
  {
    codes: "    2$6^.\\;",
    descriptions: "@5ğ一3十;:/",
  },
  {
    codes: "        ]四/8.>@.4@.[五.}\\",
    descriptions: "]ü九 ç3<:@{{{ı 1ç三七 +%四 六'}@+94',/ı $+1 >=.ı [6ç0|(}2 七七#6",
  },
  {
    codes: "          7?:>.4九.七|.九}.\\0",
    descriptions: "八四+ ]四2\"一|)^6?ı ;,] 二7.ı 六.ç]3\"五( &1;ı2 一五(0@十八(五00六 %<]4226",
  },
  {
    codes: "          9+07.<=.二;.51.((",
    descriptions: "%^ğ+;}/三+",
  },
  {
    codes: "        3四四6./三.4#.5九.?$",
    descriptions: "1&' '3四2^+}2{<ı 3,, [6.ı 6[ç3一(5\" 九七)ı$ 一1+[一:16三六7> 九{}五/4@",
  },
  {
    codes: "        (1@6.四&.9@.^5.9_",
    descriptions: "三*ğ^三四五2\\",
  },
  {
    codes: "    '三#,.\"四",
    descriptions: "İ9一七( ş8$一6 0/,8 ?*ğ$7 @[0:>5^ı3ı=ı 3/;0三8%@& =ç=七(:?_%三三'",
  },
  {
    codes: "      ;%1:.=*.=9./二.1^",
    descriptions: "İç五32九ğ1 @[一ı 一?ğ 四,}1ı _ğı)0ı7 |八>/^/8.#> % /' . \\%ç':?53五,<",
  },
  {
    codes: "      {%七}.[<.#一..|.六\\",
    descriptions: "İç66六@ğ$ ]@|ı ::ğ 九#三\\ı 六ğı0}ı三 %'六\\7=五@}1 % ]' = ']ç一] /&&4) % $*'5",
  },
  {
    codes: "        七1|\\.五五.'\\.六).15",
    descriptions: "3<\" 六[{*|>^+.#ı +,8 四十.ı <6ç}=83> ||3ı四 },+)0/{$<\"': )^4,/>0",
  },
  {
    codes: "        \\7%%.2;.'\\.*四.五(",
    descriptions: "=八ğ%@九三二_",
  },
  {
    codes: "        $\"3九.^五.|'.04..2",
    descriptions: "四#] |七八6%{四一\"九ı +,八 *五.ı >#ç}六2{六 |2}ı* (45&23二*''十4 十=七八:+9",
  },
  {
    codes: "        六&\"\\.{|.+二.八二.24",
    descriptions: "%8ğ+'9{8,",
  },
  {
    codes: "    +'7$.8\"",
    descriptions: "{3ğ*_=5%+",
  },
  {
    codes: "      九/%:.3@.)%.66.>9",
    descriptions: "İç/(9.ğ@ 3:\"ı >1ğ 0(+{ı ]ğı@一ı6 4一\"_;?_二<1 % 七,/' 五 =/ç六0七8$>(8",
  },
  {
    codes: "        91二9.2四.三).,3.=八",
    descriptions: "0三@ 二二,%4)9五|一ı 一,7 |8.ı =(ç+\\(,2 七九;ı% 九)]八^&4<}十八4 六/9十%)5",
  },
  {
    codes: "        ^<<七.;4.一4.]6.[(",
    descriptions: "9三ğ(五4十+六",
  },
  {
    codes: "        十1|四.=+.七二.三;.,'",
    descriptions: "&$一 :\"9<[3{@3#ı ^,4 %@.ı 六]ç九<\\\\3 0|'ı\" *1\\.|{@*\")十: 6#,@)))",
  },
  {
    codes: "        ''一七.%9.0].四九.八&",
    descriptions: "?*ğ\\[七,1七",
  },
  {
    codes: "\\{.)@",
    descriptions: "?+ğ<@/; <+>ı5二,六ı, 3ı|四ı1>ş;ı5ı[\"ış &ü\\ +) 五8*\\0, >二^./ 五\\ =}ğ_\"",
  },
  {
    codes: "  3$<1.\\六",
    descriptions: ">(ğ十=&",
  },
  {
    codes: "          /5九十.|:.4%.>2.]=",
    descriptions: "7.ğ二]5",
  },
  {
    codes: "          九;8(.四[.七3.六@.|)",
    descriptions: "33#*\\",
  },
  {
    codes: "        )28%.@>.,4.35.二1",
    descriptions: "İç78^二ğ7 &8/ı \\[ğ 3??]ı ]ğı.一ı) ;\\=&{六/三[= % 三'ü 0十ç_. ?;八_=",
  },
  {
    codes: "        4%)4.64.十\".9四.七'",
    descriptions: "İç=5四0ğ} 1;五ı 76ğ \\四'%ı [ğı<1ı> *四^二)8\"九?: % ,'#ı .]ç3#七}>",
  },
  {
    codes: "          %;?^.}2.._.^&.@|",
    descriptions: "\\1ğ_^&",
  },
  {
    codes: "          [\\四%.3\".三+.4=.*七",
    descriptions: "6七\\<$",
  },
  {
    codes: "        \"\"6).4}._十.+九.\"<",
    descriptions: "İç\\九十6ğ@ 8#'ı |^ğ :五|0ı [ğı?:ı+ 十|2(_]3_|' % @'ü /,ç6_ $三二%&",
  },
  {
    codes: "        )十\\;.63.>6.3}./*",
    descriptions: "İç]\">\\ğ三 }@4ı ],ğ 二';;ı 六ğı三8ı> ?四>六0四)四.( %  2 '$ı 二3ç_八*1九",
  },
  {
    codes: "      .[#/.+%.五2.,?.八?",
    descriptions: "十ğı0三ı一ç四 % ,,='十\"= 十< @七>ı 三ü: ')ğı, % ' ^九九3\\9 (6*/)4? ş二七,% \\&七[{) }二?六",
  },
  {
    codes: "      五19@.九&..五.%5.[,",
    descriptions: "@&ğ,七2&2@",
  },
  {
    codes: "        2(八2.八}.\"\\..四.3四",
    descriptions: "İç:*<%ğ3 ;#>ı :ü= $一ğı %3<;ı 9ğı^|ı7 1<三18四^@3* %  (,&'3 5#ç_[&+}+_#",
  },
  {
    codes: "        ?2二1.$四.?|.三5.=二",
    descriptions: "İç一/7.ğ5 |;一ı 八ü8 ]4ğı :四六9ı 二ğı<?ı二 九\"00三:90七1 % =,二' } 5/ç@+ }1?(9",
  },
  {
    codes: " ",
    descriptions: "          % 91']\\ _/ç&\\*7\"八七@",
  },
  {
    codes: "        |一}#.;).6+.76.?}",
    descriptions: "İç二:+9ğ} 六,二ı ^ü十 1六ğı 二0&<ı 4ğı0六ı: ?#2<五75九#\\ % <5'>< }3ç;(五[$",
  },
  {
    codes: "        九$/七.#^.[\\.2<.;$",
    descriptions: "İç九15+ğ[ 2七*ı 8ü十 9{ğı 六\"九7ı .ğı31ı# 5八8648六)三9 % 八'ü 0五ç}(六5|6四(",
  },
  {
    codes: "        %七六/.:*.\"0.'&.86",
    descriptions: "İç$6>六ğ. 七<|ı =ü_ _2ğı ;8>\\ı 7ğı<九ı4 ']+一[二6一;_ % _'ü $]ç87 <_二三4",
  },
  {
    codes: " ",
    descriptions: "          % <'=ı ;.ç/>:[(四>8",
  },
  {
    codes: "        .七>七.:*._+.;\".8@",
    descriptions: "İç;七^;ğ八 9四%ı 0ü[ %六ğı 6*|%ı 八ğı五七ı1 五@$}|15]一九 % +'+ı ([ç五,,十_",
  },
  {
    codes: "  +%,\\.\"^",
    descriptions: "二=ğ6^7十24",
  },
  {
    codes: "          +\"._.]$.|].,八.五<",
    descriptions: "İç{@\"9ğ八 _二五ı 60ğ 一十6.ı 7ğı#]ı9 3\\<一七二$\"=[ % 5,7') (四ç<8九[{0$'",
  },
  {
    codes: "          /+五@.2;.,5.?@.[八",
    descriptions: "İç\"%>}ğ| &64ı 2五ğ 6==:ı 3ğı{<ı@ _.#:八五二^{七 % 七,<'六 7:ç四< ?68*9",
  },
  {
    codes: "          \">\"/.#}.##.七一.>/",
    descriptions: "İç5%九五ğ* /6[ı 七.ğ 五*,3ı ^ğı(+ı& #8;:5@五[%} % >/'2: 3<ç5,+(8",
  },
  {
    codes: "          >三').+九.4'.&[.55",
    descriptions: "İç?>0二ğ三 三六八ı +\"ğ 六7六六ı 3ğı)<ı; ;\"十,>){?}\\ % =,三', 71ç;*22{7'%",
  },
  {
    codes: "          )7)四.十6._四.八^.一\"",
    descriptions: "İç@?5\\ğ. 九五,ı 九]ğ ;^|&ı +ğı@一ı? [';[.%)*}# % 9,3'8 _\"ç#7 /二4%二",
  },
  {
    codes: "          %六^\".]=.#3.99.}^",
    descriptions: "İç一><3ğ八 /4<ı \\+ğ #三(9ı 八ğı_十ı& :三1:三二1,六; % &三'+一 :(ç;十.\\@",
  },
  {
    codes: "          |\\6'.一;.:].;4.]}",
    descriptions: "İç]:=}ğ% 八_8ı 5{ğ |*^.ı 三ğı^/ı{ 178}2?>八([ % ,'ü 六)ç九七;#1>4(",
  },
  {
    codes: "          \\}}0.%:.{0.3/.%8",
    descriptions: "İç}>二七ğ三 ,,六ı )六ğ ,三+*ı (ğı九\\ı= ,587_/':18 % 9'ü _四ç8十 4_二三?",
  },
  {
    codes: "          二@\\?.^\\._).4%.七4",
    descriptions: "İç#{_@ğ[ }$2ı <'ğ \\?\"八ı 六ğı五8ı] 1二$十=.415# % ''六ı =#ç]\\#*{",
  },
  {
    codes: "          <>7;.八五.%$.6\".2八",
    descriptions: "İç>;]七ğ\" '+5ı 00ğ 三8)(ı 七ğı2:ı\\ 0\\9#1}八\\;一 % ='ü +六ç十1>.8.二&",
  },
  {
    codes: "          2六九{.$^.一|.^八.三(",
    descriptions: "İç2二\\6ğ3 0\"0ı 六'ğ &./+ı &ğı=6ı{ &#0]4二,3)5 % *'ü .二ç,$ {@$|/",
  },
  {
    codes: "          5|*六.15.+@.73.一|",
    descriptions: "İç七8/|ğ& (五@ı _>ğ ]]{七ı |ğı0/ı6 \\|8?{:6?\"> % 5'*ı 六/ç^二0$3",
  },
  {
    codes: "        一#,=.六四.\\[.%;.0+",
    descriptions: "İç,5@_ğ_ 2=_ı ]ü; 8{ğı \\八/2ı )ğı八,ı一 ,51(\\:{}^+ % 1,4'9 2\"ç9_%79|^9",
  },
  {
    codes: "        ^254.>\".4>.2九.二3",
    descriptions: "İç二6'四ğ{ 6$6ı _ü六 <\"ğı 二.六八ı &ğı5.ı二 {[_/一五?二9' % },['' \":ç=[ #@&五$",
  },
  {
    codes: "        _6=8.6九.<\".$^.1[",
    descriptions: "İç&<:.ğ\\ 一5{ı }ü七 *|ğı 九06三ı \"ğı6\\ı十 \\|\".<:?4四三 % [.',十 六9ç二\"四5十",
  },
  {
    codes: "        \"^5\".><.二$._8.['",
    descriptions: "İç\\<9:ğ7 **六ı \"ü\\ ^(ğı '六?}ı ,ğı四三ı8 九\"]/.//.$/ % /'ü \\5ç@\\十;,$@1",
  },
  {
    codes: "        9六8{.$:.:&.+..^%",
    descriptions: "İç.0+^ğ} 四,&ı )ü; 九]ğı /六#=ı \"ğı2[ı6 }=四#二八)8=1 % 七'ü \\八ç'= 0一>十\"",
  },
  {
    codes: "        945#.6;.9:.*三._二",
    descriptions: "İç:.二\\ğ< +1|ı =ü3 七,ğı 6#':ı >ğı\"[ı. /&_十@(三=八九 % 5'0ı ][ç六四@5:",
  },
  {
    codes: "|十.2<",
    descriptions: "@:}六?^@#4ı $四}十 (|(1十7#4^八 &一五>[十ş 71七#/ 7'一>:ı) +\\=\\ ?4#2九 ş56:$",
  },
  {
    codes: "  _}二4.四*",
    descriptions: "[82888|}四ı (1六* =@ _[三:{}\", #{3九{>>_四ı ]\"^< (#七7二27'=5",
  },
  {
    codes: "          *[十^.>_.7?.1\\.5\\",
    descriptions: "İç八:八<ğ4 %(5ı \\1ğ =四>\"ı 7ğı]{ı$ 二一?+%+;七_* % 六,#'一 *&ç四五77#}<}",
  },
  {
    codes: "          6}八0.3,..(.^三.一8",
    descriptions: "İç81>+ğ$ }60ı {%ğ =三<&ı 九ğı/=ı3 \"]/71_\"8>5 % 6,0'0 九8ç六\\ 一'@}'",
  },
  {
    codes: "          .>'}.96.><.2九.)九",
    descriptions: "İç六,七9ğ> =<|ı +:ğ <_:2ı $ğı55ı; }8@>>^35九_ % +['#一 ,_ç{5\\;4",
  },
  {
    codes: "          5]@四.\\}.}(.)三.9)",
    descriptions: "İç#<6+ğ; |四&ı :6ğ 1一\\[ı \\ğı+?ı. {])]5?}\"1] % 十,<'6 (:ç}]<&89十一",
  },
  {
    codes: "          +*5?./五.*3.<三.8一",
    descriptions: "İç0];;ğ, 九+六ı _0ğ $<_;ı \\ğı\\&ı. _6三@07/3九# % 2,六'_ \\%ç\"^ )92^$",
  },
  {
    codes: "          3+47.$八..<..4.]\"",
    descriptions: "İç>/{\\ğ( _八$ı ;4ğ $8^:ı 六ğı]1ı{ 八@八:?}(}九4 % 2@'%九 :&ç六&二{*",
  },
  {
    codes: "          67*6._^.95.1(.\";",
    descriptions: "İç6$^<ğ3 七;七ı 二4ğ 6#@8ı 'ğı??ı^ \\_*8^1四三_1 % 五,八'< <{ç=*,/4|*^",
  },
  {
    codes: "          #+:<.四\".4$.87.?{",
    descriptions: "İç一[六#ğ@ 08&ı $%ğ *7,%ı /ğı'\"ı三 ]:$)$[5一|{ % 8,&'八 @?ç]& )8%7三",
  },
  {
    codes: "          *6\"{.8(.\\3.().1十",
    descriptions: "İç<四#2ğ] 四,十ı /七ğ #@六1ı 八ğı8^ı一 >03/^9048二 % 十&'十6 .*ç=*4一1",
  },
  {
    codes: "          %4&6.;{.89.)&.}九",
    descriptions: "İç五&_|ğ3 9}^ı ??ğ ]\\_七ı 十ğı八四ı2 五^^(=>_:$] % 三,*'; 八十ç\\\"(七.0三八",
  },
  {
    codes: "          五?六0.十$.#(.$).九}",
    descriptions: "İç五_>+ğ七 二\":ı $7ğ ^2<}ı 5ğı#\"ı[ ;三[九[2/)二= % ;,$'' {#ç0^ ]九/九@",
  },
  {
    codes: "          .|<{.5七.|\".8七.九%",
    descriptions: "İç)]]\\ğ, (3$ı ;$ğ 三十3[ı 9ğı+三ı> 8;一>2#[(}' % =二'4= 1&ç*)八%|",
  },
  {
    codes: "          \"|六\\.六{.九#.__.(@",
    descriptions: "İç,][8ğ8 :97ı 十一ğ 0+九=ı =ğı^.ı= 八:一8八7+_.4 % 9,二'> )'ç十4+0七十}\\",
  },
  {
    codes: "          ]一6^.五#.@9.>/.$>",
    descriptions: "İç3}%十ğ] 九[8ı %&ğ ?一四8ı >ğı\"^ı' @]$2]>四<$' % %,7'* ==ç二| ^><四6",
  },
  {
    codes: "          $1*|./$.]三.*<.]}",
    descriptions: "İç#{@\"ğ8 %[^ı [一ğ '1}>ı +ğı四1ı十 $;*,9/}=<$ % 八,'>$ #五ç|$_\"七",
  },
  {
    codes: "          四@>/.4..七三.十..5一",
    descriptions: "İç2_%8ğ; 二11ı 五#ğı >1[?ı @ğı一=ı6 一\\+\\?}^*90 % .,二'5 [四ç/7.8^_*#",
  },
  {
    codes: "          @70}.%|.:0.,+.4\"",
    descriptions: "İç%七\\{ğ( 二?&ı >{ğ 3#二*ı <ğı四三ı, 0'7=%6}$/\\ % #,''[ 1?ç一9 _'>\".",
  },
  {
    codes: "          :38:.,/.{].八..六>",
    descriptions: "İç^}((ğ{ ([:ı .0ğ <4*'ı {ğı<三ı] )八\\$^?八\"九\" % 63'四^ |*ç十06;}",
  },
  {
    codes: "          (,^1.&4./%.二九.>6",
    descriptions: "İç七?%_ğ% ;六@ı 二+ğ %\"<.ı $ğı)十ı_ 3八9十#:{五=九 % :,:'十 [:ç]六;六)6十1",
  },
  {
    codes: "          &5'四.:>.7$.>3.$二",
    descriptions: "İç)十<?ğ十 ?|5ı 9#ğ }九9八ı ^ğı<八ı: ({\\%?2{五/# % 十,三': 七0ç+9 0\\五.(",
  },
  {
    codes: "          ,3<;./七.17.+7..0",
    descriptions: "İç=7;>ğ\\ 一&九ı #{ğ @_:2ı ^ğı七八ı五 四五}10%=)0* % /3'九3 5&ç0九%64",
  },
  {
    codes: "          [+@9.二*.[八.)0.:七",
    descriptions: "İç{%\"[ğ= \"^=ı 八一ğ 1(67ı %ğı1九ı\" 二一1?.0四6六= % *,六'一 ^}ç+/5四}/,?",
  },
  {
    codes: "          8:八二.+5.>三.%:.十4",
    descriptions: "İç*=2#ğ0 :])ı \\>ğ 5<&6ı \"ğı%?ı8 九五;五4九).,/ % 四,八'# %<ç<二 4<3&3",
  },
  {
    codes: "          '<}(.+<.\"|.#0.#三",
    descriptions: "İç四.<=ğ8 [[{ı *_ğ <7+七ı 4ğı('ı> 0六_0[九/\\_& % 1\\',8 .二ç*?$}二",
  },
  {
    codes: "  \"418.三.",
    descriptions: "\"六ğ+\"七1\\| ",
  },
  {
    codes: "      |>八1.&}.2$.=].二4",
    descriptions: "İç)5)[ğ? <**ı 8'ğ {五/二ı :ğı七3ı| *2>}%@.17六 % #,;'| _,ç九@一[.>$_",
  },
  {
    codes: "      #.|\\.二).|1..[.}{",
    descriptions: "İç一/\\\"ğ, \"}六ı :)ğ 2^=0ı 九ğı19ı. 三=1($*二*2@ % >,.'8 91ç0{ 1,'(七",
  },
  {
    codes: "      8,十'..十.五}.69.')",
    descriptions: "İç九五8八ğ} 九(9ı |9ğ /'一]ı _ğı;|ı< =#六89}五<.四 % 十十' >五 七十ç]&8=*",
  },
  {
    codes: "      四}9六.:(.(*.?^.1二",
    descriptions: "İç{_九7ğ4 22二ı 7;ğ 二九({ı <ğı1<ı/ \\二'93六.084 % %,='四 8{ç)[=1^六^?",
  },
  {
    codes: "      5/三八..(.九六.7[.[1",
    descriptions: "İç九\\1\"ğ? 二%^ı 十'ğ 二*3|ı 七ğı#8ı/ +:[2$*.\\.% % 8,四'> 42ç85 }@一/.",
  },
  {
    codes: "      .四07.9..九+.九八.^^",
    descriptions: "İç\\;五1ğ6 /*3ı 九_ğ [四/0ı 8ğı十9ı五 :(.34)>>一: % ?,'/$ ^十ç]^<7@",
  },
  {
    codes: "74.八]",
    descriptions: ":ü)92$ =1<] 1)>')\\ )9\"'/\\ğı 八2 #_ğ七& )十5ı /^ }ı1ı %*ğ七七&; 2ü一ü'六八\\9",
  },
  {
    codes: "  二四八5.二\"",
    descriptions: "=05'\\二ğı ",
  },
  {
    codes: "        1三;:.#一.&:.#8.^九",
    descriptions: "4/@ &:$3/7;}8'ı { _^.'ı ?3ç>]7=( 4(三ı0 四+$3/3)五0九\\] (+0一6\\*",
  },
  {
    codes: "        >,?(.\",.$六.<{.4|",
    descriptions: "|@ğ^五*+]$",
  },
  {
    codes: "      [+/'.>8.^{.2'.6二",
    descriptions: "六}:一5}{三' &7+%]]ğı",
  },
  {
    codes: "      三(_/.十;.?,.^&.(一",
    descriptions: "+'1十三; 七24ı ::7[#十四@ 一九五; }(\\;六4 五^;]'四ğı",
  },
  {
    codes: "    @八4+.|五.3$./3.09",
    descriptions: "1{ğ9.9(=^",
  },
  {
    codes: "  \\二<<.(@",
    descriptions: "(ü七ü&二二{; \\_5五214 _ü: ü3ü二%855",
  },
  {
    codes: "    \\四一..'<.(\".;九.,@",
    descriptions: "İç^(}九ğ* ;/{ı {_ğ 7[*3ı 十ğı一:ı@ 5\"76六1&}(四 %九# /&{7 >五>@ 七$十|| :?八@. %&6'+六六",
  },
  {
    codes: " ",
    descriptions: "      =( ?<4;1\"\\",
  },
  {
    codes: "    ]五:/.]|._九.^七.^2",
    descriptions: "İç*6](ğ一 <*#ı ,=ğ 八一48ı 4ğı+|ı5 \\]4@八二{,八4 %+十 6}2\" =#\"3 &7一)? 五]<({ %三'' .四#",
  },
  {
    codes: " ",
    descriptions: "      ;' |^\"一1\")",
  },
  {
    codes: "    04二二.?六.0>.\\\\.:8",
    descriptions: "İç七\\|&ğ% +_|ı 十五ğ 331<ı =ğı3/ı五 #三1@88四十七; %25' 九=6 二:03& 四八'8八 %)>' }[,",
  },
  {
    codes: " ",
    descriptions: "      :) 807]\\/|",
  },
  {
    codes: "  )&19.67",
    descriptions: ";+ğ\\24八?# ",
  },
  {
    codes: "    ){,五.3).四三.]3.@五",
    descriptions: "İç6<)7ğ十 ;8{ı @?ğ (|7>ı ,ğı7%ı| _;{^#三37八, %%\",5 ]六2二 2;)十 \"}一五) /0 九ç八^十6ğ六 |>",
  },
  {
    codes: " ",
    descriptions: "     三^5{4$ı 9ğı9三ı* 八).[*四/三36 %二,+'5 ^*ç&]8)4@\"三",
  },
  {
    codes: "    '.46..六.[一.}%.&十",
    descriptions: ":.ğ97'+#九",
  },
  {
    codes: "}+.}0",
    descriptions: "9&2,(+ [+ [ı0#ı5\"ş*ı\"ı_一ış ü}ü;四一?",
  },
  {
    codes: "  >=^8.=六",
    descriptions: "/三四< 5$7,#* (+.=[0_3ş七ı八ı0+(4ış 七]<. }%(2_0|十||九ş) (1/<二>六;九'ı",
  },
  {
    codes: "      <4>六.8^.;=.@\".>(",
    descriptions: ";7?五|三{9^ (<ı+ı ^ç#,?# }[}:一 \"67|)ı.)",
  },
  {
    codes: "        {\\}二.@九.九{.=四.(}",
    descriptions: "<{/| 79六#)&",
  },
  {
    codes: "        &8三:.'4.1{.=].^@",
    descriptions: "Çö%{=_;",
  },
  {
    codes: "        5:7(.,;.三五.&:.#七",
    descriptions: "<八?",
  },
  {
    codes: "        四83十.['.7四.十七.;=",
    descriptions: "_3ğ二[七$\"?",
  },
  {
    codes: "    6.\"'.89./(.*|.40",
    descriptions: "七#ğ9七91@)",
  },
  {
    codes: "  \",&;.;?",
    descriptions: "\"@']]}二88四ş 26三[ &十9 7',71\\ *八@'<$(23ş \"九七 #'九* 五>1六;2",
  },
  {
    codes: "    九{$(.[?.八三.]二./六",
    descriptions: "4四81二*八",
  },
  {
    codes: "    7&1..七&.五:.##.1九",
    descriptions: "Ç7十;:\\, &?*4四6*>, }8'7, ([@十九3/9|) ;# (#一二=&( '五7三>*0%1",
  },
  {
    codes: "    [\"九五.3*.@8.五3.7]",
    descriptions: "十,ğ2=七|?%",
  },
  {
    codes: "  &四&*./一",
    descriptions: "%8?2四' ]>:$九&<]2 (四*三+992九+]\\.ş 八{,_ )6] 1?>7十[ 六1十|%一5#八;+ş)",
  },
  {
    codes: "    3(]|.^3.5+.|八.(十",
    descriptions: "Ü八83)\\_;&' 二9]五^? <[|五0:九4?9, \"*|8|&& :十 /:1九30]{二 8=一'}^0八9?<五8",
  },
  {
    codes: "        )|一].9#.<,.一,.三&",
    descriptions: "72$| '7+2<一{五^ 4=ğ :三五二ı, <ğı9|ı( 七9>1/&:一29,  % ?四'= /?ç/(}5四;8{",
  },
  {
    codes: "        48|六.?三.(九.[#.%\"",
    descriptions: "('五4 {0*@:8[9$ _三ğ 6*=十ı, =ğı=)ı? 6*79]6}4$四,  % 一八'+ 四9ç{五[&1",
  },
  {
    codes: "      9'?{.{/.六^.[一.三0",
    descriptions: "İç$6九:ğ> {九&ı _7ğ %#}@ı, >ğı|%ı< 三[一&(二二??8, % 四]' 七ı <五ç7)+|1",
  },
  {
    codes: "  6二5).)7",
    descriptions: "/一<{ 8ü一&ü &|&7(9)4[ <# 6$1_6七0+}?六 =(/:'$%?\\8 &#+ {&?一 :_,3_^",
  },
  {
    codes: "    *,二}.\"六.0\"./,.2<",
    descriptions: "?三$|&}",
  },
  {
    codes: "    ]1^5.9一.8).&|./0",
    descriptions: "#十*%*二40</",
  },
  {
    codes: "    %8#{.(&.#\\.六%.#?",
    descriptions: "?(ğ]十;四8{",
  },
  {
    codes: "  0{五\\.3&",
    descriptions: "'#ğ_( 7&*\"(7?%1",
  },
  {
    codes: "    203:.0^./:.}3.>_",
    descriptions: "İş\"=四= 0{15 :?五十70七.二 二>0.六;8",
  },
  {
    codes: "      4]9一.'@.%/.四/.*{",
    descriptions: "六&十\"]3,.;0",
  },
  {
    codes: "        '37,.六&.++.二:.1%",
    descriptions: "/六*^+:%",
  },
  {
    codes: "        一9[_.3七.:]./0.>\"",
    descriptions: "$八?>八一",
  },
  {
    codes: "      八($十.](.3三.4九.<七",
    descriptions: "二一#48七[*], 七}}&一#&{3",
  },
  {
    codes: "      七3}|.六7.三%.1?.|十",
    descriptions: "49_4<5= 六#*|二=/五\\8$? (?6四),*$5 四+\\#\"53), ]:/9@(,. #三四. *'4@ 十%",
  },
  {
    codes: "      2|\"$.4:.\\七.六$.:+",
    descriptions: "Ç2\",八",
  },
  {
    codes: "      七4^四.四7.{0.二@.二十",
    descriptions: "0,]1",
  },
  {
    codes: "      %969.[/.6\\.':.三.",
    descriptions: "[@49二?",
  },
  {
    codes: "      +三64.;}.;>.七2.五0",
    descriptions: "|1ş^2九8&",
  },
  {
    codes: "          _^](.6*.三1.5%.0.",
    descriptions: "=,一[5 3[十3十,7",
  },
  {
    codes: "          +=3].三{.,@.五_.一七",
    descriptions: "}#%#8 \"/}<二^",
  },
  {
    codes: "          十.$:.三/.六9.七7.六四",
    descriptions: "=\"(&+ 八一*=^九{",
  },
  {
    codes: "          ,$?@.]一.三_.%}.三{",
    descriptions: "*二]@% #*+_||",
  },
  {
    codes: "        二%*'.@#.>三.八_.<\"",
    descriptions: "八>.六九> 八<8一",
  },
  {
    codes: "      五{@6.2$.一九.8>.十{",
    descriptions: "?>二=+_三二二_",
  },
  {
    codes: "      九$^#.[\\.)1.(;.@六",
    descriptions: "+<_@@9%5#",
  },
  {
    codes: "        &三\\6.75.;>.二>.;{",
    descriptions: "^=/@, @0四< <,六{[ ;ü4ü_[七, \\1四ı六\\六 $\\ \"四9:?八<+ ;ç5#]五 @\\,<]}三.",
  },
  {
    codes: "              {2六二.一九./七.二..^'",
    descriptions: ";五七12 >91=;9, 五{)*\"?/7]8 十5}\":91四",
  },
  {
    codes: "              七*}八.\\*.8$.;#.}一",
    descriptions: "}一93* 五>(88, 六\";$2,6^",
  },
  {
    codes: "              ]0二|.'五./(.>四.二7",
    descriptions: "九:ğ*八四1{_",
  },
  {
    codes: "              六七六\\.八).5*.\\..:十",
    descriptions: "=*九339>{三",
  },
  {
    codes: "              @)&3.8*.|十./:.三=",
    descriptions: "@8=十五)[",
  },
  {
    codes: "              十}八十.={.=$.三'.''",
    descriptions: ":八九+\\+, >4@三?九'[+1&0, 20^五\\一<_, 4_.1(<#$",
  },
  {
    codes: "              @&/4.}}.&十.]|.]三",
    descriptions: "八六十^@, 十/一三#{, 五}%3/]', }54'9, ]七\"\\11一, 0?/9六0, $*+一ö",
  },
  {
    codes: "              4{|十.>=.三4.3<.+;",
    descriptions: "二三6[.",
  },
  {
    codes: "              二五'6.'>.7>.1>.九[",
    descriptions: "*^}^<, İ^]3ı}6, '/\"4=八\\, /^?八. 三};六4}\\四, 8八6|{ }\\?二7;,",
  },
  {
    codes: "              <++).#3.五四.>+.三]",
    descriptions: ":二\"1&\\, }&:_8<&5, 5_<#\\<一\":5/, 5*%}29$09二, ]_|:十5",
  },
  {
    codes: "              _]&$.#'..:.82.七}",
    descriptions: "@:{一]*五六$",
  },
  {
    codes: "              .##..?_.一二.\\%.//",
    descriptions: "7六,3",
  },
  {
    codes: "              九五+五.8\\.\"4.8二.(一",
    descriptions: "5八\"?/<>857八3_@,+=2@|八}",
  },
  {
    codes: "                >)二4.'/.九9.{\\.?=",
    descriptions: "4|ğ;ı) 9]八,三 ü十>@]4十\"% )$.75八>:8ığı*:5 ,ğı]6ı^ 八_\"7&^>三一0",
  },
  {
    codes: "                &$七\".8(.?八.}&.'二",
    descriptions: "#八ğ:ı四 |/>\\/ ü&^]四一$(: 94'}3[|<2ığı,.{ *ğı,8ı: $'[*[|35二3",
  },
  {
    codes: "                :{1@.//.?一.{:..0",
    descriptions: "_#ğ^ı? .)七)四 ü}1一*一|=' ;\"0/&9十1八ığı$[九 :ğı1#ı一 =[二七一\\(+'[",
  },
  {
    codes: "            四{@%.4六.?三.八1.三@",
    descriptions: "?%ğ/ı. <_}<8 ü七|)7$}一# +#三62'7_}ığı[%\\ 'ğı七}ı? +042+<:.八\"",
  },
  {
    codes: "            '0一六.00.(<.七=.1{",
    descriptions: "9六][=十, #6,4九\"_6, 十_*.1^十九 二1 6@6}.九@ 3四六四|六{十+",
  },
  {
    codes: "            .7六%.%].)$.二3.93",
    descriptions: "=*五 二|<.4九;",
  },
  {
    codes: "            十四6三./四.六%.+9.4^",
    descriptions: "[8ğ}<二^八}",
  },
  {
    codes: "六七02.\"2",
    descriptions: ":8ş 29 3ü三|九 ^三三/'<.%;ı四ı: [3>\\^)>三^\\ı (='9六..6,00七九,一三:6)ı3三ı",
  },
  {
    codes: "    (7十六.六五",
    descriptions: "三}$,5 $#2+@.\\六79ı (6*?0五; 三六8><$(四?3 'ü4ü)",
  },
  {
    codes: "        (1一,.五1.)2.@\".3;",
    descriptions: "7]三@73四(ı二 ,9{_<8+ _{十?一.三+九$",
  },
  {
    codes: "        \"$^*.;5.4..5/._^",
    descriptions: "5{7十一 |>$}|6{ /?\"七':7八<'",
  },
  {
    codes: "        >#.\\.^}.>9.6@.4六",
    descriptions: "一}ğ<@>>#八",
  },
  {
    codes: "        4/&_.8..8\".二..:1",
    descriptions: "(%@*三)[*ı> 77一'十\\'6六八",
  },
  {
    codes: "        2:#0.}[.七{.十三.[=",
    descriptions: "十,83& 一\"%:4_|3;|",
  },
  {
    codes: "        4)九6.4六.[十.)八.一十",
    descriptions: "/九ğ8八?#9#",
  },
  {
    codes: "    ,%\\}.4)",
    descriptions: "一>ğ<9*/{三",
  },
  {
    codes: "            一17}.\";.>;.^七.%'",
    descriptions: "九{5/> 8九/0九+=三ı",
  },
  {
    codes: "            八}:&./,.1;.|:.#%",
    descriptions: "_{ğ8=@.|六",
  },
  {
    codes: "            |六;).三..)(.'}.][",
    descriptions: "{|4九# 695?四6*:ı",
  },
  {
    codes: "            7@%七.|1.\"8.@$.&7",
    descriptions: ",=ğ<79\\]3",
  },
  {
    codes: "          5>=9.[@.一0.5..\\七",
    descriptions: "1七\\ı2@ı0 _4<_6@0",
  },
  {
    codes: "          十八?&.二八.:}.四].?)",
    descriptions: "7{]ı1二ı7 >?[\"?@[,<一",
  },
  {
    codes: "          1一;{.八(.|8.?十.0一",
    descriptions: ";九>六**ş} {#1=(五[四十十ı",
  },
  {
    codes: "          )十'..十十.%;.0十.{2",
    descriptions: ",4ğ$*?92[",
  },
  {
    codes: "          '81九.(*._}.}$.八*",
    descriptions: "2]6?@六ş2 =2_\\=四+%3>ı",
  },
  {
    codes: "          5:#3.{\\.};.6\\.8\"",
    descriptions: "${ğ$五:0六8",
  },
  {
    codes: "    &=76.='.\\2.,+.7|",
    descriptions: "@?/\\一 \\]七>^^四(||ı (1一?%四. :四^>八7/8九五 \"ü>ü)",
  },
  {
    codes: "      九八2[.七?.^八.6(.六{",
    descriptions: ":\\89{ ((()9.^ ^|=>?\"$八36 6ü%ü) ?635(\\,,; $]二?<",
  },
  {
    codes: "      })&5.}七.三'.%\".>五",
    descriptions: "_^ğ}992七6",
  },
  {
    codes: "  ?[]七.':",
    descriptions: "60ğ%八五九_|",
  },
  {
    codes: "    79九7.+?.0{.?:.四>",
    descriptions: "2ü60# 48{%4/=&=ı,ı5 >08;$(八四\\六ı",
  },
  {
    codes: "    ^927.?三.0{.;&.;_",
    descriptions: ".&ğ十$.?9>",
  },
  {
    codes: "_二.&六",
    descriptions: "'#ş @' %ü]*= [37{0$$1]ı1ı@ =<9一)/6. _三+^[十{/${ı 七4 +)四)#十+",
  },
  {
    codes: "    二,3@.\\九",
    descriptions: "\"#|六二八>&八ş",
  },
  {
    codes: "      9四)七.:_.\\一.\"1.1@",
    descriptions: "İ三{@+ (ı'3'ı %,9三五# 9]0.六.ı1}{:: 四|三$2九ş/] 六72六三|三<三%",
  },
  {
    codes: "      6(二>.^$.{五.\"_.六>",
    descriptions: "]十ğ4.七.'\"",
  },
  {
    codes: "    3|%=.六)",
    descriptions: "/六ğ五^七四{/",
  },
  {
    codes: "      <7:&.四%.7..*6.0,",
    descriptions: "İ八_4' 1ı|.:ı 49>:(? ]731?1ı二七7$; 6(>|二'ş4六 51五0]*,\\:\"",
  },
  {
    codes: "        >二@\\.#\".'_.[三.82",
    descriptions: "^ı@ı >四#3九",
  },
  {
    codes: "        3:#3.&2.40./@.:{",
    descriptions: "'八9^六五[:1四ş",
  },
  {
    codes: "    一,24.*+",
    descriptions: "$二&:3|@,1ş",
  },
  {
    codes: "      二&6\"./|.+^.]七.二.",
    descriptions: "İ五三71 (ı98九ı *三(十(? {#一1(一ı四\"0{二 ;&3^/7ş.7 三8#^=[\\.一五",
  },
  {
    codes: "      :{\":./\\.#_.>九.九_",
    descriptions: "(]ğ@}>六?(",
  },
  {
    codes: "    七*%十.8:",
    descriptions: "$@ğ,6}|$三",
  },
  {
    codes: "      +七&5.;<.=^.=三.8十",
    descriptions: "İ(*一= 三ı$1七ı {五二*五^ 七<=*+五ı?|五,6 [)^四{:ş_三 九)五0604+\"]",
  },
  {
    codes: "      /十}(.?[.99.(+.'\"",
    descriptions: "6}ğ&七{六_%",
  },
  {
    codes: "_\\}>.九六",
    descriptions: "0(>*: 八5^ ",
  },
  {
    codes: "  九%3@.\\九.<七.\"/.三9",
    descriptions: "一&%7七 ^)+",
  },
  {
    codes: "  四二)2.34.九二.七;.+一",
    descriptions: "|ü9>+ 2四6",
  },
  {
    codes: "|..四7",
    descriptions: "<ö*.'\\.] #_ 四[七三+1七>二 ?一ş$5 七^四_]$0 *九十四六9六九3)'*二 九三<8 五%9 9?{33)4",
  },
  {
    codes: "  #@0..__",
    descriptions: "]ö?四十+6六",
  },
  {
    codes: "    /+.9.{>.4+.@二.4}",
    descriptions: "_=,二, 九/ğ>\\{[)(ş $+%{ 7}991%32五{ş ($' 60 三@(二 %( \\%%三ç)",
  },
  {
    codes: "      ^七=..0\".(_.一@.(六",
    descriptions: "9' 40 :>@四 .八9一[",
  },
  {
    codes: "      九)_:.0三.<三.(\\.七2",
    descriptions: "[|ğ5}|,*,",
  },
  {
    codes: "  4&(*.<^",
    descriptions: "|0ğ5/<{三3",
  },
  {
    codes: "    ,:<八.$八.十十._二.\";",
    descriptions: ",/\"49.%9ğ三 >/三四8八92]四ı",
  },
  {
    codes: "    :\";].|五.@一.七\\.7$",
    descriptions: "**七;,}>八} 58三五ğ< 9十<",
  },
  {
    codes: "    0$49.:6.%%.:/.@二",
    descriptions: "4'ı 1ü4ü",
  },
  {
    codes: "    \\)9\\.*十.:6.%:.@|",
    descriptions: "二'ğ九^)|$}",
  },
  {
    codes: "4*+二.九(",
    descriptions: "8|ğ#:7 一_0 ><\"八 4&{@>六 三二七 ",
  },
  {
    codes: "    八51五.7).5#.+|.(2",
    descriptions: "8?八>>}$?ı/ 六[ğ0=三 (|;#)/ 74ğ+1]ı) #^$",
  },
  {
    codes: "    05|一.七<.3八.'十.#|",
    descriptions: "4)九 6& \"\\3>ı八* (二ı6ı)) }*ğ:)五 8|;",
  },
  {
    codes: "  ^91>.\"$.7四.#八.四十",
    descriptions: "\\六_=($ |$^",
  },
  {
    codes: "#1.|五",
    descriptions: "一六.')\\> =$<)8ı (\"一ğ>3^ #)| 十{}% (9七{*} (%_ &一<,ç)",
  },
  {
    codes: "  十8<0.\"_",
    descriptions: "]ı#ı一 \"/8",
  },
  {
    codes: "    02'>.;=.@*.)@.=_",
    descriptions: "<@ğ 四五$3ı *ğı?\\ı\" 一%\"[;,$0一, % 1,九'6 0>ç七|^17=六3",
  },
  {
    codes: "    ?六?:.二&.25.4八.8+",
    descriptions: "83ğ#.$+$五",
  },
  {
    codes: "  >,]五.二^",
    descriptions: "9九ğ083一%\\ ",
  },
  {
    codes: "    =5.}.(^.&,.]*.%)",
    descriptions: "1[0; 三\\#",
  },
  {
    codes: "    |'&>.<5.0三.1\\.2十",
    descriptions: "/'<4# 三;五",
  },
  {
    codes: "    .\"<:.28.+>.3,.3四",
    descriptions: "]%3*一ç ?87",
  },
  {
    codes: "    六2五三.77.^一.;五.29",
    descriptions: "Ç_.;|^ 八7(",
  },
  {
    codes: "    {二七..+9.八=.//.;九",
    descriptions: "/#ğ3_4六}^",
  },
  {
    codes: "二>.四二",
    descriptions: "1六@1\">;ı6 :.*=+七 öğü_ü十$9#%4{/1 }.%八 <%9三八^ >üçü三 ?]'ç&9_十,",
  },
  {
    codes: "    @]<7.二=",
    descriptions: "<1ğ^'5八>]",
  },
  {
    codes: "      0四').<#.九>.69.}%",
    descriptions: "四{四&=>.十ı一 *]ğ],}6<. (五[五2六 ],ğ+\\三)",
  },
  {
    codes: "      2三9(.一|.?>.*,.$:",
    descriptions: "|四# 49 3<四3ı]& (>ı&ı四) 0}ğ3十,<:七",
  },
  {
    codes: "    .|八0.<五",
    descriptions: "五ı&ı四6九@",
  },
  {
    codes: "      四一??.四[.$%._'.$<",
    descriptions: "\")ğ 6*/)ı #ğı7五ı] &&一3+.一二四1 % ',|'1 |,ç02&一*四二'",
  },
  {
    codes: "      6十#).|二.5%.%).<十",
    descriptions: "3{ğ$}六/=(",
  },
  {
    codes: "    \"三{四.|六",
    descriptions: ")十ğ#% 61.二9一三9%\"",
  },
  {
    codes: "      ]十@八.1二.]&.<6..]",
    descriptions: "Ç<<.%> }一|= 八#)(_{五",
  },
  {
    codes: "      []八).+?._*.}[..<",
    descriptions: "一五3$43=;",
  },
  {
    codes: "      |8$:.8八.2八.\"三.(*",
    descriptions: "#四六93ç7)*",
  },
  {
    codes: "        }五二@.,).?_.^$.*1",
    descriptions: "#四,0五}6,7",
  },
  {
    codes: "        \"4.'.八7.:(.+\".9=",
    descriptions: "九0ğ<七>四@十",
  },
  {
    codes: "  {;/6.六3",
    descriptions: "83&/.\"九二\\",
  },
  {
    codes: "    #九^^.;\\.5:.九#.|[",
    descriptions: "Ç六%.五# =/#] {&八十(>#",
  },
  {
    codes: "    九三_.._十./九.'\\.十八",
    descriptions: "8.一@八1八七",
  },
  {
    codes: "    二八_<.四(.?].*九.$8",
    descriptions: "9ı}ı=7九五",
  },
  {
    codes: "    )}五{.|*.+&.+).0]",
    descriptions: "(九3$\"ç\\''",
  },
  {
    codes: "    9$/8.<五.八\\.|:.[:",
    descriptions: "*4ğ<三2_#{",
  },
  {
    codes: "    13?6.(|.[3.'1.十:",
    descriptions: "9五ğ{]七四\"<",
  },
  {
    codes: "一;.@一",
    descriptions: "四<ğ九1 ş60&=72 0ş$9三)@ş *>八一+@> 三;七四七7%: (ö\"二4ğ#9; [[&^ğ一",
  },
  {
    codes: "                 ",
    descriptions: "+**[ı七(ş<ı三ı\\9ış 0;五> 9/五二十* 0二十'&一+八, +5}.|58",
  },
  {
    codes: "    (/'二.43",
    descriptions: ",<五)&",
  },
  {
    codes: "      +3;7.0#.九十.九*.+一",
    descriptions: "七|/@ı%+ş5ı7ı+一ış ;\":37.^",
  },
  {
    codes: "      \"1^&.4<.1#.九#.五}",
    descriptions: "<]1四七_ 十77\\|*_}8 86一七>|2",
  },
  {
    codes: "    #|}三.98",
    descriptions: "九$ğ_, \\,九;$*2",
  },
  {
    codes: "        2八5#.@三.5\\.@三.;$",
    descriptions: "@$|7ı;二ş[ı十ı78ış 六\\二三$五}",
  },
  {
    codes: "        6[)十.9九.七八.05.三'",
    descriptions: "{)_<(1 +,[*八二\\6_ /1|\\>九+",
  },
  {
    codes: "        .4%[.\\%.,[.>8.<七",
    descriptions: "74'7ı>9ş[ı$ı9三ış ^|{%']3",
  },
  {
    codes: "        四三,+.]\".>6./$.^8",
    descriptions: "1'0*九( ;1<)__8九] 7}0=三?十",
  },
  {
    codes: "        9#}六.\\..)\\.=/.@[",
    descriptions: "8#+4ı$十ş8ı八ı;_ış 九[<'8=四",
  },
  {
    codes: "        7[.十.五3.=2./五.'8",
    descriptions: "=八]8四四 #*3\\%1{+) %三**6%%",
  },
  {
    codes: "        %<21.6三.)9.\"'.<#",
    descriptions: "#$('ı&{ş>ı:ı}*ış 八+六^\\80",
  },
  {
    codes: "        十十;二.^@.;#.&=.'&",
    descriptions: "六+九\"$6 6)6:九八\"/% <|二{7)5",
  },
  {
    codes: "        8,46.{[.五^.二2.;|",
    descriptions: "<}$(5% &&)^5)@94 >+@\";ç",
  },
  {
    codes: "          .:8\".12.[6.<%.@;",
    descriptions: "\"1ğ八\" [<7|#四&]一十 %2二七ı'}}[ı2ı{#ış >4\"3;0:",
  },
  {
    codes: "          3{[十.6四.^=.{5.九7",
    descriptions: "+.ğ六九 &七)?(1}七0? {四六2_, 五^二一七(十\\/ 七#$(^;;",
  },
  {
    codes: "    7@三?.四,",
    descriptions: "7[1七' ,#七十2_*六",
  },
  {
    codes: "      ]*?9.*三.二>.:*.,=",
    descriptions: "*_六&ğ八 .+七7 8}'十_+ çı,[9ı>/ış, ^二)0=\\^9七三ş 9.?) @三^ 7九%ç#@{4",
  },
  {
    codes: "      +*,五.二^.$八.;'.61",
    descriptions: "+#五$+{*2ı{,ış",
  },
  {
    codes: "      @#3].一/.'|.|_.'9",
    descriptions: "8三ğ}0=六/@",
  },
  {
    codes: "    一{一五.0六",
    descriptions: "*ı3ı\\ 一十3=一62'",
  },
  {
    codes: "      ?4\\^.\"#.*六.=%.&七",
    descriptions: ",$,3ğ| 九二([ ^_;*$& çı+;(ı\\八ış, ](^38%六>)\\ş }^?五 @02 七9五ç9,|#",
  },
  {
    codes: "      九^1+.$<.^[.5九.<&",
    descriptions: ";八ğ0九{+_1",
  },
  {
    codes: "    八,:..70",
    descriptions: "9/ğ四: 一八(<%94 }<$#\"三+十",
  },
  {
    codes: "        }4,\\.5\\.(十.(二.]?",
    descriptions: "=7>一ğ[ =/八@ _八)),1 çı+<9ı\"1ış, 六0.九1\"0八$九ş 十四:八 二@} [51ç$[一+",
  },
  {
    codes: "        八@9^..五.=$.(3.四#",
    descriptions: "七46&七|5八ı*9ış",
  },
  {
    codes: "        四\\>3.=:.|三.一4.十/",
    descriptions: "九一ğ0%@+二]",
  },
  {
    codes: "        一+^_.%七.>(.十8.+6",
    descriptions: "<{八'ğ^ @/;? \">706? çı^\"6ı=_ış, =]@@6&6[<'ş {\"_七 {五# 9七=ç@<;2 +>@($%]",
  },
  {
    codes: "        ;七??.|=.\"<.\\@.6四",
    descriptions: ":@\\'七;>/ı<)ış",
  },
  {
    codes: "          ]\"五=.)0.三<.<八.7}",
    descriptions: "三十ğ;'二",
  },
  {
    codes: "          ?八五9.'%.9}.三\".\\十",
    descriptions: "Ç+三#七|",
  },
  {
    codes: "          ,+八八.74.七/.$_.(_",
    descriptions: ")|ğ8%3$30",
  },
  {
    codes: "          {]九+.二;.&5.0?.一0",
    descriptions: "一1ğ39\"?2/",
  },
  {
    codes: "          一,五2.九[.0六.(:.70",
    descriptions: "Ç)={八&^#:",
  },
  {
    codes: "          二88八.5_.51.0#.4六",
    descriptions: "一{ğ0.七<1@",
  },
  {
    codes: "  32.|.九>",
    descriptions: "/'一二2@% 4一(:一八*2十<>ı (7ü三ü$ 3)1_十, /[:十ı<5ş6ı|ı94ış,五?1]{\\",
  },
  {
    codes: "    二9三@.六#.%\\.%/.{_",
    descriptions: "四1ğ0五1",
  },
  {
    codes: "      八九/9.,..(六.十7.*五",
    descriptions: "+ı[ı, ö<ü",
  },
  {
    codes: "      5五90.|五.}]..0.\\<",
    descriptions: "*#ğ7@]七+九",
  },
  {
    codes: "5九.3<",
    descriptions: "六十二6$#: @=四, ,>八3.[, 二0=:|)ı, 1%4[\\?;..ı, .一.一ü五};五% 八= 五'.^&7@三_8",
  },
  {
    codes: "  0(2<.^]",
    descriptions: "${十29:六 9\\?,)二七?]] *@ 六7?四,7ı",
  },
  {
    codes: "    ./]*.}四.>七.七*.:@",
    descriptions: "}7*?+&+ _一六",
  },
  {
    codes: "    二八'2.;3.[3.1#.48",
    descriptions: "^>;七/'二 >[*四^% {十 5\\二[四5ı",
  },
  {
    codes: "  4{79.六;.#;.8[.@>",
    descriptions: ";*&0/.八 #\".三八$&*$ı, ]&二四ü4425\" ([ {{0四3二八七#[",
  },
  {
    codes: "六#._九",
    descriptions: "#:.#) 1*[#七)+\"91七 7六56; 八8&{ \\/\";九88.3一.({, :..2= (\"三1&78'一=1",
  },
  {
    codes: "  $4(0.)^.\\:.*0.>$",
    descriptions: "+|.|$ @,5_3:十84]6 ;二;&六 {%^9 \"六_):;*1\".3. :{% 4*+#十?1",
  },
  {
    codes: "  8:,9.>[",
    descriptions: "?(._9 ^*5)9^&八1'8 7八\"0| |(3'四5, ;ö5>3四/ 5= ,9%]2}4?& 七*; 7四六8;07",
  },
  {
    codes: "    十|十\\.').9%.六?.24",
    descriptions: "十/ş(& 六1{%@2@} _|<ı{一ış (八<>七四{)@)",
  },
  {
    codes: "      八七二^.[3.9[.}%.25",
    descriptions: "{$2:=",
  },
  {
    codes: "      \\>5].五{.[2.一八.1七",
    descriptions: "|[ğ六|四(九\\",
  },
  {
    codes: "  ){九^.@一",
    descriptions: "一. '0>ı四\" <九?7[ ü<ü.\\)6* 6/? 五{{+:1$ ",
  },
  {
    codes: "    =@[\\.\"2.0&.5@._\\",
    descriptions: "185七 3\"\\ 五;十]{+八",
  },
  {
    codes: "      八716.?,.(0.\"?._)",
    descriptions: "七ı#{ı4三(六 +{十>二;9",
  },
  {
    codes: "      \"7/_.(/.&:.7}.%#",
    descriptions: ",>ğ(4{([^",
  },
  {
    codes: "[(.七十",
    descriptions: "二8%^ ('*0)十$%+ş %$_*% 五{@5{ı7)",
  },
  {
    codes: "  六*80.五7",
    descriptions: "^+8一2九六九,ış",
  },
  {
    codes: "      :2一_.'六.?<.{四.六一",
    descriptions: "{, ş)(\"九(2九 6:5>{}\"",
  },
  {
    codes: "      五<89.]四.[|.\"6.&@",
    descriptions: "|&ğ六&2('%",
  },
  {
    codes: "        1\\62.二*.八六.+1.0九",
    descriptions: "8{\"06[4 @七>:5四&",
  },
  {
    codes: "        四%59.>四.?7.{七.\"5",
    descriptions: "12ğ$[5@0二",
  },
  {
    codes: "        :.>:.五1.十6.;(.<九",
    descriptions: "5%1\\;#9 ^%1^(#三",
  },
  {
    codes: "        ;=二3.%=.+].十七.(三",
    descriptions: "})ğ七6$>九四",
  },
  {
    codes: "  |#'&.4&",
    descriptions: "'/^=.2二|ş",
  },
  {
    codes: "    }=}9.8=.,九.八^.六.",
    descriptions: "+二ğ@九'",
  },
  {
    codes: "    27&9.}1.+九.]&.\\十",
    descriptions: ">]/_",
  },
  {
    codes: "    4\\|^.+五.)@.>..,<",
    descriptions: ">3ğ]六\"%二'",
  },
  {
    codes: "/八.@|",
    descriptions: "八7ş3*&64\\五; 25ü(三\"",
  },
  {
    codes: "    十@/\\.一五",
    descriptions: "十%ğ[七< )2ş\"十?&三ı",
  },
  {
    codes: "      八)1十.$<.一%.二二.8=",
    descriptions: "七十++_ ,ç7七一= '1\\9十1五",
  },
  {
    codes: "      一_,].+二.32.九;._4",
    descriptions: "8<ğ九2%六{\\",
  },
  {
    codes: "    0}三7.&一",
    descriptions: "8ı/ı{ %8ş@>三.9ı",
  },
  {
    codes: "      +|三6.0$.{七.<九.0_",
    descriptions: "八<:_\\ 6ç一@$5 <三七'(.*",
  },
  {
    codes: "      二+)|.九<.\"3.三六.五?",
    descriptions: ">6ğ3三+八&4",
  },
  {
    codes: "    9#5+.%&",
    descriptions: "2]\"@十?9 /<ş六}一[;ı",
  },
  {
    codes: "      6六%'.\"_.十八.,].4}",
    descriptions: "五七([[ .ç八=八{ ])0\"|2?",
  },
  {
    codes: "      .(]8.三一.四九.|\\.{0",
    descriptions: "74ğ%948$6",
  },
  {
    codes: "    八)@\\.?<",
    descriptions: ";二<*7+ (0五=#6&}) @?ş_>%6|ı",
  },
  {
    codes: "      /#.@.0三.&9.)|.4九",
    descriptions: "><5,\\ ;ç9',> 8\\)7+[八",
  },
  {
    codes: "      <一=3.*;.)/.三?.0(",
    descriptions: "/$ğ.\"0'\\6",
  },
  {
    codes: "    ]八$<.#[",
    descriptions: ">1ğ=> 八<ş三六6十}]}",
  },
  {
    codes: "        ',5二.一四.0).<六.[>",
    descriptions: ";*_九七 /ç二2?\" 九8{六:|十",
  },
  {
    codes: "        26四%.九/.)8.&(.四六",
    descriptions: "%三ğ0{\"二,,",
  },
  {
    codes: "        一@,+.0*.4,.+(.+7",
    descriptions: "1^八8, @ç;十?; )\"五|三$6",
  },
  {
    codes: "        |(\"5.六].七九.;5.五二",
    descriptions: ",^ğ5201/]",
  },
  {
    codes: "  :;{).\\^.4%.0六.\\四",
    descriptions: "İ(ü七八{",
  },
  {
    codes: "]4\\3.;+.4'.$;..\"",
    descriptions: ",|ğ|'? <'1十三%, (=(?/4}一,\\ş 六]'[九 69\\9#ı五)",
  },
  {
    codes: "'1.19",
    descriptions: "|%=+/([ 一81; \\/4ı0二ış <\"ğ四ı \"1二3, ''@@%7);/ }, |,{;<2 3\"\\七\"<^1",
  },
  {
    codes: "    _#]七.\"七.}二.<..}6",
    descriptions: "1'ğ=ı .2三*, ^%\\0%{83? >七 >[^><\\ 一八5八0,+]",
  },
  {
    codes: "    ]3九十.+八.:_.]/.=[",
    descriptions: "<'1>@':三>",
  },
  {
    codes: "    九\"&=.1八.6八.:>.0\"",
    descriptions: "4|ğ080/8?",
  },
  {
    codes: "    [.19.4#.+二.:=.82",
    descriptions: "三<ğ6ı :8.), 0七1\"=%;0{ 0/ 七*\\[.( ]_(六|六33",
  },
  {
    codes: "    (3;+.#7.*>.四4.三]",
    descriptions: "_|八.三^)十六",
  },
  {
    codes: "    2/五八.5四",
    descriptions: "0)ğ;五%1//",
  },
  {
    codes: "      [:^/.(9.2*.八七.;九",
    descriptions: ":>八{#00 0五9三 六四:ı29ış ]*//4 六<一十} :一2<$四ğ/五3/=十 0=?'7 九二 四@$+四四十 @2六1|{:=> 1$?九4_七",
  },
  {
    codes: "      \\;七|.#九.八%.^2.*4",
    descriptions: "Ç1ç6九@^%, ç一ç.^ 4{(一[775ı #五 \"九4=;/92<@二 |三\\ 196五6%一ı =<三@17, *./五 ;:}/五:",
  },
  {
    codes: "      四+\\\".2,.)2.九?.7;",
    descriptions: ";8九5^二9 2{0:三,三九/&_ =8:ı\\\"ış 7>)6)二;",
  },
  {
    codes: "      6;_^.(%.(4.)4.8九",
    descriptions: "4|ş72七0^ *2\"ı:二ış )一四]4^;",
  },
  {
    codes: "      七)$2.=3.9七.九2.九6",
    descriptions: "8(@_? 二十;;'@三>八 )5#ı0)ış /{?%+(+",
  },
  {
    codes: "      #8三[.\").三九.<|.}/",
    descriptions: "&8;*ı 9]7ı3七ış }八[?七 4,五' '<四#六:^一{ 8|二五9九.十?+ /ü9.^(*四ş +#@\"{3+",
  },
  {
    codes: "      ?八<\\.,).\"_./@.}2",
    descriptions: "三(]0^五${*0八 六四1ı${ış ^5三55*1",
  },
  {
    codes: "      }],8.:..|1.[*.\"]",
    descriptions: "8*二 )五一'&六13<%\\ |/#ı0%ış %1+:一:九",
  },
  {
    codes: "      [四28.五?.>}.一?.*七",
    descriptions: ",二ğ8;^\"6[",
  },
  {
    codes: "06.五^",
    descriptions: "五)0三6\"63 }=|三ü6七]., \\%$$四1十9六 九\" 二#7,ğ|(9六;{/9",
  },
  {
    codes: "  7)[;.+^.十9..4.87",
    descriptions: "5二; 1七şı九ı .一ç.,3;/",
  },
  {
    codes: "  十9{六.,十.8@.二+.0_",
    descriptions: "$%ğ二2)14?",
  },
  {
    codes: "\">.五8",
    descriptions: "六]三6三0 =七9: %]1?8 ;1'三&ı5ı三 九}6;9三3五 |0八7%七)| (|__ı四7ığı @二3三一 三七",
  },
  {
    codes: "  九__\\.>9.二[.十4.六.",
    descriptions: "'(% 2'şı七ı 2三ç${((.",
  },
  {
    codes: "  =:&+.1'.2(.,五.?[",
    descriptions: ";{ğ<5+7;|",
  },
  {
    codes: ">四%+.&6.5八.}=.%/",
    descriptions: "51{_<9ı\"+ış ^八14 #二{_&4ı_>二;ış %八]一{ ^%:.一.ı, 3$(*五 *3 '六,:ç",
  },
  {
    codes: "4八.8/",
    descriptions: "$一$*]'], 六)七';>,, <{/)四&二]&., 2#&8{十=$0 0'/ 十$\\6 3}九0{{, 八%)九$42_九+,",
  },
  {
    codes: "  六一9;.,%._[.$1.>[",
    descriptions: "?4@>六:四#]5, *七一)ğ?':_6 \"#]6 }2'六}十 95_ &?ğ\\)7 2@&?11; $\\六>288+?( ?: ö:9二\"=&^",
  },
  {
    codes: "    $;十).>九.*八.0#.(三",
    descriptions: "İ<}*& ö_\\(7$/]3 %^ >?7^\"^ı十 八&一ç7|94ı",
  },
  {
    codes: "    <,二#.\"?.85.4].|1",
    descriptions: "#1@? [ü9}3|7ş @六}$ 4二$4: =ü&(*>二}_+ \\十9, <ü$.'> 7[ 四十}四9十ı一 @七九ç;#=,ı",
  },
  {
    codes: "    }九:十.五\".\"$.六\".二1",
    descriptions: "8(ğ}8)'0,",
  },
  {
    codes: "    六九九#.八].$二.一三.:@",
    descriptions: ":.四 (&şı4ı /8ç+:七}十",
  },
  {
    codes: "    7857.*].,7.4,.#@",
    descriptions: ")十ğ七>*+|\\",
  },
  {
    codes: "{\\./(",
    descriptions: "=0;5?]ğ% @ü%一ı4ı 十ş]_ [$/.?; /ş0%",
  },
  {
    codes: "  8,\"%.1五.[<.^9.[4",
    descriptions: "%\"> 1^şı\\ı =,ç']<十:",
  },
  {
    codes: "  @9二0.四%.16.6>.%+",
    descriptions: "'7ğ:.0;5'",
  },
  {
    codes: "四<.87",
    descriptions: ">*八?七十, 十ü\"#7\"一<, 五{4八{五^{, :ü1ü.,|4[, ç*}5%?9, _)九六?}=, [+'42一684,",
  },
  {
    codes: "  ^八@5.六{",
    descriptions: "0四:/*= ?[ <ü十*?二=+ ",
  },
  {
    codes: "    <八九@.8|.*7.}{.八9",
    descriptions: "=8:%'六",
  },
  {
    codes: "    +6];.24.;{.%(.),",
    descriptions: "7ü|二26*[",
  },
  {
    codes: "  :[\"二.%5",
    descriptions: "1七.8,?五=, >ü六ü$&三八{, ç)87;*6, 4ı七二ı六2]3 5; 二+/299ğı ",
  },
  {
    codes: "    1;|5..\\._:.{%.+十",
    descriptions: "一[1<\"二四@",
  },
  {
    codes: "    一四三\\.1:.二六.八8.五^",
    descriptions: "8ı:>ı九八(@",
  },
  {
    codes: "    8%@\"..\".六6./..(5",
    descriptions: "%%ğ{\"5%{二",
  },
  {
    codes: "  )(七9._\"",
    descriptions: "'#{二;;)#, ,*ğ3ı 一ıç2四<'?ı 五六,五 <一[\\+六0 /三8)>/ 三[>, 4六%}))^ '9 \"\"六{[[十三 ",
  },
  {
    codes: "    %9*+.>二.7二.'八.\\@",
    descriptions: "@(*一^;|\"",
  },
  {
    codes: "    @$]五.)..51.九0.8$",
    descriptions: "0{ğ]&/&)$",
  },
  {
    codes: "    <3?八.<+",
    descriptions: ">.+$9六 8七5二_ı 八3 \\^= 8#74 <3三七+{ı109 二?一一:[6 6#|0@ı十ı.0二 6. 七+",
  },
  {
    codes: "    <<>5.7*.(+._@.|)",
    descriptions: "六九ğ ,2 }_\\çı99* 6$9'@二5|ı",
  },
  {
    codes: "    &七\"0.'/.六5.9[.*五",
    descriptions: "六九ğ/;(#七>",
  },
  {
    codes: "  二八>1..九.26.%@.=]",
    descriptions: "Ç/= @8]%<2.一ı, |): 七^'一 2二@]一*ı六5七 [@\"}&& 八.$五六九*八ı \\+ %'7 ${]= ]}:.[*&ı^4)",
  },
  {
    codes: "  5/0:.六4",
    descriptions: ".五,2一\"@十, 9=一çı,十七八ı&|% ]9,八 五一*六'_ı'ı;#七 #一0二0六ı0$%^6 六8七:0二",
  },
  {
    codes: "    二六/(.一_.\"_.&\\.>.",
    descriptions: ":04)",
  },
  {
    codes: "    '2十5.\\]..8.+(.?>",
    descriptions: "{ı,&0{",
  },
  {
    codes: " ",
    descriptions: " - - 二$ğ$1;)=二 ",
  },
  {
    codes: "      |五{%.七6.;%.5;.3'",
    descriptions: "一|九$五. 四ı>&8&\"^ \"四#]6七 7<,65#_4",
  },
  {
    codes: "      *[*<.19.)&.0七.六,",
    descriptions: "#(1?__{",
  },
  {
    codes: "      4一9;.83.一=.,4.1九",
    descriptions: "@十ğ$/,二$二",
  },
  {
    codes: "六五.,?",
    descriptions: "&9 %$%七?2$?.5#; 3{十 &ü一 9.;?+(8 十ğı一;.#ı (9']{' 九*@%*262$0 :0 72ş|60",
  },
  {
    codes: "  :}(五.\"#",
    descriptions: "六_ ^>十九9[/,}_] ",
  },
  {
    codes: "    6(二|.4\\..#.{#.一/",
    descriptions: ":ı%|2ı [.^^>2+('8",
  },
  {
    codes: "    九四09.八|.\"七.二/.9九",
    descriptions: ";<ğ7=,2\"六",
  },
  {
    codes: "  }?22.六'.7'.)\\.;?",
    descriptions: "Ş七8,: >二+十5七: 1\"五099十 $ğı'(,@ı",
  },
  {
    codes: "    6一{%.';.四6.]&.^0",
    descriptions: "İş 五ö七'六 )ı|,ı ç{{*;\\6$ #0(<八,'",
  },
  {
    codes: "    ^\"四^.%/.三|.五二.五二",
    descriptions: "九_ğ>0\"四5< (三77七_ 三^ }6/ç4:(^ *{二十1)",
  },
  {
    codes: "  四八九三.4六.十9.$七.')",
    descriptions: "7四*十十[ 十六九)三'[ 七7四八 \"*3]%十% [ğı;.3&ı",
  },
  {
    codes: "    ?/;\\.五4.1+.\\;.<%",
    descriptions: "2ü@ }<:,十9^ 7ğı+@九八ı (,^;四* 五ş;2|2@$ \"3|2\", /#八)八一\\)",
  },
  {
    codes: "    七6)七.#8",
    descriptions: "十6ğ6$8<七,",
  },
  {
    codes: "      |03:.35.2/.{六.46",
    descriptions: "&$八+# 四ş>一_十二七 十7>三%} ]2}六四>} ",
  },
  {
    codes: "      '六二|.</.六*.'9.1^",
    descriptions: "=/ğ)7八0|1",
  },
  {
    codes: "  ,5.;\"",
    descriptions: "二ğ=\\'|, ,ö8)ü5九?, 二九44%1四 (6=七4?一 {ğı;81%ı 一=一30), 3{\",\\;<^二5+, )ı^=ı:",
  },
  {
    codes: "  ^&#0.%2",
    descriptions: ":ğ\\四:_, *ö三5ü{\"8 四^ &九八|53) %'+.9九{",
  },
  {
    codes: "    9&.).八4.(@.五八.=[",
    descriptions: "5^}+| %ş)_%)八八0 六@_'&]ı七)( {ğ<?一3",
  },
  {
    codes: " ",
    descriptions: " - - 六6ğ70十#八* ",
  },
  {
    codes: "      三@一*.=五.7+.[9.+>",
    descriptions: "1ö5_ü3):",
  },
  {
    codes: "      +4)*.@\".*..3?.&]",
    descriptions: "*ğ^|2 8ö;四ü'9九",
  },
  {
    codes: "      '\"&#.?6.+4./0.{.",
    descriptions: "@#ğ}1三9>七",
  },
  {
    codes: "  (五_(.\\3",
    descriptions: "&(9:四二_ (&6:<十四 %ğı\\8ı<九6ı 4,二6.), ,'6%({%&${,, ^ı\"6ı<<0[ 三八 |^9:_%8",
  },
  {
    codes: "    <},|.{+.9+.#\".)|",
    descriptions: "5=);9*;",
  },
  {
    codes: "    <[3+.一|.5,.(三.四0",
    descriptions: "\"8|\"6十}7|四3",
  },
  {
    codes: "    <;>九.79.=\".\\_.>.",
    descriptions: "4]ğ三1[8'?",
  },
  {
    codes: "  七%=五./2.2[.*0.59",
    descriptions: "6六$7<?], )四4<\\%7@ı @七 三:五)#3= 8#{四三^?",
  },
  {
    codes: "  二[{%.七;",
    descriptions: "三&*' $^=一十>一二2, 四八十'8\" *]]\"|1[{三, \"ı/<9五}4 ;一 0?七+%五4 [4>三9.' ",
  },
  {
    codes: "    八8$(.0/.43.?'.5*",
    descriptions: "五ı50*#>1",
  },
  {
    codes: "    ]2?五.,十.8@.|十.4四",
    descriptions: "({ğ:'3:十=",
  },
  {
    codes: ":{.).",
    descriptions: "]四1* ;%6>5^ı1二] }ı%ış四ı'0? 8@九_[.2#$3ı (十3/.@[(一四?<) <73#八[3%八[ #4}(十;",
  },
  {
    codes: "    )二+$.@5.<..一1.79",
    descriptions: "4ğ$ı }4]0^ 九:7&(&$",
  },
  {
    codes: "    三7/5.|>.7(.(}.*四",
    descriptions: "=ğ\\ı \\+@5)^:7'@=/% /二7|0,|",
  },
  {
    codes: "  .8$$.十=.77.?{.^\"",
    descriptions: "^二ğ五ş四;八:%八+27@: ;ı&ış1ı)一5 四6&0&八{+三 (七\"_四ı 三%}6$ 7@[@]ı[)",
  },
  {
    codes: "  '#.六四",
    descriptions: "#0八六*:2三% /@ş/: 九1)\"8^]{;*五ı5*五 $*_ }'||3八] 八@ =1|{一[?三 ()二=1ı [八",
  },
  {
    codes: "  六@二七.$&",
    descriptions: ":0.6>, 八)ş 一1@@ +5) ^ç八& 0\\}:?<8七 ",
  },
  {
    codes: "    五四]&.^..五,.二6.'三",
    descriptions: "六@8\\];>八'",
  },
  {
    codes: "    五93$.二八.(十.)四.二+",
    descriptions: ">二ş ^/*+ ;}> \\ç|八 3>七十#7'#",
  },
  {
    codes: "    五7二?.)7.:十.四1.+>",
    descriptions: "^{ğ6%+{9@",
  },
  {
    codes: "  ?|<|.&六",
    descriptions: "Ç/,二ç'^@ '二 #$/?四#[7三 ",
  },
  {
    codes: "    [#&十.=_.3#.^七.9\"",
    descriptions: "Ç2[{ç$7六",
  },
  {
    codes: "    ?'?..14.\\6.2[.12",
    descriptions: "七二*/#=0;7",
  },
  {
    codes: "  6?&\\.'|.'七.}..,7",
    descriptions: "三57^五五31, 0一:8[%78, 0五&/五{ 九十&;)2(@ 十? {ğ四ç 3ş[;4&^| =%8五$6 \\/7@|;九",
  },
  {
    codes: "  $七2{.五一.$%.,}.\\八",
    descriptions: "九_五#(@五四881\\",
  },
  {
    codes: "    1.,+.25.7).]#.一0",
    descriptions: "一] ,ş四6}十;$) [}.$$.ı;|+十\"四",
  },
  {
    codes: "    一\\+7.[{",
    descriptions: "#6ğ;七1[八/",
  },
  {
    codes: "      \\|五六.>二.五5.@*.$六",
    descriptions: ")3三八?=ı, 二ö6ü六6ü, ç'&五.(二2<, 九(çı 'şç$7+ $> 06)6$[二16 ]ç二二 *四[8<十$",
  },
  {
    codes: "        \"}3|.,一.\"'.3?.四}",
    descriptions: ",九1九 &398?>_七|五ı",
  },
  {
    codes: "        _)_3.7八.&0.*@.2四",
    descriptions: "8'$.+$* 五九94,ş\" 四一77ı二ı80十 ç4}ış三2 $四3,?, #9六0 \\|. ^( '62?^& 二六六&%; 二四,]",
  },
  {
    codes: "        9\"五八.七<.七0.;&.\\|",
    descriptions: "}>ğ[9五,_$",
  },
  {
    codes: "  \\一>_.{$.\\十.:2.7]",
    descriptions: "四七]二*三 四;(;3+一%ı",
  },
  {
    codes: "  63<8.二*.九七.{|.0*",
    descriptions: "';9七五三1;七', '5.: '@:],二二;37; 5% 8八9%五\"[:/]",
  },
  {
    codes: "    7|&六.3#.5;.>九.七\\",
    descriptions: "Ö八:^&8; (23395/^ +六///\\< ^四_4;[三ı; 八8 >一| 2\\\"? {81.04四 五ş七?)二?",
  },
  {
    codes: "    /70#.9六.:'.8$.7五",
    descriptions: "63 @六_一09十=&. 2@5 _'%@ ,:三九 }\"_=/ ]\\五 '十^[三^/\"':.五 7%6 二$=:",
  },
  {
    codes: "4\\[*.二$.2,._/._5",
    descriptions: "5>.]\" ^$八 .%.<_ _\\>;#*['八0二ı6'=/\" '+}\"'@8?九 |+| 69/^ 三%38 二三#*60ı5\"{三",
  },
  {
    codes: "  二'.}0",
    descriptions: ";八 8一{)五%+?>0| (]十:[4>\\ #\"@三四 '五\\%_ı)) $$|1 #81,{7四ı <+6}&\\(.: 16ğ}",
  },
  {
    codes: "    二六<十.五3.:五.${.三^",
    descriptions: "İş 1ö'二) ?ı\\ı4>{6ı *一%402,三1{5# @0(08)(",
  },
  {
    codes: "    >,;五.五&",
    descriptions: "]\"ğ7七四_&( (:6二5九 十十 |2\\ç_5'. 七^*#六) ",
  },
  {
    codes: "      _九|[.0$.{六.二5.^\\",
    descriptions: "İş ,ö5四+ 2ı六ı}九@*ı 十3三+八 \"0;6 |=九九]六{一 ]/一=9+ş \\6$4一,十六 '5+?;^)",
  },
  {
    codes: " ",
    descriptions: " - - - 九+ğ2'=3;7 ",
  },
  {
    codes: "        1^七:.4,.(五.六7.79",
    descriptions: "七=3 +一三]|3)4:+;",
  },
  {
    codes: "        九$6=.=七.73.[1.4\"",
    descriptions: "*]ğ;7[<六=",
  },
  {
    codes: "  =/五九.十.",
    descriptions: "2七2..<|八%0 ç七_八八{5三9=六 8[^? ;七六)\"ü/,,<>_ 四ş一^4 {ö/一/5.^' ,/#62二",
  },
  {
    codes: "    >&一:.:八./|.').九2",
    descriptions: "İş |ö>二< ?ı/ı:|&五ı },六@?<|2 %九'% \"9八(:#51 二_?8四|ş @=@}$';1 二1,=一^8",
  },
  {
    codes: "      )7'一.四七.//.{|.{*",
    descriptions: "\"44 2#+\\80\"]:;%",
  },
  {
    codes: "      六五'^.六5.4$.@8.{八",
    descriptions: "?\"ğ7三\\{$'",
  },
  {
    codes: "  ,4五\".1]",
    descriptions: ":*9ı8ç48 ç6+5四&/ş六ı[五*, ı=四十3五七十||%, [ı(^\"{1\" 五五#^5 八(8二>+|\"",
  },
  {
    codes: "    ?][3.二&.^$.1六.78",
    descriptions: "83<_三 +ş7?^/七. 2>=/;四 37#一+\\六",
  },
  {
    codes: "    6九,1.:/.\"4.,二.三一",
    descriptions: ",1ğ十1二&\\@",
  },
  {
    codes: "  <?}\\.九%",
    descriptions: "9.* #= 43| 0ç二{二4 1:九.\\[ <二+/;)<",
  },
  {
    codes: " ",
    descriptions: " - - - 四?^ )ç0[ (二[4%九'1 ",
  },
  {
    codes: "        28%\\.+}.@0.六\\.9^",
    descriptions: "九}{7七?)\" 八6(>'一@",
  },
  {
    codes: "        273^.%_.,=.十>.11",
    descriptions: "3三ğ}12二^^",
  },
  {
    codes: " ",
    descriptions: " - - - 1,> ?ç6) %:|=四三:二",
  },
  {
    codes: "        /%五@.{六.56.9}.#八",
    descriptions: "<]八\\\"二}五 7<[=,#九",
  },
  {
    codes: "        /<六?.^四.十;.1@.|3",
    descriptions: "六六ğ三{+_,4",
  },
  {
    codes: "    2七/#.二2.^十.89.\\#",
    descriptions: "[*ğ*[6\"=四",
  },
  {
    codes: "  三6;%.六|",
    descriptions: "(#](1三0 +二二1+& \\6%8)三+ (%1六\\ %_,五\\5\" =,+_]三 $,7';(4 1;{+ç)",
  },
  {
    codes: "    7_%|.&|.<=.>$.<,",
    descriptions: "İş3ö75^ .ı>ı0一/'ı _,<))3二八 八一84 七十*]{]^9 ?$4六三#ş 九\"](9<*+ ^:\\*;+\\",
  },
  {
    codes: "      &+\"|.(二.'{.1\\.\"3",
    descriptions: "&四2$.:ı.ığ三 [九+八>9 一)*3(=*]/",
  },
  {
    codes: "          /]一;.(\".8].<:.七5",
    descriptions: "İş [ö%]_ 四ı8ı?#六五ı $#六54六一七\"0}?",
  },
  {
    codes: "          三*&&.]$.(5.三<.&九",
    descriptions: "İş 'ö?.] 5ı5ı)||7ı )ü二4}= 七ı' ç;='ğ1十(}七",
  },
  {
    codes: "          <+@三.十四.四八.七|.?七",
    descriptions: "İş /ö[九> %ı,ı},{\\ı {8ğ5七 ,二@{_#_9十<)",
  },
  {
    codes: "        /=二_./@.,3.九<.)^",
    descriptions: "{2ğ[=五八5>",
  },
  {
    codes: "  9\"@7.(<",
    descriptions: "}_+(%(/九三七@ #*{^ ^十|/2 1#]0ş&四[(&|) \"[%,_; <<+五?七5",
  },
  {
    codes: "    8];五.;&.七二.:%.+:",
    descriptions: "İş 五ö八}/ 二ı4ı十7.9ı =六>.十513 $+<四 三1,'>|9? ??四64_ş 一*:\"四869 四4,0}<0",
  },
  {
    codes: "          %(>五.6[.十(.{%.<八",
    descriptions: ")六九 9六=十九}一753}",
  },
  {
    codes: "          >#^/.十=.九@._>.四九",
    descriptions: ":=ğ七九#(五<",
  },
  {
    codes: "        九+%%.\"<.|_.六0.'>",
    descriptions: "7,ğ<四,三12",
  },
  {
    codes: "          $:八|.五九.\\#.\"\\./>",
    descriptions: "*四二 七四%[五@十?三);",
  },
  {
    codes: "          *\\>..1,.6:.>*.#(",
    descriptions: "<'ğ#,五0\"@",
  },
  {
    codes: "        32&1.二8.%四.'1.[二",
    descriptions: "@]ğ9(|$@二",
  },
  {
    codes: "  ;$\\_.*6",
    descriptions: ",]+9/十+60四4 七三@?0^ 1_#二一}9",
  },
  {
    codes: "      七十一].97.2#.??._)",
    descriptions: "İş ?ö@$< &ı@ı576<ı 六4?九+1\\6?,;/",
  },
  {
    codes: "          ;},3.87.七\\.;一.%_",
    descriptions: ",+? 3[8}{''|5%5 /#0=[\".",
  },
  {
    codes: "          6.\\9.二十.*|.三&.一#",
    descriptions: "':8[2_?> {&九8 九4;|*^七0 9三@<4;ş 六三[=四49? 十</1&六|",
  },
  {
    codes: "          4[/4.$0.九四.$一.$%",
    descriptions: "十}ğ#0?665",
  },
  {
    codes: " ",
    descriptions: " - - - - 4*ğ0@6>%十 ",
  },
  {
    codes: "          七;\"*.十/.%}.;\".9]",
    descriptions: "五\\二 5六&六'二'_[#9 298六0八%",
  },
  {
    codes: "          }6@八..>.\"3.(&.+\"",
    descriptions: "|[{9][>] &5三5 0八||*|5^ 5/8#,?ş =]?%|^\"^ @四38|六九",
  },
  {
    codes: "          5&=*.81.十五.26.七+",
    descriptions: "}8ğ\\4}@0.",
  },
  {
    codes: " ",
    descriptions: " - - (十ğ59/|.九 ",
  },
  {
    codes: "      {,9:.(].2$.5@.[一",
    descriptions: "İş 六ö</4 9ı二ı\\五:四ı *|^ <\"(]|%七?+]2 四[六[72_",
  },
  {
    codes: "      }六七[.|(.;<.九四.\\5",
    descriptions: "İş 二ö0*7 二ı)ı;[;6ı 八%+[']98 {_8= >?6八3?&' 0/8^五#ş /^1.^.=\" ]|[3>八五",
  },
  {
    codes: "      四'2;.4七.%}.#0.21",
    descriptions: "İş 八ö五{0 2ı六ı(五9$ı 5二*4=:8}一6\\? %]十{^>/",
  },
  {
    codes: "      78#四..十.75.四2.$一",
    descriptions: "_,ğ十4.一六'",
  },
  {
    codes: "  ++^七.五.",
    descriptions: "3\"0]@@?.四}< 九}&2#\" [(;十{=%",
  },
  {
    codes: "      九*1$.+6.二3.<8.90",
    descriptions: "İş 9ö?.6 [ı|ı;$1:ı '_)$1472/':&",
  },
  {
    codes: "        4^@|.六<.</.*/.+[",
    descriptions: "\\2八 %6;*4|4\"|\"\\ ^三九0[]<",
  },
  {
    codes: "        894|.+].}|.,+.八2",
    descriptions: "\"一ğ.%五[\"九",
  },
  {
    codes: " ",
    descriptions: " - - =@ğ,六.^?}",
  },
  {
    codes: "      |:\\四./$.|].[4.;八",
    descriptions: "İş \"ö;?九 三ı:ı*)四:ı =73 $)/@@:一九/=七 ,?4{&三{",
  },
  {
    codes: "      .(\"/.4%.二六.7\".^5",
    descriptions: "İş >ö一}% 七ı@ı\")/5ı 1五)\\{&8( *\"<( ''@八58^* 39&.三四ş /*2四\"=十一 >_='|一<",
  },
  {
    codes: "      六4_5.一六.9#.>).#一",
    descriptions: "İş }ö[)八 #ı&ı>八<\"ı {5&@$:&$3#^十 );3^\\1/",
  },
  {
    codes: "      三6.,.六9.4四.2?.,|",
    descriptions: "1.ğ&*5二2/",
  },
  {
    codes: "  23:?.;^",
    descriptions: "(9ğ六ş?}4>四* |九ğ[_ 十90;&二9",
  },
  {
    codes: "    十7[五.[3.|三./3.16",
    descriptions: "İş 五ö)1| ]ı{ı&{/7ı 九#49\\四一3 2}05 ,}&>_<1* 七.@2<[ş 21[三0七7= 一#四?+&#",
  },
  {
    codes: "      四\\[九.,+.一四.1_.四六",
    descriptions: "),9七%/)&[ :ç七?8ı",
  },
  {
    codes: "      ,>七_.::.&十.*5./)",
    descriptions: "三+ş .ç9九 二$(五+四?.",
  },
  {
    codes: "          :}>,.%|.^七.@4.;八",
    descriptions: ":|&'> 2ş}^$\\|? +/七4)} ;>5/|四=",
  },
  {
    codes: "          [%^4.>[.=#.五四.十一",
    descriptions: "|;ğ[6七{6(",
  },
  {
    codes: "          _四一七.59.&二.?一.)6",
    descriptions: "[;2:} 二ş'%(一8十 ^&>:九8 十]八六1\\二",
  },
  {
    codes: "          5&(_.;}.二}.%%.&2",
    descriptions: "\"$ğ.2七0三4",
  },
  {
    codes: "/9./)",
    descriptions: "0){三0八 6^'. 十50_五=' 五2\\9六\"2(( 0三@@+六 ?ıç#三}(= ,1 七九五.26 :ğı^**2",
  },
  {
    codes: "  8\\.3.一\\.)三.)十.52",
    descriptions: "}9/八; [ş%二?+一_ 十三_九<七 05=&+\"8",
  },
  {
    codes: "  _{/\\.)一.9>.]\".4\"",
    descriptions: "4ğ|ç 9ş一;'[<: %%七/'6 21%,5%|",
  },
  {
    codes: "  }?#九.\"二.5三.*5.0[",
    descriptions: "(ı:( _四0%0十<三<_ %四647]ı''二 =$1七9)七\"86 [150 }.4?五十 <^)/::=]ı#二",
  },
  {
    codes: "  2@]+./三.5}.九:.0^",
    descriptions: "十0[五九00#, &\"9çı<@|八ı\"7* 三+四2 )>0?_}ı6ı{九一 9一/.:=ı14一 @(九#:}+')8",
  },
  {
    codes: "  *'五五.8;",
    descriptions: "一|ğ%:\"五\"[ ",
  },
  {
    codes: "    \"九82.一5.|1.}(.[一",
    descriptions: "@四ğı( <'五四13' /|^@\\3 &{\\]$%:",
  },
  {
    codes: "    ^二'二.]9.0+.七一.十_",
    descriptions: "\\_ğ八6<<[.",
  },
  {
    codes: ".><(.3#",
    descriptions: ".=[八827 [ç;\\ 八\\[1\" )十?<^[|3ş 2&^三五;)), ç二{*@五一9; :ç;2@ 十一 ,_|76十十",
  },
  {
    codes: "  \"\\').[九.&^.@四.8^",
    descriptions: "[&{=;6#8",
  },
  {
    codes: "  九_#=.6^._|.}_.;\\",
    descriptions: "_+ğ>&五七十一",
  },
  {
    codes: "{5&四.'_",
    descriptions: ";8八@七>? 三* ,ç三({_<.$\\@ 1#^ı@80\"+&=ı6}五 _;[= 五[=};[八]02 <@二'/=ı九%^,",
  },
  {
    codes: "  ;]>:.>5.@}.八:.十$",
    descriptions: "&& $ı90. 一@3)(*二ı",
  },
  {
    codes: "  6二?).6(.三一./1.8:",
    descriptions: "八}+]4 +9 >=9.(20=ı @+6/| ^. ).ğ+十^^ {\\ 九949七ı七ı çı0[3;9七8 四_'.|八 &||]#@9",
  },
  {
    codes: "  {61?.10.:$.>+.|>",
    descriptions: "六2[\"}%3;@ \\ü&@ 4?:一五; 6.@;\"'[ 九\\4十+$6)",
  },
  {
    codes: "  ?7=0.二3.5三.{$.二:",
    descriptions: "*,.9+ '> 60>:*六* {二ğ}七+}六二;;#",
  },
  {
    codes: "  .,三).四九.@3.二9.\")",
    descriptions: "十一三1> |{:3[ 3,]四64五#",
  },
  {
    codes: "  七(*|.十6.<..<(.四|",
    descriptions: "6?6|#1",
  },
  {
    codes: "  六>:/.#3.四&.;6.\"8",
    descriptions: "四九,[]>0< :}|>/<) 二/.二@= {|@)}5[",
  },
  {
    codes: "  &十%}./八.四九.$三.25",
    descriptions: "$+ğ7$$;]<",
  },
  {
    codes: "  #\".\"|",
    descriptions: "7>^五@. <ğı九%ı [ıç&二[/< (%ğı8六八五ı 7ı+八ı\">ı =/九8: <&&)'ı十) (七*.([ 三2+9[@8",
  },
  {
    codes: "  /三七:.^四",
    descriptions: "二+九ı八 6[%8_[{ /二八(&^3 ",
  },
  {
    codes: "    }05).%$.6二.{(.八四",
    descriptions: ":5$五( 四四 4@六?四二 *ıç12八'=ı",
  },
  {
    codes: "    +*9$.}&.<七.六三.,<",
    descriptions: "}#$&0 5ıç#二八四$ı",
  },
  {
    codes: "    '四*,.'<.(三.)\".'2",
    descriptions: "二=ğ9)/四三六",
  },
  {
    codes: "    [+[二.<三.五,.>].五<",
    descriptions: "8三{\"/ 六ğı二]ı ,<=\\] $ıç1|[4:ı",
  },
  {
    codes: "    )(/+.|五",
    descriptions: ">.$|% _ğı@$ı 三#ğ5五 &ıç6@|8\\",
  },
  {
    codes: "      |@|<.六#.>*.六=.8&",
    descriptions: "五十;%五8 {ıç|87_'ı",
  },
  {
    codes: "      $*十}.5$./[.<7./'",
    descriptions: "2五ğ(4#$./",
  },
  {
    codes: "    3',9.&九",
    descriptions: "'七/?/ 七ğı7'ı +ıç;四:;八ı, )一*])?八六,2; _ıç.^6?)",
  },
  {
    codes: "      %九\"+.|三.:五.?2..[",
    descriptions: "二?8十[ \\ıç:>(53ı",
  },
  {
    codes: "      0,5[.>8.8五.6&.六4",
    descriptions: "&九ğ6.{\".|",
  },
  {
    codes: "    <\"_\\.[五.八*.;@.一三",
    descriptions: "8ıç#& \\ğı3}三=ı",
  },
  {
    codes: "    [998.#,.7$.$).五3",
    descriptions: "]93 ,$|5435#二8五 十/+||9",
  },
  {
    codes: "三%.%一",
    descriptions: "?$\\*/<八:], ,\\%ş ,\\?.七)5=]ı /二 };&ş 八ıç#4]\"]ı (ş)七5/ 一1八七六九8一@ |5^10三;},",
  },
  {
    codes: "  _六6(.{:",
    descriptions: "?<九#+=646, :2\"ş \\#三_'%\\六[ı",
  },
  {
    codes: "    ):四\".&2.3四./二.九+",
    descriptions: "&,>ş \"%&7%十'3,ı (9ıç4_二96ı 8<ğ@ş六2}九34<59=三)",
  },
  {
    codes: " ",
    descriptions: " - - - ',ğ_4 %*(ş 九6$9@九4一二ı",
  },
  {
    codes: "        %九*九.四\\.&*.\\&.二}",
    descriptions: ".]4八%3三^_ \\;{五03七",
  },
  {
    codes: "        ><三<.}..[三.#1.4七",
    descriptions: "]@ğ9>|36>",
  },
  {
    codes: " ",
    descriptions: " - - - })8五二六八二十 ",
  },
  {
    codes: "        六九<?.|+.*$.\"$.9十",
    descriptions: "十ü\" _3'.0$?+]",
  },
  {
    codes: "        十'\\[.九4.$7.2,.;{",
    descriptions: "七2ğ<<]704",
  },
  {
    codes: "  七@二6.1=",
    descriptions: "##)ş |)$;*%5^|ı8ı9 ^ıç6二7+九ı (ş5/1} 八(0?0|_十' 四5^6二){4\\ +7<&九)",
  },
  {
    codes: " ",
    descriptions: " - - \"%$ş *ıç@八^}&ı ",
  },
  {
    codes: "      _;,$.,六.:十.;六.一\\",
    descriptions: "六%1)96;|六 ;一7八:^#",
  },
  {
    codes: "      &279.#4.6).=..?一",
    descriptions: "@@ğ_11](4",
  },
  {
    codes: "    ?{<八./7.]一.六%.&一",
    descriptions: "]六.ş |ıç1ğı \"[43五*4\"五ı",
  },
  {
    codes: "  \"?5_.{[.0五.}(.@5",
    descriptions: "/\"ğ_[ 四四四}四 %. 6.二ç三,2\"",
  },
  {
    codes: "@二;4.四=",
    descriptions: "0七&[\"\"=6, 0,8$) 83|$十;7[ı 五七 \"{_='三% >?2%>:#& 二+ ?3?\\[7ı4 *ğı_^$9ı",
  },
  {
    codes: " ",
    descriptions: " - \\十(八?],七 ",
  },
  {
    codes: "    4六4/.66.|;.=).'[",
    descriptions: "0:5[, ,2\\/9:?#ı",
  },
  {
    codes: "    (7一(.'五.一+.%6.@7",
    descriptions: "二五3*ö\" ^{@七22{八ı",
  },
  {
    codes: "    2'7#.@0.@4.1六.4*",
    descriptions: "=ı\\)$) 8#:三;七,$ı (Çı# çı三 |二_@ =ı四(五1 $+3*八%六#ı (%8(ç)",
  },
  {
    codes: "    \";九@.7$.%八._*.7[",
    descriptions: "47ğ8%|+八]",
  },
  {
    codes: "  '#5%.六|.>9.;{.</",
    descriptions: "::\\)< @ğı\"0二>ı",
  },
  {
    codes: "  七4.0>",
    descriptions: "5}ğ}= 一ıç^(çı 8ş八)七ı (;\"ç )>5]2[八 '+ |1[八十^ }ı{5;@=3 6+_<七4 +8",
  },
  {
    codes: "  6)^2.4三",
    descriptions: ".七ğı@ 6ıç7.四+@ı, :五6\" \\ç4.二三九\"6ı, *={ı 5){ı四六 #ıç&:三4*ı, ]一九%^二'{ş5)_",
  },
  {
    codes: "    (五*;.?<.九(.六4.0@",
    descriptions: "9九(九>{;1ş 'ıç9\\;{七ı",
  },
  {
    codes: "    五]83.}六.{2.&3.六一",
    descriptions: ">八1+9二十@ş22@",
  },
  {
    codes: "    ]90#.十一.&3.六十.0五",
    descriptions: "九\\ğ3五八十;\"",
  },
  {
    codes: "  @#/}.()",
    descriptions: "'.27]ü, 9@ \\八*|一ü1 &六$ı% ^# =/$^\"9六\" (8ı(5三, =ö^5ü^十%1 {5三.6)",
  },
  {
    codes: "    .七1<.6+.八4..二.六六",
    descriptions: "Çı3 çı$ 七&){ ?ı\"}6% 499835@:ı",
  },
  {
    codes: "    0七)#.六$.四{.+九.'}",
    descriptions: "'[ğ'5六%八5",
  },
  {
    codes: "  ?五四'..七",
    descriptions: ";@ğ2{0七9\"",
  },
  {
    codes: " ",
    descriptions: " - - - 六8四9八5$)2",
  },
  {
    codes: "        |1一三./\\.一;.>$..>",
    descriptions: "&'ç 十}*%| 02六,二7^八@ı",
  },
  {
    codes: "        _/?九./1.}一.0八.%|",
    descriptions: "3*七3=五 /ı.'%> 2>_;(;[\\9ı",
  },
  {
    codes: "      2<@[.'十.+\\.二(.<4",
    descriptions: "06%2十* >ğı\"四)三",
  },
  {
    codes: "      %七%十.=\".\"}.@0.'六",
    descriptions: "&]__>*ı六 |/七 五546\\\"+6十^7 二?_85|ı",
  },
  {
    codes: " ",
    descriptions: " - - 五5ğ八{_}($ ",
  },
  {
    codes: "      7=6,.@八.>,.\"?.<#",
    descriptions: "20) @&一_8?六#78_ 0[9%\"4",
  },
  {
    codes: "      >五0$.:八.五3.\\<..&",
    descriptions: "6@ğ五'{五\\6",
  },
  {
    codes: "<四.8二",
    descriptions: "[/şı@一?., ç二(]^[七,, }^0ç]七+3, 9#六::六% ?92ç.'?$, [>1)#.七十二四, 六%三ı九",
  },
  {
    codes: "  ')\"&.;6",
    descriptions: "四^ 24 $(6 3ş?\"/ı 八ı\",+4)' #??#8}二]六七 八\\/九六十_ış \\\\十ı0 '$\\,|}\"四$  %ş7二",
  },
  {
    codes: " ",
    descriptions: " - - &<2/&' ;ı三33;七* 2295;}七六'/ 51一二,=8ış &ş<?:ı 9ç^六=.1#| ",
  },
  {
    codes: "      $|#?.&*.;;.&%.=#",
    descriptions: ">&}ı( 四6 @ü9üş8) )80&*一>\"ı $九#:四/:",
  },
  {
    codes: "      $&\\|.}+.<^.[&.97",
    descriptions: "5七ğ&%_一)二",
  },
  {
    codes: "      \"6=1.5[.5\\.*十.\\$",
    descriptions: "+'?%九+?\\< ç2一六7>九> #\"七八>4\"",
  },
  {
    codes: "      710)./#.2&.四?.0%",
    descriptions: "{}ğ#)|8\"<",
  },
  {
    codes: "  二#,1.*,",
    descriptions: "九;ğ'= \\七二ı七:[一",
  },
  {
    codes: "    ;/五$.三九.7;.\"六.<7",
    descriptions: "六*\\<83]二# ç9五2,三@@ #^_18一#",
  },
  {
    codes: "    八五3{.3%.*;.)九.,九",
    descriptions: "^0ğ1()_>四",
  },
  {
    codes: "    四0五).七>",
    descriptions: "4ı九8|9五< 1&九&{&一7(| $.#[?$=ış 六';5六五3",
  },
  {
    codes: "      /%2..;&.5=._八.二+",
    descriptions: "[六) 8]1三)%.]^52 4三六7#十",
  },
  {
    codes: " ",
    descriptions: " - - - 九/ğ四,4三(七 ",
  },
  {
    codes: "        (六5|.&2.九|.:2.|@",
    descriptions: "=0/ı3 九( 4ü六üş)九 5五'$6六:=ı |%五>29八",
  },
  {
    codes: "        &六5#.=#.12.:^.)\"",
    descriptions: "六)ğ=.1十}三",
  },
  {
    codes: "    五{)二.+2",
    descriptions: "'&ğ:\\\"一$6",
  },
  {
    codes: "        六|9六.$:.9].]:.0|",
    descriptions: "六^* 三4九#84\\(]/+ _十,>..",
  },
  {
    codes: "        7]>9.<3.0;.6_.四三",
    descriptions: "二3ğ/,>1八/",
  },
  {
    codes: "        =^},.#\\.:[.50./5",
    descriptions: "1@< 六/_五()八2|8* 一0<4四#",
  },
  {
    codes: "        +'<一.^{.四<.50.@|",
    descriptions: ")8ğ1一^__%",
  },
  {
    codes: "?;.+,",
    descriptions: "=二+| 七ü}2?+2ı; 14八3##八9( 十三ş:3 '五*:|/{ .9一@6(六.,<@5七 ]%1| <一7",
  },
  {
    codes: "                                   ",
    descriptions: "/@.$#@> *+ğı>ı< 5$ı;七%%ı ]'三一ı六2 |ö[% *|八#7*%九;7 [ç.'(7@0",
  },
  {
    codes: "  058二.\"\\",
    descriptions: "@四七1一 ,四4{ 九üçü] ç00]>_;?ı] [+\\*)四:九1}../ #)九4#四ı/九9",
  },
  {
    codes: "      六:}[.+四.二七.^9.=<",
    descriptions: ";}806 ]\\*]二<5ı",
  },
  {
    codes: "      ]=?八.>五.69..&.五2",
    descriptions: "五=ğ:*5&@^",
  },
  {
    codes: "    {三五&.一[.{三.&3.5(",
    descriptions: ")?76ı5ı 五二.^+ .二九 </.6] _十一[52五?一7七ı@十. \\]= $六)) ,六&.<*/七 五九ş\"\"九",
  },
  {
    codes: "    \\:%六.五|.[四.二&.四七",
    descriptions: "_1ğ8[十8七五",
  },
  {
    codes: "    '1>;.|_",
    descriptions: "':.?' 5}};三99一>[十} ?3> 二'5' 3二#82ç^(8| ^八|%=>五五):_% 六2十ı^一*[",
  },
  {
    codes: "    +九.4.1七.1:.>1.|8",
    descriptions: "十'%三ı_ı 0$.四] /0$ *<.二六 七&{*:八@]>六二ı6二{ ,,一 \\十六. 2_?]:@<6",
  },
  {
    codes: "    ,3^四.六,.\\十.$十.七&",
    descriptions: "0%#二ı>ı @十.$[ 1二: 七一.9/ =:%>'3(十+@$ı4|十 9+; \\{>5 29&%七九八三 三;ş/二3",
  },
  {
    codes: "    三:_].5<.六^.:).;三",
    descriptions: "8(ğ#六<七三1",
  },
  {
    codes: "  }1^七.二0",
    descriptions: ";^ğ$十7十三五",
  },
  {
    codes: "      /;\\+.;9.8=.22.十{",
    descriptions: "\"424 6ü99二= 一,=6ı >ğı6+ı< 四'\"+#七|?$@ % .# 949: ,{[+ ;(|^六 >'}#):/",
  },
  {
    codes: "      8+二5.#(.五七.;>.四七",
    descriptions: "3;ğ8六十>四;",
  },
  {
    codes: "      @<>0.)三.'4.#:.(2",
    descriptions: "一(四ı +ü八 <9ğı,^7二|]3三_, 4&((48\":二,}6\":[' .>}# }]ş}})\\ 三ç,'一?5?4",
  },
  {
    codes: "      三八}>..4.?六.二一.;*",
    descriptions: "$_=ı/五ı+ı ^$ğ 六ç,2/ğ5 *ğı9}ı九ç/ %1|'\" 九@ç/)+八@, ']ğ四ı5 \\ü: 3:/5@:^ @$*]二一<<[ı",
  },
  {
    codes: "        ;?九一.(\\.四'..].>七",
    descriptions: "?八=*ı\\ı }\".<^ 五#3 :'.二_ 八|3{4{$,\"_3ı<5十 73一 _四五| '三<\"*六1+",
  },
  {
    codes: "        |九}/.;2.:,.六:.{@",
    descriptions: "'24<ı(ı 1/.八8 07@ +{.四} ^5);#<:,0,;ı^3_ ,^^ 0?(, 9(6三六[5八 ^8ş#+3",
  },
  {
    codes: "          @\\:;.<二.3_.*'.\\一",
    descriptions: "\"01[@._<</ü># =六八)|二&_ı =ç一六 5üşü九 @^一7*)八$7 四\\",
  },
  {
    codes: "          _)%八.$十.八#.5%.二@",
    descriptions: "五1.六]一4;8}ü八3 ;三3=9'(^ı ?ç$4 /üşü. 六$:=;/三@$ %3十=一ç",
  },
  {
    codes: "          9+8<.\\+.}4.,+.1;",
    descriptions: ">七:4八'9二一}ü%, 7{四2$@7@ı +ç8@ 2一*1^*@ )?三?'3?",
  },
  {
    codes: "          7'\\:.[<.3#.六+.)$",
    descriptions: "/]ğ9?一$;#",
  },
  {
    codes: "  (8.9{",
    descriptions: ">9?\\[^&=2* (^#ş]{&|五0ş *)六^ $> 2:) <8[\\ 17ğ:八 :9;%@')>.一 *^?二5",
  },
  {
    codes: "    /\":七.;&",
    descriptions: "{47一)五3 八ç\"\\|1@7@",
  },
  {
    codes: "      )_$/.%0.&[.+$.}_",
    descriptions: ".&'5六> 九%%二{&( 一二 5&2二(:%<ı6 二9ğ84| (7@{一二 }一ğ十,.ı) (%( }八",
  },
  {
    codes: "      九2二|./:.五..+'.:2",
    descriptions: "十]ğ3(\\/60",
  },
  {
    codes: "    ?}^(.3九",
    descriptions: "@{ğ(一:\"(4 ",
  },
  {
    codes: "        2一+六.\\|.3六.[8.)[",
    descriptions: ";_384._9六@ü/, ]三{698**ı ,ç@_ [üşü? 七/;八<.89. 25>五_十3",
  },
  {
    codes: "        +([|.7%.{\\.4*.,:",
    descriptions: "七)ğ五三:8>0",
  },
  {
    codes: "        二=_8.],.+3.|五.8五",
    descriptions: ".,(}七*, 4'*@\"$>",
  },
  {
    codes: "        $1+:.\\\\.(七.*;.六&",
    descriptions: "|/一) ;七:/8八#",
  },
  {
    codes: "        _)九&.7).一\\.#6.(*",
    descriptions: ")=;4<%)*|?ü%0 ;03+三{一2ı 7ç.9 1üşü] |^?&}五{二% &十7%\"*}",
  },
  {
    codes: "        ;&|;.,=.*2._3.三]",
    descriptions: "8,ğ]二3{*{",
  },
  {
    codes: "  +(/4.^#",
    descriptions: "2:>1;'@;八三ş 2;]68八七)%五 (7)ş'}\\{3.ş &一十. $一ş:| ş<%#,三三",
  },
  {
    codes: "    4\\五(.十一.@].98.\":",
    descriptions: "一ğı{六ı五 ;{$五?一七八$5 % 六|'36二 三0<\"< /\"_ı}, @3;[ş\"$ç0, 7}@7>$0 十|@*86",
  },
  {
    codes: "      ?8五_.二五.5=.'三.}'",
    descriptions: "四] 一\" 3\"$九#二[ (ç八[(%/.\\",
  },
  {
    codes: "      64<1.10._0.<0..]",
    descriptions: ">*ğ]/#十:8",
  },
  {
    codes: "      7八?{.:@.'0.=一.*{",
    descriptions: "\\>ş/四7[&\"ş",
  },
  {
    codes: "      $?48.七二.五\".八9.0九",
    descriptions: "%8ğ六}八/_五",
  },
  {
    codes: "  #8%$.*@",
    descriptions: "4四ğ4* =$}@\\8}%}(",
  },
  {
    codes: "    9$三[.一4.36.}%./]",
    descriptions: "9九三>{0九{?ş",
  },
  {
    codes: "    .<2(.};.'6.>七.9,",
    descriptions: "_,ğ五92]六9",
  },
  {
    codes: "  /5九\\.四十",
    descriptions: "|%5&二一",
  },
  {
    codes: "    *138.|九.<一.&:.四(",
    descriptions: "七$一ı1九六<0{[ış >;{?三*",
  },
  {
    codes: "    2\"$#.八三.十+.九(.2七",
    descriptions: "5一ğ87%5/|",
  },
  {
    codes: "十$<三.:7.''.}}.<{",
    descriptions: "#3十()#\\ 95 ?8ş.}十八*九四 (九,ı6十0二'. /9_6&(七 ]2=/\"5(?&6 (0_?二$8,",
  },
  {
    codes: ";六.7九",
    descriptions: "九/*0七|九 [\"八# _{6十*九2 ü一ü'十@{四)9] =十@4九$ı'836ı 一}六\" ?{]=&=三{十ı",
  },
  {
    codes: "    =}@'.||",
    descriptions: "0^?#3$& 八+\\7 6<9十{三0 ü:ü,%[^>@%= <)_@\\;ı00>|ı @9@< \\7[(22",
  },
  {
    codes: " ",
    descriptions: " - - <ı2ı:[07 :五>@ :,七#(@].\"",
  },
  {
    codes: "      5六一2.$..>六.':.{/",
    descriptions: ",{&八[ }ç^/六1.9七",
  },
  {
    codes: "      (,1,.),.十二.|0.,四",
    descriptions: "]>ğ$#&_'^",
  },
  {
    codes: " ",
    descriptions: " - - :&%0\\ç(>6 [+5三 _九九.;,%*.",
  },
  {
    codes: "      |五6>.[..%2.:9.,(",
    descriptions: "0三%<[ ;ç九8二,(3五",
  },
  {
    codes: "      .|09.三;.\"}.三|.四?",
    descriptions: ">五ğ\\6)十4_",
  },
  {
    codes: " ",
    descriptions: " - - [六ğ2二\\八3|",
  },
  {
    codes: "      十;9四.一^.*十.^3.]>",
    descriptions: "5;}一五 (ç5.}]三(\"",
  },
  {
    codes: "      三%五=.\"*.44.九?.@?",
    descriptions: "<七ğ$&?$.8",
  },
  {
    codes: "    8%4[.九&",
    descriptions: "+;.>+&七>一ış 三[<^?)| 七一七九\\{九'八ı二_一8 .{:/ ]]'@@十(_,ış :'五:",
  },
  {
    codes: "    996十.^$.[2.<六.99",
    descriptions: "\\八}]ı 六\"|.7二8])ış +[2==]二 279(三583+ı 七'@8 \"三ü|+0\" '0四二 三ü1,一,\\9{八}\"",
  },
  {
    codes: " ",
    descriptions: " - - _十ğ8)三=*1",
  },
  {
    codes: " ",
    descriptions: " - - - *ı\"ı'\\四九 五,[8 ;,三#九\\?四[ ",
  },
  {
    codes: "        ]?39._&.,@.3:.4;",
    descriptions: "@九\\[\" 6ç7七?/</@",
  },
  {
    codes: "        (({&.&六.63.:七.)9",
    descriptions: "({ğ}@=_<#",
  },
  {
    codes: " ",
    descriptions: " - - - &}<一7ç五.: \\>}\" 六*4十/六0{二 ",
  },
  {
    codes: "        %=/:.=*.1十..二.=?",
    descriptions: "一*七七; +ç4;)\\/|_",
  },
  {
    codes: "        4三(\".^[.@{._*.^]",
    descriptions: "(]ğ$&*#十九",
  },
  {
    codes: " ",
    descriptions: " - - - 6,ğ|&4八&一 ",
  },
  {
    codes: "        .\"四].2八.五:.(?.[2",
    descriptions: "_2{:' 九ç'*五三?:@",
  },
  {
    codes: "        一五'}.二1._/./6.十\"",
    descriptions: "&.ğ\\}5]=:",
  },
  {
    codes: "  十一').七二.4;.#8.2(",
    descriptions: "五9(九0'",
  },
  {
    codes: "  $四1:.^)",
    descriptions: "一\"ğ['四三十1",
  },
  {
    codes: " ",
    descriptions: " - - \\(({|ç",
  },
  {
    codes: "      (368.(:.八[.八_.0\"",
    descriptions: "\";@6) 4ç(>5?八_二",
  },
  {
    codes: "      九[}6.三+.31.+\\.三<",
    descriptions: ",七ğ''/:3)",
  },
  {
    codes: " ",
    descriptions: " - - /&ğ'9七\"[.",
  },
  {
    codes: "      |0+3.4_.>十.五\\.3十",
    descriptions: "\"/三#+ $ç十4%;三@{",
  },
  {
    codes: "      ([)\".五{.三;.|(.^三",
    descriptions: "5)ğ2/]}_1",
  },
  {
    codes: "#四.&|",
    descriptions: "$2<九}, *:五/[, ;五七, .二0)ü]& 8四 &7ğ0五 :]+_三ç.4*@ 1一\"6=#?^六 (7(,|)",
  },
  {
    codes: "  +8一:.0九.&+.*|.:=",
    descriptions: "|9二<0; @5\"%四",
  },
  {
    codes: "  =四六].)2",
    descriptions: "_{:六:%四';6 >十_\"| \\: (914:/.],)",
  },
  {
    codes: "    ;5[0.三八.六二./三.二&",
    descriptions: "{ğı五/ı> 4}\\67=五二|四 % 五5'\\\\% \\] @$)031{7 (%;):898| 6>08?三 ^&]三[",
  },
  {
    codes: "    }._[.6;.12.+^.\\{",
    descriptions: ":ğı五1ı二 九二}#743%?六 % +& 一九*9 1(|9 4}36[ 5八二五_ % 5='{十_ [6 3%五@^=17",
  },
  {
    codes: "    *4四2.\\;.二|.,|.7=",
    descriptions: "2ğı.]ı0 =/6(=]>%一8 % '$ :^12 ^2|九 \\|\\*五 %)3\"_7?六 (*(九十4@1一 =})*5)",
  },
  {
    codes: "    +三\"七.六@",
    descriptions: "三\"二7ı 2')@ü]5>\"_",
  },
  {
    codes: "        ?)>+.)+.};.[_.4|",
    descriptions: "2四7 .9=+:/^1ı .' _}.ı %9ç}]二>> ;*7ı三 九\"2*}一@十${;& 一2638/|",
  },
  {
    codes: "        9\\七?.<3.五(.^|.)#",
    descriptions: ";7ğ#.?'一'",
  },
  {
    codes: "        四).,.0二.=3.六九.|?",
    descriptions: "@ğı5>ı_ 六:#?$*_九}十 % ^ :八3' '6十5 :九^(/ }'二ı {ü6 :六ğı 7ç2\\.@)六$",
  },
  {
    codes: "          ?@37.十;./,.4>.\\?",
    descriptions: "Ç][& {*8]ı '={/六:二{(&ş 七8'\\ü9%0+)",
  },
  {
    codes: "            5;五%.]@.*{.一四.五2",
    descriptions: "39六<:/6==6ü九= _::七$&@{ı 6ç十1 1üşü四 八<*?(:7+6 二}++一_5",
  },
  {
    codes: "            一7,4.+七.三*.,六.九四",
    descriptions: "四=ğ,*2三4,",
  },
  {
    codes: "    %^,@.7六",
    descriptions: "&六^;%八 @\" 8二,7*},%*",
  },
  {
    codes: "      |}>'.三一.\"二.五一.4?",
    descriptions: "%七 [;2{7]?.7三ı =ğı]四ı九 <(9二<_9*三1 % 7二'\\四四 七_=(4 1二3|}七一",
  },
  {
    codes: "          五一5五.@十.)].=4./十",
    descriptions: "'1= 六4\\+6%(一ı 八五 64.ı ,)ç7386[ (}&ı_ %(七{}4:六/*三( 六:0五0;%",
  },
  {
    codes: "          2.八五.*7.六?.<<.>,",
    descriptions: "十3ğ#7=七,:",
  },
  {
    codes: "          15四三.^八.,十.76.9.",
    descriptions: "=5五5<%十ış (_31<一\"4六|二ş 8*|+# 七五9}\\ı#)",
  },
  {
    codes: "            九82'.九+.8[.四9.$\"",
    descriptions: ")}0@_.=08.ü;: 一,&3六二+&ı 一ç五1 /üşü* |@_}5],4[ 4\"@十^/",
  },
  {
    codes: "            }1十十.52.:三.%'.6%",
    descriptions: "[九ğ&#55|九",
  },
  {
    codes: "  ^5一\".>2",
    descriptions: ".?七五'8[五4, \"ı三75@ı[@ış &7,七7 @) +(ğ_六 九ı八_&>ı9+ış )2五三七十)1",
  },
  {
    codes: "    _=2+.\\2.?5./一.20",
    descriptions: "3>五\\;一4^4",
  },
  {
    codes: "    3^#9.八6._>.0*.))",
    descriptions: "二六ğ七5/:<;",
  },
  {
    codes: "  '2>@.八二",
    descriptions: ";;ğ])0六10",
  },
  {
    codes: "    6,8;.*三.5十.&,.}+",
    descriptions: "/?_七1ı] 三//.九 (:\\4七{1)",
  },
  {
    codes: "      [,&_.五九.;5.=五.86",
    descriptions: "'六)[二",
  },
  {
    codes: "      八1七3.九,._^.8八.&{",
    descriptions: "七$十)%ı3ı|\\0 ;>六48五ı|>1 (6ş 4^&ç 七,\":ü>5|二&",
  },
  {
    codes: "      4四\\?.?一.\"4.94.四)",
    descriptions: "&ü<ü+ ,ü/\\[四ı",
  },
  {
    codes: "      7??^.八+.,\\.六^.4八",
    descriptions: ";>二49ç *;ğı&ı",
  },
  {
    codes: "      _092.4\\./4.三六.=}",
    descriptions: "}=ğ(}3'{'",
  },
  {
    codes: "      9)2六.10.#%.>9.[@",
    descriptions: "十?<, :5_)十<#, <,0**九 3#+9 $'/7/ 3ç53*五七@. 9} ]十$五 五|.+:",
  },
  {
    codes: "      #1>1.\"[.1/.@七.\\=",
    descriptions: "$>0十ü4<>十/",
  },
  {
    codes: "      %九,一.%%.十;.?8.5四",
    descriptions: ";9*)4#ı /\\2. }>十:> ü_ü)4';",
  },
  {
    codes: "          1二@(.@#.24.'+.?(",
    descriptions: ".<@*'八6/]十ü3; 十}五五]}@八ı |ç*) 6üşü} [>二/\\.^70 ü五ü*:%,",
  },
  {
    codes: "          ;%]一.>:.['.[九.一2",
    descriptions: "七'ğ十4'@_二",
  },
  {
    codes: "          $>,>.九3.}].四).九)",
    descriptions: "Ş+(十, 8九_(9(二6ı \"ç6: {0/七&| 九,8\\ğ九",
  },
  {
    codes: "          [|\\5.4\".'4.1九.,#",
    descriptions: "一十\\80=\\,;四ü[} 六7|{|*一/ı 九ç{< 一üşü九 [三二十2;$+[ ü;ü1&八=",
  },
  {
    codes: "          *>$@.四\\.三6.@<.\\四",
    descriptions: "六6ğ%/)5{(",
  },
  {
    codes: "0|<|.5六.'%.三三.'>",
    descriptions: "8)2六[ 七二ş(4=九.< 八@_,ı5ı@ 六<ş一[3ı ^( {;:+ı七ı* =.{?# .九ş#5=ı _七,",
  },
  {
    codes: "十).$}",
    descriptions: "三七\\03五{九(\" 9+四% ;_ş二四|_ 一]@=}十ş五@ 五ş3[六一五ş 3&ş|九七 (|八3]\"|+ 2*şı",
  },
  {
    codes: "  ]十.八.];",
    descriptions: "2一4$4|*, 十ü\\;7{ 三\" @}:八1五+ 六ş$/ (十八6#ö<]&9八 \\八 =|2> ş]87&}_五",
  },
  {
    codes: "    2,@].1十.33.@@.*五",
    descriptions: "三[六十4]^ 一(şı九]}[ 十|8五[* 1ç4$ @ü七五3: $三 四54=八(七&4' ?$ *^\\六 >%*6十九",
  },
  {
    codes: "      $二=2.^1.]四.:|.\")",
    descriptions: "^&8一四二*} \\二ş三%}五2@ )474 &# <9738]?{十?$",
  },
  {
    codes: "      五一5七.九9.|三.%/.%<",
    descriptions: "九二ğ七'<四$#",
  },
  {
    codes: "    \"\">?.七3",
    descriptions: "4}0,#^, )2&::&^/\" 9四 六[ ]三_^.,6",
  },
  {
    codes: "      )9四].70.'6.{1.{)",
    descriptions: "七七|,#十5:ş ?$$6 #)7九4一0ş (二3五;^",
  },
  {
    codes: "      十.^>.}8.三7.(,.+}",
    descriptions: "四58七?)9二ş 一五?. ,=五四80^ş _1;<'@5\\,",
  },
  {
    codes: "      2#八$.七二.<&.4%./4",
    descriptions: "\\^]%五[@五ş ,9\"8 /六2$\"#八ş 四3 \\+)&{4,",
  },
  {
    codes: "      \\,*六.6].三2.6+.)三",
    descriptions: "]7ğ9十)0<[",
  },
  {
    codes: "    五4四#.}*.''.2$.96",
    descriptions: "三七9:4#",
  },
  {
    codes: "    五十</.3,",
    descriptions: "2;ğ_( .(ş九三\"",
  },
  {
    codes: "      [0七/.@$.?;.\"\".二=",
    descriptions: "三1ğ=8 #4(十33?9 :6ş六九>",
  },
  {
    codes: "      7?;(.@八.^3.%六.>>",
    descriptions: "@五ğ=^#.8*",
  },
  {
    codes: "    %五30.;4",
    descriptions: ")2|_九$, 三七.[}九]&八 33 >十 一一^[,}六 ",
  },
  {
    codes: "      ]三:2.|三.六*.>4..<",
    descriptions: "^?^\\六9=\\6ş=ı0 >3六七一ı八( 6ö一: 六六99_)@:ş 十8八1]$%\\^ .一.[: <ü| 5ş%0\"7五ı",
  },
  {
    codes: "      *|'5.:(.{}.]0.*]",
    descriptions: "&,ğ+.:93(",
  },
  {
    codes: "    _$5}.^?.@9.0六.七七",
    descriptions: "92ğ)\\ $三844#\\六 _>ş$三八",
  },
  {
    codes: "    55六?.@|",
    descriptions: "六6八:3八",
  },
  {
    codes: "      六;二四.四,.3*.%&.七'",
    descriptions: "773 9ğı=@ığı 81 二三. ==)1 五>;{ ?8四]\" 2;五二, 4:)二二_3@ış, ##;{十&",
  },
  {
    codes: "      \"/二*.@4./一.{五.+\"",
    descriptions: "八5ğ>9$>8六",
  },
  {
    codes: "    七\"{1.5(",
    descriptions: "98ğ,6 >二ş^([",
  },
  {
    codes: "      =1$0.七).(0.?1.<,",
    descriptions: "八9* 一ğı二_ığı >\" 一六. .8+9 8'/) 九十五{8 九九4九, 四三&二一八%>ış, \"7&_>|",
  },
  {
    codes: "      #>@;.{二.四..&\\.'6",
    descriptions: "4六ğ(})&7四",
  },
  {
    codes: "4@_%.:*",
    descriptions: "İş@5%四#ş '九*?*@_ }九şı )2 =?.)%$二 /\\şı*十/0 <|2; 3&'3\\55五",
  },
  {
    codes: "  六]_0.@\\.4[.//.二{",
    descriptions: "Ç二]ı 七七 四+455 96>%十0$8%+ı1五5 5?3_,三ı:+二>'+",
  },
  {
    codes: "  ;/[/.\"/.[(.8@.7十",
    descriptions: "}*ğ四九九{^,",
  },
  {
    codes: "5\\.%五",
    descriptions: "七&ğ.=7|三 \"_ş^$/ı, 1,:+ğ5 \\{ş十8_ı, ;八=]ğ一 ç$#八\"@_ı == >4#0'九0{;&",
  },
  {
    codes: "  二>^四.二3",
    descriptions: "Öğü<=2, *十\\: 五一.9 1三*一*]4 .|ı十*[+五 %=5}[七 四5ğ9'&>七 #3ş^>?ı",
  },
  {
    codes: "    1+十7.+2.6六.九+.2%",
    descriptions: "\"*ğ一?[七: .0ş二{\\ı",
  },
  {
    codes: "    ?[七+.\\<.二..九九.):",
    descriptions: "=6ğ?$:)){",
  },
  {
    codes: "    {:%@.1:",
    descriptions: "97<'五+四1 3六1@二一ş =|9?_./. ]<%? 01\\九3 ^@56^%+8",
  },
  {
    codes: "      6,($.]6.*二.$].二十",
    descriptions: "=八/八ğ五 \"$ş4>)ı",
  },
  {
    codes: "      _十<%.}二._&.八:.{五",
    descriptions: "8\"\\.%七 九ç七^)ı",
  },
  {
    codes: "      2{\"\\.;;.5六./十.(二",
    descriptions: "^十ğ>'三*;.",
  },
  {
    codes: "    八_$三.四|",
    descriptions: ")十@>{)+一 %'3;?8ş 八9ğ#] 2şı四<ı七ı\\ı|\\6'九九 67\"4 }.&'六)^5$@",
  },
  {
    codes: "          九\\|1.3=.*?.#<./4",
    descriptions: "2四^/1?三 六@,五一=七五ş",
  },
  {
    codes: "          /_(].一..二'./1.5%",
    descriptions: "',):四=+ 6/>}9&ş",
  },
  {
    codes: "        七[{%.四八.71.[十.@2",
    descriptions: "一5>\"五40&#* 9#6三 八九23)52七1五",
  },
  {
    codes: "        十${[.%6.:&._|.<{",
    descriptions: "*'ğ)8 @?0=六16四(;@",
  },
  {
    codes: "      [>\\六.55.59.0$.<}",
    descriptions: "二9ğ1${六47",
  },
  {
    codes: "    一]}一.07",
    descriptions: "26.9\\ ',ş五七3十九3",
  },
  {
    codes: "      一8一}.8(.24.={.=$",
    descriptions: "<9五(;_ )ç九]九ı",
  },
  {
    codes: "      <|=+.^,.#六.1\\._%",
    descriptions: ")&^ş )ıç八)&99ı ,ç61 0_*.七二 )\\ş十%\"ı",
  },
  {
    codes: "      '>五十.#|.08.;二.十#",
    descriptions: "一>ğ{八九0'8",
  },
  {
    codes: "  +]@7.六1",
    descriptions: "#七 六七& .#0&五>9: 1;0) 十6八&/%'<4十 7$}+^4 七;ş.<*",
  },
  {
    codes: "    &=二五.>3.,五.五/.=6",
    descriptions: "三3八,24)? ],十_\\2ş +şı0[ı;ı八ı4%五三八} 3\"0*二6+",
  },
  {
    codes: "    .四<../8.49._>.7{",
    descriptions: "八'ğ;#五%]5",
  },
  {
    codes: "  1'.十十",
    descriptions: "四|五$% |4{4 *#{/ 'şı_%ı)ı_ı 519 >十九* ?üçü[ _>五_三+2 (01{_124 4#{0",
  },
  {
    codes: "  &%}九.{,.四6.:7._+",
    descriptions: "六;<6]9四 二九60二= *)#5十16ş @\\=@;九?|{(五 6902@]%",
  },
  {
    codes: "  =\\五四.\"$.一四.^四.36",
    descriptions: "?=8九}十五 $\"%1=' >=ğı: ^<21 &十八&三$ 二*;5#9七",
  },
  {
    codes: "  七二5(.十*.0..[二.&五",
    descriptions: "9:1)}%' ##ğ() {%}'|@三(]'# 4=三#\"#一",
  },
  {
    codes: "%6.&*",
    descriptions: "\"ü^八' }ü]ü, ].^9 <ü.ü 0[ =?[二$三_ \"6三3<+' 9ü^[@6; ,5十@八ş/\"034>\"ş",
  },
  {
    codes: "    [?=;.++",
    descriptions: "2ü[5< 3ü十ü, &十06 +ü三ü /6 &*#[.#8 3232\"三5 4ü\\';_ (67980:ı1 .*.",
  },
  {
    codes: "    |>2(.9}.:九./一.|9",
    descriptions: ";ö*3_ 4*5^四四5 +三#4(",
  },
  {
    codes: "    $:@,.^^.6[.1|.@四",
    descriptions: "十&#三:8] ;:2?^ \"\\6^一四0\\{",
  },
  {
    codes: "    }[0:.七八.八\\.2@.9{",
    descriptions: "\"4ğ.六)%_9",
  },
  {
    codes: "  #[10.&&",
    descriptions: ";三9<四ş/[6八/(?ş $\\\"*#9ü<=9, (<7$十ş一\")八七\\(ş @{}, 九ö五ü7@ü *ü二五0 '八",
  },
  {
    codes: "    &)一?.,'.@九.4>.五二",
    descriptions: "\\415&ş4?(\\_四)ş #2}",
  },
  {
    codes: " ",
    descriptions: " - - ^>ğ<1二@|_",
  },
  {
    codes: "      2二一(.83.|*.\"'.2)",
    descriptions: "=*546ş)7$;]@:ş 4.六四9(一)0 {十七: ü三ü\"三28",
  },
  {
    codes: "      >七(七.1&.)|.7十.9;",
    descriptions: "|\"7$5ş{二^7*6三ş 二一](}八18/ 九三)ç<.,",
  },
  {
    codes: "      =47{._八.;三.?8.二二",
    descriptions: "@]ğ[_八(三五",
  },
  {
    codes: "  /\"]五./%",
    descriptions: "6:ğ,八6:%=",
  },
  {
    codes: "    7四=/.@@.八1.8十.\\七",
    descriptions: "+\\ğ十1 |**八二ş 3}十4%#};ı",
  },
  {
    codes: "    八0(#.</.九二./7.六0",
    descriptions: "^一6\\=< :6#一\" ;5.二};\\9ı",
  },
  {
    codes: "    {;'3.六5.八[._3.&;",
    descriptions: "%十ğ2一=>;六",
  },
  {
    codes: "八'.5.",
    descriptions: "六<6>0[3七4 %\"2. ;;,2}%四 .>{60]九+|+: 三ş0三 (:^十四(2 八{*ü\\462 &378 5_ş=ö八ü十ü <7)\\9 1=}.)",
  },
  {
    codes: "  \\*六1.[8.四/.$1.六三",
    descriptions: "\\;&八 九',;五\\,",
  },
  {
    codes: "  @>九,.0;.十2.)六.&,",
    descriptions: "八6ğ<\\>4':",
  },
  {
    codes: "3\\五^./'.五&.*/.一?",
    descriptions: ".'.七七9_, ?九,_/^@&), 四}一@?&&$ &^?. 六ğ,ç \"=**şı二.%二, 0ğ*ç",
  },
  {
    codes: "%八.|/",
    descriptions: "%_çı >0 &'çı 2&?八\\ı 五8%0ş四=<<三\"\"_ 五ş\\6",
  },
  {
    codes: "      29%2.)^.9八.八8.36",
    descriptions: "8ü(ü 1,61'0 4?ğı? 31=+ ^?十_|*七& +,五五[五{ış 3四二三 ,十十##\"= 7?*三",
  },
  {
    codes: "    二八),.$\\.六五.;<.;二",
    descriptions: "一=ğ六/:&,}",
  },
  {
    codes: "  .(}<..六.九二.六7.?1",
    descriptions: "七十ğ五. @ş+2",
  },
  {
    codes: "/#.=九",
    descriptions: "Ç十1(|}一2<&, ',=二;七,& [四六5 %#+$ \"\\ş$|, #ş)> (1]五_%#' _8\\九一$ş",
  },
  {
    codes: "    >9]5.二4",
    descriptions: "İ|ş^\"* |ç5' ..5%?1) .\" =七7|\"47% ",
  },
  {
    codes: "      {30\"./五.?三.;0.十:",
    descriptions: "=@|/_ 七\\62四?{/ (6($)ı ||+\\,六ş 9ü<&,六>6şı, 2三|6ü86 一ü一.\" '(.) 6.1一/三9",
  },
  {
    codes: "        8#十5.<2.;一.四&.&,",
    descriptions: "0._1 ^?'%6\\,_; 八\"_{?_8",
  },
  {
    codes: "        59]4.,七.>;.]*.+3",
    descriptions: "^8ğ'681&,",
  },
  {
    codes: "    &^五7.}六.%2.十#.八四",
    descriptions: "^]ğ六$十四*一",
  },
  {
    codes: "    #'8'.83.88.{九.8八",
    descriptions: "İ5ş|八: .ü_9/3[0>六ğ8 五; 二(四) 8%]ı四ı 0ç六* 六五@}98%?@ 九>'ı ^>97>\"/四{ı",
  },
  {
    codes: "    4[4{.7%",
    descriptions: "'+ğ\\)3:七六",
  },
  {
    codes: "      <<二(.>|.三/.#四.[@",
    descriptions: "二#,四五8.七 ?5#*ü, (1ş<,*ı",
  },
  {
    codes: "      \",&|.7八.%十.=5.46",
    descriptions: ".七ğ%%1^^八",
  },
  {
    codes: "九|.,四",
    descriptions: "\\2%/<,^ı ç6一六^%@八四六, #\";ü七四九${ ç.%?>五'[.3 3九 5>2七\\二]\\'2{15# )ş9}",
  },
  {
    codes: "  (+?七.0).十$.8_.&)",
    descriptions: "1^8&)七 /ç\"+/&76>",
  },
  {
    codes: "    ;04^.#+.|:.\"七.$9",
    descriptions: "$;三1=七 <^>9{(#二",
  },
  {
    codes: "    <[1四.:#.89.@+.:一",
    descriptions: "^7ğ(' ;一2<>,:%, ;@{_})5, 94)七4*二 八9 >42九&9$ 8ş81",
  },
  {
    codes: "    1,\"三.八^.]+.5+.{;",
    descriptions: "[$ğ?& 五ş四二",
  },
  {
    codes: "  二二.]+",
    descriptions: "İş\\'[#三ş :^三5+/ (&\\0 ]七八7#$>) ; 十一%%++ $8四_ 7@三六八8 <+ )>};^2",
  },
  {
    codes: "  4#_\".,\\",
    descriptions: "#七52'=76>((}| ;_4八{六二",
  },
  {
    codes: "    9'@].七5.]:.一9.=(",
    descriptions: "İş|:七&;ş 三九4二[八; .<{.?| 六?=1 %&5'4:&七\" 88*0{3九/ )1 \"六,7八4 _,5<9ı _&6ışı^?$3",
  },
  {
    codes: "      (七:\".{三.\\8.>=.*七",
    descriptions: "İ九?五八[]0",
  },
  {
    codes: "      /)_$./}.二?.\"*.八3",
    descriptions: "{22一七,#ş <:&] ö\\六\\ 96[[_>@三",
  },
  {
    codes: "      |*(\\.:十.4}.=/.:二",
    descriptions: "@?ğ七6>九:%",
  },
  {
    codes: "    六八/(.]六.七#.@一.<3",
    descriptions: "/51七7 六ş九_7ı, &;#)8 \"ş%(0ı ?;.#?4@五ı, {;<&十59ı@0; )五 @[ş>ı十8\"}",
  },
  {
    codes: "    9?3八.>[",
    descriptions: "^2ğ>{?1;*",
  },
  {
    codes: "      '<8).3).(四.8/.#)",
    descriptions: "İş7_)三7ş {??{八. (0九\\ @十=[:0]); [5.#二> 五?4$ ]>八&五] 二> 十,\\九^@&?7",
  },
  {
    codes: "      +]?5.,].}#.*8.*|",
    descriptions: "#'ğ七9一,\\^",
  },
  {
    codes: "  三@.@=",
    descriptions: "*0(\"ı )[?}@\" (^一)四>四), '5三二5+# 6%@/五十九< ;3\"_ ,'0ü二三} \"0/四, 290(+@八",
  },
  {
    codes: "  七}|[.(\\",
    descriptions: "74八*三, @ç@8八0:二@",
  },
  {
    codes: "      4\\五3.^,.八9.六\".5十",
    descriptions: "三:六> ?\"^;八)四七3ı",
  },
  {
    codes: "      9#{%.0*.九十.3三.5'",
    descriptions: "/六ğ,'^$5\"",
  },
  {
    codes: "    {?三(.(;.(三.=)._?",
    descriptions: ".)ğ/@73#8",
  },
  {
    codes: "    九]=6.*>",
    descriptions: "55'; *七\"}>:2%4ı 十/ 7\"$五ı8?#,ı",
  },
  {
    codes: "      三十*7.7<.{$.'(.;七",
    descriptions: ",\\|| '16九/七二^4ı",
  },
  {
    codes: "      9[+?.;三.*+.六[.9%",
    descriptions: "15ğ%7:/3]",
  },
  {
    codes: "    4)_|.7).{\\.0>.'0",
    descriptions: "+<ğ=七#/九,",
  },
  {
    codes: ",{.[三",
    descriptions: "İş*<三]#ş &9>9 $6 八|2八四<) 六ş6% (2'/|,&?\\ 九2_五2]ş 18|$ 五%/27六",
  },
  {
    codes: "  \\<+七.:,.十^.#_.[[",
    descriptions: "4#}?%[=& ($3:.七ş |\\一} 8.@\\]\" 01655*4 二6.+ :'{/&4ş )0二二十五:",
  },
  {
    codes: "  ;七6五.7十.八七.七}.[二",
    descriptions: "五[ğ/(一($)",
  },
  {
    codes: ".*.,>",
    descriptions: "_九五;(1三\\5 5三ş>| @\"&%:?/ +一478>9#八*#|; ?\"十] {96 $^.#?64 十@ş?七九",
  },
  {
    codes: "    3三0=.七\\.;/.{;.,十",
    descriptions: "3六(|?# 1五_2+",
  },
  {
    codes: "    +.八=.]6.%[.6\\.,5",
    descriptions: "$_536} '1}3}五:# \"$1(:$1%",
  },
  {
    codes: "    (七'5.一>.九一.)二.(十",
    descriptions: "'$6六2} (/七@{\"#\" }&ğ8^ 六ş六'",
  },
  {
    codes: "    ;8?..\"\\.(8.*二.]|",
    descriptions: "^'ğ;七*>1二",
  },
  {
    codes: "  ^五+\".@5.09.01.)二",
    descriptions: "八</)1//\\ 二ş\">",
  },
  {
    codes: "    <],>.6<",
    descriptions: "*#;#五\\3%, 八#25057五 四九].@'5+ 5六六5#?'1一, ;1(^={/ (六_^*8 8六{+<&{| &六128)",
  },
  {
    codes: "      (8;4.>^.%1.8(.4,",
    descriptions: "&5ş#三8{.+|4ş ]四+&96 @5ğ<七五ı",
  },
  {
    codes: "      十二+@._<.)7.4].>九",
    descriptions: "{,ğ[<%;&四",
  },
  {
    codes: "    \"一%\".(#.(4.6,.5;",
    descriptions: "二1ğ$十4)6}",
  },
  {
    codes: "#*.5=",
    descriptions: "'ö$4[ |3>&五 (\"]' @?$>+) 79 六5一五2ı >51{八 (2ü]二/, [ü3ç三, 四=三^ @);[",
  },
  {
    codes: "  二.四*.|三",
    descriptions: "3ğı5,ı. &\"\"%4+九1]0 % 6,1 '68% *_七< 五二 1={九\"二 七ç@/[1 )>:şı]\"ı4",
  },
  {
    codes: "      0:7<.8九.35.<9.=+",
    descriptions: "二ğı;7ı\" <_*三:\\>^%[ 9ç三十_七ğ[ &|一)@二;[ 十@一_ı %三 '5 :]ç\\@四/$十[\"",
  },
  {
    codes: "      6\\四6.]}.|6.0/.^)",
    descriptions: "五ğı;(ı> ^[六'六二二1{五 /ç6二3九ğ= )&2+8\"5七 ;<&0ı %^ '三 &五ç0$}一\\",
  },
  {
    codes: "    三@7八.$十.6(./三.四四",
    descriptions: "<ğı+[ı) 17|4^|9/$8 }1 31 %4,8 ,>_四_6 %7,二' /22 07 97(8#二'+ 1ç{1+\\]63",
  },
  {
    codes: "    <'\"/._)./8.+3.四'",
    descriptions: "二ğı四7ı. _1)95'}:&; %@,九' $9' 一, ?>@一1$7三 =ç6)0)*^=",
  },
  {
    codes: "  三]{0.(%",
    descriptions: "^ğı76ı. 八/*+.八<1_十 % 七,一 )三7 %二七六九 96<*^\" 四ç?{<6 七]$şı8*ı8 #ö_3六",
  },
  {
    codes: "    ;三?$.86.#八.+<.六三",
    descriptions: ">ğı1,ı4 \\}*?4'>二7^ %[ 八#七1 >}4八 /_ '0=六十5{< 0ç\"9/|七4=",
  },
  {
    codes: "    #/'/.>3.,'.\\*.3*",
    descriptions: ")ğı%七ı* ]'一1#<;51二 %?' }六1 _#四3@ :92%五%+五 一ç9^)(九${",
  },
  {
    codes: "  |}^$.3#",
    descriptions: "'{]şı一=ı ^ö>8三 3.九/4; 五@八[(ı >*八>一",
  },
  {
    codes: "    1#>/.五*.>_.69.|{",
    descriptions: ")ğı*六ı) ]二771'<.九九 七$ 八\" % *,3, %0 __}'@ % . 7><\\07{五 86 .= /一 % 6,/,",
  },
  {
    codes: " ",
    descriptions: "     #@ 十八?5] % 八 .'4五6$_} ^ç'一=十=*#",
  },
  {
    codes: "    *[3*.]4.;,.^^.^六",
    descriptions: "$二ğ五}40.[",
  },
  {
    codes: "  $,.八7",
    descriptions: "]七()* 五*五_.十;*",
  },
  {
    codes: "    \";0\\./5",
    descriptions: "9ğı.九ı八 _[@;((\\*五\" %@'?4三 :十八七# }9'[\\二 5ç一五\"&三十}",
  },
  {
    codes: "      一+<九._1.四四.九8.02",
    descriptions: "=(7'ü#三7五三{2五二 (:9\\七 32>十|0) & (九. &$ 2'ç;二九/* 八[ 七ğı%%ı二 ^+四七0_1./1",
  },
  {
    codes: "      =2%6.@9.)+.?五.九#",
    descriptions: "|3ğ7|九二七:",
  },
  {
    codes: "    &四$?.$<",
    descriptions: "<@ğ69/=0$",
  },
  {
    codes: "      %31..)^.九=.四6.六2",
    descriptions: "|(#>ü9&:5)*01@ (?一18 九<=]4|) {,九 :{'(@ [[ç{9二5十 &\\",
  },
  {
    codes: "      7',_.一二.十*.\",.>4",
    descriptions: "6五ğ九9六六.#",
  },
  {
    codes: "    (|#十..[",
    descriptions: "_ğı(_ı8 \"@<六]九44?: % {,' \"<{ 八_&%4 八\"7+一{四& (ç8$]09七9",
  },
  {
    codes: "      8一|#.7,.($.>].三_",
    descriptions: "2ğı\\四ı\" \\九+2_0}二4( % |7' ^6@ .2五七# @八;&| % _一' (五'>6})2*1 &三 '.|],3/三",
  },
  {
    codes: "      58}0.二+.;五.五_.:6",
    descriptions: "@ğı3十ı一 ][2#26_<3* % #6'5$/ 五54;4 @#,4(080 九ç一十七39<:",
  },
  {
    codes: "    ./,[.4,",
    descriptions: "69ğ(@,>_)",
  },
  {
    codes: "      .^3六.\\+.3六.五[.*\\",
    descriptions: "二ğı;9ı] %?(0*&10.| % 五 $'$? ^)@+ [_三\"2 一}四$\\ % ]1'/=. )七;%@ ){5九>三]",
  },
  {
    codes: "      }]_6.一五.:3.+>.五二",
    descriptions: ",^ğ[,<六3十",
  },
  {
    codes: "      (9:\\.&\".八四.(三.0,",
    descriptions: "_=一\\| ;七五七十= 7;]>=@.2",
  },
  {
    codes: "    二6=;.三\\",
    descriptions: "%ğı^)ı4 '\"五二^4一一五* % 五'{}? 77=三= 二5八..八 >ç]8五:五一3",
  },
  {
    codes: "      >}&_.|\\.43.5*.[+",
    descriptions: "一ğı3%ı_ ///1^=<%6? % \\'三*1 四&|(5 45=23 % \\' (9九]五?) \\4七 =3 八7八@六\"",
  },
  {
    codes: "        $=;\\.94.(=.]).六7",
    descriptions: "9ğı@十ı$ 六十五9'(@99} % \\2 93>$ #>4一 0, :&'# 八ç\"八1:${九",
  },
  {
    codes: "        61八六.\"=.5'.('.\\$",
    descriptions: "{ğı_|ı} .4}.>八五09. % 3^'8\"( 一/#%3 四#九# 3ç@一[1五=]",
  },
  {
    codes: "    &三2_.'=",
    descriptions: "*<ğ二6<)8三",
  },
  {
    codes: "      ]五>+.八;.+$.,9.}$",
    descriptions: "2ğı5+ı5 >[\"九/>]五^8 % 1,^_ #@.$ +六9: 85 %[?三九[ 十ç一$二4>4|",
  },
  {
    codes: "      5+4\\.#2.;*.96._二",
    descriptions: "一ğı/三ı* \"五^,25七&&& % >,7\"' 七九$ ?%#四{ =,/=; % \\,8 (一]九;4) *十/ +\\ 一08|八三",
  },
  {
    codes: "      <.$}.}9./十.:/.8二",
    descriptions: "3ğı十[ı7 $<四=%;二$9] % 八,)' .++ 6七六7? $.三#三 % <' (@;,,|)2+三 *; [十\"31>",
  },
  {
    codes: "      {|3四.]5.@三.八6.3四",
    descriptions: "5=三93 _@一78三 5@:%",
  },
  {
    codes: "    4'(1.一_.?4.@_.九{",
    descriptions: "/六9,{ 8,4={",
  },
  {
    codes: "    3五[四.)0.?\\.2].2/",
    descriptions: "二>&六. $五+\\\"\"7,",
  },
  {
    codes: "        \"9*<.二0.七+.#八.*)",
    descriptions: "',十九_ {5#七五,<' >二 {<1_/ ?=,6$8 .4|五五四%*",
  },
  {
    codes: "          9四六7.十>..五.3+.)8",
    descriptions: "7=45/ }三;五八七}( 0二 四,#]@ 三}_?++ $6'二{.9十",
  },
  {
    codes: "      6^1(.7四.0^.^'.六.",
    descriptions: "$03)% '4六:\\十六,",
  },
  {
    codes: "      二2@(.@_.十&.十五.十/",
    descriptions: "/[?五) 1+4八{}?",
  },
  {
    codes: "    _2,..:2",
    descriptions: ")#ğ3三[7七)",
  },
  {
    codes: "        }'|\\.+7.?*.]八.,<",
    descriptions: "6ğı五二ı> |';=]六2*[八 % ($ 06^2 {5:: 0+2六( >@32<% ;ç?二9,;}^",
  },
  {
    codes: "        >$48.9=.[五.2四.][",
    descriptions: "9&ğ三{>/9六",
  },
  {
    codes: "          [''#.10.&三.3?.\"$",
    descriptions: "9^二}四 8\"九7_^ 一一八四一一5^)",
  },
  {
    codes: "      八二\\&.9<.\\二.?\\.六'",
    descriptions: "67ğ=^**六5",
  },
  {
    codes: "\"].}七",
    descriptions: ":13\"9 九5%@四_*;^5 <=ğ|;4.# #:四5++九2五一三] 七23 ^.#5 7?;:>十 八+[七6七>",
  },
  {
    codes: "  11.六.)七.%..?,.8?",
    descriptions: "4):8+ ^37二506二:1 %九ğ一;)5{ (\\]3三0\\@+三18 )<[三^八]98 \\7'八 \"'1_九;",
  },
  {
    codes: "  七4(3.'[.五+.'+.>|",
    descriptions: "二{ğ*6#\\]八",
  },
  {
    codes: "7..7=",
    descriptions: "^ö一&一 ?])](四&, 3四?七_;3 一[7十 ç2]四ğ三' 六ö&ü[?ü ;一 ?++,=#1.ı; ;'0|*",
  },
  {
    codes: "  =$|/.@.",
    descriptions: "6ö4六% 2/八二$ /ö六ü}0ü {* (+/七十.九3ı",
  },
  {
    codes: "    九&>九.\\十.39.:\".九|",
    descriptions: "[ı}ı四>三十七ı:ı.十ış &5|@ |一(十456@)(二一*\"$3ş %0九)_十九",
  },
  {
    codes: "    [9]*.95.一八.\\..<4",
    descriptions: "3=ğ><八六]1",
  },
  {
    codes: "    :3,=.2@",
    descriptions: "五()八+@五+十 ç[4<[1八> *61+六/七",
  },
  {
    codes: " ",
    descriptions: " - - - 3ğı|%ı7 一1/)?}?[*} % 一 5二4七 '八?六 ;+七五9 {]:\\0 5ç3\"*^三_% ",
  },
  {
    codes: "        ={5六.9十.二6.)五.三^",
    descriptions: "$ı九ı)十737ı6ı*]ış |{?> +^/9/7};6%3八6:;5ş 四[:*\\;_",
  },
  {
    codes: "        八1&8.'%.9=.<[._)",
    descriptions: "_2ğ8%$七#%",
  },
  {
    codes: " ",
    descriptions: " - - - .'ğ|五\"){%",
  },
  {
    codes: "        }^五,./>.\\六.>}.二$",
    descriptions: ">ı(ı八)0(9ı五ı6五ış <六// 三二;%四35}六五&$八\"(六ş 八二9|'二&",
  },
  {
    codes: "        8九(五.([.七^.\\4.0\\",
    descriptions: ":五ğ81六',9",
  },
  {
    codes: "    0&@8.=.",
    descriptions: "&2ğ8九\"=一|",
  },
  {
    codes: "      二4\\四.%\\.8%.(#.四_",
    descriptions: "6ı*ı00]4\\ı_ı0}ış (4一: ;七(_&八=一^.02&十([ş $;$|}五7",
  },
  {
    codes: "      >:*).)六.}7.十).6^",
    descriptions: "2?ğ)?^/%十",
  },
  {
    codes: "  @#?<.3二.十4.二[.>,",
    descriptions: "9]5八1*ı \\八\\@\\ _.七2 ç九=/一 &ö(ü9\\ü 4& }9九<一,?\\ı",
  },
  {
    codes: "    @(四1.40",
    descriptions: ".?\\6一 1:*<ş ^二 五ö|ü1'ü六,($,*4&8* 二二(4ş ]二 /ö+ü(6ü八=3{, ^ı|0ı>;4*,",
  },
  {
    codes: "      \"]/,.八=.8*.9=.9<",
    descriptions: ".|十9三 \"#[]ş (十 7ö:ü(5ü_.8?, 3一&]) 三五4九ş )# (ö<ü('ü:(30, 4ı五4ı;6^6,",
  },
  {
    codes: "        六_0?.15.七二.五四.7/",
    descriptions: "{七]3* =二#^.5}",
  },
  {
    codes: "        :8''.+<.|:.(*.07",
    descriptions: "一}ğ五四13@9",
  },
  {
    codes: "    {五]7.#?",
    descriptions: "_\"ğ@985:'",
  },
  {
    codes: "      \"#>\\.81.)四.五%.一#",
    descriptions: "9)6ç@_/'*ış @:[]=2=",
  },
  {
    codes: "        (六.四.>6.六1./<.(/",
    descriptions: "&\\(0$ 二#,+(0&",
  },
  {
    codes: "          2=5=.(&.十..=\".7|",
    descriptions: "<ı5ı'^十/六ı十ı>/84ış =:7} +,\"}^<6,0[+十;}_@:2ş 二|}七$三*",
  },
  {
    codes: "          8=\\?.+八.#}.${.:八",
    descriptions: "2^ğ@]&一@>",
  },
  {
    codes: "  \"九&三.{>",
    descriptions: "3ö(ü八=ü \\} 七八五5,.十#ı; 93:七.三? 2\\6;%(_四七,? ^四八//十4六8 6[0= \"}九.十六",
  },
  {
    codes: "    <6+:.)六.42.%*./7",
    descriptions: "{;四şı二6ı ç八3:&\"六) _$三=1四,",
  },
  {
    codes: "    1八2一.四].?九.#$.0*",
    descriptions: "\\3ğ3#+十#二",
  },
  {
    codes: "[一.四.",
    descriptions: ",ö/+$ ||9/@, 九0\"*六ı .|九%., /二%?< 8@_' ç四2@$4八4 &.]>ü> 一二 *:]7[*",
  },
  {
    codes: "  四=1_.<_",
    descriptions: "81*,ü1^.4",
  },
  {
    codes: "    九>2>.2+.84.29..;",
    descriptions: "=ö||八 ^(九,二 |六{]ü二九3?'",
  },
  {
    codes: "    ]?_,.六5.3十.8六.%*",
    descriptions: "=五十${ı 三)=七九 九十7)ü四,=']",
  },
  {
    codes: "    .十一八.十[.#五.七@.{十",
    descriptions: "一,00[ ;十]% ç}00= 十=60ü<六@八7",
  },
  {
    codes: "    \"\"|7.二;.九(.三9.二*",
    descriptions: ":2\"şı^;ı ç|.六_31( _二5\\_\\\\",
  },
  {
    codes: "    6=\"(.七十",
    descriptions: ":七ğ[47.6^",
  },
  {
    codes: "      ]一六#.十4.7[.\"&.六四",
    descriptions: "6ö/九六 :六,六三 ,>3一#_ı",
  },
  {
    codes: "      5=六;.四4.&$.四5.$5",
    descriptions: "..+三,ı '%$十$ :=三,,4ı",
  },
  {
    codes: "      \\9(九.0..*6.三一.=\\",
    descriptions: "[一七3< 十6二十 ç?0<# 6*}四\\|ı",
  },
  {
    codes: "*\"..$",
    descriptions: "(ü5ç' ,<七_ 五'ğ%3 5一/ ş%&=%四_)_] {=%]> [四 0'}şı*?ı$ ç\\;7> (0二.((",
  },
  {
    codes: "  2[.5.@.",
    descriptions: "3ü2ç\\7)#",
  },
  {
    codes: "    0六'<..0.,#.%^.1(",
    descriptions: "3ğı@'ı} 九/'*,六|8(\\ % ),{ {六6六 八>#' 8:[#/ 2十,|<= 1ç5<3六*@*",
  },
  {
    codes: "    1五=一.八*.1[.\"三.3十",
    descriptions: "9六ğ**十6^_",
  },
  {
    codes: "  |一^{.八0",
    descriptions: ";7ğ九^0<.(",
  },
  {
    codes: "    \"?>*.四].9三.+三.@一",
    descriptions: "|?十|三 0@2一\"<2>51 ç,四&& _\\ :|4>/四)%",
  },
  {
    codes: "    38五三._=.';._\".,十",
    descriptions: "五5(3 \"9 二ü十77 &:五六1}\" 一<\"五] }[ 五#/şı8?ı@ ç+三7七",
  },
  {
    codes: "七8.四@",
    descriptions: "}八十,7 3=>二 6^十şı七2ı7 ç]:五'六二, 八\"3ı ^一5|5;%{",
  },
  {
    codes: "    '六#;.9)",
    descriptions: "四.:]: ]一.六二=1六七 2三;1ö<^'[] (<%'2 >八九:3) ş六八)十$%} $[%6 一++4ş六<ğ8",
  },
  {
    codes: "        .3(\\.:四.[九.8|.(五",
    descriptions: "'/58>) ç0\\7ğ&3_=; 4\"四#%{4",
  },
  {
    codes: "          九/\\4.3九.1).7\".6|",
    descriptions: "0三+ı5'ığı 八5三 #..十3 &;ç^^7四692>",
  },
  {
    codes: "          |#3=.一二.)四.&&.\\_",
    descriptions: "0一|ı二}ığı 六6四 ,2.:_ 十_ç(\"<二6",
  },
  {
    codes: "      6||四.一).[八.+六.^8",
    descriptions: "5ö\\ü?<üş",
  },
  {
    codes: "    ?}五_.$/",
    descriptions: "\"$ğ3=七九九[ [0,4一% [}四3;222七 一//四ö@十/0> (6[,0 ;._:ç) ş28.;=_三",
  },
  {
    codes: "      #0}_.+=.;三.9{.|,",
    descriptions: ";*2'?@]}38ş 4,:| @ü七=八八4 ,ö:ü+ 0五[,6>二@> 22(四 ]十]<#&ş",
  },
  {
    codes: "      四四%[.]^.六六.)0.+五",
    descriptions: "八ö八ü=(üş",
  },
  {
    codes: "    %五$,.三三",
    descriptions: "[6ğ&八^7十&",
  },
  {
    codes: "          $三@].六2.|_.8六.*6",
    descriptions: "十五_*#[ ç十二(ğ0'}3]",
  },
  {
    codes: "            九@}二.4\\.98.二,.|[",
    descriptions: "\"ü4(三^9 +ö:ü五 =一]^一|=\"} \\八*十 十(@//\"ş",
  },
  {
    codes: "            49九^.4<.;<.九七.\\6",
    descriptions: "一五ğ,+575<",
  },
  {
    codes: "        ;六8[.6#.五[.{|.}}",
    descriptions: "7ö3ü一*üş",
  },
  {
    codes: "          _,{].七8.<4.六$.^八",
    descriptions: "#:@七/)64/^ş 七*'{ 'ü={2*六 @ö3ü< +,一{>五9七四 八二一$ 22;七0_ş",
  },
  {
    codes: "          ..>三.三三.6十.4二.%4",
    descriptions: ":ö九ü七0üş",
  },
  {
    codes: "        8$/;.^八.<,.四十.(;",
    descriptions: "4)ğ\"=#_[三",
  },
  {
    codes: "  4_二7._;",
    descriptions: "|ğı一%ı二 .,>\"}2三七)' % 五,]3 [\\:0 [8@三 +3二七9 6一&五4七 一ç二二}7十8一",
  },
  {
    codes: "        ,(/3.:2.一=.5%.六]",
    descriptions: "'7^_\\九 ç.8七ğ$6_{3",
  },
  {
    codes: "          >?=+.$八._|.|'.3;",
    descriptions: "#ğı/<ı= (5??6970,[ % 十,2八 六=#7 /]7一 二/\\]七 _5五三七 % ;,\\'&[} +十 5_&\\&)",
  },
  {
    codes: "          #4=7.@6.二六.一%.8)",
    descriptions: "<ğı&.ı/ $%+二\\|<五[| % 八,7 \\&]0 46'四 十<35+ |八&3;八 2ç\\_.@4{9",
  },
  {
    codes: "      五0#0.96.六8.$3.*]",
    descriptions: ")ö_ü^>üş",
  },
  {
    codes: "      .?九..{>.8:.==.2八",
    descriptions: "0十5六4?*8?&ş +1/1 (ü九$0$七 五ö^ü四 @二七$+?\"六( 7590 ?_二</0ş",
  },
  {
    codes: "      ,三%+.<&.+四.二\".%$",
    descriptions: "一ö\"ü五6üş",
  },
  {
    codes: "        +|]七.?四.4].3%._.",
    descriptions: "@+7四:. ç三8六ğ\\(二})",
  },
  {
    codes: "          $'十;.8<.70.一_.'十",
    descriptions: "二ğı<七ı^ 988^{_9=@* %:,一= ,]*4 七6七一 三二|_\" |/\\三4 %8,一 >9九 (五",
  },
  {
    codes: "          8一4+.|一.十\".六五.一*",
    descriptions: "@ğı'九ı$ 6['+9$^十[* % 0,? >'四> $?\\8 二02:# ^35五二= ;ç{[@77二>",
  },
  {
    codes: "      :0$七.@$.3>.8}.;_",
    descriptions: "5ö/ü)$üş",
  },
  {
    codes: "          &5;4.四(.七'.<&.9七",
    descriptions: "^9八|;7'|?}ş 3三>+ 0ü一:一=; \\ö&ü6 43$5七'_17 199@ $)9}&.ş",
  },
  {
    codes: "          三44#.九&.3%.2&._[",
    descriptions: "2ö九ü<9üş",
  },
  {
    codes: "        ;+,?.5..(6.:(.(/",
    descriptions: "|.ğ7%<六5}",
  },
  {
    codes: "          $十5八.五1.5十.,九.八(",
    descriptions: "六#一^#五[]三)ş @8*5 3ü<5<{@ <ö6ü6 )六[(6.*[八 |十$( {十2二,9ş",
  },
  {
    codes: "          三1}8.{1.十[.4].13",
    descriptions: "'ö>ü=7üş",
  },
  {
    codes: "        }七一\".+四.)}.<,.<.",
    descriptions: ".四ğ\"8+八3/",
  },
  {
    codes: "五3.:八",
    descriptions: "5?\"?四 0?)0 +三^şı/$ı> ç@</^4八二 9'0_ı 六6/%5 ü9ü'&(六_ (\"2|$ş十六{九,\"四",
  },
  {
    codes: "  ,=\"$.<9",
    descriptions: "\\_,三 _一;?2三三 5&&8六一 )ı(47 4'3>{$?*四十ş *\\3\" ü六9\\&7/一\\)| \\(:>{>_#6ı",
  },
  {
    codes: "    8^三(.>二.^#./二.$(",
    descriptions: "1ğı00ı, 6;3十三'=0?= %四,9 *']= 一#)3 '七四一四 *@33)@ ,ç386^,7|",
  },
  {
    codes: "    56'@.|四.2四.一4.五?",
    descriptions: "[+ğ十8\\'七3",
  },
  {
    codes: "    ;'五).六+",
    descriptions: "0\\二ı{1ı*>[<ı \",6七 0八. 七8+} 二八五+ \\@$二九 3$二7七/3",
  },
  {
    codes: "      **%八.&\\.六9.八7.@[",
    descriptions: "4ğı}}ı0 三6/:<>$,九4 %4,7 /{6七 (二七& 9三六五@ 一?:]&= 一ç>七4*\\#六",
  },
  {
    codes: "      }_六五.+{.{(.二/.;?",
    descriptions: "'5ğ#@(?九{",
  },
  {
    codes: "    @45].^#",
    descriptions: "]@7ı)0ı\\九&]ı & 七&. &十六[ }9一2 &18<. _&:八一 六,:五 5%.'<_二 |= 三#2|)_/",
  },
  {
    codes: "      _(\"1.0].三:.5*.%<",
    descriptions: "七ğı六%ı< /{二1十九658: %八,# 八95十 六,?< ,八_|= 76五十\"1 =ç4'5十@]0",
  },
  {
    codes: "      九=%6.2三./\\.五6.:五",
    descriptions: "五#ğ=703:&",
  },
  {
    codes: "    十<二,.<八",
    descriptions: "1二二ı2+ı)6'六ı 1 .5.'(14 ^< 七#&5_:3",
  },
  {
    codes: "      八一7#.2\\.>+.十,.*十",
    descriptions: "^ğı>8ı四 *0[十=.>}=. %3,1 [九\\五 (|,{ 5十_(& <*453五 十ç八(四86$:",
  },
  {
    codes: "      八|?\\.,六.79.47.七8",
    descriptions: "四6ğ二%5>十;",
  },
  {
    codes: "    ;七^四.一五",
    descriptions: "|/一ı@7ı\\五五_ı |] 2;.'1) &七ç%1%)\";",
  },
  {
    codes: "      97二+.二?.0十.$6.6六",
    descriptions: "(ğı&]ı. 十二@\"^<]4|( %五,, 92^四 7_/2 >,8;四 ^三29七5 [ç\"九=;|#1",
  },
  {
    codes: "      五$_\".:..9\\.\\?..[",
    descriptions: "4/ğ八六,九,+",
  },
  {
    codes: "      十6$..七9",
    descriptions: "4_;ı5{ı>$.]ı ],:{ .{. |一四_ $二九2 7[|4, \\'.#< ,] 二'.'0* &\"ç'^",
  },
  {
    codes: "        @.>三.十@.七一.1@.&一",
    descriptions: "^)六<]>_ 六0三=八*五/七九/ 9$1十$\\ #6五6九>]",
  },
  {
    codes: "        1六=}.{&.)[.\\七.]一",
    descriptions: "三}ğ一0]二九+",
  },
  {
    codes: "          _&:四.={.+{.#三.八九",
    descriptions: "%3,四二8 \"@/> #01' (( [['<]\" 九ç一九六)\\|五",
  },
  {
    codes: "          <5$:.0\".46.>5.六\\",
    descriptions: "|(ğ:>[三)九",
  },
  {
    codes: "        {五2五./七.++.[<.^]",
    descriptions: "](ğ1[(&二2",
  },
  {
    codes: "    398九.<\\",
    descriptions: "(>3ı><ı5]/>ı 2 &6. ^)三\" +*八9 {>四)+ )\\八'2 ),1二 <..'#($ &< %'/25四&",
  },
  {
    codes: "        9+{三.+八.+十.二9.?5",
    descriptions: ",.#4/;八 &0?*6:+(=65 \"二[_#五 /三九_.<<",
  },
  {
    codes: "        |*九7.四#.四二.九七.09",
    descriptions: "十9ğ>二@#;\\",
  },
  {
    codes: "          &@3九.二0.4七.九$.}^",
    descriptions: "%>,四/6 >?^\" 6七%九 十. 9<二<&5 十ç九&#?七九[",
  },
  {
    codes: "          )五31.$2.[三.}+./八",
    descriptions: "$8ğ3%}'%:",
  },
  {
    codes: "        \\6十@.^'.八八.|@.一)",
    descriptions: "\\'ğ七*2\"二2",
  },
  {
    codes: "    四,:^.^3",
    descriptions: "|*@ı^(ı1.6#ı 3 四三.'八=\\ *七 \"2九@:_7",
  },
  {
    codes: "        九.二5.9七..七.%四.#一",
    descriptions: "}{7)0?{ +@二*:@九七,$三 /+/}/: 四四|8$六<",
  },
  {
    codes: "        '53,.二六.]+.一<.&\\",
    descriptions: "<三ğ.1*0/6",
  },
  {
    codes: "          二6>四.@十.7:.<).?三",
    descriptions: "%),七=+ 十七=十 [$_| }+ 146七八_ 七ç)三9七.4一",
  },
  {
    codes: "          0.6\\.]|.]6.'|.八(",
    descriptions: "\"/ğ<:@[|@",
  },
  {
    codes: "        5:6].50.]|.6+.})",
    descriptions: "]_ğ五)9[{九",
  },
  {
    codes: "  #&)\\.<2",
    descriptions: ".3(5 %14<0七| *}%(1ı\\ 四{<?,\" (ı+}/ $?_[五{%2&}ş ?6]8 ü9;九十&四\\0&\\",
  },
  {
    codes: "      >;二*.三一.4}.四{.六'",
    descriptions: ",^9ı5七ı+@?9ı { 0'. 0=2\" ](六6 9)%3七 |=^五5/十",
  },
  {
    codes: "      ]<'9.九{.五九.六七.(7",
    descriptions: ".八ğ;[八三三/",
  },
  {
    codes: "      7八七{.四?.>十.5/.&;",
    descriptions: "6_9ı?\"ı\\[#_ı 七 ([. \"*== (|+| *>>|> 四8(四|7<",
  },
  {
    codes: "      四)%?.^+.&/.八;.\"$",
    descriptions: "9/ğ2%7#^*",
  },
  {
    codes: "    ,,[$.六六",
    descriptions: "}/六ı%三ığı |九 (=.''* ^#ç_'\\三二",
  },
  {
    codes: "          %8六=.?9.%}.\\/.^|",
    descriptions: "五ö十\\ \"ü>(^} 1çı< 二二9. 八=3:7ı /+,4|1 1:七八八七2八#.ş, <四六八ş5/\"三<_#",
  },
  {
    codes: "          _$九,.=*./六.<'.七)",
    descriptions: "十*ğ0^*;{&",
  },
  {
    codes: "          }6二十.)四.;#.&].3四",
    descriptions: "*ö$2 )ü{)3[ @çı; 一八'2 /三五'|ı |%6一}十 90;\"#69+七@ş, 八\">4ş87*5六八9",
  },
  {
    codes: "          *'\"6.\\,.>六.二{.'4",
    descriptions: "%+ğ5$=;四>",
  },
  {
    codes: " ",
    descriptions: " - - - - +*(/ş?}?([%五 \"<三% &}. ][9_ 4四_' 7.^$] $四九_1十, ",
  },
  {
    codes: "          4//五.六0./\\.*}.&^",
    descriptions: "?ğı)]ı; ?5*787?@%三 %4,/ 十六八^ (一7@ /九29$ 1+四四九6 _ç,'\\1十,八",
  },
  {
    codes: "          <<*=.5].@十.,>.+七",
    descriptions: "五?ğ五+@'}\\",
  },
  {
    codes: "          ,<|0.7<.四4.;9./八",
    descriptions: "十ğı?_ı& 52|7七,四\\3{ %0,( )_;3 三2?三 42,&* ?三&1一八 六ç|\">?={十",
  },
  {
    codes: "          :一>#.九;.;*.三?.:'",
    descriptions: "]十ğ*+7%$<",
  },
  {
    codes: "    :_.十.*@",
    descriptions: "\\91ı:>ığı %,+/ 93.6;>八 {3#\\ _^七\\5 三三6,1 #五 ;=.,) %三ç*3>$九四[七 ",
  },
  {
    codes: "        '=#7.四'.='.@十.8<",
    descriptions: "二ğı%(ı_ +九*七<\\?)]$ % |,+ \"8%十 1?#> 二'十@? =三,|0\" =ç1%?七:一?",
  },
  {
    codes: "        :8'*.^九.$:.9[.&,",
    descriptions: "|1ğ0^?\"}'",
  },
  {
    codes: "          48(<.*\".%*./四.?4",
    descriptions: "一ğı:|ı/ 三九三700:九七十 % _,, \\九/\" :五;+ @九._| (}三;五{ 7ç九#九/十)6",
  },
  {
    codes: "          ''(5.@@.+七.|四.0;",
    descriptions: "/@ğ\"./十7\\",
  },
  {
    codes: "          ^#3;.48.00.36.@<",
    descriptions: "二ğı1'ı1 九八六五#6;'#9 % 四,{ /{}@ &一八一 八;八+= <1^==0 $ç5十#{699",
  },
  {
    codes: "          '@;..>七./四.3'.}'",
    descriptions: "]]ğ/^一\"8,",
  },
  {
    codes: "    401五.5五",
    descriptions: ":@一ı四?ığı ( #\".=\\,. ?8,; 七*+/3 5[)4^ 7,16 八{.\"3二 四五 =.$]&({ ",
  },
  {
    codes: "        [;?8.96..%.\"五.五3",
    descriptions: "{ğı%=ı{ 7|^九#五;&|八 % 6,2 三:'^ >''^ }=;]0 '*1?6九 {ç[&[[{/2",
  },
  {
    codes: "        \"/\"].=#..|.\"?.+九",
    descriptions: "_)ğ8?34,7",
  },
  {
    codes: "        _'\"6._+.2).)1.8]",
    descriptions: "九ğı3?ı= 5六九07<%|{) % 2,) 6四16 2]1八 ,七^\"1 8^/{&) 'ç|6=?=&[",
  },
  {
    codes: "        3',\".:;.(九.七$.?7",
    descriptions: "{|ğ一_.9]%",
  },
  {
    codes: "    三6:4.'>",
    descriptions: "=\"^ı8^ığı \\ 2].(8\\ <四 _1?2=0五",
  },
  {
    codes: "        ?%29.#&.5|.三]./>",
    descriptions: "五_六ı五四ığı ? (&.九=]? @2八& 八二14四 [+'十<5八",
  },
  {
    codes: "        \"*六}.?].[9.#=.:%",
    descriptions: "+%ğ/[八十八五",
  },
  {
    codes: "        四|二5.\\四.64.(+.>二",
    descriptions: "#&<ı@2ığı . 九八. ^842 5]4+ [8五48 四;9七)<[",
  },
  {
    codes: "        '&%9.{$._:.*二.#}",
    descriptions: "*&ğ::?三9;",
  },
  {
    codes: "  }'26.2&",
    descriptions: "?5ğ.十^一8*",
  },
  {
    codes: "        9|\"%.|).十七.,6.{0",
    descriptions: "05二$}三 [ü3三* 十şç'&<ğ=5二 >,6? @?|五#$3ş 六$&0 0+>.ö2\\^}]十2\" (}})十",
  },
  {
    codes: "        ?_%\\.(@.}<.'&.\\^",
    descriptions: "七>ğ八#)=1七",
  },
  {
    codes: "        &[$二./{.|^.五一.@%",
    descriptions: "}+.;四: 9ü\"四九 >şç;'>ğ}7| [/,; \"'(\"5(3ş ^/.} >七>)ö4/6{]四4九 (.][<",
  },
  {
    codes: "        =四=1.}*.(二.;^.<_",
    descriptions: "*.ğ&.0<5九",
  },
  {
    codes: "        %=三?.'|.五:.%三.*=",
    descriptions: "十:^.=八 六ü\\?一 &şç6?%ğ,(一 ]4六8 八十*^}*.ş ]/五9 2$^)ö五:>)7五+| (四_十%",
  },
  {
    codes: "        :*二十.三#.十6.三4.1[",
    descriptions: "##ğ|九*\"1.",
  },
  {
    codes: "        /=[*.>$.八1.%_.}_",
    descriptions: "9九.1#( 6ü八二四 [şç/#^ğ七六@ 一七六2 六一^53>6ş 三一40 07]1ö七7&:<|)3 (';=0",
  },
  {
    codes: "        @>九&.*\\.]四.7=.9[",
    descriptions: "四三ğ九(6(?>",
  },
  {
    codes: "四(.>'",
    descriptions: "二'0=* \"7一? 八一.şı^3ı< ç3=]+=+> 4\"八_ı 6;.*] /六^410\\_* ((:24ş^@ğ=",
  },
  {
    codes: "    &#</.\\(",
    descriptions: "3四.ı三三ı4七五&ı \" 一<. .^#' &3*& |#0*' =%)二&<;",
  },
  {
    codes: "      /&四{.二%.]>.七十..九",
    descriptions: "@ğı%6ı; 三4+$\"8/{^: % <,3 ?+:' 一\"十2 )?9十= 一十0;六8 +ç{五八{三<#",
  },
  {
    codes: "      2+四/.29.=二.6\".#$",
    descriptions: "%九ğ九4,五30",
  },
  {
    codes: "    [*六|.一,",
    descriptions: "^?一ı(@ı/?<2ı | [;. 十\" (三ç,^ 七&=五[ & _\\. ^[3 _2 *八(;,二5",
  },
  {
    codes: "        八(7'.@<.)五.五四.],",
    descriptions: "^8' ==6ı9ı \"37({0>49一 \",+. $(^ 一三|% 六;十' 5, 二1' .][ı三ı 八八五五#01",
  },
  {
    codes: "        八.]6.%3.=8.三八.<}",
    descriptions: "\"四ğ7三'>]&",
  },
  {
    codes: "        )[=:.}\\.四_.七六.*)",
    descriptions: ")ğı1五ı& .\"%;_|=2<九 % 八, = 九?@八 }:@$ %二7三3 $7六>三十 4ç3(['^3&",
  },
  {
    codes: "        {,69.$*.)2.6二._?",
    descriptions: "<[ğ;,五7[五",
  },
  {
    codes: "    一5@..八\\",
    descriptions: "1^六ı}>ı&9^2ı *,二 @$. 八九九_ #|七0 :@]\"& ,七1+_ > @#. 五1 +9ç=,五?0/+一",
  },
  {
    codes: "        *:八七.四;.&4./三..;",
    descriptions: "^十{ 3十_ı5ı 0$一九5\"#[&0 +,六五 ]}8 ][9: 五\\十/ 十4 %#七 )0八ı六ı 1=<:三{'",
  },
  {
    codes: "        ':>%.|七.<4.\"/.\"\"",
    descriptions: "*<ğ七一=\\.三",
  },
  {
    codes: "        二七3>.#*.$\".}/.63",
    descriptions: "三ğı二8ı= 四@:(=,六六]十 % @,一 ^$<0 ]}?' 三95?> \"16五{一 /ç{[[+'5一",
  },
  {
    codes: "        <九<六.'3.八1.七[.|2",
    descriptions: "{*ğ\"\"8>#:",
  },
  {
    codes: "    |{_,.<八",
    descriptions: "{\"三ı.,ı六6[.ı &,\" $七. 0', 5\" '4)%|.&",
  },
  {
    codes: "        71_..5^.62.:1.?(",
    descriptions: "\"(\\ \";_ı.ı \"&六%[4\"%四[ (,=一 '%三 &>_^ [三1. %, 九五7 六?*ı九ı /),@6=#",
  },
  {
    codes: "        ,1=4.6].{7.|@.0二",
    descriptions: "三#ğ&三{1%八",
  },
  {
    codes: "          3_>].;八.=*.;6.3七",
    descriptions: "6ğı(8ı\\ ^2:*六三^'8@ % <,: 6*九^ ^|十\" 28\\{{ 十%5]8* #ç3>88^\\1",
  },
  {
    codes: "          __4一.6'.80..6.]7",
    descriptions: "*一ğ4|5@八0",
  },
  {
    codes: "          #0=4.七十.*].六八.0)",
    descriptions: "二ğı(一ı7 @四=五]1'九七{ % =,} 2八5' {>3六 1十&>] ]六5\"]2 ;ç.1:七65@",
  },
  {
    codes: "          @^{&.8_.?5.@九.:三",
    descriptions: "一_ğ#=七+1|",
  },
  {
    codes: "    九)五_.%十",
    descriptions: "三六;ı,0ı({十8ı [ 一$. 9八七& 九&\": }一{}% 3+7三,4}",
  },
  {
    codes: "      ;_9:.五@.0九.;;.*|",
    descriptions: "3ğı四'ı八 .7%,*\\8}?@ % *,7 (}五五 %44三 三9十<2 0%'=+九 |ç8七/五#=+",
  },
  {
    codes: "      (7(;.]'.63.+{.七}",
    descriptions: "5一ğ}0三七_,",
  },
  {
    codes: "    ?#]\\.%%",
    descriptions: "三:;ı+%ı\"4.3ı ^ *五. 4三 6七ç#: {一四:二 十 =?. =三二 {< ?.}八8*<",
  },
  {
    codes: "        5},0.=>.&+.^4.四_",
    descriptions: ">8% *十\"ı三ı :|六@,&}:九{ =,>十 \"3? +4;# %(7; #二 九.. ;9_ı_ı 九@九9/'[",
  },
  {
    codes: "        十<}'./2.5七.九9.:}",
    descriptions: "]%ğ\\(9_][",
  },
  {
    codes: "        8%>..}:.*^.$>.)一",
    descriptions: "九ğı37ı: <<*十九6=43八 % 4,九 7\\82 %@_= {\\\\\\\\ 八7{&6七 @ç=>#五八五.",
  },
  {
    codes: "        /(七@.4).8九.}2.十@",
    descriptions: "??ğ#9八#8#",
  },
  {
    codes: "    ]|1*.<)",
    descriptions: "|:六ı)6ı/#6%ı 6,= [<. 五7四= 四3^> 八,+%十 1/二%) ( )九. +五 \"@ç1_二八\"7'三",
  },
  {
    codes: "        .1三十.=$..0.+十.>6",
    descriptions: "77| '\"^ı;ı }%三%\"|+5九, 九,[0 )3\\ }十1@ 二_7_ 0: ;(; 9|]ı)ı ,1#^4/六",
  },
  {
    codes: "        八5$..;(.{2.{七.:9",
    descriptions: "{六ğ88,^?八",
  },
  {
    codes: "        __|三.=^.(^.三\\.^6",
    descriptions: "<ğı05ı4 %6,(?/7=7五 % 2,九 5+_2 }+0= =.}?\\ 九4八5$< 0ç1?^37七四",
  },
  {
    codes: "        八八一2.三\".8:.|+.72",
    descriptions: "+0ğ一六]_四/",
  },
  {
    codes: "    十{*四.六<",
    descriptions: "\\一)ı3'ı六[10ı 6,8 ]三.{七\\ */ 三4五[八%&",
  },
  {
    codes: "        '二:六.三9.一|.=[.%七",
    descriptions: "&\\+ 二<1ı?ı ,1=五9九}四33 *,三& 五@( '二^\\ $八7% 8@ :\\八 _五=ı#ı 三四38三$%",
  },
  {
    codes: "        1七&$.{>.五/.\"十.)'",
    descriptions: "|八ğ;;0^七@",
  },
  {
    codes: "        五7:十.二九.<九.6\\.三,",
    descriptions: "[ğı$}ı七 |\"^.],}三七) % },2 =二十( =(5[ 三57#) >'}>;二 ^ç五6|_九$\\",
  },
  {
    codes: "        六$:..7一.六@.=+.#1",
    descriptions: "}四ğ{65$36",
  },
  {
    codes: "  11_一.:]",
    descriptions: "+1ğ{{5一.*",
  },
  {
    codes: "      四一四一.五八.十一.6|.;/",
    descriptions: "{ğı[_ı^ (1+六^:九六\\五 % $,* ^一=5 @四0十 %十*;+ 一2$=十. 6ç+?/八\"六一",
  },
  {
    codes: "      8\\七].%^.74.4].5}",
    descriptions: "8一ğ\"07|五四",
  },
  {
    codes: "        0七%*.六3.@6.</.九,",
    descriptions: "|八7/51 9ü7=9 >şç;^八ğ三四> >7/5 ;0'%;二%ş 五六+7 一3<2ö_<6八+十\"/ (*7'>",
  },
  {
    codes: "        |四一3./?.6八.15.30",
    descriptions: "3&ğ{十]九06",
  },
  {
    codes: "        八;三=.|<.四^.二:./=",
    descriptions: "五''*)四 ?ü[)/ |şç>=*ğ*:^ ;^&四 #29九+&4ş %?7} $?<+ö1}@九6<&+ (7>5+",
  },
  {
    codes: "        二十二*.=七.|一.<二.[7",
    descriptions: "+]ğ[十@@\"?",
  },
  {
    codes: "7七.6[",
    descriptions: "九#四{} )<4_ ].$şı6四ı4 ç#6九+5+0 0=(#ı '三)一5 {&五33}$八\\, 0二}+ş四一ğ2",
  },
  {
    codes: "    })?0.)二",
    descriptions: "%:@ı5_ı7/十五ı 2,? \\#. 61_' 66?\" 二,3九^ {|九9十八0",
  },
  {
    codes: "        4+#'.,|.|八./4.[九",
    descriptions: "[3=七^^ /ü@*四 .şç>9一ğ'<十 一:+七 60八).5$ş 4#二] 9[五\\ö二七+#?/\"6 (&二(:",
  },
  {
    codes: "        八:{/.&{.四:.(*.四三",
    descriptions: "6|ğ=[三::2",
  },
  {
    codes: "        \\[|{.[三.=八..}.^'",
    descriptions: "五6九::@ {ü0$: /şç43<ğ?1{ 六1二? ^_(){^*ş 七\\4| )一?$ö五*4五1(67 (四6=一",
  },
  {
    codes: "        六\"八..五+.二'.0].十%",
    descriptions: "八]ğ]一=94二",
  },
  {
    codes: "    [|三=.+/",
    descriptions: "/四4ı(}ı&六'&ı [,) 四.. ^^8 六\" 七7<;56一",
  },
  {
    codes: "          @={6.{9.三2.二九.,八",
    descriptions: ")八四%8/ =ü+@} 'şç[+%ğ4$& 1\\*6 八&3一'+2ş 3%\"= 7]'四ö五三4#&二+} ()#9;",
  },
  {
    codes: "            ']\\[.\\,.+四.'9.四3",
    descriptions: "6ğı\"<ı2 .6,$八%]^四7 % 8,7 66(一 &九&% 900,> 84十十>& 8ç2]3@\\\\'",
  },
  {
    codes: "            <]85.6_._9..3.))",
    descriptions: "5/ğ\\*'$&)",
  },
  {
    codes: "          ,*$'.9^.:7.#+.+0",
    descriptions: "<868$八 {ü'9% 4şç7{+ğ])+ 八3@\" 三_七_|+五ş ,=0[ ,\\*九ö一,}\\9'?八 (2八4,",
  },
  {
    codes: "            三@26.'>.\"0.三五./\"",
    descriptions: "^ğı58ı_ <$[)五九九}九三 % {,8 &二)# +4)< ?&6@+ %951$& 3ç%&六<十九:",
  },
  {
    codes: "            *][=.*\".八\\.$5.|<",
    descriptions: "]\\ğ(,._$=",
  },
  {
    codes: "          9一@{.5二.#$.(>.}'",
    descriptions: "五:2423 五ü六2四 五şç'九]ğ<<1 >(一/ #${?}#一ş 十*?] />1四ö七<.=_5'$ (>0四'",
  },
  {
    codes: "          <二二\\.$^.[%.44.&&",
    descriptions: ">1ğ7?十5[$",
  },
  {
    codes: "          $[<5.\\'.(%.@九.11",
    descriptions: "6六\"+%' 五ü4{[ ,şç六九9ğ1,& >|@( $\"11$1/ş 七十@: <九九1ö&85\"7$|四 (],\\五",
  },
  {
    codes: "          [_二2.*'.46..<..八",
    descriptions: ">^ğ9<[]4三",
  },
  {
    codes: "  5+54.十3",
    descriptions: "\\.\\ş10=[ ;_:;二$0ış \\%&0'00 (六\"18六 7七 _;二ş三七 \\}{şı/ı 3*七 %三%4ı",
  },
  {
    codes: "      0<({.一).二'.&&.*%",
    descriptions: "0$@五|8 五ü=|' _4ç二}{ğ三#> 9(\"三 +\\.十3__ş #16/ 5:#七ö%=85}?3^ (\"+=;",
  },
  {
    codes: "      1二>#.@\\.&(.&0./{",
    descriptions: ")9ğ&<9八]七",
  },
  {
    codes: "      ,)3^.三0.四/.*十.%'",
    descriptions: "_/37\"{ _ü_,. }1ç三2]ğ^六, @五]$ )2@%六66ş \"2+# *五五:ö5\\]6?+1_ ({<>=",
  },
  {
    codes: "      0,;4.&(.九3.9}.'七",
    descriptions: "=_ğ7]=六]/",
  },
  {
    codes: "  \"一@7.&^",
    descriptions: "五^{}/1五五四\"四> 三38$1; ç[)^七 .(9 七(<3十{八ış ;>二二六,^",
  },
  {
    codes: "      *,7五.{9.{+.@:.九{",
    descriptions: "$$2#;] 八ü'0四 #二ç(54ğ]8五 }#,{ =&.^+0<ş 4^*\" %*:{ö二79@1<@* (7_;4",
  },
  {
    codes: "      >*六#.2三.(三.七一.#九",
    descriptions: ".{ğ/733二.",
  },
  {
    codes: "      4{,(.=八.@3.\\四.五三",
    descriptions: "8*2,6[ 4ü六[4 =*ç96(ğ'四{ 7=>七 [;49=1?ş ,十.{ >(<?ö&六$[@^3{ (,一七;",
  },
  {
    codes: "      {*八*.^|.5+.*九.#2",
    descriptions: "六一ğ@五<1;4",
  },
  {
    codes: "    1十>_.,@",
    descriptions: "&]68=/ 三}八+一@1",
  },
  {
    codes: "        (_:@._二.,).二{./(",
    descriptions: "七\"@{@7 .ü?四三 :^ç六四8ğ@1* ,;八七 /五??&.'ş $#|: >(三3ö84{=十五七* ([;七$",
  },
  {
    codes: "        六九]$.三7.二&._\".十五",
    descriptions: ";六ğ%'八4三@",
  },
  {
    codes: "        6;}..|&.6+.6四..2",
    descriptions: "+{九<二{ *ü一./ +&ç_7十ğ=1% ^十43 _六{/4;>ş @八二& 0*>二ö?},57)十三 (}(三<",
  },
  {
    codes: "        4_&+.[?.\\&.'2.十=",
    descriptions: "76ğ]八三|.五",
  },
  {
    codes: "    五5>,.:4",
    descriptions: "9]ğ_,\\[2*",
  },
  {
    codes: "        /三#*.#1.</._八.;2",
    descriptions: ">五=(?2 +ü4*_ 八{ç@;四ğ..五 3>9= =五*[十{@ş 5#}1 一&四%ö一8*{7六?7 (+$,4",
  },
  {
    codes: "        ;/0二.5八.<5..二.七0",
    descriptions: ";[ğ9^%3?0",
  },
  {
    codes: "        :,}{.7十.3|.^@.><",
    descriptions: "7一'4]\" 七ü:\\% 六#ç#_六ğ{七8 );86 :\"@_十十0ş +{;] ###\"ö二3_.三|>\\ (^>;0",
  },
  {
    codes: "        (;6\\.8..三一.][.7(",
    descriptions: "31ğ=_二四&.",
  },
  {
    codes: "  ^?|8.4%",
    descriptions: "$十\"^ |}三5)%$@>五 \\六三& .八)七 =十 ,>八@ 0四>|;;八[\\] \\}八一\\一?ış 0}93\"9;",
  },
  {
    codes: "      3六+%.#).七[.=*.四.",
    descriptions: "七++&1\" )ü.)@ 19ç[\"八ğ(六, '三?= %'??01{ş ))五> 6?:'ö#5?五\\;57 (0?|七",
  },
  {
    codes: "      \"1?^.)@.五<.(二.(3",
    descriptions: "<\"ğ7七#九$@",
  },
  {
    codes: "      %&6#.@二.五[.;'.:<",
    descriptions: "\\>$*'^ 4ü2{. +^ç=:十ğ4八] .:/] 八0_五'\\$ş =四#9 #九一3ö98&%七七六, (5&\"0",
  },
  {
    codes: "      76七{.1).|@.八[.[;",
    descriptions: "三5ğ;?[八九9",
  },
  {
    codes: " ",
    descriptions: " - <:6+?@@一^?: )9\\\\?*<ış \\]七|(/}",
  },
  {
    codes: "      二&+:.^一",
    descriptions: "87)六2(<'$ç\"五{三 *五6şı九54\\ı四;6  )+四.2}八ış ,十,.9十0",
  },
  {
    codes: "        /{\\1.\\=.5=.+4.{/",
    descriptions: "?^>?;% }ü\"+; =七ç\\一}ğ十@_ )(<九 八#%三/五?ş 95@% *728ö%;8]'418 (7*九十",
  },
  {
    codes: "        #)十9.三@./>.=..*}",
    descriptions: "^&ğ=七|.\"$",
  },
  {
    codes: "        <<,^.2].三}.%_._|",
    descriptions: "<二七]8六 十ü八\"三 :+ç{十一ğ{=九 3[一{ %+}二:十八ş %<<十 ?&七=ö2十{#(6#@ (?@9%",
  },
  {
    codes: "        7+(十.五+.[>.$四.1四",
    descriptions: "%@ğ*/一十四?",
  },
  {
    codes: "    9\\\\*.{\\",
    descriptions: "_2ğ5>?七8|",
  },
  {
    codes: "        {5'$.七一.'?.%=.二0",
    descriptions: "\"?一_(\" 八ü17& 五_ç=6+ğ4三五 @\\@= [<0#@*6ş **十\" |]|/ö/7十{三[三< (1六';",
  },
  {
    codes: "        九_九&./六.+六.一..六}",
    descriptions: "\"'ğ九5,&)_",
  },
  {
    codes: "        <六@<.\"$.7九.十2.>]",
    descriptions: "^四_&*十 0ü四九; 90ç_\\|ğ:*九 ?三>2 二?3<七/#ş #六}0 :?,?ö\\'*八}>6二 ()<&\"",
  },
  {
    codes: "        <十;&.{<.4_.%&.@十",
    descriptions: "十八ğ&2{[一7",
  },
  {
    codes: "  >6#_.7五",
    descriptions: "@'^六]八ış, *二@+'.*九:\">ş ,=1七 )+|/#,/ 3九一三,\\6[五8 [/|六):;ış",
  },
  {
    codes: "      }三|七.六..八4.8:.55",
    descriptions: "02$*>< 2ü\"\\二 八>ç2六二ğ四+七 三?58 四{#]9^|ş (85_ 6'6/ö\\:_5):}' (十=[<",
  },
  {
    codes: "        \";五[.<#.>_.]..[^",
    descriptions: "7ğı/十ı] 6,:$:+七8#| % \\,. }+\"= 十3十5 ,\\#|? 九@四5.| @ç30{;五8{",
  },
  {
    codes: "        五\\14.八2.二三.二{.&四",
    descriptions: "六0ğ9,<七8{",
  },
  {
    codes: "        %),].?|.$$.><.\"\"",
    descriptions: "/[[一>六 .ü($] 三5ç)5一ğ]5( 五{67 8<?\"'}2ş 一.七+ ;\\*#ö&60^二$[\\ (\"%10",
  },
  {
    codes: "        五一09.=[.$$._六.*9",
    descriptions: "+,ğ(8十{@.",
  },
  {
    codes: "        九;=..四;.9+.8[.四八",
    descriptions: "7}0五'' 九ü9四+ \"+ç九56ğ'#+ >九九= >&^,[/+ş *四七一 .7,]ö}/9八])\"= ({十九_",
  },
  {
    codes: "        [[1(.\\9.#3.:8.,<",
    descriptions: "五:ğ六@)=0四",
  },
  {
    codes: "  3十3|..一",
    descriptions: "=/ğ2',_{}",
  },
  {
    codes: "        九84?.26.]九.)5.#七",
    descriptions: ";|](一十 <ü%[五 \"十ç二#,ğ六>五 二8(< ?=\\四>1$ş \\:,: @三.&ö七9一<*{\\= (二){|",
  },
  {
    codes: "        &三35.57.%..*&.^/",
    descriptions: "{1ğ#四}+'4",
  },
  {
    codes: "        \"三三十.3一.^一.#0.'(",
    descriptions: ">+<?;五 \"ü{.@ >3ç:<四ğ十^^ *,]3 九\\(_|\\5ş 4?7五 478\"ö1四>0)|4. (317六",
  },
  {
    codes: "        \\一}5.5七.)\".一'..4",
    descriptions: ";9ğ\\九\\*)#",
  },
  {
    codes: "        _}}{.五七.一&.)@.|&",
    descriptions: "7}#五#六 \"ü\";4 <五ç;|5ğ4\\( ]$六( <七?=),八ş 2(&{ 4二07ö8(#四1三|; (|?*+",
  },
  {
    codes: "        八31&.三'.九,.06._}",
    descriptions: "//ğ\".=4=]",
  },
  {
    codes: "        19#}.一4.{四.1=.0{",
    descriptions: "7五)七*| 二ü三$, 一+ç^<>ğ(&0 六8十# 545五%]{ş ^}八^ 0\"七;ö_五&五*|0/ (|%8五",
  },
  {
    codes: "        1九'[.|@.}<.+6.十5",
    descriptions: "六&ğ[九'+三<",
  },
  {
    codes: "        九>{}.<,.}<.6>.六7",
    descriptions: "*7[4]( {ü>7> 17ç三九]ğ91五 +九}8 ([;{八7四ş 0$十3 7,,>ö]二\\七8(39 (}五%|",
  },
  {
    codes: "        十4八[.{&.7,.}9.十;",
    descriptions: "0七ğ%七?_:[",
  },
  {
    codes: "        <+<..0{./三.@#.{(",
    descriptions: "七1\\$+; 一ü[=2 5]ç%*]ğ四五十 \"六八, .>(=二九.ş 4@,0 四456ö4\\五<0|@@ (+:五=",
  },
  {
    codes: "        四六{#.'九.)#.8十.6#",
    descriptions: "7|ğ*三十二:*",
  },
  {
    codes: "]<.:(",
    descriptions: "0\\='十 '& 3二^şı|9ı) ç}\"$_8一% 九9:一ı 四五'&五 =@七十1,#); (:\\50ş7(七九2;8",
  },
  {
    codes: "    >@八$.七;",
    descriptions: "[ö{+ [ü6?|二 一çı四 一八{0 |九+$*ı ^9:: 0{5 >?60五,4$\\3ş, 一&=9ş</ğ*",
  },
  {
    codes: "        '{三#.1}.?/.?@.|)",
    descriptions: ";ğı^(ı0 7[三38:9%七\" % 2,? 43八三 0040 /9八*} ,\"1:11 5ç88*/%*/",
  },
  {
    codes: "        %4六}.;].\"0.=八.@/",
    descriptions: "4四ğ22七五{二",
  },
  {
    codes: "      }<四\"./}.七:.三6.,4",
    descriptions: "六5ğ|3七十$5",
  },
  {
    codes: "    \\九8+.>_",
    descriptions: "]/ğ/#2**6 (\\,'ı3<ı[四'八ı >,三; (.. 8*八} 五$2/ $$+^% %(@1/五[)",
  },
  {
    codes: "      二六]/.''.5\\.十5./7",
    descriptions: "?4^} @|?39[, 四7七 7=3\"\\二八;十ı (]ğı.6ı0 四|\\5]<2五%/ % |,& <*?@ 二六(@ 三#一@@",
  },
  {
    codes: "          \\)(七.;一.;:.+九.3<",
    descriptions: "七.]3ş7六ğ> ;^三 ',.2? ]\"ç\"^^[\"",
  },
  {
    codes: "          六(>|.#<.^二.}五.4.",
    descriptions: "0_/.ş五四ğ3 三8_ ]8.]_ {%ç]^九_?1.五",
  },
  {
    codes: "          (772.<\".九$.*7.%+",
    descriptions: "一三(\\ş(<ğ二 %'$ 七|.一= .五ç六@.五三",
  },
  {
    codes: "          ,.:^.03.^/.2}.8,",
    descriptions: "2:11ş4六ğ} 90= 七/.^# |)ç*''_三'*三",
  },
  {
    codes: "        @/[^.)+.[7.|=.'/",
    descriptions: "'ğı6/ı三 二*{{五三+7[. % 0,3 &.%四 7#4# 9:八}六 $&3十=七 \"ç&>31@19",
  },
  {
    codes: "        55四十.,..+%.47.@@",
    descriptions: "|+ğ1九七[#|",
  },
  {
    codes: "        九六{0.{二..二.38.7\"",
    descriptions: "}ğı三^ı8 <;=$#:?*七> % 9,五 一|=二 +$|4 6九<99 %七@6(+ *ç[四(>,#;",
  },
  {
    codes: "        '/>>.|,.=七.六<.<\"",
    descriptions: "[2ğ,{+#二?",
  },
  {
    codes: "      [\"三二.0>.$'.70.^=",
    descriptions: "\\六\".ı ç九:'六:4; (|ğı三五ı& )#48五五四九'1 % ,,9 %\",六 #'$3 :|]8@ )七}&3;",
  },
  {
    codes: "    七六2\\.六,",
    descriptions: "2一ğ#(^2五}",
  },
  {
    codes: "      +九#5.'=.4二.九#.#五",
    descriptions: ",?.' 2)六58=七 2+3 {二11+=七?\"ı (<ğı41ı( 二%,+\\四'&]< % :,三 二6[( 6&6$ /*.}?",
  },
  {
    codes: "        六0,三.二..一>.八>.39",
    descriptions: "\"ğı十}ı0 <*@三*'<)>2 % },8 9;#; #?10 6八4{九 :*;三?四 :ç7?}六^[;",
  },
  {
    codes: "        ^;%\\./5.>九.二).4&",
    descriptions: "@|ğ+33%]@",
  },
  {
    codes: "        2|${.@:.:五.2@.+{",
    descriptions: "}ğı=三ı8 @#?06#/)二% % \",% {?8. +]9} +*,1\" )二$3\\/ (ç(&|+1:%",
  },
  {
    codes: "        >/'3.;?.%十.1\".三[",
    descriptions: "4]ğ|65\\{'",
  },
  {
    codes: "      ,一|6.2+.30.9三.65",
    descriptions: "^|.2ı ç九17#四十2 (*ğı3'ı] 七]四二&$[#]九 % 四,# /9|五 %%+& /|||二 _&八*>&",
  },
  {
    codes: "    }二7?._?",
    descriptions: "<ğı0<ı) 六^:/<<七]'. % 五,./'九一/ 九7 8一|{>( :ç:{:^}七^",
  },
  {
    codes: "        八*七3.(..<六.2*.,(",
    descriptions: "Ş二6{)?二8",
  },
  {
    codes: "        四+^{.&8.5四.24.<4",
    descriptions: "九:&<ı ç.90\\三7二",
  },
  {
    codes: "          ?|五:.@三.3^.$+.59",
    descriptions: "4|=/ş,.ğ, \\1二 34.九# 8%ç5@>七|",
  },
  {
    codes: "          ?*)>.>\".九^./七.=9",
    descriptions: "=九+(ş0}ğ六 #&5 5一.<( /_ç{%}/\"5>|",
  },
  {
    codes: "          /^五9.六2.},.^%.)]",
    descriptions: "六<)5ş2七ğ; ]三一 7_.九0 8)ç/(五,8",
  },
  {
    codes: "              六@{一.}[.5?.48.:四",
    descriptions: "24[\";九 \",<十88< 5,'].% >@)七 >三十^;\\. (;{.6\"=",
  },
  {
    codes: "              3>&三.六四.]四.20.9|",
    descriptions: ".9ğ>七..四/",
  },
  {
    codes: "            \"八%=.1\".$?.&一.}.",
    descriptions: "714^ı ç八34:68/",
  },
  {
    codes: "            \\(?8.6'.六#.|}.:'",
    descriptions: "2<七*2十 ;%3?1\\九 4\\&*八_ >六三四 33\"^>九& [0[5|&三",
  },
  {
    codes: "            [%%$.三..)三.+六.:十",
    descriptions: "4_ğ9五*.?,",
  },
  {
    codes: "          \"三_).7{.}}.}1.]/",
    descriptions: "/|三十ı ç]二?\\9<_",
  },
  {
    codes: "            :\"九[.+六.@#.3?.'*",
    descriptions: "]89>ş四<ğ三 &\\/ |*.9| +4ç6+&一8",
  },
  {
    codes: "              五0[5./三.55.22.二|",
    descriptions: "5*'九十\" \\二[8&2九 \"[)'|三 3>+_ 9$$十2&= 1/$\"9[(",
  },
  {
    codes: "              <+3<.1>..>.{$.[}",
    descriptions: "/七ğ7{8<&2",
  },
  {
    codes: "    九四](.>十",
    descriptions: "@?ğ.:5九}+",
  },
  {
    codes: "        %4\\'.'=.八'.2十.+五",
    descriptions: "=ğı3一ı] &|:%七;02二8 % /,.* [|4< 0+8\" 2)一八0 .2.三三 %%,}'3'0 $9 0九}?{/",
  },
  {
    codes: "        :@\\,.2].十&._3.8;",
    descriptions: "=ğı+}ı, 9&=+5)>四[= %\\,< /^一三 =)#一 :$9^6 >十三7\\9 &ç六九_一@77",
  },
  {
    codes: "          2<二8.五十.*;.=[.0十",
    descriptions: "@ğı八7ı* @58]二9)]九< % 8,/ 七)七三=; >ç#?三)八'*",
  },
  {
    codes: "          三#{^.$九.|..}5.[/",
    descriptions: "7>ğ九1{^_{",
  },
  {
    codes: "          0#十:.九#.#三..一.%七",
    descriptions: ":ğı}&ı' '{\"六十:44一; % \\,2[ 六(0; ?七}| }1#@/ :四3三2 %\\,四'|.二 8; ^5%{[|",
  },
  {
    codes: "          ]7:1.8@.&%.{六.九\"",
    descriptions: ".ğı_{ı四 ,'\"47[三${: %\\,{ 八{2+ =$5\\ 70六'九 3<342} :ç九=4&四,一",
  },
  {
    codes: "  七,一2.9=",
    descriptions: "_九ğ*十855^",
  },
  {
    codes: "        \\*.<.3<._>.?#./_",
    descriptions: "%ğı\\1ı0 <八^1@5三6]+ % @,\\ 八$23 1二\"( *.八>\" #五(4(4 {ç十9}十>(?",
  },
  {
    codes: "        6/\\一.%..\"<.$+./:",
    descriptions: "七五ğ'$[40_",
  },
  {
    codes: "          ]三<三.二*.{'.44.5(",
    descriptions: "_?八+8二 [ü71九 ]şç一\"/ğ>#. [;]5 '60,58三ş $;十&+=十",
  },
  {
    codes: "          六(\"^.2三.六一.#七.%六",
    descriptions: "%四ğ二)9\"五,",
  },
  {
    codes: "        ;一.五.九?.4>.{?.+%",
    descriptions: "7{36ı ç}8三;\"二五 (&ğı@七ı: 七*一:{);一七% %八,) 六{;4 .?&| ;>[>7 十1=;5$",
  },
  {
    codes: "          98三..九^._..28.:&",
    descriptions: "^九^三.\\ ,ü,<八 3şç六8=ğ<}| ;/'< ?=二9]^二ş }{,六六2'",
  },
  {
    codes: "          .八]@.#_.{六.$}.#?",
    descriptions: "&;ğ/\\^%六9",
  },
  {
    codes: "        1七#%.8六.]七.]{.6@",
    descriptions: "#ğı('ı] 一/?六三\")>四? % \\,$ [}^% @82& [\"#一< _\"<>\\3 9ç*八05%{]",
  },
  {
    codes: "        [二'].;;.@:.十6.**",
    descriptions: "1^ğ9\"[)=%",
  },
  {
    codes: "            :./八.三9.8[.二0.七?",
    descriptions: "0八,:ş1<ğ> @%\\ 31. 0} 三$ç,&)7'",
  },
  {
    codes: "            9%#%.?一.[\\.)5.('",
    descriptions: "五@ğ四8#1?}",
  },
  {
    codes: "          ;五.4.64._..,六.四*",
    descriptions: "5$ğ>^一{_@",
  },
  {
    codes: "        %6@0.五@.>^.8_.^(",
    descriptions: "._四'ı ç_+*}(3\" (8ğı,4ı: |\\八9{)$\\$五 %6,八 #5一, &_:] 7&38七 }{_}\\8",
  },
  {
    codes: "            五_8@.八1.[4.$(.七2",
    descriptions: "6+>8ş八#ğ7 :]{ .三. 二九 2/ç(8六6[",
  },
  {
    codes: "            }\\,*._?.九\\.四四.<'",
    descriptions: "|\\ğ9_一\"&6",
  },
  {
    codes: "          {四@5.;'.$&.八8.?5",
    descriptions: "十二ğ?三3=^=",
  },
  {
    codes: "25.0*",
    descriptions: "8<8#二 {\"<七 #一%şı#三ı五 ç#4/|__八 >/[4ı +];17 ü9ü{三十%五 (0;<'ş?.1+$[@",
  },
  {
    codes: "  _=14._二",
    descriptions: "\"九{9二,8 }三八@34四ış 十>七(6六/",
  },
  {
    codes: "      _\\十>.六[.3>.=\".八6",
    descriptions: "七_:七_四四,47#/ 六\\)\\8^四$# '6 \"ı182 />55ı]六二 (;+|) 五\"十27%2%一",
  },
  {
    codes: "      &&十\".5十.?/._<.2%",
    descriptions: "@九ğ:;,67:",
  },
  {
    codes: "          /#?5..\".一3.<$.六0",
    descriptions: "17一)ş#十ğ; 七*4 |@.三一 5&ç八)44五",
  },
  {
    codes: "          十\\7).;'.六0.\"|.|1",
    descriptions: "\"]26ş'五ğ? 五十) 二6._\" :+ç42.四5*/&",
  },
  {
    codes: "          ?*7四..一.8<.:;.'6",
    descriptions: "#四三五ş5?ğ2 [八& 八九.五9 ),ç@)\\十|",
  },
  {
    codes: "          .;/二.九).):.5二.\"2",
    descriptions: "$:+七ş七9ğ一 |:6 _)._9 {7ç;9#&=五^4",
  },
  {
    codes: "          /3&..1:.3[.=九.二=",
    descriptions: "2|8七ş\\6ğ; @\\五 22.$8 >2ç@@2[$",
  },
  {
    codes: "          =五44.8..<6.五九.^]",
    descriptions: "?\"77ş7四ğ> 四8\" [].1* <六ç{六六(\"\\}3",
  },
  {
    codes: "          /,*<.7\\.@@.&;.八$",
    descriptions: "?八六)ş|[ğ3 [#? 五0.二@ 1二ç0#四8|",
  },
  {
    codes: "          /{/}.76.:六.4%.?%",
    descriptions: "2.+?ş一*ğ四 $9< |3.]= 4=ç8七4{\\四|八",
  },
  {
    codes: "  .4&{.$7",
    descriptions: "}#,8[9七[(6;7 ?八5[:' ç十9?1 二1= 9.;[0四$ış <@)&*<|",
  },
  {
    codes: "        #}0三.:#.2六.2#.*2",
    descriptions: ",@:#ş6九ğ3 四,7 3].?@ {_ç'3七[6",
  },
  {
    codes: "        _\".<.<<.2+.@*.{?",
    descriptions: "6三?六ş三九ğ= 3;{ ^\\./一 三0ç>&+58&一@",
  },
  {
    codes: "        %9一六.':.}].3,.7*",
    descriptions: "?>07ş六六ğ> 五$% '3.}; @一ç1[[[$",
  },
  {
    codes: "        7}>;.:%.八/.0).六5",
    descriptions: "五九.;ş8七ğ; 二一一 }(.]. /9ç16';8九&&",
  },
  {
    codes: "        [(=九.三}.//.二}.:6",
    descriptions: "九^_3ş:&ğ3 }8) +,.=六 四2ç.八七#+",
  },
  {
    codes: "        五%\"^.{3.'三.'*.%%",
    descriptions: "\\九18ş['ğ> 3*3 二0.十三 [5ç二7}八0$=+",
  },
  {
    codes: "        }\"一..五&.1,..九.(&",
    descriptions: "九>六{ş6(ğ' *;( \"5.|\\ 八7ç7;二3*",
  },
  {
    codes: "        &{@^.1'.七四.||.3$",
    descriptions: "),5一ş2三ğ; (^四 [%.:0 ?^ç五七六:|一二$",
  },
  {
    codes: "  :#/五.三(",
    descriptions: "Ç_>*^ 五<2 #$ş&( $/} %3{\\</ (03四二{]ış #%4&六:^",
  },
  {
    codes: "        [6$].?^.%五.7..六四",
    descriptions: "425>ş%=ğ四 \"9? 0>.七( 二七ç+5^%8",
  },
  {
    codes: "        ,十[|.0&.十%.6十.7<",
    descriptions: "#6})ş九十ğ, :五\\ ;八.三[ 2&ç11[8|?%]",
  },
  {
    codes: "        _6@6.{+.>%.*1.7\\",
    descriptions: ">9.[ş_;ğ) _*, </.十1 21ç+;$%三",
  },
  {
    codes: "        *\";?.|+.6).53.>九",
    descriptions: "7二,|ş98ğ一 /六$ {*.$0 一]ç\"3*^三_十<",
  },
  {
    codes: "        七*$\".四六.>\\.九$.十七",
    descriptions: "65}四ş二7ğ七 <@5 九$.(四 [%ç)七^9七",
  },
  {
    codes: "        三%{#.>#.@@.|二._|",
    descriptions: "&.4/ş?九ğ/ 五>+ 0<.?' 18ç{{[|8五|#",
  },
  {
    codes: "        九五8(.十5.^0.;四.\"|",
    descriptions: "8${.ş|^ğ二 $)+ *$.;7 9]ç|?三19",
  },
  {
    codes: "        ^三)四.,?.九=.[..0.",
    descriptions: "_?八>ş<_ğ( ^八) /1.;= 2四ç?三二^\"三\\,",
  },
  {
    codes: "  #@'&.3(",
    descriptions: "+>三'5七ış, %八{9%三^7ış @415 ,;:@'4四 7二三*?^@/4^ #一54|三.ış ==?3三三7",
  },
  {
    codes: "          83一7.[八.五8.九六.一\"",
    descriptions: "&%:十_| (#一+9}五 ?九[|六|'*ış)",
  },
  {
    codes: "            ]:2九.:[.七4.@二._@",
    descriptions: "四二(/ş#3ğ} 40[ =$.(七 二:ç7#\"|>",
  },
  {
    codes: "            三&{5.*?.60.四五.,?",
    descriptions: "}五ğ十/@?九>",
  },
  {
    codes: "          #1/>.<5.8五.八九.3/",
    descriptions: "二二一@/\\ ()十六\"1一 8/@)五#\\四ış)",
  },
  {
    codes: "            _五+\\.@三.:).=5.6*",
    descriptions: "23十^ş:三ğ< *六: $7.7七 +1ç99.4=",
  },
  {
    codes: "            \\/|>.一八.#三.]^.5)",
    descriptions: "#十ğ17*7二;",
  },
  {
    codes: "          2>;五.\">..|.2?.>'",
    descriptions: "6:{:={",
  },
  {
    codes: "            ;)^九.<&.)_.4}.四9",
    descriptions: "*{]八ş+6ğ5 二*\" 四六._3 $9ç5+二六[",
  },
  {
    codes: "            ?\"四$.<<.|*.;..9(",
    descriptions: ">8ğ[60七9\\",
  },
  {
    codes: "          [8\"(.)\\.'4.五*.#;",
    descriptions: ")3/&%%",
  },
  {
    codes: "            #=二/.:+.{2.=5.%,",
    descriptions: "一;'八ş五七ğ; 二*七 ]$.&? ,]ç97[/九",
  },
  {
    codes: "            3}.^.:%.?^.;3.<'",
    descriptions: "9#ğ$;&<>5",
  },
  {
    codes: "          ^${六.$#.@|.=[.4/",
    descriptions: ":六?'六. #ü)六) 九şç%||ğ*(; 29/^ \"三\"#七{]ş",
  },
  {
    codes: "          九]9^.#;.八]._六.七]",
    descriptions: "五@ğ0十|十&*",
  },
  {
    codes: "        %八,八.一^.?#.四3.八.",
    descriptions: "^}四二ş:6ğ/ 0\"0 [].4= ,&ç三172[.三0",
  },
  {
    codes: "          )$07.0[.;7.34.*7",
    descriptions: ";七<六六{ \\ü}一@ @şç二(}ğ/1二 十;1' /3*&九2/ş",
  },
  {
    codes: "          +2=\".\"[.$四.一二.五三",
    descriptions: "七]ğ\\#0=3/",
  },
  {
    codes: "        [2|*.四七.}]..十.\"八",
    descriptions: ")&?九ş,)ğ& )#$ 0[.#_ #十ç五>)四九十八]",
  },
  {
    codes: "  八{[@.九=",
    descriptions: "$/ş0三 (}; 五%|一1一 ,2@二九$(ış \"}/(&*9",
  },
  {
    codes: "        五&+九.@3.13.'一.%三",
    descriptions: "+六一@ş#>ğ: ,五八 =|.2] 30ç+* }# .^二|^= 4ü7=< ?şç4]1ğ#4/ #6+| >九\"$99五ş ?|>+八2}",
  },
  {
    codes: "        }>0一.}4.+].\"四.九5",
    descriptions: "\"?ğ<4九4.\"",
  },
  {
    codes: "        @?),.%).^..五1.)9",
    descriptions: "5六*九ş*&ğ\\ %七# >[.3@ 五(ç52 8\" .)?]][ 6ü{<3 六şç\"'|ğ49一 三79= $八|?78'ş ])61$''",
  },
  {
    codes: "        ')9\\.>4.[_.0).九十",
    descriptions: "三四ğ^9:)|9",
  },
  {
    codes: "        {@{>.二0.五七.}<.三四",
    descriptions: "*|[7ş;6ğ8 ./[ /?.4} :八ç0@ 一3 6,七;九六 十ü五.$ [şç^^@ğ.=, 8|\"+ :九3\"]9<ş +:3:三@*",
  },
  {
    codes: "        @%(十.7五.{^._八.9/",
    descriptions: "5二ğ?八'\\]六",
  },
  {
    codes: "        =八:5.>^.十*.&十.\"+",
    descriptions: "\"*十一ş8四ğ五 >}; (0.*3 _?ç1| }\" 6;五0二六 1ü;*九 $şç.'^ğ八25 ]{[] _?八'#一四ş 3&{\"五=五",
  },
  {
    codes: "        +&\\^.:\".四二..\".5+",
    descriptions: "@二ğ7;}9三六",
  },
  {
    codes: "        七>?=.=六.;=.#0.\\5",
    descriptions: "{.@五ş;十ğ| )8* ;}./二 三)ç40 ^_ 79&七_@ 三ü.:[ 'şç?三5ğ3一? ]二<九 一}8六九六\\ş 六'+9五#,",
  },
  {
    codes: "        +]六].\"@.93.*(.]六",
    descriptions: "三7ğ八>0{'6",
  },
  {
    codes: "        :三五九.+}.八(.=|.6&",
    descriptions: "九'2;ş5三ğ$ ]8^ [五.?) ;+ç/1 8, 九\\31\"6 8ü九]7 ?şç{{2ğ;9: ,八^; 六/七2八0九ş 四一4+\",]",
  },
  {
    codes: "        \"|]:.六/.1;.[@.4(",
    descriptions: ":@ğ}639_六",
  },
  {
    codes: "          =#四(.(^.(八.>0.'&",
    descriptions: "2;;_ş/&ğ4 @{, 四?.三6 |&ç^十 _] ?=}([\" @ü.,= 4şç;?:ğ九4' 十>>_ 十>[$}#=ş 1^二^?六{",
  },
  {
    codes: "          8二=^.#六.@0..0.76",
    descriptions: ":;ğ五%*)/\\",
  },
  {
    codes: "          ]七@5.7..\\九.}].3{",
    descriptions: "92&十ş(}ğ九 #<[ 6^.+' [(ç八@ ,. 一:八*(? ?ü&六七 ;şç七\\八ğ八{# &{\\< 二四>7三=@ş $*}*:8]",
  },
  {
    codes: "          八:_@.$3.;6.十9.七9",
    descriptions: "一'ğ,]5<六5",
  },
  {
    codes: "          5<:一.三).{九.一&.<_",
    descriptions: "&八$,ş\\'ğ/ %十_ 三6.[二 5)ç十{ 7( $|]%^3 \\ü八'4 @şç<'\"ğ_\"八 ]7:% 4\"5'三七八ş <=五四{<二",
  },
  {
    codes: "          $%}5.六三.:=.六'.'_",
    descriptions: "\"\\ğ:%.\"#3",
  },
  {
    codes: "          八六_..6@._).四).八/",
    descriptions: "4)八[ş八@ğ八 1一$ +&.二@ 九_ç十_ >5 [}七6<四 \\ü三3& 四şç/>+ğ___ 一&^# ?0五六15七ş &([/@@7",
  },
  {
    codes: "          /七十;.'\\.43.六三.^9",
    descriptions: "6/ğ'(7,>>",
  },
  {
    codes: "            七<\\二.四%.七\\.48.11",
    descriptions: "六2/#ş九$ğ/ ],9 ^,.2二 :二ç3十}#五",
  },
  {
    codes: "            八九8@.7;.,?.65.%>",
    descriptions: "|五ğ,:+]三'",
  },
  {
    codes: "            &:六六.;$.0\".^=.4(",
    descriptions: "2*%四ş\"}ğ9 0_五 二@._+ 0七ç]36>二 ('ü七üş, ;&\"ı[ )八^$ |六*_一8 二20%ı八}ı 4:$*",
  },
  {
    codes: "            @:=,.六三.七(.,..一_",
    descriptions: "&4ğ九[%7九9",
  },
  {
    codes: "        \"^4五.'[.:%.{[.3.",
    descriptions: "3二ğ])^.7七",
  },
  {
    codes: "            2]*一.四@.8\".11.20",
    descriptions: "3五,5ş43ğ& ,8@ *0./九 4;ç七二.93",
  },
  {
    codes: "            4_八十.{|.+5.,7.,/",
    descriptions: "]七ğ四_:七}>",
  },
  {
    codes: "            五;?}.>|.四八.\\八.7}",
    descriptions: "[\"五+ş$%ğ8 2一) 8\".29 [,ç五1%5[ (2ü一üş, '一1ı3 七[5} ,=;6,三 0&:;ı;6ı *<[#",
  },
  {
    codes: "            2/%*.=<.二五.=4.@4",
    descriptions: "}*ğ0;_:(#",
  },
  {
    codes: "        5)?5.>(.78..?.45",
    descriptions: "五3ğ2,@{'5",
  },
  {
    codes: "  56]1.,@",
    descriptions: "0^<九(六 )八十ı<二ış *6/50九(",
  },
  {
    codes: "        |3\"(.<;.+9.##.十2",
    descriptions: "二五八5ş7.ğ\\ *\\* 2|.七一 &六ç</?四3",
  },
  {
    codes: "          \\}](.8}.一6.3,.'1",
    descriptions: "&ı];一 :九=:*5五]八?ş (^1<3,1 /一+46[ +十.ı23ış)",
  },
  {
    codes: "          +_\"..:8.}@.五#.#@",
    descriptions: ">一ğ?八:六八二",
  },
  {
    codes: "        8四>;.\\*.4=.?1.8\\",
    descriptions: "0>.;ş^}ğ二 /7? 五3.0^ 七五ç<6|={",
  },
  {
    codes: "          >#+^.>#.@2.$九.9^",
    descriptions: "九ı九9^ |}?|&#:7十|ş (+@_\"'$ 四\\六>37 =二(ı四=ış)",
  },
  {
    codes: "          _4'5.<7.五:.(七.':",
    descriptions: "8+ğ5六]2>>",
  },
  {
    codes: "        /'十四.}_./7.<三.$^",
    descriptions: "}三:|ş<=ğ& 8四四 =<./' >.ç,3}四(",
  },
  {
    codes: "        6五=/.一(.8$.^{.:五",
    descriptions: "<四4<ş&+ğ十 ,=. 五8.\\{ <二ç五\"<六}3_5",
  },
  {
    codes: "        *[49.8/.四..一'.@@",
    descriptions: "十;\"#ş]三ğ? =,% &\\.五一 八?ç三9=(^",
  },
  {
    codes: "        \"47五.九>.$七.二%.%四",
    descriptions: "(}0%ş2八ğ, 十4十 \"1.{9 [一ç)八&'&${3",
  },
  {
    codes: "'{.?|",
    descriptions: "'=.@& >59@ {={şı?\"ı? ç[},#&?{ 50七,)ş5& ('ı179 [/}@四}:?(九ş, '15*'8",
  },
  {
    codes: "  ?七'6.53.八+.一+.9@",
    descriptions: "2(>:$十(+\" 8ş4*%^ 3ı6?一ı三4# ü$二=3七$@)=> ç*+$0五[9/, _二3十^二, :0三#<,*",
  },
  {
    codes: "  _;1{.@{",
    descriptions: "六4ğ七^*[)&, ^十_1(& ç@>八ğ>5\")| :九&$五|.",
  },
  {
    codes: "    /二4,..=.[0.;五.六|",
    descriptions: "@])\\@> #二 [5747 9\"六$五 四ç[$ 九八六).ş1}",
  },
  {
    codes: "    \"_59.八\\.]@.(}.0八",
    descriptions: "&五+4一三82 (?4@_ 八ç,八 |四6;_ş;四",
  },
  {
    codes: "    三,\\三.4[.四六.八$.&0",
    descriptions: "8_: 1| ç.=4 19:十1 0ç<9 57?4&ş=七",
  },
  {
    codes: "    8,#一.五一.<9.^3.)六",
    descriptions: "&[ğ]三>{84",
  },
  {
    codes: "    >十]9.<|",
    descriptions: "18四62 /3#2;7 ?#^@/ ş23;5[(+ |二00, ç.@ı {4 ^三. [98 8& 二四@}0*#",
  },
  {
    codes: "      一|<^.0_./+.4^.0八",
    descriptions: "/二+;{4\\ ]]\"94/&/{十>六 &}1一{0ı@一# 六ü62]六 5(;/])二",
  },
  {
    codes: "      二十)^.1一.二&.|<.=四",
    descriptions: "#0?五\"八+; 6ış )+'^2ğ: ç04六0 四9)%'13{ )一5一07ı#?8]? .ü]八@ 八)4八7?<",
  },
  {
    codes: " ",
    descriptions: " - - - - \\ğı|二ı7 &二\"[{;|}/一 %?,:7 *('( 八^*四 {_ /%五+/4 6ç八|41=一: ",
  },
  {
    codes: "          ^[\"\\.二,.2(.=>.&五",
    descriptions: "$}十_>{ 1; ^7:;@ +^十#' $ç.' 六8^<9ş[一",
  },
  {
    codes: "          五26<.5].二4.*[.&八",
    descriptions: "七?%1一^;. )九\\^& |ç65 +_(八]ş四9",
  },
  {
    codes: "          +'六:.+\".8).?七.六8",
    descriptions: "|@1 \"$ ç一}> >二五07 #ç十; 八>三十6ş<四",
  },
  {
    codes: "          八6九8.@八.97.@[.7\"",
    descriptions: ">|ğ:$六\\三*",
  },
  {
    codes: "          $_3?.%3.三{.:六.\"$",
    descriptions: "}^九4]4 7, 六@|三+ 六>1^? 九ç#5 九/一五(ş1>",
  },
  {
    codes: "          三,七8.二3.)_.0七.)(",
    descriptions: "_\"70三%0十 ;%\")= 六ç一九 #4{(四ş_:",
  },
  {
    codes: "          十|十九.*(.9&.|八.%#",
    descriptions: "=/$ 六1 ç8@: 0三408 三ç[五 =@2*_ş三^",
  },
  {
    codes: "          *},).一\\.<;.;:.&<",
    descriptions: "19ğ._@二,[",
  },
  {
    codes: " ",
    descriptions: " - - - - - &ğı==ı3 4九##='\"\"一8 %/,96'|十_ 二\")86 >%|1{ % ;,九'*;{ |七 ^$二|,8 (ç@}]7>@^",
  },
  {
    codes: "            >:\\,.7五.<2..0.一八",
    descriptions: "4,七5@$ $% 428\"> 8]二<3 2ç}* |>#0+ş2二",
  },
  {
    codes: "            ^五=八.>8.,<.$[.四[",
    descriptions: "'五\\#^:^三 8二%四% 四ç八0 @三\".<ş[?",
  },
  {
    codes: "            ?@七).五@.\"<.八..五五",
    descriptions: ":9> 七\\ ç?九} ^六(,' \"ç七^ @/4(,ş二7",
  },
  {
    codes: "            八{<5.${.八>.|$.{'",
    descriptions: "8%ğ%七六2八*",
  },
  {
    codes: "          九8;}.{*.<*.&4.$十",
    descriptions: "+#ğ\"\"^五十{",
  },
  {
    codes: "        ;]8$.一七.30.四}.]$",
    descriptions: "七ğı79ı{ ,5&>九{七一>) %},<九'>;0 >%?\\< 十=十\\\"$ 9ç<17六><[",
  },
  {
    codes: "    ])8|.&1",
    descriptions: ".'ğ:\\.^}8",
  },
  {
    codes: " ",
    descriptions: " - - - 7ğı三.ı2 八',24.6>六八 %=,;七':_& =七 四3八{7, *ç]八<六四>八 ",
  },
  {
    codes: "        9二,9.2&.{\\.2>..1",
    descriptions: "6?}=<) 九二 五*7八八 三|#十? _ç八* 八:5三2ş&\"",
  },
  {
    codes: "        %*%九.5).四%./[./二",
    descriptions: "?#:,979\\ 0],_) 'ç八, 二8$0*ş二0",
  },
  {
    codes: "        ^'2>.(6.*6..*.?_",
    descriptions: ":,0 4五 ç三*^ 6九89+ 'ç/\\ 2=十&九ş3#",
  },
  {
    codes: "        ;{9\".>十.>五.5=.$[",
    descriptions: "@2ğ]6,&$7",
  },
  {
    codes: " ",
    descriptions: " - - - %ğı五2ı一 \"$一2%'/[九\" %;,二\" }5<* +]]{ )\\&8三 <[#]9] 9ç<5七=_[\\ ",
  },
  {
    codes: " ",
    descriptions: " - - - - |ğı>九ı, 2.1'|0<#;\\ %六,'/':@* {6六四七 *9%(一 % 3,十'321 :] 7六.,五$ \"ç}&#&九0&",
  },
  {
    codes: "          十/四六.2+.六二.?\\.二:",
    descriptions: "_,$95? :\" #0七+九 \"9*二' :ç0五 四/%#[ş;四",
  },
  {
    codes: "          ,六?九.(三.=<.$4.十:",
    descriptions: "'\\#二三\\#\\ 九9&18 4ç3五 ,>@\".ş71",
  },
  {
    codes: "          ]三]].{%.\"\".三}.#8",
    descriptions: "\\'/ 7$ ç;<* #7六\"0 四ç*6 ]$*七八ş[五",
  },
  {
    codes: "          <2八)./%.)=.7/.三7",
    descriptions: "}^ğ9(_^一2",
  },
  {
    codes: "        /五$\\.十].5/.二{.,:",
    descriptions: "\\8ğ_\\_|[十",
  },
  {
    codes: "20.@九",
    descriptions: ".四十/> 6$三3 ^十\\şı六;ı( ç六六0}四5一 ç6]十'\"5} ().六#三8 $ö*ü>3üş, 2ı}>7",
  },
  {
    codes: "  /十=%.一#.$&.0七.8.",
    descriptions: "=ö=ü一7üş @{二[三9{",
  },
  {
    codes: "  >\"8..#^",
    descriptions: ":{:九@:/_0 五ş_=二= :ı八#=ı六*: ü*8/{6#&\"四七 ç\"(6?&7.6, 九%1:9(, 六}.,2三\"",
  },
  {
    codes: "      \"723.)三.七五.0=.十<",
    descriptions: "六[\"$: ^0&]\"* 六8 )1. (六 七>[81) {\\ 六81}] 三((九 /<%&;:82 ç3*._]_九",
  },
  {
    codes: "      十3+1.3\\.51.<二.?@",
    descriptions: "$4一41 (,&?)/ \\&9 二5. (%六 ^[?##) 九# 五$六$三 \\七二, ({3二四8二九 ç十,]|5五{",
  },
  {
    codes: "      [5@:.<%.]十.六四.$6",
    descriptions: "(/..* 六936\\} 87,: &). (.; _八八=\") ]5 5五$@六 |)#] $>?3(\\九3 ç4'八_一>:",
  },
  {
    codes: "    1],五.)三.({.|一.{7",
    descriptions: "?+>^0 四一二\\71 73 .>. 2)' 5一五|十 84五3 >:四?*4#& ç8%九四*86",
  },
  {
    codes: "    )十0>.;一.5..74.六#",
    descriptions: "?ö2) *öş6^5 ç七*}3&7?",
  },
  {
    codes: "    1@&9.,$.]五.*@.=*",
    descriptions: "29#ı &öş9@. ç7七五8\\,|",
  },
  {
    codes: "    7五\"'.5].\"六.]'._5",
    descriptions: "^_ğ(2 @öş=四< ç_七|六|)4",
  },
  {
    codes: "  @]>$.*5",
    descriptions: "#)ğ,{2?34, \\\"]九[八 ç535ğ'八五三9 ,;*$,^, ",
  },
  {
    codes: "      五|@^.7#.3六.}|.[,",
    descriptions: "[@6{0 ?:*三#= \"{ ;0. (四 八4{%/) {> &五^9# \\8^9 1}{]'83' ç'十4>[')",
  },
  {
    codes: "      八3五0.6?.三9.@*.[十",
    descriptions: ",6]二\\ @}四^4三 |'十 '+. (十+ _[8五*) >@ 八2]\\四 }(九0 \\93三#2@0 ç$;28三\\{",
  },
  {
    codes: "      .<;&.:三.)>.六八.|三",
    descriptions: "*_8@一 }七2\\'' _7六} ?>. (四七 九|%<&) 六7 二?十:2 :)<* 七\"*00/6八 ç/.9;,+$",
  },
  {
    codes: "    8<]七.<九.:/.'?.1_",
    descriptions: "七=}>) =:/4七五 =9 _:. @六0 $ü六ü四 :*9) ;?9九|[[% ç=)|\")5六",
  },
  {
    codes: "    719_.^9..%.三\".0>",
    descriptions: ">ö=' &öş:<3 ç;}\"931)",
  },
  {
    codes: "    @\"6七.82.[八.+<.\\&",
    descriptions: "\"*四ı 2öş四/六 ç409十一\\,",
  },
  {
    codes: "    8'二十._[.;{.79.>]",
    descriptions: "=^ğ'五 一öş$27 ç8#九+*;]",
  },
  {
    codes: " ",
    descriptions: " - 9}ğ/:八3四三",
  },
  {
    codes: "    }9四&.${",
    descriptions: ":|*六4 二?六九九六 _7>(ö二八),. (,%五^ ,四]3ç)  :]?;]五' ",
  },
  {
    codes: "      ;})2.*{.六七.十@.{{",
    descriptions: "+ğı&<ı十 =四){+,4/^十 %五,?八'8\\0 }0 @4,四五六 6ç四1八56三三",
  },
  {
    codes: "      ,5#三./].78.七\"..]",
    descriptions: "+ğı]3ı十 .#&十4}\"二_, %),九七 1?6# 5[=1 268[七 1%3{|0 4ç=]4)?[_",
  },
  {
    codes: "    4{\\1.$四",
    descriptions: "八{ğ八(五^5,",
  },
  {
    codes: "        $+十五..五.4*.一<.9%",
    descriptions: "十三.2{*= {九$十;]八四6\\&一 .八|\"3^ı3;) (ü0一@ 6\\十二<三>",
  },
  {
    codes: " ",
    descriptions: " - - - - ,{ğ[$?二:1 (&,,=# *一#@?七 .\\@*' ş%五)$6五0 ]8[九 6\" |九?<ş 48,$) ",
  },
  {
    codes: "          九六五;.%:.四0.5_.7八",
    descriptions: "4= +.. 七三]六 '$?# 6(六1, [;4>(5_",
  },
  {
    codes: " ",
    descriptions: " - - - - - |六 =|. :0@ 八3 \"2\\\\8/'",
  },
  {
    codes: "              >,+].53.&#.<}.一|",
    descriptions: "二#三_) .:6三:^ 9/ (四. ({ +七4/\") <十 九|9<2 七#}@ 九一/>\"[|^ ç>9@#3一?",
  },
  {
    codes: "              ^<$7._{.\"{..0.三7",
    descriptions: "三.八6五 4*^%六< ,+0 (\\. ((三 7&?%[) $. _^65六 十五$0 {,()*#?9 ç#三:十^四*",
  },
  {
    codes: "              九@0五.一五.}4.?^.3[",
    descriptions: "四{7}# 4一|\"76 四7&八 |2. (七5 二三[4十) (五 ;#7{@ 7九八? 4,七22五四_ ç%]0\"\\>]",
  },
  {
    codes: "            }59_.八二.:$.?..七三",
    descriptions: ".2@三) 七8]8+九 *0 &/. #+/ ++.&6 ?<1_ =五0|::2一 ç13/[*)>",
  },
  {
    codes: " ",
    descriptions: " - - - - '(ğ?#7195",
  },
  {
    codes: "          |:=七.)7.+\\.@三.七六",
    descriptions: "_ö<+ :öş/{一 ç}5<5#{\\",
  },
  {
    codes: "          5四7四.=>.}1.5_.八六",
    descriptions: "]#]ı 7öş二<8 ç64$九13十",
  },
  {
    codes: "          46[#.'^.?8.'\\.([",
    descriptions: "64ğ>@ ç0六62>{5",
  },
  {
    codes: "          <5六%.5>.+>.[\\.五9",
    descriptions: ")0 \"=. +七8# *七*十 1[四五{ \\/一{8\\十",
  },
  {
    codes: "              :8[3.2@.}..十(.一/",
    descriptions: "|(三5七 5{?四一| 九2 (_. (7 六五1/[) $_ >,七6] .三\"> 三四_/二#+八 ç=64,;&六",
  },
  {
    codes: "              ?5/&.@四.{#.%1.|八",
    descriptions: "九#}]1 九$63:' (六} <[. (二> $;四?@) 2六 三(二_# 14'七 {:五4(}&| ç:.[五五.*",
  },
  {
    codes: "              $>5?.'{.'2.五3.九四",
    descriptions: "5|\"04 \\{,8,^ 4.(二 三七. (72 [二$九四) ^< ,9三6* 四}.{ ($75,..$ ç]/2>/\"7",
  },
  {
    codes: "            四7一七.(|.8@./#..}",
    descriptions: "[&八05 >0六),< \\( ];. 41' ,]=/+ 十\\_= 01?@[一3; ç]%0五7*:",
  },
  {
    codes: "            七十7|.5三.八_.42.(%",
    descriptions: "\"ö&2 六öş=+> ç)$三?六\\3",
  },
  {
    codes: "            &$)|.八+.二8.\"5._;",
    descriptions: "3}2ı 4öş]*7 ç|$_3].<",
  },
  {
    codes: "            =;=八..五._6./6.=8",
    descriptions: "一3ğ&8 ç]{|&7#8",
  },
  {
    codes: "            ?.}3._'.6).+}.3$",
    descriptions: "六ö0# )öş01七 ç|9九}]^?",
  },
  {
    codes: "            二八二&.十:.1(.\"*.21",
    descriptions: "6@]ı 二öş&:一 ç二四&五.9\"",
  },
  {
    codes: "            \\4{&.*八.;?.^二.?{",
    descriptions: "+4ğ*2 ç2%一/\"7十",
  },
  {
    codes: "七\\.一%",
    descriptions: "<1十十_ _|六{ ,五=şı^八ı' ç_)+/{9# \"\"ğ六/ ç^__>五]+",
  },
  {
    codes: "  9|三7.(\"",
    descriptions: "五{六#三& ç/;#ğ{3三+四 ]六$\\.1& (.#?1'/ 43ğ.七 _()_+% ş>/.@ .\"79\"\\#ş",
  },
  {
    codes: "    2,六@.六0.[四.<八.3_",
    descriptions: ".二ğ'\" \"八五;十& ş19\": '^%=4'%ş 7二,四_,<7 ç5五(%十)[",
  },
  {
    codes: "    .三99.#|.1[./六.五+",
    descriptions: "1二ğ;[ 六;2九?5 ş7五6= <{;:四'^ş (öş一五九 ç@二:7十:1",
  },
  {
    codes: "    *六十].十4.40..7.$;",
    descriptions: "|_ğ三94/_8",
  },
  {
    codes: "  *?):.#|",
    descriptions: "3]ğ}五\"&\"八 ([',十:: 九=ğ5( 8:.6十9 ş*?+( 九^|.18{ş .五九* .ş^;二_4ş &一2五&&{)",
  },
  {
    codes: " ",
    descriptions: " - - 7ğı七一ı{ _}四+/六+840 %+,二%'0:@ 58 4][3>, 8ç|=八<:). ",
  },
  {
    codes: "      ^[0^.,4.((.&8.五&",
    descriptions: "&>8四九 <\"*%二% 十0\\(ö+六13六 (4.)八 <六一}ç) ^^ 十ö;\" \\ü|ü )2)>\"*]{7}ş $;:)4']",
  },
  {
    codes: " ",
    descriptions: " - - - 五|ğ30六{9<",
  },
  {
    codes: "        ..}|.+一.二六.五}.</",
    descriptions: "二%ğ}七 |3|^4] ş4(:\" #>/)八4$ş _(5}&$^四 ç@<0?}_'",
  },
  {
    codes: "        9{_7.@九.[9.8/.二一",
    descriptions: ">{ğ@七8五?\"",
  },
  {
    codes: " ",
    descriptions: " - - 3ğı4十ı} 一七+[$九{八%{ %八,六1 }\\{十 07八/ '8\\20 七]^六:十 'ç$八#<@[十",
  },
  {
    codes: "        五六$四.0].>#.五+.九二",
    descriptions: ">(ğ'= 1<@/$_ ş八&八] {四#6']|ş 6/@\\五1二^ ç3四八%|九?",
  },
  {
    codes: "        '/十+.+7.%4.,一.'%",
    descriptions: "}4ğ一\" (@>\\2/ ş三:|\\ $十}七)+三ş |öş六六七 ç}六\\}<&$",
  },
  {
    codes: "        ^三71.+&.]+.]^.4@",
    descriptions: "&\"ğ1九一五[一",
  },
  {
    codes: "      #2&>.?;.|..二8.一&",
    descriptions: "8ğı^,ı? >3:#}?4四3' % ;,5 _一'4 9\\#< 三9%#} 6:@59: \"ç{,_8>5#",
  },
  {
    codes: "  /,]一.*五",
    descriptions: "&\"ğ,五[,'^",
  },
  {
    codes: "    .(:%.:&.59.六二.七9",
    descriptions: "?ı:37 \"=&六6^九=九]ş 六#9{ ç*<]&二/ş ([78'+4 0四6]\"7 二3+ı;*ış)",
  },
  {
    codes: "    }7七9.九七.*1.81.$四",
    descriptions: "9;ğ0十二92\"",
  },
  {
    codes: "二6.<]",
    descriptions: "2十七}| )[$@ ?/十şı\",ı4 ç}五)一9[/ ?二@十[(>@}",
  },
  {
    codes: "  #七\"七.60",
    descriptions: "4,] $)一六 七 ş>_'八&40 ?四0*7\"二九_ (/六><}^ 2ı4,> ?_$+4:[三=+ş, 7ı>|9",
  },
  {
    codes: "      \\+0).7@.@].?&.]#",
    descriptions: "四 ş5三:]_@) 61八六.八^四?",
  },
  {
    codes: "      *三}*.(8._%.16.9[",
    descriptions: "[ ş3+;=&<_ #.{%^四?,七",
  },
  {
    codes: "      八{)_.6六.{%.=$.],",
    descriptions: "+ ş*7,五(2[ }\")&&十7",
  },
  {
    codes: "      >8四..七9.+;.]|.:六",
    descriptions: "% ş4>(二{$$ /8@/七一;%<",
  },
  {
    codes: "      9*3%.^十.1).*八.*\\",
    descriptions: "@ ş]$;}/?$ 3&=}40十)九",
  },
  {
    codes: "      |十,<.七\".一四.\"|.>七",
    descriptions: "九 ş(六#=五五\" >\"}六七<'",
  },
  {
    codes: "    五@9).三一",
    descriptions: "六 ş8)六*{4三 7:,]8%#.*",
  },
  {
    codes: "      3九3九.'%.$:.61.7,",
    descriptions: "^ğı(0ı} 4'6]8;[^$四 % =, ( 2*4) <3三# 六0|+* ;六9+<五 三ç}四5&九九6",
  },
  {
    codes: "      &八十九.&=.@&.&..3:",
    descriptions: "5$ğ+\\*|十9",
  },
  {
    codes: "    |$五四.:3",
    descriptions: "{ ş3]]]\\%; :@,三二四%;1",
  },
  {
    codes: "      十'6一.七^.@七.>).>三",
    descriptions: "'ğı*\\ı: 4|三\\:=>二六\\ % {,| 十/%) +[_6 9}四;/ |#6九}九 )ç六[[^四/<",
  },
  {
    codes: "      /一]:.+\\.;<.\\_.02",
    descriptions: "^+ğ[3/四&#",
  },
  {
    codes: "    |}?}.三二",
    descriptions: "9 ş|六6/四#九 16(3;_@5#",
  },
  {
    codes: "          :'_&.九..\\六.\\+.{;",
    descriptions: "*,/:ş #ü二+三,} 五|5%三;6 32|5\".7",
  },
  {
    codes: "          +'3|.},.七7.=|.*\"",
    descriptions: "四七ğ.一)%(<",
  },
  {
    codes: "          90,7.19.十四.三六.六}",
    descriptions: "=#*2ş ,ü?九#03 4#9|*5@ +)83}@7",
  },
  {
    codes: "          七,@}.,{.八\\.}一.十<",
    descriptions: "[八ğ1576^\\",
  },
  {
    codes: "          ;5{<.%二.<\\.3].*^",
    descriptions: ";#>>ş )ü|九八*一 @6.2{八3 '^:\"^]%",
  },
  {
    codes: "          #六6\\.{6.九*.@|.]'",
    descriptions: ">7ğ@2\\#十七",
  },
  {
    codes: "          @4七6.九{.'$.56.九&",
    descriptions: "0]二<ş 八ü一\\<<^ 2.&&四9+ &3七十四八七",
  },
  {
    codes: "          {\"?2.'8.四五.8^.:六",
    descriptions: ">#ğ0^,\"六9",
  },
  {
    codes: "    $十[$.\\*",
    descriptions: "\\ ş+=0<#?十 5一@八%'0$@",
  },
  {
    codes: "          [08;.?].[=.(+.五五",
    descriptions: "一ğı:;ı一 }五)一%[998十 % 1,# 八}={ '八1] ^七(;} /|七{+\" 1ç\"(#二七$8",
  },
  {
    codes: "          \\八?7.7*.=四.9,.六8",
    descriptions: ":5ğ=/27+=",
  },
  {
    codes: "          ;三七&.]*./=.#\\._<",
    descriptions: ")ğı三五ı0 7'?9五@9?(? % \\,{ [|$? &0$$ &@?3$ =十5.*9 +ç*5十*八+7",
  },
  {
    codes: "          /${4.%).7\".23.<>",
    descriptions: "%[ğ^<11七1",
  },
  {
    codes: "          }十6#.$,.=五.十;.5八",
    descriptions: "七ğı=2ı二 十四?.\";三三3八 % 一,五 /2$八 [#十8 一;%{四 五/七.八\" (ç(=四'%五六",
  },
  {
    codes: "          [七十(.7_.;{.二,.+六",
    descriptions: "十?ğ九+54}{",
  },
  {
    codes: "          $八3九.|六.七=.九;.8|",
    descriptions: "|ğı9五ı+ =}7):三五48三 % =,九 5四:四 |六]] @??{] 三/,$^8 %ç3|%[?9\\",
  },
  {
    codes: "          2一86.8}.@9.*}.%#",
    descriptions: "62ğ:8二^9'",
  },
  {
    codes: "    }/8^.};",
    descriptions: "9 ş6,5>{|@ ?||1618=六",
  },
  {
    codes: "        八6@二.9{.二0.85.^六",
    descriptions: "$ğı三:ı四 六=二8{/=#{+ % 八,) 1五]8 %1六) >2%八八 %&:三\"7 八ç|一9^十六6",
  },
  {
    codes: "        $#({.]#.\"&.[四.).",
    descriptions: "*五ğ9=七2六7",
  },
  {
    codes: "        &三4四.}9.[8.*<.@二",
    descriptions: ".ğı\\九ı& 6(_十<^;七3( % ?,3 五'^: '0\"9|:@0( 9五(':+ 'ç'?>'/^$",
  },
  {
    codes: "        6十五\\..7.'八.[,.])",
    descriptions: "三四ğ]|6=62",
  },
  {
    codes: "  ,#+#.(]",
    descriptions: "| +$五_ ) ş3(^+^]/ ^+0@#七6+0 (?+)9九一 _ı\"|] @\">%11@\">;ş, %ı=@一",
  },
  {
    codes: "      *四_十./?.%6.@;.}7",
    descriptions: "|ğı:二ı九 5:六<#1__)\\ % ),9 ]七$+ 2五)2 :(52九 |%;#}. 五ç^二.[7'@",
  },
  {
    codes: "      6$1@.93..7.)3.<9",
    descriptions: ")六ğ>$:五(>",
  },
  {
    codes: "      =$八#.}..二6.8八.(#",
    descriptions: "?ğı;6ı{ \"六$/三二+(@1 % 七,二 *1三5 >+90 八三]4( 八^@八^/ 4ç=<0;5&3",
  },
  {
    codes: "      二{50./9.\"/.,;.@$",
    descriptions: "\"七ğ1*1:?/",
  },
  {
    codes: "  /=?..七?",
    descriptions: "八%ğ54 0/'@+'二@1 (二(0&4. 'ı,,^ @#二五4八5?2一ş, 9ı8/% ç\"](五$5ş)",
  },
  {
    codes: "      _[0+.$>.#;.\"六.?%",
    descriptions: "2ğı|>ı) 一]7&七]]八四2 % #, @ 四51= ..五9 0%%五1 九{三[_九 九ç4/七四+四#",
  },
  {
    codes: "      &5(7.(一.&@.三=.:十",
    descriptions: "三>ğ)}[七#\"",
  },
  {
    codes: "      ]=一,.;5.二4.\\:.[六",
    descriptions: "6?{[ 八6八>[_",
  },
  {
    codes: "          :%五9.^(.)\\.六:.}七",
    descriptions: "九 ş三=?$一9} |二]1&4九",
  },
  {
    codes: "          9)=[.十#.'*.'_.一^",
    descriptions: "\\/ğ一|0|五(",
  },
  {
    codes: "          :\\;].>,.6+.;=.$四",
    descriptions: "' ş_):^四#三 %&^十7:一",
  },
  {
    codes: "          :{/[.>7.)=.;六.三6",
    descriptions: "二9ğ3{8%,6",
  },
  {
    codes: " ",
    descriptions: " - 十<&^0一3(. (|]五=_& _+ğ\\七 八ş'<七六]ş 二6六/ ş%{%七 八%7:九@1ş)",
  },
  {
    codes: "    =0|十.|1",
    descriptions: "'*#五ı \")|59 '^/_(;4|\\[三=2 四九$_ |二)一:六五|:",
  },
  {
    codes: "        *三八>.+9.一九.3/.九8",
    descriptions: "八ğı&%ı/ *+:一0^+4十; % &,/ 八)三? 8}八/ 二{(六^ ^\"';|三 4ç>八五6一八,",
  },
  {
    codes: "        ;二8_.#8.?$.4四.}6",
    descriptions: "'2ğ六|六8<[",
  },
  {
    codes: "        5*七/.*6.]%.99.:^",
    descriptions: "#ğı&2ı7 _6>3七二=$#( % %,\" |8<= 98二& 五十1四一 二五&:18 [ç)2/3,1(",
  },
  {
    codes: "        一>六;._(.@$.$一.5@",
    descriptions: "9(ğ;$4;;7",
  },
  {
    codes: "    八#|>.六,",
    descriptions: "@+ğ:九十227",
  },
  {
    codes: "      69三]./五.25.\"5.^(",
    descriptions: "<ğı,六ı; \\&9._|_2九\\ % 四,& #|七[ 三?([ 九一1九] [2^四5^ |ç@&3\\?十'",
  },
  {
    codes: "      三\\/八.八[.<}.六四.+9",
    descriptions: "'(ğ{+9$0\\",
  },
  {
    codes: " ",
    descriptions: " - #九ğ9+)七八]",
  },
  {
    codes: "      \\_#{..[",
    descriptions: "[\")^ı *\\<\\一 ü%ü(|=二三@六=. /四ğ60 7]<一$一 ş1\\$一 _[|3+_|ş 1_1. {ş[(,",
  },
  {
    codes: "        \\+|\".\\%.三@.六/._\\",
    descriptions: "\"ğı@7ı^ ^十{二十(5六1[ % >, 三 (%4| >&八/ _/#$五 $五<;#+ 5ç&十&@[?@",
  },
  {
    codes: "        五(+1.5,.六=.6一.九(",
    descriptions: "$七ğ,#|^/}",
  },
  {
    codes: "            \\+?七.3四..9.>一.六5",
    descriptions: ")\\#ı'(ığı ).一 }'.;'2 十< 十[_六'{%",
  },
  {
    codes: "            |;9'.'/.:二.0\".)_",
    descriptions: "%8{ı98ığı 七.( =&. .[五\" ;4四( _5&]2 ..#*+?^",
  },
  {
    codes: "            &.六8.)9.>八.'..五九",
    descriptions: "七+4ı0十ığı 7./ \\四.九;? +五 <6三;3'9",
  },
  {
    codes: "            @六+6.9六.]+.>6.+\"",
    descriptions: "6.十ı@\\ığı #.六 八%. 1'+7 *@0; |.6\\7 )8$|]<_",
  },
  {
    codes: "            4八=五.>二.12.80.四九",
    descriptions: "三<二ı八四ığı 十.4 九9.,六5 8九 6@七,|<三",
  },
  {
    codes: "            ].1..;二.2$.*^.*[",
    descriptions: "?八$ı91ığı (.[ 0&. %70\" +3\"5 4?%34 六=.^6^@",
  },
  {
    codes: "            5(,六.三7.>\\.八#.]}",
    descriptions: "%7@ı9二ığı $.% ^|.:?5 九& 一@,|十$_",
  },
  {
    codes: "            69?^./'.七七..].四.",
    descriptions: "三5<ı:{ığı $.) '0. _)|3 一/)十 ;%三2< ]五^_4'#",
  },
  {
    codes: "    一4>四.二_",
    descriptions: "六#ğ.|#/(*",
  },
  {
    codes: "        _二,9.<%.:(./十.6一",
    descriptions: "七ı$/_ 八七44]9+%;{ş 3+{{ ç二5{#1^ş (?\\}#{] ^)$96[ &\")ı7一ış) .十/$[六:",
  },
  {
    codes: "        _五七七.,\\.&1.0;.\"+",
    descriptions: "<;ğ_八[五二六",
  },
  {
    codes: "        ]5@*.,%.;6.十;.六八",
    descriptions: "九ı[39 7:/(6'=十\"九ş 六}<] ç八四[,=$ş (3\"四&>* 八九三:2_ 9#1ı九4ış) ,1}*0%<",
  },
  {
    codes: "        14)}./;._(.8[.十8",
    descriptions: "+9ğ\\\\#\"0七",
  },
  {
    codes: "+$.|#",
    descriptions: "(|45/ %'3二 %9'şı6'ı: ç$>*^七?$ +五八&;/",
  },
  {
    codes: "  {%[|.,\\",
    descriptions: ",二三八四:[?1ış (8$$>八{三8ış #'{6[ >>六6$ı>)",
  },
  {
    codes: "      *二六1.六+..0.&{.]_",
    descriptions: ")9==4 /十<六/十.'7 @) 3三十九ş &\\3# [,五 三八. '一4 7$ ,{@十_#9",
  },
  {
    codes: "          9=五五.@十.5}.四#.\\)",
    descriptions: "&38.二 {(^1}',5_ /$ 5[%6ş _一^九 1,= 七5. {四八3 \",] ;\". (>,( 3_1五/) <>",
  },
  {
    codes: "            ,8(<.3;.+].7:.{九",
    descriptions: "_1=2五 }_'九{@3&} /> 7.&[ş ;3/\\ 1,:二 九九 (* .$'26)九三 \\0\\1{ +4^/+^/",
  },
  {
    codes: "          $=:?.@[.8|._,.七2",
    descriptions: "六八七%5 5:*7_#十}: #4 一{2&ş 495七 { @$.7]) 0一二七七 {)\"九4=|",
  },
  {
    codes: "          8;<+.一4.十<.四6./八",
    descriptions: "}=)/八 四一3+'>5六, 7@ }){]ş 58(+ ^,* 40. |(4< (,{ 74. (/,一 &>26$) 4七",
  },
  {
    codes: "            0,二5.(七.79.四<.'六",
    descriptions: "9\\)一) _九$:=5;六& +% #1{?ş '=@; $,6( |0 (< ;^六&四).9 %=52} ,4*'6){",
  },
  {
    codes: "          [二^一.三^.六|.[六.|三",
    descriptions: "87七=9 ''.(8$9^5 ^} |\"{\"ş .)^4 ) ==.5^= '十1二) ,=8|三{.",
  },
  {
    codes: "      4\\?+.7七.+\\.@?.1八",
    descriptions: "五/8?4 ?)/;{?5[^ 二[ +./*ş \\);3 5,0 |<. 82} 七# 6(三六;二.",
  },
  {
    codes: "      三一)一.>1.<6.)\".<2",
    descriptions: "<\"$5六 ;5]{0.四8? 八6 _四4?ş '二./ |,} 0@. ]$\"< 3,@ #4. (.,= >2?&>) 8^",
  },
  {
    codes: " ",
    descriptions: "        |二一?> '{09二0# ",
  },
  {
    codes: "        %(|八.0).八<.47.;一",
    descriptions: "]8/(2 ,]&:\"十一9^ 七% =62五ş |3(三 @,|十 62 () 4[<十二)&五 三?}'} 265*二三五",
  },
  {
    codes: "      01:2.三四.二%.=\\.&八",
    descriptions: "^_'71 (;<;=@<_] ${ ]#1\"ş )2@1 > @七.9}# 七(五,七 ;(|四]一^",
  },
  {
    codes: "    (0_?.<}.七3..>.^\"",
    descriptions: "+ğı8=ı& .:五%三['@8* %1,# 三>8五 ,4|2 /<五四* 六+六3^/ _ç9%2<八>[",
  },
  {
    codes: "  2:{..五{",
    descriptions: "Ç;.十= ;]八 二八^.二;七ış &0*#5^/",
  },
  {
    codes: "      ()*四.7&.四7.七5.五\\",
    descriptions: "'2\\,* 1二十?[\\{{/ 07 ]0(8ş /;'8 5,8 ?[. 379 :) ]*八,0%6",
  },
  {
    codes: "        四5/{.}9.7八.''.四九",
    descriptions: "}<5三七 十八%四\"\"+#六 七& $}&^ş ]\"+八 七,: _一. 5一四| 2,\\ 2_. (=,0 ;)一九4) <'",
  },
  {
    codes: "          {{\\五.&1.65.五^.09",
    descriptions: "#&,/2 七7;二,;七/[ 三一 <?&.ş ,}|九 4,^十 3( (七 [16.4)&] 4=/$( >(++1.五",
  },
  {
    codes: "        二三四8.*+.7'.^(..十",
    descriptions: "\"<)'五 ++,3二?\\=[ 14 >791ş 2)一; ? 1/.:'' :;0$五 2|%48八六",
  },
  {
    codes: " ",
    descriptions: " - - ;ğı;<ı四 /<*==<8<)/ %七,2} 7@.4 八7一| >+2\"6 ^@:六} %1,*'[\"二 六_ 七<三+[:",
  },
  {
    codes: "      \"=一$.九二.八4.六六.)3",
    descriptions: "<三;九/ ?三86;9八?四 {十 >*六4ş +(八/ ;,0 2;. ,{% $< [}1#五;7",
  },
  {
    codes: "      9$:/.四|.@;.&/.&二",
    descriptions: "九>+/2 :*,1七;8\\] {\\ ';十十ş +(+; 九,三 三5. 七五/$ _,6 :9. (<,十 一),$2) (^",
  },
  {
    codes: " ",
    descriptions: "        99>九< 十?#/=八. ",
  },
  {
    codes: "        ,五);.九0.七).&\\.七(",
    descriptions: "=20十> ,十{;四)7(\" {九 <*>五ş 2:*五 $,[0 \"& (3 八,{=_):] 9三\\,八 $|2'=/.",
  },
  {
    codes: "      2)八四.一八.6|.6/.(+",
    descriptions: "%?1,一 *六#_1九?{_ 70 1+十六ş '\\$3 五 #?.{%+ 2|$\\) 0>|_%@=",
  },
  {
    codes: "    &_(四.2九.+(.[七.\\{",
    descriptions: "=ğı_2ı9 四^>5+三:=>四 % /,1 $:[3 ^.78 $|<1< /$二]<> )ç四7$3}(.",
  },
  {
    codes: "  _00九.>四",
    descriptions: "+8ğ1+ ]$' ;4<4<,=(5> :&九一63&ış 2^6;*#3",
  },
  {
    codes: "      五八二$.@}.+9.@8.:+",
    descriptions: "*=1ı\\], 2?一+二4%ış 一三八#,;\"",
  },
  {
    codes: "      '$12.十(.81.@?.+.",
    descriptions: "^/ğ#3十7|5",
  },
  {
    codes: "      \\\"#0.?七.#'.五&.十1",
    descriptions: "一_4?7 '五[_^四81_ 七6 +八九|ş }%{二 九,一 六\".(<) [二 ^+|0/3二ı4 8=2ı1二' 8_3一5,%ış",
  },
  {
    codes: "      七${#.8/.'五.六9.二*",
    descriptions: ".>;;* ),6;|=\\\\% 6十 /[四_ş ]|[三 .,( &2 #八9七 +,六 7'. ($,} 0@>1四)&2 #2;&1",
  },
  {
    codes: "        <$三[.+9.\\#.'<.|'",
    descriptions: "二六40$ 五四/四/{{九[ 46 ^]^\"ş <二== ),四五 3/ (八 #$7'{)7$ 9],>/ 60']二9#ı{",
  },
  {
    codes: "      4?_..'二.&#.六六.26",
    descriptions: "?:]8] {九#7?'#5( }? (二六5ş +[*# 六 ?)._五0 十.五一一 +^]+6[7ı# ,(五ı4([ 五五0五<1一ış",
  },
  {
    codes: "        七{&$.?8.\"\".七>.'4",
    descriptions: "=@&|八 ^|$三四四&五: ?; &/?七ş ]七.七 0,四 [(.}一. 99 ^8>93{十ı$ 9'ğ}八 1三{ 十九七>(",
  },
  {
    codes: "      )]3].1;.=_.)一.5(",
    descriptions: "'|3一* ;十七七一五六六2 84 3>);ş :十__ &,& 九8. ]*八> +,, 7). ((,# :$%九[) &,",
  },
  {
    codes: "        三7';.%].:}.五+.]三",
    descriptions: "[050* 1:^>六6#%| 十[ <*8八ş ^十46 <,\"? ** (八 |>[}2)'@ >7?&七 >.)六%/2ı^ 2#ğ29",
  },
  {
    codes: "        {5三_.|{.2五.(#.6\"",
    descriptions: "[一8七六 _2六$<@?二& *2 五1&6ş ^8|^ 2 ]7.)74 \\{*\"/ >五(7*?五ı0 %十ğ三/ 2<| )_十5#",
  },
  {
    codes: "    #,.:.2\\.#%.@:.十,",
    descriptions: "#ğı一三ı二 /2\"一^%=1/^ %.,) 一:七@ 五四() 56*九$ ^0^@{0 <ç二/;1一/七",
  },
  {
    codes: "  九9:=.六]",
    descriptions: "28ğ1(_<:$",
  },
  {
    codes: "      >3:(.三/.十五.六'.七$",
    descriptions: "=65\".$] ?\\.一ı |}\\{5一/",
  },
  {
    codes: "      )三(:.85.2'..%.)\\",
    descriptions: "@)ğ一}&*\"%",
  },
  {
    codes: " ",
    descriptions: " - - (ğı3|ı十 4九79$#)>|: %4,78 二九一] ^;>] \\三1六[ +[\\=四 %8,9'5&. ;@ 1^|572 [ç.'?+四%一  ;",
  },
  {
    codes: "        \"&七#.>'.八六.#'.一\"",
    descriptions: "六九|$< \\)8(3*|25 5& @<{:ş &+/< ),& <,.'6${ 四. >?\\9一\"/ı* 十(1十@,(?1 @3七",
  },
  {
    codes: "        _{{5.六}.五七.6,.%{",
    descriptions: "8八]}* ='<20/三二六 \"& :395ş 八8%/ 8,\\ /%. \".(] 八,| 七9. (&,/ 四$#十:) 六# 6二",
  },
  {
    codes: " ",
    descriptions: "       +*, 4九^^七:0ı七 $,九>(=一#@ '?四,}*六ış /六$七十五2ı",
  },
  {
    codes: "        ,七8十.六}.$[.30./九",
    descriptions: "]一0五/ ':三五+5)一八 /七 %<?七ş 6八|@ <,;  8 七,. ($ 6{2%六) ?] *&.'< ?/?]\")*ı?",
  },
  {
    codes: " ",
    descriptions: "        %二三]八#;\". 6=#;)31ış =:$\\,三八ı",
  },
  {
    codes: "      )0\\&.(六.(+.*<.六2",
    descriptions: ">=87, \"#[}4^一十< 二} 九@}2ş 七;+{ 六 '>. \"&; 六一|6十 >{*;五?七ı/ +\",5+=七[[",
  },
  {
    codes: " ",
    descriptions: "        八99三,九3ış %|[\"4;4ı",
  },
  {
    codes: "        6&+4.六九.]=.*1.九=",
    descriptions: "4]#5: >}%*+#五=$ 四@ |'})ş :@(/ ^,四 <<.'<十\" */ '/^/:]*ı四 ?七ğ=5 二1>\"0%九5",
  },
  {
    codes: "      6?三^.八/..[./七.)五",
    descriptions: "六),4十 (十;*\"三3$7 六| 5<8八ş 五.六3 /,= }6. [6.$ ',' \\{. (六,_ [&8\"一) 6@",
  },
  {
    codes: " ",
    descriptions: "        186(* '_72\\一四ı, ]1ğ5[ 3四7三一$28:; 9.=^五八0ış 七六?五九}1ı",
  },
  {
    codes: "        十一{2.六七.{3.^二.(9",
    descriptions: "(&/[7 {三|.\\=24| >] 十:四[ş 3''四 0,)7 /|. (五 {三_九四).[ 九2二8九 五&8|,=十ı6",
  },
  {
    codes: " ",
    descriptions: "        {>ğ]九 #;_5]79七+, 一#[^>*\"ış ]24#?\\/ı",
  },
  {
    codes: "        七=&九.@[.七九.17.^7",
    descriptions: "十,9九8 4+#^二^五四\\ 九> 4&,,ş :二#\\ 6 4&. \\3| 三/}{\\ 二{3八0_/ı| 8/ğ{= 八:%<0",
  },
  {
    codes: " ",
    descriptions: "        ]&%^2 4%.?一]@ış 9<>;/_)ı",
  },
  {
    codes: "    %)<7.:?.十9.7@.九9",
    descriptions: "五ğı_二ı# .一')'十%三0( %},# \"_\\@ *_|5 7@+*& ^}6_,$ |ç))?9._%",
  },
  {
    codes: "*六.[(",
    descriptions: "三ü/ç< >^+% 41ğ5\\ ,'二 ş六二*(>]_'[ 7%8,七十六\"6 ç.46<; 十8|4一6\"^) ç六<.'<0七",
  },
  {
    codes: "  .六<9.十五.6..]/.6八",
    descriptions: "*ü^ç| 五{^+ 五六ğ^\\ 7二+ ş\\#'十;9^十8",
  },
  {
    codes: " ",
    descriptions: " - #\\ğ$)*3三2",
  },
  {
    codes: "    ;5'..十^",
    descriptions: "60=8/ :|*,2> 55;:ö$7}%* (二'7& 六六九五ç) ş五五三'三四} 五)7+@}'",
  },
  {
    codes: "        十'$:.<一.'>.9].十四",
    descriptions: "^四5?3六_99?ş ,:., ?ü6,|@@ =ö4ü' 二+]:9_+}? .十9四 #6三{=2ş",
  },
  {
    codes: "        5一四%.*5.<?.;>.:7",
    descriptions: ".ö1ü/5üş",
  },
  {
    codes: "        33]四.=5.&*./,.1#",
    descriptions: "三_三)六三|\\=六ş 四?,) =ü九]})( 5ö[ü一 (\\%8,)};9 }\"7< .]_七九8ş",
  },
  {
    codes: "        }]92._,.二/.?_.7五",
    descriptions: "二ö九ü]}üş",
  },
  {
    codes: "    7}三<.+<",
    descriptions: "(3ğ7<')}_",
  },
  {
    codes: "        1*|(.+&.7;.6+.01",
    descriptions: "%6363<@:70ş 八8*0 六ü四一**9 )ö5ü九 ?六@:[)576 1一0( 9(;6=)ş",
  },
  {
    codes: "        {二11.+#.十0.3$.十1",
    descriptions: "@ö5ü}'üş",
  },
  {
    codes: "        _二8[.八4.06.35.%_",
    descriptions: "16/%6'_6=(ş :|0\" ]ü}六十四九 3ö8ü{ 7/;.8#6[* 二三九/ .八;?九_ş",
  },
  {
    codes: "        八?$七.\"/.:九.)9.3=",
    descriptions: "$ö\\ü@<üş",
  },
  {
    codes: "^_.九(",
    descriptions: "={/+;八九}& ç四#1'+&[ 六,\"|ı ,_/>7 2^5\\4+^7= (九4){ş%,ğ四 58: <\". >二;?",
  },
  {
    codes: "    一5一^.&#",
    descriptions: ":8六ı_,ı1&4九ı [一 十7. =? :一ç二八;,^",
  },
  {
    codes: "      .#^+.;(.75.;:.0{",
    descriptions: "59/ >%\\]+ğı",
  },
  {
    codes: "      [@三:.八六.6].七=.|3",
    descriptions: "$|ğ%&四__$",
  },
  {
    codes: "      7^+3.2<",
    descriptions: "三)三ı(6ı/@|<ı 4, .* #五. ;//{ 八&(. <>&8, '<2(# )八 4六. ): <八ç\\|",
  },
  {
    codes: "        (}八3.8<.一,.&6.九_",
    descriptions: "+十0 )七93;ğı",
  },
  {
    codes: "        }.<3.\"}.4{.(7.@六",
    descriptions: "@}ğ@六'}\\5",
  },
  {
    codes: "        [4.五.九3.[六.2>.}@",
    descriptions: "四九9 */一二\\ğı",
  },
  {
    codes: "        八7_四..八.'(.==.\\1",
    descriptions: "8|ğ十六&二6^",
  },
  {
    codes: "    5,_>.九八",
    descriptions: "十八1ı}(ı?四33ı 六 三四. 6八6% [?^1 </4+6 *:1^# $, [: 9(. },七 2; 三八}六.九7",
  },
  {
    codes: "        +七八2.}].+].(1.=八",
    descriptions: "20& 四六三<1ğı",
  },
  {
    codes: "        ')\"[.8三.;4._\".\"+",
    descriptions: "四^ğ80,/一\"",
  },
  {
    codes: "        @五2七._0.{>.:七.4#",
    descriptions: "\"|9 9八2一5ğı",
  },
  {
    codes: "        8}%1._'.36.$}.&'",
    descriptions: "<=ğ2]>三\\三",
  },
  {
    codes: "    ^4+7.\"0",
    descriptions: "\\\\2ı83ı五)}二ı $ 五5. %[4 \\二 ;9十五=}_",
  },
  {
    codes: "        6}5|.|(.(/.\\\\.04",
    descriptions: "5/4 八{5>(ğı",
  },
  {
    codes: "        /&7,./,.{%.=^.九?",
    descriptions: "]七ğ4<8三/\"",
  },
  {
    codes: "        8}四%.}三.\\3.九七.42",
    descriptions: "_:0 @四[七#ğı",
  },
  {
    codes: "        //;2.&^.5+.4七.]=",
    descriptions: ".八ğ]4九=>|",
  },
  {
    codes: "    二+四2._>",
    descriptions: "五五|ı三十ı^$,5ı ;( 一二. 八四 6>ç['?_5",
  },
  {
    codes: "        |8&+.*7._二.六7.=/",
    descriptions: "*/6/)<",
  },
  {
    codes: "        8|七$.+=.)?.1四.三+",
    descriptions: "]=:.ş 6六8}七''5",
  },
  {
    codes: "        ;'3(.6$.?[.;1.八$",
    descriptions: "9八十^\"%",
  },
  {
    codes: "        >3=}.{六.5七.\"四.}$",
    descriptions: "8二@:ş 四?44=七'0",
  },
  {
    codes: "      @?<:.+八",
    descriptions: "|'0ı=一ı?^.&ı 四,*4 *[. @{&4 $%三# 6十9?^ ;六#+6 ;二 ^四. $' 1'ç七,/9/",
  },
  {
    codes: "        @5六\\._六.9{.,4.12",
    descriptions: "^|9<*/",
  },
  {
    codes: "        7?^三.六*.*#.5@.[/",
    descriptions: "五1四三ş *>五八六'(0",
  },
  {
    codes: "        >5{=.14.六5.<;.四/",
    descriptions: "|二@(#)",
  },
  {
    codes: "        四\"9>.(=.;,.)十.二[",
    descriptions: "9<四+ş [@%十#&\"}",
  },
  {
    codes: "    \\十.七.$0",
    descriptions: "2三9ı7;ığı > =/. >&<七 /+([ 8&6,5 十{四六4 一,^, 6$. 二一( ;七",
  },
  {
    codes: "      _九七5.\"&.9?.;2.十4",
    descriptions: "(七?\\@\"",
  },
  {
    codes: "      ^九\").八%.[七.@'.)_",
    descriptions: "*二0一ş #<%#六\\二8",
  },
  {
    codes: "    }五.三.九@",
    descriptions: "5?_ı&(ığı 6 }}. 0/. 四九 }92&8)@ ",
  },
  {
    codes: "      七@六{.5三.=#.=五./%",
    descriptions: "5.七|?二",
  },
  {
    codes: "      1三\\$.9二.<_.@一.5四",
    descriptions: "4九:$ş ^+\"五*0/9",
  },
  {
    codes: "    7八+@.六:.十六.)[.一:",
    descriptions: "4二>ı$.ığı 0,三九 8,. }$+^ \"=]< 8.'8九 5{{五.四六",
  },
  {
    codes: "    {\\?_.[六",
    descriptions: "五1_ı_\\ığı _ >?. 12八七 6$二^ 七[>[? /三84* \",8& 十/. 一(= 38",
  },
  {
    codes: "      '\";六.十{.四?.&|.*:",
    descriptions: "十ğı>6ı[ =九%56(1)九$ % 9,= @;^七 {7%& )?&*> 1%9,) 8ç7一[8|8?",
  },
  {
    codes: "      0七_).)三.7].=/.十_",
    descriptions: "九ğı,]ı< &.1::}十二1三 % 8,9'八)一 '九 /%{0九 十ç四;')\"\\+",
  },
  {
    codes: "    七|91./,",
    descriptions: ")2=ı四1ığı 3 =十. +六 6七ç七6 十=),| 十 \\<. 一(' 8八 三}^{五;5",
  },
  {
    codes: "      <十;..八八._$.,=.\\[",
    descriptions: "4ğı2>ı; 4+=\":.%_@3 % 六,9 六+?九 {一9< @=7?\" 7/,3# .ç>@01[,=",
  },
  {
    codes: "      }3四七.8;.*).5,.0六",
    descriptions: "{ğı>四ı@ ^四\\一6七3>') % @,)'@九7 .@ 七三)\"' 六ç一^{;|1\"",
  },
  {
    codes: "    ^;(,.##",
    descriptions: "3+]ı4八ığı ;,^ ^=. ;,>' ]一\\; 五,8六> 4+4+? 8 四八. [三",
  },
  {
    codes: "      9@,..$四.三3.八九.一一",
    descriptions: "四ğı#@ı@ =二&.[7'}{] % 2,( 4#0[ _+[) #8{%& 八^;>十 3ç'二@)=1|",
  },
  {
    codes: "      三/1|.07.3%.}3.>5",
    descriptions: "8ğı4|ı% {86[<)3[{/ % \\,0''^7 二{ 1:九五. 二ç6.8二{五.",
  },
  {
    codes: "    八5十_.95",
    descriptions: "25四ı}1ığı 1,* >=. 一[& ^] <1#_\",&",
  },
  {
    codes: "      ^17\".7九.0(.>{.二二",
    descriptions: "1ğı+*ı| [#四]3.2四75 % 五,# |九\\% 9六1> +(:\"_ 1=><8 十ç/{一%4)8",
  },
  {
    codes: "      \"&:*.'?.七五.^一.七@",
    descriptions: ".ğı&>ı# [#0:@:#>9: % ,,('>,十 $> })九2] %ç+3七_:十]",
  },
  {
    codes: "  十8.=..[",
    descriptions: "5}ğ'?^=&)",
  },
  {
    codes: "    46九六.%\".六=.|6.五三",
    descriptions: ")=二#_4,ş (5]9[}68 <$=]%9ş) 九十>三二\\9",
  },
  {
    codes: "      =<17.9<.09.'二.4九",
    descriptions: "?\\(?二. 二ü八)' |şç1>三ğ]十7 &:4< 十\"56=九&ş (;:一=&# (6十>6) :+六6 =)3,ö<(2}&.2[",
  },
  {
    codes: "      ${#0.四[.',.|五.$三",
    descriptions: "<;ğ四/{九0=",
  },
  {
    codes: "<$.<;",
    descriptions: "4&6#?01(8 ç%6六7$|} _+四&ı 1={七) 8\"\\_]9七=2 ({/4@ş4八ğ+ 8>; 二四. 5七^ #&",
  },
  {
    codes: "    @$,/.}6",
    descriptions: "5*四ı$(ığı _, \\8 2'. 2.&三 (二*_ [7&%八 %四,十三52",
  },
  {
    codes: "      <\"三^.%$.一十.=}.'=",
    descriptions: "'九] (一.7[ğı",
  },
  {
    codes: "      @+_..,%.八^.$2.$二",
    descriptions: "4836*|",
  },
  {
    codes: "      \\;@..四$.)十.:..#*",
    descriptions: "Ş一}七七6六[",
  },
  {
    codes: "    :#4六.%\\",
    descriptions: "|+8ı八(ığı 5, 八八 $). 2六^ ]} 三7^)+一^",
  },
  {
    codes: "      9\\\\|.六?.$六.(三.,(",
    descriptions: "8(< 7[+9:ğı",
  },
  {
    codes: "      四2;四.\\3.\"/./0.^.",
    descriptions: "五^>*8[",
  },
  {
    codes: "      \")1[.5$./二.@6.@[",
    descriptions: "Ş+*>十4^_",
  },
  {
    codes: "  ']八8.95",
    descriptions: "7.]\"五8 一^ğ:\" 四7(5:9.'{5ş",
  },
  {
    codes: "        ;<&).&)./:.%四.<十",
    descriptions: "{95<ş=三ğ2 &=0 8九.7\\ 8]ç^|\"</",
  },
  {
    codes: "        ;']\\.];.#6.}九.十,",
    descriptions: ",$.+ş=[ğ3 五{3 五五.2? =九ç94:七8)%5",
  },
  {
    codes: "        _+#}.}7.5^.^/._十",
    descriptions: ",二$四ş七<ğ\\ 三_$ 8+.[$ 三{ç四=6$0",
  },
  {
    codes: "        十18\\.9六.\\+.一/.)>",
    descriptions: "??:\"ş;}ğ6 >[九 @^.}] ^*ç\"[.1@*5三",
  },
  {
    codes: "        6#一五.=%.}>.}^.&四",
    descriptions: "9\"&<ş十|ğ六 \"&{ 4二.?_ 0%ç}%九十#",
  },
  {
    codes: "        7#)3.%八.#:.<0.9七",
    descriptions: "=3_4ş九&ğ五 >十9 ;#.'6 >[ç*['3([1,",
  },
  {
    codes: "        '0'%.:九.1四.7%.四}",
    descriptions: "$&五0ş#8ğ; 六1七 六@.\"8 :\"ç七7<^2",
  },
  {
    codes: "        二[>6.4?.$八.2九.九九",
    descriptions: "|6{]ş七)ğ十 <\\八 \"八.二{ 4一ç+]#('(=8",
  },
  {
    codes: "        \"十;(._=.{[.七?.[3",
    descriptions: ">9@7ş%#ğ9 @$= 九^.六} #\\ç}=9二=",
  },
  {
    codes: "        /;,\".七七.%'.&].1>",
    descriptions: "2}@}ş.>ğ十 =/( ;@.** 一/ç'<)4\"3.一",
  },
  {
    codes: "        $七#..5(.\"%.,:.%{",
    descriptions: "'十七5ş二1ğ< _(: ?*.#九 %3ç.}.8*",
  },
  {
    codes: "        ,}<9.[五.八{.{+.@<",
    descriptions: "四九:{ş六}ğ' %|八 )?./9 6,ç/'#&+]一\\",
  },
  {
    codes: "  ><?{.6四",
    descriptions: "八八ğ一'[3(\\",
  },
  {
    codes: "        >/四{.<十.?一.?4.;,",
    descriptions: ">\"%[@九 8ü$5七 ;şç[>@ğ:*\\ 2七32 &=\\8;|九ş (*5<八八@ +\"九}')",
  },
  {
    codes: "        _.^<..二.]}.>$.2八",
    descriptions: ")=ğ一#40二'",
  },
  {
    codes: "          144@.?/.];._4.%*",
    descriptions: "#ı二]] ?)^\"二?7.五0ş {四=一七[ _](三<; [4^ı=四ış",
  },
  {
    codes: "          九_/(.}?.1(.@)..3",
    descriptions: "=?ğ六3七_][",
  },
  {
    codes: "        \"七33.三六.6\\.1(.;\\",
    descriptions: "[3ğ(\\[^{:",
  },
  {
    codes: "        4)4>.\\4.<*.(<.[%",
    descriptions: "_&{1@3 3ü七二/ $şç]8%ğ%_[ <|1$ 9八^4一*$ş (+&一\"&* ,['6十)",
  },
  {
    codes: "        .=@%.五.._六.\"..三'",
    descriptions: "3\"ğ\"@6:八[",
  },
  {
    codes: "          八|^/.五二.#五.{八.一一",
    descriptions: "1ı<四| 4五*3/,6>8=ş 七$十0%3 ,.*三五^ *.=ı1&ış",
  },
  {
    codes: "          8八,@.9>.{2.五%.}+",
    descriptions: "1_ğ十9十;九七",
  },
  {
    codes: "        /七*九.'四.17.|#.1五",
    descriptions: "6/ğ_(]=:三",
  },
  {
    codes: "816^.六*",
    descriptions: "#90+1_,;\" ç九七7八\"?十 >八六二6ş八} (九ı十]五 十&+%1四9^):ş, 五;;\"$9",
  },
  {
    codes: "  六@[五.;@.三七.七:.47",
    descriptions: "<ğı..ı, 1>,.[二'六:九 % >,二 ,一$) 四8}* 七三'?5 八十_]. 9ç>&72,_|",
  },
  {
    codes: "  79七2.0?.1#.#十.七四",
    descriptions: "四ğı55ı_ 6/+,\\)43]_ % <,[' :6= ;5 9]}\\+ 1ç>40,三一5",
  },
  {
    codes: "/<.?}",
    descriptions: "7]九1#二%{二 ç7#$9)|+ ç八}9. 6\\ 三\";/八8{五=",
  },
  {
    codes: " ",
    descriptions: " - {0]0&7 :ı9[_ 2()'0&\\;&四ş 四\" ç)}%七#;ş ç十二8[(._",
  },
  {
    codes: "    (二&^.2=",
    descriptions: "七@23* 1|@>)\" \"]8$) ş>五六|^七< (,七七?6?",
  },
  {
    codes: "        九}08.&[.{6.9*.六\\",
    descriptions: "[ğı}5ı/ |.7*七二@一八十 % 七,( )九九[ 十#?( >]8九\" _+三四1 \"ç+?_&.*%",
  },
  {
    codes: "        八十^=.<\\.7{.*0.19",
    descriptions: "6ğı(<ı九 =?十(&1二):/ % [,@'七}十 22 }^;3* 2ç七;&:,六3",
  },
  {
    codes: "        五&,四.=].@1.六7.\"/",
    descriptions: "八ğı/,ı, ^9{}:8>)+? % 0,三 '<%( 九>&: 八一<2{ <8%}1 ?ç?#四\"+七*",
  },
  {
    codes: "        &六]_.三..]|.[五.\\%",
    descriptions: "\\ğı十;ı: 五3$9\\?}[@五 % 六,('五%+ 8; #8四;\" \\ç^#{(,六=",
  },
  {
    codes: "    九(7&.3\"",
    descriptions: "&0ğ'%21$五",
  },
  {
    codes: "      八(2一.=6.:<.六%.9@",
    descriptions: "十ğı3\"ı\" 一0,.0一5九;七 % ),^ :#61 8$2十 一^1^十 {#)30 )ç04十8}5%",
  },
  {
    codes: "      <^{,.$|.十7.{%.\\四",
    descriptions: "1ğı六四ı< ;@;四六)七;|六 % 十,7'5七@ \"% ]4二四十 )ç0\".1'/\"",
  },
  {
    codes: "  {*@*.<'",
    descriptions: "二七5\\四: 8)ğ.> 十二|二;; ş:三=四 十:=(_^'ş 八0|7 (ş&3十@一ş ç二93{7,五",
  },
  {
    codes: "        {&&<.九^.5三..*.四^",
    descriptions: "/ğı六$ı| :38}63@七}< % 2,; /%): ^%3& 01八6] [%\"'4 五ç\"93_964",
  },
  {
    codes: "        .六...4\".=4.三(.\\\"",
    descriptions: "四ğı0<ı四 %$*#\\}6}@) % [,<',.五 四2 ,;^+三 'ç:_#四:,;",
  },
  {
    codes: "        *一\\7.>三.;3.);.{/",
    descriptions: "3ğı七#ı( |八9,/九7($$ % %,$ #%}4 ?\"6\" {:)二2 >08|六 ;ç二&{<六(|",
  },
  {
    codes: "        ;)\"@.@八.&%.63.@;",
    descriptions: ".ğı),ı2 \\'&%0.|四|? % .,5'十00 ^7 [@_三: 四ç<<六.<%三",
  },
  {
    codes: "        四\"九4.)#.?<.1..)一",
    descriptions: ":ğı.&ı7 四<?.一%7(5二 % 7,4 29{= @/,4 *.&1二 四):]| 二ç'七&/1三&",
  },
  {
    codes: "        四八;8.7,.八].三5.==",
    descriptions: "9ğı/#ı{ :<$][:九0六; % 8,*'|6七 '9 \";>+3 @ç二>十=/+一",
  },
  {
    codes: "      &十$2.1六.,八._六.%十",
    descriptions: "'ğı+{ı] #<_'=8..七( % 六,七 39*0 |/+} _{\\(_ 五<一=: 九ç691>(,|",
  },
  {
    codes: "      四?七:.<三.\\{.12.*/",
    descriptions: "七ğı38ı2 },^@五\\;十3> % {,九';+四 :\" [>%=, |ç?&01,8二",
  },
  {
    codes: "  4十%@.7?",
    descriptions: "1二ğ=四 ç^7_$四\\+",
  },
  {
    codes: "      /六9四..;.:五.6十.2.",
    descriptions: "+ğı)[ı一 @四四%八十@#', % 1,} 一2\\八 七'7> 一*:_@ ]1%4十 >ç_&五(五1<",
  },
  {
    codes: "      @}<}.,_.\"{.5%.五/",
    descriptions: ">ğı7八ı# \"8(]\"四,/_\\ % 9,['+<: (} 9?<}? ;ç(?'3一}五",
  },
  {
    codes: "      69十#.\\/.十].六十.+*",
    descriptions: "八ı#|5 };=二<65十,(ş, ,ı%*( ç[>六>:;ş, ;9&_十] 4&4]:? +{8)/#;;9\"[ 八九:$ ,四[&)66ş",
  },
  {
    codes: "      ?51;.五九.九6.\"{.8/",
    descriptions: ".\\ğ七三)''=",
  },
  {
    codes: "  _一五九.3]",
    descriptions: "_|};|#$五七",
  },
  {
    codes: "    &}=5.27.五\".::./二",
    descriptions: ";|六';0 (ı.@[ 五\\?八]7\"?}四ş 九{4( ç三*4'4&ş",
  },
  {
    codes: "    $;八一.$@.#|.8\".一5",
    descriptions: ")七*[.= 3]ğ二) 六七5九1十 ş^?_( 4二832九十ş %'*八 六$九49二二2&ş",
  },
  {
    codes: "      9++8.((.8'.>#.3*",
    descriptions: "]ı.]八 [七3五,}:十#?ş, ?ı{七; ç\\6{85八ş, 四五7/7/ '];=^' *一:}四2五#*5* [4三\" {%,九一\\'ş",
  },
  {
    codes: "      *9{=.^九.;3.1(.).",
    descriptions: "6(ğ1[)*三'",
  },
  {
    codes: "2}\\二.四0",
    descriptions: ".87+%*59$ ç]=)>7'/ 4?五'(十",
  },
  {
    codes: "    :9十3.>>.十4.{5.六>",
    descriptions: ".ğı'三ı| 82*?>/:?%{ %)$ 十九二# 9^]2 .1[}(,]八:\\& %$%'7+] 1七9[3 6.|4{十{ 9十)一'",
  },
  {
    codes: "    *6七十.十三.一_.十8.;@",
    descriptions: "4%ğ@'1@^;",
  },
  {
    codes: "    二*'一.六%.0>.^[.)一",
    descriptions: ";ğı五@ı2 \\&(+7(1六<= % ^4 \"<$十 &}4八 %四2十\", 8#8^九 % 86'(一& (+'>& %2+\",4& 六'#_",
  },
  {
    codes: "    十十>4.;一.?二.六8.$(",
    descriptions: "1七ğ#*.1]2",
  },
  {
    codes: "78.49",
    descriptions: "2ü(ç] @<^' *2ğ>' 5四{ ş三8*/\\十@.> *七ğ+四 一.四şı7\\ı ç81\\六三%&; '七ğ&1",
  },
  {
    codes: "    8九6;.}).^八./8.八六",
    descriptions: "十^7ı} ç(4@ğ[=(*2 一|六七]:?",
  },
  {
    codes: "    二84八.三:.+^.$,.}}",
    descriptions: "]&ğ98四^=^",
  },
  {
    codes: "  );|>.3七",
    descriptions: "/;ğ]^(十+7",
  },
  {
    codes: "      3>4:.81./..六@.%3",
    descriptions: ".[$:六 八8>四五] 8二=*ö>>'九8 ([0;2 }0/#+) ş三9四%%'? $#_?.9四 八){/ +ı4)5 ;七9@一^2+8:ş",
  },
  {
    codes: "      #五4^.#\\.?4.八..5)",
    descriptions: ":_ğ六?4九'5",
  },
  {
    codes: "            '(98.%?.\\*.|八./八",
    descriptions: "5ü.%1@ }ı' ç5$|ğ,\\#%| 一49)'二二",
  },
  {
    codes: "            四八(一.6@.'6.:'.6八",
    descriptions: "三ğı(十ı? 四七二七/?九&>7 % 4,| ^六=@ =8六& 80 <一\"6]} \"2%>,8 七9+{ 8\"}四 @'7$十",
  },
  {
    codes: "            ,'八&.=五.{5.>8.6+",
    descriptions: "0$ğ#四4?>.",
  },
  {
    codes: "          _一>\".,十.';.+9.十二",
    descriptions: "5二ğ#5_*八=",
  },
  {
    codes: "        09四[.\\&.93.;四.2,",
    descriptions: "+ö5ü\\=üş",
  },
  {
    codes: "          3#76.$*.5..#>.:/",
    descriptions: "(ğı[九ı( \"'8?18>?]] 57 >' % [,三  ;@ ?\"[67 十,{_ %+]2+;,?一 #' % ^,9 }六",
  },
  {
    codes: "          8/23.>#.'9.八五.七&",
    descriptions: "6|ğ}/{;'.",
  },
  {
    codes: "        五$9七.二).{=.%2.六#",
    descriptions: "[ö.ü6六üş",
  },
  {
    codes: "五3.[_",
    descriptions: "%三ğ\"5 _.一şı八7ı ç9;}{七\"; 六6()ı =一/1] 七{3五九|\"\"[ (4二,^ş^/ğ2 \"<) 三#.+?{八",
  },
  {
    codes: " ",
    descriptions: " - ?.%<9)1十|; ]'0./)^+ ç/十@[六6*{7< ",
  },
  {
    codes: "    八(?_.>\\.{,.五$.]六",
    descriptions: "=(/三四4+三 2ö9.12[|{1\\_|ş",
  },
  {
    codes: "    |七七六.;,",
    descriptions: "//ğ\\?4:$^",
  },
  {
    codes: "        一{一九.+@.十七.二;.\"'",
    descriptions: "93六 十:'ı/ı 九|四十*.<九五= 六,}@ /]十 66 51|] 九@ 七&四 2?6ı}ı 4八<]%<一",
  },
  {
    codes: "        %一48./二.0\".四一.28",
    descriptions: "14ğ\"#二{6.",
  },
  {
    codes: "      <八七5.19.&>.*8.6二",
    descriptions: "=2ğ0: ç^六[$@\"ş 七^0\"_]+",
  },
  {
    codes: "  )(57.9五",
    descriptions: "/七ğ78}%() (4912)' 3ı六<| ]';@_\\5*三八ş) (^\\;\" 二%.**4四)",
  },
  {
    codes: "    .十九:.|^.9].+@.2一",
    descriptions: "*]_ı+ ç\\*八ğ\\^46_ \"\\&六8|8",
  },
  {
    codes: "      十9六'.+_.;4.</.一<",
    descriptions: "3@四|/[ _@%]20'八.&ş; ;)4:^五 (ü#?8 八şç十六?ğ0一} *$九4 5$__三79ş (十6二9%' *七五=>) 三&:七",
  },
  {
    codes: "      :一三7.;_.十%.,:.十1",
    descriptions: "八6ğ四}八+)\\",
  },
  {
    codes: "      *)}4.%三.%+.七%.@一",
    descriptions: "三?#3二/@+%ş )二^九五)七",
  },
  {
    codes: "      四14;.|@.94.}/.,(",
    descriptions: "/四ğ|@?:>|",
  },
  {
    codes: "  5}.9.4_",
    descriptions: "<<ğ五#六八1_ (七十=),) 8ı8). &.9707@$@\"ş) ([(7\" _[=7三3, ,@二十_一[>7$)",
  },
  {
    codes: "    [2.三.,\\.12.[%.三=",
    descriptions: "|,二ı} ç#三*ğ<{\\\"= }\"三)7;%",
  },
  {
    codes: "      6=|@.六十.三).^四.@*",
    descriptions: "2六{3八4 }^二九%{_2六?ş; #四6四;@ ?ü=<2 七şç:一)ğ/六三 [>8\\ 93&=二五三ş (<3#'三五 38一3|) 七<7@",
  },
  {
    codes: "      6\\\"九._>.+=./|.4>",
    descriptions: "3八ğ^{1十/)",
  },
  {
    codes: "      }二._.7九.六+.*七.九4",
    descriptions: "<^]ı4<ığı ^六 <六. 2\" 九四ç=5(十;",
  },
  {
    codes: "      \"八;6.1$.@十.四8.十八",
    descriptions: "0四八ı5@ığı |,二_ /0. '\\6: 一.九_ \\^六\"& >?>@+ =9 }|. |7 ^十ç92,二/@七@",
  },
  {
    codes: "      七三2四.;3.<<.九3.\"四",
    descriptions: "]/{ı||ığı $,\"八 0\\. #6] =\\ 2#313>2",
  },
  {
    codes: "  1九#;.<八",
    descriptions: "21ğ八七二8四[ (\"6(_>2 /&ğ=/ 1(6@'三九<>4ş)",
  },
  {
    codes: "        _5[#.|)..{.2&.&5",
    descriptions: "7十'[十\" *0>(三0十#&*ş; (三十(}} %ü#三4 :şç@十<ğ5四0 58一[ $,'^\"4}ş (@七'+%五 ?\"2??) 五@8五",
  },
  {
    codes: "        &3,:.'七.五^.$=./[",
    descriptions: "'3ğ'?4=四+",
  },
  {
    codes: "        七5(9.十&.34.^;.55",
    descriptions: "9七==6, :2460_五;28ş; |.<4_@ ;ü#六四 @şç;0)ğ:#0 &九*( 0@\\六5二9ş (4??4七二 八八&,|) _36+",
  },
  {
    codes: "        ^<%二.|4.2..['.',",
    descriptions: "1?ğ9,(6五4",
  },
  {
    codes: "      一_^^.一四.)).8\".*九",
    descriptions: "四7\"ı#=ığı 一六>. 1:]> *85六 [\"八\\} ;,_%2一^",
  },
  {
    codes: "      4五=\\.?{.|六.八六.四9",
    descriptions: "=<#ı2:ığı *1,. 七四六 ?& {六六3{八二",
  },
  {
    codes: " ",
    descriptions: " - 7[ğ(1)=;#",
  },
  {
    codes: "    0,$,.;.",
    descriptions: "6)>#>76/七4&\\ 6#+六8三 ç六{@1 八&8 \"二$/]_(ış [)7=三]'",
  },
  {
    codes: "      |\"二六.四'.四:.六&.十/",
    descriptions: "2@?.=7 &ü_五. \\şç][六ğ@3' ,6#4 七(八>_83ş (/三[704 0#\"\\一) <38$ 2[^3ö九,%2%一十,",
  },
  {
    codes: "      八?三4.]}.8#.%[.;一",
    descriptions: ".$ğ\"''&,三",
  },
  {
    codes: "    <4,*..2",
    descriptions: "_<ş九{ }十= 六8.>一^ ç五<&七 <@& _#22六5:ış @/^87..",
  },
  {
    codes: "      3<]4.四..<>.#,.五%",
    descriptions: "?)|;6^ 1ü;;二 \"şç+5^ğ9八^ 二.]) \"<\\_6){ş (/7$五)+ =/62二) 三9九三 _%:=ö三九五^\"6\\9",
  },
  {
    codes: "      |%/$.九@.,四.95..]",
    descriptions: "96ğ>87537",
  },
  {
    codes: "    .八0%.三\\",
    descriptions: "/=ğ,[91八十",
  },
  {
    codes: "      0;@{.四|.]1.二:.}#",
    descriptions: "三/3\":% ]ü{<六 ;şç七+|ğ|.6 5十二> 1^八五\\:0ş (十32+.( 6一=%6) 六\\|/ ?(,)ö[]8#3\\2九",
  },
  {
    codes: "      \\1:{..九.四三.(十.:#",
    descriptions: ";\"ğ)八)三7|",
  },
  {
    codes: "@#.}>",
    descriptions: ",<ğ六7 11二şı@$ı ç^7%(\"0三 二'七&ı \\+4;& <(八:%>129 (三8}(ş1六ğ/ @++ &5.",
  },
  {
    codes: " ",
    descriptions: " - 五59._九6.\\5 :四8一六七?十 ç+9(8,(十\\|七 ",
  },
  {
    codes: "    3[七7.(*",
    descriptions: "三&]一1|(_ ,ö五.>{一一6%@十)ş",
  },
  {
    codes: "      {1<6.(7.]九.一1.@#",
    descriptions: ">\\?四ş三.ğ十 四,] @_.,8 28ç4#23&",
  },
  {
    codes: "      \\)53.*:.三九.二0./(",
    descriptions: "*:}0ş)7ğ' )=^ :>. 5= :5ç3>#五二)三一",
  },
  {
    codes: "    二七);./#",
    descriptions: "(2ğ'二;{6\"",
  },
  {
    codes: "        八]7;.$四.^{.二八.2^",
    descriptions: "\\/四 <'0ı6ı 一9_(\">>\"7* 4,.五 <1十 =8 四|=5 8@ 九{| _五(ı3ı 6.$五2}十",
  },
  {
    codes: "        [(5九.9=....'\\.|*",
    descriptions: "九/ğ8__六}@",
  },
  {
    codes: "        8_)].}].三>.]#.#2",
    descriptions: "六三^[ş';ğ% .04 \"6.\\六 十\\ç<7.&四",
  },
  {
    codes: "        ?<@=.+^.<=.'六.8四",
    descriptions: "^,2<ş3二ğ三 七'十 0&. &? 0)ç=9>*:3_=",
  },
  {
    codes: "  [四1\".{4",
    descriptions: "'ü[九/' >ı8 ç<八.ğ一五&四)",
  },
  {
    codes: "      [八九%.$,.@}.0?.82",
    descriptions: "+252/6 &ı_2, .五1九@8九\\六(ş",
  },
  {
    codes: "        |十,0.三{.?4.;?._9",
    descriptions: ".ı=%. 80)#二1;4(1ş, &五%6{] 73二:三三 \">]]=?\\]9十+ ,|6? 四八}54五_ş ]一>>, 1_",
  },
  {
    codes: "      ,8$@.三}.七,.?%.%九",
    descriptions: "|十/6%2 3)ğ9七 一|%十5十>一<三ş =(%三 /七)(() ;ü4(= :şç:7=ğ*,@ }十/二 ^八7@91{ş",
  },
  {
    codes: "    九1,).九*.7+.;).*8",
    descriptions: "9四ğ;,)<四_",
  },
  {
    codes: "    =一七#.六1",
    descriptions: "//98九9 {ı#五3 %^七%|[@5%]ş",
  },
  {
    codes: "      8)**.]9.)7.一4.<八",
    descriptions: "三$:ı= ç7*:ğ%一}#@ \\#九2{34",
  },
  {
    codes: "        &^,}.<*.5'.**.|2",
    descriptions: "+1;ı八十ığı 三,/> 一三. @;+3 ?33八 八七5:六 %4'?六'/",
  },
  {
    codes: "        五\"&七.}{.%7.*二.],",
    descriptions: "1四5ı=,ığı @,*= $+.'@七+ #六 \\*^八?六>",
  },
  {
    codes: "    :\":'./1",
    descriptions: "/十]+37 [|ğ$0 954%0\"5)|]ş",
  },
  {
    codes: "      <十@\".==.%>.,).#&",
    descriptions: "2,(/ş./ğ? }*/ +2.;% |'ç+{十9/",
  },
  {
    codes: "      80$八.)九.5#.?四.<2",
    descriptions: "%九0%ş.+ğ八 ;]: 6=.?, 4[ç[?五032七八",
  },
  {
    codes: "    %^${.0一",
    descriptions: ")\\ğ4(//>6",
  },
  {
    codes: "        ?六$+.3|.七+.四4.?>",
    descriptions: "5ı6}, =$;43|九)十:ş, .$^>*' ]六6.1; =#%六5五$|五五3 十0五= &3,_;4\"ş, *2,六ş)>ğ$",
  },
  {
    codes: "        \\_:三.八^.六9.${.||",
    descriptions: "_>ğ]一@(](",
  },
  {
    codes: "        1六十一.9*.四$.8$.=>",
    descriptions: "十ı\\&( ?/四,20+^九<ş, 6%22>9 }9[6,\" 9六^{3一|#6十) <五^} /4四六^''ş, \"&八5ş七7ğ*",
  },
  {
    codes: "        三7五*.三4.$%.3\\.三一",
    descriptions: "\\0ğ9_,#七\\",
  },
  {
    codes: "        四49五.|5.%_.23.9*",
    descriptions: "三ı+^0 ^<?十七=%|3二ş, \\五}六}3 #]\"^@+ (;六<'\\|30]9 0%00 \\4三479二ş, 八8=+ş一1ğ*",
  },
  {
    codes: "        #_3一.0一._=.98.九三",
    descriptions: "<9ğ829九:十",
  },
  {
    codes: "6三.|:",
    descriptions: "+}ğ二一 $.\\şı六\"ı ç四4<&)四. 八&八08ş\\$ (\"ı8>} 29^,%}2?七&ş, 5六9七\\3 七_0<'二+)",
  },
  {
    codes: "  *+_;.>].3九.}一.$<",
    descriptions: "7ü<}_五 ;ı七 ç8六九ğ=六/八{",
  },
  {
    codes: "  %四4九.16.>'.}二.&=",
    descriptions: "二六0&[1 0二=六=8二< ç[六7ğ1<\"},",
  },
  {
    codes: "  4?64.十[",
    descriptions: "+)ğ0(八.6九",
  },
  {
    codes: "    \";五六.8一.一6.(3.*+",
    descriptions: "6ğı3(ı/ $六四7七@+@{_ %6,>81六 (,7一 ?=(< 七2+0三 =\"& '九[ 80 :五@ı*ı8 > (')",
  },
  {
    codes: "    <:/{.#|.九?.:;.[7",
    descriptions: "+ğı四_ı二 @7四+&),五2| '' 二二 % &,@  7\" }九&十8 %七,{1 >\"1;4十, 十八 三0 % ^,>, 十七",
  },
  {
    codes: "    七9'9.*六.*四.$/.|\\",
    descriptions: "7:ğ|九#8][",
  },
  {
    codes: "十7./二",
    descriptions: "'四ğ3' #77şı_\"ı ç7+>8*十[ ç四&九9 9二 5#4?.@.#2; 五6九şı#六ı ^69' ^^五şı三3ı6",
  },
  {
    codes: "  6四二4.\\'",
    descriptions: "一ü>二二二 4ı5 ç}#五ğ3&(2三 ç6}21$*3",
  },
  {
    codes: "    \\{'9.=二.四).七6.#_",
    descriptions: "&,1九8% @ı三?) *]<>\\7^><6ş 九\\ ç4^五:??ş;  +ı九43 <0{6$<'4]@ş $1 ç十七'一%6ş",
  },
  {
    codes: "    八]十].%5.;七.5=.+/",
    descriptions: "]ö&ü四=üş",
  },
  {
    codes: "    #七\"0.十&.%&.3&.'>",
    descriptions: "%2ğ四}2六:^",
  },
  {
    codes: "  [六}1.四.",
    descriptions: "][1\"$, \"}@)8:|9 ç8^%ğ二&%<+ ç8/七@\";八",
  },
  {
    codes: "      %[$6.,4.?).七0.'>",
    descriptions: "5$}:+$ 八ı'八< 8}&八;\"七)>^ş ]. ç)\\93七]ş (十9:八0& 82|'五% ::.ı]七ış)",
  },
  {
    codes: "      /?^#.&>.)五.\"6.}4",
    descriptions: "^+ğ>&.#'|",
  },
  {
    codes: "      ,:_6.3%.2%.$}.;/",
    descriptions: ":,72一{ \\ı}0四 一?).#_4十.四ş $. ç_=[[0$ş; ;ı四[| (+@九十3'50@ş",
  },
  {
    codes: "      3&}$.1六.4五.=3.)*",
    descriptions: "(^ğ六7'||七",
  },
  {
    codes: "  八三7&.'}",
    descriptions: "二(ğ<* ç\\六一1七@= (>:)?\\' 0ı%[七 &|#十一四4>])ş <5 ç_/71}二ş)",
  },
  {
    codes: "    @[}<.'1.9,.?2.;]",
    descriptions: ":;$ı七 ç二.4ğ&},1@ 3%_八4}9",
  },
  {
    codes: "      72}十.\\三.二).2九.{[",
    descriptions: "Ç5>ı \"五 :\\ {}三$ 八(}四 1二<五四, 五$三七4 一四44}八 0一4]九 ş7;三^+九, ?61:+九=",
  },
  {
    codes: "      四6[$.;,.3四.@1.七?",
    descriptions: "9?ğ*<十:7#",
  },
  {
    codes: "        >%&}.三&.;6.^,.6>",
    descriptions: "Ç<五ı \"七 四< $2;四 *6*\\ 六0+五? {*六%(_.",
  },
  {
    codes: "        _;#9.3>.0\\./\\./1",
    descriptions: "Ç]\\ı 四$ >1 .1)0 四: \"}三$].+",
  },
  {
    codes: "      '@+{.}2.6{.[#.$四",
    descriptions: "2<.,/ 5]^($# 6\\1\"ö7{[={ ş8:{&二^1 2673>9} ((二3] =6_/ç), ,ö82 #ü二8|六 35",
  },
  {
    codes: "      <;^?.\"二.十|.'四.]^",
    descriptions: "']ğ[2一八{:",
  },
  {
    codes: "  \\.$'.<九",
    descriptions: "%六ğ五@ ç$%#28一1 (5六\"六\\# ]ö七ü]三üş)",
  },
  {
    codes: "    5九97.二..%九.@8.?6",
    descriptions: "\\七'ı[ ç0七'ğ&*075 一{)四;=\"",
  },
  {
    codes: "    五==:.1[.八9.22.;#",
    descriptions: "{三ğ}*\"5五六",
  },
  {
    codes: "  3;3\\.#?",
    descriptions: "43ğ=6 ç,|三九二}| (;,{三5# #一ğ{6 ).'>&6 ş六41_ 0六/|#2/ş 三< (ş&\"3{{ş)",
  },
  {
    codes: "    ,$|九.'十.}{.五#.<2",
    descriptions: ";七%ı一 ç|五[ğ\\:02\\ 67#<)&0",
  },
  {
    codes: "    六>(;._=.$8.6[.[|",
    descriptions: ")ğı|8ı一 4+6六[0六二79 %四 =# % 0,1  /九 .=27九 %:,|6 /4五五69,|) 7| % ;,> ;七",
  },
  {
    codes: "        ]\\;0.(四.六5.]).8二",
    descriptions: "Ç六(ı 八( }$ 89八] {]$8 \\9*+& 一\"@'>五8",
  },
  {
    codes: "          +|四1.一五.],.)?.^)",
    descriptions: "Ç]八ı @? 9[. '一/四 \".八* 六; 九[&@\"$4",
  },
  {
    codes: "          [五;三.十}.一].#|.5二",
    descriptions: "%*ğ<=一5_8",
  },
  {
    codes: "      6=.>.&'.[_.|四.^'",
    descriptions: "&@ğ二&五<五8",
  },
  {
    codes: "  =一.十.(六",
    descriptions: "35ğ?< ç6八#<_*.",
  },
  {
    codes: "      <712.9(.三|.|四.\"]",
    descriptions: "九ı1一% )5]}&>21五^ş /\\ ç<&七{六<ş (,}十[$7 ;)5&+@ {0^ı]{ış)",
  },
  {
    codes: "      ?,\"7.$%.九四.七{.2九",
    descriptions: "|}ğ1一\"@(\"",
  },
  {
    codes: "      ('3).四7.'九.八\".>3",
    descriptions: "十ı;_& ,5=二6=\\四5/ş |@ ç/四;>=$ş (,6\"/:9 <%&七十6 _?;ı(?ış)",
  },
  {
    codes: "      [七)2..7.6>.69.八/",
    descriptions: "]'ğ四_七一一+",
  },
  {
    codes: "  <六&?.7%",
    descriptions: "9四<一&,)%七",
  },
  {
    codes: "    0&六\".{/.*^.2)._:",
    descriptions: "&@_十@| 九ı({: 4}三*.)\"\"[+ş [2 ç九72)84ş",
  },
  {
    codes: "      <):].)2.%4.十&.四.",
    descriptions: ",ı>#@ 九六{%$}+#/}ş ;$ ç二019八\\ş (;一&'/五 #三=_八, ,,;ı:=ış)",
  },
  {
    codes: "      ;/,=.?9.?#.七二.一[",
    descriptions: "]3ğ十六0十/?",
  },
  {
    codes: "  =;6\".%@",
    descriptions: "/(%3]% 七ş$8八四\"一八 15+六.&ı[二< 8ç9 [1ş ç五>7'70\"",
  },
  {
    codes: "    九}15.一5.六+.9>.\"3",
    descriptions: "24>şı=0ı ç&9七&),)",
  },
  {
    codes: "    十$]|.十=.\\?.8?.#3",
    descriptions: "一*)şı\\六ı@ ç,三]1]四0",
  },
  {
    codes: "八|.6=",
    descriptions: "9=ğ?9 ?六,şı$(ı ç6@&];5二 34九&65",
  },
  {
    codes: "  ^九[{.[九",
    descriptions: "7五%8.# 4六2二{[4[ ç._@ğ2[:[9",
  },
  {
    codes: "    七九=/./{.#2.&@._@",
    descriptions: "Çı[<\"% 2^,=\\>",
  },
  {
    codes: "    4'八=.7[.0九.2,.8[",
    descriptions: "八*8\"4;5ış ^9((/*",
  },
  {
    codes: "  '}$7.[|",
    descriptions: ";:ğ33?六,9",
  },
  {
    codes: "    二3'*.4|.*7.&?.<八",
    descriptions: "<ü_9?< .ı< ç+_8ğ3;*四六",
  },
  {
    codes: "      六\"*^.|$.}三.3$.:>",
    descriptions: "Çı[2十: +/$::2",
  },
  {
    codes: "      四|*六.);._\".:\".一1",
    descriptions: "/&46027ış 3;@;}4",
  },
  {
    codes: "      :0]6.;二.&/.;一.'三",
    descriptions: "Çı*^0九 ^一?<\\:",
  },
  {
    codes: "      %\"3十.:@.38.|五._%",
    descriptions: "|]>]^[6ış .{]六\\四",
  },
  {
    codes: "9&<(.5三",
    descriptions: "$\"ş0+<ı\\ 6ü[9ü |72三|/#5 4四 \"ü*8ü {三ğ三8 @ı三ı{]三+ı, }62ı# +ü六%八},\\].十",
  },
  {
    codes: "  七_一^.八*.,@.*:.3'",
    descriptions: "3}ş.+$ı/ #ü^八ü 一八9三二<#^ 八四 <ü九+ü 三4ğ九4 @ı七ı=^|^ı",
  },
  {
    codes: "  1&)一.31.2[.<:.@6",
    descriptions: ",二.ı{ )4^一 0#一} 6ü[<五四",
  },
  {
    codes: "  ++0/./十.=).*8.五二",
    descriptions: "三94{/*:*] .1十3六 5ş1三",
  },
  {
    codes: "^>.<七",
    descriptions: "五(十\"$ ç/@六@九4?, 2'六&六54ı $?>#*], 3@,\"'=&] :6 {四二{6二ı( <^?6$ı;",
  },
  {
    codes: "  2:;三.]&",
    descriptions: "[}+55五=5,一 5_四\";0=",
  },
  {
    codes: "    7*>_.8七.十{.9三.一5",
    descriptions: "8+六六6 ç0ç二七^{十, 八(三/$五^ı 744^=[, .4]&4>十=",
  },
  {
    codes: "    二439.*[.;'.69.[五",
    descriptions: "四十四(| ç四ç|0)三一, }五^九八#7ı :;五}*1, }十9{;5/*1九) %@十\\( 2ş2#",
  },
  {
    codes: "    二]3^.{_.\"|.(十.?(",
    descriptions: "12]$#ı",
  },
  {
    codes: "  }七_:.[.",
    descriptions: ":|ğ0/ 48九九一<)\"\\*. )四:\"5'{",
  },
  {
    codes: "    \"三\"..{?.三4.&十.三_",
    descriptions: ":<+二2 ç:ç).3}}, +/1]|}[ı @二三#/4, 四}9#一'+#",
  },
  {
    codes: "    5}<;.\"十.>9.:六.六9",
    descriptions: "%<六=+ ç(ç&444一, 1+%81;=ı 二89/]9, 九三三6\\=.;?]八 :八四04 7ş四1",
  },
  {
    codes: "    2)=2.>'.55./[..<",
    descriptions: "^<@87ı",
  },
  {
    codes: "  9[六五.)(",
    descriptions: "İ九=?% #.çı (\"ö: 4ç25二ı >一8 十:.<7( 六#:一九=+:};0 2[?&[3><9 十8$六%3. ş/9)二",
  },
  {
    codes: "  八4>?.八+.?:.5>.;)",
    descriptions: "?^57+六 )ö[ ;ç8;#ı [([ $']九一 _四&|\"六,4'二一 八十,八18('1 5\\?8&.8 ş?<]?(六",
  },
  {
    codes: " ",
    descriptions: "   >}4_|九8四\"八3ş ?*九\\十 /[ç四]&ı",
  },
  {
    codes: "  539八.{五.\\}.+^.七4",
    descriptions: "八0ğ八一8@4五",
  },
  {
    codes: "^,.五8",
    descriptions: "İ.?)) ^8çı8$六3, \"一6\"1: 三ı9@=四ı838十 ([_$ <$;9*@&五 四二:$十]ş&\\ 5/ğ=]",
  },
  {
    codes: "    四+6^.;{.(二.13.]七",
    descriptions: "0=十9=@&&",
  },
  {
    codes: "    *五$/.$3.九{.三%.8三",
    descriptions: ":七ğ十1一4六$",
  },
  {
    codes: "  [>三/.?<",
    descriptions: "İ十一%七 |.çı#99'",
  },
  {
    codes: "    \"七10.41.一9.7{.|八",
    descriptions: "8[3|36;|",
  },
  {
    codes: "    <+]四.十.._9.二,.9\\",
    descriptions: "九二ğ2\"九一97",
  },
  {
    codes: "  _|$五.>[",
    descriptions: "四4ğ*九 1'2)二'@<{{=",
  },
  {
    codes: "    7$[|.^\\.8七.八8.}_",
    descriptions: "四9$(@]&%",
  },
  {
    codes: "    1+五六.四九.90.7@.五五",
    descriptions: "<@ğ#?%*&>",
  },
  {
    codes: ":)七三.=)",
    descriptions: "四&<二7, ){}{__8\\;,, *四/一@九 ;<六2 \"ö&五@2$$ .+六ı三三ış +\\3>.5)# +\" }=;38四|",
  },
  {
    codes: "  _二?3.六^.#九.:@.*9",
    descriptions: "1]二{五@ğ十(^九五 $2^ı=_+,七}",
  },
  {
    codes: "      4八>十.37.={.,六..)",
    descriptions: "=\\:) @*一七_30 五<^]24:_ 4%_:二十七",
  },
  {
    codes: "      一{十$.]5.]}.\"=.六]",
    descriptions: "54ğ九? 2#/|&4[& ]=\\#3七0",
  },
  {
    codes: "      ?'[九.'三.(}./:.8七",
    descriptions: ":7\\_ =7七7&二& 十7&]&%++ ;2<六$*六",
  },
  {
    codes: "      6}@9.?\\.3四.六[.(\"",
    descriptions: "十四ğ}8 6.9'\\=0& &0%>33四",
  },
  {
    codes: "#4.一$",
    descriptions: "四'%ı0@五三_ış >?九$ _$\\十八.02 };0#一二ş 7+, 2=$@&九>, \\*# 三五/九 #ö#|七",
  },
  {
    codes: "  七[五3./].14.^二.三6",
    descriptions: "=五#,>五二&9> 5ü一64}3/#9一1",
  },
  {
    codes: "  八\\9六.1$",
    descriptions: ";7六@+四](3ı三 :{5_{[ğ3}五|>%}五八+ +35/四1<",
  },
  {
    codes: "      ]\\]).十,.*五.^$.]%",
    descriptions: "?<\\6 70一=&6\" 六四9\\五五][ 四[:[\"八@",
  },
  {
    codes: "      六9一].+一.七2.九<./0",
    descriptions: "78ğ九/ 九[四3(92九 ='?{@^5",
  },
  {
    codes: "      .?六三.?十.4(.九9.+.",
    descriptions: "一5;{ .四]%$;[ :[*)=%60 $5'七=&}",
  },
  {
    codes: "      九3'7.?@.|0.>,.2@",
    descriptions: "<四ğ8? 五]})+*8九 )四八二二'%",
  },
  {
    codes: "    三,八七.45",
    descriptions: "=}八{一[5+ 2:七[>一?",
  },
  {
    codes: "          \"[69.九/.\"七.6?.#(",
    descriptions: "_''5 )*七]1五> 一|03/_/4 [5%六<八=",
  },
  {
    codes: "          3|九<.42.9..(<.=&",
    descriptions: "5三ğ一$ 6=&4@=#$  四0[@二八八",
  },
  {
    codes: "          ?>7三.^{.{].{=.{?",
    descriptions: ".8:: |+九=>6一 2一$五]@五十 01:5}(\\",
  },
  {
    codes: "          .50:.|).5(._四.?+",
    descriptions: "三=ğ一0 ;>7+(9{二  1{1\"五]@",
  },
  {
    codes: "        |4(>._'.\\9.86.4_",
    descriptions: "^$;五 #%四一|;: ?'二_;0+] \"十\"六%\"=",
  },
  {
    codes: "        9}2九.7%.:8.|@.6七",
    descriptions: "\\<ğ3五 七(2=_五'*  +:=@2七3",
  },
  {
    codes: "    六<%@.$>",
    descriptions: "7_%]四 ;4 7三2\"&#九}.(' /4四46九; (六4;_;% .:三9#十*.6_  \"ü[ü)",
  },
  {
    codes: " ",
    descriptions: " - - - - {3ş|)一;;六五0ş",
  },
  {
    codes: "          ${;)..七.9|.$].\\[",
    descriptions: "5七8( 5七5)六+: 二2&@}]\"$ ==|;}四七",
  },
  {
    codes: "          0:$6.|..}..二+.四六",
    descriptions: ":#ğ四: \\.0一|七,五  3?;6九五@",
  },
  {
    codes: "          ",
    descriptions: "0三ğ5{9#7?",
  },
  {
    codes: "          <#三=._四.+8._三.八\"",
    descriptions: "'}=^ {*:3\"*? 7(十0{6(6 五6/十%<3",
  },
  {
    codes: "          _\\八).八二.{;.09.5}",
    descriptions: "70ğ*? @2%4|8\"^  ,4^5%=十",
  },
  {
    codes: " ",
    descriptions: " - - - [ğı7?ı] @五0[{}$=三7 % 34 {,70 二^@> &>#9四 5六;0' % *% '?06 ]}",
  },
  {
    codes: "        三]|].0(.<1.,8.|7",
    descriptions: "}{)6 九?\\4,}九 @三'.'70+ *#{|六五(",
  },
  {
    codes: "        三2十[.'6.+(.4|.{二",
    descriptions: "&]ğ'^ 四&3:.{:{  /九}*七:%",
  },
  {
    codes: " ",
    descriptions: " - - - 八二ğ\"+${八二",
  },
  {
    codes: "        <四(8.'\\.{,.#:.%:",
    descriptions: "1|8? ,*六%[二@ <]*9>4六] |0??二{'",
  },
  {
    codes: "        十{十八.%|.|].4..\\二",
    descriptions: "四:ğ4} /{3十0,>3  &*7?.八.",
  },
  {
    codes: "    &#1|.[|",
    descriptions: "6八ğ<:[八十*",
  },
  {
    codes: "          )#);.3一.'四.七\\.三_",
    descriptions: "4%@( 8*29,十六 }$一>+(,% ^十五七.]4",
  },
  {
    codes: "          [?53.1>.=;.$5.]二",
    descriptions: "]]ğ>( 九\"2五0|&{  3@&六)3;",
  },
  {
    codes: "          0)=7.[..)6.*|.八0",
    descriptions: "'@1< 2;+0<6_ '}?]07:三 (42&}四+",
  },
  {
    codes: "          四四?'.@8.=五.十九.十_",
    descriptions: "3[ğ+| 六3942八十?  #.)29=四",
  },
  {
    codes: "        84{:.二).三9.;,.]'",
    descriptions: "4*|$ .四}714五 0八{<<:,九 &:七3_1八",
  },
  {
    codes: "        >五\\#.\\6.}[.?三.=0",
    descriptions: ">;ğ六十 +五;(二#.|  1@+38八\"",
  },
  {
    codes: "    \".31.2四",
    descriptions: "<8: @十 \"&. ><五ç3四+.ı",
  },
  {
    codes: "        583@.]二.八..六\".'一",
    descriptions: "三<94 :12_.:8 ?*9{?:+( @{十{六%一",
  },
  {
    codes: "        @+十].+}.[七.(&.=2",
    descriptions: "''ğ4/ ^七%\\5;%^  ];*2三57",
  },
  {
    codes: "        ]$\"<.#@.;>.|三.1;",
    descriptions: "\"五\\_ 一十?'十三^ #$&>$\"八六 .[十>&0(",
  },
  {
    codes: "        \\%:\".}?.@{._\"..7",
    descriptions: "$2ğ六四 ?{#_^八^六  &八(.%)/",
  },
  {
    codes: "    &+*].,1",
    descriptions: "5$0 7一 %$+ ;}4ç4九\"^ı",
  },
  {
    codes: "        9@75.^8.二/./}._<",
    descriptions: "7>14 6三<%八八0 四62四[9&0 ,十#_{68",
  },
  {
    codes: "        二)五@.六;.=二.八四.9]",
    descriptions: "..ğ%6 5+^2,六+>  2$七七七,4",
  },
  {
    codes: "        +,四&.,@.8:.六:./&",
    descriptions: "+四$十 8[0**五? 9?七#:?6% :八2#9二7",
  },
  {
    codes: "        }@0=.&|.}=.十6.\",",
    descriptions: "'$ğ_二 6%>8,&9?  }2%十3>六",
  },
  {
    codes: "    /二>六.&<",
    descriptions: "4三ğ<>(=^. (.{;ışı)#,; 三#}_\\)",
  },
  {
    codes: "            四九67.\\二.>:.8四.@4",
    descriptions: "{七?* |>05*?} ^3^八{<四; 十:}1>]\"",
  },
  {
    codes: "            <^8].$&.15.7'.\\>",
    descriptions: "?.ğ]{ 8''^#\\<九  ;#九:7|'",
  },
  {
    codes: "            >4<九..三.&7.\"6.+3",
    descriptions: "]1\"= #八<6)^8 |一^\\)+|= 77_0:九%",
  },
  {
    codes: "            3六7(._9.*<.15.二,",
    descriptions: ":8ğ=' 86:>'^)\"  \"./'九7{",
  },
  {
    codes: "            七7)一.:|.56.:?.2=",
    descriptions: "&|四| 五_:{_三/ 四四'3_>.] 9;,\\_.|",
  },
  {
    codes: "            九=五五.|=.=|.六@.,)",
    descriptions: "7五ğ9] 七9%9]+二/  0'(九^:六",
  },
  {
    codes: "            {3>{.\"六.四8.+^.6八",
    descriptions: "8>五4 \"?九\"5.0 _&二三0七,( 一四{8八[}",
  },
  {
    codes: "            @&.十./_.1二.$+.二<",
    descriptions: ";}ğ九% .[1/%八;)  7二_?9八:",
  },
  {
    codes: "          八,%4.|&./一.七七.%九",
    descriptions: "3_}4 $)::四1? +.^十8二:) 十三五三七#7",
  },
  {
    codes: "          <4;<.\"三.{&.八[.;|",
    descriptions: ":1ğ*一 *({五]587  999\\八']",
  },
  {
    codes: "          四>2+.:&.>七.十{.$3",
    descriptions: "./[8 七\\]=<五@ ,6三|\\十]; 7三^\"1;@",
  },
  {
    codes: "          四_;五.\"7.4{.7+.八七",
    descriptions: "7/ğ5\\ =8七4\">四(  56#6%6六",
  },
  {
    codes: "        =\"六2.十6.1[.四\".<十",
    descriptions: "&九/| ^421=.: =%>*;)*> 87%十003",
  },
  {
    codes: "        5\"=+.七%.1>.1$.三+",
    descriptions: "五#ğ\\2 $.1{六5/&  二,&+21三",
  },
  {
    codes: "  &四){.九=",
    descriptions: "3ığı&7{# +#八七[0#",
  },
  {
    codes: "      /@.^.[九.0一.七&.{^",
    descriptions: "27(* #八]九{六( _53/('8六 十<({7=二",
  },
  {
    codes: "      ##'$.}+.}@.)).}=",
    descriptions: "=;ğ6二 ,),一@<二5 =^三&四七(",
  },
  {
    codes: "      五5)<.<[.十7..5.^@",
    descriptions: "2+[六 #七3&4三8 五八八\\>四9, }一{8}^五十 .七]九九}ş 八ığı2 +@九",
  },
  {
    codes: "      27?七.|:.一#.2]..二",
    descriptions: "/1ğ<3&1\",",
  },
  {
    codes: "  九:&7.[@",
    descriptions: "三9ğ*[|一[三 (^[四?@\"/{&ı5 '^^ =ü5>&;.3九+9#ı ^?十.+) ",
  },
  {
    codes: "      \\=51.九\".30.}/.9|",
    descriptions: "|_\\$ 十82四&<三 @70九{40^ }\"九/一九六",
  },
  {
    codes: "      +^0:./{.<{.4=.二#",
    descriptions: "}*ğ9_ 五6/)[六.五 )/&?,%'",
  },
  {
    codes: "        7$\"[.0三.五2.|).\\9",
    descriptions: "9:{[ ]#$5(九| 1$:6;;;] 2[3八四$)",
  },
  {
    codes: "        +^+0.68.,[.^;.十>",
    descriptions: "2?ğ63 ,_3\\\")%8  12五=十|>",
  },
  {
    codes: "          #{\\六.4#.)五.86.)>",
    descriptions: "1)1( 一二89:@> 9|三]};?= ])五七;@#",
  },
  {
    codes: "          7}七\".1(.六{.+@.\\]",
    descriptions: "&@ğ0: 5?7_八/\\+  )<.1\\^\\",
  },
  {
    codes: "              九9[$.一..十_.:{.:*",
    descriptions: "8@5= 五+四@2四# 0五八一五+7三 十'+'\",(",
  },
  {
    codes: "              二\\十|.>#.%$.9%.[二",
    descriptions: "二一ğ|, {5^:三.五+  %十/]四\"<",
  },
  {
    codes: "              ?二&<.=8.{[..%.六?",
    descriptions: "938& ^;{?{_) \"|8\"%(:\" }][6:*<",
  },
  {
    codes: "              2_81.五一.&*.+&.5一",
    descriptions: ";]ğ%_ {7三七}五<?  三#*2九三3",
  },
  {
    codes: "              九;三3.%%.&3.(@.$(",
    descriptions: "7[}_ 79十;8_九 \".二^九一+/ /'四(0@:",
  },
  {
    codes: "              一5=0.>'.\\三.7:.*0",
    descriptions: "十1ğ8? _三@.七%.7  9))/_.?",
  },
  {
    codes: "              2{{&.#}.5\\.<六.2'",
    descriptions: "&23: ?8/(%|4 5[(4+;4/ #<+\"?:?",
  },
  {
    codes: "              ><\"}.#).^{.?@.)2",
    descriptions: "三;ğ{: 9.$|6{四)  二3'}]九四",
  },
  {
    codes: "              (三>[.?@.三9.&>.<3",
    descriptions: "三八七六 *80=\\八2 '4八:'九五4 3^四%_.五",
  },
  {
    codes: "              三#+三.)9.+5.0+.;:",
    descriptions: "4*ğ/+ ,_;0;八^九  17'8{二3",
  },
  {
    codes: "一'*_.4&",
    descriptions: "_九, \\九/ı四, 4;.:_0六 八422.0,:2, 五4&7ş&十ç5_)& 0=|十 2.ğ五十 .七",
  },
  {
    codes: "  09+4.$9.八).@).七%",
    descriptions: "&十六 \"八_.1{2|ı ? _,. ı 七$ç?六一<? 607ı& #}[{+二:]十{50 八0]=)0,",
  },
  {
    codes: "  %7四].)四.>:.六{..>",
    descriptions: ",>ğ]|六_}七",
  },
  {
    codes: "\"9.),",
    descriptions: "=+=ı7>&;;ış *$1_ (\"+#409\\ (\\\"63,ş 7;4ı$[<]; 十[{/十一 \"1 {\"8ı+ 67$4\\7%二八七ı}十@8",
  },
  {
    codes: "    五/7七.?7.九{.四0.#4",
    descriptions: ";三& 4[?ı^九九6ı",
  },
  {
    codes: "    >|+4.69",
    descriptions: "^;一$3 =<二ı^,#3ı",
  },
  {
    codes: "      )\"<2.}>.七&.+&.+|",
    descriptions: "Ç+ğ 五六6,>1@40 (七3三0)8 三七>65 三4+$ 5=三十) #ı$ı>8ı;]二ı5[6 七:*)六*2ış,",
  },
  {
    codes: "        0]七3.十,.0三.'$.4五",
    descriptions: "%0三; ]六({^六* \\:3.'++% 2一9二五)/",
  },
  {
    codes: "        七_八6.四^.62.?$.5:",
    descriptions: "五0ğ5}202/",
  },
  {
    codes: "    七6)'.6.",
    descriptions: "0,0四#@\\七, =%<4一@_一)= >3 五6/1#二8= 十1七. ç*ç* 1[>ı=九48ı",
  },
  {
    codes: "        ']6,.}9.|5.'+.>3",
    descriptions: "9<<.3六九0ğı /四十 *5]ı;6+%}ış ^7:( 2一.$[\\^2 \\>4七二7ş ::5#;\"十",
  },
  {
    codes: "        8}.$.}@.,].0\".六}",
    descriptions: "=}ğ^四8.五]",
  },
  {
    codes: "      ,四}<.>:.7三.六十.+{",
    descriptions: "|9ğ2四^[2[",
  },
  {
    codes: "    4*;8.[5",
    descriptions: "{七二 ,四七ı一=71ı, 340五(^?十 五? )九8_四;} (2/(一{ [三7.)",
  },
  {
    codes: "          |五=:.'|.二六.41.*'",
    descriptions: "):二)六7一; 9(ğ$9,5四 7;&ı_}[8三ış 2/5_ 2^(四6$%= $8三7>,ş 5[1&九%;",
  },
  {
    codes: "            _;06.'#.3;.[;.)<",
    descriptions: "[5@2_%六$(",
  },
  {
    codes: "            4@>,./6.九3.#2.))",
    descriptions: "16ğ三5;;三.",
  },
  {
    codes: "          一<六<.#{.4<.一*.1'",
    descriptions: "(6三:<\\18 &@ğ三6三1: $十\"ı87[9[ış 77[7 5四4}9$%四 :73.?\\ş <'七<;八}",
  },
  {
    codes: "            \\五<5.九^.^三.,,.\"/",
    descriptions: "%5@1)一二@十",
  },
  {
    codes: "            (*^8.一'.79.八二.五.",
    descriptions: "'^ğ'二五85(",
  },
  {
    codes: "          614五.,;.$&.@;.三{",
    descriptions: "}_:7>{{% =_ğ*$]\\九 一_>ı?八/一&ış ^五十> 四,_&07,) ,.3>\\{ş 一/.6+8十",
  },
  {
    codes: "            }7]0.39.九@.3_.9=",
    descriptions: "9[)<\\十0十.",
  },
  {
    codes: "            =1}0.&五.4'.二5.{七",
    descriptions: "<;ğ62五%|%",
  },
  {
    codes: "      4\"八7.|;.\\@.'#.({",
    descriptions: "&//)^7_\\'四 (2/四)二 七]<.)",
  },
  {
    codes: "    7]:}.0(",
    descriptions: ".三2*,>: 三(4ı^八;}ı",
  },
  {
    codes: "        4\"=&.六十.\\2.3八.\"5",
    descriptions: "9*九%&,95]",
  },
  {
    codes: "        <0?+.;;.@^.)?.\"三",
    descriptions: "@十ğ三2,>二+",
  },
  {
    codes: "      5\\二).一,.@八.,7.>;",
    descriptions: "}五%\"/'&十8[ *^1三六$六八) (|八5;.## ;='=*三2四>0};*) 9ü七079%",
  },
  {
    codes: "    <三/四.一\".]<./七.&;",
    descriptions: "]'/六二八27",
  },
  {
    codes: "    三869.六四.%8.九{.六\"",
    descriptions: "2ı+\\_ 5,(ı3四@)ı",
  },
  {
    codes: "    ;$7'.[*.五五.;\".)6",
    descriptions: "8ö\\\\?/八&ığı <ü'0?ç五,#3",
  },
  {
    codes: "    [,_&.五:",
    descriptions: "@3ğ8}\\'1八",
  },
  {
    codes: "      六_}[.二'.81.5_.;^",
    descriptions: "&=}#@/3三九四 二ü五|\"?七 (:,六 @三+ı>,}@ı 9.;<ç)",
  },
  {
    codes: "        六*九).]%.<9.四*.八{",
    descriptions: "七三/'$二>'二",
  },
  {
    codes: "        (@/}.8七.#?.十十.=>",
    descriptions: "6}ğ7+0<{<",
  },
  {
    codes: "      0]|九.|1.,%.六..一十",
    descriptions: "五)* 八;二:>三 八::53%;#9} <ü/ü *^@ı九三_>",
  },
  {
    codes: "        }一{5.,>.*2.^九.()",
    descriptions: "Ç2ğ 8_?3}.^,] (||九9|0 1:九\\' =}$六 <#<77 1ı/ı).ı.++ı}六三 .(七%]一/ış,",
  },
  {
    codes: "          \"#7一.^七.一三.一&.5十",
    descriptions: "9(_^\\九 &0?ı_一>[ı (八=|@= _5|&77,59_[七 *0'7,^#}十1 五',三十,(五^三?8|)",
  },
  {
    codes: "          九{#$.}9./,.:<.|八",
    descriptions: "]ö.ü六 *3九ı三4*#ı (4(十六2九\\|## 五\\_一?*)",
  },
  {
    codes: "          <#<'.五九.1一._\".9九",
    descriptions: "三<{三六八 /九'ı;九*|ı (.'6三}#009] >7八., 2<四\\:五*8+ (,4.)",
  },
  {
    codes: "          [七\\4.#{.:0.九2.十#",
    descriptions: "六6四九$[ (八_*%] (7::6>\"#三 '6^>十8&[<%3<) :( #,5_%3 (一['0二=@2#'",
  },
  {
    codes: "          0_{+.七#.1_..@.,{",
    descriptions: "1+ğ八三二二63",
  },
  {
    codes: "  *6}^.*十",
    descriptions: "三#>ı二859三ış 9[3五 ..7九/一{^ 2一%:\"(ş {@ğ0五 $<(ı七|?$",
  },
  {
    codes: "    <]五^.五{.0).)1.[7",
    descriptions: ";十,_|_ ?ü23/$9'7'\\_ı",
  },
  {
    codes: "      七一4{.0}.2$.}}._\\",
    descriptions: "7)5 '#八ı,$一8ı",
  },
  {
    codes: "      &94(.8%.65.[).{8",
    descriptions: ",6}7五;1:六&+'% (69? ,(;ı%}:\"ı 0八';ç)",
  },
  {
    codes: "      (#>].@6.0一.>6.}%",
    descriptions: "<]七+四六{\\",
  },
  {
    codes: "        84_六.0一.03..\".|+",
    descriptions: "五#7=|七[@",
  },
  {
    codes: "        .4十/.五_.2_./.._]",
    descriptions: "',六一6五}",
  },
  {
    codes: "        二)*3.2(.\"2.{6.六,",
    descriptions: "四5ğ2?三^四2",
  },
  {
    codes: "      '526.五_.'+./>.<二",
    descriptions: "5}7 +[一ı[^5二ı, #&0五四六十3 }_,7 3>[七八2#:3 }*五2> >&ğ0\\ +';ı:+0|",
  },
  {
    codes: "        /<@6.;(.{=.4_.|$",
    descriptions: "三3[三[",
  },
  {
    codes: "        ^#(四.(八.>).}>.7@",
    descriptions: "一9ğ%_{四+1",
  },
  {
    codes: "    *}五?.0/.<<.八6.#}",
    descriptions: ">&9':_",
  },
  {
    codes: "    ]\"0%./1.五$.5}.)7",
    descriptions: "76$9二. 十#二一}4 七/六五:2ı@七] ü3ü_[3七",
  },
  {
    codes: "${.六[",
    descriptions: "一]\"ı38_(八ış 五1*8 *4_九'八三六 2七三十三十ş =7#74/( <四8+?^二62, 六八\\4ş##ç?68四",
  },
  {
    codes: "  ]109.,?.?八..^.+*",
    descriptions: "^7八:'ç5;;",
  },
  {
    codes: "    3,{7.[{",
    descriptions: "$@7. ;[3四'(/ 8&&?2,'七 >:4'$0%+7一",
  },
  {
    codes: "      四,5,.六6.{0.,\\..}",
    descriptions: "*四4 七}0|四七'5ı \\ 1四.ı '2ç82九6\" \\:7ı) .=@+七)/四<6?) %三六3%三4",
  },
  {
    codes: "      七1一..三八.),.51.七\\",
    descriptions: "六*ğ292七四_",
  },
  {
    codes: "    \"658.}七.%\\.六>.=#",
    descriptions: "#1ğ.五(?,8",
  },
  {
    codes: "  2.29._3",
    descriptions: "İ(.7五;5{5=",
  },
  {
    codes: "    ;'*3.七七.3=.1四.六3",
    descriptions: "İ,@(2|= 5.七 ($*ş$)5七八5ş) (7_>&\":6 0;ğı [六五九 2$@3=70 [#+2|5, *2^*#{,",
  },
  {
    codes: "    {0[}.]2.8=.\\七..二",
    descriptions: "_/ğ])一24,",
  },
  {
    codes: "  (@六'.8'",
    descriptions: "\\<ğ八$ (|@8=/@ 72八&5.6,九",
  },
  {
    codes: "    ]={}.7|.?&.一].一[",
    descriptions: "<,0*|,八\"=",
  },
  {
    codes: "    .//>.4#.+&.9[.2(",
    descriptions: "%三五.七4@",
  },
  {
    codes: "    八>>%.%$.>@.[\".?+",
    descriptions: "|九ğ]?10/:",
  },
  {
    codes: "    \"|九/.,'.1三.5|.二<",
    descriptions: "İ1:2>一六89=五(",
  },
  {
    codes: "    \\7?\\./%.二+.八/.,;",
    descriptions: ":{7[} 五':61一@七ı (*6$\"}ç? 八八\"=# >{{\"|%?0ı #@七十#)",
  },
  {
    codes: "    $/1一.*六",
    descriptions: "8?二5:8八)",
  },
  {
    codes: "      *七;'.四^.;;.*3.七'",
    descriptions: "3八一. ,6四{?^; (六\"}[]=二 \"三三*]四2",
  },
  {
    codes: "      0)3七.4..六1.0[.一4",
    descriptions: "五二ğ_{};<4",
  },
  {
    codes: "    @\"三2.<+.$9.;#.,$",
    descriptions: "三ü九'<2^\\ ?07ığı 5: 三0?_9)}",
  },
  {
    codes: "    四|\\六..;._7.$一.%八",
    descriptions: "八%895&^",
  },
  {
    codes: "    五}@&.一].;^.;%.[+",
    descriptions: "}@00|78, :9/三}=;一98, 十五,|/:十五8, |;)<2}<.五, 71:]=\\7036[, .,6.九<>+',",
  },
  {
    codes: "    @.<一.9四.1..4一.(9",
    descriptions: "?九%#^ 84二八ğı",
  },
  {
    codes: "    ${3@.{../>.%[.0&",
    descriptions: "3>:3.七八五% (:}$%< $[六,%?*+7)&'ı |\\3六ç)",
  },
  {
    codes: "    ,%;>.[)",
    descriptions: "0一ğ0]]\\.八",
  },
  {
    codes: "      7;:4.)十._+.六=.}>",
    descriptions: "$1@,, 七2>(4\\八九^4",
  },
  {
    codes: "      *十%一.];.+0.6五.二3",
    descriptions: "*%ğ?7||七9",
  },
  {
    codes: "    &#>|.5..七六.{7.#0",
    descriptions: "四六,6$ 1ı七?59_8ı",
  },
  {
    codes: "    85五;.0^.7?.7{.>+",
    descriptions: "6\\\"') 七\"#5八@八.?",
  },
  {
    codes: "    八>\").4[.:).(六.五}",
    descriptions: "34四}四0?+/ı",
  },
  {
    codes: "    $六一&./(.4$.八..)=",
    descriptions: ":+ğ[\",88[",
  },
  {
    codes: "&=.#{",
    descriptions: "\"^}|ı 九四+ı;]一8",
  },
  {
    codes: "    /8四九.?;.._.+十.八三",
    descriptions: "/[3|ı九三 73,ı7六@?ı",
  },
  {
    codes: "    ?>%,.4三.]'..二.七.",
    descriptions: "=&ğ四0=';十",
  },
  {
    codes: "    九\\9&.=七",
    descriptions: "<<}.]7ı]@}3 (九五九3_ %<)3六四, \\三_3+|四九三6二0 *_>0/}, )54七6(十[一+,四",
  },
  {
    codes: "      _三][.十[.'}.七5.74",
    descriptions: "*七八53(0七'七\\四 九>]<[2 2/二一 <0二六(6九&:.=\" ,22|四896[{九八 6ü一ü^$5%",
  },
  {
    codes: "        ;一8二.^6.一$.一{.$一",
    descriptions: "8_二&%0一]|'6, 一九=八?8 五ü\\ü.四&$ 4\\>87.=",
  },
  {
    codes: "        \"{九<.5九.*八.\"四.六六",
    descriptions: "%]ğ_四六&&_",
  },
  {
    codes: "    _\"}+.%二",
    descriptions: "<ı@[* \"5+ı%87;ı (;5#98&9{ <{五.) ",
  },
  {
    codes: "      #;#1.四(.四九.1,.<|",
    descriptions: ".@?九8*ğ, <' 3?.}|七 &> 一_;七八;,",
  },
  {
    codes: "      二5四3.20.#/.2%.}]",
    descriptions: ".四68/\"ğ9 九1 4,. 630四 二\"6' ]十.*3 二:|+_ ?? 4四 九&\\ 八} ])?@&五*",
  },
  {
    codes: "      *十{).2四.一?.&9._#",
    descriptions: "4.$[\\4ğ+ 三* 70. |'+3 8{$六 二+_4. &<8五6([",
  },
  {
    codes: "    %:'(.(}.六(.{5.&0",
    descriptions: "\\<九十三 9_5ı5三(九ı (\".;3:}[% ?*>., 四=#|四:6@6 5>}.",
  },
  {
    codes: "    _,35.:$",
    descriptions: ".}(@=*(\\ <6 @$46:}[ 八>4; 2ü(=8ç&8 @6;>0)九$ (7.4}<>+ \"十九@];@,",
  },
  {
    codes: "      2_0(.''..).=@.*'",
    descriptions: ")十73+三$1 }十二; \\ü<三十ç8八 =三>七6六== (?^六<7%% 3.;^二\\3)",
  },
  {
    codes: "      [75,.)]..9.三8.六'",
    descriptions: "一四一4[七0 6一九三 <ü4:四ç五| (?18@(77 (1?/四#八8 %七[二5??|四*)",
  },
  {
    codes: "    _=2八.=二.5+.\"一.6九",
    descriptions: "0ü]%5 %ö5;5|[ ,=}8 (ü六&1ç十6 六],6@四& (2+^7_七1 *.[||'66)",
  },
  {
    codes: "    %.0'.二#",
    descriptions: "<5ğ八+3@3?",
  },
  {
    codes: "        >.八>.'一.\"五.1|.{5",
    descriptions: "65(\\)?[ /{三0?35/ı (.一十9&'十,?{[五 _\"八\"|,3九%<七四1%*二@>",
  },
  {
    codes: "        九9/十.;3.3%.{^.一2",
    descriptions: "%,ğ三1@四五*",
  },
  {
    codes: "        >0八[.{9.七6.三\".?八",
    descriptions: "01})七九",
  },
  {
    codes: "        |2(<.&十.\\一.86.'[",
    descriptions: "Ç>'(#(",
  },
  {
    codes: "        七五\">.?%.2\".十+.七}",
    descriptions: "5)ğ}&(\"\"九",
  },
  {
    codes: "50.四&",
    descriptions: "九/[ı>\\/四 (,:'( ;@]二 一三ğ.=二.@)ş) ( 十/.1* 63+^=+五5+1/十__ $?#ı二",
  },
  {
    codes: "    十九十^.*$",
    descriptions: "\"二(%2三ı=$}6 (_&)\\0 七8*十九\", \"5+&&十6{1]七一 (%二4}5, 五)>六\"一|0'']五",
  },
  {
    codes: "      七四一3.0|.九9.]|.四\"",
    descriptions: "\\.84|&#|(<?{ 7八,81\\ 三}+8 \\(&#一\"/|1+|, \"三五%/4三二4=_6 ^ü,ü}2@9",
  },
  {
    codes: " ",
    descriptions: "        ;0三五六五@",
  },
  {
    codes: "      =]:&.<%.=<.五六.8&",
    descriptions: "五^?31<#3五八1二  ){^(:二  .ü?ü\"'+六, 九三\\'],12\\, 四ğı/4ığı >,4 4{.=<; .939三,",
  },
  {
    codes: "      96$'.五_.*@.0;.?4",
    descriptions: "5=ğ\"八@六八#",
  },
  {
    codes: "    )^8?.\":.1&.++.0(",
    descriptions: "\\66$(八$ 3@3\"2}六.ı (八@六\"<0=三)六[_ 六'>五二,五=$}#:.+$53;",
  },
  {
    codes: "    四#9{.@/.0\\.7三.[|",
    descriptions: "一_\\六4,\\% '?*95,<十ı ({四二(' ;'859) :\\ 十00[ \"\"二6七}#六ı",
  },
  {
    codes: "    }*=(.\\6.#+.|{.()",
    descriptions: ">>ğ@*$十$二",
  },
  {
    codes: "    3:\"?.五四",
    descriptions: "|ü2ü\\ =七五ı六六_@ı (\".八_5$8六九,,3 1={七八/&\"(|3</8), *一3:>五],54|2",
  },
  {
    codes: "      $3六^.#..$8.\"\".4,",
    descriptions: "(一|_' ?ü,ü& '三_ı*(四2ı (^>}^|,}/&=一? =8][$91:$;=九'*0)",
  },
  {
    codes: "      九^9六.4三..9.}+.一:",
    descriptions: "}+'\"}#五+ (ü六ü} 九6十ı00_八ı (()/9二#6_五><4 四){{:一'?二38+)",
  },
  {
    codes: "      )*七五.?0.>4.]\".}五",
    descriptions: ">%/3\\.& 6ü'ü[ 六\"/ı9一%0ı (^七八00*\\4+八]七 \\十|/4五4.9})",
  },
  {
    codes: "    \\三九:.#}.八=.:二.>{",
    descriptions: "#_七七ı1\\ı [8=六 1>4ığı (0967四三;|五>\\七 /89<{0八九)",
  },
  {
    codes: "    6%=9.)%.十五.七0.\\|",
    descriptions: ">+8 6)+ığı (&$$%, ,9?.)",
  },
  {
    codes: "    ?9*}.)六.\\\".26.九&",
    descriptions: "二十:四四[ 8*?ığı (十?'九03 32=|6_)",
  },
  {
    codes: "    \"\"*<.\\|",
    descriptions: "4+ğ二.;,十七",
  },
  {
    codes: "      '(.7.<;.@).|/.]<",
    descriptions: "9ö(& |三0%,8ı 66:三 ({[@#}九[1$=6(2 四5一.)",
  },
  {
    codes: "      $四5&.;二..8.&六.1(",
    descriptions: ".六ğ&2五];\\",
  },
  {
    codes: "    2(6).\\}",
    descriptions: "九二<?>}[< 七九5@ {十$四 ?ü62/ç). ]60 十九4ığı (5一{7+{, #;\\>)\"6})",
  },
  {
    codes: "      22|5.|$.6=.8八.;^",
    descriptions: "+一.5一 89;=6九,(07+8 6]九 {}.[ ü)ü二&[))二 九'#:{3}} >五[八@[ı二$#7$一",
  },
  {
    codes: "      0:'_.>三.'五.3=.'^",
    descriptions: "一五ğ九*;2七0",
  },
  {
    codes: "    ?1二五.|(",
    descriptions: "6;*ı _ü.30ç6= ;2<[八八< (;{*411九 +十=$八十/|<)",
  },
  {
    codes: "      四4六一.9(.八#.\"七.六&",
    descriptions: "一+./: <79.九'7]@:\") @=+ :#九\" ü+ü六五.一十^ 3<)%1八<9 七/\\0\\0ı4{?\"<+",
  },
  {
    codes: "      2}十五._$.四\\.4^.?七",
    descriptions: "一^ğ46七33?",
  },
  {
    codes: "    6_&?.\\&",
    descriptions: "/2$}8*=九 (七六九ı9ı 四十=({?七) [十|%三^七>1六4 ;*十#|/4] ",
  },
  {
    codes: "      二|<}.&六.:=.+十.]=",
    descriptions: "/#.六: 5^58\"十*八+十;6 ?五十 6;&# ü4ü1<八>04 _588{_7= 5|<@+8ı&=%|十{",
  },
  {
    codes: "      #<[五.8;.)..九/.1二",
    descriptions: "&八ğ00三\"|?",
  },
  {
    codes: "    九^<&.>\\",
    descriptions: "$;{&五二 4*:]:<=\" (9/#十<,9 <四<(?$)",
  },
  {
    codes: "      560<.12.;%.^_.\"$",
    descriptions: ".四.*8 *%':|9]?四;\\九 十+< 2_5九 ü;ü\\,十}<+ 9]19;]\\, ?'=$六*ı?3/$$四",
  },
  {
    codes: "      <九九'.三^.八十.0*.${",
    descriptions: "九十ğ九二)九'}",
  },
  {
    codes: "    七>一>.19",
    descriptions: "<三<_十0{8 六* 四|.],)< :)4九 /ü|8]ç8_ 四)=11:*7",
  },
  {
    codes: "        $333.5@.&*.39._2",
    descriptions: "二@.*4 5?五(6>>[+&9\\ .\"? 9<|5 ü二ü$68十&% ;===#$九5 0一>/九九ı>[9#八;",
  },
  {
    codes: "        .十八七.|].62..\\.;[",
    descriptions: "2%ğ:五=9+%",
  },
  {
    codes: "        六}四0.二;.^[.=六.=@",
    descriptions: "2>.<% 2八;一六七一三7十() >四) .4+^ ü@ü]43]九/ 9;=39{.} 七:747{ı{>四.=@",
  },
  {
    codes: "        ^十**.6二.\"7.;@.[9",
    descriptions: "&:ğ2四\"%五\"",
  },
  {
    codes: "    7\\}<.\\|",
    descriptions: "八ü,3二 8ö$七.6_ 4:?/ ;ü十八&ç]八 二43五]\"+( (九]十25\\四 #>5二&六]{)",
  },
  {
    codes: "      $$2/.[7.二).*>.九|",
    descriptions: "四;.]7 六&[五'.5八2&?> {八三 ^1\\\\ ü九ü05;#2( |(%@6\\@} 3.十2+$ı)19{;六",
  },
  {
    codes: "      五7\\十.7二.4|.];.三*",
    descriptions: "_<ğ三二)750",
  },
  {
    codes: "    :4'7.,[",
    descriptions: "九@ğ^六0八4#",
  },
  {
    codes: "      九2一4.8[.3八.3六.'(",
    descriptions: "]3.^{ 7@;{&?#6.四&[ \"+} $#)4 ü@ü:=九[.' '562一<六? +1|十八@ı@\\|'=1",
  },
  {
    codes: "      =+九/.^'.%二.)<.5+",
    descriptions: "九/ğ[_{三,{",
  },
  {
    codes: "      &2\"2.<2.0@.\\*./#",
    descriptions: "/.#+五 一%%ı十八6]ı (七89+[]98 (]|5二=ı}411六+ (/0八\"[_八, 一3,三_17",
  },
  {
    codes: "    4一;八.<十.%七.9<.\"[",
    descriptions: "^八}31 六,1ı0四9\"ı (0八45五};_' ?<?.)",
  },
  {
    codes: "      9五}8.^_",
    descriptions: "4一|九)8九).5: ^\\_?&( 十=6四5$8四 3_四ığı |一]7&', /8+@|#50&=, %$\"\\2八#_(",
  },
  {
    codes: "      #%|(.0,.七+.[5.九七",
    descriptions: "65+$}8 ;5\"_五4&2 三3八ığı (ü1ü _二一%[=[<){\\ (\\]'[\"1九 四#;<[十(%/6)",
  },
  {
    codes: "      2二0<.*4.@六.7;.([",
    descriptions: "3九}3+|\"{[ 0ü.ü +*:_六27六>5|; (1八&四|;三 六> /ı十0 三^5,5 04#;}@0八/一$",
  },
  {
    codes: "      二5}<.一0..一.(6.#)",
    descriptions: ".],';<>四 2'>; ç九ç6 九80ığı ($025一(\\( 3]*五六4;六)",
  },
  {
    codes: "      九7'*.九;.}=.(..;七",
    descriptions: "四九\"/三一+ 二?5ı3)+}ı  (/<1&*4[ =[1八,6) &'%*{)71, ^十*二三十六8*3 |\"七.]^.63",
  },
  {
    codes: "    (七27.1,",
    descriptions: "İ^'[2}|7 :/ 1#@一二三& #($''6{28 (:(|(3,0四八 二'..)",
  },
  {
    codes: "      &十八2.%\".{=.]/.一&",
    descriptions: ".[\"}十]:] 一*\"_5({02 (10_1[五??{ \",五=二|<二6)",
  },
  {
    codes: "      6[五=.一+.十9.+一.^3",
    descriptions: "Ş},5 七0+);&八]1 (\\]七八'十4+. 7七;|0?9)",
  },
  {
    codes: "      .05*.=/.一*.&(._*",
    descriptions: "}.ğ@一#&8}",
  },
  {
    codes: "    .,五四.%\\.*(.=6.};",
    descriptions: "^7一_[ (\"七8>@(6/十1一2 ;/?}十,,)",
  },
  {
    codes: "    &&+..#$.67.&>.)+",
    descriptions: "|ı1ıç =4}ığı (@*<四3二1 十7八'9:*) :' \\$) /.1;九2八",
  },
  {
    codes: "    四三一:.9八",
    descriptions: "\\&ğ#((/=\\",
  },
  {
    codes: "        )(9四.6=.\"二.=2.(/",
    descriptions: "%'.25 5?十|六5九<_3&1 _6< <<+= ü二ü$'&21$ 十^{]九}0五 二四+五/$ı\"'*\\六9",
  },
  {
    codes: "        七<+七.二[.四一.8:.[九",
    descriptions: "八}ğ+))5?,",
  },
  {
    codes: "      @]0#.#五.一,.>^.\\\"",
    descriptions: "七0ğ*,2*十:",
  },
  {
    codes: "      ^+#[.}3",
    descriptions: "8[八]]1 %.九ı七%&^ı (八|ğ六|+一 ^3.|?&%ı =$/5_ 八^,@)七, #二?&9\",:",
  },
  {
    codes: "      $\"9?.+}.\"_.*..十九",
    descriptions: "%6ğ+,$三 三7%8九&3ı (66&二> ['4_>八)",
  },
  {
    codes: "      +|6}.:6.^..#].?\\",
    descriptions: "\"]ğ^|%{+八",
  },
  {
    codes: "    ,'九5..(..5.[).^)",
    descriptions: "?#)8:七} (|5^&,6(五八二@': 七^;@<一$;3{)",
  },
  {
    codes: "    ##%+.7<.一7.>一..5",
    descriptions: "'ö]ü二 五8|ığı (六+9七876五)} 四十%<[一)",
  },
  {
    codes: "    ;\\#四.十$",
    descriptions: ",>?+^& 7$}ı[3|/ı (\\&=|:70八;六 {4+., ]六&四7[<;6 1@十.)",
  },
  {
    codes: "        @&2十.&+.?7.*'.[七",
    descriptions: "二ığ /+ .一<6#< &八)ı.*#{ı (+;/&.九$7+} /7九[:]3}) >> 6>)四/十' 四?0九<*",
  },
  {
    codes: "        2[}_.3^.=%.28.}&",
    descriptions: "]ü十,( {六三>;七 ?)_ı三&81ı (<5:\"十三四=七一 ,:\"^_;九6:)",
  },
  {
    codes: "        :7*].4)./一.3\\.*三",
    descriptions: "八}ğ\\^*一*九",
  },
  {
    codes: "      五5$\".+^.>:.二?.0十",
    descriptions: "三3<八六6#$} 六?7;五 九][{5五 九{7ı48,%ı",
  },
  {
    codes: "    :&\\四.}0.七6.一8.+(",
    descriptions: "<*()5/ 五六$7:/ (二:三*/.^{ ?三/四一{)'三\\6十)",
  },
  {
    codes: "    ;=五:.\\&.@\".+三.五四",
    descriptions: "+*二6)3 四[十ı[八5&ı (0=?/五]>八三%\".}6 |73\\|三%四[, ,,=/2*<六|二@九<} (_=,2一]7十)",
  },
  {
    codes: "    十.三{.2二",
    descriptions: ";_ğ?,(?2\" ",
  },
  {
    codes: "      一五3*.7..4].0=.>&",
    descriptions: "四;$)_ @$;一七%+ı (.84,7八十二>八 /^四五/)",
  },
  {
    codes: "      0](,.>+.\"}.]二./_",
    descriptions: "+五一+\\_>\\^ (]二$?\"56四三六 &=_一{7=*{)",
  },
  {
    codes: "      ?一|十.=八.;^.:).八4",
    descriptions: "}九.[{) ()@四\\@6二|1' )7五&({\\3*五) 1:%ı1九_\"ı",
  },
  {
    codes: "      _七*8.6*.四#.##.%?",
    descriptions: "8^&/>0{五 (}6#\\( 4四8.)",
  },
  {
    codes: "      9/七<..八.一五.\").*四",
    descriptions: "四@ğ({#.)|",
  },
  {
    codes: "    四十]八.8,.|>.六五.\\8",
    descriptions: "4四[{2*6 (九)(&0'(+>五1 49].)",
  },
  {
    codes: "    ;%五@.三;.[..>(.|\\",
    descriptions: "$三}ı( (^[7&3<4(0 +@五., #[\\$%五( *&}., ,89)]:9 4=+., 3九*);六_六0 五;+.)",
  },
  {
    codes: "    二六27.9?.08..$.8(",
    descriptions: "二9$*: ][$ığı (9五八:)九>6 [\\4., #;24&{'+八 一/&., ''/$四2203?\"?\\8,#",
  },
  {
    codes: "    ?,六=.%8.7'.(八.\">",
    descriptions: "+ı{:四 六[%ı@二'\\ı (八:*2/_<\\ @八九.)",
  },
  {
    codes: "    六一25.<七.|九.]_.六六",
    descriptions: "#4ğ|^?[\\\\",
  },
  {
    codes: "    .()|.三?",
    descriptions: ".ö<\\' @:七ı3]9_ı",
  },
  {
    codes: "      0&十三.,$.\"四.七].8五",
    descriptions: "四四$^五#_6 .七)4ö% (三七3九%[: ?}/8%{<二6) ;. 八%五八 二>)ığı (/<*:五)(六{:%; =6<.)",
  },
  {
    codes: "      六+/?.;?.(6./}.&三",
    descriptions: "3#( $0一二六 (;66,@ @五0;5)",
  },
  {
    codes: "      \"$七\\.){.%>.8$.2;",
    descriptions: "+4^?{ ,五'ığı (四六}二(%4六 6#&八:3)",
  },
  {
    codes: "      四三0十.<<.:\\.6$.六;",
    descriptions: "#5ğ6:二[二+",
  },
  {
    codes: "    9&\"'.八_.5九.?'.六4",
    descriptions: "]|<三1 )90ığı [=[十三 5[* ?(43,; (=<,:$二\\)]",
  },
  {
    codes: "    三六=>._=.$[.6@.:0",
    descriptions: "八,ş 三'2ı?}九<ı (\\,6++5;+6($[ \\%^.)/五 **] ?九96=\\>",
  },
  {
    codes: "    4*/一.@八",
    descriptions: ",^47二 *二=6一ğ= (九四=3五6十一8]'3/ '32.)",
  },
  {
    codes: "      6四@\\.=8.八\".7\\./十",
    descriptions: "四九0\"0七 @4@|十 %88^_ğ六 (('三_)四/八{&#6} 八四@2一,)",
  },
  {
    codes: "      <>0:.5%.七@.6}.{\"",
    descriptions: "^#ğ_:'八93",
  },
  {
    codes: "    九'2$.#(",
    descriptions: "İ六%=/._;?7[\\\\ (:^四^,0\\;)",
  },
  {
    codes: "      +_(*.2{.九五.三:.>]",
    descriptions: "2,|*ğ[十\" (}|五三}| =(1五\"7) ,8|% 六?*[*\\|} 2'^ 0^:6七<+&ı0? \\)七 )824九?1",
  },
  {
    codes: "      7四,\".%0./@.:/.7.",
    descriptions: "Ç一#4#< ($;6\\+{ \"3[<{\")",
  },
  {
    codes: "      ,,82.:九.1<.九].八\\",
    descriptions: "四*ğ+)#四2^",
  },
  {
    codes: "    八$=三.2(",
    descriptions: ":_ğ三)八*)3 ",
  },
  {
    codes: "      四四三八.]0.&\\.&>.2八",
    descriptions: "\\8一)ı,0 七;+ı&%<|ı",
  },
  {
    codes: "          55_+...._'.;<.97",
    descriptions: "七一.(^ ,(九8<\":0二2八2 |.$ /9四, ü)ü0})8;8 四]/二[3*9 $%}76=ı$\\/[*9",
  },
  {
    codes: "          ?四&@.']._{.2\".?五",
    descriptions: "九,ğ6>88,;",
  },
  {
    codes: "          五四87.%6.,,._'.3二",
    descriptions: "\\6#:.十四; 一八}<?六+ 十ü六ü{>4> (>3三6}5",
  },
  {
    codes: "          八_9=.*].\\?._/.*'",
    descriptions: ".?ğ>&八,:3",
  },
  {
    codes: "        }{+^.七九.}3.,五.五+",
    descriptions: "Ç}(79 ^+四ı(&51ı ((,四十+ [:..)",
  },
  {
    codes: "        四,|'.七六.三@.?^.1/",
    descriptions: "3$九0{ /一4ı8十'(ı (}九六61四? ?九5.)",
  },
  {
    codes: "          )^'..#$.1|.6].;六",
    descriptions: "一)十{ _+4十#): (七]\"6{7:四}\\ =0;;4,63)",
  },
  {
    codes: "          [}5:.|六.\\(.*^.*{",
    descriptions: "/&2\"|ö[ (/')^ 五'+七三ğ#)",
  },
  {
    codes: "          #九}+.][.#七.+.._<",
    descriptions: "=ü2üş >二2ığı",
  },
  {
    codes: "          |一44.<..五五.#].九一",
    descriptions: "{*ğ*@一五4十",
  },
  {
    codes: "    9六三=.',.三+..@._*",
    descriptions: "(*二2^:ğ9,><0, ;%1=;=一7){, ${3@/+|@, 八}九{|{^:",
  },
  {
    codes: "    4六;%.\"|.,6.$&.}9",
    descriptions: "{ö'?&'2.ığı |ü<>%ç五.8_",
  },
  {
    codes: "    七<六&.十?.六@.二}.#*",
    descriptions: "^[ğ二(2'(<",
  },
  {
    codes: "@\\.四;",
    descriptions: "&三*ı4四|4 (三\".五>一6_)0ş)  (^_.,三 二8&>06[%<64,$3 94/ı_ 九^三,'二6二}ı <6",
  },
  {
    codes: "    ';<二.6十.=/.#+.$=",
    descriptions: "三}]%4四, #;三_75 (八七^ :#三&0五)(六1_\\?,2十(:}. \"|;\"&)",
  },
  {
    codes: "    二2一5.[|.三$.\\#.^{",
    descriptions: "][ğ/| *6$3四[1 0^4?<=八\"ı (+二4八31六.二(<# 三|{+'^^7一,",
  },
  {
    codes: "    七|?&.{&._(.**.0七",
    descriptions: "/.三#622; /]%1'一8七ı (九7&七} 四}四一&) :: 8#0# {}#{六三);ı (_?八九} 449,6)",
  },
  {
    codes: "    3|六;.22",
    descriptions: "十三1>+\"ı+*89 (#\\9{< @,三/3:, 3二??@2/九5,?@ 5>1/9;, 二^;{{'&,<四9%",
  },
  {
    codes: "      \\\"}8.7六.0).5二.三&",
    descriptions: "*9\"2{?712|/2 (32}三. \"*九9  }}7三[(九,}[8% 1]},#(@二'?{; 九ü七ü}五五四 _*)=?(一",
  },
  {
    codes: "      .3五3.0^.七九.#,.&0",
    descriptions: "@_八0{[\\7)8一9  :{]?.+  [ü&ü]八#八, ;8_&/49*#% &ğı?+ığı ;,* \\5.#^九 $69八[,",
  },
  {
    codes: "      )&六#.十).=六.3|.<三",
    descriptions: "六+ğ:<\"二一@",
  },
  {
    codes: "    一一3#.\";.十六.四(.($",
    descriptions: "(.ğ?+十四#?",
  },
  {
    codes: "    '(3>.+;.%/.+5.%]",
    descriptions: ">?\"'%二$ (2_五=八<五8六{' 89九.)",
  },
  {
    codes: "    \\#?%.5?.三[.6,.九2",
    descriptions: "3/4ı> (27二2一$\"九8 :4>., \\*56&九7 七>+., &>七'_}] 五七'., %七:=3()%0 四^9.)",
  },
  {
    codes: "    六'四|.6'.&?.\").\"*",
    descriptions: "十/*%八 ::{ığı (一}%六)4+7 $一(., =]5?&=:=* ':>., [:+<[/(9六%\\{\"一&3",
  },
  {
    codes: "    四%:二.#6.)7./三.十}",
    descriptions: "二ı\"六3 ;9?ı,>三]ı (9?86:\"3) /*6.)",
  },
  {
    codes: "    {]六^.(七.46.九,.)>",
    descriptions: "四_ğ75'3\\二",
  },
  {
    codes: "    +%*,.四八",
    descriptions: "[ü五ü6 十/2ı4]?三ı (一]7].5十十九2<) 5^/@_8{十_@&\\5?六, 六1>3^\"5\\7'四,",
  },
  {
    codes: "      &'@%.五$.]十.@&.[}",
    descriptions: "+%)'* 十ü0ü| 7八?ı_=:三ı (>2六7$=\\@'2/% [7)1}@\"1^9|?.$[)",
  },
  {
    codes: "      #0=五.2^.33.9%.*9",
    descriptions: "八.*/&{七五 _ü/ü| }@+ı4)+'ı (_,5九:+];.7四1 ^]\\*[3589$七:)",
  },
  {
    codes: "      ,.),.九&.=#.0(._,",
    descriptions: "[{*_)九@ 3ü{ü六 9{1ı\\六2>ı (6<1)}2七9;一74 [|=六九%四?1})",
  },
  {
    codes: "    +一15.[1.五;.\\'.$六",
    descriptions: ",%?十ı('ı 87二' ;;9ığı ()4?^十]=\\$9<' .>:7?.十0)",
  },
  {
    codes: "    五7'四.;&.*8.78.14",
    descriptions: "三;( [^\"ığı (九|二\\( 三0五.)",
  },
  {
    codes: "    十^[二.=[.2*.:{.二|",
    descriptions: "+/3*;& 8>@ığı (8|六5/_ ]6:90七)",
  },
  {
    codes: "    9?.3.8] ",
    descriptions: "&)ğ1(+#(|",
  },
  {
    codes: "      ]七[八.四*.四0.\"5.8七",
    descriptions: "0>六4 @{=六32 (九7,(_1三']< @九|1<[)",
  },
  {
    codes: "      十四(4.8^.6:.十九.87",
    descriptions: ")%)三'.]/4.5 }?5/0 ^四七ı62\\\\",
  },
  {
    codes: "      :<六一.]_.21.7=.>^",
    descriptions: "*五二十'@九,> 0,九*@十,@7六 >\"五< <一1%65)^9二?十+ };二/$五?/<^1*}7@",
  },
  {
    codes: "      \\^(五.@).}).2=.十+",
    descriptions: "[&ğ四;85一二",
  },
  {
    codes: "    {<_7.,4",
    descriptions: "六}8@&6*. 二二16 \\8五九 }ü?;0ç?5 4@^ /<一ığı (十0#十一十十 6,七{二8=九)",
  },
  {
    codes: "      四})#.一+.二十.)_.)七",
    descriptions: "/&.{十 5二\\*二<7五\\('' _十\" \"6=+ ü?ü@>+5:$ ,&八*_+]? 7?7(+5ı5七{'$0",
  },
  {
    codes: "      <:7}.\"3.?5.26.?七",
    descriptions: "[三ğ,七4六'五",
  },
  {
    codes: "    $5*%.七\"",
    descriptions: "十@_ı |ü|]%ç&@ 十>%_>}< ():3?,;} 八0$5+={{十)",
  },
  {
    codes: "      |@\"+.'4.十=.九5.8九",
    descriptions: ":).[^ 八$一'\"%86.:,[ 5&4 ><_0 ü9ü2})三十8 8_/三46\\八 /.,八$3ı\\#9&'\"",
  },
  {
    codes: "      三6{@.%八.,,.\"^.1&",
    descriptions: "7}ğ{==5三{",
  },
  {
    codes: "    9]^4.\"\\",
    descriptions: ";?{三[26, (&二_ı'ı 1.*三+五*) [830}5,:_%) {一九@|\\4] ",
  },
  {
    codes: "      01$@.$#._六..四.>六",
    descriptions: "@1.:十 .5$四_#&?_=39 6}, \"1,8 ü{ü<*十0\"四 7>+<)十|4 >'15五*ı'4五十6,",
  },
  {
    codes: "      /{4;.0..]4.七\\.?&",
    descriptions: ",)ğ九}}+}&",
  },
  {
    codes: "    十^11.(1",
    descriptions: "?47[+3 ][[二>>1: (\\]^.*十四 /[907])",
  },
  {
    codes: "      \\$}一._&.\"(.47.四十",
    descriptions: "4\\.一0 ]2_六610九?二_八 二八# \\]/1 ü8ü+四>.48 4八@<0(3> %%/1%<ı]九3一:[",
  },
  {
    codes: "      9[2)./>.4八..+.]&",
    descriptions: "八+ğ二六9]^十",
  },
  {
    codes: "    ]6&/.)8",
    descriptions: "_:4[七?/[ ]_ ,+]2#81 [一三2 \\ü10|ç{2 九六六?|^$| (%#9>6二8 :%\"1;;九,",
  },
  {
    codes: "        \"十3六.5#.五?.17.76",
    descriptions: "八).'6 7十\\:1^=[85<. #+十 二九8& ü6ü八%:202 ,(5十五%.\\ :九>%62ı*$.十]七",
  },
  {
    codes: "        +9]4.{'.$二.7\".@7",
    descriptions: "9*ğ2一<1三(",
  },
  {
    codes: "        \"$#_.<$.;;.$*.8(",
    descriptions: "/'.七# }/一$五五+十13^# #&六 ['5\" ü@ü二:0五八8 [十$\"{十1] 八@[=一四ı七:七4>'",
  },
  {
    codes: "        ;|二十.#@.0十._六.5&",
    descriptions: "}}ğ{&一.四5",
  },
  {
    codes: "    6:\\&.\"|",
    descriptions: "6ü五八0 三ö五|@^3 ,\\九十 4ü$7+ç73 _%];四+:} (四$1三<:) ?七@#;@#>)",
  },
  {
    codes: "      _{&(.81.七1.十一.八=",
    descriptions: ">四.3' ,九(}\"一'^%]94 (九| 四..三 ü7ü745三.\" =2@80:55 \"七1@0%ı#5)#七0",
  },
  {
    codes: "      _%)一.?;.@=.3二.#_",
    descriptions: "七)ğ四$四><四",
  },
  {
    codes: "    28*/.2}",
    descriptions: "0八ğ3:9五*0",
  },
  {
    codes: "      ({.9.<一.一^.六).==",
    descriptions: "8八.七[ 0|{六;_'*一^6; 00二 ^8六% ü%ü二&十十四] 3\"9七7$[@ ${]七+(ı?:6>'六",
  },
  {
    codes: "      /三1]./).&四.^十.十8",
    descriptions: "(<ğ%:9<+七",
  },
  {
    codes: "      ><:+.;}.九'.}'.六[",
    descriptions: ",:33' 五@@ı\"3@;ı (二七4\"?5;{ (\"%三七'ı51一>八7 \\二.一*二&&, {(:_>|七 |五八.1二ı",
  },
  {
    codes: "      &6/;.三{",
    descriptions: "0\";二4+|:[2} (三,:;十\\ \":*3二;+8 $]|ığı 012二78+ *78?(4九*二@,",
  },
  {
    codes: "      >>/).$[.$<.09./七",
    descriptions: "*{五83, 5>@#8十十& >|?ığı :ü六ü 5:十六#8*6;五? (六<;三.九} $<:]'}>(:二)",
  },
  {
    codes: "      $十&#.5/.)].?%.}二",
    descriptions: "+]<&#[7九* 'ü一ü 八/%9:7=三^28; 十^五八2$八1 &6 |ı<? .>808 $八/;_+^六$;?",
  },
  {
    codes: "      $\\>).)九.7_.(/.\"=",
    descriptions: "#2)^{_>5 $8六^ ç6ç九 {:^ığı (?]0(7十@六 '\">7\\',2)",
  },
  {
    codes: "      @'8|.7<",
    descriptions: "}/_.5)) 36二ı$@{5ı ($0_五四[9 十7/7四>\\ $%9_十*2}, )${+四'七0()",
  },
  {
    codes: "      >二\"(.9:.十%.八@.#?",
    descriptions: "_,=**$^ (}]*]\"5五 }一+:?/=.) (5三# 53(\\8五 (,三\\#|1_ $?)0_八,8六)",
  },
  {
    codes: "      ?^九?.'{.*八.8_.70",
    descriptions: "6*1#三一;}}# @2二.09五四\" (<一*七(\"六 7>7'七'五四6=)8')",
  },
  {
    codes: "    六60#.六9",
    descriptions: "İ4]571$十 '; 7}8__=六 <二@四%=;=| (*.[三\\.一,\\ 3\\9.)",
  },
  {
    codes: "      &.]@.3(.{:.9九.1五",
    descriptions: "九1?\"六&六4 一3?]\",(.四 (三$5?;八1_# *,1|@]_(六)",
  },
  {
    codes: "      |十<%.>8.七(.五'.四9",
    descriptions: "Ş<五二 \"/7/18928 ()0'\"(|@0{ {九+&四}1)",
  },
  {
    codes: "      \\*一六.'四.14.>;.7_",
    descriptions: "0\"ğ['3九*^",
  },
  {
    codes: "    4<一#.:>.九4.|一.]|",
    descriptions: "{+_@[ (9九8九48*八七#@{ {31九[}^)",
  },
  {
    codes: "    ^\"8十.;三.#'.^>.<)",
    descriptions: "三ı=ıç =五]ığı (>)一@#<七 *{九/(#五)",
  },
  {
    codes: "    =十)8.5?",
    descriptions: ":二ğ/2;七'七",
  },
  {
    codes: "      *<,八.五一.六@.十五..十",
    descriptions: "=^3\\2 &>\\ı1九,九ı ():13一4;&< >^$.)",
  },
  {
    codes: "        $三七^.|+.{2.{1.三>",
    descriptions: "二*.>3 <1}2#六:7九|,| <三四 五%<二 ü/ü2,>三$3 二二二?&\\9( (^#0:<ı六;2)\".",
  },
  {
    codes: "        六}=\".\"..&#.^二..)",
    descriptions: "3%ğ'?/#))",
  },
  {
    codes: "      =[1[.#+./一.|+.7_",
    descriptions: "%/ğ四\\>一十\\",
  },
  {
    codes: "        /('&.八/",
    descriptions: "2,.44( ;九9ı9\"\"\"ı (/\\ğ.十|8 3)三3五)一ı|八五三} 十_4九[*, '二66五|\\= 一6\"*+43ı",
  },
  {
    codes: "      '%(9.}).&4.&0._5",
    descriptions: "'(ğ^四2\\ )84.$\\五ı (二(3$. <#>:.*)",
  },
  {
    codes: "      }@:'.93..二.2|.\\\"",
    descriptions: "74.?四.'9 9,#\"三/9ı (8%^_2 |04+)",
  },
  {
    codes: "      .\"/2.+五.4].%5._{",
    descriptions: "*#}七(#* 5\"&七\"_六ı (?/4|$ 一8.十7_}10//:>)",
  },
  {
    codes: "    /7六二.<+.)}._|.二九",
    descriptions: "+*1二一一: (;'+><*十/^三)6\" 9)+{,%|0|3)",
  },
  {
    codes: "    #>十+.八七.}@.<5.],",
    descriptions: "五ö*ü\\ ?;&ığı (?_四二_十三=<二 $七<{,三)",
  },
  {
    codes: "    +2][.&}",
    descriptions: "+%+二九* *[7ı[,七四ı (八^|十8\"<九一| 二_%., 21八*<\\{$; 十,..)",
  },
  {
    codes: " ",
    descriptions: " - - - 8*'{};+七83 六}六[; /六2/5, @@&ı+?&五ı",
  },
  {
    codes: "        一'?2.9\\.^[..4.>]",
    descriptions: "3ığ 1. 7]?9九= 二88ı七+3*ı (*/六六1*3343 '二9?六7#}) =1 七(^{\"44 5?1六八?",
  },
  {
    codes: " ",
    descriptions: "           $\\_ı{:94ı (]%2+<5八}6一 8${1十=)七7)",
  },
  {
    codes: "        2四'4.九}.九(.__.@(",
    descriptions: "**&=[{%六 +\"%三+, $\"4ı一$:十ı (1ü五($四;2ı \\3<:\"%{6 9|9%,3 $99ı+,\\}ı)",
  },
  {
    codes: "        ],三_.8,.]&.六\\.](",
    descriptions: ">ü五_8 0%6_&2 二一;ı七\\十九ı (:三+8五=$/\\4 三<0|0$.\\))",
  },
  {
    codes: "        /10#.58.四六.,&._三",
    descriptions: "六\\ğ#{\\\\9?",
  },
  {
    codes: "      $1}].九8.+八.1二.?[",
    descriptions: "{_.>|^0|& @^,0( 八@8一二< 77+ı二@2_ı",
  },
  {
    codes: "    /{*9.^四.(1.十一.((",
    descriptions: "|}&^+] ?|%六*< 1$6ı>五<十ı (十(七(]^五\" ^.>四<88一/$|,)",
  },
  {
    codes: "    [%七6.5[",
    descriptions: "##6三一5 十六2ı6七5^ı (五7/,)四]?+0\\/$; 六5{>32#8%, 7\"@/=八/七+69{/五 7$*>四?|九2)",
  },
  {
    codes: "      ?}=8.+^.'?.+五.9=",
    descriptions: "2.三\\\\7 \"九{ı\"_三^ı (#八[;9*(,六六;;&六 ]二003九,?三 $八三\\ _)七|& 五'6=十?^六.)",
  },
  {
    codes: "      $=$七.';.&$.,#.^一",
    descriptions: "[%,一一七二2]' |\\二6 3+,%二75 ( 1%+五6?+>#2&'_ 四0^4,@[+2)",
  },
  {
    codes: "    八/.七.>一",
    descriptions: "四)ğ>\\.<十7",
  },
  {
    codes: "      ,七七}.|3.\\七.*#.#(",
    descriptions: "{?'|3 _{一7>三四ı (一9[十{96?7< ]{9_2)",
  },
  {
    codes: "      &六?=.#三.6}.七..<=",
    descriptions: ",五(七六2一^[ (<五7五},\"3四. 8*9=三八八19)",
  },
  {
    codes: "      .九50.1[.六].?四.>.",
    descriptions: "+&1;八| (=0[_十6,[(; 45七@.*八<(<) #(7ı?{]5ı",
  },
  {
    codes: "      @<%,.(].%%.@).++",
    descriptions: ".6;一 .>^2(28]八 (+九49$九\\\\<八 $5}$.?0&三[_48)])",
  },
  {
    codes: "      .;{'.二&.]一.//.8@",
    descriptions: "$.3&((2五 (8九{#3 ,/五.)",
  },
  {
    codes: "      八^一八.七五.>;.9(.@三",
    descriptions: "?1ğ/&二八.;",
  },
  {
    codes: "    ?2&9./[",
    descriptions: "3ö六2四 _:$ı+|{5ı",
  },
  {
    codes: "      :\"&,.\\1.;<.9:./5",
    descriptions: "2+|9(*80 ._)<ö$ (九(>3{&] |<6^七)四\"$) {> 7;:# :}.ığı (一九][;,/四@9&? ][}.)",
  },
  {
    codes: "      7.*三.(?.${.2:.$六",
    descriptions: ">/,;三>十. [ö,(% /一6ığı (>&^:二 ]五6'*)",
  },
  {
    codes: "      0九;).四&.|$.八8.三#",
    descriptions: "[七=\\: 六六0ığı ((0:2)八[二 五四'?'8)",
  },
  {
    codes: "      \\([1.)[.十}.一十.二1",
    descriptions: "7=ğ,七$%[#",
  },
  {
    codes: "    +{>@.$=._九.七2.//",
    descriptions: ";}八/% 6|'ığı [7;%/ 五?一 @;)一|{ (七32七}99)]",
  },
  {
    codes: "    8;;十.3=.十5.{九.七0",
    descriptions: ":九ş (9七ı八914ı (五4.?'_,五}6$3 *5/.)%| 7>( @)九=(48",
  },
  {
    codes: "    七<3七.六\\",
    descriptions: ")一#二$ ]|\\#六ğ五 (_}三=三八\"四\"十1], _^七.)",
  },
  {
    codes: "      1$_6.六*.'}.八%.#9",
    descriptions: "6\\九(:+ 9一)+9 8;五,\"ğ] (]|}|六四二4)三7一@ 九?(}&;)",
  },
  {
    codes: "      6*{4.4..一+.#五.>八",
    descriptions: "十\\ğ十<.\\五2",
  },
  {
    codes: "    十*四二.九?",
    descriptions: ",/ğ\\五#\\87 ",
  },
  {
    codes: "      |'八1.42.九].六)._(",
    descriptions: ")1四9ı,@ {(五ı二4^九ı",
  },
  {
    codes: "          #{:三.>..十2.)七.|六",
    descriptions: "#5.3, {?^\"九九.九*三{八 八72 <六2} ü_ü四{九四九' }($\\\\六八2 /%2:{|ı&九\"八3二",
  },
  {
    codes: "          八$八7.5八.;+.5:.\\$",
    descriptions: ";@ğ%&?}69",
  },
  {
    codes: "          二?*七.六^.[九.{@.六(",
    descriptions: "五#+/^89/ _.1:[八$ /ü三ü^6._ }<1&;)(",
  },
  {
    codes: "          ::七1.)}./:.[/.六五",
    descriptions: "二六ğ%98七7.",
  },
  {
    codes: "          六[十:.2_.十@.三{.\\5",
    descriptions: "/?*,>九^3{九&> (>?]'\"/{5): ;一><三)三7) (<;五. <六,ı=>)}",
  },
  {
    codes: "        \"(.[.?;.*0.*4.%?",
    descriptions: "十<$<&一 五5九二]_, 7'8十@七八& 7^四 ]%[$9+/;ı(\" 1七\" 0三;五$8$",
  },
  {
    codes: "        &一@=.九3.63.^六.>+",
    descriptions: "Ç四<>65 ()8+{*0 =;]\"+4)",
  },
  {
    codes: "        2[^五.\\^.)).4*.2?",
    descriptions: "Ç=\\0四 []0ı={\"]ı (9[一|+ 五#8.)",
  },
  {
    codes: "        二)${.<<.9>.^8.\\]",
    descriptions: "]]3*; &\"/ı%三2)ı (5#^2&+& ^3七.)",
  },
  {
    codes: "          04_二.(2.[0.#二.$$",
    descriptions: "9})七 <|0?@二1 (@五6六(2\"6[' %一=七|5&7)",
  },
  {
    codes: "          .852.六;.'$.#0.},",
    descriptions: "九0十'1ö七 (1\"={ ]#\\98ğ+)",
  },
  {
    codes: "          =\"@=..5.08.:,.七>",
    descriptions: "[ü{üş *;)ığı",
  },
  {
    codes: "          }$二一.}_.{+.九*.^*",
    descriptions: "0]ğ+/$(2四",
  },
  {
    codes: "    \"*\\'.4;",
    descriptions: "]/[}$+ğ<十=%], %@^>*+七.&6, ;$四6$?%8, *9九二}八|,",
  },
  {
    codes: "      [5三3.;'./8.三?.@4",
    descriptions: "9}3=四二:/0'@ü66&*] \"1,8 12 ]八\\八?2;\" 6ü十7}^ı> [}4七3=/ 425{:^",
  },
  {
    codes: "      53@0.,0.>&.7*.}]",
    descriptions: "06ğ&&,[3:",
  },
  {
    codes: "    七8=^./$..+.一八.*八",
    descriptions: "[ö]{)_@6ığı 十ü@*'ç七二;?",
  },
  {
    codes: "    #五@3.[_.[五.'六.;_",
    descriptions: "|4ğ<二二六\\7",
  },
  {
    codes: "80.六@",
    descriptions: "7四七ı4 %&一{#六?三|ı 三> 7@ğ二四 95<ı) 6/'21. (*ı二ı._ış [;%[$ 0九?'二ı十) (五4:*,",
  },
  {
    codes: "    &二}#.3}.)?.'七./;",
    descriptions: "@>@5.0$ }]>ığı (|;\\83^;,六)] 6+].)",
  },
  {
    codes: "    %=*2.六八.七=.一=.七]",
    descriptions: "9/@ı+ [0八ığı (;3@]二{二|六 \\\\二.,'#2=四)七 8七一., =?;*9六一 ,,6., $2'#7[+7? 81<.)",
  },
  {
    codes: "    %/2五.二^.<六.$[.六8",
    descriptions: "$(? >%@1'ğ五 (}<<一0 十3二%四0#4九)",
  },
  {
    codes: "    一<;六.四[.<二.'4.60",
    descriptions: "'十ğ|=二+九?",
  },
  {
    codes: "    74六三.四4.<一.'4.九]",
    descriptions: "二(4#1\\五 四$]七.0二1ı (十?#九|九}:1二%六 $/3#,, :三.|7)('2一/9",
  },
  {
    codes: "    >}4>.一'",
    descriptions: "#,0'7(ı< (>十/@十 九1&]@2, ?82五54^七^_{| 四1._八[, >\\七=,;%四一九1六 0五0[五3,",
  },
  {
    codes: "      .2}三.;六.六|.;\\.十6",
    descriptions: ",=\"二4'<三\\^七9 242>]九 八ü二ü\\>:/ [:+/一)'^ 2ğı]4ığı ,/十 0#.十八/ ?二二十(",
  },
  {
    codes: "      五九)8.{<.九(.\"五.[^",
    descriptions: "+九一;9八\\3四一一7 @六十,/= <十>_ |{二{>五,;%';八 七五\\{>八十(,@)# )ü?ü+_}一",
  },
  {
    codes: "      30*三.'[.+/.{*.4,",
    descriptions: "}.ğ)3六五1\\",
  },
  {
    codes: "    ^四/4.,+.八3.9九.三2",
    descriptions: ">^03ı 三=5ı14五七 ({|$3一0,9,2:&(一, 8^一\\{9六?, ,十__@十3+一9\"七[,",
  },
  {
    codes: "    827..+:",
    descriptions: "二五_二八*3,五=四七,#/, :<).?:+]七二/七;%, &.(.[#三, %七(四5(1_九一+,",
  },
  {
    codes: "          8四九3.@二._]._九.一?",
    descriptions: "]%五*47 一]+ı23?七ı (#+ğ'\"9* :8*#>$+ı $六九二2 六6%二六7,$:一+..九\" 6七六+>$7ı",
  },
  {
    codes: "      九*9=.?<.01.[\\.\":",
    descriptions: "&ö5ü三 九47ığı (0二2%<21六;} {:六{+%)",
  },
  {
    codes: "      三0=1./7.'一.{@.七三",
    descriptions: "3$ğ十一>=八:",
  },
  {
    codes: "    6_&=.3,.七>.二;.1<",
    descriptions: "9ı5ıç \"三=ığı (8八[|[十七 =十\\六4$.)",
  },
  {
    codes: "    7&\\,.&;.09.二四.八|",
    descriptions: "1/ş 六_:ı.=五*ı (0#%>@)/]/{>一 ^}+.)",
  },
  {
    codes: "    [}(<.7>",
    descriptions: "九ö0=^ }+/ı二+'5ı",
  },
  {
    codes: "      ?5+8.\"].{1.八3..九",
    descriptions: "._1{+>二七 @;%%ö. (九[6八]$十 ]''|%七}>六) \\| 3三*3 ,_6ığı (/>?7]六.9九<6+ .%:.)",
  },
  {
    codes: "      <十七=.$).<7.6>.#|",
    descriptions: "69+*&$|一 四ö'^九 (一'ığı (/6}9. _4@($)",
  },
  {
    codes: "      35九|.(?.[].,/.6=",
    descriptions: "263一七 一[二ığı (._[1二8]. 五}八+四6)",
  },
  {
    codes: "      |六_*.九'.*:.,/.9,",
    descriptions: "八3ğ$七$九=<",
  },
  {
    codes: "    8{十8.4&.一2./*.^%",
    descriptions: ")]十三七 %3[ığı  [8<)/ ;_@ <3五(五{ (0.八(:?/)]",
  },
  {
    codes: "    二%0$.九五",
    descriptions: "九十ğ:;五*=九",
  },
  {
    codes: "        ?}|八.<_.}>.,+.一.",
    descriptions: "十\"[1= ]七\"ığı (八<;4,[ /六'七#.#@.])",
  },
  {
    codes: "        九;\\九.6].^7.($.^8",
    descriptions: ".3ğ2@二373",
  },
  {
    codes: "        六1二].六:.0\".*5.三2",
    descriptions: "六ı八;ı[ı 2$%ı( (3\"六^/?十八 03..)",
  },
  {
    codes: "        7;),.七八.^\".$%.]|",
    descriptions: "61ğ3:{5<,",
  },
  {
    codes: "    ;%>四.九\\.3三.$7.)<",
    descriptions: "四\\九%^5( (^一@二9_^%\"9/ 五三{.), $9三ı+ 627ığı (8三=\\*[3*9 *3\\.,",
  },
  {
    codes: "    1@+六.#4.58.0#.(,",
    descriptions: "4?2>'七ı/\">3*76",
  },
  {
    codes: "    4(_;.四=.%=.(2.33",
    descriptions: "27&:)0/\"=5,*%\"五, .\\_6}76){&7二>,, >[一一9三4, ?\\=[{{?,*六5,",
  },
  {
    codes: "    ?(+].#+.一;.,$.:>",
    descriptions: "<ı<ıç +)\\ığı (\\:$;078 ;;;1[9>)",
  },
  {
    codes: "    {*:六.七\\.<7.\\七.二四",
    descriptions: "0&ş (七*ı_/,:ı ([2#1=|^^.一@4 '02.)",
  },
  {
    codes: "    .4*<.;$",
    descriptions: "$ö)八_ 7九]ı五{?*ı",
  },
  {
    codes: "      %七6:.,(.8].}4.九{",
    descriptions: "<$).&52一 }三$'ö二 ($:(98<九 4&:)>+八;九) )十 '=_四 )}二ığı (^7/六\\五)5\"{%十 &>十.)",
  },
  {
    codes: "      ,[,四.五?.&十.6七.9七",
    descriptions: "\"4{9='七三 ,ö十<三 六二=ığı (%#}\"} @}?*$)",
  },
  {
    codes: "      $六][.四$.1&.$七.[\\",
    descriptions: "{}]2) &'|ığı (二62}\"'{| ?八\"3五))",
  },
  {
    codes: "      一\"?7.7..十3.二8.七|",
    descriptions: "三\"ğ$.@4;九",
  },
  {
    codes: "    ?>%{.:六.,2.九[.六{",
    descriptions: "/._5二 \"\\三ığı  [:七+| 3@) 八四]\"#_ (五(.7?4五)]",
  },
  {
    codes: "      六七_8.\"五.=+.[^.@.",
    descriptions: "三四)<ı 4' {0五ı@?,六ı",
  },
  {
    codes: "        ,\",%.*%.&).六_._#",
    descriptions: "四:\",4 八_,ığı 938>1四一ı",
  },
  {
    codes: "        七*四:.^9.4?.+*.;\\",
    descriptions: "$&ğ@<|一:'",
  },
  {
    codes: "    \"[&>.5&.8'.[>.2=",
    descriptions: ")'#二*,) ;>6ığı (?//50三+六_#四 6五).)",
  },
  {
    codes: "    五+{5.<三.%\".>@.六4",
    descriptions: "|.|ı& 十%7ığı ([;62二>#}十 五八一.,$<&5一{[ 七5{., 六1五|529 三七十., 1,:*五%六58 :3@.)",
  },
  {
    codes: "    &七%\\.,%.[=.\"1.)^",
    descriptions: "+[七 8|)9八ğ2 (@}_+1 一十十六'4二9八)",
  },
  {
    codes: "    [_7*.>一.0+.九五.[七",
    descriptions: "40ğ?1#3+|",
  },
  {
    codes: "        )8^..9{",
    descriptions: "^五@$)/ 5>四ı9']=ı (&九ğ$(_十 \\,[\"<,|ı 5\"\"0{ 1*#六\\0,$_7#十$54 五1三四{36ı",
  },
  {
    codes: "      _;]@.>{.;&.{6.|3",
    descriptions: "一%二+@|' 4+^六%8'ı (]二五<. '六:7/_六4?2/二三)",
  },
  {
    codes: "      ((\"..六(.72.^=.7\"",
    descriptions: "/9ğ二&;^|一",
  },
  {
    codes: "    /一;\\.*@.;九.91.$>",
    descriptions: "二0(7225 (*3:\\\"四}3一'6;9 :%\"8{\\;.$*)",
  },
  {
    codes: "    ?二0\".(0.八}.@三.六五",
    descriptions: "7ö8ü. 四^:ığı (12']八[/七8& 四五四|7])",
  },
  {
    codes: "    4九31.{$",
    descriptions: "}_[?68 +\"六ı>770ı (]$|}.\"_'\\_ 7[5.,}4*0\\(0|: '\\,.)",
  },
  {
    codes: "        9,\"\".{\".5?.十>.,{",
    descriptions: "}ığ ;八 四三=二<^ [^'ı二&<(ı ($':六^$#447 }5^($&九九)  二, 二七4.<?: &@9005",
  },
  {
    codes: "          [0.:.%一.@..?@.4*",
    descriptions: "/.>六七*{= }&七三二五 >7>ı%+9<ı (;ü^>4(\"一ı ?:)7(,四4 八6*9)9 六00ı@&;4ı) (&};三[(",
  },
  {
    codes: "        4')$.十5.八)....]>",
    descriptions: "2八ğ\"$^|/.",
  },
  {
    codes: "      \"04?.:八.0三.1六.三4",
    descriptions: "1\"*<.*</8 |0一一# 4@8[9.416",
  },
  {
    codes: "    \\@三%.#五..@.+\\.+$",
    descriptions: "/&$<}@ 1*\"一(\" .\\7ı:;三.ı (一}1\"_303 @九}129}'(2=六)",
  },
  {
    codes: "    =7一9.0'",
    descriptions: "@)ğ一六}*#五",
  },
  {
    codes: "      &\\8七.'(.*3.[七..#",
    descriptions: "$\\9[: ./+四25&ı (5}4九六4*3一( 九<$|9 六ü9ü ''五ı十三>七)",
  },
  {
    codes: "      (_\";.三;.0>.){.七/",
    descriptions: "7六|):? 十三1ı九@'十ı (}13/4]@四五@ %八$#七/^#一)",
  },
  {
    codes: "      %一四'.>七.>'.(..{@",
    descriptions: "6七八二 ;}\"*}/_8五 (二5[7<*六五#4 二6]+?'1#,*',)|)",
  },
  {
    codes: "      ?6九&.^2.0[.十(..(",
    descriptions: "五${?三56] &=+ığı",
  },
  {
    codes: "      +#82.(#.四..=9.六<",
    descriptions: "(九ğ@|7+七1",
  },
  {
    codes: "    九&四+.6].八二.>%.85",
    descriptions: "]四3{5_九 *.十五,{.+ı (十6四|6\\|1*二&{ :}六,二, \"三一七@+76>[_三",
  },
  {
    codes: "    0,2$.<<",
    descriptions: "五}\"十}<ı^ (+五36十 一+0[^二, ||1^53{{:7;$ [,&3一9, *4(九+7,&十;5? $三=五\\+,",
  },
  {
    codes: "      .|9十.1|.)%.1{.三#",
    descriptions: "6)@2{一三*>;&( 一五,965 +ü.ü,;五: [\\十2[8八/ :ğı>;ığı 3八\\ 十?.=:8 8)[6七 $_八&.^]",
  },
  {
    codes: "      (&十二.)六.四;.'七.二/",
    descriptions: "%'&4<4%2]\\7^ 七5四一// \"[2* {3%^:.{{,8八7 7%九八|139)5八_ ^ü_ü十08+",
  },
  {
    codes: "      3:'}.\\^.五0.=0.@0",
    descriptions: "'四ğ30\\{;2",
  },
  {
    codes: "    |8,一.?0",
    descriptions: "}十1>ı 39六ı六3+% (\\9({02四]八@三九(三, |五}'%六9|, /八?_/^]六52*}[,",
  },
  {
    codes: "      \\**八.\"4.|=.9十.86",
    descriptions: "*/|=ı七?ı ?}7$ .9]ığı (+%\\=1++4\"三'\" 五2(|',6>)",
  },
  {
    codes: "      *0|}.'六.4&.@三.七十",
    descriptions: "九_\"\\ 9(五/$8 (\\1八七.\"十)1@6'九六;,)",
  },
  {
    codes: "      #@\\|.47.(,.?六.*;",
    descriptions: "八ö/: 二{@|]6ı ;;*8 (8三>_0;六{)三]*. )(<.)",
  },
  {
    codes: "      :_??.六三.+[.'九.)\\",
    descriptions: "五\"ğ六六'%:.",
  },
  {
    codes: "    三十/+.5\".4五.:^.7?",
    descriptions: "二ı#ıç ;@^ığı (*]2[9@1 38四三0.二)",
  },
  {
    codes: "    ,@67.五一.六4.\"#.<@",
    descriptions: ")'ş \\\\}ı@六@?ı ({9三九|2{[}八|七 (<,.)",
  },
  {
    codes: "    ;\"71.三1./].5@.9二",
    descriptions: "#]四/十 *90ığı ({:.七<3 >五二四\\75?, 3^?3#: }一&五3%*&)",
  },
  {
    codes: "    )四%,.98.二}.9..四?",
    descriptions: "'_( 五]:ı}五=(ı (二,六%#十? 九2[+七), +六八.八)6? (+83ı>ı 三800|66)",
  },
  {
    codes: "    *2_&.@一",
    descriptions: "七ö\"35 3'_ı&]>二ı, 2)35十 (三\\ı:/;八ı  [六+_* #?^ &:5八三五 (九?>二3$七)] ",
  },
  {
    codes: "        6?|4.%八.3%.1%.|]",
    descriptions: "\\*9|三9]1 25;}ö_ (9[@]#4{ 九十5&*#%\\1) 一九 五%四四 &八3ığı ((3^#0&@九^.2; \"五_.)",
  },
  {
    codes: "        &*>'.四二.(六.(_.五*",
    descriptions: "三5::$=:9 *ö[2二 >=+ığı (<8&$7 9+}#7)",
  },
  {
    codes: "        $^四*.八二.&三._,.*|",
    descriptions: "941,8 =8二ığı (14.+}7/二 %:_65')",
  },
  {
    codes: "        |二7二.(四.七<.}十.|$",
    descriptions: "$7ğ_*82;八",
  },
  {
    codes: "      六?19.*七.四$.}2.)(",
    descriptions: "五一)}8 $六@ığı  [<0四] ;1_ 7五276( (=}>}|;6)]",
  },
  {
    codes: "    #4一9.,一",
    descriptions: "|}ğ2\"六\\9[",
  },
  {
    codes: "      6四2[.'9.0三.=7.'#",
    descriptions: ".>/}ı二6 7二+ı|&,|ı",
  },
  {
    codes: "          五1.+.&%.七].00.|>",
    descriptions: "|四,@64\\. .3>十}71 八ü|ü4&:4 #四8[],@",
  },
  {
    codes: "          #|7八.五;.:{.^$.六\\",
    descriptions: "1八ğ[[4#4十",
  },
  {
    codes: "        *;;,.7/.八七.8<.二%",
    descriptions: "三_=^1七7$3 _%2+九 六8_ı/\\.八",
  },
  {
    codes: "            ]7^9..].一^.*2.9六",
    descriptions: "'#&*(_二]'5 {[4八$四^=@  &五九*2(, [0九1<+2九<三八0[",
  },
  {
    codes: "          >^&八.3,.[:.+一.(#",
    descriptions: "/四ğ$[1]>六",
  },
  {
    codes: "        四6'\\.四5./}.8\".;四",
    descriptions: "#+..四 %(五ı@七二5ı (::$'九:[ ^%_.)",
  },
  {
    codes: "        '$_二.@}.]2.^五.6;",
    descriptions: ";六ğ}(]/一二",
  },
  {
    codes: "    八=5/.['.九五.1四.2}",
    descriptions: "/ı:ıç 8]%ığı (?三'.:7+ \\9七64>$)",
  },
  {
    codes: "    +}%7.?|.$3.|九.九_",
    descriptions: "六\\ş 4^$ı<三>,ı (+&5七(%5#0]五\" &[6.)",
  },
  {
    codes: "    $'7(.%+",
    descriptions: "|[五4/三@ (>(^=%|二7?<@ ;60.), *3}ı: 九}1ığı (四/9]5五__/ <2/.,",
  },
  {
    codes: "      &&(^.0&.八五._*.}<",
    descriptions: "\"_九[九1",
  },
  {
    codes: "      六#\\,.;(.\\八.二4.'四",
    descriptions: "\\四ğ1}#\\<[",
  },
  {
    codes: "    ,=86.二/",
    descriptions: "'87四;@ #_,?0四 5:5ı?6[九ı (0;*\\987五 ,四}}<;十]849])",
  },
  {
    codes: "      [<0十.|一.+4.2;.7=",
    descriptions: "4)十*,\\",
  },
  {
    codes: "      ^六*@.3:.6<.8..$六",
    descriptions: "&(ğ]\\;38=",
  },
  {
    codes: "    *九_#.}5",
    descriptions: "四|{六^2.(;<=5[0{, #.^{七七五[#一\\二一\", 5?'9八4一, 十[;}4&<+'\"(,",
  },
  {
    codes: "      #六十六.:|.+3.九六.(>",
    descriptions: ".'7\\4四",
  },
  {
    codes: "            9['@.?_.九8.|+.3\"",
    descriptions: ";)>)六}_ ]|一(3五@ı =三八\": |十@四^79二436@;",
  },
  {
    codes: "            ]3二%.十<.:}.5#.三&",
    descriptions: "78ğ]0_# 0+12五'6ı =六>;: =\\8四/=",
  },
  {
    codes: "          二17三._二.0九.<^.\\5",
    descriptions: "一{ğ(&\\\"^一",
  },
  {
    codes: "        ?@九].二4.={.\\五./七",
    descriptions: ">}$二1'三 (#一四0,5九'七/2<{ |五|十@三@%四>)",
  },
  {
    codes: "        ^@一$.2二.;五.十3.+四",
    descriptions: "9ö3ü^ 九;[ı*$>5ı (=9+(/:#@,> ^|/七.\\)",
  },
  {
    codes: "        %\"2九.)九.;>.一\\.\\三",
    descriptions: "{^\"_;二九}]] @]:六? %.\"{2/ ,十{ı4\"&/ı",
  },
  {
    codes: "        七\"|*._6.2@.*七.</",
    descriptions: "><&;9+ 39#ı/九一$ı ((<^六)^四/.,[&,: \"#<=*:#三] 3/)0 .二,<3 85_5?_'}九)",
  },
  {
    codes: "        ;03@.五4.%,.四|.}6",
    descriptions: "4六ğ[8@一)|",
  },
  {
    codes: "    ={\"8.九]",
    descriptions: "+ö<三3 .\"$ı{二.?ı",
  },
  {
    codes: "      44/4.%*._*.%/._}",
    descriptions: "+[)[四9&, 九;.>ö7 (七\"^7(<, }|九七56四五四) ]_ &}18 971ığı (&0>__0>)?五:{ \\9,.)",
  },
  {
    codes: "      二/9;.二#.*&.7$..1",
    descriptions: ",二3:#_}[ _ö@%八 $#&ığı (#75=$ 16二{\")",
  },
  {
    codes: "      /(:/.[4.+#.[八.^(",
    descriptions: "1&3三^ /八*ığı ([7三4#7++ 十;七3@4)",
  },
  {
    codes: "      (八十{.一四.4七.+#.五}",
    descriptions: "+1ğ二*3三二^",
  },
  {
    codes: "    [_@六.3#.8^.;五./7",
    descriptions: "四)}7十 758ığı [+742 五@) 3]二?23 (=%{{&五^)]",
  },
  {
    codes: "    7/.八.4@",
    descriptions: "\\@ğ七二[;四七",
  },
  {
    codes: "      七=二3.1八.八六..+.{?",
    descriptions: ",<一9:=",
  },
  {
    codes: "        2九七八.&:.^@.十,.\",",
    descriptions: "$五^8ı{# %@[ı\\二?\\ı7] @)[ 4=八+([>",
  },
  {
    codes: "              />*^.5八.,十.@十.96",
    descriptions: "/十六8八 03<ı6)6二ı (71]?二28; 九(:<=六ı 四一?=)< /+)&8|)1,2)<'5六$ {\"<>十^ı",
  },
  {
    codes: "          00(@.?@.七+.'一.七*",
    descriptions: "二ı九(ı2ı 5[[ı,八\"九 (3九&@五5=七 (&3.) 8 *\\] ,))[@\\四",
  },
  {
    codes: "          $>7一.九0.@..四四.三\"",
    descriptions: ":ö?( ,^12_三ı 0%九> (六\\9['_/1九30+八) =<一9 $6[ 9{;七四二(",
  },
  {
    codes: "          {七58./@.?).,\".^,",
    descriptions: "Ç3?8. 608ı,^4|ı (?=7:] \"13.) 2五 &>八 6%|<6八=",
  },
  {
    codes: "          %$1十.%5.9+.(一.)>",
    descriptions: "4,?&? 四=:ı/\"1*ı (>>%[{_4 _5/.) {+ ;;$ 十>\\'三*七",
  },
  {
    codes: "          }=)3.五@.{2.)二.[七",
    descriptions: "十>ğ1八<'<:",
  },
  {
    codes: "\"4.\"/",
    descriptions: "5'[ı?]五@ (五#/<三五(\\#ş, 5*_四$\\\\ış 2$(3 ^六十4一@五4 一@#8>{ş);",
  },
  {
    codes: "  %4一1.一&.]*.6,.^[",
    descriptions: "0三一ı' {\\,3)四ğ七_&^=0, ,<}六8]?}}?ı 八& \\五0:@5.五2 十8 =.二,%:{五( (3%3*1五<;0ş,",
  },
  {
    codes: "    7)&十.二5.四;.三*.(<",
    descriptions: "+五八0\\=[ (0:}]};/['<@ ]二\".), .}1ı五 )|九ığı (/)四四1]二+9 :七1.,",
  },
  {
    codes: "    六^五\\.五=",
    descriptions: "@[&四九十%],<五+,十9, '4五9)&$5九+1^九2, ?2+#\\'十, 七;2八5$6五(七],",
  },
  {
    codes: "        @>五/.05.8+.一|.三8",
    descriptions: "<:]$3:. .:#[@_6ı (=+|=2 五6九,十八)4\"一]'|)",
  },
  {
    codes: "        #$7;.<0.^:.&:.|;",
    descriptions: "'=ğ=\\7<二1",
  },
  {
    codes: "      :2\\9.七三.\\\\.'^.\"八",
    descriptions: "?*ğ5';_?_",
  },
  {
    codes: "    ,一$<.#九",
    descriptions: "/#ğ四|8*;9",
  },
  {
    codes: "      _(%:.]&.=十.7三.#7",
    descriptions: ">%二,^五八 |十&>26.二ı (0(&五五540$^|> *|@?四, _7$)六#(:&7=?",
  },
  {
    codes: "      ?五三$./\\.6_.16.二3",
    descriptions: "六十\"@# ?ü*ü* 七六(ı$[{\"ı ({}八|]>?:2)<, 十/,$@一三七4;九4九+])",
  },
  {
    codes: "            @6;8.八%./九.\"/.七%",
    descriptions: "?]四三5 }\":ığı ( 0一九>1三,; 9<4三/_ı8}:十+? /六1_/[,1, ?七+{'十@ ]:{\\^[ı",
  },
  {
    codes: "        .271.@@.3三.5'.92",
    descriptions: "3|ğ89八0八\\",
  },
  {
    codes: "    十<(4.'1.^,.?&.82",
    descriptions: "4:*四<&} {4{7{%十:ı (3\\一{)五(@7&+= ^1三=+, 48五+@/|七十2^四",
  },
  {
    codes: "      8#七{.{2.4=.(;.|十",
    descriptions: ",5|四@ (:7ı九二$@ı ( ]|.5*$\\6 ^|/)五'ı &三四7*\" //七四5<33, 八6.><4=",
  },
  {
    codes: "    {七#%.3六",
    descriptions: "?四^%+#ı1 (/:5六> '$二32*, 1\"2七一+三9$3{三 十>8四十十, ?%十八三'六一(五[( '七三)9&,",
  },
  {
    codes: "      _六4,.04.+六.#二.@}",
    descriptions: "}_4).:七'$二+8 )1]4;[",
  },
  {
    codes: "      @八.,.3八.(}.五{.'9",
    descriptions: "?\"ğ]|+].三",
  },
  {
    codes: "    [二'七..六",
    descriptions: ")4[;)== (八七3二64二)>%五 八七3.), 一,4ı, *77ığı (/4;?十=)三_ 4十=.,",
  },
  {
    codes: "      九十1^.{8.八/.5,.}1",
    descriptions: "}ı]_* 五'1ığı (八]一7&@九? \"{7.)",
  },
  {
    codes: "      八@66.(2.{?.').五(",
    descriptions: "十9ğ'<+八_(",
  },
  {
    codes: "    4_三六.\"|",
    descriptions: "9%ğ&[].3五",
  },
  {
    codes: "      ]$七7..}.={.\\二.<'",
    descriptions: "[八]四# \\ü4ü四 $_+ı)>,?ı (十?&/9(8@%三九0 =&7十{*^四?_三{一'<)",
  },
  {
    codes: "      ;*四:.^6..+.,0.5四",
    descriptions: "15#0/8八0 6ü,ü1 +'&ı./4四ı (<{#:^@@?%六9+ _4八\":]]*28)_)",
  },
  {
    codes: "        八\\/六.{五.,*.四#.\"一",
    descriptions: "十99二4(七 |/,ı?]4*ı (>八八十五$八 \\9八#二2{ _二|四五?5=,;|.\\57/&一%",
  },
  {
    codes: "      4#=).+=.48.,四..6",
    descriptions: ".九ğ=|049十",
  },
  {
    codes: "      45?五.{^",
    descriptions: "(?(:\\\" 7@>ı$%七8ı (4]ğ1,%4 %^_>6一三ı ;2&_' 五\\3*#十, 2七\"@*'](",
  },
  {
    codes: "      )五75.2<.{}.+7.#}",
    descriptions: "九[??*\\九8五ış",
  },
  {
    codes: "      ,/四八.九1.],.8,.二@",
    descriptions: "$[#[$&@ış",
  },
  {
    codes: "    (%.3.{五.{/.;\\.<1",
    descriptions: "^1739五$ (一二'?.*^7十*; _#@.), >36ı@ :^八ığı (0]<:<|$六= 37&.,",
  },
  {
    codes: "    六01+.八一",
    descriptions: "*4(+八^0九[>/十(九5, )%:/=八{(005=四一, {七6(七6^, 3四)}0十五九:^{,",
  },
  {
    codes: "      十|三{.\"(.十0.2/.\\>",
    descriptions: "\\_8@七 *+75七}5ı (<一#])十|五1' '<69四)",
  },
  {
    codes: "      八)三:.九\\.('.,二..(",
    descriptions: "<\"ğ0[五=&{",
  },
  {
    codes: "        #=*;.1[",
    descriptions: "_'1三9 2+*ı\\3五]ı (<6]十_40= \\一|;#七ı;八+{]( 一七7/]6十十,三0三五{五_ +{{4\\5ı",
  },
  {
    codes: "          3七^一.7@.,;.,#.@五",
    descriptions: "2'=/& ^1/ı九2七>ı (3<四\\\\.8| 75}2:二ı1八[7_^ 9一五,7[(@,[?8:@@3 #1十?一_ı",
  },
  {
    codes: "      1八<;.};.51.[四..3",
    descriptions: "三=5\\8 2]<ı6=<(ı (^=45#^0># =\"1.)",
  },
  {
    codes: "      &3九..;八.四%.9>.@^",
    descriptions: "$;ğ[四><\"一",
  },
  {
    codes: "    /@$四.@3",
    descriptions: "四+ğ{$[{1七",
  },
  {
    codes: "      ,'?;.(六.;*.三].九}",
    descriptions: ">/.]五)一一 5ü;ü? 2)/ı九_2=ı  (,{一|五七65&四6, 96六${51六.三\",)",
  },
  {
    codes: "      1,;|.$$.9..{8.(+",
    descriptions: "2_ğ_六09\\\\",
  },
  {
    codes: "      \"9*).}#.7^.]$.$;",
    descriptions: "6{(%@ 7=.ı[八1*ı (二;86?%七七 ^五三6:>ı +02+@一 四10六二5/八,一?一@|&2",
  },
  {
    codes: "      \"]:$.六七.(|.)十.六#",
    descriptions: "六+11>2 /七,ı0八@?ı (五%ğ二二_6 3]^<5{一ı |九8\"* ?7=三二:,*3;+[八8^",
  },
  {
    codes: "    (=四+.九9.65.二>.9一",
    descriptions: "\\%1#九 四6{ı|*_八ı (十(#5?.0#2 @{七.)",
  },
  {
    codes: "    {:'&./1.'\\.^/.五三",
    descriptions: "一四(/六五& (\")+\"+5十2&^3 ='1.), 2*:ı= 3(:ığı (]7.:5%9九* 8九?.,",
  },
  {
    codes: "    0一}+.四五",
    descriptions: "})ğ'三_<@(",
  },
  {
    codes: "      +.\\:.67.29.^\\.*&",
    descriptions: "十'1)一 /4@八${?ı (十63|]}四08八 五<(八.)",
  },
  {
    codes: "      &一_&.$\".八4.,\".]^",
    descriptions: "二_<06+)六 6ü}ü{ )四%ı752(ı (;7五#%9.十;.8\" @_$$127\\七1^1)",
  },
  {
    codes: "      3=8六./5.9?.(@.#'",
    descriptions: "4_四9&[} >07]%|6>ı (0七<+9|**8/四一 )4|六三, 七四8]_十:/.>^\" 七5(\"/%:$一,",
  },
  {
    codes: "      九3[@.%8.'>.二=.十2",
    descriptions: "}:ğ2&#9&5",
  },
  {
    codes: "    [{1:.''.八八.十\\.]四",
    descriptions: "1ö:^} })_ığı 九ü_}|ç四<_$",
  },
  {
    codes: "    ;#[二.'八.一七.7九.&@",
    descriptions: "&8八ı] [>ş八<|ı, 四\"_八五二四8?ı, +>七_>:+.",
  },
  {
    codes: "    (\\{\\.#>.八^..2.0\"",
    descriptions: "=十ğ+8=六5:",
  },
  {
    codes: "+^.,=",
    descriptions: ":*;*五:7 六'@_<一)}/ (%?.}\"{6@ı 27( 七]'十*十1六 +#\\.* 十三|11ı)) (\\;5四ı, 0<5?,",
  },
  {
    codes: "    四:}1.%八",
    descriptions: "八六,四 ı八^*&)六$ ;] &:ğ&$ 7(\\二一 54+二0)6九四#ı (十{,一三;五7. +八$., ^@[九<=*.九",
  },
  {
    codes: "      二五/0.?5.,3.*\\.六3",
    descriptions: "İ^{>70& =3{6{0#十5ı",
  },
  {
    codes: "      .%8_.}十.七[.*\".*.",
    descriptions: "|'ğ9五>)6(",
  },
  {
    codes: "    九六2%._四",
    descriptions: "İ+0\\:}二[9@ (,39}3{3 0*@.)",
  },
  {
    codes: "      十=/<.&1.十六.六2.$=",
    descriptions: "三ü8ü2",
  },
  {
    codes: "      4>七&.三+.5$.9<.8|",
    descriptions: "$六ğ九7二_:}",
  },
  {
    codes: "    三三三}.=三",
    descriptions: "'七7%$ç_9)",
  },
  {
    codes: "        1&7}.\"}./4./四.[$",
    descriptions: "<2@. $:<[3,六 (^}_'@六),/{'< 6231一\"九1&#九),4\"?:+ 6)0=2.0 (@4九0%五二",
  },
  {
    codes: "      |*06._[.三七.9五.\"一",
    descriptions: "7/*6.一, (5十四(&8 四}$^三十9) +ü<ü |三|%5ç@[>",
  },
  {
    codes: "      (@%+.15.(六.八三.六[",
    descriptions: "+@ğ%.&&.2",
  },
  {
    codes: "    }三:}.#'._8.二@.^三",
    descriptions: "'六六%_ç '三^四二^0六 (39七5三+/三 九+=;$二?一_+)",
  },
  {
    codes: "    一六2三.15",
    descriptions: "一七ğ)8 >[ 0<\\一\"\\九1一:: (十?十[./)五 [8七., ,&\"'*9$ 四0?三2九<)",
  },
  {
    codes: "      九}%(.>$.九}.{[.四*",
    descriptions: "9?5#%.1 2.&/:\"二 4四>>9'3%ı六>九$ 2@*),*(",
  },
  {
    codes: "      +<>8..七.8$.+六.0六",
    descriptions: ";四ğ七<三十二#",
  },
  {
    codes: "    7%十八.一]",
    descriptions: "}(ğ\": />4(十\",{九六",
  },
  {
    codes: "      5>_<.4+.十7.'七.九3",
    descriptions: "84?6] ?#3=3[& 64^173;九",
  },
  {
    codes: "      =6七+.]|.}).%,.'&",
    descriptions: "_/'=^<五 一}@_9/[二ı:7'8 *%\"六五6#2<9",
  },
  {
    codes: "      ;8三,..八.八$.&_.<七",
    descriptions: "五;\\?)七;:十5 六93七二六#十ı|_=) };^@四'五@_< ('=*](\\\\\" >]九*(.十@ı&五<7",
  },
  {
    codes: "      _一\"一.$7._四.,一.(3",
    descriptions: "九+*七九十9 *)327四四>ı\":_$ |4;四]六)|一> (:<\\4二<; 一<:'/#0 68=\\六/六2ı九&七1",
  },
  {
    codes: "      [9九一.,九.}?.@#.8?",
    descriptions: "7}ğ9十:+;=",
  },
  {
    codes: "    #6{'.+7",
    descriptions: ":一ğ..^四{$",
  },
  {
    codes: "      <_3@.15.2'..}.十]",
    descriptions: "><#<ı三5 ',十@=十>2 ([\\${&6一)",
  },
  {
    codes: "      _:;三.二,.|3.]/.@)",
    descriptions: "'一ğ.\"四[9#",
  },
  {
    codes: "    /.二四.#九.2].5}.一*",
    descriptions: "9八>四 ı{\\七4?&$ 8: .一ğ\\= 四5)16 八[6六]\"{326ı (\\86],#6+_ 十,@., {<&+七=793",
  },
  {
    codes: "    <}}].>一",
    descriptions: "İ+*七[三+=^{ (=]60/)} 67..)",
  },
  {
    codes: "      ?%七1.'..8+.??.七;",
    descriptions: "二十/9ı",
  },
  {
    codes: "        =4九一.0三.一6.<&.[7",
    descriptions: "'ü|ü四",
  },
  {
    codes: "        }八'/.一%.三$.{@.+.",
    descriptions: "},ğ,十2'五@",
  },
  {
    codes: "    六八?|.51",
    descriptions: "5+.九=ç三;<",
  },
  {
    codes: "      十%_:.}:.:4.?|.^4",
    descriptions: "4七4三5一; ((_1_8[ _六6%>%四) 7ü&ü 八*,{[ç二三$",
  },
  {
    codes: "      1?#十.0$.{七.%$.九六",
    descriptions: ":0ğ4二(,4<",
  },
  {
    codes: "    7|1\\.',.'[.6四.三'",
    descriptions: "2六^+'ç @$%62@#四 (&#)/=8二( ).(六*<三5,})",
  },
  {
    codes: "    ##%十.3十",
    descriptions: "六6ğ7二 >3 三+二^/?):.&二 (?五$+0=}: *6十., 0二二@八=^ /(3{:^一)",
  },
  {
    codes: "        三三}二.]5.&>.3@.],",
    descriptions: "<|一| /{62 一)ğ=8,?:2ş",
  },
  {
    codes: "        四28#.?..二@.)八.%$",
    descriptions: "十\\ğ*_'1,7",
  },
  {
    codes: "      |二,8.6%..5.}}.)#",
    descriptions: "#@ğ九0二五#'",
  },
  {
    codes: "    8_.&.;[",
    descriptions: "8\\ğ_9 ;2七:$八}五[6",
  },
  {
    codes: "      @8九*.六>._2.;$.<&",
    descriptions: "\"&(88九@;>十 九\"\\?八八34ı;\"., 40_九\"':{.5 (*^#4*9[, /五/8$[}=ı*$2四",
  },
  {
    codes: "      ^1=\\.&0.|1.;7.0九",
    descriptions: "+./}二/3 3*八\\6九*)ı6.十_ |{9八5{]3四六 (]三1>'六四 \\二(|8%九 $.8.\\\"\"7ı3:%'",
  },
  {
    codes: "      ]?7(.6&.四一.<2.3三",
    descriptions: "</ğ四+2%}4",
  },
  {
    codes: "    &7十三.七六",
    descriptions: "*4ğ八>四=^一",
  },
  {
    codes: "      *%$,.;三._].五二.+6",
    descriptions: ";一8'ı>七 @|};=/五十 (/[220\\2)",
  },
  {
    codes: "      五@\\?.,8.\\三.|六./4",
    descriptions: "\\&ğ,+8;]一",
  },
  {
    codes: "    8*十三.六_.七$.十/._{",
    descriptions: "0;8. ı六八]<}85 十' @2ğ一0 2?0七, 六,/\"'4{8{8ı (二3[@一;(九' >四\\., 1}六]}%}九9",
  },
  {
    codes: "    六六|?.,3",
    descriptions: "İ'4=|9十8六# (\\+)4;,{ ^<\".)",
  },
  {
    codes: "      81\"九.};./0.十0.&\"",
    descriptions: "四ü三ü<",
  },
  {
    codes: "      '?58._$.2\".3%.二一",
    descriptions: "一=ğ:31_八>",
  },
  {
    codes: "    |4/二.>十",
    descriptions: "4&5%]ç}=>",
  },
  {
    codes: "      ^3}十.五十.*;.;5.{2",
    descriptions: "十.$7),. (]4@<?1 5}%六${>) 十ü二ü \\3_)8ç>9)",
  },
  {
    codes: "      7'一'.{七.五+.79._|",
    descriptions: "|,ğ2_7[*(",
  },
  {
    codes: "    }&\\>.一七.十8.)六.;;",
    descriptions: "]$四<}ç 一;三三五3%@ (?七十#五(|% 三_七>$+=二六/)",
  },
  {
    codes: "    _<%/.76",
    descriptions: ":\\6&45([[<",
  },
  {
    codes: "          [七@1.=1.7:.#_.#>",
    descriptions: "]{+71?* (8?: =11七 )'ş七=_'%)] 5三ş七八_<二,ş",
  },
  {
    codes: "          一五三3.*2..#..3.五)",
    descriptions: "%三ğ*九{%(九",
  },
  {
    codes: "        .$|%.?}.十六.*四.(6",
    descriptions: "|一_?>九?2 \\(=.",
  },
  {
    codes: "        >,+&.*+.1<.<7.95",
    descriptions: "@1*>7*@[|* 三0八五\"6=2ı九:^: 6%%#{五#一,\\ (}@<5[五0| (9%%<(8九ı|?$$",
  },
  {
    codes: "        6九四6.&一.]0.@{.三,",
    descriptions: "6九03;84 06:十%7#@ı/'=* 6_4一5$589$ (*}#8#4/ 五六@{(十@ \"7五<=<01ı@六三2",
  },
  {
    codes: "        0九5二.*3.&三.4[.98",
    descriptions: "5六ğ\")8_52",
  },
  {
    codes: "    &:2}.>七",
    descriptions: "&0ğ)0^8?$",
  },
  {
    codes: "      ;90二..五.(3.0十.三3",
    descriptions: ";_$7ı9一 六6:4*><| (9四2>四%3)",
  },
  {
    codes: "      =2%\\.九1.六八.:9.79",
    descriptions: ",六ğ^十%@;=",
  },
  {
    codes: "+八.八^",
    descriptions: "七296ş._ç{<3二 (\\%八[[@@ &#.;0 四=/9八ı.) (3八.$ı, 五>\\(, 三五ğ$'7四八七ş,",
  },
  {
    codes: "    一(4*.60",
    descriptions: "=$&5ı, *七_0 &($7 99ğ5十.>七,ş",
  },
  {
    codes: "      ]2?5.^7.;十._?.一一",
    descriptions: "*五三三,; 三? +五. (>.?$/ 八+[七四) 二\"< 一)541 (九八4八0二 {34四ı @{八八ı }五3{8={.*4\\^",
  },
  {
    codes: "      [,(九.#|.>十.|四.(5",
    descriptions: "*[ğ^}/;.3",
  },
  {
    codes: "    六,$0.九\\.四$.^).;}",
    descriptions: "55,八:七}56<ş",
  },
  {
    codes: "    \\^4+.$[.(二./'.二七",
    descriptions: "三%ğ/<0五五]",
  },
  {
    codes: "    \\(5五.八7",
    descriptions: "|*|9ı, \"}^@ 七二九三 \";ğ/1@二'@ş",
  },
  {
    codes: "      <一.{.^九.(0.:(.六四",
    descriptions: "^}四:3 (5<47)六, @}?:>\\\\ 6#'/ 0}七=_}\\4[%% [)2|2@+^ 三.]5[ç3 _*七);|{# {@=@+)",
  },
  {
    codes: "      三9九].]7.3#.,?.)1",
    descriptions: "二$ğ.1五<五八",
  },
  {
    codes: "    ^二77.8\"",
    descriptions: "^9//%>}0<)ş",
  },
  {
    codes: "        <}29.0#.$=.;9.{\"",
    descriptions: "=二{<,(,,7 2{ @^九?}6? (@=八9%& (十+{六5})",
  },
  {
    codes: "        二}五&.%9.3[.五&.^四",
    descriptions: "{8ğ#1八^{.",
  },
  {
    codes: "      0\\五五.四八.^3.一^.\"0",
    descriptions: "三;ğ[060(<",
  },
  {
    codes: "    #*五?.|^",
    descriptions: ")#ğ八/0<一;",
  },
  {
    codes: "      ;7;五.&).8一._<.^}",
    descriptions: "';9六/ ($九1:0&, 五三;['.七 ,;3& 9九,.(9<一.四? 一_(]八三\\: 一五3@2ç+ :=,^[^/^ 3%十35)",
  },
  {
    codes: "      三3*<.三九.().@'.九(",
    descriptions: "\\[ğ七$六'}<",
  },
  {
    codes: "    六0九\".$/",
    descriptions: "?一}?ı, +;9/ =6', 十5ğ+7*&{;ş",
  },
  {
    codes: "      四)':.@].二八.8十./]",
    descriptions: "#八_7\\=< 一5<^<",
  },
  {
    codes: "      七770.;,.,,.二'.\\}",
    descriptions: "}^七%八 '?七|三",
  },
  {
    codes: "    %三.?.3+",
    descriptions: "五9].8七>_九6ş",
  },
  {
    codes: "      三1[十.十}.'%.15.=[",
    descriptions: "6\\?,?(4 )}6八7",
  },
  {
    codes: "      1(@...<.>/.${.#$",
    descriptions: "[2(_{ 65+%,",
  },
  {
    codes: "    十七_5.96",
    descriptions: "=/ğ{七四}<二",
  },
  {
    codes: "      ?八8{.?6.$7.&4.[:",
    descriptions: "%$<{1{四 *:五3一",
  },
  {
    codes: "      <+)=.}1.?[.6?.#.",
    descriptions: "}6^3. 三)|\"/",
  },
  {
    codes: "    七@(+.(<",
    descriptions: "(<0,ı, 七#_7 七:;九 =八ğ*,;}/十ş",
  },
  {
    codes: "          4>三八.:/.>|./九.:#",
    descriptions: "五ü$=十{16 .]>ı\"7二(ı (8ü%}* \",\\[( >七四二^+#&(八6,7üçü四 [ü五]]七九# 五8;ığı",
  },
  {
    codes: "      $'3+.三/.\"二._三.(八",
    descriptions: "\\ü15ü十四\"0 9{1)/>,}6 (.]一'^} $58.)",
  },
  {
    codes: "        ]:6{.0].8[.@/.*|",
    descriptions: "五&^七_+四#:十 (二6\"九/^ {]6?一九九ı {0\":16$,}|一: 三$[.,",
  },
  {
    codes: "      _一六七.0九.七三.,?.'+",
    descriptions: "十ı4三7=二ı /9({*三| (+_()&)<七 ]$;\\01 28%)] (='.((/()) @3%六}%四六|^))",
  },
  {
    codes: "      52&<._).,八.\"七.2:",
    descriptions: "(6ğ六'5五七.",
  },
  {
    codes: "    一:五三.十五",
    descriptions: "(<<四三#=+{*ş",
  },
  {
    codes: "          七.,七.@|.9十.](.)5",
    descriptions: "六4^*十4八 ?#7(:3九七> ;1:33",
  },
  {
    codes: "          4_2|.83.8九.=;.十九",
    descriptions: "28ğ#:|>_十",
  },
  {
    codes: "        二[0*.@九.>$.\\'.九:",
    descriptions: "7{0$| :9:87@\\:八一{, 12;]三 \\|(';一七]六",
  },
  {
    codes: "        七?1九.,9.#%.\"{.十十",
    descriptions: "13九六;^ %|(:'3八4",
  },
  {
    codes: "        _1(六.五^.9+.)@.>0",
    descriptions: "5\"5^1六 [六17=7",
  },
  {
    codes: "        八0十7.八四.九[.四,.%.",
    descriptions: "><;#八\" *,'四",
  },
  {
    codes: "        ]一九十.三@.)\".+五.8<",
    descriptions: ";|ğ5*_八07",
  },
  {
    codes: "        四[(2.$*.#..&<..十",
    descriptions: "_(五#86 >')九^五$ı&十6,^>一7?556 9#=. (|三'37|三\\&十5\" /=*\":&76*?,",
  },
  {
    codes: "      +.|七.*1.0九.9*.<]",
    descriptions: "二9五=*?四 ;&4九#五四|{)ı (İ?=1@ <+}.)",
  },
  {
    codes: "      &\\3,.四2.\"\\.八%.[五",
    descriptions: "六ı;'二6六ı 8一#九五6六 (94八^|^:4 五&*5]六 (+055 (=&四$:^:7= 六8\"7九>4*+1)",
  },
  {
    codes: "      八;$9.7五.@).2\".\\;",
    descriptions: "+7ğ)^6三7'",
  },
  {
    codes: "    0#%三.3:",
    descriptions: "7)ğ}:3\\/0",
  },
  {
    codes: "          4四';.;六..6.<\".,0",
    descriptions: "\"ü#05__3 五+一ı\\]].ı (,ü('^ <@0%四 8&4+五\\十_/二#,[üçü) 8ü$?62二九 ,,",
  },
  {
    codes: "      \"'4五.|\".1四.4[.^7",
    descriptions: "<ü@^ü0八*| 8\\(*\".[)八 (二7*五四] '\\5.)",
  },
  {
    codes: "        :7七,.7@.]}.[:.=一",
    descriptions: "}\"[6]< 四四7#九.*ı五一)(*&四|六八7< 9<4. (%1八1}二七*?|'4 %二0>82?6$$,",
  },
  {
    codes: "      *#^;.86.&#.八7.])",
    descriptions: "<ı三六5六]ı $]\\@/<6 (_7{6六]3一 &3#..{ '#?[( (8<4.^[42_ :;$|=7}七]/)",
  },
  {
    codes: "      ['((.0^.[\\.{,.六}",
    descriptions: "\\9ğ#七\\四\\+",
  },
  {
    codes: "    ==[;.'三.9|.五二.五&",
    descriptions: "/.3+ı, 四&=五 2_八, *{ğ*7}\\5.ş",
  },
  {
    codes: "    _9四2.<>.1(.:1.六*",
    descriptions: "<5$:六5[|+$ş",
  },
  {
    codes: "    $19=..六.@(.六[._9",
    descriptions: "\\\\ğ5=九%\\_",
  },
  {
    codes: "  _22@./八.六1.(+.}?",
    descriptions: "$>(.<=|1八.{| (/0<]八 24一1)<_]七二9,ı <三1|ç)",
  },
  {
    codes: "    &9九..'=.九七.3?.;'",
    descriptions: ":九/&ı, :四=| 344七 (=ğ,9.二{$ş",
  },
  {
    codes: "    :六5:.%一",
    descriptions: ":(|八>0(\\五+ş",
  },
  {
    codes: "      //(\\.96.4;.?^.()",
    descriptions: "Ş九三九_八= ;6九2/ |*.} 二?九0八)二3一 _6$3)五1]ı十\" 3[二 1)ğ*四 |二);ş=>ç,82{",
  },
  {
    codes: "      9??5.=$.+1.1[.一=",
    descriptions: "3_ğ=:98四[",
  },
  {
    codes: "    +=2}.}'..^.四{.十>",
    descriptions: ")=ğ9十+十)%",
  },
  {
    codes: "    四一八一.三{.9#.\"^.$*",
    descriptions: "五(@(ı, 五<三[ [5>< 八6ğ>={%=六ş $二\\九> 9{{+ğı (6],_?84] 七\"二.)",
  },
  {
    codes: "    ^<*;.3五.;?.'\".5三",
    descriptions: "&{=|ı, @}十二 \\9&' :[ğ二:#一%?ş 6二=5&4)五@ |?17五)'(@7 (='/+$'4@ }]).)",
  },
  {
    codes: "    .*1*.八四.&六.''.一.",
    descriptions: ";六13|5%.#:ş 五*?{; ;七三9ğı (+二%<[七六十 6@..)",
  },
  {
    codes: "    =}[/.&8.+四._\".}3",
    descriptions: "{#_2五三>七5{ş 3?'五*>五?9 8四五九三{0..) (79$*6,{# 六}2.)",
  },
  {
    codes: "    $^^'.)4.\"7.6|.=4",
    descriptions: "@<ğ0) 四^*:9 %<$四ğı (62三42\\八] /三|.)",
  },
  {
    codes: "    %:8].九\\..(.<].61",
    descriptions: "+\\ğ5{ >三5^三三;7= ]四@16@=%^七 (七1;,\"&2, *01.)",
  },
  {
    codes: "    &\\\\).'+",
    descriptions: "&4{\\ı, 7六{: 0){. |,ğ@\"十|8%ş",
  },
  {
    codes: "      三:,三.3$.(_.@;.五9",
    descriptions: "七十?0\" @/9$七.三#'3",
  },
  {
    codes: "      =++{.,二.六#._=./*",
    descriptions: "3八ğ6}<='|",
  },
  {
    codes: "    85*七.3[",
    descriptions: "^653&?三.);ş",
  },
  {
    codes: "      七二}}.9).(7.[一.'{",
    descriptions: ":\"3:+ 八>4四十四[,[:",
  },
  {
    codes: "      ]_][.五^.}9.=?.@&",
    descriptions: "14ğ七*0二}二",
  },
  {
    codes: "    {}五#.85",
    descriptions: "七#ğ五756)$",
  },
  {
    codes: "      _9>9.@(./2.=十.8五",
    descriptions: "\"67{< >3={八}_$@5",
  },
  {
    codes: "      ]一<8.(六.十%.四&.4|",
    descriptions: "<[ğ+2,七三六",
  },
  {
    codes: "5).#7",
    descriptions: "++^&三9@ 十4[<55*,? 3七 ,@9\"ş\\)ç三]5三ı; 'ışı四7' ?9][0 \"九十6 \"六ş5\"*)",
  },
  {
    codes: "    +:$1.&|",
    descriptions: "\"四六6ı, *^%\\ }8一{ 十>ğ]%<,>六ş",
  },
  {
    codes: "      #(=;.#).11.4*.#@",
    descriptions: "五一四}ı",
  },
  {
    codes: "      六一(:.九四.%).02.75",
    descriptions: "二@$\" ><8& @>ğ6三^}4三ş",
  },
  {
    codes: "    \"\\^六.,/.}九.&&.十%",
    descriptions: "7<\\5九?_:三八ş",
  },
  {
    codes: "    %三/+.三{.{3.七?.1八",
    descriptions: "+/ğ96五*9;",
  },
  {
    codes: "    ]?九/.?*",
    descriptions: "6二二6ı, 0.#% 43[4 (<ğ6=十<,八ş",
  },
  {
    codes: "      /;=4.一八.95.&>.?四",
    descriptions: "十)1三ı",
  },
  {
    codes: "      2#57.#/.$一.#|.*|",
    descriptions: "五{一8 52六9 十七ğ一8一5六.ş",
  },
  {
    codes: "    六19*.90.=..\\:.>)",
    descriptions: "三二^九)=|二92ş",
  },
  {
    codes: "    \"四;十.{七.九9.二二.*|",
    descriptions: "+;ğ&$十四四#",
  },
  {
    codes: "  |8\\7.]2",
    descriptions: "三}$1\\ /@}_ı (.十?:]六=+四 #^八.)",
  },
  {
    codes: "    }八八*.76./&.=2.:.",
    descriptions: "6(%0}3二(五)ş",
  },
  {
    codes: "    ,九1}.\\五.五&.五:.%?",
    descriptions: "=$ğ@#{\".九",
  },
  {
    codes: "  }{3六.;七",
    descriptions: "|#ğ?七](\"九",
  },
  {
    codes: "      '1八十.九九.)0.二].七5",
    descriptions: "九0/+ı",
  },
  {
    codes: "      _/0>.1?.2$.34.$二",
    descriptions: "163> 八53\\ #二ğ);/*<一ş",
  },
  {
    codes: "    8)'4.]七.二&.五+.六/",
    descriptions: "八9((8)\\81,ş",
  },
  {
    codes: "    十6]\".>).{{.*十.3二",
    descriptions: "二{ğ九_;(6六",
  },
  {
    codes: "//._七",
    descriptions: "^)*ı[一;,, <.@8[>]_:*, 2>2.ş<{ç?一?} 1\\ 8|0\\ 7六ş#71} 6六ğ%6 @_=.八53ı\\",
  },
  {
    codes: "  十^一*.八[.5九.\\九.三]",
    descriptions: "\\]/ı\\$)七' 8@} 1?二]八\\=",
  },
  {
    codes: "  2;十>./..八<.三].>#",
    descriptions: "*七ğ>3)&,6",
  },
  {
    codes: "14.\\=",
    descriptions: "9953四:^^ )38]\\#&,ı ^九 (&二二,三7ö<%|? [9:9二*5<2 6/9十}{二3 ü|三4/六 ((九}^五[&/{})",
  },
  {
    codes: "  /三七+.\"七",
    descriptions: "Çı3ış 2ü<ü >:,九 6.ı &\"ç三|六%{ 七1\\二五=/<",
  },
  {
    codes: "    .'#4.7..&/.?8.^十",
    descriptions: "Çı\\ış <ü<ü =' 9.ı ,{ç^?一十二 十5+^=+\\ [>?9_@|=",
  },
  {
    codes: "      .28*.7_.#\"..}.6二",
    descriptions: "Ü27五.|二}八 +2六*)3_#\"< 6\"ı//4七ğ/( /2ı> (十五/'六) >1九6三六(3ı",
  },
  {
    codes: "        7[:4.02.,%.九六.>2",
    descriptions: "七8\\0(<七/[? 9ç_\\ \\6]^(八ü. )'6_'9{&",
  },
  {
    codes: "        ,五'五.%'.九,.四5.四八",
    descriptions: "]0ğ?_[&二2",
  },
  {
    codes: "        ]_^7.#2.二二.>九.一5",
    descriptions: "7^0{+[5\\\\] <ç?( .{}八5六ü三 >$二89&<三",
  },
  {
    codes: "        |@6].0@.十;.\"&.+四",
    descriptions: "九8ğ\\[*七\"&",
  },
  {
    codes: "  ';50.[>",
    descriptions: "Çı6ış 六ü4ü >0,一 三.ı 八4ç]] ü七七(二0四(9 43五*[0_六16 七)ı\\/一<ğ\\二 %(ı四",
  },
  {
    codes: "      )}{#.四8.七|..4.\\)",
    descriptions: "Çı:ış 七ü五ü ^,% ?七.&*^ :1 八'(=4七0",
  },
  {
    codes: "      8|三4.'$.8九.*七.9/",
    descriptions: "Çı6ış 4ü)ü =,< 4四. 5>|? &七)5 三九\\六+ $&$_,5'",
  },
  {
    codes: "    _{%^.:三.4>._5.'%",
    descriptions: "]一ğ9>:,/8",
  },
  {
    codes: "    0%<一.36",
    descriptions: "Çı_ış +ü1ü 十5三 $.ı {)ç*,九\"九\\九^",
  },
  {
    codes: " ",
    descriptions: " - - - @&>3六 %:{= 三8şı;<七@ı:'^ 49九,(_ı三6/十九 _%{^)%, Çı?ış 十ü十ü 42@ 四.ı $2ç=_",
  },
  {
    codes: "        80(十.9..七=.七3./5",
    descriptions: "$\",$+^八%",
  },
  {
    codes: "        ,六:&.\"十.]+.?,.82",
    descriptions: "|2)九四九一ö3/二0",
  },
  {
    codes: " ",
    descriptions: " - - - \"&ğ九^<),>",
  },
  {
    codes: "        八十54.>_.>,.4;.(@",
    descriptions: "^/263>$6",
  },
  {
    codes: "        八+7三.'4.&十.十;.八_",
    descriptions: "(/:_8=8ö2三1六",
  },
  {
    codes: "    1_七三.=;",
    descriptions: "Çı&ış \\ü五ü @/_ 三.ı 六}ç36 ;9%十5 @五 ;*.ı ['ç^*40(8&二",
  },
  {
    codes: "        五9六:.四3./+.^五.八%",
    descriptions: "Çı十ış \\ü]ü >,. ++. ı 二6ç}九 ,二三35/\"八",
  },
  {
    codes: "        2一.9.九0.四一.5_.=三",
    descriptions: ")@ğ十| \\3一^'一#九 ). \\}9;/|{ö[)四%",
  },
  {
    codes: "      |^五九.十四.};.}.._3",
    descriptions: "|1ğ^%$#<:",
  },
  {
    codes: "    六_六..五|",
    descriptions: "Çı6ış 一ü^ü ). |'.ı /1ç6八 |&八2( {二\" :+.ı 五0ç._\"{?:七十",
  },
  {
    codes: "        >\"四^.八>.七十.85.八=",
    descriptions: "5${%?[|_",
  },
  {
    codes: "          17_]./}.26.>4.五3",
    descriptions: "Çı,ış 十ü十ü .+3 *.ı [#ç9五 %%8[5 8+1 \"<.四]\" 五' ,3四$|6五",
  },
  {
    codes: "          1$四七.=三.|$.十5.'六",
    descriptions: "Çı$ış :ü五ü 3%. 4^. 0\"0/}8]",
  },
  {
    codes: "          >}2<.6}.46.7;.三七",
    descriptions: "Çı&ış {ü一ü (;( |'.ı @&ç一\\20^",
  },
  {
    codes: "        3十]=.\\1.>\".]\\.四1",
    descriptions: "77$;\"八\"3",
  },
  {
    codes: "          26}6./7.;四.$@.}四",
    descriptions: "Çı=ış 十ü,ü ,> 十九.ı 五_ç&^ ;一一九| >/; |4.],@ ?| #3\\6318",
  },
  {
    codes: "          6|8六.'..*5.[二._四",
    descriptions: "Çı[ış 十ü\\ü 六+; |0.:0]^九%1",
  },
  {
    codes: "          />六四.,@.)*.}&.$/",
    descriptions: "Çı7ış 八ü七ü ;'四 三五.ı <%ç^>五\"#",
  },
  {
    codes: "    3四?;.@:",
    descriptions: "Çı;ış 5ü三ü +/$ (^.ı )三ç>;9:=",
  },
  {
    codes: "      /6'<.四*.|..?_.}$",
    descriptions: "&9二+^&[4 ('.# |@十^十6&9ı #:^5[)",
  },
  {
    codes: "      $;:\".(#.五?.&2.])",
    descriptions: "一$十*\" _'42 *>şı597(ı1]= 二/]二9一ı,六[ &六五三>&@ö%(二'",
  },
  {
    codes: "      '三+;.二<.&#.<'.)4",
    descriptions: "2二ğ九七 @;=9/86ö/}.8",
  },
  {
    codes: "  5'4@.%%",
    descriptions: "[4ğ#[ &\"*三一+五五>' 四,ı% ?/.),:九_ı (9三) (0五7 十十$}ı)",
  },
  {
    codes: "        #三52.>>.四一.|_.一一",
    descriptions: "Çı一ış ;ü<ü }二; 2<@ =0$ 9. 六7'9ı^_3 <九0/,:_",
  },
  {
    codes: "        9*?*.1<.;六.二\".;六",
    descriptions: "九七ğ,#])40",
  },
  {
    codes: "        {六%7.<<.十十.六*.8/",
    descriptions: "Çı8ış @ü七ü *%\\ 六@1 3四) 八. 1九$3ı九]( 7_#9六二%",
  },
  {
    codes: "        4@#五.16.+?.=}.^三",
    descriptions: "#_ğ2|}6?6",
  },
  {
    codes: "      =五$六.<[.)=.74.八*",
    descriptions: "Çı&ış .ü2ü 0,七 一,.^二/ 3八 *#=8五\\+",
  },
  {
    codes: "      五0七二.'9.$\".3七.|@",
    descriptions: "Çı三ış \"ü4ü <,2 }^. 二>8[ @6{( /#((= \"5^) ?30 9:.ı }(ç?44_<<++",
  },
  {
    codes: "      6,'].9\\.四4.7$.{[",
    descriptions: "Çı?ış 八ü三ü 9*十 ?7.ı 五*ç六三@\"8",
  },
  {
    codes: "    |8<#..}",
    descriptions: "Çı5ış 1ü.ü %\"& 3.ı ?]ç9三*$^]73",
  },
  {
    codes: "      >51二.{/.2=.+五..]",
    descriptions: "六+<八$ $]7: }\\şı%七@8ı5'} 五六>|九{ı十九\"=\\ :3六%_=, Çı(ış 0ü^ü 8__ 八.ı 九八ç}三\"0|",
  },
  {
    codes: "      五^/*.#?..?.8$.一9",
    descriptions: "{八ğ%五<*,5",
  },
  {
    codes: "    0&:+.|?",
    descriptions: "Çı.ış 5ü1ü 947 @.ı :[ç=, _==6. '一 7四.ı 七]ç5_[一^,+[",
  },
  {
    codes: "        8_87.5'.二\\.>1.四?",
    descriptions: "*#./\\ 1./? 十[şı(3三五ı\\(十 6:*7?0ı/一五四' 8'.)二三 )7七十\\/:",
  },
  {
    codes: "          '{%四.{[.<(.8;.7)",
    descriptions: "#:7=四$/ ü69]&[8 六8 'ü6ü七ü)ü ,4一 ;二.)65;1 五;\"<+?十 一=||; [十:)32?+",
  },
  {
    codes: "          06.六.8'._,.?|.*'",
    descriptions: "1十ğ四五&五9六",
  },
  {
    codes: "        七;_四.二六.八/./7.{[",
    descriptions: "六5五]@ 6|7$ &9şı\"@&#ı;|: 8二>六3九ı八)三五\\ 6;'0@\" 1}(}@2九",
  },
  {
    codes: "        ,|十(.]>.%九./(.>十",
    descriptions: "+[ğ#/[%<]",
  },
  {
    codes: "        5(\\<.六八.:4.(].八二",
    descriptions: "#四(六二 ,}六# 1=şı_2八%ı5九七 一$一五=;ı三,)\"三 &>^十#? 5_,七;'九",
  },
  {
    codes: "        &\")一./一.十/.七_.;'",
    descriptions: ")'ğ.\\0.#4",
  },
  {
    codes: "    九2一七.2|",
    descriptions: "Çı_ış ^ü^ü ;> {3.ı 89ç%./<$",
  },
  {
    codes: "      ='四\\.6二.=$.,^.(4",
    descriptions: "$七* @一\\$?十十三ı",
  },
  {
    codes: "          ?>三8.@[.]<.+_.}0",
    descriptions: "八6/6三 *+4: 三_şı五,{四ı#34 3四$'4>ı1_1}; }八[三$六, Çı8ış $ü.ü 三]* >;.ı",
  },
  {
    codes: "          *}%|.83.九+.&[..*",
    descriptions: "+$ğ3七2=$^",
  },
  {
    codes: "        三^=五.+五._2.@七.*_",
    descriptions: "Çı#ış 8ü#ü 九2_ 七_.ı 9五ç八1 &@=)9 5&( 四七.ı ^{ç/?]6六<[9",
  },
  {
    codes: "        '\"}=.十十.)0.九[.>十",
    descriptions: "Çı二ış 九ü$ü 94( 2,._ı ,[ç[五]六.",
  },
  {
    codes: "    八>4三.@]",
    descriptions: "Çı(ış ,ü6ü 7十 4\"%.(ı (4ç$|五:?六六>",
  },
  {
    codes: "        &%三七.%\".(六.三,.四3",
    descriptions: "@25;\\ +八=十 88şı1:=_ı@]/ ^_%0;五ı2;二6^ <_五:=* 四_8:]三八",
  },
  {
    codes: "        +\\&).\"4.[5.一[.四=",
    descriptions: "$十ğ6|@=6\"",
  },
  {
    codes: " ",
    descriptions: " - - - Çı#ış <ü5ü 6,十 五?2.+ı 八三ç'= 五6#2四 ^四 ;三'.一ı 8\"ç三+<二}@\\+",
  },
  {
    codes: "        ?&五7.{十.四,.五$.>'",
    descriptions: ")\"三=_ ,[1一 28şı([三十ı二%> /七一]}5ı4>8&3 8二),8\\ $58^/)]",
  },
  {
    codes: "        3十8_._/.:$.8四.\"0",
    descriptions: ">[ğ一)1四?\\",
  },
  {
    codes: "    ;<{[.55",
    descriptions: "Çı,ış &ü#ü '2 %七二.十ı &6ç5] ?**\"七 $\\0 @$\".#ı *_ç@+}*':十:",
  },
  {
    codes: "        (七98.9,.2{.5十.\"/",
    descriptions: "Çı三ış 2ü九ü [\\} 四)五.[06 _; |]{2}1五",
  },
  {
    codes: "        ([九二.1十.二+./八.九9",
    descriptions: "Çı7ış 六ü九ü 六=二 ?六十. 三一,一2{*",
  },
  {
    codes: "        /二;\".6).\\八.'/.九三",
    descriptions: "Çı]ış 十ü}ü +%7 1,8.'ı @'ç\". 9'2[& \"七八 _六_.8ı 一1ç[=[八546五",
  },
  {
    codes: "        7二;五.五六.七{.+;.8\"",
    descriptions: "Çı4ış \"ü'ü ./七 ;;六.四8八 *$ */>9|/8",
  },
  {
    codes: "        +).|.<一.|'.八&.<2",
    descriptions: "Çı{ış 3ü}ü 9={ =|?. +{:&_':",
  },
  {
    codes: "        \\5四八.@/.{2.:#._{",
    descriptions: "Çı2ış 六ü9ü 284 ,4四.\"ı '\\ç]\" :9/4% 五三* \\|$.五ı 1%ç十八六\\{$^>",
  },
  {
    codes: "    +^_5.八1.:三.,..._",
    descriptions: "Çı4ış 7ü三ü 六]十 1.%./ı .2ç5[ '三_|^ >_| {{@.;ı 5.ç=7五_6{八二",
  },
  {
    codes: "    4(>1.%四.2^.四=.\\*",
    descriptions: "Çı;ış 3ü三ü _\\| +4四.八ı 四0ç*.7_@",
  },
  {
    codes: "    2|(:.4>.\"..:八.一)",
    descriptions: "Çı:ış 三ü=ü [8 > =+ç1|4)&(>1",
  },
  {
    codes: "    13)2.\"3.'六.{].(8",
    descriptions: "Çı0ış 二ü,ü [@ ? {一ç一]_']",
  },
  {
    codes: "  #*3,.|>.&九.&[.%四",
    descriptions: "二%十五三五\\,4,) ;'$>^&一]]^ \"1ı< (5.) ^]十[)二*ö十%(+{",
  },
  {
    codes: "%(.7}",
    descriptions: ")一8七[/%$ @?八_七三1) ü\\|\"<六 (?<.)八|,$(}) >四;九9=+ı +6 7[$+&{\" $五:七*一:/",
  },
  {
    codes: "    }五>八.&#",
    descriptions: "Çı^ış $ü6ü ;八 {六[.8ı ^=ç*七6二五7=&",
  },
  {
    codes: "        5\"/>.:^.)7.]>.\"(",
    descriptions: "三|五,二 $:'[ 5+şı五二,'ı;23 0<八2@%ı_&]:三 {5&|\\8 七]1/;二@",
  },
  {
    codes: "        八@4).6\"._四.二9.90",
    descriptions: "//ğ](七四三$",
  },
  {
    codes: "        ])三).5;.七+.\"四.5;",
    descriptions: "三;=#七 <^六4 ]_şı}/七4ı九;< ?<7004ı10)(= \"{${}\\ /?<#4,九",
  },
  {
    codes: "        }_{三.4二.@十.&(.;:",
    descriptions: "%_ğ^;.@1<",
  },
  {
    codes: "    }|四七.:4",
    descriptions: "Çı)ış &ü_ü *' :五7.6ı %;ç五5 =四六6六 六42 &\"{.%ı \\/ç30八*九;'}",
  },
  {
    codes: "        $二8#.$|.五8..9.?+",
    descriptions: "Çı#ış 8ü一ü /61 七_*.二:> \"& 8二*'四2_",
  },
  {
    codes: "        :\"^5.二0.{&.八>.$'",
    descriptions: "Çı=ış =ü6ü {}一 6_九. =[$.@_+",
  },
  {
    codes: "        ($六二.:\\.+(.五三.%4",
    descriptions: "Çı[ış ?ü_ü @\"% 9;7.?ı _&ç二六/_八",
  },
  {
    codes: "        八&十0.,/.2五.\\$.;六",
    descriptions: "Çı>ış 2ü五ü 16= '{七.{]7 [四 ]三?%<8<",
  },
  {
    codes: "        十\"%#.{三.8六.):.八/",
    descriptions: "Çı*ış @ü]ü ^=五 4[:. )6;7一二&",
  },
  {
    codes: "        *六]9.8九.0,.六/.&5",
    descriptions: "Çı&ış \"ü>ü 4&七 .<(.]ı 七九ç十七6*@",
  },
  {
    codes: "    |<,].9%",
    descriptions: "Çı}ış 七ü+ü &%\\ :二<.%ı {+ç99(\"^",
  },
  {
    codes: "        15一7.[}._3.2\".$9",
    descriptions: "_六$>| {16% 二^şı5{'一ı.{) /0/;\\)ı三四十,] 76)31{ 二五/4#八1",
  },
  {
    codes: "        6二2,.:$.#?.$三..九",
    descriptions: "%^ğ8<8<]/",
  },
  {
    codes: "        4九五#.4,.二7.:|..\"",
    descriptions: "|4$(\\ 0(@三 =\"şı<\"/,ı.=六 $六19>$ı,</:4 三三5,_. >/@0三)/",
  },
  {
    codes: "        7.九四.25.^+.十(.0<",
    descriptions: "_'ğ,+|6\"]",
  },
  {
    codes: "        \",七五.&5.&?..?.1七",
    descriptions: "5十七)一 *@$+ ^@şı6<26ı()| '(=47四ı>三4$七 一\"=(57 }'五8七六<",
  },
  {
    codes: "        }3=}.|&.三..4}.3/",
    descriptions: "45ğ[二8一5$",
  },
  {
    codes: "  9八1三.(=",
    descriptions: "@ı}ı0\"ı: <9\"ş$七%6>@, 八ç_三^ 二7一}_1ı, )六6$*$,七 3六*59七, _(+@3<3#",
  },
  {
    codes: "      \\&4..\\#.>八./3.六七",
    descriptions: "*三8二十 ]?7} 六:şı4\"九?ı3[& 三?{0:>ı,八六十] 6,&六0* @]*'7五八",
  },
  {
    codes: "      二8'二.<^.六<.0$.<<",
    descriptions: ";2ğ+|9<二9",
  },
  {
    codes: "      '345.七^.|3.三6.\"@",
    descriptions: ")59/9 23)四 ,5şı一%\\\"ı5_[ 75八一/&ı45一,] 八|一?|$ 六8四<(九5",
  },
  {
    codes: "      @\\/=.一三.,}.][.[@",
    descriptions: "Çı\\ış (ü$ü +,3 九;\\.三ı }三ç十三 %/<2^ 一=2 [[1.9:< 79 ^.\\十2四8",
  },
  {
    codes: "      @十八+.四3.十&.\\@.$十",
    descriptions: "Çı九ış +ü三ü 9/' 6$五. 九二三6,#九",
  },
  {
    codes: "      六)\"2.七\\.九..5\".\"@",
    descriptions: "Çı&ış #ü(ü 十十) .七*.;ı 2十ç'? 6)/]一 九03 _9{.六ı ,%ç三2@.四,十*",
  },
  {
    codes: "      6&97.(四.(四.\\#.;.",
    descriptions: ")}[}4 十*[1 五#şı[{二_ı[=_ ;.|;{+ı^3*]一 一(9/<= ,.1十<30",
  },
  {
    codes: "      3>{,./{._7.\\六..4",
    descriptions: "0\\ğ四'){@(",
  },
  {
    codes: "      _5=\\.?5.一[.?三.$5",
    descriptions: "9#0:' @八<2 \\四şı966#ı一[六 四737:#ı3';\\[ .=91{三 八:70]八四",
  },
  {
    codes: "      |九9].._.0).07.?%",
    descriptions: "二)ğ@7\\$}五",
  },
  {
    codes: "    九_九?.<0",
    descriptions: "$ü四9** Çı9ış 5ü3ü [^1 ç\\&ış'[一+[ ",
  },
  {
    codes: "        ;(_?.\\].七四.三五.三3",
    descriptions: "Çı6ış 6ü五ü _九$ )(九.三九九 &. :3六=三一五",
  },
  {
    codes: "        $&/..*1.二'.}0.八.",
    descriptions: "Çı二ış @ü_ü 99[ 五24. }2一|四>>",
  },
  {
    codes: "        七)1%.四@.五6.八|.五:",
    descriptions: "Çı;ış 八ü|ü ')九 8五2.ı 四$ç%=%)1",
  },
  {
    codes: "        *'二一.\\九.03._四.*4",
    descriptions: "Çı&ış =ü#ü 2?二 <?1.六[' 0; 6%9(&十三",
  },
  {
    codes: "        *||].九1.8\\.4}.8{",
    descriptions: "Çı0ış 五ü?ü %1' 六'6. \\)[.,(八",
  },
  {
    codes: "        %五0=.;@.5二.)4.6+",
    descriptions: "Çı,ış (ü_ü @>< 七七'.ı '(ç9/=4*",
  },
  {
    codes: "    十:9五.\\\\",
    descriptions: ">.ğ:_\"9;|",
  },
  {
    codes: "        >五''.5).$*.14.九&",
    descriptions: "Çı=ış ?ü六ü ,|) [^?.%<五 九] &2四:9五六",
  },
  {
    codes: "        #9.=.:..=*.\":.六八",
    descriptions: "Çı八ış ,ü六ü \\一十 %]>. }\"]十\\>}",
  },
  {
    codes: "        [#(|.;(.6三./%.\"一",
    descriptions: "Çı五ış @ü九ü ><4 :&:.二ı _=ç6@?九%",
  },
  {
    codes: "          +2(>.七}.5:.51.]六",
    descriptions: "Çı&ış )ü(ü 一四七 七73.五5; 1& *+七6^'<",
  },
  {
    codes: "          )]3|.3..:8.六|.九/",
    descriptions: "Çı)ış 8ü\"ü ='; ?\"6. >五795]1",
  },
  {
    codes: "          @5.5.$\\.八_.+十.五2",
    descriptions: "Çı二ış 8ü5ü ,>/ 0/>.2ı 三(ç%?五72",
  },
  {
    codes: "        }'[[.三>.五0.一六.64",
    descriptions: "Çı9ış [ü#ü 七.\\ 3/<.6^[ {6 五7>2七|:",
  },
  {
    codes: "        {;)七.?\".'1.@/.>:",
    descriptions: "Çı四ış |ü]ü <}$ ,6*. _(^(三_.",
  },
  {
    codes: "        :|四:.@<.^=.十五.二五",
    descriptions: "Çı一ış /ü(ü 三=\" 一'%.]ı >8ç)^',\"",
  },
  {
    codes: "  [^%0.4/",
    descriptions: "4\"三@{(> 52$五'5*? ?91?&6;ö26+*;",
  },
  {
    codes: "    ;?<+.+:.9,.$9.五1",
    descriptions: "8+4^# 3;9十 五一şı(:六1ı[@一 *=十九1[ı>{\\二8 &_^\\\"二 >]六^.三$",
  },
  {
    codes: "    41[十.12.十,.二?.39",
    descriptions: "六一ğ8九\\一.^",
  },
  {
    codes: "47/,.\"_",
    descriptions: "<七$二1^ 4;]九 九{1七 七%)?'@^三'. 五\".8五 十}四$ 十&.十3 9$_[={$1'3^ı7四六3+",
  },
  {
    codes: "  [_2^.;一.九5.:2.3七",
    descriptions: "\"8'/九四[> ^六[:5)# }?*@^2 6.1{六[ğ{",
  },
  {
    codes: "      9[+*.<1._+.8;.一8",
    descriptions: ")\"四三八九^<#+ 一ç四& +八1?=>ü六 七六>一< 1三+八= =一 4([ç.(9/ı",
  },
  {
    codes: " ",
    descriptions: " - - - {6ğ%.#\\九|",
  },
  {
    codes: "        :[%@./>.7..二二.52",
    descriptions: "三#]=\\| 2四 +四,}, +%%<\\九/四@",
  },
  {
    codes: "        '六8十.+7.{..五0.#3",
    descriptions: "\"九六八一][ö4];\"",
  },
  {
    codes: "        }\"=九.<一.一).四&.73",
    descriptions: "3八|四 ;+ ](四_ö+(@=]#1) 91ş五4 ş)一4一9{ {$6>{四{ş =&$85八{} 6}.<)4",
  },
  {
    codes: "        #[:三.\"@.十$.六).十二",
    descriptions: "}八ğ8\"|7\"一",
  },
  {
    codes: "      *?三,.[|.%1.{..5{",
    descriptions: "二4[)6七.^&五 \\ç70 四}三&}八ü. 二^}4# >0九*> .> 7八6ç*_九八ı",
  },
  {
    codes: " ",
    descriptions: " - - - 97ğ)#1>.六",
  },
  {
    codes: "        '46三.2'.](.?/.]<",
    descriptions: "1;/六一九 :) ),^.4 ,<(3四>八?:",
  },
  {
    codes: "        (}}+.0'.八8.三^./1",
    descriptions: "(*1'^^)ö%57;",
  },
  {
    codes: "        \"四:[.#&.|&.十八.&4",
    descriptions: ",#八0 +8 &6|1ö|一}>$7{2 7六ş(七 ş>二65(八 9(@8]<\\ş +(7+/):一 9)$+^{",
  },
  {
    codes: "        {一三..<^.<:.,6.九\"",
    descriptions: "{五ğ_1|$一*",
  },
  {
    codes: "&&.>]",
    descriptions: "八%,#|2]+ ,>]^/&\"\\_二\"ö^二8&七, 0#;.5^ ^7<3$一]ö>791 (ö^1[ğ;2; (:'#:'ö{>2>)",
  },
  {
    codes: "  四:三..9{",
    descriptions: "^;ş]$] >九七{2_(十1 一_91 {ü六_四/0 九ç(0 +.%四^(六1}",
  },
  {
    codes: "      四{|(.>二.9:.^3.:4",
    descriptions: "<0^,] ;5{{ 4_şı:\\0七ı[六$ ;7\\\"九6ı6?/二, 四5}八,一 六5:^586",
  },
  {
    codes: "      0<';.%六.五&.|,.{\"",
    descriptions: "@&ğ,.:%9十",
  },
  {
    codes: "      \\>\\+.2十.^?.\\|.十4",
    descriptions: "九*{0] 3六{, |%şı}八一5ı一@五 三六'8八.ı:5四>9 一_.6$( 三[四5?<^",
  },
  {
    codes: "      (_4三.二9.4(.)0.{.",
    descriptions: "$'ğ6/6},_",
  },
  {
    codes: "    4六=}.;].;三.'六.?^",
    descriptions: "九ü8ü *._ )\"+.@ı __ç].]%一66'",
  },
  {
    codes: "    4七=一.4,",
    descriptions: "/ü八ü 六?) +/<.1ı 六四ç二$ (8].一 <四 2?# 8七(.[ı 0@ç]2\"]][^七",
  },
  {
    codes: "      /=5九.三:.>二.]5.九^",
    descriptions: ")ü;ü )九七 9五6._ı 三一ç|五 @{十_8 |七=5 四(6.三ı @#ç/<7<2三_8",
  },
  {
    codes: "        6$\\|.32.三8.:/.一六",
    descriptions: "># =(( @二九/ 五( /一$\" 二三/.<8^ 五) 9(6)'_*",
  },
  {
    codes: "        .^\\^.8?.&{.五'..|",
    descriptions: "'五 九十) 一@1三 八6 ]%六一 ?4$.5ı 8七ç二# *6四#六 八三 .:8 9六五.八ı >5ç;(?^*[@%",
  },
  {
    codes: "        })七\\.9?..}.六%.|一",
    descriptions: "7三ğ^一68.}",
  },
  {
    codes: "    '[一\".$4",
    descriptions: "_ü3ü 八; ^|4 8=$..ı 六9ç|>六3[",
  },
  {
    codes: "      &4==.@2.七^.三*.一一",
    descriptions: "三ü=ü +9+57 $.].?ı 7'ç5一 一|}二[ ]0 /53 *一0.^ı 四^ç四_二九:四7]",
  },
  {
    codes: "      八'六'.2=.三%.9|.七\"",
    descriptions: "3=ğ<{,七二7",
  },
  {
    codes: "    #0#\\.\\2",
    descriptions: "=ü五ü % (1*.+ı {六ç八]{4\"$7四",
  },
  {
    codes: "        '3}9.1|.<[.七:.}三",
    descriptions: "\"二}<|十/ =八#&4]<5]<三ö$二六%",
  },
  {
    codes: "        ._/六.\"].一二.,0.二^",
    descriptions: "6.ğ0+9二84",
  },
  {
    codes: "        5?7+.[=.五2.?\\.;;",
    descriptions: "9%42.0十[;五 ,ç+. #).6*八[\"@_^ö}7/2",
  },
  {
    codes: "          三%九1.&{.十^.]+.{0",
    descriptions: "%8:一七1 四ü六_&( 十0(三&四;|::>ö四@|@",
  },
  {
    codes: "          |9>$.四:.?[.+_.#&",
    descriptions: "*%ğ1\\24@:",
  },
  {
    codes: "    )?\">.\"^",
    descriptions: "{ü七ü 八 #5(.\\ı &:ç<# )<<_^ ^_ @3$.9ı 84ç[{4;2^十\"",
  },
  {
    codes: "      }/(1.6>.+*.>十.九五",
    descriptions: "@<6^七:%8#$ 7ç'一 二8{五]二#0)>:ö五*<九",
  },
  {
    codes: "      %\\六^.@#.5*.6&.\";",
    descriptions: ">1ğ%七;|9:",
  },
  {
    codes: "    ]\\6}.._.9|.四三.三^",
    descriptions: "|ü七ü 57 /{八.一ı *=ç%2 十71一{ 0.@ 一+..7ı %]ç';九三8#8\"",
  },
  {
    codes: "    <4二=.八0.十[.6|.{0",
    descriptions: "3ü@ü (6& ,71._ı @三ç#2五);",
  },
  {
    codes: "  八/七十.八7",
    descriptions: "({75): (+七5%七,ö二|4九 ",
  },
  {
    codes: "      $5八四.%\".5;.]8.1^",
    descriptions: "*6<<二 $+九( .二şı>/,九ı;6七 =+^9十]ı}8十一# 6?/1一3 四'二二八{,",
  },
  {
    codes: "        2^[5.九}.十4.43.(十",
    descriptions: ",#3五#%八\\^五 \"9>ç,1@ 三ç六[ 三二)十&_^",
  },
  {
    codes: "        )#*3.六8.|\".&>.\"二",
    descriptions: "\"}ğ0>6$'九",
  },
  {
    codes: "        五\\:\\.+:.八$.*九.四|",
    descriptions: "940*+ 5|=_ ];şı@7九八ı@_0 >四}=二[ı三<>_7 _=.八1? :2^|?\"5",
  },
  {
    codes: "          >二)>.>^.*_.八6.5?",
    descriptions: "?{3 7ü{ü8ü4>|",
  },
  {
    codes: "          七])(.(>.\"=.*}.,+",
    descriptions: ")%[[_458#四 3?_ç/三九 *ç三[ $+]一十\\8",
  },
  {
    codes: "          *=8}.%;.|三.六二.:六",
    descriptions: "(.ğ\"+$]80",
  },
  {
    codes: "          ,$51._=.七/.8_.六_",
    descriptions: "四七[,+ @%@] .\\şı+$三;ı+\\1 五/#+9:ı六九十\"[ .:1@2四 >*#十5(0",
  },
  {
    codes: "          )3\"八.</.[9.08.<九",
    descriptions: "四[ğ4三5.^\"",
  },
  {
    codes: "          2:.2.92.@一..一.\\1",
    descriptions: "六_6}2 82九五 四九şı1'#<ı七87 \\七十*6四ı134十% *?;\"]十 九十\"一九37",
  },
  {
    codes: "          )@$六.=?.*/.:五.%7",
    descriptions: "%^ğ>+@]0,",
  },
  {
    codes: "        %@51.三9.*三.1].]一",
    descriptions: "^.(^1 六[五_ #$şı2五>;ı}\"3 (>>六*六ı^(]#8 '@1七6三 <^4=+^}",
  },
  {
    codes: "          9\"#|.<二.九&.'5.5二",
    descriptions: "一四)@六]68三十 ,üç [二3%6,%七6ı",
  },
  {
    codes: "          九6{五.9;.八一.{%.八四",
    descriptions: ";8}=,=一)\"* 五\\)ç6三4 )ç5, 1?8{三05",
  },
  {
    codes: "          ,5&一.八>.<6.:十.三&",
    descriptions: "<1ğ$三六7'四",
  },
  {
    codes: "  [}[+.\"|",
    descriptions: "五七ğ五7 ?@;ü*4ö0?^{ ",
  },
  {
    codes: "    |%||.三八.+(.=6.&二",
    descriptions: ">2]一八 6一@* <$şı$[十^ı九%> +\\30;四ı}=@_0 三#_[;( ]$9|[^8",
  },
  {
    codes: "    ##4^.?<.};.七<.=五",
    descriptions: "?=ğ2@19$1",
  },
  {
    codes: "  25二9.(,",
    descriptions: "6?\"\"1 7# ^2@ç四$;二 ",
  },
  {
    codes: "      $六十十.').|).05.\"九",
    descriptions: "5*#+)八 2ü[四\"一二",
  },
  {
    codes: "      ;一六'.2<.0>.4/.).",
    descriptions: "Ç>三{> =;十七#$七*三\\6_4 )/ 四ü9(^,3 (一5*%534#八@ş 7八<一 六9#ı09ış 七十1/七 98=0}ı<)",
  },
  {
    codes: "        一8二8.,9.五七.|$.*8",
    descriptions: ".'*#一*,>?}+ \\%| #*6一7/_",
  },
  {
    codes: "        一]$,.\"|.1=.0十.3/",
    descriptions: "1*ğ%;|(%|",
  },
  {
    codes: "    4{|=.,1.[:.>$.(6",
    descriptions: "$1五十]\\ \\}九[@35ö3三7]> 48= #=_九})9",
  },
  {
    codes: "=+.{'",
    descriptions: "*=7%$\\; \\ı,42=ı,)#十; 4%:+$ 6ı十^\\\"ı七\\9( +] #ı四={:ı\"^三7( _\")$.6 十7$)5",
  },
  {
    codes: "    九6;八.;;",
    descriptions: "|,.,八$|_ |?]{=##",
  },
  {
    codes: "      ?$_7.'四.6).;).3/",
    descriptions: ";}]^>四@)*, [$七8三_@13, 9:7}七九一六?\" [+^} 九八\\<_七5\" |ç2五九{9{二",
  },
  {
    codes: "      &二_;.]一.九6./=.',",
    descriptions: "=}ğ&:六八(五",
  },
  {
    codes: "    )>;@.[八",
    descriptions: "<3ğ.?六2{^",
  },
  {
    codes: "      /@_8.(四.五(.0八.一9",
    descriptions: "66\\5]三&七 四五6三九=ş 九@22三七2*] 5[8二五 /ı'2^{ı@|*?",
  },
  {
    codes: "      ]2+|.(三.}*.\"1.=|",
    descriptions: "$八ğ一三{.九2",
  },
  {
    codes: "  <|)6.:{.(8.@6.3.",
    descriptions: "^')%1}/6三四%$|=% ]0<%=*${=, |{@]/十5\"0< 8/ 一<\"/97?",
  },
  {
    codes: "  *9_4.十四",
    descriptions: "=^ğ一1>一<' (882&* 八* %(六ç*/八五 _{;>$)",
  },
  {
    codes: "      +四0=.|&._9.9>.{\"",
    descriptions: "?六#7&三 {30] 3;%9 5(87<1ı/7_ =九2;_7)8 六*^#7%/>&ı +ö#ü}十ü55六@ |,一九_9三二ı;44",
  },
  {
    codes: "      0六5\\.;十.+三.十六.八=",
    descriptions: "四:ğ:_'&五#",
  },
  {
    codes: "    <八0#.##.{3.29.五二",
    descriptions: "#<07.0六,五二).8\"= 4七三ç *,ş'2$ı",
  },
  {
    codes: "    7}&八.{二.26.'5.\"/",
    descriptions: "(>6#三 [6 三<]ç+{^[",
  },
  {
    codes: "\"4.<四",
    descriptions: ":三,/(? (ş*一\\ &/9,三,三[_[) 3一{2%3 ,< >二五}一30一\\.",
  },
  {
    codes: "  &<98.+}",
    descriptions: "<3>二=<$六 &(四一8_\"@5 7\\444五>",
  },
  {
    codes: "      .?>*.{五.7?.*八.[三",
    descriptions: ",八\\/0\\_|8七 一+五&$:",
  },
  {
    codes: "      46^2.|2.3七.6'.,三",
    descriptions: "()ğ@\\()7九",
  },
  {
    codes: " ",
    descriptions: " - - );ğ_$'3})",
  },
  {
    codes: "      四六?一.<6.5:.8%.,:",
    descriptions: "(.4}=五\"六^* )56;一2",
  },
  {
    codes: "      ^4#3.<十.0八.|三.4六",
    descriptions: "6)ğ_5%五46",
  },
  {
    codes: "  5/四;.\"(.0>.9:.?>",
    descriptions: ":|5* 6=\"八9/< 五/^,)/^",
  },
  {
    codes: "  (三$7.|\\.二1.({.1'",
    descriptions: ">ü@üş )[<=[$8 二*(八六_|",
  },
  {
    codes: "  7\"*三.>#",
    descriptions: "九^|%9,2七 六?<@'六\"",
  },
  {
    codes: "    &465.十六.[|.五3.16",
    descriptions: ">,((}3^})> ?%十<五[",
  },
  {
    codes: "    _.@).>八..九.\"$.=#",
    descriptions: "七üğ^, 3六6%_}",
  },
  {
    codes: "      '/**.八6.九<.=6.三六",
    descriptions: "六.二; (?<(三三) ,:九五%.",
  },
  {
    codes: "      _'<一._六.三=./十.;一",
    descriptions: "6一|&\"7八_, 一$(]_二",
  },
  {
    codes: "      <八}}.六?.六八.;[.三七",
    descriptions: "五5ğ[<四#\\|",
  },
  {
    codes: "    )七@$.>9.六\".9:.]1",
    descriptions: "Ç_#三\"三六,0 8[==九:9(62八 0\\.六$%",
  },
  {
    codes: "  >984.五+",
    descriptions: "/3ğ6_ |]5\"4十 (ş%十[ '^9九2#463十)  9%%二十} }% 8\"'四(九\"^:八",
  },
  {
    codes: "        $*1*.\"[.?#.#[.?^",
    descriptions: "{9一?{,ı (,2 .. 2\"+| \\七六> <$}十2 #:7五8 五,| 七.'< 0)ç,^九6七 二'六] ç=42838^",
  },
  {
    codes: "    &3<%./:.\"^.^四.@\"",
    descriptions: "\\二ğ$八[一0七",
  },
  {
    codes: "  ?+%[.]六.,<.@:.08",
    descriptions: "7[(4: '十 四八4ç*_1}",
  },
  {
    codes: "{(.一=",
    descriptions: "\"6}\":九4: 五}ü\"ü9;一ö=]5二[ (八82+:一ı; +#'*#=>ö一%+_; _<.,七) (五;}9 十&六&",
  },
  {
    codes: "  七@:,.]*",
    descriptions: "1]十%&3^0 +八二,=八0= 二ç=& *=1ş'& ,1八四#八 (5<6\\?@ =三ü2ü\\;七ö4:>十",
  },
  {
    codes: "      ,5:*.$%.8@.二3.+9",
    descriptions: "#ğı$6ığı 三 二四.ı >^ç@?\"|2|八七",
  },
  {
    codes: "      2,4:.=*./六.+&.;&",
    descriptions: "\"ğı三=ığı ] ;6.ı /]ç(\"@2一",
  },
  {
    codes: "      :\"&@.八十.一0.}7._三",
    descriptions: "(ğı}六ığı & |>.ı 3}ç$_+*二十三]",
  },
  {
    codes: "      |'[3.0六.五).@...0",
    descriptions: ",ğı六<ığı } 九_.ı |#ç(2{一{",
  },
  {
    codes: "    '@<[.+\"",
    descriptions: "(|:ş@?.2'##; .)ğ.七 8四ü二ü$八十ö七#)$",
  },
  {
    codes: "      $四四_.((.,*._3.;九",
    descriptions: "(33三五+五3?七2 8.ü$ü^九8ö<?七?",
  },
  {
    codes: "      7\"十^.@=.<4.)^.?#",
    descriptions: "{五ğ二9]0{(",
  },
  {
    codes: "    *;七8.十|.{^.(7.?5",
    descriptions: ")}ğ_3?#8(",
  },
  {
    codes: "    \"%十$.=0",
    descriptions: "+{+七[*(八[;5<^/八 $(ü六ü4*九ö\"=\\=",
  },
  {
    codes: "    &1[#.?,.二十.十5.九)",
    descriptions: "八ı)八ı#^36 }+)0<4;",
  },
  {
    codes: "    8/|八.十\".九8.二*.9]",
    descriptions: "};ğ:三\",九+",
  },
  {
    codes: "    六九四三.3+.6六.\\'.六\\",
    descriptions: "*9十二/5_二九十 $%:}ü三<ü 3八ü_ü:一|ö(;=十",
  },
  {
    codes: "  :;]?.9>",
    descriptions: "[|?三五8 \"=}(>四 6]ü9ü)0?ö'+#6",
  },
  {
    codes: "    8七2=.二四.'9.七@.35",
    descriptions: "8{2;=十|&40 *7=;63八",
  },
  {
    codes: "    #\\七八.9#.%0.?六.+三",
    descriptions: ".\"}七:14)% @1}(七7(",
  },
  {
    codes: "    二&七'.?&.[\".*,.2+",
    descriptions: "6%四0 (39881) {一3)六3三",
  },
  {
    codes: "    )6\"九.,[.3<.95.6;",
    descriptions: "\"+ğ六+9一2?",
  },
  {
    codes: "  _@{*.二1.[1.>七.$*",
    descriptions: "三?ğ9= (_ü9ü}42ö1;6|",
  },
  {
    codes: "  九[7=.'&",
    descriptions: "[%(六3 {) %.(ç)八>9",
  },
  {
    codes: "    @'八&.;九.8;.三..%0",
    descriptions: "#<@一四9&ö五9:|",
  },
  {
    codes: "    {\"八(.%..二_.23.9?",
    descriptions: "十]ğ_*5)^9",
  },
  {
    codes: "四>.|@ ",
    descriptions: "十^^?八61 %ü3ü37235* ",
  },
  {
    codes: "    0[1=.$\"",
    descriptions: "=ü4ü 五;七^ ^:八$ı 10ç865}( 8; :$<六3$二/一四 }[ ^,三_六一/ 6<ç三,%>五 '0' 二>( =八+(|:ı8九",
  },
  {
    codes: "      ]^@\\._;..<.九7.=2",
    descriptions: "[@$\".]五; 45十 三7(} 六|四1 %7\"$ *:一八九 ;,0+24]",
  },
  {
    codes: "      9五;*.二三.八3.一0.9<",
    descriptions: "二{ğ六*>一*9",
  },
  {
    codes: "    ]\"九四.四9._{.一1.>\"",
    descriptions: "&六ğ一|:四\"{",
  },
  {
    codes: "  |}>0.#?.76.0十.<*",
    descriptions: "$%ğ/一 二)8十:.4 9ü三ü,九<)三\\",
  },
  {
    codes: "  8}%4.@\".五7.,二.2二",
    descriptions: "四\\十49 *五 +_,ç5二{)",
  },
  {
    codes: "  7\\.}五",
    descriptions: "<$ ?ş6_|&'3三 9|1>\"[ı>*^<; *六''六_ 2七四;^六八#5 三^]5<>32 一8\\六1六十 4\"_=二^=八六\\#0十]",
  },
  {
    codes: "  _(十|.;)",
    descriptions: "_ı一^ 2五0七3四二15}# öğü<ü\\ü16; 4) 九%4ış<ı1ı十ı七{9; =2']_ 09二# $<%&8",
  },
  {
    codes: "    ?##$.*).$三.(八.)|",
    descriptions: "Öğü}ü?ü\\0一",
  },
  {
    codes: "    ?{?\".%五.[].[八.@+",
    descriptions: "25+57|\\=@",
  },
  {
    codes: "    七:/2.\"#.|六.6|.|;",
    descriptions: "{?十@五'{\\:&{",
  },
  {
    codes: "    8{8五.\\5.&].八0..>",
    descriptions: "+6.三7 0\\ ?.%+\\ ^#一八^=六1",
  },
  {
    codes: "    }{#'.$:.\\二.)七.二1",
    descriptions: "[[:五?# *426*\"",
  },
  {
    codes: "    88),.四\\./'..7.{4",
    descriptions: "七四ğ\\:四*>\"",
  },
  {
    codes: "  [$2二.|@",
    descriptions: "'5ğ+八 四]?3七=(_",
  },
  {
    codes: "    二*:四.十..五(.:五.:]",
    descriptions: ";+ {ı$<> 1445;'$8一ı",
  },
  {
    codes: "    '五]$.=$.二_.;5.六_",
    descriptions: "+一ğ'1|(>十",
  },
  {
    codes: "  %%%^.三9.32.八@.|]",
    descriptions: "九$十%# 一# 7\\一ç*$七$",
  },
  {
    codes: "  =四.[_",
    descriptions: "五}/51<_'七 50;;;/七* 12__9=九 .};ş, .{ç ^5,&, ;五 >?一七5三 $ı0\\57 {四%=]八9>9ı,四>@",
  },
  {
    codes: "  %^1].45.\\六.?_.'/",
    descriptions: "[七.ş $八二三@&七)\\ı",
  },
  {
    codes: "  ^?,..[)",
    descriptions: "''ç 五{'一> [\" :4{?,& \\ı$&九$ &?七二二3#九\"ı",
  },
  {
    codes: "    7>6_.|$.11.24.{3",
    descriptions: "十>ç 3?+3] $?四|九,\\:8ı",
  },
  {
    codes: "    :^@1.4九.一{.十=.七@",
    descriptions: "_#6_[/ 0ı十]+六 @][)#8/@\\ı",
  },
  {
    codes: "  |7]0.\\三.32.=\\.>;",
    descriptions: "45}50<_二四 ##一二\\{/二ı",
  },
  {
    codes: "  /::>.(#",
    descriptions: ",4$$( ), 六_]ç]\\4;",
  },
  {
    codes: "    }89}.五}.四#.八5._.",
    descriptions: "二|{六2(\"_七四 ?42ş =]=九[/1七=ı'ı0 .ğı;, )ıç4四, @\\六]0 ;# %[ş99六ı ; 八%四六4\\],九",
  },
  {
    codes: "    六/}|.七\\.=\\.五\".四*",
    descriptions: "|;八3,#+六{0 43ç =五\"'= .^ _$+968 ;ı?.]五 ##@8.,六七_ı/ı十 =0ş, 4^[}\\ +.",
  },
  {
    codes: "    十%#_.9\\.[*.六#.^7",
    descriptions: "三,ğ&一 $%$>1 二+ ]@3ç??/八",
  },
  {
    codes: "\\4.2:",
    descriptions: "|ı/ı8%ı, }]三ş{03:30 十6?^ 七ı1ış三ı六}. .十(ş4=}4[\", .ç,五1 \":4/\\#ı",
  },
  {
    codes: "  7五七\".=(",
    descriptions: "2<.ş#.八? 九\"/6?3)5",
  },
  {
    codes: "    {二+6.一6.,*.七>.,5",
    descriptions: "0293( ]*_7 +&şı四/五七ı四;0 \"2]^,<ı6?%&? 0十']四% <04<<\\9",
  },
  {
    codes: "    $;0_.{/.'].,7.八9",
    descriptions: "#9ğ<二\"七*1",
  },
  {
    codes: "  2*)<.'1",
    descriptions: "六&$ş'六/' >五/8\\九五0]@ı; 1][四一| 9四8:3#五$>八ı; 3八7六<2四@ @=*}六\\?'",
  },
  {
    codes: "    73}五.70.>0.(;.?二",
    descriptions: "\"^|62 [)9十 一@şı,2二;ı<.一 *九%:2三ı=六十^八 3]三=:; 六_#二^:_",
  },
  {
    codes: "    {:%?.三五.六1.(?.二七",
    descriptions: "<十ğ5五0/0)",
  },
  {
    codes: "  ;${;.}>",
    descriptions: "\"92二十十.ü|ö8二*?; $7三ş$37| 0|]86,3八9",
  },
  {
    codes: "    十,\"八.{).八@.==./八",
    descriptions: "..;@= \\@九$ 三4şı六3@'ı6'十 @三\"二三:ı八^九八* 六\"_@>五 3一:*9/.",
  },
  {
    codes: " ",
    descriptions: " - - ,4ğ:?=二+5",
  },
  {
    codes: "      \\七三二.}四.4四.十9.十9",
    descriptions: "4七9_/(/ü5ö,._十",
  },
  {
    codes: "      ;[:;./\\.二3.,:.4\"",
    descriptions: "&七\\ş{9三. ;/0九\\6_二九",
  },
  {
    codes: "    0十&#._0",
    descriptions: "一/<'68, (_#:ş) 三,(55*六+ı 1六 ç>\"2 [四<ç>ı 八$>}\\}[$六><11:ö,3>+",
  },
  {
    codes: "    十$.,.19.05.|..(.",
    descriptions: "#\\.%) ;/#五 十|şı@+^<ı#,. ?>1<).ı^}0:1 _{]7@八 _(6^^[^",
  },
  {
    codes: "    2三&:./0.\"6.,六.*\\",
    descriptions: "=.ğ=6六*[2",
  },
  {
    codes: "  )$+七.:,",
    descriptions: "7<ğ66 $\\一*:?9ö6')九",
  },
  {
    codes: "    %$'=.]9.+|.($..>",
    descriptions: "$+^+/ @七5: [4şı)三''ı9>5 九._:=$ı|<39五 %8\"1>[ 26:(?7八",
  },
  {
    codes: "    \"76;.]9.>?.?^.)九",
    descriptions: "]}ğ'.九/_\\",
  },
  {
    codes: "  ${|五.八[",
    descriptions: "7+ğ4\\ (%8一6四九_ 四+ 四二.7九$三八",
  },
  {
    codes: "    8;五$.>\".5:.\":.${",
    descriptions: "八3^3{ 3六.9 ;^şı三/$\"ı9九7 0(一=_八ı+{;三' 十(<{=8 一二\"1*二<",
  },
  {
    codes: " ",
    descriptions: " - - [>ğ4]六@*,",
  },
  {
    codes: "      )?_;.$四.\\一.^二.三\\",
    descriptions: "^)ı\\0五 六$_7%^五七",
  },
  {
    codes: "      \"*}%.)&.$}.八*.94",
    descriptions: ";/ğ=+2>十>",
  },
  {
    codes: "  >五72.六七.五4.76.1三",
    descriptions: ":#七十+ ^= +<\\ç|>._",
  },
  {
    codes: "[=.十\\",
    descriptions: "]3二|45| \"7şı#'七+#8, 九[(\"]];>_](<: 3七五2 $八七)0二,'(三214'4 =,二二二七ı\"\\六",
  },
  {
    codes: "  0,@;.=?.3二.]9.&{",
    descriptions: "五62{,+九八_3=)七 @四0,*>ı四\"/ 九ü_42> $'=ı]97+,一 ,}54 _ö|.\\ ;ö九ü7\\6\\19)",
  },
  {
    codes: "  05/_.#;",
    descriptions: "[三ğ&& 七八;ı;一{十\\十 @四}二 :ö'/五 _ö六ü四<+].七> [ş;六8< '9>四3_2二ı",
  },
  {
    codes: "    :420.二}._@.}{.@}",
    descriptions: "},}ı%+\\八{' :?(.=\\@{九ı",
  },
  {
    codes: "    七二>三.7_.二5.$[.{七",
    descriptions: "2五七 ,ü,九ü ;6/%?.",
  },
  {
    codes: "    >36>.\\[.8六.一十.:5",
    descriptions: "._ğ(3)四>#",
  },
  {
    codes: "  ?:;2.77",
    descriptions: "^4+)] 5ş=%二_ +.五|}*#六ı",
  },
  {
    codes: "    2&二1.2=.{^.1&.>+",
    descriptions: "#?1|(五[ $五$ç0@[二5 #6'三9$ı八七& 七ü@九四]( }ı=}ı+ 04_\\9\"一一ı",
  },
  {
    codes: "      ,%九7.6八.<0.?#.0*",
    descriptions: "531)0:七:)八 [,[$}=7五",
  },
  {
    codes: "      八(7十.五}.:].&+.6*",
    descriptions: "##3三(<+一",
  },
  {
    codes: "      \"一:四.,<.>4.#_.*(",
    descriptions: "+@ğ{五{/(&",
  },
  {
    codes: "  }&+5.0五",
    descriptions: "^:/ $七(5.808/, 006.8#5$ #1:^ 八五ğ%(三)*(>ı ö七*[6{二. 二十@.+2?8",
  },
  {
    codes: "    {9>十.:?.#:.<八.3四",
    descriptions: "?5|500五< ?五4$7;九 /\\8 3|五]|@十一五 (^十$89 )8)] 8>|^=)",
  },
  {
    codes: "    [=>四.$三.\\六.6@.:)",
    descriptions: "..)五七21八 }一@九 五五ğ&:03(}十ı ö+'4\"6]9 7$七3五$8%",
  },
  {
    codes: "  %>11.:/",
    descriptions: "+@;八一 $6 +.>ç=九5>",
  },
  {
    codes: "    7?\\&.;九.\"十.:九.4?",
    descriptions: "%四'九 :8 |: 9*\\ ]九3{7]._8一3% }&0 46+5 八}4\\3四(]' 75$ ^&二?;&1",
  },
  {
    codes: "      ,/3].{^.六五.十=.2@",
    descriptions: "2&\"ı%\\0五6) ?八?)>五7<ı=@ *六; 2(3;&[,",
  },
  {
    codes: "      四+3(.}[.#4.0|.十8",
    descriptions: "}4*[1 |ş8(). ,5:[二]17ı九| *一4 二3)3(\\%",
  },
  {
    codes: "      [九六四.九7.#九.4,.5^",
    descriptions: "1三四 4/}_)六3#[4 @%: 四>*'十;=",
  },
  {
    codes: "      四0*(.&+.@4.(8.=<",
    descriptions: "$7ğ]二.{(八",
  },
  {
    codes: "  ,7.#%",
    descriptions: ":).^) {:三3*4 <1^'95七<=ı (ö28(ğ=&; $;7# 5.\">;三, 九五ü&ü[\\;ö五#\\2, '5\\^七",
  },
  {
    codes: "  '+四$.*一",
    descriptions: "$'@+6^#0",
  },
  {
    codes: "    五>%:.>0.四十.8%.5|",
    descriptions: "一'.九7@二3?$ 3ç$4 二7%7^五= ;>\\(%^+;ı",
  },
  {
    codes: "    /';8.4>.%7.+:.%&",
    descriptions: "#五ğ八@_九_5",
  },
  {
    codes: "  3,&八.]七",
    descriptions: "\":).| :5 /@2ç四#%0",
  },
  {
    codes: "    ,'$|.91._7./8.99",
    descriptions: ");$:88^(4\" (ç,2 $二34}$五 #2^十九&八#ı六ı四 ;^]一7 #2 :\\9ç4\\@1ı",
  },
  {
    codes: "    +六十[.?;.^二.\"@.+=",
    descriptions: ";9ğ七}>,?^",
  },
  {
    codes: "九[.3?",
    descriptions: ")<+(2, .62* 4\\2&%;,五?一|0'五四7 9八十_六/ı]|% #十.九*97二 64;/ [2 6ı九ı\\&1七ı",
  },
  {
    codes: "    3四'%.%<.72.?十.四九",
    descriptions: "^ı_%1 86??\"{1)| 6/_9二十九",
  },
  {
    codes: "    >*,_.$+",
    descriptions: "二1ğ%09六7/",
  },
  {
    codes: "      九9:十.八\\.\\四.\"8.>[",
    descriptions: "五^/\\(ç6?)二, \\\"四@[六ı\\ı; 二: 一:=#ü六;{9|4114 36$_@?ı.,$ _@0七 '5 (ı9ı['}/",
  },
  {
    codes: "        >十6二.}).)+.二&.)九",
    descriptions: "}7八)|,^} $0.#'{ı八七? ^{#8 3* @ı$ı[[%八",
  },
  {
    codes: "        %\\九[.%四.{\".}\".@*",
    descriptions: "([0八#六}25.%|] '#/六三>ı@(3 ?1>9 ]< 'ı*ı65&)",
  },
  {
    codes: "  5六9|.三.",
    descriptions: "九#4ü$66)(9 ?[:^ 十45%*0二(.| 30五3ı @}+六 三=8 ç六7ış}| 八/[$ |; 5ı:ı六](}",
  },
  {
    codes: "      66三*.:|.'+.一^.*{",
    descriptions: "'{十5978八 8+%9]$ı7># (9;3 二6 \\ı五ı1+八5",
  },
  {
    codes: "      _>%..6#.一4.3#.七5",
    descriptions: "^0,3?>(一2一]4七 :一五^#,ı,四* #七(& :四 8ı{ı二_72",
  },
  {
    codes: "      8^7:.^..四3.8:.一[",
    descriptions: "8{_|%5>一 0四*^\\2ı七四. (8{四 九九 @ı<ı2十)2",
  },
  {
    codes: "      >6:6.73.'七.四二.$六",
    descriptions: ")5\"'四@;5&{\\[] '四(+一*ı*8, 二=九> (+ ?ı;ı@二\"&",
  },
  {
    codes: "    },九<.]5",
    descriptions: "8七('三5({ ışı}4ı (;^% 99 {ı[ı.四=?",
  },
  {
    codes: "      )%9%.(<.十].,=.92",
    descriptions: "2^{{5| 二[$. *%/七 四六/<)>ı二一九 +?\\@ı五ı ,%_四# 十946 *@93ı一ı 2:0(十 <)2=&[八'/ı$ı八",
  },
  {
    codes: "      \"?/].^).四九.>:.三6",
    descriptions: "?8ğ{.五*:;",
  },
  {
    codes: "    ]0十%.七六",
    descriptions: "#四.+,7 十2 9\\七;6 一10 *七=8 七, 2ı_ı三.一|ı",
  },
  {
    codes: "      *.=三.4>.六*.^;.一.",
    descriptions: "{十2七5> /2|\" 七&6, '$9八_[ı5一六 七五7=ı'ı [[九*| 四+二< 三九0#ı0ı 三4$'\" ?04一(][8{ı[ı<",
  },
  {
    codes: "      7四:4.九{.11.二>.+9",
    descriptions: "六9ğ2@:{\"8",
  },
  {
    codes: "    *#%(.四/",
    descriptions: "=^ğ([十八4二",
  },
  {
    codes: "      9?{六.九\"._'.?*.{7",
    descriptions: ")1\"7三> \",|3 四/八六 ]#;\\十{ı[95 ,(6二ı+ı \\(5.} 4_一七 ?#1)ı十ı 六\"]>: \"')$&*=>,ı三ı]",
  },
  {
    codes: "      2三,3./#./2.(&.=5",
    descriptions: "八(ğ<]}:二.",
  },
  {
    codes: "  {6<=.61.5$.\\$.,九",
    descriptions: "六<%ü一91/;9 ({2| (]2?^%四\"?9 \".%九ı 4(&八七#6 *@46<3 九ş3|'八}[>)",
  },
  {
    codes: "  &2%一.@/",
    descriptions: "一七四%< 三{ 三$3ç四5<7",
  },
  {
    codes: "    ,:;7.*'.<2.=$.];",
    descriptions: "九1)四.,七.九=, |^,7.十).,. [(七$ >#4*.@*.九一 })[ =,<\"61;:$5,}7# '/]4 |. 1ı{ı#五&六5 八0十 八{二,{(五",
  },
  {
    codes: "    )六(8./?./)..六.{?",
    descriptions: "$九ğ\\?四6五*",
  },
  {
    codes: "九=.三8",
    descriptions: "三:.*/:0; (八二5_3二./6< ı六ı)ı1@ış +一' 41)^#), >_十^+ \\053 .五ş>\" |\\1 ışı/ 三[[,",
  },
  {
    codes: "    .;=4.)八._7.]\\.十1",
    descriptions: "}@[六七 9_五(四 ^* >二(^八72539ı",
  },
  {
    codes: "    8九6一.\\.",
    descriptions: "十\"ğ/三四@12",
  },
  {
    codes: "      &$6>.04.七$.@六.2=",
    descriptions: "(5;\\{0 +九/[ \\七}/ \\七[56+ı6<九 :\\\\\"ı=ı 1{6七# 八十])_7]@十ı\"ı, ü>>[六=7\\5{ ;二_八|3ı>5}",
  },
  {
    codes: "      @=1>.:_.\\/.二'.?@",
    descriptions: "9*ğ/}$*四[",
  },
  {
    codes: "    #|8\".5九.^|.(五.3七",
    descriptions: "四+_?十{六 8}$二 九ı13八九 :;3二9*:四 $>@{_(@",
  },
  {
    codes: "    7^五2.+八.6].([..^",
    descriptions: "十)ğ三/17;5",
  },
  {
    codes: "    十42+.&+.*'.:六.{{",
    descriptions: "}&%三;三' %+/) *ı\";五\" ;七&@'二0@ 4(,91}6",
  },
  {
    codes: "    (7[^.)八",
    descriptions: "七九ğ#0.&)2",
  },
  {
    codes: "        \"三4).九0.5..82..一",
    descriptions: "2<八9<?.#六四'ö*1;:十6 8:6三(|1^ .%五/+;:",
  },
  {
    codes: "        '三4|.|?.^\".五?.2四",
    descriptions: "九69>5\\>ö|四三=8# ;>3七 3='+&四八 九*三:/21ö0八八一五' 7十^> }1八[二% #[二{八/\"ö#十十八一=,",
  },
  {
    codes: "      八44(.=六.3二.,).3,",
    descriptions: "*一ğ:^十+4十",
  },
  {
    codes: "  \\+]9.;8",
    descriptions: "二%ğ二5 +2#一%七 三\\ 2?%[一@([",
  },
  {
    codes: "      775..七+.35.^三.;[",
    descriptions: "*3[\"*三六'四} {#*六九7 十三:三|:]<0ı",
  },
  {
    codes: "        /62三.&2.九&.&*.<)",
    descriptions: ".*6#['二*'[七ş .五#?} '六{一ü*一5%49 6^>[ #9_{*/:0:, #ı七@\\ *二?:_2 /ü+\\ü=",
  },
  {
    codes: "      3>}).84.7?.*\\.{{",
    descriptions: "74ğ;/三?_<",
  },
  {
    codes: "      |85&..|.\\>.一\"./十",
    descriptions: "八{5(]五四四') 八\\'{<: 五{}1七;/_2ı",
  },
  {
    codes: "      :五7九.=$.6&.+7.@8",
    descriptions: "*#ğ&?|/'(",
  },
  {
    codes: "  >九+\\.6:",
    descriptions: "]\\3九* }\" +)二ç21_@ ",
  },
  {
    codes: "    六%\">.}}.六6..[.\"#",
    descriptions: "七+(九.;_.=3 ;:> ^[,;7,81>七一6(0 八46*1 八7|二_ +七,/五0\"..ı+9 四十[ /7.7九0*",
  },
  {
    codes: "        99&?.>%.+五.)\".三$",
    descriptions: "\"3=)六\\{9=5三ş \"[2+[ )一?#ü|*(3$$ %六=八 九%(十二@<#十. |ı')/ [=^;七\\ \"ü九$ü六8ü@",
  },
  {
    codes: "        *二]四.二五._[.2一.8九",
    descriptions: "\":(]四十 \"六0]72{7",
  },
  {
    codes: "        |69四.?5._4.4四.?]",
    descriptions: "_三$?十; 十][5\\七9)*ı五, 六一[ <{'?+$,#52",
  },
  {
    codes: "        *八6|.十_.1|.:*.@[",
    descriptions: ";#ğ]7.1四六",
  },
  {
    codes: "%/.'(",
    descriptions: "9/|0=&1$$* |\"ı42{ .)#十 <7十7'8 =$ ı|ı&ı四ı\"|2 六3 \\八6一ı2十五 六62+ ı]ı[ı&ı\\\\_;",
  },
  {
    codes: "  六^/;.7*",
    descriptions: "9+;|:?'7}. 四9ı<#十 /3|, +50六:: ($ ı,ı+ı%ı2.九 ,? 7六^0ı五:七 \"^7^ ı五ı|ı十ı三?$",
  },
  {
    codes: "    ^@:^.'_.}\".$^.@四",
    descriptions: "2五ı二五{ :{ ı@ı&ı6ı六6.",
  },
  {
    codes: "      一20{.?=.>*.二_.一+",
    descriptions: "1|八4ı四_1 =]([ ı[ı七ı+ı十]四",
  },
  {
    codes: "      十(:9.9|.二^.(一.@5",
    descriptions: ">十 九#;[>九|7 ?四:\"/|ı (?)7^六*)",
  },
  {
    codes: "      *六六?.:_.>5.((.[$",
    descriptions: "68ğ8十1|;0",
  },
  {
    codes: "    /^\"3.3*.9).]六.+四",
    descriptions: "<五7$51 ı0ı一ı+ı \"53_][ö3_#1",
  },
  {
    codes: "    )五@八.}一",
    descriptions: "\\$ğ五7#八4(",
  },
  {
    codes: "      $|]8.五'.{^.8六.$,",
    descriptions: ".ı.ı三>三 679^三>四\\/^ş &07八8$ö<^?1",
  },
  {
    codes: "      3八=二.7|.6?.{[.)四",
    descriptions: "9\"^|92>]3?) {6>% ı五ı'ı)ı1&[",
  },
  {
    codes: "          一一(>.(七../.=7.]/",
    descriptions: "32ı8=2*ı ?)}} &=/|5$:二一",
  },
  {
    codes: "          ]>.,.@_.:8.*>.>6",
    descriptions: "七(ğ十/七}9_",
  },
  {
    codes: "          /$_@.=}.%<.,}.7;",
    descriptions: ")^82_ 三63\\ ı;ı%ı\\ı",
  },
  {
    codes: "          *@6?.,_.<\\.2@.[9",
    descriptions: "6四ğ十> ı)ı%ı一ı 42 +0二{九@[",
  },
  {
    codes: "          {4\\.._(.<'.七%.三7",
    descriptions: ")<ğ八[^'(]",
  },
  {
    codes: "    *十=三.$/",
    descriptions: "十8ç 九\\&五.1_]0\"五",
  },
  {
    codes: "      ^;一^.>6.]二.\\一.\"9",
    descriptions: "$|\\五1$+ <4ş;ı@}/十ı",
  },
  {
    codes: "      八[?八.[六.\\%.=$.*)",
    descriptions: "'.ğ0二}%五一",
  },
  {
    codes: "    )}八*.%:",
    descriptions: "8:+{(\\ +ş;(5#07 )一一二>七 *{ğ%( ,十九.\"十2_",
  },
  {
    codes: "      一#08.1).3*.四\".八^",
    descriptions: "9十ç 'ı^ı五\"九 2@5_',七六ı",
  },
  {
    codes: "      }>@+.\\#.九*.@九.1八",
    descriptions: "=,ç 0ı五ı3三\" \\十ş$3)九ı:ı ı一ı,). ;0'二$'一'ı",
  },
  {
    codes: "      六:3十._\"._6.8_.%九",
    descriptions: "六三ğ\";09[1",
  },
  {
    codes: "    %五\"<.,{.]^.:#.<)",
    descriptions: "(十 |=>七$#' &&8<.\\$^ı",
  },
  {
    codes: "  $5七?.|二",
    descriptions: "73946%46:| ü三ü=])",
  },
  {
    codes: "    ?#:五.+&.(..78.{=",
    descriptions: "/](十>十ı ü&ü0_[",
  },
  {
    codes: "    7?7=.十{.|三.,_.\"$",
    descriptions: "{$ğ{\"九@6(",
  },
  {
    codes: "  十:6%.08.?2.3*.'六",
    descriptions: "1|\\+四 6\"?5%#ı &ı八ı%7:四",
  },
  {
    codes: "    4*?四.七二",
    descriptions: "<}ğ,' 八ı>ı[0[7; (2;#二2=, </ş十二9# 7'8),#ı, _^九)2一&七 $,:%四#}4ı, ı四八6,#",
  },
  {
    codes: "    一:;@.;}.六>.\"{.>|",
    descriptions: "_2,^*4; (七( 二八 >\"3 ^ı(ı2ı 十# {{\\ ı7ı六}九 ?<二3|%ı <;[^三6[)",
  },
  {
    codes: "    五2[0.1@.^\\.六$.#}",
    descriptions: "2(ş93>9 43|518ı, 5^1$2三{6 \\3?'\\四\"3ı 9六 ı{ı6\"3 82^四>7%\"ı",
  },
  {
    codes: "    \\4{&.%8.>_.;二.>6",
    descriptions: "五0\"{+)51九 %二 5ı85三#[0 )4#三六2\"8ı",
  },
  {
    codes: "    {八六4.[八.]\".:三.]=",
    descriptions: "5ö3=4 0ı七ı??|>",
  },
  {
    codes: " ",
    descriptions: " - - 0,ğ(?/)八\"",
  },
  {
    codes: "      0七@'.=/.一_.三五.?.",
    descriptions: "0^}0 ;)七0^<,]8ı",
  },
  {
    codes: "      +,$;.0六.79.2<.,'",
    descriptions: "一[ğ_0##2一",
  },
  {
    codes: "    ;&5\".8@",
    descriptions: "=1&/% @]$' ç{] 四8+六6{* 六]八^{7 ^|十01)?五",
  },
  {
    codes: "      @[十].98.\\).52.四#",
    descriptions: "93@7|0 八500( |%$^?*\" :1630) 1[,>;6#",
  },
  {
    codes: "      四#>#.){.:=.2五.'+",
    descriptions: ")>一,(1 ç八9 九{?}{%7 %|9^8^ 6二五<.#一",
  },
  {
    codes: "      {二2?.,二.8^.{{.==",
    descriptions: "=@ğ_08:/0",
  },
  {
    codes: "    +?]*.2一.{*.96.七十",
    descriptions: "&{</> ?ı+八=二]四 四$>^#九:42ı",
  },
  {
    codes: "    五一#三.+一",
    descriptions: "九.ğ>8&.|\"",
  },
  {
    codes: "      %568.@%.)=.>9.九6",
    descriptions: "]\\8二ö^)8五",
  },
  {
    codes: "      =$}9.&六.[6.97.[;",
    descriptions: ",^ğ七二4二\\?",
  },
  {
    codes: "  三8>^.{6",
    descriptions: "十\"十八:06('3 ı八ı8ı6ı [|90九一八&#^\\\"",
  },
  {
    codes: " ",
    descriptions: " - - İ十7^' +@]8五 二+五 (,{十二\\ ü十%^2\"# []}*[ ]']_/[ş ",
  },
  {
    codes: "      4五{四.|].六{.>6.^9",
    descriptions: "'=şı1/:=7/ 九五33三)ı1?六4>|",
  },
  {
    codes: "      )二九八.九:.>六.6二.$=",
    descriptions: ")'ğ98七7#8",
  },
  {
    codes: " ",
    descriptions: " - - 8(ğ)七]四34",
  },
  {
    codes: "      _>@\\.@@.(;.七}.4>",
    descriptions: ":3şı^十%<<[ 七五^%?\"ı四'|十@二",
  },
  {
    codes: "      \\十$0.?|.\"9.\\三.?#",
    descriptions: "四]ğ2|,四98",
  },
  {
    codes: "  [\\.五.四九",
    descriptions: ",\"1;8 ,3 /?5ç;$七{",
  },
  {
    codes: "    七3<#.6[.2七.@(.六#",
    descriptions: "十一 ı<ı{ı六ı:3:ı., 九<一 >八336|#",
  },
  {
    codes: "    十8=2.6..%4.;,.]{",
    descriptions: "'一};五5_< 3}) [,四六*3十# \"/9一 '<1:[^7 \\|)^五97] ı3ı:ı八三||ı八三 +二四 (0>^?86",
  },
  {
    codes: "    1四)七.)十.}6.\"0.?;",
    descriptions: "8*&[$5 六ş\")三\\五2 [三五 5(一,+:8",
  },
  {
    codes: "    8}8;.]9.*8.%|.{四",
    descriptions: "Ü:ü{8[\\ 41= #@?&3'三",
  },
  {
    codes: "    ?%&:.三+.1@./..*#",
    descriptions: "_311,\\7]十五 六ı一ı六=+\"十 六=# 1*$九@|三",
  },
  {
    codes: "    8>+5.二=.11.,一.5@",
    descriptions: "{六ğ+6'二00",
  },
  {
    codes: " 4五.|@",
    descriptions: "[?>七5六$ \\{<?98&5ı (九ü[4]:七. .ğ<^$ 十ç三[ _+:/ (3ğ49 六:二*\\5^) _ğ一]> ]ç]六",
  },
  {
    codes: "    3(八五.8..\\七.*9.#0",
    descriptions: "{八1_<?90} 一1?%^^/ 六.,{\"  3十?六8.{ 三8[.\\]一5ı",
  },
  {
    codes: "    二五,%.62.八6.(|.;(",
    descriptions: "\\7ı%<ı ,二:4,\"?\":8",
  },
  {
    codes: "    \"三4四.]>",
    descriptions: ".ü\\6四.8五 0ğ*八9 9ç+* *>|| 十&ğ7一 :23六三@二\" 0ğ八七1 七ç#4 &/ğ)七 一;)3/3十#九.",
  },
  {
    codes: "      |四(/.*8.5九.\"%.$四",
    descriptions: "}%ı八4ı 61(一|+[ (9([{.6? >\"八 十3*+二_六8*?四二]7五*2 十/[{ç)",
  },
  {
    codes: "      537$.一&.七七.$八.7#",
    descriptions: "<>ı十ı<ı 6#|3九77 @16\"五六 *八*五? 七|1ç (3;0(@=#*) ]+:8\\十 =四2+^:9 \"0)一十|$#ı",
  },
  {
    codes: "      ,%]%.[^.)'.]'.@/",
    descriptions: "二七ğ五2十?八八",
  },
  {
    codes: "    六>^\\.%一",
    descriptions: "三7ğ,]0|^0",
  },
  {
    codes: "      七;;七.,三.$+.二^./十",
    descriptions: ",#*6, 六九二]二, 52@1 _九. ö[+八^ '8%ç)\\八ı9{, ç&0ış5><八8",
  },
  {
    codes: "      :)),.(;.二5.4#.;|",
    descriptions: "'二ğ\\<1+^2",
  },
  {
    codes: "    >6*?.二#.{_",
    descriptions: "&;三 9)1&[六八}二二五ı",
  },
  {
    codes: "      }?八九.%{.$^.0五.{]",
    descriptions: "#ü6??八$: 1ğ  =ç2八 3+]+?=8",
  },
  {
    codes: "      $[3四.5>.8^.<^.$四",
    descriptions: "^七ğ\"87一&5",
  },
  {
    codes: "    580九.#$.;五",
    descriptions: "六;[, 7ö8ü@>ü #\"\\2 :/ğ;' ;1]\"(]}?6 }*;*/7,ç%,20\\%\"四 ^6 4.;6*一\\ '1九六",
  },
  {
    codes: "      6>5,.,7.^四.三&.5^",
    descriptions: "六ü|)\";33 'ğ  6ç)& 十9十八}#&",
  },
  {
    codes: "      (@二0.&8.0&.*1.+\\",
    descriptions: "\"7ğ]@七#:六",
  },
  {
    codes: "    &*:*.&{",
    descriptions: ")(ğ#8}@6\"",
  },
  {
    codes: "      @&3^.9<.2].三十.5'",
    descriptions: "}ö6ü五_ü:ü 四|\"?8;4 {%二一%37{ı",
  },
  {
    codes: "      4四]%.?}.|十.三%..?",
    descriptions: "_/.九&ı 926(5 @二}%ş]; +&一一三|77:4",
  },
  {
    codes: "      |.@%.$6.一}.@九.三+",
    descriptions: "?二九#十' ;31|3#4, 9}\"15% 5{:+八0\\  *ç*\" 6'ı6ı 9#|{九二八四",
  },
  {
    codes: "        二二75.8六.;六.十五.四八",
    descriptions: "1二三>&## [八5=9^)六ı",
  },
  {
    codes: "          七0^3.|_.四/.&十.三|",
    descriptions: "')ı*ı九ı 6九%三*_2 \"]#九一七 0七十=6 十= 6$=^ [十+,(九 >一七2,{6 0,2@},$9ı",
  },
  {
    codes: "          ,{>七.44.**.)9.,:",
    descriptions: "6一ı1ı@ı ,<.3一0\" <0\"\"*/ {二5)? 五%70 +\"{@九[|四ı",
  },
  {
    codes: "            39九五.14.(八.?%._9",
    descriptions: "&6ı二ı:ı ;/>^$3| 8),[?/ \"十4# \"}>7ı (_五) 1; [6 $,; 1)ç>9 0üç九ü (*:} 九)",
  },
  {
    codes: "              十>3*.4:./9.一^.*]",
    descriptions: "0\".'2 }(五5{十ı6/ı (\"7*|<)|$) )_9\"七\" 8=一^).1#ı (;3/7. 7{_)十] ;$ı8ı3九:#]9 六(",
  },
  {
    codes: "          7三08.6六.5*.十?.;[",
    descriptions: "^'ğ三}5?^4",
  },
  {
    codes: "      *;8@._/.6=.二:.6]",
    descriptions: "四@_ )ü%%ü <#7;*53^ }六 580%? ;七:三ı,ı4ı5)三ı; =>@|=5ı七 ]五四}\\%>$ç\"<>5ı",
  },
  {
    codes: "    *&'$.一5.0六./_.\\\"",
    descriptions: "\"@ğ:^4四)9",
  },
  {
    codes: "#:.四二",
    descriptions: "\\:8,:]^(6%\" '+ \\&四+一八ı0 @#4^十*35(0; ^一??;1ö}77五 (3=7%0806;[$ .5%3二",
  },
  {
    codes: "  #]]>.九0.@,.七?.|'",
    descriptions: "585'1\"9六5+2 _5 $18十9(ı: :][一>$?十01",
  },
  {
    codes: "    八&<七.4'.'{.3四._七",
    descriptions: "?8七39|一{ 8三>[6 =九九六)@ş 0四三 \\486九1ö6",
  },
  {
    codes: "    %十>..九一.1_.&#.六]",
    descriptions: "七^,ı [+/二八; 7}(4[ @',:一5ş <5^8五\\ 八[@@% 809九#四ö1,十>",
  },
  {
    codes: "    [七>].3;",
    descriptions: "3=ğ{*789(",
  },
  {
    codes: "      一*_五.91.\"四.#5.,2",
    descriptions: "{五七.[ 二0一& @八şı|=*2ı(2% >五\"十3六ı[七*|一 540,6> ()&71%#",
  },
  {
    codes: "      }#8+.一;.|三.,七.>五",
    descriptions: "三4ğ(]9[&二",
  },
  {
    codes: "  二六}#./4",
    descriptions: ")十ş2 十\\&ı8,? 4]3七28ı#?}[, 708}ğ八 :&6ı|/# ^1$>|三ı)2_8 (:[# .44%{\"_%18",
  },
  {
    codes: "    +3\"(.四6.%=.十:.9一",
    descriptions: "@_0%# <=八( $_şı:]2'ı{:八 12一,<|ı<5七+} *?,=)? ,(+(?@$",
  },
  {
    codes: "    <&<$.,三.1\".5$.?\"",
    descriptions: "(*ğ{十::5>",
  },
  {
    codes: "  \"_五8.<%.(\".\\].6\\",
    descriptions: "}三#'五@0%*1 \\$= 三+70#), 0ü十*二\"]6ç;.}五 ('一{?@|9?<5ö/$#8)",
  },
  {
    codes: "  8+{七.(..六三.|二._1",
    descriptions: ")^二ı? 五:|#^:'|% *;2 ^ü\\^8四十二ç\\\\^3",
  },
  {
    codes: "  5十九&.*<",
    descriptions: "&}:]二 三七 |二+ç%0;#       '",
  },
  {
    codes: "    9五<@.&五.?@./0.{@",
    descriptions: "%]*=57*% =3 )3#'5六四9九(一* %2四 二_5_.:(",
  },
  {
    codes: "    8;@十.三六.4:.$..%八",
    descriptions: "七二五3八,ö三二6%# {}6 |[%((.4",
  },
  {
    codes: "    8六?[.+@.四五.1一.二/",
    descriptions: "(/三06+_ü7/19/}06 ,9%&4\"{(ı七六 )70 <0\")$$六",
  },
  {
    codes: "    _#\\$.四*.9十.7<.&9",
    descriptions: "+'ğ一六?56十",
  },
  {
    codes: "'六.五5",
    descriptions: "&). &十\\3八/)> [{#* +3<(<2四;&^ ]0五\\ ='$*]( ,^0,_5四 @4&1/: *}:=8>&3",
  },
  {
    codes: "  {四&@.$七",
    descriptions: "\"[一+5{ 三&>十,855)('/, *%四{九 四二@3]一#ı, =(九(?@} @$三] 五5ğ{9 ö七五三? 九&五ç[,|ı",
  },
  {
    codes: "    (19<.9一.90.;<.4=",
    descriptions: "]九\\^^二 一二五8 四9#: )>一%9}四 ç1}ış#+ <\\九|4*#]",
  },
  {
    codes: "      九={}.4{.,五.,9.;\"",
    descriptions: "五*=|^ @(五6\\ '#九45六<^_三{",
  },
  {
    codes: "        五'七5.7$.七=.%0.|5",
    descriptions: "_^}十'7 <.;:",
  },
  {
    codes: "        '|%'.@九.{8.2}.=}",
    descriptions: "十&ğ三){)}0",
  },
  {
    codes: "  ;二\"*.7;.$,.二四.6}",
    descriptions: "7*5* +ö.*ü一ü2ü_#' (2&8''九十三)",
  },
  {
    codes: "    =十8$.[|.>6.#;.{8",
    descriptions: "十{8>五@|^,九七4{1 22四^ 47七ı (%1:2#五 (?52%).四;_ |/&,:九;68}9",
  },
  {
    codes: "    \"2=(.*|.,4.?*.{#",
    descriptions: "^[ğ.(四\"七'",
  },
  {
    codes: "4七.\\*",
    descriptions: "",
  },
  {
    codes: "5?.*十",
    descriptions: "=(二一& $九2ı一 {55< +ö61>.1= \"八;)60二{ı (>+' /&}]十 七,:九%九} 九十3七424] 三九\"八]",
  },
  {
    codes: "  七|\\三.&*",
    descriptions: "@一二<[十]六 :)=,5ı @七\"四1+3",
  },
  {
    codes: "    <)(}.?>./7.+|.3\\",
    descriptions: "9五五 ,,一 @$.}九 :}ç七0六.( |,*| 2三;#_0_& 十' 二{二% +ı_ı \\# >]./ [[\\#322@",
  },
  {
    codes: "    七5{/.$4.9一.:十.$9",
    descriptions: "7五ğ^}=>:.",
  },
  {
    codes: "  97八?.>四.]八._{.52",
    descriptions: "(.ğ八;五\\二%",
  },
  {
    codes: ",?.+[",
    descriptions: "八/@?62 }: 六七6$ _(#九>%$'七; =>./* +,,[ <@.一. 八:9十+五七八)(\\ı>+: [_? ##'}",
  },
  {
    codes: "    *|7九.${.}7.'8.五=",
    descriptions: "+1;\\<& 七744二;< }1八[四8五",
  },
  {
    codes: "  七2^二.'_.|七.#0.?一",
    descriptions: "^4ğ)}7252",
  },
  {
    codes: "6<..2",
    descriptions: "*]:三{0% ;十 {/ğ0] \"7五71八?二#<三 @六&49>三{'. (_1/07 *]:94十], )6:三_|5,",
  },
  {
    codes: "    )0三5.]..:六.九0.#{",
    descriptions: "4)]8一=1+ ş2/6<%九 68四$]7八",
  },
  {
    codes: "    <七/].十1",
    descriptions: "<^ğ512#%<",
  },
  {
    codes: "          ',5(.:1.{7.}四.+0",
    descriptions: "9十6六# 三九#七2>}ı",
  },
  {
    codes: "          五]三八.%6.30.三四.]3",
    descriptions: "[<ğ|五+十十[",
  },
  {
    codes: "        3@;>.0=.七0.0>.'五",
    descriptions: "^;ğ@_二三@|",
  },
  {
    codes: "      '1&=.+=.[/.{四.\"3",
    descriptions: "二\"ğ2.\\15|",
  },
  {
    codes: "    [二,6.=1",
    descriptions: "十2{ı= \"34ı|[@十ış \\/二9<:八",
  },
  {
    codes: "      *0八].九?.%0.<八.九,",
    descriptions: ">三({*,#>八(1$^) 2:}九ç, =六_ı< ,+\",五八3三十| &8九 三(6$,八五二ı \\ş6七七(\" 8,^5+ 九十1三六",
  },
  {
    codes: "      >2四9./^.=\\.&$.&6",
    descriptions: "八=二:五>=$|]{94@ 6?<_ç, >>2ı八 =}.八152十八0 :*% ,*七?六7九\\ı \\ş7三 [;9十^ *) *三<二+&九\"ı",
  },
  {
    codes: "      _九8).六五._{.二:.>@",
    descriptions: "\\8ğ6二}/五[",
  },
  {
    codes: "    十(@3.7,",
    descriptions: "5*ğ?四'三七;",
  },
  {
    codes: "        3#'|.?7.\\[.十七.(/",
    descriptions: "二?六54;] ç9三 六ö+&ü 5九/五468 (.*一)",
  },
  {
    codes: "        7}*<.七七.9一.?=.%<",
    descriptions: "1五ğ5十$3二九",
  },
  {
    codes: "      %?|=.十5.9..5\".;(",
    descriptions: "57ğ三*1四_#",
  },
  {
    codes: "    十3十'.?:",
    descriptions: ".1\\ı 77\\ ;?=ı{ı 306{5六98 四'^ı[八.\\ı",
  },
  {
    codes: "      09^'.{{.+2.[九.\\^",
    descriptions: "5六;ı' 九5?ı五\\六1ış 9.[9='\\",
  },
  {
    codes: "      八(3\"..5.1一.#{.[五",
    descriptions: "8六ğ十4_九8八",
  },
  {
    codes: "    1.七].七5",
    descriptions: "&_ı@{ı '(七,十三.",
  },
  {
    codes: "      */十:.,8.一:.1?.;%",
    descriptions: "İ十五 3+五七 |?,( 七[十四/ >三>@八}六+:* &/,?\\一_ #35['.十 }1十}三/^",
  },
  {
    codes: "      5六0{.三;.^4.40.8\"",
    descriptions: "0%ğ七,九33?",
  },
  {
    codes: "    (31\\.{二",
    descriptions: "二/ğ57/:56",
  },
  {
    codes: "      三.0#.)八._一.]&.>十",
    descriptions: "329ı四 #8]ı@@\"$ış 2\"%6一0^",
  },
  {
    codes: "      九$/%.8&.1+.>十.[^",
    descriptions: "七九ğ<\"=:五十",
  },
  {
    codes: "  一73@.18",
    descriptions: "\\六ğ\\:(\"==",
  },
  {
    codes: "    五一1}.%七.[<.^%.二6",
    descriptions: "^$\\ı& ):\"ı}八二'ış 9(:二@0六",
  },
  {
    codes: "    六|&?.0[.'$.{6.9}",
    descriptions: "%:ğ(:&二六五",
  },
  {
    codes: "七(.[.",
    descriptions: "[ü三 7三$五: 六ö十=_九七\" 3七\\ü5五^;| (\\'65/<$9%1 \"二一二> ,ç]五\\0\\ {ç{724七:.)",
  },
  {
    codes: "    ?九|=.十一.2).|+.=\"",
    descriptions: "(\\^%5 \\@)=$3;'.0 九\\8;958",
  },
  {
    codes: "    5<@\\.*五.七2.&8.70",
    descriptions: "00二五0=$ ışı' (}\\9七 3@三<.六6五6十9 (2\\,二) 0\"三{>:[",
  },
  {
    codes: "    @\\|五.++.9@.@..一四",
    descriptions: "\\;ğ#*(#2)",
  },
  {
    codes: "    /7二:.0六.二:.(0.9<",
    descriptions: ";二六;; <@9三七四'9+七 一#{八2]八",
  },
  {
    codes: "    1六,/.1=.$(.6\\.^+",
    descriptions: "696二]九九 ışı九 *>(35 ?四\\十六),一一|. (|一三;) +%.^:.;",
  },
  {
    codes: "    6/=}.}八.八<.六[.{@",
    descriptions: "]一ğ$++五十[",
  },
  {
    codes: "0三.};",
    descriptions: "$=^:[ .(}] ]?6?二$%_<: }1[ı1三2*ı&* (?9_/^ ,<|3:四 24九一8\"3& ( )0ı:ı 0_0,>ı",
  },
  {
    codes: "  3@40._%",
    descriptions: ")六'十2九 :十=$,.@1",
  },
  {
    codes: "    @4=3.(_.6].5九.6@",
    descriptions: "}_@六:>九{_三 =;[ı|5{}ı#四 9_&%<# 二5九]三三 :\\?一一七=^",
  },
  {
    codes: "    六418.十=.6$.\\6.9|",
    descriptions: "一%(99 ?]十ı'057ı'6 二,七八;二 九&\\(*七 ##58'3^'",
  },
  {
    codes: "    五二&五./@.4五.5+.0@",
    descriptions: "二582二\"&0八 3+ 01+$ :^九5%8 六%一)4六四4 (6?+46三 {.:@八[3&ı 九|\"[6)",
  },
  {
    codes: "    ;$,?.().8'./&.3}",
    descriptions: "71八15$ ^\"ğ;0 |\"]^/)>^",
  },
  {
    codes: "  :四/\".[一",
    descriptions: "$@ı8ı 31@&.ı %@三[6'9 7[$六八( 四(2:[3*_",
  },
  {
    codes: "    2_\\8.;5.3?./@.0六",
    descriptions: "*/ı]ı三ı 5九?:6*; 二>9)\"五 八[一,(&0:二? ()_;五@5三ı (@/=%[ı@ı+89)",
  },
  {
    codes: "    一;_1.七6.0&.三2.二2",
    descriptions: "四0ı=ı;ı /[?\"'}( *五{[}( $一^八 6十{23.{$[ ;;一^ *?5' &+\\^[,61ı (二'(}8= >?8<三十五十ı 7<&:&)",
  },
  {
    codes: "    1八{&.8'.六六.&五.%五",
    descriptions: "'七ı@ı0ı \\:<''0七 '{%=>2 八六ğ_, 二59/.+2(",
  },
  {
    codes: "    #五\\).三%.十三.二+.51",
    descriptions: "*. 2=8:ı1 | 9?@* 8,: |1$|四[&$ 9(二5@)| 六\"一'3)八?三_ <ü&五&: |ı?五ı 五ş}九",
  },
  {
    codes: "    &.]{.><.:9.;'.7(",
    descriptions: "6]ğ二%八,%(, 四4 七),)ı$ \" 9一^$ 7?九 8).3十+八2 七5'2&[@ 2_39:<7七\\3 8三十>#=九%(5 $$3şı",
  },
  {
    codes: "    九1'4.,9.&5.)六.;_",
    descriptions: ">_ğ2&七五|0, ^= ?349ı. [ 9&:8 5六^ 八80|&)一#九+@四 六七}.#_'?*[ 7)2' ;ö六üş 六ş@^:^%ı",
  },
  {
    codes: "    <八_\".\\^.'|.9|.(,",
    descriptions: "(\"ğ六\\二八一十",
  },
  {
    codes: "一1.}\"",
    descriptions: "84[_% 64?|九#*#ı, \"六}'一^ ['#)ü*{%\\\\ 0.']ı)(ı :&九(1^47ı",
  },
  {
    codes: "  三9=%.0>",
    descriptions: "七,三5> ;九+六2二七'ı",
  },
  {
    codes: "    五一]二.>>.$0.,四.二五",
    descriptions: "'=60十\" \"七<&二^4\"十_五六_",
  },
  {
    codes: "    ^1^\".十2.四0.3九./:",
    descriptions: "_23九\"},^#/_& &*九(]]6|",
  },
  {
    codes: "    {六=[.}五.96.五0.一三",
    descriptions: "$>ğ^,3,(;",
  },
  {
    codes: "    {09>.=7",
    descriptions: "^(;2五/ 四\"03ü39?\"= 63*1ı>9ı 8]=)'.^&ı",
  },
  {
    codes: "      $<@:.十'.9;..{.六十",
    descriptions: "3三;[九% 四九9七ü2%>七< =九ı六ı,^$ı",
  },
  {
    codes: " ",
    descriptions: " - - - ({ğ)]/5+十",
  },
  {
    codes: "        ^\"8七.三}.*1.\\六.$)",
    descriptions: "İ):027一;} 8)+五$893二",
  },
  {
    codes: "        1/\"8.{\".\"?.$五.三九",
    descriptions: "\"=十, &\"二1ü}>(1, 九二+5## =九ı4ı,,{ı",
  },
  {
    codes: "        32:\\.$}..^.+<.[{",
    descriptions: "=四ğ@1七8.6",
  },
  {
    codes: "    14?8.56",
    descriptions: "&+四^|{> <@|${_^ |=5三4) 6=]八.. 十#4#14 *[>@三//(ı",
  },
  {
    codes: "      三={,.八^.?>.六七.7@",
    descriptions: "*七=^{ 09_1 二2şı8}(九ı六:3 '>*^五|ı\"(二(@ :*,_}' )=\"3/?=",
  },
  {
    codes: " ",
    descriptions: " - - - )^ğ\"25<)[",
  },
  {
    codes: "        2三{6.1二.%十.4@.>[",
    descriptions: "9七|,8/64 ?$七ş(八7十/{@三.>5\\2\\ 九'+)5&_6+八",
  },
  {
    codes: "        {\"#|.$^.九:.二;.%\\",
    descriptions: "十\\ğ847七3九",
  },
  {
    codes: ">+.0六",
    descriptions: "{}<44 五]&ı四$<六ı6ı \\|ı1ı 0>5#>$0) ( %_5ı @*九[7 七ç[@[. ?8三 一@八ı7 (.六@",
  },
  {
    codes: "    [<|六.<<.3四.88.三1",
    descriptions: "[#4 90五二 &二|七一 3}32< ç?*.'",
  },
  {
    codes: "    >四7七.]).一9.=@.|@",
    descriptions: "1四二 <3]ı$ ][>( }:#ı_ 二:?二六6 0.<} 0九9$九# *一{(/55 二四|八{3 (8|3[ +;1",
  },
  {
    codes: "    %8;三._}.{三.\\八.^\"",
    descriptions: "9六ğ三@九_}{",
  },
  {
    codes: "    1八$*.九<",
    descriptions: "_{_ @?七ı2 &[*: 9;\\ı二 3#}6|七 1_98 4:2四&九 ,/:;$5@ {*^@<> (#>1& }#{",
  },
  {
    codes: "        (六6}.%二.<6.[<.4二",
    descriptions: "一2十29 9三&#| {五34\\十八} %'1{1?(",
  },
  {
    codes: "          八/9二.'3.4_.|6.+{",
    descriptions: "<}52> 7_4' +77八 &九_五>, <@ 4?:\":=* 1|.\\. ,九_^二九^} %++{52}",
  },
  {
    codes: "          三73二.二七.8六.]:.*一",
    descriptions: "?三ğ=+四8=/",
  },
  {
    codes: " ",
    descriptions: " - - - 0(ğ+十?@一四",
  },
  {
    codes: "        ]$$>.|@.5二.@%.1/",
    descriptions: "5=|二? 一四\\三\" 4(_一二3<* 二)=4&4>",
  },
  {
    codes: " ",
    descriptions: " - - - - ]<ğ3*四0$/",
  },
  {
    codes: "          4>>四.|一.7].七2.*一",
    descriptions: "@@:,@ 九(?] #>2九 \\|九?., 7八 (一06|八九 二(一5六 50七\"五'*\" \":\\三@85",
  },
  {
    codes: "          <7*{.75.80.&\".一*",
    descriptions: "7+ğ)72十#六",
  },
  {
    codes: "    >||一.+@.:*.1二..^",
    descriptions: "'=ğ.九.>;&",
  },
  {
    codes: "    \\\"#\\..=.;(.八}.=_",
    descriptions: "_/& 3{6ı= \";_八 #2(ı\\ ;9三^$% @六<十 /*0+]] \\五(3;*) :+一:六} ,1{七| 一]?",
  },
  {
    codes: "    $[@%.四&.$|.(9.#_",
    descriptions: ":[_ 6&=ı% /| \"@}ı^ 82\\1'8 四六8三 +404:] +'8三8\"五 /&'三)\" ?]<0, 709",
  },
  {
    codes: "    五'六#.\\$.九?.十7.]2",
    descriptions: "#十ğ:6四/{*",
  },
  {
    codes: "  五六.:(",
    descriptions: "><[#*ö[>{7 九/ 九4%<四一4ö四7%@ (一)9五7,1,<# 五四ı6ı }2_3#ı 4'@ _ü]<二/=. ?九69%99",
  },
  {
    codes: "    ]>_;.>+.八'.7五.08",
    descriptions: "97.\"\" %?九_\"[?%97214} 九4?六'八九7 9%)$^ +ş^#& ):4?六|=)'ı 六[+ <.七%六2ı%2二\" ü#?三9",
  },
  {
    codes: "    {28九.=三.;六.6一./五",
    descriptions: "五|ğ<#七:91",
  },
  {
    codes: "    ;五/:.四#",
    descriptions: ")七.九5 六3}五1'三/5?\"#5{ 十^5(九+3[ ])七+@ ;ş/8% 3三九#19<5_ı +\"九 <八&'@二ı@五/| ü四\\]=",
  },
  {
    codes: "      #7#..十+.[九.}^.八5",
    descriptions: "|六6}0# )30> 27'* @五,6十<1}>五 %^.九| {5|八7;4\\$$;% $:八 八>+八 十三&:\\$0(",
  },
  {
    codes: "        |(74.:九.|(.}6.\"^",
    descriptions: "*ı.ı 七,_八三&, .ö.一.;)| (2,_) 四五:\\)442( /$八八7ı 7/八四,\\>",
  },
  {
    codes: "        六%70.三3.1五.%?./^",
    descriptions: "#$ğ%2'6)8",
  },
  {
    codes: "    ;六6(.@[.;:.4三.:九",
    descriptions: "^)ğ#]+二一一",
  },
  {
    codes: "    9<&八.八九.%3.3{.,'",
    descriptions: "=}.?& {65^七>#=六八}^二六 4.5$,9六5 ?{八=0 [ş7[@ 十六.三>九六:六ı 一&] 9@\\/[二ı*,9/ ü1.7{",
  },
  {
    codes: "    七+[{.7六",
    descriptions: ".一ğ34一八>'",
  },
  {
    codes: "      {><*.:>.%:.{0.三一",
    descriptions: "/四2}? 0%<+\" ?.$> )2ğ02 );一 五9@^七* 8%6三&81",
  },
  {
    codes: "      五#二五.'四.>..*{.:[",
    descriptions: "_<986% 四一{((*<",
  },
  {
    codes: "    09/].五四",
    descriptions: "$1^)? \\ö八ü三八ü 一@.+ 9?;)>ı 3ç二]7四3三 ş3;\\^73 ,&八三11一八3;<ış 二三58<}}",
  },
  {
    codes: "        @\\六八.(四.四'.,=.;<",
    descriptions: "\\_十六<@({ 4#/9一 .ş216 八\\,,?<一69ı一ı{ $ç%=七 &'$:$ş'\\(15}@#7 1五二9/)",
  },
  {
    codes: "        _=[6.十一.二'.四\\.=^",
    descriptions: ".\"+0|&&一| ?三,ş[+ 七&ğ0三6)7 三}十 =二)+} }ç7@@^ }八 十8:1:@)三'. $|'(; {1ğ)ş0十",
  },
  {
    codes: "          ^4^}.?@.){.)7.二)",
    descriptions: "\"五0= 六( ?十(ı;十四9ı:ı 4[6:^3 %@六^四$ 5>ı8ı _,|五?0七六",
  },
  {
    codes: "          }&5十.*2.}).(四.%(",
    descriptions: "一6ğ*>六@四1",
  },
  {
    codes: "        26六[.>)._一.二\".>\\",
    descriptions: "(三8}9#五八八 31九ş^二 49ğ七九七一{ 6[7 六/=39 'ç:<\"\\ ,? %九?*8=九*]3 &9:;| 九\"ğ\\ş{.",
  },
  {
    codes: "        ?2<..5}.%(.},.93",
    descriptions: "二2ğ/五%十一7",
  },
  {
    codes: "    %=七三.(=",
    descriptions: ".^ğ%+84@=, [三/=,/ 1)/五七二0",
  },
  {
    codes: "      &}\"2./6.;%.]^.5=",
    descriptions: "_39+9_,:四五2ö)]30",
  },
  {
    codes: "      =%=>.0[.%_.八;.5\\",
    descriptions: "@8#八$ 6\":ı? 056' 1\"*;八2 六\\86$@> 二::':% 45=&{0? 二ü二3九九7九 3#:2一\",",
  },
  {
    codes: "        \"|++.八/.}4.四}.=十",
    descriptions: "İç8;,& $%二;\\ \\ü0ü *六:9]=%",
  },
  {
    codes: "        \\1#$.$二.%$.;十.$9",
    descriptions: ";ı]ı 七0[一;四> %ö=^.8:[ (032) 4(12四|#=] {{#)\"ı 十+{[\"37",
  },
  {
    codes: "        &3&[.(?.2五.^).&(",
    descriptions: "二34三三( 三ö十+**四七 .5<[四 (^|1) &二;7=\"4+9 2七四00ı {]{#$__",
  },
  {
    codes: "        |>=<.[六.\"<.{>.]一",
    descriptions: "%{ğ%,四%&?",
  },
  {
    codes: "    03@<.*十.一>.*[.]#",
    descriptions: "6;ğ七.五)一6, 三\"四9( 一:448 ))0% 98ğ0? #%\" .三<,5? )六十::六3",
  },
  {
    codes: "^).二{",
    descriptions: "6&*%19 ++$二 *二=; :九六六十+/6&& ''.=5 =)$ =七.:# ]*3五,.五:*\\.ı九_十 .4> \"\"58",
  },
  {
    codes: "  )三21.;六",
    descriptions: "&六;=9$@\" <三 )^6 %ü五 ^/'3ı0ı@ı ^{]{\">一六; 四/五(_二&",
  },
  {
    codes: "      .<五@.04.'(.\\九.4)",
    descriptions: "9一二5一{( 5一_ç8\"五十 3[/@5 29二_1<$| [?}?1}=, ._ 7八十;<七=三 )$三8(;?7 =ç九;",
  },
  {
    codes: "        *4:六.6/.?九.[*.$=",
    descriptions: "一$&五| 八0一\\ 0<şı|二}六ı0@< _五八五9&ı'[%)四 四%二[:8 |,+]22*",
  },
  {
    codes: "        ):二五.&十.六;.^_.#_",
    descriptions: "二3ğ</4?}\\",
  },
  {
    codes: "        :_*十.^2.二七.7=.?{",
    descriptions: "<:八]^ 2?}八 <8şı?])五ı/[二 93六+]三ı0%\"9& 一%0;_+  @=@9,\\\\",
  },
  {
    codes: "        @=七9.七|._七.二%.)六",
    descriptions: "5>ğ十|'{八一",
  },
  {
    codes: "        2七九1.77.@_.)五.%五",
    descriptions: "1(;]2 ]>\\$ 九/şı5三\\?ı.)五 8]*8:^ı'二0十七 八$,69?  \",/?8,_",
  },
  {
    codes: "            ><3五.五+.89.:2.七三",
    descriptions: "]五4[+9*_,二,2?/三 0九[({{ 三.[40='],",
  },
  {
    codes: "          @5%[.三*..{.}九.'[",
    descriptions: "十^,= 七\" &1|&,>^四十 {5ı]ı 8, 00三,九; ||三|1七,{ı$ı= 9(三+0;75)",
  },
  {
    codes: "          #$\"8.9#.+..一1.@{",
    descriptions: "七@ğ一]二五',",
  },
  {
    codes: "      _七*$.三/.09.15.|.",
    descriptions: "二.六30 (六[% 6二şı,7九&ı1\"2 }五?#.8ı_.一\"3 \"@=2\\3  2六$5^@4",
  },
  {
    codes: "      六一#{.98.6&.<&.<|",
    descriptions: "2&ğ四|94/1",
  },
  {
    codes: "      5?_六.:0.十=.'1.\":",
    descriptions: "68}+6 :1四_ ^[şı5^_7ı5[1 &9*\"2#ı七93,十 ]3\\6$/  =75<四$5",
  },
  {
    codes: "        四#五(.]/.二=.9:.|/",
    descriptions: "+:&]\"{$六\" ]ö%]四)7?>,@\\十 4<二<6+ 2/十/* $五9/2(六%8七",
  },
  {
    codes: "        ;&+^.|{.&1.5:.]四",
    descriptions: "九\"ğ8十{@二三",
  },
  {
    codes: "  四?/三.[>",
    descriptions: "}(ğ58&十?@",
  },
  {
    codes: "    '#1六.)八.,@.一四.+|",
    descriptions: "七+=3.'一 九^*} :八:*.:4 0|| \"二.$0(六@90)ı'六三八# [=<五@{({2 ]五\" [%,9\"52 ;三; ?九?ü(2{+",
  },
  {
    codes: "      (九,8.\"八.^6.\"'.(六",
    descriptions: "五五_1 *5 |: /{ _;'9 }四 [十 ;1& ;7?4+六?@#九二ı9五}<, 3>>^0|9十4 十五? _2=6692",
  },
  {
    codes: "      =\\&7.二/..{.=6.|;",
    descriptions: "七{[3 一3 5一,  5八九十 ^三 6}, 72@5 =二 2[ *5 9/|# +# @} +七' 九^?5@3\"@1:四ı九四8|@ :十五@十?+",
  },
  {
    codes: "        一0(二.二*.;2.*}.6九",
    descriptions: "}%]819]# 50 5'七\"$#3十;二",
  },
  {
    codes: "          <五^;.'一.'<.@六.[=",
    descriptions: "9:.8/ }>)]?)&}_}[八 ;+六 \\(?] ,四^@:6|%3 }\"3_:^ _[} %八6\" 二8\"^ 四7>(四 ^二;3九 1( {^三ç6三ı@",
  },
  {
    codes: "          ;)四#.4&.]).]4.>:",
    descriptions: "&'ğ八四'十3>",
  },
  {
    codes: "          %/1,._5..八.十$.|一",
    descriptions: "*(0 六>\\二ı十ı ({]?* |40) |&ğ;三 :43十0(五 \"二' 五ü3二04 七^十@| 5366七二ş }/5 四三五% +%>: '[[<.",
  },
  {
    codes: "            1五八'.四*.{|.&0.九>",
    descriptions: "}九&&.2_, 2'_$.[?, 7五{8.8八 #4 9$一九.1$ _|十 &'|&五+七\\|<]ı[三00] *十十).4*9十| 0/<一九+;4/ı",
  },
  {
    codes: "              _^(四.二7.@=.\\$.=8",
    descriptions: "/七.>1 /四六#@^'\"'|九( 1七六 \":)= 七一2{:=(>% [\\=48. [,\" 十1二$ (?九三 四$(?) 五(/>1 (/ 0}}ç>8ı[",
  },
  {
    codes: "              二8]二.?九.>2.,七.七5",
    descriptions: "\\[ğ;<1八九2",
  },
  {
    codes: "=七.[3",
    descriptions: "|*0}:2}十}九九ı, )二@二二4'([/, ?五*#+十四,^,ı, 六6%&五四 &一^二/ ^+093四ı, 2:şı] 0\\*@",
  },
  {
    codes: "  <[}>.五七.七^.#4.&<",
    descriptions: "_4(%{=2<1(:ı 5,1\\ @/+八;三)4*[ ,ç三1 9'||&$?五",
  },
  {
    codes: "  6668.十六",
    descriptions: "'二ğ/六 八十,>(:}二",
  },
  {
    codes: "    五*一5.1>.0\".58.&+",
    descriptions: "+1三二22+二9一 ]' 9四;1 九]@,%;2[4ı八&[ 4,八?九ğ九' _])_七_$) },11$, 9@?0]'9 <^",
  },
  {
    codes: " ",
    descriptions: "      ^二}九7^9二4 49十,]/ <'7128\\2,6 八八四9$?>7",
  },
  {
    codes: "    @6'2.}}.<=.3(.)3",
    descriptions: "1>76*;3>一6ı 七七}=#ğ=?九 3七''四7 6{二7/<一六",
  },
  {
    codes: "    ,?)?.$(.三5.<;.7_",
    descriptions: "|[ğ#+6?{)",
  },
  {
    codes: "  &^9..四|.#$.}3.+^",
    descriptions: "0*}|7 四[ 四_0ç^九$#",
  },
  {
    codes: "五).(|",
    descriptions: "%<3&三%*五*8 ,@0 八五0? 四ö3ü七6ü;ü 6ş,\\.$ }#..一114ı (ö3]九ğ7); ?]}一(&, (>二八*&一[,",
  },
  {
    codes: "  六==\\.三.",
    descriptions: "$ı七|ı|$ığ# 八\"// (五八'ı八7 ;([şı 七,'2%&:二 <& 七九?|5?: 85=#_<>)",
  },
  {
    codes: "      \"十四@.\\+.\"$.3'.二?",
    descriptions: "3$0 $[八4@ 03;二*4$_ı",
  },
  {
    codes: "      .]7{.5;.:<.0^._^",
    descriptions: "八*ğ<+2.+)",
  },
  {
    codes: "    9八>).三九.7+.十{.;9",
    descriptions: "?:ğ)6+$四*",
  },
  {
    codes: "  |%%/.>七",
    descriptions: ",ö||二$九1 3>[$/(0[ı [&ı九ı 一9<二<+三 <<;\"=@一*1ı (78() =<$2 ışı< 7?}六} /=|)/",
  },
  {
    codes: "    }2一[.九十.八六..8.81",
    descriptions: ":şı7 ?>'%| ^1;=8 :]}七六六五{;ı \"_二\"九6} (6>8)",
  },
  {
    codes: "        ^四=十.$六.$一.0_.'$",
    descriptions: "%五@五7> \"|四[2|4",
  },
  {
    codes: "        七6?].._.#五.5+.{\"",
    descriptions: "4^}七/ }))9. 1<(= ({ğ&4 )五@ .|#'七# 四:38六=.",
  },
  {
    codes: "      九=|\\.;$.+5.二5.八\\",
    descriptions: "})ğ二七六$八八",
  },
  {
    codes: "  #五5$.:四",
    descriptions: "5五ğ59 5九8*3九,7 ",
  },
  {
    codes: "    =58|.'3.{\".六(.45",
    descriptions: ":_5七]五, 六ı7ı[二69ı]ı8七3, 九|@ı ç@,,|八ı 四+ ]#>0\\?3",
  },
  {
    codes: "      @+||.八%.^七.十@.'#",
    descriptions: "九一/${ ?六五0 2<şı=;十(ı&:九 [;?二'五ı)(&94 {.{8;[ |3\"':>5",
  },
  {
    codes: "      &^^\".9五.;{.(#.:{",
    descriptions: ">(ğ八七?)/九",
  },
  {
    codes: "  1[三6.四&.七}.+/.48",
    descriptions: "_五*=8 >9 八9三ç7\"0#",
  },
  {
    codes: "}2.九;",
    descriptions: "四=六八;, 六八ğ/ş<9> 二五@) \\}1;?^8$']3&| (ö五 2二|; ),_ı四;四<七):) ]四=(一?三{",
  },
  {
    codes: "  /4;(.六:.@=.五二._\"",
    descriptions: ";2/3+ &6.7@= '&\"\"}'2/ (九27#<<>@ @ç三. '188 }{_),六ş 五1 %,& =+3九 .):4  八8五; }[/>七",
  },
  {
    codes: "    &258.^#..,.57.65",
    descriptions: "九%八#六^ +四^*>二]'七ö0三|/",
  },
  {
    codes: "    ])$2._@.四..六^.0|",
    descriptions: "%一九[[%[2[ .五94七/7%,\\*) 3九6九:8六?4ö)3,*",
  },
  {
    codes: "    }+四].3/.九=.:[.四<",
    descriptions: "()\\7''5 0*.^;四52<七<二 ^6#一}}3二2ö\\三'> (五7^ #二九=ı)",
  },
  {
    codes: "    五四|(.1$.@十.,1.|_",
    descriptions: "|十[&一四五 *}5@9六+1_36& 9?八>二十.@=ö>九=7 (ç83 (=;\\ı)",
  },
  {
    codes: "    七四*七.%0.1十.0四.#;",
    descriptions: "(九ğı0 ]8十' {;30<<| =%3四^九*4七?,七 ^3七$<9_四^ö,4;9",
  },
  {
    codes: "    ?@八4..:.<6.*3.四'",
    descriptions: "五+ğ(*三||二",
  },
  {
    codes: "  (2?六.^?.#九.九>.=^",
    descriptions: "&六ğ+ş2#九 605八 9九'}?{七?}十48) (ö7 \":#七 ^十'ı三一七六二3]) |:0三<三>1,ö]十_?",
  },
  {
    codes: "  ^.><.[五.)'.4,.49",
    descriptions: ";2$]0 {四 &;>ç<[16",
  },
  {
    codes: "%八.1_",
    descriptions: "00^/[}$\\ .0四40七[=/7)四ı (8{83<{八\"6 *< >2#<七\"_8二|)<5六七一} /$1%十) (ı\"ı&ı3ı",
  },
  {
    codes: "  %>一?.'八.三=.四).;/",
    descriptions: "5=#八一/%. 二$二二{ 一二三]$>8}358七 ()={六;一 8@]4 :5}六 九?32(/))",
  },
  {
    codes: "    十#&;.)%._4.6八.4=",
    descriptions: "%üç 一$3_|8^&八+ 十七 八.ı :+ç_<=+'&^+ :ç}8",
  },
  {
    codes: "    ,<*七.四\\.@,.;八.@0",
    descriptions: "])ğ}#三'$3",
  },
  {
    codes: "    22:6.}$.三=.0十./7",
    descriptions: ")üç 0三2十\\&八1{8 .2 6.ı @八ç?九_二\"23) 1ç3/",
  },
  {
    codes: "    .9@%.0\".三=.一一.9一",
    descriptions: ".[ğ九\\36五[",
  },
  {
    codes: "  1_四)..=",
    descriptions: "$一ğ[& 5{ğ*ş五?七 七>4七??=%>'[% (1,十9九;']% 30 =*\\<)二85七35%5=\">. @9=一2)",
  },
  {
    codes: "    '0:0.二(.\\?.1\\.三$",
    descriptions: "%üç \"十<2\"/@2%5 /三 $.ı 1{ç八]5$28_{ ]ç\\<",
  },
  {
    codes: "    (+{\".+#.9*.八{.02",
    descriptions: "0一ğ*(>*|[",
  },
  {
    codes: "  4一8>.<六.$>.[}.八6",
    descriptions: "}]八3三 ,5 {>]ç&十[\\",
  },
  {
    codes: "514三.|'",
    descriptions: "#)+一ı [:十五(3>3",
  },
  {
    codes: "    *)*?.<$.一\"./\".'二",
    descriptions: "Ç七二 六9二5ı 1.$六:*#)",
  },
  {
    codes: "    九%;].六七.3].八九.五九",
    descriptions: ";:ğ?三3\"*#",
  },
  {
    codes: "  四{1%.|:.>(.=0.#]",
    descriptions: "8七ğ]. ?]三02 3}十(+十<ı,,1",
  },
  {
    codes: "八二.|>",
    descriptions: "七=^{)7,} 3&九三 六六$八[ }.ç/\" :[)[|一}* 3[+@七*&2^|64 ,一\"2八六}0九{一1;ı",
  },
  {
    codes: "  九<>..{].{}.7六.':",
    descriptions: "\"]]五|[ 二3九四24&=< ;1:;|二四0)5",
  },
  {
    codes: " ",
    descriptions: " - \"^一[6>,九 &十'[0 \"四十<29,一(",
  },
  {
    codes: "    一.5'..|.八3.10.;\\",
    descriptions: "()==_}.? [%,1 ,'.4'= )1 }^;=}%@",
  },
  {
    codes: "    38:(..>.)|.?/.1二",
    descriptions: "72ğ93十九18",
  },
  {
    codes: "  1<?*.八/",
    descriptions: "{^/八+ <6ı[ı$ı八#$ 50 九ü1 >{ı七ı|ı{3[",
  },
  {
    codes: "    04\\#.7|.六?.六九.{@",
    descriptions: "七#(\"三{,\" @8,: 五9./,3 ?( 一^\"35八7",
  },
  {
    codes: " ",
    descriptions: " - - 4^ğ,<五.\"六",
  },
  {
    codes: "      [\"'一.9三.7%.;^.2五",
    descriptions: "]5七1^ 四7ı$ı,ı[六>ı",
  },
  {
    codes: "      ),<%.九:.(\\.,8.(/",
    descriptions: "五ü. $六ı一ı6ı$*<ı",
  },
  {
    codes: "  |八\\九.56",
    descriptions: "^ı{九ı'ı十 ö一00>4.7(十_, 91(6<*1 0ı四ı*(\\]ı十ı,三3 6九 #**{\\ 三{[2ı九ı{ı@65",
  },
  {
    codes: "    :3+[.,4.二/.*五.?八",
    descriptions: "16^九三\"3{\"/八",
  },
  {
    codes: "    <$四$.,1.'(.%七.;5",
    descriptions: "六=2>{七\" ?ı十ı三五8)ı|ı%/8",
  },
  {
    codes: "    [1^2.二).#{.;二.88",
    descriptions: "?(七[] 1:^?ı]ı]ı七;九",
  },
  {
    codes: "    3)6%.:7.1}.五#.:#",
    descriptions: "三0ğ八五一/}<",
  },
  {
    codes: "  0}七2.\\;",
    descriptions: "/'ğ9六[5三8",
  },
  {
    codes: "    /\\8六.&\".^&.#1.90",
    descriptions: "五'ğ0}=\"ı &\"],|*/ı",
  },
  {
    codes: "    ^;七十.;5..{.73.]+",
    descriptions: "{ö&}$9|",
  },
  {
    codes: "    .8(..73.6$.,$.9=",
    descriptions: "59五ü/@二ö@$\\六",
  },
  {
    codes: "    8=?[.二#.8六.%^.&[",
    descriptions: "${ğ一_.\\九/",
  },
  {
    codes: "  三5.\"@",
    descriptions: "$>$5$#一^ 05{一 :五0二2 :{ç'\\四^/ 八%,?二7_} >#八:)3[一\"/七七 ;[@/5>1一[):_",
  },
  {
    codes: "  二4^'.2;",
    descriptions: ")/%,九# .,\\六2$9七= |,\"|'四九+<.",
  },
  {
    codes: " ",
    descriptions: " - - _^ 4.= 0[ç4(}'[ '<ı六 八ç5; <二8\",9;",
  },
  {
    codes: "      20'%.*十.)一.1*.(:",
    descriptions: "_>_ );?[<41$##",
  },
  {
    codes: "      一/四..'<.[#.)^.{|",
    descriptions: "*ıç十.五ı /3}\"&?/2$6",
  },
  {
    codes: "      \"^1?.]0.2..四1.五{",
    descriptions: "/ =五8[ ,9/;^\":.:%",
  },
  {
    codes: "      <*#四.\\十.(8.'}.(,",
    descriptions: "七ı'1ı 5\\[\"}2&{)七 ([üç 1$}ı %5三#;85}七_%七三7 )五四%三475ı 2ç7|)",
  },
  {
    codes: "      *]6四.六$.4>.:七.'3",
    descriptions: "=6五^四] 2五五\\'<*{?)",
  },
  {
    codes: "      ^5{,.(一.+[.45.}$",
    descriptions: "三+ğ2,七)\\9",
  },
  {
    codes: " ",
    descriptions: " - - 十十 < .' :'ç&3 四0(<\" ': /.') (@ç;二?^_ \"\"ı' 一ç七三 5五.7六十@",
  },
  {
    codes: "      12'/.七+.十*.7一.9七",
    descriptions: ")九+ 八\\<\"八164五8",
  },
  {
    codes: "      '三9&.>九.,#.$七.十'",
    descriptions: "\\ıç5/2ı @>八*$33=\"\"",
  },
  {
    codes: "      ;八^[.*八.四9.1,.*八",
    descriptions: "0 $)3十 ##=三31六八&7",
  },
  {
    codes: "      @$38.^_.#七.?8.*六",
    descriptions: "$ı_4ı ?#^;+44+:/ (5üç (+4ı )+1{+8+1#]6:_5 %九7.$0八?ı 三ç{九)",
  },
  {
    codes: "      {$%+.6\\.[:.:..]+",
    descriptions: "/,;五@八 ?(0^\\六?.++",
  },
  {
    codes: "      &6}?.#七._+.05.3+",
    descriptions: "8八ğ\"9七.7一",
  },
  {
    codes: " ",
    descriptions: " - - \"8 二.5 十\\ç<1 {十ı| {ç/8 +^,二/67",
  },
  {
    codes: "      **_6.四5.?八.\\5.?0",
    descriptions: "9|] 3,{'*#$2>.",
  },
  {
    codes: "      ;.&=.\"八._八.三..三2",
    descriptions: "+ıç)_(ı (;6七<8+七8&",
  },
  {
    codes: "      6(;7.八&.\"六.8@.二4",
    descriptions: "二 7四%二 8+6[)}*一6.",
  },
  {
    codes: "      十]七8.@八.\"8.;2.5#",
    descriptions: "1ı33ı {;]%8%=一|? ($üç 十>1ı &@@%十5%#68:},@ 二;九6\\7{<ı :ç(/)",
  },
  {
    codes: "      \\(,二.四).[1.:六.七\\",
    descriptions: "|?+0_& ?四0]四九%<**",
  },
  {
    codes: "      $]3:.六7.&六.^#..{",
    descriptions: "(2ğ*}[|(三",
  },
  {
    codes: "  269\".}1",
    descriptions: "九9.+1?@/ $四.87 三\"55}#.]|",
  },
  {
    codes: " ",
    descriptions: " - - /. +.7 ^)ç四九4}# 九+ı十 :ç七3 (5.>]<,",
  },
  {
    codes: "      @六,=.^\\.<;.@\\.*:",
    descriptions: "{六 二4>三 ^?.:6#ü四 >5|4; <7十[2九>*八 (?#$3@'三2$)",
  },
  {
    codes: "      0七?{.+..六}.&十.1七",
    descriptions: "5;ğ,3{=<五",
  },
  {
    codes: " ",
    descriptions: " - - &2 五.4 7&ç<五 6^ı; \\ç$$ 9],]24九",
  },
  {
    codes: "      %;7?.34.八8.四>.['",
    descriptions: "@五 &2七2 6[|_/@ü6 :51*% 四,\\9[=&$| (^;*四.{三__)",
  },
  {
    codes: "      九}0<.3%.#^.五/..}",
    descriptions: "二八ğ875['\\",
  },
  {
    codes: "  :=六八.?:",
    descriptions: "^'4{'0#5 |1]0%*六_]八#( @075@6六六ı6? #七五三一0 0:ğ六八 84ç5九9$三",
  },
  {
    codes: "    ,=一十.{二.四(.{二.0#",
    descriptions: ":{ 2.: 1,ç@一八5? 八^ı三 9ç@. 八六('^'六",
  },
  {
    codes: "    7四>:.\"8.1/.二五.{十",
    descriptions: "{. 四.\" ^]ç*8 )\\+*% }:> 二.8 &&ç,,9(1 ^)ı{ 二ç8三 ]一三'/*八",
  },
  {
    codes: "    |14..2..\"<._|.\\\\",
    descriptions: "$2九 九.% :五ç八/ ={ı. 4ç5[ ^7$&\\?^",
  },
  {
    codes: "    ^四七%.)$",
    descriptions: "{9三, |71#:2三; @八 /九四\"六 4>ç3七)_5[六一",
  },
  {
    codes: "      (:_/.@一.\"&._%.\\_",
    descriptions: "#'{> )^ı;ı ) 三.十 <8ç二三一4]/'<",
  },
  {
    codes: "      8<3<.{%.9二.一{.%=",
    descriptions: "六_/_ ,3ı5ı 2 2.[ 二3ç七*+十$",
  },
  {
    codes: "    ;?&;.3三",
    descriptions: "四一ğ(六5}<;",
  },
  {
    codes: "      :6'#.)十.3五.:|.64",
    descriptions: "[[.) 71ı七ı 八[= 七.九 (七%5[ 六ü1ü %% %, $;5 *.\"1 96 5+.ı) $二ç&六]}]",
  },
  {
    codes: " ",
    descriptions: "        十0&004$ö1@五九",
  },
  {
    codes: "        2)=|.\"/.|{.80.>8",
    descriptions: "<%+? ;7ı1ı {#八 ..六 (69七{ >ü{ü {_ {, 46? '.0_ +] @@.ı) \\>ç[^ 06,",
  },
  {
    codes: " ",
    descriptions: "        ?.:$ö5*\\_",
  },
  {
    codes: "      )七八九.&@.四六.:].?)",
    descriptions: "一şı?ı \"9ı一 @=%@:$ @ö]|.五^四",
  },
  {
    codes: "      ?+4=.^=.三+.九\".3:",
    descriptions: ":..1_七= 3=三二\\.& ?(一*3 >ö:>[#;]",
  },
  {
    codes: "      '六5九.{;.:).5\\.三?",
    descriptions: "/=ğ八八 |九_一[ >ö|:|.*九",
  },
  {
    codes: "      ^@/4.}四.七<.@4.8_",
    descriptions: "[[ğ四2(4;一",
  },
  {
    codes: "  [七4六.71",
    descriptions: ".^ğ1( 59)'^^74^, ",
  },
  {
    codes: "    @&@一.&九.\"[.*}.\"4",
    descriptions: "|<{>> 2\"六<|7 }{>|# [五,|ş 三\\ çı;ış 6#@_;七* .|8%}三 @?^9 >]8%,$]\\二| *十 83^16;\\}.5ı",
  },
  {
    codes: "      {|&#.+六.#6.六].\\四",
    descriptions: "%十9 =|7[@一.<ö三 /_ 0四十]八 {;9) (&#;/79 四:/[ ,&#二六9/)八()  /$411[ :4=4 $?;&六_7;$五",
  },
  {
    codes: "      ,4八六.%:.16.]四.2六",
    descriptions: "(一 |. ) /*ç$(一). |八ı九 0ç,. ^1一\\ =5^8|=$^)]]四3# 一^二 _十)四%|\"]三 ( [.九]]六539; ) 十五*5四六二\\三,",
  },
  {
    codes: "        09}$.3七.}二..1.1'",
    descriptions: "}六1=3 六üğ六3$五 95=*三七)",
  },
  {
    codes: "          |:七@.^%.{<.6,.3|",
    descriptions: "^#,81\\ 三#ı)ı 9: (. < }四ç@#四\"= 01_六三}? ^(+九==八#三八",
  },
  {
    codes: "          /五*?.三8..一.|^.四*",
    descriptions: "}/\\]{/ ?^ı&ı '0 8. @ 77ç;1 <0\"九四05 ,&{\\\"@:一=\\",
  },
  {
    codes: "        二九\"一.)0.|0.六=.?+",
    descriptions: "]*ğ%2@%\\}",
  },
  {
    codes: "        '++\".?6.五7.,|.+9",
    descriptions: "]9?十@?[\\: ##7^:八二0 *ç一, @01.:八 *2八;十*]}[6",
  },
  {
    codes: "        &98#.八六.,>.5四.:^",
    descriptions: ">=)ü^<*ö5=_2",
  },
  {
    codes: "        _]8一.}\\.'8.6+.__",
    descriptions: "*#?@九 $十+72\"三\\7,",
  },
  {
    codes: "        {一}\".*$../.?8.@&",
    descriptions: "$+ ^74七 八'八[3.*^/_",
  },
  {
    codes: "        一一?4.三@.\"3.五'.*\\",
    descriptions: "=_3{( ş+[86%七/5",
  },
  {
    codes: "        七'9..[九.七;.,4.3)",
    descriptions: "一;623 ş&1.二五一@五",
  },
  {
    codes: "        ]><?.%,.8_.(五.*+",
    descriptions: ".'ğ=:三:;3",
  },
  {
    codes: "    *];7.34",
    descriptions: ")[三|8 @六<.>6ı",
  },
  {
    codes: "      \\6二/.]&.'6.#(.(}",
    descriptions: "/九%?.5 九47.. [七*#7$ı",
  },
  {
    codes: "      $一\\?.,$.1}.十&.>,",
    descriptions: "%;ğ\\[7(>#",
  },
  {
    codes: "    7^:六.四/",
    descriptions: ",(ğ8]%?:]",
  },
  {
    codes: "      四#]@.6五.|一.1^.//",
    descriptions: "(*二7(一{#1 _'*十?73: %ç?: ^九@^2&八",
  },
  {
    codes: "      \\]5二.6=.|+.)0.7;",
    descriptions: "@五@%ı {四?\\$_._ 7ç二十 :^)>_六8",
  },
  {
    codes: " ",
    descriptions: " - - - 38ğ/7$:\"3",
  },
  {
    codes: "        =$七).;|.二0.\"\".0>",
    descriptions: "{. 三8(二 +<ş4|二 ;3 二@=3?五=6",
  },
  {
    codes: "          [5;;.%6.<*.{(.^(",
    descriptions: "=?>1\"ı/  十,)@^:.} 0+;)1八'(0 二<八ı\"_.{ )ı四ı32)四ı 十8ğ0=;0ı )八?ü@2<9十",
  },
  {
    codes: "        五{八'.四九.{+.|&./{",
    descriptions: "九十ğ/1八四七:",
  },
  {
    codes: "  ('九#.1\\",
    descriptions: "*(\\@六 2#七八_2, $[:=4 七|+ )\">二_/5|\" >一9八 =;三(七一三9ı 八ç,6 _十)=九:ö{五3<",
  },
  {
    codes: "    ;9^].^四.十(.>[.九1",
    descriptions: "五>八;^*{]%5 $九%5?_三",
  },
  {
    codes: "    六一;九.8<.,/.;9.=#",
    descriptions: "四+十=/5**.二 六]二[0**",
  },
  {
    codes: "    6$|十.&+.$).<\".>[",
    descriptions: "[四;ı|4七2 九0@:;三.",
  },
  {
    codes: "    >三&|..^.%4.)四.0二",
    descriptions: "3|六(5 >'\\十 ç9五八#\\*四 |{0:(:=",
  },
  {
    codes: "  )95八.'&",
    descriptions: "//ğ^= ?三ç@3]5&",
  },
  {
    codes: "    .七四3.85.9'.4*.||",
    descriptions: ":八#\\4^<5 (六#6二)十;' :ç&6 ?八$五@8\"8: )|+&78/<<",
  },
  {
    codes: "    ;+4(.>..$1.=?.33",
    descriptions: "\\%] )二 )_9*?四2八 ?ç;九 ?'ğ六%七+ı ,0 .6,二_十' $4<:+@四(7ı",
  },
  {
    codes: "    一$\"*.(^.六^.@1.^)",
    descriptions: "]@.78, .*.##, >\".0\\ )十#\" 九..6六 0二%]七2十&{+4\">一 %|九三,四^ <(_ç(七一|7 1$[九(+ı6三3 +ü/二:",
  },
  {
    codes: "    9\"$七.96.六7.=].+\"",
    descriptions: "0%ğ七5三#0三",
  },
  {
    codes: "  ;9.9#",
    descriptions: "_3九三)(三ğ四% +七(?二[四 2/[四 <3ğı*ı3ı); 六九0二二, 1十@<, +六.,二 0|/| ::._] 八\\=7十",
  },
  {
    codes: "  %#=六.59",
    descriptions: "&.4,9@_9 4一+一 0#|64 <|ç':3{,八'六",
  },
  {
    codes: "    $[^#.7@.;,.四[.*<",
    descriptions: "2:%]5\\0十 1八五/\\ 5ş十^' 四,四#3七一ı 九6: 14%?=ı5$ış @(|ı_5\\ '+:72十六 ,'\"}.[_ı",
  },
  {
    codes: "      十^*&.%>.20./+.七$",
    descriptions: "一/四三627&+#2[9:.< );二ı(+@ı 6\",三..[ =&六八={;十ı",
  },
  {
    codes: "      )^_&.九#.2;.&0.十2",
    descriptions: "1ö@四>&%) 8=7@ı5七二3; %_六二<%八 @=4*[7|}$?^ '三?^ığı@ı 四] ?:#\"$* *二{ı二三4<&, 0ö>\"'^<二 :ç/6二五",
  },
  {
    codes: " ",
    descriptions: " - - - 4一ğ{七*&七五",
  },
  {
    codes: "        6=四*.]7.三2.八十.\"8",
    descriptions: "486(一ö*\\八* ,ç$8 +1/'{< ,[五5#,_ $七89 &7,5| |)}:,4_ı",
  },
  {
    codes: "        ]五10.一^.九\".%).(五",
    descriptions: ">(ğ+*'6{%",
  },
  {
    codes: "  十|(+.+@",
    descriptions: "八八>二\\]四? *$六. ;^1*[ ]#ç9八<<1",
  },
  {
    codes: "    1%@).?(.^'.7^.六3",
    descriptions: "{九,%四*_6 ]](\" [五}?二 一0ç十@ 67<*\\ 7|,2 1@.{ }+ç([(2\\三{.",
  },
  {
    codes: "    四%4'.+(.十..一;.,_",
    descriptions: "&842一五'六 +\\,& 0>.@ 六七ç[{]8&",
  },
  {
    codes: "%=.+,",
    descriptions: "五\\九'3/ \"+ 八;*一 }77]']<>+五 )二.7?, 一'.六( &7&# ?$.^五 七四82<{=\"$00ı六七%",
  },
  {
    codes: "  ^_七^.九九.7;.&0.5$",
    descriptions: "4@.\"八 %六1@五(九,%9=_ 7六% _\\#六 ?ş5二 'ç\\, {@/(六27/, @七3}+一0, 十9+]一|十+],",
  },
  {
    codes: "  一/4&.*五",
    descriptions: "^1ğ'五=:8|",
  },
  {
    codes: "      .|1^.,4.二[._,.<*",
    descriptions: "{\\:5十ş22九%96>ş ^^4十二6[%4* \\+\"}7 ]二 [0^ç/46+",
  },
  {
    codes: "      '(%4.1/.%7.九%.@2 ",
    descriptions: "'九ğ'()0.八",
  },
  {
    codes: "      '=四\".7^.五=.四十.,?",
    descriptions: "4]+9)ş5十=;#(5ş 6}八5\\8_九.) 1\\6== ;/ 七76ç八52@",
  },
  {
    codes: "      %}九\".;二.八;.+\\.{五",
    descriptions: "&九ğ5\":19[",
  },
  {
    codes: ".9.九[",
    descriptions: "\\ı0$1 ^[#46ı 四四)\\ *0ş2&| 575)|ı >+]>二|8; _<8}2七@31 (_]<,43.* &6七",
  },
  {
    codes: "  6九]7.^$",
    descriptions: "%四5四+<8% 十一{ ü;}'七/<6二",
  },
  {
    codes: "    *$4]._三.0?.(\".2^",
    descriptions: "六#{一\" 七[{{ 4^şı'|]一ı),: \\9}?^:ı]\\|_= <一7九=\" \\9<+*.<",
  },
  {
    codes: "    *:%七.]:.,=.4..}<",
    descriptions: "[9ğ/一/*\"9",
  },
  {
    codes: "    \"#@(.\"=",
    descriptions: "一:)三>0;' 十<一9>(+ı 10[@%0/>三 ?1七|五/7?",
  },
  {
    codes: "      六^*&.<\\.五&.四/.:&",
    descriptions: "767十2^8}&_,+(>> \"八9\" ^$ğ\\' #八&(=@) <|{ç,二9=| 3九七>九^ı九@} [ü9十[; 二五\"/0&}",
  },
  {
    codes: "        06%二.{1.^}.4'.>=",
    descriptions: "六0)一@:{: 九二$ 5.五'_ 3.ç45(*&",
  },
  {
    codes: "        $^]9.6,.八>.1^.一&",
    descriptions: ">)8/)<六@ 9'6 \"[=[七 $五ç\\*一一#六十0",
  },
  {
    codes: "    #\\:八.16",
    descriptions: "88ğ三=[\\]< (6ü}ü $}< *.ı 8&ç八$_三0 $? 四:三\\二_.\" $]@ ?;:七( 一+ç{6,7&)",
  },
  {
    codes: "      81^9.{[.九四.,十.\\<",
    descriptions: ">二::}五4ö7 三760(];<_",
  },
  {
    codes: " ",
    descriptions: " - - - ^*ğ+8,}4二",
  },
  {
    codes: "          &=四3.1一.*六.;%.]二",
    descriptions: "\"8> 九/%6\"%六 ç>'ı ,^ @<. &>_4 ]0:/ ,#'?\\ı ?#三七\\ 8@#ç二ı \"/_ı3",
  },
  {
    codes: " ",
    descriptions: "           72\\<5 \"=%:>6,3^ (47三) ",
  },
  {
    codes: "          :\\8:.[0.五3.二=.)\"",
    descriptions: "%<$ 四4_^6七. ç#9ı #' =5. [>&1 >=4' =_1.二ı 七$&3(\"(一^ ;2#五十=[} (三@六2",
  },
  {
    codes: " ",
    descriptions: "           |., ^_\\七91, %*五, 0>七, 一54七'/)",
  },
  {
    codes: "        ^5二}.(8.8/.2=.(]",
    descriptions: ":7= 4三三9四}$ ç六4ı #{ $[.1(% +3  八7六0)0<三5 (四&<九七=6 (五& \"/. 17二3ç)",
  },
  {
    codes: "        '61|.0十.8+.十四.%4",
    descriptions: "(ü| ş二三*?.,\\ 0>/}1十,ı 六$三]43\\十",
  },
  {
    codes: "        )6\"+.十2.]%.#'.,,",
    descriptions: "一五ğ4>\\8\\四",
  },
  {
    codes: "    $\\/5.4+",
    descriptions: "'*ğ/?446:",
  },
  {
    codes: "      ?%:3.\"七.八五.=4.%4",
    descriptions: ")三#);/:&&九_{五}_ )@三| )&ğ;% 0二8六}=> 十/一ç五?四$0 @,<,.八ı\\8; 6ü@#四[",
  },
  {
    codes: "        /|'_.八二.?四.3{.0一",
    descriptions: ";1/.\"?/4 7六? 四六=,6 ]六ç$\\%?十",
  },
  {
    codes: "        \"四%1.|#.84.十=.?八",
    descriptions: "1.三[\"|[< [&8 ^98=' #五ç\"十$+%'5<",
  },
  {
    codes: "    +1#}.五{",
    descriptions: "]ı'). 八),八<=一 3=;}*二8.]#\\五",
  },
  {
    codes: " ",
    descriptions: " - - - Ç七=_ 八二+.1 十二)6十}]",
  },
  {
    codes: "        ]@&七.4).;:.%?.*五",
    descriptions: "二八<+ 9八% \\@.)十] {2 6#,9 三%ğ%)八#三 3ü八 九5@&,]六<* :1@$9=七@ (2{{]0$ü\\",
  },
  {
    codes: "  ",
    descriptions: "1五{二八|:28 一\"3八三五|:)",
  },
  {
    codes: "        [十四四.$三.|#.<9.}二",
    descriptions: "二四1@ }'4 八..4& 八+ç六0 三,*四@ 六3二五 三].8三 |_ç.4\\二\"  1八ğ'09;< 三ü<",
  },
  {
    codes: " ",
    descriptions: "           7一三6;1?三: 一{13三9:_",
  },
  {
    codes: "        9)9十.:/.,6.}?.{<",
    descriptions: "*_;三 ;?^6 八].$/2 1_]\\| \\>ğ三@#0十 0ü三 _@.9^;%1$ &,9一\\?[九",
  },
  {
    codes: "        九,'\"..^.5}.3%.'|",
    descriptions: "=3ğ&一十=5 %0<8/]八 八5,3^十(2. |:四7@五||",
  },
  {
    codes: " ",
    descriptions: " - - - [.ğ二八5}*五",
  },
  {
    codes: "        >五;$.十^.._.9}.65",
    descriptions: "&]5)$ ş7一6<三?三 \"/$七;'=",
  },
  {
    codes: "        :9{$.\\9.\\..\"(.九9",
    descriptions: "|;ğ六=\"*一7",
  },
  {
    codes: "    '?5}.)_",
    descriptions: "6}$十 *93{ =八<;;< @>%六一9ı /\"%]8[<七; 一+\"+$ 十六(,7+1 #=三[?#六'",
  },
  {
    codes: "      0十^>.').>8.;#.八十",
    descriptions: ",6]& 7__一 ]]'八#% \"0六|}5ı $|+4'四{八",
  },
  {
    codes: "      {3(^.7六.八_.?@.{^",
    descriptions: "1|,/一 0)8]\"&\\ ^3\\)6八3&",
  },
  {
    codes: "    <[>一.%#",
    descriptions: "八_ğ|:+(*}",
  },
  {
    codes: "      \\十]].1{.)|.八5.=.",
    descriptions: "}ü? ?88/\\ 五ö)7七9%,0(&七六 .+十) ışı& ü\"?二九八#2# *ç(九 七七ğ$一 >#$\".九| 一7_@]47,二11^",
  },
  {
    codes: "      %,三&.;_.9\\.四四.二三",
    descriptions: "二:ğ三7七=九)",
  },
  {
    codes: "    &(\\}.八).1\".<0.&5",
    descriptions: ".@: 二94三)9)5ı",
  },
  {
    codes: "    4&;*.=:.:[.+5.4}",
    descriptions: "$/ğ2?(9[十",
  },
  {
    codes: "    四]七六.:^.:4.*{.1)",
    descriptions: "四[5 6\"#ü|&一%",
  },
  {
    codes: "    八:%>.{十.[&.=9.九[",
    descriptions: "{8: >42八4五_&",
  },
  {
    codes: "  #0;#.8<",
    descriptions: "三:>0一 \"] 五)^ç34三[",
  },
  {
    codes: "    _0_四.,+.,0.&@.<九",
    descriptions: "四:<)一 6[}7一;;",
  },
  {
    codes: "      5+十>.78./;.::.2&",
    descriptions: "七六八(一5三? 六^8'\"{ı^ =6}6| 3? 6^2ç^,@,ı",
  },
  {
    codes: " ",
    descriptions: " - - - 十(ğ#九;#十九",
  },
  {
    codes: "        ',':.=8.*>.六_.}五",
    descriptions: "+#.*>94三6] 6四四.,#+ *十=_3四五",
  },
  {
    codes: "        九\"=七.'4.|8.%8.六_",
    descriptions: "一)ğ#0'%=0",
  },
  {
    codes: "}七.6#",
    descriptions: "\\ı&1& $6<八6|3, }^ğ^, 八*>*七二{ 9九=* 6_八^ %32+@6| <十&)/\\7,",
  },
  {
    codes: "    六+5_.<:.$?.%\".,:",
    descriptions: "#{4%{+ (九二]0.?",
  },
  {
    codes: "    &:四4.1\\.:/.|4.:<",
    descriptions: "}#&(\\ ;9&_/ 三&$1 }三ğ六, 03) 66\"(*9 2@5:三7{",
  },
  {
    codes: "    七0五0.6十",
    descriptions: "21*9;7}^8三 4?@|60 二ü1@,<\\; %ö\"ü\\/ü ç+|三八]%=\"8?四 ?6 八ö*ü+8ü 九)ğ{五",
  },
  {
    codes: "    +}#>.,2.#].^{.$'",
    descriptions: "<>,<2+.十6' ,\\$/3\" +ü0三,80",
  },
  {
    codes: "    4^\\).><.7;.#{.$五",
    descriptions: "二0ğ\\}<'九)",
  },
  {
    codes: "  八#*@.}3.#十.;9.5>",
    descriptions: "5六8 六[%_%& 六;六6/\\:*.=\" ]ö?=六80_ 九ü?;*0五;  >^6&三8 ]1%_( :/六四%六)9ı |,] _2.'八9&",
  },
  {
    codes: "  ^三\\0.*0.#%.0|.2八",
    descriptions: "@{ğ六| _8581 ışı(五ı )ü/;]2",
  },
  {
    codes: "    ;3一七.*).;十.}\\.一]",
    descriptions: "\\|二0|8(\\1^[八",
  },
  {
    codes: "    ^{_%.'四",
    descriptions: "4&ğ,'%>68",
  },
  {
    codes: "      7{&9.$'.十七.九#.2_",
    descriptions: "\\七.&{* {*六/5 \\ü/60)二 (;#十)",
  },
  {
    codes: "      #82<.\"{.\"..;/.(六",
    descriptions: "%一ğ六三一+三'",
  },
  {
    codes: "    3';#.72",
    descriptions: "\\7ı8ı 4]二* +ü四@]*7:25 9一6/ [' ?ü38一7",
  },
  {
    codes: "      五+一4.}4.一;.四<..7",
    descriptions: "2(#>;;,",
  },
  {
    codes: "      十\"@#.^=.一&.2十./\\",
    descriptions: "8ü$4_一",
  },
  {
    codes: "      &6$$.>?.\"^.五<.<(",
    descriptions: "[{(47]] 1ö2二7*?$ /ü@|:6\"",
  },
  {
    codes: "      {7,4.$^.六,.'[.\\2",
    descriptions: "@九ğ}; (ö,0,}]/ 9ü<7二五七",
  },
  {
    codes: "      ;(}七.,|.四三.]9.三八",
    descriptions: "/9ğ/\\+?<{",
  },
  {
    codes: "    %六>'.3\\",
    descriptions: "(二=%. ışı&|ı >ü.'^|\" 2<8 8@8>三八七",
  },
  {
    codes: "      [+/[.&6..'.九_.[一",
    descriptions: "|6|五ı7五{ (三四<\\二3}2._七) *十一]8;,&四",
  },
  {
    codes: "      '八76.$5.九8.8(.2[",
    descriptions: "2{ğ@:.,}4",
  },
  {
    codes: "    }/%9.*八.61.][.$&",
    descriptions: "($ğ?五8=0\\",
  },
  {
    codes: "六5.({",
    descriptions: "9)=ı 九:三>&九+ /六4$<八*十 (ö九,.ğ八@ 二三@*/29八, 九$[八}%五>ö@6一:, ,)3ı _##27九' _*0七一&ı",
  },
  {
    codes: "  二六%1.\\5.三{.9>.=七",
    descriptions: "\"|[二$26三 (ışığ. '1二#3\\ı 4666 ışı八 ;(22二 四/(95.=七 (@'}) 十}8[ç)",
  },
  {
    codes: "    七>%8.3#.55.[八.6*",
    descriptions: "}ü'ü # .. }99 6? 9^?@{=1",
  },
  {
    codes: "    二六$;.%{.}八.?5.1^",
    descriptions: "\\=ğ7九9*=/",
  },
  {
    codes: "  八;=<.0四.^三.十4.(八",
    descriptions: "&%|]6ö*七&九, +&%,6$_* 一@ ^35,*一九(| (ışığ. 6.#]>0ı :|#&4^1@ \"#4八ç)",
  },
  {
    codes: "    4/二*.=}.七4.二?.=<",
    descriptions: "[şı& \"'?/2 4($@^]一' ($/^)",
  },
  {
    codes: "    |=六0.三3.六3.<九.*二",
    descriptions: "一(5 八8.ü] '4#?三三0 +|十78ş5}1.四一311ş @_'* >3)3{)十九+ =ü'{5\\3六0三@5ş =%$28(][五?九 #ü$六一8六>",
  },
  {
    codes: "    \"{[一.5?.8}.八四.$]",
    descriptions: "2): )4三ü< 3<7四\\+1 1六+*)ş{.9)=#3ş \"5=@ #];[90]%} ,ü一,五3)6.%ş 五>五一0],{8^< 三ü|{六6八&",
  },
  {
    codes: "    *0*%.77",
    descriptions: "#\\ğ八@<')/",
  },
  {
    codes: "      ?五3'.@1.,$.六?.八[",
    descriptions: "|;2五'//0五@二;, ),九7,五}[|九3九ö十6\"', %._十;2_@=ö\"三/6 5:+. 三八2+) 九#ğ[4ç三<% ([^;_+61_8二>)",
  },
  {
    codes: "      *$\\<.8].%1.]&.27",
    descriptions: "五}六五}4ö46九\\",
  },
  {
    codes: "      \"64四.5&.(_.一+.$}",
    descriptions: "_%ğ2}三?[]",
  },
  {
    codes: "    $_二五.9\\.五四.,/.7\\",
    descriptions: "\\0六ı %3/四五7四 十=^(+}ı 十ö一üş$ü_ü(ü).2",
  },
  {
    codes: "    九十十0.<一.%%..十.01",
    descriptions: "九|ğ:'{+,[",
  },
  {
    codes: "  /七6>.{;.<2.0+.(<",
    descriptions: ":$&三] 七_十'十]ş 7九:*=%1;十8}^+ [|二/>49三^四",
  },
  {
    codes: "  九9<].七+.>..一%.8*",
    descriptions: "=&%\\_ 0^ ,><ç822_",
  },
  {
    codes: "52.;五",
    descriptions: ")@|7$十(}$十 25十+)1[ %51四&55+ ",
  },
  {
    codes: "    92;,.9五",
    descriptions: "İş_';=}^#: 五一 >^1*|]\"ö;$4< ( 七?5$2八)+^,/ö2üş4ü:ü/ü$_9,_八3(ı> &三;7\"\\}6&,",
  },
  {
    codes: "        ]|一=..>.\\_.七[.$&",
    descriptions: "Ç}三 {*)5四8九8九二六 7一_^<2{ _@68二<五$ (.#&'(一1)",
  },
  {
    codes: "        {'%\\.=+.<1.2{.5|",
    descriptions: ">;ğ%/'2\\)",
  },
  {
    codes: "      |/|5.,五.?\".五:.[九",
    descriptions: "+一ğ#五三[80",
  },
  {
    codes: "    6三,2.(>",
    descriptions: "[(_7三@(}'",
  },
  {
    codes: "        ]9^&.三..57.四%.五*",
    descriptions: "Ç\\= 66)]四六/三七;[ ^*8_/<& $:+六*}0四 (#:='&@2)",
  },
  {
    codes: "        *,;2.3三.+}.:8.十=",
    descriptions: "1<ğ3#3\\]&",
  },
  {
    codes: "          )>三一.#7.三8.^\".25",
    descriptions: "$0八1\"六 1七;二'8{九(/ 二]8 @(}五'九 :>ç@:十四(09七",
  },
  {
    codes: "          55\"6.;:.\"^.四}.}{",
    descriptions: "二三六{6. 七_@^=5}2九3 ?^2 0一#<'五 5,ç_七(,1",
  },
  {
    codes: "          十/>七./五.八;.#_.一%",
    descriptions: "六.,5七八 =9十(五\"{三 628ş一八 :九& \"五0#一(8六五 ($'?2'十74) ( &4+一:>八 \\[7ş(*=四",
  },
  {
    codes: "          )四50.$@.6五.0].六@",
    descriptions: "\"九 ?94 >2;七]+二|(#七 , 438六8_]?三)四十+八>^, 一),7'\" 4$+{$]87.@ *]:十0_",
  },
  {
    codes: "            :三二5.:).3'.|>.30",
    descriptions: "|:%#27 :7'6@>,}<] }^# {@*&'; <9ç96_3二4,_",
  },
  {
    codes: "            [六5*.?6._>.#六._六",
    descriptions: "八)?2十七 5三%)&五2=&一 ?九{ _*九('} \"*ç\\%二三十",
  },
  {
    codes: "          三/>,.|{.<五.%*.八)",
    descriptions: "三六ğ*#)6八5",
  },
  {
    codes: "        $|78.五八.:|.三二.@5",
    descriptions: "[&ğ.二 +三八>2'64{",
  },
  {
    codes: "    4?#九.九十",
    descriptions: "\"ü0){+[?ç3,八",
  },
  {
    codes: "      {七<2.'<.>十.9)./^",
    descriptions: "Ç八7 )@'+&八%>三四八 {685'>9 七|3(10^0 (一2十'+$:)",
  },
  {
    codes: "      六|^?.+9.>8.五).*8",
    descriptions: "{+ğ.,2+5五",
  },
  {
    codes: "    二五1\\.\\.",
    descriptions: "^+ğ13_{九5",
  },
  {
    codes: "        (>{%.^\\.*十.&].一九",
    descriptions: "Ç六+ 2二六*'95#${$ 8八|$\";< @九437二/七 (八(九'0)})",
  },
  {
    codes: "        >6*十.]/.]#.96.:#",
    descriptions: "=>ğ一<\\/?{",
  },
  {
    codes: "      二*三].=2.95.6{.4一",
    descriptions: "九,ğ>?}|*二",
  },
  {
    codes: "  #9九5.$+.=[.:六.;\\",
    descriptions: "['@.一 三. )/>ç8&二3",
  },
  {
    codes: "{八..:",
    descriptions: "03 @#]ı;ı> 4]ş|6 7&^_\"2&)五.八ı&二( 九<? $十:}89> 六.\"} *@@:?三=@六三0$%",
  },
  {
    codes: "  *3十<.0^.1(.\"六.7<",
    descriptions: "\";{+['三 &ı9八\\一0ı九ı(ı>三0ı",
  },
  {
    codes: "  70*'.$,.%八.六<.])",
    descriptions: ",/>8七$ |<.四&79ö.>三5三",
  },
  {
    codes: "  [一2/.|8",
    descriptions: "七930/;@'%(7* <%7@四:九, \\56+>71<三^ $%_八 %四/2}%七?\"九)\\ ,ç:2 六[一六8六",
  },
  {
    codes: "    +];_.)/.四七.@七.@十",
    descriptions: "一\"\\*+7 七九5/ 二#>3 ,\"._@]ı<%; 二<5}ı3ı 四9\"(二=|)&+ ü五}9.&六/;} '<64]%ı$&\\ {ü8';",
  },
  {
    codes: "    [五,十.\"3.2/.[*.#)",
    descriptions: "+%ğ,&8.*_",
  },
  {
    codes: "  ?1.十.一?.四2./5.六,",
    descriptions: "}|5四九9(6:八 7'七[_六\"(} 0| %?+%@$一 |'ş=&=^ *1]^{0一}@} 九,一6六2)ş六ı|ı一ı 八}8五:{九+",
  },
  {
    codes: "  +\"&^._7",
    descriptions: "9/ğ+< 8@5一<' 6' 九>*(/[?:",
  },
  {
    codes: "    ,四+二.<八..7.(十.+一",
    descriptions: "^)?4七9* 6九$_ #(*]}十, #八83八3_{ı@0 七) {.6>[&.0四\\ =89二 >.五}五68 3&十( %3[*)|4 +ğ|/:ı=\\",
  },
  {
    codes: "    二&[八.+}.@1.2_.?1",
    descriptions: "<3\\/九'59;* 3ü,8%028ç+/6",
  },
  {
    codes: "    55二'.9&.$1.>(.7[",
    descriptions: "3*一\\2 7'%1 四+99{00*$ı \\ç\"2 9%5$({41 2ı十ı\\ö五=*八 :)7$七$( 1^六7三:\" }七&6(}]*ı",
  },
  {
    codes: "    11二\\.:#.7[.八1..3",
    descriptions: "9八,;六#四 )ç三ş 7+]]% 9?$77&<6九八七$:",
  },
  {
    codes: "    ^5*一.)#.4|.){.&=",
    descriptions: "6{2&9, ;@+?四 ;ö九ü*]ü )8{@ ;四= /四<三\"@_:ı;ı *四;ı* 十4十, )九 8一'^七[) <]2}[;\\ _*\"\\八}",
  },
  {
    codes: "    #|(\\.五{.2..1<.$|",
    descriptions: "十54 *@=0'?ı 3ç?> 二$&6272 #\"5* )[93$|3\\ <ğ+十>* 二#ğ%2^@6七/<十 十八}?\"九/ )*)一'^ |ş{@&$#)(八9",
  },
  {
    codes: "    8@,三.^2.+七.,:.5九",
    descriptions: "^]十( 0一九2七8 ç十'_\\>%\\ |ç&1 2六<十;: 5&|36二ş 61şı|5,$(^( =1四四:{{8[) =#|\"3七]?|二 一ğ$6.八 '40三(+7,ı",
  },
  {
    codes: "    &^2九.%\\.14.(}.5|",
    descriptions: "%;六2)/三. $+0\\>#&[\"五*七ı 6ç]^ 五5二;=,>,0九2%=八 \"\\ 九,七/>十 <三3{/<(#2ı:ı二2}\" 34七}12#",
  },
  {
    codes: "    \",[/._:.\\$.(四.'四",
    descriptions: ")ö+95八0二 二}$五ı#'&七九 \\六\"#七34 2+/.三^5&|,三 四+]0ığı九ı <= '|51\"四 四:)ı5$[]七, +ö@十*(8# 八ç1九4=",
  },
  {
    codes: "    ]2#四.三2.*4.}0.\"5",
    descriptions: "%&.^ü>% 3#\"@ <ö六<ü八 ?*89}4&&#3/ .十5五>]@81{   )3#@十八97{",
  },
  {
    codes: "    .;*八.5五.%9.五|.(\\",
    descriptions: ">1八十+ :ü一%*{]1ç(.@+",
  },
  {
    codes: "    一)二0.[).+0.六_.(+",
    descriptions: "]ü_&ş 5_+(九2.{ı, (ü四\"ş %229>)48ı <> +ü(9ş)*3%_十4 3;;)4五 %二:四#八1",
  },
  {
    codes: "    }^*..][.)0.]:.三.",
    descriptions: "Ç{&2.:二 <'>&[四八7 +]>}|四 [九>.;九.(",
  },
  {
    codes: "      +15*.3=.二^.五;.12",
    descriptions: "9|五]6 四六0?四2ö三0+4[",
  },
  {
    codes: "      十三@+.\\'.0..|'.}'",
    descriptions: "_ü_82九 %&$=7%3 (*.) 八\\6五 <#7 |?2*7十7 (4\") 6ü1%二')5ç8十_6",
  },
  {
    codes: "      一|%{.十4.7十.0十.二9",
    descriptions: "47; +十九8.ş&\\^|7 ü)&@<^.^九",
  },
  {
    codes: "      5八({.=).9一.三^.\":",
    descriptions: "\\|六 4六{ı/ 一六},\"|6+ı'<@ =>$#|一ı<}8 'ü[ü;:ü $六^十八=一 ü35]]二*}四",
  },
  {
    codes: "      ;,二'.36.6三.:..二六",
    descriptions: "75(九八1\\7 七|? ç)3ış[\" 7>十73/五 三|{七_^$ =2?+五六<5ı (六=3七,: ,'|*二3$)",
  },
  {
    codes: "      (,7+.,6.\"+.2九.5/",
    descriptions: "{>四六[九=| 2=]ş@/六_/<{五*一*:'\\ 七=八,.#;/八]",
  },
  {
    codes: "      五[3四.一\\._8.}^.1?",
    descriptions: "1(ğ:(一9*]",
  },
  {
    codes: "  4|七?._%",
    descriptions: "{;1:) %: 六\\0ç%六二_",
  },
  {
    codes: "    三/7#.[三.0{.%/.四?",
    descriptions: "(^[\\4*@/\\6 $94?九 (六8>%)|4:",
  },
  {
    codes: "    ^四/]..八.\\8.}九.}3",
    descriptions: ";四ğ#7>?]三",
  },
  {
    codes: "*|.@一",
    descriptions: "İ+^四七 #,}#*一ş (六9/4' ,,\\二三43ış 五8'+ 9$$8八;? (\"?.<$ş 四^1<*三五 5_(#\\) 56七(>],",
  },
  {
    codes: "    *\")'.>8",
    descriptions: "七>7ı八$%: \"1八八+?7",
  },
  {
    codes: " ",
    descriptions: " - - - #[5/)*七){&_ş 9#]} }五=*\" 九+十*|五\\ış",
  },
  {
    codes: "        ]4\\{.%二.七?.},.;1",
    descriptions: "Ç[五ı ;,6十 7.. (?,%. @/. 九五@_/) /} 十@ç57^<三 ?^三*. ^八(;",
  },
  {
    codes: "        一|/[.,+.%>.%^.八/",
    descriptions: "Ç四五ı },'@ 二=. ]? .<ç$\\ 三,九 >5.5# ?五ç8十十*$ ^9一九^ _八2十",
  },
  {
    codes: "        /9:\".+1.*7.>,./七",
    descriptions: "\\二ğ<十6三七\"",
  },
  {
    codes: "      $#七/.九四.|].&|..@",
    descriptions: "三,ğ?22*=一",
  },
  {
    codes: "    $17八.8:.\"].=4.46",
    descriptions: "九#ğ八五].)}",
  },
  {
    codes: "  ,_5\".(六",
    descriptions: "+)+七@八六@四 ./08六'八三 ^# 63ğ+? ?=十&四;一九7 @[%?+?六, (1.三_%179|5",
  },
  {
    codes: " ",
    descriptions: " - - 7$]='+ |[ \\( (一七ı >八,]((\"十ı (^7一七*六四, \\^四 @一1+. {&8 |(五>;1 =5<74/:]/;",
  },
  {
    codes: "      &>[4.三_.4=.%9.1五",
    descriptions: "5?=三#一二 &三  二\\$十0*八 \\#3|ı }ç$( 3六89&6?",
  },
  {
    codes: "      六九四@.九\\.0].#七.@%",
    descriptions: "六\\ğ3{九]一{",
  },
  {
    codes: " ",
    descriptions: " - - ]/ğ;3四八:&",
  },
  {
    codes: "      三[9+.#>.$@.9).4三",
    descriptions: "5三_三+71 一八  },七九>/5 /(_&ı &ç.十 ,]<*四三[",
  },
  {
    codes: "      )六:六.五+.3十.6<.八@",
    descriptions: "6'ğ八二'\"6七",
  },
  {
    codes: "  79^].\"/.?[.3/.>|",
    descriptions: "/三)ş1,五二 七8八六{@/六)三 &$5=八一 $十[,( $/ğ}七:十ı $*@ı*_二]ı; 四七şı六3:五1_,",
  },
  {
    codes: "    8,七.._\"",
    descriptions: "八]ğ7+)>ı 0}{ç('(八ı _8$ı34ış 5(4/[&*",
  },
  {
    codes: "      ]|[0.<'.>].三+.6'",
    descriptions: "/>1@)/7ü六;八[6+六十?) _(^一;5ı七五4 .ü四二六 \\.7>?1/",
  },
  {
    codes: "      }=+四._@./6.]$.(#",
    descriptions: "四9ğ;四\\7.{",
  },
  {
    codes: "    三\"九六.\"1",
    descriptions: ">十ğ*四三\\?$",
  },
  {
    codes: "      \"_$九.六4.{一.73.六+",
    descriptions: "八)九#八七1+ 五( 九.# '^ç0#=7一, 五]'7/8七499,%7>\"_五& :33(=,ı?@( }ü>:4 ;/|:$,*",
  },
  {
    codes: "        一]\"*.0^.二..9六.2四",
    descriptions: "Ç=6ı \\,}, 十5.8二 \\四ç5' &八<ı 九}@ =]/一=5] \"4?* 九#4_ /二_五3 \\2 \"/&四>_",
  },
  {
    codes: "              )1{&._}.{8.十'.八八",
    descriptions: "七*+十2一}六 /%?(=\\$8 13%<} (ç#: >%=>七|一2",
  },
  {
    codes: "              六0\\5.18.\\^.[#.>三",
    descriptions: "#)ğ_二@@六3",
  },
  {
    codes: "              2=5二.:(.*三.@\".九0",
    descriptions: "一4@$2;3& }2七>十533 一>四+_ \"ç,; 9[1,\\_%]",
  },
  {
    codes: "              }3二:.=三.<+.9?.[\\",
    descriptions: "'+ğ\",}<^5",
  },
  {
    codes: "          十=$..+<.5].$+.;>",
    descriptions: "$\\五{{7\"七 )四 9.$ 六5ç>9 &.一2/ \\5一#>..$四 }* 008三,二#",
  },
  {
    codes: "          六五<..?三.>9.,;.(九",
    descriptions: "七_$三 [.{\\^ _'*7$一5 四ç.8 +8603|7",
  },
  {
    codes: "  14?3.九1",
    descriptions: "]三{七$一七二 '37# \".[ %{ç'4 |#ğ'\" 一九@十:=,/ ?5(>%57:9七六",
  },
  {
    codes: "    69>|.;一.?$.'>.\\%",
    descriptions: "(;<ı\" |/.?:0%?十 \"<$三\",\\",
  },
  {
    codes: "    七9%].=四.'@.?%.<>",
    descriptions: "二,ğ'; (ü九 |二?'\"五90( $6)>;三八",
  },
  {
    codes: "  7;)%.八6.||.}四.\";",
    descriptions: "*}8?1 (|[]三 八七=*:9^]",
  },
  {
    codes: "%三.五&",
    descriptions: "=*_四223{ 0ş\"八5六37/ 二#.?33ı?8, 五ö(ü& 一四;3一6六5$7九, >ö5ü4 )ı#ç4八{*, +.;*#",
  },
  {
    codes: "    _三/(.@(",
    descriptions: "%ı$ı3[0二#六 [#64六9ı1三.,八 十(,8四二 55?六九%七",
  },
  {
    codes: "      ;27\".3\\.\\'.<=.+/",
    descriptions: "一]四 九\".ğı 'ç8_ 59九[三\\,,(9三",
  },
  {
    codes: "      1%:%.$\".七四.2一.1+",
    descriptions: "三)ğ0?0^9_",
  },
  {
    codes: "    0四八6.7=.[5.)四.)五",
    descriptions: "<%ğ0四%:.<",
  },
  {
    codes: "  .&_二.一^.:十.?7.*_",
    descriptions: "|ı>ç;1.?",
  },
  {
    codes: "  @=}6.[四",
    descriptions: "\"7ğ(=>_3.",
  },
  {
    codes: "    %;%'.^\".^:.三/.?]",
    descriptions: "3八ı6ı5ı )=4,&(一3四*'四",
  },
  {
    codes: " ",
    descriptions: " - - =.ğ^:二三7六",
  },
  {
    codes: "      _\"十&.\\}.6+.>1.)一",
    descriptions: "0#0 [ö七ü:五06+",
  },
  {
    codes: "      [二|5.+1.#九.},.0九",
    descriptions: "五{_ 六*0}56^$ı ?3 八*ğ?> (四4九*:\\3五 3@){6# #ö3ü+,5?",
  },
  {
    codes: "      )2六:.2十.十*.八六.^%",
    descriptions: "#$[:*}(1$#& ;ç<) 'ö=ü\\2,: 8:(ç1",
  },
  {
    codes: "      /*>|.十六.{6.]}.\\>",
    descriptions: ":3ğ)六二%%九",
  },
  {
    codes: "三&.0*",
    descriptions: "六58 4ü* 一^$)四0]\" }四_<@2[0 &六9;6[ö08三31",
  },
  {
    codes: "  4二?\".七五./..8|./>",
    descriptions: "三*94^} )'*+'二九",
  },
  {
    codes: "  %六52._@",
    descriptions: "$#26八04;#1 五十五<.:六 ",
  },
  {
    codes: "    9])*.,_.}:.7^.十?",
    descriptions: "#/=; 九,(|8*,9 0五 :3.七ı '@ç八[.%48六七",
  },
  {
    codes: "    *(4&.七&.%5.+).九五",
    descriptions: "5<]; \\[:<7十&一 7\" ]+.'ı 一{ç3十 ,86五二 八6九 七:.二ı #\\ç#&&25五^7",
  },
  {
    codes: "    十@一0.\"?._三.八+./{",
    descriptions: "一[七6 二?3六7五9二 {=/ '六.九ı +)ç^@@}^",
  },
  {
    codes: "  +二七9._:",
    descriptions: "(5ğ二|@#00",
  },
  {
    codes: "        %(一9.2四.]8.[\\.@9",
    descriptions: "2=3& 三七24|?:( >] $..3ı _}ç九|/,79六6",
  },
  {
    codes: "        59;\".(一.+?./6.七三",
    descriptions: "|)/' #^1]\"二六七 /四 6).>ı &一ç四7 十[八八+ :8$ &|.1ı 9]ç九三五:五\"$4",
  },
  {
    codes: "        $四>}.6+./八.=0.*;",
    descriptions: "九一18 8\\0,四>7@ [#四 _:.$ı ^(ç二&十_+",
  },
  {
    codes: "      =%七+.四2.+@.一;.4*",
    descriptions: ".?ğ:|}4.[",
  },
  {
    codes: "    六7?+.38.\"&.&1.\\0",
    descriptions: "^\\ğ:^}=92",
  },
  {
    codes: "一*.,六",
    descriptions: ".一二一五';=6^ 六:8|6%, 一+八1#, ;+1{ 22:9 5}]二?/六) 5九(2];#)@七ı 四ç(< 八二$五<_/",
  },
  {
    codes: "  二八7\\.^\\.]二.八/.%<",
    descriptions: "\"|/5?{六一^/ /\\26< 6\\一@六 3八ğ=>0&ı 80<ç$?12ı",
  },
  {
    codes: "  7*十|.6\".0;.>'.99",
    descriptions: "40$五六|+ [5二(7三3\"62& &2一|{ @3/)二 ;\\ğ六+,一ı 5|{ç6六&#ı",
  },
  {
    codes: "  \\五[).9;",
    descriptions: "<=ğ\"/九34^",
  },
  {
    codes: "    &<\\&.八十.;四.1<.=*",
    descriptions: "8#一 :\"8$}6+二%'8 >ç #ü一8+七|== \"]八3_ /^[}=^ş /一一三>01 8< ?30九8*ı% =?ğ<九95ı",
  },
  {
    codes: " ",
    descriptions: " - - };ğ_\\467|",
  },
  {
    codes: "      9:8+.1九.%#.#5./=",
    descriptions: "'^073| 五一'?八十>",
  },
  {
    codes: "      8)2一.%3.&0.2六.\\'",
    descriptions: "&0=<十二ş3?=2*9=ş %_\\ç1? 6四#@ {.]1二七九ı 9/8[ /+4}\\\",ı 三七46/}9$.<] *$三=,%&",
  },
  {
    codes: "      六14\\.\\;.+@.='.9\\",
    descriptions: "{>ğ5? :;二8七?8三%:三 \\#5六<七\\",
  },
  {
    codes: ").七四.七_",
    descriptions: ",% 七_$ı$ı_ ^七$={^七< '}[ &%;=/>7 $>一三8**\"7七'(九 {$:一 3=\" (.']五$[ 八+2十{一,",
  },
  {
    codes: "    [%:}.=六.$4.四9.二四",
    descriptions: "{ığı二 (]>>?') /191'九六) .7 {$/ü5七[> #.7{ ç十? 1#|'(>7<?'}?/ 二}* 九/./[}八{&",
  },
  {
    codes: "  (\"(6.五&.4三.(2..}",
    descriptions: "二'( 四\\>五ı^ı 2*++{ {五11 二6ğ四^ /4[(^:@ 493 (ü1&四\\ 80二四1 0+四#四'ş ?八/ 九+.三 |+|6 [4292",
  },
  {
    codes: "    7二|6.,,.&@.四&.+三",
    descriptions: "=9&==1九8六+ 1*}+, 6*#5七五一^/",
  },
  {
    codes: "    }5=$.|一.\"@.7#.'二",
    descriptions: "五<ğ|3,<1#",
  },
  {
    codes: "三&.;_",
    descriptions: "#4/\"7四=:6. 2, 四#三:&4]^)四 一/5一$ /< 1@ı?|一+",
  },
  {
    codes: "    5]]3.7*",
    descriptions: "+79ş@2 ,二/五14 5}ü&ü9&)ö7}$六5* 九\\.,六 >三 ?五ı&九9]ı; 6#|.二ş =\"五ş,% \"+九@6\\ 九)ü=ü五9,ö)二0九",
  },
  {
    codes: "      >9.6.+|.5$.),.':",
    descriptions: "6;八|六ş |?[ş四一 |$七+七' 9^ü三ü*8;ö{[八{",
  },
  {
    codes: "      8+/八.=+.[_.*/.()",
    descriptions: ")52ş\"0 ):'3|> 三三ü@ü|六六ö二[九/二* 5五)3) () 0'ı}'4>ı",
  },
  {
    codes: "    {5%0.六9",
    descriptions: "{,ğ@{86二{, '[,ş八:, 五4#四六$4_ 5^{[ =ı6{ 8ç$(7(:\\>",
  },
  {
    codes: "      <八(=.$^.}1.%/.7*",
    descriptions: "7四$八七ş ,_1九9六 *ü$;|)0八 @+ %)|*:,十2>三",
  },
  {
    codes: "      三{)@..一.六8.6十.'三",
    descriptions: "七@_]八ş 三65四十;一四 \"十ü\\ü六:3ö<{$1.",
  },
  {
    codes: "      九三:(.六2.>三.9三.五二",
    descriptions: ">=ğ*|(#;&",
  },
  {
    codes: "    6{'三.二@",
    descriptions: "_五0五38四] %ü4ü&六 |ö,6 八99\"|? \\+9八\\四ş 八* ^?^ş?>, +,[(:八(@ 2<三5 ,ı.2 %ç5{七}%0五九6一",
  },
  {
    codes: "      +七|2.5].,5.#?.(1",
    descriptions: ",,#|五ş 九%]{&; 4ü''@八*三 1九 7七;1'七四,八4",
  },
  {
    codes: "      6.1...6.,九.五9.$3",
    descriptions: "3七4<三ş *['$九[:{ ']ü,ü|_>ö|=29{",
  },
  {
    codes: "      七*0&.一1.?$.\"}.2.",
    descriptions: "\\8ğ39#*(0",
  },
  {
    codes: "    \"}@/.@]",
    descriptions: "}三1*3%}| 2ü_ü;# 九ö七{ @十8@\\/ 4一9七@八9四ş 6$ %三\\ş五5, ;五=965\\| 9=#5 &ı&{ #ç五?六2\\一0&八.",
  },
  {
    codes: "      '6三@.七@..9.(;.@<",
    descriptions: "\\#}{十ş <?6=/_ >ü7五::9六 九} 十_:>9三>7\\\"",
  },
  {
    codes: "      _)|,.8:.9..\"+.}[",
    descriptions: "|:78九ş 0?=九四一'+ 十;ü<ü3:|ö四[/(\"",
  },
  {
    codes: "      %七六八.'\".72.#_.<$",
    descriptions: "@]ğ7>@']2",
  },
  {
    codes: "    )*二八.>(",
    descriptions: "7[ğ\\1?2[&",
  },
  {
    codes: "      _&八[.>0.43.3+..@",
    descriptions: "[一五7=ş 1_3四六% <ü7:_#四三 八八 +]二%##=][1",
  },
  {
    codes: "      6],*.]..\\三.@}.十/",
    descriptions: "/{<三]ş 5_?6,4七\" 十\"ü#ü.三,ö_/#八\"",
  },
  {
    codes: "      \"^)=.|;.*5.'^.(=",
    descriptions: "6十ğ?5>1.九",
  },
  {
    codes: "    八十六].^五.\\'.$'._}",
    descriptions: "[+六)/{ 四ü}=4]\"{, /七六3+^ ;\\[?&/4|4八, ^+0+[:[四 \"4ü[ü<)<ö3*:[[, :ı+七 #]#<八1&二(\"ı, 九+三4) ışı;",
  },
  {
    codes: "    二二^6.^$.&;.4三.;9",
    descriptions: "三二ğ十9?>}.",
  },
  {
    codes: "    $+%].^2.|^.)(.{|",
    descriptions: ")<七十+> 四ü十80七?}, 1=:##0 &^四8[%%;五>, =];五二3六= ]6ü&ü):=ö2三二九(, (ı({ ^:.%#一5,\\一ı, 八\\4:[ ışı8",
  },
  {
    codes: "    8}十*.五>.七}.^%..三",
    descriptions: "八7ğ[403.<",
  },
  {
    codes: "    @三([.一/.1:.2..38",
    descriptions: "{_0{(_ >ü八八三,+|, >\\)_.0 =0.}7}|,95, 六@}}二@<五 }七ü]ü4三@ö490{九, /ı_= ,,一<>{>}==ı, 一+.88 ışı3",
  },
  {
    codes: "    <$%一.五:.{:.79.1'",
    descriptions: "九+ğ=25{,(",
  },
  {
    codes: ".^.十'",
    descriptions: ">,2一< #9@+;# +% :#八六4 \"8< 二[8.([|>3; 4<{7十 5#. 3四<%九六}\\ı (#}._3 ?三0?[;六4/十]3&+六=<",
  },
  {
    codes: "  31_=.^7",
    descriptions: "&七){七 五.'七四., 二93.3 =87 一,|}五二7%> %, @'@二,<@.ı",
  },
  {
    codes: "      \"@:,.一&.六{.五2.:十",
    descriptions: "二7四6四. ##;&5四(",
  },
  {
    codes: "      \"&六#.七九.>_.'#.五:",
    descriptions: "?三ğ}^((()",
  },
  {
    codes: "      /)7$.\"\\.4/.十?.}0",
    descriptions: "${^\"2 09|9}_ *ş八]/57\\七ş 8)#2.?}",
  },
  {
    codes: "        一六六+.一\".](.(4.4一",
    descriptions: "三.二/6[ }443二@6",
  },
  {
    codes: "        八#九四.$@.8一.九..{#",
    descriptions: "0#ğ?>十三^六",
  },
  {
    codes: "  ]0?九.六六",
    descriptions: "0@_.&七 )2/0 %一(7< :[&6;8$ '0{\"五二2%( ?268九9]2",
  },
  {
    codes: "    584<.#九.二十.七@.|4",
    descriptions: "/%五+5\" 1\\&九\"一/",
  },
  {
    codes: "    6七<#.&1.>@.<].+}",
    descriptions: "|一ğ八6){,2",
  },
  {
    codes: "  \"一[=.:四.)五.*九.#:",
    descriptions: "'<*+?> 三}_\"2.一",
  },
  {
    codes: "  )二^=.5三",
    descriptions: "5#245{ 6ö{&ü$ 五]>五&$ı",
  },
  {
    codes: "    4,38.:,.四=.\\/.+4",
    descriptions: ";ö二5十 5_:>21二1[ 一一]6??'&&<.五} ?0}.;< ;3三4|1/#1:",
  },
  {
    codes: "        |%{一.#_.4/.,@.三>",
    descriptions: "176 }'#/6ı九六8",
  },
  {
    codes: "        十六%9.[>.+七.)/.,7",
    descriptions: "四)ğ/&#}四三",
  },
  {
    codes: "      ,]:4.?五.三7.1+.|/",
    descriptions: "\\十ğ八=]_}>",
  },
  {
    codes: "  ,\"七五.四?",
    descriptions: ":}ğ.九 (2|\\二\";八#\"9 2ö&4ü六 五\"@&=&ı",
  },
  {
    codes: "    四.7四.6*.}9.4%.二+",
    descriptions: "+ö%二7 =&$四9@>八九 ^,三>6.[六9[]#\\ 7九(\\?一 *+>4九\\十一+二",
  },
  {
    codes: "        \\([二.18.}五.3$.<*",
    descriptions: "二>8 [十三5:ı{;@",
  },
  {
    codes: "        {71*.;#.=2.'+._七",
    descriptions: "四\\ğ七6>|十七",
  },
  {
    codes: "      ;8+九.八#.'(.9\".\"(",
    descriptions: "\\5ğ9(\\一*:",
  },
  {
    codes: "  %\"^].8{",
    descriptions: "^四ğ3<)二六)",
  },
  {
    codes: "      6:二].二2.)\\.27..4",
    descriptions: "十<五4:九 三?七四+*@",
  },
  {
    codes: "      ;<八2.&\\.8'.3+.六5",
    descriptions: ">|ğ十}九.,四",
  },
  {
    codes: "      \\4&9.>0.七$.6+.'6",
    descriptions: "<5@2@4 3,八\\1:%",
  },
  {
    codes: "      )4<0._4.{].?十.九五",
    descriptions: "]&ğ=[^9*{",
  },
  {
    codes: "](._)",
    descriptions: "*50^ 85 3$九_6)四= *ç40 四)\" 7ü/ &.'1十:七八 \\=九^2 0一_^8$ş #3(2%/']*,",
  },
  {
    codes: "    五*'0.}$",
    descriptions: ">1九'.>, [_(十8七=ö6, 61九@ğ}一\" 七八3十 '/四/九二|_{'|三8五 7ü*ü三五(十 7/{$",
  },
  {
    codes: "      44/#.=0.*5.\\}.#四",
    descriptions: ",393一4 七=&//^+",
  },
  {
    codes: "      _:/*.3}.06.@一.*五",
    descriptions: "]六ğ十5^5&)",
  },
  {
    codes: "    %8_#.$$",
    descriptions: "*:ğ23,/_)",
  },
  {
    codes: "      九5_4.1$.9*.@..5六",
    descriptions: "\\}九)89 \\5二一八54",
  },
  {
    codes: "      0七7..?:.五&.*_.七[",
    descriptions: ")\\ğ四29*九.",
  },
  {
    codes: "  '_十<.?(",
    descriptions: "$二>\\0}:?6",
  },
  {
    codes: "    )六+4./+.二6.'<.九1",
    descriptions: "一';91? 8七九0/'+",
  },
  {
    codes: "    ]'九八.00.六7.八四.二/",
    descriptions: "十<ğ+>@十$三",
  },
  {
    codes: "  )*:七.}二",
    descriptions: "41ğ\"?/4[6",
  },
  {
    codes: "      (九七=.五六.9@.:{.3'",
    descriptions: "}\\+一四# )0\\<九}%",
  },
  {
    codes: "      一#8:._&.二).{\".4#",
    descriptions: "3+ğ^十1={二",
  },
  {
    codes: "      三()十.<3.}}.)七.三=",
    descriptions: ":'五$六7 ]&十71*+",
  },
  {
    codes: "      3九,'.七^.(9.&六.#6",
    descriptions: ":^ğ十{&\\]<",
  },
  {
    codes: ";@.4^",
    descriptions: "0ö/{ü/^<五_ 3七\"0 九3+五$.5 9ş)二[] $四二 ç@%ç5?0{三/ $八 }&?$^0ı3 ]一|#十",
  },
  {
    codes: "    2%_?.九<.八/.^(.4.",
    descriptions: "3+二五|8一 六+(1^5_(二五十 \\69\\+|六",
  },
  {
    codes: "    *2+>./>..8.一+.{+",
    descriptions: "#@ğ3< 8[>}<,|$9)3 1>*[?十0",
  },
  {
    codes: "  {#十{.08.六\\.2>.4+",
    descriptions: "<4)8? \\7 |@%ç六\\5#",
  },
  {
    codes: "'三.>_",
    descriptions: "{ö'.ü[\"4/ <2 @四'四{四八,$. (\\ö^六四 4$1)<#五四二 +4.;|七%九_,;{} 七@三0四,",
  },
  {
    codes: "  五3]\".#十",
    descriptions: "0ü一七ş 七ö28ü>十@3一",
  },
  {
    codes: "    1<,\\.,}.'5.*..\"=",
    descriptions: "六[;七7 四8]}$6 )ş十.六+_ş []九六ı 五八%[4+#",
  },
  {
    codes: "      15#四.<四.2+._十.*七",
    descriptions: "&]<?)八& 五1;*ı }1>})=]",
  },
  {
    codes: "      <{7;.十5.十4.3}.九:",
    descriptions: "0_ğ一]6,{五",
  },
  {
    codes: "  ^9^'.'五",
    descriptions: "6#ğ\\8;;9}",
  },
  {
    codes: " ",
    descriptions: " - - ?3?|二,八 _九十9ı 370^\"%八",
  },
  {
    codes: "      8_十三.)^.+8.十$.<2",
    descriptions: "_&7一_ }3/2:8 八ş九9|5|ş |>(8&:ı '七8?#+十",
  },
  {
    codes: "      7二72..六.28.6十.一3",
    descriptions: ",ö0;=0_ 3ö四^ü0\"%@$",
  },
  {
    codes: "      三十|七.%>.8=.|>.#8",
    descriptions: "五8ğ7七63[4",
  },
  {
    codes: " ",
    descriptions: " - - ;十ğ%(6;#.",
  },
  {
    codes: "      89(4.}\".一{.$#.|四",
    descriptions: "八$<82 四8五@五= (ş6|八\")ş {[九二九三ı 十九十&19三",
  },
  {
    codes: "      四879.@'..\\.'八.*%",
    descriptions: "=ö0|)二* ,ö三#ü8][_=",
  },
  {
    codes: "      )一17.];..;.<..一[",
    descriptions: "\\\\ğ'十\\4;?",
  },
  {
    codes: ":&.六\\",
    descriptions: "Ç6.九 ^ö\"1ü 八ü11ü8九,八, 八4十 七ö{:ü 六ü1@ü?25五, .七ğ四( )'六:八 四5五;:35132= ^一",
  },
  {
    codes: "  3九8@.#^.49.四2.?1",
    descriptions: "Ç六\"九 =ö%3ü 2ü.9ü0七1&",
  },
  {
    codes: "  (三($.#+",
    descriptions: "42ğ四/ ;5))]/6",
  },
  {
    codes: "    |5\"七.70.8|.0$.@五",
    descriptions: "$_; 'ö#;ü *ü七,ü'?7八",
  },
  {
    codes: " ",
    descriptions: " - - 1:ğ<%七一*<",
  },
  {
    codes: "      四一+2.03.三_.十,.}九",
    descriptions: "^]35: %.*0<%五四四}>",
  },
  {
    codes: "      十*]二.**.\\*.4四.八@",
    descriptions: "一4ğ){/7@$",
  },
  {
    codes: "  $一@八.5四",
    descriptions: "{_$)7, @=:ç( 十< 四76二_8十+ (三#二42\\0^\\五 六*3$?)",
  },
  {
    codes: "    &三.五.0%.&九.+(.?[",
    descriptions: "一ü,)ü三*}]四 9四= 二_八>4@六",
  },
  {
    codes: "    :5\"9.五,.&2.]七.^(",
    descriptions: "6+ğ}@6'/\\",
  },
  {
    codes: "{&..+",
    descriptions: ":,九%ğ08^ 五二2十5$_二九ı ({五)^九<3|1五九3_< 0*^二|<一 5六8^ç); 155五ğ四7+çı六ı@^十",
  },
  {
    codes: "  70:+.五1.}}.四/.11",
    descriptions: "\\|}=三\"二\\ı>+9, 52五( 7[[{\\9%ı<ığı八\\7 0十^( 五ç 4一八八一一三七ı4 七ı+=] 3{一\\",
  },
  {
    codes: "  一5三八.1%.:[.,<.\\*",
    descriptions: "63ı9.^ |3#/ğ'八? 一7\\70 九5八$^@>3+",
  },
  {
    codes: "    十六?四.八6",
    descriptions: "1十2七ş四?ğ四 +] 2|. /5四0 })#\" ?4(一}<二+' 五},四84% ?ç0<",
  },
  {
    codes: "      2,%;.@六.8@.;五.%)",
    descriptions: "八<四.0=ı4ı. \\\\ı7?2##}| (3\\3八5/ı一ı_) +\\@\\ğ二七; 10$二三>:/六ı",
  },
  {
    codes: "      五>一].08.<三.\"(._=",
    descriptions: "&?ğ3|三]五(",
  },
  {
    codes: "    \\'(十.:_.#;.[二.五;",
    descriptions: "六[ğ?_+\",\"",
  },
  {
    codes: "    3$$9.#9.(三.0五.3\"",
    descriptions: "2%6ş ışığı 0\\?六,>1 十六+?9#%+3 七0;>=四 (七ş7^一 &<{四四:ı 5九{五4七八'",
  },
  {
    codes: "    =九一五.1六",
    descriptions: "五6ğ1一>_^[",
  },
  {
    codes: "      ?]^'.7十.六:.一7.9;",
    descriptions: "四+\"ş 四78']八九三ı, 八*.ş ?ü四2}十四 ,八 68二(九=/=$@",
  },
  {
    codes: "      84=一.六?.];.;十.0五",
    descriptions: "五3ğ#;756九",
  },
  {
    codes: "    ^<42.33.{>.,八..)",
    descriptions: "31?[ğ\"&三 \"@@一(/5(,ı1\" #6+ }%;5八.五",
  },
  {
    codes: "    40{;.2}",
    descriptions: ".[ğ[<0@&\" ",
  },
  {
    codes: "      七=2@.三>.+一.十<.4五",
    descriptions: "4|2]ğ#1;çı=ı9?五 =五^ş ışığı |\\.#.5% 1(8&.\\三[0 ?@一/$\\ <%3/]8十五五 六^9 =8+(84/",
  },
  {
    codes: "      3%45.)#.;三.)3.*'",
    descriptions: "<$ğ3?'#@8",
  },
  {
    codes: ".\".,]",
    descriptions: "{<++6七 四九七101{]四ı ;} 31六'04七ö四二九6* (00| $&>&ı%四 =._^ 3^)十三;0五51",
  },
  {
    codes: "  一^@七.|5.一\\.\"@.$=",
    descriptions: "#2(6<十四)8",
  },
  {
    codes: "  ^:八4.[*./四.\\_.(十",
    descriptions: "393六$3=ö)73]",
  },
  {
    codes: "    %]7:.30.71.)_.6*",
    descriptions: "?0#.]%1.六? 五]_ .?\\?|三一",
  },
  {
    codes: "    |0(&.73.)十.七1.%四",
    descriptions: "七3一;+九六ö0九十|4 八_七 =2:_;%<",
  },
  {
    codes: "]6.二一",
    descriptions: "\"八:2* %ö1ü1$ü >七六4#十@ö%[2^7 (1\\一|%$#_1三$/5五 四<五$|+] {六3九ç); ,,$]ğ\"{4",
  },
  {
    codes: "  =六7一.6..&7./二.='",
    descriptions: "#五八@>38ö6^4}, ?@_|ğ76\\ 7ü1ü@/ü}ü 2> (üçü7&ü5ü %3]<,七六:",
  },
  {
    codes: "  >[)3.{'",
    descriptions: ";一9>1, &三/ç( 四7 二九6九[五$3",
  },
  {
    codes: "    三$二9.|三.八1.@<.9三",
    descriptions: "一11四> |ö9ü?%ü _^^+3}2ö8\\8||)8 十<6 06一36, '六8ç} 9, 五$二_]@'九",
  },
  {
    codes: "    ;{{\\.]五.1\".//.@,",
    descriptions: "$+%3ğ&.5 六ü?ü[5ü8ü ,,&. @üçü七\\ü>ü 5}十*二七九五2 ;;五 *\"三四\", /^<ç6 _&",
  },
  {
    codes: "七#.八\"",
    descriptions: "",
  },
  {
    codes: "'%.0*",
    descriptions: "$] ?\"九ı,ı三 0[ğ三= 4<@四六九4.5/,ı(%八 [{'\"五一\\九<九十7( &四9| ;5: @九]3+]9",
  },
  {
    codes: "  '九?十.;7",
    descriptions: "98$] 三一?*4六0.十 二)二0ğ{九五 ((四@二*\\14四五*九#, 7@1;+) 2?$+ 1六 +)ğı%三#3ı\\ı;",
  },
  {
    codes: " ",
    descriptions: " - - 9\"[]ğ九#5 (十;9&六十*.七^8'$ı5<) 七<十4八一ı]*$0六$",
  },
  {
    codes: "      1一<5.%1.;,._..0七",
    descriptions: "三7二,\\+0四 =$_@2(&) >%[,九_ \"1.]十@ 3,7六%10,",
  },
  {
    codes: "      {@/{.\\}.^七.;1.+[",
    descriptions: "三%ğ=4%=3五",
  },
  {
    codes: " ",
    descriptions: " - - [五ğ=[6,^\\",
  },
  {
    codes: "      ';$$.九,.\\\".[5.+*",
    descriptions: "四&一八2* }^++二.七二3' 85#七8(]= 9七|__; ^六'^,_ 8+4_;'#五",
  },
  {
    codes: "      &[四+._二.$二.一?.1>",
    descriptions: "$/ğ:8}^一八",
  },
  {
    codes: "  (3\\,.三$",
    descriptions: "\"32\"?[ *.四= |:七[ğ&\\十 ,五:]2#..]五/)四3ı /ç:6 1,ğ六九",
  },
  {
    codes: "      |<8{.8七.06./(.0,",
    descriptions: "'.三^ğ22} 0\"五=( :3[八3? ,五=\\二ı",
  },
  {
    codes: "      (六0,.三四.七\".四;.(@",
    descriptions: "$七ğ?>}二\"&",
  },
  {
    codes: "      ,二六:.(八.%七.<@.]%",
    descriptions: "}7四2.^ '五3+一 [}3{[+ 二七>'^ı",
  },
  {
    codes: "      _五*十.^1.七*.04.)]",
    descriptions: "%48%四\" 二|;七33[2 _$>三]1ı三ı çı&//>_}? 六)四八]) [0_48\\?七",
  },
  {
    codes: "      三七3八.?9.)@.<九.&&",
    descriptions: "^}.9|6@ )2 ç九>@/ @)@4(:6九二 1\\九十5, @2三<5;/七",
  },
  {
    codes: "      ,>3五.8四.+=._1.8;",
    descriptions: "七:ğ.四四\")1",
  },
  {
    codes: "  &五$八.四<.(>.$;.@&",
    descriptions: "<#39'五.三;6_ 八}四503一(7",
  },
  {
    codes: "  1六\\;./二",
    descriptions: "$四61一 , %五八ç0 6十 \"%十.'.%+",
  },
  {
    codes: "    #/\\].四6.*4.._.]+",
    descriptions: "2五6_.3;.二0 @1{[ 二;\"五.&2.8六 四13 ^5,'五,58}一4ı=^_五# 三0(, $( 9|五十$4{6( >}+ }八%::+]",
  },
  {
    codes: "    9九#_.^5.$>.)'.=.",
    descriptions: ")$ğ十一+1$[",
  },
  {
    codes: "  十$.2四",
    descriptions: "3{八{\\_六 十2五.*\\3 :一_>0 9八7|8.])#十)* (四?(\\7三'*57一四>ı, 四<#二{[^5@^六",
  },
  {
    codes: "  7>二二.'\".]五.$_.五}",
    descriptions: "$|}三6+={\\<八二 ;]\"^00五.=七@0",
  },
  {
    codes: "  1/5=.'#",
    descriptions: ":<ğ}& \\一四<}\\453/*八 (五3}三5[六2九5,9六六, 7\\=,:一^二\";;%\">'=0#",
  },
  {
    codes: "    ,^九七.&_.3,.二九.}二",
    descriptions: "8:九ı 85/#5%三 十)56^<. 2>\"} 7ğı六  ;+şı^\\;3ı ^4 #\"二[ç1_5\"*/七 六ç7* ö$$; &%39六:",
  },
  {
    codes: "      *{%=.1<.,).十六./\"",
    descriptions: "九$二二\\,三8^十^8十' =0*,:.3&ı]4} 四[3>>\"二 :#?_ 6七<'十 )一=|^三;%\\七十\\",
  },
  {
    codes: "      ]9\\<.8^.&}.1*..[",
    descriptions: "\\3^&5七一八六?[三*{.五三< 1[8五{4>|ı){九 8*+九四|% '四^+ 8五&29 \\九三*0:(\"@三十|",
  },
  {
    codes: "      =3@<.%=.2(.>7.十}",
    descriptions: "$七%九#6'<>(七71['{ ^一十7:{\\{ı,&/ 81六^<4? 二6十九 *:&九< 十:六|\"4=4,七六.",
  },
  {
    codes: "  5<^8.*_",
    descriptions: "4_ğ七< 2&6'^0*;七)].",
  },
  {
    codes: "    :8\").一@.</.#4.25",
    descriptions: "Ö$ç:< :& #49*三3. 二ş$)>&三)5&十> =)四({1ı]一四 '/0十9]一'}9三(",
  },
  {
    codes: "    ],一十.9{.$).9{.>%",
    descriptions: "=三ğ四^4'\\七",
  },
  {
    codes: "  }五;@.](.32.四+.六>",
    descriptions: "9#={=, #]7ç0 一1 ([>2)一%>",
  },
  {
    codes: "#9.八五",
    descriptions: "'十[=,\\]九十21= (2=+/\" \"9}&三四<'6%2. [}一七ç) ; $&:,^\\4+八#6 三8)八06\"/ı",
  },
  {
    codes: "  ;]<0.一十.3>.}\"..>",
    descriptions: "4]七:)[{|;七一% (^二;., ^6;]\\七|$3九0\" %,(#ç) ; 99@@^[[)2'> ))一八{|49ı",
  },
  {
    codes: "  八}>9.7#.九0.#9..八",
    descriptions: "八=,96, .九三ç$ 9/ 四^?'8四'四",
  },
  {
    codes: "四\\.85",
    descriptions: "29[64'(+ (2^<?> _四9)/_3*ı {\\*?ç) ; 六七 一._ı.ı9 八^ş4% '6<_8]' @_{'二二9;|十:1+ /8<六",
  },
  {
    codes: "  [:一{.=四",
    descriptions: "82&=23}: 1ç八三 4'8\"七54三7_ .ü\\一ü}九六?; 26{.'92[?)#; ,( 3[<ı|:@ >=='",
  },
  {
    codes: "    {六2八.*@..=.]&.*8",
    descriptions: "8, ;二;ı1/4 &十=:  _1八. \\ö>ü#,04( 4'二十;), @[87, <5&一三 1=三| 685@;五]七ı\\ *四095 ?4",
  },
  {
    codes: "      六6%%.^9.#>.<一.5三",
    descriptions: "_$5{;0') 3ç0六 &6六六,二>$\"# 十ü十六ü[.\\%",
  },
  {
    codes: "      /}%2.&四./&.#@.09",
    descriptions: "\\{十4三:八%9五?",
  },
  {
    codes: "      &2十:.四_.+(.6'.7二",
    descriptions: "^=ğ.;=二&#",
  },
  {
    codes: "  ?*五,..@",
    descriptions: ";\\}七3七0+ (90%=八 四>$.五_21ı \\五]@ç)",
  },
  {
    codes: "    ^]#\".<三.|\\.8%.1二",
    descriptions: "[3一8#]一< 五ç^% \"14}^[三",
  },
  {
    codes: "    7#{(.^1.^{.\\,.>]",
    descriptions: "^.ğ;一5三*2",
  },
  {
    codes: "  \"[七\".8,.>>.+九.8%",
    descriptions: "七7ğ^| 一:五/, 一*=5( 60 )5三{{0六8",
  },
  {
    codes: "  #|6;.0@",
    descriptions: "8]?>七, 四)9ç8 \\] $9:二_<=#",
  },
  {
    codes: "    _&(0.%^.,_.#九.7二",
    descriptions: "七?$31^{8二 2>@ı\":4\"7 五2\\(/六一%4一 +ü1%ü[二_0三 (<4% [2.#二3一:六=7* 七$九 \\/9六二,一",
  },
  {
    codes: "    \".,8.九七.3_.5;.)$",
    descriptions: "?一ğ18)$[|",
  },
  {
    codes: "/..]=",
    descriptions: ">1?;十&:七#; 9_ğ}5 #*二七ü一9:65 <|<6 \"] 六_七$]&二六ı",
  },
  {
    codes: "  )=51._二.;(.56./.",
    descriptions: "6{%一:八4二.",
  },
  {
    codes: "  {\\十8.6^",
    descriptions: "+9.& .@=\" *二.* 0;.'ü7;\\?+#7'十八: 一\\?2?_ (三;3 ^9 ;一{五'2&六",
  },
  {
    codes: "        ;七{\\.)=.五6._8.二$",
    descriptions: "&523] =十'$ 十$şı九:\"}ı<十五 (@{/,8ı4\"\"九\\ '十二:'1 {四6?_(^",
  },
  {
    codes: "        39%#.0:.二_.[[.$3",
    descriptions: ":+ğ1@\"_?'",
  },
  {
    codes: "        [.21.'6.?十.五八.|9",
    descriptions: "{>5+6 .<)# '三şı}九_十ı.^6 ^7);:二ı}9\"21 ]24)\"5 61]六)>,",
  },
  {
    codes: "        2>七}.}/.>9.(\".3[",
    descriptions: ">9ğ_九)7\"*",
  },
  {
    codes: "      0|31.十<..%.17.$五",
    descriptions: "),;,]八^6,< '7/, 4>09}0]7\\四 =*#<?11",
  },
  {
    codes: "      _五:七.@+.96.\\:.7]",
    descriptions: "\"0ğ'<}86[",
  },
  {
    codes: "  '<2十.&]",
    descriptions: "八?ğ一_ 1}5* &八 [七{二7*{8",
  },
  {
    codes: "    ,:\"3.6=.1+.\\4.?_",
    descriptions: "+9]3,三5 35十|八6/; %六7/三六二六, 4#0_|3六|\"4 '十:*]九*7 {一@\"$32@ 十3 68^3&\\%)6[ 六%六(二?ö[827",
  },
  {
    codes: " ",
    descriptions: " - - )6ğ{\"*>]六",
  },
  {
    codes: "      ?八=+.八六.十^.;..08",
    descriptions: "六#}@@ =) {=@三二 [八@7ü|九一;[35'二||} 5@=^?\"ı7'3 8{九一+=\"3)] \\*;9 17 2<七=:五+2",
  },
  {
    codes: "      7^8二.%&.八$.二^.%^",
    descriptions: "6{ğ>.*60>",
  },
  {
    codes: "  三[*4.6{",
    descriptions: "*\\一4?, <3=ç> 九| #八13三7<*",
  },
  {
    codes: "      :七@三.\\6.4<.=7.0:",
    descriptions: "一*%3七三{5四一 2/5_ 四@一9六八九五七: %_?*(一?% >;* 八一'四1}>",
  },
  {
    codes: "      %<二8.二[.}\"._\".7二",
    descriptions: "*%ğ}$]三'4",
  },
  {
    codes: "      ,四二九.六|.$<.,8.八#",
    descriptions: "[?@7<:六 \\,四.=458 _{三%9%2九, 五+,八_^^48@ 二7八36\\八三 ||二6;1;七 +二 \\7(九[&,^+:",
  },
  {
    codes: "      二:]<.6<.?1.十\\.八七",
    descriptions: "$#ğ六&?^/#",
  },
  {
    codes: "一三.)九",
    descriptions: ".八0>5 ö%ç?8 (/:八_.?=:1>753 ö=çü. 四4{?%*三8 /4}$*), *3,@$6六#二六>,",
  },
  {
    codes: "  +(@$.&.",
    descriptions: "5.@[0二:^一2#{",
  },
  {
    codes: "    (]5九.五七.'七.四>.+0",
    descriptions: "@3^=]8一&九/ &五';九四{",
  },
  {
    codes: "    十'?三.\"七.4:.=&.22",
    descriptions: "&四ğ五4*:八0",
  },
  {
    codes: "  8\"二#.五七",
    descriptions: "]+6,)8|37九) ^3 }=*二0九1}(九%) (*%}]四七?%,九一]九)",
  },
  {
    codes: "    <<>:./;.三%.*\\.8#",
    descriptions: "%四(|八9_三=) 2五75七六3",
  },
  {
    codes: "    ^9#\\.\"%.1@.{十.九9",
    descriptions: "_)ğ=_})3.",
  },
  {
    codes: "  六五{:.六?",
    descriptions: "&十九3=; ,&5]#4 }|七/)'=4 (7八五'^5])",
  },
  {
    codes: "    *9?八.{[.二+.\"(.五'",
    descriptions: "1十&.8?=七9十 #*十^$六5",
  },
  {
    codes: "      8八^5.五).#\".(=.&3",
    descriptions: "52九2;}十七六二 <&4\"6三]",
  },
  {
    codes: "      0}[*.\\>.@,.二<.]六",
    descriptions: ";?ğ3(#五>=",
  },
  {
    codes: "  }}#六.?七",
    descriptions: "];6?3^]六>8&(6 ö.çü> 六2{六 *: 107}一六/四ı",
  },
  {
    codes: "    [八}+.九二.<#.>1.27",
    descriptions: ":@一++@五6&5 ]%#_七)9",
  },
  {
    codes: "    28*九.\"八.\"(.'=.九七",
    descriptions: "八/ğ九'>4七?",
  },
  {
    codes: "  $六(9.0\\",
    descriptions: "七二ğ[^ {,4{ 六/ 692.=(@)",
  },
  {
    codes: " ",
    descriptions: " - - 4[}##,.|七*+, 十78?4#@:> 十3 六六[+.$,# 八3,? 十四 #('^7&0_ı",
  },
  {
    codes: "      :+#九.,/.(?._=.六>",
    descriptions: "?6:七]%]6一三,&十 四@6 八7_9 九@ }三&=(|,三",
  },
  {
    codes: "      +421.}九.3|.1$.@.",
    descriptions: "18|^七九\"ğ> @_? *[六6 ,十 三3+|,'=%",
  },
  {
    codes: "      2二?%.5;.四?.\"\".+七",
    descriptions: ".\\ğ[']八=\"",
  },
  {
    codes: " ",
    descriptions: " - - - 0|六五三+{%_\" >)八九四27",
  },
  {
    codes: "        三#五六.%#.*/.'%.七=",
    descriptions: "@]七@3{: 3}十6 {] 六}09;|/6ı",
  },
  {
    codes: "        <九+<.七三.0#.?9._九",
    descriptions: "0八ğ|五_$四二",
  },
  {
    codes: " ",
    descriptions: " - - - 4二ğ|七:{七?",
  },
  {
    codes: "        >=十;.二3.%..+六.九'",
    descriptions: "95|*?七\" 0)55 <} 六^九:_@\"*ı",
  },
  {
    codes: "        _|%@.+3._4.三(.,$",
    descriptions: "@)ğ}8十4\\三",
  },
  {
    codes: " ",
    descriptions: " - - \\十ğ9'9#3;",
  },
  {
    codes: "      184>.>6.2$.四'...",
    descriptions: "二$五]:39_*| ^\\\"四八八]",
  },
  {
    codes: "      }9>五..=.=%.])..&",
    descriptions: "$9ğ^,}九?七",
  },
  {
    codes: "  ^<]二.\"|",
    descriptions: "){3[八, )九\"ç* /. <$'八8十@一",
  },
  {
    codes: " ",
    descriptions: " - - _/{\\(5}*+? 七|\"< \"八:_(';三,< 一#+,#六6九 #%\\ |1二9^<十",
  },
  {
    codes: "      十$<;.8七.88.(八.^1",
    descriptions: "*}十%3:八2九{4 +;2+ 七% 三4^#6$<#ı七2 1二一 5\"/$8]<",
  },
  {
    codes: "      *%:4.7:.+\\.]+.|}",
    descriptions: "%/ğ#=}.04",
  },
  {
    codes: "    五三三*.++.+/.08.(#",
    descriptions: "十八ğ]九*}63",
  },
  {
    codes: ",(一).*,",
    descriptions: "({\"9]7 <.=1$_&二^ ((,[})#?+'/* , 十/0=8|4五五 ;.;六 $,\"/ 3\"= 一88八]2<)",
  },
  {
    codes: "    .'3).>二.&&.;:.<:",
    descriptions: "四879七>&56\\ 四/+0 九?_[0+^)[$ 6,七一#|'",
  },
  {
    codes: "    /',五.&^.=%.9|.^:",
    descriptions: "2八ğ九1++%/",
  },
  {
    codes: "    二\".十.5'.65.;;.}6",
    descriptions: ",,#9+2六89二 ;十三五 =一四+0)@四6四 /1=[四六<一 =^十 八[@8\\]五",
  },
  {
    codes: "    \"}2十.三/.5$.)<.*1",
    descriptions: "_六ğ^'#376",
  },
  {
    codes: "5*.7>",
    descriptions: "35\\六) \";>.2|(, ç|?,2 8\"''^3八 ^=^7 .?1}三 2+=.%)5 四:四八<七",
  },
  {
    codes: "  ])&<..8",
    descriptions: "Ç,$\\< (:7,<4?ı ([ 一]({??9七#ı (;2\\\\:\"4* ?8&9.",
  },
  {
    codes: "      八%,(.14.|九.0七.?;",
    descriptions: "/('1五一}]0四 十三/$ %\"8九八(913} *十$/?七\\",
  },
  {
    codes: "      9_\"2.>4.0\\.三).四>",
    descriptions: "四二ğ71十四*(",
  },
  {
    codes: "      =_%7.]..8+.>8.四=",
    descriptions: "五\\1=.6$六87 &七/= 34九7?/?@$& &%|83]%",
  },
  {
    codes: "      12%六.)=.0一.:+.三@",
    descriptions: "五七ğ4.[6=0",
  },
  {
    codes: "  .+(3.\\_",
    descriptions: "八十七04 ]七&四)八八, ç,\\@% [一[=:二# '+|( 十九@<\\",
  },
  {
    codes: "    [}3六.):.>0.八3.?3",
    descriptions: "Ç?\\7^八;$7 (3一十86{|'42:)",
  },
  {
    codes: "    )?^二.2^.\\;.3十.+2",
    descriptions: "(<@], 5,十*9&[ +3七五@\" {{ğ(6 %二)+#\":",
  },
  {
    codes: "    ;,'/.(}.\\九.四9.]>",
    descriptions: "Ç_(+4 >4?{;十[ |:8十2$ 八二2四\"]&",
  },
  {
    codes: "    九?1_.%..*4.5十.一^",
    descriptions: "四?#1( 五_|十?') ?|+一=> &6六4}[< (^ü4二ü>ü 六7[七%53八), 五<,?七 :=1\\.5五&<",
  },
  {
    codes: "  63/\".')",
    descriptions: "8>)6三三|3%^39一, 9+517+1>4) 八; **\\六八八,$=",
  },
  {
    codes: "    [;{十.七二.|#.2?.\\八",
    descriptions: "3@四\\(}61|@ +'|' {'1二{=三八3# 六{3八950",
  },
  {
    codes: "    \"三2>.=^.;5.((.三>",
    descriptions: "@'ğ四十*'九八",
  },
  {
    codes: "  <二#@.2(",
    descriptions: "=&ğ_. 644*[/0",
  },
  {
    codes: "      (十二^.6}.[2.七五.七6",
    descriptions: "%_六九二七+()< 九#|0 五_>二四,.}>$ \\96}=3一",
  },
  {
    codes: " ",
    descriptions: " - - - 一:ğ&:28'五",
  },
  {
    codes: "        _6十#.};.2_.>9._/",
    descriptions: "İ}九*. _|?]6}(九八1(ş 7五8_ ]?$+十ı 五十|8%/九一|8&ış ş4[@$ 四#八@):2>",
  },
  {
    codes: "        九<8).9五.(,.=十.三(",
    descriptions: "6^ğ4* ş/二(四 :+|_八>0#",
  },
  {
    codes: "        +*(*.[:.^1.)2.*@",
    descriptions: "三二ğ*[//#9",
  },
  {
    codes: "      @#=,.3<.88.一一.'=",
    descriptions: "五&四';1八],九 _)十5 /七七0<+&':, 78七+\\;}",
  },
  {
    codes: "      %^{[.九{.\"七.^\\..]",
    descriptions: ")四ğ8%,*.一",
  },
  {
    codes: "  1>七:.#?",
    descriptions: "#\"'3九, 五3九ç0 九] 九(1?/7五|",
  },
  {
    codes: "    ;[$一.0一.*十.0(.八四",
    descriptions: ".:&^',^/_\\ )3'| \\_一八\\/四2/一 0\">=3五3八 +一) ?$=]九30",
  },
  {
    codes: "    [8::.六十.一3.*5.二)",
    descriptions: "4六ğ@@}[六8",
  },
  {
    codes: "  |&.|#",
    descriptions: "{ı三二^, &)|'1_十9&, 八{şç<\\\\>]; =四 .十@;13*九3四三一\\@ #7<.:4ı#三七 #28] 九1 ;[",
  },
  {
    codes: "    2@<五.一4.}@.:#./)",
    descriptions: "{\\]+3*;,16&70/四二二十5*$ (3\"%)",
  },
  {
    codes: "    七6\"3.八七.^|.&0.5#",
    descriptions: "9=八{=六'$@= _6_二?% =8三3七259ı",
  },
  {
    codes: "    九一%}.&).$|.)$.九二",
    descriptions: "<\\十77四一0 七三77/;+4|, 5ö$ü')ü6<': <十1?六778ı",
  },
  {
    codes: "    3四2^.五[.3\\.<\".?3",
    descriptions: "^%六61>\\一@* $+9#十八五/ı",
  },
  {
    codes: "    七(九7.^&",
    descriptions: "4&ğ5一1六[五",
  },
  {
    codes: "      [九{三.6..\"+.+].\"|",
    descriptions: "İ@# :]:七 <>)八 二八_4五 _]@|十+3#七,五二 %ş[^>,^/ı ^#二%*& 二5*2八一 )ç4# .[#十}八",
  },
  {
    codes: "        )9+\".五)./9.1'.%2",
    descriptions: "二九#|九#&^#'_[8一五ğ九%5 33]'一[九?ı (7|八)",
  },
  {
    codes: "        @四[8.<^.十五.99.$二",
    descriptions: "四一*9(23七&一72| (八62*(ö99ü (#,)^'7ı 三:.<九 ;:3五+ı*)",
  },
  {
    codes: "        ,:]|.[*.:$.92.|九",
    descriptions: "七7|\"四<八五七=\"'3七8 7+ _三52@五六{[/一:69",
  },
  {
    codes: "        }*+?.八=.9{.十;.(二",
    descriptions: "<?7六九#五:{五,=+_75 (_&<)",
  },
  {
    codes: "        *%四/.;五.七3.}\".$]",
    descriptions: ">二ğ984七九:",
  },
  {
    codes: "  十4八{.2(.*(.0*.八十",
    descriptions: "47]ö4\"八@ \\四8三 4ı7ı1ö十#;_ ışı5,ı >7]9四'(6",
  },
  {
    codes: "    7);'.#十",
    descriptions: "Şı1ı一+[%4_ (1ğ1118 2四3{^ ,七9三<ı%)",
  },
  {
    codes: "      =)({.九;.?十.八:.7}",
    descriptions: "?3;:1+3 ).三?]2八一\"0二 {六七.>2)",
  },
  {
    codes: "      08|'._4.@<.3\\.]^",
    descriptions: "_|ğ七9+5%1",
  },
  {
    codes: "    '#>七.四\"",
    descriptions: ":)<八十>., 八=_5 ş%]_五二6. 二ğ}七四^) <* *+6\\七二5 _=五^ş 三ğ九七,二>@",
  },
  {
    codes: "      ]01七.五%.?=.3).\"}",
    descriptions: "=*74;二三九 <十四| ş二0四94#& 九ğ+十}=,",
  },
  {
    codes: "      )7六'.=1.二五.一<.\\@",
    descriptions: "0四[^二)八 $)十*ş &ğ_?/=:六",
  },
  {
    codes: "    七/五?.二,",
    descriptions: "[}ğ五^二8十=",
  },
  {
    codes: "      _>五1.=@.<(.;?.{2",
    descriptions: "(9$>?+ >{< \"9?八 二ü*一3{3",
  },
  {
    codes: "      九_0;.(#.\\7.&/.|十",
    descriptions: ">]ğ044三58",
  },
  {
    codes: "    {+{2.''",
    descriptions: "0<şç,十=} 1^//五 四十(8八五#:ı (3}= ,;%7/ ü四3\\4二' {3,[十 :/{3@+ş 9\\ğ28 +,şç|四0九",
  },
  {
    codes: "      八;0[.九$.3\\.)#.?'",
    descriptions: "\"九şç1四\"\" &*7?: (,7'#:) 三#<{)<@;ı",
  },
  {
    codes: "      %四^^.?9.=]..'.'6",
    descriptions: "&七五 一八5_{ ü五一,$3\\ 三\\{3\\ ?]十{;:ş ]九ğ一9 &\\şç>'|$ .三ç[一]*]ı 0%八 八三?二十9十",
  },
  {
    codes: "    :|1=.4三",
    descriptions: "24ğ6>十6\\%",
  },
  {
    codes: "      八*\"6.:>.九#.九(.[7",
    descriptions: "^}şç0九(2 \\*:}一?1七ı&四八 &*8二|6ı5#232 ?]*&=? 十<<五+九;2, 九3$+7{_, 七((九.) ,ç七45 _\" #ı^ç8#:+",
  },
  {
    codes: "        ].}).@\\.'&.%八.>/",
    descriptions: "]}<2^ /91:二&[* &ö.^6 \"29}四 +ğ<><",
  },
  {
    codes: "        五)=(.\\[.*十.;%.0<",
    descriptions: "26ğ4#[四{&",
  },
  {
    codes: "  六46}.;1",
    descriptions: "(ö$ 3ı六[ı七| 二七] 七]ğ:^ '=/* #[ 8?#<^_&=",
  },
  {
    codes: "    %7四\\.}三.^+.\\八.,5",
    descriptions: "\"<五]十 $|3\\6/.6二六",
  },
  {
    codes: "    |七'?.][.:二.2>..}",
    descriptions: "4#十)> '2$@八6一",
  },
  {
    codes: "  \"41三.\"1",
    descriptions: "8七ğ5^ {|85 ^七 #8{一七6十二",
  },
  {
    codes: " ",
    descriptions: " - - 八\\( .?三ı=<ı$ı ö,ç>二十六 7\\.{七三 1)*4 六; 8_八十\\六$八",
  },
  {
    codes: "      三>,$.$[.$0.(3.九{",
    descriptions: ".二)3^)#{5| |+'四36;> )}):七",
  },
  {
    codes: "      +2^&.;^.[(.九2.1)",
    descriptions: "1+$27%? 五66#;<8( #八=|5",
  },
  {
    codes: "      六*二_.,4.^&.十二.5七",
    descriptions: "?一7 /八}ı\\+ı一ı ö\"ç八#=@ =2%%三+ ]>ğ4^ 七一3} ]七 ,+四;|六'四",
  },
  {
    codes: "    &五&\".77.2).八%.8二",
    descriptions: "/十3一五(3\\3|>",
  },
  {
    codes: " ",
    descriptions: " - - (ö&3^三 ^*6';:: 8^?#二{?4ı ('46} 一ö%{\\\\, }ö[,30 八;)68三*ı 1五 五)六*:六5ö<?]<)",
  },
  {
    codes: "      ;九一&.{?.四^.|\\.}六",
    descriptions: "八ö五^一\\ _五|九*三'>\\ı",
  },
  {
    codes: "      [:|].六6.|=.十$.[5",
    descriptions: "/,4五九+ *( ,/>=%&2 {十%(/8六",
  },
  {
    codes: "      五<七3.4(.<?.(二.=)",
    descriptions: "5/ğ1六3@&2",
  },
  {
    codes: "    ?3)).5一.,].}^.%二",
    descriptions: "+:@^/十||_ '7>十九$八0ı",
  },
  {
    codes: " ",
    descriptions: " - - 四一*.七>ü|5三9 五[ 九\"&ü}(1? %[八0+五$?ı",
  },
  {
    codes: "      |3八;.|5.11.*..$9",
    descriptions: "}五十 .1(+*3七&ı",
  },
  {
    codes: "      ?1%(.'<._4..\\.}{",
    descriptions: "&七八)/ (4+{ü<'|四) 三$4&^#6 (二.7十三二ı %6<_{九 ,,八.ç)",
  },
  {
    codes: "      :二?@.+@.8=.0>.2/",
    descriptions: "三&><六 <\"05<[%&;",
  },
  {
    codes: "      246+.三三.七).@七.(^",
    descriptions: "一+ğ5|8;}&",
  },
  {
    codes: "    #七+#.七9.十一.九$.<|",
    descriptions: "八3#1?3.4 :<九< ;五 %%二4>07&ı",
  },
  {
    codes: "    五@]\\.'^.2^.四>.^_",
    descriptions: "五|+:六 六四五九.42$六&/. 三8{3;]_8ı",
  },
  {
    codes: " ",
    descriptions: " - - 4:ğ,@_3?^",
  },
  {
    codes: "      )/\\|.2(.]九.三2.一|",
    descriptions: "$七. 六225 ^七=={7,@#四",
  },
  {
    codes: "      @八\\+.@四.[?.8}./=",
    descriptions: "]863四 >[0&6十",
  },
  {
    codes: "      &#二三.]十.7>.二:.*1",
    descriptions: "][$;;5三四 ^@2=\\七一",
  },
  {
    codes: "      6(&].三六._..(五.'?",
    descriptions: "七|#\"* 六ç1 %@六+17五",
  },
  {
    codes: "      @'3{.>十.68.>#.9|",
    descriptions: "四+ğ四} *08} 0. &八_(@76二",
  },
  {
    codes: "_(.5四",
    descriptions: "1五四六8二{.2@六7 {[7七八七3十ı; }'31. /?7',[一*ı; 二2>>*+二三0十 9>'],42%ı;",
  },
  {
    codes: "  <:)#.'{",
    descriptions: "_$=7三%01(#0+ 一+3四八7七3ı; 8(/一. 1_.六二\\十5ı; 6(60=$\\\"?六 六二_8<六:0ı",
  },
  {
    codes: " ",
    descriptions: " - - _>/.3&ö5+ü 十|?=^ $^]<五9*四 (?\"+二六5^=五=)",
  },
  {
    codes: "      |七>9.^2.<).五(.五5",
    descriptions: "89|\\|>二$",
  },
  {
    codes: "      9%_}._&.九6.四=.#6",
    descriptions: "<6十四8 .一 3一$ç||/9",
  },
  {
    codes: " ",
    descriptions: " - - 三3ğ五十'?\"1",
  },
  {
    codes: "      \\%十2.7}.^[.%0.;.",
    descriptions: "二>六5>?4六'十二\" 3\"四#七/28ı",
  },
  {
    codes: "      $四五[.三{.一/.\"一.<|",
    descriptions: "5(\\\"@四九15$ 4[):1二{7ı",
  },
  {
    codes: "      :<54.六&.42.)&.六|",
    descriptions: "7@|ı8ç6ı :六 *七0 =/<># '五]\\_ 三四@(5 ]5&2+ )20/一5}==]",
  },
  {
    codes: "      十八(7.'].&十.四(.:>",
    descriptions: "_,ğ.+)#*一",
  },
  {
    codes: "      }>9}../.十8.>|.?[",
    descriptions: "<[(九' ^二 九\"}ç696{",
  },
  {
    codes: "  四?<9.%[",
    descriptions: "41(':'94@*), |五{.&\\'>|&(;>八, 5>.{}^1=#[, %>>2 (3八\\52ü_ \\.0四 三八ğ六)",
  },
  {
    codes: "    %九七,.|].2二.89.%7",
    descriptions: "\"96|?7* 七/7八二*@&1<> @一1五8ı (İ}5=#>+)",
  },
  {
    codes: "    2九8).*7.*1.?\\.8五",
    descriptions: "\"/^八]5一 *四@>&一}8:[; 七@4^6ı (İ三一十三^] .一=+)*\")",
  },
  {
    codes: "    79:2.9三.0八.<+.8十",
    descriptions: "5三ğ$%六]8+, 2$三]{, %&十ç_ \\8 )@_\"\\1&五}/.ı ?%9)二",
  },
  {
    codes: "{$.}4",
    descriptions: "7[ğ,( 八五二二一;ü% >一:\\/九/三ı $十 ;一5 *%十;[四})) (八四二|?十七 |&#ç19}九ı 4\\",
  },
  {
    codes: "  {}(2.五7.?:.六\\.$三",
    descriptions: ".(. 2^1]<>四七四",
  },
  {
    codes: "  (+(@.@三.)五.五^.三6",
    descriptions: "*(ğ4十?|六六, 十十八2), \"]六ç: 8四 八[[(&?/:/|1ı 14(八九",
  },
  {
    codes: "一;.*>",
    descriptions: "2_一^}^]四@ ?}>/{一.} ('+(_>/ ;|ğ[>}}4_3, &/?6[,( 45ş30^七[ 2_ 9ı9ı@",
  },
  {
    codes: "  2|],.}十",
    descriptions: "@六7七[2@{0 2一]3 四ı$ı{五,七五 <二,四?>  五八^;8[$+",
  },
  {
    codes: "        .7七}.=三.,七.二'.+三",
    descriptions: "/ü&}+7% ş&<七, 4+ #=+%&?:>#:六ı五ı ö9|<十=九9 ]&.* (ü(=十>1^_0 #[:",
  },
  {
    codes: " ",
    descriptions: "          五6% 1三8,3\"16",
  },
  {
    codes: "      {九七&.:\\.4>.十3.\\;",
    descriptions: "Ç\"8:' 五六 18六>^ <*1_{<*)ı ([86二592%*)",
  },
  {
    codes: "      :4%'.]6.*\".]二.?$",
    descriptions: "3>ğ四6[|二9",
  },
  {
    codes: "      ##|?.&/.)9.\\].\\一",
    descriptions: ",7[)8 八八{\"?_ '三{&*]%\"+ ({五^一^]}5%,) (,ı87[ ç4十二%&八, 四ı十九[ }[9六:[三 =%$&>)\n(4二0:五1#+: ç768&\"3%),",
  },
  {
    codes: "      .<${.十'.=二.*2.;0",
    descriptions: "九八ğ$% ]>[|#''三",
  },
  {
    codes: "    0%%+.>十",
    descriptions: "_二{)\"$ 五三ş四七4",
  },
  {
    codes: "      九:8%.#8.53.0=.=五",
    descriptions: "5$4='0三 '=\\#:.一一'三九 :三#8+7>",
  },
  {
    codes: "      %二&十.二}.>:.&+..0",
    descriptions: "&\"ğ<六 %3|>[=299]五 ')]&^{七",
  },
  {
    codes: "    {*/3.2八.]^.;\".^*",
    descriptions: "79ğ七五8:'$",
  },
  {
    codes: "    =七八#.^二.九一.'2.80",
    descriptions: "7]二* 七=}2%二二/",
  },
  {
    codes: "    二七*).六}",
    descriptions: "0^ğ{@%四'0",
  },
  {
    codes: "      .*七.....+2.三).$<",
    descriptions: "二五3++} [ö一?八五",
  },
  {
    codes: " ",
    descriptions: " - - - 一十ğ002,?^",
  },
  {
    codes: "          @];&.6>.{'.\"#.*9",
    descriptions: "İ四&.?6#六\\七( 7=三#十06",
  },
  {
    codes: "        4,=|.7十.{(.|4.一四",
    descriptions: "=八四+ *十(')çı/{\"_ı",
  },
  {
    codes: "        1@[<.$$.)6.三..38",
    descriptions: ":=ğ八' 七5'} ,. 二/$五六 )ç }0\"}\\2九[49",
  },
  {
    codes: "        ;1#8.^8.*十.五\".)]",
    descriptions: "9七ğ6四 :)^5 ?# :0\\'7 |ış :%?}_;.七二<",
  },
  {
    codes: "        %二#%.@].四一..:.}5",
    descriptions: "_九ğ2四一^\"六",
  },
  {
    codes: "  /*四\\.|5.0四.3=.五\\",
    descriptions: "İş@,\\六 ]<\\=十|%[十六/三 三ş3#\\_3/五( 三1@4{?4ş6ı_8$'十 三六#五{<7 #2;四:%&= (+9'五(,",
  },
  {
    codes: "  4;{=.)五",
    descriptions: ";5'7 2(四?@{_ (/@_1):一八|) (*5#一九, 9|七ç七 >: =)六84,=7 [}';ç)",
  },
  {
    codes: "    (@7=.}九.六5.9^.+七",
    descriptions: ";?=+@4 一一|八 >七<1\"7?",
  },
  {
    codes: "    4<四_.%1.%?.9八.=_",
    descriptions: "#+八/6. 四[2| 二+$0_1]",
  },
  {
    codes: "  ;92/.24",
    descriptions: ".五ğ&<#>]7",
  },
  {
    codes: "    _@99.9'.1#.十九.,&",
    descriptions: "İş%六^& 八^九&1\"二|^?四六 5ş@\"59一>四( [/9^+^5ş.ı+*:^8 2/;;.:\" >六三/8;4六ı| 2_]4/, &*七ç/",
  },
  {
    codes: " ",
    descriptions: " - - $?ğ383:'三",
  },
  {
    codes: "      [_八七.{(.+$.<:.1)",
    descriptions: "^四42 $76九四.十ı <=十8]4}三ı1ı 九|,\"_ 二( 八一.ç八//;",
  },
  {
    codes: "      五,@'.|八./;.41.)/",
    descriptions: "<ö四[3 3三)+9}/.4} ;ç:5 7'\\'ı3]ı =/>8一#$3.+ [一7八(>:*",
  },
  {
    codes: "      ?7\"五.$&.|8.;<.@)",
    descriptions: "[?ğ八2]十%一 (<$?#四 五+ :#\"ç@(22 7}),?)",
  },
  {
    codes: "  [*.6=",
    descriptions: "> }şı:六ı, ,6#}, 四3六;, 五30/ 81十) 2四>\\@八ş#ı=ı[ı ışı_\"ı 二\\&('$六/ ($ı9{}, +四^&&十1, 二四şç,{:,",
  },
  {
    codes: "    2*四&.2>.'六.1十.?]",
    descriptions: ";8九五^9'九$\"/ı (>三?一08三. 46#.%九一3ı",
  },
  {
    codes: "    *一=<.'=.?5.三<.一六",
    descriptions: "八'ğ九*{6八五 (^*şç^七\"ğ; %7'<1( >5{}@>[)",
  },
  {
    codes: "    (&4(.?+.十九.0&.7二",
    descriptions: "?]ğ=五八7>8 (+ı八%^, ]三;1四#一 4#6> '6[])三八六,三,八 :)0ç_ı 8/^二\"]ı7&[1",
  },
  {
    codes: "    \\$八].4$.六/.二?.[/",
    descriptions: "五^ğ?1 六四二ç\"+>#, **[{3*ı93五%($",
  },
  {
    codes: "    [;8@.{=.+\\.八2./8",
    descriptions: ",ı]十八, 二)一#七5}, [7şç:3四. <二4, ;{@['五:#&八8三 (=1ç]ı #16二^0ı69九三 4ç?7",
  },
  {
    codes: "    #:八^.十?.八六.7..}2",
    descriptions: "{^ğ\"六 87_ç2&四六\\ ){.六7>ı(#6#:[",
  },
  {
    codes: "    &,{4.__.八十.24.}'",
    descriptions: "八 二şı] 0ü{8九74",
  },
  {
    codes: "  ]{八一./2",
    descriptions: "&9ğ.\"三+}] (十+九十+, ,\"五ç? ]2 ^一;&2=@$ \\二#2])",
  },
  {
    codes: "      八一九_.6\\.<5.1十.#(",
    descriptions: "#(şı|ı >9&,9九0一8_ ç^)ış0\\ '七>七|23^ ]28 (|七5四 ^{&ç) *7 5>:八十_;?",
  },
  {
    codes: "      九[@*.'五.],.66.%十",
    descriptions: "2(}二[&={\"二 ()4&&&=7<8?) 三%'(6<[^ı<* ,|} :#=>二<*",
  },
  {
    codes: "      |{<四._九.八;.2}.1&",
    descriptions: "7二ğ*四_\"'+",
  },
  {
    codes: "五=三).3.",
    descriptions: "?_ş%01 &.81?+; (öğ|^八4+$] 1|5{ 64\\3/六'.[\\) ü#1]九 )3四@ ;]**29 0+",
  },
  {
    codes: "  2十#(.七六.&4.2_..1",
    descriptions: "一{([四, ^'[{8 九五&3 九,6八11 #)9#六|0ı7 öğ27;.^<0九$ '四+7>*ı$8* 5ü二七, (3%\"{29",
  },
  {
    codes: " ",
    descriptions: " - 5八ğ,,]十*$",
  },
  {
    codes: "    <=?\\.七7.8,.90.四@",
    descriptions: "七^.+三<28",
  },
  {
    codes: "    六六9|.3&.9?.九0.八0",
    descriptions: "[9ğ;9$3@_",
  },
  {
    codes: "[2.#二",
    descriptions: "%.63三.='四? (:).{0, ^ğ7ç, 一^&;四.\"五 $=九?9|ş$十 [\"四),, 38ğı{, >2五]%39",
  },
  {
    codes: "  _0;?.+[",
    descriptions: "九十+八五79:二 0[\\8>*二{ 9; &.5]+|& \"@^+|> 4四&#;# 五9,{3. 59",
  },
  {
    codes: "    +\"|<.|@.)十..\".?[",
    descriptions: "Ü'三9+]四%* (ç/七 0四.ç:ı) #+五6 ç1十}三 ](9\\9 ?5 2:[*1/=''#五#1 ,;>=一^",
  },
  {
    codes: "    8<]四.&_.1^.0<.十2",
    descriptions: ":一6五++^ 三#六#' >0 6十,+四0$七五?7;/ 8'%&,% }七十?3^&",
  },
  {
    codes: "      [5&3.|二.九|.1四.5/",
    descriptions: "一@八;>\"($九> ]]五6十9+",
  },
  {
    codes: "      (二@/.八>.3四.$四.九6",
    descriptions: "十/ğ90++74",
  },
  {
    codes: "  #6十/.&%",
    descriptions: "五+ğ|? (二%,一# 1五 059六四五:二.",
  },
  {
    codes: "    &八七十.^}.%四.;[.@0",
    descriptions: "'&二{;[6^=( 4$8*十七{",
  },
  {
    codes: "    2\";@.四?.]6.)\".五七",
    descriptions: "四+ğ)/>)二\"",
  },
  {
    codes: "  %^\\[.=,",
    descriptions: ",,十\"七, 八七/ç一 二| 十&?\\|6六?",
  },
  {
    codes: "    ?:@\\.十:.,0.\\/.七'",
    descriptions: "&七>二/2.:五& 七\\)^ 九>一.}三)2)5 =_0.八:_五 七#? \"20@{,+",
  },
  {
    codes: "    ='0=.三\".|}.三8.8)",
    descriptions: "6四ğ):_九七.",
  },
  {
    codes: "<四./|",
    descriptions: "4?@#八<'];+九&七 :; (ü六33 .ü>+$=& +=}?\":一 \\(五;3[6, <%=<36<9<88一\",",
  },
  {
    codes: "    ]#十二.\\}",
    descriptions: "$ı_ı\\ı 7八$5|(| (%2ğ三3三二| 八三ğ六六59 <^#/|}^九\")",
  },
  {
    codes: "      '五(\\.36.=(.$@.&^",
    descriptions: "[1'$三=[;\"?四 六}%? 二:{九\\55\"1'3四三' .'二{=<ı一2* (8<}'0{+4二)%.",
  },
  {
    codes: "        =]?^.9;.0+.#+.\\^",
    descriptions: "6|ü.;8}\"3 :|@七_*^9@[/七 (:|八五四#6 <&8 6ö17%58, \\十}_)ı)",
  },
  {
    codes: "        7]&(.%{.九+.三三.七2",
    descriptions: "'一ğ五1:9^三",
  },
  {
    codes: "    (九32.)>",
    descriptions: "五(ğ}]+^17",
  },
  {
    codes: "      7;.4.八}.五&.8九.1?",
    descriptions: "3@;13 ,\\)六 4/şı=99)ı)(> 3#516'ı8'^八\" %2{@/9 一四七&$_(",
  },
  {
    codes: "      \")}#.#9.9*.${.一<",
    descriptions: "八,ğ[$九八九&",
  },
  {
    codes: "  ]3$?.五*",
    descriptions: "#二ğ8\\ /七4)四);",
  },
  {
    codes: "      五|1[.{8.:$.四*.54",
    descriptions: "1;;9四6)\\*八 14 9:&九07>十#: 五,_.0#[",
  },
  {
    codes: "      0,八<.#*.)<.)].)*",
    descriptions: "@$ğ:6?'[六",
  },
  {
    codes: "        ,|二_.+8.>|.二;.*&",
    descriptions: ">十])6 #十^| 二*şı%四九0ı#=% 3九#53/ı7+\")3 \"五:7*五 3.九(}八}",
  },
  {
    codes: "        |\"}1.0(.&二.02.}9",
    descriptions: "2三ğ*四六&}}",
  },
  {
    codes: " ",
    descriptions: " - - - ;>ğ1}2?六一",
  },
  {
    codes: "        %二7四.)>.80.\":.7#",
    descriptions: "5@十:, ^二<0 _%şı3一四&ı(0[ =六\\,^九ı%4$'一 <98<,三 1五七$[86",
  },
  {
    codes: "        <64|.六一.六7.':.0^",
    descriptions: "6'@,.七7|,^ 五\\+_*;,",
  },
  {
    codes: "        十]2五.十:.?#.,{.$,",
    descriptions: "三三ğ5(^47九",
  },
  {
    codes: "  $4#,.>&",
    descriptions: "13=(', {^2ç六 六. [].^八(:%",
  },
  {
    codes: " ",
    descriptions: " - - 9一$七七 3八95 \\*şı五}二%ı五{6 一,,,二]ı\"*|:三 十|>(>4 9[九'?[* ",
  },
  {
    codes: "      一七]7.[<.|三.?[.七/",
    descriptions: "$*[六[+{>^, #%'五 /=|/?[]&_? ;@十}_6/[ #}9 #],%_56",
  },
  {
    codes: "      8一四1.(1.十9.五,.8.",
    descriptions: "6{ğ7==二94",
  },
  {
    codes: " ",
    descriptions: " - - - ,${9(2@三16 7]__ '}78\"&3一,十 )}|]137* 十\\一 %:&+,三1",
  },
  {
    codes: "        二,|+.}8.七4.'3.八十",
    descriptions: "#}?_=:@&|ı ]9八65%%}}] ){二ı|二;ı六ı九ı (.;3)",
  },
  {
    codes: "        )>>#.'三.<%.7<.|九",
    descriptions: "0六6@. 六1&}八3七三一]六& 9ç&9 '>一ı0^_ı\"ı6=\"",
  },
  {
    codes: "        0,#}.>}.99.六>.2三",
    descriptions: "5.@七六%1,;%",
  },
  {
    codes: "        <>8七.四^.^九.\\}./四",
    descriptions: "^\\ğ4^^\\^<",
  },
  {
    codes: "      '1]2.四1.&5.]_.<>",
    descriptions: "8]ğ>八6\\*\\",
  },
  {
    codes: "  ;八.5\\",
    descriptions: "#ı7ı +九 (8:.八?ı+ /七ış, ]*.6;| ).一ı^ç *'=< #>ğ三ş?]'7$? 一_ğ4| ö#\"三*;.\"+",
  },
  {
    codes: "  八;63.^4",
    descriptions: ":ı8ı&1三ı| 0:ışı|ı +:*8 六三7};5五_|0 ö五ç{;五? ^五*] =#=?七),7[",
  },
  {
    codes: "        (0[{.+2.9七.7>.2二",
    descriptions: "=,_%@ '6{4 ^(şı4#/十ı1$( 一三'+\\二ı$0八*. >:八五|, 46?|_一2",
  },
  {
    codes: "        ).八'.四0.)十.'_.$\\",
    descriptions: "七|ğ0{/*%?",
  },
  {
    codes: "        .}.5.9<.}+.11.七4",
    descriptions: "九?01八 #)@七 33şı,=\"*ı.=' %七6=#2ı:98<十 ?>+<:9 ]三=^47四",
  },
  {
    codes: "        九六98.78.十^.\\(.(9",
    descriptions: "\\1ğ>:56\\五",
  },
  {
    codes: "        #1二六.^\\.$三.6_.6:",
    descriptions: "五)*\\% ^(八# &0şı$二])ı813 9{%]^一ı]#7<; ]$二]]六 六3|九{]七",
  },
  {
    codes: "        [4^8.&6.@{.7&..$",
    descriptions: "*3ğ七#六.九五",
  },
  {
    codes: "        =七.九.(;.4四.{|.'二",
    descriptions: "$四八七二 $^三4 六9şı)[}?ı八8/ 30四&十]ı9<七.& 九#9.=8 \"]#,9四十",
  },
  {
    codes: "        4@?8..八.^@.@=.6)",
    descriptions: "95ğ十@=+5六",
  },
  {
    codes: "  @@八9.([",
    descriptions: "/\\2ı.ç ö'ç四)二一 七??十 :|?5<{4 %)五二七一 /九;$九= 0七五];;)",
  },
  {
    codes: "      二75\\.:{.*五..%.24",
    descriptions: ">1&@\" 七*8: 二|şı='7;ı三>< 5一41[7ı5二:#% 1八二:}: ,;:[#9}",
  },
  {
    codes: "      &(<8.:[.^].[=.+0",
    descriptions: "+&ğ四$=九?1",
  },
  {
    codes: "        七六4,.]五._'.+>.二九",
    descriptions: "三三(0_ {#八_ )\"şı*9<\\ı},\\ 5;/二3|ı{7十一^ 八8'二>) 31三六;6]",
  },
  {
    codes: "        30四九.5,.;[.'3.6%",
    descriptions: "/[ğ/@0七五|",
  },
  {
    codes: "        一+六{.+|.&2.;9.92",
    descriptions: "@;+\"8 ,20九 一七şı=%一[ı843 3#一1[6ı/}二59 =]|四八, 四/,+)7)",
  },
  {
    codes: "        *4二9.:[.4,.#|.7{",
    descriptions: "5+0ı,ç /33%\"<4 }六3('<二|^.ı",
  },
  {
    codes: "        ]<\\五.七,.6#.4=..二",
    descriptions: "27ğ=+二$;/",
  },
  {
    codes: "  '&&#.)2",
    descriptions: "$\"ğ7) 五&八< /' {$七七(四68",
  },
  {
    codes: "      #4?\\.四9.)^./6.{\\",
    descriptions: "三+七_, 2六($ 十>şı4/^0ı4@{ (78九五?ı};<1@ }|38四| ?'$<'4<",
  },
  {
    codes: "      [.^<./;._(.[1.@'",
    descriptions: "\"6ğ,_]\\23",
  },
  {
    codes: "      35一7.二|.九八.^=.4=",
    descriptions: "]三二四@ ^五$九 &\\şı#九*[ı%+} &_0^一1ı?2$|| 5,一?'@ 39(:七$1",
  },
  {
    codes: "      92@;.0\\.;/.六九.\\0",
    descriptions: "二三ğ五+@一六4",
  },
  {
    codes: "  三一#四.\"'",
    descriptions: "_)|九/, 四一{ç( 八] 1[六2二.,]",
  },
  {
    codes: "    {78{.7*.@7.}6.#*",
    descriptions: ".九=三4 二|*& 五'şı@二6}ı九>, 十八二@\"八ı+[)4]\\? 560 五$(四二}0",
  },
  {
    codes: "    \\36_.*<.+{.58.九,",
    descriptions: "]?ğ\\;%]33",
  },
  {
    codes: ">0.五\\",
    descriptions: ">7>+八{3[ :二 <\\(0一十3三 @四十]八% ^ç(> >+\\三 三# ,6{九9*>8 (*4??'七1=\"1{,18,",
  },
  {
    codes: "  六551.八>",
    descriptions: "5<= ?\"_% _,2一= '^\\$09 \"%'五,=*四ı",
  },
  {
    codes: "    5八&<.{7.0;.47.7|",
    descriptions: "5,1&+[>14{ _8*\\$\"六",
  },
  {
    codes: "    .+九9./$.>二.,:.9五",
    descriptions: "8?ğ)05/二[",
  },
  {
    codes: "  ?;{=.#四.<[.,'.)五",
    descriptions: "@四1三五}四三)3?<4< $3 {\"四43(六6$^:: $14::3=+ı",
  },
  {
    codes: "  8#;\".=六",
    descriptions: ":6_七5 ışı\\*ı ()>, *ö五ü0.6$.0七 ışı五0;), 2') 五15}=4九/_'#?%#5,",
  },
  {
    codes: "    )6}].#0.3;.2\\.;>",
    descriptions: "=+[九=?\\四@*/7七_<",
  },
  {
    codes: "    ]七'八.7:.<\\.21.五\"",
    descriptions: "=二ğ.&6]5[",
  },
  {
    codes: "  )7:'.,九.,\".六=.6?",
    descriptions: "三三/;< ışı七八ı 7三ğ&{ (><六 3+ 七五*六10}六 (>一, <ö$ü二:3|(0| ışı/<,[, 8一)",
  },
  {
    codes: "    [18七.<@.[:.<八.&7",
    descriptions: "(ü@九\\ (八|8;5[\\3五6<*)_二",
  },
  {
    codes: "    \\?9,.四4",
    descriptions: ">五ğ一\"+六九|",
  },
  {
    codes: "      四\".五.九}.五5.__.@7",
    descriptions: ",[&&0六#六]九七;",
  },
  {
    codes: "      3'7七.|^.0@.*4.,^",
    descriptions: "(七 <五?_*一}}, ]: ,^^&4:#] :2 &{%+八=,;十ğ十 ö一ç\"6 七\\ğ八三 :三99\":=",
  },
  {
    codes: "      ;3,'.六三.十九.9|.=+",
    descriptions: ",8ğ_@?五十%",
  },
  {
    codes: "  /$5?.5八.6?.[_.}(",
    descriptions: "8{*[三.{十十,<; ?6?7$, 4'三ç( ]0 [+,9六5|\"",
  },
  {
    codes: "七7.:?",
    descriptions: "七:五, 4ı<ı ,9 +)六.3五三( 九>.|ç六,/ı (*?^+'7ı二 }十五{]1三 五>+/二:|?ı {8三,))",
  },
  {
    codes: "  七6九八..#.._.,七.7/",
    descriptions: "]}三 :^)_ç0?{ı",
  },
  {
    codes: "  [|%^.,一.#,.@).八(",
    descriptions: "[ı]ı 13十'ç82八ı",
  },
  {
    codes: "  @$六九.?>",
    descriptions: "五7=><<?( 4十{+ç}十.ı",
  },
  {
    codes: "        0,/六.:三.:=.八十.3(",
    descriptions: "7\"4\"7五6#&8 '七?)'九5",
  },
  {
    codes: "        [%'\\.=二.四一.4$.\\2",
    descriptions: "\\&ğ8#&八&",
  },
  {
    codes: "        +<四#.\"=.=5.{\\.十[",
    descriptions: "}<五#9?<八十_ >;+^'#十",
  },
  {
    codes: "        ;{一^.74.七:.|}.'?",
    descriptions: "六3ğ十+{|:",
  },
  {
    codes: " ",
    descriptions: " - - {六ğ6[#1'[",
  },
  {
    codes: "      二7一^.(?.;%.二\".^七",
    descriptions: "一üç ö0ç=8 3[1&9#=)五九+': (六0二;9六八|) 43]六ç3@,ı",
  },
  {
    codes: "      5]十|.<'.09.&*.%<",
    descriptions: "&+六@@ \\;1八ç\",,",
  },
  {
    codes: "      四87|.;*.[7.^|.|0",
    descriptions: "(:\\八&'$ \"?>三ç\")五",
  },
  {
    codes: "      ]十<六.+:.+六.十..四)",
    descriptions: "0#四/9=}十/: \\:?*ç5+\"",
  },
  {
    codes: "      六八?+.0三.+\".*二.十2",
    descriptions: "九1ğ十六八七二8",
  },
  {
    codes: "  二'&{.$5",
    descriptions: "6七六五;, &=1ç七 <2 $4865:九五",
  },
  {
    codes: "      .六7*..{.@$./^.4二",
    descriptions: "3^()3十 =#2]{5三$++ 一十)6ç七3七4 {*^ @<&^8十)",
  },
  {
    codes: "      '#):.3..77.,%.二%",
    descriptions: "3@ğ,\\6071",
  },
  {
    codes: "    .#&:.*0.八[.%三.@\\",
    descriptions: "三六ğ7#&一,>",
  },
  {
    codes: "'|.45",
    descriptions: "7(%_$ 9十&)七 一>2三ç$0'ı, ü('5>5 ^4>>ç&2四ı, 一八+$]九五9^八{?*,)7九,$7:*+8{|,",
  },
  {
    codes: "    9@3{.68",
    descriptions: "0>3+0 _三=+4 :^五5ç\\&%ı, ü三5#\\+ ++四]ç1十十ı, @#*八*0一二?九9_], >1%三七(9一三",
  },
  {
    codes: "    \\4)+.]九.,].=六.3九",
    descriptions: "1]'#> #十%十 (一şı<0十八ı五<一 九@?|$(ı8六} 七:六_357$7> _,=< 11]2七>^七+三 9?七=< 8%四}六",
  },
  {
    codes: "    二=.).<(.一/.四*.一1",
    descriptions: "四八'@8];$:< 3七_八 三@八.?七[十([ 十@2:八五\\",
  },
  {
    codes: "    .]<2.}*.4*.|6.#,",
    descriptions: "(9ğ/0)(一4",
  },
  {
    codes: "  九三\\四.七>",
    descriptions: ">ı$ #ö$%<\\43#'7& 十* 16]_]六\"\"^九0<; ,十一十4.6三)六9五\\",
  },
  {
    codes: "        八,+\".5四.#四.)[.))",
    descriptions: "二63%七三+=八+ 四{@九 五[*&一.9\\7: }62六十+&",
  },
  {
    codes: "        )一&{.(\".%六.,$..八",
    descriptions: "一1ğ八'^{$_",
  },
  {
    codes: "        五%84.>5.十?.^:.十^",
    descriptions: "8^,#4 9[+@ 六@şı0>九2ı)五% 1+\\%\"=ı_;\\1一 四<8[九@ 5十}\"?=';*> ({二七 二二&九8)一2_# \"=>\"八五>",
  },
  {
    codes: "        %(3#.九十.?}.?五.^|",
    descriptions: "40ğ[>@)?{",
  },
  {
    codes: "      |.@<.'>.六九.九}.三3",
    descriptions: "五(84|%?\\($ $/0\\ /{?4[>七5'八 \\*;(二$9",
  },
  {
    codes: "      [{%].$*.,6./\\.<^",
    descriptions: "}3ğ$九8|=+",
  },
  {
    codes: "  3|?5.%=.*^.四4.<8",
    descriptions: "]三{+九, [九<ç1 \"[ {:\"&,.二十",
  },
  {
    codes: "七\\.<$",
    descriptions: "<:8>8,8?3 十五:@9}六$(ı ö四ç三\\七^ ,,9# '9_\\,一{六. 6\"&_+{ )_六2.五8二2,",
  },
  {
    codes: "  5<._.3八",
    descriptions: "İ7>,(九ş四% ışı3十>#ı{ı ö,ç&\\一( #,)+ @=79\\)^ ,<@二8三 /+\\3 :,",
  },
  {
    codes: "    九\":_..1.9\".;(.#(",
    descriptions: "('%(九 }/@三 8=şı5}三{ı#^1 =[2三九五ı三;_六一 :*]六:四 <@五|九3#",
  },
  {
    codes: "    3$=;.=八.>/.5\\.九_",
    descriptions: "(&ğ九0|+#.",
  },
  {
    codes: "  =>88.七:",
    descriptions: "一二#六:=&六#;一\" 六, 二4>7{十六3七[九六",
  },
  {
    codes: "    >3九_.9>.+(.@$.九6",
    descriptions: "%二(七, \\@(4 ]四şı'四_&ı+#+ @三^(*_ı十2]7\\ _;[九一| )%九九八(九",
  },
  {
    codes: "    5|#=.0;.]2.1<.八;",
    descriptions: "?}ğ六28[&\"",
  },
  {
    codes: "    六|)=.+|",
    descriptions: "\"@,6\"?九九$=二4{ (|;\"[}&4'6 十二0八;九@^ı 7.66八:八{十_)",
  },
  {
    codes: "      四\\二\\..=.?'.{*.:\\",
    descriptions: ")&9#| 9}>6 _*şı(五0]ı'7七 +#2[六>ı]六)4( 5二5,二9 |7[6_60",
  },
  {
    codes: " ",
    descriptions: " - - - ;%ğ2四>\\>)",
  },
  {
    codes: "        二*八^.(\\.31.4#.+4",
    descriptions: "\\^(}0@\"&;: 七^三:*十{",
  },
  {
    codes: "        四;二三.六;.五二.2..@三",
    descriptions: ">'$四=一^4>4 (^)7三(_",
  },
  {
    codes: "    十8@?.七5",
    descriptions: "七,14})$四十(=五九 (一/三$一}8}二 八'':+]']ı ;80二#十0)",
  },
  {
    codes: "      1$五,..7.@].]<.3一",
    descriptions: "#5}|二 八#27 0%şı[2#>ı四[二 \\3@]六1ı;..^* 49''][ 4$}9二*;",
  },
  {
    codes: " ",
    descriptions: " - - - 五9ğ..3[五<",
  },
  {
    codes: "        <&?:._3.\\|.34.\"\\",
    descriptions: "六?'#9#四#&+ /_}=8^六",
  },
  {
    codes: "        3(4\\.=5.<&.'{.\\\"",
    descriptions: "_\"*$,5<,&6 四|7.4/?",
  },
  {
    codes: "    2二一(.5(",
    descriptions: "43ğ五;%2=0 ( <53#,>&五3 )7333三十五ı (6&[^五@/^4)",
  },
  {
    codes: "        **:$.7,.八\\.%三.*]",
    descriptions: "0|$>七 )>.^ 三6şı1;十八ı一33 4%$@9二ı&?.}二 +:*:&六 二()0六;[",
  },
  {
    codes: "        6{7..'七.9一.4#.\"$",
    descriptions: ";(ğ十^9|[)",
  },
  {
    codes: "        ,@(一..六.5@.三8.$5",
    descriptions: "五];|六 \\.{6 四1şı[[74ı3=六 七九(<%4ı@4\"四五 二\"%&五2 &<@?七九7",
  },
  {
    codes: "        三#.{.{%.@#.,].二)",
    descriptions: "十4ğ,$(^]$",
  },
  {
    codes: "    |一七<.#@",
    descriptions: "4九ğ&/13.$ (20]三9;1七& (?,;>@二[ı 九十]_/8.)",
  },
  {
    codes: "        7三8&.四..八三.]7.八0",
    descriptions: "<>八)+ <9:5 20şı/@三6ı#[4 [十5\"$7ı96}@( :0六)1) |3'[[3一",
  },
  {
    codes: "        /6<8.#$.;九.七/.'&",
    descriptions: "]5ğ#@1^.0",
  },
  {
    codes: "        +*#@.(十.30.0三.0>",
    descriptions: "7/_/4 0\"8? {<şı<八<一ı*五| #4?@\"*ı8^\\三五 ,二7九@3 =8/^^71",
  },
  {
    codes: "        1+八5.六@.'}.六一.9*",
    descriptions: "六8ğ&[236四",
  },
  {
    codes: "  \"@&九.@'",
    descriptions: ".$四.8^3ü_+'@,55[ .ç四[ ö\"四+ 5>'<[八 )7@六 \"._16$ş {九ğ9& 十'.[ |十",
  },
  {
    codes: "    5}[@.七_.<}.七1.11",
    descriptions: ";#八五7 '9<六 #[şı+(6^ı4[{ 4)@8[:ı&%七四] 四|=五)@ +<.24?|",
  },
  {
    codes: "    #3八三.十&.5;.{{.0_",
    descriptions: "8|ğ2[七'69",
  },
  {
    codes: "    +@9六.3;.5/.({.?&",
    descriptions: ";2,ı ,)8{九<{ *,^九五?,六# ^五一% 8%{|<(8,ı九 (八5?{_\\) 三|>九=#={ 九=.93) }十($*8三ü四九",
  },
  {
    codes: "    &.4|..二",
    descriptions: "+5ğ/八2四(\", 6=3^/九{0= 九>9(八=2<ı .@5$}|?",
  },
  {
    codes: "      9;?5.;'.八一.四七.\"}",
    descriptions: "4'6#6 4[;= ].şı8==8ı十_四 二@>>{*ı4)&+< |&4=七二 %{>七%;,",
  },
  {
    codes: "      9^二+.&2.八8.;#.6[",
    descriptions: "^&ğ|9*3五六",
  },
  {
    codes: "    8三6+.?5",
    descriptions: "三十ğ5六1三5?",
  },
  {
    codes: "      6[)十.17.十八.&八.五%",
    descriptions: "74\\:^ ,一8五 四五şı四4/七ı%<> 8|一.+>ı'#2>/ &@;]6# ]六2<]71",
  },
  {
    codes: "      八7四^.6十.]..'七.@8",
    descriptions: "5六ğ?@,一>?",
  },
  {
    codes: "  4八6七.|5",
    descriptions: "%*0四5, 2&<ç, <一 6二:]$=5五 ",
  },
  {
    codes: "    二(<四.);.|/.七|.@0",
    descriptions: "六二2五3 ):\\4 :?şı[#*#ı六%7 八8六$.=ı\\3?<_ 3#5九五< =$2*\"[8",
  },
  {
    codes: "    0五,|.3\".'四.26.,六",
    descriptions: "三#ğ99>61;",
  },
  {
    codes: "\\四.#[",
    descriptions: ",: 9$%ı?ı二 &(七七+:&= 5$4 &:.%.&) 七7.3]十*7*/_8? @78/ 0*十 .&七3|'\\",
  },
  {
    codes: "  1{8$.七四",
    descriptions: "^@6六2(^ <|#ç9*0*ı 221#9[6'& '\"<=7+2&3ı (六]$%_{ 一五}&72五九$ı)",
  },
  {
    codes: "    +三:@.$%.*一.?^.8}",
    descriptions: "一一&|845]\"[ \\>3; 0+/|}[2^七# )<,37*_",
  },
  {
    codes: "    22一$..3.十/.2#.6)",
    descriptions: ">@ğ^#二=@七",
  },
  {
    codes: "  四2一六.?八",
    descriptions: "4$&'+ ,.<,)二+六ı",
  },
  {
    codes: "    $$.七.3一.*2.1+._|",
    descriptions: "++一$八(:7\"三 ,;>^ $)0一$.八十|| \"七%,8>4",
  },
  {
    codes: "    7七4六.4十..\\.一(.6?",
    descriptions: ";二ğ696;5$",
  },
  {
    codes: "    九三七4.%].9$.17.1七",
    descriptions: "八十0ı 一11#}.三 五九0&}%> $65[ )|2二\\<0}ı{ (八4六0%六% :七_%:二3} }#$8#) }])1六['ü;{ 二+五^",
  },
  {
    codes: "    ;{/^.#7",
    descriptions: ">%ğ\"4||一.",
  },
  {
    codes: "      ;(^+.{1.七(..[.3(",
    descriptions: "/},四43 23$31{^ö7$?八4",
  },
  {
    codes: "      \"4:8.六#.3三.8@.&)",
    descriptions: "$5ğ=$'.一]",
  },
  {
    codes: "  ]5九九.'九",
    descriptions: "$三ğ十} (||^, 二)3六} 七: @九8;(八三%7",
  },
  {
    codes: " ",
    descriptions: " - - 4=五三(|[\"5 8ü#ü一,ü一(>&@ ö?ç>\\'^ &*7& 8.#0十?一? *58四\"1 七6九\"十,五",
  },
  {
    codes: "      7>'一.6[.>%.{\\.%8",
    descriptions: "=)四(' 十{=< *}şı$八96ı837 一&\"\\七4ı{一=五8 @'>六#5 六^*[2'0",
  },
  {
    codes: "      1%[九.四+.一0.%^.1六",
    descriptions: "八?ğ八4{7一+",
  },
  {
    codes: "      @十98.6+.七?.87.七6",
    descriptions: ".[三1\" ,|九^ #=şı>十.|ı/八0 834|}.ı@七;六. 一#9_七$ [^六一0五(",
  },
  {
    codes: "      #|一二.[_.九?.九,.*1",
    descriptions: ".*ğ5八+6.'",
  },
  {
    codes: "  九九\\9.%)",
    descriptions: "<三0$=, 1/4ç三 一: 4+{\\'<六[",
  },
  {
    codes: "    <四>\".}一.九%.92.0[",
    descriptions: "{}二[= 五{九< 二二şı[六>=ı\"二) 五_八;|6ı$5五%0 [:1@@7 \\5\\;{:7",
  },
  {
    codes: "    ,7*/.)8.七二.%1.>_",
    descriptions: "七&ğ#$.四?.",
  },
  {
    codes: "2@.4三",
    descriptions: "五3五+一74二 $十0, 五= )二?+)九五 $八_5 \\= *&2$<(0十ı",
  },
  {
    codes: "  '6%?.#七",
    descriptions: "|0@92?三0.#\\三",
  },
  {
    codes: "      :六({.&@.[/.&>.3#",
    descriptions: "03}$_ 4=2$ 2三şı5\"79ı5}3 9}三\\4;ı1九7%| 二\\八\\+五 \\${}?94",
  },
  {
    codes: "      '1[5.{..七/.#+.[一",
    descriptions: "七:/'九,@%\\ 7'}六九.ı.@], 九\"?, 三+4 8七#二 :? 八2.\\* 三]1&八}五8+三}{ı",
  },
  {
    codes: "      %6二2.%&.\"五.1十.三%",
    descriptions: "$2ğ+:#\\三十",
  },
  {
    codes: "      /.&6.四+.六7.$四.7?",
    descriptions: "3&74= $;七+ %四şı五4四<ı\\02 _<3)四/ı(%{四^ 九[二一3一 ::8''[}",
  },
  {
    codes: "      _十九..一9.+#.4%.|九",
    descriptions: "\\+ğ三5^一|8",
  },
  {
    codes: "  ^+十?.?九",
    descriptions: "4:;ı1ç {[九八@.< ]三#&7}<九ı (_六一>十0>,1[])",
  },
  {
    codes: "    (^一二.一#.;'./六.七八",
    descriptions: "@7'74 2,8/ 五一şı@一:_ı3五; 9四=07]ı%.(六] '2\"\\*? 2|64@}三",
  },
  {
    codes: " ",
    descriptions: " - - 5:ğ367七七|",
  },
  {
    codes: "      )7._.三$.'|.8+.+7",
    descriptions: "6($}0*$_.] >6\\ı_ç ?|2=[}; &|1#%;6七ı",
  },
  {
    codes: "      ^>七九.;].)[.'<.9@",
    descriptions: "九9ğ07@五?)",
  },
  {
    codes: "    ,*.|.六二",
    descriptions: "+$/7907: &6\\七 九8ö#|;>一",
  },
  {
    codes: "      2八.+.&'.}\\.1\\.\\?",
    descriptions: "$8_一^ 294: {1şı<?/十ı>46 ]0五一>九ı九;.=? |\"{五|> .%0\\7}/",
  },
  {
    codes: " ",
    descriptions: " - - - [6ğ{(&8#\"",
  },
  {
    codes: "        )一;6.6@.@\".%四.6'",
    descriptions: "^\"{%1_ '(@>{四五 #'}五&六}]ı",
  },
  {
    codes: "        '93).二:._^.九$.*\"",
    descriptions: "{[二*?三七:四{ *4九|>)十七 4二'</; ..四/7 ç]]6> <4'ü四/九ö;九8八六",
  },
  {
    codes: "        八六四[.6?.0\\._'.,9",
    descriptions: "&/ğ|*:0六'",
  },
  {
    codes: "    6*;\\.九\\.5>.@_.七九",
    descriptions: "}$ğ<(@:+2",
  },
  {
    codes: "  %+43.?;",
    descriptions: "@*@}十, 十八<ç& 05 :{{1五^$^",
  },
  {
    codes: "    2=/0.;5.71.:八._)",
    descriptions: "四\\三一八6一3&% 1+0% 7$&{9/<:\"1 \"^八[?[/> },) 八@953*6",
  },
  {
    codes: "    六二8(.四1.:6.五一.(八",
    descriptions: "5'ğ一)|四=3",
  },
  {
    codes: "^五=&.)二",
    descriptions: "三]. 20+ı_./ 七(^ 五$四: ;8.+4五, >:\"九 8^ &%7四四\"][# [五* =二*&#, ?四)ç@",
  },
  {
    codes: "  ^?七..8=.):.=).五[",
    descriptions: "80& 8(9'ı?ı 3@六}{ :6{' %^ğ<八 >3八\"{2? %,6 八ü,=,? _='*| 九+9_8)ş 3七& 一三<( 1.'5 六8'三,",
  },
  {
    codes: "  %八>9.五>.=3.$一.9*",
    descriptions: "8|ğ9三&'(五",
  },
  {
    codes: "35.%5",
    descriptions: "]6* <}\"3<*}三, @*五 十/6九八^@@ &# *八ğ\\一 84<0*六. (#七4<* ö}ç一三 [;/十ç2六8",
  },
  {
    codes: "    一/7+.++.|八.|(.+六",
    descriptions: "二三[二七五 */(#2]^ ]ö十四91&&&四 >六(4_#&",
  },
  {
    codes: "    6六61.>九.>二.?>.6九",
    descriptions: "@5ğ^一.^:\\",
  },
  {
    codes: "    $>&6.|,.+5.$+.?:",
    descriptions: "十五^+&六,} _六3一;十ı 1八<>}_六",
  },
  {
    codes: "    $二,7.六../(.;九.七5",
    descriptions: "5^ğ#&2^=:",
  },
  {
    codes: "    @7&*.9\\",
    descriptions: "2+二,九&六)6_ ç\"<ış68@}#",
  },
  {
    codes: "      [=$四.五#.二,.十3.:1",
    descriptions: "=__ 4$<<六5[@",
  },
  {
    codes: "      六=九'.2^.,<.'*.0\\",
    descriptions: "一@?7\\4,8/=四#^",
  },
  {
    codes: "      [_&七.8[.八'.1+.}(",
    descriptions: "9/ğ:[,\"{3",
  },
  {
    codes: "    {二+\"./*",
    descriptions: "{}ğ四,#.+1",
  },
  {
    codes: "      三二(?._{.7四.^@.$'",
    descriptions: "二]3 二*@#4$)&",
  },
  {
    codes: "      &<;6.:8.2七.;..(]",
    descriptions: "九+#'|}九10?十七(",
  },
  {
    codes: "      )三&{.六9.6;.{七.@三",
    descriptions: ".[ğ五八十)'四",
  },
  {
    codes: "=].1八",
    descriptions: "一}五 三八\"\"/242, 九七) %;>)六[三# ;一 5+ğ}= *>'5四+九 ()五9)+ ö3ç3五 <5五4ç4[五ı",
  },
  {
    codes: "    9六=九.八7.03.<..}8",
    descriptions: "*六}六*@ 8)三四0)一 (ö2\\四.\\'\\( '\\{十五($",
  },
  {
    codes: "      )7_^.>十.[五.[#.3三",
    descriptions: "八十7//, \"76+<^/[<八?9八三 }ö三七+\\7)+= >|]0\\'四 (>%58一_四)",
  },
  {
    codes: "    *九:九.}+.四|.(四.0$",
    descriptions: ":>ğ七\\六>.9",
  },
  {
    codes: "    九五&十.5_.三(.4\\.四一",
    descriptions: "1十\\8&}*; 9|8>六]ı ,(<五四\\十",
  },
  {
    codes: "    ,_{;.七;.十8.7#._:",
    descriptions: "'二ğ\"\"\\$%*",
  },
  {
    codes: "    9[\\四.[9",
    descriptions: "六(9|%7({*9 ç\";ış,_67;",
  },
  {
    codes: "      .&.九.四$.二{.8\".,十",
    descriptions: "(8[ 0<*&'=^;",
  },
  {
    codes: "      九{8;.,>.八2.{十.3[",
    descriptions: "8*5七7]7;\\(@@?",
  },
  {
    codes: "      .108./8.九'.{#.{(",
    descriptions: "%{ğ?8九4<五",
  },
  {
    codes: "    7.3[.4^",
    descriptions: "八_ğ二^&\\五八",
  },
  {
    codes: "      /_/\\.五}.八5.10.$5",
    descriptions: "{九9 \"+五\\八6十3",
  },
  {
    codes: "      四七.2.)7.六>.五%.\"0",
    descriptions: "三&七,_九^0%}<&八",
  },
  {
    codes: "      ;/一七.>].一2.三5.^)",
    descriptions: "五;ğ7_&\\十1",
  },
  {
    codes: "0{.?.",
    descriptions: "七]+\\ **5%六五;ı 87)*4 ?_+7$二三2 +9 ç0&'8 一_九6):\\( <).%六 四';\\1六($八^?*九十",
  },
  {
    codes: "  (\\>三.?/",
    descriptions: "6三9(\\五8//$ ç44ış153&7",
  },
  {
    codes: "    0{\"2.]三.七[.八}.八=",
    descriptions: "Ç'+[$ 十*九&3::",
  },
  {
    codes: "    [八|<.5\".六?.二?.五[",
    descriptions: "_}ğ{七十%&,",
  },
  {
    codes: "  7三十5.>+",
    descriptions: "@_ğ<;7:6十",
  },
  {
    codes: "    *五9&.(8.<1.1|./+",
    descriptions: "Ç7五/' >,五'*4,",
  },
  {
    codes: "    ,9|%./0.@|..*.:*",
    descriptions: "[九ğ@九;?^*",
  },
  {
    codes: "+'.一.{;",
    descriptions: "\\*#一八五] 98十二 二_}>3 4七'ı[四)1%ı)ı_, /ç/&#3|ı#, 十六2. 4??ç')3ı七ı&, 8^@_2/45&",
  },
  {
    codes: "  八五>5.]=.\\\\.十十.?&",
    descriptions: "八\"0@八八_;?0 &十53 /十一'一3]$$[ 4四_5(6[",
  },
  {
    codes: "  %六;:._<.十=._1.<|",
    descriptions: "/&ğ5@#?13",
  },
  {
    codes: "7{.九<",
    descriptions: ")|ğ\"2 ,|#(76}",
  },
  {
    codes: "    +|\":.%].%[.|?.[_",
    descriptions: "十>)一54)\"五7 ç1'ış27['3",
  },
  {
    codes: "    $067.20.;3.8{.$3",
    descriptions: "%\\ğ5\\四_[九",
  },
  {
    codes: "    四]五三.七六.@\"..+.{0",
    descriptions: "五7三833(7@+ ç/3ış一二,=:",
  },
  {
    codes: "    %+五<.\"一.\"<.%八./}",
    descriptions: "=)ğ#三九*|五",
  },
  {
    codes: "    %三94.二(.|十.9四.一'",
    descriptions: "&六四三+八8三七& ç47ış^二.十:",
  },
  {
    codes: "    (7\"十./|.&8._2.十/",
    descriptions: "七,ğ7三.2*0",
  },
  {
    codes: "七\\.>,",
    descriptions: "2;六: 51七三五(4ı 3{05 六七|=&22 三}:三}2一 ?);\"三 {&$}[2+ &?*$1,&9ı <7",
  },
  {
    codes: "  >}二?.二[.八5.#^.8十",
    descriptions: "四7\\3* ;<28@[;'< '五20六*9$; [_1;+ ;{ ]八2< <三5\\|7]八, \"_:>6@@五",
  },
  {
    codes: "  $:3十.61.46.40./[",
    descriptions: "^5ğ\"#{0.五",
  },
  {
    codes: "5十\">.六三",
    descriptions: "3九]7\"&2 ]九( 一&八四52六 7/{ ^|/,^4?)\\'ı /@?,\"3八: 四|ç_2>七}八 十十ğ五1#十%",
  },
  {
    codes: "  /二七一.八>.%,.八0.四&",
    descriptions: "%_六八6%?五一_ /3.四 )}.+5}][2( \\^^/=2:",
  },
  {
    codes: "  >*_3.)=.\\].&(.38",
    descriptions: "三)ğ{|)>.七",
  },
  {
    codes: "一>.36",
    descriptions: ".)| {$二八 \\&. []/\\] _(五9一2四,3ı (+$@?十十;.]ış )十|4 ?^.*@ş九*75\\三九ş) ",
  },
  {
    codes: "    &]:0.6,.>一.=/.四7",
    descriptions: "/二/三'' }三\\/):< 3ö15+九,/>\" /四%0 ,;(6&九6 九ö九45一$六*.七",
  },
  {
    codes: "      >十\\;.>5.$(..|.+二",
    descriptions: "_?)一:$ 6>[^\\九:6三+|七_8 _ö>=2]*6]4 &0}?四=4 (\"+;.<五七)",
  },
  {
    codes: "    ;98,.&1.十}.\\七.88",
    descriptions: "{8ğ,\")一1[",
  },
  {
    codes: "  十_=9.[;.+$.;/.\\7",
    descriptions: "五'</[\\#十 *\">17.ı 6十九2七@$",
  },
  {
    codes: "  &%%六.&\".4/.&5.\\]",
    descriptions: "六;ğ二四五\"9%",
  },
  {
    codes: "五一.6一",
    descriptions: "三%ğ*八 *五0六 \"8.[[/[62ı ((/七%@七六6九ış '六 0*七$$ş八@一|/5=ş) (6[| 0' \"&<",
  },
  {
    codes: "  &\\@\\.]..九四.[_.*#",
    descriptions: "五{\\]3/#20% ç0[ış}9七十8",
  },
  {
    codes: "  7七%>.\"#.^@.四?.|一",
    descriptions: "六三ğ\\三{=_,",
  },
  {
    codes: "?:.十<",
    descriptions: "$>.一2三#六.ış |6?+ 7}一六2二[&八ı (^*$56ş@^2?|.74]ş +9?( 0ı?)9&",
  },
  {
    codes: "      一;\"%.五,",
    descriptions: "2\"$'.1&'>ış 四'#48二[^二 ({%(]8ş一一九二1七*0&ş *8五; 4ı8三=% 十:26_ş",
  },
  {
    codes: "      <六;&.]%..].@,.18",
    descriptions: "\\@九六_\\ [19,4>]ı {6+三209",
  },
  {
    codes: "      4}|_.*3.\\7.三{._$",
    descriptions: "2#ğ^8,{_/",
  },
  {
    codes: "    >?;三.2[.6十.,二.&2",
    descriptions: "7/;>七/?|9七四ış )四,0]\\_8+ (#{2.]ş:二[?'{五ş)",
  },
  {
    codes: "    _4>三.%;.}&.9%.#十",
    descriptions: "0=_2七二_;4",
  },
  {
    codes: "  .2%:.2/.=).6{.六\\",
    descriptions: "26ğ|,>]]/",
  },
  {
    codes: "/<.14",
    descriptions: "]&六8六0九8八 四3\"'})(ı {4 十7%五',ı六 *{.三5 0) 9_3ç|(;@ı",
  },
  {
    codes: "    [=/2.=>..7.[9.;7",
    descriptions: "七ı4279五六 {一四七){]&};九 9)9) (ı][&{22 [4:&4&<6二十 <(六/9,@ %{}六?\"$8",
  },
  {
    codes: "  7@=五.48.九九.\"/.>四",
    descriptions: "9{@ 9{{\"'6]1}一/ )2<7二九九 (#九五ı\" ?)%八 &ü@üş]_ =5|一$'&ış %三;三8",
  },
  {
    codes: "  @4(#.>,.二7.^3./_",
    descriptions: "|]ğ}> 十#{269%",
  },
  {
    codes: "  #0.五.[\".(四.=八.6{",
    descriptions: "+九]*三 6八 +'9ç|63*",
  },
  {
    codes: ">8.?四",
    descriptions: ")六15 1三 八八6&& 一二#/)|八8四七三 []0#24{ı *} :0 @>]ı\":七/. 九ş0九%十 4三四 ]三十#2$_",
  },
  {
    codes: "  ',&;.[0.:\".十二.&&",
    descriptions: "<$)|\\<六",
  },
  {
    codes: "  :.二(.\"&.{^.五*.七四",
    descriptions: "/,@八( 三3 7七\"ç<&@九",
  },
  {
    codes: "$}.?*",
    descriptions: "2\"3六 |(3ış=?3ı 五一 七+6|六{ı7 //:二{ ^五 1}五ç七6一四ı",
  },
  {
    codes: "  8)2[.八[",
    descriptions: "\"ı78}1.@ ?四8$\\^*+#十2 ?+十/ +ı(五.*六十 0#二\"27五=0六 (二1四三$4",
  },
  {
    codes: "    八$93.九?.|2.#四.?九",
    descriptions: "/ı八3七六九+ :三7>三9+7'&& &\\6二一^四",
  },
  {
    codes: "    ?$^<.\\#.四{.29.+7",
    descriptions: "三ı七四+39七 ^四:二6+87() 3四七八|{] ;一一|;一45)9] @0十%八99",
  },
  {
    codes: "  ;二9二.九.",
    descriptions: "++五 .>.[(\"三}]'6 <9二:%:^ (八%八ı: $;7} _ü5üş(7 @三;65/'ış 7@*{\"",
  },
  {
    codes: "    <一|3.五#.六三.0一.二3",
    descriptions: "?一二ı[[九 \\1'\"\\8一ış ;):八;9_",
  },
  {
    codes: "    六)%+.{7.+_.,].$9",
    descriptions: "15ğ4+)?1.",
  },
  {
    codes: "  三,8?.>#",
    descriptions: "_七ğ7+92?0",
  },
  {
    codes: "    +六8).5六.%6.89.1一",
    descriptions: "{十一)@, 一(\"} @^0( \\3_六一7 *)@三:八0 九五\\) #九三;>一 六]一四4七6 二||*六七0",
  },
  {
    codes: "    &7'六.7三.;三./六.6<",
    descriptions: "五十六^16四[=二一 \"<?89 =*1^十4一",
  },
  {
    codes: "    $?4/.,%.;\".\"二.$四",
    descriptions: "İ%|9, 2ı}六>>9} (/ş; \"1\\@99), &%}{ /\\}\\@三 @十(1八(三 一5(八 34四<@一 71ş/@8八72",
  },
  {
    codes: "    %_\\(.;四.>二.;3.1?",
    descriptions: "03${,9[ &?{3+7{>']= 九;822{9",
  },
  {
    codes: "    十五七9.23.5/.,/.二;",
    descriptions: "九2ğ{\"(}.&",
  },
  {
    codes: "九[.八5",
    descriptions: "9\"ğ九] \"二6* =$9=$ı ",
  },
  {
    codes: "  :$[%.[\".|3.${.2三",
    descriptions: "707|;#/>^",
  },
  {
    codes: "  *?八3.?>.21.@一.=#",
    descriptions: "5%@(%)/* \\5 0ö})ü*%)",
  },
  {
    codes: "  >1&4.$七",
    descriptions: "?[ğ一2*|九2",
  },
  {
    codes: "    '1.3.\\6.[;.五\".6三",
    descriptions: "}{[2[4 (九&>#/三七=?>; 4六}=2)",
  },
  {
    codes: "    *4&七.三7.@九.]?.五3",
    descriptions: "6>ğ>7:&九{",
  },
  {
    codes: "  \"四.)?",
    descriptions: "2ü,>0)) ='80]ö一}七八; \"ü|3一<1 ,?0$#ö(#1* |ç}6 ışı'61&}.]ış __二ı{ 860",
  },
  {
    codes: "  $六六+.0$.+,.\"\".#三",
    descriptions: "'ü:930{ $##十八ö&十94",
  },
  {
    codes: "  十>=\".+四",
    descriptions: "İ#\\七+0%一 7'ı一ı# 9ç90 ,^@)二4 \"% ]87六五#四., 4;七八'^ı( ?>3>. }五",
  },
  {
    codes: "      8\"|*.9<.&\\.\\=.'%",
    descriptions: "三0&|六1@@",
  },
  {
    codes: "      \"七#二.\\/.}9.^2.+}",
    descriptions: ")$=,5 三一 [七八ç*6十5",
  },
  {
    codes: "      >,{六.{8.四=.+%.85",
    descriptions: "=.\\{(>*:",
  },
  {
    codes: "      二&7?.$一.^9.六六.)5",
    descriptions: "\\_35< 十一 ;*十ç七71八",
  },
  {
    codes: "      _7六1.\"三.一3.^8.\\4",
    descriptions: "十九>2七,7七",
  },
  {
    codes: "      >%{三.2#.四{.=\".&:",
    descriptions: ":5^+{ ?6 0%9ç)_:四",
  },
  {
    codes: "  82}六.六一.=%.?|.二,",
    descriptions: "&şı+四七|@+[ış 十,九ı_ 80^)}9/2&ı (_@8一(ş37\\)",
  },
  {
    codes: "  70七8.三(.9>.(,.<.",
    descriptions: "0ü]*十0# %'1+7ö$三^/8= /,\\{. 5\" 一{,ç^5^*ı",
  },
  {
    codes: "  6@.六4",
    descriptions: "十6五=( 4一15/160ı (#六^ı ^一',九2| 二三ç<, 四\"1ı四ç3ı /3 八72三十ı @9 ü十0@){6",
  },
  {
    codes: "    }[91.九5.}=.:5.85",
    descriptions: "&5八$.9 $] /*@6'七 |)6+三 %9358 ü2&&\"? _六 '2:&一+ %/](}$四_",
  },
  {
    codes: "    |>*..84.3九.7?._$",
    descriptions: "三)_一+五 \"\\ 66七 .%二三 ]+': ]1 一@*一> ü六\\*\"& 六_ 二十*一6: 八九@二_二,?",
  },
  {
    codes: "    三&(}.*四",
    descriptions: "六\\ğ@> ''@^* ü五^(*^ 一(八'_{}一 (8>\\三{ 7/56?_=三 $6:91)",
  },
  {
    codes: "      ]一四(.74.^七.三=.%?",
    descriptions: "三(一五 >?54** ]二8}#,#[",
  },
  {
    codes: "        九:九}.一,.?十.八:.{<",
    descriptions: "%{十<) 0五,0]\" $.一}+={|",
  },
  {
    codes: "        五\"\"\".6<.七五.&四.5<",
    descriptions: "三/ğ九四>_+/",
  },
  {
    codes: "  *八二/.0).,}.(;.六^",
    descriptions: "七ı四{ı| 九六 3*8五}四&&ı",
  },
  {
    codes: "  \\八]2.;_",
    descriptions: "0{二^? &> 一={ç4;\"一",
  },
  {
    codes: "      [[_十.,/.\\#.九五.三%",
    descriptions: ";6/ü%0 5ü九#<@6((",
  },
  {
    codes: "      (@*..:&.{..},.,$",
    descriptions: "=4ğ/十'[.#",
  },
  {
    codes: "      (:九*.|)..&.=4.八?",
    descriptions: ")\\2/七 6二3/,82ı",
  },
  {
    codes: "      \"1{).7@.七\\.六七.>=",
    descriptions: "}/&3* *{2\":一ı",
  },
  {
    codes: "      $)(四..一.{[./一.\"[",
    descriptions: "{\\98九 |#一=三(ı%ı 九{\\4[4$ (/\"=八六}\"ö,%二|)",
  },
  {
    codes: "      ,|{|.++.##.);.(.",
    descriptions: "(八十ü'2 )ü十9+|?@@",
  },
  {
    codes: "      \\*:2.39.|}.:0.<.",
    descriptions: "四_ğ;<,#5八",
  },
  {
    codes: ";|.=:",
    descriptions: "'五2]^@{ ı]ı?3{ 七六.(九四=+ı ($..4二 2@]$&三?3^'三{[五1}二 ;%'$ç)",
  },
  {
    codes: "  #{_八..$",
    descriptions: ")\\;5\"|^:",
  },
  {
    codes: "    :>6$..{.三三.六*.^}",
    descriptions: "$*=@0 \"ö一ü3:一9 [9^三.3二",
  },
  {
    codes: "    +$?%./).*?.?=.}|",
    descriptions: "@@ğ九十\"[*}",
  },
  {
    codes: "  }:{0.@@",
    descriptions: "'0^三( >+ ?7'ç九?|\"",
  },
  {
    codes: "    ?*>九.7#.{(.+4.[)",
    descriptions: "4_5&十 九ö{ü\"^'四 27|&\"十#",
  },
  {
    codes: "    =九七2.^二.]#.;^.90",
    descriptions: "=:ğ37五'},",
  },
  {
    codes: "0_.]七",
    descriptions: "\\%.(十 =[九, >@.^% 0;)#;)70[十{ı9\"四十< @<'26\\6:4< {[十8]*/] <;/[4九ı^>一90",
  },
  {
    codes: "    (七'+.<7",
    descriptions: "\\九.^. *三)一 一二.四4 一;五14#<78<?ı1^0%} 二32四=}三四八\\ 三%02一七五2 \\=&八九九ı'^#",
  },
  {
    codes: "    <[+(.)$.8十.一\\.+1",
    descriptions: "=六3十#&9 ı<ı*7十 0;^&#,一二ı 八3| */'{六9$| (.+/;/ı709 四8$:ı=十ı ]7[%65<{",
  },
  {
    codes: "      五十/:.\"七.>].三=.99",
    descriptions: "^' ı?ı8ı/ı十+&ı (|_6六;,=&ö|8./) \\< 十八0二 ı;ı1ı八ı565ı",
  },
  {
    codes: "      }^4'.八#.}{._<.#3",
    descriptions: "?ı五1ı(ı}ı二一二 四_ 9ı96ı(ı\\ı ]'ğ^_五)]@_一ı",
  },
  {
    codes: "      2^?>.$:.%^.:六.'7",
    descriptions: "3&七:$ _+ ı八ı 8,ü9ü1];ö\",+()",
  },
  {
    codes: "      66九).一..8%.\\\\./八",
    descriptions: ":%5,= ]%.+五|九四1 $七$$四)_9ı",
  },
  {
    codes: "      三497.\"/.八\".四).;'",
    descriptions: "#)^ \\3三,*九># 5七8,十+>七ı",
  },
  {
    codes: "      9七<%.|7.:'.56.4)",
    descriptions: "<6ğ@;二三七一",
  },
  {
    codes: "  {\\>%.#=.'六.4四.#1",
    descriptions: "7?0#< =üç ü{'?.2{%八 1ç$& 3?七十,3九ö+8|4",
  },
  {
    codes: "  =2%九.一5",
    descriptions: "五一五;十 :\\ %|5ç}5<五",
  },
  {
    codes: "    @)>%.[\".四@.'五.\"=",
    descriptions: ",2)五^$0 ı7ı六+\" $'$]\\三十)ı /(. 2=%.:3四6 <九&<五<ı?,/ 1++8ı\"$ı 7;03$十,<ı|",
  },
  {
    codes: "      {,[:.=9.8?.)@.九#",
    descriptions: "[ı6$ı,ı4ı =[\\三一{1",
  },
  {
    codes: "      @5六六./\\.9:.<].^六",
    descriptions: ":&ğ_7^七'0",
  },
  {
    codes: "<三.1%",
    descriptions: "+,三 }@四} =3 @)'ı %_四'=8'ö|十@44 ('一ı8ı?ı*#5ı }2四 /,3八;&_, %927三 4#九_4ı_);",
  },
  {
    codes: "  十?7[.16",
    descriptions: "+4五 &二&四 5> 八;_ 3\\9二5%4ö$:,七& (*{ı7ı@ı|0'ı ,}) 0\"一>十十*1 [['9\\ {{一|5ı8);",
  },
  {
    codes: "    '>/九.$@.*六.73.八7",
    descriptions: "=三三 0'#\\|#1ö\"8二:$",
  },
  {
    codes: "    $.7*.5七.<1.一2.\\:",
    descriptions: "#九 2三1ı 4294,7(ö#1_@?",
  },
  {
    codes: "    |[二+.':.:,.$1.*,",
    descriptions: "38 ?二7 ?ş五&[7= 6}0*,四[? #:=]4#_ö6#=六*",
  },
  {
    codes: "    五|+\\.{五.\";.(1.5:",
    descriptions: "六%五二#一^ 四十1八?四:ö55\\八=",
  },
  {
    codes: "    :%4}.二|.八#.6:..2",
    descriptions: "}七ğ1>9二,$",
  },
  {
    codes: "  )})=.($.$七.一|.^十",
    descriptions: "$*$]0 十/ [十;ç1_8_",
  },
  {
    codes: ";7.:%",
    descriptions: "\\|/(七 ]ü+4七?#@+*",
  },
  {
    codes: "  3]9&.{].\\一.69.十*",
    descriptions: "_9{1七 *]şı_],6ı七\\ @85 *ü六>'$\"41",
  },
  {
    codes: "    三$六7.2三.*八.十..三二",
    descriptions: "@ü=ü }< 三''ı 五1ç一*[<.",
  },
  {
    codes: "    八,8}./5.9一.+八.十7",
    descriptions: ";ü二ü #\" \"7.ı 7,ç95)(十?$*",
  },
  {
    codes: "  #@=/.十}",
    descriptions: "(;2$? ^\" }7&ç&/六5",
  },
  {
    codes: "    ][>_.二4.5%./@.1^",
    descriptions: "_6,六8^ ç一(;$63ı, .7_八7:,: 八& 4+九_二,ı\\ ç5七十)$$ı",
  },
  {
    codes: "    #八%\".七[.(6.一;.]6",
    descriptions: "[/ğ\"&九=六+",
  },
  {
    codes: "&=.四|",
    descriptions: ",ı+ı&:ı六 \\+, 四=%ş一_3一{^ |ç=5六 八}八>*9ı 1(ğ5]/一> $一8* +ö#七< <9七.\"&1,",
  },
  {
    codes: "  (\"八@.八)",
    descriptions: "+&十+ .(şı|.二*ı 2ç[* @七,>二0+",
  },
  {
    codes: "      3)1一.|8.[一.:=.5=",
    descriptions: "55>三59}四 02二:= 92{ =>4 {586 一9\"五 ?) =.@#048",
  },
  {
    codes: "      \\六}一.8一.#十.'/.=三",
    descriptions: "@)三)0八#$ {47九+ 43八 7.1 .八#{ 2八130 (<;+_四九",
  },
  {
    codes: "      六^,八.)七.}/.,\"..]",
    descriptions: "48六&&三)五 3'?[5 七;? \":八 _>+5 #十八一 十( '|[七@一&",
  },
  {
    codes: "      @6(#.九<.八>.5二.,*",
    descriptions: ".|?'?}{\" ':五;十 0{. 4*\" .?5六 一>一{; }二3'}29",
  },
  {
    codes: "    ]1=十.8一",
    descriptions: "<ış8<. ,{八^十 &,|}54?#",
  },
  {
    codes: "      (十{1.六\\.>3.八=.;?",
    descriptions: "9\\&|'1>[ }|1[6 {^九 4(三.ü %四ç五八:\\;#|十",
  },
  {
    codes: "        0{六:.^).'5.8三.)六",
    descriptions: "$ü9ü ]? }[.ı 三/ç43]二四1=$",
  },
  {
    codes: "        ,:五八.*六.<2.?=.39",
    descriptions: "^ü0ü {? @\".ı )七ç@$=8)",
  },
  {
    codes: "    一$;7.%2.$八.,#.,七",
    descriptions: "7(ğ=&/\"98",
  },
  {
    codes: "    %:三2.>*.六3.#\".十_",
    descriptions: "四4四[[.3} ;$&\\/ 0> +>(.ü ^_ç>:二}1一*4",
  },
  {
    codes: "    $}五,.0'",
    descriptions: ">5^八|@+0 2六,36 7\" >八9.ü 七^ç;( );;=) '一: 0]2.ü 12ç\\二:9三);<",
  },
  {
    codes: "      ^{$+.?*.&?.9..]2",
    descriptions: "@{二十=十6= 4};?> #三 @.).ü 二,ç4三 ?六|+八 880 1(:.ü 6三ç七0四六(;<}",
  },
  {
    codes: "      七)6+..@.{3.,*.':",
    descriptions: "五]'八六[@& :|,1_ [三| 四七..ü 十六ç%^ <({9@ ;*= 四;;.ü 十$ç九七五54>&三",
  },
  {
    codes: "    3(\"七.|#",
    descriptions: "七54\"九,\\$ 58五\\: >$五 %一3.ü 5七ç3一 #\\(.7 九.0#$ (/[.ü 九#ç二8九^$4+=",
  },
  {
    codes: "      \"]6@.四&.^%.\\+.十$",
    descriptions: "#三>7?八() )一?*] 1_6 \";<.ü 7}ç2_ ;7*'= {$[ /6六.ü .#ç五2&)'38五",
  },
  {
    codes: "      ^六{0.&8.^[.\\<.*$",
    descriptions: "^二一二#?*$ {一=八8 .六7 一5>.ü 5]ç[3 一九<13 627十 $?\".ü %3ç'七'四3三八?",
  },
  {
    codes: "    (:@].^八",
    descriptions: "8二0+'9@九 十九/[' ;\\&7 /)$. ü 2?ç}[16+",
  },
  {
    codes: "        六:/&.十}.%十.七四.1:",
    descriptions: "三0&=/) 8.6_@?.二五 {&}\\$, 3九0_?$5 七/二2. 五( ;>* .二)5二0'>+3@%%' ,八|5",
  },
  {
    codes: "        三7^\\.[$.?@.^?.九>",
    descriptions: "8>9,1'ı'九ış",
  },
  {
    codes: "          \"5$*.9\\.4{.(二.:|",
    descriptions: "(9}十_二/' /十{'; $+四\" 1四).ü :5ç七.^五*?&2",
  },
  {
    codes: "          54}..二,.}<.二8.六@",
    descriptions: "|&2,)9十$ >^4'\\ /九(三 }十9.ü 0_ç=_七{]",
  },
  {
    codes: "  /9,7.%三",
    descriptions: ")^ğ7? _0=|0>@:",
  },
  {
    codes: "    =+$九.\"#.七7.>].@5",
    descriptions: "\\八.0[%一3 |#%,\\ )十\\ $([.ü $?ç六[8*;@八八",
  },
  {
    codes: "        二1\"六.四<.78.4^.7八",
    descriptions: "%{二942 (/9三_6@*( 5+3六12 |[8(三二四 2@?].$[ :?+ '三\\#_>>1六)0@6' */{)",
  },
  {
    codes: "        ^0{#.%1.(2.=0.:九",
    descriptions: "|ü四ü 8? |#.ı <=ç'81}2?>7",
  },
  {
    codes: "        ^十一_.0七.'4.四*.9七",
    descriptions: "9ü|ü 一* ^二.ı <_ç@,\"|五",
  },
  {
    codes: ":\".2'",
    descriptions: "'ı=ış八ı1六2>一6 九\"?ş|+三+二4 七ç.[. &四;\"/六ı %.+\\;\"7_ 七六三454,@ (8十四[* 7?",
  },
  {
    codes: "  {_五八.=八",
    descriptions: "'2\\13 |)şı+>[4ı 3ç6. 813六)三'",
  },
  {
    codes: "        +九|&.七].?八.^四.%4",
    descriptions: "二6.32 5>* 9=.=5 9=|三?@%*:[.ı6+\"|$ 1='|_0七 #<='3@?八(七 [7<七五5 \\九şı六",
  },
  {
    codes: "      >八六(.\"8.>\\.^;.5十",
    descriptions: "#\"ğ+二十/)#",
  },
  {
    codes: "          三=2).六{.五八.%..1七",
    descriptions: "一1.@, 二^0 9&.,; 7#7'四5^&01二ı一三*.: [\"二四)十& _]#&_九四0\": (+<一,' %一şı(",
  },
  {
    codes: " ",
    descriptions: "          &}4; []\\#.*>.五八.\\; 0(/ &\\3_2七?$/)\"5三= 8ö_<]7ö=+21 >3 四2{{.;一.1{.四<",
  },
  {
    codes: "        5八}#.)五.&2.96.九二",
    descriptions: ")'ğ,_十]'$",
  },
  {
    codes: "          *一=$.'{.六#.6].&\\",
    descriptions: "一'.$> &二一 \"}./; \"&3'07八七;+]ı九8一,@ 5_三#.八) .七'<十九>五1_ <)#8|] 1八şı[",
  },
  {
    codes: "        五四%1._<.\\0.,\".0;",
    descriptions: "%[ğ985]六1",
  },
  {
    codes: "          ,'('.+9._#.*8.)0",
    descriptions: "七7.7[ ':} 五一.., \"99])三三6[\"8ı%三$\"7 +}?二')4 8十67>;$2$' 0&]@(二 %9şı五",
  },
  {
    codes: "        二+@\".\\三.一三._三.,}",
    descriptions: "一二ğ*(^:)'",
  },
  {
    codes: "          .@#2._5.三=.(4.3二",
    descriptions: "21.六( {8五 (六.8二 七1|(9%{@}*^ı七}@6$ 九9:&]*; \"]48^<'三{2 %2三十{\\ 7<şı)",
  },
  {
    codes: "        }8_3.4四.三,.|0.}\\",
    descriptions: "8+ğ%;\\:\"^",
  },
  {
    codes: "          )#$|.十+.;}.:].%七",
    descriptions: "@*.一/ }6. 0).<$ |39+/四6_{二1ı]81]1 0{$2<)[ 5<四八=/:'\": 二],#+五 十;şı八",
  },
  {
    codes: "        4$二_.\"..五/.七四.三.",
    descriptions: ",{ğ九@{*@'",
  },
  {
    codes: "          _(.9.7$.86.=9.5_",
    descriptions: "\\..&% ^一# ,^.[九 2九}<五7四*,,5ı9|=?\" =+%48)三 ^7十+%#0二$八 8&一$)一 ?四şı&",
  },
  {
    codes: "        五&\"十.'9.67.>/.':",
    descriptions: "\"十ğ1'8九}%",
  },
  {
    codes: "          ?|四/.九[.六8.,\\.#8",
    descriptions: ":%.2[ ^5二 5?.<9 九{九五@%0(#;.ı?4,一一 4}.\"\\,. 2(<'\"|%&<| 四四8六.二 二,şı\\",
  },
  {
    codes: "        ?7}{.]3.]+.>(.五四",
    descriptions: ",(ğ5/)3一:",
  },
  {
    codes: "          (>三?.六(.二@.八#.<.",
    descriptions: "一5.九? +4[ 五三.7] 三七(七($27465ı{50\\二 3|十#六[四 (31\\7**9四_ .(六%<6 .{şı九",
  },
  {
    codes: "        ^/八\".十二.#+.二5.]七",
    descriptions: "三*ğ|&(={>",
  },
  {
    codes: "  18一_..3",
    descriptions: "=7. 十]8ı'&_|[ 七^şı七?[/十? ,\"&@|3ı7\\> 7ü$[7 ]{#1 7)(一/2/\"",
  },
  {
    codes: "      四8七<.<'.$+.>九.;二",
    descriptions: "{>二8:] &3二(5四二?{ )+|五_0 ^.^五\\^] 19{^.?6 /十: #{>'二;四;%9(五01 \"=六^",
  },
  {
    codes: "        1].^.5\".:7.五?.)$",
    descriptions: ";ü;ü .< 7\".ı }9ç=+[&{二>4",
  },
  {
    codes: "        ]/(二.9\".$4.'$./3",
    descriptions: "七ü五ü 8八 一*.ı 九_ç.( &.&九\\ \")/ 十7.ı 90ç\"8]7>+8一",
  },
  {
    codes: "        _(:*.=七.5十.五>.8|",
    descriptions: ",ü=ü <九= \"&.ı }<ç(^\"$\\",
  },
  {
    codes: "        &?六}.\"七.{'.#^.=1",
    descriptions: "\"ü=ü 八一 七}.ı 30ç)6九二%):=",
  },
  {
    codes: "        ?5十+.^4.二#.一4.)&",
    descriptions: ".ü十ü '{ *_.ı ,'ç$* ^,1%十 ^九五 十) ı 四%ç十十|+7_>=",
  },
  {
    codes: "        }五四;.]7.1{.8七.3<",
    descriptions: "六ü{ü 2六& }8.ı *%ç十8 2=0[* 九^^ (@.ı |十ç4七{0^08]",
  },
  {
    codes: "        五五\\$.>*.5'.|2.1_",
    descriptions: "3ü三ü ;6] 7四.ı <7ç\"8二#&",
  },
  {
    codes: "  =<=5.九/",
    descriptions: "(*ğ/5 ]&33/=二二",
  },
  {
    codes: "    ,^\\[.6/.8\\.)+.+{",
    descriptions: "7_五8[八|3, 三@şı,[4|ı*+三 一_%一=.ı^八六}< 2*&五4+ 99{/八,#",
  },
  {
    codes: "        $8九\".0$.$二.3&.'二",
    descriptions: "/:)3/ |九]; 3=şı+%2\\ı'十{ %:$\\七@ı}&\\=. =7|]&; }<五]&%2",
  },
  {
    codes: "        '|38.29.7七.<6.07",
    descriptions: "九六ğ'$三&'八",
  },
  {
    codes: "          _+#九.\"@.#八.^七.;5",
    descriptions: "1;七4& 2*6三 '$şı]9三2ı.{0 一7*:\\;ı3<\"六# #[十#\"} $七:](}|",
  },
  {
    codes: "          (0+%.&一.九>.;].&\"",
    descriptions: "一{ğ<=二三|_",
  },
  {
    codes: "          &4%%.七5.*).|九.,}",
    descriptions: "八九>6? {%6; 八+şı#,一^ı^五> ?[}$;6ı\".3,五 十4;7&( 74,'三\"一",
  },
  {
    codes: "          ]@\"/.24.八五.><.6:",
    descriptions: "6&ğ$七^]\"_",
  },
  {
    codes: "          }/(\\.十八.8&.8\".$|",
    descriptions: "/('8十 六九七1 \"4şı2五%%ı4[] '一六一]\"ı1$)三0 $六七十54 _四/一6$(",
  },
  {
    codes: "          ]]三,.>=.4二.<0.?+",
    descriptions: "/四ğ3;%七,\\",
  },
  {
    codes: "          8十(#.八[.2=.八/.四^",
    descriptions: "五<四九\\ ?^1) 四.şı三+[,ı/?十 三七[089ı\"<.7五 四(二/1七 /];3三/$",
  },
  {
    codes: "          7931._=.@6.六[.八8",
    descriptions: "=,ğ\\#'7_%",
  },
  {
    codes: "          =)%8.八五.'6.:{.*,",
    descriptions: ">*六.六 '3四= 八_şı七&3)ı@\\, /五|(<_ı9,9?[ )3|)(4 :]04*七;",
  },
  {
    codes: "          5+;7.六\".$十.一8.0%",
    descriptions: "五<ğ9*,]2.",
  },
  {
    codes: "          '/00.=0.\"[.五=.:\\",
    descriptions: "34<29 八'\"三 =8şı_(30ı2() ;\\0=*[ı)七]() \\/十^*三 ]9六八一&十",
  },
  {
    codes: "          ;八6{.{(.[^._:.<#",
    descriptions: "(九ğ1>:92五",
  },
  {
    codes: "          ]七)9./'.八八.5三.}#",
    descriptions: "9七[|* ?/#| 2六şı五十2)ı^十( |$四十#六ı(+5[\\ 九[5四8. |一十+,'{",
  },
  {
    codes: "          %=,二.八九.=一.七+.@#",
    descriptions: "4;ğ[|70].",
  },
  {
    codes: "          十五),.;五.)四.2五.>,",
    descriptions: "九一'5> 三3,七 9'şı%</三ı四三3 一?八[*?ı_<4^> /)8\"2? (\\>五@:一",
  },
  {
    codes: "          +_|?.5@.=9.2{.二+",
    descriptions: ",8ğ八8\"<)8",
  },
  {
    codes: "          四\".\".:五.0,.${.七[",
    descriptions: "]>\\<八 七十四& 一)şı五%@十ı|@) ,$[七8^ı\"3;.? +1#{十九 %.36八六三",
  },
  {
    codes: "          =2九九.3%.24.四7.七9",
    descriptions: "[}ğ十._4|&",
  },
  {
    codes: "          <{$<.三4.&3.}五.%4",
    descriptions: "五(\\五, *{)? 六4şı7,7.ı#六* 五0.]92ı@4三;' 2'{2+@ 9\"7:|'>",
  },
  {
    codes: "          三@(七.>(.':.87.六四",
    descriptions: "//ğ七(*>%+",
  },
  {
    codes: "#>.7)",
    descriptions: ".[,|99 二一六[ ;.)% 三二*+}|@:九1 .九.4. 一76: 7\\.;/ )6:\"九2@_=*8ı}四[]<",
  },
  {
    codes: "  38'{.9\"",
    descriptions: "16|+ @]şı97%3ı 一ç%\" [@9九七%=",
  },
  {
    codes: "    9二<\\.,@./八.二?.>@",
    descriptions: "\\[>^/ 五?十6 $@şı:)_?ı八九八 <?六六,3ı]二:三* \"一%^九九 )7]@六八八",
  },
  {
    codes: "    七{9^.=^.*6.%,.[九",
    descriptions: "|{ğ=三(?:_",
  },
  {
    codes: "    \\_;[.&@",
    descriptions: "5*7\\|2 2'十0 二=$) 四@87{(\\%\"( 三ı_ı;5ı7 7$} 6十七ş3)9[五9 |ç'\\}",
  },
  {
    codes: "      >四|十.{%.九\\.&<.20",
    descriptions: "四\\_8五:,+%4十0 七.二>:八}: 七0_二七;.#ı",
  },
  {
    codes: "      8:三:.,}.\\9.36.#_",
    descriptions: "&;+]}三',六",
  },
  {
    codes: "      &\"6^.四?.](.{#.>%",
    descriptions: ").?七八'.)'",
  },
  {
    codes: "      :@\":.4五.5三.\"7.^\"",
    descriptions: "\\/^;;^|五 六ö)二(*七五#9",
  },
  {
    codes: "      ^%三\\.%6.九:..七.2'",
    descriptions: "九ü\\;?_二二",
  },
  {
    codes: "      五五五(.5十.;3.七4..5",
    descriptions: "\"#&5+四ö);\\'",
  },
  {
    codes: "      %^\\|.5/.六四.\"'.$>",
    descriptions: "9>3|&6\\'",
  },
  {
    codes: "        \\_>].七\".五,.*[.?;",
    descriptions: "八一*)> }ö二ü.#4_ =六|;五^4",
  },
  {
    codes: "        030六.='.$八.七,.]四",
    descriptions: "\"\\ğ二一四@+.",
  },
  {
    codes: "    6*二..},",
    descriptions: "$.ğ_,十?)/",
  },
  {
    codes: "      +[>,.{^.4_.>{.)}",
    descriptions: "4(_五:&>'/三三+ &{一;]|{二 92六/#/$3ı",
  },
  {
    codes: "      7^%2.?[.{7.3\\.九8",
    descriptions: "&{^=*二%={",
  },
  {
    codes: "      *'{:.,>.@[.%[.95",
    descriptions: "六70'&三+(>",
  },
  {
    codes: "      95=+.=}..5.(5.*3",
    descriptions: "七=0一*$_* \\ö$=_(|,,\\",
  },
  {
    codes: "      05二'.0|.}^.&'.九<",
    descriptions: "@ü8$)^'六",
  },
  {
    codes: "      4一9+.十六.16.六:.|8",
    descriptions: "]9五/<)ö|)#9",
  },
  {
    codes: "      =)'].\";.1四.%*.七三",
    descriptions: "('十七1.\\4",
  },
  {
    codes: "        ][1七.@).[_.>8.45",
    descriptions: "|一871 |ö)ü=九7\" 4=四八七九六",
  },
  {
    codes: "        |#=|.\"<.九].0六.一>",
    descriptions: ";)ğ$?3{2<",
  },
  {
    codes: "_}._}",
    descriptions: ")) *ü),8]1#(2, 六, ç#六_9?_ı 62 #.{$*[ 七ç_' /八'ü+@>ö>+七$",
  },
  {
    codes: "    ?四79.*}.,四.41._+",
    descriptions: "+ü8ü 六七[十 '\\.ı 2}ç]二(八*4;6",
  },
  {
    codes: "    3\\$九.五(.五2.\"<.五^",
    descriptions: "\"ü5ü :6一7 55.ı ]3ç六[ 九九]五< 0+ ,七# 四+.ı '%ç六41四<+1:",
  },
  {
    codes: "    [{_五.+5.?7.3'.^四",
    descriptions: "]ü}ü }0 ,]4 |'.ı +三ç一}28;",
  },
  {
    codes: "  6\\/六.五4.\"*.{9.5}",
    descriptions: "^<5># 四@ /九九ç^=,三 (九^8ü一)\\ö6=9} <六8九})",
  },
  {
    codes: "/=.$6",
    descriptions: "?'3三6>\"六一.<, 7六%8{.+\";八[.五<4^? )& 六^ğ0? 一五. 6ü'\\(1}:九^",
  },
  {
    codes: "    {?^八.:+",
    descriptions: "İ@]四1 四ü0ü %十 %三.< 七+ç八2三八>%一0",
  },
  {
    codes: "      2/(4.81.)2.+|.?八",
    descriptions: "七六>{7 )#;0 )*şı$8,.ı.1& {/;'{\"ı?七1{} 1#8$四@ ?}54/5六",
  },
  {
    codes: "      }/八<./=.8(.^'.+,",
    descriptions: "|3ğ&>'\"5>",
  },
  {
    codes: "    \\=0\\.三&",
    descriptions: "İ}*%3 8ü三ü /? ;十.& *二ç(@\\@/",
  },
  {
    codes: "        ??三..=].;<.@六.[九",
    descriptions: "1]]3& $,3\" ;8şı4{}]ı[<\" 757_7_ı}(1.@ :二@九^? ;]一+/;一",
  },
  {
    codes: "        ^|88.&\\.0,.九九.[)",
    descriptions: "&8ğ'/%20_",
  },
  {
    codes: "        =9三>.三十.*1.一七.*@",
    descriptions: "4一:九& ^/$3 :}şı一9:\\ı<*0 /.==)\\ı[九;五? 三(<==| 1)07九.?",
  },
  {
    codes: "        ,,56.|1.(&.;六.|四",
    descriptions: "+6ğ\\+六#79",
  },
  {
    codes: "        /{十;._六.{\".&*.七{",
    descriptions: "{:2,{ 六2一& ?9şı}+三(ı4=5 %;8|{4ı七四',9 一]4|五< 一,2\".>^",
  },
  {
    codes: "        *;4二._\".二\".^#.(,",
    descriptions: "三八ğ7{]+5+",
  },
  {
    codes: "    2%十/.五七",
    descriptions: "9ü[ü 5 ?;) ]).ı 六,ç.^*7九)_=",
  },
  {
    codes: "      })四七.'\".七=.\"0.2五",
    descriptions: "0六3三九 $>2< 九]şı\\+<:ı}3五 #?^(&&ı五二(五1 四#三九4< \";8[十&5",
  },
  {
    codes: "      =四@三.三|./<.=9.\"}",
    descriptions: "<1ğ{{四@|\\",
  },
  {
    codes: "    />'(.@\"",
    descriptions: "[ü1ü > %六1 > 五.ı 8七ç一0三八七",
  },
  {
    codes: "        9217.3二.17.二7.0.",
    descriptions: "<一8_& 1}6八 [三şı#六?@ı]五= ^.二94六ı3<^3^ 九七.[4^ 0{8^{|)",
  },
  {
    codes: "        @4\"四.六]./(.5一.\\=",
    descriptions: "^六ğ[>,一?*",
  },
  {
    codes: "        \"\"六^.0一.二,.五二.))",
    descriptions: "+<十(0 #_(' +?şı_;_;ı]\"( 9<?五'\\ı/_49@ 九+6三?| &*,7六\\[",
  },
  {
    codes: "        7.93.[\\.37.,九.十*",
    descriptions: "1_ğ七五}&七1",
  },
  {
    codes: "    _五*十.{1",
    descriptions: "_ü1ü ( =@} .?.ı @@ç>025<+*二",
  },
  {
    codes: "      六$$四.一#./4.2#.5三",
    descriptions: "\\1>^一 二八?* 7\\şı&一八+ı<<_ ,六?*>[ı五6]九九 (|(.十@ #三三26九=",
  },
  {
    codes: "      &九}#.*四.6>.9一.3二",
    descriptions: "四\\ğ33六29%",
  },
  {
    codes: "    |.%].十2",
    descriptions: "&ü六ü { 1=9 \"+.ı {)ç0'+=0",
  },
  {
    codes: "        3*:?.66.;=.1八.2+",
    descriptions: ")四^;% ,)/> 九(şı#二=一ı){; 一5*三一9ı[},.八 )8%^9七 &\\一\\=八[",
  },
  {
    codes: "        /,{5.2十.?0.\"=.#>",
    descriptions: "49ğ{>3;<6",
  },
  {
    codes: "        '?={._;..?.\"七.一%",
    descriptions: "7七93, [)?^ _(şı八二{>ı;<5 '=八$4$ı'/,六[ 0|])三= 四_.一&}[",
  },
  {
    codes: "        8_>{.九六.六六.0\\.*4",
    descriptions: "__ğ七}九\";$",
  },
  {
    codes: "        ')%/.6:.七+.,4.+2",
    descriptions: "四四^}+ @}+九 八/şı/7'七ı*三( 六一+{($ı3'#5: ]_)十,] {(&}]4[",
  },
  {
    codes: "        0三?2.1二.2?.+,.^/",
    descriptions: "\"八ğ六\"二四_}",
  },
  {
    codes: "    '三四4.三1",
    descriptions: "&,3九十二五\"[\"{ 9\"}五 9九$_2+]<;十1\\8\\=96六 7三5 ^5?_64<",
  },
  {
    codes: "      387}.,2.十_.<#.0^",
    descriptions: ")%十四3 /[:$ 五{şı2.)8ı}七一 (5<<八=ı:/*_# 二)0十7| @8?\\6_+",
  },
  {
    codes: "      )四)@.2$.{_.二9.[%",
    descriptions: "七&ğ五六&.1@",
  },
  {
    codes: "    [,0^.9:",
    descriptions: "<三ğ(七(四9%",
  },
  {
    codes: "      四1三5.9;.(4.4八.*@",
    descriptions: ":@{;< 四<+4 76şı<#={ı>$三 ?六&&五1ı|&( ?)一 ?ü<.九}6?@0}{ ,六\" \"}\\\"九,+",
  },
  {
    codes: "      ==][.;0.7).五=.}1",
    descriptions: ")%ğ@&9+|[",
  },
  {
    codes: "{{.一:",
    descriptions: "十;ğ\\{ 2['3&8=' )\\ ]77^/十 ,六?ı% {}_> 十8=八九>>&十",
  },
  {
    codes: "  |?七三.%}",
    descriptions: ".]5十;4( 一41\"=0|3 (,72八1 ]十三八&* :@?七ç)",
  },
  {
    codes: "    \"+^{.72.1*.四+.#2",
    descriptions: "二>9八一 84&八 13şı七'%#ı{=, &.+十85ı*+;(( 7十7?九: ;5/|0|4",
  },
  {
    codes: "    ,'6\\.^=.&(.#十.[五",
    descriptions: ".,ğ]八九六八\\",
  },
  {
    codes: "    %#&..7[",
    descriptions: "5^ğ四)9一7 ,二}\\5+'.% 8%七>,*( (十]四<三+*'19})",
  },
  {
    codes: "        七六62.%_.九6.九9.十3",
    descriptions: "七<五|9 四8}八 8十şı)#24ı(_/ >\"}#8十ı|_; #{2 一ü?:八4.;0=+3 _*@ 4(05八7&",
  },
  {
    codes: "        61[{.$六.,三.九\\.02",
    descriptions: "8{ğ三@二65(",
  },
  {
    codes: "        =^五$.4七..>.^六.七十",
    descriptions: "'七?{^ +/一, [六şı\"十&\\ı0}= 81<4,@ı{$# 8八] 4ü\"]六][\\2&<? 八4[ 一2:@+'一",
  },
  {
    codes: "        /6+$.八6.六二.|#.9]",
    descriptions: "/_ğ五&&+<|",
  },
  {
    codes: "    8;#{.七'",
    descriptions: "六一ğ.7八四6五",
  },
  {
    codes: "        '9'6.5?.四&.69.*@",
    descriptions: ">|_\\, 十$@4 $\\şı?9$.ı@七四 九八%.7)ı::% .?@ \\ü18@六[八七1|6 5]四 #|3@%??",
  },
  {
    codes: "        %.37./>.=..*三.\"9",
    descriptions: "8|ğ.^{七&二",
  },
  {
    codes: "          0&$1.*八.#_.7[./4",
    descriptions: "[6*1= 八^7; )4şı\\五%|ı二(. 六3二/六.ı一<* )三' <ü7四]\\4[四71五 八6; +*八^\\九\\",
  },
  {
    codes: "          5&_十.;3.#>.0'.?)",
    descriptions: "75ğ,9:3$&",
  },
  {
    codes: "          %(九5.<../).^六.四四",
    descriptions: ">@&\"\\ &#\"0 %&şı$61$ı:%# \\0=?#4ı$#8 九&^ .ü1#三[_8/%]\\ \\*> \"@>3;=4",
  },
  {
    codes: "          }6\\9.3;.:\\.>五.43",
    descriptions: "六(ğ九9#,{%",
  },
  {
    codes: "    八\\09.\"0",
    descriptions: "2[ğ_+.3+ 0七七*%7,一_ 772%_^1 (@>六+{@_('@&)",
  },
  {
    codes: "      ,[<:.9,.2>.'七.51",
    descriptions: "8(+76 %$3@ 四2şı}}%六ı||三 8$六}七2ı62五*二 5?五/2$ *7_6<四}",
  },
  {
    codes: "      5_五3.52._:.一8.+8",
    descriptions: "5>ğ八五八七#/",
  },
  {
    codes: "    ,@5:.+|",
    descriptions: "二>ğ,#9_四%",
  },
  {
    codes: "      2?15.@一.+1..二.\"$",
    descriptions: "#0六二4 >'十0 六四şı(\\+>ı二8: ?7)十(2ı2&二@7 )>$_六< 六)^二%&@",
  },
  {
    codes: "      :,八'.*+.八>.\"%.\"一",
    descriptions: ",六ğ[]7三_:",
  },
  {
    codes: "  四&八+.:^",
    descriptions: "=+ğ{{三(#六",
  },
  {
    codes: "    七<三十.三+.}[.:|.44",
    descriptions: "/[9七*|# ç{=ış$九 八/十}_七>三.",
  },
  {
    codes: "      \\{>1.二\".(九.{六.$0",
    descriptions: ")7\\#; %659 \\]şı{,(<ı一6* 7&9}2<ı四7八[2 {九_*?+ 五>二9;+6",
  },
  {
    codes: "        三&七九.@9.八].76.5:",
    descriptions: "#ü#0(:># ç^)ış|一 }一&)* '1 ,6+五8#)五{",
  },
  {
    codes: "        4?^#.3$.03.七^.2@",
    descriptions: "九,ğ七二:8|'",
  },
  {
    codes: "  5*_二.二*",
    descriptions: "九)一六< #< \"\\*ç%,<6",
  },
  {
    codes: "      九六九{.|{.05.2#.四+",
    descriptions: "'|<五( 十*$三 #九şı)_?^ı六六/ 4{8一#.ı=七069 $>['四四 >+}5九,二",
  },
  {
    codes: "      44%三.五0.||.}}.20",
    descriptions: ";\\ğ')8%1{",
  },
  {
    codes: "      )91五.;\\._九.十:.|@",
    descriptions: "_|3=8 六\"2\" 17şı40>\"ı$$' @58/二8ı:+四:+ >]'{四) >\\+十.6|",
  },
  {
    codes: "      ^>6八.]一.九6.五|.[%",
    descriptions: "<#ğ三六%:&_",
  },
  {
    codes: "      +\\9_.$六._1.5十.7:",
    descriptions: "九@四%.八} ç@9ış十; #七:2(七?_{% 8<& 一三,(二八&",
  },
  {
    codes: "      $六八{.4四.二;.,五.一<",
    descriptions: "十:十八) 三9三$ 五8şı四\\>五ı)>_ #68{一\"ı.6]六五 }二3})] ^十#9]_<",
  },
  {
    codes: " ",
    descriptions: " - - - \\'ğ_9$$\\五",
  },
  {
    codes: "        )\\五8.六(.>*.21.08",
    descriptions: "%.ö(?}\\/ .üç /*一&.=\\四ı(1 :二 '([三35.七>ı>七 :#$ 八<.:五九+",
  },
  {
    codes: "        8\"#二.19.6一.*0.&7",
    descriptions: "\"ü}\\四|11 ç三_ış($ '四/3/ 一$ \",0[六五\\&.六 };} 1十5#=三四",
  },
  {
    codes: "        九0三^._十.$[.0:.四九",
    descriptions: "三+ğ]&,0五[",
  },
  {
    codes: "四九.&.",
    descriptions: ")ı5ı65\\ #ç五7 ?:>四.)2> (ö{çü 五?^$一五$七ı ?三\\2六 ^八\"30ı十) ; 7ı五ı 四\\一;\"三ö二:_:_",
  },
  {
    codes: "    :_四7.六七",
    descriptions: "_2\\四0( :;>]3{@6\"9@ı =];] %_)$8*八0/_ 0#718]ı七^% }ü}八( &+四ı} 9六\\三 &\\ğ",
  },
  {
    codes: "      0[)8.3$.6(.五5.3,",
    descriptions: ";]三:< &\" &]_\\{三 二ö3';/<\" 二+;@{7\\}ı 9*3. 46ğı#ı: 八'4一三五{?ı",
  },
  {
    codes: "      {|?9.'八.().79.[/",
    descriptions: "_七ğ[=$六3{",
  },
  {
    codes: "    )八7?.八]",
    descriptions: "<三ğ85*|#+",
  },
  {
    codes: "      ,三*5.[2.5&.[/.:3",
    descriptions: "21)(| 三#?, 9&şı;&03ı)五\" 2\"三\"[@ı]8一\"{ 0\"[&六_ /#{1]{五",
  },
  {
    codes: "      +=9|.(*.二*.;七.\\七",
    descriptions: "七)9七, 一& {'%1>$ 9ö:三9<$' )^>=_一,*ı '3|, }*ğı{ı4 6;)5四5八?ı",
  },
  {
    codes: "        >三$3.五%.{一.$[.0_",
    descriptions: "三ı9. 5.{]}二2]& 八ç}' }'}::\" &ö8@九/(八 |一ğı二ı@ 一]五八6/十七ı",
  },
  {
    codes: "        ;?'三.>8.=八.七(.9=",
    descriptions: "二|47十> 十ö_四十0}四 7四(二一(,,ı ._%7 2六ğ\\_ 4<ğı{ı& $/0(*3}0ı",
  },
  {
    codes: "        十.},./{.;八.0[.)2",
    descriptions: "|=ğ#3七<=@",
  },
  {
    codes: "  415*.一(",
    descriptions: "8] 四八九1\\八#?ı (.三2@.$= @\\@8 #)^).($ \"[2 #?+\"%八5?*#>ı4['2%六八, 五5@@ç)",
  },
  {
    codes: "    /$8>.二九.3<.75._>",
    descriptions: "二七:#^ )+@_ 57şı七&%,ı3$> 5+##八|ı}'6_^ 九'><89 五四四(,'>",
  },
  {
    codes: "    \\%>8.4/.二五.;*.>一",
    descriptions: "']ğ八九@,<_",
  },
  {
    codes: "  )46].:]",
    descriptions: "İç6<( 五=({九:ı (^;,8一)二 @7=7]一0) 六ç[四 {{6ı7, 1|ğ 一/^$ *$ğ8\\一<",
  },
  {
    codes: "      三九_\\.:八.=].\\三.:9",
    descriptions: "24'四四 三&二十 +\\şı[?(八ı?9) 6/?;\"七ı]十.9# 8二%六^4 十>$+*42",
  },
  {
    codes: "      ]3\\十.$7.3*.?|.四5",
    descriptions: "\"]ğ;9(+一,",
  },
  {
    codes: "      二{1;.&3.\"/.8;.$五",
    descriptions: "\"三八四7 @*7> =.şı#,*+ı八_& %^1)%?ı0六%六七 一7@981 /9/<_二&",
  },
  {
    codes: "        五9/&._@.4@./@.4|",
    descriptions: "四\"ğ 7'%+8四''ı",
  },
  {
    codes: "        [?;5.[9.\\$.4;.**",
    descriptions: "+/ğ二'.' ;一#},_;/ı",
  },
  {
    codes: "        %::八.六*.^).0|.'一",
    descriptions: "2六ğ50;4^>",
  },
  {
    codes: "  1十[&.\"四.+八.\"4.?{",
    descriptions: "十*/{( 八/九7十;+七ı",
  },
  {
    codes: "  18_|.8/",
    descriptions: "二6ğ67 \"三ğ%二一七> #?,=9;^:三2ı (.77七九,八 }$9\\六二三@5) 3}/三三'8=",
  },
  {
    codes: "      :'6五.十[.'七..\".).",
    descriptions: "\"{\\一8 :1;. \"^şı&二:六ı_[八 七@/{/$ı)**(# ^|0]五六 .\\{[0?二",
  },
  {
    codes: "      =(%..,*._十.\\九.8一",
    descriptions: "+?ğ#{0三六四",
  },
  {
    codes: "      ^三三6.{,.[1.三,.三[",
    descriptions: "245十9 \"一\"$ +三şı5三(7ı'二9 三8'7)?ı/4{|_ 4)29(\" )0{?55>",
  },
  {
    codes: "      @9**.九..|;.六十.四0",
    descriptions: ";八ğ:十1/,7",
  },
  {
    codes: "          6>九八.4%.一{.*(.;+",
    descriptions: "二#|*$ 四>=} 19şı五{/三ı四#2 [=2+三;ı5一='十 \"七{,/8 0_413/\"",
  },
  {
    codes: "          &77二./九.五九.一'.*)",
    descriptions: "/#ğ'<>+([",
  },
  {
    codes: "          109^.*,.?%.二=.99",
    descriptions: "(:9[( #\\[六 8#şı)六8;ı]}三 一三七7\"<ı|={七十 '{]五)= 6)5<七&六",
  },
  {
    codes: "          55<4.四9.|_.]9.8/",
    descriptions: "(?ğ/4;82]",
  },
  {
    codes: "        ^)十7.<>.八].)?.*/",
    descriptions: "=<一三2 五97九 ?3şı7)四]ı一+; 7|&9{*ı^]}七. )+83+) 二0<六02一",
  },
  {
    codes: "        ('1&.,<.|&.五(.27",
    descriptions: "%,ğ9三4|:<",
  },
  {
    codes: "  (8?<.]\\",
    descriptions: "/]ğ): <ö\\五0 +9:\"85>5{六ı (\\&:七七{$ ^@二六8(二7%) [/@_*一<四",
  },
  {
    codes: "      7二)五.,六.;2.1[.>0",
    descriptions: "八]#*九 ;<': 十}şı@)1+ı九@四 #\"2))/ı}1%6\" 四69<<7 \"六七5}4$",
  },
  {
    codes: "      |[8七.%*.六;.八}.二:",
    descriptions: "]二ğ^&十']3",
  },
  {
    codes: "          一三_\".+七.^1.=\\.(#",
    descriptions: "<|四5& @十+# 六二şı2+7]ı]*& 2%%[;_ı|_).六 $15_二> 九,2+:六5",
  },
  {
    codes: "            十+五>.+1.五1.八九..5",
    descriptions: "@.64三5]#$ 8ö32:#9, 六'\\*99 一_#(; 4 4五,/&6{5) %十?+ 6+2] ?,^三4 4|*95",
  },
  {
    codes: "            &)十&.五5.?].^4.=;",
    descriptions: ":3ğ4{.=4+",
  },
  {
    codes: "          八,{..67.^+.=;.\\3",
    descriptions: "@2|四八 (四0' {,şı=}+:ı1$八 二{=};{ı=##:@ ^_$$四, @$]2+*$",
  },
  {
    codes: "          5^%|.1$.5^.1%.3[",
    descriptions: "0.ğ\"\"/^5.",
  },
  {
    codes: "          %+一2.,;.7=..一.八'",
    descriptions: "}:)$三 5\"@( 9^şı*:{9ı八\"] {&\"二#五ı(^五/< 9[?7@, ]\\.1^五*",
  },
  {
    codes: "          9&;六.\\^.6].&].5(",
    descriptions: "*>ğ2%*9八[",
  },
  {
    codes: "          五))0.:六.('.#四.%9",
    descriptions: "=&/.< ]=)_ 6>şı六\\4+ı}9一 四二六十{1ı\"一02) <7{91六 }十'六;,=",
  },
  {
    codes: "          2?+^.#1._\\.?&.一九",
    descriptions: "'}ğ=&:\"=3",
  },
  {
    codes: "        >8{七.%^._9.八+.二]",
    descriptions: "&@?6五 \\十$三 2七şı[\"十)ı\"') ?$3二^$ı*(九6@ :?+十#\" \"\\九4.4;",
  },
  {
    codes: "        <[03.|*.'%.38.<?",
    descriptions: "{,ğ0*八六|6",
  },
  {
    codes: "        +三>2.6/.八九.5一.一:",
    descriptions: "$<=六{ _五四4 十^şı/三_二ı;&5 一五^三\\>ı}:@&) .%,三三5 00({4[一",
  },
  {
    codes: "        (2}0.(0.四=.六七.|+",
    descriptions: "二十ğ{\\一二五)",
  },
  {
    codes: "  六78?.六9",
    descriptions: ">8ğ(一 <9八>$;1ü五 三三:八=#/四",
  },
  {
    codes: "        %{${.%|.)/.|(.六4",
    descriptions: ":5.&\" 9一二\" }[şı^5\"&ı]<, 24四}5:ı#七]]1 十六*>*; {一5=[,;",
  },
  {
    codes: "        )'七4.八1.$一.[3.]:",
    descriptions: "一五ğ三/六.[)",
  },
  {
    codes: "        ?五一=.1'.三二.一五.^8",
    descriptions: "=\"{|> :0九} 九$şı95\"&ı$$十 8七六一'五ı[6(4$ @3一5+5 七<;??\\/",
  },
  {
    codes: "        :四7{.,\".\"\\.:一.{%",
    descriptions: "',ğ5?七;3五",
  },
  {
    codes: "      五@四*.>&.]二.9一.?.",
    descriptions: "十[\\#{ '一50 ]0şı|{四九ı_七+ \"7'%五二ı九7'四| 十'一9三一 (.[\\八^6",
  },
  {
    codes: "      [06<.?九.三六.>八.三五",
    descriptions: "二8ğ%98|*8",
  },
  {
    codes: "        4<%_.1{.%三.8\".七/",
    descriptions: "七%|<二 六>九} \\5şı&六0六ı2]& 8/二/77ı?9/}; <+{?五0 <四0&<三(",
  },
  {
    codes: "        $2.6.}$.13.4三.}'",
    descriptions: "^八ğ0*四:)]",
  },
  {
    codes: "          一]七..二六.<(.四).6=",
    descriptions: "四_5@1 }二/六 3^şı.{五5ı4六. ;6970<ı7*五=五 5\"&88九 #.+53*{",
  },
  {
    codes: "          九*2八.'].$九.二=.26",
    descriptions: "=五ğ.?)+_$",
  },
  {
    codes: "                #>{十.\";.三&..%.4=",
    descriptions: "/:;\\] 六6|1 74şı\"八*.ı212 九]^]5[ı1;.二* 15}?<^ {;=二9>五",
  },
  {
    codes: "                6九5一.+=.十四.四%.&*",
    descriptions: "&%ğ七4'(7#",
  },
  {
    codes: "                $%九_.54.$].{+.:<",
    descriptions: "=,四六< 五.\"@ ]<şı#六,五ı\"二七 6=#?六三ı:,八[四 :/<1;_ 995六=(/",
  },
  {
    codes: "                695_.2].6七.5+.八)",
    descriptions: "23ğ0_9?七7",
  },
  {
    codes: "              +二九3.四0.|+.二四.|四",
    descriptions: "&(七\\. 1.9五 &}şı{[5,ı三_+ \"{_{.[ı{%[九\\ \"=5'>二 7'四980;",
  },
  {
    codes: "              ^八0'.*6.3八.&5.']",
    descriptions: "&%ğ.)|$62",
  },
  {
    codes: "            ?}五,.$>.4>.2:.)三",
    descriptions: "三#七?: <六:\\ \"=şı:@%=ı六二一 60}七']ı<+@&} 08二}3{ 三/]+9]0",
  },
  {
    codes: "            )2九(.=八.3_.一/.4,",
    descriptions: "三'ğ五二#7;[",
  },
  {
    codes: "            $三>_.一}.=).7=.5\\",
    descriptions: "87%2十 $/;, 6@şı3\\88ı五六^ ';\"十09ı4三7一二 \"_],十' &%.:\\三六",
  },
  {
    codes: "            $^八<.#六.9[.\\#.$_",
    descriptions: "|;ğ\\>_八)7",
  },
  {
    codes: "            ^|$|.@三.2}.9>.0七",
    descriptions: "七(;63 .=^* .:şı01六|ı5,5 一([^>]ı'{#?( 2]9?三> 9一:9/六六",
  },
  {
    codes: "            ,?_[./=./4..(..2",
    descriptions: ",七ğ}?\"1四5",
  },
  {
    codes: "    ?^(二.=#.41.四四.:^",
    descriptions: "7九1.七5十7",
  },
  {
    codes: "    七7)&.6].3(.六_.%五",
    descriptions: "_ı%ı 1[%3_三ö/_?_)",
  },
  {
    codes: "    7#[十.^$",
    descriptions: "@09(\\10': 十#{ #=6四*_\"",
  },
  {
    codes: "      9)6[.]^.1\\.8?.[*",
    descriptions: "|?(\\+ \"ö4ü[5六一 </六.>.2",
  },
  {
    codes: "      五{+四.1..四).]).六{",
    descriptions: "3:ğ三二+)5:",
  },
  {
    codes: "    十8.^.#?.[0.=).3\\",
    descriptions: "7ı1ı 六$}0>:ö9&$4(四四 &7三 <8}$8}0",
  },
  {
    codes: "1四.3三",
    descriptions: "1五7九 \\二{] 六.]10 ^%=|@>0五ı, ,#5四 |1一8 >2ğ@< 六=\" \\六2{.><ö6@{四十,",
  },
  {
    codes: "  \\,%|.二[",
    descriptions: "\\二}一0 [一;8.7+|ı",
  },
  {
    codes: "    <]95.?*.9#.)一.6^",
    descriptions: "一三|ı 0*1}\\}&十\"^四一 >3@^ ;)九#>( ;?\") 4三.( [一1[31ı五:} _ü{ 7七0#* $ö1[7四58;二1?$",
  },
  {
    codes: "        \"四二一.4_.(..08.0/",
    descriptions: "\\7&二4 *8=' ;/şı)一{}ı一=\\ ;3/,$_ı:八,(1 :>=6/6 六3八五=一1",
  },
  {
    codes: "        |$,1.^<.6].\\|.四*",
    descriptions: "\"?ğ$|*@,十",
  },
  {
    codes: "          九,]@.七十.(九.=^.?五",
    descriptions: "@,:6三 []?三 {+şı1|四^ı\"十> .,|7@$ı9:]\"+ 六>{二+[ 一:5_1?|",
  },
  {
    codes: "          五*}].7八.[(.]{.*)",
    descriptions: "#*ğ24}_}9",
  },
  {
    codes: "          .三_(.$@.40.}0.9三",
    descriptions: "9九@*= /$5[ ^7şı}#2十ı四(二 \\^.十四四ı(四3&] =;0{二= &十14_&/",
  },
  {
    codes: "          57>>.\\;.三6.一五.{]",
    descriptions: "六<ğ|一一9:{",
  },
  {
    codes: "  41'[._^",
    descriptions: "<| [2^< 六1五+<7 ç[&ış.ı六ı</七 ,:十九 /_4^^九;)ı",
  },
  {
    codes: "    9'1[.6\\.^).六六.$<",
    descriptions: ".5=99$&六+58 {, :'#六五73八七365] ,ç+/ 1九 )},八\\5&五ı",
  },
  {
    codes: "      +.八(.$|.}$.7(.*7",
    descriptions: "36|五八 五8+} 五3şı$$2七ı&*八 _@1;0[ı五=<?5 六[3\\\"9 )*_#3:4",
  },
  {
    codes: "      {*^<.,*.{{.+].七$",
    descriptions: "三/ğ+/57,一",
  },
  {
    codes: "  /七>@.^}",
    descriptions: ")/ğ;(=; 六七?>&?=}ı三95 ,]{/&4ı)0九 &五2&7\")ö二.76",
  },
  {
    codes: "      *五8@.0,..=.三'.9.",
    descriptions: "^{一\\十 :=#七 66şı8八;&ı4八四 >>6>二1ı$03+^ ;8{=%+ }4&八>9#",
  },
  {
    codes: "      =8三五.一[./0.',.(=",
    descriptions: "%.ğ+\":&\\'",
  },
  {
    codes: "        九'6(.7?.2[.六3.)6",
    descriptions: "{@%3* +七:四 ^2şı9六一<ı+[九 .10?,[ı3>/88 四2=#&七 _\\?..[5",
  },
  {
    codes: "        ,二)%.四>..8.&{.',",
    descriptions: "<0ğ5\"?%七三",
  },
  {
    codes: "        九十,|.五).<九.@).)>",
    descriptions: "379一{ @三6/ ?\\şı]6十*ı){三 <,?*$2ı0^{8+ 四&九6九\\ 五十}{@^,",
  },
  {
    codes: "        十三&%.&9.=8.一一.3<",
    descriptions: "=>ğ^六九+二二",
  },
  {
    codes: "  0<}+.(十",
    descriptions: "{7+4一<)1一7 ş}99/二九 ü*80=&四 43049 .'四[/]ş ç]八六1\"/*=8八 \\>,%",
  },
  {
    codes: "      )八0>.三五.+三.3|.(|",
    descriptions: "四,4二8ı 二$\"(8九3",
  },
  {
    codes: "      [3^|.?).41./六.>九",
    descriptions: "699十\\三(| }三_八%9=",
  },
  {
    codes: "      .二05.;十.'六.7@.79",
    descriptions: ">6ğ;9(%?0",
  },
  {
    codes: "      *@{;.;5.?二.\\].<>",
    descriptions: "${_七@ı '5,=#9*",
  },
  {
    codes: "      1@[).\\6.7_.+二.六?",
    descriptions: "八*9五{\\五二 二四^>七^9",
  },
  {
    codes: "      .&%$.六(.五|.>三.6{",
    descriptions: "\\6ğ$六'{(<",
  },
  {
    codes: "    4二]@.>一",
    descriptions: "(ü]ü ,_四 &.ı 89ç?2九八+ ;&{六{;]8. &2;,/>{八 =<;6|+5 四3<六, <#9,",
  },
  {
    codes: "      .)@?.?_.,2.27.十#",
    descriptions: "@)三九四 ]8}十 [8şı四.{}ı五&# 9\",\\\\八ı6<八'9 +%|?.* |(|>8%#",
  },
  {
    codes: "      [七四0.@{.'\\.3?.三%",
    descriptions: "2?ğ七}三:<<",
  },
  {
    codes: "    (}一=._十",
    descriptions: ",/ğ=\"/3@\"",
  },
  {
    codes: "      <?+一.]七.[1.[(.五/",
    descriptions: "'%=5二九 0;}; +)$5 $;&二二,ı^三2 |\\6%4九ş七\\4]#];四'<, 198}@#2)%@(9{|+一 \\9#]*二:\\ı_ı六,",
  },
  {
    codes: "          ]八^8.['.二+.$#.七八",
    descriptions: "534\"_ 九1%& %0şı&;?1ı&0, 八']^七.ı],2二九 \"\"4二二} >五>68(_",
  },
  {
    codes: "          <3一3.&,.35./五.)[",
    descriptions: "<0ğ}二'0#二",
  },
  {
    codes: "          (;^四.(7.二&.九].{五",
    descriptions: "15=0五 &:(> <四şı,九6四ı三八? 五$3,@(ı八6二,8 }\"|6:八 五{五99>&",
  },
  {
    codes: "          $十+%.56.,一._+.1^",
    descriptions: "一二ğ04%4八7",
  },
  {
    codes: "          }+<]./一.六=.=四.\"+",
    descriptions: "=十.九3 二/三> ^*şı2=]0ı'{{ 41[[/@ı;/:二2 二2)|>% |(;75\"十",
  },
  {
    codes: "          \"0八2.?(.六1.{二.6八",
    descriptions: "[7ğ七[3九一]",
  },
  {
    codes: "  十\\?&.九#.<六..6.六$",
    descriptions: ")一 <ü/ü# $九三[. 八3]:)ı .;/ 二/._3 1六ç七\\28_ 二四四\\$1;0[3\\*",
  },
  {
    codes: "  6%十?.0$.0七.]\\.$(",
    descriptions: "六{} 0$ç/二二67 三)[,八1;@) 7ü{$三8:* &7七十(|2|9 ('3[&+^一5 :^\"\\二 {=\";<ı|)",
  },
  {
    codes: "  二(八4.\\\\",
    descriptions: ";+ğ>19九;3",
  },
  {
    codes: "        0{\\\\.^+.(七.?二.0{",
    descriptions: "(&*八六 /|九| [$şı7|%一ı8十: 3?$十\\:ı三9\":4 /六1五1八 2十[六'.2",
  },
  {
    codes: "        @]}[.)>.9*.2,.六六",
    descriptions: "6@ğ{5}&#0",
  },
  {
    codes: "        一9十0.^[.五=.\\=.$'",
    descriptions: "%17\"^ >0\\1 三七şı8@;\\ı六}= ?七5:=7ı]:2\\* #{1%4五 /一3#7@@",
  },
  {
    codes: "        五:;<.六9.\"..;%.七[",
    descriptions: "3#ğ>?八]\\/",
  },
  {
    codes: "          ]七|四.#].:6.&九.[六",
    descriptions: ".:@3五 2$十= :9şı十|/@ı9)1 \\(九九4二ı%6五五3 ^)&@=* _?'五|85",
  },
  {
    codes: "            =+5\".5@.$'.四{.4)",
    descriptions: "9214 六]72ı 五;一|?$ @1六[五{0",
  },
  {
    codes: "            5九;8.5@.:/.|*.(^",
    descriptions: "七\"ğ_七{0九.",
  },
  {
    codes: "          ,|]/.=\\.七\\.^九.]^",
    descriptions: "五=三?+ +六4$ 六1şı>@十二ı='& ]<四&+#ı=]\\3一 =41{@/ '$11十']",
  },
  {
    codes: "          八一>5..二.[@.(三.|_",
    descriptions: "[五ğ;&($@5",
  },
  {
    codes: "          5八六&.}\".5].;4.一3",
    descriptions: "@四五%六 /十二8 .$şı,}七一ı}=( 7|:4{四ı4}六*0 _8:'=> 4八八\"4&\\",
  },
  {
    codes: "          五}四].?..:/.\"十.五)",
    descriptions: "$0ğ一\\*六]七",
  },
  {
    codes: "          三0^@.]}.;4.?=.?1",
    descriptions: "0}1_三 [1$二 2[şı75/7ı72\\ ^%&一/@ı*^+|? 3<6,4> 一};>$_三",
  },
  {
    codes: "          )@三+.一[.八}.5<.?七",
    descriptions: "十?ğ#,)#>?",
  },
  {
    codes: "        七8?八./3..@.&{.五%",
    descriptions: "52&+8 ^九5& 1七şı#/,}ı五八\\ 七23[三一ı|,.八{ ::一九4= _&19\\一<",
  },
  {
    codes: "        ]'1].=十.?=.1%.五七",
    descriptions: "_'ğ六_1;}7",
  },
  {
    codes: "          ?七三3.九7.||.7/.0'",
    descriptions: "%+697 $/6: 5$şı三\\0七ı3+^ +5]7+'ı?3/?\\ 0六4七5, #.{._八$",
  },
  {
    codes: "          3八]8.{..)&.{六.*/",
    descriptions: ">.ğ|\"5七<}",
  },
  {
    codes: "          1)五).@;.>\\.十|..五",
    descriptions: "9>_<@ 六[九{ 8九şı:五9二ı>[? 四{[\"七4ı}}_7_ >四八4九= \"一8四九;六",
  },
  {
    codes: "          一[六*.十1.[+.*].9'",
    descriptions: ">八ğ9*;\\:二",
  },
  {
    codes: "      /{九1.>;.'三.八%._?",
    descriptions: "[/\"\\七 ')#? ;四şı9]六9ı||' <:九>八[ı:\\,七^ 0?*=二, ^\\八.[6$",
  },
  {
    codes: "      1[}十.十&.$\".|{.八/",
    descriptions: "八3=七?;四ö#ü {|[= ];9(601) .5=}二9%,70八'",
  },
  {
    codes: "      <七15.八&.|七.六5.>>",
    descriptions: "'8>])+1 |十|三(_>> (二6=五_0ö@_/_",
  },
  {
    codes: "          8八%9.%^.四'.?3./*",
    descriptions: "/7 .ü+ü% $%:七7 }1(]]ı 六?) #/.8? $#ç$二 76( &{\\.(&七ö}ü '7:{ *\\一六+&",
  },
  {
    codes: "        十2+/.13.;$.?}./九",
    descriptions: "=>ğ八\\:(?0",
  },
  {
    codes: "  5*_$.三4",
    descriptions: "}4一八= :4 5]<ç六=<=",
  },
  {
    codes: "    *(7三.#<.?四.'@.<&",
    descriptions: "0九三]= 九&_2 =八şı\\]2_ı一九) \"]六六=3ı{9?)\" 16>]$| %{四,+*)",
  },
  {
    codes: "    8;6&.//.七,.$6.75",
    descriptions: ";二ğ}0.]=\\",
  },
  {
    codes: "七8.|=",
    descriptions: ",三)八@ [,0(<_+,ı ($)^%七|$ '(1 +九五&%\"32ö= 9\\9 (\"_ 1五 ı/ı1ı 五(ğ六ş>一?8**%",
  },
  {
    codes: "  四[:8.七)",
    descriptions: "7<|二六9&, #'7(1, 3{八]8 <%三3 }7'']九 (<0]{[_九:+}八 >五&一七(",
  },
  {
    codes: "      },十..7?.'|.&$.四[",
    descriptions: ")三七 +\") }ö一1七 二五29/|= (.95一\\1/<0三九+3)",
  },
  {
    codes: "      九三+'.}=.一二.|..\\四",
    descriptions: "#{ğ<ş六&3 六^528九 (6@]) (3九;10|",
  },
  {
    codes: "      一=0?.八].}).\"三.@8",
    descriptions: "1+ğ>%7九七@",
  },
  {
    codes: "  $18八.*1.5(.八%.5'",
    descriptions: "\"9_^五7\" \"7şı一四:三$) ş?五ı\\\"/四 三ç0[ +(十,/十ı@$1 &ü九53@ 四;三=?>九",
  },
  {
    codes: "      }(三8.}@",
    descriptions: ":7九 4^ğ}一}\"9 ü|一^五 五十 \\<ğ)十六#ı4ı:一九 ç}:五[4五七8 2?七}五$9 ^'ğ七ş四[)7+",
  },
  {
    codes: "      73[#.|,./七.4[.^|",
    descriptions: "九\\\\.三 一)\"{ 六一şı)8>三ı),四 @7$=8八ı六7'49 }一_3;四 ,十*3[]$",
  },
  {
    codes: "      <^,{.;..三).#=.@.",
    descriptions: "//ğ/.<九\"一",
  },
  {
    codes: "    \".(%.{.",
    descriptions: "[六ğ\"\\7|,+ (一_七 .#ğ七48%6 ü五\"+% \\ç^:八七}四8)",
  },
  {
    codes: "      5{二;.&&.05.:>.}6",
    descriptions: "<:四,一 76十@ ;3şı6^;七ı4五} @$七3:一ı[.%5四 <一9<=七 })*#**1",
  },
  {
    codes: "      :#六\\.8二.,'.一2.<{",
    descriptions: "@>ğ3:八8七{",
  },
  {
    codes: "    _七九_.'七",
    descriptions: "/11 ?[ğ56四?$ ü12\"十 >ç5|8\"二五5五+九",
  },
  {
    codes: "      五_[,.>\"..七.9五.&.",
    descriptions: "\"*七}四 三27: >一şı7]十十ı)3六 ,9/_8<ı(31+. >二1(\"五 |}9九9(,",
  },
  {
    codes: "      17二$.3[.(^.八$.\"+",
    descriptions: "&%ğ_26六?2",
  },
  {
    codes: "  }八九>.十$",
    descriptions: ":?2五[ (, <#_ç{四一{",
  },
  {
    codes: "    ?六>:.5$.@&.=[.&/",
    descriptions: "九九'%.&9, >^^>.'4 /#0_ 九_>].<七 4十\\ 89\"<+:]&六|二ı/\\3,? '=三十< @.\\]",
  },
  {
    codes: "        5.7一.;9.3*.^}.+五",
    descriptions: "5八ğ1ş]八? 六/#九_二 ($九六) /{(&\"=0",
  },
  {
    codes: "        二>6八.:二.$+.*八.[九",
    descriptions: "七七ğ>8(*三十",
  },
  {
    codes: "      8{5,.@,.|9.五四.4+",
    descriptions: "#|0三8 四九七3$%=@7<@ [ç ü@]7十;{<8",
  },
  {
    codes: "      八十:十.*5.|_.>@.2八",
    descriptions: "八;ğ&';,.&",
  },
  {
    codes: "$一.|五",
    descriptions: "3097/8(ı九, 7\"&,>ş1ı$ı)四ış 五|(ı =69ı[ :^7: 十6九 1*4ı>_ı $0,+ @十ü$ö>)(@{;",
  },
  {
    codes: "  ;|十0.?0",
    descriptions: "八)三十六1九ı6 八\\ü^ö1%17/",
  },
  {
    codes: "    $八,&.#?.*9.?9.*二",
    descriptions: "@2;\"八73] <;8/]三# $八86六#<#ı 2五=9<^2.%二",
  },
  {
    codes: "    七?.4.().@@.?/.,6",
    descriptions: "{;ğ.<八.五十",
  },
  {
    codes: "  十<[..\"7",
    descriptions: "9八ğ?\\ ,\\1ı_ 5}ü>ö2一九五6 (?<.四一|# +5ü#ö八%,八 #$7八\")",
  },
  {
    codes: "    八&|8.6@.,\"./^.十=",
    descriptions: "2八.[@五 9七\" >ç二{ )3七)&$) (1一$九+&\\^, 十/一 *2_四1568ö. 5$ |?# ).1/:2+ 九:'%6ı =6五",
  },
  {
    codes: "        ;68%.<7.三,.*/.六5",
    descriptions: "十,90=ş2ı=ı6[ış 85=ı 一八(ı* 六9ü{ö%/*八=",
  },
  {
    codes: "        3{二\\.\"[.[0.|\".+:",
    descriptions: "]7ğ8;八一,十",
  },
  {
    codes: "        九_一..77.一?..九.;/",
    descriptions: "五,6/<ş一ı;ı一,ış 7{#ı 四0?ı一 [(ü?ö?八@$#",
  },
  {
    codes: "        十}(\\.$5.62.<].@+",
    descriptions: "4&ğ九>*.>[",
  },
  {
    codes: "  四'九八.)3.;7.九@.|8",
    descriptions: "二四83$6@ $ö7ü} /(şı]ı六ı]八五 (三三三9+@ı9 0$\\13%八 ı六?^<四/},ı,",
  },
  {
    codes: "  (2.'.\\,",
    descriptions: "九1)三5 )' 八=>ç(\"[4",
  },
  {
    codes: "    45}二.{一.一^.04.,五",
    descriptions: "*^ü.ö+6三++ 7}? %三_68七+",
  },
  {
    codes: "    (四#}.;>.\\三.\\7.@=",
    descriptions: "39ğ87九,六0",
  },
  {
    codes: "4>.六]",
    descriptions: "2[+%%) 五6六1 九四一{4,@一]8}*]^= \"十\"4#: ?五'9;/<,六十 八;^[四%[ 8ı^ı五3%五 '$",
  },
  {
    codes: "  四/'七.\\8",
    descriptions: ")七/{二^'五4三, %四6<9 \\(>{40\"八5二八}<, 八九21&%3^14 &&2七@二*七(ı, $8);72七'%5*",
  },
  {
    codes: "    07?%.8,.3#.九%.9十",
    descriptions: "七五@=+;? 七ı五ı#\\<=ı",
  },
  {
    codes: "    8五,0.[\".3^.\"八._\"",
    descriptions: "九.ı= =ş#)) 7ı=ı#五8九ı",
  },
  {
    codes: "    '9\\4.1[.,1.4一.五八",
    descriptions: "İ<}9\\ 八ı<ı八八42ı",
  },
  {
    codes: "    }$:<.八).六).二0.]+",
    descriptions: ".十ğ,四}=7*",
  },
  {
    codes: "  ,五)_.*6",
    descriptions: "?;]三*ç1>;< 7ı@ı:三*|ı (八[八@ü*+ }ı,ı0七二$ı 四六'八$)",
  },
  {
    codes: "    八377.{;.?十.%\\.)_",
    descriptions: "^ü)>, 三ı:ı56+6ı",
  },
  {
    codes: "    &\\5/.@@.<@.\"<.:*",
    descriptions: "|5ğ1#&九八?",
  },
  {
    codes: "  一]|;.{6",
    descriptions: "[八ğ7}1五5六",
  },
  {
    codes: "    六<}三..=.1一..,.十.",
    descriptions: "^6三四{2八 ü/ü250@/) 'ı}ı九)</90二ı六三 #九\".9* 三ı五ı=*56 #一 %六}8四\"6",
  },
  {
    codes: "    |^1五.六四.0_.8].]+",
    descriptions: "Ç3]030二, 8{& _@ \".\\+五+)6 ü=ü0三9四一3 \\ı+ı^九=53二\\ı\\< =?\"5二2 }ı1ı七]0九 @# \\#14三九六",
  },
  {
    codes: "    >四=|.4九.8).2}.6.",
    descriptions: ">4ğ一二二,二]",
  },
  {
    codes: "  *\"2=.(>.%八.@九.<=",
    descriptions: "3>*五' <8 0>0ç十;三>",
  },
  {
    codes: "二,.\\&",
    descriptions: "|35^十'2\\十^2ı, \"7;'$=,<=6]八 五> 二8ğ]三 []ğ一3?8} \"0 ?&?\\二;1{= \"5\\五=六五}",
  },
  {
    codes: "  ]&+<.00",
    descriptions: "七2&;03 五56八四 (ış ^)4ı#ı 075七 ç四^+五二%\\9 <六}# 4\\0}23ı^ \"=]*[,1[+\"十5 /\\8四",
  },
  {
    codes: "    }十8?.@:.二^.<五.;.",
    descriptions: "四三>>) ^], =%,十/二7 _$ç\"9#03",
  },
  {
    codes: "    \"5九).%九.七7.五%.四)",
    descriptions: "&\"ğ九#\\7十?",
  },
  {
    codes: "    /0七7.[3",
    descriptions: ";>:\\,:.ö1二ü 九;<_$55",
  },
  {
    codes: "      十13/.=*.>十.)4.^%",
    descriptions: "[]&]6 ,$^ %@三一九)0 4,ç2'一三}",
  },
  {
    codes: "        ?\\,八.|,.六&._#.'7",
    descriptions: "五$}: 五四/四(七 |{:一==_",
  },
  {
    codes: "        \\八%%.八八..0.]十.=.",
    descriptions: "[ö8=六 七三三 '95五6|$",
  },
  {
    codes: "          %:^?.@&..%.六@.6]",
    descriptions: ";=%,# ';\" |@908)七 \"_ç*二六%(四@#",
  },
  {
    codes: "          5}||.\"十.九>.$[.#十",
    descriptions: "^=9十一 二六) 1$(4'() =6ç5/ 40_(三 ;/} )*#1一#5 0^ç%五$六?((^",
  },
  {
    codes: "    $@&|.<;",
    descriptions: "^2ğ\"87八三{",
  },
  {
    codes: "      6%&十.\"].;).,4.4.",
    descriptions: "6#\\94六) >二@七7三ı[*二 .<%&9(\";=1{ *&?8(*1|,51ı",
  },
  {
    codes: "      .\\;?.|#.=9.82.[{",
    descriptions: "+十ğ九.]六?^",
  },
  {
    codes: "  @8$].=>",
    descriptions: "?六一六7 ?>. }五>$\"398六十60 (@&@$\\ @>八 *1=?*4) {7ç1二^五$&&?)",
  },
  {
    codes: "    9)五;.{'.>(.<七.7&",
    descriptions: "\\十/[八 47* 五7147?& (_ç(&#([$三}",
  },
  {
    codes: "    6>?九.*\".@|.#十.十_",
    descriptions: "6@八二) ,十八 ]):%>#@ ,\"ç六] 八八五#) $%九 七004$=[ (七ç2\"[六_十2)",
  },
  {
    codes: "  @);].七*",
    descriptions: "93,+* ]九} 5)五,8|')|+7: (<\"8%/ 五?$ {]:,>3} :+ç十(}?.3七十)",
  },
  {
    codes: "    4}.十.九8.1).(].$五",
    descriptions: "}4{;{ 07@ %,&)|8, &*ç&18十^五十5",
  },
  {
    codes: "    二<九=.2/.八八.;一.7<",
    descriptions: "?_2+二 六9) 67#}77> %一ç8八 :|$^. 3;4 一?<{.1; |5ç十%9四:11>",
  },
  {
    codes: "  四)六<.2九",
    descriptions: "24$9%;?+四 /7.< 二[ğ8$.{八 $[#^6[37,9 /4二 2ü[=#46: 三\\四|5''[ <<",
  },
  {
    codes: "      %0'+.(%.五六.'九.&>",
    descriptions: ",2,@^十,+三九ş +ı五(八}2ı+ ,,),;六<?#|ı 'ç八6 .(<&\\>*",
  },
  {
    codes: "      (2,四.&五.{9.\"}.]8",
    descriptions: "[3ğ@<=3#$",
  },
  {
    codes: "    _六32.24.,六.6四.09",
    descriptions: "^九^0五\\\\ @')( 10ğ][ 3\"ğ七?六0@.?3",
  },
  {
    codes: "    ,七65.3}.8_.|^.(2",
    descriptions: "{$ı #+7&一03|ı ( 十\".三2 ;(6四四?0+#<]四 }\\\" >八八% /\\+(; 九九六十<四七'ı 十六;^ç)",
  },
  {
    codes: "    ['^五.)4",
    descriptions: ":}ğ6(\\'(;",
  },
  {
    codes: "      ?六}|.二3.^<.$|.3\"",
    descriptions: "(六1|\\3 4五ğ十$(1 一6*(28>26",
  },
  {
    codes: "        {3[五.五9.6*.9{.七^",
    descriptions: "5六四八1'8&?[ };六ç @.({5三九ı2@ .,<ı+十,:# 0>&=%9 3}ğ^*5六? ü/'&03}一",
  },
  {
    codes: "        #8\\十.[一.3八./..|@",
    descriptions: "%,] .}5(] [十/=>;$\\三^",
  },
  {
    codes: "        七[一(.86.二六.:+.>十",
    descriptions: "}?ğ%一1二三/",
  },
  {
    codes: "    六三;三.2{.)十.\"9.9^",
    descriptions: "*4ğ#{\"八; 七]<五 1&_?}五=/3 :>9.九九.>} ;}0/@一 ;'**$三\".$八",
  },
  {
    codes: "    [六}?.0]",
    descriptions: "^.ğ33;十$_",
  },
  {
    codes: "      一二&三.=&.$一.}九..1",
    descriptions: "9五六=&\"+|ö九}}四 2$ %\"/\\#四/'1/%| ($0 _4@7 40>90;七六$;#ı 7ç\\# 七61(.,2 \\\"%;ç)",
  },
  {
    codes: "      &#:;.,2.)五.6_.=七",
    descriptions: "#$ğ\\二^二5七",
  },
  {
    codes: "[\\.七#",
    descriptions: "9&ı =@ğ[ş/$$<ğ六 &=十6>九2 (ö,四+,)2九8 ı@ı二*(, /^ş十2.{, '_,$<三五, #:^ı=}\\,",
  },
  {
    codes: "    |\\/[.&十.四+.十0.?十",
    descriptions: "&[4?= ç_$ış2五 二\"ı八$9 =] ı>ı二ı7ı5'^",
  },
  {
    codes: "    90\\三..6._八.3/./五",
    descriptions: "9ü'2ş 八六*_*0(7 (05八18) [] ı$ı.ı?ı'+一ı",
  },
  {
    codes: "    *#_#.)六",
    descriptions: "?\\ğ<3(0,\\",
  },
  {
    codes: "      一.||.5九.?1.(0.3)",
    descriptions: "^)\\二[8]|四{#=] (四@八6_十 2; ı]ı_ı(ı五98ı)",
  },
  {
    codes: "      *5七,.'0.\\2.\"#.7(",
    descriptions: "2七ğ=+2四&?",
  },
  {
    codes: "  三}六%.1{",
    descriptions: "5ı55{, 4}九7\\;< }>5_ 97@7四二$二,四+ ,$&)3#十]}九ö([\\5?",
  },
  {
    codes: "    /6$0.[?.二七.七..&|",
    descriptions: "七八四20:;五@, 七/三8二四三$)}ö\\_(]",
  },
  {
    codes: "    7九0).三$.2<.<8.7八",
    descriptions: "}=ğ*2 @>8}#9(%|@ö八?[$",
  },
  {
    codes: "    +#六_.}(.=3.?*.九九",
    descriptions: "五$$^9五九66五,[\"0 +44\\二>八6, <三+@[?9/^五 十;\\&_\\] ü七[@#*6;6 ,4 6%35九 \\二十>]1$|?&_",
  },
  {
    codes: "    二(1'.一五.+七.;一.$五",
    descriptions: "六<ğ*\\五\"八[, 6.七ı7 ü<ü62(七\\ 3ç0_ ?<一三#?@",
  },
  {
    codes: "    }?5五.5:.六二.二@.5'",
    descriptions: "一7ğ四=\\_1六, ?ğ[ç, 85ğı2 *&四<'十, 57ğı五 ;2'_ 七077\\7$&三 )ç2八 |4八62&三",
  },
  {
    codes: "    &七.$.6).十6.?[.一%",
    descriptions: "#+ğ:*||%七",
  },
  {
    codes: "  ({$七.=,",
    descriptions: "^}$ı@1一 <8}四 +一四.@{,%# <41]/:|0四",
  },
  {
    codes: "    十^)七.\\3.7?.二;.7\"",
    descriptions: "<} )}三ı)#: }三(^+ı (9?)40+$五八5 ç27ış{^)",
  },
  {
    codes: "    二(/四.=一.&4.0十.;四",
    descriptions: "7(ğ)<>6|}",
  },
  {
    codes: "  '57%.6*",
    descriptions: "64ı 14ğ)ş=2_九9.]0{] (^ş@九二ö}三}$)",
  },
  {
    codes: "    ?)^..,3.八:.#九.1\\",
    descriptions: "8{0六0九五$_5\\23|'*&; 五92[* ^ç ç{8ı | +} 五,\\7 ;7(, ^# ,@7{ 九15*&&+ )^^)ş 九#",
  },
  {
    codes: "    (8一@.3;.44.]7.+.",
    descriptions: "\\<ğ9八#四三六",
  },
  {
    codes: "  3九&,.2@.,?.28.%八",
    descriptions: "(:(一 2094 >[ğ2九 ]三}九九\\ı [ı<ı3四ş'ı}03^) 1&,6@= '五^{|三{@/",
  },
  {
    codes: "    七:@(.;3",
    descriptions: "\\ı+7\\ %ç?\\\"四 <4/九八七九 <*三] 5八6/1>&五&四& 4*ş@_]四四: /三=三 ı&ı+\\(%十",
  },
  {
    codes: "        一?5八.().&].?5.二?",
    descriptions: ">^$9;,40一八 =2一*七6九",
  },
  {
    codes: "        9215.九7.\"*.({.@'",
    descriptions: "1%ğ&2三7六^",
  },
  {
    codes: "      '_4九.4四.*>.\"9.?;",
    descriptions: "08ğ_1)八7七",
  },
  {
    codes: "    )7?五.]&",
    descriptions: "|^ğ_/)7'一",
  },
  {
    codes: "      <二三8.[八.二/._6.*]",
    descriptions: ".( @[_:şı\\ı ,七[ı5'三ı 六\"* \\:ğ.十_*3 *\\ğ三?十91一 @_八=2| (.六^ &7ı十四一",
  },
  {
    codes: "        五]>}.|'.(<.*).6.",
    descriptions: "&,,+四'1%一1 <$.8*0) \\)#九< 三@;]一_{9/ 3};2|( $>四5+#'2#, 2&五四八五)ı",
  },
  {
    codes: "        '6^>.0@.一\\.05.十#",
    descriptions: "@九%|ö|=五7'ö八));",
  },
  {
    codes: "          #四9十.[三.62.?^.一[",
    descriptions: "(ü6<ü六&六4 88七,@四*&ı",
  },
  {
    codes: "          五一)%.3..').一七.*;",
    descriptions: "\\()@%.6= ,:5997( 四9%:九五 |7|#一8五; 2^, \"八$9{ı 六'?7|4) :{八>7三",
  },
  {
    codes: "          @9';.7五.]*.=8./<",
    descriptions: "三\"ğ:&}|_7",
  },
  {
    codes: "  _|九4./五",
    descriptions: "8\\[一九 ^= 29一ç|^三5",
  },
  {
    codes: "    8六>5.'[.}^.八}..%",
    descriptions: "@一三七.七].5..$( 三五; ;}>[117'九^5=%0 [>九_?九8^四:ö6八六[9 93{ 九=.9@;#",
  },
  {
    codes: "      5{8,.\\*.},.3,.>}",
    descriptions: ",[,九ö12.9:ö>_/[# ?十八 _$'三0五{",
  },
  {
    codes: "        <{四二./?.?九.二4.{$",
    descriptions: ")'*$(]9}ö0(:]四 ^五+ 五.六@^(九",
  },
  {
    codes: "        \\五(十.+{.7+._(.+一",
    descriptions: "Ş}{9四1 %, 7@ğ>5 {{ ı:ı十ı(ı八79ı=8 }\\一 ,^57();",
  },
  {
    codes: "        =十@4.二2.&5.$..9\\",
    descriptions: "/<ı +}ğ0ş3<)''$[[\\(,6 (2ş八_?ö>十]\\) '二* :一>五@六二",
  },
  {
    codes: "        [<_%.八5.;三.十9.};",
    descriptions: "}1ğ五[92?{",
  },
  {
    codes: "0>.21",
    descriptions: "三.}一5&#}{:2 8) $+ğ五. /|3<四 1.>$}[十一/ı (4.^十\" )*(, :|8 /#5/3",
  },
  {
    codes: "  ^六3二.6|",
    descriptions: "]$六\\<六一87]4 ^六 #_ğ*] /四$\"0 九++:>:%.9ı",
  },
  {
    codes: "    ;4一三.85.\\=.)<.<2",
    descriptions: "%][>8)' @七_/[;2二1二 7八)'\\八ı59* 0ü<_02 </|.|%/",
  },
  {
    codes: "    四\"=八.八5.62.'?.;8",
    descriptions: "{;ğı> (54$七二|六\\3 36_五@\\ı}'\\ <ü#七)$ 六九三02^:",
  },
  {
    codes: "      ,0六7.$@.2].七../<",
    descriptions: "*4_@4; 8.;\\ 八6%七 九}'\"+_ı'#1 十\\3{ı8ı #九=${ _五?^ 五三(2ı\\ı |/@8< 5ü/6\"四5&三四四一",
  },
  {
    codes: "      .<,@.(|.+:.三#..二",
    descriptions: "5>ğ2};/]>",
  },
  {
    codes: "    )&+7.]=",
    descriptions: "[{六*五_3七\"?)",
  },
  {
    codes: "        ){]3.?(./5.[$.0十",
    descriptions: "$6>/$<<)三6< 0ç$\"",
  },
  {
    codes: "        <[7%.#三.九1.八+.六+",
    descriptions: "5)ğ<十 一)九+\" $六#(6七5_*ı 十ç.3",
  },
  {
    codes: "        ^五<七.>%.:0.4_.<1",
    descriptions: "七{(&三*48一(_ /ç$8",
  },
  {
    codes: "        五82八.79.:..{$.5/",
    descriptions: "5九ğ02 &<.?* :七8九?七|五&ı @ç五二",
  },
  {
    codes: "    ;#'四.七一.<9.>六.}9",
    descriptions: "7^ğ1%6{七=",
  },
  {
    codes: "9'.6.",
    descriptions: "七\"四^二+六ü}}20 ({/四[:三8ü^ {四一\".七_ ,<,一^;?>5ı 2:5]三); [ı6ı\\+.ı3 =,8十",
  },
  {
    codes: "    十0+,.九_.5*._五.=(",
    descriptions: "4=?+] 七'ı6ı4ı5{# (]\\八_$ö|五)\\)",
  },
  {
    codes: "    ];&5./%",
    descriptions: "Ç一|.şı/ ;:&^*0^ \\9|9十:;3?ı",
  },
  {
    codes: "      17&6.八?.4一.九3.0六",
    descriptions: "}}5 #5$4&1 _<4?*2]%ğı ç八0=şı}ı0 @:<: 7ğı]^ığı / 2{.ı *六ç|@?._(八",
  },
  {
    codes: "      ;+:九.,\".;@.十八.+8",
    descriptions: "@二; )9[*:八 [:一五=.二]ğı ç{4七şı;ı8 {??1 $ğı37ığı 三 <<.ı 87ç\\)",
  },
  {
    codes: "    };十5.5:",
    descriptions: "9[ğ.,8十6/",
  },
  {
    codes: "        八{三八.?七.'/.,|._'",
    descriptions: "四6(_\\ 2十>_ ^}şı五六}{ı=_= 九五3[>&ı68=$# :十三9<\"  ^{'七}四四",
  },
  {
    codes: "        ^;%?.<+.\\1.九<.1*",
    descriptions: "1#ğ}05:@^",
  },
  {
    codes: "        8$#{.]).?五.]>.3(",
    descriptions: "'|(21 8)二4 .7şı:三?=ı&+6 八84}?8ı九((十[ ,=3八\\七 .+:*0/3",
  },
  {
    codes: "        5@)[.三_.8七.60.'*",
    descriptions: "&6ğ九//?2九",
  },
  {
    codes: "    )&五0.一].#1.{(.)一",
    descriptions: "七0\";八 7]4二,[ .2*[^&五,六6 \\>2* 0$ı$ı五#五二ı]< 62\"八五% 58\\++=6",
  },
  {
    codes: "    :1+一.:#.,八.07.+@",
    descriptions: "İç六=7[6三五@[ (四四 二九\\)ç) ,0四\\\"六 #三?:;99^%六 3$'_ 4$ı_ı%5三>ı|$ 4;.$\\9",
  },
  {
    codes: "    .二]_.三四.1%.#七.一八",
    descriptions: "İç九<] 九九九^九1ı 三<$/^;14 <ç6十 ,;ğ .1 一\\$ı一 :十六^五7_/@&",
  },
  {
    codes: "    37;\\._$",
    descriptions: "八[ğ_*\\:\"/",
  },
  {
    codes: "      十%\\|.(&.\\0.:4.(十",
    descriptions: "<.七^0+{@##8二2,{ (_九2| )@\"@一[ 三<:> #1ı三\"^ ?十%}_<(ı 17*ı<}ığı 0\\[ <$15'#) \"五ç六一三1一,{>",
  },
  {
    codes: "        6.:5.:%.;'.二2.二>",
    descriptions: "1七288*\\ö七",
  },
  {
    codes: "        ^+6{.4四._}._{.7九",
    descriptions: "^5ğ^1<7&@",
  },
  {
    codes: "    ^8,(./*",
    descriptions: "İç二|, ,/@.六/ı 二7六],9;7 九ç2@ \\/'5 <?4七五,十9$三",
  },
  {
    codes: "      '1\"+.\"8.[十.十/.1,",
    descriptions: ")'六\\& 七=33 68şı1@二*ı()二 %五九_六%ı2,>六2 九@:九,9 '*|/\"1/",
  },
  {
    codes: "      {+五_./,.?四.四一.\"一",
    descriptions: "[)ğ8四;四'8",
  },
  {
    codes: "    \\2十'.七6.<@.(+.四七",
    descriptions: "İç89@ 4|^0十=ı 57*9]?二47七' çı7$< 十+?四/ 七;八@%@ı\"ı 4}ı)4*: @6(7 .4一/^,<'\"=9 #ç四8",
  },
  {
    codes: "    \\八九).五1",
    descriptions: ")1ğ\\/%[2四",
  },
  {
    codes: "      三@九>.}:.*&.1%.二5",
    descriptions: "19八;[56:. ç^一?$ 2ö{[五>8, ;ç ç*,ı 7,% 五< ];ç}:{/; 163;\\%/ 6八@6ş ;六 çı(ış",
  },
  {
    codes: "          二'0+.^(.1_.%2.七$",
    descriptions: "@>#九, %<^0 二6şı*4+}ı7;) $_'6%=ı'七[0_ 二>二*'| *0|三{七七",
  },
  {
    codes: "          =三7).02.=(._五.)0",
    descriptions: "[3ğ}(7六>|",
  },
  {
    codes: "            五%;/.六\".8[.:4.<二",
    descriptions: "8一._十 二|6八 0)şı<,;<ı>二; 7九八(=|ı>五九#$ \"2050? /二*480\"",
  },
  {
    codes: "            六六=八.66.五5.>,.6\\",
    descriptions: "@|ğ++[=|0",
  },
  {
    codes: "            %><八.:八.七$.(>.}{",
    descriptions: "七?>五| :七二[ \"*şı七+*一ı@4@ '#?]%,ı@七<九@ 42/二(^ <&<3\";(",
  },
  {
    codes: "            44;二.$4.85.<^.](",
    descriptions: "{三ğ@.四{]\\",
  },
  {
    codes: "    九\"5>.八/.{4.8|.二0",
    descriptions: "*][|]2一ü;'/;九 )六= _=^3%_$ (4十<二|<'ü1 $:^九五\"二 @八({1七8{*ı}# 7^九 '+5)1$_ $|}_})",
  },
  {
    codes: "    九+.五.1^",
    descriptions: "%@ğ>八;4九3",
  },
  {
    codes: "      2^^八.[七.'@.1>.@六",
    descriptions: "\"@一7.)@.?五 '&$7 五$<六.<4.#$ <0: 七.七@]?]6{17ı十@1六( *+:五$> 八> 5九$5九#?八. 六{' 一(60?^*",
  },
  {
    codes: "        [0<3.+\\.;].?/.五}",
    descriptions: ":ü@üş \\94<]九 五|9九 &$0;46 ,三){五ş0<<ı",
  },
  {
    codes: "        }九四].2五.\"五.一[.{\"",
    descriptions: "一@5六 :'三_' }>8,$= 4七\\|五一}ı",
  },
  {
    codes: "        7}1%.+,.5四.#&.;%",
    descriptions: "&5#< 2%@^/4&1[ \".二05七 ?0{8;四六ı",
  },
  {
    codes: "        {#'}.四[.0\\.,@.0八",
    descriptions: ">3\\36 一. 九$+&, 四$九)>;\\2%= #三{};1 9?8一97#ı",
  },
  {
    codes: "        5三+\".={.六}._二.#&",
    descriptions: "五,ğ?\".七03",
  },
  {
    codes: "&十.)|",
    descriptions: "#]5}şı九 七ı_二4] 五6%)}{,四1ı; ş7ş[八\\|3 \\一六8 \"'ğ)九 六五4_\"四ı 六三$七;_|>?九@",
  },
  {
    codes: "    ?{*十.十$.^七.<三.7\\",
    descriptions: "5(7三37< $89|2*ı二^$_一>",
  },
  {
    codes: "    }3>>.四1.$六.三一.4=",
    descriptions: "六二ğ?十+_5+",
  },
  {
    codes: "  >_七6.二_.$\".'\".四.",
    descriptions: "Ş九ş(5\"%9 {七14 &$ğ6: ,,_}.,ı 六七五十,'$/{}| {0*) _}七一9))|; 五;=,六三",
  },
  {
    codes: "    {6+5.{}.?0.>四._[",
    descriptions: "Ş四ş.:六*), >)$_六一0ı, ç二']8^一,ı 7@{0 ,?ğ七} '三%^八一ı 一52?七0.十9七, )四4三",
  },
  {
    codes: "  *:=四.#(",
    descriptions: "<%ğ一[ \"{{^+六七#] 61<# '=&]$%三八{七7 )二;%三:.|,ı (ı[ı ^#二 /ü:.)41",
  },
  {
    codes: "    四_64.*0.(三.8五.;<",
    descriptions: "%)#五&(, #+9., 4#\",?322= 5四#六 =7*八二六\" >3/|]5五5? _^.^ 9\"73|_5\"六0} 六&%&四3|&5ı",
  },
  {
    codes: "    #\"0}.)>.>九.4..$六",
    descriptions: "十_ğ三2#_十#",
  },
  {
    codes: "  =十+八.}4",
    descriptions: "八49#/ =, 四>#ç[,)*",
  },
  {
    codes: "    34\"&.六>.$=.\\三.%2",
    descriptions: "十9%一şı, %ı六四{6 ,^五;9六:|\"ı八' {3一 6)?054#",
  },
  {
    codes: "      ^#>{.&9.>六.[$.#7",
    descriptions: "8?ğ{7 七一)七^/<2$ %四\\[ \\2一(^@八八[四+ 5#;(\":{*,ı^九 [{+ @'十0+三2",
  },
  {
    codes: "      二.一4.&1.6..3@./}",
    descriptions: "七八ğ,_五:8'",
  },
  {
    codes: "(9.61",
    descriptions: ">[+8ı =}39 /@ &$二=^/09ı ({*5[#二四:三?; # [)5%九{9八# \"6#2 \"'5% {_三 +=38",
  },
  {
    codes: "  %>2;.八^",
    descriptions: "İ[六五四 五19)\\] 五\\*.ü|%&一 (#.(五\">:^+ ?'9六/}+\" 4%]_7一 .八4=>{4 $九3三三);",
  },
  {
    codes: "    八]'..五2.一,.九*.4五",
    descriptions: "八十十36.五 2+六七@{ı;&< $8#._八$5+",
  },
  {
    codes: "      7<<_.7;.33.1*.4二",
    descriptions: ":*八十}/=%#4 8, 九|}0$5<}一二 01\\八ü二*:1",
  },
  {
    codes: "      [八.@.@九.=%.97.%>",
    descriptions: ":?ğ<8%+6八",
  },
  {
    codes: "  )[&;.5]",
    descriptions: "5@şı<ı九ı &>*;二&{ ü一/((\\%({= 二^一36九) %)8>{/$& _<8八五> 0]?>ü<[':",
  },
  {
    codes: "    /{?).4;.@+.5_.六<",
    descriptions: "@ğı6一ı| ö7çü7ü4ü }[]1\"_{4;; 7+四三68 .>}|一@=;",
  },
  {
    codes: "    六61/.|;.0$.十9.三八",
    descriptions: "5十ğ七?1.45",
  },
  {
    codes: "  3;二>.\\^",
    descriptions: "+37{4 |ğı:五ı7&8,ı *,四]26 <.$7ü]8%二 |& ,']二.4% 0[#}]+< <&^ 4ğı.*ığ/",
  },
  {
    codes: "      *]9五.0九.\\/.<{.),",
    descriptions: "+\\;,ü1<+七",
  },
  {
    codes: "      十)7一.四+.#%.5九.96",
    descriptions: "_2ğ431五[.",
  },
  {
    codes: " ",
    descriptions: " - - 6+ğ;)$,&^",
  },
  {
    codes: "      |#^7.九_.80.2&.;(",
    descriptions: "*8+:ü0)02",
  },
  {
    codes: "      7六]/.%二.>].2<.7%",
    descriptions: "六十ğ$一+,三\\",
  },
  {
    codes: "    二<,*.七]",
    descriptions: "三]28,$ 十/4^$五$.三| 三} ]七.ı 04ç%0%八]一&)",
  },
  {
    codes: "        |四;?.0{.一/.[<.88",
    descriptions: "5<8+,\\一七:6 ö@2(七[/ 3^0\\># ^八{\\6[ 3[: 二ğı9\"ığ八 4ö]% 五ğı5_ı1 ,%1三/十*ü}ü",
  },
  {
    codes: "        #}6%.4&.@#.一四.]|",
    descriptions: "Ö一六>六二) @6九8$|42\"八ş )?:,%8,2九 (^6.0十&\\ :4 8三2+[[#2]73@ 四二9$五(  /5&_ %< 06@][二{8",
  },
  {
    codes: "        九十八#.4}.一,.</./'",
    descriptions: "(ü=..:]六'八\" 八$\";,9ı.&@ @*]3)七4@8",
  },
  {
    codes: "        4{\"二._;.二$.&四.{'",
    descriptions: "二=ğ40<_15",
  },
  {
    codes: "      %}6;..2.#|.'3.\":",
    descriptions: ",6ğ4;一55十",
  },
  {
    codes: "    五&/].七五",
    descriptions: "|七七]%6 99[十._^0%[ \"% 8+.ı $三ç$| 一#*3= |\"]6 +2.ı 八8ç?0七(三一十&",
  },
  {
    codes: "      &'.&.#7.@>.三).|二",
    descriptions: "?ğı'*ı* ö;çü三ü^ü ,四:&6=)5$八 _$$#&9 '6_#%[六$, 四^%]};@ [@7ç0九:ı _*二7二一十\\* (四)]ç",
  },
  {
    codes: "        7五8:.{一.=0.{/.$[",
    descriptions: "4(9}}6<[七一 ö3四+一^/ 9^_*0? \\,9&九) ^%二 9ğı.^ığ# }ö/; /ğı四,ı5 _十{40%9ü\"ü",
  },
  {
    codes: "          七\\+0.0).七=.二).:4",
    descriptions: "十八>\\ü二?.0",
  },
  {
    codes: "          48三...三.}7.2八.=\"",
    descriptions: ";八ğ四三+>一%",
  },
  {
    codes: "    =&%..+\\",
    descriptions: "^>ğ1十八十#[",
  },
  {
    codes: "        3#[>.七|.:+.三一.七一",
    descriptions: "八ö9@ü +:四( (八七九ü?七&0",
  },
  {
    codes: "        一一四+./(.九0.6(.十;",
    descriptions: "八/ğ1|99^1",
  },
  {
    codes: "        6}_=.;?.1+.9=.三{",
    descriptions: "+ö}5ü 四*1} |,{五ü1十7{",
  },
  {
    codes: "        383[.二^.九七.1:.0)",
    descriptions: "十=ğ^二0|三五",
  },
  {
    codes: "  .>5/.;{",
    descriptions: "二/七 #ü二{ü 2=;(ı 34[} ;, 2''一2一八\\ı2一 /:*十$. ^ğı={ı,1^+; {<四1ı 4>}; 9十",
  },
  {
    codes: "    {4<{..,.?(.29.8&",
    descriptions: "8'#7./^.{\", '七?>.二:.&{, 六;九4.八四.$], .>@九.\\二.9}, ,#3^.]#.[=, 0六?2.$:.^=",
  },
  {
    codes: "    4;八>.=1.#2.15.^\"",
    descriptions: "6*ğ一*5三七]",
  },
  {
    codes: "%\".{七",
    descriptions: ">ı:ı #6六_ .], .*+96?3{; [[3_6{四$八 ,ü二.ü三=9^3', 7{ğı(八_{8 ]&#+",
  },
  {
    codes: "  5三六5.*<.七六..:.@八",
    descriptions: "=.\\{ı= 九ö8三ü{\"九 4#}@(:一一ı (;九{8>,七?(?ş >四一^= /}=七六ı六)",
  },
  {
    codes: "  8=八9.,..9(.66.&7",
    descriptions: "\"ü;0ü.*>% 三二*2_^五一+4ı 六7 &=<%361 %三(一一=/;",
  },
  {
    codes: "  3]:}.$七",
    descriptions: ",6&八_ $_?& }7七 7ü七8ü六#?3 ,()3八七77&ı &\" 六}'五八,六 12八@<二89&",
  },
  {
    codes: "      3*\\+.六,.};.#[.4_",
    descriptions: "9\"ı一^+ ?\"五六@四)三2ı +8@\"5{4",
  },
  {
    codes: "      7.%2.六|.十}.[&.$)",
    descriptions: "5二ğ五64?85",
  },
  {
    codes: "      2._九.&..54.三3.\\\\",
    descriptions: "三|$ı>ç#ı +0]| 61九 ç4八ış_六^>>",
  },
  {
    codes: "      三#(九.&\\.#<.十}.2三",
    descriptions: "_&ğ<\"07九3",
  },
  {
    codes: "    一=6九.6*",
    descriptions: "&\\(六3%[5 \\=[@/九:",
  },
  {
    codes: "      >]11.7|.>九.三(.二8",
    descriptions: "二1*@+ ,ü*[\"六['一| @<{五41ı#\"? ]ü7六十 ;)五?(|6",
  },
  {
    codes: "      二\"_@.<;.%;.&6.7,",
    descriptions: "%六ğ)|>+十五",
  },
  {
    codes: "    *6七,.四:",
    descriptions: "|$ğ一*三''=",
  },
  {
    codes: "      1二一?.@?.{#.=5.?2",
    descriptions: "+/四#{ö&)/: 四'3八四ı9$_' ç?{49八0( @=@2 {\\&四|十6 一(2二@ _9一7<三五",
  },
  {
    codes: "      [/>|.]<.七:.]1.)<",
    descriptions: "三/ğ七(%%)0",
  },
  {
    codes: "    |(:/.1=",
    descriptions: ";4)ı;} 十9{[ 八&_ç9>052ğ五 /8. 八358五?&",
  },
  {
    codes: "      %9;}.六;.*:.%'.83",
    descriptions: "五15\"}8 {五5&=一五?ı",
  },
  {
    codes: "      1五十\\.%>.八一.+7.三\\",
    descriptions: "_1ğ\"./.$^",
  },
  {
    codes: "    %十1九.}.",
    descriptions: "(^ğ&}>+%@",
  },
  {
    codes: "      }一8,.#].三*.[6.*八",
    descriptions: "五$.六八& 12]六 2<3@ ,<0[%%ı六=. +五3.ı二ı ?九八%4二三二 |一<] %{%&ı>ı ^&}[& '/\"'*6>2九ı?ı5",
  },
  {
    codes: "      {6,\\.十3.13.七'._二",
    descriptions: "'&ğ一[7'@}",
  },
  {
    codes: "  /7@=.<6",
    descriptions: "二4\"?< %} ></ç#]|5 ",
  },
  {
    codes: "    [八&,.)十.?六.5|.?六",
    descriptions: ",;*6.4\".=@ ,2\" :/一8六/,?,&$/八七 二四;十/1\\ ,#8十#}$二# =+八 7]四_\\,九",
  },
  {
    codes: "      15^一.^[.三8.8%.一,",
    descriptions: "(#3ı9 '二 '9^ç:86@七ğ2 <三7 \\+2\".三>",
  },
  {
    codes: "      (三2二.2:..4.;2.1>",
    descriptions: "0$ğ#^+?7:",
  },
  {
    codes: "$六.%0",
    descriptions: "^[7五+_@6|^ =} 一ğı七 *ü] ._+.ı/ı7ı0四*ı (+@七+|< ^ü二 &6}+ı五ı,ı|3三ı 7_%二ç);",
  },
  {
    codes: "    一十%<.|4.&九.)九.三{",
    descriptions: "^/0'<六^5 %8'五5\\8 ,>二.@5{",
  },
  {
    codes: "    %$;,.(\\.十{.:}.{0",
    descriptions: "7八ğ三/[&9五",
  },
  {
    codes: "    .|{?._7",
    descriptions: "85四*{+1, +>_3:*十 }_#一:\\[",
  },
  {
    codes: "      \">_=.6,.四&.3一.]5",
    descriptions: "\\六72五]>?) 四0\\[\"ı>*5 三*={\\*四|?五1 4: ı3^4\\?\\*",
  },
  {
    codes: "      五十3_.,&.1十.]七.$一",
    descriptions: "<六ğ)五15:[",
  },
  {
    codes: "    6八六[.@0",
    descriptions: ";五ğ&$八(9七",
  },
  {
    codes: "      )>;九.+=.(%.一,.'^",
    descriptions: "/+六%# 十三;$二2*ı八} \\)}=03四$3 七一3$+]30$ çı%{,ı2一.*ı九ı &5ğ\":/++ .[?七6\\二六=?] ;",
  },
  {
    codes: "      4一八>.@#.?0.八[.^五",
    descriptions: "一'ğ|;\\=3+",
  },
  {
    codes: "    03一,.3*.\"十.%^.五二",
    descriptions: "&:8#|/ 七一三八\\?\")))九ı _十五, :&&十七2*[#} \\二<?一二ı&]7 ,ü/3||( ;.9+> /736)",
  },
  {
    codes: "    :四@<.||.'=.\\九.+\\",
    descriptions: "]1ğ+} 0+]}:六六% 3六五一*三>> &一 ,ğı$ 四ü) &8{2ı'ı;ı*4)ı (七%$.8:2])",
  },
  {
    codes: "    99'六.3%.二5.4五..}",
    descriptions: "\">ğ[四?三{\\",
  },
  {
    codes: ">4.,|",
    descriptions: "_^38 2.=ç3&13 (\"2)](.十:五\"); ?_;ç二八@ (\":二2><}\") (9六şı<ı}ı 六><={*ı 5;\"ç\"<(",
  },
  {
    codes: "    %一*{.:$.2{.#2.86",
    descriptions: "^八^3* ;92 8:\"[二9 ü5_8+二) 七%\"%^_ $_五.<#ş ?'7五八 <ö七>ü 三>八ç3/;7",
  },
  {
    codes: "    :四|1.}一",
    descriptions: "=七7(87%37 0&六三ı<\\[ ç\\?ç_十+]\"*^ (2 ş5*3;} \"一^}?>7@七\" ]1') #八九. ,\\]9",
  },
  {
    codes: "      3&\\9.|4.一六.>五.九3",
    descriptions: "一<*|0.\\#\\ 六%十|ı,7八 ç{(ç?%@$36四",
  },
  {
    codes: "      *八六<.六四.八<.7三.|}",
    descriptions: "2<ğ\\>1'?<",
  },
  {
    codes: "    18$七.八七",
    descriptions: "四:ğ38@=_^",
  },
  {
    codes: "      \"五.5.)七.$9.@=.;{",
    descriptions: "九:(\\八> /+{ç/\"六",
  },
  {
    codes: "      三.??..二.=/.>..]$",
    descriptions: "/ö[[ü :9五ç}&)",
  },
  {
    codes: "      8]8|.7&.3>.;..7|",
    descriptions: "\"]{/ı 一@şı\\ı.ı @)?ç[|三",
  },
  {
    codes: "      3=0).[十.=>.三十.三\"",
    descriptions: "=]ğ6^八3四八",
  },
  {
    codes: "  三(%'.|/.;{.+4.7=",
    descriptions: "]94? 二四;ç(九{",
  },
  {
    codes: "  ^六{+.\\@.八;.七*.)一",
    descriptions: ";{7_九 %9>> >四=,9 '>* 0(^&)9 8四$ç)&[",
  },
  {
    codes: "    }五九=.%{",
    descriptions: "+四.^七九 七七:一4$五三8七 九二四2<:{",
  },
  {
    codes: "      >?5五.\\?.五三.@\\.)7",
    descriptions: "201ç5* 6ü九 七七'29五;7ı",
  },
  {
    codes: "      ^7<'.(九.$?.1].+一",
    descriptions: "|三ğ十=七;.|",
  },
  {
    codes: "    {48一.)\\.)@.十一.七(",
    descriptions: "五十ğ$0/十(#",
  },
  {
    codes: "    2<4}.2.",
    descriptions: "4\"三2 1\\şı?/\\=ı一_ >1三|* 5[+?<9, ü\">\\) 六#<* 五三[:)四ş {七八.一\\)",
  },
  {
    codes: "      ^二\"%.87.^九._,.[1",
    descriptions: "=<şı&7|}ı九 7ü{:五五&' \"3ş]七5ı二三二^ı?( \"\\一,二& 四7{四\\五5\" 二:>ç}^&",
  },
  {
    codes: "      ;三十8.^+.{3.;8.64",
    descriptions: "=八ğ三2{七六3",
  },
  {
    codes: "    四\\+五.$\"",
    descriptions: "64ğ五三六&三&",
  },
  {
    codes: "      七<二1.{六.:3.六五.?<",
    descriptions: "1483六 七=,= 一三şı>;|\\ı(.\" /二4.%%ı^;<*= 3=._9& 五90=77九",
  },
  {
    codes: "      四五8五.18.9$.>)._%",
    descriptions: ":|ğ_\\三,]十",
  },
  {
    codes: "+8.九:",
    descriptions: "(1,三$.四,六'三; =<>]ı7}# {> 八.4|ç4七三= 7:二十(%0三ı 5&)= 0$ğ\"% %ü五 &十<}:?*<ı",
  },
  {
    codes: "  \"65七.?<",
    descriptions: "三{(一]\"*四 3,\\=一十\\ #[46一1^九\" ;=|;|三@50 %3八]}\"*四十_< ?二 七六ğ=\" \\ü四",
  },
  {
    codes: "    &035.二3.八五.三二./(",
    descriptions: "'%;>ı%\\四 九ü九0七八四5ğ^ , [. [|+四 68;' ?.]&1 六2,.二\"*",
  },
  {
    codes: "    [五|8.九}.6九.,7.&0",
    descriptions: "2_ğ十一@],<",
  },
  {
    codes: "  <?;?.69",
    descriptions: "九++{0(]5七 六37十七7>2& 4<ğ3] =[+({四四@三5= /% 17ğ|二 7ü| {(\\04,_\"ı",
  },
  {
    codes: "      <^0(.\"?.四3.06.=+",
    descriptions: "1,五{七 }]^|十=2九九;6九 _二 ><六:<3*+#^: \\0_|5% |一ğ>% 1ü; ]\"二.七)#十ı",
  },
  {
    codes: "      #^8[.六;.九十.%:..:",
    descriptions: "十<ğ,1.?三)",
  },
  {
    codes: "    $四<六.[|.5*.%'.8<",
    descriptions: "<'ğ(/,)(:",
  },
  {
    codes: "  _#1{..,",
    descriptions: "8^ğ*, {2(一?七七九十八[ \"7 \"ü@ >_}525五\"ı",
  },
  {
    codes: "    2[_6.{一.9\".@..:8",
    descriptions: "]四6一\\^三 \"7八^ı六五十 十8*^?八_\"?十 三ü6 },<96七3六ı",
  },
  {
    codes: "    三十(;./\".8_.1).%三",
    descriptions: "5*ğ<33*+5",
  },
  {
    codes: "'\".(七",
    descriptions: "七8]@ı;八/, }三4(ç[2.|, )ü九3四9二 七${' ,@ş)二七[7 5ş59九.1/ [79_二1 六*ğ1八",
  },
  {
    codes: "  1+三五.}7",
    descriptions: ":五>?二ö=3&$ 十= %&>/>. 5ü; ;@,_ı:ı五ı#;\\ı",
  },
  {
    codes: "      ^+*>.%\".3四.二&.#[",
    descriptions: "三)=/一 ]八三8 :,şı7/6四ı|25 ]五)[十6ı0;$}三 <)5三1\\ _2%4;{;",
  },
  {
    codes: "        #_,|.+8.>\\.5,.^]",
    descriptions: "İ0;$^ 2.şı十97@ |7五=>三 八{+&})5",
  },
  {
    codes: "        [八'+.43.%\\.&(.]|",
    descriptions: "+ü, 八=şı\"|=七 0九四1}& 七[?.#*五",
  },
  {
    codes: "        \\:(五._#.5&.+{.:4",
    descriptions: "_^ğ{_十七{:",
  },
  {
    codes: "      五'(四.1(.四+.]九.\"\"",
    descriptions: ":]3=8 7,(# :/şı7&/六ı9\"^ 2&=85九ı三{5?\\ 6#132九 58,;九五*",
  },
  {
    codes: "      4'{%.十[.九<.6{.八一",
    descriptions: "一三ğ3+62\"八",
  },
  {
    codes: "  #9\"6.>%",
    descriptions: "=@ö{40七= {八@9(}ö72+9 =7 七<\"四九,ö一[\"[",
  },
  {
    codes: "      40:^./\".<八.49.8三",
    descriptions: "?&十8十 ?六?% =6şı2&__ı1;[ _二042@ı#$>七^ ;[^:{/ 7$['\\'六",
  },
  {
    codes: "      \\二\"五.二/.12.4:.四三",
    descriptions: "三)ğ1.9二1*",
  },
  {
    codes: "      五}一|.九}.2二..#.二八",
    descriptions: "+@五}] 3{,六 ;4şı@/7.ı>,\\ <?\\$<9ı%0五一. 4#+2.) <二5三,.{",
  },
  {
    codes: "      一七7(.,_.六[.3九.[&",
    descriptions: "一&ğ二6|<<2",
  },
  {
    codes: "    &四}十.)].六;.(四.九8",
    descriptions: "<(( )九五ı三.4 :七4)七(ı%4五< ü三八五/ ö[$\" \\5[^\"* #$^6 七十3四8[ş \\%9四<一*",
  },
  {
    codes: "    1]51.1?.四七.六0.六/",
    descriptions: "九?ğ<<十;九九 (二三8五#ı %>)=^+8)",
  },
  {
    codes: "    (,5(.6二",
    descriptions: "[(ğ七9\":十四, (=二二|]ı 9)+四}@=)",
  },
  {
    codes: "      5*]*.&五.=/.^9.29",
    descriptions: "\\9%={ 88一> 二^şı%六}[ı%@2 (8|>9>ı19>四2 [,<+)四 *十七=)#(",
  },
  {
    codes: "      ]+9十.?;.+/.}:.三)",
    descriptions: "1(ğ\\$1<:]",
  },
  {
    codes: "    %<):.4九",
    descriptions: "7_ğ(三^*七:",
  },
  {
    codes: "        九0/3.<1.+\\.[$.]六",
    descriptions: "*0$$\" ]%4# %=şı\\八十八ı1&% 八;><%八ı@'@,| ?#4>.? 8%^;八^二",
  },
  {
    codes: "        五5>三.&&.$3.9一.]三",
    descriptions: "54ğ>'}四&九",
  },
  {
    codes: "        '3'4.3,.9].四1.四一",
    descriptions: "^)1$_ 4;}@ 四(şı*54七ı]#6 二$五<|十ı?323^ 8_\\\\七5 六^#,67,",
  },
  {
    codes: "        _7十&.7九.97.二一.50",
    descriptions: "'1ğ七^^六,九",
  },
  {
    codes: "  >九1|..8.2{.\\}.;;",
    descriptions: "\\ü\"ü.?\" \"'三>%&+* 85 \\ü*ü:九5 ]七&.五)四;.六二",
  },
  {
    codes: "  5^6\"._>.>^.]=.四=",
    descriptions: "\"3+>0四二1五31?, @$42八八%)_{#+ ,& <十三@'>+3八&; 0ü十^9ü>四,7|4 \"ç[: ç[%;ş",
  },
  {
    codes: "  0_(@.#六.|).3\".;.",
    descriptions: "}ı3/; 二^)一|[<>",
  },
  {
    codes: "  九5?十.,九",
    descriptions: "/五ğ@5 9九7八|六 =+ (>8%8[#0",
  },
  {
    codes: "      50)6.}九.}:.;十.+}",
    descriptions: "14<|九 \\8\\)>ö@2^?, 二^=ı四十_' ü,)六' 五3>' ;^<六[<9\"(",
  },
  {
    codes: "      %)_8.\"八.九%.:1.;7",
    descriptions: "09ğ)十7二八>",
  },
  {
    codes: "      ]六'八.二^.七..\\<.4/",
    descriptions: "\"三\"\\3&8#五 :ç2五 ^五5.}; }四 &2={,'03; ü4ü三二[}三* =六şı)七六3ı56 )#(.>]",
  },
  {
    codes: "      '.:..&$._(.}|.=4",
    descriptions: "二;0七5 <5\"八 7|şı\\{{六ı四#三 $3*_|二ı六6%四| <$十]@9 (>'四2一$",
  },
  {
    codes: "      60五{.七*.{4.5四.{+",
    descriptions: "\"\"ğ=$<&&|",
  },
  {
    codes: ")4.\"3",
    descriptions: "六}.>=\"/\": ?<\"\\0'0^& \"9}]七|,,一一,, 48+3十'9=&8六<4, )6一(?^六二21, 7_@>#;",
  },
  {
    codes: "    /二.五.六7",
    descriptions: "三,*82}5 '5)7#]3",
  },
  {
    codes: "      _9@:.六七..九./5.\"2",
    descriptions: "十@7_^942*\"+",
  },
  {
    codes: "      十[#'.四6.五1.二=.?1",
    descriptions: "^九7十七3+\"^^五,]",
  },
  {
    codes: "    七)十四.%九",
    descriptions: "7+ğ|^5=43",
  },
  {
    codes: "      .388.92.五\".,}.十五",
    descriptions: "二四{*}8>0$6(",
  },
  {
    codes: "      ]>六|.#7.2五.七4.#五",
    descriptions: "{,4[5{8$四?二.*",
  },
  {
    codes: "  4>[*.])",
    descriptions: "}/.)%2}$九. 四| 7?,26ğı九 >4六,二二三五&\"^ %6%>7% =@&25二81十",
  },
  {
    codes: "    >[^5.4[.1{./'.^4",
    descriptions: "*$/(三4九五$4",
  },
  {
    codes: "    ]$1#.&*._].>{.九}",
    descriptions: "_一)[=ğı, 6<五['十六<{#4 五<]^'九 %一*八>9一,&",
  },
  {
    codes: "  +3一1.2{.*..)$.8#",
    descriptions: "6[9七2>\"\"三三^",
  },
  {
    codes: "  九八3}.#{",
    descriptions: "十ı/ış1ı三十^ @ş2:^ .4?\\''八/[&+ 七=]>86\".三 四3;]# 二<.四:<],% 5一 八35",
  },
  {
    codes: " ",
    descriptions: " - - - 4三72\\7ö7[ü 九(/29'3",
  },
  {
    codes: "        六;\\].1}.=+.?(.{$",
    descriptions: "?$1;#十 _&,:.\"8七(( _九(\\;&+",
  },
  {
    codes: "        %',9.%\".十?.2/.三\"",
    descriptions: "?{ğ?)二九五>",
  },
  {
    codes: "      03$;.四%./[.3).*<",
    descriptions: "|六ğ9{7>2三",
  },
  {
    codes: "    $#七/.}=.五<.%[.四一",
    descriptions: "&ı2ış'ı_)? 六ş@]一 :十<$六;<6\"/四 /八二&%三5&6 ,{_.2 '七.一五七128",
  },
  {
    codes: "    #七'二.三)",
    descriptions: "Ö十7'三 ?ü^(>十93三 九ü^#|&%\" 五ü^+&)五}<,一<",
  },
  {
    codes: "          _\"二=.,/.<7.=十.:三",
    descriptions: "四&#/4六 <.^四8@7二|$ 5/九=++[",
  },
  {
    codes: "          ,'8五.<{.二{.#,.'四",
    descriptions: "十四ğ(%5[)(",
  },
  {
    codes: "          \\/{9.九二.8\".[十..8",
    descriptions: "\\(]6/\" 六/3(_17:+四 .#;/=%四",
  },
  {
    codes: "          \\@(/.\"%.二*.%[.十:",
    descriptions: "{[ğ&>二八三9",
  },
  {
    codes: "        一^6二.26.四}.一九.五三",
    descriptions: "*(六六;/# 八ü八8/88#&>3\"",
  },
  {
    codes: "            5一9..六[.2(.)=._<",
    descriptions: "{^十7{> 2六%五2#\\\"0十 @2.|二?9",
  },
  {
    codes: "            二<35.4\".)(.'$.=#",
    descriptions: ",三ğ9%二/十}",
  },
  {
    codes: "            ;3#$.<七.0<.&四._一",
    descriptions: "六:')一( 八一=)/:0(^五 9十;/;=八",
  },
  {
    codes: "            52:3.22.1(.三六.2四",
    descriptions: "|.ğ|68>$:",
  },
  {
    codes: "    90八{.86",
    descriptions: "?}8二[三 一'七 2三]$8) [ö_@[<1\"; &|\\.>& 68 1.'$九7:)",
  },
  {
    codes: "      ,三}3.%?.四/.一五.8\"",
    descriptions: "3三&_一&\" ,^五\"二}{?ö82&;",
  },
  {
    codes: " ",
    descriptions: " - - - 二#ğ1(一@六+",
  },
  {
    codes: "        四'(1.三}.4七.|=.三二",
    descriptions: "$>='2; 8/七9+75^&三 @/87七<=",
  },
  {
    codes: "        五八$,.0七.\"四.四八.九[",
    descriptions: "%}ğ(57一三7",
  },
  {
    codes: "    十三?&.|8",
    descriptions: "[,ğ_7六}5八",
  },
  {
    codes: "        <二&1.(:.94.五=.\\$",
    descriptions: "@5;5 3_4@'",
  },
  {
    codes: "        5一<^.+;.$:.:8.\"7",
    descriptions: "|)ğ一&@]|2",
  },
  {
    codes: "      七%0九.72.7+.九).八'",
    descriptions: ">,ğ@/[?0&",
  },
  {
    codes: "  :%.;五",
    descriptions: "$_+;}ğı*, 5九(,九\\>=](六# 0一.. 八\"'五=4_6,7' 8.şı+2七)ı, +1一ı2*,>ı, 一?).|",
  },
  {
    codes: "  十:(*.8_",
    descriptions: "(^]ı5 4,9?四&十=:ı 四^ :六@ı$ 七ö+5_ 八|.2=':六<ı",
  },
  {
    codes: "    \\+$六.六^.]+.0六.\\>",
    descriptions: "_$四ı> ;30*^:*4|ı",
  },
  {
    codes: "    \\:三9.九=.一4.3二.]%",
    descriptions: "?七/ı一 *ö二@( 九{55一%|\":ı",
  },
  {
    codes: "  /<1+.2二",
    descriptions: "三.> 十ü=77三4.?七%2 七. 三<@ 八ü}>ü10ü#ü\\2$十",
  },
  {
    codes: "    $九六}.三一.=十.;[.十2",
    descriptions: "[$< :ü/八9+</十四+三",
  },
  {
    codes: "    &|二六.2$._二.}@.*八",
    descriptions: "2/] 2ü'\\ü|%ü@ü=|四.",
  },
  {
    codes: "    :)96.4/.二].\\3.九7",
    descriptions: "}0七三''{.\\ 六':.\"九49( ]>>.89*",
  },
  {
    codes: "    (])+.五8.7_.>4.+八",
    descriptions: "_,ğ)<|三二=",
  },
  {
    codes: "    七'%六.八8.95.9,.$八",
    descriptions: "@;九:]一>八9 3一}\\}五+># )6)49三3",
  },
  {
    codes: "    +)七5.8_.=_.[/.一\\",
    descriptions: "+^ğ[/八31六",
  },
  {
    codes: "  六$^九.+9.六<.[,.四3",
    descriptions: ":6@二#\\三<' (37十七\"&.< 三>ğ十一 ]一;6{8 1\\ 十75>#,^>",
  },
  {
    codes: "    _三($.[{",
    descriptions: "四ı[ış,ı五&% \\\\十/ 六(0ı9 'ı.ış>ı27= =3_>:0 <$ 7\\七3四85[ı",
  },
  {
    codes: "      $1=\\.=|.七:.&|.6|",
    descriptions: "İ94).5 1五 ç四%$四#/ 520 :].[四八&十四八七八",
  },
  {
    codes: "      [#6,.)3.3{.8%.,%",
    descriptions: "]=ğ?七>'六.",
  },
  {
    codes: "    .四}3.#@.|1.三二.三=",
    descriptions: "$?ğ}/#<)$",
  },
  {
    codes: ".二.@;",
    descriptions: "Ö</+8#'9三 ,$[& ?,0十88 ?九.一2 六#四 十十.0% )|$八:7(]$$六ı九#*6+ 80六^^) %$",
  },
  {
    codes: "  /<*;.*9.#八.&@.一五",
    descriptions: "?6.., 3<@6<$:%+5<\\五# 1三=<4} 6八 3(,58]46五 <'十 九)八(三四_",
  },
  {
    codes: "  *#一五.$|",
    descriptions: "六+.3= 9&(,)7^^\"5\\20* 九_四<_7 三& 2?55六}79* _&1 9六二)六1(",
  },
  {
    codes: "    :2六#.?^.[五.77.65",
    descriptions: "\\$\"十.*/./7.]0 9二六 :+一一]4\"['%=|六? 三:40_} |5 54%&,二\\{5 +3: 1)七=%.4",
  },
  {
    codes: "    一八}#.7;.64.1$.@3",
    descriptions: "四三八<.9>._0.11 ;{] 八5]2三<七一?{{}四4 86八)<四 *, ((#8四八&]二 五^} \"5,二65'",
  },
  {
    codes: "    3+七:.&^.:+.%$.4'",
    descriptions: ".;ğ)&/九\"四5+ \"二6 {[一57&?",
  },
  {
    codes: "    \\|1八.二9",
    descriptions: "=\"(*<ö*]一69, ,$二'2; \"+二4二ö九55,九 |八?/ ?ü'ü*=$ 2八<七2#+\\{@>[",
  },
  {
    codes: "      ?\\七五.:三.${.),.@{",
    descriptions: "三3|_ 9 ]十7_, 0] ü|(1十 _|)#}ö} ışı[ {&二十3<0 (}}< '=<\"\\?九五)",
  },
  {
    codes: "      八(,一.十#.=:.4五.#&",
    descriptions: "4=九三:ö\" {ı;7七四二 ;3_七%<ı",
  },
  {
    codes: "      9[4(.一四.#六.]7.^=",
    descriptions: "33ğ6二一&[@",
  },
  {
    codes: "    }\"#^.;_.40.五9.^%",
    descriptions: "9*ğ]'八;_)",
  },
  {
    codes: "    6|=,.四\".41.<\\.:三",
    descriptions: "2九9&$0三, $@,ç=**_, \\ü一'六(@&, 'ı$_*ç(六四 '十 9$二92)5&",
  },
  {
    codes: "    1{\"..&@.^七.*5.(>",
    descriptions: "6?1五.&\"6 >) 八\"{*$'}七9@ >ıç_一/三/ı",
  },
  {
    codes: "    |]$|.四1.五4.}>.?九",
    descriptions: "(;/'.(] .* >1$].十五 |0: 七1十,[^:=3#+ı&{<;| 2十\"十六 九)一> (*@十]7",
  },
  {
    codes: "    ><}0.(\\",
    descriptions: "1\\ğ1<五)%+",
  },
  {
    codes: "          *^[1.&2.五^.4[.<.",
    descriptions: "七\\/八.八%.[#.5% \\= 5=>&.5九.?5.,5, /:93.四*.6三.\"=, 六>三*../.^二.4| 4<_",
  },
  {
    codes: "          #<0_.$9.三@.'{.三7",
    descriptions: "6('五.\\].{3./$ \\6 *)/2.?6.$四.#2 9,[ ;{.;七@/'.六}ı_[+|{ 5'<\"0] '[",
  },
  {
    codes: "          4:/5.六;.'}.>>.5@",
    descriptions: "'^ğ:==?一七",
  },
  {
    codes: "        九*)3.30.二|.七^.\\7",
    descriptions: "%十ğ.06八#+",
  },
  {
    codes: "      一七+%.'四.4:.三4.${",
    descriptions: "一}ğ[+].?@",
  },
  {
    codes: "[六.九四",
    descriptions: ")49&\"ğı ,四}ı|)_'5六;, /ş七|&=#7 四_ #;'五<8 ]三#;>? @?<#) ,% 1[(ç]",
  },
  {
    codes: "  7十>[.?二.:=.+9..{",
    descriptions: "4<七七@[\\3一",
  },
  {
    codes: "    :9五[.^四.九..三0.[\\",
    descriptions: "[0八>[* 四ı十7ı?|#6 ([\"*7二2%}:|)",
  },
  {
    codes: "    4;;(.^,",
    descriptions: "72ğ十&/]+/",
  },
  {
    codes: " ",
    descriptions: " - - - (<[92798三@ö四九6九 .? .ü7#=^>七ö&二[\"",
  },
  {
    codes: "        85九$.六(.<八.*五.%3",
    descriptions: "二>11'@七\".]ö%,.{",
  },
  {
    codes: "        [;一\".&*.'三.:5.$\"",
    descriptions: "?ü5四+?,一ö?[:)",
  },
  {
    codes: "      五\"/;.%三.8..&六.6]",
    descriptions: "3ı28ı\\十?#",
  },
  {
    codes: "      3五6/.*5.三\".十6.=;",
    descriptions: "%一?)%{ 6.十@'\\6一] (>ö四}8}&ö五六|>)",
  },
  {
    codes: " ",
    descriptions: " - - - *%ğ&#7)>0",
  },
  {
    codes: "        $2:;.91.}十.五:.4十",
    descriptions: "六ü[:.\"/ ç^>? %七九一'@=7)ı",
  },
  {
    codes: "        $?@(.<..[0.:3.|\\",
    descriptions: "={ğ15 ç3%2 >=0七=十1'#ı",
  },
  {
    codes: "        {&_[.十五.>[.$<.\"$",
    descriptions: "/ı)四ı$0ı (七\")3\\+七十6",
  },
  {
    codes: "        #)四;.(4.三五.^@._&",
    descriptions: "9#ğ%} #;:四,八5{一#",
  },
  {
    codes: "        )四]<.=3.>二.九4./;",
    descriptions: "3'ğ*}>9+5",
  },
  {
    codes: "    .八'?.|6.一*.='.1$",
    descriptions: "'<二7$ğı >ü十/93&{ 七=ğ%^%24 >@六' :六 ;九26[ =<#+$一十5",
  },
  {
    codes: "    _(\\(.:}",
    descriptions: "八=ğ&}:|<六",
  },
  {
    codes: " ",
    descriptions: " - - - - 4&)十$;@;; #:十|70|/ $>八?{九 ,_2) 六4$/^?)'*ı",
  },
  {
    codes: "          十7+三.{}.=6.&(.27",
    descriptions: "*>7九%八_ {^&二733 (7<]<{=)",
  },
  {
    codes: "          \"一6+.8).0&.四:.十\\",
    descriptions: "二五ö4二:)< 六*<8)6十 ((五4|=+)",
  },
  {
    codes: "        }\\四五.^,.七八.'$._1",
    descriptions: "*七ğ5%(8:七",
  },
  {
    codes: " ",
    descriptions: " - - - ;:} 8|{^ 8七 #1?@3 +{|; &&@九%.\"\\?ı <八 九}六8 十| {351* 8四^60 九_|_二|4六6ı",
  },
  {
    codes: "        +/^<.?_..6.<6.%&",
    descriptions: "十{/ =\".> ^:五六 |&_+>二八;{ı",
  },
  {
    codes: "        ,九]7.=$.一}.;@.'{",
    descriptions: "18\"6$ #2三;; 1六$#四(&\"|ı",
  },
  {
    codes: "        \"八21.51.$3.$^.*\\",
    descriptions: "三_8二 \\四>#三 7)^={1\"+]ı",
  },
  {
    codes: "        <0二(.^+./>.八(.]0",
    descriptions: ";一6^3 5)&9$ .#$3772九%ı",
  },
  {
    codes: "    .0\\1.(|.$六.'6.)九",
    descriptions: "'三5七[, *ü六:二七, 0十ğ@ı,ı@=\"ı",
  },
  {
    codes: "    775\".25.\"].@%.>^",
    descriptions: "(7ğ十九 十ü^?: <3ğı:ı三ı||0ı",
  },
  {
    codes: "  1|四5.+9",
    descriptions: "+七ğ[四 (三[(二> ?三 九>五七\\74?",
  },
  {
    codes: "    ^二九+..六.=;.;六.)|",
    descriptions: "Ç?%['0十九*五. 47 七四,{ 六一.十_6.ı 十ç){ $*5'八<\"<::6",
  },
  {
    codes: "    }8.#.^4.0八.8'..十",
    descriptions: "三\\ş &.]ç^=,\\ı^ı 3ö'%#, 6<3%0/5 3\"&二一十14?ı",
  },
  {
    codes: "    #3十十.${.]\".\\3.七四",
    descriptions: "&一ğ^四{@}七",
  },
  {
    codes: "  3十7六._;.^^.八=.=%",
    descriptions: "6,$九7 六> 三<4ç{5:&",
  },
  {
    codes: "[十._:",
    descriptions: "0#6ı] ü七ü六9$七2.一五 一十#_二 0& >?]^3$ \\;>\"'<_9@& %_/:@四 +.?七:八 七四",
  },
  {
    codes: "    *.^/.5[",
    descriptions: ":^],十;. )%八}九)3 (八+四;% }#% >六&9\\ ü6^3<0?九 7ö十*# 7九\\+]) (四*@\\九3\"^ı)",
  },
  {
    codes: "      ${2%.9\".<(.76.*^",
    descriptions: ")2五>9:(@ }\\4@五=九 0十八四{#6",
  },
  {
    codes: "          {.8?.\"9.\\?.68.,[",
    descriptions: "9<七七\\|:3 0九== 1#二四4:*",
  },
  {
    codes: "          三*3\\.%\".一9.*'.8_",
    descriptions: "#'ğ}四一=[$",
  },
  {
    codes: "        64#3.+'.二^.'7.七?",
    descriptions: ">五ğ1+\\八6;",
  },
  {
    codes: "    九_['.6六",
    descriptions: "1'ğ8十五3六[",
  },
  {
    codes: "        3}>..#+./}.三@.3一",
    descriptions: "7'\"三八>9_ 一&?/七;7 ++4九^七2",
  },
  {
    codes: "            <四&{.八#.08.}5.三四",
    descriptions: "1三817五?? 十$九/ 五64十|^;",
  },
  {
    codes: "            六'八3.>).十]._4.6五",
    descriptions: ")五ğ}九|九0(",
  },
  {
    codes: "          \\]9{.=?.0二.一*.)3",
    descriptions: "\\@ğ一|$/3/",
  },
  {
    codes: "      >?*(.五%.三+.五1.0>",
    descriptions: ")^<5[一:九 1>^5(+六",
  },
  {
    codes: "  七'2).9/",
    descriptions: "74ğ{: ç六/ %@ç8| ./?316#_&ı (77十<九ö&五9%1 .*=}? 0五#@二{ ^]:@/}8ı [一ç1七",
  },
  {
    codes: "    =#一1.?|.91.{七.三*",
    descriptions: "5{')十五一 ^%#九71%",
  },
  {
    codes: "      :一7=.)7.7\\.2\\.('",
    descriptions: "086 四,{+&ö'3五 >,şı{=;) '五22 ç6*9+/.四 ü(十:: }[@5 <:?=3\"ş *33:二*#",
  },
  {
    codes: "      ?;九7.七6.,4.8$.47",
    descriptions: "=<ğ四.{5%}",
  },
  {
    codes: "  7<十一./&.[$.2).%三",
    descriptions: "4' 十>2ı'八一:{ 1?.^%9 #^ }1{二/=+2ı",
  },
  {
    codes: "  1三*^.3十._;.]一.:七",
    descriptions: "62 =? :<3一四 ?](三2三七二, {七?,<8 2] 八/%5+三$八ı (8| &十 一#(*8&十6ı",
  },
  {
    codes: "    )一(}.{五",
    descriptions: "三&ç四0 0ö/=;3)九",
  },
  {
    codes: "        ,+七@.}7.]}.(二.%|",
    descriptions: "5三;^<)['[ |@4(二78_7 )6一+\"+>",
  },
  {
    codes: "        +9%;.{$.;三.二+..\"",
    descriptions: "#}ğ@%\"%九7",
  },
  {
    codes: "        _二|).{十.一?._六.@2",
    descriptions: "}52'2|@5& .+,59:4一四 $59^1{\"",
  },
  {
    codes: "        $^?]._;.8{.55.七.",
    descriptions: "{6ğ84.五十,",
  },
  {
    codes: "    ,[六六.\\{",
    descriptions: "??ğ^二 十*9}}< 7}五;=, 一$ |&九二</八\"ı",
  },
  {
    codes: "      &七^?.+^.2八.%<.9六",
    descriptions: "?五4八[>_#( '1|'6十七|2 2+$(六53",
  },
  {
    codes: "      三\\<[.六&.}\".&^.]6",
    descriptions: "九一ğ66$:\")",
  },
  {
    codes: "    '八8*.}<",
    descriptions: "(ö{ ^\" >,1六4 *)二?九 \\8,=40/九3ı",
  },
  {
    codes: "        ]*三@.<5.)3.0\\.9*",
    descriptions: "\"7一/.五{四[ 八^三3%^(1@ ?<四&=十[",
  },
  {
    codes: "        4二<四.(^.24.^5.3\\",
    descriptions: "=2ğ']十_\\)",
  },
  {
    codes: "        &@一+.3^.,9.*六.@二",
    descriptions: ">*八10]26. 5.]六.五\"9: )二六[四]^",
  },
  {
    codes: "        5#十#.八十._\\.三{.+9",
    descriptions: "{9ğ30#\"^.",
  },
  {
    codes: "          四=&0.三..%9.四四.一{",
    descriptions: "($六9十;@七> 三+三0三4[六+ ;2](三@三",
  },
  {
    codes: "          _&.0./8.^:.三<..#",
    descriptions: "96ğ$+63:%",
  },
  {
    codes: "        $[4四.^%.<8.四+.#\\",
    descriptions: "=@ğ7.二8七@",
  },
  {
    codes: "    7二>3.&九",
    descriptions: "@\\ğ)_$)&5",
  },
  {
    codes: " ",
    descriptions: " - - - \"|\\{九 <7二$({?ı",
  },
  {
    codes: "        :&,>.八>.6}.)&.\"五",
    descriptions: ">十)_[?8%6 %七=|}01_七 一六_八,*二",
  },
  {
    codes: "        ,#^;.)..6).*7.四&",
    descriptions: ":六ğ@|十<=7",
  },
  {
    codes: "          {|<三.9>.#:.#_.,八",
    descriptions: "2+_&{$十^# ;9+十+8#4( ,$=]'.:",
  },
  {
    codes: "          %&$3.?*.四五.4(.六\"",
    descriptions: "0%ğ5#}%一_",
  },
  {
    codes: "          /(六3.3(.:四.@\".3五",
    descriptions: "\"六十.4|#八\\ =^9|五<97@ '[@|+十$",
  },
  {
    codes: "          (七十%..#.七一.七\\.六1",
    descriptions: "0四ğ11三:%二",
  },
  {
    codes: "        ++二8.二>.*>.,+.\"*",
    descriptions: "]]ğ:'4?&>",
  },
  {
    codes: "    '一34.=\".\\'.四\".9四",
    descriptions: "6九七'&\"^|一六ı, @?<$3%五4ı =[[6 \\:ğ二^ '(四ı1 ü0ü十.五80九七 9ğı|,ı0 _|五\\ /ü+ü1",
  },
  {
    codes: "  十,?一.^,",
    descriptions: "0一#$6 }三 \"_,ç/)[(",
  },
  {
    codes: " ",
    descriptions: " - - 7七0<60. <?三%# 十( 二$,十]三 7$二&<@八九8ı48 (.|ç%/ 'ö/=7/:]1) '7 |* /\\",
  },
  {
    codes: "        4#:}.^+.二八./<.八五",
    descriptions: ",^ç#六|ö}一+ }ı9\"$|7?ı",
  },
  {
    codes: "        :8[九.二:.四3.\\}.6七",
    descriptions: "八八ğ\"(888}",
  },
  {
    codes: "      /&48.?/.],.,'.(?",
    descriptions: "}( %; 58|三@四1|ı 一=4十3$*1# =&,七四61|7ı$九 {^, {三%&九+)",
  },
  {
    codes: "    [0%].\":.^>.++.19",
    descriptions: "^,%.5@ )7^03%0=四ı(< &_九 >8$})$一",
  },
  {
    codes: " ",
    descriptions: " - - 3十ğ96)}]%",
  },
  {
    codes: "      8六六%.[}.<四.#4.8+",
    descriptions: "5&'八 \\8(六6()\"9ı4/ (六#ç})十7三{) ]8. 8一14)#_",
  },
  {
    codes: "      ._&:.三七.九<.八).三1",
    descriptions: "+八ğ一二\\+_6",
  },
  {
    codes: "_;.%&",
    descriptions: "=ü1 八五ğ&7 _[]91五=|[ı 9' 'ü七çü'ü6'@ 三\\{8>1ı三{[ 十|}三0] 1四 $}<八=\\#一",
  },
  {
    codes: "  .@<\"./1.?四.+#.1\\",
    descriptions: "九ü2 =/ğ一7 八]五$2%4四;ı",
  },
  {
    codes: "  4二八<.^\\",
    descriptions: ".ü0çü\\ü:=2 0)|58?ı'?, 三/@一{6 九2 5;三=@四[*",
  },
  {
    codes: "    ]394.,?.{十.56.|8",
    descriptions: "'2%06七ğ '9/^/四' 8)|%^> :_八93二 ?3 +0九/$2$一 (^$%.}ö;>36 八3*#ç)",
  },
  {
    codes: "    }四四九.|三.九6.六,.?<",
    descriptions: "^六#)*: 8,]')四] +:七八__ 8九3^'* 三3 <七0}>[5三",
  },
  {
    codes: "    $#9三.8:.0$.5@.'<",
    descriptions: "五一ğ+?^:一\"",
  },
  {
    codes: "  */9*.9|.|(.\"=.=1",
    descriptions: "8七2[二 ,\\ 六61ç十十$九",
  },
  {
    codes: "@%.,+",
    descriptions: "Ş@五五1, .77: ş一''八ı, '%&.: >:1$五ı 七,9< 八三(=(1( {ç2}'{&6$五十 5&&</\"5",
  },
  {
    codes: "  %7^十.)'",
    descriptions: "一33七;九 \\七 \"十14=+\"*",
  },
  {
    codes: "    {8四&.};.\"0.?$.八9",
    descriptions: "Ü6ü_ ,ı30八 +_|%<%[=",
  },
  {
    codes: "    90一:.7$.&5.+0.2^",
    descriptions: "3{六#4 #:=<五ı\"ı çı,2\"0)?5 .;+_#( (三$,二+0",
  },
  {
    codes: "    五\"八\\.七*.#{.)2.4=",
    descriptions: "#5ğ76<@3七",
  },
  {
    codes: "  [9%1.]0.[).$一.|四",
    descriptions: "*?\\\\0 27 六二[ç+0一七",
  },
  {
    codes: "\\*.三4",
    descriptions: ":=+/九 $六 [*,ç) 四1一ı9ı<八, <%1四六九ı+ığ], *ü>一* 一3]':0\\ı{ığı一{ |3,:",
  },
  {
    codes: "  ?八36.4+.':.<三.#5",
    descriptions: "}#三>4< >+:2/)++| |,|ı21%1@2[ 七2$十29 /@]}5| '7 二一75四441",
  },
  {
    codes: "    5$(九.':",
    descriptions: "%六九;*七 çı七,;<1七 .) 六ü=ü}二1 '九4十<6 一{ 62(6一十22ı",
  },
  {
    codes: "      *九...十7.+5.6\".?(",
    descriptions: "9:七四5八 çı?五430十 &{五9[/ 六一 :24_3[/三ı",
  },
  {
    codes: "      八%]'.7一.七{.36.\\&",
    descriptions: "三_%7.| 'ü.ü八|0 八..六%六 十/ )'四三);(8ı",
  },
  {
    codes: "    <.^八./#",
    descriptions: "2十ğ5$89七>",
  },
  {
    codes: "      8[:6..?.1|.4一.<*",
    descriptions: "$}(*< &{^十/_五4% >6}+\\1 二{}三_[^* :八;*.%五/",
  },
  {
    codes: "      +4}十.7>.六{.&].<一",
    descriptions: "一4*八/%[;)[ı \"七1^?}[ $(9十十$ ?0八|69 ?$?{5)二 @6\\7}'6>$ı 四.{\\.] \"七",
  },
  {
    codes: " ",
    descriptions: "        _<]_7&#] (1八}#$) [(4四&3??)ı |*(,ç)",
  },
  {
    codes: "      \"四二[.5^._七.四)._;",
    descriptions: "2_25/3()ı. 1ü*62.;1# ',{(十45 0?#?=? ?)|.,] 0一 }{[:十{{9",
  },
  {
    codes: "      {44四.|$..4.9六.十;",
    descriptions: "'_ğ7%]$4|",
  },
  {
    codes: "  _<}<.四+",
    descriptions: "八=ğ{= /八]&<? 96 6十494@>5",
  },
  {
    codes: "    %|\\,.二八.二6.:一.+\"",
    descriptions: "{<}<4@ı]ığ< '七{+)| '0?{>三五",
  },
  {
    codes: "    7*8,.$七.{九.十九.5十",
    descriptions: "二四ğ&([^\"三",
  },
  {
    codes: "    %8四二.\\'.五2.五七.,;",
    descriptions: "2ü|\"$ ]&<0^四?ı四ığı五& 68>% &%9{@| çı,>七(2' *\\ 2ü$ü+1> 2/*三八#",
  },
  {
    codes: "    }一2].\\|",
    descriptions: "@+ğ^0六11:",
  },
  {
    codes: "      $*8三.}?./0.2/.0\\",
    descriptions: "2_(\\9; ,八;一七二&44 ^$9ı4\"八|_= *,@{58 }8 };/<*/@\\^ <一% [\"7^[%七",
  },
  {
    codes: "      +(.4.|'.*一._+.&_",
    descriptions: "十八ğ九{.><3",
  },
  {
    codes: "一:.{2",
    descriptions: "09{|2(,>ı], 九$5,(.%ı], ,$=3 #)一(1(七一&<八92 8二,*=4}二<|四+/., [二七(五#",
  },
  {
    codes: "  ]%七@.+{.>%.%+.[}",
    descriptions: "}四,<@=7<ı_, ;;八$'&+ı2 8七}+ $%{^ 225_20{^@26三八 3)8^|,09一_['\\%,",
  },
  {
    codes: "  四],8.$#",
    descriptions: "1}ğ;5 &1七{@\" 三四 一一八:)02.",
  },
  {
    codes: " ",
    descriptions: " - - /\"ğ.3,&{&六\"0@%^ ^85''%ı'1+五} 五:'二*二 *二8一9>*",
  },
  {
    codes: "      $[[0.>十.二^.一}.*9",
    descriptions: "(二@@|95ı: öğü1ü九<.2|(\" }/ \\ş#,;@<5十_[ >1+?九} ='<七*+ 39 ;1^]'二2五",
  },
  {
    codes: "        4]|1.*@.*).)_._8",
    descriptions: "九七%) {][55二}八2,;二十 öğü<ü<60(^六; 8三 $ş0<)*79;5_ {>四?二; 8@24#\" $@ [一",
  },
  {
    codes: " ",
    descriptions: "        1;|:?%",
  },
  {
    codes: "      十八$1.七<.\"一.五%.+7",
    descriptions: "0:ğ三二=七_*",
  },
  {
    codes: "    (6三'.*九..2.5八.六9",
    descriptions: "|七ğ$#7七八;",
  },
  {
    codes: "  \\4'4.$二",
    descriptions: ">3七八% |} 八]2ç&+[{",
  },
  {
    codes: "    0=8&.二/.六[.$1.#=",
    descriptions: "%:;八五五|*ı$, +>二九五;.ı) &^四* 二/1四 0=\"&5九^6八四]?# 88/5;四四>二)*五, 八ğı9四ığı",
  },
  {
    codes: " ",
    descriptions: "      :六]='九 .七 (<&+#七四<| {/4 ]4;)2(4",
  },
  {
    codes: " ",
    descriptions: " - - '五ğ5^七51}",
  },
  {
    codes: "      九五7一.,5.七).'9.|三",
    descriptions: "?|七+ ]一九一{七ı",
  },
  {
    codes: "      ..八&.&0.)=.;十.:(",
    descriptions: "[八ğ%27}3#",
  },
  {
    codes: "[八.十=",
    descriptions: "七八 7(七ı;ı3 5.ğ@| 九二[^+;5十:@?ı$\"; @%/ 9|]?(七八 <+7% 二^8三;7七九七十\\八?",
  },
  {
    codes: "  $|94.%%",
    descriptions: "$?%=$ç九}五\", '6_7&四ı三ı4, %??^ü,22\"#'( .>'六#六 三, )/\\}[8\\#ı 89* ^3;二}$^",
  },
  {
    codes: "    =[:五.6*.'十.[;.?&",
    descriptions: "{\"8四\", 91>>?, '6}5ü2% $6&9<十% .八十|&( 88|(七5?",
  },
  {
    codes: "    >}_/.四>.[#.$|.七)",
    descriptions: "7>;五一=\" 7+[] [0(七6&#%.6.2} 8)00十$一 ;\"六=5{ #6七二{?(",
  },
  {
    codes: "  3%;..)].五'.3@.86",
    descriptions: "Ş$4:3+>九八, {.&'^ 7=@> ç{$+?#;一 4@2]2{九 >/?}%5 &<4?_> }8",
  },
  {
    codes: "  十一二*.3二.;三.75.{^",
    descriptions: "Ş>{\\八 5%*一6;3 一,)>九五 #十九\\\\. 七= ';*53}=/",
  },
  {
    codes: "  ;@_五.\"$.(一.6'.(,",
    descriptions: "\"(;@%ı'ığ: )4@_二, ,九七?四: :' 七$<0{&%二",
  },
  {
    codes: "  九>\\'.32.一>.3/.3@",
    descriptions: "8\\九?2[| {}9{ 十ü{+六 >二1三#\\'3}ı8ı] '+7九%#'6二 二一3ı|#@四=(0ı$' />\\.七\\",
  },
  {
    codes: "  %3%).8?",
    descriptions: "<;$5六, 1$?四^ +* 六/九=八0> 7{3一ş四六2{\\ $(.ı=5四?}0/ı#$ 三九:49九",
  },
  {
    codes: "    \"八;@.8|.7'.\\8.8#",
    descriptions: "_,#2#=& \">{|六 502{.? 六2 43@5.*?;ı",
  },
  {
    codes: "    九_(五.>五.<^.0八.七{",
    descriptions: ",}>四九 %8 0*$九;/九^四十 1四一ı[_%;)2九ı/5 一一1#^: <54\\二五 $2 4五])+8>2",
  },
  {
    codes: "    5$(?.=$.1+.%].6\"",
    descriptions: "']0九+_? >十二<ş+3%=8 =24ı四_[一.]一ı+} #?9%]\\ 6\\/([5 五\" ,(2三4;6:",
  },
  {
    codes: "  *&2(.九七",
    descriptions: "{.ğ六# 一二七%,} \"' 3七一//(九六",
  },
  {
    codes: "      7^00.五%.]三.三=.93",
    descriptions: "Ç>一 ']/}二#'三6ı九ı 五7|}' #0'' 六ı0ı.*@ _五+?[[",
  },
  {
    codes: "      *3&\\.4).二'.\"\".]%",
    descriptions: "4@ğ8五一五\"一",
  },
  {
    codes: "      $.七0.一?.=%.|4.2\"",
    descriptions: "İç@3&{%:&\"{ 7;8ı六四5[#&4ı%% &{#5 3六%3三>% {五%#三? +*;}^| ]< <:|04(七:",
  },
  {
    codes: "        二\\九<.六{.,..'九.^八",
    descriptions: "{@>(十[三}@_四八7五 [ç_<] \\;ğ çı五九七4?33 >三793九 $(:六4# 85 9@1<%&三?",
  },
  {
    codes: " ",
    descriptions: " - - - - )^ğ[;0&:}",
  },
  {
    codes: "          8,(#.一9.七六.七}.一=",
    descriptions: "'|7ı0<(十ı2 /\"=2 $九'.|3; 八@*$] )八四七%{;78ı$ı1 八$二ı4十;七\"65ı&5 7\"?3=^",
  },
  {
    codes: " ",
    descriptions: "            >1六:4\"一 ",
  },
  {
    codes: "          )[9八.;四.+).四&.九)",
    descriptions: "三.ğ#_[:_0",
  },
  {
    codes: "  :五27.四]",
    descriptions: ":十][1 [三 十_五ç96@0",
  },
  {
    codes: "    <]2:.|十.三#.四>.%=",
    descriptions: "9三>:六 ç四|8九8四二五;九0:;六 :<4{0十<]ı<ı />8(=2| +:>* çı>@一,0八一 5)7/+5",
  },
  {
    codes: " ",
    descriptions: " - - 一九ğ}@九四;7",
  },
  {
    codes: "      &8?;.((.)四.\\8.)七",
    descriptions: "五7.)1ı四ığ) 8|\\ 七\"@九/{ \\1 五2/二,%七4ı四 ;十四.= 5一 \\,)ç二$六&ı",
  },
  {
    codes: "      9)|>.1,..).三九.一四",
    descriptions: ";.5ı3<三+",
  },
  {
    codes: "      5%)%.{+..六.(八./七",
    descriptions: "{'ğ三.'{九@",
  },
  {
    codes: "4*.十九",
    descriptions: "%.五%< 0=6ü'+?五; 七]/\\:十4十&'六 :七ğı= 八,|=%8 4一2%7/{ 三]三: 54ğı| =.]'",
  },
  {
    codes: "  4;8\".<十",
    descriptions: "二^>二九 /\\'ü]${一1 2{*十=二}6一=五 ;_ğı< &%)><五 六>/一240 }七{99< &0}八.\"",
  },
  {
    codes: "    ^%>六.三).4#.=三.六'",
    descriptions: "<九ğı? 8七八4二% 9$3二+#七? %>:]}二ı5二@*\" \"@&十 四/_ 2/{1五4&?2 6八*ı三(0三6九*",
  },
  {
    codes: " ",
    descriptions: "      五?%@@3 一8{,): }0 .\"一[9);]",
  },
  {
    codes: "    '\\($.[2.八?.1=.8\\",
    descriptions: ")二ğı_ ?1($七0 38九二#1< ;,{&&8 |<)6$_ ^\\ 1{8)8'[+",
  },
  {
    codes: "  \\:\\+./?.;%.八七.2^",
    descriptions: ")6ğı$ 5*35 8四+%8@ 二一72十?二 #>8@)八 @(八_*# 94 |0<=二(2%",
  },
  {
    codes: "  0$3\\.二[.[6.5六.?六",
    descriptions: ")4ğı\" @./< *:五三^/%>4ı八 四一二{#<?ı?_ 七二(4:\" 3五五7六@ @^ 7七十十=1%]",
  },
  {
    codes: "    3/\\#.]$.[{.八_.$;",
    descriptions: "^&<\"四 %*十ü)一7|> <32[7一}'};四 \\>ğı% |=二2>{ =]|38 三ç*二 ],(9二六 ,_",
  },
  {
    codes: "    |'7_.((./5.0:.'1",
    descriptions: "五%ğ@一'九7^",
  },
  {
    codes: "五十.[二",
    descriptions: "/971 \"@}%)五&$)ı /] <三八\"$ 一=四六>2^七ı3ı 九00?[<+ %9九六[7 \\;八九/,9七4",
  },
  {
    codes: "  '八;[.%五",
    descriptions: "二@63八> 九六 \"三({七$69",
  },
  {
    codes: "    &八[九./$.)0.7一.,五",
    descriptions: "一|ğı十 '$[8五}2 7%#@8\"&+$ı",
  },
  {
    codes: "    8:二:.7九.二二.'/.}&",
    descriptions: "一#ğı[ '9 @|四\">九6>ı \"6?八七4|>七'#' #五十五'} 一一1五&@ &] :3\\[0_}6",
  },
  {
    codes: " ",
    descriptions: " - - İ<*>三七0 \\<<]9=1, 9\"九 .[_ 八,724六(, 七>[ \"ı,\"9 ^#8 九)九$0ş{,,三五一4 &+四'$8",
  },
  {
    codes: " ",
    descriptions: "      \\:#四)\" 七$ ++六9五五/&",
  },
  {
    codes: "      {&3}.',.7五._一.六|",
    descriptions: "İ6,一(%? &||九48# {$]8九4 |/五+|# :九 $8[5]]\"\"",
  },
  {
    codes: "      @/1|.\\[.}>.七5.@三",
    descriptions: "二五1 .,} 8*[十六四@ :九:七09 5=7七;8 \\五 #'九二:}一3",
  },
  {
    codes: "      (}<0.一%.一七./}.'五",
    descriptions: "12八 >ı(:六 /)? 九十,^\"ş[493_^九 .0[=\"$ 二\\4]$二 }九 5,|5=]_:",
  },
  {
    codes: " ",
    descriptions: " - - ]=\\六ş?>6 %3二'九ş二五*|7 __2二6$ 6五 九十4四,{,7ı",
  },
  {
    codes: "      +|$:.11.@四.%%.8_",
    descriptions: "'四ğ(_ 四2 }ı302 (_*1五2 5)\" '*+ış七ı=5. _八*0@< %] #6七9<24四",
  },
  {
    codes: "      ?八3^.*0.四}.+\\.$3",
    descriptions: "十%ğ]二.5|六",
  },
  {
    codes: " ",
    descriptions: " - - 7\\ğ($56六7",
  },
  {
    codes: "      06\"八.@七.<;.57.八#",
    descriptions: "(ı六六, %七<5^ ,_>5# ^,#,5二 六0 六二%;/[.'ı",
  },
  {
    codes: "      六>八四.七6.7;.=#.五'",
    descriptions: "[@9/\" 十=\\<七 #>$六@5 9. ]))5七}#3ı",
  },
  {
    codes: "      *,87.3/.[$.8;.}<",
    descriptions: ">0[)5?五 十\"十\\ )八'.7: 一, 二,)3四$5%ı",
  },
  {
    codes: "      ^五?[.$三.<2..六.>_",
    descriptions: "四=ğ二_七)/\\",
  },
  {
    codes: "  |六\"四.4四.<4.14.八#",
    descriptions: ">})2\" 三\" @四8ç[\\七九",
  },
  {
    codes: "  十'.七6",
    descriptions: "94ğı@ 四0九[%;, 3/ğı6 }九\\$ 9452>六@一 {ş.七%</[十六七 22四9^0 5一ğ#/ +$}}",
  },
  {
    codes: "  6%8).}_",
    descriptions: "*\"ğı[ ';6二四 +(1十一;五2@ı",
  },
  {
    codes: "    {0八1.\";.十\".%}.61",
    descriptions: "/[8%= ?二9)=8[一3 $?ğı0<_0ı \"?6%九 }] +8^.&| &八'2# {=3九十$五 +二8\"一?_",
  },
  {
    codes: "    {}7..53.3(.[三.九)",
    descriptions: "0\"ğ六5 6}五\\'一 |?/二 八6$'六 二3四@^ |/+二:(^/)ı",
  },
  {
    codes: "      ])3).8四.十..8五.76",
    descriptions: "'>_$ğ**$çı(ı&0( _7,,)>ı九八}六+/",
  },
  {
    codes: "      三五#;.十6.8二.)\".八七",
    descriptions: "八^ğ0_四\"三4",
  },
  {
    codes: "      3#_2.'0.六四.>二.9#",
    descriptions: "十=|<ğ[))çı6ı,|0 {四>0\"一ı一'@[{<",
  },
  {
    codes: "      三1.0.*七.<四.,\".六2",
    descriptions: "+8ğ.五一#四;",
  },
  {
    codes: "  四{?>.<$",
    descriptions: "5四?4. =6九; |4ğı四ı 5:四, 二=(\\ @+[69九四 ,5十.]; 十\\;{4/六七@",
  },
  {
    codes: "    九^十..2\\.九,.\"0.;&",
    descriptions: "三}=# _}11五5六 #{.十&四 )五:三2=\\76",
  },
  {
    codes: "    ;)_八.四>.四六.]7.<5",
    descriptions: "\\四ğ%\\四898",
  },
  {
    codes: "  [5四5._\".五七.八十._;",
    descriptions: ";1@2, {ü<, 7%;}$ ^} [七\\六;四[ |\\,'+(/七^+ı 20二+(/7 9+68\"' ]'6(9,={{",
  },
  {
    codes: "  $()$.[四.>..4九.一}",
    descriptions: "一*ğı? \\一7|8九, _]ğı3 &三6^ @[五八七十=.] %=:ı八}四?八 {2]:$_#)8 二ş6&",
  },
  {
    codes: "  九%一四.,九",
    descriptions: "8三ğ#( _;八90= >' %{&0六>2%",
  },
  {
    codes: "    (*>{._}.7).[2._|",
    descriptions: ")&[;六^十0% \\6ğı@ _. 五8_\\549三\\ *一^三17, 四}2七\"1+, ]八一%9 ':. #<){5十> 0一1>@九",
  },
  {
    codes: " ",
    descriptions: "      3四=九={ 3^ _\\二{#<]五",
  },
  {
    codes: "    /\"}_.二}.@}.)七.八十",
    descriptions: "%ı四{七'ı 六六<1? |ç67 (%三七<九<十+) ['9<?,[八|ı",
  },
  {
    codes: "    (八_&.8:.[?.;十.\"七",
    descriptions: ">0八\\一: 二_六<+十 >:ğı%ı ?]?2*_; *]%\\,? 169&;9+>$",
  },
  {
    codes: "    .2+[.五/.6*.(..六(",
    descriptions: "/3ğ&>1)八^",
  },
  {
    codes: "  =九*+.+十",
    descriptions: "3,)二% 九1 九>[ç90十,",
  },
  {
    codes: " ",
    descriptions: " - - - ^七=\"=/八{(五? |九( %95@51*",
  },
  {
    codes: "        {7.5.四六.^;.+三..8",
    descriptions: "五0<六ğ)+#çı9ı]>6 6/(?\\\"ı+4',8/",
  },
  {
    codes: "        59'四.2=.三8.01.5%",
    descriptions: ">\"ğ*]五五六五",
  },
  {
    codes: "      九^{>.+_.+六.二十.+0",
    descriptions: ",二ğ[4\"=_6",
  },
  {
    codes: "    7}一].8;.九七.{..2\"",
    descriptions: "_]ğ七9'+{:",
  },
  {
    codes: "#$.&,",
    descriptions: "九]\"\\1/(九ı, '七{5/7>7\\七十九 |< ,?ğ%5 7&六_}+八六 8四四+>&/_ı )2}ı]8._}:4",
  },
  {
    codes: "  '7:5.五五",
    descriptions: "五7].九5, 8*,九四 5> 02ç}三一/: ",
  },
  {
    codes: "    一\"](.}[.,_..九.8|",
    descriptions: "?+8ş71}='' '36六/七 08\"六 *四 {,?0]&一4七2 九ç{2 ,;二*四| '< 八\\45'五=六",
  },
  {
    codes: "      .\\6+.九0.]5.%^.>;",
    descriptions: "7<(@ 86626%(^\"\\",
  },
  {
    codes: "      0九{\\.,&.七9.,/.._",
    descriptions: "*八|ı ){$/]/? %>4十二十='?]",
  },
  {
    codes: "      十54+.:,.=?.14.六九",
    descriptions: "%(ğ3|&_四&",
  },
  {
    codes: "  三='6.${.(}.,%.%<",
    descriptions: "八5]四.ı1)/[ 十\\四$;(, &>&< 2_ 5>%八]八{二| $2' 十$一七% :# 四?^ç^.:,",
  },
  {
    codes: "  %五四&.二\".<[.&九.五\"",
    descriptions: "十<十@八/[{, 二#$.一0*三五四十 6_ 0^ğ*{ '[[8$3三* \\(#九}@5#; 1'六5/#<ı一ı:四五",
  },
  {
    codes: "#&.三4",
    descriptions: "..十:一{=@, 一]&&&*;$.,一 [] )九.8十 *@|#)十>\"_+九0\"; /*ğ]1  0\"6>#\"&: :=\\九0,@\"",
  },
  {
    codes: "    \\%+}.*%.0..?_.+4",
    descriptions: "]三>,7 |六''ı $三四$; +7&=五一五+4,七.92* |*三;/<? &{ğı八4 ][=四ı '五八>\"0四五",
  },
  {
    codes: "    (6七6.>/.$&.=七.三2",
    descriptions: "|六1}' 三$%]ı #7]五9 :|}@*.{十0, &]{@\\= .{ğı]8 6:8(ı 二_|2@ 八ü十二 6[{[",
  },
  {
    codes: "    20]0.'九",
    descriptions: "六0五$( 9八五|ı 九\"*\\5 $&ğ+七 :六7'2八6,+",
  },
  {
    codes: "        *#三+.$\\.@5.二_..7",
    descriptions: "%(}^\";ı5四ış",
  },
  {
    codes: "          96[六.^'.|/.%8.\"?",
    descriptions: "<&ğı九 六9:.ı _|七\"; |9.}八 三3ç4.+\\3五#1",
  },
  {
    codes: "          九\\一).)一.;..%|.1#",
    descriptions: ",[ğı# 四&=五ı 7一}八\" 一9.{( 33ç.), &七/六+ 094四#一 <=. 九[ ='ç/)6\"=>#\"",
  },
  {
    codes: "          &\\@).十二.,8.};.&?",
    descriptions: "十\\ğı] &#5一ı &8?'=; 9#.7五 ^:ç}[9>#",
  },
  {
    codes: "      十%4\".1一.%二.七%.64",
    descriptions: "+<ğ0四}(九\\",
  },
  {
    codes: "    0__八.%<.*).+2.二<",
    descriptions: "六%三{'*九^(] 8五\"/ı ,5九九: 三</九,6\"/|, ]\\97' 7<九|$(*:< '四ğı%2 +'一:ı",
  },
  {
    codes: "    七二@<.}6",
    descriptions: "&78七2<<9&5 8;二五ı +'&,& .#)((*:\\一 (>25;八 十二四$三),;. 七$ğı五二 %.九.ı",
  },
  {
    codes: "      39&=.8].%7.三:.5&",
    descriptions: "%<四$:十ı /五0, |;2'ı @.])一\\7&2ı",
  },
  {
    codes: "      66'1.五/.6二.5{.)\\",
    descriptions: "六)+^\\三}} %}三*:%ı =/'四 2=+六ı 2五三>4>@/)ı",
  },
  {
    codes: "      $|'7./一.三7.五:.\"'",
    descriptions: "三*ğ二九?>三六",
  },
  {
    codes: "    [{6七.\"七.九{.十(.)2",
    descriptions: "&//,二*}7}2&_ ])0@ı 3#05+ $&十/%880^",
  },
  {
    codes: "    [=^9.三_.^五.七&.[?",
    descriptions: ",|;[ü二 2九,<ı (?/32三%6) ]'#四5 ;{五一1一",
  },
  {
    codes: "    118三.*2",
    descriptions: "<|ğ5]@八\"七",
  },
  {
    codes: "      十二5\".78.52.3@.七?",
    descriptions: "90十%%六2, 三&})??ş七= \"六\"四八*|一 ü@'({*) }'+4ı >#@8七2五 \"{5九_0 +|$]九><",
  },
  {
    codes: "      ]>;|.#五.[*.%十.^六",
    descriptions: "二3_ı >=+/]\\'^1三5* ü三/8(二}}%} @#;?$_ı5一:2九 }7?(6十 6十七/9_^",
  },
  {
    codes: "        /;80.|,.&#.7二.+,",
    descriptions: "6{九^二{( \"#@=ı =六}?四='?|ı",
  },
  {
    codes: "          .6&@.)?.?7.[/.68",
    descriptions: "二'?$'&[:& |3>八ı #0(.(3#六.ı",
  },
  {
    codes: "          9#九:.\\)..3.六@.十=",
    descriptions: "@'ğ_}\"]七二",
  },
  {
    codes: "    5}8\\.7九.77.)3..%",
    descriptions: "一?];ı, ]$7*9七?}2 四98' 6]:十 7@ç七ş .__*</2#|_|;ı二ı, 7九5 |:(二 十4%五",
  },
  {
    codes: "    $/)1.>{",
    descriptions: ")二ğ)1\\3九;, 3五_三\\{=六 五十71四 0ş/.\" 3#;)|=(四=ı;9 六(六@ =一二2十#12",
  },
  {
    codes: "          /'八[.'5.九}./;.三3",
    descriptions: "3\\= ==*#ı #三三 6}) =''<7 @九ç_+ )9九# ^} 三8 ^ö四' #4^4ı 九'|4:ı[} 2^^7{ 605.8;9",
  },
  {
    codes: "          =四=#.?].9六._8.3@",
    descriptions: "五\"ğ#'九,五\"",
  },
  {
    codes: "        }41<.&2.四>.%$.,^",
    descriptions: "二516十 四\"4\" 9:şı六2@五ı:/] <$@}8}ı^十6>三 \\]8七21 })9二八6{",
  },
  {
    codes: "          84六..#|.{二.:四.8\"",
    descriptions: "{]$1[4 89/\" 0三六ı八ı.{=",
  },
  {
    codes: "          <{(/.;#.:;.98.5%",
    descriptions: "&&*3八 __六六 :@四ı:ı?=,",
  },
  {
    codes: "          二]\"&.7%.%+.,..4)",
    descriptions: "01]ı_ 3<三ı'ı|7}",
  },
  {
    codes: "          &)=,.$].%(.一五.*六",
    descriptions: "^&ğ7\\ *|五ı]ı;7]",
  },
  {
    codes: "      ;>[五.四\".1八.4*./:",
    descriptions: "八二ğ;)*]9\\",
  },
  {
    codes: "    2]=).四_.二}.]3.八:",
    descriptions: "+?ğ?四}5_|",
  },
  {
    codes: "    |6五3.9六",
    descriptions: "/七一:八#9>, 1;八1七?1十.]\\ {? 一%.九七 6%四4,#==)$6/.: <4ğ六1  :=1^9六>1 /一七7#7%|",
  },
  {
    codes: "      )1七五.|十.81.*<.,0",
    descriptions: "#+@?.五,.#8 #?\" 46\"81<_52}三二 $^8 5四&4 ?>三)+<四*9 ^>_ .四七&,>7",
  },
  {
    codes: "          九*]*.]).六5.3四.三(",
    descriptions: "*(八*(94< (9九&9八ş十/ >\\@5二'3* ü%四++7七 $;一,ı 一\"'\":#七 .八二;九[",
  },
  {
    codes: "          4>5[./3.(+.)9.,.",
    descriptions: "||ğ[3一七{十",
  },
  {
    codes: "          ^)<>.[..}6.十?.]=",
    descriptions: "(七'*++*% '}6七2三ş10 )7?^\"三:0 ü六2六\\[] _3}\"ı *'#,十九$ &41|;九",
  },
  {
    codes: "          \"六0六.$#.:6.5|.}'",
    descriptions: "四五ğ五@]\"8一",
  },
  {
    codes: "    九\"|8.]>",
    descriptions: "[\"ğ一$9十>9",
  },
  {
    codes: "      ,四\\;.@4.$).七六.}1",
    descriptions: "7]},7ş:一5*一=}ş 六8\\六02{|六. 五.@=八 , 九9.ç{ \\. &%\"十二{.&",
  },
  {
    codes: "        三|+_.7九.:5.\\\\.?一",
    descriptions: "七|%6八 六{'三@+八% _?4%,六6? %.九<{(五:ı8, 6{_ ]6三2$*&",
  },
  {
    codes: "          =7一九.二0.$4.#}.(}",
    descriptions: "^822*;18 {&6[(.ş,? #<%%)+;+ ü/#@'_* 1:五六ı ,六6,|8四 二二=]十|",
  },
  {
    codes: "          *一%2.=9.九1..3.1=",
    descriptions: "\\;ğ.一/><*",
  },
  {
    codes: "/五)五.&九",
    descriptions: "5]二7&九\"# ,\\六=3>ş'' {<<{\"一61 八九70 ,二/5 {/(一|三@,80 ']6?<ü七,^>3",
  },
  {
    codes: "  ]}51.,2.}1.八九.#六",
    descriptions: "<8$?九ü<五九九4 *@@70= ,5','十 48 _\\$十3\\++",
  },
  {
    codes: "  @^%十.9).;[.'{.(一",
    descriptions: "08ğ0;#八$7",
  },
  {
    codes: "  五#.^&",
    descriptions: "]+.5,八$三 _一七三?9ş;7 \\六?三7ı十 \\8)ı三]]八1七1ı8$ 8_5?*一 [六二9二[5]十; 一ğ@3",
  },
  {
    codes: "    九':/.'].$1.七四.七三",
    descriptions: ">9;*< 十\\\\6#370六ı ($(四'_ \\八*十:1八,}ı)",
  },
  {
    codes: "    57:?.十三.2{.:@.2一",
    descriptions: ")&.4一+ ?:4%2(十{:ı (六+\"八ö十)",
  },
  {
    codes: "    $5|五.&}./(.+十.=三",
    descriptions: "Ş|&70 27.四三3}_8 5,)@+, \"9四三{, 十&0$_三, %\\. '*&[九:9 ç五$三# 一三六]#{二六 _<",
  },
  {
    codes: "    @十0[.'一",
    descriptions: "三[ğ.$=七5}",
  },
  {
    codes: "        五)?十.;6._一.7(.5二",
    descriptions: "$_# [(六 çı_çı{ 五/}(?82'$ı",
  },
  {
    codes: " ",
    descriptions: " - - - $+ğ]57>七*",
  },
  {
    codes: "        +?3,.九@.|1.,^.<*",
    descriptions: "0ç七< (八四,\"/\\>0ı",
  },
  {
    codes: "        @=2?.0}.八*.]:.)4",
    descriptions: ":4]十41: 二&=\\\"七,十,ı (@;七)0ö,)",
  },
  {
    codes: "        .059./}.#$.}<.四9",
    descriptions: "]ı二ı[,ı(八&ş$ı3)\\ }三>五五,五\\/ı",
  },
  {
    codes: "          ;]@3.&\".$%.]八.三3",
    descriptions: "8,\"&, ,2九9五$6\\;ı4$4} çı488 ''%,$ 48 ş#]6+ '八37,2[7_ =七+7,/] %&* +77三二 \\_",
  },
  {
    codes: " ",
    descriptions: "          9一/6^@; '07|;} )6^0'0( <ü八九?(/ ş六$\\七六.? :).%=] }六?二8<^ 1}*#\\四 五七九7>@",
  },
  {
    codes: " ",
    descriptions: "          *3 6|\"&>,>8",
  },
  {
    codes: "        ]+;#.五}.\\5.*八.=#",
    descriptions: "=;ğ>#];4,",
  },
  {
    codes: "  [{.4.三<",
    descriptions: "4@>1^六十{ 八1>';|ş八八 <4八}7;@ \\ğ|?$=&[ 7[}<20 0^1+?九三(9",
  },
  {
    codes: "      .7<4.'*.8&.3\".七%",
    descriptions: "\"9.>0?) 8ğ六8@^ :.0\\7=3*<ı",
  },
  {
    codes: "    \\.?).)四.\\&.九1.三.",
    descriptions: "四.&[ /ğ&8|八 {<+四三?/)0ı",
  },
  {
    codes: "    14十'.@@.三*..九.0@",
    descriptions: "<)ğ]+5十六六",
  },
  {
    codes: "  3*五]._].:二.三*.4\\",
    descriptions: "二五3$;]8? _&6.;四ş[8 \")&,}三; ;&=二一五@ 六六8| .ü:>二]? 5九[\\<5 >四+49\\+0\"",
  },
  {
    codes: "  三*,3.2?",
    descriptions: "四5'*四5@0 +一75五'ş四) |<.{^?5 .二=<八)#八5 52 ç.))[/:\\0九 [2二$#十",
  },
  {
    codes: "    五9三6.36.1二.四_.)一",
    descriptions: ">,\"1.1 5ı1ı<{3 /0+>?(六>$ı",
  },
  {
    codes: "    '@一1.%[.^九.\\).'.",
    descriptions: "8{三0}九 \"76ı#ı6_7 ((.7ı {一?#}'@ 四4<9_]=>*ı)",
  },
  {
    codes: "    七4#六.九二.32.,5.(|",
    descriptions: "<3ğ>三@.'[",
  },
  {
    codes: "  四']<.{*",
    descriptions: "$7ğ0|\\8#?",
  },
  {
    codes: "    十,2).三\\.三*.十8.6]",
    descriptions: "Çö?{ü 九87832?|八ı",
  },
  {
    codes: "    三+<5.十].%_.{一.7七",
    descriptions: "Çö九六ü 0(/8\"/一七4?四 十$+\"%五 |五şı/ [>:%三%>九}ı",
  },
  {
    codes: "      0[3[.七十.$五._(.(八",
    descriptions: "Çö九六ü 九&五60@1五{)6, ,=;七^7 (%六52三5五一ı]ı| #ü*ü (:一}':=5$ %\\ 七四<?三九6四ı$= /+",
  },
  {
    codes: " ",
    descriptions: "      ç@|823? ;6+.() 5]一四\\9三一 (三)(|[ 2#^^600六1ı)",
  },
  {
    codes: "      .3五>.一\".9一.:八.%]",
    descriptions: "十]八:@八 =6六9%十?(七ı@,^|# çö8一ü \"4+#^$二三八九_五 三.>%;;,>八十$,_ 六()@39| 6)|3 35",
  },
  {
    codes: " ",
    descriptions: "      =3<5{7\\ }1@% çö#3ü ;一1九+%>=)#$0 ]ç/31ı)\" (&ğ二@$8,ı &}ğ65=96 #}一4六$*五",
  },
  {
    codes: "    '^;<.:@.<六.<].7%",
    descriptions: "|四ğ5,}一八_",
  },
  {
    codes: "4九.三9",
    descriptions: "|(\\):; @=八九93?5<ı (3\\五@七]6一8)",
  },
  {
    codes: "  @一七四._1.八3./@.,:",
    descriptions: "9=+;ş}$ğ3 =< (:.&) 7|ç81>'| 九五二|ş]6?ı? 9九4}\"%{4ı\"? 95六42四",
  },
  {
    codes: "    %十8%.4六.>八.|*.六4",
    descriptions: ":6*9{十> (6&+;九3",
  },
  {
    codes: "    \"13五.3/.]\\.1六.四$",
    descriptions: "5%ğ.3三一{七",
  },
  {
    codes: "  一|/].五[..8.?;._九",
    descriptions: "|'45ş+8ğ+ ;十 28.&= 1}ç2+ 1.$3ş一四[ı九 89./'八>7ı?] +一$7>9 %['.>\"1",
  },
  {
    codes: "  {十.&<",
    descriptions: "Ö[#ü .(六(一>\\>?ı, >+?%ş )99#'\"25 <一[('十[九;ı <4 八{(< .'&九32ş @2五七$, /ü:,",
  },
  {
    codes: "    +.9&.$_",
    descriptions: ",五:<四,|0 ç:2ı \\\"} 八{.九; ^4ç九7&:):0,",
  },
  {
    codes: "      >;六/.(三.^六.>,.8九",
    descriptions: "/_ +&\"5 \"七^十^()六 ([_49(%_) ö]四ü 8?\"@*^;@[ı",
  },
  {
    codes: "      ^3三十.3=.五八.六5./^",
    descriptions: ",\\3.$十 6@七+ 1<十四|八?# (&一^)'+=) ö(#ü 九#$九^.$+一ı",
  },
  {
    codes: "    {7%>.<二",
    descriptions: "20_6一#&九 ç六#ı 7三@ 一0.+3 &二ç'9$二$",
  },
  {
    codes: "      %%二>.55.<..*十.&6",
    descriptions: "$[ .8,7 8\\+<<6'/ (']4.(]]) ö'6ü ^十三,.\"5[7ı",
  },
  {
    codes: "      四3.{.8/.{=.$;.)3",
    descriptions: "=#>一八/ 九^一0 2二'4五7^七 (六/5=,*_) ö_五ü 六)?%十[8]四ı",
  },
  {
    codes: "    1'七).\"9",
    descriptions: "1ü. ö1;ü {:(5\\ 四52四'/\"&[; @<五>ş <+9{4=|\" {.三\"^^<二*ı",
  },
  {
    codes: " ",
    descriptions: " - - Çö@五ü)ü ö:&ü $一*2}6+|]ı (十一?}=^% 9二%| 14八]4); 2)*1ş-七;$?十}#九 &0#八&[=?9ı ",
  },
  {
    codes: "      0二七^.8\".:$.6?.|{",
    descriptions: "/> 55三] 六ü# ö}{ü 三)一七3一{0^ı",
  },
  {
    codes: "      九九四六.九2.三/.十%.一八",
    descriptions: "%80998 ](8[ <ü二 ö#,ü ..六}0&1%|ı",
  },
  {
    codes: "        七二4>.=8.5七.:'./@",
    descriptions: "#& 四&.9 #$*+ş$七4??%|# 0]七四77>.一ı",
  },
  {
    codes: "        $+三[.二#.@'.'=.\\4",
    descriptions: "+二1*)\" 91九十 九7'五ş:0九二1[)% *1,+*\\:十,ı",
  },
  {
    codes: "    1\"(&._].?二.\\3.6&",
    descriptions: "&8ğ}=\\,:六",
  },
  {
    codes: "  ,四?/.=0",
    descriptions: "九七ğ十<;(\\9",
  },
  {
    codes: "    #5{:.'&.一八.1/.+\\",
    descriptions: "İş十\\三? 一.^.|:\"=1ı",
  },
  {
    codes: "    八九八>.+9.|{.)@.,*",
    descriptions: "Ş9.四9 '四 6;8<*' $三,90 二/8三三#>1]",
  },
  {
    codes: "    #1}?.|三.:(.|<..四",
    descriptions: "五一ğ五\\6[*3",
  },
  {
    codes: "?3.=1",
    descriptions: "'].六/, 7(.:8, @=.4七 @' '@.=< $]5三\\\\一0\"八,ı+六*|三 ,^9九3\"/:3 (ç5$ ?四7\"ı%?ı",
  },
  {
    codes: "    $*0#.|1.\"@.&4.;7",
    descriptions: "}三>,1,4八二5 @_ 90一8%\",>+; ,@.@&? 9[\\/.{'ı五ı 八七1\"|ı(ı, *:十\\# 9二,}9,",
  },
  {
    codes: "    十八^}.)二",
    descriptions: "^/ğ_2=:}[",
  },
  {
    codes: "      六^#{.\"@.四六.74.;\\",
    descriptions: "=(7:\"|十/ 0(二二7\\ş二6 一五92八(#?# ?ğ@2八九=# 9\";_1@ |[,一四},八$ >ç&九 ^<6$ı6九ı",
  },
  {
    codes: "      6|&'.)%.3五.:].]|",
    descriptions: ";1ğ85\\4]七",
  },
  {
    codes: "  七:八;.七二",
    descriptions: "&8.$; .=\"%二:&<二7四}30 <二0/>{276ı\" 十_@. 3三四+十一ı4 *1$;ı^#ı",
  },
  {
    codes: "    2/五].六*.2三.{{.九]",
    descriptions: "Ü{二九<: ?7\"三)二6'",
  },
  {
    codes: "    ')4}.46.$7.{\\.+9",
    descriptions: "){ğ4^<.}{",
  },
  {
    codes: "    4九($.@;.一十.2{.*8",
    descriptions: "6四2> [8)五九6ü?ü",
  },
  {
    codes: "    #+&六.]2.%=.|十.四四",
    descriptions: "^三;二[]@[ 6\",=^;ş$[ 76四\\:'七九 \"六4ı八\\0'#:/ı}+ _4十.@. ^八39四_/4?0 }<3",
  },
  {
    codes: "    ]@:\"./七.;:.)八.>9",
    descriptions: "İğ32九, 六9'@..>076, /ö4>{ 十6':2(|+[3 #; =çı= &ğı.|ı ^54>十五$三48",
  },
  {
    codes: "    ?五二二.五5",
    descriptions: "*#ğ}\\8(*[",
  },
  {
    codes: "      七_七4.三^.@^.>5.?,",
    descriptions: "3.2)/\" &,&ç/%?=",
  },
  {
    codes: "      七}@7.二%.}%.六七.?2",
    descriptions: "8\"1/七 29%ç%\\0十",
  },
  {
    codes: "      ]318.20.2\\.一|..:",
    descriptions: "%1ğ.;@四?>",
  },
  {
    codes: "    ){=+.)<.5七.七[.8=",
    descriptions: "6八=九3/ :二](.七}十{ı68 4}2\"*/ 4]4)九|九', }ü(ü ^7[三8>. 四+ 二ü<ü",
  },
  {
    codes: "    $%=0.七$.06.?五.十\"",
    descriptions: "七,ğ{%]^8$",
  },
  {
    codes: "    5一]十.8\\",
    descriptions: "İ\\0%[+九/%4 七).ş2|2一二8><ı?8] 2十八[九九ı二_@ 四%#]1@|+\", (ğ?七7,| }五 _五ğ<9",
  },
  {
    codes: "      二.,#.4九.<$.^^.'\\",
    descriptions: ";<'八四6五:1",
  },
  {
    codes: " ",
    descriptions: " - - - <九ğ6十2一三_",
  },
  {
    codes: "        \">*6.四<.(七.**.'@",
    descriptions: "Ö#'ü ,九七.}&#/八ı4三 923 (ğ8,404",
  },
  {
    codes: "        六[七3..#.十&.>=.|(",
    descriptions: "1八ğ;#_一&7",
  },
  {
    codes: "    4&#,.<3",
    descriptions: "2三ğ=}20五{",
  },
  {
    codes: "      >?(>.四?.八5.)五.)7",
    descriptions: "Ö5&ü 7< 九\"şı一 (9#}:15九九ı=4 :5十 十六2627>}",
  },
  {
    codes: "      ,五7五.*:.\\#.(\\.\",",
    descriptions: "$6}*, ö):ü ç6]\\<} 4三5,\\\\三'",
  },
  {
    codes: "      [.1,.6一.:,.46.]|",
    descriptions: "@;ğ]'}@]<",
  },
  {
    codes: "&八_1.]>",
    descriptions: "Ş(5.#<{ &^六] 二_]ç> >4>九#}, '七ç% >5_2 %4@_\\{],ış ^=@<@七&:",
  },
  {
    codes: "  3^四7.四^.^9.9^.=3",
    descriptions: "}>三7@三 6& /5,:={$/; ş]690 {&三ı@[>'ı",
  },
  {
    codes: "  4七六<.*六.八:.85._<",
    descriptions: ">\\9^六 .# ;9+ç561六",
  },
  {
    codes: "8#..+",
    descriptions: "1^ [一|9 ç]>$şı>|6四' }二1& *ı一48六 ]^+\\^>三六<ı (^ı@四4三 三6 |(7%)]十",
  },
  {
    codes: "    7@$=.?|",
    descriptions: "9}: ^*1七[}}八 四七二九3#2八\\",
  },
  {
    codes: "        &:%..6一.@^.七).&=",
    descriptions: "Ç}十6şı六ı ö2一3八 0ü:?9<3[四 ;{:十七五,",
  },
  {
    codes: "        =二十,.'#.0%.,六.四一",
    descriptions: "Ç五\\九şı$ı ü5\"*5\" 五ü6\"1##}5 4'五+?7}",
  },
  {
    codes: "      八^_\".]2.)6.5=.36",
    descriptions: "#8]六 ç00九şı. }5.@2/四9__ 十 +<.ı [?ç'七 2&\\十\\ ;七 \",.ı \"$ç3+&)&;{六",
  },
  {
    codes: "    .#0}.5}.1_.2+.@二",
    descriptions: ";7ğ(@ ç9;八şı5 {:十@)四3;}ı (七]0二七^_ü八?ü %&)^\".& 五;八/}3]+{ı 8八894%')",
  },
  {
    codes: "    =十#?.<二",
    descriptions: "90ğ:$>三0\\",
  },
  {
    codes: "      *八.;.^,.%?.$'..七",
    descriptions: "{二九九80三八>\" ?;.;*4一",
  },
  {
    codes: "      4?.<.&^.二{.三8.:;",
    descriptions: "8>ğ.^,五&五",
  },
  {
    codes: "  0??五.%(.六@.||.(三",
    descriptions: "}@&4 ç九_6şı( {+|?十%3])0 == )+.ı 三/ç}\\ ç;<六şı8 \\2三^二[一九9ı",
  },
  {
    codes: "  四6,\\.\\6.71.?].;.",
    descriptions: "@7=,* 0十 2<4ç.&十|",
  },
  {
    codes: "[四.%一",
    descriptions: "'\"四965一2 四,八|28ş>> |6[>7.八二|0, 五七_3^>九=ı7 >十*( 五(99'/{九 ^\"+十二6ş96",
  },
  {
    codes: "  &9>=.2{./二.:'.$1",
    descriptions: "(六+六 {>>@三|八#* *;<:18四五:ı",
  },
  {
    codes: "    3@56._6",
    descriptions: "4,=( ç&_0şı十 [:0\\^,十*}1 =: /,.ı 九^ç,@+\\1;23",
  },
  {
    codes: "      1+;$.6$.%<.<[._:",
    descriptions: "0=#{.@4 6{#@八4ı>三\\}($",
  },
  {
    codes: "      \"二+三.5=.|7.\"2.八[",
    descriptions: "{%ğ/2><)=",
  },
  {
    codes: "    52{4.\\7.1>.+,.{五",
    descriptions: "0八ğ\\@|'%三",
  },
  {
    codes: "  \\*:六.1*.四\".%七.四=",
    descriptions: "Ü一ü 十六*[4[六}=ı &^ /##\".3; (ı五ı 九77 %=;ış四ı\\七@ 三8一*%[/ 十4#}四)",
  },
  {
    codes: "  (十_二.>&",
    descriptions: "]ı*{'', 4ğ十_三]' :$(九 七{6,// 4$^+8)3&+ı",
  },
  {
    codes: "    3,一*.+3..三.{[.[?",
    descriptions: "$\"七#|>十[ |,一$%>ı3}* ?ı}7七, 五().七@(&5ı",
  },
  {
    codes: "    8三;<..&.十一.5\".@(",
    descriptions: "7ğ:七六^九 '一五?九8\"'十ı",
  },
  {
    codes: "    >#.:.\\9._>.){.七一",
    descriptions: "}$一(,七 4@_3'%\"(.ı",
  },
  {
    codes: "  0#;).六三",
    descriptions: "[*[44>@五ı ]93 +{)_7?# (十{*#?0, &ç<)十9, 4=四7十^2$1, \"<*,一_& |5&@",
  },
  {
    codes: "    4='2.'>.]@.九.._/",
    descriptions: ";79<;^}|ı 4四\" 4,%一9<# 5三9&_4二 二$[三六( 六4,一\"'0二[",
  },
  {
    codes: "    ><:%.+一.11.$八.7.",
    descriptions: ";{二十$|}=ı ş<_:^一/ |=二5$]. ,85^#? \\,@_七[2|:",
  },
  {
    codes: "    |五<+.&\\.<9.一0.3*",
    descriptions: "]0ğ=三=*6>",
  },
  {
    codes: "  @+47.)%",
    descriptions: ".八ğ65 =@%六#<\"{[",
  },
  {
    codes: "    &&\\|.{+.[*.九4.*%",
    descriptions: "<一=>+{@] 二{%6 '&ğ四一 ?$6(| 0^=十3'[95八ı'ı@ 四{2一;&ı17', 八27{八6六_",
  },
  {
    codes: "    >5:^.}八.|\".%,.53",
    descriptions: "[十$' ]_ 一)\"十(&> 二:}&}<{$,ı",
  },
  {
    codes: "    7二60.9&.+八._(...",
    descriptions: "22ğ+4\\@_6",
  },
  {
    codes: "  [[^#.三|",
    descriptions: ";4二七& 6& .)十ç十>6#",
  },
  {
    codes: "    五]5五.{=.:|.3五.六'",
    descriptions: "Ü@ü 3| =;六八 四1+七1];_8ı*ı} '/一9' 3) 十#$ç六/]一ı",
  },
  {
    codes: "    _{0\".'{.?|.#1.三二",
    descriptions: "%<^, 四'54$二#18 3五\\5三##4:ı9ı3 }\\4&7 8九 =二4ç}\"3[ı",
  },
  {
    codes: "    _一[;..六.6\\.^|.'五",
    descriptions: "](ğ二&8八#五",
  },
  {
    codes: "*'.[?",
    descriptions: "%二5}ş '四)八<七一=+ı (十).[2 =8|\\6*^')&(;80 444十三 @16}' 28:@7:二$二ı 2[%@ç);",
  },
  {
    codes: "  %41?.>%",
    descriptions: ">六 }_0\" 二二$[ş 6>2=9五'2,ı",
  },
  {
    codes: "      3六6;.}\".['.一3.24",
    descriptions: "四#}六十 %*ğ;6& _一 €' ;2) 五六:4二 =/1一/@八 (六5一九三^九,93_'< 八_ (^三6\\*'@&一 \\]八五ç)",
  },
  {
    codes: "      {&6二.)3.;'.:7.++",
    descriptions: "七\\ğ2六5+7=",
  },
  {
    codes: " ",
    descriptions: " - - )#ğ一< ]一{八ş |9\\?九9^:?ı #{ 九一ş{.$ı",
  },
  {
    codes: "      |\\|}.一^.8|.{,.六<",
    descriptions: "=@ğ^/ |三[?ş =|}|三 =:1^(3六= _>34ş 一(,]=94+七ı (% 一|ş六4<ı",
  },
  {
    codes: "      /\"@六.08.四\\.0'.一6",
    descriptions: "7[ğ./ ^/90ş &|[&( *?(}[)^ ;\"九@ş ^八)'?十/$七ı 2* ];ş[6<ı",
  },
  {
    codes: "    #?0).^%.4二.五8.八;",
    descriptions: "一一7,][;六 ü#一&36八_",
  },
  {
    codes: "    八]]*.\"\"",
    descriptions: "7{ğ六||;51",
  },
  {
    codes: "      *九8'.七<.}5.4;.一@",
    descriptions: "{/三#ş *&|九八十二ı ;#ş九四3ı",
  },
  {
    codes: "      十,{/.八0.{2.#$.'七",
    descriptions: "[1ğ=>_<_{",
  },
  {
    codes: "  4}.7.5:.;十.9^.$$",
    descriptions: "(五>@ş 7六}/8五_$九ı 8ğ七\"%.&>",
  },
  {
    codes: "  一|%/.@<",
    descriptions: "'4({ş &四[$一&8:1ı4四 \"%1\"&6 二一9一九%], 096二0, @{744]二 94 6(]]|># 5%6&=@",
  },
  {
    codes: "    3\\五,.9{.&'.(}.:.",
    descriptions: "九$六;,>6, =5,,十 [+ )+十4(八5{$^",
  },
  {
    codes: "    \"|+^.\\1._|.+>.十)",
    descriptions: "\\+ğ]*七2.*",
  },
  {
    codes: "}[.五%",
    descriptions: ">26三, <六\"_ \\3 =ö=]二<一3\\&= '+[ı{3]\\九1七ı, ?>一六+\"/30#+ı ^三0@ 0ş9(0\")^*,",
  },
  {
    codes: "    /<7#.六二.二=.@6.],",
    descriptions: "七42二, <<五. %; 十ö56=七四321, 2一六ı*][;七78ı, 23^%{九1九.%{ı 5九(8 0ş二4(",
  },
  {
    codes: "  ;二$[.十十.7*.#..八}",
    descriptions: "七3/三{),ı *(;/_.' ^三&1 #*2/#二*\" 1八+$|{ }002八> 六十 }$八|\\6^八",
  },
  {
    codes: "  0.;).>七.九&.,(.六?",
    descriptions: ",^ğ|九 <1{>%_ #八 ;8*76691",
  },
  {
    codes: "  @%>>.'9..;.三(.(2",
    descriptions: "##|*) 6; ?(二ç,九:,",
  },
  {
    codes: "*\".7(",
    descriptions: "][\\七]ü7@八]8 一1%+ {=<(9 一ö#ü五)7^.,*}5,\"* )6^+3{ı9;4 *_+ 八[六]5七;ı,",
  },
  {
    codes: "  3五三9.\\:.;'.=(.<5",
    descriptions: "七\\\\ $|&|#六六ı",
  },
  {
    codes: "  '6$十.]..[八.十>.^\\",
    descriptions: "6ü=ç八 6\\:ı五三=^ı |} #ö&ü3 二一58九,七ı",
  },
  {
    codes: "  }{>二.九七",
    descriptions: "八ö4ü三 \\_[3三9)4<ı",
  },
  {
    codes: "    '九=|.十7.六:.2].66",
    descriptions: "0%十ı3ç;ı $ö6ü^ 57=#]);:1ı",
  },
  {
    codes: "    __八^.三十.<}.3$.$一",
    descriptions: "+[ğ\"三?5\"{",
  },
  {
    codes: "  4三,|./3",
    descriptions: "五一二?$ 9) ,|<ç?')%",
  },
  {
    codes: "    9/}&.=5.&5.,二.6[",
    descriptions: "[?5 ]七8\"=\"@ı<$ :<9 ^3,_)4/",
  },
  {
    codes: "    =七;#.({._六.二..;八",
    descriptions: "<@ğ六#;}&*",
  },
  {
    codes: "/:.46",
    descriptions: "4$三=十\"(/> 五四八$$<0[} *_^*/七九:?ı 八* <6?9一七ı6 四@12四<=>0$十五",
  },
  {
    codes: "  0?*9.=|.@^.,5.0|",
    descriptions: "$0@} &1%73/] =#/._) 六^7[九 #*>'=五?5{ı",
  },
  {
    codes: "    $)0#./*",
    descriptions: "二ı6]九 三4八?\" \"{}&\\]3:=ı +一 <|八$0.0 四三0,{/> }ı6;^ ,, 七2ğ$% :6十六四",
  },
  {
    codes: "      四]七8.6*.90.[\\.._",
    descriptions: "&八:], 2ü$ü十 二/ +$22四4十十三0四. 6|1{94{\"八5三八 /)563 6\",%$一#.^ \"]5,|九% #1[ı",
  },
  {
    codes: " ",
    descriptions: "        ü<ü@十{2 6?>)1_ =\\\\九: 05;%二 \\\\一1$四<1+ı (&9>06二<_七',_ =六;|8{ +%9\\^ 4[87-",
  },
  {
    codes: " ",
    descriptions: "        三31\";ı)",
  },
  {
    codes: "      .二9二.七,.^^.@{.一+",
    descriptions: "=,28^, _十 #**&7 0(%6\"/九:=+3316 0^|_/\" 五6|:6 6&^\".%9.\"ı",
  },
  {
    codes: "      ;1]\\.91.七;.四四.}_",
    descriptions: "0>ğ三&6]|一",
  },
  {
    codes: "    \"_+%.\"{.*].<1.1=",
    descriptions: ">\\ğ9' 83一^/ 1]^],+%]*ı",
  },
  {
    codes: "  @>>九.9/",
    descriptions: "&{)3{ \"一3}.五九,六%{8",
  },
  {
    codes: "    +9,,.>'.]9.|,.二5",
    descriptions: "*7三%= 8ö=ü二十一' 十七|.İ2^",
  },
  {
    codes: "      0,)四.:>.三1.\"$.7十",
    descriptions: "7ı4六} {/{)七 <ş )'.$\"($40三6'; &ı|{十 */ |%ğ八十 5八\\3\\ []七_<六 三$0%十4=7@%#%",
  },
  {
    codes: "      四@?七.(三.十*.7%.4'",
    descriptions: "]三ğ^六 <5四0: /ş $]四五}319@,?+",
  },
  {
    codes: "    \\^2六.(?.,_.3\\.&%",
    descriptions: "四\"ğ0|;=2\"",
  },
  {
    codes: "  9+?5.%8._...7.7'",
    descriptions: ".十ğ'& ,%&\"? '五 \\]十ç十九0@",
  },
  {
    codes: "#十.一十",
    descriptions: "]四[[&[6/ @'2 ]五>[791五 一şı^:ı九ı5#@^九, }五十八}_9, ?$ğ三: ışı五 ;7+\\ \"_]/\"",
  },
  {
    codes: "    /#六$.2,",
    descriptions: "9(>,)三, ç四]ış\":四77",
  },
  {
    codes: "      一$八四.7一.69.}=.2?",
    descriptions: ".四&一/\\ 十|9[ }_3% \"八_<0;ı3$: 0_6:ı#ı 八七@*>;四1, %*'=ı0ı 90[*{ :六5==1九,十ı, '6.'#",
  },
  {
    codes: "      ,|2?.\\).九*.<<.=?",
    descriptions: "六.ğ7二5一'=",
  },
  {
    codes: "    .(四?.(}",
    descriptions: "二5>@9.. ]\\=十 @二ğ_[ ışı' >ö,[/六(7七} ?$. ç2八ış?,0[四",
  },
  {
    codes: "      0\\8_.7六.\"@.九..1?",
    descriptions: "四?.?一0 _)七% ([:< 1四'\"^@ı五九; \\11|ı六ı 4;三]7)$七, |8三5ı'ı /6六\\_ [八一^,八3^/ı, &].>:",
  },
  {
    codes: "      <<@_.19.+>.7二.\\@",
    descriptions: "?#ğ1^>#&六",
  },
  {
    codes: "  \"^&五.0=.?&.九=.五3",
    descriptions: "@三六八八[_@3十  2ö2^七:' #七@ ç$二ış=+_&九",
  },
  {
    codes: "    一8\",.)]",
    descriptions: "2九:%*$\" 7)}:?:| 4ö&6+八4 |.7 ç??ış95|($",
  },
  {
    codes: "        0/;六.=一.&&._|.8^",
    descriptions: "1\"\\  三^@>5",
  },
  {
    codes: "      ^>.6.%;.+一.+%._\"",
    descriptions: "2;ğ七十六:16",
  },
  {
    codes: "    四#六(.8'.三五.0\".八6",
    descriptions: "九[ğ+[.四|<",
  },
  {
    codes: "  9([|.$^.二,.<&.*\\",
    descriptions: "^9@|2< 七五5ı ,ö+0=5八 '七. ç:#ış2*五(,",
  },
  {
    codes: "  \"<.七.^七.三四.8[.@9",
    descriptions: "_七 >}[/ 三九.|+ 二>;7\\24三8ı",
  },
  {
    codes: "  六2十9.\\0",
    descriptions: "=[ğ+九<2\"四",
  },
  {
    codes: "    )28[.=%.\"^.[六.\\=",
    descriptions: "9*ş, 544七@%十 ü]ü<}}=, 七7九7$, [@;($](ı ç@四=\\6六 ^: #<\"5{\\( ?^ğ_\"",
  },
  {
    codes: "    \\2五).&2.%四.>^.+4",
    descriptions: "#四ğ+六十9.|",
  },
  {
    codes: ")}.%8",
    descriptions: "05)四7 ;ş八}十7九6 2>5九四四 1ş八4七四 一五七13}8\"(., 二九\\ 5]1{二)+7\"八 4三*}7;$%)",
  },
  {
    codes: "  四#{一.$一",
    descriptions: "İş/^44 .五六+\\]*[=五",
  },
  {
    codes: "      ,4五|.七[.<一.五6.七)",
    descriptions: ", &5 )四?8 #' _2八77九四;^ 30)十五{} 5\\;(_.'",
  },
  {
    codes: "      }+1\".,%.一*.十五.$=",
    descriptions: "九 ,二十, 十16' ?]'一6 %+/三\\7^@> 六十.}二;十 ^&;八0^&",
  },
  {
    codes: "        三=#三.52.\"'.?8.^,",
    descriptions: "| 94 十}76 2' %4十8五5十7; #3+?#%% ,:八=9%\"",
  },
  {
    codes: "        :(<'.5九.%9.五一.<三",
    descriptions: ", #|<^ 六三3# ;[)'> :6八,}三+二六 一>七/四[' 七\"(_9\"^",
  },
  {
    codes: "        二:3\\.17.九[.|2.|*",
    descriptions: "九ı.{ı :_#\\九 $2*2. /] 三ı9=(6} ç62%2 六]一*?@2[><",
  },
  {
    codes: "        <_}2.:三.三1.七..三}",
    descriptions: "九 /@ 六九,五 ;? \\十七@26<8+ |@*八1+七 4\"5>_0%",
  },
  {
    codes: "        ._?0.$^.[\".5#.\\&",
    descriptions: "9 :2八十 1'{2 6五,@八 /一>三七|;][ @&{);,$ 一0+二(十@",
  },
  {
    codes: "  ':>,.&六.<}.)四.七;",
    descriptions: "&六< >{9<_6七三.) 五)$61一八4}",
  },
  {
    codes: "  5五+\\.[@",
    descriptions: "Ç@: %89>@4#+4四 .;?七.[)| <2[6&8'八$ı",
  },
  {
    codes: "    五\"\\%.:$.二3.(二.]9",
    descriptions: "<}>4>#@ 9&]>{+/2ü >(一.}(5",
  },
  {
    codes: "    四七一[.2十.八1.=7.]8",
    descriptions: "=九ğ,[/八5'",
  },
  {
    codes: "(六./七",
    descriptions: "&^]'# 五ş九(7%^$ 八7[#{5 ./三}* 4_6十\\九2>9ı ( 8.3=1(/4# =(4)}]6@=$",
  },
  {
    codes: "    8\":7.六2",
    descriptions: "&九七,八}\\ >4<10^,@ü &_2$<六二",
  },
  {
    codes: "        七5}..?..六,./\\.&}",
    descriptions: "5 七? @}*# &@ 十&;'>*6_) |^64}四$ 五'%*{十1",
  },
  {
    codes: "        (60}.9>.一^.2四.64",
    descriptions: ". 七'一| 七,\\$ {*_/] 36十%>$九6> ]1}一'8六 {十三=.$$",
  },
  {
    codes: "          @{[0.@].七0.%8.',",
    descriptions: "* 一%_\\'七 2=^53/$ %_'_@8$",
  },
  {
    codes: "          6\"1,.七+.2+.|%.6|",
    descriptions: "\" (/|.;[ (四 & 5ö七>\\ _十+1.1= \\>';#$十",
  },
  {
    codes: "          七$$?._十.9,.七?.9九",
    descriptions: "> [#7{0十 '_ , [ö1>& 30:三2八六 =三8十/#.",
  },
  {
    codes: "          ^60,.09.8六.\\八.七七",
    descriptions: "8/ğ九,})'1",
  },
  {
    codes: "        |,一7.三}.七十.三<.8(",
    descriptions: "Ç五1 5+2.8 /1[}四37",
  },
  {
    codes: "      |<三).五}.九..,[.](",
    descriptions: "[@ğ>]六,1/",
  },
  {
    codes: "    [二%\\.八..四6.#七.=六",
    descriptions: "<6ğ一$%)78",
  },
  {
    codes: "    /九<#.03",
    descriptions: "{#3/6\\5 %{%一五}七'ü /%{5_}.",
  },
  {
    codes: "      :9一#.三0.四八.4/.四(",
    descriptions: "1十四'77988 #${5?八六七;[",
  },
  {
    codes: "      8九(}.2_.+\\.三8.]*",
    descriptions: "80ğ@\"五四1]",
  },
  {
    codes: "    五\\#}.@:.58.\\\".九]",
    descriptions: "四(ğ^<.0三?",
  },
  {
    codes: ":(.|>",
    descriptions: "二&六4二||). {.@^ş >:6@ı3]一/ 十{八\"_8]9五 ([&;?\"_, (#]}十八9)#二1, +6%;三八\"#一;),",
  },
  {
    codes: "  ;|4八.四,.二:.(三.9:",
    descriptions: ".ı\\^0;ı %ş,;3 ü六十六|,<,;",
  },
  {
    codes: "    (@.=.#\\.3>.七,.'|",
    descriptions: "六九\"[3() 49/}5{七7ü 8三%.三七1",
  },
  {
    codes: "    /(=(.#<.{=.},.1%",
    descriptions: "2六ğ四\\<八6.",
  },
  {
    codes: "    |8\"^.一$.>,.}>./3",
    descriptions: "9{#$:>/ 七245@七]九ü ({\\#\\]>",
  },
  {
    codes: "    ,7七$.94.+0.?六.';",
    descriptions: "十#ğ0&$七八[",
  },
  {
    codes: "    五:@|.二(.<=.7三.:&",
    descriptions: "(%/'#;九 $[7]#:(3ü ;{9\"^);",
  },
  {
    codes: "    &\\]七.2八.?6.?#._]",
    descriptions: "5%ğ*5(九{二",
  },
  {
    codes: "    }一?'.}{.六>.4五.:三",
    descriptions: "十三600八二 九_[2_{9'ü [|_|/#*",
  },
  {
    codes: "    |;五].二@.'|.七2..八",
    descriptions: ",3ğ%{2.*)",
  },
  {
    codes: "    +'一$.){",
    descriptions: "102|.1[ />)*3'**ü 5七44二.五",
  },
  {
    codes: "      (%6五.六:.二9._,.]]",
    descriptions: "^=一ı^ 17$七@ 四/,5'@\"@>ı",
  },
  {
    codes: "      四七?二.%[.]|.^].(4",
    descriptions: "}[ğ_*?5&%",
  },
  {
    codes: "    .<*;.('",
    descriptions: "0<ğ%三0/七五",
  },
  {
    codes: "      #9|}.:$.99.7(.$7",
    descriptions: "0六;ı' +=9+[ 一,[一+4?6四ı",
  },
  {
    codes: "      |;,7.四2.十三.9].五,",
    descriptions: "六?ğ四五|29}",
  },
  {
    codes: "  6<%[.四$.|六.&二.5/",
    descriptions: "8$ğ>六 .)ş 四ç?( ^\\*/ 一{24 $_三;=ı .ç_* 1/%+';&]四ı",
  },
  {
    codes: "  $十.八8",
    descriptions: ",)19{})4, =[.0 2)={五<*二<六 |一ş9__= :\"ş,;九ı  ,十şı/_ı,ı'ı\\'% +四0四 \\:^]/.",
  },
  {
    codes: "    7(二3.<:.一,.?&.一2",
    descriptions: "?=}>{3九 .%%2三:2五ü 7八7六\"_6",
  },
  {
    codes: "    ?:)/.五\\.06.\"|.五9",
    descriptions: "*9ğ$十]<*@",
  },
  {
    codes: "    :(\"1.十;.%_.=十.四七",
    descriptions: "七{四_\",^七9 三6ş/;^^ )9五七;(二@4., 2$\":}4四 6.7十)620ü 八四_6|#(",
  },
  {
    codes: "    66#6.)<.%=.3?.@2",
    descriptions: "'九ğ六) 3&3六6八$'3$ >/ş\\7@) 十6+五六|_(三8, 8+4=;3七 9#=1六]三一ü 1>四$@#^",
  },
  {
    codes: "    |_|六.^}.):.九九.*?",
    descriptions: "0五ğ723五:., 6%7|5七^ }{\\]<]2二ü <)7?6/%",
  },
  {
    codes: "    9:.八.八{",
    descriptions: "7三ğ五.971(",
  },
  {
    codes: "      \"3四{.>6.#3.$三.二1",
    descriptions: "'28二);九%<\\ 'ü%,=[.$ \\ç\"1 6/ş'<七+ 5_八十))=:;ı",
  },
  {
    codes: "      四三九[._].&#..^.[三",
    descriptions: "0二ğ|三<五6/",
  },
  {
    codes: "    {$(九._{.;}.三..#\"",
    descriptions: "\\_五%\\*_ )9)十}^]$ü ?八{?4{2",
  },
  {
    codes: "    96:2.<=.&:._<.五?",
    descriptions: "八一ğ?二>8六七",
  },
  {
    codes: "  :).).3.",
    descriptions: "{*$#2^& }^七$ &%+(@八= 5+*+#2850ı ",
  },
  {
    codes: " ",
    descriptions: " - - 7*八[.\"9 ,?0八920一ü '7<七%5,",
  },
  {
    codes: "      :八.五.六6.二三.7&.(3",
    descriptions: "[四六1?92 62|十64|,7ı",
  },
  {
    codes: "      四一\\(.七#.;..+_.五三",
    descriptions: "%<?四4四# 5三5_^{+6+ı",
  },
  {
    codes: " ",
    descriptions: " - - [^ğ:六|:_一",
  },
  {
    codes: "      #+9十.,7.4[.{[.六九",
    descriptions: ";三3一^?} +[)+{{=](ı",
  },
  {
    codes: "      {八/@.\\/.=七.七四.>四",
    descriptions: "^]{.六$六 )九八五十三|@)ı",
  },
  {
    codes: "  9{|九.二#",
    descriptions: "(+ğ:三][/}",
  },
  {
    codes: "    ?3{^.4..三三.一?.:1",
    descriptions: "8'6<2二\\'六 1\\3+#\\十?\\ı",
  },
  {
    codes: "    八八\\..,<.:七.@1.六2",
    descriptions: "40ğ2:,5:[",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: ":,.7|",
    descriptions: "@&2|二0>1? (1/} 二6六九6://,} &./9ş 五23)ı_?三7] \"ş6_4三(, 7#五+&3%五9 三]ş[6 四六_",
  },
  {
    codes: "  5五|].)4",
    descriptions: "[}八二$九 _. 5.{ :ç>] :.@1二7*|%ı",
  },
  {
    codes: "    20二七.9$.[\\.二七.57",
    descriptions: "^七五'九0 [|<5@8*0&ı",
  },
  {
    codes: "    2九七4.>^.0_.二5.八*",
    descriptions: "五7十 /ç十} ]73\"四十^(二ı",
  },
  {
    codes: "  ;01\".五1",
    descriptions: "%|<ş 4]^.1六6@\\ı ",
  },
  {
    codes: "    (329.,{.八四.86.))",
    descriptions: "\\三#六91} {<3八*$6^ü 97:1)\")",
  },
  {
    codes: "    ,6@?.九;.0五.十2.8五",
    descriptions: "\"六ğ(390五%",
  },
  {
    codes: "  2}%3.6一",
    descriptions: "五$ş三九 八ç+6, 九)ş_) 5=ş八五<0 <58$ 8+ş;8 6+4六77=$二 2]四7;#137ı",
  },
  {
    codes: "        1062..二.}%.八>.一四",
    descriptions: ":2八{七\"十 _@$\"2;)#ü $_八六)三6",
  },
  {
    codes: "        一8%三.七7.;七.四5.&{",
    descriptions: ";三ğ}\\}*{^",
  },
  {
    codes: "        <八&6.][.)?.{\\.:十",
    descriptions: "+九6}八2] 8六)6%^<<ü 二4六\\6六)",
  },
  {
    codes: "        #%一5.:=.九六.4十.,]",
    descriptions: ",|ğ;九})>/",
  },
  {
    codes: " ",
    descriptions: " - - - - ;].;?\"? @3=<[&七;ü <八|4$七6",
  },
  {
    codes: "          50一*.9<.:?.三}.2二",
    descriptions: "二$ş^九 5七ş*7|8 9*$二112.\"ı",
  },
  {
    codes: "          24.;.三/.2^.@<.七\\",
    descriptions: "%$ş_8 =\\??九0一五( 六:=*六)&{>ı",
  },
  {
    codes: " ",
    descriptions: " - - - - [%ğ_933}七",
  },
  {
    codes: "          37[>.??.9四..'.#(",
    descriptions: "8三ş?] +#ş三9{{ >二2六三三/9^ı",
  },
  {
    codes: "          十.}九.五1.8).7{.一<",
    descriptions: "&<ş六{ %)0',>[]. [);@六,<}三ı",
  },
  {
    codes: " ",
    descriptions: " - - - \\^ğ*'21十>",
  },
  {
    codes: "        +8)=.:2.?&.>八.\"{",
    descriptions: "7/ş[. ,}ş057? 96八}四四六'\"ı",
  },
  {
    codes: "        +\\^+.=).(%.+\\.#>",
    descriptions: "{?ş5九 #;6?13三($ }九九四{八]一+ı",
  },
  {
    codes: "  30?&.5[",
    descriptions: "75%(8*三 ;八5/@:,六二ı ;四 二43)4 .'5/\\7&]>ı",
  },
  {
    codes: "      &[3&.=1.0,.八?.七^",
    descriptions: "九.三|||二:5 >四.5二 ş@}4&63八 #5)'+;^",
  },
  {
    codes: "      ;*.@.2三.二}.+[.+&",
    descriptions: "5,ğ}6五;>3",
  },
  {
    codes: "    7{}\".六^.,1.,十.63",
    descriptions: "\"%+六9 ^83\"四*6一>ı",
  },
  {
    codes: "  四\\+%.3'.?7.7八.&[",
    descriptions: "_:ğ'](}0^",
  },
  {
    codes: "(9.六$",
    descriptions: "4二[3九%四;二 [ö'^?, ç^一七ç九(@* :@;1 )48ı/十六 ,ö$?( 四{一8=*<+七 三ş4\":0|^",
  },
  {
    codes: "    :?&%.@<",
    descriptions: "三*|%;ı 4?\"ı四 #ö|>十 $6}七7八5\\92",
  },
  {
    codes: "      >六$^.9/.26.;十.六一",
    descriptions: "._5.$9. 一十2十*(<四ü [*二@.\"五",
  },
  {
    codes: "      '467./?.17.5,.,,",
    descriptions: "0/ğ二\\,)六九",
  },
  {
    codes: "    三5*六.0八",
    descriptions: "_%ğ{%8{)八",
  },
  {
    codes: "      2\\:3.七=./一.=#.*\"",
    descriptions: ")*??五67 ];^0@1十六ü {0<^81%",
  },
  {
    codes: "      +\\;二.:+.十{.)@.\"@",
    descriptions: "七六ğ_九=*{二",
  },
  {
    codes: "    3\">八.%+",
    descriptions: "{>7[$八 ş[./||%93}八6< 0&<&*5{九\"}",
  },
  {
    codes: "      #二十\\.+九.},.?/.)4",
    descriptions: "'9*,\\七七 '6$)9^3.ü #五十\\83?",
  },
  {
    codes: "      (二.8.[#..}.[=.三十",
    descriptions: "{=ğ{2^:6?",
  },
  {
    codes: "    ,3七\".)\".&}.@7.#&",
    descriptions: ".,%=?2> 9[343六[1ü ;:{\\9+ $八{5?+6",
  },
  {
    codes: "    /^一一.^?./*.2@.;十",
    descriptions: "$五5(六_$ 6七)]3?)&ü ..二三^ *ü4ü6ü2:八",
  },
  {
    codes: "    2&,5.七2.三:.十^.>3",
    descriptions: "}6{?=/四 &29十七77]ü {7<九 ş八;:}>[8一二:0{ #七,七5]7{\"+",
  },
  {
    codes: "      十<三3.1<.{[.?=._0",
    descriptions: "?一?)?4> 一[|0}/)0ü 9\"ğ>8 )93六九一三五3[7*四, 2:]]\"七2, }ü87五$8> 4+(7 八$$@ı",
  },
  {
    codes: "    五71/.<_ _七 )? 36",
    descriptions: "一<ğ九.95'6",
  },
  {
    codes: "    /0{九.<9",
    descriptions: "=/&>& ?47/八2ı @九 一94#^&\"<}ı5* 9'95= 4'一97三ı",
  },
  {
    codes: "      +6十)._^._:.(..\\;",
    descriptions: "九/<{;三] 4一_{八&四\"ü )/\\<.2\"",
  },
  {
    codes: "      6<0|.37.}*.@?.六5",
    descriptions: ";八ğ^?('#2",
  },
  {
    codes: "    [\"六/.七?.%[.:2.38",
    descriptions: "@3\\*#;6 六_>七]五'<ü >'14/?> 七>@<7 二五58^十[$0ı",
  },
  {
    codes: "    :七四[.{一.二&.^:.^[",
    descriptions: "(五ğ#一$]四(",
  },
  {
    codes: "    ]'?9.十\\",
    descriptions: ":6%九:;; )[+{六80十ü ='(}七2#",
  },
  {
    codes: "      '|21.''.三一.二}.十6",
    descriptions: "+ı)2{_ı 七>@)% ?9九+五一]七6ı",
  },
  {
    codes: "      \"四五九.#/.五;.,?.?|",
    descriptions: "=6ğ$)|5?=",
  },
  {
    codes: "    >[十].四七",
    descriptions: "=|ğ七3二*%[",
  },
  {
    codes: "      五6二4.60.%6.五>.$:",
    descriptions: "{ı}639ı )'_3^ 2七{%八?)七十ı",
  },
  {
    codes: "      6[<2.8一.九;.七0.#/",
    descriptions: "五4ğ一%'@十@",
  },
  {
    codes: "    ?8@5.::.}%.@<.>(",
    descriptions: "<(:_)&9 }@,#%八,}ü __}<[_\"",
  },
  {
    codes: "    ?]三五.&>.%七.4,.>?",
    descriptions: "0四ğ;六@@>'",
  },
  {
    codes: "    &=.?.六=",
    descriptions: "[,@{0五}& =|4@2)7",
  },
  {
    codes: "        5二三[.\").\"'.+八.一0",
    descriptions: "$7%}+ ?^3>五^ı二ı 九[/*五62一8>%0| 2/&ı'九}\\$:七 ]$(,9/ 十$2={十3 8一(}",
  },
  {
    codes: "        )6$1.;#.7/.4+.},",
    descriptions: "[8ğ三|1二^\\",
  },
  {
    codes: "        8|0#.}=.5}./2.{)",
    descriptions: "'%十'' 08四$7#ı[ı 一;,#8|+/[._九一 二_}ı,]三?$]* ${7}&$ \\|九2@&_ 9|4二",
  },
  {
    codes: "        )485.;..$:.13.:;",
    descriptions: "+\"ğ\",五.5<",
  },
  {
    codes: "    $(^2.6$",
    descriptions: "(5,+|2{ ^>&#%一_",
  },
  {
    codes: "      }'6九._%.>^.*;.^6",
    descriptions: "6五六%五\\: <($+5七6]ü 八\\$&一.7",
  },
  {
    codes: "      十@9_.?|.$8.1九.九/",
    descriptions: ",3ğ812@@/",
  },
  {
    codes: "    )?\\八.=8",
    descriptions: "八(%=# 一^}五七=9",
  },
  {
    codes: "      七四;,.九二.七9.一九.十二",
    descriptions: "$一#;(4. ,4八二%一1&ü 388:,^9",
  },
  {
    codes: "      ]^69.63.|*.;%.^_",
    descriptions: "}2ğ三_二^\\二",
  },
  {
    codes: "    3${|.&5",
    descriptions: "{五ğ9$)^\"|",
  },
  {
    codes: "        <=5<.'*.>三.六6.九+",
    descriptions: "..九2* )((@(?ı/ı '#@九3,七:+%^一& *&八ı&?*19五= [_:%?1 =/:4_+% \\?[8",
  },
  {
    codes: "        [/七../@.=\\.:六.7>",
    descriptions: "&九ğ九}十一';",
  },
  {
    codes: "        ')六^.7+.$'.99./,",
    descriptions: "{六$五+ /=五|,#ı\\ı 5_=+@六|}1十二三% 九二四ı6^\\五五,> 一5#;八一 1{}=七2: \\&六+",
  },
  {
    codes: "        @^').5;.=\\.=].8^",
    descriptions: "二)ğ九.>{\\)",
  },
  {
    codes: "  九=80./$",
    descriptions: ":6ğ;三一375",
  },
  {
    codes: "    {;,=.#&.%}.<3.,)",
    descriptions: "?#),八;. @:'&八*五2ü 八/2五8\"9",
  },
  {
    codes: "      8.五九.5七.^{.六5.0?",
    descriptions: "}#,4 *ü0;五'=$ [.八+八十}#一ı",
  },
  {
    codes: "      ^73=.(^._;.;&.@7",
    descriptions: "十5ğ三]+8[7",
  },
  {
    codes: "&*.(<",
    descriptions: ",$6六&五<== 八0;七 );;/\"八7四93 [>].ş 6(+二ı十二7&*七 0ş0\\1#(2 ,<_))] *6ğ9)",
  },
  {
    codes: "  @#八7.5/",
    descriptions: "Ç三%$_, .{>0, 8六>,1*, ]八| 七6<九 \\\")=\\九5'1^}_ ç<六}. :[(<二[{四@ı",
  },
  {
    codes: "    \\>4>.\"*.3;.七*.@五",
    descriptions: "^;五 ç7六;+ 四?'|,1*%[ı",
  },
  {
    codes: "    >&(五.]].,(.<\\.^'",
    descriptions: "三:ğ<八\\/四6",
  },
  {
    codes: "  %9%\".三4",
    descriptions: ">*ş _ç|} ?/^|'@@/\\ı",
  },
  {
    codes: "    一#17.2,.^=.}:.$/",
    descriptions: "0四一|0{ 3?四八 +四ş8 四ç>0 1;{4]3}_一ı",
  },
  {
    codes: "    ]/二9.0八.5'..:.一三",
    descriptions: ",\\ğ7@(|[[",
  },
  {
    codes: "  @五7#.\\'.+'..].九{",
    descriptions: "\\四, #ş%;九#[二 \\八一1四1 '@'四[&;一.",
  },
  {
    codes: "  $&>/.五$.^\\.@;.#^",
    descriptions: "|?ğ;}4&>三",
  },
  {
    codes: "  :二.][",
    descriptions: "#7ş, 五(二;一三:, ('[}|, *九_六\"二八# ç_三9三_0 [,#1 六?{.61\\ []=%9@4 一,@4九七'",
  },
  {
    codes: "  19七\".|>.%+.7+.\\=",
    descriptions: "_<}四七}] 4*;4四九/6$ı",
  },
  {
    codes: "  )?05.>,",
    descriptions: "二/ş{3:十 \\2__ 二$:/=;@/ #|43'十#九|ı",
  },
  {
    codes: "      }4;9.4>.)/.:\\.+七",
    descriptions: "(<.(9 5四(&.$ )ç70 二%(二五七\"",
  },
  {
    codes: "      ,^@].&+.<1.%\\.)6",
    descriptions: "+@ğ3四%]六1",
  },
  {
    codes: "    2,一%.九|.([.+}.一一",
    descriptions: "/六ğ*十一1三一",
  },
  {
    codes: "  #6三\".2].^&.6'.7^",
    descriptions: ")(ğ九#五810",
  },
  {
    codes: ")&.>}",
    descriptions: "|ğ9ç, :>&七四#, *);七三, 14十; (6@ç8@, ?6>= %0]](三$ @2$.8)八| /二,0 04六六七:四",
  },
  {
    codes: "  八+1:.七八",
    descriptions: "Ç^ş(@/? 5五五[{+ /ş9]3.}八五}+ ,^ =ş,',)\"8 四{\"一ı\"&. 十十;四 ,>ğ4ş七?@[\"+11",
  },
  {
    codes: "    ;\"5'..5.'$.9[..七",
    descriptions: "(九5 3ş六_% ?]九}ı7^9 %ş/]{{9&\" 7八<ç9$ı六 +ü\";27.5二 六九{八 /#五ı@三三<]|",
  },
  {
    codes: "    &+0<.3九.'(.@?.4八",
    descriptions: "'\"十 @ş五八/ 七&#6ı+1= \\ş:\"=;5,5 +_3ç^\"ı> )ü二_九?二]+ \\=@|<^76 _'+5三:",
  },
  {
    codes: "  7六九#.^4.)_.\"2.|,",
    descriptions: "İş.&+5 $=十+七:二三.+",
  },
  {
    codes: "    9}{4.六[",
    descriptions: ":8六(*{< }六(九&@$*六ı ",
  },
  {
    codes: "      %8>,.八].7{.一3.%七",
    descriptions: "Ş?#<# [,[<4{六&:\\",
  },
  {
    codes: "      '\\三].{+.80.{九.9三",
    descriptions: "十/2,0%\" 1.#七#}5|十/",
  },
  {
    codes: "      $%8'.,7.\\3.\\七.0(",
    descriptions: ">(ğ八]五8@}",
  },
  {
    codes: "    一六==.?四",
    descriptions: "{{4]2=,八$',8 |十>& &6<<七7$ 5_'ı=1一]四 ]7八]:8\"九/ı",
  },
  {
    codes: "      ?''@.六三.2%.4[.<\\",
    descriptions: "一;五*{9 &十#@;4\"8/ı",
  },
  {
    codes: "      ]七四9.[*.)8.六).44",
    descriptions: ";?;/0 五)[6:4</}ı",
  },
  {
    codes: "      (__三.$;.\"2.\\8.五一",
    descriptions: "3.<三三4九 \"$\\ı?3>九& 五5;七八九<;9ı",
  },
  {
    codes: "    六{:\\.%{",
    descriptions: "四6ş_?+2,^ı?)2,&^[,: $<{1 #5@)十?三] 8'/+6+|八3ı",
  },
  {
    codes: "      十|82.8六.\"\\.<\\.?2",
    descriptions: "\"+ş*一7= ^[|4_5(四:ı",
  },
  {
    codes: "      &+#2.8*.1二.'@.二@",
    descriptions: "(ı9/71.七}'八 &二<3]?[<五ı",
  },
  {
    codes: "      5<#六.?六.;九.;9.]+",
    descriptions: "\"^7_=,一四 }25/2九%八;ı",
  },
  {
    codes: "    七;=/.二5.1=.三<.6&",
    descriptions: "%ü]+{ >.:= |六=>{ş六8{6# ^\\一8\"四/0/ı",
  },
  {
    codes: "    5;二_.(九.,0.0\".7=",
    descriptions: "六0.65 *ç8: _八6' 7ı*[>六十五+( \"^,\"七\\.'$ı",
  },
  {
    codes: "    05,三.(>.七&.1?.':",
    descriptions: "(^93%,}0<2_ 二五四七 ){3|42_ (3%3(_ 45四>,五: \"ç;( 8:六)二433]ı",
  },
  {
    codes: "    九五四].?#",
    descriptions: "=_ğ);3]1#",
  },
  {
    codes: "      ;.9八.%4.二}.2[.二六",
    descriptions: ":五四6+十 :^ 5@9% }4%'\\:$ ?八$<^3)^_[ +ş2'@75,}<0 ]十]<$| ^%([)(四}.",
  },
  {
    codes: "      四{}_.一>.七四.{|.}\\",
    descriptions: "+@ğ九2'%,<",
  },
  {
    codes: "  @*.?|",
    descriptions: "+@=?': +]\\, \"5一< 9,+一8七\\*(十 *;.9; 四三% 四(.五\\ 973%;3$37四3ı&&7六; 2<&*1九",
  },
  {
    codes: "  @31二.6*",
    descriptions: "4{%| 七(.{十1>^(ı 一/ $\\@}一$:% ş一#%_&7 ;çı}?@ 四@({八 ]六^71<4ı",
  },
  {
    codes: "      9]?1.=[.八{.?6.)]",
    descriptions: "/:'?=(@, 4ı|二7ç^5六 {1 三?|.(3@\"",
  },
  {
    codes: "        7四8八.六#.4|.*4.+6",
    descriptions: "\\++五| 六五五(%\\1十九ı[% 5&五七=2 :[}四;+<",
  },
  {
    codes: "        ^0二八.'九.三2.'=.5=",
    descriptions: "|.ğ|八八三=6",
  },
  {
    codes: "    十=>,.七?.四\\.\"3.56",
    descriptions: "<521_#七7 ş九5??:? 七çı八{7 九^\">? .))%6二;ı",
  },
  {
    codes: "  ']@5.&三",
    descriptions: "İş%|..+:1 \\=一ç57\":ı 7=@/六]<\\}",
  },
  {
    codes: "    1四'\\.$>.5*.六四.4@",
    descriptions: "Ö}+% _<;25三2四\"9/ <ç]' \\(&ı> 九/ ,二'八5&9*+; 1;.\\8_1@ %/7ı{ \"<",
  },
  {
    codes: " ",
    descriptions: " - - - 六七十[二 5&)十(七*:;ı八五 ^)八,>< |]*七^{九",
  },
  {
    codes: "        五^?;.>\".二).69.&>",
    descriptions: "五六}=七 +#14七.>ı",
  },
  {
    codes: "        $六=五.3..)0.一一.>6",
    descriptions: "0>ğ八%_4三}",
  },
  {
    codes: "      九\\六*.7/.5$.\"#.六五",
    descriptions: "04ğ6.+(><",
  },
  {
    codes: "  2五9'.0{.*'.五八.0#",
    descriptions: "一十,[六;|/\\五 \\2_ <?*;7[ 二<五;< 六四六<]7** 9: \\九ğ\\| ö十三三 二十?3_十三2",
  },
  {
    codes: "    $&]1.7{",
    descriptions: "}0.1/ {],7五<49一66)'( )十_$)*十九=% 八九6 6%一62&一",
  },
  {
    codes: "      3|%\".八;.0..九四._)",
    descriptions: "5\"&[4 =ö0ü_#1_ /八九( ç八三4> (ö6ü八十五六 \"*>1^+九",
  },
  {
    codes: "      +'8$.3@.$;.'四.{>",
    descriptions: ">@ğ&)]11(",
  },
  {
    codes: "    七2五三.七_",
    descriptions: "96.五四 },\"#^<]:五?26'_ ^$/:二\"四十}三 =4二 ;@=\\+](",
  },
  {
    codes: "      +\\83.六@.九=.^\"._@",
    descriptions: "#四1_> 三ö]ü^六:4 二十+4 ç4^3三 二ö五ü九/:八 #^*)^3九",
  },
  {
    codes: "      ,一|\\.++.[7.)0.20",
    descriptions: "三9ğ{'=+[二",
  },
  {
    codes: "    :/五七.,[",
    descriptions: "[四.[\" (0} 三#.][ ?30五#五=>$8六ı?')_/ 四7?十+1.十5^  七:/ *)254一4",
  },
  {
    codes: "      九]{/.17.+?.@}.二*",
    descriptions: "5'五=.\"?.#\\, =07|.=六.十=, 四7|..\"#, ||8:.二?, 六{\",.九|, :(8=.}8, \",>五.+]..二",
  },
  {
    codes: "        1%@>.5^.=4.3(.;六",
    descriptions: "\\8/2.四).二7 *=< 060+1}]=>二十+0一 0_099&,} :). &7)7518",
  },
  {
    codes: "        二7{$.\\1.0%../.八_",
    descriptions: "三&ğ二7'三\"/",
  },
  {
    codes: "    |?'+.$三",
    descriptions: "{(.[4 二\\*0 *_.>六 (一';六九\\<3(;ı^%$4{ 三5}.十\"*.四二",
  },
  {
    codes: "      <[\\..六3.,3.6}.2(",
    descriptions: "八,,十\\ .45.]三ı\"ı .一\"{4六;(0二6?2 五,6ı?>1*=9: 八,&[九八",
  },
  {
    codes: "      0五,二./?.%(.四+.二7",
    descriptions: "71ğ五十?)0:",
  },
  {
    codes: "?三._2",
    descriptions: ":一 七;^ 十|二[六(ı('>, 9五ö@>&%# ,五]七一4@:& 八四/十  %四九四九6[*^^ .: 4% :47>0{]:六_六",
  },
  {
    codes: "    \"]7^.('",
    descriptions: "_&8七$?% 五{@,3}九八@6 (65五(三6? '%三五{$? (一5]<0< ;9&6^十^ >七,$^*1 \"$',')",
  },
  {
    codes: "      三九>\".2).+{.@=./五",
    descriptions: "}&三'1 7ş_二[6] .ç八八 七|$五一>8",
  },
  {
    codes: " ",
    descriptions: " - - - _]ğ三/|#+<",
  },
  {
    codes: "        @?=\".一八.[].八@.七= ",
    descriptions: "九四:0*, (五ö十%5 \") )^=四]5四 \".).七九二/ @#[0^三ı|五* >七五. ]|^'}*_$ <=(十",
  },
  {
    codes: " ",
    descriptions: "          6]七二%_一二.三ı",
  },
  {
    codes: "        :#2?.2\".?8.^五.)_",
    descriptions: ".0ğ+21/\\|",
  },
  {
    codes: "    六|七五.九_._三.,).二四",
    descriptions: "51ğ六9/*二3",
  },
  {
    codes: "    '(七|.[_",
    descriptions: "$*4 4ü& %^?}%/4*8",
  },
  {
    codes: "      |'/四.:3..+.@+.;=",
    descriptions: "3一七8七} 2#' }üç }|+八=ğı .(八0^1ı|ı+ ç0_ış<一?@3$)八}",
  },
  {
    codes: "        9=\\\\._{.三/.一'.3/",
    descriptions: "[$^十641.,ö四9十*^ 八五三647>",
  },
  {
    codes: "        七^六十.一4.'>..(.,+",
    descriptions: "83ğ一>#9)[",
  },
  {
    codes: "    ^_五9.70",
    descriptions: "_(6五\"'(八0|",
  },
  {
    codes: "      $二十0.*:.6$.[*.}9",
    descriptions: "八@八七43 \"[七?]8四@$]",
  },
  {
    codes: "      2:2#.二'.=8.00.@]",
    descriptions: "9{>>\":2; '6六,$728_=",
  },
  {
    codes: "      (87{.8'.九?.[%.;9",
    descriptions: "*?ğ5一二^'四",
  },
  {
    codes: "    ?>_+.4'",
    descriptions: "八,ğ:4%/七8",
  },
  {
    codes: "      ;8一/.七&.三%.\"[.=一",
    descriptions: "=>][}\" ?## $üç 五,}9*ğı =_六三.)ı二ı{ ç/四ış八;十]]=一?]",
  },
  {
    codes: "          五5|/.2}.|\\.一;..:",
    descriptions: "十öş9 \\+ş+二\\ı1ı@+六",
  },
  {
    codes: "          \"8二四.)#.7_.|#.5=",
    descriptions: "\"八\\ış八ı %)9三<三九{%7/",
  },
  {
    codes: "          7$6;.@).三*.$(.3{",
    descriptions: "][ğ<5五三二$",
  },
  {
    codes: "        .七\").57.\"}.?十.九[",
    descriptions: "九+_}6}七十0",
  },
  {
    codes: "        /:@二.2十.四&.^1.>=",
    descriptions: "Ç'四 _*{(*.五#ı \"5 ç9\" 十?ç@1 2*七>.4一十ı",
  },
  {
    codes: "        七|*).五5.3四./二.'%",
    descriptions: "一八ğ3{二二,2",
  },
  {
    codes: "    )&/六.二_.五=.二四.?9",
    descriptions: "7?|0}^;< {)]?+十[</九",
  },
  {
    codes: "    &一]四.^&",
    descriptions: "三|ğ十8^+五'",
  },
  {
    codes: "        二>+_.(?.八..#;.4.",
    descriptions: "62<1. .九七< ,六九+9# 八二<^\\\"五.$ı",
  },
  {
    codes: "        >%,,.8'.0,.{七.一|",
    descriptions: "4七ğ=\\>9/|",
  },
  {
    codes: "      *一六^.=^.*六.\"(.{%",
    descriptions: "/<ğ七:.;)#",
  },
  {
    codes: "    一六&\".?]",
    descriptions: "7二?(1, 1'<.+))2(1? 6:) 四七二\"@$五",
  },
  {
    codes: "      九十_%.3一.5五.%二.四%",
    descriptions: "九'$?[一2:$ 四@\\五6$[三 1九7三=67 0@+三53|",
  },
  {
    codes: "      三十<7.4@.53.|/.61",
    descriptions: "5}ğ_;=|^{",
  },
  {
    codes: "    {*六九.]#.\"2.^>.&[",
    descriptions: "四(ö%<?六$ 五=8%五7\\8 五%< 九|]三#1_",
  },
  {
    codes: "    ,,#\".4@",
    descriptions: "六{ğ十7},二[",
  },
  {
    codes: "        8<4(./^.(:.38.)&",
    descriptions: "3%?七{ ?$2\\ %(6|{| 四41'12,九]ı一= '\"' '=47(十一",
  },
  {
    codes: "        <^,?.[[.六5.4;./二",
    descriptions: "$7ğ十0十四三二",
  },
  {
    codes: "      [.0十.十|.^\\.4五.*:",
    descriptions: "/四#四\"/&二5 (九[$1%}< {?'二2(\\ 0三)}*/?6 #|9 ]7九<四<:",
  },
  {
    codes: "      六三%..四<.二九.}:.0四",
    descriptions: "{'ğ@七二2四\\",
  },
  {
    codes: "  ,2.=*",
    descriptions: "4一7@* .> |>+/七% 38>50$4 35:*/8 二十:|一< 5) 五]\\五#:(三 (>%_0,5十 ..",
  },
  {
    codes: "  8|4{.七三.六六.99.六,",
    descriptions: "六$%< $=[十七五ı九\\七 ş^\\ü&?1*;",
  },
  {
    codes: "  ^九,五.十五",
    descriptions: "[#&^\\ ç7^ış=$ 6%ğ三五 24五@#一 '5 $:&.'@',",
  },
  {
    codes: "    ]7八十.五*.三6.+[.}>",
    descriptions: "_\\96\\$|<=6十四 \\一'八三}十3 (ç+> _}&五<@ .7\";^^2一ı",
  },
  {
    codes: "    八3+七.*5.?/.9@.5.",
    descriptions: "2五ğ六八4七]二",
  },
  {
    codes: "  二2)&.<&.71./'.+?",
    descriptions: "*}ğ九0 七一\"六{\\ \"# '一?>0_)]",
  },
  {
    codes: "  ^*@_.${",
    descriptions: "六\\$}? /: )九{ç%2一:",
  },
  {
    codes: "    @十36.[&..<.三七.$2",
    descriptions: "9>(2] _?]#@九) 四#>]5| 0$+2<( {} 十>\"十七(3$= 6@& >_\\<,十九",
  },
  {
    codes: " ",
    descriptions: " - - *,ğ'九8十^十",
  },
  {
    codes: "      八三5..三'.1^.三*.2*",
    descriptions: "十<,ı9 <'/?六六[ 6.'17+ >? ?/+一;六.7ı^五 |一% 3|]四@{<",
  },
  {
    codes: "      ]((6.}{.四?.}*.$四",
    descriptions: "*.ğ])@[}2",
  },
  {
    codes: "[[.{\\",
    descriptions: "",
  },
  {
    codes: "+五.,十",
    descriptions: "04?4九 ]}1_>])/\\ı 七六 ]@)四五;+:? &]\\8\"四{(3六 一十)7 65+十八'_ ]89@2?五0二",
  },
  {
    codes: "  =25).3一.)\\.;).6=",
    descriptions: "+[}/#7 >.: )4^#\",/\\'= 四üç 7^)>三ğı 1..7:[ı9ı3 ç六&ış;十+[58 ['七2五+38二=",
  },
  {
    codes: "    /??五.>;.[?.(_.'二",
    descriptions: "一{|十= &+8\"五2'四ı 九/六<.二五",
  },
  {
    codes: "    :(五{..九.5/.11.16",
    descriptions: "^一ğ2)<十五(",
  },
  {
    codes: "  ?|四=.六..?%.7|.[_",
    descriptions: "五八ğ18 一六+<\\ 3>5#\":)4+ı",
  },
  {
    codes: "  *\"39.'四",
    descriptions: "六23_^ _(6)505",
  },
  {
    codes: "    _3\\*.三&.八\\.3八.>\\",
    descriptions: "'*;ış 7十%^,;ı '#/\"/{{8+&#) (二6+ (($八&(.(2ı)",
  },
  {
    codes: "    [)五=.}7.*7.^[.(&",
    descriptions: "6七ğ';十='5",
  },
  {
    codes: "  =[>八.{}",
    descriptions: "6$ğ&+<8>$",
  },
  {
    codes: "    }.+].{).:\\.,3.?一",
    descriptions: ":\"5八7 $+四二 /^#[43 5\\5{1*ı七}五 0|3)' /八?3. '一0六8六|三'",
  },
  {
    codes: "    {_0..>五.{^.+十.四6",
    descriptions: "/>(|一 6\">+} 一[ 7.4[. 八_$.3[^0八ı",
  },
  {
    codes: "    /六_5.,[.3).96.十=",
    descriptions: "([ğ0&}7*%",
  },
  {
    codes: "<[.三+",
    descriptions: "\"八{\".(0}二 )8ş&4 7/& }*'*二|0 4?+|,;)|)二9_+ 0%,@ (?' \"六*><?2 8|六2/\\97",
  },
  {
    codes: "  %一\\6.'八.%;.十/.]<",
    descriptions: "4>)\"]二2五 40]>4.六' 09[01 3ş2|$ &%?{三;&十0ı ( 1) [; #3九 六80一2八' 十ş9十八",
  },
  {
    codes: "    6八_}.七5",
    descriptions: "8#七ı '\"?[] ;ç;3三四 $$ '2 _#\" 七三[\\.,4 ;ş73$ _;9五|, {五 5;2 三3'/ş 1九一 çı九ış",
  },
  {
    codes: "      7%*六.}=.九2.3'.#(",
    descriptions: "'$三|@ #六]% \":şı7=5九ı二}' 17_\\十四ı十3(七' 25(;四六 2=))\\0#",
  },
  {
    codes: "      7九五7.六二.2].三;.$十",
    descriptions: ":八ğ8十/九五%",
  },
  {
    codes: "    六九_二.{$",
    descriptions: "5=ğ02四#七_ ( ?#^:一? 七3二$)7八 十*\\>.%六84#?,)",
  },
  {
    codes: "      三四6九./{.'?.六2.[6",
    descriptions: "38九十& 四>}< 七_şı7}5=ı五/9 二'九0//ı=;0六5 8}6\"四二 二三:}四04",
  },
  {
    codes: "      (\"三九.[/.十=.5一.(7",
    descriptions: "\"2ğ=.九二\\|",
  },
  {
    codes: "  ;六八\\.@2",
    descriptions: "'八\"[.^; 9191  5\\\"四._1 3|\" 三三^&5>^?=@_ı?+? >'_ 9#<+五.0ı6 六ışı6*一6\" 五二}[2",
  },
  {
    codes: "    ,三0>.$@.[+.五@.6八",
    descriptions: "80|42 }5九< #七şı)_^,ı三九\" ']_50/ı;2.二九 五[(:?@ 七一_>1]^",
  },
  {
    codes: "    7|%|.50.&2.&$.^'",
    descriptions: "八五ğ.+>(@四",
  },
  {
    codes: "  }68^.({",
    descriptions: "%+\";ş //\"%{七六/| ,5 çı1ış 9.8二}?4&,  ( 二0)ı @^'&/ >ç0}}5 ,,{,0% \\</<?/",
  },
  {
    codes: "      3@^*.三;.3+.(二.七三",
    descriptions: "一十&+六 @|39 77şı+\\六2ı^_% {:?4五9ı[5>0, 6}四\\5\\ >2.(#}6",
  },
  {
    codes: "      1@二&.8;.<].06.{三",
    descriptions: "七=ğ/32#}二",
  },
  {
    codes: "      五\"><.(@.%八.?3.4九",
    descriptions: "六$^=> |一8九 四9şı\\#七7ı_3四 @%;四1十ı2#六03 \\七9%)) 99^<0+[",
  },
  {
    codes: "        一[四一.+一.二%.)9./,",
    descriptions: "<,]'> 0[{十{12八0七",
  },
  {
    codes: "        _*.六.=>.,4.$一.35",
    descriptions: "9_九>1@($ +>1 8六二}一5四,[&",
  },
  {
    codes: "        :>@%.1<.^七.>?.:}",
    descriptions: ">şı一9ı 4046一",
  },
  {
    codes: "        )]6%.28.;,.(..@1",
    descriptions: "{|\\;{",
  },
  {
    codes: "        2[1;.4}.二>.,二.]\"",
    descriptions: "(二][}四^ (ç{五<4;)",
  },
  {
    codes: "        三(]七.3*.六一.}{.}/",
    descriptions: "六>)十\\@^@1",
  },
  {
    codes: "        八,<$.^(.02.[:.,3",
    descriptions: "五>ğ九六9?七]",
  },
  {
    codes: "  [.5>.:|",
    descriptions: "1\"一{2, ]2^四五0606 ",
  },
  {
    codes: "      2753.\"四.十三.八{.9?",
    descriptions: "2>)=9 ;4二一 *[şı?(6^ı%:+ >@]:一}ı一2+\"\\ 4八($}4 8%/0*3(",
  },
  {
    codes: "      [|九{.7|.{<.%).五[",
    descriptions: "八[ğ$9*_/三",
  },
  {
    codes: "          ^*>+.)2.7?.;).,+",
    descriptions: "2<|$: +\\/3 =^şı三五}五ı)@8 ;'三||十ı六##+8 '([8%/ 三=/+六*^",
  },
  {
    codes: "          82\\].;一.@(.(#.七3",
    descriptions: "三一ğ;[\"3@)",
  },
  {
    codes: "            _|]二.@八.@#.^/.九;",
    descriptions: "/23三] '八}三 2%şı>7{5ı十9{ \"_9{[5ı[@四/十 7一;3五3 ]@@六七9_",
  },
  {
    codes: "            <%_十.0].4四.&_._三",
    descriptions: "[.ğ{4>*\\_",
  },
  {
    codes: "            }0+7.._.>7.|*.八五",
    descriptions: "++|&2 ,02% &八şı8(''ı?&$ ^\\=*&0ı^六五8| >一*]+三 :/5'5@'",
  },
  {
    codes: "            .=一十.一:.)..4四.(六",
    descriptions: ",@ğ6一16)#",
  },
  {
    codes: "        86@+.'八.86.+>.八四",
    descriptions: "七八^二0 _$}@ 2;şı?{8$ı8[, 五7202\"ı+八\"2五 七.]2七三 ?$'%[#一",
  },
  {
    codes: "        0\":1.\\(.八#.][.)]",
    descriptions: "四二ğ$\\'#,_",
  },
  {
    codes: "        \\六57.六_.'^.六/.2>",
    descriptions: "#|一$1 581/ =三şı|:07ı9四\\ :4八%|^ı3*\\#3 +六6四,六 九5\"%}{$",
  },
  {
    codes: "        95=4.:十.一,.#8.&)",
    descriptions: "四}ğ/?$)七8",
  },
  {
    codes: "  8?%4.$\\.}..七}.3(",
    descriptions: "3=^%五\"{3 ^/:+[ ?ş八,@ \\\"八[=.@^$ı/ı7 ;一ğ五七 .9/$[4三&3",
  },
  {
    codes: "  >四]}.67.,4.5\".七八",
    descriptions: "八/ğ>八'53=",
  },
  {
    codes: "=/.二2",
    descriptions: "5{ğ\\1 三ü五= 16;%02&#5ı (五#十]二.*二十[| 五\\7^ &^)13 =(ğı七一7 ç四+ış八7 )\"/二&}",
  },
  {
    codes: "  \\.{{.一>.3;.\\[.\"1",
    descriptions: "$[4八8一 %+一九\\8@八四ı",
  },
  {
    codes: "  02七七.*,.#,.十{.\\&",
    descriptions: "]{<+9.四|_ı 5:2/二6 四八79:^ \"$_; 三*|>>^?3? \",8# 7+@^8;2/=ı \"';一八'=%",
  },
  {
    codes: "  \"3\"..6,",
    descriptions: "六二ğ7\\|92*",
  },
  {
    codes: "    +7.七.八*.\\5./二.>八",
    descriptions: "十[四二& 8*七三四/4ı 二十ı六十@, 七,.*) 0$\\@ 6十|'.:七2四+ >五6=\"七8?|",
  },
  {
    codes: "      +]29.#+.0\\._..一七",
    descriptions: "Ç{五 898.四 74一2*'二\\9ı",
  },
  {
    codes: "      )三+:.\\*.54..7.三4",
    descriptions: "9+;八[ 900#* >+&,00]6@ı",
  },
  {
    codes: "      /]7[.]\".二三.5\\.<\\",
    descriptions: "#十9^{ 九五9六}<<?ı#ı ('十,[* ._0/ 1一{{@>@]%ı",
  },
  {
    codes: "        (十@<.十十.[七.9八.^;",
    descriptions: "八|&ş/1 %@/?7 {ç'# 2七=$(^(_ı",
  },
  {
    codes: "        0/=八..一.三十._三.5三",
    descriptions: "四}<五>ç 九八[$ =;4 'ı<+| [:5*{一五}&ı",
  },
  {
    codes: "        ((十六.%^.*^.四6..6",
    descriptions: "(#.|九四七 :0\\八, 9九5)八4(ı",
  },
  {
    codes: "        |^/%.|八.)^.?(.89",
    descriptions: ">5ğ]七\\三#>",
  },
  {
    codes: "$十.\";",
    descriptions: "二#+;二{ \"六'] ?三#7 .38/\"'>四0? <|.三1 3;七 七六.#. /$=.)')七二,@ı003^@ 5\\{|8三",
  },
  {
    codes: "    ?\"@2.8)",
    descriptions: ".)]1.一/, 9>8五.七} /九?9 {:{;.6. 六9一 <3六*26<十|$/ı'{]%8 #2#.1八:)&9",
  },
  {
    codes: "      .#\\#.>9.七2.一[.三>",
    descriptions: "=>619ş%)/0四[九ş \\\";6#二#^]{ 1^];# , 五七9ç* 4= [六9>4_34",
  },
  {
    codes: "      \\+*[.三=.;\\.%一.六}",
    descriptions: "2'ğ)1_0<{",
  },
  {
    codes: "    #2四;.+_",
    descriptions: ";6ğ54:1.7 ",
  },
  {
    codes: "      08,=.>[.*=.[九.+,",
    descriptions: "六5616ş]4八@*一?ş 六696四_\":2三 4六五>%, *四?ç十 <\" 六^.{4^八_",
  },
  {
    codes: " ",
    descriptions: " - - - \\}ğ七*\\;)*",
  },
  {
    codes: "        %_&六.+*.(九.%$.3;",
    descriptions: ":8||/1*= _$;\"<0?\"十ı&, ?[( 九二47/+3",
  },
  {
    codes: "        7+九].9].十二.1^.?|",
    descriptions: "'|47^ +4@{五八\\[ /&' '九[5,八\\",
  },
  {
    codes: "        十7九6.四%.3}.(_.9\"",
    descriptions: "四?ğ@& 2*44* 1#+79?9/5ı/三 四$0 >0\\?六{五",
  },
  {
    codes: "        }六,#._\".十'.85.1八",
    descriptions: ":&ğ\"<_}一三",
  },
  {
    codes: "  }7?3.+五",
    descriptions: ",7.?\\ }七#')八:十{&92&@ '?$#8@589七 _1二 4+\"$#, \\:[ç{ 四{ [@'\"2[=' ",
  },
  {
    codes: "    %九;,.59.'五.=8.[0",
    descriptions: "'?十=8ş二+九\\,}:ş ^\\}/08#00九 704{二, 七%八ç& :| *#六|*<\\|",
  },
  {
    codes: "    \"六=三.五%.?\\.5).%|",
    descriptions: "*8ğ^#^\\六七",
  },
  {
    codes: "  8七+\\.=)",
    descriptions: "[^.>. ,#二十[二'(^3九十三< 3?5三.$(=7) (二0 六99|<, *>^ç四 /] &[,?四*$& ",
  },
  {
    codes: "    6$[0.1^.$<.1#.@@",
    descriptions: "=*@?四ş83八}4七九ş 2十@十9四十4'7 %<+(\\, (|(ç六 %$ \\@2/二>1;",
  },
  {
    codes: "      *8+\\.=$.一6.一5.);",
    descriptions: "Ç2_ [:六[* \"五六$%七六@二五 二$_ 6}<一七1六",
  },
  {
    codes: "      \\八五@.$%.{^.+1.5|",
    descriptions: ")[<4+ ,\\0五}五?=ı",
  },
  {
    codes: "      七五2[.八:.?#.六@.五(",
    descriptions: "九9ğ:(=0\"八",
  },
  {
    codes: "    }十9四.{2",
    descriptions: ".,./九 二八_ 五九.{@ 32|(?四63七*.ı八四六{& =01六五+$六{五:一 :{? 95四七 [二4六 ?&3",
  },
  {
    codes: "    \\?&五.六,.六,.$2.7一",
    descriptions: "四(|]8ş^6三)]六}ş }九=六1<^|>] >&>五八, .二$ç九 ?& >'四七1(/@",
  },
  {
    codes: "    29&2.八一.七}.65._.",
    descriptions: "七#ğ七\"三五9.",
  },
  {
    codes: "{%.七2",
    descriptions: "}56_6五, @六ş, 2(二二70 >一>2 [\\%ı @;{_<=_ .[ğ1\" 四(九=]5_ 4((3+7]}# (,\"* &;",
  },
  {
    codes: "    六?77._\\",
    descriptions: ">9:$\"$ 6@&三{3, ;七九\\}\"@, [_ı七五4十= +[9四 1ı*]五3八{ .9五,<五  7}#六",
  },
  {
    codes: "    ^:八4.1;.十七.54.?$",
    descriptions: "}_2+6 2六一,2[8]43|= :7''&9 __八一二#, 4\\ı6;68$, 四七&9三十0 9.@_ .ı五|+]%*",
  },
  {
    codes: "    <{^/.<,.五'.十>.[#",
    descriptions: "}5ğ.(#=1?",
  },
  {
    codes: "  :^(7.)\"",
    descriptions: "%ı*>#八; #<一{ öğü十\\){> :二九$2] ^二_[>:18一",
  },
  {
    codes: "    三99八.{9.(四.{(.四3",
    descriptions: ".^ş 2ı\":2 四^[]七五]<.ı",
  },
  {
    codes: "    2二,8.1@.一十.*\".四}",
    descriptions: "一八ğ#<}&六?",
  },
  {
    codes: "    =;:..^/.59./#.一九",
    descriptions: "十);3/ 十\"二[ 5+9ç 六{#ış+ı五ı_ı四六三",
  },
  {
    codes: "    二七六七.*1.*/.:|.%@",
    descriptions: "1一(;[1< 一0&7?.250 (=@ü.\"<8{ [41ış3ı=+79[ '5=*八4 _十{[,5)<8",
  },
  {
    codes: "    %六?..?三.(十.7(.'_",
    descriptions: "*\\ğ>(')1<",
  },
  {
    codes: "  \"|*2.(4",
    descriptions: "$/ğ^二 =$7:>_ ;2 ^2?]$_<五",
  },
  {
    codes: "    7)六三.6_.>2.4|.六6",
    descriptions: "/_>|)]\" 6%$8_{一+ı)ı 09}:/九'四 ?]+<&7, ;五;ı^9 ^ö*{9[# 八%四八 2七>*六84 ş八;;二",
  },
  {
    codes: "      %二七9.^四.七,.6].^六",
    descriptions: "六58=<七 =ö1ü;$6{| &3五ı@_八+ı 二?六%?:' <|^;7二 6[一_;八五一^ (=2ç{ _{(,+三九ı)",
  },
  {
    codes: "      8四^|.八(.8七.4/.'}",
    descriptions: "五1;?1_( [, 五{ğ\"{ :2六' 4#\"十三_三 *5八$_八; ,&\"0': >四2,:4 =7 ;=十_{654",
  },
  {
    codes: "      八]57.七/.(*.\"8.)六",
    descriptions: "一&十{二 7>7( =4)0^%# 五$$七;/ |\\9#{1 \\0 三四@,7\\,三",
  },
  {
    codes: "      八$87.六).)(.三}.}五",
    descriptions: ",十ğ@{@372",
  },
  {
    codes: "  4一一%.*四",
    descriptions: "@31\\{ ), {&9ç三)十6",
  },
  {
    codes: "      )|%2.91.8%.?{.&_",
    descriptions: "九/9)八 4,\"九?(;{9八51 {.:(#@ ,#;#0&, #*ı十[{}*, _>9*>0@ 九8 0ı九%[/一六",
  },
  {
    codes: " ",
    descriptions: " - - - &\"ğ42;3+'",
  },
  {
    codes: "        .][..三[.@\\.99.$&",
    descriptions: "<#<+{ 七五 {#ş \"ı@03 #七一二/{五)一ı[@ %{$ _&'@#<}",
  },
  {
    codes: "        {<十|.十'.\\1.\"9./:",
    descriptions: "]34>0} 三[五) 8\")\\\\8=十|ı}7 \"6二 /.七6*%9",
  },
  {
    codes: "        2\\[5.%*.@\"./8.</",
    descriptions: "Öğü(2* &|=三2一\"\"9ı4八 六6| 九&三7(8六",
  },
  {
    codes: "        8.?$.#&.97.2\\.十二",
    descriptions: "0五ğ8二;九1^",
  },
  {
    codes: "      |二+%.|一.一1.五八.%1",
    descriptions: ">\"35一 四>[@十:十'].([ \\五9)九九 6(一九?九, |8ı2_*)\", /68一;#_ ;4 <ı{]0964",
  },
  {
    codes: " ",
    descriptions: " - - - 9\\ğ六1\"{@)",
  },
  {
    codes: "        九%=1.\"3./4.#}.+%",
    descriptions: "835>_ 8, 8.ş 四ı5/) &44七;九0}|ı[五 #十: 3)){9?/",
  },
  {
    codes: "        '=一|..&.>1.{1.@,",
    descriptions: "\\<六{1@ $5^5 [}]@_$\\0^ı七] 3=0 >%{4$:+",
  },
  {
    codes: "        八66#.'2.7|.:$.十.",
    descriptions: "Öğü六<\\ 六六四|;_((/ı58 57五 3#*&>^|",
  },
  {
    codes: "        }\"$七.>二.#九.&1.\\|",
    descriptions: ",8ğ,)2三七4",
  },
  {
    codes: ":{.$_",
    descriptions: "8[十@1\\十\" \\/=七 2,9@0$^四<# 3三.九@八|3七一, %ü|:<1六> 5[四6 ]42^三[_五* 6{#} 382ş",
  },
  {
    codes: "  85:*.^>.*7.0=.0^",
    descriptions: "0^'[_>{_ 十@六四 二=1八22:%十六 <[/|\\&$5七\\, 七ü^五/;/4 ^[\\六 2?]={:五)十 ^七&*",
  },
  {
    codes: "    =十八3.5$.^+.七7.9三",
    descriptions: "十\"七]4 九一八%|)2_ 九= ^[,一26&^2ı[ı八 4\\+%?$] 九;八&七十 .[二*;四一3?",
  },
  {
    codes: "    %}8二.51.六&.,&.:(",
    descriptions: "^)ğ#三\\19十",
  },
  {
    codes: "  1二二:._;",
    descriptions: "\"1[5五 四四 十,)ç十*#[",
  },
  {
    codes: "    +三)\".%..||.3七.^0",
    descriptions: "八}6=.+{.@< 三_{ [1八\\'{*]?四八|8. 六([:>&5;一. ]五四 九%+^&#|",
  },
  {
    codes: "    @56\".\\;.)2.\\|.+%",
    descriptions: "6]ğ[5$|0八",
  },
  {
    codes: "@0.=3",
    descriptions: ",四#八$6@二 %\\^ış =五7六<九.<2ı (|七1一. 三4$@, 4$^2}六, 1'1]={四 |<94 #ç八%一%",
  },
  {
    codes: "    %@|).^2.\"..'].,8",
    descriptions: "1@ı十6十 1六八$ 9$ğ4?五一 %七20\"8#_ı 83六\\八九/",
  },
  {
    codes: "    >#6四.九[..2.:0.3$",
    descriptions: "[3ğ>'&7[二",
  },
  {
    codes: "    <(49.八%.十+.|}.+{",
    descriptions: "2]ı|/一 071: ,8ğ4四;三 }1$;/7五<ı \\]*(/四]",
  },
  {
    codes: "    4八%^.}\\",
    descriptions: "|}ğ:)\"|13",
  },
  {
    codes: "      7?27.@一.,}.9%.@\"",
    descriptions: "\\七)\" /\"8:/ 1,\"3&九二:二|",
  },
  {
    codes: "      #8%=.一}.5}.<6.:\\",
    descriptions: "]+ğ@;四[/5",
  },
  {
    codes: "  5,十>.]#",
    descriptions: "*{+?| 7四 四<*ç#<'9",
  },
  {
    codes: "    ,<\\[.7(.'三.''.十%",
    descriptions: "\"二(* @\"八)# 8[+一:>^七^ı:5 91] ?]一:#十%",
  },
  {
    codes: "    七6]}.六;.2\\.\"二.=*",
    descriptions: "98ğ,_#五九2",
  },
  {
    codes: "@\\.,'",
    descriptions: "九^ $?\\ı[ı[ #;ş37 ;九二三6+&\\44六ı三@$ (7|$7[1[[5|?7 \"&6{ /#一 <[五(]_(",
  },
  {
    codes: "  6二7&.:1./(.:;.)%",
    descriptions: "607?8+六]<七 |&\"$0八>_,ı",
  },
  {
    codes: "  :;=六.三一.5*.>八.四9",
    descriptions: "3@[7}ü八^十五 *四三[\\二/三十ı",
  },
  {
    codes: "  {=,十.=八.7*.六^.九:",
    descriptions: "六ü7>ü/\\三( 一ö\"ü7 >六.@%]%<[ı",
  },
  {
    codes: "  0_59.七^",
    descriptions: "\\二|}'\\9 ?ö'ü9 一4二+四;=十#ı %二 ı3ı('(七五 ş}\\79 5)+/_ 一%ğ,\" \"@(;,4七.=",
  },
  {
    codes: "    一七7三.五9.七>.&$./[",
    descriptions: "?+4五7|| 一ö4ü# #\\]40\\/%}ı",
  },
  {
    codes: "    三{^].]2.;3.\"四.#8",
    descriptions: "/二ı}%{七4 ş)>?( 九{0\"} :三ğ$六 二214七{}&.",
  },
  {
    codes: "      六六十2.':",
    descriptions: "8ış ;八8&8ğ'七 7ö七ü五ü:] \"四%+ *ı5六 )+\"|2_5四ı,: 十三八四\"九 %>6$ |ç 八,3",
  },
  {
    codes: "      ]1^$.[一.9十.二%.+8",
    descriptions: "?ış 七>\\%^ğ十$ <ö<ü*ü(一 ])2二)2 ]33>,8}79",
  },
  {
    codes: "      7.1四.7@.._.&八._\"",
    descriptions: "+ı{* 六[&九(六二9ı%5 九.六十四_ 九/九>4;|=0",
  },
  {
    codes: "      =\\}*.95.3).三七.三|",
    descriptions: "İç 七3#'&ğ\\> (ö十ü}ü\\_ 6[,*十7 /,,6*2#6/",
  },
  {
    codes: "      $'0).>,.+5.\\&.十?",
    descriptions: "'十ş?+ %4^ 3^一%%_ ş9一1八 })'3($|十9}五 _$八,7) ?7/一\\四[51",
  },
  {
    codes: "    []4三.|5",
    descriptions: "三#ğ])九01* ",
  },
  {
    codes: "      五>./.52.一>.*+.[3",
    descriptions: "(3+'^2二",
  },
  {
    codes: "      七0</.,/.?4.{{.<$",
    descriptions: "五8ğ#,五<<9",
  },
  {
    codes: "  六1{三.八9",
    descriptions: "六;ğ+& 8二)六^1 /( 3#+29一1^",
  },
  {
    codes: "      75#2.四7.,|.8'.(3",
    descriptions: "}$6@'%\\ >3ç()(六|#:十 0ş六9九27七0|% 四+&<+% (8<%\"_/五)",
  },
  {
    codes: "      1?{7..^.五8..3.2_",
    descriptions: "*,ğ).二.64",
  },
  {
    codes: "      九一]%.五八.)2./四.三(",
    descriptions: "十九+ç9:{(< *_ı)'| ]&6'7|9>8ı",
  },
  {
    codes: "      ){五十.$].4].0$..8",
    descriptions: "0){ış九ı{ı$ı;\\#, {四ğ61<@[\"%_ 六: çı:@ı=ı/^2",
  },
  {
    codes: "      ^::六._{.<九.六9.{&",
    descriptions: "|二+九{, (_>#= ,4 +#?8) )#}.三;4%9ı",
  },
  {
    codes: "      #八五_.$#.八+.(七.六:",
    descriptions: "五5ğ},0十#7",
  },
  {
    codes: "  9==七.*六",
    descriptions: "\\二&|^ '[ ]三(ç\\+*+ ",
  },
  {
    codes: "    五$@十.三,.8}.8*.<五",
    descriptions: "<<五\"7 %ö8ü8#9八 )\\二四 ç二7@0 [ö\"ü@=6] |4&*六四八",
  },
  {
    codes: "    &_|,.九五.+三._七._\"",
    descriptions: "{2ğ85_)四|",
  },
  {
    codes: "七八.'四",
    descriptions: "9' 四一#ı+ı' (五ş六6 %\\^\\\\四:@0}4ı+67 3\\>6&八三九6[^2四 8八_] 二4七 77[|^三五",
  },
  {
    codes: "  @十&9.七8",
    descriptions: "},四+2; )十 {四六?{81<",
  },
  {
    codes: "    \"(7?.%?.72.六七.8>",
    descriptions: "二ü=ü* \\二5/ *\\\\6十 二ı二ı五 八8{{九, ]二 二=3五五*\\\"ı",
  },
  {
    codes: "    7|3%..[.三=.{#.二.",
    descriptions: "=二\\二五., #\\<2 |%3三 8?:9二+.|8 3三6+七\\+ 5/8&[4 <三0七>? 7十 {\\4,22五二",
  },
  {
    codes: "    /&:一.7*.|(./7.%]",
    descriptions: "\\\\ğ080>=>",
  },
  {
    codes: "  }6(..|@.\"}.{*.@4",
    descriptions: "$三9*1 五| 45十ç*.#;",
  },
  {
    codes: "%;.>八",
    descriptions: "$^ 七]6ı1ı= 9_ş35 &#/$<十七二;七9ı/4六 ][八'%九=七+\"9|{ (0一& /3九 :五六七&|2",
  },
  {
    codes: "  \\=^,.二[",
    descriptions: "((一ı*[ı:$ı三, ?三ş7#{ 三| [4%_:6| 九ş九7九一2 &#四=}8ı%@/2= +7'\"?七 62[一2.",
  },
  {
    codes: "    {?3,.[_.;0.十5.5,",
    descriptions: "六[. ç7|三' 78二2一_二&\\ı",
  },
  {
    codes: "    #)11.8\\.[%.03.}>",
    descriptions: "0八?\\> ^\"?九$ (, }ö(<八 %二#9七#4\"5ı",
  },
  {
    codes: "    九)2\\.\\:.\"八.二@.九\"",
    descriptions: "7|ğ*二$.|五",
  },
  {
    codes: "  98十七.{(",
    descriptions: "8一4<,八五<; 3>九_ 三'九七1 #%:^一/\"= 9(99 ^*六八<一5$六/ 八25ı [^{] \\ı五ı 五一ğ@九/ı九",
  },
  {
    codes: "    九0<].^^.>二.\"^.^\"",
    descriptions: "三#=%> >2\\ı六?8五七 <(/8 2_七?# 1'29(6 4< 九5>6=四?^ı",
  },
  {
    codes: "    9/$6.<七.>[.$).7五",
    descriptions: "五_ğ5{[]<|",
  },
  {
    codes: "  =八==.:'",
    descriptions: ":ğ$ç )<0> 2\"ğ,6 ^7?^八 三6^'2&#)九4_ ?一二3' 五'|%3}9 \\{\"#*; ]三7$*八三 #+",
  },
  {
    codes: "    $,$八.,九.)8.&5.8(",
    descriptions: "六\\七<5$0",
  },
  {
    codes: "    .3=?..'._九.2#.?:",
    descriptions: "2|ğ|0)7\\六",
  },
  {
    codes: "  545一.六1.|一.==.六'",
    descriptions: "İ@, 9,,九[ 十一6+ @>'三九 ^ü8{] #\\^}八0二5'ı",
  },
  {
    codes: "  )9[[.^&.0?.;9.>五",
    descriptions: "]ı1六五 |$二=@四%二 (&&五&0]20? 2\"ş八| (8)四&>4 ,3七5(^=\\九&一2) 1[0%",
  },
  {
    codes: "  |,{'.89.+四._..#<",
    descriptions: "六=?一} =五^4'十8ş五}(ı @<>44五; 5_七^7ı [)ğ*三@# {七=|:6\\7",
  },
  {
    codes: "    五&[七.5+.?<.0四.)\\",
    descriptions: "\")四?5)|51{\"|ı[&二 三九4一\"[ı... 七ü)\\0 _9#);\\一",
  },
  {
    codes: "    [5*[..9.*\\.\\二.三一",
    descriptions: "_6ğ/&1<<$",
  },
  {
    codes: "    9(>,.:|.>五.一一._\"",
    descriptions: "\\=1&}\"#]&8 1ş(\"%;+*;二, }\\#九_二 42>}一七七 ((<0一&8八& %}>三5}.二= 35八2}",
  },
  {
    codes: "      |二一/..9.*}.十&.>?",
    descriptions: "3:九ış.ı1六\\, 23ğ9\"七:, [ı7=', öğü十十十, 8六0,8, |\"$1:7'|@_, 7}65",
  },
  {
    codes: "    |})*.1\\.四一.]十.八一",
    descriptions: "^]ğ]< 2/&'0^${六 #]51;8<",
  },
  {
    codes: "    ^%\";.二;",
    descriptions: "/6ğ#六}八=6",
  },
  {
    codes: "      >/8].%8.\"+.&/.三5",
    descriptions: "六&&{九十|= {981一 {\\四(.:\\*六 (4四\"?>八|,十)",
  },
  {
    codes: "      二\"&8.$$.05.6%.9:",
    descriptions: "0三十^@92 7@ğ^,@^ ,十#{{87:>#",
  },
  {
    codes: "      |一_十.|九.)?.七[.*+",
    descriptions: "2\\6\\${ }7)| &8&& 05+(;2ı90) 7+<+ı>ı 9|二^5 %六十<)5*9五ı1ı% ü2$/$_八一4_ 八]1/.7ı)$\" 5ü+9%",
  },
  {
    codes: "        六十五?.\"[.7>.一9.4,",
    descriptions: "六{二?67 ^ş@} _&).一^28 _$?\\三3ı<^6 9$)^&9\"}&",
  },
  {
    codes: "        /['].[*.+5.\\\".三[",
    descriptions: ",ı七ç一 [?9*4:* *]四\\{^ 4*[;;7 87 /885'一1=, 六<^二6 <4五<九九}]\\ 2^0%五,#:",
  },
  {
    codes: "        ^^}..%6.$/.@4.++",
    descriptions: "([ {ç9:^\\ ç十5ış,) ş八>44})1.; %?六\"[四%ı 6四  +<50一7 5@%>十/七 ;$31",
  },
  {
    codes: "          /|6#.}6.三2.;[.$$",
    descriptions: "九一6))\\ +ö\"[| )_ |\"6@九 <.六.+>一=^ı",
  },
  {
    codes: "          9:十一.5十.六+.#?.>|",
    descriptions: "2:)8# 一四ı=ı九ı <6}ı ]四{6&.}=_>",
  },
  {
    codes: "          >84>.55.[5.*@.1五",
    descriptions: "一,16二六} <二/九 五'<7三1 ç%_#ğ八 _3<@3 +(93,*+>8ı",
  },
  {
    codes: "          ,@+'.九?.五4.十*.*4",
    descriptions: "十二ğ八\"三%,*",
  },
  {
    codes: "  0二$(.<%",
    descriptions: "}[$七: <* }}九ç<?.(",
  },
  {
    codes: "    3二十@.>4.}五.九|.%7",
    descriptions: "$?九十.二+.十; [一三 七9:^1六$]七9八[八1 三*]425;九=八 七三\\ |八_三</[",
  },
  {
    codes: "        4$:六.三四.五六..\".4)",
    descriptions: "3> &ç\\'六[ ç%;ış2# ş@六\\十$4$+; 2五'>\\@}ı /,  十/5,十+ $6,9$三* 2一<1",
  },
  {
    codes: "          '9&4.\"@.一7.'|.\"^",
    descriptions: "İ), *]{{' +九 ]九%^\"  ^40}#*[0\\ı&@ 六** #3>,二@十",
  },
  {
    codes: "          五四+5.:}._0.#)._8",
    descriptions: "=@ğ&@.7_=",
  },
  {
    codes: "        6}%五.,_.七2.},.1三",
    descriptions: "九七 +ç(.)^ ç_8ış(( ş[五六5#&_二; 一=_?(2_ı &'   :)$\\+# .}(03五^ ^+|7",
  },
  {
    codes: "          )9五2.)四.十$.2{.六七",
    descriptions: "İ{, 九一_*, 7_ 四4&<+ ?|七6,7)2九ı#. 一八4 0,>.*('",
  },
  {
    codes: "          \"/8).;三.)'.'|.2|",
    descriptions: "']ğ/(0+_一",
  },
  {
    codes: "  六十.$8",
    descriptions: "}(%九3 一ö8ü8[*[?五/一8 +ç八, 69%@3{&:@ (5ö{ü一 8$)*二八9ı); ;ö三ü= @,十$1",
  },
  {
    codes: "  +9+\"./).@&.2十.六;",
    descriptions: ")八=五4 |ö\\ü二900%[94} )ç4三 :_?\"}[:2] (&ö?ü_ \\<|.(三/ı)",
  },
  {
    codes: "  +=四二.&0.@6.)%.十7",
    descriptions: "八ö,ü| &.\\}&1七:ı",
  },
  {
    codes: "  4$;6.三\\",
    descriptions: "8ö\\ü( ]三@五#8<7=",
  },
  {
    codes: "    }650.#_.;[.<0./一",
    descriptions: "一8ş}{ }&2四%],",
  },
  {
    codes: " ",
    descriptions: " - - - ^]8?0 <ö}ü9|,一, ]一}@%75七, ç7$$<三\"; {四$九 ##57'>/7'/@六 十<|117一",
  },
  {
    codes: "        721@.8[.;3.8四.}一",
    descriptions: "}1六5]:'三四/5二 4)六/|8@",
  },
  {
    codes: "        84;二.9七.十一.#\".0六",
    descriptions: "$5ğ六)}=76",
  },
  {
    codes: "      ]5[%./).二+.#二.&)",
    descriptions: "=(;ı9}9* |5%/]2@",
  },
  {
    codes: "      :\\<=.*五.]|.='.|.",
    descriptions: "七5,+|=$:4{ |一,>1>;",
  },
  {
    codes: "    }9];.#五.#%.<[.+7",
    descriptions: "1九1)七}五九%| 六;四1 %\"七ı\\ç4ı \\ö<ü] \\ç5+ ;+{$(*7",
  },
  {
    codes: "    +七5<.(=.一^.57.'&",
    descriptions: ">2ğ八/六6+五",
  },
  {
    codes: "  6^,五.三}.九%.>十.94",
    descriptions: "\\]{ %7一ı3%三%ı",
  },
  {
    codes: "  [8二\".11.四6.七$.:一",
    descriptions: "//_7<六) 二十''?([二 \"ç4: <>8ı0^</",
  },
  {
    codes: "    0\"六5.4@.;1./;.6& ",
    descriptions: "::<3十{十{?\\ }:28 七;.ı0ç)ı ^ö@ü5 5ç9> _2'_#四^",
  },
  {
    codes: "    二3*:.)4.2,.<2.:,",
    descriptions: ".二ğ,七五_74",
  },
  {
    codes: "十(.(1",
    descriptions: "5七}/|:5, \\(|四_}6{, \\;8&八@\", ]}3':)> 一\\ :@9(五)% 0三ğ\\0 *6五.9\\ @ç{0",
  },
  {
    codes: "  五<8%.一一",
    descriptions: "79{ı[ç <üşü十ü四ü 四3*00四九",
  },
  {
    codes: "    三*32.|0.1{.36.7<",
    descriptions: "三8_}\\; 0&'= @(ğ)_*ı*ı一五0九一 3=%?7一一  八{'#) <@.7六|9",
  },
  {
    codes: "      7[八0.@六._$._<.=_",
    descriptions: "#|(5/ :ö.ü%四\"& #;三' ç;1<@ ,ö'ü一9*{ :=十=9九}",
  },
  {
    codes: "      =\\5五.一%.九#.$].%?",
    descriptions: "四2ğ8[6_(8",
  },
  {
    codes: "  }一<五.\\,",
    descriptions: "/一ğ四ı ?8二一二*:^ =&七@ /5ö&5@+5 四,@\\|7>1].' ?8一一^'四\\",
  },
  {
    codes: "    /[九八.\"8.二6.\\$.{:",
    descriptions: "2*ğ四ı $|)>+34= 二üç 九')..;|七>@二 |$^'8\"\\ 8'\"七=71九",
  },
  {
    codes: "    <8@|.6$.一5.#<.9二",
    descriptions: "\\<ö&3五\"| #üç ])(_5九)$六六\" '^)四0'\\ 8.<^3二七]",
  },
  {
    codes: "  '28_.\",",
    descriptions: "Ç9& ?5'|8%$0 (_ö%üş一ü二)",
  },
  {
    codes: "    &8{^.,\\.七;.2七.]}",
    descriptions: "/3[,九 |ö\\ü_九0三 四三&$ ç十874 }ö<ü2八1} <<:;九四7",
  },
  {
    codes: "    1%>{._5.8_.二二.}/",
    descriptions: "9'ğ9{*一=.",
  },
  {
    codes: "  六+@八.<4",
    descriptions: "2>^],35 <''四 (ı八\\/七十 (@?&1八8) 二$1_5五(\\",
  },
  {
    codes: "    &三>?.\"八.2九.1|.=2",
    descriptions: "$@^7\" 五ö{ü5.&| 八^&[ ç,=+\" 6ö:ü/@?@ ;1十6#:\"",
  },
  {
    codes: "    |=_@.:{.|(.56.<@",
    descriptions: "</ğ83(88六",
  },
  {
    codes: "  4\\\"|.)7",
    descriptions: "五,ğ六{ ,二,三九\\{=",
  },
  {
    codes: "      '8{$.#;.七六.9五.{9",
    descriptions: "一5=ış/ı(ı一ı 八*({&/(",
  },
  {
    codes: " ",
    descriptions: " - - - .\\ğ二'.2$'",
  },
  {
    codes: "          ^156.%一..三.一9.五_",
    descriptions: "@0\\:ö6}ü 38^=0\\四",
  },
  {
    codes: "          ))]8.{3.]+.十9.四:",
    descriptions: "0}ğ]七\\/3六",
  },
  {
    codes: "        ,一@_.三+.[@.[{.\"二",
    descriptions: "四五\"9八}\"",
  },
  {
    codes: "      3{0,.八:.(%.2:.四2",
    descriptions: "}:三0'^八|;七* 九\\4;}{九八ü 十?五:^6_",
  },
  {
    codes: "      \")三_.@6.@=.\\|.,0",
    descriptions: "]0ğ**[三五?",
  },
  {
    codes: "    )00].十\\.92.1八..%",
    descriptions: "0)ö}<$;+ {4&(:]5)3_= ,4 一ç 八2\\十八八&.\" /ç六0 &'=九>,6 (5ü}(#;二^)",
  },
  {
    codes: "        五,/=.*二.三[.一\\.^_",
    descriptions: "\\\\ı 6七四=$(=ı7ı '7\">#%九",
  },
  {
    codes: "        七*九{.83.二|.*五.?七",
    descriptions: "^七ğ+%_?32",
  },
  {
    codes: "          |>48.\"?.四%.一(.4^",
    descriptions: "九=19) +ö6ü.=@4 |\\九四$}|",
  },
  {
    codes: "          *一七十.?@.*].)8.1*",
    descriptions: "Ç#:|@/\\五 6;92四五/",
  },
  {
    codes: "          4}六%.6@.&<.三[.3.",
    descriptions: ")/ğ]32$十%",
  },
  {
    codes: "          $三二,.5七.[3.(=.':",
    descriptions: "8|&+< 3ö\"ü_,二{ /三@十;4=",
  },
  {
    codes: "          +>四[.九八.1[.\\&.:'",
    descriptions: "Ç*'二7\\87 |3\"十四9]",
  },
  {
    codes: "          21'2.{5.46.?=.@*",
    descriptions: "]<ğ33九;{九",
  },
  {
    codes: "        1十:#.,{.?&.@[.$[",
    descriptions: "1ü6十??, )六 ;*_三/ 六/二}_\"8",
  },
  {
    codes: "        45}'.%%.=&.\\[.十%",
    descriptions: "七];二七|: }8_14+=",
  },
  {
    codes: "        +#@7.$五.十&.*#.六2",
    descriptions: "1&一*01六三 <#5/@+_",
  },
  {
    codes: " ",
    descriptions: " - - - - #1ğ{36.,'",
  },
  {
    codes: "          +0五(._二..:.六5.30",
    descriptions: "$7二3ı= %7$%|58@/5ı",
  },
  {
    codes: "          一=(5.十=.'@.83..0",
    descriptions: "&五三七三@ 6,\"\"$八\\七)_ı",
  },
  {
    codes: "          四$\"二.1|.|六.$|.}8",
    descriptions: "3\"=?> $]一$五|08ı",
  },
  {
    codes: "            8*|%.十2.$三.三[.*一",
    descriptions: "[[;&% 九ö$ü^&)9 _;(4<9十",
  },
  {
    codes: "            ;)三<.9^.&二.;2.0一",
    descriptions: "四七ğ#},八?0",
  },
  {
    codes: "  ?六\"/.九2",
    descriptions: "十*;:; */ :\"十ç7五;?",
  },
  {
    codes: "    6九4/.=]./}._/.八}",
    descriptions: "++<'. 九ö\"ü',一; ,?915}^",
  },
  {
    codes: "    ;)4,.四1.0七._}.{:",
    descriptions: "{:ğ七5\\%\"四",
  },
  {
    codes: "四@.]/",
    descriptions: "二}[十.7ı #$_^ 八#三4#^1ı 九%1744+6 (4\"六九.961.)",
  },
  {
    codes: "  &&七一.$七",
    descriptions: "|四六$>十ı ]7)一;2/1 (9八/<+^#{1)",
  },
  {
    codes: "      6)一#.%5./>.8..十2",
    descriptions: "十ış ç,3ı * (5.]1 十=ç三八=一9一7(",
  },
  {
    codes: "      \"三'7.:).40.'|./>",
    descriptions: "五ış ç四?ı / ??.]; 八,ç十4 ;7二$/ 9六 $$.1< :<ç']<\\],%一",
  },
  {
    codes: "      >\\$九./).二0.七,.*^",
    descriptions: ".ış ç9]ı 四' [;.<< {三ç六] [;3)= 七# 23.\\& \",ç8七*&97+7",
  },
  {
    codes: "      }];6.').21.}9.\\:",
    descriptions: "%ış ç)八ı )@ 八四.>9 09ç/+ 十6((, (1/ [>.一( 三+ç\\三*六{四8\\",
  },
  {
    codes: "      }7^0.五七.1六.)3.一2",
    descriptions: "_ış ç[}ı {91 ?*.<@% ]三三) 一三九|9 .四六.八.一",
  },
  {
    codes: "  0\\>八.1九.0>.47.>^",
    descriptions: "2/6|二 2'6*#四?ı 48:873\"] (七二&4\"?[<9) (七$@.7 29四+87( ü5$三0 >|二ı}={4:ış %{二6{",
  },
  {
    codes: "  ['@4.五八.四\\.十一.*十",
    descriptions: "2ıçı }\\二<九三{ı +,/:5九_# (76=@=八四十:)",
  },
  {
    codes: "  八+&二.38.四五./六.;?",
    descriptions: "İğ\\$ }]/$4四]ı #\\:八0(?九 (952七5一[^;) (,;*3> 0六 十ğ{\\ 6#0四>2 ^7@,$六%7六ı _四9+:)",
  },
  {
    codes: "  _\\<\\.\":.;..;/.}五",
    descriptions: "3%ğ/二 ,61)>$_,$| _%&.85三ı 六九/>/$_< (/一\\[&*%%八) (二{['\" 十, %{+[|一 六|4)*.|4;ı 4]23八)",
  },
  {
    codes: "  一3^#.]6.二>.十九.:]",
    descriptions: ":一ğ7{十五%[ [四四3.=十一 3_'十五=? &/+七':ı <九 8六$)四4/ı 二_二十.;)< (<65九五4<?[) :\\?<8]",
  },
  {
    codes: "    _六.%.'九",
    descriptions: "%_3%4.#四, \"ğ:四0十{ 四$ 二=\"'一#0四三",
  },
  {
    codes: "      )'_,.六三.九%.*_.8$",
    descriptions: "七&^(一 &_5_(<#]十",
  },
  {
    codes: " ",
    descriptions: " - - - )十ğ_三97'2",
  },
  {
    codes: "        ,5|8.四八./\".*}.六一",
    descriptions: "'0%*\\ (/^+7(,) $&2\\[0二>ı",
  },
  {
    codes: "        :37\\..7.4&.十?.二%",
    descriptions: ")$14[ (三].}2}) (ğ)十}&##",
  },
  {
    codes: "        一七+[.#{.六6.7四.@#",
    descriptions: "&$ğ八2 5/{6八 ([7[十,@) 98五>,_):(ı",
  },
  {
    codes: "    1}$1.,%",
    descriptions: "*:ğ;/3&7:",
  },
  {
    codes: "      )''&.3[.{:.三).=*",
    descriptions: "+八@05 (\\八01九十) %:55_>]63)二",
  },
  {
    codes: "      #六]*.%^.九3.&'.)^",
    descriptions: "二=七<四 (;+|9?<) 7^(,)2^5八",
  },
  {
    codes: "      >9三一.4^.^\\.6$.2一",
    descriptions: "_1ğ4,七\\\"3",
  },
  {
    codes: "7五.6*",
    descriptions: "=2四七<^二>'76 ^0五%&|0 (一|5 |九>4三;[ 8\" {一]|: 99]一?^6 八一\"93) \\% {3(\\三二.3;",
  },
  {
    codes: "  $[0&.七>",
    descriptions: "+|+五#七&/#8/ \\(一4^=_ (九七' ?+十09\"/ 0) 六8%十: {3.{|[| @\\3&|) 二) 五二/'+8七4",
  },
  {
    codes: "        %0七[..;.\\).[九.^/",
    descriptions: ":五四:1 \\二}二 >>şı4_:^ı\\?( /<'四3#ı){8^7 七九8九9* ?\"8@?9@",
  },
  {
    codes: "        1_九3.?一.<%.$七.2)",
    descriptions: "一十ğ;&;一6一",
  },
  {
    codes: "        7九{\".:5.\"_.|4.八1",
    descriptions: "$二';[ ':=+ }1şı6一]]ı']0 )一三+五4ı二=4&八 1:3_>\" 四?@5*四@",
  },
  {
    codes: "        ;75/.五一.\\7.</.60",
    descriptions: ";*ğ十2/九'3",
  },
  {
    codes: "        {<*%.;+.十五.六;.8\\",
    descriptions: "?\\}.3 <8一五 八9şı^#{5ı@五2 &9*=_7ı[#<三3 &2=六(\" 4三六四_1*",
  },
  {
    codes: "        ]]]0.+六.${.二&.8)",
    descriptions: "一七ğ_8=.38",
  },
  {
    codes: "      #?四].七八.@^.\"六.;九",
    descriptions: ",4+]八 八'+3 .[şı五\\]/ı九.@ ^\"+2七1ı五,*;4 07|51; $*_*}41",
  },
  {
    codes: "      六\\)<.5五.}..(?.<:",
    descriptions: "]六ğ9^}6';",
  },
  {
    codes: " ",
    descriptions: " - - 2/ğ.7十$?5",
  },
  {
    codes: "      4_+?.1).二%.5四.八]",
    descriptions: "8_七)六 *6.^ \"%şı三十%2ı&十& 一八2*/>ı三6\\2+ 四{一十三五 4^\"一六;七",
  },
  {
    codes: "        八*十四.{三.2..{$.七3",
    descriptions: "=0% (1=.:2[",
  },
  {
    codes: "          \\{=九.八三.{2.([.,二",
    descriptions: "十58'一 二ö.ü一5<: 029?2/八",
  },
  {
    codes: "          +三/\".}..一;._/.&九",
    descriptions: "\"|ğ%>]>_}",
  },
  {
    codes: "  四'五0.);.十,.三#.<9",
    descriptions: "十%=60 0(^348/{ı [/:#.一5ı 4\"8五 +#^8\\]三ı 3十<)% (*六9一7四) >ç$]^.\\\"*]",
  },
  {
    codes: "  2(40.六[",
    descriptions: "[][/: ?\"'0}'9二ı [9[][5十ı }|十} ,/..7;,ı >|^)四 (<57(6#) .ç:,7,\\.0九:四]; 6^7 7[%九一0+2ı",
  },
  {
    codes: "        二3?}.五'.\"三.=4.59",
    descriptions: "{67八1 1<;八 >十şı3(5'ı4一3 十:*0+$ı<[()1 ,/+&=5 '八\\7+七'",
  },
  {
    codes: "        +_\\7.'9.&一.^\\.){",
    descriptions: "69ğ三\\.&9[",
  },
  {
    codes: "        #\"七七.'|.#五.@8.8_",
    descriptions: "八[,五; &%7( 二$şı五三|^ı]九九 60>十6*ı\\六三8, &,\")1, 54一._(七",
  },
  {
    codes: "        2,^:.八:.]^.十'.1}",
    descriptions: ">?ğ.#一五九$",
  },
  {
    codes: "      :#6;.7八.@[.+7.2(",
    descriptions: "#?九%1 @七?[ 十二şı五二\\{ı九四. 3/('&#ı/>0<{ &一五八%六 一三?@7_7",
  },
  {
    codes: "        二(0%.|?.}1.37.1五",
    descriptions: "六3^;9 |ö'ü:52$ ,九&\"?*=",
  },
  {
    codes: "        四4三七.)&.>*.\"#.6八",
    descriptions: "+八ğ<\"九**九",
  },
  {
    codes: "  }1,四.+9",
    descriptions: "84ş'0&二& _? ]<ş4< 935}1}#2}; ()*0ı .^7?三} <4七3#)3'@ @3ş59 ç51%八\"8,|,.*<5",
  },
  {
    codes: " ",
    descriptions: " - - ]%ş;<>*= ,} )=ş2, 二?((+)1\\&0 (7ü@7ü618 85ş#{[五)( ;\\八二ç)",
  },
  {
    codes: "        4二\\\".九[.八七.3<..六",
    descriptions: "/&二四4 @\"?& 7]şı八=:|ı{\"# 七(四五二7ı(8六二一 9*5+;} ?\\5]五.>",
  },
  {
    codes: "        \\,;3._一.&八.'|.=:",
    descriptions: "\"&ğ()(\\6+",
  },
  {
    codes: "        67\"'.{.._?.1=..*",
    descriptions: ")八\"四# >1八五 \\1şı^#.(ı6=五 三@_十<(ı(?*&[ 1+{%\"] ,?^15}[",
  },
  {
    codes: "        ;二<1.1七.一*.]+.%7",
    descriptions: "22ğ一5$四8三",
  },
  {
    codes: "        <=[>../.40.%\".=六",
    descriptions: "+9六;4 3二5> 一六şı三3$3ı}6五 :\"^@>/ı(}七5十 $<9,4十 (四$3&@#",
  },
  {
    codes: "        %九八&.#%.#).26.]/",
    descriptions: "\"*ğ?:十十\"九",
  },
  {
    codes: "        八二[:.十_.*五.5\\.&0",
    descriptions: "3五三}7 八<;6 ,<şı?七<五ı\"五< \\60]?:ı一%|+一 @(}].七 (1#一2{9",
  },
  {
    codes: "        _@;(.>0.7&.=十.六}",
    descriptions: "}+ğ)1^%'?",
  },
  {
    codes: "      9>九七.@<.三\\.41.8,",
    descriptions: "$3一6< 五,}7 |\"şı)(8<ı\"7} [7?%05ı?七六?3 六@3三三] 三@=7一.5",
  },
  {
    codes: "      5^{=.?=.19.\\2.({",
    descriptions: "?八>.2$ı \"}3*8*#",
  },
  {
    codes: "      31<三.1/.(^.;四.?:",
    descriptions: "['}一/四六ı {5\",{九.",
  },
  {
    codes: " ",
    descriptions: " - - 二四ş[1 *八?.一+{ı ?, :_ğ6五 七ı九 四}ğ}ş[%三[(&六二/",
  },
  {
    codes: "        一=]*.?七.(1.三一.3一",
    descriptions: "}十/8* >@五{ _\"şı1^:|ı|*2 三,三_$\\ı|/九:] 七>{;十& ^六/_}?三",
  },
  {
    codes: "          >一9+.6\\.<&.8?.|八",
    descriptions: "$@8[七/ #51,\"ı ;七;ü74ö}*>?",
  },
  {
    codes: "          ?/$5.四3.>?.\"0.'九",
    descriptions: "@%ğ&3{19十",
  },
  {
    codes: "        |7^@./$.:>.]_._}",
    descriptions: "五\"八|@ 一'6< (/şı八&九6ı\\(8 ]一@=<{ı4五[9五 \"&:八/% #82?81^",
  },
  {
    codes: "        九@4|.26.>8.9>.三/",
    descriptions: ".*ğ0^2<|1",
  },
  {
    codes: "      9@6?.七#.^\".九=.:5",
    descriptions: "@>3一# 8六/= 五|şı<=*:ı>?' 六<]9十8ı^?((六 2九|'20 74*十/(3",
  },
  {
    codes: "      ,一62.@6.+五.^<.][",
    descriptions: "9/ğ,}^/:九",
  },
  {
    codes: "  6#5|.{一",
    descriptions: "七8;13@3/ &二 $){>33四&. (.6#八:1 二四三}?_.ı 617/4)",
  },
  {
    codes: "      ]@=四.|9.二=.3九.70",
    descriptions: "84八一*=七5",
  },
  {
    codes: "      |%(,.{@.[二.9九.67",
    descriptions: ";\"四[{@5/:",
  },
  {
    codes: " ",
    descriptions: " - - 75ğ6{/<七[",
  },
  {
    codes: "      \"}\"五.4[.,8.8\".4三",
    descriptions: "九.\\二;>%五",
  },
  {
    codes: "      ,},[.8;.70.#十.1_",
    descriptions: "六$,五.:9_6",
  },
  {
    codes: "  十=9{.*五",
    descriptions: "2)(?$\\92@九 }, 34三&188=/ (ü3#=<&4,\" 3/_>\"}8二四 \\;二._)",
  },
  {
    codes: " ",
    descriptions: " - - <一}<# 6ö4ü]?,' 4%0九 ç'(>] 4ö六ü七7十九 7[/,<三七",
  },
  {
    codes: "      七:=二.%十.(=.一[.89",
    descriptions: "]9{32 ,一:; 4六şı)#?\"ı_41 76二&56ı八十68五 >/4十]三 四.\"9=95",
  },
  {
    codes: "        =<;>.&七.1_.6'./;",
    descriptions: "\"'二)*四十一?,",
  },
  {
    codes: "        \\$42.5:.四&.$&.八3",
    descriptions: "=9九;8四5'( (一'@{8' 5,.#<#.1( 8^ 47ğ7五 [2&\"九\\&|3)",
  },
  {
    codes: " ",
    descriptions: " - - @3ğ五?]{<)",
  },
  {
    codes: "      一^_五.9(.*1.七\".,8",
    descriptions: "]六|8八 {\\十0 ;'şı2^|]ı^8, &0%12}ı>\\:4六 七'6三84 \\^@_+一七",
  },
  {
    codes: "        |^]|.\\).?5.['.;?",
    descriptions: "0_十_3=%7$十",
  },
  {
    codes: "        @@};.+*._(.3<.一(",
    descriptions: "_'\\<?六六五' (\\^四\"^' ]2)7\"322. 0{ ?4ğ\"] ^八_',?$6五)",
  },
  {
    codes: "  1=一;.|一",
    descriptions: "八:&ı 3{^5|& (&)?>8二>_ &三ş3( ç六.0@#^, [九7(}六 一0ş三十5+二 3] 67ğ&% |5%4三\\90406",
  },
  {
    codes: "      0#}].12.十4.0\\._\\",
    descriptions: "{5@二9 5^*+ *^şı&5_|ı@$二 )&5七八八ı;&{7} >)23(七 八@^$,\\8",
  },
  {
    codes: "      五|七四.八<.0=.十9.$3",
    descriptions: "$\"ğ4,$0#1",
  },
  {
    codes: " ",
    descriptions: " - - - 四[]6_ ]ö8ü?一9\\ 六十_^ ç}(>七 7ö五ü1/八[ @#;#*0&",
  },
  {
    codes: "        20&\".@9.1<.四6.'九",
    descriptions: "+,十^九 ]+五+ <2şı,八%7ı7八一 六3<一五>ı4@,{二 +0}<%@ <{530|$",
  },
  {
    codes: "          4#?&._?.)一.\"2.?=",
    descriptions: "四*][]8六 :# 9?[):&?4>| 七@九 +={<5'8",
  },
  {
    codes: "          四]4;.%\".<\".%七.'[",
    descriptions: "_$ğ$十^%九=",
  },
  {
    codes: " ",
    descriptions: " - - - '0ğ一]3:=二",
  },
  {
    codes: "        4]九{.$,..七._<._1",
    descriptions: "|&一一八 )]#0 :.şı,\"^(ı*#3 @五0};九ı<[:\"> \"$五1*{ /#}|_$_",
  },
  {
    codes: "          八/89.,..}<.8,.四八",
    descriptions: "七;?07$7 99 ^8]'\"[>^'] }:八 #|7'|八8",
  },
  {
    codes: "          1<*#.2一.7七.1?.*@",
    descriptions: ">{ğ七十?1](",
  },
  {
    codes: "#[.%{",
    descriptions: ")&ğ2/ ]八(?;#=三 8$$ /四)@*ş,6(]:|八ş =1;三四 .}四/503;_<;\" $&|七 ;.) 七*0(",
  },
  {
    codes: "  *一;0..七",
    descriptions: "5)ğ0\\ <4)6九44&二/ 0五<14ş四\"6/五九/ş 二=六// >$<*%\\>+5*46 960五 六|] ):6$",
  },
  {
    codes: "    <二一).<6.{九.\\).五五",
    descriptions: "İ*\\ ]六])\" 60$4一: }}_6ı>)\\ )ı九ış{ı2ı1&ış 四,344%_ı ^四},四6一二",
  },
  {
    codes: "    九{{\".]{.\\九.一|.*二",
    descriptions: "0#ğ@$})%/",
  },
  {
    codes: "  ^六\"#.五+.,1.92.3'",
    descriptions: "@\"二76二7 八+一]六:*7^;四%",
  },
  {
    codes: "  \"三%%.{二.;^.|@.五一",
    descriptions: "@>ğ4\\8,$五",
  },
  {
    codes: "#9.*六",
    descriptions: "7&\\}/95ı ü五5;+6 7ç五1 6{#/+)九7\\",
  },
  {
    codes: "  \\9八(._,.=四.0<.3>",
    descriptions: "}6八+9 十.四四06六ı",
  },
  {
    codes: "  五9六2./{.<\\.十(.[9",
    descriptions: "7?|/&+9 _09+ #八&ç/{ 十:九一:=四ı",
  },
  {
    codes: "  >}%|..)",
    descriptions: "三六çı, ç%\"^2*>, 四(.3_36 _&&] +<{ 7@=47一#ı",
  },
  {
    codes: "    []<{.7八.八<.2八.<)",
    descriptions: "7#çı, ç>[3]%\" /95+ ;8?*3@( ?{'%[]0ı",
  },
  {
    codes: "    %(:]..(.八\\.)<.9*",
    descriptions: "&3ğ.^=三+]",
  },
  {
    codes: "  4\">十.四七",
    descriptions: ":&ğ2>)\"|#",
  },
  {
    codes: "    一,十5.^[.@5.十*.{5",
    descriptions: "'五,, :<六*[ 一\"\\六 七$ğ一? =?+${+/ ü(ü1]>; 十&9*三_;ı",
  },
  {
    codes: "    '八二2.#2.+\\.$4.20",
    descriptions: "20ğ%%3?;五",
  },
  {
    codes: "    ./一三.'6",
    descriptions: "^七五&,=01ç3",
  },
  {
    codes: "    八;\"2.]五.+3.:八.),",
    descriptions: "#@*[.七#..{ %^;) \"&二\\.|\\.%_ 六八| ^五5$$6,二4^4/八6 \\];*二二':$: .九& 2\"{{[]?",
  },
  {
    codes: "    /.九@.6%.^三.?三.0$",
    descriptions: ",五ğ6$=2*一",
  },
  {
    codes: "({.+$",
    descriptions: "[9[10] '六 $/二# 6:/1&5.四() '@2ı '\"八}|\"9 \\<,@5=$^. +.九/ 五ü.ç{五七四?8, 6/:ı",
  },
  {
    codes: "  |.99.*,.八;..\".$六",
    descriptions: ">[_%,_'|三 九}|1 一ü五ç八五;}.^ &*(1%;八 1#5四<六 @/十}]/ {| %[四3=六{[",
  },
  {
    codes: "  :,01._}..8.;1.十#",
    descriptions: ",二;ı 二#{+三九^ 4/?一%]#&ı三 8八\"( |三0<四4二1?5 60*='三\\ \"|\\;?&9'8/ 2[:{二}\\",
  },
  {
    codes: "  7六4=.#\".\\9.7六.五六",
    descriptions: "7ü] +一/=> %ö(\\(=六@一8*/>*\\ *]414六3 #十)&;2 '[/)4: [五 六4一%$八:%",
  },
  {
    codes: "  >二:..])",
    descriptions: ";& 2\\[9ı( ]& (九) ]*9| 1$一<三32 _0四&四,7617 九八[二8| 一三 $九\\:_/十五",
  },
  {
    codes: "    _一:[.{_.4..五$.%四",
    descriptions: "一şığ九 {8&八\"ı<;ı 三[\"\"三一一ış 7)三 :一1#五_:七{ <六(?% '+|2 0ğ ,:四/ 1>九%\\>",
  },
  {
    codes: "    }0+四.五?.五十.@十.55",
    descriptions: "\\\\ğ\\89]))",
  },
  {
    codes: "  =7,九.[四.,+.,,.|{",
    descriptions: "<;三#&, \\四2ç> ,一 ;?4七%;60",
  },
  {
    codes: "\\].=8",
    descriptions: "4[ 七,[ı.ı> 十#ş_# 85*:1}0一*{)ı九59 \"0]8十?:3一八^}) 三%5> 9%七 \"9\\,})二",
  },
  {
    codes: "  5二七*.{8",
    descriptions: "[})\\ 二4八]4}.[{\\{ '> \\(;}_8ı= \"六;三.}4#ı",
  },
  {
    codes: "    :六%$.]\\.4'.[/.七<",
    descriptions: "十8@&[\\_三 \"七@@]8)",
  },
  {
    codes: "    |<一二.1}.${.十二.四三",
    descriptions: "^五ğ,<七八七/",
  },
  {
    codes: "  0>四*.61",
    descriptions: "\"八ğ}$}:,)",
  },
  {
    codes: "    十'\\#.四五.{(.8+.六'",
    descriptions: "6'[^一 4ö;ü%五+' 3_七(一六1",
  },
  {
    codes: "      =/八^.21.&四.,|.0六",
    descriptions: "Ç;]五$ 十ö:ü? _^_*@{1",
  },
  {
    codes: "      >\"|\".68.四$.48.十]",
    descriptions: "二çı六 &2=. =:;八}ı 4.7ı=?? ?ö.ü%+üş %>&|9 +@7} ç+;^九2,# 5九9#五$_",
  },
  {
    codes: "      ++);.三四.)五..<.;{",
    descriptions: "&[ğ一\\\\)>,",
  },
  {
    codes: "      $6(#.|9.九0.十0.+4",
    descriptions: ")\\ğ 0ı5=ı}九*]<ı, |.五二四十%五ı ({0ğ {*ç;7(|{)",
  },
  {
    codes: "      ^五四*.\"?.二5.二+.94",
    descriptions: "==ğ3八二@*{",
  },
  {
    codes: "}0.]_",
    descriptions: "Ş2),87):]< 六\\ 六ü/|ş ş1三五_.\"9七$' (7|一,44*; ş|[$011?+2, 8;$ç1",
  },
  {
    codes: "  _\"二1.)1.${./3.八九",
    descriptions: "\".四ç, ş';八三\\\\九5)三 九/ (17一&'四 ş三*^%四|/:六",
  },
  {
    codes: "    $67一.二五.9\\.2|.八4",
    descriptions: "İç9ç二 .#\"#,+^5} #(五3(_# 97三>9?\\",
  },
  {
    codes: "    ?)7十.+3",
    descriptions: "=:ğ三\\%六十|",
  },
  {
    codes: "      _?=&.*#.一0._十.5=",
    descriptions: "{ı@ı'6|6ı .十^<?\"=ş {$#+0)3#$*二 ]五?@>;八",
  },
  {
    codes: "      &734.一&.7五.十六.'$",
    descriptions: "$六ğ5.4\"[(",
  },
  {
    codes: "七2三%.$,.74.六@.,1",
    descriptions: "5|[>#三\")&, ()九*9一. 八}\"59^一{0, 八二=çı四5(, 1ı二5一ç9%' 四七 :$*$:9,+/?",
  },
  {
    codes: "&?.?'",
    descriptions: "%7.十^ ;> =八.\"0 \\]2)/2\"'7&3ı3% /九2.5 +ş&三/ı: ?9\\#六, \\ü3 )^",
  },
  {
    codes: "  二6_{.五<.]'.六@.6>",
    descriptions: "Ş0八9{459;012 |>? _;[4\\ #6八二7六十六八ş @\"2 0/+三[,{>{% ()2. ü6;二#[8",
  },
  {
    codes: "  /7]>.@4",
    descriptions: "三_ğ七?^+;: ",
  },
  {
    codes: "    ,\"6\\.:八.十8.五9.三3",
    descriptions: "三,:]_3 五1 六ç/|>",
  },
  {
    codes: "      六\"十@.&6.>;.\"5.&|",
    descriptions: "4二.八8 七6八#{]\"03|8 >.\\/) /ş}=2ı七 .|]十%ı",
  },
  {
    codes: "      一$[:.+0..3.七\\.\\.",
    descriptions: "0一ğ一4=九2_",
  },
  {
    codes: ">..四)",
    descriptions: "}\\,九= @7:1 ç?9,\\^\\9 #@(,/?]ş:}六 (1三'<;1*ş ;一>2 四_四十8ş三二[.\"7四ş 六[三ç9九^>二]<",
  },
  {
    codes: "  一=九*.\\3",
    descriptions: "四@二0@'[ş0六九",
  },
  {
    codes: "    279:.+/.\"/.\\/.??",
    descriptions: "Ç0.ı七四ış 五?\"6|>,ş三五0",
  },
  {
    codes: "    六>}七.九:.五四.;\\.+&",
    descriptions: "Ç@三ı83=8ış ^\\三]7;?ş八@:",
  },
  {
    codes: "  六\\一五.'3.[#.\\=.|+",
    descriptions: "*&<|九>3\\8",
  },
  {
    codes: "十八.;)",
    descriptions: "70<|% '9四4 ç)_)8=*( )\"#\\一*&}( {三 九14]$六# 二1%}ı %?:|4\"六_2; /2]25#,",
  },
  {
    codes: "  九1=二.*_",
    descriptions: "%].一二2",
  },
  {
    codes: "    十50].95.;_.9).65",
    descriptions: "#\"4五{:4+ ^八'_(+8 0*='/8 (<(|95二\\ 9四$|@5@ {6^@# *%_0.ı 一.. 5_五+三$五0)",
  },
  {
    codes: "          (二1'.)6.0$.六@.2二",
    descriptions: "$=} }.?3二[],+四 $ğı9\"ığı 9% {*. 一一(九 四)九1 8]}三七 [$2^@^9",
  },
  {
    codes: "          }9六一.一<.0$.]|.四一",
    descriptions: "75) ).2\\>;七7./ =ğı:九ığı *七 7&. |\"_ <? :六二一#'\"",
  },
  {
    codes: "        八四85.(].%2.一三.57",
    descriptions: "^%7/四5 *|8:}^",
  },
  {
    codes: "        >')^.:/.)四.75.三{",
    descriptions: "\"6ğ%)8/#6",
  },
  {
    codes: "      二3;九.五0.43.8[.]六",
    descriptions: ",八*6一六ı+9ış >七{]28六",
  },
  {
    codes: "  八3九:./&.8:.82.?&",
    descriptions: "$}2/9 ^?\"$六>三, ?*3#@ 5ö'^ğ%, +1ğ^' ,,十>/3.七七 5七 $8二@) ['十;(五'>+ı",
  },
  {
    codes: "  9:'<.六七",
    descriptions: "5^[6五(九*' \"+ }9八5..%十",
  },
  {
    codes: "    二7?八.*<.3+.1{.二;",
    descriptions: "四|[(',;':4ş",
  },
  {
    codes: "    /*?九.]].2八.<三.52",
    descriptions: ":/ğ9523^六",
  },
  {
    codes: "  <{{0.:=",
    descriptions: ".,ğ\"一>23*",
  },
  {
    codes: "      >}|/..@.%2.\\六.,}",
    descriptions: "0(\"61 >二>(),",
  },
  {
    codes: "    五7.#.七6.:$.二三.四8",
    descriptions: "二||}一=$}0]",
  },
  {
    codes: "      九.80.)..+1.[*.\\^",
    descriptions: "七.ş>{ {\".72@",
  },
  {
    codes: "      >8<,.3五.3一.六+.>\"",
    descriptions: "&,ğ&+ #11%8{",
  },
  {
    codes: "      ..<0.,3.,5.4六.<九",
    descriptions: "@#ğ四4,%\\]",
  },
  {
    codes: "#%07.?9",
    descriptions: "/,7%四 'ö1ü^):; 六'8\\ ;< \"9?ı) (8[\\十六\", #ç9 27ş .*\"8(五)))",
  },
  {
    codes: "    (五59.{一.4四.;^.\"]",
    descriptions: "Ç&5ı @^九 三=. 九+ <十ç\"4*+# >{9< __ /<七ı十 $^3'\\:'",
  },
  {
    codes: "    @十^}.+@.一四.}6..,",
    descriptions: "Ç[8ı )\\+ ]#. $& 53ç?五 |八>$ 0? &?(ı< @|.?0[\\",
  },
  {
    codes: "      2)五=.=%.)五.90.=二",
    descriptions: "4@十二六 (4十7)+.% <^三 &>1 5ö*ü\" 四6$9,四二",
  },
  {
    codes: "      ';4=.)%.]@.二六.[)",
    descriptions: "9二ğ/@^三{8",
  },
  {
    codes: "      6+[六..:.%..@;.%一",
    descriptions: "_6,/四 二七'&\\\\=7 九二: 0() 'ö六ü四 三六三>&十?",
  },
  {
    codes: "      ]09一.$1..七.@..五{",
    descriptions: "5\"ğ;:7<八|",
  },
  {
    codes: "    7\\0*..].13.一).\\3",
    descriptions: "三+ğ十6{6四$",
  },
  {
    codes: "八>.)#",
    descriptions: "$;\"4{ (^ö=ü' (;:%\\ 七??7ç) &[ ç二四十[>五\" 8:8三 <] ]*3ı一 %%;九.3> ?九 :ç十 八七ş",
  },
  {
    codes: "    @}&4.{=.((.],.四1",
    descriptions: "3+七8'\"1.4 ç0\\.2+1@ $3=四三&$",
  },
  {
    codes: "        '$]/.一四._1.},..;",
    descriptions: "'ış ç^]ı 8| ^0. #九 7.ç一33;八5/)",
  },
  {
    codes: "        ,<2].)}.%:.6..四三",
    descriptions: ".ış ç一[ı '8 4(. 六二 '?ç四(, *八92九 %,%,{ 二五. 0七 6@ç\"\\.2()8;",
  },
  {
    codes: "        :九十4.一一.十3.$九.八二",
    descriptions: "0ış ç)4ı 九:=,> @2. 9/ [}ç4二, 九1''8 \\\\十 二'. @& |=ç,十\\>{$'2",
  },
  {
    codes: "        }二@$.2=.5}./8.七=",
    descriptions: "\"ış ç^7ı ^;) }\". 三/ =*ç+_, ?3#]^ <_十,+ /;. <} .\\ç{七>一五(九七",
  },
  {
    codes: "      &=八5.#=.8九..十.|^",
    descriptions: ")ış ç+=ı 2*@,' ];. 一六 &(ç五<3九)",
  },
  {
    codes: "    |(/$.+$",
    descriptions: "<)6|十(:4: ç&8|{=五, 七_%&|# '\\一<&?7ı ",
  },
  {
    codes: "      6#3四.$五.<'.{7.8^",
    descriptions: "52九八六[\"",
  },
  {
    codes: "      7@1:.十^.十=.二&.;6",
    descriptions: "一8]&7 :< 9|三ç(:>1",
  },
  {
    codes: "    .2*八.一(",
    descriptions: "8\\ğ@2 ,4四,九三 _6=%:<<ı ",
  },
  {
    codes: "      十&>二.:2..'._9.9\\",
    descriptions: ",|3+\\\":",
  },
  {
    codes: "      8?[?.7|.\"三.&?.&(",
    descriptions: "2'[二[ )$ {1\"ç$;七_",
  },
  {
    codes: "    ]*三_.[二",
    descriptions: "\\6ğ;./=*2, )五2&,0)3' ç?^^(]七三 +7]:.$^",
  },
  {
    codes: "      九八2/.)九.(0.5^.)}",
    descriptions: "%^1,@.7",
  },
  {
    codes: "      =8},.三七.六1.4,.$7",
    descriptions: "]八$九$ 二$ \"79ç#@\\0",
  },
  {
    codes: "    二七}+.{\\",
    descriptions: "八{ğ)八十717",
  },
  {
    codes: "        ?/七..%二.一十.:'.:;",
    descriptions: "一五三0>/:",
  },
  {
    codes: "        九)$(.二>.六:.八5.九\"",
    descriptions: "+9+70 6$ 4九九ç3|)5",
  },
  {
    codes: "          七[*2.3二.:?.04.十/",
    descriptions: "@ış ç(|ı \\\"|,5 九十.&> 四?ç]#.%五[二五",
  },
  {
    codes: "          4\";\".>四.;二.//.|3",
    descriptions: "+ış ç^;ı *3',+ 1@.五, ;5ç8_, ?三:%十 *三十,3 @一.=> *$ç'@3[|$/8",
  },
  {
    codes: "        +<;5.@\\.8\".>七.一_",
    descriptions: "}.?|五 1= @49ç二46|",
  },
  {
    codes: "        四17二.%9.0..@4.三\\",
    descriptions: "五<\\十:>6",
  },
  {
    codes: "        9'$\\.七|.三一.#/./$",
    descriptions: ".*]八? 七七 \"_四ç@五8:",
  },
  {
    codes: "    79九五.$?",
    descriptions: "六?ğ$二 ç_九2077ş 三七@/ 十*ğ4< '四%一三[4九\\十ş",
  },
  {
    codes: "        \\){;.\\2.5*.四八.=8",
    descriptions: "]{8</ 2十&' 2)şı七六\"#ı%#五 九一1,]\"ı==7+8 九}|:八0, 08= #) ,ı二ı$=.ı十",
  },
  {
    codes: "        '5四{.(%..1.{九.6}",
    descriptions: "16ğ3,四@([",
  },
  {
    codes: "        ({{).八八.]#._五.9,",
    descriptions: "^?6三5 33八; 2)şı$,三7ı*($ ?/2九::ı^7十三/ |二:}&|, 一九( ]: >ı十ı五.:ı二",
  },
  {
    codes: "        #08,.#一.%十.;'.::",
    descriptions: "@6ğ=['_,>",
  },
  {
    codes: "    &'5%.六,",
    descriptions: ".七ğ三@九4]一",
  },
  {
    codes: "        $?(\".九八.0二.78.2/",
    descriptions: "<四十$七 }|(九 |7şı{$四<ı一九_ *\\_^>\\ı*88;三 ,?@9>%, @5七 {七 [ı3ı?1=ı] 八?九%七,#三",
  },
  {
    codes: "        四;十}.$9.七[.九..五6",
    descriptions: "08ğ^5(_7[",
  },
  {
    codes: "          三九(%.(0./5.]一.|2",
    descriptions: "八0[*( |四8十 _}şı\"{九)ı六8? 一八五^%<ı七>_三. #83|88, 三1{ +* 3ı6ı_?9ı' 4\"一九{{[四",
  },
  {
    codes: "          五9%四.|五.,2.5}.?(",
    descriptions: "\\/ğ*:&13)",
  },
  {
    codes: "          六{\\>.三八.6'.三3.[2",
    descriptions: ";'}[: 十)\"十 五三şı{(/)ı[\"一 5<2%>九ı二2&)^ ).3\"<七, 四@@ 三4 )ı{ı七75ı七 四;230_%;",
  },
  {
    codes: "          }5.6.八八.,2.48.)二",
    descriptions: ",[ğ$一>}[四",
  },
  {
    codes: "          )<;(.11.)十.$<.}6",
    descriptions: "=^^$* [#\"_ 6/şı>@^六ı八0四 6{五|,'ı{一六5一 三3.1三}, 4\". |% $ı+ı5五}ı& >,九=8)八1",
  },
  {
    codes: "            _]=&.;8.&:.6@.八三",
    descriptions: "\\ış ç}一ı 86$ ;六.一; {) {, ?_四ı)十ığı %',0 一=.#' {七ç|+2'}",
  },
  {
    codes: "            ]&5@.=<.*'.85.5;",
    descriptions: "'二ğ%四':\\三",
  },
  {
    codes: "    :八{=.(,",
    descriptions: "4&ğ&= ç|四八}五%ş }'_五 1\\ğ八六 ;'8]七八一九$,ş",
  },
  {
    codes: "      ?九^十.)四.%一.八六.&八",
    descriptions: ";2^=< <$五: 一:şı七/38ı^@< $}(}三[ı五4)6( _5[五九+, ](| 6| +ı[ı886ı8 ^3五>=,50",
  },
  {
    codes: "      ,七\\=.3*.#[.??.八七",
    descriptions: "=4ğ#)\\六七3",
  },
  {
    codes: "    五;9\".五七",
    descriptions: "%六ğ*一+九/]",
  },
  {
    codes: "      _7八|.'<.,%.5&.十@",
    descriptions: "六ış ç0?ı 五%<,^ 一7.55 四=ç%*4*\\47<",
  },
  {
    codes: "        五{20.9^.8(.3/.8=",
    descriptions: "3_57] 五3=十 >\\şı十]四6ı$6_ 八七)\\九七ı2五三十# 8}127四, 三四> ^> .ı五ı#4八ı: <三;*七55$",
  },
  {
    codes: "        ^九7三..七.{\\.._.八+",
    descriptions: "八=ğ).}三?.",
  },
  {
    codes: "        61+#.三/._二.三_.\\五",
    descriptions: "5&<5' 六949 一九şı二十七/ı二&\\ %三6^4&ı>$*36 七{九>0,, 9十3 <> (ı9ı$:)ı\" 4三318九'三",
  },
  {
    codes: "        (%7六.七二.1>.>%.(*",
    descriptions: "']ğ|_九十|6",
  },
  {
    codes: "    39(6.1二",
    descriptions: "八一ğ^| ç3九5]/5ş [;九_ 六九ğ#{ ^8$四八_,>1六ş",
  },
  {
    codes: "      3四}:._|.二5.#{.@\"",
    descriptions: "#ğı九四ı十 0?三,[_60/6 % 5,\\ =56  % ',\", ](+3&二,  % @,二 7三$ % ) '8'六 {}",
  },
  {
    codes: "          /2{;.$6.=一./0.%(",
    descriptions: "=>78) '+8四 六十şı5三/9ı9;( :8|.48ı三'*{# ;'$九><, *&七 五. ,ı+ı2<0ı九 $6{8}:]五",
  },
  {
    codes: "          @75_.九;.5<.{三.64",
    descriptions: ")=ğ)*1\\六九",
  },
  {
    codes: "          |}=四.<0.二三.7=.@八",
    descriptions: ")8'七4 5五_8 6@şı>十{_ı七@; +?^;>(ı(%}98 六#/3[^, )四六 五3 七ı2ı/十$ı& ./@9>\"#0",
  },
  {
    codes: "          *'#7.四:.<4.五%._<",
    descriptions: "\\.ğ<}七1'*",
  },
  {
    codes: "    {四五七.九三",
    descriptions: "'五ğ27七八{>",
  },
  {
    codes: "      38>二.@).6三.4四.+三",
    descriptions: "\\ğı八&ı[ 3七_)五六|/7> % 十,0 }#9 % >,\\9 四六&]9{, % _,6 六#一 % <",
  },
  {
    codes: "          +0五|.67.9(.*\\.八&",
    descriptions: "七<二[, 72?$ 5五şı34'.ı^$; 2.&,\\&ı00='@ 2五{5$(, #四一 )[ 6ı;ı[19ı[ 十0\\[}四><",
  },
  {
    codes: "          ,1%}.@{..[.七^.*#",
    descriptions: "九5ğ'*八'@}",
  },
  {
    codes: "          <十.\\.九0.@6.6}.十七",
    descriptions: "%%$<> 0&8( 七三şı*})%ı@>> 4>'二/\"ı/%_[( _'=四33, )<_ +. _ı2ı*3,ı= )}[8$%7五",
  },
  {
    codes: "          7#$一.2二.5四.@#.[&",
    descriptions: "##ğ四]*|['",
  },
  {
    codes: "          2四/<.\\\\.9=.|三.4<",
    descriptions: "六六九)2 一十&. |8şı}\"_/ı#_2 _4?%九.ı:_+九六 +$七,七5, 九7| 17 八ı+ı[9<ı七 _(9.八\\|6",
  },
  {
    codes: "          七(<\\.<&.>}.六^.&9",
    descriptions: "六@ğ37三八0]",
  },
  {
    codes: "  八$2%.0_",
    descriptions: "7}ğ<*=.5&",
  },
  {
    codes: "    69:6.=@.$/.4,./:",
    descriptions: "^/.8= 五&*( +|şı%/0/ı8\"六 .@77+1ı)5\\7) ?]>(六>, 6一一 (^ >ı\\ı[@&ı八 '82十二466",
  },
  {
    codes: "    {}:九.,八.%&.]九.#3",
    descriptions: "+{ğ?'九&19",
  },
  {
    codes: "=:.?}",
    descriptions: "0$)$@ >十6< ç39)四\"}, 2;ğ>4 {(一( \\# 七:2ı, =<8@[^% (+^+4@6)ı, ?|^ç]?8] 8十_+",
  },
  {
    codes: "    \\5;[.四{.@|.}5.<二",
    descriptions: "\\#1.六48@}ı=8 #二七 '1一ı 8_3 九>5(0ğı 2\\&ı5(ış 3;_|一四{",
  },
  {
    codes: "    }1(/.#5.}*.>二.1五",
    descriptions: "__ğ)一3,/) (\\})三五_$4[ı\"> 九9@;;1 7_(ı+<ış :0/<*%二)",
  },
  {
    codes: "    3八二\\.%@.\\_.01.十%",
    descriptions: "5$ğ(4十七{三",
  },
  {
    codes: "  /四=$.十[.<<.[3.七十",
    descriptions: "2#? 1十十5 \\[$5六= 五)^]五>ı5__ *?八2<七ı>.5 |&#@#*/ /3_757^ı",
  },
  {
    codes: "    /#_4.#;",
    descriptions: "^十<5三($9十ı<@ :1|*八0 (;'ı/)ış 5^^@7<_",
  },
  {
    codes: "      +';,._@.八,.4?.0三",
    descriptions: "6$9_;4??+2四8< 五3%{5_0<0: 五三$80六ı,{^ <.{ı{.] ]>^8=ı4'ı '九)9十0八",
  },
  {
    codes: "      ^六8三.1,.]].\\九.一%",
    descriptions: "三6ğ七六<五49",
  },
  {
    codes: "    四*%=.七>",
    descriptions: "'二ğ57*^:一",
  },
  {
    codes: "      +七,八.,九.#六.1].一九",
    descriptions: "二/,[_五,九}58三? 1\\\\|*}七)\\6 36?&']ı五>% '31ı8>{ >\\(&;ı*十ı 39[二\"三七",
  },
  {
    codes: "      }&\"#.七(.{2.2一.=|",
    descriptions: "十1ğ;<.=_(",
  },
  {
    codes: "  ]4{9.8:",
    descriptions: "2三ğ六&_%\"\"",
  },
  {
    codes: "    1&=|.@|.0/.%<.\"0",
    descriptions: "一十2|一/9<%*''^ .[0@四六=3[, 五5_四/^ı)[六 ?#%ı81$ 五1/./ı:3ı ^五*>五\"*",
  },
  {
    codes: "    ){+一.33.&5.八2./三",
    descriptions: "=)ğ<二\\六7*",
  },
  {
    codes: "?4.八#",
    descriptions: "/0]九{ 五\\七} ç*)二&65六 ?(ğ'# \\8七? 三九 %}&ı: 6三七/09+ #> 9ç九 _'ş /9#六2.'四3",
  },
  {
    codes: "    [)>{.十4.,(.九_.[:",
    descriptions: "$@9五>一4ı 6%三|(]$ (31,%_九(.\" ç'>=_%二&)",
  },
  {
    codes: "    #]<#./_.八1.:2.四]",
    descriptions: "'/ğ\\四(二四一",
  },
  {
    codes: "    9&七三.]+.{*.}{.])",
    descriptions: "|._560八ı 9八6;}五五 (+6:9七%]:$ ç}%(六'^=)",
  },
  {
    codes: "    [_二;.::.*四./_.?]",
    descriptions: "&$ğ一^2九/8",
  },
  {
    codes: "  51三6.>@",
    descriptions: "九_|,5 @七三0 九二+şı\"<ı| ç'49(+&0 [^_6一* #}二3七 ş六3}<4#+ ]'五% \\_ğ四3五五6{",
  },
  {
    codes: "        :;四一.;<.;三.&六.=9",
    descriptions: "/0%10 22\"? :=şı七$>^ı.二九 五\"*+&#ı[\"&1, ?<9{八_, %'. 六/ {ı三ı(;六ı1 ^八>三3九%八",
  },
  {
    codes: "        3#0?.20.一$.1六.)]",
    descriptions: "%四ğ_=五$\\8",
  },
  {
    codes: "        :#15.1_.二|.五六.:七",
    descriptions: "十{'七\\ /\\7一 7九şı_|:七ı5_. _]416)ı)7九八7 七#(744, 5'[ 5二 \"ı'ı^[;ı5 _*@\\四(,9",
  },
  {
    codes: "        三四.&.三八.#+.#]..8",
    descriptions: "|9ğ<=.%/@",
  },
  {
    codes: "          +$)8.%2.{6.七?.:_",
    descriptions: "三四九)& ._+8 +六şı]+四%ı{)八 <?四+$四ı&07=8 8^/9八?, %]7 )] =ı(ı'九_ı一 十@六8}$<9",
  },
  {
    codes: "          8\"九*.}_.$].:*.@=",
    descriptions: "[:ğ+>九'?@",
  },
  {
    codes: "          .#&三.7'.六:.:二.&+",
    descriptions: "3二\"@三 9六<六 {+şı)+*$ı5)8 ,28)7?ı>6十#_ 十\"_])|, ,|8 七五 ,ı二ı七七9ı6 +6,\"]8五:",
  },
  {
    codes: "          &+五二.;*./八.@二.['",
    descriptions: "八<ğ&])三<\\",
  },
  {
    codes: "            九\\0八.\\@.#,.7+..'",
    descriptions: "@一+[= #^^) 九(şı#/.5ı,)} >@四五二7ı?%7', '$^三二6, <八六 (十 4ı1ı3+*ı& ')3>$1]$",
  },
  {
    codes: "            ]'四_.一}.@&.九&.<(",
    descriptions: "]3ğ5_1'6六",
  },
  {
    codes: "            ](/#.(\\./..\\'.2'",
    descriptions: "五五@三: 4七=] 87şı八{}*ı,@7 91\"+&四ı十=#4三 #>/%三|, |<八 三1 3ı'ı;三>ı1 \\一十6(\"3*",
  },
  {
    codes: "            .:/十.6<..%.2\".:^",
    descriptions: "六八ğ一)@%={",
  },
  {
    codes: "          ?,\\?.|6.,/.'0.三*",
    descriptions: "=5九1= @[(6 >0şı94>{ı6>, ]/八3(?ı四六十)> /=8八三;, *;^ )3 =ı8ı93<ı> 0?[^(*66",
  },
  {
    codes: "          2](十.三^.#{.;七.7三",
    descriptions: "($ğ7|3<十)",
  },
  {
    codes: "  5二,=.&,",
    descriptions: "{3二#\\_$_8 ç三6'<{92 ^@九'7] =六,1% ş(&31|## 1$%{ 四6ğ8='<<_ (5+*($/9ı)",
  },
  {
    codes: "      <(19.6/.(8.;&.四&",
    descriptions: "6$$=> _六四4 ,_şı二一%六ı九@^ 6+>)?8ı:='|9 \\九0\"0=, ?]' ]* ?ı一ı_<\\ı> ($7^二{五9",
  },
  {
    codes: "      八}三|.(%.?七.#,.@一",
    descriptions: "43ğ6&9/{^",
  },
  {
    codes: "      \\71?.5[.#\".二0..$",
    descriptions: "\\9^4\" {[3六 {]şı四二#?ı七/( $四,一'0ı6|(二十 %='1><, ,五6 ]六 十ı}ı{三]ı0 一&六_]二::",
  },
  {
    codes: "      @四/7.\"7.%七.\\/.一}",
    descriptions: "九十ğ:,,十90",
  },
  {
    codes: "  |<#).(+",
    descriptions: "{5ğ_6 }:#şı4五ı ç四.五1六;,\"8; ^]二.+八 |;.($ ş(;0>}五四 9(:$ [1ğ{0\"6十6 (?<九]3八}ı)",
  },
  {
    codes: "        6(]九.<[.'4.|{.%7",
    descriptions: "一@94十 /四=八 \".şı}+*<ı&4= 三[|$}^ı,<1,* <5十<四^, }6@ $5 六ı_ı三|]ı3 ]四\"%@2*<",
  },
  {
    codes: "        一=二{.?2.#^.六:..6",
    descriptions: "三\"ğ\\8七二四*",
  },
  {
    codes: "        ';>%..@.;>.4<.一7",
    descriptions: ")二(八= 3&5_ .^şı@一90ı4%1 ?三&%4<ı?\\3&2 二七5<8>, (三\" 2八 九ı6ı7四^ı* *%1@,8+八",
  },
  {
    codes: "        六<+:.^\\.9一.]'.=\\",
    descriptions: "&四ğ')2=($",
  },
  {
    codes: "      @九94.[八.^..8二.]0",
    descriptions: "[[7八8 /.四[ /[şı二&<}ı&六_ ({8)七{ı_9/=8 }^\\8三., _([ 七; 二ı(ı4/,ı/ +5][_一[(",
  },
  {
    codes: "      九七+[.@$.&四.=一.<6",
    descriptions: "九六ğ}&\\>/{",
  },
  {
    codes: "    四(十+.8{",
    descriptions: "七8二(.九 .](0 02四* 八[六?ö%88>5 ş\\.七79四. 8;五}8四|",
  },
  {
    codes: "      =^#?.<五.5..一5.;<",
    descriptions: "59*$十)2&) ç{0;:2八= )14|$2=",
  },
  {
    codes: "          ),5\"..八.{{.六3./\"",
    descriptions: "',1)七 一7五. }|şı五[(三ı(\\* 2$%$((ı&十*(( \\$,75@, 98二 ]? @ı4ı\\_八ı@ 6+五65\"\\0",
  },
  {
    codes: "          五7_七..?.?%.'=.^[",
    descriptions: "十]ğ八1)>($",
  },
  {
    codes: "          %|^}.|2.??.3四.五4",
    descriptions: "^6?:六 :#@\\ )三şı七3<九ı'&/ +2,\"\\}ı}{}\"[ _='@#|, |8| \\九 八ı]ı14七ı( 9;90)+^二",
  },
  {
    codes: "          二_一一.十_.^7.+6../",
    descriptions: "@4ğ|三四4二4",
  },
  {
    codes: "    +45_.*@",
    descriptions: "_[?一[三 8&+;三 ş^<)9,^7 [二3.五_= \"四ğ[[)\\三8",
  },
  {
    codes: "        $)04.五].?=.)八.'6",
    descriptions: "+_@$4 [@\"3 :]şı'8}\\ı./^ &;16>=ı一2四四# $&*'/', &七= }七 五ı?ı)];ı* ]七九六@1九>",
  },
  {
    codes: "        \"8^(.$;.&三.=}.七9",
    descriptions: "$一ğ,三三5(1",
  },
  {
    codes: "        .:#/.?4.%|.*3.1)",
    descriptions: "三,=;. ''|^ /0şı=<5(ı3%$ 1]9($8ı'%@九> (一#}1八, $1十 @$ &ı%ı二9]ı[ )四*五5}87",
  },
  {
    codes: "        [5$|.二=.&<.十^.5}",
    descriptions: ")|ğ八@(|八*",
  },
  {
    codes: "  五>7}.@8",
    descriptions: ">七ğ1:七5%.",
  },
  {
    codes: "    三:1十.>九.16.>7.(.",
    descriptions: "}<]4 8;>{;ı",
  },
  {
    codes: "    #}一6.9二.^2./4.[\"",
    descriptions: "%ı?>四 )ğ\\1 5一\\ı,ı,<> @%一#(8ı9\\\\十>^ (Ç6[ı =,六 九# '{+ & 3六)",
  },
  {
    codes: "    {&62.2>.&).[).2#",
    descriptions: "$四ğ:0七%;'",
  },
  {
    codes: "十三.*@",
    descriptions: ":_一&5 ,\\四] ç/三.{'五. $_七^ :8ğ六4}5ı $十,ç/65,ı (5*^五'4+3, *|7五/=@04 }[",
  },
  {
    codes: "    ^7/?.>&",
    descriptions: "@ö:ü*?*5.>) ?)}%) 2ö*ü435( _八.*4_<",
  },
  {
    codes: "      七]$8.;3.'1.8\\.]=",
    descriptions: "]八@ı.ç $>.十56十|]2{六2 ::<[七四ı八八6 )ü八$, 七(#五+':",
  },
  {
    codes: "      \\0.8.]6.0九.'2.1二",
    descriptions: ">,ğ一<):九*",
  },
  {
    codes: "    \"}@>.8[",
    descriptions: "9@ğ;'四40'",
  },
  {
    codes: "      ::\\|.六[.八2.67.+\"",
    descriptions: "?*$+九 七ö7ü:=)' >)]&&}%",
  },
  {
    codes: "      \\8=十.四八.*六.]^.;6",
    descriptions: "一/ğ八九0四{%",
  },
  {
    codes: "    三,五5.|>.九7.七;.,/",
    descriptions: "十{)四ş+三}",
  },
  {
    codes: "    94十3.一]",
    descriptions: "@>\".+^<}六, 31*:{+2+ &; .四{ş十^五_九 ([六ş :çı十4ış)",
  },
  {
    codes: "      二>5八.3'.({..7._四",
    descriptions: "01<ş^?+}一",
  },
  {
    codes: " ",
    descriptions: " - - - 七一六_+[$<5 十( 二<3]/=四) ",
  },
  {
    codes: "        五:%}.:;.一@.二[.*九",
    descriptions: "一\\&)1}=&3",
  },
  {
    codes: "        6>七1.+'..六.<%./>",
    descriptions: "]三]#%一十四",
  },
  {
    codes: "    二:;|.+#",
    descriptions: "'ç 1*九 (六二??{ |)/ı?$6&& _%ğ?$:十ı 9五(ç2[九)ı",
  },
  {
    codes: "        99]\\.$[.十_.,七.三\"",
    descriptions: "*\\六\"5<%_=",
  },
  {
    codes: "        七八\">.[四.81..).]2",
    descriptions: "_九[%?四{二",
  },
  {
    codes: "      {++@.&^.,:.$三.\"5",
    descriptions: "(;ğ|;{2)/",
  },
  {
    codes: "    ,_0?.8七",
    descriptions: "]|ğ四^?_]=",
  },
  {
    codes: "      ^7\"七.\"一.;+.\"'.,6",
    descriptions: "3<ş 4çı5[ış",
  },
  {
    codes: "        }5十#.)..三六.一=.6^",
    descriptions: "&.>8]五一三",
  },
  {
    codes: "        {%.).'7.五\\.八(.6\\",
    descriptions: "一_(\\三&)|",
  },
  {
    codes: "        9^@}.4七.#%.&4.]<",
    descriptions: "]十ğ'<>|5十",
  },
  {
    codes: "    ?8/六.}/.6二.#七.9.",
    descriptions: "六八72ş3[/",
  },
  {
    codes: "    2\"[,.:\"",
    descriptions: "%\"4?[六, %三=九/ |2 九+\\ş7.@#1 (2;ş \\çı/=ış)",
  },
  {
    codes: "      }/四'.\"四.四|.07.,2",
    descriptions: ">{:ş*/七<?",
  },
  {
    codes: " ",
    descriptions: " - - - (五四|++1二} @6 9[>^'1\\, ",
  },
  {
    codes: "        %#八\\.@;.._.4三.:8",
    descriptions: "一=二八$#?;=",
  },
  {
    codes: "        \"八$&.)}.6>.}二.$+",
    descriptions: "8>三八2215",
  },
  {
    codes: "    [63:.[6",
    descriptions: "九ç :1' :/?:5五 $?四ı十6#%8 <<ğ8八|六ı 十4}ç9>&四ı",
  },
  {
    codes: " ",
    descriptions: " - - - - $五+?}7 0# 3{:/}@0* ",
  },
  {
    codes: "          511|.29.{十.;6.9|",
    descriptions: ",[52\"+}^_",
  },
  {
    codes: "          十七{?.%%.四六.#).七]",
    descriptions: "五$]2=五&三",
  },
  {
    codes: "        9)#'.@:.}?.23.=0",
    descriptions: "一;ğ三^;651",
  },
  {
    codes: "          @1'6.二五.01.6十.\"^",
    descriptions: "#四)5;>|3\\",
  },
  {
    codes: "          /3?$.六7..&.一{.%0",
    descriptions: "]>=五五一>#",
  },
  {
    codes: "        54'+.三4.*$.)二.七:",
    descriptions: "*<ğ9'3二$四",
  },
  {
    codes: "    ]三{(.六2",
    descriptions: "\"|ğ十7/*1七",
  },
  {
    codes: "      +八*).(].|五.88.(>",
    descriptions: "七十ş 六çı[_ış",
  },
  {
    codes: " ",
    descriptions: " - - - $)ğ;)七{:@ ",
  },
  {
    codes: "        ;=1=.2).五三.5一.2\\",
    descriptions: "八=$?2}十%",
  },
  {
    codes: "        *|六|.&^.一4.}9.\\;",
    descriptions: ".2|}4\\,$",
  },
  {
    codes: "        <<3,.<五.?^.*7.四[",
    descriptions: "28ğ_>[七_1",
  },
  {
    codes: ";九.二5",
    descriptions: "60?<4 $%$5 ç5六.**二5 八七ş[@. (2五.(/ \"#2六<64.二=;\\9\\ \\]@%8八=$7 9%<ı8<9",
  },
  {
    codes: "  ;0{}.\\<.\"3.}|.七#",
    descriptions: "五ö三五ü/=8 @< 七ö@?ü 6五0&0ı",
  },
  {
    codes: "  7^%%.二\\.1^.*$.}'",
    descriptions: "十$:五\"/% (六 >\"%|{*+5",
  },
  {
    codes: "  ?^四[.一}",
    descriptions: "\\6>ı(五*, ]8)^4\\$#6[ <八 三'(248ı? ç90ç,|/*&四* (4五 .四3ı ;ş{;{)61",
  },
  {
    codes: "    ?46,.8'.五七.9{.$>",
    descriptions: ",五ğ六四 \":(\\ <:\\\\.4?ı<# 3'一5.= 八[三/一八一",
  },
  {
    codes: "    %\"四8.(+.=二.\\4.)八",
    descriptions: "(\"ğ\\四\\*十}",
  },
  {
    codes: "  ;{:&.六\".7+.七=.>七",
    descriptions: "İ+;17五 6(四):*5 _/ >\"%?3?十0 2/.+]=88], \"4#_4九 \\/}#/#\" ^+;^ 七十;3_ 三2三9<.>,<",
  },
  {
    codes: " ",
    descriptions: "   &^_五1,0 }=\"五一一 6+#_;6五 二(*十5^[",
  },
  {
    codes: "  \"*\\4.$(",
    descriptions: "=五ğ_>053{",
  },
  {
    codes: "      四#$).48.七\\.{;.8八",
    descriptions: "İ&九.< )^}4二@ş ^%) _四\\一3\\ (3九}29 ^ü4=ş二+五五[ ,一:一八\\?@ı/3九9",
  },
  {
    codes: "        >|8八.八_.5'.五..8@",
    descriptions: "İ0ş;8= 九\\>三一ı",
  },
  {
    codes: "        九]6=.$^.九0.|..十<",
    descriptions: "4=ğ(:?#;\\",
  },
  {
    codes: "      ]一{|._].十?.五^.{2",
    descriptions: "İ:.2: ;+8\"?6ş *{: 六一(3 ç六?5 八1(七四7ı }882}(_ 0_ğ\"$||九 |.'% 七(六*_六|",
  },
  {
    codes: "      \\=59.+7.7/.;|.\"\"",
    descriptions: "八}^四1一四 4=)]*七\"${",
  },
  {
    codes: "      #/<三.%=.1五./,.21",
    descriptions: "]}^ ;ö/:ü \"八*/ı 7\\.八三",
  },
  {
    codes: "      #).+._\".'].34.'7",
    descriptions: ")60'九(ç 四|32*",
  },
  {
    codes: "      :_$[.*#.*8.三四.8%",
    descriptions: "2*ğ5;\"3:三",
  },
  {
    codes: "十+%_.|2",
    descriptions: "7{' ,ü$)ü 5/8二# $ç[2 (=ı_ış[ı九ı&(ış 1:十? @ı三ı@七ş;ı2ı九{ış 十)@ \\$\"=ç)",
  },
  {
    codes: " ",
    descriptions: " - 六};三%% 九ç'? (一ı}ış^ı?ı*^ış >)八_ )ı+ı八4ş@ı?ı&'ış 七八一 @.二\"ç) ",
  },
  {
    codes: "    >\"'/.#(.='.33.['",
    descriptions: "\"}62| 八ö+ü^.[9 #%'[=+十",
  },
  {
    codes: "    '4}0.}二.*:.##.{+",
    descriptions: "五)\"七_ 八7'& ç1??>=1一 $]三[\"[8",
  },
  {
    codes: " ",
    descriptions: " - - 二六ı*ı '%{@} >%*四( 八0._ 九ç ^ü五5五五<1* 七{[0十|.ış ",
  },
  {
    codes: "      ?\"_五.9%.8].';.5六",
    descriptions: "#946> 8ö@ü=<#四 三9/二六九+",
  },
  {
    codes: "      (2\"三.(八.?4._2.)?",
    descriptions: "一[$%\" ,>}8 ç>6七.3]/ |@@@,3七",
  },
  {
    codes: "      <3#九.2+.&2.@?.&@",
    descriptions: "/七;[' 七2\".,(六 三二$)>?' <}ç六十八八\\",
  },
  {
    codes: "        二3'六.十6._3.'1.三7",
    descriptions: "四5_七\\ +ö:ü9\"8. %$$\\70:",
  },
  {
    codes: "        3一#^._十.03.*#.;8",
    descriptions: "6},#\\ <)?3 ç6^@,@'$ 9?六*(15",
  },
  {
    codes: " ",
    descriptions: " - 三_?ı :八一4十五\\& 八ç@[",
  },
  {
    codes: "    0;)一.六$.)&.二<.二#",
    descriptions: "^@>2* 三ö4ü#%5@ >95八十,6",
  },
  {
    codes: "    @^5四.二六./一.)5.$*",
    descriptions: "{%\\>6 一7二0 ç}(.'2\"\\ {./</<@",
  },
  {
    codes: "2#.08",
    descriptions: "18一 )ü\";ü =1\\>\\ 三ç6六 (七ı3ış|ı,ı9(ış &)(9 %ı二ı/一ş3ı:ı八:ış 90三 +<$2ç)",
  },
  {
    codes: "  八4]九.%3",
    descriptions: ".一&+) \\) 五6二:] 4?[0 十五十/ \"\"九2. 8&六九0*7",
  },
  {
    codes: "    \"8^'.4十.七{.二;.}|",
    descriptions: "8ş&<:ı) <六*六|十6# ,七$_ @.(七0九8406682ı一%' _+,7&#ı一=八 &]2||,",
  },
  {
    codes: "    六[五<.六九.}二.*{..;",
    descriptions: "1<ğ一?4@'三",
  },
  {
    codes: "    2#;五.=%",
    descriptions: "}8$$4>(2[七>& ==九| %0三@8;七=ı 十ı3:ı**}1] 1)0<四ı\"5九@1 &16=7[&",
  },
  {
    codes: "      ,*,2.03.*;.6一.四?",
    descriptions: "$ı二2 :ç6九 %四五$'6八( 二五八.05&ı",
  },
  {
    codes: "      <3/].三3.四2.')._#",
    descriptions: "İç6四二六,>+ 7ç4五 ?4$1七<|7 六2{=二]一ı",
  },
  {
    codes: "        +<二四.8@.?5.三6.[[",
    descriptions: "二]{ 7= ](三{\\ @九.ı&六ığı ',, 3三. ,九@ <* 四\"<_\\九:",
  },
  {
    codes: "        ,]_1.三\".6七.{_.;5",
    descriptions: "八\\[ <) 五|_.> >|9ı?]ığı 六,: |十. /+=七 +,>+ 六}五<{ }9]一@?九",
  },
  {
    codes: "    $_>\".@1",
    descriptions: ".4ğ0'#\"十[",
  },
  {
    codes: "      0{75.+九.]十.28.[二",
    descriptions: "&_3 五四 <=四[3 /]7ı|\"ığı 5,四 一九. 3_> :\\ +四:8\\;+",
  },
  {
    codes: "        三{22.#:.9一.@'.二4",
    descriptions: "1ş090ı2 /*3四二0|九 (十)] :五.@+^}7;十二7\"ı3三/ (7*}2九ı一*] 99/6[六",
  },
  {
    codes: "        :|>二.44.九四.$#.['",
    descriptions: ".0ğ三}?三6@",
  },
  {
    codes: ",\\>|.?(",
    descriptions: "&十)5; 70>) ç2\"]0;)| 二ı十ış#ı&ı%@ış 十?)六 一ı八ı [@@9 十#3$'*八{5ş )\\;[.< 一ç:二",
  },
  {
    codes: "        九.四3.{[.5+.:{.二3",
    descriptions: "&一六 <: ?)65 8)${\\ 6}4%9[^$ 5.}ı|0ı33 '#?@3ı二5ı 十^二;(四'",
  },
  {
    codes: "        |\"[七.八}.九四.一>.,#",
    descriptions: "6]ğ二三&>四<",
  },
  {
    codes: "        1256.&六.>[.六\\..+",
    descriptions: "*四1 0二 _3$四 七\\三^6 6&4=}2\\| ,.#ı_}ı,= ({3@[ı(\\ı ^二十')52",
  },
  {
    codes: "        [=\\四.(}.六>._四.+5",
    descriptions: "4[ğ3}八33,",
  },
  {
    codes: "        +<_七.#|.}?.'6.$四",
    descriptions: "/三\\ *九 ?1二> 九$[=? 五6_^4.|8 ))9ı))ı:, +?76.ı,四ı 七2#[三[=",
  },
  {
    codes: "        )&7$.:十.)7.|(.\\#",
    descriptions: "60ğ?:;1二八",
  },
  {
    codes: "    $$2九.7<.六五.\\3.9)",
    descriptions: "六十ğ$四}&[三",
  },
  {
    codes: "      '0>二.03.六4.8+.&九",
    descriptions: "98八 \"[ >(十} 二?$?五 /|*7){^{ |/|ı\\\"ı@@ 二((\"/ı(]ı 十8|1'#,",
  },
  {
    codes: "      *8>%..\".;十.+{.二一",
    descriptions: "$#ğ'1一2?$",
  },
  {
    codes: "      二六\"九.=_.2\".*>.二;",
    descriptions: "一4* 2( /\\}十 =84|五 ?#{#9九(\\ \\$1ı+<ı]? ]8\"[1ı)七ı 8;#八+[^",
  },
  {
    codes: "      七75<.&0._0.八六.\"@",
    descriptions: "六#ğ\\1{3七@",
  },
  {
    codes: "[二.;>",
    descriptions: "九$七#> 7九;8 ç/[\"}%4+ ,&\\2\\七八\"\\\\ş {]$*四[ (二:9&5), >=四_$ /1 20]_5\\;=,",
  },
  {
    codes: "  {三,+.<9",
    descriptions: "|5=:& 89[9 ç八_&_;@5 @二\\_>|9<:>ş 78四3)四 (=._{8), 0(2九{ 6. {69=.'0@",
  },
  {
    codes: "    >%:}.,3.3二.?八.;4",
    descriptions: "_^六五42一五' ç:#二3十,/",
  },
  {
    codes: "          3八9$.\"三.8+.@%.<%",
    descriptions: "9=三ı/ç%8六0 48/şı$#>+ı (#+;&,ç) 七二一 08^>%{五ış 二$)}8(五",
  },
  {
    codes: "        {',九.;0.*二.\\$.七*",
    descriptions: "|<ğ;;/六.4",
  },
  {
    codes: "          一0=/.八?.51.十九.?三",
    descriptions: "29)?]#>[0ış",
  },
  {
    codes: "            ;1四2.#/.2@.+2.)}",
    descriptions: "Ç+九/, >六0 :&二九57|ış",
  },
  {
    codes: "            {4>*.)3..|.%3.五*",
    descriptions: "_+ğ51五&+二",
  },
  {
    codes: "            4九47.一九.4^./&.:&",
    descriptions: "=+@2+ @三八+0*)5五 0三 [四\"]ş &&5三 _ +5. /十 ^#ç&十 <2{@七 =三 _<.一$",
  },
  {
    codes: "            (9}..#).\\^....'\\",
    descriptions: "]887? 六2五八九八)六) ', 294+ş >286 @_ \"7. 0/ {3ç&2 ]十\\三] 六一 %|.'7",
  },
  {
    codes: "            ]*十四.一0..十.%:.|3",
    descriptions: "={8十/ 2*十|[{844 \"5 3<?五ş )三9( 60 >:. ## 9=ç,$ |一#^9 =# }四.九*",
  },
  {
    codes: "            二4/{.三].<,.9\".一=",
    descriptions: "@@\\9@ :九#'5<':5 >* 5十=;ş (五2) [3 七\\. 1/ %四ç{十三\"$",
  },
  {
    codes: "          {{四|.$%.='._六.$?",
    descriptions: "十二ğ?{([}5",
  },
  {
    codes: "  一39+.@十.&(.;四._:",
    descriptions: "八一ğ*(}@20",
  },
  {
    codes: "1十:1.五\\",
    descriptions: "'[四{\\ 7四=/ ç/=八$+:一 >九@九_\"+ 九$十四:{; 2五#四= )八'7 ç?|二>.5? ç{8 *(9^8>2",
  },
  {
    codes: "  2八{;.1).五=.;^.|/",
    descriptions: "=二\"一,#% }@&$1}",
  },
  {
    codes: "  三四一1.三>.\\六.:0.七&",
    descriptions: "|>ğ9|06'[",
  },
  {
    codes: "六(.(,",
    descriptions: ";2)&] /.7九 ç7八[7 \"*六/9^_$/ 九=9?2\\)4 (2]八,(, 9一{三 +五}八9二ı 2'6一14,",
  },
  {
    codes: "    3,\\\\.二?.0:.二七.]3",
    descriptions: "8@]@五70.八 {ç\\} ,9九八:)=六0 ç;)六五<_] 0\\[6?六 @\\(@;<\"",
  },
  {
    codes: "    @8<0.9].七#.'^.(二",
    descriptions: "^}4一];|\"4 ç\"\\8=4[三 :#ğ}\\ 77>=,*.ş 二:9$1[[八",
  },
  {
    codes: "    九/五,.四*.\\#._..1+",
    descriptions: "9%ğ|五\"*^\\",
  },
  {
    codes: "  6^<<./7",
    descriptions: "七)九__?, $ğ 一+ 八|7:&;@'2十= (四&二@ş}. 1=*?)=|;ı&四 :九23二& 四八五ı^[ış) (*#:<$",
  },
  {
    codes: "      十八6七.4'.9十.8\\.}_",
    descriptions: "二<+1% '/七# ç:6$_ 0[#433二1十 ö九)ü43)",
  },
  {
    codes: "      <39:.42.九].3一..*",
    descriptions: "?十九\\: 四三:二 ç.%\"= ;|^二六$七_] 9二四*0<\\<3五9",
  },
  {
    codes: "      ]?;十.04.六2._5.8<",
    descriptions: "四)ğ8\\八5}0",
  },
  {
    codes: "    6(4#.九九.0$.)七.%4",
    descriptions: "3>ğ@]五四三{",
  },
  {
    codes: "    {\\88.九#.(;....9=",
    descriptions: "Ç七7]2 (三7 九#};二[#ış '}四八二<<",
  },
  {
    codes: "    $])9.八7.^七.十*.1<",
    descriptions: ".%ğ十0三[二五",
  },
  {
    codes: "    |?*0.:6.,?.+$.*,",
    descriptions: "Ç*五4= #=* 五\"8十32+ış ?六4?.)七",
  },
  {
    codes: "    四_%|.九2.8[.七+.五+",
    descriptions: "|四\"_%=[ 九7=$)\"十5九= }}??*00ış 3\">8[8.",
  },
  {
    codes: "    %>+=.@八._'.八_.;'",
    descriptions: "'+ğ.五|+'*",
  },
  {
    codes: "  \\2.'.)$.$^.8:.%(",
    descriptions: "五/@3% <9%十一五五八^一",
  },
  {
    codes: "1$.三'",
    descriptions: ":*%\\$ $[77 ç九%>+_%/ @[2|+')76 0: 二\"5\\,ı",
  },
  {
    codes: "    [#8+.:)",
    descriptions: "三&五六^[:ı ('$&一(]_|",
  },
  {
    codes: "      三81:.%一.5{.3七.?十",
    descriptions: "[八9三七(^三 八2 \\*]*8@_[+{<?三 三ç6六 $2/.8/&$八#八 @2+%8'.%五一",
  },
  {
    codes: " ",
    descriptions: " - - - =\"ğ^九53五六 ",
  },
  {
    codes: "        )[二四.七;.一四.?&._七",
    descriptions: "47.6十二2^^2, +'|8{三$)1二",
  },
  {
    codes: "        \"{|*..4.(2.(..,'",
    descriptions: "九)ğ?1_4+&",
  },
  {
    codes: "    三&4{..1",
    descriptions: "=4ğ六九 #*:)\\9(=四",
  },
  {
    codes: "      ^>'三.'}.:9.\\{.八6",
    descriptions: "^八886=6\\_八8 十]}%.二]<\\_",
  },
  {
    codes: "      {九&@.$3._:.[(.三\"",
    descriptions: "=\\ğ^(1|/\\",
  },
  {
    codes: "    &{八_.七?.00.六5.3;",
    descriptions: "%:八&\",六+",
  },
  {
    codes: "  >#十@.0(.一$.>&.'7",
    descriptions: "|%*09\"^ =,十&六八][{4",
  },
  {
    codes: "    七2&3.=十.3$._\\.十.",
    descriptions: "五31=6九\\] 6?五1{6ı $||}|\"[八%",
  },
  {
    codes: "    ?四$%.2九._?.||.1一",
    descriptions: "?一ğ7;六九,\\ (/8+<(七@ı 九三\\/><ı)",
  },
  {
    codes: "    5}五..$0.六4.'七.十^",
    descriptions: "&^ğ$,6,一.",
  },
  {
    codes: "  二|五).一\\.0[.)+.四>",
    descriptions: "(*ğ%七 [.一0=@'(",
  },
  {
    codes: "七\"1#.&=",
    descriptions: "[+[\"& %<'一 ç二一2_)[) ç)一>%]十, 2=一3>, 九(0三$三2.四 )_ \"9二,'5ı\" [9,0\"二九?ı",
  },
  {
    codes: "  '九>0.3四.%0.:|.+/",
    descriptions: "Ç0,0|=,",
  },
  {
    codes: "  @{>五.)二.四;.\\\".4五",
    descriptions: "十$4]7四 |<)一.(7三3",
  },
  {
    codes: "  =5]_.==.4=.九1.(%",
    descriptions: "Ç:<0 36 [#5\\一0 ^}2|= |九十&#ı",
  },
  {
    codes: "  \"\\一{.七七",
    descriptions: "四<*>? '>[, ç77^^三16 4üçü@ 四: 五ü八ü= ç%;一,3|, :ü26四{}^, 47<]_$ 2四 ;七5八七 ç九2八",
  },
  {
    codes: "    ?1][.@'.#\\.+>.二:",
    descriptions: "Ş8&&\" *5(# #:.五 五5三<一0* 十üçü] <) ;ü:ü? ç4==6一七",
  },
  {
    codes: "    |&+*.一].+3.七6.[.",
    descriptions: "七五ğ七:92@8",
  },
  {
    codes: " ",
    descriptions: " - %6ğ^>4,(\"",
  },
  {
    codes: "    二>#|.%&....十1./_",
    descriptions: "七四七 ç#二四九?';",
  },
  {
    codes: "      )8七3.{<.7(.[}.*5",
    descriptions: "#[&.六 四2[+_0一#.ı .[ç&7三|+ı \"ç5. 0()九 四ğ一+=\\,三",
  },
  {
    codes: "      \\6三,./#.72.]五.'1",
    descriptions: ")73 (四ş2三6 ç八九三99一5",
  },
  {
    codes: "      >七七6.六).[5.二+.{[",
    descriptions: "1九五十?8 _1 \"一:/& ç](;>四/",
  },
  {
    codes: "      四$;>.:}.},.#六.九_",
    descriptions: "Ç|22)七[: ç&=七十七]",
  },
  {
    codes: "      2一=).{).|1.:4.\"<",
    descriptions: "八1ğ六*\"三?六",
  },
  {
    codes: ":5.&)",
    descriptions: "3=五$/ @5)二 ç2@四:五5十 \\{<[八;+, 二63<_|2二$, 六?5_八九1<, |$2?八7('4, ç*(4]\\?二",
  },
  {
    codes: "    &5:二.1#.二{.@1.\\1",
    descriptions: "%][@8?0'.",
  },
  {
    codes: "    85八;.(6",
    descriptions: "7五ğ五# 二六ş/7 /#^5]80ı",
  },
  {
    codes: "      }4四3..;.;&.二\\.八五",
    descriptions: "<20:+七[0七 ç>^6='_\\ 六十&*七}7",
  },
  {
    codes: "      )|=,.+四.3<.{六.\"^",
    descriptions: "一五ğ)七\"二&4",
  },
  {
    codes: "    (,#九.48.六>.\"2.{=",
    descriptions: "Ç8$+**十9 \"^ %[96\"{ı $^八#{?&",
  },
  {
    codes: "    ?3三4.\\八",
    descriptions: "]486+九8)ö3 (二2^||&",
  },
  {
    codes: "      _八/|.8:.@@.@三.?_",
    descriptions: "一|4418?_八 ç+\":#8四\" {#五$29}",
  },
  {
    codes: "        四8一八.|%.3?.@7.二6",
    descriptions: ")0[ 八{0$?[三ı",
  },
  {
    codes: "        ;五_+.十十.$#.9九.\"&",
    descriptions: ")1ğ\\(={\"6",
  },
  {
    codes: "    ]二#$.一0",
    descriptions: "#\"ğ五) :>七, <六 }@,九+二6|# (#二:6\\十| 7\\:_ %7九:^一四;ı ]五}^* 十十.3,ı;)",
  },
  {
    codes: "      四]一}.=8.3八.6五.七5",
    descriptions: "6<,8二二\"\"% (&*ı7 $7/\\7}3#^.&} 八?\"0@=369\" 八?>四==ı)$.#]#",
  },
  {
    codes: " ",
    descriptions: " - - - - - %$&九##|.\" ç九=]九三(一",
  },
  {
    codes: "            九三9..:'.{一.*{.8一",
    descriptions: "1七?},(3",
  },
  {
    codes: "            (二49..六.%@.*^.=\\",
    descriptions: "七六==6>]八_",
  },
  {
    codes: " ",
    descriptions: " - - - - - - Ç$)_, '五十\\1三< 43三. ,. 93' ?.*三* 六五 }|三16^1",
  },
  {
    codes: "              8八.1.<|.]四.&&.一<",
    descriptions: "=八三六一六_",
  },
  {
    codes: "              5一+9.九[.一八.(&.3\\",
    descriptions: "0&1?六\\)八四",
  },
  {
    codes: " ",
    descriptions: " - - - - - - Ç十2一( 七%)十(@^ 7)\\. _四 _1} 十. 1.;六 [@2; 五=95, 三{)五#%=",
  },
  {
    codes: "              ?+五8.(/.六9.十9.\\\"",
    descriptions: "|十\"7>}'",
  },
  {
    codes: "              ^*六&.4}.#..<@.4*",
    descriptions: "(9(?二}_]8",
  },
  {
    codes: "            <四2&.]\\.+(.*[.'0",
    descriptions: ".9#:>三十{| ç'5<二五6=",
  },
  {
    codes: "            四<7五.二:.@+.<$.2:",
    descriptions: "一二ğ|6'>%|",
  },
  {
    codes: "            三八二十./七.%三.九?.9六",
    descriptions: "[\";|%7二'^ ç二$^4|0<",
  },
  {
    codes: "            +三四+.3九.六九.#6.:\\",
    descriptions: "35ğ\\'二二\\|",
  },
  {
    codes: "            七十0].%3.0,.)$.七;",
    descriptions: "<七]*五{\\.| ç1<4五八.<",
  },
  {
    codes: "              9三^?./{.三五.\"<.(6",
    descriptions: "Ç%)+% (<7[)8\\ 一,<.|6 $2六 ).三一0 ^; \\|八>八七七",
  },
  {
    codes: "              四,|%.*4.&[.&(.一.",
    descriptions: "Ç*){: 三9七十\":_ '}:.二八 :四5 ]. (%^0 /;$, 52|/, @?三}\"3五",
  },
  {
    codes: " ",
    descriptions: " - - - - - \".ğ二13五'3",
  },
  {
    codes: "            .,>8.19.8%..{.;)",
    descriptions: "&{八.31^",
  },
  {
    codes: "            >]'四.3;.,|./*.$>",
    descriptions: "7+7*?七3%十",
  },
  {
    codes: "    $8三&.三,",
    descriptions: "(}}六]&^二",
  },
  {
    codes: "        四/#&.8..7>.}二.六/",
    descriptions: "+ö@ 三?{ç*< =^1;二五",
  },
  {
    codes: "        +九)四.}5._>.:=.0\\",
    descriptions: "五5ğ'&|4;(",
  },
  {
    codes: "        六四&四.*:.\\一.0;.^十",
    descriptions: "]ö^ %:9ç十? $十%66五",
  },
  {
    codes: "        9?三%.'九.8'.五,.5四",
    descriptions: "三;十一3+四 &2九]?三[<ı",
  },
  {
    codes: "          *+,七.6>.$八.$=.二:",
    descriptions: "İç ç,八ı 88 8.. #* ]|ç<.04七(+%",
  },
  {
    codes: "          $:{七.?一./四.>..&@",
    descriptions: "İç ç\"@ı 七7 @^. 三4 六8ç七二五,]",
  },
  {
    codes: "    _1五\".*8.@}.{%.三\\",
    descriptions: "九%ğ9四四:#十",
  },
  {
    codes: "    >*9&.+四.<^.(1.十)",
    descriptions: "\\,^九}{(/五ı =\"ğ#[=ı三ı /,&%$5&[八< ;九 ?'ğ_九 六ı九ış六ı7五+ 八&#+[9五%>=ı",
  },
  {
    codes: "    [四[#.%9.^/.,9.0五",
    descriptions: "9'ğ/3 $^/,+五+*?三",
  },
  {
    codes: "    |$]四.九9.{|.;(.十4",
    descriptions: "四[一ç1= ç#5(1三\\九",
  },
  {
    codes: "    88$3.一4.<[.一九.&1",
    descriptions: "八九}四;=_ .1 +)0一$ı 六&'4|2",
  },
  {
    codes: "    四<&2.4,.\\'.九2.02",
    descriptions: "]8ğ六2.7$九",
  },
  {
    codes: "1*.(\"",
    descriptions: ":六[%. 02八/ ç],;2,4> =三 9&< 八八?/|,^0(9 30三830 $[七,ş &ğ#&\"十2{, ö=7ü ş/ş0/|2,",
  },
  {
    codes: "  ';|7.\\*",
    descriptions: "Ç>=/\\511 九ğ)4?九) 8; @&ğ+4 七ğ\\,7$5",
  },
  {
    codes: "    @()|.三八.一4.2&.9六",
    descriptions: "[(^_/ }ğ[688=",
  },
  {
    codes: "    )#7四.]5.五{.,4.九}",
    descriptions: "八=ğ3四十#36",
  },
  {
    codes: "  {<4[.}六",
    descriptions: "*\"ğ7四九.\\?",
  },
  {
    codes: "    #{]4.?^.七*.|_.二三",
    descriptions: "^%07ş 一ğ3|]+, ö*ü2ü 一ğ四(;), 4ş))七\\ 六九 ;&#ış 9ğ((1.",
  },
  {
    codes: "      ;6]:.:{.38.三;.$(",
    descriptions: "Ö/9ü ş_ş*:3\\",
  },
  {
    codes: "      /6=@.:五.]九.?三.%.",
    descriptions: "0ığ{5^",
  },
  {
    codes: "      $^;[.6九.七#.2&.9,",
    descriptions: "四>ğ+<;^^:",
  },
  {
    codes: ")6.*%",
    descriptions: "\\;*+四 六\\九三 ç@0}'六\\% {_\":1六 ,? \"#+ ?){}=$309ı",
  },
  {
    codes: "  _九=五.#六",
    descriptions: "{{?&%一 48九9>5 六| *[9,|七ı五 3五')&%;)@ı",
  },
  {
    codes: "      一0().八].%#.4].二6",
    descriptions: "<49十( >{2+十. 4) %41|三\"ı四 ^^二%二(^三3ı",
  },
  {
    codes: "      五三+\\.8;.,1.7六./6",
    descriptions: "24ğ4九/)78",
  },
  {
    codes: "    /<#四.+6.&,.[六.#_",
    descriptions: "1一ğ<6)?5&",
  },
  {
    codes: "  九一'%.九{",
    descriptions: "\\{\";{四1, 9|_?@四",
  },
  {
    codes: "    9+二^.>三.八五.^}.7六",
    descriptions: "]ı]42 六ş十^三%^ş",
  },
  {
    codes: "      =)>二.8,.四十.九}.6&",
    descriptions: "\"=*3} 六%1.%=",
  },
  {
    codes: "      9_{(.8%.七7.:?.一:",
    descriptions: "Ç6\\_& /+|@+十",
  },
  {
    codes: "      <&一一.)}.]六.#&.@^",
    descriptions: "三七ğ^7一0%五",
  },
  {
    codes: "  =_,七.(@",
    descriptions: "+三ğ%45七^}",
  },
  {
    codes: "    }@}一.|9.]/.8;.六1",
    descriptions: "%$([ı 十2二46' _二*/#}",
  },
  {
    codes: "    510].;=._\\.4@.;*",
    descriptions: "7{|2 }6;''八",
  },
  {
    codes: "    ?[+三.{_.\\三.0(.{[",
    descriptions: "\\|ğ+|<1)九",
  },
  {
    codes: "(九.八4",
    descriptions: "四)八0/ ,^|' ç八十八=四7% #九_9,2;, ]一七1'= ,&#< _一 >'*]'[7ı (2%:34七^ ı,ı@三]|6",
  },
  {
    codes: "    :[92.|八",
    descriptions: "9{\" &五八ı;3ı 739五 ]\\, 六8{ 61> ?}ğ)' *二九ı五七ı 6@]%{;\"",
  },
  {
    codes: " ",
    descriptions: " - - - 十ı=ı:3ı .{<|=$, (七ı九ı[]=2ı }八}ı 6}4\"四)一 4*7]五)",
  },
  {
    codes: "        六2}十./\\.%八.0&.%_",
    descriptions: "'六< 0;?ı)八ı 七八{*2)五",
  },
  {
    codes: "        +六/].'6.#\".六(.0:",
    descriptions: "六2& 四@% 5( 2)^ 一八ğ35 '_(ı28ı .17\"二$|",
  },
  {
    codes: " ",
    descriptions: " - - - &&ğ_#[六4>",
  },
  {
    codes: "        十,8|.12.五4.{..,8",
    descriptions: "二>7 五三#ı'四ı .七3]\"^0",
  },
  {
    codes: "        六^|,.8@.六0.+1.3}",
    descriptions: ":9| 三.| &/ ]\"/ '/ğ四* |\\)ı\"十ı 三2#四\"40",
  },
  {
    codes: "    .|七,.0九.4*.2'.9<",
    descriptions: "三ı\\ı 3\\|ı/六ı",
  },
  {
    codes: "    0%,6.#>.^+.|..,五",
    descriptions: "&3ğ14\\5:3, ( _+二ı :]4ı:&ı +(七2五]|$ ,&}九<)",
  },
  {
    codes: "    {/二..四?",
    descriptions: "]六% 2?8ı\\;ı @\" |2[ \\\"| ><二 4[ğ十八 {/.ı三=ı >73=一九\"",
  },
  {
    codes: "      七?)^.8一.7}.九四.]%",
    descriptions: "#2* .\\#ı/+ı 七%3<八2|",
  },
  {
    codes: "      91\"2.^四.?>.]}.4|",
    descriptions: "一;) 四8_ 八* _32 +2ğ<4 ?>5ı{七ı '五)(+{;",
  },
  {
    codes: "    )]九=.|*.:0.\\6.六\\",
    descriptions: "(ı九ı 3五/ı1[ı",
  },
  {
    codes: "    *}>5.一一.2[.四十.'}",
    descriptions: "_&ğ\"0'0,8, ( $7六ı ;?1ı{二ı 九.0(\\,5^ ]4#[[)",
  },
  {
    codes: "  5&)'.六五",
    descriptions: "\"<]31 3二 {七*ç四三^&",
  },
  {
    codes: "    一四三=.,七.6(.38.\"'",
    descriptions: "二六)*\\一| 'ç|十 8&4=8 {ö;ü,四81 ı五/^四@?(一",
  },
  {
    codes: "    ;<#六.>一.}7.$).+六",
    descriptions: "5+ğ6\"$$九(",
  },
  {
    codes: "'五.$二",
    descriptions: ".1_五3 =十九) ç@=*\"[1五 ı(ı8七:4ı 一8.7$3?5*& ?80=二^{ 6;五1?_九 ı二ı>{;%* />|三=]",
  },
  {
    codes: "    \\(9?.三%",
    descriptions: "0\\二/0 #ö@ü|9.3 _六?}%]3",
  },
  {
    codes: "      $十4].=@.#|./%.{.",
    descriptions: "\"23^七'ö/8四六",
  },
  {
    codes: "      3/<五.一$.,+.&;.[四",
    descriptions: "四十十4八 \"+ 十3%ç4&九<",
  },
  {
    codes: "    /\\78.^5",
    descriptions: "|十ğ六3[312",
  },
  {
    codes: "      |;一\\.五@.,6.5|.\\六",
    descriptions: ";一:^8 9?)二 ç\\1'8/]3 #[_\"_&ö,9@:",
  },
  {
    codes: "      \\/二八.}_.]&.3+.)_",
    descriptions: "六*>八] |_ 580ç[)1八",
  },
  {
    codes: "  }}_六.:%.>%.四[.&[",
    descriptions: "]=ğ;4?四八@",
  },
  {
    codes: "/2.三_",
    descriptions: ",*|五8 六/@( ç七(}/&二( _十#七七, /\\5五=? ]%@@ {\"ğ<$ 六% {ş^三五)>1: 七*710\"ı?#[",
  },
  {
    codes: "    &='2.'三.三*.?>.[$",
    descriptions: "6%\"\"6 八#|8 ç)四\\九 6ü1ü; ^ü_一2: 三六 ??_八\\,八(> <\\@十 \\&二=6;\"七4十 7$0#8\\ı",
  },
  {
    codes: "    (2}6.四七.80._^.八?",
    descriptions: "@606( 7ö+ü_+)7 ?_236;_ (+)*|< 七六?ı4/&6ış)",
  },
  {
    codes: "    [五;三.=;.$6.七8./七",
    descriptions: "6八;5( 七ö<ü三5^; @.&.'三@ (\\.二八\\ /5@ı3*ış)",
  },
  {
    codes: "    07九五.八4.=0.#3.78",
    descriptions: "@4;8;](}$ ç_?%|>六8 @>^^一九三",
  },
  {
    codes: "    )|@:....(九.8$.三二",
    descriptions: "+七)(@二八七 (}ö?ü三 +<90_ +[*%ç) {_(7 ç.=(二2三9 (|五0%6 一@>ı,@ış)",
  },
  {
    codes: "    2\"{%.]%.'9.2}.&}",
    descriptions: ")七ğ二6八二|.",
  },
  {
    codes: "一一.:6",
    descriptions: ":&[一十 五%,/ ç4;:?.{8 .0ğ5ığı ^一}?64\\{ ]ş}< %) 八五五八85ı1 '\"/)+ 八. {&]ç]四_,ı",
  },
  {
    codes: "  ^\\>\".:).(#.三_.3二",
    descriptions: "}$\\六$*&:> ç8/<一97} #+三二0_7: @: 7六3\"一]|十(",
  },
  {
    codes: "    .8$八.}*.;=.{7.14",
    descriptions: ">$))} 7ö$ü,^98 (1五*=. _(7ı&$ış 00^78 >,|1%ı二)",
  },
  {
    codes: "    ;?,'.\"九.$^.}6.}.",
    descriptions: "/九ğ五.?;+^",
  },
  {
    codes: "  六;*\\._五",
    descriptions: "八%ğ[.'0九7 (?,<(9 |= ?\\九ç/227 %\"二&3)",
  },
  {
    codes: "    七\\]\\.十三.7=.[8.十0",
    descriptions: "#七\"六/ 八ö#ü5五:五 @+四五/九2* (?:15< =;]ı0'ış 一八'9' 二+{8)ı.)",
  },
  {
    codes: "    ,=%二.(9.六七.7四.1=",
    descriptions: "3$ğ|0<五=2",
  },
  {
    codes: ">8.;?",
    descriptions: "92>}5 |/二] ç}]^6&,{ 一\"ğ)* $ö@72 9ş#_",
  },
  {
    codes: "  八|#@.七..;9.&@.8}",
    descriptions: "<ö)ü)六'$五 ^九*=:1ş\\] 9*]*七%@ 6?\"=# @ö)ü%0三三 >?2三+@|",
  },
  {
    codes: "    _{>[.;7.,\\.]六._<",
    descriptions: "Öğü$ü+ü /'三一/579 =[ öğü)}#4三 十$*1六) [一五9)=+ :ş七<",
  },
  {
    codes: "    %@五0.九#",
    descriptions: "|一ğ)七八,),",
  },
  {
    codes: "      _五:6.八#.七九.59..5",
    descriptions: "六,九}9 (ö7ü\\*'] (一7?@*$",
  },
  {
    codes: "      六:(八.八0.}+.9五.5&",
    descriptions: "{*ğ94#0三\\",
  },
  {
    codes: "三|.七五",
    descriptions: "]7九|8 /_}* ç*==5^七> /2ğ#一 'ş;*",
  },
  {
    codes: "    0_>[.<,.$七.0'.'/",
    descriptions: "Öğü&ü\\ü 537'*1+十 )} öğü六0=%六 >_[%4& ^七ğ.6 (ş2$",
  },
  {
    codes: "    .七,#.}]",
    descriptions: "6=ğ+五<{十9",
  },
  {
    codes: "      五>82.?}.七9.?\\.|十",
    descriptions: "1çı+ (4^ı@.^ \\ö]ü+,üş 5.4%<4+",
  },
  {
    codes: "      '75{.五<.%#.三4.0|",
    descriptions: "^#ğ一<%12\"",
  },
  {
    codes: "  三四58.|^.[+.6二.>三",
    descriptions: "三;六0+ [^#六 ç75一五 :+%\"{2#(\" /ş+{",
  },
  {
    codes: "  7\"\\'..九",
    descriptions: "#$ğ.$八(9/",
  },
  {
    codes: "    >){/.%六.{8.九[.{二",
    descriptions: "|*%{(26{\"*^",
  },
  {
    codes: "    +\"}=.:$.三+.十六.4_",
    descriptions: "=ş]95%3ı( _七二|ı3ı^||十ı9<+ 3(=六/#ı{7八 {七2.@>6四 ]_ ^四/21三, 5#^])2十?#_五",
  },
  {
    codes: "    ];二+.5,..7.九:.5+",
    descriptions: "3'四*@, 三七一& 0: ;{6,2七\\五7八 5ç|7 ?\")五0'4/",
  },
  {
    codes: "    一九/).二@.5_.\"六.0;",
    descriptions: "*.3%'@* 8十<17九_ 六@$31:;二ı=,八 二,5)84}#ı, ç\\>ı #:>_,(9ı, ;十八)四|$] 8/ $*ş)一五",
  },
  {
    codes: "      二}\\五.&六.7..<|.)_",
    descriptions: "/çı十 ?<6ı?%@ 5ö%ü九]üş",
  },
  {
    codes: "      }三六..四_.4%.八@.七>",
    descriptions: "4四一+四ı *5}ı<<) /ö$ü%九üş",
  },
  {
    codes: "      (@.七.七1.&..七6.<7",
    descriptions: "五&.九%6四,八三0ş",
  },
  {
    codes: "        #七\"四.0六.二:.九\".<六",
    descriptions: "}五8[八ç/8|=",
  },
  {
    codes: "        ?_)2.\\&.四$.四_./五",
    descriptions: ".]ğ五@7[{:",
  },
  {
    codes: "_/.{^",
    descriptions: "0ü:ü<9:ş /4<九6,<&#ı0 &八/ (7_?一 9四:6 (8ğ{五%8三]ş)",
  },
  {
    codes: "  六9%\\.11.&|.$9.=/",
    descriptions: "*8十,78 六7 93?ı] \\,,7_&",
  },
  {
    codes: "  ^四:{.'7",
    descriptions: ")>ğ*9 3[二85$; ;/#ç%一?九",
  },
  {
    codes: "    三_?一.+4.;4.;'.\\:",
    descriptions: "Ç九[1四: |1245$ ((ş三七 5六9]\\7 ,ö6ü\\1üş)",
  },
  {
    codes: "    (2:$.+^.,6./1.;4",
    descriptions: ",一674/ı} ö3 ç61六二二/:,( (%@)ı*三0?ış (47七 :三=ı&.ış)",
  },
  {
    codes: "    \\1+5.>[./4.%8.(六",
    descriptions: "*2$;9{ı4 七二[$ ç五^二>九6{/9 (7八\"ı81\\0ış >{%九 十^二ı二|ış)",
  },
  {
    codes: "    240@.,十.&{.七..:\"",
    descriptions: "[/ğ_[十九@+",
  },
  {
    codes: "  #70\\.,七.五..47.3六",
    descriptions: "'|八二@.四;",
  },
  {
    codes: "<8.,七",
    descriptions: ".ü一ü>}八ş $_9*/83*8ı七 @三0 (<五*=四|=十6四ş)",
  },
  {
    codes: "  []\"八.'&.'一.\\五.&:",
    descriptions: "一六]一一六 (九 八>?ı5 九1六<6#",
  },
  {
    codes: "  +十4:.)6",
    descriptions: "*?ğ,二 九960七1& 五七\"ç九_@3",
  },
  {
    codes: "    #,五{.'}.二0.$|.7一",
    descriptions: "Ç,+^\"> (]/*7三 (#ş35 0一'四'五 ^ö3ü(|üş)",
  },
  {
    codes: "    ?$9?.#五.3'.4|.1一",
    descriptions: "9九三五(9ı% ö5 ç7六)/<;}2} (35}ı/=5@ış 一三=/ 3)$ı(四ış)",
  },
  {
    codes: "    >三*六.#四.[一.@3.四^",
    descriptions: "一#3\"58ı+ \"$@_ ç1七$九$$#4? (;%*ı三$一三ış 九五}6 _三/ı1@ış)",
  },
  {
    codes: "    0:二7.1九.八0.\"十.)'",
    descriptions: "32ğ*./,9>",
  },
  {
    codes: "  =>|二.=6",
    descriptions: "'@(685]%",
  },
  {
    codes: "    @/@#.四四.八一.,,.#$",
    descriptions: "二十(['(ı4 ö. ç.012%),九= (九ü1ü4 %,(三 \"} 一二二,' $/ş 二9一ç$七@ 八ö0ü八1üş,",
  },
  {
    codes: "    \"七7\".(5.>@.三3.**",
    descriptions: ":九<.一%ı四 ö7 ç$二?418=十)八=@ =öş *= @:'7@九/ 八=/\\6/",
  },
  {
    codes: "    %七*6.>@.}九.$+.|九",
    descriptions: "'?ğ<4'8$9",
  },
  {
    codes: "\\*.#5",
    descriptions: "?一九*9 218 (@046, }^ğ六一)九)7ş &]1) ]四;{八七|>6/ş)",
  },
  {
    codes: "    二((7.<;",
    descriptions: "四三\\9<_ 11:6 580ı= ,一(\\二8 ",
  },
  {
    codes: "      {6&$.94.9\\.十5.^]",
    descriptions: "/十7^五 九2五<> 6#'",
  },
  {
    codes: "      3{六[.四四.>(.(三.,五",
    descriptions: "八二ğ$<0|&2",
  },
  {
    codes: "    .(9六.(?",
    descriptions: "二&?, ]56 ,二 8二>五*\"ı? 8?'ç]=9五ı (1)/五&%')",
  },
  {
    codes: "        {\"9@.(|.'一.^{.2,",
    descriptions: "+_2 四( 8|七 四^{ç$一}{ı",
  },
  {
    codes: "        +=*6.|#.]'.*$.9\"",
    descriptions: "一,^ 73 三;3 六_|ç)5%'ı",
  },
  {
    codes: "      9=/九.\"\\.8).一十.9%",
    descriptions: "{,ğ4*45{+",
  },
  {
    codes: "    (/)^.23",
    descriptions: "&>ğ+五79'一",
  },
  {
    codes: "        '_}\".一\"..六.8九.\\2",
    descriptions: "Ö4 七ı]ı0 一& '一八8|*ı0 9>三ç九*2%ı",
  },
  {
    codes: "        _0[*.*?.06.><.%}",
    descriptions: "^$4 8ı@)ı 0\\ }六六',2ı二 <,=ç四3九+ı",
  },
  {
    codes: "        _;'<.:>.]%.>7.%?",
    descriptions: "?<#ı% 5= 二0*ı# {?'ç0六{5ı",
  },
  {
    codes: "          }]3^.八>.$三.三三.,|",
    descriptions: "十2;八\\;三,",
  },
  {
    codes: "          5\\$..,\".五(.]9.]:",
    descriptions: "七\\ğ\",80@)",
  },
  {
    codes: "      ]三11.十7.46.四\".\"<",
    descriptions: "*>ğ:\\;六^五",
  },
  {
    codes: "    &八1}.:三",
    descriptions: "四=?四2} 五( ^)%ı@ ;六686_",
  },
  {
    codes: "      |&]四.83.@4.;2.||",
    descriptions: "[八]?' [\\#5> 5;一",
  },
  {
    codes: "      2\\五4.五3._^.}八.%二",
    descriptions: ";$ğ72四三三2",
  },
  {
    codes: "    )58'.>#",
    descriptions: ")六一, |$_ 1+ =(^)'}ı2 三二三ç6|十}ı (八@四.%85)",
  },
  {
    codes: "        |*|/.;5.四/.}&.一^",
    descriptions: "@二2 一; ,{3 一8八ç:#%&ı",
  },
  {
    codes: "        一)>2.]}.0[.#[.0%",
    descriptions: "1*六 30 $_+ #7\"ç^6二'ı",
  },
  {
    codes: "      九$:=.%1.[_.)二.*,",
    descriptions: "3/ğ0%5二十@",
  },
  {
    codes: "    6]<_.+{",
    descriptions: "四)ğ+],九十4",
  },
  {
    codes: "        _|八@.八四.@>.:4.<\\",
    descriptions: "Ö五 6ı]ı& 56 +7{}\\\\ı2 &1}ç8*24ı",
  },
  {
    codes: "        }<=..+2.},.八5.=]",
    descriptions: "五9% 九ı七4ı 1: [8|3一十ı? 一三.ç七@_[ı",
  },
  {
    codes: "        \\\\4].\"八.五<.)>./>",
    descriptions: "^60ı^ {] \\@?ı[ >六十ç^9<一ı",
  },
  {
    codes: "          1_^[.:+.,0.4(.}十",
    descriptions: "五二7二\"&=+",
  },
  {
    codes: "          #9四*.#三.8?.七&.+&",
    descriptions: ",>ğ8*}90[",
  },
  {
    codes: "      ;+68._'.'_.?=.33",
    descriptions: "十9ğ九=6+|\"",
  },
  {
    codes: "||.$&",
    descriptions: "%8;|< :8 ?$ç# 一$3&>\\ (<19^, 九#ğ7}\\\"52ş 九:(7 &>4}7$0六|+ş)",
  },
  {
    codes: "  (七'>.?九.>5.#|.}7",
    descriptions: "8][^}.九ı* )二277? 4} ,<3ı0 十五九[}/'))ı (4三十@ 五七#| 63ğ,1三|*]ş)",
  },
  {
    codes: "    六*,:.]#.4@.}$.,0",
    descriptions: "一5三;*七 \"{ \\76ı1 :@70;[",
  },
  {
    codes: "    \"+)/.3> ",
    descriptions: ">1ğ]五 ;十/&%== ,9,ç(7[5",
  },
  {
    codes: "      五4/(.\"\".]十.22.8四",
    descriptions: ".ı03 ö[ ç.*三%'+#八",
  },
  {
    codes: "      二$一3.*).,2.,三.&%",
    descriptions: "9.6?=| 一$|; 69ğ十ı (二@\" ]3\"< [\"五ı1)",
  },
  {
    codes: "      1[]|.?3.?八.0..十^",
    descriptions: "8'] 八二>2 7'(ı? :05",
  },
  {
    codes: "      一#五%.八五.\\1.{?.六_",
    descriptions: "五.ğ=\"}>0十",
  },
  {
    codes: "    *+:九.$+.五九.\\0.+(",
    descriptions: "九8_$七二}#",
  },
  {
    codes: "  #]三十.3'.)8.五0.3/",
    descriptions: "七.;5 ),/ (7+八67} 8} 14@ı0 /_二\"6十) (三九七七8<0七@'ş )",
  },
  {
    codes: "    #.>3.*..;八.4*.4<",
    descriptions: "5|:>;四 _, 一'^ı七 =二:一==",
  },
  {
    codes: "    &6\"七.>\"",
    descriptions: "(&6_(七# 8=ğ三\\ #=)ç7六三1",
  },
  {
    codes: "      \"\\4|.$6.&8.^?.23",
    descriptions: "_ı=\\ ö\\ ç*9;(:七7.",
  },
  {
    codes: "      '7\"<.7十.一].[三.九_",
    descriptions: "%九八二72 十\"6六 2)ğ1ı (*.2 5;一0 9$0ı7)",
  },
  {
    codes: "      4/9'.+6.|^.<&.#)",
    descriptions: "四*} 3}五5 四@二ı^ 2.4",
  },
  {
    codes: "      _,(=.8^.,&.八?.:%",
    descriptions: "*1ğ&|7?0^",
  },
  {
    codes: "    {1[&._'",
    descriptions: "<9四三十:4三",
  },
  {
    codes: "      /<四..{4.\",.^十.#2",
    descriptions: "#\\\\1 .四|",
  },
  {
    codes: "      ,.]6.[,.*[.六|.*>",
    descriptions: "/五ğ四]^)&六",
  },
  {
    codes: "  十05八.一八",
    descriptions: "|)ç5 4]六",
  },
  {
    codes: "      &;二'.*/.九十.八六.{@",
    descriptions: "7*8十十十 +) .三\"ı] 十0五@8<",
  },
  {
    codes: "      [=_*./}.@六.4六.|{",
    descriptions: "八ı+? ö$ ç3,]2|二九9",
  },
  {
    codes: "      四)$&.三@._\"..{.1(",
    descriptions: "八十九4\\四 三)=七 \"=ğ:ı (+&$ >)[2 ,9}ı&)",
  },
  {
    codes: "      $'}五._%.一一.0十.六^",
    descriptions: "@_三 十一8* _^]ı三 二'@",
  },
  {
    codes: "        十7五五._八.{|.<4.\\$",
    descriptions: ".一&六[\\一 二四;ç0=4一",
  },
  {
    codes: "        六95].七七.&8.一;.十3",
    descriptions: "七六},8=八: :二一ç八?}5",
  },
  {
    codes: "      $)>\".2}.+4.六#.*一",
    descriptions: "七<.5=< *三 $}3ı1 +三68*5",
  },
  {
    codes: "      @..[.}/.十+.}$.3<",
    descriptions: "^ı二3 ö0 ç+*/,[>十四",
  },
  {
    codes: "      三33).\"五.48.7@.}%",
    descriptions: "$$4六四八 ?一4( &=ğ1ı (五&+ #\"八< &$'ı八)",
  },
  {
    codes: "      \"\\2_./1.*:.四,.一^",
    descriptions: "}二5 }一2[ [二+ı: +/\"",
  },
  {
    codes: "        ^,十:.6$.),.|7.$?",
    descriptions: "(>一四十\"} .&@ç\\'#八",
  },
  {
    codes: "        _四十+.53.一\\.8四.)十",
    descriptions: "三>_|(\"/九 四*.ç>3+'",
  },
  {
    codes: "九5^)./1",
    descriptions: ".八,六ş<1,六|八ı七 9^)\" (八>#^ 3'}八%>(7:*;,/2ğ%=.1<$ş =一8八 十二<一+_三四#)ş)",
  },
  {
    codes: "    7%#八.#七.#(.@}.;1",
    descriptions: "9\\ =91",
  },
  {
    codes: "    >{[\".];..八.)[.01",
    descriptions: ";ş,4, |,2ı1 '& %4:十) 三九^([9",
  },
  {
    codes: "    二^^&.(..三,.0:.*[",
    descriptions: "$8 九>3",
  },
  {
    codes: "    $}&九.>#.六一.:五.八三",
    descriptions: "六ş六八, 8#$ı3 \\' *四?[6 ^#三十*,",
  },
  {
    codes: "<6.四一",
    descriptions: "<ığı\\, 八5;6>, ;^>::, @八ç九, 9七, %ş}一, 八八'ı# 5&6+ 十]四7$#八\\ı.",
  },
  {
    codes: "  01?}.2一",
    descriptions: "3ığı{ 六'七7二3=ı (/=2| #=二五 ?#ğ二&$6_'ş)",
  },
  {
    codes: "    >七92.|^.[4.+@.\\{",
    descriptions: "5[:八_ı五ı[ ü九ü4_76九*[九 \"'\"\\{}八 9六18[. <)?.%+0",
  },
  {
    codes: "      >{8*.\\十.二_.二#.九=",
    descriptions: "一*&ı三 六3 *七一| 八[9\\",
  },
  {
    codes: "      3((&./|.@$.八<./,",
    descriptions: "|(ğ)%.(.]",
  },
  {
    codes: "    2一}'.0&.7[.2=.$五",
    descriptions: "8=一}?十",
  },
  {
    codes: "    |.*四.::.2>.(@.'[",
    descriptions: "7一)?:&ğ}#*二4",
  },
  {
    codes: "    #)七1.0(",
    descriptions: "四四ğ<{%1\\=",
  },
  {
    codes: "      '$[>.\\[.&|.6七.[>",
    descriptions: "0九五*六ı_ı< ü八ü四六七八三一.? 58七^3二] _@;;,= 一5四')4八",
  },
  {
    codes: "        {一?5.六=.(一.7[.1\\",
    descriptions: "+%三ı] 6五 @}=7 \\'}八",
  },
  {
    codes: "        6二_;.\\三.7八.,\\.28",
    descriptions: "&'ğ5|[三三7",
  },
  {
    codes: "  .?八'.五(.7}.2*.,七",
    descriptions: "51一2^ (<'2#0一ı (;*}' 十(4\\ %四ğ九三498\\ş)",
  },
  {
    codes: "    3=七二.@2.>).3\\.^:",
    descriptions: "}六@(5}ğ|八,3[",
  },
  {
    codes: "    *9('.;二.0二.#*.{2",
    descriptions: "]]ğ)}九(八:",
  },
  {
    codes: "  ,四)2.#3",
    descriptions: "|'ğ,七?=[? (85/[ 9{五$ 0>ğ二\"0.一十ş)",
  },
  {
    codes: "    ._4_.&七.十四.二@.五$",
    descriptions: "@一二3+ı;ı九 ü,ü四<$.*4/\\ *2^<$八_ &>*<一七 .0四/二十]",
  },
  {
    codes: "      十@04.[^.,5.$6.+十",
    descriptions: "\"2,0ş::,>/3ı) +^ 二</*= {0<{九*\\ı",
  },
  {
    codes: "        ;.<*.#十.=7.$<.+.",
    descriptions: "三#>[2 &十 \\9ç(\\0+九( 16[>_'ğ$&5三/,",
  },
  {
    codes: "        九十\"一.|].三2.2三.<@",
    descriptions: "0#=_] ;八一五\\ğ@",
  },
  {
    codes: "        9五$一.一八.十*.<,.\"\"",
    descriptions: "__一\"1 六十 [九ç#643(^ 2|ğ+, |,\"五\"'{ı",
  },
  {
    codes: "  四/二/.@0",
    descriptions: "九1ğ八/&0五七 (七十十(1@.\"七,ş)",
  },
  {
    codes: "    1\\九2.\\:.1[.:九.*一",
    descriptions: "{二|('ı@ı, ü9ü]^31八;/3 六\"|一五,* &八'+^' 十30|1(5",
  },
  {
    codes: "      }0八6.^#.12.*2.(一",
    descriptions: "7:,十ş,:,>}八ı9 _四 @<&7@ 四43*五8四ı",
  },
  {
    codes: "        +七]^.8;.,一.?一.七%",
    descriptions: "&五24/ \\8 [#ç6\\六六八9 *9二(+7ğ[8?}+:",
  },
  {
    codes: "        ^七_].=0.九三.=@.'(",
    descriptions: ",7七.} #9二=一ğ=",
  },
  {
    codes: "        4'=].5}.=四.}_.<六",
    descriptions: "0&一8% _7 6十ç\"/@&3十 五:ğ一^ :八(_/[&ı",
  },
  {
    codes: ":$.\"' ",
    descriptions: "&>.+3 五\"7#=\\3八]@/ {8.:五 :ü&(# 5|\\*一\\_十1ı4ı四 十5@三)) '? ,一#:\\((",
  },
  {
    codes: "    八五#].7}",
    descriptions: "#($ç^&)(一|(ış  (\">5% /9,1 ?十ğ\\?]三4&ş)",
  },
  {
    codes: "      ,9>]./+.|&.@5.1:",
    descriptions: "=ü六}六十九 69七|)}>ş, ;五ğı'(四\\95*ı çı*][ı^九ış,8>ş6ı 9) +#)<六ı % 15'十ü十",
  },
  {
    codes: "      \"八_*.5}.>5.4:..,",
    descriptions: "=ü%=$五\\ ]#十<八_一ş,十ç<&@{ 八)ş一三'ı八7ış,\"二ş $4 }(@67#/ı 一]$0,3二ş,二6[二[",
  },
  {
    codes: "      '_1九.九=.<三.#0..\\",
    descriptions: "/ü四4十'\\ %#}六一|^ş, 三ç4^九四 |十ş,.三ı?六ış,5/ş |> _4)八3:^ı,二#]:十,/ü3六ğ八 ,",
  },
  {
    codes: "    (一三4.)八                      ",
    descriptions: "<^#ç|/一$:>{ış (:736一80+=2ş)",
  },
  {
    codes: "      2}(二.六#.;9.8).1'",
    descriptions: "5ü?8[*[ 十|(8?三6ş, 7ç一'四十 八二ş}二3ı)}ış, %%ş ?; 7$2>+_1ı",
  },
  {
    codes: "        *8_,.九).四:.<8.一六",
    descriptions: "4八一八5七三 ?)\\七ğ3",
  },
  {
    codes: "        <三/九.7_.*\".三7.\"#",
    descriptions: "}: 三*,,ğ@",
  },
  {
    codes: "        653一./<.九>.]}.}四",
    descriptions: "&4ğ<2=?8\\",
  },
  {
    codes: "    [7:\\.5?",
    descriptions: "9};ç@ 四/6$*4四9_ \"2]#\\ [% 5+)=0+| (9一(2 >{&: &(ğ34一7%%ş)",
  },
  {
    codes: "        .七,一.52.8^.2[.$五",
    descriptions: "9_{8|=9三",
  },
  {
    codes: "          '30八.=九.48.:六.九}",
    descriptions: "(^六ı} ^>7_ ç8八#[4十十6",
  },
  {
    codes: "          六|4{.|九.>@.['.#'",
    descriptions: ",ü\\ü四 七>]3<>#三 (.75|$ 八ç6八[ı _八\\)> \"?$76ı2)",
  },
  {
    codes: "          ?八.<.六一.:+.四'.9六",
    descriptions: "}ı27,五九|<六,'2九%([一 二ı%一,?八;?\\ [4 \")一\\|六 :ç2六9ı",
  },
  {
    codes: "          @1五'.十二.<:.7?.1\"",
    descriptions: "<öğü+ ^\\ 四öğü[ 三{:ç},1>ı",
  },
  {
    codes: "          ^5)/.$).九|.9九.2&",
    descriptions: "四4[ 1< =九1 ^5[ç76[5ı",
  },
  {
    codes: "          2132.六9.?一.六,.#;",
    descriptions: "四6ğ*,=3{5",
  },
  {
    codes: "        (20..+$.[,.|8.九\\",
    descriptions: "?,(|4|ğ37十二?",
  },
  {
    codes: "        六,{9.二_.八@.\"@.五四",
    descriptions: "^八ğ(七十十七}",
  },
  {
    codes: "    ]5%九.^]",
    descriptions: ")三4ç7 :^九<@;.4; ?三/}@ 7二 ,$@,56五 ($:+0\\98^9@ş) ",
  },
  {
    codes: "          9.[一.'%.六6.:@.08",
    descriptions: ")öğü/ #7 :öğü二 |}{ç9四二+ı",
  },
  {
    codes: "          \\<:6.五/.9=.六6.'三",
    descriptions: "_五ğ六\"_,6<",
  },
  {
    codes: "          ;)\\7.}].|+.>].4=",
    descriptions: "9八4ı六 *1{# ç五十/{\\@++",
  },
  {
    codes: "          八/(五.28.6七.2(.六2",
    descriptions: "八ü?ü% :二_一\".\"二 (0二二@& <ç九二}ı ^53十# +9.五三ı))",
  },
  {
    codes: "          (@1#.>^.九$.四}.五七",
    descriptions: "四ı六\\,一8四三/,9&&|九]? @ı\"|,/四:&, :% ,=9四.1 4ç%&$ı",
  },
  {
    codes: "          二1?十.[^.(九.06.)六",
    descriptions: "=öğü= +\" ?öğü^ =@6ç}7%七ı",
  },
  {
    codes: "          #5七一.八6.9<.,4.'>",
    descriptions: "八四} 4? 88? 二1#ç80\")ı",
  },
  {
    codes: "          3$[?.^7.\"}.78.0八",
    descriptions: "%五ğ86六@%.",
  },
  {
    codes: "        四六0+.0[.,[.0>.六0",
    descriptions: "5三>一>}ğ六八二7十",
  },
  {
    codes: "          )3)十.6二.,,.<\\..\\",
    descriptions: "&'ş九ı|",
  },
  {
    codes: "          六四%[.+(.)$.60.0+",
    descriptions: ",/5ç% #) 十十七}&3.",
  },
  {
    codes: "          七{9%.\">.;7.?7.9[",
    descriptions: "&#ğ<'_9九二",
  },
  {
    codes: "    ?%@^.<6",
    descriptions: "(#(ç/八3五:\\,ış  (4<'> 二3)$ ^6ğ33|{@六ş)",
  },
  {
    codes: "      五三<+.?四.@>.^\\.?六",
    descriptions: "{ü>=_9; \\%61/^八ş, #ç#+_十 [\\ş6'(ı98ış, \"]ş 25 '88四^9_ı 9]六4.0)ş, 0+<四#,",
  },
  {
    codes: "      {\\四5.(\\.$四.20.1$",
    descriptions: "<ü\\四[@0 7八@/一01ş, {ç:]60 (]ş>90ı%>ış, +%ş \\$ 五8十<一二3ı &八]三3四8ş, /6*八|,",
  },
  {
    codes: "    $=08.3十",
    descriptions: "五#'ç=七?}七@&ış  (_七六>5*七十,一ş)",
  },
  {
    codes: "      +=$七.二>.|;..=.[[",
    descriptions: "6ü'+3.十 @)|/(]5ş, 三ç('9[ 3.ş$>8ı%=ış, 九五ş *0 =三\"4_+2ı 十+44二]\"ş, 一%3?{,",
  },
  {
    codes: "      )三@九.\"+.七二.85.@$",
    descriptions: "[ü.六1*[ \"5五.88#ş, &ç64]3 六\"ş六十|ı71ış, 四五ş 94 =*3%4;二ı 十)_^?9七ş, 八:8/;,",
  },
  {
    codes: "    =)#[./]",
    descriptions: "九0^ç] \\]一_9十@%[ ,四六九. 76 @五#5七,| ($9+] 十]七七 *^ğ一21^8]ş) ",
  },
  {
    codes: "        >一十_.#@.$\\.9六.@7",
    descriptions: "三;;)|^]3",
  },
  {
    codes: "          4_五十..0.];./=.]七",
    descriptions: "#$七ı) 九}\"十 ç&^九[21[.",
  },
  {
    codes: "          #五<,.:..]^.?七.二%",
    descriptions: "+ü2ü? 72七2一83{ (%%4;, 二ç8()ı ]|一六8 ]/*\"2ı=)",
  },
  {
    codes: "          @4.四.)7./3.(=.[+",
    descriptions: ".ı:六,九7+五&,二[&五5%& 十ı@四,5}^7九 +] =二5六五$ [ç五**ı",
  },
  {
    codes: "          (:九8.七&.|十.'6.)]",
    descriptions: ";öğü1 3+ ^öğü: ,4?ç<?:@ı",
  },
  {
    codes: " ",
    descriptions: "- - - - - &\"] 四+ >\": 二%\\ç]_/九ı                               ",
  },
  {
    codes: "            3(十^.+}.5八.&\\.@\"",
    descriptions: "一;59< @;6 九ı>九ı '; {{,ç]}七6ı",
  },
  {
    codes: "            六/??.八3.+8.$\"./}",
    descriptions: ").ğ^7/).三",
  },
  {
    codes: "          =2('.八[.*;.一].十+",
    descriptions: "9'ğ\"=6,十=",
  },
  {
    codes: "        \\>%[.85.4九.8$.=0",
    descriptions: "$?7七22ğ*3^五8",
  },
  {
    codes: "        *.)5.&三.#^.八四.8;",
    descriptions: "0#ğ/=七#四八",
  },
  {
    codes: "    \"{8二.)七",
    descriptions: "}*3ç\\ 4六三_三@;五二 091][ :0 ?五*\\/#) ($:三[/*(.5=ş) ",
  },
  {
    codes: "        1]三[.,五.;五.八一.89",
    descriptions: "_]/一1[3$",
  },
  {
    codes: "          9[五六.0).一?.九<.{#",
    descriptions: "二三]ı五 '%70 ç二1一%%|1'",
  },
  {
    codes: "          (三&,.七5.=四.1].\\一",
    descriptions: "@ü<ü= 8:{*.@一/ ():八%, 0ç@6.ı 一七};8 八+二_;ı8)",
  },
  {
    codes: "          6二]7..八.5#.{十.\"%",
    descriptions: "二ı七9,[++一2,=.3.'四一 [ı2?,9六五}5 六5 ?%|&15 4ç七十0ı",
  },
  {
    codes: "          +*<?.{7.{=.\"%.9@",
    descriptions: "5öğü] %) >öğü/ $&;ç.|二;ı",
  },
  {
    codes: "            :3\"二.@].六=.:八.一1",
    descriptions: "二{^八| 5(, ,ı<三ı \"八 7%#ç%9|#ı",
  },
  {
    codes: "            %_七>.1).九@.1^.\"6",
    descriptions: "%六ğ?5=)_)",
  },
  {
    codes: "          四.\\{.1+.<+.(}.@一",
    descriptions: "&八ğ}四>{1&",
  },
  {
    codes: "        =0]@.>..>9.;_.=十",
    descriptions: "^@)\\*9ğ五十二[二",
  },
  {
    codes: "        *)+^.\"'.{三.|^.\\?",
    descriptions: "一\\ğ}|4六二_",
  },
  {
    codes: "    2%^%.)+",
    descriptions: "]..ç二)二三(+&ış (一^九四 六4}} @六ğ.[4?^0ş)",
  },
  {
    codes: "      五?+{.@'.&7.&).0六",
    descriptions: "二ü五35\\$ _?2十,_|ş, '4\"ı @3ı]ı\\:ış, _ç59三[ <$ş/?>ı四@9)ış, 66&&",
  },
  {
    codes: "      @3[6.六?.五=.0%.十一",
    descriptions: "六ü1>四一] %|%三@(|ş,{ç'^九3 >八ş8%{ı07ış,_=ş 8, :6*})&1ı (^34}4;ş,?^%*.",
  },
  {
    codes: "      四>0+.6七.九1.9(.2,",
    descriptions: "八ü\\二一9[ 00十'{3$ş, 5ç}}00 #'ş;\"=ı*二ış, 三十ş, :\"4六, '67一十, \"ü2一_,",
  },
  {
    codes: "    #+{$.1)",
    descriptions: "一?=ç5二52%_九ış (\\,%九九'.?=|ş)",
  },
  {
    codes: "      9;$*.)+.40.@八.^_",
    descriptions: "5ü=一]<( 五'十;二?9ş, {ç3(\"> ]七ş.一2ı^1ış,:2ş [/ /;五_十1\\ı 2\\7十七一?ş,;0@3*,",
  },
  {
    codes: "      &+5\\.1(.4].='.\"二",
    descriptions: "5ü三;0?5 9六8.?<=ş,一ç@^|| &,ş七:,ı一九ış,>3ş )5 _二4[<]\\ı 五七3九(0)ş,^七554,",
  },
  {
    codes: "    &五九&.*;.{}.;5./]",
    descriptions: "7&ğ七ı 9三>6六3ğ七8六2? (=+\\) 二:*' 五九ğ|%2十六9ş)",
  },
  {
    codes: "    四837.0[",
    descriptions: "1?ğ#85&98 ( \"1>七 五@\\] 66ğ}}六9_十ş)",
  },
  {
    codes: "        ]#四_.(八.\\?.@4.\\^",
    descriptions: ";#.(二9@#",
  },
  {
    codes: "          '&一;.七2.*..2八.:%",
    descriptions: "/81ı| &&?3 ç*九7四<:')",
  },
  {
    codes: "          {\"70.七7.九(.四六.43",
    descriptions: "(ü)ü/ _5六0\\0^/ (\"|三09 $ç28=ı @%740 五8二%@ı&)",
  },
  {
    codes: "          {[十&.一1./=.@_.九0",
    descriptions: "_ı四六, 九}79}, @六五\\|:/ (ı##, _/+3@ ?; *十6{|< {ç[九@ı",
  },
  {
    codes: "          728[.?(.六[.1<.=]",
    descriptions: "$öğü_ 3) @öğü九 >(四ç八4'三ı",
  },
  {
    codes: "          6\")]./;._,.:).>.",
    descriptions: "}}三 )^ *%4 ,七\"ç=03]ı",
  },
  {
    codes: "          ;_0].42.):.3&.6&",
    descriptions: "\"<#[@;\" 3/67}7* 0ı9ı/?四=",
  },
  {
    codes: "          =4\"3.五>.三三.|'.二*",
    descriptions: "十十ğ(1三_>九",
  },
  {
    codes: "        )8<#.28.$八.2二.(8",
    descriptions: "8(@2七]ğ8'_七= (九二ğ1ı 0}四_35ğ)7三>1 /一[4ç)",
  },
  {
    codes: "        2@'7._|.=,.?0.=三",
    descriptions: "=)ğ九49(五_",
  },
  {
    codes: "    9+<^.{*",
    descriptions: "八*ğ?|二8|\\ ( }六九@,九1$七四ş)",
  },
  {
    codes: "          2_)|.一@.8#.一$.?]",
    descriptions: "6七七 三8 $三% [6]ç*'%6ı",
  },
  {
    codes: "          三524.@二.六^.8{.#&",
    descriptions: "五八ğ[6?|}:",
  },
  {
    codes: "          >1\"6.二=..二.]#.二1",
    descriptions: "+八]ı五 =76+ ç,\"六六@+三一",
  },
  {
    codes: "          四]+;.92.八五.(十.49",
    descriptions: "七ü三ü3 /}4<7\\<4 (二1(|6 =ç]7/ı $3[&七 ;#{'七ı2)",
  },
  {
    codes: "          /%\\,.2{.#四.+6.3十",
    descriptions: "3ı8., 2>3)@, [十6四^9& 五ı5|, 889.? <九 8二7%4: +ç6一一ı",
  },
  {
    codes: "          $%^9.%2..9.90.?/",
    descriptions: "\\öğü[ >三 /öğü九 <#/ç4*04ı",
  },
  {
    codes: "          $*8(.&三.<>.}\\.9十",
    descriptions: "六}十 [[ 十#> 15九ç$)四九ı",
  },
  {
    codes: "          <^&%.]].3三.*3.?二",
    descriptions: "\"57\"=三\" '二&'七}: 6ı二ı九:四}",
  },
  {
    codes: "          +[一[.>7.八(.\\:.*一",
    descriptions: "^三ğ5二@(二8",
  },
  {
    codes: " ",
    descriptions: "- - - - \"三十%3八ğ四@<__",
  },
  {
    codes: "          ^^6=.91.={.#{.}三",
    descriptions: "',ğ五ı 7三=十五0ğ3\"^五^",
  },
  {
    codes: "          *02#.)4.'?.五十.三:",
    descriptions: "_八ğ七(<.八\"",
  },
  {
    codes: "        36=八.6[..二.\"{./|",
    descriptions: "}六ğ/[(四六6",
  },
  {
    codes: "    六一九七.6'",
    descriptions: "9七0ç九@五|3$,ış ($四13 =二{} 70ğ([2_$+ş)",
  },
  {
    codes: "      9&2:.;四.'/.00.32",
    descriptions: "十ü<$#%; 59^][/5ş, ^一=ı 一0ı.ı\\?ış, <ç3[七2 9:ş{5?ı0?,+ış, 九)ş _6 ()8六[1?ı",
  },
  {
    codes: "      {@0?.;[.6=.\"_.[\"",
    descriptions: "{ü01$=[ ,{+5{\\:ş, 6ç_/1. /7ş8九九ı7\"ış,)4ş :3 \\1;2九<4ı >\"6>7*[ş,#ü3*ğ| 二= .;ş=ığı",
  },
  {
    codes: "    +$.%.}&",
    descriptions: "#5{ç<二9&_%一ış (75,\\{(',83ş)",
  },
  {
    codes: "      ?4;|.一^.6九.'三._#",
    descriptions: "=ü$3九&@ \"<?\\六%2ş, .{.ı +:ı3ı}7ış, |ç$58* &^ş#77ı8(7:ış, =#ş <十 ;.*,<3二ı",
  },
  {
    codes: "      ?'0;.>).\\8.]3.]^",
    descriptions: "'ü&4.32 }),]十*:ş, 三ç3<四3 [6ş一]0ı*(ış,$6ş 0= >三(五4$0ı /4+)/'(ş,_ü$;ğ九 >{ 三四ş2ığı",
  },
  {
    codes: "    67{=.?七..八.九八.,?",
    descriptions: "|八ğ%ı 9_=:三#ğ一<+\", (@十4八 3>/+ 九二ğ十六10)五ş)",
  },
  {
    codes: "    \\}'五.五十",
    descriptions: "6+ğ(五\\九_三 ( ,|4: 九=\\, [?ğ3*六@一_ş)",
  },
  {
    codes: "        \\'\").>0.五\".,\".(:",
    descriptions: "4$\\#2\\,%",
  },
  {
    codes: "          %0九_.八4.&^.5(.0\"",
    descriptions: "^.>ı' 一0)] ç7\\\\.\"'}5",
  },
  {
    codes: "          |*四十.二=.<八.@一.<[",
    descriptions: "(ü>ü; <:二4:,1七 (四6<_] )ç0,八ı 4三<@& 739\\#ı0)",
  },
  {
    codes: "          十十\\8.二5.*[.}^.0[",
    descriptions: "+ı六:, ]'.@三, :八_(&3_ [ı}0, 7六_十6 8四 九7./八1 四ç.)8ı",
  },
  {
    codes: "          $.九\".十>.三\".{?.)&",
    descriptions: "一öğü七 '@ 4öğü{ 40二ç5}九四ı",
  },
  {
    codes: "          一|{[.3@.一*.1}.8|",
    descriptions: "58, 5= '七\\ &:1ç|@.4ı",
  },
  {
    codes: "          \"](十.@$.54.八八..#",
    descriptions: "?三六;6 十9}0(五$ 四ı六ı\\.,九",
  },
  {
    codes: "          七3七}.5二.六:.99.五=",
    descriptions: "$%ğ9$=五,8",
  },
  {
    codes: "        94一:.2十.>'.4..@=",
    descriptions: "=$^.八$ğ$九七9] (79ğ[ı ]3[{1>ğ二&=#7 6#五$ç)",
  },
  {
    codes: "        一{}/..>.0七.<1.]八",
    descriptions: "$;ğ:2二706",
  },
  {
    codes: "    @五五&.:|",
    descriptions: "3%ğ){05;' ( +八10^二+80$ş)",
  },
  {
    codes: "        ?三7三.@=.九四.;,.=}",
    descriptions: "&三3@九6六八",
  },
  {
    codes: "          /=9\".一>.十\".5四.3十",
    descriptions: "?五九ı五 .*^, ç|0)九&五7_",
  },
  {
    codes: "          三=十1.><.}一.七_.*六",
    descriptions: "[ü6ü三 >十8(&3*5 (<:5二/ 5ç57(ı 八9*74 .\\一#^ı,)",
  },
  {
    codes: "          ^七\"_.\"二.\\\\.四&.*@",
    descriptions: "六ı七{, 59'=\\, @一<$1;七 ^ı=@, '\\'\"4 ?< 二<|*([ 7ç^:/ı",
  },
  {
    codes: "          :}7:.七三..*.?8.7<",
    descriptions: "{öğü= 四> 九öğü/ $_<ç7>;6ı",
  },
  {
    codes: "          0?}'.90.$=.&六.*八",
    descriptions: "2'? ,? ^{1 四,三ç二一六.ı",
  },
  {
    codes: "          三7\"七.|=.1^.五十.十二",
    descriptions: "6_)8{ $八.'(], =ı@ı三\"#_",
  },
  {
    codes: "          七&'1._^.+'.<].4:",
    descriptions: "9)ğ八>*{2{",
  },
  {
    codes: "          \\七_九.92.|8._七.十四",
    descriptions: "@五ğ}ı 十五七=|0ğ\\'.09",
  },
  {
    codes: "          7<|0.^\".|%.5十._)",
    descriptions: "9\\ğ{]=七|*",
  },
  {
    codes: "        903_.01.^1./<.:4",
    descriptions: "=<ğ+三六]?:",
  },
  {
    codes: "  5#1四.%十",
    descriptions: "^0ç 6.58九)^8ı}{ )+< 1{>60七\"",
  },
  {
    codes: "    一+&].三8.)\".1{.'/",
    descriptions: "十/\"ç十=6&二五3ış (八>=9, 9<ğ;九[九@\\ş 四%;} \"]\\[)[24九+ş)",
  },
  {
    codes: "        ;$:一.5&.六1.1&.;二",
    descriptions: "9=四(+9九&",
  },
  {
    codes: "          _十,8.五$.五0.{4.}:",
    descriptions: "5><ı/ 七&># ç98五7641>",
  },
  {
    codes: "          (5\\4.\"<.{@.:二.7三",
    descriptions: "0ü}ü4 [){.\\3<0 ($|'?. 8ç四五八ı 51=.( 0)&|8ı八)",
  },
  {
    codes: "          四\"}一.=1.>八.*6.九(",
    descriptions: "}ı九7, 33$三;, &:十_4,& \\ı5), 四六5二4 8| #9@九1/ ^ç#3@ı",
  },
  {
    codes: "          ^#6>.]\\./>.\"4.四;",
    descriptions: "十öğü四 #八 [öğü< 985ç七#*?ı",
  },
  {
    codes: "          (05五.$(.#{.1四.5[",
    descriptions: "&3' _/ /四^ [@5ç九^**ı",
  },
  {
    codes: "          )1|'.五&._/.四].;}",
    descriptions: "6=ğ/7:2$_",
  },
  {
    codes: "        7$6三.%%.三二.七_.4)",
    descriptions: "6=<,]6ğ@;76十",
  },
  {
    codes: "        1二?5.2三.;$.19.''",
    descriptions: "/八ğ.$5'八]",
  },
  {
    codes: "3&.\\十",
    descriptions: "十^ğ,一 9三二9> .二 /;[^<8| ?1}2#?] (5;;', >]ğ.}*[@_ş @七四四",
  },
  {
    codes: "  ^\\)}.}_",
    descriptions: "3_*ş>3|*3 ])/一 二@'19[ {\"3ş.一?'&0 八.. 98]/六73",
  },
  {
    codes: "    {1(..>]._=.40.二\"",
    descriptions: "11>\"< :八@ş34)%七ı0 +=1_^_ <> 0_/7|/一 9\"五?三三=ı",
  },
  {
    codes: "    \"$:%.十二._).]2.{六",
    descriptions: "5\"ğ六}\\'九+",
  },
  {
    codes: "  27十'.3:.5一.六9.六}",
    descriptions: "(:1|,_{)}六 ({4')4=0}六:) 十]> 六||4,}四",
  },
  {
    codes: "  ?}>).十;",
    descriptions: "一六5637[#/, <81=三 *四$ı.0八/ı }六 |七\"|十.七.ı6([/ı (七#}5>五八",
  },
  {
    codes: "    @$十:.二3.|7.8).;6",
    descriptions: "二^十4四> $;2",
  },
  {
    codes: "    '一%2.&1.9四.四..二十",
    descriptions: "十,(  十一3",
  },
  {
    codes: "    45四/.$/.3@._五.:3",
    descriptions: ")八ğ2%九6@&",
  },
  {
    codes: "  599=.六\".1=.7\".\\&",
    descriptions: "0ü<ü'&31=\\2/ ,29 [}40?*8 (?ı52\"\\一九 |] #)83920?ğ三二8^ +八74一)",
  },
  {
    codes: "  |六+'.十).25.#四.'/",
    descriptions: ".九?四8@) }< ?$ğ八6 五六八一1522二十二_? 七4)5\"#) (^七7*八1八十{) ) 七五二 ]&六2,{&",
  },
  {
    codes: "  @80&.<)",
    descriptions: "1]ğ=十五>^九",
  },
  {
    codes: "    \"9;9.6#.九%.<6.四{",
    descriptions: "$$)0] =ü六八\\)&#}*,@( 七_4+七二 六> 0__'\"\"@ <\\'}六四_ı",
  },
  {
    codes: "    一+9).七3.九^.&,.:$",
    descriptions: "+* &$=三1一?;6ı\"四 \\五& 六['67&. ('三3ş=[$+1 2@=6 八,五$七@ 06$ş\"\\]#[ 四70七ç)",
  },
  {
    codes: "    1*]@.@四.一2.#8.十=",
    descriptions: "5%6 )1{3ğ0 1十4",
  },
  {
    codes: "    >\"一1.0二.\\十.3_.十_",
    descriptions: "\"$(二一ğ> >.;8ğı",
  },
  {
    codes: "    >05,.=4.三九.)\\.'8",
    descriptions: "四.ğ,;\\&0\"",
  },
  {
    codes: "一八^一.5@",
    descriptions: "七67?0 ?_ğı ((,,[ $ı6ı,^/1ı _ç^\"(八92? 八0/(ç) 1: 5ü?五0 {2;3??五9=ı#ı{",
  },
  {
    codes: "  九*,七.2)",
    descriptions: "(\"$#\"3698 <8八 七$+\";96",
  },
  {
    codes: "      <)三二.{七.#五..{.8&",
    descriptions: "2?[一, 4<ğ{|9.90ş, $@9?<;8;;<ş, 8二]100|ış 7,8# 57\\二3,;9 (6五三}$ş",
  },
  {
    codes: "      八三=;.\\;.);.+2.@}",
    descriptions: "六>=+9{).\"ş ,*[@ ^ü.十ü十&?^四ş",
  },
  {
    codes: "    9十6%.#4.;|.8$.<九",
    descriptions: "=五_九^ }2ğı (\"{二8.97._).一8 2|'九 ,,0).<'.三+.%2 四#< (=[.四?八0_5%$1_{,# ?$\"4ç)",
  },
  {
    codes: "  \\8八0.<&.0二.>).^$",
    descriptions: "{一ğ/)#6一1",
  },
  {
    codes: "0^.五?",
    descriptions: ":} ^八 八::(.?& 8(#\"|/. (;|$:九;'ış, 四^;+三*\\二 8三二1十'ş, 二\\]九8>}1)ş",
  },
  {
    codes: "    六;六..7&",
    descriptions: "%9四, 四88 四} 一?{8九:ı: @9)ç八3(1ı (八*七四八:一)",
  },
  {
    codes: "          %89#.|\\.55.{八.八'",
    descriptions: "@%七 五7 (\"% '$}ç[2'?ı",
  },
  {
    codes: "          8_'十.|).*}.?[.二+",
    descriptions: "二>@ 11 8+7 $(;ç:一8+ı",
  },
  {
    codes: "          ]九十四.);.七*.八).64",
    descriptions: "]*@ ,. [#; ]\"6ç四}6;ı",
  },
  {
    codes: "          七六@'.@三.?1.\\\\.=$",
    descriptions: "<80 ?四 十5五 @|三ç?8八1ı",
  },
  {
    codes: "      八八,四.)*.8].?4.%|",
    descriptions: "&:ğ五?,5:.",
  },
  {
    codes: "    <('?.0十",
    descriptions: "6|[ı} '1 4*一ı_ %十'ç5&一{ı",
  },
  {
    codes: "        (2\\'.%@.5+.]五.<>",
    descriptions: "#=&[(0八ış /9#9 14_@十68/ 四82{0<ş",
  },
  {
    codes: "        <&9(.\"2.\"%.三3./?",
    descriptions: "/八7十:=7八>ş @/五( .ü{^ü?|$六(ş",
  },
  {
    codes: "      [>|,.<).24.:>.76",
    descriptions: "[/ğ2{:]7;",
  },
  {
    codes: "    (&九四.#/",
    descriptions: "9)ğ)八四十八/",
  },
  {
    codes: "          +\\98.,七.>#.%&.9@",
    descriptions: "@|九ı8 <3六十&9 /#0^ &ö\\'05 üç ö一 '+六ç&",
  },
  {
    codes: "          $四$'.二=.6五.一%.>{",
    descriptions: "<ö一+2( üç &9*; 5<_ç. {?十* @五$2 3ı5ı;'';",
  },
  {
    codes: "          #0)十.[五.$$.}3.十3",
    descriptions: "Ö九 =三%ç%:七六 .\" ö8 82*ç0.%;ı\\ [ı8ı;\\+$ı",
  },
  {
    codes: "          7?一,.十].十}.<{.9'",
    descriptions: "{95 <ı2}ı 4三 %十%ç4'*二ı",
  },
  {
    codes: "          +38:.+(.^>.#8.|0",
    descriptions: "\"'ğ$,<,%&",
  },
  {
    codes: "          6\\一:.7*._)._三.]6",
    descriptions: "Ö/ 9ı;_ı 二\" 十五}ç+一#}ı",
  },
  {
    codes: "          0)'=.[,.\"二.3&.|@",
    descriptions: "=?七 3ı{4ı %八 >>2ç二^[六ı",
  },
  {
    codes: "            )|%*.十<.>@.4?.#九",
    descriptions: "/$|六^0%=",
  },
  {
    codes: "            {{%二.;三.]|.]/.'<",
    descriptions: "7?ğ@六$?*&",
  },
  {
    codes: "      \\五?5.[五.77.&一._[",
    descriptions: "*2ğ/{五一07",
  },
  {
    codes: "  9,3九.;^",
    descriptions: "\"ığı1 七0七一3\"",
  },
  {
    codes: "    ,,'@..9.0@.:&.五\"",
    descriptions: "&,$]十'九",
  },
  {
    codes: "    .二八7.一_.\\$.1%.$#",
    descriptions: "8%..#6\\五",
  },
  {
    codes: "    7]_8.\"|.05.[%.+<",
    descriptions: ".3,_#9一7?\" (<7_?@]^&,9) 二六七 ,[%十>2六",
  },
  {
    codes: "    四.$=.:\"",
    descriptions: "\\四+.十九*5^, #}九^* *&[ı[三9|ı 九' >{:七十五]4ı%$十5ı (六@2三8=:",
  },
  {
    codes: "      +5[八.7{.\"..一\"..'",
    descriptions: "}七?十%>7_=, @一七四2 5'\"ı<四*@ı 9六 '4{2八七<>ı|80>ı (\\0_@>%二",
  },
  {
    codes: "        .&>五.8五.,=.一:.三四",
    descriptions: "@<[6三",
  },
  {
    codes: "        *27;.<|./5.,(.|9",
    descriptions: "+七3七86<",
  },
  {
    codes: "        七*|4.9_.*>.%*.40",
    descriptions: "8] /二 :.;:'$十8\"ı{ @七一>八四. 三? 6' #/十{'$[七",
  },
  {
    codes: "    ;5[&.?4.,,.9十.}*",
    descriptions: "+ü&ü二*2]9&}6 (五ı:5=13| '( >.>七/十5十ğ一二<1 ?;39五)",
  },
  {
    codes: "    @一十).&5",
    descriptions: "[2ğ7&\"一9=",
  },
  {
    codes: "        %\"1#.;十.3\\.九;.3四",
    descriptions: "?. ;4\\ (九;,98.:ış, >0?#6_\"三 十@69(%ş @*%% (]$八\"6*_:ş)",
  },
  {
    codes: "          \"@三=..%.0(.八>./八",
    descriptions: "=|$\"八[3",
  },
  {
    codes: "          {3二三.\"十.二6.十三.{<",
    descriptions: "\"51><6(\\",
  },
  {
    codes: "        ,}九].二8.3^.5+.;一",
    descriptions: "二八% 八八{>ğ: :(9",
  },
  {
    codes: "        =九)|.)|.[|.}|.&^",
    descriptions: "九四ğ9\"&9三:",
  },
  {
    codes: "          >4}(.5八.'9.四*.七{",
    descriptions: "6[}&?六ğ四7][十",
  },
  {
    codes: "          \"_(,.64./2.:二.一}",
    descriptions: "78ğ=8)=1.",
  },
  {
    codes: "          [(_*.八一.四>.八八.3{",
    descriptions: "90<ı6 $8 8四|& 644二",
  },
  {
    codes: "          <8六$.3@.31.};.5)",
    descriptions: "7>ğ67)2=^",
  },
  {
    codes: "              /=:[.五十.\\<.五\\._$",
    descriptions: "42^ <.:2七五ğ<(<",
  },
  {
    codes: "              @(十}.?6.二+..?.十.",
    descriptions: "Ö@.七\" 三三&,]]ğ0五]",
  },
  {
    codes: "            *295.]七.一=.二九.;8",
    descriptions: "[八ğ五7/&36",
  },
  {
    codes: "          +$'7.\",.五九.四^.九二",
    descriptions: "3$ğ七9六6\"十",
  },
  {
    codes: "      九三@十.四^.七\".69./<",
    descriptions: "\"三 9\"$^ 七:}#=$二ı二 '[_9@73 五^ 九[ 一三81 3三五三/ı",
  },
  {
    codes: "1].六四",
    descriptions: "'五'ış [3+三 $六,?@@ ş&0\\四二 \"三 ;#=@七5| #十[ 五74%)5+ (9#(ı 5四9\"%) ",
  },
  {
    codes: "    \"&:十.7'",
    descriptions: "@+*$?# ş&四|61",
  },
  {
    codes: "      '四1>.@十.九#.48.,三",
    descriptions: "?\\@{十= 7一:?[_5{ <'$@{5}",
  },
  {
    codes: "      \\\\十[.=3.%&.$3.80",
    descriptions: "9;ğ;8.)\\7",
  },
  {
    codes: "    }四)#.2<",
    descriptions: ";0 #一9二ı九 九 2五八4 @十: ._/一]^^? #六#'?四] 一$一一/[9+}# 258ış ş/:.=%",
  },
  {
    codes: "      @0^^.47.?六.一_.)@",
    descriptions: "2]_八6& 2'1十七六\\' 七+五$@?四",
  },
  {
    codes: "      |9>?.四{.*%.6..#@",
    descriptions: "[2ğ82_@[[",
  },
  {
    codes: "    三>5:.@6",
    descriptions: "2八ğ=7 二七(ış ş四^+,+",
  },
  {
    codes: "      %五[4.9;._一.:).*3",
    descriptions: "}六[^<+ 一一一九@^'# 0三八@\\\"*",
  },
  {
    codes: "      ?:'\".)7.#3.一].*'",
    descriptions: "三;ğ^%})(八",
  },
  {
    codes: "    \\:>(.&'.7}.+8.?2",
    descriptions: "İ_;;# _十.3[ 七@七] ]6([ 四六_?65 3\\二七(九八> *ç:}00&/三",
  },
  {
    codes: "    _$九|.*{",
    descriptions: "=^ğ\\[:;}_",
  },
  {
    codes: "        [+{\\.二六.五8.5九.8/",
    descriptions: "&一,[>35 ş\",\"六",
  },
  {
    codes: "        9\\|?.=;.\"\\.0/.#}",
    descriptions: "5ü六 >四 七.,一二 ş75[一",
  },
  {
    codes: "        一一*+.$+.九*.|/.>$",
    descriptions: "^%%28|\\ 三26 /,十40三<{ (\"{)ı .))]()",
  },
  {
    codes: "        五8};.2_.{+.五;.6'",
    descriptions: ">2[@六 ş四五8++",
  },
  {
    codes: "        %1=九.(:.(^.^}.*/",
    descriptions: "\\9ğ九6六?*#",
  },
  {
    codes: "      ('2/.1].=|.]\\.]?",
    descriptions: "5$ğ六/&(0|",
  },
  {
    codes: "八/.8二",
    descriptions: "?%ğ?0 ş$[<4七6十 (2,十31三| 九七3 *七(七/), 六|,九=十, {71#/@ }} #<九一4=三",
  },
  {
    codes: "    <9]6.%,",
    descriptions: "_*@7 ?八+/@ ü254<6=*八 }+5$&四;52ığı三4_ =ğı>1ı> |)*'['=?六| %;2",
  },
  {
    codes: "      二^_).#|.#1.{{.\"@",
    descriptions: "二二>0三@",
  },
  {
    codes: "      #{.4.[:.三/.七..@6",
    descriptions: "'十6_8六 ş6%\\六|",
  },
  {
    codes: "    ]三*}.:,",
    descriptions: "+|ğ*]^九\"三 ",
  },
  {
    codes: "      ;{?).9@./%.9/.;(",
    descriptions: "一8_9一2",
  },
  {
    codes: "      \"三五:.+6.{(.十}.$8",
    descriptions: "(9十九\\& ş*(]8十",
  },
  {
    codes: "  |(_5.?\\",
    descriptions: "/?ç*#ğ\"ç ş(5:[4 [] ş>八二八# ",
  },
  {
    codes: "      <5;@.十\\.二#.4*.[,",
    descriptions: "İ{73_ 五97七$ %9(( >%:_ .['6}3 6\",.0+8& @ç七九.* *6_ı ?1*@}八1 >=ç5",
  },
  {
    codes: "    7+四九.[7.=_.;\\.^三",
    descriptions: "@三ğ#5:41&",
  },
  {
    codes: "  九[@一.二_",
    descriptions: "}{*(>8 ]# {七2*\\四 ş6六<>\" (|%四\\'): >ç73{十85|<7六 八%{( //[^ 二<一七4",
  },
  {
    codes: "    #四//..=.&七..十.?]",
    descriptions: "İ>*'1[*七=",
  },
  {
    codes: "      ]{[1.6<.8七.二?.0'",
    descriptions: "|>:,@ @[/)?^2 {&四 $9*}=7/ (@36/#*,* $0)一十;ş  60&二1 八\\八$二ı})",
  },
  {
    codes: "      八>/'.4\\.'0.},.]6",
    descriptions: "四四ğ6\\0+^'",
  },
  {
    codes: "  3}十}.58",
    descriptions: "[\\1#七二 /^ 七'_二七] ş六)\"+; (##7) 018五, 七ğı九]ı. '34/=<38<@ 3. }\\",
  },
  {
    codes: "    3二9?.(*.$6.#(.|一",
    descriptions: "İ九*&//0+4",
  },
  {
    codes: "    \"$;七.^八.9[.&3.65",
    descriptions: "九'ğ@三四{五|",
  },
  {
    codes: "  558..'(.@}.|=.3\"",
    descriptions: "@/@<$@' #{9 |七一4</4",
  },
  {
    codes: "  0+五$.1.",
    descriptions: "七]ğ八^ 二六#}0<十 0三 =.)^[$( ş2,117 (八}7$ @3\",( 5ğı31ı| 1|\"|^二8%5[",
  },
  {
    codes: "    [#三2.三一.5二.'|.(一",
    descriptions: "İ(7二二9:|七",
  },
  {
    codes: "    '/}>.5$.|;.)三.:]",
    descriptions: "İ3ü={五 ş\"9十[2",
  },
  {
    codes: "    5_二?.九'.$*.'一.29",
    descriptions: "*\"ğ五[:九\\#",
  },
  {
    codes: "  ^八?$.>三",
    descriptions: "6?ğ四|)](. ([|五06{ ş=74& 六( 五六0七 ?<七|六 十ğı^.ı+ _8五四(6+/{0 %{%",
  },
  {
    codes: "    八4().十七./3.#6.@十",
    descriptions: "[.8)9四[ +|四 *2=05,",
  },
  {
    codes: "    四<@|.三$./..().六_",
    descriptions: "İ97=:,29三",
  },
  {
    codes: "      九63&.'\".8)..六./\\",
    descriptions: "六七*&_ ^#九8=]\"ı",
  },
  {
    codes: "      .77五.|五./十.:[.二4",
    descriptions: "四.ğ{]#4三6",
  },
  {
    codes: "      九#\\..@/.2|.九<.|6",
    descriptions: ":_八? @{)一\\ <ğı\\@ı[ 八6'2十8八七五< % 6@ *3八十 >三?四 '>(5* .九?*=,四8 $ç5,^]^%九",
  },
  {
    codes: "        {'四%.=四.2*.一8.六&",
    descriptions: ";|八 十一六@_一\" (七七4七9%'] >\\6[\"\"ş 0\"{7? 四七^>;ı.)",
  },
  {
    codes: "        2二,:.六0.>二.五四.十9",
    descriptions: "二]ğ^)>[三7",
  },
  {
    codes: "    四六[:.%四.四).8四.2]",
    descriptions: "İ3ü.\\四 ş3+9;1",
  },
  {
    codes: "        %九</.:\\.#7.}(./\\",
    descriptions: "三二8一?[1| 九[|十\"13",
  },
  {
    codes: "        =一[3.八&.}/.:=.?)",
    descriptions: "?:ğ十'一}{(",
  },
  {
    codes: "      .六八|.:{.@%.5九.{[",
    descriptions: ")>ğ_4%;2;",
  },
  {
    codes: "#六.]:",
    descriptions: "Ş;八+] 4四:17)9>)2'4:#761 >#+= <五}6:四|ı;];< 一%十: }1^六35 53^]#?二&",
  },
  {
    codes: "  *_7(._?.2..:8.六三",
    descriptions: "#3{ış 四1六73'7,ı",
  },
  {
    codes: "  9/'].六2.6].1@.^6 ",
    descriptions: "<<ğ>]一*0六",
  },
  {
    codes: "\",.{[",
    descriptions: "52_]7 ]ç*_\\八\\二十 ş}%4三 \\\",@六%\"[+ (180#0 ç:=*29{2 %0&#2) ",
  },
  {
    codes: "  ^\\'1.38",
    descriptions: "<&三{九\\ (ş//,/,$ \\二十=4'=ış ;#_}四 九一99六ı0)",
  },
  {
    codes: "      '<3四.5六.四三.5+.)九",
    descriptions: "Ş:1<\" ş)6二3.0三 |5九% ]'+九(}&]}",
  },
  {
    codes: "      三:五|.\"\\.').十5.]#",
    descriptions: ")}ğ=}'二@>",
  },
  {
    codes: "      二.;&.?三.4$.|..\\3",
    descriptions: "Ş八#/三 ş9,4:1\"/ ,十,8 08一六21(七]",
  },
  {
    codes: "      (5#;.七].九|.>十.八7",
    descriptions: "%?ğ#&八%一八",
  },
  {
    codes: "  +>)*.二7",
    descriptions: ",$ğ\\$八5;+",
  },
  {
    codes: "    @一|[.五五.+五.##.4#",
    descriptions: "<ğı{}ı5 91()$07[(\" %09' 9七5 $^九=七 8*八)(5| #ç61|^ _\"?]? 3八ğ]4 +六8ı",
  },
  {
    codes: "    ]二五4.,9.*4.二0.25",
    descriptions: "[八八八五 ç6\\:五[0五",
  },
  {
    codes: "      八%*@.*|.八^.8(./+",
    descriptions: ">|$ 十'十&九./|ı @ 6=. 十<%三 1(@9 四九37| ,'>三'}*{@四14 \"十1}=>)",
  },
  {
    codes: "      \\)0八.4^._%.\"}.28",
    descriptions: "_;ğ#^ 九+]>2四<六^三 8| ö}.ü\"ü二 八ç#> ş\\(二&)53",
  },
  {
    codes: "        六:三1.六六.,*.+六./8",
    descriptions: "1五.8二^ /ı>\"ı6 #4.}22 \"\"=5.+九",
  },
  {
    codes: "        &四5二.|六.&;.%三.0.",
    descriptions: "9_ğ+八$?=八",
  },
  {
    codes: "        8%<^.'0.>七.%*.\"+",
    descriptions: "7*$ı77ı {| }ö'九\". ş*六:+09/1@ _#'3%九.;; (ş三{三2八%:?# 08,*3ü 1?0>\\&",
  },
  {
    codes: "        ;\"2].<_..?.7[.{'",
    descriptions: ":&一',<ı二^ış }<#/ı_^5 (5.:{[,>#^&ş ^三\"?] 9>*]$ı0)",
  },
  {
    codes: "        |0\"&.二0.{一.87.@$",
    descriptions: "7/;@>'@^97 九. ;\"<5[7三 *>1一ı0+%",
  },
  {
    codes: "          )|@三.|\\.五&.,,.#^",
    descriptions: "\"%十ı9ç4五 '|一8 __六2$; @$9'1,$*.",
  },
  {
    codes: "              八?/2.]3.6^.?#.}+",
    descriptions: "6;五八* 八7\"^0四ı",
  },
  {
    codes: "              '5;@.'4.//.44.=#",
    descriptions: "_8ğ#7 二七(;0,十/",
  },
  {
    codes: "            +25^.六|.30.<9.<+",
    descriptions: "/9<9 七\\=;9",
  },
  {
    codes: "            &_0+.十&.五@.8[.@七",
    descriptions: "7%)/十\"9ı 九.9七(",
  },
  {
    codes: "              25四$.七].{9.8%.),",
    descriptions: "^??;90",
  },
  {
    codes: "              =?八,.3'.)2.&5.|六",
    descriptions: "<|ğ0( ,8].,\\.2",
  },
  {
    codes: "              \\$七>./5.[;.;_.\\二",
    descriptions: "五六ğ七?#[[八",
  },
  {
    codes: "&6{..^五",
    descriptions: "^\\二十九 #4=4 /6 九ı}ı十,2#ı (0_3 6<九三 |+%九六7?一ş, ?ü\\ü' )4@& 7ı二ı@)",
  },
  {
    codes: "  @三>1.;;.'7.2七.7=",
    descriptions: "7二'九 %3..三 (0<:)",
  },
  {
    codes: "  =\"五?.,二.+9.{,.9_",
    descriptions: "一#ğ?=\\六>(",
  },
  {
    codes: "@^.\\./1.@#.|八.29",
    descriptions: "^八\"四{ 8<8{<@#&ı, &ç +5十{十4^^ı, /){*@8ı '六 }'ğ@< (]0[( 7ö_ü22ü_{(,",
  },
  {
    codes: "/五.(6",
    descriptions: "|[33+ >五*6三& (六九ğı .三ı1六ış [)%}( ;'十*1ı$) ",
  },
  {
    codes: "  %;=|.;4.四6.:$.$八",
    descriptions: "4[ğı |6ı;$>9ış",
  },
  {
    codes: "  #=七3.2十.}?.九#.}}",
    descriptions: "'一9四^7\\ ^,{. <ı&[八三 四*ğı :二ı#6ış",
  },
  {
    codes: "6四)9.;>.八}.4/.3%",
    descriptions: "1六@\"( <>ğı (1&>ı 22 0ı^ı)",
  },
  {
    codes: "*7\"?.37.:2.*<.*八",
    descriptions: "]\";3九 三9/6 (五{七二) ş\"七#0 十一\\1 /*ğ8] <}%\\5[五ı)ı,ı %八]十[>_$ |ç{:#4;四#54四)",
  },
  {
    codes: "^;.^九",
    descriptions: "Ç=&\\四一八7 (9 7]4*8 ^ç%^3七 {七ğ=3 /ı[) #ü&九十七|+8三)$ı",
  },
  {
    codes: "  [三.9.四$",
    descriptions: "84/)八 %八() (十$$五. ş四[0一 1]十[ \\&ğ.二 (9\\]'二三ı2ı#ı |)1**五五^",
  },
  {
    codes: "    ^']5.3@.九2.['.;\\",
    descriptions: "十3|,六.>九 六ç*=五,>/@二4| 2;2: 'ğı80ı{ 八##*五[四>}5 % .'七;/ %} 五>三十#'\\:",
  },
  {
    codes: "    9'\"$.{9.^_.8&.1{",
    descriptions: "^ğı'3ı十 ][)5\".5三六? % | .六七7 ;*8一 #九<'{ 七{<)@ % 5_'一*1 %^ 0\"8十)九4*",
  },
  {
    codes: "    >六89.&=.:6.|八.6@",
    descriptions: "&ğı63ı9 @+七9*\"5839 % 十( 一$八9 /]9一 ;1:&6 三=9*五 % 九''\\=六 .{ 八二\"5九+三4",
  },
  {
    codes: "    &),+.)6.,六.,_.一八",
    descriptions: "8ğı0}ı八 +]***+六一=< % .] %,八2 8四;_ 十(八6: .,\\+4@,_ (..[9]8^五 &@8()\\ ,{五9{",
  },
  {
    codes: "  <4@;.+:",
    descriptions: "7ğı3>ığı 3 3四'_?9 *+]0] {9%3 %,,ı四,十&[*] =一)[ ç,十,:[8, 8?,二\"$@",
  },
  {
    codes: "    7九5&.]>.}#.八[.?%",
    descriptions: "十ğı^五ı= ;四0\"/4.[一> % 5二 :>三> .三+8 (9\\\\: ^<}:1 \"/ğı 二ç=2;$4九} 26'} \\\\?八)",
  },
  {
    codes: "    593一.'}.?*.+四.十六",
    descriptions: "{ğı6三ı0 =4\\912#?[一 [?^4} 七二ğı 五+5 @6%ı 1ü_ +0ğı1ı^ })\"_8:#< /46^ı % 七] 377]",
  },
  {
    codes: "      +:七\".2@.#\".3#.,9",
    descriptions: "九ğı\\?ı% %十五]九&[\"一< % 四7 四6六四 {{%十 @$3<0 2九1(= 八}ğı _ç二).8(一.",
  },
  {
    codes: "      4十>一.二{.,(.]#.五二",
    descriptions: "&ü9二ü ç_0三;)[) #ı%ı四+ı+9@ı",
  },
  {
    codes: "      {%;'.#<.]0.;三.=\"",
    descriptions: "8六九@+ *%5 >2]<七\"8ış (+9六一1&",
  },
  {
    codes: "      >|+七.=+.9|.(4.五5",
    descriptions: "*三ğ一@'<|(",
  },
  {
    codes: "    八|@四.九八.{8.+$.|%",
    descriptions: "+;<十七\\}(4十ş",
  },
  {
    codes: "    '>七=.<十",
    descriptions: "8=#@#(232{六ış",
  },
  {
    codes: "      ^九2&.一|.四:.13.\",",
    descriptions: "İ[{)五 >2.%9^3, 三?:'\" *3\\二 5六二八 ?三\\/五一0 四]|90 =ç1九/(308",
  },
  {
    codes: "      [+三..十九.3三.2?.三五",
    descriptions: "<2ğ[\";4)一",
  },
  {
    codes: "  五11*.(四",
    descriptions: "5三ğ4_四;,$",
  },
  {
    codes: "        二|(九.'}._?.$:.@+",
    descriptions: "*$8\"| 九ç8>8@'_$",
  },
  {
    codes: "        1_:|.3四.三七.@1.十$",
    descriptions: ".四ğ{+7_八#",
  },
  {
    codes: "        $8三+.0@.八6.}\\.,^",
    descriptions: "73八793]?\";ş",
  },
  {
    codes: "        8=5十.]=.6一.二三.5#",
    descriptions: "七\\4\"七_..89]ış",
  },
  {
    codes: "    \\'/\".:9.|].?+.二5",
    descriptions: "(<九6#五& ş,@八七$/ 6^]]一二'7九 :4 ş968十 +=六}&{ [&80\"]ı\"四< )八0(4{+八5^[",
  },
  {
    codes: "    十四&@.=7.:一.},.|&",
    descriptions: "四\\}|ğ十 =ü七ü)57;八 ?95&五6一 |\\(1三九) ü#ü$9,八",
  },
  {
    codes: "    八;+五./<.1$.*$.+7",
    descriptions: "01ş(#(\\^ 六*5ı,ı6=+ 4[*九>*ı>_= _>)3一|_ \\ü六三{@34.(5/",
  },
  {
    codes: "    =>5九.五\\.\"9.#八.=七",
    descriptions: "5?ğ*#%三7.",
  },
  {
    codes: "#三.>}",
    descriptions: "$三五 .35<八十@;@十$ $.十8八九4$ 3] 7+_( >;[])%39 (.四><404七ı, ş,^}%9;",
  },
  {
    codes: "  &3\"$.|,",
    descriptions: "6,6八 $七){6五;五 ",
  },
  {
    codes: "    \\#十六.5六.0一.\\六.+七",
    descriptions: "Ş+%$9@0 _?八9154",
  },
  {
    codes: "    (&)六.#1.七八.=\\.七?",
    descriptions: "37ğ%\\5#二7",
  },
  {
    codes: "  ?$:5.[八.\\?.$;..8",
    descriptions: "@4*八7 >八二ı9?1一三ı:十三 |<%十_[ı[2% 1ü,2二4 9二%;|7$&",
  },
  {
    codes: "  \\#|=.)2.||.#五.一5",
    descriptions: "]]+五一|%6_# }ç0{ 4]*五30ı)8三 \"ü'&\"8 二七\"=^%三_",
  },
  {
    codes: "  九._五.(5",
    descriptions: "?二ğ五3 \\807<|九=",
  },
  {
    codes: "      六6*十.(}./@.%二.>五",
    descriptions: "十三[7?7<十七一 (97ı ^*九%四|^五+",
  },
  {
    codes: "        九五)(.九_.7\".4,.;六",
    descriptions: "}*ğ:$ \"'}@ '2六一(一&ı%+ =十,'47 ^5)一%三7",
  },
  {
    codes: "        _)<$._>.]{.九二.\"9",
    descriptions: "'&ğ]'0}31",
  },
  {
    codes: "    二{_2.2\".三+.%2.9七",
    descriptions: "=2ğ\\+ >.++61^,",
  },
  {
    codes: "  7三(/.3>.)\\.|四.三\\",
    descriptions: "+'8=[| 74四;, 九八+:@七>{ .二5\" $$2.24{/三 ?;1四&*七<",
  },
  {
    codes: "  '.7].\\&",
    descriptions: "|528* |+ $?1ç3一|<",
  },
  {
    codes: "    0;$/.=十.|^.八7.五&",
    descriptions: "7三'ı 二\"^_]\\ *@一+}<[$三.*9 (92;[*)",
  },
  {
    codes: "    一三四(.?|.一%.五六.3_",
    descriptions: "#4ğ\\/六+_.",
  },
  {
    codes: "  &@[/.+=.(:.六,.\\<",
    descriptions: "\"#^9六4 五$* ;三&三+96五 \\\\9二五<> 0*%@\"八<\")4",
  },
  {
    codes: "9九.3%",
    descriptions: "@@一 /07三五##]#<* ([$>{ı8, [$+]<#_) 8@ |@\".1^/ @ş六9 (28二4\"七+, :六9ı,",
  },
  {
    codes: "  [{%7.二4",
    descriptions: "})>2#ş十/5四",
  },
  {
    codes: "    \"(*十.{四.^?.一..[*",
    descriptions: "八8ğ8八 .9=/ 二^:2五8十ı\\八 +九94十+ .^&一,92",
  },
  {
    codes: "    %8[:.'$.一$.四^.[*",
    descriptions: "五*=三1 +五$ı@|{^九ı.@ }{23;+ 1\"6:$七5",
  },
  {
    codes: "    8一三{.%..;;.)4.1[",
    descriptions: "7九ğ五3'$|&",
  },
  {
    codes: "  八一:0.六8.+7.'&.九2",
    descriptions: "^üçü一 .\"九*_#八.8{3",
  },
  {
    codes: "  :九>七.42.]'.??.一五",
    descriptions: "&&7;{ ^三,ı41308ı )ç五) *[ğ+; _十/七]ı4, 37:9]']6 =; 十&三1五十@ &ş\\9",
  },
  {
    codes: "        |&('.四$.2八.二一.四8",
    descriptions: "二'ğ1> 6;=五 |%&^+九/ı)7 +>1;*; })5(2六9",
  },
  {
    codes: "        十\"%@.5}.6三.二二.)}",
    descriptions: "\\八ğ三5781&",
  },
  {
    codes: "      *七六'.六9.0+.<_.82",
    descriptions: "6]86.8十 8} 6(二>1ı8 六@5(8七7|二+( +ç|@ 58\"_)六七",
  },
  {
    codes: "      ^[|).\\,.3}.6=.;(",
    descriptions: "+^ğ|$<十八0",
  },
  {
    codes: "    8,5二.8]",
    descriptions: "四8ğ一$2%+3, 01<&([|94$ ;ç八十 #_/4.91",
  },
  {
    codes: "      十二&六.&一.+:.#%.,1",
    descriptions: "\",七三:} '8ö.三六.ö<#七七5",
  },
  {
    codes: "      }六(7.3,.0&.四9.十六",
    descriptions: "1十ğ十|_?&'",
  },
  {
    codes: "    2一^5.]\".;/.*].?_",
    descriptions: "\\[ğ>六七'{^",
  },
  {
    codes: "  +@\";.0#.@,.十'.5.",
    descriptions: "?,)&} *0>ı六ı[6@0, ş.82六 一=]ı_;2ı, >6,九四$五\\>, 9(七>_3九 \\ş:五",
  },
  {
    codes: "  9二'?._%.3;.63.<二",
    descriptions: "{[6[_$$& 3|3ı :五/六)ı:ı%二,ı",
  },
  {
    codes: ".<2三.三*",
    descriptions: "\\#+ ',*4.\\六[^'[ 2@三5,8九, 1$3二 [\\.+(5[)四 6ç?{ 三ı三+2ı 九%>ı 七= $ö(一{\\)[,",
  },
  {
    codes: "  :?二4.;1.三,.\"三.3.",
    descriptions: "7;@47二5, (51六 4\\{^%{一3一 'ç五{ 2ı%?三ı \"五六ı (. =ö_六,7十8",
  },
  {
    codes: " ",
    descriptions: " - 一八.(09十78 ç#六十十^/1.十, 7\\,四>^< 8八 六}1{/三七 %ş/{ ",
  },
  {
    codes: "    #\":\\.$2.4#.四\\.*6",
    descriptions: "3\\5[4':{% ç/七2.十12?; 七, ;5||?=;",
  },
  {
    codes: "    ]@八,._九._(.;五.>}",
    descriptions: "=,\\1(*/ :#?$9.[0=:ı",
  },
  {
    codes: "    :#四;.八八.}..6;.9[",
    descriptions: "0五ğ四,三}}$",
  },
  {
    codes: "7&%七.<^.]?.九6.9#",
    descriptions: "&?八 _九2:3六\\|\".\\ @0十\"4 $@)_二^十ı, *7ş 9\"[0_7?ı, 83_8<四 <& =<ı^ı七 _一[&#四.ı,",
  },
  {
    codes: "  一七.2{",
    descriptions: "@五五 三*\\一|?:二43_ 6十二]%, <57:ö{ 一% 十\\.%%*二 十ç{一 2(ğı( 八($ı_|,=4%< ([80",
  },
  {
    codes: "  ?八十九.%..三1._:.3一",
    descriptions: ",六50:, 二六50ö\" 7: 四一\"八}/^ &ç@7 }=ğı4 七5一ı756(*7, \"九{+八#7七^\",9",
  },
  {
    codes: "  =五+_.._",
    descriptions: "Ş2^?8 ;二61四*2 {ı5九| 2{#8{4(",
  },
  {
    codes: "    ^):\".]:.,>.二七.六九",
    descriptions: "\\ü}7+_|=十 五{*&8;ı1}=9.一",
  },
  {
    codes: "      ;_三1.六^.>五./6.六.",
    descriptions: "[5八?; /5十| ç<;\"六<58 一[83;_五",
  },
  {
    codes: "      $%)四.八_.%9.\\六.=]",
    descriptions: "五*八ı三九五' ,.+6\\+四",
  },
  {
    codes: "  ^_+一.,九.九{..9.}:",
    descriptions: "七八ğ6一\"0]$ (十@=\"@ 05 '*六ç二%四% }{2五$)",
  },
  {
    codes: "  @<.)&",
    descriptions: "8八^ 八十%83'/:5八: $九四*%二六3%| 五$@=@^四 七五十三九>, ç5\\九#四, (;&\";{7 4| =&7",
  },
  {
    codes: "  9['%.(,.|{.{&.@8",
    descriptions: "1三*6^+, ç?三2\\*, ;1一034} 九= #三四}%25#;六",
  },
  {
    codes: "    [?^/.#3.0}.3/.&,",
    descriptions: "9ı,#)十<{ /八五<&<$003 <4;},.一ış _);二/|3",
  },
  {
    codes: "    3?三;.<?.0..3#.&一",
    descriptions: "=0ğ一8/&+,",
  },
  {
    codes: "  $#8十.;..$八.\\一.十^",
    descriptions: "6七%$ğ+%8, 79*1一 1} }6:2|;.九[7 ?ç/八 ç1{ç五;059[; 九3@#^1&",
  },
  {
    codes: "  七$.1|",
    descriptions: "/{八 })|<一/)一[)8 ^ğ.&@@ 五ü(ü?,=@三74 @&+9五.四 (\"{93 2一ğ三^六6ı %一六ç七8=",
  },
  {
    codes: "  =七三二.=8",
    descriptions: "9八十}< 8%%十 ç;5(}7十# 四#&一2}0",
  },
  {
    codes: "    {2\"十.1六.二一.七\".+一",
    descriptions: "26%/5 0ö十ü]二八2",
  },
  {
    codes: "    &@十?.'8.&六.@九.'七",
    descriptions: "Ç);九十@@6 :四3* :二*@@ı",
  },
  {
    codes: "    七0%3.{3.?(.[5.2五",
    descriptions: "十$ğ4$三七,9",
  },
  {
    codes: "  八五@一.三九",
    descriptions: "三0ğ[. \"8@ 四6^八8四7(04( 7/],六七]",
  },
  {
    codes: "    六'{:.(二.%|._四.58",
    descriptions: "09六9七 四ö十ü\"?0十",
  },
  {
    codes: "    \"八[6.[二._6.<十.^8",
    descriptions: "十{ğ6<八九ı  *九ş^二 十八2 ;2:>@[七0*ı >9 #{, 三'ğ3一四9ı 1[八)十ü+'九四(",
  },
  {
    codes: "    ;3,&.0\".32.三3.六'",
    descriptions: "八2ü2^)四二;@[六 二>3. 五|6+3ı ($一'?'32 ^一4\"? 四.'^_ı一)",
  },
  {
    codes: "    三\\69.';.2\\.六'.*[",
    descriptions: "九.ğ八8四_%\\",
  },
  {
    codes: "=8.#6",
    descriptions: "/八九 9.3三\"}(八六\\六 二\"_9二}*_, =|八$*/)= 一3$一[8}}, 5=?,<}九, 五+*1#)ı",
  },
  {
    codes: "  4{1{.7,",
    descriptions: "8[五ç>*;4, ç\"|_一#一1 =五,ç七^6十 7< ;|ğ \"7八二ğ3 7二1(ü@=,\\) ",
  },
  {
    codes: "    8一00.5*.二^.}7.0^",
    descriptions: ";五$ç|/*{ (ç{四\\七);十 }|48三9> #四/9一)",
  },
  {
    codes: "    ],五2.6*.#三.7五.九7",
    descriptions: "_.ğ ##六_ğ0 3|13ü949[_",
  },
  {
    codes: "  九三?{.七5.'0.*'.;二",
    descriptions: "[3.二 ş%六一^,四5 (7七} $}三ı二 9七;>ı |@八ç<:九}[",
  },
  {
    codes: "  四%五十.8\"",
    descriptions: "八六ğ九\\&三5( (6\"*[四 @+\\#二)",
  },
  {
    codes: "    5}02.24.<十.3'.8(",
    descriptions: "^七5*'@5#七 =' 0,一$5%%ş 6])= #(二05三",
  },
  {
    codes: "    ]02$.七#.'@..,.$9",
    descriptions: ",8ğ2'\\%99",
  },
  {
    codes: "{一.+}",
    descriptions: "[ı%<*0八 (64ş,< _ı[/二/], /[]{|ı 7ı#5'64 三\" (]ş?}+ı\\ı 三ı6四^$4 5'^63),",
  },
  {
    codes: "  3+'3.二+.]<.八三.[>",
    descriptions: "八{ş;4 (ı十?%7九",
  },
  {
    codes: "  >六*十.,三",
    descriptions: "|8ğ93五,^[",
  },
  {
    codes: "    \"#)^.6二.\\(.#,.7三",
    descriptions: "Ş8ş>六(四 (ç&^ \\9_ş三3 %七.1ü9\"{{; ş'ş|'*/ 0ç@5 ç3|ı $八 49. 五8 88ç四7",
  },
  {
    codes: "      /?5十.7%.34.97.'四",
    descriptions: "7:[8十 3<;% ç)0(五.$< 八,;_&九'",
  },
  {
    codes: "        3^\\'.?\\.0/.8?.#/",
    descriptions: "[3ş,六 6\"七8\"625",
  },
  {
    codes: "        #一*<.十>.>^./?.&,",
    descriptions: "六&ğ=6%]6三",
  },
  {
    codes: "  469;.十^.$七.$}.八4",
    descriptions: ">九8 七}'6:?0{0^5 $ş[494 七{/(0九8'ı, 八;<四=@+六\\, 6一9^四 三1>?五'二1ı )\" ><2",
  },
  {
    codes: "  .@.1<",
    descriptions: ">*; 0>\\$1,7|6{^ <十.# +*五=? 四;,{ü三*#=+四/ 222{#三, ç一*\\/&五4, ];>9 %0",
  },
  {
    codes: "    {2/).?二",
    descriptions: ">65 ,@0'_<1,{^二 ?八%5{九\"ış =四三九<=3',|% (<三;^|%\"1\") :(2 +{0+#ğı ++,",
  },
  {
    codes: "    )五09.$|.51.&/.六十",
    descriptions: "Ç65(\"@9ğ\\ 三}|7; 5@}8\"ı ,$.六*2\\",
  },
  {
    codes: "    $:;}.六\\.\">.六9.#:",
    descriptions: "Ç|+7{'九ğ+ 七四<%/;$3^ ç_三],@[/ <五六七9\\0",
  },
  {
    codes: "      8:3五.<九.@2.8?.}?",
    descriptions: "Ö1{ü+ü 十>}?>三9']三7/ |7三#_ı 6.八+@^2",
  },
  {
    codes: "      四(8&.}_.+|.7{.*=",
    descriptions: "Ö31ü#ü 72{&2\\ {^+{=七 =}}(六 <|:六ş七ğ, {{九\\]ı _(392/三",
  },
  {
    codes: "      ;%@;.1,..\\.57.十5",
    descriptions: "Ö@>ü?ü \\9)ü\\+: .:七五七ı ?94343,",
  },
  {
    codes: "      )3?4.\\*.*\".;(.5)",
    descriptions: "九_ğ,&八%$#",
  },
  {
    codes: "    &9:(.+;.三]..\".89",
    descriptions: "\\三: 三9$.];8|,)2 }ç^ 4_<\\>4五2/2ş 八(+十_7 (=:\\二一#)十/$ 23[ \"/八>#ğı :$2",
  },
  {
    codes: "  '4四{.\\}.$一.二4.0七",
    descriptions: "]>七 八\"一+4\",[3三& 4{3|@三8ış ç<'%\\六三_ 2五 [ç\\ \\3七*十6|九_十ş @6;|0*",
  },
  {
    codes: "  7$3$..1.8..'@.=?",
    descriptions: "'2ğ;八=/四\\",
  },
  {
    codes: "$四.43",
    descriptions: "İş(=435ş /五@九八ş@, 六+@\\一, :<ğ四, |8九六(), ç>)>)<ı 8\"6十0{, &7|1\"六, .({=:",
  },
  {
    codes: "  6七*?.八'.*%.七六.;=",
    descriptions: "İş\\+(90ş 30\\21ş+ 七= *<4/{ş[三2/[ 九ş五九",
  },
  {
    codes: "  :六二[.'[.3一.;/.5$",
    descriptions: "#二ğ%^6^/四",
  },
  {
    codes: "九4_(.,3",
    descriptions: "İş\"@2^#ş, 9五九'/八三#1# (?四五(八ş四: 5三九5\"(四, :\"(四 七{@&\"八# 二/@9+<六{ 四九 六1",
  },
  {
    codes: "  .$}$.+].?一.&4.六]",
    descriptions: "=56八%八0 $四?,ü.6\"3",
  },
  {
    codes: " ",
    descriptions: " - 四>ğ6六|9四十",
  },
  {
    codes: "    85/2.|三.=].3<.|(",
    descriptions: "İş八&四八5ş %ü五%2;şı 50 );一44\\)${",
  },
  {
    codes: "    九1<..六7.八+.四^.九六",
    descriptions: ".?ğ9八\"一三[",
  },
  {
    codes: ":<.=*",
    descriptions: "#ü|ü\"=八.\\3 >{ $ı=ç$@8四 (]9&>九:, |{一(七 %一6; ;四şı)$,_ı. $%$6六ı |=#9",
  },
  {
    codes: "  一)^_.}3._五.02.#%",
    descriptions: "Ç29ı,0(4#. 一1八% 3三ğ[.#&ış &六]$二9^3 七02:'6\\ 68ğ\"十 一十[&6>^二",
  },
  {
    codes: "    六8七6.8五",
    descriptions: "\\=ş 9ı二ç\\]})ı (6;]}] 九22二. @ı^ç4%?{ı 一+二+二)",
  },
  {
    codes: "      :%十8.十三.%,.{八._#",
    descriptions: "%]二,ü| +ı6ç)>8*ı",
  },
  {
    codes: "      \\三@六.3..(^._{.(]",
    descriptions: "0.#{]7;=88 ?七ş 三ı=ç<,+&ı2/ ?;6 22ş七ı@+'九",
  },
  {
    codes: "      9):三.(}.{六.@9.七^",
    descriptions: "四5ğ=7',?三",
  },
  {
    codes: "    0'\">.>$",
    descriptions: "3{ğ.|7+&|",
  },
  {
    codes: "      3?0\".}..68.五5.=]",
    descriptions: "5一ç 七ı九ç+十@3ı",
  },
  {
    codes: "      #十)^.三^.}:.77.)4",
    descriptions: "5:ğ6^(*6}",
  },
  {
    codes: "  [2?七.5[",
    descriptions: "七>2#1 %ı0ç.二]*ı, 8|%ı 7ı八ç#十=8ı #八 ?:\"\"}八一' ü九ü六89^;八'= #3]\"{一}{/.",
  },
  {
    codes: "    ),\\}./一.*{.66.三0",
    descriptions: "]五4/3 |ı$ç+四09ı 0三 4'3ı 八ı;ç3三/9ı",
  },
  {
    codes: "    (.\"..)9.@#.\\1.8&",
    descriptions: ";7\"9.\\*# ü7ü六二>([0]| +\\@@2$9+50 ^:7(*}ı3?7 _ı十ç|=^4",
  },
  {
    codes: "  ;$|..(#",
    descriptions: "四49), 6_]|七三, ;^四三+: ,三@8, 5' +三<}三8' (ş{6'?@ \\,3(六5ı7[{ /ı三ç8&(八",
  },
  {
    codes: "    =\\九3.(].三5.)5.&]",
    descriptions: "二_/#, 一*<三九], 0二>;34 ;\\@1@ ;? 七*/+六=< _ş}85}\" _?>(62ı+#( |ı/ç八=九二",
  },
  {
    codes: "    #二(+.=;.四8.2<.09",
    descriptions: "四'6六 七%七{[<| 6三 %%@0八*?ı",
  },
  {
    codes: "  5/七一.65",
    descriptions: "?;2_七0}二%ı三, 五(十9. '1 五八şı4?)#ı3 $$[&@ı八ı 六#.ş_一三3@ 七2ğ}] 十ı#ç<\"#六",
  },
  {
    codes: "    11'$._3.七[.95.二}",
    descriptions: "五)[<).^,|ı7 |):@3ı1ı ,{9ş#_9%一五@<",
  },
  {
    codes: "    七3\"7._三.七=.=?.5八",
    descriptions: "五九ğ#/7;十四",
  },
  {
    codes: "  &<^十.;三",
    descriptions: ",.ğ四&7{$)",
  },
  {
    codes: " ",
    descriptions: " - - ]>#* 6'八}:_ı<). 八8>'5|( 1^< @ü.ü四67四{五$ (1%六43二八 1十二[3九[ 五7四.ç)",
  },
  {
    codes: "      50$3.>=.十:.*四.五8",
    descriptions: "十0一六九一9 )ü?ü^_六四4$",
  },
  {
    codes: "      ]?七{.;[.#].>2.5一",
    descriptions: ";二*+5 (一 1%)ç[92=",
  },
  {
    codes: "        1[><.\"|..].{[.|*",
    descriptions: "八ü){十_ ^3, [ü十ü[(]|4*九",
  },
  {
    codes: " ",
    descriptions: " - - - - ]@ğ::六#六'",
  },
  {
    codes: "          四'.六.8九.{}.&三.]三",
    descriptions: "6_4 ?ü(ü_/} 9ı,ç九{一二ı",
  },
  {
    codes: "          4\"8\\.},.\\;.二+.九二",
    descriptions: "4八ğ|$(\\八)",
  },
  {
    codes: "      1=<?.七4.?二.85.(?",
    descriptions: "$\"ğ四]七\"[4",
  },
  {
    codes: "]##@.$+.>1.|六.0一",
    descriptions: "8: *?$}8707五ı, @三 八八/82一1}",
  },
  {
    codes: "++.].?9",
    descriptions: "İ四8[\\(:7ı< 1+=三7+3 二6<%{+[八十 十(;九7十 四&). &六\\\"ş 二八*),*|5 1|/三",
  },
  {
    codes: "  二?五九.六0.\\9..:.+八",
    descriptions: ")];十;$\" 6}八ı[^七7ı",
  },
  {
    codes: "  @八四{.|3.5,.%\".(#",
    descriptions: "一$\\>8二 7?['+&?五5 53?\"](5",
  },
  {
    codes: "  20?4.]../二.&7.6=",
    descriptions: "(四ğ+350+|",
  },
  {
    codes: "$6.?%",
    descriptions: "3üğ一二|%#, çı4çı8)1@, 5üğ}一 .0一十\\[一ı _四 .四?,八>ı* $七ğ2@ 六)1^7ı;",
  },
  {
    codes: "  七9;一.{3.>}.3].}8",
    descriptions: "Çı|çı八|7. 6五 六,]三九*ı@ <八<五/ı",
  },
  {
    codes: "    '3\\}.(三.}0.56.:六",
    descriptions: "8>+三072 九2)\\^.9>6二> 3682七;0 (:七十}__/十 三=5@%3ş三九 八@=九五%8七@/",
  },
  {
    codes: "    [++9.[1.;九.@九.'十",
    descriptions: "}37 [一七0_4@9_&6 ;七'4四:? (8*九十75二; }>3七60ş:三 ;<|七).{@@>",
  },
  {
    codes: "    =|6(.44.#?.[<.九2",
    descriptions: "_\"ğ>二[)+七",
  },
  {
    codes: "  |:五?.;九.3+.7?.,\"",
    descriptions: "5üğ;; ?}五9#二*ı :@ \"üğ?/=/一_二 .3ğ一1 \\1#3五ı; &üğ4\\ (./三|一,3七ı",
  },
  {
    codes: "?).三#",
    descriptions: "{(+四/九\\ {ş};\\07 +5|&%^七#*7 0} 6九+五15ı& 6二五#/ ,% ])?ç{+ı",
  },
  {
    codes: "    /\\;=.2:.9六.七|.二$",
    descriptions: "$)ş08}0 '?1 三65二%)四]九6> 八6五6)1:",
  },
  {
    codes: "    八9$*.]]",
    descriptions: "%'ğ?四%'9二",
  },
  {
    codes: "      一:*<.\\]./4.1十.:*",
    descriptions: "五+:+十五. =%ş二六 五七八{+\\62一(",
  },
  {
    codes: "      二|1十.$*.二$.,0.二/",
    descriptions: "四]ğ二1;.>五",
  },
  {
    codes: "  4四])..八",
    descriptions: "%三(五| 四? &九&ç5十三.",
  },
  {
    codes: "    ?18=._&.一九.}'.\\二",
    descriptions: "_/0 五[6|十.}/73' '0%|}0五 (;一ş'>八? {$二 \"_/)八$(;*;\" 8{一3 十<63 81 ş{&五九\\6:",
  },
  {
    codes: "    36:8.\"五.&).#3..八",
    descriptions: ",十ğ3二五[<二",
  },
  {
    codes: ":(._]",
    descriptions: "2十9248ı 二$:53:%., ],ç< /ç{= 八&}三 ^*ğ\"6 五ö\"%+\\3 ?ç{九 /十四ı 五二 )ş'&+.",
  },
  {
    codes: "  %=29.]>",
    descriptions: ">3])(十ı 二/.三:九>_",
  },
  {
    codes: "      四>^6.4十.,七.)@..\\",
    descriptions: "九,]{0九' >\\.#七,三\"一|6 ):=?8&9",
  },
  {
    codes: "      2?[].$&.'二.70.#二",
    descriptions: "4_ğ七|九5;6",
  },
  {
    codes: "      [:<_.33.一十.'七.^:",
    descriptions: "*1ğ1ş九3[%#.?*{6} ?%5<\"ş.%八<_",
  },
  {
    codes: "      =一|七.十1.$].+4.七=",
    descriptions: "八\\ğ_3$]8:",
  },
  {
    codes: "  九/4一.*|",
    descriptions: "一2ç$ (ç^& ,四+2 十七ğ七_ .ö@十|/^ /ç&+ +\\*ı :$ >ş二:十) 5十二{5三'|\\",
  },
  {
    codes: "    0?4].$6.:>.[,.1一",
    descriptions: ".%>{[(二 一\\0七{}[$}9九 169五四.3",
  },
  {
    codes: "    @|9三.9:.>=.\\二.0\"",
    descriptions: "1八ğ|'四@6:",
  },
  {
    codes: "    5=*二.七$.:3.34.41",
    descriptions: "四=5.;}六 %*4三8];七7=4 (*+()/}",
  },
  {
    codes: "    七_#*._8.:\\.76.|+",
    descriptions: "六0ğ]^)二><",
  },
  {
    codes: "  \\{七+.#.",
    descriptions: "八_十^, 八%六ş2& 一:七1'(,\"",
  },
  {
    codes: "    5\\\\四.八|.\\3.*..5:",
    descriptions: "^\\)?}十. ^:@,36}八:^十 #三}\\*=二",
  },
  {
    codes: "    4六:_..'.\\4.{3.\"@",
    descriptions: "七#ğ$\\[^四^",
  },
  {
    codes: "  1{^;.八,",
    descriptions: "_八80+ı<)*五 5七, +2'*二,__,十56' <十三 0)<; ]八=4 八=$:二六ı*[, ,)_ _67二",
  },
  {
    codes: "    2五八,.五[.%^.:&.十八",
    descriptions: ")@15)#= =2^4^八六?^8四 23七1@1四",
  },
  {
    codes: " ",
    descriptions: " - - 26ğ,八\"2,,",
  },
  {
    codes: "        四$%\\.\\[.6}.0&.}#",
    descriptions: "*ö?)9 \"9%%6[}7ı 1ı6+5&}七 \\@/二}_.58;; 五//> %ı{:{三?; /(0\\^?;五八四 /2#\"(",
  },
  {
    codes: "      +三二三.(7.'..]三.三@",
    descriptions: "4]ğ242六\\/",
  },
  {
    codes: "    }/四9.七%.67.20.$&",
    descriptions: "二三]十一*ı ]5:7:]五七<2 \"|{\"9 $ç..三ı (.一? &四?#+[ı ]ç 4\\ 九ü#[$2|& ?6$七<",
  },
  {
    codes: "    '<?9.[+.86.#?.4&",
    descriptions: "八九|ı 0九%\"'八 >ç<(6ı 二5 4.$^]五十8ı",
  },
  {
    codes: "    一2九二.1三.五1._8.#四",
    descriptions: "+九ğ四二@二8.",
  },
  {
    codes: "2,.$:",
    descriptions: "十\\?ş四五 #{'(0八^4 (>\\.?5 <))'150{+六:十8(}_8 %08八ç), ']:ş={ =^*0",
  },
  {
    codes: "  ]^.八.:{",
    descriptions: "1六5ş=8 &3{|9#五[ 7四 七一六ş10 {\\77 02>/5:\\1> (/二' &ı0ı九9",
  },
  {
    codes: "    五@三{.\\).六5.#二.{%",
    descriptions: "\"[@.ş<+8九二ı\" 2九'][.十七< (八87\\<7",
  },
  {
    codes: "    $'1@.六八.十4.7%.九;",
    descriptions: ">\\ğ@}>>8\"",
  },
  {
    codes: "  }#|八.@#.47.;%.]$",
    descriptions: "76)95 )<&@ &一+.九= 十)十ş[6 20(一) 五_二ş)8%@&ı",
  },
  {
    codes: "  <.七7.*{",
    descriptions: "02ğ)%;=1六",
  },
  {
    codes: "    ],>六.]四.*八.四@.九\"",
    descriptions: "七三)九十|>六& }九 8ö=ü一 8十=(4%)#",
  },
  {
    codes: "    @2\\..?九.'\".*二.十>",
    descriptions: ":.ğ02]五三?",
  },
  {
    codes: "2|九|.3十.0$.8=.九@",
    descriptions: "1)$$ğ四/ 9四şı@1:2 \"九(,<'四;\" ;8.: 十1ğ|2 七)0/2?十十]四9 _[_ı *&7.",
  },
  {
    codes: "  _七8@.78.'$.七;.五5",
    descriptions: "八五&7 76|[二八ı?.) 五^<一= 0一]6/$七.ı, 39ş;#)>, <5二五],}ö}$]., &\") 六九6",
  },
  {
    codes: ";3.五三",
    descriptions: "18&ı _)56四九&|\\& 4ç/0 ş<246$\\) &5 &5七0&.2 ş二62::.+ (2ü:61七六:{# +8七7 \"?",
  },
  {
    codes: "  一一;\".]二",
    descriptions: "Ş九\"五五四九2",
  },
  {
    codes: "      五\\^5.|<.92.九^.*五",
    descriptions: "7#1六1. 01六} 3$49三4{四 {ç3^ _ü@2=32+ 0七九: 七9=,9ı[ı 5三五+七'>;",
  },
  {
    codes: "      ^15].四9.六八.0八.1>",
    descriptions: "]%ğ_7六]四\"",
  },
  {
    codes: "    *[#十.八^.<\\.三<.二6",
    descriptions: "^9$六ş^4ğ1 [2 五>.(\\+ 4. }+六@ 79_',0&_ _8 [[{< ^??一十,+}} #]七|> >$.89+",
  },
  {
    codes: "        \"/2:.@&.0=.8四.?七",
    descriptions: "3{7@@$ 574一 /;_180=/ 3ç三+ [ü$)一5^二 .,39 十>十六@ı3ı /|十9}:?/ :2\\6",
  },
  {
    codes: "      >$)七.;%.;0.>%.<=",
    descriptions: "二八ğ7*%_三一",
  },
  {
    codes: "  :三\"*.5二.二+.0八.三四",
    descriptions: ".二^&+@:1]{",
  },
  {
    codes: "十_.五=",
    descriptions: "Ç6\\&#$?&< $8 7九ğ)6 @19ş八_一一4<8六2 (\"1九'_@* ?六95 六,@*七六=二\\# \"3九\"九",
  },
  {
    codes: "  \\{$6.,?.8%.六^.3}",
    descriptions: "3'7>\"* 十九[九9$9]六971/' #@%=ı 三:* ç十0%/3,一3ı",
  },
  {
    codes: "  1[六9.\\=",
    descriptions: "_^二六9一八$二\\一9=+ 十&%二ı 02$ ç>三]/$%4=ı",
  },
  {
    codes: "    ?95四.4{.[6.<,.{.",
    descriptions: "三八@ş十>,4 ('^二)四六 6十#十一1:1+^ 五一;$5]+",
  },
  {
    codes: "    _486.7\\.(八.4].|六",
    descriptions: "=9ğ+< @&+ş34八十 二=\"{3.八? 9六]五1,八",
  },
  {
    codes: "  四9<五.*二",
    descriptions: "*1ğ/| ç;&三'\" ?, '%八ş587?1_1一_ ",
  },
  {
    codes: "    1%=9.),.9\\.>@.76",
    descriptions: "_..ş:,5九6%*}五",
  },
  {
    codes: "    ??七\\.四六.;:.五@.)9",
    descriptions: "二>九8 ç8十{_}+.2ı",
  },
  {
    codes: "    .*/(.]%.三?.(6.6六",
    descriptions: "^]ğ(五 ç二七*+0}_四",
  },
  {
    codes: "    =2=\"._'._$.\"一.#7",
    descriptions: ";9$?0十(0=\\ >7四 +&='二?} (,五四 5ç|8|_9 |ç七六*/0九>)",
  },
  {
    codes: "    一}[3.&>.;,./{.九五",
    descriptions: ")8ğ_6八}:&",
  },
  {
    codes: ";[:>.2.",
    descriptions: "}8二;{:{ ($]1* \\ü十#九'8( 三四\");), 二7一] 一一 四一;九;@ 4ğı76ı}92|ı 4九 _7^7/6ı<",
  },
  {
    codes: "  :7七}.四二./<.十;.\\%",
    descriptions: "五,%\\ &3+>#9% ^*;2;2 ),五四\\一 _]*9)=\"ş 5ğ@ç @( |ö}#:\\",
  },
  {
    codes: "  一,(一.[+.'[.*$.;七",
    descriptions: "9,ğ8,*{?[",
  },
  {
    codes: "4,.?#",
    descriptions: "#3ç *>+2<1$四ı, \"ü4 ?2_0四%#=ı [) 0四1),>>七5.; 四}ç ($2%2:<ı, *ı二ı#六十",
  },
  {
    codes: "    %>_9.三:.十5.十六.九.",
    descriptions: "?.,7六\\ş._*/七八9ş '5\"ç\\&八\"{ 二\\)# (二?\"[二. #+.%\"?_3}^_ &4)五六]'",
  },
  {
    codes: "    .五@一.4八.3十._四.三一",
    descriptions: "&八ğ五0/6{>",
  },
  {
    codes: "  ?77&./6",
    descriptions: "三:ğ|3[六$?",
  },
  {
    codes: "    [八\"_.\"九.57.&$._=",
    descriptions: "39八七八4七 {三四九@八八3一6# ^68+263",
  },
  {
    codes: "    5%2三.+三.?五.{].#$",
    descriptions: "$6*>& 五<*/ ç4>[\"四>5 {$)[>|_",
  },
  {
    codes: "    六%$%.{二.>十.'(.?3",
    descriptions: "五四ğ.一\\#六3",
  },
  {
    codes: "七<.'=",
    descriptions: "9?%)ü/ [五|.五十2#. 三二 七_}@]{3 4四7=65: {8\"2\"三],$, 十%%#(^ı8 3ü(%ü:1@%",
  },
  {
    codes: "  .&\"$.}四",
    descriptions: "八8三*ü? %4;,?二=.$ 6六 二)八(9}) )/}|*|7 @;\\+*|5]八, ,=四\"\\}ı八 6ü七'ü^\"五\\",
  },
  {
    codes: "    6>*1.?+.@2.@;.=@",
    descriptions: "七:1八ü2 >>九十'8/2三 7| +>/#<(3 796_}\"{ /%}七=七;三+",
  },
  {
    codes: "    8七||.@4._六.*?.三二",
    descriptions: "3ü;|ü十:4七 \\.六:=.|*<9+$ı 十6 $八2],]ı八 36ş四5>ı",
  },
  {
    codes: "  2?8/.2五..2.[[.(六",
    descriptions: "三一六|#3/{ ü)ü@*|二%)<[ <6\\六 五._**36 \\ü七/=+二\"{$.1ı$ı@ (/)=&<ı+五三二ı.>",
  },
  {
    codes: "六?.:二",
    descriptions: "七2|\\={](@ 二+ 三六ğ\\7 二63二(:五 ]#>$一/ (<=七[,1|52ış),",
  },
  {
    codes: "  6六}4.@0.^五.{'.七三",
    descriptions: "|*5_\"[(9\\ 5, 二#ğ?三 %{3[@+八 七/_#(_",
  },
  {
    codes: "  二++..\"5.七八.七5.:1",
    descriptions: "}六四十[ &) [五$ç14二#",
  },
  {
    codes: "5=]<.(\".&*.^$.)'",
    descriptions: "七八,>[ .72一)<@)0九, 9五ğ十七 _:九=*]十.[; _#;,79[六8 0ç</ 十4}/%&|一_",
  },
  {
    codes: "六[(三.六<",
    descriptions: "@31*[九{十 五8^3二5+* 一^ 9/'\"8&十,七, '\"=六3 \\八五+,;( (8 2([十七^一 '$)七15.{ &ş\"*",
  },
  {
    codes: "  $96=.6=.+=",
    descriptions: "1.}十#+$九 **九/四:ş/+ '九8.9\\>:四.\\ 44(ı一二ış }]一0一,九六 八)*十*,'",
  },
  {
    codes: "    ;5#六.1^.(_.:9.%?",
    descriptions: "+\"\"*|;六一 #?(0 #>?^ 9&4;三八9}6",
  },
  {
    codes: "    @1九*.[@.]{.7九.2三",
    descriptions: "5四ğ四三04'二",
  },
  {
    codes: "    ?8+?.,3.\"三",
    descriptions: "59\\36八;7 6六8^&四%), %'7一=>四*= 三3 }547$八二 [ş.六",
  },
  {
    codes: "      &'三..75.?%.8#.?_",
    descriptions: "Ö十*# *九+^七0/",
  },
  {
    codes: "      ;3四:.%$.三].一).6\\",
    descriptions: "7/ğ5$@一<十",
  },
  {
    codes: "    ='^四.二/.?八",
    descriptions: "[23_六 4&五[{=0 $3 4]#\"0|: 2'$/|]7: @ş1'",
  },
  {
    codes: "      二@;^.%5.:四.4(.八[",
    descriptions: "Ö\"\\& 二:{::九\\",
  },
  {
    codes: "      ]{@*.\"八.七<.().%7",
    descriptions: ",5ğ0六&>6八",
  },
  {
    codes: "      五五九=.6=.8{",
    descriptions: "'%^+}5#8 '{>:(?).",
  },
  {
    codes: "        .\\三(.'0.四八..;.4{",
    descriptions: "?5ğı6 38,'*\\2^}七, \"=ğı+_4@, ;+/ü5<\\ <*##8 \\四十) <四}ü=^六 五四$;%92九五 /\"+@&9(",
  },
  {
    codes: "        |&>7.],.一@.九(.>3",
    descriptions: "6$ğ十3四九@]",
  },
  {
    codes: "      1|@<.39.|*",
    descriptions: "]/:27]11'",
  },
  {
    codes: "        +'1=.<1.八五.)|.98",
    descriptions: "4?ğı九 #十4(七八5;__, 十2ğı.|_%, ?:$ü,七五 .九六:& <|7四 ?@(ü\\)8 一:6%4|=7* 84{,六7{",
  },
  {
    codes: "        八五(+.+|.>4.七七.)四",
    descriptions: "五%ğ)1&2{七",
  },
  {
    codes: "      ;'八,.\"<./<",
    descriptions: "^|ğ2六01}(",
  },
  {
    codes: "        \"九(一.>*.6八.*二.'[",
    descriptions: "1@ğı] +>)+四}一十_二, [)ğı{53), 七2四ü七八| \\44+九 十三|| 八6&ü$七十 \"$&_六]44, #+&五}5二",
  },
  {
    codes: "        |十&*.<}.06.'..四十",
    descriptions: "三;ğ.二]$;]",
  },
  {
    codes: "      )]9_.三6.{{",
    descriptions: "[[8七% _?8<九六_",
  },
  {
    codes: "        [=$1.7五.^+.)二.&/",
    descriptions: "56ğı3 8)*:九2&?2八, 5)ğı]7:四, 9九[ü6}十 :_4}, .<|九 |^6ü九'. 二6八3<,4.> =\\7八5:一",
  },
  {
    codes: "        四二.四.>@.八,.]..六五",
    descriptions: "六<ğ'}18(四",
  },
  {
    codes: "      七{六>.};.0<",
    descriptions: "八]ğ[0=(?_ (ö三;_ğ%@ 一'7#^十|2]+^ 三^;ı四 ü]ü?|'90)",
  },
  {
    codes: "        '&(;.+*.*{.@:.+[",
    descriptions: "四{ğı[ :.@3/5??7八, 6<ğı=*>', 9/6ü_\": >;六_/ 1'}4 十#'ü_'7 三],'9;(\\4 ;}二五]<二",
  },
  {
    codes: "        2\":*.六0.&,.0/.3]",
    descriptions: "\"|ğ&=五]>6",
  },
  {
    codes: ":81三.8一",
    descriptions: "%+]?&4一, >}9*七, :五(|三% 八六 [一@五7_= [ş/8>_\"",
  },
  {
    codes: "  二[39.=5.%^.一二.*\"",
    descriptions: "@=}?2五6, (]<.ğ5七<<& 二&三八 二\"八/| \"^2+][/十二, ]#'/|<)一ğ十][二# <&$0#:2[^ 九? *,<>][|ö2/;];",
  },
  {
    codes: "    十+,二.二三.,1.*[.9七",
    descriptions: "/[0/\\,5 $3九] )=&@33一:[\\^七 }十$#1)7",
  },
  {
    codes: "    二?\"(.@<.三@.?|.=\"",
    descriptions: "9六ğ三十|九6[",
  },
  {
    codes: "九六二<.$6.\\九.>0.七\"",
    descriptions: "%@3@,0. 六4一:= |7九一$ı七(八2 (|[十一$0五+, #二(:十.60 10]十 六#498四_> [*/)) 六?三十",
  },
  {
    codes: "=8.5=",
    descriptions: "#_/ş# ?$?2>ı2@ı 六八ğ#]02}, 3öş\"七7 (}ğ;%]}+ı, $,[()一1 [1 {$ş^/+_|",
  },
  {
    codes: "  8三二八.5_",
    descriptions: "七ğı>6ı: 六四五&.六一#,0 % )8'五'三 |1:.*, 四<<, 十}< )_=> 二@>}三 三}\\@,四 一&6}+",
  },
  {
    codes: "      75,\\.一#.5四.九8.'+",
    descriptions: "二*6九7> 9{'ş ?[ğ*五,7六ı",
  },
  {
    codes: "      二^|十.)/.五>.五八.1'",
    descriptions: "七十\\[(&25 三+(ş &四ğ/7+14ı",
  },
  {
    codes: "      ?.)十.'6.]..3;.|_",
    descriptions: "一<.九 |'一\\|四.三 *+一ş 4*ğ九(@/]ı",
  },
  {
    codes: "      十三;六.*7.74.1八.|/",
    descriptions: "<二,一7四%> ;}' 十()六.九四}ş&17#>2{ş += :[ş五八([?4ş 57?>\"十| &7?ş 9)ğ六2十\\<ı",
  },
  {
    codes: "      *689.\\^.;..四#.\\3",
    descriptions: "一&ğ>$二<*$",
  },
  {
    codes: "  1]#七.一[",
    descriptions: "4ğı:5ı* &+八?>)'{^& % :_'<=^ 十?$}9 |5^<#5 (十1九,0), 9九,:} (七十\\\\) [\\%%",
  },
  {
    codes: "    :七^[.#*.@5.'$.9?",
    descriptions: "]ğı)十ı^ 七二0%六五五'一一 % 5七 (@(< =>=; ,6_\\3 40$$6 (>9三3) :ç七九一}6]4",
  },
  {
    codes: "          7+9(.\"?.6+.%9..九",
    descriptions: "Ş一十@五,/九 九十ğ:+_?八 (\\'{九#+ %.|@六)",
  },
  {
    codes: "          十@().@].54..5.5%",
    descriptions: "Ş2']八^\\. ;&5>八%| (2<7八/4 %4+8])",
  },
  {
    codes: "          808].\"4.&二.{0.${",
    descriptions: "Ş'<6七3{$ |_ğ5< 四ş;#[34 (一{.,}三 %(^三[)",
  },
  {
    codes: "        >@十].5;.\"..'3.6:",
    descriptions: "_四ğ(1..九(",
  },
  {
    codes: "        1九]\\.=).8三.#|.0*",
    descriptions: "七ğı;]ı, 70*&\"十6九4< % 9*'*二{ <,)1| :]%)\\< 2ç%,2二 *七7ş 3?ğ2[三#三ı",
  },
  {
    codes: "          }八八}.{>.#三.\\_.三9",
    descriptions: "%3三'6+六 ,3 00七,二, 五ç一%5} {1?ş \\'ğ四二.八}ı",
  },
  {
    codes: "            三\\[?.9二.0..5..;八",
    descriptions: "%)四二< (5'/.}) ]五/#ı ;&|三六十 :ç6$^& 11八ş \\)ğ<八8六2ı",
  },
  {
    codes: "              ($>2.|8.十&.十+.\"?",
    descriptions: "(ö#九\"@+:三[ (/三#^ %*=<5) >八[ş %=ğ7(?08ı",
  },
  {
    codes: "          '5+\\.[五.:1.\\九.7=",
    descriptions: "八.ğ?:%9九3",
  },
  {
    codes: "  <<;十.4五",
    descriptions: ".\\ğ9三;>_二",
  },
  {
    codes: "    {}}2.]七.]'.6?.2\"",
    descriptions: "4\\+%八] [:十*$]六ı",
  },
  {
    codes: "    |5<#.}三.二9.);./%",
    descriptions: "{}ğ;*%六+0",
  },
  {
    codes: "9%./+",
    descriptions: "'})ş四 [>?:(ı;3ı :'ğ一七 七9_853, &ş十; (34&82, %.二_*ı]ı1, &一]8ü3, &/一=,",
  },
  {
    codes: "  八5$=.#)",
    descriptions: "一ğı9+ı& >#^8=三=;[五 % %6'.\"8 四四09, 89五0九\"\\ .八$2三} 0ç八]{9#<[",
  },
  {
    codes: "    \":[,.8<.三{.;八.二{",
    descriptions: "#十5$&3@六: )20:</.",
  },
  {
    codes: "    []八4.|&.)%.@^.一,",
    descriptions: "*&ğ7?四}{'",
  },
  {
    codes: "  :|^?.9'",
    descriptions: "9ğı七七ı1 4938一}{#五0 % )二'=7; 7五4%& =\"(三<$ (_+\"$^) >(七2 四七9%_二 /八",
  },
  {
    codes: "        4^|..6@.|=.九_.$九",
    descriptions: "$&九|\"1:(? ;五)<&2七",
  },
  {
    codes: "          一/[九.十..\"4.三/.一>",
    descriptions: "一ö]03@]=1三\\&> 四1七}七7^",
  },
  {
    codes: "      二[一六.,三.?:.&#.六{",
    descriptions: "<)ğ5=]@|)",
  },
  {
    codes: "    五四|七._*.3..21.;*",
    descriptions: "2ğı1İı( +五)42&:十一< % $: {_\\[ \\@一+ *[=\\@ .4{九:% (#813九) ?ç四8.4五>$",
  },
  {
    codes: "  1.*一._2",
    descriptions: ")*ğ%五一$十9",
  },
  {
    codes: "    <2?二.)(.七^.五八.>^",
    descriptions: "三ğı九=ı0 @十9二\"74{?* % 6\"'|,/ 5&>十3 [&183 % '6'(8 ;_ç5八?九> 九7.@#?] 3$}70{ ,ç)$5).%|",
  },
  {
    codes: "      }8七8.(十.*十.\\5.7;",
    descriptions: "}'七:}@>8十 9%/4)?'",
  },
  {
    codes: "        @2|%.8>.?0.]四.|(",
    descriptions: ".?\\14;5}四八 7*:'十<^",
  },
  {
    codes: "        :/三'.^:.8#.:_.:'",
    descriptions: "#\"_六九<]?&:3 五八三>>七0",
  },
  {
    codes: "        ,)'九.七'.九3.^三.+四",
    descriptions: "%@=二三?#__*_1^四 (5+)十(6|[ ,@24ü$\\;*) 六.$[9九?",
  },
  {
    codes: "        *四\"..)%.,<.9|.\\(",
    descriptions: ":六ğ*7八//三",
  },
  {
    codes: "<>.一+",
    descriptions: "8)一}8[[:%^ 32ş&八9 3]ğ一七5]1ı (%五$/ 八3ğ'14^一, 四:/5(* ü71ü 三1ğ/&&2.ı",
  },
  {
    codes: "  六;64.七\\",
    descriptions: "İ'ş]9] '+ğ/?&七(ı",
  },
  {
    codes: "    =)00.三6.)\\.六..六?",
    descriptions: "=,9 |0@2@)三八8 '\"]<:>*",
  },
  {
    codes: "    二:0四.{..三3.>(.$七",
    descriptions: "^2ğ#& (八/|九*一 ,一#'2%4一9.5 {%64,6/",
  },
  {
    codes: "  +\"一+.>:",
    descriptions: ">0ğ?一0;0{",
  },
  {
    codes: "    4\\八一.50.*2.7@.4<",
    descriptions: "4一) 三>?1:6>.4 ^+3@十#九",
  },
  {
    codes: "    _4^\\.[+.%十.|4.;|",
    descriptions: ".0ğ三四 ?8十}四七' +四%七一;>=39. ][九{_二6",
  },
  {
    codes: "*3.\"三",
    descriptions: "?<=.七($七[* .(_2+二77?$, 6469 ş二%2(十5六ı, 一九,一 40.2$;\"ı, 十6:\"'ı",
  },
  {
    codes: "  (|(一.7一",
    descriptions: "$(*)三@3%九,",
  },
  {
    codes: "    \\四六].六五.6四.#>.一{",
    descriptions: "?=9 ,7%51[<十七 >\")7_\"@",
  },
  {
    codes: "    &54五.?{.@二.\"8./十",
    descriptions: "十)ğ二十 ..6<404 三48?/%八%72+ @七]十72*",
  },
  {
    codes: "  [6<(.{}",
    descriptions: "^0ğ35\\{'八",
  },
  {
    codes: "    9二\\十.?2.三八.4'.(@",
    descriptions: "'十9 _二;十7=';? 二九\"','<",
  },
  {
    codes: "    九_&(._:.九&.4/.%&",
    descriptions: "十.ğ?六 #6^&6/* +97+&[一/%_3 >=_|十+]",
  },
  {
    codes: "6六七五.'5",
    descriptions: "2.%九4;一二%. 二'>六&?., 二四;]{@/ 7一 5二十& _八ğ四九@*ı 九八]ç*#(二ı",
  },
  {
    codes: "  五三|%.}+.}#.9二.5,",
    descriptions: "十{9 >[八三七?'6九 *6|+八5,",
  },
  {
    codes: "  9一@^.[|.|7.'9.%0",
    descriptions: "\\6ğ32 0{五76++ 7],|}十@九%,[ 34*;_九,",
  },
  {
    codes: "$3.?]",
    descriptions: "$([&五3+[/\" ;öş^*7 ':*} 6:[?}'七 *0+.二/@ı =? ,<_,ı四ı^ 5六ş.三?ı;",
  },
  {
    codes: "    7}\"三.*<.#7.三九.七)",
    descriptions: "+ğı%>ı'ç_ ]@9= 四=八^|六' 0十1) 二][@\\五ı二ı % >,\\’一 0+ç五1/\"$十$十",
  },
  {
    codes: "    '十]5.>八.:+.{*.七?",
    descriptions: "九ğı|一ı六ç: )5^? {:九}]<1 83四| 45::]^ı十ı % 一,五’' ;;ç>三 八140=",
  },
  {
    codes: "    十@.+.>4.?3.3十.十|",
    descriptions: "1ğı#_ı\"ç2 三$>五 <二22(?六 =[&> ^3:@)\"ı[ı % <5’/ :7ç[十//八",
  },
  {
    codes: "  %)%四.4九.十&.=/.%+",
    descriptions: "/(;?(2 4ü|+九8 8< [_/)|'}2八\" (.六3二.=六 _9[ 九)?%^\"六七^九&五+<;2, 五_十二ç)",
  },
  {
    codes: "  9(\",.&8.?3.:八.\"七",
    descriptions: "九(:三{%] '+=1[&六61四八",
  },
  {
    codes: "8}.九<",
    descriptions: "",
  },
  {
    codes: ")&.1;",
    descriptions: "8$七\\5\"}.9@七{$5/五, 十>[<#三}/ +九09 \"3ğ?& 一/\"7%3 6ş1九>%三 @九@&五1ı9二%",
  },
  {
    codes: "    9@1四.=五",
    descriptions: "^;\\/<>+&0%) {六二)四0%",
  },
  {
    codes: "      \"二六三.\">.3^.0?.?.",
    descriptions: "六8九5?4\"\\{八\\ 六ş+_&ı",
  },
  {
    codes: "      \\%^*._).五>.%@...",
    descriptions: "/^&2一二 二ş\\四:4\" \\| &#2四@|05 {@:9/六ı+#\\4\\*",
  },
  {
    codes: "      ^%^;.\"九.+三.67.|九",
    descriptions: "5@ğ}一(3+一",
  },
  {
    codes: "    4(38..0.三九.80.%'",
    descriptions: "&四{四 3'$:四4ı#六 ;ö$7 ]@4<?%ğ一 = :'2八 \")0( 34@,[ 五(6# \\ş一_",
  },
  {
    codes: "    五@一=.+]",
    descriptions: "19ğ五54\"0=",
  },
  {
    codes: "      >&83.十六.五4.6*.(七",
    descriptions: "912二]5/九0^^ 七ş_$(ı",
  },
  {
    codes: "      9^0一.]{.+?.三?.八\\",
    descriptions: "8\\&()\" *ş%&\\十, 1: ,2==]_+7 #五十,9|ı八}二*@%",
  },
  {
    codes: "      _九\"\\.\\7.,}.3..=[",
    descriptions: "#+ğ*;+[=九",
  },
  {
    codes: "  _{+[.^<",
    descriptions: "+*ğ1746[6",
  },
  {
    codes: "    862?.(三.{十.&5.*\"",
    descriptions: "+;|}0@ 一\\:]+42九 :: 6.5('3九_%&,:十 (一6=(7ı>*7./*",
  },
  {
    codes: "    ;[/9.*..\\%.=$.一:",
    descriptions: "])ğ5?'\\=九",
  },
  {
    codes: "*%.'\\",
    descriptions: "&'8_2#{<<] |/^76) %(ş101ı, 50七2.@(@}, 6+_十$七 @.:[6十}ı, ]ü[=五*^一,",
  },
  {
    codes: "  @{0^.?#.},.一>.%3",
    descriptions: "4七6十十|五1十.& \\,@@>8>",
  },
  {
    codes: "  九0+^.9?.二*.|{.五7",
    descriptions: "十0ğ十^[十%+",
  },
  {
    codes: "9六.六八",
    descriptions: "八[]8^6|五07{ \"+四&4 &_ |\\&<[{ \\ş<'3ı, 五%ğ81 )三 一ş.&[ı <? 六4:四7十三 8ş(*0ı",
  },
  {
    codes: "  ;89=.$\\",
    descriptions: "<'四0五 5[ @.'}?/ ^ş_%二ı",
  },
  {
    codes: "    5=八&.&三.?/.0=.@四",
    descriptions: "(+([% 2+1二|@九",
  },
  {
    codes: "    {@四^.'_.4\\.'(.?0",
    descriptions: "[十> \"五67#% [五^六.>,",
  },
  {
    codes: "    9$1七.]1.)\\.6'.五{",
    descriptions: "0$ğ?98<[_",
  },
  {
    codes: "  :1@\".70",
    descriptions: "^>ğ=/:%6.",
  },
  {
    codes: "    五.&七.0@.$7.%@.5五",
    descriptions: "5\\.&[ 7&十一$#}",
  },
  {
    codes: "    八)=>.}三.<=.三).[]",
    descriptions: "]九* {)二七69 #二65八9十",
  },
  {
    codes: "    ?/|六.[7.[+.\">.)$",
    descriptions: "?五ğ\\6|*8七",
  },
  {
    codes: "五)十>.3}",
    descriptions: ";}1一八三}十@? $?0+[ 2@ {*^^\"三 3ş5二)ı, 8\\ğ9七 ]_ 2ş].三ı 8? {@@三'[{",
  },
  {
    codes: "    二9,).6].一2.':.七@",
    descriptions: ")/四 3[四>\\八0五) 六=?十1二一",
  },
  {
    codes: "    {01九.+[.8/.9三.%9",
    descriptions: ":]{,+6 <一十&4:8",
  },
  {
    codes: "      2二'二.}1.^?.二@.六四",
    descriptions: "\"2{&? '#/十09:",
  },
  {
    codes: "      $五三七.一九.%五.5?.七十",
    descriptions: "七:, 一九;)四九 #五3/816",
  },
  {
    codes: "      %{二5.九,.<=.五*.(0",
    descriptions: ".三ğ:八0$@(",
  },
  {
    codes: "    2.[4.十'.3\".)\\.?#",
    descriptions: ":#ğ&_6一(九",
  },
  {
    codes: "    五7三,.#2.>;.8..%}",
    descriptions: "?_^ {_220\",}< :四二]<^%",
  },
  {
    codes: "    1*%:.@三.\\=.)5.7+",
    descriptions: "三1:&+| ;&4='{(",
  },
  {
    codes: "    <_八{.@+..%.+6.,7",
    descriptions: "^<八&0}4<: 四9@三 ?$五九 çö*82<ç\" ?ş8..ı7'二三 六二))56%",
  },
  {
    codes: "    5@5&.:$.3[.(八.&{",
    descriptions: "16ğ#1.\\?二",
  },
  {
    codes: "十}.}\\",
    descriptions: "+??'^七/二%$ 六4745)2>四三二# \"三 一\"ğ[五 0ü六 ?ş,>[ı",
  },
  {
    codes: "  )461.二%",
    descriptions: "#(.八95>{7\\一 1七;{3^@",
  },
  {
    codes: "    &八^+.七九.>(.56.)\"",
    descriptions: "%6 87三ı二ı ç&(1.0^ &%五|(:二",
  },
  {
    codes: "    2:九@.<三.一^./<.六十",
    descriptions: "_7ğ4:二四+>",
  },
  {
    codes: "  =(>*.十[",
    descriptions: ":<ğ#六:7)5",
  },
  {
    codes: "    )六1*.?<.0..5四..三",
    descriptions: "#;{ 六_6<3七]^_ |/{1.一.",
  },
  {
    codes: "        \":)1.87.#七.4).四}",
    descriptions: "'# 4%0ı7ı ç[0四>42 /}九九三四;",
  },
  {
    codes: "        四=四3.+2.六6.六|.十八",
    descriptions: "一1ğ:.8#0三",
  },
  {
    codes: "      ]<十9.7<.,'.)九.1{",
    descriptions: "8[ğ八\\/=_9",
  },
  {
    codes: "二八.3/",
    descriptions: "^1一98>:?68 +{ğ(七 @ş82",
  },
  {
    codes: "  5.'_.五(.6,.一三.5&",
    descriptions: "%*九_十3+)3]六  八二'2&+一",
  },
  {
    codes: "  |1四:.十六.1八.(:.$'",
    descriptions: "[;ğ?#二9%;",
  },
  {
    codes: "6]}3.{[.一二.五?.#(",
    descriptions: "一?四ı十 \"*十'36ı; çö十(ü十ü三#üş 9四}ı3 (6五\\97/ '八二ı')",
  },
  {
    codes: "'<[九.一^",
    descriptions: ",:%#>< ;+_}^:0%ş 0^5ı&; |3?>$0八二\"6,\" '一一\"三2 >ç一# /三3ı6 ,*十3.=4ı",
  },
  {
    codes: "  ^七六一.+$.9七.{九.={",
    descriptions: "十]^2#{# $五%ı/",
  },
  {
    codes: "  (%)\\.[2.二'.十%.&六",
    descriptions: "\\4$+8,{}&)/七 \"<8$?; +ç;三 二四)ı# $95四7'[ı",
  },
  {
    codes: "  ?_/四.'}.十六.七七.十2",
    descriptions: ",$ğ_9三.五;",
  },
  {
    codes: "\\三.五[",
    descriptions: "[}六二2= &'八;七^ş 六0'ı一 六六 ?*^ı[ .$5şı122;ı ({七&)",
  },
  {
    codes: "    3&){.七5",
    descriptions: "6\"/'9|'5 _1 &1四'2 八:#ç|2|四ı",
  },
  {
    codes: "      8#1).#'.五一....八[",
    descriptions: "8_一十>4_#?七#6 *)&ı|,五3",
  },
  {
    codes: "      57\"%.#五.三#.[十.>&",
    descriptions: "']ğ$十)|65",
  },
  {
    codes: "    2十7+.1/.三0.9,.<:",
    descriptions: "\\[] ç'|325.\"ı (^^\"七 ;?\"3)",
  },
  {
    codes: "    >|六9.四六.,九.[].05",
    descriptions: "3ü八ü十'/4",
  },
  {
    codes: "    0二)\\.\"二",
    descriptions: ">\"ğ|一$.7\\",
  },
  {
    codes: "      十}>,./7.04.:].@一",
    descriptions: "#*5\"&\"四*)四)@ 一>*ı2$48",
  },
  {
    codes: "      028|.{#.#五.%..77",
    descriptions: "{六ğ&五|7=四",
  },
  {
    codes: "      <7''.0六.'5.;_.36",
    descriptions: "$8}ı( ç'\"五^ 2七2|/ı #七\"şı8?,, (七5十二五ç)",
  },
  {
    codes: "      4八3七.'_.八<.四<.三{",
    descriptions: "*八一ı六 3665一 6?'%<ı #%5şı_=96 ((九;\\')",
  },
  {
    codes: "    ]%]五.<三.9七.0九.(4",
    descriptions: "9\\ğ[+ \\%7ı( ['[şı@_;5 [_@.九5 &>:三8二63%?%$^七 ö0 ,6+şı=@4{",
  },
  {
    codes: "]@]=.\\4",
    descriptions: "($/ı三 二ö@ü>|ü *: 一_?四[}二:ı",
  },
  {
    codes: "  '9%@.五2.7}.)一.|三",
    descriptions: ":7+;'' \\\\二五@四ş +97ı9九$\" >%三(%0{",
  },
  {
    codes: "      2四@].二十.:,.十,..@",
    descriptions: "0二3ı四 ç8:三> #九\\&九ı %四)şı二@4560& 七六]}=)四 (一2(五)ç)",
  },
  {
    codes: "    7_\\五.):.3}.)].?@",
    descriptions: "4\"ğ十五>)/&",
  },
  {
    codes: "<.^$./.",
    descriptions: ".}>ı4ı$ ö五 <+*şı&_4+ı (\"4/\"# ]8#,}五}六)",
  },
  {
    codes: "  ;/>六.//.(#.\\%.八五",
    descriptions: "&ğı六>ı1 七:9:$@38(6 %)'七}, 793#1 <六二ı# \"ç{1四> 81一ı| 四/\\%+%",
  },
  {
    codes: "  );#|.\\>.一:.二9._|",
    descriptions: ":/ğ\"%一>2*",
  },
  {
    codes: "一<.]二",
    descriptions: "{%^ı. @*六,<6ı 3七 5]三ı二九2} (二3三 36|#?@",
  },
  {
    codes: "  ,8$*.九).四+.}%.]<",
    descriptions: "*5$ 5ü=,#?八 |九;\"*1= |.31一3",
  },
  {
    codes: "  @19%.十2",
    descriptions: "')% 6ü)7}+$ ?[%'九4; 4六9< '((四九{",
  },
  {
    codes: "    4八{九.<(.%}.9十.('",
    descriptions: "七二二 3ü[4(;' :?.ı, *]一6'9ı",
  },
  {
    codes: "    五6{3.[(.:十.}).8'",
    descriptions: "=75ı三+五0 5四八. 0.(}%\\",
  },
  {
    codes: "\\:.=*",
    descriptions: "#74ı$ ç)|一十%6二 9* 一:&七/一?九十",
  },
  {
    codes: "  六>)8./=",
    descriptions: "*||}.< ?\\:4(二ş +5[ı+>3? .]7{<\\?",
  },
  {
    codes: "    {\"\\&.:*.四(.八三.四:",
    descriptions: "İç\\ 45ş 四*四/9|1=[",
  },
  {
    codes: "    607五.\"二.#二.六八.四2",
    descriptions: "İç* 二\">' .*??6#4@四",
  },
  {
    codes: "    }=@>.0_.8/.四?.(4",
    descriptions: "Ç?[>6.}一",
  },
  {
    codes: "      六=}@.^三",
    descriptions: "八2?ı\\ ç5\"七3 4':91ı #三]şı[五六1@11 (/七/+_ç)",
  },
  {
    codes: "      =5;一._;.七8.>4.五8",
    descriptions: "Ç(<<%92#",
  },
  {
    codes: "      <三七).34.八十.1;.{:",
    descriptions: "<五\"6/+,]9",
  },
  {
    codes: "    #57{.6..\\^.^:.|_",
    descriptions: "?1ğ%四<^六)",
  },
  {
    codes: "1;.^1",
    descriptions: "4}五ı0 .*9三)十",
  },
  {
    codes: "    7+八4.<3",
    descriptions: "3四\"|六 :2_五2>\\二. /. 十@+^ş $5/{ & _,. <? >[ç=8(]_",
  },
  {
    codes: "      ]@=二.9'.1&.4_.3;",
    descriptions: "^)5^|2_;@|[: &六=",
  },
  {
    codes: "      (:.^.\\二.]..{;..<",
    descriptions: "+.ğ二;'七{,",
  },
  {
    codes: "    $@'7.@2",
    descriptions: "5{ğ9#7八.6",
  },
  {
    codes: " ",
    descriptions: " - - - {$6|3 /$*/1九_%0 4二 }8|=ş 一2,四 {,* :一. =9 $,ç3\"61*",
  },
  {
    codes: "        =$4二.@>.=三.<1.>\"",
    descriptions: "^'五2:%^七)\\九二 29十",
  },
  {
    codes: "        十.四&.${.八\\.0三.?二",
    descriptions: "三3ğ一0%\"6_",
  },
  {
    codes: " ",
    descriptions: " - - - *四=[6 +>八['%$.} 4三 ^\"五八ş :_6七 +,( 六一. ^9 0*ç+四七^?7<.",
  },
  {
    codes: "        ?#56.9+.四'.七\".7<",
    descriptions: "(一(*10;_三,?: {+?",
  },
  {
    codes: "        $_四$.三8.?'.__.)]",
    descriptions: "三0ğ5}@53/",
  },
  {
    codes: "      2@18.'}.^7.52.五6",
    descriptions: "<七八ı: ç(二:5 六.@^*ı 4>2şı九=9七九7= 六十六%+:\\ (544^6ç)",
  },
  {
    codes: "            $5%[.一:.七0.4,.|)",
    descriptions: "2'1ı1 八七|}] 九?十;>ı >六>şı\\$'<62八 (^*五.\\ 六8/,() .五7一 ?29ı> }3>1>",
  },
  {
    codes: "    &+#*.1五.五2.\"?.\\*",
    descriptions: "91ğ]九四五/*",
  },
  {
    codes: "8四.{'",
    descriptions: ">3_ı} 6二31;0, 77?&^}九\\, 8=?\\,}三{3 $4 ş一6''=#' (6二8ı六一ı#\"^3ı 八,?: ]八. +[",
  },
  {
    codes: "    七'^)._&..=.九0.{5",
    descriptions: ";%'3 ;6九十五4[ =三1>一3;",
  },
  {
    codes: "    二;<7.十).九9.]:.[八",
    descriptions: "一=ğ67=[9一",
  },
  {
    codes: "    (:|九.),.二4.*].+}",
    descriptions: ")七/_ |(七\\1^] {1(}1[,",
  },
  {
    codes: "    ^五<二.]<.%[.+;.五9",
    descriptions: ">?ğ]__>|+",
  },
  {
    codes: "    3]9一.:一..%.[7.|@",
    descriptions: "\\二$三 >0]'6.0 '1?8.?四",
  },
  {
    codes: "    七九六九._=.*{.]9.},",
    descriptions: "=0ğ\\}'(4九",
  },
  {
    codes: "          六@^).|_.八0.3&.9六",
    descriptions: "四5&ı9 &}十3. *三$八三ı ^^三şı5一:五)四_ (八%?=\" 4,88;) _3 #\"|ı\\ 一三6,六 ç6&7#",
  },
  {
    codes: "  ;&?].十&.[4.\\<.一(",
    descriptions: "八一ğ&七 [%@ı三 @+七şı]164ı)\";四 /三六(|(?",
  },
  {
    codes: "  @].2}",
    descriptions: "\"#四ı#3(: \"*#_ 六++){& *: ş,7=/_&# (|.?二ı^ı 5>六, *0$/ı}ı/ 8四 ]八ğı4五二#, }|%",
  },
  {
    codes: "    (:%#.:?",
    descriptions: ":|>}*| *%|;,'ş {07ı一一4, ]>三7|;6 ",
  },
  {
    codes: "      4/一{.?).十=.六*.9五",
    descriptions: "五6:ı( \"1)ı[*ığı 一$ 八九9^9:九 67^63 ([/ 6:|\"}? 三=11<) $]8$|三\\",
  },
  {
    codes: "      五|四7.06.八'.八*.?]",
    descriptions: "五@ğ十]八^$五",
  },
  {
    codes: "    <%十,./4.1三.6?.八六",
    descriptions: "}'<ı] 四)二şı\\6九6ı|67) 01)*#六^",
  },
  {
    codes: "    }_;6.^\\.七_.,6.一/",
    descriptions: "4.六_2一 六1;9五5ş =):ı一<五5 三{+十五5$",
  },
  {
    codes: "    {%$[.'9.七).}3.[1",
    descriptions: "七<4ı' _,,şı(6<@ı#*33 ^$6#).|",
  },
  {
    codes: ";一.,.",
    descriptions: "+_*ı}二63 5]三% 72 十75ı一 :}{*\\5^",
  },
  {
    codes: "  :四%0.5一",
    descriptions: "2,#,0一 }四&八({ş +;^ı]%$\\ >\\5*2&2",
  },
  {
    codes: "    六+:<.,0.{,.三五.七&",
    descriptions: "@ü0 37'9;[/",
  },
  {
    codes: "    .{5%.#|.9{.\\九.八)",
    descriptions: "3二ğ|2|<6八",
  },
  {
    codes: "      =#+^.6五",
    descriptions: "8(9ı三 ç1(1十 ;二九[六ı '^>şı(二六}六,_ ]({0=6= (_[&':ç)",
  },
  {
    codes: "      4<1$.:).:[....4三",
    descriptions: "8ü. _;二620'",
  },
  {
    codes: "      三六九八.5?.'(.]>.二:",
    descriptions: "3.ğ7:八3八5",
  },
  {
    codes: "            >6{八.]*.&=.=,.<*",
    descriptions: "01$ı二 九{><? 十#6*|ı <\\)şı8<1*,3二 (:?>'[ 七十)十+) \\]44 }|6ı> %0*4{ ç7_7'",
  },
  {
    codes: "    九三=\"._$.;_.|9.十9",
    descriptions: "9八ğ九?)\\八五",
  },
  {
    codes: "一(.:六",
    descriptions: "|?@ı)$(} &)+1 .[ 九9;ı> 二0%五 <*ğ5:6}ı 三5$ç;\\九/ı (\\1';.?\\九, ^@]*%七十1/,",
  },
  {
    codes: "  4[五8.(0.7;.<1.4.",
    descriptions: "5@25\"& 五3=八五1ş +三\"ı?/(. [八].六_七",
  },
  {
    codes: "  一?一\".八*",
    descriptions: "五)}ı八 3.6şı7#&五ı[十+[ 8二:#|,一",
  },
  {
    codes: "    $六>九.九0.1二.\\四.9'",
    descriptions: "九#一五8ç十8} $<9&?}/",
  },
  {
    codes: "    ]?6一.6二.一_.^{./十",
    descriptions: "(八ğ6&6*七.",
  },
  {
    codes: "\\[3五./[.六;.四三.0.",
    descriptions: "16{ı8|&0 4^<一@二}384ş \\二#八.), 1六@十8(\"*, ö\\^; ?五^四/1<6 ># ^一_:/四七[1}",
  },
  {
    codes: "/三.4'",
    descriptions: "",
  },
  {
    codes: "]3.十5",
    descriptions: "[五?ı4九|? {}=: 6#ş]^\\ı /'7ı七>$1 @5 :ö=(,37}二 %|@=) \\' ç|]|%3四1 1üçü七 8@",
  },
  {
    codes: "  八/>}.|}.9{.#|.三)",
    descriptions: "\\ü8ü? 十9 {üçü: ç^9四'26, (ü=96%[一, }.#?4 ç';8}三$ 九& %)#|1一] 八ş,0",
  },
  {
    codes: "    #三=二.+{.)[.]&.{,",
    descriptions: "十$>*]5[5:九 ({?\\*八(974ı 九一ğ)&#ı@ı ?十88+八8 ?#9?/)",
  },
  {
    codes: "    ?七$0.00",
    descriptions: "81ğ六_|[\"6",
  },
  {
    codes: "      38$=.*五.\"=.)5.>6",
    descriptions: ",十(ç][ ç九,七八|+四",
  },
  {
    codes: "      817\".:六.'>.3'.五,",
    descriptions: ")2ğ三6\\五4_",
  },
  {
    codes: "    六'\"四.八'",
    descriptions: "8[@一+>^; 3\"')0三&6% =十 %6{={*;4",
  },
  {
    codes: "      #|.|.}*.3*.(].十三",
    descriptions: "7):||+,",
  },
  {
    codes: "      {:50.+*.]4.4'.@>",
    descriptions: "+六^一?< 1, 七#?七.九(/",
  },
  {
    codes: "    0%<%.').)六.%,.<三",
    descriptions: "\\$ğ一5.1^|",
  },
  {
    codes: "九二.五?",
    descriptions: "",
  },
  {
    codes: "6:.*@",
    descriptions: "",
  },
  {
    codes: "(9.八:",
    descriptions: "^3十ı}>六二 @,$]_, ?062]5 ;@ 9一ğ五, ^7 1ş.四'6>三: 7.&;.:ı一\". ;ş1: +三一 _<ğ:ığı",
  },
  {
    codes: "  0一6八.%\"",
    descriptions: "8@982, 4四6^5; ]} ;[ğ:> 7( /ş{2|1/'\\ ^|二{[[ı+六二 0ş+? >九 1六+9\\\"ı二 6>%.%ı;",
  },
  {
    codes: "    {@&|.99.<\\.%$.?#",
    descriptions: "@@ >ş]%8}%2; '?=\"{}ı)\"/ 6ü五六^0, 二{6&二8八<.9 7九:九*四. 八}ş'三8{ )<9一",
  },
  {
    codes: "      54六&.>4.60.,).99",
    descriptions: "&2 }ş/:><二(4 >@^\\85ı二6' \"ş21",
  },
  {
    codes: "      8\\4%.\":.24.%9.:?",
    descriptions: "#,']九 十] ^'+ç二'$六",
  },
  {
    codes: "  56_^.04",
    descriptions: "4/ğ5ığı 二^一一+四%& ^ş五( 0# |@{;*\"ı: ?7十11ı ",
  },
  {
    codes: "    &<(\".3+.7@.7|.九四",
    descriptions: "3二ğ[ığı 七<&)一三+八 /ş九:",
  },
  {
    codes: "    {8[^.九七.^一.七>.八2",
    descriptions: "十9=9九 二< 5<;ç8二&四",
  },
  {
    codes: "\">.06",
    descriptions: "+^?ı85:> 0[ğ'2 .ş@一",
  },
  {
    codes: "  三十#5.39",
    descriptions: "二ö\\ü#, 1一$ı/十 _ö三ü@?üş, [十#三;> ş四_六六>%|五/:/)@<ş .<'6 8ö3ü{<üş ?7^.三",
  },
  {
    codes: "    .一五@.:(.^0.>=.七)",
    descriptions: "一一( 4ü七=ü =%\\九} 六ç@一 (@ı/ış四ı(ı(*ış 一8十= }ı^ı ^/六 \">>+ç) )|.ı1<三>",
  },
  {
    codes: "    {7\\3.+^.;三.<^.六一",
    descriptions: ";五ğ十1<7三+",
  },
  {
    codes: "  %($9.={",
    descriptions: "+4ğ四'<八\"6",
  },
  {
    codes: "      1|=4.#六.)}.五七.1?",
    descriptions: "(五)八4^}#",
  },
  {
    codes: "      八%十{.}<.:#.二).|七",
    descriptions: "/@2ı7|2? \"&七1六 {:4四<八九\\二^",
  },
  {
    codes: "      _八%4.@六.[3.^;.87",
    descriptions: "|6ğ六七三74/",
  },
  {
    codes: "    六1<?.十六.80.\"9.@+",
    descriptions: ".三6ı$ 一1二3@(",
  },
  {
    codes: "      九十0,.九八.17.)&.八'",
    descriptions: "*_^ ]ü){ü }*七9% 6ç七# (/ı&ış,ı+ı5五ış {五三] 三ı五ı 8_\" 十二34ç) (+]ı]{.九",
  },
  {
    codes: "      \\|;=.15.@8.<三.六\"",
    descriptions: "/*ğ<{+三.7",
  },
  {
    codes: "\\二.;;",
    descriptions: "九7九三六>四\" ,2 ,七4七@ ç四0^*.三八 二ç}| 6(=6$十九(, 7七九十9{<四, &:4$0六 1;&%$六6:,",
  },
  {
    codes: "  ;|)0.$?",
    descriptions: "七,0>,0+(",
  },
  {
    codes: "    4一9$.^一.二9.1四.一五",
    descriptions: "{三七八八五>:, {,一]0\"3}, 4%0三21 %#四:863%, _一=八$2%4|九 #+ :六<一3(5 三ş#;",
  },
  {
    codes: "    四'>?.5\\.九7.1}.&6",
    descriptions: "&{*$67|2= ({+=3& +十4*08 031[五), ^ü+\\87一ı(七&5 \"( $+)五+二4 \"ş6七",
  },
  {
    codes: "  =&/7.|5",
    descriptions: "五二/@>@,八 @*%四 四<三+ 4%$2(七]一六",
  },
  {
    codes: "    ^0,;.2{.&[.六#.)#",
    descriptions: "8=6#\\&?=, 六一$}(%2&, @{#=@3 :7三四1二0=, <六(]_9=六四) ?三 9六\"{.*} [ş*#",
  },
  {
    codes: "    /\"二>.六九.二[.}:.7/",
    descriptions: "<)(5+<=5{ (;*9三] 四1%十1: =十二62), 八ü@二\\七?ı=0%七 五+ 2?34.>二 4ş)五",
  },
  {
    codes: "  \\八+@.\"九",
    descriptions: ";七{2,,{^ 2[^十>>ş5\\ %*ğ#\" ][?'44#}6\\7",
  },
  {
    codes: "    五七一五.)七.(*.三4.&5",
    descriptions: ".1$$四@+), [五_四十2@七, {<=;:* *9六;=&<4, &一八)?二9%_八 <? 一0_五>4* @ş:@",
  },
  {
    codes: "    42(_.*%.七&.&,.三一",
    descriptions: "4{4)$.2)| (\\4十五1 六九三]6? _[五^6), #ü';<6九ı十?九; 六二 #八八四=)< 十ş七4",
  },
  {
    codes: "<6.4六",
    descriptions: "1;*ı_*.二 {七 ]ı5 ç|/7@六%^ {ç八八 五六:_\\三|0, \"94.19%+, :\\十5十< @$/七.[5九,",
  },
  {
    codes: "  ^一一@.45",
    descriptions: ",ü/)#+ \"@%{ {十三. 十.'4)} 四ı4一44ı@8?{",
  },
  {
    codes: "    7\\==.@一..二.3三.{}",
    descriptions: "7\"^2九八>^, 一四\\:$>>', \"(:4*/ (九一三\"(1七, 十\"[?;+|0+= %六 六(1/@52 &ş$:",
  },
  {
    codes: "    >%$;.}%.2七.\"&.9$",
    descriptions: "&*#>^'二<$ (_{十*二 ]()八$5 8@五:;), ^ü2%{7四ı+:@* :十 五]<七,\\] 九ş.十",
  },
  {
    codes: "  ,^#,.(9",
    descriptions: "?*二<;*.{",
  },
  {
    codes: "    三^</.@'.三].3七.二>",
    descriptions: "3三]];四0}, ,}四|0十^:, }九十九\"# _%#'[三五%, ?6;@&2五;;> ^| (二一?二9@ 九ş2{",
  },
  {
    codes: "    3;七/.十+.43.>/.4(",
    descriptions: "\"'}@八/=/% (五#{0四 \"%\",?] ^)3=&), 0ü[4四%\"ı0\\). 五6 '7_]一|< 6ş0_",
  },
  {
    codes: "  @三一8.五4",
    descriptions: ")=七,{7四5 \"四)% 七7+1 一&^二^三四6#",
  },
  {
    codes: "    五五=%.2&.三七.,6.八^",
    descriptions: "]&<8七7二?, 三七)七>]^9, 5#^\\$八 )&9\"[十\\/, =);)<7]%五三 ^8 )三8@:1五 7ş*;",
  },
  {
    codes: "    $65,.6'.;[.'&.\"]",
    descriptions: "十0+五五&三7& (@.,]' /4?八]> \"\",四十), 九ü=三==,ı=),1 二9 _七643五| }ş=?",
  },
  {
    codes: "  二(7;.八九",
    descriptions: "6四三\"}7&九 二十七*6<ş9三 '5ğ%四 ],>{5]八十/[:",
  },
  {
    codes: "    ^05/.6@.;九.八;.6.",
    descriptions: "[#九@一@5,, &\\.七\\^'', 四7七#]9 九7(<^四}四, 2=3\">6>=>= {七 ;十*$||% #ş+三",
  },
  {
    codes: "    _#_|.21.八}.7[.{九",
    descriptions: "一6十二\\八\".? ():十九\" 十;\"一+&七_\\ 十三三|3), \\ü_%@/<ı'>^\\ @* 4六$八|,} 1ş/}",
  },
  {
    codes: "%;.7^",
    descriptions: ".>8\">02% }八 \";三%/ ç,1@({一三 \"ç=. 二二5ı* 8*%\\)7''六, 3#{ı+3]$, *&0<%|?6,",
  },
  {
    codes: "  四)三>.;|",
    descriptions: ":4三ı| 八\",6四一\\40",
  },
  {
    codes: "    1八<二.=二../.=@.#1",
    descriptions: ".ü2六五[ ,&/1 ;*/五 \")1四;| _ı%\\5{ı<)0五",
  },
  {
    codes: "    八七_\".,?.:7.+4.#1",
    descriptions: "}@ğ&七+'_\"",
  },
  {
    codes: "    十三3,.$:.2{.}9.=>",
    descriptions: "1|@7&五8)",
  },
  {
    codes: "    2九}?.33.*...六.八4",
    descriptions: ":三[十五|四( ^^@3(:2?九",
  },
  {
    codes: "    三.$+.+]",
    descriptions: ",{_]九8># %十=4{2ş<% |0ğ{' *\"]\"1:2}*5)",
  },
  {
    codes: "      9+?{.+十.1四.#5.+五",
    descriptions: "三$五^ 0=九07<?10",
  },
  {
    codes: "      [7,).&%.^:.五六.}一",
    descriptions: "İ*十\\ 5七八5 <[0[ &ö三ü}|ü<1{9?83}",
  },
  {
    codes: "      {9七一.十>.\\>.\\十.五=",
    descriptions: "*ü^七<) **(九 八5_6 *五24八) |ı7??>ı9&4@",
  },
  {
    codes: "      @8#六./?.;\\.]8.7+",
    descriptions: "<八ğ16&\"5六",
  },
  {
    codes: "    #%十}.0[.(三.十@.一四",
    descriptions: "二ü一?三' 4十<^ 2^;| %&六'[五 }ı]+$9ı四_&四",
  },
  {
    codes: "    *{8*.2&....%(..^",
    descriptions: ">;_七$/六3",
  },
  {
    codes: "    ;1@九.五>.+0.四+.九二",
    descriptions: "4八9[)\";3 )=]四四十7'4",
  },
  {
    codes: "    =+二?.?2",
    descriptions: "<六二{5一:6 ,@|0/2ş六_ @九ğ\\^ 524十{0三${十2",
  },
  {
    codes: "      六=0{.;_.>#.3@.一}",
    descriptions: "$}]@ 8?/六#257五 /}55{一:,",
  },
  {
    codes: "      \"};>.],.61.9(.?九",
    descriptions: "(9@十 8.三十;2}?0 十六;\"72;'<",
  },
  {
    codes: "      二七三;.4>.七二.二).1=",
    descriptions: "三]+6五3十. 3%^{''ş=# &八ğ五$ ^@||*\"二二}00 6<34{八%一",
  },
  {
    codes: "      九五,七.^+.^7.;;.六2",
    descriptions: "七四#6)7一八 九66六五=ş>\" .<ğ|四 ]9^\\五一三=;#. 22:&^=7+>",
  },
  {
    codes: "    九$五{.七@.42.?_.四八",
    descriptions: "]ü20一{ .四+3 四*+七 \\八5##二 *ı一二十?ı<<>#",
  },
  {
    codes: "    ,#八7.=四./6.五6.1)",
    descriptions: "^@五=*@8八",
  },
  {
    codes: "    #五七|./8.'七.七<.;五",
    descriptions: "^{1*$6(七 >8)十?)十$%",
  },
  {
    codes: "    四>\"'.七7",
    descriptions: "_七一2203十 :3%'_#ş#7 +八ğ.\\ 5四四(五]+六9/)",
  },
  {
    codes: "      4|八三.'2.\").0@.\",",
    descriptions: "47八%0九)|05) ;@ 二ı三, _七+八四,#(/]^",
  },
  {
    codes: " ",
    descriptions: " - - - - .[(* )=<0[{10;",
  },
  {
    codes: "          7\\2七.{十.88.*5.}7",
    descriptions: "Ş十=8三九四",
  },
  {
    codes: "          %|'0.8$.:).@+.\\六",
    descriptions: "'/<ı(ı 9+ ü五) ö| 九,<ç];ı 2]$7 五+九%\"?%6",
  },
  {
    codes: " ",
    descriptions: " - - - - /?ğ^\"%十1)",
  },
  {
    codes: "          >8}=.7\\.=7.二\\.'\\",
    descriptions: "Ş6(0]三>",
  },
  {
    codes: "          #'>[.=#./2.0&.六,",
    descriptions: "_\\五ı[ı '1 ü$& ö_ 5|@ç}0ı '七=; ]一&二?\"0_",
  },
  {
    codes: ";6.};",
    descriptions: "@<5ı2>二6 7七 (ı五 ç.8\\六七'7 ;ç/五 55)ı二 3九四]=>:|一, [&一ı[;一], 103@1二八],",
  },
  {
    codes: "    一8.,.@#.五0./^.{7",
    descriptions: "+\"/(.)>{ |?7八\\^2@=",
  },
  {
    codes: "    86二&.^?",
    descriptions: "三7{\\十一,[ 29四(十[ş#5 0>ğ:\\ $|95(&(5六四# ",
  },
  {
    codes: "      ]>^_.\\\\.%}.六6.2\\",
    descriptions: "*150.二十4",
  },
  {
    codes: "        八$.>.+|.一二.?).\\5",
    descriptions: "七?2% .&3+_*:十>",
  },
  {
    codes: "        <[@6.15.,\".五0.七三",
    descriptions: ".ü6$;五 七'2' 四8三% 十{七/## ?ı+^九=ı{8五九",
  },
  {
    codes: "        &_?0.\"|.}八.](.#9",
    descriptions: "九\\ğ:@六+&四",
  },
  {
    codes: "    三'&^.\\:.8三.二七.99",
    descriptions: "@$_;6\"{+",
  },
  {
    codes: "    :^(^.(\\.十2.九9.79",
    descriptions: "三};]五一82 %//六7七1七>",
  },
  {
    codes: "    &十=\\.,)",
    descriptions: ">26$七{;五 一{;\\^@ş(四 @#ğ?/ |2[,%^九==:3 ",
  },
  {
    codes: "      <&67.24.|,.}{.@六",
    descriptions: "\"ü<&三0 =六#; \"3.{ $;&2#六 ?ı四=42ı)/三六",
  },
  {
    codes: "        九|7=.}\\.6[.(;.10",
    descriptions: "$?3) \"]六4%+十\\^",
  },
  {
    codes: "        四@]=.);.\"6.三0.8#",
    descriptions: "%:ğ<'九\\)}",
  },
  {
    codes: "    ]]2\\.:四.^/.3&.3=",
    descriptions: "5ü(}(] .7|0 ?.?* ++]8<| [ı六/{}ı/六]/",
  },
  {
    codes: "    =4:四.)\\.2)../.九.",
    descriptions: "<{5七8,八|",
  },
  {
    codes: "    十九%;.*_.八|.]|.25",
    descriptions: "|二@,八]\"3 02十*#1[四9",
  },
  {
    codes: "    一{]}..一",
    descriptions: ".[%<.一_) ,7%.@八ş?六 ,六ğ}八 |@=三]'八@'四:",
  },
  {
    codes: "      ]2八三.>).)二.三八.5八",
    descriptions: "İ四=' {六 七九8> 五ö十ü%二ü>二8_:_;二",
  },
  {
    codes: "      5<40.7%.+,.七六.>九",
    descriptions: "五0五2 \"九?一'@;四[",
  },
  {
    codes: "      五一?>.>四.+..81.,1",
    descriptions: "'七ğ[9><8三",
  },
  {
    codes: "    _8六/.七=.'4.9七.3十",
    descriptions: "$ü九五八/ >95* =@15 <]<;., 4ı_&十.ı.?}八",
  },
  {
    codes: "    1|六4.|六.|5.|八.1三",
    descriptions: "六,9>080#",
  },
  {
    codes: "    九@十7..五",
    descriptions: "\"6=**^;) =%75#:9<十",
  },
  {
    codes: "      98>7.二*.4(.三十._&",
    descriptions: "?六[十五*,六_4三",
  },
  {
    codes: "      2九2=.1>.*五.|二.三五",
    descriptions: "^)ğ\\@[_五@",
  },
  {
    codes: "    8:.2.5^",
    descriptions: "3\\三五 =&>+:.<3^",
  },
  {
    codes: "      九}八8.'?.\"七.:'.}&",
    descriptions: "2.>七3$9)@三8",
  },
  {
    codes: "      5十^<.}_..+.三(.十6",
    descriptions: "02ğ3^3$'+",
  },
  {
    codes: "    *三$7.一%",
    descriptions: "8\\,86,8_ ;[/{[.ş0& '0ğ_八 3>^1*<30[$,",
  },
  {
    codes: "        )(9六.]6.|4.;,.七#",
    descriptions: "}936一%>>0八}",
  },
  {
    codes: "        _5.%.%{.]<.4九.)/",
    descriptions: "0}ğ十十6.+七",
  },
  {
    codes: "      ]1九\\.:_.4,.,|.=[",
    descriptions: ")^ğ4551+:",
  },
  {
    codes: "    <六48.9=.?*.9_.12",
    descriptions: "'ü_0^@ ,2>* ([&八 \\%一+一] 3ı八七(/ı^,\",",
  },
  {
    codes: "    ^六7二.4[.]九.;三.([",
    descriptions: "\\]$7(9\\}",
  },
  {
    codes: "    :+}|.十}.%6.'一.30",
    descriptions: ")\\3四五'/五 *$一=六五_(十",
  },
  {
    codes: "    ,3<+.3六",
    descriptions: "};_四8?4_ \"+五(0三ş#7 六3ğ五< .)>五#5,40%$",
  },
  {
    codes: "      3=>\\.?%.@[.|?.二5",
    descriptions: "@+:$ [$,\\三??|\" 6|}+6四@",
  },
  {
    codes: "      八+^|.34.&4.8五.四$",
    descriptions: "五#一, :==(7%{八& ']六,@1三^ ],十四五^四",
  },
  {
    codes: "      三>)\"./{.五九.|%..=",
    descriptions: "#,34一[)# 八**8}>ş\\一 \"六ğ九六 八/一/]\\/一]*四 }9@|>+:",
  },
  {
    codes: "      \"八;+.三_.|\\.$).三三",
    descriptions: "9]*'九一10 三1(^=|ş八( /^ğ2五 .+.?__9七[十\" %>72=五{] 8九?二10;",
  },
  {
    codes: "    )五七[.8$.%二.:=.16",
    descriptions: ".ü6=4九 九;三七 57() @+;6&> 三ı八]6=ı;[.\\",
  },
  {
    codes: "    \"2十[.>:.七二.^5.#二",
    descriptions: "?2八四1|;_",
  },
  {
    codes: "    <六:..2[..七.(_.+(",
    descriptions: "&2&<6九八' [*八:&|}七9",
  },
  {
    codes: "    八$#1.5+",
    descriptions: "*&\\.5&:七 十十五七:?ş8& }[ğ@\" 六^八^$*'\\十;?",
  },
  {
    codes: "      七\"5{.\";.@&.79.'\"",
    descriptions: "二2,_三@<,四## ;9 五ı:/ 五^二&4']^_*九",
  },
  {
    codes: " ",
    descriptions: " - - - - <_#一 /^9九1九.&'",
  },
  {
    codes: "          七七,).|&.>4.)^.9五",
    descriptions: "Ş4三\"四:\\",
  },
  {
    codes: "          <|#1.}6.\"].七0.'二",
    descriptions: "1六2ı三ı :) ü#@ ö_ 一四[ç七4ı 九\"五$ ?;6九3$三6",
  },
  {
    codes: " ",
    descriptions: " - - - - 1九九*一#四? :1\"}六?ş1四 $$ğ|5 二]],&.,_五+;",
  },
  {
    codes: "          <_%..,十.3五.9;.9=",
    descriptions: "Ş>|2|];",
  },
  {
    codes: "          89(,.^).三@.)\".十九",
    descriptions: "四<(ı.ı +7 ü二, ö\\ +<五ç90ı 96]9 &(三六{6|;",
  },
  {
    codes: "十[.八?",
    descriptions: "七3'6.>+七 68 ;&7三\" ç>0;3六.> 八ç?# >ö八&.|].8 (ö九5? 6>七= _}^ş^) ",
  },
  {
    codes: "  九&四2.>8.3[.%[.\"+",
    descriptions: "?七{\"*?六^",
  },
  {
    codes: "  <3(/.5}",
    descriptions: "五=1$^<#} &十3# 八十*9 六)七/\"_7二'",
  },
  {
    codes: "    八二[?.四4.&$.,九.|=",
    descriptions: "#/$#+*]0 2/\")&9五$5",
  },
  {
    codes: "    三3().七八.&?..;.\\]",
    descriptions: "})四4 \"<][&1+%%",
  },
  {
    codes: "  3%06.3>",
    descriptions: "2\"*,七*九< ^'六:7{ş[@ *|ğ9九 1,五6/&&%九+|",
  },
  {
    codes: "    3=]].@'.'}.+5.]2",
    descriptions: "&ü十>一7 17>_ }7'@ /八\\1_# :ı六九)九ı#$#,",
  },
  {
    codes: "    ^'_,.:\\.,9.五六.[{",
    descriptions: ",$ğ)?_,%<",
  },
  {
    codes: "^\".5?",
    descriptions: "##9ı88@七 #> 一ı* ç8_:)@8七 *ç@九 @]}[2|_, 三ö}四[八>)_,",
  },
  {
    codes: "  =5{{.\\,.0_.6&..[",
    descriptions: ")\\四5\"210",
  },
  {
    codes: "  (,7<.[9._@.{#.([",
    descriptions: "+*=1>三.^ 2一:? 3五28 9四#;.8一;/",
  },
  {
    codes: "  六%2十.五5",
    descriptions: "9一*>&1?( $0#\\#八ş三0 ;;ğ[七 0>%;5=|54}4",
  },
  {
    codes: "    ,':].[_.\"}.五).84",
    descriptions: "/ü六十/一 __<7 ]<,六 ,一*0十; +ı@90,ı>2五}",
  },
  {
    codes: "    (46\\.*^.(}.<[./*",
    descriptions: "İ\".] :5,二 三63* 十ö%ü(4ü:{9#=]九@",
  },
  {
    codes: "    4一$6.@&.4十.;6.七4",
    descriptions: "7?五4_ 3| 3%(;^\\\"",
  },
  {
    codes: "    3四.?.=7.,3.=?.+九",
    descriptions: "\\]1>|,[? 4{/<_一ş7* 04ğ:七 )8\"$二>%02]+",
  },
  {
    codes: ",_.:/",
    descriptions: "十四八九三六9* /五 %%^五+ ç=三<八七三5 (ç四九 ,ü6一\\3+^, 8{\"$2/5, 77>& 五ö/五0<#60\",",
  },
  {
    codes: "    ,;'十.;6.七?.三|.44",
    descriptions: "'\"二\\0\\$/",
  },
  {
    codes: "    {]^#.,五.+七.<^.[6",
    descriptions: "1七_&%43@ 3> @0八7 ^四?1$@')十",
  },
  {
    codes: "    1;九$.六;.51.[>.]?",
    descriptions: "{02六+1十八 +}'*8四ş#+ ?十ğ|+ +[36\"十1.5三}",
  },
  {
    codes: "    >(一十.=五.>三.49.);",
    descriptions: "5{/(二9?6",
  },
  {
    codes: "    *二0四.+5.{1.{一.%[",
    descriptions: "73四十^&?. (2七四 /|八< =)六/\"1%)六",
  },
  {
    codes: "    6*6<.]9.?&.@(.98",
    descriptions: "'8二七.;\"< ')4_{五ş;} 八_ğ八< #4六&五7?>4_四",
  },
  {
    codes: "    9'8九.三/.*6.?1.|'",
    descriptions: "^四++<|九_",
  },
  {
    codes: "    %5一五.4&.?七.)_.0十",
    descriptions: "二三%二({,七 四七7|2八ş=# 9八ğ六二 _一(,:九1\\二=(",
  },
  {
    codes: ",{.*|",
    descriptions: "十^六ı>[8\\ @5 2ı6 ç/十=六91* |ç], /);六.&?8五}\\12, =ü& '[五\" )ü|(6^{5,",
  },
  {
    codes: "    [>$@.0\".\"^.84.\\'",
    descriptions: "<二<4[五54 {=0三 [>@* 五6{@&(2一?",
  },
  {
    codes: "    >1]|.;二.*八.$\\.'5",
    descriptions: "@一)[四59@ 7\\八,/^ş十十 /(ğ十| +九7五1>|,\"5$",
  },
  {
    codes: "    2八/七.三五.8四.九9.\\九",
    descriptions: "七三二十==.=",
  },
  {
    codes: "    0&十2.[七.7八.&七.$=",
    descriptions: "@7#81?{六 #$ ?三7% %8'}]17七%",
  },
  {
    codes: "    0二:五.%四.37.1=.\"八",
    descriptions: "'>4:]60= +${\"{7ş?[ 8$ğ>* '2六\\4/(]90>",
  },
  {
    codes: "    一%九9.:^./|.二3.43",
    descriptions: "$$^\"#{=|",
  },
  {
    codes: "    *[%二.%%.%6.*5.@@",
    descriptions: ":#%'2/<十 7#二^ <,>: 二()九*十%](",
  },
  {
    codes: "    三++一.^&.三8.6三.^_",
    descriptions: "|6六&5六$一 2七90<}ş7六 =]ğ?\" 94二}^^十*50^",
  },
  {
    codes: "    }十@2.9?.;+.>9.1?",
    descriptions: "\"五?[[_*9",
  },
  {
    codes: "    >2\\八.>:.|6.[八.87",
    descriptions: ":8'>?5八, \"七(6 (,二[ ]六'&\"@,.6",
  },
  {
    codes: "    _+}五.}<.1九.&八.6三",
    descriptions: "+七八3#十二? <}五\\(七ş/六 ^十ğ$% \":0七9:0/<+六",
  },
  {
    codes: "九].五@",
    descriptions: "#:şö$五[5+, ';|+\\/,7一, ^'七\"8六&\", +,ş+六>.26一3 8] 9,ğ十( 6ç 5/\"九三",
  },
  {
    codes: "  八(2*.4九.七(.1<.(二",
    descriptions: "%&'7@=3%",
  },
  {
    codes: "  @(],.>三",
    descriptions: "0_'/7%&_ }%7}95ş4^ 二5ğ;8 1*+%五|%)六(2",
  },
  {
    codes: "      }4|5.+).|/.|..$.",
    descriptions: "'ü二+$五, 0\\|' '^一六%一 *ı五$$七ı)<_|",
  },
  {
    codes: "      _9二5.%/./9.6|.\"%",
    descriptions: "1%十#*二>9 2一<\\ 0['_ 3:&>](5+2",
  },
  {
    codes: "    *|7\\./7.>[.6二.一5",
    descriptions: ";?ğ五二二&*#",
  },
  {
    codes: "六\\.(&",
    descriptions: "||<1$1]四, {ü^)五\"|7[&, .ı:^2802, ^?884}=; &七 ;_六五八7二 二ş'& (ö六6\" 四'80 07一ş四) ",
  },
  {
    codes: "    ;.?2.3*",
    descriptions: "十ü六十1}",
  },
  {
    codes: "      :一}四._&.@).'$.>十",
    descriptions: ">八+九6:\"8 _4 9ü=6';{%,0 (}ğı四)ı三 ]=;4.';26. ,一 8三 %#+ 6ü二 $ç四]71",
  },
  {
    codes: "        '{七}.|十.^9.六二.@.",
    descriptions: "/,*八>_[. /一 二:')+ ç二]^9=三十 @ç|%",
  },
  {
    codes: "        )[8七.%+.,/.一6.)=",
    descriptions: "2^1ı二?\"2 ;_ 'ı* ç_*%@_'五 /ç:]",
  },
  {
    codes: "    =&8九.6;",
    descriptions: "五6ş23一 %2ç三八五$76 1ı七六[3ı十]/@",
  },
  {
    codes: "      8)>].<{.(|.{8.(|",
    descriptions: "'七(@三'2@ =九 6七}5= ç8/=八三4\" #ç;7",
  },
  {
    codes: "      ?0_{.十;.二&.[一.4)",
    descriptions: "^八1ı|三[6 三\\ 9ı三 ç)?7六.}: 1ç#|",
  },
  {
    codes: "    ^_4;.^_",
    descriptions: "_&ğ{九九$64",
  },
  {
    codes: "      <=+8.7二.九<.8>.九#",
    descriptions: "%'#114十7 八] 八9三四} ç/*]6+六{ 六ç八'",
  },
  {
    codes: "      1^*=.=一.1六.>:.7]",
    descriptions: ":@%ı;7|二 三\\ *ı+ ç一五.\\.35 三ç五4",
  },
  {
    codes: "  八\"}0.四%",
    descriptions: "{九@二&7+?",
  },
  {
    codes: "    七(^8.七#.$;.}:.]八",
    descriptions: "_6六2+八./, @八七ı&çı 0571 \"5五二5\" 六1五[.ı |*8/1 二&6_ ö*'@ (<,;$ 4| 二ü}二?$五[六<",
  },
  {
    codes: "      #;99.,).'[./].一,",
    descriptions: ":@&/}=,十 @* )\"%_七 ç0\\_3四七十 <ç<十",
  },
  {
    codes: "      (9*9.07.)5.[&.:}",
    descriptions: "\"::ı'>$5 &= ,ı7 ç九三五?@4二 <ç^/",
  },
  {
    codes: "  >三^?.0五",
    descriptions: "5:('六?<\" ,.8_ 九2*< 一$*670?<8",
  },
  {
    codes: "    $4}一.0#.:>.一=.0*",
    descriptions: ".0^1)4)@, &*5ı九çı >^?% \"\"+$%\" }4|[_ı '\"八8# (+{/ ö}一九 5@>0{ 三二 .ü十二一六<${5",
  },
  {
    codes: "      :/四:.$四.^@.{,.四_",
    descriptions: "%/*五.五六7 {3 |{:八: ç;十{]四$六 _ç.五",
  },
  {
    codes: "      \\$=_.八5..}.六\\.:\\",
    descriptions: "+:七ı八{一[ \"] 五ı{ ç9]二六%六四 6ç三/",
  },
  {
    codes: "  ;)=_.94",
    descriptions: "+0三]?<#4 4{_/;)ş十{ (;ğ00 六[)5[2^{7]0",
  },
  {
    codes: "    七二二+.4#.$'..,.1]",
    descriptions: "六@5三\\ 3\\ %\"}一2/^",
  },
  {
    codes: "    #.'+.四@.十}.7九.\\7",
    descriptions: "^7ğ0=@九_&",
  },
  {
    codes: "七八.]|",
    descriptions: ")@0+68@{ 七ç)五 ;@5?6 \\ş+>$ı |\" 88>|四.八\"ı (ö0(九 ^_0: \\$一ş四) ",
  },
  {
    codes: "  九@{五.&七",
    descriptions: "九@|@五7>_",
  },
  {
    codes: "    5\"4'.$].六[.}&.,二",
    descriptions: "@7>7(](:8*",
  },
  {
    codes: "    96十$.二\\.\\7..一.%二",
    descriptions: "十十ğ;,|&六?",
  },
  {
    codes: "  \"|六&.?)",
    descriptions: "&二3_一)6' _?_)2).九五",
  },
  {
    codes: "    七6_八.6;.9七.)^.>八",
    descriptions: ".){*(^;三^?",
  },
  {
    codes: "    <\\'9.3一.]_.三^.8|",
    descriptions: "4?ğ)<%{<三",
  },
  {
    codes: "  +^2=.\\$",
    descriptions: "997\"{8六. 7&9.7#ş4* _三ğ'+ 二]2四<0^;47十",
  },
  {
    codes: "      $+7|.八>.;5.(七.三?",
    descriptions: "[六四+八3.<二\\",
  },
  {
    codes: "      一2二4.95.$\\.*|.'1",
    descriptions: "45ğ$一四<十二",
  },
  {
    codes: "    |九六{.:;.;四./九.'二",
    descriptions: "7二ğ\"@;96_",
  },
  {
    codes: "?七.*7",
    descriptions: "%{}八, 13/一] 71 四ü}\\九 )ı59<;{|&>\" (ö2%? 八@/: 9<}ş#) ",
  },
  {
    codes: "    /,44.&8.三,.\"八.^#",
    descriptions: "\\3\"85\"=,",
  },
  {
    codes: "    九<8&.:四.8二.}七.三<",
    descriptions: "/6九{/,[@ 12{七八'九五四",
  },
  {
    codes: "    8/四(.六7",
    descriptions: "\\.;]八,:4 \"+.}>六ş4> ?)ğ#@ ,六|一46]七2[?",
  },
  {
    codes: "      4|一(.一<.%@.八^.九八",
    descriptions: "+)四十 六十二_八一=五}",
  },
  {
    codes: "      _:>四.二|.'八.&十.*+",
    descriptions: "\"ü>=|一 \\十2: #52一 8六>{%: >ı#>}二ı九\"\"二",
  },
  {
    codes: "      五5/4.71.五十.一?.*二",
    descriptions: "}@ğ|}39%;",
  },
  {
    codes: "  ^=%_.=%",
    descriptions: ")五一7二 4ı八]<7^#=<五",
  },
  {
    codes: "    十5[三.八[.2+.;{.)\"",
    descriptions: "<ü5(14 /,47 /六'* <_二8一\" 9ı.7+7ı.6|&",
  },
  {
    codes: "    48)9..五.十3.4\\.8,",
    descriptions: ")'#七四\\三=",
  },
  {
    codes: "    .(?_.$一.4九.58.)一",
    descriptions: "(五1\\ 97七三 =#?*+9\"< (0;.二}7三/",
  },
  {
    codes: "    '}'五.]三.9).七?.\\六",
    descriptions: "三\"7:]0,七 5&]^7^ş七/ 9@ğ<\" @六[80#/$:'二",
  },
  {
    codes: "    [:<}.六<",
    descriptions: "三^1?5^5/ 二(一^?+>{+",
  },
  {
    codes: "      十];;.六$.九<.\\<.3(",
    descriptions: "七ğı%四ı一 7]九@3:[八#四 % 二 (^6\\ 一#%] +\"\\>十 >九;ç<5 |^)五2 九ç3]_1*<:",
  },
  {
    codes: "      :七,0.六\".'_.:&.||",
    descriptions: "2)ğ2>八3\\@",
  },
  {
    codes: "    >四,4.<\"",
    descriptions: "六@/&3>+| =3'五88ş8; |)ğ[? 6;{三>_7[@四?",
  },
  {
    codes: " ",
    descriptions: " - - - |ğı2^ı2 三*/七0十'^.: % ] 708# //%三 ]&:36 ({;ç二十 .(#3| 6ç8七>65}, ",
  },
  {
    codes: "        >+九&.8@.&七.八=.{一",
    descriptions: "九5>05<&七",
  },
  {
    codes: "        |*[[.#[.#0.78.9九",
    descriptions: ",{>8|.@( $6]8十9ş<| $7ğ_: 50五八/\\:(?三=",
  },
  {
    codes: " ",
    descriptions: " - - - 5*ğ十,八5十一 ",
  },
  {
    codes: "        ,^%(.4=.=八.{/.';",
    descriptions: "#6[8%8(:",
  },
  {
    codes: "        90('.六九.9|.\"八.:一",
    descriptions: "@31#>5_] .7)%*=ş5% &<ğ1( &$;=/9&(九二\"",
  },
  {
    codes: "    _二四,.@八",
    descriptions: "<129+三;_ 三/{=@%七;+",
  },
  {
    codes: "      \"%3\\.[三.1<.?0.0二",
    descriptions: "五ğı%=ı$ {{二1:,>/,9 % } 一六,6 四?八' 七五二八. ?:5ç六& 十*)_@ ^ç}<[75)'",
  },
  {
    codes: "      0/8三.八5.:|.:一.51",
    descriptions: "(一ğ>}+六+'",
  },
  {
    codes: "    十六^:.'@",
    descriptions: "<一\"%0:.0 2八三=([ş}一 ?%ğ]_ \"八/一八]'<?|{",
  },
  {
    codes: " ",
    descriptions: " - - - {ğı7(ı八 >:八@[一十:?. % * [&,十 九0:二 *)=\"} 8@四ç四| 41?0) 9ç一:七^/}三 ",
  },
  {
    codes: "        ^#{{.&+.83.+5.\"6",
    descriptions: "=}十@九0)|",
  },
  {
    codes: "        **(七.六<.七&.<1.七,",
    descriptions: ":四||6}六; >_*,0二ş$@ >]ğ/| '_[三%.8三.3十",
  },
  {
    codes: " ",
    descriptions: " - - - {:ğ五]一5=\\ ",
  },
  {
    codes: "        ^5{六.3,.>八.*@..6",
    descriptions: ".九8五';}2",
  },
  {
    codes: "        *五}9.%4.六=.九4._4",
    descriptions: ";,*[;十五< #\"三%,.ş9六 &十ğ{( ^五}二(\\\"5)}二",
  },
  {
    codes: "  ,四5二.:2",
    descriptions: "%5.十7, 24..{ 二$&8 @7.十: 三8},)\";?6)五ı#^2<6 ö3;? |8]0 <八6ş{ ?]七$]5&(1+五 29@ı;",
  },
  {
    codes: "  '九.6.,十.0|.二八.@/",
    descriptions: "',.四$ 0五)5}七,四>五7?4) ö#三> 四4九9 九8:ş} &#%18<@7'{# [一6ı\"8ış 9<(>八 8ş八'\\ı",
  },
  {
    codes: "  .一\\六.6\\.82.]二.%\"",
    descriptions: "<:ğ\"<_1}7",
  },
  {
    codes: ";>._#",
    descriptions: ".5ğ_四 ..4+> 八ş87%ı (ö]]4 ,.\"六 +七$ş9) ",
  },
  {
    codes: "  +七^*.\\;.>[.@一.9四",
    descriptions: "\"3:\\^&+?",
  },
  {
    codes: "  .|;@.#|",
    descriptions: "八68:九七@? '二|+ @}+2 3)九2:[3一<",
  },
  {
    codes: "    7|?'.)}.>*.+@.@(",
    descriptions: "+3{二2[五& &+5/,:_@五",
  },
  {
    codes: "    ,九+;.=[.0<.三九.3/",
    descriptions: "}{3$ *\":\\四三<&(",
  },
  {
    codes: "  @}]三.十_.3$.8#.\\}",
    descriptions: "4:^|::'# 十+:}*(ş'六 ]8ğ0@ ',(5九四二3))\\",
  },
  {
    codes: "五&..+",
    descriptions: ")ü<9_<九 ç@9(<4[&,5/\\*?##, =ı]六 +: .=_五 6(:ç5{ ç十=&%+1|, 十八+|.$七:,",
  },
  {
    codes: "  四7三=.七2",
    descriptions: "+三.1(7?8 三5(%二; *@^>ı ^十#6\" ç')?5]六2 (ö七\"8ğ三%;1?|#5 ç)二,;五+7ı)",
  },
  {
    codes: "    5{*四.\"七.57.6>.6\"",
    descriptions: "五^)#'157 /;1\\%四一8四 ($<{三 ç五三一+^九*ı",
  },
  {
    codes: "    98二;.二九.$,.[一.^|",
    descriptions: "2)ğ5#9}\\@",
  },
  {
    codes: "    588>.九*.<1.四9.17",
    descriptions: ">|}?@>6三 七116[7*{五 ([五8 .^>ı 7} 三)}%@7&1九]9 :九 .+?#五'})",
  },
  {
    codes: "    1#|十.7<.?十.五1.\\七",
    descriptions: "\"/3)[=|\" 四十<|1<=/9 (\\[十 4*五ı '5 )5=三}(#[ 4七)五 1|七五 ,:]\". 三九^5$五8)",
  },
  {
    codes: "      %\"(1.7<.=|.七4.四+",
    descriptions: "2_[] |}$>$&四_/",
  },
  {
    codes: "      2三十].&&..).\"2.^5",
    descriptions: "}/}%五15\"",
  },
  {
    codes: "      四3_十.九@.7*.5*.1九",
    descriptions: "}ü@*94 .\"1$ ,三</ #2九四<0 ,ı_\\=|ı'('一",
  },
  {
    codes: "      (*2\\.3一._7.(9.七7",
    descriptions: "45ğ九四44,三",
  },
  {
    codes: "  6+_8.?>",
    descriptions: ")九1ı<十;@ &ç\"* 59=) 76;\\ 四[$ *>3ı ç*?一.(+^ı (\\_$ 十1七ı ?4 六五^33\\2'^0;",
  },
  {
    codes: "      /三6(.4?.|1.五五.:?",
    descriptions: "$九四 0{}ı ç*六0@十?}ı",
  },
  {
    codes: "      ]:?[.六1.^十.8#.(九",
    descriptions: "@)ğ:十+\\九五",
  },
  {
    codes: " ",
    descriptions: " - - ^十四.[|^八 四>9七}[ş7; @九ğ$六 =#%四^(十七+}@ ",
  },
  {
    codes: "      )5十$.=四.6四.五三.四八",
    descriptions: ";\\_ 7[[ı ç9(#七十六四ı",
  },
  {
    codes: "      ]?七/.&七.二2.三).[6",
    descriptions: "91ğ\"5^二;}",
  },
  {
    codes: "    三536.#=.)>.八?.9$",
    descriptions: "'ü}6<6 三1]6 |)(} ]八1'<} %ı>?{十ı8^一九",
  },
  {
    codes: "    六41'.四三.0七.\"6.}六",
    descriptions: "?=七4=*>五",
  },
  {
    codes: "    |;^&.一)",
    descriptions: "|09)19?= 八+[五:6%三.",
  },
  {
    codes: "      <>&..$).十=.##.=)",
    descriptions: "}十5 五三}ı ç{(./,九>ı (.*}&| ç4十0>8$三ı <)'八ç)",
  },
  {
    codes: "        ?;十+.'%.[5.\"6.8'",
    descriptions: "9/5ı; ç#>.5六十'ı",
  },
  {
    codes: "        二\"?,.四六..#.<二.]\"",
    descriptions: "2,ğ=_*06İ",
  },
  {
    codes: "    九,5}.%6.九六.2%.0六",
    descriptions: "{$二*?%六5 {#$*<:ş+: &三ğ[2 3六_六;四32#五4",
  },
  {
    codes: ")|.七;",
    descriptions: "7一40)}/2八三, *六* |4八^7五四ı 7@三4/九5\\7/ &? |*[十六九三ı6 四.五(2@一5)八 (ö]*9 1&七5 四}/ş4)",
  },
  {
    codes: "  *0四六._5",
    descriptions: "1|五2=\\/ [)九? 八],ç|. )[)\"35'3&ş, 七ı#*八\"ış, }<1^%+=ış 7\"四? |{>56四 _\\\\?九二ş",
  },
  {
    codes: "    \\'一七.5四.&@.三].?七",
    descriptions: "二_八ç79 #6七#;五?+|ş, ?ı58_9ış, ]二十八619ış ;){: <][一=/ '2$&45ş ',(八44六#_十",
  },
  {
    codes: "    6/$0.+..七?.6一.|%",
    descriptions: "2;ğ6=#\\6五",
  },
  {
    codes: "    五六八/.)<.\\|.二%.九五",
    descriptions: "&ü=*[: 2<]@ 四_59 1九_+{十 八ı:四$8ı六四\\7",
  },
  {
    codes: "    /\\^(.九1.#].七?.^[",
    descriptions: "9十\"|五>3<",
  },
  {
    codes: "    九)7\".'].={.4|.;=",
    descriptions: "\"&/*3*9九 4|^;8>_^四",
  },
  {
    codes: "    %=)六.;十.9{.\\@.八四",
    descriptions: "&{>二&8)一 '1七833ş四, /五ğ:{ }_'];四=四(;8",
  },
  {
    codes: "().^,",
    descriptions: "九9@八十 @ş>\"$ı十ı[ 四七ğ一, 三四%@?/二)ı (ö}?九 +8;= >?%ş5) ; :@0八3 1ş一[五ı^ı\" &十)八 十/';=",
  },
  {
    codes: "  [二一十.+六.@一.<1.9一",
    descriptions: "Ş,)<#}, {ş.四7^[\", ,三_七* 6六八ı)五*ı, \"五ş)六:,[[, +五ç\"}6六, 4.#[二^7} 二十",
  },
  {
    codes: "  =1;0.*6",
    descriptions: "#6ğ$. ?/2/\"){八",
  },
  {
    codes: "      +\\44..;._{.=/.4一",
    descriptions: ")8六{=,[8@",
  },
  {
    codes: "      96六*.5..1八.?+.[=",
    descriptions: "#:ğ[六>八*八",
  },
  {
    codes: "    \\;;(.9..七;.八8.^^",
    descriptions: "\\0ğ5*6\\,*",
  },
  {
    codes: "  [}#=.,}.@九.二\".十&",
    descriptions: "四十八ç四#\"三",
  },
  {
    codes: "+'.\">",
    descriptions: "0;ğ'九五 二九 <六6三}九",
  },
  {
    codes: "    ?*2四./*.七%.2五.1@",
    descriptions: "\\六#)\\\\'九",
  },
  {
    codes: "    1;\"五.3;.一1._5.五6",
    descriptions: "[.ğ7九9四六5",
  },
  {
    codes: "    2\\二+.,{",
    descriptions: "3237|五.[",
  },
  {
    codes: "      ^7八一.4%.|0.+1.=$",
    descriptions: "\"<3*ı;| (九ı九ı二) %6ğ?)'",
  },
  {
    codes: "        1)|\".0七.%0.^4._+",
    descriptions: "#.= 三三ğ#**",
  },
  {
    codes: "        {0\"2.&7.6五.5/.九=",
    descriptions: "五0四八^七",
  },
  {
    codes: "      三)/[.7十.+'.二[.&_",
    descriptions: "$4ğ#(*7四5",
  },
  {
    codes: "      )十*7.&>.,十.}..5=",
    descriptions: "二?3 \\2ğ*4@",
  },
  {
    codes: "      }9(}.%|.=;.#,.八+",
    descriptions: "$二'@'/",
  },
  {
    codes: "      八$>六.$].四/.1_.(:",
    descriptions: "'6九&ı五七 (五ı$ı七) +5ğ六75",
  },
  {
    codes: "\\\\.*|",
    descriptions: "Ç5三5)1",
  },
  {
    codes: "  *三=>.\"6.一一.@六.七5",
    descriptions: "十^5><一%二",
  },
  {
    codes: "  2><2.9).94.<,...",
    descriptions: ",0ğ十=八\"%2",
  },
  {
    codes: "(4.二八",
    descriptions: ")八]%",
  },
  {
    codes: "  五,_五./六",
    descriptions: "*>.七1四*]",
  },
  {
    codes: "    8%0+.7%.0{.,六.+九",
    descriptions: "0_九十4 }[5_",
  },
  {
    codes: "    >,=%.^\\.^九.{3.*+",
    descriptions: "九6ğ五4*'\\二",
  },
  {
    codes: "  |6<%.;2",
    descriptions: "四*ğ?>(>99",
  },
  {
    codes: "    十二$<.@9.&$.,6.九,",
    descriptions: "八9*0* *'4>",
  },
  {
    codes: "    \"@@*.%..\\].#九.;一",
    descriptions: "3八7<5ı: 九;十@",
  },
  {
    codes: "    二&27._<.&6.\"三.<四",
    descriptions: "|2ğ一@\"{9;",
  },
  {
    codes: "]<.47",
    descriptions: "{$6[_",
  },
  {
    codes: "  [四^_.+?.&八.*5.1七",
    descriptions: "十(五%'.5九",
  },
  {
    codes: "  97\\%.四_.}{.'\\.\\)",
    descriptions: "3'ğ^|九2@:",
  },
  {
    codes: "七3.2;",
    descriptions: "五ı2ı?",
  },
  {
    codes: "  6382.'|",
    descriptions: "二*0}?[[4",
  },
  {
    codes: "      (..<.\"4.9#.;九./>",
    descriptions: "Üç*ü .)^$\\",
  },
  {
    codes: "      >@一八.\\].)九.三&.)}",
    descriptions: "?\\0 9,八7]",
  },
  {
    codes: "      :三;(.6五.01.'#.]#",
    descriptions: "{'ğ\\<@1_8",
  },
  {
    codes: "    二*十..$5.;|./9.)\\",
    descriptions: "3>ğ}^ 三,;6+*2'2@五",
  },
  {
    codes: "  {4|:.九五",
    descriptions: "2,ğ;6,0*四 ",
  },
  {
    codes: "      /(&1.[,.九_.#/.四*",
    descriptions: "#[2 6ı五ı|ı (.}{72(ış 五ı>ı_[6+ @,3四 2&0ı@ı:$[ %2<0)#ı2(四 }ı{ı七)",
  },
  {
    codes: "    八}3$.四[.二>.),.;2",
    descriptions: "^一ğ<[+六三(",
  },
  {
    codes: "八*.)>",
    descriptions: "@?/\"\"ç",
  },
  {
    codes: "  \"$=\\.2九",
    descriptions: ";0七|. 0ç(一[1 :\\3八][@ 四&.八4ç (ç\"{&7#)",
  },
  {
    codes: "    &1{&.0:.0).??.五}",
    descriptions: "+0{九=;三十 @=}5&$六",
  },
  {
    codes: "      四,}*.^>.\":._\".九>",
    descriptions: ")8@%(十|> 九3600+",
  },
  {
    codes: "      九六四五.{_.=].\"..四9",
    descriptions: "?#3. /}$4五)",
  },
  {
    codes: "        [六7:.\\一.^#.3<.一,",
    descriptions: "*一{七$/十/|;,3ş\\&二 .五<六ı )'$二% 'ü@ü; 七^十六, \"'一一+ .üçü七 |,1):五|",
  },
  {
    codes: "        0,六9.九一.0&.7二.#@",
    descriptions: "($]一};{/4._{ş}?八 39[<ı :'六 \\ş&( .3'$ #ü.ü\\ 4}*;二一}",
  },
  {
    codes: "      #0(*.2?.<>.;\\.九$",
    descriptions: "五6ğ2#五_/=",
  },
  {
    codes: "  )5?#.五$",
    descriptions: "6#六?1} çı&(七ı1|ış (+五.十\"{6十\"一) ?{%一<ç",
  },
  {
    codes: "      :@{2.四_.5三./八.#<",
    descriptions: "|^2||七9} %'|$四9",
  },
  {
    codes: "      )4九..十].'8.+].2'",
    descriptions: "/[5五 三+五*];",
  },
  {
    codes: "        {={(.=7.$8.\"[.?七",
    descriptions: "八+./8['/6$)6ş九@* 0}=>ı +'$七/ @ü四ü@ 64'8^ \"'九$* 2üçü. %2四一@|>",
  },
  {
    codes: "        十8七;.,%.3:.<7.={",
    descriptions: ":.九,#四\"/<'五?ş二七) 4.八|ı 二ş七| 3)1( 一')\\3 ;ü|ü] 0\\{/9二[",
  },
  {
    codes: "      八;<六.}\".'<.,五.)/",
    descriptions: ":9ğ/^:556",
  },
  {
    codes: "      1=]9.}7.二:.=@./5",
    descriptions: ".三;<*@:+ ={{<5*",
  },
  {
    codes: "      (]\\九.\\8.&4.六:.41",
    descriptions: ":/{{ 七一一@\\六",
  },
  {
    codes: "        四2'3.(+.四,.{四.3二",
    descriptions: "6)2*7<#/3五<;ş八七4 ]{\\十ı 三'1)/ )ü@ü\\ #+])二 8'::/ 9üçü四 *#1十四/[",
  },
  {
    codes: "        :|九六.4十.%).一3.八三",
    descriptions: ":8@4*85/5%>8ş7(. 五>{七ı |ş五0 九4\"# ='<]二 七ü五ü\" 3\"#_五十.",
  },
  {
    codes: "      8^|..]*.@=.,}.十(",
    descriptions: "|=ğ$&+\"1&",
  },
  {
    codes: "    >/4#.3<",
    descriptions: "@/%ı 一八@@ 8十五 '五&四&2 *=ğ<3%十三#/一 二+ç<二)|八一ş 三/^6三ç ([,}*八\"ı六",
  },
  {
    codes: "        44#=.\\_.[^.>一.+%",
    descriptions: "=$*五)?5+ )一三=五9",
  },
  {
    codes: "        +00九.十{.九,.#&.\"[",
    descriptions: "%91% 299五>九",
  },
  {
    codes: "          [8#]./=.3;.&9.59",
    descriptions: "'}三>_.+/四%67ş四<9 八2九=ı &'6[\\ >ü四ü6 1^五.? 3'+}六 ]üçü十 9^}>二{四",
  },
  {
    codes: "          :$*;.;:.9$.$:.7/",
    descriptions: "9:五;0[七/#({.ş_9# #{}_ı 三ş@2 @九.( }'+二0 2ü}ü; 0^@六)五五",
  },
  {
    codes: "        7[$三.1十.2七.@三.%;",
    descriptions: "五[ğ@&\\###",
  },
  {
    codes: "        )'$九.>4.六\".四+.)四",
    descriptions: "4;++=.*' 一\\五^'|",
  },
  {
    codes: "        $十0九.4<..:.八|..6",
    descriptions: "2{0| ;一^01四",
  },
  {
    codes: "          [0+[.('.七&./&.18",
    descriptions: "0/_:八五二/>$|}ş8(, *1,{ı 5'三>> $ü*ü@ ?\"}\"1 ,'>三3七üçü二 *八(&@<=",
  },
  {
    codes: "          {*'{._?./%.$,.5六",
    descriptions: "__,:9八#/72=]ş6_@ (%'三ı 8ş+8 =%四* 9'%5( 二ü|ü' )2:一十七{",
  },
  {
    codes: "        *_五1.+八.一|.[\".><",
    descriptions: "七=ğ九二\"七九九",
  },
  {
    codes: "        =5:八.四9.+三.\\<.5^",
    descriptions: "6一三:1|&四 二5十八93",
  },
  {
    codes: "        .四\\;.一;.%2.2=.;三",
    descriptions: "])[4 五8+?{\\",
  },
  {
    codes: "          九]{'.\"6.\"2.)1.*\\",
    descriptions: "=_;?\\三1/#四一一ş五5) .;?+ı ,'?十_ =ü四ü二 7九((' |';=; 2üçü% <2.$>_九",
  },
  {
    codes: "          8}.[.三(.8].一'.八七",
    descriptions: "二[三二=五七/:#四一ş|+{ 十{/,ı \\ş6& 0一5| <')六: 一ü}ü? 7五*0,6+",
  },
  {
    codes: "        (?]*.=;.)@.九六.十0",
    descriptions: "0一ğ%9四8&=",
  },
  {
    codes: "        4.'8.七二.^).二_.}1",
    descriptions: "十/(#一一'6 4)?)'5",
  },
  {
    codes: "        六%42.}^.{:.四\".九/",
    descriptions: "|#十} %0三+*八",
  },
  {
    codes: "          3>&五.%八.\\八.:&.0/",
    descriptions: "一(+3\"@6/7?6@ş9@] #[六$ı *'};+ 五ü\"ü[ 9]9/九 5':@# &üçü一 九,.<三0五",
  },
  {
    codes: "          :^/{.0[.?\"._].8%",
    descriptions: "4;;$.九;//七{,ş[:> &9.3ı 2ş?[ ?3八十 0'&三@ 1ü十ü0 _:五三>1{",
  },
  {
    codes: "        三6)一.,5..八.0|.&七",
    descriptions: "4五ğ五四9:^^",
  },
  {
    codes: "  ,68*.三1./6.*&.#{",
    descriptions: ".ı|ı\" :<五%\"ç>{\\",
  },
  {
    codes: "\\'.<3",
    descriptions: "$九9} |^三ı (&=({} $$.^9八])",
  },
  {
    codes: "  (+7%.\\@",
    descriptions: "三三\\4.:\";",
  },
  {
    codes: "    4五.).九九.*七.:四.九6",
    descriptions: "[)五3{6)五",
  },
  {
    codes: "    7\\?;.五?./^.六;.\\.",
    descriptions: "九+ğ9$五%,#",
  },
  {
    codes: "  /'三九....];.0^.\"_",
    descriptions: "八六ğ二八六三_>",
  },
  {
    codes: "6*.<八",
    descriptions: "[8八$ 0$ğ]:6, ?:二ı (]4' [, 七08 }](ı), _?ş 9'1(; 9八ğ5) ;8$&);}",
  },
  {
    codes: "  )8&..*^.三,.\"8.\"二",
    descriptions: "][}< 一/ğ2_三",
  },
  {
    codes: "    \"7二二._=",
    descriptions: "三<二九/,一+",
  },
  {
    codes: "      |9;七.,9.]+.}四.<:",
    descriptions: "五八>ı",
  },
  {
    codes: "      9&十1.%八.6;.\"{.&4",
    descriptions: ".27\"?ı",
  },
  {
    codes: "      \"=^).&&.;5.4六..\"",
    descriptions: "^*}ı",
  },
  {
    codes: "      8<二>.九{._八.五..七.",
    descriptions: ":六#(&ı",
  },
  {
    codes: "  9<&/.4_.]\\.'?.九九",
    descriptions: "#6ş 二3/6",
  },
  {
    codes: "  七\\:一.#5.={.:六.4.",
    descriptions: "Ç=5九> 七25 (*+'九二) (7()十?7+\\十 /;十.)",
  },
  {
    codes: "  \"%;_.\"&.[五.二五.\"$",
    descriptions: "<三_一2 (二8237十6|)1( 9[%880)",
  },
  {
    codes: "  六}>7.1十",
    descriptions: "9^ğ(1: 2七 ç7.]>一 ++[\\九$",
  },
  {
    codes: "    :\\#*.+一.五十.+6.'.",
    descriptions: "6>;|二7.4",
  },
  {
    codes: "    4]>十.0^.=二.*=.'=",
    descriptions: "*,ğ}+3九三\"",
  },
  {
    codes: "  #@八2.十(.)五.3$.6\\",
    descriptions: "_九ğ'3 /}五&?%'",
  },
  {
    codes: "8>+$.9=.@4.)#.:0",
    descriptions: "İ[$;% ='çı ('ş1.[#/五/ş, _ı五七7十ış #|>: 2+ğı |:ı2七ış &5|.十",
  },
  {
    codes: "|/.二]",
    descriptions: "><>4一 ./68七 五7;. 7)四?1 十6一5'八 :ı37>]ı; '=九?5& 八ı763)ı",
  },
  {
    codes: "  1=,'.七9",
    descriptions: "二@{6[ 五十>.{ 57$% +1=.| 02四%八\\ ?ı4四{<ı @5 +}*}1九ı% 九ö5ü+\\ü九七五{",
  },
  {
    codes: "    |9?;.四6.\"二.九+.+|",
    descriptions: "23#8\" [ı_ı",
  },
  {
    codes: "    8一九3.1).{*.8+.>九",
    descriptions: "^/ğ{}<1六%",
  },
  {
    codes: "  五五9*.'7.)=.1三.一=",
    descriptions: "二;ğ#:>5一三",
  },
  {
    codes: "五八.六\"",
    descriptions: "",
  },
  {
    codes: "+6|..;2",
    descriptions: "五7} %|七二 .@八ç@ 十#+=]63 ,十=6五1 <8ğı#?+/{六七ı, $36:>%+{八6 )1",
  },
  {
    codes: "    47+^./9.十1.\\=.(2",
    descriptions: ">$&2 +三)五ğı ş4二}四[@",
  },
  {
    codes: "    二{}八.6|.&:.4%.五[",
    descriptions: "8九ğ,8@&七二",
  },
  {
    codes: "    64>九.九:.04.[八.四6 ",
    descriptions: "九5ğı*九\\八'&{",
  },
  {
    codes: "    二&[>.2<.]二.九&.,二",
    descriptions: "=*(>#[.({)ış ş;{_{54,^",
  },
  {
    codes: "    8{;|.0|.=).,五.6四",
    descriptions: "#一ğ{#5*$[",
  },
  {
    codes: "#@.*二",
    descriptions: "/8ş+|七ı? *ü=<ü ##'< 3%7& *ü.十ü ?(六* 二5 ):ğ=' +ı,ı#3十|ı, ]ü#6#七",
  },
  {
    codes: "  }5>九.)\"",
    descriptions: "&六.2%一三75_ 二3#6七%ı#四_ {ü&;|7; @\"}+ 4ü3八十3",
  },
  {
    codes: "    七:4{.]$.五?._&.十3",
    descriptions: "İş}@;四4]五ş",
  },
  {
    codes: "    +:\\三.%四.七0.5&.五(",
    descriptions: "%8ğ&十/'(十",
  },
  {
    codes: "  \\7{7._@.,(.\"\".*=",
    descriptions: "0十ğ>九四.6:",
  },
  {
    codes: "</.:七",
    descriptions: "|五二;七7\\/ 1> ]七8'|@ ^ç四 9)八_#'|一: (]ş*=$8%八]ş, 三4ğı 3一ı#*ış,",
  },
  {
    codes: "  <八',.(七.*=.3[.[>",
    descriptions: "3}八=|五 七ş\"八, \"ö^7üş ^^7@一 六0 五ı2ı<\"/[6{(",
  },
  {
    codes: "  [四1+..@.三'.'8.6*",
    descriptions: "&8ğ八二)十|九",
  },
  {
    codes: "2<.十二",
    descriptions: "+*3*6ş., 六]<六四)=?ğ六 '$[一ğ(, 765?2八 >4ş; 2* #九%8#> 4$ş/^)[",
  },
  {
    codes: "  15|六.)^.;2.}[.七0",
    descriptions: "七六@1*ş< ; >%11<ş@ 六\"71 ]九 二ö5ü^*ü@#四^",
  },
  {
    codes: "  ?<(>.|2",
    descriptions: "3<ğ%#十**五",
  },
  {
    codes: "    9五9#.%{.98.=].三]",
    descriptions: "//一\"六5;8)",
  },
  {
    codes: "    [;'+.一(./}.;=.9'",
    descriptions: "]ı1]9八\\#}",
  },
  {
    codes: "    #一\\1.九\\.35.>7.55",
    descriptions: "6八ğ十%九>一/",
  },
  {
    codes: "/二十9.七&",
    descriptions: "42&+<[ ?= 9\\*9(+' _十_7\"二.@(二ş@[二八(_5ş 31*> 一|.1[ 4六% ş14^>五八",
  },
  {
    codes: "  01十_.4九.(十.0}.二/",
    descriptions: ">ı40ı/ı *]八二'七 (/八]9^二)九% \\1.^6五)",
  },
  {
    codes: "  .5九>.|\".}+.\"十.十六",
    descriptions: "六'ğ一九/^$0",
  },
  {
    codes: ";|.&.",
    descriptions: "",
  },
  {
    codes: "$二四一.;&",
    descriptions: "\"九六'三4_, (二^;十< 一>十;}ı (=9九二#&八3)), 十1{七 ?六7[* ([一三二\") 七* 一1.>;",
  },
  {
    codes: "  2>)六.1).=三.[2.3'",
    descriptions: "五六四=8$>三",
  },
  {
    codes: " ",
    descriptions: " - 七一ğ7九十6\\&",
  },
  {
    codes: "    2九0二.#2.%).42.三>",
    descriptions: ".一八五0 (5]8六_+七(}ş |>(二二 43}$四ı3 )",
  },
  {
    codes: "    三3[九.]1.*5.}$.四>",
    descriptions: "[{ğ&十@八1#",
  },
  {
    codes: "  \\九.八五",
    descriptions: ":>??/*'七} 五%ş.# #'十5&]& [../三]_(;3\\?, \\:三% ]:' 5五83@5& ](二=$7",
  },
  {
    codes: "  ,[}6.14.十|.<&.;;",
    descriptions: "|ığı[ \"四=[@@七6'",
  },
  {
    codes: "    (一*一.?/",
    descriptions: "一\\^ı9,一\\22+1) 四8;{九8,90<%ş1'ç一 +\\一@ 四>ğ2一 @?",
  },
  {
    codes: "      '\"]%./三.二\\.';.:&",
    descriptions: "%91ı< &ö@ü\"}ü2十|一",
  },
  {
    codes: "        |四_[.]九.96.十八.+?",
    descriptions: "56(]>*ı: >1\"3五九六四ı",
  },
  {
    codes: "        2*+&.$}.;7.?[.*\"",
    descriptions: "<3ğ十)/<三=",
  },
  {
    codes: "    3)五(.#七",
    descriptions: "/}ğ*3*>@}",
  },
  {
    codes: "      |\"#5.,0.一1.83.1,",
    descriptions: "[\"\\$<八/; $# 9>*)#2/7|; 七1.|\\\\4七六%四ış (=\"6@十[\"[ 五ı\\五ı1_ı ||",
  },
  {
    codes: "        <_0<.'^.=1.@1.|/",
    descriptions: "İş,?[6889ş",
  },
  {
    codes: "        四$#9.+4.<:.49.40",
    descriptions: "1#ğ%{@0<六",
  },
  {
    codes: "        _.0{.3<.@5.$@.90",
    descriptions: "İ<^+ )ö@5ğ) 8{二]八|一四#>ı",
  },
  {
    codes: "        0':$.八九.{;.^<.@六",
    descriptions: "_九 'ı四ı \"+ 七> .ı>ı *ö八ü$%ü八)1| (3二ş\\,  (]}+&7_7:| ]97九ı4ı|{+ 71[[",
  },
  {
    codes: "          二三2+.4?./%.00.#*",
    descriptions: "七.一7六[ 5七?ı (,$?八0?\\3)ş :一$/{ 三@}=?ı三)",
  },
  {
    codes: "          ;%五].^6.九?.\"(.;2",
    descriptions: "*ı五|ı+ =ö?5ğ5 ('<%七5:?>.) 七3 九5*(五<; 一ö二]?)'=",
  },
  {
    codes: "          +@\"八.93.{*.=#.1=",
    descriptions: "}<.9*[ @04> &>@ı二ı2九九 2)三%.三ı}%3:. 八四675>^7七*",
  },
  {
    codes: "          \\2,/.?].*六.5三.#三",
    descriptions: "二[ğ29+*&{",
  },
  {
    codes: "=八.,}",
    descriptions: "?一四811 五6}(=#(?&$# (5$: ş|('.&十四,{)",
  },
  {
    codes: "  %九(\\.1:",
    descriptions: "Ö十4ü\\ \\ü四+八\"7 /,二二'0+: &? &一七? 7@五(8三<五#8",
  },
  {
    codes: "    =6|:.)7.十..七;.=,",
    descriptions: "02_36) 六$五2^四|1%8",
  },
  {
    codes: "      十四六六.\\{.&_.)+.)8",
    descriptions: "\"3ç>1 一@ğ\"\"|]{ ]=\\^?5.九\\二",
  },
  {
    codes: "      )三${.8'.五].]>.5九",
    descriptions: "^.13[五5%二二 .\"十|三3=$,%>ı",
  },
  {
    codes: "      |5}&.2,.@4.7,.?,",
    descriptions: "五<ğ=+&\"$五",
  },
  {
    codes: "  十+/{.^八",
    descriptions: "Ö1<ü| @ü@?%五8 *,,( 6.%9 ^4:= 六2三<. 十二}八 \"|.八2@?9四3",
  },
  {
    codes: "    \\(1九.|_.\"5.七6.2^",
    descriptions: "[/%^=:;>@) (五_ 二'%=ı7 % (6) 四5:%五3* 1|4六23?,:三 ş$\\3(十{2%\\@ 5\"7/?%6, 七?°9'九七",
  },
  {
    codes: " ",
    descriptions: " - - >2ğ4:(#/1",
  },
  {
    codes: "      <,2<./>.#,.九;.四=",
    descriptions: "九ü一4#\\ 8^ğ>5,'& .一7^7|%}@<",
  },
  {
    codes: "      ?八>五.'^.?6.|..5'",
    descriptions: "1;一>'\"^\"70 99$+\\*3十,%#ı",
  },
  {
    codes: "      \"37(.83.{\".一7.6;",
    descriptions: "一.ğ_>7++?",
  },
  {
    codes: "    /]'&.(#.?:.\"6.:{",
    descriptions: "八六1#54}/(:2 ]9.{>: 五;%>)7}0五$+>,",
  },
  {
    codes: "      {{@\".*_.?/.47.5.",
    descriptions: "=\".五%8$:/+九($*;< <>,]:(%2+十四五八, ö六四ü^ <ü179;\" *,二)’%@_ :8 3[9>3$,",
  },
  {
    codes: "  ./}:.十<",
    descriptions: "[9ğ&@:'七*",
  },
  {
    codes: "    一八一3._1.*<.(3.&二",
    descriptions: "七?*八29三9 +0[三)2|_一, 8>8一 '三5 _2一四ü()四 ':四三+三二 {< ;=*)二=四?># \"+九> 二ç21三> {4八>];8\" 4九ç8[@",
  },
  {
    codes: "      \\六}(.{=.三$.十四.\"九",
    descriptions: "八7'_#5 [/八[_/(< \"六5}37十#九一",
  },
  {
    codes: "      六&(2.2^._六.*0.九十",
    descriptions: ",\"%,:ü\\|#<6<{ış ?%ç.} \\2ğ9,[$7 六%+^.:九九六} (:八@@九0'?{\\% \"}=>0)",
  },
  {
    codes: "      $9八@.{4.(二.0[.1|",
    descriptions: "九&ğ三:<三,;",
  },
  {
    codes: "七9.[二",
    descriptions: "七二六四\"{九+ ,$ ,?ğ_* \"$[五.1三一/十九 \\5五&[<<\\,?} (9<9 ş$]>3+%五三0)",
  },
  {
    codes: "  6^_五.:}",
    descriptions: "%&@七=6三_^8?}",
  },
  {
    codes: "    '六${.五五.4二./7.七)",
    descriptions: "0{$十8}5$#六,6 九^<二]/)二二:\\ı",
  },
  {
    codes: "    <+0一.一\\.九六.?五.二6",
    descriptions: "7)ğ,:}%五一",
  },
  {
    codes: "  9,0:.6^.]三.二:..十",
    descriptions: "^_=)._2\\@'.+{(",
  },
  {
    codes: "  >}}*.70",
    descriptions: "十十'五{七/七 2四二[^五五%]+$%=",
  },
  {
    codes: "    >\"七四.'7.4[.3{.二四",
    descriptions: "+:{(;七\\;@32: 8八.四.<$.五@十十| ^6九*8,*7四>|ı",
  },
  {
    codes: "    489@.*六.\\6.二1.2+",
    descriptions: "8<ğ=8\"0=+",
  },
  {
    codes: "  %43,.=|",
    descriptions: "#/ğ+八^0[<",
  },
  {
    codes: "          9八1:.{;._十.$/.}^",
    descriptions: "7$'7\"8(\"三八':{ ]7七 .四8= :/一_7二#^;#, (]\\6六#%]8[*6% 二一四@}%1>_ =五 #*/)五3?:>\\",
  },
  {
    codes: "            };_^.>一.$5.三$.+@",
    descriptions: "}*/&4'+ # \\7四 ($%]46}% +6. >ğı$,ı{ 十]八(一<]|?@ % 一_ %%'6 7三08 七@ #;59?六",
  },
  {
    codes: "    四*3八.#'.[五.八@.$7",
    descriptions: "*7ğ^六5?|_",
  },
  {
    codes: ":<.]=",
    descriptions: "#一=5/[ 九\"三.)_186一三 (一\"九 ş'七5=6八七.})",
  },
  {
    codes: "    '?#/.+3.=+.四?.]^",
    descriptions: "*3>十6ş?5三=_%",
  },
  {
    codes: "    )2^五.3十.85.>0.'&",
    descriptions: "|九ğ05920\\",
  },
  {
    codes: "    @3二{.四\\.5).十].\\九",
    descriptions: "|九$(<;/)五^(=0.^,&一 (四1$) @(七5_'=四九+?;%",
  },
  {
    codes: "      5{\"^.{十.[九.七*.[7",
    descriptions: "1,九?}5_五22,五2=三九一_{2<%,\\14 (?:%) (^#6一$2?9|\"7.",
  },
  {
    codes: "  @66@.,/",
    descriptions: ";=ğ]'+@@/",
  },
  {
    codes: "    3;四7.)十.4七.九九.).",
    descriptions: "*8]<\"8 [;82三七 四六 一5.# 五^6,.ü] ?#73三>@@(+ (%4:5&< 4一ğ五五二 95* 十17^ (,^四",
  },
  {
    codes: "    \\+:2.}@.)+.*;.08",
    descriptions: ",/57\\二%四ış :六7{>五'26= ('ğı<六ı< 六十二3五,七.$& % #[ 七4^* &{0< 二[/:% .9>八八",
  },
  {
    codes: "    &五九{.六}.'#._}.#八",
    descriptions: "四+ğ十\\11)0",
  },
  {
    codes: "十九.7;",
    descriptions: "一=&^: <93>ü四 %七.五 \\一ğ*3 8:]_六}七4六#/=ş <:096\\>三八40 9四&{770@67*",
  },
  {
    codes: "      (5':.\\二.',.6五.($",
    descriptions: "@+ü388[5$ _;>{(=0{五四 52三:ü9) (4891)",
  },
  {
    codes: "    六4)9.7九.2}.'9.八&",
    descriptions: "&'ğ八九0六9?",
  },
  {
    codes: "    #(#}.|/.^八./%.\\@",
    descriptions: "6/?;8&&50\" </\"]@3十五ş 6.七",
  },
  {
    codes: "    /3^_.}六.二}.'7.36",
    descriptions: "一六;九_9'八]8 4八1]五四ş 六;>",
  },
  {
    codes: "    二&{=.;+.3九..+.43",
    descriptions: "':*]+ +{2<ü68}@*, %.4二:四 五^385,=四八*六一;",
  },
  {
    codes: "  \\394.[|.四三.6|.=(",
    descriptions: "三9ğ7五 *4二>7 \\<0|ü% $八71一\".七}\\&2.",
  },
  {
    codes: "  6*十?.@一",
    descriptions: "#六[*+{%|= &0[5ü= ;@0七08'(三<%",
  },
  {
    codes: "    .>#1._;.2<.三五.}=",
    descriptions: "}3=四二{|@> 七].7ü>五} :3]六四|[1十;,六 六四4.&九{'$7 (ç:#ı 8 2八九3>_/53六 8$>+ 一+十\\",
  },
  {
    codes: "    [%五四.三].三'.四<.]%",
    descriptions: "+2ğ8:_八*[",
  },
  {
    codes: "    二?7$.?^.)5.5:.<[",
    descriptions: "80\"$7六&@\"五'二'九38[八= (一\">=)",
  },
  {
    codes: "    三[十8._]",
    descriptions: "])ğ3七|_4八",
  },
  {
    codes: "      五@?..)_._五.'四.$7",
    descriptions: "2十&&1(7\\: 八$+'ü一 (:3 9一九ı=ı四 9 (,) $^十3{八} :,1}6*三+四9 ş[7七3@11二4五 =,[1#|)",
  },
  {
    codes: "      49(\\.:{.>+.七8..+",
    descriptions: "(十^?#$[|6:)=9@十 +九四",
  },
  {
    codes: "      4{八\".2..4).^十.56",
    descriptions: "六7ğ4$9)%/",
  },
  {
    codes: "  :$\\'.:%.:5.7{.:]",
    descriptions: "七^ğ*二^8,5",
  },
  {
    codes: "<5.三}",
    descriptions: ".六9_# './六=? >]八& {3ğ?& 25(\\九 +<[三''七4一0四五 :4\"'7$四%六/+ (=$^ ş二0二1'5*]+ );",
  },
  {
    codes: "    )#三/.|_.1].{九.二七",
    descriptions: "^;7/ .二.七*1&[)6} ,\\{]4<7",
  },
  {
    codes: "    76八5.8\\.29.8@.3.",
    descriptions: "')ğ?6六&六_",
  },
  {
    codes: "    )|'六.#1.'\\.@四.|三",
    descriptions: "=||2 \\(3\\*?五?8)6 }=#_,:五",
  },
  {
    codes: "    十一]7.二*.*6.@).6一",
    descriptions: "九\"ğ:五21六6",
  },
  {
    codes: "  '?八#.44.[4.68.2^",
    descriptions: "|$六5(&二)\\# >_{一2) (九五1>:\"#四3 ]<>?八ış 一七:2}< ]五四+]8+ı 0ç四[)十4 8ç:|]#(\\))",
  },
  {
    codes: "    %#^3.[5.{九.六>.)^",
    descriptions: "0'.三七|:6一>九}",
  },
  {
    codes: "    )7[一.二[",
    descriptions: "五二ğ6六四995",
  },
  {
    codes: "      [:七6.|4.$?.[九.|1",
    descriptions: "/;?| (338:\\ \"51九一&) (@七 八:0ı>ı( 一 (|) ,六?&&{} 65$)*}\\八44 ş\\]5%十二?7\"' 8(?:十'?",
  },
  {
    codes: "      |2)六.九四.7三.7六.6\\",
    descriptions: "7<ğ:\\^)5四",
  },
  {
    codes: "(%.7^",
    descriptions: "6#3^:&6 6>42@+<28二 (@#[ ş;二>8%#[&|)",
  },
  {
    codes: "  >二七3.%@./%.六八./7",
    descriptions: "2&/#(2}7<\\ 64%{@|):,:)",
  },
  {
    codes: "  @+'\"./#",
    descriptions: "$,ğ+@66_@",
  },
  {
    codes: "            1+%>.?[.>八.&*.1八",
    descriptions: "=|八三 [_ ($ |^\"九>5五七五\"'#, %,/ 七14:@四337#70) 3_一三三\\>6四]",
  },
  {
    codes: "      %)@?.六=.8_.08.$@",
    descriptions: "0\\>,8;=7{八十#{;2五2:]5, ==1二> ?)九(4$四 5>= =#?[# *?\"{4 <26九七5$ı0 二八7二;[9_,=\"68",
  },
  {
    codes: "      一@}].4_.二].五<.四4",
    descriptions: "\\]3=五@< 70>' 6二6 }||,<六^?$<% 9_+#七0> 0]/&2^;十|一 ($ğı$_ı. 八0&@十四>九六| % /三 0五[7",
  },
  {
    codes: "      三\\十}.4/.3}.{6.*4",
    descriptions: "五一93?2@[=二9* @?\\ *%9(0 |^七\\[4: 一,';*4}一94 [>|32|六}}>9三<..?;<&3=@60+ (8]#)",
  },
  {
    codes: "    8_['./&.{@.,).^/",
    descriptions: "=8*\"'&四 )_&+ı @<7_}九ı'ı[ &]三6*2,7 一ı:}; 4六五九(, $>+>*\\ =446三=ı>\\{9@",
  },
  {
    codes: "    ;'七九.十#.$十.{5._3",
    descriptions: ";ğı_<ı3 .八_$三:^2$十 % ]. ]4四> :]#二 十|八$* |;957 [_$_=二{ >ç%;三: (2六4<_$ @ç(一^:>",
  },
  {
    codes: "    8二$2.+*.9|..>.*4",
    descriptions: "2>ğ^(三三三七",
  },
  {
    codes: "#十.=?",
    descriptions: "}9319.9_三[^0&, &;ğ六@ ,4七4.5九%54) 7, ]_9\\八六 六?ç=六7$:六 (十.) ş五40]+9/0]);",
  },
  {
    codes: "  ,%3一.&五.2}.|\\.{二",
    descriptions: "$;*'%九(七#\"三@}",
  },
  {
    codes: "    ?六>].2^.],.]8.^'",
    descriptions: "8@8(+/{九1一20>@四三+<) 二七四|+\\39'/5九五",
  },
  {
    codes: "    4+\\0.8_",
    descriptions: "五九ğ>|八|95",
  },
  {
    codes: "        一\"?十.]八.(三.十2.6@",
    descriptions: "}2;*二%4>_? <2一0@>九二0",
  },
  {
    codes: "        }%2,.[\".&4.八一.$@",
    descriptions: ",_ğ$十?*.3",
  },
  {
    codes: " ",
    descriptions: " - - - \\4ğ]五(.|/",
  },
  {
    codes: "              =$二;.三@.??.'/.,六",
    descriptions: "0?&\"二四<,_@&<七.7,八[7{9 @}4 ,0$>#* ;二6#> \"四|>(|^.5@",
  },
  {
    codes: "        八5:十.*五.八,.{四.\"[",
    descriptions: "1%ğ%八8]三%",
  },
  {
    codes: "  092]./七.五9.八,.,六",
    descriptions: "|$七'}$ *9ç六\\一七\"|",
  },
  {
    codes: "  三八{5.>六.(二.1%.八七",
    descriptions: "6三{五%七_#六#@#3/;",
  },
  {
    codes: "  _}@$.\\*.0六.%2.二+",
    descriptions: "711十= $'ç=^+5}一",
  },
  {
    codes: "    二>25.\\,.7?.??.=[",
    descriptions: "=+ \\一/@ @42? 三>_{ =一二\\( .^1%<二四5$五3 4]@0四*{",
  },
  {
    codes: "    8<@四.}(.%一.01.三?",
    descriptions: "3<ğ0}四十\\*",
  },
  {
    codes: "  五'六:.0十.二[.%二..#",
    descriptions: "/5_}(*3|0一@ 5二_<)",
  },
  {
    codes: "    ^%九\\.*四",
    descriptions: "3_8)七+ış四一十",
  },
  {
    codes: "      0一=\\.^}.[九.&:.*,",
    descriptions: ",ı十ı",
  },
  {
    codes: "      #0.+.8四.;&.%+.=九",
    descriptions: ".8ğ;^*\":$",
  },
  {
    codes: "    一八二(.\\#",
    descriptions: "<(ğ[]$;33 ",
  },
  {
    codes: "      ^@)9.%=.\";.;'.十+",
    descriptions: "8='8|\"\\,1159 .ı+ı :\"九/;}* 1\\二%五8七4 \\2$>,_五0二 ($]:/4@3\"%_七#",
  },
  {
    codes: "          )]=五.{\\.?}.二4.三}",
    descriptions: "@6*< (#950一5 |?)二3四@'$,_,)九[@,}6'\"?@六)",
  },
  {
    codes: "        ]\\9六.七\".]..'=.八十",
    descriptions: "||\"#7ş |=十\"%;=|| |)ç\\.#<\">6",
  },
  {
    codes: "        5}2#.7九.八九.(三.4%",
    descriptions: "五[ğ三**^四二",
  },
  {
    codes: ":\".0=",
    descriptions: "#;|0*+@'一\\十 (%1, ş*^四3$\"*十*)",
  },
  {
    codes: "                八39九.79",
    descriptions: "7四/一五六:| 五,  (?,  ?@,  0,_,  [,六,  =,,六 0*>;  [,八*",
  },
  {
    codes: "    1六<?.;3.2二.|+.59",
    descriptions: "|:8:五十8>四7%ı",
  },
  {
    codes: "    #5四十.6}.三6.';.=>",
    descriptions: "四[ğ一五1九|8",
  },
  {
    codes: "  .;=,.7>.?[.}[.7$",
    descriptions: "$:ğ:}%?$八",
  },
  {
    codes: "<:._[",
    descriptions: "/%9*. 0\\ç0\\>*一:, 1]:三七3| 一'ç6二4{六^ ^' 十八五一ü1%*&|六+; (十&] ş8十40&四%六/)",
  },
  {
    codes: "  /[.一.^九.六@.,四.+<",
    descriptions: "Ü'& 三<ç一];五^*+; 8%六一ü*( 九@ç?|?+五1&",
  },
  {
    codes: "  :,:?.,\".8七.2%.?二",
    descriptions: ";.九九;二9 1九ç&1<\\2九",
  },
  {
    codes: "    ]|#4.?#.$&.1{.十三",
    descriptions: ";一#四 (#十三_$8八 4;六\\> \\9272#(]_十) (一$十 五5?, +一':1,3:+ |9+)",
  },
  {
    codes: "    四二_\".十^.\"7.9四.十@",
    descriptions: "三3ğ*五一,十@",
  },
  {
    codes: "  二1@{.[<.四{.九4.5'",
    descriptions: "2十$2)*# =四ç5\\%@二=",
  },
  {
    codes: "  _二}].4_",
    descriptions: "}[|.ü\"62一7|$\\",
  },
  {
    codes: "          \\?五).二,.2二.}六.|+",
    descriptions: "三,+' (一四<6十一六6三五3四十1) .%00{七.三 六6 九,9六'4}@&*$*<\\]>%9:&6七四{< >三#四.)一64}八:",
  },
  {
    codes: "    '}六>.:;.{#._七.四6",
    descriptions: "|(ğ6|[%\\\"",
  },
  {
    codes: ":五2五.35",
    descriptions: "一九{三^|2五%$ (*,2 ş八五,5'|13七)",
  },
  {
    codes: "  8三四*.?6.54.[$._\\",
    descriptions: "{四=\".^五 24ğ8十?ı",
  },
  {
    codes: "  _{(;.:=.#[.五4.|}",
    descriptions: "0_&一%/二1/:?, $=一=8[|七', 二ü,%>@9^'7< 六% çö;*7八\"十四/",
  },
  {
    codes: "  ,2#_.)3.三%.0;.?5",
    descriptions: "五八ğ;9五@%十",
  },
  {
    codes: "  %8.二?",
    descriptions: "十二十\"#\\ ]八ç&九一_五六七, \\八($四:9九}$6/ 4{ç八5@&6二$, <{_=26=;@<%#,,",
  },
  {
    codes: "    \"一2=.)[.{_.}/.60",
    descriptions: "|0'..; /.ç'0二/$$@, }#:三|]四, |^|3) ^;1? $九二.34五>@7{) 十%ç:/$三[}],",
  },
  {
    codes: "    +1=].九#..5./&.+&",
    descriptions: "68二)(#,五3)}}8二> _%)三十六七8^(&\";)",
  },
  {
    codes: "  <(一4.{)",
    descriptions: "十/ğ1;\"=\"6",
  },
  {
    codes: " ",
    descriptions: " - - 3+\\1=]%@@7>{ #一五_ ç五{'=,五} }=)七<&\\七^九{@#^ ü)ü九三{6% ((<{8{9(]",
  },
  {
    codes: "                  7}]]./*.[*.9二.:4",
    descriptions: "2二\"' (*3}7三,十@<\"/十09+[四>'_;四:,十,一7\\;|.九]六>;,6>?'九五9:;;6八{'$:@69'3",
  },
  {
    codes: "          .2;六.]}.*7.\\8.1{",
    descriptions: "八三2* (9*}>六,}\\\\\"_}:))",
  },
  {
    codes: "      .8$*.}:.四7.(八.十6",
    descriptions: "?三ğ_].=)?",
  },
  {
    codes: " ",
    descriptions: " - - \"(ğ=1六156",
  },
  {
    codes: "          :=<,.五4.*@.8十.**",
    descriptions: "5|/十8三( ]五 :[二_4,三 五*_&>? 9{}9'{;四6]##( 5,*\"{{%8九7八4\"]五<@7'=[",
  },
  {
    codes: "      [#41.+五.'(.1(.+?",
    descriptions: "@十ğ93]]九8",
  },
  {
    codes: "  .=.2)",
    descriptions: "一80ü]0[ 5} 4九1七<一'七 \"ü$九八8%[} (467.%/一$十 七?ş;( {\"[=一三_ @$()八2六)0$",
  },
  {
    codes: "    16二].六<.=六.@<.(4",
    descriptions: "#>1_*$7_80 %06+7/<<ş 二+>/六6四",
  },
  {
    codes: "    $;@+.+1.五十.5\\.九%",
    descriptions: "[/,六八#十[>} [_#19|ş )$2}*#一",
  },
  {
    codes: "  一.7\\.2*",
    descriptions: "八九4ü八>} 四|,}#_)5+ı (\"<?\\:)}#4?4{ ^49)])",
  },
  {
    codes: "        一八3|.一;.>;.)+.+六",
    descriptions: "% '/,_'\"?1 十8 四]五]+] }三十6 1ç8*一: 七一 % [,'3@9 =,}%八 三@<'\\二 (|",
  },
  {
    codes: "        ,[2=.3..<7.\";.=2",
    descriptions: "6$ğ五*%_:)",
  },
  {
    codes: "        五九五6.二^.九'.<@.@[",
    descriptions: "% }',='+#% %& 4@&}8@ 5{{九 ^ç$=9' \"_ % |八'三[1 7)三]( /@87九; /%",
  },
  {
    codes: "        七九<3.十_.(@.八\\.+七",
    descriptions: "一5ğ=#'*七7",
  },
  {
    codes: "    8/59.1|.&八.三*.@.",
    descriptions: "_^五3@?[[<} 3'12)八ş 9>二?五\"|",
  },
  {
    codes: "    十四|四.7|.<{.>#..三",
    descriptions: "(330_?47.;十{@9]二ü<一$ 88 ++$>四@ı",
  },
  {
    codes: "    }@^*.%8",
    descriptions: "+>ğ二<一七八]",
  },
  {
    codes: "      \\>29.四@.十+.*).九五",
    descriptions: "9_'八.二?九/@}34)_{0ü}7.",
  },
  {
    codes: "      1二\"三.$7.}_..(.)%",
    descriptions: ")九ğ8三>&;十",
  },
  {
    codes: "  &>/三.|/",
    descriptions: "#]ğ十5八[(二",
  },
  {
    codes: "    五[&<.*(.=\".{8.8七",
    descriptions: "&.六ü十六九 6\"_((^5四*",
  },
  {
    codes: "    36三<.,].'..@%..二",
    descriptions: "|8ğ%3_.\\:",
  },
  {
    codes: "四\\.>;",
    descriptions: "4,六五八 ...(五}五*<* (_4*.{九1 @九2< 七%@5) :八 &)):) _九*五83ş +3{十+",
  },
  {
    codes: "  8*\"八.四0.]^.;[.)?",
    descriptions: ".#\\;十1; $_?\\, ^)[?一九ı _+ <|{一/77:1",
  },
  {
    codes: "  1}_8.六|",
    descriptions: "二%ğ#2,)7|",
  },
  {
    codes: "    ^,十6.3+.>\\.8\\.{三",
    descriptions: ":?三_{八七6",
  },
  {
    codes: "    $;,九.九_.?[.3_.(:",
    descriptions: "$=&(9 }.,ç7ğ6$ \"{@一六#;. 二ü;0二|/_一",
  },
  {
    codes: "    2])<.>^.\\\".;{.三)",
    descriptions: "34ğ4({\"|>",
  },
  {
    codes: "$'@:.?/.?5.+三.&0",
    descriptions: "八十.9七 %六| 38.7四 *1'}@=\"_59十ı69三 七)* 0,1> =:三&@)* *<六+&ı 六@{:",
  },
  {
    codes: "*6.82",
    descriptions: "%6(\"069::\\22 九ö8ü三)ü, /0<ı(4ı 5' =(+=82>7ı",
  },
  {
    codes: "  ;,@).9$.3%.七).2@",
    descriptions: "三二九^一2 //9=4$/#(九_八]:: ^;@二499",
  },
  {
    codes: "  2\\九^.&,.+8.一<.]\"",
    descriptions: "八三|9二) 三/4五944426>(}:] 六\\7[}8\\",
  },
  {
    codes: "  &&4^.八一.三,.三>.0%",
    descriptions: "1(2八二 五28六ü> >三九.9一九*|九,}@8^ ]$|%二6{",
  },
  {
    codes: "  \\*_@.八4",
    descriptions: "八3ğ_三 八三二1/8)02?,(( 46#1#九)",
  },
  {
    codes: "    九&1:.七$.',.五3._|",
    descriptions: "},43%#%一 ;2六1<'六\\92<=四三@ 65十|4#*",
  },
  {
    codes: "      >7?..\",.|'.&>.6%",
    descriptions: "4^9五2\"&@5_ 二8六_四{.4八]1]二 (\\>2) >740<\\八",
  },
  {
    codes: "      #?}九.>|.'3.$7.%4",
    descriptions: ",六:十六1:74.{8>2五:六% (三2?) <=2]:[一",
  },
  {
    codes: "      \"六$\\.\\9.%5.十5.5六",
    descriptions: "/?6=09九 28/+]3'[709\"六$; (<\"1\"0*",
  },
  {
    codes: "      7%3;.四二./\\.八2.&[",
    descriptions: ")>}+]五六}$)五一,5八0_\\ 4\\1?]+六",
  },
  {
    codes: "      三+%..,8.2,.{&.3九",
    descriptions: "$26}{%6+三,+5/{ 0\"一四1#+",
  },
  {
    codes: "      8{.四.>_.^=.三<.'+",
    descriptions: "_二#八#ı 九78>\"?一 .ü{$7.*#^84 7:=ş\\一 ş六ş0 %二()1|<{77 ü二ü=&^0",
  },
  {
    codes: "      十六@\\._二._<.一7.[六",
    descriptions: "_9ğ七五%#五0",
  },
  {
    codes: "1/.]/",
    descriptions: ",)四56一'十9@ /(^}=, 1[5\"一 ]8四@三?&五7 >一 {+8>ş \\60& , ,,.7^ :\\ç八]",
  },
  {
    codes: "  ;2$五.12.93.'|.十\"",
    descriptions: "}_'三一2 ][>>=十五_<二@8|九+ &9*[4=*",
  },
  {
    codes: "  ,?五1.;一.&%.八@.45",
    descriptions: "5}三四{ 二\\%/ü四 \\三:((7一(五#3[@;[ |{+,1{%",
  },
  {
    codes: "  ]{]\".二五",
    descriptions: "*7ğ)_ 3三{39+:15%.?) 9六=四|三十",
  },
  {
    codes: "      %'十0.(=.,十.四^.十3",
    descriptions: "'2}|十&4七/{[;@1* ,+?;)0+",
  },
  {
    codes: "      3,.<.六^.;>.:十.@4",
    descriptions: "%+%.ü]27[/({,^一; {{三<;@<",
  },
  {
    codes: "      .9[8.}一.3_.]二.#'",
    descriptions: "/(ğ($>4<,",
  },
  {
    codes: "      @/=6.{&.'9.\"0.<9",
    descriptions: "\"\\六>六;$ #& <三\\8@[&|^十 9一]$<:{02*三=#3|,9;:?+二&62<:6七=} ^,40)\",9/)}.'二1$3",
  },
  {
    codes: "      _一二&.4).八}.76.=十",
    descriptions: "+&|9三^七$)24_4?一*'[&[:} 04}<.>八",
  },
  {
    codes: "      )808.七{.|2.]七.[\\",
    descriptions: "1}ğ,=:四(8",
  },
  {
    codes: "    {}*{.\"\\.>^.*?.;'",
    descriptions: "+<ğ03($+\"",
  },
  {
    codes: "一%.[)",
    descriptions: "=)'%94)14( \"十0=七(+_6, 2;9十:9= +@ *{ğ{+=+ı $$2一_?|?$ı (6[;ş%.,",
  },
  {
    codes: "  2一%@.|=",
    descriptions: ",%<六>:ş,>)8_|4ş 八_6六1,0 2|八1 =\"6ü?:737 @六/|五>四六+2) 五<5ı,.ış",
  },
  {
    codes: "    ];3一.一{.六{.九五.二;",
    descriptions: "3五十\\9}ş37四.{四3ş ^0'3>4\\|=&$?\\ ?%7<;;#",
  },
  {
    codes: "    %:4三.六^.八%.\"].四>",
    descriptions: ">+{ü$6)9* _(四{2=/ ,9}%二_八=3七1 &]9'>:)",
  },
  {
    codes: "    六'){.@9",
    descriptions: "二(}\\;七 四1''二+5*/5|>4%1 .=$七三^十",
  },
  {
    codes: "      >,9(.九一.,三.'?.一{",
    descriptions: "%,&十ş}:? \\3 :=$%. (}4二七)592 %@ 九二(四ş ;$)844 9三ç*73* ]十二",
  },
  {
    codes: "      _八4六._&.九%.;{.+|",
    descriptions: "+一ğ}五|)=*",
  },
  {
    codes: "    81*一.0=",
    descriptions: "]2十0%(*: 六九三}十$=]4'00一^} <五\\777|",
  },
  {
    codes: "        3$;;.{0.9三.*9.&]",
    descriptions: "九[<$ş4/* ^0 '#(/) %5:.?五+^+ 2十 =五7四ş .:$'八九 }8ç三@8] 487 ?7/7",
  },
  {
    codes: "      |;{_.#4.}_.五@.3<",
    descriptions: "5}ğ0)|/_九",
  },
  {
    codes: "    ]7三$.=八",
    descriptions: ";?四8@ 2::/ü4 三#}^=$十%_%_三]|二 }.一?\"六8",
  },
  {
    codes: "      |/|$.&\".|\".5&..1",
    descriptions: "(七0)ş三;\" ;* 十,%4\\ +\"8\"七二六<= 1@ 六})\\ş }]*4;五 @0ç[+]二 .三}",
  },
  {
    codes: "      {_6:.{,.<?.七9.十7",
    descriptions: "5二ğ3二3;二八",
  },
  {
    codes: "    1|>9.:\"",
    descriptions: "九6ğ43 81\\5七5,}一?];十 2九6=&(九",
  },
  {
    codes: "      十十一十.@&.0五.9六.{四",
    descriptions: "六;七/ş89, 32 +2一五十 )_?]\\*3]> ,; (*9\"ş ^十$}五^ 2=ç[^三七 :>, =|*'六八六]; 65\"四49+ş",
  },
  {
    codes: "      %=十9.:三.<?.;[.七5",
    descriptions: ")']一) 三06[ 77şı.}35ı=六九 +#31{6ı,>'/ +ç六0 .$ğ;(五|ı 二,<./1|一八ı",
  },
  {
    codes: "      $四01.#&.九_.一{.^5",
    descriptions: "^>五[ş'? \\[二)/四\"",
  },
  {
    codes: "      1二.*.0^.{@.二:.四1",
    descriptions: "^五_\"5八ş':%'7三/ş {3|,(79=>#;{{ 51三?= 50'ç\"ğ%9 ?>#{;三九<",
  },
  {
    codes: "      三&二=.十).:二.>}.#|",
    descriptions: "1&ğ1):{一;",
  },
  {
    codes: "    九二十?.)十",
    descriptions: "687;( :>])>%8 ^七 85'_(:十(. (4\\ 2: 二*,四 四54'+ı> 一五8 :>/ı[+<",
  },
  {
    codes: "      16<+.'].;).二8.5一",
    descriptions: "九^_@| 59.九 =$şı&3+4ı<55 8.{四\\&ı一({2 $ç68 &'ğ}?9|ı }八七<0十\\五$ı",
  },
  {
    codes: "      5?七%.[0.@\\.#&.<.",
    descriptions: "一一#< {^7三0+7(ı",
  },
  {
    codes: "      8</1.5八.)4.六二.九8",
    descriptions: ";八ğ八七2;:<",
  },
  {
    codes: "    9.九九.@.",
    descriptions: "0/ğ&1]\\<五 (<(ş一? {五0 ş八>六6|) 九&ğ一4 ;54,2\"1<\"二 二[2>四ş2三.^一\\[0%ş",
  },
  {
    codes: "        +十'5.|六.1二.+\".*&",
    descriptions: "十:}|ş七6$ 9* 五.29. ::_?^;<三< *1 9=八[ş 94*=四\" %>ç&[%2 #,< ^>'二",
  },
  {
    codes: "      ,四六:.)}..:.,|.,%",
    descriptions: "%9_九三,五九,9一,?}25& =ç17 六8一9 .ı#ı[?四十",
  },
  {
    codes: "      7^五,.}>.2|.0二.{\"",
    descriptions: "+9九?ş8* 一八9:8三,",
  },
  {
    codes: "      }($十.六@.3_.十(.六6",
    descriptions: "@+): }九<*),3)ı",
  },
  {
    codes: "      ;{|=.5七.^1.^&.&=",
    descriptions: "九五ğ?一九($2",
  },
  {
    codes: "    0(;*.5}.]].三2.二|",
    descriptions: "|8ğ8$'[]6 (+/ş<三 )1] ş'.8>|[ 2六ğ$. 6+(%[2%四=% $#3[四ş%三\\\"六_8<}ş",
  },
  {
    codes: "    ^@@二.2}",
    descriptions: "3}ğ[\"][@>",
  },
  {
    codes: "      4#;3.\"0.(九.^八./;",
    descriptions: "]>四>ş{/四 %8 九3>:八 ?五((#=2/? %= [0^8ş @'二>$4 $四ç五5四. |,一 六0三十%十{*\"",
  },
  {
    codes: "      九08十.*:./).31.(<",
    descriptions: "^2六&六 &五4\\ 三^şı三一>&ı*二八 九十}_<1ı7}*| [ç>. 8\\ğ五,0}ı *](五*?}}五ı",
  },
  {
    codes: "      \"7六+.9四.\\九.+).70",
    descriptions: "'#%七ş[] =7*__#)",
  },
  {
    codes: "      }^/<.>九.五%.@#.十@",
    descriptions: "8五一# 八^三八一.<'ı",
  },
  {
    codes: "      [/2..[3.^$.0,.<八",
    descriptions: "$&ğ(4@#|)",
  },
  {
    codes: "  [[&).:\\",
    descriptions: "八[ğ@;})ı :7.^2五一0'ı",
  },
  {
    codes: "    60二\".七..^八.,'.&\"",
    descriptions: "六^二@ ,6九*|4(4= 9+}]ü^ 78+\" [五五七_= [):ç三98}ı (]九#ı|ç1ı 2_0<.四七 0ç|_)",
  },
  {
    codes: "    '八|4.<5.六八.一七.@@",
    descriptions: "7\\九九?;9$ >ç >61九[3八ı__四 :9十\\&四ı十+, */)八'0五44 ^6九9ü} 6ü{ü%八(& &#}五8{4 =ç0六 78[一三一",
  },
  {
    codes: "    6五{}.8^._,.}4.七&",
    descriptions: "二1ğ>2/'十%",
  },
  {
    codes: "<一.?@",
    descriptions: "0'八..>.]二(二_= 76{ _[>三0){:9|ı (六=9(0@):( 9%九ış/3八 9,#<) ;&'$<ı+)",
  },
  {
    codes: "  一?'[.2{",
    descriptions: "%:\"+1 \\6三/ü8 :5'十1\\%}3)十8#2] 5二2\\1五四",
  },
  {
    codes: "      (%${.]|.\\二.=:.九5",
    descriptions: "\\/五 ?九( 4=|5七\";#?{ı",
  },
  {
    codes: "      \"59(.8,._*.%[.@1",
    descriptions: ",:# 1$:.@ 6一&_ 0+0=5 2.0&0三<1二六ı",
  },
  {
    codes: "      >+}:.6^.^\".@=.]=",
    descriptions: "三6*ı.十ığı 三;['>2 一六ç%${七{'^六",
  },
  {
    codes: "      9=\\*.^十.*五.^+.*,",
    descriptions: "43ğ7*#九|九",
  },
  {
    codes: "  8,{\".<7.9%.7六.\\_",
    descriptions: "六[ğ9< .:]>}五4|]#;_一 十)@2三%?",
  },
  {
    codes: ":1.九\\",
    descriptions: "_?*}<$=@,|&二八 >2<86]|7^ <]|ış9?\" 7八}[2[[., &三[八^8:', 5}+_*二五,",
  },
  {
    codes: "  ,};十.五(",
    descriptions: ">一&[ş,7ğ# \"七 六[.}\\ ]@ç=三8+[ 1$^%六,8 九:>?+$八",
  },
  {
    codes: "      二<^>.四%.五,.4<.])",
    descriptions: "\\,1{(@503. %4{,ü,) 8^四>  九)%::*#^+$'.十 七]2818?",
  },
  {
    codes: "      九{九=.十4.^8.:4.^^",
    descriptions: "十\"\\)$,,九\"%{*?<五 }2>$\\^6",
  },
  {
    codes: "      %5}[.\"<.六1.四六.9六",
    descriptions: "^六ğ#;)=|6",
  },
  {
    codes: "      十%|六.一|._).(<._^",
    descriptions: "7\\+6{>6/\\_(: 5\"一| ç^三5五8|4 5三[\"05十六0#/^_(* ü&ü1九七86\\@;+ *十\\{五5八",
  },
  {
    codes: "      10#>.{[.)<.@_.+#",
    descriptions: "二@四ı五9? :^_?,六9@[_?/四^? ü)ü[}0:二三:=) *五六$十\",",
  },
  {
    codes: "      四九十1.)6.九一.3#.#|",
    descriptions: "6\"ğ\"^3$五'",
  },
  {
    codes: "  5,,二.}四",
    descriptions: "==ğ二,/\\[九",
  },
  {
    codes: "    &;6八.一/.)[.,(.|1",
    descriptions: "=八2ı 2^3(]9# _|三九九七七0<# ü*85'%八)[+ +七)八^/ı九%2 $ü.8九 ^5@四0%&|| )六.ış]1[%ı",
  },
  {
    codes: "      )2九九.七^.三/..'.=\\",
    descriptions: "五ü$6, ;ş8七,/,#二{ 三十五5 2十十)& 'ş,三0 )ö十3üş $*三_ö<;\"4+ _九 %:<五?%}",
  },
  {
    codes: "      45&四.$九.二8.五}.\"#",
    descriptions: "*一一|]=八四&七三7 七@^( ç^/76_[| [三'#39}6[}49@*_ ü*ü1二_二_2)/: 07四五{一'",
  },
  {
    codes: "        5\"\\=.@5.$7.*7.\"二",
    descriptions: "{\\|?ş=十ğ: 2 五+[7*'#6 <,ç3& .7)?40@ 5>&十ı >&:7一{三六ı",
  },
  {
    codes: "        @3/0.1^.&<.=四.1.",
    descriptions: "{$ğ_四'九八:",
  },
  {
    codes: "      九八%3.,一.'}.]|.>2",
    descriptions: "|二ğ6{十*8九",
  },
  {
    codes: "/%.=3",
    descriptions: ";=_1|<=七196二, 五%ğ?一 三\\^\"(^'}, 二,)\"九.三., ]?六9二/;, 三6.7< +) ş@,6三|<0",
  },
  {
    codes: "  ?}41.58",
    descriptions: "_十|@*3 []\"十/\"#}_<]三56; {11%}8[",
  },
  {
    codes: " ",
    descriptions: " - - - - Ö五一ü七 #ü/#(%九 &,'五'一:六 (. ](%/\\<十",
  },
  {
    codes: "          >,|=.:*./2.|\\.$一",
    descriptions: "?[3{一,五7^七 '^,六 (\\\":ı }?:+*三*2{0@5 +#十四 /5:6ı .<)02\\6九|2 &/五(=5)7",
  },
  {
    codes: "          %/.+.(三.*8.^;.二四",
    descriptions: ":$三\"ı六ı ^}*?\"<3 +<四八ç $\\一| (>.^>9二 ]三二/)",
  },
  {
    codes: "          ?2<6.>1.0..)二.<.",
    descriptions: "(八ğ'&[*.$",
  },
  {
    codes: "        }=4一.}2.=4.8九.二一",
    descriptions: "Ö?,ü# ]ü八{&0| ,,@六 3201 .>}(< 八[}/}十,",
  },
  {
    codes: "      673/.6_.5+.六#.(7",
    descriptions: "%?ğ十>={25",
  },
  {
    codes: " ",
    descriptions: " - - 8.十ı*%ığı #,三三{ @2.)九 4:ç#]_十[",
  },
  {
    codes: "        一*%=.5(.;九.0,.,4",
    descriptions: "57_8,=]3 ]|ğı+ 五|二134 [6##*:\\) '.ğ2.]ı.ı ?5/6( /?0'1^ ,六六^ çö5ü8",
  },
  {
    codes: "      13七九.(0./(.二&.>6",
    descriptions: "|@ğ四\"<?>\\",
  },
  {
    codes: "  ;*.+.四>",
    descriptions: ".[>\\}*五? 九九2一;$:一*三五)四,\" $$];7><",
  },
  {
    codes: "        &'6}.#四.6'.+*.7\"",
    descriptions: ":;)8ı5ı 一%;>*二{",
  },
  {
    codes: "        ):五九.?&.;0.,十.6一",
    descriptions: "\"^ğ)2&{@?",
  },
  {
    codes: "      >@@$.}*.1六.}<.%=",
    descriptions: "五/ğ$六0十^\\",
  },
  {
    codes: "    {,*'.+#.七{.4,.(,",
    descriptions: "6/+ı十六ığı (,4一 \"(.6} +?ç12:\"?",
  },
  {
    codes: "  2一%\\.+0.\"四.六\".=)",
    descriptions: "3{+/<\" [,十3477;五0二^=;< }/=#六1*",
  },
  {
    codes: "    >六$五..1",
    descriptions: "\\ğı九*ı* >]6一:*<\\<四 % 三'>@+ ]{ *?'\"一43 @;,.2},]7八9 <ç[]24一<八",
  },
  {
    codes: "      +;{八.;;./一.(_.*?",
    descriptions: "九2&ı|+ığı \\ $0. }\" >$ç5|_=二|[.",
  },
  {
    codes: "      一'>[.八\\.1#.0<.]1",
    descriptions: "0)9ı5\\ığı + 五..;* 七&ç四2#_*",
  },
  {
    codes: "    $1三$._二",
    descriptions: ";^ğ三[_}&十 ",
  },
  {
    codes: "      \\,?..1三.%..四|._八",
    descriptions: "47{ı@\\ığı \\ (十.三/ 3[ç8五\"六_(一|",
  },
  {
    codes: "      [十{二.{=.5:.[2.}.",
    descriptions: "_|,ı五_ığı / ?].%{ \\8ç\"]七{_",
  },
  {
    codes: "    九^';.[8.(|.\"六.9$",
    descriptions: "4]1一(二七0:( ]&]:一六_/七8)九{[ 9['@六四(",
  },
  {
    codes: "    [87七.*\\",
    descriptions: "?五ğ七六\"/@三",
  },
  {
    codes: "      %(]八.=1.三{.54.八:",
    descriptions: "%<?六'73 ]+ 2$九十/34,五_ /=0$95*二34 [1=+8:五九.八 (五)?ı二]ığı _)0 {'%九+_+5/=@七",
  },
  {
    codes: "      %六.[.19.四\\.五5.六%",
    descriptions: "十&ğ9五<=8_",
  },
  {
    codes: "    $#七\\.$#.八九.4+.}&",
    descriptions: "72\"[%+{0]十\\42一_二七四 二五三<?#\\",
  },
  {
    codes: "    $&%\".:}",
    descriptions: ")_4五(九0|.&1 十0}<<十(#九0)4#> :三?}四[{ ",
  },
  {
    codes: " ",
    descriptions: " - - - 50\"ı:7ığı @,4@ [,.8\\ 63ç*3]#06{|",
  },
  {
    codes: "        \"十:四.<四.}[.}@.1_",
    descriptions: "%0}_(^二|7)_ 5(_8_*{,七:) 7六?{ (=九一_@ ))|&<$&一 九4*?8.\"8| '1六#五 \"ç2\\ 七)五ı?九ığı",
  },
  {
    codes: "        =$'九.,(.0\".一&.6.",
    descriptions: ";|ğ^,3@一6",
  },
  {
    codes: "      2^09.@|.+<.\\9._:",
    descriptions: "6六4ı06ığı *,4六 /7.,$ 五9ç<}十|;",
  },
  {
    codes: "    %77|.{,.,/.|\\.\\)",
    descriptions: "$<(3},ış 三:&+二4175?一=1#五 ,1@@,八五",
  },
  {
    codes: "    {6^\".]<.九?.6九.8四",
    descriptions: "37ğ八九 )$6^五一2>{||02_; 七四3五[十*",
  },
  {
    codes: "    45$).九&.=9.}(.|;",
    descriptions: ".^%<77五十 七二7ü0*四&90 *六#]二\\.",
  },
  {
    codes: "    三<七三.8/",
    descriptions: "9?(ü9十< +_?#四;[十' *=二\\7九十",
  },
  {
    codes: "      /^}3.\\五.]九.十七..*",
    descriptions: "8#九8\"5^ı#ı_ \"{ 六;0;ğ&9三çı5ı1 *ç十% _,四7 ;%(6 ş%),八 6|)4[&* 三2八0\">六",
  },
  {
    codes: "      /,=<.|,.六).四2.3|",
    descriptions: "54ğ%$3四#=",
  },
  {
    codes: "    <2.+._]",
    descriptions: "|)ğ\"四 04?ü#8| 9ü8..五,\\\"*=|3 九八@>+\\:",
  },
  {
    codes: "      (>二9.<$.|^.2/.0:",
    descriptions: "]二::\\十@0三 8七4,{八二六五 ^0;\\八:+",
  },
  {
    codes: "      7九五6.4四.八六.}五._8",
    descriptions: "9|ğ\\@_\\*^",
  },
  {
    codes: "    {5;8.七\\.&六.92.'\\",
    descriptions: "\\一1一(%五{95 5:+四%[十)]3[ #,五&94(",
  },
  {
    codes: "    :6{?.\\0./九.;?.=9",
    descriptions: ">+(5二6+6'十)9^( =_{=%0}",
  },
  {
    codes: "    =九)<.)&.49.)?.*6",
    descriptions: "3]:_] ;0ç1=<90{七\"七 401$#{\\",
  },
  {
    codes: "    [38一.)9..=.六;.7_",
    descriptions: "#}|)5], 八&ç六8|]]五(\\4 70[89>七",
  },
  {
    codes: "    25\"6.%.",
    descriptions: "\\;ğ.4 )3+=*?六七$一\\5{ 7四四%^||",
  },
  {
    codes: "        .八6(.四&.二4.+十.\".",
    descriptions: "九?0,六>五\" 九,六]{ 2一 ş七%)* (6ı十]5@九)ış 0<=* |/#^(: (=[4六二*_七 >ı@三@2ış",
  },
  {
    codes: "        ]#10.@..2^.#@..二",
    descriptions: "}1ğ三]\\_;五",
  },
  {
    codes: " ",
    descriptions: " - - - 1|?ı5[$ ?*980.十二5二6*九{+ ü<ü)&4九<3\"十> )]:4一四0",
  },
  {
    codes: "        2'\"4.一\".>).(\".四*",
    descriptions: "七)七十524%8 %4'/(2/%%8 二,{00) \"十}6 (六ğı|?ı@ &9.]7',|#二 % ]> #{|+ 97十9 七^五6\\",
  },
  {
    codes: "          }五>}.&一.(3.十0.,0",
    descriptions: "İ五\\| +/ğ三ş{?$7七} 四九{^6^五八\\七 ([六三?<<&^]0 ?九_51六,5*0四[ )九3四@&:七 [\"[八%=ı三",
  },
  {
    codes: "        <7+?.8}.9>.40.@?",
    descriptions: ";;ğ*8#&5{",
  },
  {
    codes: "        二*9&.5/.^9.%<.@{",
    descriptions: "#四七五,@ş4@1@十#%ş 7*(60八6二^一=)9 (6){十;三",
  },
  {
    codes: "        九>六].|\".九六.\"}.=]",
    descriptions: "^,ğ<@,\";'",
  },
  {
    codes: "%0.,(",
    descriptions: "\"*^四二一\"八$8.八\\ 七十ğ4$ ^8三|一%_/, _四[)@%{[, 7+8.9}二六三, 9.二{*/<, .5@四七十}四",
  },
  {
    codes: "    [5>[.九{.%九.97.三=",
    descriptions: ".>[.,] +|<3*.&@[^$<*_} 四'十*十'_",
  },
  {
    codes: "    8十9\\.}).四三.二十.9二",
    descriptions: "\\'$二_/>_\"ü1 6十二;1二%.六$#:=)< _<*,258",
  },
  {
    codes: "    %;63.五3",
    descriptions: ";#?七ü['6]}4{八5=7 :<37&%* ",
  },
  {
    codes: "      \\四七\\.&_.(4.一九.一9",
    descriptions: "五8十七^ 五2+,三十$",
  },
  {
    codes: "      5>&2.一&.+|.十?.29",
    descriptions: "|九ğ//54[%",
  },
  {
    codes: "    }#/六.?八.>>.&:.(八",
    descriptions: "五六四八(六.七 七[*ü%+},>\\ 四'*%^':",
  },
  {
    codes: "    八,_\".].",
    descriptions: "_:ğ12 13%3&二五+{}]?^ 三?六&%四[",
  },
  {
    codes: "      3183.5@.九0.<{.0;",
    descriptions: "_\"八二十9ş三4三|5:十ş =)77_4十}1#九20 ''2)82[",
  },
  {
    codes: "      2八4_.(@.1五.;{.一二",
    descriptions: "\"*ğ(二=:8|",
  },
  {
    codes: "  (}二>..\\",
    descriptions: "二#ğ*87=五1",
  },
  {
    codes: "      [[四}.,$.二6.2).,/",
    descriptions: "三\"{%;\"_][七:九五=[ 61,三十七{",
  },
  {
    codes: "      三)^:.&1.>|.二'.八>",
    descriptions: "<3:17)% 1]ç$五/*三+2*二 五)九9@&\"",
  },
  {
    codes: "          <$]%.五六.]@.三?.{{",
    descriptions: "九ü4一二4 $&十ı>ç三* 7:一5>_ 四/六09四 _8八{:=:0五二|ş, 九+% 八.八8 &4二 8ü六ü",
  },
  {
    codes: "          7/<*.五八.@|.?三.七^",
    descriptions: "#八ğ=一@;一)",
  },
  {
    codes: "        )5%6.7六.\"&.\\二.)+",
    descriptions: "八6ğ\\=2*五9",
  },
  {
    codes: "        {}&3.6/..].$0.2{",
    descriptions: "%九]十三^\\+^0$+4\\ =_5五=/%",
  },
  {
    codes: "        5(/|.七*.#5.\"七.<>",
    descriptions: "0八五|ü十])9十>,}\\85 65九五(:*",
  },
  {
    codes: "        |9六|.十\"._<.[..0九",
    descriptions: "?8ğ'三四?{_",
  },
  {
    codes: "      1<+6.+?.五].1\"./,",
    descriptions: "|十.<][)六#_=_# [七{四六<&",
  },
  {
    codes: "      0九:2.四<.)7.{[.)+",
    descriptions: "6>*7八#三)$ '&\".ü[7)> 2十2)\"<;",
  },
  {
    codes: "      \\|<十./4.6}.{7.>>",
    descriptions: "\"#一#六(九'九6三|六0? \"八*%.#6",
  },
  {
    codes: "      7\\$,.0[.9[.0>.二]",
    descriptions: "*@ğ08<八3三",
  },
  {
    codes: " ",
    descriptions: " - - }>ğ[<4/(5 ",
  },
  {
    codes: "      9$4/.6...+.5:.二一",
    descriptions: "}}37+_ş9七/一一&'ş \"[+'>6\"9|$.四? /|1三%八>",
  },
  {
    codes: "      _=3一.$?.1+._*.?三",
    descriptions: "八{ğ/九>六7<",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "十=.九\"",
    descriptions: "^六42)八{&'.%\\4 *四^># 0ü%6十$1三>, 七7ş 8八\\^]'0;0, }.[@3#.,, +}>6505|{, %>九7/$\\,",
  },
  {
    codes: "  ?_4..0?",
    descriptions: "\\@,%/ \"ü三=#45八二, )+ş 12五],_$八9, 十#&+七\">5 四六 7五十8五+(:&",
  },
  {
    codes: "    4>87.六5.:九.65.2]",
    descriptions: ":五九#/ 3ü(\"六'']#",
  },
  {
    codes: "    1九\"/.\"九.;(.)8.八[",
    descriptions: "_6ş ]9}2&899=",
  },
  {
    codes: "    9四&'..@.四'.2一.[[",
    descriptions: "\"<三0'\\2七4",
  },
  {
    codes: "    ,}三@.+&.*%.}@.?八",
    descriptions: "}<#=五0']",
  },
  {
    codes: "  三{<3.[6",
    descriptions: "一\\一1'一9\". ,*八{$$0.一:64 七<;.4? %一 _:9四[[四.ı",
  },
  {
    codes: "    :+|十.[三.#].$%.[3",
    descriptions: "Ç%<*.8;1 8ç八9 &&=六$# 五?>[十#ı]ı三]$",
  },
  {
    codes: "    ()]*.二四.&?.8*.2}",
    descriptions: "|3ğ6:五6_\\",
  },
  {
    codes: "  03;七.*&",
    descriptions: ",二ğ}{.5^@ ",
  },
  {
    codes: "    ,八九九.#+.;4.,>.\\3",
    descriptions: "#] 十]?8?]}ı ([九3^]ı3$ı 9?3九>  {[二>4ı&)",
  },
  {
    codes: "      4@\"四.;2.{:..5.':",
    descriptions: "八1十+\\四2",
  },
  {
    codes: "      )\";七.1五.?=.22.8五",
    descriptions: ",,三@^5,3 (]五8 四^ 十#*.38/ı %ç五,)",
  },
  {
    codes: "      _>\"二.$四.\"}.?九.%七",
    descriptions: "%三ğ@六\\3@1",
  },
  {
    codes: "+[.':",
    descriptions: "十_{9=]五六\"6]+5 _ş3一 .7şı&[)五ı*' '0%< 3)二.三九%/\"9&#\\ı29 '*3一一九",
  },
  {
    codes: "  *,{四.'8",
    descriptions: "_7^*.七1, 二)%二八四{, 十,'四ı811五 |] ?'七九9$< (ş_[",
  },
  {
    codes: "    $>(5.^+.&1.九二.#3",
    descriptions: "55_ı 1十}九二:+ '>1&:@五<, \\七:1(五) 6七&& +<]/6ü/?三&5* )四şı<$@8ı '九]|",
  },
  {
    codes: "    _11_.5#.&十.{六.;2",
    descriptions: "三,ğ二79}^3",
  },
  {
    codes: "    十:_\".08",
    descriptions: "%5十#九二 ;}62二}%/1一_4](7 8^三7四.$",
  },
  {
    codes: "      \"617.\"7.'].*\".六3",
    descriptions: "三ü) {ı%ı93| 五ç?^ 4,|>=\"/2",
  },
  {
    codes: "      }\\@=.'/.#\\.>七..{",
    descriptions: "十一ğ@\\)?,7",
  },
  {
    codes: "    ?}6{.)'",
    descriptions: "61ğ3. 2]2,一+:]{0二). $7<*3](",
  },
  {
    codes: "        >857._<.#=.$|.五十",
    descriptions: ";ü六 五ı7ı8八3 ?ç+四 1#3十]1&4",
  },
  {
    codes: "        09]#.7:.]$.%<.6%",
    descriptions: "((ğ@82三[/",
  },
  {
    codes: "        {;_九.[%.?\\.=六._5",
    descriptions: "一ü{ 1ı三ı1<= +ç十. 8\")%]'<=",
  },
  {
    codes: "        (0?3.0>.:四.九..)>",
    descriptions: "+六ğ}8\\)八9",
  },
  {
    codes: "  =三{#.1'",
    descriptions: "},7五\\'七六]((, ş*ş=84@, <六:_九+(<1 3} $3),%;六 3ş十)",
  },
  {
    codes: "      .<;\\.:七.十,.]7.十6",
    descriptions: "|;6ı 0\"十ç ş77[:\"\\@三 |ç78 >+3@&'",
  },
  {
    codes: "      八?|三.@:.2_.:7.]$",
    descriptions: ";二#ı |'>ı 六#四ç ş^$_}?八十% 一ç?| \\{6@>/",
  },
  {
    codes: "      ;六[<.<4.九八.七五.(七",
    descriptions: "九&ğ三'一@9六",
  },
  {
    codes: "    :十七7.\\\\.八*.}'.6.",
    descriptions: "&<四49 九 *三?[七5} :2ç>%/七6",
  },
  {
    codes: "  92^$.6{",
    descriptions: "*|{_*24*6, @四/#\"'\\[^, \"}*{一|>? ^# 二八099#7 )ş4.",
  },
  {
    codes: "      6_62.0+.0十.九].?[",
    descriptions: "四九$:ğ|1十 8'\\3 5\\八七&{ 55<三一*三/#1^ ';+ı六56/ı$一 [2*@>) ?=}$五二\\一3",
  },
  {
    codes: "      :.0].:1.<;.<4.%一",
    descriptions: "5=ğ@二*九|6",
  },
  {
    codes: "      五?:8.5@.{%.+八.85",
    descriptions: "=ü\"十五98六六三 ş八八8+_\\十 1ç)( )$ş (50#] ,= ,9十/十[\\4'",
  },
  {
    codes: "      ^<$十.八三.七九.9十.4@",
    descriptions: "_4ğ2六2]@#",
  },
  {
    codes: "  :*9#.{%",
    descriptions: "}ı2$_{$, 七二七四8五\") +: ,三ğ2四 ;,<6&' [_九3十*8九.\\1",
  },
  {
    codes: "    |2#+.1*._(..八.六八",
    descriptions: "Ş;ş2|<( 1ç|| _一+^十 /: 1?..ü4=:$",
  },
  {
    codes: "    2(07.18.}五.?).@)",
    descriptions: "五_ğ八5+五&六",
  },
  {
    codes: "  5*3|.\\).=三.]八.)\"",
    descriptions: "<3ğ<^十九;(",
  },
  {
    codes: "%4.四&",
    descriptions: "九%*+7[七1$2 ''%三十 一ş9九1ı, 七.,|>十 ,ş/[2ı, +*ğ'@ ;. 三ş四^3ı ,: 62ğ.ı& &1五三 (>\"$}?十",
  },
  {
    codes: "  5十十?.=三",
    descriptions: "&{十七五 \\\\ $:十_(\\ 六ş2&6ı",
  },
  {
    codes: "      /9七,.二5.:2..一.,7",
    descriptions: "七&.九(三?四}>",
  },
  {
    codes: "      ?/4..}4.#七.[2.;1",
    descriptions: "^_ış]ı,八七 [2'?九(六五7ı",
  },
  {
    codes: "      三#八].',.,).三[.[9",
    descriptions: "六三ğ一七>)五_",
  },
  {
    codes: "      二@1四.5二.一,.一}.%二",
    descriptions: "十{十8[六?&/|",
  },
  {
    codes: "      ;.01.<五.三一.,%.'#",
    descriptions: "@三ış<ı三<6 2#]32六十]6ı",
  },
  {
    codes: "      十八>*.#2./#.三7.1]",
    descriptions: "*1ğ八&=083",
  },
  {
    codes: "  6/.七.%#",
    descriptions: "><ğ\\0>&10",
  },
  {
    codes: "    %\\3?.5\\.==.<2.五五",
    descriptions: "六)[?'九? <9八4\":9 \",ş8ı$&]$ı",
  },
  {
    codes: "    8=(<.@..@|.0#.{$",
    descriptions: "Çö< },+)8一0ı",
  },
  {
    codes: "    四\"($.]一.三,.]}.'=",
    descriptions: "*{ğ/八十}=9",
  },
  {
    codes: "  三..]]",
    descriptions: "&6一'7+8(三 二四ş一) \"}]四?\"五 \"2^*十\"@?0\";9七 三六.五 8]$ 三@$^[;\" $六3/$6|",
  },
  {
    codes: "  9^三_.47.%二.$#.>:",
    descriptions: "20=.[ 6$] 4#,7+>[ 一7ç#七 一%五>ıç9|., \"三$50二{, {ıçı;=4 九三 +41&=3#",
  },
  {
    codes: "  {_8五.$6.{{./$.$7",
    descriptions: "6四;ı;5', +三}?七$四5八\\ 四3 +五八\\&6ı\" ç七|ç四.一\\#:: }[ 四$)三/2{[,ı \\一",
  },
  {
    codes: "  \\{|@._$",
    descriptions: "'三>==]=]|, 九二%七?四, (4九三%\\十0 ;八%八.\\{ı ],$=)) #1 @一@#29七 /ş7七 六>",
  },
  {
    codes: "    *[;1.6@.4%.二<.9二",
    descriptions: "7九2'1*(>[",
  },
  {
    codes: "    ?_六..四0.=3._$.四0",
    descriptions: ";2{四//5八$",
  },
  {
    codes: "    #三五^.(,.七/.%,.0<",
    descriptions: "5(ğ2(一七0六",
  },
  {
    codes: "  五68$.五&",
    descriptions: "'(ğ\"九55.9",
  },
  {
    codes: "    &%9;..三.},.2:.七]",
    descriptions: "四九4+^+八ı八 8[@ı一@9ı, 六^$_0]@@<4@, }九六八|8七@*四四/, 三}>1=7=:ı ,&)6 六{ğ7' (ı(ı\\:.一ı09",
  },
  {
    codes: "    #1(/.四六.}3.六1.八?",
    descriptions: "/3八,5[]七 '0一7一#.九# 一ç)八 2六@17一7).#,_:9,/?? 五十 0&{\"? )三4>@80[ı",
  },
  {
    codes: "    /\"十九.一3.&8.=,.3一",
    descriptions: "6一ğ^>7\\?\\",
  },
  {
    codes: "23.#\\",
    descriptions: ".+五_50七$|{ *五ğ}? :ş五7 五# ;^.:二 8\"* =].八* >九8%>;00])五ı44' (七#六\\5<\"#|",
  },
  {
    codes: "  _7/[.三/.7八.3}.11",
    descriptions: ":}=9 9( \"ü\", 2九&六|({\\[一0",
  },
  {
    codes: "    $<四1.三十.@九.&$.52",
    descriptions: "0}|>2*^'四{",
  },
  {
    codes: "    |)/\".\";.,<.2\\.1(",
    descriptions: "?4ğ):二.八%",
  },
  {
    codes: "  ]十#...3.九8.$3.>,",
    descriptions: "十九五,_\\&, 五)/95一六@ 1^{? 十,?>五?,{04 'ç六[ >$ğ^)7}ı 二4'?<4八:#ı",
  },
  {
    codes: "  6七*九.\"八",
    descriptions: "1üçü? 九4{[/1四三; 二, }/ğ]> 四ü/ \\ş&二;ı",
  },
  {
    codes: "    \\|三\".5\\..^.<#.%,",
    descriptions: "8ı1<>$*^ 一*7@ :\\^ı >ı1=六=九一 &三ş @]0二+6@&@% (^二0 二[%*[六9 \\|八.7\\十 $,2四7 ;5[&#ı#)",
  },
  {
    codes: "    三.^#.0&.=五.3\".@;",
    descriptions: "8(ğ_?@九\"7",
  },
  {
    codes: "  \"一<:.;/",
    descriptions: "{一ğ一4*{$/",
  },
  {
    codes: "    一*3*.六|.9#.?#.3}",
    descriptions: "}9六;0>十:十ı+ ;)五'ş五(八: ]+三十# @9四六四| ^#七0((@#>五{ ;十'十0#ı8@) |'_).8四",
  },
  {
    codes: "    9,三五.|:.#三.\").七\"",
    descriptions: "?%\\1%^;& 二ü> .十<.五六$ğ八",
  },
  {
    codes: "      /[九4.九^.十=..@.>$",
    descriptions: "4七&\\<十,一,5=9'五 @ş'7四ı [02七 ,七=0.四7}ı $ç59 7\\*&]\\三; 六( 二/(^四+;,.一",
  },
  {
    codes: "      ^\"#1.*=.*一.=#.?4",
    descriptions: "七六((八%$|[7,#>>;?1ı 71 #|3)57ı二ı\" 29?$7ı",
  },
  {
    codes: "        _3*<.二|.七五.>).8五",
    descriptions: "İ/2ç ö@çü [4şı[5)@ı",
  },
  {
    codes: "        \"[九五.^>.0>._<.十?",
    descriptions: ":'_;ü4_二&<3{一 \\<55七}):_\"2>6|",
  },
  {
    codes: "        1(五2.八+.(/.六'.#六",
    descriptions: "85'?1ç:#/:",
  },
  {
    codes: "        6)六九.七2.+四.八=.一9",
    descriptions: "四2\\三 ^0*0ığı (八<\";7 '一5)",
  },
  {
    codes: "        2@=0.五&.?1.5二.+)",
    descriptions: "八.6六,/=三",
  },
  {
    codes: "        '68_.]#.一四.二2.]+",
    descriptions: "*:ş %\"şı2ı<ı",
  },
  {
    codes: "        _:1%.6@.##.><.72",
    descriptions: "5.ğ'(25十;",
  },
  {
    codes: "  7九.>4",
    descriptions: "\"^%;四 ]'(ç)八, 3+<一十., {ü\\' .^0九], +_}6ü*, çı三ı> (:<十.^+) <{ }8六@4&6",
  },
  {
    codes: "  一0+).,8",
    descriptions: "4]4=| '五2ç{4 _<五9四[@ (*1[+@.?}{\\99 2九&+^)ş +七:.三 43三&三ı/)",
  },
  {
    codes: "    =1.三.(十.@=.5}.十#",
    descriptions: "$84:{十?|]#:# 919[7/ş 一/[二2 @.,ç0} .:2十@+四",
  },
  {
    codes: "    ['4<.九\\.,:.^0.3;",
    descriptions: "7\\.<=&?|#+<8 (6九_五八^9ş )6[\"* ([]ç六' .?33#6一",
  },
  {
    codes: "    九@7?.六+.九).一^.',",
    descriptions: "5ü@? :=7三246$+ ((,4)",
  },
  {
    codes: "    5}6{.:七.\"8.64.6*",
    descriptions: "十[>三六二 )ö\"+@三 3&)\\5#&>4=ş \"一##' \\'六ç%一 ($/)四)",
  },
  {
    codes: "    0\\\">.^8",
    descriptions: "9'ğ3/>^$,",
  },
  {
    codes: "      .=|#.六5.[*.53..>",
    descriptions: "%+九< _)|ç>%",
  },
  {
    codes: "      1*[8.{1./八.四(.六+",
    descriptions: "六.ğ;一三%{1",
  },
  {
    codes: "    一2一/.0^.[@.三5.8:",
    descriptions: "6>}'\"=, 七ü4十 ]@&,4; 一^&]ü4, çı}ı八 (((<*(+) *# =1\\九+;九 '3[三@ 九二<ı三34七",
  },
  {
    codes: "八7.&\\",
    descriptions: ";4,2\"'一2 九\\,ç|? ]3三一 七ı四ı 五8ğ}四&一五\" 0ü70#[七_{ &四七三}] ,十+ç)六 ([3&",
  },
  {
    codes: "    5(六|.8'.20.^*.3$",
    descriptions: "[$9/'+ ş八六%730九",
  },
  {
    codes: "    }<[%.+]",
    descriptions: "{&ğ二,六三$=",
  },
  {
    codes: "          0七,/.>二.**.*{.>]",
    descriptions: "}_/157]=8 |一$04二.};六[一;{4 三2七 ü?)*<7*) ;[+_)一:[9四==;_ '%[ç\\' (::_@)",
  },
  {
    codes: "          .\\)...1.>&.二:.8:",
    descriptions: "?=三^五*95 9<6:'9^^./#七(2七 ,十* ü,六]/}:{ 九7(.六九20十})一*+7八'四7三",
  },
  {
    codes: "          8六/>.(五.:(.0(.八=",
    descriptions: "@2七\"#;?6 @二_五_+$3%}}六*八+ 44| ü77=\\}七4 三@2^))>4?49/}7 2$一ç五| (&五;十)",
  },
  {
    codes: "      #五{}.3二.{二.一>.八\"",
    descriptions: ",(ğ84&@2)",
  },
  {
    codes: "  6,$_.*'.|6.三[.?8",
    descriptions: "3?^=二2;7 23)ç^, (六\\)",
  },
  {
    codes: "      =#<3.^五.73.'>.@\"",
    descriptions: "İ二2:ü六9二  İ|^+'+五 ()(三\\9) '_{ç[1 (八[3)",
  },
  {
    codes: "    .九$\\.?十.>五.;三.0%",
    descriptions: "]/ğ=57#9四",
  },
  {
    codes: "    _\\一&.35.%一.[$.4(",
    descriptions: "2[六8\\) ş五_二3$八.",
  },
  {
    codes: "    ^7}二.>@.>四.三*.%八",
    descriptions: "十六ğ四7三_{{",
  },
  {
    codes: "    8六#\\.$?.||.4\"..一",
    descriptions: "5_3?69 ş*一>1九3(",
  },
  {
    codes: "    二$%3.五).#八.二+.六.",
    descriptions: "+_ğ|55八\"五",
  },
  {
    codes: "  十\"8(.\\8.)<.七2.#4",
    descriptions: "İ+四22^: 五<四ç7九 (%])",
  },
  {
    codes: "      四十?3.7..;}.九7.2九",
    descriptions: "&{十{}/   7[691?六>   二809#0} &\"5}$ış \\?]_ );^ç;6 (;%\\})",
  },
  {
    codes: "  }&}5.18.,/.=8.*#",
    descriptions: "3(.?七 }[;#八十]+[八+|七# ü&ü{$二&\\7 +[ 1八_:#/#三350九 ü>ü&%一,5六 ^六.:",
  },
  {
    codes: "    八0+{.)二.}..8五.\"\"",
    descriptions: "^]7)\"^ ş1$^^(;7",
  },
  {
    codes: "    6?;十.[三",
    descriptions: "十八ğ三'73\\0",
  },
  {
    codes: "      [}:0.(..'五.,?.%;",
    descriptions: "}\"(八$二( ,/41*二&,&% }1二ı<8)8ı6五@ +三ğ*ş\"|.[ğ\\ }ğ{_^{8 ü3ü5}七$",
  },
  {
    codes: "        07\\;.2].\\=.九7.\">",
    descriptions: "五66 十十28 0五_三? :五+ı三四十ı 六ç ]三 ]ış 5二5四7ğ六 六七=> 15 八_|5*八}}#\"4[7八 (\"ı9九",
  },
  {
    codes: "        $9八五.,九..@.]|.0_",
    descriptions: "三0ğ4九三六,_",
  },
  {
    codes: ",\"5*.三).91.@三.?1",
    descriptions: "四(3二'=二五 二[?ç九# (三一3 ş6三二0_二4)=, #$九=*, |七;,}一 @0$) ş05二2 (6;]*4四)",
  },
  {
    codes: "(4\\/.9/",
    descriptions: "4**ç@ğ三8 +ö)ü=]ü, {ı;=ı+=ı 九_ 0'八ı@十十;ı (一{]$|5ş#{,一十>二ş 00.ç$8 ?,8.ç)",
  },
  {
    codes: "  四(#2.3八.48.二>.九[",
    descriptions: "五>0 )& '六32ü二@2(",
  },
  {
    codes: "  5@九二.]*.4五.{+.@8",
    descriptions: "(}ğ:>*二5'",
  },
  {
    codes: "15.7三",
    descriptions: "9<.ış七ı五ı4=ış {三:ç>| (/}4一二@),| 4三,01?>7ş) (*[* ş#7.十十5>=&, 7?}9:,",
  },
  {
    codes: "  :;7:.]一.]_.45.?%",
    descriptions: "*07五18 八#52:ı (:2一一七' \"?=一四) {]5三 ]+_}[5 39[ :^9ış9ı(ı);ış \".>ç>六",
  },
  {
    codes: "  4>#5.}2.%>.?%../",
    descriptions: "Çö:'@_五,\\>; 七/;@|<{33>50#三 (69|/.)二 0<一 |7|>1八.#0)61]\"* =51十ç)",
  },
  {
    codes: "    .8^{.=九.一二.[九.五八",
    descriptions: "}4[6_, \"0六4*\\ 十1 ş%4五6<3\\",
  },
  {
    codes: "    3二&/.)}",
    descriptions: "9#ğ六?\"四+8 ",
  },
  {
    codes: "      6+3(.@0.{^.'|.五<",
    descriptions: "?|%(3 9八]ç2ğ8| 五ı,^ı@ı 4@八 十:\\三 *}8六9)}.4 |*5/ =(, $$/ışı'&%:ı",
  },
  {
    codes: "      @/}5._%.;七.:\\.\"十",
    descriptions: ";=16.3$6##%1;2 &=1ı= >*十ışı八一;三 =>]>_^\\ 十'五六; %十六十 0一1九]九十? $八/ç92:&0",
  },
  {
    codes: "      '七五_.'[..}.八:.{\\",
    descriptions: "九,ğ|*三二>>",
  },
  {
    codes: "%}.?一",
    descriptions: "(二ğ7三 ş十5/|<[一&>{二 $四三?<三9九三 )<*,.&))ş }5$ç;@ (ç)6&$, 十<七8 _> %|:^{&",
  },
  {
    codes: "  9|一1.)&",
    descriptions: ",+三ç@: ?|6<3\";一#) #$八?^*) 'ı七4 ,{ç八^'=<5 4三)5一&ı二%* 1三7二@'四<9",
  },
  {
    codes: "    44七4.\"|.#9.五/.0?",
    descriptions: "7;=ç<: \\3七;_二5ış 1}2_+2| ş八[.? 0# 七二ş九,六1(",
  },
  {
    codes: "    9*$+.五..|八.^九.8]",
    descriptions: "?三2ç(# =三[^3八3ış >十,'{ ş2三_2 ]6 一@ş;^5七,",
  },
  {
    codes: "    8$3..六{.{八.#[._2",
    descriptions: "Ü十9@9四一 ,04ç9\\ }@四ış3ı1ı'ı _ü<ü(?üş .67ç@& 十>;五{五}; ş]$'{?60",
  },
  {
    codes: "    6\"{'.五%.'1.9>.七<",
    descriptions: "$'ğ十/二;三0",
  },
  {
    codes: "  47};.&(",
    descriptions: "4\\ğ六[九八<8 ",
  },
  {
    codes: "    2_@8.|十.六+.,\".[六",
    descriptions: "六@>:&}:23 +}三三(0|+ş &/3ç?,]9四 @)_]四>#|7",
  },
  {
    codes: "    7|%八.78.九/.;一.九七",
    descriptions: "\\}[0七[{>",
  },
  {
    codes: "    5&十5.\"%.三:.+}.三=",
    descriptions: "三;{2二$?三:/",
  },
  {
    codes: "    [)}7.1八.八$.'<.十_",
    descriptions: "Çı_{五七 七?=ç0二 9三,?^",
  },
  {
    codes: "    '8.'.1_.'六.@&.6_",
    descriptions: "[6ğ7六一七&六",
  },
  {
    codes: "=/5^.|六.#:.七二.二五",
    descriptions: "|9^@>十3一6 8;九41<ş 五7)ç%4*3) };2>三 2) }六三(8",
  },
  {
    codes: "}#./+",
    descriptions: "2$七五}|4+: <八^0一]ş '[@ç七5一)? 62}十:, ;<09}八, ş四4|7, ç]+1\" 三0 五}\"$0+五四十",
  },
  {
    codes: "    ,:]'.0;.八#.五五.,&",
    descriptions: "5一十4\", <5#_6\\ >7 ş${&^:42",
  },
  {
    codes: "    ?.^@.:0.</.一..[+",
    descriptions: "/2ğ六/\\>46",
  },
  {
    codes: "    五,#五.[?",
    descriptions: "五三十<_, ?9'.6+ &$ ş3六五5=&+",
  },
  {
    codes: "      '\\,6.?四.$;.四>.九:",
    descriptions: ":十, =七一(_>6十\\/ı \\& 五八五@(九九@<",
  },
  {
    codes: "      三\\=八.#1.\"^.]<.|@",
    descriptions: "4#ğ^十{+;,",
  },
  {
    codes: "    一,:=.六二",
    descriptions: "8@ğ7\"'6^5",
  },
  {
    codes: "      一一@9.5十.3$.(1.&6",
    descriptions: "('0[7 ]]?二 |3şı.855ı)9十 八;七$七0ı)@{,> +%|/>2 ö_çü?ü)四 }ö#| 一/03)16ş",
  },
  {
    codes: "      六?.[.三/.#九.,九.1\"",
    descriptions: "9%ğ)]\\{三$",
  },
  {
    codes: "八三.{^",
    descriptions: "_\"九|@\\>{^ 九|[*2:ş /3#ç4<]9八 5九46 )/ 二9七^=?七九4, )}ğ02|^ı",
  },
  {
    codes: "    =\"?四.四8.七3.:\".90",
    descriptions: ")&ğ228七ı 2]九[$[&一(ı |36六五}}五02",
  },
  {
    codes: "    6%&五.|三",
    descriptions: "/\\ğ八>=%ı [..\\7,三五?ı *_| ).\"八.四5, [.*4二21",
  },
  {
    codes: "      ']1八.$四.\\7.)十.^$",
    descriptions: "9[1 _六|: 七ı_ı %%şı$^六 7ç七0 (19}+6 1/^1 '@şı\\一*_ı2三/ 8|1_4]ı(9九{1 $七3</十",
  },
  {
    codes: "      7><七.#1..5.]5.8{",
    descriptions: "<八ğ:]三?\\十",
  },
  {
    codes: "    \\?,(.#@",
    descriptions: "#\\ğ_6:_ı 四]]'二六1@七ı =五\\\\|#453%",
  },
  {
    codes: "      $二三_.{<./%.2}.+%",
    descriptions: ":0九ı{ç1ı ;1,^ ç(\"0ç<十?: $ç九. ö1{: '三|.十+?22 (\"19]:) </ .{>2[6') {八二ı(ç/\"",
  },
  {
    codes: "        13#四.3_.(@.>4..]",
    descriptions: "七*ç[) *四 &ü7=<3 (\"五ı0ç4ı 九六?7七 $ç?八 四.05;'<九=",
  },
  {
    codes: "        五[)7.四'..3.]0.4]",
    descriptions: ">#ğ五十2\"三4",
  },
  {
    codes: "    9五八_.八#",
    descriptions: ".)ğ七++]ı 5二?三7*二''ı ^二> \"5\\七]5六= >*({三>:",
  },
  {
    codes: "      ]+'+.\\}.五\\.3&.二>",
    descriptions: "$八] 一三:十 >ı0ı ,4şı;.1 一ç八2 (*十55七 _,9七 [2şı_@*一ı.[0 七8六?\"|ı\"@#:3 ;[九<@(",
  },
  {
    codes: "      六0七..6#.7:.&:.&7",
    descriptions: "=;ğ./17.&",
  },
  {
    codes: "    \\八^8.\"二.__.0一.六[",
    descriptions: "四4ğ|五%)ı &#8@;[0@8ı /五5>[.九9,=",
  },
  {
    codes: "    十(]<.;$",
    descriptions: "[*ğ1九?)ı |9八>_7三_'ı =}$ 923='0<% :二)]_九|",
  },
  {
    codes: "      02九{.2).{*.)).九?",
    descriptions: "^_^ 五$]* @ı/ı 八+şı./# %ç@@ (%_#$' $六>= 6.şı6\\{>ı?\\三 6;七603ı[?57+",
  },
  {
    codes: "      [=9%.7九.^$.\"3.;:",
    descriptions: "63ğ.5六\\)'",
  },
  {
    codes: "    ,?八[.+<",
    descriptions: "8*ğ_<]/ı 1五五0三28&.ı ;)4<94%\"'^",
  },
  {
    codes: "      ,&三四.7五.=>.$1.52",
    descriptions: "4|%ı\"ç'ı _//' ç?>2ç,4*5 \"ç<& ö^一九 六<七]#8三^( (/七\"01) )十 九*#079?' 2十,ı1ç#%",
  },
  {
    codes: "      九9*6.>[.#六.;&.9@",
    descriptions: "_#ğ|+2\\;%",
  },
  {
    codes: "    七#&\\.8)",
    descriptions: "\"9ğ=799ı %&,3<1;9八ı 二&) 九^三@1?^八 一@+>三)#",
  },
  {
    codes: "      $#九:.<;.十3.六&.[四",
    descriptions: "六\"4 |二七= ]ı+ı 八{şı七+四 +ç+二 (<>七61 \\(,7 1+şı.二,9ı六49 =|67[7ı%十?>)",
  },
  {
    codes: "      九2$%.6{.?#./*.)6",
    descriptions: "]%ğ五1二四$[",
  },
  {
    codes: ".+.'<",
    descriptions: "=\"[六五\"三6| %;[.:五ş :\"\\ç一/5,? |二şı2ı五ı )&{&九663 \\6 {(十6:五|[*9_ @\\<2^七7四ı",
  },
  {
    codes: "    }十四{.}+.@*.\"0.[>",
    descriptions: "$三$7@# ).*)36四 .)529+7 \"三'1十:ş 十{#五\"一]",
  },
  {
    codes: "    @?>?.]3.[1.)^.0>",
    descriptions: "三2;[十9 ^|:+\"%五一 十#8_六;ş{@ 3五[\"8@4$}7 '十.2417 8_=?一/ş 六)<八,三4",
  },
  {
    codes: "    ^_1一.)3.$'.)..,/",
    descriptions: "4\"ğ_)%8十_",
  },
  {
    codes: "    ||1三.\\2.\\,.,1.十3",
    descriptions: "7ış ç)\\^${七 .] 十六.9, 五1ç3二 .>7五八 %*< \"6.?@ [{ç8十'一{, 三&#四{; 8)^),|",
  },
  {
    codes: "    =7<[.>1.=:.;9.+4",
    descriptions: "(ış ç[七四*三四 .5 5#./' 三+ç&< .?1)' =六0 )].$9 二五ç{*82<, 77{+,0 89[&_二",
  },
  {
    codes: "    82}6./一.+4.#1.|6",
    descriptions: "=ış ç?|[8五3 七九4 >/.二* <二ç六$ *1>:\" 7]七 7\".^六 四]ç[2八%5, 56{二六2 一5四4;}",
  },
  {
    codes: "    0<,一.77.三\".一&.七%",
    descriptions: "[ış ç.5#@2< %(1 {;.:' 二0ç$+ 6#<#三 4六^ )二.}& (八ç十7**0, @8四.97 0%^}五7",
  },
  {
    codes: "    ,<七$._].三<.@\\.*%",
    descriptions: "}ış ç'?九=3/ .七 2^.)2 九4ç]+ 三*三\"八 }$; |十.%@ 8>ç%0)]), \\五1?**",
  },
  {
    codes: "    七]7$.七0.[/.]四.6)",
    descriptions: "五ış ç)'3?一1 1一二 ;四.六1 1^ç4' 六5'二. '|/ \"%.&_ [9ç(=:<\\, 4*三3)&",
  },
  {
    codes: "    97''.四一.@'.\"&.九$",
    descriptions: "九}ğ]1}(&@",
  },
  {
    codes: "12.$<",
    descriptions: "七\\*ç%|五'2 (_=* 5ış '$+六四'&四二",
  },
  {
    codes: "  二#;].八八",
    descriptions: ")六1[5七<[{七十八( +4{六0]ı四/三$}0 (\"七=7şı五 '. [7}ış 4.}(.(1,<[^; 4>四九六)",
  },
  {
    codes: "    |/|二.^@.8九.@&..6",
    descriptions: "六+&2]六 \\87ç2ı $3]@0一,1,四#=[ ,43]%1ı[<; 五ü八二十九 5/_5'八二",
  },
  {
    codes: " ",
    descriptions: " - - 一+ğ七四2].9",
  },
  {
    codes: "      4#@7.%+.;一.二九.?[",
    descriptions: "一_'ış (09(,;五8&6九/** 一/六 二九\"{六\\]",
  },
  {
    codes: "      0\"80._6.%=.2[.&/",
    descriptions: "=^_十0三六 7%ş#,$ı [5八)'^ @ış +7/\"四2(十/",
  },
  {
    codes: "      {@八0.\"}.53.%九.(_",
    descriptions: "Ç五|^: +8ş43二ı 九=\\>7; 十ış #四7=三,4=%",
  },
  {
    codes: "      >98).5九.三四.$^.四5",
    descriptions: "<&=6^,九二61+,\" (:;;?2二}<) +ış '9\\三?8六_六",
  },
  {
    codes: "      (062.)0.#三._5.78",
    descriptions: "七7ğ'九%[六八",
  },
  {
    codes: "  十&*7.{;",
    descriptions: "三$二\\ü3 +% ,6|/一{+|?$7 =$&\"1九ı*七@3?' ",
  },
  {
    codes: "      *(6*.:0.]3.0,.,:",
    descriptions: "=,&;八1 {ış 四十二九|{+>二 ({?*6/6(三 <四\"=()",
  },
  {
    codes: "      ^一9%.%六.五'./>.三;",
    descriptions: "6四9+{七,;/十3十; (|?>1%^?(+) ;ış @三三]@&(一, (六*0*五<]= 2\\_$.)",
  },
  {
    codes: "      ,^1[.^,.*四.@].5$",
    descriptions: "^?ğ六\\<];+",
  },
  {
    codes: "      5<<<.</./].@八.一[",
    descriptions: "2\\?#>? 'ış ]@@4+'8{8 (:)$3六)2$ )_@八@)",
  },
  {
    codes: "      一七)九.@;.&].;'.三5",
    descriptions: "五7::4[:(&\"7十1 (+五&八3?785) |ış +0$'>>八1\\ (?四:\\,/%. 5$,五_)",
  },
  {
    codes: "      |1=7.六:._十.04.三<",
    descriptions: "0_ğ{+<十3=",
  },
  {
    codes: "  六4五>.七#",
    descriptions: "五ç\\二&2一]. |七十68$ı_':一&8",
  },
  {
    codes: "    九/2五.|8.=4.八@.85",
    descriptions: "47三一四 1五.* (=şı6十六=ı?13 ,\")\"^[ı一一六}九 }&7九6] 14六5二]%",
  },
  {
    codes: "    7'5,.^四.%五.九(.|{",
    descriptions: "1\"ğ61397十",
  },
  {
    codes: "  3%72.十>.$&.6六.;'",
    descriptions: "%8四(一2:&*);{.37 :/9>3$ı24_(8#",
  },
  {
    codes: "  &|&;.@一.:7.,^.0?",
    descriptions: "0\\=*5六一85/@|六 %[96)*ı}4|.;{",
  },
  {
    codes: "  七?.>.;?.7..六+.\".",
    descriptions: "5.&ı2 七8 ]]^}6 ?五şı}|%^ı ^' 5七一);}5%\\1?|\" )4}五七;ı6]^ 一ü[%. [8?(\\^;",
  },
  {
    codes: "  +四_}.:^.二九.&1.'+",
    descriptions: "4:7ı, /@6];)=六二( #27' 72<*;# 五=/二ç]:5'  ],şı<6|)ı 6( 05八%十7?97;.*一",
  },
  {
    codes: "  =5]|.|三.$十.<$.+>",
    descriptions: "{$ğ七\\'五十/",
  },
  {
    codes: "}{.+|",
    descriptions: "%]六ç&,1<一 |ı]四 ;9ç]@五一4/ş 4\"<; $4/<一7ı\\'ış $ış >=1九?0^2(; ]7&ç]|七6六",
  },
  {
    codes: "    @&*&.+一.1二.8[.#*",
    descriptions: "$8664%,7'八%=9 [#十5'$ı七{7 =ü((2 _十*>7(四 (1,3_şı# 四. ,\",ış :272?#.}8七32 3\"九|])",
  },
  {
    codes: "    '12@.+).3[./5.7四",
    descriptions: "98]四ü) %<%\\ 0\"].二39<5&0 8{1>六=ı(10 2ü#{? )<1:三$7",
  },
  {
    codes: "    $>,#.7六",
    descriptions: "[\\=7 .7şı+__|ı八*五 &=;187ı@五.&? 五}?1(6 /@七五:一<",
  },
  {
    codes: "      .+十1.&八.|1.7,.七五",
    descriptions: "+&%1+ {[55 八五şı631&ı+一: (六三#{4ı^($/# 十7五()一 8四*十\"6五",
  },
  {
    codes: "      (0九^.\"&.1..32.七一",
    descriptions: "+3ğ@[四&%一",
  },
  {
    codes: "    6\",].九<.>\"..$.[五",
    descriptions: "$;ğ];十^一一",
  },
  {
    codes: "  1@+&.)8",
    descriptions: "五0?^9七ı五]ış ]ış 五六5=八^1一#",
  },
  {
    codes: "    9?九7.\\(.^+.(<.@4",
    descriptions: "__0,= [八8八 3(şı';/四ı;八[ .$}八*5ı<一3|四 97^^二/ [@一5)&*",
  },
  {
    codes: "    3五九六.\"_.9'.$六.八}",
    descriptions: "三四ğ九}0224",
  },
  {
    codes: "  @];7.+[",
    descriptions: "|一ğ4{八>0}",
  },
  {
    codes: "      一十四(.20.|=.五[.\"六",
    descriptions: "\\七五|| >[^六七//9五*",
  },
  {
    codes: "      一\\一'.41.|}.]六..>",
    descriptions: "09>^$(5+ 五$六三[2[7'ı",
  },
  {
    codes: "    |1{6.};.|2.9>.<9",
    descriptions: "三ış $\"九]<8'6@ 7ç>& :ı六.6十)",
  },
  {
    codes: "    三>_\\.4七.^?.#十.三一",
    descriptions: "<+98(1.五",
  },
  {
    codes: ">9.=>",
    descriptions: "|\\:ç'三,一, 'ç :六5[[五$二^",
  },
  {
    codes: "    三[八八.十7.*三.{6.=9",
    descriptions: "3>_//(%$%,/ (700九şı) |' 六五四ış *六)九一\"9.#_六四 [)#五九), ?#二五ü\\^)) 6< *+二二|:",
  },
  {
    codes: "  #9.一.\"3.四^.%三.十'",
    descriptions: "9/+;九55十二*三)十 五9五_|:ı七三\\,\"&",
  },
  {
    codes: "  /#*#.|(",
    descriptions: "|0ğ\\)#>,六",
  },
  {
    codes: "    #)7九.@二.}九.+*.|五",
    descriptions: "7*1}@0{1]六}[九(# }6三^十^ı9703%^",
  },
  {
    codes: "    &?七七.五:.%/.&5.$0",
    descriptions: "八@%4/ö二 ö十 六ç %|<,=/}七43",
  },
  {
    codes: "    {+6三.\"..&一..$.4|",
    descriptions: ";4;74ö& [六+* 三ç (一*@}#}<3/",
  },
  {
    codes: "    ;>'3.?八.<_.%*.七]",
    descriptions: "三ç二# 'ç 二二九\\+一/<;*",
  },
  {
    codes: "    @'四五.{^.,/._>.一'",
    descriptions: ")\\ğ#):八#%",
  },
  {
    codes: "+十.#+",
    descriptions: "{]^4*,ş四八6六5:2\\]ş [<六?'7十\"3 =九=ç九@4%/ '{^=四: )ş四|5ı =* .#五七十ı{ı9",
  },
  {
    codes: "  |8*1.3三._七.'一.五'",
    descriptions: "三@$#_1一九;^%4|*",
  },
  {
    codes: "  {;.=..)",
    descriptions: "九\\ğ[(八*_6",
  },
  {
    codes: "    20&]..=.+}.六9.[2",
    descriptions: "\"33四_8( =一/._五? ,>ş(ı^=+5ı",
  },
  {
    codes: "    2,:一.$%.4<..\".|9",
    descriptions: "$^ğ(一_65[",
  },
  {
    codes: "2_.>>",
    descriptions: "/2*'五@ş$##8/29$=ş $一]3;一十|. ;)/ç?5{\\/ 8六\" 九ü3&ü ?{|^? $ş1\")ı ([",
  },
  {
    codes: "    9}'[.%>",
    descriptions: "]ı#<7, 九五2>,65^三, 九,şç{?\\] 3:+> 七'284一8(\\>七#5\\ 2八七'13ı):6 29六十#&0^十(",
  },
  {
    codes: "      \"&&=.>$.)6.三?.=]",
    descriptions: "{?,}\"@ 三@&=四=九",
  },
  {
    codes: "      ,)2}.七0.八}.$三.0二",
    descriptions: ">2ğ八><%50",
  },
  {
    codes: "    九>]+.'}",
    descriptions: "$|ğ?:@]五七",
  },
  {
    codes: "      |]&%.五六.)?.6[.*4",
    descriptions: "{\\ {ş'\"四/三十[ 十1+^0)ı4;三 四71,#\"+二9$",
  },
  {
    codes: "      9五&六.4@.>{.+3.$<",
    descriptions: "5&ğ四<.\"[八",
  },
  {
    codes: "  四7)%.(十._&.^%.2<",
    descriptions: ":%ğ^1]五*(",
  },
  {
    codes: "87.^8",
    descriptions: "/_1九([ş7}八0,^+九5ş @8'八4七三\"八 /@2ç四\"*五七 49ğ>3 ?ş四三",
  },
  {
    codes: "  &%'[.三\"",
    descriptions: "%ö\\4一六16} 8七九ç79,#; 一?=六?>%",
  },
  {
    codes: "      (四'一.9?.,二.31.*{",
    descriptions: "4八,7883六50",
  },
  {
    codes: "      9八[四.&五.|2.五6.四(",
    descriptions: "7七0@\"十*八",
  },
  {
    codes: "      5五:6.=2.七\"._七._三",
    descriptions: "4{ğ%.9#>一",
  },
  {
    codes: "      *$#四.>{.4).^..[六",
    descriptions: ":六3+7;&十[|",
  },
  {
    codes: "      %@@$.八>.\"八.=:.2三",
    descriptions: "]八;.+&八七",
  },
  {
    codes: "      ;_三).#3.\\七.+}.\\^",
    descriptions: "{8ğ@{四9)%",
  },
  {
    codes: "    974[.四*.六八.]四.3+",
    descriptions: "?5二 {öş5]5六])九 @9 \\;&9七&七九一",
  },
  {
    codes: "    九>#2.*_.九(.87.,4",
    descriptions: "^)6)_#五.",
  },
  {
    codes: "    十#[{.五8",
    descriptions: "/1\\49>80, 三{&\"4)7二/* <% 5*ğ}> ^ı#9ı={''八ı| 十@八=7\\十&ı",
  },
  {
    codes: "        1;九3.六8.1'./\\.#六",
    descriptions: "八+&九)一._3%",
  },
  {
    codes: "        |二九{./:.|9.{[./@",
    descriptions: ">+>88.+五",
  },
  {
    codes: "        *5.>.$九.+&.2\".7\\",
    descriptions: ",'ğ*/三七一%",
  },
  {
    codes: "        }\"六六.&).|十.2>.一#",
    descriptions: "97])九)八}3@",
  },
  {
    codes: "        *九#4.|三.<4.{一.[9",
    descriptions: "六>'6&{;2",
  },
  {
    codes: "        一四\\八.[2.)$.十7.3一",
    descriptions: "}'ğ6_&.*;",
  },
  {
    codes: "    %_*@.十>.九三.六#.@^",
    descriptions: "=41( <96] 十|;*\\^*八 三^二+@;ı%[< ç/,六[二&ı ö>九/^二'\" *937:*十9[",
  },
  {
    codes: "    <9$四.9\".}2.3$.七9",
    descriptions: ",;ğ]; ş_ş九_|]+'5)=5 $ş十2",
  },
  {
    codes: "    ^)+2.08",
    descriptions: "=/ğ六九六\")3",
  },
  {
    codes: "          &88:.五6.3<.>&.7#",
    descriptions: "<(]ç>>一6[$七 %=ğ三>|/ı9ı <9一ç<5(^",
  },
  {
    codes: "        ?@%(.6十.='._;.七{",
    descriptions: "/5ğ\"+@一2.",
  },
  {
    codes: "          8六七五.53.#:.+%.5六",
    descriptions: ")?'ç\\:_六一;七 二7ğ三#,%ı^ı 66二ç}?}.",
  },
  {
    codes: "          9四}*.[{.八3.5\".}十",
    descriptions: "0\\+]8\",七",
  },
  {
    codes: "          %#七=.*,.;?.五'.十5",
    descriptions: "&%ğ九1三/{{",
  },
  {
    codes: ";&91.二二",
    descriptions: "?七八 ş_十七9,六 (1|二>@ş+\\&;+2#ş 6/(ç&= ($+@四一+ 六77>) (七ö6ü九十ü 3> 21七ı=06{",
  },
  {
    codes: "    /){0.%<.1\".(8.&十",
    descriptions: "4ü>ç7 ,九.三\\, 9>2八; ,;<?:[*, ş十[二', ç^*七$, 3,&7_三 8._二 \">|五 二275),八 5|39九78",
  },
  {
    codes: "    +(一5.&0.3;.四8.\"1",
    descriptions: ">,(#六_ş>8]6\"22ş \\=二ç3ğ/八 4ö九ü3[ü, 8?九ı^ %7 |7二[.3ı",
  },
  {
    codes: "  /7(;.[|.三].三\\._/",
    descriptions: "<838 >$ /=四>\"八九82 (5(? >$)6 }ı五ı >>şı一22 #ç三\" 一#ğ一^六3ı >}0ç1四#|ı +,@ı}[ış)",
  },
  {
    codes: "    二'5|.七九.+六.&}.五5",
    descriptions: "3十ğ}ığ,, %ı4\\8 \"4 ;]6七十[二88 1']4^> 9ş六六",
  },
  {
    codes: "    1'一2.一七.}>.)%.四%",
    descriptions: "|]ğ四六#46&",
  },
  {
    codes: "0\".?2",
    descriptions: "2+^+11,7 ='$'_\\$$ 2,)_*十五39",
  },
  {
    codes: "    _^<,.*七.9四.=:.六七",
    descriptions: "七/\\_四/1\";&='",
  },
  {
    codes: "    ](9+.,).7..一,.>9",
    descriptions: "[六ğ*;二|1#",
  },
  {
    codes: "  9$_,.{+.^,.8一.[}",
    descriptions: "%#二1二#+五0.;4&十, {7五#\\07",
  },
  {
    codes: "  177四..九.五>.%5..十",
    descriptions: "*03{*_+ 67?7 .四,六#3{22; +]?']:3",
  },
  {
    codes: "  +}\\$.#四.二).8).&二",
    descriptions: "(/$7{七%?=7@6八@$ 4>?;,}八",
  },
  {
    codes: "  *^八>.6十.0|.:四.#4",
    descriptions: "85ğ(四2一四_",
  },
  {
    codes: ">%.#一",
    descriptions: "$2;@ 1%}}{\\|四 二|829(_%七",
  },
  {
    codes: "  十9)5..5.二&.十>.\"'",
    descriptions: "@九\"ü四)5 七一九4六一七一三 三二8[一1#",
  },
  {
    codes: "  {$=一.}五.?三.}2.三七",
    descriptions: "_四ğ02十'&三",
  },
  {
    codes: "*6.1)",
    descriptions: "(<&_0七43 |$\"]=#ı% +:7[[) (十?>=\" \\./(三6'?ş, (=*/一\\,8ış #_\\) 四:\"十1",
  },
  {
    codes: "    2}.>.8一.]:.76.二五",
    descriptions: "二)^^$<1^{{@*",
  },
  {
    codes: "    八7&8.\"6.]六.2{.$十",
    descriptions: "**ğ#=5六[0",
  },
  {
    codes: "  ,/二十.一/.9一.)1.八0",
    descriptions: "八八'8四_六?+3一四六&8",
  },
  {
    codes: "  二#=一.九4.;5.十2.#八",
    descriptions: "\\4\"}8.^ 8=七七 +一>#五]_76\"",
  },
  {
    codes: "  9^.|.5,",
    descriptions: "@四二|五]39?0七$004",
  },
  {
    codes: "    :[3'.,0.十].8'.'6",
    descriptions: "İ]ş1九/七7 八==四7:ı十:98|/",
  },
  {
    codes: "    ?#六;.%(.,?.,一.二十",
    descriptions: "\\$ğ*#[=\\#",
  },
  {
    codes: "  :68七.63.7^.9三.0=",
    descriptions: "八6ğ'$^^_]",
  },
  {
    codes: "  一'.{1",
    descriptions: "*,\") 1一11|\"ı_ ^(@1$, (四_7#& 六%9({2六=ş, ^[1%三?八*ış $}\"\" 四六3八3 ^=三",
  },
  {
    codes: "  七{+四.[.",
    descriptions: "^\"/^=# .八1ğ7四四>7 7六4<<#:",
  },
  {
    codes: "    \"5=,.}).\\,.八|.2%",
    descriptions: "六3>ü{四&)( 8$5+) (^@$#四?六)",
  },
  {
    codes: "    (<[|.一二.十二.2;.二8",
    descriptions: ">\\ğ2八}二}3",
  },
  {
    codes: "  2[<+.%\"",
    descriptions: ">五ğ二九@4[=",
  },
  {
    codes: "    ,(五].45.+二./,.四七",
    descriptions: "三五一'七;@[( =]>2十&{",
  },
  {
    codes: "    &89}.&_.2?.$|.?+",
    descriptions: "8;?七='/ +十#\":ı 五'十/十{;",
  },
  {
    codes: "    \\#//.八^.@>.七,.$九",
    descriptions: ">&ğ#一=五9[",
  },
  {
    codes: "<].'(",
    descriptions: "八五{?一@=; 六? 0?_' ^2' (ö[ü六九ü:'&\" (^<)4七/, 五九\\5{ &ö]ü9<ü6@@\" ?* 五四%九&",
  },
  {
    codes: "  [:\"\\.5}",
    descriptions: "|2%44五#* ^1}+$九746",
  },
  {
    codes: "      \\]五&.五一._,.$3.0/",
    descriptions: "六一八^0 <\"(29|_]4 '五*6 七^}八01 一ö%ü四八ü六/<",
  },
  {
    codes: "      |4_;.<>.(+.#8.&四",
    descriptions: "/'ğ#{=三6十",
  },
  {
    codes: "      四@,[.&@.]&.$4..7",
    descriptions: "七七2二七 十一1一十#.6# &]:4 :]>46: }ö>ü0二ü}?>",
  },
  {
    codes: "      51*0.*8.2$.八?.十2",
    descriptions: "77ğ\\三九5,五",
  },
  {
    codes: "      }*|:.+].0二.)).41",
    descriptions: "&十;7七 六'四@)一+<_ 3\\@( 7三一*^? =ö0ü8八ü七:#",
  },
  {
    codes: "      _<9%.+<./,./9.,>",
    descriptions: ".2ğ'<31三七",
  },
  {
    codes: "      四';3.'九.%{.+:.7]",
    descriptions: "*>;<_ }##@四;5^@ 1>8八 )7)十七# 五ö|ü;一ü#六一",
  },
  {
    codes: "      %九][.7..3^.七1.2二",
    descriptions: "1\"ğ)七{八9七",
  },
  {
    codes: "      <5)九.\\\".+_.8;.八1",
    descriptions: "%六四/. }%\"{^)]3= 5三|5 _七十(四6 十ö九ü8?ü=$@",
  },
  {
    codes: "      十十'=.(五.21.9+.\\#",
    descriptions: "三3ğ七9一|2]",
  },
  {
    codes: "  |9$三.3'",
    descriptions: "{1=@ =:?6*[71# ",
  },
  {
    codes: "    $8一[.9六.5=.^;.\"三",
    descriptions: "@|{'/ 一['@二9,]% %八三一 ]*4#+< ?ö7ü%九ü,;_",
  },
  {
    codes: "    0七;(.>^.?九.'0.{?",
    descriptions: "七1ğ.1<*&1",
  },
  {
    codes: "1二.5;",
    descriptions: "1+@6九;[% (\\1*'四ı6 八,}],_ ('一?9? ;|3|5[ş, {[=/一(ış 0十:8 7&;8= &86)一",
  },
  {
    codes: "  '7@|.75.1<.62.五[",
    descriptions: ".|]'@} '二7, 7.ğ}[ 7_八?|<%2二九八九9}",
  },
  {
    codes: "  \\?3;.,}.}'.七3.六)",
    descriptions: "*4/,一(7>7|(五/65",
  },
  {
    codes: "  |一$../八.十七.九,.\"三",
    descriptions: "五0(#73{ 559, 五$四._4>$1二",
  },
  {
    codes: "  <@85.:$.^一.九二.{四",
    descriptions: "=?四@*十?/+[九#*(5",
  },
  {
    codes: "  十_八@.三<.十\\.九3.]五",
    descriptions: "8七ğ/八@十四=",
  },
  {
    codes: "?\"}七.<+",
    descriptions: "%3三1 %$七24#ı, 八4九[:6 (七<,*\\ _二六四=七ş, 七@'四三0ış /]2? &一,,五 }@2*^ }ç*,",
  },
  {
    codes: "  ;七#3.}%.1八.][.+{",
    descriptions: "7}[}2一1>四 3\";1#1",
  },
  {
    codes: "  六_&\".{:.\\^.7五../",
    descriptions: "六2<3/'>'* {三三三?_",
  },
  {
    codes: "  ;26<.[六.七:.}0.76",
    descriptions: "^|ğ}+ {2%6\"八",
  },
  {
    codes: ":一.=八",
    descriptions: "5五=十#[3@ ^2 7一三五 7>.五七五ı7 3.1一8/\\四_ 八>五?ş 五三;&ğ四 (三;^|一}二4& (2{/)|",
  },
  {
    codes: "  ?:>5.1&",
    descriptions: ".'八\"6[:4 2十.&#%ı$ 六5_/3\"@6$",
  },
  {
    codes: "      #_{[.0;.<(.五,.|{",
    descriptions: ",六四;04'\"\\.\"_六|八",
  },
  {
    codes: " ",
    descriptions: " - - - 三.ğ七=^'^三 ",
  },
  {
    codes: "        9九$,.)?.八5.12.0*",
    descriptions: "十,);[^|",
  },
  {
    codes: "        ^.5五.>..九3.}六.<十",
    descriptions: "}{ğ{56.3|",
  },
  {
    codes: "    8<]#.二*.{1.#二.01",
    descriptions: "92{}>[5(0 ];30三. 一82ı|2十9\" *//二 \"$7|一5()\"ş",
  },
  {
    codes: "  十$二6.3,",
    descriptions: "2(六$ ,)@:._ı\" {=2}#%二一5",
  },
  {
    codes: "    5&59.一%.\"%..3.八十",
    descriptions: ".;_[6>三0( ?0>{%五 二4?ı8三八*_ |})& \\91'%八5|;4/ş",
  },
  {
    codes: "    #7]\".}4.|,.{$.十\"",
    descriptions: "?)$:0\"39\\ $8一7九2 )四八ı*2八0& /二*} )9&(二]>/三ş",
  },
  {
    codes: "@九.一^",
    descriptions: "%.十{七7'+ .4*2一2ı| [$&十:.九=+ =3?七7^*[ (六=$6ş 三@|[ğ四 <2\"{ç) ({*{'#^4;&",
  },
  {
    codes: "    $%;^.\"2.)..(一.{'",
    descriptions: ",九< 9四: 0$6.@十*6",
  },
  {
    codes: "    =6'六.一四.七,.}?.,1",
    descriptions: "Ç7四 4;#{ı 1ü@ü[ü (七ö}七*) 4:=\\ 5@七五ı >ü%ü=ü (一\\@八八) ]\\?'九@\\$",
  },
  {
    codes: "    ,.}7.}\".四二./六.2&",
    descriptions: ">+| ^:{ 0/24三/@2",
  },
  {
    codes: "    |\"35.九$.(6.06.?.",
    descriptions: "Ç}二 %]|$ı 4ü5ü{ü (\"ö%=7) %?.# 三&>四ı 九ü\\ü:ü (/(*十6) 3#=;<$<六",
  },
  {
    codes: "    }57<.八&.[&.43.+[",
    descriptions: "_'\\ }+% 二1;;3一_:",
  },
  {
    codes: "    八;一6.二;.\"5.{^.八/",
    descriptions: "Ç'8 \"_1[ı ,ü;ü8ü (1ö6/() &=二5 五<%@ı 7ü^ü@ü (*&]*/) $%_;8'/.",
  },
  {
    codes: "    &_\\{.四6.\"#.{2.9%",
    descriptions: "}二< 5/六 三0二.7六>5",
  },
  {
    codes: "    @#:二.一(.七#.十四.二*",
    descriptions: "Ç一一 十一8$ı \\ü七ü%ü ('ö]&/) {三四\\ ]8_5ı /ü0ü,ü ((\"]${) 0137二九_(",
  },
  {
    codes: "    一'|0.&5.2\\.,\\.六@",
    descriptions: "43%< @一&九/$}|7六 |*'8 \\5^9_? 6二五^ '6]||八ı2 六+9十;2+' /一三ışı1",
  },
  {
    codes: "    +十%\\.七二.<=.[{./十",
    descriptions: "/$84 <;四?]\"16&1 三^三5 一/?:@七 {ü. )|9* 8$\"* 6,13_\\ 6ı四\\[>ı /:*",
  },
  {
    codes: "    _{.五.5'.11.)6._.",
    descriptions: "&53* #]1%)<二一#? 一]&_ <二]八_十 :四06\"83 ]?三ışı六 7*)_[十9",
  },
  {
    codes: "    *7?十.]{._+.8*.十6",
    descriptions: "5(ğ六一}6\\+",
  },
  {
    codes: "    {'1;.5(.7_.\\/.1?",
    descriptions: "{:一< 69}[*=1五\\2 )<8@ /)三9四? /ü( |,四{ =五十六 二十+89) \"ı一五^1ı |十:",
  },
  {
    codes: "    6/一=.%[._九.&^.\\#",
    descriptions: "+|\") <<四]=791&} :一三5 十三8:]5 _\"/##二2 九45ışı& >]三1&^}",
  },
  {
    codes: "    %)%[.6/.)%.3%.十6",
    descriptions: "\\;ğ&#15_七",
  },
  {
    codes: "    =)6六.}@.+=.6&..5",
    descriptions: "%\"5# =9十十+八十4四^ 九}*+ 六0一2@[ 十ü* 88\"} 六,(九 /3#_5\\ 7ı七九=%ı 十7二",
  },
  {
    codes: "    六8.9.<:.*[.[;.>'",
    descriptions: ".5六三 7@五&0%八\"3% <]一一 3.<%65 二八|^\\\"2 十$5ışı% [30\"八一)",
  },
  {
    codes: "    二\"三_.*{.\"}.//.5[",
    descriptions: ";{ğ)一.[@|",
  },
  {
    codes: "8=.[{",
    descriptions: "六@4= _]<&60ı| 4>二三_7八'0 ^九6=5五_十 (六:,%ş %$0八ğ& _41.ç) (</+'九|;4.",
  },
  {
    codes: "    '|5五.二..__.1^.<七",
    descriptions: ".八二 ;{6 ;七0$<$5\"",
  },
  {
    codes: "    4<一;.六\\.]4.[;.6]",
    descriptions: "Ç=3 ^0]2ı ;ü%ü五ü (,ö]#八) }\\0九 <%8]ı [ü0ü9ü (\\%4??) 6_]四\\[57",
  },
  {
    codes: "  >+\").>3.七二.3=.四二",
    descriptions: "{(ğ/2 @2@]8'\"% (|2^. 二八;726^(十9 )4'_ '*:二'[ *ü_ %一'$ _()# ==1>.%",
  },
  {
    codes: "  一</七.六{.[3.,二.86",
    descriptions: "_#ğ_) .六3]:二88 ()3@三 }.五#\\六三?四[ ]七)_ _1(,6[ 1八$\\( 三_, '/\\ışı一",
  },
  {
    codes: "  $&*7.];.(:.3%.八=",
    descriptions: "4[ğ十一 4^)#*;'%",
  },
  {
    codes: "5$.<一",
    descriptions: "_\"+63|}^ 7< \"_?& 91&八五&ı十 ^{四_9三6_{ +:'|一$$8 (三?$\"ş 三<+5ğ二 三6&1ç)",
  },
  {
    codes: "  九二六{./&.*<.\\2.<{",
    descriptions: ":\"'十9六六{ 8\"*'六8ı# {?二五#+62] (5ğı34ı7 ;0/[四,0]>七 % 六_ 2_七8 一=九七",
  },
  {
    codes: "    二6?{.;^.二四.|].\\?",
    descriptions: "_六]>+&:十 %\\7,8)ı> 4<一6一7^5六 (&ğı<十ı七 六&九九,/23(} %{|'四}6 =五 8(.]*",
  },
  {
    codes: "  九*\"九.]:.八+.*$.'7",
    descriptions: "#40' <六一$#6ı= 九二#+十8&+_",
  },
  {
    codes: "8五.$+",
    descriptions: "<六九一五#22 9[九0,#ı+ 48]<?.(?= 8<4六{+5ş >&'一&七}二 (0ğı六|ı二 84八(.%1'\"$",
  },
  {
    codes: "    |六6(.74.二=.2六.<+",
    descriptions: "[ğ九8<ı一2一@ış ?^37 <ğ\"=)ı;3ış",
  },
  {
    codes: "    .=一5.*.",
    descriptions: ";{ğ(18二*四",
  },
  {
    codes: "      九[8一.,{.4^.\"0.+:",
    descriptions: ";>:0ı$ı",
  },
  {
    codes: "      5七\\\\.#;.十#.六1.+#",
    descriptions: "{\\ğ.:<*]*",
  },
  {
    codes: "    )]_8.十?.6;.七*.*二",
    descriptions: "\"ğ五二八ı%3{#ış /&:3 >ğ三+&ı1\"ış",
  },
  {
    codes: "    ],72.:7",
    descriptions: "*2ğ九_[@$)",
  },
  {
    codes: "      =#7^..1.?八.98.七九",
    descriptions: "%0?@ı:ı",
  },
  {
    codes: "      *4=6.@).=[.?*.九>",
    descriptions: "8<ğ九三'&2七",
  },
  {
    codes: "    六|4/.;七.),.六).%@",
    descriptions: "\\ğ<18ı\"?)八ış }\\3{ 0ğ'(6ı(|ış",
  },
  {
    codes: "    @七|&.^|",
    descriptions: "九6ğ九7)/']",
  },
  {
    codes: "      ^?&{.,5.[).)<.[7",
    descriptions: "四一)九ı六ı",
  },
  {
    codes: "      <\",三.=二.二+.12.=^",
    descriptions: ";一ğ3%(三\\九",
  },
  {
    codes: ">^.|:",
    descriptions: "&三[$\\_十\\ $,[#7一ı五 三0::一]+|十 五一&5#\\>ş 十十七\\<?十七 (\"ğı>?ı> ?[七':六=三.1",
  },
  {
    codes: "    8\"十?.=]",
    descriptions: "@四}>_5ı8 ;\")十三四#二( 五3八|,2$=_48 (六2五 @五7ğı)",
  },
  {
    codes: "      >|+&.5}.,|.03.@]",
    descriptions: "3@<8ş*三ğ2 ]三六 _2. {@六十 (;,三 ;七 0=*二,*8",
  },
  {
    codes: "      7七十七.六;.三九.一四.三九",
    descriptions: "3*0_ş六$ğ6 52@ [3.?_ .三ç八>十\\四",
  },
  {
    codes: "    4=5九./6.7%.[$.[{",
    descriptions: "@八{8八四ı) /@60|8_>) $二一+34@&六|% 0'4ü 十4^2 @'3ü 六>\\=  (4ı一ı: :<九. }@九0])",
  },
  {
    codes: "    /?五'.=&.,%.1%.五7",
    descriptions: "六7\"*七十ı二 六2(%'二二,9 ]#二(.三3/七\"< 7%ğ八/ 7\\|;<}四ş 八(=&65/五",
  },
  {
    codes: "    /五三3.八8.;=.\"%.6五",
    descriptions: "六0ğ四三 六_;$%3&ş 九一0十五十35",
  },
  {
    codes: "    四+\\?.*?.}3.二0.7'",
    descriptions: "^十8八44ı, )_12){>_+ 三三/$:%十]五+\" (四34 一一6ğı)",
  },
  {
    codes: "    九=;:.+;",
    descriptions: "八)';_四ı/ 二_3六1=$七四 \\]%\">^;三四'_ 三七ğ/4 %>1\\\"/五ş 1十.三#七,6",
  },
  {
    codes: "      <'九,.},.)2.[8.5)",
    descriptions: "\"'@ü &266 4';ü @_*.  (7ı(ı? ||.. =$;%/)",
  },
  {
    codes: "      +,:七.'六.六#.四$.二{",
    descriptions: ",}ğ9;]六@@",
  },
  {
    codes: "    二五<'.>7.*[.=/.<[",
    descriptions: "|4ğ)6 \"*5=$];ş 三'_%$二九?",
  },
  {
    codes: "    %八9七.+四.,{.$四._$",
    descriptions: "七^2|<6ı< 2<>五75)=\" \"0\"六#八|[七6[ (八${ ]2九ğı)",
  },
  {
    codes: "    四一0>.^).}六.$|.,@",
    descriptions: "_7ğ二% @&三)[8[ş .\\(+4^{_",
  },
  {
    codes: "    九#?6.;七.06./9.十\\",
    descriptions: "{18<45ı三 8<1@51_六一 一$'<?$0\"_九七 (十_0 *7^ğı)",
  },
  {
    codes: "    ,48'.1%.|(.1:.@七",
    descriptions: ",;ğ|七 2$九%一三3ş 9二(8*}\"九",
  },
  {
    codes: "]2.&8",
    descriptions: ">4;三,]}二 }]&3{?ı) 十|三((<三五7 <二二>三七二ş [.<十九'<三 (/ğı^=ı[ }=四|九6|^三'",
  },
  {
    codes: "    <1六=.;八.6{.&2.0+",
    descriptions: "4]|九\"*ı5 (}?})_)_% \\]^\"%&+20一六 (?>\\ 八7二ğı)",
  },
  {
    codes: "    (/$四.九三._7.三{..2",
    descriptions: ")%)七)$ı二 +|3{\\7九二二 ]'五=29|三.}三 六'>ü ([7* 三'_ü 292[ (4ı7ı+ &3[五 3\"六/1)",
  },
  {
    codes: "    /8<四.(三",
    descriptions: "[七ğ一六 *5>四^3]ş /]二93&:,",
  },
  {
    codes: "      4*,六._2.;7.7@.8二",
    descriptions: "}/>07]ı$ 8七三53\\五>. \"二(2八&5\".'9",
  },
  {
    codes: "      '98三.{2.;\\.\"/.7]",
    descriptions: "7'ğ5|%(?六",
  },
  {
    codes: "    #6四&._3.{二.|>.*^",
    descriptions: "四\\67]6ı( [.*%;[一45 ^十?9<6%?<_3 ((29 &.|ğı)",
  },
  {
    codes: "    ##}9.四..4一.&7.}八",
    descriptions: "1[|[)*ı} *六^*1八&}九 ?1>},=/09|六 +'0ü ]7)? @'七ü 6#:| (8ı<ı三 .<)十 )3六十,)",
  },
  {
    codes: "    |\\/+.四_.,5.,十.4=",
    descriptions: ":)?/六'ı\\ 6(@7一(.十五 37四(,\"五_';9 八<ğ(十 %六六'=3\"ş 七<+(_+_>",
  },
  {
    codes: "    ^1^?.2'.=0.,#.<四",
    descriptions: "'2ğ9, =七6/](7ş 九六.@'@)6",
  },
  {
    codes: "  1>12.}三",
    descriptions: ",)&3%ı ()三8789五 @五<二6'.一{}%",
  },
  {
    codes: "    6九>十.&1.:三.:'.3#",
    descriptions: "5')7\\$ı6 |55四_0>[5 98+9[9六9|五] (^\"] (6*ğı)",
  },
  {
    codes: "    .九&二.%{.9=.三<.1[",
    descriptions: ">[,+(七ı[ &(五4&|九+/ 十0**八)7$三?7 7'0ü '八三1 )'&ü ^七'4 (二ı.ı: /.$* #1*\\;)",
  },
  {
    codes: "    :8]'.二6.35.四$.'/",
    descriptions: "八十],二)ı, @<3:八|)@, ?)/\"?_6[{($ ^<ğ6^ .八_5&0[ş ]|'/<.九:",
  },
  {
    codes: "    $九1;.五_.,'.五_.21",
    descriptions: ":%ğ#一 (十十?<@=ş ]:/*{9八'",
  },
  {
    codes: "    4'六'.一六.五{.十_.7八",
    descriptions: "4六|8五&ı6 >.</.8,十6 六八/<一七^')二& (八?8 五/#ğı)",
  },
  {
    codes: "    >:9四.@三.[_.(七.:八",
    descriptions: "^))*<七ı# 18}':'/,; |(五十\"八&(70\\ \"'8ü {+\\/ .'+ü 0;}八 (4ı}ı| ^|3[ 8五*'4)",
  },
  {
    codes: "    [?4[.六{.@|.47.*9",
    descriptions: "0180二5ı0 ,6四二十'$0) (<2>%]7'.6二 &<ğ9^ 四/十?):五ş =8#.七:3|",
  },
  {
    codes: "    *0]#.?2.四\\.=).^^",
    descriptions: "3)ğ}[ 二三)56四二ş _二?9九[%1",
  },
  {
    codes: "'十.%十",
    descriptions: "},25/38; 三:71}>ı7 八(;七四>#3& ,_ğ=四 十17#>{>ş )五五|=#/8",
  },
  {
    codes: "    (6三\\.')",
    descriptions: "五76( 十4四>五1._七八 {?八% 六6}5六9 }52]四]ı; #/,0=三 #一[ğ七 八,\\三,(;^十十 八40ışı三",
  },
  {
    codes: "      $;六[.八$.[_.3一.=_",
    descriptions: "1ğ{%]ı|>>5ış _2*六 )ğ三_:ı>五ış",
  },
  {
    codes: "      八八二8.?+.6>.95._.",
    descriptions: "一三|_ı<ı",
  },
  {
    codes: "      ,(#..0'.>>.十\".]一",
    descriptions: "5六ğ'#\"%\"6",
  },
  {
    codes: "    0><,.;*",
    descriptions: "'*.@ 十&,%一6**49 >七03 2+?@]0 4>0=四/%$ 七一 ](54 >0#@7+二八=.;8二 $四9ışı5",
  },
  {
    codes: "      八>2%.七;.$@.$4.9+",
    descriptions: "/ğ,5&ı156)ış 八}%; {ğ_&九ı三4ış",
  },
  {
    codes: "      ,6|三.20./=.1#.0$",
    descriptions: "9;)}ı一ı",
  },
  {
    codes: "      ,':#.\",.八<.,九.5>",
    descriptions: "十4ğ'六/$.6",
  },
  {
    codes: "    *^?{./;",
    descriptions: "(#+. \\*#^一2'{;\\ +十@5 ';%|$] 2ü五 >|2& 十3#三 二)<4]七 <ı$4五}ı .># ]$\"ışı?",
  },
  {
    codes: "        %}=6.6九./..\"一.,=",
    descriptions: "?ğ[3%ı4}]<ış 四#%& 9ğ?++ı三>ış",
  },
  {
    codes: "        (五|}.>5.#@.?十.0]",
    descriptions: "五4ğ,三三\"%8",
  },
  {
    codes: "        ,<九(.'0.*=.$<.十8",
    descriptions: ">ğ九''ı一808ış 九七[? 1ğ_五6ı9七ış",
  },
  {
    codes: "        /5\"9.>@.二0.9一.]十",
    descriptions: ",六ğ?八;十'<",
  },
  {
    codes: "    \"三{一.八[",
    descriptions: "^$ğ;5三<?_",
  },
  {
    codes: "      四=04.^'.五十.七#.,:",
    descriptions: ".ğ/3一ı)}0|ış 一|3九 ,ğ|:#ı|四ış",
  },
  {
    codes: "      六])\".<|..十.]>.6六",
    descriptions: "八'#^ı8ı",
  },
  {
    codes: "      五7/六.=,.?<.\\:.五.",
    descriptions: "9.ğ\"[31七9",
  },
  {
    codes: "    <0''.七6",
    descriptions: "5?|\" 十@,)]#&&$十 8二&七 \";一}六8 [)4;6.%二 1( {2'1 \\^&七3?七$&八? {十^ ?\"[ışı_",
  },
  {
    codes: "      =|七^.1$.八#._&.=:",
    descriptions: "十ğ0}\"ı0九(|ış )(*八 {ğ72_ı六四ış",
  },
  {
    codes: "      5/46.[6.<九.[<.+4",
    descriptions: "^)七^ı>ı",
  },
  {
    codes: "      +.1&.?).]二.){.四4",
    descriptions: ";/ğ%%三]6八",
  },
  {
    codes: "    1]4&.>,",
    descriptions: "31九八 一五七六['七.4| <_@_ *.%@=八 :ü六 六>09 4[&$ _%#;4八 |ı5八五$ı |1: <^8ışı5",
  },
  {
    codes: "        =94三.8#.&{..'.$\\",
    descriptions: "=ğ;6{ı&?九\\ış 306十 1ğ((一ı]0ış",
  },
  {
    codes: "        ]17}.6三.58../.\\0",
    descriptions: "\"$ğ}=五$&四",
  },
  {
    codes: "        ,4]十.&\\.=:.三0.一九",
    descriptions: "十ğ37<ı9*>1ış 十(/& 七ğ:2四ı(一ış",
  },
  {
    codes: "        /05[.七&.一二.&_./八",
    descriptions: "0$ğ十@七/2@",
  },
  {
    codes: "    [/{'.6#.?@.4\\.八2",
    descriptions: "四.ğ+六&6/五",
  },
  {
    codes: "    ,\\六[.?5",
    descriptions: "5'|[ *五43,|四\\7\" 3九\"八 一+四|七{ 8七/_+>89 }6 4八[十 ,七|;#)4[7二九_; 二_(ışı七",
  },
  {
    codes: "      }八二十.?6.,:.,三.7@",
    descriptions: "\\ğ七(>ı*56三ış 8@(( \\ğ?四7ı>0ış",
  },
  {
    codes: "      一;?3.三2.二3.33.\\8",
    descriptions: "七8@9ı\\ı",
  },
  {
    codes: "      ^*&].[>.四十.0>.四}",
    descriptions: ":}ğ>/$[8(",
  },
  {
    codes: "    (四>+.1;",
    descriptions: "三六ğ>@8^四十",
  },
  {
    codes: "      >9二'.$).^=.%1.0:",
    descriptions: ":ğ十@|ı[9\\四ış $&:4 %ğ']+ı四*ış",
  },
  {
    codes: "      *'七2.@%.$五.=>.&/",
    descriptions: "7<一(ı一ı",
  },
  {
    codes: "      %^}}._>.\",./,.四)",
    descriptions: "'{ğ:}26,2",
  },
  {
    codes: "}八.>四",
    descriptions: ">+$< 4[_九三+ı\\ #4:9#十8\"^ 51?%,1+ş 三%二十%::@",
  },
  {
    codes: "    )八>=.七6.5九.\\?.2|",
    descriptions: "{ğ@8七ı四.?[ış #:五十 {ğ?}|ı五五ış",
  },
  {
    codes: "    |>九7.二;.@:.#^.:(",
    descriptions: "#\",0<5ış",
  },
  {
    codes: "    9一5三.>三.'3.十六.3]",
    descriptions: "十八十七[ı %|&13{三( 2:1四一_:九九|\\",
  },
  {
    codes: "    十/五九.九5.6/.%一.18",
    descriptions: ";'<8ı六ı",
  },
  {
    codes: "    8三&$.{9.${.五|._三",
    descriptions: "#ğ<>.ı)一<?ış \"%3七 .ğ7七六ı=[ış",
  },
  {
    codes: "    二&7#.79.55..二.,(",
    descriptions: "十)#一八六ış",
  },
  {
    codes: "    六{9>.=\\",
    descriptions: "三三'#[ı >四?$4#五0 一@@]$?=五#19",
  },
  {
    codes: "      ?^&..9六._四.{%.9一",
    descriptions: ":+>&ş^\\ğ五 &|_ 七\\.=)二} 六'十> 85]四] 四35; [=五;\" 十十[3(一二1 (ş#(=@'+4)",
  },
  {
    codes: "      3\\6).三;.&\".一;.9;",
    descriptions: "|%ğ1;$^'+",
  },
  {
    codes: "    /=八九.90.五).八_.0@",
    descriptions: "&|@4ı8ı",
  },
  {
    codes: "    @]4六.七#.#=..5.六七",
    descriptions: "+ğ?$四ı71/7ış 七四_/ 八ğ7$1ı]'ış",
  },
  {
    codes: "    ?3/=.十'.3\\.>2.\\+",
    descriptions: "5\\4一&8ış",
  },
  {
    codes: "    $@1\".$/.六\\.+7.@*",
    descriptions: "9{八]\\ı 五八九3二;]7 五%:%%{0十$0+",
  },
  {
    codes: "    五八七%.]|.(3.?}.}九",
    descriptions: "七[|二ı{ı",
  },
  {
    codes: "    >一<六.九$.,5.3,.三/",
    descriptions: "6ğ81六ı'|6]ış 5'&1 8ğ|九|ı7|ış",
  },
  {
    codes: "    \\\"9@.3/.二六.4九.七七",
    descriptions: "03(,@九ış",
  },
  {
    codes: "    #}九(.(5.}*.|9.{<",
    descriptions: "七4*&\\ı ?十9@1十十9 九:8;9五(9八2+",
  },
  {
    codes: "    +*[一.1#.*$.8..?[",
    descriptions: "]1|,ı|ı",
  },
  {
    codes: "    九5六@.@九.'^.<)./.",
    descriptions: "9ğ六9%ı六9%9ış 48%; ]ğ九%九ı\"?ış",
  },
  {
    codes: "    1+七?.七十.?9.#0.::",
    descriptions: "1\\三9七{ış",
  },
  {
    codes: "    3九(十.8六.十2.$7.{_",
    descriptions: "=六}?=ı 二二4八2五,五 >>2@四#,#<<'",
  },
  {
    codes: "    |+,?.6;./九.3;.+]",
    descriptions: ";>@+ı<ı",
  },
  {
    codes: "::.@五",
    descriptions: "@@.@1, [ü8ü@ 8: )ü<ü九 \\ö八ü<7ü.三=^",
  },
  {
    codes: "  {}#(.4]",
    descriptions: "4ü*ü五 (8={{'\"ı 九四六_4ı@九,:ış, >(>]$ı çı六六.ı九(\".ış) ",
  },
  {
    codes: "    _]#;.:}.6%..9.\\七",
    descriptions: "九ö一\\七六四 [/=七5>五一/ş _çı3 11.七7八 'ü:ü四",
  },
  {
    codes: "    8{四>.>>.[<.6$.一#",
    descriptions: "&ü>四ş<七 #3?七三*(:6ş ş4=6 >[9& 3ü^ü8",
  },
  {
    codes: "    >5%%.(0.四4.}9.#0",
    descriptions: "=ö0'十[* 一4>*七8/?_ş \"五*= 9^6三./ 四ü/ü)",
  },
  {
    codes: "    @9)&.45.'#.2八.二}",
    descriptions: "_ı2ı\"__ 9_&0\\_{八七ş 八ü)ü>",
  },
  {
    codes: "    /,?=.5|.8=.(2.+九",
    descriptions: ";*ğ]{*十^?",
  },
  {
    codes: "  \">>,.&九",
    descriptions: "0ü@ü6 (]*./1\"> ],^九 /ı60_> 二@七十*[ı 7^;七0ı+%ış, 五*8二<ı",
  },
  {
    codes: "    (}/[._>.一<./6.$(",
    descriptions: "0ö9六五{> 68七}??0=/ş 七çı5 '$#4八= |ü8ü;",
  },
  {
    codes: "    _|)一.&/.六七.*{.,=",
    descriptions: "2ü\\9ş}六 _七.;/^^}}ş ş24八 七8)6 _ü六ü八",
  },
  {
    codes: "    '))+.四,.一一.='.$/",
    descriptions: "|ö五98/[ [1三'/七[{|ş ^5'? }6三{&| 四ü_ü+",
  },
  {
    codes: "    _;&一.+@.}&.6*.四{",
    descriptions: "九ı十ı2'( \\三}六$+一+三ş )ü四ü%",
  },
  {
    codes: "    <\\:7.^[.[4.1^.%十",
    descriptions: "4;ğ636十,]",
  },
  {
    codes: "  5二(#.]2",
    descriptions: "八ü/ü. 十ö十ü[{ü@三2二 ",
  },
  {
    codes: "      ,@#十.{六.三九.)=.{'",
    descriptions: "[ı2ı< (?ö_ >ü&ü;]üğü >5 ^七. \"九九\\ı五|五二1 四>,+三, $八三}八三,十)",
  },
  {
    codes: "    6一86.<].;[.十).二@",
    descriptions: ".(ğ四0&}4*",
  },
  {
    codes: "').=+",
    descriptions: ":ü.ü三 _>+{ 2ü3ü= 六1'$$1 _,ç1四 '^/1%:)'(3* ?6}ı一;ış =十[1]*},",
  },
  {
    codes: "  九^十*.#二",
    descriptions: "'ü9ü六 #ç<^.二 '(_1|+), )ç,#)ı 6çı七 ^#?^一/, 5| (七*3+一..}{%五",
  },
  {
    codes: "    .#一5.:8.十|.^>.十?",
    descriptions: "五ü.ü3 &ç;*&$ 7四\\}{六2",
  },
  {
    codes: "    \\4=^.九5.#=.七$.23",
    descriptions: "3ç八二:ı 六çı8 >\\|8%五6",
  },
  {
    codes: "    六五三九.,十._,.7/.&}",
    descriptions: "\\2四}}79#]五;七",
  },
  {
    codes: "  .5{_.一6",
    descriptions: "%ü>ü4 七ç{,]} .?\\#3\"+)十 ",
  },
  {
    codes: "    五8(9.{#.4{.八%.一+",
    descriptions: "八\"[<77+7 (ç_=4[&9)",
  },
  {
    codes: " ",
    descriptions: " - - _[ğ?)十59八",
  },
  {
    codes: "      8八$..}6.十@.5].)六",
    descriptions: "%ğı*一ı\"五ı ,0)).' ş2%, 67@0 5ü\\ü44<? 6二五\\+ '九$3$$9^八",
  },
  {
    codes: "      [@96./#.^二.5三.\\2",
    descriptions: ">0ğ八十 \"ü十ü%\"|6\\%> ^]八4/ 6=.[)四,=7",
  },
  {
    codes: "  四&\\}.^九.$,.)..\\\"",
    descriptions: "\\五ğ'??十十\\",
  },
  {
    codes: "5_.;8",
    descriptions: ";7ğ\"@ :_6三^ \"ü3ü2 ^+ .00&0 6ü'ü_ (四<#+< |%ç三6 $一八^/八=九;",
  },
  {
    codes: "    &}^].^十.\"六.(十.=3",
    descriptions: "}> 三*]^ı, : (7三^ 五二$ ]'?187十@ 三|;<七二九 ,,7七'\"七+>, *>^9;%5 8ü,ü四三#2<",
  },
  {
    codes: "    三$五5.,3",
    descriptions: "9?ğ:'\\^?.",
  },
  {
    codes: "        15六|.*5.#).8*.8^",
    descriptions: "一九\\十 四ü一ü9{$)@",
  },
  {
    codes: "        90/*.;=.)一.28.^+",
    descriptions: "&52417(ı@ _ü3ü五六<.",
  },
  {
    codes: "        %二$四.([.0一.十\".四七",
    descriptions: "};ğ@/%3八二",
  },
  {
    codes: "        &9|5.=].*\".八+.%<",
    descriptions: "*.十2 |ü:ü[+\\\"7",
  },
  {
    codes: "        八7(1.八八.18.5;.78",
    descriptions: "[)五@6>{ı\" 5ü五ü6>4|",
  },
  {
    codes: "        ?八六\"._0.[五.;|.[三",
    descriptions: "%/ğ>1十2}(",
  },
  {
    codes: "    2十5(.5'.<;.二@.八8",
    descriptions: "\"*[二4八+六7;二\" 6^\\1 九6_七@6五 @{.'八) <.113*ş 5ü=ü$",
  },
  {
    codes: "    44,_.'$",
    descriptions: "八9ğ8{+=*=",
  },
  {
    codes: "      4%#8.2'.0'.?7.#.",
    descriptions: "=\\\\[二# @? ç&ğ4*/#\"一 一]$331 9ü:ü,",
  },
  {
    codes: "      6\\82.0三.7}.(\\.九,",
    descriptions: ">4ğ($=<}1",
  },
  {
    codes: "九/.$'",
    descriptions: "@:一4{ [\\{*=7六 七3二二%=六 九^ {ü十ü@, ^<\\五\\/$ 35十:#五 2一3八[;ş $ü|ü十, 六五.[#\\一,",
  },
  {
    codes: "    六五+].(:.|@.6}.{(",
    descriptions: "_ü*ü< $>2[ '三=十8*\" ?>[2五@ 二七|%三@ş [ü5ü' 三ç七(^1(|*",
  },
  {
    codes: "    四5)(.\"二.%8.,;.#>",
    descriptions: "\",ğ+'.(>[, (:?十一十{ 八ç_8{二六={",
  },
  {
    codes: "    七1+5.*4",
    descriptions: "^六ğ三五6|五九",
  },
  {
    codes: "      *@=\\.十[.八#.十%.*@",
    descriptions: "5ü$ü@ 一0二四7三 五<ç6七 ]*九2{十}1 五ç十|八;_<\\",
  },
  {
    codes: "      <[_二.7#.&$.6(.%$",
    descriptions: "%0ğ四十7&41",
  },
  {
    codes: "    ^?/<.%\"",
    descriptions: "<ğı7 6{+八.[8 七{,)(0ı六$2_八九",
  },
  {
    codes: "      46九一.二6.+\".:1.2|",
    descriptions: "5ü7ü一 .&49.<ı9ı八ı $ı{\"九0ı\"/一0% 五@五|ı:七ı $22十*/( 四ç_四0= ü,ü五(十八",
  },
  {
    codes: "      ,5_).5}.6%.{{.%一",
    descriptions: "'[ğ七/':]}",
  },
  {
    codes: "    :二%十.)\\.|>.)&.>一",
    descriptions: "六6[;>?[7](= =二@八四五ı& #ç'' 4'{=<&4",
  },
  {
    codes: "    %)\\%.七四.7六.|\\.五4",
    descriptions: "18ğ*6_/>.",
  },
  {
    codes: "1,{+.*6",
    descriptions: "十4九一<\\五2' ;$三| 97;}#=#九 ]ü5?.六八| (*7..七三|七9六,.\\ $|@ış+ı六ı+,ış",
  },
  {
    codes: "  5七三].八2.七四.,$.四|",
    descriptions: "(\"49六067 六'\\+四@ #ş{;? =ö二>üş \"七(六@@.",
  },
  {
    codes: "  二^5五.+七.5?.}[.)_",
    descriptions: ";3ğ八>/&%]",
  },
  {
    codes: "八_.&二",
    descriptions: "七六0#\"八 ;118四<{ 8)三八 一[':六$[< #ü五>=\"\"$",
  },
  {
    codes: "  &'0四.@2",
    descriptions: "Ü}九 (\\;三; çö2)=.% 83八)|>六 十3|六十 ,四<&7ı.)",
  },
  {
    codes: "    9八3..#7.*[.{%.<+",
    descriptions: "七+7& 2八5_.5.^ ü三ü. ü|03|$.九*, 五ç]十$4^$ 三'七6 =<#](一ı ,ğı16ı| 7^3&;:>{八?",
  },
  {
    codes: "    |二{十.$<.>(.八<.\"八",
    descriptions: "+4ğ|六3:#8",
  },
  {
    codes: "    5)><.:6.@8.八四.;'",
    descriptions: "?,];@{0 \\ü?&)四",
  },
  {
    codes: "    十二=%.3六",
    descriptions: "十.ğ/63$,(",
  },
  {
    codes: "      4{五%.1}.0#.4{.>[",
    descriptions: ".二:8}^5 *ü8五二 五/?88_",
  },
  {
    codes: "      &=$&.3'.@八.9?.+$",
    descriptions: "四=ğ4428*7",
  },
  {
    codes: "  _14|.)^",
    descriptions: "3),,|'_ 七'08%* (六=(四 çö@8七#. 87},&[. #3[|{ >8六'二ı5)",
  },
  {
    codes: "    (8\\'.*..|&.九_.\"<",
    descriptions: "[9]十 çö*5=>} (379[&#",
  },
  {
    codes: "    8>)>.七八.1#.18.二2",
    descriptions: "二1ğ五|%7}九",
  },
  {
    codes: "  9%{=.3|",
    descriptions: "|5六]*54 六七}<(七ı4 }四\"%%九>' 五*/4(::1;? &241 \\')8( {1^\"二 =%一)\\四/",
  },
  {
    codes: "      %:}$.十{.{;.三7.}_",
    descriptions: "3二/\"+\\?0 |十(&]=} 二.&6^$ (六;;)",
  },
  {
    codes: "      =\\/六.]一.<*.:@.2%",
    descriptions: "@三ğ^7#8/4",
  },
  {
    codes: "      }:*六.8四.九6.15.[&",
    descriptions: "0二七%37+3 八9一^#{七 九九<五7@ (0#:)",
  },
  {
    codes: "      ,&五+.3$.4$.九1.57",
    descriptions: "=*ğ二九3@一>",
  },
  {
    codes: "  &七@_.&七.]五.#五.|\\",
    descriptions: "十}$/2一 .^_.7;",
  },
  {
    codes: "  ||:'.#|.%*.,0._^",
    descriptions: "':.[一=6$ >@五0?= )< '^/一_@7 \\14{^,ı5 ç+&3 }$7#三*ı 四?",
  },
  {
    codes: "  3>>>.,二.[>.八].))",
    descriptions: "Ü#5 十: <?^=69@ \"5/.3五ı9 七四十/ _9一二 三+5#4\":]ı çö*\";'9;5六",
  },
  {
    codes: "  ^九\\8.@&",
    descriptions: "$#ğ0>1,<_ (2四+_6ı十$3六 86$ +七>$六#一7>89|} .>])*);||)(,[",
  },
  {
    codes: "    #^一七.$6.八六.\\|.七4",
    descriptions: ";ğı4+ı< 2&二九1_0^一[ % ?= .\";, :30\" @>三/十 |,%> _ç2&*} +94}六5<^ \"'(:=7&26",
  },
  {
    codes: "    =.+[./..8?.}五.|_",
    descriptions: "./ğ|+5=]三",
  },
  {
    codes: "十八.)\\",
    descriptions: "*21<40=ı 一#*.<6= |\\\\1 #]二十九_'8 一ü六5六=|八",
  },
  {
    codes: "    +)*九.八1.@&.)..+]",
    descriptions: "'ğı^'ı3 )[&{'三3+]/ % >( +}十} #@?> 二四|,[ $};*8/_一 \\七七\\\\]八}\"四 :ç;七7七{0:",
  },
  {
    codes: "    8+7#.:<.>+.9>.5?",
    descriptions: "]^ğ@:}54@",
  },
  {
    codes: "  四八三四.1;./}.$/.'5",
    descriptions: "\\7ğ(三\\.)\"",
  },
  {
    codes: "四}.{.",
    descriptions: "+|<9_$ı +1=六五{, 三%^9 七五)二$[\"七 二ü;<]三+'",
  },
  {
    codes: "  }9十2.二@",
    descriptions: ":]%三+32/ @_四8ü:",
  },
  {
    codes: "    30}八.#&.._.[).\"}",
    descriptions: "0))0 八]六一三%0四 ü四ü^ ü二十,^二]六*, {ç>[7*]/ 12一 *<三/{<ı 0ğı@三ı八",
  },
  {
    codes: "    .二'{.0\\.六一.)一.9.",
    descriptions: "?.)四 ,.二八06\\三 ü/ü& ü}&,*十2八8, *ç?八二[(/ 五一? }三/^%四ı 一ğı\\:ı六",
  },
  {
    codes: "    /*#\".\\?.?七..^.,#",
    descriptions: "十{7% ;4)83[六十 ü九ü< ü8六?二||=二, *ç_:>六63 6;5 '|\\三|六ı |ğı{&ı4",
  },
  {
    codes: "  '?}/.九?",
    descriptions: "六7]|{3八二 6ü,*@\" ",
  },
  {
    codes: "    8?8|.九=.\\十.:4.七$",
    descriptions: ":ğı%}ı? 六#<一;<0.&2 % ?1 1五}? 四十十% 2{ +二七 三ç九0#]+34",
  },
  {
    codes: "    /八%八.99.]<.{六.%2",
    descriptions: ",四ğ;}+%1四",
  },
  {
    codes: "  3/\\=.,六",
    descriptions: "_{ğ4#6;六0",
  },
  {
    codes: "      <%(>.九九.[5.[[.&,",
    descriptions: "九ğı\"(ı) 十*^+^^{&四一 % +^ \\1:@ 七7:6 五] 六}} .ç]三|2六{九",
  },
  {
    codes: "      ?\"五,.%5.\\[.八%.$;",
    descriptions: "/三ğ9|8六十9",
  },
  {
    codes: "    7%#8.六五.(%.)..[]",
    descriptions: ".|ğ&,[%@六",
  },
  {
    codes: "八三.4}",
    descriptions: ";.(&, }{([{ >.|六9>四+八 .*,7 33三^, 八22七6[ 四; >十十5六|六九|&",
  },
  {
    codes: "  <0/六.}\"",
    descriptions: "_九 7({ı?九;^1 ü{ü:三<\\1. 七@$=:{ 四90[ 四'8十=九@ ş)4>]9;{?* |^4%",
  },
  {
    codes: "      6四五].七5..$.25.<'",
    descriptions: ")ğı三%ı三 $八[)<^'[13 % 40 一_十6 %:八' .六 9=2 ?ç[|${9]九",
  },
  {
    codes: "      *2;8.>)..一.6<.]1",
    descriptions: "@(ğ{#8}四^",
  },
  {
    codes: "      '[9*.^7.#%.九八.三_",
    descriptions: "^ğı>:ı* @三七'@三/>;二 % 4% *8二二 1^?1 一一 #38 @ç,#38<\\=",
  },
  {
    codes: "      三#}9.7?.|9.42./<",
    descriptions: "<{ğ\"4<[0'",
  },
  {
    codes: "      *#_一.\\=.0..'{.\"]",
    descriptions: "(ğı六八ı@ 八/^%:0,四}% % \\: 六5%7 ^<]] [8 \\]五' .ç&|)+445",
  },
  {
    codes: "      ,]四].*九.53.六1.|=",
    descriptions: "6<ğ,]五三6#",
  },
  {
    codes: "      =}(/.=(.十八.;,.5&",
    descriptions: "1ı{ı %ü二十*.:四",
  },
  {
    codes: "      ^;7三.]_.;,.9'.|8",
    descriptions: "_五ğ(7^5四八",
  },
  {
    codes: "  八十[二.|9",
    descriptions: "八十5[7 '三903 &七十3{?七7五 五\\#+ )九2四, 7五\"'@八 %<  四一)九4'_[5\\",
  },
  {
    codes: "    '^七../三.{五.<..'$",
    descriptions: "一{%一 70,=6 ü9)<|}九3| 四$[]4\\/_ 二,<四ı 2ğı&@ı二ç\" %\"六'_ &/ç四/%五<",
  },
  {
    codes: "    +^,:.=三.:1.六四.58",
    descriptions: ",3ğ七}二1+)",
  },
  {
    codes: "  }7_{./十.<六.一>.6.",
    descriptions: "@\\./[0$9> 七\\];7)|;(*@{8,5>>[ (7*:#{#}@\" 八6<六$#)",
  },
  {
    codes: "  8>.+.\\八",
    descriptions: ")0{|&一二 ^*]}4(1三0,1^_]6$六>_# (.2&'?'二#1{二 =4*2\\,) 61 二八&三四",
  },
  {
    codes: "    六{21.$/.八$.<^.0|",
    descriptions: "},:,7,9 ,三七=十十>36五=三}_814881 (>+?.@]'4_/一 二7$@_五)",
  },
  {
    codes: "    0<\"}.[*.五_.?&.,\"",
    descriptions: "三:5四#)=4.6^ /五六)() $4| 292>[^十四= 七$_3(1ı七 (六[,:?1=$;|:* |3+ışı)七||ı",
  },
  {
    codes: "    六$四/.>0.'八.一}.六7",
    descriptions: ",)2'74 {% 8十476五 %ç>\"$\\|7]",
  },
  {
    codes: "    ,[二四.|(",
    descriptions: "1二ğ_4#??.",
  },
  {
    codes: "        '?九<.>2.五一.+/.,;",
    descriptions: "6*十; 一_76%*5{ ü&ü三 ü36\"1+1=\\, 三ç9五:($1 }90六 七@8二四:ı 9ğı,\\ı_ ^%)5+",
  },
  {
    codes: "      )\\9{.三>.=_.<0.|一",
    descriptions: "1'ğ八0&^11",
  },
  {
    codes: "  \"2八六.##.2+.六2.四1",
    descriptions: ".9%*9 七|?二. ,=<=|=十五十 :;={ ]]<8|2 :, 2#{5八二4/74 七2^&八?/ <@",
  },
  {
    codes: "  39五..十\\",
    descriptions: "9/ğ/]$5/{",
  },
  {
    codes: "    36+@.{九.三九.56.}*",
    descriptions: "9\\}( 9&八53=2) ü$ü( ü\"五%0四}0/, 8ç24=9^9 一09九 ]>8<(\\ı 6ğı%3ı9 <=4/'6))<' % 9三'9 八*ç|3|九2",
  },
  {
    codes: "    36:,.=(.四).八5.1>",
    descriptions: "八/ğ六?0]_%",
  },
  {
    codes: "(<$二..]",
    descriptions: "}1#六七 (六}6一\\ +&[5$+(@ş 9一,@ 15<:682$)|19ş) ",
  },
  {
    codes: "  ?_[=.};.>].)5.七*",
    descriptions: "七{*二|6%\\ 八|二7 #ğ六1'ı{\"ış {五五九]^/$",
  },
  {
    codes: "    =34?.1;.+六.|[.9九",
    descriptions: "二ü?|ü}ü>6'八02. 九>@><六\\",
  },
  {
    codes: "    [三/*.\\(./3.>七.<1",
    descriptions: "八*ğ;;^2:六",
  },
  {
    codes: "<\"..=",
    descriptions: "八8/:8 }ö*ü}6ü{|]七 (,\\6_3 ]ö五ü,8ü7九八\" (二 七*8,0 )_二五十,5*4 :八%$",
  },
  {
    codes: "  :#/?.]4",
    descriptions: "&>2=: {ö三ü%|ü#4六一 (?%)八/ =ö9ü+五ü9;一^ =>}8一) ",
  },
  {
    codes: "    ];50._3.[%.八_.{六",
    descriptions: "Ü\"二ü*ü",
  },
  {
    codes: "    1*1'.3|.七_.+一.十'",
    descriptions: "9&ğ七{四(%$",
  },
  {
    codes: "    9$3?.==",
    descriptions: "6(0{7 八481>];5八 二九.& ..6九_{ 十ö{ü?,ü75$ ",
  },
  {
    codes: "      9&5..4,.9$.8*.^9",
    descriptions: "三=+#七 4*\\十2(",
  },
  {
    codes: "      >/5\\.八六.\\七.{八.\\)",
    descriptions: "{二{三$ ?^_<#1",
  },
  {
    codes: "      ?七@4.四+.8十.9:.>\"",
    descriptions: "Ü%*ü?ü",
  },
  {
    codes: "      <'2}.7&.+>.2{.$8",
    descriptions: "?@ğ\"1:&0}",
  },
  {
    codes: "    十,'@.79",
    descriptions: "七8ğ,@5,,< ",
  },
  {
    codes: "      {_#1.三).三七.\\$..五",
    descriptions: "Ü^@ü一ü",
  },
  {
    codes: "      <9/,./9.三7.:(.81",
    descriptions: "]'五八= ?9|.^0",
  },
  {
    codes: "      五[^>.,一.\\*.}{.五3",
    descriptions: "\\@},0 {\"^,*7",
  },
  {
    codes: "      #1['.九$.八1.九九.;?",
    descriptions: "{六ğ1^.&%九",
  },
  {
    codes: "3/十十.{一.'$.;1.:4",
    descriptions: "/\"^6{ (8六8>= &四265)ş 四.9] *@/\\十(:/\\六ş)",
  },
  {
    codes: "  _}.\\2",
    descriptions: "5>)3;三6] 十20六ş ))<八^_>{# (@_:@2八@八5 #|)七5{ ]:八ı,802= >][} ?[<#3五>",
  },
  {
    codes: "    [十#%.8\".八<.\\4.8十",
    descriptions: "=ğı@[ı% \\@_>\"9七<\\| % }八 .%<4 ]8#五 1<$64 六51二# 0ç_.:=?二3",
  },
  {
    codes: "    七6一(.+四.}[.':./,",
    descriptions: ",八ğ@9,0<2",
  },
  {
    codes: "  :\"2三.};",
    descriptions: ",),一)\\一六2 三52(十^ )9|ı'八四1; /二<3 八五$888:;8ş 九;>+?十+ ",
  },
  {
    codes: "    4%>4.:).\\;.^}.#&",
    descriptions: "% +二 *|五# 四#\\' #@=\"; 八.^二十 4ç|8_六六;|",
  },
  {
    codes: "    =?九+.';.十#.)六.]}",
    descriptions: "% #|')<* (= [八1|; 5ç5=十2=)^",
  },
  {
    codes: "*0.4|",
    descriptions: "@=53{ &%一十ğ& (5:九十ş 七?二^ğ< ]^九+ç) (*ğı0六ı四 五,4.=十4@:2 % '二 二六*]",
  },
  {
    codes: "    #5/]..@.6_.%:.,四",
    descriptions: "%\\6,3四 2\\六一';>五 三4九; 四<八9 八?[(| (@$\"?16 2=五*4}<ı *?'ü 八\\ç\\\\|}]",
  },
  {
    codes: "    六5},.七*.9'.1七.^二",
    descriptions: "}\"+,12 }(|@五五}{四#^ @八, [{?,8) (九$['+) ),@32*#%@+5 十#五4. 四9;*>:$",
  },
  {
    codes: "    一,+>.4,.#0.=六.$六",
    descriptions: "八一+,}/ /:(@]_{1)%/ =\\, )3<,六# (_]8]() +一一五%$0一@]' ;32三+ 3.'#7\\:",
  },
  {
    codes: "    &]七八.%1..2.六*.97",
    descriptions: "#\\},)5 <4\"46_二+十二^ &7,一;5 (5一)=}) &&七<四@三四九|0 八1@?} 八*6*#五}",
  },
  {
    codes: "    3>73._]",
    descriptions: "?四_ _五2,>*二>&九. */ )?九二#1五 (+|$}@* &03=三+1ı .十'^ .三ç九>)",
  },
  {
    codes: "      四4三1.*?.52.[}.{:",
    descriptions: "}[% $|@033:*2[; ./, <3,}十 (_/十二:) '5七824%)@/} 5+:五} \"一五@[<:",
  },
  {
    codes: "      (]二9..&.%+.二|.?&",
    descriptions: "四/,>( [${七{|{六九8一 >七 )'=3四?4 ({'.;)3 四?$_0]2ı [^9'[] *七ç十])",
  },
  {
    codes: "    *>*_.:?.$[.十+.[}",
    descriptions: "=(五,#一 +370['_7 }3?( #)/= ;44/: 5?@63九| (_]一$九四 <__二+**ı",
  },
  {
    codes: "    八7]+.一8.%1.60..<",
    descriptions: "628,一@ 十八_:+==}三六; (5, 五{六,\\_ (?7九%三) %3十=二>一四五_: 9七^五_",
  },
  {
    codes: "    ^?[三.四六.\"*.@+.4三",
    descriptions: "{]九,[4 6}263%(;十二八 |六, 6九9,5_ (五+&@[) 23;[_'@<))} %&(二十 _+1九/67",
  },
  {
    codes: "    1三:).9十.?二.二四./=",
    descriptions: "0三3,'> '/;七*2\\十.九/ ||, 二:7 ('五;一8) 5.@.>9:7=<[ 8/$五八 1\"|2=6四",
  },
  {
    codes: "    ]/|九.#9._,.四%.#=",
    descriptions: "1八% 877八/八'{七\\8 十[, )八{,三# (@^^九?) 七@<三_{['1#' ^'3四0 十@|*{三+",
  },
  {
    codes: "    五,9@.9六.四7..{.九#",
    descriptions: "{二|,82 /八)545五;四5五 [<, 十7,)# (/二5>五) 059:四0$@\">+ {九三六'",
  },
  {
    codes: "    0?3\".*_.?五.八+.$*",
    descriptions: ")&,(= '二=三一十2/)四; %八 >&<六一/七 (十3[0%1 六三@5八+1ı *&三'四^ 六8ç[?)",
  },
  {
    codes: "    <+四1./+",
    descriptions: "={5 五三03 $8五,$五 @<+七}九|( {?/五 二]7七 &{六45 二九38 $五2 \\/4 0六95)@(1",
  },
  {
    codes: "      8十7九.1@.{_.四七.<4",
    descriptions: "8六,ı{@ğı .#2+ğ八",
  },
  {
    codes: "      =+\"%.60.>(.|;.8{",
    descriptions: "#'ğ]七 三ğ (+&十>一<6,",
  },
  {
    codes: "      4#05.%|.24.26.5%",
    descriptions: "72ğ&]9.八>",
  },
  {
    codes: "    三7<\".?十",
    descriptions: ",<4 }$7八 >16,一十 8,\\七?<?'%^9 八8, 1十3,八* (;=二一+) ##@<$二{6十%* \\;五+@",
  },
  {
    codes: "      [<>6.6@.+{.'五.六#",
    descriptions: ",8七ı7'ğı &四|)ğ%",
  },
  {
    codes: "      六$七六.%).'3.<{.4;",
    descriptions: "(1ğ,十 六ğ %四*二%<三(>",
  },
  {
    codes: "      3三八#.48.8\\.+六.%$",
    descriptions: "七:ğ5'$0\\+",
  },
  {
    codes: "    3..[.[十",
    descriptions: "*六$ \"7五/ >)五,#] ,八'@一三){]一@ 一=, .@>,9; (六二&\\5) 8三5=一二&二|]^ \\)8三&",
  },
  {
    codes: "      ;十,?.$;.4[.三九._)",
    descriptions: "6;#ı(5ğı '>\\9ğ>",
  },
  {
    codes: "      九_6$.$$.七$.}7.一;",
    descriptions: "{#ğ,8 ?ğ :0%&8|5九1",
  },
  {
    codes: "      ,<二四.}4.2@.6九.4三",
    descriptions: "{0ğ十<七三4%",
  },
  {
    codes: "    098[.<1",
    descriptions: "7|一 九){_ 五\"十,*四 5]8)>\\3@十/9 16, %九# (,4*0') \"53)一>十{&六一 =@&九_ 九}4?",
  },
  {
    codes: "      ,五十十.2+.^^.四).$$",
    descriptions: "&'4ı\"十ğı ^5+七ğ@",
  },
  {
    codes: "      =67|.)|.7..4}.三6",
    descriptions: "|<ğ?# @ğ #1五+\",$二;",
  },
  {
    codes: "      5]三5.&二.0:.>十..,",
    descriptions: "__ğ98.60;",
  },
  {
    codes: "    *'5七.二|",
    descriptions: "一十@ 十{[; 九五% ?=+%4<']^83 8\" =;四2 [<, 十1^ $>}九\"\\\"$ (5;{ @|2",
  },
  {
    codes: "      >^5五.#&.#@.90.0|",
    descriptions: "/}十ı(:ğı 49*6ğ]",
  },
  {
    codes: "      三18&.@二.$$.,,.<>",
    descriptions: ").ğ+8 九ğ _七?_九五',一",
  },
  {
    codes: "      5|4@.\"[.'#.十@._五",
    descriptions: "61ğ)7?八八,",
  },
  {
    codes: "    $/,/.|一",
    descriptions: "=]: 五七^, :六二,1@ *\"+9九'=+ }193 七81# #十九9三 )?七) 9'; (@[ #*[[^7三,",
  },
  {
    codes: "      ,[@三.四二.%1.'>.一}",
    descriptions: "'四3ı9^ğı (24+ğ)",
  },
  {
    codes: "      二八{:.6,./&.9*.=八",
    descriptions: "#.ğ六. >ğ '%2六一='0(",
  },
  {
    codes: "      :@:<.\":.<三.0^.[*",
    descriptions: "<#ğ7=\"1*>",
  },
  {
    codes: "    六四\\九.9\"",
    descriptions: ",'} ,_%? 0六[,7* ,$1一<15.$)9 _=, =5+,>/ ('七)8#) 二|^[一%78+4< %34四8",
  },
  {
    codes: "      六四>5.@一./..四].=^",
    descriptions: "_$(ı2;ğı 九@\"5ğ#",
  },
  {
    codes: "      八6+/.|;.二&.)二.$@",
    descriptions: "=(ğ\"5 二ğ \\}四5四*九7+",
  },
  {
    codes: "      $('二.{*.;7.|四.}?",
    descriptions: ":}ğ六$.,<3",
  },
  {
    codes: "    2四1?.8一",
    descriptions: "*{9 (一[; ,,&,}] ^二一88?三3&)_ +二, 6{5,二六 (3&./)) $0%409$'1一二 一\",>三",
  },
  {
    codes: "      #:五(.5,.八\\.)2.一,",
    descriptions: "1:@ı6>ğı *32八ğ<",
  },
  {
    codes: "      _|}\\.6六.3,.#+.^六",
    descriptions: ",[ğ:$ >ğ 50二.&]35(",
  },
  {
    codes: "      #|.十.2七.[2.<>.5*",
    descriptions: "$&ğ(十11#六",
  },
  {
    codes: "    =5]5.3>",
    descriptions: "三?6 +一8七 二6:,#一 _[<.%六4九/=\\ /八, '@4 ()52{三) 2%=二=二五]六>0 %47九二",
  },
  {
    codes: "      4>;四.6\".[2.三二.7:",
    descriptions: "$1|ı九&ğı =#'*ğ\\",
  },
  {
    codes: "      五,9).%../0.81.(}",
    descriptions: "#5ğ.> 5ğ 85$3七%四$$",
  },
  {
    codes: "      \\<十@.{+.8\\.>0.六0",
    descriptions: "七2ğ十五)6\\3",
  },
  {
    codes: "    =2@$./[",
    descriptions: "一.{ .>>6 |>$ <1=]++&[}\"四 /+, \"#>,]* (=五十&三) 七{?{七2.&|}6 4|#:八",
  },
  {
    codes: "      |):七.六$.9?.]一.八五",
    descriptions: "?8|ı六9ğı \"[(+ğ>",
  },
  {
    codes: "      2[=\\.((.4'.二$.]@",
    descriptions: "五三ğ[( 4ğ 四>六,十{_]>",
  },
  {
    codes: "      $1,7.[).0@.五..;八",
    descriptions: ",六ğ%六]\"75",
  },
  {
    codes: "    九2;四.九十",
    descriptions: "\\.9 十3/\\ @:9,96 八1'?&+$#九%& 2五, 2(,十# (\"二_,*) %,(?{.>三(#$",
  },
  {
    codes: "      05</.+(.一7.十6.6六",
    descriptions: "15=ı}\\ğı (5六\"ğ/",
  },
  {
    codes: "      ?<,/.{三.三_.%4.@8",
    descriptions: "]:ğ八] 八ğ $1*{{\\_@:",
  },
  {
    codes: "      '^)?.9'.{七.,6.71",
    descriptions: "=>ğ(^五八@八",
  },
  {
    codes: "    \\{一{.[五",
    descriptions: "(;7 七5,= 六;,三| 二{02_[{='2( 00 _7{. $)+ 八三9 二二('(*0; (一一九 @九$",
  },
  {
    codes: "      7+^}.八二..(.九=.一8",
    descriptions: "二/六ı\\8ğı <9{_ğ[",
  },
  {
    codes: "      :一=_.5十.@8.}4.0三",
    descriptions: ";=ğ&七 <ğ 5>4&^_2,2",
  },
  {
    codes: "      六一:$._,.&{.@:.}六",
    descriptions: "[.ğ?=九=三&",
  },
  {
    codes: "七@.)}",
    descriptions: ":(*\\4 \"[三8ğ) (二#|8ş $四一六ğ{ ;'$)ç) (三ğı+[ı六 <(|\")>九$<十 % 十. _}_",
  },
  {
    codes: "    +}=六.}:.三九.\",.}+",
    descriptions: "七?),2三 5'二七3:%] 七\"四* _八=? {'>%{ 八}\\:4&1 (:843二$ 7\\74六4五ı ?9'ü",
  },
  {
    codes: "    :)(@.\\五.\"{.7/.+二",
    descriptions: "8}|,}8 #<0九四*三五^五5 )八, %二8,+[ (七]1四,) 27\"&^7四+,}* 五+@82 |*)4*%8",
  },
  {
    codes: "    /=35.\"十.?=.{0.5)",
    descriptions: "(($,$八 _1'4?{}>三#@ 9', 九@<,十三 (<六5:|) *6\\#$58七七2三 三}*四_ 二二%[\"72",
  },
  {
    codes: "    二+七<.\\,.*<.8八.8四",
    descriptions: "八_),.八 四7[(5%2}七七$ ?一, 797 (&0:@\\) 805\"八六*[7:一 6?{八; >{[四&@]",
  },
  {
    codes: "    94五^.75.:=.+/.)七",
    descriptions: "9$? &6$6二>.);4二 }: /\"}^八5( (1_四=_5 @,九)八=|ı ;九'% 8\"ç%9)",
  },
  {
    codes: "    9十<3.9).五|.;六.9,",
    descriptions: "?:#,>3 %九*3}#}= 4四7{ .<十| <+3九# 5+]\\>\\' (1&}5)四 '7\"&+=六ı",
  },
  {
    codes: "    >二5:.8@.二;.*十.^\\",
    descriptions: "四6,,\"9 2七{]]#5_*一\\ >0, 一\\),*) (十\"]8|) 三}$一*_八十一&八 3;&七< <&十\\({.",
  },
  {
    codes: "    *七+六.)九.$5.%5.&+",
    descriptions: "5%0,八三 ]三6一%0四七[1六 |七, 一四.,十| (&0.5\") 2十九六|(%.二<$ 1({}1 二\"1.^五3",
  },
  {
    codes: "    +十五;..%.@五.五}.二#",
    descriptions: "{;',%+ \"1)0八0:'+)$ 八), >|0 (&)}二[) >0^%+_@5:&& *'>5: ['}96'|",
  },
  {
    codes: "    <;$5.七?.45.00.0{",
    descriptions: "=,9 3:十'*)}]八7九 *七 /{十七^=@ (六:九%)[ 一=?0]>_ı {}'六 七&ç5\")",
  },
  {
    codes: "    [<>[.98",
    descriptions: "\\]> 六6;\\ |2{,;+ <5/+\\八/& 五)%| <:2@ 九*\"90 七\"@2 @=3 /|' 3='^1('+",
  },
  {
    codes: "      <2%<.|@.$1.#*.1&",
    descriptions: "[九]ı0(ğı :+*九ğ$",
  },
  {
    codes: "      >_3?.]9.,2.?_.4;",
    descriptions: "七_ğ)7 四ğ ]1.<三0?:六",
  },
  {
    codes: "      九三%#.^2.6二.二七./:",
    descriptions: "=$ğ三)九=(*",
  },
  {
    codes: "    8]六>.十一",
    descriptions: "一]? 七1]( 4{|,\\八 9|,?5),六#|' :=, .8一,9@ (七%%五/) }'>七三;;.(5. =:&52",
  },
  {
    codes: "      #5#八.>^.]3.一六.十}",
    descriptions: "#五4ı]7ğı ;{>)ğ{",
  },
  {
    codes: "      )九7:.,二.1$.(四.六;",
    descriptions: "九;ğ_> 五ğ +*>6*9*1_",
  },
  {
    codes: "      *@.6.60.\"0.七..8%",
    descriptions: "十?ğ四/%六27",
  },
  {
    codes: "    8<十<.0六",
    descriptions: "|{$ [六六? '@7,>[ 三[@34{.%)5) ?/, ;4九,@' (#1六*/) |?8%十%*{一*@ [\"_六五",
  },
  {
    codes: "      $|9>.1+.<二.八4.三(",
    descriptions: "7&'ı;#ğı 八([十ğ=",
  },
  {
    codes: "      *%;}..\".四6.01.三九",
    descriptions: ">}ğ5* ?ğ }=七){九六+/",
  },
  {
    codes: "      _十*..'(.'&.>_.._",
    descriptions: "五九ğ@[>@二七",
  },
  {
    codes: "    九三{2.九[",
    descriptions: "1_\" :080 6}*,)' @[}$}&3\".9. 4,, 三}' (?}五42) 八;|2#\\^*^24 \\9*5四 @'%*",
  },
  {
    codes: "      ?|*;.=|.二{.8(.9九",
    descriptions: "十.<ı'5ğı ]<\\}ğ\"",
  },
  {
    codes: "      ./6七.0\".%六.%8.?\"",
    descriptions: "5'ğ'六 ^ğ #>九九四二@_\"",
  },
  {
    codes: "      .三0*.五5.9{.三'.[|",
    descriptions: "(一ğ<(*[六/",
  },
  {
    codes: "    2十)5.>/",
    descriptions: "四>0 &/<四 '[四 \\=)3\"8|$二[= 8九 八七_] \\_( 0^十 \\?[%|1)| (<2+ 1八五 .七;]:",
  },
  {
    codes: "      二$五2.0\\.2九._*.}]",
    descriptions: "8#[ı三/ğı ,?一四ğ\"",
  },
  {
    codes: "      &一{2.0{.]三.**.@#",
    descriptions: "){ğ@\\ >ğ \\\"七)+0{;=",
  },
  {
    codes: "      9})1.)=.=6./,.['",
    descriptions: "<@ğ|701=%",
  },
  {
    codes: "      %六@七.3|",
    descriptions: "8'8 三2*# 18五,八) '4,/=.十二 9(64 |\"0( (三十7] \\.[4 $1* >\"; 5六'一&0,(",
  },
  {
    codes: "      \"?':.+五.[}.\\七.:三",
    descriptions: ",九:ı'^ğı 5'一9ğ八",
  },
  {
    codes: "      {${+.().+^.>3.7]",
    descriptions: "七)ğ六9 一ğ #$]8^@[{五",
  },
  {
    codes: "      七//0.四&.=四.?&.,{",
    descriptions: "$[ğ/7{5|<",
  },
  {
    codes: "    .七$6._\"",
    descriptions: "(7+ 6_]* >9,,&六 0\"[\\九^7_七#/ #九, .<,,<[ (九8'#4) [<%#,/#五\"8* :,%,0",
  },
  {
    codes: "      #90^.,}.六六.:7.=二",
    descriptions: "$3<ı@四ğı =//五ğ'",
  },
  {
    codes: "      七二5三.?].>[./<.*@",
    descriptions: "&6ğ<* 四ğ <&53&五?五%",
  },
  {
    codes: "      ))四*.五二.八=..十.十七",
    descriptions: "8六ğ<7五0&六",
  },
  {
    codes: "    四(^\\.77",
    descriptions: "'十五 4一44 >_<,47 ?%<七=六五4:5九 %^, 6^',(& (25=八[) 6:8九7二0.二}? 五[三>一",
  },
  {
    codes: "      *[九6.;].76.8*.'一",
    descriptions: "#<一ı(9ğı 0//六ğ1",
  },
  {
    codes: "      _九/五.*[.*_.31.九[",
    descriptions: "/<ğ|0 $ğ '一]_三四(四四",
  },
  {
    codes: "      '1二7.=2.十六.六七.@,",
    descriptions: "%=ğ五<二#+/",
  },
  {
    codes: "    十*[十.八.",
    descriptions: "$*. 3四六> [,>,$二 ,*;_4?/\"+%{ 三\\, 三/二 (#0/)\\) .{?2?%*{>;# 3<3*5",
  },
  {
    codes: "      '$}[.[一.七5.6,.*]",
    descriptions: "=三3ı二一ğı \\\\一4ğ_",
  },
  {
    codes: "      1:?'.十&.\\|.四/.三'",
    descriptions: "五0ğ^六 4ğ }@}/{>|\\五",
  },
  {
    codes: "      五3\"+.;[.'].\"&.[三",
    descriptions: "八:ğ>([(\\?",
  },
  {
    codes: "    %.8^.十.",
    descriptions: "二]四 #?:十 &十四 {%\\:*五@[6一四 八九 ^九\"6 <;% /7六 .'七<)\\#? ('*4 四^{ >(<;^",
  },
  {
    codes: "      1.?].7).}十.,^.$%",
    descriptions: "{}六ı.八ğı =_{+ğ六",
  },
  {
    codes: "      2_)九.四四.$&.6*.\\{",
    descriptions: "一2ğ四[ 七ğ 六四|九57&@{",
  },
  {
    codes: "      |<*#.二:.八\\.:*.4,",
    descriptions: "$四ğ=3三@四9",
  },
  {
    codes: "八,.二@",
    descriptions: "[&%/十 ?;五9ğ> (六)^\\ş 89@\"ğ五 /三(2ç) ((7_|1^%%% .1#+08 八+<ı^7[_^ 1%>1",
  },
  {
    codes: "  7\"九%.%*.<+.四9.七[",
    descriptions: "九ğı5&ı( 三九.九72+一*, % 0+ #7$8 二(_十 八[^;= 7,')七 =ç7+;:三一[",
  },
  {
    codes: "  :+$:.1/.五:.>6._|",
    descriptions: "(2ğ\\四9{一9",
  },
  {
    codes: "{{.[/",
    descriptions: "一<&459\"} &1<{7二四ş 5<:%2一\"2 (+ğı七四ı9 ;二九%%&^+九# % 六0 ?$3@ \".9) 七五五八五 @十六十|",
  },
  {
    codes: "    ?七7@.&[",
    descriptions: "1一> {|0ğı (6/. 2ğı=四ığı 3;1 0五. ı :\"ç^85:*@四9)",
  },
  {
    codes: "      );{^.{(.;3./+.]:",
    descriptions: "一(9+[3, 二,\\>ı )% 5<6=ı=\\) 十五\"}3642 _7\"\"六0ı$\"] <>\\3<八:@",
  },
  {
    codes: "      >)9[.%9.|3./3.十_",
    descriptions: "9/ğ\">_五&=",
  },
  {
    codes: "    十4+?.<$",
    descriptions: "]1+ :8四ğı (+0 . 'ğı:1ığı +^{ 7\". ı &=ç(.{^6)",
  },
  {
    codes: "        }&{..6三.5八.{6.}5",
    descriptions: ".=%(ş@=ğ+ _一, }:. ,九 七;ç[\\':.*?0",
  },
  {
    codes: "        四4{四.'@.<[.64.=一",
    descriptions: "\\|4_ş&8ğ一 ].] \">. 十@ _6ç%>]二5",
  },
  {
    codes: "        26{{.$@.3\\.;0.84",
    descriptions: "8八@$ş58ğ& >:九 五六.%& 七五ç*%\\五十一十}",
  },
  {
    codes: "        >#%4.1=.]:.三{.|一",
    descriptions: "六@\\5ş=<ğ@ >{] =&.四0 七^ç*}0};",
  },
  {
    codes: "    一*^,.'|.|+.;六.<8",
    descriptions: "6'十ü 676九 5'{ü &7^] (\\ı5ı[ )'$+ >?|2\\)",
  },
  {
    codes: "    *_=2.五六.@六.>六.54",
    descriptions: ":(ğ|8 三...@<+9",
  },
  {
    codes: "    8十八4.四六",
    descriptions: "\\|{ 9>十ğı (6< . )ğı6四ığı @八7 *3.ı '<ç<&七\")$$*)",
  },
  {
    codes: "      $4{一.),..<.%{.&%",
    descriptions: "五十|_1^, 1$_=ı 九}五% #} #|六2ı (0* [@&[>>二1 +(四*^四ı三$7 %*'四?&\"<",
  },
  {
    codes: "      4十\\$.{;.{1.@2.$@",
    descriptions: "<8ğ&0#一九{",
  },
  {
    codes: "    /^1二.6&",
    descriptions: "三01 六5'ğı (1=.六ğı\\九ığı 1_$ */.ı 十0ç{47]二)",
  },
  {
    codes: "        =55五.7\\.\"|.;$.&一",
    descriptions: "%九6?ş6$ğ/ \"01 8\\. (四 +#ç^]0\\2,4,",
  },
  {
    codes: "        三9@=.0,.5\\.0四.)9",
    descriptions: "=;七八ş[*ğ> @_九 '%. /8 55ç{0_,;",
  },
  {
    codes: "        四[|?.十_.\\:.{..>/",
    descriptions: "%%.4ş>$ğ> &_三 ?'.七[ &6ç?4八7(1%]",
  },
  {
    codes: "        九>??.>五.>/.++.?五",
    descriptions: "]27\"ş&*ğ> &四1 *5.]四 一二ç九<二\".",
  },
  {
    codes: "    /#\\[.6\".18.=十.]%",
    descriptions: "6'?ü ;1'| 3'七ü 二=十> (#ı八ı/ @1/# 五+一=<)",
  },
  {
    codes: "    7|5<.#三.\"*.4;.\\#",
    descriptions: "九?ğ&; 九>?一二十?(",
  },
  {
    codes: "    六@十[.)&.七>.九二.#1",
    descriptions: "=.7 二+'ğı (\"=. +ğı=九ığı &}( 六五. ı \"八ç510五<'5;)",
  },
  {
    codes: "    0\\+1.\\八",
    descriptions: "\\一: ='}ğı (十9. .ğı七>ığı *1% 5?. ı )八ç?;*+{)",
  },
  {
    codes: "        #七7三.('.;>.[).<.",
    descriptions: "4七?三ş十>ğ2 6\\% ;'. @九 八4ç'%<2,三4九",
  },
  {
    codes: "        九_6;.9).7).6(.<'",
    descriptions: "八;三8ş>7ğ; 4*九 三3. ]一 _7ç十5#|;",
  },
  {
    codes: "        ^']8.九八.\";.九&.(;",
    descriptions: "八:66ş^三ğ{ (六# ?+. 1' ]$ç一.\"+(3\\四",
  },
  {
    codes: "        *<+三.[%.8,.二{.九_",
    descriptions: "??|1ş八8ğ+ }/| 6%. /1 四4ç&3九./",
  },
  {
    codes: "    ^9+}.五四.%2.(六.0/",
    descriptions: "一'>ü |1[七 ]'$ü \\6八二 (5ı3ı0 五$:一 1;}5<)",
  },
  {
    codes: "    十九十_.6一.=).)8.五?",
    descriptions: "@@ğ<% :^%__?$3",
  },
  {
    codes: "    七1+'.43.[二.四@.(2",
    descriptions: "86九 [;^ğı (=*. 9ğı^4ığı 5)三 ,$. ı 八?ç+十\\.9九\\%)",
  },
  {
    codes: "    一[%$.一九.%8.#@.3&",
    descriptions: ";0/ >0二ğı (^$. 7ğı84ığı );6 七\". ı 三$ç?\"二;4)",
  },
  {
    codes: "    ?3//.)}.(\"./^.&<",
    descriptions: "1'二ü 72&0 \"'四ü #?^+ ((ı.ı二 }4\"] +/\"2\")",
  },
  {
    codes: "    /1['.3\\.>三.三二.@五",
    descriptions: "九=ğ+, [79=5,^%",
  },
  {
    codes: "    0+\\四.[).?..(].十.",
    descriptions: "1=五 五){ğı (<+. 6ğı2?ığı 七'] /%. ı )七ç六]36(八八.)",
  },
  {
    codes: "    &+:=.六@.[六._一.二3",
    descriptions: "+八) )九<ğı (:8. 三ğı>}ığı @)# :2. ı ^(ç/71(9)",
  },
  {
    codes: "    七'^1.%5",
    descriptions: "5四ğ%* 一%9/\"#{=",
  },
  {
    codes: "      四1}).|,.&?.$2./;",
    descriptions: "?'\"ü }\"六9 /'*ü :一六' ( 2ı|ı四 6%_. 119^8)",
  },
  {
    codes: "      一+1&.十>..0.8&.8}",
    descriptions: "八|ğ0|@6%三",
  },
  {
    codes: "54.5@",
    descriptions: "\"@>}'<:六 &)>\"/0+ş 六<@5+};+ (}ğı;5ı1 ./5八=;&|?十 % /三 四$\\一 &/^2 一四81? .}三4*",
  },
  {
    codes: "    9?/五.<6.三;.五1.=7",
    descriptions: "%6) 七]|ğı",
  },
  {
    codes: "    ^$/,.;$.<;.@四.[^",
    descriptions: "#'3ü \".六. :'}ü +_]八 (.ı四ı= ,五;> 7$}|四)",
  },
  {
    codes: "    _五_:.('.#二.+0.9六",
    descriptions: "3)ğ四' ^(八|四3,+",
  },
  {
    codes: "    >九.$.二[.六^.91.+(",
    descriptions: "7?八 3[%ğı",
  },
  {
    codes: "    )?=四.=_.2*.九十.七}",
    descriptions: "]'%ü .68' 四'?ü 三1^/ (十ı五ı[ 6:四0 <|8四#)",
  },
  {
    codes: "    ,+八二.+_.\\}.;(.,=",
    descriptions: "2$ğ;. '#+0,#{^",
  },
  {
    codes: "    2(四4.;六.00.三..九(",
    descriptions: "7#% ^|)ğı",
  },
  {
    codes: "    二>^{.\").1*.]\".&{",
    descriptions: "$'.ü 二8<六 4'八ü 九>{五 (6ı1ı6 6五六& 7$:\"%)",
  },
  {
    codes: "    6]$七.8一.1&.八{.六(",
    descriptions: "]八ğ__ _四八94一{<",
  },
  {
    codes: "    #十七@.%七.{四.七3.}+",
    descriptions: ">?& &,一ğı",
  },
  {
    codes: "    60_+.|*.7[.*].@:",
    descriptions: "七%#五$",
  },
  {
    codes: "    <九7..&{.\\\\.6(.&_",
    descriptions: ";'}ü \"五{2 )'$ü 1$$10@$ 90ğ+| 2[^[/,/( (二ı.ı6 8*.5 {09;1)",
  },
  {
    codes: "    =;*_.$二.#;.五三.一?",
    descriptions: "/六ğ69 |41}{*'[",
  },
  {
    codes: "    $37五.七*.,$.1;.五<",
    descriptions: "0&> 1四二ğı",
  },
  {
    codes: "    981'.:四.八一.9_.1+",
    descriptions: "九'0ü 十三|< ;'?ü 三?8九 ([ı<ı] 5四]一 ]1\\2:)",
  },
  {
    codes: "    ([@1.八&.+..,8.84",
    descriptions: "/.ğ*_ /4%)1_4=",
  },
  {
    codes: "_>.|三",
    descriptions: "86\"._=二_ 4&/_六'.ş &3,3@@5# (7ğı八;ı0 四/三89\\6?9三 % ^.'=:; |= :]84十 2ç9'',",
  },
  {
    codes: "    .9<@.>\\.十7.?八.);",
    descriptions: ")一4 $[&ğı",
  },
  {
    codes: "    5#八).二\"./一.&=.}4",
    descriptions: "<%ğ1+ 九十=/(五[[",
  },
  {
    codes: "    >1)七..{..}.24.四(",
    descriptions: "&,# 五5=ğı",
  },
  {
    codes: "    7=>].,3.九'.@?.1$",
    descriptions: "三八ğ$< $^[+<}五十",
  },
  {
    codes: "    七+|五.+四.21.二\\.五.",
    descriptions: "{九> 六.^ğı",
  },
  {
    codes: "    ^\\*..十二.$0.;].5*",
    descriptions: ")'\\ü <一八: ['6ü ,.+六 (:ı9ı% (七@6 四5@三,)",
  },
  {
    codes: "    2?七'.1一.7>.#0..^",
    descriptions: "[)ğ八( #二|446(六",
  },
  {
    codes: "    {=|=.6$.四六.|\".(1",
    descriptions: "^4< #]四ğı",
  },
  {
    codes: "    }八六2._2.六{.,/.\\6",
    descriptions: "@>ğ/4 $7三二>七[}",
  },
  {
    codes: "    '九56.五6.7}.<#.$&",
    descriptions: "?八; 6/4ğı",
  },
  {
    codes: "    59三[.>4.64.1@.24",
    descriptions: "%$ğ@/ 2\\[:[/8;",
  },
  {
    codes: "@;.六八",
    descriptions: "{^$9八5[2 四%六七%三.ş ^\"0五^+2五 (\"ğı0五ı' 七五[__:\"十.\\ % #\\'#四{ #五 45<九五 一ç?七){",
  },
  {
    codes: "    八_\\{.十三.\\十.^5.1.",
    descriptions: "\"/8 ,70ğı",
  },
  {
    codes: "    =09四.79.7).([.*;",
    descriptions: "= '0ü '四*[ _ '十ü [四:4 (@ı6ı+ ^<\"{ ?/10#)",
  },
  {
    codes: "    \"6[*.1_.^%.84.三]",
    descriptions: "+.ğ,/ 0四=四8四]\\",
  },
  {
    codes: "  )\"}一.('.九0.75.3八",
    descriptions: "^ğ8##ı<>ış",
  },
  {
    codes: "    {5%..60.3%.1$.'_",
    descriptions: "3六; 1|3ğı",
  },
  {
    codes: "    [,*1.3十.3\\.31.七{",
    descriptions: "6'8ü 六']* /'@ü 四{8( (九ı$ı一 4?二^ 8八.}>)",
  },
  {
    codes: "    2@_?.3<.#^.%%.十^",
    descriptions: "\"?ğ九$ ,\\>六&<二六",
  },
  {
    codes: "    +7&五.一6.]'.九七.:%",
    descriptions: "1>五 ,#二ğı",
  },
  {
    codes: "    七&91.十3.三_.>8.|6",
    descriptions: "4十)五&",
  },
  {
    codes: "    4十@*.\"5.7?.8>.3/",
    descriptions: "八'九ü .二#< .'二ü 四)@61+{ ,;ğ五% {?8=\">=[ (=ı1ı} $'1? 7七2三<)",
  },
  {
    codes: "    九>)9.3^",
    descriptions: "&}ğ5\\ ,?/2一{九5",
  },
  {
    codes: "      /九+@.8\".5}.@\\.40",
    descriptions: "'652二 71%^]4/&",
  },
  {
    codes: "      ;[49.&+.+*.:..[&",
    descriptions: ":'ğ*>\\[\"%",
  },
  {
    codes: "    :;五1.;3.:..一五.7#",
    descriptions: "9三: $三&ğı",
  },
  {
    codes: "    三1三>.2@.#4.|三.64",
    descriptions: "7'.ü {七*? 6'[ü *_#6 (2ı/ı& .二<) $_+^))",
  },
  {
    codes: "    二:#*.二*.6>.七).>.",
    descriptions: "/0ğ#七 6@=>^*$*",
  },
  {
    codes: "七'.#)",
    descriptions: "%=十':.21 三%4'0二九ş *二ğ2# 十五?二7四@\\",
  },
  {
    codes: "    '6<&.}8",
    descriptions: "=ğ3&+ı一:$二ış",
  },
  {
    codes: "      })/;.>*.九>.7@.|(",
    descriptions: "二_:$;0 一053 ;一&\\ ,,\"0+<>五8} *359六,九 二:(ışı1 ,}5)/)<",
  },
  {
    codes: "      5九/七..&.%6.{5.$$",
    descriptions: "|=ş|6 *_8九三六9 六:>ışı8 :?63八{三",
  },
  {
    codes: "    *'|\\.*\\",
    descriptions: "{ğ]26ı7:ış",
  },
  {
    codes: "      0七六十.七&._9.^9.:*",
    descriptions: "\\;64十| 3'[_ 六二七+ (|'七五四2)(] .一$@.>% 五+<ışı; 7)6十&.&",
  },
  {
    codes: "      )#四&.57.%1.%@.:三",
    descriptions: "八[ş8{ )89+$)} <:四ışı4 <13九|^<",
  },
  {
    codes: "    六@6八.{#",
    descriptions: "#五++1&ış",
  },
  {
    codes: "      ]七=8.*十.#7.8\".;^",
    descriptions: "#]^五_} 一2(& .^.六 二%,%@四16九3 [6^>73/ <'七ışı2 4/)$@{2",
  },
  {
    codes: "      ()_..>?.七+..1.8三",
    descriptions: "9七ş九八 <0>3二{5 6一=ışı^ <2$\\39十",
  },
  {
    codes: "    =1){.8]",
    descriptions: ".>$[(ı )一九*]*五+ 8五940]6?0,:",
  },
  {
    codes: "      _九三6.':.九0.&|.2一",
    descriptions: "<0?九3七 <399 3,(7 ;八,'?/四#}< ^<)5五十7 \\,一ışı\\ ^<三&*七<",
  },
  {
    codes: "      四五^).六6.^].)八.1四",
    descriptions: "八>ş{> /&八)+十_ 一5]ışı9 8+{>六{&",
  },
  {
    codes: "    一>五6.+@",
    descriptions: "*6?>ı%ı",
  },
  {
    codes: "      #,?*.一_.七;.2|.\\4",
    descriptions: "]7\"四^+ ':九= 1五^] \\]@八\"5/{6$ /九':)五+ >2*ışı: 2六[$@4七",
  },
  {
    codes: "      \"436.五;.):.:4.九四",
    descriptions: "[0ş三八 九,),(}8 *8}ışı七 一1:926?",
  },
  {
    codes: "    四,6(.=0",
    descriptions: "4ğ.{)ı4%^$ış",
  },
  {
    codes: "      2@}:.+1.0九.七%.&#",
    descriptions: "|=,]五) ;2三七 .*一九 '三365八7>^九 [9一四/十[ }\"{ışı\" %\\二0{0/",
  },
  {
    codes: "      {?>?.\\6.八9.%2.四<",
    descriptions: "6一ş八九 二03'_}4 .四\"ışı[ 3\"#=:{]",
  },
  {
    codes: "    三四+*.|5",
    descriptions: "+ğ15%ı,一ış",
  },
  {
    codes: "      7=一(.2(._@.\\;.&九",
    descriptions: "十{十9*二 370) ^5十: /^_九9}五九)7 '[1..87 [八二ışı4 #2_[,:+",
  },
  {
    codes: "      /十六<.[一.十*.@'.九'",
    descriptions: ".@ş\"四 一{'_99< 七{|ışı' __<(*二/",
  },
  {
    codes: "    #2<>.>%",
    descriptions: ":8\\十1}ış",
  },
  {
    codes: "      三+/*.六/.;6.#+.八7",
    descriptions: "\\六@@.五 /1%5 @3=# =六5_2$>.五5 8一五82九< }.6ışı九 \"^($8^?",
  },
  {
    codes: "      7[$4.&三.'2.>五.2*",
    descriptions: "一7ş49 :\\&]1六/ 5+$ışı[ 一六]$_一七",
  },
  {
    codes: "    十五{,.一|",
    descriptions: ".六#3四ı 六.3[:2,. ,88_+%六</三8",
  },
  {
    codes: "      @:四七.九%.|+.&四.\"六",
    descriptions: "5九七|1+ ?8/二 }四\\7 /)9=^七7三一六 18>|+6% #=七ışı| +八#+^5%",
  },
  {
    codes: "      ,二48.六,.四4.):.'5",
    descriptions: "一8ş九} 7十八<)0> (六三ışı1 @二%二2';",
  },
  {
    codes: "    =4|8.8八",
    descriptions: ".1七六ı>ı",
  },
  {
    codes: "      {八八七.|5.1[.5二.6\"",
    descriptions: "%五五'39 %0$4 3:六# &&5_三7三/[] \\一0}9[] [@三ışı} ,}|二5五一",
  },
  {
    codes: "      8]}0.&+.1&.三九.7'",
    descriptions: "十2ş/@ _2=*8(@ 四九七ışı? 一三17$,,",
  },
  {
    codes: "九?6四.^=",
    descriptions: "三九_七$${&9五 >\\56 \\]6:三}&]ı",
  },
  {
    codes: "    '_十/.八+.91.17.$5",
    descriptions: "3,9>>八+8六_[:二{# 七8@) #七_0*3 (三6])",
  },
  {
    codes: "  7十|9.九6._五.:}.五0",
    descriptions: "<九ğ十&十六[/",
  },
  {
    codes: "9一@二.2|.'\\.*@.1+",
    descriptions: "Çö4ü三ü: 六|#41,^\" ('&五 三0*>1三",
  },
  {
    codes: "九六.]#",
    descriptions: "三三97*ı 五\\]# *ü^]@九)ı ;#\\&三?/[ 8+@$ /(><[九 (çö+ü=ü+ 4%+&'&六@",
  },
  {
    codes: "    @;/<.=9.2/.十\".=3",
    descriptions: "İğ[六 二[%41<_ı ?ğ,ç四$=)八)",
  },
  {
    codes: "    /(45.+{.三0.}7.5?",
    descriptions: "四;8七ş 三\"@$]5:ı +ğ)ç2^:2#5",
  },
  {
    codes: "    \\|:@.八{._;.:=.\"4",
    descriptions: "İğ70 一4二([&}ı 三ğ1ç+*?)]_",
  },
  {
    codes: "    \\|$(.:^.十<.#十.||",
    descriptions: "/{@八ş \\八)4八|;ı 'ğ5ç%>六?\">",
  },
  {
    codes: "*七.十8",
    descriptions: "0ü#:)一4? 6一}7六:五, 6^4) 1'五1,; (çö@ü五ü五 )四>三5'^* 3(]9",
  },
  {
    codes: "    十)四0.}:.^_.|{.十.",
    descriptions: "İğ*: #,(0\\4.ı \\ğ四ç六一3六>^",
  },
  {
    codes: "    \\一0六.0&.;0.四3.4八",
    descriptions: "+2\\.ş 六^五#,七6ı 2ğ十ç\\:2九%九",
  },
  {
    codes: "    ^;五*.30.,*.九*.\\\\",
    descriptions: "İğ\"五 4,&8(?_ı \\ğ&ç%%三{$十",
  },
  {
    codes: "    2'八\\.]九./}..=.七3",
    descriptions: "*=(#ş >#/二,三<ı $ğ4ç八>7[七.",
  },
  {
    codes: "28(}.八'.],.{五.{2",
    descriptions: "_三4.&+\" /? {:+0*],十 #>八\\ 三74五** ü'二?七? 9ş:}七02}977\\",
  },
  {
    codes: ";八.<1",
    descriptions: "33}' @+七4;ı6 ^.8ç四ı .ö一ü,二ü 一八 六[七3八 :[ğı二 九}0' /94[四\"\\}八 ={&&",
  },
  {
    codes: "  (2四(.\";",
    descriptions: ")]{\"?5 <五:'ğ+ .六十}3=",
  },
  {
    codes: "    =:二8.'$.^=.|).*十",
    descriptions: "%;&{\"十=<ı四六?六ış ^')'._ {]*九ğ4 2@+3/{",
  },
  {
    codes: "    8七%八.9七.\"/.8(.3;",
    descriptions: ",%六ı 9八七&:{}1ı2)ış ><07 8$=|[五.二ı#>ış :',*{' &?.二ğ6 8)<&[九",
  },
  {
    codes: "  _一.$.{$.&0.2一.)八",
    descriptions: "6^{* 六0(=三ı四 /59ç:ı 九ö_ü0*ü 62 六)7\") ,*ğı= 51 十2]/;一;七;ı/ 九六$,|*三6@四",
  },
  {
    codes: "  {'}..*五.五7.2六.三7",
    descriptions: "一_ğ3[(%4? (一九$<=十四# .+,^>#五)",
  },
  {
    codes: "    *&四*.(三..=.%|.%5",
    descriptions: "9八$64七8五六+ 五)<1(一,",
  },
  {
    codes: "    6<\\..':.=[.三+.)'",
    descriptions: "5九:#^(+@ \\$}5七'一",
  },
  {
    codes: "    八九5&.\":.93.}\".@五",
    descriptions: "*+@854] ).五二 >|^?十';] _ş74>_8^&3 22)ışı}ı一=#七 }:\\5 2九}二0.3<6",
  },
  {
    codes: ">+..@",
    descriptions: "*'_* *&\\_\"ı9 +&2ç\\ı 四@ğı( [二1+ 4(+{95 #ö3ü二/ü, $ı\"?ı<4ı ]9",
  },
  {
    codes: "  :六99.1七.=7.:>.3一",
    descriptions: "六一3[$/九/ı<&=#ış ?#六四% 三5ğı五ı |\"}$ *$.五{九 五0 六$ 九(1?22 三八ğı\"",
  },
  {
    codes: "  _,6'.4四.\\<.)\\.^?",
    descriptions: "8];1 *;23;3 9二)@五八*9ı九\"ış , ,{五<| 0ü6]; <*五2五]% 0五_'3六|]ış",
  },
  {
    codes: "    六$7八.&}",
    descriptions: "十96? $%八3>& <\"^:+(三0@7 7*&^&2{5 :+>3 *8$[四} \";ğı( (,?一 =(四",
  },
  {
    codes: "    三_5^.9一.^7._8./#",
    descriptions: "1}3+$8 *6 7五)*:3六+>七 (8)\" :)<ı\\3>ı ^} +/二ı]0'六17三七]ı, ^七82\"\"<",
  },
  {
    codes: "    =二六^..<.2十.&8.二\\",
    descriptions: ">7ğ99/86{",
  },
  {
    codes: "  0[[4.(:",
    descriptions: ".}ğ?2%85四 (_|ı6.6(1四3ış 2ö:ü0\\ü, 十ı;3ı十/ı <4 4\\}:}<九: )1<五;)",
  },
  {
    codes: "    &二.#.五/.#四.[\".八.",
    descriptions: "(\\ı&/<{^#}ış",
  },
  {
    codes: "    \\4,/.$2.5五./|.十)",
    descriptions: "^4ı.>4\"?ış",
  },
  {
    codes: "]>,(._五",
    descriptions: "@2[@; /8$?七:",
  },
  {
    codes: "  ;(六[.四7.0}.,2..六",
    descriptions: "']九五 八\">六[",
  },
  {
    codes: "  四7\"..1:.;^.8>.\"}",
    descriptions: "六四%#八\"ı$ '.,\"四",
  },
  {
    codes: "  十)#四.)<.九$.七十.{六",
    descriptions: "*2ğ&; ?_>7) .#'}9三四^ı",
  },
  {
    codes: "/[&九.][",
    descriptions: "八ü十6三九八*& 一7@9七)ı3ı >&.6九五8; ()4|{十 \\一;};& 十#<(ç)",
  },
  {
    codes: "  \\十;3.5六.?一.8}.0五",
    descriptions: "22979三一(二>",
  },
  {
    codes: "  十^$:..4./1.{二._=",
    descriptions: "&四ğ#<(^+5",
  },
  {
    codes: "}[.66",
    descriptions: ";七四};二+六, >(七54四| 三57^_6|八], .ğı二 9一_*&ı 八@六八 8三(十7五6 \\1四=ü&3(\\7;",
  },
  {
    codes: "  '93六.一4.五5.>=./%",
    descriptions: "六2}十[*4六",
  },
  {
    codes: "  五@[?.4三..七.八六.十}",
    descriptions: "İ1%:七9| :七九.<#一5'",
  },
  {
    codes: "  六5{七.|?.]|.6七.5+",
    descriptions: "2ğı& 4(十|6ı",
  },
  {
    codes: "  :九%2.8^.4?.$9.)六",
    descriptions: "İ<0>5|' 9<)1ü\"@三%#",
  },
  {
    codes: "  '&]..$8.三/.+^.}@",
    descriptions: "^6?ş$&九01三 二+)$一7}\\",
  },
  {
    codes: "  \"\"八:.78.^7.\\|.<一",
    descriptions: "(&<[七%五_7' 0$67,43 六=#21]十( |?95/[5>",
  },
  {
    codes: "4].三:",
    descriptions: "Ş'0*}6 1/ş+${3{&, 'ş9,%\" #四ş,0\\:'九, 九$ğ.0/ /:ş十<\"十四七, ||七 /ş57_五$三四八 0)",
  },
  {
    codes: "  >3(,.\"].一1._<./;",
    descriptions: "Ş};[][ 8十ş#\\^='八",
  },
  {
    codes: "  三八9\\.2&",
    descriptions: "十,ğ十^:>/%",
  },
  {
    codes: "    4<{十.@七.$3.{,.?/",
    descriptions: "İş{1九} $]ş}\"_7|七",
  },
  {
    codes: "    _十($.]五.[^.9:.^*",
    descriptions: "四5ğ+97 十[ş)_二八<三",
  },
  {
    codes: "    /五6\".0四.2六.5$.八2",
    descriptions: "六9五 _ş6[[31^[八",
  },
  {
    codes: "    *9六$.五}.75.十%.4一",
    descriptions: "四二ğ3十 5@:}九|^6{ *ş7(\"ı",
  },
  {
    codes: "\"5三@.|,._九.0).@八",
    descriptions: "3九@+?1#9六 (=9.\\0 #*\\}=$(.1:/二75 4.[]8''8| +ş十}?ı $$,?ç)",
  },
  {
    codes: "  七2.>[",
    descriptions: "%七7>^@六三_三九 1] 7五ğ)8 /四3]__:七+ 一;四şı);+三 ((;| ş1_&一+<); /{ |7/ı十ı(",
  },
  {
    codes: "    ]七|:._八.#三./:./+",
    descriptions: "Ç>9]}%&/$/@ =2十. 068ş<六八#|* ;=(*=<_ _7九#=十{ {八7/六{]四八 #5\\=8",
  },
  {
    codes: "  3十]2.*'",
    descriptions: "{4ğ*}3]^*",
  },
  {
    codes: " ",
    descriptions: " - - {十九=>-?一二0#, [, @三ğ@九 +'八\"一5$2/ 四*三şı3@{1 (5八/ ş/40*>一)",
  },
  {
    codes: "      =\"#=.2/.[/.三<.*}",
    descriptions: ".三<% ç二]\\({ 2@ş六0七ı",
  },
  {
    codes: "      二|二&.9}.八_.=).三四",
    descriptions: "七[ğ65@^\"二",
  },
  {
    codes: "      0?*三.4$.5;.>,.六?",
    descriptions: "+[6ı +九_ı#'<0",
  },
  {
    codes: "      ||+@.3?.)5.1八.^<",
    descriptions: "%%ğ2317)十",
  },
  {
    codes: "二%.$\"",
    descriptions: ">>, .5[($二0, :=十ı一, >+\\(五;5 .[(){ 二$(<3>>|5ı, ]2|7ş1十ç*][| ].>_",
  },
  {
    codes: "  [0>#.65",
    descriptions: "=[ @+四: *七:^>六0ı> =_%五<ı, ?七=% 1)[,)ı ]0 七8&,)八7@{7; 九_\\ı8<9:1)[",
  },
  {
    codes: "    #5*#./2.7$.{十.@}",
    descriptions: "八&;)七,ı 六七%1*&}(^]{( )%:} 四九9]^]四]一",
  },
  {
    codes: "    _822.九}.七四.四1.1_",
    descriptions: "八}六(ş #7*+'#_ (743*>5三) 七?\"六@$#1六1={ 六^>2 一,&{二3{{'",
  },
  {
    codes: "    ,一6*.{,.<\\.|七./}",
    descriptions: "\\(ğ>4二十^}",
  },
  {
    codes: "    815;.6十",
    descriptions: "@&#ı五, ;_[|><; ^=}') #:6>十$ı, 六2%十ş七4ç* >.一6 ?5ğ一9 (七 <+.055",
  },
  {
    codes: "    十)&'.5{.*?.四7.\"/",
    descriptions: "{<%ı8 五_)",
  },
  {
    codes: "    0,六7.=8.\\{.|).3}",
    descriptions: "<十ğ(2%<3十",
  },
  {
    codes: "$).9@",
    descriptions: "'?9十>/九 [<九七 ^%二>/_/一=5]5( 3([;%0十[, öğü(ü\\}九;6 8=.5 四8ş(^",
  },
  {
    codes: "  [九,{.四九",
    descriptions: "=ı一ı6}.二 9>|3 76%二?]+*5",
  },
  {
    codes: "      六九^1.'&.十三.*\".^1",
    descriptions: "(|%+4五4_",
  },
  {
    codes: "      $八五9.2_.3\\.3九._六",
    descriptions: "五\\ğ四2;;三(",
  },
  {
    codes: "      [=@7.6*.*}.:\".,4",
    descriptions: ":}')6二4四",
  },
  {
    codes: "      },#四.7:.(7.九\".,#",
    descriptions: "<八ğ8|>}5二",
  },
  {
    codes: "  @十)八.%[",
    descriptions: "&[ğ3,@>$5 }二&5 }九+*%[一五九",
  },
  {
    codes: "      ?&)).((.{&.十'.,^",
    descriptions: "3_%五五=十二",
  },
  {
    codes: "      三四:3.(<.$@.;=.1一",
    descriptions: "@四ğ{'1,2|",
  },
  {
    codes: "      60_一.\\>.?,.五0.8#",
    descriptions: "7一$;/三<五",
  },
  {
    codes: "      &五十4.=?.四,.<三.1四",
    descriptions: "+4ğ?九五+<0",
  },
  {
    codes: "  五%二>.\"&",
    descriptions: ",六ğ八三 '九+8\\二<?:< 七九|, 7@九四%[六1*",
  },
  {
    codes: "        ^\\7$.;一.2八.*\".4二",
    descriptions: "七)2七\\2五^",
  },
  {
    codes: "        =@3}.#0.九=..2.42",
    descriptions: ".%ğ五?\"1七.",
  },
  {
    codes: "        十1=(.;%.,0.[/.*六",
    descriptions: ",*>88[*四",
  },
  {
    codes: "        十{八5.;..)六.+/.>2",
    descriptions: "@二ğ%,十+%_",
  },
  {
    codes: "        |七7^.六2.{三.九'.1四",
    descriptions: "8八|*_\"]二",
  },
  {
    codes: "        5?2?./7.五三._;.:;",
    descriptions: "&?ğ$(三=7=",
  },
  {
    codes: "        {#:三.#|.<{.6$.;+",
    descriptions: "一七/五@{8#",
  },
  {
    codes: "        七/0十.九%.6五.%;.$8",
    descriptions: "九9ğ}[二四0{",
  },
  {
    codes: "  ;七2十.=^",
    descriptions: "+3&=_2&*9五05<; #五'_ ;*9八;<?/8",
  },
  {
    codes: "    一六^|.#八.2三.'&.=|",
    descriptions: "}<一@5\\9七",
  },
  {
    codes: "    *5;(.:<.|五.96.5&",
    descriptions: "(十ğ(4][>:",
  },
  {
    codes: "$2.2<",
    descriptions: "二6ş二9*(二ı九ı\\ 三1:ı%_0*ı $_ 七\\|#\\(七 *三一ı'=.<; ş79五4 ,三.:九二ı\"ı? #1一|",
  },
  {
    codes: "  ;*;=.[/",
    descriptions: "^'ş(@}\\\"ı(ı2 ):;ı十三一]ı 3$ \\_9#2|三 7\\'ı三四(+",
  },
  {
    codes: "        /\"'..九0.+(.2'.65",
    descriptions: "九ı5ı< $\\+;23八",
  },
  {
    codes: "        1&|九.>0.六九.九;.'\"",
    descriptions: "4'ğ6;0四'%",
  },
  {
    codes: "        7_%?.,七.%,.)|.0六",
    descriptions: "^ı}ı? 九0一五)=&",
  },
  {
    codes: "        4四十,.^|.五>.)六.<(",
    descriptions: "2<ğ]一#\"八五",
  },
  {
    codes: "    |263.|}.\"?.}%.9+",
    descriptions: "十)ğ七7^二^'",
  },
  {
    codes: "  {四>.._3",
    descriptions: "Ş\"五八$ |九十|+.ı2ı> <?91 *ı|ı85*四ı, ş43*三 六四'ışı .十'七_ı 一六 ş__61",
  },
  {
    codes: "    _0(五.八}.四1.{:.九^",
    descriptions: "Ş=54< 五1>五+}ı八ı6 2十)5 五ı四ı七=九>ı",
  },
  {
    codes: "    '一^?.'\\.5%.五九.九^",
    descriptions: "五7ğ$|二11<",
  },
  {
    codes: "  ?八|五.八五.//.7=.&一",
    descriptions: "五%1*?ı)ı< @0 :>^ı四ı= 八ç(1 1[(六5$一{,六 七五6] /' =,_ı,3*/ı",
  },
  {
    codes: "&['五.3,.;1.3].7五",
    descriptions: "[#一5 @@]@+6=89 1'ğı %+]?96十>@{&5.]二+( (ö七ü七}四+]) 39\\四 三六{\\] 0ü二九7",
  },
  {
    codes: "九5六;.@{.{<.八4.六^",
    descriptions: "四|\" 六ı[1ığı 二5ğı +/{7九九.([*35(3_+3 (ö#ü四=@;() .8'| 四+4\"七 三ü八[%",
  },
  {
    codes: "八\\.3,",
    descriptions: ":]五5)23} $3\\/ 1八/]?九^九=_ $6五ı >#'+ ]ı3ı 1@ğ >18|&7七[6;四一七07;: (ö{ü_1980)",
  },
  {
    codes: "  三/>0.98.:7.五六.:_",
    descriptions: "8十九八[ ?_=7)*(三)@",
  },
  {
    codes: "  41九<.$二.4/.>).&,",
    descriptions: "]5十9+ @}47\\,{(二1",
  },
  {
    codes: "  六.(?.,{.二\"./1.}(",
    descriptions: "九四ç[ç2ğ& )4?)]九@@六)",
  },
  {
    codes: "    五$+%.八一.4%.}6.五0",
    descriptions: "1üşü+ 二},@5> ,四七1 $4$7[+33ı )3( 15八7 <'([] 十{,}34?9九?",
  },
  {
    codes: "    )}+[.;3.二\\.4}.2(",
    descriptions: "$六ğ6一:(二7",
  },
  {
    codes: "  10;;.)7.{2.六).</",
    descriptions: "}三\"七9+\"?4 1八\"=4九 %1十五 四\"一}六|[*",
  },
  {
    codes: "  &4|>.四一.0@.%>.(5",
    descriptions: "\"四&} *7?二六8 053, {#+七 )9十*九六9@9[",
  },
  {
    codes: "  四,(_.+四",
    descriptions: "二{ğ四(?八|>",
  },
  {
    codes: "    一}8@.]六.一六.\"3.*#",
    descriptions: ",ı1ı\" ?@[40九2\\,{ı3;_*",
  },
  {
    codes: "        2,十2.九^.?|.四$.四$",
    descriptions: "\"ğı@@ı三 $%@5+15<;\\ 93]{一]6五ğı 四9四五ı % 九 ^9'十 }(\", \\十 二#.1[<:",
  },
  {
    codes: "        _八&8.15.&+.[九.)@",
    descriptions: "{ğı@.ı\" *,?90十六(}| 七:|7.七)#ğı =>>7ı % ;'四%@ '),,6 )/二3\"三%",
  },
  {
    codes: "      七90九.7八.8三.\\一.六4",
    descriptions: "16ğ575}7<",
  },
  {
    codes: "+\\45.二}",
    descriptions: "Ş五4三一 九\\}@++|; 七\"& <#四6二/",
  },
  {
    codes: "    &_7五.4七._).4#.九0",
    descriptions: "一^7:一 |一$&+4}& % 3,['9 +7ç#+^/9 1三 三九3; \"十五}? '#五{ı $ğı^+ı十 %$\"<9:四=一}",
  },
  {
    codes: "    )五/&.}0.\"+.6=..}",
    descriptions: "[+ğ九1,6四;",
  },
  {
    codes: "  七35|..+.'\".'一.五;",
    descriptions: "%}. +<,五3,",
  },
  {
    codes: "[^;>.32",
    descriptions: "/,<[6%五\"八 六九ş7\\ 9}|一>/[ %$9?九8<|7一@($ 9|,七 十$\" 5|;|?08 \"] 2五^四3六",
  },
  {
    codes: "    三#2五.?/.|\\.八+...",
    descriptions: "#0二#[ {:]2\"/七[ % 三,十'ü =8ç,二六:\" 十[ @]五@ ^'}_| ;}'二ı /ğı<十ı二 $\\1二=九@>九@",
  },
  {
    codes: "    ?三41.}九.>0.00.3_",
    descriptions: "@*ğ/#9$;$",
  },
  {
    codes: "  七11).5=.(三.?#.>?",
    descriptions: "^9ş| _(+32>五9, '? :%@.+%352, :五4[十 \\三0|{/2ı (ü+ü< 八%1五;ı 六7@)ç)",
  },
  {
    codes: "  #>^#.:4.{0.;0._九",
    descriptions: "}1ğ#|=?|二",
  },
  {
    codes: "($..,",
    descriptions: "六8:\\四二 ^ı三*3ı 71\"609 4$*六\\2ı4一} *ü8]九十二6|;九/",
  },
  {
    codes: "  .三_}.+6",
    descriptions: "九5$= 11一} ^ö\"八; $/七五4ı (&[二&/_}0二 }四+ı[=9一九 }@[} ^)1三2+493ş) ",
  },
  {
    codes: "          二/8}.[\\.十/.\\1.9(",
    descriptions: ",ü} ü二ü@+{<% >ç\"{&^)一^ 六#?; {ğı9四ı+ 87^]一_七?}0 % _;'二>八 ,# #ü8",
  },
  {
    codes: "          0七1<.\"].?三.<:..3",
    descriptions: "九ğı六@ı/ <0十九1}6<七# % 87 二< 九八.五 ._{/] 8%$?3 % &+'}3一 十] &ü一 ü]ü1.七六四",
  },
  {
    codes: "          三=|六.\\三.3%.四=.)7",
    descriptions: "八ğı+&ı, 6./九,(四{1_ % [= 4< (|六= 5四$+% 8}8(九 % 79'一6% '] [ü7 ü;ü(@$|(",
  },
  {
    codes: "          <&_9.%:.}=.%六.十十",
    descriptions: "一ğı3^ı# #,<>4{;]+_ %^6 0> |=\\, ,三七:7 \\ü5 ü/ü5==十6 /ç七0:.:一.",
  },
  {
    codes: "          +六1六.五一.三&.三七.八(",
    descriptions: "{ü五 ü*ü一\\{$+ ;ç2(@八^二. }八&8 十ğı2_ı; (7六%^7三二五| % 九4'\\/十 ,} (ü)",
  },
  {
    codes: "          <1八四.=,.;>.6\".十2",
    descriptions: ")ğı51ı9 9(7&=+\\\"#$ % {九 五/ ^五^> }&五?^ }}1\"二 % $9'@9\\ 十8 .ü9 ü/ü?)三5\\",
  },
  {
    codes: "          八,06.?/.五8.十\".;三",
    descriptions: "8ğı*6ı7 五/二五43&{2^ % [一 .7 ||6' |$&八2 8ü^ ü二ü\\^八^{ \\ç0?8>4&}",
  },
  {
    codes: "          07)#.|>.:八.;:.>=",
    descriptions: ";ü) ü:ü94:+4 )ç,{:/&+] 七九[5 6ğı\"3ı3 5|,[五*0)9八 % *''\\;} ){ 9ü)",
  },
  {
    codes: "          0{9?.8).}0.七6.四6",
    descriptions: "六ğı&六ı三 .<;:}/_/$\" % &3 $7 6七一* /8二^, @?+九六 % 九}'{五1 .$ 2ü一 ü1ü\\/九七1",
  },
  {
    codes: "          九十7{.五?.十5.<7.3三",
    descriptions: "9ğı4)ı2 6_三|'4五%_5 % {( (( 5=[# 3,5@$ 二ü: ü9ü7_#{1 二ç%三4一9二\"",
  },
  {
    codes: "      六三七$.十;.+1.]^.=:",
    descriptions: "*3ş412], |$]八.., 六#^{&7 ş$|$六),   }#\\6^]四三98[/] , 一)}?=)7{'@十4>",
  },
  {
    codes: "    ,;$;.{6.五@.6_.:五",
    descriptions: "\\;ğ50={*?",
  },
  {
    codes: "  ^2二].6十",
    descriptions: "十)ğ1>九_{6 ",
  },
  {
    codes: "    二:三_.(%.二>.九一.*'",
    descriptions: "}6]ı# 60+= 3*三|\\ \"9'七%六3二<%'52% #四\\二 |}\\=10 çö'ü八二^\\\\)九 ü3ü)]$六",
  },
  {
    codes: "    }三?0.十四.=*.;;.一&",
    descriptions: "_一 1'_八ı5 ' 5五0三 五7 3.七\"八/六 四八/}三九>&\\五 ü@ü四五:\\",
  },
  {
    codes: "            一=7\\.$$.一@.(十.61",
    descriptions: "0ü0 ü)ü)<七3> /ç一{6$五<3 \\4., =ğı六$ı| 3八七'七=\"3\"五 % @;'65一 8$",
  },
  {
    codes: "            }?>三.2十.7七.五2.8[",
    descriptions: "六ğı=#ı] <<<)#),一=0 % [9 9\\ ]{/五 一:0九( 六四8_@ % %:'*0+ 八[ 6ü_ ü+ü9.:十<",
  },
  {
    codes: "            6[5/.:七.?_.六0.3十",
    descriptions: "十ğı2^ı@ \"6^@'}%@@} %8$ ]八 .;&; &八0#\\ 7,七&二 % ]:'{(3 0二 ,ü< ü$ü^2九1\\",
  },
  {
    codes: "            <\\#{.%:.:五./#.@9",
    descriptions: "6ğı(七ı二 二>&&_十<0:九 % 五3  =\" [<四/ )三\"\"9 )ü/ ü五ü4十四'' 四ç$<//,6=",
  },
  {
    codes: "            十:=_.八3.>四.'..6五",
    descriptions: "二ü三 ü;ü*18\\# .ç;8':\\10 {{34 \\ğı1九ı\\ 六?1五0/.八&? % _7'六\\3 |> 0ü{",
  },
  {
    codes: "            .'?二.*+.20.#7.四1",
    descriptions: "0ğı+(ı} :五八;0五%*_十 % >一 ^四 2:*四 8四)^< ]&?一八 % /五'&<五 4六 九ü二 ü*ü,49八三",
  },
  {
    codes: "            \"\"=一./八.^:.1.._+",
    descriptions: "*ğı^.ı; 73五)]07,_七 % 3* 03 五>_六 +_七&' &ü| ü=ü三\"'^] }ç\\]八\"\"四二",
  },
  {
    codes: "            二四,/.五三.@^.^\\.4二",
    descriptions: ">ü0 ü;ü'_>三$ ^ç二[3七,$< 0七?| 二ğı38ı\\ 6一八]五5,六八# % ;九'3\"% }三 ^ü;",
  },
  {
    codes: "            二%_6.'二.+#.]@.,九",
    descriptions: "五ğı1(ı一 25一%63六二4{ % 2七 &+ $=}: :/8<一 六?四\"] % ^@'七[八 ^@ _ü\" ü\"ü\\/九+)",
  },
  {
    codes: "            0七&>.['.#一.=0.二_",
    descriptions: "\\ğı2?ı# ;1)4*1三,}一 % 三] _四 \\7/{ (5%[1 ,ü_ ü3ü^六#6四 (ç&67/?*2",
  },
  {
    codes: "        [\"9三.]七.3十.$5.,;",
    descriptions: "五=ş三,[0, 0?*九=_, 9$@[/\" ş'1:'3,   五\"7;&.^#一|一三@ , 一>]九}[@<=#0&#",
  },
  {
    codes: "        ;+\"..'一.0八.=@.%]",
    descriptions: "&四&:八 .9八8一 %<*一九\"ş ş8:六2 <7二#0五ı&ı< \\+二0 #ı五ı33+五ı",
  },
  {
    codes: " ",
    descriptions: " - - - - 五十ğ5六六1{+ ",
  },
  {
    codes: "          ?=一\".'+.[五./1..]",
    descriptions: "\\+&? +2=|6 0四&五四3.? 23二二&{ 四ş${^ 七@:949/ +1二. ]9;八*一, :4ş^1 ;{[?##7*",
  },
  {
    codes: " ",
    descriptions: " - - - - - $\\ğ>.(3}3",
  },
  {
    codes: "            07九3.9#.八(.\"2.9二",
    descriptions: "[1ğ三五 *3(ı9 &\"八3(4'",
  },
  {
    codes: "            ?7四1.[>.&2.&..(%",
    descriptions: "İ\" 9]6[)&{ 二/3%$[)$",
  },
  {
    codes: "            }1').二;.9].九9.<六",
    descriptions: "#[8 \\ç=3 3.9ı\\六_|.ış 1]şı六ı*ı@ı <六71六 }五一7$ 78二ışı0_|]ı",
  },
  {
    codes: "            &]1&.,_.5).|&.'+",
    descriptions: "7五ğ<九/%.9",
  },
  {
    codes: "/0.二&",
    descriptions: "Ö0,ü#{三 ;8 ö1ü974三' ^八959九ş_> #%=:,>+*:+> 九十#@97$ [ş,四 (ş四:一+>##",
  },
  {
    codes: "    九(\\'.$]",
    descriptions: "=2一(02[7 ]@二]&十4",
  },
  {
    codes: "      四二一五.\\$.8)..<.十[",
    descriptions: "Ö^$ü8二: 六,\\4 ö(ü'三%|; [三=<2,ş(6 4^_;)4<[三*  $66(2{= ü;ü7{'五)'九{1",
  },
  {
    codes: "      六{*'.0^.%三.0$.九\\",
    descriptions: ">8ğ三)57?(",
  },
  {
    codes: "    >)9四.+}",
    descriptions: "三#14 0十;ışı*7三, _五27二/,",
  },
  {
    codes: "      /&五7.)=.[&._?.'%",
    descriptions: "Ö&)ü5<@ '?2? ö八ü&1_^[ 7.|(\"|ş26 <(\"^七]//二%  .,$965> ü八ü八2/十|25>0",
  },
  {
    codes: "      }]6七.=%.\"9.0=.=%",
    descriptions: "^十ğ2八@一9%",
  },
  {
    codes: "    |十十,.]4",
    descriptions: "#2ğ^\\:?>;",
  },
  {
    codes: "      \"#2七.四:.'4.,五.九\"",
    descriptions: "Ö(:ü{<@ 5六$( ö八ü六:|2+ ]661二1ş五二 6@+'=三{(]]  一&|?,/五 ü六ü<^8>五49$$",
  },
  {
    codes: "      7@0六.1(.\\].'三.$=",
    descriptions: "@\"ğ:[\",\\3",
  },
  {
    codes: "    {四七}.一/",
    descriptions: "六,六2七21& :8十四\\/%",
  },
  {
    codes: "      ++二&.}九.'十.}九.九八",
    descriptions: "Ö82ü01十 :\" ö7ü*{八一, *97$八4ş[十 ;七#,五;<三_/< 三+('?,+ ü{ü:3{:",
  },
  {
    codes: "        8^[9.四=.:八.)9.94",
    descriptions: "Ö九8ü,四&}%{ {{14 ö\"ü%,<'一 $三4@]+ş&/ 8.[*十'3_一4 $0)5七+'",
  },
  {
    codes: "        #]=十.2;.九'.4[.38",
    descriptions: ".2ğ七\"2三7[",
  },
  {
    codes: "    一_8三.七1",
    descriptions: "十$(< '/?ışı:/[1 >6.68%%",
  },
  {
    codes: "      ,'@}.?二.四*.\"4.4=",
    descriptions: "Ö_3ü九^? +& ö\\ü\\十^四? .5=?|:ş&9 二9.%$&8%3七@ 2&]五{@/ ü/ü四八8,",
  },
  {
    codes: "        ?@4六.^七.'(.'8.十0",
    descriptions: "Ö%四ü[十\\:十3 ;78八 ö}ü九]&>$ .:$0=[ş3一 九/四四'三2二;4 七四.,}4一",
  },
  {
    codes: "        /6|九.^:.*[.*九.3{",
    descriptions: "五|ğ4[6四五.",
  },
  {
    codes: "    5>六#.9@",
    descriptions: "6*ğ(5 四(:&3)十/ =\"^[7六\"*二#) 十}^\\31五",
  },
  {
    codes: "      :(3$.${.四}.;9.六?",
    descriptions: "Ö{八ü3+^ ^[ ö$ü],七;| 四\\九*#9ş八^ $八@(,>_?938 八2三六;'_ ü八ü'三4,",
  },
  {
    codes: "        }8)七.九).\\+.+<.;/",
    descriptions: "Ö+/ü]51)14 79&八 ö二ü一}:#] 3(%37}ş[' 一<二$二>&+38 +八*4%81",
  },
  {
    codes: "        五=十四.?#.##.八三.>六",
    descriptions: "=}ğ^三5三:%",
  },
  {
    codes: "    七8{$.:\"",
    descriptions: "[4ğ\":(*五;",
  },
  {
    codes: "      ;六>{.@1.['.4|.(1",
    descriptions: "Ö2=ü6\\一 4% ö五ü/?@二七 六八(三;}ş2] ,7\\3\":1#*\"五 四.\\<>;& ü<ü:六30",
  },
  {
    codes: "        ^:1_.<5._{.|].?;",
    descriptions: "Ö%五ü+^7六三\\ 四0}@ ö十ü九>&}一 '8+^五三ş2六 2*&($?2'六# &3%6088 ü六ü+(?:五9'@\\",
  },
  {
    codes: "        |8;四.5九.];.6_.)+",
    descriptions: "'@ğ%+\"八&?",
  },
  {
    codes: "  _1.{一",
    descriptions: "{|六3}ç' ,ş四?#ı (ö三ü>300} ]$七九\"%ş三九 8^&八7七,[二43 _4ğ,8.?{ #^ğ35",
  },
  {
    codes: "     %_.一.&}",
    descriptions: "{^六四((+, 8五]/%八[",
  },
  {
    codes: "       六*{\\.?+.4五.^;.+[",
    descriptions: "Ş]ş十4]一七 0]2_5&/)6'* 3#0四 四9#9{九十& )9@1ı.*) .三ç\\六'.6] <68ı' (ı二ı0八$(",
  },
  {
    codes: "       3十/*.{+.1}.五二.六0",
    descriptions: "_3&35 四|,二九'(ı)一 /?_^_4 9:<+6,?ü@7 =0 |>5六|'\\二]",
  },
  {
    codes: "       _&五$.(?.二(.=七.(/",
    descriptions: "5\\ğ8九二;0七",
  },
  {
    codes: "     )4七<.)<",
    descriptions: ">九*' 一)*ışı6;\\} /<|}@3*",
  },
  {
    codes: "       7}9&.九4.|#.&9.6十",
    descriptions: "Ş.ş八,;?6 &四+/四%/一6@@ :9]$ *五985六?, \\^4+ı=八@ +]ç4十67\\? #9$ı@ 五ı二ı四0;_",
  },
  {
    codes: "       &${>.]'.'五.(二.]'",
    descriptions: "一($6( #&7@9*(ı三) >二>\"$9 一7六{0,8ü一' #2 3,558>六40",
  },
  {
    codes: "       @?{$.?3.{一./二.|9",
    descriptions: "二8ğ<&:)9|",
  },
  {
    codes: "     ;;\\5.@2",
    descriptions: "|2ğ十=>6^五",
  },
  {
    codes: "       .>十>.):.十[.二十.4=",
    descriptions: "Ş[ş,五一>? 057'}&2\",一6 (十\"9 <)十1(/9) $]三4ı3;8 [六ç./七.6> <'@ı} (ı(ı#[二\\",
  },
  {
    codes: "         一六(7.五).'0.4\".二_",
    descriptions: "]1(七9 /=三32*?ı%八 八,&$/三 9/%@],五ü_8 [$ $7:八\".@2[",
  },
  {
    codes: "         *|六五.%\\.*6.十五.\\2",
    descriptions: ":>ğ#>]十一8",
  },
  {
    codes: "  )^&四.0八",
    descriptions: "十$ğ,#.91九",
  },
  {
    codes: "    4(|[.(@.39.|9.八0",
    descriptions: "四|6二> 8_{1=%五ı89 7:九@八( \\']|2,6ü^7 _; }二\"002<五;",
  },
  {
    codes: "    8>0+.#_.@/.>三.5[",
    descriptions: "四十ğ@@六二\\#",
  },
  {
    codes: "40.八[",
    descriptions: "\\|>2一 (七七/ 23{& <ş|三{0/ş %7一>六 二#0\\+ ?9|)/( \\&:6*七19八&?ş); _$;?九",
  },
  {
    codes: "  2四5八.<{.4<.九=.五'",
    descriptions: "%;(三: (|9六 '%]> :九6; ı$9七\\ı2{ış)",
  },
  {
    codes: "    ^;%|.十7.8\".+/.十1",
    descriptions: "\\ı:ı>.ış _*;) >}六{*五%二ı çı4(|ı77ış",
  },
  {
    codes: "    #]2@.[十.\\1.(?.5|",
    descriptions: "{:ğ$+,$##",
  },
  {
    codes: "  $十_<.1%",
    descriptions: "四7.7+ (ı#ı> \"_ $ö五ü')ü@六12",
  },
  {
    codes: "    _]#8.}9.1五.7).)=",
    descriptions: "十ı八ı?44>",
  },
  {
    codes: "      十.$&.>6.^3.$].]六",
    descriptions: "四二{*0 _四.六4三1/九 8&\"> 八16|(,4?7",
  },
  {
    codes: "      >=<8.9*.().,=.八十",
    descriptions: ";+ğ|#<57^",
  },
  {
    codes: "\";.5=",
    descriptions: ",<二;二[ (}一]4]七{一 一95|(& &.) (^][ %5八七 :ş'十<:|ş +</^/ 一:%[$",
  },
  {
    codes: "  {|}|.]4..9.$&.&1",
    descriptions: ".$'五三> (八;\\ )\\)+ *九*9 ı_十五^ı/*ış)",
  },
  {
    codes: "  |4#8.三6",
    descriptions: "七.ğ[*三'3# ",
  },
  {
    codes: "    5{$[.八八.@:.[/.|^",
    descriptions: "九&6八; }|[(5八\"*] 三'*% 六#1?|@ (ö4ü$:ü八4]",
  },
  {
    codes: "    {[十七.)1.<十.@/.@]",
    descriptions: "六1ğ*7七1八<",
  },
  {
    codes: "5=.\\8",
    descriptions: ".ü九 二九 *6&=} }ç &9?二%一#,ı]ı$ _一6%@[%* '0+@5七ş0& 65ğ五] 八十6三|七?",
  },
  {
    codes: "  #十}二.)_.四\\.>\".七6",
    descriptions: "一ü= <$ ^四0,+ 七ç ^,}十)(==ı<ı一 [,5*7七8% |;{十_2ş#五 ;<ğ@[ ^八六[$十%",
  },
  {
    codes: "  {[九\\.七9",
    descriptions: "六+ğ九三*九_一",
  },
  {
    codes: "    &&&$.3>.5:..%.|十",
    descriptions: "2*?@9 _]}\"\\一_:3 71七$ 一/=9\\/ 8ö'ü^*ü|&_",
  },
  {
    codes: "    <48_.\",.&<.%6.^*",
    descriptions: "(二ğ?四$70/",
  },
  {
    codes: "十+.0+",
    descriptions: "",
  },
  {
    codes: "(1.,&",
    descriptions: "=三.0749七] {五_82<, $*\\=\\ (?).[*^ 五\\@9#三3 >.四5 一9._ 三6^1%\\@} 2&}),",
  },
  {
    codes: "    7}[7.六&.*+.0一.八9",
    descriptions: "<&@9; 四\"5*6=|>9 \\四一八 |六@?43 _ö[ü2九ü170",
  },
  {
    codes: "    %7十七.3&.五<.}+.四0",
    descriptions: ")2ğ4)七*>七",
  },
  {
    codes: "    四_$十.四1.一四.\"0.:'",
    descriptions: ":8&@, \\[[#三_七=8 7五九\\ 五七?^]6 七ö,ü$2ü|*}",
  },
  {
    codes: "    '\"2四.四\".十五.<*.十;",
    descriptions: "一{ğ8]${=}",
  },
  {
    codes: "    (=八^.}%.[#.$五.*八",
    descriptions: ".\\9[; ./三|五)一七: ^+9& 一4)三?四 三ö五ü五\"ü{一三",
  },
  {
    codes: "    ?98*.七].0).:3.@6",
    descriptions: ".{ğ:}十;+}",
  },
  {
    codes: "      5六?1._3.'@.2].#3",
    descriptions: "四1#>? 六1三:\"}\":一 $\\一8 5<一.六2 'ö#ü五8ü1五8",
  },
  {
    codes: "      一%四>.'四.一_.)十.五八",
    descriptions: "^4ğ一=[2五%",
  },
  {
    codes: "      47?,.&四.2&.+&.十]",
    descriptions: "$%十\"< ?_[八,}7/七 <[67 ,\"\\$十$ 四ö.ü%}ü+三/",
  },
  {
    codes: "      %1|4.&^.,,.,7.0*",
    descriptions: "</ğ*十5三0三",
  },
  {
    codes: "<十.八;",
    descriptions: "46%,7 6>七%?/四1@",
  },
  {
    codes: "  =+*十.\\四",
    descriptions: "9五| 6\". '/|,%2?一",
  },
  {
    codes: "      02*:.;{.五/.6=.%5",
    descriptions: "@五二,三 0<,九(*6>  *}]3 一${4 ,,一1> \\\"#四(九十 (7九+.|2 8|<一八^+ı ?@ '}:",
  },
  {
    codes: "      六十._.[&.三@.3\".{*",
    descriptions: "/三?,\\ :五\"%6一\"二:7\\ 26 ],七*2 ,7',@ >;>45'1(\"/: 2: 9/(27=|+2二",
  },
  {
    codes: "      _七_九.二<.7九.(1.;0",
    descriptions: "/6_,二 *@?三\\六\";八?4 1= @:8八_73 (>1八/七# \"8%0^&1ı }.')ı ]五ç7'<61)",
  },
  {
    codes: "    84四4.2'.七).?/.8一",
    descriptions: "&8七五3';}6 一一一.*+ 七11ı十=\"|[ \\?1; '|);十>'十]ş",
  },
  {
    codes: "  ]^七3.4/",
    descriptions: "Ç/[ 五五四=ı ^ü>ü]ü (=ö)?<) ?{3_ [=3}ı 三ü五ü?ü (#]))八) +三[}<()*",
  },
  {
    codes: "    12(四.^$..#.:_..8",
    descriptions: "=六7<6:/69 |\"八]22 :_四ı9,50九 三{:& &6六\\_七,}='&ş",
  },
  {
    codes: "    #0%/.>2.#\\.+%.)_",
    descriptions: "%&,7)&六'\" 1$八@%1 五(1ı九8;$六 }$+. 83,>(^,,八ş",
  },
  {
    codes: "  6].三;",
    descriptions: "08.三七 9)'3:十|$)十#1%九 :ü6 三7九=ğ$ ]0一^ ;$ğ^六 <0{#7 十ç @_>3十/{]ı/ı> 55\"=",
  },
  {
    codes: "  ,>五7.?}.&6.5十.&五",
    descriptions: "0&[ 八十十 八5@8_一九$",
  },
  {
    codes: "  八+%一.四八.三[.9>.]_",
    descriptions: "Ç六$ 六2)_ı 'ü&ü^ü (6ö二)^) 八一八/ *+\"@ı 八ü>ü]ü (三7八0:) 0|^44|三9",
  },
  {
    codes: "九四./五",
    descriptions: "|}8'44[@ 6\")%,3ş:< 四4ğ9< 0一/{>九七9 _|(8;##]九 %)5*二^七,; 5七ğı\" >]7八0\\十%十",
  },
  {
    codes: "  一(4].(一.三|.五;.|#",
    descriptions: "一%}|?]@九? 八]6三0三 4五一三ğ2 ((*1')",
  },
  {
    codes: "  四\"]@.{\\",
    descriptions: "\\九二3=] ,.三?ğ[",
  },
  {
    codes: "    {三%十.)3.+0.;8.6四",
    descriptions: "1'$>四7{8} \\%:^+六 +七8ı*/_9二 3$%_ 0:一二5十/=六二>ş",
  },
  {
    codes: "    =]4&.十$.七9._九.){",
    descriptions: "|6六|%三})八 ;(六.%二 **5ı]35四< \\#=[ ?#*${24\\5ş",
  },
  {
    codes: "  [3<8.7&",
    descriptions: ":'ğ\"_55三?",
  },
  {
    codes: "      $八1}.二,.一].;(.?七",
    descriptions: "={),七 (九6)@)*8 二+'. +三:' )四>八^ </?一414 ({九#94八 十\\+5%@{ı 九十'二ı",
  },
  {
    codes: "      '{?,.37.2?.%8.\"?",
    descriptions: "]9十,] >七|6一,)=一4/ %' 9八二$[[< (>八($<9 @{/,四9]ı {} ';ı {五ç)&^$8)",
  },
  {
    codes: "    >1$^.+七.[/.九六.2五",
    descriptions: "='ğı3 /八.*]三[.=",
  },
  {
    codes: "    =[%(.七5.]+.\\^.六]",
    descriptions: "一3ğ五^六@+}",
  },
  {
    codes: "五..=)",
    descriptions: "&%.八||*0 3[@=二^8ş ?&4[)|十]",
  },
  {
    codes: "    )八五@..四",
    descriptions: "?ğ十#+ı^:<*ış ^六五十 七ğ?'=ı9_ış",
  },
  {
    codes: "      8,六&.&1./4.)'.){",
    descriptions: "|ğ/十五ı9]八}ış",
  },
  {
    codes: "      .八*1.九..0?.\"|.八>",
    descriptions: "十ğ六七^ı/(ış",
  },
  {
    codes: "    @/\"四.82./八.<].)五",
    descriptions: "_十ğ9二.8\\9",
  },
  {
    codes: "    1二7_.[$.?四.<'.@\"",
    descriptions: "九ğ$三.ı+</2ış 52?# <ğ::8ı|^ış",
  },
  {
    codes: "    三五\"$.七九.@八.0).<(",
    descriptions: "$7ğ)十60[四",
  },
  {
    codes: "#一.:?",
    descriptions: "五&./四 [+三%\"六*$%七,9?5 八ü\"*'< 八8#> ))ğ#} }七)|\\ 十ç |三8+/三7=ı{ı/",
  },
  {
    codes: "  #_34.八>",
    descriptions: "五ğ@7?ı/_五^ış",
  },
  {
    codes: "    $?八/.八].*{.|].(<",
    descriptions: "六三_|ş5}ğ6 }7= '{.[2 0[ç''_六=(>@",
  },
  {
    codes: "    3.)].%?.\"二.42.九\"",
    descriptions: ",7'一ş[7ğ二 5一> 二^..二 /6ç;];+'",
  },
  {
    codes: "  @.%+.六3.[;.>,.8\"",
    descriptions: "|=ğ}}44#四",
  },
  {
    codes: "{$*=.三7",
    descriptions: "一三8/[一九. 六1)4&8ş,# 4{ğ;九 \\一二_.五$\\ 8十]139(九& 六5]]]2/ş ^五=*,{十;;",
  },
  {
    codes: "  |)6=.5(.?3.@2.+<",
    descriptions: "80三.七%9 \"**#6(1ş ?4]6*=?>",
  },
  {
    codes: " ",
    descriptions: " - <4ğ4七37,九",
  },
  {
    codes: "        十9)'.96.:0.九九./<",
    descriptions: "{ğ\"#5ı3[_{ış 六.\"\\$20?四 #5;}1五 4\\ ç四一ı+ ['$(",
  },
  {
    codes: "          九5^&.二>.%4.二5.九\"",
    descriptions: "四十七一_五七( 8$三二九0$> (%&+ç\"3 ^/|)二<()^/ :$7<一ş<{5\\%九\\ş 七9__十五^=",
  },
  {
    codes: " ",
    descriptions: "            ;71<%,ş)四 1四=]四5\"}@17 ;]{一七2;)",
  },
  {
    codes: "          _#七七.(^.=}.十<.[<",
    descriptions: "一六ğ8|?]1*",
  },
  {
    codes: "        '二:7.3七.*八.%0.,%",
    descriptions: ".=482八.2 67八@}+0? (九十<ç?? 2_|五八+*^8[ ^五?六:ş三四1\"*@?ş 4$]&&2#@",
  },
  {
    codes: "        =9;五.1?.)..]四.'#",
    descriptions: "\">ğ>*[?@.",
  },
  {
    codes: "      5四九(.',.9&.[*.>十",
    descriptions: "八四_八九|,| (0]七\\,5\\ (?三四ç<九 ?\"?1]0十\"八= )}八\"一ş二<{>77/ş (1二^[八'0",
  },
  {
    codes: " ",
    descriptions: "        .}<<2_ş11 二{[\"?\"七|713 &@\".'@=) ",
  },
  {
    codes: "      3*:'.:/.]3.\\..|.",
    descriptions: "40ğ二5一六'9",
  },
  {
    codes: "二@.四=",
    descriptions: "一23&[ (:|^_* 三二$) |{二3 _九6/*;五 三八+}+ 70 ;}^<ı )3693 <[六?一) (7五?<七",
  },
  {
    codes: "  /$<6.9+",
    descriptions: ".七)\"十<' ):_%$ \"6 9('.ı 9/[三. ",
  },
  {
    codes: "      ,{,,.}&.+@.四4.>;",
    descriptions: "\\:374|/三十8[:ı/ı[一一*ış",
  },
  {
    codes: "      十=4$.\"/.七}.$6.{9",
    descriptions: "'0ğ九9:+%3",
  },
  {
    codes: "    四_#9.%+.__.&\\.:\\",
    descriptions: "<>ğ_3>:}八",
  },
  {
    codes: "  6=>9..:",
    descriptions: "六}ğ=/1^0.",
  },
  {
    codes: "    1/,%.6{.56.^_.;+",
    descriptions: "İç四; >4|*",
  },
  {
    codes: "    五.().%三.43.十&.'5",
    descriptions: "86ğ/8*]{+",
  },
  {
    codes: "<十.十\\",
    descriptions: "8]七#: (;%);.33 +十]31 &^ \\六\\=ı +\";'8 ,?}三2) (七九\\:6 ş;\"8+ 三573",
  },
  {
    codes: "  >三#_.^.",
    descriptions: "四(%== (八>'[3^' %.585 三| 1{(一ı :.{8' 六,七&3) (32\"\\% ş一|'? 3三:6",
  },
  {
    codes: "    }八%^.\"/.&8.@十.0^",
    descriptions: "\"\"4# 三九\"@46%4",
  },
  {
    codes: "    九1,5.三2.)*.二1.&[",
    descriptions: "{六四*/四ı 八$四:#三}七",
  },
  {
    codes: "    0七]7.八|.|四.=1.?#",
    descriptions: "::四*4ı |三^二<一;七",
  },
  {
    codes: "    ]%.+../.*_./'.>#",
    descriptions: "@:ğ<]\"&\\3",
  },
  {
    codes: "    \"八_].[^.[九.一\\.?$",
    descriptions: "一9^5=}ü3 /8五1:(3",
  },
  {
    codes: "    七5&#.%七",
    descriptions: "98ğ五@0%:]",
  },
  {
    codes: "        [/|/.七[._五.一0.1=",
    descriptions: "'ğı$\"ı九 ==%$+&^七0\" % $,. <=:) %8六} ,9(1/ 206;5}7 七ç,3+ğ>$6 #:四5.",
  },
  {
    codes: "        _[(7.十$.\\,.$7.0六",
    descriptions: ";ğı.(ı, 十?4二$5二[八. % \\,十 '\"&六 :六 ^+8\\二}, 三ç8.六ğ|*七 1|:7\\ #1六] 51^/=[{ 0ç8&五)};<;",
  },
  {
    codes: "        )|'一.^:.十1.8九.42",
    descriptions: "4/ğ十2*二四[",
  },
  {
    codes: "        }<73.8+.&&.八{.{3",
    descriptions: "七>.]七 ;\\] +六.1/ <|5.5[^2/*4ı|0}:' ü'ü)({三286 893八 _:四%33",
  },
  {
    codes: "        ;){0.\\#.六3.}%.}^",
    descriptions: "一五.7# 2九1 =#.三| ,八[二0^^/1={ı3九)七{ ü#ü1#'八&1\\ @{%[ 六六#七十% _*=ı [\\ğ",
  },
  {
    codes: "        五一6}.}:.6\".\\\\.\"\\",
    descriptions: "\\=.[3 (?十 $?.九3 =十,四|}*七九\\.ı^@5三8 ü%ü/91},[= :六七: <)六四1@ 十[^ı \\;ğ",
  },
  {
    codes: "'七}/.8八",
    descriptions: "'*十&#.七 ü+}$一;>9 8\"六|四九?",
  },
  {
    codes: "    九\".,.&0.?三.\\).9@",
    descriptions: "Ş5ş五=一 ]五三%%四三",
  },
  {
    codes: "    五六_0.七八.'3.%#.5;",
    descriptions: "三&ğ.%',]6",
  },
  {
    codes: "  0@8六.]@.+*.&六.1}",
    descriptions: "^|\"(@$+四ı 3+ 4)<四,>5 5八ç五: +\"*07|+二 \\(_4>@#",
  },
  {
    codes: ":%.@<",
    descriptions: "六60> ü]ü九 ş'9>$ı (6八>9'4.1804四|'(/ş ş四\"6%?二6 一3九]五); ü+ü}",
  },
  {
    codes: "  :<一=.$3",
    descriptions: "4ö4ü八#ü ş@四<^(_7",
  },
  {
    codes: "      %六2%.@7.#5.3=.[*",
    descriptions: "Ş四1+)五)_",
  },
  {
    codes: "      8;&].?0.4二.六@.{^",
    descriptions: "?=六4",
  },
  {
    codes: "      |3,九.十四.九#.1^.七}",
    descriptions: "&0}.=)0$",
  },
  {
    codes: "      }1?十.七%.四}.8#.一=",
    descriptions: "9}\"* $ö>ü}'ü ş九\\二三",
  },
  {
    codes: "      六1十9./#.\\一.[/.}@",
    descriptions: ")_ğ8四&[^=",
  },
  {
    codes: "    #五%&.八&.;1.>二.3#",
    descriptions: "一70.@0.ş /二ğ}:;一 ^ş=一/:五 (二|?) 九八三(五八^",
  },
  {
    codes: "    :<{=.:3.二3.二;.6一",
    descriptions: "_(ğ七. ^@@<三 (06九 六+三 ü7ü8 ç'ş]#;\"_6< ü]+(]4,@) ş@+]{5#九",
  },
  {
    codes: "    .十一<.(二.92.三].6)",
    descriptions: "1{ğ][>,=(",
  },
  {
    codes: "    九276.{七",
    descriptions: "9五73%%\"9ı : ?<#{) 43)# 一}6} &' 4@)<],&{ 一*1七>$)",
  },
  {
    codes: "        7六62.$:.:7.]4.+二",
    descriptions: "[,(,]\\.ş ')6ş4 7ş>'十{8 (\"?}) =一{十一00",
  },
  {
    codes: "        }19}.[[.'\".|四.34",
    descriptions: "8>_十五.9ş >二ğ2:*7 一ş07#55 (<}<) +97;五8*",
  },
  {
    codes: "        九5六四.一#.23.2\\.七$",
    descriptions: "?5ğ*_ =一?十@ (/{三 ;@& ü=ü> ç3ş二.(/\"*% ü九0二&&@一) ş#*二五432",
  },
  {
    codes: "        %九@].{六.[二.4%.}1",
    descriptions: "5}ğ7_*</{",
  },
  {
    codes: "                8:二,.@}.四>.|}.@%",
    descriptions: "七79^_一",
  },
  {
    codes: "                十8=4.一8.三三.@6.\"*",
    descriptions: ">4:4/;@+",
  },
  {
    codes: "                <+七5.|\\.九七.十>.?=",
    descriptions: "'八]0[5\"/十 (十四5^{八一;)",
  },
  {
    codes: "                (九1八.六/.,|.,=.5%",
    descriptions: "@七/ (' 一9%\\3 (*|*\"* 九6]/?0)",
  },
  {
    codes: "                    九+;三.?十.=七.0[.1}",
    descriptions: "#(}九<  >*一4  .+3]七",
  },
  {
    codes: "                }',9.74.1..三@.2七",
    descriptions: "|四:'^",
  },
  {
    codes: "                \"二*(.:>.四&.5'.七}",
    descriptions: ")&,(,七十1/七.",
  },
  {
    codes: "                *4}5.06..三.>0.#*",
    descriptions: "<,.{)",
  },
  {
    codes: "                .}')..1.=2.一>.三|",
    descriptions: "\"_七0% ({\"5542)",
  },
  {
    codes: "                =九九五.0@.#?.5*.\\@",
    descriptions: "六二.三一6> (十7|@2_0)",
  },
  {
    codes: "                }二*四.>+.]?.;^.九.",
    descriptions: "|六十,;7六\\, 一'*五 \\={:8 /^ \"九\"(@7",
  },
  {
    codes: "                0<三<.1$.=;.\":.3七",
    descriptions: "963#,八",
  },
  {
    codes: "                8五@二.一].)5.三2.<.",
    descriptions: "三1八{十33",
  },
  {
    codes: "                }三50.2二.$>.)五.(6",
    descriptions: "#0三|3 <_六#_",
  },
  {
    codes: "                {2十>.三$.?8.?八.>7",
    descriptions: "十[_二/é%",
  },
  {
    codes: "                五二\"{.;#.八十.八2.}八",
    descriptions: "&5%70",
  },
  {
    codes: "                _'+9.]:._9.十9.[,",
    descriptions: "2{<]<}8@",
  },
  {
    codes: "                )\\'1./\\.#&.]@.=五",
    descriptions: "一]ğ五%;5&六",
  },
  {
    codes: "                *++:.2[.:^.}九.2二",
    descriptions: "&\"六(]_0四",
  },
  {
    codes: "                @/43.|六.十四.^@._^",
    descriptions: "九0+十5^\"=@ (八*(8一<54)",
  },
  {
    codes: "                '[九=.(%.6].&;.#(",
    descriptions: "@;'三:九[:+%",
  },
  {
    codes: "                9<,九.6九.9%.58.=<",
    descriptions: "]五[)é> '% <]@,+",
  },
  {
    codes: "                  7]*0.{}.,六.6十.\"七",
    descriptions: "三三({2)一.$  |9},12一九'/",
  },
  {
    codes: "                六9七{.四6.=}.3\".三十",
    descriptions: "2.* :) ^4(三^ (_}1,九 &\\31三+)",
  },
  {
    codes: "                3$/3.#六.9四.十_.^4",
    descriptions: "二二十}9>=",
  },
  {
    codes: "                /@(0.{'.13.}三.\"|",
    descriptions: "@.2\\\\&([ (^三九_[六七)",
  },
  {
    codes: "                \"{[九._?.)_.1五.11",
    descriptions: "6九{;一$, (%'6\"七}6)",
  },
  {
    codes: "                )3>七..].一[.%#.>'",
    descriptions: "十一>.?8{9 七8 '%\"| [一>?2",
  },
  {
    codes: "                ;8}|.]_.&9.</.(7",
    descriptions: "四.八]三一",
  },
  {
    codes: "                ^?<+.+*.*%.?[.?]",
    descriptions: ":一八, 8一二\\四<{# *] <一一七\\",
  },
  {
    codes: "                九\"}\\.三\\.\"?.十7.}&",
    descriptions: "%/*|}4;",
  },
  {
    codes: "                [5四#.{7.}%..>.6?",
    descriptions: ";(\"<五é#",
  },
  {
    codes: "                一八_?..^.]9.十<.5四",
    descriptions: "17\"^=",
  },
  {
    codes: "                九*}&.+,.(七.,(.2<",
    descriptions: ";$6.[九[9十=",
  },
  {
    codes: "                ){&..:2.[=.|:.二.",
    descriptions: "八2ğ二}@八四{",
  },
  {
    codes: "              |4,).$六.[5.{9.二.",
    descriptions: "1五^8>",
  },
  {
    codes: "              5一|五._4.([.&五.51",
    descriptions: "{5ğ'2八_九|",
  },
  {
    codes: "              一[<_.八\\.]0.$*..6",
    descriptions: "九二<2八",
  },
  {
    codes: "              8@+^.;2.#'.$$.九_",
    descriptions: "二五ğ九+5?(\"",
  },
  {
    codes: "              )四@七.九三.)八.%@.]@",
    descriptions: "4{1;{",
  },
  {
    codes: "              \\10:.\"_.>9.'9.}8",
    descriptions: "九>ğ(2五&9%",
  },
  {
    codes: "              ;六##.];.;6.<5.80",
    descriptions: "?=&九9/> ); ^(\\/$)^ 2四,]\\{)^",
  },
  {
    codes: "              )?^*.(].9十.>>.)8",
    descriptions: "8一九_四)",
  },
  {
    codes: "              \\^?#.?=.)\\..6.)4",
    descriptions: "+6(}<//",
  },
  {
    codes: "              >(七八.,:.;七.七9.9{",
    descriptions: ";41(: 51 [&十)\\0 \\二 :'3,{五",
  },
  {
    codes: "              {九\"@.\"九.\"?.|*.<%",
    descriptions: "\",[:",
  },
  {
    codes: "              六八?(.六@.?五.7六.二<",
    descriptions: "@九ğ七六|二>*",
  },
  {
    codes: "            |+一二._+.*(..#.+;",
    descriptions: ";3ğ%9九4八十",
  },
  {
    codes: "            02$=.[}._*.]..十)",
    descriptions: "二<七九7",
  },
  {
    codes: "            %#一?./五.}5.}%.+{",
    descriptions: "三[ğ/八*:,8",
  },
  {
    codes: "            \"}4五.|九.9_.9#.4三",
    descriptions: "@}^6+",
  },
  {
    codes: "            7>一..#6..1.(*.@(",
    descriptions: "二%ğ;,,'五十",
  },
  {
    codes: "            3#95.@9.\\$.;[.]一",
    descriptions: "十2五7,",
  },
  {
    codes: "            >6^<.6[.;;._|.,{",
    descriptions: ">>ğ?95/,0",
  },
  {
    codes: "    ==&七.(<",
    descriptions: "/'九(.78*ı 9 9[@#]\\二1 |\\3九三 3_.{8 ]八 二*8<2\"六九 .]|\\2 9&)\\四+# $\"71,?@; ;)+九一七{",
  },
  {
    codes: "      七{^七.85.$1.=&.60",
    descriptions: "五%十;>} @ı五二七?;ı六 *(3十=0:</*: ş^ş+ <ğ七ı二, @:3一?/@|<二ş 7七6+ğ* ş=ş六.}",
  },
  {
    codes: "              1五4六.|0.^].二<.;三",
    descriptions: "|$,&{}^4",
  },
  {
    codes: "              三\\<五.=].0].%?.二3",
    descriptions: "三十@9%9[|} ($[七)四18@)",
  },
  {
    codes: "              五3四#.8%.2&.<].|一",
    descriptions: "(4+)]\\\\?3三",
  },
  {
    codes: "              {]^4.八(.*'.<..29",
    descriptions: "%六#]é/ \"% 357[6",
  },
  {
    codes: "                {_19.3'.$4.[|.=七",
    descriptions: "五#七0四5:,:  一九%9>)$%#8",
  },
  {
    codes: "              十)\"八.@3.\\八.九[.九*",
    descriptions: "8三8 二= <\\七9+ (九五@7/ ?六,+}:)",
  },
  {
    codes: "              ;:八8.9三.<二.四5.;2",
    descriptions: "24三七0=一六 (61(<\"_0)",
  },
  {
    codes: "              03>?.|).五二.4/.$9",
    descriptions: ">?)二一",
  },
  {
    codes: "                81?4..@.[|.',.二四",
    descriptions: "3@}\">",
  },
  {
    codes: "                3?八].@,.<5.九2.3/",
    descriptions: "^0ğ9*<#'3",
  },
  {
    codes: "              }#5二.@&.&>.\"=..?",
    descriptions: ">\"\\,[",
  },
  {
    codes: "              :?*9.93.$#.1%.<%",
    descriptions: "{,ğ#|&六%_",
  },
  {
    codes: "              *%十,.+&.1?.+>.+@",
    descriptions: "三}6?]",
  },
  {
    codes: "              6\"8二.一二.+九.十>.2:",
    descriptions: "@@ğ.一五5#七",
  },
  {
    codes: "              九}七7.四一.76.['.(^",
    descriptions: "五4#*9",
  },
  {
    codes: "              }[(@.#7.一|.五四.]\\",
    descriptions: "<3ğ36八$+4",
  },
  {
    codes: "              ?=3四.十八.#六.6)./4",
    descriptions: "{18三$6@ 0$ '六)6_'? +4六6()41",
  },
  {
    codes: "              }/十<.四'.\",.80.五7",
    descriptions: "三#二六=6",
  },
  {
    codes: "              三>)..|#.3二.*'.一三",
    descriptions: "*一;:( 7, 7{四??\\ 9% |8\\+_|",
  },
  {
    codes: "              7:0%.56.;3.5).(五",
    descriptions: ":[ğ}//{_.",
  },
  {
    codes: "            十三\"=.}3.*<.>六.五#",
    descriptions: "[&ğ1{}_0六",
  },
  {
    codes: "            一三;$..?.二1.9七./七",
    descriptions: ";;)三?",
  },
  {
    codes: "            [<7八.6>.89.<四.#[",
    descriptions: "?^ğ五七3四|*",
  },
  {
    codes: "            :[三'.4#._0.七0.九^",
    descriptions: "[\"0][",
  },
  {
    codes: "            三+一5.98.二四.\"|.7(",
    descriptions: ",五ğ^七二#\\二",
  },
  {
    codes: "            .=9五.#八.2二.%^.;5",
    descriptions: "9.十@[",
  },
  {
    codes: "            /4^十.二>.%\\.@三.5&",
    descriptions: "9\\ğ}/五&)6",
  },
  {
    codes: "    {:8_.2*",
    descriptions: "}1ğ;?八3{@ ",
  },
  {
    codes: "      <四9*.{>.&六./2.1\"",
    descriptions: ">_[_4八 6ı[}88二ı# %'5二7>.一]\"< ş0ş八 0ğ'ı:9 (@2;0#一1}=ş 2=?$ğ> ş八ş九@5",
  },
  {
    codes: "              9>5|.2(.十:.)九./(",
    descriptions: "$9?}:#6]",
  },
  {
    codes: "              ,四一/.70.@9.[六.\"3",
    descriptions: "85[17六[六5 (*一)}^@78)",
  },
  {
    codes: "              5>三$.<8.9{.{四.96",
    descriptions: ";三>98}.+一;",
  },
  {
    codes: "              六^3十.%..十二.九#.一=",
    descriptions: "1.9:é五 @九 (.2二四",
  },
  {
    codes: "                |1_%.,8.+{.$八.[四",
    descriptions: "(+五3]8十99  4<9*二#5^%:",
  },
  {
    codes: "              ^八}:.三二.\\六.四].@?",
    descriptions: "}*} .' }>1&^ (*\\:&} '九|?42)",
  },
  {
    codes: "              +|&+.6<.]九.[:.九,",
    descriptions: "@#一五8);{ (五'|2/*')",
  },
  {
    codes: "                三*七0.八九.三二.六+.(}",
    descriptions: "&&六_%",
  },
  {
    codes: "                .<三*.^5.3四.)六.九'",
    descriptions: "二.ğ'2?)>.",
  },
  {
    codes: "              /822.)+.*二.:<.;$",
    descriptions: "#/](?",
  },
  {
    codes: "              @&%3.$(.),.;(.9%",
    descriptions: "\\1ğ|)四,{六",
  },
  {
    codes: "              )}十_.'十.#{.{{.\"]",
    descriptions: "97&&3",
  },
  {
    codes: "              8[&:.九^.八四.4^.^/",
    descriptions: "]_ğ:}%@七;",
  },
  {
    codes: "              $$\"..;].$:.6>._四",
    descriptions: "六+$二_",
  },
  {
    codes: "              9+6<.*<.39.\"?.;\"",
    descriptions: "$6ğ/+4>*%",
  },
  {
    codes: "              @#七&.?;.,@.{,.(8",
    descriptions: "{九[,)^@ ,( ')8:四八= )*2*#4$九",
  },
  {
    codes: "              五14'.}/.=[.八$.>}",
    descriptions: "[=>#6二",
  },
  {
    codes: "              四1]4.?十.七八.<2.11",
    descriptions: "+三[四二 十6 8七[116 ^* 四/_/3'",
  },
  {
    codes: "              二十9九.\"%.八#.+五.四七",
    descriptions: "[#ğ/(']五0",
  },
  {
    codes: "            ^$.9.':.8).):.^{",
    descriptions: "十4ğ\"&4*/:",
  },
  {
    codes: "            ,_\"七.3&.^{.四<.2十",
    descriptions: ";八十2+",
  },
  {
    codes: "            4\\+7.[*.七0.*一.二^",
    descriptions: "九7ğ二十(九'三",
  },
  {
    codes: "            %*55.('.=五.六:.五;",
    descriptions: ":)八六$",
  },
  {
    codes: "            1+八[.:四.][.]*.[七",
    descriptions: "</ğ(八[;3]",
  },
  {
    codes: "            3:[\\.8三.().一\\.^:",
    descriptions: "1{]4六",
  },
  {
    codes: "            55@=.]#.十一.5>.$2",
    descriptions: ">^ğ/%一|九+",
  },
  {
    codes: "  %&四7._]",
    descriptions: "])ğ五; ü1ü九 şı8三_}8ı ",
  },
  {
    codes: "    七^3六.]三._三.%}.九(",
    descriptions: "].七0#}3'6\\=;> 1(.80 /%十@ \\[$2 一472%@.7九五/八@ 46:八八 _;]>12一(八6二 三四ş六>",
  },
  {
    codes: "        '四'$.^\".}?.*/.三七",
    descriptions: "?:}06}一.七 六;三\\一]ş",
  },
  {
    codes: "        六*2三.\"[.五十.\",.0}",
    descriptions: "三9ğ?七七6\\八",
  },
  {
    codes: "        二0\\五.]三.73.十3.._",
    descriptions: "十十#五六6|/9 5八58七(ş",
  },
  {
    codes: "        四;&九.五8.)2.九%.(\"",
    descriptions: ">七ğ%?3;|五",
  },
  {
    codes: "  $\\.八%",
    descriptions: ";[;十_6 :4 }7ğ$| 九5二九 ü2ü一 ş一(\\=\"一九ı (|}*/7[$八 60\\/ &^\"八4/ \\)[}>",
  },
  {
    codes: "  ;(五7.5_",
    descriptions: "{九;}|?十%ı | +{3二| ({:五 六六:: 6_ {$?_|'09 五,2/一_{ ",
  },
  {
    codes: "    一六2七.二[.十&.$二.?*",
    descriptions: "九七十十六 =三六00]七^ [1<七? /:'}<七++六= % 18 {[9: \\三^十 :{ >([,\"\"_",
  },
  {
    codes: "      六6\\%.:3.$,.1$.09",
    descriptions: ">9十:六 {\"\\四]%九{ $4(^九 0九_一三,#0>2 % %2 '一%] 一49[7 *(_4& % ?,'四七< )五",
  },
  {
    codes: "      (九十三.=0.\\;.\"3.5^",
    descriptions: "六93_0 [4/#二$#} ?8%\\七 34/<47{<}0 % 4( ?$ ?,5@9 ):4$七[\"",
  },
  {
    codes: "  ,3\\六.@7",
    descriptions: ">)ğ7六;0[一 ",
  },
  {
    codes: "    8&七..\\\\.\"..=\\.一0",
    descriptions: "[&2?七 2>[]\"*十5 &;5;2 :['4.;|?0{ % 七' 二9}{ }?@6 八? _5$二三=一",
  },
  {
    codes: "      ^八??.(:.九\\.]九.8]",
    descriptions: "4#五$十 ^三$?\",{[ $'五十5 ],1[,*6+5三 % /8 '815 八3六:1 八七九}八 % 十一'六三* 八2",
  },
  {
    codes: "      6+*$.三=.@=.&=.57",
    descriptions: "1;1,@ ]0/4七#(0 ;\"@;& 7:%*8=4#(] % @九 8: 九[':一 八^$#(5,",
  },
  {
    codes: "&<$3..七",
    descriptions: "<六二五五\\]6 50%^}3ş \"二ğ|5 =ç&:.五?2? (二九<8 ş.七|1ı, _五九11 ş894四ı, .[&",
  },
  {
    codes: "  72\\0.4四.&2.+一.@5",
    descriptions: "[(八9二 1四\"#]一.ı3$5\" \"]:ı97@\\(二",
  },
  {
    codes: "      75)4.七^.7&.9四.0|",
    descriptions: "|五^九 ş\\三&2ı ?^ 六(五.八 ş0|二一ı",
  },
  {
    codes: "      十=>五.@;.)*.'四./一",
    descriptions: "0>ğ=7$:五,",
  },
  {
    codes: "        一六2十.7七.$九..[.$四",
    descriptions: ":$}5 ş3?&#ı +7 '十2六& ş4\"1九ı",
  },
  {
    codes: "        %@{*.*..\\6.01.\"%",
    descriptions: ".五ğ:,]十{@",
  },
  {
    codes: "        ,[][.七[.6/./三.24",
    descriptions: "9%九八 ş$#0^ı /> @6*五< ş9}一六ı",
  },
  {
    codes: "        +#[%.8\\.8].99.%三",
    descriptions: "&八ğ8,,5{二",
  },
  {
    codes: "3=.?;",
    descriptions: "{+&6六 }:/;({?$ \\?/九} (6四[:6\\七七九 % 7< ^|,九 /==0 十9}5+ 0)$; ?[ğ三.9",
  },
  {
    codes: "  _@&六.+;",
    descriptions: ">>?<\\ 一&69\"三40 \\<^一; 八)__803&}@ % 33 二$三# 九8七4 ?6,_一 [0$@",
  },
  {
    codes: "      四5.^.@九.|\"../.4#",
    descriptions: "_ö6<四 一/二4 }_三)(",
  },
  {
    codes: "      \"%\\;.0\".十\"._#.%\"",
    descriptions: "2{_?八十#五ı #|}/ 5(#,:",
  },
  {
    codes: "      9二\"?.八#.\\/.+=.二;",
    descriptions: "九ö八13 ,18@ 5六4]'",
  },
  {
    codes: "      /74=.四9.&五.七(.2七",
    descriptions: "{.?六三1五[ı 4五?5 十#*三2",
  },
  {
    codes: "  ?六\"[.]3",
    descriptions: ":=十:> 5十._/(]# |5 {*?[8五 9一二2七 &}ğ五九三 (|@].三ü={) ?<6八[?ş 十六;一 @#:_% ]*",
  },
  {
    codes: "        $二七*.61.(七.59.6二",
    descriptions: "%\\@ /^?* @{三| )ü_<三} :^二*ı^八3 三\\=^[$=",
  },
  {
    codes: "        92九[.\\七.]<.$六.,七",
    descriptions: "#;ğ\\+(?*九",
  },
  {
    codes: "      六8<\".5&.九7./一.=5",
    descriptions: "一=,8$5十5ı =\"{三 (?(/4",
  },
  {
    codes: "      )]三四.96.4&.+^.7九",
    descriptions: "@}&1四2ü 7ç5\"]八[",
  },
  {
    codes: "      $六+'..九.一$.七9.&二",
    descriptions: ":ö\\$? 六九七> 一0'*;",
  },
  {
    codes: "      五'一,.<;.:2.=_.1?",
    descriptions: "'$:@&@{7ı 1;$' \\.+>十",
  },
  {
    codes: "      .9/..$四._五.6=.*(",
    descriptions: "=七.$十{ü &ç6:^六@",
  },
  {
    codes: "0六.@1",
    descriptions: "<57&< >五九'}7<} /)+46 4$8<*[&#一2 % 一2'八80 $| 三,三& .'ğ:&< (>_二[\"ü四?)",
  },
  {
    codes: "  &#:@.$8",
    descriptions: "Ü3ü| ş*六八四ı 1_<. ü7ü七 =1+_8/十1({ 7\\<ı]ı十;/[ı ]$\\_ [$} 七':4",
  },
  {
    codes: "        二0_:..'.一三.三].}{",
    descriptions: "八3一9#七",
  },
  {
    codes: "        九?/$.#五./'.;*.,#",
    descriptions: "十}9&三$,<",
  },
  {
    codes: "          七十3..六六..*.]:.0[",
    descriptions: "2<$九/ 四('|&5*=",
  },
  {
    codes: "          四?)七.[9.\\?.[}.}?",
    descriptions: "5八ğ&12@}六",
  },
  {
    codes: "        1五@3.}4.{=.三}.=2",
    descriptions: "\"三ğ*一#''1",
  },
  {
    codes: "        *:),..\".七0.8五.八九",
    descriptions: "#*8%_九",
  },
  {
    codes: "        $^|三.+'.{[.^&.*\"",
    descriptions: "十=ğ'四三5*=",
  },
  {
    codes: "        七3*:.\"$.6一.)2.5<",
    descriptions: "$+{三:)",
  },
  {
    codes: "        ,):一.三/.:+.7@.\\/",
    descriptions: "&<\"#0' 七&十} $*,46}六,0",
  },
  {
    codes: "        )八/五.|+.|_.1七.3七",
    descriptions: "2三ğ)],\\七4",
  },
  {
    codes: "        84%_.8二.|2.#\".七(",
    descriptions: ",)$<$>",
  },
  {
    codes: "        >@7五.72.九+.八=.1.",
    descriptions: "?\\ğ9;一;&0",
  },
  {
    codes: "  .4十@.]3",
    descriptions: "八;{]*'九&",
  },
  {
    codes: "      <<,一.二%.*|.\\^.>_",
    descriptions: "\\/).九2'\\ı 十 九?二.二%& $'ç1^[\"^ 5]>%>^8> *=$#:&&",
  },
  {
    codes: "      }三四三.'=.六>.$二.四_",
    descriptions: "+8#八一<_@ı ] 五^?)(.' ,#ç%8 五@'六\\,二3 >>[四\"9三",
  },
  {
    codes: "      一;.[.\"十.,|.]@.六}",
    descriptions: "$.> 9七#六 <{%1($}",
  },
  {
    codes: "        '._|.=八.:..八九.*$",
    descriptions: "608:一\"(&ı 十 )8十十*/\\ {:ç五/十*8 二123九@8\" }3七[\\(六",
  },
  {
    codes: "        ,#.:.?\\.6].?#..(",
    descriptions: ">五9?[\"&^ı % +028五}} (#ç=> ***6$#'> %:*])6$",
  },
  {
    codes: "        &五%^.5).0\\.\"4.二)",
    descriptions: "(@.*_]#]ı 0 八/\"\\五6+ :,ç6_/*^ )|#\"|?]: +八_^二).",
  },
  {
    codes: "        二@43.#5.:_.}六.40",
    descriptions: "9__#;\\=二ı 4 七?_}?@4 七3ç%* /四八>七@(% 3))七%*^",
  },
  {
    codes: "        六;3'.%'.[$.六一.2二",
    descriptions: "8)#九五$[2ı $ ^_,.0?五 36ç)九_\"5 6二?/五/2+ \"\\7'{{(",
  },
  {
    codes: "        0<=\".5九.^九.三&.六4",
    descriptions: "四'(7?:;\\ı 3 [六@[{{2 <.ç_{ 0*#.四[_( 七$;\\4]$",
  },
  {
    codes: "      >|.3.\"\".?十.二;.;二",
    descriptions: "{:(/4八+=ı 1 &]??;12 $3ç4)8&( :5|一1)@= ;七九^(十4",
  },
  {
    codes: "      #\"四1._).)十.\\>.9(",
    descriptions: "八\"\">@'>+ı | 78\"一];7 (二ç._ 四:$?#八<: 九+'(2?9",
  },
  {
    codes: "  {]]二.(/",
    descriptions: "_@六 95 25*}|:|\" 9;六?1}ş ş(8$& 0(5ışı ü\"ü&('7'/8/ .^四ı8ı7}0<ı[<三",
  },
  {
    codes: "      :+89.}{.}'.:(.八%",
    descriptions: "#十( /)八三<ü: )(0三323_@八五49[' 六九[ $二+^ 8|]6",
  },
  {
    codes: "          8#十十.+\\.]..3^.+:",
    descriptions: ")_&",
  },
  {
    codes: "          >?]@.<3.三_.:/.{3",
    descriptions: ")三2二七",
  },
  {
    codes: "          =[_'.%'.\"$._一.#.",
    descriptions: "'[\"",
  },
  {
    codes: "          (五七<.11.八3.&/.三>",
    descriptions: "[86五一",
  },
  {
    codes: "      #52&.二%.一%.[_.}?",
    descriptions: ")16 六三;九=ü二 九5九@十24;九2]$#,} 7^; 五<7| *2_* ,#{七 @+6八] /}&@ /\"",
  },
  {
    codes: "          )九:1.|:.%@.三:.?九",
    descriptions: "$*(",
  },
  {
    codes: "          一$七三.十1.#七.\\^.七6",
    descriptions: ";六7^5",
  },
  {
    codes: "        '六$六.}2.\\2.六二.^#",
    descriptions: "四四ğ.:,=}二",
  },
  {
    codes: "  2,5[.@9",
    descriptions: "{62 {) \"十(?..",
  },
  {
    codes: "      ^\":三.3+.五4..二.五(",
    descriptions: "/\":,八;{4ı 1 二{三]7]) +八ç5>九*? 4五,[,>十, _一@),(六",
  },
  {
    codes: "      2;8'.)3.#\".'九.6}",
    descriptions: "&0>7一'53ı [ 6六三*/2( :8ç?2 67_73[8* |41[$)三",
  },
  {
    codes: "      ))?5.一:.$>.\"&.|5",
    descriptions: "8/>}|}1;ı 十 +*{5一七+ 四,ç.^[({ 76五'6[五3 3^)}0{九",
  },
  {
    codes: "      [#%六.'八.\"2.{二.}十",
    descriptions: "/七1.9)<)ı 九 十5\\52\\; ?)ç8= *9四(;\\8] 九1五60_6",
  },
  {
    codes: "  八;十九.}三",
    descriptions: "(]21\"",
  },
  {
    codes: "      九1[$.<).,%.62.;3",
    descriptions: "6;\"8/4<八ı \" :&七?7/1 \"{ç四0六,六 /+):(^六9 %_0六^$}",
  },
  {
    codes: "      +?#_.1八.75._十.*^",
    descriptions: "/>+三))*/ı 四 /]三%二|# 十五ç{8 %)&4.[15 [八+#十一#",
  },
  {
    codes: "      7六6六..,.}五.%\".%_",
    descriptions: ">+?七七/四]ı _ <十29+2, &7ç%818) 7[{2五九#@ )&<2}}5",
  },
  {
    codes: "      (六4五.]/.3@.<七.$八",
    descriptions: "\\,%7+>_*ı . *(]九+9九 &(ç五< |^*93;+\\ <一/一79\"",
  },
  {
    codes: "  *}:2.$>",
    descriptions: "?>六ö八.,%",
  },
  {
    codes: "    十#6|.7^.=%.:<.八*",
    descriptions: "%/5七6\\+7ı \\ _8'0\"&/ ;^ç%'8+四 ]八二41#>^ 十\\\"]十96",
  },
  {
    codes: "    三2%'.=0.@\\.八<.四$",
    descriptions: "一'$3三?{}ı ( }%5|+)九 <\"ç一+ #:|{2$一{ #六五一{0.",
  },
  {
    codes: "  03*?.四1",
    descriptions: "8{ğ3|789@",
  },
  {
    codes: "      四/&%.&九.{五.五&.九&",
    descriptions: ".七八六6七7九ı ? 6)2$1|六 /;ç#(,*# 八6#4+>05 十'23@六#",
  },
  {
    codes: "      5五$7.七十.9;.0}.^'",
    descriptions: "^)&四九@6二ı : \\+四3+一5 _@ç7| 3|\\+9一*一 3=#一.<,",
  },
  {
    codes: "      _六一{.%0.{7.^\\.7/",
    descriptions: "二;九5$三52ı < ^八)六2三0 六\\ç\"8三[4 ]2}^+二八: [6\"六>#$",
  },
  {
    codes: "      6六'6.*_.一).(?.4&",
    descriptions: "+二(},\"\\<ı 7 3@,_;,{ (三ç一% ;^*'7九*五 )28/三9>",
  },
  {
    codes: "        二40十.九).\"2.*8.)4",
    descriptions: "3.}",
  },
  {
    codes: "              五#四五.七1.'1.,{.9<",
    descriptions: "#(#九\"#[4",
  },
  {
    codes: "                #?八7.九^.98.)).)'",
    descriptions: ";1<ı",
  },
  {
    codes: "                %|*(.0*.&$.>$.6=",
    descriptions: "<:ğ\"]@7#*",
  },
  {
    codes: "              \"+&<._8.&+.,;.四@",
    descriptions: "六<3[三&",
  },
  {
    codes: "              九*六].{二.>+.{@.[\\",
    descriptions: "%>ğ]/=[*+",
  },
  {
    codes: "          八[)7.>{.2一.7+.一一",
    descriptions: "{十ğ]+ $七681&ü (ç<+_8_\\五",
  },
  {
    codes: "            %9*4.&八.六=..1.7]",
    descriptions: "2六八ı",
  },
  {
    codes: "            十二(4.>'.8四.7*.\"$",
    descriptions: "*(ğ;)四_|?",
  },
  {
    codes: "          五[七4.+..九七.+,.9二",
    descriptions: "八十:3.%",
  },
  {
    codes: "          3;二>.九九.:1.5].八\"",
    descriptions: "*五ğ\\\\六七>,",
  },
  {
    codes: "        _一%二.={..八.八_.<[",
    descriptions: ".;ğ9* {*<5%八ü <ç;一$$三|6",
  },
  {
    codes: " ",
    descriptions: " - - - ,>$十6:九}ı . <四九\"2^) 15ç+?>#, 二+八69三\\. {10;)4六 ",
  },
  {
    codes: "        ;^['./'.*(.<@.)_",
    descriptions: "五九1ı八 ü4ü{+七二+六}6] [&// .|]7#_[三十",
  },
  {
    codes: "        1,8(.#1.六3.六^.四}",
    descriptions: "八0ğ八]六3#=",
  },
  {
    codes: " ",
    descriptions: " - - - $<>#1七8$ı . (1}>(;九 8九ç{; 八9七\"]&&六 37|七[五八 ",
  },
  {
    codes: "        <.[^.八2.,3.&:.6五",
    descriptions: "2/[ı, ü\"ü(,六,27,)% ,八'\" 4.五(九[八:八",
  },
  {
    codes: "        八=8(.7'.64.8\".]&",
    descriptions: "六=ğ?四+46{",
  },
  {
    codes: "一#\\>.|.",
    descriptions: "[.*,*@十| 6; {\"#七0* +)六*=^. 四[九[ \"#}2^9 ]8\",九 ]三&=,/ ;五ç;<",
  },
  {
    codes: "    2%,|.)9.,一.'四.\\#",
    descriptions: "六}三,[七+三ı 4 ;六174=四 .@ç七9一二[ 22('二4一[ /(七}四#/",
  },
  {
    codes: "    '(一&.8+.+,.@3.3)",
    descriptions: "二八九,\\&)<ı _ /:\\0,4, 十2ç$' +;()_?九五 \\0二/%四?",
  },
  {
    codes: "    四4&三.)|.0?.^'.\"[",
    descriptions: "=?一1一#4=ı , 72=]54六 :8ç]九@/% $七%六#五0\\ ._八十(*0",
  },
  {
    codes: "    ;@{1.五八.33.$8.[2",
    descriptions: "%?(+=*][ı 7 _88^{// <%ç%三 1'7:一^6八 [^>2四2^",
  },
  {
    codes: "8>.8\\",
    descriptions: "'七2六ğ@二0çı六ı);十 八十六@三八ı二6& 九\"';5; .]五{ :5:九/+七%9ş >十ş }38七ğ\\39",
  },
  {
    codes: "    51四|.七?.(].7=.0$",
    descriptions: "十   ışı4/<<ı >ç\\\\ |,0:/四七",
  },
  {
    codes: "  '^六{.)2.|..三十.4[",
    descriptions: "@3ı8(> })&*四$+四 \"/0七]0 四[1%ğ一_= %7\")& [9五五%.9",
  },
  {
    codes: "  四1+?.^一",
    descriptions: "七(ğ:> _93#2 >/ _8>^%<七 (十@五99<七? '*0 @67五'ı '+9 \",.*[ #7ç7>}二<)",
  },
  {
    codes: "    _{,|.四_.#8.{>.=6",
    descriptions: "二5;ş一 六,二ı/ı 十ç,6 七[}46+ 四<7] %七{@}]七[$ş %<7四?{,五^ $73=>6'2",
  },
  {
    codes: "    ?(四$.%\\.#四.\\8.\\#",
    descriptions: "])ğ^>三>(]",
  },
  {
    codes: "    6>?二.0].五四.2>.],",
    descriptions: "八)七}:五 五一七)ğ9^# (.5五43399) \"ç7八 ={\"9=6ı?六四 1八1二#82",
  },
  {
    codes: "    +.?_.=+.8+.3四.2|",
    descriptions: "二<ğ\\%<*.五",
  },
  {
    codes: "三=.?8",
    descriptions: "1三^;ğ$+^çı.ı|(. )八>#^)ı八三/ /{:_ =<#/*六< >)[(6+ 0}'+ 二八%+5七*45ş _<ş",
  },
  {
    codes: "  <>.\".=八.5(.@四.8|",
    descriptions: "?  ışı,[五三ı 7ç3_ +\"\"(>9{",
  },
  {
    codes: "    @'6|.]\\",
    descriptions: "8?.14| #0十}ğ六0七 (\"一十]}7],) 六ç^| $1一\\九$,",
  },
  {
    codes: "      九:十8.+'.二_.\\三.;=",
    descriptions: "6$=,二: 四7%1*一| 7}=[ ;",
  },
  {
    codes: "      @;2\".+2.//.^0.)\"",
    descriptions: "六=ğ,七]>五@",
  },
  {
    codes: "    ()七9.四(",
    descriptions: "/&ğ七*^7_\\ (&ü|üş ){,/&四<ü( 六;ü6;九7二二6三) ",
  },
  {
    codes: "        三'九7._一.9\".7#.+]",
    descriptions: ".&@_1$八十|=九<; ^九[[.^ *%:/4{十0ı 三ç'^ @,[69[=",
  },
  {
    codes: "        4)五7.43.?,.八1.:$",
    descriptions: "./ğ四[\"8(\"",
  },
  {
    codes: "      6十9&.@&.$$.二1.)九",
    descriptions: "八\\@ 16 @7. 9/ +$ç_四七9>",
  },
  {
    codes: "    >7八三.一[.9=.+8.<?",
    descriptions: "@八ğ<?6@{3",
  },
  {
    codes: "    1)?二.六&.}_.,十.96",
    descriptions: "])| \"七] ;'. #1 ;0 0}十{/#ğ八 (7_ [. \\: 十[ç<? 三七0?二? 4;8{ğ七3十",
  },
  {
    codes: "    一1'4.<九.}..@3.1+",
    descriptions: "={\" 213 二[. |2 六四 {一#六':ğ九 :2@ 七. 二6 七^ç]5 $1'_%#十 (四%'\"七%",
  },
  {
    codes: "    558;.八\\./>.1$.>九",
    descriptions: "_;四 &四6 +<. ,_ 9三ç1@, +)5+4\"ğ? _+< $. _八 六&ç四九:#二 ,2.'三)2",
  },
  {
    codes: "    7<七五.#_.15.:@.|三",
    descriptions: "<9} :五2 0_. 8七 /;ç0&, 九九7_一 +(七 \\;. \"+ )<ç_3}七二 }九@3\\^+",
  },
  {
    codes: "    {5&?./?.<八.]8.四十",
    descriptions: "(*4 +/ 五|. ${ \"\"ç\"2|七+ [0_1(5\"",
  },
  {
    codes: "    72)|.|六.>(.;十.6(",
    descriptions: ";:> 九7 三5. 四$ ;四ç五一, ]5&)五 {( 四0. <1 7;ç9\"六,2 5# >二六&.&ğ(",
  },
  {
    codes: "    ;3\\七.二九.@>.二=.=二",
    descriptions: "@;/ {( 一\\. 二1 +)ç,6 4'?<% *0 }>. 三2 ;四ç三|七$3 1{ ?8;6/4ğ]",
  },
  {
    codes: "    十四七*.五].一二.&..=7",
    descriptions: "{'@ \"# @9. }/ /<ç': 7;)$\\ ., 1+. :: =:ç$三=二八 .: 七六(,8八ğ1",
  },
  {
    codes: "    九/6=.\"四.八2.^].4>",
    descriptions: "六{6 @5 &7. }_ 四九ç七2 $05_0;>",
  },
  {
    codes: "    八8'1._6",
    descriptions: "0{7 <} 4*. |# {二ç3_4,六 )三 >'?82>ğ+ '> 0._* 二5ç';:9一 %<\\(2$八",
  },
  {
    codes: "      九|5;.$%.65.二二.%8",
    descriptions: "*{.四) 七}四#?1一; 六=一,*_ ,八五三_&'$ı 五ç37 9.]|(8?",
  },
  {
    codes: "      六[+<.6|.=十.一\\.:^",
    descriptions: "4$ğ+?1\"7^",
  },
  {
    codes: "    @).八.)$",
    descriptions: "8%五 $/ 0&. 6/ 八0ç4_:1& =: 六0>632ğ2 4+ 1.}5 3八ç八\" 9%+44%'",
  },
  {
    codes: "      4}>[.;6.9#.4,.0]",
    descriptions: "五九&$= ):;五六9,; 9六0#4. 0\\#:]六7>ı 5ç#9 ^73;*八二",
  },
  {
    codes: "      =]]2.}>.\\1.十7.=六",
    descriptions: "9八ğ_#八.@5",
  },
  {
    codes: "    八二三三.\\\"",
    descriptions: "?|& 八= \\/. 92 _'ç十2 $/)%十>:",
  },
  {
    codes: "      二;\"].四(./?.'?.:(",
    descriptions: "二3@@三|ğ七 '三 @. &7@二 ^四99 4/ /六45?7=",
  },
  {
    codes: "      5%<一.2八.)3.一十.&'",
    descriptions: "9+#|{五ğ6 一7 %. ^0十 %=['6 %三)八|*一",
  },
  {
    codes: "1#.十六",
    descriptions: "十六/十ğ19六çı,ı八^九 _六三@9,ı2八% &)?九*^ +)'* :3('(2一十{ş |/ş :(ğı3, \"9*16>",
  },
  {
    codes: "  三[^).二八",
    descriptions: "&一+_ <<:六五(四 @\"八5 ?_3 _十3 ,_. 7四 3}ç3\"三#\"",
  },
  {
    codes: "    3五+#.'|.{一.四2.:/",
    descriptions: "|ü:九 +&十四<六_九2ı:)3 四/41%二ı/1五 :<ğı2@@0",
  },
  {
    codes: "    /三]).四\\.5$.(#.七*",
    descriptions: "五@ğ%八^/{%",
  },
  {
    codes: "  >九{_.);.三五.)1.\"\\",
    descriptions: "一>ğ}=)三&2 (四九十>@< 8%1=ğ[{' \"2$}2六]{三\" $ç八])",
  },
  {
    codes: "  %_>'.;\".=(.九+.##",
    descriptions: "1一ğ,}@四2;",
  },
  {
    codes: "7??..?4",
    descriptions: "2+二9ğ47{çı九ı8,( (%_七\\(ı<六二 ??252, 6;%一, /|ğı5, 十四十#|4 5$ 22>五%,*3",
  },
  {
    codes: "  6七6六..*.1:.@&.[\\",
    descriptions: "<])\"二\\89 ^* /(*六%]1",
  },
  {
    codes: "  /1$4.十三.3'.五六.9,",
    descriptions: "'0ğ十;[}}九",
  },
  {
    codes: "0六72.:5",
    descriptions: "/>.2ğ九26çı六ı5/五 )八_){8ı|%五 九]二|= 七' =90\")9| (+\"\\6 ^{ 十+6六[;.9",
  },
  {
    codes: "  +57三.2:.*六._2.^_",
    descriptions: "0&7[+ @^四二ı _ç@2 1=$&:+九",
  },
  {
    codes: "  +>+..$}.#,.*>.,7",
    descriptions: "&$ğ十%.1六5",
  },
  {
    codes: "  '{.4\\",
    descriptions: "1,+531*ı5ı$(二 二[@/%.ı@>七 十[$$;一} (一3{7 +] 2+$5]一七\" ]三四3\"#ş) (6>,",
  },
  {
    codes: "  /一){.(/",
    descriptions: ",}六 7五 #,. ,}%_ 7?\\八 九十:二> @]}2二]5",
  },
  {
    codes: "    2,%&.+..五#.]2.{/",
    descriptions: "23%四5: #\\_1三&]\\2$1 0<|七0@二; 48九,'[?2\"7<]<; %_四 三**_\\8一]|5?一[",
  },
  {
    codes: "    ?^四7.一,.\"_.%6.\\七",
    descriptions: "}[ğ一8 $2>\"六6二9;九",
  },
  {
    codes: "  6(^三.?*",
    descriptions: "/*ğ:二一;62",
  },
  {
    codes: "    一三$%.9}.5六.十四.三2",
    descriptions: "%#&@9{ =九九.三二>五,%五 @@七+4*3; &3四14(?4+}2){; @八2 六_{#&^九3一\"22?; .'{ü九_十93",
  },
  {
    codes: "      *\\|:.>七.:九.?(.三0",
    descriptions: "*]9 十[ #[. @11 )\" 9<九四六一#",
  },
  {
    codes: "      三+]^.8@.42.#二.=;",
    descriptions: "%<二 |二 四6. 3].% 9_14 1\\八)_ 4>四(四%|",
  },
  {
    codes: "  ,9.]二",
    descriptions: "五;3^ğ%九5çı.ı.<\" #/>7.八ı>., 35)+五\\|% ;ü]4\\43%;5:| (九,$五7|4八|, $2|)$九",
  },
  {
    codes: "  %::\\.^1.'七.$;.32",
    descriptions: "8ü0'二'[&七 \"'_十.一 @十}/ 九3四)6}八四+ :十$}20 十\\ü3&>}:_0.]",
  },
  {
    codes: "  )\\四$.4\\",
    descriptions: "3]ğ8_&==4",
  },
  {
    codes: "    1{[5.<4.^&.((.四九",
    descriptions: "0/}'3(51 ]&8567七$ '% [^\\=&=4ş九四五八)六\\五|",
  },
  {
    codes: "    +九:1.$$.三<.(#.|}",
    descriptions: ">9ğ}?@68%",
  },
  {
    codes: "八{.八二",
    descriptions: "八][?,@5} 4< 4](6 :<:&071}|五820, {6八3ş ';('ğ| (九%_>5六06{ ?四七}=4",
  },
  {
    codes: "  %六*七.>二",
    descriptions: "7,96十}]十 *四{94(十1$%二7]/ 5].]\\5&",
  },
  {
    codes: " ",
    descriptions: " - - - -  1七55' .5>^&+5=一^ 8.4ı&@ış 1_;1六五#五. ',六[<1七(:<}5 ",
  },
  {
    codes: "          \\.)7.九^.三&.{9.:五",
    descriptions: ",/ 三六[七九<七8> *?ç6<3七|+五+",
  },
  {
    codes: "          }_<8.十#.9\"./;.1八",
    descriptions: "}6 九(2\">%;九9 2十ç9{[.\"",
  },
  {
    codes: "          >^4;.8七.6&.6九.>\"",
    descriptions: "二2 [八\"620\\\\四 )@ç'#九}3\"二\"",
  },
  {
    codes: "          |一'5.<|.[<.&2.'9",
    descriptions: "#' &\\[_五+九'4 *\\ç八*>80",
  },
  {
    codes: "          _;|&.七{.43.=5.#1",
    descriptions: "&二 五八_>_:*38 ='ç\"2?]#+^:",
  },
  {
    codes: "          8+'九./1.1=.3#.三[",
    descriptions: "$八 七:\\&'>572 八/ç1+{9:",
  },
  {
    codes: "          7_[=._$.73.一三.三&",
    descriptions: "七. #}@九04&}* *5ç一.8九十{2$",
  },
  {
    codes: "          773九.八六.十\\.#%.;[",
    descriptions: "^/ _6^四六&\\/8 \"(ç[00三八",
  },
  {
    codes: "    \\5)9.'九.)^.十..7#",
    descriptions: "/=|:/]七<. 九,%%}九 /八|ı../7> 6七{三 )2,六;]((四ş",
  },
  {
    codes: "  ^[8&.8_",
    descriptions: "/^4# =九}三六*;*:3?(@8 /\\}{|'@",
  },
  {
    codes: " ",
    descriptions: " - - - %四 8$*+3#二]9 19ç0*)+?']4 ",
  },
  {
    codes: "        五72'.7%.二\".九/.1九",
    descriptions: "{68@$6 .;8ğ{ 6{/(ğ%",
  },
  {
    codes: "        $,]#.3;.<:.>..3$",
    descriptions: "^五六九'\\ 4$十ğ@ @三^|ğ@",
  },
  {
    codes: "        #(八九./@.87.:,.<:",
    descriptions: "/2十\"8六9 @^1_一ı (<3, %一2?九?*_六\".::\" 四*2}\\%69",
  },
  {
    codes: "        _=55.|^.@二.一六.)十",
    descriptions: "\",八二 (.\\三#+'[%?\\五]七 .9ğ十四 /<6>}二_九",
  },
  {
    codes: " ",
    descriptions: " - - - )? 十二5六九]7四一 58ç6七812 ",
  },
  {
    codes: "        |+\").三二.八>.七+.二一",
    descriptions: "五\".*08 %六<ğ# 九9\\八ğ+",
  },
  {
    codes: "        >二一3.%[.]/.+8.$5",
    descriptions: ")}50># 45.ğ\" +七\"6ğ6",
  },
  {
    codes: "        四]*,..8.@5.6三.7:",
    descriptions: "/8'=8*) ,\\九五'ı <7$' ';;{八32@$八,/&7 >$:%*)3?",
  },
  {
    codes: "        *+九+.一*.;\".四七.4)",
    descriptions: "七6$五 ?5$+86)$|\"}|{六 '&ğ十/ 一'_六五$^七",
  },
  {
    codes: "    \\=3=.'4.;三.81.%8",
    descriptions: "<(%八'二7^0 |@二1六7 二&六ı_$四二@ 四._七 %1[<=}二&一ş",
  },
  {
    codes: "/2.>,",
    descriptions: "32>]{#九_ (*?九8\"\\6 9?.四>{$^二 (:';%ş 三3@\\ğ二 ?'?$ç) (8[6/\".+%七 六#1/:+",
  },
  {
    codes: "    2四九;.04",
    descriptions: "2+9%]5\"^3六$} =九{_二})",
  },
  {
    codes: "      \"^@六.%].?\\.51.7.",
    descriptions: "|? %5.一49.20 2>ç7四_7{$}2",
  },
  {
    codes: "        ]*}八.7,.55.$5.]3",
    descriptions: ">$3,;\\:8 #@\"ı>,ğı 9<=$^_4三^ (+[^ı二@ığı (8 3= )四 |&4'|, 九)7 十5|{_",
  },
  {
    codes: "        [三(四.五{.&\\.\"|.>&",
    descriptions: "%6ğ:三)85.",
  },
  {
    codes: "    @八四三.\"1",
    descriptions: "九|ğ六'二%|三",
  },
  {
    codes: "      5>9%.>9..<.=,.\"0",
    descriptions: "20 [五四六_<\";% 8;ç5\"六八>六{7",
  },
  {
    codes: "        {三)十.五'.+7.<六.17",
    descriptions: "十;$三;七五^ 1.9ı=十ğı 6@73%2:\\2 (二八]ı一.ığı )二 >* #八 <\\#/0, %\"( 四%七七< 十6 <#",
  },
  {
    codes: "          0$$九.#二.):.$%.:+",
    descriptions: "四$^ }三;,2<:78 (五.=*四) 二七二=9 }$\"4七](",
  },
  {
    codes: "          >;>4._四.十8.}三.'+",
    descriptions: "六三七 六四三\"+.);1/# 一\\四八/ı #6二十*^三",
  },
  {
    codes: "  9;5@.,>",
    descriptions: "1>八)?]./_/#&四五一 @ü<5+7 $\\5/9?十(5:. 2/'五一9八7, 一}\\十@ü@3 五二/3四?ş 1:>0八 $0(9<ı|",
  },
  {
    codes: "    0>,八.//.7五.7#.9十",
    descriptions: "64 >九=九_)3二三 @.ç:+:}6{四/",
  },
  {
    codes: "      9\\九).6四.6/.^2.5^",
    descriptions: "9十85@6|1 69八ı\\)ğı 1\\._.+\"/7 (四\"|ı八&ığı ?| 三: 八> ]2@[9, \\}# :33>@ {% (,",
  },
  {
    codes: " ",
    descriptions: " - - - ?>ğ'十|([= ",
  },
  {
    codes: "        二);7.{=._5.*,.三{",
    descriptions: "96$ /八9@\"^五:= (>&五0[) ,)8}< 58+4}](",
  },
  {
    codes: "        2=?@.>七.8=.#@.二:",
    descriptions: "&6> #九\"十/:6#_}七 十{七#|ı 十一一(*\\\\",
  },
  {
    codes: "    #\\二3._'",
    descriptions: "=/{1*+ 5二七% #三ğ5> 二%\\.{:0(九;\"9二+ ($4@ *:七ı{ı{ :3八 #?={ 十] $\\七,&",
  },
  {
    codes: "      四\"|五.60.[十.;三.1六",
    descriptions: ":,= 二一+#7 十\\ç74\")>/,^",
  },
  {
    codes: "      &0八2.二5.2五.$\".^=",
    descriptions: "三,? #一]1% 九,ç?[.=.",
  },
  {
    codes: "    %\\3?.,4./|..;.'+",
    descriptions: "$|4>7]9%* 七3]六 4<ğ1二 1五(6)>62<7(14} (\"3* 九|*ı[ı1 四九% <七?2 十3",
  },
  {
    codes: "    \"]九}.;\\",
    descriptions: "十六?/@89+_八一一{\\<",
  },
  {
    codes: "      {六%*.|&.6].%%.?1",
    descriptions: "六,$ ?五,)7 /0ç一_')4&九2",
  },
  {
    codes: "      一]_六.五1.三9.|2.}]",
    descriptions: "%,# *83}十 1}ç{二八[+",
  },
  {
    codes: "    *#六五.59",
    descriptions: "8&5;:五|^\\/,一{2,",
  },
  {
    codes: "      };9三..;.@5.{/.71",
    descriptions: "九,七 八16#0 _|ç}4[八99,#",
  },
  {
    codes: "      .七七五.\\^.${.二4.五}",
    descriptions: "<,. 八]五(% =&ç=.8/0",
  },
  {
    codes: "    5'4*.3一",
    descriptions: "?4ğ\\五?$$@",
  },
  {
    codes: " ",
    descriptions: " - - - 4,= =九$)> 6>ç\\_{三\":?.",
  },
  {
    codes: "        \\\":五.<^.9/.\\2.()",
    descriptions: "四'+?+二] '&六三1ı 七八四\"七^+} +;*6$三8,四[>2',",
  },
  {
    codes: "        &五9十.6..\"0..#./%",
    descriptions: "\\??@7 &%:\\}ı 9五[]'7^, :>*&6418二七六%六七",
  },
  {
    codes: "        二)2五.六/.=十.*).=五",
    descriptions: "\\3ğ八$%,四|",
  },
  {
    codes: " ",
    descriptions: " - - - 十,9 918.; *6ç)五63五",
  },
  {
    codes: "        九93_.2(.5}.?;..:",
    descriptions: "一5三+';九 1七九/六ı 一,七[%4*? *二_{/&\\]]%八|.5",
  },
  {
    codes: "        5二^].八五.7$.98.+%",
    descriptions: "=6[_} 五_9.八ı 一']+七|{0 |四,?\"'三八9\"$}@]",
  },
  {
    codes: "        '七\"|.)八.7*.=#.6二",
    descriptions: "@十ğ\\六<$)?",
  },
  {
    codes: "    <]*二.4?",
    descriptions: "八'&}.八:6^&0308<",
  },
  {
    codes: "      }%0'.七八.二<.二/.四^",
    descriptions: "^,& ,@0$^ :&ç:;@;3九\\/",
  },
  {
    codes: "      {(|;.$四.]三.四八.&%",
    descriptions: ">,十 &八19: {5ç{{*一八",
  },
  {
    codes: "    一5{三./}",
    descriptions: "一|ğ^',一12, 九2>9><2一:%&} 一:$7 /四ğ'2 $]*/九1十\"%#二十%,",
  },
  {
    codes: "      <353.&4.36.6?.{9",
    descriptions: "四,8 一}<&0 &2ç四一1十=/七4",
  },
  {
    codes: "      _]?_.:\".*{.五(.2|",
    descriptions: "九,? +)<=@ |%ç1>'2|",
  },
  {
    codes: "    &19(.$?",
    descriptions: "'五ğ*75:%{, }2.[%'&(九;一3九8? (十ı{(3二 ç|三,九@.)",
  },
  {
    codes: "      ,六:<.八].十%.9,.^{",
    descriptions: ",,2 八*\\,九 六=ç*33七4五#$",
  },
  {
    codes: "      =*=/.@8.^1.二五.三0",
    descriptions: "|,3 六?59) 6;ç三}?;*",
  },
  {
    codes: "    |\\|十.4>",
    descriptions: "?^ğ[/$2%1, )016:六五/四87一五0\"",
  },
  {
    codes: "      ,+}4.三&.一4.\\7.三\\",
    descriptions: "一,} #8;\"[ 9>ç&1/\\八五8=",
  },
  {
    codes: "      _一4}.三四.&7.+6.%1",
    descriptions: "/,@ 52{4\" @[ç九<.:+",
  },
  {
    codes: "    =_1[.,|",
    descriptions: "*;ğ<十764,, [0*八九{03|5=>&[4",
  },
  {
    codes: "      %三]<.4+.4#.三,.#6",
    descriptions: "2,' 4}?[六 .五ç&^^二*+_$",
  },
  {
    codes: "      }九0&.6(.*9.0:.二四",
    descriptions: "},) =?</, #五ç_9{+[",
  },
  {
    codes: "    5*#4.五*",
    descriptions: "5&ğ.十四@\"一",
  },
  {
    codes: "        \\+<^.<#.:四.78.^&",
    descriptions: "*9@9\\\\3五十?<9} 9>\\八五;三五二 二@\"&0六9",
  },
  {
    codes: "          /=五三.+*.0#.{$.#十",
    descriptions: "2:5<(&9 七%|六?\"3=五< *四?60;四 &]{7一9 五,一+[% #&)[ 五[ '(2七[\" 一六四?二3 /5二1&;.]四]|",
  },
  {
    codes: "        9=|).6@.|;.'五.=三",
    descriptions: ":]+7ı 一;*九}$6&:{九 ;&五.:* 一,三{三二 }二.6(/ı\\ı0 ,]一1{*8\" *七37.五ı八二. +三1];[,@9",
  },
  {
    codes: " ",
    descriptions: "         <+>(9ü二 :/$*=ü5; 9一,\"$0&二ş ']六六=八}一 :七三%3[?83",
  },
  {
    codes: "        [<@<.1九.^1.2/.1\\",
    descriptions: "%.:?.;7 /\\+42;^:0八三= ;[]4五\\二 _四;九&五%",
  },
  {
    codes: "        )6:_.;6.6@.九,.三'",
    descriptions: "=$?%&_ 八,;9七 ?一9+_${6[5 一'}0九;4 0四九3,,, 九1四?+%ğ: 70] 8. 二四,五 二@五* .;'_;,",
  },
  {
    codes: "        (四四七.九(.<四.9五.9/",
    descriptions: "6$.二二 %>+}一(一;>2>九 @[% 二&)三 \\ş三&.三三ı/ _$$|二七3 四九}4)7 /), 5^3, >%[",
  },
  {
    codes: "            一874.((.:}.<三.[)",
    descriptions: "9|=+]一[ .+[; (八八7五六,0_[%十六])) 四一七 六ü7ü_>|++ş 四[} 四.\" 69={;;八$",
  },
  {
    codes: "        九三'].32.,<.一\\.$十",
    descriptions: "&=ğ(\\一九&八",
  },
  {
    codes: "        九\"?,.>八..;._8.^\"",
    descriptions: "$\"_.0/;6%八;\\{ >$十&9?7@6 }4十四^五3",
  },
  {
    codes: "          7(]8.+十.]/.六一.2&",
    descriptions: ",_3_|\\六 六+[%53{_.) 四>一(#&' >%?五;8 2五十七{6 .@>\\ <| (7#]五二 .@二\\4^ 5四.二2'73+一八",
  },
  {
    codes: " ",
    descriptions: "         \\%=7八4 $3六七六4\\",
  },
  {
    codes: "        ?七0四.>2.6<.六$.$九",
    descriptions: "+?/:ı .6{?92,@}_6 72334= 3@三八5^ 二;七六六4ı5ı四 2}九七(&{1 三+九);}ı|二+ *\"&+五]@,3",
  },
  {
    codes: " ",
    descriptions: "         9*??四ü2 二6+3二ü九2 ;9|9四5\\^ş 七|23|_,1 +0,1八(<{:",
  },
  {
    codes: "        )%+@.:].>|.*七.>=",
    descriptions: "3}.十98] 九1},7八(^%@9* @@3}一四2 53三<{78",
  },
  {
    codes: "        %,一十.六^.'*.@,.十7",
    descriptions: "\"20,1< ^6<:& /?_}+3+(十) +'#:24( \\六六28[, $&\\一{十ğ七 >0一 '. 28.@ _}%+ 六2<|&,",
  },
  {
    codes: "        /]&九.,\\.2..#5.七{",
    descriptions: "6;.[, 2/%一)0九++(\\[ 3<_ 九283 ^ş1;>5[ı= 九六5一$>8 &)99五九 =\\, ',3, 5七$",
  },
  {
    codes: "            2}(}.)三.八).<+.^)",
    descriptions: ",{(六+1| \"*2< (_7%五5,9),四,(&[) 十*_ 六ü6ü+六^八#ş =%0 __[ 四七?31^八'",
  },
  {
    codes: "        0<一7.&[.$#.>0.%四",
    descriptions: "十2ğ49_\"7十",
  },
  {
    codes: "    #.5}.{一",
    descriptions: "'*\\4[#4七二 二一[[ 二八ğ9十 三|:二八三0九<0九>{;",
  },
  {
    codes: "      十二十五.{二.9@.7/..(",
    descriptions: ",,三 十\"@28 ^三ç(@;})九>一",
  },
  {
    codes: "      <:@$./{.&9._|.0%",
    descriptions: "{,> 一一_/? 79ç3十/4^",
  },
  {
    codes: "    0\\\\%.5,",
    descriptions: "0九|@_六{[.*%22{十",
  },
  {
    codes: "      ,\\>?.二{.;十.33.*四",
    descriptions: "+,二 0九0*; +[ç一#6\\7_3\\",
  },
  {
    codes: "      @,#{.#*.{+.%>.)5",
    descriptions: ">,> =二^.* 九]ç{+一'}",
  },
  {
    codes: "    3四\"?.=0",
    descriptions: "8二):9.一_3七;九#二(",
  },
  {
    codes: "      }_;8.6[.(一.$<.(?",
    descriptions: "%,二 #\\*'四 >=ç五\\53%&18",
  },
  {
    codes: "      _@\\/.+{.&5.十;.6=",
    descriptions: "九,3 4;%$) 2六ç.1=|四",
  },
  {
    codes: "    {(}0.{;",
    descriptions: "十8ğ八^;))五",
  },
  {
    codes: " ",
    descriptions: " - - - 九,9 <1二3< 五;ç八;八#:5^二",
  },
  {
    codes: "        [+三).%[.'#.47.5]",
    descriptions: "8::$=_& |\\/{{ı .六五]<;]1 二^/#93<}6)八.十;",
  },
  {
    codes: "        六十#1.}四.>\\.=&.一十",
    descriptions: "五九\\四9 }4@;@ı 5一十六十=二) (四二1{%/(*[{5九+",
  },
  {
    codes: "        _:十三.39.六|..$.{9",
    descriptions: "六%ğ七+\"/;&",
  },
  {
    codes: " ",
    descriptions: " - - - #,一 /三.@_ _4ç#$九;5",
  },
  {
    codes: "        .'.3.0/.=;.)6.%?",
    descriptions: "'*八&四九四 \"#:;$ı 0@%二五}'二 '$^7==>四^一(_2;",
  },
  {
    codes: "        #六3{.4;.二_.}九.#{",
    descriptions: "#五%二* 92(九:ı 六4:*二七.. 94$=[九[93&6)>\"",
  },
  {
    codes: "        七六二:.)八.5六.六>.[四",
    descriptions: "/;ğ七=一#\\:",
  },
  {
    codes: "    六九\\二.'4",
    descriptions: "<(>/?0\"*6 6八\\) 50ğ6七 ^]+三2?_7^68%.3",
  },
  {
    codes: "      8,$5.,#.{\\.2九.;@",
    descriptions: "',/ 3[\"]] {&ç}二_1四0#十",
  },
  {
    codes: "      _*二三.]].@*..7.@0",
    descriptions: "二,# 6@7@7 @1ç\"//:^",
  },
  {
    codes: "    =三%|.<3",
    descriptions: "'@6?>3[_6[7$>:8",
  },
  {
    codes: "      =@十二.=1.@:.+三.>五",
    descriptions: "),* +一六86 ]?ç86六_:9\\:",
  },
  {
    codes: "      |067.?{.)%.>七.[5",
    descriptions: "0,* 一8十|9 %七ç)'^8}",
  },
  {
    codes: "    :/96.七8",
    descriptions: "#|\"2#5*七4/四二[\\七",
  },
  {
    codes: "      ,@七,.$5.'五.十五.(八",
    descriptions: "0,' |5336 ,7ç三_|++((3",
  },
  {
    codes: "      ;^七七.一].$0.)=.七*",
    descriptions: "4,/ %%;/1 |^ç5二<%九",
  },
  {
    codes: "    %七八^.$,",
    descriptions: "三?ğ[{(八七7 ",
  },
  {
    codes: " ",
    descriptions: " - - - 一,六 /三._1 \";ç[{6三9{&.",
  },
  {
    codes: "        5四};.\"@.5六.5十.}九",
    descriptions: "52三{.'@ 7(%5>ı 七/={4*[\" @/1)>四}#0;%%{三",
  },
  {
    codes: "        ,六二八.\\?.=,.[[.^|",
    descriptions: "104#4 五\\75+ı &九$[_}九= :五38一%$\\6|/_=}",
  },
  {
    codes: "        @]\"..6<.\"=.'三.八%",
    descriptions: "=4ğ五6@=$(",
  },
  {
    codes: " ",
    descriptions: " - - - 五,7 3';.九 /6ç六?}}1",
  },
  {
    codes: "        |六[七..*.?(.#7.1'",
    descriptions: "5(=<(二十 {0*%|ı @九六:1七'? [$>8.'_+*=[八^&",
  },
  {
    codes: "        <4一3.=一.{#.|8.62",
    descriptions: ">三四)2 '八2&)ı /)=三八[九2 :28一($二\";+,.{{",
  },
  {
    codes: "        5#八五.@=.4四.#十.\\#",
    descriptions: ",>ğ8四1}\\=",
  },
  {
    codes: "5+.:;",
    descriptions: "+\"++ ?<六^#八01 0|六七#,&:一 (|58|ş >{88ğ[ +$<{ç) (<^五.\\8\\四{ 八{77^+ 十=|ı6|五}十",
  },
  {
    codes: "  =}六/.$]",
    descriptions: "}1?'|$ 三\"\\ğ三?[十3 {ü1@;) 669五八五\\*{九; 1七五5% ",
  },
  {
    codes: "    &+0$.;=.@].0三.0(",
    descriptions: "}5 \\=|七76八?7 9二ç\\^&+?{#/",
  },
  {
    codes: " ",
    descriptions: " - - 17 #{7八6五^76 &五ç/6;六| ",
  },
  {
    codes: "      五6}:.%9.|&.二\".1三",
    descriptions: "'三> 7<.九7二=六八 (..0?^) 0七{)* =95[五8四",
  },
  {
    codes: "      4>五=.七}.().{(.1,",
    descriptions: "8@( 2}52{71#6.3 ,=六@/ı \\/84@二八",
  },
  {
    codes: "    /%$5.6>",
    descriptions: "}={[六三 3=[ğ,31[) (3ü五ü%^七|+ş =>,2 一107=;\\ ;_ 三一2[( 三|二 '2[",
  },
  {
    codes: "          ;(|}.九7.)\".;0.+七",
    descriptions: "}\\>\"8+@ \"{八73ı 十'$% 2四'7.$&'=9[四[4",
  },
  {
    codes: "          8?'\\.二9.7七.7].52",
    descriptions: "@/ğ2^?三7(",
  },
  {
    codes: "        {七八8.3[.0|.'>._\\",
    descriptions: "0九ğ8+*+_八",
  },
  {
    codes: "          \"$十$.9三.;<.二).[六",
    descriptions: ":>}六;&& 7六4&四ı 7_]$ 46/+五八&&/|)4'$",
  },
  {
    codes: "          ;.>/..(.九3.*3..二",
    descriptions: "1%ğ>348&>",
  },
  {
    codes: "        (七四^.,十.93.38.'}",
    descriptions: "7,ğ9:&4<)",
  },
  {
    codes: "    4四35.@;",
    descriptions: "六\"6@五) %0(ğ九九}%; (8^5四%)# :十; :'八三]: 1'=五一 二ü|ü&二üş)",
  },
  {
    codes: "          $九**.七<.{(.28.\\%",
    descriptions: "9#五\\/一0 一;3@<ı 8350 ]_])*1197|八八<+",
  },
  {
    codes: "          \"^#=.+>.4$.四三.0:",
    descriptions: "?>ğ_32);:",
  },
  {
    codes: "        '五?1.三五.%*.'七.[6",
    descriptions: "##ğ#.1.一^",
  },
  {
    codes: "          ?01*.#>.;二.:2.:%",
    descriptions: "(<654四七 <={**ı )[(' ^$?26八$六:*{<=;",
  },
  {
    codes: "          四1&^.}九.1五.3八.#2",
    descriptions: "?9ğ八*}*九+",
  },
  {
    codes: "        <一]5.}(.\\$.<2.$$",
    descriptions: "1^ğ#5,八:1",
  },
  {
    codes: "    (1二+.^;",
    descriptions: ")40ü,四0 九三,七4'.8十",
  },
  {
    codes: "          (%\\*.2>.[9.8..69",
    descriptions: "),[1)=十 二'?七0ı /*\"七 7[?/&\\=六31六%*二",
  },
  {
    codes: "          >九76.^八.;^.)^.'七",
    descriptions: "@3ğ2;^{@_",
  },
  {
    codes: "        \"<=\\.^+.]十..4.九五",
    descriptions: ">\"ğ2)4(.;",
  },
  {
    codes: "          |19{.二三.;四.54.%*",
    descriptions: "三2/七&四> =二>八2ı ,6二2 五]>/(>二*^5}五\"*",
  },
  {
    codes: "          )8]六.}=.<+.:\".}(",
    descriptions: "(;ğ二5三6三}",
  },
  {
    codes: "        .9一?.[0.19.*#.九三",
    descriptions: "7|ğ三<>46.",
  },
  {
    codes: "    @一\\=.>0",
    descriptions: "?+ğ,四'二七^ ",
  },
  {
    codes: "          $/\\9.0三.]?.:#.^{",
    descriptions: "#$.二)+4 六[+八三ı 9九|. 5九5:十6'1_&)四二^",
  },
  {
    codes: "          %@*^.'].27.+四.二三",
    descriptions: "$.ğ45\\%=九",
  },
  {
    codes: "        /#^*.47./5.\"2.\\;",
    descriptions: "23ğ*,<四\"9",
  },
  {
    codes: "          ^5六].&\\.@'.0四.(?",
    descriptions: "78七@>|4 (<}@]ı %:三{ @{@&_九,,7二^\\一三",
  },
  {
    codes: "          ;1\\九.=*.[六.[/.}|",
    descriptions: "5;ğ\"十5|四十",
  },
  {
    codes: "        +0:[.四*.一9.>@._六",
    descriptions: "?6ğ<70^$|",
  },
  {
    codes: "    [@|#.,;",
    descriptions: "#7+\\&, 三+$ğ7}@八| ",
  },
  {
    codes: "          \"九90.2..三,.)5.五'",
    descriptions: "1七5_.|* *八八五=ı 八#.@ $>82&/[&=?23/九",
  },
  {
    codes: "          六:25._一.三}.三6.^/",
    descriptions: "三\"ğ@#+八3>",
  },
  {
    codes: "        }二(六.(1._6.四三.[五",
    descriptions: "5'ğ^$?<(四",
  },
  {
    codes: "          十,>一.{*.&;.(1.#%",
    descriptions: "/;58;3[ ]6$@一ı #=0. 0@@^039二|5*0)4",
  },
  {
    codes: "          :00十.0,.#+.1七.+{",
    descriptions: "九七ğ$%九2?&",
  },
  {
    codes: "        %十@}.<;.八>.\\<.八9",
    descriptions: "0二ğ四七九[6|",
  },
  {
    codes: "    2/\\..一;",
    descriptions: "八0,ü9十\" }'七三_九,五@ ",
  },
  {
    codes: "          =6<7.6九.51.8:.%6",
    descriptions: ";7,0/$+ >?2/'ı +四1/ #'\"#'[[0三+]二>4",
  },
  {
    codes: "          .[=1.@'.|>.6+.8(",
    descriptions: "),ğ\\$5#三一",
  },
  {
    codes: "        九=\\6.四九.八|.四:._六",
    descriptions: "<八ğ=*'\\9一",
  },
  {
    codes: "          ]三{六.($.$\".九\\.]3",
    descriptions: "1(二)七:. 六|5\\4ı 六五&) 二18_^3四.>,8*70",
  },
  {
    codes: "          三)]四._+.\\}.&^.>;",
    descriptions: "九1ğ6/七:]8",
  },
  {
    codes: "        {九_5.:3.(?._2.)3",
    descriptions: "27ğ|十\";4^",
  },
  {
    codes: "    $\"[#.3=",
    descriptions: "\\.ğ9四4八五) ",
  },
  {
    codes: "          %9?*.;[.七一.$十.68",
    descriptions: "4|)\"/,8 {$&}<ı 一五九[ 6六<'六)*.四,九五1五",
  },
  {
    codes: "          一四|七.[\".十>.@#.七*",
    descriptions: "5(ğ(2=十十=",
  },
  {
    codes: "          七=+1.#十.,$._'.:_",
    descriptions: "/五_七@*一 $:十九四ı 六八@四 {三7>^_二}:1*/(%",
  },
  {
    codes: "          |4:_.五).>四.三$.十#",
    descriptions: "16ğ\\3:九|*",
  },
  {
    codes: "          5|)4.(,.五九.|;.#)",
    descriptions: "$7?十+,< :)8/2ı %]|7 %二四}&8,^^|_&[;",
  },
  {
    codes: "          4)86.9_.|'.&=.\\+",
    descriptions: "四1ğ[一?|五8",
  },
  {
    codes: "          #1五1.84.七八../..%",
    descriptions: "4七7\"9一四 [一$,九ı 3|,二 %4八|五五8^[\\0,::",
  },
  {
    codes: "          六/^9.(1.[:.^\".}@",
    descriptions: "*4ğ一/'|;.",
  },
  {
    codes: "六]./1",
    descriptions: "6&|<.?_2 3=$>:#.\\67#[八*九 (20 }八十),.1十 26^_ '(00 三1&十一 52.,,1,4三+",
  },
  {
    codes: "    八/四;.)2.(<.)7.+'",
    descriptions: "];十\\8十/五35七>二@8",
  },
  {
    codes: "    }七=>.八1",
    descriptions: "|'ğ/十^|?1, 8|'一二*';+\\\\.6八<",
  },
  {
    codes: "      三6^@.三七.二$.+].\\.",
    descriptions: "İ7ş,.43} |2&*二$ı%0(二^/",
  },
  {
    codes: "      (二|^.@七.五七.\"0./[",
    descriptions: ":9ğ{7#一<九",
  },
  {
    codes: "    3三{*.二^.<二.(4.五[",
    descriptions: "1\\ğ+五)六(2",
  },
  {
    codes: "  +3.九.%_",
    descriptions: "九\"ğ9*四800",
  },
  {
    codes: "    @)(_.|;.四>.\\6.三]",
    descriptions: "四0^(($4九?9{:\"%三",
  },
  {
    codes: "    .*$}.+..?三.@%.\\\\",
    descriptions: "+一ğ%=十七%+",
  },
  {
    codes: "{四四0.&7",
    descriptions: "')8$ _二[#%\"219,:<7三五 ( 七九 三26二五'$( _二五& ''5] $三}7\\ )0<?, '$3$四 7七*3+;",
  },
  {
    codes: "  ;@8/.二$.三9.七}.]>",
    descriptions: "_&>.'|9)4(\"5六^;",
  },
  {
    codes: "  98#\\.\"三.五5.'=.:*",
    descriptions: "五_ğ三[三:8|",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "$七.?{",
    descriptions: ";^九三9<7, 9. *},? 5|#,/二^3 7$*,}七八39 (6七一<ş \\1\\4ğ= _$:二ç) ((9@1.05/$",
  },
  {
    codes: "  #六=3.三十.7=.3^.+3",
    descriptions: "七七七0六?>9 三':ı5@ğı 二四;'9+三9? (五>:ı6九ığı %6 十' =\\ =@\\|{, >$7 ^三七四九 &> {& 六;,,",
  },
  {
    codes: "  ||十7.)*.2}.\"\".32",
    descriptions: ";>ğ#{|一94",
  },
  {
    codes: "  2五.\\2",
    descriptions: "\\?4[@$@四 );$|&@{^ +2=\\&五七)一二_七^ 7$?一:(=ş &*\"^:?8六 (%0. 84 .:|七八/[",
  },
  {
    codes: "  .0^>./三",
    descriptions: "3.>]%九 6二四( 八二ğ2六 6六四=\\$?2三十;289 35^$ '73(5九/4四一<(\\?' 9一九一 &>\"{七#",
  },
  {
    codes: "    三;4@.=二.%七.<*.]+",
    descriptions: "@/\"六6二(4 十)七十3三:6 ( &&二ç), 1//90|十二四[ 3>5=十ş4|七九六九6ş <:@九%']8 )>?五+&ş]4",
  },
  {
    codes: " ",
    descriptions: " - - 六=ğ[11十,@",
  },
  {
    codes: "      ](29.}..,|.+4.8>",
    descriptions: "+&四8şü4|ü一 1\"9九/_$'",
  },
  {
    codes: "      \"4$:.]].#|.九四.9二",
    descriptions: "Ç2八ı=4ı= 七九&3&;12",
  },
  {
    codes: "      \\2十八.@{.@9.五}../",
    descriptions: "_:ğ(二三'&1",
  },
  {
    codes: "  ]三一1.七%",
    descriptions: "Ş八九<^ _= }<:?.八7%6*%/3< '+'92,.ş 58+2|@?'",
  },
  {
    codes: "        ?六%二.\"..=3.七{.7(",
    descriptions: "20?.^]^, ;@十76<七? ( 4?$ç三十 七;$九6?+,<8 *:]*9ş}\\]^;0|ş _$57七];} ^_五五*6ş八>",
  },
  {
    codes: "        6'8十..@.69..2.六,",
    descriptions: "五/ğ_#:=9四",
  },
  {
    codes: "        +,?6.[/.5<.}5.)|",
    descriptions: "\"%?(四}9% '一2}+'?0 ( ]{/ç// 9]六十\":五七+十 八)十{<ş]十(150%ş ]六十{三{[1 5@^58:ş>五",
  },
  {
    codes: "        &八三8.8&.<0.+一.十>",
    descriptions: ";)ğ(\\/>&|",
  },
  {
    codes: "      *:{,.)1.四).=\\.];",
    descriptions: "4#]二二\"一\\ 七<<$3,51 ( 7七|ç'? 一四=$八36:++ 35\\0/ş5@8]=#6ş (2二十$五/三 &$@#($ş.)",
  },
  {
    codes: "      ,三/;.八..5/.}<..7",
    descriptions: "0.ğ^,7%四2",
  },
  {
    codes: "  &五{'.{六",
    descriptions: "%6. 六ö/ü}ü< , 一&1;@@/ı 二$,五\"八: $1四%|.0二/五 ,(_<1三9,",
  },
  {
    codes: "    .\\七'.十?.>1.:2._;",
    descriptions: "[五+%,]九& =八七[六四,* ( \"7四ç95 )0,=%{,七八7 2{+\".ş[5\"|%六*ş 五六(#*60_ 1{&十44ş$]",
  },
  {
    codes: "    :>7&.;^.一5.2?.})",
    descriptions: "{,ğ*&;<九三",
  },
  {
    codes: "    >\\一{.五@",
    descriptions: "$ğ?5三ı八3>;ış )\\^一 }ğ<38ı四=ış ",
  },
  {
    codes: "      19_/.{三.1(.0二._九",
    descriptions: "1八十99<{' 7(1[:四9八 ( ).十ç62 ;\"四1八三,6;3 92一.5ş},三一&[_ş $二>八>,>' **{;五:ş'五",
  },
  {
    codes: " ",
    descriptions: " - - - 十3ğ$)5%/七",
  },
  {
    codes: "        1|%].十二.七六.#@./九",
    descriptions: ">öş+4$87{ |4)五.二^3",
  },
  {
    codes: "        ]8<>.5二.'].三/.+<",
    descriptions: "+0]5/十}. }16\\四?一@",
  },
  {
    codes: "        &:二二.{*.四&.七'.=三",
    descriptions: "}76+六五ı, 四_>_+0\"?",
  },
  {
    codes: "        4/+?.:'.*(.{+.)9",
    descriptions: "9?ğ<5:三一二",
  },
  {
    codes: "    八九+6.)/",
    descriptions: "一052/四ış",
  },
  {
    codes: "      3'十#.七%./七.12.三)",
    descriptions: "}七\"4@9#+ |4{七:一九+ ( 十]5ç]\\ 九(*'六2一/@_ #&1*三ş>}?七五八7ş %(三_+4]< 9八*\"=*ş8#",
  },
  {
    codes: " ",
    descriptions: " - - - [_ğ8?七六九9",
  },
  {
    codes: "        4;八}.>..'一._&.六{",
    descriptions: "5öş十一8\"49 >八十$%26]",
  },
  {
    codes: "        @+五九.;5.}$.五{.5'",
    descriptions: "三62.二七6. 5\"#1%/[^",
  },
  {
    codes: "        ^十1六.&一.'{.?四.12",
    descriptions: "=}]/{+ı. +___}]^6",
  },
  {
    codes: "        @\"六].二7._'.^六.7\"",
    descriptions: "@$ğ:}@5.5",
  },
  {
    codes: "    \\7七=.&)",
    descriptions: "七,01,ı ,'7}七三5# 9#%|55&八^7六",
  },
  {
    codes: "      ,(&1.二$.:,.+$.8?",
    descriptions: "九[一0=1+\\ 三@;2)$__ ( $>;ç七+ 三6^[87{++/ /+=%九ş';1?5七八ş 九\"{*^三7] $1]{|}ş}3",
  },
  {
    codes: " ",
    descriptions: "        3.,三)+<四八8) [63五%1() ",
  },
  {
    codes: " ",
    descriptions: " - - - 九2ğ')%<{4",
  },
  {
    codes: "        2八/^.}>.[七.&[.3三",
    descriptions: "<öş..\\&#} 4&.\"?十1=",
  },
  {
    codes: "        $6;_._4.2^.|$.2#",
    descriptions: "四\"$五六0\"1 {'$七$91*",
  },
  {
    codes: "        ?二\"/.1二.9二.七=.四%",
    descriptions: "2^)+>?ı六 3#2/九^_?",
  },
  {
    codes: "        6*四一.|(.8\\.@/.;$",
    descriptions: "(@ğ\\5.&十,",
  },
  {
    codes: "    三{]一.<5",
    descriptions: "0<#\"ı@ı",
  },
  {
    codes: "      #{$,.9:.\\6.一六.6*",
    descriptions: "?+[_]%\\9 六;0{8二3/ ( ^75ç8+ 3五(\"九6]@'% 6\\]{十ş|1+4>:8ş +十*#}&[3 .&+|<%ş八8",
  },
  {
    codes: " ",
    descriptions: "        6三,十3}7<&9七 )9#0[99) ",
  },
  {
    codes: " ",
    descriptions: " - - - 九三ğ8四<一.七",
  },
  {
    codes: "        \\:二7.;4._8.$).>(",
    descriptions: "$öş7?08*5 一|\",$|,:",
  },
  {
    codes: "        (2九\".十二.5一.十[.:+",
    descriptions: "六;^521四| 8|&五七[16",
  },
  {
    codes: "        &$<<.56.二六.@%.@0",
    descriptions: "2二ğ53一八|?",
  },
  {
    codes: "    }+三:.@?",
    descriptions: "\\ğ$7^ı七一(3ış \"\"+, |ğ#0六ı@9ış",
  },
  {
    codes: "      '一7[.9'.]九.]|.\\?",
    descriptions: ",四三/56=七 |一84_九二: ( 四八<ç04 六+=8](二6六1 (#3{[ş.'+6<3四ş >8三\"$九三& 2>3.3@ş7十",
  },
  {
    codes: " ",
    descriptions: " - - - 1@ğ}九#,#_",
  },
  {
    codes: "        5$6十.>\".5^.35.七{",
    descriptions: "8öş,<三^14 4>#*]三#4",
  },
  {
    codes: "        '19*.六*.<七.八\\.\\十",
    descriptions: "一七&=四3[, _:7|:>#|",
  },
  {
    codes: "        1]/].+九.六七.%$.\"\\",
    descriptions: "<*_=]*ı6 (<?7.#.四",
  },
  {
    codes: "        七}[/.\"3./:.]|.2五",
    descriptions: "4二ğ#/0七]七",
  },
  {
    codes: "    八四}3.,]",
    descriptions: "6四六4<=ış",
  },
  {
    codes: "      :#十九.29.七3.7|.^9",
    descriptions: ">(/]:33; >67,*$=十 ( 十二^ç三七 =?2@]](@#$ 8'七\"#ş>.:五>7五ş @0@九一[+{ @2^\\*六ş{'",
  },
  {
    codes: " ",
    descriptions: " - - - 1.ğ;&四二10",
  },
  {
    codes: "        二五42.<:.]<.|<.^1",
    descriptions: "十öş六九,五#三 十%.#%+,一",
  },
  {
    codes: "        6一2十.二五.\\'./&.\"[",
    descriptions: "七)9:三三|< }1五一_四&九",
  },
  {
    codes: "        6=*?.#=..八..八.9\"",
    descriptions: ">)3二^6ı] +?八\"4[@|",
  },
  {
    codes: "        {3]1.6五.84.=;.,2",
    descriptions: ")@ğ=}|八#^",
  },
  {
    codes: "    )(8?.%:",
    descriptions: "四<2二.ı .九?{|55: .二$/9六??'|'",
  },
  {
    codes: "      十四*?.8..)<.67.四@",
    descriptions: "):九{0'(: .六0八\\}十^ (十9:ç二2 39八%8$?51+ ,九@九4ş584一@7@ş 5''<)3六十 七九*/39ş#一",
  },
  {
    codes: " ",
    descriptions: " - - - '6ğ@八}8<>",
  },
  {
    codes: "        八'$&.3%.6/./2.1>",
    descriptions: "三öş<|,|{_ 一2?二%>?&",
  },
  {
    codes: "        四一2$.<0.五五.9).8_",
    descriptions: "九5{&八%;* [一八#|_一.",
  },
  {
    codes: "        ^;七7.%&.\\九.二六.?$",
    descriptions: "/2六'84ı$ ]0|)826四",
  },
  {
    codes: "        42?\".八@.{4.5/.(七",
    descriptions: "*@ğ=%0五1二",
  },
  {
    codes: "    {#五*.四?",
    descriptions: "<6_3ı7ı",
  },
  {
    codes: "      五\"4二.\\{.($.>6.十十",
    descriptions: "&4三]\"七.3 951;\"9)六 ( +*0ç{' 10二4;(|\"五: 六六?26ş.006九2^ş ^0二{)\\5= 一.8]9,ş?;",
  },
  {
    codes: " ",
    descriptions: " - - - ]4ğ7/>&;{",
  },
  {
    codes: "        七=}一.八].1$.(0.8}",
    descriptions: "?öş1七{一_: %九+616二<",
  },
  {
    codes: "        \\4四=.二_.^7.+七.1(",
    descriptions: "十5<*%&9' *二][@[/*",
  },
  {
    codes: "        40:[.:/.十?.|(.']",
    descriptions: ";?ğ/*)六八六",
  },
  {
    codes: "    ]9,|.一一",
    descriptions: "二ğı十十ı% *十35%四4.五% % +6 }5五0 八0九8 $]六'' 三(2(6ü^< ,8五2$&九_ş",
  },
  {
    codes: "        06九;.((.\"1.七4.1/",
    descriptions: "二+1+52八$ 三九]5;_\"= ( 8&^ç9八 8二>]8\"\\4四四 (,九4=ş/',@5[*ş ==五九_3#1 $/#=一<ş=)",
  },
  {
    codes: " ",
    descriptions: " - - - - %3ğ/8{8_(",
  },
  {
    codes: "          :%(&.\">.>8.'^.5}",
    descriptions: "%öş._((/* ;<七九8&/一",
  },
  {
    codes: "          :二'].<4.8五.四%.)八",
    descriptions: ".^'5^,九9 十6}']九3#",
  },
  {
    codes: "          ,二_<.;'.{八./#.(=",
    descriptions: "&%;五03ı2 [0十*9_四.",
  },
  {
    codes: "          ^*_九.:9.\\8.9[.,[",
    descriptions: "九@^*二|二}+ 六&9一_(九>",
  },
  {
    codes: "          2[4&.+七.@#.)<.2;",
    descriptions: "三)ğ'2七6?2",
  },
  {
    codes: "        三5七2.<+.十三.2..%二",
    descriptions: "^@5^八?,6 \\'*三|#.& ( 61一ç#\" 8五四七@三\\[#四 七03.7ş?\\:/]}八ş 6$0>/\\一> \"*三&十+ş*1",
  },
  {
    codes: "          二;+\\.三3.]:._=.<三",
    descriptions: "&öş'二*.+[ +_*?二'?$",
  },
  {
    codes: "          ]89,.*{.&{.四5.&0",
    descriptions: "0{\\5十[一0 %=5[|8#;",
  },
  {
    codes: "          九五3{.03.九{.\\9.九4",
    descriptions: "[.七=]}ı? )七3+=&4:",
  },
  {
    codes: "          五六'\".|2.$4.3*.?}",
    descriptions: "[_ğ二8八七|+",
  },
  {
    codes: " ",
    descriptions: " - - -  &9四.五ı \\6一+三3二_ ;_八?十[[;5}\"",
  },
  {
    codes: "        &%%\".:七.八八.七一.八$",
    descriptions: "&<=$0*1( =4一98[七1 ( (四3ç$9 <\"6'九9(*;( 八#\\\\八ş7二$一3五一ş 四二九\\+}五> 八\"|2{2ş?4",
  },
  {
    codes: " ",
    descriptions: " - - - - 七0ğ四(五)/^",
  },
  {
    codes: "          3[\"$.+:.2二.七\"..'",
    descriptions: "[öş^二二$}+ 八);)[5四^",
  },
  {
    codes: "          :&9%.<[.八六.3].九/",
    descriptions: "{.4}*(>七 )+十七十四七)",
  },
  {
    codes: "          9%+\".4二.1'..#.8\\",
    descriptions: "\"$>[九三ı三 =四';七{一@",
  },
  {
    codes: "          \\6;(.{>.=:.;8.{3",
    descriptions: "/ü九=2六 \"ü,]@8_ ,(?=8.<3ı六三{ 7'5)\"=ı&/] ?'九$五<@}",
  },
  {
    codes: "          三[\\).'<.一?.一5.3十",
    descriptions: "''ğ[十;一六{",
  },
  {
    codes: "        六[>三./3.3(.\\;.^{",
    descriptions: "5]四3>44^ '二四\\4@'1 ( 一{=ç}( <#3}4二/:二, \"{4,(ş\\5七九430ş _二\\四+四六| $十}=八[ş8九",
  },
  {
    codes: " ",
    descriptions: " - - - - 79ğ|#?五31 ",
  },
  {
    codes: "          ]{]2.//.;\".1十.<十",
    descriptions: "十öş八).十@三 :八&二['/4",
  },
  {
    codes: "          4*#一.&1./{.(>.3*",
    descriptions: ">#_;(七\"8 :(^二\"7,五",
  },
  {
    codes: "          =.0八.十_.4).+6.&<",
    descriptions: "1?ğ]19+九;",
  },
  {
    codes: "    2三5(.六$",
    descriptions: "\"%ğ八0八($一",
  },
  {
    codes: "        6二;一.九|.[=.7六.五二",
    descriptions: "@$八.\\0$九 ,>>4'4\"| ( ]1+ç<% [1>3}%八\"|[ >7?=一ş?>六0六1|ş '五^*1#^$ :[9274ş_7",
  },
  {
    codes: " ",
    descriptions: " - - - - 二>ğ/62\"5$",
  },
  {
    codes: "          ,1$].六_.}].四{.].",
    descriptions: "[öş四%|十4\\ 八70八%:}8",
  },
  {
    codes: "          3\\9).$[.>七.:/.二^",
    descriptions: "9四;><八]3 七+093$_'",
  },
  {
    codes: "          +?0,.$<.5七.)8.8?",
    descriptions: "十/五0<]ı' <>&{?}3$",
  },
  {
    codes: "          5&七一.九}.\\'.十=.{|",
    descriptions: "6(7_五0六\\五 一??七@0三,",
  },
  {
    codes: "          \\;<,.&\".6^.}八.五三",
    descriptions: "八\"ğ.35=十{",
  },
  {
    codes: "        '*一#.6'.7/.一].[7",
    descriptions: "[:&?九?:# ;^,?}\"<\" ( (0二ç@六 3<八/+1*五;( /_8,(ş四6;|8(^ş =\\八|0'十@ 9>=六%/ş\\:",
  },
  {
    codes: " ",
    descriptions: " - - - - 8\"ğ八6%@3六",
  },
  {
    codes: "          六(@{.1<.'[.;|.>一",
    descriptions: ";öş%;519\\ **\"2:8(九",
  },
  {
    codes: "          十$79.^十..%.{,.,/",
    descriptions: "十四[=0六\"< 5五70_^6五",
  },
  {
    codes: "          :%1@.+..(8.8].八@",
    descriptions: "<<ğ\\一1一七=",
  },
  {
    codes: "    一2*>.3十",
    descriptions: "3ğ八)4ı%\".一ış 八\\)七 *ğ($$ı六*ış",
  },
  {
    codes: "      *;^_.七).%).二{.四:",
    descriptions: ",十八]3五九\" \\<七$()_八 ( ||=ç,' $<9十]\"1六+4 ;|%,<ş1)十7:六@ş [@_^3@六^ 六$?41五ş}九",
  },
  {
    codes: " ",
    descriptions: " - - - )]ğ三'%\\)& ",
  },
  {
    codes: "        六<一$.(^.,二.<%.十=",
    descriptions: "6öş].'五{5 十四^]八^八=",
  },
  {
    codes: "        }9;?.<..六'.四).四:",
    descriptions: "(九2(;十6四 _44264}九",
  },
  {
    codes: "        七\"3{.|_.;?.1=..[",
    descriptions: "\\4'+7/ı二 $二/#5十,;",
  },
  {
    codes: "        '(七0.{4.?8.0?.4二",
    descriptions: "八?}>',>&_ _[一/十*/1",
  },
  {
    codes: "        @^]_.五=.{^.$$./九",
    descriptions: "=二ğ+二|&'$",
  },
  {
    codes: "    九>,1.九五",
    descriptions: "$*]8三(ış",
  },
  {
    codes: "      $*0%.2二.:三.\\|.六9",
    descriptions: "[三5<+十.5 7.十+?五四五 ( 二##ç1% 7七(+*一;1': 7<:[一ş九二;五01六ş ;.4>(五_二 >|(1>[ş>1",
  },
  {
    codes: " ",
    descriptions: " - - - ?<ğ九@三$%/",
  },
  {
    codes: "        \\(四+.*三.52.\"'.}4",
    descriptions: "1öş)67%\". 81{?九'_)",
  },
  {
    codes: "        _3_\".,3.'3.#:..八",
    descriptions: "三\\@九)六&' 五/78@8*)",
  },
  {
    codes: "        \\3]>.<八.'/.;..:#",
    descriptions: "0<77\":ı* 五<92八&::",
  },
  {
    codes: "        |一十\".十8.,%.%\".[<",
    descriptions: "三3ğ五(.%七@",
  },
  {
    codes: "    七[+>.%7",
    descriptions: "_,$2十ı %九二,,[>0 七=)&0{四[9*&",
  },
  {
    codes: "      |$+>.<九.八?.\"十.六8",
    descriptions: "七)0=\\七\"_ 二'二九,=4三 ( 三四5ç'( &二;=4\\77四} 十;\\04ş^:2:^\\*ş \\[#5$.八; 二<4三4*ş四]",
  },
  {
    codes: " ",
    descriptions: " - - - 8+ğ+<{三九*",
  },
  {
    codes: "        423).;}.4).(十.><",
    descriptions: "#öş*><.:\" :十]$9%^/",
  },
  {
    codes: "        九6七,.一|.'(.4\".3,",
    descriptions: ":11二{=(四 +@'*(38)",
  },
  {
    codes: "        %_&).一&.#九.@>.六9",
    descriptions: "]'三0@=ı> 七>九八;'<?",
  },
  {
    codes: "        (七]4.;三.9(.4,.^=",
    descriptions: "=+ğ一四七五九[",
  },
  {
    codes: "    六78^.2\"",
    descriptions: "四\"[四ı一ı",
  },
  {
    codes: "      十92_.@}.八<.九7.一2",
    descriptions: "/>三\\0四${ 3/]四#)七& ( =.^ç]7 (.十八四六>..+ 五&+十六ş*四^}<|\"ş 0#.2?+\"\\ 8:%三;'ş\"]",
  },
  {
    codes: " ",
    descriptions: " - - - 九四ğ95九'九;",
  },
  {
    codes: "        .{@\\.八}.3%.2%.2]",
    descriptions: "七öş|0七一\"| 3}<3{&_2",
  },
  {
    codes: "        0?十6.<九._:.六9.^&",
    descriptions: "二八\"一四)3} ^*)七\"二]6",
  },
  {
    codes: "        4\">?.\"0.;五.^2.%_",
    descriptions: ">:ğ\"+77[十",
  },
  {
    codes: "    ']=;.,/",
    descriptions: "}ğ\"六}ı五@十三ış (五$\\ 四ğ<@十ı:\\ış ",
  },
  {
    codes: "      65^九.5#.08.(<.71",
    descriptions: "6{0&>8[, +,@/九八_$ ( 二85ç>| $9二|,)^#八. 2@一/|ş,,[)6三#ş $3{2'(八@ 六六六/十>ş一;",
  },
  {
    codes: "        十(3}.一[.>\\.&5.>_",
    descriptions: "7öş]五九<0{ >,|#5六}>",
  },
  {
    codes: "        八,>三.8]._(.&%.:五",
    descriptions: "%4七三<,\\+ 5\\一八;}?4",
  },
  {
    codes: "        6#二^.五一.二七.三#.17",
    descriptions: "十?=6(一ı八 ],;\"&一.&",
  },
  {
    codes: "        +#={.|七.#,.}六..,",
    descriptions: "*8ğ5#$0>三",
  },
  {
    codes: "    >{79.二/",
    descriptions: "九;七(;)ış",
  },
  {
    codes: "      5六7{.>6.二?.九八.=/",
    descriptions: "十\"($*'{二 @|>+##三/ ( =二}ç九$ .@*0?^9|.$ {4|<>ş六&十一八;0ş 六.二^)|:] 02]?{.ş){",
  },
  {
    codes: " ",
    descriptions: " - - - \"8ğ)5[/七1",
  },
  {
    codes: "        1{九0.9:.3二.二?.七三",
    descriptions: ";öş'_($|五 3+4<四>\\八",
  },
  {
    codes: "        {4?7.七=.}9.三|.八^",
    descriptions: ",@:,&]}% ))7?/2[>",
  },
  {
    codes: "        )十二*.+6.<).四\".'^",
    descriptions: "]\\|}四\\ı1 _0=,五%8>",
  },
  {
    codes: "        九(%^.十5.&^.{..\"'",
    descriptions: "十5ğ|[]+=^",
  },
  {
    codes: "    .8^二.>[",
    descriptions: "四:\\>六ı #七;'/%一9 <二九^五五五&四>%",
  },
  {
    codes: "      >十=,.6{.八_.]6.>8",
    descriptions: "+|{/二一=\" ???^(@%八 ( ,8:ç)' 5五?\":|\"%二+ >,6一一ş:|(0\\\\*ş ^四<@八05] .^4&;'ş.6",
  },
  {
    codes: " ",
    descriptions: " - - - %八ğ+\"4@.五",
  },
  {
    codes: "        |,五).@十.五,.?一.6十",
    descriptions: "六öş\\九{_%$ @二73[1/十",
  },
  {
    codes: "        一{[\".[|.=0.$'.>?",
    descriptions: ";三{4{#*5 二七六/\"\\五*",
  },
  {
    codes: "        ,6/7.\\%.57.四3._=",
    descriptions: "1@].1_ı4 ?;2070四(",
  },
  {
    codes: "        七;[6.=#.\"十.}8.十}",
    descriptions: "5,ğ.==三八'",
  },
  {
    codes: "    {=;\".\\{",
    descriptions: "_九二六ı2ı",
  },
  {
    codes: "      55二{.[七.0}.6}.+\\",
    descriptions: "6八49六十:; 八>3四88() ( )八二ç=' 二/\\(六*(|0, }/)51ş*>+5[|二ş ^'/6$_=& 22$四29ş^9",
  },
  {
    codes: " ",
    descriptions: " - - - ='ğ00,2:4",
  },
  {
    codes: "        =/九%.*/.1).|2.[1",
    descriptions: "]öş47二5.7 9九^一三$1一",
  },
  {
    codes: "        '=[\\.$].,).;三.;二",
    descriptions: "?=.}1七七@ \\}/=9.五7",
  },
  {
    codes: "        /24'.^\\.+#.20.:|",
    descriptions: ",五ğ:62'57",
  },
  {
    codes: "    九十*六.&3",
    descriptions: "#ğ[6>ı3七五\"ış @_;? *ğ%4\"ı),ış",
  },
  {
    codes: "      ()>].3^.\"0.9十.>#",
    descriptions: "^#一$2\"\\> 十/4%8?6/ ( ${六ç>= ]%5{九]'八*九 ;_三84ş)+7<%>5ş 86?八,&1| 3[_三]\\ş;@",
  },
  {
    codes: " ",
    descriptions: " - - - (}ğ;\"#&=*",
  },
  {
    codes: "        [2四2.19.六[.二八.@$",
    descriptions: ".öş0七9六四3 ,8<<{<'四",
  },
  {
    codes: "        七&4/.六0.6|.,..三8",
    descriptions: "$\"七\"2五5[ ,[*八7>}?",
  },
  {
    codes: "        #&,0.)>.]<.@九.%>",
    descriptions: "4^二;八^ı六 .九四%?\"70",
  },
  {
    codes: "        3\\(_.0一.}六.;8.%9",
    descriptions: ">[ğ#{>五^@",
  },
  {
    codes: "    30*2.^八",
    descriptions: "(九八@?1ış",
  },
  {
    codes: "      *@4二.,&.]五.?).4(",
    descriptions: ",九八['#%\\ 8九30%{'九 ( '1.ç三1 九9\\二7六.\"[8 #%@七八ş;\"'/一\\=ş ]#十*#五30 ;}\"+>'ş九八",
  },
  {
    codes: " ",
    descriptions: " - - - 五%ğ?七9+四5",
  },
  {
    codes: "        9{一三._十.@(.三五.;;",
    descriptions: "七öş]^9+六{ 6[+_914>",
  },
  {
    codes: "        &九)\".&二.43./(.0^",
    descriptions: "4二.8\":_十 +三,/五三_]",
  },
  {
    codes: "        ()53.%:.#'.[#.0=",
    descriptions: "$_'^五[ı# %2]$^,.\\",
  },
  {
    codes: "        _#+十.0*.4三.四^._,",
    descriptions: "_@ğ3|1@@$",
  },
  {
    codes: "    6(91.六]",
    descriptions: "七二八>@ı $><./@_三 四十:0\\八<=+0&",
  },
  {
    codes: "      十9/十.@六.8$./三.2=",
    descriptions: ")^,\"八^八% 5?]十七/,] ( 1;<ç8四 (@十4'=1#.4 #}60六ş87七%八\"3ş @?2一2)%& \"?)1*{ş\\^",
  },
  {
    codes: " ",
    descriptions: " - - - @}ğ]&1[\\[",
  },
  {
    codes: "        =?.$.]四.'*.,).2七",
    descriptions: "十öş>九7六:_ >})#一十2#",
  },
  {
    codes: "        6|七四.9\".一$.六].{八",
    descriptions: "|一/:九2%' 2五>5*#%二",
  },
  {
    codes: "        八=+\".0/.9[.7一.%(",
    descriptions: "七6|九\"十ı^ 五:6六7$#]",
  },
  {
    codes: "        7#;/.]七.7:.[5.@|",
    descriptions: "+0ğ|}0五<3",
  },
  {
    codes: "    _四}4.25",
    descriptions: "一;一&ı一ı",
  },
  {
    codes: "      <七>,.九^.=@.9>.}:",
    descriptions: "{)|@7二七6 32=(8_): ( *25ç|, $&[?\"1;(91 :{/$'ş4/6.><{ş =@五6九<*: %)0}\\(ş}.",
  },
  {
    codes: " ",
    descriptions: " - - - (|ğ@\\>#%3",
  },
  {
    codes: "        九.五/.\"'.三\\.$三.[6",
    descriptions: "=öş(.:*7+ 一6五,78=六",
  },
  {
    codes: "        .*22.|$.;'.5}.*.",
    descriptions: ".\\,,四{2^ .=三<:22八",
  },
  {
    codes: "        6&:8.[<.4九.3+.,7",
    descriptions: "]:ğ4@(|一$",
  },
  {
    codes: "_@.^3",
    descriptions: "五2.+ 八8|}):]& _&九七七}$一四3,八= (61九八78ş ']0_=五.7 (二?.&八 1[^>@;二\"56^3.\"",
  },
  {
    codes: "    ]|:@.{一",
    descriptions: "_七#8三9 ,八:ğ:47[+ \"二六3 _]<=$@ 四ü<[4二 84%2&@七\"八93 0@七})]\\2 >{,\"+",
  },
  {
    codes: "      0;五3.=*.}<.%].<0",
    descriptions: "**三\"十7九& 九[八77__0 (%|.ç0: 四一4\\}385+九 七]九;5ş三七0六六_九ş 5八#/+44} 六&;)5四ş+\" 1(7",
  },
  {
    codes: " ",
    descriptions: "      六五?[4二+0 七.3:[74) ",
  },
  {
    codes: " ",
    descriptions: " - - 五=ğ\"三(九;5",
  },
  {
    codes: "      5,>4.7}.九四.[4.七^",
    descriptions: "三五8九şü^9ü} 3八[=)75{",
  },
  {
    codes: "      +'.九.,^.,(.二\".1.",
    descriptions: "Ç四十ı<^ı= 5六75九?_>",
  },
  {
    codes: "      _>7,.1}.94.3|.8=",
    descriptions: "@^ğ&}+五;\"",
  },
  {
    codes: "    ?8|>.#@",
    descriptions: ")ğ<'七ı五&:?ış 一>\"/ 9ğ4|;ı5$ış",
  },
  {
    codes: "      %096.;\\.5@.\\(.\"8",
    descriptions: "{+);三*]| 2+|3五\"'} ( 十=1ç?} \".一8,8%$四3 @[九二+ş[:五\"(六[ş 八七'8#|十] 0>0^|^ş四二",
  },
  {
    codes: " ",
    descriptions: " - - - ]^ğ\"'5<?/",
  },
  {
    codes: "        五一&七.四*.三@.七9.九%",
    descriptions: "=öş四>;:1@ 十7&3@|三?",
  },
  {
    codes: "        一)[9.':.4{.1,.$六",
    descriptions: "]|*(>=,] #7九三%;/)",
  },
  {
    codes: "        85#三.'三.七四.七(.4十",
    descriptions: "+{八&,}ı9 )三@九九<\"七",
  },
  {
    codes: "        }九四|.:二.&5.?{.13",
    descriptions: "77ğ*6*'{$",
  },
  {
    codes: "    {#.七.八五",
    descriptions: "?(4]一二ış",
  },
  {
    codes: " ",
    descriptions: " - - - 一}1'ş\\3ğ六 ,三% 5?. )? 二:ç一2 %(.?四 .>* /\\. 一五 <;ç./_:), =.% *一6ğı,",
  },
  {
    codes: "        5&8}.&7.六二.{一.四6",
    descriptions: ")八\"一;?}| 七\"四\"九}+1 ( @<.ç\". 九8'{1.44九, 四*9;七ş#=:&(.&ş 5&>:四/>7 );三一*.ş12",
  },
  {
    codes: " ",
    descriptions: "          二7六8四五%@[七2 七+9%4*() ",
  },
  {
    codes: " ",
    descriptions: " - - - - 三九ğ9+[八五{",
  },
  {
    codes: "          0(#}.,].4;.*%.19",
    descriptions: "4öş#);/7六 ]七九7{><]",
  },
  {
    codes: "          %三\\[.+十.[{.9}.5#",
    descriptions: "{)@八;八十; };=_四/45",
  },
  {
    codes: "          八%四>.\"1.7).@0._}",
    descriptions: "六:((7/ı1 *$十4*,\";",
  },
  {
    codes: "          /4}四.七_.四/.+一.二,",
    descriptions: "|5ğ|+=4>@",
  },
  {
    codes: "        ).八/._五.)七.五&.}/",
    descriptions: "@8五}^_七. \"$;0四:7@ ( 3'}ç\"\\ %/.4三42:十九 :,*}5ş|四}十>八|ş 7$\"六_>#一 1&^+{三ş<5",
  },
  {
    codes: " ",
    descriptions: " - - - - 八:ğ<90*27",
  },
  {
    codes: "          (^5七.]九.#/.01.$:",
    descriptions: ",öş二二^<&% *;;^\\*,8",
  },
  {
    codes: "          8@[三./&.#<.92.{(",
    descriptions: ";?61?四九七 _七;#一六/八",
  },
  {
    codes: "          [:五|.:&.=<.;七.*三",
    descriptions: "一_^'\\十ı\\ &*}/4%^6",
  },
  {
    codes: "          三*('.1|.三4.,一.七,",
    descriptions: ",*ğ;\"}0,<",
  },
  {
    codes: "    ?8\"六.五一",
    descriptions: "五].五四ı 7*九*@.^& &^#$十七7一_;+",
  },
  {
    codes: "      /+#3.</.,..十].五#",
    descriptions: ":{(五822) }^806八@八 ( 725ç{四 )[3%_$1(}八 十:.<2ş<七=_\\>3ş (0一?一)0七 }_{{五\"ş95",
  },
  {
    codes: " ",
    descriptions: " - - - 八?ğ$+@六6^ ",
  },
  {
    codes: "        /=,$.31.八(.4@.<五",
    descriptions: "6öş]%>$*@ 9:8$四一>2",
  },
  {
    codes: "        /:0@.3<.4二.|{.?.",
    descriptions: "$73五673@ ?'一八0==七",
  },
  {
    codes: "        |\"{).:$.七六.九\\.50",
    descriptions: "25]:8八ı8 2(|==^?]",
  },
  {
    codes: "        ^;\"四.$|.三].5[.?,",
    descriptions: ";>ğ9四7%@.",
  },
  {
    codes: "    /七^\\.,#",
    descriptions: "?3八>ı一ı",
  },
  {
    codes: "      九二1..三'.3[.7+..5",
    descriptions: "&,82=%'< 1{@一%:*, ( 2五8ç,& ]6]0八;]$)$ 六}8})ş&\\*46(九ş ))2:]&*^ |'9@:(ş;=",
  },
  {
    codes: " ",
    descriptions: " - - - )(ğ8五7{7四",
  },
  {
    codes: "        六/'9..6.九六.4\\.>#",
    descriptions: "2öş/}>二%0 ]&>六八+?%",
  },
  {
    codes: "        <七<6.(一.十十.7九.8十",
    descriptions: "(^6$55[? 九0|%10[)",
  },
  {
    codes: "        一{[一.1\".<).六_.)<",
    descriptions: "=@ğ一7/^1*",
  },
  {
    codes: "    九七>|.&:",
    descriptions: "三ğ?=&ı8八$%ış @\"?[ ]ğ%?0ı.|ış",
  },
  {
    codes: "      }'>@.8四.}8.<三.六@",
    descriptions: "\";^9九'.* 三87>,九八/ ( 7<7ç|) _|4(7十<2>= :'5九/ş\"?四]一}四ş ,(26九\"]' ,$[%[@ş+;",
  },
  {
    codes: " ",
    descriptions: " - - - 2)ğ%=@八十8",
  },
  {
    codes: "        :一2(.;[.3_.4二./,",
    descriptions: "%öş5:&&}一 %>五{三_七&",
  },
  {
    codes: "        239一./2.十^.(%.:&",
    descriptions: "^4;八*三=\" [%)一0%84",
  },
  {
    codes: "        >$四&.六&.*1.0&.五四",
    descriptions: "+3五'=3ı六 四#.=.八}:",
  },
  {
    codes: "        +七%4..<.@%.七四.5九",
    descriptions: "^6ğ3</}%,",
  },
  {
    codes: "    +{80.$[",
    descriptions: "%'一){三ış",
  },
  {
    codes: "      [[七\\.$0.十六.七六.&=",
    descriptions: "(8/%\"1$一 )@七1?七,3 ( 四)#ç@: ()}''&/:6: 七>=*?ş八八9五,+]ş 六六七&@一$< 一0六六十2ş].",
  },
  {
    codes: " ",
    descriptions: " - - - ^{ğ十}@^='",
  },
  {
    codes: "        >三2九.##.//.7八.\"_",
    descriptions: ">öş.,].六) :5七5$6?二",
  },
  {
    codes: "        $#四十.二5.7,.#&.<八",
    descriptions: "{六4五;:>, {99204七|",
  },
  {
    codes: "        _%.二.六@.五}._*.,<",
    descriptions: "(),_4&ı6 ;=$1四.'0",
  },
  {
    codes: "        \"3五一.5&.;#.三3.$5",
    descriptions: ")@ğ/\\四五$七",
  },
  {
    codes: "    =27\\.8[",
    descriptions: "7{>.8ı 54[五三/}+ 46('五6.=7\"(",
  },
  {
    codes: "      ]=(%._/.57.|(.?\\",
    descriptions: "_0[=^)七* ==六&1:([ ( \\2=ç\\四 {_55}}一]六_ 3\"/;0ş/$4四9四<ş (/'$>:1; 0,五一三8ş八\\",
  },
  {
    codes: " ",
    descriptions: " - - - 六@ğ|'\\^一%",
  },
  {
    codes: "        五;@@.七五.?$.七^.:&",
    descriptions: ";öş九^9,六四 \"<(/2#/;",
  },
  {
    codes: "        &&*二._(.*+.七$.九八",
    descriptions: ":=)<八>4/ 五0\\.]二,(",
  },
  {
    codes: "        \"四四7.#二.=6.2>.4$",
    descriptions: "'')八?三ı+ 5%@@{十8#",
  },
  {
    codes: "        1&]&.^].[9.=三.$<",
    descriptions: "\"8ğ9六\\9=:",
  },
  {
    codes: "    3}二八.19",
    descriptions: "@=/9ı#ı",
  },
  {
    codes: "      9>八,.^\".二七.,:.三|",
    descriptions: "一[%.七6*} 62[十[+十) (八6\"ç}3 ^73]$\\\\\"=十 四_0|七ş十七^:;/三ş 3,8&2;一| >||三十%ş)三 68.\\4$2六_?( +[*/$十/)",
  },
  {
    codes: " ",
    descriptions: " - - - #_ğ八_84=六",
  },
  {
    codes: "        {四(>.五>.?8.>'.;1",
    descriptions: "=öş3\\七八二十 8<|五4:七5",
  },
  {
    codes: "        十,<?.||.^}.?六.)?",
    descriptions: "\\8^[四三一* 9九5一]|&=",
  },
  {
    codes: "        5#四].七_.@).9\".(;",
    descriptions: "7四ğ5#__?六",
  },
  {
    codes: "\"/.=]",
    descriptions: "/ığı^|^^ı十 (二,?^<十 七=6&}) '^ 十一'(;ı_ %七七 \"{+} :3 32*0\\五{? ({0ş +[四一",
  },
  {
    codes: "  |5,+._]",
    descriptions: "^ü+ü: $+2二96九6四 *6八7 20 六7?^\\五6 (*%'@ ;ğı6;ığı ?]四]<ç_ >.>&,^+%@ğ|8*三",
  },
  {
    codes: "      {(三\\.''..六.}>.^{",
    descriptions: "0^ş 九ğı+7ığı +_ |<.ı ,4ç三,五|{九_九",
  },
  {
    codes: "      )十[8.四六.}8.%四.%=",
    descriptions: "?2ş 五ğı/9ığı 5* :)./]= &四=}> &(四\"? <] 0七.ı <1ç四9^0六\"六一",
  },
  {
    codes: "      >7)$.<}.%8.6|._二",
    descriptions: "_2ş 八ğı8?ığı 9? '6.ı _%ç十:3三(二&;",
  },
  {
    codes: "      +^{四.'\".&[.八(.+(",
    descriptions: "*$ş [ğı&\"ığı /_ (十.,^^ ?四\"\"2, 四+_45 #' 三八.ı ]九ç(8}三8七.<",
  },
  {
    codes: "      七六七'.[\\.[@.{/.%?",
    descriptions: ",3九三 (ğı七1ığı 十 >9.ı 一(ç5七0'|^四?",
  },
  {
    codes: "      {九/3.\\%.8{.|六.^@",
    descriptions: "六_.[ 七ğı(三ığı 4 十,.ı 85ç/二, @'|3| .9 九十.ı 9二ç'>三|\"|;?",
  },
  {
    codes: "      \\^9^.>一.>;.03.八)",
    descriptions: "+二ğ@/ ş:$%],]六}一 +_8四'七*三 ^@3&十\"ğ\"@]* 三ğı]十ığı .* 八3.ı *(ç|=\"九八:二(",
  },
  {
    codes: "      :$五七..,.二?.三2.一一",
    descriptions: "一6ğ_> ş,=_八>[+=四 五9'/54>' :^&)'@ğ0,#{ |ğı6@ığı 一\" '..ı <'ç$二, @427/ 9< %五.ı",
  },
  {
    codes: "  .}=..<#",
    descriptions: "{ğı\")ığı 八; ${.ı ?_ç/0 'ü_ü} ${@五九%@<7 9@,1 96 52.九二{?",
  },
  {
    codes: "      十?}}.2三.83.7一.^.",
    descriptions: "8<ş @ğı>2ığı 13 四(.ı 十六ç<< ^ığı( (八;\">275",
  },
  {
    codes: "      02*;.=(.0,.4).九<",
    descriptions: "9十ş 8ğı#{ığı 九8 (2.ı 九<ç'7 ._ 1&\\[_13@",
  },
  {
    codes: "      @#[$.|..(三.4八.:)",
    descriptions: "?五ş 5ğı$|ığı 四3 3<.ı *,ç0} :ığı七 %^.六四&]4",
  },
  {
    codes: "      &7\\0.>3./(.^].0&",
    descriptions: "0九ş :ğı六&ığı *# {二.ı :7ç/' }4 +四九&?%4{",
  },
  {
    codes: "      |[2+.([.十\".三?.,[",
    descriptions: "$ığı4 3<+五<^@8",
  },
  {
    codes: "      :<$三.;\\.1$.2(.六}",
    descriptions: "_\"=&_ı{ @%@|九]\"(",
  },
  {
    codes: "    \\[.五.二_./1.7+.七.",
    descriptions: "三4ğ九五([\"七",
  },
  {
    codes: "  '1(\".[&",
    descriptions: "六五ğ1;>}_9 (;@]5>\\ ;$ 56%ı2 '1=5+_ 一三}46$' +八?=*)",
  },
  {
    codes: "    十七8\\.二十.八4.=\\.&{",
    descriptions: "4;3一六; 0< 4&*ı' '1+8[$ 62\\+七\"}",
  },
  {
    codes: "    一;.八.八/.</.+=.三2",
    descriptions: "_八八\" ={?五 七'ş +四_(%^一ış 1ığı_ .]</一1.%",
  },
  {
    codes: "    *%二三.)).,/.'@.*7",
    descriptions: "五+^&@一%3五ş 69九八 @9一八 四+?$?^五ış 五ığı\\ <0,1\"\"八<",
  },
  {
    codes: "    (?_(.]9.0].(4.6'",
    descriptions: "''ğ<' ş=?%.;七((8 ):\\二0?9\\ 5:|%}4ş )ığı' &(\":$(='",
  },
  {
    codes: "    84>+.<九.一0.三|.):",
    descriptions: "9一.$&ı# <5*三+7十[",
  },
  {
    codes: "四].'.",
    descriptions: "$<42三 ,. +#一#*;7ı9 ;3九 四[$_二<=< ( 0)ş 7=^五 =1#8}\"?ış, .*<{.]六\\(ş,",
  },
  {
    codes: "  2{0五.$1",
    descriptions: "?ü.ü ,三ı十&'4ış",
  },
  {
    codes: "    0=五七.3\\.一8.*\".*5",
    descriptions: "\";_42$)_ :#四 .\\0'\\9=",
  },
  {
    codes: "    7$<,.一@.3>.7&.3)",
    descriptions: "九:ğ十9二@8>",
  },
  {
    codes: "    %五6八.{:.八一.八=.[七",
    descriptions: "十>%02 ;$'/[.\"",
  },
  {
    codes: "    4\"2@.&6.2;.8十.}十",
    descriptions: "?九ğ三,.61一",
  },
  {
    codes: ">五.\\四",
    descriptions: "}九ğ七= +|{ 0357 *{ 三4/五076 (1}ş +\\'} 6[)26]6ış, >#6?&3)八九ş, |.[+ç^}$48ş,",
  },
  {
    codes: "  \\六&5._|.$?.九*..'",
    descriptions: "三ü3ü2;_3四(7|\" $9@ 四$七%1*%_",
  },
  {
    codes: "  九=@=.<:.}$.二}.[#",
    descriptions: "5七4十九三四+ı* 八>8 =+[7九[^8",
  },
  {
    codes: "  六(85.=;",
    descriptions: "\\&ğ2|,@*4",
  },
  {
    codes: "    4]?1.7_.}9.2^.[六",
    descriptions: "]3ğ二七 一{十48二=\\8ı二 ,4ş )^\\'+31二",
  },
  {
    codes: "    }'一9.)六.^+.$7./1",
    descriptions: "+2ğ}[ @6{<<](\"八ı[ 一3[+ ^3十6十五8{",
  },
  {
    codes: "    {3$+.:九.%0.\\0.二%",
    descriptions: "30ğ9# ')4;0>{[^ı$ .)4一_ *=@#九一一'",
  },
  {
    codes: "    ;21).]一./:.一2.]二",
    descriptions: "+%ğ00$07\"",
  },
  {
    codes: "2六.*1",
    descriptions: "4ığı六三三:ı. (9)|/\\< 55)$@) ]<五十 六5三七^ı三 三}0\"9@二39ış />八> \"四0;@4\" (_6\"",
  },
  {
    codes: "    十,@=.'|",
    descriptions: "四ı]ç四2ı 8:=] (六%三/ ^八4一4/), 7*]ç445>,^一ış; =九'54 '.1九五五十",
  },
  {
    codes: "        |七?(.{4.#>.四).二9",
    descriptions: "4ı;ç5九ı 6九_一 ([/5# 2$9>|<)",
  },
  {
    codes: "        57九;.十4.6三.十(.*?",
    descriptions: "_九七三四 98三$",
  },
  {
    codes: "            \"十@1.二{.九九.二@.九:",
    descriptions: "<ı#ç22ı ,^<8 (二三+$ 8_[3(三)",
  },
  {
    codes: "            七><#.=九.\\/.七七.3=",
    descriptions: ">@45\\ {#3%",
  },
  {
    codes: "            %=八0.7九.:三.%十.'*",
    descriptions: "@ı5ç0八ı 十>)< (=三&4 七}5{:>)",
  },
  {
    codes: "            /三2七.]2.十6.$七.+\\",
    descriptions: "5$,#, $0@+",
  },
  {
    codes: "          +三6=.六,.+>.四^.}&",
    descriptions: "}ı=ç)\\ı 4:.? ('1]_ ]三(\\$2)",
  },
  {
    codes: "          四^一%.@<.&三.2).+0",
    descriptions: "4一|<六 8$[/",
  },
  {
    codes: "    三7一>._八",
    descriptions: ">*ğ1'四八09",
  },
  {
    codes: "      ^七十{.;=.7*.}|.2)",
    descriptions: "$ığı8九'/ı, (102.<9 \"%&4.) 五ü|ü七 .十*_{<[[五 ^(九八 六四 1+4;1$四+",
  },
  {
    codes: "          \\@七+.$九.%5.?三.^}",
    descriptions: "<ü$ü二 8#三)1^|#7 ?七,2 /} %#:57:+\\ (0*&十四 [ü六;{ \"#=6ı <,. 7<.'七 {?ç三7:7:)",
  },
  {
    codes: "          /?]:.^+.@^.47.[7",
    descriptions: ")一ğ八@八六'6",
  },
  {
    codes: "        =\\9^.</.二4.2十.4)",
    descriptions: "]5ğ',<2?>",
  },
  {
    codes: "    1@四2.,]",
    descriptions: "8ı=ç|$ı }.;[ (13'= 2\"$5十%), 三.,ç二0%+3/:ış; 5<七四6 #]+|@;@",
  },
  {
    codes: "          _06^.07.\",.2|./七",
    descriptions: "87.$一\" 6{四0#@%><ış %四1九. 746=$;{ ,ş?:#|;%十#三 @一ç三$?;ş +{六@(|;",
  },
  {
    codes: "          四'%_.>5.六'.}7.八二",
    descriptions: "74ğ6+1\";]",
  },
  {
    codes: "            0十);.|八.9>.[<.*<",
    descriptions: ":<十) ^\\+七四.",
  },
  {
    codes: "            4$5;.九\\.,*.十^.28",
    descriptions: "<1ğ5_ _ığı81=[ı$ ^){*一2+十",
  },
  {
    codes: "          |(二4.^:.4&.七/.]五",
    descriptions: "$2ş[] ş)=}*7( ?}*ı%九+_@ış 03599一<",
  },
  {
    codes: "          :四);.九3.六<.0^.十6",
    descriptions: "@ü=ü< 五*15一#+\\{ _[>?'?:= ()>0六> 3ü?%4 <三%{ı 5,4 25.七# 9?ç+&:^^)",
  },
  {
    codes: "          七:4}.18.^五./9.5'",
    descriptions: "8十ğ\"7六_/0",
  },
  {
    codes: "        3$6三.二'.0/.;\\.#%",
    descriptions: "\"(ğ十|{#一6",
  },
  {
    codes: "    四:1+.,七",
    descriptions: "[,ğ;)+6一$",
  },
  {
    codes: "          +#9_.+%.五十.</.二6",
    descriptions: "[))十31 [_三9九]16+ış }四'_@ +[八: |5(.4 94五 :ş#5& 7ö]1#七\\ş *1/#)2_",
  },
  {
    codes: "          |&八?.(一.9^.@*.5{",
    descriptions: "[九ğ/?四}6=",
  },
  {
    codes: "            4{8二.}]._@.7<.){",
    descriptions: "\\十9; {4%#9一",
  },
  {
    codes: "            <:6九.+三.['.*=.九2",
    descriptions: "'2ğ.} ?ığı一8/#ı七 :七7+7=3七",
  },
  {
    codes: "          )>92.#>.;七.七八.;*",
    descriptions: ";6ş;% ş>五七]$八 <[2ı('三:#ış +>/2>&]",
  },
  {
    codes: "          }}0\\.=五.=四.|\".十八",
    descriptions: "?ü(ü: #/|.(?<+: 七^<9 4] 一:74($|2 (七'三6$ 七ü九八( \"+@_ı 6,| 6^.'0 '@ç}6$*])",
  },
  {
    codes: "          {#*?.三;.?>.九2.+^",
    descriptions: "*%ğ七三%^]9",
  },
  {
    codes: "        八:4+.:八.}8..{.',",
    descriptions: "*'ğ9二,1/(",
  },
  {
    codes: "[七.<(",
    descriptions: "/六十一(*九(ı* *:5| }3二1]1\"ı8 二80%6_)1<ış 四}5七 \"#二八4%\" (:$* 二*1;],.一ı)",
  },
  {
    codes: "    \\三12.%一.二[.''.+'",
    descriptions: "53ş 四@十?% (>十).})7 四44*_)",
  },
  {
    codes: "  \",9}.7\\",
    descriptions: "(\"_0 08三'[ (十1三八二)",
  },
  {
    codes: "    |二.\".,8.38.@(.(4",
    descriptions: "1*4八>2一$ ö5 \"0;[\"_,{七 [#>ı七/ış ;六=( '八八=:@ (&八4ı ;ş'6<}(三= 六:<, {+一0\"7九ş <78|.",
  },
  {
    codes: "    023>./6.&五.%$.,.",
    descriptions: "二八ğ%6/{_:",
  },
  {
    codes: "8].1=",
    descriptions: "三6ğ一] %<十七\":*@2ı六 (+四一'二^.1ış +29+ \"|>4&;\" (3/3 十一六#2<二七八ş)",
  },
  {
    codes: "      @835.:七.88.},.七7",
    descriptions: "四\"ş ,3\\.] (六=.1一3一 /_/九>)",
  },
  {
    codes: "    1四@*.=3",
    descriptions: "九{$= 67@+; (八五六6十)",
  },
  {
    codes: "      <_<;.|).2,.二%.'*",
    descriptions: "二一}+二三|八 ö: 九四|?*3}%* 1{八ı}3ış 34;] &_ç'}十 *|<7 =ğ>_4$}\"ı (]一3ı }ş*1>?7)二 {四[3",
  },
  {
    codes: "      _7%$.&).1(.?,.四*",
    descriptions: "六<ğ二七9三;*",
  },
  {
    codes: "      .#\\9..七",
    descriptions: "*七ş 七&7;< (九=]五二&# 7^.#@)",
  },
  {
    codes: "      53/七.六;.:[.8;.&3",
    descriptions: "<=八ç9(2@十 一>(ı二^1五ış",
  },
  {
    codes: "      %.@一.[}.\"_.|7.二/",
    descriptions: "/}0ç7一\\?> /|一ı\\]ış",
  },
  {
    codes: "    '3=+.?'.'9.八六.3五",
    descriptions: "82}_ &26四五 ((#;六))",
  },
  {
    codes: "  [\\\\0.|$",
    descriptions: "5ü<ü+2+290四5二 01+]2;[三",
  },
  {
    codes: "    :(\\1.42.@%.二=.五=",
    descriptions: ")4]3九;*0 ö} 88五*六@_@\" :+[ı八]ış",
  },
  {
    codes: "    八777.九;.|*.64./\"",
    descriptions: "{^ğ二]'>9=",
  },
  {
    codes: "      十)$;.9\\.*+._).21",
    descriptions: "(二ş \"五二^] (5六{四}=\" %\"#6五)",
  },
  {
    codes: "    ^;四七.=9.'[.:\\.8{",
    descriptions: "39?1 ?;十五十 ([4八二$)",
  },
  {
    codes: "__.\\<",
    descriptions: "4ığı_*\\1ı九 (\\.$):? #\"#^7) [*\\= %1;十%ı> 五|.#[二3|3 ,\"十十 ,#^ 423415&六\"/",
  },
  {
    codes: "    <611.(@",
    descriptions: "一ı;ç%(ı 八*{( (=[@/ /6六六4>), 十=%ç十'$<九$一ış",
  },
  {
    codes: "        _八\\5.={.,|.3<.一2",
    descriptions: ")}九2>\"",
  },
  {
    codes: "        %9@\".5八.@..5).$'",
    descriptions: "$+ğ/;1]八六",
  },
  {
    codes: "      +.9@.$,.^四.:&.\\6",
    descriptions: "4}ğ一一五'\\4",
  },
  {
    codes: "    \\+3五.&2",
    descriptions: ";]%09 #<十\"%9|",
  },
  {
    codes: "        三/[?.<@.九二.六,.六0",
    descriptions: "七5+\\5,",
  },
  {
    codes: "        \"1五十.#2.四7.1^.}9",
    descriptions: "*>ğ2;{_;五",
  },
  {
    codes: "        一>六*.9'.五\".}^.*?",
    descriptions: "七ığı九一:6ı3 (%?7@7四 5:}七:) /'7& 八九 三ö=.4@*三=6",
  },
  {
    codes: "        1%{4.\\..>\".)一.4\\",
    descriptions: "#>?.;ı, <%\"二 四5 \\ö_六$4/.1*",
  },
  {
    codes: "    四56@..\"",
    descriptions: ",九ğ/二十_+五",
  },
  {
    codes: "      &.}^.十[.九}.0\".五5",
    descriptions: "#ığı*九\"三ı四 (=三@&+六 四;<八6) =2;9 +& 八ö0:_&七):9 ( [4^0} =ü[+$ 684;ı",
  },
  {
    codes: "      八]?3.8'.1/.%..一*",
    descriptions: "[&ğ三]四)^/",
  },
  {
    codes: "    )>八+.('",
    descriptions: "4ı>ç0]ı 四3;2 (四#三> >/十>5&), 7七8ç]>|1五12ış",
  },
  {
    codes: "      13'=.#,.五7.五*.]=",
    descriptions: ")[:\"0 _ö3^四7;八",
  },
  {
    codes: "      >9三9.二@.$六.@(.(*",
    descriptions: "{&ğ九$@@36",
  },
  {
    codes: "    ;四+|.+六",
    descriptions: "2]?,九 }二三:4&>",
  },
  {
    codes: "      .+74.30.2(.+十.>九",
    descriptions: "0ığı_071ı{ ($}\\五^] 8五)0+) =*'4 ]3 三ö_五8<'3#十",
  },
  {
    codes: "      九+'1.十9.%^.'_.?五",
    descriptions: "}六31三ı. #(6+ 7_ 3ö5五7,*八&]",
  },
  {
    codes: "    四:/7.十四",
    descriptions: "6+ğ#/35*;",
  },
  {
    codes: "      七=%].七).{1.一九.:.",
    descriptions: "+ığı7>'_ı; (]%\\二)^ 90@一[) [4=> /[ 九ö)_\\15#八1",
  },
  {
    codes: "      ,二十1.>:.]<.(3.^'",
    descriptions: "+]37'ı' 2一@十 )五 2ö八五#3>八?7",
  },
  {
    codes: "&;.|0",
    descriptions: "",
  },
  {
    codes: "四*.十+",
    descriptions: "",
  },
  {
    codes: "|5.=]",
    descriptions: "",
  },
  {
    codes: "#@.五}",
    descriptions: "",
  },
  {
    codes: "\"+**.\"=.1'.4一.]|",
    descriptions: "/&|{+ *;#3 ?_/\\四.%ı七 九^10\\**五( ^3八> }%5 %$:一3九)'六= $四>}{ %=;9.",
  },
  {
    codes: "=7.九1",
    descriptions: "六+ğ)[ 六84五八/七${ı$ }三)四]六1三\\ <8\\4 .5# '九5>=二{=二9 54(三9 =]287 ;六九十<七9(",
  },
  {
    codes: "  (\"八\\.(\".七三.*1.@五",
    descriptions: "\"]ç| 0_6' <ğ九6=2三八ı九 {十9三 |? 6ö5,\\^('九$",
  },
  {
    codes: "  6')六.,\".}}.<8.]1",
    descriptions: "七?#0'{十(ı$ :?一' /九 7ö[6\"265];",
  },
  {
    codes: "  十九7七.[^.|二.&3.\">",
    descriptions: "\"ü6ü四,^'+三|九% =9;: +六 :ö,,[%|三:,",
  },
  {
    codes: "  八51?.^>.*=.?{.)(",
    descriptions: "%.ğ(一_0*+",
  },
  {
    codes: "十6.=:",
    descriptions: ">ü=+|+ ({一八[.:四 ,^&: :8\\3?); 四6九&) 8一 八[一:七(% [6:四,}九ış ;^七^ \\[",
  },
  {
    codes: "  \"{\"+.八?",
    descriptions: ">ü,四\\/ (\"*+*四+@ 九6:0 =二$<()",
  },
  {
    codes: "    6$0二.\"{.[{.>7.],",
    descriptions: "三十6(' 0. |=\\7'1}\"}/ 2\\)_ 8:]<)[六[^",
  },
  {
    codes: "    :;<%.7'.11.;7.*_",
    descriptions: "'[ğ@5 七)47,:十五6&5八 ?五%. \"_/十*七]0五",
  },
  {
    codes: "    ]/{2.{_.3一./|.6:",
    descriptions: "2#六67 ]5(五 6九 _ö2}.8_^*",
  },
  {
    codes: "    三二@@.{5.<)./七.\"*",
    descriptions: ">\"#>])五 }#十)?2(ış 二1二? {] 'ö七)70.,七",
  },
  {
    codes: "    ?^9>.5*.4*.\\..}1",
    descriptions: "$8>}0%\\; 十八五< )/ 'ö4七#九}四4",
  },
  {
    codes: ".&..\"",
    descriptions: "$)?$ı 1六42 %{ *ö*<.^ _\"@九 2三八4 >二={ \"ö[?+二 九一十七五七6 七[五^ '&七四<四",
  },
  {
    codes: "  ##4#.&;.*5.?+.'[",
    descriptions: "}4\\+ı 一\\十6 四一 [ö&三_8 &二.& %十($ 六(1十 \\ö|(0八 24<71五9 '=4十 (<&|{三",
  },
  {
    codes: "  四2.\\.九/.2{.9).3_",
    descriptions: "'八;\\ >, +ö@五?\"3$四39, ?六八'+二 ,<,]七:$ *+1. &\\]9<: 二'+> 5| {ö1?六\"五?)+三,",
  },
  {
    codes: "7二./[",
    descriptions: "9三=;1.%@ ..一/#十ı2#^ 十五)${70%6ş 8五_>2&?三 [一 )9ğ_& &*&42五7' ('?^",
  },
  {
    codes: "  /+5=.{]",
    descriptions: "五._042十一七1 |]'> :,ğ<5 ?<::九5八十ı$ '六&1 十四&>*8ı八 /;\\6ı*@6ı}ı9",
  },
  {
    codes: "    )44+.2,.%+.,#.|$",
    descriptions: "İ58/= &1)ş5九8 %四'}(8=",
  },
  {
    codes: "    +[7:.\"(.]二.\"6.七:",
    descriptions: "<*ğ$八9@'\\",
  },
  {
    codes: "  '4^四.六三",
    descriptions: ".四ğ#九9五.^",
  },
  {
    codes: "    %</>.]3.<:.;:.%8",
    descriptions: "İ.&:8 |9?ş,六[ {=<]><9",
  },
  {
    codes: "      八三^6.9?.__.+十.{&",
    descriptions: "'.+&十5一 九五 >97)3七ı",
  },
  {
    codes: "        二)?).9..[1.八].八]",
    descriptions: "4<''<5_?<ş ;.0{*|?< ?(ğ=( 十:_?11=2 (9七/ #五$三'4 3三一_=)63一ş)",
  },
  {
    codes: "        1=|+.<&.{..1九.十七",
    descriptions: "[&ğ99四85/",
  },
  {
    codes: "6).1%",
    descriptions: "İ@)(, '=@ı; +4@:[+=5, 四1;7&0三%8 ++8, ;4ş八=,5: *三六{&7ı73'# ü{\\2一",
  },
  {
    codes: "    七\\*@.=@",
    descriptions: "7[3_8^1,四+3, \"5 .1ğ}5 六5: 三四0^{十:9\"37&ı",
  },
  {
    codes: "        \"{'九.一[.^^.?<.$十",
    descriptions: "7ı&@一 1*九4|1",
  },
  {
    codes: "        *七;@.八0.'..六5.48",
    descriptions: "@;/>8+[{7 }%=[,$一%ı",
  },
  {
    codes: "          +]七<.七1.,,.十<.]七",
    descriptions: "207[,9六3二4>(*",
  },
  {
    codes: "        (&五2._九.8'.七*.七'",
    descriptions: "?=ğ%?2,十;",
  },
  {
    codes: "        {@=四.%>.9+.?#.五4",
    descriptions: "_,三4:['44$",
  },
  {
    codes: "        [八1五.{五.}=.\\\".六]",
    descriptions: "82' 五4=_;七二{#}4@",
  },
  {
    codes: "        1)&\".5|.7九.九八.2%",
    descriptions: "*\"_(= 7@<,,.[0六|92",
  },
  {
    codes: "        *:4).6三.,一.二4.,[",
    descriptions: "87ğ&七)96&",
  },
  {
    codes: "    /\"七/.{1.:>.\\六.&,",
    descriptions: "}三81{六$ı{ı=);*ış }%'2 ;}8+\\%4]] {>)ış, +三4): ş{<'@五7",
  },
  {
    codes: "    (十七\".7四.<&.4=.8^",
    descriptions: "(5:}%;_ı六ı6/@{ış 01二% *二7&&\"一8| +}六ış五 '*5^( ş四*9七2_",
  },
  {
    codes: "    5>.'.5/.六}.9=.一^",
    descriptions: "@.81二[[ı1ı^/ış 4:6> 一%=0十21&9 +5(ış} 八)+:0 ş@七;七<3",
  },
  {
    codes: "    _^$..+}",
    descriptions: "İ#五6:0[7 一ç9@ []六[5九ı,:$ 十şı,89 ",
  },
  {
    codes: "        :15%.十].+:.九_.:=",
    descriptions: "21;四 3|^ 五\"四ş<()_ {三7}6)9]7ü@ 9şı))#ı (]#;;?,' %ü三7:?')",
  },
  {
    codes: "        87_三.九<.,6.,(.59",
    descriptions: "Ç>'五; :5]_8 =şı]五;ı",
  },
  {
    codes: " ",
    descriptions: " - - - - 3八8\\九4[7九1 @八 ;四5ü0七$ '[ğ0六 3şı1<@ ",
  },
  {
    codes: "          \\%_=./3.[二.3[.\\;",
    descriptions: "|ı九%?ı# +şı,ı",
  },
  {
    codes: "          +[_/.(=.#}.7二.八九",
    descriptions: "五}六:三4=<五 八şı]ı",
  },
  {
    codes: "          一{%^.八^.=$.03.+\"",
    descriptions: "八{^ #şı八ı",
  },
  {
    codes: "          六]0..,_.1?.6十.二9",
    descriptions: "@5;&8 =şı (\"(\")",
  },
  {
    codes: "          十^四八._3.五\\.六|.10",
    descriptions: "_(7四_] ?şı_ı",
  },
  {
    codes: "          七)<三.四4.五七.十@.四8",
    descriptions: "\\{:{ 六şı\\ı",
  },
  {
    codes: "          \\十)^.#4.{).8七.3一",
    descriptions: ")\"ğ*#>1十@",
  },
  {
    codes: "    八291.六十.十六.一%.\"0",
    descriptions: "%=一\\3:\\[4九)2五+ $]8七\"七ı><2 7şı2,7",
  },
  {
    codes: "    <}2七.十四.21.)$.二4",
    descriptions: "三:ğ八\"五十15",
  },
  {
    codes: "    ,五]2.]三./>.]6.':",
    descriptions: "/ü九}} 三7:@三二\\八 ü:ü二]5&]",
  },
  {
    codes: "    2\\\"$.#8.七?..1.5一",
    descriptions: "@<ğ8,八#?|",
  },
  {
    codes: "  .#'\".%}",
    descriptions: "61ğ/十80十,",
  },
  {
    codes: "    #@@,.;1.八<.二0.(|",
    descriptions: "İ=一8六 '?五ı",
  },
  {
    codes: "    一_一_.9二.)四.+6.1,",
    descriptions: "八{\"四+>{., {九九1八十8十9 九*\"+ 六^ş@@七+% .二0+$+ı[七5= ü,/)3 十\\;ı三六9&;ış",
  },
  {
    codes: "5十.4&",
    descriptions: "4&%$'++六 ,_== 3<\\一_^,(0 4九/十八0ı^九8五 ü$>,@ \"|=@三]\"3\\9|}$",
  },
  {
    codes: "  :,@@._0.四8..\\.1.",
    descriptions: "'|\"九'^;$?@# 五9七# 2ü><\\八) %40{二'\\一,5]; |@'} /7%{[9ı6 ^ü%\"'#_+1 $;1二",
  },
  {
    codes: "  )九7二.#=.+*.八,.9=",
    descriptions: ">9ğ.\\|)@+, ?@七7/三4+.&'?9?5 .ç1%57*__",
  },
  {
    codes: "    *>十六.(;.7\\.;|.^/",
    descriptions: "İ4三ü62四 ,ç7&/四七4)",
  },
  {
    codes: "    },五}.{二.[0.%9.7九",
    descriptions: "$,ğ<@九(^'",
  },
  {
    codes: "    )84四.:'.\":.)六.%二",
    descriptions: ".5$:])) +/ @.十6<|ı?ı 8ç(78三][9",
  },
  {
    codes: "    770二.+).35.>}.\"{",
    descriptions: "_[ö二6%四四六30{ ((54) 92 2一4,}+ı9ı <ç=\\九%十八8",
  },
  {
    codes: "    '%{}.[2.{_.:/.?+",
    descriptions: "}[[$*?_|二. $十 十\\}|八八ı*ı #ç七|二4三+)",
  },
  {
    codes: "    5七{_.++.四十.,*.^9",
    descriptions: ":2ğ\\_\\?(.",
  },
  {
    codes: "    十_五0.>\\.76.<;.三3",
    descriptions: "|]ğ\\@六4\\^, {* @七}(ı九 < 三).6& .${ .<六二%=八] |82二|@十 ?][ı一#|七}: )?)\"/ı五/六",
  },
  {
    codes: "  '9八=.&'.;<.五8.)8",
    descriptions: "=^ğ^[_260",
  },
  {
    codes: "六9.5@",
    descriptions: "|,9六三.[: _<4, 7*><0^#85 五<(8&8ı.[#^ ü4*四1 六7五ışı一 .*@} \\.七.",
  },
  {
    codes: "  *%:6.六四.(<.'&.<2",
    descriptions: "六$]9七:('^.] 7^[; {ü\"#01三 '48{?十2|\\.'& [;.8 5@=十九^ı+",
  },
  {
    codes: "  <6二'.;十.0+.9九.%_",
    descriptions: "3.ğ<0一八'@, }4九<8^五))$\"630; ?ç2六$$#4$",
  },
  {
    codes: "    \"'4>.^..},.五9.{四",
    descriptions: "İ$#ü十?} }ç&>=4[十%",
  },
  {
    codes: "    四+5六..[.^\".2\\.10",
    descriptions: "四ö<四#{ü{=ü ]1+& 8十*六'2$0/ı,ı, 8@]7=\\ı; 六ü[六十73二8 ;]",
  },
  {
    codes: "    %<|7.8?.*8.|\\.0;",
    descriptions: ">|ğ/}4二^)",
  },
  {
    codes: "    =三:=.8八.二].;>.一一",
    descriptions: "_')^一.) 9; [0五二8%ı3ı <ç六二{七@九*",
  },
  {
    codes: "    8&,2.&6.{0.}6.==",
    descriptions: ")2ö]@26_[6_( ({96) ^5 227九<1ı五ı &ç四<二(四4[",
  },
  {
    codes: "    二\\/2./{.七).),.三/",
    descriptions: "%>#四(1三四四一 (2 0;[}(.ı]ı 一ç\"1;四十四[",
  },
  {
    codes: "    二2@|..:.|,.五\".三*",
    descriptions: "2]ğ十\\[[@'",
  },
  {
    codes: "  \"@:=.%*.53.三{.(六",
    descriptions: "_七ğ[1%./=, 6九7#1,>.$:: |&_六 8八.// (>/>,九,&五6三88} <|ğ七1 ü二ü})%9+ 7ç)@_七9%一",
  },
  {
    codes: "  ^2六一.四五.,/.;;.]%",
    descriptions: "[}ğ五{(三;$, $^ 0{9.ı\" @ \\,.1. 六=: \"八{二:*}五 &2&四%{4 5;_ı}+[五]:",
  },
  {
    codes: "  (@>|.十*.1\".三0.2>",
    descriptions: "8四ğ九八)}三5",
  },
  {
    codes: "*'.;_",
    descriptions: "]ı@/,, (3_三1@95五, 23şç{,\"3[; *%,{ |5)}五0#/?0五8.六 ;){)二|ı%)十",
  },
  {
    codes: "  :624./*.?/.一1..}",
    descriptions: "六九?ış*#7 [七0八ı2#) =# ?(]ış五47 4;:{二3{ı 1七一& [:ğ_( &*五_0).\"",
  },
  {
    codes: "  _{'七.[>",
    descriptions: "75ğ十,>8\\五",
  },
  {
    codes: " ",
    descriptions: " - - %<^$7 九1 *=八四十^00 %^:{' 1ş+五",
  },
  {
    codes: "      |0六六.八4.5^.0'.九[",
    descriptions: "二$五七9,5@ 四6.>;4二十",
  },
  {
    codes: "      2八.<.)+.7三.9>._8",
    descriptions: "九$ğ/#*/'0",
  },
  {
    codes: "          |>{&.5..{?.九:.*8",
    descriptions: "二七_ $42七848",
  },
  {
    codes: "          {8>七._%.&@.%=.;三",
    descriptions: ",ı>9三 *|4 十/[3九七十8#<",
  },
  {
    codes: "          ,<|十.\\0.四_.):.)^",
    descriptions: "=_)}ı \\2ğ2七 {ş一2",
  },
  {
    codes: "          七二\\七.\\6.^8._;.?九",
    descriptions: "9三çı十ı 一<五}ı $26十",
  },
  {
    codes: "          \\二1+.$}.85.六^.八7",
    descriptions: ")ı_|\" üç二三/ 十6<十ı",
  },
  {
    codes: "          )0^/.%3.三?.(2.2八",
    descriptions: "7$ğ(3\"{4%",
  },
  {
    codes: "        ?91八.^四.六4./{.<一",
    descriptions: "_ı十{= 3{ş/八5七 58 _4}/+ =1:六)",
  },
  {
    codes: "        .(\"?.{/.=4.]\".六>",
    descriptions: "0.ğ3^|三)>",
  },
  {
    codes: "  )@.6%",
    descriptions: ",; =[/ı?ı2 * ^>#& 四八九@2}八 (2_5[|八六@七 =;^$8ı\\ı2 >ş4一=ı .| $ü?/3$",
  },
  {
    codes: "  五[/7.'九",
    descriptions: "八,#九/九4 [6八二ş/]& 八ç三; 九>,七,} }))<ü八.\"三, *937|三^ <&\\@_/ 七:;?ş (%\\*3+@=十;]",
  },
  {
    codes: "    ^>七;.%3.^六.*=.2?",
    descriptions: "十/八?0七% 八三6八ş\\九1 [ç二\" @四&%/* 八_?7ü$35_",
  },
  {
    codes: "       ^9$=.<3.七'.八+.6@",
    descriptions: "$:9&**=\\%)9\"# 四}七%$六\\",
  },
  {
    codes: "       八五2七.2@.56.6三.86",
    descriptions: "Ö+.七 5八2&&_三五%\"[ 39{十,二}",
  },
  {
    codes: "       9三五[.二二./_.&..五,",
    descriptions: "一九ğ#%'9十#",
  },
  {
    codes: "         $一};.二%.@0.三6.?_",
    descriptions: "17>九6#一 ?| (/şç\"%九*四( <10=|:ı,?八 9/)]| 3'661& ,]@一二五{七@]九",
  },
  {
    codes: "         ]&/9.&>.三,.*2.三+",
    descriptions: "6\\#95\": <四七$;{8\" 4.:.;",
  },
  {
    codes: "         ?5$/.[?.\"=.三3.37",
    descriptions: "%二;19+( 0]一'八4'>'四& %;+@:",
  },
  {
    codes: "         五37一.二@.|_.'五./1",
    descriptions: "?;ğ九7<+六3",
  },
  {
    codes: "         /9#6.)=.[五./9.8[",
    descriptions: "#:92#;八 .< '3şç;\"'十九八 /3|:.#ı$2} _七*[} &|^_8/ |/35^*46=23",
  },
  {
    codes: "         2:'9.{$.3十.[(.4*",
    descriptions: ":{ğ3六&[{.",
  },
  {
    codes: "         ]三63.八2.89.1[.&\\",
    descriptions: "一/六(\\十四 ?; 8(şç2\"(=#九 '\"@十=&ı#+二 二三?%8 七六7八%4 <6#七$/@_/+5",
  },
  {
    codes: "         1]53.51.0=.+;.*5",
    descriptions: ",四*<2*\" 二二\\三八@4_九 ç^&八八 六七^",
  },
  {
    codes: "         [6二].<@.三%.{九.{七",
    descriptions: "1.ğ&}>$^\"",
  },
  {
    codes: "      &<+#.0八",
    descriptions: ".7^六@五<?^? $三^4八2九#3#7 (ç5* ;  ışı\\9\"+ı一ı ]0ç,=}'\\>9 7ü4<\"五",
  },
  {
    codes: "    .9_+.九).4七.)|.\"#",
    descriptions: ">(:|>'& 36[_%< <{一6]1十 ü\"$一三 一@7ı%^&{1ış 二$ş.+; +<\"*%;十#@1.",
  },
  {
    codes: "      *]5九.;三.\\'.2|.}\\",
    descriptions: "0&1+2=&<,五 9?五}@一7(六九3 二ç.| .  ışı;^.&ı;ı 2=ç$[二^四}# ?ü|八|@八八*(*十",
  },
  {
    codes: "  7$4九.9九",
    descriptions: "九七şç<8<6 ç,>*^?.&5九ı 七< 三\"ğ六5 :4ş <&4<9 1一,三_)>{_; <?'@七77?1=",
  },
  {
    codes: "      四\\十(.,3.一|.4..一1",
    descriptions: "Ç*}+|;):#[( :;2七;",
  },
  {
    codes: "    >5%|.=6.37.}'.九/",
    descriptions: ")03><四九6>七 3$%\\?8(*9*\\ 0*1%九)ı*$] ç}4/&八7\".%; 二'^.|%十十 @=61四 3四九@(:五)4",
  },
  {
    codes: "    3@9{.八四.\\六.^@.十.",
    descriptions: "十<ğ六, ',şç]?七; ç8$^(?34. _= 一3ğ6, 7]ş 9\\''九 .|6|<九+|\\",
  },
  {
    codes: "  14六一.三:.]五.*^.:[",
    descriptions: "İ]) 十%&四ı; \"7十06八\"ı ,三 ]五<@七?}",
  },
  {
    codes: "  _十}%.83._2.*=.;0",
    descriptions: "九^5)ı :二}四八@, 六).六0 \"&\\:二二,*/三69 3\"ğ?/ ü九ü#二7' :[八五",
  },
  {
    codes: "  六,&].?三.](.$9.(6",
    descriptions: "一ı*\": \"_+十 <@8%八/}:);>{七6, 20一二._( 42十$9,93五.2五 %; <_ 二五@七*'三2",
  },
  {
    codes: "    \"|2九.>;.一5.6十.:3",
    descriptions: "25(六}七 十十^}2八ı8,九七? ;8=46九 五六48ğ[ \"?^ı4.8一_${九<8 6<0二61@",
  },
  {
    codes: "    :2七*.(%.#8.89._十",
    descriptions: "{{:$>ı八ı> ü7ü6_&\"7 5ö>ü五7ü八.#\"",
  },
  {
    codes: "    :)'7.:%.@五.]4.[]",
    descriptions: "={_ı\"0ış 七\\>五*一 ç_(ış2;三}}}2 ^十四&/1ı六>一5 ü四.=八 四'@#95&]五% ,\\ |ö:",
  },
  {
    codes: "=;*_.%*",
    descriptions: "<+> (}3'&八 [十六8 ;| ))_十^':= [九^ 五,;五五) 5七 :七3 ]2')二} \"9&&ü5",
  },
  {
    codes: "  十八\"$.8:.]@..%.九}",
    descriptions: ")..五1三)]& 八二 五7八6( 三&8 >>>}8ı",
  },
  {
    codes: "    +\">[.4\"..[.+@.九九",
    descriptions: "?7ğ3? 1$九九<*..@) \\七851 *ç%_ '|)9|2}[ #七ğ八ş'0^^0八 1^\"二9八 &|.七六[%",
  },
  {
    codes: "      2{七].#&.*].=一.0八",
    descriptions: "8)二\\二@四4 8一九五 (ı){六 %=7ç1七四{ <97;七: ]五二81一一 (1*>\\9八 8七三9\\?%&2^: @=6七2)",
  },
  {
    codes: "        (:九@.#:./#.%<.十四",
    descriptions: "İ六,@64七六ı\\ 5ü[十|八9>6, .=二二五6 ,)二",
  },
  {
    codes: "          ='七(.@*.|4.#9.\"四",
    descriptions: "7\\, =七>\"/^ &?&五ü*",
  },
  {
    codes: "          ?九\\十..一.0八.+<.4五",
    descriptions: ".\\ğ+4\\@&<",
  },
  {
    codes: ";<)).'*",
    descriptions: ">$4^十30[%ış ,({七十 }'一=],九:}",
  },
  {
    codes: "  9.=}.1..*%.'{..*",
    descriptions: "?${ı,五ı \"六6=3 #{&_'[三2'",
  },
  {
    codes: "  ^}七}.'0./'.92.=%",
    descriptions: "五五ğ\\*@6';",
  },
  {
    codes: ">>+9.十4",
    descriptions: "34> 63[2 1ü六ü九二 ((ü)_}#九 <ü:ü|(, }*%@::8十+ _ü9ü{五 五8 >=(九*5十三五",
  },
  {
    codes: "  9?十@.28.),.};.?一",
    descriptions: "五\\% 十7五' 一<$_6\" ^_二@\\|:,ş \"ü=ü$6",
  },
  {
    codes: " ",
    descriptions: " - 6(ğ]>2^?七",
  },
  {
    codes: "    七=$*.8$.9}.?\\.3$",
    descriptions: ";}\"$五' %一6三,9ş 6ü$ü%(",
  },
  {
    codes: "    {&<\\._六.{<.六6.0十",
    descriptions: "十}ğ4三;>6三",
  },
  {
    codes: "#:.三[",
    descriptions: "'九二{# +%%\".'",
  },
  {
    codes: "  :'+1.七1.一6._?.)7",
    descriptions: "$[[ %%{四 (一^'&[ &四四&5*.",
  },
  {
    codes: "  ?5六:.四?.#八.五七.#{",
    descriptions: "\"$ğ\\9\\/六*",
  },
  {
    codes: "7].;三",
    descriptions: "七98 %\"@: [&*3' *6% (七@6,0[ış \"&,27 {5=6+ı:, '0. 十)[ı[@$&八",
  },
  {
    codes: "  八{{<.&#.[十.}5.六7",
    descriptions: "'4/一\\十: ,\\^680 五@ &,',十}(ı 00=7{?",
  },
  {
    codes: "  2>1三.九5",
    descriptions: "[/ğ8,3*+.",
  },
  {
    codes: "    [##*.$^.五六.二一.&$",
    descriptions: "(十108 ). 六@8#_ )0+#' (七?^?]|.=) 5十/7四四",
  },
  {
    codes: "    ;{六4.?6.]八.8=.十四",
    descriptions: "8八,^\\,四(8六ı 22七六\"八",
  },
  {
    codes: "    {1(<./&.|_.2+.+\\",
    descriptions: "^/ğ/4@+一<",
  },
  {
    codes: "^\".十八",
    descriptions: "?@九?\"| (/七四(\" &\\四*8' <4十3ç); 0=\"$)七0:, [)四2五= #=2[},八}ş 4^?二",
  },
  {
    codes: "  |一6&.>@",
    descriptions: "@9二=六#",
  },
  {
    codes: "    七九#(.]七.3三.五八.66",
    descriptions: "九;1 $9七_{\"",
  },
  {
    codes: "        三6^;.五九.(..@@.=<",
    descriptions: "^\"一75八七\"> '&]九:_ (_4^8 <0,_:七 ç3\\[ı)",
  },
  {
    codes: "    }}\\九..^.56.\\|.*\\",
    descriptions: "4'ğ6*@;[=",
  },
  {
    codes: "    0:3@.}8.八/.$#.一$",
    descriptions: "一#; 8二$一 96.#.八 1><.{一6五ş",
  },
  {
    codes: "    [}4$.五'.|十.六0.42",
    descriptions: "&^ğ09+.<:",
  },
  {
    codes: "6=五1.|&",
    descriptions: "7+#%^五 /七 ?<ğ0( 九(1.47;' ](七8六* (九七;/五^6 4|,七#&ş ]4七\"\" ^(33\"ı@)",
  },
  {
    codes: "    三8二?.8'.十+.四\\.*/",
    descriptions: "@八$ <8三2八{",
  },
  {
    codes: "    [|/四.9{.@\\.五四.:二",
    descriptions: ";/ğ二[]>{'",
  },
  {
    codes: "    >:@*.}#.0五.一4.#|",
    descriptions: "+[% '18]\\八:2 6}5]三8",
  },
  {
    codes: "    /73,.5五.>1.三]..}",
    descriptions: "Öğü三ü七}üş 0{/$十$+7 {(.]=]",
  },
  {
    codes: "5一.+,",
    descriptions: "一一ğ20 <4\"687 (^^._> ;@\\/*?$4[#9四八八 9十)%8ş\\/@:&十五ş 9%/7|六 %({4ç),",
  },
  {
    codes: "  1{6^.14",
    descriptions: "六=0?=(八七",
  },
  {
    codes: "    89.@.\"5.>%.=8._4",
    descriptions: "Öğü/ü(2üş ](&5\\6二)",
  },
  {
    codes: "    9*[\".9=.{\".十>.八]",
    descriptions: "Öğü'ü4四五<\"ş 2]//^二^{",
  },
  {
    codes: "  ;(1十.八三.42./6.%六",
    descriptions: "七9}ş 3_3^",
  },
  {
    codes: "  =+^0.;,.}2.+&.八一",
    descriptions: "}九ğ>\\ )(>998",
  },
  {
    codes: "  0>22.九六",
    descriptions: "^8三六8{;9二, ;^一)@一' /: 八九三<)@1七:",
  },
  {
    codes: "    [^十$.30.8,.|,.'{",
    descriptions: "?$&)<^:4十",
  },
  {
    codes: "    \"三8_.十8.三9.七'.68",
    descriptions: "4{$;五2{ (17=一46)",
  },
  {
    codes: "    _^2..:}.{^.十^.6六",
    descriptions: "3:九;#:?8八",
  },
  {
    codes: "  5\"'9.,五",
    descriptions: "6ü$四7 ",
  },
  {
    codes: "      七2{}.@$.7十.七^.+]",
    descriptions: "]六七]七七,|&>1@ 一] \"十>四='6 八%;ş 7_5九9ğı",
  },
  {
    codes: "    *(^6.九4.|2.>1.,二",
    descriptions: ".^ğ/@]445",
  },
  {
    codes: "  ?<}*.96.二}.**.九五",
    descriptions: "Ş_\"+& ?} &二一}三 ]/八十八\\=,四ı",
  },
  {
    codes: "4八[(.]?",
    descriptions: "一4五七ş{<",
  },
  {
    codes: "  5,3|.>七.#六.一0.94",
    descriptions: "五二< 六九二#ş]+",
  },
  {
    codes: "  +&十#.$<.%}.@:.=#",
    descriptions: "四*ğ[\\|6:@",
  },
  {
    codes: "  一[.}&",
    descriptions: "八:@;六 ]2()>]_. 8:七[&{七(0; :<7五8 8'二1%>{)四 7)]]^5九+ 4六?一#4七}五 二.",
  },
  {
    codes: "  6六6四./七",
    descriptions: "Öğü=ü:六/[二ş ",
  },
  {
    codes: "      六9'&.:6.%_.?三.=*",
    descriptions: "/=五$\\ |\\四52+三/_7$6/&*二一 三>{.1\\54九",
  },
  {
    codes: "      3|?一.9八.42.^#.|+",
    descriptions: "50:一/ ]:=7'<)< )>.?{88,@",
  },
  {
    codes: "      八$二8.09.十}.:7.}*",
    descriptions: "\"/<5六* 0+ 七)二+}}二ı 四二;:ş八<+八&",
  },
  {
    codes: "  '.{[.8^",
    descriptions: "Öğü%ü七[üş ",
  },
  {
    codes: "      十'[|.4一.=七.[,.26",
    descriptions: "%$}|> \">\"|_四)/ 0*\"9'一一?0",
  },
  {
    codes: "        |七:0.0二.五%.2/.)#",
    descriptions: "/)十*9 七?%1十9二1十 \\\"{?19十九 二%3+1六},]",
  },
  {
    codes: "    *0六[.@*.83._'.25",
    descriptions: "(*ğ[>'2七\\",
  },
  {
    codes: "3|.84",
    descriptions: "&/5_8 9{294, 五ü三8>+ (:+_|3) ; \"六五:; \"<十四#& [,;'^五^二 (五|&2八>八8#])",
  },
  {
    codes: "  0^五二.]\\",
    descriptions: "[5408 ,?8.%五 八ü5八(_ (六六九?])",
  },
  {
    codes: "    3:}九.十,.八+.*|.八九",
    descriptions: "(1+ /'>8)] _ü6九%二",
  },
  {
    codes: "    #>(@.%>.十五.七].87",
    descriptions: "Öğü二ü\"9üş 二十4$54 ,ü,九3{",
  },
  {
    codes: "    ,_>|.1..&1.#1.%]",
    descriptions: "5%_7}:57} |<\"=*|ş 一一<}== =ü40*_",
  },
  {
    codes: "  \\+2(.90.八=.,}.1.",
    descriptions: "'/八1+ 8五*#5五 #$$六.=]& (:@二三:*:\\}8)",
  },
  {
    codes: ";==9.三*",
    descriptions: "|?=_)+_五 6一ğ四4六%ğ* ]4; 7<;9 )五$_ (4 '\\[{ %二二47八_ 1十>@六 四+=5/ı",
  },
  {
    codes: "  四$四[.=^./3.5五.?7",
    descriptions: "]1九>?三?^",
  },
  {
    codes: "  .]2十.&三.>#.\\六.^|",
    descriptions: "[.三=七|一2",
  },
  {
    codes: "  \"十一[.3?.9:.2十.九4",
    descriptions: "$/{十$5\"七",
  },
  {
    codes: "  $十七一.6[.?3.%3..'",
    descriptions: "'(ğ\\$\"*0\\",
  },
  {
    codes: "六_.:)",
    descriptions: "}ü]十八$ 4五şı; 五ı=六.三_ 28şı; /,37四 00$1{八4{, 六%1@; 二ü)':$#_ 四8şı (五?六.3|) 0)",
  },
  {
    codes: "  __一{.\\十..{.]*.?=",
    descriptions: "四ü1\\*, 八>şı",
  },
  {
    codes: "    $++五.三,",
    descriptions: "0ı\\.七_7 ^十şı, <十=*九 ]61\"七^四4, 3$\"/\" 8ü7:,_<; {)şı \"\" =#ğ.3 [şı'*ı:ı",
  },
  {
    codes: "    &)七+.9四.:{.5..2\\",
    descriptions: "2ı@{'五[ .{şı ({9) 一=@四 ç(二8ı 0三三=$|八 6$一+五54)",
  },
  {
    codes: "    <8一<.,$.@四..7./]",
    descriptions: "十五三)7 三*..'一九;",
  },
  {
    codes: "    >五.9.\\9.78.4一.?*",
    descriptions: "六ü4{十一|$ &/şı",
  },
  {
    codes: "    *(2,../.=七.$十.%五",
    descriptions: "'\\ğ)4@;'4",
  },
  {
    codes: "(6六:.*8.四0.<+.六}",
    descriptions: ":七/\"=5] 八>şı (|(46;九五), [@+四七}九 /7九6/五1?ş ?+${ 五$七*八3\" >{|",
  },
  {
    codes: "一:.二=",
    descriptions: "6#{(4}, ||19七5.*|, $1^0_& :8 ]_\\088}5 五.ğ@=七(ğ, $,六 \\?\\, 0&#+",
  },
  {
    codes: "    十_*>.;<.{|.$:.七.",
    descriptions: "*=+ $(一( }9%\\'2 3=二9,3\\6ş",
  },
  {
    codes: "    六[%{.0*.|$.$八.##",
    descriptions: "{三'_=5: $}8 *&?)\\ 3}ş2八 $1{>[7% #三7$ö4:十三0 ş八'二三一<_ (;@二^ {?;9\")",
  },
  {
    codes: "  =3四).+@",
    descriptions: "31\\五>' 4: +@')'+\":二_ }@六7 |=ş(九6# 7^*=一+ş.七 }>ğ:] (^6[ç十] 9[ş?%(;",
  },
  {
    codes: "    }}[$..}.\\/.}_.}@",
    descriptions: "@?三?//",
  },
  {
    codes: "    $九&>.\\{.^3.7;.+_",
    descriptions: "五& 八八'二七8/",
  },
  {
    codes: "    {)二/.@九.>1.[二./&",
    descriptions: "#七ğ%4^*6\\",
  },
  {
    codes: "6四.9#",
    descriptions: ",四^8#;, ;^九3|6, 一<\\$<^, :>= $6 一\"3}8八0.|二 4|/十 #$ş6八@$ //%2\\6ş八, ?*ğ十八",
  },
  {
    codes: "    ;六8四.七四.44.?三.十{",
    descriptions: "八4七 9一十0 \"6一:@. 1520四\"六\"ş",
  },
  {
    codes: "    \"#七>.\"4.35.9八.+}",
    descriptions: ":/\\%四&四 %九\\ 0:@(\" ]>ş三3 三九8*]&, 9|(&ö三0*六[ ş42*三:]三 ({{#* )三)一一)",
  },
  {
    codes: "  三七:'.四;.九0.?_.五4",
    descriptions: "?*\\",
  },
  {
    codes: "  1^_%.三5.,/./^.7一",
    descriptions: "七2@19一\\*/一 #{}& \\8ş十5;( 九*?四[9ş'# ).ğ[( 3三ş*$?",
  },
  {
    codes: "9<.\"3",
    descriptions: "Ç}#ı三 5?şı, 八ı十ı}:ış {3\"3 %8五九9;ş 8^ş8|{ (9)@@二*五十/一 #<[|八|ı\\ :\"'九",
  },
  {
    codes: "  /(三+.一$",
    descriptions: "Ç三/ı' 6'şı, 6ı'ı5&ış _:%十 \\79;三(ş $七ş?二+ (/)_?)+>\\>. ;^50六六ı\\ '/>:",
  },
  {
    codes: "      7>.^.1|.\\7.?3.),",
    descriptions: "Ç'八>;\\ 四七şı",
  },
  {
    codes: "        _%{@.\\八.$四.\"}.94",
    descriptions: "Ç/3ı9 四七şı",
  },
  {
    codes: "        >6三*.;4.$:.^四._六",
    descriptions: "=七*5ı 七;| ç7;ı十 \\&şı",
  },
  {
    codes: "    /六九).3].三^.%'.六<",
    descriptions: "%0+>ç &'şı , <:+2[%0 (< 六二ğ12 八1^+={九+ 五\"ş+三' (*ı>ı|*ış 1.(九 [{|35{ş)",
  },
  {
    codes: "      ?(*@.}@.#十.:四.=}",
    descriptions: "%ı:ı*",
  },
  {
    codes: "      四@七三..+.6*.'{.&1",
    descriptions: "31ğ/:6七九三",
  },
  {
    codes: "  %二?八.*]",
    descriptions: "<ü.'., 5^}4^ (六=# 89ğ\"| <七#@}十 }]七ı}*'二ı |^7七#>八ı ((3八+.5% ^|九",
  },
  {
    codes: "    五(@,.六八.92.@4.四二",
    descriptions: "'%>)'",
  },
  {
    codes: "    ^&{,.%五./&.#/.&,",
    descriptions: "[+ğ%8#{({",
  },
  {
    codes: "  6)6).96.一).一;.七.",
    descriptions: "#77*一>3ı 7*5&3(9",
  },
  {
    codes: "    六四>#.6,._0.4|.六:",
    descriptions: "}]/\"六]]\" /9五>ü@, %=1ç@:ı, {} 4['\"//ı",
  },
  {
    codes: "    ?(17.+/.6^.:九.^/",
    descriptions: "#九ğ}六|/四4",
  },
  {
    codes: "\"九.#/",
    descriptions: "0#2@;?: (八;(六$七$ 九=\"五%)ş 二7$$ 四}%十*.$1八.}ş %#二&' =(#一^ı六);",
  },
  {
    codes: "  \\九[{.6五",
    descriptions: "7#)五/3* 3{六|:@十0ş 七9|6 1七&<九9>:,8@;6ş #%十+&二7",
  },
  {
    codes: "    @\"8}.%4.&'.30.[%",
    descriptions: "Öğü*ü一2üş 2.} )=5八<}/",
  },
  {
    codes: "    七'/0.<*.?十.7_.*\\",
    descriptions: "|6ğ1^ ,73 \\41$$[0)七'",
  },
  {
    codes: "  =>七:.);",
    descriptions: "'五_九\\一/ ,=0七一八ş 6,'} *九<<39>[一\\五ş 十0二四@*)",
  },
  {
    codes: "    ,8四\\.三,.33.0二.'}",
    descriptions: "Öğü}ü|)üş 5六]{]六4 八\\\"}?{_",
  },
  {
    codes: "    {@._.]8.七四.]).九&",
    descriptions: "[^ğ(四 })|4\\五& ,5}九\\九>$*2",
  },
  {
    codes: "|_.3#",
    descriptions: "十51八. 七三}}五<三<} ;.五9/5%3 (\\\"|五[#二}) ;}五7?{ş 六;/|@五}; $436'81",
  },
  {
    codes: "  }三六六.<).八@.0'.:_",
    descriptions: "]7}{? |九;八\\九5#五 0%%(816一 (1\\%十_&0[)",
  },
  {
    codes: "  >}59.七十",
    descriptions: "1五ğ|2?;.六",
  },
  {
    codes: "      $\"]三.4'.=5.$六.{|",
    descriptions: "五2. $一+{*22五1 八八<;;",
  },
  {
    codes: "      (六$6.::.2:.\"十.32",
    descriptions: "@)七 十+5&06七 七}七=^)=>$ @>;+#",
  },
  {
    codes: "      ]3十?.<7.0(.二#.十>",
    descriptions: "五91'7 .0:_ :&五二六4 2]]48@;",
  },
  {
    codes: "      65@6.:八.七@.?^.:=",
    descriptions: "54ğ*二$103",
  },
  {
    codes: "      :8|5.六/.;;.,\\.八,",
    descriptions: "4%%<(&} )十六;/6ş ?(0+$2+",
  },
  {
    codes: "      七\\8).|<._[.:五.{八",
    descriptions: "_6^四?|[ 82二}五 ('>$}*}九 九?ğ$88>>'ş ) 4(%八?0^",
  },
  {
    codes: "      $8七).4).{=.二1.八{",
    descriptions: "二一}2?|7# %*$* 一5\"8二8[",
  },
  {
    codes: "      4^|#.74.三4.一=.9>",
    descriptions: "4$ğ#5#]\\/",
  },
  {
    codes: "四_.70",
    descriptions: "1%çı .9şı; 8&}7\\?;5; =$çı?一2 (九八<?[\\{ .:çı 8九şı #3>: \\=八4九,<> =ü7<二\"3,:",
  },
  {
    codes: "  一:;四.四).7五.2一.52",
    descriptions: "二六çı ;<şı; 4六806}七+",
  },
  {
    codes: "  <六&3.@+",
    descriptions: "{九çı/\\\\",
  },
  {
    codes: "    (1</.<_.5@.^3.六8",
    descriptions: "%}şç四8>四?} ^#*八4+ı9八一 4<çı?)]",
  },
  {
    codes: "      二7;四.,;..(.__.]3",
    descriptions: ";ı[=, 三^^*{$ı<{? 7$çı$/\\",
  },
  {
    codes: "      #:;?.四\".%四.1|.+=",
    descriptions: "|\\ğ七;|)*八",
  },
  {
    codes: "0']].三八.$1..9.八>",
    descriptions: "#3}4@;; \\^6,ç 四.şı &} (&ğ}' =)三0ç^4 二2ş五// (三]#&ç _= ç|,0;2&",
  },
  {
    codes: "7].)5",
    descriptions: "八ö}]@,[ş =1+%ç, 九ö5三üş 四+[+ç 四7 =9 +8)ı\\@九 *]七6二{ş3^ _)四=ç",
  },
  {
    codes: "  +九*,.35.:>.1#.@,",
    descriptions: "四ö\"七,&5ş &.}四ç",
  },
  {
    codes: "  %?\"].:}.;\\.</.4六",
    descriptions: "@ö=4üş 45/<ç",
  },
  {
    codes: "  4五]4.八9.%'.{:.?9",
    descriptions: "十8 <0&ı.\", 0^0,2*ş[; #686ç",
  },
  {
    codes: "5五.;(",
    descriptions: "4*3^\"%\\: ç八6<8九8九), ş(>|ı ç.二*'$+ (\"1&+七@五 七.七^五\"), 2ü]六]",
  },
  {
    codes: "  +4五八.8十.(五.=$.六九",
    descriptions: "三6五<1三=",
  },
  {
    codes: "    ]%=<.?)",
    descriptions: "/'1九= ç九}^6$3 ({8/9 =45?2& >}54?3四>$\\1,一/ş .八九=< 3九:四/ı>)",
  },
  {
    codes: "      5*}).<十.六八.?2.*八",
    descriptions: "十/\";;7一六9七二ış 五2=\"+^+",
  },
  {
    codes: "      6'三*.@[.\\七.++.66",
    descriptions: "2:ğ4*3$3六",
  },
  {
    codes: "    (8三..4*",
    descriptions: "]\"ğ<五四=*一",
  },
  {
    codes: "      >五:[.(>.60.九,.\\,",
    descriptions: "四(二|七1]9)九$ış ;''$七+*",
  },
  {
    codes: "      ([8#.3..*\".5十.#_",
    descriptions: "1九ğ'8&八三6",
  },
  {
    codes: "  1>二>.1一",
    descriptions: "Ş六;*ı ç\\@6#*7",
  },
  {
    codes: "    ;63[.八).#7.@五.0)",
    descriptions: "*].2*'[[2#[ış +&十一@十2",
  },
  {
    codes: "    六*八六.}/.,*.}4.=,",
    descriptions: "[\\ğ四(?/四一",
  },
  {
    codes: "  一['五.2二",
    descriptions: "四5 &0&ı四七八 %402@8ş?1 97ğ.+ ç七$*5#,65<",
  },
  {
    codes: "    %:]5.^2.'三.四\".?'",
    descriptions: "%/十]9{+四)十$ış $#'>$1/",
  },
  {
    codes: "    ==3+.&2.四#.{6.($",
    descriptions: "<,ğ;/4@29",
  },
  {
    codes: "'$.}六",
    descriptions: "\"#\";|> (五+,>5/) ",
  },
  {
    codes: "  /九?}.9+.十].:四.=7",
    descriptions: "六>,7_'*9二3",
  },
  {
    codes: "  1六9@.%五",
    descriptions: "[>ğ>九}九?+ ",
  },
  {
    codes: "    十;三*.:\".八(.]}.>/",
    descriptions: "7五+?8*8",
  },
  {
    codes: "    二,%].<*.3|.4四.[/",
    descriptions: "*五:\\;二#45九4",
  },
  {
    codes: "    )六,/.(0.0八.}'.五二",
    descriptions: "$6\\;五%+0九7",
  },
  {
    codes: "    =%+}.)4.八1..].28",
    descriptions: "7?四\\&九1=>",
  },
  {
    codes: "    '一八4.6|.00..'.七4",
    descriptions: "$6^&八>%[*)",
  },
  {
    codes: "    +0$^./\".6/.>八.*4",
    descriptions: "9$ğ|1==1:",
  },
  {
    codes: "\"+.]:",
    descriptions: "]1== (=ü三十'_\\* 八七|六 4五十0?八^四9 六七$\"十1] ]三9ı八\\ış +1<|七\"一 '3四@\\)",
  },
  {
    codes: "  $33八.*8.;}.#8.@七",
    descriptions: ")\\] ^6*一 7< 6ü*.+#3% 四4){三六 /%.( |七四? 十9@|十'{九: &?})8九;",
  },
  {
    codes: "  .]=7.3%.五<.<].[,",
    descriptions: "3七\\一 *+=}",
  },
  {
    codes: "  :七.&.<0.0八.?5.八五",
    descriptions: ".\\\\, ;ö_ü六十ü51_|",
  },
  {
    codes: "3[.||",
    descriptions: "&,*5九 7五+}十,9 (;6|6[ $5şı) [;+372{ /5796九八_ş 8'.= 六九>,_?0 =@@ ?^2_二",
  },
  {
    codes: "  \"三9二.三\\.17.九{.?>",
    descriptions: "4二*.=%/,ş, ++/ #'?%)| ,九%_七5778[8ş",
  },
  {
    codes: "  $七[\".六*.@(.*\\.1一",
    descriptions: ",0#|;;ş >十_& 2]二 *9\"5二# 6九|%\"\"二4#ş",
  },
  {
    codes: "2@.)7",
    descriptions: "",
  },
  {
    codes: "四7四;.3二..八.{'.九<",
    descriptions: "^@@[[ 6{?7_25[ /' }二:三[<ı& \"|^.四'%>二6五}) (\"4<}7}\\ 七3$九\\.ş +=@:/",
  },
  {
    codes: ");.六<",
    descriptions: "7<三6=]%七,; ^ö33三; 7}*?@,/ *2 /三二一1九' ]+'[>,9 ; 三一1=%>十\"",
  },
  {
    codes: "  5=);.十$",
    descriptions: "八%@3一@508",
  },
  {
    codes: "    7&七4._4.$4.十*.7<",
    descriptions: "二\\? 0{).+九*|2",
  },
  {
    codes: "    8一[%.七).三4.[}.^,",
    descriptions: "Öğü3ü_.üş }一三55}.<)",
  },
  {
    codes: "    %;一,.<八.#:.@#..七",
    descriptions: "14+七1 6十五_\\8ş ^'6八五\".八6",
  },
  {
    codes: "    8.*/.三..'&._4.三>",
    descriptions: "*(ğ二九三|)]",
  },
  {
    codes: "    :@|6.3八.]..&=.6^",
    descriptions: "'ğı{*ı\\ \"*8七^[@三5三 % _) \"#七. 4#[5 >@ 一527|,[6 #2$一ü] [ç六十四九3四:",
  },
  {
    codes: "    /?:五.,0.,0.八].,?",
    descriptions: "5ğı二}ı+ .;8八_$8?%) % 八六 '十0% 8二%>, 九6/_8]26 0#2一ü十 |ç]二}\\8四\"",
  },
  {
    codes: "  \"=2].13.0<..十.5三",
    descriptions: "^ö/#$; 八\",@'八5 && =)|+5@_ &十\"五五一(",
  },
  {
    codes: "四五.;_",
    descriptions: ">?9+}_五$5 *六ş\\0 }+八]2<# /,九<}/6*1>|'_ 八一#^ 四]^ +*\"六^:3 &<0>\"($",
  },
  {
    codes: "  \\}$4.<.",
    descriptions: "?_七?\"4ü九&@, 四八{7*. {* 五[/@_六=@< (73\"%$ş二(0313五{'ş) ",
  },
  {
    codes: "      4152.,二.&6.三@.]5",
    descriptions: "8四五% .五7五 \\?,'{三 \"\"ç@:;三#+3=",
  },
  {
    codes: "      }}.六.9..五7.8#.]'",
    descriptions: ",=?9 ).%四 8#('+十 #^ç|六 八 =一'_& 一四ç9*二3十八八^",
  },
  {
    codes: "      0?>六.5六.一).七二.%*",
    descriptions: ",8九, /二5} \",; 三8''+ 25ç+_ 7 '1'3@ 3#ç八\"三四::0一",
  },
  {
    codes: "      二五八八.%\".@$.(一.?4",
    descriptions: "四6(8 三三.7 8 [六'%4 ])ç+9 *3 }九'+3 5@ç'20|,七8;",
  },
  {
    codes: "      八3四}.\"].1%.&,.(3",
    descriptions: "九,ğ=%\"^8\"",
  },
  {
    codes: "    3四|7.[4.三].^九..2",
    descriptions: "9四ğ7\\六+九,",
  },
  {
    codes: "  0^0'.四7",
    descriptions: "+{^1十2#>, \\]=十9]六 (一\"{^2 |{']0十$\"@ 2ü>四一6一:=)",
  },
  {
    codes: "    ?八%五.|六.=].4(.=<",
    descriptions: "6^*2,#四九",
  },
  {
    codes: "    ^\\\\}.</.';.0*.四|",
    descriptions: "\\六8&137",
  },
  {
    codes: "  /;<6.\"_",
    descriptions: "八3ğ八六_<^]",
  },
  {
    codes: "    ='92.\"../?./].@<",
    descriptions: ";52*5.@9 %+ (}$=四三/.|8六",
  },
  {
    codes: "    ^7#).一'.+?.五7.#/",
    descriptions: "@'\\_六5 $\";三]0]{*,743}3 6>.1+ ,6:{??七', \">一9六2#, &一:2<九十五:, 81七五#;八|?9,",
  },
  {
    codes: "    十55五.;\".}6.90..<",
    descriptions: "_四.八' (/4)/五 1一>四6^\\+三, @(二59<七9. ,'7八 '2(五8九2 :十{:%30<.八|七1五4 +?&,{",
  },
  {
    codes: "    *十*@..^.一/.4\\.]|",
    descriptions: "8?ğ*\"九\"[?",
  },
  {
    codes: "三+.\"^",
    descriptions: "}3*? :6\",66; 7一{#/四.;九 @'^) #二{ı 十82\\#4{六' 0/^一^5; +4二六ı :一+}>, +;四', 9六04%",
  },
  {
    codes: "  五)&?.3].4>.五(.5^",
    descriptions: "0三]五 |.[九+=",
  },
  {
    codes: "    五4+'./7",
    descriptions: "2.73,**\\: 7一八} '七一ı7?70?0[_七 602|四/",
  },
  {
    codes: "      \":73.^九.0\".;2.九9",
    descriptions: "0ı七ı .?ğ {ç十;.4 çö@^八?2 7九0$^\"三 )'5{ 4\\89([{#( ',',一*; \\2<ı])[2一:}4五 4''\".二",
  },
  {
    codes: "    '九+%.,#.九].\"八.8#",
    descriptions: "$4ğ,+;_二5",
  },
  {
    codes: "  八=}+.?9.04.8@.%%",
    descriptions: "}@+)1十4一九1{ ^ç二6 @}[?@<<} @@=#[_\\十 ^) 5ı:ı+*七(ı% 八$?$(\"=八四;七%{",
  },
  {
    codes: "  七5三_.三0",
    descriptions: "/}ğ四6696) ",
  },
  {
    codes: "      八八7;.二_.一?.<+.=九",
    descriptions: "255)六8\\.2$一{^一 =#4)ş'*<[@ /\\$%七7]五7 6)$#/57_* '|@四 一}ğ\"八}_四= (|#@}, 七0<)0, ç%\\8^",
  },
  {
    codes: "    八700.:三.三..<?..八",
    descriptions: "\"$ğ1@>_七一",
  },
  {
    codes: "五1.九[",
    descriptions: "%}3七0 六|'| (\"|\\#七4'一ş 九六#四)\\ (|37': 二ö(ü.); 二六9;/ 八九5十 (^}+2*:>)ş ^<^7三",
  },
  {
    codes: "  六274.\"|.19.@,.(?",
    descriptions: "@5四>< '五@( ]7+:#十@,:ş \"=6'7,",
  },
  {
    codes: "  二@,8.十5",
    descriptions: ">=ğ_三8^\\八 ",
  },
  {
    codes: "    二2)^.二\\.七..$|.}}",
    descriptions: ">#_$,5%六2 950'九&7 (64[五;+ı'三ış 9) 8七九,?# <三*&]\\ış 0一$六=八:() #6?_'_( |\\=/6)",
  },
  {
    codes: "      (六?;.8八.一[.5六.(&",
    descriptions: "\\3&|3 >_1[ $^一/#&<二)ş *'],*七 .. 67七/0^ (2ğ)@二?. \"+1六:ğı )> %>四0 ,/=.四六'$&74* [{&=(\"",
  },
  {
    codes: "        五八},.@六.五{.八>.1$",
    descriptions: "\\ğ1#三\\& =\">十三ğı{,七4 5*{{五>七6+\";* 9.8#*$",
  },
  {
    codes: "      :四十7.]3.七五.3六.$:",
    descriptions: "9八ğ1#@953",
  },
  {
    codes: "[三]\\.0%",
    descriptions: "\";\"9 @<' (5ı{ı **ç五九$) (一^52六8 \"[89]/ş \\&十}3 _7,0[ı三)",
  },
  {
    codes: "  ,1>&.七九.*&.*<.=5",
    descriptions: "_}= _\\十7 ;九&",
  },
  {
    codes: "  7八三十.22.4&.0].+三",
    descriptions: "9%ğ'\\2五;8",
  },
  {
    codes: "*<五^.^|",
    descriptions: "[%%4 \\@?9六6:.@ ,_^55009; :+5\" \\\\4九四 5'|一.三6[ (十:六五|600# |六二78九ş,",
  },
  {
    codes: "  二9?6..7.?八.3:.四?",
    descriptions: "?&:8十一8\\6 三(/@十2ş ;ü十0//&八 9[]四;'_+",
  },
  {
    codes: "  6/:[.:\".&六.三^.13",
    descriptions: "$4@} 15八+*九7;. 2*七# $三{| 6ü十9_+ 82四2三2.^ .}^ ,[>1九6ı六7六2 [/.) %:十?一",
  },
  {
    codes: " ",
    descriptions: "      $一\\5^:}6",
  },
  {
    codes: "  一6^一.^四.33.(=.三$",
    descriptions: "七九ğ八六=)8.",
  },
  {
    codes: "\\5.四'",
    descriptions: "七5=$(六}@]八 }32\",ı, ç二\" 8ğ7#ı '2十(\"ı 8=二| _.ğı9 })二9|=&5( .四九5",
  },
  {
    codes: "  {七*1.>一",
    descriptions: "3)$十3(+0?< ,?>@3ı, ç;4 8ğ]@ı )?+)^ı 十8十四 二:ğı2 34]$|*<7$ 2=[?",
  },
  {
    codes: "    十{七9.:3.,?.8一.$#",
    descriptions: "+9=九五七:1+* ,一6(\\ı",
  },
  {
    codes: "    一六_=.一$.二).8&.>)",
    descriptions: "Ç}6 :ğ,#ı >(九.'ı",
  },
  {
    codes: "    1$四&.9%.五五.}|.[=",
    descriptions: "=;ğı= 十\\),{]@=1 五三#: /+'四= +ü?;&,5ı 十1.;]",
  },
  {
    codes: "  #6?..,<",
    descriptions: "88ğ四]{8.) ",
  },
  {
    codes: "    %_;六.十).七六.]^.二九",
    descriptions: "Ç[4 \\;ğı",
  },
  {
    codes: "        四|%$.'#.}&.3\".)_",
    descriptions: "@;% \"{五?&^\\;@6@# (#,二6三ğı 七+ 九2&*)| {7'四.)",
  },
  {
    codes: "        ?(\"一.?^.75.@八.8七",
    descriptions: "%:5 .5七%\\++<",
  },
  {
    codes: "        '2(二.1_.0九.\"二.];",
    descriptions: "|?ğı[ 5三((@\\/0_ 十一9九 八?@3+ 七{{ü)_'八(四 +九[:八",
  },
  {
    codes: "        (三六9.2}.五六.[@.一(",
    descriptions: "$@ğ)\"#9=,",
  },
  {
    codes: "八).=_",
    descriptions: "三5一;0六>2?', 9$ç=^; 2:]|&,:; 8+ ?;7\"五;ı^ 五ü[八]$#%[; \":九9七|\" #@\"#?ı ,9",
  },
  {
    codes: "  153;.<'.'3.8{.5:",
    descriptions: "五|264十九5<3 &十 ]七ç)\\* ,^%0*.+=",
  },
  {
    codes: "  8<<%.2|.八@.25.@2",
    descriptions: ",'</<5$;=1ı8 (/,\\ <|ç五\\% 六%;^|{$])&六 4\"58  |,二二|三= 八+)& <(ç[8:",
  },
  {
    codes: "  $二1_.*)",
    descriptions: "72@十) 9+1ı六7八&ı ",
  },
  {
    codes: "    {五:一.一).3%.二2.{<",
    descriptions: "一ı\\ı /五二六 九_8{* 四#1&. $0#2 4?36, [98ç五 , |ı(1ı, ş,六&\"1|4 四ü三ç八, &:==ü/,",
  },
  {
    codes: "    七$[七.四&.=\".(&.)二",
    descriptions: "九二ğ/_+0^二",
  },
  {
    codes: "  |]&6.<(",
    descriptions: "=[ğ]九四:8_ ",
  },
  {
    codes: "    5六,七.+?.'\\.|*..\"",
    descriptions: "\\\\}/九?^<ş 6{<ı<0六7",
  },
  {
    codes: "      ;_:..|;..五.六).^3",
    descriptions: "3,ç六'^ 四6五@六_七.++. <+,一>3}九@",
  },
  {
    codes: "      =|8;.&|.*4.\\,.\"*",
    descriptions: "&]ğ/#94八2",
  },
  {
    codes: ">\";8.;/",
    descriptions: "七>:3 :;五,二)ı; #%{一 52+五,;ı _2ğ八}七ı; '9.9 *三\\{九{八九; 1二&< }.$8_?ı;",
  },
  {
    codes: "    4<三%.16.9>.[..$;",
    descriptions: "#6\"ışı) çö七ü7ü;2. {十 一,+二+2四八6十:|",
  },
  {
    codes: "    一=8%.#$.1八.一三.8五",
    descriptions: "&七ğ5{[#}9",
  },
  {
    codes: "      4九五#.八+.<5.八&.一7",
    descriptions: "8一六ışı十 çö七ü?ü1;[ ]2 &9</三五(=;},]",
  },
  {
    codes: "        ;#&,.五$.:^.%七.六%",
    descriptions: "(/%; 三九二'七%ı 6)ğ_3+ı",
  },
  {
    codes: "        三\\'二.{6.(一.(五.3%",
    descriptions: "九六}二 <^4]48.7",
  },
  {
    codes: "        0一(二.*'.四+.'3.&九",
    descriptions: "二九/3 二十9(\\*ı ( &%一八*]八 )",
  },
  {
    codes: "      4一{(.八}.\\$.)1.?_",
    descriptions: "((一[|一_{ ;49\"二*}",
  },
  {
    codes: "      0]@>.)1.]0.六#.八4",
    descriptions: "二)ğ>3.?四\"",
  },
  {
    codes: "?一.二)",
    descriptions: "\\二ş{2六) ö0)ü$ü{ü, %二#九{')> [三二.4;87;% 5;三şı <8>$&[;}, 六9一一+四ı",
  },
  {
    codes: "    四<一\\./三.\"..三<.\\2",
    descriptions: "77十 (@@五) (#?[_九|*>@\\< (}#4)), %<[ &ğı53ığı 二{/ =}’ı *五ç@)7$2 五=十*九].'\"9?_",
  },
  {
    codes: "    四=5:.=8.五八.)?.>^",
    descriptions: "(六ğ2五四_[9",
  },
  {
    codes: "    \":77.{}.1八.2_.)二",
    descriptions: "二?? _ğı7;ığı 五八) .\"’ı <;ç]4>1三 ?3:>3五[?6+%七 24/^5}二",
  },
  {
    codes: "    [.七4.>{.5三.七2.=0",
    descriptions: "9<八 &ğı/九ığı 十;> *五’ı 1(ç), \",, 二\"’ı (.ç八二8^% 六]\\]+/,)^七,/ 3九_@七68",
  },
  {
    codes: "    四#九四.6'.:7._8.}^",
    descriptions: "#'ğ]0${:#",
  },
  {
    codes: "    \"三58.({",
    descriptions: "=五ş9[#\" ö2,ü*ü#ü1=;",
  },
  {
    codes: "      八):三.九%.:^.&六.<'",
    descriptions: "})=5ı ,+.?\"%=5[一) \\/[.+8)",
  },
  {
    codes: "        >*1=.八=.3六.'..[#",
    descriptions: "_;}<|#. 十ç77*\\七$七",
  },
  {
    codes: "        _3+*.#?.<^.六5./5",
    descriptions: "8>ğ七\"十1[五",
  },
  {
    codes: "      八674.{7.+\".五\".@>",
    descriptions: "%=>六ı 七{;*四=,'0'[ 一4>九??2",
  },
  {
    codes: "        0,>7.8九.;\\.7%.02",
    descriptions: "}\"7(ı &'@:(?%{.|[\\ 4==]ş,一@}02 ;3%9)\"五",
  },
  {
    codes: "        /&4{.$(.&2.六二.67",
    descriptions: "十六^|?一.三九七[:(+ (> 9> ;30%:6\\'2= (/\\,}= =十+十ü6) 3ç%\\9/?/5",
  },
  {
    codes: "        :2?$.]\".=].(三.3>",
    descriptions: "_.ğ三9_5四|",
  },
  {
    codes: "    ;}+\\.]7",
    descriptions: "(\\;'0+ı 七三5六<<七=@ı 6)?_三\"=0'95",
  },
  {
    codes: "        #8$;.)<./五.[8.7五",
    descriptions: "_五25ı ?0]ı八 5十一;ş^@]2'一 五一)5 七ü=(+$一1*1*\\",
  },
  {
    codes: "          一六\\五.94./..|八.^四",
    descriptions: "${1){@0<2&&七%7 &( &3 \"?*&2五|?5% (.@九\", :?|+ü4) :ç?|2%四六七",
  },
  {
    codes: "          %[+十.一:.>@.(五.\\#",
    descriptions: ":+ğ/559^=",
  },
  {
    codes: "        \\=一@.十2.}@.5+.){",
    descriptions: "{.\\6ı 十2$:,83>\"]08三6*\\ '%,8<}^",
  },
  {
    codes: "        @2四'.十=.;十.%<.(一",
    descriptions: "@}4;ı 3(\\+4八十'95;7九@3 =;\\|+四%",
  },
  {
    codes: "        $四>6.6[.二+._$.>%",
    descriptions: "9,6<ı /?{九三[ }9'. %[.3%+' 4>^三;)8",
  },
  {
    codes: "        |+,5.0?.十七._2.<十",
    descriptions: "8:##ı ].{'9( 一<01 ,4二五7&_7四'8 2]])7)八",
  },
  {
    codes: "          八^七三.|}.十6.*七.?}",
    descriptions: "*4一7#|4#六七七;八\\ 六< 六+ 十46_+四三0\"\\ (9十十08 .四六^ü() \"ç\\<六:({_",
  },
  {
    codes: "          ('7;./一.五0.{四.;2",
    descriptions: "=@ğ二6@'=2",
  },
  {
    codes: "      <9>).\\七",
    descriptions: "*46\"[_ı {6/_&3五1七 三\\[ 0\\&,7, 七ü^0ü<4#{> ö三.*八_一: , 7'/::",
  },
  {
    codes: "          #三&^.六五.]6.八6.|九",
    descriptions: "3([9ı ,3\\=<}二 四%?@| ?\\=>2三#十0ı \\.一?\\9&",
  },
  {
    codes: "        七/0(..].:}.八8.六'",
    descriptions: "1<}三ı 八五8三2$三8$5 7@@\"1二]",
  },
  {
    codes: "        ,4@3.*八.8#.九'.四5",
    descriptions: "}07*ı )>6@.'六 5七六十;})",
  },
  {
    codes: "        八{?\\.]*.八#.九三.@)",
    descriptions: "2:九:ı 九7)>5八?<3}十 {*+七三*4",
  },
  {
    codes: "        00+%.4四.(9.2..<?",
    descriptions: ">]#+ı ($[#'591=19.7 @ü#8|%[六0 .+=.^):",
  },
  {
    codes: "        一00\\.\"3.五一.>:.三九",
    descriptions: "%十>9ı ü57, ü30五十* &( 十ü72=@5ü九( .ü1=:.:<一 @八+&^#&",
  },
  {
    codes: "          3=一$.四1.1八.九\".[1",
    descriptions: "五二+一七3十3$一十七83 =9 3% <二7|四四&9四} (六0二=五 @&十三ü=) 9ç二?'@四?$",
  },
  {
    codes: "          #8|[.@'.._.&{.91",
    descriptions: ":>ğ七1=一+7",
  },
  {
    codes: "        2|&>.{七.三7.十_.*%",
    descriptions: "49六4|*;_>(%九48 :( ={ 2/.,一5/|6* (9七_#= '9九1ü=) /ç8,五,230",
  },
  {
    codes: "        三六^1.九四.八十.十|.六^",
    descriptions: "2(ğ一.*2>2",
  },
  {
    codes: "        二3二六.八$.^>.,*.\"_",
    descriptions: "(:4.;9?九?@{六<) &, /5 五1>#&#六\\四: ((,二四\" ]@三=ü8) ;ç<七._二\"8",
  },
  {
    codes: "        =44$.四*.$*._..|$",
    descriptions: "):ğ\\=七8},",
  },
  {
    codes: "    >+一5.8&",
    descriptions: "(八/=?\"^*^( [/__037}",
  },
  {
    codes: "      '0一*.八,.<+._<.#[",
    descriptions: "*=?|ı 八十一6\"二&9, \":{五?(= {^2(4\"ı ;3)&#四[",
  },
  {
    codes: "      三一8\".@0.$^.>3.2\\",
    descriptions: "三+{$ı )四=|77四七六1五&ş [;&十ş#|,七7 三$.%四@*",
  },
  {
    codes: "        |\\9@.5).+|.三\\._七",
    descriptions: "9二359[52\\^9九_. [: :/ 五7六1.#二六?] (\"}]_, \\|0}ü三) 2ç1十,?^=\"",
  },
  {
    codes: "        @;八?.*].4}./}.#二",
    descriptions: "十<ğ4|?,六四",
  },
  {
    codes: "    :八_6.四3",
    descriptions: "$|ğ:七132< ",
  },
  {
    codes: "        一@九).$8.:].|'.5'",
    descriptions: "(0+5?47[:%(_[( {' .五 <1>#${^六九? (|,8'+ 十,十>ü?) ]ç{二4$;#三",
  },
  {
    codes: "        4%3九.77.&三.(7.九\\",
    descriptions: "+九ğ4=7\\十\\",
  },
  {
    codes: "        \"_:$.一_./,.&八.=?",
    descriptions: "9}=0+四'5一'26@_ #+ ?] :=19四,$7'8 (九0<一} 8),{ü?) [ç88[39|&",
  },
  {
    codes: "        9一=(.#5.(二.\\,.九八",
    descriptions: "&^ğ_(=?#0",
  },
  {
    codes: "  7_.1|",
    descriptions: "0/,+2|5<& $)ş+一 二5二[{七= 7%六 %0\\]956 03一@ ]四,?))29六4]([ ;5;八}",
  },
  {
    codes: "  六三{二.77",
    descriptions: "1@\\.ı ?]ş,>+_]ı _4\\0) 五.@6>4[",
  },
  {
    codes: "    2<3*.|<.\"%.|].4&",
    descriptions: "五ğı71ı1 十[|十/?五6$} 2ç}1;3/} })ş2?\"+%ı 五九#6$ @九三@&\"ı % ^}'九四# [< |8]8(37",
  },
  {
    codes: "    +]\"@.7^.二十.=9.{%",
    descriptions: "+ğı=)ı] }4[@)..;06 _ç>\"<一十} 3,ş)5#:四ı \"四@<6 +6#\\\"?ı % 1三 四9+0",
  },
  {
    codes: "    2{2五.|5.(,.)@.#\\",
    descriptions: "五ğı|#ı; 五%@&>>#十79 1ç九2)\\47 {%ş7#^]&ı |6三(. 1四五;/4ı % ;_ )?#\\ 4][4",
  },
  {
    codes: "    '3:二.}六.;4.@4.\"七",
    descriptions: ".ğı](ı_ 四5\"'七七1}') \"ç八((十{# ;:ş.3_7*ı 一9三四, 2%\\+()ı  % 1, .四)'",
  },
  {
    codes: "    六=1九.'[",
    descriptions: "八/7<^:($ |{.^6}++9+ 二八4# )\"(四=#3 5@六九十#二#&]八 :}=(72ı0七四",
  },
  {
    codes: "        33十|.@{.二六.9[..:",
    descriptions: ":ü一46\\;[( 八_şı二 ]^ 六8十?)四1",
  },
  {
    codes: "        五.四十.'1.\\].@?.*_",
    descriptions: "五/>四+<68) }ü0}三\"#三<[&#ı",
  },
  {
    codes: "        六+9{.85.(,.\\+..四",
    descriptions: "'38?\\#4六ı? }| 九*ç#\"+(7 八四(\" 二+\\八0':$)7#九九 四八=77五 8ü3+_$*一45&8",
  },
  {
    codes: "        (,&+.9九.>}.五/.+:",
    descriptions: "二#ğ十|七{2=",
  },
  {
    codes: "    \"六7{.三*",
    descriptions: "八.ğı$ 五0=00)8八(. <?|= 六.]二(?@ $8'<\"6&*+#[ 七3'八3\\ı4#5 9ü六63",
  },
  {
    codes: "      $[\\).(%.@&.#6.>#",
    descriptions: "^ü\"十六0(^六 .四şı九 ]十 ?*+@|_八",
  },
  {
    codes: "      \"}三#./七.二;.一9.4)",
    descriptions: "00ğ_52九@三",
  },
  {
    codes: "    ]_'三.七&",
    descriptions: ".94二 >^91六=%&_^ ;六\\) 0[\"*7\\八 5_%[8&五#@(/ \\,七4@/ı697  八ü{*?",
  },
  {
    codes: "      ?一(*.42.'$.\").)9",
    descriptions: "5ü二;>3{|7 ^:三十@51",
  },
  {
    codes: "      一@*'.9六.五十.9>.&四",
    descriptions: "}十ğ五8,7^(",
  },
  {
    codes: "4<.?%",
    descriptions: "3一四^* *5.ı;\">.ı五ı< 8+>1十十7=[&7:十#\\ =}3&8(ı*90 七ü八5十1^二)5七+;",
  },
  {
    codes: "  .@\\三.'7",
    descriptions: "1#_.@ /,五ı3+\"=ı9ı> 十\"|]=(6\\二%876一八 五五\"/*5ı<53 ,ü}\\:5二?*=]|;",
  },
  {
    codes: "    ]=七四.3^.>+.'7.^$",
    descriptions: "='<#{ 5_三ı75%'ı,ı二 四_6]>4(八,=4三&2一 二七|:<%ı'(七 &ü9$\"(&四一七$/",
  },
  {
    codes: "    :43?.35.}=.4九.^九",
    descriptions: "%_1(} ;7 %>ğ六十 一<){四,一?三三) )44}二@? 十7/8\" @八9}. ?/ >=六|$三 三*三:九1=4ı",
  },
  {
    codes: "  六四[八.1:",
    descriptions: "&三ğ:六86)%",
  },
  {
    codes: "    };(;.'>.=\\.^&.@.",
    descriptions: "3+六六4 =0 五八九0]九 ç6六<9二##ı{ı一 十三 *二;$/[?/二九7ı6ı八 五ı>,二}&_ı|4=",
  },
  {
    codes: "    九六^:.8{._'.八=.7.",
    descriptions: "八七ğ)_^{十3",
  },
  {
    codes: "九三.(:",
    descriptions: "6/%十26,9二 ö/|))##五 )ü9^^.|2*\\^,, \\;三34.一四\\=> )\",二}2十>&",
  },
  {
    codes: "    )一\\;.|$",
    descriptions: "*>九%ı %'6ş+$ 二9:5ş=3九三$: &8^[=;5",
  },
  {
    codes: "      三__=.十_.五四.一].8*",
    descriptions: "\"8八6ı 9*.ş4>#($七2|;六* [.2'/.六",
  },
  {
    codes: "      ]+6三.十八.@一.#5.6]",
    descriptions: "八七ğ?|,$0八",
  },
  {
    codes: "    }八八1._&.1%.八十.27",
    descriptions: "^<ğ\\[)一二+",
  },
  {
    codes: "    #十[$.九四",
    descriptions: ")六$(88 /十ğ]六[ı |五%* '^@ü0)]^3 }=5/6<]>^>/(= 二%$一 .}(7|&",
  },
  {
    codes: "      &]1@.?5.九二.95./\"",
    descriptions: "'五\\|?@2 ;=ğ十]5 )(四@ 六二:<[九& '{ğ二[# {]7九 六\\8ı .#十ç(_ 6三(.@5ı\"34 )%ğ九0 9ı2ı",
  },
  {
    codes: "      1;7#.十二.:7.8\\.7'",
    descriptions: ":2ğ2?)四7>",
  },
  {
    codes: "    十}六7.六/",
    descriptions: ">&ğ7二#=_\\",
  },
  {
    codes: "      ]八|3.#,.82.\\=.+7",
    descriptions: ":一\"二#(1 $,ğ七28 '&90 )_<二三]2 /2ğ}&1 :>=; 9@:ı /#'ç15 {@一23+ı?=' (六ğ;? 9ı=ı",
  },
  {
    codes: "      七[3?.[2.7@.6%..&",
    descriptions: ",8ğ\"一@\\#9",
  },
  {
    codes: "  九五>$.4{",
    descriptions: "/=ğ>;[#14",
  },
  {
    codes: "      #,[,.3九.;二.}4.三<",
    descriptions: "五ğı\" %;#6八** 9七ğ_{^ 十ç6十 [ü五4(@十)2 ?:2二ı+}[",
  },
  {
    codes: "      七五十=.}七.)=.9<.(八",
    descriptions: "二\\((8 9&@}五:\" 2\\ğ(/7 ;ç]; 二ü68四五95? $一2@ı?_:",
  },
  {
    codes: "      \\3一九.*三.>八.+[.四;",
    descriptions: ";}ğ%{)10.",
  },
  {
    codes: "    &三9..|$.=五.50.:$",
    descriptions: "[#ğ18四_1@",
  },
  {
    codes: "8六..{",
    descriptions: "3+:7七(=&$8^6八\\ ç*五2*|5ş六ı#ı1ı 4ü#一*@1九8%,4; 0五^ç7; 三& 三5九7=.四]$@",
  },
  {
    codes: "  ?*?四.;四.六\".|4.#{",
    descriptions: "六]2六,>]二五3九9%6 ç<'九]&3ş*ı_ı7ı |ü#六三#:二7五,,",
  },
  {
    codes: "  ]^+#.5/",
    descriptions: "8.二ç六3 十=?% |7[九.|>#}一 $ç一4 8^*一六八,|一四十 8($6ş+九4@2",
  },
  {
    codes: "              五三[=.^九.;}.#七.,<",
    descriptions: "$%:七37 ^  3({五};4<;7@十5|  ^  9&^七]_;><  <,{  9^|七:+七>5%四一+ 2*@/55 |$ 6@>%1六",
  },
  {
    codes: "    2((\\.(8.}九.&8.}\\",
    descriptions: "5三ğ四六%|6三",
  },
  {
    codes: "        三7八@.%五.\\三.\\四.6&",
    descriptions: "0,],;,一#二,}九<#,, %八\\'{六}?]一:[2三]> (3*') 6+一6(_(}一.[;#*# 九<九ışı四(0/ı",
  },
  {
    codes: "    二2.9.六三",
    descriptions: "+^ğ@%五8*四",
  },
  {
    codes: "        四:六1.]三.6#.*].六)",
    descriptions: "8三*ç), #ç/二 [七,.7@5/]<& ö+.$+七?@ \\ü5>]/.+)}五)",
  },
  {
    codes: "        8\"+^.('.%=.;\\.1/",
    descriptions: "{六ğ'1 六0._四6十*1七? ö十}十8[0一 九ü5<三*1=&_九.",
  },
  {
    codes: "      @五^\".34.5五.&?.9四",
    descriptions: "@九ğ)|;/五.",
  },
  {
    codes: "51九0.六:",
    descriptions: "四(一九ı_ 8ö七:ü(三^ +=/3一8<| _ç,五 [{;三七 |('0'8五__ 52 ._{#ş六一|6[;",
  },
  {
    codes: "    <3二?.|四.16.+\\.1一",
    descriptions: "{%四%).6:二'三,%@})3六^), 2.'6九)2\\六{=(/六*0$ [七/六 (+{三6九:()=[4]8#\"#3#\\ ,ç@&5.*}+",
  },
  {
    codes: "  \\九;八.>9.*%.五3.6\\",
    descriptions: "%/三三六, )$@; 3$+( 5_七\"四九 \"35=\":'九]@]\";#0$_8二%,#6 ((@二九'&\\3) [ç2;)|>.*",
  },
  {
    codes: "  三4&8.>&.*八.4<.*?",
    descriptions: "}637五, #08} 91\\> *?'22八 ||六(<},>九;+九+八|七+四%+五'9 ()%\\@'?5_) >ç一)1>._)",
  },
  {
    codes: "  ;?;_.',.二七.%'.三9",
    descriptions: ")@@七,.<'(四;(95 ]ç',2十(%.",
  },
  {
    codes: "  \\7五=..九.8一.1[..$",
    descriptions: "&9ğ:)=^*]",
  },
  {
    codes: ".[九?.八;",
    descriptions: "6}6\"0+52* 3*ş三} ;\\2=六=三 8六五(十{(六28二八/ 四七四) 六^六 +3_/&;5 3@}0;{0",
  },
  {
    codes: "  4&8,..:.;三",
    descriptions: ".\")九ı 9ü782$三2[&7 &_9>?=1",
  },
  {
    codes: "    十2,%.\"$.66.0{.{七",
    descriptions: ",0八九$, 十^&{ <$0{ :二|;3九 八}八)八一#\\8=$5>8十83/ı (:'五) 九ç)五八九{六(",
  },
  {
    codes: "    六)]'.;1.五*.十_.二%",
    descriptions: "#,七?1, {'#, 五&/十 (;十3{/ &&5<'(*9.十一?7+(^四/\\8七七#七ı (九^4}) %ç{七_2 @,<五?",
  },
  {
    codes: "      ,(,%.+%.\"^.{三.七%",
    descriptions: "3,]2^^@\"=%89二_%ü>, 80一>4+九_2二%(#*一 3七8; ],4,=%7[*六}*1=_五* (\"1*一八{一&8['二?#)",
  },
  {
    codes: "    ;\"\"+.,_.'\".89.$,",
    descriptions: "]九ğ四@|48&",
  },
  {
    codes: "  ^|五$.%五.}]",
    descriptions: "+十ğ4九六7$2",
  },
  {
    codes: "    /二^..(}./5.07.<五",
    descriptions: "(}五_', &$>四 |_三\\ #/6'.' 三\"\"(二66'1九4=六八)0&一ı (九(二) 8ç**四_二35",
  },
  {
    codes: "    ,四二_.1三.%&.=七.\"?",
    descriptions: "?;%9三, 9)二> 三6[0 ',&87},五一七九}520}^'八);9一六_:,七2(ı ([(}6) |ç5]** 五七9\\5",
  },
  {
    codes: "      58_九.*{.[3.@,.5;",
    descriptions: ";3七三<:{9六9五,'&九ü2, (_;=1&七(_^三[//4 1,七' 六,&,三1[%]2七四%七三<@ (5六=/<'=六3(\\5)|)",
  },
  {
    codes: "    %0>七._\\.\\\".8'.[\\",
    descriptions: "9五ğ{_\".@六",
  },
  {
    codes: "  ',.$\\",
    descriptions: ">七三/九#][@ 21ş四四 #]}}*十} '&$4\"9七_*三7|& 七0.四 九@6 \\>3二{9@ _*'^三<",
  },
  {
    codes: "    )5六..\"5.四%.三[.)七",
    descriptions: ".^三5四 =_3)):' 6*0#6 #%(; *;>,四 ,八四}ş五3八(=/ .%}八四四$",
  },
  {
    codes: "    [四八(.0=.(3.&八.9&",
    descriptions: "9<,七/ +\"6{<|2 ^ı54;四.一 5}/1( 六8\\三 七ı八5八四@\" 4/_[五 '七4一ş<十$\"^<",
  },
  {
    codes: "    9\\'6.九5",
    descriptions: "\\一ğ九]=^_.",
  },
  {
    codes: "      (四{九.{{..*.=+.九<",
    descriptions: "[ğı9八ı\" .2*?}八*五八7 % /三 89|+ 七一四六 =<$3}六ı 三$+一85ü* ;$=. 4] 一+)8|@%06;&.",
  },
  {
    codes: "      %|5}.^6.}&.):.'\\",
    descriptions: "3{ğ.六三=三三",
  },
  {
    codes: "  十一.*.(3",
    descriptions: "&八ğ八+#4*<",
  },
  {
    codes: "    .6+六.1{.^2.四&.六#",
    descriptions: "4$%>九}五 /ç;_(, çö},]@; 九1六['&八 |6_4七}^+四&四5)86@九\\2八二 九^=\\0; |ç@57'",
  },
  {
    codes: "    \"<五_.四@.3$.二).*:",
    descriptions: "|[ğ:#+一九>",
  },
  {
    codes: "  九})3.七$",
    descriptions: "(7}ş. &六九@七ı%\"ı ç08@[.+九七@, :_0ç4七十, 5$|6,7]三 七\" :七>7九5| {十七ışı5",
  },
  {
    codes: "  ?七<6.@*.?:..{.6?",
    descriptions: "九三7<%/+ )|/ş 7,ğ.)九ı '3[]ı",
  },
  {
    codes: "    3'4十.?..,;.+六.六@",
    descriptions: "7#六ş^ 六239@ı}{ı ç/三03?@>,:",
  },
  {
    codes: "    *3{<.3:.五1._4.$6",
    descriptions: "$</ş五 ^;6>6ı_*ı  %9七ç5五=",
  },
  {
    codes: "    [一五二.九八.6%.3\\.九#",
    descriptions: "三2ğ@?一56?",
  },
  {
    codes: "*[四1.\\四",
    descriptions: "\\<<ışı二 '四0(九@八 4'一九,一%='#]六8} 8+ 五8|;$]\\|[)六二07|. (8|.,@ ;:[九 22.$三",
  },
  {
    codes: "  9'2(.5:.{/.六\".=<",
    descriptions: "66[三@, _0'[=,55*28",
  },
  {
    codes: "      五1&,.?4.,8.)$.8%",
    descriptions: "]四\\) 718八%%_五}/**/ ( 9?@四0%&%5#^三/_ )",
  },
  {
    codes: "    七9$6.\"(.0).4八.5[",
    descriptions: "$*_ışı' &^二?_}一 九^ğ0@ @{|[. {\"^:}:[?\"",
  },
  {
    codes: "    [(6|.>二.={.一\\.4?",
    descriptions: "+二ğ5八\"&一)",
  },
  {
    codes: "\"#7[.七%",
    descriptions: "5.四|4;三3;六<? 1_四/}+ı|/|四 ü<六2} ^(八{ $)\\@四}ş 9三?=&二#, 7{'二;>@?10",
  },
  {
    codes: "  六_@三.>六.二\\./\".=0",
    descriptions: "[]:? 三六*,)6ş :&九二\\七(2",
  },
  {
    codes: "  1&00.九[.五,.\"三..%",
    descriptions: "九九ğ8^]8四二",
  },
  {
    codes: "0$\\七.四十.1]._9.'<",
    descriptions: ".|&1二九/一 [.四7 9ı9ı一6{ı 八7 八(18&$5[ 6)*四&六0%7*|六)/ &ç?) 五13^.九 2三ğ0三2ı",
  },
  {
    codes: "$六)9.[八.:五.六0.%'",
    descriptions: "6;|六?9ı ö<*八$;0九 ?ü9:#&3(6\\/八 )9 十五(二\"2ı çö0ü8ü [ü4_/{3%9",
  },
  {
    codes: ">九7/.三%.86.*一.8四",
    descriptions: "]6八}=#七\\)+[九*&3'<ı& ( >)2ü>9八: 三一 %8?&'@五一<5 ),#3+$8)十*:,, 十;23一 8$五2 八(二<\"\"",
  },
  {
    codes: "#十.#&",
    descriptions: "6九@ 七{8?(> ü9?()[21 91^=+十: >?%7六/十五28九\\'>六2 三7'3 *4ş08(一五",
  },
  {
    codes: "    .2|一.[%.{=.6@.(#",
    descriptions: "[ı%/^ ?ç#7 \"_8=274",
  },
  {
    codes: "    \\323.$三.九|.2/.%[",
    descriptions: "1{0; 0; _>43. 六7>三1 {七*#0|\"{0$1三1九二& )(ç#2 3@ğ^9 **\\.]^ı$?>| 0ç86 十'|17一@",
  },
  {
    codes: "    ^=%2.%八.二:.<{.0,",
    descriptions: "七十= $]7@{68ı五ı) (5+< 8,六 ^)/#ö:%>35@二2 0%0/三' 一ç6_ [8=三<十%",
  },
  {
    codes: "    |429.五:.(>.1\".;五",
    descriptions: "<;ğ七6>\":=",
  },
  {
    codes: "  ]八二&.十[",
    descriptions: "\\'ğ**三*':",
  },
  {
    codes: "    _3十一.^\".%%.三四.一+",
    descriptions: ")10 8{八5009 二=?#]5/)< }ş]>六'十^;/十{.8二",
  },
  {
    codes: "    @;五\".5>.*{.十?.74",
    descriptions: "{七ğ\"_=二|9",
  },
  {
    codes: "4'.四:",
    descriptions: "?ı|三& 九}};|@0\\/]六]78: []ğ 十_.八9703; <^^8}?0 +\"}四1#ü 七,/% 2*ğ#三四ı;",
  },
  {
    codes: "    ?6=九.?\".$八.%?.;0",
    descriptions: "0[九^八|三 #>2*",
  },
  {
    codes: "    +<?}.?=.86.八}./;",
    descriptions: "_?4=' %,0/",
  },
  {
    codes: "    :]{).?/./?._二.6七",
    descriptions: "^(() \">\" 二ı|ı _@ğ ,7%七'|2;",
  },
  {
    codes: "    .,十2.三4",
    descriptions: "34ğ>]_六65 ",
  },
  {
    codes: "      &[^+.%..@&.十[.5#",
    descriptions: "(\\$ı^ı十7ış #{ğ :,^二\\八8)",
  },
  {
    codes: "      /7@四.八(.<1.1七.8六",
    descriptions: "[七ğ >5^十五*(5 十62ı/九6 ü4ü?八@%>",
  },
  {
    codes: "      2:#4.,?.}?.1九.2&",
    descriptions: "|<ğ\"#十五四六",
  },
  {
    codes: "  6七四@.1?",
    descriptions: "七ı,?+ \"=ğ <|{四({:(八 ",
  },
  {
    codes: "    48)+.:$.四=.八二.4/",
    descriptions: "_*< _++?&%@['@十 $\\|{ 1ı<十| 2|ğ )62|[:751",
  },
  {
    codes: "    :\\3四.?^.三[._四.7]",
    descriptions: "['ğ#' %ı3:} 七)ğ .\\>#七?8*=",
  },
  {
    codes: ";=..]",
    descriptions: "四ö八ü=#84七 9|ç1二;二ı一, 二十*4 ?58ı\\/^[ı八\\ 5^\\3|) 4ü#\"十;5九四",
  },
  {
    codes: "  0九+5.一]",
    descriptions: "7ö/ü*@九)8 #.ç[五^:ı:五 $?=2 七七十ı[]5[ı]| >七三^五# 九ü&_[四<77",
  },
  {
    codes: "    (八&\\.&*.5|.0].#)",
    descriptions: "8\"[>ı (四,04 9[ç8;: ?五4六七6?",
  },
  {
    codes: "      #(六7.5九.8}.'7.{3",
    descriptions: "[1|)ı 0^2;;/99 0一ç%十| /&>$,'ö]ü56七.0 /9ç'3)三ı+? ?$5八 )$;ı\\1\\'ı)1",
  },
  {
    codes: "      ([1(.:2.36.$$.{[",
    descriptions: "^九ğ#_&3二}",
  },
  {
    codes: "  9七:}.)1.;$.**.二>",
    descriptions: "二8十3% 8一.02?ı,七{ ]1\", 四4.#74@ )0ğ}#8ı二ı/;二1十 六十^ış?ı1ı06ış",
  },
  {
    codes: "  @)<*.=&.七\".六8.{\"",
    descriptions: "Ç#五2=^%, *}&ç 十二>; _<_十7,>02 十.\\?}3 {ü):;六81) ((92ı[01",
  },
  {
    codes: "  |^*5.\\_",
    descriptions: "四)%ş' +79@|ı|_ı 6:19三#一 '41ç 五\" ?九.6八)三\"",
  },
  {
    codes: "    十^^4.\\<./^.+(.@4",
    descriptions: "\"<_ı&] 三ö|ü五:#四六 ^\"一ı] <^94{",
  },
  {
    codes: "    814一.一?.三4.3=.六7",
    descriptions: "{{ğ九%|一#六",
  },
  {
    codes: "  *@三8.)\\",
    descriptions: "12)四>^;% (\"1%2.3七 4/{ 5;]]七六75@<六7 0(6,^#]09* |\"\"三*\"(6 +四七5ç)",
  },
  {
    codes: "        一五_0.};.0|.%?.?0",
    descriptions: "\\三=?'四)5] {|3_7?ı ü?7$,)}[8 )三^|八%6^4ığı#)\" 五ğı二2ı^ 46{八_%6%七四 % 八",
  },
  {
    codes: "      _五0\".>一.+4.\"1.4|",
    descriptions: "五五ğ三@#\\#:",
  },
  {
    codes: "        [一>5.*(.<3.?十.@[",
    descriptions: "[一0十:*_:0 5%)$二}ı ü0(([(1;( +71一\\{0|一ığı#], #ğı51ı| ]{70;1\\*,| % :",
  },
  {
    codes: "      8|9七.(七.1'.7..2\\",
    descriptions: "六/ğ8六0六;4",
  },
  {
    codes: "    4三*=.%3.,/.[八.四&",
    descriptions: "/}_;:^五 (十;八#七八0=二_%) ?ç.:30=+<",
  },
  {
    codes: "    _&十6.@8.@[.|_.?;",
    descriptions: ")4|4七,@26:\" 七?@5<八ş 48&+6*(九^[ (=50四),#60}>^+6<$5 _4一?2'ş 6九+三五.835&, (&5>=) 八<9<",
  },
  {
    codes: "      1;七九.?_.五_.%{./*",
    descriptions: "二7}\\ ((,七|'八+98?|&,5;?) 七/三+={ #ç四2<06二4",
  },
  {
    codes: "    8六4#.8,.\\>.7:.三9",
    descriptions: "<3{59} (#四9), \"六^81七+9;(六 (6八:) (1四八?/^十98), >._0)五=六四 (}#/),",
  },
  {
    codes: "    .<{>.20.五].}/.3:",
    descriptions: "%;'+{(八 (%*二, 三十,) ^6六三*, 1,),6,7,9,八 |40)\\:={&}7=六3九八二七#}' (>9@ (@2七)) 十ç9<$;十,^",
  },
  {
    codes: "    (=)七.6_.};._%.九三",
    descriptions: "\\(76|+02=(='31<七^1 (?.四) @59* )%;3%.]七七>二0>)#&/ (8%@) 6ç@)'|+{$",
  },
  {
    codes: "    ?五,5.=,.三[.,}.3^",
    descriptions: "^六#八四=3{&六;^7,九 \"ü^六6)#[ \"三1., 七^^^二一ı, _{十5四\"六@三@[\",>8",
  },
  {
    codes: "            5+9%.+2.]三.四{.}^",
    descriptions: "%'6{3, 3[<四%, ^二|3 <::74 >/3> ^840&;/=}(十+;,+^< 5八6一^二9( 3ç7[/9五'_",
  },
  {
    codes: "    _[+[.,;.4_.:一.^6",
    descriptions: "十ı3& 五([%>二八七 0:81$三 三四3#,十一95{( (ç/\\*0+八9",
  },
  {
    codes: "                    九}\"8.\"8.\"\".'八.九)",
    descriptions: "%八三2 {:)2(一 <_]七_?/<45{八7*五@)6\" 九,&,*8<>/3七三*{$&六&二:",
  },
  {
    codes: "    四;9:.'{.5一.]九.*#",
    descriptions: "六8/*\"八702#二55 |55<$六 }>(8一3二;1/ 十@96.6#(}",
  },
  {
    codes: "    79('.#九",
    descriptions: ",;ğ@=>七95",
  },
  {
    codes: "      )二>*.%;._8.5}.)1",
    descriptions: "=\"77#} {ü四+]8&十^.@ı (]3@六?3 三$67(*[四?六, {=*三%一\"1+ 五+ 8&0%,%_,7'=|@|9",
  },
  {
    codes: "        ,%\"九.%8._五./3.三4",
    descriptions: "3六.四ı :ü_,7_05 8五^{>六\"八$ 59^六 ';^2| *八}9=\\) 5\\);九 38八1]五\"",
  },
  {
    codes: "        +72^.*).八|.9'.${",
    descriptions: "3{\"%+8&< 4[13十{",
  },
  {
    codes: "        ??\"$.2@.9,.\"五.|/",
    descriptions: "[*ğ?四 七=6} 1)ğ六ş28'1%%6二\\",
  },
  {
    codes: "      #三?6.$#.%_.'十._4",
    descriptions: "0五二\"七/2} |ü{5|9九 三三 &七:八91^.五[^ 6@>>八 _^7<,|^四 };/3|4* @)五08 :)<#ş.$(二|",
  },
  {
    codes: "      #$九十.:一.九@.6].>3",
    descriptions: ")5#77三)216{;_ (六<=_+#八@ *二九?)/==一四 6%(_); ^)% =)0{.#(< '&}七*)*; ;十二",
  },
  {
    codes: "      *5|3.十四._9.一#.}|",
    descriptions: "<]|34:52 [8二<?二$, ,9七/五四ı/ 54=\\ çö2ü>0&?,6 7一_|}?ı <} ?6,#&'}十十",
  },
  {
    codes: "        9#)六.\\).6;.<?.[9",
    descriptions: ",<七*$,/5**}ı ö:\"}8:(6 []11ş一3'}5 ;九 +四__4}/64:",
  },
  {
    codes: "        %]=?.]二.^).7+.*'",
    descriptions: "=)>{?81>六} ,三04)\"七 #+/三4四十;5 二\"<00:ı41# \"ü_>\"%五\\+82^",
  },
  {
    codes: "        >3:=.::.一).#三.,6",
    descriptions: "5:,三, ]3 @} ?&5 七七,5:02八九[三十四 66\"ş], }921\"'+; <]*ışı\"@|]ı (\"?*ı \\3ğ4三=",
  },
  {
    codes: " ",
    descriptions: " - - - - 六|?)<ı:ı} =七 ^?八'5^六,' \"\\\"&=4ı二&$ ü一ü;9@@ {( <ü八)/*1三;七>^",
  },
  {
    codes: "          .五97.@6.?<.5@.\\2",
    descriptions: "八/.^' }十62+'&'#{六'1二 .029[九$7 ,ç{四 {十十=二4ı二<{ 一+)ç十/*ı7 @,)6:四< ^七\\)十'",
  },
  {
    codes: "          +,二3.:>.\"\\.?8.7\\",
    descriptions: "十,五.)二|[ .[[*{'ı' ='二$二,9(] ;2二\\ 八_^;八. /38 ü\"ü六?8:",
  },
  {
    codes: "          \\\\^六.5十.二*.>五.<*",
    descriptions: "\"^ğ三\\}(#一",
  },
  {
    codes: "        5?:).8..\\四.|}.)7",
    descriptions: "7ö9ü]*ü5ü四>3 六八2二.+ı+\"4 七ü'<+5 0九{六ı+一ı ü一ü=9四2 ('三9].4+.&\".#3 :{8",
  },
  {
    codes: "        $1&6.六|./>.七\\...",
    descriptions: "İ8ş一/] )一\"一3六十*%9 2八\\()4ı}&七 05;])&ı 十\\ 7% :@ç=一九一(一 ö8:\\_'1",
  },
  {
    codes: "          三91[.6三.(,.5二.八}",
    descriptions: "1/_8九8 /{八四四三五 9,%四( (2{]? >|'.3.8^ş)",
  },
  {
    codes: "          #44(.)*..三.'6.4\"",
    descriptions: "[4_一|]57 九/?\";%ş <?ğ ?9五四${*4|8(] {ü*九>)八_五ş, |86<+?0| \\/十[&ü= 'ğı四五ığı",
  },
  {
    codes: "                    51}+.^/.七[.九八.]三",
    descriptions: "@  (7  二#十七  ] 60'&75二_'$五) >九[35%&*四  1  ^*九&0九 (9*=ü#= 5ç79^九",
  },
  {
    codes: "          %@,0.>7.8四.十#.>.",
    descriptions: "Ç8ğ>=4/(七[ 六七//2$: 00'_)三]@=>6\\', 七*(/1一\\ *+ (142二9二0{,}+<%{6>,",
  },
  {
    codes: "              ,四|5.八3.1:.六,.[五",
    descriptions: "{ü<六0 0+ğ6(&ı",
  },
  {
    codes: "              九(七;.'=.一八.二].*@",
    descriptions: "5%3{.) /二ğı",
  },
  {
    codes: "              )5<?.四3.5_.八一.三二",
    descriptions: "{=ü九二7六%二 三ı%ı,ı6,%",
  },
  {
    codes: "                _9^七.\\+.六2.:%.1\"",
    descriptions: "[三ş@ı七九 9 <|.?. (£ [十二, >(=4|*^八十. [ç'(2) ) 六/=\\] (($5|二5, %[@<7",
  },
  {
    codes: "                  8.十\\.\"9.七一.#4.六7",
    descriptions: ",4ş>ı79 > (:四,* (£ :\\2, 6:[&77*2>} 十ç:24? ) 3,25>/_=={ (*$],;<,",
  },
  {
    codes: "                    6*}*.@三.}).>{.=#",
    descriptions: "|\\ş^ı七@ [二八(77@\"$9| (9=]五;_, 0&;5九, \\七.+^3> 七[\"< _9*七八}@九?) 0&&#五)",
  },
  {
    codes: "                  '{七+.4十./'.'八.=8",
    descriptions: "?五ş5ı,] <一]}+ (=$\\;[[, 九8=*0, _<七*:+* <,=\" 0]八]+六?2@) $};@(十七;五+",
  },
  {
    codes: "                    $三82.九..#%.^/.*三",
    descriptions: "2<ş六ı$六 [19 五四九{五|{ (:8/%十\\, |+一'', >$1七八<9 #;35 二9@@{*:(9)",
  },
  {
    codes: "                  二{9$.92.>6.三1.[0",
    descriptions: "7四ş\\ı\\< 8,+ 2四&\\5&: (_:九三\":, 8(\"?=, :'1/@&& (7\"2 925五%@_)[)",
  },
  {
    codes: "                  *$[8.|4.^|.#二.8四",
    descriptions: "0一ş:ı十六 \\+\\一2=+ (八#1?)_, 73>+|, ]4)\\;四5 四03二 #<7^一_[\"/) 三,四^(#四二']",
  },
  {
    codes: "                  :$6$.8@.四,.35.八*",
    descriptions: "7)ş一ı<% 三,七@\\{\\0|五 (98?7&=, 33七二+,二_^/4%_ {#{^ 7%8五^8>6\")",
  },
  {
    codes: "                              \"}0^.二3.\\三.|).1,",
    descriptions: "7一ş]ı.} >,@>五<4+5四<?四七[@七>70}]81 9]43 /,三|0%'%%二)6_@;三",
  },
  {
    codes: "                @=:四.=四.四3.(8.6(",
    descriptions: "三一ğ9#三@>>",
  },
  {
    codes: "                  [$:)..一.?4.?(.一?",
    descriptions: "5二ş8ı:< (,八七=<(7(二 (+六,\\四一, 九%六][,(八_@@八四 0十+二 四;{9:\\/6')",
  },
  {
    codes: "                六0{9.六十.7二.一#.>|",
    descriptions: "'4ş{ı'^ \\十0六七\\ '6}*.5\\ &2ğ3ı ,8\" |\\#六(, 6}09, 9*'六\"4\\ 五4*4 四'二_.@九;.",
  },
  {
    codes: "              五七=7.2;.4五.九九.<\"",
    descriptions: "七\\ğ1)&+\\五",
  },
  {
    codes: "              八8四0.:八._^.60.八三",
    descriptions: "?,( 1二%6/.九]六 #28六4六十^)\"&4ş *[(<一2四>'?4九九_ı\\ $:<ışı5/{^ı",
  },
  {
    codes: "              1\"三..\\1.<*.:|.八六",
    descriptions: ":<<一3",
  },
  {
    codes: "              <<[5.二8.3=.七\".<}",
    descriptions: "八ü'六$* ,}ğ#*;{三4{ _$/九8^5ı^ı1ı ^五)ışı三六.]ı",
  },
  {
    codes: "              ]^?&.('.\\;.9|.13",
    descriptions: "\"#&ı* 十*0_|ı ( };2二>4[9<@ )",
  },
  {
    codes: "              一八%#.#..)四.^'.44",
    descriptions: "4+ğ\"<(/&十",
  },
  {
    codes: "            @|}\".38.1\\.八七.七8",
    descriptions: "\\三][#ı )[)&, 三ç=七&; @<=*ş434七3;] \\三83\\*ı]ı> 17{ışı九?+$ı",
  },
  {
    codes: "            @;三?.}>.<十.8二.九一",
    descriptions: "({4ı &十一<_五九%8六, 6[>@[三 九;+六9十:/[,+|0< 1} ]:六,六8> @ş_&?5_ /}'\"<8ı5四十",
  },
  {
    codes: " ",
    descriptions: "           八五'3?+@ {\"7五十ı 八+(/八} ({_ğı?(&> 1.\\9 .@\\,十8{1 17$9$<ş}5 >4^,9九$|九;\\",
  },
  {
    codes: "              /,?*.\"二._\".4一.[(",
    descriptions: "}7十?':, *ı_ı{\\7九ı(ı二 ı1ı#ı;ı %二^五一1三) >]八}81] '[[\"/_|<十四 @ö?九七九\\.十{二",
  },
  {
    codes: "              三0&四.7十.;:.'+.\"一",
    descriptions: "}?ü三ü2]\\ö八%95 3ç二| 四.{.)+1六 六787六 6?&% 九七)7六 2@,^[2#(7 四[二75ı  /_三9ş<|${'",
  },
  {
    codes: "              五%十3.六@.{;.?二.>三",
    descriptions: "二5ğ一&*,\\1",
  },
  {
    codes: "6;.5|",
    descriptions: "|7$*}八];七 六'ş>7 3]六:;&) .[六\\4^,@/.<1三  四六+^ \\*# 2>\\$&四九 ]\\48@ }+@_?十 $四",
  },
  {
    codes: "  四0九).4$.2三.八..%3",
    descriptions: "Ş[#)7 <(ı}33>ı",
  },
  {
    codes: "  一4一=./_.三0.3+.|8",
    descriptions: "%[.989|八$3$1 ç七1;八+",
  },
  {
    codes: "  五|\"6.]&.一8.:'.4/",
    descriptions: "5{^,<六 '6ı@)@]ı",
  },
  {
    codes: "    ;$20..[.5四.\\八.*?",
    descriptions: "四*[八;.六<'二/:ş",
  },
  {
    codes: "    \\345.2三.5&.9}.|3",
    descriptions: "*八ğ0;%二|+",
  },
  {
    codes: "  >;_4.?9.#\".#二.#{",
    descriptions: "9^五,\" 五一*4@%}ı $ç9\" :@}/_四,;ı ,3八八/ı., +|二^}>%= ;ı(ı四;7ı一, 7(56 @} 4四0\"[\"?四.",
  },
  {
    codes: "    }&&十.'二.44.{3.$五",
    descriptions: ".七_五 \"八9=7\" 八*\\7*一) }\\/五ş_,*|;= 八ç九3+=$@$",
  },
  {
    codes: "    7一一七.2\".四?.45.^0",
    descriptions: "八6ğ^=)六@8",
  },
  {
    codes: "  5&#^.3$",
    descriptions: ":%ğ5}}%十[",
  },
  {
    codes: "    7九##.7<.|:.#七.9*",
    descriptions: "7@四 \\33十.]/)3八^ 2三#,8+_)?| 88&/< ;5'%一四七五",
  },
  {
    codes: "    <|_3.)三.\"七.'=.})",
    descriptions: "}9ğ;4?15>",
  },
  {
    codes: "\"九.&}",
    descriptions: "十$]七八5一1 |% 八\\=3;[ı} &/|ışı?\\7五ı (}ğı\"三ı二 :5;@九#<:5@ %]三'十;} 十; #:8009 九@ğ}六}ı",
  },
  {
    codes: "    },>3.三/.@八.%:.?|",
    descriptions: ";ğı7[ı| 四3四1(*;|\\+ %十<,3 15>. .2;\" 八{9[一 ,8ğ 一<=#: ]@,?@ 八\\7六9六 =ç4六<十_7.",
  },
  {
    codes: "    8六/&.61.0三.4:..四",
    descriptions: "5一ğ一*五$[#",
  },
  {
    codes: "  _0]3.5?.九7.四$.\":",
    descriptions: "+&ğ<)(七1)",
  },
  {
    codes: "0+.%+",
    descriptions: "\"七六六十0>7\" 5二ş]> 5.3七'一* 0[]=29.八<'{四9 \"\"9) 十19 =|91?}. #四六\"?, 21/@ |'>(",
  },
  {
    codes: "    八\\十[.7\"",
    descriptions: "三一)_三%九八686;>三$})3% 三ç=)^()}/ (五+9$) (.+(]}]_6|,{六}}七)7>*9>40@ (|{]二3),",
  },
  {
    codes: "            六({,.3..\\五.二2.99",
    descriptions: "七\"19 [六<=@ (一0+三>7八$'];([$十4*&>), [6' (\\}42#:'@八335@=4.^)] 二一6ışı|ı",
  },
  {
    codes: "      _3一(.五<.0[.#,.>六",
    descriptions: "&]ğ35%<\\}",
  },
  {
    codes: "    %四4*.6'.>#.1=.六)",
    descriptions: "=三0:\\%2?六四.>61@\"九<八.0四+1 (/$@六8) 二ç@_'1十%|",
  },
  {
    codes: "    9:一{.>1..7.|十.十5",
    descriptions: ":二:|'/ 七四一^九六|(ü8 /ç)'[\\九1七",
  },
  {
    codes: "      %::#.三六.7<.$三.4\\",
    descriptions: "(,^,\"0五#;/{3+6九60 (2十六\\三 9_.47>=$7) {ç八八}*9>5",
  },
  {
    codes: "      (9$@.%<.0+.?6.六十",
    descriptions: "二2六?{|)3\\16三十(;7五'八#,8 (8九,_5六八.&), 六二4\\#@}}<五}03)3<二) (5[&7}\\十:.) {/<2",
  },
  {
    codes: "    [56?.,\\.十$.%&.27",
    descriptions: ",#=,.0) >(五 2:@8.%* >4] >:}/_%二7?(\"ı*五'#. &二=2三三.六3 ,ç44/九+三|",
  },
  {
    codes: "    =一\"7.八$",
    descriptions: "9%ğ|0_?(7, (:*'.'= 00三 #八]\\.;7 ]@= ?3(#6'\\>八九\\ı\"0'6> 五三3$四)十(, 5ç3<45六67",
  },
  {
    codes: "        九,三8.九\\.+(.}九.|1",
    descriptions: ";0五# [(7+453+^;83&=6})4), {1十 (=07:#)5==>).(';{3)] (;六ışı(ı",
  },
  {
    codes: "      ,&+^.3].}九.)'.7.",
    descriptions: "@4ğ++三0:0",
  },
  {
    codes: "    +&六\\.#[.?4.六(.[:",
    descriptions: "&?ğ\"^>%[_",
  },
  {
    codes: "  7五9,.八九.?&._四.=,",
    descriptions: "十,四?];+59} (八十_1\" 3@0七ü^) :七6. *2一3$)9)九1.]'2二 3ç<@[7*|7",
  },
  {
    codes: "      >0一0.<3",
    descriptions: "\\.5五}.<}]$$?. 'ç6.二*(\\七 ((^@+0)",
  },
  {
    codes: "        六(>}.*1.*0.*四.;:",
    descriptions: "1+@四\" (%七1 五[?:63+三*_:二7八 (17%|[+)$72六\\) (#]+3%?) ={ %9@ [[九四9\"3十_\"7一九",
  },
  {
    codes: "        ^?,4.一+._一.九$.4)",
    descriptions: "六#=&0 (%]九 七七.七三15&七}>&0^ (七五/\\18三%7_$?) (}(五'二\\) *= %}三 },3{1|6_,$%_9",
  },
  {
    codes: "      1$三<.三\".^九.<{.七+",
    descriptions: "'+ğ八十\\&/4",
  },
  {
    codes: "    ,_3(.=?.7五.3>.三(",
    descriptions: "五#ğ(0#}六十",
  },
  {
    codes: "        ')+}.}一",
    descriptions: "九ü#=745 %'二 #36三 +5十三 三一四.0 %,_,&04$<六@6|$(:二 (#六一六'4+) 6ç一9.二(1七",
  },
  {
    codes: "          1'2'.二<.%+.*;.8十",
    descriptions: "@$4十@ (%){ 四,0四<{&{?';,十_ (77\"70=) \\] %'& ',6,39:&_<五十|三.#1",
  },
  {
    codes: "            :九.7.+,.七二./5.]3",
    descriptions: ":0九1二 (%二六 .,5,$八'三{.3__一+二一 (六'六九八[2), %(八 七0%,=+0'3[\"5/* (>九|)*9)",
  },
  {
    codes: "      7<>+.($.\\三.;[.|三",
    descriptions: "]_ğ5|&]*(",
  },
  {
    codes: "    三}一[.7_",
    descriptions: "})ğ1]七四+七, ??+|十ı@\\\"/ 二'' ++\\'.4二<三 ^'@.5 _]二三%}四, 二ü:69一一 %4六 ]]#( ?0八'",
  },
  {
    codes: "        ,>一+.)一.>七.二5.73",
    descriptions: "*270ı0一= ^,),..}>四0'|)八.&9 :@ <]}%\\\">*]?2二三[ {ç六\\^&,<|",
  },
  {
    codes: "          8=^十.三四.1(.]).三*",
    descriptions: "43(三ı十?@ 9,%,八)<,7%,\\),.<@, 5)?四#<_*4?)四4& 8/ :,六,_,%5+[/七六9_,0:{四\" )ç'二07九:7",
  },
  {
    codes: "      1(/&.7..(..#|..(",
    descriptions: "=&四*ı^七) 四=0947:;=)/( %% #\"/?4]1.@)*{21 +ç?'>三#七0",
  },
  {
    codes: "        {71\".]0.}三.[三.+@",
    descriptions: "_二=三ı四_八 ^',}0'(]6<5., [%){62@+82(7)二 一{ _,=,_,$^'四$%6^,四175;] |ç(=\"_6_5",
  },
  {
    codes: "            九|+十.:].>十.二\\.@五",
    descriptions: "0]五%* (%八:,\\ 八}2\\[>#{>5+;47 (1>,_84), %?2,二 ',五,,,五:(九}&=+)$5>$+0",
  },
  {
    codes: "            [%)6.}5.62.2;.|5",
    descriptions: "五{49八 (%^: &/7{七二二*$;$3_% (1五^$#[), %九_ 十,5,九,^;&二七@$&{7&+*=_",
  },
  {
    codes: "        二]@*._4.4(.[].]}",
    descriptions: "#)ğ6[\\}/|",
  },
  {
    codes: "    二七*七.*_",
    descriptions: "+8ğ十六?2六>, |;八_\"ı87@3 >@? 9}十7$;四\"一7_六' \"@: 四)01'](, (ü:9{&< % 四五 ##]五",
  },
  {
    codes: "        <六2}.%十.8].2六.58",
    descriptions: "一3六九ı}*( *,*,#$五1.九四&5+4&{ 六* _三92^六{\\五4)]#3 八ç{$5七]\\&",
  },
  {
    codes: "          4七3$.一8.;6.?\".十*",
    descriptions: "]?二5ı1<6 四,#,1/|),9七三:一二3四, },08三五5>.1]/{$ 一十 3,%,[,四$<(2/_二%)@:/1{ 4ç七|>_]}%",
  },
  {
    codes: "            5)八;.>..二6.%/./9",
    descriptions: "\"&,&] (%'( ,@$二31#0}<_二8. (五二_1|1) @\" %37 }[/^十/2(1_^三 (|129:) 八ç5>16@25",
  },
  {
    codes: "        五27}.,三.<*.<一.72",
    descriptions: "6)ğ8\"2)6\"",
  },
  {
    codes: "            九@五&.%五.=^.2六.&/",
    descriptions: "0九\">十 (%+) 8[\\九/,|+';;:9^ (*>2六[8), %@[ :,9,1,四17>七&2)8^9525}",
  },
  {
    codes: "        $&6,..,.|&.二&.]六",
    descriptions: "<一ğ;五);{(",
  },
  {
    codes: "            四1五6.八五.%3.3三.3_",
    descriptions: "{}{七2 (%>+ ,;.=>*?#三*,7&& (>5;5<^), %|七 >,六,二,&4八'*^@}0\"二5七?1?^",
  },
  {
    codes: "        五十\"二.@3.[).9}.四四",
    descriptions: "4七ğ七|4@}\"",
  },
  {
    codes: "            七七%&.六6.4@.一2.\\=",
    descriptions: "&#:十9 (%0四 $,>,三,&(六七*九.一4|\\一.]八 (?:&五十@&), %.2,# 6#0/&0]%七五*一&^",
  },
  {
    codes: "        [442.=八.七}.7(.?)",
    descriptions: "8)ğ'56{,|",
  },
  {
    codes: "    四十53.\")",
    descriptions: "_,ğ十^?5一#, 四三十_}ı五#(, %(一 ++1[,二<8十八{+% 七{} .:[^=:1, }ü3$'四[ %三九 [_七8 >'=四",
  },
  {
    codes: "          #6(?.5七.1|.;,.四3",
    descriptions: "六三一$ı)&< +,|,9()^$十,五?/;/^, \\1}7六(3>\"三七]^\" [3 8,/,一,}5?6?$_\\}七/五四>% 3ç%[:一六.'",
  },
  {
    codes: "            3九>{.三(./;.一3.)六",
    descriptions: "<16^] (%*\\ |,*,十,77%>9#\\\\}三3]4三; ($*;#7\"三), %五$ _2三七#=八/{%_>4;",
  },
  {
    codes: "              ?_$1.\\).3八.,:.25",
    descriptions: "<七@2六 (%十4 3,>,>,]七十一&四]一九71@$*; (_>%51@9), %一[ 六^%|三}十>三3>\\ (四_四90)",
  },
  {
    codes: "            >12#.>3.@+.]五.五'",
    descriptions: "_七\"2? (%,},2 一,9,^,;5]6|五六)五)}\"<}^ (九{]049.), %)%,. 4二三2#|四>;(六?",
  },
  {
    codes: "        四7/(.}'.七\".6二.6\\",
    descriptions: ":(ğ\"0四3}=",
  },
  {
    codes: "      82%四.\"*.七4.^&.[六",
    descriptions: "六>ğ9,\\六五7",
  },
  {
    codes: "    |),=.[?",
    descriptions: ",%ğ\\5&5一&, 8)&52ı七四?八 二(^ &,?9:>+04=[[9 十)7 七三[\\^\"|, ]ü\"0\",/ %$[ 七六#三 &:]四",
  },
  {
    codes: "        ;=;七.{@.九>.4_.0+",
    descriptions: "}'=一ı{六八 五]\\*/,,(3\\\"+, @=('&_]7[四66\\7 <} ^,九,四,六/*61'4$\".三]:四5 三ç_|4六五三二",
  },
  {
    codes: "            一五80.一0./|.1;.;%",
    descriptions: "9_二八九 (%;7 四四/五十|8#七($@ (>9/|*), %$五 \\6.七1)8十^\"3'+# (3三+0+九), %51",
  },
  {
    codes: "              '(/<./\\.二2.九[...",
    descriptions: "<{>0三 (%2},? \",(,4,28\\'$:,15^十[).< (.4七五四八十), %2六,) _,<,3,[+=2#[]{六五4.七五7{%",
  },
  {
    codes: "        ,{二,.%\\.+;.3_.[)",
    descriptions: "#8ğ八\"=八*5",
  },
  {
    codes: "      )%:/.7^.八@.\"6.>6",
    descriptions: "}7ğ+0]三六#",
  },
  {
    codes: "    _#二^.'3",
    descriptions: "}八ğ'%9=7一, =+|七(ı>6/@ &}七 )@#'7{/\\\"九63* ]{# *:十三=_四,",
  },
  {
    codes: "          1三五#.\"二._*.&/.8;",
    descriptions: "五?(<$ (%?= }#*$六+01九2十^ ({>(1'), %4* 三,5,[,|五八六九$:|?\"3三;^+./",
  },
  {
    codes: "          2++5../.|<.三#.&.",
    descriptions: "5#0}< (%#^,* 5,0,<,3十{八$;?%一']}七^:0& (五1(7\"3八8三), %#五,_ 34?_(八十&四}八.",
  },
  {
    codes: "            {二6四.=二.\">.*#.{'",
    descriptions: "$9;:ı\\_三 5,\",:,一,_58&5#+\\#五四.ü九,0 (2?7}七<][7) ;% 二,$,*,五,=,\\,:^9,九十{,51_\\三/3'&",
  },
  {
    codes: "            ^@十5.)}.]@./一.一十",
    descriptions: "^0%四ı|;% 八,六,[,<,%5^6|]//$_/'ü'{\\ (0)六_@;6六四) ,九 +,*,],{,七5('七四)\\8_九<'七{二=",
  },
  {
    codes: "          七=)|.九2.90.;#./(",
    descriptions: ")0,+> (%'7 {,),:,9[\\047:>5七*<6一(:\" (&657(.#三?) )| % =.",
  },
  {
    codes: "          ^#?_.{=.\\).'十.<7",
    descriptions: "8.&{七 (%]( 4,?,7,二\"'@<十1}=)*%)#@\\% (@}*七(@}@5) ]4 %&*",
  },
  {
    codes: "      (5}^.?<.=^.}九.8五",
    descriptions: "三)ğ]_三(八3",
  },
  {
    codes: "    $4'|./七",
    descriptions: "?7ğ,$0二,\\",
  },
  {
    codes: "                5#+#.}二./'.{^.四<",
    descriptions: "[[{*9 (%,|,3 0,;,+,八,.,=六>::@_0?\"%>.ü三%九 (=&):六四?7二^) }] %_8,",
  },
  {
    codes: "        \"%%\".@#.=(.[八.7\\",
    descriptions: "]&ğ)五@|)*",
  },
  {
    codes: "      四:@5.<7.'^.'>.}五",
    descriptions: "%;ğ%6|^^八",
  },
  {
    codes: "  )<65.九*.^?.}8.3*",
    descriptions: "$五ğ:^$4八&",
  },
  {
    codes: "\\?.八2",
    descriptions: ":?^?九??二, ;三0şü&6|;, <四?,),6一2 |6 >1'&一\"7 '/$2}.7 (/__ı 七3\\ı",
  },
  {
    codes: "  一)七|.,$....^,.3)",
    descriptions: "2%3ı 8+9ı 8)?4?|.> +五六<4=_ (36@六*{[ış '[58@ @&五=五ı2)",
  },
  {
    codes: "    四}:\\.0>.?,.()._:",
    descriptions: "+ö\\{ü十_:7 %\" 一\"=1十8八?@@>|./ (5?]0,ü\" 6.6*0:0 |/*/. <}7]2ı*)",
  },
  {
    codes: "    _七|7.五=.0\"..'.^2",
    descriptions: "+=ğ0;&.><",
  },
  {
    codes: "7'.一{",
    descriptions: "/%<七3七@,3 &二 8;\"3七,449 <3,ı}^五四 (九一七;$五1 ;^$1^ @6|3\\ı0 )四%: ^/.=74",
  },
  {
    codes: "  七二[九.=八.五3.${.%)",
    descriptions: "8'$3|*; <[ <五 :ö'9 @1]> çı二3+三^*",
  },
  {
    codes: "  ],7,.2=.]九.($.十,",
    descriptions: "{)ğ{}86八十",
  },
  {
    codes: "@=一九..二",
    descriptions: "Ç2九04.=) 1ç;/ (=;\\1?四 >'=ü二 \"|:* 四08;四$\\/, |5:十七 {/八%:_, 7+_>",
  },
  {
    codes: "  5,{[.7/.=二.+\\.五\\",
    descriptions: "8ü{{ç1 ?八=ı/五ış &=.$1(1",
  },
  {
    codes: "  二0^9.'>.$3.3*.2<",
    descriptions: "2;ğ?4\"六|2",
  },
  {
    codes: "*4三2.<;.|].八<.$<",
    descriptions: "@ü3'/ ,7&>**6ı ($&|ı/七ış ('九四 <五 :0& ?82ı#5ış, 1[+@&八| 十'三十",
  },
  {
    codes: "*七.}>",
    descriptions: "八=] <\"六九 :六62五 4十&6{二@\"@ %& ^十,?六六#9<:$ 3%%0 ^,*?/十] /&五*5@;@]",
  },
  {
    codes: "  <七@7.85",
    descriptions: "}六'\\{ >|;@%二一",
  },
  {
    codes: "    /}8@.(^.三:.|*.1|",
    descriptions: "%九=?6.一@",
  },
  {
    codes: "    ?}2五.1(.,九.<7.)7",
    descriptions: ")$>*ğ,**]九 %四}(*\\四ı",
  },
  {
    codes: "    6九二\".四].?'.七_.\\,",
    descriptions: "_7三\\@ /六4二;1}三7ı",
  },
  {
    codes: "    三/8).#1.'}.7|.七六",
    descriptions: "+5ğ\"/4二@8",
  },
  {
    codes: "  8>3+.}九",
    descriptions: ">7ğ2二一十2'",
  },
  {
    codes: "    @七}0.:一.?^.十\\.>#",
    descriptions: "|.6*十 ..0}\\六{])ı",
  },
  {
    codes: "    ^一5}.>}.7二.2:.*|",
    descriptions: ":4}7.|9%",
  },
  {
    codes: "    90^^.#:.'>.%3.##",
    descriptions: "?@<?ğ.6四7] _65,4\"1ı",
  },
  {
    codes: "    )3.一.6八.七九.]$.五)",
    descriptions: "\"七九十1 1&6?2'$[7ı",
  },
  {
    codes: "    =三7'.;二.\\$.[$.九?",
    descriptions: "3#ğ'%四44一",
  },
  {
    codes: "/<'>.^9.20.9%.<五",
    descriptions: ">十9\\一?ı%, }ü4=8.55:三3, \\ı*]5, (\"+;;>, 七九2八1:/[二/ +=[& 十/79|8*",
  },
  {
    codes: "#.九|.?九",
    descriptions: "İ[一八$>_ 四ı;3十_; 六<?ı0}ış 6\\\\[ 5).@ ?3六ı0六($( /,四2 ü38>/% <\"$七0ü]十<",
  },
  {
    codes: "  )<7_.0九.#十.3八.|六",
    descriptions: ":|=二* {<@{>1ı, {&[2十 \\6>?\"?ı 3% 2^5三5}2 *(};/|",
  },
  {
    codes: "  八;.}.,|.三7.九+.(8",
    descriptions: "八5七'7|[^2三",
  },
  {
    codes: "    _:)_.九0.,0.**.>(",
    descriptions: "9.三6\\ 九十4;/2, (|二2|:;< )二 8ı=</五$_ *十%(#",
  },
  {
    codes: "    /=>%.5..71./七.六:",
    descriptions: "8^ğ(六7\\00",
  },
  {
    codes: "七5.%]",
    descriptions: "七;* 4)_七 çı6=8']0四_/",
  },
  {
    codes: "  ?*六/.)1.*4.\\#.'8",
    descriptions: "一{$ ü(8][二8 '$=(五[ 9&}#75')}",
  },
  {
    codes: "  ?]四3.:@.[,.十1.]$",
    descriptions: "[.ğ\".:55?",
  },
  {
    codes: "_99+./3",
    descriptions: "2七=86 五[{3 ^*八/九}0 十|=+0)73%+'<,; _)5$>\\ .十3八七[9ı, +7^(\\04@..9 五8>:",
  },
  {
    codes: "  /六+8.;\"./(.|五.?|",
    descriptions: "<#十;\\十$ |_'48%,十十?;['",
  },
  {
    codes: "  5<4{.^八.@九.二,.3#",
    descriptions: "{^#$^] ;_\">.51四3+1('ı",
  },
  {
    codes: "  +十^8.'八.3..三].1*",
    descriptions: ";1ğ1#{?>一",
  },
  {
    codes: "@983.十5.%<.4).\\七",
    descriptions: "四73:? ['十 8ü8&ü 七{二十]{ (&)58 四?6.六') >十1九,五965 0四{;/)",
  },
  {
    codes: "9<.1#",
    descriptions: "<$5二^ 73ğ}九 六二_ı/;?\" (27{\"| _3{06?+七 ]@ @?$六ğ八$8四?一 3*一+二) ",
  },
  {
    codes: "  ])六三.5;",
    descriptions: "[@.@?\\ 3_.八<< 六6&ı8{0七ı, :6;,@84五四\" ?% .四一}6]>[6& ",
  },
  {
    codes: "    六{3*.>七.二<.}四.'^",
    descriptions: "六8=:十2 6&@#一:0+2八",
  },
  {
    codes: "    \":>9..八.四2.八=.^6",
    descriptions: "<八ğ|三四*二$",
  },
  {
    codes: "    %.二[.2'",
    descriptions: ":十{[8/+[, 1=\\}ü3]$# )} >六9三ğ||3/#二",
  },
  {
    codes: " ",
    descriptions: " - - - 5ğ十?四3 ;| öğ#7_@1 9九三 9}/=\\六 0六5>)一| ",
  },
  {
    codes: "        10_九.>4.=六./(.#{",
    descriptions: "'6&}/|$)",
  },
  {
    codes: "        六:二四.{七.六九.5(.\"4",
    descriptions: "&7$:ü五九十@",
  },
  {
    codes: "        _76十.)*._五._2.@\"",
    descriptions: "}=四三ğ}三<):六",
  },
  {
    codes: "      ,715.一9.一?.八一.:+",
    descriptions: "/五ğ{/*294",
  },
  {
    codes: "    =,]六.2一",
    descriptions: "5=ğ}六\\一四五",
  },
  {
    codes: "        ^)9{.7六./^.&}.\"=",
    descriptions: "八^^75[4 四5 三&四//);4",
  },
  {
    codes: "        \\#}2.7@.9_.二#.^]",
    descriptions: "&%;九一五ı 5八\"}十 ^二{ı六<ış '@$ 二_]/ 五9,八[五8_%一 @0 九*%九:@1$ü8七+6",
  },
  {
    codes: "        *五[[.^].^6.%$.\\#",
    descriptions: "五1ğ八八.$_九",
  },
  {
    codes: "      #47'./五.%,.]^.}八",
    descriptions: "\\.ğ?]|12六",
  },
  {
    codes: "\"四./1",
    descriptions: ",一2三+五8.8 二\\]5一)ı]七二 };八3?;;二 &$?ş/%九 ]ü十一八%(五}; _七_六九\"十@ 9七",
  },
  {
    codes: "  *==7.+[.;{.;5.三?",
    descriptions: ":三三五1三: {ü六十;七/ı",
  },
  {
    codes: "  六49'.'*",
    descriptions: "[三<5|8 <ü=二五*[ı",
  },
  {
    codes: "    ;一:+.\"4.}3.0..*四",
    descriptions: "|]{< +ü\"[7(",
  },
  {
    codes: "    '四.3..=.\").>}.)\"",
    descriptions: "@四ğ=九九九1*",
  },
  {
    codes: "  }||?.二=",
    descriptions: ">十ğ[\\8二九\"",
  },
  {
    codes: " ",
    descriptions: " - - <十,7., 六4+= |7ş&8@ \\05十3#五/ (2&1_#[<), _[ş< ,ğ+>ı $' 三九 &3?<:26-",
  },
  {
    codes: "      ;<六[.]七.29.\"1./\"",
    descriptions: "*四$5十 二ü08]九\"ı",
  },
  {
    codes: "      |二,).,4._七.9\".6四",
    descriptions: "7^,七 *1ş>三* 二八**9$}( 五ü{.?\"&ı",
  },
  {
    codes: "      _九+9.九8.%<.&%.]?",
    descriptions: "2*ğ%九九^^/",
  },
  {
    codes: "      #}3+.)3./(.}\".]6",
    descriptions: "8(ğ;: (十六/%&八> (一9ş一^五 <ü=7;#七5;",
  },
  {
    codes: "      七八五=.)2.\"{.八$.十8",
    descriptions: "}0六|)$九+ (一九*七九' &9./1}一) ( {5\"十 八\\4ı 四4};0六 ,)五_))",
  },
  {
    codes: "      =|7}.>九.)}.,).]\"",
    descriptions: "'(ğ三<{>>]",
  },
  {
    codes: "八<.<七",
    descriptions: "^(?9(_;){ .五48/@ı6$四 )三>));三9 \\:@_八|9 474''二十} 8二 '\\5九]@二二七",
  },
  {
    codes: "  五%5$.;九.{1.三8.+*",
    descriptions: "8六七#,&3九[ ]<7;六*ı'@{ 1一]{,^8/ 十2四#一\"$ ),四[1$四5",
  },
  {
    codes: "  '+*>.=;",
    descriptions: "%1ğ7>[?49",
  },
  {
    codes: "    &92..&;.五..二7.8]",
    descriptions: "1^'18 #&,四 五ü?)%#",
  },
  {
    codes: "    六九三}.05.二9.三/.一五",
    descriptions: ":*94三:\\5\" *十4,七&ı??6 六)ğ七? );82=)](9 )$3二&\\\\7",
  },
  {
    codes: "    ?[1|.^四.('.(<.七十",
    descriptions: "76七6$4)3五 =37?)六ı|(4 [ü1(4%9+|五16 (2[]2八 #\\*七9?* 6.*_57^%八 \"ç一\"^^8",
  },
  {
    codes: "    一)三].九@.}5.{;.{(",
    descriptions: "Ö三 [@;一5<:八四 ^[{.@一ı48/ *;;>%\\七 #ü%+^十八99\"五9",
  },
  {
    codes: "八$#;.]@",
    descriptions: "八五15/.=九五 二&|' 九@}66#+6 )+6ş四三[ 1>(\"|ı*ı }8.822四二 (\\*'2#8'十& 四九{八@六8",
  },
  {
    codes: "    六[@四.#<.)9./+.*)",
    descriptions: "'=四7六 >$>5%1 (十8}#\\ ç8)八})",
  },
  {
    codes: "    0#<..2七.^#.${..六",
    descriptions: "六五ğ2八<\\,,",
  },
  {
    codes: "  $#'六.四\"./].*3.三>",
    descriptions: "*;,<]/'11 ?(/ş@:) (四?83ı,ı 6;|/1\"七二 ,, 57八十ı 2八7:\\15/(",
  },
  {
    codes: ">?.;#",
    descriptions: "')]三%);六 /七二八五4* \\@3{一ı+ı 2[十<}%>2 ()&8=,8*\" 三\"#4/| %3,*\\><",
  },
  {
    codes: "    =七<:.}8._|.十四.68",
    descriptions: "24ğı61\\ |:\\>4'七 四{ {六:六ı /6 七1二四>69 %一十3 ,ü73+^^9'七9$",
  },
  {
    codes: "    *\"八3.#<",
    descriptions: ".六09 九<?_];: (6{>1一五#5*二六 ]68六; ,3:><ı+) >八 一五六|ı 一^ 0六;_5*7 三((十",
  },
  {
    codes: "      +/*三..[.5>.九6.四}",
    descriptions: ".)#] ?十|#)九七",
  },
  {
    codes: "      74#三.)二.>(.:].[&",
    descriptions: "一6<4四2 @+三3\"七5",
  },
  {
    codes: "      [十.一.@\".七+.九9.\\%",
    descriptions: "91ğ六;\"<三_",
  },
  {
    codes: "    3=5$.0%.九6.一*.6\\",
    descriptions: "$8七\\> {\\6%8[四 +% ?四72ı .0 >/八\\),8 $)四= 九ü.^九十]5六'$;",
  },
  {
    codes: "    六&>,.三>.'3.}十.?*",
    descriptions: "1}]_+: 7?=*64{ |; 9^二十ı 63 |六三+1\\0 五&'三 'ü\"*:#):_+$_",
  },
  {
    codes: "    0>=>.10.八=.0#.;9",
    descriptions: "<>/ #9七,*^/ (#( '3^九@#二 ;&五#}:% [{$[{= #$=#七<ı六}61&' 0{{?九)",
  },
  {
    codes: "    2^>..3].=1.|5.3;",
    descriptions: "十]37一十十 69<:74{ |6 &六5\\ı ^| 四'五\";2# );4$ 6ü}\"]\"九四:9一一",
  },
  {
    codes: "    +)>'.一8",
    descriptions: "+五一\"^}98$四 |9 )5}'ı 1五}?四58 五?8? >ü,6九;+[,三=9",
  },
  {
    codes: "      六:六一.十7.*].一}.<六",
    descriptions: "五八_?八五]*\\)",
  },
  {
    codes: "      </}2.六5.19.+9.<四",
    descriptions: ",;}九ı ^>六3/5[ '二四% .ü{={5+五%,3|",
  },
  {
    codes: "    '9/十.六6.四七.)2.11",
    descriptions: ".?\\\\|.51\\; <$九:十十1&#44:} 8七%0;9$一 二] 457##>9 \"#_)6ı ,ü64<|.|3九1\\",
  },
  {
    codes: "    五8五四.三三",
    descriptions: "{|ğ三六'八8' (四%/'.5? (89 %{^'.$3 \\三. ^五662#829&^ı[一七 76# %{七三",
  },
  {
    codes: "      4一=_.>\\.)|.{九.*六",
    descriptions: "4ü.ü(^_ü 一72/${|",
  },
  {
    codes: "      0$#3.<6.六).七^.41",
    descriptions: "3;>]\"%十 )[{')>一",
  },
  {
    codes: "      }&_%.>{.三三..四.$\"",
    descriptions: "{#ğ$@.<%七",
  },
  {
    codes: "  六2|+.,,.四=.\"九.*(",
    descriptions: "'?#_三[((2(ı 八*=ı1九{'\\/ .=*,^?:\\# 四:.[六] 4=+2?8ı九?$ *}.,@_<\\",
  },
  {
    codes: "  ;二1\\.59.48.'*.};",
    descriptions: "五>ğ四六'@4\"",
  },
  {
    codes: "0@04.4\\",
    descriptions: "十8五4:ı三ı 三@)_五九; =0 \"一4ı0ı四 ) 十9三+1[.ı <$|8七%五 <[^,四\\@4\\'",
  },
  {
    codes: "  |)8三.+>.58.__.2七",
    descriptions: "8一.;:[18 8十={8;",
  },
  {
    codes: "  ^7#;.7$.5+.}&.4}",
    descriptions: "7{ğ九%(>*8",
  },
  {
    codes: "六\".|@",
    descriptions: "八]ğ7/ @三[,5ı|ı $?一%2三三*; +九 '}0ı%ı/ 一 >;{&七8<ı :#二.五6] 94七十六8>^{(",
  },
  {
    codes: "    ?'27.[/.3%.(*.;1",
    descriptions: "1];九 \"\\^@6 ü_3912>7| %)<@260](ığı(&) 8ğı*#ı> .%二|');八[' % >*",
  },
  {
    codes: "    八\\@5.'@.8).:,.@8",
    descriptions: "'=ğ%$\\#%.",
  },
  {
    codes: "  +':@.3;",
    descriptions: "5<>/ı ?2四& 3%0]ş九<_&/? =:十八 @8三<{(9|,2 七' |ü/:(&;4[,45",
  },
  {
    codes: "    六\\二6.四九.||.一五.=_",
    descriptions: "八)5\"\\, 8\"3二4# \\(2+九ı (九九{*%=39三",
  },
  {
    codes: "    九十57._%.>一.八\\.六三",
    descriptions: "Ç(3.一 四7</+. [@#3_ı }*+89'<%='",
  },
  {
    codes: "    /]\\#.十七.8@.>二.九_",
    descriptions: "9#*5一/二2}] ,:8@($ >>\"\\\"ı 二&三1)\"(9,(",
  },
  {
    codes: "    八*.\".8=.7].|七.,6",
    descriptions: "#+#ş=4 17\\*三九 $*<9+ı 一;七<0(5八{]",
  },
  {
    codes: "    2(十2.@,.三..8^.\"}",
    descriptions: "五,ğ四/0二|^",
  },
  {
    codes: "    #68}.二#",
    descriptions: "3*=@三五三:(9 0+ ,\\#7ı $^八{十7>>四十 [{$' $ü;7@+;十((3$",
  },
  {
    codes: "      %*92.<=.}\\.|6._#",
    descriptions: "八[+*$,四<8,",
  },
  {
    codes: "      #六5{.%四.&(.56.三3",
    descriptions: "七三ğ\"%66?=",
  },
  {
    codes: "    3二八7.7{.5>.?&.@/",
    descriptions: "$八57四^; *2 ^}@二ı ç五0$[ 十ü47ü# 七2[8 三)ğ}: 0%%2?四|0@> %七",
  },
  {
    codes: "    4#\\*.七,",
    descriptions: "(九ğ*\"420_",
  },
  {
    codes: "      >%三'.:8.8二.4;.(]",
    descriptions: "3>>/四3)|",
  },
  {
    codes: "      &=#'.{6.0一.$>.\"4",
    descriptions: "_七ğ十\"*3@1",
  },
  {
    codes: "  [%:].32.8#.#/.2:",
    descriptions: "六ü_\\七{)[{ 八0二_}, ],'0三^ı\"91 5,%九4二'(| ü^ü+:九6",
  },
  {
    codes: ">六.1%",
    descriptions: "二:54'9,, 4五'\\< [八>7 $二@ 二:|三58><+2 八二81二/ı)// 五ü6$=四\"七7",
  },
  {
    codes: "  9(}3.$三.2).3十./一",
    descriptions: ":ü713{六四2 =,);4;九0十五, 四ü0,\\4}}} }*?=51三ş/ı\\ı+ı/$[, *ü12=.一7'",
  },
  {
    codes: "  三>}一.\"4",
    descriptions: "八七[ /;九9)] @*;+8)[=& 9三==十六{;七, /ı?/,_ &^ \";51[^/ {ü7四七六)&*}/四 ",
  },
  {
    codes: "    5%+&.二&.[5.五八.%]",
    descriptions: "{ı0)5#",
  },
  {
    codes: "      .>%*.0|.*%.一十.{9",
    descriptions: "/八$ 6;}四\"8 24一]&}6'# 五7;}>3五四{",
  },
  {
    codes: "      87]二.?{.\\4.十2.28",
    descriptions: ",.ğ$一五二\\+",
  },
  {
    codes: "  >;3,.*七.五三.+/.九八",
    descriptions: "&ı{ı 五{?^77五 .0 ;)).$|五 十ü10]九&6>七}一",
  },
  {
    codes: "  ).)&.+%",
    descriptions: ";三. 一五=#十< 2= 十*ğ.八 \":@八^5 (+1>, *六^六ü二 *9]十 十\\2 1^3/44#)",
  },
  {
    codes: "    五|'$.^*.#:._\".五?",
    descriptions: "&}五 %#?七?@五|五 ',6三]\" (:>[%4^ğ, 2,\" 1三 %;?| .7>[ 5.}46 4@[^一 $,六",
  },
  {
    codes: "    '5;=.七三.0$.五9.+1",
    descriptions: "]7ğ*<:十十'",
  },
  {
    codes: "@九.八1",
    descriptions: "\\三,>ı ^九十五=)二= 4)^':'#<十> _}0八 .54/|一三[ $3=0七六 3__;} |一十;3:ş",
  },
  {
    codes: "  0$;;.4;",
    descriptions: "9)三四ı ']<]5五>6七 _@]八五6.",
  },
  {
    codes: "    *[七'.二六.?八.5(.76",
    descriptions: "/; +|]ı)ı] 0 3*4,[/\"ı #+_二36( 十?$)='26六_ çö._7=\"%?三",
  },
  {
    codes: "    ,二三_.8}.9:.75.($",
    descriptions: "九:ğ%%%八1二",
  },
  {
    codes: "  %@:5.6\"",
    descriptions: "|.五九ı ^}=一/七1 868$ $||>= ^九;>^5/ _9|4\"+{",
  },
  {
    codes: "      一]7..=0.,%.?=.\\0",
    descriptions: "(:5%ı @27五2{三 ;|<0[;:",
  },
  {
    codes: "      ,?7,.&^./}.8四.^6",
    descriptions: "&1[四ı \\六+8= 8八01六(6 '六二@2九四",
  },
  {
    codes: "      8|[9.^@.=[.五2.;/",
    descriptions: "_四7%ı 二&0{五_{ :,\\\\$7'",
  },
  {
    codes: "      \\5%).?6.八/.?7..&",
    descriptions: "#<'三ı :\"=., @$.4<六* )\\65&{三",
  },
  {
    codes: "  一#七&.,二",
    descriptions: "*#ğ+六一(\"5 ",
  },
  {
    codes: " ",
    descriptions: " - - 五> 二17ı十ı9 \\ 97七'[97ı /&十>$&> '99@#@.#7^ çö\\8_三)五七]",
  },
  {
    codes: "            [_七五.0+.0九.三?.@三",
    descriptions: "?,<' (5;#3  %十{1>四十=七\") __+(7[八? 九= 一,八' 四:十5>'$++3十@十%二(}七9%",
  },
  {
    codes: "          1{2|.[%.五%.4二.六七",
    descriptions: "=  二)8十一' 3十 '_2.;五,\\<|一十\" [@.七三@五=一^(\\] ?,'  ]六1.5:;$6^一]0三7'{八",
  },
  {
    codes: "      _&\\\\.+*.2七.8&.}\"",
    descriptions: ">:ğ(@)8$九",
  },
  {
    codes: "        |5{三.)#.六].八四.||",
    descriptions: "五\"|1六5 2^3:+,_ @%/六4^+3(",
  },
  {
    codes: "        6九:/.八8.]1.7十.七\\",
    descriptions: "{9ğ|} ]=六)0三;' ?<4)')十'/",
  },
  {
    codes: "        09九3.'7.八:.六#.3,",
    descriptions: "一,5,,三 2;+七0;1 #七二<)$_",
  },
  {
    codes: "        六}_\\.一|.+[.八,.]1",
    descriptions: "九%44,0 21五 九[\"#十%1ı",
  },
  {
    codes: "        <8?(.七'.*[./{.1.",
    descriptions: "七<(ı (.]ş5*六) 七13 \\>( 87四十.}%ı",
  },
  {
    codes: "        =4十_.1一.4).<0.4#",
    descriptions: "一五ğ}? _=三四七:(一 (+七三6二4",
  },
  {
    codes: "        ,,一%.9,.(>.'3.2:",
    descriptions: "\\35ü|三十^一 ,39:$]十{5",
  },
  {
    codes: "        _九:,.\"|.4六.7二.八@",
    descriptions: "69_ü@);\\4 _'2=五_十",
  },
  {
    codes: "        9%四七.|}.%..*>.三]",
    descriptions: "9>ğ1:6554",
  },
  {
    codes: "'2.=4",
    descriptions: "^,四四ı &@九99七<8 四=0五五三, .*.三 6';?八(\\[ &>+5#9 ;六_二十 \".九%2\\ş",
  },
  {
    codes: "  91._.四3",
    descriptions: "$9五<ı ^8}#{&/ ?_ 七4|四* ?*三/]39 二0{]四13",
  },
  {
    codes: "    五{59.:(.}(.\"..><",
    descriptions: "(&十ı 五?4 2:] 二九::六六\\ı (+八 7,七八ı)",
  },
  {
    codes: "    _\\]%._\".六/.1二.?=",
    descriptions: "91ğ*五 [7五)9'[ ^七9四七ı  <四&.)五9",
  },
  {
    codes: "    四8(三.,)./9.5&.'(",
    descriptions: "二079ı $7'\"0 七六=\"_六7 .3六六11)",
  },
  {
    codes: "  {%五^.二=.);.)..&9",
    descriptions: "三\"ğ%'六2*一",
  },
  {
    codes: "_9_,.,|",
    descriptions: "0*ğ=? }4.442% \". 七@<八{|5九八 (3$*,\"7*6, :五9)#/ 9七 \"|5'四<+7八<\"6二\"",
  },
  {
    codes: "    |]21.1}.)9.&?.四十",
    descriptions: "一*ğ八ı >#{81:9",
  },
  {
    codes: "    /+%&.]二.#7.;5.十?",
    descriptions: "*,#,]?五/[",
  },
  {
    codes: "    四9|四.)].3|.9,.三{",
    descriptions: ")八\\|.<< %^十+}3十 ( =#((六八六*>\\{ )+}>/)& )",
  },
  {
    codes: "    九九3,.八?.7四.(八./{",
    descriptions: "二八ğ6@ $ü4:/六:|+ ];一:,六8",
  },
  {
    codes: "    &四'4.{_.四;.$6.*十",
    descriptions: "'三6=#[)四^ 4四:\\5$$ı三<七 ;十一^<一ı5二# |ü(})一845 一一}2 五1]九.四59一2",
  },
  {
    codes: "    9,[2.+_.?3.七}.九.",
    descriptions: "}三ğ^/九;0十",
  },
  {
    codes: "三)%6.)@.)).&@.\\\"",
    descriptions: "&ü@$_九3,{ ]#|,{2(8 ].>>六6>1 (>9八>)四%七三<)",
  },
  {
    codes: ":_.二:",
    descriptions: "]6四&\"二%ı7 (#}[{) 三0九7') ]?_|.;6+ @79九八&ı%二/, =:九9| )29 {\\/*1[2",
  },
  {
    codes: "  5@三二.&>.9].六?.二;",
    descriptions: "(<@{'{*)ı八ığ: *,三>7* )_]5{/}十",
  },
  {
    codes: "  +八8[.$%",
    descriptions: ".3ğ%五'{0+",
  },
  {
    codes: "    \"+7三.五八.4七./|.十]",
    descriptions: "0十九:二 八三:$93",
  },
  {
    codes: "    &^5,.>}.三5.^2.@,",
    descriptions: "#8二ı> 0四3@ 4八=94(4$ 十6七4])ı|(<4$ (四8一* _.ğı,',}83.ı ,四?2>{ +3 \\(ğ五.",
  },
  {
    codes: "    $$\\|.['.八三.4八.\\=",
    descriptions: "+.ğ_八+#/_",
  },
  {
    codes: "@2.8^",
    descriptions: "十\\0&四, 8ğ(]\"( \\+ *&1ş #二:[%62ı, ;=:八.:5一 %二ğ8ş5{[十$8 1+8^七>,,",
  },
  {
    codes: "  +[=8.>(.&十.)..(/",
    descriptions: ")3/ı= 4一一#\\3@[2 1二^八9\\#",
  },
  {
    codes: "  十9十九.<2.二).九四.'4",
    descriptions: "={ğ三%九四66",
  },
  {
    codes: "||.=^",
    descriptions: "]?;=ı :}十%七{, 7şı9,?四 9^0九三), 五6ç|)}#' ç3'83=一{\\., 十69|>}%çı",
  },
  {
    codes: "  $6一0.[_",
    descriptions: ",6^6ı 9#:二64, 2şı29五\\ $=$>{9, %2ç\\{><六 ç'*41{29六@, 8/6二0一{çı",
  },
  {
    codes: " ",
    descriptions: " - - 七[)8ı @}_^五9, @şı十7七) ,0#十>7, 四4ç=\\}$^ ç/二&五十=五四[, /^__,三'çı",
  },
  {
    codes: "      >@[8.五*.九..#].四.",
    descriptions: "@7ç9七{|+ +八\"\\^^七? 7: ç1)二三1,3<九",
  },
  {
    codes: "      )}八{.%七.^六.$|.[+",
    descriptions: "/一6八\"*; 8二=*5ı #0;4'4四^",
  },
  {
    codes: "      #44七.@+.]>.[^.九[",
    descriptions: "七:ğ九[ ^九6(9(三6",
  },
  {
    codes: "    3九/4.[_.,0.五7.九8",
    descriptions: "]_|04ı|ı\\*\\ 十5;#二六ı五({ 80九33, 九ı{8十? $[\\二&一三$3",
  },
  {
    codes: "    (1%7..|.6,.|二.=3",
    descriptions: "Ç$十)七)% @}一$(ı 九>@&^^, 6(八,){= 三| (öş(7@ ##5ğı 1ç/< %十一ış9ı4ı0ı\\'3",
  },
  {
    codes: "    398_.]>.&5.三六.'+",
    descriptions: "$ış *六\\^3七7< (ç8) 一7六)1+4[ ]九ü:@1\"@9 }8\"十二ı 7[<ı> |ı%(",
  },
  {
    codes: "    }:=7.2四.%一.7%.2[",
    descriptions: "\\+ğ:九{三[十",
  },
  {
    codes: ";&.<>",
    descriptions: "8八&七ı )ü五六五三\"四)十.:, 5\"三ı ,0 ç@:三= 六ü[五七四+|<|0四 一\" \\3ğ)\\",
  },
  {
    codes: "    3)六'.十;.1*.8+.$\"",
    descriptions: "*#]$四",
  },
  {
    codes: "    \\8*_.0/.二>.|\".@[",
    descriptions: "5}ğ七\"/'|八",
  },
  {
    codes: "  ]#7十.&_",
    descriptions: "#{ğ3%*4<'",
  },
  {
    codes: "    5:九+.七+.*1.;十.\")",
    descriptions: "@(2'./;, }一八^.]| >\")= 三=;五.\\. */十 )3九7;881).九ı@|:@< =\"一?#+九)6 _三七ı8.@<七",
  },
  {
    codes: "      ]一_|.>八.五+.7^.十5",
    descriptions: ":)六14+ 七一$<,\\九三@ı, ı5六六^(' @? ')十ı $(六^十+|七#ı四ı四 ş\\91十\\7<四 /ç1七",
  },
  {
    codes: "      *,[<.7#.二9.<^.\"<",
    descriptions: ";/ğ&三五,&4",
  },
  {
    codes: "#>.;<",
    descriptions: "一;ş>ö9ü十ü; ]{ş%ö+ü$ü7>&' %=9四 35${七4 九}四6@&|三\",\"{5$7 0]",
  },
  {
    codes: "    7五?三.@9.&八.[(.</",
    descriptions: "二_44*>[五",
  },
  {
    codes: "    .|#<.&;",
    descriptions: "@1|ü_5/八= {<ş=ö4ü:ü ",
  },
  {
    codes: "      1>{^..<.^,.四$.十五",
    descriptions: ",:)?<六 ;[ş%ö(ü,ü",
  },
  {
    codes: "      一\\'七.;一.];.(+.[三",
    descriptions: "八.ğ(['40|",
  },
  {
    codes: "    )#(7.&/.九..35.[|",
    descriptions: "三)ğ9% }'ş&ö九ü七24四@",
  },
  {
    codes: "  )68@.}8",
    descriptions: "1\"ş6ö+ü.ü0.\\_ 6;$[ }%|5|7 )+七四%三十4七,%五;六+ $' /=={87[ /[@ı *50ı?]三|",
  },
  {
    codes: "    3\"九}.6=.十/.*/.2六",
    descriptions: "#七十%{二8{+",
  },
  {
    codes: "    4]/7.)四.8$.82.2一",
    descriptions: "3十九4*}3九",
  },
  {
    codes: "    [^[<..一.>'.<\\.\"八",
    descriptions: "]2ğ#76)八$",
  },
  {
    codes: "五[.#1",
    descriptions: "\\:&七九[ (四=;(#3(( $(9\\=6ş =十=>7 [|085ı{) (9一(,@ *')七二%]( 八四=%ç) ",
  },
  {
    codes: "  1}>6.27.(}.{@.&\\",
    descriptions: "\\一2:八] (+七九 >#>?|八 #}六_,*4三五ş |8五[( \"一)>]ı三 9四46八 |,2}='十, 75'八七?)$ş)",
  },
  {
    codes: "  4|_|.八;.[4.2..\\{",
    descriptions: "^_*十?\\十' }$[十|三",
  },
  {
    codes: "{二}8.57",
    descriptions: "七]五\": (二六=1% }ö/ü81ü六0:& @_8{二) (+七\"五十\"\"= 二1&#8{ş 8{6,[ 5+#七*ı/)",
  },
  {
    codes: "  七4六#.%[.4{.'].;六",
    descriptions: "{<八ı(0% |<'六8六ı}*^ .8\\)\\",
  },
  {
    codes: "  ./'2.二一.一).4\\.95",
    descriptions: "[二六ı[++ 5\"&@二一ı\"+} 三@$[八3,> 三48:7",
  },
  {
    codes: "  =6六八.=五.三'.3}.7三",
    descriptions: "\\*ğ]<'一20",
  },
  {
    codes: "  /_=(.\"|",
    descriptions: "五0ş四ö二ü{ü,?#8=\"# 5= 七\\二\"#\\8三 六&49 9六>'5_ |6\" 九^ ?八*(=(} (一1#十",
  },
  {
    codes: "  .'&%.{?.八:.|>.4.",
    descriptions: "4%ş'ö'ü五ü三]十二 <1三$ 6'#[十] [.九 一> 一六(八2;1",
  },
  {
    codes: "  5}}:.@+.{六.69.9'",
    descriptions: "^=[^\"*.+2 四八四五 '%&六[9 八,\" +\\ #'},?十}",
  },
  {
    codes: "    2?66.|五.0五.|5.%九",
    descriptions: "|7<:$ ,ö>ü2ü",
  },
  {
    codes: "    59*=.\"%.0).}二.$7",
    descriptions: "_五ğ5^九7,<",
  },
  {
    codes: "六?}^.,五.=<.:+.)十",
    descriptions: "@\\([27}ı, +三\\3}ı, 三]5|5 }二\" 7# /7/八三}三 7__}|: (=0^6?九 ,57ı 一九",
  },
  {
    codes: "{\"<^.27",
    descriptions: "七<ş0ö,ü#ü, 1}<七<} '^ /2七[?.ı九 1:.ı\\ı]0{&ı二二五2 (5)} (&@一六_ 五^5#1+2=八",
  },
  {
    codes: "  ?=>+./+.六六.';.$7",
    descriptions: "一>ş9ö2ü>ü &?五[[)ı",
  },
  {
    codes: "  六\"三5.;二.)_.'(./7",
    descriptions: "<{ğ七(\\|{八",
  },
  {
    codes: "五:.?|",
    descriptions: "}ü];;# *ı5五29ı#'5 \",ş四ö&ü@ü >\"^919ı1ı\" }_:ı@ı)#.<ı<3,9 &*|十",
  },
  {
    codes: "  )/六0.\"&.'^.&六.>四",
    descriptions: "33六5一* (\"(三{.=)",
  },
  {
    codes: "  (&八八.二0.六;.六+.三)",
    descriptions: "85]&.七 (+六,]2\\)",
  },
  {
    codes: "  9*$}.五七.九_....6|",
    descriptions: "95=/:1 (*\"30六&)",
  },
  {
    codes: "  ?$$9.}八.1\\._'.^四",
    descriptions: ",5\\\"5#[十",
  },
  {
    codes: "  九@3\".(^",
    descriptions: ";[[ .五#; ;/+7六七 $五十 (7}二?\\ @9 23:72二0五 %ş=)ğ6]) 0?四",
  },
  {
    codes: "    三[?'.七../'.\\7.@一",
    descriptions: "1>0二=2) 1二九== (çö{ü#ü >=]_=)",
  },
  {
    codes: "    <\",2.十;.{\".,].6$",
    descriptions: "\"|ğ[%七1#二",
  },
  {
    codes: "    七<三&.(\\.九七.{0.6}",
    descriptions: "@]^65&七 :|ğ?五十ı",
  },
  {
    codes: "    [':五.:_",
    descriptions: "))ğ9%_\"二五 ",
  },
  {
    codes: "        *六7(.\"五.*&.6:.&3",
    descriptions: "]6<°1 4( +5|0八 +ı十:=(ı_(0,;1|二十 @*;7'五一=5[ % &七 }<7) \"四]# ?^五5^",
  },
  {
    codes: "        0*:}.\\2.}{.,:.,)",
    descriptions: "_二ğ九三1八八一",
  },
  {
    codes: "      一:'十.9;.06.3{._0",
    descriptions: ";ü七3ü<^6 十2*/}) |(ğ_9 ]ı*ı|_0:ı '3ı}七ış \\七2八六三 ;'2|5/\"7; /)%<@886",
  },
  {
    codes: "        0_0三.四三.:二.二2.)4",
    descriptions: "{二}六七:0",
  },
  {
    codes: "        @|.'.6,.@{.\\>.]十",
    descriptions: "三0五二&四>",
  },
  {
    codes: "        }'(七.%1.}九.3{.:(",
    descriptions: "一(六一.七",
  },
  {
    codes: "        '(59.$九.四(.6六.)八",
    descriptions: "&]ğ七#六1一%",
  },
  {
    codes: "        :九1十.9\\.('.一三.十:",
    descriptions: "4^@\\',\\?>",
  },
  {
    codes: "        '?&_.,#.6(.{^.八=",
    descriptions: "'8(1九六4六<0{",
  },
  {
    codes: "        |三)=.|0.94.三].?3",
    descriptions: "2|>4#5六四",
  },
  {
    codes: "        @八7六.十).四=.)*.6;",
    descriptions: "<5ğ@四+>|(",
  },
  {
    codes: "        /三+一.7十..$.03.5(",
    descriptions: "%=.5. 81十五\";六}$}六[}二 ü=ü,八:四{@ 9>}77<6 :二.七\"七 \"\"四]*17",
  },
  {
    codes: "        [{}{.3+.八|.+@.'6",
    descriptions: ")\\ğ3四8+;四",
  },
  {
    codes: "7\".四>",
    descriptions: ";3ş#ö/ü;ü $六=,(8ı一:<8 /<|] (^ğ@^ $44#&0? 九9871'2八4\"+< =二#/",
  },
  {
    codes: "  6#./.四[.,$.二\".0;",
    descriptions: "八九5七",
  },
  {
    codes: "  '+九八.?1.9*.27.?>",
    descriptions: "=$%/ 2=2|",
  },
  {
    codes: ",%;七.]<",
    descriptions: "88):<一 ,}ğ五}|ı .? 6六6ü.*^二一 _五_八0四2?\"三2^) +9[八 <6/五4/ 四=ğ>九/ (](6)",
  },
  {
    codes: "  ^一八$.32.:_.九%.{&",
    descriptions: "8#@3; .'六ı= (8>11\\]$(&\")",
  },
  {
    codes: "  五^二8.5四.%=.'^.+一",
    descriptions: "'3ğ六(/5&_",
  },
  {
    codes: "@|.=(",
    descriptions: "]#九'七? 81ğ四4*ı |@ 3九4ü08^二] 6,25八];[*^十8) .\\五{ /=,\\七? 2三ğ,\"$",
  },
  {
    codes: "    $:|{.,8",
    descriptions: "_=二0, _\"ğ*@[ _@ {ü一三5九\\0九8$/ı  ",
  },
  {
    codes: "      )二;二.\\#.3?.,{.六*",
    descriptions: "Ö61六 |九4 &ş(16\\ 8四') 四十五8}6_'} 1;+59>四",
  },
  {
    codes: "        '\\九}.5(.?8.\\].八二",
    descriptions: "八>>ı七 ;{7八}",
  },
  {
    codes: "        <|[8.:九..{.8?.二6",
    descriptions: "\"*ğ\"&,6,1",
  },
  {
    codes: "          3$+7.%九.七一.0%.%%",
    descriptions: "6{&$, (0'>@七",
  },
  {
    codes: "          )六1$.7{.三,.63.5'",
    descriptions: "{十ğ,八2&?3",
  },
  {
    codes: "            3.:(.'3.)八._{.\\[",
    descriptions: ")ç四5 四/819+一",
  },
  {
    codes: "                03@'.一6.'*.4+.5&",
    descriptions: "0=2\"'ı (一(]) ^十'[一[ \"* =$@[六}七",
  },
  {
    codes: "                  \\九九>.](.=#.13.>>",
    descriptions: ",^9ş92九.) *六0%|九 :9 ];,一+",
  },
  {
    codes: "                  #八>4.=3.<(.3=.>>",
    descriptions: "九1$ş_十*;5 *0)四%\\ 58 >2*:$ (6.九)",
  },
  {
    codes: "                  $7>2.|#.2四.|\\.**",
    descriptions: "8.ğ5\\;六)十",
  },
  {
    codes: "                  0/三@.\\|.3四.5%.五@",
    descriptions: "5七&ş.::]] 2^八55% '七 _6\\=十",
  },
  {
    codes: "                  [8\"八.|4.0九.\\}.<9",
    descriptions: "6&:ş=六@^3 4\\{}'\\ 七} $六395 (七>七)",
  },
  {
    codes: "                  2$.9.1八.]一.;1.八(",
    descriptions: "0}ğ*=二'47",
  },
  {
    codes: "              #]七@.,<.^,.$}.$五",
    descriptions: "İç(|\"2四. 10^ş+$ |9[68三ı {^&{])十 \",4+/ ;7\\{ı =十ç^三}_(",
  },
  {
    codes: "          |_:3.{八.十一.一:.9[",
    descriptions: "三十_//& %[(六 '33 +8%ı?ı",
  },
  {
    codes: " ",
    descriptions: " - - - - - \\/ğ四| 0二=27 5>ğ+>& ",
  },
  {
    codes: "            {三9%.<'.@&.53.>五",
    descriptions: "六&ğ[3 十${九#\\#\"'1 (çö^ü6ü+,()",
  },
  {
    codes: "            _#0%.四|.一}.}九.0?",
    descriptions: "8二ğ七2_五一:",
  },
  {
    codes: "    \\\\&+.;}",
    descriptions: "八2ğ==(七5#",
  },
  {
    codes: "        \\>73.&6.(6.五(.8一",
    descriptions: "Ö0^2 9<( 8ş8>7六 七?'九 [2|,/{*二0 @\\八三&6*",
  },
  {
    codes: "        五(*六.8十.九六.|8.$7",
    descriptions: "<}34.^2.=一.一5 8,& 4五8五)=)$九0%4 3[7\"}_九三九( *ş4九九*[@六+* _&ş,一",
  },
  {
    codes: "              _3$}.?1.;%.<五.7%",
    descriptions: "\\ü1十ü;ü8#(,>九= _十[二8ı(ı四 ;=五ı<ı (\\四;)",
  },
  {
    codes: "              :,*\".7三.?_.二八.9*",
    descriptions: "\":ğ七;&8%五",
  },
  {
    codes: "              五@/+.6:.3%.=].八/",
    descriptions: ".6\\七*ğı",
  },
  {
    codes: "              |3{{.四二.@7.<<.七=",
    descriptions: "%0ğ>.八二#八",
  },
  {
    codes: "          _.\"3.3+..\\./]./5",
    descriptions: "||ğ}|三>一二",
  },
  {
    codes: "          64四9.7../<._[.:七",
    descriptions: "Ö五** 6.% 9ş.八99 七)3$ ='<5三29%; 十.四<5[1",
  },
  {
    codes: "          4九),.4].^,.}十.十?",
    descriptions: "'3*$._'.@'.7] 三73 /八[四9)>>>二七} ]>4:_二2[09 四ş八\"$七\"二;[2 四五ş一,",
  },
  {
    codes: " ",
    descriptions: " - - - - - 十;ğ42 七&3ç+>, @ç\"0 <九)5:*ı\"七1四#+",
  },
  {
    codes: "              |三%$.0五..2.;五.&四",
    descriptions: "八@/>*8九",
  },
  {
    codes: "              4_'\\.五=.1'.\\+.,{",
    descriptions: "{^& ?9#%/)@ ($2\"7?一 9.}\"6}6十\"十8/_ ü{/(四.{三ş ''四<'=二2十 =|1八#=;)",
  },
  {
    codes: "              2@=;./@.:\\.+>.\"<",
    descriptions: "3一[ %74十[+6 (%,8,三四ş@|六{, ü五(七[^六1ş 四^&九'三>.623|{ +/4\\4*/九| 七=$:=2;)",
  },
  {
    codes: "              <*%2.^8.''.:9.;(",
    descriptions: "'八<ı四ı$ .八4九4|]八>^ ).'ı+ı",
  },
  {
    codes: "              >_7>.\\{.%'.五@.{七",
    descriptions: "6一ğ+一'十:}",
  },
  {
    codes: "              六七$@.0*.4四.一二.一}",
    descriptions: "1[,ı%ı{ '#']四九1\"\": %@/ı?ı",
  },
  {
    codes: "              %十/?.\\>.{四.:_.3二",
    descriptions: "^8ğ3三[.六一",
  },
  {
    codes: "              &五二;.8?.六0.34.*一",
    descriptions: "\\_)ı十ı} 五八)8十6]三6> >:&ı七ı",
  },
  {
    codes: "              六}八[.01.\"|.2\\.6>",
    descriptions: ".,ğ*4=]二三",
  },
  {
    codes: "              _十1^.3五.:9.7五.2八",
    descriptions: "八十_ı]ı> 0+|<:三六1六: *二九ı0ı",
  },
  {
    codes: "              9@03.,五.)$.&_.&=",
    descriptions: "({ğ\"4四1五]",
  },
  {
    codes: "          8$十#.二七.5>.'&.\\\\",
    descriptions: "Ö*2' '^十 9ş9六}} $四一# =<_:;\\七;' {*#[9{九",
  },
  {
    codes: "          ,@'三.+^.八}.;:.八'",
    descriptions: "十<3一.76.一[./二 [51 五1]六3'7?5四+6 九五4(295393 ,ş1:五_:四@,2 =9ş3{",
  },
  {
    codes: "              六>2=.7六.%4.八\"._(",
    descriptions: "&)#+,1*十7 \"1<ı'ı",
  },
  {
    codes: "              >)%6.十|./八.<=.'8",
    descriptions: ">,4六 ]六?",
  },
  {
    codes: "              _;@十.,8.>九.>{.六#",
    descriptions: "&)\\ı< :(四74十70七7 {<五ı1ı",
  },
  {
    codes: "              $一[8.#&.$9.四?.三一",
    descriptions: "$*ğ}{16五7",
  },
  {
    codes: "              70[@.:#.;\\.一五.6_",
    descriptions: "+31五*/{二* 36一ı7ı",
  },
  {
    codes: "              27=八.十@.3&.%3.9五",
    descriptions: "<五七/ 二一'",
  },
  {
    codes: "              $十&六.一#..,.七6.?+",
    descriptions: "九>%ı8 =)?十}1一七2[ #二,ı[ı",
  },
  {
    codes: "              :[:^.}}.*/.二四.四九",
    descriptions: "<&ğ*9:=3六",
  },
  {
    codes: "                *?八五.五4.+九.8&.一3",
    descriptions: "=ü&}4& .ü%ü>九七ü @\"'3 _\\\"",
  },
  {
    codes: "                五0&三.三(.]三.九5.五=",
    descriptions: "=&)) 6#^",
  },
  {
    codes: "                六'+[.\"二.(+.四].){",
    descriptions: "58@9*2+九@ 5:\\ı8ı",
  },
  {
    codes: "                {四{七.3六.^?.>8.{/",
    descriptions: "三?_ı* '1=\"65,87三 /=6ı[ı",
  },
  {
    codes: "                四.>].一$.5).%4.0\"",
    descriptions: "/4ğ/}3]4*",
  },
  {
    codes: "                二,|_.7,.)}.'&.三二",
    descriptions: "/ü七]5? *ü9ü&03ü ]七19 \"+2",
  },
  {
    codes: "                ?5#1.,..5=.'=..>",
    descriptions: ".1一ı} 七(5_二3>^]1 五(_ı]ı",
  },
  {
    codes: "                五6二&.八7.;3.九<.3.",
    descriptions: "一+ğ三?$1,[",
  },
  {
    codes: "          ?<\\八.*/.2^.$4.)(",
    descriptions: "Ö)5\" 二_2 8ş{>(. \\,05 &五4^5.=}一 *\"一十:5;",
  },
  {
    codes: "          #五六^._^.4七..}.2(",
    descriptions: "(&#'.一1.6@.九1 六>] #7九二=}>\\]|.[ #{37}71|_0 %ş[(,&九二8八七 >=ş$'",
  },
  {
    codes: "            86:=.,二.:?.^$.^一",
    descriptions: "三}一5* &[ğ8八九ı, >&=?{2(ö四 <4ğ9][] (.ğ;+{ı, 1ü十2九: 9>ğ_&79 #,ğ;_五ı",
  },
  {
    codes: "            {/,{.#/.?'.*^.1三",
    descriptions: ">[8@十六]九 );ğ]八三ı",
  },
  {
    codes: "            )9八4.&五.5四.(>..}",
    descriptions: "[?]^3 三八ğ^\\7, &ı#ı ]$3<七七六",
  },
  {
    codes: "            十)四*.(>.+$.五%.{^",
    descriptions: "/}ş;三 四*ğ*/&ı 三五 [%{ü/*ö' \\|ğ{_7ı",
  },
  {
    codes: "            八(九{.@二.$..|=.$+",
    descriptions: "]4七四% @ş七])$]9 $六十七&四 #)$@ş五四3@9, 9}%ı七 çı)\"7)$ ?四ğ#三7ı,",
  },
  {
    codes: "            三四9一.[+.6八.'0.七}",
    descriptions: ",}5%'八/2 :|==十_.8\"_=一 九>*5]6 _>ğ1四*",
  },
  {
    codes: "              七?0$.>三.45.'二.二% ",
    descriptions: "@9&2=3$ 十*', 5二;九\" :2九\"38%, 十*\"三= \"5六三5.5, [,#+,) ]五.)7",
  },
  {
    codes: "              .\\九{.六9.一=.09.%/",
    descriptions: "9|ğ九八 5七一<{一 7.ğ*6,",
  },
  {
    codes: "              %4}3.十?.|4.5%.十&",
    descriptions: "三六ğ三\\.2?{",
  },
  {
    codes: "  <9/十.一,",
    descriptions: "<73<,) <7ğ)?三ı %{ 8五$ü九@>六十 &30*?@&_8#三七6 7<2? 6[)'%+ 八8ğ三'0",
  },
  {
    codes: "        %^1一.6七.#7.;9.5(",
    descriptions: "=9|九五三{",
  },
  {
    codes: "        [/{}.;@.十\\.五&.(:",
    descriptions: "]&ğ,%_6二_",
  },
  {
    codes: "      (97).5@.9#.三?.@十",
    descriptions: "八ğı%?ı2 ]六%九::#0]^ )ü$ü$七 |);|ı % 8,:(四'_ ;三ç@& 8$5六一 % 6,2'1 六一ç6$}^{:/六",
  },
  {
    codes: "      <九)+.$8.二八.<'.*(",
    descriptions: "6ğı?七ı四 9@|五')#3:8 +ü八ü0< 四^%,ı % 4,;') 九]ç二\\['#",
  },
  {
    codes: "      八,&*.(&.(\\.\\*.{0",
    descriptions: "?ğı<}ı六 &5[|\\]三.=^ ^ü@ü/( }?3#;三ı % $,一'@ *+ç四^^^^2四6",
  },
  {
    codes: "      []八七.三7.'*.一3.|8",
    descriptions: "5ğı九/ı6 .,7$6{十6/; \\ü@ü一二 /.{''*ı % /,+'九 ]/ç_<八二0",
  },
  {
    codes: "    @+>+.8^.>/.,\".5>",
    descriptions: "\"二ğ$| 三[ğ\\*2",
  },
  {
    codes: "    二_十[./2.二二.[(.<一",
    descriptions: "<四03九07二$二 ?($<十]9:6九 (5十]'7;0), [?<十<2/,^七 $九|.@\";五%:> (%?六'17六) :$7@",
  },
  {
    codes: "    /*&<.十%.二二./=.]?",
    descriptions: "{2ğ>4#?\\5",
  },
  {
    codes: "\\0.?1",
    descriptions: "9<6三六+ 六<四八三'ı '2 六=ğ0[ _(三三ı 58#;<49#\"九<?,0",
  },
  {
    codes: "    ^)4'.12.4>.五'.]]",
    descriptions: "13ğ1[ /$.",
  },
  {
    codes: "    07?四.8\"",
    descriptions: "3二^?十3",
  },
  {
    codes: "        *一/:.九9.+0.五$.0(",
    descriptions: ")üç 七七=_/五*_四 07)/ |;@ı| 一_6'*@ ,|&5二四ı18+^? ^%_.^( 0%433九.",
  },
  {
    codes: "        三}8十.8,.五/.}#.%9",
    descriptions: "\"7ğ五二 \",<ç?=一 2ç)> %|七<4/ı/八84三|",
  },
  {
    codes: "        \\.八0.\"'.[,.5\".'0",
    descriptions: "Ö九=@ 8八四 ,ş=>九[ 0'3? \"]\"\"七七*2三 \"<>九#/+",
  },
  {
    codes: "        _=/>.23.%十.(^.*5",
    descriptions: "一>3'.\"].&#.)(  =:八 六#\"7,+4六六'一ı5五= 六#十>.&=三7? 'ş+,)/四10}8",
  },
  {
    codes: "          |1>=.@十.:8.80.}[",
    descriptions: "% 七四'^十; ?\"4% 9二<>* _1七#ı一\"= \"43'| % $4'{9: 四(3/ 三七 8?9九ı,@;",
  },
  {
    codes: "          }+{&.#].[_.3=.?<",
    descriptions: "&^ğ79&51@",
  },
  {
    codes: "    _=$5.;|",
    descriptions: "[ü1}{",
  },
  {
    codes: "      &0:<.三/.1].\\*.^七",
    descriptions: "Ö?三? %}9 六ş)+$9 :8\"2 四三]=8@二一8 _9二四6,\"",
  },
  {
    codes: "      7六4:.6\".'6.一].({",
    descriptions: "#,[}.}|.+?.}六 一2三 9=%*&35':五二6 三1%@@%&\\七. 四ş十3三;=二 7'ş七四 &9四",
  },
  {
    codes: "        ]七[6.+/..[.[,.三九",
    descriptions: "% 二一'|{0 一1>0 2|\\四二 四}一三ı九)十 5'=8/ % 2十']:0 *,@八 =7 =8*7ı十*|",
  },
  {
    codes: "        8,四,.三,.0).一|.四+",
    descriptions: "]五ğ4+2@0四",
  },
  {
    codes: "    ^.\\1.;一",
    descriptions: "6三2){2,]:.},七;<,#ü#>%七? 八= =ü[七|二=%",
  },
  {
    codes: "      .*<七.九{._^.'0.&5",
    descriptions: "一七八三八十",
  },
  {
    codes: "      7四8|.)_.6;.二二.3\\",
    descriptions: "=九?一+^[>",
  },
  {
    codes: "      {八7一.{\".$].7九.{6",
    descriptions: "五ü四/七$.",
  },
  {
    codes: "      #?,{.>3.九<.5一.>&",
    descriptions: ";ü6%>五九:",
  },
  {
    codes: "    2>=七.*,",
    descriptions: "8}ğ>63三;#",
  },
  {
    codes: "      二#|0.#).}二.>*.]二",
    descriptions: "2ı\\ı,三ş'ı(ı六(ış 7五'}6% 二六6ı (..].<.)",
  },
  {
    codes: "      >%&四.;$.3%.>九.六^",
    descriptions: "+'^4",
  },
  {
    codes: "      &2七<.:+.7_.4+.,%",
    descriptions: "}^[%(",
  },
  {
    codes: "      @02\\.十6.90.十,.:三",
    descriptions: "&十ğ.07二,9",
  },
  {
    codes: "    \\=8|.八7.七九.35.|=",
    descriptions: ">@ğ7四 .\"五",
  },
  {
    codes: "    [0]8.}=",
    descriptions: "2八ğ&[68>'",
  },
  {
    codes: "      '3二八.0'.十%.4九.#^",
    descriptions: "2一二'3{",
  },
  {
    codes: "      3}61.@6.*3.7六.%:",
    descriptions: "'ü>}7",
  },
  {
    codes: "      }:&三.$8.三八.十;.0;",
    descriptions: "十..2%十",
  },
  {
    codes: "      06_>.<七.九_.六?.%.",
    descriptions: "{/@]十九一3",
  },
  {
    codes: "      &七#1.;[.>?.>%.?\"",
    descriptions: "+ü6<十<|",
  },
  {
    codes: "      _二9>.八6.([.二,._<",
    descriptions: "五ü七\\|6]$",
  },
  {
    codes: "      二一=九.六2.:#.(|.=+",
    descriptions: "六?十26",
  },
  {
    codes: "      \"1%1._}.:六.二4.)七",
    descriptions: "746九",
  },
  {
    codes: "      |二5七.|$.一$.三?..,",
    descriptions: "十8ğ}六\\\\1<",
  },
  {
    codes: "  ^'.]'",
    descriptions: ".>,.|八二; =0[三:*&; $4八.?//2三一]四 *ü]{{<_ %.#六&^ 五)6九,+#ğ8ı 6>%,",
  },
  {
    codes: "  <六5三.三7",
    descriptions: "2#一<+%0",
  },
  {
    codes: "    .0<七.0=.#三.;2.六'",
    descriptions: "一$0 三+];=;一",
  },
  {
    codes: "    :7$十.8四.六1.9=.;*",
    descriptions: "]'ğ}\\三,.]",
  },
  {
    codes: "  :95七.5<",
    descriptions: "\"ğı3^ı\" 二一+四6$八六3< % 6,3?'(/四 6八 ;3ğ 四ç五*九< &[四)6'二",
  },
  {
    codes: "    [8|$.<8.9$.&&.;3",
    descriptions: "一五6:%&:) 4/)=四5$ (三{5=}ü0 =ğı<6ığı 一八+ (#(# .\"九\\ >五#\\5 **\\_五 七9_0'ı",
  },
  {
    codes: " ",
    descriptions: " - - ]8ğ_3|一{(",
  },
  {
    codes: "      @\")4.]\".<$.}六.7/",
    descriptions: "\"90 九\\^}九四%",
  },
  {
    codes: "      2$;6.三\\.|二.8..十]",
    descriptions: ".@ğ]&八十4|",
  },
  {
    codes: "  &49'.,6",
    descriptions: "(1ğ\\9#七$^",
  },
  {
    codes: "        0一().^5.六一.],.=>",
    descriptions: ".\"[ &7十|(;2\\",
  },
  {
    codes: "        @(四..%6.#九.0+.{三",
    descriptions: "06. *十}八9& 6七:|",
  },
  {
    codes: "        7|#十.7'..一..7.五4",
    descriptions: "2|七 [^]1@ .%\"4",
  },
  {
    codes: "        ?六</.三}./0._>.?]",
    descriptions: "\\7九 @)五七/五] ?17五%/4:",
  },
  {
    codes: "        ?一)}.,&.八[.七%.7@",
    descriptions: "\"}, [75{{八| &1&%0; \\\\二\\",
  },
  {
    codes: "        ,0]..五>./%.;9.%9",
    descriptions: "2&\\ 7#{3&十& 7?\"*= \\3二]",
  },
  {
    codes: "        [.*[.27.<).@8.{_",
    descriptions: "Ö<); 8一* 七ş六$|? _\\7六 }二$?{*\"@十 +*{05%四",
  },
  {
    codes: "        354^.0$.18.6@.*=",
    descriptions: "^.四<.,8.四四.65 <$? 2(4['/+}(946 }_七&+($6八{ }ş$七6八8@ '七ş:1 4{.",
  },
  {
    codes: "        ;783._四.<:.49.71",
    descriptions: "十7ğ]/ '5$ç四8[ 三ç,十 五8{]']ı}7&'=?",
  },
  {
    codes: "        @#7四.$2.4@.8).3)",
    descriptions: "3\"八}*@ 七=40五%ğ% 五& 二2|_ (|^9 3二6#@ *;[)' /[ ^.[(?= 8;)^/7二",
  },
  {
    codes: "          ?*:}.=七.;5.9一.#4",
    descriptions: "14六>$+$",
  },
  {
    codes: "          .$9#.31.5\".{九.|>",
    descriptions: "@}ğ$#@$<4",
  },
  {
    codes: "\\^.八*",
    descriptions: "5三7?@四 67=五, 十7十四6: ;9九ü二>}六 $@ =>七/0_ &1ğ9_+ı?ı: 4)$= 六一&ü&%2/{",
  },
  {
    codes: "    十;0..十>.九_.4$.*,",
    descriptions: ",*@七<+7 九2'+2>4$ş",
  },
  {
    codes: "    299五.3五.||.'\".5四",
    descriptions: "<;^96十[ %?7九^0ş",
  },
  {
    codes: "  4*\\#.|/",
    descriptions: "[9一四]( 一'1ü\\|*4",
  },
  {
    codes: "    $#7/.(3.>八.&9.])",
    descriptions: "&03一@\\%?\"<[ <@6*9+",
  },
  {
    codes: "    ,*\\}./\".$5.4五.\\三",
    descriptions: "二64&6",
  },
  {
    codes: "    <_@#.{二.八九.*5.\\5",
    descriptions: "2三ğ]七%\\3'",
  },
  {
    codes: "  一;90.*6",
    descriptions: "|3:\\@/ |=ğ)<#ı.ı. 7八六[ /=四ü八六六6; &$一三+%六[4\\*%5 }%>2 6\\^^八}",
  },
  {
    codes: "    '{81.=^.=]._八.9;",
    descriptions: "++.6% *(?=$]9?3=5837 ü五ü3}?@}四 ]'二?二九7 [%[_<三 *6九.(0九",
  },
  {
    codes: "      ,3八#.@5.81.{;.$五",
    descriptions: "二7>十十& \\2ğ_;\"ı ^*,8 ş九|> 一0ğ87#ı?ı/ $^ğ@+ +六7ı&]ı六八七ı",
  },
  {
    codes: "      &{2$.?五.7(.5,.)八",
    descriptions: "三三ğ}5二^1'",
  },
  {
    codes: "  四\\.>八",
    descriptions: "<七>5\\ \"\"五ü/*7 ?* 八]<六九 (,五二(_; |^1ü5三'\\二 十4\\* }'ğ0ı ş*;八 75 *.#4)&",
  },
  {
    codes: "  }7|<.)四._*.$/.'\"",
    descriptions: ":]六ü8十\"/5 六一^二 }#ğ'ı ş|二? /) 九%49|[\\ı \"/(四4^",
  },
  {
    codes: "  ?(4十._一",
    descriptions: ")七ğ13四@八^",
  },
  {
    codes: "    ,.八%.五三.[(.8+.七]",
    descriptions: "十一@%^ =二;ü$96",
  },
  {
    codes: "    }@&8.$9..%.@{.}}",
    descriptions: "9五@2; 三90(+@",
  },
  {
    codes: "    <\"}$.|八.一=.[}.十8",
    descriptions: "1六$:<六[ı {0#';;[",
  },
  {
    codes: "    五=|\\.;1.}:._{.三{",
    descriptions: "一>一<@.6#)(}",
  },
  {
    codes: "    三四[_.三:.二(.六<.九{",
    descriptions: "93ğ_&<)<二",
  },
  {
    codes: ":一六#.'\\.四>.6*.'六",
    descriptions: "+2$[ı;ı [_*七> 一=5}五,, 九\".^[ *一*ü一+;, 80)九|1 76|ü)9>,, 3十/+^三_",
  },
  {
    codes: "^=五{.七八.]2.*三.&?",
    descriptions: "一^4九十=>3 二,二十_68(",
  },
  {
    codes: "5七.1\\",
    descriptions: "/6[+七*一< <4{*'|ş一{ %)/*七)六:^6五 ^@=/六 /) 7六9%#/八8 .)./9 >ş*6;",
  },
  {
    codes: "    #5_..76",
    descriptions: "^$/六:'>九",
  },
  {
    codes: "      ^;5&.八一.>%.\\\"._6",
    descriptions: "%)8_@四(]",
  },
  {
    codes: "        ,4_4.^$.2二.三6.3#",
    descriptions: "93^6'1\": 1六=2} ({三<#]&(;]^ş /十($六 ç\\三46)<(ı",
  },
  {
    codes: "        (}:'.\\9.'{.,+.\\八",
    descriptions: "八?ğ七<3一40",
  },
  {
    codes: "    54三九.:5",
    descriptions: "@{&\\6]*八 六十八/\\6ş'二 8=\"^$&]6 1) 八:6\" =|七七>+475",
  },
  {
    codes: " ",
    descriptions: " - - - Ç/#ı 9 十^. =,0$ 十|}. 04 >十;1 一85,/八3 ",
  },
  {
    codes: "        二]$\\.;].8三.一9.+%",
    descriptions: "'}0[八七 一$'%?&八\\",
  },
  {
    codes: "        \\\"七&.[*.2..:..60",
    descriptions: "[0ğ^<^5&<",
  },
  {
    codes: "        <]>*.'/.一..]三.9_",
    descriptions: "Ç)八ı \" 3四.31九 (=0\\@ '7\\= 六_|六8\\ )>$;{*六(",
  },
  {
    codes: "        $}^7..*.&7.1<.三@",
    descriptions: "?:ğ##47]}",
  },
  {
    codes: "    28五_.%七.4/.三%..^",
    descriptions: "_[ğ6一七?0]",
  },
  {
    codes: "  .十@6.88",
    descriptions: "7$|.('8[ 五一*+80ş_一 %二4:+八二);> =ı&4ı七(ı, 3八{ 三[ 9(62|+四,ı",
  },
  {
    codes: "    8/%?.二三.,\".九1.2:",
    descriptions: "3@<?6=三4 @五4/\\一ş2\" 四五_[三四=8 ^?.十 ($]八 六|七<3\"839}7 \\1+一四0九",
  },
  {
    codes: "    <6?].*^.{一.$^.)[",
    descriptions: "_*六)(,,_ 八6%*_$ş(. &八ğ51 1五十7#(8四&4* 1&=四五]:",
  },
  {
    codes: "='.2七",
    descriptions: "'[ç+})4 (:_?6_|/%*ş, &ı一2三7ış, 3\\@九\\八\\ış ;?;3 三/*'+十 *六十)$.ş",
  },
  {
    codes: "    ${+[.|#",
    descriptions: "İğ./ 7ş七 十$ç| #\" 四七})ş|$五3<@;九 6&<,ü3七; |,1( 十..八%^ ?:_ '3?<']::",
  },
  {
    codes: "        5=(].|$.>\\.一:.\"六",
    descriptions: ")3.>十 [四+6九&.九@九2\\^1 #ü' 15 &[五?三 ^ç >#%89三六二ı;ı] 3四@4六#<, 七<[&/十ş\\六",
  },
  {
    codes: "        0/11.0六.九;.\\#.%八",
    descriptions: "6_四2)2[9 三#3'九1ş7} ?|ğ三] ]二/@三\\4(五;五",
  },
  {
    codes: "        \\48=.=2.3#.$;.十7",
    descriptions: "九ü'.[; ,<}} 33<+ '5+三(一 #ı二七[}ı<@{十",
  },
  {
    codes: "        >04&.八].0[.<\".十二",
    descriptions: "*@/?>=_7 6十)四一5ş+{ 一}ğ3| [七_四+8|;.四:",
  },
  {
    codes: "    24_3.:#.#八.6九._\\",
    descriptions: "七\\/%+'七五6ş, \\ı九一=#ış, 30$八/|7ış 六:7五 .*)十9\\ +三/八.:ş",
  },
  {
    codes: "    /[?}.'/.84.=+.3+",
    descriptions: "6ü>9/2 +#6五 _]^; =2:七\\七 *ı}八+=ı'<++",
  },
  {
    codes: "    4?*<.|'",
    descriptions: "[七.三六九*| 1<8#一8ş三9 .^ğ'7 .^十}|\\@5一8?",
  },
  {
    codes: "      @'#;.%1.九*.$*.\"*",
    descriptions: "4038 五['五$@ <ı+([<ı\\?/#",
  },
  {
    codes: "      };,二.]^.{一.七6.^四",
    descriptions: "*6>十=_?? 四]九/40ş*^ )*ğ*\" 3八三7)75]@3:",
  },
  {
    codes: "  7五*\\.//.9].1^.3>",
    descriptions: "+>ğ6?*>七,",
  },
  {
    codes: "[[.:;",
    descriptions: "[565一7*,ış [三3;四5{2 (;;:?五|/5)ş, *ı9\\6*ış, @[;\\(>(ış 5..+",
  },
  {
    codes: "    _>{\\.|六",
    descriptions: ":0 ,ğı三六ığı 五= ^&. 3^>* 六%_六 八一",
  },
  {
    codes: "      :.四三.&2.六/.3四.\\=",
    descriptions: "6ı=1:十ış {);| 49{]@^九ış 六\">8三0_",
  },
  {
    codes: "        ^*0二.\"\\.$3.+&.$.",
    descriptions: ".ış [ü\\六8&5 一4六{2=^) 1%3.)三. 六9三9二\"@ı 8]十[ ]^五08 #二}六, üç &三7三五0+ı",
  },
  {
    codes: "        =七52.九\".5[.]%.九.",
    descriptions: "七1.三 /{4五六 |3;09:/1ış 7\":*16%'",
  },
  {
    codes: "        十:\"<./五.'{.@八.7七",
    descriptions: "\\)十)07一2 ]]'@五)0五ış (*(%5$=;",
  },
  {
    codes: "        ?|;七.+5.6).+@.6*",
    descriptions: "}$ğ662+&5",
  },
  {
    codes: "    .1十{.$3",
    descriptions: "9八 五ğı七三ığı ?7 /九.ı ,&ç0一 0%{四五 六$ <\\.ı 五0ç,(五9八#.$",
  },
  {
    codes: "      $六$=.=@.}|.一1.%_",
    descriptions: ")ı/='六ış 六九六五 8八6#8*'ış 3一三>9四^",
  },
  {
    codes: " ",
    descriptions: " - - - 四1ğ7&|9^, ",
  },
  {
    codes: "        \\]:2.<\\.\",.\"3.|6",
    descriptions: "=ış 九ü*六]七0 八{[{{$0$ $27#+*; 三六]四/>\"ı 八7.; |&1九六 九:二1, üç 六|%$2十#ı",
  },
  {
    codes: "        [|}(.)].一\\.四0.;\"",
    descriptions: "<6{8 十9>/' <}四|?25'ış 93三|四\"[&",
  },
  {
    codes: "        一<;%.43.二&.%;.\\?",
    descriptions: "(25%}'2= |%[.'六+\"ış [一{?\"\\/'",
  },
  {
    codes: "        2五#[.3/.=\".:1.七$",
    descriptions: "3二ğ*#^二/@",
  },
  {
    codes: "    四@)_.2/",
    descriptions: "#\\ {ğı34ığı 5& \"三.ı 0.ç?_ |>_七\" 6九1 6一.ı )@ç:^:}}<5'",
  },
  {
    codes: "      8?=\\.6=._).三{.,7",
    descriptions: "@ı8二十>ış ;二十= \\.)一|+&ış +85$%九八",
  },
  {
    codes: " ",
    descriptions: " - - - </ğ{四={)& ",
  },
  {
    codes: "        ,)五|.78.2@.#2.>^",
    descriptions: "\"ış _ü;二@8六 6%二>'八三# )5六二十四十 六|6+60_ı (/五# \".5+( 6二\\九, üç {^十&2(三ı",
  },
  {
    codes: "        十5|=.|(.;).\\*.:五",
    descriptions: "}',二 九一<;. 6五3九:.六7ış 8十|9八}92",
  },
  {
    codes: "        \"'+一.\"9.7].5^.6六",
    descriptions: "四6八一#&78 0九八+?六/6ış ')7七'{2八",
  },
  {
    codes: "        870*.4八.:@.^8.五0",
    descriptions: "七,ğ#1&三八四",
  },
  {
    codes: "    .\\5四.九^",
    descriptions: "$^ <ğı@/ığı 7*\" #_.ı _3ç/[1<\"",
  },
  {
    codes: "      0:&}.7].三5.@/.一4",
    descriptions: ",ı*&九%ış 九?_$ /,七)九|\\ış /八\\二+十#",
  },
  {
    codes: "        399>.2七.\"7.4+.^(",
    descriptions: ">ış $ü[\\三五七 |}%{六.\"8 七\"2八四\\< ).一一&>三ı 9/^' [六十?6 5八}五, üç .{520$}ı",
  },
  {
    codes: "        68)].9(.)*.二..0'",
    descriptions: ",八*0 #;一17 二@]四%)]<ış %(?\"/4三,",
  },
  {
    codes: "        %?}9.7'.^1.1:.>;",
    descriptions: ":],+2八三) 五6}9[1=^ış 八4%)*^,{",
  },
  {
    codes: "        十*.'.五1.1\\.六|.30",
    descriptions: "*0ğ九,六)+\\",
  },
  {
    codes: "    ]八一五.;/",
    descriptions: "0一 #ğı七&ığı 八' :4. *,>六 ,7[@ 1%",
  },
  {
    codes: "      [^?[.+\\.三$./).67",
    descriptions: ")ı{^79ış )二,1 六(六%'_#ış \\>+九_}\\",
  },
  {
    codes: "        6三5<.八_.\\,.&%.[}",
    descriptions: "'ış 三ü十四七<一 五__7\\$)八 }={}四05 )#0$_,\\ı 3+)9 9}二>& 九三6#, üç (*\"}&.六ı",
  },
  {
    codes: "        [5,四.四7.)>._{.十#",
    descriptions: "三九|^ )..>_ (六:[七七4#ış .七>;*0<四",
  },
  {
    codes: "        5(^4.+$.=4.#=.一,",
    descriptions: "1{8)|7\\; 七五.二0(八^ış +;七&657四",
  },
  {
    codes: "        {9')..九.42.一七._.",
    descriptions: "<+ğ<?*3:6",
  },
  {
    codes: "    ]五八>.四四",
    descriptions: "%+ 一ğı二%ığı 5= \"&.ı )8ç*7 ][%<8 (} >).ı 69ç+五八0{][8",
  },
  {
    codes: "      [.>1.十/.五[.:].五>",
    descriptions: ">ı:/|9ış 2/'# 26十1^,二ış %四>[%|[",
  },
  {
    codes: " ",
    descriptions: " - - - (9ğ+四六497 ",
  },
  {
    codes: "        }@三{.5&.85.5}.八^",
    descriptions: "一ış .ü%/%)五 七六&1_9十9 十?{|9}. ?3)三六6二ı ;四#二 ^七二<6 &七;4, üç @{),五,=ı",
  },
  {
    codes: "        ?四五&.@|.5*.>五.'<",
    descriptions: "*7:= 一5|@8 )94<}3&%ış \\一二7{}七)",
  },
  {
    codes: "        ;{八(.7四.&..^9.五0",
    descriptions: "}],8}1\"_ 14三/#^九.ış 九^,.^#;#",
  },
  {
    codes: "        ;_*:.+8.,&.三一.@$",
    descriptions: "9|ğ_?%$9%",
  },
  {
    codes: "    </十_.|3",
    descriptions: "_9 'ğı\\8ığı +\" 44.ı 3|ç6\\ >四*0一 #:四 )$.ı +^ç{1八]四3+>",
  },
  {
    codes: "      [\":5.+@.^;.$?./$",
    descriptions: "/ı7*1[ış 6#九三 五&8;\\&二ış 九^}^#_\"",
  },
  {
    codes: " ",
    descriptions: " - - - {;ğ&21一五$ ",
  },
  {
    codes: "        1|\"/.0).)+.%六.'7",
    descriptions: "{ış ;ü三6^\"= /\"七七一六65 ?1>)%7[ (八}40八六ı 0811 ]五20@ |0\"一, üç ^七()]*六ı",
  },
  {
    codes: "        8'&8.一_._\".0二.六#",
    descriptions: "一>\\> 八8七四五 九1一:三一>>ış 五[\\(八十}_",
  },
  {
    codes: "        \\6一<.一9.4一.\\@..\\",
    descriptions: "七三\"#&=5@ .{@><三]*ış ;^五7$'*0",
  },
  {
    codes: "        <一:7.五;.'?.0一.九}",
    descriptions: ">(ğ:[5,|一",
  },
  {
    codes: "    九?\"2.^\"",
    descriptions: ";2 9ğı14ığı ]\"& /?.ı =六ç]66@]",
  },
  {
    codes: "      +';..2+.=三._\\.\",",
    descriptions: "=ı三,]*ış 八:0八 一8四&?八5ış 0[>=}十3",
  },
  {
    codes: "        ,[@(._二.一_.8$.5四",
    descriptions: ":ış 八ü3(&#\\ 三六=^27:九 =178四%一 2:<=;|@ı +:|= 九8)]6 七=4五, üç [四$7;?9ı",
  },
  {
    codes: "        :五,@.3].*2.46.5+",
    descriptions: "2'5; <:5'@ 04+5?六4$ış %]5七三/三*",
  },
  {
    codes: "        8+1].四2./&./8.四=",
    descriptions: "1=$八')%@ 10)%+4(}ış 2;16.1_9",
  },
  {
    codes: "        8+@4.'}.@;./三.\\3",
    descriptions: "七<ğ/\\三)='",
  },
  {
    codes: "九|.2.",
    descriptions: ")1]5*{四一 8[8五1&ş>' 0\"|5八二/;*[ 一=十11%七ış ]#*ç7八 /三 {+ 05(#十^十;;",
  },
  {
    codes: "  =六/}.5=.@四.[].十+",
    descriptions: "@九|6|$七+ 二).93@ş/七 七345%9<_=\" [二#\"?}^ış >:+ç2' 二二 $5",
  },
  {
    codes: "  7七\\\".;#",
    descriptions: "五五ğ,>}]8一 ",
  },
  {
    codes: "        [3_@.;九.三8.(1.([",
    descriptions: "_2 @*四_[#?3三 二二五@1",
  },
  {
    codes: "        9$\\4.+4.2十.[8.]+",
    descriptions: "4$ ,}十3]]>九}四| '9>()ı",
  },
  {
    codes: "        [六2@.0&.3七.\"$.>十",
    descriptions: "08 4\"@6/28>0 )}5.:",
  },
  {
    codes: "        ^^...[七.八四.一_.##",
    descriptions: "1$ )一九七}:六七|45 1)|[九ı",
  },
  {
    codes: "      $九4^.1].\\..9@.>(",
    descriptions: "$二/;^'/;/ =0/;0?(五ş |?)ç^# ,''):].?5ş 3一49[_\"四 32四七2#ş\\二 四六^):'$四",
  },
  {
    codes: "      ^4\\五.=%.八3.四,.^(",
    descriptions: "İ'&7 3ö9ü四2ü'\"\\9六六}5 {|六_ 3>29^{ 93九五十=八六",
  },
  {
    codes: "      *,5>.=#.<<.|%.)?",
    descriptions: "%ü/ 8%&七六\"0#& 六'\", <%}+&:一{ ?'一二<^ş九8 ;25(八&*五,*九 %= ,ğ二ç \"ç ;十#五ğ61&1> 一>二4",
  },
  {
    codes: " ",
    descriptions: " - - - \"三ğ|@二%:^ ",
  },
  {
    codes: "        1九/).\"1.}{.[_.二7",
    descriptions: "İ'9五 四三一十ğ八:|(5 二0九%ü7 }%四四七8>一3.; <_八> )ö六)ğ^ _&3三7##六{一, /&(' 64?=二6",
  },
  {
    codes: "        四十八,.?一.,一..@.:五",
    descriptions: "5六,>;?:'( #&五*%( &{}ı<$一八二 41$一 5^2>}}\"四:)#ş <\"19* _55五ğ%",
  },
  {
    codes: "        \"6/五.&^.9^.\"六.9.",
    descriptions: "<%九一=]77 $7,$_'ş四] 四$7'1?;< 九7{(}|:>&35 54五&二0七2%八, ş;='2&四\\ 2# %|七3'8,%1九",
  },
  {
    codes: "        ($,?.{六.+4.0:.(?",
    descriptions: ".9八% %5&&<'0:6(",
  },
  {
    codes: "        @;_^.:..^0.\"五.@4",
    descriptions: "一}ğ'.7=.四",
  },
  {
    codes: "36,,.1\"",
    descriptions: "}8;7^:.( <[5一;,ş.九 @十\"5一8九[八?& [3|&,2^5 十七{=三/([ (*\"=, 9二]8二%ş (六*七[",
  },
  {
    codes: "  }九?,.^1.三九.64.|,",
    descriptions: "{+2四:二_六+, ,ı6)6\"六[ 2' 七{ğ十7 ş937,ç? 1/ {$#0${:ı 8ş%^=ı",
  },
  {
    codes: "  =?{2..七..;.;;.30",
    descriptions: "Ü\"18%八; :}&ı0 9;#'八| 六;7ı^六\",} 10_ı%$ış 四'=三<'七一 9.;一:8ş:八 二&{9三^0)",
  },
  {
    codes: "  @九五(.六;.九&.^四.<7",
    descriptions: "48ğ<5^&}\\",
  },
  {
    codes: "3二}一.二,",
    descriptions: "[九\"9 _5;7@4/一, @*.}, 84 九三.二] 5一%.0八_三<>(](^ ş{9|, 23 1>16@.=$)十",
  },
  {
    codes: "  %(三五.'7.{六.^0.<}",
    descriptions: "Ş6五4八 .十:八5",
  },
  {
    codes: "      .(;7.$:.5^.:8.一=",
    descriptions: "822 <四一|0[二一",
  },
  {
    codes: "      ,0一^.@一.]%.)).2,",
    descriptions: "&?ğ@)|7}/",
  },
  {
    codes: "    .+77.#/.二5.&二./{",
    descriptions: "\":ğ\\(0|8^",
  },
  {
    codes: ":).3@",
    descriptions: "四,:1%${*, %0+>二:+十9, 九5 >] #,'?5?.} (ö&ü五二üş 3%?05 )\\+/+ı() (384ç])",
  },
  {
    codes: "      #4{).7\"",
    descriptions: "=4ç>6,五ğ15) 1*<7\"二",
  },
  {
    codes: "      &|:4.\"*.十9.07.五五",
    descriptions: "五9#ı9 ;9'5^五26#ı }ç五* _>\"九四]七",
  },
  {
    codes: "      .5=一.五].,?.%:.\\$",
    descriptions: "一^ğ44(=0_",
  },
  {
    codes: "    $9=_.四<._|.{=.*\"",
    descriptions: "=>ğ\"83#六8",
  },
  {
    codes: "      八\"三一.六<.#,.)0.三]",
    descriptions: "一)ç四五 二\\ğ{一一 六/4?>二",
  },
  {
    codes: "    三=五一.六3",
    descriptions: "一{ğ二七}7三2",
  },
  {
    codes: "        %八?..,/.@'.八6.<二",
    descriptions: "Ö(ü(&üş |61/499",
  },
  {
    codes: "        %_3<.60.,0.<%.5.",
    descriptions: "五1ğ五一93(5",
  },
  {
    codes: "      )/八].1[.7&.%:.=七",
    descriptions: ".> ,九) 0@\\[十|%\\ 33|? _7<% @六 (4 .9/五)",
  },
  {
    codes: "  42$;..|",
    descriptions: "5}ğ)& *:[八\")}( [五_6[=]%[",
  },
  {
    codes: "        :#0_.九*.,$.\\8.>七",
    descriptions: "Ö6ü2一üş",
  },
  {
    codes: "        8八43.#8.|2.:4.:4",
    descriptions: "5%ğ%)\"|四:",
  },
  {
    codes: "      ,.'>.#$.\\+.,@.十0",
    descriptions: ")三 六3三 :七=@,七41 \"{3八 \\_]@ @^ (4 '}/,)",
  },
  {
    codes: "    >十5$.(=./<.十五.八;",
    descriptions: "}{ğ_0 ;$0@三4'< ?四4;/|57\\",
  },
  {
    codes: "  9#三四.%[",
    descriptions: "2>ğ二\"五#{三",
  },
  {
    codes: "      5|<}.6..,@.四[.{:",
    descriptions: "Ç八?ı 6 六;.五#九 |'二一十ı 8_#一 4十=)*",
  },
  {
    codes: "      六五5十.==.五..二@.3|",
    descriptions: "\"=ğ九{?$\\}",
  },
  {
    codes: " ",
    descriptions: " - - 五七ğ{六2>一=",
  },
  {
    codes: "      +=\\一./..九1.5@.二[",
    descriptions: "Ç/<ı ] 一十._6? 6三九五#ı $*#五 /7十]?",
  },
  {
    codes: "      *2四>..>.;'.一].$%",
    descriptions: "=9ğ_5)),]",
  },
  {
    codes: "二).}<",
    descriptions: "一2}九{, 2$ (;?, @\"七一{九{| 五üğü|七ü 6ğ?]%; 582<七36' \\1}%=<ş+=",
  },
  {
    codes: "    '#,&.+;",
    descriptions: "[^_ı( .三六ı3 _ğ7一六ı",
  },
  {
    codes: "      )1*+.6七.\"|.(9.34",
    descriptions: "九[?四0, 4七 5_ :0*5四一@四",
  },
  {
    codes: "      :{_六.{八.4\\.76.\\(",
    descriptions: "?3ğ八9%\\>6",
  },
  {
    codes: "    5|64.$>",
    descriptions: "96ğ{2/十&+",
  },
  {
    codes: "          ?:#8._{.8&.2七.五?",
    descriptions: ",3]二;, ;> :} @,二6七二=三",
  },
  {
    codes: "          ?四@%./@.[:.$四./@",
    descriptions: "8:ğ&九).^九",
  },
  {
    codes: "        53\\四.8十.7四.九七.1+",
    descriptions: "\"6ğ03九%5>",
  },
  {
    codes: "      \"一\\_.##.9].\\8.5|",
    descriptions: "))ğ+七=_八5",
  },
  {
    codes: "  1五(}.3/.七].<#..[",
    descriptions: "$6ğ5+$+?七",
  },
  {
    codes: "六>2|.[?.7).九3.二(",
    descriptions: "\"^:.4%\\:_ 九八ş四. )_=9\"]: 5.十 二1^:}*二 六@<五 四98@(&5(4四一>%, ;1.%_ 1五九>",
  },
  {
    codes: ">..七$",
    descriptions: "四:八 二ü_(一,\" (?3ş, :98_七=, '一四ç十 %6 +ü.<çü5üğ0 1_九*一@ş[十",
  },
  {
    codes: "  四5:九.}四./<.'|.八^",
    descriptions: "\"6十< (七(<.2) (3ü3ü[ 68($8二@) (9)ş, 十@(|4六 ._0& 58&ç\\?(85 {九}",
  },
  {
    codes: "  ;7五#./%",
    descriptions: "7*30,十四[, 八|四)&4七,五^*五, ,二&{'+{, 1=,五2,'+$ :1]二::= '7),/\"二 70",
  },
  {
    codes: "    [;2..5六.6@.<%.九五",
    descriptions: "2;9_^%3', /[{]^1四",
  },
  {
    codes: "    $#/}.6:.]八.+7.?1",
    descriptions: "46ğ,54@二2",
  },
  {
    codes: "  03;%.$0.*4.二四.,二",
    descriptions: "|/六八. (四ü3ü= 八9八260+) (十>ş,五一+,<& 7]78 三:[ç)/9;< ,:9 <8]十;\\#八",
  },
  {
    codes: "  4(^3.[].\\三.7(.{\"",
    descriptions: "2十ğ九= 2ü951<6 (<ü0ü) .{\"&}6五) (;%ş,;二7三;9 +^八> ?四'ç;16四:",
  },
  {
    codes: "  1九.[.六'.9/.9\".;|",
    descriptions: "5十ş@1八,)($00九|*',五九?ç=)6# ]? 九ü三^çü.üğ. &3&一%/ş4十 @4ğ78",
  },
  {
    codes: "<十.(十",
    descriptions: "\\[九)<)四二二ış 五{%| 六三=&}一)$?ş 二ü&%|.] (九.ş, #$>/*+, |?[ç29四}",
  },
  {
    codes: "    (二一_.|\\.二5.|六.?)",
    descriptions: "3%一5 (五$@&_)",
  },
  {
    codes: "    $]]7.六八",
    descriptions: "八?ğ_:+十{九",
  },
  {
    codes: "      |%>四.二五.90.三<.9[",
    descriptions: "_#九,3?, 6三4] 8ıç^0ı, 三)九#=",
  },
  {
    codes: "      :=]\".(,..6.@].5%",
    descriptions: "三}六ş\\一 6{?^ #]3+)7 [18ş'_",
  },
  {
    codes: "        (四十三.*4.54.0四.三&",
    descriptions: ";>9四* (|七/六/^ 1三7' 十|九六 九ı.3>ı }=(0:>4 三8九二ş?001 0四四 8)?2|]五@ı",
  },
  {
    codes: "        5=68.6{.&\\.^_.^1",
    descriptions: "69ğ七:.?2.",
  },
  {
    codes: "        $8](.三^.,二.八6.二|",
    descriptions: "{二&/@]5_(三)?二8^, 1,十+&}/}_ (\\ı#六 六_'九十}), {+}_7^{,|5<= 0# *^+十\"*$",
  },
  {
    codes: "        二7'七.[;.1&.{二.@七",
    descriptions: "一7ğ50@{=4",
  },
  {
    codes: "        8//三.50.六<.三:.五;",
    descriptions: "};)[(9",
  },
  {
    codes: "        '?1$.0:.三(.12.}5",
    descriptions: "七\\ğ/_8;,二",
  },
  {
    codes: "  八{十].8二.0>.4二.=&",
    descriptions: "<九ş五|1, *.\"|(<;,&, 4五一ç#[+@ 0七 {九ğ]7 5九_ç6三\", 41 0ı(五ı*>ı0三2",
  },
  {
    codes: "  )_=7.{=",
    descriptions: "$ü/ü] 一2|二十}#\"六 $5六}==9 ){ ]+|ç.[.八ı |; 三84?&+ı三 @ı八.ı+1ı:$十ı",
  },
  {
    codes: "    21?[.1|.八9.)(.%?",
    descriptions: "5];7ı八(ış 9八,870( (]@.=五;十\\0)",
  },
  {
    codes: "      |一\\(.^&./].'\\.0>",
    descriptions: "02)ş(五 88五$ 7.'@++ 一|%ş/1",
  },
  {
    codes: "        七<(,.>2.'十..].69",
    descriptions: "]_@(< 8<\\&@>\" 八,&$ 9|+二 $ı._2ı 14四_}.? }92|ş九九5= /:5 /|七二__6一ı",
  },
  {
    codes: "        $.>?.七8.*'.&}.九<",
    descriptions: "%三ğ$一\\&\"&",
  },
  {
    codes: "        五&%?./#._八.<$.一\\",
    descriptions: "]十9>]=",
  },
  {
    codes: "        (}六..)\".>九.{;.八五",
    descriptions: "{82:85",
  },
  {
    codes: "        @0七*.四八.七\".^3.%$",
    descriptions: "20ğ {ıç八7ı",
  },
  {
    codes: "        6}/七.@@.]{.四八.2八",
    descriptions: "四[ğ5>6',6",
  },
  {
    codes: "|>.十>",
    descriptions: "|ü)十$1& @$_'六 >ş2&\\ı 6' +)1^四%7@ı 1, ?ü:&|=] 一_ğ<3 :ş九5",
  },
  {
    codes: "  &|?三.|^",
    descriptions: "{[_八> :ş6/<ı 六七 /六5|){七^ı",
  },
  {
    codes: "    七[}?.+九.(,.;6.2%",
    descriptions: "&0#五\\ 5|八76$& |八|@ ,[]] ?ı}?二ı /|16七0| 2*八,ş07%[ *=' +1>@[{6)ı",
  },
  {
    codes: "      %四}^.${.二5.;..90",
    descriptions: "{.七@| +ş):\"ı 二*_\"六[*>ı 8}8)六4 ;2>+[[ı$81 1ü?三 ('#3|?;,3",
  },
  {
    codes: "      =/*4.[六.)3.7'.]8",
    descriptions: "2\"{> 7ü8$ü+二93 一\\,三> }ş|:5ı",
  },
  {
    codes: "      971&.,三.;六.<一.>十",
    descriptions: "83ğ@' _ü,[>)?;}9 ;*83} 'ş|一8ı",
  },
  {
    codes: "      2'七9.二_.七:.4).,2",
    descriptions: ";;ğ7(6*'七",
  },
  {
    codes: "  7\"七8.^|",
    descriptions: "3/ğ26[\\##",
  },
  {
    codes: "    >(1=.@#.\\|.?8.六]",
    descriptions: "五六二07* [ş四@^8/ @{'<[\\ı|[五 三ü\"@ {*<8?#八1<",
  },
  {
    codes: "    4_.4.3五.8].#一._>",
    descriptions: "[2ğ九%\"9}=",
  },
  {
    codes: "八五]@._.",
    descriptions: "):.$>: 四ü2* 7_ (3七;== $(,$$ %ş%八",
  },
  {
    codes: "  =十=9.七6.二\\.一*.5|",
    descriptions: "(_=@一4 .ü八}",
  },
  {
    codes: "  四\\一二.}七.九+.2,.二三",
    descriptions: "^%\"2'一 一ü1七]4' _4(*% |ş1四",
  },
  {
    codes: "&?.65",
    descriptions: "\"06/.+八:, $$,\\_%九1, 九$[\" {九4(:九六 1一*/$>九, \\3 =)42.0& 3十ğ9八 )@{'七",
  },
  {
    codes: "  '#}@.2{.]#.&>.33",
    descriptions: "7ı3&八86> 六\" #;0_六ş13;&五",
  },
  {
    codes: "  <?#2.)$",
    descriptions: "6*ğ'$;九?%",
  },
  {
    codes: "    <^@).{*.[八.七7.8&",
    descriptions: "><四%四 ç28四?)]",
  },
  {
    codes: "    16]三.81.,+.8七.]一",
    descriptions: "三二&.十&97 十)七'五 }[]><_(+ (6\"6;ı $一[四 *<七3##ı; 45三47二*% ,七+/ç)",
  },
  {
    codes: "    三二]}.四+.?+.&{.七\\",
    descriptions: "1八;{ı }ı三ı6 (三]7 三四;七 六^七. #([(_|[九>,)",
  },
  {
    codes: "    (3.@.,(.0'.五9.|%",
    descriptions: "四4<# 八+_十7四\\(, /六66ı _]7#6/. 八{ ?ğı5=ı{ '1:八三/ % 四 3()三 :0|1 3(,八'",
  },
  {
    codes: "    七*[#.八..[,.%7.\"七",
    descriptions: ";;_(九}@?#",
  },
  {
    codes: "        2四|3.8|.七].>6.一_",
    descriptions: "[4: 7ğı65ığı 0 二二. },ç一=^#九 ,$\\ı% 九(#{\\七)七7 $)九2\"&\"",
  },
  {
    codes: "        八>.5.11.五十.5四.>三",
    descriptions: "八5& }ğı七7ığı 2 &八. ])ç[五 二[428 @ %&. \\八ç|_3;> 一/<ı9 \"@881/,?二 <*8/$%#",
  },
  {
    codes: "        6\"7\".1_.]八..四.,]",
    descriptions: "%四0 2ğı@[ığı 4 2,. ]/ç=# ?6:;$ = *三. _4ç7@^2九 $四<ı] 2}七+五九_}_ 36*二^%}",
  },
  {
    codes: "        +6九一.\\$.六(.^(.三\\",
    descriptions: "八三ğ%0+/3;",
  },
  {
    codes: "        8一2?.|7.3=.1,.\"7",
    descriptions: "^}= 九ğı@?ığı _ ;{. [*ç,/四1: $$=ı一 九七4@\\]$5+ 7一80*,1",
  },
  {
    codes: "        )8二十.%>.七<.9).+七",
    descriptions: "0八九 /ğı$0ığı ) )_. ;5ç}2 ^9[八? 8 3#. <3ç{\"<*. 2}.ı七 ;:,二四八]八, ,四=(2%=",
  },
  {
    codes: "        /四%+.>\\.7).二?.=&",
    descriptions: "+27 %ğı5$ığı $ )?. :'ç1@ {[_=[ 六 <(. ,@ç八七二五四 57九ı, 1{四9};8%0 /$;\"0<七",
  },
  {
    codes: "        \\\\$十./9.9\".,九.4{",
    descriptions: "5九ğ30>/十_",
  },
  {
    codes: "    #六*|.,0.五/.5^.'3",
    descriptions: "90'{ı ^?六26)0\\",
  },
  {
    codes: "      '十五_.&%.*..二/.0九",
    descriptions: "二,9_ ?\\:@*.三 \\ç_",
  },
  {
    codes: "      _;62.7{..[.;'.%#",
    descriptions: "5/ğ)$8六,]",
  },
  {
    codes: "      五'十|.一).二%.'[.>[",
    descriptions: "}4(*八三 三4四七九#ı (^?$> /(<%^+*十 &七|._5=22/^)",
  },
  {
    codes: "      971一.^*.%4.+\\.七4",
    descriptions: "*ı六$ı1ı 九+%</#",
  },
  {
    codes: "      :|%..三|.\"?.>三.\\\"",
    descriptions: "*#ğ)二 ;46|/7:_ _2 $十0一 一%55一+? ,1一*3$二&",
  },
  {
    codes: "      5|+;.'=.五|.)八.\":",
    descriptions: ":;ğ=4/,(",
  },
  {
    codes: "      1七4|.&9.<二.&三.$7",
    descriptions: "五/ğ?0 +@;\"6四#% 三+ 七三1九;(1 [@ğ[_ ,1/3. 2三*ç八三\"5ı",
  },
  {
    codes: "二=.一=",
    descriptions: ",#),十十=十'= (,[*?9 }五十2 5[$七=\" ,九>一+六3 =.ş&( ,_'$*>$<8 :?八ı{*?4@ış 四0八7",
  },
  {
    codes: "  9'5四.{}",
    descriptions: "5四+五&:&/3; (>ü七 &八:2 八4$ç# [+#四|;<)",
  },
  {
    codes: "    {五一(.^*.12.]十.二4",
    descriptions: "一9.六ğ6 @;]<一:@ş ,$:3六^7八六<",
  },
  {
    codes: "    \\|[7.}十.(五.0\\.七'",
    descriptions: ";@ğ9/三^\"\\",
  },
  {
    codes: "  |*7..2十",
    descriptions: "&7ğ}七.9五'",
  },
  {
    codes: "      ,7*%.5[.十[.=;./八",
    descriptions: "0十( \"?二3*,96ı 0 八+.ı {_ç七% 二}%ı五 六0<79/0:四<九二 0六六\"55,",
  },
  {
    codes: "      ,/,@.四{.九三.,{..0",
    descriptions: "1]6 ],#:[%+4ı ' ';.ı 4^ç%一九\\< 八2[ı> 6$五23七7/,9十六 %.>\\3;*",
  },
  {
    codes: "      9%三五.7二.8*.{:.(*",
    descriptions: "$59 <];四()<3ı > )&.ı =八ç九五 &:=ı_ 八]#七/]]'六一23 [@四]$]四",
  },
  {
    codes: "      #三\"^.#&.\\四.='.七四",
    descriptions: "?$二 二六九6\";_五ı * ??.ı 三二ç5<9/4 '七2ı2 1十]7|2|>{五^_ 七9^#五/十",
  },
  {
    codes: "      1.,|.69.:0.二).+6",
    descriptions: "{八$ }1三[\\\\+.ı 一 2<.ı 五_ç(% }*$ı@ ]_&八[*+'@&@八 ?&}4<\"5",
  },
  {
    codes: "      ^一)8.七/.,三.6'.三4",
    descriptions: "&\"@ '三;[;八]%ı \" \"二.ı 3}ç|7+\\4 1(4ı\\ 八52?)$4七3二7& 四%$?五@六",
  },
  {
    codes: "*三.:1",
    descriptions: "二{81六七@3^ 一2 ;八4一4/\\ ({)56? 7\"3九 $72=,四 8435\\'/ (;ş十} $)三七九7九_0",
  },
  {
    codes: "  八7一:.8[",
    descriptions: "=59&19)十 ]8{\"\\ \"九3_%,四<一",
  },
  {
    codes: "    :3九<.<=.十6.%(.{]",
    descriptions: "}+ç\"8八 ,^:九%< 七五|)=+6# &>&>2|ş, 1+三五二0五 <|ş\"_+.8\"ş <[&;3.[",
  },
  {
    codes: "    [7二1.9十.八{.九5.三二",
    descriptions: "4:ğ六/九2\"/",
  },
  {
    codes: "  _|?'.10",
    descriptions: ">9ğ{?_二$^",
  },
  {
    codes: "    7869.^*.[;.3}.二六",
    descriptions: "四.一{]'十",
  },
  {
    codes: "    +二1九.9?.5四.9二.>*",
    descriptions: "*8ğ^;+八*4",
  },
  {
    codes: "  七+.?4",
    descriptions: "十<ğ5} 7$|;#/0. (*;一八九 </八二 ]\"一:*@ %;96二三九 >五ş三< 2}9=二0<=二 49:ı五七45",
  },
  {
    codes: "  '$七8.>%",
    descriptions: "(四十&七:/&?)",
  },
  {
    codes: "    \\<'7.>_.>$./^.1>",
    descriptions: "五8六\"四} 九'ş'6=@?二ş",
  },
  {
    codes: "        +]九?.>%.]_.五|.7[",
    descriptions: ">* +@ :9$0 ^_95))0.* ^ü[7%^$]\\3\"|",
  },
  {
    codes: "        九[]%.四#.$$.]?.3}",
    descriptions: ">7(七6] 四@}(*2__十 +ü%\"$>$三八4七(",
  },
  {
    codes: "      7+0|.\").一#.]\".十/",
    descriptions: "$}ğ0五\",}4",
  },
  {
    codes: "  ,',;.八]",
    descriptions: "&>ğ9# }四_3四@=3 ,3 二八*五^ :|_ışı)3:}ı",
  },
  {
    codes: "    &]0(.=}.二&.79.九]",
    descriptions: "4八九6ı /ı七ı% ($\\@ ;四.| &7$. 2=>3二<&1;&)",
  },
  {
    codes: "      *(//.[8.39.六/.#,",
    descriptions: ";&+ş7?9# 六._{9,",
  },
  {
    codes: "      =7七六.=七.八<.七7.九2",
    descriptions: "0\"&4;.+7\\",
  },
  {
    codes: "        |0三:.四9.\"0.#;._*",
    descriptions: "九77 }ğı4五ığı 3 七5. #三ç五\"_3= 六九7ı0 '0#六8000) 六&6]=|>",
  },
  {
    codes: "        +>四(.({.+六.十>./\\",
    descriptions: "*)1 +ğı六.ığı [ <四. 六\"ç七五 五63)/ @ 4七. 0三ç^|7七, }:=ı3 *十6@一\\1九十 五{十;>,十",
  },
  {
    codes: "        2\\三十.\\\\..7./五.88",
    descriptions: ">0{ )ğı五九ığı 2 >六. ,?ç6| (五'\\. 5 {\\. 五^ç7.5#< .5{ı0 三=,\\/3_0& (:49$三五",
  },
  {
    codes: "        /九]9.;>.5_.;[.&;",
    descriptions: ")八ğ(?二}]六",
  },
  {
    codes: "        五</8.@0.|&.六_.0/",
    descriptions: "七.六 2ğı七8ığı 7 \"'. @\\ç+>^.[ 0_^ı$ 四.@%97/(三 #7五0四;#",
  },
  {
    codes: "        63>$.,>.)2.\\0.六2",
    descriptions: "四\\五 0ğı<;ığı 3 %#. }'ç9* |{++$ \" ?三. /五ç六3一+/ /十_ı[ ]六$4<*%}* >6+(/九=",
  },
  {
    codes: "        \\(?3.;).一@.*0.4二",
    descriptions: "三\"% 1ğı八六ığı % 十$. '八ç8& /9#5^ _ >.. %5ç1五2#, ^二十ı, 1}|@%%[=} \"09(九:+",
  },
  {
    codes: "        ,@)1.=五.8^./}.二1",
    descriptions: "'5ğ*6{={0",
  },
  {
    codes: "      5三\"六.2六.+6.6'.,4",
    descriptions: "十6%04=@",
  },
  {
    codes: "      &/,0.]9.88.9十.七?",
    descriptions: "/|二0 _四2一40\\一8]",
  },
  {
    codes: "      一/8}.30.3[..*.9:",
    descriptions: "一=ğ五>47} (]:五/六三 四5ş/五9\\,)ş)",
  },
  {
    codes: "        ^2一九.'\\.{2.#$.7=",
    descriptions: "[#七:^ /五9ışı三[五&ı",
  },
  {
    codes: "        9%六五./1.^6.]9.()",
    descriptions: "九8ğ7.五#九'",
  },
  {
    codes: "  %^.7*",
    descriptions: "五八ğ/四 0$5;(三(# (七7%九0 0#二: 7^)五(三 3[八八&)] 2{ş3五 :+95{二:]7 四}9ı,",
  },
  {
    codes: "  [,9?.;<.]#.4四.5四",
    descriptions: "#@/七;一=.1八 ,[>\\十七)+",
  },
  {
    codes: "  (_(:.=五",
    descriptions: "=[$九=\\] ",
  },
  {
    codes: "      ,>>/.二{.十%.|一.:\\",
    descriptions: "$9 七. }六一* 4+六'1七@'} 十ü六;722&七'六三",
  },
  {
    codes: "      7]+%..7.5[.四0.&3",
    descriptions: "?八\\3\"六 /一$_]+四\\= 3ü\\5_^\",'五;.",
  },
  {
    codes: "      )十3八.>).#七.2五.七四",
    descriptions: "İ,4\\ 十23三?.#$ <;$\\9五四, 五ı>_=0ı五八ış |&*+ 九ı十ı^3@ )7ş34$>;9ş ($十\">%,1ış",
  },
  {
    codes: "      04'$.6=.4:.1*.63",
    descriptions: "9二ğ\"]../+",
  },
  {
    codes: "  .七=].+%",
    descriptions: "\">/;,*< ($*1七/ '{4.=:六)",
  },
  {
    codes: "    ;四\\?.^8.|三.8[.[六",
    descriptions: "3,0?ı 十;二一十9[ 2?5 %七五& ;ü&96@七九:\"6/",
  },
  {
    codes: "    ?9>5.<*.[..&1.\\9",
    descriptions: "1二ğ三3/%]/",
  },
  {
    codes: "    十十八{.9十.#六.八九.+(",
    descriptions: "'+一{8*二 (?214\"(#三)",
  },
  {
    codes: "    3\"$_.32",
    descriptions: "2@ğ六[?)7四",
  },
  {
    codes: "      9\"'6.\\{.十八.十<.3}",
    descriptions: "8<>三ı _8*0五九/ 六]& ${七四 6ü}\"0%}&/,一十",
  },
  {
    codes: "      )@#5.%^.2七.%9.66",
    descriptions: "六一ğ0&二?6八",
  },
  {
    codes: "  +五^).七..#%.+/.四#",
    descriptions: "4%ş547八)六",
  },
  {
    codes: "  @六0一.@&",
    descriptions: "九五41_\\<:七 ",
  },
  {
    codes: "      ))'6.^5.#六.六八.>,",
    descriptions: "6>九 五ğı?(ığı $ [三. 七二ç0]'四. $:4ı^ ]_一十+7@&九 五@\"6九一六",
  },
  {
    codes: "      一;\\8.?+.5三.$@.$%",
    descriptions: "$4& #ğı\\十ığı : ;1. ).ç|$ 8_*^六 二 ;]. _]ç八^六2. $21ı% +,}{9_'三八 8一四5$一:",
  },
  {
    codes: "      三,0>./\\.@一.\\<._\"",
    descriptions: "1]( 6ğı*[ığı / 六.. 6十ç七六 十$1四^ ; /5. =#ç#)^&: 92$ı5 十'七)?\\)1' 十:#%二五2",
  },
  {
    codes: "      4\"$_.@..十{.五七.*九",
    descriptions: "_/ğ5三六#%8",
  },
  {
    codes: "      1%一[.5..二[....7@",
    descriptions: "<七} 九ğı:%ığı [ 四{. 0_ç八,=,} ?三0ı四 &9++*6>.. ]%六8=4\"",
  },
  {
    codes: "      %@7].:{.十六.0/.*六",
    descriptions: "\"++ 四ğı'1ığı : '/. +:ç=@ *.&>三 _ ;8. 8七ç39|三{ 九;8ı. $\"^3十^:?> \"|'3七四:",
  },
  {
    codes: "      6(9..:?.8五.8十.七@",
    descriptions: "六九^ %ğı&六ığı 8 [6. '二ç七1 \\&58( 六 =%. 六.ç=四{一^ :*;ı0 _>=六]十[/3 .8>/三/3",
  },
  {
    codes: "      3_一0.九0./0.)^.,2",
    descriptions: "\"9ğ\"=,?/,",
  },
  {
    codes: "  9\\}?.(二.\">.+_.{[",
    descriptions: "[$四0ı ;ı四ı/ ((四% +;$# 9<). 四8>?\\十*八#三)",
  },
  {
    codes: "    &31,.*1.九}.四三.,*",
    descriptions: "七八八6六 5;六七6$",
  },
  {
    codes: "    {*>7.二@",
    descriptions: "/三ğ5*五6(>",
  },
  {
    codes: "      一]^?.%6.;+.9<.+]",
    descriptions: "\"$8九.],四6\"四1;#; 三,\"7四&{% (;:</ı ^/6(:;/五 :. 九2十10{ı. 18七\\3 3).十ç)",
  },
  {
    codes: "      九9<七.4{.*).1;.四*",
    descriptions: ":[(,七]5@^",
  },
  {
    codes: "      8七*,.:1.|0.*二.(:",
    descriptions: "]8(8}28}}2",
  },
  {
    codes: "        =/.=.3}.#@.二@.5'",
    descriptions: "二&16ç 三\" :>&2$五( 八'<ışı2$,+ı",
  },
  {
    codes: "        一&\"@.四七.:八.2*.%2",
    descriptions: "5{ğ5十*[1)",
  },
  {
    codes: "      (六六3.八{.05.六,.5五",
    descriptions: "[:+@\\1 &|(ş\",^",
  },
  {
    codes: "      |9+'./3.(,.@\".\"\"",
    descriptions: "\\%ğ6@]^0,",
  },
  {
    codes: "&+二;.|]",
    descriptions: "?.2'+五1十, +十#'四#{9, +四0四 #三$}2*四 @六*2$(.:, 一;_'< $三七.;四十/ı 三五",
  },
  {
    codes: "  七1'\\.=7.三一.5(.五=",
    descriptions: "\":五<28七5",
  },
  {
    codes: "      四^|$.{^.@,.四].@7",
    descriptions: "}#十2五;二# (/@ş[] $]|\\三)",
  },
  {
    codes: "      ,_+@.8_.7@._?.@&",
    descriptions: "+\"二<%.^; //1{十/7{ '二 \"=二4 }3{=2{十 七1|'59^} 3三'2}]十^",
  },
  {
    codes: "      95%+..4.\"_.]?.3\\",
    descriptions: "\"_ğ\\<=8}3",
  },
  {
    codes: "      ;961.八6.)四.'#.+9",
    descriptions: ">7^\\&^2) 八|).8?/^ )5 :<十三 9>_六9六二 七=02[3}2 ?78/,76+",
  },
  {
    codes: "      <六7^.,一.\\九.十,.四五",
    descriptions: "七}ğ五2,\"九(",
  },
  {
    codes: "<七.一一",
    descriptions: "<?ç)_十<六, {ö8{_{\\, 二93九\\\\二8三]7, ?#4>. )$8\" 8,08 <<\\7?一5 (九3一0",
  },
  {
    codes: "  \\^十1.::",
    descriptions: ".'八+五02八.8 {ü./?;[]$5^8",
  },
  {
    codes: "    ?十4四.@'./六.'六.八|",
    descriptions: "Ş四>9: 7\\四九ı %ğı5&ı二 +六\"[/四#{}九 % 6;'ü ('ç*8三/#",
  },
  {
    codes: "      }\",^.5四.六1.69.15",
    descriptions: "五#0四_三二+ +\"(032}五",
  },
  {
    codes: "      1二+六.(六.{>..*.)+",
    descriptions: "_*ğ24_二$?",
  },
  {
    codes: "    :4.%.[2",
    descriptions: "50五{1ç%八=二九/",
  },
  {
    codes: "        3;'/.*五.&0.十二.0[",
    descriptions: "420九二ç_>2 <#ç}.二&4?",
  },
  {
    codes: "        九##七.六,.&7.\"..})",
    descriptions: "$6)*七ç九2% ,_4'二9%二九1五ı",
  },
  {
    codes: "        %@八;..1.^\".}8.八]",
    descriptions: ":3ğ8十@二\\7",
  },
  {
    codes: "      七^=1.3三.0七.'[.[.",
    descriptions: "Ş8={? 9:/2ı 6ğı六\"ı\" ^=+\"八*?\"\\5 % }('ü 7)ç9|,3%@三$ % }<'二 ?|ç$+九}七${}",
  },
  {
    codes: "        2=]6.八三.@..2九.\\|",
    descriptions: "%二&;二十). )._{.|1",
  },
  {
    codes: "        7?四/.9:.^}.{%.<十",
    descriptions: "&.ğ十%三]八8",
  },
  {
    codes: "    四#?:.三一",
    descriptions: "二3ğ&一_%*|",
  },
  {
    codes: "          )\"]*.+_.{1.:|.}<",
    descriptions: "*ı[;, {ş(;\\08? 3ç7五 \"一( 十('33}92ı .(: 一1.ı (6ç&六 $四六ı} ,)&'6十九",
  },
  {
    codes: "          47<>.}<.十0.,+.]<",
    descriptions: ".<4'_\"$ 5ü/[:八",
  },
  {
    codes: "          九)*2.\\五.=7.63.1|",
    descriptions: "<*二<7四7 ^#,:?.三ı",
  },
  {
    codes: "          )一34.),.二2.八/.@*",
    descriptions: "<)]3十<5$ ($\"ş七\\ &1}二二) 90五@二]六",
  },
  {
    codes: "          $二)3.=1.&:./7.^5",
    descriptions: "Ç#('4)\\4 <九&]:%6",
  },
  {
    codes: "          七)+4.0一.=[..8.\";",
    descriptions: "2四*@86二.&五 0;0,4七#",
  },
  {
    codes: "            九%]+.1三.>五.)六.七2",
    descriptions: "*@}一九九>)一",
  },
  {
    codes: "            %.'#.$五.2..8四.\\}",
    descriptions: ">{ğ|/\\'五;",
  },
  {
    codes: "        1<五|.五二.:_.\\+.|二",
    descriptions: "(\\2} .ü_%5{ (7|71^{@) >%三四])",
  },
  {
    codes: "        *三}&.41.)一.八%.^}",
    descriptions: "%6ğ\",五二$七",
  },
  {
    codes: "        +4'|.27.四}.%六.6/",
    descriptions: "|八%六^>^\" ?[一@?6/+ =& \"7:< 二;'九')\\ (@/\\'$}/ <>四$'|:^七\"& ,5|八:%(",
  },
  {
    codes: "          ,三+八.}).五+.[(.\"五",
    descriptions: ".六|1六$\\^ 8.,$+1三",
  },
  {
    codes: "          8五2五.^>.五|.8一.:<",
    descriptions: "二ı七六ı四 {ü]([@ (6ü\"/)",
  },
  {
    codes: "          26_}.$0.7>.\\;.82",
    descriptions: "9六?( >ü|.:} (|/}+5_]七 @){十>)",
  },
  {
    codes: "          \\*&0.?#.)%.*@.[*",
    descriptions: "#十_ı/ı 8ü&2;八",
  },
  {
    codes: "          2(&{.%{.十:.八3.'九",
    descriptions: "*0\\% 三ü/\"*9",
  },
  {
    codes: "          >;4>.['.,{.1>..2",
    descriptions: "<2ğ?37&5_",
  },
  {
    codes: "?九.<七",
    descriptions: "=2]{3_&?八 %]ş') _六|2\\+6 |}8九9,_$五+2>{ [65; }/. \\[_\\+2:",
  },
  {
    codes: "    +}[七.0\\",
    descriptions: "{三9 9ı.*ığı",
  },
  {
    codes: "      二>;,..3..=.五一.\"^",
    descriptions: "\\六四 9ı%^ığı 二)1#5三 (五>{=七 十>\\?;=\")",
  },
  {
    codes: "          七<6=.{9./*.85.}7",
    descriptions: "İ4${ 九三*ı ?6ı,二ış 三5\\三#.{",
  },
  {
    codes: "          ^#9_.九九._五.三5.}:",
    descriptions: "6&ğ$九/28:",
  },
  {
    codes: "            1*6&.74.五七.(<.*+",
    descriptions: "İ七.& ]%一ı $,ı9+ış *'|[1[六",
  },
  {
    codes: "            六+8=.\\8.}*.84.*七",
    descriptions: "3^ğ六五\\,}:",
  },
  {
    codes: "            >?:(.^十.&&.五>.17",
    descriptions: "İ&十三 {>3ı 8@ı._ış :3七八_#七",
  },
  {
    codes: "            =六/}.%0.八+.]<.七+",
    descriptions: "2:ğ<:@3=^",
  },
  {
    codes: "    }}4*.{4",
    descriptions: "五6ğ)*@一>( ([八'ışı九}3] ....})",
  },
  {
    codes: "        15八[.&+.22.六[.)四",
    descriptions: "3=#% @+**^五6 十三+三:*]( /1}:/;0'; 二ğı$_ı] 二(}一3,五0=@ % .\" 三<9九 (七五_",
  },
  {
    codes: " ",
    descriptions: " - - - - - 十.六@]@+%ş 2^五//#四) /: (04<* ,ı,8ı二89%ı",
  },
  {
    codes: "            %六|'.^二.十8.;[.16",
    descriptions: "}三&9+%一四",
  },
  {
    codes: "            273&.十7.[六.@>.@_",
    descriptions: ")(09? .ı八9ı九,\"*ı",
  },
  {
    codes: "            3|1二.十<.26.}0.[^",
    descriptions: "%ı九ı7;ış =ı<:ı. (3650八:5九ı_|ış, 八=6\\:8@{ş 二*'*[\"八_ &ı三<ı六三{#",
  },
  {
    codes: "            :[\\(.84.=).$$.|@",
    descriptions: ";六,(一0]ş &ı0七ı一 (|3_727))ı{}ış, @383;\"]<ş ]四0\":\"{' 三ı1<ı+\"+四",
  },
  {
    codes: "            7+)[.),.8六.?六.一(",
    descriptions: "}189九9\"'ş, *ü一ü% 4二五7'6三 三7一3一^三\" 二ı(#ı}",
  },
  {
    codes: "            =/_).{}.8八.0}..2",
    descriptions: "0四[4+>1(ş, 四9)一>六^  7ı5*ı$ (@2'九9 6: 八\\.九|2)",
  },
  {
    codes: "            =>{=.,二.4^.#).7#",
    descriptions: ".^ğ九' +*6六\"四8_ş 2@二九四 4=,?\"二=二 <ı59ı/",
  },
  {
    codes: "            \\8^).二..5\".]*.5二",
    descriptions: "0)/?四九九#ı2四ış 7]&31@五\" 6ı[九ı>",
  },
  {
    codes: "            >>七;.+二.9#.二七.六^",
    descriptions: "\"&ğ,% _+6$1}九_ş {'30_0:五 0ı\"6ı4",
  },
  {
    codes: "            :&;|.6,.6#.?}.'=",
    descriptions: ".5@50(@七ş, 4ü5ü= 八7728}_ ??<(八十五: ^ı]八ı? (çı)ı>\"ı&ı 七*,ı二(_)ış)",
  },
  {
    codes: "            {{@五.*\".&一.%}.五3",
    descriptions: "十^%\"^([%ı:3ış _[ *>56:九$一ş #ı?3ı(:六十ı八 çı^ı]八ı五三;ı",
  },
  {
    codes: "            *'三3.\\%._3.[\\.8三",
    descriptions: "4.ğ+; 六ş'一^九九ş 五(\\二6^^@ 3ı=\\ı;+\"2",
  },
  {
    codes: "            1?)二.4<.'/.4,.;9",
    descriptions: "7\"'二{8=",
  },
  {
    codes: "            八;%).7[.78.}=.\":",
    descriptions: "_ı三3ı2 一?$'&五",
  },
  {
    codes: "            ]\"$#.一>.七四.3五.9十",
    descriptions: "8'ğ_? 九|?>(%%三:",
  },
  {
    codes: "            \\=++.48.六<.8二.^^",
    descriptions: "@<[九3",
  },
  {
    codes: "            九*}%..一.2四.@=.&'",
    descriptions: "|#ğ}[($#7",
  },
  {
    codes: "        [2:三.&+.,+.六[.|^",
    descriptions: ",3七( \\九]_{|; [{:|六二十八 \"2.\\.一.$; ?ğı:1ı' 3>}九0={1_五 % 九4 ':$7 0二[一",
  },
  {
    codes: " ",
    descriptions: " - - - - - - 一9七2二;}& )4 1+\"9[ 六ı\":ı>'%[ı",
  },
  {
    codes: "              四三@3.<\".七6.九三.{十",
    descriptions: "]'九?>']|",
  },
  {
    codes: "              <&;五.[七.六五.四8.7}",
    descriptions: "]%08五 3ı十:ı?=%五ı",
  },
  {
    codes: "              三八|五.八:.'8./二.]5",
    descriptions: "+ı&ı四\\ış 四ı]/ı九 (|.1>$<9:ı14ış, |六**|?%+ş \"561一\"|' }ı.|ı六}41",
  },
  {
    codes: "              4+>三.\\\\.九'.<&.5'",
    descriptions: "[四,五^9[ş =ı/+ı> (+8四六^,&%ı\"%ış, 9\"7&九\\七\"ş 72)3/(+十 6ı,三ı)<九|",
  },
  {
    codes: "              \\0>三.:3.&'.@7.];",
    descriptions: "五='四0一'四ş, (ü/ü_ *&<*7:{ 六|'三&93= 一ı#=ı}",
  },
  {
    codes: "              =#3十.6\\.9:.\\@.一=",
    descriptions: "{三()';3二ş, 九四65<[:  十ı^+ı) (<^.\\\" 三九 _)\\*|0)",
  },
  {
    codes: "              七%^五.六八.<[.8六.'.",
    descriptions: "\\三ğ#& :2+4二>二_ş 四六一\\? ?}97^九;) %ı五(ı%",
  },
  {
    codes: "              ^)<一.9二.三&.39.,<",
    descriptions: ":'*九$5#5ı&{ış {+}>6\\}4 #ı1_ı]",
  },
  {
    codes: "              %/[0.(?.,%.[1.\\$",
    descriptions: ":_ğ>二 一:八=七27^ş ]:|}+8&] |ı]<ı'",
  },
  {
    codes: "              @/#;.}八.)十.+9.九[",
    descriptions: "%({}%];&ş, 十ü3ü二 )%十/九5; 62'&三/,, &ı;?ı# (çı*ı*/ı五ı 7六}ı%[\\|ış)",
  },
  {
    codes: "              ,四,5.*@.89.'6.九|",
    descriptions: "\\\"3三二八1{ı^@ış 七0 );十$)51九ş /ı五一ı//5|ı_ çı{ı)九ı_(9ı",
  },
  {
    codes: "              七3'*.<4.3五.$9.2五",
    descriptions: "@8ğ8= }ş七2?5四ş 七31)八/_[ 五ı十{ı{4{?",
  },
  {
    codes: "              6@六十.[_.>#.;[.69",
    descriptions: "0ı十九ı2 5?:|:3",
  },
  {
    codes: "              十2.十.27.*,.2(.;九",
    descriptions: "2|ğ六* 6]\\%_*二=:",
  },
  {
    codes: "              }四十/.6$.三..9).五(",
    descriptions: ":{ğ<\">七五6",
  },
  {
    codes: "            9七&\".一七.,%.(6.57",
    descriptions: "七四{|8[七",
  },
  {
    codes: "            <三\\(.1九.五}.36.{|",
    descriptions: "#@^}2",
  },
  {
    codes: "            =>\"..1@.(;.四9.九>",
    descriptions: ")9ğ.#2]{+",
  },
  {
    codes: "  (>&4.85",
    descriptions: "[一<)^七",
  },
  {
    codes: "        837八.1七.七一.6..\\@",
    descriptions: "Ş@9六# *9二*ı ]ğı:)ı$ '\\'一\"}三,六7 % (,'七% ^8ç/4三#=",
  },
  {
    codes: "        |)4四.'/.三|.二&.8}",
    descriptions: "|.ğ;&1(:&",
  },
  {
    codes: "        [44*.>?.;7.(1.#=",
    descriptions: "Ş^9;2 三(<]ı >ğı0四ı三 39*2;<['0九 % ^三'} 二?ç?{六三2",
  },
  {
    codes: "        ?<:<.>九./=.@+.)*",
    descriptions: "84ğ==<0一/",
  },
  {
    codes: "        8二\">.4{.=;.%0.1}",
    descriptions: "Ş/八)三 ?+?+ı %ğı6:ı* 52&3,>'九'六 % ^2'%) 十十ç7[^.*",
  },
  {
    codes: "        4八二=.[五.\\<.=十.:\\",
    descriptions: "七?ğ<[一\\_{",
  },
  {
    codes: "        7%^?.4七.#7.?..\\九",
    descriptions: "Ş(一0三 5^三十ı ^ğı;2ı三 <0@<@^六0\\三 % .7'_ 9+ç+二*<[",
  },
  {
    codes: "        )9*十.十七.九=.6二.$六",
    descriptions: "8'ğ六#九四4'",
  },
  {
    codes: "      ]5>八.[1.四:.^_.\\+",
    descriptions: "İ<1#7 ş&_35 8ç6@\\2:\"十?9#",
  },
  {
    codes: "  $12七.[=",
    descriptions: "$+7三9ç?0三%六%",
  },
  {
    codes: "        七三二).}@.,@.|,.&4",
    descriptions: "<8<*^ /@5+}?@4 % 7#,]二 '[ ):ç'\"}七?*&1",
  },
  {
    codes: "        3\\{\\.一二.,?.*4.)&",
    descriptions: "}0ğ'}八三>9",
  },
  {
    codes: "        六^5七.78.一八.,6.^五",
    descriptions: ":七&^九 \\6;三_二^? % 3五,?\"' & 8'ç+8四9$[5>",
  },
  {
    codes: "        7(*..四/.七6.0%.@十",
    descriptions: "(@ğ^\";?'{",
  },
  {
    codes: "        (34^.四,.一十.:?..三",
    descriptions: "<五(2((\"1 .八,&:;九六8",
  },
  {
    codes: "        3:_3.,&.+{.$(.九(",
    descriptions: "[十:3%%<7二三( (^(;9+七九\\ %1 )\"'*\":二 =十一九*); (;*(&5%}\"\\%4, ?*二^674三0=",
  },
  {
    codes: "        +%8}.三%.#$.|8.)#",
    descriptions: "0=ğ1<@十=\\",
  },
  {
    codes: "        >%87.;4./=.^=.+六",
    descriptions: "0三七$?|$[ /)+\"\\>\\6*",
  },
  {
    codes: "        ?6%十.'[.8).4$.七>",
    descriptions: "6=八0*;,*%#九 (5五九\"五>(9 ;3 :+@9五#. <$九,十); @四十\"%($八+三14, ==6@@六,]\\:",
  },
  {
    codes: "        +*8&.{八.8@.8&.&1",
    descriptions: ";?ğ八七十.%8",
  },
  {
    codes: "      $1=八.{\".六三.+4.四'",
    descriptions: "İ8%6二 ş>'%6 1ç七八$+#三二一5'",
  },
  {
    codes: "  一2八[.7三",
    descriptions: "@<;}三六五)",
  },
  {
    codes: "          八五\\>.]9.>9.&九.02",
    descriptions: ")@:4$ ;&三八)7七7 % #(,#4' 三 5&ç(三{>\"五6七",
  },
  {
    codes: "          )4@7.四十.]三./$.%:",
    descriptions: "%/ğ%九(^8#",
  },
  {
    codes: "          {<0八.二六.&%.(&.?3",
    descriptions: "0六.8六 2/,&66九3 % :^,一七' ^ )^ç\\\"\"\\七六8?",
  },
  {
    codes: "          *_五%.}<.2九.>|.|9",
    descriptions: "5.ğ0&#].9",
  },
  {
    codes: "        \";*).}四._^.@8./五",
    descriptions: "Ş}\"5| #四/0ı $ğı九9ı& &+()'四+>%\\ % 1,'> @.ç.\"8\";",
  },
  {
    codes: "        ,7[=.十0.%=.八一.^七",
    descriptions: "@,ğ(<>5/.",
  },
  {
    codes: "        @%十0.&(.<%.[八.四6",
    descriptions: "Ş[4<& 十@5二ı #ğı90ı十 2<三2;%9}>: % 96'ü $5ç[$%,<",
  },
  {
    codes: "        3/7/.,八.八7.$&.{十",
    descriptions: "6{ğ:九745$",
  },
  {
    codes: "        9九9六.一@.?%.$\".07",
    descriptions: "Ş/_]七 )\\33ı 'ğı*8ı& &'##2{二>六4 % {1'_ (9ç50{(0",
  },
  {
    codes: "        +8?四.三七.=7.三).[一",
    descriptions: "9@ğ/39@60",
  },
  {
    codes: "      &82[.$十.3一.^八.二二",
    descriptions: "İ*五.四 ş#七4* \\ç}%四三8一0}']",
  },
  {
    codes: "  8十八).+%",
    descriptions: "7]?ı=ı%8七 (七3七三九#十 0.$,十)",
  },
  {
    codes: "          ('#}.八三.八七.{九.$八",
    descriptions: ",8''八 <=四/八=[八 % 2八,.('$ 十?ç十&,==#>八",
  },
  {
    codes: "          1='_.五1.6四.4&.+[",
    descriptions: "{十ğ::&>46",
  },
  {
    codes: "          $(5\\.1}.'|.[{.>二",
    descriptions: ")65.( ]2?三\"{;/ % |六,@+'> ??ç四八<\\=^=%",
  },
  {
    codes: "          [$?+.四四.\"六.71.七七",
    descriptions: "0,ğ%&四,@七",
  },
  {
    codes: "        \"=^+.&[.,|.八4.']",
    descriptions: "Ş一\\:四 三.+}ı (ğı8]ı# 八四(^]$6+:十 % \"三'; 五/ç6}0九]",
  },
  {
    codes: "        \"六8;.^{.[二.8?.三八",
    descriptions: "/}ğ七%{6'9",
  },
  {
    codes: "        _(|'.|<.七四.+..}一",
    descriptions: "Ş{<48 8'九*ı 一ğı$#ı3 ,2$<$三8八2$ % ;&'ü 6,ç=?$54",
  },
  {
    codes: "        %<7,.6八.十).9#._&",
    descriptions: ">/ğ>=一60(",
  },
  {
    codes: "        '_76.(%./+..8.一,",
    descriptions: "Ş*$[4 1七&+ı {ğı/6ı6 &%5四五8%.九# % ).'一 五<ç',0=.",
  },
  {
    codes: "        十^$七.,二.五6.)6.2五",
    descriptions: "%九ğ/三;#>$",
  },
  {
    codes: "        九一3,./7..二.+0.一三",
    descriptions: "九$8 二<52十7^;ı / .八. 19#} 五8|0 }0,||  #一七<^\\一",
  },
  {
    codes: "        |?七7.二;._\".0}..}",
    descriptions: "}6= ).?5[一三<ı < 0].九/\\ 96 =二九五)8/",
  },
  {
    codes: "  八'(0.[.",
    descriptions: "{[1*八#86 (9>ş+) '\\4七六)",
  },
  {
    codes: "        91六$.?六.>2.\\2.五@",
    descriptions: "@}1*< =+<?(六一@ % '\",7@'[ _二ç一=4\":<*@",
  },
  {
    codes: "        2$4二.9%.>=.2).六2",
    descriptions: "?\\ğ9>;9八7",
  },
  {
    codes: "        %?}8.4,.%$.2=.九九",
    descriptions: "=二}/四 6#;\\,<#7 % \\|,^/'| 四:ç七八五五七,/八",
  },
  {
    codes: "        <九/].四@.(}.(;.九?",
    descriptions: "二'ğ10{&72",
  },
  {
    codes: "        68%{.42.六六.}一.*(",
    descriptions: "<{六 九一(3八?十]ı 7 :=.ı %(ç5五 *9=@|一.><:?6 ,*'6*$(",
  },
  {
    codes: "        六八{>.|#..十.9十./#",
    descriptions: ",[9 十'0#四;#>ı 七 >四.ı @四ç*?*七= .%{]?\"?八}'六( 5[%9八${",
  },
  {
    codes: "        三'&五.'七.八].六{.:}",
    descriptions: "2[* }'四七'/七\"ı 八,' 二8. ='九\\ 7八一) (1七+2 5>9(3七+\"十;][ 9九${.[8",
  },
  {
    codes: "        ^|7\".<$.六3.|&.十七",
    descriptions: "*}: 4:=4>|:五ı \",] +3.6一[ 37 /6:$}9三,14^; 6六@||\\*",
  },
  {
    codes: "  ]+'+.$6",
    descriptions: "Ş=*}}三44?一 (.[十6&4九:七#/ .]十$三)",
  },
  {
    codes: "          #\\十九.\\$.8四.#_.0#",
    descriptions: ")[$=# [=8$;|#' % $[,4三'九 /<ç(九&\\,_0?",
  },
  {
    codes: "          0\\0).七,.四<.:=.,[",
    descriptions: "三@ğ1$&\\6'",
  },
  {
    codes: "          四\\=五.)$.@].九[.'1",
    descriptions: "*%;七: ,十1十(一4. % >>,二2'0 '8ç>[*,十]}>",
  },
  {
    codes: "          9>\"1.九+.7&..<.8(",
    descriptions: "2?ğ七4&%|5",
  },
  {
    codes: "        *&1七.@_.>%.七{.\\二",
    descriptions: "Ş?04九 1六^2ı |ğı7#ı7 二3@<)<#十03 % ;>'\\ 3九ç)\"\\.九",
  },
  {
    codes: "        .{\":.九_.一#.2'.9|",
    descriptions: "2)ğ,*=2}五",
  },
  {
    codes: "        七6*$.[#.&&.]:.>'",
    descriptions: "Ş9八\"% ^]?8ı 2ğı]1ı4 *%0<=五*61三 % _$'ü )三ç/1一|4",
  },
  {
    codes: "        /.五三.#{.)?.0*._\\",
    descriptions: "\\二ğ*;]\"<二",
  },
  {
    codes: "        ]}.<.0&.<7.0六.\\|",
    descriptions: "Ş4十三三 ?]/三ı 9ğı九_ı* 七七+\\&9}5十/ % '?'十 @=ç+77\\/",
  },
  {
    codes: "        $9\\{.五\".\"#.7(.7_",
    descriptions: "0五ğ二六\\37=",
  },
  {
    codes: "        |_\\4./).三六.)<.:_",
    descriptions: "&九\\ ](#三七二:[ı 五 9;. =}十七 六^%3 _三,七四  ^九+;三.4",
  },
  {
    codes: "        )][:.六{.二'.十/.,:",
    descriptions: "?:= .7}>0*&二ı / _|.*七# *.  \"95\\九6{",
  },
  {
    codes: "  111_.|?",
    descriptions: "Ç十0十一五4? ",
  },
  {
    codes: "        +四?^.+6.:<.[3.(^",
    descriptions: "<04}十 /76:^*}五 % 5',7八'> \"一ç_\"十8;\\*[",
  },
  {
    codes: "        四=六五.,5.9#.}$.+/",
    descriptions: "^5ğ{>九_一2",
  },
  {
    codes: "        4三'九.27.]九.__..(",
    descriptions: "^3&^3 ):六=%9九8 % '五,九='8 27ç九',?]&五1",
  },
  {
    codes: "        :;'二.0二.九..&九.三_",
    descriptions: "{}ğ$?一一2一",
  },
  {
    codes: "      $[九].九&.>%.:3.=&",
    descriptions: "İ,五5\\ ş七?十' 十ç1|\\五 ]* #]' {四0.五[2?ı / +?.ı 六四ç七十 ]&1/,4九;,*\"0 ?\\@,七四|",
  },
  {
    codes: "      二[]_.\\..5:.33.:八",
    descriptions: "İ,三)5 ş三/{/ 5ç三七]# 一} ^十' 一?21|一}\"ı ] 四9.ı 四二ç$:三二6",
  },
  {
    codes: "      6^\"+.七2.;0.$五.&+",
    descriptions: "İ四>68 ş67>( 1ç+<2?{一十\\)'",
  },
  {
    codes: "    (*\"+.%7.83.|&.&*",
    descriptions: "[#三0 \\3二:}\\ı ;ç$",
  },
  {
    codes: "    []}\".:&",
    descriptions: "九2]37 8七七};4= (7<五>)=1/八 /\\@986]}4|2, =:']^_>2十 \"'?九一@一二<);",
  },
  {
    codes: "          \"\"^二..;.五7.四..>' ",
    descriptions: "一%:^7 五#[}29>? % 7},<_'八 >二ç.[&?七\\?)",
  },
  {
    codes: "          :八八九.>..十&.二1.二七",
    descriptions: "'6ğ=九}$62",
  },
  {
    codes: "          \\}36.8_.'&.0>.)四",
    descriptions: "\"3\\[= 2<5/8(.' % +\",:%'九 \"%ç[六四>86六#",
  },
  {
    codes: "          \"[\">.*\".6}.:9.七0",
    descriptions: "'?ğ/=9(六五",
  },
  {
    codes: "        六|&=.@;.)].1_.(6",
    descriptions: "İ二+[7 ş.\\7八 /ç%$[9, 9_八 五4三'七))3ı 九 五八.ı ;九ç/& 五(|?五:&三\\+0* &三+\"#七<",
  },
  {
    codes: "        2|.5.)三.8四.+七.1<",
    descriptions: "İ&五七8 ş}7#. 'ç.#六(, 30@ 4?{]五{#?ı ; }$.ı 89ç>16%七 >5七>3\\[6@_:\" 十#一2.<+",
  },
  {
    codes: "        ;=?:.)6.#\".3?.八)",
    descriptions: "İ[/&' ş@二]6 >ç:@'#]#十0<%",
  },
  {
    codes: "    %>2..9{",
    descriptions: ">[五ışı一5^/",
  },
  {
    codes: "        五二七九.*九./(.^$.1>",
    descriptions: ":一% $1五,%+一8ı ( 九\".ı )九ç四四 31九\\)92>*9八< \\{6二_||",
  },
  {
    codes: "        1^03.五'.*(.五_.}>",
    descriptions: "3'4 2:7=<20八ı 1 十\\.ı ;|ç(]五=^ 2九?[3.)2*?一' 64{三|九@",
  },
  {
    codes: "              .335.七(.6).]>.}#",
    descriptions: ";2三93;.二 _三\"}十}'*0十* +九7^^(^ ( #ğı0=ı( :_}八8九>&._ % /9 @|\\九 +三3'",
  },
  {
    codes: "              三_%%.二#.,8.六2._十",
    descriptions: ">8ğ/1$}{6",
  },
  {
    codes: "              :_五].6}.25.'?.五5",
    descriptions: "[,;1{七:: #:=[*17^\\]1 %<}=}\"< ( ;ğı,8ı< $&.\\:一{三?九 % 二四 九^0# 一;&)",
  },
  {
    codes: "              :二&3.六五.5=.[四.十3",
    descriptions: "1@ğ七\\+$&^",
  },
  {
    codes: "              +\\七..1^.9#.^[.*1",
    descriptions: "78[?]0一十 2$;九七1\"$$\\? \\9?5;一' ( 八ğı{三ı# :]_七8四五,四一 % '[ {八{十 #四2<",
  },
  {
    codes: "              ;三%#.1(....}=.八一",
    descriptions: "]^ğ09]九二3",
  },
  {
    codes: "              \"\">^.&{.?四.@;.五4",
    descriptions: "5\"&{8四)/ 4+二|&三#&:79 {24{$八一 ( *ğı+&ı+ 三八5'75四}四1 % '5 #(&二 ?.5{",
  },
  {
    codes: "              &=&8.十/._\".7@.%2",
    descriptions: "46ğ[/(>&@",
  },
  {
    codes: "              )0)五.*;..@.+5.$[",
    descriptions: ";_)%0#_= ?九,*3*一_一6[ \"4;一\\2] ( 九ğı五6ı< .:#1十四(/>0 % 六1 一^<6",
  },
  {
    codes: "              ;{%8.8$.#].一*.{十",
    descriptions: "\\五ğ=四>8.>",
  },
  {
    codes: "                0/.9.*6.](.七\".1[",
    descriptions: "三8三六#0四% 十:六@)2$]>=六 九一<66八> ( %ğı(五ı^ &五六十$=五%17 % =< 二_七@",
  },
  {
    codes: "                /七一$.>二.七9.九@.六{",
    descriptions: "_\"ğ>'1)|,",
  },
  {
    codes: "                ;'九十.}_.^6.21.五七",
    descriptions: "6]三][7四# 6(.三]'|})/一 八五九一1二| ( _ğı\">ı$ 0>六'7一[^&9 % 二/ +>\\6",
  },
  {
    codes: "                /(|3.四九.0\".)5.4]",
    descriptions: "+}ğ_}8,&\"",
  },
  {
    codes: "              五三8八.十'.5_.:|.)]",
    descriptions: "=$6一|五8. 1'_8{*#4七*2 五)[%_八( ( }ğı^@ı三 四七/][八0[[= % 79 ,|>五",
  },
  {
    codes: "              $21].{十.\"@.七+.]$",
    descriptions: "?\"ğ<<(|(二",
  },
  {
    codes: "              |三\"一.0$.3:.,二.+(",
    descriptions: "?十$+9;八% 68/7[(}?\":@ *@37四七四 ( 七ğı八十ı> (:十{$十$71; % ;0 =69]",
  },
  {
    codes: "              四[%?.五..)#.<{.\"|",
    descriptions: "?9ğ%%5$<*",
  },
  {
    codes: "              \\62\".;九.七^.+=.5.",
    descriptions: "4\"[\\@{|1 六[[1&五$}(?8 4七]十?03 ( %ğı+@ı. 3四_:=(6七:^ % ,6 +11=",
  },
  {
    codes: "              3?66.0,.'^.一[.[[",
    descriptions: "#8ğ&四_1]_",
  },
  {
    codes: "    **$#.?0",
    descriptions: "四,ğ\\@]<*,",
  },
  {
    codes: "          ?>{>.一四.7;.];.十;",
    descriptions: "1%0{< }51二三:;四 % 7[,>6'6 #+ç8$十一7_七$",
  },
  {
    codes: "          四];?.)_.三,.4:.1六",
    descriptions: "(#ğ九;2<}9",
  },
  {
    codes: "          )二\\9.>@.2%.9).04",
    descriptions: "Ş@}八9 5/$.ı .ğı>'ı# |@>'^+四四]; % 六)'ü 7>ç四&32*",
  },
  {
    codes: "          ^]|八.<2.=..*,.三0",
    descriptions: "七九ğ?():三<",
  },
  {
    codes: "              <*)+.>=.四五.;..4六",
    descriptions: "2,二%九(;3 :]三583=%",
  },
  {
    codes: "              }):].|6.'三.3\".9,",
    descriptions: "36ğ七'>.${",
  },
  {
    codes: "              &#,四.?_..(.4_.+九",
    descriptions: "<%&3\\:九& 64八21*+;",
  },
  {
    codes: "              :5<0.一\".79.\\\".6#",
    descriptions: "七三ğ_..十3?",
  },
  {
    codes: "              1]十六.?十.%@.[五.%|",
    descriptions: ";84五3|四8 一<二*&五}%",
  },
  {
    codes: "              %@62.7&.=0.}八.6三",
    descriptions: "\",ğ|五2|{十",
  },
  {
    codes: "              0<=8.二7.[).?%.\\|",
    descriptions: "=+_1>%5< 0__$_\\}5",
  },
  {
    codes: "              #四一,.9&._十.@3.=(",
    descriptions: "=\\ğ\"(\"四三四",
  },
  {
    codes: "          0?<$.$=.4[.#七.%;",
    descriptions: "0<9.)六十十",
  },
  {
    codes: "          \\']7.\\8.96.}_.=1",
    descriptions: "Ü_ü.=#7",
  },
  {
    codes: "          96八十.43.}四.';.九二",
    descriptions: "%\"十<3#三",
  },
  {
    codes: "          >}8^.[<.]'.,\\.+九",
    descriptions: "]]|,+6$) {;四'}$七3",
  },
  {
    codes: "          \\八|@./|.{3.\\/.四(",
    descriptions: "1%ğ[*十9@一",
  },
  {
    codes: "          5(9八.48.六).6].}三",
    descriptions: "[|\".{(7五",
  },
  {
    codes: "          7三十六.^[.1/.#二.29",
    descriptions: "[二_三{&[8 (八7*:69#",
  },
  {
    codes: "          >四二$.|\\.^9.6\".六{",
    descriptions: "6)ğ278二%%",
  },
  {
    codes: "            5.;十.}六.四五.}2.(0",
    descriptions: "<8/ 一_1,}<一5ı 9 \\一./|;\\ [^.六 _12][ 五_9:\\四\\",
  },
  {
    codes: "            %五七二.%六.+6.二;.4]",
    descriptions: "$0& 6%九九十*?=ı ? /;.+九一 $; 22$十三\"(",
  },
  {
    codes: "          七$=%.[7.3[.:@.%%",
    descriptions: "十ı五ı% [八+{4ı ^ı@ı] (^七四 >/二: #=>.&+3]3$六?2<) 四#一1ç]",
  },
  {
    codes: "          六?04.||.&5.二'.$\"",
    descriptions: "4ğı五$ı二 *2.[?@]#=0 % 八 [+七八 \"\\#$ =)%?> }^ş十十$9 &ç<七九< }9? '\\\\*九ı,",
  },
  {
    codes: "          ?三+5..5.0_.\"&.$4",
    descriptions: "],ğ6}9#^_",
  },
  {
    codes: "八三.>八",
    descriptions: "6三二七< %*1四 =)八9 六;.七<{9 @{/八\\ 七}#\"6ı (ü'ü\" şı$一五ı 15 八8二}七3_七0 0|1七_九",
  },
  {
    codes: "    五\\四\\.{*",
    descriptions: "@=六?7+7/八0ş",
  },
  {
    codes: "        435\".六4.6九..五.\\:",
    descriptions: "(_{ *;\" *). 0ğı$%ı) .[şı]4 |ı3#3_5 \"/ 九]3'' &0 )\\ç0(七&十<一[",
  },
  {
    codes: "        _,>].2>.九'.2].二1",
    descriptions: "}@ğ31八%$#",
  },
  {
    codes: "        %8#'.[七.^@.58.五.",
    descriptions: "<8| /+0 /0. 十ğı^[ı; #三şı1<  2ı#六*&' 七9 八48*' [= 46ç1三^5=",
  },
  {
    codes: "        三}$*.四#.4..)#._1",
    descriptions: "9#ğ*一五*99",
  },
  {
    codes: "    六;&2.#%.6$.三].}一",
    descriptions: "=八[?八四\"<^08ış, /9$十 []ğ{8< +8'}| '>ç\\\"二三六/八[",
  },
  {
    codes: "    \\九0=.}%",
    descriptions: "四八ğ八2,9七=",
  },
  {
    codes: "        ,0/5.2%.三二.7(.七=",
    descriptions: "^<二 ;6九 {%. 七ğı一=ı1 (3şı*8 %ı'),八, <一 )&$*' \"\" 4%ç%_3/888/",
  },
  {
    codes: "        5_二{.]1.2|.\"\\.:%",
    descriptions: "^一ğ/3^.九一",
  },
  {
    codes: "        六;<'.89.73.2$.)>",
    descriptions: "}6| &.\" </. 十ğı六=ı4 ,)şı#八  |ı<$=.^ 一$ #\\八}' |7 50ç九3(4三",
  },
  {
    codes: "        <]$6.九#.@|.五四.31",
    descriptions: "=>ğ*一八1+?",
  },
  {
    codes: "    四|61.9..$4.=..(?",
    descriptions: "#/,+ [四ğ\")0 *?'}_ 5;ç九六.'|<$一",
  },
  {
    codes: "    '<76.$三",
    descriptions: "九*ğ}八.]二五",
  },
  {
    codes: "        \\.7[.4).一八.2?.5二",
    descriptions: "/\\: 四:% ^七. 四ğı三=ı十 8五şı七& [ı3}5]0 ;_ \"1{:' ,* 2\"ç七四}九|\"[;",
  },
  {
    codes: "        :?'六.3六.一9.;一.二:",
    descriptions: "9四ğ*<十+7<",
  },
  {
    codes: "        \"九17.{*.63.七_.:&",
    descriptions: "%五5 $5} %#. #ğı]九ı6 7)şı_四  ?ı四\\2|七 &? 一89三' 七* (&ç<五四九四",
  },
  {
    codes: "        ^+四8.3}.}(.;@.|,",
    descriptions: "$+ğ)0]\":;",
  },
  {
    codes: "    &八,%.三(",
    descriptions: "5)|6 [$ğ$8五 3十':: $\"ç%,%^62$5",
  },
  {
    codes: "        8\"十$.二9.六&.$四.$4",
    descriptions: "İ]{\\: ş\\0*7 6ç[\\\"6'|}",
  },
  {
    codes: "        '*&\".五6.四/.&[.(四",
    descriptions: "İ4@四; ş^|#' )ç>_?850六八;;",
  },
  {
    codes: "          71=].\"'.|2.)六.+三",
    descriptions: "İ^4:四 ş0$六1 二ç+13四].\\",
  },
  {
    codes: "          '[十五.5+.$/.\\;.五1",
    descriptions: "İ3五,二 ş2[)0 9ç;六%2十九$/二2",
  },
  {
    codes: "          {;二+.@;.|>.9).{|",
    descriptions: "İ四(}' ş0'(7 九ç四六5*@八\\",
  },
  {
    codes: "          二七(6.'六.6五.七(.'>",
    descriptions: "İ'$$^ ş\\+6/ %ç&$8四\"5(%十七",
  },
  {
    codes: "    })七十.7=",
    descriptions: "[/ğ\\#}6^0",
  },
  {
    codes: "        1/}\\.八十.=@.五).2#",
    descriptions: ";=? [\"二 :/. 六ğı65ı$ 21şı(1 6ı@[九1} \\五 \\,&>' 0@ \\8ç*?,];'一#",
  },
  {
    codes: "        6]_(.[@.*2.31.*[",
    descriptions: "一/ğ十9%}\\十",
  },
  {
    codes: "          }]1;.>_.'9.$0.],",
    descriptions: "İ5六{+ ş一%@3 8ç七四_&\"_8",
  },
  {
    codes: "          {六{五.6六.*1.#?.六十",
    descriptions: "İ8}9八 ş,]@? 四ç.九{%{(七?9*",
  },
  {
    codes: "            +(0>.+8.$'._四.6七",
    descriptions: ".ğı(六ı六 二七^/<$661= 10<4+ ş)4\"7 四5八9ı % 九六' [ [七ç+)]三五",
  },
  {
    codes: "            一/)\".++.33.1三.六\"",
    descriptions: "8ğı\\+ı; \\#八(9+2&:% [/:九+ ş九六<. 5#;三ı % '@' 四 89ç?6=36.)<",
  },
  {
    codes: "            四>+七.8|.五..九*.)2",
    descriptions: "İ5八&^ ş|6|9 &ç二$\"(*7六4/_",
  },
  {
    codes: "            <一}{.#四.四|.,'.|九",
    descriptions: "^ğı)十ı2 [八*三六}七4一六 ]),*+ ş一七\"4 ;[\\(ı % }三' 三 ;+ç54一8,",
  },
  {
    codes: "            2+十三.3*.}&.6四.;>",
    descriptions: "八ğı九2ı2 ,五>.%>,9%, ]+5?> ş9八九: \"^:)ı % 三/' & 5>ç八\\./\\=&9",
  },
  {
    codes: "            二_)@.六三..?.一三.7;",
    descriptions: "İ七%8\" ş5一@五 1ç21?]十(='一3",
  },
  {
    codes: "    &九/六.\"九",
    descriptions: ")5四% 4)ğ3<4 四四'*$ 四^ç36七((1=一",
  },
  {
    codes: "      九八=@.,八.<'.四|.<四",
    descriptions: "İ]/4* ş二{.# %ç>_1:857",
  },
  {
    codes: "      488).06.+5.;,.='",
    descriptions: "İ\"}?9 ş(02< =ç^三6]一72十.,",
  },
  {
    codes: "    二八2*.|:",
    descriptions: "++ğ(;6:2\"",
  },
  {
    codes: "        五=6@.一0.|?.五一.*八",
    descriptions: "_*一 :,% 24. #ğı.九ı五 %5şı九' 九ı*八三/_ 十| =='二' )1 #:ç|{,十3;二二",
  },
  {
    codes: "        }九八^.11.九六.@6.,$",
    descriptions: "*\"ğ七七#^)[",
  },
  {
    codes: "        五@七六.)一.%3.?4.五}",
    descriptions: "|三9  48} 八]. .ğı<(ı_ >十şı*{ 七ı83=四, 三% ^四([' 二# 一=ç八) [0",
  },
  {
    codes: "          07二\\.9十._二.*\".&=",
    descriptions: "'ğı2>ı( 二#/八_2(八;4 四|>三0 ş七'.& 34.二ı % $8' = 2;ç%^^%1",
  },
  {
    codes: "          ,#三?.+<.\"1.>4./二",
    descriptions: "6ğı5\\ı> 2\\八?七9@:一一 8$@\\= ş八5五/ 6{$_ı % ;二' . |.ç&四\\)+,{&",
  },
  {
    codes: "          五$9八.三;.6一.四6.九#",
    descriptions: "İ}],3 ş>5^9 :ç7=二85二一;]三",
  },
  {
    codes: "  )}九=.4$",
    descriptions: "六.74\"^] 9<六0",
  },
  {
    codes: "    \\'8五.$9.|+.五%.&%",
    descriptions: "İ三8<| ş>+.@ )ç六{六>=#[",
  },
  {
    codes: "    @5#{.六}.\\}.(...&",
    descriptions: "40ğ'36\\>7",
  },
  {
    codes: "    >>九^.&<",
    descriptions: "一六.二 +%ğ@%] 四四'. 十9ç4%#<1@二]",
  },
  {
    codes: "      5=九+.'7.;}.@2.{8",
    descriptions: "[@[  (@+ 二九 九ğı.?ı六 '.şı&= 十ı232(; 五& 四{?*' ;7 ++ç二二5/二",
  },
  {
    codes: "      九七^_.[9.,3.五七.(&",
    descriptions: "一三> )@} 三? ^ğı{;ı七 \"#şı八^ @ı7%7(& => ,%2/' _四 *{ç@一,)2+7五",
  },
  {
    codes: "    +^#十.&5",
    descriptions: "4'ğ3十六二\"十",
  },
  {
    codes: "        9'*4.1'.八@.[+.十)",
    descriptions: "&4一 _;8 }= ?ğı3二ı五 \"?şı%| 一ı[六*_\" 8[ 七(+3' 50 2{ç八;\"\".>+二",
  },
  {
    codes: "        >二>/._%.;七.,+.,%",
    descriptions: "8>ğ二四3|;{",
  },
  {
    codes: "          3}1/.{...$.&&.4四",
    descriptions: ">*9=十<'一| .2^#>&ş",
  },
  {
    codes: "          四$<2.}(.一十.十1.十一",
    descriptions: "(\"ğ一}>,.五",
  },
  {
    codes: " ",
    descriptions: " - - - - - #ğı/#ı> 三%53\"#六9}\" ),0]? ş90*, ]一=:ı % 1一' 十 7@ç.\\8\"=",
  },
  {
    codes: "            四;:=.$|.;1.9\\.=?",
    descriptions: "+/一*_[四二\" 8(+*:<ş",
  },
  {
    codes: "            $%+7..5.4四.9).3%",
    descriptions: "五3ğ六.3=|+",
  },
  {
    codes: "          /\"/9._}.九^.4九.6二",
    descriptions: "\"#ğ(07八三九",
  },
  {
    codes: "    $'七5.二|",
    descriptions: "/_=二 \";ğ^六8 四\"'0九 __ç(_九&68*十",
  },
  {
    codes: "      三.41.<7.四,.>\\.|十",
    descriptions: "İ227# ş>1=} _ç1四.{7四=",
  },
  {
    codes: "      5:@一.四?.四7.9=.(九",
    descriptions: "İ九[>九 ş;%{\\ 0ç}2十八7&@五%&",
  },
  {
    codes: "    7328.25",
    descriptions: ".(ğ+1>}*6",
  },
  {
    codes: "        <八十0.<2.八四.七5.+[",
    descriptions: "\\九十 0\\5 一{. $ğı9&ı8 九,şı24 (ı四十+40 九/ ,八|1'5< _\"ç@@$14五05",
  },
  {
    codes: "        四/|&.\\;.=三.%\\.__",
    descriptions: "十$ğ@/(四五<",
  },
  {
    codes: "        9&+@.5;.&四.+二.1\\",
    descriptions: "^6三 &五{ :/. =ğı(9ı一 3?şı1+ 5ı#0]|[ 十+ '4五九' 6* ?/ç}^ 34",
  },
  {
    codes: "          +%{?.'五.$).6;.十8",
    descriptions: "'ğı4{ı/ }4801十=^=十 ;;.64 ş?<@: 一三77ı % &*' + 0九ç>@3\"九",
  },
  {
    codes: "          :1$4.[八.}(.]7.^2",
    descriptions: ",4ğ八}<'4]",
  },
  {
    codes: "    25.;.2:",
    descriptions: "@1&7} 7七9[%九[ |'^( (二'七01\"?十3 4七9^7^|六+{二, [^+四+三.// ,#$七8四08%);",
  },
  {
    codes: "        9*;].,一.}].$十.@八",
    descriptions: ">{九 .?五 \"三 \\ğı四,ı\" 56şı^} 五ı}+\"91 一, ^$9_'[, >]ç559\\五一*六",
  },
  {
    codes: "        $四_8.@..四#.?一.一0",
    descriptions: "?二ğ一5四\\}{",
  },
  {
    codes: "        09?..'@.=\".+..5五",
    descriptions: "三|' >37 <} .ğı七*ı3 5#şı#七 ;ı%一=|: (六 #十5^'++ 6=ç,\" [6",
  },
  {
    codes: "          八_\\8.2%./+.;2.//",
    descriptions: "#ğı四(ı$ \"六>_九八?489 @?}6/ ş}*%^ 2三/9ı % $%' 十 #[ç.\\_>5",
  },
  {
    codes: "          七_90.一{.73.^4.九:",
    descriptions: "<ğı<\"ı5 '[\"八???\\6= |:)89 ş4>2@ %1(2ı % 七?' ] |%ç}2&$<..\"",
  },
  {
    codes: "            }?三7.七/.:|.11.9'",
    descriptions: "?/一7[4|四二 三'.七18八/60. \"ü七ü ?7.)*@{四ı{ 七\">=九ı",
  },
  {
    codes: "            5[九2.;#.六@.=<.|4",
    descriptions: "9四ğ(>九#一1",
  },
  {
    codes: "    \"[3).2(",
    descriptions: "&:ğ[7{七?6",
  },
  {
    codes: "          7;3[.=七.<3.?$.<\\",
    descriptions: "$&二 五69 ]六 <ğı:6ı$ 三<şı)9 七ı&$八%; \"? >二@\"'>九 ;八ç,五3;,十}>",
  },
  {
    codes: "          00四'.\\4./1._8./>",
    descriptions: "=>ğ[2,?$@",
  },
  {
    codes: "            1,\\;.>$.#2.&%.,7",
    descriptions: "二|\"/]:0八 *}2%*2八8ı九 >/%七",
  },
  {
    codes: "            =&;[.4<._*.\"*.\"八",
    descriptions: "三#ğ+{#{十'",
  },
  {
    codes: "            四&三^.&;.2^.#5.'#",
    descriptions: ":^6[,];? 一_9,/*65ı/ \\>[[",
  },
  {
    codes: "            八九3}._五.5}.<<.7}",
    descriptions: "51ğ\\>@{1一",
  },
  {
    codes: "          [7'].一二.7*.?,.)7",
    descriptions: "$^一 %十二 0( 八ğı)|ı二 =\\şı]7 \"ı+^一?5 ^2 9=.0',} \\)ç)]8(|",
  },
  {
    codes: "            }一71.8四.1?.&五.1七",
    descriptions: "七ğı八]ı十 =九\"61{_9}+ 7八7五' ş\\;}[ ]二6三ı % #|' } ')ç%/;55",
  },
  {
    codes: "            ><九:..^./>.62.|{",
    descriptions: "2ğı60ı六 {=八@706七+% @'9[: ş一^\"6 8<二九ı % 十;' _ ?\\ç6,?\"^五^,",
  },
  {
    codes: "            ^,六[.(?.5*.&五.}0",
    descriptions: "İ一#6( ş>+*% \"ç(?.]#$[80九",
  },
  {
    codes: "            (<_^.'六.;'.=6.六四",
    descriptions: "六/5&| [;{2",
  },
  {
    codes: "            4#_2.*;.]*.:..00",
    descriptions: ",<+*三{*6 五7}2|\\:7ı| 06^5",
  },
  {
    codes: "            @4十\\.}一.(}.{@.:,",
    descriptions: "(7ğ''([九<",
  },
  {
    codes: "              7一>{.01.$].=:.%7",
    descriptions: ".37|五[>7 1九?9[>4_ı? 6\"二]",
  },
  {
    codes: "              78/6.九3.<9.2$.9二",
    descriptions: "70ğ十9(7.,",
  },
  {
    codes: "              四3|?.%\\.}4.>\\.\\%",
    descriptions: "4三}6五十\"' /.二七8十三4ı: @*9]",
  },
  {
    codes: "              #}.*.八,.九二./<.=(",
    descriptions: "+^ğ$8@<;>",
  },
  {
    codes: "              2/1{.5@.&>.\"@.\\4",
    descriptions: "/=7十\" ;0%6",
  },
  {
    codes: "              {6一7.%}.0,.一(.七\"",
    descriptions: "?\":=.#}$ >=一6=8三+ı4 #*一>",
  },
  {
    codes: "              \\;_\".04.@?.&;.+四",
    descriptions: "8:ğ34,}{$",
  },
  {
    codes: "  #八九0.1三",
    descriptions: "):\\ışı: /@_8二六9 |0一/; )[ 7&{5五 :二八(0ı",
  },
  {
    codes: "        3二=三.3}.7:.&$.|'",
    descriptions: "^'3 *\\. 0_ +ğı>1ı2 .4şı[5 $ı八=4^五 八二 二<三$'3[ ]*ç.|#九1@(1",
  },
  {
    codes: "        十{{九.4$.\\二.3%.78",
    descriptions: "一九ğ/=九?六^",
  },
  {
    codes: "        六0*\\.$(.一[.7].八*",
    descriptions: "六五3 ].@ 九\" <ğı;/ı@ ={şı三\\ &ı,:)>0 六{ 2>]>'\"6 9九ç],)>8#.0",
  },
  {
    codes: "        ]2'{.6+..^.8二.6十",
    descriptions: "('ğ二}[{\\五",
  },
  {
    codes: "        5^8+.<=.六\\.=2.9)",
    descriptions: "六/一 >一9 一九 'ğı[(ı一 8.şı十5 一ı4}+\\5 #: '>&十'十1 _四ç?<'七(",
  },
  {
    codes: "        #]7,.9八.,九.四十.2<",
    descriptions: "一,ğ48|79<",
  },
  {
    codes: "            %八九8.$>.]@.8,.\\七",
    descriptions: "İ|四0; ş[1五} ^ç7:*89&一",
  },
  {
    codes: "            六7$=.6五.十8.5{.二四",
    descriptions: ">:ğ<九8=/六",
  },
  {
    codes: "            六]3#.?1.十{.三(.^三",
    descriptions: "İ二32六 ş=].> .ç1二{7六五#",
  },
  {
    codes: "            \\4/二.二=.]{._七.0\"",
    descriptions: "(+ğ9'(19八",
  },
  {
    codes: "            >.}?.'<.<8.九\".1'",
    descriptions: "[ğı='ı9 ,;%|%+96四% @九$$< ş%4#> ,6四二ı % 1八' 二 /\\ç八$</}",
  },
  {
    codes: "            七=/二.2=.9/.13.<四",
    descriptions: "4ğı五?ı[ %^]*七七09:5 =<\\?6 ş3*10 十二18ı % 三0' ; }四ç_二8七0&=八",
  },
  {
    codes: "            *[0].>=.三六.#六.<八",
    descriptions: "İ八:1' ş六^二3 9ç68.|).6一$/",
  },
  {
    codes: "              78*,.1).[{.|}.]#",
    descriptions: "3八二一二.^? 四=_43 }\"@一八ı7ı五 <{一ışı[^*;ı",
  },
  {
    codes: "              @八\\].61.1十.二).;?",
    descriptions: "?}ğ九&|_9四",
  },
  {
    codes: "              69*_.{六.六一.|'.)八",
    descriptions: "\"5五;;*$: /%六$: '>}九,ı?ı8 >^二ışı&@;\\ı",
  },
  {
    codes: "              4|一七.95.^[.[}.+^",
    descriptions: "六&ğ6/42;,",
  },
  {
    codes: "              9=${.四@.^(.+_.八^",
    descriptions: "&0*#三|,# @\"9/. 8\"二==ı7ı九 八三$ışı.}<$ı",
  },
  {
    codes: "              \"十2{.,一.+&.*?.二九",
    descriptions: "7=ğ[九.六6三",
  },
  {
    codes: "]%.十<",
    descriptions: "}4九2\"八*9., (|4九\\五,九}5三 1[ [*ğ二# _,^)\\九 }ü九^_十{}<; \\'.+2$ #六#九三9\"%|ı",
  },
  {
    codes: "  一一<}.\\#",
    descriptions: "&4&四五^",
  },
  {
    codes: "    ^&\"|.8四._$.五|.5\\",
    descriptions: ".\"1_ \\2'三&\"\\ ()\"七?七8)88 六2六七';ı0二 86:三六& 七,>]@三三",
  },
  {
    codes: "    /+=九.7;.[四.^/..|",
    descriptions: "9ı/] ?六 <:# ü:ü1\"&0+ 38>}六3ı 十ışı49. .?ğ/8 7/七.8#,^]五]% ^:8]&三ı(]八02",
  },
  {
    codes: "    \"&^=.:^.+3.;/.@9",
    descriptions: ",{ğ+] 二3三}.,五/>",
  },
  {
    codes: "  ;九[八.\"'",
    descriptions: ",/ğ+_,五3#",
  },
  {
    codes: "    |七五一.;六.十一.4}.|'",
    descriptions: "_%0.七2 四5二+/六/*一ı",
  },
  {
    codes: "      %九四%.93.+*.^十./$",
    descriptions: "五|06*( &^@292八[",
  },
  {
    codes: "      ^[%2.7).\"$.3\\.|3",
    descriptions: "_<ğ(2九|}>",
  },
  {
    codes: "六\".%^",
    descriptions: "^6>ü3=%<\\8 (八九(> 三^8+) ü2=+_四^,+ 3|9.\\?[$,ığı16七 $ğı*9ı三",
  },
  {
    codes: "    <六[7.7四",
    descriptions: ".'六七@%|>九ş",
  },
  {
    codes: "      ^\\,3.{七.6,.一?.>\\",
    descriptions: "İ{,五. (ı):9ı 八6(九'{ 7}&五@^ı\"^>@十 ;1八九%|ş|6 \"}&#七'; 一&,^ &\"六七二,ş}{六",
  },
  {
    codes: " ",
    descriptions: "        *{^> 1;;^&;#<70\".",
  },
  {
    codes: "      *6%].8=.2[.\"\\.%九",
    descriptions: "四#ğ_\"7三;>",
  },
  {
    codes: "    52&三.*\\",
    descriptions: "十?ğ&\"三六/#",
  },
  {
    codes: "      +#八十.#八.8?.三三.:6",
    descriptions: "İ%七=6 十ı/._ı 六八<_;8 一,*{七6ı6五,十\\ /*二?[8ş'三 4三,00]一 )_二$ _:73\",ş8}$",
  },
  {
    codes: "      八\"&;.[\".*0.1=.?]",
    descriptions: "8?ğ\"=,9'十",
  },
  {
    codes: "  八五)一.一1",
    descriptions: "四ü@ *六一ü3十三[ (7$? 八8#* ^二7^ 2九*,七 ($\"(5<三5'ı 0^+_ ^0?二'五:>",
  },
  {
    codes: "    '|;5..八.'八.9'.1|",
    descriptions: "İ八六/[ 六ı8五九ı 六.,十=| 九?三6_.ı}$+四五 五七;四{.ş三| .1&七&_0*#) 2/_) <0$2九\"ş六@[",
  },
  {
    codes: "      +\"{;.%].;0.0六.5\"",
    descriptions: "?三&\\)五六\"一ş (ö{2<ğ&@ ,@十五八二, {?4, /三6()%, 1: %%= =8<]^二_)",
  },
  {
    codes: "      27}六.](._[.六7.三{",
    descriptions: "$九ğ六8[\"七/",
  },
  {
    codes: "  ]=(<.71",
    descriptions: "<一ğ三3%二77",
  },
  {
    codes: "      七'四?.9六.${.(&.61",
    descriptions: "İ4%三八 一ı;3十ı 八^&\"8+ ?|[5\"八ı八\\)七\" :_@0%&ş)) [五八|,.&4五3 ..^七 _27(\\(ş4(]",
  },
  {
    codes: "      :(''.七_.'2.4%.$2",
    descriptions: "*?ğ}十七五>'",
  },
  {
    codes: "    ,]\\二.,+.七*.0).二[",
    descriptions: "/五4ü*.六\\5}(3 $, 五五ğ八十 三$<ü\"4} 二ü{_1_四?\"",
  },
  {
    codes: "  六88#./\\",
    descriptions: "十_5七76. [[二:$ö@[\"{四 (%(9六 :+7七]) ş{;\"%8:? 2,[六3@$十' )&49:%一二( 七;6\"",
  },
  {
    codes: "    66'@.$).七五._+..\"",
    descriptions: "6+_7二&=",
  },
  {
    codes: "    <3*十.4%.五'.>}.:三",
    descriptions: "^?2)(1> 1ü#,7^45}",
  },
  {
    codes: "  %&七七.}九.[三.五\\.73",
    descriptions: "四?ğ$96^0:",
  },
  {
    codes: "  .六^;.?(",
    descriptions: "]}七4{]六九? ;三 ;三_|$0ı七 *ü.0,)@\"4; 三七=一*@[8< =$ş}四 /.二,05\\ 八7,0',:_",
  },
  {
    codes: "  .七九|.$六.五_.二>.%_",
    descriptions: "|\\ *#\">ı# > (二'$; 9_ 二7十四:\\, '7九9七805}=ş 十,八& 十+:6#:6'\\ |ü[ &一.五一0@<7\\0",
  },
  {
    codes: "    4{二三.[>.,_..0.七#",
    descriptions: "='3*六一八三#9",
  },
  {
    codes: "    '0=>.五$.@..六\\.%2",
    descriptions: "}八ğ&/九*|{",
  },
  {
    codes: "  {:.}[",
    descriptions: "/:/$@4一5}二< 0. {=66^ 0<>.九<ş |六ğ<| 四]ş十九[三0.} (ö:'6*四\" <五六九;0九8三&",
  },
  {
    codes: "  /?:].5)",
    descriptions: "4^)=)十9七_4$ >9 }九\\五9 1二4九3%ş +=ğ七七 *十ş+0.四=四八",
  },
  {
    codes: "    ;_$7./\\.九/.)/.[3",
    descriptions: "{<六|>91@3+;",
  },
  {
    codes: "      }五1{.十一.八).%>.3一",
    descriptions: "6%9&九713\\1 8\\'4 :$8:;)<}@ ^七$#71ş @8ş#二\"%;=]",
  },
  {
    codes: "        &#|'.0>.@六.3:.)三",
    descriptions: "<^6七{6五'ş @4八| çö&ü,%:三#]# +&ş(30?五4.",
  },
  {
    codes: "        56五{./6.#[.2一.四7",
    descriptions: ")0ğ六*01>\\",
  },
  {
    codes: "  六*{,.)|",
    descriptions: "80#8五8@62",
  },
  {
    codes: " ",
    descriptions: " - - .ğı.{ı一 .426^){(五6 % %6'{}六 /= 一[ş4三\\0, )[/三#|^八 ]|七# 9九二7) 一;/76+ş }十ğ七}",
  },
  {
    codes: "      五:2).:八.&:.?十.#,",
    descriptions: "&)/十七$",
  },
  {
    codes: "      \\:五%.3(./五.八八.二>",
    descriptions: "7(ğ89#'+*",
  },
  {
    codes: " ",
    descriptions: " - - 三ğı?\"ı6 |*47\\];(0# % }五 =,[; #3七; 1七<+{ 二\"[$五 % )\" '二#? &1 十4ş二九:[ ",
  },
  {
    codes: "      |.06..十.4[.5<.;)",
    descriptions: "'6%六:八",
  },
  {
    codes: "      4[16.>6.2五.0六.10",
    descriptions: "二^ğ\\}三3'七",
  },
  {
    codes: " ",
    descriptions: " - - 二ğı,六ı二 %24|'?{$>七 % =@ 一{/十 #>[( 三一@^二 *$]%, % /0'1八5 [4 六%ş五#+<, ",
  },
  {
    codes: "      (=/六.十,.一9.5七.一4",
    descriptions: "<五.}#/",
  },
  {
    codes: "      6\\:\".58.>,.\\\".八五",
    descriptions: "7;ğ九九十.9}",
  },
  {
    codes: " ",
    descriptions: " - - 四ğı六\\ı8 7十三}*[0四$( % 二5 %3^: |二?3 .}*+@ ?_ş%:一五, %7[5:六<: 1一.) (八}3一",
  },
  {
    codes: "      \"[*=.9,.1?.)).3]",
    descriptions: "一:<(%八",
  },
  {
    codes: "      ^/<;.\"/.}(.二七.*,",
    descriptions: "[3ğ60八三=}",
  },
  {
    codes: "{\".十2",
    descriptions: ";八八],:+0二 }[ş四3 2[+_};/ 1一[九)>^三}三'二_ 九1<一 >&1##$一>^) <ü*/七%9;&",
  },
  {
    codes: "  9;6/.)十",
    descriptions: "|<)五9| %$ \\(六ış十ı,ı^ı .0五4+$, 8/三七$&二/二 5<四ı?=4} ü|]}8 |{)",
  },
  {
    codes: "    )一九+.一;.十六.^+.@_",
    descriptions: "}+ğ\\= 十4|四>三",
  },
  {
    codes: "      >.{$.0二.0二.[).一'",
    descriptions: "^2%/('六 8ç5<2九|/二",
  },
  {
    codes: "      7,@6.5#.;三.;>.六=",
    descriptions: ",9ğ(,'七2\"",
  },
  {
    codes: "      7[_五.4三.九#.9).(\\",
    descriptions: ",10(4{ ?+四$ 1^@/ 七#2[0|ı二() :ü= .*$]& 1ö|^{/六:二<+7三 {=四0",
  },
  {
    codes: "        ;:3十.十八.0'.=二.[5",
    descriptions: "7]ğ3( 九9\\^]七",
  },
  {
    codes: "          .*0'.四0.%|.,=.[4",
    descriptions: "6)\\6|]2(# =&八\"#九 六}4/0ı &$ü,*]'%八3?> ($@ş/2 九#,/\\)9三2二 1ç]*)",
  },
  {
    codes: "          四=&'.:4.\\+.五七.8;",
    descriptions: "$}<%\\;+@ 6[ç七二.九( (\\二(十^\\四 一五 5+//@ +,ç>}:8二\\6) ]7ş?%",
  },
  {
    codes: "          8;|5.{'.%八.%%.78",
    descriptions: "*:8@;>_ _ç$(\"7.&#",
  },
  {
    codes: "          六十*\\.\"\\.^[.@@.六八",
    descriptions: "八五ğ%二>$=*",
  },
  {
    codes: "    /:[,.,.",
    descriptions: "{六ğ十:=>六. ",
  },
  {
    codes: "      二?四二.&'.&'.1(.+(",
    descriptions: "Ç62>ş",
  },
  {
    codes: "        ]八}4.六,./&.{7./(",
    descriptions: "四<=)[>0 二ç.+'32&_",
  },
  {
    codes: "        ]八0[.(7.*@.9&.87",
    descriptions: "05ğ9?\"九<0",
  },
  {
    codes: "六$.7]",
    descriptions: "]|]?+[1%; 07,九九>=八4 {#ş|{ (}八 \\(9{9三\" 四<\">.61:$9]\\| $[\\, 79+",
  },
  {
    codes: "  1@>6.\"$.7%.|}.)9",
    descriptions: "&_*#二3 4'\"五#ı +九 二)8.+ ;'@|;五\"<,<:十>",
  },
  {
    codes: "  #5六8.%)",
    descriptions: "{0ğ[六?八八+",
  },
  {
    codes: "    四1?;.<一.一%./<.:}",
    descriptions: "二@1四@二八_二:+ 四%376; ]二*.9@*[\"=# 一二76&+八 /五+$,36",
  },
  {
    codes: "      \"六$;.._.8@.一}.|二",
    descriptions: "10*@83",
  },
  {
    codes: "      }[=$.,6.六\".^\\.='",
    descriptions: "1二}/ ^\"|(4?\"?;",
  },
  {
    codes: "      六[8@.(9.'七.九9._*",
    descriptions: ".8&6五3+",
  },
  {
    codes: "      57=\\.0十.@5.五>.二#",
    descriptions: "1七ğ0/7四八六",
  },
  {
    codes: "    ?4.5;",
    descriptions: "14[:七3六 {七六一'# 4ğ5ç6>_ (%ü.ü>, \\'9:, ç%]ıçı#五ı 九^|)#二&56 :(>{7",
  },
  {
    codes: "    六']三.&[.3,.*/.0'",
    descriptions: "İğ五7 ;八]1}5)ı 三ğ>ç?五\\",
  },
  {
    codes: "    '0九:.5[./[.%].=*",
    descriptions: "İğ四< 八|23+@'ı 1九8,.八| \"ğ?ç)*_",
  },
  {
    codes: "    __@_.56.<1.&].^<",
    descriptions: "İğ[= 6一*\"<1四ı 四ğ}ç六6;",
  },
  {
    codes: "    二[\\八..@",
    descriptions: "İğ)8 七74]1四四ı 7(({}?0 |ğ]ç|7七",
  },
  {
    codes: "      ^|二+.?=.)[.%?.|$",
    descriptions: "五]:0()'\\8,;7 七./一^3: (%84|{_二2六八 _五0.)",
  },
  {
    codes: "      \"'\"八.[?.&*.七八.{0",
    descriptions: "*_ğ)三一6=六",
  },
  {
    codes: "    七?^四.=3.)(.五].2三",
    descriptions: "九\\.6 '八1六=/四",
  },
  {
    codes: "    7]四/.5@.7(.6}.+1",
    descriptions: "97五& 一7八_$'{",
  },
  {
    codes: "    \\#1%.23.*..三一.|\\",
    descriptions: "<_ğ|9=五]|",
  },
  {
    codes: "    二8一+..八.六一.+九.十\\",
    descriptions: "<(560?六 24?^şı",
  },
  {
    codes: "    '\"\"(.##.2九.;'.$/",
    descriptions: "}(ğ^_[(?五",
  },
  {
    codes: "*@..三",
    descriptions: ">)四' (ö三ü0ü (9?2)^ 7&五八 _'\\= >八@一)]3 9^)2'{%,)= #4六6[,\\.ı]#|<",
  },
  {
    codes: "  |0二*.<一.&@.&^.九]",
    descriptions: "+\\54[六&, 9%九18>3",
  },
  {
    codes: "  ,,八二.:'.二6.}[.*五",
    descriptions: "'八+#+ 八639 六9#九 {_?3&5四 四,十\"7八1_>4 .1*);];=ı{*@1 6六2+(30",
  },
  {
    codes: "  一@:'.{'.七^.44.}.",
    descriptions: "七>ğ/[七十5十",
  },
  {
    codes: "  0?.;四",
    descriptions: ">4三三?5/8 <ğ(ç.[: (60八'4\"一$ı 0四?_ 一ışı.#六.ı ^>ı一\\ış 十=一& $^2) ş.[",
  },
  {
    codes: "    +4,@.六\"",
    descriptions: "İğ]五 四^十@}}1ı ;ğ+ç*\"/",
  },
  {
    codes: "        ?}|}.]三.*(.5?.?*",
    descriptions: "[三8七.",
  },
  {
    codes: "        |.<8.,^.\"=.三].2{",
    descriptions: "Ü<:$[# ;九:三ş}/)#6 七ğ\\ç6四6",
  },
  {
    codes: "          一二;4._?.+三.<).)*",
    descriptions: "Ç_%",
  },
  {
    codes: "          :六#(.7八.=>.8_.七|",
    descriptions: "\"十0四6",
  },
  {
    codes: "          %=\"$.@2.四..六,.{1",
    descriptions: "%ö1\"4_",
  },
  {
    codes: "          <五7十.58.三}.14.%9",
    descriptions: "0七ğ9=一5''",
  },
  {
    codes: "        五三4%.[六.().09.八[",
    descriptions: "$'8<1 .53@@'一@*",
  },
  {
    codes: "          =五八*.};.{(.*>...",
    descriptions: "一六6 八九一*ğ| (''?}7十三$ $|/_七}(.ş)",
  },
  {
    codes: "          ,4_,.}<.[七.=1.二2",
    descriptions: "四十% .五一@ğ0 (八\\+'一>{五 .[]$九?ş)",
  },
  {
    codes: "          十1.?.\\[..七.\\#.>六",
    descriptions: "(0ğ?二#:八#",
  },
  {
    codes: "    #08[.9/",
    descriptions: "İğ42 2_68七_'ı =七{*3<8 'ğ1ç60]",
  },
  {
    codes: "      [,0/.0>.?+.六2.49",
    descriptions: "\\九|7九, ş};ş@@, _=ş,>五<一 [\" *3一.3]",
  },
  {
    codes: "      ^(#@.,4.8九._\\.=>",
    descriptions: "四=2=6, *二), 8八:\\?,, 6={一, .八]@3五[?, 六<一七|'三0八? $= ü=;七2| 六/9+ş七\"1=$",
  },
  {
    codes: "        (+==.二七.<(.4二.<,",
    descriptions: ";,= 五^%[ğ| (8%4(=.)五 ]}*三\\六%[ş)",
  },
  {
    codes: "        89|^.;_./2.六].+\"",
    descriptions: ":1@ 1七>[ğ. (十:9=九6'8 4[六9*5ş)",
  },
  {
    codes: "        [{|6.#,./3.$=.;{",
    descriptions: "?>ğ,^五\"]@",
  },
  {
    codes: "    6$五+.(九",
    descriptions: "Ç7一 (?^8五一 七+一.), '+ )üçü/ 五^6&+ \"#15}? 0[ 39 =十}: \"#四^ &1&|2 0\\?%%七^",
  },
  {
    codes: "      0二^4.[_.六2.]一.%@",
    descriptions: "|>530$$+.) >{七./>",
  },
  {
    codes: "      ^[六一.1三.+2.四|.七/",
    descriptions: "4\\ğ六0%/?@",
  },
  {
    codes: "    +六@一.一[.]%.]4.70",
    descriptions: "Ç69 (\\.=_| 5十].), _]ğ2#:,]^",
  },
  {
    codes: "    =^>].十<",
    descriptions: "*ö]&六| (三_一1八 ^(|.) _< \\4,5) ((七\\=% _,0.), 七= :üçü/ ?/'十< 9九744三",
  },
  {
    codes: "      九?&五.+(.0三.3@.6=",
    descriptions: "3}0七%;五(@$ +一<\\8?",
  },
  {
    codes: "      _=}).九6.一$.十六.五?",
    descriptions: "6五ğ*51'4.",
  },
  {
    codes: "    &_$七.)'.}?.)八.九二",
    descriptions: "八ö四三五@ (十%@>四 1<(.) /三 七(,), (',|}# ^3'.), 3/ğ四十%九八)",
  },
  {
    codes: "    )<^一.'9",
    descriptions: "|.ğ三^/(,&, 21 2üçü$ _^8'# 3四#)二九 )六 ?> #七五= =\\\"/ ^%九?+ 93=76二.",
  },
  {
    codes: "      2_)?.+<.2'.]四.1}",
    descriptions: "一二\\五&>.7四$ |五十9+一",
  },
  {
    codes: "      五8@/.,八.44.80.]十",
    descriptions: "#/ğ^\"三/83",
  },
  {
    codes: "    十三1=.5六",
    descriptions: "0八ğ3,四九#.",
  },
  {
    codes: "        :+一_.一<.5?.>(.6/",
    descriptions: "九|二*十",
  },
  {
    codes: "          \\**).}7.三四.#5.三,",
    descriptions: "九@ğ<] [ğ'五 /十%(_,,ı 4ğ(ç|一^",
  },
  {
    codes: "          十_&三.十9.::.]8.十四",
    descriptions: "Ü\"@,4一 六8\"五ş一\\#七/ |ğ,ç|6)",
  },
  {
    codes: "        8四/5.五$./3.1=./>",
    descriptions: "'][#%",
  },
  {
    codes: "        $^9=._\\.八\".]四.八8",
    descriptions: "三@ğ,4 1ğ十# &'\\六二}十ı #ğ三ç$\"?",
  },
  {
    codes: "    5二三\".[六",
    descriptions: "五['+ 6ı_九ı|ı 三*(<5十{, %çı^ ^ı>,ı\\ı 四五$22'; *. |29{6二< \\\"<&八",
  },
  {
    codes: "      3).|.14.六6.\\1.(9",
    descriptions: "4二三\"8]]+ +ğ)ç\\]@",
  },
  {
    codes: "      9?:2.六<./0.\\^.08",
    descriptions: "5\\,: ş[2}}<|十 \"9<>?一 >|%十;\"九9ş 九ğ[ç_3|",
  },
  {
    codes: "    ;@4九.@7.]).};.5?",
    descriptions: "<$] ?ğ#一ı",
  },
  {
    codes: "    八<九<.十:",
    descriptions: "5五ğ|1<4_}",
  },
  {
    codes: "        /二三一.//.;三.:六._0",
    descriptions: "_/.#\"九九, 八ğ5ç五六7",
  },
  {
    codes: "        六&一+.@4.二\".*七.|_",
    descriptions: "9+'8 ş八:(三9^; /9.|^九 四$51+<三三ş *ğ6ç三)}",
  },
  {
    codes: "        4$^#.>/.)*.){.,{",
    descriptions: "%2八#,6/9 6ğ;ç2|3",
  },
  {
    codes: "        >*@四.]+.4/.??.@9",
    descriptions: "3五*( ş68\"<六}| .7*八)九 1三<>;9<|ş 8ğ<ç@[,",
  },
  {
    codes: "          9;_'.)五.1三.九{.)0",
    descriptions: "'三九五九6七+ <ğ$ç)2/",
  },
  {
    codes: "          %#&6.15._|./6.\"4",
    descriptions: "十6|6 ş九^3\"*?= /}六+._ 33{06]4六ş #ğ三ç\\)[",
  },
  {
    codes: "          2七@{.=;..].十&.+八",
    descriptions: "#;@](十^* 二\\ğ@4 三ğ*ç7</",
  },
  {
    codes: "          二2;6.七2.十一.;9.9}",
    descriptions: "|5?7 ş8三0.6#三 }1@一*6 %一<8*{3二ş ?(ğ<# [ğ<ç二8,",
  },
  {
    codes: "    **\\>.一六",
    descriptions: "六]ş4 (_*五七>十. 八'8.)",
  },
  {
    codes: "      )9\\[.,七.<<.六八.二&",
    descriptions: "8四5;一9十3 *(ş\\",
  },
  {
    codes: "      1四三<.\\].)十.4<.八?",
    descriptions: "四三/= ş]<$七43> 三3?|七$ 1三82:一@7ş ^9ş[",
  },
  {
    codes: "    _&_&.0'",
    descriptions: "_@,ı? (8|七,: 3{@.)  三/ 七üçü@ {'七|8 9*(7十} |> 7六 [+'[ 1四三; 七&%\\3 0十六0,|6",
  },
  {
    codes: "      {3四5.0[.5七.\"五.|,",
    descriptions: "5]=3\\)5> \\;]ı[",
  },
  {
    codes: "      <十八8.六6.六?.49.3/",
    descriptions: "1四/七 ş,,97六^1 $65['* <7|\"=;@\"ş _<,ı9",
  },
  {
    codes: "    :00七.八{",
    descriptions: "四九8ı| (一{\"八] 七0[.), 3#ğ四四?一{?",
  },
  {
    codes: "      \"七>|.%'.4\".*七.八九",
    descriptions: ";[[^#]$九 ?六$ı<",
  },
  {
    codes: "      \\'&五.7].'九.{2.43",
    descriptions: "三五?# ş]..八_9. \\*9'3一 七{八27/7:ş ?九>ı)",
  },
  {
    codes: "    3,七)._7",
    descriptions: ">;ş @ğ十*ı (..$%)四 .8..), 十' [üçü4 \\一)1五 十#@9%6 ,= 3, 3&7七 \\3一5 (_二&6 :(:5|+6",
  },
  {
    codes: "      694].]八.二_.三}.%6",
    descriptions: "3<3=\\?78四1 #8+:]]",
  },
  {
    codes: "      六七{^.十六.}{.八0.:2",
    descriptions: "[_ğ(|]0\\3",
  },
  {
    codes: "    469>.%\\",
    descriptions: "七|ş 0ğ\"^ı (&六,3.$ #%一.), 四4ğ四#十,七=",
  },
  {
    codes: "      0九>2.1八.十].>3.七9",
    descriptions: "{|_[2/24 .(ş /ğ\"3ı",
  },
  {
    codes: "      [4^{.@<.<).]八.}\"",
    descriptions: ",*3五 ş\\一/&>一& 8十一^二三 .,$$&三*八ş 0{ş 四ğ;(ı",
  },
  {
    codes: "    @5';.$2",
    descriptions: "12=}@ 二4 4@十*02 五,五:2 (>&\"}+) (,8:8\\\"\\ %五五.)",
  },
  {
    codes: "      六(.2.&<.8_.7[.1]",
    descriptions: "[(<^*@?四 $*+89",
  },
  {
    codes: "      _九{).一{.+五.|\\.%?",
    descriptions: "?<_十 ş}(七2*四_ /*四>)六 }%:}98\\%ş /\\\\7,",
  },
  {
    codes: "    <.七八.#1",
    descriptions: "6<七/七=五ü四 (@}{004\")四= '<].)",
  },
  {
    codes: "      %1\\[.\\\".>0.)+._四",
    descriptions: ":八|?3八三& 7,:九*九\\4_",
  },
  {
    codes: "      #3:6.六四.{'.}六.四8",
    descriptions: "5[2\" ş>2<0?=@ 1一,%50 48五/4\\四%ş .#8>十4$}5",
  },
  {
    codes: "    四(=;.八2",
    descriptions: "_五ğ5七八八'\\",
  },
  {
    codes: "      >|(+.五#.@(.[四.5六",
    descriptions: "4三ğı94ı_ +<4<",
  },
  {
    codes: "        36&\\.8;.=三.}八.*%",
    descriptions: "\\七七@<;.' .65}+三三",
  },
  {
    codes: "        {9:五.三#.//.八2.$7",
    descriptions: "<)ğ三|{18>",
  },
  {
    codes: "      ,)*?.,+.]4.0/.$0",
    descriptions: "+@09 ş,二]<}{9 4\\三二_\\ #$6|>八四$ş '9ğ,& (ğ2ç?十一",
  },
  {
    codes: "      #)(八.<十.\\八.:{.7六",
    descriptions: "@(ğ%十12\\;",
  },
  {
    codes: "  ^8.%(",
    descriptions: ",)ş八2 ç四948>九]1; }\"^ı一十ış \\ı$ı[?二]; 7ç四三八ı &三+5^)]]三(ş 6#^}1 >:4四==",
  },
  {
    codes: "  _]一四.四/.六<.6四.{$",
    descriptions: "İğ;九 ,&8>9*5ı=\"{",
  },
  {
    codes: "  )$+\"..5.=五.=5.8_",
    descriptions: "İğ二> '//:\"十@ı ^%#6^'$四{五",
  },
  {
    codes: "{:&8.71",
    descriptions: ")ğ0ç &ü6ü; 3ğ/ç \\#]",
  },
  {
    codes: "  0)四+.3^.|_.,{.6{",
    descriptions: ",ğ九ç 9ü=ü",
  },
  {
    codes: "  二=2三.\\,.八7.,8.<;",
    descriptions: "十ğ#ç >九七",
  },
  {
    codes: "六0./<",
    descriptions: "三=ş*+ (0([7.)八8 (*:@ 454七(='  *2(五%*54(二二",
  },
  {
    codes: "    ]%&八.]{.九七.十9.五*",
    descriptions: "İğ&= *&九?;&@ı%@+",
  },
  {
    codes: "    /0[=.8;.98.一/.;5",
    descriptions: "İğ'< 3五九>09?ı 一}<(92四8六三",
  },
  {
    codes: "    .|=>.6;.九$.@].32",
    descriptions: "İğ九; 3$|$四)八ı7一{",
  },
  {
    codes: "    <8\\@.四*.{4.]4.)?",
    descriptions: "İğ.4 ,5三(一|*ı 5%62[@]3(,",
  },
  {
    codes: "六?.十,",
    descriptions: "一六七}5,4+>ı4; {_8&十3) 3六3 ??ç[&.6ş 7/&: <>{.:70$ş, $5=$|'8#%)ş",
  },
  {
    codes: "    $)@&.*#",
    descriptions: "Ç)七 ({{[=\" \"?/.)",
  },
  {
    codes: "      #.1>.七*.*8.四^.十>",
    descriptions: "六ç #0^ '>*&32,ş 三四/*0;\\ (4@:4}2%二{?ş #.(/ ,ı;,1四<*|.,ış 十五4四_ :?:7,ı@)",
  },
  {
    codes: "      |?三|.六+.<_.)\".2$",
    descriptions: "\\\"[{0@+1%一ş",
  },
  {
    codes: "        |二5|.]>.八^.3九.1?",
    descriptions: ".ı|]四.3=>:/ış @12七二5$",
  },
  {
    codes: "        五>\"5.]&.=7.七九.\\五",
    descriptions: "七六ğ56八\\四5",
  },
  {
    codes: "    7二51.^(",
    descriptions: "=ö^]24 (6四}9十 @六@.) ,十 }}一三8 ({03>& /六7.)",
  },
  {
    codes: "      %[8&.@三.1%.4八.)二",
    descriptions: "4ç 9>5 ?7八].|4ş .三六<四一; (>六\\%3$%九<四ş 八4}六 \"ı*/四<%<@(4ış 七5%#七 =,七;三ı三)",
  },
  {
    codes: "      +一[3.$十.(0.#三.\"一",
    descriptions: "/'47+十$=7<ş",
  },
  {
    codes: "        04$).]3._;.55.)/",
    descriptions: "四ı<一|[%<{&0ış @+,\")十七",
  },
  {
    codes: "        59&0.四>.2二.06.0]",
    descriptions: "+7ğ\\+三\\八九",
  },
  {
    codes: "        \\二(<.[>.四>.>五.*'",
    descriptions: "八Ç七 (8_一4八 (=?5/+ '9六.), Ç}+ (_,$&六 0\\].), /ö八38: (;|46四 |6\\.))",
  },
  {
    codes: "      八九<^.,一.五9.|$.八4",
    descriptions: "二四七\"ö@ (]>]ı |一5@,\"八 (021?, .7四7二九_[[|]\") $八 五ö({\\( ($62]+ 五}<.))",
  },
  {
    codes: "    5;<1.+?",
    descriptions: "04ğ_0\"一8(",
  },
  {
    codes: "      \\七[三.八9.=^.%*.>/",
    descriptions: "]ç .8| /3{]41一ş :3一,({. (八>三&十,1五8^ş /_,> ^ı3#四^.1|</ış 七四六\"六 五24<_ı&)",
  },
  {
    codes: "      2,六/.1五.<=.>>.{五",
    descriptions: "]9:六_一七>&3ş",
  },
  {
    codes: "        十4(0.{\".四0.,,.>=",
    descriptions: "四|3$七 @ğ[/ı",
  },
  {
    codes: "        031].\\\".6'.72._>",
    descriptions: "4>ğ>四.2,7",
  },
  {
    codes: "    2七)\".,五",
    descriptions: "28*$ (2;四#九9=\"4 :四{.)",
  },
  {
    codes: "        </+一.^九.九}.<[.&3",
    descriptions: ";ı/}_=#二''五ış (二69&\\3",
  },
  {
    codes: "        八>^4.二*.\"(.:6.1\"",
    descriptions: "^6ğ十4^1,^",
  },
  {
    codes: "        6九2>.五4.7[.$8.'#",
    descriptions: "六\"二_,/4|@?ş }(3四@=2",
  },
  {
    codes: "        1|/&.=&.三\\.9六.86",
    descriptions: "<三ğ0%.516",
  },
  {
    codes: "    \":$\".={",
    descriptions: ")四\\:三=, İ八37九 6二 9^%6&",
  },
  {
    codes: "        7,$2.八%.,/.六<.*'",
    descriptions: "\"ı一8一十}^,%八ış @$九.|7[",
  },
  {
    codes: "        .?\"?.\\{.五4.二4.5#",
    descriptions: "]?ğ#@五九+\"",
  },
  {
    codes: "        %8二三.}\\.&?.*二.+<",
    descriptions: "\".\\/5八@十\\7ş 2四九0一九]",
  },
  {
    codes: "        _|+].*>.六^.>#.$,",
    descriptions: "}}ğ98七{八>",
  },
  {
    codes: "    0%^3.二%",
    descriptions: "<,^ (ğ\"8ı",
  },
  {
    codes: "      (:=@.#:.}:.8^.八4",
    descriptions: "{ı六]_}4\"九|/ış; 四ç ,75 9七一+四^八ş ?一\"9]_+  (#)]四5]@^7^ş 9+&}",
  },
  {
    codes: "      七6)八.?*.^}.七七.一?",
    descriptions: "九&八5八/5])5ş 8三\\7@0\\",
  },
  {
    codes: "      \\9_五.五3.&四.九\".四?",
    descriptions: ";$ğ{\\|99<",
  },
  {
    codes: "    }一+二.*+",
    descriptions: "一]}+ ;ı0二ı*ı {.\\;0$9, 3çı8 四ı1^ı6ı ;\"七'&:8, +^ 九^,['8/ 1六}一&",
  },
  {
    codes: "      ).]+.7\".;五.$七.84",
    descriptions: ";ç \\?> |十)5七一\"ş 六>六[|9' (3:](四/4[,&ş 十)?九",
  },
  {
    codes: " ",
    descriptions: "        七ı]]:+=三+]}ış {%_一八 =五二,8ı6)",
  },
  {
    codes: "          ,+,%.>{.<2.>六.二;",
    descriptions: "@öş{_=:\\\" 五五ş?@ 八=@\\73;",
  },
  {
    codes: "          ]三]三.&(.;^.二1.*;",
    descriptions: "*6ğ7[|六>^",
  },
  {
    codes: "        *<6\".=一.七*.{).&1",
    descriptions: "<ı五八({+%二四二ış (<'七3五@",
  },
  {
    codes: "        +一|[.|七.(九.(0.\"<",
    descriptions: "^六ğ+6^)九}",
  },
  {
    codes: "    )3>九.二[",
    descriptions: "六#6%( 四}0^七, [十*5一 ;,十/95@, :/8\"七 :,^五7五, 7}>ı ^9:4_十]",
  },
  {
    codes: "      _}*:.五^.25.4\".十十",
    descriptions: "六ç ::. [@(\\:$九ş ,@一\\'>/ ([>,0%9:\\0八ş 三[四五",
  },
  {
    codes: "          24[_.十|.]:.$,.|3",
    descriptions: "9öş2九=^0< ,$ş0一 二&?55,一",
  },
  {
    codes: "          \\'8八.6=.7七.8/.\"_",
    descriptions: ":3ğ^四##.&",
  },
  {
    codes: "        3九&6.\\{.=四.]六.9八",
    descriptions: "4ı一+.八八_四9%ış ]#十七_+0",
  },
  {
    codes: "        9;二|.8^.'五.3#.+一",
    descriptions: "5#ğ^?\"二}/",
  },
  {
    codes: "    十@二一.二7",
    descriptions: "三\\二'}:>",
  },
  {
    codes: "      ?三八_.八|.'三.\\四.:三",
    descriptions: "*ı0*\"<(七+#.ış ; |ç \"四^ 5十%\\九}}ş ;五.)+?? (六*2*'55?1十ş 九\"九十 一ı69九五@@_7|ış",
  },
  {
    codes: "          &>^'.}\".'[.0七.0,",
    descriptions: "2öş?{>}^; :|ş三; \\^2八':}",
  },
  {
    codes: "          ,.3$.七九.'4.^<.8>",
    descriptions: "'=ğ六}六6一4",
  },
  {
    codes: "          :&#}.$(.#7.#8.=^",
    descriptions: "六ı十9\"七;一',五ış 九三<四7<_",
  },
  {
    codes: "          );十七.}].92.五八.&#",
    descriptions: "|\"ğ/@{_4+",
  },
  {
    codes: "    一_<(.&二",
    descriptions: "İ.*#六",
  },
  {
    codes: "      五#)_.@7.二,.**.#二",
    descriptions: "#ı:'四四八[[九;ış; #ç >(一 +3^,.二<ş :五6||五> (八六2|\\.72五;ş ]+}三 四ı+@一六.\\,$0ış",
  },
  {
    codes: "          '8<_.)2.9,.?三.一3",
    descriptions: "/öş,:1{;一 #$ş0\\ 五五五;<^$",
  },
  {
    codes: "          9.6?.?_.:>.七{..[",
    descriptions: "一{ğ五$_九|@",
  },
  {
    codes: "          |{/?.\"5.*\".(/.{(",
    descriptions: "\"ı\\^$=;二,7一ış ]_=|7|,",
  },
  {
    codes: "          ^0\\#.1).92.,一.四8",
    descriptions: "&八ğ九]{[九7",
  },
  {
    codes: "    [>({.|&",
    descriptions: "五<ğ=五:1九9",
  },
  {
    codes: "          :二|:.$一.[9.71.{七",
    descriptions: "4三1 &=1<<86@0, 六{77 3四一8+='<3 '9 1六59 一六四9[[4(]",
  },
  {
    codes: "          55[).3>.三\".=\".4>",
    descriptions: "十五ğ(^5&>}",
  },
  {
    codes: "          $||\\.1'.(三./6.;}",
    descriptions: "}六4 =>5;1三\\+*, |}八# 06,=394^% 9六 <8,1 .,一;*6{>1, .1}?7&九^&?ş",
  },
  {
    codes: "            三;''.\").%9.\\2.?]",
    descriptions: "三'5十>/6三&3ş :/]:三7&",
  },
  {
    codes: "            /}[:.2八./3.十(.\\2",
    descriptions: "二ı<五?四.;0+;ış 4|8%+\"8",
  },
  {
    codes: "            七;九^.(..&八.8/.,0",
    descriptions: "六]ğ一一六6#'",
  },
  {
    codes: "        九<#@.一'.7七.')..5",
    descriptions: "<#=三:?,7一/ş; 十ç 7&/ _五]//;^ş 八十\\\".[0 (/+六九.;}六_|ş $68#",
  },
  {
    codes: "          [4&<.五5.三十.九六.6{",
    descriptions: "+ı>一七*_;[;,ış ]^二,8((",
  },
  {
    codes: "          ;\\二'.@$.(_.==.56",
    descriptions: "]$ğ^</7.四",
  },
  {
    codes: "    9;2=.3四",
    descriptions: "9一ş\\_七2 (\"八*?#|{ =六?.)",
  },
  {
    codes: "        {九#九.6'.|'.{四.5四",
    descriptions: "/ı2三\"[_六5@<ış &$%\".二]",
  },
  {
    codes: "        _九五].五\\.九+.四;.三四",
    descriptions: "]%ğ)51989",
  },
  {
    codes: " ",
    descriptions: " - - - - - <&%56 .0>^ ?)ş#^ .32八 6öş^6$:31#ğ' 八ç一\" 8:5四}ş,2六:,;2$五ş 7>;8, ş2,九十",
  },
  {
    codes: "            >+/<.1\\.九九.'?./;",
    descriptions: "Ş+.@@ ^_ @ü\\ü7<八#52五|3",
  },
  {
    codes: "            '0*4.^+.2^.:&.==",
    descriptions: "|öş#2_[&. 三\\ş;@ 十:3[$70",
  },
  {
    codes: "          /\"6十.^(.6@.*九.六}",
    descriptions: "_]ğ95)*6十",
  },
  {
    codes: "        +]^].:8.)四.三%.@六",
    descriptions: "13ğ四$8[1[",
  },
  {
    codes: "    %>|%.\\%",
    descriptions: "%)_ı_*>五 (2\\九#五 {(二.)",
  },
  {
    codes: "      <七六)..&.^二.十*.&&",
    descriptions: "$ı三8六<3#89三ış +(>7九.)",
  },
  {
    codes: "        7|?八.;=.5(./#.%,",
    descriptions: "九<三ş十} .[\\83 [八.九^%} :0(];_ 七üçü] =@9七.八*|",
  },
  {
    codes: "        6)9#.[{.四五.}1.$9",
    descriptions: ":?ğ十<一一2/",
  },
  {
    codes: "    _<#三.^_",
    descriptions: "0&ç_,ğ\"ç9一一 (&5八二 |>9.) ^([]{]6",
  },
  {
    codes: "      9$5三.;)._&.+{..^",
    descriptions: "(4八七5九\"=?#ş; [ç 二$一 5#二\"|*\"ş \"八#]九)% (&八三#$十;<|四ş $+;三",
  },
  {
    codes: "        <9\\].%,.|八.],.2]",
    descriptions: "七ı3=二0一09^_ış 二八(四+#>",
  },
  {
    codes: "        92#五.一:.8..7七.%@",
    descriptions: "/{ğ<@\\=^八",
  },
  {
    codes: "    |三十六.九5",
    descriptions: "2$)六@ }ğ五7ı九十.十 ($_28[\" ,\"_.) 五?七一@一(",
  },
  {
    codes: "      三4{十.[|.[,.[0.^八",
    descriptions: "八9{]|$二#41ş;  ,ç 9'十 二^二]:%\"ş 六7*&七,@ (_一%^三(>|/^ş 四600",
  },
  {
    codes: "        ?:]+._[.十八.,;.79",
    descriptions: ",ı,+三6三}]#一ış 85)7,=#",
  },
  {
    codes: "        *=$=.?8.9+.?_.]#",
    descriptions: "1)ğ=二(02'",
  },
  {
    codes: "    >>+,.六'",
    descriptions: "|(ş八一5*# ;ğ%八ı.一,> (<.@五0一九@ ^八:.) 8$+七]<&",
  },
  {
    codes: "      ][)二.#6.1三.一3.7二",
    descriptions: "[(}6<'+<1(ş ; 2ç @:: 一2)+}9二ş 1八+七二2, (^>/?%:{;七?ş 三\"9三",
  },
  {
    codes: "        #三[:.,..7{.七<._8",
    descriptions: "1ı|(451*6:\"ış 649七?<七",
  },
  {
    codes: "        #7'>.6#.1=.<*.\"4",
    descriptions: "[,ğ三,[0:3",
  },
  {
    codes: "    一=>3.'}",
    descriptions: "+%ş #ğ\"四ı ($<六9){ }|5.)",
  },
  {
    codes: "      '=3].一{.^8.').81",
    descriptions: "<0$_十;.{七5ş ; 八ç 3三0 ].['9.&ş 二)5六8#\" (七三十1/+=43>ş 一|=七",
  },
  {
    codes: "        '{[;.<].:九.>..二七",
    descriptions: "+ı[\\\">,@}十四ış %*<)*|{",
  },
  {
    codes: "        (17>.五^./'.25.(|",
    descriptions: "一一ğ_'三8八五",
  },
  {
    codes: "    &_9?..9",
    descriptions: ";'|八$ /六 <9八'八? ?2?:) (?一四/*二二 (三九.)",
  },
  {
    codes: "      354>.;六.}%.{七.2$",
    descriptions: "十3&9四2三8]?ş ; =ç 八七七 %8678:3ş \\七>350{ (<七^3>\\=:%一ş 0+6一",
  },
  {
    codes: "        五<3三.4<.$^.+$.八六",
    descriptions: "}ı]>:'$三429ış 7_7]四五<",
  },
  {
    codes: "        0八)七.]八.八:.}\"._[",
    descriptions: "八十ğ(=?九六;",
  },
  {
    codes: "    六#8+.0&",
    descriptions: "7五ğ'十}0|:",
  },
  {
    codes: "      三一69.>(.?'.?/.一|",
    descriptions: "8>[+#:3($六ş; &ç _八\\ 8<94+四7ş %.一@[\"一 (+8@043三5六>ş <)5|",
  },
  {
    codes: "        03*<.9?.七5.6?.6?",
    descriptions: "7ı1:8,2'^)%ış =六>9九三]",
  },
  {
    codes: "            七^六5.%@.28.%[.六.",
    descriptions: "十4二ş]6 ]|+9二 <2\\9_六* <%64]_ 四üçü9 一2|8(\"@=",
  },
  {
    codes: "            :六3九.,5.@一.十_.[三",
    descriptions: "=九ğ(<,#一#",
  },
  {
    codes: "            {二^\\.\\#.7<.[七.#=",
    descriptions: "Ş&?ş六二",
  },
  {
    codes: "            <?%$.5;.0).',.^?",
    descriptions: "{/_0^;}",
  },
  {
    codes: "            一*?7.+十._..;1.<?",
    descriptions: "]5八'\\)",
  },
  {
    codes: "            26]三.三4.</.=五.八九",
    descriptions: "/#{一'",
  },
  {
    codes: "            +3八3.&^.^\".七6.{;",
    descriptions: "+ü055二",
  },
  {
    codes: "            三<,6.五0.(6.二^.5)",
    descriptions: "5^ğ七;&'\\四",
  },
  {
    codes: "九八.3;",
    descriptions: "/八;]5/(++ı/( (_#,(十八 4四7 \"|ç/}^四ş, =_十#十9@?_*ş =;一1 八七+{<三",
  },
  {
    codes: "  $一9$.>六",
    descriptions: "İğ64 .{)}一五<ı 1ğ一ç;%\"",
  },
  {
    codes: "      7八]=.5(._'.6..十2",
    descriptions: "三ı_[.?七四4'1ış /,+1.0一",
  },
  {
    codes: "      八\"\\7.'{.0=.4(.#$",
    descriptions: "{022&'一5,%ş *519_>2",
  },
  {
    codes: "      0;十].84.@..>七.|@",
    descriptions: ">一ğ),%&8/",
  },
  {
    codes: "      九四0{.8+.一[..:.=}",
    descriptions: "/.3ş1四 11六\"5 二^五四75六 3二}3二] 5üçü2 2二$9;7<)",
  },
  {
    codes: "        />/8.<@.二}.+9.[\\",
    descriptions: "5四}ı#?ığı . 三[. 9^'三:[/",
  },
  {
    codes: "        七五^_.二'.\"#.'(.6=",
    descriptions: "#'{ı5+ığı ; 十?.<七: ]八 #@5四一4六",
  },
  {
    codes: "        {五:八.>7.@8.?+.;\\",
    descriptions: "9二,ı0/ığı : <七. (@.\\二ç) 三54 七 7<. ((}\\二[) 1054ı六&七 *9??<>'",
  },
  {
    codes: "        6五'#.\\2.四[.0七.&4",
    descriptions: "一一?ı八<ığı 六 9四. 6># &)?*@ 四三>八五7八",
  },
  {
    codes: "    [+/八.5六",
    descriptions: "八?三6 <ı,一ı5ı 4/$,_七;, _çı一 'ı']ı五ı 0.八+9\\& 0三 十7(二<<^ 八4@6/ ",
  },
  {
    codes: "      :|48.;../>.](.8}",
    descriptions: "七ç $:2 &\"1四8++ş =\";+$.\" (6@:九\\{}?30ş }>,> ;ı一?(^3%=%6ış",
  },
  {
    codes: "        7([6.6+.{/./五.\\六",
    descriptions: "八/八95十十%十十ş 九_(_{\".",
  },
  {
    codes: "        一六^*.四九.五二.1,.0,",
    descriptions: "五ı三)?45*七$>ış *?.六\"#,",
  },
  {
    codes: "        六^八<.:7.)四.\\一.&*",
    descriptions: "$*ğ三%七#八\\",
  },
  {
    codes: "    55,\".#.",
    descriptions: "四,ğ^;9^%,",
  },
  {
    codes: "          8{;6.3六.,;.7..21",
    descriptions: "9ı五四6[?+.三$ış 二{;$(5^",
  },
  {
    codes: "          =十二#.三..]/.4#.3#",
    descriptions: "十0ğ^8&二67",
  },
  {
    codes: "          五\"81.六=.8..|十.94",
    descriptions: "三;[五[9八.)一ş 九\\,300]",
  },
  {
    codes: "          二\\4\".';.十四.十$.:6",
    descriptions: "7#ğ{^|九$&",
  },
  {
    codes: "          $六九&.3\\._三.\"@.?1",
    descriptions: ",ı,4^1'>七5:ış =@七,五81",
  },
  {
    codes: "          /\"'五.一{.|\\.56.四八",
    descriptions: "7;[一+()1六一ş ,四=5<,9",
  },
  {
    codes: "          七&06.|%.九:.59.1*",
    descriptions: "%;ğ+.六47|",
  },
  {
    codes: "          4'=).6@.39.)*.三二",
    descriptions: "\")^ş]} 三0*八六 一九5<}3@ 7>三;3_ \"üçü( <*五,五[[$",
  },
  {
    codes: "            8<{0.+七.六6.七).8_",
    descriptions: "7#十ı}一ığı { @$.## )6ç@九4六四%+}",
  },
  {
    codes: "            (17}.?四.六\\.9*.3十",
    descriptions: ")六&ı{?ığı , }>.七+ ]4ç6二3$[",
  },
  {
    codes: "  >\"\"^.{|",
    descriptions: ",七ğ三三6|+5",
  },
  {
    codes: "      76+?.=+.'3.\\三.|}",
    descriptions: "[<44四\\#<&一ş +*%8@(@",
  },
  {
    codes: "      二\\]^.+?.@$.四2.|$",
    descriptions: ":ı,/九{%_九.}ış 66一\"=5{",
  },
  {
    codes: "      $<六:.2[.^(..:.)3",
    descriptions: "05ğ\\8*3.9",
  },
  {
    codes: "      ]六\\>.:3.^=.+六.1)",
    descriptions: ",=/ş[2 :/&<> ]七'一%0/ }四4(五0 }üçü二 _三@\\五#\\+",
  },
  {
    codes: "        $<六].>6.\\八..8.(3",
    descriptions: "8)1ı5@ığı 七 @五' _& 四9ç七六|(?}_五",
  },
  {
    codes: "        .?二).)}.|\\.$2.55",
    descriptions: "六9.ı}八ığı 七 @\"' %} 9^ç6#.九=",
  },
  {
    codes: "&5.{|",
    descriptions: "8(六一$))三 14, $七{%2ı\\2\\, 25|1#% 0:\"* /ü6ü15? 五ü%%五=# :}五<二: ş];;/",
  },
  {
    codes: "  {七5*.八*",
    descriptions: "İğ七三 :=:|,5.ı *ğ九ç三;<<四二",
  },
  {
    codes: "    )9|六.{4.一三.>>.0一",
    descriptions: ">八五,*, 8三_,ğ2\"七, 6_'八 三: 6四'+)<6 .ş5五 ç,(ç9*六+' 七ç@: =九ş\\[ ;八_;3409(",
  },
  {
    codes: " ",
    descriptions: " - - 二&ğ+%0^*一",
  },
  {
    codes: "      4&#$.5七.二#.|*.3}",
    descriptions: "^@一%四六}=\"=ş %$|八>${",
  },
  {
    codes: "      ^_11.$^.]三.九%.2七",
    descriptions: ")ı#>+$9'5二三ış 七}]六0+&",
  },
  {
    codes: "      七^六'.@十.=7.3,.$$",
    descriptions: "'%ç]*$#ş 十>50 çö>九}7 }$&_三| <#<\"[00]&ş )ğ;ç,83",
  },
  {
    codes: "      7[^9.六8.@#.$1.八十",
    descriptions: "一2ş+7 ç三/三$303 6< 一八=九五=#九_ (4|4八|?0, ç\"\\ç四\"?, 'ç $_\"\\5, 7六>|三2三5",
  },
  {
    codes: "      03_7.[).>7..9.#;",
    descriptions: "^(ğ:;九六二二",
  },
  {
    codes: "    (}&8.\"\\",
    descriptions: "=$9:)+9? 三=三;)四六",
  },
  {
    codes: "      八4'六./八.八六.{六.}=",
    descriptions: "十5\"&]五三@5八ş 五}9八52四",
  },
  {
    codes: "      四三1八.3$.|(.]二.三@",
    descriptions: "3ı5(%}[&五=|ış 九)>^[|5",
  },
  {
    codes: "      4一[,.八1.\"十.八_.|>",
    descriptions: "'0ğ)@\\_)五",
  },
  {
    codes: "    八9<}.$<.;八.三%.3{",
    descriptions: "8一1025九五 十ğ]ç|.5[=$ 78三五(97",
  },
  {
    codes: "    2';}.*七",
    descriptions: "6八ğ^八:8_八",
  },
  {
    codes: "      六'}_./九.#8.三2.十$",
    descriptions: "*@3#9, 六:3>ğ2)十, |0\"^ 8七 /8;{)]# $ş'8 ç四{ç@147四 [ç44 /@ş十4 @\"八9{一十+4",
  },
  {
    codes: "          [=+(.七2./^.五\\.$_",
    descriptions: "Ş5$9( (* <ü\\ü_五1+19.|七",
  },
  {
    codes: "          28(>.=%.十'.<\".八8",
    descriptions: "@öş:七七五八四 ('ş\\; ])>@3<七",
  },
  {
    codes: "          _十7$.0四.一_.\\9.0七",
    descriptions: "(&|%#7/[:8ş 3|[%%\"?",
  },
  {
    codes: "          %#1{.}(.%二.五\".<5",
    descriptions: "\\ı2二<__#6'.ış 6,.5<&5",
  },
  {
    codes: "          (_^&.*).=九.]^.@$",
    descriptions: "九#ç&;<2ş <?4* çö五.九: 9二十;{六 @13\"^:九^^ş %ğ>ç%9/",
  },
  {
    codes: "          %3&2.2$./^.+3.五$",
    descriptions: "(<ş?> ç3三九31*三 [: =1.}9'>$七 (%,\"?9{%, ç}九ç+4七, \"ç 三五\\79, /]{六9_;2",
  },
  {
    codes: "          '三七+.四:.三\".}[.&(",
    descriptions: "{@ğ1四@%=>",
  },
  {
    codes: "二&._\"",
    descriptions: "_ğ\"ç'八8 ?=75 &]ğ0@ 十%;?/十 十七2833^九九|( ^+>九{ +%.,1,",
  },
  {
    codes: "    @/',.九五",
    descriptions: ";九3(七 7\\四$.",
  },
  {
    codes: "      8,五:.3>.(|.3].十?",
    descriptions: "İş十49;:}+ş {;}6 635一七$ 8ı#\\9五?三{{_ış 6:1七= .1九; 5;6六( ),二 \\ş'|+ 一ö\\八?,#ş",
  },
  {
    codes: "      9^63.:八.三_.21.九十",
    descriptions: "|ü二<5@ #>2+(8[ 五;1\\一139四ş ,{ğı2[{ {+四^\"71ış 六$二\"5/%",
  },
  {
    codes: "      _6_/.9:.八4.{%.八2",
    descriptions: "三ü)三@;  四;?9=/\"二]3 <4<4'4二@# \"五[*& ?}四[ @*二六[<;六 >7\"6/44ış |62\\002",
  },
  {
    codes: "      :2三八.=\".'十.三<.:@",
    descriptions: "七9ğ8三03=<",
  },
  {
    codes: "    八\\4*.=4",
    descriptions: "二ö[+{>7<\"7二(+ş ;7\\\"= 十{}2/ ({\\_)",
  },
  {
    codes: "      |/5^.2_.)'.,).7/",
    descriptions: "İş|九#[>5>ş 八^六0 ;六+@九三 )ı九.+6&^;三.ış 九十一二& \"|)< 三[[+0 5+* /ş0/一 _ö^六223ş",
  },
  {
    codes: "        \\|2,.七<.7(.9$.九)",
    descriptions: "九ü[@一8  }七(35三63七8 .^>0,八}04 ]})8十 二?,? )/_0.二=( [[ %4 4八^7二{.",
  },
  {
    codes: "        {|,'.*7.十3.2?.七=",
    descriptions: "十^ğ6七#][0",
  },
  {
    codes: "    3')8.十五",
    descriptions: "@一ğ_)|0{|",
  },
  {
    codes: "      /<+&.$>.79.[九.,%",
    descriptions: "%ü%\\<二  一:六六一/八84三 ?/\"三33$六; 十5一\"5 2942 {[;4\"=/: {6 *0 )二五9.@]",
  },
  {
    codes: "      }92@.\"&.\"九.4].十四",
    descriptions: "1&ğ)9.九七*",
  },
  {
    codes: "  }]*..#\"",
    descriptions: "9三ğ{|9>1?",
  },
  {
    codes: "    =4\"..]7._^.1三.+;",
    descriptions: ":三十4# 2ö5ü4/ü#.,7五一@+ #7)7 7)/{/=__2",
  },
  {
    codes: "    六@2].3].三8.:=.?{",
    descriptions: "三9ğ十_^51|",
  },
  {
    codes: "4{.63",
    descriptions: "|ğ:ç四;: 39]} #)ğ.] <三六)$# :7?3^,3:$?2 二3: 五]&六八.|九 ($+ç;[;四&9",
  },
  {
    codes: "     6]5一.五'",
    descriptions: "[四#ı|\"ı.1>;ı 8 >9.七$ 13ç%2?'5*#8",
  },
  {
    codes: "       \"8_+.%).八:.,6.}.",
    descriptions: "|]&1五七9 七*?|四] 1ş五1十}\"<#ş ,(三9 1ü七ü [七,#|^十九5ış >1,]^?6 (.一五 ;八.2六)",
  },
  {
    codes: "        @]十十.='.}}.(二.@0",
    descriptions: ".6ğ\"+四7ğ3 3,) \\_/1[³'ü (&.4) <2ç]八,_>",
  },
  {
    codes: "        >#+$.:?.0'.1+.0<",
    descriptions: "//ğ467*ğ4 9,6 十\"/9[³'ü {@ç$8六4九;:八",
  },
  {
    codes: "     [3@=.;<",
    descriptions: "@:八ı]|ı4&41ı \" 1].>^ ;:ç2七 , \\\\6>{ 一 .5.($ \\)ç9)十){十一)",
  },
  {
    codes: "       \\5一五.五2.五十.)6.一0",
    descriptions: "七]二{?6< 28582四 5ş:>/\\;{[ş \"::8 {ü<ü 7\"2}6>(|/ış 六;#=9[} (;9二 $十.七七)",
  },
  {
    codes: "          \"_}>.(,.$\".0$.|2",
    descriptions: ">四1]九九0 86\"1:",
  },
  {
    codes: "          +/^^.>七.>八.#&.3&",
    descriptions: "3>ğ[([)<}",
  },
  {
    codes: "           四>一#.1(.七<.),.二)",
    descriptions: "9九+|7五/ :三|[|",
  },
  {
    codes: "           九20\\.42._..一(.}四",
    descriptions: "('ğ4&\",(3",
  },
  {
    codes: "     4六六..六四",
    descriptions: "&三九ı四[ı>{*{ı > 三5.,0 ,(ç:9504",
  },
  {
    codes: "       6/*;.#>.'[.,]._>",
    descriptions: "1+*(9*6 ^?三八?% #ş;4{五9#&ş 一%一六 五ü.ü ]十,_\\0$<六ış 三2$;>15 (九{9 ^七<%<)",
  },
  {
    codes: "          五'%二.:%.['.4_.{十",
    descriptions: ">}#.三2> ].8,(",
  },
  {
    codes: "          十%_&.25.*0.{=.]7",
    descriptions: "一八ğ九[七:]六",
  },
  {
    codes: "          0'>九.%七.%(.=%.二)",
    descriptions: "53<@%;# {六二>7",
  },
  {
    codes: "          |$$3.\\?.&+..2.一|",
    descriptions: ":8ğ.'/';*",
  },
  {
    codes: "           @#*<.(+.4$.&..3{",
    descriptions: "三#十8_>2 |四^^#",
  },
  {
    codes: "           九%%(.66.+?.]\".4,",
    descriptions: "*]ğ二6.7$$",
  },
  {
    codes: "     00三3.1|",
    descriptions: "十]ğ#十八<ğ{ <,) 2@/@[九.ü 九(ç二+8+?",
  },
  {
    codes: "       {四}2.\":.一五.;三.%二",
    descriptions: "十[二''={ 八\"=+*八 七ş+{,=}9+ş [5|5 _ü$ü 四=^五?三>(九ış ^{(/'46 (5|) 七^)9{)",
  },
  {
    codes: "         二二1?.,2.三\\._@.\\四",
    descriptions: "\"'\\]@<四 |]1}\"",
  },
  {
    codes: "         七5?(.九4.%..43.二九",
    descriptions: "十6ğ二+'<一@",
  },
  {
    codes: "     五*,\".九]",
    descriptions: "五<ğ四$八2ğ7 三,% $:/8|:.ü 3.ç*>, 三@\"七? 7,八 >_/ 七=].ü 4九ç{三$}六_%0",
  },
  {
    codes: "       @^1@.2十.5十.8[.|0",
    descriptions: "9六2=二1@ .二61:",
  },
  {
    codes: "       十一}7.@八.,+.#@.,:",
    descriptions: "<*ğ(8&三八四",
  },
  {
    codes: "     ('<七.[5",
    descriptions: "十*ğ|]0]ğ1 1,( 九7/$&5.ü *5ç,七九_十4]8",
  },
  {
    codes: "       />'九.7十.九?.^>.>+",
    descriptions: "]{{^>@7 /}0?{五 3ş7>>0:一1ş |,0九 十ü.ü 0[/<六:八2九ış 十3'=?{六 (二9? ['七0九)",
  },
  {
    codes: "       六:+*.'&.十3.三七.++",
    descriptions: "{6ğ&0);^%",
  },
  {
    codes: "(|.0<",
    descriptions: "$二三$七@]4%2)六, 一;ş四/ &$二?]@一8ı *\\0>)60| \"7 {9/}*?> /^2?二^ 五/七@]二ş",
  },
  {
    codes: "  '+0三.)五.&@.93.:#",
    descriptions: "\\1}[1=二十 九46九九%$",
  },
  {
    codes: "    70九).5}",
    descriptions: "]+ 5六 96一 3ış 2ü,ü ,6#:;二]6 7ğ[ç九>七/:} &\"|>&六@",
  },
  {
    codes: "      5<五五.一:.一<.1:.:\\",
    descriptions: "_7五< _ı:一ı$ı ]|{;&{4, \\çı{ 二ı]五ı一ı >^[.?四_, [._7> 0七}\"+, >/十#, /=[*&,",
  },
  {
    codes: "      8)&,.13.十\\.9+..五",
    descriptions: "6+ğ)7六十>(",
  },
  {
    codes: "    三22]._六",
    descriptions: "<|ğ|6$={\", 二@ .一 4八5 7ış *ü九ü \\ı五ı:.ğ*ç (九.3^' *4\".), ,@ş}%九$5",
  },
  {
    codes: "      六04<.(@.&8.:{.五九",
    descriptions: "+4 六| +3\\ 'ış ?ü^ü +>ş $ğ二'ı@,@0 (=8<0@6 1\\{.) '87八)$.",
  },
  {
    codes: "      二&4五.十,.三4.#;.,4",
    descriptions: "_ış <ü}ü 6&ş ]ğ8八ı 0%2}:>\\, 5+三8) +^ 九& 九1六 5ış 五ü|ü ?)五96 ]9二一",
  },
  {
    codes: "      四<一,.2?..0.>].4\"",
    descriptions: ":ış 3ü'ü (>ş %ğ3九ı, ?4{8; 2_:: )'7$'二 ):%一^ (4'一|?@+ 五\\7.) ^二$#%\"六,",
  },
  {
    codes: "      *\\八<.八1.\"2.;二.5*",
    descriptions: "/3ğ十五=+5+",
  },
  {
    codes: "    九一[4.8>.=>.|6.5=",
    descriptions: "00ğ3@_五=;, 六3 +^ 87, ;ış 'ü^ü =}[十./三 *[: ?%({36五九&十二\\",
  },
  {
    codes: "    .八九七.二/._<.5?.&[",
    descriptions: "?=ğ({(*@;, 8@/ &(_ \"ış =ü$ü 1{ 'ğ五[ %三#4五42ı>8$,@+ $(六==|六",
  },
  {
    codes: "    一&九#.?#",
    descriptions: "]> )九 *,: 6ış 1ü(ü =_[%]\"十. @ğ九ç&2._'八 ?{十1311",
  },
  {
    codes: "      9;]七.\\:.^\\.6?.2/",
    descriptions: "|八 )% 三#5 /ış #ü4ü <ğ&[ ]'?7八#四ı #九2\\>3九 <ğ&ç;1'_#0 6&=}:7*",
  },
  {
    codes: "      {42+.}|.=\\.];.三九",
    descriptions: "}%ğ_三<六8.",
  },
  {
    codes: "    ;.]'.六=.]/.1=.|'",
    descriptions: "]|ğ93?<|九, 一( (} 十*一 5ış 2ü&ü (ğ7( 十;('$9)ı 013五#53 4ğ#ç##\">二七 _*八,8_;",
  },
  {
    codes: "    }'?五.59.'9..{.%=",
    descriptions: "}4ğ>七]3[(, #+, {八三 六ış 4ü_ü :& )ğ\"? |1],|5&ı 4ğ八ç七九四91% 一@3\"七%.",
  },
  {
    codes: "    2;3(.\\五",
    descriptions: ".; ^; 8#, \"ış {ü&ü [=5[五};十 :ğ九ç6十九{二$ ]二<\"0}*",
  },
  {
    codes: "      六二八|.=).四八.33.3?",
    descriptions: "#* )八 九8) #ış /ü'ü 7ğ.^ ,;,6六{%ı $*(1五\\8 八ğ|ç;十]06} :.,8_5六",
  },
  {
    codes: "      =8@;.0九.十<.^十.&%",
    descriptions: "5%ğ^._:+2",
  },
  {
    codes: "    :$0^.__.七+._五.<{",
    descriptions: "}九ğ=9/2七4, /\" 26 *52 四ış 6ü二ü ;ğ?四 一三[<(*三ı (1@7@|= 4ğ>ç.7|067 05二(\"$&",
  },
  {
    codes: "    [四\\二.{十.&}.{#.九|",
    descriptions: "3八ğ.5&1)9, ^#} *一6 )ış {ü6ü [3 三ğ3_ 一@1%[81ı >ğ@ç?=六七;} ]2&}|十5",
  },
  {
    codes: "    十$6:.3八",
    descriptions: "1. 3, ?>$ .ış 八ü\"ü [十一=$六)5 ?ğ1ç%,3&}8 八}87#0一",
  },
  {
    codes: "      {78八.,1.\\:.=).%2",
    descriptions: "<. |3 <一{ 6\"0八3&八ı ?72三二 }四}8} 0ç&六九十[&1",
  },
  {
    codes: "        94_|.1'.十一.&<.+]",
    descriptions: "(( 十) 七9? %ış 五ü[ü *ğ|二 ?+0)4=|ı 6六\"六&$2 ]ğ/ç八+.=九9 /,(\\二)六",
  },
  {
    codes: "        |2<(.?八.1八.7:.六九",
    descriptions: "四九ğ>^>6:七",
  },
  {
    codes: "    ]34&.四:",
    descriptions: "二/ğ9;四||$, 5= 三\\ _九8 一ış )ü>ü 十ğ.\" 5;?):\\@ı {:9<九;} :ğ1ç&五<\"一2 |'=:)/$",
  },
  {
    codes: "      =$@八.\\'.^&.]'.>7",
    descriptions: ">六 2, ^4\" />'九八20ı ;{@七八 \"1}(# <ç<<93+#一",
  },
  {
    codes: "      %}52.0%.\\0.?=.?^",
    descriptions: "<[ğ五;_8&<",
  },
  {
    codes: "    @四一5.9@",
    descriptions: "#|ğ|五47#2, #一% 8.\" #ış |ü五ü 十九 ^ğ+. 1<2;{+:ı @ğ九ç'#]._$ \\八四七|;$",
  },
  {
    codes: "      <&二4.>'.@$.十一.五七",
    descriptions: "?% +' 二\\二 ]八四>一$#ı 六五\"|二 |9%8$ 四ç四%}6/6;",
  },
  {
    codes: "      二%>(.九|.3四.,3.八*",
    descriptions: "0*ğ^十四'3;",
  },
  {
    codes: "5一二../*.(八.53.@6",
    descriptions: "8.ğ/一,%ğ五 七%>ı$ı&9ış [ğ2ç (3/(81^%, ]612+,\\^, ş九#.)90/ 04}+ 6+七;$[",
  },
  {
    codes: "\\[.#'",
    descriptions: "六|ş<二 ç八2ç\"60)]$ (__八#., @95&ğ]+|, +/41 ;% ]6]_*|十\\七) %ç一8)",
  },
  {
    codes: "  93^>.\"&",
    descriptions: "7]三//?七四 三ğ0ç{\"&.?; [六八*=8/",
  },
  {
    codes: "    十?4\".|9.\\九._?.#一",
    descriptions: "四/ ?\":ı五ı^ ) +;.9/ 0, &%2五\"^( =22一:/|/七9 }^\"&;.%0 十ğ五ç3$十三五^ ',七04;&",
  },
  {
    codes: "    %?_{.)一.:八.九6.07",
    descriptions: "^?ğ6, 九ğ6ç&_六>五& ]$=#*/二",
  },
  {
    codes: "  14?@.%五.二|.?_.4十",
    descriptions: "}十ğ|6|((2",
  },
  {
    codes: "  [;.=5",
    descriptions: "{2ş1. 九ü?ü' [= {üçü} %=[?ı二/$一, 五三1六/ 二^13ı|9+:, 7^,>二七]七 3.#=ı2",
  },
  {
    codes: "    ,>]二.十十",
    descriptions: "十,ş09 /ü;ü五 七| (üçü\" [九六4ı九_:), 0{十=? 3六*=ı五'<5, 三{一8.5\"1 <六九",
  },
  {
    codes: "      )九$8.<7.)|.二+.一一",
    descriptions: "0七<|?8&>{ ]1:4 十'8四98= %_/八1/ı六{8七 '%五ı<[^2{_",
  },
  {
    codes: "      四'5<.3二.4>.,八.五&",
    descriptions: "\\2ğ\"$4;一五",
  },
  {
    codes: "    =七\\9.5[.二五.>].'4",
    descriptions: "九/一>5 十一|\"八'0#]ı",
  },
  {
    codes: "    _>'7.?一",
    descriptions: "1'3'5}>%, 2[七+8 五一%@ı4=;一 _< 二4ğ五+ 八ü| ']9\\九(_?ı; $/ş十一 >[7四二 .三9",
  },
  {
    codes: "    $九{%.\\<.8^.五四.}(",
    descriptions: "(ü: 三七^82&&0; 0|,8; \\一\"+ı1ı四ı1四8",
  },
  {
    codes: "    (/九\".10.十三.5,.二{",
    descriptions: "]1ğ0]47??",
  },
  {
    codes: "%$}5.+八",
    descriptions: "5四ş/四 四{^^\"^;^, >ıçı四一0, ?#@*>8三, 2++*,$}一' 56 /9ğ,' %ıçı\\ı @ş(7_ı 六}",
  },
  {
    codes: "  .(+二.<%.7:.(5.$六",
    descriptions: ".&7ı1=ış |ıçı .五1]#73*ı (^@二 (.'| 3ü五ü 三\\\\;36_ 一^/ 九=ç七6$+ş 6.二^+ \"/.4九ı<,",
  },
  {
    codes: "  :十9*.八5.\\;.53.&1",
    descriptions: ".五ğ;十<;七^",
  },
  {
    codes: "九_)七.&#.#$.二{.?二",
    descriptions: "6_ş&/ <^五;.1一, '二+; 三ö]九61十9九, /二八二 32*9四4ı, ?ü5ü.@% 2@ _ı六ç一 四ö?5[",
  },
  {
    codes: "七=.1}",
    descriptions: "}四ş/$九五) $十$% 5/ /[ş九,, 五ç#' ?&(@三5>811六 五\\@三?3六95 ]1 7一ğ;{2七 97二ç,'*|ı",
  },
  {
    codes: "    2#5$.&=.}|..^./@",
    descriptions: "六6#*\"7;3 一ğ2ç}|八/7} _八43&九@",
  },
  {
    codes: "    '十二八.六.",
    descriptions: "%|ğ855+,|",
  },
  {
    codes: "      8:*^.8..^\"..:.2)",
    descriptions: "İğ\"二 五*?6'@)ı 6ğ{ç;三一十&. 一_}/\\9=",
  },
  {
    codes: "      /|八7.十九.八\\.+%.%#",
    descriptions: "7|ğ+7*6{0",
  },
  {
    codes: "    六:]八.+:",
    descriptions: "\")_七9\\%] 六ğ}ç764^({ '.77四(7",
  },
  {
    codes: "        \"1\"五.九..;0.69.9八",
    descriptions: "#4&ı四六\"",
  },
  {
    codes: "        6六'@.:九.一9.6*.1*",
    descriptions: "一八ğ_*>一)$",
  },
  {
    codes: "        一@{<.\\四._三.?>..{",
    descriptions: ")6]ı'\\;",
  },
  {
    codes: "        ^;1+.0.._3..*.#七",
    descriptions: ",,ğ@九0,四0",
  },
  {
    codes: "    四3@].一$",
    descriptions: ";六ğ.+#五_/",
  },
  {
    codes: "        890|.%$.+}.<十.七7",
    descriptions: "6三九ı0;六",
  },
  {
    codes: "        #*7].<?.|$.^].六十",
    descriptions: "一1ğ=@8<一一",
  },
  {
    codes: "        7=_六.>3.%7.(*.八;",
    descriptions: "4$四ı三十7",
  },
  {
    codes: "        六0{(.:九.2|.4四.@,",
    descriptions: "9+ğ?2三四三五",
  },
  {
    codes: "  %$三}.\\1.1<.一..四.",
    descriptions: "8<4八.0& +23 =2@8.(: (=% +{%@4=(七$|#ı =ışı]#一 /3<?+ 八}..'*二: +^ #八[$ş五&'",
  },
  {
    codes: "  36一九..8.(:.九8.|.",
    descriptions: "二])=> 一五ş;#@ [ç{[ 8@+ı2四%;",
  },
  {
    codes: "  \\[7@.2[.;5.}=.,0",
    descriptions: ">,:5{四)]=_ _< ç,七ı ö/]ü%一一4 ()%八.八[)",
  },
  {
    codes: "    1'1?.#0",
    descriptions: "八@(\"{四六] 一九'四六5> 0十2> 3# 四十 ü[十 5<@\\5\"一ı (四şı<>( 九>4@$二) 四?+8\"_)\" ?\\41__|",
  },
  {
    codes: "      9^{?.六9.#六.4).七]",
    descriptions: "八四@@三{ _öş+?<一72 .ç+一 62十7二6六",
  },
  {
    codes: "      =8?2.)八.@四.4#.五4",
    descriptions: "|)ğ:*}74%",
  },
  {
    codes: "    ?8#^.六8.{七.九?.3%",
    descriptions: "|>ğ:}九*/@, 四#7:83 六öş九|;6(< 6ç&/ $4/,'二(",
  },
  {
    codes: "    218,.[七.6*./\\.\"9",
    descriptions: "一\\ğ二{*九8一, ç7[ ??一$ı ;_#+三39",
  },
  {
    codes: "    7\"\"十.=/.十5.'>.|四",
    descriptions: "{八ğ<0#<*{",
  },
  {
    codes: "      ?&二十.\"'.八八.:|.4&",
    descriptions: "2:9$*<七^十#三6}^\"@|}7 3'六二3@ş 29(,'7+ (1<1*.>)",
  },
  {
    codes: "      0+@三.=*.&五.:}.三+",
    descriptions: "Ç>,[&] )^:*+0 /三八,+七ş 3{<_<|= (@十八 '>.九 3|4.)",
  },
  {
    codes: "    ]?=&.;9.@}.*四./,",
    descriptions: "$ ;7六#ş3四?",
  },
  {
    codes: "    &+六;.>:.}3.{5.七.",
    descriptions: "八三ğ二7;&六'",
  },
  {
    codes: "    0)八5.@*./\\.^十.7@",
    descriptions: "十>\")1[十1 四$\".7三<",
  },
  {
    codes: "    0.@>.4@.''.&二.35",
    descriptions: "]ü=+三四0 [0ş&/ {=十#'9五8",
  },
  {
    codes: "    .|6[.]3",
    descriptions: ".$ğ*四\\十8六",
  },
  {
    codes: "      11\\\".二1.十\\.3<.5二",
    descriptions: "九\"]六$?\"(",
  },
  {
    codes: "      0=四五.92./八.0七.#六",
    descriptions: ".ö%]( %|%&&|九0",
  },
  {
    codes: "      2四&].2[.<十.二五.\"[",
    descriptions: "İ/ş三7, 8):ı:/4:ı",
  },
  {
    codes: "      九;=五.+5.=#.\"七./|",
    descriptions: "四\\(})\" >} 三>三1:6",
  },
  {
    codes: "      69?二.6%.,{.\"..(六",
    descriptions: "七十\"八0十ö>一七4 ;8 +3@三五@':&:",
  },
  {
    codes: "      4<四].+十.?一.=九.}三",
    descriptions: "#一ğ八>/,&{",
  },
  {
    codes: "七十.十8",
    descriptions: "四[ş5六 二$:三\"九 [& ':]%9 +ş七,(ı",
  },
  {
    codes: "    8四三=.@>.:\\.#$.二%",
    descriptions: "{6](7 $五39六@)9ı, >8>=一 (*5ğ{5;^) 十' 6,}一一^= 六ç5五 #一4七/三十#",
  },
  {
    codes: "    2_0$.'1.,,.五\"./\\",
    descriptions: "Ç七二 2四\"@ü \\2/.< ç?7)}*]6ı",
  },
  {
    codes: "    <.#'.%$",
    descriptions: "+,ğ5;八(六二",
  },
  {
    codes: "        二'二,.,十.十$.5).{/",
    descriptions: "Ç&4\\5)8四 06(ış四ı+ı3ı",
  },
  {
    codes: "      8?0六.%9.?九.,}./<",
    descriptions: "五}ğ一,八,$2",
  },
  {
    codes: "  二0>:.;2",
    descriptions: "\\{,}6一九^ 5ğ$ç1/51|= :8八一'三(",
  },
  {
    codes: " ",
    descriptions: " - - 9十 20+ı}ı5 十 四\\.二十 八3 /六;二##* +5六]7_52:# 3=三十,3<九 七ğ\"ç六[*=八| )?十..+,",
  },
  {
    codes: "      <^八九.3'.九<.{\\.五#",
    descriptions: "九_;(十 ö&çü _三9:*三ı",
  },
  {
    codes: "      ,&>二.]五.>).七\\.;八",
    descriptions: "3;==1)四*",
  },
  {
    codes: "          %%八^.[#./[.]1..%",
    descriptions: "Ç='@:%(: 六^一ış{ı四ı+ı",
  },
  {
    codes: "        九0%三.一].:4.9|.2/",
    descriptions: "{;ğ7_^十,=",
  },
  {
    codes: " ",
    descriptions: " - - ;0ğ\\{$@&>",
  },
  {
    codes: "      794].?四.二3.:|.+;",
    descriptions: "49*1, ö四çü 3五九?&十ı",
  },
  {
    codes: "      $+|].\"/.[五.28.?十",
    descriptions: "3四5%\\??^",
  },
  {
    codes: "          四.3/.$1.三;.八九.7\\",
    descriptions: "Ç五<九./(/ 八5=ış8ı七ı十ı",
  },
  {
    codes: "        三#((.&二.,1.85.\"@",
    descriptions: "\"?ğ?1%.[四",
  },
  {
    codes: "  四4]?.二八",
    descriptions: "*&ğ五#8,]7",
  },
  {
    codes: "    二0\\9.四0.6<.4%.\\^",
    descriptions: "四9^\\四 ö.çü 1十八5四@ı",
  },
  {
    codes: "    |3${.*/.1$.(:.+[",
    descriptions: "|@三7\"一9五",
  },
  {
    codes: "        &#)<.二9.{2.十五.|}",
    descriptions: "Ç/五8}/5三 %{二ış五ı%ı{ı",
  },
  {
    codes: "      86{9.三=.\\@.1=.8$",
    descriptions: "七%ğ五',;)8",
  },
  {
    codes: "八..%8",
    descriptions: "Ü二五七十|9 十+三四五 <四7ı;%ış 一ğ3ç; }ü.&<@%:*[ 六, ç\\@@十, 九ıç五6 0>(ı1ı",
  },
  {
    codes: "    十^45.八八",
    descriptions: "&九8'三?90 四ğ二ç;8三8六) 四0+六%<5",
  },
  {
    codes: "      .|[%.6%._二./1.,六",
    descriptions: "?& /1'ı{ı2 & 6<.;2 ]= 十三=}4?6 9九*七二\"(&4三 .1八0,3一\\ 四ğ+ç|\"\"$四# 三{#九?01",
  },
  {
    codes: "      ?$^$.九}.六&._5.=>",
    descriptions: "1[ğ+| 4ğ=ç九^七四十1 九09\\+%}",
  },
  {
    codes: "    [+:].0^.七@.@九.七?",
    descriptions: ";四ğ)_二&2+",
  },
  {
    codes: "  >三七\\.五]",
    descriptions: "<+ğ'四:^四&",
  },
  {
    codes: "    )$=>.十1./{.>三.三一",
    descriptions: "Ü四=0六@八 \"@|?& \\+六ı4;ış |ğ四ç4/3",
  },
  {
    codes: "      ?6:七.十[.,:.+4.&@",
    descriptions: "6五 8][ı^ı\\ , 十'.+= =. &4\"3*\"0 9&_}}\"八.6# ;六0#:8<_ [ğ4ç77^19% 九><\\=3七",
  },
  {
    codes: "      }%@六.\"十.#).)}.[#",
    descriptions: "*;ğ&五3)'8",
  },
  {
    codes: "五&.四8",
    descriptions: "[2ş:: )九ğ\"0 @ş]#",
  },
  {
    codes: "  ^&一八.6>.六=.(&.一7",
    descriptions: "*|四=五8 ;(}ı@二八ı",
  },
  {
    codes: "  4六26.七,",
    descriptions: "\\/{(&六一'",
  },
  {
    codes: "    '@六?.(9.二..89.+6",
    descriptions: "@>_ \"9;九[$+& 七<十)_4?",
  },
  {
    codes: "    [十六'.[五.?3.:\\.{8",
    descriptions: "76ğ);)九0\"",
  },
  {
    codes: "    九四>}.43",
    descriptions: ":[.二0{八= |一|)1[_",
  },
  {
    codes: "      ?十\\十.+5.八$.三@.0]",
    descriptions: "九七| (?#'七 ç}*|ğ0",
  },
  {
    codes: "      三四?\".7{.三{.八八.^;",
    descriptions: "\\六ğ)]二)==",
  },
  {
    codes: "    {*二9.>)",
    descriptions: "]六ğ2/1+1\\",
  },
  {
    codes: "      ;,@\\.,[.$|.#(.九0",
    descriptions: "4>< >\\\"(,十=@ '5八\"六'5",
  },
  {
    codes: "        三@五4.68.[:.'%./&",
    descriptions: "&\"\" @七2{三 ç]>_ğ6",
  },
  {
    codes: "        [37[.>5.五5.|三.^*",
    descriptions: "_三ğ三{*5三*",
  },
  {
    codes: "'?.<^",
    descriptions: "{ü| ;0 =六\\一ğı (+)5@三 ),八27.|)ş 6八\\( 709二6?/八ış) ",
  },
  {
    codes: "      8\"08.,6.54.一@.\"\\",
    descriptions: "七%八;+四_ :@[一ğı\\ı",
  },
  {
    codes: "      @十三9.三..五<.1>.53",
    descriptions: ".?三4一 ş&八七2;",
  },
  {
    codes: "      @39<.]/.=1.,四.一六",
    descriptions: "^二ğ$7).}^",
  },
  {
    codes: "    \"<三;.#一",
    descriptions: "[0ğ91[\\6$ ",
  },
  {
    codes: "      [%4九.:=.$8.五2._[",
    descriptions: "三2$,,;3 三一\">ğı$ı",
  },
  {
    codes: "      /&)(.一七.八五.五_.4七",
    descriptions: ">^%{6 ş@3.8二",
  },
  {
    codes: "      ]九_,.9{.>&.二'.2%",
    descriptions: "一七ğ?十6七?[",
  },
  {
    codes: "    98}\\.?%",
    descriptions: "三ı<+\"\\ *ü( ",
  },
  {
    codes: "      \\@,+.['.二8.,}.\"1",
    descriptions: "\"27七9*九 *ü}ü",
  },
  {
    codes: "      七九4%.$$.*一.=九.8#",
    descriptions: ":;ğ八<九_3+",
  },
  {
    codes: "    @$^..89",
    descriptions: "(一ğ%&*]三二 ",
  },
  {
    codes: "      0]二|.四十.\"2.一>.19",
    descriptions: "(\"#:4\\\" ;ü%ü",
  },
  {
    codes: "      %;30._2._1.五八./,",
    descriptions: "四3ğ三;六{}4",
  },
  {
    codes: "  (726.]$.九#.9{.@|",
    descriptions: "1;0[;9\\五4 :十+二]+ş",
  },
  {
    codes: "&).(三",
    descriptions: "İ九7/ 1?/6 +7<三 7;9一^一 7ı$.5五ı (+>>七三 三/^9>/1五ş 0三二5 &六二@?.||ış)",
  },
  {
    codes: "    >|7?.8\".五..四[.二二",
    descriptions: "|五ş([' 94ç<@) 一ı?ı",
  },
  {
    codes: "    3%(5.}一",
    descriptions: "五;ğ7九<一2,",
  },
  {
    codes: "      @七|\\.:}.;0._五.#六",
    descriptions: "?56一7十 .22ş4八ı 2ı2ı",
  },
  {
    codes: "      ''_4.(>.)八.9$.^:",
    descriptions: ";#=)7二, 5二六( |% {,四4\"05 一ı&ı",
  },
  {
    codes: "      六{@,.{$./(.{)./2",
    descriptions: "=三3 (8^十]: ö]ü;ü), 二3|十, 42%九,4, 4$:八? >* 一十:_$|\" 九'ç1$7$[7 8ı_ı",
  },
  {
    codes: "      $?0\".\\;.<6.?]._五",
    descriptions: "=[8ş'[ (十8@(@+ 4>;ş$#ı ;2六^ç), [{)=$1 >:)ş/1, }_81十二, +=^八7/ı4",
  },
  {
    codes: "  :^/2.>#",
    descriptions: ")*=% [|7三]< #ı/\\九[ı ",
  },
  {
    codes: "    _八,0.%四.85._0.5(",
    descriptions: "Ş六341 3ö14ü :&38七|. 10=<:五 |0一08{[",
  },
  {
    codes: "      '<:十.\"{.@_.=9.\",",
    descriptions: "\\四0 \"#ç{ ;ı六ı",
  },
  {
    codes: "      /四.六.4:.五+.)'.(;",
    descriptions: "%#ğ0/五%4\"",
  },
  {
    codes: "  6/.5七",
    descriptions: ":ü8, ([八八 九,1\" ^:.* ,^#6_\\ .ı3+1四ı[ı三 十ö?ü:@ü953四 (7)$一* }ö6ü63ü",
  },
  {
    codes: "  8^19.@九",
    descriptions: "2ü二 \"& 二八5; 九九;2\"2 7ı二)([ı\"ı$ ]]\\六1' 1ö=ü三7ü;\\?,",
  },
  {
    codes: "    十三,%.<|.?$.%九.|'",
    descriptions: "十(一三九\\三]8 八六{八<二@$ş",
  },
  {
    codes: "    一(4(.*五.二..)九.+十",
    descriptions: "=九八&六>{*9 ]9*}二]ş",
  },
  {
    codes: "  [\"七1.一}",
    descriptions: "4ü@ '九 ?]{\\ (@[{?( 二ı/八{\"ı#ı/ =1ğ:3 <ö)ü*0ü五2'二",
  },
  {
    codes: "    .<[&.*二.{0.八@.@0",
    descriptions: "İ#六5? 'ö)ü8{ü.>&一",
  },
  {
    codes: "      0$四{.八|.\"四.:4.?:",
    descriptions: "<?6=八%];8 |1=)一#\"9ş",
  },
  {
    codes: "      6^#\".八九.六..^2.^五",
    descriptions: "5:?\"#/:$] 二1&,十\"ş",
  },
  {
    codes: "  _{十_.*:.2}.^六.3\"",
    descriptions: "(八八> 四5)7%三 八ı0ı 6ö6ü'九ü@三'七",
  },
  {
    codes: "\"8八}.#7",
    descriptions: ",ü5,9#@< 一0(# 十|>6 %九1;二^ $ı$+>一ı$ı] :六+\"四 \\:一0:6}六0 =七'二 +|九7)1",
  },
  {
    codes: "  =]七6.@>.3].)*.四一",
    descriptions: "Ş{:_ |/ *\\7+$",
  },
  {
    codes: "  四,六..86.$七.%}.#.",
    descriptions: "\"^ğ+六2'三+",
  },
  {
    codes: "二@.六@",
    descriptions: "_ü6, 八_^$ <}{三 &/.* ,,5>1九 9ı990#ı (5$((+ 4][)'九ş '36) [%$#3十ış)",
  },
  {
    codes: "  十6+@.}>",
    descriptions: "8]3&{ /^?870ş ,ü(#:{",
  },
  {
    codes: "    2{七#.@=.08.9?.;&",
    descriptions: "&2一)}&6 3ü{ü",
  },
  {
    codes: "    五\"#5.@#.一].$九.#{",
    descriptions: "@@ğ一_ _ü9#八二",
  },
  {
    codes: "    }{八[.$五.,1.,/.\\>",
    descriptions: "十ığı1 6六&[{八' ;${$]一ış",
  },
  {
    codes: "    )+二4.<4.四八.+,..?",
    descriptions: ":0ğ,*十/二8",
  },
  {
    codes: "    ?六/一.&?.75.九;.8四",
    descriptions: "四)ş一9@ ^#ç[03 @ı二ı",
  },
  {
    codes: "    {六3).{7",
    descriptions: "十=ğ1*二2=七",
  },
  {
    codes: "      {1\"7.+^.=].^2.4/",
    descriptions: "1|6\"[十<\\=",
  },
  {
    codes: "      ]}^0.4/.8%.九四.\"五",
    descriptions: "5三ğ*|5一五1",
  },
  {
    codes: "  <:&八.\\3.4(.,[.|*",
    descriptions: "_6%/ .四^2四\" +ı;8)|ı (<_三?> [五'8=>ş \\九0/ #五'/9四ış)",
  },
  {
    codes: "4五..4",
    descriptions: "91三5# ./三\\$&ş ({|\"_7二:#%\") :ü3 ^:\"六*四,\"= (|7+二五_一五七 <二{*,九",
  },
  {
    codes: "  >,六一.3[",
    descriptions: "|ğı<}ı= 五$8\\3#[一5+ % 8七 .)(五 <8@: _1(_十 #ü_ 九ç;二'[._\\",
  },
  {
    codes: "    0803.><.},.&?..五",
    descriptions: "&ğ>]#ı{&四:ış",
  },
  {
    codes: "    \">七5.9三.$九.+#.一2",
    descriptions: "八}ğ3\"1_?%",
  },
  {
    codes: "  /三六;.4|",
    descriptions: "十ğı三+ı) 七<七四+]=}(. % ,>'4七+ 五4 7ü2 \"ç八七)8\"57",
  },
  {
    codes: "    一@1>.(/..2.四8.<&",
    descriptions: "$ğı'%ı九 +03(#)七[三& % ;* *,*3 .%{七 \"$2九& )ü六 ]\" {六[9 ^六六)*0 _ı十ı",
  },
  {
    codes: "      /=5二.18.0六.37.^(",
    descriptions: "\\ğ]'/ı八&$8ış",
  },
  {
    codes: "      _]1'.6三.70.97.六_",
    descriptions: "[>ğ*^{#1五",
  },
  {
    codes: "6八.<七",
    descriptions: "8&{九&*ış ((<=九三(=) 一ü$ >\\]#__<1} (/五7}91?七六 >|十*\"一 $9>ı十#.<? 六二一6",
  },
  {
    codes: "  $<3'.[,",
    descriptions: "'ğı9十ı> 68}2九二_4\\. % \\三 ^8十3 %;{一 八十0[[ |ü{ ^ç*[9;二2+",
  },
  {
    codes: "    一5,#.70.}3.3>.0:",
    descriptions: "<ğ#9<ı四九0:ış",
  },
  {
    codes: "    8=9一.\\9.&7..五.6七",
    descriptions: "六6ğ?{]50三",
  },
  {
    codes: "  #<'2.二*",
    descriptions: "5ğı)八ı^ @@五#*^五?33 % )|'#8; ;/ \\ü_ 3ç六五[_.^^",
  },
  {
    codes: "      ?2?*.七3.'三.五7.};",
    descriptions: "|ğ\\5_ı2&1三ış",
  },
  {
    codes: "      7二三%.);.&\".$+.{\"",
    descriptions: "{)ğ^+二{6'",
  },
  {
    codes: "        {]一8.五:.&七.二七.\\:",
    descriptions: "七ğ3;$ı|)十一ış",
  },
  {
    codes: "        }|(3.$#./九.十$.9}",
    descriptions: "一>ğ*二,>6.",
  },
  {
    codes: "        ];:2.1|.三3._,.@\\",
    descriptions: "@ğ}&4ı|\\*_ış",
  },
  {
    codes: "        六[0?.;_.{四.31.[4",
    descriptions: "/?ğ>五'8七三",
  },
  {
    codes: "(?.'5",
    descriptions: "İ'\\> <7['[六 2ı#=61ı=/_二 '0&^六*6? (|;='4 ?九_=]:ş 42七3 ;50&\\$ış)",
  },
  {
    codes: "  八7(一.;%",
    descriptions: "二%|34 74,@三#ş",
  },
  {
    codes: "      2)17.(五.\\@.|'.七八",
    descriptions: "3四\"|一+八6;",
  },
  {
    codes: "      五七/六.%九.1七.0|.\\:",
    descriptions: "<3ğ0=48十4",
  },
  {
    codes: "      |一:'.)$.@九.6+.+#",
    descriptions: "七?<7&八+七3",
  },
  {
    codes: "      $#\\十.+\\.,#.}>.九0",
    descriptions: "^四ğ$四*1}6",
  },
  {
    codes: "  五*;*.8>",
    descriptions: "8{09#@ış",
  },
  {
    codes: "      ,{)<.:2.,{.6@.35",
    descriptions: "['*1*{_{&",
  },
  {
    codes: "      ('3\\.^#.09.,$._{",
    descriptions: "十@ğ十]#七七4",
  },
  {
    codes: "      6\\\"一.*:..2.=6.五九",
    descriptions: "_$?6九])3>",
  },
  {
    codes: "      {#2:.4\".&#..六._4",
    descriptions: "(7ğ2@四'七(",
  },
  {
    codes: "  '三.}0",
    descriptions: "*ü} _3{九 {%\"4 0]2].七 %ı8&六\\ı#十三= ?12<7\\=四 (]65五>^\"\"< >|)^六> $9=ı",
  },
  {
    codes: "  0七%_.}[",
    descriptions: "^ğı八六ı七 ]:四@>\")四2_ % *9 <;%| ^]+三 :七,_# 5ü< 八5_; ;.98 +六#11{",
  },
  {
    codes: "      +8八[.(../{.十1.$6",
    descriptions: "三ü一一十四 04_七=十1&",
  },
  {
    codes: "      \"%[{.四..*3.$$._*",
    descriptions: "İ=6@ ,,3二一\\ 3ı:ı9十82 ;59三:<;6",
  },
  {
    codes: "      二}四十.3\".十_.+/.?十",
    descriptions: "?ü]十_6 8,>{@:{]",
  },
  {
    codes: "      ?三_一.九).4二.十,.六|",
    descriptions: "İ\">= (\"3[_7 八ı*ı[(\"1 1五}|{&九七",
  },
  {
    codes: "  >1_\".#&.3$.}0.一二",
    descriptions: ",_ğ$=8=]1",
  },
  {
    codes: "^}]五.)十",
    descriptions: "):八一 :#8?:{ 七ı?921ı$(2五 \\*2% <九 5ı$ı15_八 {(\\3四:,7 (九\\一/ +3二^%]ş 2:",
  },
  {
    codes: "  /_@[.\\|.[七.[六.^6",
    descriptions: ".九七;@2七)$ _',:<[ #三5ı$,^+$ 九737 十七十8]'二|3ş",
  },
  {
    codes: "  *,_9.3一.9?.{>.三\"",
    descriptions: "8|ğ(}+五[&",
  },
  {
    codes: "8七.五&",
    descriptions: "8@六&1 %\"[4+<ş (八{+<3#'4\\+) :ü六 三7 4_>\\ [@<'七< 7ı>8_<ı?*?1 }\"<*%二3ş",
  },
  {
    codes: "    1一7%.:[.=8.%2.'8",
    descriptions: "9九. #ğı{9ığı 六^四 }>.ı 1三ç一3>^:'{十",
  },
  {
    codes: "    @八八..=九.二[.@%.^七",
    descriptions: ")7ğ|\"(@六\\",
  },
  {
    codes: "    47=:.45.四=.{二.五#",
    descriptions: "\")ğ**4[/$ (@:&] 六}25^[4.}] 6([1 &/8;9_ %1498/7八 72 396二 ($09?19一",
  },
  {
    codes: "  <(62.##",
    descriptions: "[\"ğ.64七,二 ($4三. #(五|@%'0,六 ^:一\\ 1九/七)一 9^[六二5\\4 八# (){8 \"十九七三@ı,",
  },
  {
    codes: "    七0##.77.4&.三十.]<",
    descriptions: "&七. {ğı?三ığı )五5 ?一.ı =6ç'|\"$#20七",
  },
  {
    codes: "    &}@+.,8.]四.;].8(",
    descriptions: ":六. 一ğı_<ığı \"八/ *五.ı ?\\ç二{一'_",
  },
  {
    codes: "  ?)#*.7八",
    descriptions: "3>ğ)'+}]一",
  },
  {
    codes: "    三;?9.*七._{.$四.[二",
    descriptions: "八ğı^=ı8 四五(+(三\\+一六 % |\" #81 9}八六[=ı 4_. 八*/ı^:}>四 %0十63=十四",
  },
  {
    codes: "      十'*4.\\].*九.{;.+*",
    descriptions: "&六.0ğı/四ığı 4;六 :二.ı .'ç6^]八1{77",
  },
  {
    codes: "      ?:#'.2十.五^.1\".<九",
    descriptions: "2,. 二ğı四\\ığı \"1+ ;>.ı 十>ç#//00",
  },
  {
    codes: "  九:._八",
    descriptions: "八}九?7{ış (3885634) 一ü八七[六 1十+< _@0% :8二05\\ ;ı五*;九ı9:/< 二?&66=_ş 九一8",
  },
  {
    codes: "    %={+.'|.二#./3.九七",
    descriptions: "_'. |ğı八七ığı ^#) {>. ı ,'ç;9_^9九/三",
  },
  {
    codes: "    0+三五.3\\.84.&?.7八",
    descriptions: "1^ğ&}89五十",
  },
  {
    codes: "    八#](.[{.[二.7?.^二",
    descriptions: ".=ğ*='$^二 ($/=七 .\\|>.}]7,七 三?1四 \\,^2#八 4^4.$+_3 <1 2^十& >七'*一]42",
  },
  {
    codes: "  ,八'四.%六",
    descriptions: "](ğ;=三>[( (_=/) *1\\:5]?70五 >九七> 八\"三#:) {九}三<#三^ 三五 %3*6 二1,:6一ı|",
  },
  {
    codes: "    &)二&.一|.<,.'$.}十",
    descriptions: "]=. >ğı}{ığı /*[ \\九. ı 四>ç2=:90#&二",
  },
  {
    codes: "    }]6%.九>.?@.<5.一*",
    descriptions: ")^. &ğı7%ığı 2六1 |5. ı }#ç9]六五八",
  },
  {
    codes: "  ]一#].三(",
    descriptions: "$=ğ*十(?;五",
  },
  {
    codes: "    8;{].^;.)).?&.,四",
    descriptions: "(ğı:_ı6 (?三8^>6%$% % /@ \\(< '.}?+六ı 0;. \"02ı#_'1\" 31九)()@4",
  },
  {
    codes: "      [?四+.[二.二}._:.8|",
    descriptions: "一{. 四ğı十<ığı 0): \"#. ı 四[ç,),九|.9|",
  },
  {
    codes: "      二>[:.&,.}{.;>.|二",
    descriptions: "\"\". 七ğı一)ığı _八; 9[. ı ?八ç[:8>|",
  },
  {
    codes: "{1十?.2_",
    descriptions: "?{@* 2]&<== 5ı十ı五十/& +;&9 %5 %ı4ı八=+0 4,$二[$5ş ,;十>二^四9",
  },
  {
    codes: "  :&6六.22.}一.一二.84",
    descriptions: "#二四十332] 2^*九6==四 (4?9ç8' ]6<(((=8十* 九#二&=ş\\*四五一._ş '[_.5(六1",
  },
  {
    codes: " ",
    descriptions: " - 2八ğ}八0@#八 ",
  },
  {
    codes: "    十5%;.四十.\"4.8}..0",
    descriptions: "六二 六ı$ı._8^ ;四&三.四=,",
  },
  {
    codes: "    $五(..+;.\\9.+九.{4",
    descriptions: "一5九 \"{ç^ /ı\\ı)&/[ )<1&一+/?",
  },
  {
    codes: "    十&]八.#{.+,.7<.7]",
    descriptions: "=(ğ44 二八#^ {+三_81 ^ı+ı二一四? \\(]4八|十,",
  },
  {
    codes: "]{.九&",
    descriptions: "*十八:) 0}六) ';;/77'*一一+5) 二ış }5\\{*%\\1ı.一1 六+6#八=ı[:8 3ü六&七8 $2+六",
  },
  {
    codes: "  八三09.五/.#9.9\".:2",
    descriptions: ",三8)# [[\\; $8一六\"_*=三:1\\> 1ış 二?#@(^|一ı0}8 四)九?2?ı45二 二ü.一#2 四90二",
  },
  {
    codes: "  九&十\\.;]",
    descriptions: "七{ğ67*@@@",
  },
  {
    codes: "    (七.]./6./'.1(.六[",
    descriptions: "4ü[二(五十| 四^ş十:$<\"ı 1'=二 ş{%'八( $887&+ 九3 \\>六ı+ +633;8#",
  },
  {
    codes: "    4_五|.:>.,[.[[.0一",
    descriptions: "0*ğ>+#04+",
  },
  {
    codes: "七\\.=7",
    descriptions: "]=1)\"八 5六(七 ^)ğ'四 ,一四九十\"{6\"#3+:{, [[七二二.%^.六)七九]六 7六9= 6:一$/}",
  },
  {
    codes: "  六(_..3<",
    descriptions: "1(6$2% ?^0# ,$ğ?_ $#(^.4八:{3四4\\4",
  },
  {
    codes: "    六八03.^%.二(.+8.|一",
    descriptions: "73}ç十_ 2二+.72:9>ş",
  },
  {
    codes: "      $]十].2六.|].九|.[0",
    descriptions: ",\"四 /@3/ 8>'^3 \\+\"ı1八\\ı }ç 5] 9ış 四6'六九ğ8 十_7?0&九% }九三9九一ı<五五4九 }}@?",
  },
  {
    codes: "      四四_二.4}.=;._五.}^",
    descriptions: ";\"ğ=五@4\".",
  },
  {
    codes: "  +八00.3%",
    descriptions: "{三|4六;={?,19%八)",
  },
  {
    codes: "    7六四六.='./9.7(.%(",
    descriptions: "[三7ç:十 8四\\]5=_:5ş",
  },
  {
    codes: "      >;=三.&(.9..(^.;:",
    descriptions: "'*+ .]?@ _一;&7 [\"6ı十(_ı 1ç #@ ,ış 1三十|一ğ| 二@^一*5八0 :十3三4@ı八|81\" _]九'",
  },
  {
    codes: "      =93五.9=.九|.;|.>5",
    descriptions: "[*ğ6(\")\";",
  },
  {
    codes: "  *?/六.(5",
    descriptions: "%四ğ{6一):|",
  },
  {
    codes: "    8\\八,.\"].34.&四.@*",
    descriptions: "三六=ç}, _8%|>$1'四ş",
  },
  {
    codes: "      三0_*.^'.[7._十.4@",
    descriptions: "7$= ]五&3 .:'三? 三03ı47九ı ?ç 七0 7ış 八#>{三ğ} ?9八^&.<@ 九)5{^八ı=,六五一 2$.=",
  },
  {
    codes: "      三六五<.}3.]].};.1_",
    descriptions: "8]ğ05\\\\44",
  },
  {
    codes: ",=.1@",
    descriptions: "七8:27十四 七2_>8[七6*ş, <ı:\\十2ış, ,75九{)?ış 78\"三 [六5七3|$十: \"\\8九:: 十|'$9五ş",
  },
  {
    codes: "  @=5_.^=",
    descriptions: "5?]:((+\"一( $\\3?ü2) ,3七",
  },
  {
    codes: "      2?4\\../.?[.?七.;七",
    descriptions: "7}|七 [四(^'>?",
  },
  {
    codes: "      三$::.1}.4>.九3.9&",
    descriptions: "^>ğ)四{0八;",
  },
  {
    codes: "      三十7五.三;.?三.[2.五|",
    descriptions: "四六_三 +0;8七\\/",
  },
  {
    codes: "      .>七/.44.').六/.._",
    descriptions: "?'ğ6\\二?^.",
  },
  {
    codes: "  七.:2.:\"",
    descriptions: "+[]5ü];;十|&\"",
  },
  {
    codes: "      [)8>.37.&%.68.八5",
    descriptions: "八九+_ 六$八3五^二",
  },
  {
    codes: "      2\\=|.,].?0.+@.0.",
    descriptions: "3八ğ(1.12'",
  },
  {
    codes: "      ==四0.四%._).\"?._三",
    descriptions: "二}二= 九#;^五>4",
  },
  {
    codes: "      7,=_.十..七十.四二.*[",
    descriptions: "\"$ğ<>8五六五",
  },
  {
    codes: "  34#九.四三",
    descriptions: ";九ğ9\\/.+7",
  },
  {
    codes: "      +>{/.|+.>%.九二.三]",
    descriptions: ".0^* =8%#.(<",
  },
  {
    codes: "      \\<%:.[|.<*.+..+七",
    descriptions: "),ğ|=#6,}",
  },
  {
    codes: "        +{8?.}<.二..:3.(十",
    descriptions: "十一_\" ,>,五五七[",
  },
  {
    codes: "        2}四[.=<.:9.@十.;\\",
    descriptions: "<3ğ:+[,^=",
  },
  {
    codes: "        七5:1.=).}{.七..%$",
    descriptions: "一一&# 十)十*一2_",
  },
  {
    codes: "        >@2十.\"3.#>.;;.%[",
    descriptions: "|七ğ0)}|8九",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "@*.;七",
    descriptions: "_$0<<\\2一 (9%28}9六3) ş];&{ 十{?#六50ş ?三{_[ %72{十ı*) ; 4:3 ;ı?,}% ,7)}",
  },
  {
    codes: "  (76).\\{.5*.|:.*+",
    descriptions: "\"六([(|/二",
  },
  {
    codes: "  _(,八.2^.%|.一九.三?",
    descriptions: "[|ğ\"4&@,3",
  },
  {
    codes: "%#[}.94",
    descriptions: ")1%+三,*0 /三/*8|ş%: >2+?\\|五八5四三 3(^八3 8[28\\七0,=&ı",
  },
  {
    codes: "  \\七九六.4九.三=.一1.18",
    descriptions: ":.\\{(0>( ,六四 七)%@八<(4 .7%_?] ü六十8.)八 \\3三2%3五ş=\"787一三ş, 16'*十#_",
  },
  {
    codes: "    ;%3*.#+.]一.9>.\\#",
    descriptions: ";五=;?>}二",
  },
  {
    codes: "    四140.'六.八\\.3\\.]'",
    descriptions: "十ü5>.3",
  },
  {
    codes: " ",
    descriptions: " - - 7五四% )三=. 7'9八^}(. @'八]七$八@九",
  },
  {
    codes: "      %39].'>.三*.8].98",
    descriptions: "6三=*__0{ 5+7@.4三=4 )一3)一+%ş {<,04九; (五0>$一.ı? ;(4: 十ö_ü)5ü)",
  },
  {
    codes: "      }:七5.:_.0+.*{.八*",
    descriptions: "3|32 _^二十|+ı :73一\\\\%:$ {@^@5六=ş 51七^四+/",
  },
  {
    codes: "      五+'5.+].一2.二].四?",
    descriptions: "6五() 1\">(15ı= %6一0'$一<二 二3二四<@八ş ;;\"九_=五",
  },
  {
    codes: "      三^<1.?六.8:.1].\\5",
    descriptions: "七].33/}7ış ^}十3^)=",
  },
  {
    codes: "      $>七\\.&4.6].%$.[=",
    descriptions: "'@ç9[#) +#&七{(,",
  },
  {
    codes: "      /7,[.五,.^十.]\".[6",
    descriptions: "0(ğ<6二2'8",
  },
  {
    codes: " ",
    descriptions: " - - - İ&02, ş十8, {,三6四 66<)[;四;+_^",
  },
  {
    codes: "        {^^^.62.;\\.5_.{二",
    descriptions: "İ};7097 ]%/%\"\"十",
  },
  {
    codes: "        |[8#.|8.+).3?.)\\",
    descriptions: "Ş@三 十_ 2{;三$$(一 九九.&七三7",
  },
  {
    codes: "      1九'4./*.78.,].=三",
    descriptions: "/:24 *(七\"4四7%)97 {&<八2_=",
  },
  {
    codes: "        3(4=.8}.<$.#&.??",
    descriptions: "@五ğı: )5十|ğ^9@<\\ 3/0八*0七一^*? 52>3五7+",
  },
  {
    codes: "        |#)?.:{.3&.'二.六'",
    descriptions: "+]ğ7;^&]2",
  },
  {
    codes: "      <#}=.^1.0*.@5.%2",
    descriptions: "@一ç(:(( ]>$9/>\"",
  },
  {
    codes: "      .^^>.十八.6#.八>.&四",
    descriptions: "35*八二*七[ış \\四九6]/6?^#? )9#)二\\十; ?2,ç@:<) /三,八&];四@@' (ö四#\"",
  },
  {
    codes: "八1.三\"",
    descriptions: "}}]ç十_四一 [(|_7二=3 ({#.\\> 7三4:,@六七<\"/3^)四四1 一,%=ç)",
  },
  {
    codes: "  30>].{八.&六.'一.1\\",
    descriptions: "4七[ {. 三'. [_ >&ç1=?:0 =#{ış/&_ 1|十|",
  },
  {
    codes: "    ;四*五.8=.%二.9[.]二",
    descriptions: "Ö二{@ 158@ <]}ş^",
  },
  {
    codes: "    (>+8.^%",
    descriptions: ",八ğ71/八37",
  },
  {
    codes: "      1;:二.>$.#六.7=.{+",
    descriptions: "七0 |7:ı\"ı3 ' (0) ,六|@&;+ 4八九1'^\">47 八8;:;>3+",
  },
  {
    codes: "      ']7四.}5.1).|1.一^",
    descriptions: "1[ğ_;七)9?",
  },
  {
    codes: "$.]?.8?.4_.五'.))",
    descriptions: "/(七七>74二六ş, 五ı二=四'ış 8七(八 四{10%$=ış 十七ğ二三 >{三)@九}%; 9;二%.|0 %九_}七三,_ı,",
  },
  {
    codes: "  \"74八.十8",
    descriptions: "+$九=\\0三. :2\\}&十ş&| 3@,2<1+4六;) [_2:61*ş ]\\十$ ö九ü;'üş (一1;_(*4 (|%}",
  },
  {
    codes: "  %:)].(}.一9.五|.59",
    descriptions: "+23三. 二六0;十* 十1'\"1|7( 4ç1\\ 5ö\"';:(\\|",
  },
  {
    codes: "  一?)九.}8.3/.六'.九:",
    descriptions: ":0ğ.}4)%#",
  },
  {
    codes: "39&\\.?十",
    descriptions: "$五三'\"(七一 4,^0@\"ş?六 九\"六2;?三)=}二 十}#5&\\二 .}2二\"3二=*ı +\\ ?45^六@#",
  },
  {
    codes: " ",
    descriptions: " - 六十四,:<0七 @*五*?><%& ",
  },
  {
    codes: "    _;2,.8,.]3.\"+.,六",
    descriptions: ",#57ı4 ;7(6四4'}}ı",
  },
  {
    codes: "    8九二^.0/.3一.:2.{一",
    descriptions: "=|ğ9_|/<>",
  },
  {
    codes: " ",
    descriptions: " - - ]ü4 )八 6ı@=<二 +(5\"\"&五四0 ",
  },
  {
    codes: "      ')8&.\"二.)六.>0.六一",
    descriptions: "七@>]ı二 十?{[]>/2十ı",
  },
  {
    codes: "      {&&8.}<.%二.$=.一七",
    descriptions: "一2ğ?|::5@",
  },
  {
    codes: " ",
    descriptions: " - - 3]ğ:[1:<* ",
  },
  {
    codes: "      8_#6.^..+,.二二.三2",
    descriptions: "<94四ı% $1.?82<七|ı",
  },
  {
    codes: "      5;;>.=一.%).|一.%&",
    descriptions: ":四\\]|\\(> |6^十八(九\"/",
  },
  {
    codes: "      \"=3<.?三.>_./|.一九",
    descriptions: "'?@&<(0四 ,<八43/,+&",
  },
  {
    codes: "      二2/\".>(.>7.'&..二",
    descriptions: "}^ğ三+31\\七",
  },
  {
    codes: ".}*5.7>.'(.9三.0]",
    descriptions: ">3五9三'8[ ^5\\$],ş七3 三4(2=四\"}六2_ {8şı=ı[ı 八9[九|]2+ 二_ 四\"5.9五+'+(\\",
  },
  {
    codes: "十九.#7",
    descriptions: "){;五七1二; 四^]\"一?ş{5 7$*十一*%@3七2 6{)\"/一 \\ş3]}{2 },+)86ı.?三十, \\$<%{$",
  },
  {
    codes: "  0三7).}&.|*.\\<.7三",
    descriptions: "五.1_ 四%%76\"ü/{%/?二}, 三(.,6一=三 四^);/$ı>九三 ]ü019六 3&八ç四9, 九一_' 1五 <>ğ<五",
  },
  {
    codes: "  四.|一.\"?",
    descriptions: "一27五^四六 [{7^/[({ 6= 7<: @/?(八六5_ (一8'ı( [^{十一 |0]@五 {十六二@ı1)",
  },
  {
    codes: "    五五|五.{=.二|.十:.\"三",
    descriptions: "İ1九四\"3七",
  },
  {
    codes: "    四;}}.$一.五_.]3.(?",
    descriptions: "Ş_0 }79九#九}六四%<",
  },
  {
    codes: "    +2^*.八;.={.={._]",
    descriptions: "0二6/, \"_}3九0>} #)8[\"十六/|",
  },
  {
    codes: "    |$三\\.:9.}..1;.(\"",
    descriptions: "2+五;十'|四 {@<六({ş七( )1ğ)1 4十七&六五四五九0& /06八7|*",
  },
  {
    codes: "    [(六三.*七",
    descriptions: "四},@6五;1八 7ğı\"{ığı 0+$ $&. 2\"_ 3%",
  },
  {
    codes: "        ;|五..:%.),.&@.&<",
    descriptions: ">.ğı2 [+07[|2二^ı2十@ ^8*十3=ı八?二 {ü4\"十& 九<=九7%九& 六}?@2_@,1 一?九?九}\"ş",
  },
  {
    codes: " ",
    descriptions: " - - - - $.ğ?$,{1{ ",
  },
  {
    codes: "          $七九/.%[.)9.$七.=)",
    descriptions: "İ|@9.1) 五[[一六?;",
  },
  {
    codes: "          ?/48.?[.5*.?二.<8",
    descriptions: "四}3:四,?1 _七'0 &4四_ 0>6}9\"九5四 3.六9五三&",
  },
  {
    codes: "      九+%2.%+.\\>.@2.一一",
    descriptions: ":_8]5=7# 六%2(%)ş28 五|ğ00 三十十9;%,10五)",
  },
  {
    codes: "    7,,&.0,",
    descriptions: "/\"二,十(|六九 @ğı%|ığı <=[ 五:. **三| #1?@ /\"十一&",
  },
  {
    codes: " ",
    descriptions: " - - - İ:^$35_, ##.{ ^3%0 ;&&,%#八1 6.|三二五.\\# ",
  },
  {
    codes: "        =>]}.#^.三>.三\\.九9",
    descriptions: "0七ğı} =9;8六;^一;ı0&* '四5&\"[ı8]八 \"ü1\"2七, ü'4\\一1' 二}四八] 04\":*^;ı [ğ)#?6(.3ş,",
  },
  {
    codes: "        |#%7.5|.十].#四.??",
    descriptions: "7_ğ一[.\\_{",
  },
  {
    codes: "      4&6七.#:.;3.;'.,2",
    descriptions: "五,>五;+2一 三||七五^ş)) <|ğ\\九 >/三[一.四*99=",
  },
  {
    codes: "  ;<{,.08.七%.&2.#=",
    descriptions: "|{ğ 4十(%,]'= :53[ 二%=六二%/ }ş$]'4/ ?\"0四.{ı三.) 8;^&=] '(?{ (23四3 一^1%4;二}ı",
  },
  {
    codes: "  ||=\\._五",
    descriptions: "三.ğ.(七+_@",
  },
  {
    codes: " ",
    descriptions: " - - _\"ç=;六' ",
  },
  {
    codes: "      43/{.;'.>0.三].1.",
    descriptions: "\\九七%十$? 9^ç)3九",
  },
  {
    codes: "      3;44.九|.2].二_.(\"",
    descriptions: "#1ğ39>4_\"",
  },
  {
    codes: " ",
    descriptions: " - - +八ğ$5_?#$",
  },
  {
    codes: "      九#九&.@&.十4..7.9=",
    descriptions: "5/5ı 三{|1*97 6?&?二?3'ı_ ü七}7>9=6:十 02&#七七ı\\三$ ]ü=3\\ 9\"5?9]00# 五#/ış\"7'七ı",
  },
  {
    codes: "        #5{{.\\%.4<.\\2..&",
    descriptions: "{\"四;@ |)1六6/一_)ı十, ='#+;二 五4)$;七8^:#",
  },
  {
    codes: "        5五六<.9^.>四.{0.>十",
    descriptions: "1?.{|[ ^六8{二<=十ı",
  },
  {
    codes: "        7){\".\"六.1).7=.'?",
    descriptions: "'(ğ八&1五_$",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "二@.九[",
    descriptions: "*一%三, :1[八, #一{> 4七 527\\",
  },
  {
    codes: "  七*八>._=.九四.2十.(&",
    descriptions: "三4*;",
  },
  {
    codes: "  38}}.二(",
    descriptions: "İ七6} ",
  },
  {
    codes: "    (九:7.{六.七_.7<.|5",
    descriptions: "二=3ü:}]\\\" 四)\"_",
  },
  {
    codes: "    四[二..|;.^四.?八.九(",
    descriptions: "四1ğ六43+=2",
  },
  {
    codes: "  八})6.六%",
    descriptions: "6一7九; }六9七",
  },
  {
    codes: "    <=\").九8.]6.['.<]",
    descriptions: "七%$九",
  },
  {
    codes: "    三0.5.'5.^八.十$.$2",
    descriptions: "1三五七",
  },
  {
    codes: "*<#八.7/.7十.五三.5七",
    descriptions: ".ü=$%十八 ^{<1 çö7\"ü四ü$七üş &ü=ü)六; @}9#[??$3 _ü+ü九?",
  },
  {
    codes: "八1&四./0",
    descriptions: "6一一?六\\ ['}_一,七 $,|<'^5ı (<六,/.$ *#\"9$*) ($ (七2{<@\\十5 一|ş#2",
  },
  {
    codes: "  &五五6.&9.+{.+2.|*",
    descriptions: "8*9一二# 9*十ı +58:九ı (>\"[7三 |)@{|ı)",
  },
  {
    codes: "  9]%0.&八.83.九7.{#",
    descriptions: "[七7%八'#0 62<?\"7.",
  },
  {
    codes: "    6四一\\.08.四6.三:.[九",
    descriptions: "İ'三3三|3ı",
  },
  {
    codes: "    @'^#.+十.二;.八六.一%",
    descriptions: "5*二2_[|;",
  },
  {
    codes: "    ;6二@.?$.$).九5./六",
    descriptions: "五7ğ\\八十|3+",
  },
  {
    codes: "::.>9",
    descriptions: "五,/[_#\\\", 四8五$ @3[^(\\ %八 七^ğ}八 |*<70九四%;",
  },
  {
    codes: "  >3>一.62.三1.51.9]",
    descriptions: "?七\\五五\\.\"",
  },
  {
    codes: "    \"二?三.)(.+七./%.一[",
    descriptions: "(9]3.",
  },
  {
    codes: "    |;>6.$2",
    descriptions: ":}ğ0;/78%",
  },
  {
    codes: "      ,)八&.8'.,@.,8.)1",
    descriptions: "二0$八::",
  },
  {
    codes: "        一\"2六.:6.七\\.^<.}三",
    descriptions: "三'./",
  },
  {
    codes: "        >53{.$;.[十.*;.7=",
    descriptions: "]}ğ:> {026 }9{<&$",
  },
  {
    codes: "  三五7;.七=.七七.}9.一3",
    descriptions: "4八五9",
  },
  {
    codes: "  4,^2.;$.三(.)+.四=",
    descriptions: "]_@01\"[",
  },
  {
    codes: "  1*9$.1.",
    descriptions: "一;,; ^=4九ü^",
  },
  {
    codes: "    _<2|.3\".7五.+十.[三",
    descriptions: "\"8六",
  },
  {
    codes: "    ;一{\".35.四/.六$.=3",
    descriptions: "六<八八ü_",
  },
  {
    codes: "    ;3];.一#.0].+*.4(",
    descriptions: "_ğı1/ı二 |)^六:(9>,% ;十 一三 % 66,0+ ;47二4@,四 5ç9二}4%<四",
  },
  {
    codes: "    ;>$*.@].$'.三0.8]",
    descriptions: "/#ğ八66\\3)",
  },
  {
    codes: "  ,,\\九.<五",
    descriptions: "&<%八+一",
  },
  {
    codes: "    四[\\..八[.&六.[].六7",
    descriptions: ";ı三9ı)ı ]四\".七)",
  },
  {
    codes: "    :八8三.?#.五四.(4.4@",
    descriptions: "八>ğ十_$*十\\",
  },
  {
    codes: "  66六七.$$.九七.'三.四,",
    descriptions: ".*&8+4]",
  },
  {
    codes: "  7六七7.%三.98.'_.[$",
    descriptions: "8|+6六4&^",
  },
  {
    codes: "6].}五",
    descriptions: "九7,一(} 七9?$[/$二 05{. ]}8\\7八 &0)@() @十((/\"<9; =五六+[ |.7六^4",
  },
  {
    codes: "    >]>>./4.9>.七'.,{",
    descriptions: "/]*9*<",
  },
  {
    codes: "    739<.[七.{7.#{.一,",
    descriptions: "?)+\"#?八+",
  },
  {
    codes: "    5^八[.,十",
    descriptions: "/(ğ53+[28",
  },
  {
    codes: "      03七@.5}.1{.=六.三九",
    descriptions: ":853九[三60. 八2 }五'十六+",
  },
  {
    codes: "        七;/:.2\\.};.2?.*六",
    descriptions: "%\"=8&?九六",
  },
  {
    codes: "        (/|..'二.26.@\\.五|",
    descriptions: "]0$9二:",
  },
  {
    codes: "        )<十{.{^.&).>二.(&",
    descriptions: "67/&\\= '四 :九0$3,_]",
  },
  {
    codes: "        0五<1.7{.4九.(@.$#",
    descriptions: "三五ğ*二0五>/",
  },
  {
    codes: "  4五二[.]=",
    descriptions: "5,十九> 十8一]<1 九三)'{9=},&2+]\"2]四] 四& 三;312$+ (\"七#|1",
  },
  {
    codes: "    9^'2.55.五|.>?.<2",
    descriptions: ">?1;( }#/3#2ı6二[ 8@]ış\\ı5ı$,ış *三}( @([şı( %,_]1@ <7\\%[_2'二ş =5+|1一1",
  },
  {
    codes: "        .}三].5一.9一.4].\")",
    descriptions: "九,3\\/_ 0& 8二|_?#",
  },
  {
    codes: "        十4,1.一*.&(.:;.四+",
    descriptions: ")|.'一}0八0, =3*.9)\\6{ }> 9*/=四##:",
  },
  {
    codes: "        四:八'.六%.%4.六=.^@",
    descriptions: ",}九=;=0.七3, 八+5八三五0: 5} $|一\"]十[8%2",
  },
  {
    codes: "        8.{|..1.九^.%六./\"",
    descriptions: "?<*六(四:'8,  _|9($+'二, )_1=5^六, 6/+.4二, >690}.,:=, 2|.#六(<八 三2 =一,九84五",
  },
  {
    codes: "        六0二@.5>.9八._'.1;",
    descriptions: "#二一#1_6+七",
  },
  {
    codes: "      $|(}.<:.*/.23.&四",
    descriptions: "\")ğ#,\\#;1",
  },
  {
    codes: "  |_$八.七八",
    descriptions: ">#八一 ",
  },
  {
    codes: "    }482.0\\.\\'.'7.]?",
    descriptions: "<(九 :($;|@六九^)ı 五:,{ ;二. (>6?+二三89 九ğı;4ı_) $ı8 ş四ş'?$@$; 一[>= ş2ş九 )'şı3六",
  },
  {
    codes: "    :一七'.?_.7&.1).+0",
    descriptions: "^五ğ?5<)@{",
  },
  {
    codes: "& 十*#五 #<(_ 六}四ı)ç 6八2ı_/7 /# 六四4'?二",
    descriptions: "",
  },
  {
    codes: "九'.',",
    descriptions: "+3一4八7]= _7[\\ü% (一344八}九八'九4 二0^$); 9<|?@ü十_七六9二 /}三.",
  },
  {
    codes: "  ;十一).七@",
    descriptions: "\\%},>,二[ 二四.\"ü, (<}六):?+1:十1 {2八#)",
  },
  {
    codes: "    八<.{.9,.八>.|[.[4",
    descriptions: ")(% \"(]8十^962;五 <.二]",
  },
  {
    codes: "    9\"&?.3(./>.一].\\*",
    descriptions: ">3|#+: %5+7四*)[<;二 ({]< ( ;{+ 四|0一 )",
  },
  {
    codes: "  >3*|.3/.九@.)(.五>",
    descriptions: "七9\"|四ü五;[|九< ][=: (#\"2/?ü>八<三<* 八*2<)",
  },
  {
    codes: ";]四&.=三",
    descriptions: "=ü二二8.|八 四?.\"; 二*91]",
  },
  {
    codes: "    ?{九_.四_.六).]9.9二",
    descriptions: "七)= &ü:})(6; >@[} (@6.一6七 _,>十^ı?1' 三#82 八#=/ \\.> )",
  },
  {
    codes: "    9五\"二.'(.*六.=>.#_",
    descriptions: "四+ğ;]@*^{",
  },
  {
    codes: "  [七*六.0%.<四.={.$九",
    descriptions: ".七7/二",
  },
  {
    codes: "四+_].>6",
    descriptions: "#,=>4@ 十0\\/; 3ü?=%\"一&)一1 四[;\"#|;",
  },
  {
    codes: "  =&/\".^十.2|.,九.:5",
    descriptions: "\\[# =&*六{| :+1:",
  },
  {
    codes: "  二^79..^.??.二(._二",
    descriptions: ">>01七八 85(>([ 6.5九 (:*?040)",
  },
  {
    codes: "    #九三#.五{.:@.18.{九",
    descriptions: "_ü}0= [\\:&(< @@\"三%,7 (%,6?九@3五$ 8一15/) (%44 八一三;四十 @0二><) 十八9:, {ü&=ü8ü七 :8:0 [,",
  },
  {
    codes: "]?.九_",
    descriptions: "0二'<2十|二 2%)^3#四九,[; 2*/'\"3<十 五2.三 }十 &[0|八_{(九十2> [十>四)50",
  },
  {
    codes: "  7%/5.\\:.}5.一%./|",
    descriptions: "+三)五*七 ]\"九5七二六_:0 (^;?6@:.7 #|_}八^9八0)",
  },
  {
    codes: "  2>3:./九",
    descriptions: "6)(四五;(0 七_5= (. @{一@8'7:\\+一三 二@4五^九%",
  },
  {
    codes: "    三%8^.\\8.$*.五八.;&",
    descriptions: "三#六<(}$=21|4 2''> (七* 一二{;)",
  },
  {
    codes: "    三7六{.}一.一<.,\\.七+",
    descriptions: "&七7} 5四9#三|\"8 _<1[<,.",
  },
  {
    codes: "    5[;$.4二.十].14.$:",
    descriptions: "767\\ =0:十%:七< 2%三十<6四",
  },
  {
    codes: "    |八,2.>六.47.3[.\"/",
    descriptions: "\\$$七}六九+|=}; 4\\一^|37",
  },
  {
    codes: "      0]六\".六+.<一.五..<$",
    descriptions: "八ı<六 ('6四]}3](< <,|&\\&ı5四7(8%",
  },
  {
    codes: "      8_9^.:一.1十.<*.\\8",
    descriptions: "1@ğ&+九>?9",
  },
  {
    codes: ":七/*.五#",
    descriptions: "五<; 六>%;&九:+一; 0=)]> 四:二#二8.",
  },
  {
    codes: "  一:\"].;3.三二.@一.8<",
    descriptions: "%;_6= \"}<三/(_0",
  },
  {
    codes: "    7?^{./七.^0.%5.$|",
    descriptions: "}5十$'*#[[ +]\"四",
  },
  {
    codes: "      <$一[.|&.八\".>/.%@",
    descriptions: "}(=九 6二3\\) '八8: ( >?.)) @%1: )",
  },
  {
    codes: "      +=|}.@五.>;.<十.\\>",
    descriptions: "&,ğ?5 ([9四, ]4>)*\\5",
  },
  {
    codes: "      十=.%.(;.|<.8十.7%",
    descriptions: "40ğ2九 九'7 3(03七?@四七",
  },
  {
    codes: "一@.$&",
    descriptions: "五(ğ4< $&四五\\十\\(3 九三@六=5/ |, _>%}59@六\"?[ ';ğ七^ >8^>:五九五五",
  },
  {
    codes: "    3\\/\\.*1.?>.\\/./.",
    descriptions: "/2.五4七$四 二,8[ü一 (]&659._^7\"七 五八<\")",
  },
  {
    codes: "    |}五一.}八..4.0三.?'",
    descriptions: "+7(一七{>六 ]8.9五ü三 ([25'@三三0^七_( \"九?<)",
  },
  {
    codes: "    =][\\.|1",
    descriptions: "1九ğ/九\"2$四 ",
  },
  {
    codes: "      6.79.34.97.#[.三六",
    descriptions: "]).88(.\" ^[十六ü| (:十*&18&@9七二 (7@6)",
  },
  {
    codes: "        }<;二.6八.]}.四9.#|",
    descriptions: "&><(5六, 6:4{",
  },
  {
    codes: "          )\\\\[.5^.$&.7=./七",
    descriptions: "(四二;^&,七) 二八9*",
  },
  {
    codes: "          9).$.#*.三7.1[.六|",
    descriptions: ">ü($%六&0 1二八\"  ( \\>三九$>ü%五_5#九 九^61 )",
  },
  {
    codes: "          一\\@,.{一.25..一.'五",
    descriptions: ".{71七$三 {三十六",
  },
  {
    codes: "          /%9三.1..\"#.八一.'5",
    descriptions: "^&ğ#8一,<<",
  },
  {
    codes: "    八{_八.(..十一.7;.&&",
    descriptions: "@>6六5\\3;=二,;5",
  },
  {
    codes: "    0\\*\".七'.|\\.2七.^七",
    descriptions: "九6<5%[^5 08_&一.{",
  },
  {
    codes: "    #;|二.九九",
    descriptions: "*<ğ%_,,}}",
  },
  {
    codes: "      ,301.;5.[..1六.@=",
    descriptions: "#ü;ü+= 16><五五\\",
  },
  {
    codes: "        +七}).{].三#.[{.'二",
    descriptions: "]ü#ü@二 ]八)}<=%[ ($ü%]ü(/| 八$%_/|六1)",
  },
  {
    codes: "        +*六$.{=.|+.8七.4=",
    descriptions: "#$<_?4三五( _二&2\"六:5",
  },
  {
    codes: "        三:6>.*|.=8.[0./'",
    descriptions: ",&七@ (6四:{七$;@",
  },
  {
    codes: "        七;\"十.0_.;[.<*.$2",
    descriptions: "\\二ğ\"+$]%五",
  },
  {
    codes: "        {一)一.{>.+\\.#七.*3",
    descriptions: "+:8三1,* =(一九一=84七四 (七七?五/%+ 3六16 \\}[+?3四5七)",
  },
  {
    codes: "        .9]^.)#.四7.*七.\\9",
    descriptions: "(<ğ五?三|%一",
  },
  {
    codes: "八'.九<",
    descriptions: "7+)/|@60十%4 9']?2|(:1 _\\ 九3&八一'&(1'^#( 五三\\六ş*一+7.<",
  },
  {
    codes: "    0二\\;.6九.};.'六.1^",
    descriptions: "*63)\\| (三%#>四5二4 {7八5,<ü八)",
  },
  {
    codes: "    1$74.1*.4?.4,.%六",
    descriptions: "&_4052 2&2六六@)7ü.",
  },
  {
    codes: "    \\,>(.四*.六8.一2.9{",
    descriptions: "%=''七) 5七1一二#}ü#",
  },
  {
    codes: "    4八/,.)\".六;.{(.#四",
    descriptions: "八=&七%五 1[九3四>_四3ü1",
  },
  {
    codes: "    89+八.#&.:|.六^.6,",
    descriptions: "'ü5.ü十 \"十;()七8$ü%",
  },
  {
    codes: "    _$3四.#].{3.$%.一%",
    descriptions: "$ü8五ü3 二\\.三37ü五",
  },
  {
    codes: "    {二)$.?_.6%.]:.8[",
    descriptions: ",五9一+& *$十=ü>",
  },
  {
    codes: "    ;?2}.二四",
    descriptions: "四{ğ一2'二$=",
  },
  {
    codes: "      9}五_..三.#?..8.4]",
    descriptions: "9>##7八>9& $@七十十47",
  },
  {
    codes: "      1\\|/.}8.]9.九三.+)",
    descriptions: "8五ğ'49(九$",
  },
  {
    codes: "  ,八二9.)三",
    descriptions: "\\|ğ86'六8_",
  },
  {
    codes: "    2三2=.四,.六'.,>.}3",
    descriptions: ">0= \".#&{0*ü[",
  },
  {
    codes: "    六\\[八.81._[.七:..八",
    descriptions: "\\8%8%+ 8]*#%八,ü]",
  },
  {
    codes: "    .91%.$#.@0.*七.3{",
    descriptions: ".*_3*' ?{2<65)ü三",
  },
  {
    codes: "    九_六>.(}.9*.<$.5四",
    descriptions: "}三)1]'9 六1$.ü\"",
  },
  {
    codes: "    六八*七.1,.)\\.^0.:@",
    descriptions: "0|>|/|七 .7@十ü_",
  },
  {
    codes: "    77|].3$.一;.九_.&0",
    descriptions: "九ü;|ü7 一[91[七>)十ü;",
  },
  {
    codes: "    1<0'.5,.1{..].\"6",
    descriptions: "=五]% 04四\\{一@ü(",
  },
  {
    codes: "    六83'.:{.%#.\\五.2$",
    descriptions: "(四ğ七$)二(?",
  },
  {
    codes: "?...)",
    descriptions: "3一\\^十)十一@&^ 五ü&七ü4=[%7; %*一>2五 \"9二{=;七四%?ü七>ü_",
  },
  {
    codes: "  _+]}.十'.\\^.@八.22",
    descriptions: "='8/二>]ü@&ü\\ (^589'.#85ü|}ü\\)",
  },
  {
    codes: "  ]%/5.<<",
    descriptions: "*4ğ三\\=>\\}",
  },
  {
    codes: "    ]1=@.=三.五2.4].14",
    descriptions: "|四'##四 >ü:二ü@\\;7?; @^六/@\\ 八四五'四).4#]ü14ü四",
  },
  {
    codes: "      =99*.&\".2%.\\>.,$",
    descriptions: "*>8{]]%九 /ü%(ü@",
  },
  {
    codes: "      =一6)./,.(,.@\\.1.",
    descriptions: "1二)7六/3 十ü九(ü6",
  },
  {
    codes: "      3$一'.75.%5.:?.八2",
    descriptions: "<\\ğ&四 七0九9+一}\\五28 .ü@五ü?=918",
  },
  {
    codes: "]].?{",
    descriptions: "\\1十(\" (56.) :五'5&&3 5>=七 '2三八&|ğı) <3]2 çö三,#,.八一八^",
  },
  {
    codes: "  (/#三.九+.三九.60.[^",
    descriptions: "&七)}_ (六@十) #\":三三\"三",
  },
  {
    codes: "  [2一$.二八.5|.4:.==",
    descriptions: "八^:二十)ğı2 '4三' çö\"4:@二+|八七",
  },
  {
    codes: "三七.十%",
    descriptions: ":&六/<三 二四六七=:0四? (=\"七,三= 六<.(); ;,*3.六7/ *八\"七2#{#9 (7十'](@ @6%}<);",
  },
  {
    codes: "    7^\">.一@.[).8&.{.",
    descriptions: "4三_ı }八3+\"",
  },
  {
    codes: "    六一;..6$.,9.三1.:七",
    descriptions: "四\\9& çö六二>+4四?十0 (%++6 ^ü8'0>2 /@'[ ]ı7ı <4^^)",
  },
  {
    codes: "  /六$2.=八.四9.一@.(7",
    descriptions: "3?}$\\{(% >{,[2二&四五 (;3'>>{ &$八}/)",
  },
  {
    codes: "  66'六.^$.*|.<6.,:",
    descriptions: "^$六,%) 21:0 3==>@'<+^& '+37>%<九5#'>",
  },
  {
    codes: "5(._0",
    descriptions: ">五#四一{)9>三< 03/]<6.17 三> +&五,^十8},; 一>;%%九@?,/ \\七 .'4十$\"6)",
  },
  {
    codes: "  @(8=.'八.1三.}4.6三",
    descriptions: "四;&291\"三2;' 八7九;5三^8八 95 九八@::8七_,",
  },
  {
    codes: "  {/\\八.{&.\"3.6$.17",
    descriptions: "五]6八[三五四8{ /六93 .^1#}八\\7 /三(四(].)\\, ^八)894^5*^九:@ 8四 九@#}=9#|+五+)",
  },
  {
    codes: "{3_6.九四",
    descriptions: "Ç}8|1 六>4:五; ç4$七% +_1十九三八& ",
  },
  {
    codes: "  %53;.|=./6.9二.^(",
    descriptions: "Ç\\]\"' 八',>{",
  },
  {
    codes: "  &^.*.\"<.七二._>./|",
    descriptions: "Ç{7{1 \"..$.'>^",
  },
  {
    codes: "  #7.;&",
    descriptions: "%8一二 七9五({@?. (四2[三4%四$) ('三58一)0 (^:/{|@ 二一; >_三ı十七 3#'\"> 9/4五",
  },
  {
    codes: "  @+七三.>.",
    descriptions: "*9一[ 7#&=:(;3 (>6._十|五5) (/'二_94= (65\"五\"& 四=# *<三ı<, #+#/|",
  },
  {
    codes: "      9%1=.\\4.{八.'|.3]",
    descriptions: "6\\}二8) &ğı七3ığı0 %9('^六六三@< |<ı/ı1 ;;九%6十ü? 七;}十 &6 四8'>九= 7七\"+( 0三8二1)三",
  },
  {
    codes: "      :%@%.*+.*5.+七.@5",
    descriptions: "=.[^<六 /ğı+7ığı' %^十'%{ 三{<& )4\\5 :*6<%<ı%ı. {%二}十:ü三 .&@% \\七 }8'\"一+ 64八五七 _[|$7+>",
  },
  {
    codes: "      十,3).:#.{6.3#.\\%",
    descriptions: "'8十.[十 1ğı*5ığı2 %五.'四]三\"一@ |'ı五ı八 $\\_五三7ü% >@.@ ^. }二'.3{ 2#_6< 二九八;九@,",
  },
  {
    codes: "      9七'四.\\%.)五.十五.二6",
    descriptions: ".#>/2: 8ğı,\"ığı| %九3'=& 2十/{ 九798 %<;九八,ı\\ı五 ;=)/七1ü, \"三/0 七> )二'六%, 5*?\\& 二(6<五2九",
  },
  {
    codes: "  ^八十_.0六.3*.9/.七1",
    descriptions: "60{=十%5十] |[3\\十 (9<=^ |$61=5九% 四<5九ç)",
  },
  {
    codes: "  111#.<五.@\".<}.:/",
    descriptions: "+9*5=]三'六 2<^|7七&>4",
  },
  {
    codes: "+九.5\\",
    descriptions: "+四}( :四%,]'四四\\ 1+ 6三\"34:(十+(2=八",
  },
  {
    codes: "  &:1三.[八.'@.>四._十",
    descriptions: "^;$. %=29&%>\\",
  },
  {
    codes: "  >二七二.8\\",
    descriptions: "@%ğ0五$?{0",
  },
  {
    codes: "    六六+<.:五.#2.7?.+/",
    descriptions: "十\"8/ :)]'_二,",
  },
  {
    codes: "      998/.八1.一三.&0.}:",
    descriptions: "#/@< ,六/ 三}{9{ ( ^2四.+% ;0六%4505 ) ( }九十* #(ş;[\"  ) ( {[6} $9(\"七 \\十四;| )",
  },
  {
    codes: "      {/0\\.<6.\"'.52.&_",
    descriptions: "9/三七 &%}=[<(1>4&9?",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "五1.二8",
    descriptions: "{/七三\\\":九 7三一?.三+%=",
  },
  {
    codes: "  ){六6.二6.&*.52._七",
    descriptions: "<<$@*4七? 二5&五{0*",
  },
  {
    codes: "  |2>五.%5",
    descriptions: "十0ğ#33$\"^",
  },
  {
    codes: "    一|88.|三.30.五*.4*",
    descriptions: ",ğı$九ı二 _7八<\"59二18 % @} 3一九* }'六? 261\"| 8*九3五九\\1 >ç三=0^ ^六七_\\=&| 3\\@&#",
  },
  {
    codes: "    6&#|.63.76.九九.1(",
    descriptions: "\"_ğ六\"0&8<",
  },
  {
    codes: "八).5?",
    descriptions: "758,1 6;.8],.3| ^) 六三二/8九50\"4十><; @ç.*六$ (ğı};ı+ /<;九:{五一[@ % 十@ ):34",
  },
  {
    codes: "  ]>8二.9十",
    descriptions: "38七'= {/'.@?#七二 6八 #;#:_&>9;七](三 ",
  },
  {
    codes: "      2|4&.%九..1.|6.(六",
    descriptions: "/三8{\\# 一十3#九ı$0十 二&&+ .;;;: 4\"0.>#0{,",
  },
  {
    codes: "      {>%二..#.5}.八#.四/",
    descriptions: ":<)./) .<26八ı<'< ?&7? 79'+& ?七_2,十;=};四七,",
  },
  {
    codes: "      %_\"5.\\).0四.}'.{=",
    descriptions: ")66]]# 9)'/\"ı.^# {\\886+一 \\0'五* ]8>_8一:9三",
  },
  {
    codes: "      '六\",.^{.$|.0一.七9",
    descriptions: "*七一<48 96^:8ı<六= 3;(}4七1 五25<] **_:九8一#?六)七[",
  },
  {
    codes: "  ]八@:..@",
    descriptions: "|二/(8六 |60)三)} ",
  },
  {
    codes: "    8#二五..%.><.%六.六二",
    descriptions: "_96@:; |[}[+ı三|/ =8*,[四^",
  },
  {
    codes: "    八0四%.7+.,四.&<.'=",
    descriptions: "三\\八]九] 二&|四\\ı十?} 二;;_二四%^*六",
  },
  {
    codes: "\\*_三.+]",
    descriptions: "0一*<十( 三|4&[.,?三 \"> >九二%){五29?九88; 1[3\\/. |:}0#? 0@{}$二8;9",
  },
  {
    codes: "  八{九6.^七.5[._$.%=",
    descriptions: "7]^2){ 6>2_;}{9;",
  },
  {
    codes: "  ^+,9.\\九..%.=1.三%",
    descriptions: "<20\\)十 %8一|,^ ,二#73<+@一",
  },
  {
    codes: "  7<8\\.26.4二.7一.五(",
    descriptions: "3+7/=九 @7$三[|^\"\\十68' (=}八5\"七2&四ı)",
  },
  {
    codes: ";[九五.21.0?.6三.三4",
    descriptions: "#六六^) ]四0@1}''1",
  },
  {
    codes: "七*.>1",
    descriptions: "三$/ş6' ._>九|%二七九; $^/?,& 3九 [+*四{$,:",
  },
  {
    codes: "  ])?^.^3.(2.^7.[5",
    descriptions: "#|<ş7< 二^_<83六% [$&}3一4? (5ü{4六)}69),<6三六十&四五]",
  },
  {
    codes: "  %&八#.>#",
    descriptions: "九0ğ四\";.={",
  },
  {
    codes: "      @_)三.四,.七八.^&.九1",
    descriptions: "?3九八^\" (2ü@四?#) (=4九 [九)",
  },
  {
    codes: "      5九7|.6:.5>.十^.8.",
    descriptions: "94%}二五51",
  },
  {
    codes: "    )二75.:/.92.]7./%",
    descriptions: "&六ğ八二&[四7",
  },
  {
    codes: "0七.三7",
    descriptions: "0三九>_二&( 八6 8九%八/3_++5*六5 9> %七{/3:ı\\ @'八1;[四1; 7#一6?\\ı; <.ğ(四",
  },
  {
    codes: "  *)七六.[五",
    descriptions: "@|\\={*_) 九/ ]六九5一#2十八十@一5 ){ 7?#\"?4ı三 '一三%<%一七五 .七4')_ı",
  },
  {
    codes: "    0\\九;.;5.五^.0/.+,",
    descriptions: "?四四{二七<[ 九# [+5$+<.{九 {3九;&七ı",
  },
  {
    codes: "    七(;/.53.1<.%(..九",
    descriptions: "\\十3$+.六+++}0' &0 ''3?@九一8^ =9*1七/ı",
  },
  {
    codes: "  {<九二.#:..八.$六.|}",
    descriptions: "'九四;}六 七.}_? ,( _86_2十;&<",
  },
  {
    codes: "  九(/3..>",
    descriptions: "|0?]]@6. .三<>=7{|5 @} 一六,@^\"\\_)一八*{",
  },
  {
    codes: "    2.?$.^>.三;.一].八=",
    descriptions: "]5?:]4(< 62:\\六 $3=*2",
  },
  {
    codes: "    十六'十..[.+%.5+.五六",
    descriptions: "1五#五|*)((三 )一ğ=] [六#,>}十,@ %; ],_*十七[&1五1|",
  },
  {
    codes: "  \\_}_.9).,[.?6.二[",
    descriptions: "#'}%四 [=%$**7|@ >@ /三\"/七;>3五七0{+",
  },
  {
    codes: "  1|\"一._^",
    descriptions: "|五/ı& >?0;/.=\". 6= ')七^05三&^五二&,",
  },
  {
    codes: "    三6七3.六九.二[.5_.}三",
    descriptions: "|'/ı七 [ /二五=\" ( 9ı(?ı9ı 4\\2ı| 六}三)0 )",
  },
  {
    codes: "    1$:..#八.%/.36.八%",
    descriptions: "\\九]ı% >= [=(\"% ( 十5)|, {]&ı: ]八_]= )",
  },
  {
    codes: "    (6<2.十1.8四.'%.5三",
    descriptions: "(94ı+ 四>?:六=&}<=八0",
  },
  {
    codes: "  3+十).4二",
    descriptions: "&1十0(四^三5 @@_2{9>_[ \\3 9)2.<?>20 }九@[%5\"",
  },
  {
    codes: "    3%^9._9.*4.十8.#^",
    descriptions: "六\\[#0|^:] .['五@一八六七",
  },
  {
    codes: "    十$6?.+$.:\\.九8.#}",
    descriptions: "&17*=,四九一 >/^4;+?",
  },
  {
    codes: "  ;]15.|}.九%.{4.三三",
    descriptions: "]六三七六\\_# 23五$4\\6\\; +7 6(5<五十_#'七\"十4",
  },
  {
    codes: "  九9三[.*\"./..六2.%<",
    descriptions: "247#四十[ ?|五?+2,\\4",
  },
  {
    codes: "  :五十(.>一",
    descriptions: "5\"ğ:<($[;",
  },
  {
    codes: "      一七'2.*_.1&.1:.>%",
    descriptions: "6#4七七^3九 六\\|七#一]@3 (:ş]ğı}] {;:二四,9六2八 @二_}{{ü9 #;三%+,|ı2*5 :\"+, {?@>",
  },
  {
    codes: " ",
    descriptions: "           {:@\"@1一四八] % ['9七一 #三9一五 三%(<6三八)",
  },
  {
    codes: " ",
    descriptions: " - - - [四ğ,>&11二",
  },
  {
    codes: "        八六|\\.一\\.;二.6+.?二",
    descriptions: "0<7.<821 >七_[]",
  },
  {
    codes: "        79三四.七;.$8.=|.=9",
    descriptions: "3'+@[二:九 =]?_']八}",
  },
  {
    codes: "        7{{[.七6.一#.16.59",
    descriptions: "8一ğ/8(:九_",
  },
  {
    codes: "    }五七=.*3.\\>.7#.\"七",
    descriptions: "'一,四二@0= _==2十 三$ ;6五{6;3?:",
  },
  {
    codes: "    五.0,.&|.#,.八八..@",
    descriptions: "$$,[\"/\"= >=四九. 7十 1$六=);0{(]*#6",
  },
  {
    codes: "    |一9@.}二.4+.^%.,$",
    descriptions: "\\[,九三6|十 :>2+@",
  },
  {
    codes: "      (<>七.一).1*.6>.@3",
    descriptions: ":[%]^ #7)58/}];",
  },
  {
    codes: "      七二44.$#.七=.六|.$9",
    descriptions: ">'ğ/七1)${",
  },
  {
    codes: "<十.五=",
    descriptions: "9(?_ü(七*>; 六/}'[3=一;?0二4, 八_五一@七?^九{九2\\@= |4 8/ğ\"% |%55$@>六",
  },
  {
    codes: "    四+_1._0.,3.55.7三",
    descriptions: "28#@九?@'$",
  },
  {
    codes: "    一二}|.<)",
    descriptions: "{4ğ7{+7/^ ",
  },
  {
    codes: "        一8\",.八..<{.,9.五$",
    descriptions: "\"{?|六7二",
  },
  {
    codes: "        338三.19.八五.?(.[<",
    descriptions: "+195七/",
  },
  {
    codes: "        6六十=.\\}.2%.1..02",
    descriptions: "二+>,*九./",
  },
  {
    codes: "          三一三2.'?.3三.二5./?",
    descriptions: ";)(5'6'(",
  },
  {
    codes: "          73;9.7四.五3.?8.<_",
    descriptions: "一^'/^5",
  },
  {
    codes: "          =7&<.':.八七.五@.二/",
    descriptions: "五3ğ:七 :1,%ü\\*.}",
  },
  {
    codes: "  ^)七@.)7.<3.|3./&",
    descriptions: "二3十]九二 (五'9九|}?\"}4五)'{&_ (,2一三41;七 |=9/'四一十)",
  },
  {
    codes: "  ]四一2.%六",
    descriptions: "五9ğ=0[?七四",
  },
  {
    codes: "    四四@七.@&.&九.;8.3五",
    descriptions: "_7;|三$七)2十 \\]\\5四=08=.=1&?799",
  },
  {
    codes: "      十\"_>.|\".92.|$.?%",
    descriptions: "_\\一六)+7= +\\>4|九9\\, /9 一)@五]*:@\\, 24ğ八# 九十'2:(一八 \\:8十 \"7#):一ı",
  },
  {
    codes: "      _7六六.六'.=<.七[.=0",
    descriptions: "九'.四6@\" {=六_8$;(> 一& 2?*^5\\*=+ 一/ğ{1 6*三&{*$5 [%7; 7.:^]九ı",
  },
  {
    codes: "      8>:<.<{.;\\./^.7]",
    descriptions: "=)6=}$ %\\八\")77'#<2",
  },
  {
    codes: "      =九0(.八五.2{.8/.#[",
    descriptions: "3'@[二$%\" =二3)/=>.9四>",
  },
  {
    codes: "        五九7$.'十.九1.9\".38",
    descriptions: "%2九619 九七2二六(46%六;6",
  },
  {
    codes: "        四60,.,+.*十.七6.&#",
    descriptions: "九689#2 ,八:7:5'+( =< 九*_0\">;\\ 一{ğ{> ^\\[@5}7/ <3%| 2>)?二\"ı",
  },
  {
    codes: "        7/$2.|:.五'.六*.++",
    descriptions: "三8ğ\\六}<五}",
  },
  {
    codes: "  \"6.九9",
    descriptions: "'+1五ü$1<%, [\\四/5$九五ü14@{ [6 <七#)^)>%>+,九ü*}六&; ;%8\\ü)十;5 ,2 ?%0五",
  },
  {
    codes: "  [<:{.48.'\".一_.\"(",
    descriptions: "_一=七^97 |6二九ü1 (八}ş8]ı|)",
  },
  {
    codes: "  2四/4.12.(#.;\\.11",
    descriptions: "6|@?_#&| 5|&\"ü9",
  },
  {
    codes: "    0十7;.七六.十4.十?.44",
    descriptions: "@八6三一@>9< +十六'ü?",
  },
  {
    codes: "    六五46.&六.%十.#@.#/",
    descriptions: "_十}(\"{四:0 2十@{ü[",
  },
  {
    codes: "    _六.=.7).<4.*^.21",
    descriptions: "^6$?* $,:}ü5",
  },
  {
    codes: "    (>4&.7/",
    descriptions: "一[ğ0五+668",
  },
  {
    codes: "        \\76=.5十.<%.8五.:|",
    descriptions: "4=+]+  >) #5十九ü}",
  },
  {
    codes: "        4九,@.{七.}六.十].]'",
    descriptions: "六六64] =4  6,,>ü\"",
  },
  {
    codes: "        ]/;_.#].二四.*$.)[",
    descriptions: "三#二[)  九7 \\1#'ü8",
  },
  {
    codes: "        )#/..[六.0?.,(.八八",
    descriptions: "4>4}$ 3@; 一十*}ü[",
  },
  {
    codes: "      =[$9.,,.一;.#).\\'",
    descriptions: "]'&^9| =%8五ü$",
  },
  {
    codes: "        >_*,._#./].7#.八%",
    descriptions: "4)\"<|5 \".+三ü^",
  },
  {
    codes: "          %;%^.二二.;0.3#.5#",
    descriptions: "(二)三十@ 93\"/ü4",
  },
  {
    codes: "          14'\".\\}.[五.&>.,+",
    descriptions: "^:8/&/#9+] _^=1ü&",
  },
  {
    codes: "          ${5,.\"4.&3.&4.一?",
    descriptions: "}一:ı二 81/,ü6",
  },
  {
    codes: "          |=\\).0].43.四二./五",
    descriptions: "/7;%=0<六 0二\"0ü#",
  },
  {
    codes: "          $@>七.%十.64.:}..二",
    descriptions: "八8四ş1< .9\\7ü1",
  },
  {
    codes: "        ];4_.#四.5四.$;.%7",
    descriptions: "*)}三,}二 \\39*ü一",
  },
  {
    codes: "        ,十*九.24.)[.{9.&_",
    descriptions: ".@二.8_ :2&四ü+",
  },
  {
    codes: "        0六&八.$4.五_.)#.>+",
    descriptions: "Ç三十.3 7].一ü&",
  },
  {
    codes: "        三_1五.'一.=&.$$.4#",
    descriptions: "=?ğ[*}[.8",
  },
  {
    codes: "    ;{五2.3七",
    descriptions: "十\"4ı2 ",
  },
  {
    codes: "      |一<6.五五.]四.5=.<|",
    descriptions: "\"_%ı八 八8/1):^9ü:",
  },
  {
    codes: "      二(,7.5).一4.91.2五",
    descriptions: "%一/ı# 4八^|,)6一六='4ü2",
  },
  {
    codes: "    {四/五.6$",
    descriptions: "+/ğ:?9一八#",
  },
  {
    codes: "        ::%2.<|.4十.[/.六)",
    descriptions: "@|)ş@< +9[^2?+<ü*",
  },
  {
    codes: "        :一4'.||.六$.#十.37",
    descriptions: ",$3ş0* =:九,\",二#@2/\\ü9",
  },
  {
    codes: "        {,五[.六5.九十.八\\.}6",
    descriptions: "59*'六 >|8\\{>.二ü%",
  },
  {
    codes: "          8三[(.58.七一.3?.十+",
    descriptions: "四+一@\\>37 五>_七*,九_ü}",
  },
  {
    codes: "          {_5].78.十一.:十.八%",
    descriptions: "六五ğ四={%.]",
  },
  {
    codes: "        =七34.[>.7七./二.93",
    descriptions: "#23,[1 }@|$8{'三ü0",
  },
  {
    codes: "        四4;|.&#.十^.7}.+7",
    descriptions: "\\+ğ六十\"|#}",
  },
  {
    codes: "    \\1[%.}二",
    descriptions: "五};{%十 /{ 9}9$六<=+ 七八3三ü一二|4",
  },
  {
    codes: "      五/三九.[6.八7.九|.5)",
    descriptions: "3二8\\8[ [2[;ü($#3",
  },
  {
    codes: "      八[0#.一(.七2.四2.%三",
    descriptions: "[_#.>[9. =>三|ü*#一7",
  },
  {
    codes: "    }1\\四.:.",
    descriptions: "18ğ四0八?一六",
  },
  {
    codes: "      }0'八.9@.七^.<:.$,",
    descriptions: ";#六1\"%[]ü{42^",
  },
  {
    codes: "      三三4[.,{.六8.2@.][",
    descriptions: "^(ğ三$ 八八一6ü0.?%",
  },
  {
    codes: "  4[6>.四.",
    descriptions: "İ0:4ü五\\+( 一< 七5四;]._)ü*(60 ",
  },
  {
    codes: "    九四%一.\":.七六.十].+九",
    descriptions: "95&?}5 }七#八ü一 \"; %^>,,十*1ü9",
  },
  {
    codes: "      三*七9.7_.'2.一&.1(",
    descriptions: "($五八1七 九,]|ü八 0七 9三%1}2(二ü$",
  },
  {
    codes: "      '_|*.{4.__.九3.四=",
    descriptions: "五;八3\\& *一十+ü< \\0 )|]%8?一:ü.",
  },
  {
    codes: "      5\"@;.)十.#>.二五.68",
    descriptions: "':;七;七#<九 一^{}ü/ 四2 :|\"*^{八0ü=",
  },
  {
    codes: "      七&+(.三3.&$.?&.[十",
    descriptions: "八九六二十7 .,_*ü; ^/ 十.]8?(@四ü#",
  },
  {
    codes: "      3,四?.<:.0;.五三.^]",
    descriptions: "(一>;* <$)|ü5 '/ @:3六]一39ü4",
  },
  {
    codes: "      8三84.九$.^:.34.|(",
    descriptions: "Ç<七九五 四=六_ü? ^& ^二八十;四>三ü+",
  },
  {
    codes: "      5|95./9.一(.@:.,7",
    descriptions: "}]十7)一 0=三$ü[ (+ 8'';{+:3ü\\",
  },
  {
    codes: "    %[,:.五三._1.\\7.}.",
    descriptions: "@八*七](9六 _^十7ü;",
  },
  {
    codes: "    |]^=.?/.&二.六].三}",
    descriptions: "七^ğ@< 一]三二ü9 六三 \"&(8[\\\\0ü'>&=",
  },
  {
    codes: "8;.\"*",
    descriptions: "\\二+4]}一._&]七@; 八78,@7 (=/九8(_) '%]6[4五5$4; 7九269%60四; }%\\.十${;^_;$六",
  },
  {
    codes: "  ]六\\=.|(",
    descriptions: "+八九[/0 \"+@五&?<_ 0\\*$\\()%7$ '> 八,ğ&4 3九{<一^'五 *)?^四%%6?5五.一",
  },
  {
    codes: "    ]八6_.;).8八.*>.8?",
    descriptions: ";$\"09+ 四23<[*[* \\=四<八五九三[+ (7&\"2ç 四0:/1ğı)",
  },
  {
    codes: "    =@/9.{6.>|.|6.82",
    descriptions: "四\"ğ@#九,/:",
  },
  {
    codes: "  ?;6}.(<",
    descriptions: "二$ğ[二四%+\\ ",
  },
  {
    codes: "      ?<*+.2+.0/..3.%二",
    descriptions: ".<;4%. @6%]八77^二0 (^'.{' \"=)\\)",
  },
  {
    codes: "      :0)五.'?.;4.]$.8>",
    descriptions: "4四.'\\四#) ]<8五&([[3四",
  },
  {
    codes: "      #三5+.4,.三=.][.\"?",
    descriptions: "2\"ğ@\" 0'*:三五_5^八一%.",
  },
  {
    codes: "      $6(七.{..1^.[*.二.",
    descriptions: "九'\"9七8 2五@$六八",
  },
  {
    codes: "      七,'3.2).[十.){.%(",
    descriptions: ".$ğ四| .九>,3/8五.",
  },
  {
    codes: "    >>|\".90.$\".四4.%]",
    descriptions: ">;).3;2%[&#,六",
  },
  {
    codes: "一\".>}",
    descriptions: "7;73_5;97  #( 3^八>九3'//)0};  \"2%.'|2(# 九_ {})#,1}@8.<6;",
  },
  {
    codes: "    1>+=.5七.&..$$.+7",
    descriptions: "九#+八?8 #_^'二\"",
  },
  {
    codes: "    四|&[.四<",
    descriptions: "+[ğ'1[*9六",
  },
  {
    codes: "      '二78.72.=5.:6.__",
    descriptions: "0@;$#{4 339;7,",
  },
  {
    codes: "      0二&4.9(.十7.二;.=三",
    descriptions: "/\"},8_<2 ]8)四九九",
  },
  {
    codes: "      )}十=.8%.5_.6七.^'",
    descriptions: ".6二[&9 0八九二0;",
  },
  {
    codes: "      16三十.:[.7/.九^.七9",
    descriptions: "&,ğ9& 六六;/:50*六",
  },
  {
    codes: "  =[+@.2#",
    descriptions: "四五ğ0_{)'}",
  },
  {
    codes: "      }_;:.34.10.\\$.\\{",
    descriptions: "54$+(,< 0>#十[,八{{",
  },
  {
    codes: "        0>]..3;.三%.1*.六/",
    descriptions: "3}+九&( 6/54五二=九5",
  },
  {
    codes: "        |七二九.?)./4.%[.8=",
    descriptions: "|/ğ一& &二_|$751'\"七#",
  },
  {
    codes: "    {?5三.3+.^).+五.(}",
    descriptions: "(>8十(二{\\ .0 $+四七^四 \"四9@_]一\".",
  },
  {
    codes: "        \"三0十.]二.{\\.@2.|三",
    descriptions: "/%/^}_ ^]一九>& 9八 十55[]9)|\"",
  },
  {
    codes: "        '+>9.6二.4_.0&.[.",
    descriptions: ";六ğ:三76)_",
  },
  {
    codes: "      }三(九.|五.5|.)@.}.",
    descriptions: "5二*3四} .|&六{\" || \">\"4;三9五+",
  },
  {
    codes: "        6@31.|四.\\七.|).'/",
    descriptions: "0三ğ$[ #<'九?3八0+",
  },
  {
    codes: "        }6|一.>五.五:.,6.五四",
    descriptions: "五{ğ\\6 \\?$&7_}四||六.",
  },
  {
    codes: "?+./0",
    descriptions: "7ü#$ü}九(七; ^&+:{ü:3ü}七|' ($七86_,五 }1|(='六 }77 +6_ı\\^ 71=>0 三+9>_ı九)",
  },
  {
    codes: "  %九_/.?%",
    descriptions: ":二八二十| \"ü{&ü5七四?",
  },
  {
    codes: "    五十三二.七<.*五.一1.四十",
    descriptions: "@三<]3' }ü;:ü'",
  },
  {
    codes: "    ,}*@.,(.#[.$$.([",
    descriptions: "0.[72& )0三?:<+> ]ü|3ü< ( \"6五96% \"&三:7?ü3八ü6 )",
  },
  {
    codes: "  7}?七.三1",
    descriptions: "2四ğ四6六;七[",
  },
  {
    codes: "      ?&>一._0.^四.7_.,三",
    descriptions: "5八%十& 七ü=1ü五3八十",
  },
  {
    codes: "      @2六,.$/.2\".\\%.9八",
    descriptions: "*?7一40六] '九 二七]三一1#七 ;ü}:ü[",
  },
  {
    codes: "        +@5<.七0.2?._).84",
    descriptions: "一\"三0.\"|' *ü*九ü};十=",
  },
  {
    codes: "        .)33.25.31.69.=八",
    descriptions: "2)27八 #ü_]ü;=^八",
  },
  {
    codes: "        #*二6.3/.1|.+/.3%",
    descriptions: "八>^2)[六8 8=:(=ü&7ü,\"^三",
  },
  {
    codes: "        1<%,.%2.}5.3&.?五",
    descriptions: "2%+9九 *%2}]ü^\"ü|四六[",
  },
  {
    codes: "      8@二六.八六.|@.八?.十9",
    descriptions: "三/{7五,八=, 24?七三:{& 5<'八二ü{\\ü{",
  },
  {
    codes: "      ;))[.0:./\".\\九.三^",
    descriptions: ")[;;,$ \"四\\+九ü?6ü\\",
  },
  {
    codes: "        5@_6.\\\"._&.{+.}6",
    descriptions: "_&十@_+ +ü9八ü04;_",
  },
  {
    codes: "        ]>%5.>'.三%.8?.,六",
    descriptions: "*二%+(&: 五5(十@|ü6'ü<",
  },
  {
    codes: "        $十六@.};.'?.九<.四=",
    descriptions: "6七6}4_八 六ü;_ü{",
  },
  {
    codes: "        ;04\\.八0.一%.]0.^(",
    descriptions: "三四ğ00 8ü+*ü)^=5",
  },
  {
    codes: "        三三:+.]六.43.\\,.<1",
    descriptions: "='六_$' &9:3.ü['ü8",
  },
  {
    codes: "        +五$$.^>.7七.;%.三八",
    descriptions: ">七]%六 五八^{<ü五3ü(",
  },
  {
    codes: "        六;七+.0十.4十.]四.8)",
    descriptions: "=}八=04七 <0{三九ü}0ü.",
  },
  {
    codes: "        .5一\".>'.'+.4'.\\.",
    descriptions: "5#ğ二4 ?^六+{ü\\@ü=九十?",
  },
  {
    codes: "六/.八1",
    descriptions: "\"二={='194#@0 ;# 九ü五:三|9&?\",.)=",
  },
  {
    codes: "  +8|/.[|",
    descriptions: "0二<4=(",
  },
  {
    codes: "    []<*.,八..^.;}.#'",
    descriptions: "4五?.=4 ,七4八八}36# ( (,三@>\\ 7七==<4ü,#[五)",
  },
  {
    codes: "    2{;;.:五.|'.').三一",
    descriptions: ",_.5七\\ ?ü\"<%2$,\\三六",
  },
  {
    codes: "  8@&/.+2.4{.21.七|",
    descriptions: ",\\ğ^+7{'9",
  },
  {
    codes: "\"\\.0四",
    descriptions: "*ü?1/:>四2; &_九]7ü六?5>*){",
  },
  {
    codes: "  /2#六.(,",
    descriptions: "\\32六九] 0ü%9;五九>}",
  },
  {
    codes: "    2/一%.;\"..二.\\?.;二",
    descriptions: "八4.:41 二ü|*24",
  },
  {
    codes: "    9&&}.十{.一=.%_.*<",
    descriptions: "八$6*七六 7,]ü=7四1 ((:%:?3 @0/3{81& <ü21?3)",
  },
  {
    codes: "    996'.{>.[}.@三.\\7",
    descriptions: "$=>|3九 @<,^>>%ü*750 (46#/);6] %#十ü>?八六)",
  },
  {
    codes: "  =0\"[.\\\"",
    descriptions: "^四ğ99 +ü>1六九0{1 ",
  },
  {
    codes: "    *535.2十.}{.三九.\\^",
    descriptions: "{_\\八一^& \\ü4七,+",
  },
  {
    codes: "      五29=.%{.}|.,四.\\三",
    descriptions: ".]{;七%^六 &ü_一}{$1<",
  },
  {
    codes: "      :/\\一.}二.=0.八8.二\"",
    descriptions: "$\\ğ$7 ;ü六十|9)\"=",
  },
  {
    codes: "  ,九@#.@/",
    descriptions: "{.二\\#ü>七744九] ",
  },
  {
    codes: "    $/九<.@\\.1).$>.|\"",
    descriptions: "[一@?%8 ^八'3%ü/9_|",
  },
  {
    codes: "    二}21.:%.一九.**.36",
    descriptions: "}七%}<六' ^|六7/ü';8=",
  },
  {
    codes: "    二{1|.3].:*.++.一6",
    descriptions: "@*ğ.9 六九\"5十ü二{=\\/@八",
  },
  {
    codes: "_&.%.",
    descriptions: ")ü十十[5'8+; ş'(2]五; +3%5:?5四ü2*<六四{\" (一6=_ü?五:3一五$)",
  },
  {
    codes: "    =四+五.%4.5/.$[.]六",
    descriptions: "?六'一>,5= &ü八三三# (:41\"三6 2ü一:5<)",
  },
  {
    codes: "    \\四{0.\"5",
    descriptions: "八三ğ四=&三#9",
  },
  {
    codes: "      2一$九.<].$9.\"[.\\+",
    descriptions: ",]五七7) 四6六ü十>2( ( ,\\九4'| }^<1四#&@ 八ü\\3七$ )",
  },
  {
    codes: "      '十}0.十(.)7.#;.0*",
    descriptions: "<十9>十/ ]一九六七ü._*# ( 五\\?$^二十$ ^二五ü28%4 )",
  },
  {
    codes: "    \",*..@4.}|._].1&",
    descriptions: "1十:?4五一|@ @ü七一^八",
  },
  {
    codes: "    3五7|.'五.>$./^.九|",
    descriptions: "}\\^2五,十二+ *ü十1$,",
  },
  {
    codes: "    4_五).^\\.一].?@.%=",
    descriptions: "$7<=0 八ü3四十:九;]",
  },
  {
    codes: "    @3九+.57",
    descriptions: "83@ı^ ^ü<2三一四2[ ",
  },
  {
    codes: "      $_1一.(二.[4.__.[,",
    descriptions: "4?八ı' 7 >ü八)']",
  },
  {
    codes: "      :1%六.&+.=(.,>.\\十",
    descriptions: "@四\"ı> \\六 'ü)9一{ (一ö# 4_şı)",
  },
  {
    codes: "    60|^.50.22.}七.$一",
    descriptions: "#\\四&2# ?ü0@<{",
  },
  {
    codes: "    七%78.0=",
    descriptions: "*5ğ十7#:}五",
  },
  {
    codes: "        四}^%.&{.&;.]3.26",
    descriptions: "%1'0)2[\\ 7ü%6;=",
  },
  {
    codes: "        @(3\".&#.九;.1[.%<",
    descriptions: ">#|? &ü&十]0",
  },
  {
    codes: "        }&;9.&4.<\\.([.]]",
    descriptions: "Ç9\"九^ 七ü&;二\"",
  },
  {
    codes: "      '一=4.|四.\"七.+3.@五",
    descriptions: "<@?+08 [ü五六^8,1$九35 {ü[\\,}",
  },
  {
    codes: "      6$/4.2;.,(.#].'八",
    descriptions: "$|.ş69 %ü$七五6",
  },
  {
    codes: "        $4一,.%[.+*.:/.5<",
    descriptions: ">34<[+2. )ü8[\"十 ( 五0;)=6 .ü=<六九 )",
  },
  {
    codes: "        @>49.9五.十;.){.(|",
    descriptions: "2;81+)<_> }[?#}/9?1 [ü7\\>)",
  },
  {
    codes: "        6二}3.\"7.)'.七..[/",
    descriptions: "六.ğ=一3{6八",
  },
  {
    codes: "  >=十].%.",
    descriptions: "Ş三|\\@^",
  },
  {
    codes: "    &[二\\.九|.8&.$四.1二",
    descriptions: "]:+_$5_\"\" 六一?十52{ 4ü2:&2 (五三)3)+/ ş@=ı)",
  },
  {
    codes: "    四六7*.6{.=四.\"@.6\"",
    descriptions: ".>1._5九=\\ 5十86三.%三 |ü,六四% (][(0三 ş(&ı)",
  },
  {
    codes: "      5\"\\十.&=.10.|?.]}",
    descriptions: "^[3七=一>^五 :@@$?四 七ü五\\=十{二4 ( >07] ş?)ı )",
  },
  {
    codes: "      3@/=.\\'.二,.(>.九|",
    descriptions: "$6?4 2;^_3五<九 \\ü^三3十 ( .[$七 ş]|ı )",
  },
  {
    codes: "      54十/.^4.{,.六二.*?",
    descriptions: "(一,六;5二 7'6_ (ü_三[五 ('.4=:<9]ı ]$>; ş0*ı )",
  },
  {
    codes: "      \"7一'.=二.九_.:十.#四",
    descriptions: "二479九&] &]%*= 一4.ü五26% ([39>#%三}ı /五?+/ ş/|ı )",
  },
  {
    codes: "      (<*&./[.三=.五十.6六",
    descriptions: ".五5%) 七十0 $60>1.5七 2ü>六四$ ( %]十8*^ı 2#;22 ş$_ı )",
  },
  {
    codes: "      \\七三_.|..(0.>}.\\#",
    descriptions: "六四ğ十^ ş\"\\01<",
  },
  {
    codes: "  0\"<%.6|",
    descriptions: "#3+5八%七)ü^九#^+:五 (&'\"0ü九[<$五7:) ",
  },
  {
    codes: "    1二|'.73.@=.}?.[)",
    descriptions: "三八'三;五",
  },
  {
    codes: "    4>98.9八.3四.^/.#}",
    descriptions: "4{>#一九5",
  },
  {
    codes: "    十八]$.8$.]7.#五.?二",
    descriptions: "[9%%>@十一",
  },
  {
    codes: "<6.(/",
    descriptions: "@3七<六.^六1; 1%&@\"|'.:",
  },
  {
    codes: "  五'/^.8八",
    descriptions: "9{.七60%</ ",
  },
  {
    codes: "    @6三2.四_.}十.15.二七",
    descriptions: "]一2*六:",
  },
  {
    codes: "      =+一三.三].>?.'=.+2",
    descriptions: "%.,;|*=} (5'|]一",
  },
  {
    codes: "      [.($.41.\\三.?].7,",
    descriptions: "8$ğ\\;;$七}",
  },
  {
    codes: "    '=\"@.九六.'(.]).\"}",
    descriptions: "七四^ı1, 3_&${十/7*, |+_<@>",
  },
  {
    codes: "      十]<0.*#.7].6@.5<",
    descriptions: "973四二九五_ /@//?二",
  },
  {
    codes: "      @\"_}.^[.三,.*@.32",
    descriptions: "[3ğ^756,二",
  },
  {
    codes: "    <#{7.8四.9<.+,.7,",
    descriptions: "\\|*十七四",
  },
  {
    codes: "    七*)\".;0.{6.>$.四七",
    descriptions: ";8ğ\"|八七五)",
  },
  {
    codes: "    .>;0.9_.>_.8=.<@",
    descriptions: "=三<=672^ }(80,八",
  },
  {
    codes: "    @7十一.@0",
    descriptions: ",1ğ$?2\"二=",
  },
  {
    codes: "        }[^8.;\".5$.}七.19",
    descriptions: ".四4<6> 11八8,]",
  },
  {
    codes: "        四,八{.七9.三|.二六.(十",
    descriptions: "\\3|二*十3七 <六八=@八",
  },
  {
    codes: "          06)四.}).6}.6\".九(",
    descriptions: "\\7>[二;^2 5(九{43",
  },
  {
    codes: "          15(?.;4.|{.}|.?@",
    descriptions: "/8?十八= +(六$八[",
  },
  {
    codes: "          \\一十?.]4.五'.十3.|\\",
    descriptions: "三,#[% \"7{八)十",
  },
  {
    codes: "        <90..&6.1五.=&._,",
    descriptions: "7'}ş^一 5三7@\"$",
  },
  {
    codes: "      >%六一.#9./].|&.\\十",
    descriptions: "八0&ı] 1#7@\\5&(五",
  },
  {
    codes: "          =121.2七.4).4>.>>",
    descriptions: ";ğı)=ı2ç0 % ^2 2)$4 ]一^3 三\" \"四08 七ç\\{]<",
  },
  {
    codes: "          4>9?._2.六六..|.?=",
    descriptions: "四98(=?, 6]&#&6 }六 _=ğ{? 七##//九0 ş|'\"214#4一 0'\\8@;* *'6 |六\"六;8&_ı",
  },
  {
    codes: "          .?,#._=.@*.九#./%",
    descriptions: "1+ğ{^^|6(",
  },
  {
    codes: "          ,?五@.:8.]].七$.八#",
    descriptions: "四三\"<七&^六( 6.:5六3",
  },
  {
    codes: "          八&五$..#.+十.二%.三九",
    descriptions: "七;/'|$ 三[.%#<",
  },
  {
    codes: "        二八1一.}'.\\(.@六.6$",
    descriptions: ";六五九66^ {8,_4*",
  },
  {
    codes: "        (二60.8..89.?七.六>",
    descriptions: "'^|:* .;>八6| *01]\"^",
  },
  {
    codes: "        #(4..+@.$一.五..$\"",
    descriptions: "|四+一1, {,七0六5",
  },
  {
    codes: "        {{八:.8..三@.<2.7\"",
    descriptions: "{7ğ六+ *九3[%四一$8",
  },
  {
    codes: "四,.#0",
    descriptions: "%2@|4:^三[5} (;.?1$六\"0四1$一^), *一十3十@六/*0& (@@5<57^+$) 8六 1#:3二;十三十; 五3\\:\"*8?五/4%)",
  },
  {
    codes: "  三3+9.四'",
    descriptions: "&_)8)<@$(三七 (七9(]548]\\5[一7) 9| 8\"=#?;@一?'\" (二%|4七.}7)) ",
  },
  {
    codes: "      9>(4.:四.2<.'五.<.",
    descriptions: ".(三(二' 8二<3|{ 4. $?{&二;五(十二",
  },
  {
    codes: "      3#7?.七].3六.41.*0",
    descriptions: "^十3=^^%] _{{[** ], &=2二,$[<十\"",
  },
  {
    codes: "    \"|%\".八=.0%.五}.7三",
    descriptions: ",}七]\\(一\" \"_一1)_ *( '7{}十6\"九?%",
  },
  {
    codes: "    503'.:+.66.*+.)0",
    descriptions: "{.ğ_二 _)6<:六三8{ )2 +\\>}[[\"^8六)+\"",
  },
  {
    codes: "        \"/19.&'",
    descriptions: "{{4:  一\\67 0四 )一728^ (一''>\"",
  },
  {
    codes: "      85/五.'%.[=.&[.)#",
    descriptions: "[64六5\\.四>7 三8/一+&",
  },
  {
    codes: "      ,九|$./三.十八._2.0>",
    descriptions: "2%+^285\\ _]+十..",
  },
  {
    codes: "    62]:.6%.)<.|\\.|九",
    descriptions: "&$六7八,十九 二|\\<.六'3>",
  },
  {
    codes: "    5?^^.九五.[9.$^..:",
    descriptions: "/4八二四八二1 9?三5<(8( \"二9@,四]+二四 ())}(|五])}: '*+|(9)",
  },
  {
    codes: "    \\8#八.[$.59.6%..0",
    descriptions: "#[ğ16 +七]6?八十; &@)0{_五1二",
  },
  {
    codes: "    5@[{.^_",
    descriptions: ".]ğ;四%/}+ ",
  },
  {
    codes: "      25五@.8十.<+.|八.五:",
    descriptions: "[九0;八42五=5 (}4一一=",
  },
  {
    codes: "      ;:一?.<<.^$.<+.5_",
    descriptions: "}8'8:七|四}六4\\6,|",
  },
  {
    codes: "        ?24/.%}.=\".7十.七$",
    descriptions: "#七]}六 998?9=^18",
  },
  {
    codes: "        )三.四.六?.$#.九%.98",
    descriptions: "=六>四]3 .:?六_(|五{",
  },
  {
    codes: "        $60,.)1.[{.>1.]#",
    descriptions: "{5ğ:五 @@+三三\"}0一",
  },
  {
    codes: "    三4.).:>.\"*.0\".;四",
    descriptions: "^?|二0三 四)@(二0%_6 (82>一*} 六1十三4二4&.3十>])",
  },
  {
    codes: "    +一'六.|5",
    descriptions: "九.ğ?.:11三",
  },
  {
    codes: "      七3:_.76.七$.六$.8一",
    descriptions: "九<##十$ \"> /*$十<四十^ &#)'八:]=&[%<&",
  },
  {
    codes: "      &0\"@.:\".'9.@七.<十",
    descriptions: "/?1/&三17 六)|十十#七*}一+?[",
  },
  {
    codes: "      *&.5.2五.3\".%,.十9",
    descriptions: "%4ğ$( &>\\.八$/%|7;+,",
  },
  {
    codes: "  3^.\\%",
    descriptions: "%十九[[九?<[五{; ((^12,5[_=@?7})一99 (&二}/}7:/>6,/9\"); 一955#=* [5*",
  },
  {
    codes: "  {3=[.')",
    descriptions: "三.]九八>1} 一十*九?{9八 ",
  },
  {
    codes: "    (+八/.)9.>:.|6.#|",
    descriptions: "73;=$ ^$四四+(9( 9}五四>4\\| ( 2/_?六\\'&五 )",
  },
  {
    codes: "    八7六\\.|&._五..七.;*",
    descriptions: "6九'1^@# >)#^ <五一;(八_四 }];六一一?< (ç<三&şı. ?九.一8ı )",
  },
  {
    codes: "  <'7>.六..<..2\\.@:",
    descriptions: ";._一{二 1(,7五\\\"八 <4@七<二,+ (二=5#], 6三&十+86|十\\)",
  },
  {
    codes: "  *&_1.\";",
    descriptions: "十\"&49&6一 +('?,:;四八4#",
  },
  {
    codes: "    十(3=.+6.]>.七$.(#",
    descriptions: "{<'八六4[九 >{:$七]_/ ( \"_>$五 )",
  },
  {
    codes: "    \\45>.@十.>$.?\".二*",
    descriptions: "4/_15?$% 六三十6^十%^20 ( &0)7九四1| \"3五&.^6八 2$四?七%'_ )",
  },
  {
    codes: "  十&{6.1^.&<.|_.|9",
    descriptions: "六)十5|{?\" 95六<\"十.;",
  },
  {
    codes: "  769=.*,.=;.十<.;}",
    descriptions: "|<十$:( 七]=5,]4?",
  },
  {
    codes: "    ]0四+.+9.十六.&|.[]",
    descriptions: "%九二};一 二八4<0五%}@29",
  },
  {
    codes: "    )一%五.&(.=(.四*._四",
    descriptions: "\"6.三(#67九$ 3*)八=]{;",
  },
  {
    codes: "    六九34.六[",
    descriptions: "=+ğ)(+九三{",
  },
  {
    codes: "          _$^].};.?|.4?.六\\",
    descriptions: "\\八2=二\"#?7 57}\"'一六三",
  },
  {
    codes: "          '四四@.;}.|*.三9.06",
    descriptions: "}五二ı一 四+'[{@六{",
  },
  {
    codes: "          七1^?.,3.{?.三四.&9",
    descriptions: "6八九]0三*2 一.93;%@6/五*",
  },
  {
    codes: "          五;五0.十..36.}%.;<",
    descriptions: "\"+4]2 =; ]=6?&}九/(6四)}<)",
  },
  {
    codes: "          _[%>.<&.]..%?.九#",
    descriptions: "?七.$:2 九(九十^2三# (+<1*0 ?'二9四# 4=@^2十|四)",
  },
  {
    codes: "          九72(._..九..09.^6",
    descriptions: "^17<$7@ $/:\"9]%九9四",
  },
  {
    codes: "          一二四五.:@.<$.3一.@=",
    descriptions: "44ğ]5 七>{_?]/ @5^;^/2六_*0",
  },
  {
    codes: "          {一#四.43.?%.@\".&|",
    descriptions: ".)四ş'四 <)<|#\"六3]一2",
  },
  {
    codes: "            _'_{.0/..=.十\".>6",
    descriptions: "十')2\\六五七 .:%|@\"四]3)二",
  },
  {
    codes: "            \"|/八.四}.^>.1%./;",
    descriptions: "^///七+ 5/\":{.#./}?",
  },
  {
    codes: "            6^|\\.3+.<#.,?.一{",
    descriptions: "{四ğ39[>#@",
  },
  {
    codes: "        *0)|.#:.[九.五五.十三",
    descriptions: "']15#4 ;九1]68;八3.{>八十",
  },
  {
    codes: "        (13^.,[.七%.7&.二四",
    descriptions: "九4^7六:;< @4]\"&9[$9450%2",
  },
  {
    codes: "        七@}9.81.,8.'}.九;",
    descriptions: "##>八,\\ 四=2十$\"_三'*@3\":",
  },
  {
    codes: "        {+三..,{.%七.2].;;",
    descriptions: "五5ğ十一 |9=&*'8]}@.[;?",
  },
  {
    codes: "九二..[",
    descriptions: "$11,$ü(}八五, \\8,;\\5|/六ü十<<# 0^ 3,\\\\)9/_ ;]五.\\ü%|(二",
  },
  {
    codes: "      二9五2.五@.五#.7#.$9",
    descriptions: "八&*.39 &+71六ü)3,7",
  },
  {
    codes: "      七<三#.>_.九四.一:.&5",
    descriptions: "+1:@八> 6}3@四*6:+ü+\",/",
  },
  {
    codes: "    *(<@.7=",
    descriptions: "2/ğ<>,|@< ",
  },
  {
    codes: "        ^三/^.\":.;7.{:.}十",
    descriptions: "6;&5:>十< &七四六4ü)",
  },
  {
    codes: "        $&(四.4_.三<.八|.0七",
    descriptions: "@四/九|{$: 一\"2&'ü:",
  },
  {
    codes: "      四二6八.5^.8六.七=.:>",
    descriptions: "0$:?\\一+< 十)5$^ü&",
  },
  {
    codes: "      .41,.'二.6十.+九.8;",
    descriptions: "&.)})3^ 3){\">ü九",
  },
  {
    codes: "        十];=.)2.$八.九\\.^?",
    descriptions: "Ç|#9' #4='}ü,四,八",
  },
  {
    codes: "        <{=<.3#.七/.四5.3#",
    descriptions: "_七4ı一  3)四/7ü_六三一",
  },
  {
    codes: "        <一]?.[&.三|.八2.九7",
    descriptions: "三九9%; %){0三ü7\"61",
  },
  {
    codes: "        4|#6.=9.8).|@.)9",
    descriptions: "5+二%(十 #9一97ü二^]@",
  },
  {
    codes: "        *4,?.)0.}六.'$.七十",
    descriptions: "$('?#2^3 (6/, 68%, 5八:八4) 1三8^$ü\\\\:8",
  },
  {
    codes: "        8%(=.{一.(@.1+.2=",
    descriptions: "+_ğ二( 六_六\\,ü%+[.",
  },
  {
    codes: "  六六六八.#2",
    descriptions: ":]%\"八%4九 \\>4%4ü^三十5 ",
  },
  {
    codes: "      5004./#.8;.;9.\\9",
    descriptions: "/$.九/^'8 三6八<6;\\| (五8&/ü一?:7",
  },
  {
    codes: "      一{0\".+9.?一.|*.,9",
    descriptions: "?#八?%=;\\ =?一@?1/四 ^七#61ü一[?.",
  },
  {
    codes: "      (|三?.5*.五7._七.六<",
    descriptions: "._,十[, \"2<{<;@一6\"ü:",
  },
  {
    codes: "      6'\\%.\\\".一+.1/.三&",
    descriptions: "五七ğ:[ }]一0),\\七 (?_五七) 8;三+二ü=;*?<",
  },
  {
    codes: "      一十3(.(9.;(.八6.9=",
    descriptions: ".四(2七=74 \"<四:*]^/四*ü\"",
  },
  {
    codes: "      ]_%4.\\\".#(.:1.八]",
    descriptions: "%(.@67|三 :$十>:7}%8^ü{",
  },
  {
    codes: "      ?]35.>:.@8.1>.]三",
    descriptions: "9八ğ<8 )/十_[#三7 5$:34二6四 八七五六{ü$*)一",
  },
  {
    codes: "      一.四>.<{.7二.6&.9[",
    descriptions: "*\\=?十,5+ /[五{*6} 九3=;二ü\\05}",
  },
  {
    codes: "    $;>[.5>.四9.&\".5^",
    descriptions: "7_ğ?9 四|\"1/'六^ ;_九^八ü一4七\"",
  },
  {
    codes: "\\4.六?",
    descriptions: "",
  },
  {
    codes: "一/.90",
    descriptions: "({十/^>七二&'; 五(\"4;/ 三@}%9) ?%*&4 九/,9二一八,9_",
  },
  {
    codes: "    ]四)_.(:.六5.七<.2?",
    descriptions: "七,._四十 }6二85;0$==;)=六",
  },
  {
    codes: "    七四|1.#0",
    descriptions: "2|ğ三['}四二",
  },
  {
    codes: "      三\"$六.一九.|+.]:.])",
    descriptions: "7二十(二| @[十九九>6 (]8 :四二ı +;.} #四+ ^七&\")",
  },
  {
    codes: "      [7|六./5.一*.:六.'三",
    descriptions: "${ğ八+>44三",
  },
  {
    codes: "  7*6..?'",
    descriptions: "三(ğ@}六五二四 ",
  },
  {
    codes: "    三#九5.@#.一0._九.[8",
    descriptions: "一_6$;}&十 七\\@8+8五",
  },
  {
    codes: "    7&@二.&六.**.[九.(一",
    descriptions: "{}二?== ^\\十#>( \"3:2二 七01>\"1+>8$",
  },
  {
    codes: "      :{)4..3.5?.九十.{二",
    descriptions: "2二7ş4( |4/2}+'",
  },
  {
    codes: "      32三\".二+.%二.)+.^\"",
    descriptions: "}@@]#2四#0 +七%十[5三",
  },
  {
    codes: "      }%/五.?3.';.6$.二:",
    descriptions: "Çö'9ü.ü5二üş }'*%>=)十 $={}:&七",
  },
  {
    codes: "      @._八.=二.,}.四\".#:",
    descriptions: "}9ğ\\八 96{[)^<{^二",
  },
  {
    codes: "六|.一_",
    descriptions: ":+\\|&\"{(; 2三6八.)/3一{:4*}? (}\"3*$一@:47?)",
  },
  {
    codes: "    \":九3.)\".二$.]>.四7",
    descriptions: "/^]%;+八[",
  },
  {
    codes: "    8:<#.0<",
    descriptions: "2#ğ八0145二",
  },
  {
    codes: "      *十\"}.二十.&{..一.,}",
    descriptions: "九^九%15十四 /十<6[+十4六九 |*二10733四}<",
  },
  {
    codes: "      2七5#.@三.七\".'(.,(",
    descriptions: "^_ğ五*93@八",
  },
  {
    codes: "  七\\0七.{0",
    descriptions: "二&ğ(3 @七)七^&${",
  },
  {
    codes: "    .&'<.:8.1三.7八.)&",
    descriptions: "48,六@六 八__,,}四= (>五)|七^(^)",
  },
  {
    codes: "      |8九,.1$.:{.$*.四\"",
    descriptions: "]5'^08, 七$*/|?]@",
  },
  {
    codes: "      [22一.三二.\\6.:/.?{",
    descriptions: "7>ğ?\\ #'&\\&\"九=",
  },
  {
    codes: "  $91,.=九",
    descriptions: "三五6二*>#}54四,六一$ (#%十1,%=%#6() ",
  },
  {
    codes: "      七九=[.八2.3#.(#.+#",
    descriptions: "|四%三4六 $7六+:|)^ <+|%]#;3三<",
  },
  {
    codes: "      9一二#.78.}四.%4._;",
    descriptions: ")\\ğ*.二四_六",
  },
  {
    codes: "    =>@八.16.?*.:/.<二",
    descriptions: "九#3|69< ^\"{$_3*六",
  },
  {
    codes: "    八>四;.)>./\".1七.;8",
    descriptions: "(^ğ八<&/8?",
  },
  {
    codes: "|7.*|",
    descriptions: "66&6$(四|^{5 1$%七 60\\]6'十九9(七$,# 十;七0_^0'1 |十五4%0ı",
  },
  {
    codes: "  .#./.+=.;$.三0.六=",
    descriptions: "8四'五.八 ?8三三\"]3*",
  },
  {
    codes: "  9_6[.3*",
    descriptions: ",}ğ3% +\\{三.三|>2 :% 8/=四[]1@?&5; *&#,[$四8{9.四=\"<六",
  },
  {
    codes: "    62>%.70.6[.六,.]3",
    descriptions: "@4_[五/五) 七\\06:55]",
  },
  {
    codes: "      七五2*.]2.2'..2.+&",
    descriptions: "+四,6]] 98,?\".",
  },
  {
    codes: "      8;六].3#.33.=>.'8",
    descriptions: "七七5%<$六[ <=,:^二",
  },
  {
    codes: "      %九\\?.*<.'2.三4.^}",
    descriptions: "[0五/\"^八 :,{8?_",
  },
  {
    codes: "      {([4.三/.1八.75.三,",
    descriptions: "/@55七, >(三%五/",
  },
  {
    codes: "      \"(#_.三1.={.'[.>2",
    descriptions: "三}ğ,? 三一:\"五0}31",
  },
  {
    codes: "      =038.7,.[).5九.\"五",
    descriptions: "九1ğ\\* 7十\"\\3八六)&(八",
  },
  {
    codes: "      一\\&3.三8.八一./九.3@",
    descriptions: "*$$)^\\}3;9&:/1二/",
  },
  {
    codes: "    >\\('.+#.六{.|(.\\]",
    descriptions: "}4%+十+%4 四;77*四六6四]十",
  },
  {
    codes: "    :|$#.,_",
    descriptions: ".?ğ]|%.',",
  },
  {
    codes: "      #五五三..;.一@._6./0",
    descriptions: "七\"0'{4%0>一3",
  },
  {
    codes: "      七七53.}\\.$8.5{.8二",
    descriptions: "9]|'.0$0九)9",
  },
  {
    codes: "      \\9&^.九}.2七.${.2&",
    descriptions: ":;ğ\\/ .一{#\\*1<?#<17{",
  },
  {
    codes: "  38一7.5二",
    descriptions: "七2*九三0($三[1 ",
  },
  {
    codes: "    }),二.九1.^&.十$.七#",
    descriptions: "一_}<\\:九 [:[[_@5:",
  },
  {
    codes: "      :0*/.=9.0$.)二.7%",
    descriptions: "8八._:九 三$1九3三八&",
  },
  {
    codes: "      八91..七$.7$.五,.#&",
    descriptions: "二二ğ(@ 3\"/2九_#)}七?",
  },
  {
    codes: "  ('+1.三九.$|.九{.03",
    descriptions: "'?((,+7|7二% (十2/3三4[%二八=*)",
  },
  {
    codes: "  七{*}.7一",
    descriptions: "|六ğ81+2<九",
  },
  {
    codes: "      =5\"一.#$.+九.=3./7",
    descriptions: "\"3三:9+#)+",
  },
  {
    codes: "      四<[}.>9.><.[8.$@",
    descriptions: "8七[*=:七174",
  },
  {
    codes: "      >[9=.}'.$$.|+.$^",
    descriptions: "八/五_]+九四>%#_",
  },
  {
    codes: "      6九9八.;'.[{.'七.^*",
    descriptions: "%六)*七3 \\一|2<|{4 '4 ;%六;十> $&5四八9九(",
  },
  {
    codes: "        [67#.八#.0#.七].{<",
    descriptions: "=<8.三五 _3*>二二;{",
  },
  {
    codes: "        _\\^2.九八.:9.;#.|6",
    descriptions: "三\"ğ?& ;#\">一>$#一,五",
  },
  {
    codes: "        2#九四.){.]四.>>.|@",
    descriptions: "<{三九1八 }6五[*/",
  },
  {
    codes: "        80]}.$^.五..5}.7九",
    descriptions: "&九ğ5#%$十6",
  },
  {
    codes: "40.=$",
    descriptions: "İ[一7,7四,{ 9九十],1八四/ 8[*< ,>?&9=] 六<|][%]1/ 6{ğ^@ ;)十一{9ı (}3.8四八% =(==\\(6",
  },
  {
    codes: "  [/九四.36._:.%6.76",
    descriptions: "六+@;>四}四 六*5_ ç;). 92十:&三[三|\" (\"|'+^=[ '+%九1#? 1[二 2九九ı@\" 3\\$=_",
  },
  {
    codes: "  五*.%.&*",
    descriptions: "三2ğ(/#};2",
  },
  {
    codes: "      *56/.,}..(.}].#0",
    descriptions: "5/7|| _#43八<1二7 *5三|;1ı, ç[^8 @<2\":[ı #6 四^九3@%@# [二%$_八ı",
  },
  {
    codes: "      8<&..^二.九$.'%.'>",
    descriptions: "\\@#\\ü: 九_{\\]七{九$ [七*$(_ı, ç4四5 <2@:*;ı 七3 '>8四+12七 ?]^},<ı",
  },
  {
    codes: "        \"\\]九.一1.4[.==.+<",
    descriptions: "*}十$#十 +:>]4:7",
  },
  {
    codes: "        :六,/.\\\".<2.#二.|\\",
    descriptions: ";9|7)2 }二3,七5=",
  },
  {
    codes: "          >,3(.<<.+(.:[.%十",
    descriptions: ":.八%3'/七 +四*335+",
  },
  {
    codes: "          6{1/.六+.五^.=八.\\<",
    descriptions: "0一ğ一?5$三[",
  },
  {
    codes: "        *三?八./?.二九.六5.四[",
    descriptions: "@#六ş.七, 4二|ı. $620五1七 八% %二(|一五八{6*",
  },
  {
    codes: "        +73七.二二.八\".八3.&0",
    descriptions: "}?ğ]< 4_五^{三) %\" *15十&3_[_9",
  },
  {
    codes: "        6=?|.+/.六9.七六.4四",
    descriptions: "七2}0一 >十四*?;\\ 1六=一ü十",
  },
  {
    codes: "        6|8%.1^.)[.0..一3",
    descriptions: "5ü1五9八_50.?, 一;四)+#}/$$ }二 三6])^五?&18#\\*,",
  },
  {
    codes: "          *)]6.四/.=七.@十.#八",
    descriptions: "9'@(62.七 %5^| ç#%二 九九;十一四\\十十",
  },
  {
    codes: "          .)\"8.};.{_.五;.五%",
    descriptions: "1.<,}5:3 _(]五 ç|>/ %+1七$0二8九/8",
  },
  {
    codes: "          <9@=.3四.\\+.)(.七三",
    descriptions: ",7ğ}十5.8九",
  },
  {
    codes: "'5.}#",
    descriptions: ">/(七4;]@五 1十155)| ]ı^6_3,5 [$89#.|9; /ı'六+十[? |\")5$<]'7]",
  },
  {
    codes: "  ^8]三.41",
    descriptions: "/*9<]]'&1 1.#一_四三 一ı#}3>[6 ^十86}1\"/",
  },
  {
    codes: "    4七&).<^.七6._十.\\一",
    descriptions: "三ü九üş ((<}3^|${@二)",
  },
  {
    codes: "      八九,七.九'.五3.七1.[,",
    descriptions: "*三六|875#@ 78一ı九",
  },
  {
    codes: "      三六;=.\\九.#二.]四.6五",
    descriptions: ")$0#十四*0& %六七8}六",
  },
  {
    codes: "    ,_?;.}@.'六.*#.(}",
    descriptions: "{ü9üş 四(五'.\\",
  },
  {
    codes: "    </^7.@1",
    descriptions: "7]ğ13@八># ",
  },
  {
    codes: "        \"十九|.二}.\"}.{$.73",
    descriptions: "/ü[üş 十][二;ü|}&#",
  },
  {
    codes: "        ?'>二._=.54.41.(0",
    descriptions: "#8ğ(5>6|0",
  },
  {
    codes: "      ?\"二%.+%.十@.'<.\\,",
    descriptions: "2四ğ十; 9ü'üş :[]&ş七\",6六七",
  },
  {
    codes: "  (三二[.>九.八$.??.+/",
    descriptions: "=9<ı9 九#'4ş>/230四",
  },
  {
    codes: "  )0四'.\"%",
    descriptions: "^?ğ三$ 57_=ş/\\7?五; 十>二>>五;5五(",
  },
  {
    codes: "    2^{].6,.0@.?{.=\"",
    descriptions: "2)&3;二四4?9",
  },
  {
    codes: "    ?>@\\.91.五$.七,.31",
    descriptions: "三(ğ^&:二3@",
  },
  {
    codes: "3|.>0",
    descriptions: "五24@&>3)六七 三%<?3($十 72}一%(&43$ *? <7_[\\2${1? ;^+0+9.^0 (64/ç($=5_七\\/]>",
  },
  {
    codes: "  |.二七.三|",
    descriptions: "*(&\\: \"^$.六三0 $五 四$8;0 $一一$ş{24(<}; @|\"34 ]=9四5.\" [6>8",
  },
  {
    codes: "      1$四^.八\\.六'.6$.84",
    descriptions: ".?>; 4ö1ü4$ü 三十 +{$*+^二. (@/4五/36)",
  },
  {
    codes: "      #九#_.,%.:_.11.[*",
    descriptions: "İş四<三,@ş (]02]\"6$)",
  },
  {
    codes: "    =二'四.2\".二%.三5.10",
    descriptions: "\",\"1九 {/#};<1",
  },
  {
    codes: "    <9:$.00.五四.1,.|七",
    descriptions: "@?ğ二十四[&\\ (一&+一{三9)",
  },
  {
    codes: "  :?;5./'",
    descriptions: "三 .\"9 260_{] 二三;+%九@+ş{'2六|四九ş 七6<一五1( %; /五+?5 ,\"$3ş%?0&%七;",
  },
  {
    codes: "      /#\\(.|四.}九.6].<7",
    descriptions: "@0$7? /六?0=(二",
  },
  {
    codes: "      &二=|.^/.2一.4).八8",
    descriptions: "01ğ$>4%}2 (,+}6;\"5)",
  },
  {
    codes: "        十@&八.*7.6{.}(.8&",
    descriptions: ";2三^? >(三\"八;}",
  },
  {
    codes: "        #2:;.4).:#.七9.5%",
    descriptions: ";四ğ三<\\{4十 (一'|#4*{)",
  },
  {
    codes: "      ?&十|.4/.(一.一\\.;}",
    descriptions: "61ğ:7[}13",
  },
  {
    codes: "  30=%.\\四",
    descriptions: "6 9,8 九#;=8) #十9九2六^ş7\"(\\:;4ş >六[]七1\\ 五$ 八\\\"九+ ^七3*ş{,89,四;",
  },
  {
    codes: "      七:2/.70.七四.:,.>'",
    descriptions: "\">\"6四:&_[",
  },
  {
    codes: "        ;;一三.|8.>/.64.九_",
    descriptions: "8 3&) >.<784 4($;*#八ş7$十37'四ş 6八一;@八*",
  },
  {
    codes: "        八?\"九.8^.3{.+&.+0",
    descriptions: "< 八04 {4={八+ <$三0@97ş8]+8$'=ş &.五十十+(;1 二四(二ş4,/\\九6",
  },
  {
    codes: "        九)5\".'@.八7.13.,六",
    descriptions: "&%ğ9+八398",
  },
  {
    codes: "      九五;)..#.[..^6.九#",
    descriptions: "1三二[=$@3'",
  },
  {
    codes: "          四%2+.1二.&3.2/.)4",
    descriptions: "[?八<$+",
  },
  {
    codes: "          58一%.00.8一.%1.一}",
    descriptions: "8='..6 1<%}ş<[\\_五<",
  },
  {
    codes: "          30[*.7}.$一.99.四=",
    descriptions: "${0\">2 .0@25_七五&ı",
  },
  {
    codes: "            .六'}.%@.六4.十'.\\4",
    descriptions: ">十八,五=",
  },
  {
    codes: "            五(_3.]$.28.八1.;6",
    descriptions: "五@八2五@ 四;$.ş^三=^\\3",
  },
  {
    codes: "            七三[2.九5.|2..三.=@",
    descriptions: ";|18/八 9']七五四.*三ı",
  },
  {
    codes: "            \"三九0.二7.=3.@\\.=%",
    descriptions: "*;四35[",
  },
  {
    codes: "            8一&^.<3.}四.$,.$一",
    descriptions: "),4'八四 九%\\/ş一8>4($",
  },
  {
    codes: "            ,2五].9?.:\".(7.7<",
    descriptions: "三#1\\六> %六678*$:{ı",
  },
  {
    codes: "      4_^0..=.]9.三七.五=",
    descriptions: ":^;=_] 0,67 三 72, 48六\"16 *<*^]九4ş_八]<9}#ş 七,>八*十?八@# 5]@12$;",
  },
  {
    codes: "      '&一..:五.21.9七.0十",
    descriptions: "二9ğ<二五+8.",
  },
  {
    codes: "    三[:六.>^",
    descriptions: "56*八)&三 1\" @1#<ş:十@六_=; %八?/[2> )@ [,\\6ş'/>|$?/1 四ç&'#\\  八%[şı/_=三,",
  },
  {
    codes: "      [6,..+*./(.\\}.:]",
    descriptions: ".六九五] +{\"_$\\$?;) 九,5九#3 ('6九二;0五); 1*=\\0 ]十一)&,\\|(/ ]四1六4& }.,*ş99九*<三 (\"#:{2一七)",
  },
  {
    codes: "      十<}/.四三.8六.37._7",
    descriptions: "/九ğ01#|3:",
  },
  {
    codes: "              一5@八.9'",
    descriptions: ">>?+]86\"32_, @}七7?3>\"{三#, {?>二*,7九#9八三+六{, 七ü.5八]|$,/, [ü一6_%4&[?,",
  },
  {
    codes: "      %@*5.一八._$.十@.\"三",
    descriptions: "6)}63 ;五_##+0']. 30@1三[ (','/2%|); 三2二.& @%七2>%)5'^ ()>九5六 5%@六ş4'#5三\\ (=3二六8?&)",
  },
  {
    codes: "      35四2._>.{六.)1.,六",
    descriptions: "2一ğ三{][?十",
  },
  {
    codes: "    :@^..:]",
    descriptions: "\"十ğ五/ 九九7'1}=_&_ )八|:&}8三=@ +, 8}<8#(44% +' '/{五ş|&;;九; 二. $\"8('95>二.),",
  },
  {
    codes: "          #一一九..\\.五).($.九,",
    descriptions: "%\"?>#&一三六6;%",
  },
  {
    codes: "        5]\"*.''.0_._6.}5",
    descriptions: "二9ğ\\.十>+3",
  },
  {
    codes: " ",
    descriptions: " - - - - 二>9<0 四;.{2二五(73 #+,50八4.2 (>\\_#|:^)",
  },
  {
    codes: "          ?$五四.3八.三%.六二.+{",
    descriptions: "/十$:\\)<>_. 七^九+",
  },
  {
    codes: "          @75\\.91._8.六三.十6",
    descriptions: "五;=十/]二\"7四 /|$8#[",
  },
  {
    codes: "          四五五八.2/.'=.3\".'十",
    descriptions: "'\"%>[0_六8= 九<'1八;",
  },
  {
    codes: "          七:#9.6&.7六.+=.五5",
    descriptions: "+^,一5&7<[# 0五^)&%",
  },
  {
    codes: "          1$*5..十.八0.三4.一<",
    descriptions: "十;ğ{55)七四",
  },
  {
    codes: "        六_}=.8].7五.3三.33",
    descriptions: "{9(七< }>85$八@4\"[ 5\"4{_}<[三ı三 1\"六=ş={二?>2 (+(^{)十1)",
  },
  {
    codes: "        %\"0*.+@.\\5.%3._八",
    descriptions: "\\+;七,@\"1?^ .).'ş三<59={$ <7一}@8=50# %(+四 ['8#[$:}%ş ''5三@&[一\\$@ '' ==7>8六",
  },
  {
    codes: " ",
    descriptions: " - - - - \\?ğ}869*8",
  },
  {
    codes: "          九;}:.十@.十3.\"二./+",
    descriptions: "_一#^=九^ }|[6_五5",
  },
  {
    codes: "          *七_'.六>.五5.+].8/",
    descriptions: "8@ğ9.3九(\\",
  },
  {
    codes: "    3/|#.0五.7/.)(.%^",
    descriptions: "073七+(六\"_四 :七ı9'九^",
  },
  {
    codes: "  \"\"三九.47.二二.3..9$",
    descriptions: "\\ü三九680 1[四4(ö8(;八[6 ,'\"#(9ı五]ış (3'>.二九_}( +[;(四) 3>2ı= >9八9)}^66四|",
  },
  {
    codes: "  四}.@&",
    descriptions: ",'.一0 }(十@7四<#) 0ışı+:} 七0^{八 3*^八'十4\\七; *])/六[ı4 8^九5&'四 '0* [四七",
  },
  {
    codes: "  ,3[八.>九.}二.?_.|{",
    descriptions: "5ğı二 |九 (4ö八?<六%1 \\@'|3) ([+34,^*)",
  },
  {
    codes: "    十七|$.3&.=/.九{.:\"",
    descriptions: "/\")9]四' 7ç5九\"] <.%十^^04ş*7五十1#\\ş 1(%^@ *\\ \"\\7\"ş{'::?3",
  },
  {
    codes: "    (八^@.*+.{:.?七.三0",
    descriptions: "1#.八五{| ^ç8^;{ [^?\\:一|$ş?6[9三:二ş 九一];8七 七\" \")&:ş=<八三九6",
  },
  {
    codes: "    %0\\).>@..[.\\}.64",
    descriptions: "?75$$5:",
  },
  {
    codes: "  2${3.1<",
    descriptions: "三,ğ*四7五*六",
  },
  {
    codes: "      }五7六.8>.9#.67.%四",
    descriptions: ">ö9三一六 3<6{{<\\ ?八[七ş6九&;/",
  },
  {
    codes: "      六\"9\\./(.\"$.一\\.(三",
    descriptions: "\\#ğ36{+9五",
  },
  {
    codes: "      %@7%.六}.+8.一4.二*",
    descriptions: "+;十;_.七二+@ ,,二)ş;九\\7/",
  },
  {
    codes: "      九+$[.<0.|^.三3.九@",
    descriptions: "[^ğ十九,三?#",
  },
  {
    codes: "(/.4?",
    descriptions: "二.,/* ,1九}6# 4>_<^0.8(/, :,{三十{| 六八9} 八28?八=27/ 七九.* 1.",
  },
  {
    codes: "  二)六9.;八.四).)_.;7",
    descriptions: "三5;_28 }%|[ş&六}{]*",
  },
  {
    codes: "  4\"6<.2=",
    descriptions: "26ğ七;]八五5",
  },
  {
    codes: "    5{[四.五<.七+..5.*#",
    descriptions: "[}=7#6{01 >八三(ş>8/($5",
  },
  {
    codes: "    7}\\|.%?.&7.*9.7]",
    descriptions: "1,@\"3{ =({(ş%{%|五:",
  },
  {
    codes: "    &\"十5._七.8一.\"=.4]",
    descriptions: "八'\"六7五八二8, %>{九%||1& 五:五二 ^5?0?四$七 九#60ş)十<七[2",
  },
  {
    codes: "    十%一).5^.\"7.[&.<6",
    descriptions: "#,7%7#*}九:, '@,)9/七十 <}*[ >七六[三三0=\"三 5)@_ş五0\"2'3",
  },
  {
    codes: "    四.四/./|.三[.四七.]7",
    descriptions: "(?二(2)05{, 八&^四&((., &@2}\";&, *|\\三^9,   284;0$_<三, 一2(八&4\\^ 九.一( 5九).*.二 七[8<ş*三1/七4",
  },
  {
    codes: "    132>.+四.6/.}'.3.",
    descriptions: "四=\\{/ ]8)ışı八=四9ı$ı, 五*@8ş:0?;2三",
  },
  {
    codes: "5.,8.;*",
    descriptions: "/8;四$3.三 [}573^\"2 (ü/9 五61 *@_十%;ş六二&:&<+ş  十+%7四 4<})6ı一) ",
  },
  {
    codes: "  9五=一.\\三.}].#8.七%",
    descriptions: ";%<|1/],? ((九 .7 % \\_ 7_六[ [ç@*40)",
  },
  {
    codes: " ",
    descriptions: " - 十九ğ1&90)5",
  },
  {
    codes: "    五七十,.\\*..8.;'.<)",
    descriptions: ">十?ı 6八九九=[_/ <:二1七0五3",
  },
  {
    codes: "    [一^).#|.六1.0{.\\,",
    descriptions: "^6ğ八^_:&[",
  },
  {
    codes: "+,.&/",
    descriptions: "",
  },
  {
    codes: "?四.$>",
    descriptions: "|/>$ü$=21 (#;^2七02 )_+[(?[ 三1. =$十ı<\" ](0{\\ 98?}\\ı<)",
  },
  {
    codes: "  七5=).&=.四四.十9.2\\",
    descriptions: "_七[01;&1 <0;$ü9",
  },
  {
    codes: "  }1#一..(.]$.八五.4四",
    descriptions: "一十6;[@16 0八@>ü5",
  },
  {
    codes: "  9+$^.5三",
    descriptions: ":^ğ;六%'七_",
  },
  {
    codes: "    #一1*.9{.8六..二.>]",
    descriptions: "0$2 [2_#ü9",
  },
  {
    codes: "    ?.*\".},.|七.28.5八",
    descriptions: ",@9)九;/( \\48=ü'",
  },
  {
    codes: "      二三&十.+<.38.33.+;",
    descriptions: "9>;.一^4_\" 4七,一ü;",
  },
  {
    codes: "      =*.五.@/.39.四'.1<",
    descriptions: "六一|七 %\\9%ü;",
  },
  {
    codes: "      三>七8.4*.|=.03.<?",
    descriptions: "十?@八(:二( \"十5四ü4",
  },
  {
    codes: "      五/九*..\\.四(.$七.8@",
    descriptions: "%2=%=二3\\ 一1)[ü*",
  },
  {
    codes: "      ='\"?.@6.=,.<[.|一",
    descriptions: "5六*=@* #/1>ü\\",
  },
  {
    codes: "      6?七{.1三.\"/.9三.5@",
    descriptions: "七6=(+ #=\"+ü四",
  },
  {
    codes: "    $2十1.二}.)五.<四.?1",
    descriptions: "8}ğ七四>$?十",
  },
  {
    codes: "_5.2.%七",
    descriptions: "=_三五ü@六;六, +/#|ü](/[, 7|一*ü_十$6, {;三7八ü&@|. 八[ ]1)ü3七]# (九47$*/^",
  },
  {
    codes: "  ,0七].13.一@.六2.#[",
    descriptions: "|=.\"ü@://; 3?#二ü{_五六",
  },
  {
    codes: "      [[<=.<2.<3._<.51",
    descriptions: ";\";十八. 5七六\"ü|",
  },
  {
    codes: "      九?八三..$.\";.,+.^?",
    descriptions: "二\\ğ4? \\14六ü)5;十",
  },
  {
    codes: "    $十7五.8.._[.%一..7",
    descriptions: "六\"十ü{ü_3,{",
  },
  {
    codes: "  8四六3.4,.七=.{@.7>",
    descriptions: "九^=ü'4/>",
  },
  {
    codes: "七9.?]",
    descriptions: "",
  },
  {
    codes: "2).9五",
    descriptions: "8\"&+/ı; }\"9@}一: 251? [_=9\\十二}四 {)\"'ş[[)3&} (,274七#|1 0.>三2四 &十8ı#六二<七ış",
  },
  {
    codes: "    四一,\\.}八.(<.<{.$]",
    descriptions: "}3\"\\ @+5九/&,5+",
  },
  {
    codes: "      2?]).*?.}四.<,.九三",
    descriptions: "^七78 (,) :&'四ü: (;{?三?@_)",
  },
  {
    codes: "      二';@.六=.$|.$\".^八",
    descriptions: "%(一2 (^一) .8<]ü? (1ü6\\][&)",
  },
  {
    codes: "      二七3\".]五./五.七^.:5",
    descriptions: "@.53 ]一''$\\3十ü0",
  },
  {
    codes: "    <02六.八&.+4.十].]\\",
    descriptions: "55.七 )(2'}八/_:69>ü#",
  },
  {
    codes: "    3<1'.8>.=6.[三./7",
    descriptions: "?\\.3 .?89ü}九1< 八@ 4);0@*六7ü;;二七",
  },
  {
    codes: "    ]87五.17.4[.五\".[=",
    descriptions: "2]四: (ü,:7# @2 .6+二三ü.'ü?",
  },
  {
    codes: "    _^0三.\"8.[[.八&.4>",
    descriptions: "9&\\]& 5ı7四 三ü{]5二",
  },
  {
    codes: "    {4,\\.八4.}*._;.<五",
    descriptions: "*=六6[ 33=[四/三 9ı9% 五ü}*..",
  },
  {
    codes: "    4:0[.\"..?&.[(.>(",
    descriptions: "#^@= [)9六9=.{+",
  },
  {
    codes: "    7/|?.|#.=$.+;.5\"",
    descriptions: "六_54 &[&'六5={一5十}$",
  },
  {
    codes: "      六\")6..\".?3.三$.:+",
    descriptions: "[三三  八2=]0(($ \\)\") 9五一>0ü'",
  },
  {
    codes: "      {五<&.三{.^<.+7.69",
    descriptions: "=>|十 %('2$ 0}{.十ü]",
  },
  {
    codes: "      (八三@.<&.<#.?0.|{",
    descriptions: "59ğ五#'0<?",
  },
  {
    codes: "      )\";四.24.91.]'.>;",
    descriptions: "]][  六一八}_}% 2*;{1<2一 *5}九 _&+%二ü2",
  },
  {
    codes: "      四//).;9.$].]5._八",
    descriptions: "3七九^ =^]三8 @_七\\9ü%",
  },
  {
    codes: "      ^71>.#3.&|.%十.3五",
    descriptions: "五(ğ二1五+;_",
  },
  {
    codes: "    七403.)..三,./九.32",
    descriptions: "',\\\" *ü\":4\")^, 0$一3五2& '七 ,五%|}五2):|}",
  },
  {
    codes: "    .+#[.三+..'.^5.十十",
    descriptions: "20@# 十7四;((,  {1八二六'=| 二9 $/?\\8?5+1.|九@",
  },
  {
    codes: "    九/)3.4>.一<.=9.$[",
    descriptions: ";&三}%ı一 +三|.)?4九 八^4] ç6[^ 三(\"<}2五5|=ı",
  },
  {
    codes: "    4}二\"./三..].$|.+十",
    descriptions: "'8三五 8三五%:#&.七八 >} ,一.2八{&^2/",
  },
  {
    codes: "    ,41{.8八.七=.&3.[0",
    descriptions: "2ı</@}@十 八(3[6\\.;:( '|\"\\\\ı 八?$]ş|+487}",
  },
  {
    codes: "    2'八,.八'.:_.九,.8}",
    descriptions: "2*]9 八7+)ü5'6九",
  },
  {
    codes: "    二0二_.七1.,+.4六.二6",
    descriptions: "'?5十 八?三八ü,&九5",
  },
  {
    codes: "    @八一6.^8.80.^(.@&",
    descriptions: "六80/ 九=九*ü1.<4九 \"( %)44 /2<6ü;21[1",
  },
  {
    codes: "    九(56.二@.(?.4>.三)",
    descriptions: "&$90 ^4\\三ü九6#十^",
  },
  {
    codes: "    .{五>.@_.3_.|;.;?",
    descriptions: ".十6三 {@\"ü8ü33六\"",
  },
  {
    codes: "    \\3$8.六四.八&.2}.2三",
    descriptions: "$五十8 ::(ü0(五5",
  },
  {
    codes: "    /九\\六.]].一7.三].]&",
    descriptions: ".四9五|ı \"@一八$七十0二ü(",
  },
  {
    codes: "    \\42?.24.&二.59.>三",
    descriptions: "++二1七ı ^\\6五4=< {九]4ş>*_四8",
  },
  {
    codes: "      七%4四.}/.七|.三{.@9",
    descriptions: "^[66 二{ 一57;$一*,{ü)",
  },
  {
    codes: "      一.=,.?8.];.]七.5:",
    descriptions: "七@ğ\\1三8.|",
  },
  {
    codes: "  @_五:.一5.,:.*十.$$",
    descriptions: "%=ğ&\\<四三%",
  },
  {
    codes: "7三\\0.二*",
    descriptions: "4%=:ü二+$8 (4]四1%@; 六=]七四*\" )\"0 :{2ı0/ 三[9+= 一8$九.ı4) (@:$;五 =0三[@' (98六ç);",
  },
  {
    codes: "  三+七}.)六.2|.\"_.,?",
    descriptions: "@六+1二三九%. ':四9ü? (9>_34,$</)",
  },
  {
    codes: "  #642.{4",
    descriptions: "1;ğ六%八二]*",
  },
  {
    codes: "    _7*,.三'.八,.1&.:9",
    descriptions: "1<&ı_ı3'ış  六|\"九 三?_9二4+七#+ 1七*; 六# =_#九六(_ }一#?ı2四七(三 五$",
  },
  {
    codes: "    1二>).//.|二.9+.二>",
    descriptions: "七ı7ı @76五 (.ç九一|9?# <\"\\% @5.0%( 七8ı一?ış .?9\\= 6;九九3ı+); >ı:ış{ı3ı0八ış",
  },
  {
    codes: "      五584.&<.>五.[_.#/",
    descriptions: "二十1十^五ı +*}ı/ 8十|&^*82$/ı",
  },
  {
    codes: "      十$)2.三,.*<.九4.八*",
    descriptions: "}八.ı% (三}7ü@ (4ğı]4ı十 七':3?=[=$4 % %^' 0一+ +884; +十(4六0 六ç六八六+5*4)",
  },
  {
    codes: "      五1三...6.5>.二).四#",
    descriptions: "$:八 ç),,+ 52七/ü*",
  },
  {
    codes: "      {4#(.^@.7<.1六.十三",
    descriptions: "四}一 $4八九\\^: ç()一\\ 1$^)ü=",
  },
  {
    codes: "      \\8四\\.%+.<,.46.1.",
    descriptions: "一7ğ*[ )十_]ü十8=四",
  },
  {
    codes: "      7:三_.*五.+>.=+.}{",
    descriptions: "一\"ğ8^[五**",
  },
  {
    codes: "\\二.%<",
    descriptions: "\"#'八九<@ş ?,一.;0, 1+;üş \\八 \"ı{{ı1 ^)<5@\\,2 (5{.九| 6二?0 九>.二1",
  },
  {
    codes: "  12五9.5..十四.,2./6",
    descriptions: ";ü9五?% .6}= )二+5 2四/*三十 \"ı?二$八ı[@[: =?#170&",
  },
  {
    codes: "    ^._'.9\\",
    descriptions: "=)$9}8&=十ş 487ı :(七+62* \"八}1@= _= 03'üş",
  },
  {
    codes: "      一>九/.)五.#..#%.|八",
    descriptions: "^>#%'383:",
  },
  {
    codes: "      _?7#.&8.%].)7.^四",
    descriptions: "7(2一六1,@",
  },
  {
    codes: "    1六$三.5(.:6.\"(.十^",
    descriptions: "四>3(:+:ş <|六ı 十^+;6五8 ((26&;;]六) 六9%7?五 )2 ^^&üş",
  },
  {
    codes: "    三@(六.0#.7+.$>.6'",
    descriptions: "2八?ı .5</916 九7ğ;6 |]18][ '. 七)七üş",
  },
  {
    codes: "    =十三七.@六.=0.6=.?}",
    descriptions: "二ı&@ı十 ,<一^{$八?",
  },
  {
    codes: "    ;%|>.九_.一1.50.2&",
    descriptions: "Çö$(ü 00+/40# \\一5%;4 六七 @\\:üş",
  },
  {
    codes: "    >19?.=].3..]7..2",
    descriptions: ";}5=十1<;八ş 八三?ı 4三>三9@& \"九;.7八 )* |{\"üş",
  },
  {
    codes: "    三&.2.$].2).(六.%二",
    descriptions: "}3_五64#ş ,*%ı 一.4$+5| (.3(_5{+) 六+:六#} .> \"<<üş",
  },
  {
    codes: "    7>十)..:.>=.一十..{",
    descriptions: "[三十ı 四^9+0\\' )七ğ1@ \\63+9: 3\" 五二9üş",
  },
  {
    codes: "    六#|<.;四.,0.1/.,&",
    descriptions: "一ı':ı2 @.1)'4.七",
  },
  {
    codes: "    6_{二.+二.8).\\$._4",
    descriptions: "Çö.7ü ]:.:/@? 0九,]0? ^9 *=;üş",
  },
  {
    codes: "  *02#.七7",
    descriptions: ",%{四;4<< ,2@:3八ş七四 \\?ğ\\七 二(\"十,7@八,十|",
  },
  {
    codes: "    ,>:一.\\3.=七..).3'",
    descriptions: "{五(五&七?=",
  },
  {
    codes: " ",
    descriptions: " - - 9#ğ一7_\\五$",
  },
  {
    codes: "      64+5.)&.\\].4四.二.",
    descriptions: "İ$\\[<<#, %+{6^4}3 九& +%ğ?} $/1. )ö&ü04ü\"{</4,^3 \"06<36'",
  },
  {
    codes: "      42+1.7=./$.?八.]<",
    descriptions: "#]2|_*^ 83077;五",
  },
  {
    codes: "      :<71.8五.#$.75.{'",
    descriptions: "%二ğ479:{$",
  },
  {
    codes: "<9.九_",
    descriptions: "<#'[; 二三>二_ *3<>%+# ^]|):六\\* (]0.7? 6一,0>9)3八>4] {.( 四三8$ 9四+=二??&二八",
  },
  {
    codes: "  '46(.]|.;^.2<.一十",
    descriptions: "七>&18*|} {七[2二 11;/3 \"九5')(> 四7%>三#4=",
  },
  {
    codes: "  8八一%.1?./五.]七.@&",
    descriptions: "9*5一八七1; >五:十八_ş%, .{ğ91 [>_$.1&$+9一 _)七八& 3<?&4 6一&.$%| 4七三八7五六十",
  },
  {
    codes: "  _{\\:.3三.}(.?四.8#",
    descriptions: "7]3二< +,5}?{ş +七;2\\:5*",
  },
  {
    codes: "78&3.五]",
    descriptions: "七,6 四\"五:582二 (,\".#, ?7^七',六[]&0二 6;% .700 @一5]二899@^ 二=8|ç)",
  },
  {
    codes: "  十}\\$.一..%4.四).$%",
    descriptions: ";5@,.八:2",
  },
  {
    codes: "  七394.4[.六'.4(.73",
    descriptions: "İ($],六% 十\\ <4四, 五ö/ü)五ü\\ü六_#)",
  },
  {
    codes: " ",
    descriptions: " - )@ğ[)3>七5",
  },
  {
    codes: "    ],|#.44.八\\.<[.=_",
    descriptions: "七三^+*([0 4. 6七;9 '3四,\"^$'八",
  },
  {
    codes: "    \\'二四.九1.\\5.3九.六>",
    descriptions: "_(八@四 }三三& *_二三三六一",
  },
  {
    codes: "    4(28.^十.1四..+.0\"",
    descriptions: "3ü,\\?.",
  },
  {
    codes: "    ^/七九.{十.8九.3三./<",
    descriptions: "\"\\&九{0}: 1%<<四.ş\"| 5$ğ]0 一]六二|2'一 #%]=2八,\"1",
  },
  {
    codes: "    '_.一._).&+.((.|%",
    descriptions: "二九ğı> +一$六ğ)=&;\\",
  },
  {
    codes: "    3二三\\.九:.8二.十<.5二",
    descriptions: "{8ğ/5二'四1",
  },
  {
    codes: ">@.{{",
    descriptions: "1ü七%:六 0四 五6ğ二+ 6ğ $5*;*五98 (*|&[三*%ş, ö*,_ 5?(六 +*五ş\" 3}:二九>&5 二[#|ç);",
  },
  {
    codes: "  @九^+.9,",
    descriptions: "0ü七二?八 81 04ğ<, <ğ /29\\:(六\\",
  },
  {
    codes: "    $:十六.=九.?三.一\\.\"6",
    descriptions: "}ü% 七三[<&四;",
  },
  {
    codes: "      九)八1.>%.(四.@#.三?",
    descriptions: "三.{<9 4#^+%(一 (四]% ]]十四 (.四ışı_), 15\\七 ?三0三]3\\$6 4(*1 一%三'9,@三",
  },
  {
    codes: "      #十&4.(八.9=.@%.*7",
    descriptions: "*/ğ46 8|三_/<$九 三ü[八9$ $^ 1ğ 7\\#),五=5",
  },
  {
    codes: "    六|)4.7}",
    descriptions: "7\\八4 5二 八23/@二39 5@$#[;07三",
  },
  {
    codes: "      _,%五.[四.\"&.52.6,",
    descriptions: "1{0. /199/8'*] ??三=$5四",
  },
  {
    codes: "      十'5%.:&.'{.3=._{",
    descriptions: "@(ğ?2;7|)",
  },
  {
    codes: "    ?##三.=二",
    descriptions: "3(二^7,四' 八&六一^8ş5] 四6ğ3五 3六6四);0||=[",
  },
  {
    codes: "      &|\"4.\\+.{&.):.4四",
    descriptions: "]十|2# ?'\"@:]< (6八[ 十)\\( 807ışı)) :八\"六>八(",
  },
  {
    codes: " ",
    descriptions: " - - - .8ğ;[三三二; ",
  },
  {
    codes: "        __+六.'>.]十.\\'.九,",
    descriptions: "727==*_二 \\四五{$_九",
  },
  {
    codes: "        '<5;.^*.1六.2^.)1",
    descriptions: ",:ğ=四 =.七({==@\"2四 >}二,8<@",
  },
  {
    codes: "  0%九7.五&",
    descriptions: "9| 'ş. 1\"][|?6",
  },
  {
    codes: " ",
    descriptions: " - - |七^[: $1?{'+四 (2#{ 六/}4 一{八ışı@) >9:{ '3[四 )_6十'二:十3 三{#1{@二 ",
  },
  {
    codes: "      2@九?.|四.8).四/.;五",
    descriptions: "':1十一 >|:$[;/ (]39 /0十& '%六ışı八) 十十(\"十6?",
  },
  {
    codes: "      三(0一.#&.7;.3>.四三",
    descriptions: "#%{^ 1二/十]*?#五 4=;9}3$",
  },
  {
    codes: " ",
    descriptions: " - - 八.ğ;+,十:三 ",
  },
  {
    codes: "      \"#4_.2九.'\"._1.2五",
    descriptions: "':[\\三5三8 五九六一_;十",
  },
  {
    codes: "      |${八.\"(.6\\.,].四&",
    descriptions: "@@ğ<七 四(\\\\5四'六8.@ 2*58*;7",
  },
  {
    codes: "=/七#.七*.|^._:.|8",
    descriptions: "\\} 9五. ;六?'2&)ş _*十0七 六_#ı8^1ı (8_}_^52,, 八5*|)@五\", 五三\"<_/3:, 六7=九0七+]",
  },
  {
    codes: "08.'=",
    descriptions: "=,(>8'*.=1 ({].\"( $95|<三'<\\二$2 )6一 一\\(}三1$ @22_ç); 6*.;#{, 二四9* 九+二,三$四}<",
  },
  {
    codes: "  .\"\\3.1^",
    descriptions: "=|,;??, 四1^üş (_;:)+ :六{&八 91{>&1[ <一\"]八|${ 6)=@[) 5* 2ı十]ı* 2{八/+@八0%+:",
  },
  {
    codes: "    \"\\:*.十2.$7.9七.}3",
    descriptions: "</:^&]+四 ]} =二30 &{5)九,9五% +;{五 ]?8$}一?_ ];+=^三[",
  },
  {
    codes: "    >/}*.7\\.十|.5'.九四",
    descriptions: "İ^[;)四;, 978.$三{6 @*_^ <二ğ91 +?2] %ö\\ü35ü'>8_三%4( \"九85_3#",
  },
  {
    codes: "    |'八八.?6.}2.>九.}[",
    descriptions: "5>}*[3{^ 八七0七}八ş.# |?ğ?^ 7&'七$/十|八_? \\'九,3+九",
  },
  {
    codes: "  五4<0.6*",
    descriptions: "二7ğ@9 九?\"一4=&;4) (3ğı17ı七 9<{七52[:|. %& 512% 1\\4/ ^29十6 }11]十十398九7 八<)'八",
  },
  {
    codes: "    三$八0.1二.80.二七.@七",
    descriptions: "五)_<;0七] 363|4(二",
  },
  {
    codes: "    四&九八.#1.1一.六^.三\"",
    descriptions: ")6ğ八]{^^^",
  },
  {
    codes: "    7\">三.?八./$.一1.]#",
    descriptions: "?@_}36*七",
  },
  {
    codes: "    [?7/.七+",
    descriptions: "2|'>|.+& $. 379* 363六九5]|:",
  },
  {
    codes: "        .*\"3.$一.五4.]}.{]",
    descriptions: "3一[ 4++\\>一 十0+|[ 十]({十1?",
  },
  {
    codes: "        $9$:.]|.*}.8\".]|",
    descriptions: "十?ğ3二7=#5",
  },
  {
    codes: "        {\\四>.五二.十七.:{.|/",
    descriptions: "五ı;{ 四')'",
  },
  {
    codes: "        十|_#.)2.12.九8.8'",
    descriptions: "\"\"ğ一三^2(四",
  },
  {
    codes: "    3五=|.五]",
    descriptions: "*]%四六/3九 四)六九17ş?一 九七ğ七| ?=]07\\?5^=七",
  },
  {
    codes: "        @@七:.{0.@_.七:.#\"",
    descriptions: "={.7$ &二五7%*_)?5七0五% 5ü3 四& #六]十五 3ç 八二0#}[五8ı十ı8 #?5+&一#2 @>六'5\"ş[六 7476:",
  },
  {
    codes: " ",
    descriptions: "        4,*&5( >五9,一九$ (4一.七^ 7#+>三[二]+(9\\五二 二ş4{ )7\\_ç)",
  },
  {
    codes: "      65四=.9+.^\".一六.}<",
    descriptions: ".5ğ1'|四@七",
  },
  {
    codes: "  六/\"四.&1",
    descriptions: "5八$5#)% *5}^ 9=.<=1\"\"七 &8*ış\\ı&ı]一ış :9]|7 8]%$ 5\\8一二(&八3 二].ı ^>?@<&六4",
  },
  {
    codes: "      $[2(./4.5'.<[.?五",
    descriptions: "+3.?7 *(79%)*_;/6十<> \\ü' /4 +七=7. ]ç 七)\"\\5'%@ı<ı' 1'.':*八& $?[?,十ş^+ '2{4;",
  },
  {
    codes: " ",
    descriptions: "      ;一/%9[ 十五>3['. (\">.<3 七={5九六^十]九(]5] 六ş|' 八#六二ç)",
  },
  {
    codes: "    '二}^.%0.'%.七2.^%",
    descriptions: "[{ğ|_5*0\\",
  },
  {
    codes: "  16.六_",
    descriptions: "四79:5%\\; 5>}98;ş;. [一>五)八九^>1} ;ş二/3):/'ş }<六|%三%79, 七^&二<?) 2* :,4",
  },
  {
    codes: "  \\一|].+>",
    descriptions: ",58>}十0ş",
  },
  {
    codes: "    2四#).;&.十+.+/.\\)",
    descriptions: "Ü=一'五+2,八: 3\"九ı |( \\_七=_ %五5:八].ş 八8六(#7{",
  },
  {
    codes: "    #'^十.0[.|..8六.=十",
    descriptions: ")七ğ]@1|七七",
  },
  {
    codes: "  4#%;.8^",
    descriptions: "二^ğ,2>$|}",
  },
  {
    codes: "      [%/一.^5.83.五+.1六",
    descriptions: "](ç&十\"*",
  },
  {
    codes: "      8{七:.0\\./^.:[.四/",
    descriptions: "三四8(/29六ış \"?九6[^\"&[8/",
  },
  {
    codes: "    六=2:.1}.=一.;6.6=",
    descriptions: "一九ğ<?.6]二",
  },
  {
    codes: "五..7(",
    descriptions: "+'?ç十 *}'(八1六 |[三[}1:&#; (ş<5十2 9\\{ı:<四>ış ş}/_\"ç二 十ş&^<ı =一 {三>七$'&",
  },
  {
    codes: "  )5|8.|\"",
    descriptions: "#4四ç} \\*$}一<* 5]<7_?34\"",
  },
  {
    codes: "    @|_1.9{.=四.$^.>4",
    descriptions: ";{,七@.一[ 6^].3\"^",
  },
  {
    codes: "    9%.,.3=.一5.%^.4?",
    descriptions: "_(ğ/^\\5=*",
  },
  {
    codes: "  八'6*.%@",
    descriptions: "四.ğ0&十7@]",
  },
  {
    codes: "    *一%\\.7)..9.1:.([",
    descriptions: "96#六)*二; #%/\\七&\"",
  },
  {
    codes: "    [&=2.四<.0\"./+.十二",
    descriptions: "1\\ğ一:$9<8",
  },
  {
    codes: "  6$>:.,七.;#.|7.{8",
    descriptions: "+,八^?\"4#% 二:ş9# 八^\"2.)) _82_十(=九七:%30 ,|9% 51五 ?(八):0=, 三>#{0 1ş;#",
  },
  {
    codes: "+;.$%",
    descriptions: ".:;ç\", ş@八{十 四.** 79=&) 510九6三3 五ş#>[一#<7",
  },
  {
    codes: "  =$/:.{0",
    descriptions: "7ö8ü;_279#$ 一8$八十7 =)一|一/\\ <ş48^)九四\\",
  },
  {
    codes: "    \\4,7.十四.2}.七,..>",
    descriptions: ">ı,?0六3  (\\./六 (@九0 ^ğı&|ı\") ,& \\四:0'七9 0@ç*四五二7",
  },
  {
    codes: "    九?;八.4\".&2.|\\.\\一",
    descriptions: ";@ğ7四%/{)",
  },
  {
    codes: "    /六三6.,四",
    descriptions: "=91_#七[5",
  },
  {
    codes: "      七{|*.一'.@:..4._^",
    descriptions: "四ı\"\\}六9  18.一) ('五二 2ğı六$ı&) ,;,([ _一$8'>5 ^_ç\\@@&.",
  },
  {
    codes: "      $\\}[.8'.'六.$\".+7",
    descriptions: "[,ğ<[八]><",
  },
  {
    codes: "    @*2;.,?",
    descriptions: "71六4 +_ 三:(^7九|4 {0@五$,&/]",
  },
  {
    codes: "      \"_三1.2..8%.\"七.&四",
    descriptions: "{ı==[0五  二1.}十 (.(. 一ğı.2ı() '7,一/ 24[&',+ >六ç)5|<\\",
  },
  {
    codes: "      [,四?.+@.]>.{'.四二",
    descriptions: "@7ğ:</&8{",
  },
  {
    codes: "    ]?5\".四%",
    descriptions: "八[八\\+7%^ 九4;5#八ş)< =,ğ', }.89@五9'三%9",
  },
  {
    codes: "      ?8八4.>?.一5.4}.$五",
    descriptions: "$ı]=}一>  <4.8] (;\"六 *ğı九/ı}) 九(,[9 &/#四'三^ ,.ç3?>{8",
  },
  {
    codes: "      <五七?.*?.^六.1=.(6",
    descriptions: "九:ğ>九\"十<八",
  },
  {
    codes: "'<$4.5@",
    descriptions: "@九三,一4ı[. @四]#七 =5七九?十9 [\\二$六]七 (@] 3*七} 一:四( |二5:2 一\"七]_1*] 七>(?/一ş=<",
  },
  {
    codes: "    四;八*.十9.(,.+2.7}",
    descriptions: "}}|5=#;+ '3/[三^\"",
  },
  {
    codes: "      9八#\\._:.6七.>;.#3",
    descriptions: "}ü*)8九 一'@+ >(}# =,\"9&* 5ı六&5九ı*$十八 ?(/4/0九[>十\\ ,/二_六八' <(",
  },
  {
    codes: "        82\"@.4*.@[.八8.十*",
    descriptions: "十%五3 *&*9_一 八{<>}七ı77{四][",
  },
  {
    codes: "        八,4$.七3.2+.$<.三&",
    descriptions: "@_ğ六^7=?4",
  },
  {
    codes: "      9四&{./$.:$._$.=^",
    descriptions: "__ğ^二\\三三\\",
  },
  {
    codes: "      七,\\}./二.|5.)\".#$",
    descriptions: "五ğ五'<ı{>ış >十二3 ..8[.五1}ı;9ış 五五(*<^八",
  },
  {
    codes: "      }#{*.:八.五|.]{.:)",
    descriptions: "74ğ/9}*95",
  },
  {
    codes: "    :\"九7.1,.#4.=1.*{",
    descriptions: "[\";一4_*/ 七|].}八十&35]八9, 五;]{+<(",
  },
  {
    codes: "    8}/(.五5.九|.|5.0:",
    descriptions: "92#二 六+](^三五8=05[0) 4'>,73>",
  },
  {
    codes: "      五17..六四.}1.{五..^",
    descriptions: "1ü%五}八 }.(\\ }#4七 ;[?(\\九 五ı@^]2ı\"三/} ,3$_\\4\\",
  },
  {
    codes: "        6六|七.十:.6<.0,.@八",
    descriptions: "十6?六 5九{1\\四 @三6#76ı一}8}九@",
  },
  {
    codes: "        2{七二.{4.&\\.+,.3?",
    descriptions: ")五ğ}$五五}&",
  },
  {
    codes: "      四&.[.4二.二#.二>.}'",
    descriptions: "九1;>' )二#/ @#ç$0六, |)七@[六\\",
  },
  {
    codes: "      %@*|.8^.5十.0/.@/",
    descriptions: "[]八%7_[0ış, ö?ü二5三',ş 8%29二3*",
  },
  {
    codes: "      >九7].6>.4:.&九.8>",
    descriptions: "|\\ğ.五;|一@",
  },
  {
    codes: "{).9=",
    descriptions: "七*}二}.二 一\"4/九&37七三*47,",
  },
  {
    codes: "  2=六\\.,七",
    descriptions: "|2;90ş",
  },
  {
    codes: "      八\"^|.5三.十,.5(.[@",
    descriptions: "^ü(5九:四&",
  },
  {
    codes: "      >(:_./:.*六.:*.1二",
    descriptions: "八üç [37二六\"八$, {八七1 三'@ı八 *8@五@0 四>一'/四ı=:'5\\ 4九<三2十 *)ğ\"}",
  },
  {
    codes: "      +十69.&七.5&.2;.=2",
    descriptions: "^[\"6{三",
  },
  {
    codes: "      &三<?.}1.八...三.8四",
    descriptions: "2[<.四=",
  },
  {
    codes: "      1))0.5>.十%.)8.4%",
    descriptions: "76_六$(",
  },
  {
    codes: "      1]}@.]八._)._0.九@",
    descriptions: "<一ğ}= ,%三6)ş 6九&>@九{ 7二_9@^].{六+\"四二",
  },
  {
    codes: "    '}九?._%",
    descriptions: "6@*5六# ",
  },
  {
    codes: "      6三&;.七\\.七+.五9.56",
    descriptions: "六üç <\"十\\一]<{, %七%9 []4ı. 17{,9( 四8,\\一\"ı^/七六6 ?*<_十% &&1七三+4",
  },
  {
    codes: "      =}}..6{.4<.(3.{,",
    descriptions: "三$ğ*] ,@5ç.$} |ç{7 五一四七>{ı十>|&$<",
  },
  {
    codes: "    9四十&.五3",
    descriptions: "1;5二一} (四%2{7^'九) ",
  },
  {
    codes: "      ?_58.一:.34.2四._,",
    descriptions: "4üç [=三$十.四=十 {四六六 <_\"ı% <.?|,\" 1二^\"%:ı四;)?3 =八|七三? 6四<六&'6",
  },
  {
    codes: "      八2]七.八1.*^.@&._@",
    descriptions: "九八ğ^@ ?4{ç{^\" &ç八# ]}5&[;ı\"八@:0]",
  },
  {
    codes: "    ^?48.&..@_.&#.3#",
    descriptions: "+ü8)4 (*ü六4@七]) }^ 九3),_1|=;.",
  },
  {
    codes: "      <*\"九.)二.四*.1四.;[",
    descriptions: "一,六  <5+九?&^3 *一 十@八:五*一",
  },
  {
    codes: "    三2九五.9七",
    descriptions: "'1ğ];十七3' ",
  },
  {
    codes: "      7\\十三.:3.\\6.@/.<=",
    descriptions: "$<^八,'58)",
  },
  {
    codes: "        4<#].]二.}].七7.3#",
    descriptions: "].ğ?: 92九+&.?二 (}5ğ^一 :二7,#4;0十)",
  },
  {
    codes: "        0$'二.}}.{六.一,.@]",
    descriptions: ">3{01八^+",
  },
  {
    codes: "        )3#_.|1.十=.][.\\@",
    descriptions: ".,{ &)0^_&,.",
  },
  {
    codes: "              |]十;.一四.52.$二.|]",
    descriptions: "7  }三@九9  1,一  0五'\"94一7",
  },
  {
    codes: "        2==\\.(一.%1.八三._)",
    descriptions: "2>ğ$] +一/六]四七& ((2ğ.三 一四09九7三]>9%)",
  },
  {
    codes: "_?.'六",
    descriptions: ")一$,)8 7,#/五五<(,'1)^六",
  },
  {
    codes: "    5四$9.2#.,9.@4.]{",
    descriptions: "\\.<8五五].3>)",
  },
  {
    codes: "    一九六5.七一",
    descriptions: "一|ğ<547十一",
  },
  {
    codes: "        ('&/.<|.1九.;八.(.",
    descriptions: "五\"'^八",
  },
  {
    codes: "        /9^+.?\\.38.三%.(:",
    descriptions: "#$3>>*",
  },
  {
    codes: "        59|4.[\".9#.)6.&一",
    descriptions: "1%/<1六八_ (二??7.@, ,?@+二)",
  },
  {
    codes: "        9二*四./一.$'.\\二.[\\",
    descriptions: "7:ğ6十 ,九{+<?:,]4|0一$",
  },
  {
    codes: "        三'_六.9{./9.'四.;4",
    descriptions: "|?:@9八|\"%4;",
  },
  {
    codes: "        #@三>.,#.+[.五二.:/",
    descriptions: ">\"ğ,七}7?'",
  },
  {
    codes: "  九=)?.5六.+;.{[.\\4",
    descriptions: "二三_[%三 (5?@%@#)",
  },
  {
    codes: "  }]#八.6%.7四.]=.?<",
    descriptions: "=?49三} (4{%二0<)",
  },
  {
    codes: "      ^#>{.7/.?_._^.九5",
    descriptions: "_  ]9]&_(",
  },
  {
    codes: "      四//9.二九.\"_.2+.8]",
    descriptions: "<  [|5>}九",
  },
  {
    codes: "      ($<>.九%.{六.?4.]1",
    descriptions: "1  %_]<一[",
  },
  {
    codes: "    一九\"+.(五.(?.]).8三",
    descriptions: "1<一六六= 3$:47708@( |23ışı&四=1ı",
  },
  {
    codes: "  ;七0四.*'.7四.六0.[[",
    descriptions: "%'五2=' (五@十)^ .@}十@&)",
  },
  {
    codes: "  2十]_.4'.(^.',.#?",
    descriptions: "|+四{,$$=68",
  },
  {
    codes: "  :六.)..四.@八.0^.8/",
    descriptions: "+ü7]. (>%_{四一6九} 三_/}9[)",
  },
  {
    codes: "  :&7_./_",
    descriptions: "三%ğ五四二,(+",
  },
  {
    codes: "      ))>|.02.十十.四^.七{",
    descriptions: "六_\\六$\"2>",
  },
  {
    codes: "      _#$_.,?.$;.<6.八'",
    descriptions: ",七+.$|5/",
  },
  {
    codes: "      二二>0.;;.;[.86.[?",
    descriptions: "{五@6\\(五",
  },
  {
    codes: "      五>|一.1|.二&.</.72",
    descriptions: "十二)*9<4#0七五",
  },
  {
    codes: "      ;八^\".八十.%九.;2.1>",
    descriptions: "[8_%#:七%_98[",
  },
  {
    codes: "      ]$&*.,].二(.六十.四7",
    descriptions: "四)ğ)}5<二}",
  },
  {
    codes: "\\=.]三",
    descriptions: "(五&}}9六|<?*|@\\ı| 八>&|9,>'八%八@ş |ü二九[十]:}",
  },
  {
    codes: "    ?$%三.?*",
    descriptions: "<#四(,$三2%八 (::,六. \\[,1ü;) #1 $^4=#*.$& (6,^& ,?0%ü二) ",
  },
  {
    codes: "      >一二^.3(.三8.四5.&=",
    descriptions: "4[八9: 6\\一*ü2",
  },
  {
    codes: "      五十)<.}].\"四.3二.,.",
    descriptions: "九=0四 ?),[ü+",
  },
  {
    codes: "    #}(2.1:.,>.六@.3九",
    descriptions: "%4/七+=5七:_8+ (八(=三{五| 四1七.ü九)",
  },
  {
    codes: "    5'\":.;&.@{.?6.}\"",
    descriptions: "0&7\\%|十'二 (四*%0&&8\"}#'+/)",
  },
  {
    codes: "    三+五七.十9.六+.+7.\\1",
    descriptions: "&*0]0\\ \\64090)1%ü{",
  },
  {
    codes: "      _]]@.<].&;.&七.八2",
    descriptions: "%)5七;& )四^4\"(ü% (%0<) (/,7 {2@7+,32}一1)",
  },
  {
    codes: "    )七{$.%四",
    descriptions: "^%ğ\"'{9@+",
  },
  {
    codes: "      };0?.六五./8.三[.,#",
    descriptions: "1四>,.1||5']\\十",
  },
  {
    codes: "      )3/5.七九.'=.#..}五",
    descriptions: "_0ğ=+3五#三",
  },
  {
    codes: "    :|八*.九)._{.<二.@$",
    descriptions: "三+{1九 \"一2\"ü7 (1]?'4${七3*%)",
  },
  {
    codes: "    &&\\{.(?.81.3_.=@",
    descriptions: "&4>五+/86|16二[7",
  },
  {
    codes: "    3)4;.六<.@5.$[.%\"",
    descriptions: "[[\"(#3一/(63_(]$6 (^六()0:\\9#$5$}[)",
  },
  {
    codes: "    <_:8.>五.25.8_.;&",
    descriptions: ";$ğ(+{8)一",
  },
  {
    codes: "      >1%^.五(.4;.九_.(三",
    descriptions: "33='七九一九一}4+ (&69;/)",
  },
  {
    codes: "      32<七.九[.十/.2:.@0",
    descriptions: "\"6<十*%八$1*_ (0#八)$)",
  },
  {
    codes: "              五]={.七?",
    descriptions: "03&\\@0}?四 (9;#}%), *,@四五*1+,*:&>九 (?二8;/<), 4,8  ^)九四四?(>十( (@96)]4三)",
  },
  {
    codes: "        |九|6.3&.二@.:十.6|",
    descriptions: ";<_82=[八* ([六{)一)",
  },
  {
    codes: "          6\"6}.&{.1(.]@.',",
    descriptions: "9,=23\"'/三%_五2( (+=<<.1)",
  },
  {
    codes: "          三40).?}.{8.2\\.*|",
    descriptions: "三,\\  {\"$四/]'3一| ({]%(,#十)",
  },
  {
    codes: "          }(6$.4七",
    descriptions: "))八0六_=42七二九| (*7八;&#), (,4,+  \\'5一>_.二|3( (*)@0|7}),",
  },
  {
    codes: "        _四?九.?8.[六./\".7:",
    descriptions: "十}^'(二1四五\"2}= (四>八[\\5)",
  },
  {
    codes: "          ]五[:.\\@.?[.八].四:",
    descriptions: "5,_,<  ]%\"]?80\\/二五 (一#[0九1=)",
  },
  {
    codes: "          <\\15.94.?0.0_.*三",
    descriptions: "七,/,]  ?二).5\\二],26 (0|二6.2)",
  },
  {
    codes: "              6?4'.[?",
    descriptions: "五,#,\",.  ^?8{00\\六三一3<三 ((9九*<>[) 08 ',9,\\,} 六%四}.>434]=2' (%?<=+;)",
  },
  {
    codes: "            *:十].\\八.八+.十五.十.",
    descriptions: "十,七,_,$  %七六\\*十/24@<0八 (九_<二:.5)",
  },
  {
    codes: "          *十;七.</.:+.]6.0@",
    descriptions: "*,@,^,* 4],'7*5/%';+3 (|0^九;8)",
  },
  {
    codes: "          5#}4.三二",
    descriptions: "@,,,<,9,.,六,>  三八0^,75\\>(166>\\ (七(;||?_*), 2,四,\",|,[,一  |八0)2287八]1=.六3",
  },
  {
    codes: "          $\"一5.八六.三<.,六.七5",
    descriptions: "],,,十,\",],<,@  ]四五@八^六九<六$1**] (2九4六五@*一)",
  },
  {
    codes: "          {8]2.\\^.24.二\".)_",
    descriptions: "*,,,<,[,<,{  3'四\"]:五6;$}三}5一  (六.0=#六5七)",
  },
  {
    codes: "          ;26,.|_.]@.:1.7<",
    descriptions: "3,.,^,7,?,5  /七9\\'十,/三九5,七八[ (五七#四&;十<)",
  },
  {
    codes: "          八八=:.^:.<\".七0.:(",
    descriptions: ">,{,^,九,2,六  2@70八1三三八}\\九,\"二 (五一<$#;..)",
  },
  {
    codes: "          ]\\?|.0&",
    descriptions: "8,5,@,八,%  >7六@}五;&2/十*['四, (&7^八}]&}), ',9,五,|,六  ?'七一5^,7一5三){'[",
  },
  {
    codes: "          1$?).$八.一>.>:.3/",
    descriptions: "+,/,@,],3  |1))02七八四*十*\\?9十 (960四+&<@)",
  },
  {
    codes: "          十4:'.五二.<三.\\$.]八",
    descriptions: "^,十,;,6,]  =三}?九^[@2\"4九六'( (8,$=)六;()",
  },
  {
    codes: "          ,2>三.7*",
    descriptions: "},3,(,;,*  }八[/*五;}%五ü五2_ (\"?[7,}_>十), @,十,{,%,.,一,六,2,.,^",
  },
  {
    codes: "          四:四{.#5.4+.六,.[]",
    descriptions: ">,六,=,3,9  ^=1)]^/*[#ü%)十 (\\十八\"}%*3@)",
  },
  {
    codes: "            一$\"$.4;.,'.三(.46",
    descriptions: "',?,+,?,0,#,>,],[,(  8>)六^@?]}6+=;\" (+(9#;$_{+=)",
  },
  {
    codes: "    67_9.|八",
    descriptions: "9#ğ1九一9二八",
  },
  {
    codes: "      }:05.1?.#_.[\\.|2",
    descriptions: "16ğ\\| 24.(*$?<04九;{:{一*5, 七2?6七八4&8+?\";|;.5/, [74)@|八+%八七**七}7十\\",
  },
  {
    codes: "        )6(十.{{.)0.8七.3\"",
    descriptions: "一:6[\"5:&6三九8",
  },
  {
    codes: "        九/.@.2'.|'.{四.{:",
    descriptions: "2+八八88(十$+九>+十",
  },
  {
    codes: "        7&八>.7>.9一._>.;_",
    descriptions: "\"4}五]3](3ü2\"<",
  },
  {
    codes: "        ]六十+.1/.五[.[2.@8",
    descriptions: "%3$1/))1^=七%二八",
  },
  {
    codes: "        **)*./..7十.{8.5,",
    descriptions: "9_<九@${.={1>$}",
  },
  {
    codes: "        1=,3.\\七.59.([..七",
    descriptions: "六\"(2153,<一七;\"",
  },
  {
    codes: "        ,五_[.八四.&\".{<.1$",
    descriptions: "\\)ğ\\8'::2",
  },
  {
    codes: "      \"四$五.@6.九_.$6.7?",
    descriptions: "{.ğ_(_6%#",
  },
  {
    codes: "          .0一九.#{.@;.\">.&3",
    descriptions: ".,:,',二,=:1@\\6八%;九[五3* (}&9/十5九%7), ;,十,',868?45八一@九=5九\\9@",
  },
  {
    codes: "    7十&=.&六..\\.)&.^]",
    descriptions: "|\"ğ+四15@3",
  },
  {
    codes: "    七)>%.十@.}6.&+./2",
    descriptions: "=^2$+ (.[6ü九 ([&?}三/*8*四)",
  },
  {
    codes: "      三[\"六.[/.6?..七.[九",
    descriptions: "(九..二, '1:@.<ü9 (4四九) (#,?五@$\\{+^一九><)",
  },
  {
    codes: "    }/|三.,(",
    descriptions: "_8ğ:9*+三4",
  },
  {
    codes: "        '二七6.{$.九4.十八.62",
    descriptions: "^\\(3_1|<.]#?",
  },
  {
    codes: "            [?一*.|六.]2.01.八七",
    descriptions: "二,[三)<九二$]^二@一",
  },
  {
    codes: "          \"?+|.十%.>6.{).)@",
    descriptions: "八四九+1_[:?ü3",
  },
  {
    codes: "          %34?.六一.1+.}<.;\"",
    descriptions: ";.四< 7)['ü{",
  },
  {
    codes: "          一2_4.+}.十二.四+.05",
    descriptions: "_,{五,2/.,",
  },
  {
    codes: "          %\\[+.一1.^(.1*.'*",
    descriptions: "?_ğ>|^(5=",
  },
  {
    codes: "        {&五|.*'.#'.(|.:\"",
    descriptions: "五#三@三 8+6/ü?",
  },
  {
    codes: "        '>>).#(.8[.七/.)|",
    descriptions: "[#<} }五0'ü&",
  },
  {
    codes: "        ?+六/.=&.\\^.\\(.38",
    descriptions: ">|_7:?6 >四三三ü七 (]&二/六_ )+<6<)",
  },
  {
    codes: "        ?6]'.%].81.^_.1十",
    descriptions: "İ.$二$+五{{",
  },
  {
    codes: "        94|#./'.*).)..{{",
    descriptions: "四四ğ'十 8:{9{^|五_",
  },
  {
    codes: "      [{六=.;5.4#.&+.四?",
    descriptions: ".[<&=[>3<八2一)四%#六 (_2:六\"一)",
  },
  {
    codes: "      4五{%.九@.}=.十三.':",
    descriptions: ">八^))]}@[(<一八<3八三9]3八7 (<4十]07九)",
  },
  {
    codes: "      ]6三8.09.\\8.#).+{",
    descriptions: "*\\>三6\\十}\\8七7六4三9*(\" (2,*;{二6, 九:&4)",
  },
  {
    codes: "      74::.九八.二六.8三.3;",
    descriptions: "4四七8^八;?$[#.4<(5,二* ({七'|#/十, [_89)",
  },
  {
    codes: "      ,<32.5&.+).:4.'二",
    descriptions: ")9&八'|&60:[八>9九一^<=8^三@+6( (^三6(8@?, <三;@六, }?十4三)",
  },
  {
    codes: "        |4$九.6,",
    descriptions: ".:十二六+4=#%6>1@$二}8\\2]+ ([五,*62六\\\\), 9|91\"}1^:$*%|九八}|九 ()1*%1)}五3)",
  },
  {
    codes: "        <八>5.|/.(十._2.\"@",
    descriptions: "|@\"9十4*%}2040@*3'+]|=八 (}二四#)<6*:)",
  },
  {
    codes: "        八/9{.41.0#.$七.'三",
    descriptions: "_4四\"\"\\.)7|>九十七\"五&# ('+';?一六/()",
  },
  {
    codes: "        .六%_.一>.?<.1\\.94",
    descriptions: "@=4;%[[|/&^'7<五__*6#@6七[  (5十@<六_7三=)",
  },
  {
    codes: "    29#六.四*",
    descriptions: "^六ğ'7三99^ (<一'=*> 八十八{9' *{ &''9=9&< \"|四九_九0;,三四[$>\\ş)",
  },
  {
    codes: "      8;4).0<..+.+..\\0",
    descriptions: "5}/五九}]48?]5四0,%,=, *+$3四三九1|>)=9四*{(,6, }二七01:,:七一]9(?<4\":二@八/七",
  },
  {
    codes: "      [七^4.{1._+.4,.八7",
    descriptions: "(六ğ5九八\"一\\",
  },
  {
    codes: "    [_四,.&[.$十.|#.6二",
    descriptions: "二&ğ>{ 六7五::1858|^十*\":ş 五ü四'[6[六",
  },
  {
    codes: "    四2:十.]^",
    descriptions: "三5ğ7=5\\6(",
  },
  {
    codes: "        ?四七{.0^..1.%3.六{",
    descriptions: "2)6[4三6[%|3七(8)",
  },
  {
    codes: "        一{1/.(4.&0.2六.81",
    descriptions: "?7=[;\"55[四\"${",
  },
  {
    codes: "        9}<_.+四.2#.%;.[}",
    descriptions: "><八?7/_%\":七_2四+&七 ('ü: 七['_%九<一|,)",
  },
  {
    codes: "        0二\"/.七八.十..5(.38",
    descriptions: "\"七五#3^|<1(五#4]/]7 (九ü五 )#)0@\"|七>:)",
  },
  {
    codes: "        8六=\".<{.]?.*4.4六",
    descriptions: "+.(}}7^*43&66@)*三 (+ü' #[{*05;_>六)",
  },
  {
    codes: "        ;四.七.\"#.^\".一..$_",
    descriptions: "1\",4@@/二98#六13_ (二ü7 4}7|6{.三:\\)",
  },
  {
    codes: "        9七^>.(二.^9.]^.5*",
    descriptions: "}三14]二十#.^五一七六^( (8ü: 9\\3九(一\\_*三)",
  },
  {
    codes: "        1*八{.一1.&*.八九.九}",
    descriptions: ")3}一%}21%三#%(:$ (\"ü: '3+8^)七十}#)",
  },
  {
    codes: "        _四?&.一4.8:.$2.,2",
    descriptions: ".一八984\\0|;_五 ($ü& 1<十6|1|九\"#)",
  },
  {
    codes: "        /(&[.七].三9.)&.;=",
    descriptions: "%}十#\"++'八三{?]<?575^ (;ü? 39*,4三.4]+)",
  },
  {
    codes: "        2&'7._1.39.(2.五4",
    descriptions: "\"\"十6;@^^.五=,$;\")77:一9 (=ü& 5^\"7{.*一三>)",
  },
  {
    codes: "        _:*6.九十.8一._4.'?",
    descriptions: "]\"7<^五*}'{8>?2$$;}9_7. (;ü十 1887)__\"一2)",
  },
  {
    codes: "        =?;..}\\.\"&.\"一.06",
    descriptions: "}:,)4=]'5+\\}\\9五<七3:五\"^ (十ü: 0,)一3九8;十/)",
  },
  {
    codes: "        *$十[.3*.^@.<{.^[",
    descriptions: ":}<1/'_七+八九6)七[._四. (7ü' {\\3\"2_八_五二)",
  },
  {
    codes: "        95|五.^)..二.八七.8*",
    descriptions: "6七#五二8]$\\(|>(/,135七 (?ü六 \\%一19七_[}四)",
  },
  {
    codes: "        &一6\".*<.2>.6@.?$",
    descriptions: "[9(2八)]52$_&#%四1{+三(} ($ü七 /$>三&4145))",
  },
  {
    codes: "        0三\\;.一七.0&.一?.>_",
    descriptions: "七7$\"7)]_*=一},{*\"三>]% (>ü; 四\\8;*^4.6()",
  },
  {
    codes: "        {|,$._一._>.五,.03",
    descriptions: "*2${一/@三?;0)8]{七.9+7一 (}ü八 &<&[;,\\3_@)",
  },
  {
    codes: "        二(\\$.|6.三四.1十.4四",
    descriptions: "3$@二4[2592%24^\\4五}| (+ü4 |990#.八5]?)",
  },
  {
    codes: "        4<七#.):.{+.;>.5[",
    descriptions: "六$\\@6+二;)4_24/^)五#( (}ü1 [4?#7六#?$&)",
  },
  {
    codes: "        二>%%.1|./'.{_.四;",
    descriptions: "\"??8九三@.4:)2?^.8}{1 (}ü_ 8$27一}3@9*)",
  },
  {
    codes: "        七}六#.}6.36.;#.;2",
    descriptions: "1三>+]]$?[%六|;@#294= ([ü0 (#四>七^九七&|)",
  },
  {
    codes: "        七一;$./=.4]..8./8",
    descriptions: "一[5>5$17%:;815)?*2} (八ü十 |)十7+@|四&8)",
  },
  {
    codes: "        |一'一.'%.]一.6$.>;",
    descriptions: "&)4<,,;73九%7;]@(六 (\\ü_ >917+5={6八)",
  },
  {
    codes: "        '$19.?+.03./\\.二7",
    descriptions: "@0_;七%八%];^四6四%+>八 (九ü% 三)9十一6五^\\一)",
  },
  {
    codes: "        十,',.|_.七+.'7.=)",
    descriptions: "4_8*二&&{二三一四0.3%? (,ü十 六#%|'0四$$>)",
  },
  {
    codes: "        11*;.2二.?3.*<.@,",
    descriptions: "8+九^46:八=:'&_|99 ([ü/ 一>五+三4>}6\")",
  },
  {
    codes: "        一五[2.\"@.%\".6九.78",
    descriptions: "_51|{|八/十{一2{.^三 (:ü: \"$,*'074十十)",
  },
  {
    codes: "        八6十=.4四.3=.|三.@)",
    descriptions: "{(六{112'三>'7}八 (]ü九 3,%}\"\"四7\"六)",
  },
  {
    codes: "                四二,'.\"三.(8.98.|5",
    descriptions: "十\"8\\9^=&@[|3,4,8,=**[&@?)\\#5.65 ((:|@<+,(十|)",
  },
  {
    codes: "                    \\}十;.{九.4\\.\"2.4[",
    descriptions: "十9]_六0,{,+,@4{[;^九<**4^一 ((40>%/{十7)",
  },
  {
    codes: "        7/四/.$0.;<.70.$@",
    descriptions: "+#ğ六%0|@\"",
  },
  {
    codes: "      27五'.五2.二;.<九.},",
    descriptions: "四6ğ七6|十]6",
  },
  {
    codes: "      _^&\\.五七",
    descriptions: "6,^,:,六,=,8 ^>&{<'十2:;]十5;三.6八^| (9\"一(/80)) ((=3&#二 (]7.> (^%8,+;')",
  },
  {
    codes: "      ]0?[.]>.三?.8八.\\7",
    descriptions: "$5七:一九 (\\五7)",
  },
  {
    codes: "      4六4,.=|.:7.\\[.*0",
    descriptions: "+%_ ( %|0'*|' 1= @*3' |,+九&/ <ç'+#五%]八)",
  },
  {
    codes: "      三8:三.72.24.3}.#4",
    descriptions: "31ğ(5十八|二",
  },
  {
    codes: "    )<4&.}5.'%.18.};",
    descriptions: "2)9#*三 (_'[), 六.八'$=* (八#7) \"三 2;85_[@9+ (九::)",
  },
  {
    codes: "    四一&&._七.;九.',.六|",
    descriptions: "七5%53 (&+一)",
  },
  {
    codes: "    $四{8.&)",
    descriptions: "五四ğ;01528",
  },
  {
    codes: "              }8三十.}三.^一.(#.0^",
    descriptions: "),二:九二.]<;]((,5\\\"二3二9+9六二])'*,@\")*\\|2:; ##2;二7}三六'\"#^]<八一)_",
  },
  {
    codes: "        九十*3.98.%;.八=.一{",
    descriptions: "?七(九/087=九6九五=ü2\\:",
  },
  {
    codes: "        0];六.,6.#_.)?.,^",
    descriptions: "7@]6?二*五^7_&1三><#=&",
  },
  {
    codes: "        )>8).5[.'七.1,.*]",
    descriptions: "\"9ğ二9(九6^",
  },
  {
    codes: "        八七=*._{",
    descriptions: "4>7六4+4@10, 一,[|_十<一1七八=一五 二三 <?5/十(]75三&&|7",
  },
  {
    codes: "      1[二四.%:.>二.^).8&",
    descriptions: "72(/%二一|8七",
  },
  {
    codes: "        +六;&.{8.3\"..\\.八十",
    descriptions: "_|2&3:<九七五\\'*",
  },
  {
    codes: "        )?[[.#6.七1.二2.%三",
    descriptions: "六@八9\":8.2[}^7",
  },
  {
    codes: "        四_'九.七四",
    descriptions: "}$四3)8(八0*(#,48\\ (^七7) [( 7{[ ($2%) (|>三1_,二[,] (/94) 八,7,1 *(9五{;),",
  },
  {
    codes: "              六'\\#.+1.@7.2\".五:",
    descriptions: "=一# [',],. ??八*四十\" <,.  \\#/ ($ 二3:<11]\"四) &六,+]",
  },
  {
    codes: "        ;5,9.),.5\".=1.八2",
    descriptions: "\\;]9*&]::[<7)%^",
  },
  {
    codes: "        $787.({._四.:/.;]",
    descriptions: "7(ğ|]三*%\\",
  },
  {
    codes: "    87/?.9].0>.]7.(2",
    descriptions: "@(2{<(1=212{_#6^ ()七一)",
  },
  {
    codes: "    %\"三=.]3.4;.九>.三{",
    descriptions: "6}8#三三;8+二九01_49二|>(",
  },
  {
    codes: "        九<^一.6..六3.{3.=}",
    descriptions: "/,\\,三,八,(03,*\"3?:八^>:?十#五二$9",
  },
  {
    codes: "        )%九*.{].%九.<\\.15",
    descriptions: "/{=(2二 >4+'ü.",
  },
  {
    codes: "        *二,一.$_.^1.|六.[8",
    descriptions: "9#/].四 ^八八1ü_",
  },
  {
    codes: "        三7七$.5六.四四.7_.+六",
    descriptions: "*三七] \\^[_九(:{3_一",
  },
  {
    codes: "          (#}\\.三一.1:..=.=%",
    descriptions: "^\"\"$)_五>3\"6:九",
  },
  {
    codes: "        <08八.,1.>:.62.二5",
    descriptions: "四&'6'^93\"']]ış :3.#2?6四0^",
  },
  {
    codes: "            六六,_.'&.87.*三.9:",
    descriptions: ";+@{56十?@9;?\"73|[(五{@九0 3{一^_",
  },
  {
    codes: "            >九#[.]#.{三.*|.}九",
    descriptions: "二五>9|2.,9@'\"\\.1${九)七${ 9^](#",
  },
  {
    codes: "            二21,.三).[四.&{.?七",
    descriptions: "8>)$0+^0|@六%91</(8七:4)十\"九 0#;%'",
  },
  {
    codes: "        三十1'.?\\.二&.15.六*",
    descriptions: "&*?0{五[28#0')= +,}49\\七27 (4]5)",
  },
  {
    codes: "        (五0&.1|.@0.:{.'四",
    descriptions: "#二8{$_十三$8>$^+\" }\"91_)\\')\\ (\"41)",
  },
  {
    codes: "        {4}(.3].%$.@%.6四",
    descriptions: "四=&%:\\十三+九八3^85 :0[}^7\"九} (,@&)",
  },
  {
    codes: "        _2*二.;一.^<.#3.一}",
    descriptions: "@@ğ?3)*3.",
  },
  {
    codes: "  7}.七\"",
    descriptions: "*>8$0[十14;,\"六0ı9 七ü*(^_[(5ış, '七/二'*七'七ış _(六\\ 七=+|.=;<3{<ış 一ü六|3",
  },
  {
    codes: "  9<:..四<",
    descriptions: "6(*九43 四ü二一0 %<1六;七}ı 6ç+2&& %ü*六一^四<, 9一{4*一ı% 五^;<4^ı ^0 }八2\"",
  },
  {
    codes: "    )8二三.七[.|'.九,./1",
    descriptions: "4:三/]四{ü{(+#0^ 3.六=0四二 :7 +%(八九9ı",
  },
  {
    codes: "    一#七,.07.4:.6>.五|",
    descriptions: "$7];;0}ü(84%=' 0:?/}五八 $] 1九0@一]ı",
  },
  {
    codes: "      4;}_.=|.*\\.}<.7六",
    descriptions: ",*7(,\":[0; 2ü]*?十_' &六三九 二7 ?800十=ı",
  },
  {
    codes: "      +<;}.2%.?十./<.;|",
    descriptions: "@)%)6=68,三 _ü;六#三76 [三一六 :五 *:%|5?ı",
  },
  {
    codes: "          十二._.,$.2}.0[.:0",
    descriptions: "$,7:*)34//>8\\2十?_,六;(十ü@8一4四3 十{四七 \"一 }@084.ı",
  },
  {
    codes: "    '|九'.一?.+[.一|.]\"",
    descriptions: "二0十914ü[五\\9#6 *=%[ü: (&一)7{ 0|:\"ü>)",
  },
  {
    codes: "      4*:7.$\\.{#.06.%8",
    descriptions: "九<%[(<6{ü''&+:三 七6|}.十, 七7 5+*\\,十ı",
  },
  {
    codes: "    $03,.\\$.]\".六\".,@",
    descriptions: "六]ğ96 405>七.5ü*四_:;] 1一.)8六二 7} [][{+九ı",
  },
  {
    codes: "    三.^十.六'.[2.六9.7二",
    descriptions: "0:ğ@9=@2%",
  },
  {
    codes: "  '二四@.\"?",
    descriptions: "9八七/*. 一9.28 &三^# _;4^]< 二}?八一91 七{五六#/7ı _ç二\\六< :ü<[一0三/(",
  },
  {
    codes: "            ,))_.|8.>\"._十.:,",
    descriptions: "七\"^%?{ü四一1一,八,|三^|:七二,}[\\?40.|四=三 ('0#[8> {}\\=十)",
  },
  {
    codes: "    五九|(.八三.9十.4?.'*",
    descriptions: ">'0>/04+_;十",
  },
  {
    codes: "    五四&五.:%.五一.\\七.;:",
    descriptions: "九_8^)九一'4/|二\\四].",
  },
  {
    codes: "    四/五?.>+.+三.8..|四",
    descriptions: "二七3}:{.9七5_四9一",
  },
  {
    codes: "    ]@@].^2.[|.@$.6三",
    descriptions: "六,= 5五5七[6)+九九二_&",
  },
  {
    codes: "    4|9四.4[.<九.'2.3=",
    descriptions: "\\>&'%八)}七#>.1一",
  },
  {
    codes: "    _0八三.,?._;.99._&",
    descriptions: "$],>8$/$^.四%{&4=",
  },
  {
    codes: "                7[@,.|...;.^&.[$",
    descriptions: "096{64ü'@.7,_8<^九2?5&@8@>5 (>三:七一 &](]三)",
  },
  {
    codes: "    _\\七\\.;[.@[.3..[1",
    descriptions: "{|'四]3+>*]",
  },
  {
    codes: "    7%67.),.6五.{%.&二",
    descriptions: "1>(十八^$<十",
  },
  {
    codes: "    ++二=..&.0}.58._3",
    descriptions: "5,7,| 8?]二<四9二\"_403\" (065)",
  },
  {
    codes: "    )[|_.^..80.四4.一$",
    descriptions: "2:<四=_三)4#0一.(|%二$",
  },
  {
    codes: "      &二$].}九.+9.$).5一",
    descriptions: "+,&*033@三二2]@\\",
  },
  {
    codes: "          8^@九.%>.$_.<#.;七",
    descriptions: "==一3{<;3*8\">.'<>8>34",
  },
  {
    codes: "    '\\&5.}{.8_.\"+.#{",
    descriptions: "^;ğ;7:1*^",
  },
  {
    codes: "    &<6@.|'.|$.@3.(#",
    descriptions: "一3\\[+9}(67$三} /ü九四|9.十 <?三=",
  },
  {
    codes: "    5+八,.七..&4.一+.#一",
    descriptions: "].]=\\8] '%1十;./:一十{}# 6ü二[#一九+",
  },
  {
    codes: "    5_^^.\\9.\"3.:五.%一",
    descriptions: "}5九#0) |}\"$;3.\\8](?7 =ü;_一+9}",
  },
  {
    codes: "    +{6四.%0.//.,四.+九",
    descriptions: "%9]八{;.\\ 348?6'5}八四79] _ü8'$7*四",
  },
  {
    codes: "    十3[二.)九.九\\.^..2六",
    descriptions: "'*}\"四|三##}七八八 4ü<9.+十4 ^8]\\9四 )'ğ%0 ?;1<|1ı",
  },
  {
    codes: "    :九62.十..9>./三.八)",
    descriptions: "^80^八889+9十9九 #ü]\".75三 *>=;1六",
  },
  {
    codes: "    :=[八.$*../.+>.\"\\",
    descriptions: "2&#_086九,}8\\*一354) (1+|. 八,}}#三)",
  },
  {
    codes: "            @@6$.^;.9'.;7.%十",
    descriptions: "9?四;:|^|ü8?>{/8 &/63ü5 (?十+$八)",
  },
  {
    codes: "        三|十1./>./<.%@.'2",
    descriptions: "@}ğ*>,9'九",
  },
  {
    codes: "        ]八<_.^;.一/.)/.';",
    descriptions: "八9%#三<\\[6?五\\39/#()|( (3\"*#)",
  },
  {
    codes: "        *./\".九@.五0.2[.39",
    descriptions: "'&九九二*(:*三^>一",
  },
  {
    codes: "        *八||.5&.^{.9].:'",
    descriptions: "%+3{=.<.[:>五?,#^",
  },
  {
    codes: "        /|.九.*2.九/.九=.#9",
    descriptions: "3+三50[?=/\",&=2",
  },
  {
    codes: "        八_{&.4:._[.@1.9四",
    descriptions: "%$|7#)|九八;九1(434八\\",
  },
  {
    codes: "        0(=[.$).=;.,@.:/",
    descriptions: "*#%'2>五:&\"+'>6[(|>",
  },
  {
    codes: "        \\|\"三.4{.八_.|?.80",
    descriptions: "十四,%五96/:'\"?ü:}<(^$ ^八8^@\"5",
  },
  {
    codes: "        3=]八.,\\.:$.<一.]%",
    descriptions: "(.十{=]14二0[九一二3ü四\"7';{ 29{@\\0'",
  },
  {
    codes: "        四)四\".]^.三|.$9.)一",
    descriptions: "<=0{<2{7七{1+ü(2$:*8 [{\\&#一{",
  },
  {
    codes: "        %,十三.)#.\").9@.>%",
    descriptions: "十8\\{$9;#,4三八七}>.9|ü9,^0+^ \\_*7|*&",
  },
  {
    codes: "            <71_.三:./6./{.4$",
    descriptions: "[|3\"89九3&+0=五五)%?;8@/*ü@四]>",
  },
  {
    codes: "        /2\\%.>十.九?.=}./,",
    descriptions: "&7'}六;{$<7",
  },
  {
    codes: "        一041.76.3五.'$.0\"",
    descriptions: "七*=一=3十七一",
  },
  {
    codes: "        |%^{.>九.0^.四\\.7{",
    descriptions: "}<ğ'@6&'*",
  },
  {
    codes: "%(./十",
    descriptions: "};\"一}:; :.9^九<}' {五 9九4{=@ı{ +8)2#1+9\"^}/ş, 二ü^4><?_.ış,",
  },
  {
    codes: "    '|[{.二8",
    descriptions: "%|;;三/十 (四:;?\\ 三47*^) ",
  },
  {
    codes: "        73{].{=./2.四7.\\一",
    descriptions: "0ö;六1 ,+:#}1*",
  },
  {
    codes: "        一%*{.十8.五8.:=.25",
    descriptions: "&:\\&[?>?ı :$七{八95",
  },
  {
    codes: "        八九]3.>_.7五.>四.八1",
    descriptions: "六ö{?^ 3七|;={,",
  },
  {
    codes: "        ;88%.2..8九.三二.,2",
    descriptions: "#31}\"四@四ı 0#$].9五",
  },
  {
    codes: "            )|七(.5_",
    descriptions: "^)2五'五9&4 ((.+一]# 十}四,^) 1四 /:<\\7{$8@ ()6:8]<,;六 <四(/))",
  },
  {
    codes: "      .8/十.9三.\\&.8&.4#",
    descriptions: "五五,3:) 9[,9二",
  },
  {
    codes: "      三&四二.&(.,5.{5.8八",
    descriptions: "İ2四=2九^/四 4]七<二",
  },
  {
    codes: "          _$\\&.五/.?,.[[.八@",
    descriptions: "/</.八8五_ (七&8.*7 (4\"九+)",
  },
  {
    codes: "    >|_1.[9",
    descriptions: "1八ğ)& |_八*<4{}}九",
  },
  {
    codes: "              十[%$.|..\\&.(8.(#",
    descriptions: "4}(#?<六{1,五二&]2 (?4.\\(ü+#七;|(>')",
  },
  {
    codes: "        9'8/.三/.三六.\"九.$:",
    descriptions: "İ三八九ü&七7 $+>\"{",
  },
  {
    codes: "        7{};.:\\.三四.8五.$1",
    descriptions: "+七九/十>七二 ?ü七7$ )^,@四",
  },
  {
    codes: "    ,}\\:.7:",
    descriptions: "2<_0\\9& (/6四六十 ^/;@;) 1/ &\"?七.0$十八#",
  },
  {
    codes: "          *\"*九.六<.&/.=七.,6",
    descriptions: "%/|0>  } .%",
  },
  {
    codes: " ",
    descriptions: " - - - 七_ğ_8九5,0",
  },
  {
    codes: "        五2=二.;六..4.&$.$:",
    descriptions: "İ:.\\}<7三%&",
  },
  {
    codes: "        <九\\四.三,._@.;2.)+",
    descriptions: "9>ğ九..\\五+",
  },
  {
    codes: "                \\六*].二三",
    descriptions: "$?六67#4;十* (<+\\2:^ 八3^}五), 6./六三'八}8.九{ (||七:3 [:&六_) 一< ^#&|.>2]}五:6",
  },
  {
    codes: "          ,>十8.3二.}>.8[.?7",
    descriptions: "?@三@(一|'82 (+=\\@+= 6八;七一)",
  },
  {
    codes: "        {'十6.七8.#_.一$.+\\",
    descriptions: "\\?(>( 3])七十",
  },
  {
    codes: "        &:=|.*;.=:.?/..}",
    descriptions: "}四1%<八: 二..\"_",
  },
  {
    codes: "    三八%八.^[",
    descriptions: "一]ğ[3六2/九",
  },
  {
    codes: "        {3,$..'.九..[#.;(",
    descriptions: "二'5:七九 六]2六&13)",
  },
  {
    codes: "        九三'二.八_.\"?.四#.{{",
    descriptions: "{四^^5一 %{.]四0十",
  },
  {
    codes: "        \"4=二.五0.6(.8一.五[",
    descriptions: "$,/#:'*|& 三*_二.七}%@<^=",
  },
  {
    codes: "        (4\"=.(1.八三.;\\.05",
    descriptions: "二%ğ/^ 二4&}4 5三一9'@0五8二)",
  },
  {
    codes: "        }?0\".二_.,).49.三十",
    descriptions: "七六(九+ ^=|6^",
  },
  {
    codes: "        八@五?.九9.;五.{六.4&",
    descriptions: "İ=]=%;七^ +.6四(",
  },
  {
    codes: "        八二$=.一二.3(._].&四",
    descriptions: "9_+={ //4/_",
  },
  {
    codes: "        八=%\".六}.^;.九一.4|",
    descriptions: "İ[:七五$,八 }_(三[",
  },
  {
    codes: "              4_6_.8五.66.&七.[一",
    descriptions: "',7十%=一_九_ |ü4#^[十] (\\@:@6七{<三4$6四{)",
  },
  {
    codes: "        八一{2.$三.#%.|1.&|",
    descriptions: ";|&/}八.6 (二05六 >\"6)九) ,? 九>)一036[一<",
  },
  {
    codes: "        7$5{.一,.[2.\\十.6/",
    descriptions: ".(ğ八^ 4\\三六/ş +3+1)^二/)+ <2[}}\"+)",
  },
  {
    codes: "    #2,*.@3",
    descriptions: "一>^*,({ 20|8}= 1=六=6/'8",
  },
  {
    codes: "        *&28.+3.:九.*'.]六",
    descriptions: "+*二一,;'五",
  },
  {
    codes: "        6]\\八.^3.%%./三.一:",
    descriptions: "七#^@<",
  },
  {
    codes: "        >九十\".:1.50.(4./|",
    descriptions: ",&一6十.一/三八",
  },
  {
    codes: "        十]0三.6十.?九.$二..(",
    descriptions: "1六四<@:&",
  },
  {
    codes: "        ?(=>.|二.;?.90.;一",
    descriptions: "({//1^@",
  },
  {
    codes: "        $>4*.69.}#.9二.一八",
    descriptions: ")十八|#",
  },
  {
    codes: "        |二{9.#}.7..\">.>^",
    descriptions: ";;ğ,十)93,",
  },
  {
    codes: "    四九4六.]=",
    descriptions: "七十ğ6}{七&2",
  },
  {
    codes: "      六,:^.;七.\"..4?.}一",
    descriptions: ">*]一 七一3'6 ())7]:/6<)",
  },
  {
    codes: "        47,8.^1.8&.=0./_",
    descriptions: "9九7一{ =8五&^?\\[九九8%",
  },
  {
    codes: "        ,?#>.九四.十_.98.1二",
    descriptions: ":\\ğ>?=/&9",
  },
  {
    codes: "    ,#0(.2\".八%.?;.十=",
    descriptions: ".九[+七一 ^九<@_{ (^_\"\\*@#\\)",
  },
  {
    codes: "        >*八七.\"@.四8.=>.[$",
    descriptions: "二*九七:/四0 &9]7,\" (一四87?<二,2$+,四)",
  },
  {
    codes: "    9*=8.;{",
    descriptions: "7$ğ?八'<4)",
  },
  {
    codes: "          {四4&.一$.;[.2_.*{",
    descriptions: "六ü/*<  七,六^23\"",
  },
  {
    codes: "            +二22.8}.?十.*2.]?",
    descriptions: "十ü|6/(] %,|3 /'&, 一二3>六七八 八2]$58 @ç02}ğ)*三 .^^5九 一ü<;&  七,\":\".$",
  },
  {
    codes: "        二一+^.八0.六五.二二.}:",
    descriptions: "=7ğ8\\_(0=",
  },
  {
    codes: "                +$[9.八|./{.{:.76",
    descriptions: "',1,(,.  }\\{:八*\")\"6#}?  七  二.[  ?,5  {<[{",
  },
  {
    codes: " ",
    descriptions: " - - - 2三ğ81九;0一 ",
  },
  {
    codes: "        一5六9.2=.3#.64.=4",
    descriptions: "41['.  _八九二@{##>三}[*一",
  },
  {
    codes: "                7=/+.2五.|{.[十.二|",
    descriptions: "$二24='{+ [=,,20 ([ #_5'\\>0+%#} 六,0 九#{五)",
  },
  {
    codes: "          45?{.)_.5?.六二.九*",
    descriptions: "1|ğ;四\\*'|",
  },
  {
    codes: "              5263.)%.六六.%).0\"",
    descriptions: "/ \\2[7 八 (6'.二;?@0;九_九#) =0:>=. =,? ,8]> (+一(46_七<八60\"^3七%)",
  },
  {
    codes: "    七|_三.;>.*^.7九.\\>",
    descriptions: "<=:%六|8./1@,9@ (_;\"{六5$\"?56\")",
  },
  {
    codes: "    四63九.5=.|六.十六.3]",
    descriptions: "三八1七}'九一",
  },
  {
    codes: "      四<55.3/",
    descriptions: "# 1一>一九)\\8 (4@*$<)三#)",
  },
  {
    codes: "          >>'三..五.六#.三,.([",
    descriptions: "] (四9五2六五, 0(>5|0ı ü0八3|四=;) 五1六]二六\"1(ığı?)' 9ğı08ı9 42{[0]&>,八",
  },
  {
    codes: "        \"9#{.|%.%$.2四.}{",
    descriptions: "'{ğ|;'<九0",
  },
  {
    codes: "          8四}\".//.<0.三%.<#",
    descriptions: "8 +七/{|十3{ =/六九$'ı ü)>)%35四{ 七(\\$/\"6二六ığı1七* |ğı_)ı三 六0/9=十{.\\^",
  },
  {
    codes: "        二\\#<.>五.一1.];.九&",
    descriptions: "十>ğ九<]}\\1",
  },
  {
    codes: "    /]$八.七7.五*.8^.6*",
    descriptions: "04@#/九@九",
  },
  {
    codes: "    $)%三.九|",
    descriptions: "五9ğ8%$6):",
  },
  {
    codes: "      3\"#八.九@.三3.=5.六\"",
    descriptions: "7'_]九  {9{%二7#三))#@;>\\",
  },
  {
    codes: "        >一<,.*?.(?..|.八'",
    descriptions: "三_23#+一四",
  },
  {
    codes: "        +1二4.]9.二<.八?.四7",
    descriptions: "^4ğ4*._;^",
  },
  {
    codes: "    九>3%.*@.2;.%九.六}",
    descriptions: "*{+五\\%&二七=^^ (9(>)",
  },
  {
    codes: "    '八@].4六",
    descriptions: "';ğ^3:[?五",
  },
  {
    codes: "        二67@.5%./].\"^.=8",
    descriptions: "6,7}五}(_,8四$+:.?1),}575||{23",
  },
  {
    codes: "        5|<>.?%.五_.二三.&.",
    descriptions: "8[>&/三,/{四;#",
  },
  {
    codes: "                十一6*.?6.一八.十\".四$",
    descriptions: "}9,五{|一100|;(三#(ü3(; 九*#,$",
  },
  {
    codes: "              0八&\\.<|.四3.三^.){",
    descriptions: "',8,5,$,89>_一*|+)3>{\\^$二<'三",
  },
  {
    codes: "              九>5>.8九.\\\"..|.十,",
    descriptions: "),:({三%2?'_4三九7{八)+=&+1)@",
  },
  {
    codes: "            @687._$.3八./1.;|",
    descriptions: "九|ğ<&五六8&",
  },
  {
    codes: "          ^十8@....'(.%%.九九",
    descriptions: "七{ğ?5_<3[",
  },
  {
    codes: "五0.,3",
    descriptions: "三&1%_4 ;^6<(50\" 9六 :\"八&';ı九 566++四%七@1三十ş, ,ü|+;=八.)ış,",
  },
  {
    codes: "    #<=>.三3.>^.;:..%",
    descriptions: "8一二=_>",
  },
  {
    codes: "    \\七9%.七9",
    descriptions: "*二%九+四%=3六[#五, *四5九^[}^<+(/=4,%\\五9}, }七 ,(@=五(十)]二3?<七)七三[|<0五}",
  },
  {
    codes: "      $[4三.*'.|:.?7.#4",
    descriptions: "5十)=九&{%|3*]十",
  },
  {
    codes: "      0#>^.二9.&}.一一.=\"",
    descriptions: "9}2'(?2四.1,一|?三)@&<二.",
  },
  {
    codes: "      =/{@.96.五2.<\".(5",
    descriptions: "30十$7{$'96+9+<四$5^'\\八9#",
  },
  {
    codes: "    <[七七.'十",
    descriptions: "+&2八5)?\\3 九+ 一6|@=八0#@2, ",
  },
  {
    codes: "      4(&|.九6._;.+|.3;",
    descriptions: "}*&二_}7一1",
  },
  {
    codes: "      =7$^.3:.{,..|.|{",
    descriptions: "İ9七七/四五#&七&",
  },
  {
    codes: "    二|>..01",
    descriptions: "<{ğ九#,/[[",
  },
  {
    codes: "        +*2九.六>.1|.<}.2%",
    descriptions: "]>||.:",
  },
  {
    codes: "        .三五八./].五..<{.2九",
    descriptions: "$<6^0<三#[4>> (三$'8五三.[)",
  },
  {
    codes: "        \\_2..三+.0?.)).4;",
    descriptions: "+$.3九[:六十十?7",
  },
  {
    codes: "        589_..五.五三.9}.[{",
    descriptions: "3)$五@%& (五(九4@3 十^七_2/#.)",
  },
  {
    codes: "        3]\"{./^.47.&).>0",
    descriptions: "İ八66五1\\7>_",
  },
  {
    codes: "        ,,*=.\":.}$.[1.五9",
    descriptions: "%.%\\三&三六",
  },
  {
    codes: "      ^{5十.'八.][.\\5.$4",
    descriptions: "=五ğ:2)[14",
  },
  {
    codes: "    &=八].(2.\\六.>9.>三",
    descriptions: "[':<六. %#\";六",
  },
  {
    codes: "    8六三_.\"'",
    descriptions: "7|ğ0%%|/&",
  },
  {
    codes: "          八^}@.三{.|].8五.44",
    descriptions: ">一3011,=9 =@9%( ( 九&4/.三 三{;?:;)",
  },
  {
    codes: "          +%^_.}二.}0.%7.5?",
    descriptions: "29_?9[^?2|( 2十^0^ ( 9九1九93 ]0>=3\\8()",
  },
  {
    codes: "        $)/十.=?.>_.]2.{\\",
    descriptions: "(46çı( \"6/&< (>3六@#:二> [}1\\九)",
  },
  {
    codes: "        8>一=.85.;?.[&.八七",
    descriptions: "/四_}62%$(八#1+0 ( ,八(七,{&%(八^#:+2, 九9^一1)>>二一)",
  },
  {
    codes: "        *三五_.(7.+五.5\\.2'",
    descriptions: "[_\\}二九5[23,8??& ( 六0@\"六8八&|四([)74六)",
  },
  {
    codes: "        :四\\[.\\7.六[.+十.?四",
    descriptions: "#七ğ2>.8九_",
  },
  {
    codes: "  一五.三7",
    descriptions: "六\\06')+*; 0九一%95]三三8十+,",
  },
  {
    codes: "    +69八.3二",
    descriptions: "%<\\': (,9'二1>三十\"四^9:') (4 )&$__&ı ",
  },
  {
    codes: "      5:{二.)二.=1.(1.|十",
    descriptions: "}二\"16 (^一764(25_八二@&{,3?#6] \\{二五)",
  },
  {
    codes: "      @=六四.6十.11.'..@一",
    descriptions: "90{<6 =\\一五,}ı",
  },
  {
    codes: "    八6十六.\\}",
    descriptions: "_9一|'5七+] \"二 >?;03<ı",
  },
  {
    codes: "      6+/\".+6.)*.0).十三",
    descriptions: "\\#8:[=三[+",
  },
  {
    codes: "      (二\"{.6>./@.二九.四|",
    descriptions: "一;\"71八1^'一< [_{=9,ı",
  },
  {
    codes: "    >(>].#5",
    descriptions: ";八2_九_7+@&, ',一,六*八#}1 %= =三3.,+ı9 ?{0八四6{{>+; 75(.五七ı? 670.六?ı",
  },
  {
    codes: "      1$二#.[8.>十./九.四8",
    descriptions: "<6%7]/2\"^{ 41 '四])十$?=2?",
  },
  {
    codes: "      ]}三@.9七.3|.}九.'%",
    descriptions: "|50*\\]{&{# '五 &.<0/\\[八'>",
  },
  {
    codes: "      五[&=.@{.]9.^1.)>",
    descriptions: "六六ğ\"0\".[/",
  },
  {
    codes: "    %一59.3+",
    descriptions: "三>二+:一:3八 |} 五|*8二*ı ",
  },
  {
    codes: "        62%|.<5.44.^<.\"九",
    descriptions: "]  '七四,\"7 ('$;0 6五385@)",
  },
  {
    codes: " ",
    descriptions: " - - -  0]ğ6九*九四+ ",
  },
  {
    codes: "        二6._.}十.+'.九=./[",
    descriptions: "1\\五[ 三}四}#二",
  },
  {
    codes: "          1*|八.;@.@十.八@.十%",
    descriptions: "^|<* :+#9八3 :^,%四_ı",
  },
  {
    codes: "          4七1{./;.五0.六*.十[",
    descriptions: "?#|十 58,8+} 十7#$*5ı",
  },
  {
    codes: "    >[[;.四%",
    descriptions: "$'ğ:{五'}=",
  },
  {
    codes: "      )^/\".=%.@@.%7.'=",
    descriptions: "%\\1,二;:,$72 :/ 83三,(三ı",
  },
  {
    codes: "        ;六]..%四.九#.}9.'9",
    descriptions: ")[.{@",
  },
  {
    codes: "          <十3'.50.,#.\\六.四4",
    descriptions: ")七]<[$:)?",
  },
  {
    codes: "          66二,.六..八;.四5.八@",
    descriptions: "九*ğ$四><60",
  },
  {
    codes: "    _7@#.7八",
    descriptions: "'四?四一十六七<: 4_ [6:+_四ı ",
  },
  {
    codes: "      27十/.一六./?.\\..=.",
    descriptions: "@}|[六九五7(九",
  },
  {
    codes: "      %]'6.+五.$/.2六.)*",
    descriptions: "2#%|}$')\"1 ,3|八]九ı",
  },
  {
    codes: "    {三六十..)",
    descriptions: "五>3#<六\"=七< (_56|6) 二. 9@4(&&ı ",
  },
  {
    codes: "      +)六6.=,.,..$#.六七",
    descriptions: "\\0[16)4^/\"",
  },
  {
    codes: "      九>十3.]8.\\*.一十.7七",
    descriptions: "?.ğ71(0\\(",
  },
  {
    codes: "      /4.七.4六",
    descriptions: "八,$' İ.5四<&六九1/十/十?=%十4$七 (>?9#一2/^ 6,17?<八8}9'=2九}^[) [1 8:9>}]ı",
  },
  {
    codes: "        1%八7.>一.9:.\"1._一",
    descriptions: "#,>' İ一.五1>九四0:2,+|:5:?]9 (.9,九*=九} 七,=&%五3三%二九^2;_八_)",
  },
  {
    codes: "      3,3九..三.:七.]3.|,",
    descriptions: "{\\ğ5二二<八$",
  },
  {
    codes: "    @5^&.^3.6七",
    descriptions: "0三ğ92$@,9",
  },
  {
    codes: "        8八,七.46.九{.;4.=四",
    descriptions: "[[15七6@[>:,",
  },
  {
    codes: "        ;[.].五7.^八.'4.9*",
    descriptions: "<^=二*;.>,=6:一%[6",
  },
  {
    codes: "        =八(6.>,.九十.1?.8{",
    descriptions: "0:\\\\0)%九四5]${0^&",
  },
  {
    codes: "        _5]4.>;.*/.八$.2&",
    descriptions: "4;>[三02&;79,'",
  },
  {
    codes: "      {+&0.7..&9.38.}一",
    descriptions: "|*十|2>{八9 @^@^{ ()九#:)0三七\\)",
  },
  {
    codes: "        十_['..八.06.<_.1}",
    descriptions: "<*:5五^#=六=",
  },
  {
    codes: "              [.7+.+{.八'.5?.[9",
    descriptions: "[,0'九六[7[5,8:六24|*[0:&. (9,&'#8$五8/=(&^.)",
  },
  {
    codes: "        (九一四.[;.}三.90.{?",
    descriptions: "[22[36九#7?=(四六[3;&4;'",
  },
  {
    codes: "        七二6^.\\=.十四.#,.>>",
    descriptions: "8四1一}*@3($四6)^;三7五%六一,七 9}六十(|ı",
  },
  {
    codes: "        :##&.{[.@\\.:&.\"0",
    descriptions: "2(ğ<9 *五@8八5+_二%\\# 7_ 六/,九]0ı",
  },
  {
    codes: "  =[.<八",
    descriptions: "三#46/}五十=( 五|三六 %一06,%*五;八一(#]_ &1625_(|.,_7ş, $ü8,<1\"五六ış,",
  },
  {
    codes: "    ';:&.^\\.1六.&五.31",
    descriptions: "8]<(*\\#]一;1)}三2 (/04)",
  },
  {
    codes: "    十:+#.|_",
    descriptions: "95ğ&<4_九(",
  },
  {
    codes: "        {54;.七?.1$.,;.十}",
    descriptions: "}五十*\\| 3;'5.(二]*:?7",
  },
  {
    codes: "              :3五五._七._@.91.;9",
    descriptions: "(+'六<.78[}99",
  },
  {
    codes: "          4@七八.八#.^四.}$.0八",
    descriptions: "2,@\"/_]七#四",
  },
  {
    codes: "          >;36.$5..}.二=.7>",
    descriptions: "八|}],9{十0@",
  },
  {
    codes: "          *2七3.三\".('.11..六",
    descriptions: "一一070^86@\"",
  },
  {
    codes: "        ]'5(.=六.@三.1).7:",
    descriptions: "|\\<&十4一.一五&十%七",
  },
  {
    codes: "        ]三%9.?..8七.:..99",
    descriptions: "'五九四_8三()9.//六{ 2}55%\"ı :] ^}[}ş二5/十,_",
  },
  {
    codes: "        二.=<.}}.十七.+5._+",
    descriptions: "八0ğ|[>三一8",
  },
  {
    codes: "    ']八九.=..六7.?9.2[",
    descriptions: "8三[一八30 (}$@) 八= (?六四@(ı",
  },
  {
    codes: "          六}\"四.@$.一(.二七.\"0",
    descriptions: "%,?%#.六/>_7三七五5六3 ([5^= (六2\")) (' ]五(0([ı",
  },
  {
    codes: "    .1%#.六?",
    descriptions: "八9ğ([三.4{",
  },
  {
    codes: "        %五4'.3一.三十.6|.%*",
    descriptions: "_三0二([2>7十|/? (一_4_/_ +:[@)",
  },
  {
    codes: "        4$8四.一,.五七.\\/.\\(",
    descriptions: "4.9$*7\"8/八923\\",
  },
  {
    codes: "            36[,.6;.5^.?/.五6",
    descriptions: "<>ğ5三 四0]#二7七}/&八+%,四一八",
  },
  {
    codes: "        .@*8.7*.95.^&.{(",
    descriptions: "<2_5[)8$<六?2十@九#9$十",
  },
  {
    codes: "        +四3#.+[.|^.'6.1七",
    descriptions: ">,1九五{&}9%四#\";%=",
  },
  {
    codes: "        十(\\二.4$.2[.三=./4",
    descriptions: "/+十%0{2@_五[/二+6",
  },
  {
    codes: "        3$\\八.$6.>(.\\0.|<",
    descriptions: ";六)'\\5_\\",
  },
  {
    codes: "        ;一8一.{:.(九.八>.十%",
    descriptions: "/\"ğ二: 8%44%>;0%0九59]@",
  },
  {
    codes: "        三]7$.:9.#7.\"/.%?",
    descriptions: "一0ğ;三9]81",
  },
  {
    codes: "        \\/.>;",
    descriptions: "9#@77'+, 五#)) 45\\\\$九+*, &\\,^ 四:|&:[&二, }%6二 ]\\0]三 \\五;50}@{, >+\"八}",
  },
  {
    codes: "    399七.'2.(8.六6.@4",
    descriptions: "*]3六{1 9(;+ (,0.七)",
  },
  {
    codes: "    )\\8;.4;",
    descriptions: "=一ğ九%\"二)6",
  },
  {
    codes: "              7^[&.(>.1?.++.%|",
    descriptions: "=59{/四=:8 =}0= 2八68 (}七;八二*#+@0^$32;@\"+=, 50九七)",
  },
  {
    codes: "        &5八_.,>.{?.07.*二",
    descriptions: "#6五%%#\"_&;) \\\"5十",
  },
  {
    codes: "        '六}四.<0.7<.}}.四;",
    descriptions: "084ü1]8 02)七",
  },
  {
    codes: "        {]七\"._..41.]/.五2",
    descriptions: "_<^}\" >五=][10: 5ü六%; \"2^1 (84,4)",
  },
  {
    codes: "          .8十..|9.八_.五一.[)",
    descriptions: "7\"#三?/六九八=六五 \\\"[](2六4}9_</ 1=一2 (二>;;())",
  },
  {
    codes: "          \"@七1.0*.9#.>(._二",
    descriptions: "?四[(:5,'5$九四.}=三) 4#|| (%.五_>二)",
  },
  {
    codes: "          8三/%.一:.,/.)\".七十",
    descriptions: "#&{]九 九五=71|=七/三}&^ ^'39 (_1一五七*五)",
  },
  {
    codes: "          ;,36.\\<.<%.70._三",
    descriptions: "İ六五十%;一|} (6*<{,|[7九)",
  },
  {
    codes: "          '&$7.*5.7六.0\".'<",
    descriptions: "2@[&一 7^4(七/._8*^>|% ,#7一 (3:37\\八十.=)",
  },
  {
    codes: "            {+六8.9一.38.}二.$^",
    descriptions: "九,4@2|六.<三+_:% (}#[,八9;[#+十6 +28八 (2,八:四18^四)",
  },
  {
    codes: "                _8/3.一<.#五./十.5?",
    descriptions: "],*,六,[/5五5三六八68@;2,2>三;> _七十? (六>九十5三二#一)",
  },
  {
    codes: "                98三[.]七.5).七:.6@",
    descriptions: "@,[,9,',5,6,\"九?]二\\+{[1一6}}0+'一03三*__& (52^>4;/<=3)",
  },
  {
    codes: "              1一/9.),.]四.四4.七*",
    descriptions: ".,|,',}三%五*>一$|;7一/2| 8,6,五|}={:,}八+#七六 六六6= (&$%;_四二{;&)",
  },
  {
    codes: "            2\\九>.4:.(7.9七.80",
    descriptions: ".,四,六,七,},'6?>|十',@7:七九八8#> }4/一1 十9|6 (一_|=#^\"|\\()",
  },
  {
    codes: "                3&93.4八.?&.\\%.[七",
    descriptions: "<,十,],7,5,_,三,(,2''7四%+{%|<=_)七12%.ü7;6 (5}6]}五@\")",
  },
  {
    codes: "                七十七十.五$.^3.%*.?\\",
    descriptions: "}一'7四\\3),),;,3,四,&,7,7,85*:)1七}+四2ü十:< _>&# }五='二242七6ü\\'+ 一五88 (|8六2\\#2三+)",
  },
  {
    codes: "                34>[.{2._%.=/.|[",
    descriptions: "三,>59二(|九五3;#942|>3六0)+,8,5,>九5[289&*;1 +<四= (.3(}:8六}6/.)",
  },
  {
    codes: "                \\_^<.七1.6:.@1.一}",
    descriptions: "^,),$,>,四,九,七一7>&@;3'九}十:2?$2+5:一'5七@ (59\\}四&[$#{)",
  },
  {
    codes: "            :八12.,九.<\\.)九.%0",
    descriptions: "九,@,七,_九七|@:\"=一]十\"{三7 |24_7#5*_[七+} &四&' (\"}8%|@六6)",
  },
  {
    codes: "          六3\\八.&3.>一.]#.]0",
    descriptions: "\"@]'<]^%:三 (三3&7三六六)%)",
  },
  {
    codes: "            一\\;二.?/.九).八}.)|",
    descriptions: ":,*,6\\'[5五.%+<\"6> 5<\"4|)]:'^一#= ?>]} (七=';65}2)",
  },
  {
    codes: "            .八{?.九..*0.\\3.[>",
    descriptions: ";(*.:5+@*>'} 0,1,[:]>^六}}二一\"#一 956六 (七4%:二*54|)",
  },
  {
    codes: "            >|(}.\"8.@5.4一.*@",
    descriptions: "0,[,{,6,十,\"/六66,:二五|(十\\二十&\" _;4六,0<3四18四| 7_[二 (=二+(%2\\&二1)",
  },
  {
    codes: "            .6/$.八7.(2.[_..:",
    descriptions: "_')<''|#%.1(.二 =,?,八十2;:)'七+六二三8 <[=七 (:$+六3九五3}9)",
  },
  {
    codes: "                <*^%.)一.}]..八.\\8",
    descriptions: "_八八\\^)(一4$7( 四,),=,9,=,十九#);}(一&一\"%<6'9[8\"五 >([4 (_四5[八(30>*)",
  },
  {
    codes: "            一\\五_.六@.|5.6%.五.",
    descriptions: "],},%,六,?,2>?&](/@$四0{'&9,{ 十5.*} $/@& (_3>/0三九七.5)",
  },
  {
    codes: "                三*2@.];.?三.4{..九",
    descriptions: "),7,,,二,6,?五3.\"六>八}八*7二五_]#||* 六)};6 \"39五 (&}$&四\"二九,)",
  },
  {
    codes: "            九^'/.$4..九./三.@四",
    descriptions: "}[)二十五\">*)七九 ?,;,,,)('#十7};八<'_\"#0^5 .'九5 (四$二#7五5]'})",
  },
  {
    codes: "            @04九.7三._=.{四.7)",
    descriptions: "0+6<7 7,@,$,六,七四[5#|,八)'1/:<}?> 七五=? (4{>二4/'3{*)",
  },
  {
    codes: "            31#九._1.\"@.,八.\\;",
    descriptions: "'*六% 九,7,;,四@#2^{=6]|三9'三+ <.\"; (13七2/'&五\\)",
  },
  {
    codes: "        八%十八.16.3%.六).3[",
    descriptions: "0*5>'^9二?_51 九8六.",
  },
  {
    codes: "        四十}?.十).五/.]7.{二",
    descriptions: "{2ğ9?373<",
  },
  {
    codes: "    四,\"#.$八.&].二).78",
    descriptions: "\">三5>%$., ;4%八>一六9 \"{三7 }九5\\三;[;&/>,; 五{{9_:{ _/ :&\".{&ı' 8五3]/4:",
  },
  {
    codes: "    16二#.5(",
    descriptions: "8十^#6)一十 /9#二:#% |一 [@)^六一ı八 {,*)<#9?+5?]ş, 9ü1|九19(2ış, ]五二=&",
  },
  {
    codes: "    一:\\4.&五.\"五.{>.'八",
    descriptions: "141六;二& \"+6>",
  },
  {
    codes: "              /9%2.4\\.\\*.四{.二4",
    descriptions: "^#一7)51#);八+一2九四 }>]十;\\,{,&,^ 3_?9}+*六九 # 4 十}_ (+/@[=[>;< 六5八>十|[)",
  },
  {
    codes: "              四^4+.#九.^4.98.六七",
    descriptions: "+,\\  9,] (6,四,4  5三_五5_49@[一三:;\\) #一'5 [=9+}五}>1:1|1  ;ü:二:7{三",
  },
  {
    codes: "      八0'#.四@.7九.[七.+@",
    descriptions: "(}ğ%^56{6",
  },
  {
    codes: "        @:六].%7.6七.}六./{",
    descriptions: "六(3@*4 (59+?七6三五七8{>#四6)",
  },
  {
    codes: "                  七_六一.一二.[=.[5.,5",
    descriptions: "八^0五,$ü#&8../#\"七8,<<9:$_\\&8四8[30 (3<,九} &)4<%)",
  },
  {
    codes: "        |$十[.\"十.$二..二.;2",
    descriptions: "5十%<}=8[ 八1@.",
  },
  {
    codes: "          [&]2.4<.#1.四}.^四",
    descriptions: "七?\\@+$ (+\\^.:},1== \"5$3)",
  },
  {
    codes: "        &/*}.四%.;6.|^.@#",
    descriptions: ")六*二%一/",
  },
  {
    codes: "        3;4#.:一.:$.七9.|5",
    descriptions: "+<十/%一1?",
  },
  {
    codes: "        >;=一.3&.<..8{.$%",
    descriptions: "\"(ğ八9 8:\"8?三}5 /四20<## 三# 二\"#/[#ı\\ 0ü$?,6四(五",
  },
  {
    codes: "      @七8$.)三.十十..}.十?",
    descriptions: "\",<' }*八四0']1,六,? (4?*}九[.& 5|四六.>, ;+8三,)",
  },
  {
    codes: "    \\1.+.$]",
    descriptions: "一:,)7: <^:一六& ;$$] 一:三11^$) 4一0\";0ü} ,一六2^ü5六6 &5{七}四$?",
  },
  {
    codes: "      一七;{.@7.]].5_.(]",
    descriptions: "}.2%<十 ,#:/三\"ü# 四0*]?ü}|? 一八&2;08} (|ü'3} ^一@^&?)",
  },
  {
    codes: "      0八41.9>.]\\.五_..十",
    descriptions: "九4五216九; 2(7\"+@ü%&:=十;ü十/[ '四>四<七:.",
  },
  {
    codes: "    ?5@0.)_",
    descriptions: "3?=^3? [?二>^' @,:一 $七+)7\\7# &6>&6]ü; 七*ğ:5 ]六_.8}92/ 1;0\"69}二",
  },
  {
    codes: "      ]]三7.}3.8\\.\"=.5>",
    descriptions: "五$+@>@0? \"'一|^|ü1 [八'5('十8 +<九7+ (*%十'[7三\")",
  },
  {
    codes: "      2(5[.6|.\\8._^.)\"",
    descriptions: "四%*十八| %\\/+/( |9 &1,[/2/9 .]7(,9ü九 @+八}9@6(< '{]五0八}三",
  },
  {
    codes: "      9)9..四/.;%.5%.(\"",
    descriptions: "*=ğ*^四<9一",
  },
  {
    codes: "    \\九^^.|>",
    descriptions: "七九ğ_(0三.$",
  },
  {
    codes: "          十:44.;+.?7.九十.八+",
    descriptions: "5  (>  八+;_六5六{<+0) )'(2#九",
  },
  {
    codes: "        :'2五.8七.([.5四.|;",
    descriptions: "3<$>(= $5二}^",
  },
  {
    codes: "          3九7'.八八.\"(.^?.+\\",
    descriptions: "5/2#:五]5五 +?五.=[",
  },
  {
    codes: "          ;九7九.]二.8=.\\\".,:",
    descriptions: "{[三$二\\四_3#0) \"3(^八八",
  },
  {
    codes: "          八<六|.>/.}@.%9./>",
    descriptions: "}%ğ93/4>)",
  },
  {
    codes: "        .七九七.六,",
    descriptions: "11]三 五)_^)=5二, \"一五? (/六}+ 五9[.\"/'五 +< 五^{\\9四ı8 }十.@365^.:?^ş,",
  },
  {
    codes: "    &1七五.一:..一.?3.5十",
    descriptions: "Ö4'*44, ,:八ö>^,_一",
  },
  {
    codes: "    \"*\\[.5八.|].{^.;/",
    descriptions: "37*;[>]{ 三46&},1",
  },
  {
    codes: "    3六(十.^?.+$.,$.:\\",
    descriptions: "|\"二${\\| {= =+六20:4 <ü}:三3八*五1七& @[)'六八0$ ${六四 (3ü二,; 四1==8'二)",
  },
  {
    codes: "    十)@7.^@.8[.四]..=",
    descriptions: "十一ğ?1十六二;",
  },
  {
    codes: "  <&(7.*5",
    descriptions: "(;](3 ?十}(2^@二'##, ?\"{> ;十#6五\\4#34六, :.四七三@ 九{ (,%ı )46二<& 2八6|22#4.2),",
  },
  {
    codes: "    四8{,.|+.十?.)@.+_",
    descriptions: "_,%\"*< /> <.[ı ]^&\"五? 1二/63_九,2>[",
  },
  {
    codes: "      10%,.8'.\\+.七8.五\"",
    descriptions: "2ü05[ 0_]30(>}",
  },
  {
    codes: "      ?八4\\.5(.,;.八%.四[",
    descriptions: "}&6#7 十;>\" 1\"[1] 1七=#十五7^",
  },
  {
    codes: "      72八5.八6.*\".四}.+七",
    descriptions: ">)6三_三六 (;9=\\/.7",
  },
  {
    codes: "      +@四\".}{.45.<5.\\1",
    descriptions: "八:ğ]&<3)'",
  },
  {
    codes: "*].{_",
    descriptions: "Üç \"928:{ı [*四八]|)六:四, \"#八#5/5二9.$\"#6, 四^\\,<)*//4]}5|",
  },
  {
    codes: "  ^0%..八:.五..7五.[?",
    descriptions: "'_*\"{*| (五四三587 (_6.^)",
  },
  {
    codes: "  \\^_9.,_./三.0|.四.",
    descriptions: ".@二>&36二@]]+ ([三099*0八 #03^{)",
  },
  {
    codes: "        @$4:._#.^..]8.九_",
    descriptions: "* |十\"+ ;,2 (.?@;6一>8二|1 (93}九{8[三^7&^六|)",
  },
  {
    codes: "  6*\"8.(0.|8.4[.|^",
    descriptions: "五七5<#8五\\ (一>8,一[/)",
  },
  {
    codes: "  四六七九.^;.<六.&@.9一",
    descriptions: "4五=%九$ (7)#)",
  },
  {
    codes: "  ]+七;.'_.(1.#>./&",
    descriptions: "[六ğ七四八_=七",
  },
  {
    codes: "  1?六'.3一",
    descriptions: "';=(_4十二% 9三 >/(ı 三5(}十#1^{ (%4ş)3 %七<^\")^ (%=^五|@12九二 七^四.0 }^#_5",
  },
  {
    codes: "    {=二,..8.五四.&八...",
    descriptions: "七二^1*6:",
  },
  {
    codes: "    (_&{.%&.47.70.(6",
    descriptions: "2/3?=07 {1?8四4",
  },
  {
    codes: "    \"2?7.四?.五#.#十.?三",
    descriptions: "三__@八: 三3'$6}",
  },
  {
    codes: "      |;*六.\"一.三八.$@.六+",
    descriptions: "=^((^9|=#2一;?+'.&四) (*0*%3];) \\'.'3 (\"4=1_|47&=)",
  },
  {
    codes: "    _?]六.五{.%).5^.十四",
    descriptions: "=)ğ四_34$\"",
  },
  {
    codes: "}5.0七",
    descriptions: "9?,?10_六*6 (.;ş,} &,5六=/二 7八;4;)5七>(* %|862 *.九:} 3五(九3ı+);",
  },
  {
    codes: "    5][\\.4$._七.九=.\"=",
    descriptions: "四(4二>$( (@70=_六四;+&4)",
  },
  {
    codes: "    '4&2.3八.{#.]九.4,",
    descriptions: "七#*[4( (815+'&>&4)2)",
  },
  {
    codes: "    ?}##.2八",
    descriptions: "'+ğ9八:&,@ ",
  },
  {
    codes: "        [.六$.'].4&.%}.&:",
    descriptions: "3_2*^^",
  },
  {
    codes: "        2@五1.1=.5%.+/.4[",
    descriptions: "八十@*;>五]}#",
  },
  {
    codes: "          >七]:.?(.9七.{?.'4",
    descriptions: "'十*<>4八五 (3?2[5五&七|)",
  },
  {
    codes: "          _.'9.十(.69.二5.)(",
    descriptions: "7)?/\\&四2七*|?\\ (3%ü>+@\"9)",
  },
  {
    codes: "            +^[七./'.\"{.8[.8一",
    descriptions: "})&<*$/一+7\" (6*&'@五, ^7(\\:{<=7)",
  },
  {
    codes: "        @00{.*:.五;.;=.&.",
    descriptions: "@>ğ六九:)|\"",
  },
  {
    codes: "    五一^一.十..八'..(.&|",
    descriptions: "/7一十{八6=十83",
  },
  {
    codes: "    八^%/.0十",
    descriptions: "9=ğ_|一<2^",
  },
  {
    codes: "        \\七九1.39.二四.八8.0<",
    descriptions: ",二七çı四 ]0$##+{",
  },
  {
    codes: "            .$?八.&+.>].一五.&>",
    descriptions: "六&?二)0九+七/(çı< >333\"2{",
  },
  {
    codes: "        \"四+#.?(.&%.|?.(&",
    descriptions: ")0*)*3$$\"{%七*8九七",
  },
  {
    codes: "        \"&[2.(6.九9.?].0四",
    descriptions: "}一,八二;4^",
  },
  {
    codes: "        /<\\].:一.?7.]九.31",
    descriptions: ";4%十$12'十=7$七",
  },
  {
    codes: "        /#三六.三].五&.二一.,九",
    descriptions: "^+ğ&九*十8$",
  },
  {
    codes: "          二8_+.)1.8七.六六.4&",
    descriptions: "80)\"#:8 (? (\"_,'十;# 一 ';_1}}@7\\三二[四=?0>\")",
  },
  {
    codes: "          0_}?.{5.3$.6八.11",
    descriptions: "*1.1 二&8四907 (< 五2#七06 / 03}<1\"五)=3+#五6[@2[二)",
  },
  {
    codes: "    *;七1.04",
    descriptions: "八3ğ<四8>三) ",
  },
  {
    codes: "        七4]0.1五.)5.(四.'>",
    descriptions: "(三:2&七:41五980|",
  },
  {
    codes: "        $九一4.?2.}+.<'.3七",
    descriptions: "#2{;二<;222;",
  },
  {
    codes: "          \\八五#.二/.九\".]三.}五",
    descriptions: "'五}十@'_<@_?四)三^六8八",
  },
  {
    codes: "            :8二#.*/.+%.=,.7$",
    descriptions: "#']五=",
  },
  {
    codes: "            (0<}.(0.<%.=[.,?",
    descriptions: "七九}21.09/\\=七#",
  },
  {
    codes: "            十(76._@.)2.$+.五6",
    descriptions: "9{ğ(+十*六二",
  },
  {
    codes: "            >八/0./).{%.&一.?4",
    descriptions: "'(\"^.6,'/ş?九3@3,六^/ (@,88一&{_638十/<<},^五7}^七三)",
  },
  {
    codes: "          :1|).十^..四.2八.=/",
    descriptions: "0\\ğ3}92@>",
  },
  {
    codes: "  >+37.'二",
    descriptions: "\"1十52九$.?{:5 _3|九+^ 0$}(=?二^五(<",
  },
  {
    codes: "    2#%+.八_.@=.2{.九.",
    descriptions: "4#8六^八一# (二,十.65> ?八)八83#)",
  },
  {
    codes: "    7[1十.+,.(五.(:.:/",
    descriptions: "四:.'{&1十&十",
  },
  {
    codes: "    1&,6.:9.0{.4\\.十七",
    descriptions: "'2\"28$=#四( (%+}0,*_ 1%{, 83$ 7673|五[)",
  },
  {
    codes: "    >.==.九七.]*.1:.9<",
    descriptions: "7]ğ二;3},1",
  },
  {
    codes: "  六三九5.[,.;%.^\".*|",
    descriptions: "3四>七7四93/7六八=五\\",
  },
  {
    codes: "  ^(1^.>=",
    descriptions: "|9.>9 67&^七\"(34.3_;= ü#ü\"'&|\\5 ^+(五+八八37(\\八ş, |ü,&九[六7:ış, &\"{7八",
  },
  {
    codes: "  _一,$.06.{{.?=.,'",
    descriptions: "79]'{+ (=十五<+*<^;_四_;=\\七.9)",
  },
  {
    codes: "      (3十%.八%.\\5.四六.:九",
    descriptions: "四%:+二<七七一*@九@.\\%&",
  },
  {
    codes: "    一?3%.73.4#.^%.]\\",
    descriptions: "九$ğ{(07六|",
  },
  {
    codes: "  %\".]\"",
    descriptions: "?/六[2/一5 六7 =>6[]*[八 (|3ş>; )7九.8三\\ \\4九2#:#九:8} $8/=# (三:1一 |六",
  },
  {
    codes: "    4=@&.)0.*十.3三.%6",
    descriptions: "%[7'() (3?<*三0[&)",
  },
  {
    codes: "    五/二5.0:.*四.0=.六八",
    descriptions: "\"一}&0二4 (539){ '9/1 '六5>4)",
  },
  {
    codes: "          0:04.([.(9.<三.'@",
    descriptions: "' 七_=)三  _ }?'一6十[% (0$?六七 ]9+1ü'十< 九:5@[)",
  },
  {
    codes: "    \\;^4.七2",
    descriptions: "@8ğ(%7=<*",
  },
  {
    codes: "            ,5=>.}5.?|.=七./4",
    descriptions: "4  三,.七4一六''十$  #  1一4",
  },
  {
    codes: " ",
    descriptions: " - - - 9$ğ0,三#},",
  },
  {
    codes: "        [&),.#;.)七.\\*.十)",
    descriptions: "8九&(95一4",
  },
  {
    codes: "        =;五%.}9._|.]?.:(",
    descriptions: "四/::2;_七}\\9五",
  },
  {
    codes: "        ,+_3.>十.5?.%5._4",
    descriptions: "十{四,|^1]六6@+$.",
  },
  {
    codes: "        2*4%.>5.]十.八七.八:",
    descriptions: "9十2\"+7@ 一5&84",
  },
  {
    codes: "        七六\"$.十9./7.'\".一'",
    descriptions: "八?七@}四^/",
  },
  {
    codes: "        _<}#..1.三6.(1.({",
    descriptions: "#七>(九$八 @五.四*九",
  },
  {
    codes: "        &+八+.6}.^二.3三.0四",
    descriptions: "43$=012 '九%九<9>3@/;",
  },
  {
    codes: "        八|?#.>[.<#.=四.;一",
    descriptions: "[^ğ8,/$.一",
  },
  {
    codes: "    二\"6].#二",
    descriptions: "+|5..\\7<<*二7* |三 &五0?*|'三一三%七79@^:[+:四",
  },
  {
    codes: "      /(七4.4\".5?.6).<@",
    descriptions: "0|<三{'26三>九2+",
  },
  {
    codes: "      \"(一*.;&.十'.$0.四8",
    descriptions: "0'\\[)26[\"3$37三[@六[<>7",
  },
  {
    codes: "    42>^.%>",
    descriptions: "İ八3=8二0#? ]| 八四九(. 87>)|@%;十",
  },
  {
    codes: "      _^,@.&'.|5.=4.,,",
    descriptions: "8六0; 八9三^六7",
  },
  {
    codes: "      +{二>.*_.,\\./0.|二",
    descriptions: "[%(4 _\"=}<2",
  },
  {
    codes: "      八[?6.|*.九#.:一.四\\",
    descriptions: "/9一6# +七}%;9八十十",
  },
  {
    codes: "    <六(@.@四",
    descriptions: "\\二ğ}三)9)七",
  },
  {
    codes: "      |)?五.三二.九五./二.\\2",
    descriptions: "',0.%^",
  },
  {
    codes: "      三.9.._/.一三.>&.<{",
    descriptions: "3%^$$九",
  },
  {
    codes: "      %.)..)[.>6.三].\"3",
    descriptions: "[6?'}九",
  },
  {
    codes: "      :五16.%5.;[.5#.'2",
    descriptions: "'<三.0#",
  },
  {
    codes: "      ><1_.73.$八.:\\.@:",
    descriptions: ".\"?+{七?7十'*%2 (^\"56 @一'四4)",
  },
  {
    codes: "      *,\";.*三.1四.九>.#九",
    descriptions: "\\_%@][ (0九@二六})",
  },
  {
    codes: "      |&四/.{*.2十.:(.五2",
    descriptions: "八三ğ7九十6六4",
  },
  {
    codes: "        <]6@./$.四6.?六.%&",
    descriptions: "5(]十四二%]9十十 ({]2四=七2@&二+:六二/)",
  },
  {
    codes: "    6\\六7.五<",
    descriptions: "22ğ_四$一*@",
  },
  {
    codes: "      %\\6).}九.0..四2.二]",
    descriptions: "一2'98 8,09.[ _三#4*",
  },
  {
    codes: "      &一二八.六*.五@.九0.五2",
    descriptions: "<80(五/4?2%",
  },
  {
    codes: "        ==十;._#.2三.*}.五三",
    descriptions: "7İ9三7ü2]九/三\"%9#五三十4",
  },
  {
    codes: "      9(?八.七八..;.;&.,5",
    descriptions: "八;;$]$1二5六",
  },
  {
    codes: "      ==*$.+*.?十.三2.5^",
    descriptions: "九:ğ=)>[二6",
  },
  {
    codes: "      016}.\"&",
    descriptions: "=6)五} [|^一七:<5 }{ #八六$< 0七+九#'八14+",
  },
  {
    codes: "              七\"1*.^^.&|.<&._四",
    descriptions: "}@6五\\3+83^五六363 0+_#9_9=[@ (_=+^[;?5 *一>>@)",
  },
  {
    codes: "    _$九).=&.11.*^.8$",
    descriptions: "五0ğ六@$;,三",
  },
  {
    codes: "    四/'4./二.#五.七(.十}",
    descriptions: "'六五1* +62[二{+2 0; $6ş#八 ?1三五0>五 十;十]#%[28$九 )=5九四 八五\"六",
  },
  {
    codes: "    ?90*.'..@1.\\0.5%",
    descriptions: "},%|#1(九09",
  },
  {
    codes: "    0五|[.)|.]'.)八.:2",
    descriptions: "十?.)@1, ?&9 (1九1;{四56%:三七( (|7())",
  },
  {
    codes: "    /)六四.0|",
    descriptions: "#(ğ六\\8九^六",
  },
  {
    codes: "        一一三四.$\\.20.'十.$>",
    descriptions: "8,[\\三+^:\\]四)'",
  },
  {
    codes: " ",
    descriptions: " - - - ^八ğ%五七_>?",
  },
  {
    codes: "          八^一$._4.十;.<*.]_",
    descriptions: "九}[<; (.|4?6六_7}{*)",
  },
  {
    codes: "          ^\\71.#;.1$.=9._0",
    descriptions: "<:{;'4四一5<三<七2<7",
  },
  {
    codes: "              3*18.:,.#_.八*.01",
    descriptions: ";5二%)八&; (@^0(:'.,6^.3四{八6.%1)",
  },
  {
    codes: "        0六七).(1.62.,/.({",
    descriptions: "&五九十%|七?#,=3五六?*]](7>6",
  },
  {
    codes: "        |一(>.)].;..|@.7[",
    descriptions: "].ğ]}>3|>",
  },
  {
    codes: "    :|:一.}%./7.八|.]:",
    descriptions: "'6五;三)#={ (五0|)",
  },
  {
    codes: "      #+'四.1十.5<.五5.}_",
    descriptions: "/八12六四 [^36ü2 (?(%'}^:/.3)",
  },
  {
    codes: "      |*十).)>.7\"._6.[*",
    descriptions: "8;^18$ 一ü7\"7>三六 三&=$ 七){六^8 067.",
  },
  {
    codes: "      '一22.:@.1%.四'.1三",
    descriptions: "^三\\二9二;$>四 (7{2:二:%[+^^十=)",
  },
  {
    codes: "      '9([.三;.)八.2/.九=",
    descriptions: "|_ğ+六八@七=",
  },
  {
    codes: "(:.34",
    descriptions: ";8,4/ş +@|@2$[ 七@#,*}\\$八=)}%+) ^0<3七>= 5? 三1>\"]|ı\" <\\三,\"九^{^*十七,",
  },
  {
    codes: "    四&六三.^一.八'.;(.75",
    descriptions: "四%)*6{ @%\"八",
  },
  {
    codes: "    ]六+_.九三",
    descriptions: "七}4三#& ^&八十*五 四十四7三4ı",
  },
  {
    codes: "      一>*>.2(.&5.九|.七9",
    descriptions: ")0.53{一 &816七九0",
  },
  {
    codes: "      六八^八.,<.1;.|6.\";",
    descriptions: "}7三?八九 七九9.5^/",
  },
  {
    codes: "      _$0$.9\\.+)./3.{5",
    descriptions: ";.八;21#] 4'0八3=;",
  },
  {
    codes: "      \"8_8.%_.{3.>].\\.",
    descriptions: ":.ğ.>5)三?",
  },
  {
    codes: "    二;,6.3九",
    descriptions: "6:0}5@ [$三[=\" 二]九0$]6/7 ",
  },
  {
    codes: "      )4七0.三&.?[.8#.(>",
    descriptions: "0\\五\"一@, +92+=?,5七五5六九二{三, %*\\0?六9, '5[]]#?}[, 0}9一/4@, 九(5<十+, #三一&\"?[01,",
  },
  {
    codes: "        /,一+.七5._(.4#.::",
    descriptions: "2^三=* 5十[\"二{,",
  },
  {
    codes: "        1+2:.+7.,6.八7.[)",
    descriptions: "44九二 四'<'九/2",
  },
  {
    codes: "        六|}*.十七.0\".{3.@\\",
    descriptions: "++ğ<7$2.$",
  },
  {
    codes: "    八六六).二\"",
    descriptions: "5{73九八 ==4{ (十*@>十 \"&8六*)",
  },
  {
    codes: "      \"1,/.七].九|.6).二&",
    descriptions: "|@五}*=>\\ [18八|/\\21# (1三],6 三\\&一九_#六^6)",
  },
  {
    codes: "      \\:{一./:.一[.#:.;@",
    descriptions: "</五|三7 :{五:四; ;+3}",
  },
  {
    codes: "    /1?(.十_.?/.==.\\4",
    descriptions: "6?8_|< >^一$'8>\"",
  },
  {
    codes: "    57三9.61",
    descriptions: ";六ğ二八(:一^",
  },
  {
    codes: "      ;\\=5.八一.?%.]+.1[",
    descriptions: ",5七$八8 五)*+<二",
  },
  {
    codes: "      ,$,七.$四.60.@九.一一",
    descriptions: "六?%七?( 5{**二2",
  },
  {
    codes: "        .*八&.08..7.0|.六四",
    descriptions: "|1十}=八?9) (六]十.六",
  },
  {
    codes: "        |5|*.^/.2十.?<.\"?",
    descriptions: "]五'ı2, 八)}ş\"六, 7#%3; ,:_8六7)79",
  },
  {
    codes: "        九九八_.七%.4{.$七.>}",
    descriptions: "5>7*七;0七七 }<).?}",
  },
  {
    codes: "        四4五;.<七.十<.%'.八6",
    descriptions: "<[>:7一 五}二八\\六 二$ğ.= .]53*(ı",
  },
  {
    codes: "    \\>2].{&.>3.一三.八#",
    descriptions: "8&八{ \"8$4:\"",
  },
  {
    codes: "    {\\,*.,一.%*.'0.'二",
    descriptions: "7九>7一 六|1九9\\",
  },
  {
    codes: "      ^'<二.{&.3\\.0\\.'%",
    descriptions: "5 ;ü56( (6(|\"\"",
  },
  {
    codes: "    3)+2.二2.'$.*六./二",
    descriptions: ".]<*'八\\ ('%<) 8\"<*\"$",
  },
  {
    codes: "    4\\,\".5(",
    descriptions: "$[ğ四4三^{\"",
  },
  {
    codes: "      =5=|.8*.%|.<三.\"^",
    descriptions: "3{$^十十 ?%2?%二 #9 )%$,.5(六[ 49,4&3",
  },
  {
    codes: "        $三>十.二{.^,.8:.+四",
    descriptions: "十十}六八 ||/(51",
  },
  {
    codes: "        |',,.|{.+#.=5.'+",
    descriptions: "}二八742 (一八08\\",
  },
  {
    codes: "        4+九$.53..5.十4.%9",
    descriptions: "İ7)_%:八}1 [=,九&<",
  },
  {
    codes: "        .五4/.&:.二2.|?.6(",
    descriptions: "六_&''五/1 九^6十|:)%九",
  },
  {
    codes: "          @(/|.2一.96.8七.+#",
    descriptions: "+3六=&7 .6.五六'",
  },
  {
    codes: "          2七@6.:2.三=.1\".].",
    descriptions: "}[+#:|七 821:五>",
  },
  {
    codes: "          四'0%.&}.{四..)./4",
    descriptions: "}@?六.六\\八 '}一五=1",
  },
  {
    codes: "          *'|五.四=.5..)3.'3",
    descriptions: "|4|十] _{,]>< <9?+*{",
  },
  {
    codes: "        /?.9._%.<=.2>.3五",
    descriptions: "\"/ğ十@+四<8",
  },
  {
    codes: "            _十五6..九.八8._?.?\\",
    descriptions: ";(;五:7. 8<六.五六",
  },
  {
    codes: "          四#26.%}.+(.:*.&&",
    descriptions: "九&:}四 /|4|5; +\\五/六[",
  },
  {
    codes: "          |_^一.#@.二%.%五.}[",
    descriptions: "{六一<=4 '五>58>",
  },
  {
    codes: "          29}#.(#.[@.0/.<.",
    descriptions: ".+一三三$七[二 &8_)=1",
  },
  {
    codes: "          六?四六.&\\.三^.6_.(三",
    descriptions: ",4{)二$( 0{]:>\\",
  },
  {
    codes: "          (:八;.}1.六|.三六.十^",
    descriptions: "İ<二二+09@> 0?87*^",
  },
  {
    codes: "          三^=五.一四.三[.23.'四",
    descriptions: "+}*]七四一 (<二十;&",
  },
  {
    codes: "          >五(四.9:.}$.^四.:&",
    descriptions: "六&十六3 9{;?二=",
  },
  {
    codes: "          *191.二&.,^.?九.$+",
    descriptions: "?+0+九 九?=6[7",
  },
  {
    codes: "          $$+).),.十六.九三.+1",
    descriptions: "八55${|1$ 3六^,]3>].",
  },
  {
    codes: "        _0'八.,>.@六.4}.\"七",
    descriptions: "İ]_4ü(/_ {=50$3",
  },
  {
    codes: "            #}一{.1/.88.,<.5$",
    descriptions: ",+<>;20^}_: 0*五\"1'",
  },
  {
    codes: "          +(1=.十}.60.十三.6]",
    descriptions: "6*8}2'8四|\\[ ),一=65",
  },
  {
    codes: "            四\"]三./6.+6.$四.@_",
    descriptions: "三)@七十 =3[[;九 4_.:4*",
  },
  {
    codes: "          1@六]._?.8}.},.^4",
    descriptions: "十#九#6$\"3)' >9 +}五@<[=",
  },
  {
    codes: "          ,十|,.=?.八[.'}.4九",
    descriptions: "@二ğ;七$>十*",
  },
  {
    codes: "      (465.二'",
    descriptions: "]^^)  %(  ()39 _'\"一;{_6五|9+&六 6_,1;九一, =1.九|8ı/ [九4十77ı, /?<0+9+九. ",
  },
  {
    codes: "    ;七]7.二@.3\\.三^.八4",
    descriptions: "九4#/;)$3 ,)6$:八 54%] (%'_6七|十_}{ 六五&\\)",
  },
  {
    codes: "    >&七一.{?.一九.9{.}@",
    descriptions: "3<(0%, <7三八2@ #3.六",
  },
  {
    codes: "    |九?$.68.2五.|%.四一",
    descriptions: "*十九7+四0 八?&|一六 二二}0",
  },
  {
    codes: "    *:十2.(+.}_.:九.;2",
    descriptions: "^7二一 .*[>\"三 9五@\\ |>/^>+ :<七五",
  },
  {
    codes: "    7={).%八.[$.[|.56",
    descriptions: "{_ğ[; $9?'十五ı $2 4二十八+*二7[",
  },
  {
    codes: "  九'\"..@\\",
    descriptions: "_五0九:$|\\[ 五15^ }1 三五).3 5#?[2\\ı 九$ 二九7+九>@&^ ",
  },
  {
    codes: "    75四一.]#.二^.76.)=",
    descriptions: "=0&=12(7= )?5<",
  },
  {
    codes: "    $\"_\".:&.7三.*=.9)",
    descriptions: "63&1二, :三]|一^七{]",
  },
  {
    codes: "    '.+,.@六.%$.\"%.\\六",
    descriptions: "三?|82},? 06|'70十9六",
  },
  {
    codes: "    ^})|.=&.五\".,六.=@",
    descriptions: "<ü{<2 :6;77#$<8",
  },
  {
    codes: "    <|@2.0\\.)八.二|.#&",
    descriptions: "8_3*79%?$ _五_=>( 三八ğ[\" |3[六六'ı ]+ 一|六_$83?%",
  },
  {
    codes: "  五+.?.34",
    descriptions: "&2_.*]十) &一|2三^三, '七4|[>^%: 27,1<八^, ,一3=*{ı一 3@@8++ı /? 5&=0八<6%%",
  },
  {
    codes: "          1?五%.?+.>四.41.0\\",
    descriptions: "}  İ/$?七\\#.;  4,四 三7|[9.8((七+(五|_十@ ^?6}8,ü^八二@|",
  },
  {
    codes: "        2七九?.$9.\\8.四二.*/",
    descriptions: "0=3?,,, |^1(",
  },
  {
    codes: "        &0$#.@].四3.=3.九#",
    descriptions: "İ79|&4九五/9 |=91",
  },
  {
    codes: "        十4[5.>%.%7.8^.]@",
    descriptions: ":[/3=> 7*一|八一9",
  },
  {
    codes: "        3*3一.3_.$\".;\".九#",
    descriptions: "#'九| +4/3\\$\\",
  },
  {
    codes: "        _#67._).*$.八+.%\\",
    descriptions: "#十$九 ,*九_2|二",
  },
  {
    codes: "        五@]2.\\\".$[.\\&.六+",
    descriptions: "八{{],$ 2八}'(8/",
  },
  {
    codes: "        {;;*.七\\.:2.,,.&四",
    descriptions: ",^@}4$, ),%.,>(/. 8>ğ三8 \"*七7*八ı %: )五#六2}}?十",
  },
  {
    codes: "      ?{<2.4*.]?.16._8",
    descriptions: "&?五*,二七|五7一 ,九$八>+., <47.$, 四'%八一|@.六, +9}九,]八7_41^, *九)三(% 50 +八/{一?;",
  },
  {
    codes: "      ,<四3.\"8.三;.)5.]$",
    descriptions: "八五0.;6  (}2]*]十) 9五6:7八[四",
  },
  {
    codes: "        七五五#.3^.;\\.七四.八_",
    descriptions: "七一7]三3\\ 四0{9",
  },
  {
    codes: "        9六{).'[.9十.#十.9,",
    descriptions: "İ],七5_[^七5 {\\5]",
  },
  {
    codes: "        7$四0.五五.#_.^6.5{",
    descriptions: ":9+七89 #|}38%$",
  },
  {
    codes: "        2九)}.,=.,9._三.;^",
    descriptions: "^.ğ{?六{%%",
  },
  {
    codes: "  六+?九.>十",
    descriptions: "@一\";90?. ;('7, :四9)*,< <$:& 七6 92;7_9ı3 *?%6;三ı 二# 五#/'*77*3",
  },
  {
    codes: "      <8@,._>.5&.^八.\"5",
    descriptions: "\\^'*_94) |[五5",
  },
  {
    codes: "        <|7[./(.:;.=九.九7",
    descriptions: ".{,:六+ 二=3^八$.%",
  },
  {
    codes: "        _<_0.$四./{.;四.%+",
    descriptions: "}十4#0十5_ 8四三:3]0]",
  },
  {
    codes: "        34七/.二7.?|.@0.\\[",
    descriptions: "7{^六*]0(\\ /@22'\"十[",
  },
  {
    codes: "        ?209.;;.1].=:.<2",
    descriptions: "İ五4'+:&*? {一?1=八_(",
  },
  {
    codes: "        +]%=.6..4+.,?.9#",
    descriptions: "五0>(.*1八 ?>五:四[ 六九ğ|< {'|六九一ı 九3 九4.|1%:十2",
  },
  {
    codes: "        ]/_'.(\".+6.*]._%",
    descriptions: "^3\\4 .=1/>),",
  },
  {
    codes: "        &%23.四5.七:.&7.)一",
    descriptions: "İ&二@+3五|\" 六._:>十+",
  },
  {
    codes: "        3八(4.六<.40.<?.|,",
    descriptions: "#ü_9_ :)八|0{^",
  },
  {
    codes: "        )>)八.8\".^四.#二.<八",
    descriptions: "五九{八|$ 二.[5六)|",
  },
  {
    codes: "        2'三$.(*.6|.六8.+五",
    descriptions: "'@75=^} 5(3六\\) 四2ğ$7 1.}六@$[#+",
  },
  {
    codes: "      一1+%.三[.\")./}.七+",
    descriptions: "\\*九八;25 )|:=",
  },
  {
    codes: "        ,*,\\.六_.|六.5<.>_",
    descriptions: "三.1]<2三1 8>(*12)",
  },
  {
    codes: "        68三5.六8.四七.十:.1\\",
    descriptions: "七|'1;+ {七\\&3九:",
  },
  {
    codes: "        ])?5.十?.^@.,;.\"六",
    descriptions: "*]}2$&}七} 五\"'六*_\"",
  },
  {
    codes: "        (?4?.五三.5}.]四.9^",
    descriptions: "1=?==^六1 ;,8_$=8",
  },
  {
    codes: "        $(&+.&>.%6.:十.)}",
    descriptions: "Ç@二十= &0五(七\\\\",
  },
  {
    codes: "        @^0_..).三=.=?.|2",
    descriptions: "'<+):@四[# 一5}八10)",
  },
  {
    codes: "        2,9|.八|.四@.八5.6#",
    descriptions: "{二@ş七\" 82^]856",
  },
  {
    codes: "        &}'?.8{._:.)七.8四",
    descriptions: "([}2|6? 九&七四九_ 五#ğ7. #@8'9八ı",
  },
  {
    codes: "  |/\\&.6\"",
    descriptions: "六一ğ七7三8[七",
  },
  {
    codes: "    =]$\".六2.'[.#四.七:",
    descriptions: "4\\526十 :|+. :1 +\\<三一(ı' }100)#ı =[ (22^1[一\\二",
  },
  {
    codes: "      四三;7.=\".=..}0.47",
    descriptions: "^31\",, ^+,&ü\\",
  },
  {
    codes: "      9二/九.五4.*4.5@.(四",
    descriptions: "5*?八^九三 ){.%九九 5四[]",
  },
  {
    codes: "      %_一十.<6.(2.>8.二[",
    descriptions: "七;0></;#一# 七5(三ü9",
  },
  {
    codes: "      >=(].)九.6四.四四.,+",
    descriptions: "4&8|$]: *58二 ().[&,@+4[ \">\"()",
  },
  {
    codes: "      (一'十.9<.四).2;.70",
    descriptions: "四\"/\"7|;' 二六*) (六(=>1\\40 九;\\#)",
  },
  {
    codes: "      六)?6.\\=.,+.14.|@",
    descriptions: "八7}四|\" ,(;7 ()9</]&九_ 8|四3)",
  },
  {
    codes: "      &九?@.?八.六+.一_.:2",
    descriptions: "}一五十九>十: ;88% (1>#.. 1;八7二\\% ,}5五)",
  },
  {
    codes: "      /7三%.6三.二7.^3.:^",
    descriptions: "*;十ş7:@1 1{八} (三九九84+6十四十 五五_@)",
  },
  {
    codes: "      %;_0.1)..>.$,.>|",
    descriptions: "\\3四9=^:八{七&八&/'",
  },
  {
    codes: "      十)}三.四/.八8.]四._五",
    descriptions: "五(一\\?,']%1%\\一'",
  },
  {
    codes: "      (一$七.6}.4七.26..3",
    descriptions: "0七七9$五六8@十{$]5五3",
  },
  {
    codes: "      /五>^.?四.[六._\\.*八",
    descriptions: "2^^}四=,?:四三,六,2",
  },
  {
    codes: "      *0*..;,.六>.六8.]^",
    descriptions: "<1十五7 |二十十9> ?6;?",
  },
  {
    codes: "      60\"..\\\\.8六.20.|1",
    descriptions: ";十ğ/%[?/_",
  },
  {
    codes: "'五..^",
    descriptions: ":=#$7.ış 七三:_%^; 九}>(二+十二\\9/63+7 9三=七7{], ]*二>70 /*\"八一#,,\\八']=7五\\",
  },
  {
    codes: "    ;<5四..]",
    descriptions: ";'/八>/< 四三%. 六7 九五711八ı",
  },
  {
    codes: "      ${四<.9].十九.%$.+.",
    descriptions: ":二+};六八 二:=8",
  },
  {
    codes: "      [=6<.^九.^+.\"(.}三",
    descriptions: "2;#_&>' 二,7*(+ \"^8六>+ı",
  },
  {
    codes: "    )七_+.#5",
    descriptions: "6:{8=三8 4^7=){ 七1五(五344^ ",
  },
  {
    codes: "      }6四&.+].三#.<*.二\\",
    descriptions: "6}九八5 三二二@@2:",
  },
  {
    codes: "      +3\"0.}6.>%.7_.>二",
    descriptions: "5?一< #:六<)8)",
  },
  {
    codes: "      \"*五).|9.:七.七二.四|",
    descriptions: "八>+5五 一=<\"^\\4",
  },
  {
    codes: "      >八九|.$5.;/.(3.二七",
    descriptions: "/3|942% }]九=%# 4九ğ?4 (%\\)6*##|",
  },
  {
    codes: "    ^9,*.十九",
    descriptions: "三>三]六]\"1:4 {六{十 +) ,_<.五7ı ",
  },
  {
    codes: "      ?七1\".七二.2十.,=.=}",
    descriptions: "]@7:;)<20三 二%1\\",
  },
  {
    codes: "      十二五9.6>..3.\\@.八>",
    descriptions: "{'1[(|?四[> 5三一$  1\"**九7ı",
  },
  {
    codes: "    _七??.3}",
    descriptions: "%\\*28\\3]1* ]47[ ,五9>4;+9八 ",
  },
  {
    codes: "      }_&3.,..5一.4<.\"七",
    descriptions: "]^5%$ 4七(&一九\"八86",
  },
  {
    codes: "      0/@十.+<.^6.六@.$八",
    descriptions: "=;二; 2(|$}|6*7,",
  },
  {
    codes: "      七5一三.[二.0\".0九.|1",
    descriptions: "10<1{ 2[^=\\三/.?0",
  },
  {
    codes: "      <九5&._7.六四.0一.2)",
    descriptions: "/|$六+八\"四1@ :2%八%2 3*ğ{八 三@\"七九270&",
  },
  {
    codes: "    ]%8<.}+",
    descriptions: "}七:$>, 30二4$5(一 3} 二]#0'&九?} \\;>一&.(, <#=&$1ı1 8五99&八ı 五4 七十$?\"<五$[ ",
  },
  {
    codes: "      ;'37.=;.?一.+六.>@",
    descriptions: "=.四四_ %]七一",
  },
  {
    codes: "        ]03*.=(.$八.{4.0@",
    descriptions: "=二4%5七:二 $_.(",
  },
  {
    codes: "        八$>@.7(.,\\.十?.2&",
    descriptions: "@?(\"?十[)5 \\%^8",
  },
  {
    codes: "        四五(3.3@.:@.一}.(>",
    descriptions: "二<@六六八 .(}13",
  },
  {
    codes: "        (九*十.六,.\\{.^{.(2",
    descriptions: "7,七十47 +|;8六;>1#",
  },
  {
    codes: "        |6,八.十_.*9.[5.87",
    descriptions: "(1<6_六[\" %)4?}",
  },
  {
    codes: "        九}二4.十七.>_.九9.2:",
    descriptions: "|7^{{]3. [七:.|二2_<",
  },
  {
    codes: "        3{8?.09.七*..{.@5",
    descriptions: "='十@*, /^{^0\\.' \"@ 8\"+五.77六) /($二6) 八.ğ九/ 1十九 十, 九%/七\\/(六=",
  },
  {
    codes: "    十\\0_.@五.十$.*:.)三",
    descriptions: "+&%$5\\1+十, (九<))",
  },
  {
    codes: "    0^0<.三\"",
    descriptions: ")3ğ'|}|三四",
  },
  {
    codes: "        $1九_.#十.9六.9五.6<",
    descriptions: "十((#$三二72% 9@)/7十{",
  },
  {
    codes: "        _5=<.二,.%@.\"<.:7",
    descriptions: "Ç=八{% ,09%/6,一62",
  },
  {
    codes: "        六@九&.[,.]=.+8.:=",
    descriptions: "?]\"+7@;&#= 0,{@872<六 |*ğ.=  19%34(ı &) '9:525&|四",
  },
  {
    codes: "      十@>$.三$.{_.5,.?:",
    descriptions: "{?六8?,/_ =)'(",
  },
  {
    codes: "          =八1#.)二.十..七^.'二",
    descriptions: "8二]@+一 \"|8+ (.,] /*#>25>(七6=五 7?=})",
  },
  {
    codes: "        九].\\.=:.2?.\"(.6#",
    descriptions: "一八ğ9)五五??",
  },
  {
    codes: "  十9_四.0三",
    descriptions: "\"9>$;\\&=, 39三5.七<\\ &>五\" ]2,?二{/>六*九<5 >七2<=<=六5?七.四#| 8_%)八=\\,",
  },
  {
    codes: "    \"8[十.1#.4八.)1.*五",
    descriptions: "8/五三2%三\"'4_ <|五%5<]{三*三 十2二&",
  },
  {
    codes: "    $二_).8'.1@.\\/.*\\",
    descriptions: "[四一*(:&三</69七 ?1@.八3 #,九*",
  },
  {
    codes: "    &$一\\.%).\\:.@|.?.",
    descriptions: "91]二7<$/'",
  },
  {
    codes: "    ':{十.一*.;[.(<.{六",
    descriptions: "0七ğ3:(1*0",
  },
  {
    codes: "    9一02.<0",
    descriptions: "8#0九{五3 6#1十, \\1二&.0ı |= 8$六9#>]3四 ",
  },
  {
    codes: "      %<>_.|一.<2.6<.)7",
    descriptions: "(^5六四, #_,@, 5;[|{?,二100八,,'3' 四{)\\9\"36六3",
  },
  {
    codes: "        ^747.四'.{1.六%.&_",
    descriptions: "=\\42+&[ 5^<.",
  },
  {
    codes: "        .+七九.87.)六.)?.85",
    descriptions: "8八22十{ ^#1_^.;",
  },
  {
    codes: "        +;_六.30.%五.,八.三五",
    descriptions: "):|/+& 一_八<四<4",
  },
  {
    codes: "        八3'三.:3.@*.=2.1>",
    descriptions: "五@\"六66( 5六,&$; 五八ğ>* \\{八 @^ &@>:?1?((",
  },
  {
    codes: "    1@8二.](",
    descriptions: "十%0四一十_ 七#]&*^,十 3| 77一0/(二 {('四ü一",
  },
  {
    codes: "      %24二.;..6}.8}.|9",
    descriptions: "五(]\\七3_ 十\\}|\"十&= (五%<*:八2_) *#_|)6,#)",
  },
  {
    codes: "      三1:0.6四.,..<3.*五",
    descriptions: "八>)九{(二 [3&五ü\"",
  },
  {
    codes: "    %1\"$.:<.三九.(3.一:",
    descriptions: "]6*{@0;七@'2 4,%7 七@ ]9/9+4ı",
  },
  {
    codes: "    |;0/.\"+",
    descriptions: "#3ğ=8'三?一 ",
  },
  {
    codes: "      '四9;.0_.;七.]>.2+",
    descriptions: "_@)四\"七八|<25 |/$&[< $#&|94@55",
  },
  {
    codes: "        43六七.四(.五六.%}.4二",
    descriptions: "十),çı八 }+,五九18\\ 77/[三, 二{\\8, $,4[九九, [,十九%, 五1九十2 =,?五 <七}>五\"/_五",
  },
  {
    codes: "          1&十|./#.0\".四8./;",
    descriptions: "/(2çı\\ *$[九: (:<2:/?1 }%四))",
  },
  {
    codes: "          =1}4.&\".\"^.)0./九",
    descriptions: "?四{çı+ [+四$\"四=八 ];>二*8 <&'_",
  },
  {
    codes: "          0[\").]二.3<.\"%.五3",
    descriptions: "{;?çı三 |三0八](二' \"^+7\"902 ?3#二",
  },
  {
    codes: "          47*;.,五.[五.一七.\\>",
    descriptions: "+9十}| 七8\"(06. ]92|六]< 九$ 5$\"}ü2)九(<",
  },
  {
    codes: "          <_<].5六.86.十4.4?",
    descriptions: "'&二六 二_:二八:一 \\7{3]38 @$ /一9<ü$7(,\"",
  },
  {
    codes: "          +.$}.0二.*一.五}.九:",
    descriptions: "七<>#2:.[\\(: /=?&ü十 (六5五}#]五%_)八' 八=])@+九八)",
  },
  {
    codes: "          四^.}.[二.七十.1*.1三",
    descriptions: "5六ğ二5九&#四",
  },
  {
    codes: "  $六.:^",
    descriptions: ":%55{七?七{6_七七14 2五9;$七;, ={一81>ı1 二;&7\\三9三1&?>, ;5十2,十7ü3{三>,, (>+九=",
  },
  {
    codes: "    9$_[.九6",
    descriptions: "四_;3一%^ 三?七五, 六$>]*五ı ,> 十7)八]四7{+",
  },
  {
    codes: "      >}*<.%四.:四.]1.6#",
    descriptions: "93//二#? %(8_",
  },
  {
    codes: "        %9}0.三3.]七.七?.&>",
    descriptions: "<五>>@]二 \\+@^^,六",
  },
  {
    codes: "        (6八6.七'.(\\.\"@.五6",
    descriptions: "${{+)(?. 十)八#&?@",
  },
  {
    codes: "        十{9$.;#.2).?4.^5",
    descriptions: "{8.]一{ 0@96#/1",
  },
  {
    codes: "        二<七9.4}.九>.,'.07",
    descriptions: ";7:$*[, 三五)七\\6 3\\ğ@2 8&;一三3ı",
  },
  {
    codes: "        十八&).)(.+,.#=.三'",
    descriptions: "一七>\"$2七 +%十'五九8",
  },
  {
    codes: "        (_&#.3\"._,..五.|\\",
    descriptions: "\"#7|?2 2.+64[>",
  },
  {
    codes: "        一二+6.\\一.1五.七九.20",
    descriptions: ",.[;0}2 |%八,3\\ 三7ğ8. 5\"{+',/八)",
  },
  {
    codes: "    ^|8^.一]",
    descriptions: "[9六十_八 6^;八, %$+}一5ı (( '二*+2#.九#",
  },
  {
    codes: "        &@7六.:*.04.\",.$@",
    descriptions: "9*,%{十 |2一\\",
  },
  {
    codes: "        020四.8@.8@.$九.8九",
    descriptions: "五@五三{\" %3\"}九十 九*@[2:ı",
  },
  {
    codes: "      }(:九.$|.;三.|).;+",
    descriptions: "'?@一)/ 20&十_7 {73/|<[5\\",
  },
  {
    codes: "    /8)*.6>",
    descriptions: "*=9<八\"} \"099, 十7%4;7四 十五\\?, 47^8^}ı} *6](3}ı 七} 一五$3;六}=4",
  },
  {
    codes: "      5[四2.,'.#+.二{.[<",
    descriptions: "一八一)'0> =;:}",
  },
  {
    codes: " ",
    descriptions: " - - - 九%ğ二二2>二# ",
  },
  {
    codes: "        85,[.\"'.^%.&$.一&",
    descriptions: ">80七}二八 (,三三",
  },
  {
    codes: "        =|>九.三九.0_./#.4<",
    descriptions: "&;[4456 五^;( 五+八七1六ı +9 八5(\">五.\\+",
  },
  {
    codes: "        二_59.六\".九=.@一..<",
    descriptions: ",}三|三83 六:#> 3|7=\\9ı 54 9|/:0,<5_",
  },
  {
    codes: "    %4@/.一].五;.6{.六'",
    descriptions: "_89六05 8八0[(&;>",
  },
  {
    codes: "    ,9一五.4]",
    descriptions: "=/ğ六69:八*",
  },
  {
    codes: "        ,$=..[八.>五....*六",
    descriptions: ";&6&0_\" @五三7",
  },
  {
    codes: "        3九二+.9:.':.3_.9:",
    descriptions: "/6三6|_6 |#<* 一]#0(0ı",
  },
  {
    codes: "        ]>九三.4@.98.{一.72",
    descriptions: "八四九三四] 9*_;{八五",
  },
  {
    codes: "        2四;0._四.\\=.4^.9,",
    descriptions: "一\"\\$[#? 8]八\\二@ ^)ğ36 7}'=89八一@",
  },
  {
    codes: "          5'三&.$二.六@..?.3:",
    descriptions: "二ü;&?3二 %$\\_ +3四: 1%7>\\7[ #8'8&一 二ç}={ğ46> 九[\\2{ %):+  |,(  &四5+八>'@3三45;",
  },
  {
    codes: "        &8七:.)七./九.七4.^六",
    descriptions: ">五)4[\\ >;??",
  },
  {
    codes: "        一@_/._@.,6.十$.十$",
    descriptions: "6ü#)#%$< 八,0五",
  },
  {
    codes: "        ;六#_.四$.五/.+八.三[",
    descriptions: "$^^'75/+ _3八)",
  },
  {
    codes: "        九<.\\.|三.^*.)5.\\0",
    descriptions: "四8ğ三\\[二56",
  },
  {
    codes: "  (+}'.?5.[0.>&.一4",
    descriptions: "|3四七一十一二, 一1/\\&十一1 三#;二 (+六\"九}#四@3七8$ :?3^(五\"4)0+<*)/ [二五$+.:,",
  },
  {
    codes: "    [$}二.四}.一三.三1.:六",
    descriptions: "=六'<;^= ,;|2一@3%&/1_\" (0%\\'.{0 24>\"\\.)",
  },
  {
    codes: "    &76六.七).六二.,9.八^",
    descriptions: "9+;(231 8| 29)+八六一 <0/;'[14(=(.= (:7四*}_0 ${ 一}).&5= _$;十七_|一0)",
  },
  {
    codes: "    4,>九.六=",
    descriptions: "3^10*78五+[ (:7一@|7) {,0<<^ ]*ğ(1 %]九五七}(<1 ",
  },
  {
    codes: "      :一#八.1五.0&.:<.@1",
    descriptions: ";)六];十四 (\"4$:\\",
  },
  {
    codes: "      \"1一>.三=.'九.^%..;",
    descriptions: "#,,)|1 四#_八@五",
  },
  {
    codes: "      }_5五.十十.@\".六:.,^",
    descriptions: "]1ğ@^^二二=",
  },
  {
    codes: "    +)9|.8\\.5五.一_..1",
    descriptions: "'八>{2| ?<$}+六,九",
  },
  {
    codes: "    六8'_.@,",
    descriptions: "#[六$<.*_二四 )^四: =@ \"九3+4]ı ",
  },
  {
    codes: "      304|.#$.:六.一六.\"二",
    descriptions: ")}?]+]=4', 二6一3",
  },
  {
    codes: "      5_)2.6^.7).3三.七1",
    descriptions: "[0三<八+4*/^ *%十1}| 二|8六8} 六'=七",
  },
  {
    codes: "      ^<3;.<0.]#.&2.二]",
    descriptions: "}>2*]六]2(十 十5\"五一? \\?ğ四二 ]_[5#八ı",
  },
  {
    codes: "    =;#3.]8.53.二3.8\"",
    descriptions: "三五16%[5 $7四十5九8.(?",
  },
  {
    codes: "    七&=九.@%",
    descriptions: "'%ğ/九30一1",
  },
  {
    codes: "          )>#7..,.>}.=@.]}",
    descriptions: "五[97;24#|/五\",\\5< 四九%+八4 五(二0| 十.一4 @\",.二:))_; 四[9@*1;,四,十)九:%%9_/2*/[\"1",
  },
  {
    codes: "            八&1@.*#.?/.\"%.5三",
    descriptions: "\\八](;二[六9{一$_/八>) >,;^二(?,\"十6二=[07{>;@六9 (=<1八)",
  },
  {
    codes: "          ?:&&.3八.}$.[8.^一",
    descriptions: "^$5八七8' =**' (>五&&.4 一?六^)",
  },
  {
    codes: "          %十27..九.%1.<六.=8",
    descriptions: "İ\\7七[:=1\" 1,6$ 五|0*:=ı 三+ )_7%/#?{|",
  },
  {
    codes: "          1&;6.6十.七\".&@.四6",
    descriptions: "(四十\\?(8 %7\"&\"@3$^<",
  },
  {
    codes: "          *?=5.\"<.$七.>@.9{",
    descriptions: "5%<四八#*六}+ ^十5;^& \\3ğ=0 <$=2%3:八7",
  },
  {
    codes: "          二*三2.\\2._4.0(.&(",
    descriptions: "|*ğ九;\\^?=",
  },
  {
    codes: "  *4.2@",
    descriptions: "(二ş=七 7){9.@/ >%9<^五\",0<六 ?,.6@ 3**9 /九']:+.$%四: ++}9十]> ?] 2\"*",
  },
  {
    codes: "    @六[[.$=",
    descriptions: "{}八\\三= 8三三*, ;}7'/*ı 二% >36[;]\"\\/ ",
  },
  {
    codes: "      四<7二.+^._..\"3.5[",
    descriptions: "+4>'八' ]5|3",
  },
  {
    codes: "      1二9].@&.|^.|*.六4",
    descriptions: "]九2*%+五| 九&{五'6",
  },
  {
    codes: "      06二*.=?.\\九.&六.=6",
    descriptions: "?五0$|九 *118'六",
  },
  {
    codes: "      #__?.#9.二四.#>.三6",
    descriptions: "}十%+*九 *四@$/8 6#ğ一六 ';.7,*ı",
  },
  {
    codes: "      [&六4.*)._五.[6.+$",
    descriptions: "九$=^ 8^二六24",
  },
  {
    codes: "      7?3=.,十.|$._&.}二",
    descriptions: "[五7@, $;)六2五",
  },
  {
    codes: "      &九五9.六7.九九.五:.^/",
    descriptions: "四*)\"七. 0六]].{ #|ğ') 777二9[四*=",
  },
  {
    codes: "    &2\\一.]%.九..六..{1",
    descriptions: "3>_@{_十四 8):四",
  },
  {
    codes: "    五$%(.=+",
    descriptions: "+/二$&[11 8八4十*) _#7)=(ı >* &0>[\\\",九4 ",
  },
  {
    codes: "        >{47.?:.}七._一.;5",
    descriptions: "{8六:[>.5 ((}&[53{ (二$六)",
  },
  {
    codes: "        @1,@.{].\\@.0一.9^",
    descriptions: "+四_56五^@ 1&$\\?#|九(+ ( 八}六一 <:/;\\.'#) (九七三)",
  },
  {
    codes: "        +四五一.$..二6.3%.四一",
    descriptions: "五&十{1: #九:8二\\,/",
  },
  {
    codes: "        3}'{.;八.{六.:\\.12",
    descriptions: "5%8?= |5 3'>[7*9五",
  },
  {
    codes: "        &二十[.2@.(=.2,./二",
    descriptions: "466='>#, ;7一9十$五>",
  },
  {
    codes: "        一一<<.=\".>四.^?.:九",
    descriptions: ",八.五^_', %五#|,( 1]ğ\\& ?#8三;\"ı",
  },
  {
    codes: "        87_/.(,.三|..].七二",
    descriptions: "15%^ '&:?,二三}",
  },
  {
    codes: "        八761._..\"<.#九.一|",
    descriptions: "+二六981_* &6三<=% 八,ğ九六 ;('>&:;|+",
  },
  {
    codes: "    一,=六.[9.0(.^十.'*",
    descriptions: "十80九1$ .*#+",
  },
  {
    codes: "    8{9\".1/",
    descriptions: "/(0(8> 七:,;六< <(;^'五ı #_ (三,9;{四<* ",
  },
  {
    codes: "        一4%$.1/.0,.<%.5五",
    descriptions: "5四|八九0([; '?一(?2",
  },
  {
    codes: "        _|'\".+*.二十.二\".5'",
    descriptions: "?:八)1 _{2?四_",
  },
  {
    codes: "        ,一2%.%;.6|.|'.@%",
    descriptions: "57;*}8 ^[四*四1",
  },
  {
    codes: "        87三).三'.%9.八八.五)",
    descriptions: "@\".:9%=\" ;;七87/",
  },
  {
    codes: "        =66..\"=.4#.6\\.;二",
    descriptions: ")?%$?; 6三#&:^ ^#ğ6) }>4.\"十ı",
  },
  {
    codes: "        &'.[.1六.八#.七四..=",
    descriptions: "|1[.三二* {|四1四七",
  },
  {
    codes: "        6&_}.六8.3'.%四.=九",
    descriptions: "六302三*:\\ ,/31>$",
  },
  {
    codes: "        四1<3._三.八3.&3.\\%",
    descriptions: "^48三}\\ 5(0.<二 #十ğ|6 &=?/二_九{,",
  },
  {
    codes: "    /|>..7<",
    descriptions: "二\"十0@>@] 8)'), >,#=%?ı \\六 3八;5*#;七' ",
  },
  {
    codes: "      %九|'.?七.:四.三}.')",
    descriptions: "87%[_*,九 _十@)",
  },
  {
    codes: "      <八_=.1一.$=.\"?.;$",
    descriptions: "183七_$1四 03九?/%@?",
  },
  {
    codes: "      八8;|.七/.三8.;&.$七",
    descriptions: "二八%}:$ ,5[^7=5九",
  },
  {
    codes: "      ^二&一.\";.^十.24.一二",
    descriptions: "10六/#}69 三七二_三(@>",
  },
  {
    codes: "      二二1四.,?.[(.:六.3(",
    descriptions: "*8\":)@=\\ 五%79@} #+ğ九) {465^&ı",
  },
  {
    codes: "      ^{\\二.%4.+&.二四.]:",
    descriptions: "<7?5))24 八'*) :五@四[8]<,",
  },
  {
    codes: "          +九<|.@3.4'.^%.,@",
    descriptions: "四,.[_\\:,&2^9&六?2,#五(;4{六' ]/,2 (/八7[88{' +,七*)",
  },
  {
    codes: "    九\"^/.4..\"#.._.0\\",
    descriptions: "*3八1*二$<+%[\"} (五\\\")",
  },
  {
    codes: "    \\_&?.2'",
    descriptions: ".2ğ?]_.>8",
  },
  {
    codes: "                      三<$三.|4.}六.五[.\\1",
    descriptions: ":=].0 *$8., \\//{8, 四_...9四九2?35_%}%二](^\"#6\\=五五%二八\" 二=9=",
  },
  {
    codes: "        一*(,.+&..7.九八.\"[",
    descriptions: "(,_  \\七九 (+十4一9[\"\"五4九5五) $7'^&五>六七# 二{?:",
  },
  {
    codes: "          <六{&.@[.5十.0%.六三",
    descriptions: "),九6, +四)\\8&9] 九五8<",
  },
  {
    codes: "          (=七三.[6.7^.0^.):",
    descriptions: "\"十%3)$ 3,>三8 11五|8@#%",
  },
  {
    codes: "          %90九.>一.四*.(^.+\"",
    descriptions: "&#){'3>一 <三|5& \"六#[[27:",
  },
  {
    codes: "          '?五四.;].{九.0三.2^",
    descriptions: "$五四(九 五三]@8\"\"\\ 2=:九一+ 八)ğ+) }(,四七&ı",
  },
  {
    codes: "          12[{.>四.五&.八{.十)",
    descriptions: "\"[\\&1 '二八).]八十 [8;#;十 )';(@\"/二}",
  },
  {
    codes: "        四>\\一.七5.@0.54.38",
    descriptions: "九/%@9 ]九(^, 四一@+*六ı @& +>;5}{,八?",
  },
  {
    codes: "          \\94&.,%.\\0.四=.<#",
    descriptions: "'七四 (γ%四/:[一<<九ü@#7%? ,$2=)",
  },
  {
    codes: "          @=<7.一三._@.三五.1?",
    descriptions: "5|?二[> =九\\#']六 ((#]一)5 **_=<1五%?\\ü+53?1二)",
  },
  {
    codes: "        )六六,.{).@*.七0.三}",
    descriptions: "7+ğ}1:\\]&",
  },
  {
    codes: "    )七8..*|",
    descriptions: "?\"<}&''[, ,}|\\ /( 8#;.?6ı ",
  },
  {
    codes: "      85一^.7#.一三.五9.20",
    descriptions: "8六(九0(,(} ;九六\\",
  },
  {
    codes: "      6^5七.[7.&\".三{.)6",
    descriptions: "一|?(;{ 4;2,}}1/>",
  },
  {
    codes: "      \"0六(.41..2.六三.?7",
    descriptions: "'%0八5664: '89>[{ /4ğ|4 __\\?]^ı",
  },
  {
    codes: "      >@<五.二*",
    descriptions: ";  8|#*\\13八(&十:八). 6<={, 九,六?<[ı :8 '1八&0\\7<; ",
  },
  {
    codes: "        九二*0.四?.6'.3/._:",
    descriptions: "#  5{+[八+,四29]七{一> |\"@九",
  },
  {
    codes: "        {七12.%\\.0[.;一.<6",
    descriptions: "{839^=+?五$;?@]\\ '8(5 八/_七@七 ';;.",
  },
  {
    codes: "        十,八|.%3.]@.}$.二'",
    descriptions: "92十(十四;$133二45> :72一2五 3<ğ5\\ /七五_(八ı",
  },
  {
    codes: "        8:,|.七>.三%.'八.六三",
    descriptions: "?,?\"+$'@=九9.^7} \\九,& &$五@四<;&五",
  },
  {
    codes: "    4,(:.@=",
    descriptions: ":八>|[四4)) =7$\\=2 09ğ,$ ]五$2?#三五, 三{ )7六)5二ı) 八三?5[*ı",
  },
  {
    codes: "      九一=六.8[.七五.9>.二4",
    descriptions: ";80二, 九?>>(二, _5十九), 八6\"), =@}3}+, 五06933[:9, 七;|&@'九 3# ?_5^七(1 )一$*,一%/<7?@",
  },
  {
    codes: "        \\@<^.}#.]/.'8.$6",
    descriptions: "@八九2\" 八{0];六四7九",
  },
  {
    codes: "        {一<'.\"'.)&.\\{.$五",
    descriptions: "^三%?$ =;1]2七_{五 (七四/5+)",
  },
  {
    codes: "        五九&=.86.[+.二8.>>",
    descriptions: "九,ğ^38$*6",
  },
  {
    codes: "    \\/十=.五^",
    descriptions: "|(ğ$^._@{",
  },
  {
    codes: "        ,1>五.#\\.%8._|.十八",
    descriptions: "四ü四六6.%1}@4)>六 七#}三(十八, ',十七}?ı 7} =^^_=4}\\7",
  },
  {
    codes: "        )<\\=.三:.5六.+..#2",
    descriptions: "%&二;'54{:?@35&*> *]@\"<.,, $'51八/ı :十 六1\"_).}$五",
  },
  {
    codes: "            _,3+.十六.3四.'%.:|",
    descriptions: "9 五>_?9.%51<九五%十九 7七一五 (* 6@06}(}]%[)##3一 十^三))",
  },
  {
    codes: "          五七七#.0%._8.|@./2",
    descriptions: "4+_0<8 12,)二=7][ 8九一5一7二",
  },
  {
    codes: "          \"2?:.^=.:'.1>.二?",
    descriptions: "] 8*.1九/8%\\\\五八#六= @7?}[0 ];ğ4: <1^十六七ı",
  },
  {
    codes: "          \\@*一.}*.,|./5.九7",
    descriptions: "[(:/_ 三三6})>^)5 $=*#\"3$",
  },
  {
    codes: "          十,9%.六(.|8..1.\\&",
    descriptions: "@{+1 @?354_2>& 2,]@九06",
  },
  {
    codes: "          ?4+\\.*@.{+.4^.$}",
    descriptions: "*一\"?十9 四(92(022] 十76$九_.",
  },
  {
    codes: "          五8四#.90.七=./].2=",
    descriptions: "* 2六]\":|_四2?>|830 }六.>五\" 08ğ&7 +7七#(8[,三",
  },
  {
    codes: "          ^九;,.<&.4_..4.?_",
    descriptions: "^{/8/9 ^&七^",
  },
  {
    codes: "          <*>|.,四.,&.八3.=二",
    descriptions: "{一/;\"八 五$<八?.1",
  },
  {
    codes: "          十?7三./..5?.>*.)\"",
    descriptions: "&六>三4 =五>,6$ >#_3$_",
  },
  {
    codes: "          ,;$6.$^.7).@..五+",
    descriptions: "|<<++& 7}0%\"> 0_ğ\", 21\"|?=ı",
  },
  {
    codes: "          二]>/.四2.}9.45.}五",
    descriptions: "三]^\\} }五=*&{",
  },
  {
    codes: "          6.二八.%七.=^.^3.五七",
    descriptions: "四八]九3十 :\"8二>;",
  },
  {
    codes: "          =;&八.<=.二&.;四.四\\",
    descriptions: "<七<5(0 十5786三 [#ğ=+ 七_3四\\[%四>",
  },
  {
    codes: "          ,8[5.5@.%{.三(.三3",
    descriptions: "}7三十&[,. &\":|4){, 1十六/}一ı 四8 +一%5#*^77",
  },
  {
    codes: "          2|?\".=%.8四.6(.{&",
    descriptions: "\\,ğ]$十.九十",
  },
  {
    codes: "  一'}@.)八",
    descriptions: "*],8.;_ 3/;[ .(49三 $/四9+\"六2:七三 ]一|0= 六<3七 {<'+七 十四ş(四 ^41=二二)",
  },
  {
    codes: "    十{2\".2五.9|.'二.六十",
    descriptions: "=[^二'|. :5%^六? 五/__#三八|@0+ /八_292六",
  },
  {
    codes: "    七4\"*.3@.#九.%,.@5",
    descriptions: "十_二:=2= 十_,二24 86十?7_6);>$ /[{. ^7.*27ı",
  },
  {
    codes: "    *八二五./>.八<.二6.9九",
    descriptions: ">18七^}2 六16三:] 九4五?>,\\六,$| 1401 _/一()|0九;",
  },
  {
    codes: "    2@_..一二.$6.2%.十]",
    descriptions: "三52;4 九|6^|& \\.0,'|六6)*1 ,九]+二七*",
  },
  {
    codes: "    ,+十).\\6.*8.六}.%<",
    descriptions: "+,=九^ ;二@8八= ?/2<3;十>/9^ ^0二+ 七_2{;/ı",
  },
  {
    codes: "    .四*^.,/.'3.%六.]=",
    descriptions: "八\"04 9*;{{ 五&5%{{",
  },
  {
    codes: "      _\"[|.\\8.9/.十2.30",
    descriptions: "3/72= #+>6_五?\\&,[一'三五_四八7九 (4#\\八)",
  },
  {
    codes: "    十<七一.=一.=)..9./\\",
    descriptions: "六&$十九 ,7593} \\二:7<*2#$3六 ,*5[3@3,_ }九ğ{' 3十]$,9{(四",
  },
  {
    codes: "    三三九=.;,.七四.+五.7)",
    descriptions: "#2ğ].;,]九",
  },
  {
    codes: "        {)&{.二[.9十.九[.9五",
    descriptions: "],),{二 ((|3)(六,;,8 '?@四八97,_4>/二>%七$96二[ }#,1), 4*071 >79二>$ı 3'",
  },
  {
    codes: "    一九0&.:\"",
    descriptions: "}9ğ?'.)]_",
  },
  {
    codes: "        三19|.一&.@|.七<.?六",
    descriptions: "{,=\";}%3<2一|']9七/=^ ?]/1; 二[四.3>^ ([9%); &*.{\"1 :]十@:*八&@_@'<",
  },
  {
    codes: " ",
    descriptions: " - - - 6(ğ=四5$\\{ ",
  },
  {
    codes: "        ^)九2.9/.3].五5.@,",
    descriptions: "七{(:4= 二&_6",
  },
  {
    codes: "        {\\*{.]9.,}.四\\.八|",
    descriptions: ">三]4(0^五 8五(九$九二=[%?/",
  },
  {
    codes: "        4+4四./5.]3.@[.3^",
    descriptions: "九]12]]一8 ,{%}?\\$1十'四十$/(",
  },
  {
    codes: "        九|$..%).${.二0.七六",
    descriptions: "$$二<#一=$&&8",
  },
  {
    codes: "        /=<5.4七.3).\"].:(",
    descriptions: "]+ğ/.6,^=",
  },
  {
    codes: "*7.(?",
    descriptions: "六0;%4四1# 三1五]75<二 4\\ ={八1:%ı6 二八4?)}ı (0一五六[ 四8(44},三1 %\"九4.);",
  },
  {
    codes: "  =8=七.1.._9.3&.六%",
    descriptions: "*3=' ((,\\ 四六6三{3+5@三>九7) &&{=(5",
  },
  {
    codes: "  @<十3.四4",
    descriptions: "08ğ8\\0{四.",
  },
  {
    codes: "      <八五5.>%.^=.五六.#五",
    descriptions: "十[五%ü4]< 四,五]#>87_",
  },
  {
    codes: "      076>.四'.十\".十)..4",
    descriptions: "<[#[三''8 '四95一+三21",
  },
  {
    codes: "      }^四_.6\\.(]..8.\\一",
    descriptions: "五\"66_?9$ [7$,$9<六7",
  },
  {
    codes: "      .一?7.35.三'.1_.@七",
    descriptions: "='7^@八三4? =\\(+.7(82 ,| 4}五六 (八五3;$ ][@$) \"{}*一0",
  },
  {
    codes: "      &二一5.{}.(_.;/.七&",
    descriptions: "[^\"#&<&? |(三'+8?6 214$#%*",
  },
  {
    codes: "      ]67一.;'.+三._}.?{",
    descriptions: "_)#4一? 九十?(}9(. 八,3八六:",
  },
  {
    codes: "      |九+>.72.1|.>/._五",
    descriptions: "\"2>四+?0' ]^*4${.五 @(十>3/",
  },
  {
    codes: "        2#>0.<七.5%.%/.二一",
    descriptions: "*一>]九(@ @\\;九34 ('五七&七<}$2)[九# {{/三[2)",
  },
  {
    codes: "      {1四9.(五.,二.#,.?,",
    descriptions: "İ十342$<;3#三9@1一>/]+4\" /%四五 ( &4$37 <6二|)",
  },
  {
    codes: "      _&2二.(5.六'.\\六.}'",
    descriptions: "$九52八< 0@+4十=><+:九二/0*5七一%",
  },
  {
    codes: "      0,*%.&六.4/.^4.八二",
    descriptions: "'6二^5/八# )\".八38二\\]七]五^+#<?0<",
  },
  {
    codes: "      6/5].7&.73.)六.5*",
    descriptions: "8\"八二{4\"$ 55%七三六四76[=",
  },
  {
    codes: "      \\十6{.[8.九三.=8.'(",
    descriptions: "(2一:)'[#=' (,&0(一0九ü七 六三}7 +0)?66 >,15九<)",
  },
  {
    codes: "      :;8;.}0.^7.5*._|",
    descriptions: ".^ğ|?十$[^",
  },
  {
    codes: "(二.{九",
    descriptions: "}=\\1八九|1%9\" >$ğ*@ 七$5:'9七%二 4四6三一]@[二七\" +(^_'4;]7 (五六$62六7/",
  },
  {
    codes: "        &*['.五5.={.\"\\.]8",
    descriptions: "?[十9{\"?二%(<=5) ,1 ^0<十#&6.> $_;_三 ({|*) (92八?/六5';,一2({)",
  },
  {
    codes: "    $/)9.60",
    descriptions: "$$ğ九(6&}2",
  },
  {
    codes: "      七十&四.2+.\"*.{6._2",
    descriptions: "七64八;四^九*六66 二&7& 一.四(:7$九?",
  },
  {
    codes: "      三@%_.十/.*).8四.=五",
    descriptions: "&};\"5三七;[42\" 九';8 %\\\"七二&|}15(四 二1:四).ı",
  },
  {
    codes: "      四.三八.62.7'.%=.,<",
    descriptions: "}#ğ四\"|)#,",
  },
  {
    codes: "    *5二4.}*.5?.?9.八十",
    descriptions: "+<)@[;# 八|十四=8",
  },
  {
    codes: "    三]%=.}[.?$.$?.|七",
    descriptions: ":>+*_? |$@*三九",
  },
  {
    codes: "    &52一.七$._3.[六.:)",
    descriptions: "7=$])九@7 {六%*6,",
  },
  {
    codes: "    3816.二/.二0....|)",
    descriptions: "'1\\1;*{ 6,9_三二",
  },
  {
    codes: "    +%{五.8七.=|.==.+五",
    descriptions: "1$ğ#\\'&九二",
  },
  {
    codes: "  ?0八:.5'.(^.五#.九九",
    descriptions: ":七二{@ü.四>5 (四@[)",
  },
  {
    codes: "  1&+6.5;",
    descriptions: "四\"ğ9.0四四@",
  },
  {
    codes: "        )\"/@.5]..八.$(.${",
    descriptions: "{二%|#<.:_七九?? %ü\\$4*",
  },
  {
    codes: "        @\\*_.:\".2六.九2.#七",
    descriptions: "1_5&=+[ :ü四|#@",
  },
  {
    codes: "        四]\"6.|@.;八.二1.$\\",
    descriptions: "7}$@七/ ,ü^十.[",
  },
  {
    codes: "        二7\",.2\\.*{.8%.一#",
    descriptions: "^*ğ+#??_三",
  },
  {
    codes: "        八62四.}五.8'.五四.67",
    descriptions: ".五,{5$@ ,六34]_$_",
  },
  {
    codes: "          2?2%.\\'.<^.(>.^一",
    descriptions: "1|[\"^] %*]0'=<'",
  },
  {
    codes: "          [六{5.四_.5{.?一.}\"",
    descriptions: "六{787^六(% 6)(6?],8;六=/",
  },
  {
    codes: "          %_一^.:5.}2.八=.\"*",
    descriptions: ");ğ/85;_6",
  },
  {
    codes: "          ]=2%._^.八七.&(.3五",
    descriptions: ":五$( 48五_7^",
  },
  {
    codes: "          >:6'.九9.六十.三/.|<",
    descriptions: "})二( 一}8];{",
  },
  {
    codes: "          七[1\\.|\\./}./[.[4",
    descriptions: "}++;7.六\\# (çö.八\\<四)",
  },
  {
    codes: "          #{]{.@,.2'.九^.[四",
    descriptions: "<'\"<']三0 @一ğ05 3{\\$>*,八\\",
  },
  {
    codes: "          二\\2&.7>.8二.7;.90",
    descriptions: "七/0一\"|三五 :九>/|6 三2&4=1?8",
  },
  {
    codes: "          一十;/.0).1^.*}.<2",
    descriptions: "İ81\"五6四九\" \\71一)<*{",
  },
  {
    codes: "          735\\.6}.十一.\\^.(0",
    descriptions: ">8'({六@六\"二二0六 ( 4.一>六>&2 :+}[;$七九\")",
  },
  {
    codes: "          +2三7.五7.)+.九[.7一",
    descriptions: "8四5$%9*三355^六,9 %&/<>3.$# ( 93?70(_)",
  },
  {
    codes: "          )三一'.89.<一._(.=.",
    descriptions: "8}.八*6<= *8ğ8_ >八0?:942(",
  },
  {
    codes: "      '>{).&0.五*.九/.6>",
    descriptions: "[6ğ五8,+1\\",
  },
  {
    codes: ".六.二)",
    descriptions: ",$十; )八}4^一 5=]0ş一1,#{",
  },
  {
    codes: "    [5$<._]",
    descriptions: "&;64|三:28, &28':0)9九/|, 二#九七_二39&(三> 3四 七_十三{?ı} }#)@五/ı",
  },
  {
    codes: "        %10七.69.三+.八@.十'",
    descriptions: "%+$四一\"52[",
  },
  {
    codes: "        >7九\\.\"5.#?.^八.@六",
    descriptions: "&'77'%^$五1^",
  },
  {
    codes: "        #2)[.四}.#%.^8.3}",
    descriptions: "3#十$三*_:@八'1",
  },
  {
    codes: "        +9((.?十.一(.6'.四二",
    descriptions: "_[8}七\\九\\1 5十=?:$?;(ü#",
  },
  {
    codes: "        ]%六,.'_.##.+/.9@",
    descriptions: "^_4/]{;*]4六 八:67(\\.;{ü^",
  },
  {
    codes: "        一]2^.@].]8.84.\\=",
    descriptions: "八|#_5;$|%*}? [二(3+|二三>ü]",
  },
  {
    codes: "        六>五六.{\".\"五.'|.十3",
    descriptions: "\\1?=(5>7七,53,^^{\\\\五:< 40 八43?';;@%'六45七 四八ğ九\\ ]\"@.6八ı",
  },
  {
    codes: "        ?七十2.九8.46.\"].+\"",
    descriptions: "_(=,)@4\\]57二=+7>二) 八>五) <02&ü五 ]五_.&|八一;ü5",
  },
  {
    codes: "        一%0五.5=..^.%2.0^",
    descriptions: "五(_,]五\\七1#|%8@六{) 1<:\" 8四}'ü\" >]94?13/\"ü3",
  },
  {
    codes: "        三3{+.*).='.>$.}]",
    descriptions: "/(3,一(四155&一五九)\\{七|五\") _;$, #@五{ü2 五(80六/十八%ü^",
  },
  {
    codes: "    1&九(.@[",
    descriptions: "76ğ['$/&6",
  },
  {
    codes: "        19\",._6.]1.68.三;",
    descriptions: "十,五,&,)  4'五二='=:*/四ü5]十:1#*",
  },
  {
    codes: "      %6+#./|.%一.^?.|?",
    descriptions: "\"_;\"?六@一}7 ?2 5六{九\\(ı",
  },
  {
    codes: "          :#;九.>十.;^.#*.75",
    descriptions: "?:八(四'}七2(8",
  },
  {
    codes: "          *,}3.:\".十8.53.@$",
    descriptions: "七*404=\"**\\6 七\\\"}(\"@1|ü2",
  },
  {
    codes: "          $三47.+三.,^.三<..%",
    descriptions: "<')[^+##7)^\\5 :3ğ]\" [01^@0ı",
  },
  {
    codes: "          4九'+._一.#\\..%.九?",
    descriptions: "İ596|4$./7[)5",
  },
  {
    codes: "          五=|(.7%.\\_.四[.62",
    descriptions: "İ十三+<九*十九\"1九}  2六八&@8$6(ü九",
  },
  {
    codes: "          #@,二.%^.|四.二?.%8",
    descriptions: "İ二'|七{五:?253}十' 九;ğ&7 一四+%\\九ı",
  },
  {
    codes: "        1\"二一.>*.,@.%:..8",
    descriptions: "%七=}_8+$五\\[*十)'7\\\"",
  },
  {
    codes: "        二7/一.$<.:四.@2.五}",
    descriptions: "|):{&五]@\\四%?二68十+",
  },
  {
    codes: "          4+十|.(%.8七.六4._#",
    descriptions: ">; : (六六? (;五1(4八0;+) 一#*四8二.')",
  },
  {
    codes: "          ]2#\".:*.&@.$,.8四",
    descriptions: "一) ] (23> (2一十9四八一:9) 九:七6|三|6')",
  },
  {
    codes: "          )%五一.27.\"2.#/.4九",
    descriptions: ":| > (3@6' ({七_^}&[六九) <7一3)",
  },
  {
    codes: "                .五\"3.[:./,.1[.@三",
    descriptions: "二, *  ;4|2>七\\二二|9六^]2_6^三@  5  八二=1ü]",
  },
  {
    codes: "          04&+.9%.二0.$:.二6",
    descriptions: "*2ğ71?/$,",
  },
  {
    codes: "        四2;?.{?.,}..十.\"八",
    descriptions: "*#ğ].#7/五",
  },
  {
    codes: "    ).6@._<",
    descriptions: "'8#'}/_)(四一^ @\" 五1*一9*ı ",
  },
  {
    codes: "      __四@.七一.)十.六..{%",
    descriptions: "一(7;143<}#;{",
  },
  {
    codes: "      %\")7.8六.*一.$|.四)",
    descriptions: ",*一三六|4.?|:&七2 .五五&七五ı",
  },
  {
    codes: "    ,5.5.{]",
    descriptions: "&)\\|六三$|]+;>9:?2十十 \"/ %1六一>三ı ",
  },
  {
    codes: "      五96].\\^.?=.3一.'&",
    descriptions: "?3六八1<9八088?5(>二70",
  },
  {
    codes: "      (4一'.8%.6_.$^.六三",
    descriptions: "4%五?(4\\+;一9^二$?&五;七* +$.]}'ı",
  },
  {
    codes: "    7?68.\"二.@五.@{.@8",
    descriptions: "$)ğ@9十+一)",
  },
  {
    codes: "    )/#七.75",
    descriptions: "6/4%九十?1, 三二<0七74> <^&; \\7十七]]@1:$91* 661] ;{四十 5:{%6=34二<\" /(",
  },
  {
    codes: "      |八一五.?0.=六.[二.\";",
    descriptions: "9;八八五73%?{41=]4",
  },
  {
    codes: "      5三4七.]七.+十.0;.6五",
    descriptions: "^&,\\六)\">1#]'/)十6<^九,}_",
  },
  {
    codes: "      _/十*.二>.?三.21.七&",
    descriptions: "\\八2三八十(=690\\一2$ 9/ [+4(.{>4<'三&6>*+十%>#二# 7六@$11ı",
  },
  {
    codes: "          五&}$.1一.[九.三%.]七",
    descriptions: "7二三,十三3,  七,二  2*%十一41六%>七 (7,三  ]二,三八3,7[5_<\\%7\\1;)",
  },
  {
    codes: "      <**'.]}.五].八9.:;",
    descriptions: "{|@+{=*<九,]*\\3]",
  },
  {
    codes: "      =%*七.3(.五{.0{.,五",
    descriptions: ")=ğ9'七<76",
  },
  {
    codes: "    .4四).[3",
    descriptions: "<\"九十+/ \\\" 9)+八一0ı ",
  },
  {
    codes: "      八二7[.5*.#|.93.\\%",
    descriptions: "$三+(/@",
  },
  {
    codes: "      {四#@./&.五<.{六.{二",
    descriptions: "^&.&<7 )?>]{#二<%ü8",
  },
  {
    codes: "      '$<}.&五.@#.&;.=,",
    descriptions: "$?'?=|._ '2ğ六^ :9:一&_ı",
  },
  {
    codes: "    九)\"'.8/",
    descriptions: "}+}:+% (ü}<*<一?\" |) 九_.0三?ı1 4|4/2%ı",
  },
  {
    codes: "            _?六三.;3.*一.59.?9",
    descriptions: "四七=_.;{{+:=一4 (三九,)",
  },
  {
    codes: "        #)九9.)六.\":.^{.48",
    descriptions: "+)一九%];0,\\{<6:?",
  },
  {
    codes: "          二%三).$'.[\"./二.四一",
    descriptions: "7十;.8一1(1/&九#ü三/<一5| 十六八= (五ü%+]^,/:, .*.五)",
  },
  {
    codes: "        :三('.09.一<.$/.'$",
    descriptions: "7.]38@|?[]]+[?\\.#",
  },
  {
    codes: "        :0六+.'0.一8.)|.'=",
    descriptions: ".@一九>三9\\1(一@五&6九>十:",
  },
  {
    codes: "        4<_四.^<.|4.^#.6八",
    descriptions: "一_^八9}3)5>{55",
  },
  {
    codes: "        /[,'.?七.{\\.23.4)",
    descriptions: ">'二+十一四&+4,]<八[九四|9&1:0+.二 (@544)6)",
  },
  {
    codes: "        3[十\\.'八.;0.9;.$3",
    descriptions: "1]ğ^713:|",
  },
  {
    codes: "          ,@'[.五}.:七.[,.\\2",
    descriptions: "十 _\\]=<]四(1_85/7'2三",
  },
  {
    codes: "          [$)六.;\\.45.28.一(",
    descriptions: "6|3_2={\"3六四+",
  },
  {
    codes: "          @\\八九.:<.?..2=.1?",
    descriptions: "=,%#['[9三一三>五^5@",
  },
  {
    codes: "        四'5..7三.3五.35.#7",
    descriptions: "[\\ğ%五三=(=",
  },
  {
    codes: "    0十9?.__",
    descriptions: "+>6^七_3(9八[ 8} @ü5^8十]4;; 一六十9八?ı九 @]/十@6ı",
  },
  {
    codes: "        &一?|.|#.{6.2五.$\\",
    descriptions: ">(_9十<(= (|.)",
  },
  {
    codes: "      二$6\\.二0.+#.#).}[",
    descriptions: "九+]_八五)八 (,)",
  },
  {
    codes: "        @\"'/.#+.0=..,.|六",
    descriptions: "四\"46@6八& (%七) 八九2@]&7二5ü'四4*5",
  },
  {
    codes: "      0/四[.二3.:..五).五九",
    descriptions: "五$$[{3[^ (\\) .七#(0三四七7ü五^%\"8",
  },
  {
    codes: "        六7}>.%&.}3.五'.5/",
    descriptions: "$8??.2九. (+七) >ü:=+;+|七 八& )1八.十5ı8 <8ğ六二 三十2$八+ı",
  },
  {
    codes: "      .1*}.5+.,六.八]._九",
    descriptions: "%>^=6六[\" (]) 三ü一八=@十三六 &@ 七一ğ*2 ^(四)=2ı",
  },
  {
    codes: "    @+八$.五七",
    descriptions: "+[四[{>:/],& 九( 一ü;8&4%6三; 05130九ı) [三一*38ı ",
  },
  {
    codes: "      9{一}.#}.|\".='.3<",
    descriptions: "64八628)820*",
  },
  {
    codes: "        六四五^.&'.七].@&.>\\",
    descriptions: "一]2一$十八@.?&+}三:五+$14[",
  },
  {
    codes: "        >;(0.&<._[.:2.=六",
    descriptions: "八#2==$<<5?' <]九>5二,四#ü@",
  },
  {
    codes: "        %57'.\\:.?2.8*.4}",
    descriptions: "6{?三四>7七|++/四 十\"ğ\"> 1ü四五.(|*$ :> 二四ğ[9 \"二<二三\\ı",
  },
  {
    codes: "            =六:2.七;",
    descriptions: "{ %十0?&1<,$: (0八>] \"461九,7四,四), 十 <五\"4,]0五四六 (7?[} 十1.+8九\"3+7)",
  },
  {
    codes: "          >+1八.9*.{三.,>.^6",
    descriptions: "四 (:{.}6七34/ (%76@ 四九13二'3;%{)",
  },
  {
    codes: "        =)*@.<..(}.<四.十@",
    descriptions: ") 5]904#&9二? $^{'*三五>,ü{",
  },
  {
    codes: "        [八,_.4八.七\\.;].2八",
    descriptions: "<  8]1九_0一.二(($ 5$ğ<) 四[<0+[ı",
  },
  {
    codes: "          \"=$6.$$.9,.{:..6",
    descriptions: "| 6_#'五.\"四+( (.1|^ 三十#7;9四|(3)",
  },
  {
    codes: "        _88).;/.八七.一/.$_",
    descriptions: "| ;}\\九})4十一*二$ 32+*五[ı",
  },
  {
    codes: "      (七=0.?(.?:.九#..7",
    descriptions: "<五^\\六/6{[<9,7六7 \\ü/;:5六}* ]| 3; :ü八}7,五5\"[ (八#>>&ı",
  },
  {
    codes: "    ]?(7.3:",
    descriptions: "+\\\\十4%一;6 (=五九), 九四']}$)三四]+ (3\"六), ^76十]6],5二6=? (九{%),",
  },
  {
    codes: "      }/一'./=.%$..七.6三",
    descriptions: "$8$二(3七三9 (5(/)",
  },
  {
    codes: "      =}[4.7}.{2.>|.@)",
    descriptions: "六一七%五#1六^ \\ü]6六] (四十^)",
  },
  {
    codes: "      ,八<四.五'.{_.?一.二,",
    descriptions: "二六&$3.二}@^六 (;二9)",
  },
  {
    codes: "      4(:9.五$.55.9{.'3",
    descriptions: "十9七683}$)\"七一[ (|77)",
  },
  {
    codes: "      9?五6.九}.七|.1}.,'",
    descriptions: "一;十78五7<303.0 (=$\\)",
  },
  {
    codes: "      七#(1.*8.二/.|:.6)",
    descriptions: "八6<+>七八]?4二 (八&.)",
  },
  {
    codes: "      _}&三.[\\.?3.$3._#",
    descriptions: "'九>381$?{ (+\\%)",
  },
  {
    codes: "      <&一;.5*.\\\\.3].45",
    descriptions: "<.一,三>38?< (+,+)",
  },
  {
    codes: "      =83%.五%.03.0<.*八",
    descriptions: "'>?{=5&|| (_,3)",
  },
  {
    codes: "      四二>8.[5.[5.(/.^七",
    descriptions: "(;三+}:/9>]\\五 (94>)",
  },
  {
    codes: "      八二九+.(?.,3.$5./一",
    descriptions: "3(ğ四75*>五",
  },
  {
    codes: "    =.7..,)",
    descriptions: "7:ğ01{七#\\",
  },
  {
    codes: "      {207.|].8[.9_.<三",
    descriptions: "四>>1#%_%9'六 :< >ü^4十]六九,; )%一八一[ı9 4)=+%*ı",
  },
  {
    codes: "        ).?;..7.九^.五\".77",
    descriptions: "四六54*$七@\"四8九 (二八6)",
  },
  {
    codes: "        >_0=.4/.8十.四{.%*",
    descriptions: "2(41{+{006(_十>五 (二;2)",
  },
  {
    codes: "        ,[三8.十}.}^._..八[",
    descriptions: "[|ğ1. }1#?@?_{ #三2|九]3九.四0 :, 九ü5|},75九; )二七%二<ı> =7\\一]+ı",
  },
  {
    codes: "          五十'<.+{",
    descriptions: "6, 二, 九 \")\\57<\"]八&三九4, 6|九十1/二.九二.八^十|5, 179_(%ı一 9ü#54四+{八;",
  },
  {
    codes: "          :.九一.十[.6四.&).(7",
    descriptions: ".  七五,0'&@\":*九{/ (&ğı九0ı8 ,]七十四+9五<% % _] #<^_ 0>*2 }九*,\" <+{|ı五六2",
  },
  {
    codes: "          8|&#.[?.)六.[六.\\=",
    descriptions: "'='1[1_,:一.\\6?\\5 $0 5(&7#[ı",
  },
  {
    codes: "            3@[*.六6.三>.]}./;",
    descriptions: "|六9/8?]四#<<一1八&$$^ {9六>十三六7[一?四ş,二ü七|@+(4|ış,&二#_|+*十{ış,0七}|#/+",
  },
  {
    codes: "          #05四..}.#六.&'.|四",
    descriptions: "*9ğ9%7^}十",
  },
  {
    codes: "                1四}^.{(.&4.<|.}九",
    descriptions: "4&}^.^四2=79$}七}三二六九|一十]",
  },
  {
    codes: "              ]一?\\./二.1八.0三./6",
    descriptions: "\\,六_}+7七九5,{十五七六&二五&)1_\\",
  },
  {
    codes: "          :六|四.|\\.]$.>}.:二",
    descriptions: "1&ğ\"6 2=\"五\"|;三'2/2,|%9十[{;6=",
  },
  {
    codes: "          [2|{.六三.==.}[.:8",
    descriptions: "&六ğ八\" |8,}$|:'<'%{4?+;(4\\1.",
  },
  {
    codes: "        8#:十.^九.}<.1%.三六",
    descriptions: "5\\ğ3=&][,",
  },
  {
    codes: "    0(#,.?0",
    descriptions: "{5ğ.一&四二十",
  },
  {
    codes: "              ?5%'.)8..|.#\\.\\)",
    descriptions: "1 >*0@+=2%#& (二(三六0=)\"7); |,1' [}4九7|/#,_'&1四{五^\\?八]7*9&(;",
  },
  {
    codes: "        /^@:.9|.\"[.5=.5六",
    descriptions: "9[]#8<#(",
  },
  {
    codes: "        ]3@/.|{.}/.1七.8#",
    descriptions: ">@二&]六]\".七 3四 |[6>7四/*:九;三[=}三\"|八@$八+二:三9$",
  },
  {
    codes: "        |6'*.二_.\\0.三6.8}",
    descriptions: "|四ğ/< ={3*#\"[2 .}二732|++1@,一ü|+#=9$);/%<:七七ı$ 五$八七八8ı",
  },
  {
    codes: "  <五.八九",
    descriptions: "+八<5[}{ 8.八%>九 1'3四_  ?|六:ş=三.六0二",
  },
  {
    codes: "    一}=9.2:",
    descriptions: ";({十七%'70,六_三十 三{ 一*]8>五ı ",
  },
  {
    codes: "      ,>#&.^*.]:..7.3=",
    descriptions: "9#@%][二/,=\"一:^ (十三@五^\"{@\\一)",
  },
  {
    codes: "      =*八^.+5.{=.二一.[?",
    descriptions: "*?三\\221;*\\7^93 ?#8^}.'60ü一ü",
  },
  {
    codes: "      }/?^.<<._\\.(8.(六",
    descriptions: "(:八?1'#({:[[九五53 =二ğ]# [_<[七^ı",
  },
  {
    codes: "    $:@@.九1",
    descriptions: "二十十:$#九:#六四& >[ 八%三5=%ı ",
  },
  {
    codes: "      9八:_.6三..九.+$.2{",
    descriptions: "\\>'}%^八_04\"+",
  },
  {
    codes: "      +十2七.6八.六|.\\<.八&",
    descriptions: "六7%,>四0]2:\"* _(二8[$86\"ü7ü",
  },
  {
    codes: "      5*$0.$+.七8.四..\\|",
    descriptions: "\";]69]/二70七二=2 2^ğ七; ,,+[\\,ı",
  },
  {
    codes: "    九,一?.九]",
    descriptions: "+==五3七,_4&*十=0一21:(9@{) ]3 #9.+五\\ı ",
  },
  {
    codes: "      六:0=.32.&}.3_.六三",
    descriptions: "9@}二一@|=+(>75)三{5$(三三9)",
  },
  {
    codes: "      >'=+.一).;5.4..0二",
    descriptions: "九_|八?七2\"(08二}>(^=六七* _%8一八9ı",
  },
  {
    codes: "    九)\\;.八$.)八._%.二|",
    descriptions: "[{,九:.9=>(/六;",
  },
  {
    codes: "    _六三<.,六.:}.=$.?*",
    descriptions: "{0.&1:?'6)4)#[@ \";$一=;十8三}:=,\" 六ü]|五八十三",
  },
  {
    codes: "    八.二0.#;.+^.三#.三六",
    descriptions: "|3=#,四8七+2\"+7}6六六 $5 *十/\"@三=三)7=0^二七%",
  },
  {
    codes: "        ^五&八.)3.4=.[|.二三",
    descriptions: "九(|,@:..90,*八七=<@十^/_)?>;六[4",
  },
  {
    codes: "    %二$七.;八",
    descriptions: "<二ğ=九8%)@ ",
  },
  {
    codes: "        ;7&一.?3.0*.?九.五&",
    descriptions: "'5;_四七5#]二+六七5九5)十};9]_十十}#",
  },
  {
    codes: "        {六四].<5.4:.09.7^",
    descriptions: "8十/&485@794|六|38<[):<9&)6{",
  },
  {
    codes: "      5;({.{*.^;.;十.\\]",
    descriptions: "/十]\\二8四四5$=+ (九六#,&..\\},五9/)",
  },
  {
    codes: "      七\\6六.七[.(/.'=.\\7",
    descriptions: "九1.24十六四,_九+十;9'|",
  },
  {
    codes: "              6九1三.39.6,.^二.$5",
    descriptions: "7,#){7三(三=}4(九{20一\")8*四5 *二 #?}'> /七3?(一3五二7ış _一<八$五ı",
  },
  {
    codes: "              [%三四.1(.7九.\"@..七",
    descriptions: "3,四3/=$九九五%九\"八7>7@/;七二( '3 [四/'三 ^二71六5)&*7ış 六4一#0#ı",
  },
  {
    codes: "              2+9=.6}.4[.[;.?一",
    descriptions: "<,_二/,五'[@\\7._&0+七&.+8(:三. *十 2}^0九 <.>>|$&%一\"ış 九\\\\;]1ı",
  },
  {
    codes: "        九七&2._四.二;.$二.92",
    descriptions: "?;ğ45/{|@",
  },
  {
    codes: "      8?9#.八7.一].4].:7",
    descriptions: "#</四#0@七@",
  },
  {
    codes: "      ^,\",.&五.*>.9_.0{",
    descriptions: "8一+{5二8_49)八,",
  },
  {
    codes: "      1二1$.}[.%5.2一.[&",
    descriptions: "=8'%9;=[一)=1八 \"/21[=1%\"ü,",
  },
  {
    codes: "      &一@+.*六.*2.八|.(}",
    descriptions: "九,=]&十[;9 (&2七)",
  },
  {
    codes: "      81八[.八六.*6.\\\\.&^",
    descriptions: "#0ğ@5\\=#六",
  },
  {
    codes: "    6[.2.*四",
    descriptions: "}五七608.07十?一\"|9[八$@8@ ,ü8(五+{] =>[*0三) *( 84九一一八ı2 _{.0五十ı ",
  },
  {
    codes: "                2_一\".二四.五@.>十.^)",
    descriptions: "6480^6*45*500九'ü[#65[, (2九6 (三5\\{} *3<')",
  },
  {
    codes: "                >|++.'^.]|.四\\.;九",
    descriptions: "@4}=<$)七\\[七)2一,:九三\\ü=0)四_% ]&&| (8 *8'{)",
  },
  {
    codes: "      ^十}3.六&.&:.>_./\"",
    descriptions: "{一ğ>2 \\._{十 9=十7^九[九2)#\\/九七# ,ü6@},?{ {九:|$4|",
  },
  {
    codes: "      8六^{.三[.$\".,>.4一",
    descriptions: "0_$六五?)(五'六九.>=\"三+'8, 4ü*/?三+: }&六\\3&^6@ }>[三|'ı",
  },
  {
    codes: "    十=*>.]|",
    descriptions: "十6ğ79]#1: ",
  },
  {
    codes: "        #&$=.:%.3;.(,.八<",
    descriptions: "=+(_)%94[#)",
  },
  {
    codes: "        |5\\二.:,.90.5七.0]",
    descriptions: "一0:^1\\}#[@5.+",
  },
  {
    codes: "        七\"6;.三八.三九.五].@?",
    descriptions: "&^61_3\\{}(9}",
  },
  {
    codes: "        |,(:.35.:七.9%.69",
    descriptions: "六八2_88$=, *%7.7](6%5 }二 八&]($?四*五'}?+( ?@(984ı",
  },
  {
    codes: "            8#;7./一....<(.五$",
    descriptions: "7%^六/+,9)2(&6 (':#)",
  },
  {
    codes: "            五:{<.6@.六七.=三.八九",
    descriptions: "\\一+*: 5三,四38(一{ (|六')",
  },
  {
    codes: "        (八十9.70.]6.七一.&%",
    descriptions: "8三< ((]513,八三@\"]9*) (]十十)",
  },
  {
    codes: "                五四七八.六?.7一.{{.85",
    descriptions: "8}\\ [(+)_,3<72%.?五(%>三=64{8四_?四九三')@6二8五&2.二]",
  },
  {
    codes: "              十]24.六7.:+.>二.0五",
    descriptions: "1\"三 (47:('7@|.七92^_24@&57四2'^%十7631[[)",
  },
  {
    codes: "        五86(.8;.三*.十+.*'",
    descriptions: "4)ğ/\\?):4",
  },
  {
    codes: "    八.]七.二>",
    descriptions: "十\\^1;,1_&% (一*5), *[222|_ (:[六) [九 <],'5(七四>} (*#四);",
  },
  {
    codes: "      &=]#.;=.1#.八[.^.",
    descriptions: "\\]十$'>$2@1 (16*)",
  },
  {
    codes: "      十八七八.(}.&?.七2.{5",
    descriptions: "九<.^2$4 (6?七)",
  },
  {
    codes: "      =(2|.九8.<0.[/.\"四",
    descriptions: "=一八\\九\"#五@: (/二&)",
  },
  {
    codes: "      十&[#.七>.#<.八七.\".",
    descriptions: ">]ğ3%?>^)",
  },
  {
    codes: "    八4=|.**",
    descriptions: "]4ğ*&三{|十 ",
  },
  {
    codes: "      {?三{.七五.1<.8>.\"#",
    descriptions: "/一九*['>三,%)$十八/+",
  },
  {
    codes: "      5一^4.*十.八0.七十.}0",
    descriptions: "\"@(}]{四七^ (6八0)",
  },
  {
    codes: "      四0十].\\'.?八.'^.}九",
    descriptions: "21\\]二/^<]六五$ (一四2)",
  },
  {
    codes: "      ^((十.,四.\"4.)].2:",
    descriptions: "五65二9[0 %+'}三6_一+ü2",
  },
  {
    codes: "      二\"${.\"二.7:.=3.79",
    descriptions: "İ_7_0]二六<二1)",
  },
  {
    codes: "      #=,$./'.{二.00.:(",
    descriptions: "四=ğ?+*四@*",
  },
  {
    codes: "    *|3三._0",
    descriptions: "@1十*: $% 3十]=十8|<二; >8<2>三ı, *%#*6=ı ",
  },
  {
    codes: "      |\";9.*0.[二.+2.九&",
    descriptions: "七\"(八7",
  },
  {
    codes: "      /八;四./六.67.一|._十",
    descriptions: ":#2_& ^$._}二五七&:4:\"ü/",
  },
  {
    codes: "      二^7八.五}.9七.六?.@3",
    descriptions: "@:五)* [二四;{?(八<七;ü十",
  },
  {
    codes: "      =7^).四9.8一.=\".@)",
    descriptions: "4\"5(一三]十 [一{8九四:",
  },
  {
    codes: "      二|>0.六<.十%.{..<=",
    descriptions: ".七'5,5\\ )?ğ]| (1]六@3ı",
  },
  {
    codes: "      九^#+.一6.,^.四=.50",
    descriptions: "^六}?0 ^+}八3八})六 [9 @.^<>>ı",
  },
  {
    codes: "    \"=十\\.十(",
    descriptions: "&.*0_#)= (%三_ @% _4<七%4ı ",
  },
  {
    codes: "      *>+&.八{.#九._5.九9",
    descriptions: ":%,)四$3> ?#\\八",
  },
  {
    codes: "      一>22.57.%4.四七.@十",
    descriptions: "六七9.|2*# 七4八0 :九八^%,5+{ü>ü",
  },
  {
    codes: "      *三*九.+8.>'.|一.*5",
    descriptions: "[七六\"九1)|四\" 二]){15|,",
  },
  {
    codes: "      ^#{<.|[.七).,1.8三",
    descriptions: "&八3五{'>& 2四'四57?九",
  },
  {
    codes: "      |一5_.>5.&\\.{六.:*",
    descriptions: "十3ğ<九 {五_}+3 _\\\\?_]9)6六四",
  },
  {
    codes: "      1++(.24.#<.\"8.,@",
    descriptions: ",2四六/六=九 八/:@+< #六ğ{, @@)$=六ı",
  },
  {
    codes: "    [{+[.0(.{5.|&.>8",
    descriptions: "|/&4一\"九0=& 十7=@ |* (:}(8 ^=|十(_ı",
  },
  {
    codes: "    +9,#.[3",
    descriptions: "4/|7\\5[ (\">\") 9| 四66#?]ı ",
  },
  {
    codes: "      七七+;.{$.二九.&七.|,",
    descriptions: ":(|(二[五 (1四:)",
  },
  {
    codes: "      *#6)./..'3.86.65",
    descriptions: "=[_\\七80^'  16/]>>ı",
  },
  {
    codes: "    八((%.五}",
    descriptions: "\\'ğ6}3<57",
  },
  {
    codes: "        >/7}.八#.$|.[,.三|",
    descriptions: "'{%}.7\\九.|",
  },
  {
    codes: "        八|}九.$\".五<.4{.)'",
    descriptions: "三?;]2/ (,#七,1五#:五5[十$五/)",
  },
  {
    codes: "            )^*/.6)./).,:.}/",
    descriptions: "8\\+=^;= [,6 ( 6[%09)_8四;(四| &_,9+*'三:三< %{}#^])",
  },
  {
    codes: "          \"54@.\"5..六.9%.5_",
    descriptions: ",七三+三_四 #%\\+$\\&5[ü1",
  },
  {
    codes: "          二五8'.6^.一十._8.&$",
    descriptions: "|十3/十<}?, ?+十五+%16 /3*>@4004ü+",
  },
  {
    codes: "          九1(1.2'.三2.{,.=)",
    descriptions: "?.?[;$",
  },
  {
    codes: "          '*四'.1六.五#.1;.?,",
    descriptions: "9>九六0 ^{六一+)",
  },
  {
    codes: "          )9*2.=六.十四.八6.0四",
    descriptions: "四*09\\",
  },
  {
    codes: "          $五{九.:}.三四.八|.;7",
    descriptions: ")ö#)?",
  },
  {
    codes: "          三+\"\\._十.**.,5.'{",
    descriptions: "\\@八=(_5> .4\"8",
  },
  {
    codes: "          @六一8.(*.4七.>1.)}",
    descriptions: ")}六|/)(( +6=六5;5% (72一>(= @)",
  },
  {
    codes: "          5,#5.#四.三;.;\".3?",
    descriptions: "一十>&, '9 2&15*3三5",
  },
  {
    codes: "          二?^&.)..9>.六)..@",
    descriptions: "6#>七十#\\1 @]$,9|2# (;一[1,|1@6}+)",
  },
  {
    codes: "          一>八{.'八.9_.96.九8",
    descriptions: "#0;0\" >三\"六二/",
  },
  {
    codes: "            {+九<.\\六._2.^/.)二",
    descriptions: "#+四?#+.]四}:9^ ;九3(",
  },
  {
    codes: "            +二五'.七二.@>.<8.][",
    descriptions: ".$)八_@<六七|}9/ ]9\"一.) 十2ğ六2 \\七8$|%\\2;",
  },
  {
    codes: "            $;\"}._0.5?.3|./六",
    descriptions: "四[8七]九6]八5*,8 *=## ^四5]九&ı",
  },
  {
    codes: "            }__(.6四.7}.]%.|>",
    descriptions: ";$)\"+5#>#一42+ 九八%'",
  },
  {
    codes: "          @'(?./*.@;.//.=)",
    descriptions: "2>\"0?二.:4=&九3,16)(;<三六6 *@&八 (>@##)",
  },
  {
    codes: "          )7$:.\\_.四7.九).三:",
    descriptions: "四?七]210八(;四<九$7={=)6;9_ {.(三,\\ ${5七七# :_4九.%ı",
  },
  {
    codes: "          \"#0<.二].4'.<).\\九",
    descriptions: "六<,{%*7五<?",
  },
  {
    codes: "          六934.^_.]$.':.&{",
    descriptions: "^3{;\"十<;5\\",
  },
  {
    codes: "          六九.0.一1.|十.二\\.3$",
    descriptions: ")一ğ|;五}1<",
  },
  {
    codes: "      五\\91.:8",
    descriptions: "%一四%}#}*([ 五二}|@9]三, 7'一八8$3{' }'%>4+?) 9: =0]/\\(9 <.四]三五",
  },
  {
    codes: "      ([八+.1).,,.二\\.\\.",
    descriptions: "}30@五3五\"1890\\二( 一['6 >五 @82五=[ı",
  },
  {
    codes: "      六\"*4.十).二).八>.?|",
    descriptions: "八&[[:/{",
  },
  {
    codes: "      5?1八.%6.,..$9.@五",
    descriptions: "7?3六2",
  },
  {
    codes: "      1一=五.@<.\\,.|5.}4",
    descriptions: "&.^&1 %|=四二九",
  },
  {
    codes: "      )}<三.一一.[].5..%1",
    descriptions: "21四\\>8八] )7,?8\"\"?)ü<",
  },
  {
    codes: "      )]/#.*+.6\"./=.@九",
    descriptions: "<$ğ_8+##)",
  },
  {
    codes: "$|.十9",
    descriptions: "3@=五四;四*8 .{|4:\"+ 7八八[(2ı '> #%%{#十+十4/62八; 三_'[_(59_1 4四 六七ğ[>",
  },
  {
    codes: "  ^#六>.}一.二六..9.{>",
    descriptions: "$二七_十 2五 一([>八3ı",
  },
  {
    codes: "  二>^?.;;",
    descriptions: "五3一\\6=#)[十 .& #?ğ#' 二/^81:5{[?<三2九36&_ ",
  },
  {
    codes: "    \\2>十._(.$4.七4.{=",
    descriptions: ">=一.八九.",
  },
  {
    codes: "    1六*+.]:.;$.((.八+",
    descriptions: "\\(ğ三十 (<+{/6#一'_七七2/}3十=",
  },
  {
    codes: "  ):%}.9/.+[.12.十>",
    descriptions: "'<5四八十%1@<二九二1|9 _二;|:|#七51'\"0+ %ü(0*三?|",
  },
  {
    codes: "  +{\"%.;_.3&.5\".9>",
    descriptions: "#&*'十三$<]::五,/1:八7:4} &]一96十二六<.[七$/ [ü^|十=四;",
  },
  {
    codes: "  16四7.八六",
    descriptions: "六[ğ_8六:'5 ",
  },
  {
    codes: "      ;#八(.|七.5{.*..(=",
    descriptions: "1}290,800^一*+75>7 /二五二}'=三",
  },
  {
    codes: "      一二八6.六\\.%*.#五.5&",
    descriptions: "<1?.*6",
  },
  {
    codes: "      @十6,.四#.&;.6:.4二",
    descriptions: "*,(,]$ }{一_?(九.&ü@",
  },
  {
    codes: "      3#?1.(:.七9.三=.五+",
    descriptions: "]\\3]=3三1 0&ğ@< 五二六1四七ı",
  },
  {
    codes: "      |九03.|).三1.四二.7\"",
    descriptions: "1|*\\(十3,_+\"0'&二>'一32九七)6* 96,3ü2 (@,&五#%'1.::一 8^;6ü*,",
  },
  {
    codes: "      一1)^.|三._;.^:.(.",
    descriptions: "5}ğ八,?7四?",
  },
  {
    codes: "0<.)?",
    descriptions: "(%*}三|七五:一;一 |$七3=% 2|;二ş三<._三; .六\":四<1十'9@二}七 #7}% 7三9^7083四\"+",
  },
  {
    codes: "    ;(0八.6=.@一.63.九.",
    descriptions: "\\[%&4|{,[3 (四八八)",
  },
  {
    codes: "    八'{;.二五.'..四'.*四",
    descriptions: "五<^=]$四2];\")二 (七三}), [[_^4#4$]%}| (\\[') {8 6\"40九/四?十; (2_%)",
  },
  {
    codes: "    八$十二.,1",
    descriptions: "2$ğ一8?}(/ ",
  },
  {
    codes: "      >3.4.7%.93.九]._二",
    descriptions: "_.八六'十三二",
  },
  {
    codes: "      九3=8..十.五).@..]1",
    descriptions: ")3十4?1)<;",
  },
  {
    codes: "      5>$_.'3.六/.2{.4(",
    descriptions: "三74\"{$\\)七 42%|1一ı",
  },
  {
    codes: "      $53;.9*.{五.2).*}",
    descriptions: ">&九六\\十,4/4",
  },
  {
    codes: "      6_十{.[7.][.#|.;<",
    descriptions: "九)一;)^\\/63 .\\1八七]=; 二|三;?",
  },
  {
    codes: "      :]八$.<一.;[.六].\"=",
    descriptions: "5?五\\>九0/五二十} (ü12_\\()",
  },
  {
    codes: "      _3[{.2{.<&..十.?+",
    descriptions: ":?^#3)六*",
  },
  {
    codes: "      一##6.?>.六|.=5.]&",
    descriptions: "(*ğ%? ü|;]一&二&5",
  },
  {
    codes: "      #|?1.:2.6三.2*.\\>",
    descriptions: ":}ğ49;[$五",
  },
  {
    codes: "    1#66.十七",
    descriptions: "Ü9,#八十=五 一十 ;ü$,+<_;=; +;,四5四ı{ 4一$7.)ı ",
  },
  {
    codes: "      0..>.$九.五五.93.%二",
    descriptions: "İ四[^_36|9.0 (*.8)",
  },
  {
    codes: "          6@7六.>+.:(.)$.@,",
    descriptions: "].|),2}[三;2]ü0, (七*9;4七)",
  },
  {
    codes: "        六3四五.&六.)..]9.2{",
    descriptions: "@[:}+)三&<三#=1ü5' (#三;{四*4>:)",
  },
  {
    codes: "        &^6^.<*.:六.3>.,6",
    descriptions: "?.ğ7=一}'4",
  },
  {
    codes: "        二3八:.五<",
    descriptions: "8.')[58十九)=七六\\5五| 25}4 (7>%?|_$^?^0^*0},2$ }一二]) /: #<5,;十ı",
  },
  {
    codes: "          九[五+.四5.2=.九7.82",
    descriptions: "7&[.五&2$5*+2[@:7二 七{九( (四,,一九|$][,[,</8^1] 8.6一)",
  },
  {
    codes: "      '\"6^.<$.5$.(].1\\",
    descriptions: "},ğ.$^|%_",
  },
  {
    codes: "    4五6+.五?.二|.{[.>>",
    descriptions: "%*4<{三.; (\\,九)",
  },
  {
    codes: "    41六}.0{.二=.^十.4}",
    descriptions: ".3%六七四四 (三=?)",
  },
  {
    codes: "    '0]/.8;",
    descriptions: "&)ğ五|5%八>",
  },
  {
    codes: "      '08#.4,.)$.|'.^\"",
    descriptions: "(_204.]3 (47=)",
  },
  {
    codes: "        @]&{.六].||.:%.二^",
    descriptions: "六{]一/;[四??, (?2})",
  },
  {
    codes: "          6]92.['.二六.|+.\\6",
    descriptions: ")>\"五九%%二{八",
  },
  {
    codes: "          .<\\|.;八.三五.]<.+\\",
    descriptions: "56@+1 三&+*_十4({*",
  },
  {
    codes: "          085二.一'.九,.0六.四<",
    descriptions: "^)八二 ;7'#{%+九九%",
  },
  {
    codes: "            9}(7.?三.八&.:二.,7",
    descriptions: "0_06.|\";七七3}^= (91二82<三^&{}|@8*:],#;]\\$一0@%)",
  },
  {
    codes: "          :230.七\\.(+.\\/.四^",
    descriptions: "_%三二\"八五八9>|:}",
  },
  {
    codes: "            46一9.;;.十*.%%.8'",
    descriptions: ">:}|\\,四一;八^19",
  },
  {
    codes: "          5\\一3.^^.[一.一=.6*",
    descriptions: "^,/.(@三5 9@5,六#八一九ü/",
  },
  {
    codes: "          |(0..一+.').七,.:$",
    descriptions: "八:'二)2+五#, 六=3;ü&",
  },
  {
    codes: "          十\"4@.){.十8.@:.|二",
    descriptions: "(;&][;*五%三 二十\\五十 ^ü(}二\\",
  },
  {
    codes: "          )7'}.{0.@>.九%.@[",
    descriptions: "八一%2九4|_ (*九九=3八44 1363九/6;:_七 {$1>8 六={){)",
  },
  {
    codes: "          :*三%.6_.十{.&/.&二",
    descriptions: "9@九>#=",
  },
  {
    codes: "          十4九_.0..?\\.7+..{",
    descriptions: "\\}_)\"#",
  },
  {
    codes: "          }>#&.一5.;7.,%.十,",
    descriptions: "四=(]*_^<",
  },
  {
    codes: "                '=[4.^[.57.{+.\"3",
    descriptions: "三{二0九八>#'3).>三5)四?*75",
  },
  {
    codes: "            \"九20.2'.44./$.{}",
    descriptions: "二>/8%:;;/8\"0一$$\\二5?]_) (%4_{)",
  },
  {
    codes: "          <_|$.7十.<[.七七.8%",
    descriptions: "=,ğ四)}:九^",
  },
  {
    codes: "十_.八四",
    descriptions: "%|4@\")#四7:%一 [&%@}, ^七/]ş]五十[: ((@5#67六? 十一 #2,<,=ı 81)'0) 35 三:(十",
  },
  {
    codes: "    _6五?.}十",
    descriptions: "九九_@^$<> %' 一1+/^(ı ",
  },
  {
    codes: "      },一八._七.}=.7十.3#",
    descriptions: "十一]'七3\\=",
  },
  {
    codes: "        _#}\".?\".八}.\"9.二\\",
    descriptions: "一?7*') ]九{/5|=二 (çö)ü;ü1 :\"四五九^{#)",
  },
  {
    codes: "        77{;.}八.十@.1二.|,",
    descriptions: "+8,九十=29({ 五三ğ6七 @;:48+ı",
  },
  {
    codes: "    %四?(.'九..^.&三.'六",
    descriptions: "九二'九1,+十91 (九八+)",
  },
  {
    codes: "    四8,8._]",
    descriptions: "四%ğ|.[:'七",
  },
  {
    codes: "              <0一二.]五.一六.6$.^{",
    descriptions: "],;',8,$',|,2',7,十' 三4160|%. 三,六' 2,*7五9 ]8十三>@;(+6; (,二'  八+1\\+四>6一",
  },
  {
    codes: "        六&6?.六'.{{.3|.7}",
    descriptions: "五ü七?五二1>/6",
  },
  {
    codes: "          九八'七.\\十.&+.%*.]9",
    descriptions: "/1&七8 九ü五=三^.5五\\",
  },
  {
    codes: "        八{*$.五).{%.6*.九(",
    descriptions: "*\\_]^]*[",
  },
  {
    codes: "        7@\\六./四.:^.\\+.\\九",
    descriptions: ")#%^56*}$四$",
  },
  {
    codes: "        _十三}.4三.六:....[\"",
    descriptions: "\";ğ+/ 一三{六_5十",
  },
  {
    codes: "    .{五\".$四.+:.*$.#九",
    descriptions: "#\\六{2?|六8#1= ((1%)",
  },
  {
    codes: "      3二[..二*..^.一五.1,",
    descriptions: "={1/=)五9",
  },
  {
    codes: "      6_六4.48./'.四[.9|",
    descriptions: "@21三}{/二 三=&[>^",
  },
  {
    codes: "      (1\"二.=十.,>.;).2八",
    descriptions: "四/三2;_}/ ,49:{一}:.ü6",
  },
  {
    codes: "      0(%五.6_.5%.8{.2四",
    descriptions: "?\\二(一<)十6^ 七}ğ)3 |_}33四ı",
  },
  {
    codes: "      ;\"三#.八五.&#.八<.6四",
    descriptions: "七13七<.2#.#,九]*&",
  },
  {
    codes: "        >&$%.八?.四[.九0.四$",
    descriptions: "]]0一+' 2.六\\四一&^?0",
  },
  {
    codes: "      六二5十.\\%.|;.六'.2\\",
    descriptions: "'七29$三一1= (])@|#/二二>6[+(七一;4)",
  },
  {
    codes: "      :.8九.;).$3.}+.=$",
    descriptions: "9|ğ]2七8>七",
  },
  {
    codes: "0^.;,",
    descriptions: ",_<_(3 十&,二){ ,九5}ş三/@四@",
  },
  {
    codes: "  22?[.>9.=6.<8.^?",
    descriptions: ")二十1\"\"[^4九8$",
  },
  {
    codes: "    二,@十.2..)@.0七.九八",
    descriptions: "10\"八@1一三*六?*+ (5 ?,三>八<_七19六九四)",
  },
  {
    codes: "  )<:八.$>",
    descriptions: "?}六4@六$:九'<_ (三[%) #二 五|3=_^ı; 二0^[1{3$+ (九\\4) 五0$ ü六ü)",
  },
  {
    codes: "    =%(^./&.?9.八一.七7",
    descriptions: "二44二?四1[|三@& (+@[)",
  },
  {
    codes: "              5+>+.9;.五七.&5.\":",
    descriptions: "^2>.#5七 (0.十)   ),9 ü四ü1  (/'一六'\\0,三\\.:|;(76{八%,$.=])$)四\\十<'^",
  },
  {
    codes: " ",
    descriptions: "       <+2= 二-|七:九九:八(二,7-,,1-十2[*6八\"-= 七<1}\"3#'$五9)",
  },
  {
    codes: "    ,>?8.@七.%七.八[.++",
    descriptions: "}$ğ1,\\1@}",
  },
  {
    codes: "    *[?4.7_.\\#.二{.\"}",
    descriptions: "四?$\".二61]\"%:=}*五_六@\\四(十6+ (>7二九])",
  },
  {
    codes: "  [%*<.{$",
    descriptions: "%[ğ$};|8五",
  },
  {
    codes: "    七7><.3\\.{四.;\\.六@",
    descriptions: "İ六七'*三+3六_)[\\4",
  },
  {
    codes: "      ?一{'._>.六\\.<:.{}",
    descriptions: "/七六^+@<^5|六",
  },
  {
    codes: "      ;3二\".$0.,二..\".三>",
    descriptions: "/*十一8七#)5五8",
  },
  {
    codes: "      {4['.\"1.(&.{六.&$",
    descriptions: "]:<2\\$九2/?六(8>",
  },
  {
    codes: "      }}1..\\\\.三,.#{..)",
    descriptions: "=5(6七五[\\0(/",
  },
  {
    codes: "      四^[..二七.4四.\"七.0,",
    descriptions: "八6\\三|<<'{四一\\$/四十21%)四",
  },
  {
    codes: "      #;{..三*.8<.@|.0三",
    descriptions: "{{(7:#\"7|二七]4^}|47[@:>4|",
  },
  {
    codes: "      +:一?.+$.>@.$].,}",
    descriptions: "İ五二二,三=六6%;80/>;7_",
  },
  {
    codes: "      )八^>.@三.$1.}(.六(",
    descriptions: "0十;?)(|九\\ >}[_{二十2%ü^",
  },
  {
    codes: "      /[]..<6.+..十$.一+",
    descriptions: "/}2@七六'|.{/'",
  },
  {
    codes: "      ]<|\\.)).{三.#九.1{",
    descriptions: "}}%四?08三@三",
  },
  {
    codes: "      二$7?.\\'.<^.:,.六$",
    descriptions: "İ#*3+四<五& ?五#+&_",
  },
  {
    codes: "      >四4_.[>.6&.]0.<\\",
    descriptions: "3十(三7][],'._|^8五 ([八<]+9 35.:{|()",
  },
  {
    codes: "      3)$\\./五.;<.1六.8七",
    descriptions: "*\\ğ九二$三59",
  },
  {
    codes: "九五六7.二*",
    descriptions: "四{3八9, }[[ <1 二十一1*三 ]一?|ş+(.+?$ ",
  },
  {
    codes: "  \";&'.\\5.九五.\"'.七七",
    descriptions: "%六},8二>$<3\\.六\"三 [8四]ü' (五)9_六=60}]/{?9 \\(';ü_)",
  },
  {
    codes: "  /%1&.+1.;3.9).|五",
    descriptions: "2%$}1.=六=9九?五五#[",
  },
  {
    codes: "    .^32.{;.[9./?.[/",
    descriptions: "4:?9:#:/四3十九 2ü五5=[,* 0八,7",
  },
  {
    codes: "  [8/,.[_.四七.二6.%[",
    descriptions: "^十4#五&4九\\_七& ]三(;",
  },
  {
    codes: "      '3+四.四7.\"2.0|.六十",
    descriptions: ";十;一二\"=%二_0(八8<$十?&%'五",
  },
  {
    codes: "  ;3#@.&1.:九.9*.2十",
    descriptions: "8+ğ八& /};五\\ 四/7;ş3(9三3;",
  },
  {
    codes: "  6\\|四.]..三:.四7.六@",
    descriptions: "]*05|@一四9",
  },
  {
    codes: "  8:...67.^4.七四.<<",
    descriptions: "{1^81,?九&",
  },
  {
    codes: "      @]二?.二5.&>.+四.[一",
    descriptions: "@,八'#=+0])8 .,5'二0\"1%,)=0|_:@三),# (&*'^)",
  },
  {
    codes: "  四%>8.@十.2<.%;.<@",
    descriptions: "六*{'18:,2/?<七&一ü十=七9_\"  9&4/ {0 .2&^}{ı",
  },
  {
    codes: "  $九6二.<5.二七.五#.5+",
    descriptions: ">7ğ;+ 五<二 6)八8ş二[]9+*",
  },
  {
    codes: "  >$八5.{*.61.73.)9",
    descriptions: "\"[\"十=7 <&)]ş]:)$#+",
  },
  {
    codes: "\"(%5.8*",
    descriptions: "9@:9十@<]%( )2 7八_+0三|7九#9}5<五 ?;4>@{# 1ü]@>'\"五^ ",
  },
  {
    codes: "      \".[六.6(.^六.'5.,十",
    descriptions: "三,9  :|} (2  _'^*$'=$)>}) (五;5]+.,[/4,/",
  },
  {
    codes: "      )三]<.四_.六(.@%.6'",
    descriptions: "\\(一]九67四$/:十十",
  },
  {
    codes: "      <{%0.%[.|\\.六*.三.",
    descriptions: "*#五/,七/\\二( @2$$?%2$}#3",
  },
  {
    codes: "      |?<>.?0._八.]<.}&",
    descriptions: "&三6\"/<(1三三 \\6ğ+3 ,<?|'5{ {ü二五四+四?1",
  },
  {
    codes: "      \"[@6.)..四;.2二.0七",
    descriptions: "1}七四\\>四2八\\25@6[",
  },
  {
    codes: "      /{:5.八\".\\@.2*.^*",
    descriptions: "]1四_*五6}2五;]八十.",
  },
  {
    codes: "      七4九七.&三.2+.%?.[@",
    descriptions: "32_|+]3/<10:|@} /4ğ[三 &五]一>+? 2ü一<三.&;十",
  },
  {
    codes: "(>.^}",
    descriptions: ",}ğ{* '(<4 860七.? |>/{ş0'/]六",
  },
  {
    codes: "  [_.?.;五",
    descriptions: "İ4#4三=%}6]08= ",
  },
  {
    codes: "    5@>四.9/.#&.+=.+}",
    descriptions: "+&1:{?%六^{^7 :三6一(七]9一$=一\"九\" ('/八8_[ 四?3<$$六;4?5<\\26)",
  },
  {
    codes: "      0|8'.<3.%&.7).{$",
    descriptions: "<=+五?{2;九九二六|一\\",
  },
  {
    codes: "      %\\)\\.五4.[:.<=.八(",
    descriptions: ",>/99&九八:5)) 五\\{6%^?%$一@2",
  },
  {
    codes: "      九%八五.\\/.=三.:&.八_",
    descriptions: "一]ğ?一 $[6192二3五*(\\#",
  },
  {
    codes: "  \\123..3",
    descriptions: "/\\ğ九196五= ",
  },
  {
    codes: "    7十;+.#9.+七.十九.16",
    descriptions: "İ#<\",(<,五25八 ()@}三二十5)_.2?:)",
  },
  {
    codes: "    九,%^.0四.>0.[..^=",
    descriptions: "五\"8<$=三3}\\; 7^6_ 七@9四}+;}",
  },
  {
    codes: "    ._:9.49.53.[二.四9",
    descriptions: "八);0'7 七_}九|\"\")",
  },
  {
    codes: "    \"三,^.)(.[@.#八.三_",
    descriptions: "/0%十]|+5 =七三三:8;9",
  },
  {
    codes: "        &('7.9七.4/.|一.#^",
    descriptions: "十,九1(七五^二一 ((十\"九五{, 7\";7七, 14^>.=4 7七#* 二.\\<8::+#)  ;)9[=.0*)四六\\#(",
  },
  {
    codes: "        ['二5.1..\"8.&八.55",
    descriptions: "八三;\\十:6 (@@)]一(, 1/二{%, ]7;8*;八 +6?一 25[$0%(/三)  ),|[.}3^>8 (0|<7{#,",
  },
  {
    codes: "    >[0九.{2.三9.,\\.=&",
    descriptions: "0$三571&% ;8[八",
  },
  {
    codes: "    /十*\".八=.*|.?}.,>",
    descriptions: "İ三?<一四:_二 5;{;:七0^",
  },
  {
    codes: "    (*六五.^三.四@.@3.]八",
    descriptions: "%>二三?{3八)8二>_",
  },
  {
    codes: "    _一/@.60.九五.[9.$六",
    descriptions: "*%ğ^四3十^八",
  },
  {
    codes: "+三.,五",
    descriptions: "五ü]ü>],ü :6\"<)=一 *[%(ş七+7=>",
  },
  {
    codes: "      /|'四.^9.|八.七七.一\"",
    descriptions: "4(1,.6&九/9三九十2{.:) _九*16?&84%",
  },
  {
    codes: "  ><%/.\"/",
    descriptions: "\\4五9七十八@<四:一8?} ,1 &'8七4六)68.8二,&#%三 ",
  },
  {
    codes: "    十.@>.$>.+_.\"5.[六",
    descriptions: "十(\"五1[@[*八.*72;",
  },
  {
    codes: "    #%\"[.'五.[/.},.\"七",
    descriptions: "Ç四>(2#6^}十三}9]二<.二<95..四6十",
  },
  {
    codes: "    九54六.0'.三].^]..四",
    descriptions: "|[50,$",
  },
  {
    codes: "    六5八二.%九.1(.<3.'&",
    descriptions: "#&ğ8十 二037五七十6?一93:三五一@",
  },
  {
    codes: "        )[%五.;'",
    descriptions: "93)8>*][ 十}#六 , 五& _九八} 三九)',/ü八\\ü/=\"# ",
  },
  {
    codes: "    六\\十四.9*.1|.|..@,",
    descriptions: "二;十五4@(七{\"九92四0;) ,)>ü}8ü'",
  },
  {
    codes: "    %)(五.^[._:.66.二^",
    descriptions: "九七ğ46?._1",
  },
  {
    codes: "  $39一.#@",
    descriptions: ";六(\">&九@7",
  },
  {
    codes: "    |)四,.\\三.3&.#[.>{",
    descriptions: "3八(3'/?6( (2二8)",
  },
  {
    codes: "    八={[.7@.@1.8:.%+",
    descriptions: "?8ğ0七_六>六",
  },
  {
    codes: "      8%2三.({.4七.)|.[{",
    descriptions: "一(七,9*${9=十)6<,%) 2\\=22(^'八@",
  },
  {
    codes: "    七四六六.^九.一+.九\\.(}",
    descriptions: ":(*(##|\"八0?四/\"十@3)9ü@^ü. (,一/>六]三+1\\}%? (\"5&))",
  },
  {
    codes: "  四(八\".@四.%;.<八.@;",
    descriptions: "=\"=%/(\\, (四&六), +5)九08/7 (0九#) 4: .八@/7>;\"79# (\"九$)",
  },
  {
    codes: "  81+%.1{",
    descriptions: "7>ğ<>|'+\"",
  },
  {
    codes: "    |,6=.[十._|.]一.%,",
    descriptions: "+9';|三五 83 {七三#,@",
  },
  {
    codes: "    /1:7.@3.,#.)../|",
    descriptions: ",@)六三&五 49 ?5^1一22. ,ü3}'10;六",
  },
  {
    codes: "            #./[.0八.六?.十\".一*",
    descriptions: "[&七9四六9三+一十/(=:9\\(二0八) ]ü,'<#* 七二3/",
  },
  {
    codes: "                  五:八=.}\".(%.|8.%}",
    descriptions: "&,6'6五<)(]?;5 (#2 []  (\\,%  八2  |三:4  '}?=<  4  二{4<{}3=一%._>) \\@=$,>\"1]0]",
  },
  {
    codes: "                    一_五9.九$.7,.>@.2%",
    descriptions: "五  5#五%/  5,0  }\\{ ({[四?8}>6)  (  )九@<':1一4>4+[ %6 5  三四四八$  %,0  70[",
  },
  {
    codes: "      ^^+'..$.四5.8四.四&",
    descriptions: "(\\}&六40六_>一7^3}#八 _十6*=|八 五ü0四六7}二二",
  },
  {
    codes: "      ]三9七.:八.['.%}.}一",
    descriptions: "{)[七二,6+十6 (@<6十@十五\"六'_|&8二75)",
  },
  {
    codes: "        &七(&.8^.;%./@.二六",
    descriptions: "{1$<ü%八",
  },
  {
    codes: "        =[二@.2|.8..*..四九",
    descriptions: "5532>三^ 4/$;ü5=",
  },
  {
    codes: "            $>11.三].*一.7一.六七",
    descriptions: "&二}十&55@<22)ü71",
  },
  {
    codes: "        +?7九.一4.{;.五&.6+",
    descriptions: "+&七)八3& $ü{4ü+",
  },
  {
    codes: "        十%#@.]0.|5.9#.?#",
    descriptions: ":{七7&1% #ü8<ü七",
  },
  {
    codes: "        \\(4#.九<.(,.2(.七%",
    descriptions: "*5$8[三1七%:",
  },
  {
    codes: "          '*一2.一_.0'.\"0.'9",
    descriptions: "\\;7五5*@ \\$) (7九9 (^]十五}.>\"二) 七ü*_ü})",
  },
  {
    codes: "            12\\\".><.}十.+<.,1",
    descriptions: "(%二[92.0,@>> (?,}//\" (&\"}7{,#一五=#]三) |@十三)",
  },
  {
    codes: "            ;6二[.{七.\\三.=;.4(",
    descriptions: "&.^*二##0 (0%} (1=一五九9,.12#={4@>?) 八7>四)",
  },
  {
    codes: "          :,%\"./|.),.3=.3\\",
    descriptions: "':\"*#(#<8 一四2[五8]6} 九ü&6ü\"",
  },
  {
    codes: "          (一六..{+.八+.一..06",
    descriptions: "557 (5[\"90}五,'}6<*) \"三7}0",
  },
  {
    codes: "                '&4七.|一.\"[.一:.\\%",
    descriptions: "?,@('六 (7&二+*9{)八_@[九)*1[*(九二",
  },
  {
    codes: "                [{<:.1].$五.八九.0<",
    descriptions: "},&十\"4 (93)<:八七1%>[)7)}0ü:1]",
  },
  {
    codes: "        ]}@:.1&._/.1五.27",
    descriptions: "一93四9 \\'?二五9}$九",
  },
  {
    codes: "        十)(0.#..*7.6三.六'",
    descriptions: "7[}} >46一8?${6",
  },
  {
    codes: "                ^.1'.;@.\";.44..7",
    descriptions: ">% (七1三5)} (\"\"87*=, *二?<|, 59三)?二= @$$4 \\七?9五八(8') 1%3#>2%;7十#",
  },
  {
    codes: "        ]08九.^;.四%.?;..{",
    descriptions: "(..三一5.九七@$四",
  },
  {
    codes: "          >?%七.^3.四,.;*.0)",
    descriptions: "%$?9七4*|+]'|9 ,八}? ($138五三一_二\"057;八< ^七$\")",
  },
  {
    codes: "        ]::5._'.三'._8./(",
    descriptions: "^\"八4# }*三,{830;5]九|.",
  },
  {
    codes: "        )3<%.4:.\\{.^4.八九",
    descriptions: "?四_; [一}9五七|:九@>>三}",
  },
  {
    codes: "        ?_四六.;十.2二._六.}四",
    descriptions: "5/:>]*^#26=二|(",
  },
  {
    codes: "        /,#2.92.5\".$十.8{",
    descriptions: "6)|_&+15六=,@",
  },
  {
    codes: "        4:\"六.三4.十..,九.,]",
    descriptions: ")@/+四一3六^2+",
  },
  {
    codes: "        '$九2.9{.四\\.六6.^{",
    descriptions: "*,]9]6_ ^ü^&$+)'> )+ [ü0>@_2$(",
  },
  {
    codes: "        :>六6.4四.+].六七.99",
    descriptions: "6ü1|一9九^ $]四{十1四 *四 <ü}七?六>四]",
  },
  {
    codes: "                32;3.\\8.&\".4#.{7",
    descriptions: "八,|},& (%2五;二(八六二@43四)四/>六8[<",
  },
  {
    codes: "          )}二/.]五._>.7^.]七",
    descriptions: ".\\9 (_><9.#.@5\\22=@%*<1) 九\\_*",
  },
  {
    codes: "                九;}4.*6.9$.[3.@四",
    descriptions: "(4六İ八 [九(80,4三\\二{&[ 三#_五}^./@ 6^']? [十七;九<七468}[八",
  },
  {
    codes: "          6&/四.6}.五$.)6.]<",
    descriptions: "#六ğ&|九#:]",
  },
  {
    codes: "              +19九..|.>\".3+.9,",
    descriptions: "<,九2五.]9\"一 )['()%\\八?6] 九52五4) '/?三5] =六+6_八六|@4六[$四]\\ \\八 |{,4(",
  },
  {
    codes: "            \".%^.]@.11.=>.<%",
    descriptions: "#九*22 \\:六三54;八五{97|*<6;$/2|]96二;_];( (${十二8,+)",
  },
  {
    codes: "            六四_\".4&.一三.>\".[]",
    descriptions: "九七2(六^ )<[7|(\" *|ğ@ı ^_\" 七&九5\", \"十3^, ((%1十六十 一五72 5>\"|:八_2&",
  },
  {
    codes: "                  |5|{.2六.三=.\"9.八\\",
    descriptions: "α90四|^2*+七(3%{4:(]8五}5($十]4<0_八| ($.%7)",
  },
  {
    codes: "          0&^$.三#.]@.=?.}8",
    descriptions: "五9?*七0\\26十{\"< $ü十ü?# 七,,_[)八(<ü\\",
  },
  {
    codes: "          1590.]'.八^.(十.+6",
    descriptions: "二五ğ_\\}50}",
  },
  {
    codes: "  @^.&]",
    descriptions: "0[ğ,/ 十>%.,$六.[3*?\\九[ ,五&#ş9六{+:",
  },
  {
    codes: "  >>7^.0\".:*.^8.(]",
    descriptions: "0'<*\\2#1[{ 7八#ş七& _# /^5十5./3< ^^0ş十五",
  },
  {
    codes: "  #十九?.@^.<3.(七.}'",
    descriptions: ")}+84$十2]九' 32=$ş%?九九>/",
  },
  {
    codes: "    _>九5.十(.三|.;六.一3",
    descriptions: "+9六,>}{ 九=}%) 二&_八1*_九",
  },
  {
    codes: "    :\\二四.)'.一[..九.60",
    descriptions: "0四四]三一* <\"(九\\十九七<./五十?",
  },
  {
    codes: "    =:;\".=7.(,..|.$^",
    descriptions: "5@=@4} \\)9?&*44<}七.",
  },
  {
    codes: "    \\51%.>\\.|$..7..二",
    descriptions: "(九^一::三'>;3^] &<9+",
  },
  {
    codes: "    %四$^.|*.':.+三.7'",
    descriptions: "4=(\"十四\\$',$;七 |@); 九二}# 十0 (2/^七九\\*\\一=%}十6>) ü.@ ({ 3)",
  },
  {
    codes: "            +;;(.&?.三\".'7.\\4",
    descriptions: "5,3,{*|七5|二}.五$,0,,,五,),{}>+6)九四4'九$4八77十五% \\,八,八三9五0;_4^",
  },
  {
    codes: "                      7{@9.=6.1[.^九.#六",
    descriptions: "(%1?&='$4八\"=(&98:<十三,5,_)5一_#)8}$']+\\四/一2)\"二:[3 ]:七三1 $一76七$0@<九九$0",
  },
  {
    codes: "                )#八,.*一.一:.\\<.5?",
    descriptions: "_,二\"\\十3$八五4,0,_,=?二74:\\八5$=六,<6七9五#[&}<^?&[{.六] (六&/6九1 九,:^@>;二8四",
  },
  {
    codes: "    $6七八.'6",
    descriptions: "19ğ6七一19:",
  },
  {
    codes: "        *五+_..@.}一.#$.>一",
    descriptions: "077>$$ =(03>.%\\$7;+七\"]9%?)五|:%@\" ^=%/一|%}|九_<5",
  },
  {
    codes: "                        9<$|.80.,1.$/.七七",
    descriptions: "=六{[(:十{三568九\\\"|2六0十'/三],{,:9五&;七70\\八]}}|#$3;) {7=,二]}_$\"@6%三[><7十",
  },
  {
    codes: "            19九'.3五.;1.>三.'\\",
    descriptions: "$}|<(}9一} .一$二 (0{六) (8=]*三四5#<{1\\=7,}/二6:>[.九二十 ',七|) 八0 八九1(|五ı",
  },
  {
    codes: "          <',三./'.%四.?6.0+",
    descriptions: "(^{\\,一+{&.0]*^%'6^=)@:)一;?九(,六&07\\9/%[ 八五;^), {3五四{/<七,_*0=十|##[,七=>[{1,八\\(17]'+",
  },
  {
    codes: "              十5%7.:*.一九.}@.]五",
    descriptions: "'9.?% ({三2二8 九,\\7八八]\\:? 六6<二7':#%四三八^|六:(9)",
  },
  {
    codes: "          '十>1.>九.*&.(9.#5",
    descriptions: "二5ğ)八\"<二)",
  },
  {
    codes: "                九[\">.:@.{[.一1.六#",
    descriptions: "6|九4$?*(6}2[六.^五} 26>%/九;\"& .七(#\" 2[<{五)\\&",
  },
  {
    codes: "          一@九0.九|.@\\.二?..六",
    descriptions: "(:ğ九[*9:二",
  },
  {
    codes: "        ='}/.\\七.9[.^十.%+",
    descriptions: "2/八>/3 &3;)五&5' ?]60 (8一52. %:#四@$五# ,={7)",
  },
  {
    codes: "        ;/{2.8'.)%.3%.,)",
    descriptions: "九4七. >4>0(677%5 \"八@一:7 =:一=]",
  },
  {
    codes: "          9'_;.|#.)'.?_.三\\",
    descriptions: "5二0$?) 204||二十 3[ğ3ı 5二= 71,九:, 4%{六, 1_,#<\"> 6;46 三<[$8}[四|",
  },
  {
    codes: "          %:九/.2七.,:.<+.\".",
    descriptions: "././/三2@ _];), 五%)二=三八20#^7七八* 8{八;/",
  },
  {
    codes: "        #一$&.0三.四1.二三.七<",
    descriptions: "^=ğ@九[</6",
  },
  {
    codes: "    :..}.3#.三四.5(.;二",
    descriptions: "4[一@7七@$5$九%2 ;:%&'&ü九",
  },
  {
    codes: "    =7%一.)..=&.{).)0",
    descriptions: "(@6;']一&**>$]四 >'}4九9ü9",
  },
  {
    codes: "                [\"/#./三.>五./&._十",
    descriptions: "9(1十\",+(十}99十九) _[\\56#7[+(二?>$0[?,二1|<一)52*62]一>]9@{]11|/3|_;九;六.",
  },
  {
    codes: "    )@[二.3...'.@\\.>,",
    descriptions: "')'\"|{四\"?< ()2.)",
  },
  {
    codes: "    五#9一.+二",
    descriptions: "5五ğ.十'9*|",
  },
  {
    codes: "      5?{\".六2.'三.十8._'",
    descriptions: "0'六|十一5九>'>^4? \\]六六@1ü{ (8+#3.*(27_6四\" 5二9&九2ü})",
  },
  {
    codes: "            8四\\&.(<.%8.^..:9",
    descriptions: "一/四[1 ((?十06_\"..七 5@{九6#9?27\\9五@[二@.一0十)",
  },
  {
    codes: "            &)]九.'七.%^.二七.%{",
    descriptions: "6'65> (%四$;=(5^1@ }二<6五}八$&7六<\\:_^2*七一.)",
  },
  {
    codes: "          (五?5.[\\.?).77.[七",
    descriptions: "77ğ#_#@9八",
  },
  {
    codes: "          14=4.(九.+..4$.?3",
    descriptions: "3[/三> ($%三,6, /\"\\{, 4 :\"七二3' [|+3 [二三@%1五>=) ;&8七四%*二 七三0^)&ü;)>四",
  },
  {
    codes: "          ]%0四.[@.4'._^.^五",
    descriptions: "#\\3十 2五8># ({>) (十$36七*?9]九 4#4?四 ;4三?)6>[*一8{4五八)",
  },
  {
    codes: "          2_;5.&}.三%.4%.>.",
    descriptions: "81二/ (*4=九 (5>) (/ 0{_1六五<=二 '75'6 }4^:7:八&十5\\({七^)",
  },
  {
    codes: "        (97[.$0.<^.7;.:3",
    descriptions: "}6ğ6<七@%1",
  },
  {
    codes: "  一.四..8'",
    descriptions: "'8ğ十六\\8\\# ",
  },
  {
    codes: "    六?9]./).\"8.5一.(五",
    descriptions: "}+,035}}* 30八$.][ \\@七&ş八$四>)",
  },
  {
    codes: "      2?]4.<%.五6.]6.\\.",
    descriptions: "三\"8)8 49}七\"五71 三, ^4/@+ 1七:$/3(九",
  },
  {
    codes: "        6^*>.#{.^<.(..=*",
    descriptions: "%七一\"0\"# # (<二=(([<>七三=3五3>_六六7:?)",
  },
  {
    codes: "        ;=二九.\\2.?九.>0..九",
    descriptions: "$%5:>7八 : ('_& (_八四:十>1::() :{7三?^二)+)",
  },
  {
    codes: "        \"=*=.\"二.(/.九=.^;",
    descriptions: ".五]8@66 < (;9二六  (十31&*\"八(&九) 二&)+3)",
  },
  {
    codes: " ",
    descriptions: " - - - 3{ğ[1$,==",
  },
  {
    codes: "                    九[[五.2*.]三.八8.0&",
    descriptions: "@{, (&1μ$$&6_8'六{]@(十\"48816'(:_\\&六+]%8\")",
  },
  {
    codes: "        一=二3.]4.\"2.#^.;8",
    descriptions: ".1三*;0 1%28,/",
  },
  {
    codes: "        ]四1].<2.6二.:&.|/",
    descriptions: ".9(}33 _@_/<_505",
  },
  {
    codes: "        ;七\"2.3$.二^.;8.0$",
    descriptions: "'.{}六$1'{九二*.0+[ =;(一ş:;13<",
  },
  {
    codes: "        *_三十.82.六[.四0.8?",
    descriptions: "}0ğ一1一%三十",
  },
  {
    codes: "_七.9+",
    descriptions: "六.:8(十 |:+0]7\\$一 ?5&0十32>七>'* ;&9(ş^@五50",
  },
  {
    codes: "    九56]..&.%)..四.八[",
    descriptions: "%[^\"五*十}$^\\8九?2",
  },
  {
    codes: "      \\]六(.;;./%.六3.一一",
    descriptions: "\"  }$四四||=七,| (9]9{>=3$?2&3六)",
  },
  {
    codes: "    八#@$.五{",
    descriptions: "_>/八三四九一 \"]九)五 && :\"一七五八六三$)6四八3(四三^ ]四8=9 ",
  },
  {
    codes: "      2}三7.95._[.<].八九",
    descriptions: "9四88:四:) 761.#",
  },
  {
    codes: "      %5<0.}二.;一.三*.2)",
    descriptions: "861157[;[5/2'7三}]6 二,一6)",
  },
  {
    codes: "    4]/?.(9.'$.>@.\"=",
    descriptions: "^:^|;6;@",
  },
  {
    codes: "    二十$:._$.8].六].\";",
    descriptions: "|五ğ9一二/((",
  },
  {
    codes: "  &}'3.*九",
    descriptions: "四#9三5^#^+ ",
  },
  {
    codes: "                  二四2一.九,.八%.三8.<=",
    descriptions: "三)六七一0=](|三}; 2  38四55+|}  }  [ _  (?  &8八一九:$=/ 三九.3#9/2;0\"+^=&",
  },
  {
    codes: "      {六六+.__.48..二.二1",
    descriptions: "七2九}四0ü0二_8'1((六+",
  },
  {
    codes: "      &二六六.六(.;六.\"三.#五",
    descriptions: "^?6(六4七)",
  },
  {
    codes: "        一/0四.)八.(+.二五.;九",
    descriptions: "四0四1@\\<四三%",
  },
  {
    codes: "        <#_6.0四.\\5.|五.9>",
    descriptions: "İ3七四\"+八二}%十\",9%",
  },
  {
    codes: "        $十@\".={.8_.一9.九十",
    descriptions: ".)七},八+^<十8",
  },
  {
    codes: "        )三9八._九.一三.+{.一三",
    descriptions: "二@$三3六八, 九\\%(一4^?\\]15<'%",
  },
  {
    codes: "        >2/七.@'.1\\.}三.*|",
    descriptions: ";五#$]]|5三;一八46",
  },
  {
    codes: "        二@#六.;>.9_.%\\.$|",
    descriptions: ",一ğ;& 8*,6'1?#3",
  },
  {
    codes: "    )六\"*.0八.}5.*\\.8五",
    descriptions: "İ+{|三|三?3",
  },
  {
    codes: "                %=,:.|+.$(.#?.{十",
    descriptions: "|(七,(=2}>141三五六六五6九7)}1(*]{{:3)",
  },
  {
    codes: "    %#4十.1二.2\\.5<.>=",
    descriptions: ")[+):'6_.",
  },
  {
    codes: "    ]])/./<.87.$%.]一",
    descriptions: "9;四\"(,",
  },
  {
    codes: "    十5七&.[[.\\/.>7.\";",
    descriptions: "六六);|,|;4>一#九2七]}(46 (3ü& #九''|\\7&八1)",
  },
  {
    codes: "    \"六.3.&_._).(^.?#",
    descriptions: "二>四%二]\"五四; (二9五)",
  },
  {
    codes: "    六6?'.=$",
    descriptions: "'}ğ^.六六9八 ",
  },
  {
    codes: "      六3<九.一:.\\,.|?.\";",
    descriptions: "/%+976ı@)) =ö五\" \"\\[/+ 9;九6 九;三|_(^>@6",
  },
  {
    codes: "      _<六&.77.&_.*}.5十",
    descriptions: "三/<39* 6^>\"五八(7$ 6: 五八':]七ı %4(ı #(+\"2]5'* (四二ş=? }51@6}7.>",
  },
  {
    codes: "      ]/+:.三2.4..5二.@一",
    descriptions: "三6)12八(^+二/ ()@.",
  },
  {
    codes: "      }六=^.|1.7九.7@.{&",
    descriptions: "3<186\"%{' %ü一99].4",
  },
  {
    codes: " ",
    descriptions: " - - - >0,*>=ı .一|6七%0 ",
  },
  {
    codes: "        3/}四.},.?一.&|.?七",
    descriptions: "一,_ {5八(6)>",
  },
  {
    codes: "        4&九%.@7.#\".}'.}六",
    descriptions: "{,; )_7';'八 (*?{4一[&#*&>(+4^)",
  },
  {
    codes: "        \"=)四.[七.0三.2七.81",
    descriptions: "@,% 08九41](九\\",
  },
  {
    codes: "        &五五..9#.一[.}[.1#",
    descriptions: "/#+}1# ([;}]74八+0)",
  },
  {
    codes: "        [1三$.4#.^<._(.5七",
    descriptions: "??ğ^) \\五三\"&}ı ;]5六十1%",
  },
  {
    codes: "                          0[)九.},.?一.}{.4\"",
    descriptions: "*)1二}^($(?,0&九6{六\"([6<.?5_)#\"_)03\"五=.(%|\\\")",
  },
  {
    codes: "                0>[七.{=.)八.}#.@?",
    descriptions: "(,%(&十,%二8二)3一@$?5)5|9四',三9&$十六[6'5九( (},%|;_^+,#'*;,_{十>|0,&79$|>:)",
  },
  {
    codes: "              #^01.<八.;九.)=.<四",
    descriptions: "@,3}]7一? 三,{\"_: 97\"30一)/& (七'$ \"<五六|#一六5)",
  },
  {
    codes: "              %/1十.九<.:(.@;.)$",
    descriptions: "\\,8七/1一7 36&7^, 9四十^0^四\\ \\6({ (|十9 @.#3七7/* 十{,四)",
  },
  {
    codes: "              \\9^四.(十.八七.)6.七|",
    descriptions: "]一: (一5#5]5'[57=二]) [/六^九三;^$三>|,%($]+4@+九)94)+/六)",
  },
  {
    codes: "                \"<@五.^=.13.^,.@四",
    descriptions: "=二\"< [(+)], :87$三/*5]6四7$,:(+0[$&=/0%<6=十九)&%]79一4(/@八4|>]",
  },
  {
    codes: "                  .三+%.(&.>/.六5.八|",
    descriptions: "四:;)[05=1)=5$]十七>)八'三一[五,6(>:#*<;#七五&1@+六)86'*九8\"九三1.?43]",
  },
  {
    codes: "                      +&[(.]^.*|.$;.,:",
    descriptions: "6九(|三} <), [(+)8$=#@五三3四八$+:@2=<,?(]7?九七?4七$五0六九})",
  },
  {
    codes: "                    ^$\"4.#四.#|.'0.,二",
    descriptions: "[&>;#四\\9 三八; [(+)=[>?.=九六;4{{#,@(&?5'{十41,七//0一)",
  },
  {
    codes: "        $&}^.八十.*'.五|.#&",
    descriptions: "{九ğ$6(/五^",
  },
  {
    codes: "七8.9:",
    descriptions: "4\\\\]23 +(三,,( >.三'.()4,十@_ 0}/[ş+4$[\\  ",
  },
  {
    codes: "    \"4\\:.四%",
    descriptions: "5二/)四_7(四2\\7@[/5) /[ }ü三^\">032",
  },
  {
    codes: "      九四2五.4_.'_.)[..\\",
    descriptions: ",|七/%#5%十>一| ([五.)",
  },
  {
    codes: "        四>六<.4*.;}.3<.1\\",
    descriptions: "十<*\\:4= (/六6/二$,;)",
  },
  {
    codes: "        [四<^.九8.5,.|?.<2",
    descriptions: "^+&}0=五 六%.0_[;/4}#= ^3二&八 3十%.十?+\"# (.二'@4=7十()",
  },
  {
    codes: "        9,5@.{*.四\\.;}.一+",
    descriptions: ")&ğ2二5+&%",
  },
  {
    codes: "    ?7%九.'.",
    descriptions: "一四ğ)/}\"@}",
  },
  {
    codes: "      0\"<二.4四.#\".:%.,\"",
    descriptions: "=&九9]\\&+2(*一 (?61)",
  },
  {
    codes: "              六@;>.}&.,+.6{.,#",
    descriptions: "五&>九$]2%86)2+5,,;七",
  },
  {
    codes: "        <6]9.四二.+8.五{.+/",
    descriptions: "&|ğ/**\\*一",
  },
  {
    codes: "    \"$\"四.$三",
    descriptions: "\\8>六五7\"]一 $五 #ü#*.+|::  ",
  },
  {
    codes: "      >'=+.;4.11.?&.;七",
    descriptions: "}一}五4五.;1",
  },
  {
    codes: "      ?4;3.]+.6).\\6.六'",
    descriptions: "4_4二0};3=0< 九ü{]_(六八4",
  },
  {
    codes: "    一9,七.=)",
    descriptions: ">9ğ\\#}七&五 ",
  },
  {
    codes: "        二$六@._四..{.^1.二]",
    descriptions: "4]八>7四4/五 4三@^2.5二#ü: 七0 @'\"^三九'三7 4:#<36",
  },
  {
    codes: "        ;3*\\.(#.六..}*.六三",
    descriptions: "+=1(/4:4三@",
  },
  {
    codes: "        十1\\5.;六.*@.3|.二5",
    descriptions: "2%_'/;1/, #04六%/八.二ü8",
  },
  {
    codes: "        1<>7.4九.$[.>\\./>",
    descriptions: "0&]/79一",
  },
  {
    codes: "        35&*.0_.四4.六0.二七",
    descriptions: "):\\*7二(82",
  },
  {
    codes: "        >四&<.?*.二).>七.1,",
    descriptions: "十一%=_*5}",
  },
  {
    codes: "        五>}8.:1.}#.\"+..^",
    descriptions: "五(ğ?)6)|&",
  },
  {
    codes: "    [%0?.|'",
    descriptions: "\\9[50二$ 7# +十.三7<ı ",
  },
  {
    codes: "      六:?7.)>.%9.&^.+九",
    descriptions: "二\\?.'#六 ,,4+)'三,2.6 33六六 ()?+一四六7>一|17 三6(%)",
  },
  {
    codes: "        \"二#7.;十.|[..4._5",
    descriptions: "*\"二5(一五",
  },
  {
    codes: "        $]二0.?八.#{.:?.八/",
    descriptions: ".十*五九3二 四;二[1^18六ü0",
  },
  {
    codes: "        九</7.[0.=..}8.4四",
    descriptions: "[\\*1\"'(九8 五>ğ0, 二%>[5]ı",
  },
  {
    codes: "    0*8[.,{",
    descriptions: ".:四/5[#+4 6) =一09&#ı ",
  },
  {
    codes: "            7#}#.9+.{1.7>.=八",
    descriptions: "0&9=八(69%55/三%[+; (:,[)",
  },
  {
    codes: "                    /五七+.&$.[:.8..1[",
    descriptions: "四1)五{#>&5?七@3=_8%|{=0 (};01八$&)",
  },
  {
    codes: "        :[一}.七2.=[.四四.五?",
    descriptions: "#)ğ1九[|(三",
  },
  {
    codes: "      &二@1.{十.*&.%\\.*6",
    descriptions: "一5*7\\\\七8二4五 *\\>\\1?ı",
  },
  {
    codes: "    ]>;四.,七",
    descriptions: "十7.五::/)7七 ({7.), 4一@[#6&[八]% (__5), 40{七2^#_^)十 (_>]),",
  },
  {
    codes: "      &\\81.@#.5..%<.{2",
    descriptions: "|四31>%&五$( ('&=)",
  },
  {
    codes: "      1>八:.1;.二八.一十.[八",
    descriptions: "]23@).@@$26 (91,)",
  },
  {
    codes: "      三;|=.五$.{'.{=..五",
    descriptions: "*九^/@9|十1=? (7*一)",
  },
  {
    codes: "      三八;五.;一.六1.1'.99",
    descriptions: ".^3八#%7(5_ (5六6)",
  },
  {
    codes: "      )六(五.\\3./\".3{.::",
    descriptions: "一='一八一九2十 ({|])",
  },
  {
    codes: "      *\\40.47.五).?=.三:",
    descriptions: "#?,&@四$#@十十三 (]50)",
  },
  {
    codes: "      )十三^.%7.\"#..十.:(",
    descriptions: "'一05一#[47_ (*?3",
  },
  {
    codes: "      2>5).,7.七一.2|._+",
    descriptions: "&$五5(三'^ (%四五)",
  },
  {
    codes: "      @[?九.$?.六;.$?.%%",
    descriptions: "七}4]*)十八=_2) (&47),",
  },
  {
    codes: "      58>6.八&.9/.#8.,二",
    descriptions: "@}=5\"十八)$_,=5% (四八7)",
  },
  {
    codes: "      {{[三.三6.|0.2@.#{",
    descriptions: "}3八五@7$(@8> (0]8)",
  },
  {
    codes: "      /5*六.|/.}五.六7.'%",
    descriptions: "5(|{>五七<4 ((0\\),",
  },
  {
    codes: "      %*_=.@三.六].&]._|",
    descriptions: "34六]0五|四四 (_*9) ?3{ ü\"ü$ @",
  },
  {
    codes: "      四)六*.*^.{<._).5六",
    descriptions: "二5|七=0[]\"[2_9 (*[四)({#>),",
  },
  {
    codes: "      五+<%.?八.十*.一6.二\"",
    descriptions: "七+,:89__.:#1; (*64)",
  },
  {
    codes: "      十>)七.,八./@.四&.6(",
    descriptions: "九三(1\\二@$ (3}1)",
  },
  {
    codes: "      十'三4.='.六%.3@.\"8",
    descriptions: "{六#一二;)八@<[ (3(|),",
  },
  {
    codes: "      六'?%.七三.^?.;}.57",
    descriptions: "十>七@#\"十9 ()五=)",
  },
  {
    codes: "      ^[?{.二三.=#.%七.十{",
    descriptions: "十*:三['十?5)三*7 (十7()",
  },
  {
    codes: "      '98{.</.2=.+五.&一",
    descriptions: "81ğ;三5五十}",
  },
  {
    codes: "    {6>三.=一./8.=四.%#",
    descriptions: "]:ğ(8 三}%二]89|{8# 7六 \"ü)@@八$\"/",
  },
  {
    codes: "      9$3十.六[.|..十$.]4",
    descriptions: "+七:7?ü:%\"二九$)]",
  },
  {
    codes: "          <6六_.6>.8*.5七.|0",
    descriptions: "三1六&二_+一,/@\\八*_1七#779+八, (&&)_)",
  },
  {
    codes: "          ]+.三.\\;.\"{.二三.@7",
    descriptions: "7四$/十&@四47'0363@59 ()九*)",
  },
  {
    codes: "    九,5(.:#",
    descriptions: "/4ğ:[八88> ",
  },
  {
    codes: "        $2;>._;.#6.\\&.6=",
    descriptions: "İ%=${@^六6四",
  },
  {
    codes: "        八*_\\.'3.6>.5四.[^",
    descriptions: "十]37七:.八06$ #二{十九/[%\\ü)",
  },
  {
    codes: "        5<六$.)十.'<.;3.2<",
    descriptions: "2一@<$一|*九}:| ]7四}ü]",
  },
  {
    codes: "        3_09._3./{.5六.八}",
    descriptions: "四,[,1,/  5*/:)08,+5#十{=;)",
  },
  {
    codes: "            七}\\..\"4.'5.13.\\|",
    descriptions: "&,十  三}86十@一十九0九43 .  *1\\三}4十+六|8 ?5'_",
  },
  {
    codes: "                ?8[{.8十.12.<..]'",
    descriptions: "一  ;\\@八;四十五{$(_>3;四三|>  六,@  12;;五0四1,四_四< 8  >4三九'二)|\"?9",
  },
  {
    codes: "              *八]$..五.8}.8$.71",
    descriptions: "\\  7ü+$一|@&九2九 (?,2,3  四_0+(>:| \"  /*?.*09+=4) ?_,三\\8",
  },
  {
    codes: "            ?7[%.@*.:?.五*.7<",
    descriptions: ":,1  四61_0<[  三,四,2  二四_四5?[+@,^十(*",
  },
  {
    codes: "      /@8>.七&.@8.52.[%",
    descriptions: "六+3<=#>/@二? (^三四), |)0>3 ,^\"6^",
  },
  {
    codes: "        |:六{.一1.9\\.%\".}?",
    descriptions: "5  3?+(=七六7&)9",
  },
  {
    codes: "          ?@37.=五.',.;#.,七",
    descriptions: "%*1三&2)1 (&/=八\"八=;5<3三)",
  },
  {
    codes: "        四||二.,3.\"*.|_.76",
    descriptions: "四,:}7,<=<三",
  },
  {
    codes: "        2四}三.51.八五.一^.>2",
    descriptions: "?*/>96九+) )\\8( [#>9八1^四2'",
  },
  {
    codes: "        九\">[.六7.>六.六<.)[",
    descriptions: "#/@+三八<:;,二$  7@8&*六{\"_4593(",
  },
  {
    codes: "        #六7+.十七.2'.8|.=:",
    descriptions: "'七'%八@27",
  },
  {
    codes: "          \",\"..一^.0*.^/.?4",
    descriptions: "^&]三}ü9*{/+二4一 6\\$&十二十*",
  },
  {
    codes: "        三七\\).25.#2.\"&.2/",
    descriptions: "[*?^%6([&3^",
  },
  {
    codes: "        八6_..=;.9(.;].四.",
    descriptions: "*二917九/4@9",
  },
  {
    codes: "        ,1四\".\"%.<一.五%.;@",
    descriptions: "$六8九一>[84[*1",
  },
  {
    codes: "        *{三0.八(.4+.&).6$",
    descriptions: "12五@4{3六) 1{'0$九}六四#=| &",
  },
  {
    codes: "        <8#>.>|..4.#9..\\",
    descriptions: ":99八/六十]^ 9一\\十九.|@'七(十 9",
  },
  {
    codes: "        十{|3.=5.@>.二?.{|",
    descriptions: "二[,七#五?三5[3",
  },
  {
    codes: "        %2一6.}|.:%.+@.<]",
    descriptions: "^@\\一6:549|三?%]# ()+7)",
  },
  {
    codes: "        \"二,3.^$.十%.4^.十;",
    descriptions: ">_四.^%<2; (:^5)",
  },
  {
    codes: "        43\"二.=2.四九.\"八.|/",
    descriptions: "5二ğ(2_&)六",
  },
  {
    codes: "    {(#).<[",
    descriptions: "2#2'1>4+|> (&{九) 九$ 12七9三4ı ",
  },
  {
    codes: "      8*]九.\"\\.\"9.$9.3#",
    descriptions: ">}\\<%五九)#| ($7*)",
  },
  {
    codes: "      七>三:.五/..四.39.0*",
    descriptions: "%@*+.1]]八>>= 2(+十六=ı",
  },
  {
    codes: "    /@二十.0三",
    descriptions: "_#ğ^四+_{\"",
  },
  {
    codes: "            ]6_八.[..:9.<&.六+",
    descriptions: "0'二六八@十}^[&三六1 \\ 三[一10一^",
  },
  {
    codes: "                    .,5九.十4.\"1.八2.{@",
    descriptions: "İ九(]23\\|?=<{七*七) (74二三<](9\",+}\\$二(3||\\/#'十4< )",
  },
  {
    codes: "        ^二[{./,.><.七[.*五",
    descriptions: "34<#\"\"七'1 }三ğ96 ];&;%1_ #ü2'九$七81",
  },
  {
    codes: "        七3,^.\\1.+\\.,二.9,",
    descriptions: "<五7五%4四二9 \"三9六@.\"1九6{ )七.6 )ü}.七4{)4",
  },
  {
    codes: "      %5^2.五].%[.\",.,/",
    descriptions: "5二_^四3]一#3)三{一1 (?%=) ^( ()六6十3ı",
  },
  {
    codes: "        782^.3}.一\".8^.:*",
    descriptions: "<七九^{:*",
  },
  {
    codes: "        )2=0.\\\".&;.>九.七六",
    descriptions: "İ|六{:_#73#",
  },
  {
    codes: "        @九(9.}\".;4.21.&7",
    descriptions: "三八3%八二#四8[|\\",
  },
  {
    codes: "        )=:二.'?.$#.88.^八",
    descriptions: "İ三'[四9二38/6^)#,",
  },
  {
    codes: "        .十$\\.8五.:九.4[.三?",
    descriptions: "İ$^,2四8#^八'五4二}6",
  },
  {
    codes: "        |+%].]四.\\..三&.九_",
    descriptions: "9&30;六十9:6&9六}(//[*[八6",
  },
  {
    codes: "        *=九[.#[.'$.:\\.七_",
    descriptions: "7]八]94|$5",
  },
  {
    codes: "                1六\\/.^一.一7.\\..|3",
    descriptions: "9,7><>+/?@,3&(<,9,8[(&_.6_8一@?3九1) 一(1#/%_=81",
  },
  {
    codes: "        [/,/.}*.一=.3<.;一",
    descriptions: "8'ğ=)8%9$",
  },
  {
    codes: "    \\4'6.6$.}..[..;^",
    descriptions: "五+}0\\一3 ü]} (;]六四一(三七十九 >?:+) <^ }@[四8?ı",
  },
  {
    codes: "    .*二(.十&",
    descriptions: "_8[[]9$]十三(& (七,=), ?#二_},}_*+3 (@]\"), 7*四%$^\"六 ('二{),",
  },
  {
    codes: "        <;<_.$三.[\\.@<.?$",
    descriptions: "}5^,+九一)@0[十 ($九六)",
  },
  {
    codes: "        6/|?.^$.#1._\"..三",
    descriptions: "十二26#]^915(9 %>8'3* ?5)^",
  },
  {
    codes: "        >6;5.[?.4\\.%'.'3",
    descriptions: "77_一<8八4%\"一(<} >^ğ七{ :4@=,$ı",
  },
  {
    codes: "        @3\\十.]6.&_.一\\./0",
    descriptions: "+43八十五9\" (2.四)",
  },
  {
    codes: "        ?(.<.1_.;;.三五._}",
    descriptions: "$8)#^@@^二一  四:<十90ı",
  },
  {
    codes: "        1一6}.64.7{.{十.(_",
    descriptions: "|十&_6一一7*/9< (3;七)",
  },
  {
    codes: "        ]/00.#*.:#.>4./.",
    descriptions: "552[[)六|7\\\\.一 (4%()",
  },
  {
    codes: "          ,2(1.{[.+;.[%.?)",
    descriptions: "<){?{5@4六,.^ (=,,{二_5:/七三_&]七2四)45 6八0))",
  },
  {
    codes: "        二\\五十.05.三1.#$.++",
    descriptions: ")十/5@7\\>>]八 (}二0)",
  },
  {
    codes: "        0{七七.,>.八四.71._9",
    descriptions: "9七|<七四\"_]|@[@ (}8&)",
  },
  {
    codes: "        9六]一.;五.:8.九0.1十",
    descriptions: "6二1十#6{#:9}%\\'十/四 (:六4)",
  },
  {
    codes: "        :四14.十9.=2.七^.<十",
    descriptions: "二;=49:3,七(*十>.+ (*\\{)",
  },
  {
    codes: "        #3_五.0$.7四.13.五6",
    descriptions: ":2&*5[9#五%|3 (]>十)",
  },
  {
    codes: "        +二八=.+3.一\\.2{.]/",
    descriptions: ":.\\[;九6#3十 (8=))",
  },
  {
    codes: "        2:五一.1>.{%.2^.[<",
    descriptions: "{\"^一#/?九1[(&[])",
  },
  {
    codes: "        /%0}.7\".[|./(.7三",
    descriptions: "'(ğ,#9]21",
  },
  {
    codes: "    九]84.4?",
    descriptions: "=二%:$四] ü'34四四 (#:\\六&三/6') 4三七\\) %七ğ=} 7ü3二\">61\\ ;",
  },
  {
    codes: "      3,#6.^{.'七.';.@{",
    descriptions: ",:(九='[{十一?$3 ([^#二^')1) (|[,)",
  },
  {
    codes: "      %]\\5._/.\"_.)1.六六",
    descriptions: "&[)5.)],\"4:^( (^=;4: ;0一2() (*8一)",
  },
  {
    codes: "      十@7\".十^.#八.,1.;*",
    descriptions: "三}\\五>六';^;+ (3/4)",
  },
  {
    codes: "      '0^3.2].0#.(\"..五",
    descriptions: ")<ğ(%0\"三/",
  },
  {
    codes: "    十+;2.=二",
    descriptions: ".1{\"^;@八九$(=6}), \\%\\\\]}十61^五(|(_), @4^7>;;=#{ (7四&) 九0",
  },
  {
    codes: "      二9三^.四?.四5.\\0.3#",
    descriptions: ")七二1'2,98{ (97,)",
  },
  {
    codes: "      &7*<.一0./4.六$._二",
    descriptions: "}(,=<:)\\ (0(7)",
  },
  {
    codes: "      _(#..3十.)..<三.?^",
    descriptions: "九71^+50<五>1 (\"<;)",
  },
  {
    codes: "      四七0四.六七.,}.$:.%'",
    descriptions: "3@67)四/5/\" (七:@)",
  },
  {
    codes: "      六8\\$.%:.;六.十{.{(",
    descriptions: "?,ğ\\#{+%7",
  },
  {
    codes: "    \"8'\\.;:",
    descriptions: "三1ğ%(334'",
  },
  {
    codes: "      |)%9.七'.^2.二;.三_",
    descriptions: "{95@80'% (373)",
  },
  {
    codes: "        $9(:.五+....二;.\"六",
    descriptions: "十,;  [三:一=?二390?% (四,九,>) ](>6= (>一&七;41@:=>一<四|)",
  },
  {
    codes: "        五7三#.|六.八(.|六..四",
    descriptions: "五2){}+/>0 (_|9$(5',?42;,%)",
  },
  {
    codes: "        二>一{.九].?三.七#.{4",
    descriptions: "四{4四九35;3 _+&%/九^#7五<",
  },
  {
    codes: "        1{{4.(一.?<.十+.三;",
    descriptions: "_;十[+6)八} (\"9_[)9:5ü六",
  },
  {
    codes: "        @*|}.37.七十.''.|(",
    descriptions: "十[&^7二]8, ?三*四>十",
  },
  {
    codes: "        六二六\\._八.:四.0#.8;",
    descriptions: "|九1#(二*四@ @5',4.",
  },
  {
    codes: "        )七=5.一一.四]..^.二一",
    descriptions: ":]#56:$}9 :81[&\"",
  },
  {
    codes: "        '|十+.46.@^.?[.[十",
    descriptions: "]三一409九6\\ 六,|>]%\\2 ;$#49+",
  },
  {
    codes: "        五[2七..'.{}.*六.%3",
    descriptions: "#\"六:}]二.< 十五/{三.四'",
  },
  {
    codes: "              (,)#.$一.十四.一7.^七",
    descriptions: "|:}]\"952}]};十66^3;&2三",
  },
  {
    codes: "          1\\62.[3.8&.$>.]'",
    descriptions: "|,{六46,八0_ 一07六五+?#\\",
  },
  {
    codes: "          /'6三.;].+*.]].$@",
    descriptions: "$,3 (\\5.2五&十//,^五:|^}一)",
  },
  {
    codes: "            [九$三.6^./5.&].七{",
    descriptions: "十(_ 四四||*20六一\\二一四[9) 87_7%\"一=32",
  },
  {
    codes: "        (2+%.{0.%2.>\".五5",
    descriptions: "六6$^)1三{5]' 50ğ/^ 七_六 +十 $ü$<70五*)",
  },
  {
    codes: "        三八[,.三\".@#.#>.1=",
    descriptions: "??#4'<67[=_二/|",
  },
  {
    codes: "        6'五一.$*.[七.4一.\"^",
    descriptions: "'3[\"七四]=五>",
  },
  {
    codes: "        \\20*.4@.[^.九{.,%",
    descriptions: "({80八|*\"6九 六一 (%>四)",
  },
  {
    codes: "        '.#=.6'.+{.3:.(5",
    descriptions: "0@,62\\%/ (三三')",
  },
  {
    codes: "        七十\"\\..@.{=.\\).$四",
    descriptions: "6_ğ<\"^'=二",
  },
  {
    codes: "    /#五+.五七.2*.八'.$$",
    descriptions: "@3[六*七,",
  },
  {
    codes: "    4(\\}..:",
    descriptions: "+九ğ1\"0?|/",
  },
  {
    codes: "        ^/'五.8?.>{.二\\.}三",
    descriptions: "#1{?7^?^  ($7%二4,^$2九[4八五四,&*,\"()",
  },
  {
    codes: "          _六?+.&].\\[.@<.八@",
    descriptions: "22/2$)8",
  },
  {
    codes: "          六八)5.]).十四.&).]&",
    descriptions: "@,三.:%,八",
  },
  {
    codes: "          六^[\".'*.@9.^,.七6",
    descriptions: "{五{四>.,",
  },
  {
    codes: "        .6)*.)5.]一.2七.\\{",
    descriptions: ";9$=:.8>7 (@6九) (#|7]#?;6.三五(四九^九六/八+)",
  },
  {
    codes: "                            ))\\\\.\"&.05.,0.=|",
    descriptions: "&,) ?2|+六七[|\"五\\\\ [5,\\89六 (1}%_[二=五&)5,+,}&|六;*十&&48九,三%] /:$/;",
  },
  {
    codes: "        七^>}.8五.三1....:#",
    descriptions: "}(.15ü一9= \\?八_",
  },
  {
    codes: "        [=8@.{\\.4>.\"{.四$",
    descriptions: "}:ğ%六<+七@",
  },
  {
    codes: "        57九?./+.八_.)三.{八",
    descriptions: "{\"5五._一 ([六{1_%_$\"/ (; *]5\"'}@0\\7[六0)",
  },
  {
    codes: "    =\"三五./(",
    descriptions: "<;五<*36十(,=^) 一六 83,&.$19.: (2六一) ",
  },
  {
    codes: "      一(<七.$8.\"{.6;.%六",
    descriptions: "|八十@2\"四9 (60[)",
  },
  {
    codes: "      2七#@./{.6+.+*.3)",
    descriptions: ").?(9七五;十_ (六.\")",
  },
  {
    codes: "    九\"[|.十/",
    descriptions: ">\"ğ六九 ,<*{5|:0\" ",
  },
  {
    codes: "      *#一+.7七.](.5}._0",
    descriptions: "İ八九^四%",
  },
  {
    codes: "        /;<*.九二.=0.({.\"0",
    descriptions: "九}'|@}七四3(八;||8@ (*\\#.]2?41'\\)",
  },
  {
    codes: "      0=0\\.=>.?7.一?./%",
    descriptions: "四五<3六二六7 (0五@)",
  },
  {
    codes: "      {\\<=.=_.(=./+.三八",
    descriptions: "0三ğ>@ ;=6$9$三十2",
  },
  {
    codes: "    *六九$.二8",
    descriptions: "]三9\"{一二(>\\(9一七), 七二九/[\"九|7(.)$),九|十3>]\":?5九:]八} (3>\\)",
  },
  {
    codes: "      @?%_.1十.二2.二三./十",
    descriptions: "十六?八:08)三一二}>5九 ():<)",
  },
  {
    codes: "        ^{+二.=/.8八.%&.*一",
    descriptions: "3,|八)七;五1? (二(/)",
  },
  {
    codes: "        $+3,.七%.$七.?_.#6",
    descriptions: "\\{(]3二%}4 ([78)",
  },
  {
    codes: "        81+9.5三./八.@八.七$",
    descriptions: "四(三8六8+#$'(一?*)",
  },
  {
    codes: "        <^$/.)4.%九./9.]}",
    descriptions: "三:??;|&,2/]",
  },
  {
    codes: "        <二,}./#./$.#*.|二",
    descriptions: "7)3四二六'九*<8 (}\\六)",
  },
  {
    codes: "        $+\\}.?五.9&.)&.4=",
    descriptions: "{*十]^\\77 (^;一)",
  },
  {
    codes: "        12{9.七1.五|.1<.@\\",
    descriptions: "*>#\\十八^1九 (+[#)",
  },
  {
    codes: "        5;8十.)五.}/.六8.=二",
    descriptions: ",五&; 03>[三3$+?3. (=2一)",
  },
  {
    codes: "        |63=.|].#6._/.3<",
    descriptions: ",9)3_0@{七?7 (1/十)",
  },
  {
    codes: "        \"9七0.八0.#6.?..^9",
    descriptions: "5]四\"5_=六$^_.八 (7;<)",
  },
  {
    codes: "        #(|八.[[.8,.[5.'5",
    descriptions: "一#八[6{+&]= (]_>)",
  },
  {
    codes: "        }&?6.{0.|五.?}.\"?",
    descriptions: ".}一_五;六<六 (]二:)",
  },
  {
    codes: "        ]5.3.五六.16._#.七0",
    descriptions: "\"]\\)=二)|6 (&9七)",
  },
  {
    codes: "        (42,.&9.0^.=_.(2",
    descriptions: "<6三$5一2.*>]( ()/七)",
  },
  {
    codes: "        $,4=.+九.\\五.>/.七3",
    descriptions: "0五=]9七^] (@7九)",
  },
  {
    codes: "        $03#.?八.六@./\".2三",
    descriptions: "六[<_@'$一[ (#0{)",
  },
  {
    codes: "        /[<@.__.四2.#;.<\"",
    descriptions: "[)&9二,{&2 (七5()",
  },
  {
    codes: "        二8十>.<'.34.六5.:;",
    descriptions: ";51*1_九82]3 (?9$)",
  },
  {
    codes: "        >_0/.'1.=,.四%.%5",
    descriptions: "三@3:8,(七七 (*4九)",
  },
  {
    codes: "        5\"&:.50.3%.2^.](",
    descriptions: "&}十(八(五六 ([@九)",
  },
  {
    codes: "        *'五<.=<.8}.<四.3九",
    descriptions: "}6=*:%$四4, (\"4;)",
  },
  {
    codes: "        6.8[.|%.七^.=6.\"十",
    descriptions: "{5(?九4三/三 (四十2)",
  },
  {
    codes: "        1&][.$+.#&.2#.六;",
    descriptions: "];(3.?<36@ (4@[)",
  },
  {
    codes: "        \"(八[.,7.7?.>\".二\"",
    descriptions: ")#@6八|二&一 (八1九)",
  },
  {
    codes: "        1六&|.,].四8.7].)四",
    descriptions: "六^二0八五十[=\\ (:=,)",
  },
  {
    codes: "        二七?0.;0.一四._).=3",
    descriptions: "0_(@二7六};\\ ((_@)",
  },
  {
    codes: "        四}2=.二9.六<.@3.)0",
    descriptions: "$\\8九八7&|| (74@)",
  },
  {
    codes: "      :|\\1.=(.}>.]3.^8",
    descriptions: "46六=]#\"三_\"?% ('}十)",
  },
  {
    codes: "    1*0一.七(",
    descriptions: "|<ğ21,[十6",
  },
  {
    codes: "          48}].)\".7'.\\4.{*",
    descriptions: "İ4\\8= _二 五'[6${ı",
  },
  {
    codes: "          ^&5九.%[.+>.0/.三3",
    descriptions: "2?&5四& [0 ]'|6*9ı",
  },
  {
    codes: "          \\0二六.|@.,六.:3.[\"",
    descriptions: "/>7三.四三6 |) 八'一九5五ı",
  },
  {
    codes: "          ='九9.,9.三].@/.;|",
    descriptions: "2十3@七38%[_ |一 1[)'%|ı",
  },
  {
    codes: "          '十>..;).[(.&0.十=",
    descriptions: "İ'7_.*2\\> 5+四}7._#|ü|",
  },
  {
    codes: "          3三?<.二/.七六.#'.八九",
    descriptions: "2;ğ:},五_8",
  },
  {
    codes: "                      3#$8.&0.)十.{三./(",
    descriptions: "{,;三[|?116|/}'?(%七九=)^@十:=\\四>6:_6%[$) 1八8?)",
  },
  {
    codes: "            十\\.二.$@.?8.(9.)$",
    descriptions: ";@{/七8)'3四4)/:|\"@ (||7$七,1七&<;3#<^:14{@ )",
  },
  {
    codes: "          015&.8'.83.71.7八",
    descriptions: ",\"5_9一+3:($_,",
  },
  {
    codes: "          四]8'..1.,+.>十.3\\",
    descriptions: ",\"六[\\,[?七1.",
  },
  {
    codes: "          四}09.@3.八..4}.6一",
    descriptions: "一| (17# (七|]一八\"&%? 5*>..9六$3八)",
  },
  {
    codes: "          @=&四.=:.)\\.五十.3_",
    descriptions: "%六 8_1\" (二>七9]+4七'@ (5.二7九75]四)",
  },
  {
    codes: "          2<[[.^7.]..五九.^4",
    descriptions: "İ#}6七#&<八九9 \"二|5",
  },
  {
    codes: "          ]@_\\.六6.$八._[.(@",
    descriptions: "İ(五)4\\[^(九7? 529五",
  },
  {
    codes: "          :<[九.:>.|..7\\.+'",
    descriptions: "&6:[_0$ ^{ 八ü5[八.)6=",
  },
  {
    codes: "          八)2五.五0.\"%.74..一",
    descriptions: "^五47}二%? 八7 8ü[}95')8",
  },
  {
    codes: "          [,六'.*三.\"7.88.=^",
    descriptions: "$]>七,七*\\{ (\"七十+?ş 2>)七六 三;#<}ı])",
  },
  {
    codes: "          三+((.0一.*0.:_.3=",
    descriptions: ">$;=>十8六6_/2\" (]$8)",
  },
  {
    codes: "          六%7六.5<.八[.0六.(}",
    descriptions: "==*}六(8?六^ 4:_'{^",
  },
  {
    codes: "            ./*8.8(.59.)1.'?",
    descriptions: "5(八8十 .0[6.% >5||[九+4^八(%^|]",
  },
  {
    codes: "          6643.@七.#九.,8.+,",
    descriptions: "一2二[六3|.,,%.\\ (]06)",
  },
  {
    codes: "          \"一/7.二@.:5.}九.^<",
    descriptions: "/[+8}>{三八@ (七'二)",
  },
  {
    codes: "          三#.(.=1.五|.三6.^'",
    descriptions: "$<1八21{( |十十2])^$",
  },
  {
    codes: "            二29十.]*.六|.十%.五8",
    descriptions: "五+九{\\<3.4<=\"四",
  },
  {
    codes: "          ,一]#.%&.{,.:*.]2",
    descriptions: "'}&[八八.二(/七五",
  },
  {
    codes: "          '\"3*.0#.\\:.?#.<]",
    descriptions: "四,|7<9 (一1+7+2七",
  },
  {
    codes: "              <五九三.:四..4.;9.^{",
    descriptions: "?,\">八\"]'二/]二五0七?[42.5三*九^二@",
  },
  {
    codes: "          4*^八.1八.二7.4&.四四",
    descriptions: "=,36<@'?)[",
  },
  {
    codes: "          29)9.'6.4%.^\".=8",
    descriptions: "六)^.(>}\\八2 _[/$五\",95ü5",
  },
  {
    codes: "          九_^=.$4.五[.\"四.6十",
    descriptions: "\\+{二:0$/1",
  },
  {
    codes: "          25=}./八.六五.|(.(^",
    descriptions: "*>ğ5{4一|九",
  },
  {
    codes: "一三.'_",
    descriptions: "6ü]:@0八 二5>三[=2 九七 ^%1=六<ı, 5(3/}{ı, (五$41@;一 $四*\\*{4 }八+ı9> 0:@\"三",
  },
  {
    codes: "  _$:$.*三",
    descriptions: "五=7ı5ı>07 五\"ş一^ |5\" (<十*五;^ _二[ış九\"^ış *>' _二)_/七 二\\>0,八ı",
  },
  {
    codes: "    '六1三.29.<\\.]&.十=",
    descriptions: "7六四五一&三/<_九",
  },
  {
    codes: "    :2三[.三一.16.>3.'#",
    descriptions: "2>,9%八>",
  },
  {
    codes: "      5七八9.^\\.七十.(].四十",
    descriptions: "%五>){二%十7 (=73^5.$6八1^十*)",
  },
  {
    codes: "    \\,{0.八5.八/.='.07",
    descriptions: ">6ğ7.4=9七",
  },
  {
    codes: "  >,+&.:八",
    descriptions: "<][ı五ı八9* $>ş4六 \\,: 3]516\\5 33}ış_9二ış ^三, 56=七;3*/*:+",
  },
  {
    codes: "          <|97..九.十\\.\\..;=",
    descriptions: ">8 (&2.8七;2六\"60 9 (|) )30ü十一ü&",
  },
  {
    codes: "          <*#'.05.>|.26.5'",
    descriptions: "$4/06七={5238#]/六二三1 (]二6四92<*>十% ' /六八,) 二+ (2]9@&ı",
  },
  {
    codes: "    /十三$.+六.|?.二+.@6",
    descriptions: ")一ğ八九+二3一",
  },
  {
    codes: "  3+:十.十6",
    descriptions: "{<一ı[ı/9{ #@ş\"; 八^* /^\"$3九* 四九0ış\"?$ış })十 |.>]/{二,一\\ 八=|'一3ı",
  },
  {
    codes: "      六/五%.?'._3.[<.90",
    descriptions: "四5五[9%九五^)&\\六",
  },
  {
    codes: "      ;+1三.66.7\\.+八./(",
    descriptions: "0=?%九*7二9七",
  },
  {
    codes: "      [&)8..\\.<七.六1.%3",
    descriptions: "\"3八56;6二%> /七\",}一ı",
  },
  {
    codes: "    ]_*4..&.+8.^:.76",
    descriptions: "?1ğ七}<8#6",
  },
  {
    codes: "    4&八|.八4",
    descriptions: "3|四三九\\三3(九#六), &\"九$:85%一5 (6/|), %\"三1*/1?)六('(:),",
  },
  {
    codes: "      $[八[.$9.一>.七二.^^",
    descriptions: "#六]82一0@ (&65)",
  },
  {
    codes: "      三=}:.('.#?.一{.,$",
    descriptions: "[:]6]一6一?* (@三<)",
  },
  {
    codes: "      一一'1.\"<.|四.九5.*5",
    descriptions: "2>\"九))/:0/八 (七>))",
  },
  {
    codes: "      {{81._0.5六.:四.*)",
    descriptions: "3<(+七>77<_ (\\九十)",
  },
  {
    codes: "      #(|[.0二.'|.79.五<",
    descriptions: "*,{^3@七一7'}*<?  (%+\")",
  },
  {
    codes: "      3七十6.{3.}@.3].'八",
    descriptions: "%\\.]四:+)2/> (4十')",
  },
  {
    codes: "      5@$6.,@.*#.九@.一五",
    descriptions: "二二+|9六#*八 ($]|)",
  },
  {
    codes: "      (+十/.2..41.)0.一*",
    descriptions: "十:5.+;#五 (]\";)",
  },
  {
    codes: "      0六4\".&5.三}.六\\./十",
    descriptions: "7:'(69四\" (6$+)",
  },
  {
    codes: "      0*四4.&2.//.四|.二|",
    descriptions: "4^)?4.九@ (9^四)",
  },
  {
    codes: "      |八四七.4八.6*.4&.,*",
    descriptions: "\\@7%九|九9一.二八,八\\ (@65)",
  },
  {
    codes: "      3_\">.九八.*\\.+,.{九",
    descriptions: "]九四[4]三二0+ (&四一)",
  },
  {
    codes: "      五_+六.八:.七一.]*.三$",
    descriptions: "7=ğ)$1*>&",
  },
  {
    codes: "    二5&?.;^.七5.4}..9",
    descriptions: "7>ğ一六 #(一}],<69+五 /( 九ü九*四二}8五",
  },
  {
    codes: "    ?)@*.一\\",
    descriptions: "2:ğ]八,0[(",
  },
  {
    codes: "          =;\\九.\\1._;.1*.七1",
    descriptions: "二9%4,一$7}>十八3",
  },
  {
    codes: "          {)五八.^四.47.#五.8&",
    descriptions: "五|十?一五]8@",
  },
  {
    codes: "          八2=七.七8.:,.6=.2二",
    descriptions: "四0{+五八/{\" }八37&7#?+|'ı /( 5二}五5,%}3ı",
  },
  {
    codes: "        \\=*%._/.[&.6七.21",
    descriptions: "^,ğ|一,*=7",
  },
  {
    codes: "        %[%*.*|.1:.{].4;",
    descriptions: "_ü8*$<1^0 >; ?ü.@;\\十=7",
  },
  {
    codes: "        七$:九.}一.八..六八.六}",
    descriptions: "^三})&0",
  },
  {
    codes: "          '7)%.=#.^;.9%..1",
    descriptions: "{?=<5$\\{%6",
  },
  {
    codes: "          九七一@.$0.六四.:$.]7",
    descriptions: "五ü]]八三> 七}?7 三一 $九*\"#八ı",
  },
  {
    codes: "          2]_二.六一.&八.43.1;",
    descriptions: "_1六=4][1;2;6",
  },
  {
    codes: "          $\\四{.二@.1/.#0.8*",
    descriptions: "55?(@|<六 十642^7:一27]&$",
  },
  {
    codes: "          $六8}.\"/.,).5&.9九",
    descriptions: "}7(+<+^789\"",
  },
  {
    codes: "                    <<,*.九0.一#.'/.二6",
    descriptions: "(:二;ü6^十+^ (五.*一]九&,@,15>^<十$)6431(9#)",
  },
  {
    codes: "          0?;@.>:.七0.>[.&1",
    descriptions: "^=*<>六*五|+08",
  },
  {
    codes: "          十八>\"./(.,6.(<.@[",
    descriptions: "\"#=65=<9 :[3&ü&2九七 :0=四",
  },
  {
    codes: "          |?(%.,四.五@.}_.$[",
    descriptions: "一\\,%六;七|0@ <' 25一三:九ı",
  },
  {
    codes: "                    {+|'.七:.=/.一四.]6",
    descriptions: "641)一三七71+三22/*||0<@{7]{2|九(",
  },
  {
    codes: "          /\\一^.=6.[|.+0.7=",
    descriptions: "^)ğ*);}.八",
  },
  {
    codes: "三&.?2",
    descriptions: "7ü9&0'0七803八[",
  },
  {
    codes: "    |六八九.[,.@{.1[.>5",
    descriptions: "}%=}94:;2^九>=*<@六<17| 0ü..3\\=七37",
  },
  {
    codes: "    七]八<.'\".<@.一9.四5",
    descriptions: "八#九'6'<1*5&[/{\"?%八五 {ü?+三67四\\6",
  },
  {
    codes: "          %|3(.9;.@6.五三.7=",
    descriptions: "01}_:一()*.?@5十^[一^_^) +七?>2<$|\"|:5;; *ü五6]2<四'一",
  },
  {
    codes: "          /9,_.10.67.]7.&'",
    descriptions: ",(七1{九一,,%(\":)8))?三{=|0&89&|#;_6十^$十 一ü}^&三96:'",
  },
  {
    codes: "  \"_五&.9五.3九.3=.,,",
    descriptions: "4一ğ4十 6)2}$:一+三7[9一7 [ü(*;,((九:4十二",
  },
  {
    codes: "  #七4=.{;",
    descriptions: "@0ğ));八八|",
  },
  {
    codes: "                          ?二+4._\\.2/.}5.六三",
    descriptions: ":{'[(三(8&||5:六九6631}*四>))',一3%)8+?^$@_0$4][&\\[= 3, :[4<01}",
  },
  {
    codes: "      {<1^.6}.{:.九:.2三",
    descriptions: "|ü,.2一一,_*三",
  },
  {
    codes: "      一1'六.5|.]5.(8.@9",
    descriptions: "<ü三_七九*<八_=",
  },
  {
    codes: "        十^^%.|四.八九.+>.'_",
    descriptions: "{'5)/>三 8ü?'9++;^*",
  },
  {
    codes: "        十[^四.<8.]?.:\\.'|",
    descriptions: "4}ü0|&七1\"8=|/01}4",
  },
  {
    codes: "        _\\7^.\\&.,三.五9.'1",
    descriptions: "十2<}_一十十5](\")ü4@一二)%八{",
  },
  {
    codes: "      3}}@.9[._五.1二.];",
    descriptions: "\\ü9十\"十%;六5",
  },
  {
    codes: "      \\=二?._{.七|.>\\.四.",
    descriptions: ".ü[<7)]2\"$99",
  },
  {
    codes: "      一\"\":.@@.$\".==.@]",
    descriptions: "4$\"?,@ ;ü11.|:9_59{",
  },
  {
    codes: "      6二;*.'0.@十./(.3_",
    descriptions: "|/9@#3六?.3\"+@@}",
  },
  {
    codes: "      <<3\".=2.三2.0@.7四",
    descriptions: "(ü;\\:+#(':&{}",
  },
  {
    codes: "      98^%.|七.?>.#@.'5",
    descriptions: "(\\五五0?^ü七<<一八五九}^<;",
  },
  {
    codes: "      5,71.,#.(0.0$.|7",
    descriptions: "一*ğ;^?@\"1",
  },
  {
    codes: "  #8.)八",
    descriptions: "二$(*;+五三5@\"%7 |三 三#十1<)3.五6 (9)(一十 [\"七/ \\[8,十二 二\\?\\&8? 2\".) 484@",
  },
  {
    codes: "    ,_$&..0",
    descriptions: "){^%+4> ; #/ 一ü#([.3四九 ",
  },
  {
    codes: "      :七?二.=\\.}3.&2.=%",
    descriptions: "(${<],\\ |",
  },
  {
    codes: "      7十七'.,7.)三.五二.}\\",
    descriptions: "2(^*.)4 .  七7四6|>",
  },
  {
    codes: "      /0八8.0一.六6.4八.,9",
    descriptions: "#583一/| <  {五<]七二}八二%",
  },
  {
    codes: "      七6[\".@六.%|.}二.:'",
    descriptions: "%\"三$十&\\ *  :=:1=十#9",
  },
  {
    codes: "      %七二}.<[.]%.<]..四",
    descriptions: "&3(3_?% 2  +三?; (21[四'四48 +^三十)",
  },
  {
    codes: "      >:<|.2/.24.七4.]*",
    descriptions: "}/十%(四四 8 ''ı4 .一ğ二= 九ü})&十#十>",
  },
  {
    codes: "    0七}=.(一",
    descriptions: "2+[9?^\\ 十六 6\" \"ü$#99五&十 ",
  },
  {
    codes: "      '一^四.>=.07.0:.(3",
    descriptions: "40}?,9} : } [2].6一> ((九?), ?@>7'一]",
  },
  {
    codes: "      *%三..三>.?].七三.?一",
    descriptions: "<;/5\"|四 二;二9九六:.$ü<",
  },
  {
    codes: "      |#69.七4.#5.四<.)#",
    descriptions: "(]&;)/< 8]6?六八>(|*",
  },
  {
    codes: "      *>七,.^>.0/.?4.>十",
    descriptions: ")<)五七3九 # 二'九, *七ğ6& /ü%一/2@|;",
  },
  {
    codes: "    #*六(.五二",
    descriptions: ".八{三一?# 八% (@ 0ü5};+#6十 ",
  },
  {
    codes: "      %六.<.&八.]7.二:.\\&",
    descriptions: "十,六]十)_ > # [五?\"_5?一|一九 (8$$), 九一2%^ 5[$;^*]",
  },
  {
    codes: "        一9+2.(\".)0.|$.,九",
    descriptions: "{,十|^,(=3; %'五_2七二=四.5[*+ ${$:61",
  },
  {
    codes: "        (八=?.九十.{(.$五.'十",
    descriptions: "*三29}(9@,4 ;'2*{十,[0:;| '58\\])2四4 &四@<$_ı",
  },
  {
    codes: "      &>\\\\.:七.五].=?.<?",
    descriptions: "/五99257 $ 五'=(| 98ğ5) (ü7={\"0三$",
  },
  {
    codes: "        \\21).四=",
    descriptions: "[  $9?五 *@ ^8543}4005 {;十} (8} :二^_$}六2)",
  },
  {
    codes: "          =>三=.:\".十..[二.@+",
    descriptions: "九,七@} 三/四4=(%?九:(1 __/.",
  },
  {
    codes: "      [\\+$.#,.\"十.0八.|0",
    descriptions: "十*'六;一 八^1八十0七十?七",
  },
  {
    codes: "      七7\\(.2(.三,.二_.8;",
    descriptions: "\\5|七=七2| 三/[]?0.八五%",
  },
  {
    codes: "      /7一'..三.$二.#|.5#",
    descriptions: "\\?*一三四_ & 二 ,三十八 % &'五: ^四ğ;四 9ü?十_六'三.",
  },
  {
    codes: "    7九:+.17",
    descriptions: "{三五@=76 / 八 +. ;ü\"5\\<*%? ",
  },
  {
    codes: "      01=3.43.[<.]5.:%",
    descriptions: "2二2'0'六 | 4 [27(;$$856; (四四]), 5.1@>22,**?八三(3623]",
  },
  {
    codes: "      +@四;.0一.[?.三4.五+",
    descriptions: "+*7+0+1一:5 9%?;_3#\\三ü#",
  },
  {
    codes: "      ,@@..(,.<五.七(.3/",
    descriptions: "2十34#五四 ( }'?ı三 九&ğ'3 &ü\"3五/+86",
  },
  {
    codes: "    五.八5._.",
    descriptions: "?\\'十一]\" = .0 _四 二ü^@&4十十2 ",
  },
  {
    codes: "      八\\%5.&_.一八.0二.一{",
    descriptions: "#9#&0八# ) >[ [8七\"九.2|三\"5|八;:4 (&|1) ]",
  },
  {
    codes: "      [$8_.:七.十4.#@.五八",
    descriptions: ":%83|'十' 十七4<&八],_",
  },
  {
    codes: "      $>,'.]^./[.*6.&?",
    descriptions: "(十03'%6 $ |3'三', ,一ğ>' =ü+^7九%%'",
  },
  {
    codes: "    ;?一七.三}",
    descriptions: "&/五?9五4 七 七^ :ü三+\"\"8七# ",
  },
  {
    codes: "          [}_*.:@.6*..}.\"&",
    descriptions: ")<3.五,\\ 五 [046.一 {,)2七*_|五[ ^8+& (六四\") ]",
  },
  {
    codes: "      795<.+9..[./9.$|",
    descriptions: "十四八@4] ;+'/二(,7",
  },
  {
    codes: "      (08|.^;.0&.6:._<",
    descriptions: "=&\\八?5'( '0$?;7]+",
  },
  {
    codes: "      }六六四.+十.三=.94.一'",
    descriptions: "%三4六}|, 4'}八> 七:ğ&5 6ü9#+=2\\(",
  },
  {
    codes: "    \\一八..七]",
    descriptions: "'=5三\\)3 六 42 九ü_,一8二{? ",
  },
  {
    codes: "      +.&9.九<.:8.}8.=四",
    descriptions: "&:1{54= 四 (十三_]^8]'})",
  },
  {
    codes: "      7=}九.<[.<$.|{.}3",
    descriptions: "'}:四\"9)}? >__十]{",
  },
  {
    codes: "      \">&五.2二.{一.1二.=三",
    descriptions: "%'&?2六十六 五>'九.?六4[ 七5#\"四/",
  },
  {
    codes: "      九_@9.五#.6[.;:._(",
    descriptions: "#|\"%}*0 7'.;8 .4ğ五* 6ü@#二0二/;",
  },
  {
    codes: "    2@{\".^<",
    descriptions: "&&ğ=0 (五);(+>'($ |四 四ü9五'一,(:",
  },
  {
    codes: "        \\,&6.三{.{^.;+.}}",
    descriptions: ":}_+(0; 九 七 [ =?[#六二57$ (2%7) 六十_七 =_二9十@0?=+}$一六## 16三四]",
  },
  {
    codes: "        2五1四.二#..@.69.二1",
    descriptions: ";五六(六\\@ % ]'09 :ü2\\#%@十?",
  },
  {
    codes: "        %四1五.(\\.六{.%3.四6",
    descriptions: "=\"%6/15 & (<\"%|04<)",
  },
  {
    codes: "        \"4&_.{_.+9.(%.7{",
    descriptions: "一二九5&七\\ 0'*0* 1ü|<$98一5",
  },
  {
    codes: "        @+{&.八三.@三.2八.&+",
    descriptions: ";+'/七(+ # ((五$\\6}.\\4[=, (八(+) )",
  },
  {
    codes: "        9:2>.9<.{^.,;./+",
    descriptions: "86}4%<0 |'<ı八 9ü;=三九}八8",
  },
  {
    codes: "        十@/五.\";.4\\.五3.&(",
    descriptions: "?十#7#/^ ;( (<\"'八=|5^五5( (\"]|) )",
  },
  {
    codes: "        |6|+.九].'四.>>.{:",
    descriptions: "/,}*5九$6\" 三<46 ([=;)",
  },
  {
    codes: "        8%四一.7,.\\&._四..0",
    descriptions: "三(,&?>7 )6'+%8 %'ğ八; @ü(6{,\\7三",
  },
  {
    codes: "        &]},.1}.九&.一三.十十",
    descriptions: "%31'三|0 @ . [6七八5[4二_)二,二00]?^2|六)<^:> (<1/|=='五]_) ]",
  },
  {
    codes: "          九/二..@<.二2.9十.|二",
    descriptions: "13>9二八^ + [ (6&@+#.1?五'\\%^{|;_<))",
  },
  {
    codes: "          ^一++..八.:3.6六.七$",
    descriptions: "3|^*>&{ 一 8 (:九,]$五_$,(六#)九二{}%?(]九)",
  },
  {
    codes: "            ]<三/.一8.=%.'^.9$",
    descriptions: "|?@*'#? 一 6 (%73>])八|327} ?十(*=3_^.)",
  },
  {
    codes: "        <&2}.\\}.%?.{6.9三",
    descriptions: "*6+0:*四 .'{&$ 九ü七&5:|<#",
  },
  {
    codes: "        :+09.;2.[|.一1.=<",
    descriptions: "二&六#.@5 ]+_%",
  },
  {
    codes: "        九&一|.|\".8;..一.<7",
    descriptions: ";@ğ2< 4=\"08$四*41 0_ 6ü4^{.三0十",
  },
  {
    codes: "  /六=}.;8",
    descriptions: "]\"ğ7二10}$ (>\"+:三 &>\\:$==3五7$< ?#,[2)",
  },
  {
    codes: "    七,十$.^>.^5._?.*}",
    descriptions: "*三七十七_*,?六,%| ({99ışı. )]?&七,;2,+)",
  },
  {
    codes: "      .3?四.+二.三九.}>.+:",
    descriptions: "=+8 九二\"$[三\"&/+[_5< {=0+; }<|7,=)>}/]%六",
  },
  {
    codes: "      3,34.|七.十十.63.五|",
    descriptions: "7二ğ八七 ]%>;1 66[%4(九|;四53",
  },
  {
    codes: "      %#8#.5七.九].|+.五2",
    descriptions: "6四ğ八;{四三七",
  },
  {
    codes: "三2.8@",
    descriptions: "2>':,]七8$, 九<>%#二]|_1五057;2, 十?,*一/三三@=|&, ^#",
  },
  {
    codes: "    {五六7.四^.^六.[).8[",
    descriptions: "{^4|七[%四>九/四, _七:@{八ı1 \"ü8]@'@1. ^& @1)三?@* 2{,ı'一4;六^?",
  },
  {
    codes: "    03.四.:五",
    descriptions: "1七?ü8$9 七# ,*.九四/ı ",
  },
  {
    codes: "      九1,9.=一.+3.29.72",
    descriptions: "{:+ü八('",
  },
  {
    codes: "      6<*,.9).[0.八/.|一",
    descriptions: "五六/ü1一'  +]四?九[ı",
  },
  {
    codes: "      51,#.五}.59.'3.5'",
    descriptions: "+一五#5\\7 ö* 0>=< {{'.#{十@九ı",
  },
  {
    codes: "      8>九?.十6.+七.6(.}\"",
    descriptions: "八]7'六三& 六>7^ 二*四4 七<.四5'}&3ı",
  },
  {
    codes: "      /_:^.,*.77.十<.^+",
    descriptions: "[|ğ,(@$二)",
  },
  {
    codes: "    _六四9.二)",
    descriptions: "^[>=3}3^, +6/.70?90九1:一, ?>{1<9={. (%\\:二[0./#|'@,){) 9{",
  },
  {
    codes: "      <&)4.7%.五=.9'.&十",
    descriptions: "#<''*+}2",
  },
  {
    codes: "      二七@+.=9.&=.0六.5七",
    descriptions: "九{十十((五80>五3_",
  },
  {
    codes: "      [%\"9.九三.\\7.\"$.(4",
    descriptions: "|9八[5^_&2 (//七69)5{}).])八()",
  },
  {
    codes: "      ';}5.2$.):.|九.59",
    descriptions: "=44\"+54>\\15 (\"2,+;一*{八@八四{6十二]2.三)",
  },
  {
    codes: "    五#&)..+.@八.[五.>五",
    descriptions: ",\"三#六三+七2<)'?/%/ %](&四<九8^ı三ı[ &0]一#五_九二>|3ş 4ü01九]{|%",
  },
  {
    codes: "      \"|]'.3\\.{'.二<.十七",
    descriptions: "%1{/=/+\"/{?",
  },
  {
    codes: "      =))$.,+.({.3}.0)",
    descriptions: "')[?|3/81;%%^% (二*#$ 43{),':]])",
  },
  {
    codes: "      一六_'.二].七8.二四.9\\",
    descriptions: "_五,%6>四 '#0一二一四6四ı",
  },
  {
    codes: "      十\\:0.0<.4>.五2.(>",
    descriptions: "{8(+8五ü(89 \"5)一^%2八2ı",
  },
  {
    codes: "      =6;8.:\"._].>四.3九",
    descriptions: ":4ğ;?(|&]",
  },
  {
    codes: "  7七$:.[八.;$.{<.6)",
    descriptions: "7四^4}.^0<$<五8(六+, #四九五\"+四二24>)' 6\\ _三=一十+^|>四?*},",
  },
  {
    codes: "  <四十七.\\9.\"六./'.{8",
    descriptions: "^_ğ6%:&1+",
  },
  {
    codes: ":8.[1",
    descriptions: ")六6\\,</四(五# (八七四八\\ ;:0\\ 一%^=6, =4六= 九'七 1%47 一&>6一五ş) >( &/#六))ı\"",
  },
  {
    codes: "  (六/1.[&",
    descriptions: ":%:九一:2 ('4$十;) {; 九ü.^&四>}+ ",
  },
  {
    codes: "    九五\\一.'..,,.2@./8",
    descriptions: "二)=}_]_ (4十二0四)",
  },
  {
    codes: "    ^'七$.++.九(.\\二.@五",
    descriptions: "140#四{. 1ü七03&6一六",
  },
  {
    codes: "  3&|六.五.",
    descriptions: "+2ğ*>五\\9一",
  },
  {
    codes: "      *<六%.%6.[十.5(.五+",
    descriptions: "+五|>/5六二九1",
  },
  {
    codes: "      8_7八.[/..{.五\\.三^",
    descriptions: "+\\ğ7_/四'&",
  },
  {
    codes: "    5'^三.七*.\"七.八/.\"/",
    descriptions: "0五(}2#&|#; /$:, (0四4二二)4.{_) 7> #<七六#.>?/\\8@'",
  },
  {
    codes: "      &5六二.四;.1一.十[.5}",
    descriptions: "0/3八八,<九<八",
  },
  {
    codes: "        8_\"十.|十.9\".(四.{>",
    descriptions: "3十八@_*1%5[",
  },
  {
    codes: "        >::;.?,.六>.>十.(.",
    descriptions: "二十八?七五'",
  },
  {
    codes: "        1八;:.四/.四).:#.1]",
    descriptions: "'四四四<六二8%",
  },
  {
    codes: "        ;]6@.一,.4:.8^.,|",
    descriptions: ")八;?一$九};五:0/ \"*+5}<\\%(4;,9 (8+二三)",
  },
  {
    codes: "        \"<+5.,2.八_.\\(.(?",
    descriptions: "十,?9=二' 四(7[\\|857一七",
  },
  {
    codes: "        七?三@.}..4十.34.九:",
    descriptions: "]^ğ%十&54五",
  },
  {
    codes: "{;.[$",
    descriptions: "八@)九三968%十^ (八\\,五五 82[% }&七18+ ?八_, ;6| 六41三 ;二64[^ş),",
  },
  {
    codes: "    九[=八.<4",
    descriptions: "1)ş#六ş ,#?ı *958>九.?;8$8|; 五3.%九四3三2<三(/@6), 九|*^{},",
  },
  {
    codes: "      '[[/.>?.&&.3|.1{",
    descriptions: "^.ş**ş 三;$ı 6*@{九8九一二五=#八",
  },
  {
    codes: "      九二七七.79.%\".\\+.*十",
    descriptions: "+02三5/",
  },
  {
    codes: "      [八)|.{五.五}.@四.5,",
    descriptions: "_八{6[九六六 ];*七<( (,>;>&)",
  },
  {
    codes: "      %+#&.}..五*.&..3#",
    descriptions: "一2\"十 二,=五&[",
  },
  {
    codes: "      八;四\\.%@.四&._<.五七",
    descriptions: "3{三7):",
  },
  {
    codes: "      ;5=|.,2./5..?.@;",
    descriptions: "(&<>)$",
  },
  {
    codes: "      四十97./\".|;.九\\.9八",
    descriptions: "四?<\\)*十'5}25= (');)",
  },
  {
    codes: "      ;1)1.四>.4{.\"[.五&",
    descriptions: "%十$三七八9]+%一二,> (({十)",
  },
  {
    codes: "      \\五#十.二八.:[.>5.\"8",
    descriptions: "}>六?十,2>. (&^?)",
  },
  {
    codes: "      }六七{.4\".一\\.22./&",
    descriptions: "#)|%3十#:十六(  (八三?)",
  },
  {
    codes: "      '#($.六@.6#.;9.9三",
    descriptions: "'_8/+|五]三4&=十 (64五)",
  },
  {
    codes: "      75?]._^.@1.七3.*;",
    descriptions: "00}0]:$^十>一九  (|]:)",
  },
  {
    codes: "      十&\"'.:_.5五.一;.十十",
    descriptions: "{;^31}6四四($,*)",
  },
  {
    codes: "      ')五五.一1.七:.$:.=,",
    descriptions: ">))]4六3:(一] (\"0=)",
  },
  {
    codes: "      7二\\[.<@..#.八\".4$",
    descriptions: "\\二五%&/六.2, (六|五)",
  },
  {
    codes: "      八|]八.6=.&/.(6.\"'",
    descriptions: "%\"]3>四@# (九.6)",
  },
  {
    codes: "      8三^[.@<.=?.%;.一^",
    descriptions: "+九<78七 @ü二&]/",
  },
  {
    codes: "      '/十..六8.八:.?4.五&",
    descriptions: "'6/|({ \"\"七一四九;;|ü二",
  },
  {
    codes: "      +八$>.0:.*9.二7.<(",
    descriptions: "($ğ<_'>:十",
  },
  {
    codes: "    十六^?.+三",
    descriptions: "}\\ğ十[_165 ",
  },
  {
    codes: "      *\\2九./].九六.9$.}\\",
    descriptions: "六九5<>%7",
  },
  {
    codes: "      6?@一./>.}七.>$.六9",
    descriptions: "@2%?>4|四 (%5八(\"十}: (七九2) )",
  },
  {
    codes: "      <.9%.7@.>^.[6.=7",
    descriptions: "二\"?_十)^<八",
  },
  {
    codes: "      九'+:.%/.(:.%^.0>",
    descriptions: "?8/{7.<';",
  },
  {
    codes: "      @71*.4:.十|.七$.四.",
    descriptions: "四%[五=_,=3",
  },
  {
    codes: "      7\\\"..@/.\"=.:].{0",
    descriptions: "])6(|<02^(?",
  },
  {
    codes: "      3<:}.}|.)二.00.}^",
    descriptions: "#}ğ%6九八\\]",
  },
  {
    codes: "  <}4$.^/",
    descriptions: "/ı,$1ı>+ 32:八八\\:]\"二8' \\# '.<4,?ı[ 一ü&二;%$(8 35%六5九ı六 @\"\"6十.ı",
  },
  {
    codes: "      六\\_1.@#.四}.92.3?",
    descriptions: "\"$?.9",
  },
  {
    codes: "      &|1(.\\\".二^.@'.三八",
    descriptions: "&./]) &ü[&<六",
  },
  {
    codes: "      $二]&.八[.:[.+\".七&",
    descriptions: "四(ğ十2九十%0",
  },
  {
    codes: "      一<{\\.(|.7,.&#.3\"",
    descriptions: ":|.;;]+@二|3 @& 六[7|五\\2",
  },
  {
    codes: "      %<五?.*十.0五.9;.5四",
    descriptions: "&五ğ三.86*3",
  },
  {
    codes: "  @二5[.96",
    descriptions: "4>8&一1 ]\" \\=}@\\$ı ",
  },
  {
    codes: "    三>/#.十*.>=.'2.四八",
    descriptions: "0%三]0九",
  },
  {
    codes: "    3@^#.6三.;九.}九.八1",
    descriptions: "八(;%=@  6\"四_%|ı",
  },
  {
    codes: "    %$<$.4)",
    descriptions: "{一六;1,5 十四 25=4九)ı ",
  },
  {
    codes: "      \\'十?.)<.:^.\"&.>*",
    descriptions: "[2;14/]",
  },
  {
    codes: "      :二#六.88.|6.10.?&",
    descriptions: "\\+87}五' *?.{'七/>|ü(",
  },
  {
    codes: "      十%2%.|\".六).6九.[;",
    descriptions: "六#<_|=#>: 7*ğ'; 三\"&7,|ı",
  },
  {
    codes: "    >97*.5\\",
    descriptions: "6?;9=\\+:十%.0} ((<.) .4 四七$[&八ı ",
  },
  {
    codes: "      \\@<8.八二.0@./5.9九",
    descriptions: "0#'>6%七0}\\&|#",
  },
  {
    codes: "      .9'1.|#.,>.@..$七",
    descriptions: "九%七_3+]{0十+\\十六七 }三\\\\\"一ı",
  },
  {
    codes: "    7&[二.67",
    descriptions: "14[.七, (=;六) 八* #8<{九5ı ",
  },
  {
    codes: "      七\\九1.?|.1二.@4.4+",
    descriptions: "]$6$\" ([?&)",
  },
  {
    codes: "      ^&;?.|^.}七.:{.;6",
    descriptions: "#;|二52^ 八[12|#ı",
  },
  {
    codes: "    2九[#.(0.2@.:_.9九",
    descriptions: "六六+<;&;'5@,* ,% 二<3]=@ı$ 三8七75%ı",
  },
  {
    codes: "    ?}0#.[*.]二.'..00",
    descriptions: "%;\"31$\\@4九9一1='], #/六五8[@?@{0六 (七.2), ]八\"$[六:+54'> |[?):|} 六二 .+六056ı",
  },
  {
    codes: "    二'三|.1七",
    descriptions: "&6ğ(>/96= ",
  },
  {
    codes: "      四'七*.六1.八]..$.$%",
    descriptions: "4五=\")6_四2.9'",
  },
  {
    codes: "      八2&2.八二.{四.[1._[",
    descriptions: "\\一};#(/六十八'@",
  },
  {
    codes: "      /@\"{.6@.{@.)1._3",
    descriptions: "五七(4三{_二})一",
  },
  {
    codes: "      6%>(.七].2七.^8.)六",
    descriptions: ")}ğ(/九.9四",
  },
  {
    codes: "    三:_二.)$",
    descriptions: "{<|)/)_四九 (九.:) ^] __#五.(ı ",
  },
  {
    codes: "      $3*9.$[.&*.$:.+^",
    descriptions: "七'@\"二:0&| (九六二)",
  },
  {
    codes: "      &55一.>五.七9.%1.=3",
    descriptions: "三>'[九39*|/二 %+五$八三ı",
  },
  {
    codes: "    453).{?",
    descriptions: "<)ğ]+\"一{& ",
  },
  {
    codes: "      5七9?.11.6#.三6.[十",
    descriptions: "\"五72,:\") (二 ^=1八:3五\"+@ }| 9|=,/8ı",
  },
  {
    codes: "      378_.}..3七.}(.五六",
    descriptions: "五+>(5二六,)\"|二&",
  },
  {
    codes: "      &&0\\.&).12.七/.5^",
    descriptions: "十|ğ_六&:21",
  },
  {
    codes: "    :7;3.|&.一$.{0.3\"",
    descriptions: "三*;八六|=&\"[ (:}五) &8 *51_)8ı",
  },
  {
    codes: "    <3,六.9三.+|.)四._)",
    descriptions: "八七%,1\"六:] (7%^) #& {^,24}ı",
  },
  {
    codes: "    +^/四.四|.]二.一4.$五",
    descriptions: "三25|20:> 24*{ |一 ]四'\\0\\ı",
  },
  {
    codes: "    *@0).=8",
    descriptions: "{七ğ五(4#7$ ",
  },
  {
    codes: "      (94}.十..$二.4).三:",
    descriptions: "4>5@@1,(31]",
  },
  {
    codes: "      0*十/.0七.61.五|.>*",
    descriptions: "&;+四$[(%四&",
  },
  {
    codes: "      ;4'(.^<.%4.?'.;%",
    descriptions: "%;%6?% %6七十55@,八<( (3'&06四 [八四#9:7@\\3) (<=(488 /^六4?5@九=$) 十五 )=\"=五&ı",
  },
  {
    codes: "      \\309.*;.6八.&四.$/",
    descriptions: "17ğ六(10/^",
  },
  {
    codes: "    \"6={.九_",
    descriptions: "4_68^?, '4一\".\\\\; 0八$'8>ı2 >|.$>;ı, 九&#??45|% .2 四[ğ八\" ]ü,>@二+==",
  },
  {
    codes: "        0#?%.;{.[6.8;.(^",
    descriptions: "@9六 \\2二119",
  },
  {
    codes: "          2:7{.十'.十@.'#.]%",
    descriptions: "&^@>=3=",
  },
  {
    codes: "          7)\\;.九0.9$.3?.*九",
    descriptions: "_5ğ*+七(1&",
  },
  {
    codes: "      3|0*.\\}.^\\.%十.=+",
    descriptions: "十\"ğ8]七*(_",
  },
  {
    codes: "    一一二?.一@",
    descriptions: "%六ğ9\"3=#,",
  },
  {
    codes: "      (2|5.^@.$^.{(.六,",
    descriptions: "<2%_@八& '五 八六%07>ı, +三,?七76%, %8四五,9'一. )@ 十_ğ0四 6ü$|_|四>%",
  },
  {
    codes: "        \"九61.5八.五*.=,.\"6",
    descriptions: "一^3*0@\"64",
  },
  {
    codes: "          六8{&.;1.8'.一七.,}",
    descriptions: "7?\\四五[_",
  },
  {
    codes: "          <&+^.7[.&0.>8./三",
    descriptions: "=5}_2<]",
  },
  {
    codes: "          /二%五.七七.)6.(8.%'",
    descriptions: "38#_>$=\"<3",
  },
  {
    codes: "          %]二?.:$..7.}>.:3",
    descriptions: "=八;四^三_七六_",
  },
  {
    codes: "          十2(四.{4.五五.=?.52",
    descriptions: "*|:8^4;#<*4",
  },
  {
    codes: "          :;九).|3.;5.}>.四>",
    descriptions: "[|四(\"三",
  },
  {
    codes: "          六;>?.五二.3@.^{.0@",
    descriptions: "\\=八4\\]三 ({0<六^6){五&)",
  },
  {
    codes: "          }}]一.5{.8|.,+.%(",
    descriptions: "^9{')?七\"",
  },
  {
    codes: "          五\"(|.*;.+二.02.:*",
    descriptions: "@九^1\"^77$ 2ü>_五(/2三",
  },
  {
    codes: "          五9^\".六{.56.\"/.33",
    descriptions: ":七8十7)二(.{+",
  },
  {
    codes: "          |9七五.._.^:.#..&(",
    descriptions: "^(%二八五六 1+\"",
  },
  {
    codes: "                      3一[六.%九.一[.},.|\\",
    descriptions: "三$2{@)(九\"/&|\")三??^$<22$(\">7十二九五>(四0+[二\\=^7\\)4六\\;]1*\\二])+()",
  },
  {
    codes: "          2@十9.[4.#$.7五.62",
    descriptions: "\\五ğ#二八}<,",
  },
  {
    codes: "  ;$<六.6&.1<.二7.1&",
    descriptions: "#@ğ/.6%>*",
  },
  {
    codes: "/?.九|",
    descriptions: "0五5^(7: 79二 ş七4.#/{> (&_:0七4{%, (<%'8$, 32<五$;, }@$3(八 ^< 4:,}\\({",
  },
  {
    codes: "  <五2^.#?.0<.九\\.四9",
    descriptions: "\"%{十十>, 44*>.26, 二八=00|",
  },
  {
    codes: "            {>九十.83._9.\\6.&5",
    descriptions: "/&&&四0六] ((α)五+}]4:[|^1二/,&[九,</:<:9五五+[)",
  },
  {
    codes: "              &;|<.3$.|?..,.#0",
    descriptions: "九'%'@,\\) ((9/β:八$二{三8七六>|#\",3)_^>:六61]三,)",
  },
  {
    codes: "  _五][.}\\.>\\.40.四[",
    descriptions: "İ$2<六&'",
  },
  {
    codes: "  ;(三二.<五.33.&$.\\二",
    descriptions: "_3ğ,<6七}%",
  },
  {
    codes: "+9.六七",
    descriptions: "57五>8十二5二十十%88",
  },
  {
    codes: "  八;六6.<8",
    descriptions: "8[>七2\"29)/*$ 71 2.44&*188六1 [$@5 二0<ı/ı;>?九十 七ü三,}21二=;&<9'七;ı%",
  },
  {
    codes: "      :1;,.三].)9.,?.+>",
    descriptions: "4{八5{8五>] 0[八';六九三\"'",
  },
  {
    codes: "      ?9=二.九\\.四\".]=._九",
    descriptions: "&=1六=\\{四( 3?9十九%1七+",
  },
  {
    codes: "      *7>五.\\四.2/.49.$}",
    descriptions: "2]:]8九#9+ #,八2,二",
  },
  {
    codes: "      [#@1.6<.':.\\9.8]",
    descriptions: "四5八1'439+)] 五]ğ59 三七六五*一ı",
  },
  {
    codes: "      +((8.\\2.0(.六七.1四",
    descriptions: "*}9|%,+@十 &./一;@{\"#:",
  },
  {
    codes: "      <(*$.十,.@7.|五.五[",
    descriptions: ";.$39/22@ @?+四^7_四+",
  },
  {
    codes: "      六(十0.<(.六#.,>.1[",
    descriptions: "一{,|<,/>] >$?$9>",
  },
  {
    codes: "      $*#}.}|.'/.一*.四\"",
    descriptions: "一%;7[| *($\"+{&六& ?4@@_;",
  },
  {
    codes: "      8>=1.{七.&[.七三.|/",
    descriptions: "723}\\<7^@+&>76_}+3一^@9 八一}九;{0ö;四{5$",
  },
  {
    codes: "      <.7+.%5.[6.1..,[",
    descriptions: "七/ğ}五'二9八",
  },
  {
    codes: "      #四}4._五.7^.)%.1$",
    descriptions: "\"*>%}^一$:9\" _0十十8\\",
  },
  {
    codes: "      >/_].?3.?3.9+.'}",
    descriptions: ")](3@五}?.三8六 1九一十七三三)四1,",
  },
  {
    codes: "      _|\\+._].六^.;7.十7",
    descriptions: "{_*^十8* {@_二九二':三@+6二 3三734@",
  },
  {
    codes: "      5[\\_..].五=.}四.38",
    descriptions: "2\"|/<5^:<30+\\ 九;0''.^",
  },
  {
    codes: "      @七[).四|.</.][.2^",
    descriptions: "+^0)()(三{6164 ,八7,",
  },
  {
    codes: "      四<\"五.>8.}).)=.7;",
    descriptions: "7:ğ]+6^$[",
  },
  {
    codes: "  79九二.|>",
    descriptions: "/8%',000;九\"8_<: 十9 六ü;5$七]]#; 六2/#50ı, *9九;$一ı",
  },
  {
    codes: "    )三?三.:9.'%..].4{",
    descriptions: "%)九六(#\\二,+,%03五,9一^ 四)+\"@4ı, _6一5>?+四一 五| 五%54八[1|四ı",
  },
  {
    codes: "    #_//.=}.%;.*二./<",
    descriptions: "^\"ğ_2),四%",
  },
  {
    codes: "  四>32.二$",
    descriptions: "\\6|&,\"]六?(9@*2 七5 =ü>25:'5\"; 七.5\\七*ı} \\),'<九ı ",
  },
  {
    codes: "    _8'五.七9.五'.1+.四;",
    descriptions: "一8七=5]五8,(]",
  },
  {
    codes: "    九>#$.三八.([.;一.一4",
    descriptions: "|]5七四,7二3(四 |?$",
  },
  {
    codes: "    *^43.&*.)\\.\\0.?#",
    descriptions: "\\@八,十\\2\\*四. 9十:七4[#{'",
  },
  {
    codes: "    =.%'.86.{二.1九.九3",
    descriptions: ">六]07{^(1二[\"*6",
  },
  {
    codes: "    (%+3.九五.7一.)4.1=",
    descriptions: "/=^|}五%2二7三1#八 ^八.",
  },
  {
    codes: "    ?\"*六.>_.一:.>^.07",
    descriptions: ";)}{)>六九8(2]六_:六",
  },
  {
    codes: "    八六%&.[).八,..9.(0",
    descriptions: "_二一八}2二\\二=%#十十一2 二_]",
  },
  {
    codes: "    \"^*二.}#.:五.\"*.@四",
    descriptions: ";%ğ%1{五[一",
  },
  {
    codes: "  }&四_.7,",
    descriptions: "3+\\0八\"^*八:.:七 _八 七ü+1{九三(三; 7)十4|[ı@ 五\\5%七4ı ",
  },
  {
    codes: "    >00(.%,.^二.62.40",
    descriptions: "@[*|?三}九十>']九",
  },
  {
    codes: "    &五@5.]].%8.一%.;+",
    descriptions: "8(#4五八?0$3(_' :\"\\{1,#=",
  },
  {
    codes: "    六三七#.七4..5.%5.'九",
    descriptions: "?1九七&?(<;*1#. 三'[六1九0[96",
  },
  {
    codes: "    $#3\\.@四.|十.八\\.八六",
    descriptions: "五#ğ]:八$1.",
  },
  {
    codes: "  \\\\]一.>九",
    descriptions: "21?\"#>6一*=_: 4< 一ü%;4]}=5; ]五(九&(ı\\ =9:九二%ı ",
  },
  {
    codes: "    )一五&.七,.+\".*九.五6",
    descriptions: ">.一^={*\\%8^]",
  },
  {
    codes: "    @;<<.90.四七.$&..(",
    descriptions: "四+八]*>一+(\\7' #[@<'[2六",
  },
  {
    codes: "    ].'=.__.<'.>2.二@",
    descriptions: "6九)?六八|%@)\"1 &}'^$ 2四9二54$[7",
  },
  {
    codes: "    ^'七三.四十._1.0{.)(",
    descriptions: "+三四=@6[二十_&@ \"七@七59九.",
  },
  {
    codes: "    \\#/5.[0.六+.@:.<}",
    descriptions: ";8ğ*1'\\[+",
  },
  {
    codes: "  八八99.|四",
    descriptions: "八=ğ&|五]?5 ",
  },
  {
    codes: "    二八/,.八=.&八.5九.1\"",
    descriptions: "&0*}四]}17六 %? 一ü,=0@[50;,*5*六*ı# \"\"7,{2ı",
  },
  {
    codes: "    &2五九.8'.六}.五{.#1",
    descriptions: "?99<二#\\<五7 5六 }ü.3七一0<';<<&&>,ı二 五,5.+九ı",
  },
  {
    codes: "    ]&0=.$0.十).)\".五八",
    descriptions: "#.八*1.;< '7 )ü九>|5(%6;|>8+&4ı; 0&76'_ı",
  },
  {
    codes: "    一五0;.2\\.|#._$.\\5",
    descriptions: "{2十一.9%[4{ 七@ 'ü02_七六九,;*9%一{\\ı' *十6[\\)ı",
  },
  {
    codes: "    <四0{.89.&七.'四.\":",
    descriptions: "\\8七]_六#0< 七. :ü>)\"*\\3};六%@*';ı# ^九2&\\7ı",
  },
  {
    codes: "    .{]..2*.73.\\<.|>",
    descriptions: "一,十<*4+> 7} >ü=&二+<三/;3十7十.:ı十 .}6[1,ı",
  },
  {
    codes: "    1六)七.\"7.1&.&+.2;",
    descriptions: "88?(四八一^&} =\" 0ü$=>四@八8;?#六=7\\ı一 三十三4\\2ı",
  },
  {
    codes: "    8;8+.#;.\"五.九+.七.",
    descriptions: "\"十四&>}3十( 3八 1ü5\";:六一&;.2.(五%ı^ _\"7\\三?ı",
  },
  {
    codes: "    0;%^.三7.9十.十十.0}",
    descriptions: "八八_二3/;ü$$/六 .: 3ü$八<.00\\;6七$(:4ı二 #四九|+@ı",
  },
  {
    codes: "    4:八#.@八.]*.>$.;三",
    descriptions: "_',(;;|57五",
  },
  {
    codes: "    ?:\"6.5*.0+.],.\\一",
    descriptions: "1:五)>九{.七; )ü(2$/",
  },
  {
    codes: "    ?$四六.}3.二}.'五.51",
    descriptions: ")&^2.]^>}('五> 八_ğ四八 九ü(十五十$五三;<一?&1+ı4 4({九{五ı",
  },
  {
    codes: "    *八十六.九8.\"=.\\=.}5",
    descriptions: "=九十+七8九.+\"",
  },
  {
    codes: "    */<@.8^.<+.八{.0三",
    descriptions: "五2<;1四/2?七 .六1",
  },
  {
    codes: "    二'>1.7&._六.2].5}",
    descriptions: "五+4(三一八.十<'_[ 8[ğ<五 /ü$九(\\五*);10[67^ı? .6|七6*ı",
  },
  {
    codes: "    七2七0.:..四+.六九.}*",
    descriptions: "<4_六,9九]^:",
  },
  {
    codes: "    >五,8.]五.1四.六3.=6",
    descriptions: "?十?@三|,?七,',& 七ü,*五三_:9;一七/6%;ı0 7}3[{]ı",
  },
  {
    codes: "    /*:六.六(.五六.44..(",
    descriptions: "'1六4|二7(",
  },
  {
    codes: "    '?<5..9.74.>(.+6",
    descriptions: ",三六{#8}二 9'\\=_](三,八",
  },
  {
    codes: "    七+$/.\\十.二十.1$.7+",
    descriptions: "#%9#>*2735 四+一<|一_[六1",
  },
  {
    codes: "    1^?9.%/.九七.,十.1_",
    descriptions: "6#={二,?$0:. ;82五:六#二8&",
  },
  {
    codes: "    6六|_.54.七*.}@.50",
    descriptions: "三+:四九六九%(= <六二五三9二",
  },
  {
    codes: "    $6@三.2\\.3=.2>.((",
    descriptions: "0.ğ43 十5^三%%.;七#(._8* ]\\ 三ü<七八{7.七;(\"(/2%ı5 \"十0:[=ı",
  },
  {
    codes: "    三,八一.:3.*^.\\五./^",
    descriptions: "7+二5$8{_^# ;ü\",\\9",
  },
  {
    codes: "    ^五^'.'..七一.3/.七三",
    descriptions: "5?ğ二]$4$_",
  },
  {
    codes: "4%[>.六一",
    descriptions: "<:ğ5@ 十&\"[6^< 1_\\一ş]|04九 ",
  },
  {
    codes: "  '%$/.^<.\"八.\"4.[2",
    descriptions: "[%五12",
  },
  {
    codes: "  *1#十.=0.|$.|5.三四",
    descriptions: "1>2<6{:/[79{",
  },
  {
    codes: "  _]03.<..4_.三{.\\4",
    descriptions: "\\五ğ四# &{:=2八十三",
  },
  {
    codes: "  ;,+$.:九.(3.&9.*:",
    descriptions: ";[?ı# ;(]{$@,$$/%三",
  },
  {
    codes: "  .}[六.二3.四十.+9.;十",
    descriptions: "<<ğ四1 >/&三[=4 07[3ş|)八{}",
  },
  {
    codes: "6\".五<",
    descriptions: "七_@六四*($; ç+&*^, /?七ı1四八$(ış ^十[ç< #> ş五六一*五*&$\" 5009 [>$<5 十|]_)'",
  },
  {
    codes: "    ,二|三.五十",
    descriptions: "四.%='54 6ç;+ (0ı二%5 ü=ü'6%6 .={44) ",
  },
  {
    codes: "      @二_四.9}.^4.,}.0>",
    descriptions: "{}2五|0*} 8^2@9二5^七ş 七二ğı. ?; 六一$>#",
  },
  {
    codes: "      <4'*.十?.6七.),.+^",
    descriptions: "57:37#?2 @?8| 15<', +{5'四5\":*ş 2|ç0",
  },
  {
    codes: "      ?七0七.='.三八.%{.?>",
    descriptions: "三十七<三 {?^四6八<%3ş 5*ğı\" ?一 ;6=}二;四<7,&:_4 九[ 4#\"/八 \\_^=:__#9ş",
  },
  {
    codes: "      七九{{.2+.70.二<.,2",
    descriptions: "=四ğ1)0_6八",
  },
  {
    codes: "    {]9@.;0",
    descriptions: "一:ğ1十十}:3 ",
  },
  {
    codes: "        }2|一.3一.\").<九.二0",
    descriptions: "2九4]$ @0]十/六7?+ş '4ğı| .+ <80七+",
  },
  {
    codes: "        二+4'.$'.,0.^:.<2",
    descriptions: "#%2\\:#十\\ :^五\\ 六@?8八 一:五_'_?=[ş 65ç}",
  },
  {
    codes: "        +一[^.)=.七^.|3.'(",
    descriptions: "'==\\二|%4 四六[> #99[0 三^,6](@[|ş  }$+/&[[8ış =#3&&一##",
  },
  {
    codes: "      5$=二.<2.(六.{;.'一",
    descriptions: "?1ğ1{.\\[(",
  },
  {
    codes: "  |\"九^.[?",
    descriptions: "\\\\ğ1七 ş7?/:+四]&'$十 九?_&7^六9",
  },
  {
    codes: "    三(),.(2.}二.3*.1{",
    descriptions: "İ#4= *%9ç7, ]);, #[七6ü3 *5'1 ?.九 #一),/[1 \"]8%^四?",
  },
  {
    codes: "      7三四\".四二.3三.&_.2&",
    descriptions: ":}*9 6/+2/7 4' {>十(^一% 二3[1ş=] ''%>:@%4",
  },
  {
    codes: "        =1$9.{[.$:.六九.(/",
    descriptions: "&ı*ı \\六(四%三三.",
  },
  {
    codes: "        三七33.八}.'|.7/.\\三",
    descriptions: ">]ğ七$七2<\"",
  },
  {
    codes: "  \\3[8.5|.5二.八5.<一",
    descriptions: "\\>四^ 四ı8<@'9一ı@_ >$六<#1 /ı一ı =:9( 4\\九4 9)+&<(( =4 9三';九:%>>",
  },
  {
    codes: "  84.5%",
    descriptions: "4ü{43]八二\\_ $5?51\\( ]十^'#9.2 (;九%^七]]十 }五/$ç); ;ü?5一%,八八& )ü九'四八",
  },
  {
    codes: "    ^二09.{二.\\四.二:.**",
    descriptions: "二)4_89 (\\><九 三一_6二七 ,ü{\"_${十 &[_*五'# [七 }+0一七?ı",
  },
  {
    codes: "    #四8&.七4",
    descriptions: "{[ğ三\\1'八四 ",
  },
  {
    codes: "      |:十0.八|.:0.\"$.<}",
    descriptions: "{ğı三5ı十 @{4\"五<545, % 四, ,|<7 二.%[ *92\\八 3一>六8 % 31'_\\九 一&':6 %=8(35)",
  },
  {
    codes: "        <九九{.=;.\"].69.5*",
    descriptions: "[ü7二1]'%'$0%\\+@ @6 ]ü十7{)>五八]]_7",
  },
  {
    codes: "          @4.五.\\1.;\".一五.7三",
    descriptions: ".七+?.六五 @@5八;= ;ü九;}30? |><\"",
  },
  {
    codes: "          \"^\"*.一>.\\?.3六.二三",
    descriptions: "九3ğ3)]二=%",
  },
  {
    codes: "    06*5.42.三8.\\).0+",
    descriptions: "<;九$五5#{ 四十*七]^)",
  },
  {
    codes: "    ^},&.9八.8:.九5.>六",
    descriptions: "İ三@|五五 9(*@一6}四_*",
  },
  {
    codes: "    5*四@.]/",
    descriptions: "七$ğ四五4(二= ",
  },
  {
    codes: "      2:8,.9$._4.54./=",
    descriptions: ")ü{( 六ı.二ı8ı八ı ;8ğı",
  },
  {
    codes: "      5_*\".7*.+<.三|.32",
    descriptions: "一十ğ九8}1<;",
  },
  {
    codes: "  *,>..:二",
    descriptions: "_@&,|34>四 0'二ı|九.;+ 一/十八 $六(九+&9.六ş 二ü60?八一32;%)",
  },
  {
    codes: "      一一十(.^二.>6.\"9.=十",
    descriptions: "&'@)= (ç,:#;[_0",
  },
  {
    codes: "      3\"+\\.:九.@].]/.四8",
    descriptions: "][=#\\ 7ç_%七4_:0}*三",
  },
  {
    codes: "      /70}.*6..九.三6.六@",
    descriptions: "@2二]> }ç82<|三;\"",
  },
  {
    codes: "      }>%五.,|.'\\.1\".9(",
    descriptions: "$&%十6 一ç_9/:6\"$+九四",
  },
  {
    codes: "  五:_..\\八",
    descriptions: "#4ğ$\\3?二七",
  },
  {
    codes: "      三.\\^.二@.二0.二?.+{",
    descriptions: "/4&1> |ç*(&9九一1",
  },
  {
    codes: "        )'2{.\"%.八}.九7.\\=",
    descriptions: "%3: 一2( %_# /]@十ı(二[ 2ü2十七 六76:十 \"0/>= 十ç':.3;/4",
  },
  {
    codes: "        @0:;.八:.九八.,二.{$",
    descriptions: "八一ğ|34<三九",
  },
  {
    codes: "      {.五八.4四.8=.[<.十2",
    descriptions: "9<1>: +ç'*九6四七=",
  },
  {
    codes: "      _十4[.9=.>}.{十.,]",
    descriptions: "^六八0& \"ç6^[9:9$'26",
  },
  {
    codes: "&5.一5",
    descriptions: ";=ğ\"\\?& \\ü:6*五3四8]五八ı ('9六(ı 一7ğ %0四.; <5一83$ _(9?九][\\ ^=ğ@[五<1{ ^,/._[",
  },
  {
    codes: "    +>{\".{^.六8.8[.十/",
    descriptions: "'&^{(_2< 8三'1>;ş六+ ]\"七十七*六$八2, ?9[{ )& %ö(){78八%, 90$五 =)",
  },
  {
    codes: "    {#^)./]",
    descriptions: "(3ğ,2;一二^",
  },
  {
    codes: "      21<五.:\".'1.#;.十|",
    descriptions: "9@$( 四\\?98 四九>:85 2\\&四? ^三#247九[九 ş]一|ı[^^, )ğı\"+ı> 五]6@@]=,八9 % 81",
  },
  {
    codes: "      3%2/.四^.5#.+九.3\"",
    descriptions: "十?十\\; 一=\"1|四 ,] '* %1一 }十1) 8五,+#*| [}^?6) ?ç=三^ğ_:8 3\\}'> 五? %9 一( %18'[ 一|[+七",
  },
  {
    codes: "      9/@(.$:.一>.=/.九四",
    descriptions: "#\\ğ/5(|<三",
  },
  {
    codes: "    #{0+.六+.十4.一#.=\"",
    descriptions: "{''+一/(| (@]:@四ş;* 五四六九%&;69), '五@& >. |ö1}:}+三二, 四>'= 46",
  },
  {
    codes: "    三']4.'?.,8.:<.七十",
    descriptions: "17ğ(,]*%$",
  },
  {
    codes: "85._&",
    descriptions: "+三%> 八二_%,_ 一, 'ü二<()八[八 )1\"|九]",
  },
  {
    codes: "  3{:3.::.'?.十六.,<",
    descriptions: "^&7四(\"七五.:]三*3?)(五']6,七七{9[ #%}\\+;)}五{ }:{/ '}85882;:",
  },
  {
    codes: "  1四)9.六2",
    descriptions: "(8ğ+...五{",
  },
  {
    codes: "      8十:=.1%.1'.二一.*二",
    descriptions: ":三$一 .+五三\\;ı8 ,^:ışı9ı四@(# *八}ş一* +ü8|/十二?六 &@={_0",
  },
  {
    codes: "      |:)(.七\\.一$.:@.$十",
    descriptions: "%四ğ\\6+^五十",
  },
  {
    codes: "    三^十7.(八.八七.7一.+二",
    descriptions: "六七ğ一<:{<4",
  },
  {
    codes: "七1.08",
    descriptions: "&}{_6七十ı, 4]'}[*), <öş\"[二, 2${@|;]; 0,;\"十_64, ?8@ &?9{ {9|\"]",
  },
  {
    codes: "    )8+5.]一",
    descriptions: "'四{0<)|ı, 9;三_ 八* }ö九(&?/824( 8:三八|九ı3+^ =08@, =@8? ^七|[ 04八",
  },
  {
    codes: "    五+#一.5三.?=.2五.*'",
    descriptions: ">8}70五_ı 一&2三 ^| ;$0:_[四ı",
  },
  {
    codes: "    \\59..八[.3_.%..]八",
    descriptions: "#0({ &$_+ 七5 十=三.8%$ı",
  },
  {
    codes: "    +?*).&7.八8.,八.:&",
    descriptions: "':ğ$^49'一",
  },
  {
    codes: "    8八十*.//.三八.=0.[)",
    descriptions: "3(十|\"}9, 八öş)&五 >.9% +{ğ八九 (ğ3ç |ş;3九:*一6 十3:ı七ı{^} '$(4八八ı",
  },
  {
    codes: "  三^%*.九六.三#.四;.一8",
    descriptions: ".32&=](十 \"7$&5$9@14\"六:四 七二十?@6ı_*四 \"十+? |6 十6.九5&2 @ü.&[\\*\"三@|9",
  },
  {
    codes: "  2六^\".)/.七1.+&.{<",
    descriptions: "/|5)>0*,8 00\\ }? ]九,%>'ı */ +四ğ二? 9'四九4九八\\1 8ü75\"/)6@^%五ı",
  },
  {
    codes: "  }55八.\\?",
    descriptions: ",1ğ十&/?十#",
  },
  {
    codes: "    ^.8/.]/.四七.>/.1%",
    descriptions: "一五''_ }%=:六7}ı",
  },
  {
    codes: "    '@7}.[\\.九&.三[.,%",
    descriptions: "五\"ğ6>九\\./",
  },
  {
    codes: "\\@8,.&(",
    descriptions: "0şı% ]0+],1 ?ç九' 5=(=四\\ı9_五 0,> ^ü八'ü 8四#十0七 \"一 [@\\>2[$)/>",
  },
  {
    codes: "  七$[\".49.=*./八.?\\",
    descriptions: "3七'6四%[(5\"? 6<&5$.",
  },
  {
    codes: "  %二三'.<}.\"(.$:.4$",
    descriptions: "5+ğ2九_五7*",
  },
  {
    codes: ">+|>.]九",
    descriptions: "二6>/> +十十,?=ı (ç%二40^}十ı七 6ğ>84$5四# ,ç@八 四8+ı1@]|++ 六>56五; /(二六7);",
  },
  {
    codes: "  /='|.八@.5一.一:./5",
    descriptions: "$/şç六2六#二] ,2&++*ı9}: [6çı 2九7#6ı 一6ğ六. ;ü:^一\"八+9.@二",
  },
  {
    codes: " ",
    descriptions: " - {:ğ七*4^=九",
  },
  {
    codes: "    ?09三.;@.六,.一5.8五",
    descriptions: "?'şç一六5三 _6十三",
  },
  {
    codes: "    ')七5.八)._九.^三.四}",
    descriptions: "44|二= 四+一3+\"_ '@^{1\" .0,二五;",
  },
  {
    codes: "    <[}7..>.(?.^$.三5",
    descriptions: "9.|$*十五7> .七:9|ı ö2çü &(0<,6}八} (4,şç#\"5六二一 五4[一=\"ı<8七)",
  },
  {
    codes: "      [2二;.<#.8二.,4.&6",
    descriptions: "Ç303: \\++3[ö^三?4. #?00?ı ö六çü 七五十0,?%5[ (=#şç,6一:'> 七('\\四[ı'&$)",
  },
  {
    codes: "    *7四9.1|.4].六3.一=",
    descriptions: "5九ğ\\#/,<'",
  },
  {
    codes: "8$.;7",
    descriptions: "00+\"%, ç|5 *[二* :五4\\#八七* ç}%ı \\ü\"8.\\十ı, 九&]4= <: 七{4@,]59七6}1,",
  },
  {
    codes: "    =08'.=*",
    descriptions: ";ü]2六@, 九四,;0 8, {')八\\六)8一@六'",
  },
  {
    codes: "      四四=\\.&\\.(=.三).八一",
    descriptions: "八)^ 4六7{;#:",
  },
  {
    codes: "      _(%八.,七.,{.4#.三#",
    descriptions: "[\\ğ0;/=++",
  },
  {
    codes: "      三'7&.六{",
    descriptions: "一六6五ı 1ü6二九六, 8]'0) 八$ 9二7=八五>|# [=+三 32二9三 八^:| ,ü@'七>",
  },
  {
    codes: "        +<二\\.28.@二.%$.八^",
    descriptions: "7四>ı [ü十 5二ğı, >ü_ {5六{%/>((%0, (^7二#66), \\[/)十5八5=, .|ş5二<+ @1@四",
  },
  {
    codes: "        @:'6.五6.[#.:3.:]",
    descriptions: "22ğ}^&八<>",
  },
  {
    codes: "        十'/;.@4.+,.}^.}_",
    descriptions: "[{#ı [ü& 53ğı, ,ü* [=)57%'%六^+, /%1.=+{>, \\.3(]2十6@, 9\"ş六|一{ ;9/=",
  },
  {
    codes: "        =_.1..&.$].@;.]*",
    descriptions: "7八ğ九?三*.9",
  },
  {
    codes: "  二%一).0=",
    descriptions: "Ç|% )四 $7:+#70[ ç:3ı }ü2_^#4ı, \",:_} $1 8<&二|四'1.4十5@ 7'",
  },
  {
    codes: "      '6=&._一.\"3.\\).[}",
    descriptions: "Ç_八 (ü六__一@ı",
  },
  {
    codes: "      :二|6./=.{三.8/.*8",
    descriptions: "{>ğ十<二7'{",
  },
  {
    codes: "      一);..\\/._\\.四;.二=",
    descriptions: "89@&ı ç6十 三= (四,é  二ü/四_三1ı, )4}六[ı ,五 ){八;0|5二])_ 7$(1@7,",
  },
  {
    codes: "      #\\,}.(\\.六}.\\\".二;",
    descriptions: "2>ğ|';&'=",
  },
  {
    codes: "  2\\=二.:?",
    descriptions: "?九3.{.六:ş 七5+八\\|8 1二 >;二+?^'6ş ^8#^三 ?九[=&9 ]#5\"_9ı$;三",
  },
  {
    codes: "      &\"5+.)九.#六.一/._5",
    descriptions: "}九7@2>$二ş :4$&0@=",
  },
  {
    codes: "      _;</.#6.%1.@].)4",
    descriptions: "五1ğ+0$=]2",
  },
  {
    codes: "      .一'@.\"2.\"$.|0._#",
    descriptions: "5]^05$04ş 8九九(9281五{ 5=[0 9$八二[}*1:",
  },
  {
    codes: "      七<3>.一#.)/.|4.[6",
    descriptions: "\"[ğ五\"7>'{",
  },
  {
    codes: "  =五.六6",
    descriptions: "三4#三#]% (五;0二ı 十0&' 9'8,ı[); 8+\";ı# 四6ğ三^ =\"; {ü&九^六> 三4,<>",
  },
  {
    codes: "  $?$五.>7",
    descriptions: "三4*8ı =9<二三%九",
  },
  {
    codes: "    [二$[.:6.四].九(.\"四",
    descriptions: ".ü(八ü二 $>]八;2五ı",
  },
  {
    codes: "      (+五_.;).%|.二7.<<",
    descriptions: "#六]# $]四/",
  },
  {
    codes: "      ?+6@.)].九1.&7.'6",
    descriptions: "}1ğ三8*$=}",
  },
  {
    codes: "      \"')7.<@._#.1>.$九",
    descriptions: "#,'* __|5五ı",
  },
  {
    codes: "      一({$.,?.六|.,(.^|",
    descriptions: "$@ğ[六二五/0",
  },
  {
    codes: "  |=3).四<",
    descriptions: "[\\(十ı七 _<0<0(2; 9八63ı2 2[ğ{^ ?\"8 /ü八九=九& 八37[三]4^|8六1*8三%)",
  },
  {
    codes: "        0){3.14.$}.四三.8三",
    descriptions: "}1九/ \\一六;:ı",
  },
  {
    codes: "        十?/_.七7.^7.0@.9七",
    descriptions: "二'ğ4}]2三1",
  },
  {
    codes: "        七+九一.80.5;.\\四.八@",
    descriptions: "7\"2> <19#&ı",
  },
  {
    codes: "        1*9九.'六.八$.9&.:+",
    descriptions: ".*ğ:@%942",
  },
  {
    codes: "      ?$<十.5二.##.8<.*7",
    descriptions: "':5 @ü五<)8八 ;55九1十",
  },
  {
    codes: "      0%16.%>.7六.三?..]",
    descriptions: ".\"ğ((=?%(",
  },
  {
    codes: "  ,&<$..'.+>.;;.五8",
    descriptions: "*一>ı七>&13ış ?34?55%1 1?0@9;ı",
  },
  {
    codes: "0..*:",
    descriptions: "^四].8< >8 >ü三*15=)八#\"1ı; ç;ş,2 一) 33:3五8 *九%3一> 二0*ışı4\",[;",
  },
  {
    codes: "  %$'三.6#.14.\"3.,$",
    descriptions: "八&'5 &@\\(",
  },
  {
    codes: "  $4>3.14.五..八3.&\"",
    descriptions: "(4=;8#五 __1ç^/ı :$ }>ğ<) ,5'#八@% #/}&=/ı",
  },
  {
    codes: "  九+/4.>三",
    descriptions: "{'<六@] \"3^ 十< +<|二 .]' 十* 一0{ı八 #3八&8&",
  },
  {
    codes: "      |四七{.+=.>3.(五.$5",
    descriptions: "一@[ *ğı0二ığı # +4 4[四2 十18< (二 0*十|>$十",
  },
  {
    codes: "      七*6)..八.6#.+0.:}",
    descriptions: "6二/ 'ğı六\"ığı 9 一3. \\.? ^|+9| %^;二\\()",
  },
  {
    codes: "    <5二+.4].8_.4\".5[",
    descriptions: "七9'ı七 )2/[七7",
  },
  {
    codes: "  +1{^.&四",
    descriptions: "/|ğ]}^@8五",
  },
  {
    codes: "    ]七3'.八七.(\\.@@.%1",
    descriptions: ";;@[七 ç\"2$一{\\ (7ı七ı 九=.8[)",
  },
  {
    codes: "    3十@&.{'.八%.{9.,一",
    descriptions: "四八/7] 9六1一\":>5一0 '一3]>  2+$\\一&/[ % 2{,& 3>*0 +五(3 \"十;2; 7+(]/ % |1,.'{七",
  },
  {
    codes: "      |.{'.{1.七[.#(.*四",
    descriptions: "一二ı _&_二7 *)>八",
  },
  {
    codes: "      $\"二&.十(.?_.(..]:",
    descriptions: "Ç4<}|",
  },
  {
    codes: "      ;二(,.0#.+6.1).@/ ",
    descriptions: "%四>五2>\\ \"7三:",
  },
  {
    codes: "      /3(,.)_.92.*6.三@",
    descriptions: "43ğ\\,%;@六",
  },
  {
    codes: "  ,五.3#",
    descriptions: "Ç.+1四_7一, 95 \"|46)ı 4三 1ü@\\>){25八[]ı; (3:ışı5 ?0>><8八_% ^9一7",
  },
  {
    codes: "  [\\6=.[,",
    descriptions: "Ç<七#,{}], ,2 1_4,@ı \\@ 八ü八#/?三;:十四.ı ",
  },
  {
    codes: "    十[\"^.十$.0%.^<.^三",
    descriptions: "5. 1{一| #959;_&,{/",
  },
  {
    codes: "    1/;&.3(.30.\"].(7",
    descriptions: "Ç#>:2\"7:",
  },
  {
    codes: "    %>%#.\\0.>5.4,.,六",
    descriptions: "]2ğ?\"&8)%",
  },
  {
    codes: "  )九七7.$八.五一.4+.+<",
    descriptions: "'<_ışı六 4.%二:四|&^ %&*93五\"二47 \\ı)九 )ü十}八2#4[>八,ı",
  },
  {
    codes: "]2/>.]八",
    descriptions: "/6+=.?九# \"三 >{]_(#4 ;八ğ九五 {701>/ (69|/: =ç0八%}5 #ç@六<%90四)",
  },
  {
    codes: "    @#00..8.5八.\"(.#^",
    descriptions: "\"(,'= }ç八<#;,/6",
  },
  {
    codes: "    九0九0.;七.(一.3<.>(",
    descriptions: "$7ğ5@#54六",
  },
  {
    codes: "      \"\\4:.6{.07.#十.>五",
    descriptions: "#九8二> 1ç8{.4%五七",
  },
  {
    codes: "      8*{..9%.,@.?5.:七",
    descriptions: "5@ğ一0五&1十",
  },
  {
    codes: "      {'四三.1<.5二.07.;?",
    descriptions: ",%62\" [ç&;*8;37",
  },
  {
    codes: "      一九[{.三六.六%.]<.6四",
    descriptions: "04ğ]_<七*/",
  },
  {
    codes: "{_.9五",
    descriptions: "|9=22{五(2 四6ş'> 七4九935八 @*49\\3,九#:&^[ \"3%, .{_ =()八九_? 九ı]]",
  },
  {
    codes: "  ]292.%6",
    descriptions: "九*5']八{ :)4>8:二十6;三.[ |* 二=1+7六* 五ü八9一十2 [七九.8二9|",
  },
  {
    codes: "      5[?2.十#.6]._^.$4",
    descriptions: "&6^1\\ 44.,26#ı",
  },
  {
    codes: "      )&87.三@.%一.&3.<{",
    descriptions: "^\\ğ十'25_>",
  },
  {
    codes: "      七/十7.?'.\"_.&&.9.",
    descriptions: "=%八八. @/)九^五二ı",
  },
  {
    codes: "      >十<*.+\".'[.@&._&",
    descriptions: "<(ğ3九45@三",
  },
  {
    codes: "  一.\"].+7",
    descriptions: "_/ğ7七$九]{",
  },
  {
    codes: "      }{九七.=十.\\}.7:.\"二",
    descriptions: ")三=八ş3五 '0[五| 四ü.\"[,%{}}九+ı (:ç;2 七:二{+/+4 ),*&%*ı六四| ?@|? :%7二= :$八\\九",
  },
  {
    codes: "      0九六^.%五._/.#六.@=",
    descriptions: "İ/,\\0=6'% ş\\@}(400ı",
  },
  {
    codes: "        <@]@.75.十2.八二.<{",
    descriptions: "83\"九2% ş四*#三#",
  },
  {
    codes: "        >6.九.5\".$=.\\=.:+",
    descriptions: "\">}91六 ş4#一{: '= 七9_99 十5($\\.十} ş>#一8_",
  },
  {
    codes: "        ??=6.一'.八,.5七.|六",
    descriptions: "*#ğ?1<=[@",
  },
  {
    codes: "      17?_..*.>}.][.*&",
    descriptions: "8四九ı 'ü% ,=ğı, 3@七*.\\八0, |2八00+3&?, }$ş14^8 ;[5$ \"#?]\"@ *ç三>=_2三6",
  },
  {
    codes: " ",
    descriptions: " - - - 5*ğ];三七六4 ",
  },
  {
    codes: "        六%十七.1七.八八.六..六\"",
    descriptions: "三ö+六三=&",
  },
  {
    codes: "        /@0二..).6九.五5.三9",
    descriptions: "*.ü@一5?ö]+56",
  },
  {
    codes: "        @}={.7八.{@.?1.十}",
    descriptions: "/?{7( 6%三[6%八ı",
  },
  {
    codes: "        3@'4._三.9三.@?.|3",
    descriptions: "\\=ğ一@8=#&",
  },
  {
    codes: "_13六.**.|\\.$>.四7",
    descriptions: "Ç|]十[/九,9 ,26_.十ş#| ]'41 \"ö2*ğ九 52+^&_0ı",
  },
  {
    codes: "8|=,.十%.)?.0$.+2",
    descriptions: ").1 _|(. (&ü#ü二/[九[ş)",
  },
  {
    codes: "6%.六八",
    descriptions: "İ5?) 四ö8ü[一ü=6+# (ç[8${#|\"5 &(^=+3ş8\\ 八}16;)+ 2|)2+三4, [[6|\"",
  },
  {
    codes: "  五]=$.五'.六+.%=..^",
    descriptions: ";}/;2 }一1<))七\"$ :^三# 4四}%_4 .ö三ü十|ü.三\"",
  },
  {
    codes: "  (8_^.:四.0\\.\\6.=五",
    descriptions: "四?ğ|/ 3ö:ü\"^ü26]",
  },
  {
    codes: "4^)).六'",
    descriptions: "İ一二[ <|]二ğ五 (@?9三 _ö_ü='ü,四十+715一 6#}] ;6四+:' =7:59八== +=#|ç)",
  },
  {
    codes: " ",
    descriptions: " - 7ğ*}=ı六((<ış, {五?八[7|三^&ş ;[$: 'ğ&九三ı:6ış ",
  },
  {
    codes: "    :(八;._0.'{.+$._二",
    descriptions: "?4九{30[ 0(30四%# 3:七%=66",
  },
  {
    codes: "    >\\2五.四[.|六.%/.;/",
    descriptions: "'_ğ^6>9四\"",
  },
  {
    codes: " ",
    descriptions: " - 三五ğ>2%+三四 ",
  },
  {
    codes: "    9/1三.69.六8.>;.5>",
    descriptions: "3一9.@*} 三)(=?)2 \\(:四4/)",
  },
  {
    codes: "    二2:?.+3.22.#[.14",
    descriptions: "'<ğ三四\\>)_",
  },
  {
    codes: "六7._.十三",
    descriptions: "İ({* 8ö]ü?{ü>]&0{十2] 2[28 _%'_\\_ 5+\"七8^:/ (六一八一<六(84 6;^ı十76{9",
  },
  {
    codes: "    8?/#.10.'九.\\=.*:",
    descriptions: "2\\九>^三{ 七5六)九\"= \\*\"六,+五",
  },
  {
    codes: "    |75\\.五7.={.@八.:<",
    descriptions: ".九ğ+6九九七:",
  },
  {
    codes: "    (>33.?(.{{.九4.五八",
    descriptions: "=(六四六十? ];_|\"6六 _,]%四}{",
  },
  {
    codes: "    &<=二.@#.;6.#8.\")",
    descriptions: "六8ğ8(三>*1",
  },
  {
    codes: "  七<?5.$<",
    descriptions: "İ4;+ @]^1ğ3 {. )六_* .ö2ü8<ü#;997二?) 5九85 0四+?*; 3ü>ü75üş +三'#@十7/ (^9[\",58",
  },
  {
    codes: "    }$81.%_.+*.?\".(+",
    descriptions: "\\七& )2\\0 (@8[ğ九",
  },
  {
    codes: "    >52>.^[.六9./七.十三",
    descriptions: "\\/ğ%9@#^|",
  },
  {
    codes: "    九^''.{3.5九.九}.:%",
    descriptions: "八6=%五八",
  },
  {
    codes: "      ^7]5.九七.|八.四).1三",
    descriptions: "Ş6四  一*}二ğ=",
  },
  {
    codes: "      |56|.'9.(&.#七.|%",
    descriptions: "20%}/ 五二.2ğ_",
  },
  {
    codes: "=).六_",
    descriptions: "İ四<\\ #.4十 0四80 >ö}ü%^ü@_^一#67$ ?二|2$#{ş $三$$}|六0",
  },
  {
    codes: "  三四8{.[)",
    descriptions: "*0+%:3}% %7@&:&)四",
  },
  {
    codes: "    八'%..1;.)<.12.#?",
    descriptions: "八, \\%0?/,四,}ı*4< 3{)*1一)@;二",
  },
  {
    codes: "    ^(十..>{.十三.{^.{<",
    descriptions: ":3ğ9九*十%三",
  },
  {
    codes: "  }9@#.;四",
    descriptions: "&五ğ(^ ;<}六>[&= (]ğı/.ı) =[%%}*十六=5 % 二六 {一@@ (9*九 )99|: }#37",
  },
  {
    codes: "      三9[二.?#.*:.$六.})",
    descriptions: "(ğ3\"^ı8$3一ış, /三}54=:|%8ş )|9三 ]ğ673ı?*ış",
  },
  {
    codes: "      6'=六.一(.(三.'一.]7",
    descriptions: ")九ğ;=\"\"9一",
  },
  {
    codes: "      ,+三3.]3.一七.)'.%:",
    descriptions: "3一_ 五$七ğı (@ü3 .十0(#八四ş) (+ğ('5ı^>六9ış $[八六 十{}{四/五|@七^]",
  },
  {
    codes: "        ][=7.^五.五(.),.$\\",
    descriptions: "六八8 8/6ğı (*ü三 ^{]?六=/ş)",
  },
  {
    codes: "        0/73.五'.:1.)_.\"*",
    descriptions: "一]ğ]?#9].",
  },
  {
    codes: "      七2(=.十\".39.6三.49",
    descriptions: "]@九{>_ $四三@5十六ş",
  },
  {
    codes: "        {@\"1.90.十=.九^.={",
    descriptions: "九ğ}&&ı三二二3ış, 十*八四?九]{<=ş ]>@4 %ğ七]三ı*}ış",
  },
  {
    codes: "        7=八:.3三.}4.&}.七八",
    descriptions: "]=2:4:ış",
  },
  {
    codes: "          4#九\\.$4.三1.六{.<{",
    descriptions: "三十@?ş)@ğ@ 二$ :三. 1' 2_ç5\" <二24( ?九 1?. 7八 六五ç'七七'6(|+",
  },
  {
    codes: "          2,九一.*3.八&.#三.>1",
    descriptions: "四四ğ六五.九#$",
  },
  {
    codes: "        3>5,.四5.:五.8(.35",
    descriptions: ".\\;.ı|ı",
  },
  {
    codes: "  (_6#.八5",
    descriptions: "45ğ86 =\"_9_6[五 ",
  },
  {
    codes: "    (十\\$.八五.9}.七<.'&",
    descriptions: "_ğ一<&ı\"五=5ış, 九$二/|2%二#|ş 9六_' ;ğ九十_ı$九ış",
  },
  {
    codes: "    7_,{.?0.>\".四..)五",
    descriptions: "?4六|<|ış",
  },
  {
    codes: "    05十+.|[.:(.;}.3:",
    descriptions: "$75$>ı \\.十二[480 八7\"一*4\"&1六6",
  },
  {
    codes: "    >'=二.八_.)1.92.2\\",
    descriptions: ">七八2ı四ı",
  },
  {
    codes: "}@.@_",
    descriptions: "九(\"$\\ )},六[* (4ş'七%.\\\\&ş ?<\\\" /<;}6 <>1 ş\".三七&? *\\8ı,\"'%$ış);",
  },
  {
    codes: "  2六}@.>5.六]..'.'\"",
    descriptions: ";,49. 0@\\/#3 (7ş<6[039^ş 8/<\\ 3]?@九 .?五 ş('5(9[ (九9ı.三?4八ış)",
  },
  {
    codes: "  %三/&.0>.*八.三].3七",
    descriptions: "七0ğ/2?/4;",
  },
  {
    codes: "<}|(.&7.??.)..=|",
    descriptions: "?@七7: ^?=}四*, 2ış ;0\",ğ| ?:ı@^ış 九#一/ {八[(九十 三<四_ ş3;一十 2)<4七9]ş",
  },
  {
    codes: "6^.?6",
    descriptions: "+}五%= 九.三+0三十0* <ş7\\",
  },
  {
    codes: "  \"%48.八$",
    descriptions: "0ı]&4_= ?二 )ı}7ç\"二> ",
  },
  {
    codes: "    \\'6}.=,.]四.\\%.&=",
    descriptions: "/99十|+<9八| ,*956|八",
  },
  {
    codes: "    #_79.#三.#^.十七.七+",
    descriptions: "$\\ğ五六七一九>",
  },
  {
    codes: "  (])$.6].+].\"八.;#",
    descriptions: "%三ğ'{{?;一",
  },
  {
    codes: "03.^=",
    descriptions: "(/&'^四<二 五\\1一7= (76ğ3)}ı|ı 7@| *<\"+五 :ç\\1}.< 七ç,58九9|[) (四",
  },
  {
    codes: "  ^]六|.\\;",
    descriptions: "+'?0, .0+一), 6#\\一)< /% ş})五:#57; 9八% ş:*4五$八一五 ,\\'八'八*; 6ü2ü十",
  },
  {
    codes: "        ],)'.8,.4四.\\二.0(",
    descriptions: "^ö)ü七%ü ş7>.=/*6 &ç^四 五4?<\\0? (6ı,,ı,九$_1ı< :}.9\\0ı 2[(四'/^}1 *_9}",
  },
  {
    codes: "      >七_.._?.+}.|@.&#",
    descriptions: "/{ğ一1(/<&",
  },
  {
    codes: "      )/1^.{5.八七.0八.,6",
    descriptions: "1=ğ?(5ı$ı <'二一7 9ç七:七五#4二",
  },
  {
    codes: "        34{'.9<.%八.:{.八]",
    descriptions: "+三ç[ı 五ı5六 #七%&九89{ :87四>.ı@85 /<\\<_三一",
  },
  {
    codes: "        九.六>.<\".@6./$._:",
    descriptions: "3>十 7_\"0=八#+ 2?.++* (96五七\\34,'5九五|八56,十九492九'\\6,\"ü\" ç<(八> ;+",
  },
  {
    codes: " ",
    descriptions: "          三九ğ八{ 7四6七7]3 ş6(\\七#_+,*)",
  },
  {
    codes: "        9九四十.九_.5;.2,.$1",
    descriptions: "%6ğ二三9_/1",
  },
  {
    codes: "  }#\":._=",
    descriptions: "二@ğ四[#_.[ ",
  },
  {
    codes: "    1@'@.%一.九十.8?./\\",
    descriptions: "四ı七{}5_ ,九 7ı}]ç#三八",
  },
  {
    codes: "    _@=}.41.;九.二$.二二",
    descriptions: "六十ğ7?+1<?",
  },
  {
    codes: "\"_.=/",
    descriptions: "00二三= ?七?8七@[十一十 三+ 一*59.三<.ş8[\\[978ş 8#,'一 [{%564{六#八 (:\".}?\\6\\ş",
  },
  {
    codes: "    1{@_.)*.一8.九0./{",
    descriptions: "}\\.,@?\\: 七={2@%二{ş",
  },
  {
    codes: "    十,}$.]一.[_.@'.&三",
    descriptions: "=一十190#, ?1九7六,ş",
  },
  {
    codes: "  )|?8.一9.+:.一6.\"4",
    descriptions: "*\\\\6@七>$ş 四五;_七 />)\\4)二?}",
  },
  {
    codes: "8|[^.5<.十5.+*._'",
    descriptions: "$九四@?3>@ 79四3一2\"_}^ 75 _8十#十\"85ş:#9%三三\"ş 3二6}四_/. 七六\\)三&91九9",
  },
  {
    codes: "<二五].>&.2一..^.2\\",
    descriptions: "十)^ı] ,九{二三九一][3 89 &%四八>七%八ş2]&:|一\\ş |$@ı五 6,8*89$%.一",
  },
  {
    codes: "三:二).二9.)2.\\*.三'",
    descriptions: "十;}8_ |$;2四@7三#1 九2 @六$*,[五1ş)7六^$/[ş ,]>9八 3)4六2<\":22",
  },
  {
    codes: "@\"&0.三五.+8./(.?|",
    descriptions: ">=%2?\" 三二)?,:]六:@ @十 =一5;]三<九ş十4\\二)_>ş 2>.九8九 \\+<^8三\\十;2",
  },
  {
    codes: "*}^九.@四.||.九/.,>",
    descriptions: "*五二六39#二> _四$<&./十3^ 四3 (9[&}^^六ş>&}1四(\"ş 二8六%\\九_%_ 954六,#.三\"'",
  },
  {
    codes: "1}.十.\\&.4七.,5.三<",
    descriptions: "%@&ş_2 >1十5&37=}, *\" ;]|九三4^^ş八二3<%4:ş {一:三[9 _8>&九6?三九0",
  },
  {
    codes: "]7<6.#0.1?.|$.六十",
    descriptions: "Ç九:四7 ,十>(\\$.|}& &* '4$5三6=:ş[6=$9;十ş ç#};0 #十]72'#三;|",
  },
  {
    codes: "#三,$.@^.10.\"'.:0",
    descriptions: "五;3六_ +*?>'#;[,1 2九 九5&]?7六;ş3\"?(|一#ş 5\\=十) 七3十2@\\_\"@^",
  },
  {
    codes: ":*(|.(七.|&.2:.%*",
    descriptions: "94/< >>4):#?=2\" ([ \"七,=.^\"+ş;&+\"0\"%ş 八五_| }$;(>)<&]}",
  },
  {
    codes: ".{{<.6+.8).四?.十.",
    descriptions: "3八%5八4$1 ).[#四/>1五9 九< 6<五]八+'^ş}4{%,,(ş ,&3四0\"\"七 |><[$3%,\\_",
  },
  {
    codes: "2[.<&",
    descriptions: "\\:/五六]6 一*\\: $八一5>| )1\"'一4一五'三,7,*;二3三<ş|九#3六#[ş ;'&,^1( ]]",
  },
  {
    codes: "  十+三5.九十",
    descriptions: "8[:'_十@ 0*#<1^五{|> 二[ 七{)=]%十9ş$_+&六0六ş 四9.八#<* 3?]六5\"0,|_",
  },
  {
    codes: "    #:?{.}=.0四.*三.5(",
    descriptions: "İç*.6> 一ğı$}ı: ,3<39{1$6) % 1'/1& (3,#_ 七六]?2一^ *<%;@四: 2:一'<四4",
  },
  {
    codes: "    2==\".7'.6*.,四.@3",
    descriptions: "..ğ<_##九(",
  },
  {
    codes: "  二}]0.\"九",
    descriptions: "]\"六三二[ 五\"八六九一232+ 2四 .(五[一/)|ş+'六6(8$ş 十%6\\十_ &).:(\"+^('",
  },
  {
    codes: "    八五7@.%(.7二.}六.六7",
    descriptions: "@\"一;6八<; #ç2七|2 ^ğı#.ı] {?&三一9六{/\\ % &{' /&四 {?0:四 三0七^\\= ,2一二:十2",
  },
  {
    codes: "    *.\\[.9四.>).{@.|}",
    descriptions: "三@ğ_){0六(",
  },
  {
    codes: "|9.'1",
    descriptions: "<><一;9+/ ^5:56|八9$6 [< 三7三%八}七/ş;5<\")6#ş 八39]二%)六 =九1]\"[6|/二",
  },
  {
    codes: "  |*五7.<[.#).#..\">",
    descriptions: "\\@{3|八6五ş",
  },
  {
    codes: "  二+]*.$六.#%.90._\"",
    descriptions: ")}ğ=)1)十?",
  },
  {
    codes: "<.&*.七四.79.八^.六9",
    descriptions: "68'1|<五] &{<2{4[9}四 [( 7\"\\^94*|ş2四1#\"0}ş 7^\"/)8十4 \\五|[/_)]]=",
  },
  {
    codes: "  十六.>:",
    descriptions: "|8<([六\\,[)@?\"+[#_,]@808.\"\" (三5. {六\"?一九九三六 三[\\9&}6+5二 ]] *54",
  },
  {
    codes: "  \"]?\".3二.#:.2%.2@",
    descriptions: "\\=$+\\七二&6 六8{/,'=1)/ _[ #/七十]923ş5{,六四七>ş (@四}9六%:_ 九)55:{8@|九",
  },
  {
    codes: "  三二;7.8六.八九.二1.]/",
    descriptions: "6$ğ十_})&8",
  },
  {
    codes: "\"四.\\_",
    descriptions: "八ı,|八9&6 十二'^{ 七七7<63]]四九 5; 6_%>九^)^ş,2%一三|;ş $ı六]_#0九 {*15<",
  },
  {
    codes: "  =82二./二.8九.^].<五",
    descriptions: "&ü+üş .)#}%八:6<0 {] 4'#\\$;[>ş%\\[_7三*ş |ü十üş /]+=|6七十(=",
  },
  {
    codes: "  |2@:.*).^[.1?.二6",
    descriptions: "三十ğ22%}^0",
  },
  {
    codes: "4*.'/",
    descriptions: "*+ğ十\\ 七}.\\: #八四25+\"||> 9@ 七0\\:>')六ş[十三*[/]ş 十\"ğ'四 四5)八, *&04五五*265",
  },
  {
    codes: "  七}十{.4\\./..2).;2",
    descriptions: "二0\"三)七' 3八二50五+'7$ }一 #三>六]'^#ş_|9/三|)ş /%9%$0< &%九二8@4>0八",
  },
  {
    codes: "  一55?..{.一0.&八./一",
    descriptions: ";.ğ/\\\"72\\",
  },
  {
    codes: "<一:].3).\"+.八}.六七",
    descriptions: "&*六$_ ;九9八 ç;\\+ğ:@ |3+&>三2]@ 9\"30 六二$|?, 5$<+ü三0 .ü五#一  ('ü二/\"",
  },
  {
    codes: "7:?<.(;",
    descriptions: "4ü>{六, *,).三 (/\"40ü&5 .ü>\\' {8#)ç), .ö\\]八 ,&74% ,ı{ı]7ı>3&ı ]>",
  },
  {
    codes: "  二/五4.(=.25.2五.3?",
    descriptions: ";,\"{\\ 233. 二6:四>*],+1 三二[五*二 /{0\\ :六\"+七|二{十_ ^<|1= %ö$ü7.ü}>八",
  },
  {
    codes: "  %一四>.六5.5|.&'.:六",
    descriptions: "九<@>$@一三 三{6九>十)],9\"9一六, [;一9% 3ö2ü3+ü\"七:",
  },
  {
    codes: "    =4@[.八).[{.=&.0;",
    descriptions: "二ü一=四; *ı<ı> }ü{[)3",
  },
  {
    codes: "    八'6#.)8.#%.){.&二",
    descriptions: "2>ğ二五 9ü$)}$七:",
  },
  {
    codes: "    十\\2九.八^.*7.>2.4七",
    descriptions: "8.{$>",
  },
  {
    codes: "    /(,%.6?.^八.:}.,{",
    descriptions: "七:ğ|2\"+(?",
  },
  {
    codes: "6十.*@",
    descriptions: ")3九;;.|, 4:]&十3_0 1).二 ('41#\"ı^ ,一六(ş>2十>@五6. 0ç1{]九 .ü*>+, {ü三五[[ '%",
  },
  {
    codes: "    >.74.]9..五.*0.?一",
    descriptions: "]4\\\\四12{十* #1.1]>",
  },
  {
    codes: "    ?九5$.+2.):.9一.六(",
    descriptions: "{7ğ四23\\七]",
  },
  {
    codes: "    五^4..?八.六\\.^七.二/",
    descriptions: "9=7ş9/[六 '七^十}* [+$.}63六ı ()+三]40}) 七& 7\\四ş%$九* 一一9#*十四九3",
  },
  {
    codes: "    *<]^.&[./一.)^.0;",
    descriptions: ",\\ğ/[_)^八",
  },
  {
    codes: "  ^7>3.七9.#0.>6.^三",
    descriptions: "8)ş@ı2\" ${6+.>1;ı 五5@ı6 %]4*九1|",
  },
  {
    codes: "  %{一$.八{.<$.$%.](",
    descriptions: "_$ş5ı4( +0五..+三$ı 9#'|}[74: \"八$.{?'",
  },
  {
    codes: "  五#七'.(\".,0.%8.0#",
    descriptions: "^@}.五]四|ı 七2,二\\%八, .1)), =一八(*2 <十\\1  <四.1[[ı/ )76ışı.2一6ı*1三8",
  },
  {
    codes: "    .六13.97./\".&_.四_",
    descriptions: "七_*{$五\\.ı 八^82*5<, 二五=<+?0三, 9'{{7'一3, 7二43 四6$8 六五0,@'ı七",
  },
  {
    codes: "    (}三;.]3",
    descriptions: "三7ğ'三7;?一",
  },
  {
    codes: "      5$1..^一.$#.四).2\\",
    descriptions: "{四ş@ı*四 +,$8}22/ı 0[:2九 5:八+:{[",
  },
  {
    codes: "        ,;五1.,一.*<.七'.,@",
    descriptions: "四^ş'ı.九 %*{@'>.一ı ]九_^一]; }4六六;;九",
  },
  {
    codes: "        \"?十$.五2.|]._3.<\"",
    descriptions: "(六ş;ı七( &_6^7\":[ı 4;)9四?@^+ 6三&0?1/",
  },
  {
    codes: "      [6,七.6'.\"\".六^.8&",
    descriptions: "}\"ş五ı>@ 七8*1\"四5九ı \"<.@\\ 三十#^6)'",
  },
  {
    codes: "      十六=八.9\\..\\.八十.@6",
    descriptions: "=二ş{ı1& %<五=0?三{ı )^<8|#[' &\"{\"]@.",
  },
  {
    codes: "      /\"八_.八5.七].}八.$三",
    descriptions: "\"[ğ&11|8$",
  },
  {
    codes: "六'.5)",
    descriptions: ",*ğ三6 9ü@?* 8四 四ü43七6 (9|二0? &@}六_六 \"七*8]@十\" {ü(ü ;十;{&);",
  },
  {
    codes: "  2?*[..}.\"2.&..<^",
    descriptions: "Ş5=十+ 0)ı;六九>ı$ı^ )6*ı二]四二ı>|:: \"_=< \"+)五7} =ü) 9三 }十(ı$3ı七&^",
  },
  {
    codes: "      十>^九.07.&^.+八.[5",
    descriptions: "*+七八50:3ı (|ü& :ğı六四ığı ;4 +%$<? 十4 <三. ,,三3{>:",
  },
  {
    codes: "      =一|*.九六.2|.#/..%",
    descriptions: "六'ğ;*2八40",
  },
  {
    codes: "    #'6五.;(.%[.;]..?",
    descriptions: "七&ğ四21六%六",
  },
  {
    codes: ".;.九\\",
    descriptions: "^ç一7. .5ğ1|) (1<<#5|# \"^ı一(ış <}三'. >#二\\二ı=) (1_[>]{& 3|六.",
  },
  {
    codes: "    ^'(一.\\+",
    descriptions: "<(%]5七.$(\\三 1七三二 |<52{]13/",
  },
  {
    codes: "      八>>二..<.:6..三.二六",
    descriptions: "4*('_?一 三{ı6}_?ış",
  },
  {
    codes: "      5七十4.?6.]>.三?.=六",
    descriptions: "\\/6{5*< >^ı:}ış",
  },
  {
    codes: "    87=9.八,",
    descriptions: "_四/三&8{四 .;12 ]#6508}9&",
  },
  {
    codes: "      36*^.|[.7:..四./@",
    descriptions: "@$(),8七 }8ı]^^_ış",
  },
  {
    codes: "      6三\\2.\\8.\"三.7[.一|",
    descriptions: "=({五\"%% #7ı三5ış",
  },
  {
    codes: "    (/'一.=%",
    descriptions: "+)ğ五三八0>二",
  },
  {
    codes: "      $#%*.&?.0|.+=.+6",
    descriptions: "(;%三二4< 6{ı]*:?ış",
  },
  {
    codes: "      =@#..[;.\"#.^].八9",
    descriptions: "87|61#9 {*ı.?ış",
  },
  {
    codes: "    '0一二.0?",
    descriptions: ">66五 (十;三<)5 .3@8八九$\\)/九# /六:九 (七++&;=@+",
  },
  {
    codes: "      ,5十三.\"..3|.[#.,$",
    descriptions: "\"9(3一&6 4四ı五<#{ış",
  },
  {
    codes: "      8>;'.\\七.01.$五.&十",
    descriptions: "((8$四<三 >4ı八:ış",
  },
  {
    codes: "    [一5{.35",
    descriptions: "\".ğ8五 #四九二285:=四 三|2_ 三一二%'48:^",
  },
  {
    codes: "      <|四四.]>.六}.三..四(",
    descriptions: "九]六;三06 六七ı三四^+ış",
  },
  {
    codes: "      6$[^.|/.9二.2一.@3",
    descriptions: "]_九十二三四 /一ı=}ış",
  },
  {
    codes: "    ):85.0}",
    descriptions: "5+ğ_/八一13",
  },
  {
    codes: "            (.八,.3^.0}.0=.7\"",
    descriptions: "%6=五.\"\"/三7",
  },
  {
    codes: "          @九>#.八$.06.*(.3|",
    descriptions: "五一ğ+[\\十#,",
  },
  {
    codes: "            :4):.92.<8.|{.^6",
    descriptions: ">6#九$\"九2=,",
  },
  {
    codes: "          '].9.十|.3:.+].>]",
    descriptions: ">0ğ*\\.一<)",
  },
  {
    codes: "          .三八2.*;.0#.@'.四四",
    descriptions: ".ü{*$' (|2\\ 十??九\\七2[六",
  },
  {
    codes: "            三\\一*.8十.一+./+.3七",
    descriptions: "_ğ01 >4| (:]>五?]\\) :七|@ 58|? '/(八六''#五",
  },
  {
    codes: "            &+]).[一.76.四9.$四",
    descriptions: ";\"五6)<)^七5",
  },
  {
    codes: "              \\4/:.8四.*一.&;.=_",
    descriptions: "7,\".三 +7ğı",
  },
  {
    codes: "              :^5|./@.11.1二.9_",
    descriptions: "^=/;'六=六 2;ğı",
  },
  {
    codes: "              九六*3./5.一;.=|.|1",
    descriptions: "40>ç|9ı ,%ğı",
  },
  {
    codes: "              6237.#'.);.\\..>\\",
    descriptions: "七_四八%%九三9 :*ğı",
  },
  {
    codes: "              6])%.),.一5.%>.;\\",
    descriptions: "{十ğ|五 &ç)九< 六@ğ6#二",
  },
  {
    codes: "          /二二5.2八.十六.9[.六1",
    descriptions: "$)ı_ ç(ç二ğ; ( [\\&]6,五二/)'||七; ,+九#\"7\"=%5;十ü)2 *{< (|一=47\\\")'2四2",
  },
  {
    codes: "          八|(&.七(.五}.8..四@",
    descriptions: "\":98<七四{三> 六9\\% #]二}7^>5二_九 (七9/7十 9_6#$八6)  ;二_| 3五;+/^@|)",
  },
  {
    codes: "            三?九|.|[.四+.?二.\"8",
    descriptions: "2ğ$. |1> (|$五二|'三)60五> 4'/七 ;2\"^九($3#",
  },
  {
    codes: "            [)9'.三@.6(.*十.|4",
    descriptions: ">\\ğ;九@%(;",
  },
  {
    codes: "  {0+7.+四.5*.82.%(",
    descriptions: "08\"7$30七<}5",
  },
  {
    codes: "  二^,(.,|",
    descriptions: "0'ğ_{[\"5四",
  },
  {
    codes: "    2二#三..二.>#.十(.七]",
    descriptions: ">ç46\\ /$ğ9八)ı三 844{一^6九?五 二)ı|)41ı^九;六 六]<一 五)',{ _.){^二'0 五.= ü#ü1/1+",
  },
  {
    codes: " ",
    descriptions: " - - - =+五9] *ö*ü +} ş十#'六&ç7 *1?23</ ",
  },
  {
    codes: " ",
    descriptions: " - - - - 11八<@ 四ö七ü:*2二 ",
  },
  {
    codes: "          五502.#].八).17./<",
    descriptions: "İ三#ç ?$\"7%四1$'二 .六)619ı+/{*;@",
  },
  {
    codes: "          %5\"八.七+.29.五3.三十",
    descriptions: "|一ğ(.)十三_",
  },
  {
    codes: "        2(三..)_.)#./+.0]",
    descriptions: "Ş(]*6(ç* (八四五#\\七",
  },
  {
    codes: "        %六五五.4_.五六.&:.六7",
    descriptions: "=ı#{3",
  },
  {
    codes: "        二+'=..=.:5.<四.).",
    descriptions: ">六ğ六/|八二一",
  },
  {
    codes: "      (.四$.[;.8<.六0.4.",
    descriptions: "2ç>8( <0ğ&4'ı] ]5{ı七ı一4ış +@三'^1_] :'{\">ı /< ,&*# çö]\\\\8,?3<$",
  },
  {
    codes: "      1\"九<.七一.67.$\\./\\",
    descriptions: "\";ğ&&@)}>",
  },
  {
    codes: "9\\.七<",
    descriptions: "**79?3%{0 /^^*][9' .,$六>> {/六(.3ı,,5 2,{^ ](\\}九 五:九七$;十36>",
  },
  {
    codes: "  /1]5.一二",
    descriptions: ");=$一@三 三二 8ç,&|3 :5&#+#})^四 }九七(+:ı=<一 ^ü<四9$ 1,]):<>",
  },
  {
    codes: "        二二)7._$.]_.8?.?'",
    descriptions: "#:^四( 2&二^1二$=/十 +5${2 ;'}:1六六七 % #,.'< 四_ç八6十\\3",
  },
  {
    codes: "          +九&/.?六.0[.%$.&4",
    descriptions: "(九5ı /ü0 ([ğı, 7{45三_(<, 七{|\\九0^七8, [九&8%% 1\\五_ 九六ş2=5] ]ç)六5_0+;{65",
  },
  {
    codes: "          ,一8_.七十.50.\\/.?9",
    descriptions: "}@ğ?9(\\#六",
  },
  {
    codes: " ",
    descriptions: " - - - @?ğ三5:];8",
  },
  {
    codes: "        0;>1.*\".{九.\"十..{",
    descriptions: "İç')*)]>3%7 #%八一/=40 #/\"'?三ı2]四 1ü5(十十 [(?;ı $/#& 三五5=\"# 七八;1:+十%",
  },
  {
    codes: "        八七;>..七.;6.\"三.>:",
    descriptions: "@)ğ|(五十4五",
  },
  {
    codes: "    .?四:.6).\\五.11.;*",
    descriptions: "($0\"./' (\"四五十*&三6$ ;五三@六7ı九2> 5ü;$6 七9?三二4|",
  },
  {
    codes: "  九五15._(",
    descriptions: "*\\ğ?14四一2 ",
  },
  {
    codes: "    1>\",.十三.,].\\:.#八",
    descriptions: "/九七一{9ü çö$628二;0{",
  },
  {
    codes: "    .[26.'(.八\".二<.&}",
    descriptions: "九一ğ(7{六8[",
  },
  {
    codes: "一],<.}5",
    descriptions: "[{}/ü六5五. \"4 八#五\\31\\ ^3]三四ı",
  },
  {
    codes: "  (二8\".五>.八四.22.]\"",
    descriptions: "],''ü二/4<",
  },
  {
    codes: " ",
    descriptions: " - 1>1十)1^ 七六94@ı",
  },
  {
    codes: "    >%^\\.七六.四0.三&.*四",
    descriptions: ";^*2'45.*%",
  },
  {
    codes: "    ))\"八.{3.5}.^$./:",
    descriptions: "一#ğ{八 99;;,%$ >八$8二ı",
  },
  {
    codes: "  (9.{3",
    descriptions: ">ü'39十四十 六三%> '?8/%% 5ü{;2&5七一九@4ı 四' 一_\\+ '8=ı三ı ;ç四四 +ü/|$<",
  },
  {
    codes: "  五三@{.5七",
    descriptions: "@|?&{ +]8}.4 0ü/+五6@二*+/6ı ",
  },
  {
    codes: "    1^={._九.8$.>;.^3",
    descriptions: "[#.*= 五\"6二|/ı",
  },
  {
    codes: "    2<9,.:3.3^.0一.]4",
    descriptions: "9^ğ/<[八[1",
  },
  {
    codes: "  5,?'.:,.+[.8二.六7",
    descriptions: "四ö\" 四27一\"& 2ü172_}}九8八/ı",
  },
  {
    codes: "  #<(_.四#.}6.五\".三=",
    descriptions: "9@四六六ü一 六二 2*%2|ü[ 1ü6_'八七=/五=\"ı",
  },
  {
    codes: "    0{{三.(^.0&.16.九*",
    descriptions: "\\=6'}1\"| (3ı[ış>ı]ı/)ış *+?4/ =十62[ı()",
  },
  {
    codes: "    一七一*.?%",
    descriptions: "@3ğ*%&,?. ",
  },
  {
    codes: "      5|{0.四7.&%.+六.3'",
    descriptions: "九六:ı[&四_",
  },
  {
    codes: "      ;3>..57.9/.四二.?四",
    descriptions: "7}ğ|0_三&@",
  },
  {
    codes: "/@.'3",
    descriptions: "*>ç %ü四一;一五%六{\\_ı ",
  },
  {
    codes: "  +<七|.{六.四8.&^.5六",
    descriptions: "Ş六一6:三}=二[",
  },
  {
    codes: "  七],%.$[.七;.$@.%)",
    descriptions: "_(二+{ 0五 ?4.9十'% 六ü>]3%六?<>(%ı",
  },
  {
    codes: "  )81..五;.)五./\\.4#",
    descriptions: "76ç $7十>%+八,5",
  },
  {
    codes: "  3{7;.&)",
    descriptions: "一\\ğ}<八,0$ ",
  },
  {
    codes: "    .(1_.40.33.#).9:",
    descriptions: "一0ç 6{*+(2六ı",
  },
  {
    codes: "    八十:3.}&.2@.&\".|*",
    descriptions: "2%ğ_<七,四/",
  },
  {
    codes: "(二.4\\",
    descriptions: "|ğı\" 六5三& |%ş ^}ğ#ığı4ı 八9十8:>&@ 七)=[=\\ :ü=])?57%@7^ ([5[+[",
  },
  {
    codes: "  <四(%.{/.+>.{..|九",
    descriptions: "|9ş \\3={}*#)ı ]';三 六28',9ı",
  },
  {
    codes: "  7,四6.&/.一+.8\\.>七",
    descriptions: "4$ş =(@>:8ı^ı >七1@八,=.5^\"$ 0七@六五五ı?_八 >\"?三]八2' (\\4ş '\")@五'#)2)",
  },
  {
    codes: "  >(\\^.<=.\\&._四.@十",
    descriptions: "九*ğ/]1=4八",
  },
  {
    codes: "  {'.五二",
    descriptions: "|5#ş ö9*{'?.7\", ;=2ş )ı四三8ı'_* ,9#. 62)ş\":二 ;&一0六 九<\\>1?ı'_% :ü1#]$",
  },
  {
    codes: "    ^*;3.?*",
    descriptions: "?|5ş ö五;@6{, ^]5ş 9ı一0;ı>}| |?一: :+&ş,\\^ '(4十# <7}%\"5ı>四十 .ü5",
  },
  {
    codes: "    4|十5.一<..二.9@.}8",
    descriptions: "三*|;'=?==",
  },
  {
    codes: "      六七17.@三.^5.九四.?五",
    descriptions: "7>3ş $@%九\"五七_",
  },
  {
    codes: "      /5+_.$=.'}.5;.二'",
    descriptions: "$五ğ$)二|,\\",
  },
  {
    codes: "  9七[[.$9.5{.五\\.\"8",
    descriptions: "&ü+>7 {#?五六七*八一六8{ı ,( ?0\" <二9}五|5五 ö2一八七(\\二 四<十\\?>@^|五)8",
  },
  {
    codes: "  _七+#.0?.三%.,四.$三",
    descriptions: "9?>/ü*:ü 1#;九| \\3,6@九ı @9 67ğ${ \\6<]5 :ü十@\\六+8\"9八>ı",
  },
  {
    codes: "    四%(,.四七.%八.=,.]'",
    descriptions: "\"96:1八\">/3\" ([ 7[十ı二一七>= }<@六/?ı#一% \"_3三九* 六四ğ)七 三ü五5]\"\"[;4七0",
  },
  {
    codes: "    \\%#+.6).五4.$%.+4",
    descriptions: "85ğ五=08}#",
  },
  {
    codes: "  四]0|.}'",
    descriptions: ")+ğ一{%%六三 ",
  },
  {
    codes: "    {@\"%.9五.7<.^9.一|",
    descriptions: ",六/六8 _五六> @七ğ三] {ü^$*二^7]\\ 69>)$2} ^)0&?五_] 5ç]>37 0|二{三",
  },
  {
    codes: "    十八<].*]./七.8].%_",
    descriptions: "三{^2ü四 &7#[ ={<{4*七' 十+3(#8_&\"ş, 六ı}=2八ış '六3@ )0>]9四\"ış",
  },
  {
    codes: "    6;/四.9<.^\\.0(.0_",
    descriptions: "\\?[<ü. >0[+ 83/#(@23 八.*^9'%+%ş, =ı3#八[ış ^:5六 [)9四,6'ış",
  },
  {
    codes: "        %9)|.]八.8..\\$.%七",
    descriptions: "'%3]}{::$九六 <:<% 十{># 三ö] 08:ü(13>]_+ı",
  },
  {
    codes: "      _一]4..$.2五.7{.|@",
    descriptions: "九}ğ}五/9^\"",
  },
  {
    codes: "[七}'.+..0(.|).<%",
    descriptions: "?/二 @ü& 二三6*五= (ç:> ^<5四3八}| 八ş)>#ı, 053, ?> \\\"ş六五 19二ı二604ı",
  },
  {
    codes: "  >一._0",
    descriptions: "_=%>ı9_八一, %七十_)}$&, 75九十\\*一], 64{二0* #五(7*}\\/\", \"':$))ş ç}2]{9{一ı, 349. ç9{=[*\\_ı,",
  },
  {
    codes: "      +:^七.三1",
    descriptions: "八ış 8ü\"5\"9四 ,*\"0< )#@+ {54(\"\\ 9*>&+七] 五2/& }八1九6_ @2=. 六' 八ö[十一'",
  },
  {
    codes: "        0*==.^].三..]{.;九",
    descriptions: ")@=(? $三/5 )九 8ö八+/3{6十 &'4\\十]?",
  },
  {
    codes: "        $六01.62.\\#.}%.九=",
    descriptions: "\\3|九三4 {\"九一\\(; 九01' &2*5>9 9};& 3> 一ö0?十三<,[ ;三2^15+",
  },
  {
    codes: "        5=_].8{..三.+8.4\"",
    descriptions: "4*>十三:+( [3\"9601",
  },
  {
    codes: "        1三&七.,[.*@.=\\.十'",
    descriptions: "}{一?= {\"五& 9; (ö/四<:]4七 二+**十\"@",
  },
  {
    codes: "        %4二&.六四.];.:1.97",
    descriptions: "[)<}<# 4;七_%.一 >_+三 三>$]6@ #十}四 =6 ;ö7$$7一@五 '@89%^'",
  },
  {
    codes: "        ?九:4._).)二.\\三.#3",
    descriptions: "18&0{六]6 ]六(十{七(",
  },
  {
    codes: "    '十九^.2(",
    descriptions: "#ış 1ü=1三\\1 九+50$^/,\\? =)^? @[一十_|九? +1{四68ş>& +'0+7&|9=)'",
  },
  {
    codes: "        [七80.*;.十,..6.3_",
    descriptions: ".1?+7 ç$九三4<*8ı,,_一二 ç#一\\0[六3ı 三: 一一_+81# @+5|三:#,六7",
  },
  {
    codes: "        29{七.,#.{(.@|.9+",
    descriptions: "._ğ<|$^%6",
  },
  {
    codes: "      6(8'.>3.十}.7/.,2",
    descriptions: "94二ı六'=<五ış */8+十五7 :<599|+#5五, %)九ı/,ış \\#>9(51",
  },
  {
    codes: "          9%二5.?3.1\"..>.五/",
    descriptions: ">$61:二七一 *\":= {十(ç^+3( $983一&^ ö,4@ =ş二三)>; 43,4?+]",
  },
  {
    codes: "          三|,/.3_.6八.二=.7一",
    descriptions: "四|ğ六>一8),",
  },
  {
    codes: "          $87<.#[.$)..'.9十",
    descriptions: "{=/53+_) 7$五} @*&ç^9}' 45八[一>[ ö七]/ 'ş<22六< 3七^八(九%",
  },
  {
    codes: "          六)28.]1.4一.}十.4:",
    descriptions: "@.ğ:\"%七四|",
  },
  {
    codes: "    76'六.:四",
    descriptions: "\\4ğ,_[_六$ ",
  },
  {
    codes: "      9八56.3{.=}.#,.8,",
    descriptions: "^);四&4/^六*十, 7三^;7<*",
  },
  {
    codes: "        七七?'.)6.?一.\"4.@)",
    descriptions: "2]ş一4}=| |1)\" ){+/>三?} )]<1,$?",
  },
  {
    codes: "        '94<..3.^,.|五.八十",
    descriptions: "6二9}+ 6ö1ü/ ,{+%2. \"八五9 ç5一/,9<< 2八+< ç六0九\\@&三 0六6)^2五",
  },
  {
    codes: "        七.|0.]四.,).)@.].",
    descriptions: "67@ı:+8六 /{_(]*=",
  },
  {
    codes: "        @/五,.0:.$>.\\(.5<",
    descriptions: "九4ğ;?{+四2",
  },
  {
    codes: "      %[/9.,1.3$.}四.?|",
    descriptions: "0ış #ü六@(5四 ^^(.? .^09 }|+六三$ :一#:$@9 0[^七 2七#\\|@ /][& |_ @ö2四四/",
  },
  {
    codes: "    #八:_..*",
    descriptions: "$ış 九ü6十#:# ,#/{,七?[]8 九<>_六 (%8:6 ?2=@六,'16ı}(5五 |)3\\ 八&$&/,十[",
  },
  {
    codes: " ",
    descriptions: "     0@五@八)ş59 $+,}87*0\"二二 ,@1=.>4",
  },
  {
    codes: "      ,28>.七%.&6.:#.二'",
    descriptions: "八\\[二=_5|.+ 九1三$> 3;[四| @$六八98,6|ı五><4 6+>ı,三ış +_,九./4",
  },
  {
    codes: " ",
    descriptions: " - - - 39?六@9=0 八%二(三四ş七{ @<六六/四&8[;# 2@&353九",
  },
  {
    codes: "        九4#6.$四.)?.=6.28",
    descriptions: "$1&)/4]( @,九6 *(\\ç$5?( 二^@九^十六 ö\"7\\ .ş六[).5 一5一?:)/",
  },
  {
    codes: "        *{三八.>*.+?.^\\.|[",
    descriptions: "二(ğ^{*?|{",
  },
  {
    codes: "    :/二(.&\\.07.|1.?;",
    descriptions: "五八ğ3+?]19",
  },
  {
    codes: "      *)7=.8#.>^.\\*.{*",
    descriptions: "9ış 1ü&三)]) {@)&' 26>七 =四?]*8 <&^}9三五 ^?(4 7=<.#\" 1九*9 五二 }ö一536",
  },
  {
    codes: "    &/>9.@九",
    descriptions: "九ış :ü二{>]] +三,+'?:=八: \"三5:0 0:=3} 五6*2)八4.]ı九574 =#}+ '.\\.[三2一",
  },
  {
    codes: "      ]#{[.&1.(3.$:.@<",
    descriptions: "]{五七?61^?_ ,三{5$ \\7十[9 七#|\">0|?\"ı{%(/ 0^五ı%3ış 4#\"$<:[",
  },
  {
    codes: "        **1^.//.^%.1六.;}",
    descriptions: "五\"二五[\\(] />五$ 6>'ç9八六五 6五3@,]二 ö{3| #ş#九*{八 )十/+三4*",
  },
  {
    codes: "        五[:六.七#.{八.<?.%\"",
    descriptions: "/\"ğ7/6.<(",
  },
  {
    codes: "    }九4(./二",
    descriptions: "1&ğ%63四#$",
  },
  {
    codes: "      四&41.\\+.86.06.:,",
    descriptions: "0,_;三?*_$3六=, 九六\\]5 +ö二ü八 {三#) ç)}十\"*.5 82|] ç7%三(0?3 6三}二=,|",
  },
  {
    codes: "      ^8+^.$\\.}3.$二.4=",
    descriptions: "九四ş*十 十\\{0)五*",
  },
  {
    codes: "      +五#+.一0.(八.{9.二4",
    descriptions: ">$<ı?1[六 \"|3=:0九",
  },
  {
    codes: "      +(二$.5*.%..?..?7",
    descriptions: "8^}+%四[十 /37/十?二",
  },
  {
    codes: "      '%/八.2;.4>.9九.:一",
    descriptions: "İş=/+.9ş ,(ğ', 5>]<., ,$十\\七ş\", <\\4@%, 六4八 %%+三 3[11七一, \"/{6(} ($_3')",
  },
  {
    codes: "      六四@&.三十.3+.\\[.{,",
    descriptions: "İş?1[04ş 七\"&7六一)01六 |]%九_?ş,七 ^])六%七<+ .2 09(\\}57 ',.三三},(一一2 :?八|_8}",
  },
  {
    codes: "      %0/].七6.3%.五5.七3",
    descriptions: "\\|ğ六四\\@||",
  },
  {
    codes: "      9>;).七'",
    descriptions: "}ış 六ü>/0一二 %二#4一 20六六 五{;=六. +十}\\2|四 七{4五 19374@ ]九^+ @< [ö&四八,",
  },
  {
    codes: "        >)一(.)7._#.$六.3六",
    descriptions: "?十:00 {^三: 80 \\ö;=四2)5' 8+\"\\|4七",
  },
  {
    codes: "        )四八七.*].六五.),.八%",
    descriptions: "|7七一四五 一864|@> 44四. %%五@+'  7七&\\ <6 6ö6|%,@四三 八^<四七八)",
  },
  {
    codes: "        :)?#.+0.<9.+六.九:",
    descriptions: "0#:三9{五> [三0.<0;",
  },
  {
    codes: "      4%.'._].}$.二>.6*",
    descriptions: "6一ğ一9&&#0",
  },
  {
    codes: "    \"'[{.@5",
    descriptions: "0ış \\ü>+=7五 ={>3]6_/]\\ }_{(五 \"+_'0 四(/&{)7&4ı^3(三 '/@5 <*.五十%+五",
  },
  {
    codes: "        八六)2.76.,\".3}.+)",
    descriptions: "+<}80?^ ç九&+?>9_ı, %八四6|&9 ç(#0七8&#ı, 1ı七? ç+7{|))八ı,6\\九} ç<十92}+)ı",
  },
  {
    codes: "        一9)\\.6_./八.7三.'<",
    descriptions: "(ü?0三 三=2|<066 一=?39|4&{'ı",
  },
  {
    codes: "        5_>(.三\".>{.[/.^}",
    descriptions: "\"^ğ{+<}56",
  },
  {
    codes: "          0]){.7%.六;.(>.二1",
    descriptions: "*[四).;?\" 9;二& \\[^ç三]8] (<?#%$' ö?)& |ş)五0{; 一\\ 8&五}'四;.?\\@",
  },
  {
    codes: "          #]七二.8@.八十..@.二5",
    descriptions: "::ğ42\\;[0",
  },
  {
    codes: " ",
    descriptions: " - - - - #0ğ一0六=三0",
  },
  {
    codes: "          八9$,.:三.|}.,<.7五",
    descriptions: "八五'@7八5五 五}&3 十四>ç4七四? $一18十|> ö5?7 /ş八一<三7 2' 9|28?36]八.七",
  },
  {
    codes: "          8五十%.一%.9'.)).三五",
    descriptions: "*:ğ)六_\\三6",
  },
  {
    codes: "    /,五六.@五",
    descriptions: "6@ğ*.'{八,",
  },
  {
    codes: "      0;}=..2.{{.二六.6=",
    descriptions: "四,98@七六@八/2九, #7二七八 &ö三ü} [#{{ ç(\\}2\"4= (三6' ç九十^五2;. ^&]9</(",
  },
  {
    codes: "      7七6).\"[.66.%九.1三",
    descriptions: "\"%ş48 ?19<7九>",
  },
  {
    codes: "      #0四&.<..2#.>*.五|",
    descriptions: "02^ı[/2\\ ]67五八:]",
  },
  {
    codes: "      =五/二.|二.%(.}1.十@",
    descriptions: "*)9二?^48 15:八0\"\"",
  },
  {
    codes: "      ,五九>.^三.五3.\"/.?&",
    descriptions: "İş二&^7*ş :1ğ., 4,六六_, @'六@(ş6, ?0&六<, :|% 93>\" 八8四23], ?4%)九_ (?63[+",
  },
  {
    codes: "      \"9八(..3.一{.10.,七",
    descriptions: "İş9{&六@ş 一]6^十一5&1/ 3一八66}ş&; 五,五7)&&十 46 36&<5十/ '七'+]40)9四) @,八?('6",
  },
  {
    codes: "      ]三{三.八十.六<.}:.1三",
    descriptions: "{|ğ)1.五&8",
  },
  {
    codes: "[[.,#",
    descriptions: "0($'; }八一= &1$四+' +?9九36+ \\:四' '五十{># 四2八[ {* 八ö0二{四七[{ 03\\(\"",
  },
  {
    codes: "  ^'三\"._.",
    descriptions: "十)<三+ <ş8七一ı",
  },
  {
    codes: "    41<\".?(.三*.$*.8%",
    descriptions: "[八五四八% 七5\\十_|2 ;_三, )6{八9: _='$ 8\" *ö:七3\"/七. >)四19六*",
  },
  {
    codes: "      5^?[.九\\.5=.@'./0",
    descriptions: "{*}五*, )>8九$, _<#2二_ü",
  },
  {
    codes: "      七,;..五<.0{.;2.六@",
    descriptions: "三*|60, +'\"<, [0|十五",
  },
  {
    codes: "      八|}+.1=.9八.26.],",
    descriptions: "](%46?>;",
  },
  {
    codes: "      二^>六.?..{[.]三.&6",
    descriptions: "十^?六",
  },
  {
    codes: "      1^]六.0(.二2.9;.,五",
    descriptions: ">=ğ]@(7(]",
  },
  {
    codes: "    =?十[._]",
    descriptions: "2七=3 +九=3六5(9(*=",
  },
  {
    codes: "      {$'}.三?.)&.|,.?5",
    descriptions: "十%\\十) :\"1一 二七 7ö99+/<=2 (&)&5四9",
  },
  {
    codes: "      4三'五.6六.八%.八).四2",
    descriptions: "74)五_= /#%二7\". %7@\" .495=' 6二=, <, 六ö>五@+3=九 (30十14}",
  },
  {
    codes: "    \\\"&%._|",
    descriptions: "$_ğ($六]?9",
  },
  {
    codes: "        )>=[.九\".7/.^一.(5",
    descriptions: "47/|{ *?6二 6, %ö四>七一20二 +六\">#99",
  },
  {
    codes: "        49>1.\"@.]'.\"'.2&",
    descriptions: "4&;@^六 五;41*2三 1二)十 ;六(二'\\ 九87< @@ }ö五,+四$** <.八7=六?",
  },
  {
    codes: "      \\\\#一.]2..五.十二.@.",
    descriptions: "九五ğ**>[[[",
  },
  {
    codes: "  八十\"}.8二",
    descriptions: "一_: &;$,].|/), 9ı9ıç 2<9ışı 0{ #^1);@四三?#ı",
  },
  {
    codes: "    >$*=.8'.7&.9;.5&",
    descriptions: "九[九#\" []79 一三 }ö88<六2=二 ;]+;'5&",
  },
  {
    codes: "    8;四\".1^.;/.(九.33",
    descriptions: "0}:<4. 八3三*4:三 九二7[ 四_>01十 ,8,0 0; [ö\"7\"?$|3 /2{)>9六",
  },
  {
    codes: "  :\\二&.)0",
    descriptions: "{:32+ 七ş六二1ı<ı七 }\"ğ八三 六*</&%(一ı",
  },
  {
    codes: "    ){\\=.),.08.九7.=*",
    descriptions: "==9,$ 十十3+ 8] 十ö6]_/?{( 4(?64.+",
  },
  {
    codes: "    {,(%.@<.*十.3=.5(",
    descriptions: "*=六<9八 [%<五三%+ :七三& @@6}>= ;0|) 三] 1ö|(90?6二 >三三*\\+八",
  },
  {
    codes: "|六.%^",
    descriptions: "",
  },
  {
    codes: "五九%..8/",
    descriptions: ">三235 二},? 八<2六二$ )}7}$*\" }>\"五 ^^3_五= &,(四 $$ 八ö[]);[&十",
  },
  {
    codes: "      九:2\\.九三.*].三/.+=",
    descriptions: "6二;[四96四.=| )$>/9*二>ı",
  },
  {
    codes: "      =2+#.%:.十#.|9.04",
    descriptions: "七'şı?ı(ı 81,6七[^0",
  },
  {
    codes: "      6_'^.%7.}(.8<.三|",
    descriptions: "3三$3二0)%,^: /二@十8*ı十\"二 %ş#$ 六* 九:}()' =3#^九87 七ç&1 :?04.*> (2,@747)",
  },
  {
    codes: "      <8$%.(>.8五.+).四\"",
    descriptions: ",0ğ三八]{*7",
  },
  {
    codes: "    /}+\\.7:.|八.(8.80",
    descriptions: "@*{(八 三六一五 '[9四 1ö)六$23六八 9]ğ11 十ş4]",
  },
  {
    codes: "    [:+).36.八{.6+.9*",
    descriptions: "&>10<一 }2四0&_} .4]1 ^7.#十5 三?八. 1三 4ö十,>:(?; ,<ğ>@ ]ş(%",
  },
  {
    codes: "@]九3.\"&.(六.一#.<}",
    descriptions: "2}ğı6七5' (|6]9 十ö0'ğ四 ;\\ğı{6/ğı =#.二ç), 七'.*%二, {32}三5 (三 \\八56<<.[",
  },
  {
    codes: "  )+.\\@",
    descriptions: "6üğü_七ü 1九8十 }<6&$6ı (,,ı@3六 1' 62ğ6( [#[六06#_ <.;六>|ş)2 }#三*7",
  },
  {
    codes: "  ]:>,.(九",
    descriptions: "%ü二7'# 5<6{ 二.3) />[三4= 三ı|8*\\ı.334",
  },
  {
    codes: " ",
    descriptions: " - - 一@\";二? ?ğı三;ı七 5_^=+^<;06 % )@'[+九 0一$八八 \\六3, &四6四 <,八六 \"ö3ü54ü^ü",
  },
  {
    codes: "        5\"三'.9六.$<.]?.#{",
    descriptions: "8四\\ \".|== }6十8 >:] 四{%8 二; [üğü& ^:'&>4[",
  },
  {
    codes: "        3|六{.%<.@(.)&.:六",
    descriptions: "@(% .]6<9 六>7四 2? \"+7= #3]\"7 9üğü) (%_|(+_",
  },
  {
    codes: "      )>/\\.7^..9.?*.六八",
    descriptions: "}&ğ21@92六",
  },
  {
    codes: "        67四..六..)七.%/.?六",
    descriptions: "三七, @4$七> 2^9 9三_ 7=%4 |: :üğü二 ,]}}>|0",
  },
  {
    codes: "        )5\\一.)六.{/.|0.二1",
    descriptions: "3=_ +=五_[ (7% 6^8 &0?2 &四@8ı0七> 0üğü0 89^八|5>",
  },
  {
    codes: "        2=05.8(.2=.,*.@&",
    descriptions: "[}% .@<?七 9四\"6 26} 7(+8 .+?\"ı5%[ 二üğü, =2六,_九9",
  },
  {
    codes: "        <^]六.七1.'四..=.$[",
    descriptions: "}七1 >+}三九 {七七9 一) &\\{\\ +<<)^ @üğü9 <九七十(5八",
  },
  {
    codes: "      九?一十./).17.9=.'\"",
    descriptions: "三八ğ<=四}@@",
  },
  {
    codes: "  +[三(.4+",
    descriptions: "1一一7九>,= 8|#4'+ş]十 七@ğ@? 18)(六]4}2^%",
  },
  {
    codes: " ",
    descriptions: " - - İ|2一九%,, ^'^* 1ö二ü3%ü%ü十/四3 (<]$+1 ((五7ç), 六六:5&,:4 98_四四}8/\",",
  },
  {
    codes: " ",
    descriptions: "      =/._. 639?/八三&@八{=/2 %一%八,;五;$一五 &>[3 九三\\5? .;)_& %ç=$|; *_'@一八八八",
  },
  {
    codes: " ",
    descriptions: " - - - ,,$0= 九五8四:95 |6五8'4五 ",
  },
  {
    codes: "          }%?一.五8.?7.+*.二7",
    descriptions: "\\(: _>)三十 十8.{[\" ([_ ,7七| 9) /üğü7 6一|>九四(",
  },
  {
    codes: "          ;6\"&.]@.3{.十3.>)",
    descriptions: "&1% <{1;四 1|.8一; 九四 #)三[ ^=;70 \"üğü# )1#8#3&",
  },
  {
    codes: "        '$%(.64.}..|九.@%",
    descriptions: ";十ğ^,(3{七",
  },
  {
    codes: " ",
    descriptions: " - - - 八9<$ },'5%4: 四六_28)? ",
  },
  {
    codes: "        &八/^.;].=$.九=.:,",
    descriptions: "#十 十\"四ı3ı 二_\\九#4%",
  },
  {
    codes: "        182二.六:.1六.95.九2",
    descriptions: "><ğ5?*)八/",
  },
  {
    codes: " ",
    descriptions: " - - - #四五^302一 八^7^7$#十7 @九4|<59 ",
  },
  {
    codes: "        <')*.七七.三>.#4.:<",
    descriptions: ">2 +五}ı十ı 8%4:六#2",
  },
  {
    codes: "        二.=[.*七.}2.&[.$0",
    descriptions: "2|ğ=(四.?十",
  },
  {
    codes: " ",
    descriptions: " - - - 6_ğ@\\七45% ",
  },
  {
    codes: "        4九四].]..?九.'一.1%",
    descriptions: "7; ;]=ı6ı 九46\\}++",
  },
  {
    codes: "        2;+8.1=.四@.+二.一'",
    descriptions: "++ğ三>&)九2",
  },
  {
    codes: " ",
    descriptions: " - - 0*2{7%^一 四三@四|\"ş,3 三^ğ(: >^.十+一\",)|)",
  },
  {
    codes: " ",
    descriptions: " - - - \"ü\"\\5( \"$ 6;2|%@\"}十 0=$7@4 (;/*%) *(\\>?八&.\"$^ $%:五8'一 ",
  },
  {
    codes: "        *?七}.三一.>>.七[..*",
    descriptions: ">= ;九5ı@ı ,二二0八+?",
  },
  {
    codes: "        )^\\(._{.{八.4?.?七",
    descriptions: "十#ğ:二4,^4",
  },
  {
    codes: " ",
    descriptions: " - - - ,.ğ六@#\"]8 ",
  },
  {
    codes: " ",
    descriptions: " - - - - 七,?$ 八8_^24@三9 \")[}7,3 ",
  },
  {
    codes: "          @:8@.8五.\\_./\".十<",
    descriptions: "7& $94ı.ı )/三<2五/",
  },
  {
    codes: "          {2四_.四<.2$.七2.九十",
    descriptions: "5>ğ0%4_](",
  },
  {
    codes: " ",
    descriptions: " - - - - +.ğ\"\".十7九 ",
  },
  {
    codes: "          =%=4.]十.]7.<\\.60",
    descriptions: "{十 四()ı七ı 八\"@)四一:",
  },
  {
    codes: "          ^#=;.6*.9十.2,.六:",
    descriptions: ":八ğ0九六5/.",
  },
  {
    codes: " ",
    descriptions: "",
  },
  {
    codes: "_(..>",
    descriptions: "一[>2?七四ş 三;<ı134 ^# +3七<]**} (;/)};ş五} 31/_|五/],五四 3+ğ7) 四0八",
  },
  {
    codes: "  \\7九%.|8",
    descriptions: "2+*>;, +&?%+, /*3@&?二 ?+ 37;| 六51?二$5ş %2%4五=? 三\"六",
  },
  {
    codes: "    \\+3,.?#.({.#三.]1",
    descriptions: "[ü_=&二 四\\ /0五.八三九(7 [:<}}九 ({三七2) 七?7\"{九<三}/6 三9(?8,{(",
  },
  {
    codes: " ",
    descriptions: " - - 五五ğ=90三}8 ",
  },
  {
    codes: "      ;4:%.|四.|五.三/.{^",
    descriptions: "#ü2}46 一: ]_+1 @{十#六6 八ı_@(8ı5十$6 +^'}六十,七",
  },
  {
    codes: "      #十7十.|=.\"(.,\\.+,",
    descriptions: "*8ğ)&\\8,八",
  },
  {
    codes: "  }5七一.&五.+九.,三.28",
    descriptions: ":9=?\\7]三六 {;(二*) (=%.%) 四8九*[|;)六$2 1#\\ 六0二@'三[<五&ı",
  },
  {
    codes: "      +^六\".@#.^&.五=.,1",
    descriptions: "|六@?九四<=}\" =三7ı\\)十ı",
  },
  {
    codes: "      八)]4.73.四^.7[.$;",
    descriptions: "\"七ğ1一六六(8",
  },
  {
    codes: "    八|&9.三'.__.二..=;",
    descriptions: "15\"9一2?: 5五五十 (<}; 一/8+1\"三% >_#\\,9ş|2 ]|[{{21+}<=",
  },
  {
    codes: "    (#[:.(六",
    descriptions: "+七ğ五0 五0+{$<九) |8'<?十ş,5 9).六64;6六_[",
  },
  {
    codes: "      8七2^.;>.2六.42.四8",
    descriptions: ",=1九二47+",
  },
  {
    codes: "        ",
    descriptions: "十八ğ六,|8八&",
  },
  {
    codes: "        ><9).^:.五/.}3.三二",
    descriptions: ".一.'| ?/[+二三<一]\\[三(一 %ü: *四 十?2二^ <ç }?)]#|,\"ı九ı' ;[{七$十71 %<四六:5ş4八",
  },
  {
    codes: " ",
    descriptions: "          ;^3四*四三{|七$ {;\\4?(9",
  },
  {
    codes: "        六9九*.(@./).0@.(2",
    descriptions: "*{七一^]27, )6497 ?& 2'_5#_ .@[0|*? <9@2^四940{, @[@25,=",
  },
  {
    codes: "        517\".)5.九:.>6.5]",
    descriptions: "3\"ğ0*9*(3",
  },
  {
    codes: "    }<,三.1_",
    descriptions: "4ü)/\") 八2/\\ \"5;$ $;$]%: 'ı856六ı&71@",
  },
  {
    codes: "      ,>}七..7.8:.\"@.三]",
    descriptions: "'?三9?0+$十: ({六ı;\\{ı",
  },
  {
    codes: "      \";九四.46.八^.}9.}^",
    descriptions: ";]ğ5[04(六",
  },
  {
    codes: "    三)%@.\">.%{.'<.=5",
    descriptions: ".七^9四5三] =?/. .八.# '8^[七7{1 一;八$/六ş.] #$(+_$二'|%8",
  },
  {
    codes: "    (.九*.5一",
    descriptions: "0九ğ三+ '八%5$4九? 7<[(|'ş2\" 7|}{\"七/(三@5",
  },
  {
    codes: "          :[*7.^%.0..<<.(>",
    descriptions: ":].*> ],.]'>;二0七@<二{ >ü> ]( 十40<4 三ç 六)2?二8三9ı'ı* {{/%($\\; 九{8)\"%ş",
  },
  {
    codes: "        #/8>.>*.+%.>\\.=*",
    descriptions: "二[.? 0?$6$二(\\71: \"六一\\[>/#_",
  },
  {
    codes: "        七十\"*.三^.十@.7#.+四",
    descriptions: "&8二[:十]|",
  },
  {
    codes: "        5=/_.>,.2四.@'.;1",
    descriptions: "9一\"九)64], 6%一8九 @| 1八;|?九 @八:9$^{ [:+%九&,七\"]: 9'146?<",
  },
  {
    codes: "        1九^1.)/.八+.$6.,三",
    descriptions: "$.ğ'#([7]",
  },
  {
    codes: "  40十>.&}",
    descriptions: "64ğ=7*,(? (三六\" 二九七ı\\ı+'+ ($八9#&七)()一%ı四 1ş十' ^<}+^]$ 11;{2七1335)",
  },
  {
    codes: "    八+<{.(二.3;.5一.八[",
    descriptions: "1ü2\\=$ .五4: ,?@& ><)8四五 九ı3,&'ı二88一",
  },
  {
    codes: "      {九0八.五三.2八.二0.;7",
    descriptions: "*/193一,六八\"}8'九} _;一+0三四",
  },
  {
    codes: "      ;\\.{._<.9,.2@.\"四",
    descriptions: "32ğ?五??六2",
  },
  {
    codes: "    =*2三.八*.^}.$;.#/",
    descriptions: "[0ğ8/ 6>九3$1$' ::\"{二_ş=< 87七%@.五,%6三",
  },
  {
    codes: "    %:三十.1*.#二.十8.@*",
    descriptions: "?ü?+)> <?%十 ?3{) 3|\"#)\" 十ı五5&二ı;4#三",
  },
  {
    codes: "      };#>.{>.$+.0九.9十",
    descriptions: ".,_40/++7\"4/|_% 七2>]六六八",
  },
  {
    codes: "      }]#1.二,.$四.|(.;三",
    descriptions: "二2ğ6%九*)七",
  },
  {
    codes: "    %*2七.八*",
    descriptions: "一*ğ@# 一_>''._二 }/八&=五ş^( <>八=十24.五七8",
  },
  {
    codes: "        9>五八._..?*.>\\.+六",
    descriptions: "['.<) 7?3%.$/530\"二[一 \"ü9 六? 七?一<= 5ç *二三#.}}[ı5ı. (/8+'9六[ *,六\"},ş",
  },
  {
    codes: " ",
    descriptions: "        七七 &<十<}2三;*9( ,+3?,+; ",
  },
  {
    codes: "      六五;..#七.@>.八?.一8",
    descriptions: "5=4九七+1) @<.七=九2",
  },
  {
    codes: "      :$:1.\"3.6四.51.\\九",
    descriptions: "6/'(5|1二, |&&十| 5) 445+][ &405八[+ >82_三<)\"%4( 5^.7+}6",
  },
  {
    codes: "      五,四8.{@.$/.)<.*+",
    descriptions: "<五ğ四;一=21",
  },
  {
    codes: "2|.<二",
    descriptions: "}\"8ı6() 十8 [*/:$:#$ $6}&#\"ş]^ <九[=.374.八_ 三]ğ&^ }/? 一六(^九\"@|/(ı",
  },
  {
    codes: "  ^>;8.?,.,}._*.=^",
    descriptions: "&ü\\2$5 85;' /^&{ ##*三6: &ı<+十@ı'十{'",
  },
  {
    codes: "    )七[*.七七.*六.7二..7",
    descriptions: "Ç{0",
  },
  {
    codes: "    +三^2.&二",
    descriptions: "%*ğ9一=3=%",
  },
  {
    codes: "        $$\"..%三.<1.8[.[7",
    descriptions: "三&.&^{% (.ü|%\\{ { (1.{三 ==ç*2.)二6^,)",
  },
  {
    codes: "        :4九=.]%.\"&.=_.+十",
    descriptions: "&(ğ'%一4四5",
  },
  {
    codes: "        >8:{.*三..$.*十.'七",
    descriptions: "}\"二45[0 (六ü\"四<6 # 五'.|三 |9ç2#1>$三6|)",
  },
  {
    codes: "        &($:.\"一.@#.七八.]@",
    descriptions: "六?ğ],九^}7",
  },
  {
    codes: "    8{9}.七6.五>./).六\\",
    descriptions: "Ç'+",
  },
  {
    codes: "    '{:8.%^",
    descriptions: "08ğ??;八$二",
  },
  {
    codes: "        4=#%.=6.&8.#9.+%",
    descriptions: "@/[**.@ (@ü5七\\\\ 0 8[.二} #@ç(三(;四]6五)",
  },
  {
    codes: "        \\?6|.7>.?5.7十.94",
    descriptions: "9|ğ5$}*_,",
  },
  {
    codes: "        9\"九:.九6._五.十八.33",
    descriptions: "七}'七}^| (/ü@8四) < 十(.%; }]ç@(二%六:&6)",
  },
  {
    codes: "        1?]七.]5.&&.+<.<\\",
    descriptions: "7<ğ{4<[4^",
  },
  {
    codes: "  八3三*.*二",
    descriptions: "/:ğ七3 #1七九二94八 7:#>\\,ş*四 二九0@8;+3六三$",
  },
  {
    codes: "    $7$8.[二.(九.一1..|",
    descriptions: "8/0十*二& (\\ü2'九| 八 84.)# *@ç;三五二二四}@)",
  },
  {
    codes: "    6|26.一9.+=.:四.五1",
    descriptions: "十_ğ二=/,[]",
  },
  {
    codes: "'9.\\^",
    descriptions: "+:ç7?=4 #6_ı1+| '二 9四^\"]**\\ *3%@六]ş(, <:二八<0(4\"$七 \\十ğ六< :七四",
  },
  {
    codes: "  ^49六.0:.2一.4*.5_",
    descriptions: "^=>9六2' (十ü三(}\\ ?,4 .=.#三 |\\ç6]%}@^'=)",
  },
  {
    codes: "  |)<#.4_.4=.73.\"8",
    descriptions: "四6}(九九一 (*ü/?60 0,九 <1’十& +十ç<2 (十7>? 1 *|’四二 #六ç[?4+|九8二)",
  },
  {
    codes: "  $039.8五",
    descriptions: "($ğ(\\:&&五",
  },
  {
    codes: "    =十7^.'@.&}.五;.@\\",
    descriptions: "[5 [一_ı5ı #>\\#}1]",
  },
  {
    codes: "    |..九.@;.)9.$二.一$",
    descriptions: "二?ğ);=''3",
  },
  {
    codes: "九\"\\;.2,",
    descriptions: "&0ğ$] (#一ı2二: _[ ;._九一\"$? [四一9()ş<: ,@九73.二25四* 7}ğ)= 十:;",
  },
  {
    codes: "  /)|7.&十./).\"一.3二",
    descriptions: "]一)十<'六一 /+?} 九@3四 }|#六九2&六 /.64&[ş七? 6%.六<)|$0一:",
  },
  {
    codes: "  6\"八十.&三.十(.:>.;{",
    descriptions: "一1ğ十< {79:\\+\"一 八9_(6六ş十_ @%){\\%,五_五2",
  },
];


export default gtip;