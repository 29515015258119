import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Button from '@material-ui/core/Button';

import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from '@material-ui/icons/Group';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import BusinessIcon from '@material-ui/icons/Business';
import PaymentIcon from '@material-ui/icons/Payment';
import Forward5Icon from '@material-ui/icons/Forward5';

import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: "1em"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
}));

export default function ({ open, handleDrawerClose, handleSidebar }) {

  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{paper: classes.drawerPaper}}>

      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      
      <Divider />

      <div>
        <List>
          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="userList" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t(";>#'6 _四79")}/>
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="addAdmin" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>

              <ListItemText primary={t("二4[五七: 0九?3")} />
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="formList" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <SpeakerNotesIcon />
              </ListItemIcon>
              <ListItemText primary={t("^'7> /三三0一")} />
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="odooList" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <BusinessIcon />
              </ListItemIcon>
              <ListItemText primary={t("1{* 一^1七[ 115_")} />
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="statusCheck" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <Forward5Icon/>
              </ListItemIcon>
              <ListItemText primary={t("92_五;/ 5四1=")} />
            </Button>
          </ListItem>

          <ListItem button>
            <Button className="d-flex align-items-center side-button" value="paymentList" onClick={handleSidebar} disableRipple>
              <ListItemIcon>
                <PaymentIcon/>
              </ListItemIcon>
              <ListItemText primary={t("七3*']{+%")} />
            </Button>
          </ListItem>

        </List>

      </div>
    </Drawer>
  );
}
