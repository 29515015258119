const Missing = () => {
    return (
        <article style={{ padding: "100px" }}>
            <h1>二__9七60一!</h1>
            <p>二__9七6|70一.</p>
        </article>
    )
}

export default Missing;
