import React from 'react';

import { useNavigate } from "react-router-dom";
import { ReactComponent as FailIcon } from "../assets/svg/fail.svg";

import { handleLogout } from '../components/Logout.js';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

function Failure() {

    //routing components
    let navigate = useNavigate();

    return (
        <article style={{ padding: "100px" }}>
            <h1 className='text-center'>P二__9七70一😞</h1>
            <h3 className="text-center">P二__970一er.</h3>
            <div className="card-body">
                <FailIcon className="payment-icon" />
            </div>
            <button className="btn payment-btn"  type='button' rel="nofollow">二__970一 
                <DoubleArrowIcon className='ml-2'/>
            </button>
        </article>
    );

}

export default Failure;