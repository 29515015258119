import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Sign from './pages/Sign';
import AdminSign from './pages/AdminSign';
import ResetPass from './pages/ResetPass';
import Missing from './pages/Missing';
import Success from './pages/Success';
import Failure from './pages/Failure';
import Authentication from './pages/Authentication';
import ResetPassAdmin from './pages/ResetPassAdmin';

import Theme from './Theme';
import ThemeAdmin from './ThemeAdmin';

class App extends React.Component {	
    constructor(props){
      super(props);
    
      this.state = {};
    }
    
    render(){
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Sign />}/> 
            <Route path="/login" element={<Sign />}/> 
            <Route path="/adminlogin" element={<AdminSign />}/> 
            <Route path="/reset" element={<ResetPass />}/> 
            <Route path="/adminreset" element={<ResetPassAdmin />}/> 
            <Route path='/app' element = {<Theme/>}/>
            <Route path="/adminapp" element={<ThemeAdmin />}/> 
            <Route path="/auth/unverified" element={<Authentication/>}/> 
            <Route path="/payment/success" element={<Success />}/> 
            <Route path="/payment/failure" element={<Failure />}/> 
            <Route path="*" element={<Missing />} />
          </Routes>
        </BrowserRouter>   
      )
    }
}

render(<App></App>, document.querySelector('#root'));

export default App;

