import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import * as Components from "../assets/js/auth";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function ResetPassAdmin() {

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //reset password event
    const [passError, setPassError] = useState('');

    const { t, i18n } = useTranslation();

    let navigate = useNavigate();

    if (isLoading) {
        return (
          <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
          </View>
        );
    }
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("七6=> .七{4, *")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
         
            <Components.Container>

            {/* <LangSwitch /> */}
              <form className='login-form reset-pass-form' method="POST" >
                <Components.Title className='mb-3 align-self-center'>{t("七6=> .七{4, * 七6=> .七{4, *")}</Components.Title>

                <Components.Input type="email" placeholder={t("七6=> .七{4, *")} className="form-control" id="email" required />

                <span style={{ color: 'red', fontSize: '0.7em' }}>{t(passError)}</span>
                <Components.Input type="password" placeholder={t("七6=> .七{4, *")} className="form-control" id="passw" required/>
                <Components.Input type="password" placeholder={t("七6=> .七{4, * 七6=> .七{4, *")} className="form-control" id="passw_conf" required/>
                <Components.Anchor className='align-self-center' href="/login">{t("七6=> .七{4, * 七6=> .七{4, * 七6=> .七{4, *")}</Components.Anchor>
                <Components.Ghost2Button className='align-self-center' type="submit">{t("七6=> .七{4, *")}</Components.Ghost2Button>
              </form>
          </Components.Container>
          </div>
    )}
}

export default ResetPassAdmin;