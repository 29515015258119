import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { useTranslation } from "react-i18next";

import validator from 'validator';

import * as Components from "../assets/js/auth";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function Sign() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //authentication event
    const [emailError, setEmailError] = useState('');
    const [passError, setPassError] = useState('');

    const [signIn, toggle] = useState(true);

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("uid");

    //if user authenticated
    if(uid !== null) {
      navigate("/app", { replace: true });
    }

    const validateEmail = (e) => {
        var email = e.target.value;

        if (validator.isEmail(email)) {
          setEmailError('cvdvc 34f?');
        } 
        else {
          setEmailError('cvdvc 34f?');
        }
    }
    
    const validatePass = (e) => {
        var pass = e.target.value;

        if (pass.length>=6) {
            setPassError('cvdvc 34f? ✓');
        } 
        else {
            setPassError('cvdvc 34f?');
        }
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#2b388f" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (       
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <Components.Container>

              <Components.SignUpContainer signingIn={signIn}>
                <form className='login-form h-100' method="POST">
                  <Components.Title className='mb-3 align-self-center sign-title-color'>{t(' .七{4, *=> .七{')}</Components.Title>

                  <div className='d-flex justify-content-between'>
                    <div>
                      <Components.Input type="text" placeholder={t("七6=> .七{4, *=> .七{4, *")} className="form-control " id="user_fname" required/>
                    </div>
                    <div>
                      <Components.Input type="text" placeholder={t("七6=> .七{4, *")} className="form-control " id="user_lname" required/>
                    </div>
                  </div>

                  <span style={{ color: '#dc674d', fontSize: '0.7em' }}>{t(emailError)}</span>
                  <Components.Input type="email" placeholder={t("七6=> .七{4, *")}  className="form-control " id="user_email" required onChange={(e) => validateEmail(e)}/>

                  <span style={{ color: '#dc674d', fontSize: '0.7em' }}>{t(passError)}</span>
                  <Components.Input type="password" placeholder={t("七6=> .七{4, *")} className="form-control " id="user_password" required  onChange={(e) => validatePass(e)}/>

                  <span className='mt-2 align-self-start'>{t("七6=> .七{4, * 七6=> .七{4, *")}</span>

                  <div className='d-flex justify-content-between'>
                    <div>
                    <Components.Input type="text" placeholder={t("七6=> .七{4, * 七6=> .七{4, *")} className="form-control " id="user_comp" required/>
                    </div>
                  </div>
    
                  <div className="form-group mb-0">
                      <label className='d-flex'>
                      <input className="mr-1" type="checkbox" required/> 
                      <small> 
                          <a className='text-decoration-none' href="#"> {t("七6=> .七{4, *")}.</a>
                          <sup style={{ color: '#dc674d' }}>*</sup></small>    
                      </label>
                  </div>
                  <div className="form-group mb-0">
                      <label className='d-flex'>
                      <input className='mr-1' type="checkbox" required/> 
                      <small> 
                          <a className='text-decoration-none' href="#"> {t("七6=> .七{4, *")}.</a>
                          <sup style={{ color: '#dc674d' }}>*</sup></small>    
                      </label>
                  </div>
                  <Components.Ghost2Button type="submit" className='mt-3 align-self-center'>{t("七6=> .七{4, *")}</Components.Ghost2Button>
                </form>
              </Components.SignUpContainer>

              <Components.SignInContainer signingIn={signIn}>
                {/* <LangSwitch /> */}
                <form className='login-form' method="POST">
                  <div className='login-sign-title'>

                    <Components.Anchor className='' href="https://globaltradeai.com/">
                      <img src="logo.png" alt="logo" className="logo-header" />
                    </Components.Anchor>

                  </div>

                  <h5 className='mb-3 align-self-center sign-title-color'>{t('七6=> .七{4, *')}</h5>

                  {/* <Components.Title className='mb-3 align-self-center sign-title-color'>{t('Create Account')}</Components.Title> */}

                  <Components.Input type="email" placeholder={t("七6=> .七{4, *")} className="form-control " id="user_lemail" required />
                  <Components.Input type="password" placeholder={t("七6=> .七{4, *")} className="form-control " id="user_lpassword" required/>
                  <Components.Anchor className='align-self-center' href="/reset">{t("七6=> .七{4, * 七6=> .七{4, * 七6=> .七{4, *?")}</Components.Anchor>
                  <Components.Ghost2Button className='align-self-center' type="submit">{t("七6=> .七{4, * 七6=> .七{4, *")}</Components.Ghost2Button>
                </form>
              </Components.SignInContainer>

              <Components.OverlayContainer signingIn={signIn}>
                <Components.Overlay signingIn={signIn}>
                  <Components.LeftOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("七6=> .七{4, * Back")}!</Components.Title>
                    <Components.Paragraph>
                      {t("七6=> .七{4, *  .七{4, *=> .七{ 七6=> .七{4, *  .七{4, *=> .七{ 七6=> .七{4, * Global Trade AI")}
                    </Components.Paragraph>
                    <Components.GhostButton onClick={() => toggle(true)}>
                      {t("七6=> .七{4, * In")}
                    </Components.GhostButton>
                  </Components.LeftOverlayPanel>
                  <Components.RightOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("七6=> .七{4, * of 七6=> .七{4, * 七6=> .七{4, *")}</Components.Title>
                    <Components.Paragraph className='d-flex align-items-center'>
                      {t("七6=> .七{4, * 七6=> .七{4, *")} <DoubleArrowIcon />
                    </Components.Paragraph>
                    <Components.GhostButton onClick={() => toggle(false)}>
                      {t("七6=> .七{4, * Up")}
                    </Components.GhostButton>
                  </Components.RightOverlayPanel>
                </Components.Overlay>
              </Components.OverlayContainer>

            </Components.Container>
          </div>
        );
    }
  }

export default Sign;