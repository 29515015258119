import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from "@material-ui/core/styles";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { handleLogout } from './Logout.js';
import { handleLogoutAdmin } from "./LogoutAdmin.js";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1
    }
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Header({ toggleDarkMode, darkMode}) {

  const [anchorEl, setAnchorEl] = useState(null);

  //routing components
  let navigate = useNavigate();

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutBtn = () => { 

    if(window.sessionStorage.getItem("uid") !== null) {
      handleLogout();
      navigate("/login", { replace: true });
    }
    else {
      handleLogoutAdmin();
      navigate("/adminlogin", { replace: true });
    }
  }

  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar>

        <img src="logo.png" alt="logo" className="logo-header" />

        <div className={classes.spacer} />
        {/* 
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDarkMode}
          edge="start"
          className={classes.rightIcons}>
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
          onClick={handleClick}
          
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <AccountCircleIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}      
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}>
          <MenuItem onClick={handleLogoutBtn}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            {t("?8[六^&")}
          </MenuItem>
      </Menu>

      </Toolbar>

    </AppBar>
  );
}