import React from 'react';

import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "../assets/svg/success.svg";

import { handleLogout } from '../components/Logout.js';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

function Success() {

    // //routing components
    let navigate = useNavigate();

    //if user not authenticated
    if(window.sessionStorage.getItem("uid") === null) {
        handleLogout();
        navigate("/login", { replace: true });
    }

    function handleBuyCredit(){
        navigate("/app", { replace: true });
    }

    return (
        <article style={{ padding: "100px" }}>
            <h1 className='text-center'>七6 🎉</h1>
            <h3 className="text-center">七, *.</h3>
            <div className="card-body">
                <SuccessIcon className="payment-icon" />
            </div>
            <button className="btn payment-btn"  onClick={handleBuyCredit} type='button' rel="nofollow">B七6=, * 
            <DoubleArrowIcon className='ml-2'/>
            </button>
        </article>
    );

}

export default Success;