const sic = [
  {
    codes: 111,
    descriptions: "七_>22",
  },
  {
    codes: 112,
    descriptions: "二523",
  },
  {
    codes: 115,
    descriptions: "4,四十",
  },
  {
    codes: 116,
    descriptions: "@96四,'四=",
  },
  {
    codes: 119,
    descriptions: "3(=\" 70@=\\,, 7$一",
  },
  {
    codes: 131,
    descriptions: "七=_,六九",
  },
  {
    codes: 132,
    descriptions: "[^#3^四$",
  },
  {
    codes: 133,
    descriptions: "*{\\十二#:,^ 四70 三九&五) =八>^6",
  },
  {
    codes: 134,
    descriptions: "{[[2五 ^2&四5四4]",
  },
  {
    codes: 139,
    descriptions: "90.|6 .三\\#九, 六五+)9} \"9@< %15七*",
  },
  {
    codes: 161,
    descriptions: "/1:6.(:/*七 7#4 [);0#0",
  },
  {
    codes: 171,
    descriptions: "?]\"二9 七_<(:",
  },
  {
    codes: 172,
    descriptions: "@;5)1*",
  },
  {
    codes: 173,
    descriptions: "三@2( 二)8七",
  },
  {
    codes: 174,
    descriptions: ")35]六} {8@^7%",
  },
  {
    codes: 175,
    descriptions: "@6>_$四=\\> )一9# 8)7%4&",
  },
  {
    codes: 179,
    descriptions: "//,}.3 }#\" $96@ 八九{十, ^四<",
  },
  {
    codes: 181,
    descriptions: "四@*^%^#%[& 47}$4#_ *_}六{&三*",
  },
  {
    codes: 182,
    descriptions: "/6.# }|$\\\" ,*,九/ [六;6^ (96*4",
  },
  {
    codes: 191,
    descriptions: "?\"#二二[4 {<.(,, &',;十&\\15 56五>",
  },
  {
    codes: 211,
    descriptions: ":15六 8:六03四 \"20.七3九+",
  },
  {
    codes: 212,
    descriptions: "\\%:^ \\='%\\4, [[六>=十 [&{\\\"四&+",
  },
  {
    codes: 213,
    descriptions: "_]九,",
  },
  {
    codes: 214,
    descriptions: "1|2_/ 2,* =*[>八",
  },
  {
    codes: 219,
    descriptions: ":164(5_ [{^'\"'六8#, 九02",
  },
  {
    codes: 241,
    descriptions: "十,,六* 七4+;五",
  },
  {
    codes: 251,
    descriptions: "&@7){]1, 八\\9一八, ,#六 =;)九4&十 @0^九,6:}",
  },
  {
    codes: 252,
    descriptions: "九九&_3|} 1(四三",
  },
  {
    codes: 253,
    descriptions: "_4/.65{ 四#? (}0}76 $}9$",
  },
  {
    codes: 254,
    descriptions: "&1四7$8/ 4^二二3=二\\8<",
  },
  {
    codes: 259,
    descriptions: "二{)@}&) 七68 ,四9三, |]^",
  },
  {
    codes: 271,
    descriptions: "%六4-}#一1%>@ 三9|)九*% |六. [+=#;\\6",
  },
  {
    codes: 272,
    descriptions: "\\<四+七( 六一3 =^%87 ;>*'&一三",
  },
  {
    codes: 273,
    descriptions: "(}四_#七 &\"&6,@1?;'{",
  },
  {
    codes: 279,
    descriptions: "])一+3( 99二}[|'三七[$, \"十0",
  },
  {
    codes: 291,
    descriptions: "?四.*|9/ ^七^*8, 十.0\"(>\\(4 '3>二*7二",
  },
  {
    codes: 711,
    descriptions: "<二2四 '_\\%##?9@\\& +]%一六5一#",
  },
  {
    codes: 721,
    descriptions: "7九一, @?@%1六#% 十*一 <九(\\6五#1三|",
  },
  {
    codes: 722,
    descriptions: "$四五} 1&,$@^#七四一",
  },
  {
    codes: 723,
    descriptions: "|^1\" }9九);30/七8# @九_=,,五四 (}< 二{@八\\'",
  },
  {
    codes: 724,
    descriptions: "&@+七]3 十七\\/61=",
  },
  {
    codes: 741,
    descriptions: "九}/7二=&_=0 \\:[<&#?7 >5; {,5_043\"|",
  },
  {
    codes: 742,
    descriptions: "^$'六0{七^^六 ,八@[八九三一, }8*一<\\1>+2五",
  },
  {
    codes: 751,
    descriptions: "{<{^二}八9# :*二@\\*$\", 一七(八一; 60:'一1>九9>",
  },
  {
    codes: 752,
    descriptions: "三:&_.六 1八6,^$三69 ,)四六|8($",
  },
  {
    codes: 761,
    descriptions: "\"61\" {}.>二 3<%)五四8=?'一",
  },
  {
    codes: 762,
    descriptions: "9<^4 $^\\}{?+,2+ /十7五@:^)",
  },
  {
    codes: 781,
    descriptions: "九)+\"|)6\\& ?\"=#_+>八.6 九二, 8$(___?(",
  },
  {
    codes: 782,
    descriptions: "三3@_ 54九 ^一$4十三 $2五一一一&'",
  },
  {
    codes: 783,
    descriptions: "[81八,}2\")@ 七6?}[ +八# 十>_, 九06七8%6>",
  },
  {
    codes: 811,
    descriptions: ")4,}5$ =|十7+@",
  },
  {
    codes: 831,
    descriptions: "<(<*/| 十五/:#6_五",
  },
  {
    codes: 851,
    descriptions: "#*[0{[|_ 8:49',1[",
  },
  {
    codes: 912,
    descriptions: "\">'/三八五",
  },
  {
    codes: 913,
    descriptions: "0?十四十\\.8四",
  },
  {
    codes: 919,
    descriptions: "五<'$^(\".{:+一1 8:=_九0 二%_*6'@]",
  },
  {
    codes: 921,
    descriptions: ",05| (_}八\"_十}@= #/{ ./,_七@6.+",
  },
  {
    codes: 971,
    descriptions: "1%={}九十, <一}二$8?2, /三九| <一{(四九@,@(,",
  },
  {
    codes: 1011,
    descriptions: "三}([ 6#>#",
  },
  {
    codes: 1021,
    descriptions: "(/]三*五 @6'@",
  },
  {
    codes: 1031,
    descriptions: "*=:7 2+. \\(|8 四\"8九",
  },
  {
    codes: 1041,
    descriptions: "&}六6 /,八;",
  },
  {
    codes: 1044,
    descriptions: ".)六一五/ .*}=",
  },
  {
    codes: 1061,
    descriptions: "$*3二=${6&8 8二?3, 4六#_*@ 3十>(.#5'",
  },
  {
    codes: 1081,
    descriptions: "五5^六= 9[六八,7 ]=三&九7*]",
  },
  {
    codes: 1094,
    descriptions: "/八#{)+2-.二2{^/-(>^5六|.? \"]八)",
  },
  {
    codes: 1099,
    descriptions: "六){五^ \"*+?, 六({",
  },
  {
    codes: 1221,
    descriptions: "一58+3})^)6 七十)五 >9十 }9*|九\":-;五@二1&# =8,[<]",
  },
  {
    codes: 1222,
    descriptions: "+<五^三八五;6/ 1一5)-$21}4四八5%十= 十&{八十6",
  },
  {
    codes: 1231,
    descriptions: "#)三02十八0九. 4)九;:八",
  },
  {
    codes: 1241,
    descriptions: ",&\"一 2<{三{2 >0\"(6@%四",
  },
  {
    codes: 1311,
    descriptions: "4_<(# ^>{二>1')6 |,} \\'.'二=/ (|\"",
  },
  {
    codes: 1321,
    descriptions: "}\\^%&\"四 一)+ 747九4$}",
  },
  {
    codes: 1381,
    descriptions: "3=一2七:1\" 四]9 五[& [%* 六@八57",
  },
  {
    codes: 1382,
    descriptions: "7}> 9=7 四_] 四五[7十{@8,\\& }{^3.4;{",
  },
  {
    codes: 1389,
    descriptions: "%五九 2\\五 @%三 [^':8 \\|?46_'5, @四&",
  },
  {
    codes: 1411,
    descriptions: "$5四:\\?9^' #)86)",
  },
  {
    codes: 1422,
    descriptions: ",+_九,(九 >%> \\四20&? %<五#+七七\\一",
  },
  {
    codes: 1423,
    descriptions: "=,&>89@ '$. 7@%0>3 +,(一)二)",
  },
  {
    codes: 1429,
    descriptions: "\\[21)[. (5九 44/九,1 八|{2?, ];6",
  },
  {
    codes: 1442,
    descriptions: "^三&_=;$'+#<一 4|#% 四}# ?三/六#\\",
  },
  {
    codes: 1446,
    descriptions: "三(/十[63@#{ 03}/",
  },
  {
    codes: 1455,
    descriptions: "九<+[\\三 =4二 ,\"&一 \"#四=",
  },
  {
    codes: 1459,
    descriptions: ",,.{ 五#二 53|七九5> 四4'9?1$6, 9_5",
  },
  {
    codes: 1474,
    descriptions: "五六四\"2十, ;@00, 十十9 @4[三\"六 十#{'7;]3",
  },
  {
    codes: 1475,
    descriptions: "_五]三7六:>八 7:[$",
  },
  {
    codes: 1479,
    descriptions: "二五{^:十十} 4四9 五)_0\"_*7\\_ #)*<三?",
  },
  {
    codes: 1481,
    descriptions: "0+2,(88:,?^ 七4*([]2 五+十四04:?",
  },
  {
    codes: 1499,
    descriptions: "@9}3=3=5??八8? \"@%三1{\"5|+} '*九八[;",
  },
  {
    codes: 1521,
    descriptions: "$^<四3{-@十}8<& [6\\.九&= <#六>四(>2&\"^(",
  },
  {
    codes: 1522,
    descriptions: ",}+}_3.*)&^ <二:十)3561六四7, ?)1",
  },
  {
    codes: 1531,
    descriptions: "1#5^二^三^_ 七7?}<二9;",
  },
  {
    codes: 1541,
    descriptions: "九|4七3;#六7: 五'@5#5九&| 25九 9<[一}%.?三5",
  },
  {
    codes: 1542,
    descriptions: "一9>&2{(一=一[:十< 1五^9=6九})?六五, {九三",
  },
  {
    codes: 1611,
    descriptions: "#{.*<1: _;@ ;八1二,( 二(65#.>九六二<4",
  },
  {
    codes: 1622,
    descriptions: "8;六八八?, 七_四七8&, 9'7 四=3一:$^[ <二?@五一:",
  },
  {
    codes: 1623,
    descriptions: "八,*|%, 8177二, 五(六 =03=二\\_ [?&)九",
  },
  {
    codes: 1629,
    descriptions: ":10八$ |$1=^#七0(八三{, 二_五",
  },
  {
    codes: 1711,
    descriptions: "}二077七)二, ($\"};\",, #8九-一二%六\"=7|四3四.",
  },
  {
    codes: 1721,
    descriptions: ")>\"6^%'1 +\\六 \"&/)2 *.7</'}",
  },
  {
    codes: 1731,
    descriptions: "5#\"*]=^九)< '&@\"",
  },
  {
    codes: 1741,
    descriptions: "{四,||=' >>三 /.5?; 7八,$6)$5'",
  },
  {
    codes: 1742,
    descriptions: "3(五3/>8\\^?, >二;|.6:, 8<8 0六(八七'?]]九",
  },
  {
    codes: 1743,
    descriptions: "&2<^8=;7, 六0'三, 五=>$二#, 七*\"9'> @5#;",
  },
  {
    codes: 1751,
    descriptions: "?'69^[@\"+ .{]>",
  },
  {
    codes: 1752,
    descriptions: "/八94: _(一一?, \\八0 [26九} _\"5/, \"?>",
  },
  {
    codes: 1761,
    descriptions: "7?\"(<九1, 3[{_12, \\6( 1#\"18]\":|+ 十].8",
  },
  {
    codes: 1771,
    descriptions: "5\\四五[一04 759二",
  },
  {
    codes: 1781,
    descriptions: "]742. 5七96 [+九九三六十,",
  },
  {
    codes: 1791,
    descriptions: "1,2#\"635@九 七1}]{ 八%六)11;3",
  },
  {
    codes: 1793,
    descriptions: "(_七:2 8{? '/)一.63 4=&\\",
  },
  {
    codes: 1794,
    descriptions: "]%七$<5<^#| 三>.|",
  },
  {
    codes: 1795,
    descriptions: "十;八#9二八四 五9/ @,.二3$九,+2 一')一",
  },
  {
    codes: 1796,
    descriptions: "四'二\\)九一0@, )九:_$?3* [5:7;'&+]",
  },
  {
    codes: 1799,
    descriptions: "\">\\|&&[ $八[15 六=8?'\\,十八三;, |%%",
  },
  {
    codes: 2011,
    descriptions: ",三七\\ \"四><$%) @七>41,",
  },
  {
    codes: 2013,
    descriptions: "\\72五3/_} /0/ +.)}/ (3五7)6== *{+*=",
  },
  {
    codes: 2015,
    descriptions: "十(,=7+六 _<05]@(3%=%7 3%} %3))]<#四=@",
  },
  {
    codes: 2021,
    descriptions: "/{,/4,,{ 74四|.0",
  },
  {
    codes: 2022,
    descriptions: "7+6]九9; 七十五八_六: \\^? @]]^一,1=^",
  },
  {
    codes: 2023,
    descriptions: "^42, 4}九二|3?二3, (二?]+三(二^8 ;十$四}]|:",
  },
  {
    codes: 2024,
    descriptions: "[^) +5三4\" 99三 十<?=%$ =二>7七七;",
  },
  {
    codes: 2026,
    descriptions: "/9/\"2 #五&>",
  },
  {
    codes: 2032,
    descriptions: "61五,@^ _9五一四三,^%八一",
  },
  {
    codes: 2033,
    descriptions: ";2$4.> 九)%**五 8%' ,/77@}七八##/",
  },
  {
    codes: 2034,
    descriptions: "+)##087/1} .[,)%5, 二十},35%{二五, 二9?=$",
  },
  {
    codes: 2035,
    descriptions: "?/:]&|\\, 七019四*, @17 一三}18 一31%$>2&:",
  },
  {
    codes: 2037,
    descriptions: ")342九< _&)30@ 七1{ 六=六三#{八;:3",
  },
  {
    codes: 2038,
    descriptions: "0+|]*= 5()}四=%5%*%, +十&",
  },
  {
    codes: 2041,
    descriptions: "/}9#) 六三: %#<\\\" ?+/[. =)=八 @0}.9\\+二",
  },
  {
    codes: 2043,
    descriptions: "\\\"九八二十 5(五[:@四9\" &\\七二(",
  },
  {
    codes: 2044,
    descriptions: "+五*[ |:\"<$81",
  },
  {
    codes: 2045,
    descriptions: "三({\\$四\\^ 二\"八.> 7八三:] =<九 5+三=*七",
  },
  {
    codes: 2046,
    descriptions: ";6} +2七1 十%一六九#]",
  },
  {
    codes: 2047,
    descriptions: ")*# 32\\ )?% 九]?,",
  },
  {
    codes: 2048,
    descriptions: "十42(&?/# &5}(&, 八+二",
  },
  {
    codes: 2051,
    descriptions: "^十四}三, _{8,, \"}三 八8&=79二 745.二:#二",
  },
  {
    codes: 2052,
    descriptions: "|@%](39 .@# :_=,4?&三",
  },
  {
    codes: 2053,
    descriptions: "9+四四^< 三九3九%] 一8$3]六2*, 2二六)3六 7#\\六{",
  },
  {
    codes: 2061,
    descriptions: "八3: \".#\\ );三五十",
  },
  {
    codes: 2062,
    descriptions: "五一七0 \"32\\^ ?353\"_九9",
  },
  {
    codes: 2063,
    descriptions: "五7[+ :6十+一",
  },
  {
    codes: 2064,
    descriptions: "+1一|= :*7 一(^0一 九}||_七8*$|+八七 [('5?*|六",
  },
  {
    codes: 2066,
    descriptions: "$&\")|>3/@ >,五 2@_\"^ 1^4]\"{{\"",
  },
  {
    codes: 2067,
    descriptions: "]%(九+4( 33;",
  },
  {
    codes: 2068,
    descriptions: "七七>00五 (三9 7一&+<31 六八0二 +十] [九四{2",
  },
  {
    codes: 2074,
    descriptions: "十八8(<|874{ 109 一+<}}",
  },
  {
    codes: 2075,
    descriptions: "\"十.一1七$ *八) 七二:<1",
  },
  {
    codes: 2076,
    descriptions: "&2$[@=*'* :.[ 9}五九,, 2八^",
  },
  {
    codes: 2077,
    descriptions: "九425_八 $\"八 %2一,六@ ^/8三 _<[ 七@三/",
  },
  {
    codes: 2079,
    descriptions: "三八4八8* ?:十二 五%% {/七{",
  },
  {
    codes: 2082,
    descriptions: ">6四? #.2+\\八^六\\",
  },
  {
    codes: 2083,
    descriptions: "四\\#\"",
  },
  {
    codes: 2084,
    descriptions: "'&四四&, 八,五$二}, ,:8 +)({_[ 十|0%)/\"",
  },
  {
    codes: 2085,
    descriptions: "一+八$^$;/五 \\$< \"二六[:'% 5&@六四九+",
  },
  {
    codes: 2086,
    descriptions: ":三?0七_四 九4四 0}0?*\" 一0八1 +&二?一*",
  },
  {
    codes: 2087,
    descriptions: "&8{\\04\\4* 三}九./*$% 0/七 :|\\六0], 4三#",
  },
  {
    codes: 2091,
    descriptions: "*@八*\"四 91] 六<二<; 三]&' _5/ #,6?=}42",
  },
  {
    codes: 2092,
    descriptions: "78一8? <4 [74\\>] @:三/{七+$ 七七_,",
  },
  {
    codes: 2095,
    descriptions: ":698九一$ 八[)五%@",
  },
  {
    codes: 2096,
    descriptions: ":三,?六/ [7?七^ $;{ 5十三\\?+( 八(\\9十<",
  },
  {
    codes: 2097,
    descriptions: "$[?,九'#四/六四三 (5二",
  },
  {
    codes: 2098,
    descriptions: "|]>十#九][ 四\\& 52>\"\"}+$二",
  },
  {
    codes: 2099,
    descriptions: "2九6% (?|&*:5'五;%(, /\\五",
  },
  {
    codes: 2111,
    descriptions: ",0+&九1四{54",
  },
  {
    codes: 2121,
    descriptions: "&['(<2",
  },
  {
    codes: 2131,
    descriptions: "三^>'1八= |<= 9$}],)= [77十#@*",
  },
  {
    codes: 2141,
    descriptions: "七\"一(7|' ^=\"二9\"一6 二$? |.^&七}.+",
  },
  {
    codes: 2211,
    descriptions: "6]#^\\三)*六> )6:04& /*;六1, 二8}八|*",
  },
  {
    codes: 2221,
    descriptions: "\\\"<五七>39%$ \"928.? 四717;, ]'三41;二",
  },
  {
    codes: 2231,
    descriptions: "81+7四'@^十八 =8*3?* 8/%七^, .<5>",
  },
  {
    codes: 2241,
    descriptions: "7)<&)_ ,?8=三? 6#1四6",
  },
  {
    codes: 2251,
    descriptions: "四四+三8'] ^<1三{6\", ,|'二=' %,];2",
  },
  {
    codes: 2252,
    descriptions: "四?.;4:\", 八'3",
  },
  {
    codes: 2253,
    descriptions: ".八}$ 十$五3^\"2[0 #%>}5",
  },
  {
    codes: 2254,
    descriptions: "34_8 <0=*]\"060 六十4四}",
  },
  {
    codes: 2257,
    descriptions: "五&2{ 七六)& 二七三.\"; =三(#&",
  },
  {
    codes: 2258,
    descriptions: ">@#? _// 一一#7 七一{八 2\\二十(| }=3^#",
  },
  {
    codes: 2259,
    descriptions: "[#\\;\",\"七 )?*.}, //十",
  },
  {
    codes: 2261,
    descriptions: ";:_二=62十? #+)@三7, 3}四4<九",
  },
  {
    codes: 2262,
    descriptions: "97四)/$1(1 \\*09|<, };;十:1+",
  },
  {
    codes: 2269,
    descriptions: ")?()61{*十 63^(}', 1二]",
  },
  {
    codes: 2273,
    descriptions: "[四#六七六^ &}六 8\\^\"",
  },
  {
    codes: 2281,
    descriptions: "九@三一 %_[>九<5\\ 3,0*\\",
  },
  {
    codes: 2282,
    descriptions: "=_'%:一^4 #8二 :&,&五五七 {'{8%",
  },
  {
    codes: 2284,
    descriptions: "@1%].八 :7+\"/",
  },
  {
    codes: 2295,
    descriptions: "3\">08八 5十?十4九4, 五9' /^.三九;=/={",
  },
  {
    codes: 2296,
    descriptions: "1,\"六 8}^1 ,9( %1七&六}五",
  },
  {
    codes: 2297,
    descriptions: "7六.52.十十 >\"\"]<十一",
  },
  {
    codes: 2298,
    descriptions: "<五/'%>[ ;{> 三;|[四",
  },
  {
    codes: 2299,
    descriptions: "_#九$#%& \"&8'9, 五\\7",
  },
  {
    codes: 2311,
    descriptions: ":\\五'. *), _%$') .六7四= <%< 3/_三%",
  },
  {
    codes: 2321,
    descriptions: "|&>'< +(9 \\(%'< 7一\"十2)4]=三$",
  },
  {
    codes: 2322,
    descriptions: ")九8'一 六一' &#;'/ 2.)#64})( }9] 3八\\85八九%}",
  },
  {
    codes: 2323,
    descriptions: "<[二'} \"/$ ]:二'; =+%+@/;七",
  },
  {
    codes: 2325,
    descriptions: "四89'$ :九; \"2$'( ,<?,.]=\\ )|一 ;8,%.&",
  },
  {
    codes: 2326,
    descriptions: "\",九'] 2六> 244'十 七|*6 /'&<@<_,",
  },
  {
    codes: 2329,
    descriptions: ";(*'@ )8十 2八四', 九二3十^十.7, 9@(",
  },
  {
    codes: 2331,
    descriptions: "\\十%九六', ]七十 9.<*^八' +[('{#/ 三^? ;4&七五0",
  },
  {
    codes: 2335,
    descriptions: ":9\\\"@'|, 7五[0{:'{, 九7_ 三0}};,' /三十7十1>",
  },
  {
    codes: 2337,
    descriptions: "五'+\\_'4 6三' 八7:=九;' ];5七; _6三 >=五二?",
  },
  {
    codes: 2339,
    descriptions: "[四一\"?'% }<; ?十]4+,' :六.^五+?@?, ]]1",
  },
  {
    codes: 2341,
    descriptions: "864五_'< }1六 9四三[##三]'| ?一?2=_十9]",
  },
  {
    codes: 2342,
    descriptions: "${\\\", 1,九十=三}, <33 5#}2*# /<2[^八五\"",
  },
  {
    codes: 2353,
    descriptions: ":.9}, 七+^$, @)_ (7\\')%六三6",
  },
  {
    codes: 2361,
    descriptions: "|4,='+ 十1, 七九*(#}}_'| /*>|+一四, 46{二\\\"'",
  },
  {
    codes: 2369,
    descriptions: "四$6]'? &4五 一{七@^\\一='二 }+2+一@;@八, \\0(",
  },
  {
    codes: 2371,
    descriptions: "57七 3:?九=",
  },
  {
    codes: 2381,
    descriptions: "^二[@6* #^@9] 4七' \\[\"\" &九八>三二",
  },
  {
    codes: 2384,
    descriptions: ";59(/ ;/< =\\四&=;^一 四<六&八",
  },
  {
    codes: 2385,
    descriptions: "^七#二=四&3.& |一,1六*0)一",
  },
  {
    codes: 2386,
    descriptions: "[1^5^{& 4六/ 1九((*-@=5{/ 五$+3\\{二+",
  },
  {
    codes: 2387,
    descriptions: "4'$'5|[ {5}'.",
  },
  {
    codes: 2389,
    descriptions: "7(^[@{{ ]>^ 0七(50.\\$_九(, {|+",
  },
  {
    codes: 2391,
    descriptions: "六|:#5\\@八 =九# 52)四{.&\\_",
  },
  {
    codes: 2392,
    descriptions: "+&九'十六四)$ 0=\\/]一56*.[, 一$;",
  },
  {
    codes: 2393,
    descriptions: "//$七;]^ 六&一1",
  },
  {
    codes: 2394,
    descriptions: ".$=@+$ >四八 0八5%7\\* 3:八二^九8&",
  },
  {
    codes: 2395,
    descriptions: "|.:五4)五六 ,十7 四#8$\"]61/",
  },
  {
    codes: 2396,
    descriptions: "[7一=}七8九%% }7$ 7\"1'*[$ =四11}五_二}",
  },
  {
    codes: 2397,
    descriptions: "}[+九\"*9' @{)*一$_ 6一:$)八(&2三<\"",
  },
  {
    codes: 2399,
    descriptions: "5%6<^|三}|七 7'/1|/7 @3>五二九六九, {+[",
  },
  {
    codes: 2411,
    descriptions: "_)/?>$6",
  },
  {
    codes: 2421,
    descriptions: "53一九%4二^ \"&8 十]3'六七^ \";十^(, 八83五{8@",
  },
  {
    codes: 2426,
    descriptions: "}..六>\\七' '+.七01三0+ =}6 4>6.十4{> 八十一^}",
  },
  {
    codes: 2429,
    descriptions: "八(一.五6: 五三四1\"$, .八&6>/4], \\<|",
  },
  {
    codes: 2431,
    descriptions: "2.六|[&&\"",
  },
  {
    codes: 2434,
    descriptions: "=六2. 二#'1#三4 \\{1|@?三=",
  },
  {
    codes: 2435,
    descriptions: "|@=]0.(0 ;=二5=, 9]七 ?八\"^5,3",
  },
  {
    codes: 2436,
    descriptions: "97/三9>\"\\ </#7>$ ?五: ,#{51#%",
  },
  {
    codes: 2439,
    descriptions: "*7十八26十7二9 '五(2 [*.,&_\", 六21",
  },
  {
    codes: 2441,
    descriptions: "+|7=:\" @4^5 ,#&?< <_; ?八$$#",
  },
  {
    codes: 2448,
    descriptions: "一一,/ ,/;%=&二 }$} 六@\\<1",
  },
  {
    codes: 2449,
    descriptions: "?六+. :8}**%<一$], ^5'",
  },
  {
    codes: 2451,
    descriptions: "8).$|_ 3;1_)",
  },
  {
    codes: 2452,
    descriptions: "/7{.&'1^六%6一8 >%二% 三八'1_7@*\"",
  },
  {
    codes: 2491,
    descriptions: "一#[@ 一{'六:$二=二7",
  },
  {
    codes: 2493,
    descriptions: "2\\4&:一1'八十=/二 二_@八 848}<2.#",
  },
  {
    codes: 2499,
    descriptions: ")&5( |)十??197, +0@",
  },
  {
    codes: 2511,
    descriptions: "1]\\6 ;5/_#+>$* {4%(二七&1?",
  },
  {
    codes: 2512,
    descriptions: "9:.|三四69*:: ?7_%2十[6* #7}|四\"|^=",
  },
  {
    codes: 2514,
    descriptions: "五七*0> @+十_2五8$\\ *%?七+七四|=",
  },
  {
    codes: 2515,
    descriptions: "4六1五<5*二}. 九{七 <8三34|.]%>",
  },
  {
    codes: 2517,
    descriptions: "二)七| |>,6四4.+]_ 6六* 1=/三) %2\\5=={+",
  },
  {
    codes: 2519,
    descriptions: "+0^六一3[四1 4@+\".6%=_, #93",
  },
  {
    codes: 2521,
    descriptions: "%5三, 1+=3== >{五174六二*",
  },
  {
    codes: 2522,
    descriptions: "2}]八?' 4_)2五3|]九, 8,]:^四 (]/1",
  },
  {
    codes: 2531,
    descriptions: ")4^].> =6二3'6]) '*_ 四//.,+& 2十<0;.}7]",
  },
  {
    codes: 2541,
    descriptions: "六\"|八 |53[.924十: ^*& 一八九$\\4#8",
  },
  {
    codes: 2542,
    descriptions: ")7*1%'8]$} ^64 3;七?&8十十, 2?0|1一 #\\_=",
  },
  {
    codes: 2591,
    descriptions: "9>,&9+四 5_>'6%|& )%6 ]+@;一; {#$ 七+..27",
  },
  {
    codes: 2599,
    descriptions: "七\"九七二九{$六 七八; 3^*\\//@三, /)@",
  },
  {
    codes: 2611,
    descriptions: ">${. 四四(54",
  },
  {
    codes: 2621,
    descriptions: "82&{六 0#八)'",
  },
  {
    codes: 2631,
    descriptions: "一八)?一]|六0' +3(7三",
  },
  {
    codes: 2652,
    descriptions: "4十&五十 ='>>{{八;}\\ 6'7九0",
  },
  {
    codes: 2653,
    descriptions: "]^>四33六79, 207 [>?*[ 九}63二 #:|{一",
  },
  {
    codes: 2655,
    descriptions: "一4二19 :;九:, /+16;, 一\"6 )\"(>_3七 6}(;.六#>",
  },
  {
    codes: 2656,
    descriptions: "@/,62.=3 五%1) ]九}1*<八#\\五",
  },
  {
    codes: 2657,
    descriptions: "2四1^,^二 七6][<8._九_ .五九|*",
  },
  {
    codes: 2671,
    descriptions: "]._(6; 3一九十)七 }3] &4二\"(八二{& ,/?1=三9*_",
  },
  {
    codes: 2672,
    descriptions: "$=+三3; ,2/八#? |:2 #六四&四*六3%, 八五8",
  },
  {
    codes: 2673,
    descriptions: "八六二九: \"四三三]四6, [三}0$3011, #)@ 8)9八&<",
  },
  {
    codes: 2674,
    descriptions: "92@?: )\"?^五&)* 7十九\"% 6\"9 #十一^;+7十,",
  },
  {
    codes: 2675,
    descriptions: "八\\2-#[< 5+|33 9|9 \"05>$",
  },
  {
    codes: 2676,
    descriptions: ":[=三,4,[ ={|4六 一一1+:/|2",
  },
  {
    codes: 2677,
    descriptions: "&$四+38[=7",
  },
  {
    codes: 2678,
    descriptions: "九()>九五.4[= ?%$0>5}=",
  },
  {
    codes: 2679,
    descriptions: "{四#,_/8七# >=七12 4<66@{五2, 四\\七",
  },
  {
    codes: 2711,
    descriptions: "&6:/0]$0_%",
  },
  {
    codes: 2721,
    descriptions: "/<二)13>7*四&",
  },
  {
    codes: 2731,
    descriptions: "7_}^ \"(<;7]5三93",
  },
  {
    codes: 2732,
    descriptions: ",|,4 ;九[四五,;\\",
  },
  {
    codes: 2741,
    descriptions: "二^((&一)二8[6#六 六一61二一十'八/",
  },
  {
    codes: 2752,
    descriptions: "95,}8七8六3+ }$*2^\"77, &七/(/]1\"^3四,",
  },
  {
    codes: 2754,
    descriptions: "@_<']49\"\"* *)?6&七,>, *七9_2三%",
  },
  {
    codes: 2759,
    descriptions: "]六#8五九<?*^ <[]]0\"?七, 二&_",
  },
  {
    codes: 2761,
    descriptions: "/.)4;三_& 5[)七[')一 (?九=2",
  },
  {
    codes: 2771,
    descriptions: "\\七四6.>六$ )八|@>",
  },
  {
    codes: 2782,
    descriptions: "(一85八一\"[六? 九8( \"\"六#>*9[} _1二;十七%",
  },
  {
    codes: 2789,
    descriptions: "二九六_?6%3#十^ \\)( ^:四二}[5 ^:#,",
  },
  {
    codes: 2791,
    descriptions: "?_二@(4三六:九}",
  },
  {
    codes: 2796,
    descriptions: "$]三十八*|7七\\七 /三0\\};+7",
  },
  {
    codes: 2812,
    descriptions: "\"3/@{八\\& 4?0 9,3五:十\\'",
  },
  {
    codes: 2813,
    descriptions: "_;四$十六.9[\" ;五1>\"",
  },
  {
    codes: 2816,
    descriptions: "%70\\\\一]#& 八?':(*$2",
  },
  {
    codes: 2819,
    descriptions: "*6])八二六.?^ >:\">)=七\": 五+%8'@三八@, 4*四",
  },
  {
    codes: 2821,
    descriptions: ")9&[3@#^ */=.]{.02 41) #&(三+|",
  },
  {
    codes: 2822,
    descriptions: "四%:[五@$1< 七八九57>",
  },
  {
    codes: 2823,
    descriptions: "%8八8./91/% &9二8{\"$ [1|2一'",
  },
  {
    codes: 2824,
    descriptions: "@/7%<)二 '0.\"_>, /,'^十/+|%:_8四",
  },
  {
    codes: 2833,
    descriptions: "/*|[十4^1六一 /01 4&{9#一[6八$",
  },
  {
    codes: 2834,
    descriptions: ",,4%一六8,八7]九;$ @/]9:五九{}1十4",
  },
  {
    codes: 2835,
    descriptions: "一七}9@>;\"2[ 二^三|6>\\三}十",
  },
  {
    codes: 2836,
    descriptions: "3|八[)7十四_+ 八20六六一){, _十=四(& =(17_#三$十'",
  },
  {
    codes: 2841,
    descriptions: "八{\\# 四<8 >^:_5 /72九十|6|*#",
  },
  {
    codes: 2842,
    descriptions: "+?^\\@九^\\ |七五 {9([|九#.七十 =|905",
  },
  {
    codes: 2843,
    descriptions: "八|六\\@/8 7.<$4五 7'三%;&",
  },
  {
    codes: 2844,
    descriptions: "八)08*} *%/%*\\6*4一?1",
  },
  {
    codes: 2851,
    descriptions: ":/:1]^ 一01 |99二>} 6七2674七}",
  },
  {
    codes: 2861,
    descriptions: "&(/ 88: @(1; #(,十9\\五&+",
  },
  {
    codes: 2865,
    descriptions: "'四+三,6 九551%: =\\三 8^七1$_十\"6\"七&/",
  },
  {
    codes: 2869,
    descriptions: "4'/6>>(62$ +&*^0^_ '*&=5十@]3, .=六",
  },
  {
    codes: 2873,
    descriptions: "60>?|{$%>六\\ 0854+7%6}.八",
  },
  {
    codes: 2874,
    descriptions: "|:7=|,七七二七 5(^7\\;\"8三6'",
  },
  {
    codes: 2875,
    descriptions: "5]15$\\九#/三', __$'4^ ={55",
  },
  {
    codes: 2879,
    descriptions: "|;八三/4五>?=:@ [[6>七三.<九, :/\"",
  },
  {
    codes: 2891,
    descriptions: "}二$58六^(, 六)] 九$2九<_[四",
  },
  {
    codes: 2892,
    descriptions: "4#.#.(2二九;",
  },
  {
    codes: 2893,
    descriptions: "/\\7|;]]= /[1",
  },
  {
    codes: 2895,
    descriptions: "+\"\"五9^ [)+9八",
  },
  {
    codes: 2899,
    descriptions: "@>[:*0三{ ;4#3:3[].5)(, 五5一",
  },
  {
    codes: 2911,
    descriptions: ")'>'+&{?. ,五二二'十5:",
  },
  {
    codes: 2951,
    descriptions: "?(],6]) ?&_|五\" +?2['?]七 \\|5 _[_&九/",
  },
  {
    codes: 2952,
    descriptions: "[十七@$:一 二9<#* #八@ #二+#一=九六",
  },
  {
    codes: 2992,
    descriptions: "4\\>九$(#_.+0 五八^$ (四7 {*3?>2\\",
  },
  {
    codes: 2999,
    descriptions: "五,#九^十.%五 ?0\" ?|}9 四?/#4/{\\, ;三+",
  },
  {
    codes: 3011,
    descriptions: "*{&;$ %?8 @.\"七[ *9<1@",
  },
  {
    codes: 3021,
    descriptions: "]四0二#十 1一/ 六8;.三#十7 二'>6=十8%",
  },
  {
    codes: 3052,
    descriptions: "十6@六<@ )]} [1<<7}3五 5&^6 ^7\" 0一}[:5*#",
  },
  {
    codes: 3053,
    descriptions: "^/@二@/七; 七+}2五)九 1=7 ^8?*1+, 5十*五6八=",
  },
  {
    codes: 3061,
    descriptions: "/8一(二;\\:4八 26$二4# $*0';",
  },
  {
    codes: 3069,
    descriptions: "@五2/8}%##> 一7;^^\\ ||十;/五\",, #\\}",
  },
  {
    codes: 3081,
    descriptions: "@2&,05'>+}) 九?*{&#2? ]?_^ ;'' |4@*8",
  },
  {
    codes: 3082,
    descriptions: "1/5/]四.六.)四 四|一*>六*7 +三={5?0 六72144",
  },
  {
    codes: 3083,
    descriptions: "%^\\]9_#*+ 十{{?[四+) 6.5}{ =^} =*=\\=",
  },
  {
    codes: 3084,
    descriptions: "5\"{,;_** 2<,#",
  },
  {
    codes: 3085,
    descriptions: "2@08\\:22 {)233.四",
  },
  {
    codes: 3086,
    descriptions: "9'8{|四3% 五#九0 \\+6'3=%7",
  },
  {
    codes: 3087,
    descriptions: "\"1/五*] 六十+<0五]_ /99*2837, 3&2@<一",
  },
  {
    codes: 3088,
    descriptions: "二%四七+9$> 5\\([+/@+ 一'八六七/8:",
  },
  {
    codes: 3089,
    descriptions: "=八;[^七>十 19{)三:\"/, 0{0",
  },
  {
    codes: 3111,
    descriptions: "]>一?^十\" +?['3>二 .八3 8[#82+;&|",
  },
  {
    codes: 3131,
    descriptions: "(;0一>/3七 %{5 6)七>>",
  },
  {
    codes: 3142,
    descriptions: "<|五8二 九.'二$?9八",
  },
  {
    codes: 3143,
    descriptions: "四{十'& ^5+一1]&*, '<:[96 ]二+1一0七3",
  },
  {
    codes: 3144,
    descriptions: "七^),二'' 五2<#>[五%, 4}6\\三} +)0五/十7'",
  },
  {
    codes: 3149,
    descriptions: "}@09)(75, )%$$.; /,5一5|, =二四",
  },
  {
    codes: 3151,
    descriptions: "4?+0|三四 :@四一五@ ?四3 =十):?{(",
  },
  {
    codes: 3161,
    descriptions: "[&18八|二",
  },
  {
    codes: 3171,
    descriptions: "六十8二<'九 2743'$%; ,\"一 二(十2#2",
  },
  {
    codes: 3172,
    descriptions: "&@]#六4八? 五九$%_@_ 3九五{|, 四十/",
  },
  {
    codes: 3199,
    descriptions: "48]\"六四) #5\"}2, 9六:",
  },
  {
    codes: 3211,
    descriptions: "九\\六9 '516\"",
  },
  {
    codes: 3221,
    descriptions: "':'8. 九二}],\\*1^^",
  },
  {
    codes: 3229,
    descriptions: "三四':0八0 ^}| 8四八八\\ {{]#(, 九六7",
  },
  {
    codes: 3231,
    descriptions: "+94五一3六2 [; &\"七<三@+]0 {=/{&",
  },
  {
    codes: 3241,
    descriptions: "三$1\\}>, @^五^八*(3&",
  },
  {
    codes: 3251,
    descriptions: "8二四十九 &\". \";/.2<07.二 9\\三0 \\一|(",
  },
  {
    codes: 3253,
    descriptions: "7)\\;五/十 /_$九 ]五^ 九_%]一 0}八六",
  },
  {
    codes: 3255,
    descriptions: "4}六3 7三\\/:3八@05([",
  },
  {
    codes: 3259,
    descriptions: "{83\\0=(八7] {/%0 二(:二@8\\], \"八/",
  },
  {
    codes: 3261,
    descriptions: ">^&%)\"7# ;\\八三\\十*7 ]>\"'>二3^",
  },
  {
    codes: 3262,
    descriptions: "[6.*<八_九 >]十^4 {#5|2 75% {七[%<39.6#5",
  },
  {
    codes: 3263,
    descriptions: "5四<5*;8]},>8 '\\(一9 八9, @七[2十\"=十#^7",
  },
  {
    codes: 3264,
    descriptions: "*9_)2二2}十 &<6三%%九%;六 一*十六一十,@",
  },
  {
    codes: 3269,
    descriptions: "五=?{]>3 7%三$六6@8, 8八.",
  },
  {
    codes: 3271,
    descriptions: "%($(.8>, 5三7[< 7{三 ^]#6_",
  },
  {
    codes: 3272,
    descriptions: "7}4=*2)四 ]/#二%;)六, *\\]",
  },
  {
    codes: 3273,
    descriptions: "#1>$九-{,5<, ]:(,六一>4",
  },
  {
    codes: 3274,
    descriptions: "/17_",
  },
  {
    codes: 3275,
    descriptions: ";.五|/$ '\"4):'}+",
  },
  {
    codes: 3281,
    descriptions: "7\"% ^%(76 &14 \\#4^. _]0{)_十\\",
  },
  {
    codes: 3291,
    descriptions: "'<=4六|?_ 5)八一+十9:",
  },
  {
    codes: 3292,
    descriptions: "@=七_'9[] )一四^六十#[",
  },
  {
    codes: 3295,
    descriptions: "3559_六>^, /(0九'五 ,* 一|六)十0,",
  },
  {
    codes: 3296,
    descriptions: "+5,<^[四 53,[",
  },
  {
    codes: 3297,
    descriptions: "3(5|四.五 }0七|>六;五|'\\^",
  },
  {
    codes: 3299,
    descriptions: "\"六/*<三:&;(2 |三05十_{ $=]九=四),,",
  },
  {
    codes: 3312,
    descriptions: "8'=七三 :<八,=^7{ <&[ >_九7: ]%十=\"",
  },
  {
    codes: 3313,
    descriptions: "九'3九十*五6九35^9=%1(七九\\ #&[%*;三七",
  },
  {
    codes: 3315,
    descriptions: "二0;\". 十';3 九=+ \"*5:七0\" *$'>1七].",
  },
  {
    codes: 3316,
    descriptions: "78六@ |$<六}29:9 2? ,:七}? /八^}8{",
  },
  {
    codes: 3317,
    descriptions: "?五5九四 8七'一 )七+ %五\",八",
  },
  {
    codes: 3321,
    descriptions: "[*5] ]?\" $^七?95^ )七7{ 十90=)>)$(",
  },
  {
    codes: 3322,
    descriptions: "十四9<_四(八+ ,4[1 八^,\\9一9#二",
  },
  {
    codes: 3324,
    descriptions: "*#>5_ }/二9五|#??3 |;|[7;>,8",
  },
  {
    codes: 3325,
    descriptions: "{;^78 %?%+3++四七, 六二/",
  },
  {
    codes: 3331,
    descriptions: "六5@\"九四= 九]&4六$",
  },
  {
    codes: 3334,
    descriptions: "2]5(三=1 ,九(一7二|\"",
  },
  {
    codes: 3339,
    descriptions: ">1'&]*9 =@\"87}%八}> }13;1五, 五|/",
  },
  {
    codes: 3341,
    descriptions: "85四<3五6+) )八三%.$&\"%% 7二;$.?",
  },
  {
    codes: 3351,
    descriptions: "@八</#9 +](,7六0 1]四 <0四七五十七",
  },
  {
    codes: 3353,
    descriptions: "}九914])+ 1*^@一, 0八6\\\", [*= #'/'",
  },
  {
    codes: 3354,
    descriptions: "五76.9=({ '<0五?八%3 [,%(+788",
  },
  {
    codes: 3355,
    descriptions: "'$五&60#& 3;四四五十[ \"@七 966八\"&), <三\\",
  },
  {
    codes: 3356,
    descriptions: "(2^91747+0 [#,?//十 [十+ \\六六4$8六, _十6",
  },
  {
    codes: 3357,
    descriptions: "&\"6,3二7;%# ;<四*08,十一=+ >六= '0二[7四\"三=/",
  },
  {
    codes: 3363,
    descriptions: "#八?+77:$ ,六4-]'$3>四',",
  },
  {
    codes: 3364,
    descriptions: "一_七##九;'0四 6\\<-}64=8九[十 *'$.3< 三,八/五:[>",
  },
  {
    codes: 3365,
    descriptions: "_+[>七(9; }2>.九六/0=",
  },
  {
    codes: 3366,
    descriptions: ".五(一&十 *$二40462\\",
  },
  {
    codes: 3369,
    descriptions: "%;=,;五{六@5 &%&06\\957, '=$",
  },
  {
    codes: 3398,
    descriptions: "2二\"[. 三>六: ?.]5九&1|",
  },
  {
    codes: 3399,
    descriptions: "4.@}\\\"% '=%1{ {[九+1六十6",
  },
  {
    codes: 3411,
    descriptions: ">$六{: {[?八",
  },
  {
    codes: 3412,
    descriptions: ">+\\二* .|%7+二7, *一]八$, {}@ 7([二一",
  },
  {
    codes: 3421,
    descriptions: "_八,;5+七",
  },
  {
    codes: 3423,
    descriptions: "|/十\" |六八 7七,# 4=_?%, 3[1",
  },
  {
    codes: 3425,
    descriptions: "一4) /4.{72 2\\5 ^/':7<七一",
  },
  {
    codes: 3429,
    descriptions: "2)59\\&,<, [3[",
  },
  {
    codes: 3431,
    descriptions: "5%\\四4 85*9二7五( 十29八",
  },
  {
    codes: 3432,
    descriptions: "5$_*104_ 7?{%<八; }九:九0.\\六 四?1 六:,[",
  },
  {
    codes: 3433,
    descriptions: "八+7%9*5 _一十,&0154, /[7{[+ 4$0]?|8五",
  },
  {
    codes: 3441,
    descriptions: "*{/'五?[/[7 '\"],(:'0{7 :||(7",
  },
  {
    codes: 3442,
    descriptions: "6/4七+ }三5四1, %=8五, |六[ 十五6六",
  },
  {
    codes: 3443,
    descriptions: "四+}五一<9_8一 }三/1& 7六]8 (]七三三%$ 一\\四%)",
  },
  {
    codes: 3444,
    descriptions: "\"一二=三 {>=|@五@一九",
  },
  {
    codes: 3446,
    descriptions: "六8$(+]{6#&九88 八$'6五一九二3",
  },
  {
    codes: 3448,
    descriptions: "^一*#4{;四}九&', 六<5十八 :%^三0(九%五",
  },
  {
    codes: 3449,
    descriptions: "五2八.?\\:.0@2&4 >'<3*二六@一",
  },
  {
    codes: 3451,
    descriptions: "6(?+( \"6一=二;\" 七5+2*[+,",
  },
  {
    codes: 3452,
    descriptions: "五:,{[, )六|3, 3;.=6], 五(8 <2^&/]]",
  },
  {
    codes: 3462,
    descriptions: "]二1< _3/ 四8;>/ >八,4#%\\/",
  },
  {
    codes: 3463,
    descriptions: "@>{&'?=6[' >七$298九九",
  },
  {
    codes: 3465,
    descriptions: "\\6;80#5}七/ |四五3^+一&七",
  },
  {
    codes: 3466,
    descriptions: ">九五9:一 \"四3 *18/4一;{",
  },
  {
    codes: 3469,
    descriptions: "5)_7{ @2=|^+>>八, 9一4",
  },
  {
    codes: 3471,
    descriptions: ")}}.,;> 十6< &$十&九|>0八",
  },
  {
    codes: 3479,
    descriptions: "#十=25 四{(%217 ,#} 5(>4:, 九/50000(",
  },
  {
    codes: 3482,
    descriptions: ";</0十 9{|= '八%/八)+七0四",
  },
  {
    codes: 3483,
    descriptions: "00$.十@七四.+, \"4\\?'三 6六{ 五.]$三 \\六8三, 3五/",
  },
  {
    codes: 3484,
    descriptions: "*\\五;1 *8八.",
  },
  {
    codes: 3489,
    descriptions: "八7二?七{十) ):< .>八@二八@@?\"[, \")8",
  },
  {
    codes: 3491,
    descriptions: ".>,%>二2.&三 一7_[,四",
  },
  {
    codes: 3492,
    descriptions: "九?47% :三\\4^ #[)3@| )@^ .十/: 7[}?$#%'",
  },
  {
    codes: 3493,
    descriptions: "4>九七0 %//9二{*, ,8>[$1 |$;\\",
  },
  {
    codes: 3494,
    descriptions: "}0''九) )4< 5_3十 二9^[.%{:, .;四",
  },
  {
    codes: 3495,
    descriptions: "<}'三 一.,7_\"]",
  },
  {
    codes: 3496,
    descriptions: "六0八|/@%}9/|七七 |}<9#十八七$4 五(五+ /+一,^一*4",
  },
  {
    codes: 3497,
    descriptions: "21四{8 ;{@' ];十 /\">]",
  },
  {
    codes: 3498,
    descriptions: "_十\"#}五43;{ 三五>: 40+ ]'3#0\"四六",
  },
  {
    codes: 3499,
    descriptions: "*6\"=[{三3二# [[\\三九 .<=$'五:三, 0^[",
  },
  {
    codes: 3511,
    descriptions: "一五六.六#六二 =|7 _七,./*= \\[]:\"二三|} 2\\$八",
  },
  {
    codes: 3519,
    descriptions: "六\"7'%)&+ 82\"/77+67七 .:,,#>\\, +'#",
  },
  {
    codes: 3523,
    descriptions: "七一$2 <*?_>)0三四 28六 十九3$<九+;十",
  },
  {
    codes: 3524,
    descriptions: ",$^* %十{ \\十四8+八 @八/五$;:#9",
  },
  {
    codes: 3531,
    descriptions: "4十)9@{?二七::3 |十六&80二81",
  },
  {
    codes: 3532,
    descriptions: "五七##}) 5一$&;三[三三",
  },
  {
    codes: 3533,
    descriptions: "四{< 2\"< >2; ?{,5{ (\"=@四(]六(",
  },
  {
    codes: 3534,
    descriptions: "$4)=一8=?0 %0} 二80/4) (5[[^+\"六:",
  },
  {
    codes: 3535,
    descriptions: "一三*<9一$22 (_2 :4|4九7]三> _6/?八七$九=",
  },
  {
    codes: 3536,
    descriptions: "_[6^>=, 八\\75($, 9*8 \"九八十四三@<)",
  },
  {
    codes: 3537,
    descriptions: "%\"9@7'(8;6 @}>[8一 %6十 六,;^<#三0",
  },
  {
    codes: 3541,
    descriptions: ";七4;7'0 九323三, >2)八七 七[?:9_九 5八%^",
  },
  {
    codes: 3542,
    descriptions: "_\\九[+=< =>@76, .02一6 0\"0<\\四9 八75|",
  },
  {
    codes: 3543,
    descriptions: "\\二'七二\\1六/十 )二二:')3%",
  },
  {
    codes: 3544,
    descriptions: "<%六8=.2 3(*+, ,4五^,, \"/^8, &<# }|3+<]:^",
  },
  {
    codes: 3545,
    descriptions: "2)@=327 六十)\\ :.2$七.&2\"九二",
  },
  {
    codes: 3546,
    descriptions: "五9($$-_}二@[* ;}7*.7@;=",
  },
  {
    codes: 3547,
    descriptions: "\"*,+9)[ \\_&_ $三三.%三四[?",
  },
  {
    codes: 3548,
    descriptions: "\\|五五)8; ]?5?#6&;1",
  },
  {
    codes: 3549,
    descriptions: "661$十五1123/: ,/1*9*7/7, 五$7",
  },
  {
    codes: 3552,
    descriptions: "1\"4二?}? 七五'/@_^五)",
  },
  {
    codes: 3553,
    descriptions: ".\"四\\'26{'七} |.[二$/_[%",
  },
  {
    codes: 3554,
    descriptions: "57,^9 %二四(8({&.# |7:\\五&六$二",
  },
  {
    codes: 3555,
    descriptions: "六三9'7({( /.一.一% >(七=(,_1|",
  },
  {
    codes: 3556,
    descriptions: "@.}四 ?\"5%,五3} 71一四(}&40",
  },
  {
    codes: 3559,
    descriptions: "1#2/_+. 7'\\72三1十 _.51:六}6八, 七@)",
  },
  {
    codes: 3561,
    descriptions: "@3@]7 三4/ ]七>八)九< {九$六^<五十{",
  },
  {
    codes: 3562,
    descriptions: "&\"<\" #%$ :_5+五. 三#0=50:$",
  },
  {
    codes: 3563,
    descriptions: "[}/ ^+; 494 一|=19三^6_三]",
  },
  {
    codes: 3564,
    descriptions: "三$八七%三7 九3[ ?二二一",
  },
  {
    codes: 3565,
    descriptions: "@_,十六#'十* 3.#八#(09}",
  },
  {
    codes: 3566,
    descriptions: "[#^%九 6}四六&}<4, (1_@&(, $99 6四*/七",
  },
  {
    codes: 3567,
    descriptions: "_四*十$#>+1. (=十^:^2} :}4 三%4四8",
  },
  {
    codes: 3568,
    descriptions: ".)一*7 7^23)].^+72六 =[:']1[九{, #:.",
  },
  {
    codes: 3569,
    descriptions: "/3*$九96 492$+%}十八4 2;18*;+6\\,",
  },
  {
    codes: 3571,
    descriptions: "五24$%9&三三( 5#/;一八[6)",
  },
  {
    codes: 3572,
    descriptions: "六[%5?7/2 :十<'\\{* ;%_<.二@",
  },
  {
    codes: 3575,
    descriptions: "533+一1]五 三95|6六七{,",
  },
  {
    codes: 3577,
    descriptions: "[\\#|2.[五 3,九.8六6+&/ 29+?六|7<,, 八5{",
  },
  {
    codes: 3578,
    descriptions: "+6五($;__>+$ 十%* 1=&;:_%{,九 一*+*=八3'二",
  },
  {
    codes: 3579,
    descriptions: "1三2_#( >六?三}九*', ,4十",
  },
  {
    codes: 3581,
    descriptions: "八{=5{$&)) [5175一3 ,?一=0?#^",
  },
  {
    codes: 3582,
    descriptions: "}\"}八(九'$#: *十7二:六_ ?,#八>一二9=",
  },
  {
    codes: 3585,
    descriptions: "6+六三七9)85045六 =>* %四5七;>> |<&九<+{八2",
  },
  {
    codes: 3586,
    descriptions: "|6;?12八5, .8) 6{){%{3?六# 5(<八;",
  },
  {
    codes: 3589,
    descriptions: "\"+二=八|? {7]四十9{\\ 九#.3#\\2.五, 九.6",
  },
  {
    codes: 3592,
    descriptions: "?_八七\";4/一},, 二^:^|:., ^七&八1, 79@九9%",
  },
  {
    codes: 3593,
    descriptions: ",)</1 9五六0, (2_2,)#=9 &>^ 9:?5*一4$%",
  },
  {
    codes: 3594,
    descriptions: "}6=^] [一(五三 _,七#三 3\\1 &\\/+十\\",
  },
  {
    codes: 3596,
    descriptions: "5.4八'1 ^九3 9?六9:/)9, 9_#'%七 7/;七'.七三\\4",
  },
  {
    codes: 3599,
    descriptions: "@十}一七##{<1 9七'二#$;.>, 43]",
  },
  {
    codes: 3612,
    descriptions: "八五四+98%]}十+4, 四{?(1+ %三/<十2=二",
  },
  {
    codes: 3613,
    descriptions: "81@5@24五二\\ }{9 五四(六\\#\"&一9七 _$\\4五八\\二*",
  },
  {
    codes: 3621,
    descriptions: ":九>=%3 _六{ .,#4/70+;\"",
  },
  {
    codes: 3624,
    descriptions: "{1十?二@ 8%\\ '1.十||一_ 二|二62%0<",
  },
  {
    codes: 3625,
    descriptions: "五'五一\\七 =六^ ,@}_二七>九五* 417七,三+7",
  },
  {
    codes: 3629,
    descriptions: ",#八十二(6@}5 =8四_%*<#|> ,$8;六9一2#",
  },
  {
    codes: 3631,
    descriptions: "<;('_一四}) 一\\十|?00 <六\\八%,12#",
  },
  {
    codes: 3632,
    descriptions: "五$$.=4^99 >_;14<:(十=9|[ ;90 七9.;三一一_",
  },
  {
    codes: 3633,
    descriptions: "2一二<>7/40 '2@/\\九| @^283_*;;",
  },
  {
    codes: 3634,
    descriptions: "8]}9524' 7_7,](四,5. 1?, 七\\<三",
  },
  {
    codes: 3635,
    descriptions: "3|%+]<1九1 2,70九5 @=七<#157",
  },
  {
    codes: 3639,
    descriptions: "7/六7七<&]二 <>\"40{29:六, 五(2",
  },
  {
    codes: 3641,
    descriptions: "^五:三;70\\ 四5?>=",
  },
  {
    codes: 3643,
    descriptions: "*27\\$76-@五十?=,五{ ?55(%^ ;2\"八6一]",
  },
  {
    codes: 3644,
    descriptions: "?\\+?十七一\\7二-@1?1;+一_ %一%十四8 :54'八七六",
  },
  {
    codes: 3645,
    descriptions: "八'{七[八|\\+{九 #>&%3/[+ 三七)^+;]三",
  },
  {
    codes: 3646,
    descriptions: "849三5@_>*5 5四4{015* ^四927六\\;",
  },
  {
    codes: 3647,
    descriptions: "九六&[六[七三7 2|一@[一(6 一?{]|{6})",
  },
  {
    codes: 3648,
    descriptions: "}二4五%9+] 五|*|十1五'=, 2八7",
  },
  {
    codes: 3651,
    descriptions: "/2$_四8^>* *八<'0 83五 {<%一% %七八#\".>\"2",
  },
  {
    codes: 3652,
    descriptions: ",69四一{>:{9( 30+5*98 ,一\\ 十@四0^",
  },
  {
    codes: 3661,
    descriptions: "*;,#2(6\\@ 九{2 :\"='(4八;1 #\"6[#{3['",
  },
  {
    codes: 3663,
    descriptions: "(424@ ,1' 七.%. ,十7%#6,}+}.四#四 _四@69{\\四7",
  },
  {
    codes: 3669,
    descriptions: "+\"/^688?>|三十0六 \\75十八+/4}, @\\)",
  },
  {
    codes: 3671,
    descriptions: "八15%);五^ :七二_6",
  },
  {
    codes: 3672,
    descriptions: "@_[<^3; :9*{2*一 }$?(\"_",
  },
  {
    codes: 3674,
    descriptions: "9六+|&^4五7@2五[> <}) 4*四('}三 ;{(%@0%",
  },
  {
    codes: 3675,
    descriptions: "{7::三6八^.2 :%二54七;79[",
  },
  {
    codes: 3676,
    descriptions: "8_320#+2\\& >.({55|07",
  },
  {
    codes: 3677,
    descriptions: ")四四57五\"九@] \\{}{7 \"8} '(?.<二=$**)|",
  },
  {
    codes: 3678,
    descriptions: "/2/+4六{%89 9+4*[=}&{九",
  },
  {
    codes: 3679,
    descriptions: "#3^4|;三:十_ '1{3:九7?8\\, %+7",
  },
  {
    codes: 3691,
    descriptions: "{0六八#6| {.|:^%*三?",
  },
  {
    codes: 3692,
    descriptions: "1)四%#九$ .}[){{六[#, 一*\\ }+十 ;{9",
  },
  {
    codes: 3694,
    descriptions: "$>{%5. _|?三4{$*:三 ?>#六6八七\\<",
  },
  {
    codes: 3695,
    descriptions: "三=2=(\"(六 %>, }?3#:71 #<{3}\"\"&/ :@5二\"",
  },
  {
    codes: 3699,
    descriptions: "{]\"@|九七二{6 _六三.#*#0五 \\?} 7\"5|\"[^六, (}五",
  },
  {
    codes: 3711,
    descriptions: "|四六6^ 8八十#%%_. 八&% )*五 五499十;",
  },
  {
    codes: 3713,
    descriptions: ",九9八2 \\.< |1九 }(9九?*",
  },
  {
    codes: 3714,
    descriptions: "4+&一, 四?]二八六^ \".7#} 三1] <)):;4:二三,7",
  },
  {
    codes: 3715,
    descriptions: "四&>40 ?一%八'九九三",
  },
  {
    codes: 3716,
    descriptions: "\":_&$ '$0@0",
  },
  {
    codes: 3721,
    descriptions: "[+*九五八4&",
  },
  {
    codes: 3724,
    descriptions: "_68&6_%' {四+九_4: ==& ;0二<'. 五$四十_",
  },
  {
    codes: 3728,
    descriptions: ".6/>]|'; 2&\"#$ 8$[ ];^一6\"'<;, 十'=",
  },
  {
    codes: 3731,
    descriptions: "}*)6_([+4?'} /<七 =56,+7八;+",
  },
  {
    codes: 3732,
    descriptions: "二7^\"2{58.四]% #}2 {?:5={八十一",
  },
  {
    codes: 3743,
    descriptions: "十;2{&=四4 \\\\^?,4.$<",
  },
  {
    codes: 3751,
    descriptions: "$683>^;四%4&, }@十/_三9一, 1三三 <$三.二",
  },
  {
    codes: 3761,
    descriptions: "?&7\\一二 &216..,' =五\" 七)2[; 25=六3=三七",
  },
  {
    codes: 3764,
    descriptions: "$#=%三 8$五七$$6=\\7 =5&1六 七一# $?&#*",
  },
  {
    codes: 3769,
    descriptions: "九\"六*. :五?\",九; 3;八15四=?#, ;$四",
  },
  {
    codes: 3792,
    descriptions: "5_1;六; 八+{六3一[八 二<: #+五?{30",
  },
  {
    codes: 3795,
    descriptions: "/(五=十 0\"{ |92^ *七)59(^\"3,",
  },
  {
    codes: 3799,
    descriptions: "(],/+=二;一二九>:9 ?>三18八十,,, >*:",
  },
  {
    codes: 3812,
    descriptions: "一5三_$4 *一% 6:二[5)70,| 三<3*九十3五十",
  },
  {
    codes: 3821,
    descriptions: "_<(|=二[&2十 {八.1%|24* _%九 ][&>(五%]九",
  },
  {
    codes: 3822,
    descriptions: "一?五+[5\\1七+{>_ 五,二,<六五|",
  },
  {
    codes: 3823,
    descriptions: ":&6二\"七( {?=>&[7 9}#9十*?<\"三)",
  },
  {
    codes: 3824,
    descriptions: "八*$/六 +4'六三< 55十 /5二|*@)$ 十%/六]二}",
  },
  {
    codes: 3825,
    descriptions: "1?8^'3(?|七] 四% /|8\\'三二 六27)九[一/#十)",
  },
  {
    codes: 3826,
    descriptions: "*&一)56<6五^ $\"><>九(/$五+",
  },
  {
    codes: 3827,
    descriptions: "3@'.,<> };九=/一736^( %8二 ]_(5.9",
  },
  {
    codes: 3829,
    descriptions: "3{_1%[7_' 二{八 /&?,一*%$*\"( 四四.%,五_, 3二'",
  },
  {
    codes: 3841,
    descriptions: "<,七5=1/% 7六) 4八+:96[ _八[十七十|.9<七",
  },
  {
    codes: 3842,
    descriptions: "五27八4八2+ 60=三>,@;2{ 6#+ $4一*6?9六",
  },
  {
    codes: 3843,
    descriptions: "6十)\\&_ 7,四'八三@\\{ 9/6 /\"五0\"34%",
  },
  {
    codes: 3844,
    descriptions: "3-_4四 <9>[$六'9< ,七) #三+\"5",
  },
  {
    codes: 3845,
    descriptions: "|\"?=十=五+4')|8{ >八9七&二%9&",
  },
  {
    codes: 3851,
    descriptions: ")%|?(1:39_ 0七十##",
  },
  {
    codes: 3861,
    descriptions: "四)<&+:_\\三,一; ^六=二#1)四5 二.] }三{:七十/九",
  },
  {
    codes: 3873,
    descriptions: "七六/2\"三&, |8:1\"一, ?$$+三六).10, &三七 '?%_一",
  },
  {
    codes: 3911,
    descriptions: ".{四{]九/, 六%0五一.0: )五[8.",
  },
  {
    codes: 3914,
    descriptions: "五?*九=四一^/+ @&^ ?8+6$^ %{3{",
  },
  {
    codes: 3915,
    descriptions: "九>九}二{=;' %50三)&一/} +97 ,{46^9}二 0#八\\",
  },
  {
    codes: 3931,
    descriptions: "[+8九_2> 2}六%%'20{_0",
  },
  {
    codes: 3942,
    descriptions: "7]&9六 ]*: 八}四,^五* 4:{二",
  },
  {
    codes: 3944,
    descriptions: "二七9五一, \"{1+, %'9 八=\\5九$8>'一 _九'}|六#>",
  },
  {
    codes: 3949,
    descriptions: "6五0%##七8 九\\? 九4>(3八六, 3%三6@, ]|,",
  },
  {
    codes: 3951,
    descriptions: "_{04 _': =四[|_(|.,( 四五[%$;8",
  },
  {
    codes: 3952,
    descriptions: "'^3= {{40七八/ 6{5 '_> ]37>^",
  },
  {
    codes: 3953,
    descriptions: "?_4%*() <\"<.($(",
  },
  {
    codes: 3955,
    descriptions: "42六*\\' \"&三8| 3|| [三十'8 0)@9&#3",
  },
  {
    codes: 3961,
    descriptions: "五?;:九@8 *五,二八\"一",
  },
  {
    codes: 3965,
    descriptions: "八}十)四}六十五, $[一.八六), 3七>=2?), 四,8 .*<5",
  },
  {
    codes: 3991,
    descriptions: "[\\十<]9 ;十= 9{四一_%五",
  },
  {
    codes: 3993,
    descriptions: "8十#__ $*5 七7%2@76.*?0 3';:&五9:&_+",
  },
  {
    codes: 3995,
    descriptions: "1}九2五\" +0|五五;=",
  },
  {
    codes: 3996,
    descriptions: "一3#@ ,.[\\:_/ #八二80 =\\8]?8{二九, {十.",
  },
  {
    codes: 3999,
    descriptions: ";%^+8@%二七&七84 =一[91@#+五#, .0(",
  },
  {
    codes: 4011,
    descriptions: "#%&十/二^6八, |+三3-;$:5 )6二@0\\|$;",
  },
  {
    codes: 4013,
    descriptions: "<_$*#\\({1 6五/ ?42三)=5/ _]'五七[}\"",
  },
  {
    codes: 4111,
    descriptions: "6八4?三 64八 :八?[=*一2 /0=一八39",
  },
  {
    codes: 4119,
    descriptions: "+*3;0 5@=+2_四|八 =]+@五++*三0(8_2, 2^_",
  },
  {
    codes: 4121,
    descriptions: "}\\=0?6\"+",
  },
  {
    codes: 4131,
    descriptions: "*235%4十一( 一7; '四{@@ 一\\\" ;[5二=40%}3,七?#",
  },
  {
    codes: 4141,
    descriptions: "四六}十4 @[2 +<4>*八一 9=),八67",
  },
  {
    codes: 4142,
    descriptions: "+)# \":六{五九] _*二4#=>, 3;:三*七 三五)[<",
  },
  {
    codes: 4151,
    descriptions: "六2&9)_ #六%;.",
  },
  {
    codes: 4173,
    descriptions: "$五[ <(四四五\\)十 ;0\\ 四_:/(三: 五//#七76<62",
  },
  {
    codes: 4212,
    descriptions: "#55+7 ,_2六,九\\/, 4十(十4二; 三47:04;",
  },
  {
    codes: 4213,
    descriptions: ")九六]@6&^, 二>四五\\0 %*七:{",
  },
  {
    codes: 4214,
    descriptions: "{|六&# 二{&二<(30 一九\"0 八,)十[五0",
  },
  {
    codes: 4215,
    descriptions: "五/?\\'*| 2?1%\",5=, ::9九十% #5 *{{",
  },
  {
    codes: 4221,
    descriptions: "'(^| 十{^二3&一 {_>六:七4七七{_ }0? 九;2\\@?1",
  },
  {
    codes: 4222,
    descriptions: "=)'{$24)}(6< %=8十@7],@八十 十|^ \\|六&}]=",
  },
  {
    codes: 4225,
    descriptions: "(一十75)9 [':]三+)三_^\" 9]九 1^>1+三/",
  },
  {
    codes: 4226,
    descriptions: "*'八1&83 0八七\"(六1$>84 %\"# }四}3三)\\, 7<九",
  },
  {
    codes: 4231,
    descriptions: ">\"7|一,一五 _八^+>6\\2 一#;六=:}@10",
  },
  {
    codes: 4311,
    descriptions: "2.\". .90}40 /二{11\\\"",
  },
  {
    codes: 4412,
    descriptions: "^]\"* 四六{ 2九}(1*\" 2/2八<二>{四^十=.6 >/ \\2'{\\]3",
  },
  {
    codes: 4424,
    descriptions: "三+53 一一4 8八9%76+$ &?}9)十一__]二}五% 6% .*08+.七",
  },
  {
    codes: 4432,
    descriptions: "五四1四:(二 3五825([@\\.>/九五 */ /.十 ,7)\\_ 0/=7十",
  },
  {
    codes: 4449,
    descriptions: "?>7七9 &;^5:[{#[|七65> ;\" &\\十|二(,",
  },
  {
    codes: 4481,
    descriptions: ">=(五 *三[ 8[^2+\\$\\< %){九]2,\\?四十;8三, ,六1+$9 [\\\"&9",
  },
  {
    codes: 4482,
    descriptions: "2[=\"\"\"4",
  },
  {
    codes: 4489,
    descriptions: "6=?二四 \\|*39#九?\\ 6@}1'{3_6一十(\\*",
  },
  {
    codes: 4491,
    descriptions: "1三1<6} :.$29 ^.'=十<_5",
  },
  {
    codes: 4492,
    descriptions: "*8十;\"五 5<> [1[;{+} )#五一三0&",
  },
  {
    codes: 4493,
    descriptions: "||0/3$8",
  },
  {
    codes: 4499,
    descriptions: "六3九$: 九九8_%=%0#{])[] %:7>五,八&, >9.",
  },
  {
    codes: 4512,
    descriptions: "#'$ )#6.五二八1/]/十31, }#九七五七$\"*",
  },
  {
    codes: 4513,
    descriptions: ";*% 5四一=2三二 ^6<\"'^,二",
  },
  {
    codes: 4522,
    descriptions: "九47 +三七[2:7四8五七(二+, 六*$]九,\"三\\十六三",
  },
  {
    codes: 4581,
    descriptions: "}四九'五一&), 0\\(十1* =9二7);, 3,9 7四}_=(+二",
  },
  {
    codes: 4612,
    descriptions: ",六二\\? 三}2;四*+${ +*})/%一:五",
  },
  {
    codes: 4613,
    descriptions: "七^十八_\\六 ?'@.^{:'3 %[,一}&:=;",
  },
  {
    codes: 4619,
    descriptions: "|}\\\\;2=_六, 6/4",
  },
  {
    codes: 4724,
    descriptions: "%5>^,1 0#十(八%=.",
  },
  {
    codes: 4725,
    descriptions: "9[^{ 5*九9九七九6*",
  },
  {
    codes: 4729,
    descriptions: ",^十92\\)4八 2+)[一]5\"/+一?#十 _<4_?十5*9:\"",
  },
  {
    codes: 4731,
    descriptions: "|#3五9}八 7>}=){,|八5.?五七 5>0%|<|#(@]",
  },
  {
    codes: 4741,
    descriptions: "=;;三@2 $] ;八{八;2]{ }$八4",
  },
  {
    codes: 4783,
    descriptions: "?|:27.= ^<十 }_[}=&@",
  },
  {
    codes: 4785,
    descriptions: ")十=^1九}\"七6 ,_三 7;5^六 =\\^*二3八\"7六",
  },
  {
    codes: 4789,
    descriptions: "[,|@{4+/&+(六4# =7#三^九/*, 七.=",
  },
  {
    codes: 4812,
    descriptions: "<六七_4\",0五\\=,_/ +4>3一&^'/78}*",
  },
  {
    codes: 4813,
    descriptions: "_6?473{2四 #/@五+;6%/九,&|, 7{:61* }八;<|",
  },
  {
    codes: 4822,
    descriptions: "六]3[$2<'0 |+3 #==9} 十%0(六&<7@6$\"=九",
  },
  {
    codes: 4832,
    descriptions: "[$)6; 7十$*0<6900:0 \\32一,四;)",
  },
  {
    codes: 4833,
    descriptions: "五)<[七四\\十>{ /#四(1'_)十38> 七八二+八/十=",
  },
  {
    codes: 4841,
    descriptions: "<_+3六 }{二 ?八六(七 .九8 .49=2八38三0 六七?7^.2四",
  },
  {
    codes: 4899,
    descriptions: "$9一+)'五^&>;(= \\:*(三/\\:, ,):",
  },
  {
    codes: 4911,
    descriptions: "六149$|;: *&<一53(6",
  },
  {
    codes: 4922,
    descriptions: "&+\"八七=八 [<8 )#&?,;_/:_\"九",
  },
  {
    codes: 4923,
    descriptions: "35. <8)&:<742?]\\ /:3 }(,八758九23一%",
  },
  {
    codes: 4924,
    descriptions: "八?五4\\\\2 #.\" 2}[>'=.>]十1二",
  },
  {
    codes: 4925,
    descriptions: "7八1 2八8=,六1943 二[$/82 %七=])3}八'九?三",
  },
  {
    codes: 4931,
    descriptions: "0/五五七<[% 八二6 ::[%八 五)#:四六>二 ++2十4{{>",
  },
  {
    codes: 4932,
    descriptions: "/十; @0三 +}五&' *)七;{]6五 9\"5;\\一$)",
  },
  {
    codes: 4939,
    descriptions: "(89\\6$@七>七< {5/?*九,+7, 89=",
  },
  {
    codes: 4941,
    descriptions: ")<七九七 '六1}.7",
  },
  {
    codes: 4952,
    descriptions: "%.}^;7|5 /7三{^[七",
  },
  {
    codes: 4953,
    descriptions: "&八[':十 三.@41/]",
  },
  {
    codes: 4959,
    descriptions: "'0&三#)*七 七七八7$19;, 一九=",
  },
  {
    codes: 4961,
    descriptions: "%(%]3 ?65 九七}-?(/二六九三{五19} /五/2八%",
  },
  {
    codes: 4971,
    descriptions: "96十'一八;'<+ {*一5_06",
  },
  {
    codes: 5012,
    descriptions: ":]*8_67五5,十 *6六 \"六一(八 \\?>4八 6@六[}.\"@",
  },
  {
    codes: 5013,
    descriptions: ")八[9八 ;($}{<; 4,)=?%^# \".= (/_ 2四二二:",
  },
  {
    codes: 5014,
    descriptions: ",/\"', |^& &3四\\/",
  },
  {
    codes: 5015,
    descriptions: "六^?1三 九;4#2[{ 九四十十\\, :九十;",
  },
  {
    codes: 5021,
    descriptions: "+>1四五十3七2",
  },
  {
    codes: 5023,
    descriptions: "六<2九>^9=]<*)一11",
  },
  {
    codes: 5031,
    descriptions: "四|;0{$, ?二[46\\四, .五五 ||六4);/=",
  },
  {
    codes: 5032,
    descriptions: "\"&=$|, {三7#=, 2九( \\]&]&/[ <%一19:六@",
  },
  {
    codes: 5033,
    descriptions: "|七[三\"[&, 2六)二98, ')四 3$#?五,{5/|",
  },
  {
    codes: 5039,
    descriptions: "$[八\\六@八/九\"'四 三_0$*2.;3, ^\\]",
  },
  {
    codes: 5043,
    descriptions: "十,3&/9一*;)|1 六一)\",(0># 3八二 ^.8五\"=四|",
  },
  {
    codes: 5044,
    descriptions: "五五:=\\二 }九_55.'48",
  },
  {
    codes: 5045,
    descriptions: "_%^^3@=六\\, _;78八'九2990, 十0/ 1六四;7'^)",
  },
  {
    codes: 5046,
    descriptions: "八&]4\\,7&#* <七6092?{八, {([",
  },
  {
    codes: 5047,
    descriptions: ".$五四1#+ |%五 \\三%四9]二7 >九一3.3\".{",
  },
  {
    codes: 5048,
    descriptions: "/|'>%+#=4一 5&5|}",
  },
  {
    codes: 5049,
    descriptions: "><[@4+八四^}.| :%<六=&@+7, 五\\:",
  },
  {
    codes: 5051,
    descriptions: ">_{:[^ _四2781一 二34=二]{ _3# ]+&八三7?",
  },
  {
    codes: 5052,
    descriptions: "0\"|; 八3六 九1^四0 八^六<|^43 二($ 180,",
  },
  {
    codes: 5063,
    descriptions: "?六六[三五&七@九 9六二,/.$28 |三八 4十#6'}十\\/",
  },
  {
    codes: 5064,
    descriptions: "\\41九03/一|三 .45([\\#%}8, $三:四)0七\\}] 五._ 五@%.九",
  },
  {
    codes: 5065,
    descriptions: "九8二55|\\五0@ }]九四7 1四1 8\"2三(七6<], 三3;",
  },
  {
    codes: 5072,
    descriptions: "(9508+4{",
  },
  {
    codes: 5074,
    descriptions: "7四$}([2' \",= '四=%.0七九 ^十四>四>6 <6\"3%4%@",
  },
  {
    codes: 5075,
    descriptions: "6六67 \".0 >+*二5/九 $13 ;*, _0$8九$三39[7(",
  },
  {
    codes: 5078,
    descriptions: "\";7四&&3八:&一\"+ ?七2五5^%2十 }?九 \\*七四1,0七",
  },
  {
    codes: 5082,
    descriptions: "=2>_(_1%,五四, |_@ ;:七[?8 #/七;^2>(三",
  },
  {
    codes: 5083,
    descriptions: "#7\"5 ;;2 ,7六_九\\ 三一{$四:}:9",
  },
  {
    codes: 5084,
    descriptions: "七,{_七}[773 五二%\"@<|[& 9三五 (5七80一3;5",
  },
  {
    codes: 5085,
    descriptions: "[#三_.'_九六$ 六*一六;十九}",
  },
  {
    codes: 5087,
    descriptions: "二}%三<=? 4^$(九\\+(#五[+5 )>七'^$+*六",
  },
  {
    codes: 5088,
    descriptions: "|[43$9:)'一1*)六 )七2+}\"#.( 8>一 [+&_十/1{",
  },
  {
    codes: 5091,
    descriptions: "四.?]6,:8 7四: 三>|.[]十|26 \\*_五9",
  },
  {
    codes: 5092,
    descriptions: "_.*五 '二[ \"|四2. }{::7 %;四 +(一一#九?七",
  },
  {
    codes: 5093,
    descriptions: ";五[;四 $十; *7#六8 ;{3四?230/",
  },
  {
    codes: 5094,
    descriptions: "$#%2[2< 425 \\5*5四#)@ 0]五_:2",
  },
  {
    codes: 5099,
    descriptions: "(0四<*5八 七<<)#, &)[",
  },
  {
    codes: 5111,
    descriptions: "]六339\\<6 @0_ (7$##:% :五<?六",
  },
  {
    codes: 5112,
    descriptions: "/.四}八51@+四 81| \\*一2一' <}#7_二/八",
  },
  {
    codes: 5113,
    descriptions: "&_:^三,#_^, 九}+ +^六]6七二2 |3二@}@四 :十(]/",
  },
  {
    codes: 5122,
    descriptions: "七五'一#, 4^'五_,<[二六/;}, 3]$ *%;<\"8'^",
  },
  {
    codes: 5131,
    descriptions: "^十@$一 @+一_{ 4&6 7.八一*&.",
  },
  {
    codes: 5136,
    descriptions: "[\"0'? ;(* 四5['一 <;#7/一\"2",
  },
  {
    codes: 5137,
    descriptions: "('^二|'6 |.: \\:05>*.+'十 @[^%[*4)",
  },
  {
    codes: 5139,
    descriptions: "3771*=|3",
  },
  {
    codes: 5141,
    descriptions: "\"\"二\"))9|<, +<|一\"&} \\\"八1",
  },
  {
    codes: 5142,
    descriptions: "七[二<|^.& .<;九=| 六^)9.",
  },
  {
    codes: 5143,
    descriptions: "七七&六7 |%一5%=+五, )[一.12 十#一七7 三1 ?一0$六^",
  },
  {
    codes: 5144,
    descriptions: "十:.;+五' ^6五 十@$*.七* ^'1+|]=8",
  },
  {
    codes: 5145,
    descriptions: "一九:.|%45%%三^5",
  },
  {
    codes: 5146,
    descriptions: "^4五@ 二)十 ;六\\](*&4",
  },
  {
    codes: 5147,
    descriptions: ".>'\"/ =/九 一\\四2 十9>{*1二|",
  },
  {
    codes: 5148,
    descriptions: "七二$1} \\7&3四> 七9/ %)5十&八4十三>",
  },
  {
    codes: 5149,
    descriptions: "'%%}2\\<}: 5七# 1&7$七五) ]五]<*三\\(, 23.",
  },
  {
    codes: 5153,
    descriptions: "69六(( 五+% 9+??@ .({:(",
  },
  {
    codes: 5154,
    descriptions: "$)1\\+==二|",
  },
  {
    codes: 5159,
    descriptions: "六,]4-.七{三6四6 1%. :'159376:, 四九[",
  },
  {
    codes: 5162,
    descriptions: "\"一三?\"一;三 .;;\\#.0七八 +0/ 9'九九' :0九四0{",
  },
  {
    codes: 5169,
    descriptions: "+三6^@/4?) [五' 七96)|& ]78>四六八3, 四8:",
  },
  {
    codes: 5171,
    descriptions: "4*#&}8五82 1,/] 4<,六;*?$ ,#六 ^>六]](,^>",
  },
  {
    codes: 5172,
    descriptions: "]6.\\^#'\"9 ?9:00九十六, >|}",
  },
  {
    codes: 5181,
    descriptions: "1)|1 三&三 二]9",
  },
  {
    codes: 5182,
    descriptions: "一,$一 >:_ 七八十9二+'8% \\+&;四%七[/",
  },
  {
    codes: 5191,
    descriptions: "5<八八 四*(){_/六",
  },
  {
    codes: 5192,
    descriptions: "{9&五], ,_六'八4七62'=, 四\\4 /6%5一*九_(9",
  },
  {
    codes: 5193,
    descriptions: "'+/0%)六 &$9 >_=/=_一? ;七七'.十/7",
  },
  {
    codes: 5194,
    descriptions: "七八<四>6} 3&$ \\5=四+十. 四_\\/&2三0",
  },
  {
    codes: 5198,
    descriptions: "_.;'[十, {|6$#;.[三, :,6 }4?三*五\"九",
  },
  {
    codes: 5199,
    descriptions: "$5五)+$9#;8 \"四\"五一, 1>)",
  },
  {
    codes: 5211,
    descriptions: "[三;六37 *7三 }&+一, 06二>#[\"< 九4:34%八4六",
  },
  {
    codes: 5231,
    descriptions: "十8_一0, 九4\"=_, +'% *\"6|%6十?( ]'^三>五",
  },
  {
    codes: 5251,
    descriptions: "四}@十++八五 .298][",
  },
  {
    codes: 5261,
    descriptions: "\\^九5=一 ,三),^[\\;/ %&] 十&五4@五 十\"[]|)",
  },
  {
    codes: 5271,
    descriptions: "九]6.六十 0>九3 \\6}{},三",
  },
  {
    codes: 5311,
    descriptions: "@^%;^|二*四^ 0{}三八*",
  },
  {
    codes: 5331,
    descriptions: ">?//一4, 4#;&八0",
  },
  {
    codes: 5399,
    descriptions: "22七;'*%;;九八<? >9)六2一\\ 二|&+_*一五7_一",
  },
  {
    codes: 5411,
    descriptions: "%+八4/1: 二4*{\")",
  },
  {
    codes: 5421,
    descriptions: "二0}0 8四四 四>\"@ 七四三?\\,=",
  },
  {
    codes: 5431,
    descriptions: ";2:]四 '\"& @}*,@7_&3 +3#9}=5",
  },
  {
    codes: 5441,
    descriptions: "9八一=:, +九], ;\"一 >*987_'2七;%($ 5十56'7",
  },
  {
    codes: 5451,
    descriptions: "5_6+9 五$%1九:五六 十二^<%(",
  },
  {
    codes: 5461,
    descriptions: "1三?]2> <八17#;$0",
  },
  {
    codes: 5499,
    descriptions: "三\\.',?2+'.4;& <;#% %三八{一@",
  },
  {
    codes: 5511,
    descriptions: "5;9 :') :^)九 %8& 4^二十}八3",
  },
  {
    codes: 5521,
    descriptions: "'=\\4 四\"] '&.^^=九",
  },
  {
    codes: 5531,
    descriptions: "9四}% 六*; 八&_4 ?}二80二 (^3八\"9",
  },
  {
    codes: 5541,
    descriptions: "|{|)<\"七五 +;]9/九8 8.四(\\9*1",
  },
  {
    codes: 5551,
    descriptions: ":六七1 #65,九{)",
  },
  {
    codes: 5561,
    descriptions: "二$六'>:@四\\|$, +\\十九$3& \\1=;=#$",
  },
  {
    codes: 5571,
    descriptions: "^}[91三;07) ,&.8九1>",
  },
  {
    codes: 5599,
    descriptions: "\\]:$9'1@84 八[=]}#|, 6.'",
  },
  {
    codes: 5611,
    descriptions: ">|:'7 ;40 3/]四' |四|8五%六) _3@.=2",
  },
  {
    codes: 5621,
    descriptions: "(六三2:'< ^])/{3|[ _&696]",
  },
  {
    codes: 5632,
    descriptions: "5>四'_'八 1({90)69四 \"}* 二九37%七5(/ {九.*|)",
  },
  {
    codes: 5641,
    descriptions: "'九$$:%))'> 八8& 2?(7=四七' 3;47 1八3[5|",
  },
  {
    codes: 5651,
    descriptions: "九四^\\三> 9]&九0{|9 四^29\\=",
  },
  {
    codes: 5661,
    descriptions: "6$(3 1'%_,:",
  },
  {
    codes: 5699,
    descriptions: "十//:.4九9:^(六8 &一7>一.* }2九 7=8@}'/[\";9",
  },
  {
    codes: 5712,
    descriptions: ":\"&,{:/.7 41}三四^",
  },
  {
    codes: 5713,
    descriptions: "2\"#%八 #六:十十1^六 5十四/|*",
  },
  {
    codes: 5714,
    descriptions: ",2+\\50九 四(二 7)六)|=?(?. /二'三80",
  },
  {
    codes: 5719,
    descriptions: "@||'*九+十.十:九6 \\5.十'#五)*<<+}一十",
  },
  {
    codes: 5722,
    descriptions: "5;40[[,'八 6<]/4一三*十 )|2^;)",
  },
  {
    codes: 5731,
    descriptions: "}@7五3, |八9八.|[_5., *$? #/>^2[\\8七1 十.]6\"十",
  },
  {
    codes: 5734,
    descriptions: "^九+<>\"{& 五五( 二_*二47=+ 八+|十\"#",
  },
  {
    codes: 5735,
    descriptions: "]十<三*\\ ]?一 六九六<:五/七5$# 2=?一 }%五;<五",
  },
  {
    codes: 5736,
    descriptions: "+&)(\"5一 {7二>5}\\八#) 8\\\"\"/=",
  },
  {
    codes: 5812,
    descriptions: "[{?+[% .;四\"九一",
  },
  {
    codes: 5813,
    descriptions: ")6$\\\\|34 六[',$=",
  },
  {
    codes: 5912,
    descriptions: "7/十^ '五一,^; '八1 @6$22八49:^* \"二(1'五",
  },
  {
    codes: 5921,
    descriptions: "?,五\"0? ,%##*3",
  },
  {
    codes: 5932,
    descriptions: "+9+七 五(}%八(一&<,_ &,0八{_",
  },
  {
    codes: 5941,
    descriptions: "$?3二2]|[ @1#二6 ?36 二%,=[<< +九:4}",
  },
  {
    codes: 5942,
    descriptions: "#七\"七 |3?6*6",
  },
  {
    codes: 5943,
    descriptions: "&5}2'五,02, 三^8)二九",
  },
  {
    codes: 5944,
    descriptions: "((\"}五+6 :&=5:>",
  },
  {
    codes: 5945,
    descriptions: "1'8五$, &九|, 4七/ 四4[{ +四2?[",
  },
  {
    codes: 5946,
    descriptions: "$17.%} ,>= 五.?,十\\_5{\\$0 )二^}五7 %/&@/一",
  },
  {
    codes: 5947,
    descriptions: "2$=#, $'六^8(四, _九3 8三(_九*'# 89)^",
  },
  {
    codes: 5948,
    descriptions: "$:/8@@七 9,3 %2#[=@二 +@[.四 9*+^*[",
  },
  {
    codes: 5949,
    descriptions: "五>;4'(, 1<<2)=^#@四, 十四{ 三]\\/\\ 四_&十'",
  },
  {
    codes: 5961,
    descriptions: "{八*@\\七^ 3[{ 8十.\"-(六]}\\ 0'(5|五",
  },
  {
    codes: 5962,
    descriptions: "三#&(*,7.3|.?: 三二\\3&[十 \\一七*^?+[(",
  },
  {
    codes: 5963,
    descriptions: "六八<\"\"八 >二$三三三} ;三@1/]&7)4:八('",
  },
  {
    codes: 5983,
    descriptions: "'8{4 5八7 ^.四]50;",
  },
  {
    codes: 5984,
    descriptions: "_4<八.7%(; }六=$]七(九五 七]; @5&八_|,",
  },
  {
    codes: 5989,
    descriptions: "六\\0) )^7^[七|, ^_:",
  },
  {
    codes: 5992,
    descriptions: ".+九::>#[",
  },
  {
    codes: 5993,
    descriptions: "0:]8:[$ )\"7十}& 九6五 ?七^%一四",
  },
  {
    codes: 5994,
    descriptions: "八十,五 1):|三5十 九五九 ,]]三四十)1?)",
  },
  {
    codes: 5995,
    descriptions: "五_+一?二1 );9.) ?,'4五$",
  },
  {
    codes: 5999,
    descriptions: "=7}&十%^?971五/ :5'2_0 十十&四/二, 0十?",
  },
  {
    codes: 6011,
    descriptions: "//*2(二= &[669#. 六八\\+八",
  },
  {
    codes: 6019,
    descriptions: "'.一六[>七 %|^八六二7 1四四+六0267二, ,)十",
  },
  {
    codes: 6021,
    descriptions: "#六}_七0&六 6+0_二9九8一8 二|二\\/",
  },
  {
    codes: 6022,
    descriptions: "二?])+ 94?4**四06# _$<#^",
  },
  {
    codes: 6029,
    descriptions: "5=>*(七:.67 一\"{}十, +;0",
  },
  {
    codes: 6035,
    descriptions: "|_9二八91 27五8:2十 &{6十*四<一十24^",
  },
  {
    codes: 6036,
    descriptions: "^一*二]六一 八五0$?_七=\\五=<, #六_十四] 六七%|([0",
  },
  {
    codes: 6061,
    descriptions: "八;}'[十% 一四|]三% 9_{01(",
  },
  {
    codes: 6062,
    descriptions: "|?=67 ,=5^>四 4:?/{+",
  },
  {
    codes: 6081,
    descriptions: "%<三二三三0 6/<: *九= {9><{_22 &二_ ,=#<&.3一",
  },
  {
    codes: 6082,
    descriptions: "8十2六^*@ 1五\\九6 7+6 9[(^_七]?三%<?$ ,^9一^",
  },
  {
    codes: 6091,
    descriptions: "70}7<4$9五: ^=(7] 一.#@\\%#一$&",
  },
  {
    codes: 6099,
    descriptions: "三%6二2\\}74 &<?六*十% |: 八5{6*五^^四六 ]四五}九六]",
  },
  {
    codes: 6111,
    descriptions: "四,|?>$一 &5八 9|\"6@>>\\* 1@@=_<>{] 9七}四>二",
  },
  {
    codes: 6141,
    descriptions: "\"三$0#+三| 六?$421 5|7/九#,+五4十7",
  },
  {
    codes: 6153,
    descriptions: "%2'{(-}^[% 二三$:|九)# ?:9<九三",
  },
  {
    codes: 6159,
    descriptions: "|4八九>二二>#=八(% #|=五&#六@ 6#四_一'",
  },
  {
    codes: 6162,
    descriptions: "#\\<八9)[[ >1)<:2$ &九= 一六&.*1,).:0\\三,",
  },
  {
    codes: 6163,
    descriptions: "*/[| {五,)7八9",
  },
  {
    codes: 6211,
    descriptions: "[1:6二$&> \"150|二十 $^? $一\\*}+九",
  },
  {
    codes: 6221,
    descriptions: "?九$[/\"@十/ >@/?\"]7五五 五&^一三五], 5}#2一>\"",
  },
  {
    codes: 6231,
    descriptions: "/\\2]4十]6 ,0四 ^+\"/六7{)3 6七五<_24一四",
  },
  {
    codes: 6282,
    descriptions: "^9225,四=0| [;\\1,_",
  },
  {
    codes: 6289,
    descriptions: "^:9:\"[二一 +:二 8&四={四'<7 >4&}.])",
  },
  {
    codes: 6311,
    descriptions: "二,一\\ 6,>:9]*7十",
  },
  {
    codes: 6321,
    descriptions: ",5*}<八2四 5八; |440二+ _一十|{}八%^",
  },
  {
    codes: 6324,
    descriptions: "(/:&3\"[/ 一=% 二$=\\|7% =五){%@[ ^/2|>",
  },
  {
    codes: 6331,
    descriptions: "<}九6, 四56#\\#, 三|七 >>一5@_二四 \"[=:$;^二3",
  },
  {
    codes: 6351,
    descriptions: "6\"3$8% {7'^\\]:#}",
  },
  {
    codes: 6361,
    descriptions: "|\"};' 9八二*七一\\十_",
  },
  {
    codes: 6371,
    descriptions: "+0<六二二_, 7|六1|7, 1'' 六*7_6^: @1%$一",
  },
  {
    codes: 6399,
    descriptions: "$=*&9#(六^ _五六@?^[八, \"\":",
  },
  {
    codes: 6411,
    descriptions: "^九七)&一[4六 =68\\九九, </?=&九6, 一一二 _五四^2二;",
  },
  {
    codes: 6512,
    descriptions: "4.六)>\"33<\"}0四+ ^_十^7四]& 8,七(_>\\=?",
  },
  {
    codes: 6513,
    descriptions: "七&/8六%6&| 九7三1九五^* <5:八六0%:六",
  },
  {
    codes: 6514,
    descriptions: "6];一|\\41 ]五,六]6<\\&, $\"&1二{ *)\"0.163\"三",
  },
  {
    codes: 6515,
    descriptions: "+{[+;$ :[41 (9^3 ]|/8|9]]\\",
  },
  {
    codes: 6517,
    descriptions: ";8}四<*一= *十^,%1十^ 三四)$3五+",
  },
  {
    codes: 6519,
    descriptions: "49|一 六[二^(八99 {5.二'_>, _.(",
  },
  {
    codes: 6531,
    descriptions: "7%>| 八|'],6 [七43三4 六\"$ ;五$5[@3%",
  },
  {
    codes: 6541,
    descriptions: ".93一四 :2七=二@(] 二^/四>[#",
  },
  {
    codes: 6552,
    descriptions: "<>+1_[?>?八} %>< 十七)九=<[[九[, 七|2",
  },
  {
    codes: 6553,
    descriptions: "98四六2三0一 一>%{={>四七[; :9二 $,%\\^=八1_/",
  },
  {
    codes: 6712,
    descriptions: "\\(1% }七四八]^4 7四>3十七六[_",
  },
  {
    codes: 6719,
    descriptions: "?1$]五3> @?二[034\\<, 47,",
  },
  {
    codes: 6722,
    descriptions: "%1[*'4\\五一1 九#3_二>>%二0, )?_三-0\"^$2",
  },
  {
    codes: 6726,
    descriptions: "五一|\\7)[5,+ ;八>_@+_, 8@一",
  },
  {
    codes: 6732,
    descriptions: "$<\"三8=: \"><\"2六(3&%五, 四^}8;7%'=, $/%.",
  },
  {
    codes: 6733,
    descriptions: "56#六一:, 268",
  },
  {
    codes: 6792,
    descriptions: "五七2 =0?,8一% 七}七九0%{",
  },
  {
    codes: 6794,
    descriptions: "'9*07{ /四$.^. \"+2 9,七]%)*",
  },
  {
    codes: 6798,
    descriptions: "=[2% '3七3六+ 3*八*\"$七*七: ,>^||十",
  },
  {
    codes: 6799,
    descriptions: "二&^.十_+%|, 2;4",
  },
  {
    codes: 7011,
    descriptions: "&八@一七6 97% 五9五02{",
  },
  {
    codes: 7021,
    descriptions: "\"\"9@[59 ${* 二十?\\.九>_ ;=2六0)",
  },
  {
    codes: 7032,
    descriptions: "):0=^%3* #=_ {+六0四7}}八_四* =<八}*",
  },
  {
    codes: 7033,
    descriptions: "五#|九#[' 四一6]7 0*) 一3\\#8十6%一",
  },
  {
    codes: 7041,
    descriptions: "^[86?|3]]九-$<十$1 :$'二六8=7(2+二 三>&七四^",
  },
  {
    codes: 7211,
    descriptions: "07]六{ 5[?%?^四八}, :六_%五, 六=} 6四>五+0八六>六",
  },
  {
    codes: 7212,
    descriptions: "%<9七/;? |#1四+,>+ *{6 *[四3二@{|' 三<(_五十",
  },
  {
    codes: 7213,
    descriptions: "@六+\\{ ._*)+一",
  },
  {
    codes: 7215,
    descriptions: "七.8@->?_二四>二八 _,>,=七>七7 >@八 0;@七\"{5:",
  },
  {
    codes: 7216,
    descriptions: "_<十&\\}八'^?) 0六一:六&, .<,?五6 (一[四",
  },
  {
    codes: 7217,
    descriptions: "=%<\"九0 62? ]:1>\",.06@ *9十一\">八二",
  },
  {
    codes: 7218,
    descriptions: "\\=\\,&%?3}3 *十3#,<)8+八",
  },
  {
    codes: 7219,
    descriptions: "(06/2十/ {八\\ 8五;九9<: *9]$一\"7,, >七2",
  },
  {
    codes: 7221,
    descriptions: "十61:十2%+九&?{ 六9(\\=$6, ^6\"#4/+;",
  },
  {
    codes: 7231,
    descriptions: "二38]<四 [)=三@",
  },
  {
    codes: 7241,
    descriptions: "{(^(=8 ?2^1%",
  },
  {
    codes: 7251,
    descriptions: "\"26$ 9|31;7 #八; ]十#,19++六 3&^2,|2",
  },
  {
    codes: 7261,
    descriptions: "':<]@(% =四.2%'( '29 6|:.2.$二0?_",
  },
  {
    codes: 7291,
    descriptions: "六*> 一$七十@八 ,?[6)\\0七28_ ^+@[\"七&八",
  },
  {
    codes: 7299,
    descriptions: "'#6\\{\"八一=#7一' 05七)_.五) \\1八五81?:",
  },
  {
    codes: 7311,
    descriptions: "六?4一|}]+=4_ :2$>.36/",
  },
  {
    codes: 7312,
    descriptions: "九6{>'/* 4四五二|<7十{二' >,/五9七=>",
  },
  {
    codes: 7313,
    descriptions: "五\"十/^, 1'五六四}五.0*, ,^七五\\[.,@ 9*(四十*,@{:^)<^,",
  },
  {
    codes: 7319,
    descriptions: ",七4'41四;020, 三&?",
  },
  {
    codes: 7322,
    descriptions: ",>,*二%/54\\ (一\\ ^0(.#2<+'? 六六,/四@[8",
  },
  {
    codes: 7323,
    descriptions: "1二>]八\" 三<%^|{557 一&八{)六.+",
  },
  {
    codes: 7331,
    descriptions: "&四]2#1 九.64 7;1\")八12>?7 四7八三十&<<",
  },
  {
    codes: 7334,
    descriptions: "一#;6]九4{_[八4 2十六 六,:&?>[\\:九* #8+@九?+3",
  },
  {
    codes: 7335,
    descriptions: "<6</&\\4*58 ').$#五((<:0",
  },
  {
    codes: 7336,
    descriptions: ";一};]六\\三{# _'( ?)^ $=*8.九2 )7][*)",
  },
  {
    codes: 7338,
    descriptions: "9\"+#;三)[5三四 <*< #}=$\\ 7|7一三0四5*",
  },
  {
    codes: 7342,
    descriptions: ",^[2&)]:\"十54 *'八 八<\"> 三;*9:@< 四四([52二'",
  },
  {
    codes: 7349,
    descriptions: "^*>五六\\&_ +九$8@?\"8|7八 }十^^|四:*, 九;5",
  },
  {
    codes: 7352,
    descriptions: "<\\四4048 5五:8>,三}6 ;|八#$+",
  },
  {
    codes: 7353,
    descriptions: "7七,,} @]^十]$:?+7#2 .0(+:一21' 9十(九.'",
  },
  {
    codes: 7359,
    descriptions: "6八5十_}八[8 >8&9\\| %4' @@{*5%}, 4_二",
  },
  {
    codes: 7361,
    descriptions: ",{(#/)5)6四 7$一=0(?%",
  },
  {
    codes: 7363,
    descriptions: "三4%[ 三?{(}) @八'&1^@#",
  },
  {
    codes: 7371,
    descriptions: "\"58')3 一;%4>#@. $2二\")8^[(三1 ?#'8六七3>",
  },
  {
    codes: 7372,
    descriptions: "=五:?七'*?2三: 7,:二>\\八9",
  },
  {
    codes: 7373,
    descriptions: "[01(三8=} &\\>0<'5&五@ ]七5>=^) &5八#七1",
  },
  {
    codes: 7374,
    descriptions: ",八{, #:一八?七][49 {,六 '|)79\"_;三1^",
  },
  {
    codes: 7375,
    descriptions: "$7*89七6\\\"(= 六@@九'$五三二 *&}(#^]?",
  },
  {
    codes: 7376,
    descriptions: "030七@(>? ,9/|,/七/<% 五(九八$&8)9三",
  },
  {
    codes: 7377,
    descriptions: "二八72四5四@ 五四十+6* @;' 4>&(={'",
  },
  {
    codes: 7378,
    descriptions: "$8三一=六一五 \\\"三$^四,3[&& 七+( <[\\\"%=",
  },
  {
    codes: 7379,
    descriptions: "二02<九四}+ +]0+七_= 8>^>?<(八, 六,2",
  },
  {
    codes: 7381,
    descriptions: "9六>二<4_]三 /{8 {9十四%7. 5(; @_<\\7+=十",
  },
  {
    codes: 7382,
    descriptions: "7\"\"76_,六 }六]1={\\ 3&/<_(\\4",
  },
  {
    codes: 7383,
    descriptions: "二三二* =六319}?\"_9",
  },
  {
    codes: 7384,
    descriptions: "8|>$(*六五+74 六.七,826@6_6/",
  },
  {
    codes: 7389,
    descriptions: "\"_#@;+(八 |七7五<五;_, 九37",
  },
  {
    codes: 7513,
    descriptions: "@}八\\十 5}/_>} ,4} &$五四#七\\, ]三十_\\=% )/\"5?:]",
  },
  {
    codes: 7514,
    descriptions: "[.}?\"_八4, 一;六 三)7一}三",
  },
  {
    codes: 7515,
    descriptions: "*9:%三三54| &八% =+十>9七8",
  },
  {
    codes: 7519,
    descriptions: "$\"_'+(> /十4七十$5 &|)5\"六",
  },
  {
    codes: 7521,
    descriptions: "3@3*5))]?4 8.%+*=三",
  },
  {
    codes: 7532,
    descriptions: "4#九 /*2 \"9五6 五{9}^} >九4 <六+>] ,7九[@",
  },
  {
    codes: 7533,
    descriptions: "]\\'二 *六[{$一( 7_};四{ 8+|>?{ '六7[8",
  },
  {
    codes: 7534,
    descriptions: "7|一6 *8一四9,$十_< :二十 15八>\"% ^'四[3",
  },
  {
    codes: 7536,
    descriptions: "*:^,@^)%/七 @>[^) 一62四)*'#5&2 8八\"''",
  },
  {
    codes: 7537,
    descriptions: "=3*三五三1}]$ 5九;%6$:{'{三十 .&一8+& ,;$$.",
  },
  {
    codes: 7538,
    descriptions: "八=二0九一' 四{*:.#\"+]# 十09(1, *三&六2",
  },
  {
    codes: 7539,
    descriptions: "|$六七1}9'八? .9七,五9 二二三*三, 二\"'",
  },
  {
    codes: 7542,
    descriptions: "0+.三八,'/;",
  },
  {
    codes: 7549,
    descriptions: "^&[[2\"七9>) 四6%36四#|, 1@7",
  },
  {
    codes: 7622,
    descriptions: "<}一#六 &_二 )6:3]十@3+} +}62二五",
  },
  {
    codes: 7623,
    descriptions: "|$)一69<;<%八三. 7三+?:.四 1$# ^&一一#三",
  },
  {
    codes: 7629,
    descriptions: ".?3一|九<3三| :}三)*] 三77)十",
  },
  {
    codes: 7631,
    descriptions: "@二九1(, 3[/*5, ]&9 $@'%#三3 \"七:0六一",
  },
  {
    codes: 7641,
    descriptions: "1;(十\".{四901[ ]%三 三]四+?5'七+ 7三七%%;",
  },
  {
    codes: 7692,
    descriptions: "}七八三%:十 七|1三一[",
  },
  {
    codes: 7694,
    descriptions: "36九*5三七四 {&:')\\四(\\ })九五三",
  },
  {
    codes: 7699,
    descriptions: "($<^|; ;}六7三[|4, ,\"(",
  },
  {
    codes: 7812,
    descriptions: "四');$3 六3,)*五3 [(7 ..63] 六七六])三一0|&",
  },
  {
    codes: 7819,
    descriptions: "^八七]$%三| /\"二0$6 %} &4五四]5 27|*六>)?",
  },
  {
    codes: 7822,
    descriptions: "二4^'十十 ;四*#&:四 [:八 三1=九 .七]@[\"*:)\"/三",
  },
  {
    codes: 7829,
    descriptions: "\"}?<5] {*三%*?$ 9,%三);^)%082 8;=4\\>|'",
  },
  {
    codes: 7832,
    descriptions: "五八61.> 七二=二/=; }(三六3^[9, '.六1六= }七十5=-4,",
  },
  {
    codes: 7833,
    descriptions: "+?@一5-三五 #七八/\\( ?2^>%八> ,,2)9+十#",
  },
  {
    codes: 7841,
    descriptions: "十\"/一. 72\"> 二)3/{一",
  },
  {
    codes: 7911,
    descriptions: "六六3[1 .十十十.五4, &八*_五({, ],} 0)十7;",
  },
  {
    codes: 7922,
    descriptions: "](四=^八0*一+ 三7十{十(^=@ .五5 ?]#*%:?4",
  },
  {
    codes: 7929,
    descriptions: "?+九}^@&三+十9四 0+3 2;8})9>2?|六]? 0*5二一>",
  },
  {
    codes: 7933,
    descriptions: "六6\\@%64 十#.}*^六",
  },
  {
    codes: 7941,
    descriptions: "<五一)1< %十&一七, |84>?#1七, |5? @^十\"\"/5%八",
  },
  {
    codes: 7948,
    descriptions: "9八{)){, &\"&\"0]370 #$=:< ,\"}八|0904",
  },
  {
    codes: 7991,
    descriptions: "七六_&$@1= 九;十\\$6/ 三,:>\\;8<,5",
  },
  {
    codes: 7992,
    descriptions: "六}二1+% /\"<^ ^7@5><;",
  },
  {
    codes: 7993,
    descriptions: "1四6^-\\4九'1'1\\ ]'五十4=七二四 2八_1%一1",
  },
  {
    codes: 7996,
    descriptions: "[8=6\"十]二; #\\@2.",
  },
  {
    codes: 7997,
    descriptions: "@>5#[*0五1# \\七;)8$ /%( 八八{;*]7>8: 0;,三<",
  },
  {
    codes: 7999,
    descriptions: "|四七5,==4二 五@四 +六|6+.:%二*, <1@",
  },
  {
    codes: 8011,
    descriptions: "九,,/<(四 }?[ (七十6.%一 八五 33*'&8_ 7_.?二[8",
  },
  {
    codes: 8021,
    descriptions: "56六四3}7 ><六 95|九%;> 8\" %/]:9340",
  },
  {
    codes: 8031,
    descriptions: "_0[$94/ ./2 #四$/二八1 0* ^7*1>[七\\@}五 3四422<+{五九",
  },
  {
    codes: 8041,
    descriptions: "*^$?\"\\四 9八6 )]%);82 ]九 八一0/五<\\,+4<四五",
  },
  {
    codes: 8042,
    descriptions: "九4@+<@{ $'6 7|}|1?< ]* 七9*#\"}=/(.#8",
  },
  {
    codes: 8043,
    descriptions: "<五#8四九^ }31 十|;+3十四 ^六 十十@_.2;4}{十",
  },
  {
    codes: 8049,
    descriptions: "77五九十九: 3七 5一十50, (&@十_(7}8八六4",
  },
  {
    codes: 8051,
    descriptions: "]}1)7#一 108#_&' 八九六3 一4六八?<十$1?",
  },
  {
    codes: 8052,
    descriptions: "%@<.%.+\\;)?3 四$<| \\五4){十九@<六",
  },
  {
    codes: 8059,
    descriptions: "|3:;二[8 九]9 <'62%%'二 二_?#, )一八",
  },
  {
    codes: 8062,
    descriptions: "$4?5二三三 /四%_&*] >[2 %:7六:九;$ 6*四*{((7七",
  },
  {
    codes: 8063,
    descriptions: ";3'四二|#二>(_ 九{&\"%{@=\"",
  },
  {
    codes: 8069,
    descriptions: ",+十四29九{$ &6[三_十\"*?, ^6五3&九 3@5(#,\"=@*^",
  },
  {
    codes: 8071,
    descriptions: "9@_&>七十 ;57四7八+7^&;>",
  },
  {
    codes: 8072,
    descriptions: "四%)五\\: <一}#04/*]&$[",
  },
  {
    codes: 8082,
    descriptions: "%1\"十 '_\\[@} $=?, \\1;$_{七/",
  },
  {
    codes: 8092,
    descriptions: "68七四五< $三5)八#>2 ]&\"$|>$",
  },
  {
    codes: 8093,
    descriptions: "五十8七+/=:; _8,\\,3?八一7 \"九8\"5,?, _68",
  },
  {
    codes: 8099,
    descriptions: "8七一3]' 7;\\ ,七三十1, 四_(8=0@5, &;十",
  },
  {
    codes: 8111,
    descriptions: "}81\\二 一8%五5,四^",
  },
  {
    codes: 8211,
    descriptions: "+\"]|三四\"一]2 29@ {四四三\"五六十& 六9%\"4$[",
  },
  {
    codes: 8221,
    descriptions: "\"70\\&6\\四 $八. 十1;**)九七/三=<",
  },
  {
    codes: 8222,
    descriptions: "九%9五九$ ^四./一')0",
  },
  {
    codes: 8231,
    descriptions: "二四)8,六%8(",
  },
  {
    codes: 8243,
    descriptions: "22十7 =|/7\\^0=二^ }&=1八(十",
  },
  {
    codes: 8244,
    descriptions: "二九/七,_六六 5[' >#5,^九0{?五& _:05{(一",
  },
  {
    codes: 8249,
    descriptions: "=6$[}]十\\%( 0)@}@)九, ]5+",
  },
  {
    codes: 8299,
    descriptions: "0^6十九]< 二+[ {五$'五1033&; [=0(^?&=",
  },
  {
    codes: 8322,
    descriptions: "0@{七|}八\\9. 八*^ }:;四[\\ ]4|一:|50",
  },
  {
    codes: 8331,
    descriptions: "}#] 7\"@/六,九. :一^ 五60#1十= '四五一\"[^\"",
  },
  {
    codes: 8351,
    descriptions: "\\:=:一 08# \"九五4 +)66??@]",
  },
  {
    codes: 8361,
    descriptions: "七(602.3#十.7 四$4'",
  },
  {
    codes: 8399,
    descriptions: "'\".\"@2 \\./^\\四{4, 五一@",
  },
  {
    codes: 8412,
    descriptions: "八三9(6\"6 6八{ ^_/ )九\";)^<[_",
  },
  {
    codes: 8422,
    descriptions: "},\"[八一0}4 %(2 )0<9853\\\\] 十]\"一80<",
  },
  {
    codes: 8611,
    descriptions: "\"\\{:1%6? }>|一7=\\@四/|五",
  },
  {
    codes: 8621,
    descriptions: "<三=\"&>\",222/ \\@&\"_#&79九%^+",
  },
  {
    codes: 8631,
    descriptions: "+九^四2 |+7\\$>+?}九}*.",
  },
  {
    codes: 8641,
    descriptions: "5^@}\" *八4 五\"770( :0%2%'七#.4(2",
  },
  {
    codes: 8651,
    descriptions: ":8,十>78六. ^#九.#[\\\\7#一<<",
  },
  {
    codes: 8661,
    descriptions: "<*[<=六%/= {}8$<6_$/&</一",
  },
  {
    codes: 8699,
    descriptions: "六八九九@.;+\"% 500}6}%四=;?三,, ?\\?",
  },
  {
    codes: 8711,
    descriptions: "_二9<{%9)4#6 [./59七?^",
  },
  {
    codes: 8712,
    descriptions: "一6&>;}8@<二;9? 五)二<4{}6",
  },
  {
    codes: 8713,
    descriptions: "六<+6(3:三% @一三一&六{四",
  },
  {
    codes: 8721,
    descriptions: "(九+#](/610, \"|六;89,}, 十:+ %|#'),三(%%\\",
  },
  {
    codes: 8731,
    descriptions: "#八6五.一\"1四: 一'51五1一[ '八{#'6七0",
  },
  {
    codes: 8732,
    descriptions: "五\"#{|1六二22 六>)7<87,8$? 0|7\">十,#",
  },
  {
    codes: 8733,
    descriptions: "_4'<'7.\"@^八9] 八三/*#$;二 9(@@22六@十|^18",
  },
  {
    codes: 8734,
    descriptions: "*/3<2*十 四,3%\\$\"8八=3}",
  },
  {
    codes: 8741,
    descriptions: "%四四]十\\]$5& ^$/|十518",
  },
  {
    codes: 8742,
    descriptions: ":9:六四一&)二6 3>7四四$)[:八 #@二;{一2一",
  },
  {
    codes: 8743,
    descriptions: "6'2\\#6 四^.^:7[9\\ 六|六]_&5,",
  },
  {
    codes: 8744,
    descriptions: "[?十8@五十95\\ @[}三>.+ 1=\"八一一1*",
  },
  {
    codes: 8748,
    descriptions: "$2]9八,)8 ,%+8?_二%55, 二.)",
  },
  {
    codes: 8811,
    descriptions: "<二{25@二 九_=9+三六三4/",
  },
  {
    codes: 8999,
    descriptions: "八.\"|^_十5, 七7{",
  },
  {
    codes: 9111,
    descriptions: ")十三+1五[<六 @;@\\,@;",
  },
  {
    codes: 9121,
    descriptions: "\\/..3四4$>\"_ ;[*($八",
  },
  {
    codes: 9131,
    descriptions: "50[6>*九=五 <7' )}四/@=5^$]$ .>$1三#]#",
  },
  {
    codes: 9199,
    descriptions: "四6.#|二| 八十]5七九22_七, }二/",
  },
  {
    codes: 9211,
    descriptions: "=<?七八,",
  },
  {
    codes: 9221,
    descriptions: "''一_7六 <<六2\"|七>^]",
  },
  {
    codes: 9222,
    descriptions: "&378八 %:60^]# 1六1 九6(]九2&2'九6",
  },
  {
    codes: 9223,
    descriptions: "*#\\.1;:9)@[/ %<+|+1?&95]5",
  },
  {
    codes: 9224,
    descriptions: "*&); ]}==*_{$%>",
  },
  {
    codes: 9229,
    descriptions: "?四+><9 十9<+8 +八七 三8_+:0, \\[九",
  },
  {
    codes: 9311,
    descriptions: "二,7}七=(, [5^4九+;八, =(^ :,'九(*@1 &%一(二\\",
  },
  {
    codes: 9411,
    descriptions: "8)9#_@三@^5#{七9 [= *#七]5一三二@,一 '(十@#.>十",
  },
  {
    codes: 9431,
    descriptions: "5_+二6\"&六四六3;[@ {十 :8]_9@ 九:三*0) ^&$?/八4*",
  },
  {
    codes: 9441,
    descriptions: "七四一.05$<%(+\"三@ _八 +\"二七九\" :<\" 8#,'61{* /=_+\\*$十",
  },
  {
    codes: 9451,
    descriptions: "九|?九9'八十'{,49% {二 ^62.九+三{' [_;8}五七",
  },
  {
    codes: 9511,
    descriptions: "1$,, 一==41, ]<四 二2=*八 .@十{| |四?三七1五[[三",
  },
  {
    codes: 9512,
    descriptions: "*@七6, 四二四;[7<, ;4( 5=<&??\\) 2一(4*/$}一{'八",
  },
  {
    codes: 9531,
    descriptions: ",_,四四]: [@五2+>\\)",
  },
  {
    codes: 9532,
    descriptions: "4^七%@ 五'} 十3>08=\\%^ \\79_,:922##",
  },
  {
    codes: 9611,
    descriptions: "82'&\"/\\0*#3\"<9 #] 4三+0='1 ,\"八九}?:6 3三@$)5[%",
  },
  {
    codes: 9621,
    descriptions: ">|十=7}一6?{, 22五\\,><三5&八?\"7 9五 \"九=<\"]>+^七>(,2",
  },
  {
    codes: 9631,
    descriptions: "|+;*:5\"\"五五, 9#88$7一1六\"{七<八 5四 ?九\"3#=五十1",
  },
  {
    codes: 9641,
    descriptions: "2九*=&)8六|{ _一 #,@*08六六<}一+ 6=\"3|\"7<)",
  },
  {
    codes: 9651,
    descriptions: "6{:/,][;52, }%:四#$%9%<二)4 ^)四=#九\"641 $2*(五&@",
  },
  {
    codes: 9661,
    descriptions: "\">[:# 三;9@_(22 {\\> ]1%|&#|37\\",
  },
  {
    codes: 9711,
    descriptions: "@/<$@%&\\ =)三7一八+[",
  },
  {
    codes: 9721,
    descriptions: "=80六1[__四11)^ *一八|;3九",
  },
  {
    codes: 9999,
    descriptions: "六十_九7\\二{6.十0%1三 =7一七六九?]}|'?五一",
  },
]

export default sic;