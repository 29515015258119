import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Text, View, ActivityIndicator } from 'react-native';

import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { handleLogoutAdmin } from "./components/LogoutAdmin";

import AddAdmin from "./pages/sidebarPages/AddAdmin";
import PaymentList from "./pages/sidebarPages/PaymentList";
import OdooList from "./pages/sidebarPages/OdooList";
import UserList from "./pages/sidebarPages/UserList";
import FormList from "./pages/sidebarPages/FormList";
import StatusCheck from "./pages/sidebarPages/StatusCheck";

import { useTranslation } from "react-i18next";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import "./assets/css/styles.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    overflow: 'hidden',
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },

  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


function ThemeAdmin() {

  const [open, setOpen] = useState(true);
  const [SelectedPage, setPage] = useState("userList");

  //loading components
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);  

  let navigate = useNavigate();
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const uid = window.sessionStorage.getItem("admin_uid");


  //if user not authenticated
  useEffect(() => {
    if(uid === null) {
      handleLogoutAdmin();
      navigate("/adminlogin", { replace: true });
    }

    else {
      // const list_obj = {
      //   "uid": uid
      // }
    
      // fetch(process.env.REACT_APP_REQ_IP + '/check_role', { method: 'POST', mode: 'cors' , headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json())
      //   .then(data => {

      //     console.log("EĞER KULLANICI ADMİN DEĞİLSE THEME E GİDECEK")

      //     // if(data.userRole === "freemium") {
      //     //   navigate("/sandbox", { replace: true });
      //     // }
      //     // else {
      //     //   navigate("/app", { replace: true });
      //     // }
          

      // })
      // .catch(err => {
      //   console.log("Error :", err)
      //   navigate("/unauthorized", { replace: true });
      // });

    }

  }, [uid]);


  const pageList = {
    "userList" : UserList(),
    "paymentList": PaymentList(),
    "odooList": OdooList(),
    "addAdmin": AddAdmin(),
    "formList": FormList(),
    "statusCheck": StatusCheck()
  }

  // kolayaiStatic
  
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  var theme = createTheme({});

  theme = responsiveFontSizes(theme);

  const handleSidebar = (e) => {
    const selected_page = e.currentTarget.value;

    setPage(selected_page);
  };

  if (isLoading) {
    return (
        <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color="#d83534" />
        </View>
    );
  }
  if (error) {
      return (
          <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
              <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
          </View>
      );
  }
  else {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={5000}   anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
        <div className={classes.root}>
          <CssBaseline />
          <Header
            handleDrawerToggle={handleDrawerToggle}
            handleSidebar={handleSidebar}
          />
          <Sidebar handleDrawerClose={handleDrawerClose} open={open} handleSidebar={handleSidebar} />
          <main className={clsx(classes.content, { [classes.contentShift]: open })}>
            <div className={classes.drawerHeader} />
                {pageList[SelectedPage]}
          </main>
        </div>
      </ThemeProvider>
    );
  }

}

export default ThemeAdmin;
