const cs = [
    "七[六}[4$0_八五 ",
    "2$'<+*+ ",
    "*2]{#; 一)=/)_ (_ 5]\\七_2七 ",
    "一=,9四}[5 |一四七) ",
    "1&5九_三| ",
    "%|七二/8 ",
    "六8+*5.7', '二<|1[ .42++二一 ",
    "+六.八六^[ '0_ 六]50十二( ",
    "9<[八\")}=2 ",
    "|]='\"四+ ",
    "9|'7十 , %?一&;+:八三78 ",
    "<\\?]|/?^?",
    "四一}<|][",
    "(9<*7一382#",
    "6:4(八十九",
    "|3,)9]$",
    "1二\"^]}96八(",
    ";一)=7}^九",
    "<\\{一;四九",
    "7^:五%{",
    "+6?.9",
    "3\"4?#/1, <;*\\]99",
    "八7=/;][ ",
    "%\\7.;三 ",
    "04七/^/ }三八( #]:)*二六1 ",
    "_三%;17| (*${#(\\七) ",
    "08,五</1 ",
    "+三4五++ #一八 ;+2&$^&1^5' ",
    "&$^十八<}. ",
    "1#一十_7 ",
    "?九7#九) ",
    "{>_一+\\九5 ",
    "9)七四六_> .22七 ",
    "五*\"_七六5 ",
    "六<9\" >6'+八 ",
    "一80}二$ ?'>*39%, [/\"=九四五 ",
    "1三}}'(8 ",
    "$>[九=\\4(( $0193四 , \"@六<六)/|9 ",
    "7:九/六二:; ",
    "{\"九_ ",
    "1六\".[ ^,|'[/*@ ",
    "八[一9@ ",
    "$)@00=1 ",
    "//<二 =]十<{",
    "九九80[_7五? ,^一一=]^<",
    "6?$>(0+.",
    "|三7|1^6九7(",
    "/62@6$?(+7 *|五$86",
    "五七 4\\@\"*9九\\",
    ":[/.\\0__]",
    ":$;*%九{",
    "(二二};;+",
    "六八_$\"\\|",
    "\\五八*九';\"",
    " 九]3@八;@ ",
    "@五(@",
    "七%<?{ 7九2<'",
    "二八<9=}77_0>",
    "四十四2<?7/&",
    "|>九(34^",
    "<八<\"(^3= *#十3{'/, ;+,<>&{",
    "3)七}二,",
    "?.五0<< 六,_|三'",
    "=84>_3 7六七七$56% &,八1'?,4$ (5二3=&8]@* 5二#五%<+)",
    "[十^$?四 /2#三@=6七[",
    "*^0@\\",
    "4%\\/6",
    ")四4[\"/",
    "[<][二",
    "^$7{ ';4_7",
    "七四九'十0>/2, 五27]*89",
    "1=@七*}",
    "%|{8三[-;.八二十/",
    "^:}:8(1",
    "@十{</七'九\"",
    "<1/)&9七:, 九1*=:) ",
    "^1<四, @';*1四) ",
    "@.$.5\"0<4",
    "一*#2;.",
    "六\"二二\\ \">5;3:",
    "1,%$< |1&;+>一 055 1三& 8>0>2 六)二2八/七? ?:0二?(6, >{?]三5 .:|=698",
    "九?四%}$七 ,.十九九1,{四一(%九八",
    ",七/*$ \\十十+0",
    "_=7@46六 ",
    "\"]3>九",
    "7/@{:= %'}[@[五",
    "1四七0三\\$",
    "{六一2@",
    "=八{,\\\";=}0}",
    "二#{'十+?;@#4 ]$|,=84>",
    "[{三三')9+",
    "28):",
    "_(6}3@,",
    "四}^/",
    "+6\";)&1",
    "\"7),[=95 ",
    "五六70二 ",
    "4[/'}( ",
    "0|七'.% ",
    "&9%29.)<':? ",
    "$四/79 ",
    "二'三^5<七 ",
    "#六>2一3^ ",
    "@,6(\" ",
    "7%十四.九6四 36>七$, &/四;二九; ",
    "2.;六. ",
    "3四5&5八2七",
    "2^%;).%(",
    "$&[,5六",
    "[9>:[八 ,.%44六六",
    ">(二0'[7一<三",
    "\"\\/\\1",
    "六,十+0=二七=+",
    "<0,/'",
    ";\\四];7_='5",
    "5\\十1八639",
    "2+十}=5九4",
    "@1@$)十\"",
    "五2&?二 ",
    "&5十.:四十四0_ (二2七7/三: 96 四<十 3.八$二 ",
    ",':\"\\' ",
    "|19_\" 5_1> ",
    "6@.5}2 ",
    "九*7@_'九* 二];四\\+} ",
    "7^_一( ]+二七五 ",
    "0.],'|三7 |#*;[?1 ,\\一[]九6 ",
    "三\\,, ",
    "]@%五 ",
    "@二%7}8九 ",
    "八五(6*& ",
    "9}.八?6/ ",
    "&1[,' ",
    "/2四4>41$\"|]六1 ",
    "]}%*>45*/ ",
    "}_;7%<六 ",
    ",</(5]^五56 ",
    "{)+七\\(; ",
    "<9十七六/'**3 ",
    "2.6@6 (6九九2{) ",
    "2%]八+5八,四 ",
    " ,.)二4{ ",
    "6[.|\\18 ,七,:}\\$",
    "&{^9&}三)",
    "六5&&",
    "二){三5",
    "=[+二|#四 \"七0<65_",
    "九>五+九}8}8>, #8}\"八9",
    "+)八6,九/)\"",
    "十%.]};3, 七}}八十$",
    ":')}&%",
    "\".&四5",
    " 七四'2{| &*,,\"]\", {一=&>?九 ",
    "0_??6五|18七",
    "0477#,4_",
    "*\"9=0四=(",
    "?]三:$六",
    "8六八/6'五1*8",
    ")$\\(:四_+四]",
    "%?+\"5^?}十8",
    "?十2+}7八",
    "};]9_",
    "1#4}一",
    ".四,3^",
    "[\"'/+=_",
    "@0_\\4,五九六",
    "七(+], ]四二 &三0*一%[",
    "^六:七.= ?",
    "\\*+八九=, 9]65八7, *[6>九九#}",
    "=3[)八#;?|;",
    "0九8#_;],",
    "1;8;: //$+^8六",
    "6].7],{ $五九$一, 四[]2=\\_",
    "&五十]四>",
    "$_40. +\\十 6,_八$0",
    ">?(八.,6:",
    "五九&四",
    "*083十6",
    "0五)四?12.",
    "?/$}*> 4:}^, ^^/&9[|",
    "\"4八{8#3, 三1\\八)[",
    "@404'?八",
    "七1==<3",
    ".>692/. 8八6'六二=96/",
    "&9四4四. 6%6二9一, $二六十\\0_ ",
    "三:=*_ 六}1:三\", ='#九:4 ",
    "=@@\\[ *27\"<5 九十+ _三三#023六, ]_/=(三 ",
    "0'7@+ ",
    "][\\ 七7十@*) ",
    "\\.),2 <五四八. >:# 八$3?9 ",
    "|{\"3九 2^四+\" ",
    "511{9 }[.)$5. \\+> 八*7 (8=三?/八*九1 ",
    " 8八$ \\一九@ 六|4 1*2<#二<6 ",
    "+十@,|十=",
    "\"#一%)%七;*三",
    "六13九8#",
    "[四_@.? ?五三五.",
    "1*&三.%三二=",
    "3[0)5=7'",
    "二+三$33]&",
    "十/九九九;# +5?七4七:",
    "七八7;{八@",
    "+四* }8+/8",
    " *575? ",
    "8%9;=?{;",
    "\"/}3|",
    "30四0| 5$:9九*",
    "^三5九\\&+[, _.40\\_",
    "/(七一五=6十五",
    "/4]十'",
    "\"七(|'十[]一3",
    "2\"87.,6四",
    "'}9>%七56",
    "/>145_",
    "4[>$",
    "七38$.",
    "[5'9/$@) 4.1 0(四>^?",
    "4'4七19[",
    "\"=8}( |:{ /二三4五@ 1九九8六.@, 4]四七,八 =一七四\\四7",
    "=%1{5^",
    "|/*.:=",
    "9\\&#六一6,4%,四",
    "56九三%;",
    "二二四+七&九",
    "十394",
    "_#@九;\"\"",
    "[七;]_{ ",
    "?6九'+一 50五 一四{|2,, {/3{\\\" ",
    "三\\$),4, ",
    "一{十$8]*#< ",
    ";九十)]\\< ",
    "一八三:;^ ])5六一1#, )@/']]三 ",
    "])=#}\\ <?#)二:>, (9八5三\\* ",
    "910. 63五'}>[, _9四31一4 ",
    "四四六5} ",
    "/+8 一[4@三|_1+, [;2,=九 ",
    ",%% 4^/四[42 ",
    "91?0_< ",
    "(]:[(8 ",
    "\">?:/十十&",
]

export default cs;