import React, { useState, useEffect } from "react";
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import { DataGrid } from '@mui/x-data-grid';

import { handleLogoutAdmin } from '../../components/LogoutAdmin';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const columns = [
    { 
        field: 'email', 
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 200,
    },
    {
        field: 'status',
        headerName: '二__9七6=> .七{4, *{;|70一',
        width: 300,
        valueFormatter: ({ value }) => `${value} 🔄`,
        cellClassName: 'gtai-app-theme-cell'

    },

];

function StatusCheck() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [results, setResults] = useState();

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("admin_uid");
    let email = window.sessionStorage.getItem('admin_email');

    useEffect(() => {

        setIsLoading(true);

        const list_obj = {
            "email": email,
        }

        fetch(process.env.REACT_APP_REQ_IP + '/admins', { method: 'POST', mode: 'cors' ,headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json()).then(data => {
            
            setIsLoading(false);

            if(data.result === false) {

                enqueueSnackbar(t("You are not allowed to view this page."), { 
                    variant: 'error',
                    persist: false
                });

                handleLogoutAdmin();
            }
        })
        .catch(err => {
            setIsLoading(false);
            setError(err);
        });
    
    }, [email]);

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#2b388f" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (
            <div className='d-flex'>
                <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
                <Card className="list-container">
                <div className="list-title-container">
                    <Typography variant="caption" className="bar-title col-md-3" gutterBottom>{t("二__9七6=> .七{4, *{;|70一")}</Typography>
                    <div className={{ flexGrow: 1 }} />
                </div>
                <div className="mt-4">
                    {results && columns &&
                        <DataGrid
                        className="datagrid-container"
                        rows={results}
                        columns={columns}
                        pageSize={5}
                        />
                    }
                </div>
                </Card>
            </div>
        );
    }

}

export default StatusCheck;
