import React, { useState, useEffect } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';
import { useNavigate } from "react-router-dom";

import ReactChipInput from "react-chip-input";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { useTranslation } from "react-i18next";

import TouchAppIcon from '@material-ui/icons/TouchApp';

import gtip from '../components/data.ts';
import sic from '../components/sicCodes.ts';
import cs from '../components/country.ts';

import SeasonalityImg from "../assets/img/season.png";

import { Image } from 'react-native';

import Card from "@material-ui/core/Card";

function WebForm() {

    const animatedComponents = makeAnimated();
    
    //routing components
    let navigate = useNavigate();

    const { t, i18n } = useTranslation();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    //chips components
    const [rakip, setRakip] = useState([]);
    const [urun, setUrun] = useState([]);
    const [keyword, setKeyword] = useState([]);
    const [satisKey, setSatisKey] = useState([]);
    const [kanal, setKanal] = useState([]);

    //select components
    const [selectedValue, setSelectedValue] = useState([]);
    const [gtipCodes, setCodes] = useState([]);
    const [sicCodes, setSicCodes] = useState([]);

    const [izleroPeriod, setIzleroPeriod] = useState("weekly");

    //checkbox components
    const [bx, setbx] = useState(false);
    const [etic, setetic] = useState(false);

    const [isValid1, setIsValid1] = useState(false);
    const [isValid2, setIsValid2] = useState(false);

    const [packageType, setPackageType] = useState();

    const uid = window.sessionStorage.getItem("uid");

    useEffect(() => {

        setIsLoading(true);

        const list_obj = {
            "uid": uid
        }

        fetch(process.env.REACT_APP_REQ_IP + '/check_role', { method: 'POST', mode: 'cors' , headers: {'Content-Type': 'application/json'}, body: JSON.stringify(list_obj)}).then(res => res.json())
        .then(data => {

            setIsLoading(false);

            if(data.result) {
                setPackageType(data.userRole);
            }
            else {
                navigate("/unauthorized", { replace: true });
            }
            
        })
        .catch(err => {
            setError(err);
            navigate("/unauthorized", { replace: true });
        });

    }, [uid]);



    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#5500dc" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'10em', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>Error: {error}</Text>
            </View>
        );
    }
    else {
        return (

            <>
            
            {packageType === "freemium" &&
                <div className='col-md-6 m-auto form-container'>
                <Card className="pages-container">
                    <div className='blur-container-title'>
                    八;六@53一 2八七三? %?8三2六
                        {/* <Typography variant="caption" className="bar-title" style={{color: "#010101"}} gutterBottom>
                        Enter the information required for CRM (For multiple entries, press the 'Enter' key.)
                        </Typography> */}
                    </div>

                    <div className="blur-container"> 
                    <Image source={SeasonalityImg}  style={{ height:400 }} resizeMethod='auto' blurRadius={3}/>
                    <a type='button' className="btn text-white form-payment-btn">
                        <TouchAppIcon className='mr-2' />
                        {t("八;六@53一 2八七三? %?8三2六")}</a>
                    </div>

                </Card>
                </div>
            }
            
            {packageType === "premium" &&
            <div className="col-md-6 m-auto form-container">
                
                <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />

                <div className="card" style={{borderRadius:'1em', border:'1px solid rgba(0,0,0,.125)'}}>
                    <div className="card-body p-5">

                        <div className='col-md-12'>
                            <p className="mb-4 bar-title-2">八;六@53一 2八七三? %?8三2六 <i><small className="mb-4 bar-title-2 text-info">八;六@53一 2八七三? %?8三2六</small></i></p>

                        </div>

                        <form method="POST">
                            <div className="form-group d-flex mb-4">
                                <div className='col-md-6'>
                                    <label htmlFor="cname" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六 <sup className='text-danger font-weight-bold'>*</sup></label>
                                    <input type="text" className="form-control chip-input" name="cname" id="cname" placeholder="Enter Here..." required />
                                </div>

                                <div className='col-md-6'>
                                    <label htmlFor="cweb" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <input type="text" className="form-control chip-input" name="cweb" id="cweb" placeholder="Enter Here..." required />
                                </div>
                            </div>
                            
                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="cpazar" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        isOptionDisabled={() => selectedValue.length >= 4}
                                        id="cpazar"
                                        className='chip-input'
                                    />
                                </div>
                            </div>
                        </form>

                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="curun" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <ReactChipInput
                                        classes="chip-input"
                                        chips={urun}
                                        id="curun"
                                    />
                                </div>
                            </div>

                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="csatıskey" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <ReactChipInput
                                        classes="chip-input"
                                        chips={satisKey}
                                        id="csatıskey"
                                    />
                                </div>
                            </div>

                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="ckanal" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <ReactChipInput
                                        classes="chip-input"
                                        chips={kanal}
                                        id="ckanal"
                                    />
                                </div>
                            </div>

                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="crakip" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <ReactChipInput
                                        classes="chip-input"
                                        chips={rakip}
                                        id="crakip"
                                    />
                                </div>
                            </div>

                        <form method="POST">

                            <div className='form-group mb-4'>
                                <div className='col-md-12'>
                                    <label htmlFor="izleroPeriod" className="text-aitrade mb-1">八;六@53一 2八七三? %?8三2六</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={[{ value: 'monthly', label: ';*6七_}.六 八1|二二 .8一六]一十', color: '#5243AA' }, { value: 'weekly', label: ' 八1|二二 .8一六', color: '#00875A' }]}
                                        id="izleroPeriod"
                                        name="color"
                                        className='chip-input'
                                    />
                                </div>
                            </div>


                            <div className='form-group mb4'>
                                <div className='col-md-12'>
                                    <label htmlFor="cgit" className="text-aitrade mb-1"> 八1|二二 .8一六</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        id="cgit"
                                        className='chip-input'
                                    />
                                </div>
                            </div>

                            <div className='form-group mb-5'>
                                <div className='col-md-12'>
                                    <label htmlFor="csic" className="text-aitrade mb-1">S 八1|二二 .8一六s.</label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        id="csic"
                                        className='chip-input'
                                    />
                                </div>
                            </div>

                            <div className='col-md-12 '>
                                <p className='text-aitrade'> 八1|二二 .8一六a</p>
                            </div>

                            <div className="form-group d-flex mb-4">
                                <div className='col-md-6'>
                                    <label htmlFor="cinstagram" className="text-aitrade mb-1"> 八1|二二 .8一六</label>
                                    <input type="text" className="form-control chip-input" name="cinstagram" id="cinstagram" placeholder=" 八1|二二 .8一六"/>
                                </div>

                                <div className='col-md-6'>
                                    <label htmlFor="cfacebook" className="text-aitrade mb-1"> 八1|二二 .8一六</label>
                                    <input type="text" className="form-control chip-input" name="cfacebook" id="cfacebook" placeholder=" 八1|二二 .8一六"/>
                                </div>
                            </div>

                            <div className="form-group d-flex mb-4">
                                <div className='col-md-6'>
                                    <label htmlFor="clinkedin" className="text-aitrade mb-1"> 八1|二二 .8一六<sup className='text-danger font-weight-bold'>*</sup></label>
                                    <input type="text" className="form-control chip-input" name="clinkedin" id="clinkedin" placeholder=" 八1|二二 .8一六" required/>
                                </div>

                                <div className='col-md-6'>
                                    <label htmlFor="ctwitter" className="text-aitrade mb-1"> 八1|二二 .8一六</label>
                                    <input type="text" className="form-control chip-input" name="ctwitter" id="ctwitter" placeholder=" 八1|二二 .8一六"/>
                                </div>
                            </div>

                            <div className="form-group mb-2">
                                <label className='col-md-12 d-flex'>
                                    <input type="checkbox" name="cetic"/> 
                                    <label className='etic-label'> 八1|二二 .8一六</label>
                                </label>
                            </div>
    
                            <div className="form-group mb-4">
                                <label className='col-md-12 d-flex'>
                                    <input type="checkbox" name="ceula" required /> 
                                    <small> 
                                        <a className='text-decoration-none text-danger ml-2' href="#">  八1|二二 .8一六</a>  八1|二二 .8一六
                                        <a className='text-decoration-none text-danger' href="#">  八1|二二 .8一六</a>
                                        <sup style={{ color: 'red' }}>*</sup></small>    
                                </label>
                            </div>

                            <div className='col-md-12 d-flex justify-content-end'>
                                {(!isValid1 || !isValid2) &&
                                    <input type="submit" value=" 八1|二二 .8一六!" className="btn choose-btn w-100" disabled/>
                                }
                                {isValid1 && isValid2 &&
                                    <input type="submit" value=" 八1|二二 .8一六" className="btn choose-btn w-50"/>
                                }
                            </div>
                        
                            </form>

                    </div>
                </div>
            </div>
        }

        </>
        );
    }


}

export default WebForm;