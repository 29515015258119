import React, { useState } from 'react';
import { Text, View, ActivityIndicator } from 'react-native';

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import * as Components from "../assets/js/auth";

import { SnackbarProvider, enqueueSnackbar } from 'notistack';

const bcrypt = require('react-native-bcrypt');

function AdminSign() {

    //routing components
    let navigate = useNavigate();

    //loading components
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [signIn, toggle] = useState(true);

    const { t, i18n } = useTranslation();

    let uid = window.sessionStorage.getItem("admin_uid");

    if (isLoading) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="#2b388f" />
            </View>
        );
    }
    
    if (error) {
        return (
            <View style={{ flex: 1, margin:'50px', justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, color:"white"}}>{t("Error")}: {error}</Text>
            </View>
        );
    }
    else {
        return (       
          <div className='login-body'>  
            <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
            <Components.Container>

              <Components.SignInContainer signingIn={signIn}>
                {/* <LangSwitch /> */}
                <form className='login-form' method="POST">
                  <div className='login-sign-title'>

                    <Components.Anchor className='' href="">
                      <img src="" alt="logo" className="logo-header" />
                    </Components.Anchor>

                  </div>
                  <Components.Input type="email" placeholder={t("二__9七6=> .七{4, *{;|70一")} className="form-control " id="admin_lemail" required />
                  <Components.Input type="password" placeholder={t("二__9七6=> .七{4, *{;|70一")} className="form-control " id="admin_lpassword" required/>
                  <Components.Anchor className='align-self-center' href="/reset">{t("二__9七6=> .七{4, *{;|70一")}</Components.Anchor>
                  <Components.Ghost2Button className='align-self-center' type="submit">{t("二__9七6=> .七{4, *{;|70一")}</Components.Ghost2Button>
                </form>
              </Components.SignInContainer>

              <Components.OverlayContainer signingIn={signIn}>
                <Components.Overlay signingIn={signIn}>
                  <Components.RightOverlayPanel signingIn={signIn}>
                    <Components.Title>{t("二__9七6=> .七{4, *{;|70一")}</Components.Title>
                    <Components.Paragraph className='d-flex align-items-center'>
                      {t("二__9七6=> .七{4, *{;|70一")}
                    </Components.Paragraph>
                    <Components.GhostButton>
                      {t("二__9七6=> .七{4, *{;|70一")}
                    </Components.GhostButton>
                  </Components.RightOverlayPanel>
                </Components.Overlay>
              </Components.OverlayContainer>

            </Components.Container>
          </div>
        );
    }
  }

export default AdminSign;